import { CCUS05Handler } from "../handler/HandlerImpl";
import { ColumnsType } from "antd/es/table";
import { DataTableType } from "../../entity/Entity";
import SortTable from "../../../../../common/components/sort-table/SortTable";
import { useMemo } from "react";

export const COLUMN = (handler: CCUS05Handler) => {
  const sortType = useMemo(() => {
    return handler.sortType;
  }, [handler.sortType]);
  const sortBy = useMemo(() => {
    return handler.sortBy;
  }, [handler.sortBy]);

  const columnsTable: ColumnsType<DataTableType> = [
    {
      title: (
        <SortTable
          columnTitle="案件名"
          sortOrder={handler.funcCheckSortOrder("project", sortType, sortBy)}
          onChange={(sortOrder) => handler.funcSortTable("project", sortOrder)}
          justifyContent="start"
        />
      ),
      dataIndex: "project",
      render: (text) => <div className="text-table-ccus">{text}</div>,
      width: 130,
    },
    {
      title: (
        <SortTable
          columnTitle="所属事業者名"
          sortOrder={handler.funcCheckSortOrder("company", sortType, sortBy)}
          onChange={(sortOrder) => handler.funcSortTable("company", sortOrder)}
          justifyContent="start"
        />
      ),
      dataIndex: "company",
      render: (text) => <div className="text-table-ccus">{text}</div>,
      width: 140,
    },
    {
      title: (
        <SortTable
          columnTitle="技能者名"
          sortOrder={handler.funcCheckSortOrder("user", sortType, sortBy)}
          onChange={(sortOrder) => handler.funcSortTable("user", sortOrder)}
          justifyContent="start"
        />
      ),
      dataIndex: "user",
      render: (text) => <div className="text-table-ccus">{text}</div>,
      width: 135,
    },
    {
      title: (
        <SortTable
          columnTitle="対象日"
          sortOrder={handler.funcCheckSortOrder(
            "attendanceDate",
            sortType,
            sortBy
          )}
          onChange={(sortOrder) =>
            handler.funcSortTable("attendanceDate", sortOrder)
          }
          justifyContent="start"
        />
      ),
      dataIndex: "attendanceDate",
      render: (text) => <div className="text-table-ccus">{text}</div>,
      width: 120,
    },
    {
      title: (
        <SortTable
          columnTitle="出勤時間"
          sortOrder={handler.funcCheckSortOrder("checkin", sortType, sortBy)}
          onChange={(sortOrder) => handler.funcSortTable("checkin", sortOrder)}
          justifyContent="start"
        />
      ),
      dataIndex: "checkin",
      render: (text) => <div className="text-table-ccus">{text}</div>,
      width: 110,
    },
    {
      title: (
        <SortTable
          columnTitle="退勤時間"
          sortOrder={handler.funcCheckSortOrder("checkout", sortType, sortBy)}
          onChange={(sortOrder) => handler.funcSortTable("checkout", sortOrder)}
          justifyContent="start"
        />
      ),
      dataIndex: "checkout",
      render: (text) => <div className="text-table-ccus">{text}</div>,
      width: 110,
    },
    {
      title: (
        <SortTable
          columnTitle="登録区分"
          sortOrder={handler.funcCheckSortOrder(
            "registerType",
            sortType,
            sortBy
          )}
          onChange={(sortOrder) =>
            handler.funcSortTable("registerType", sortOrder)
          }
          justifyContent="start"
        />
      ),
      width: 120,
      dataIndex: "registerType",
      render: (text) => <div className="text-table-ccus">{text}</div>,
    },
  ];

  const rowSelection = {
    selectedRowKeys: handler.selectedRowsKey,
    onChange: (selectedRowKeys: React.Key[]) => {
      handler.getSelectedKey(selectedRowKeys);
    },
  };
  return {
    columnsTable,
    rowSelection,
  };
};
