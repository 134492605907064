import { createSlice } from "@reduxjs/toolkit";
import { AM028SliceEntity } from "../type/Presenter";

const initialState: AM028SliceEntity = {};

export const am028Slice = createSlice({
  name: "am028",
  initialState,
  reducers: {},
});

export const {} = am028Slice.actions;

export default am028Slice.reducer;
