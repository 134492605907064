import React from "react";

// Image
import analogBg from "../../../assets/images/analog.png";

const ClockComponent = () => {
  const [timer, setTimer] = React.useState(new Date());

  React.useEffect(() => {
    const interval = setInterval(() => {
      setTimer(new Date());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="am010__clock">
      <img src={analogBg} alt="analog background" />
      <div
        className="hour_hand"
        style={{
          transform: `rotateZ(${timer.getHours() * 30}deg)`,
        }}
      />
      <div
        className="min_hand"
        style={{
          transform: `rotateZ(${timer.getMinutes() * 6}deg)`,
        }}
      />
      <div
        className="sec_hand"
        style={{
          transform: `rotateZ(${timer.getSeconds() * 6}deg)`,
        }}
      />
    </div>
  );
};

export default ClockComponent;
