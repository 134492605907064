import React from 'react';
import CM0013Api from './infrastructure/api/Api';
import CM0013Handler from './presenter/handler/Handler';
import CM0013Service from './usecase/Service';
import MainUI from './presenter/ui/UI';
import '../../../assets/styles/AM/AM028.css';
import '../../../assets/styles/AM/AM002.css';
import '../../../assets/styles/OM/OM002.css';
import '../../../assets/styles/CM/cm3854.css';
import '../../../assets/styles/CM/CM007.css';

const CM0013Component = () => {
    const api = CM0013Api();

    const service = CM0013Service(api);

    const handler = CM0013Handler(service);

    return <MainUI handler={handler} />;
};

export default CM0013Component;
