import React, { useEffect } from "react";
import { Handler } from "../handler/HandlerImpl";
import { Modal, Button, Row, Col, Spin } from "antd";
import Table from "./Table";
import { RootState } from "../../../../../store";
import { useDispatch, useSelector } from "react-redux";
import LoadingComponent from "../../../../../common/components/icons/LoadingComponent";
import XIconModal from "../../../../../assets/icons/x-icon-modal.svg";
import IconCircleCheck from "../../../../../assets/icons/akar-icons_circle-check-fill.svg";
import { STATUS_CM_CHILD_TABLE } from "../../../../../common/constants/COMMON";
import { resetCM0029 } from "../slice/Slice";
import ApprovalFlow from "../../../../../common/components/approval-flow/ApprovalFlow";
import ApproveConfirmModal from "../../../../../common/components/modal-approve-confirm";
import useWindowDimensions from "../../../../../common/helpers/windowDimension";

interface props {
  handler: Handler;
  afterSuccess?: () => void;
  onSubmit?: () => void;
  onReject?: () => void;
}

function MainUI({ handler, afterSuccess, onSubmit, onReject }: props) {
  const dispatch = useDispatch();

  const visibleCM0029 = useSelector(
    (state: RootState) => state.cm0029.isVisibleCM0029
  );
  const documentId = useSelector((state: RootState) => state.cm0029.documentId);
  const documentInfo = useSelector(
    (state: RootState) => state.cm0029.documentInfo
  );
  const isDisableRetrieveButton = useSelector(
    (state: RootState) => state.cm0029.isDisableRetrieveButton
  );

  useEffect(() => {
    if (documentId) handler.getProjectAlls(documentId);
  }, [documentId]);

  useEffect(() => {
    if (documentId && visibleCM0029)
      Promise.all([
        handler.getReSummarySubmittedProjectLaborCostTotal({
          documentId: documentId,
        }),
        handler.getWorkflowSummaryLaborApprovalProcess(documentId),
      ]);
    return () => {
      dispatch(resetCM0029());
    };
  }, [documentId, visibleCM0029]);
  const { width } = useWindowDimensions();

  return (
    <Modal
      style={{ marginLeft: width > 1872 ? 300 : 0, marginTop: 40, marginBottom: 40 }}
      className="group-detail-modal modal-fixed-1572"
      visible={visibleCM0029}
      centered={width > 1700}
      maskClosable={false}
      width={1572}
      closable={false}
      onCancel={handler.cancelModalCM0029}
      footer={
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {onSubmit && onReject ? (
            <>
              {/* DONE */}
              <ApproveConfirmModal onSubmit={onSubmit} />
              <Button
                className="button-purple cm047-btn-submit"
                icon={<img alt="" src={IconCircleCheck} />}
                size="large"
                onClick={handler.showConfirmApproveModal}
                disabled={
                  !(
                    documentInfo?.status ===
                    STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE
                  )
                }
                style={{ marginLeft: 16 }}
              >
                承認
              </Button>
              <Button
                size="large"
                className="button-warning cm047-btn-reject"
                onClick={onReject}
                disabled={
                  !(
                    documentInfo?.status ===
                    STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE
                  )
                }
              >
                否認
              </Button>
            </>
          ) : (
            <Button
              disabled={isDisableRetrieveButton}
              size="large"
              type="primary"
              className="button-purple cm047-btn-submit"
              style={{ marginLeft: 16, width: 120 }}
              onClick={() =>
                documentId
                  ? handler.putReSummaryProjectLaborCostRetrieve(
                    { documentId: documentId },
                    afterSuccess
                  )
                  : null
              }
            >
              取り下げ
            </Button>
          )}

          <Button
            size="large"
            style={{ marginLeft: 16, width: 118 }}
            onClick={handler.cancelModalCM0029}
          >
            キャンセル
          </Button>
        </div>
      }
    >
      <Spin indicator={LoadingComponent} spinning={handler.loading}>
        <div
          className="w-100-percent header-modal-containter"
          style={{ height: 50 }}
        >
          <div className="header-modal-title-om004 ">労務費</div>
          <div
            style={{ cursor: "pointer" }}
            onClick={handler.cancelModalCM0029}
          >
            <img alt="" src={XIconModal} style={{ height: 18, width: 18 }} />
          </div>
        </div>
        <div style={{ marginTop: 24, marginBottom: 8 }}>
          <ApprovalFlow
            isNotCheckingUser={true}
            breadcrumb={[]}
            listApprove={handler.workflow}
            currentUserApproval={handler.currentUserApproval}
          />
        </div>
        <div
          style={{ margin: "24px 24px 24px 24px", backgroundColor: "#ffffff" }}
        >
          <Row style={{ marginBottom: 24 }}>
            <Col span={8} style={{ display: "flex" }}>
              <div style={{ marginRight: 24 }} className="CM0017_3-info-title">
                資料名
              </div>
              <div className="CM0017_3-info-content">
                {documentInfo.documentTitle}
              </div>
            </Col>
            <Col span={8} style={{ display: "flex" }}>
              <div style={{ marginRight: 24 }} className="CM0017_3-info-title">
                作成日
              </div>
              <div className="CM0017_3-info-content">
                {documentInfo.createdDate}
              </div>
            </Col>
            <Col span={8} style={{ display: "flex" }}>
              <div style={{ marginRight: 24 }} className="CM0017_3-info-title">
                対象期間
              </div>
              <div className="CM0017_3-info-content">
                {documentInfo.targetPeriod}
              </div>
            </Col>
          </Row>
          <Table
            summaryTotal={handler.summaryTotal}
            handleScroll={handler.handleScroll}
            projects={handler.projects}
            checkKeyExpand={handler.checkKeyExpand}
            setExpandKeys={handler.setExpandKeys}
          />
        </div>
      </Spin>
    </Modal>
  );
}

export default MainUI;
