import { OffsetCostPayload, ReceivedMoneyOffset } from "../entity/Entity";
import { APIImpl } from "./ServiceImpl";

const Service = (api: APIImpl) => {
  const postReSummaryCompanyOutsourceCostOffsetCreate = async (
    data: OffsetCostPayload
  ): Promise<any> => {
    const dataSource = await api.postReSummaryCompanyOutsourceCostOffsetCreate(
      data
    );
    return dataSource.data;
  };
  const getMoneyReceivedMoneyOffsetSubCategory = async (): Promise<any> => {
    const dataSource = await api.getMoneyReceivedMoneyOffsetSubCategory();
    return dataSource.data;
  };
  return {
    postReSummaryCompanyOutsourceCostOffsetCreate,
    getMoneyReceivedMoneyOffsetSubCategory,
  };
};

export default Service;
