import PM005Api from "../PM005/infrastructure/api/Api";
import PM005Service from "../PM005/usecase/Service";
import PM008Api from "../PM008/infrastructure/api/Api";
import PM008Handler from "../PM008/presenter/handler/Handler";
import PM008Service from "../PM008/usecase/Service";
import PM003Api from "./infrastructure/api/Api";
import PM003Handler from "./presenter/handler/Handler";
import PM003UI from "./presenter/ui";
import PM003Service from "./usecase/Service";

const PM003Component = () => {
    const pm003Api = PM003Api();
    const pm005Api = PM005Api();
    const pm008Api = PM008Api();

    const pm003Service = PM003Service(pm003Api);
    const pm005Service = PM005Service(pm005Api);
    const pm008Service = PM008Service(pm008Api);

    const pm003Handler = PM003Handler(pm003Service, pm005Service);
    const pm008Handler = PM008Handler(pm008Service);

    return <PM003UI handler={pm003Handler} modalHandler={pm008Handler} />

}

export default PM003Component