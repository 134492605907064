import React from 'react';
import moment from 'moment';
import COMMON from '../../../../../common/constants/COMMON';
import helpers from '../../../../../common/helpers/common';
import { Empty } from 'antd';
import TooltipText from '../../../../../common/components/tooltip-text/TooltipText';
import MESSAGE from '../../../../../common/constants/MESSAGE';

type props = {
    costDetailData: any[];
};

const CostTableComponent = ({ costDetailData }: props) => {
    return (
        <React.Fragment>
            <tr className="bg-fa">
                <th colSpan={2}></th>
                <th>工事名</th>
                <th>担当者</th>
                <th>対象期間</th>
                <th>支払日</th>
                <th></th>
                <th></th>
                <th></th>
                <th style={{ width: '40px', textAlign: 'center', marginTop: 13 }}></th>
            </tr>
            {costDetailData.length === 0 ? (
                <tr>
                    <td colSpan={10}>
                        <Empty
                            style={{ width: '100%' }}
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={MESSAGE.MESSAGE_DATA_NOT_FOUND}
                        />
                    </td>
                </tr>
            ) : (
                costDetailData.map((element, index) => (
                    <tr key={`table${element.id}`} className="bg-fa">
                        <td>
                            <div style={{ width: 25 }}></div>
                        </td>
                        <td>
                            <div style={{ width: 25 }}>{index + 1}</div>
                        </td>
                        {/* 工事名 :Tên công trình ở màn PM005, sắp xếp theo thời gian tạo mới nhất tới muộn nhất */}
                        <td>
                            <div style={{ width: 150 }}>
                                <TooltipText content={element?.construction?.description ?? ''} />
                            </div>
                        </td>

                        {/* 担当者
                    Hiển thị tên người phụ trách ở giấy dự toán CR001_thông tin tiền mới nhất và đã được duyệt
                    Sắp xếp theo thứ tự trong giấy dự toán */}
                        <td>
                            <div style={{ width: 150 }}>
                                <TooltipText content={element?.manager?.fullName ?? ''} />
                            </div>
                        </td>

                        {/* 対象期間
                    Lấy giá trị trường 対象期間 có ngày 支払日 với status 承認済 ở màn CM0013, lấy những 対象期間 có ngày 支払日 thuộc phạm vi filter tại mục #11.6 */}
                        <td>{helpers.getStartEndDate(element.startDate, element.endDate)}</td>

                        {/* 支払日
                    Lấy ngày 支払日 ở màn CM0013 theo 対象期間 bên trên */}
                        <td>
                            {element.paymentDate
                                ? moment(element.paymentDate).format(COMMON.FORMAT_DATE_OM)
                                : ''}
                        </td>

                        {/* 支払金額(税抜)
                    Lấy số tiền của trường 実費用 theo 対象期間 bên trên */}
                        <td>{helpers.formatNumberMoney(element?.actualCost ?? 0)}円 </td>

                        {/* 消費税
                    Công thức: 支払金額(税抜) x % thuế (thuế setting ở màn setting) */}
                        <td>{helpers.formatNumberMoney(element?.tax ?? 0)}円 </td>

                        {/* 支払金額(税込)
                    Công thức: 支払金額(税抜) + 消費税 */}
                        <td>
                            {helpers.formatNumberMoney(
                                (element?.actualCost ?? 0) + (element?.tax ?? 0),
                            )}
                            円
                        </td>
                        <td style={{ width: '40px' }}></td>
                    </tr>
                ))
            )}
        </React.Fragment>
    );
};

export default CostTableComponent;
