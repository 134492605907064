import { createSlice } from "@reduxjs/toolkit";
import { PM025SliceEntity } from "../type/Presenter";

const initialState: PM025SliceEntity = {
  isRefreshPM025: false,
};

export const pm025Slice = createSlice({
  name: "pm025",
  initialState,
  reducers: {
    toggleIsRefreshPM025: (state) => {
      state.isRefreshPM025 = !state.isRefreshPM025;
    },
  },
});

export const { toggleIsRefreshPM025 } = pm025Slice.actions;

export default pm025Slice.reducer;
