import { notification } from "antd";
import { NotificationPlacement } from "antd/lib/notification";
import ErrorIcon from "../../../assets/icons/discount-circle.svg";

const ErrorNotification = (message: string, description?: string) => {
  const placement: NotificationPlacement = "topRight";
  notification.open({
    duration: 2,
    icon: <img alt="" src={ErrorIcon} style={{ marginBottom: 10 }} />,
    message: message,
    description: description,
    placement,
    style: {
      borderTop: "2px solid #EB4D4D",
    },
  });
};

export default ErrorNotification;
