import moment from "moment";
import helpers from "../../../../../common/helpers/common";
import { CM017_2_1SliceType } from "./../type/Presenter";
import { createSlice } from "@reduxjs/toolkit";

const initialState: CM017_2_1SliceType = {
  loading: false,
  isVisible: false,
  refresh: false,
  parentCode: {
    from: helpers.getFilterTimeMonth(moment()),
    to: helpers.getFilterTimeMonthTo(moment()),
  },
  isUpdate: true,
  typeModal: true,
  modalTitle: null,
};

export const cm017_2Slice = createSlice({
  name: "cm017_2",
  initialState,
  reducers: {
    resetCM017_2_1: () => initialState,
    setLoadingCM017_2_1: (state, action) => {
      state.loading = action.payload;
    },
    setIsVisibleCM017_2_1: (state, action) => {
      state.isVisible = action.payload;
    },
    setRefreshCM017_2_1: (state) => {
      state.refresh = !state.refresh;
    },
    setTypeModalCM017_2_1: (state, action) => {
      state.typeModal = action.payload;
    },
    setParentCodeCM017_2_1: (state, action) => {
      state.parentCode = action.payload;
    },
    setIsUpdate: (state, action) => {
      state.isUpdate = action.payload;
    },
    setModalTitleCM017_2_1: (state, action) => {
      state.modalTitle = action.payload;
    },
  },
});

export const {
  setIsUpdate,
  setLoadingCM017_2_1,
  setIsVisibleCM017_2_1,
  resetCM017_2_1,
  setRefreshCM017_2_1,
  setTypeModalCM017_2_1,
  setParentCodeCM017_2_1,
  setModalTitleCM017_2_1,
} = cm017_2Slice.actions;

export default cm017_2Slice.reducer;
