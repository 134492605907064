import React from 'react';
import { OrderStatus, PlanSettingDetail } from '../../../entity/Entity';
import { FormInstance } from 'antd';
import { Handler } from './Handler';
import { MainUI } from './UI';

interface props {
    form: FormInstance;
    planDetail?: PlanSettingDetail;
    statusOrder: OrderStatus[];
    formName: string;
    data?: any;
    deleteAPlanFunction(id: string, name: string): void;
    getChosenPlanFunctionOnFormChange: () => void
}
const PlanFunction = (props: props) => {
    const handler = Handler();
    return <MainUI {...{ ...props, handler: handler }} />;
};

export default PlanFunction;
