import { CHECKIN, CHECKOUT } from './../AM004/presenter/component/attendance/helper';
import helpers from '../../../common/helpers/common';

const convertUserTimeSheetData = (timesheet: any[]) => {
    // get company id
    const user = helpers.getObjectFromLocalStorage('user');
    const companyId = user.company.id;

    const companyTimeSheet = timesheet.map((element) => {
        let isDoubleCheck: boolean | null = null;
        if (element.doubleCheck) {
            const foundDoubleCheck = element.doubleCheck.find(
                (ele: any) =>
                    element?.category?.code === ele.categoryCode && ele.companyId === companyId,
            );
            if (foundDoubleCheck) isDoubleCheck = foundDoubleCheck?.approve ? true : false;
        }

        return { ...element, isDoubleCheck };
    });

    const checkin: any[] = companyTimeSheet.filter(
        (element: any) => element.category.code === CHECKIN,
    );
    const checkout: any[] = companyTimeSheet.filter(
        (element: any) => element.category.code === CHECKOUT,
    );

    const maxRow = checkin.length > checkout.length ? checkin.length : checkout.length;
    const sttArray = [...Array(maxRow).keys()];
    return { sttArray, checkin, checkout };
};
export { convertUserTimeSheetData };
