import { OM006SliceType } from "./../type/Presenter";
import { createSlice } from "@reduxjs/toolkit";

const initialState: OM006SliceType = {
  loading: false,
  isVisible: false,
  memberId: null,
  isHasUpdatedOM006: false,
};

export const om006Slice = createSlice({
  name: "om006",
  initialState,
  reducers: {
    resetOM006: (state) => {
      state.loading = initialState.loading;
      state.isVisible = initialState.isVisible;
      state.memberId = initialState.memberId;
      state.isHasUpdatedOM006 = !state.isHasUpdatedOM006;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsVisibleOM006: (state, action) => {
      state.isVisible = action.payload;
    },
    setMemberIdOM006: (state, action) => {
      state.memberId = action.payload;
    },
    toggleIsHasUpdatedOM006: (state) => {
      state.isHasUpdatedOM006 = !state.isHasUpdatedOM006;
    },
  },
});

export const {
  setLoading,
  setIsVisibleOM006,
  resetOM006,
  setMemberIdOM006,
  toggleIsHasUpdatedOM006,
} = om006Slice.actions;

export default om006Slice.reducer;
