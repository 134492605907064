import AM016Api from "./infrastructure/api/Api";
import AM016Handler from "./presenter/handler/Handler";
import AM016UI from "./presenter/ui/UI";
import AM016Service from "./usecase/Service";

const AM016Component = () => {
  const am016Api = AM016Api();
  const am016Service = AM016Service(am016Api);
  const am016Handler = AM016Handler(am016Service);
  return <AM016UI handler={am016Handler} />;
};

export default AM016Component;
