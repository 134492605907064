import React, { useEffect, useMemo } from "react";
import { HandlerImpl } from "../handler/HandlerImpl";
import IconDown from "../../../../../assets/icons/icon-collapse-cm.svg";
import EmptyData from "../../../../../assets/icons/empty-data.svg";
import helpers from "../../../../../common/helpers/common";
import moment from "moment";
import COMMON from "../../../../../common/constants/COMMON";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import TooltipText from "../../../../../common/components/tooltip-text/TooltipText";
import { Empty } from "antd";

interface props {
  handler: HandlerImpl;
}

const NO_DATA = "データなし";

const Table = ({ handler }: props) => {
  const loading = useSelector((state: RootState) => state.common.loading);
  const contentTable = useMemo(() => {
    const sortedDataView = handler.dataView?.sort((a, b: any ) => {
      return moment(b.createdAt).diff(a.createdAt);
    });
    if (!handler.dataView || handler.dataView.length === 0)
      return !loading ? (
        <tr>
          <td colSpan={10}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={NO_DATA} />
          </td>
        </tr>
      ) : null;
    const elementTable: any = [];
    sortedDataView.forEach((element, index) => {
      elementTable.push(
        <React.Fragment key={element.key}>
          <tr style={{ height: 4 }}></tr>
          <tr
            style={{
              cursor: "pointer",
            }}
            className="row-level-0"
            onClick={() =>
              handler.funcSetExpendKey(element.key, "level1", element.id)
            }
          >
            <td colSpan={2}>{index + 1}</td>
            <td colSpan={4} style={{ textAlign: "left", paddingLeft: 82 }}>
              <TooltipText content={element.name} />
            </td>
            <td>
              {helpers.formatCurrency(element.paymentAmountExcludeTax)}円{" "}
            </td>
            <td>{helpers.formatCurrency(element.consumptionTax)}円 </td>
            <td>{helpers.formatCurrency(element.taxInclude)}円</td>
            <td style={{ padding: "15px 0" }}>
              <img
                alt=""
                src={IconDown}
                className={`cm024-icon ${handler.funcCheckKeyExpand(element.key)
                  ? ""
                  : "cm024-icon-active"
                  }`}
              />
            </td>
          </tr>
        </React.Fragment>
      );

      if (element.children && element.children.length > 0) {
        if (handler.funcCheckKeyExpand(element.key))
          element.children.forEach((child1: any) => {
            elementTable.push(
              <tr
                key={child1.key}
                className="bg-fa"
                onClick={() =>
                  handler.funcSetExpendKey(
                    child1.key,
                    "level2",
                    child1.id,
                    element.id
                  )
                }
                style={{
                  cursor: "pointer",
                }}
              >
                <td colSpan={2}>{child1.no}</td>
                <td colSpan={3} style={{ textAlign: "left", paddingLeft: 82 }}>
                  <TooltipText content={child1.projectName} />
                </td>

                <td></td>
                <td>
                  {helpers.formatCurrency(child1.paymentAmountExcludeTax)}円
                </td>
                <td>{helpers.formatCurrency(child1.consumptionTax)}円</td>
                <td>{helpers.formatCurrency(child1.taxInclude)}円</td>

                <td
                  style={{
                    textAlign: "center",
                    marginTop: 13,
                    padding: "15px 0",
                  }}
                >
                  <img
                    alt=""
                    src={IconDown}
                    className={`cm0028-icon ${handler.funcCheckKeyExpand(child1.key)
                      ? ""
                      : "cm0028-icon-active"
                      }`}
                    onClick={() =>
                      handler.funcSetExpendKey(
                        child1.key,
                        "level2",
                        child1.id,
                        element.id
                      )
                    }
                  />
                </td>
              </tr>
            );
            if (
              handler.funcCheckKeyExpand(element.key) &&
              handler.funcCheckKeyExpand(child1.key)
            )
              elementTable.push(
                <tr key={`table${child1.key}`} className="bg-fa">
                  <td colSpan={2}></td>
                  <td colSpan={2}>工事名</td>
                  <td>対象期間</td>
                  <td>支払日</td>

                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              );
            if (child1.children && child1.children.length > 0) {
              child1.children.forEach((child2: any) => {
                if (
                  handler.funcCheckKeyExpand(element.key) &&
                  handler.funcCheckKeyExpand(child1.key)
                )
                  elementTable.push(
                    <tr key={child2.key}>
                      <td></td>
                      <td>{child2?.no}</td>
                      <td colSpan={2} className="wrap-text">
                        <TooltipText content={child2.constructionDescription} />
                      </td>
                      <td>{`${child2.startDate
                        ? moment(child2.startDate).format(
                          COMMON.FORMAT_DATE_OM
                        )
                        : ""
                        } - ${child2.endDate
                          ? moment(child2.endDate).format(COMMON.FORMAT_DATE_OM)
                          : ""
                        }`}</td>
                      <td>
                        {child2.paymentDate
                          ? moment(child2.paymentDate).format(
                            COMMON.FORMAT_DATE_OM
                          )
                          : ""}
                      </td>
                      <td>
                        {helpers.formatCurrency(child2.paymentAmountExcludeTax)}
                        円{" "}
                      </td>
                      <td>
                        {helpers.formatCurrency(child2.consumptionTax)}円{" "}
                      </td>
                      <td>{helpers.formatCurrency(child2.taxInclude)}円</td>
                      <td></td>
                    </tr>
                  );
              });
            } else {
              !loading &&
                elementTable.push(
                  <tr
                    key={`table${child1.key}nodata`}
                    style={{
                      display:
                        handler.funcCheckKeyExpand(element.key) &&
                          handler.funcCheckKeyExpand(child1.key)
                          ? undefined
                          : "none",
                    }}
                  >
                    <td colSpan={10}>
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={NO_DATA}
                      />
                    </td>
                  </tr>
                );
            }
          });
      } else {
        !loading &&
          elementTable.push(
            <tr
              key={`${element.key}nodata`}
              style={{
                display: handler.funcCheckKeyExpand(element.key)
                  ? undefined
                  : "none",
              }}
            >
              <td colSpan={10}>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={NO_DATA}
                />
              </td>
            </tr>
          );
      }
    });
    return elementTable;
  }, [handler.dataView, handler.expandKey, loading]);

  useEffect(() => {
    const tableScroll = document.querySelector("#table-cm0020-scroll");
    if (
      tableScroll &&
      !handler.openFilter &&
      (helpers.getFilterTimeMonth(handler.from) !==
        handler.filterCurrent.from ||
        helpers.getFilterTimeMonthTo(handler.to) !== handler.filterCurrent.to)
    )
      tableScroll.scrollTop = 0;
  }, [handler.from, handler.to, handler.openFilter]);

  const totalTable = useMemo(() => {
    let totalPaymentAmountExcludeTax = 0;
    let totalConsumptionTax = 0;
    let totalTaxInclude = 0;
    for (const item of handler.dataView) {
      totalPaymentAmountExcludeTax += item.paymentAmountExcludeTax ?? 0;
      totalConsumptionTax += item.consumptionTax ?? 0;
      totalTaxInclude += item.taxInclude ?? 0;
    }
    return {
      totalPaymentAmountExcludeTax,
      totalConsumptionTax,
      totalTaxInclude,
    };
  }, [handler.dataView]);

  return (
    <div
      className="cm024-table"
      id="table-cm0020-scroll"
      onScroll={handler.handleScrollTable}
    >
      <table className="cm0020-table">
        <thead>
          <tr>
            <th colSpan={2} style={{ width: 110 }}>
              No
            </th>
            <th
              colSpan={2}
              style={{ textAlign: "left", paddingLeft: 82, width: 218 }}
            >
              業者名
            </th>
            <th className="none-bl" style={{ width: 280 }}></th>
            <th className="none-bl" style={{ width: 218 }}></th>
            <th style={{ width: 212.66 }}>支払金額(税抜)</th>
            <th style={{ width: 212.66 }}>消費税</th>
            <th style={{ width: 212.69 }}>支払金額(税込)</th>
            <th style={{ width: 40 }}></th>
          </tr>
        </thead>
        <tbody>{contentTable}</tbody>
        <tfoot>
          <tr className="table-foot">
            <td colSpan={2} style={{ width: 55 }}>
              合計金額
            </td>
            <td colSpan={4}></td>
            <td style={{ width: 170 }}>
              {helpers.formatCurrency(handler.summary.paymentAmountExcludeTax)}
              円
            </td>
            <td style={{ width: 170 }}>
              {helpers.formatCurrency(
                handler.summary.paymentAmountExcludeTax *
                (handler.summary?.taxSetting ?? 1),
                true
              )}
              円
            </td>
            <td style={{ width: 170 }}>
              {helpers.formatCurrency(
                handler.summary.paymentAmountExcludeTax *
                (handler.summary?.taxSetting ?? 1) +
                handler.summary.paymentAmountExcludeTax,
                true
              )}
              円
            </td>
            <td style={{ width: 40 }}></td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default Table;
