import { useDispatch, useSelector } from 'react-redux';
import ErrorNotification from '../../../../../common/components/notification/ErrorNotification';
import SuccessNotification from '../../../../../common/components/notification/SuccessNotification';
import { NOTIFICATION_TITLE } from '../../../../../common/constants/MESSAGE';
import { SM06UseCase } from '../../usecase/ServiceImpl';
import { resetSM06, setIsVisibleSM06, setLoading } from '../slice/Slice';
import { setIsVisibleSM04 } from '../../../SM04/presenter/slice/Slice';
import { refreshPage } from '../../../SM01/presenter/slice/Slice';

const SM06Handler = (sm06Service: SM06UseCase) => {
    // LIB FUNCTION
    const dispatch = useDispatch();
    // STATE
    // FUNCTION

    const deleteSchedule = async (scheduleId: number) => {
        try {
            dispatch(setLoading(true));
            const data = {
                id: scheduleId,
            };
            const res = await sm06Service.deleteSchedule(data);
            SuccessNotification(res?.data?.message ?? NOTIFICATION_TITLE.SUCCESS);
            dispatch(resetSM06());
            dispatch(setIsVisibleSM04(false));
            dispatch(refreshPage());
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        } finally {
            dispatch(setLoading(false));
        }
    };

    const onCancel = () => {
        return dispatch(setIsVisibleSM06(false));
    };

    return {
        onCancel,
        deleteSchedule,
    };
};

export default SM06Handler;
