import {
  ConstructionCompanyType,
  ConstructionCountMember,
} from "./../type/Presenter";
import { RootState } from "./../../../../../store";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { PM011UseCase } from "../../usecase/ServiceImpl";
import { Form } from "antd";
import {
  hidePM011Modal,
  resetPM011,
  resetWithoutCloseModal,
  setConstructionCompanySlice,
  setConstructionCountMembers,
  setHardNumberChosenCheckBox,
  setIsCheckAll,
  setIsUpdating,
  setLoadingPM011,
} from "../slice/Slice";

import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../../common/constants/MESSAGE";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import {
  convertConstructionCompany,
  convertToPayload,
  sortConstructionList,
} from "./Helper";

export const TYPE_ASC = "ASC";
export const TYPE_DESC = "DESC";

const INITIAL_STATE = {
  isFetched: false,
  constructionCompany: [],
};

const PM011Handler = (pm011Service: PM011UseCase) => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  // SELECTOR
  const projectDetail = useSelector(
    (state: RootState) => state.pm011.projectDetail
  );

  const numberCheckbox = useSelector(
    (state: RootState) => state.pm011.numberCheckbox
  );

  // STATE
  const [isFetched, setIsFetched] = useState<boolean>(INITIAL_STATE.isFetched);
  const [constructionCompany, setConstructionCompany] = useState<
    ConstructionCompanyType[]
  >(INITIAL_STATE.constructionCompany);

  // FUNCTION
  const getConstructionList = async () => {
    if (!projectDetail.id) return;
    try {
      const params = {
        projectId: projectDetail.id,
      };
      const responseData = await pm011Service.getConstructionList(params);
      return sortConstructionList(responseData) || [];
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return [];
    }
  };

  const getCompanyWithConstruction = async (value: any[]) => {
    const results: any[] = [];
    for (let i = 0; i < value.length; i++) {
      if (!value[i].id) continue;
      try {
        const company = await pm011Service.getMyCompany({
          constructionId: value[i].id,
        });
        results.push({
          company: company,
          construction: value[i],
        });
      } catch (error) {}
    }
    return results;
  };

  const getConstructionCompany = async () => {
    if (isFetched) return;
    dispatch(setLoadingPM011(true));

    // call construction first
    const listConstruction = await getConstructionList();
    const constructionCompany = await getCompanyWithConstruction(
      listConstruction || []
    );

    // setup members count
    const constructionCountMembers: ConstructionCountMember[] = (
      listConstruction ?? []
    ).map((element) => {
      const result: ConstructionCountMember = {
        id: element.id,
        totalMember: 0,
      };
      return result;
    });
    dispatch(setConstructionCountMembers(constructionCountMembers));

    // set state
    setConstructionCompany(constructionCompany);
    dispatch(
      setConstructionCompanySlice(
        convertConstructionCompany(constructionCompany)
      )
    );
    setIsFetched(true);
    dispatch(setLoadingPM011(false));
  };

  const hidePM011 = () => {
    dispatch(hidePM011Modal());
  };

  const resetStatePM011 = () => {
    setConstructionCompany(INITIAL_STATE.constructionCompany);
    setIsFetched(INITIAL_STATE.isFetched);
    dispatch(resetPM011());
  };

  const onSubmit = async () => {
    dispatch(setLoadingPM011(true));
    try {
      const value = form.getFieldsValue();
      const { message } = await pm011Service.addToQueue({
        data: convertToPayload(value, constructionCompany),
      });
      setConstructionCompany(INITIAL_STATE.constructionCompany);
      setIsFetched(INITIAL_STATE.isFetched);
      dispatch(resetWithoutCloseModal());
      SuccessNotification(message ?? NOTIFICATION_TITLE.SUCCESS);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoadingPM011(false));
    }
  };

  const onCheckAll = (checked: any) => {
    if (checked) dispatch(setHardNumberChosenCheckBox(numberCheckbox));
    else dispatch(setHardNumberChosenCheckBox(0));
  };

  const setCheckAllValue = (value: boolean) => {
    dispatch(setIsCheckAll(value));
  };

  const onBackUpdating = () => dispatch(setIsUpdating(true));

  const onFinishUpdating = () => dispatch(setIsUpdating(false));

  return {
    getConstructionCompany,
    hidePM011,
    onSubmit,
    onCheckAll,
    onFinishUpdating,
    onBackUpdating,
    setCheckAllValue,
    resetStatePM011,
    form,
    isFetched,
    constructionCompany,
  };
};

export default PM011Handler;
