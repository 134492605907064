import { AxiosResponse } from "axios";
import axiosConfig from "../../../../../config/axiosConfig";
import { ReSummaryProjectLaborCostRetrievePayload } from "../../entity/Entity";

const CM0029Api = () => {
  const getReSummarySubmittedProjectLaborCost = async (params: {
    documentId: number;
    projectId: number;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/re-summary/submitted/project/labor-cost";
    return await axiosConfig.get(url, { params });
  };
  const getReSummarySubmittedLaborCostProjectList = async (params: {
    documentId: number;
    page?: number;
    size?: number;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/re-summary/submitted/labor-cost/project/list";
    return await axiosConfig.get(url, { params });
  };
  const getReSummarySubmittedProjectLaborCostTotal = async (params: {
    documentId: number;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/re-summary/submitted/project/labor-cost/total";
    return await axiosConfig.get(url, { params });
  };
  const putReSummaryProjectLaborCostRetrieve = async (
    data: ReSummaryProjectLaborCostRetrievePayload
  ): Promise<AxiosResponse<any, any>> => {
    const url = "/re-summary/project/labor-cost/retrieve";
    return await axiosConfig.put(url, data);
  };
  const getWorkflowSummaryLaborApprovalProcess = async (params: {
    documentId: number;
  }): Promise<any> => {
    const url = "/workflow/summary/labor/approval-process";
    return await axiosConfig.get(url, { params });
  };
  return {
    putReSummaryProjectLaborCostRetrieve,
    getReSummarySubmittedProjectLaborCostTotal,
    getReSummarySubmittedProjectLaborCost,
    getReSummarySubmittedLaborCostProjectList,
    getWorkflowSummaryLaborApprovalProcess,
  };
};

export default CM0029Api;
