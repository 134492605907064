// Component
import BreadcrumbComponent from '../../../../../common/components/breadcrumb/BreadcrumbComponent';
import MenuWrapperComponent from '../../../../../common/components/menu-wrapper';
import HeaderUnit from './Header';

// Service
import { AOP004Handler } from '../handler/HandlerImpl';

// Media
import '../../../../../assets/styles/AOP/AOP001/wrapper.css';
import ContentUnit from './Content';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import H4Modal from '../../../../../common/components/modal-H4/H4Modal';
import { useSearchParams } from 'react-router-dom';
import {
    GROUP_ROLE_ID,
    GROUP_ROLE_NAME,
    KEYWORD_PARAM,
    PAGE,
    SWITCH_TAB_KEY,
    SWITCH_TAB_ONE,
} from '../../contants';
import COMMON from '../../../../../common/constants/COMMON';
import H1Modal from '../../../../../common/components/modal-H1/H1Modal';
import H2Modal from '../../../../../common/components/modal-H2/H2Modal';
import H3Modal from '../../../../../common/components/modal-H3/H3Modal';
import HomeSvg from '../../../../../assets/icons/home.svg';

type props = {
    handler: AOP004Handler;
};

const MainUI = ({ handler }: props) => {
    const BREADCRUMB_AOP001 = [
        {
            title: '設定',
            url: '',
            icon: (
                <img style={{ paddingBottom: 2, paddingRight: 8 }} alt="" src={HomeSvg} />
            ),
        },
        {
            title: '役職・権限',
            url: '/app-aop001',
            icon: <></>,
        },
        {
            title: '役職・権限詳細',
            url: '',
            icon: <></>,
        },
    ];

    const h4 = useSelector((state: RootState) => state.h4Modal.isOpenH4);
    const h1 = useSelector((state: RootState) => state.h1Modal.isOpenH1);
    const h2 = useSelector((state: RootState) => state.h2Modal.isOpenH2);
    const h3 = useSelector((state: RootState) => state.h3Modal.isOpenH3);

    const [searchParams] = useSearchParams();
    const tabkey = searchParams.get(SWITCH_TAB_KEY) ?? SWITCH_TAB_ONE;
    const groupRoleId = searchParams.get(GROUP_ROLE_ID) ?? '';
    const groupRoleName = searchParams.get(GROUP_ROLE_NAME) ?? '';
    const page = searchParams.get(PAGE) ?? COMMON.DEFAULT_PAGE;
    const keyword = searchParams.get(KEYWORD_PARAM) ?? '';

    return (
        <MenuWrapperComponent chosenKey={71} openKey={7}>
            <BreadcrumbComponent data={BREADCRUMB_AOP001} />
            <div className="aop--001__wrapper">
                <HeaderUnit handler={handler} roleId={groupRoleId} roleName={groupRoleName} />
                <ContentUnit
                    handler={handler}
                    groupRoleId={groupRoleId}
                    groupRoleName={groupRoleName}
                    page={String(page)}
                    keyword={keyword}
                    tabKey={tabkey}
                />
                {h4 ? <H4Modal /> : null}
                {h1 ? <H1Modal /> : null}
                {h2 ? <H2Modal /> : null}
                {h3 ? <H3Modal /> : null}
            </div>
        </MenuWrapperComponent>
    );
};

export default MainUI;
