import AOP001Api from './infrastructure/api/Api';
import AOP001Handler from './presenter/handler/Handler';
import AOP001UI from './presenter/ui/UI';
import AOP001Service from './usecase/Service';

const AOP001Component = () => {
    const aop001Api = AOP001Api();
    const aop001Service = AOP001Service(aop001Api);
    const aop001Handler = AOP001Handler(aop001Service);
    return <AOP001UI handler={aop001Handler} />;
};

export default AOP001Component;
