import React from "react";

import IconI from "../../../../../assets/icons/icon-i-aop.svg";
import { Table, Form, Checkbox } from "antd";
import { HandlerImpl } from "../handler/HandlerImpl";
import { TYPE_I } from "../../constant";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { AUTHORIZATION_CODE } from "../../../../../common/constants/AUTHORIZATION";

const { Item } = Form;
interface props {
  handler: HandlerImpl;
}

function WholeSystem({ handler }: props) {
  const subscription =
    useSelector(
      (state: RootState) => state.auth?.authData?.order?.subscription
    ) ?? [];
  const isM19 = subscription.some(
    (element: any) => element.code === AUTHORIZATION_CODE.M19
  );
  const isM18 = subscription.some(
    (element: any) => element.code === AUTHORIZATION_CODE.M18
  );
  const isM17 = subscription.some(
    (element: any) => element.code === AUTHORIZATION_CODE.M17
  );
  const columns = [
    {
      title: "No",
      key: "no",
      width: 112,
      render: (row: any) => {
        return row.no ?? "-";
      },
    },
    {
      title: "機能",
      key: "name",
      width: 400,
      render: (row: any) => {
        return row.name ?? "-";
      },
    },
    {
      title: "",
      key: "empty1",
      width: 257,
    },
    {
      title: "追加・編集",
      key: "add-edit",
      width: 257,
      render: (row: any) => {
        if (row.column?.includes("4")) {
          const findIndex = row.column.findIndex((ele: any) => ele === "4");
          return (
            <Item valuePropName="checked" name={row.code[findIndex]}>
              <Checkbox />
            </Item>
          );
        }
        return "";
      },
    },
    {
      title: "",
      key: "empty2",
    },
  ];
  const columns2 = [
    {
      title: "No",
      key: "no",
      width: 112,
      render: (row: any) => {
        return row.no ?? "-";
      },
    },
    {
      title: "機能",
      key: "name",
      width: 400,
      render: (row: any) => {
        return row.name ?? "-";
      },
    },
    {
      title: "閲覧",
      key: "browse",
      width: 257,
      render: (row: any) => {
        if (row.column?.includes("3")) {
          const findIndex = row.column.findIndex((ele: any) => ele === "3");
          return (
            <Item valuePropName="checked" name={row.code[findIndex]}>
              <Checkbox />
            </Item>
          );
        }
        return "";
      },
    },
    {
      title: "作成",
      key: "create",
      width: 257,
      render: (row: any) => {
        if (row.column?.includes("4")) {
          const findIndex = row.column.findIndex((ele: any) => ele === "4");
          return (
            <Item valuePropName="checked" name={row.code[findIndex]}>
              <Checkbox />
            </Item>
          );
        }
        return "";
      },
    },
    {
      title: "",
      key: "empty2",
    },
  ];

  const columns3 = [
    {
      title: "No",
      key: "no",
      width: 112,
      render: (row: any) => {
        return row.no ?? "-";
      },
    },
    {
      title: "機能",
      key: "name",
      width: 400,
      render: (row: any) => {
        return row.name ?? "-";
      },
    },
    {
      title: "閲覧",
      key: "browse",
      width: 257,
      render: (row: any) => {
        if (row.column?.includes("3")) {
          const findIndex = row.column.findIndex((ele: any) => ele === "4");
          return (
            <Item valuePropName="checked" name={row.code[findIndex]}>
              <Checkbox />
            </Item>
          );
        }
        return "";
      },
    },
    {
      title: "追加・編集",
      key: "add-edit",
      width: 257,
      render: (row: any) => {
        if (row.column?.includes("4")) {
          const findIndex = row.column.findIndex((ele: any) => ele === "3");
          return (
            <Item valuePropName="checked" name={row.code[findIndex]}>
              <Checkbox />
            </Item>
          );
        }
        return "";
      },
    },
    {
      title: "",
      key: "empty2",
    },
  ];

  const columns4 = [
    {
      title: "No",
      key: "no",
      width: 112,
      render: (row: any) => {
        return row.no ?? "-";
      },
    },
    {
      title: "機能",
      key: "name",
      width: 400,
      render: (row: any) => {
        return row.name ?? "-";
      },
    },
    {
      title: "閲覧",
      key: "browse",
      width: 257,
      render: (row: any) => {
        if (row.column?.includes("3")) {
          const findIndex = row.column.findIndex((ele: any) => ele === "3");
          return (
            <Item valuePropName="checked" name={row.code[findIndex]}>
              <Checkbox />
            </Item>
          );
        }
        return "";
      },
    },
    {
      title: "",
      key: "empty1",
      width: 257,
    },
    {
      title: "",
      key: "empty2",
    },
  ];

  const columns5 = [
    {
      title: "No",
      key: "no",
      width: 112,
      render: (row: any) => {
        return row.no ?? "-";
      },
    },
    {
      title: "機能",
      key: "name",
      width: 400,
      render: (row: any) => {
        return row.name ?? "-";
      },
    },
    {
      title: "閲覧",
      key: "browse",
      width: 257,
      render: (row: any) => {
        if (row.column?.includes("3")) {
          const findIndex = row.column.findIndex((ele: any) => ele === "3");

          return (
            <Item valuePropName="checked" name={row.code[findIndex]}>
              <Checkbox />
            </Item>
          );
        }
        return "";
      },
    },
    {
      title: "追加・編集",
      key: "add-edit",
      width: 257,
      render: (row: any) => {
        if (row.column?.includes("4")) {
          const findIndex = row.column.findIndex((ele: any) => ele === "4");
          return (
            <Item valuePropName="checked" name={row.code[findIndex]}>
              <Checkbox />
            </Item>
          );
        }
        return "";
      },
    },
    {
      title: "提出",
      key: "propose",
      render: (row: any) => {
        if (row.column?.includes("5")) {
          const findIndex = row.column.findIndex((ele: any) => ele === "5");
          return (
            <Item valuePropName="checked" name={row.code[findIndex]}>
              <Checkbox />
            </Item>
          );
        }
        return "";
      },
    },
  ];

  const columns6 = [
    {
      title: "No",
      key: "no",
      width: 112,
      render: (row: any) => {
        return row.no ?? "-";
      },
    },
    {
      title: "機能",
      key: "name",
      width: 400,
      render: (row: any) => {
        return row.name ?? "-";
      },
    },
    {
      title: "閲覧",
      key: "browse",
      width: 257,
      render: (row: any) => {
        if (row.column?.includes("3")) {
          const findIndex = row.column.findIndex((ele: any) => ele === "3");
          return (
            <Item name={row.code[findIndex]} valuePropName="checked">
              <Checkbox />
            </Item>
          );
        }
        return "";
      },
    },
    {
      title: "出力",
      key: "add-edit",
      width: 257,
      render: (row: any) => {
        if (row.column?.includes("4")) {
          const findIndex = row.column.findIndex((ele: any) => ele === "4");
          return (
            <Item name={row.code[findIndex]} valuePropName="checked">
              <Checkbox />
            </Item>
          );
        }
        return "";
      },
    },
    {
      title: "",
      key: "empty2",
    },
  ];
  return (
    <div className="aop-tab-list">
      <div className="aop-tab-item">
        <div className="aop-tab-title">
          <span>部門・メンバー</span>
        </div>
        <Table
          bordered
          pagination={false}
          dataSource={handler.dataTab2[0]}
          columns={columns}
        />
      </div>
      <div className="aop-tab-item">
        <div className="aop-tab-title">
          <span>案件管理</span>
        </div>
        <Table
          bordered
          pagination={false}
          dataSource={handler.dataTab2[1]}
          columns={columns2}
        />
      </div>
      <div className="aop-tab-item">
        <div className="aop-tab-title">
          <span>協力会社管理</span>
          <img
            src={IconI}
            alt=""
            onClick={() => handler.handleClickI(TYPE_I.h2)}
          />
        </div>
        <Table
          bordered
          pagination={false}
          dataSource={handler.dataTab2[2]}
          columns={columns3}
        />
      </div>
      <div className="aop-tab-item">
        <div className="aop-tab-title">
          <span>勤怠管理</span>
        </div>
        <Table
          bordered
          pagination={false}
          dataSource={handler.dataTab2[3]}
          columns={columns4}
        />
      </div>
      {isM17 || isM18 || isM19 ? (
        <div className="aop-tab-item">
          <div className="aop-tab-title">
            <span>収支管理</span>
            <img
              src={IconI}
              alt=""
              onClick={() => handler.handleClickI(TYPE_I.h3)}
            />
          </div>
          <Table
            bordered
            pagination={false}
            dataSource={handler.dataTab2[4]}
            columns={columns5}
          />
        </div>
      ) : (
        <></>
      )}
      <div className="aop-tab-item">
        <div className="aop-tab-title">
          <span>集計・出力</span>
        </div>
        <Table
          bordered
          pagination={false}
          dataSource={handler.dataTab2[5]}
          columns={columns6}
        />
      </div>
    </div>
  );
}

export default WholeSystem;
