import React, { useState } from "react";
import IconOption from "../../../../../assets/icons/icon-option.svg";
import IconSend from "../../../../../assets/icons/send.svg";
import IconEditBlue from "../../../../../assets/icons/edit-blue.svg";
import IconDelete from "../../../../../assets/icons/delete-red.svg";
import IconCancel from "../../../../../assets/icons/icon-cancel.svg";
import { DefaultAvatarComponent } from "../../../../../common/components/default-avatar";
import { Button, Form, Input, Popover } from "antd";
import { SM04Hanlder } from "../handler/HandlerImpl";
import InfiniteScroll from "react-infinite-scroll-component";
import { DataCommentType } from "../../entity/Entity";
import COMMON from "../../../../../common/constants/COMMON";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import helpers from "../../../../../common/helpers/common";
import { SYSTEM_ROLES } from "../../../../../common/constants/AUTHORIZATION";

type Props = {
  handler: SM04Hanlder;
};
const CommentComponent = ({ handler }: Props) => {
  const isRoleAdmin = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdmin
  );

  const authData = useSelector((state: RootState) => state.auth.authData);

  const [key, setKey] = React.useState<number>(Math.random());

  const onCancel = () => {
    setKey(Math.random());
  };
  const user = helpers.getObjectFromLocalStorage("user");

  return (
    <div className="sm04-comment-container">
      <div className="title-comment">コメント</div>
      {handler.listComment.length > 0 && (
        <InfiniteScroll
          dataLength={handler.listComment.length}
          hasMore={true}
          next={handler.handlerScrollInfinity}
          height={"20vh"}
          loader={null}
        >
          <div className="content-comment-wrapper">
            {handler.listComment.map((item: DataCommentType) => (
              <div className="content-comment" key={item.id}>
                <div className="information-content">
                  <div style={{ width: 54, height: 54 }}>
                    <DefaultAvatarComponent
                      isAdmin={item.composer.role.code === SYSTEM_ROLES.ADMIN}
                      avatar={item.composer.avatar ?? undefined}
                      value={item.composer.userName ?? ""}
                      size={54}
                    />
                  </div>
                  <div className="information" style={{ marginLeft: 16 }}>
                    <div className="text-name">{item.composer.userName}</div>
                    <div className="text-time">
                      {moment(item.createdAt).format(
                        COMMON.FORMAT_DATE_TIME_STRING
                      )}
                    </div>
                  </div>
                  <div className="btn-option">
                    {+user.id === item.composer.userId && (
                      <Popover
                        key={key}
                        placement="bottom"
                        onVisibleChange={(e: boolean) => {
                          if (!e) onCancel();
                        }}
                        content={
                          <div
                            style={{
                              width: 94,
                              height: 56,
                              padding: 16,
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <img
                              src={IconEditBlue}
                              alt=""
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                handler.edit(item);
                                setKey(item.id);
                              }}
                            />
                            <img
                              src={IconDelete}
                              alt=""
                              style={{ cursor: "pointer" }}
                              onClick={() => handler.deleteComment(item.id)}
                            />
                          </div>
                        }
                        trigger="click"
                        getPopupContainer={(trigger: any) => trigger.parentNode}
                      >
                        <img
                          src={IconOption}
                          alt=""
                          style={{ marginLeft: 8, cursor: "pointer" }}
                        />
                      </Popover>
                    )}
                  </div>
                </div>
                {handler.isEditing(item) ? (
                  <div style={{ marginLeft: 50 }}>
                    <div style={{ marginLeft: 16 }}>
                      <Form
                        form={handler.formEdit}
                        id="formCommentEdit"
                        onFinish={(e: any) => handler.editComment(e, item.id)}
                      >
                        <Form.Item
                          name={`commentUpdate${item.id}`}
                          rules={[{ required: true, message: "" }]}
                        >
                          <Input.TextArea
                            placeholder="コメントを入力"
                            style={{
                              height: 120,
                              width: "99%",
                              resize: "none",
                            }}
                            autoSize={false}
                            maxLength={5000}
                          />
                        </Form.Item>
                      </Form>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginRight: 7,
                      }}
                    >
                      <Button
                        htmlType="submit"
                        form="formCommentEdit"
                        size="large"
                        style={{
                          marginRight: 16,
                          width: 76,

                          border: "1px solid rgba(15, 164, 74, 0.50)",
                          borderRadius: "3px",
                          color: "#0FA44A",
                          letterSpacing: -1.5,
                        }}
                      >
                        保存
                      </Button>

                      <Button
                        onClick={() => handler.cancelEdit(item.id)}
                        size="large"
                        style={{
                          width: 118,
                          border: "1px solid #DAE2EE",
                          borderRadius: "3px",
                          color: "#666",
                          letterSpacing: -1.5,
                        }}
                      >
                        キャンセル
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="description-comment">
                    {item.content}&nbsp;&nbsp;
                    {item.createdAt != item.updatedAt ? (
                      <span className="edited-comment">(編集済み)</span>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </InfiniteScroll>
      )}
      <div className="information-content new-comment">
        <div style={{ width: 54, height: 54 }}>
          <DefaultAvatarComponent
            isAdmin={isRoleAdmin}
            avatar={authData?.avatar ?? undefined}
            value={authData?.fullName ?? ""}
            size={54}
          />
        </div>
        <div style={{ marginLeft: 16 }}>
          <Form
            form={handler.form}
            id="formComment"
            onFinish={(e: any) => handler.sendComment(e)}
          >
            <Form.Item
              name="newComment"
              rules={[{ required: true, message: "" }]}
            >
              <Input.TextArea
                placeholder="コメントを入力"
                style={{ height: 120, width: 770, resize: "none" }}
                autoSize={false}
                maxLength={5000}
              />
            </Form.Item>
          </Form>
        </div>
        <div className="btn-option" style={{ marginLeft: 16 }}>
          <Button
            icon={<img src={IconSend} style={{ cursor: "pointer" }} />}
            htmlType="submit"
            form="formComment"
            type="link"
          />
        </div>
      </div>
    </div>
  );
};

export default CommentComponent;
