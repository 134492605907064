import { Button, Form, Input } from "antd";
import { memo } from "react";

import IconSearch from "../../../../../assets/icons/search-icon.svg";
import IconDelete from "../../../../../assets/icons/delete-red.svg";
import IconPlus from "../../../../../assets/icons/plus-white.svg";
import FilterIcon from "../../../../../assets/icons/Filter-2.svg";
import { PM012HeaderHandler } from "../handler/HandlerImpl";
import { RootState } from "../../../../../store";
import { useSelector } from "react-redux";
import {
  AUTHORIZATION_CODE,
  SYSTEM_ROLES,
} from "../../../../../common/constants/AUTHORIZATION";
import { isHasPrivilege } from "../../../../../common/helpers/privilege";
import {
  PRIVILEGE_CREATOR,
  PRIVILEGE_PM,
  PRIVILEGE_SUB_PM,
} from "../../../../../common/constants/PRIVILEGE";
import { PLAN_TYPE } from "../../../../../common/constants/COMMON";

const descriptionDelete = (
  <>
    <div>削除されたメンバーは案件にアクセスできません。</div>
    <div>削除してもよろしいでしょうか？</div>
  </>
);
interface props {
  handler: PM012HeaderHandler;
}

function PM012Header({ handler }: props) {
  const permission = useSelector(
    (state: RootState) => state.auth.authData?.permission
  );
  const projectDetail = useSelector(
    (state: RootState) => state.pm005.projectDetail
  );
  const isRoleAdmin = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdmin
  );

  const isRoleAdminSystem = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdminSystem
  );
  const isOrderBasic =
    useSelector((state: RootState) => state.auth.authData?.order?.code) ===
    PLAN_TYPE.PLAN_BASIC;

  return handler.isViewNav ? (
    <div className="pm012-header">
      <div className="pm012-header-left">
        <Form
          form={handler.formSearch}
          style={{ width: "100%" }}
          name="pm012-search"
        >
          <Form.Item name={"keyword"} style={{ margin: 0 }}>
            <Input
              size="large"
              prefix={<img alt="" src={IconSearch} />}
              className="pm012-input-search"
              placeholder="検索したい名前を入力してください"
              onChange={handler.onSearchChange}
            />
          </Form.Item>
        </Form>
      </div>
      <div className="pm012-header-right">
        {isHasPrivilege(projectDetail, [
          PRIVILEGE_PM,
          PRIVILEGE_SUB_PM,
          PRIVILEGE_CREATOR,
        ]) ||
        isRoleAdmin ||
        isRoleAdminSystem ? (
          <>
            <Button
              className="button-purple pm012-btn-add pm012-btn-flex"
              size="large"
              onClick={handler.handleMemberInvitation}
              icon={<img alt="" src={IconPlus} />}
            >
              メンバー招待
            </Button>

            {isHasPrivilege(projectDetail, [
              PRIVILEGE_PM,
              PRIVILEGE_SUB_PM,
              PRIVILEGE_CREATOR,
            ]) ? (
              <Button
                className="button-warning pm012-btn-delete pm012-btn-flex"
                style={{ marginRight: 16 }}
                size="large"
                icon={<img alt="" src={IconDelete} />}
                onClick={() => handler.handleUnInvitedMember(descriptionDelete)}
                disabled={handler.selectedRowKeys?.length === 0}
              >
                削除
              </Button>
            ) : (
              <></>
            )}
          </>
        ) : null}
        {isOrderBasic ? (
          <button
            className="pm012-btn-filter"
            onMouseOver={handler.handleMouseOverFilter}
            onMouseLeave={handler.handleMouseLeaveFilter}
          >
            <img alt="" src={FilterIcon} />
            <ul className="pm012-filter-dropdown" ref={handler.refFilter}>
              {handler.listRole?.map(
                (filter: { name: string; id: number; value: string }) => {
                  return (
                    <li
                      key={filter.id}
                      className={`pm012-filter-dropdown-item ${
                        filter.id === handler?.filter
                          ? "pm012-filter-dropdown-item-active"
                          : ""
                      }`}
                      onClick={() =>
                        handler.handleFilterMyCompany(filter.value)
                      }
                    >
                      {filter.name}
                    </li>
                  );
                }
              )}
            </ul>
          </button>
        ) : (
          <></>
        )}
      </div>
    </div>
  ) : (
    <div className="pm012-header">
      <div className="pm012-header-left pm012-header-left-other">
        <Form
          form={handler.formSearch}
          style={{ width: "100%" }}
          name="pm012-search"
        >
          <Form.Item name={"keyword"} style={{ margin: 0 }}>
            <Input
              size="large"
              prefix={<img alt="" src={IconSearch} />}
              className="pm012-input-search"
              placeholder="検索したい名前を入力してください"
              onChange={handler.onSearchChange}
            />
          </Form.Item>
        </Form>

        {isOrderBasic ? (
          <button
            className="pm012-btn-filter"
            onMouseOver={handler.handleMouseOverFilter}
            onMouseLeave={handler.handleMouseLeaveFilter}
          >
            <img alt="" src={FilterIcon} />
            <ul className="pm012-filter-dropdown" ref={handler.refFilter}>
              {handler.listRole?.map(
                (filter: { name: string; id: string; value: string }) => {
                  return (
                    <li
                      key={filter.id}
                      className={`pm012-filter-dropdown-item ${
                        filter.id === handler?.filter
                          ? "pm012-filter-dropdown-item-active"
                          : ""
                      }`}
                      onClick={() =>
                        handler.handleFilterMyCompany(filter.value)
                      }
                    >
                      {filter.name}
                    </li>
                  );
                }
              )}
            </ul>
          </button>
        ) : (
          <></>
        )}
      </div>
      {permission?.includes(AUTHORIZATION_CODE.M12) ||
      isRoleAdmin ||
      isRoleAdminSystem ? (
        <div className="pm012-header-right">
          <Button
            className="button-purple"
            size="large"
            onClick={handler.handleViewModalAddMember}
          >
            新規メンバー追加
          </Button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default PM012Header;
