import { createSlice } from '@reduxjs/toolkit';
import { AM0017SliceType } from '../../entity/Entity';

export const initialState: AM0017SliceType = {
    isRefresh: false,
};

export const CM0017Slice = createSlice({
    name: 'cm0017',
    initialState,
    reducers: {
        refreshCM0017: (state) => {
            state.isRefresh = !state.isRefresh;
        },
    },
});

export const { refreshCM0017 } = CM0017Slice.actions;

export default CM0017Slice.reducer;
