import { Button, Modal, Tooltip } from 'antd';
import { OM001Hanlder } from '../handler/HandlerImpl';
import TrashRed from '../../../../../assets/icons/trash-red.svg';

import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import '../../../../../assets/styles/OM/OM002.css';
import '../../../../../assets/styles/OM/OM001.css';
import '../../../../../assets/styles/OM/OM004.css';
import IconI from "../../../../../assets/icons/icon-i-om.svg"
import OMHeaderModalComponent from '../../../../../common/components/om-header-modal/OMHeaderModalComponent';
import {
    AUTHORIZATION_CODE,
    SYSTEM_ROLES,
} from '../../../../../common/constants/AUTHORIZATION';

interface props {
    handler: OM001Hanlder;
}

const OM001UI = ({ handler }: props) => {
    const isVisible = useSelector((state: RootState) => state.om001.isVisible);
    const detailGroup = useSelector((state: RootState) => state.om001.detailGroup);
    const undefinedGroupId = useSelector((state: RootState) => state.om002.undefinedGroupId);
    const permission = useSelector(
        (state: RootState) => state.auth.authData?.permission
    );

    const isRoleAdmin = useSelector((state: RootState) => state.auth?.authData?.isRoleAdmin)

    const isRoleAdminSystem =
        useSelector((state: RootState) => state.auth?.authData?.isRoleAdminSystem)

    return !isVisible ? null : (
        <Modal
            style={{ marginLeft: 300, marginTop: 40 }}
            className="group-detail-modal no-margin-modal"
            visible={true}
            closable={false}
            maskClosable={false}
            width={996}
            centered={true}
            footer={
                <>
                    {detailGroup.id !== 0 && detailGroup.id !== undefinedGroupId && (permission?.includes(AUTHORIZATION_CODE.M12) || isRoleAdminSystem || isRoleAdmin) ?
                        <>
                            <Button
                                size="large"
                                type="primary"
                                style={{ marginLeft: 16, width: 76, letterSpacing: -1.5 }}
                                onClick={handler.onUpdate}
                            >
                                編集
                            </Button>
                            <Button
                                size="large"
                                className="button-E9EDF2-red-border"
                                icon={<img alt="" style={{ marginRight: 8 }} src={TrashRed} />}
                                style={{ marginLeft: 16, width: 106 }}
                                onClick={handler.onDelete}
                            >
                                削除
                            </Button>
                        </>
                        :
                        <></>

                    }
                    <Button size="large" className="om-cancel-button" onClick={handler.onCancel}>
                        キャンセル
                    </Button>
                </>
            }
        >
            <OMHeaderModalComponent title="部門詳細" onCancel={handler.onCancel} />
            <div
                className=" w-100-percent"
                style={{
                    minHeight: 199.25,
                    display: 'flex',
                    marginTop: 24,
                    paddingLeft: 24,
                    paddingRight: 24,
                }}
            >
                <div style={{ marginLeft: 14 }}>
                    <div className="label-detail-group ">
                        <div className="min-h-22" style={{ width: 90 }}>
                            部門名
                        </div>
                        <div className="min-h-22" style={{ marginTop: 16, width: 90 }}>
                            総メンバー数
                        </div>
                        {detailGroup.id !== 0 && (
                            <>
                                {detailGroup.id !== undefinedGroupId && (
                                    <div className="min-h-22" style={{ marginTop: 16, width: 90, display: 'flex', justifyContent: 'flex-end' }}>
                                        <div className='center-item'>
                                            承認者
                                        </div>
                                        <section className='button-style' style={{ marginLeft: 8 }}>
                                            <Tooltip
                                                placement='topLeft'
                                                overlayInnerStyle={{ width: 330 }}
                                                title={<div>
                                                    勤怠管理申請の承認者 <br />
                                                    申請内容：打刻申請、休暇申請、遅刻・早退申請
                                                </div>} >
                                                <img alt='' src={IconI} />
                                            </Tooltip>
                                        </section>
                                    </div>
                                )}
                            </>
                        )}

                        <div className="min-h-22" style={{ marginTop: 16, width: 90 }}>
                            説明
                        </div>
                    </div>
                </div>
                <div className="info-detail-group" style={{ marginLeft: 24 }}>
                    <div className="min-h-22">
                        {detailGroup.name
                            ? detailGroup.name.length > 50
                                ? detailGroup.name.slice(0, 50) + '...'
                                : detailGroup.name
                            : '-'}
                    </div>
                    <div className="min-h-22" style={{ marginTop: 16 }}>
                        {detailGroup.totalMember}人
                    </div>
                    {detailGroup.id !== 0 && (
                        <>
                            {detailGroup.id !== undefinedGroupId && (
                                <div className="min-h-22" style={{ marginTop: 16 }}>
                                    {detailGroup?.approver?.fullName ?? '-'}
                                </div>
                            )}
                        </>
                    )}
                    <div className="min-h-22" style={{ marginTop: 16 }}>
                        {detailGroup.explanation}
                    </div>
                </div>
                <div></div>
            </div>
        </Modal>
    );
};

export default OM001UI;
