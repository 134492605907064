// Built-in
import React, { HTMLAttributes, useMemo } from 'react';

// 3rd party lib
import { Form, DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/ja_JP';

// Source files
import '../../../../../../assets/styles/CR/CR003.css';
import '../../../../../../assets/styles/CI/ci001.css';
// Image files
import CalendarSvg from '../../../../../../assets/icons/calendar.svg';
import { useEffect } from 'react';
import { DateRangePickerHandlerImpl } from '../../handler/HandlerImpl';
import COMMON from '../../../../../constants/COMMON';

const { RangePicker } = DatePicker;

type props = {
    handler: DateRangePickerHandlerImpl;
    record: any;
    name: string;
    onAttendanceLaborCostChange?: (record: any, property: string, value: any) => Promise<void> | void;

};

const DateRangePickerUI = ({ record, name, handler, onAttendanceLaborCostChange }: props) => {
    const { setOpen, open } = handler;
    const bodyContent = document.querySelector('#yma-body');

    const elementTable = document.querySelector('.scroll-table');
    const tableScroll = elementTable?.querySelector('.ant-table-body');
    useEffect(() => {
        const funcScroll = () => {
            setOpen(false);
        };
        if (tableScroll) {
            tableScroll.addEventListener('scroll', funcScroll);
        }
        if (bodyContent) {
            bodyContent.addEventListener('scroll', funcScroll);
        }
        return () => {
            tableScroll?.removeEventListener('scroll', funcScroll);
            bodyContent?.removeEventListener('scroll', funcScroll);
        };
    }, []);

    return (
        <Form.Item name={[`record${record.id}`, name]} style={{ padding: 0, margin: 0 }} >
            <RangePicker
                format={COMMON.FORMAT_DATE_CI}
                className="ci001-date-picker w-100-percent"
                placeholder={['', '']}
                inputReadOnly
                locale={locale}
                separator={<span className="calender-separator">-</span>}
                suffixIcon={<img alt="" src={CalendarSvg} className="icon-calender" />}
                open={open}
                onOpenChange={(open) => setOpen(open)}
                style={{ marginTop: 7, marginBottom: 7, height: 38 }}
                onChange={(value) =>
                    onAttendanceLaborCostChange ? onAttendanceLaborCostChange(record, name, value) : undefined
                } />
        </Form.Item>
    );
};

export default DateRangePickerUI;
