import React from 'react';
import CompletedPaidAmountUI from './ui/UI';
import CompletedPaidAmountApi from '../../../infrastructure/api/ApiCompletedPaidAmount';
import CompletedPaidAmountService from '../../../usecase/ServiceCompletedPaidAmount';
import CompletedPaidAmountHandler from '../../handler/HandlerCompletedPaidAmount';

const CompletedPaidAmountComponent = () => {
    const api = CompletedPaidAmountApi();
    const service = CompletedPaidAmountService(api);
    const handler = CompletedPaidAmountHandler(service);
    return <CompletedPaidAmountUI handler={handler} />;
};

export default CompletedPaidAmountComponent;
