import { useDispatch, useSelector } from "react-redux";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import { NOTIFICATION_TITLE } from "../../../../../common/constants/MESSAGE";
import { RootState } from "../../../../../store";
import { setRefreshAM010 } from "../../../AM010/presenter/slice/Slice";
import { resetAM014 } from "../../../AM014/presenter/slice/Slice";
import { resetAM020 } from "../../../AM020/presenter/slice/Slice";
import { resetAM024 } from "../../../AM024/presenter/slice/Slice";
import { AM027UseCase } from "../../usecase/ServiceImpl";
import { resetAM027, setLoading} from "../slice/Slice";


const AM027Handler = (am027Service: AM027UseCase) => {
  // LIB FUNCTION
  const dispatch = useDispatch();
  const requestId = useSelector((state: RootState) => state.am027.requestId); 
  const screenRequestToAM027 = useSelector((state:RootState) => state.am027.screenRequestToAM027)

  // STATE
  // FUNCTION

  const handleDeleteRequest = async()=>{
    try{
      dispatch(setLoading(true))
      const data={
        requestId
      }
      const res = await am027Service.deleteRequest(data)
      SuccessNotification(
        res?.data?.message ?? NOTIFICATION_TITLE.SUCCESS
      );
      dispatch(resetAM027())
      if(res){
        switch(screenRequestToAM027){
          case "am014":{
            dispatch(resetAM014());
            break;
          }
          case "am024":{
            dispatch(resetAM024());
            break;
          }
          case "am020":{
            dispatch(resetAM020());
            break;
          }
        }
        dispatch(setRefreshAM010())
      } 
    }catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false))
    }
  }

  const onCancel = () => {
    return dispatch(resetAM027());
  };

  return {
    onCancel,
    handleDeleteRequest
  };
};

export default AM027Handler;