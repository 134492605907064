import { AxiosResponse } from "axios";
import axiosConfig from "../../../../../config/axiosConfig";

const ConstructionApi = () => {
  const getAttendanceSubConstructionListSubConstruction = async (params: {
    contractorConstructionId: number;
    constructionId: number;
    from?: string; // format 2006-01-02
    to?: string; // format 2006-01-02
    timeOffset?: number;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/attendance/subconstruction/list-subconstruction";
    return await axiosConfig.get(url, { params });
  };
  return {
    getAttendanceSubConstructionListSubConstruction,
  };
};

export default ConstructionApi;
