import {
    CommonPutDeletePostResponse,
    MoneyDirectCostMaterialCreatePayload,
    MoneyDirectCostMaterialDeletePayload,
    MoneyDirectCostMaterialListResponse,
    MoneyDirectCostMaterialListResult,
    MoneyDirectCostMaterialUpdatePayload,
} from '../entity/Entity';
import { CollapsedTableAPIImpl } from './ServiceImpl';

const CollapsedTableService = (api: CollapsedTableAPIImpl) => {
    const postMoneyDirectCostMaterialCreate = async (
        payload: MoneyDirectCostMaterialCreatePayload,
    ): Promise<CommonPutDeletePostResponse> => {
        try {
            const response = await api.postMoneyDirectCostMaterialCreate(payload);
            return response.data;
        } catch (error: any) {
            throw error;
        }
    };

    const getMoneyDirectCostMaterialList = async (payload: {
        documentDirectMaterialCostId: number;
        page?: number;
        size?: number;
        sortBy?: string;
        sortType?: string;
    }): Promise<MoneyDirectCostMaterialListResult[]> => {
        try {
            const response = await api.getMoneyDirectCostMaterialList(payload);
            const { results } = response.data;
            return results;
        } catch (error: any) {
            throw error;
        }
    };

    const putMoneyDirectCostMaterialUpdate = async (
        data: MoneyDirectCostMaterialUpdatePayload,
    ): Promise<CommonPutDeletePostResponse> => {
        try {
            const response = await api.putMoneyDirectCostMaterialUpdate(data);
            return response.data;
        } catch (error: any) {
            throw error;
        }
    };

    const deleteMoneyDirectCostMaterialDelete = async (
        data: MoneyDirectCostMaterialDeletePayload,
    ): Promise<CommonPutDeletePostResponse> => {
        try {
            const response = await api.deleteMoneyDirectCostMaterialDelete(data);
            return response.data;
        } catch (error: any) {
            throw error;
        }
    };

    const getLinkPresignUpload = async (params: any): Promise<any> => {
        try {
            const response = await api.getLinkPresignUpload(params);
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    const uploadToS3 = async (url: any, data: any): Promise<any> => {
        try {
            const response = await api.uploadToS3(url, data);
            return response.data;
        } catch (error: any) {
            throw error;
        }
    };

    const getPresignLinkS3 = async (params: any): Promise<any> => {
        try {
            const response = await api.getPresignLinkS3(params);
            return response.data;
        } catch (error: any) {
            throw error;
        }
    };
    return {
        deleteMoneyDirectCostMaterialDelete,
        putMoneyDirectCostMaterialUpdate,
        getMoneyDirectCostMaterialList,
        postMoneyDirectCostMaterialCreate,
        getLinkPresignUpload,
        uploadToS3,
        getPresignLinkS3,
    };
};

export default CollapsedTableService;
