import { createSlice } from "@reduxjs/toolkit";
import { AM005SliceType } from "../../entity/Entity";

export const initialState: AM005SliceType = {
  isRefreshCompany: false,
  isRefreshConstruction: false,
  isRefresh: false,
  isCollapsed: false,
  projectChosen: {},
  projectList: [],
};

export const AM005Slice = createSlice({
  name: "am005",
  initialState,
  reducers: {
    refreshAM005Company: (state) => {
      state.isRefreshCompany = !state.isRefreshCompany;
    },
    refreshAM005Construction: (state) => {
      state.isRefreshConstruction = !state.isRefreshConstruction;
    },
    refreshAM005: (state) => {
      state.isRefresh = !state.isRefresh;
    },
    setIsCollapsed: (state, action) => {
      state.isCollapsed = action.payload;
    },
    setProjectChosen: (state, action) => {
      state.projectChosen = action.payload;
    },
    setProjectListData: (state, action) => {
      state.projectList = action.payload;
    },
  },
});

export const {
  refreshAM005Company,
  refreshAM005Construction,
  refreshAM005,
  setIsCollapsed,
  setProjectChosen,
  setProjectListData,
} = AM005Slice.actions;

export default AM005Slice.reducer;
