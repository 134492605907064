// Built-in
import React from 'react';

// 3rd party lib
import { Form, InputNumber } from 'antd';

// Source files
import COMMON from '../../../common/constants/COMMON';

type props = {
    record: any;
    name: string;
    disabled?: boolean;
    onValueNumberChange?: (record: any, property: string, value: any) => void;
};
const InputFormMoneyItemComponent = ({ record, name, disabled, onValueNumberChange }: props) => (
    <div className="cr001">
        <Form.Item name={[`record${record.id}`, name]}>
            <InputNumber controls={false}
                disabled={disabled ? true : false}
                size="large"
                maxLength={COMMON.MAX_INPUT_NUMBER}
                max={COMMON.MAX_NUMBER_VALUE}
                min={0}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                placeholder=""
                parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                onChange={(value) =>
                    onValueNumberChange ? onValueNumberChange(record, name, value) : undefined
                }
                style={{ width: '100%' }}
            />
        </Form.Item>
    </div>
);

export default InputFormMoneyItemComponent;
