import { AxiosResponse } from 'axios';
import { CCUS05APIImpl } from './ServiceImpl';
import { ResultDataType } from '../entity/Entity';

const CCUS05Service = (api: CCUS05APIImpl) => {

    const getTimesheetSyncData = async (params: {
        projectId?: number;
        companyId?: number;
        state?: string;
        sortBy?: string;
        sortType?: string;
        page?: number;
        size?: number;
    }): Promise<any> => {
        try {
            const response = await api.getTimesheetSyncData(params);
            return response?.data;
        } catch (error: any) {
            throw error;
        }
    };

    const getProjectListCCUS = async (params: {
        companyId?: number;
        approverId?: number;
        state?: string;
        page: number;
        size: number;
    }): Promise<any> => {
        try {
            const response = await api.getProjectListCCUS(params);
            return response?.data?.results ?? [];
        } catch (error: any) {
            throw error;
        }
    };

    const getDependedCompanyListCCUS = async (params: {
        projectId?: number;
        approverId?: number;
        state?: string;
        page: number;
        size: number;
    }): Promise<any> => {
        try {
            const response = await api.getDependedCompanyListCCUS(params);
            return response?.data?.results ?? [];
        } catch (error: any) {
            throw error;
        }
    };

    const approveCCUS = async (
        payload: { id: number[] }
    ): Promise<string> => {
        try {
            const response = await api.approveCCUS(payload);
            return response?.data
        } catch (error: any) {
            throw error;
        }
    };

    return {
        getTimesheetSyncData,
        getProjectListCCUS,
        getDependedCompanyListCCUS,
        approveCCUS
    };
};

export default CCUS05Service;
