import "../../../assets/styles/AM/AM002.css";
import "../../../assets/styles/OM/OM002.css";
import "../../../assets/styles/OM/OM004.css";
import "../../../assets/styles/AM/AM004.css";
import "../../../assets/styles/AM/AM001.css";
import Api from "./api/Api";
import Service from "./usecase/Service";
import Handler from "./presenter/handler/Handler";
import MainUI from "./presenter/ui/UI";

type props = {
  afterSuccess: () => void
}

const AM040Component = ({ afterSuccess }: props) => {
  const api = Api()
  const service = Service(api)
  const handler = Handler(service, afterSuccess)
  return <MainUI handler={handler} />
};

export default AM040Component;
