import React, { useEffect } from 'react';
import { Collapse, Empty } from 'antd';
import CertDoc from '../../../assets/icons/certificate-doc.svg';

import useCertificationViewComponent from './useCertificationViewComponent';
import COMMON from '../../constants/COMMON';
import moment from 'moment';
import NoDataTableComponent from '../no-data/NoDataTable';
import MESSAGE from '../../constants/MESSAGE';
const { Panel } = Collapse;

const EMPTY_VALUE = '-';

type Props = {
    label: string;
    initialLines?: number[] | null;
};

const CertificationViewP01Component = ({ label, initialLines }: Props) => {
    const { setMaxKey, setLineQuantity, lineQuantity } = useCertificationViewComponent();

    useEffect(() => {
        if (initialLines) {
            setLineQuantity(initialLines);
            setMaxKey(initialLines.length === 0 ? 1 : Math.max(...initialLines));
        }
    }, [JSON.stringify(initialLines)]);

    const ListRecords =
        lineQuantity.length === 0 ? (
            <div
                style={{
                    width: '100%',
                    backgroundColor: '#ffffff',
                    height: 90,
                }}
            >
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={MESSAGE.MESSAGE_NO_DATA} />
            </div>
        ) : (
            lineQuantity.map((element: any, index: number) => (
                <div
                    className="cert-view"
                    key={element.id}
                    style={{
                        backgroundColor: (index + 1) % 2 === 0 ? '#FAFAFA' : '#ffffff',
                    }}
                >
                    <div style={{ width: 62, marginTop: 20 }}></div>
                    <div className="detail-label-certificate" style={{ width: 75, marginRight: 8 }}>
                        項目名
                    </div>
                    <div style={{ width: 255, marginRight: 64 }}>
                        {element?.name || EMPTY_VALUE}
                    </div>
                    <div className="detail-label-certificate" style={{ width: 75, marginRight: 8 }}>
                        取得年月日
                    </div>
                    <div style={{ width: 100, marginRight: 64 }}>
                        {element?.issueDate
                            ? moment(element?.issueDate).format(COMMON.FORMAT_DATE)
                            : EMPTY_VALUE}
                    </div>
                    <div className="detail-label-certificate" style={{ width: 65, marginRight: 8 }}>
                        取得番号
                    </div>
                    <div style={{ width: 144, marginRight: 64 }}>
                        {element?.registrationCode || EMPTY_VALUE}
                    </div>
                    <div className="detail-label-certificate" style={{ width: 65, marginRight: 8 }}>
                        取得先
                    </div>
                    <div style={{ width: 126, marginRight: 92 }}>
                        {element?.issuer || EMPTY_VALUE}
                    </div>
                    <div className="detail-label-certificate" style={{ width: 65 }}>
                        有効期限
                    </div>
                    <div style={{ width: 59 }}>
                        {element?.expireDate
                            ? moment(element?.expireDate).format(COMMON.FORMAT_DATE)
                            : EMPTY_VALUE}
                    </div>
                </div>
            ))
        );

    return (
        <div className={`w-100-percent collapse-no-bottom-border`}>
            <Collapse className="folder-collapse collapse-no-padding " defaultActiveKey={['1']}>
                <Panel
                    header={
                        <div className="cert-header">
                            <img alt="" src={CertDoc} />
                            <div style={{ marginLeft: 8 }}>{label}</div>
                        </div>
                    }
                    key="1"
                >
                    {ListRecords}
                </Panel>
            </Collapse>
        </div>
    );
};

export default CertificationViewP01Component;
