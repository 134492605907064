import { createSlice } from '@reduxjs/toolkit';
import { CM0026SliceType } from '../../entity/Entity';

export const initialState: CM0026SliceType = {
    isRefresh: false,
    visibleCM0027: false,
    isExportDataCM0026: null,
};

export const CM0026Slice = createSlice({
    name: 'cm0026',
    initialState,
    reducers: {
        refreshCM0026: (state) => {
            state.isRefresh = !state.isRefresh;
        },
        setVisibleCM0027: (state, action) => {
            state.visibleCM0027 = action.payload;
        },
        setIsExportDataCM0026: (state, action) => {
            state.isExportDataCM0026 = action.payload;
        },
    },
});

export const { refreshCM0026, setVisibleCM0027, setIsExportDataCM0026 } = CM0026Slice.actions;

export default CM0026Slice.reducer;
