import { Button, Collapse, Empty } from "antd";
// Icons
import UploadLightSVG from "../../../../../assets/icons/upload-icon-purple.svg";
import eyeIconBlack from "../../../../../assets/icons/eyeIconBlack.svg";
import DocumentIconSvg from "../../../../../assets/icons/document-icon.svg";
import { CM003Handler } from "../handler/HandlerImpl";
import { ChildrenDataEntity, TableDataEntity } from "../../entity/Entity";
import { findColorStatus } from "../../helper";
import NoDataTableComponent from "../../../../../common/components/no-data/NoDataTable";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import {
  AUTHORIZATION_CODE,
  SYSTEM_ROLES,
} from "../../../../../common/constants/AUTHORIZATION";
import { useSearchParams } from "react-router-dom";
import { CONSTRUCTION_ID_KEY, PROJECT_PARAM_ID_KEY } from "../handler/Handler";

type props = {
  handler: CM003Handler;
  projectName: string;
  rangeDate: string;
  from: string;
  to: string;
};

type childrenProps = {
  element: ChildrenDataEntity[];
};

const CM003ContentComponent = ({
  handler,
  projectName,
  rangeDate,
  from,
  to,
}: props) => {
  const [urlSearchParams] = useSearchParams();
  const projectId: string = urlSearchParams.get(PROJECT_PARAM_ID_KEY) ?? "0";
  const constructionId: string = urlSearchParams.get(CONSTRUCTION_ID_KEY) ?? "";

  const permission = useSelector(
    (state: RootState) => state.auth.authData?.permission
  );
  const isRoleAdmin = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdmin
  );

  const isRoleAdminSystem = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdminSystem
  );

  const Element = ({ element }: childrenProps) => {
    return (
      <>
        {element.map((ite: ChildrenDataEntity, idx: number) => (
          <div className="tb-header" key={idx}>
            <div className="item" style={{ color: "#222", width: "10%" }}>
              {idx + 1}
            </div>
            <div
              className="item w160-percent"
              style={{ color: "#222", width: "20%" }}
            >
              {ite.DateTime}
            </div>
            <div className="item" style={{ color: "#222", width: "10%" }}>
              {ite.ReceiveAt}
            </div>
            <div className="item" style={{ color: "#222", width: "10%" }}>
              {ite.PlanReceiveMoney}
            </div>
            <div className="item" style={{ color: "#222", width: "10%" }}>
              {ite.ReceiveMoney}
            </div>
            <div className="item" style={{ color: "#222", width: "10%" }}>
              {ite.OffsetMoney}
              <img
                style={{ cursor: "pointer" }}
                src={eyeIconBlack}
                alt="view"
                onClick={() => handler.openModalCM0063(ite.Id)}
              />
            </div>
            <div className="item" style={{ color: "#222", width: "10%" }}>
              {ite.ContractorReceiveMoney}
            </div>
            <div className="item" style={{ color: "#222", width: "10%" }}>
              {ite.DocumentPath !== "" ? (
                <div
                  className="button-style"
                  onClick={() =>
                    handler.handleOpenViewer(ite.Id, ite.DocumentPath)
                  }
                >
                  <img src={DocumentIconSvg} alt="" />
                </div>
              ) : (
                <></>
              )}
            </div>
            <div
              className="item border-right-none"
              style={{ color: findColorStatus(ite.StatusCode), width: "10%" }}
            >
              {ite.StatusName}
            </div>
          </div>
        ))}
      </>
    );
  };

  const { Panel } = Collapse;

  return (
    <div className="cm003-content">
      <div className="cm003-btn-export">
        {permission?.includes(AUTHORIZATION_CODE.B9) ||
        isRoleAdmin ||
        isRoleAdminSystem ? (
          <Button
            className="button-brown-3"
            style={{ border: "1px solid #605BFF", width: 106 }}
            size="large"
            onClick={() =>
              handler.exportDataCM003(
                projectName,
                rangeDate,
                from,
                to,
                projectId,
                constructionId
              )
            }
          >
            <div className="center-item">
              <img
                alt=""
                src={UploadLightSVG}
                style={{
                  marginRight: 8,
                  fontWeight: 700,
                  fontSize: 14,
                }}
              />
              <div className="export-button" style={{ color: "#605BFF" }}>
                出力
              </div>
            </div>
          </Button>
        ) : (
          <div></div>
        )}
      </div>
      <div className="cm003-note">（税抜）</div>
      <div className="cm003-table">
        <div className="title">案件総出来高</div>
        <div className="description">
          <div>
            <div className="header">
              <div className="item" style={{ width: "25%" }}>
                契約金額
              </div>
              <div className="item" style={{ width: "25%" }}>
                請求出来高
              </div>
              <div className="item" style={{ width: "25%" }}>
                請求出来高(％)
              </div>
              <div className="item border-right-none" style={{ width: "25%" }}>
                請求残金
              </div>
            </div>
            <div className="content">
              <div className="item" style={{ width: "25%" }}>
                {handler.summaryData.contractAmount}
              </div>
              <div className="item" style={{ width: "25%" }}>
                {handler.summaryData.billingVolume}
              </div>
              <div className="item" style={{ width: "25%" }}>
                {handler.summaryData.threshold}
              </div>
              <div className="item border-right-none" style={{ width: "25%" }}>
                {handler.summaryData.rateValue}
              </div>
            </div>
          </div>
          <div>
            <div className="title">工事出来高</div>
            <div className="tb-header">
              <div className="item fw700" style={{ width: "10%" }}>
                No
              </div>
              <div className="item fw700" style={{ width: "20%" }}>
                対象期間
              </div>
              <div className="item fw700" style={{ width: "10%" }}>
                入金日
              </div>
              <div className="item fw700" style={{ width: "10%" }}>
                請求予定金額
              </div>
              <div className="item fw700" style={{ width: "10%" }}>
                請求金額
              </div>
              <div className="item fw700" style={{ width: "10%" }}>
                相殺金額
              </div>
              <div className="item fw700" style={{ width: "10%" }}>
                入金額
              </div>
              <div className="item fw700" style={{ width: "10%" }}>
                エビデンス
              </div>
              <div
                className="item border-right-none fw700"
                style={{ width: "10%" }}
              >
                ステータス
              </div>
            </div>
            <div className="tb-content">
              <div>
                {handler.data.length > 0 ? (
                  <>
                    <Collapse
                      className="cm003-content-collapse"
                      defaultActiveKey={["0"]}
                      // onChange={onChange}
                      expandIconPosition={"end"}
                    >
                      {handler.data.map(
                        (item: TableDataEntity, idx: number) => (
                          <Panel
                            header={`${item.ConstructionName}`}
                            key={idx}
                            style={{
                              background: "#DAE2EE",
                              color: "#222222",
                              fontWeight: 700,
                            }}
                          >
                            {item.Children.length != 0 ? (
                              <Element element={item.Children} />
                            ) : (
                              <Empty
                                style={{
                                  padding: "24px 0px",
                                  margin: 0,
                                }}
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description="データなし"
                              />
                            )}
                          </Panel>
                        )
                      )}
                    </Collapse>
                  </>
                ) : (
                  <NoDataTableComponent />
                )}
              </div>
              <div className="tb-footer">
                <div className="item" style={{ width: "10%" }}>
                  合計金額
                </div>
                <div
                  className="item w160-percent"
                  style={{ width: "20%" }}
                ></div>
                <div className="item" style={{ width: "10%" }}></div>
                <div className="item" style={{ width: "10%" }}>
                  {handler.dataTotal.PlanningTotal}円
                </div>
                <div className="item" style={{ width: "10%" }}>
                  {handler.dataTotal.ReceiveMoneyTotal}円
                </div>
                <div className="item" style={{ width: "10%" }}>
                  {handler.dataTotal.OffsetMoneyTotal}円
                </div>
                <div className="item" style={{ width: "10%" }}>
                  {handler.dataTotal.ContractorMoneyTotal}円
                </div>
                <div className="item" style={{ width: "10%" }}></div>
                <div
                  className="item border-right-none"
                  style={{ width: "10%" }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { CM003ContentComponent };
