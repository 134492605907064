import { useSearchParams } from "react-router-dom";
// Redux
import { useDispatch, useSelector } from "react-redux";

// Services
import { uuidv4 } from "@firebase/util";
import moment from "moment";
import { useMemo, useState } from "react";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import COMMON, { TAX_CODE } from "../../../../../common/constants/COMMON";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import helpers from "../../../../../common/helpers/common";
import { CM0024_TemplateExport } from "../../../../../common/helpers/exports/cm/template_cm0024";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import { RootState } from "../../../../../store";
import {
  setHeaderDataCM0012_2,
  setIsVisibleCM0012_2,
  setParentCodeCM0012_2,
  setTitle,
} from "../../../CM0012_2/presenter/slice/Slice";
import { setIsExportDataCM0026 } from "../../../CM0026/presenter/slice/Slice";
import {
  setIsUpdate,
  setIsVisibleCM017_2,
  setParentCodeCM017_2,
} from "../../../CM017_2/presenter/slice/Slice";
import {
  ReSummaryCompanyProjectOutsourceCost,
  ReSummaryOutsourceCostCompany,
  ReSummaryOutsourceCostCompanyProject,
  SubCategory,
} from "../../entity/Entity";
import {
  setIsExportDataCM0024,
  setIsHavingData,
  setVisibleCM0026,
} from "../slice/Slice";
import { CM0024ServiceImpl } from "./../../usecase/ServiceImpl";
import ConfirmModalAsync from "../../../../../common/components/modal/ConfirmModalAsync";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import { doExportForResponse } from "../../../../../common/helpers/exports/common";
import { showModalApproveConfirm } from "../../../../../common/components/modal-approve-confirm/presenter/slice/Slice";

export const MENU_ITEM = [
  {
    name: "業者別",
    key: "trader",
  },
  // {
  //   name: "案件別",
  //   key: "project",
  // },
];

const PARAMS = {
  menu: "menu",
  sortType: "sortType1",
  sortBy: "sortBy1",
  from: "from1",
  to: "to1",
  clear: "clear1",
  from2: "from2",
  to2: "to2",
  clear2: "clear2",
};

export const PARAMS_DEFAULT = {
  menu: MENU_ITEM[0].key,
  from: helpers.getFilterTime(moment()),
  to: helpers.getFilterTime(moment()),
};

export const HOLD_TAB_KEY = "3";
export const DEFAULT_TAB_KEY = "tab";
export const COLUMN_SORT = [
  "totalAmount",
  "taxation1",
  "taxExempt1",
  "recordAmount",
  "taxation2",
  "taxExempt2",
  "excludingTax",
  "consumptionTax",
  "taxIncluded",
];

const STATUS_SUBMIT_CHECK: any[] = [
  {
    VALUE: "承認済",
    COLOR: "#0FA44A",
    CODE: "money_approved",
  },
  {
    VALUE: "否認",
    COLOR: "#FF0909",
    CODE: "money_reject",
  },
  {
    VALUE: "取り下げ",
    COLOR: "#3234A8",
    CODE: "money_retrieve",
  },
  {
    VALUE: "未承認",
    COLOR: "#7932A8",
    CODE: "money_not_approved",
  },
  {
    VALUE: "承認待ち",
    COLOR: "#FF9054",
    CODE: "money_waiting_for_approval",
  },
];

const CM0024Handler = (service: CM0024ServiceImpl) => {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const menu = searchParams.get(PARAMS.menu) || PARAMS_DEFAULT.menu;
  const sortType = searchParams.get(PARAMS.sortType);
  const sortBy = searchParams.get(PARAMS.sortBy);
  const from =
    searchParams.get(PARAMS.from) || helpers.getFilterTimeMonth(moment());
  const to =
    searchParams.get(PARAMS.to) || helpers.getFilterTimeMonthTo(moment());
  const clear = searchParams.get(PARAMS.clear);
  const from2 =
    searchParams.get(PARAMS.from2) || helpers.getFilterTimeMonth(moment());
  const to2 =
    searchParams.get(PARAMS.to2) || helpers.getFilterTimeMonthTo(moment());
  const clear2 = searchParams.get(PARAMS.clear2);

  const [dataView, setDataView] = useState<ReSummaryOutsourceCostCompany[]>([]);
  const [expandKey, setExpandKey] = useState<any>([]);
  const [sortTypeModal, setSortTypeModal] = useState<string | null>(null);
  const [sortByModal, setSortByModal] = useState<string | null>(null);
  const [isVisibleApprove, setVisibleApprove] = useState(false);

  const visibleCM0024 = useSelector(
    (state: RootState) => state.cm0024.visibleCM0026
  );
  const [subCategory, setSubCategory] = useState<SubCategory[]>([]);

  const [fromValue, setFromValue] = useState<any>();
  const [toValue, setToValue] = useState<any>();

  const [fromValuePrev, setFromValuePrev] = useState<any>(null);
  const [toValuePrev, setToValuePrev] = useState<any>(null);

  const [fromValuePrev2, setFromValuePrev2] = useState<any>(null);
  const [toValuePrev2, setToValuePrev2] = useState<any>(null);
  const [status, setStatus] = useState<any>();

  const handleToSetFromValue = (value: any) => {
    setFromValue(value);
  };

  const handleToSetToValue = (value: any) => {
    setToValue(value);
  };

  const sortByCreatedAt = (data: any[]) => {
    return data.sort((a, b) => {
      const A = moment(a.construction.createdAt);
      const B = moment(b.construction.createdAt);
      return A.valueOf() - B.valueOf();
    });
  };

  const getListReSummaryOutsourceCostCompany = async (
    from: string | undefined,
    to: string | undefined
  ): Promise<any> => {
    try {
      dispatch(setLoading(true));
      const params = {
        from,
        to,
      };
      const responseData = await service.getListReSummaryOutsourceCostCompany(
        params
      );
      if (responseData?.results) {
        const data = await formatListReSummaryOutsourceCostCompany(
          responseData.results,
          from,
          to
        );
        const finalData: ReSummaryOutsourceCostCompany[] =
          formatFinalToView(data);
        setDataView(finalData);
        const keyDefault: number[] = [];
        if (finalData?.length > 0) {
          keyDefault.push(finalData[0].key);
          if (finalData[0].children) {
            for (const item of finalData[0].children) {
              keyDefault.push(item.key);
              if (item.children)
                for (const itemChild of item.children)
                  keyDefault.push(itemChild.key);
            }
          }
        }
        setExpandKey(keyDefault);
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const formatListReSummaryOutsourceCostCompany = async (
    data: any,
    from: string | undefined,
    to: string | undefined
  ): Promise<any[]> => {
    var child1: ReSummaryOutsourceCostCompanyProject[] = await Promise.all(
      data.map((item: any): Promise<any> => {
        return getListReSummaryOutsourceCostCompanyProject(
          item.company.id,
          item.company.colabRefId,
          from,
          to,
          COMMON.DEFAULT_PAGE,
          COMMON.DEFAULT_SIZE
        );
      })
    );

    const results: ReSummaryOutsourceCostCompany[] = data.map(
      (item: any, index: number) => {
        return {
          key: uuidv4(),
          no: index + 1,
          companyId: item?.company?.id,
          colabRefId: item.company.colabRefId,
          traderName: item?.company?.name,
          totalAmount: 0,
          taxation1: getValueByTaxCode(item?.offsetCostSummary, TAX_CODE.TAX),
          taxExempt1: getValueByTaxCode(
            item?.offsetCostSummary,
            TAX_CODE.TAX_FREE
          ),
          recordedAmount: 0,
          taxation2: item?.offsetCostWithTax,
          taxExempt2: item?.offsetCostWithoutTax,
          excludingTax: 0,
          consumptionTax: 0,
          taxIncluded: 0,
          taxSetting: item?.taxSetting,
          children: child1[index],
        };
      }
    );
    return results;
  };

  const getListReSummaryOutsourceCostCompanyProject = async (
    companyId: number,
    colabRefId: number,
    from: string | undefined,
    to: string | undefined,
    page: number,
    size: number
  ): Promise<any> => {
    try {
      const params = {
        companyId,
        colabRefId,
        from,
        to,
        page,
        size,
      };
      const res = await service.getListReSummaryOutsourceCostCompanyProject(
        params
      );
      if (res?.results && page <= res?.pagination?.numPages) {
        const data: ReSummaryOutsourceCostCompanyProject[] =
          await formatListReSummaryOutsourceCostCompanyProject(
            res.results,
            companyId,
            colabRefId,
            from,
            to
          );

        return data.concat(
          await getListReSummaryOutsourceCostCompanyProject(
            companyId,
            colabRefId,
            from,
            to,
            page + 1,
            size
          )
        );
      } else return [];
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const formatListReSummaryOutsourceCostCompanyProject = async (
    data: any,
    companyId: number,
    colabRefId: number,
    from: string | undefined,
    to: string | undefined
  ): Promise<ReSummaryOutsourceCostCompanyProject[]> => {
    var child2: any[] = await Promise.all(
      data.map((item: any): Promise<number> => {
        return getListReSummaryCompanyProjectOutsourceCost(
          companyId,
          colabRefId,
          item.project.id,
          from,
          to
        );
      })
    );

    const results: ReSummaryOutsourceCostCompanyProject[] = data.map(
      (item: any, index: number) => {
        return {
          key: uuidv4(),
          no: index + 1,
          traderName: item?.project?.projectName,
          recordedAmount: item?.actualCost,
          taxation: getValueByTaxCode(item?.offsetCost, TAX_CODE.TAX),
          taxExempt: getValueByTaxCode(item?.offsetCost, TAX_CODE.TAX_FREE),
          excludingTax: item?.paymentCostWithoutTax,
          consumptionTax: item?.tax,
          taxIncluded: item?.paymentCostWithTax,
          children: child2[index],
        };
      }
    );

    return results;
  };

  const getListReSummaryCompanyProjectOutsourceCost = async (
    companyId: number,
    colabRefId: number,
    projectId: number,
    from: string | undefined,
    to: string | undefined
  ): Promise<any> => {
    try {
      const params = {
        companyId,
        colabRefId,
        projectId,
        from,
        to,
      };
      const { results } = await service.getListReSummaryCompanyProjectOutsourceCost(
        params
      );

      if (results) {
        const data: ReSummaryCompanyProjectOutsourceCost[] =
          formatListReSummaryCompanyProjectOutsourceCost(results);
        return data;
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const formatListReSummaryCompanyProjectOutsourceCost = (
    data: any
  ): ReSummaryCompanyProjectOutsourceCost[] => {
    const arr: ReSummaryCompanyProjectOutsourceCost[] = data.map(
      (item: any, index: number) => {
        let startDate = "";
        let endDate = "";
        if (item?.startDate && item?.startDate !== "") {
          startDate = moment(item?.startDate).format(COMMON.FORMAT_DATE_CI);
        }

        if (item?.endDate && item?.endDate !== "") {
          endDate = moment(item?.endDate).format(COMMON.FORMAT_DATE_CI);
        }
        return {
          key: uuidv4(),
          no: index + 1,
          outsourceCostInformationId:
            item?.documentDirectOutsourceCostInformationId,
          constructionName: item?.construction?.description,
          contractWork: item?.colabConstruction?.shortDescription,
          targetPeriod: `${startDate} ${startDate && endDate ? "～" : ""
            } ${endDate}`,
          datePayment: moment(item?.paymentDate).format(COMMON.FORMAT_DATE_CI),
          recordedAmount: item?.actualCost,
          taxation: getValueByTaxCode(item?.offsetCost, TAX_CODE.TAX),
          taxExempt: getValueByTaxCode(item?.offsetCost, TAX_CODE.TAX_FREE),
          excludingTax: item?.paymentCostWithoutTax,
          consumptionTax:
            (item?.paymentCostWithoutTax +
              getValueByTaxCode(item?.offsetCost, TAX_CODE.TAX)) *
            item.taxSetting,
          taxIncluded: item?.paymentCostWithTax,
        };
      }
    );

    return arr;
  };

  const getValueByTaxCode = (data: any, taxCode: string) => {
    let res: number = 0;
    data &&
      data.length > 0 &&
      data.forEach((item: any) => {
        if (item?.category?.code === taxCode) res = item?.value;
      });
    return res;
  };

  const formatFinalToView = (data: any): ReSummaryOutsourceCostCompany[] => {
    const result: ReSummaryOutsourceCostCompany[] = data.map(
      (item: ReSummaryOutsourceCostCompany) => {
        const recordedAmount = calculateRecordedAmount(item.children);
        const taxation2 = calculateTaxation2(item.children);
        const taxExempt2 = calculateTaxExempt2(item.children);
        const totalAmount =
          item.taxation1 + item.taxExempt1 + taxation2 + taxExempt2;
        const consumptionTax = Math.floor(
          (calculateTotalConsumptionTax(item.children) + item.taxation1) *
          item.taxSetting
        );

        const excludingTax = recordedAmount - totalAmount;
        return {
          ...item,
          recordedAmount,
          totalAmount,
          taxation2,
          taxExempt2,
          excludingTax,
          consumptionTax,
          taxIncluded: excludingTax + consumptionTax,
        };
      }
    );

    return result;
  };

  const calculateTaxation2 = (data: any) => {
    let result = 0;
    if (data.length > 0) {
      result = data.reduce((total: number, item: any) => {
        return (total += item?.taxation);
      }, 0);
    }
    return result ? result : 0;
  };

  const calculateTaxExempt2 = (data: any) => {
    let result = 0;
    if (data.length > 0) {
      result = data.reduce((total: number, item: any) => {
        return (total += item?.taxExempt);
      }, 0);
    }
    return result ? result : 0;
  };

  const calculateTotalConsumptionTax = (data: any) => {
    let result = 0;
    if (data.length > 0) {
      result = data.reduce((total: number, item: any) => {
        return (total += item?.consumptionTax);
      }, 0);
    }
    return result ? result : 0;
  };

  const calculateRecordedAmount = (data: any) => {
    let result = 0;
    if (data.length > 0) {
      result = data.reduce((total: number, item: any) => {
        return (total += item?.recordedAmount);
      }, 0);
    }
    return result;
  };

  const funcSetExpendKey = (key: any) => {
    const checkKey = funcCheckKeyExpand(key);
    if (checkKey) {
      const newExpandKey = expandKey.filter(
        (keyExpand: any) => keyExpand !== key
      );
      setExpandKey(newExpandKey);
    } else {
      setExpandKey([...expandKey, key]);
    }
  };

  const funcCheckKeyExpand = (key: any) => {
    if (!key || !expandKey) return false;
    return expandKey.some((element: any) => element === key);
  };

  const handleChangeMenuChosen = (menu: string) => {
    searchParams.set(PARAMS.menu, menu);
    if (menu === MENU_ITEM[0]?.key) {
      setFromValue(searchParams.get(PARAMS.from));
      setToValue(searchParams.get(PARAMS.to));
    } else {
      setFromValue(searchParams.get(PARAMS.from2));
      setToValue(searchParams.get(PARAMS.to2));
    }
    setSearchParams(searchParams);
  };

  const handleCancelModal = () => {
    dispatch(setVisibleCM0026(false));
    setSortByModal(null);
    setSortTypeModal(null);
  };

  const funcCheckSortOrder = (
    columnName: string,
    sortType: string | null,
    sortBy: string | null
  ): "ASC" | "DESC" | undefined => {
    if (columnName === sortBy) {
      return !sortType ? undefined : sortType === "ASC" ? "ASC" : "DESC";
    }
  };
  const funcSortTable = (column: string, sortOrder: string) => {
    if (visibleCM0024) {
      setSortByModal(column);
      setSortTypeModal(sortOrder);
    } else {
      searchParams.set(PARAMS.sortBy, column);
      searchParams.set(PARAMS.sortType, sortOrder);
      setSearchParams(searchParams);
    }
  };
  const funcFilterTime = (value: any) => {
    const fromValue = helpers.getFilterTimeMonth(value[0]) ?? "";
    const toValue = helpers.getFilterTimeMonthTo(value[1]) ?? "";
    setFromValue(fromValue ? fromValue : fromValuePrev);
    setToValue(toValue ? toValue : toValuePrev);

    if (menu === MENU_ITEM[0].key) {
      searchParams.delete(PARAMS.from);
      searchParams.delete(PARAMS.to);
      searchParams.set(PARAMS.clear, "1");
      if (fromValue === fromValuePrev && toValue === toValuePrev)
        searchParams.set("isChangeFromTo", "0");
      else {
        if (fromValue && toValue) searchParams.delete("isChangeFromTo");
      }
      if (value) {
        searchParams.delete(PARAMS.clear);
        if (fromValue !== PARAMS_DEFAULT.from) {
          searchParams.set(PARAMS.from, fromValue);
        }
        if (toValue !== PARAMS_DEFAULT.to) {
          searchParams.set(PARAMS.to, toValue);
        }
      }
    } else {
      searchParams.delete(PARAMS.from2);
      searchParams.delete(PARAMS.to2);
      searchParams.set(PARAMS.clear2, "1");
      if (fromValue === fromValuePrev2 && toValue === toValuePrev2)
        searchParams.set("isChangeFromTo", "0");
      else {
        if (fromValue && toValue) searchParams.delete("isChangeFromTo");
      }
      if (value) {
        searchParams.delete(PARAMS.clear2);
        if (fromValue !== PARAMS_DEFAULT.from) {
          searchParams.set(PARAMS.from2, fromValue);
        }
        if (toValue !== PARAMS_DEFAULT.to) {
          searchParams.set(PARAMS.to2, toValue);
        }
      }
    }
    setSearchParams(searchParams);
  };

  const funcViewModalCM017_1 = (value: {
    companyId?: number;
    colabRefId?: number;
    code: string;
    from?: string | undefined;
    to?: string | undefined;
  }) => {
    const categoryId = convertCodeToCategoryId(value.code);
    dispatch(setIsVisibleCM017_2(true));
    dispatch(setIsUpdate(false));
    dispatch(
      setParentCodeCM017_2({
        companyId: value.companyId,
        colabRefId: value.colabRefId,
        categoryId: categoryId,
        from: value.from,
        to: value.to,
      })
    );
  };

  const funcViewModalCM0012_2 = (
    outsourceCostInformationId: number,
    code: string,
    headerData: any
  ) => {
    const categoryId = convertCodeToCategoryId(code);
    dispatch(setIsVisibleCM0012_2(true));
    dispatch(
      setParentCodeCM0012_2({
        outsourceCostInformationId,
        categoryId,
      })
    );

    dispatch(
      setHeaderDataCM0012_2({
        traderName: headerData.traderName,
        contractWorkName: headerData.contractWork,
        targetPeriod: headerData.targetPeriod,
      })
    );

    dispatch(setTitle("案件別相殺金額"));
  };

  const convertCodeToCategoryId = (code: string) => {
    const res = subCategory?.find((item: SubCategory) => {
      return item.code === code;
    });
    return res?.value;
  };

  const totalTable = useMemo(() => {
    if (dataView.length === 0) return [];

    const results = dataView.reduce((acc: any, cur: any) => {
      const obj = { ...acc };
      obj.totalAmount += cur.totalAmount ?? 0;
      obj.taxation1 += cur.taxation1 ?? 0;
      obj.taxExempt1 += cur.taxExempt1 ?? 0;
      obj.recordedAmount += cur.recordedAmount ?? 0;
      obj.taxation2 += cur.taxation2 ?? 0;
      obj.taxExempt2 += cur.taxExempt2 ?? 0;
      obj.excludingTax += cur.excludingTax ?? 0;
      obj.consumptionTax += cur.consumptionTax ?? 0;
      obj.taxIncluded += cur.taxIncluded ?? 0;
      return obj;
    });
    return results;
  }, [dataView]);

  const handleGetSubCategory = async (): Promise<any> => {
    try {
      const res = await service.getSubCategory();
      if (res?.results) {
        setSubCategory(handleFormatSubCategory(res.results));
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const handleFormatSubCategory = (data: any) => {
    const arr: SubCategory[] = data.map((item: any) => {
      return {
        label: item.description,
        value: item.id,
        code: item.code,
      };
    });
    return arr;
  };

  const handleOpenCalendar = (open: boolean) => {
    if (menu === MENU_ITEM[0].key) {
      if (open) {
        searchParams.set("openFilterCM0024", "1");
        setFromValuePrev(searchParams.get(PARAMS.from));
        setToValuePrev(searchParams.get(PARAMS.to));
      } else {
        searchParams.delete("openFilterCM0024");
        if (fromValue !== fromValuePrev || toValue !== toValuePrev) {
          searchParams.delete("isChangeFromTo");
        } else {
          searchParams.set("isChangeFromTo", "0");
        }
      }
    } else {
      if (open) {
        searchParams.set("openFilterCM0024", "1");
        setFromValuePrev2(searchParams.get(PARAMS.from2));
        setToValuePrev2(searchParams.get(PARAMS.to2));
      } else {
        searchParams.delete("openFilterCM0024");
        if (fromValue !== fromValuePrev2 || toValue !== toValuePrev2) {
          searchParams.delete("isChangeFromTo");
        } else {
          searchParams.set("isChangeFromTo", "0");
        }
      }
    }

    setSearchParams(searchParams);
  };

  const exportData = () => {
    if (menu === MENU_ITEM[0].key) dispatch(setIsExportDataCM0024(true));
    else dispatch(setIsExportDataCM0026(true));
  };

  const formatDataExportCM0024 = (data: any) => {
    const listCompany: any[] = [];
    let listOutsourceCostInformation: any[] = [];
    const tableData = data.map((item: any, index: number) => {
      if (item?.companyId)
        listCompany.push({
          companyId: item.companyId,
          companyName: item?.traderName,
        });
      listOutsourceCostInformation = [
        ...listOutsourceCostInformation,
        ...formatChild1(item?.children, item?.traderName)
          .listOutsourceCostInformation,
      ];
      return {
        dataTotal: {
          no: index + 1,
          traderName: item?.traderName,
          recordedAmount: `${helpers.formatCurrency(item?.recordedAmount)}円`,
          taxation2: `${helpers.formatCurrency(item?.taxation2)}円`,
          taxExempt2: `${helpers.formatCurrency(item?.taxExempt2)}円`,
          excludingTax: `${helpers.formatCurrency(item?.excludingTax)}円`,
          consumptionTax: `${helpers.formatCurrency(item?.consumptionTax)}円`,
          taxIncluded: `${helpers.formatCurrency(item?.taxIncluded)}円`,
        },
        child1: formatChild1(item?.children, item?.traderName).child1,
      };
    });
    const res = {
      tableData,
      listCompany,
      listOutsourceCostInformation,
    };
    return res;
  };

  const formatChild1 = (data: any, companyName: string) => {
    let listOutsourceCostInformation: any[] = [];
    const arr = data.map((item: any, index: number) => {
      listOutsourceCostInformation = [
        ...listOutsourceCostInformation,
        {
          header: {
            companyName,
            projectName: item?.traderName,
          },
          data: formatChild2(item?.children).listOutsourceCostInformation,
        },
      ];
      return {
        no: index + 1,
        projectName: item?.traderName,
        recordedAmount: `${helpers.formatCurrency(item?.recordedAmount)}円`,
        taxation2: `${helpers.formatCurrency(item?.taxation)}円`,
        taxExempt2: `${helpers.formatCurrency(item?.taxExempt)}円`,
        excludingTax: `${helpers.formatCurrency(item?.excludingTax)}円`,
        consumptionTax: `${helpers.formatCurrency(item?.consumptionTax)}円`,
        taxIncluded: `${helpers.formatCurrency(item?.taxIncluded)}円`,
        child2: formatChild2(item?.children).child2,
      };
    });
    const res = {
      child1: arr,
      listOutsourceCostInformation,
    };
    return res;
  };

  const formatChild2 = (data: any) => {
    const listOutsourceCostInformation: any[] = [];
    const arr = data.map((item: any, index: number) => {
      if (item?.outsourceCostInformationId)
        listOutsourceCostInformation.push({
          outsourceCostInformationId: item?.outsourceCostInformationId,
          constructionName: item?.constructionName,
          contractWork: item?.contractWork,
          targetPeriod: item?.targetPeriod,
        });
      return {
        no: index + 1,
        constructionName: item?.constructionName,
        contractWork: item?.contractWork,
        paymentDate: item?.datePayment,
        targetPeriod: item?.targetPeriod,
        recordedAmount: `${helpers.formatCurrency(item?.recordedAmount)}円`,
        taxation2: `${helpers.formatCurrency(item?.taxation)}円`,
        taxExempt2: `${helpers.formatCurrency(item?.taxExempt)}円`,
        excludingTax: `${helpers.formatCurrency(item?.excludingTax)}円`,
        consumptionTax: `${helpers.formatCurrency(item?.consumptionTax)}円`,
        taxIncluded: `${helpers.formatCurrency(item?.taxIncluded)}円`,
      };
    });
    const res = {
      child2: arr,
      listOutsourceCostInformation,
    };
    return res;
  };

  const exportDataCM0024 = async () => {
    try {
      dispatch(setLoading(true));
      // const res = formatDataExportCM0024(dataView);
      // const dataCM017_2 = await getAllDataCM017_2(res.listCompany);
      // // CM0024_Popup1_TemplateExport(dataCM017_2, 'popup1');

      // const dataCM0012 = await getAllDataCM0012ByCompanyAndProject(
      //   res.listOutsourceCostInformation
      // );
      // // CM0024_Popup2_TemplateExport(dataCM0012, 'popup2');
      // const data = {
      //   parent: {
      //     aggregationPeriod: {
      //       from: moment(from).startOf("month").format(COMMON.FORMAT_DATE2),
      //       to: moment(to).endOf("month").format(COMMON.FORMAT_DATE2),
      //     },
      //     outputDate: moment().format(COMMON.FORMAT_DATE_OM),
      //     tableData: res.tableData,
      //   },
      //   popup1: dataCM017_2,
      //   popup2: dataCM0012,
      // };
      const fileName = `社内収支_外注費_業者別_${moment().format(
        COMMON.FORMAT_DATE_SUBMIT
      )}`;
      // await CM0024_TemplateExport(data, fileName, totalTable);
      const response = await service.doExportCM0024({
        // projectId: item.id,
        // size: 10,
        // page: page,
        sortBy: COMMON.DEFAULT_SORT_BY,
        sortType: COMMON.DEFAULT_SORT_TYPE,
        from: from,
        to: to,
      } as any);
      doExportForResponse(response, fileName + ".xlsx");
    } catch (e: any) {
      ErrorNotification(e?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
      dispatch(setIsExportDataCM0024(false));
    }
  };

  const formatDataCM017_2 = (listResponse: any, listCompanyName: any) => {
    const arr: any[] = [];
    listResponse.forEach((item: any, index: number) => {
      if (item?.results?.length > 0) {
        const childData = formatChildDataCM017_2(item.results);
        arr.push({
          companyName: listCompanyName[index] ?? "",
          child: childData.child,
          total: `${helpers.formatCurrency(childData.total)}円`,
        });
      }
    });
    return arr;
  };

  const formatChildDataCM017_2 = (data: any) => {
    let total: number = 0;
    const arr = data.map((item: any) => {
      total += item?.value ? item?.value : 0;
      return {
        itemName: item?.name ?? "",
        consumptionTaxCategory: item?.category?.description,
        offsetAmount: `${helpers.formatCurrency(item?.value)}円`,
      };
    });
    const res = {
      total,
      child: arr,
    };
    return res;
  };

  const getAllDataCM0012ByCompanyAndProject = async (
    listOutsourceCostInformation: any[]
  ) => {
    let i = 0;
    let arr: any[] = [];
    for (let i = 0; i < listOutsourceCostInformation.length; i++) {
      const res = await getAllDataCM0012(listOutsourceCostInformation[i]?.data);

      if (res)
        arr.push({
          ...listOutsourceCostInformation[i],
          data: res,
        });
    }

    return arr;
  };

  const getAllDataCM0012 = async (data: any[]): Promise<any> => {
    let i = 0;
    let arrRes: any[] = [];
    do {
      let check = true;
      let page = COMMON.DEFAULT_PAGE;
      let size = COMMON.DEFAULT_SIZE;
      do {
        const params = {
          outsourceCostInformationId: data[i]?.outsourceCostInformationId,
          page,
          size,
        };
        const res = await service.getListMoneyDirectCostOutsourceOffset(params);
        if (res?.results?.length === 0) check = false;
        else arrRes.push(res);
        page++;
      } while (check);
      i++;
    } while (i < data.length);
    return formatDataCM0012(arrRes, data);
  };

  const formatDataCM0012 = (listResponse: any, data: any) => {
    const arr: any[] = [];
    listResponse.forEach((item: any, index: number) => {
      if (item?.results?.length > 0) {
        const childData = formatChildDataCM0012(item.results);
        arr.push({
          titleData: {
            constructionName: data[index]?.constructionName ?? "",
            contractWork: data[index]?.contractWork ?? "",
            targetPeriod: data[index]?.targetPeriod ?? "",
          },
          child: childData.child,
          total: `${helpers.formatCurrency(childData.total)}円`,
        });
      }
    });
    return arr;
  };

  const formatChildDataCM0012 = (data: any) => {
    let total: number = 0;
    const arr = data.map((item: any) => {
      total += item?.value ? item?.value : 0;
      return {
        itemName: item?.name ?? "",
        consumptionTaxCategory: item?.category?.description,
        offsetAmount: `${helpers.formatCurrency(item?.value)}円`,
      };
    });
    const res = {
      total,
      child: arr,
    };
    return res;
  };

  const getStatus = async (from: any, to: any): Promise<any> => {
    try {
      const params = {
        from,
        to,
      };
      const res = await service.getStatus(params);
      if (res?.results) {
        setStatus(res.results);
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const getStatusCM0026 = async (from: any, to: any): Promise<any> => {
    try {
      const params = {
        from,
        to,
      };
      const res = await service.getStatusCM0026(params);
      if (res?.results) {
        setStatus(res.results);
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const checkConditionSubmit = (status: any) => {
    const res = STATUS_SUBMIT_CHECK.find((item: any) => {
      if (
        item?.CODE === status?.code &&
        status?.code === STATUS_SUBMIT_CHECK[4]?.CODE
      )
        return false;
      return item?.CODE === status?.code;
    });
    return res ? false : true;
  };

  const getColorStatus = (status: any) => {
    const res = STATUS_SUBMIT_CHECK.find((item: any) => {
      return item?.CODE === status?.code;
    });
    return res?.COLOR;
  };

  const handleConfirmData = () => {
    setVisibleApprove(true);
  };

  const onCancelSubmitModal = () => {
    setVisibleApprove(false);
  };

  const onSubmit = async () => {
    ConfirmModalAsync({
      onOk: async () => {
        if (menu === MENU_ITEM[0].key) await handleSubmitCM0024();
        else await handleSubmitCM0026();
      },
      onCancel: () => { },
      className: "confirm__modal confirm__modal-purple-oke",
      title: MESSAGE.MESSAGE_TITLE_BEFORE_CREATE,
      description: "外注費" + MESSAGE.MESSAGE_SUBMIT_CM0019,
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_MODAL_2,
    });
  };

  const handleSubmitCM0024 = async () => {
    try {
      dispatch(setLoading(true));
      setVisibleApprove(false);
      const data = {
        startDate: from,
        endDate: to,
        fileName: `社内収支_外注費_業者別_${moment().format(
          COMMON.FORMAT_DATE_SUBMIT
        )}`,
      };
      const response = await service.submit(data);
      SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
      await getStatus(from, to);
    }
  };

  const handleSubmitCM0026 = async () => {
    try {
      dispatch(setLoading(true));
      setVisibleApprove(false);
      const data = {
        startDate: from2,
        endDate: to2,
        fileName: `社内収支_外注費_案件別_${moment().format(
          COMMON.FORMAT_DATE_SUBMIT
        )}`,
      };
      const response = await service.submitCM0026(data);
      SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
      await getStatusCM0026(from2, to2);
    }
  };
  const checkHavingData = (value: boolean) => {
    dispatch(setIsHavingData(value));
  };
  const showConfirmApproveModal = () => {
    dispatch(showModalApproveConfirm());
  };
  return {
    dataView,
    expandKey,
    menu,
    MENU_ITEM,
    PARAMS,
    PARAMS_DEFAULT,
    visibleCM0024,
    from,
    to,
    clear,
    from2,
    to2,
    clear2,
    totalTable,
    sortType,
    sortBy,
    sortTypeModal,
    sortByModal,
    isVisibleApprove,
    status,
    handleToSetFromValue,
    handleToSetToValue,
    funcSetExpendKey,
    funcCheckKeyExpand,
    handleChangeMenuChosen,
    handleCancelModal,
    funcCheckSortOrder,
    funcSortTable,
    funcFilterTime,
    handleConfirmData,
    onCancelSubmitModal,
    onSubmit,
    funcViewModalCM017_1,
    funcViewModalCM0012_2,
    getListReSummaryOutsourceCostCompanyProject,
    getListReSummaryOutsourceCostCompany,
    getListReSummaryCompanyProjectOutsourceCost,
    handleGetSubCategory,
    handleOpenCalendar,
    exportData,
    exportDataCM0024,
    getStatus,
    getStatusCM0026,
    checkConditionSubmit,
    getColorStatus,
    checkHavingData,
    showConfirmApproveModal,
  };
};

export default CM0024Handler;
