
  import { AM024Api } from "./ServiceImpl";
  
  const AM024Service = (am024: AM024Api) => {
    const getRequestLeave = async (params:any): Promise<any> => {
      try{
        return await am024.getRequestLeave(params);
      }catch(error){
        throw(error)
      }
    };
    return {
      getRequestLeave,
    };
  };
  
  export default AM024Service;
  