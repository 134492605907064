import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { HandlerImpl } from "../handler/HandlerImpl";
import BreadcrumbComponent from "../../../../../common/components/breadcrumb/BreadcrumbComponent";
import { Button, Layout, DatePicker, Form } from "antd";
import CompanyComponent from "../component/company";
import HomeSvg from "../../../../../assets/icons/home.svg";
import MenuWrapperComponent from "../../../../../common/components/menu-wrapper";
import moment from "moment";
import COMMON, {
  STATUS_CM_CHILD_TABLE,
} from "../../../../../common/constants/COMMON";
import helpers from "../../../../../common/helpers/common";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import UploadLightSVG from "../../../../../assets/icons/upload-icon-purple.svg";
import CheckIconSVG from "../../../../../assets/icons/check-icon.svg";
import CalendarBlueSVG from "../../../../../assets/icons/calendar-blue.svg";

import TableAboveComponent from "./TableAbove";
import TableBelowComponent from "./TableBelow";
import locale from "antd/es/date-picker/locale/ja_JP";
import CM017_2Component from "../../../CM017_2";
import CM0012_2Component from "../../../CM0012_2";
import ModalApprove from "../../../../../common/components/approval-flow/ModalApprove";
import MESSAGE from "../../../../../common/constants/MESSAGE";
import {
  AUTHORIZATION_CODE,
  SYSTEM_ROLES,
} from "../../../../../common/constants/AUTHORIZATION";
import CM017_2_1Component from "../../../CM017_2_1";
import CM017_2_2Component from "../../../CM017_2_2";
const { RangePicker } = DatePicker;
interface props {
  handler: HandlerImpl;
}
const { Content, Sider } = Layout;

const BREADCRUMB_CM0017: any[] = [
  {
    title: "収支管理",
    url: "",
    icon: (
      <img style={{ paddingBottom: 2, paddingRight: 8 }} alt="" src={HomeSvg} />
    ),
  },
  {
    title: "工事代金支払明細書",
    url: "",
    icon: "",
  },
];

const MainUI = ({ handler }: props) => {
  const [searchParams] = useSearchParams();
  const companyId = searchParams.get("selectedCompany");
  const colabRefId = searchParams.get("selectedColabRefId");

  const sortBy = searchParams.get("sortBy") || undefined;
  const sortType = searchParams.get("sortType") || undefined;
  const isCollapsed = searchParams.get("isCollapsed") ? true : false;
  const fromDate =
    searchParams.get("startDate") || moment().format(COMMON.FORMAT_DATE4);
  const toDate =
    searchParams.get("endDate") || moment().format(COMMON.FORMAT_DATE4);
  const page = searchParams.get("page") || COMMON.DEFAULT_PAGE + "";
  const size = searchParams.get("size") || COMMON.DEFAULT_SIZE + "";
  const keyword = searchParams.get("keyword") || "";
  const companyName = searchParams.get("selectedCompanyName") || "";
  const isVisibleCM0017_2 = useSelector(
    (state: RootState) => state.cm017_2.isVisible
  );
  const isVisibleCM0012_2 = useSelector(
    (state: RootState) => state.cm0012_2.isVisible
  );
  const isVisibleCM0017_2_1 = useSelector(
    (state: RootState) => state.cm017_2_1.isVisible
  );
  const isVisibleCM0017_2_2 = useSelector(
    (state: RootState) => state.cm017_2_2.isVisible
  );
  const isRefresh = useSelector((state: RootState) => state.cm0017.isRefresh);
  const permission = useSelector(
    (state: RootState) => state.auth.authData?.permission
  );

  const isRoleAdmin = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdmin
  );

  const isRoleAdminSystem = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdminSystem
  );

  useEffect(() => {
    Promise.all([handler.getTax(), handler.handleGetSubCategory()]);
  }, []);

  useEffect(() => {
    handler.initiateFilter(fromDate, toDate);
  }, [isRefresh]);

  useEffect(() => {
    if (companyId && colabRefId)
      Promise.all([
        handler.getReSummaryCompanySummaryOutsourceCost({
          companyId: Number(companyId),
          colabRefId: Number(colabRefId),

          from: fromDate,
          to: toDate,
        }),
        handler.getReSummaryCompanySummaryOutsourceCostTotal({
          companyId: Number(companyId),
          colabRefId: Number(colabRefId),

          from: fromDate,
          to: toDate,
        }),
        handler.getReSummaryCompanyDocumentOutsourceCostStatus({
          companyId: Number(companyId),
          colabRefId: Number(colabRefId),

          from: fromDate,
          to: toDate,
        }),
      ]);
  }, [companyId, colabRefId, fromDate, toDate, isRefresh]);

  useEffect(() => {
    (async () => {
      if (companyId && colabRefId)
        await handler.getReSummaryCompanySummaryOutsourceCostDetail({
          companyId: Number(companyId),
          colabRefId: Number(colabRefId),
          from: fromDate,
          to: toDate,
          sortBy,
          sortType,
          page,
          size,
          keyword,
        });
    })();
  }, [
    companyId,
    colabRefId,
    fromDate,
    toDate,
    sortBy,
    sortType,
    page,
    size,
    keyword,
    isRefresh,
  ]);

  return (
    <MenuWrapperComponent chosenKey={52} openKey={5}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
        }}
      >
        <div className="om002-header-container">
          <BreadcrumbComponent data={BREADCRUMB_CM0017} />
          <div className="header-row-title" style={{ marginTop: 8 }}>
            工事代金支払明細書
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <Form form={handler.form} style={{ margin: 0, padding: 0 }}>
            <Form.Item
              name="filterDate"
              style={{ margin: 0, padding: 0, height: "100%" }}
            >
              <RangePicker
                onOpenChange={handler.onDateFilterChange}
                size="large"
                picker="month"
                inputReadOnly
                style={{
                  borderRadius: 6,
                  marginRight: 16,
                  borderColor: "#605BFF",
                  color: "#605BFF",
                  width: 230,
                }}
                placeholder={["", ""]}
                locale={locale}
                className="cm007-datepicker-font"
                format={COMMON.FORMAT_DATE_JP2}
                getPopupContainer={(trigger: any) => trigger.parentNode}
                suffixIcon={<img alt="" src={CalendarBlueSVG} />}
                allowClear={false}
              />
            </Form.Item>
          </Form>
          {/* 
                    "Hiển thị text: 提出
                    Điều kiện enable button: Khi status ở mục #6 là 1 trong số các giá trị 承認済, 否認, 取り下げ
                    Click button sẽ submit nội dung table thành file gửi lên cấp trên lưu ở màn CM0052 & status mục #6 chuyển thành 承認待ち
                    Click button sẽ hiển thị popup confirm, thực hiện submit sẽ hiển thị message submit thành công giống màn CR001
                    Tên file: Tên màn hình_Tên công ty hợp tác_Năm_tháng_ngày submit"											
                    */}
          {permission?.includes(AUTHORIZATION_CODE.S45) ||
          isRoleAdmin ||
          isRoleAdminSystem ? (
            <Button
              disabled={
                handler.summaryStatus?.code &&
                handler.summaryStatus.code ===
                  STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE
                  ? true
                  : false
              }
              onClick={() =>
                handler.postReSummaryCompanySummaryOutsourceCostSubmit({
                  companyId: Number(companyId),
                  fileName: `工事代金支払明細書_${companyName}_${moment().format(
                    COMMON.FORMAT_DATE_SUBMIT
                  )}`,
                  endDate: toDate + COMMON.ISO_8601_HH_MM_SS,
                  startDate: fromDate + COMMON.ISO_8601_HH_MM_SS,
                  companyName,
                  colabRefId: Number(colabRefId),
                })
              }
              style={{ width: 98 }}
              size="large"
              type="primary"
              className="button-purple"
            >
              <div className="center-item">
                <img
                  alt=""
                  src={CheckIconSVG}
                  style={{ marginRight: 8, fontWeight: 700, fontSize: 14 }}
                />
                <div className="export-button">提出</div>
              </div>
            </Button>
          ) : (
            <div></div>
          )}
          {permission?.includes(AUTHORIZATION_CODE.B9) ||
          isRoleAdmin ||
          isRoleAdminSystem ? (
            <Button
              className="button-brown-3"
              style={{
                border: "1px solid #605BFF",
                width: 106,
                marginLeft: 16,
              }}
              size="large"
              onClick={async () =>
                await handler.exportFile(
                  Number(companyId),
                  companyName,
                  fromDate,
                  toDate,
                  Number(colabRefId)
                )
              }
            >
              <div className="center-item">
                <img
                  alt=""
                  src={UploadLightSVG}
                  style={{
                    marginRight: 8,
                    fontWeight: 700,
                    fontSize: 14,
                  }}
                />
                <div className="export-button" style={{ color: "#605BFF" }}>
                  出力
                </div>
              </div>
            </Button>
          ) : (
            <div></div>
          )}
        </div>
      </div>
      <Layout
        style={{ minHeight: "84vh", marginTop: 24 }}
        className={isCollapsed ? "close-side" : "open-side"}
      >
        <Sider>
          <CompanyComponent />
        </Sider>
        <Layout
          style={{
            backgroundColor: "#ffffff",
          }}
        >
          <div
            className="w-100-percent"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
              alignItems: "center",

              marginTop: 24,
              marginRight: 24,
              marginLeft: 24,
              marginBottom: 24,
            }}
          >
            <section style={{ display: "flex" }}>
              <div className="cm007-status-font">ステータス :</div>
              <div
                style={{
                  marginLeft: 8,
                  color: handler.summaryStatus?.code
                    ? STATUS_CM_CHILD_TABLE[
                        `${handler.summaryStatus?.code?.toUpperCase()}` as keyof typeof STATUS_CM_CHILD_TABLE
                      ].COLOR
                    : "#222222",
                }}
                className="cm007-status-value-font"
              >
                {handler.summaryStatus?.name ?? ""}
              </div>
            </section>
            {(permission?.includes(AUTHORIZATION_CODE.S33) && isRoleAdmin) ||
            permission?.includes(AUTHORIZATION_CODE.S44) ? (
              <Button
                style={{ width: 174, marginRight: 48 }}
                size="large"
                type="primary"
                onClick={() =>
                  handler.openCM0017_2({
                    companyId: parseInt(companyId ?? "0"),
                    colabRefId: parseInt(colabRefId ?? "0"),
                    from: fromDate,
                    to: toDate,
                  })
                }
                className="button-purple"
              >
                業者別相殺金額登録
              </Button>
            ) : (
              <div></div>
            )}
          </div>
          <Content
            style={{ margin: "0px 24px 24px 24px", backgroundColor: "#ffffff" }}
          >
            <TableAboveComponent
              openCM0017_2={() => {
                handler.openCM0017_2({
                  companyId: parseInt(companyId ?? "0"),
                  colabRefId: parseInt(colabRefId ?? "0"),
                });
              }}
              funcViewModalCM017_1={handler.funcViewModalCM017_1}
              summaryCost={handler.summaryCost}
              summaryTotal={handler.summaryTotal}
              from={fromDate}
              to={toDate}
              taxSetting={handler.taxSetting}
            />
            <div style={{ height: 4 }}></div>
            <TableBelowComponent
              data={handler.costDetail?.results ?? []}
              openCM0012_2={handler.openCM0012_2}
              summaryTotal={handler.summaryTotal}
              from={fromDate}
              to={toDate}
              companyName={companyName}
              taxSetting={handler.taxSetting}
            />
          </Content>
        </Layout>
      </Layout>
      {isVisibleCM0017_2 ? (
        <CM017_2Component refreshData={handler.refreshData} />
      ) : (
        <></>
      )}
      {isVisibleCM0017_2_1 ? (
        <CM017_2_1Component refreshData={handler.refreshData} />
      ) : (
        <></>
      )}
      {isVisibleCM0017_2_2 ? (
        <CM017_2_2Component refreshData={handler.refreshData} />
      ) : (
        <></>
      )}
      {isVisibleCM0012_2 ? <CM0012_2Component /> : <></>}
    </MenuWrapperComponent>
  );
};

export default MainUI;
