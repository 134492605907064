import ErrorNotification from '../../../../common/components/notification/ErrorNotification';
import { NOTIFICATION_TITLE } from '../../../../common/constants/MESSAGE';
import { DB007Api } from './ServiceImpl';

const DM007Service = (api: DB007Api) => {
    const getTotalMandays = async (params: {
        projectId: number;
        from: string;
        to: string;
        page: number;
        size: number;
    }) => {
        try {
            const response = await api.getTotalMandays(params);
            return response?.data;
        } catch (error: any) {
            ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };

    return {
        getTotalMandays,
    };
};

export default DM007Service;
