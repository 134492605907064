import { Button, Form, Input, Modal, Spin, } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import OMHeaderModalComponent from '../../../../../common/components/om-header-modal/OMHeaderModalComponent';
import REGEX from '../../../../../common/constants/REGEX';
import helpers from '../../../../../common/helpers/common';
import { resetApproveViewModal, setIsEdit } from '../slice/Slice';
import { HandlerImpl } from '../../handler/HandlerImpl';
import { useEffect } from 'react';
import LoadingComponent from '../../../icons/LoadingComponent';
import MESSAGE from '../../../../constants/MESSAGE';
import { STATUS_CM_CHILD_TABLE } from '../../../../constants/COMMON';


const alertUser = (e: any) => {
    e.preventDefault();
    e.returnValue = '';
};

interface props {
    handler: HandlerImpl;
    isViewOnly?: boolean
}

const MainUI = ({ handler, isViewOnly }: props) => {
    const dispatch = useDispatch()
    const document = useSelector((state: RootState) => state.modalApproveView.document);
    const isEdit = useSelector((state: RootState) => state.modalApproveView.isEdit);

    useEffect(() => {
        if (handler.isDirty) {
            window.addEventListener('beforeunload', alertUser);
            return () => {
                window.removeEventListener('beforeunload', alertUser);
            };
        } else {
            window.removeEventListener('beforeunload', alertUser, true);
        }
        return () => {
            window.removeEventListener('beforeunload', alertUser, true);

        }
    }, [handler.isDirty]);

    return document === null ? null : (
        <Modal
            style={{ marginLeft: 300, marginTop: 40 }}
            className="group-detail-modal no-margin-modal approve-view"
            closable={false}
            visible={true}
            maskClosable={false}
            width={996}
            centered={true}
            footer={null}
        >
            <OMHeaderModalComponent title={isEdit ? '変更内容' : '変更内容'} onCancel={() => dispatch(resetApproveViewModal())} />
            <Spin indicator={LoadingComponent} spinning={handler.localLoading}>
                {
                    isEdit ?
                        <div className=" w-100-percent" style={{ padding: '0px 24px 0px 24px', marginTop: 40.5 }}>
                            <Form
                                form={handler.form}
                                layout="horizontal"
                                onFinish={handler.callAPI}
                            >
                                <Form.Item
                                    style={{ margin: 0, padding: 0 }}
                                    name="explanation"
                                    labelAlign="right"
                                    colon={false}
                                    className="form-item-label form-label-height-40"
                                    label={
                                        <div
                                            style={{ marginRight: 16 }} className="approve-form-label"
                                        >
                                            内容
                                        </div>
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message: MESSAGE.MESSAGE_REQUIRED_FIELD,
                                        },
                                        {
                                            pattern: new RegExp(REGEX.EXCEPT_VIETNAMESE),
                                            message: MESSAGE.MESSAGE_INVALID_INPUT,
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder='変更内容を入力してください'
                                        onChange={(e: any) => {
                                            helpers.preventInputJapanese(e);
                                            handler.checkDirty();
                                        }}
                                        maxLength={300}
                                        style={{ height: 40 }}
                                    />
                                </Form.Item>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: 39.5 }}>
                                    <Button
                                        size="large"
                                        type="primary"
                                        style={{ marginLeft: 16, width: 76, letterSpacing: -1.5 }}
                                        onClick={async () => await handler.callAPI()}
                                    >
                                        保存
                                    </Button>
                                    <Button size="large" className="om-cancel-button" onClick={() => {
                                        dispatch(setIsEdit(false));
                                    }} style={{ marginRight: 24 }}>
                                        キャンセル
                                    </Button>
                                </div>
                            </Form>
                        </div>
                        :
                        <>
                            <div className=" w-100-percent" style={{ padding: '0px 24px 0px 24px', marginTop: 25, marginBottom: 24 }}>
                                <section style={{ width: '100%', minHeight: 71, display: "flex", alignContent: 'center', alignItems: 'center' }}>
                                    <div className='approve-view-label ' style={{ minWidth: 28 }} >
                                        内容
                                    </div>
                                    <div style={{ marginLeft: 24 }} className='approve-view-content'>
                                        {document?.note ?? ''}
                                    </div>
                                </section>
                            </div>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', }}>
                                {
                                    document?.status?.code === STATUS_CM_CHILD_TABLE.MONEY_APPROVED.CODE && !isViewOnly ?
                                        <Button
                                            size="large"
                                            type="primary"
                                            style={{ marginLeft: 16, width: 76, letterSpacing: -1.5 }}
                                            onClick={() => handler.changeToEditState()}
                                        >
                                            編集
                                        </Button>
                                        : <></>
                                }
                                <Button size="large" className="om-cancel-button"
                                    onClick={() => dispatch(resetApproveViewModal())}
                                    style={{ marginRight: 48 }}>
                                    閉じる
                                </Button>
                            </div>
                        </>
                }
            </Spin>

        </Modal >
    );
};

export default MainUI;
