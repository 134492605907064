// eslint-disable-next-line @typescript-eslint/no-unused-vars

import axiosConfig from "../../../../../config/axiosConfig";

const PM011Api = () => {
  const getMyCompany = async (params: any): Promise<any> => {
    try {
      const url = "/invite/my-company";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getListMyMember = async (params: unknown): Promise<any> => {
    try {
      const url = "/invite/list-my-member";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const addToQueue = async (data: any): Promise<any> => {
    try {
      const url = "/invite/add-to-queue";
      const response = await axiosConfig.post(url, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getListSubStruction = async (params: any): Promise<any> => {
    try {
      const url = "/construction/list-subconstruction";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  const getSubConstructionListMember = async (params: any): Promise<any> => {
    try {
      const url = "/subconstruction/list-member";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getConstructionList = async (params: {
    projectId: string;
  }): Promise<any> => {
    try {
      const url = "/construction/list";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getInviteConstructionMember = async (params: any): Promise<any> => {
    try {
      const url = "/invite/my-construction-member";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  return {
    getMyCompany,
    getListMyMember,
    addToQueue,
    getInviteConstructionMember,
    getSubConstructionListMember,
    getListSubStruction,
    getConstructionList,
  };
};

export default PM011Api;
