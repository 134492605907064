import { Form, Input, InputNumber, Tooltip } from "antd";
import { memo } from "react";
import "moment/locale/ja";

import SquarePurple from "../../../../../assets/icons/squarer-purple.svg";
import VectorMinus from "../../../../../assets/icons/vector-minus.svg";

import { CR001AmountRelateHandler } from "../handler/HandlerImpl";
import REGEX from "../../../../../common/constants/REGEX";
import MESSAGE from "../../../../../common/constants/MESSAGE";
import { onBlurTrimFormItem } from "../../../../../common/helpers/typeInput";
import helpers from "../../../../../common/helpers/common";
import COMMON from "../../../../../common/constants/COMMON";
import UploadEvidenceComponent from "../../../../../common/components/common-form-item-cm/upload-evidence";
import DocumentIconSvg from "../../../../../assets/icons/document-icon.svg";
import UploadFileCRComponent from "../../../../../common/components/upload-cr";
const { Item } = Form;

interface props {
  handler: CR001AmountRelateHandler;
}

const MESSAGE_REQUIRED = "";
const MAX_LENGTH_TEXT = 30;
const MIN_INPUT_NUMBER = 0;
const REQUIRED_INPUT = false;

const Indirect = ({ handler }: props) => {
  return (
    <>
      <div className="cr001-cr-item bg-header-row">
        <div
          className="cr001-cr-item-child border-bottom border-right cr-7 padding-tb-15"
          style={{ width: "20%" }}
        >
          項目名
        </div>
        <div
          className="cr001-cr-item-child border-bottom border-right cr-3 padding-tb-15"
          style={{ width: "20%" }}
        >
          見積金額（円）
        </div>
        <div
          className="cr001-cr-item-child border-bottom border-right cr-3 padding-tb-15"
          style={{ width: "20%" }}
        >
          原価金額（円）
        </div>
        <div
          className="cr001-cr-item-child border-bottom border-right cr-3 padding-tb-15"
          style={{ width: "20%" }}
        >
          予算金額（円）
        </div>
        <div
          className="cr001-cr-item-child border-bottom border-right cr-7 padding-tb-15"
          style={{ width: "20%" }}
        >
          ファイル
        </div>
      </div>
      {handler.isEdit && (
        <div
          className="cr001-cr-item-add"
          onClick={handler.handleAddListIndirect}
        >
          <img alt="" src={SquarePurple} />
          <span className="text-bold-14">項目追加</span>
        </div>
      )}
      {handler.listIndirect?.map((indirect: any) => {
        return (
          <div
            className="cr001-cr-item cr001-icon-rotate"
            key={`indirectCost${indirect.key}`}
          >
            <div
              className="cr001-cr-item-child border-bottom border-right cr-7 padding-tb-15"
              style={{ width: "20%" }}
            >
              {handler.isEdit ? (
                <div className="cr001-cr-item-bonus">
                  <img
                    alt=""
                    src={VectorMinus}
                    onClick={() =>
                      handler.handleDeleteListIndirect(indirect.key)
                    }
                    className="cr001-margin-icon"
                  />
                  <Item
                    name={[`indirectCost${indirect.key}`, "item"]}
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (value && value !== "") {
                            // CHECK INPUT VIETNAMESE
                            if (!REGEX.NOT_VIETNAM.test(value)) {
                              return Promise.reject(
                                new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                              );
                            }
                            if (value?.length > MAX_LENGTH_TEXT) {
                              return Promise.reject(new Error(""));
                            }
                            return Promise.resolve();
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input
                      maxLength={MAX_LENGTH_TEXT}
                      className="input-cr001"
                      autoComplete="off"
                      onChange={helpers.preventInputJapanese}
                      onBlur={() =>
                        onBlurTrimFormItem(handler.formAmount, [
                          `indirectCost${indirect.key}`,
                          "item",
                        ])
                      }
                    />
                  </Item>
                </div>
              ) : (
                <Tooltip
                  title={
                    handler.listTooltip?.includes(`tooltip${indirect.key}`)
                      ? indirect?.item
                      : ""
                  }
                  getPopupContainer={(trigger: any) => trigger.parentNode}
                  overlayInnerStyle={{ color: "#fff", margin: "0 -4%" }}
                >
                  <span
                    className="cr-text-long-tooltip text-tooltip"
                    id={`tooltip${indirect.key}`}
                  >
                    {indirect?.item}
                  </span>
                </Tooltip>
              )}
            </div>
            <div
              className="cr001-cr-item-child border-bottom border-right cr-3 padding-tb-15 color-gray"
              style={{ width: "20%" }}
            >
              {handler.isEdit ? (
                <Item
                  rules={[
                    {
                      required: REQUIRED_INPUT,
                      message: MESSAGE_REQUIRED,
                    },
                  ]}
                  name={[`indirectCost${indirect.key}`, "estimateMoney"]}
                >
                  <InputNumber
                    controls={false}
                    maxLength={COMMON.MAX_INPUT_NUMBER}
                    max={COMMON.MAX_NUMBER_VALUE}
                    min={MIN_INPUT_NUMBER}
                    className="input-cr001 input-width-40"
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Item>
              ) : (
                handler.checkFormatValue(indirect?.estimateMoney, false, true)
              )}
            </div>
            <div
              className="cr001-cr-item-child border-bottom border-right cr-3 padding-tb-15 color-gray"
              style={{ width: "20%" }}
            >
              {handler.isEdit ? (
                <Item
                  rules={[
                    {
                      required: REQUIRED_INPUT,
                      message: MESSAGE_REQUIRED,
                    },
                  ]}
                  name={[`indirectCost${indirect.key}`, "costMoney"]}
                >
                  <InputNumber
                    controls={false}
                    maxLength={COMMON.MAX_INPUT_NUMBER}
                    max={COMMON.MAX_NUMBER_VALUE}
                    min={MIN_INPUT_NUMBER}
                    className="input-cr001 input-width-40"
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Item>
              ) : (
                handler.checkFormatValue(indirect?.costMoney, false, true)
              )}
            </div>
            <div
              className="cr001-cr-item-child border-bottom border-right cr-3 padding-tb-15 cr003-item-labor-cost-percent color-gray"
              style={{ width: "20%" }}
            >
              {handler.isEdit ? (
                <>
                  <Item
                    rules={[
                      {
                        required: REQUIRED_INPUT,
                        message: MESSAGE_REQUIRED,
                      },
                    ]}
                    name={[`indirectCost${indirect.key}`, "budgetMoney"]}
                  >
                    <InputNumber
                      controls={false}
                      maxLength={COMMON.MAX_INPUT_NUMBER}
                      max={COMMON.MAX_NUMBER_VALUE}
                      min={MIN_INPUT_NUMBER}
                      className="input-cr001 input-width-40"
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                    />
                  </Item>
                  {handler.checkFormatValue(
                    indirect?.budgetMoneyPercent,
                    true,
                    true
                  )}
                </>
              ) : (
                handler.checkFormatValue(indirect?.budgetMoney, false, true) +
                " " +
                handler.checkFormatValue(
                  indirect?.budgetMoneyPercent,
                  true,
                  true
                )
              )}
            </div>
            <div
              className="cr001-cr-item-child border-bottom border-right cr-3 padding-tb-15 cr003-item-labor-cost-percent color-gray"
              style={{ width: "20%" }}
            >
              {handler.isEdit ? (
                <UploadFileCRComponent
                  nameForm={[
                    `indirectCost${indirect.key}`,
                    "documentIndirectCost",
                  ]}
                  fileNameDefault={indirect.document}
                  form={handler.formAmount}
                  afterClear={() =>
                    handler.afterClear(
                      [`indirectCost${indirect.key}`, "documentIndirectCost"],
                      handler.listIndirect,
                      "indirectCost"
                    )
                  }
                />
              ) : indirect?.document?.length != 0 ? (
                <div
                  className="button-style"
                  onClick={() => {
                    handler.handleViewFile(indirect, "indirect");
                  }}
                >
                  <img src={DocumentIconSvg} alt="" />
                </div>
              ) : null}
            </div>
          </div>
        );
      })}

      <div className="cr001-cr-item bg-header-row">
        <div
          className="cr001-cr-item-child border-bottom border-right cr-7 padding-tb-15"
          style={{ width: "20%" }}
        >
          合計金額
        </div>
        <div
          className="cr001-cr-item-child border-bottom border-right cr-3 padding-tb-15 color-gray fw-700"
          style={{ width: "20%" }}
        >
          {handler.checkFormatValue(
            handler.totalIndirectCost?.estimateMoney,
            false,
            true
          )}
        </div>
        <div
          className="cr001-cr-item-child border-bottom border-right cr-3 padding-tb-15 color-gray fw-700"
          style={{ width: "20%" }}
        >
          {handler.checkFormatValue(
            handler.totalIndirectCost?.costMoney,
            false,
            true
          )}
        </div>
        <div
          className="cr001-cr-item-child border-bottom border-right cr-3 padding-tb-15 color-gray fw-700"
          style={{ width: "20%" }}
        >
          {handler.checkFormatValue(
            handler.totalIndirectCost?.budgetMoney,
            false,
            true
          )}{" "}
          {handler.checkFormatValue(
            handler.totalIndirectCost?.budgetMoneyPercent,
            true,
            true
          )}
        </div>
        <div
          className="cr001-cr-item-child border-bottom border-right cr-3 padding-tb-15 color-gray fw-700"
          style={{ width: "20%" }}
        ></div>
      </div>
    </>
  );
};

export default memo(Indirect);
