import ErrorNotification from '../../../../common/components/notification/ErrorNotification';
import COMMON from '../../../../common/constants/COMMON';
import { NOTIFICATION_TITLE } from '../../../../common/constants/MESSAGE';
import helpers from '../../../../common/helpers/common';
import { UserTimeSheet } from '../../AM040/entity/Entity';
import { AM008APIImpl } from './ServiceImpl';

const AM008Service = (api: AM008APIImpl) => {
    const getAttendanceConstructionListMyMember = async (params: {
        constructionId?: number;
        from: string;
        to: string;
    }): Promise<any> => {
        try {
            const timeOffset = COMMON.TIME_ZONE;

            const response = await api.getAttendanceConstructionListMyMember({
                ...params,
                timeOffset,
                from: helpers.getFilterTime(params.from),
                to: helpers.getFilterTimeTo(params.to),
            });
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };
    const getAttendanceOffsiteListMyMember = async (params: {
        from: string;
        to: string;
    }): Promise<any> => {
        try {
            const timeOffset = COMMON.TIME_ZONE;

            const response = await api.getAttendanceOffsiteListMyMember({
                ...params,
                timeOffset,
                from: helpers.getFilterTime(params.from),
                to: helpers.getFilterTimeTo(params.to),
            });
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };
    const getAttendanceUserTimeSheet = async (param: {
        date: string;
        constructionId?: string;
        contractorConstructionId?: string;
        userId: string;
    }): Promise<UserTimeSheet[]> => {
        try {
            const response = await api.getAttendanceUserTimeSheet({
                ...param,
                timeOffset: COMMON.TIME_ZONE,
            });
            const { results } = response.data;
            return results || [];
        } catch (error: any) {
            return [];
        }
    };
    const doExportAM008 = async (params: any): Promise<any> => {
      return await api.doExportAM008(params);
    }
     const doExportAM009 = async (params: any): Promise<any> => {
      return await api.doExportAM009(params);
    }
    return {
        getAttendanceConstructionListMyMember,
        getAttendanceOffsiteListMyMember,
        getAttendanceUserTimeSheet,
        doExportAM008,
        doExportAM009,
    };
};

export default AM008Service;
