// BUILT-IN
import React, { useEffect, useRef } from "react";

// DEPENDENCY
import { Button, Checkbox, Form, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// SOURCE
import COMMON, {
  STATUS_CM_CHILD_TABLE,
} from "../../../../../../../common/constants/COMMON";
import {
  isHasPrivilege,
  isHasPrivilegeByPermission,
} from "../../../../../../../common/helpers/privilege";
import { PRIVILEGE_PM } from "../../../../../../../common/constants/PRIVILEGE";
import { HistoryPaymentHandlerImpl } from "../../../handler/HandlerImpl";
import { MoneyReceivedMoneyListResultsData } from "../../../../entity/Entity";
import helpers from "../../../../../../../common/helpers/common";
import CM0063Component from "../../../../../CM0063";
import { RootState } from "../../../../../../../store";
import InputFormMoneyItemComponent from "../../../../../../../common/components/common-form-item-cm/InputFormMoneyItemComponent";
import UploadEvidenceComponent from "../../../../../../../common/components/common-form-item-cm/upload-evidence";
import DateRangePickerCMComponent from "../../../../../../../common/components/common-form-item-cm/date-range-picker";
import DatePickerCMComponent from "../../../../../../../common/components/common-form-item-cm/date-picker";
import { hideImageViewerModal } from "../../../../../../../common/components/image-viewer-modal/ImageViewSlice";
import { hidePdfViewerModal } from "../../../../../../../common/components/pdf-viewer-modal/pdfViewerModalSlice";
import { AUTHORIZATION_CODE } from "../../../../../../../common/constants/AUTHORIZATION";

import ApproveViewModal from "../../../../../../../common/components/modal-approve-view";
import { CheckBoxCollapseComponentCM007CM009 } from "../../../../../CM007/presenter/component/collapsed-table/ui/CheckBoxAll";
import ApproveEditModal from "../../../../../../../common/components/modal-approve-edit";

// IMAGE
import EditButtonIconSvg from "../../../../../../../assets/icons/edit-button-icon.svg";
import DocumentIconSvg from "../../../../../../../assets/icons/document-icon.svg";
import DeleteButtonIconSvg from "../../../../../../../assets/icons/delete-button-icon.svg";
import AddRowButtonIconSvg from "../../../../../../../assets/icons/add-row-button.svg";
import EyeViewDetailSvg from "../../../../../../../assets/icons/eye-view-detail.svg";

import IconI from "../../../../../../../assets/icons/icon-i-red.svg";

import EyeApprovedIcon from "../../../../../../../assets/icons/eye-approved.svg";
import EyeNotApprovedIcon from "../../../../../../../assets/icons/eye-not-approved.svg";
import EyeRetrieveIcon from "../../../../../../../assets/icons/eye-retrieve.svg";
import EyeWaitingIcon from "../../../../../../../assets/icons/eye-waiting-for-approval.svg";
import { ViewDateWithRange } from "../../../../../../../common/components/view-date/ViewDate";

export const COLUMN_WIDTH_CM005: number[] = [
  100, 350, 200, 174, 174, 174, 174, 174, 174, 174,
];

type props = {
  handler: HistoryPaymentHandlerImpl;
};

const HistoryPaymentUI = ({ handler }: props) => {
  // Params
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const fromDate =
    searchParams.get("filterDate") &&
    moment(searchParams.get("filterDate")).isValid()
      ? moment(searchParams.get("filterDate"))
          .startOf("month")
          .format(COMMON.FORMAT_DATE2)
      : moment().startOf("month").format(COMMON.FORMAT_DATE2);
  const toDate =
    searchParams.get("filterDate") &&
    moment(searchParams.get("filterDate")).isValid()
      ? moment(searchParams.get("filterDate"))
          .endOf("month")
          .format(COMMON.FORMAT_DATE2)
      : moment().endOf("month").format(COMMON.FORMAT_DATE2);

  const projectName = searchParams.get("projectName") || "";
  const constructionId = searchParams.get("constructionId");
  const isExport = useSelector((state: RootState) => state.cm004.isExport);
  const isSubmit = useSelector((state: RootState) => state.cm004.isSubmit);
  const dataLocal = useSelector((state: RootState) => state.cm0063.dataLocal);
  const isRefreshCM005 = useSelector(
    (state: RootState) => state.cm005.isRefresh
  );
  const visibleCM0063 = useSelector(
    (state: RootState) => state.cm0063.isVisible
  );
  const permission = useSelector(
    (state: RootState) => state.auth.authData?.permission
  );
  const isDisabledCheckAll = handler.data.some(
    (element) =>
      element?.status?.code === STATUS_CM_CHILD_TABLE.MONEY_NOT_APPROVED.CODE
  );

  const detailProject = useSelector(
    (state: RootState) => state.cm004.detailProject
  );

  const isRoleAdmin = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdmin
  );

  const checkButton =
    isRoleAdmin ||
    isHasPrivilege(detailProject, [PRIVILEGE_PM]) ||
    permission?.includes(AUTHORIZATION_CODE.S48);

  useEffect(() => {
    return () => {
      dispatch(hideImageViewerModal());
      dispatch(hidePdfViewerModal());
    };
  }, []);
  /**
      Hiển thị: 提出
      Điều kiện enable button: Tồn tại record ở mục #8.3 có trạng thái 未承認
      qClick button sẽ submit thông tin #8.3 thành 1 file tài liệu để gửi lên cấp trên duyệt
      Click button sẽ hiển thị popup confirm, thực hiện submit sẽ hiển thị message submit thành công giống màn CR001
      Tài liệu được lưu ở màn CM0034
      Sau khi submit thì chuyển trạng thái của record chưa submit ở mục #8.3 từ 未承認 sang 承認待ち
  
      Tên file submit đặt theo format: Tên màn_Tên tab_năm_tháng_ngày submit                                                                               "											
  */
  useEffect(() => {
    handler.onDataLocalCM0063Change(dataLocal);
  }, [JSON.stringify(dataLocal)]);

  useEffect(() => {
    (async () => {
      if (constructionId && !isNaN(parseInt(constructionId))) {
        await handler.getMoneyReceivedMoneyList({
          constructionId: parseInt(constructionId),
          page: COMMON.DEFAULT_PAGE,
          size: COMMON.DEFAULT_SIZE,
        });
      }
    })();
  }, [constructionId, toDate, fromDate, isRefreshCM005]);

  const firstRender = useRef(false);
  useEffect(() => {
    if (firstRender.current) {
      (async () => {
        await handler.putMoneySubmitReceiveMoney();
      })();
      return;
    }
    firstRender.current = true;
  }, [isSubmit]);

  const firstRender2 = useRef(false);
  useEffect(() => {
    if (firstRender2.current) {
      (async () => {
        await handler.exportFile(fromDate ?? "", toDate ?? "", projectName);
      })();
      return;
    }
    firstRender2.current = true;
  }, [isExport]);
  useEffect(() => {
    handler.checkIsEnableSubmitButton(handler.checkedList);
  }, [JSON.stringify(handler.checkedList)]);
  const editActionButton = (record: any) => (
    <div>
      <Button
        disabled={
          String(record.id).includes("new")
            ? !handler.isEnableSaveButton(record)
            : false
        }
        type="primary"
        size="small"
        className="cm-save"
        onClick={() => {
          if (constructionId && !isNaN(parseInt(constructionId)))
            handler.saveRecord(record, parseInt(constructionId));
        }}
      >
        保存
      </Button>
      <Button
        size="small"
        className="cm-cancel"
        onClick={() => handler.cancelOperation(record)}
      >
        キャンセル
      </Button>
    </div>
  );
  const renderEye = (status: string) => {
    switch (status) {
      case STATUS_CM_CHILD_TABLE.MONEY_APPROVED.CODE:
        return EyeApprovedIcon;
      case STATUS_CM_CHILD_TABLE.MONEY_NOT_APPROVED.CODE:
        return EyeNotApprovedIcon;
      case STATUS_CM_CHILD_TABLE.MONEY_REJECT.CODE:
        return IconI;
      case STATUS_CM_CHILD_TABLE.MONEY_RETRIEVE.CODE:
        return EyeRetrieveIcon;
      case STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE:
        return EyeWaitingIcon;
    }
  };

  const columns: ColumnsType<MoneyReceivedMoneyListResultsData> = [
    {
      /*
      Hiển thị text: 項目追加
      Click button sẽ thêm 1 record, xem mục #8.3.10
      Điều kiện enable button: Khi status của record không ở status 承認待ち"				
      4/14/2023							
      */
      title: (
        <CheckBoxCollapseComponentCM007CM009
          showCB={handler.data?.length > 0}
          onCheckAllChange={handler.onCheckAllChange}
          checkAll={handler.checkAll}
          isDisabled={!isDisabledCheckAll}
        />
      ),
      key: "No",
      align: "center",
      width: COLUMN_WIDTH_CM005[0],
      render: (_, record, index) => {
        const isHavingWaitingStatus = handler.data.some(
          (element) =>
            element.status.code ===
            STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE
        );

        const conditionCR = true;
        if (record.key === "add")
          return conditionCR ? (
            checkButton ? (
              <div
                className="add-row-style button-style"
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "fit-content",
                }}
                onClick={() => handler.addNewLine(constructionId || "")}
              >
                <img
                  src={AddRowButtonIconSvg}
                  alt=""
                  style={{ marginRight: 8 }}
                />
                <span>項目追加</span>
              </div>
            ) : (
              <></>
            )
          ) : (
            <div
              className="add-row-style disable-style"
              style={{
                display: "flex",
                alignItems: "center",
                width: "fit-content",
              }}
            >
              <img
                src={AddRowButtonIconSvg}
                alt=""
                style={{ marginRight: 8 }}
              />
              <span>項目追加</span>
            </div>
          );
        return (
          <span
            onClick={(event) => {
              event.stopPropagation();
            }}
            className="cell-cm007-style white-space-nowrap"
            style={{ width: "100%", float: "left", textAlign: "left" }}
          >
            {record?.status?.code ===
              STATUS_CM_CHILD_TABLE.MONEY_APPROVED.CODE ||
            record?.status?.code ===
              STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE ||
            record?.status?.code === STATUS_CM_CHILD_TABLE.MONEY_REJECT.CODE ||
            record?.status?.code ===
              STATUS_CM_CHILD_TABLE.MONEY_RETRIEVE.CODE ? (
              <Checkbox
                className="no-hover"
                disabled={true}
                style={{ marginRight: 8 }}
                checked
              />
            ) : (
              <Checkbox
                onChange={(value: any) =>
                  handler.onCheckboxChange(
                    record,
                    value?.target?.checked ? true : false
                  )
                }
                style={{ marginRight: 8 }}
                  checked={handler.isCheckbox(record)}
                  disabled={!record?.status}
              />
            )}
            {index}
          </span>
        );
      },
      onCell: (record) => ({
        colSpan: record.key === "add" ? 10 : 1,
      }),
    },
    {
      title: "対象期間",
      key: "対象期間",
      align: "center",
      width: COLUMN_WIDTH_CM005[1],
      render: (record) => {
        return handler.isEditing(record) ? (
          <DateRangePickerCMComponent name="targetPeriod" record={record} />
        ) : (
          <span className="collapsed-table-row-style">
            <ViewDateWithRange {...record} />
          </span>
        );
      },
      onCell: (record) => ({
        colSpan: record.key === "add" ? 0 : 1,
      }),
    },
    {
      title: "入金日",
      key: "入金日",
      align: "center",
      width: COLUMN_WIDTH_CM005[2],
      render: (record) => {
        return handler.isEditing(record) ? (
          <DatePickerCMComponent record={record} name="receiveAt" />
        ) : (
          <span className="collapsed-table-row-style">
            {`${
              record.receiveAt
                ? moment(record.receiveAt).format(COMMON.FORMAT_DATE_OM)
                : ""
            }
                            `}
          </span>
        );
      },
      onCell: (record) => ({
        colSpan: record.key === "add" ? 0 : 1,
      }),
    },
    {
      title: "請求予定金額",
      key: "請求予定金額",
      align: "center",
      width: COLUMN_WIDTH_CM005[3],
      render: (record) => {
        return handler.isEditing(record) ? (
          <InputFormMoneyItemComponent
            record={record}
            name="planReceiveMoney"
            onValueNumberChange={handler.onValueNumberChange}
          />
        ) : (
          <span className="collapsed-table-row-style">{`${helpers.formatNumberMoney(
            record.planReceiveMoney
          )}円`}</span>
        );
      },
      onCell: (record) => ({
        colSpan: record.key === "add" ? 0 : 1,
      }),
    },
    {
      title: "請求金額",
      key: "請求金額",
      align: "center",
      width: COLUMN_WIDTH_CM005[4],
      render: (record) => {
        return handler.isEditing(record) ? (
          <InputFormMoneyItemComponent
            record={record}
            name="receiveMoney"
            onValueNumberChange={handler.onValueNumberChange}
          />
        ) : (
          <span className="collapsed-table-row-style">{`${helpers.formatNumberMoney(
            record.receiveMoney
          )}円`}</span>
        );
      },
      onCell: (record) => {
        return {
          colSpan: record.key === "add" ? 0 : 1,
        };
      },
    },
    {
      title: "相殺金額",
      key: "相殺金額",
      align: "center",
      width: COLUMN_WIDTH_CM005[5],
      render: (record) => {
        return (
          <div className="center-item">
            <div
              className="collapsed-table-row-style"
              style={{ display: "inline-block" }}
            >{`${helpers.formatNumberMoney(record.offsetMoney)}円`}</div>
            <div
              style={{ marginLeft: 10 }}
              className="button-style"
              onClick={() => handler.viewDetail(record)}
            >
              <img alt="" src={EyeViewDetailSvg} />
            </div>
          </div>
        );
      },
      onCell: (record) => {
        return {
          colSpan: record.key === "add" ? 0 : 1,
        };
      },
    },
    {
      title: "入金額",
      key: "入金額",
      align: "center",
      width: COLUMN_WIDTH_CM005[6],
      render: (record) => {
        return handler.isEditing(record) ? (
          <InputFormMoneyItemComponent
            record={record}
            name="contractorReceiveMoney"
            onValueNumberChange={handler.onValueNumberChange}
          />
        ) : (
          <span>
            {" "}
            {`${helpers.formatNumberMoney(record.contractorReceiveMoney)}円`}
          </span>
        );
      },
      onCell: (record) => ({
        colSpan: record.key === "add" ? 0 : 1,
      }),
    },
    {
      title: "エビデンス",
      key: "エビデンス",
      align: "center",
      width: COLUMN_WIDTH_CM005[7],
      render: (record) =>
        handler.isEditing(record) ? (
          <UploadEvidenceComponent
            nameForm={[`record${record.id}`, "evidence"]}
            fileNameDefault={record.evidence}
            form={handler.form}
            afterClear={handler.afterClearEvidence}
          />
        ) : record?.evidence ? (
          <div
            className="button-style"
            onClick={() => handler.handleViewEvidence(record)}
          >
            <img src={DocumentIconSvg} alt="" />
          </div>
        ) : null,
      onCell: (record) => ({
        colSpan: record.key === "add" ? 0 : 1,
      }),
    },

    {
      title: "ステータス",
      key: "ステータス",
      align: "center",
      width: COLUMN_WIDTH_CM005[8],
      render: (record) => {
        if (record.key === "add") return;
        const checkEditing = handler.isEditing(record);
        if (checkEditing) return editActionButton(record);
        if (record?.status?.code === false) return <></>;

        const color = STATUS_CM_CHILD_TABLE.hasOwnProperty(
          `${record?.status?.code?.toUpperCase()}`
        )
          ? {
              color:
                STATUS_CM_CHILD_TABLE[
                  `${record?.status?.code?.toUpperCase()}` as keyof typeof STATUS_CM_CHILD_TABLE
                ].COLOR,
            }
          : {};

        return (
          <div className="collapsed-table-row-style center-item" style={color}>
            {`${record.status.name}` || ""}
            <div style={{ marginLeft: 8 }} className="center-item">
              {record?.note !== "" ? (
                <div
                  className="button-style"
                  onClick={() =>
                    handler.viewApproveModal(record, Number(constructionId))
                  }
                >
                  <img alt="" src={renderEye(record?.status?.code)} />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        );
      },
      onCell: (record) => ({
        colSpan: record.key === "add" ? 0 : handler.isEditing(record) ? 2 : 1,
      }),
    },
    {
      title: "",
      key: "action",
      align: "center",
      width: COLUMN_WIDTH_CM005[9],
      render: (record) => {
        const {
          MONEY_REJECT,
          MONEY_NOT_APPROVED,
          MONEY_RETRIEVE,
          MONEY_APPROVED,
        } = STATUS_CM_CHILD_TABLE;

        const editButton = [
          MONEY_REJECT.CODE,
          MONEY_NOT_APPROVED.CODE,
          MONEY_RETRIEVE.CODE,
          MONEY_APPROVED.CODE,
        ].includes(record?.status?.code ?? "") ? (
          <div className="button-style" onClick={() => handler.edit(record)}>
            <img alt="" src={EditButtonIconSvg} />
          </div>
        ) : (
          <></>
        );

        const deleteButton = [
          MONEY_REJECT.CODE,
          MONEY_NOT_APPROVED.CODE,
          MONEY_RETRIEVE.CODE,
        ].includes(record?.status?.code ?? "") ? (
          <div
            className="button-style"
            style={{ marginLeft: 8 }}
            onClick={() => handler.deleteRecord(record)}
          >
            <img alt="" src={DeleteButtonIconSvg} />
          </div>
        ) : (
          <></>
        );

        return (
          <div className="center-item">
            {checkButton ? (
              <>
                {editButton}
                {deleteButton}
              </>
            ) : (
              <></>
            )}
          </div>
        );
      },
      onCell: (record) => ({
        colSpan: record.key === "add" ? 0 : handler.isEditing(record) ? 0 : 1,
      }),
    },
  ];

  return (
    <>
      <Form
        form={handler.form}
        style={{ padding: 0, margin: 0 }}
        onValuesChange={(changedValues: any, values: any) => {
          handler.checkEnableButtons(changedValues, values);
        }}
      >
        <Table
          scroll={{ x: "max-content", y: 500 }}
          title={() => <main className="cm005-title-header">請求履歴</main>}
          rowClassName={(record) =>
            handler.isEditing(record) ? "row-no-padding" : ""
          }
          bordered={true}
          columns={columns}
          dataSource={[
            { key: "add" },
            ...helpers.addKeyToDataSource(handler.data),
            ...helpers.addKeyToDataSource(handler.newLines),
          ]}
          className="collapsed-table-header table-footer-inside cm005-table-header summary-border-purple scroll-table"
          style={{ display: "flex", flexDirection: "column-reverse" }}
          pagination={false}
          summary={(pageData) => {
            let planReceiveMoneyTotal = 0;
            let receiveMoneyTotal = 0;
            let offsetMoneyTotal = 0;
            let contractorReceiveMoneyTotal = 0;

            pageData.forEach(
              ({
                planReceiveMoney,
                receiveMoney,
                offsetMoney,
                contractorReceiveMoney,
              }) => {
                planReceiveMoneyTotal += planReceiveMoney || 0;
                receiveMoneyTotal += receiveMoney || 0;
                offsetMoneyTotal += offsetMoney || 0;
                contractorReceiveMoneyTotal += contractorReceiveMoney || 0;
              }
            );

            return (
              <Table.Summary fixed="bottom">
                <Table.Summary.Row
                  className="parent-table-summary"
                  style={{ background: "#605BFF" }}
                >
                  <Table.Summary.Cell index={1} colSpan={3} align="left">
                    合計金額
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={2}
                    colSpan={0}
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={3}
                    colSpan={0}
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell index={4}>
                    <span className="center-item white-space-nowrap">{`${planReceiveMoneyTotal.toLocaleString(
                      "en-US",
                      { maximumFractionDigits: 2 }
                    )}円`}</span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5}>
                    <span className="center-item white-space-nowrap">{`${receiveMoneyTotal.toLocaleString(
                      "en-US",
                      { maximumFractionDigits: 2 }
                    )}円`}</span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6} className="text-center">
                    <span className="center-item white-space-nowrap">{`${offsetMoneyTotal.toLocaleString(
                      "en-US",
                      { maximumFractionDigits: 2 }
                    )}円`}</span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7} className="text-center">
                    <span className="center-item white-space-nowrap">{`${contractorReceiveMoneyTotal.toLocaleString(
                      "en-US",
                      { maximumFractionDigits: 2 }
                    )}円`}</span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={8}
                    align="center"
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={9}
                    align="center"
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={10}
                    align="center"
                  ></Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </Form>
      <ApproveEditModal callbackOk={handler.updateOnNoteModal} />
      <ApproveViewModal callbackOk={handler.updateOnNoteModal} />
      <CM0063Component />
    </>
  );
};

export default HistoryPaymentUI;
