import { useDispatch } from "react-redux";
import { useState } from "react";
import ConfirmModal from "../../../../../common/components/modal/ConfirmModal";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import { CM006UseCase } from "../../usecase/ServiceImpl";
import { resetCM006, setLoading } from "../slice/Slice";
import { showImageViewerModal } from "../../../../../common/components/image-viewer-modal/ImageViewSlice";
import {
  DataTableHistory,
  DataTableOverview,
  TitleEntity,
} from "../../entity/Entity";
import {
  setIsVisibleCM0063,
  setParentCodeCM0063,
} from "../../../CM0063/presenter/slice/Slice";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import COMMON, {
  STATUS_CM_CHILD_TABLE,
} from "../../../../../common/constants/COMMON";
import moment from "moment";
import { convertDataOverview, convertTitleData } from "../../helper";
import { refresh } from "../../../CM0034/presenter/slice/Slice";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import { setDocumentView } from "../../../../../common/components/modal-approve-view/presenter/slice/Slice";
import { ItemApprove } from "../../../../../common/components/approval-flow/ApprovalFlow";
import { showModalApproveConfirm } from "../../../../../common/components/modal-approve-confirm/presenter/slice/Slice";
import { showPdfViewerModal } from "../../../../../common/components/pdf-viewer-modal/pdfViewerModalSlice";

const SCREEN_NAME = "CM005";

const CM006Handler = (CM006Service: CM006UseCase) => {
  // LIB FUNCTION
  const dispatch = useDispatch();

  //state
  const [isOpenViewer, setIsOpenViewer] = useState<boolean>(false);
  const [pdfUrl, setPdfUrl] = useState<any>();
  const [pdfName, setPdfName] = useState<string>("");

  const [dataSummary, setDataSummary] = useState<any>();
  const [title, setTitle] = useState<TitleEntity>({
    constructionId: 0,
    constructionName: "",
    expiredContract: "",
    orderAddress: "",
    projectName: "",
    contractCreateDate: "",
  });

  const [dataOverview, setDataOverview] = useState<DataTableOverview[]>([
    {
      key: "0",
      contractValue: "0円",
      paymentValue: "0円",
      paymentValuePercent: "0%",
      invoiceBalance: "0円",
    },
  ]);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedList, setCheckedList] = useState<any[]>([]);
  const [dataHistory, setDataHistory] = useState<DataTableHistory[]>([]);
  const [currentUserApproval, setCurrentUserApproval] =
    useState<ItemApprove | null>(null);
  const [workflow, setWorkflow] = useState<ItemApprove[]>([]);

  //function

  const getWorkflowReceiveMoneyApprovalProcess = async (documentId: number) => {
    try {
      dispatch(setLoading(true));
      const data = await CM006Service.getWorkflowReceiveMoneyApprovalProcess({
        documentId,
      });
      if (data.results.currentUserApproval)
        setCurrentUserApproval({
          approveDate: data.results.currentUserApproval.approveDate,
          contact_address: "",
          email: data.results.currentUserApproval.email,
          id: data.results.currentUserApproval.id,
          isApprove: data.results.currentUserApproval.approve,
          reject: data.results.currentUserApproval.reject,
          position: "",
          priority: data.results.currentUserApproval.priority,
          username: data.results.currentUserApproval.username,
          note: data.results.currentUserApproval.note,
          fullName: data.results.currentUserApproval.fullName,
        });
      const workflowData: ItemApprove[] = data.results.listApprover.map(
        (element) => {
          let note = element.note;
          if (element.id === data.results?.currentUserApproval?.id)
            note = data.results.currentUserApproval.note;
          const result: ItemApprove = {
            approveDate: element.approveDate,
            contact_address: "",
            email: element.email,
            id: element.id,
            isApprove: element.approve,
            reject: element.reject,
            position: "",
            priority: element.priority,
            username: element.username,
            note: note,
            fullName: element.fullName,
          };
          return result;
        }
      );
      setWorkflow(workflowData);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getListSubmittedReceiveMoney = async (
    documentSubmitId: number
  ): Promise<any> => {
    try {
      const params = {
        documentSubmitId,
      };
      const res = await CM006Service.getListSubmittedReceiveMoney(params);
      if (res?.results && res.results.length > 0) {
        const data: DataTableHistory[] = formatListSubmittedReceiveMoney(
          res.results
        );
        if (data) setDataHistory(data);
        if (res.results.length > 0)
          setTitle(convertTitleData(res.results[0].dataSummary));
        getDataOverview({
          constructionId: res.results[0].dataSummary?.constructionId ?? 0,
          documentId: documentSubmitId,
        });
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const formatListSubmittedReceiveMoney = (data: any) => {
    const dataFormat: DataTableHistory[] = data.map(
      (item: any, index: number) => {
        return {
          key: item?.id,
          id: item?.id,
          status: item?.status,
          paymentDay: `${
            item?.receiveAt
              ? moment(item?.receiveAt).format(COMMON.FORMAT_DATE_OM)
              : ""
          }`,
          targetStage: `${
            item.startDate
              ? moment(item.startDate).format(COMMON.FORMAT_DATE_OM)
              : ""
          }～${
            item.endDate
              ? moment(item.endDate).format(COMMON.FORMAT_DATE_OM)
              : ""
          }`,
          billAmountPlan: item?.planReceiveMoney,
          billAmount: item?.receiveMoney,
          offsetAmount: item?.offsetMoney,
          depositAmount: item?.contractorReceiveMoney,
          evidence: item?.evidence,
          note: item?.note,
        };
      }
    );
    return dataFormat;
  };

  const handleOpenViewer = async (documentId: number, link: string) => {
    try {
      dispatch(setLoading(true));

      const companyId = JSON.parse(localStorage.getItem("user") ?? `{}`)
        ?.company?.id;
      const response = await CM006Service.getPresignLinkS3({
        keyName: `re/${companyId}/${SCREEN_NAME}/${Number(documentId)}/${link}`,
      });
      const checkFile = link?.split(".").pop();
      const evidenceName = link?.split(".").slice(0, -1).join(".");

      setPdfName(evidenceName);
      setPdfUrl(response.results);

      if (checkFile === "pdf") dispatch(showPdfViewerModal());
      else dispatch(showImageViewerModal());
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleCaculateSummary = (data: DataTableHistory[]) => {
    let totalBillAmountPlan = 0;
    let totalBillAmount = 0;
    let totalDepositAmount = 0;
    let totalOffsetAmount = 0;

    data.forEach((element: DataTableHistory) => {
      totalBillAmountPlan += element.billAmountPlan;
      totalBillAmount += element.billAmount;
      totalDepositAmount += element.depositAmount;
      totalOffsetAmount += element.offsetAmount;
    });

    setDataSummary({
      totalBillAmountPlan,
      totalBillAmount,
      totalDepositAmount,
      totalOffsetAmount,
    });
  };

  const openModalOffsetAmount = (data: any) => {
    if (data?.id) {
      dispatch(setIsVisibleCM0063(true));
      dispatch(
        setParentCodeCM0063({
          parentRecordId: data.id,
          status: false,
        })
      );
    }
  };

  const onCancel = () => {
    dispatch(resetCM006());
  };

  const getDataOverview = async (params: any) => {
    const responseA = await CM006Service.getConstructionInformation({
      constructionId: params.constructionId,
      documentId: params.documentId,
    });

    const responseB = await CM006Service.getListConstructionReceivedMoney({
      constructionId: params.constructionId,
    });

    setDataOverview(convertDataOverview(responseA.results, responseB.results));
  };

  const retrieveReceiveMoney = async (documentId: number) => {
    ConfirmModal({
      onOk: async () => {
        try {
          dispatch(setLoading(true));
          const response = await CM006Service.retrieveReceiveMoney({
            documentId: documentId,
            listDocumentReceiveMoneyId: checkedList,
          });
          dispatch(refresh());
          dispatch(resetCM006());
          setCheckAll(false);
          setCheckedList([]);
          SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
        } catch (error) {
          ErrorNotification(NOTIFICATION_TITLE.ERROR);
        } finally {
          dispatch(setLoading(false));
        }
      },
      className: "confirm__modal",
      title: MESSAGE.MESSAGE_TITLE_CM016,
      description: MESSAGE.MESSAGE_DESCRIPTION_CR_13_1,
      extraDescription: MESSAGE.MESSAGE_DESCRIPTION_COMMON_QUESTION_1,
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_MODAL,
      isCenterWithoutMenu: true,
    });
  };

  const onCheckAllChange = (checked: boolean) => {
    setCheckAll(checked);
    if (checked) {
      const checkedItems: any[] = [];
      for (let element of dataHistory) {
        checkedItems.push(element.id);
      }
      setCheckedList(checkedItems);
    } else setCheckedList([]);
  };

  const isCheckbox = (record: any): boolean =>
    checkedList.some((element) => element === record.key);

  const onCheckboxChange = (record: any, checked: boolean) => {
    // setCheckedList(list);
    let newCheckList: any[] = [];

    if (checked) {
      newCheckList = [...checkedList, record.key];
    } else {
      const checkedData = checkedList.filter(
        (element: any) => element !== record.key
      );
      newCheckList = checkedData;
    }
    const possibleItems: any[] = [];

    for (let element of dataHistory) {
      if (
        [
          STATUS_CM_CHILD_TABLE.MONEY_NOT_APPROVED.CODE,
          STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE,
        ].includes(element?.status?.code)
      )
        possibleItems.push(element.id);
    }
    setCheckedList(newCheckList);
    setCheckAll(newCheckList.length === possibleItems.length);
  };

  const viewApproveModal = (record: any) => {
    dispatch(setDocumentView(record));
  };

  const showConfirmApproveModal = () => {
    dispatch(showModalApproveConfirm());
  };
  return {
    dataOverview,
    dataHistory,
    isOpenViewer,
    pdfUrl,
    pdfName,
    dataSummary,
    title,
    checkAll,
    checkedList,
    workflow,
    currentUserApproval,
    handleOpenViewer,
    handleCaculateSummary,
    openModalOffsetAmount,
    onCancel,
    getListSubmittedReceiveMoney,
    getDataOverview,
    retrieveReceiveMoney,
    onCheckAllChange,
    isCheckbox,
    onCheckboxChange,
    viewApproveModal,
    getWorkflowReceiveMoneyApprovalProcess,
    showConfirmApproveModal,
  };
};

export default CM006Handler;
