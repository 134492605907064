import { Table } from "antd";
import { memo, useMemo } from "react";
import { PM012TableHandler } from "../handler/HandlerImpl";
import { COLUMNS } from "./PM012Column";

import EmptyData from "../../../../../assets/icons/empty-data.svg";
import {
  AUTHORIZATION_CODE,
  SYSTEM_ROLES,
} from "../../../../../common/constants/AUTHORIZATION";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { isHasPrivilege } from "../../../../../common/helpers/privilege";
import {
  PRIVILEGE_CREATOR,
  PRIVILEGE_PM,
  PRIVILEGE_SUB_PM,
} from "../../../../../common/constants/PRIVILEGE";

interface props {
  handler: PM012TableHandler;
}

function PM012Table({ handler }: props) {
  const dataView = useMemo(() => {
    return handler.dataMemberPM012;
  }, [handler.dataMemberPM012]);
  const permission = useSelector(
    (state: RootState) => state.auth.authData?.permission
  );
  const projectDetail = useSelector(
    (state: RootState) => state.pm005.projectDetail
  );

  const isRoleAdmin = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdmin
  );

  const isRoleAdminSystem = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdminSystem
  );

  const user = JSON.parse(localStorage.getItem("user") || "{}");
  return (
    <div className="pm012-table">
      <Table
        columns={COLUMNS(handler)}
        pagination={false}
        dataSource={dataView}
        onChange={handler.handleChangeFilterTable}
        rowSelection={
          isHasPrivilege(projectDetail, [
            PRIVILEGE_PM,
            PRIVILEGE_SUB_PM,
            PRIVILEGE_CREATOR,
          ]) ||
          isRoleAdmin ||
          isRoleAdminSystem
            ? {
                selectedRowKeys: handler.selectedRowKeys,
                onChange: handler.handleChangeSelected,
                getCheckboxProps: (record: any) => {
                  let checkManager = false;
                  if (record.userProjectRole) {
                    checkManager =
                      record.userProjectRole[0]?.name === "manager";
                  }
                  return {
                    disabled:
                      checkManager ||
                      +record.id == +user.id ||
                      (!handler.isViewNav ? record.approve : false),
                  };
                },
                columnWidth: 65,
              }
            : undefined
        }
        scroll={{ y: "50vh" }}
        locale={{ emptyText: <img src={EmptyData} alt="" /> }}
      />
    </div>
  );
}

export default PM012Table;
