import { Collapse } from 'antd';
import { MyCompanyHandler } from '../handler/HandlerImpl';
import IconDown from '../../../../../../../assets/icons/arrow.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store';
import { getConstructionTotalMember } from '../../../../helper';
const { Panel } = Collapse;

type Props = {
    handler: MyCompanyHandler;
    construction: any;
};

const MyConstructionComponent = ({ handler, construction }: Props) => {
    const { myCompanyMembers, onCollapseChange } = handler;
    const constructionCountMembers = useSelector((state: RootState) => state.pm011.constructionCountMembers)

    return (
        <Collapse
            className="folder-collapse pm011-collapse-header pm011-construction"
            onChange={(value: any) => onCollapseChange(value, construction.id)}
            expandIcon={({ isActive }) => {
                return (
                    <div className={`${isActive ? 'collapse-icon-active' : ''} collapse-icon`}>
                        <img src={IconDown} alt="" />
                    </div>
                );
            }}
            style={{ marginBottom: 2 }}
        >
            <Panel
                className="pm011-panel"
                key={construction?.id + '' ?? '0'}
                header={
                    <div className="pm011-panel-header-title">
                        <div className="pm011-panel-header-title-left">
                            {construction?.description ?? ''}
                        </div>
                        <div className="pm011-panel-header-title-right">
                            人数：{getConstructionTotalMember(constructionCountMembers, construction.id)}人
                        </div>
                    </div>
                }
            ></Panel>
        </Collapse>
    );
};

export default MyConstructionComponent;
