import moment from "moment";
import COMMON, {
  STATUS_CM_CHILD_TABLE,
} from "../../../common/constants/COMMON";
import { TableDataType } from "./entity/Entity";

const convertDataTable = (data: any[]): TableDataType[] => {
  let output: TableDataType[] = [];
  data.forEach((item: any, idx: number) => {
    const obj: any = {
      key: item.id,
      no: idx + 1,
      status: item?.status,
      documentTitle: item?.fileName ?? "",
      submittedDate:
        moment(item?.submittedDate).format(COMMON.FORMAT_DATE_CI) ?? "",
      submitter: `${item?.user?.fullName ?? ""}${item?.user?.branchName ? `（${item?.user?.branchName}）` : ""
        }`,
      createdAt: item?.createdAt
        ? moment(item?.submittedDate).format(COMMON.FORMAT_DATE_CI)
        : "",
      reason: item?.reason,
    };
    output.push(obj);
  });

  // Filter with status

  output = output.map((item: any, idx: number) => ({ ...item, no: idx + 1 }));

  return output;
};

export { convertDataTable };
