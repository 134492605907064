import { Params } from '../presenter/type/Presenter';
import { CM0027_2Api } from './ServiceImpl';

const CM0027_2Service = (cm0027_2: CM0027_2Api) => {
    const getReSummarySubmittedProjectCompanyOffsetCost = async (params: Params): Promise<any> => {
        try {
            return await cm0027_2.getReSummarySubmittedProjectCompanyOffsetCost(params);
        } catch (error) {
            throw error;
        }
    };

    return {
        getReSummarySubmittedProjectCompanyOffsetCost,
    };
};

export default CM0027_2Service;
