import { FormInstance } from "antd";
import { useState } from "react";

const DoubleCheckHandler = () => {
  const [doubleCheckState, setDoubleCheckState] = useState<boolean | null>(
    null
  );

  const changeDoubleCheck = (data: {
    form: FormInstance;
    constructionId: number;
    doubleCheck: boolean | null;
    userTimeSheetId: number;
    isFirst?: boolean;
  }) => {
    setDoubleCheckState(data.doubleCheck);
    data.form.setFieldValue(
      `timesheet${data.userTimeSheetId}`,
      JSON.stringify({
        constructionId: data.constructionId,
        doubleCheck: data.doubleCheck,
        userTimeSheetId: data.userTimeSheetId,
        isTouched: data.isFirst ? true : false,
      })
    );
  };

  return { changeDoubleCheck, doubleCheckState };
};

export default DoubleCheckHandler;
