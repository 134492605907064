import ASLoginApi from "../AS-Login/infrastructure/api/Api"
import ASLoginService from "../AS-Login/usecase/Service"
import ASChangePasswordApi from "./infrastructure/api/Api"
import ASChangePasswordHandler from "./presenter/handler/Handler"
import ASChangePasswordUI from "./presenter/ui/UI"
import ASChangePasswordService from "./usecase/Service"

const ASChangePasswordComponent = () => {
    const as003Api = ASChangePasswordApi()
    const as001002Api = ASLoginApi()

    const as001002Service = ASLoginService(as001002Api)
    const as003Service = ASChangePasswordService(as003Api)

    const as003Handler = ASChangePasswordHandler(as003Service, as001002Service)

    return <ASChangePasswordUI handler={as003Handler} />
}

export default ASChangePasswordComponent