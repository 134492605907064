import { PM007Api } from "./ServiceImpl";

const PM007Service = (pm007Api: PM007Api) => {
  const getDataDetailProject = async (params: any): Promise<any> => {
    try {
      return await pm007Api.getDataDetailProject(params);
    } catch (error) {
      throw error;
    }
  };

  const getDataStatus = async (params: any): Promise<any> => {
    try {
      return await pm007Api.getDataStatus(params);
    } catch (error) {
      throw error;
    }
  };
  const editDetailProject = async (data: any): Promise<any> => {
    try {
      return await pm007Api.editDetailProject(data);
    } catch (error) {
      throw error;
    }
  };

  const getDataMembers = async (params: any): Promise<any> => {
    try {
      return await pm007Api.getDataMembers(params);
    } catch (error) {
      throw error;
    }
  };
  const getLinkPresignUpload = async (params: any): Promise<any> => {
    try {
      return await pm007Api.getLinkPresignUpload(params);
    } catch (error) {
      throw error;
    }
  };

  const uploadToS3 = async (url: any, data: any): Promise<any> => {
    try {
      return await pm007Api.uploadToS3(url, data);
    } catch (error) {
      throw error;
    }
  };

  const getPresignLinkS3 = async (params: any): Promise<any> => {
    try {
      return await pm007Api.getPresignLinkS3(params);
    } catch (error) {
      throw error;
    }
  };
  const getPdfMap = async (params: any): Promise<any> => {
    try {
      return await pm007Api.getPdfMap(params);
    } catch (error) {
      throw error;
    }
  };

  const getListMember = async (params: any): Promise<any> => {
    try {
      return await pm007Api.getListMember(params);
    } catch (error) {
      throw error;
    }
  };

  return {
    getPdfMap,
    getDataDetailProject,
    editDetailProject,
    getDataStatus,
    getDataMembers,
    getLinkPresignUpload,
    uploadToS3,
    getPresignLinkS3,
    getListMember,
  };
};

export default PM007Service;
