import { Params } from '../presenter/type/Presenter';
import { CM0025_2Api } from './ServiceImpl';

const CM0025_2Service = (cm0025_2: CM0025_2Api) => {
    const getReSummarySubmittedOutsourceCompanyOffsetCost = async (
        params: Params,
    ): Promise<any> => {
        try {
            return await cm0025_2.getReSummarySubmittedOutsourceCompanyOffsetCost(params);
        } catch (error) {
            throw error;
        }
    };

    return {
        getReSummarySubmittedOutsourceCompanyOffsetCost,
    };
};

export default CM0025_2Service;
