import { NOTIFICATION_TITLE } from "./../../../../../common/constants/MESSAGE";

import { RootState } from "./../../../../../store";
import { useState } from "react";
import { Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import ConfirmModal from "../../../../../common/components/modal/ConfirmModal";
import MESSAGE, {
  LABEL_MESSAGE,
} from "../../../../../common/constants/MESSAGE";
import { UseCaseImpl } from "../../usecase/ServiceImpl";
import { resetPM028, setLoading } from "../slice/Slice";
import { useLocation } from "react-router-dom";
import { toggleIsRefreshPM025 } from "../../../PM025/presenter/slice/Slice";
import helpers from "../../../../../common/helpers/common";
import { OrderingCompanyUpdateCreate } from "../../../PM025/entity/PM012Entity";

const DEFAULT_STATE = {
  isDirty: false,
  certType: [],
  fileData: undefined,
};

const Handler = (service: UseCaseImpl) => {
  // LIB FUNCTION
  const dispatch = useDispatch();
  const location = useLocation();
  const [form] = Form.useForm();

  // STATE
  const [isDirty, setIsDirty] = useState(DEFAULT_STATE.isDirty);

  // FUNCTION

  const submit = async (formData: any) => {
    try {
      dispatch(setLoading(true));
      const payload: OrderingCompanyUpdateCreate = {
        companyName: formData?.companyName ?? "",
        furiganaName: formData?.furiganaName ?? "",
        mail: formData?.mail ?? "",
        phoneNumber: formData?.phoneNumber
          ? formData.phoneNumber.replaceAll("-", "")
          : "",
        fax: formData?.fax ? formData.fax.replaceAll("-", "") : "",
        address: formData?.address ?? "",
        confirmationDepartment: formData?.confirmationDepartment ?? "",
        managerName: formData?.managerName ?? "",
      };
      if (formData?.orderId) {
        // update
        const response = await service.putOrderingCompanyUpdate({
          ...payload,
          id: formData?.orderId,
        });
        SuccessNotification(response?.message);
      } else {
        // create
        const response = await service.postOrderingCompanyAdd(payload);
        SuccessNotification(response?.message);
      }
      resetState();
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getDetailOrder = async (orderId: number) => {
    try {
      dispatch(setLoading(true));
      const result = await service.getOrderingCompanyInformation({
        id: orderId,
      });
      setInitialForm(result);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const setInitialForm = (detailOrder: any) => {
    if (!detailOrder) return;
    // basic info
    const defaultValue: any = {
      companyName: detailOrder?.companyName ?? undefined,
      furiganaName: detailOrder?.furiganaName ?? undefined,
      mail: detailOrder?.mail ?? undefined,
      phoneNumber: detailOrder?.phoneNumber
        ? helpers.getPhoneNumberFormat(detailOrder?.phoneNumber)
        : undefined,
      fax: detailOrder?.fax
        ? helpers.getPhoneNumberFormat(detailOrder?.fax)
        : undefined,
      address: detailOrder?.address ?? "",
      confirmationDepartment: detailOrder?.confirmationDepartment ?? undefined,
      managerName: detailOrder?.managerName ?? undefined,
    };
    form.setFieldsValue(defaultValue);
  };

  const resetState = () => {
    if (location.pathname.includes("app-pm025"))
      dispatch(toggleIsRefreshPM025());
    form.resetFields();
    setIsDirty(DEFAULT_STATE.isDirty);
    dispatch(resetPM028());
  };

  const onCancel = () => {
    if (!isDirty) {
      resetState();
      return;
    }
    ConfirmModal({
      onOk: () => resetState(),
      className: "confirm__modal",
      title: MESSAGE.MESSAGE_020,
      description: MESSAGE.MESSAGE_022_1,
      extraDescription: MESSAGE.MESSAGE_022_2,
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_MODAL,
      isCenterWithoutMenu: true,
    });
  };

  const checkDirty = (): void => {
    const formValue = form.getFieldsValue();
    const formProps = Object.keys(formValue);
    for (let i = 0; i < formProps.length; i++) {
      if (form.isFieldTouched(formProps[i])) {
        return setIsDirty(true);
      }
    }
    setIsDirty(false);
  };

  return {
    onCancel,
    checkDirty,
    getDetailOrder,
    submit,
    form,
    isDirty,
  };
};

export default Handler;
