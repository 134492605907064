import { AOP004Api } from './ServiceImpl';

const AOP004Service = (api: AOP004Api) => {
    const getRoleListMember = async (params: any): Promise<any> => {
        try {
            return await api.getRoleListMember(params);
        } catch (error: any) {
            throw error;
        }
    };

    const getRoleDetail = async (params: any): Promise<any> => {
        try {
            return await api.getRoleDetail(params);
        } catch (error: any) {
            throw error;
        }
    };

    const getRoleCategory = async (params: any): Promise<any> => {
        try {
            return await api.getRoleCategory(params);
        } catch (error: any) {
            throw error;
        }
    };

    return {
        getRoleListMember,
        getRoleDetail,
        getRoleCategory,
    };
};

export default AOP004Service;
