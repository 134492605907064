import { Drawer, Empty, Tabs } from "antd";
import { CR001DrawerHandler } from "../handler/HandlerImpl";

import TimeCircle from "../../../../../assets/icons/time-circle-black.svg";
import ShowSvg from "../../../../../assets/icons/show.svg";
import { memo, useEffect } from "react";
import moment from "moment";
import COMMON from "../../../../../common/constants/COMMON";
import { VERSION_APPROVAL, VERSION_UPDATE } from "../CONSTANT";

interface props {
  handler: CR001DrawerHandler;
}
const { TabPane } = Tabs;
function CR001Drawer({ handler }: props) {
  return (
    <>
      <Drawer
        title={
          <>
            <span className="cr001-drawer-header-label">履歴</span>
            <div className="cr001-drawer-header-title">
              <img alt="" src={TimeCircle} />
              {handler.tabVersion == "1" ? (
                <span className="cr001-drawer-header-title-text">
                  最新履歴： 編集履歴 {handler?.listVersion[0]?.version}
                </span>
              ) : (
                <span className="cr001-drawer-header-title-text">
                  最新履歴： 承認履歴 {handler?.listVersionApproval[0]?.version}
                </span>
              )}
            </div>
          </>
        }
        placement="right"
        onClose={handler.handleCloseDrawer}
        visible={handler.isOpenDrawer}
        className="cr001-drawer"
      >
        <Tabs type="card" className="am028" onChange={handler.switchTabVersion}>
          <TabPane tab="編集履歴" key={1}>
            {handler?.listVersion.length === 0 ? (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="データなし"
              />
            ) : (
              <>
                {handler?.listVersion?.map((element: any, index: number) => {
                  return (
                    <div key={element?.id} className="cr001-drawer-item">
                      <div className="cr001-drawer-item-left">
                        <span className="cr001-drawer-item-text">
                          編集履歴 {element?.version}
                        </span>
                        <img
                          src={ShowSvg}
                          alt=""
                          onClick={() =>
                            handler.handleShowVersion(
                              element.id,
                              element?.version,
                              VERSION_UPDATE
                            )
                          }
                          className="cr001-drawer-item-show-version"
                        />
                      </div>
                      <div className="cr001-drawer-item-right">
                        <span
                          className="cr001-drawer-item-right-text"
                          onClick={() =>
                            handler.handleOpenNoteHistory(
                              element.note,
                              element?.version
                            )
                          }
                        >
                          変更内容
                        </span>
                        <span className="cr001-drawer-item-right-time">
                          {moment(element.date).format(COMMON.FORMAT_DATE)}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </TabPane>
          <TabPane tab="承認履歴" key={2}>
            {handler?.listVersionApproval.length === 0 ? (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="データなし"
              />
            ) : (
              <>
                {handler?.listVersionApproval?.map(
                  (element: any, index: number) => {
                    return (
                      <div key={element?.id} className="cr001-drawer-item">
                        <div className="cr001-drawer-item-left">
                          <span className="cr001-drawer-item-text">
                            承認履歴 {element?.version}
                          </span>
                          <img
                            src={ShowSvg}
                            alt=""
                            onClick={() =>
                              handler.handleShowVersion(
                                element.id,
                                element?.version,
                                VERSION_APPROVAL
                              )
                            }
                            className="cr001-drawer-item-show-version"
                          />
                        </div>
                        <div className="cr001-drawer-item-right">
                          <span
                            className="cr001-drawer-item-right-text"
                            onClick={() =>
                              handler.handleOpenStepNoteHistory(element)
                            }
                          >
                            承認フロー詳細
                          </span>
                          <span className="cr001-drawer-item-right-time">
                            {moment(element.date).format(COMMON.FORMAT_DATE)}
                          </span>
                        </div>
                      </div>
                    );
                  }
                )}
              </>
            )}
          </TabPane>
        </Tabs>
      </Drawer>
    </>
  );
}

export default memo(CR001Drawer);
