import { createSlice } from "@reduxjs/toolkit";
import { CM007SliceType } from "../../entity/Entity";

export const initialState: CM007SliceType = {
  isRefresh: false,
  parentStatus: [],
  evidenceLink: "",
  checkedCollapse: [],
};

export const AM007Slice = createSlice({
  name: "cm007",
  initialState,
  reducers: {
    resetCM007: () => initialState,
    refreshCM007: (state) => {
      state.isRefresh = !state.isRefresh;
    },
    setParentStatus: (state, action) => {
      state.parentStatus = action.payload;
    },
    editParentStatus: (state, action) => {
      const newElement = action.payload;
      const newParentStatus = state.parentStatus.map((element) => {
        if (element.id === newElement.id)
          return {
            ...element,
            actualCost: newElement.actualCost ?? element.actualCost,
            status: newElement.status ?? element.status,
            childrenNumber: newElement.childrenNumber ?? element.childrenNumber,
          };
        return element;
      });
      state.parentStatus = newParentStatus;
    },
    setEvidenceLink: (state, action) => {
      state.evidenceLink = action.payload;
    },
    setCheckedCollapseDirect: (state, action) => {
      state.checkedCollapse = action.payload;
    },
    setCheckedCollapse: (state, action) => {
      const data = action.payload;
      const isExisted = state.checkedCollapse.some(
        (element) => element.id === data.id
      );
      if (isExisted) {
        state.checkedCollapse = state.checkedCollapse.map((element) => {
          if (element.id === data.id) return data;
          return element;
        });
      } else state.checkedCollapse = [...state.checkedCollapse, data];
    },
    setCheckedCollapseParent: (state, action) => {
      const id = action.payload.id;
      const isChecked = action.payload.checked;

      if (!isChecked) {
        state.checkedCollapse = state.checkedCollapse.filter(
          (element) => element.id !== id
        );
      } else {
        const isExisted = state.checkedCollapse.some(
          (element) => element.id === id
        );

        if (!isExisted) {
          state.checkedCollapse = [
            ...state.checkedCollapse,
            { id: id, checked: [] },
          ];
        }
      }
    },
  },
});

export const {
  resetCM007,
  refreshCM007,
  setParentStatus,
  editParentStatus,
  setEvidenceLink,
  setCheckedCollapse,
  setCheckedCollapseParent,
  setCheckedCollapseDirect,
} = AM007Slice.actions;

export default AM007Slice.reducer;
