import { ConstructionCountMember } from "./presenter/type/Presenter";

export const getConstructionTotalMember = (
  constructionCountMembers: ConstructionCountMember[],
  constructionId: number
) => {
  const foundConstruction = constructionCountMembers.find(
    (element) => element.id === constructionId
  );
  return foundConstruction ? foundConstruction.totalMember : 0;
};
