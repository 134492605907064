import React from "react";
import { HandlerImpl } from "../handler/HandlerImpl";

import IconDelete from "../../../../../assets/icons/delete-button-icon.svg";
import IconDeleteWhite from "../../../../../assets/icons/delete-icon-2.svg";
import IconPlus from "../../../../../assets/icons/plus-full-white.svg";
import { Empty } from "antd";
import { description } from "./UI";
import MESSAGE from "../../../../../common/constants/MESSAGE";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import {
  AUTHORIZATION_CODE,
  SYSTEM_ROLES,
} from "../../../../../common/constants/AUTHORIZATION";
interface props {
  handler: HandlerImpl;
}
const BodyLeft = ({ handler }: props) => {
  const permission = useSelector(
    (state: RootState) => state.auth.authData?.permission
  );

  const isRoleAdmin = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdmin
  );
  const isRoleAdminBasic = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdminBasic
  );
  return (
    <div className="sw02-body-left">
      <div className="sw02-body-left-list">
        {handler.dataLeft.length > 0 ? (
          handler.dataLeft?.map((menu) => {
            return (
              <div
                key={menu.id}
                className={`sw02-body-left-item ${
                  handler.menu === menu.id?.toString() ? "active" : ""
                }`}
                onClick={() => handler.handleChangeMenu(menu.id, description)}
              >
                <span>{menu.name}</span>
                {isRoleAdminBasic ? (
                  <div
                    className="sw02-body-left-item-remove"
                    onClick={(e) => {
                      e.stopPropagation();
                      handler.handleRemoveDataLeft(menu.id);
                    }}
                  >
                    <img src={IconDelete} alt="" className="icon-default" />
                    <img src={IconDeleteWhite} alt="" className="icon-active" />
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            );
          })
        ) : (
          <div className="data-left-empty" style={{ marginTop: 360 }}>
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={MESSAGE.MESSAGE_NO_DATA}
            />
          </div>
        )}
      </div>
      {isRoleAdminBasic ? (
        <button
          className="sw02-body-left-add"
          onClick={(e) => {
            if (e.detail === 1) {
              e.preventDefault();
              handler.handleAddDataLeft();
            }
          }}
        >
          <span>新規追加</span>
          <img src={IconPlus} alt="" />
        </button>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default BodyLeft;
