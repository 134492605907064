import { CR002Api } from "./ServiceImpl";

const CR002Service = (cr002Api: CR002Api) => {
  const getCompanyList = async (params: any): Promise<any> => {
    try {
      return await cr002Api.getCompanyList(params);
    } catch (error) {
      throw error;
    }
  };
  const addCompanyForList = async (data: any): Promise<any> => {
    try {
      return await cr002Api.addCompanyForList(data);
    } catch (error) {
      throw error;
    }
  };
  const getMaterialSupplier = async (params: any): Promise<any> => {
    try {
      return await cr002Api.getMaterialSupplier(params);
    } catch (error) {
      throw error;
    }
  };

  const addMaterialSupplier = async (data: any): Promise<any> => {
    try {
      return await cr002Api.addMaterialSupplier(data);
    } catch (error) {
      throw error;
    }
  };
  return {
    getCompanyList,
    addCompanyForList,
    getMaterialSupplier,
    addMaterialSupplier,
  };
};

export default CR002Service;
