import { useDispatch, useSelector } from "react-redux";
import { useRef, useState } from "react";
import { CR002UseCase } from "../../usecase/ServiceImpl";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../../common/constants/MESSAGE";
import { Form } from "antd";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import { RootState } from "../../../../../store";
import {
  resetStateCR002,
  setChangeChosenCompany,
  setListCompany,
  setViewChooseCompany,
} from "../slice/Slice";

const COMPANY_ADD = {
  name: "",
  email: "",
};

const CR002Handler = (cr002Service: CR002UseCase) => {
  const dispatch = useDispatch();
  const size = 10;

  const [loading, setLoading] = useState(false);
  const [addCompanies, setAddCompanies] = useState<{
    name: string;
    email: string;
  }>(COMPANY_ADD);
  const [keyword, setKeyword] = useState("");
  const [form] = Form.useForm();
  const refCompany: any = useRef(null);
  const [isAddCompany, setIsAddCompany] = useState(false);
  const [refreshModal, setRefreshModal] = useState(false);

  //pagination
  const [page, setPage] = useState(1);

  //redux
  const isViewChooseCompany = useSelector(
    (state: RootState) => state?.cr002.isViewChooseCompany
  );
  const chosenCompany = useSelector(
    (state: RootState) => state?.cr002.chosenCompany
  );
  const listCompany = useSelector(
    (state: RootState) => state?.cr002.listCompany
  );
  const type = useSelector((state: RootState) => state?.cr002.type);

  const project = useSelector((state: RootState) => state?.pm012.project);
  const getCompanyList = async (params: any) => {
    setLoading(true);
    try {
      const response = await cr002Service.getCompanyList(params);
      setLoading(false);
      if (params.page === 1) {
        return dispatch(setListCompany(response.data.results));
      }
      dispatch(setListCompany([...listCompany, ...response.data.results]));
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      setLoading(false);
      dispatch(setListCompany([]));
    }
  };

  const getMaterialSupplier = async (params: any) => {
    setLoading(true);
    try {
      const response = await cr002Service.getMaterialSupplier(params);
      setLoading(false);
      if (params.page === 1) {
        return dispatch(setListCompany(response.data.results));
      }
      dispatch(setListCompany([...listCompany, ...response.data.results]));
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      setLoading(false);
      dispatch(setListCompany([]));
    }
  };

  const addCompanyForList = async (data: any) => {
    setLoading(true);
    try {
      const response = await cr002Service.addCompanyForList(data);
      if (refCompany) {
        refCompany?.current?.scrollTo(0, 0);
      }
      SuccessNotification(response.data?.message ?? NOTIFICATION_TITLE.SUCCESS);
      setLoading(false);
      setIsAddCompany(false);
      form.resetFields(["name", "email"]);
      setRefreshModal(!refreshModal);
      setPage(1);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      setLoading(false);
    }
  };

  const addMaterialSupplier = async (data: any) => {
    setLoading(true);
    try {
      const response = await cr002Service.addMaterialSupplier(data);
      if (refCompany) {
        refCompany?.current?.scrollTo(0, 0);
      }
      SuccessNotification(response.data?.message ?? NOTIFICATION_TITLE.SUCCESS);
      setLoading(false);
      setIsAddCompany(false);
      form.resetFields(["name", "email"]);
      setRefreshModal(!refreshModal);
      setPage(1);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      setLoading(false);
    }
  };

  //modal choose company
  const handleOpenChooseCompany = () => {
    dispatch(setViewChooseCompany(true));
  };

  const handleCloseChooseCompany = () => {
    dispatch(setViewChooseCompany(false));
    setPage(1);
    dispatch(resetStateCR002());
  };

  const handleChangeChosenCompany = (e: any) => {
    dispatch(setChangeChosenCompany(e.target.value));
  };

  const handleConfirmChosenCompany = () => { };

  const handleAddCompanies = () => {
    setIsAddCompany(true);
  };

  const handleDeleteAddCompanies = () => {
    setIsAddCompany(false);
    form.resetFields(["name", "email"]);
  };

  let myVarCompany: any;
  const onSearchCompanyChange = () => {
    if (myVarCompany) clearTimeout(myVarCompany);
    myVarCompany = setTimeout(function () {
      const searchText = form.getFieldValue("keyword");
      form.setFieldValue("keyword", searchText.trim());
      setPage(1);
      onSearchCompany(searchText?.trim());
    }, 1000);
  };

  const onSearchCompany = (stringWord: string) => {
    setKeyword(stringWord);
  };

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const handleViewModalAddMember = () => {
    // dispatch(setIsVisiblePM013(true));
  };

  const handleSaveAddCompany = (value: any) => {
    if (type === "subcontractor")
      addCompanyForList({
        name: value?.name,
        email: value?.email,
      });
    else
      addMaterialSupplier({
        name: value?.name,
        email: value?.email,
        phoneNumber: "",
        address: "",
      });
  };
  const handleScrollListCompany = () => {
    if (!loading) setPage(page + 1);
  };

  return {
    loading,
    isViewChooseCompany,
    listCompany,
    chosenCompany,
    addCompanies,
    page,
    size,
    keyword,
    form,
    refCompany,
    isAddCompany,
    refreshModal,
    project,
    type,

    handleOpenChooseCompany,
    handleCloseChooseCompany,
    handleChangeChosenCompany,
    handleConfirmChosenCompany,
    handleAddCompanies,
    handleDeleteAddCompanies,
    handleViewModalAddMember,
    onSearchCompanyChange,
    handleChangePage,
    getCompanyList,
    handleSaveAddCompany,
    handleScrollListCompany,
    getMaterialSupplier,
  };
};

export default CR002Handler;
