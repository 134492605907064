import { useNavigate, useSearchParams } from "react-router-dom";
import { AOP004UseCase } from "../../usecase/ServiceImpl";
import {
  KEYWORD_PARAM,
  PAGE,
  PERMISSION_PROJECT_KEY,
  PERMISSION_SYSTEM_KEY,
  SWITCH_TAB_KEY,
  SWITCH_TAB_ONE,
} from "../../contants";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { showH4Modal } from "../../../../../common/components/modal-H4/H4ModalSlice";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import COMMON from "../../../../../common/constants/COMMON";
import { showH1Modal } from "../../../../../common/components/modal-H1/H1ModalSlice";
import { showH2Modal } from "../../../../../common/components/modal-H2/H2ModalSlice";
import { showH3Modal } from "../../../../../common/components/modal-H3/H3ModalSlice";

const AOP004Handler = (service: AOP004UseCase) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [tabKey, setTabKey] = useState<string>(SWITCH_TAB_ONE);

  const [totalMember, setTotalMember] = useState<number>(0);
  const [members, setMembers] = useState<any[]>([]);
  const [dRole, setDRole] = useState<any[]>([]);
  const [role, setRole] = useState<string>("");

  const init = () => {
    const tabkey = searchParams.get(SWITCH_TAB_KEY) ?? SWITCH_TAB_ONE;
    setTabKey(tabkey);
  };

  const switchTab = (tab: string) => {
    searchParams.set(SWITCH_TAB_KEY, tab);
    setSearchParams(searchParams);
  };

  const openModalH4 = () => {
    dispatch(showH4Modal());
  };
  const openModalH1 = () => {
    dispatch(showH1Modal());
  };
  const openModalH2 = () => {
    dispatch(showH2Modal());
  };
  const openModalH3 = () => {
    dispatch(showH3Modal());
  };

  var myVar: any;
  const onSearch = (e: any) => {
    const keyword = e.target.value;
    if (myVar) clearTimeout(myVar);
    myVar = setTimeout(function () {
      searchParams.set(KEYWORD_PARAM, keyword.trim());
      searchParams.set(PAGE, COMMON.DEFAULT_PAGE + "");
      setSearchParams(searchParams);
    }, 1000);
  };

  const onChangePage = (e: any) => {
    searchParams.set(PAGE, e);
    setSearchParams(searchParams);
  };

  const findCategoryId = (data: any[], code: string): number => {
    const finded = data.find((item: any) => item.code === code);
    if (finded) return finded.id;
    return 0;
  };

  const getMemRoleList = async (params: any) => {
    try {
      dispatch(setLoading(true));
      const resp = await service.getRoleListMember({
        roleId: params.roleId,
        page: params.page,
        keyword: params.keyword,
      });
      setTotalMember(resp.pagination.count);
      setMembers(resp.results);

      // Get category
      const respTwo = await service.getRoleCategory({});

      // Get detail
      let categoryId = 0;

      if (params.tab === SWITCH_TAB_ONE) {
        categoryId = findCategoryId(respTwo.results, PERMISSION_PROJECT_KEY);
      } else {
        categoryId = findCategoryId(respTwo.results, PERMISSION_SYSTEM_KEY);
      }

      const responseData = await service.getRoleDetail({
        roleId: params.roleId,
        categoryId: categoryId,
      });
      setDRole(responseData.results.listPermission);
      setRole(responseData?.results?.code);
    } catch (error: any) {
      ErrorNotification(error?.message ?? "");
    } finally {
      dispatch(setLoading(false));
    }
  };

  const redirectAOP003 = (roleId: string, roleName: string) => {
    navigate(`/app-aop003/app-aop004/${roleId}?groupRoleName=${roleName}`);
  };

  return {
    tabKey,
    totalMember,
    members,
    dRole,
    role,

    init,
    switchTab,
    openModalH4,
    openModalH1,
    openModalH2,
    openModalH3,
    getMemRoleList,
    onChangePage,
    onSearch,
    redirectAOP003,
  };
};

export default AOP004Handler;
