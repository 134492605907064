import { Button, Checkbox, Empty, Form, Radio, Select } from "antd";
import { NODATA_TEXT } from "../../../../../../../common/constants/COMMON";

import React from "react";
import { MENU_ITEM, PARAMS } from "../../../../constants";
import { FilterCCUSType } from "../../../../entity/Entity";
import { useSearchParams } from "react-router-dom";

type Props = {
  onResetFilter: () => void;
  handleGetTechnician: () => void;
  handleScrollTechnician: (e: any) => void;
  handleGetDepartment: () => void;
  handleScrollDepartment: (e: any) => void;
  handleSearchOptionTechnician: (e: any) => void;
  onChangeTechnician: (value: string) => void;
  onChangeDepartment: (value: string) => void;
  listTechnician: FilterCCUSType[];
  listDepartment: FilterCCUSType[];
  loadingTechnician: boolean;
  loadingDepartment: boolean;
};

const FilterComponent = ({
  onResetFilter,
  handleGetTechnician,
  handleGetDepartment,
  handleScrollTechnician,
  handleScrollDepartment,
  handleSearchOptionTechnician,
  onChangeTechnician,
  onChangeDepartment,
  listTechnician,
  listDepartment,
  loadingTechnician,
  loadingDepartment,
}: Props) => {
  const [searchParams] = useSearchParams();

  const state = searchParams.get(PARAMS.state) || MENU_ITEM[0].code;

  const content = (
    <React.Fragment>
      {state === MENU_ITEM[0].code ? (
        <>
          <label className="filter-label">部門</label>
          <div className="item-filter-container">
            <Form.Item
              name="department"
              className="item-filter-child"
              labelAlign="right"
            >
              <Select
                size="large"
                listHeight={128}
                filterOption={() => true}
                loading={loadingDepartment}
                onDropdownVisibleChange={(open) => {
                  if (open) handleGetDepartment();
                }}
                onPopupScroll={handleScrollDepartment}
                getPopupContainer={(trigger: any) => trigger.parentNode}
                onChange={onChangeDepartment}
                notFoundContent={
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={NODATA_TEXT}
                  />
                }
                allowClear
              >
                {listDepartment?.map((select: FilterCCUSType) => {
                  return (
                    <Select.Option
                      style={{ alignItems: "center" }}
                      key={select.id}
                      value={select.id}
                    >
                      {select.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>

          <label className="filter-label">技能者名</label>
          <div className="item-filter-container">
            <Form.Item
              name="technician"
              className="item-filter-child"
              labelAlign="right"
            >
              <Select
                size="large"
                listHeight={210}
                filterOption={() => true}
                loading={loadingTechnician}
                onDropdownVisibleChange={(open) => {
                  if (open) handleGetTechnician();
                }}
                onPopupScroll={handleScrollTechnician}
                getPopupContainer={(trigger: any) => trigger.parentNode}
                showSearch
                onSearch={handleSearchOptionTechnician}
                onChange={onChangeTechnician}
                notFoundContent={
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={NODATA_TEXT}
                  />
                }
                allowClear
              >
                {listTechnician?.map((select: any) => {
                  return (
                    <Select.Option
                      style={{ alignItems: "center" }}
                      key={select.id}
                      value={select.id}
                    >
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: 400,
                          color: "#121212",
                        }}
                      >
                        {select.name}
                      </div>
                      <div
                        style={{
                          fontSize: 12,
                          fontWeight: 400,
                          color: "#666",
                        }}
                      >
                        {select.email}
                      </div>
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
        </>
      ) : (
        <Form.Item name="lastReferred" valuePropName="checked">
          <Checkbox>前回検索した技能者IDを除く</Checkbox>
        </Form.Item>
      )}

      <div className="btn-filter-container">
        <Button
          htmlType="submit"
          size="large"
          className="btn-filter-ccus06"
          type="primary"
        >
         {state === MENU_ITEM[0].code ? '絞込み' : '検索'} 
        </Button>
        <Button
          className="reset-filter-btn btn-filter-width"
          onClick={onResetFilter}
          size="large"
        >
          クリア
        </Button>
      </div>
    </React.Fragment>
  );

  return <main style={{ paddingTop: 24 }}>{content}</main>;
};

export default FilterComponent;
