import "../../../assets/styles/AM/AM002.css";
import "../../../assets/styles/OM/OM002.css";
import "../../../assets/styles/OM/OM004.css";
import "../../../assets/styles/AM/AM004.css";
import "../../../assets/styles/AM/AM001.css";

import Service from "./usecase/Service";
import Handler from "./presenter/handler/Handler";
import MainUI from "./presenter/ui/UI";
import Api from "../AM040/api/Api";

const AM042Component = () => {
  const api = Api()
  const service = Service(api)
  const handler = Handler(service)
  return <MainUI handler={handler} />
};

export default AM042Component;
