import React, { useEffect } from "react";
import MenuWrapperComponent from "../../../../../common/components/menu-wrapper";
import { OM005Hanlder } from "../handler/HandlerImpl";
import OM005HeaderComponent from "./Header";
import "../../../../../assets/styles/OM/OM005.css";
import OM005Content from "./Content";
import { useDispatch } from "react-redux";
import { resetOM005 } from "../slice/Slice";
interface props {
  handler: OM005Hanlder;
}

const OM005UI = ({ handler }: props) => {

  useEffect(() => {
    return () => handler.clearReduxOM005()
  }, []);

  return (
    <MenuWrapperComponent chosenKey={72} openKey={7}>
      <OM005HeaderComponent />
      <OM005Content handler={handler} />
    </MenuWrapperComponent>
  );
};

export default OM005UI;
