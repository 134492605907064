import React from 'react';
import moment from 'moment';
import COMMON, { TAX_CODE } from '../../../../../common/constants/COMMON';
import helpers from '../../../../../common/helpers/common';
import { Empty } from 'antd';
import MESSAGE from '../../../../../common/constants/MESSAGE';
import { OffsetCost } from '../../entity/Entity';
import CostTableComponent from './CostTable';
import IconDown from '../../../../../assets/icons/icon-down-small.svg';
import { getSubConstructionBasedOnConstruction } from '../../helper';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import TooltipText from '../../../../../common/components/tooltip-text/TooltipText';

type props = {
    constructions: any[];
    setProjectExpandKeys: (key: any) => void;
    checkProjectKeyExpand: (key: any) => boolean;
    openCM0027_1: (value: any) => void;
    openCM0027_2: (value: any) => void;
};

const ProjectRowComponent = ({
    constructions,
    setProjectExpandKeys,
    checkProjectKeyExpand,
    openCM0027_1,
    openCM0027_2,
}: props) => {
    const isShowOffset = useSelector((state: RootState) => state.cm0027.isShowOffset);
    const subConstructionData = getSubConstructionBasedOnConstruction(constructions);
    return (
        <React.Fragment>
            {subConstructionData.length === 0 ? (
                <tr>
                    <td colSpan={11}>
                        <Empty
                            style={{ width: '100%' }}
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={MESSAGE.MESSAGE_DATA_NOT_FOUND}
                        />
                    </td>
                </tr>
            ) : (
                subConstructionData.map((element, index) => (
                    <React.Fragment key={`table${element.id}`}>
                        <tr
                            key={`table${element.id}`}
                            className="bg-fa button-style"
                            onClick={() => setProjectExpandKeys(element.id)}
                        >
                            {/*+ No
                STT đánh từ bé đến lớn*/}
                            <td colSpan={2}>{index + 1}</td>
                            <td></td>
                            <td>
                                <div style={{ width: 200 }}>
                                    <TooltipText content={element?.constructionName ?? ''} />
                                </div>
                            </td>
                            {/*+ 業者名 Tên công ty hợp tác
                Hiển thị tên công ty hợp tác thuê ngoài ở giấy dự toán CR001_thông tin tiền mới nhất và đã được duyệt */}
                            <td>
                                <div style={{ width: 100 }}>
                                    <TooltipText content={element?.name ?? ''} />
                                </div>
                            </td>

                            <td>{`${helpers.formatNumberMoney(element.actualCost)}円`}</td>
                            {isShowOffset ? (
                                <>
                                    <td>{`${helpers.formatNumberMoney(element.offsetValue)}円`}</td>
                                    <td>{`${helpers.formatNumberMoney(
                                        element.offsetValueTaxFree,
                                    )}円`}</td>
                                    <td>{`${helpers.formatNumberMoney(
                                        element.offsetSummaryValue,
                                    )}円`}</td>
                                    <td>{`${helpers.formatNumberMoney(
                                        element.offsetSummaryValueTaxFree,
                                    )}円`}</td>
                                </>
                            ) : (
                                <></>
                            )}
                            <td>{`${helpers.formatNumberMoney(element.totalOffset)}円`}</td>
                            <td>{`${helpers.formatNumberMoney(
                                element.paymentAmountExcludingTax,
                            )}円`}</td>
                            <td>{`${helpers.formatNumberMoney(element.consumptionTax)}円`}</td>
                            <td>{`${helpers.formatNumberMoney(
                                element.paymentAmountIncludingTax,
                            )}円`}</td>

                            <td>
                                <img
                                    alt=""
                                    src={IconDown}
                                    className={`cm024-icon ${!checkProjectKeyExpand(element.id)
                                            ? ''
                                            : 'cm024-icon-active'
                                        }`}
                                />
                            </td>
                        </tr>
                        {checkProjectKeyExpand(element.id) ? (
                            <CostTableComponent
                                outSourceCost={element.outSourceCost}
                                openCM0027_1={openCM0027_1}
                                openCM0027_2={openCM0027_2}
                            />
                        ) : (
                            <></>
                        )}
                    </React.Fragment>
                ))
            )}
        </React.Fragment>
    );
};

export default ProjectRowComponent;
