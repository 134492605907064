/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { ResponsivePie } from "@nivo/pie";
import { linearGradientDef } from "@nivo/core";
import Legend from "./Legend";
import helpers from "../../helpers/common";

type props = {
  data: any[];
};

const MyResponsivePie = ({ data }: props) => {
  const margin: any = { top: 0, right: 350, bottom: 0, left: 0 };

  const tv = data.find((item: any) => item.code === "tv")?.value ?? 0;
  // const cl = data.find((item: any) => item.code === 'cl')?.value ?? 0;

  const LEGEND003 = [
    {
      color: "linear-gradient(270deg, #2EC5C1 0%, #119CD3 100%)",
      name: "完成出来高",
    },
    {
      color: "linear-gradient(270deg, #FFC53D 0%, #EA6B38 100%)",
      name: "請求残高",
    },
  ];

  const styles: any = {
    root: {
      position: "relative",
      height: 320,
    },
    overlay: {
      position: "absolute",
      top: 0,
      right: margin.right,
      bottom: 0,
      left: 0,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 24,
      color: "#666",
      fontWeight: 400,
      textAlign: "center",
      pointerEvents: "none",
    },
    boldText: {
      fontWeight: 800,
      color: "#222",
    },
  };

  return (
    <div style={styles.root}>
      <ResponsivePie
        data={data}
        margin={margin}
        innerRadius={0.7}
        enableArcLinkLabels={false}
        arcLinkLabelsColor={{ from: "color" }}
        enableArcLabels={false}
        defs={[
          linearGradientDef(
            "gradientA",
            [
              { offset: 0, color: "#2EC5C1" },
              { offset: 100, color: "#119CD3" },
            ],
            {
              gradientTransform: "rotate(90 0.5 0.5)",
            }
          ),
          linearGradientDef(
            "gradientB",
            [
              { offset: 0, color: "#FFC53D" },
              { offset: 100, color: "#EA6B38" },
            ],
            {
              gradientTransform: "rotate(90 0.5 0.5)",
            }
          ),
        ]}
        fill={[
          { match: { id: "完成出来高" }, id: "gradientB" },
          { match: { id: "請求残高" }, id: "gradientA" },
        ]}
        legends={[]}
        tooltip={(point) => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                background: "#fff",
                columnGap: 5,
                borderRadius: 5,
                fontSize: "12px",
                padding: 15,
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                border: "1px solid #eee",
              }}
            >
              <div
                style={{
                  width: 12,
                  height: 12,
                  borderRadius: "50%",
                  background:
                    point.datum.data.code === "tv"
                      ? "linear-gradient(270deg, #2EC5C1 0%, #119CD3 100%)"
                      : "linear-gradient(270deg, #FFC53D 0%, #EA6B38 100%)",
                }}
              ></div>
              {point.datum.label} :{" "}
              {helpers.formatNumberMoney(point.datum.value)}%
            </div>
          );
        }}
      />
      <div style={styles.overlay}>
        <span style={styles.boldText}>{helpers.formatNumberMoney(tv)}%</span>
        <span>完成出来高</span>
      </div>
      <div style={{ position: "absolute", top: 130, right: 200 }}>
        <Legend items={LEGEND003} isPie={true} />
      </div>
    </div>
  );
};

export default MyResponsivePie;
