import { memo } from 'react';
import { Button, Input, Modal, Radio, Form, Spin, Tooltip } from 'antd';
import { PM024ModalCompanyHandler } from '../handler/HandlerImpl';
import { useEffect } from 'react';

import '../../../../../assets/styles/PM/PM024.css';
import REGEX from '../../../../../common/constants/REGEX';
import MESSAGE from '../../../../../common/constants/MESSAGE';
import InfiniteScroll from 'react-infinite-scroll-component';

import PlusPurple from '../../../../../assets/icons/plus-purpose.svg';
import IconSearch from '../../../../../assets/icons/search-icon.svg';
import IconDeleteRed from '../../../../../assets/icons/delete-red.svg';
import IconCheck from '../../../../../assets/icons/akar-icons_circle-check-fill-purple.svg';
import EmptyData from '../../../../../assets/icons/empty-data.svg';
import IconCloseModal from '../../../../../assets/icons/icon-close-modal.svg';
import { onBlurTrimFormItem } from '../../../../../common/helpers/typeInput';
import { ORDER_STATUS } from '../../../../../common/constants/COMMON';

interface props {
    handler: PM024ModalCompanyHandler;
    handleConfirm?: () => void;
    handleCancel?: () => void;
}
function PM012ModalCompany({ handler, handleCancel, handleConfirm }: props) {
    useEffect(() => {
        if (handler.isViewChooseCompany) {
            const params = {
                projectId: handler.project?.id,
                page: handler.page,
                size: handler.size,
                keyword: handler.keyword ? handler.keyword : undefined,
            };
            handler.getCompanyList(params);
        }
    }, [
        handler.project,
        handler.page,
        handler.keyword,
        handler.isViewChooseCompany,
        handler.refCompany,
        handler.refreshModal,
    ]);

    useEffect(() => {
        if (handler.isViewChooseCompany) {
            const params = {
                page: handler.pageInput,
                size: handler.size,
                keyword: handler.keywordMaster ? handler.keywordMaster : undefined,
            };
            handler.getCompanyMasters(params);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        handler.isViewChooseCompany,
        handler.pageInput,
        handler.keywordMaster,
        handler.refreshModal,
    ]);

    return (
        <Modal
            title="協力会社選択"
            className="cr001-history-modal pm018"
            visible={handler.isViewChooseCompany}
            onCancel={!handler.loading ? handleCancel : () => {}}
            closeIcon={<img alt="" src={IconCloseModal} />}
            footer={
                <>
                    <Button
                        size="large"
                        className="button-purple"
                        onClick={handleConfirm}
                        style={{ letterSpacing: -1 }}
                    >
                        確認
                    </Button>
                    <Button
                        size="large"
                        className="btn-text-color"
                        onClick={!handler.loading ? handleCancel : () => {}}
                    >
                        キャンセル
                    </Button>
                </>
            }
        >
            <Spin spinning={handler.loading}>
                <Form form={handler.form} style={{ width: '100%' }} name="pm012-search">
                    <Form.Item name={'keyword'} style={{ margin: 0 }}>
                        <Input
                            size="large"
                            allowClear
                            prefix={<img alt="" src={IconSearch} />}
                            className="pm012-input-search pm018-search pm012-input-search-company"
                            placeholder="検索したい名前を入力してください"
                            onChange={handler.onSearchCompanyChange}
                        />
                    </Form.Item>
                </Form>

                <div className="cr001-external pm018-company">
                    <div className="cr001-external-header">
                        <span className="pm018-external-header-left">会社名</span>
                        <span className="pm018-external-header-center">メールアドレス</span>
                        <span className="pm018-external-header-right">システム導入状況</span>
                    </div>
                    <div
                        className="cr001-external-body pm018-company-body"
                        ref={handler.refCompany}
                    >
                        {handler.listCompany?.length > 0 ? (
                            <Radio.Group
                                onChange={handler.handleChangeChosenCompany}
                                value={handler.chosenCompany}
                            >
                                <InfiniteScroll
                                    dataLength={handler.listCompany?.length}
                                    next={handler.handleScrollListCompany}
                                    hasMore={true}
                                    height={'40vh'}
                                    loader={null}
                                >
                                    {handler.listCompany?.map((company: any) => {
                                        return (
                                            <div
                                                className="cr001-external-item pm018-item"
                                                key={`name${company.id}`}
                                            >
                                                <Radio value={company?.id}>
                                                    <div className="pm018-item-radio">
                                                        <span className="pm018-item-radio-name">
                                                            {company.name}
                                                        </span>
                                                        <div className="pm018-item-radio-email">
                                                            {company.email}
                                                        </div>
                                                        <div
                                                            className={`pm018-text-status ${
                                                                company.status?.code ===
                                                                ORDER_STATUS.ACTIVE_ORDER
                                                                    ? 'pm018-text-status-activate'
                                                                    : 'pm018-text-status-deactivate'
                                                            }`}
                                                            key={`email${company?.id}`}
                                                        >
                                                            {company?.status?.name}
                                                        </div>
                                                    </div>
                                                </Radio>
                                            </div>
                                        );
                                    })}
                                </InfiniteScroll>
                            </Radio.Group>
                        ) : (
                            <div className="pm012-empty-data">
                                <img src={EmptyData} alt="" />
                            </div>
                        )}
                        {handler.isAddCompany ? (
                            <div className="cr001-external-item cr001-company-item-input pm024-bg-white">
                                <Form
                                    name="company-colab-add"
                                    className="pm024-form"
                                    onFinish={handler.handleSaveAddCompany}
                                    form={handler.form}
                                    onValuesChange={handler.handleValueFormInputAutoComplete}
                                >
                                    <Form.Item
                                        className="pm018-modal-form-item-name"
                                        name="name"
                                        rules={[
                                            () => ({
                                                validator(_, value) {
                                                    if (value && value !== '') {
                                                        // CHECK INPUT VIETNAMESE
                                                        if (!REGEX.NOT_VIETNAM.test(value)) {
                                                            return Promise.reject(
                                                                new Error(
                                                                    MESSAGE.MESSAGE_INPUT_INVALID,
                                                                ),
                                                            );
                                                        }
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(
                                                        new Error('会社名を入力してください。'),
                                                    );
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input
                                            placeholder="会社名入力して下さい"
                                            className="pm018-company-name-input"
                                            maxLength={50}
                                            size="large"
                                            onBlur={() => onBlurTrimFormItem(handler.form, 'name')}
                                            onPressEnter={(e) => e.preventDefault()}
                                        />
                                    </Form.Item>

                                    <div className="pm018-autocomplete">
                                        <Form.Item
                                            className="pm018-modal-form-item"
                                            name="email"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: MESSAGE.MESSAGE_REQUIRED_EMAIL,
                                                },
                                                {
                                                    pattern: new RegExp(REGEX.EMAIL_COMPANY),
                                                    message: MESSAGE.MESSAGE_EMAIL_INVALID,
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder="メールアドレスを入力して下さい"
                                                className="pm018-company-email-input"
                                                maxLength={50}
                                                size="large"
                                                onPressEnter={(e) => e.preventDefault()}
                                                onBlur={handler.handleBlurInputAutoComplete}
                                                autoComplete="off"
                                            />
                                        </Form.Item>
                                        <div
                                            className="pm018-autocomplete-popup-wrapper"
                                            ref={handler.refAuto}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                            }}
                                            onMouseDown={(e) => e.preventDefault()}
                                        >
                                            <ul
                                                className="pm018-autocomplete-popup"
                                                onScroll={handler.handleScrollPopup}
                                            >
                                                {handler.companyMasters?.map(
                                                    (company: {
                                                        email: string;
                                                        id: number;
                                                        name: string;
                                                    }) => {
                                                        return (
                                                            <li
                                                                className="pm018-autocomplete-popup-item"
                                                                key={company.id}
                                                                onClick={() => {
                                                                    handler.handleClickItemAutoComplete(
                                                                        company.id,
                                                                        company.email,
                                                                        company.name,
                                                                    );
                                                                }}
                                                            >
                                                                {company.email}
                                                            </li>
                                                        );
                                                    },
                                                )}
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="pm018-action-btn">
                                        <button
                                            type="button"
                                            onClick={() => handler.handleDeleteAddCompanies()}
                                        >
                                            <img alt="" src={IconDeleteRed} />
                                        </button>
                                        <button type="submit">
                                            <img alt="" src={IconCheck} />
                                        </button>
                                    </div>
                                </Form>
                            </div>
                        ) : null}
                    </div>
                </div>

                {!handler?.isAddCompany ? (
                    <div className="pm018-btn-bonus-company">
                        <Tooltip
                            title="勤怠管理と収支管理へデータ連携するための正規データとして参照されます。"
                            placement="bottomLeft"
                            overlayInnerStyle={{
                                width: 254,
                                textAlign: 'center',
                                fontSize: 12,
                                fontWeight: 400,
                                background: '#222222',
                                borderRadius: 3,
                                padding: 10,
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <button
                                    className="pm012-btn-add-company"
                                    onClick={handler.handleAddCompanies}
                                >
                                    <img alt="" src={PlusPurple} />
                                </button>
                                <span style={{ marginTop: 3 }}>マスタ追加 </span>
                            </div>
                        </Tooltip>
                    </div>
                ) : null}
            </Spin>
        </Modal>
    );
}

export default memo(PM012ModalCompany);
