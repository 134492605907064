import { useState, useRef } from "react";
import { Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AM018UseCase } from "../../usecase/ServiceImpl";
import { setLoading, resetAM018 } from "../slice/Slice";
import ConfirmModal from "../../../../../common/components/modal/ConfirmModal";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import COMMON from "../../../../../common/constants/COMMON";
import { LateEarlyCategory, OptionType } from "../type/Presenter";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import {
  RequestCreateLateEarly,
  RequestUpdateLateEarly,
} from "../../entity/Entity";
import { RootState } from "../../../../../store";
import moment from "moment";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import { setRefreshAM010 } from "../../../AM010/presenter/slice/Slice";
import STATUS from "../../../../../common/constants/STATUS";
import { setIsVisibleAM020 } from "../../../AM020/presenter/slice/Slice";

const DEFAULT_STATE = {
  isDirty: false,
};

const AM018Handler = (am018Service: AM018UseCase) => {
  // LIB FUNCTION
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  // STATE
  const [isDirty, setIsDirty] = useState(DEFAULT_STATE.isDirty);

  const [lateEarlyCategory, setLateEarlyCategory] = useState<OptionType[]>([]);

  const [loadingOption, setLoadingOption] = useState(false);
  const [projects, setProjects] = useState<any[]>([]);
  const [constructions, setConstructions] = useState<any[]>([]);
  const [chosenProject, setChosenProject] = useState<boolean>(false);
  const [currentProject, setCurrentProject] = useState<any>();

  const [pageOption, setPageOption] = useState(COMMON.DEFAULT_PAGE);
  const [sizeOption, setSizeOption] = useState(COMMON.DEFAULT_SIZE);
  const [keywordOption, setKeywordOption] = useState<any>(undefined);

  const [pageOptionConstruction, setPageOptionConstruction] = useState(
    COMMON.DEFAULT_PAGE
  );
  const [sizeOptionConstruction, setSizeOptionConstruction] = useState(
    COMMON.DEFAULT_SIZE
  );
  const [keywordOptionConstruction, setKeywordOptionConstruction] =
    useState<any>(undefined);

  const [requestLateEarly, setRequestLateEarly] = useState<any>();
  const [requestApprover, setRequestApprover] = useState<any>({});
  const [projectStatus, setProjectStatus] = useState<any>();

  const [projectIdChoosed, setProjectIdChoosed] = useState<number>(0);
  const [pageOptionConstructionChoosedProject, setPageOptionConstructionChoosedProject] = useState(
    COMMON.DEFAULT_PAGE
  );

  const haveProject = useSelector(
    (state: RootState) => state.am010.haveProject
  );
  const typeModal = useSelector((state: RootState) => state.am018.typeModal);
  const requestId = useSelector((state: RootState) => state.am018.requestId);

  // FUNCTION

  const handleGetProjectFirstOpenModal = async (params: any): Promise<any> => {
    try {
      const data = await getListProject(params);
      setProjects([...data]);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const handleGetConstructionFirstOpenModal = async (
    params: any
  ): Promise<any> => {
    try {
      const data = await getListConstruction(params);
      setConstructions([...data]);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const handleFormatOptionLateEarlyCategory = (data: LateEarlyCategory[]) => {
    const option: OptionType[] = [];
    data.forEach((item) => {
      option.push({
        label: item.name,
        value: item.categoryId,
      });
    });
    return option;
  };

  const getListProject = async (params: {
    page: number;
    size: number;
    keyword?: string;
    statusId?: string;
  }): Promise<any> => {
    const paramsGet: any = { ...params };
    const response = await am018Service.getProjects(paramsGet);
    if (response?.results) {
      return response.results;
    }
  };

  const getListConstruction = async (params: {
    page: number;
    size: number;
    keyword?: string;
    projectId?: number;
  }): Promise<any> => {
    try {
      const paramsGet = { ...params, onlyInvolved: true };
      const res = await am018Service.getAttendanceUserConstruction(paramsGet);
      if (res?.results) {
        const data = formatConstructions(res.results);
        return data;
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const getProject = async (params: any, projectId: number): Promise<any> => {
    const res = await handleFetchApiGetAllProject(params, projectId);
    if (res) setProjects([...res]);
  };

  const handleFetchApiGetAllProject = async (
    params: any,
    projectId: number
  ): Promise<any> => {
    try {
      const res = await am018Service.getProjects(params);
      if (res?.results?.length > 0) {
        if (handleCheckExistProject(res.results, projectId)) return res.results;
        else {
          const param: any = {
            page: params.page + 1,
            size: params.size,
            keyword: params.keyword,
            statusId: params.statusId,
          };
          setPageOption(param.page);
          return res.results?.concat(
            await handleFetchApiGetAllProject(param, projectId)
          );
        }
      } else return [];
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const handleFetchApiGetAllConstruction = async (
    params: any,
    constructionId: number
  ): Promise<any> => {
    try {
      const paramsGet = { ...params, onlyInvolved: true };
      const res = await am018Service.getAttendanceUserConstruction(paramsGet);
      if (res?.results.length > 0) {
        if (handleCheckExistConstruction(res.results, constructionId))
          return res.results;
        else {
          const param: any = {
            page: params.page + 1,
            size: params.size,
            keyword: params.keyword,
            statusId: params.statusId,
          };
          setPageOptionConstruction(param.page);
          return res.results.concat(
            await handleFetchApiGetAllConstruction(param, constructionId)
          );
        }
      } else return [];
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const handleCheckExistProject = (data: any, projectId: number) => {
    const res = data.find((item: any) => item?.id === projectId);
    if (res) {
      setCurrentProject(res);
      return true;
    } else return false;
  };

  const handleCheckExistConstruction = (data: any, constructionId: number) => {
    const res = data.find((item: any) => item?.id === constructionId);
    if (res) return true;
    else return false;
  };

  const handleGetLateEarlyCategory = async (): Promise<any> => {
    const res = await am018Service.getLateEarlyCategory();
    const option: OptionType[] = handleFormatOptionLateEarlyCategory(
      res.results
    );
    setLateEarlyCategory(option);
  };

  const handleGetDetailProjectById = async (
    projectId: number
  ): Promise<any> => {
    try {
      const res = await am018Service.getProjectById({ projectId });
      if (
        res?.results?.id &&
        res?.results?.projectName &&
        res?.results?.constructions
      ) {
        const data = {
          id: res.results.id,
          projectName: res.results.projectName,
          constructions: res.results.constructions,
        };
        return data;
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  let timeOut: any = useRef();
  const handleSearchOption = (searchValue: string) => {
    if (timeOut.current) clearTimeout(timeOut.current);
    timeOut.current = setTimeout(async () => {
      try {
        setLoadingOption(true);
        setKeywordOption(searchValue);
        setPageOption(COMMON.DEFAULT_PAGE);
        setSizeOption(COMMON.DEFAULT_SIZE);
        const data = await getListProject({
          page: COMMON.DEFAULT_PAGE,
          size: COMMON.DEFAULT_SIZE,
          keyword: searchValue,
          statusId: projectStatus,
        });
        if (data) setProjects([...data]);
      } catch (error: any) {
        ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      } finally {
        setLoadingOption(false);
      }
    }, 1000);
  };

  const handleScrollProject = async (e: any): Promise<any> => {
    try {
      setLoadingOption(true);
      const target = e.target;
      if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
        setPageOption(pageOption + 1);
        const data = await getListProject({
          page: pageOption + 1,
          size: sizeOption,
          keyword: keywordOption ? keywordOption : undefined,
          statusId: projectStatus,
        });
        if (data) setProjects([...projects, ...data]);
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      setLoadingOption(false);
    }
  };

  const handleSearchOptionConstruction = (searchValue: string) => {
    if (!chosenProject) {
      if (timeOut.current) clearTimeout(timeOut.current);
      timeOut.current = setTimeout(async () => {
        if (!chosenProject) {
          try {
            setLoadingOption(true);
            setKeywordOptionConstruction(searchValue);
            setPageOptionConstruction(COMMON.DEFAULT_PAGE);
            setSizeOptionConstruction(COMMON.DEFAULT_SIZE);
            const data = await getListConstruction({
              page: COMMON.DEFAULT_PAGE,
              size: COMMON.DEFAULT_SIZE,
              keyword: searchValue,

            });
            if (data) setConstructions([...data]);
          } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
          } finally {
            setLoadingOption(false);
          }
        }
      }, 1000);
    } else {
      if (timeOut.current) clearTimeout(timeOut.current);
      timeOut.current = setTimeout(async () => {
        if (chosenProject) {
          try {
            setLoadingOption(true);
            setKeywordOptionConstruction(searchValue);
            setPageOptionConstructionChoosedProject(COMMON.DEFAULT_PAGE);

            const data = await getListConstruction({
              page: COMMON.DEFAULT_PAGE,
              size: COMMON.DEFAULT_SIZE,
              keyword: searchValue,
              projectId: projectIdChoosed
            });
            if (data) setConstructions([...data]);
          } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
          } finally {
            setLoadingOption(false);
          }
        }
      }, 1000);
    }
  };

  const handleScrollConstruction = async (e: any): Promise<any> => {
    if (typeModal === "create" && !chosenProject) {
      try {
        setLoadingOption(true);
        const target = e.target;
        if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
          setPageOptionConstruction(pageOptionConstruction + 1);
          const data = await getListConstruction({
            page: pageOptionConstruction + 1,
            size: sizeOptionConstruction,
            keyword: keywordOptionConstruction
              ? keywordOptionConstruction
              : undefined,

          });
          if (data) setConstructions([...constructions, ...data]);
        }
      } catch (error: any) {
        ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      } finally {
        setLoadingOption(false);
      }
    } else {
      try {
        setLoadingOption(true);
        const target = e.target;
        if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
          setPageOptionConstructionChoosedProject(pageOptionConstructionChoosedProject + 1);
          const data = await getListConstruction({
            page: pageOptionConstructionChoosedProject + 1,
            size: sizeOptionConstruction,
            keyword: keywordOptionConstruction
              ? keywordOptionConstruction
              : undefined,
            projectId: projectIdChoosed

          });
          if (data) setConstructions([...constructions, ...data]);
        }
      } catch (error: any) {
        ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      } finally {
        setLoadingOption(false);
      }
    }
  };

  const handleFindConstructions = async (projectId: number): Promise<any> => {
    try {
      setChosenProject(true);
      setProjectIdChoosed(projectId)
      if (projects.length !== 0) {
        if (projects.length !== 0) {

          let param = {
            page: COMMON.DEFAULT_PAGE,
            size: COMMON.DEFAULT_SIZE,
            projectId
          }
          const response = await getListConstruction(param);
          setConstructions(response ?? []);
        }

      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const handleFindProjects = async (constructionId: number) => {
    if (typeModal === "create" && !chosenProject && constructions.length > 0) {
      const data = await handleFetchApiGetAllConstruction(
        {
          page: COMMON.DEFAULT_PAGE,
          size: COMMON.DEFAULT_SIZE,
          keyword: undefined,
          statusId: projectStatus,
        },
        constructionId
      );
      if (data) {
        const newData = formatConstructions(data);
        setConstructions([...newData]);
      }
      const goal: any = constructions.find(
        (element: any) => element.id === constructionId
      );
      if (goal?.projectId) {
        const data = await handleFetchApiGetAllProject(
          {
            page: COMMON.DEFAULT_PAGE,
            size: COMMON.DEFAULT_SIZE,
            keyword: undefined,
            statusId: projectStatus,
          },
          goal.projectId
        );
        if (data) setProjects([...data]);
        form.setFieldValue("projectId", goal.projectId);
      }
    }
  };

  const resetProjectWhenSelectOrBlur = async (
    projectId: number
  ): Promise<any> => {
    try {
      setKeywordOption(undefined);
      const data = await handleFetchApiGetAllProject(
        {
          page: COMMON.DEFAULT_PAGE,
          size: COMMON.DEFAULT_SIZE,
          keyword: undefined,
          statusId: projectStatus,
        },
        projectId
      );
      if (data) setProjects([...data]);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const resetConstructionWhenSelectOrBlur = async (
    constructionId: any
  ): Promise<any> => {
    if (chosenProject) {

      try {
        setLoadingOption(true);
        setKeywordOptionConstruction(undefined);
        setPageOptionConstructionChoosedProject(COMMON.DEFAULT_PAGE);

        const data = await getListConstruction({
          page: COMMON.DEFAULT_PAGE,
          size: COMMON.DEFAULT_SIZE,
          keyword: undefined,
          projectId: projectIdChoosed
        });
        if (data) setConstructions(data);
      } catch (error: any) {
        ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      } finally {
        setLoadingOption(false);
      }

    }

    else {
      try {
        setKeywordOptionConstruction(undefined);
        const data = await handleFetchApiGetAllConstruction(
          {
            page: COMMON.DEFAULT_PAGE,
            size: COMMON.DEFAULT_SIZE,
            keyword: undefined,
          },
          constructionId
        );
        if (data) setConstructions([...data]);
      } catch (error: any) {
        ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      }
    }
  };

  const handleCreateLateEarly = async (): Promise<any> => {
    const dataRequestCreate: RequestCreateLateEarly = {
      requestCategoryId: form.getFieldValue("requestCategoryId"),
      requestDate: form.getFieldValue("requestDate"),
      requestTime: form.getFieldValue("requestTime"),
      note: form.getFieldValue("note"),
    };
    let response: any;
    if (haveProject)
      dataRequestCreate.constructionId = form.getFieldValue("constructionId");
    if (typeModal === "update") {
      const dataRequestUpdate: RequestUpdateLateEarly = {
        ...dataRequestCreate,
        requestId,
      };
      ConfirmModal({
        onOk: async () => {
          try {
            dispatch(setLoading(true));
            response = await am018Service.updateLateEarly(dataRequestUpdate);
            dispatch(setIsVisibleAM020(false));
            SuccessNotification(
              response?.message ?? NOTIFICATION_TITLE.SUCCESS
            );
            resetState();
            dispatch(setRefreshAM010());
          } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
          } finally {
            dispatch(setLoading(false));
          }
        },
        className: "confirm__modal confirm__modal-purple-oke",
        title: MESSAGE.MESSAGE_TITLE_BEFORE_CREATE,
        description: MESSAGE.MESSAGE_DESCRIPTION_BEFORE_CREATE_ATTENDANCE,
        canceText: LABEL_MESSAGE.CANCEL_MODAL,
        okText: LABEL_MESSAGE.OK_MODAL,
        isCenterWithoutMenu: true,
      });
    } else
      ConfirmModal({
        onOk: async () => {
          try {
            dispatch(setLoading(true));
            response = await am018Service.createLateEarly(dataRequestCreate);
            SuccessNotification(
              response?.message ?? NOTIFICATION_TITLE.SUCCESS
            );
            resetState();
            dispatch(setRefreshAM010());
          } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
          } finally {
            dispatch(setLoading(false));
          }
        },
        className: "confirm__modal confirm__modal-purple-oke",
        title: MESSAGE.MESSAGE_TITLE_BEFORE_CREATE,
        description: MESSAGE.MESSAGE_DESCRIPTION_BEFORE_CREATE_LATE_EARLY,
        canceText: LABEL_MESSAGE.CANCEL_MODAL,
        okText: LABEL_MESSAGE.OK_MODAL,
        isCenterWithoutMenu: true,
      });
  };

  const handleGetRequestLateEarly = async (requestId: number): Promise<any> => {
    try {
      const params = {
        requestId: requestId,
      };
      const res = await am018Service.getRequestLateEarly(params);
      if (res?.results) {
        setRequestLateEarly(res.results);
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const handleFillFormUpdate = async (): Promise<any> => {
    form.setFieldValue("requestCategoryId", requestLateEarly?.category?.id);
    form.setFieldValue("requestDate", moment(requestLateEarly?.requestDate));
    form.setFieldValue("requestTime", requestLateEarly?.timeRequest);
    form.setFieldValue("projectId", requestLateEarly?.project?.id);
    form.setFieldValue("constructionId", requestLateEarly?.construction?.id);
    form.setFieldValue("approver", requestLateEarly?.approver?.fullName);
    form.setFieldValue("note", requestLateEarly?.note);
    const response = await handleGetDetailProjectById(
      requestLateEarly.project.id
    );
    if (response?.constructions) setConstructions([...response.constructions]);
    setChosenProject(true);
  };

  const formatConstructions = (data: any) => {
    const arr: any = [];
    data.map((item: any) => {
      arr.push({
        id: item.id,
        name: item.description,
        projectId: item.projectId,
        projectName: item.projectName,
      });
    });
    return arr;
  };

  const getRequestApprover = async (): Promise<any> => {
    try {
      const res = await am018Service.getRequestApprover();
      if (res?.results) {
        setRequestApprover(res.results);
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const getProjectStatus = async (): Promise<any> => {
    try {
      const params = {
        page: COMMON.DEFAULT_PAGE,
        size: COMMON.DEFAULT_SIZE,
      };
      const res = await am018Service.getProjectStatus(params);
      if (res?.results) {
        res.results.forEach((item: any) => {
          if (item.name === STATUS.UNDER_CONSTRUCTION)
            setProjectStatus(item.id);
        });
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const resetState = () => {
    form.resetFields();
    setIsDirty(DEFAULT_STATE.isDirty);
    dispatch(resetAM018());
  };

  const onCancel = () => {
    if (!isDirty) return resetState();
    ConfirmModal({
      onOk: () => resetState(),
      className: "confirm__modal",
      title: MESSAGE.MESSAGE_020,
      description: MESSAGE.MESSAGE_022_1,
      extraDescription: MESSAGE.MESSAGE_022_2,
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_MODAL,
      isCenterWithoutMenu: true,
    });
  };

  const checkDirty = (): void => {
    const formValue = form.getFieldsValue();
    const formProps = Object.keys(formValue);
    for (let i = 0; i < formProps.length; i++) {
      if (form.isFieldTouched(formProps[i])) {
        return setIsDirty(true);
      }
    }
    setIsDirty(false);
  };
  return {
    form,
    isDirty,
    lateEarlyCategory,
    loadingOption,
    projects,
    constructions,
    chosenProject,
    requestLateEarly,
    requestApprover,
    projectStatus,
    onCancel,
    checkDirty,
    handleGetLateEarlyCategory,
    getListProject,
    handleSearchOption,
    handleScrollProject,
    handleSearchOptionConstruction,
    handleScrollConstruction,
    handleCreateLateEarly,
    handleFindConstructions,
    handleFindProjects,
    handleGetRequestLateEarly,
    handleFillFormUpdate,
    getRequestApprover,
    getProjectStatus,
    getProject,
    resetProjectWhenSelectOrBlur,
    resetConstructionWhenSelectOrBlur,
    handleGetProjectFirstOpenModal,
    handleGetConstructionFirstOpenModal,
  };
};

export default AM018Handler;
