import { MemberType, MyCompany } from "../entity/Entity";
import { APIImpl } from "./ServiceImpl";

const Service = (pm011Api: APIImpl) => {
  const getMyCompany = async (params: any): Promise<MyCompany> => {
    try {
      const response = await pm011Api.getMyCompany(params);
      return response.results ?? null;
    } catch (error: any) {
      throw error;
    }
  };

  const getListMyMember = async (params: unknown): Promise<MemberType[]> => {
    try {
      const response = await pm011Api.getListMyMember(params);
      return response.results ?? [];

      // return (
      //   response.results.map((element: any) => ({
      //     ...element,
      //     approve: false,
      //   })) ?? []
      // );
    } catch (error: any) {
      return [];
    }
  };

  const addToQueue = async (data: any): Promise<any> => {
    try {
      const response = await pm011Api.addToQueue(data);
      return response;
    } catch (error: any) {
      throw error;
    }
  };
  const getInviteConstructionMember = async (params: any): Promise<any> => {
    try {
      const response = await pm011Api.getInviteConstructionMember(params);
      return response.results || [];
    } catch (error: any) {
      return [];
    }
  };

  const getSubConstructionListMember = async (params: any): Promise<any> => {
    try {
      const response = await pm011Api.getSubConstructionListMember(params);
      return response;
    } catch (error: any) {
      throw error;
    }
  };
  const getListSubStruction = async (params: any): Promise<any> => {
    try {
      const response = await pm011Api.getListSubStruction(params);
      return response.results ?? [];
    } catch (error: any) {
      throw error;
    }
  };
  const getConstructionList = async (params: {
    projectId: string;
  }): Promise<any> => {
    try {
      const response = await pm011Api.getConstructionList(params);
      return response.results;
    } catch (error: any) {
      throw error;
    }
  };
  return {
    getMyCompany,
    getListMyMember,
    addToQueue,
    getListSubStruction,
    getSubConstructionListMember,
    getInviteConstructionMember,
    getConstructionList,
  };
};

export default Service;
