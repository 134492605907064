import moment from "moment";
import React from "react";

const TimeComponent = () => {
  const [timer, setTimer] = React.useState(moment());

  React.useEffect(() => {
    const interval = setInterval(() => {
      setTimer(moment());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <span style={{ fontSize: 40, color: "#222" }}>
      {timer.format("hh:mm")}
    </span>
  );
};

export default TimeComponent;