// Built-in
import React, { useEffect } from 'react';

// 3rd party lib
import { useSearchParams } from 'react-router-dom';
import { Layout } from 'antd';
import { useSelector } from 'react-redux';
import moment from 'moment';

// Source files
import BreadcrumbComponent from '../../../../../common/components/breadcrumb/BreadcrumbComponent';
import { RootState } from '../../../../../store';
import MenuWrapperComponent from '../../../../../common/components/menu-wrapper';
import { HandlerImpl } from '../handler/HandlerImpl';
import { getTitleByFromToDate } from '../../../AM008/helper';
import FilterComponent from '../component/filter';
import ConstractorTableComponent from './ConstractorTable';
import COMMON from '../../../../../common/constants/COMMON';
import AM040Component from '../../../AM040';

// Image files
import HomeSvg from '../../../../../assets/icons/home.svg';
import { OpenStreetMapComponent } from '../../../../../common/components/open-street-map/OpenStreetMapComponent';
import helpers from '../../../../../common/helpers/common';

interface props {
    handler: HandlerImpl;
}
const { Content, Sider } = Layout;

const BREADCRUMB_AM002: any[] = [
    {
        title: '勤怠管理',
        url: '',
        icon: <img style={{ paddingBottom: 2, paddingRight: 8 }} alt="" src={HomeSvg} />,
    },
    {
        title: 'メンバー勤務チェック',
        url: '',
        icon: '',
    },
];

const MainUI = ({ handler }: props) => {
    // Selector
    const tableColumns = useSelector((state: RootState) => state.am001.tableColumns);
    const isRefresh = useSelector((state: RootState) => state.am001.isRefresh);
    const isRefreshFullApi = useSelector((state: RootState) => state.am001.isRefreshFullApi);
    const isVisibleStreetMap = useSelector((state: RootState) => state.openStreetMap.isVisible);

    // Params
    const [searchParams] = useSearchParams();
    const fromDate =
        searchParams.get('startDate') || moment().startOf('month').format(COMMON.FORMAT_DATE2);
    const toDate = searchParams.get('endDate') || moment().format(COMMON.FORMAT_DATE2);
    const selectedConstructionId = searchParams.get('selectedConstructionId');
    const pageDate = searchParams.get('pageDate');
    const keyword = searchParams.get('keyword') || '';
    const isCollapsed = searchParams.get('isCollapsed') ? true : false;

    useEffect(() => {
        handler.initiateFilter();
    }, []);

    useEffect(() => {
        handler.calculateTableHeader(fromDate, toDate);
    }, [fromDate, toDate, pageDate]);

    useEffect(() => {
        (async () => {
            if (!selectedConstructionId) return;
            const param: any = {
                from: fromDate,
                to: toDate,
                constructionId: parseInt(selectedConstructionId),
                contractorConstructionId: parseInt(selectedConstructionId),
            };
            await handler.getAttendanceSubConstructionListMember(param);
        })();
    }, [
        keyword,
        selectedConstructionId,
        isRefresh,
        isRefreshFullApi,
        fromDate, toDate,
    ]);

    return (
        <>
            <MenuWrapperComponent chosenKey={21} openKey={2}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'end',
                    }}
                >
                    <div className="om002-header-container">
                        <BreadcrumbComponent data={BREADCRUMB_AM002} />
                        <div className="header-row-title" style={{ marginTop: 8 }}>
                            メンバー勤務チェック
                        </div>
                    </div>
                    <div></div>
                </div>
                <Layout
                    style={{ minHeight: '84vh', marginTop: 24 }}
                    className={isCollapsed ? 'close-side' : 'open-side'}
                >
                    <Sider>
                        <FilterComponent />
                    </Sider>
                    <Layout style={{ backgroundColor: '#ffffff' }}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginLeft: 24,
                                marginTop: 16,
                                marginBottom: 18,
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div className="total-money-style" style={{ marginRight: 16, minWidth: 250 }}>
                                    合計金額：{helpers.formatCurrency(handler.totalMoney)}円
                                </div>
                                <div className="total-money-style">
                                    合計稼働人工数：{helpers.formatCurrency(handler.totalWorkingDay)}人工
                                </div>
                            </div>

                            <div
                                className="date-label"
                                style={{ textAlign: 'right' }}
                            >
                                {getTitleByFromToDate(fromDate, toDate)}
                            </div>
                        </div>
                        <Content
                            style={{
                                margin: '0px 24px 16px 24px',
                                backgroundColor: '#ffffff',
                            }}
                        >
                            {isRefreshFullApi ? (
                                <ConstractorTableComponent
                                    isCollapsedMember={handler.isCollapsedMember}
                                    onChangeCollapseRowMember={handler.onChangeCollapseRowMember}
                                    isCollapsed={handler.isCollapsed}
                                    onChangeCollapseRow={handler.onChangeCollapseRow}
                                    expandKeyMember={handler.expandKeyMember}
                                    setExpandKeyMember={handler.setExpandKeyMember}
                                    onChoose={handler.onChoose}
                                    constructionId={parseInt(selectedConstructionId || '')}
                                    onNext={handler.onNext}
                                    onPrev={handler.onPrev}
                                    dataSource={handler.listConstractor}
                                    columnsTable={tableColumns.columns}
                                    isPrev={tableColumns.isPrev}
                                    isNext={tableColumns.isNext}
                                    expandKey={handler.expandKey}
                                    setExpandKey={handler.setExpandKey}
                                />
                            ) : (
                                <></>
                            )}
                        </Content>
                    </Layout>
                </Layout>
            </MenuWrapperComponent>
            <AM040Component afterSuccess={handler.afterDoubleCheckSuccess} />
            {isVisibleStreetMap ? <OpenStreetMapComponent /> : <></>}
        </>
    );
};

export default MainUI;
