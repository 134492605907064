import { createSlice } from '@reduxjs/toolkit';
import { CM035SliceType } from '../../entity/Entity';

export const initialState: CM035SliceType = {
    refresh: false,
};

export const CM034lice = createSlice({
    name: 'wrapper3453',
    initialState,
    reducers: {
        setRefresh: (state) => {
            state.refresh = !state.refresh;
        },
    },
});

export const { setRefresh } = CM034lice.actions;

export default CM034lice.reducer;
