import { AxiosResponse } from 'axios';
import axiosConfig from '../../../../../../config/axiosConfig';

const CM0047Api = () => {
    const getDocumentReceiveMoneyReviewList = async (params: {
        statusId?: string;
        from?: string;
        to?: string;
        page: number;
        size: number;
        sortBy?: string;
        sortType?: string;
        keyword?: string;
    }): Promise<AxiosResponse<any, any>> => {
        const url = '/re/document/receive-money/review/list';
        return await axiosConfig.get(url, { params });
    };

    const putMoneyApproveReceiveMoney = async (data: any): Promise<AxiosResponse<any, any>> => {
        const url = '/money/approve/receive-money';
        return await axiosConfig.put(url, data);
    };

    const putMoneyRejectReceiveMoney = async (data: any): Promise<AxiosResponse<any, any>> => {
        const url = '/money/reject/receive-money';
        return await axiosConfig.put(url, data);
    };

    return {
        getDocumentReceiveMoneyReviewList,
        putMoneyApproveReceiveMoney,
        putMoneyRejectReceiveMoney,
    };
};

export default CM0047Api;
