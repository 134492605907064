import OM009Api from "./infrastructure/api/Api"
import OM009Handler from "./presenter/handler/Handler";
import OM009UI from "./presenter/ui/UI";
import OM009Service from "./usecase/Service";


const OM009Component = () => {
    const om009Api = OM009Api();
    const om009Service = OM009Service(om009Api)
    const om009Handler = OM009Handler(om009Service)
    return <OM009UI handler={om009Handler} />
}

export default OM009Component