import { Button, Form, Layout } from "antd";
import React from "react";
import { useEffect } from "react";
import HomeSvg from "../../../../../assets/icons/home.svg";
import helpers from "../../../../../common/helpers/common";
import { HandlerImpl } from "../handler/HandlerImpl";
import MenuWrapperComponent from "../../../../../common/components/menu-wrapper";
import BreadcrumbComponent from "../../../../../common/components/breadcrumb/BreadcrumbComponent";
import CertificationFormP01Component from "../../../../../common/components/certification-form-P01-component/CertificationFormP01Component";
import EditP01Icon from "../../../../../assets/icons/edit-p01.svg";
import UserFormComponent from "./UserForm";
import UserDetailComponent from "./UserDetail";
import CertificationViewP01Component from "../../../../../common/components/certification-view-P01-component/CertificationViewP01Component";
import { CertificateEntity } from "../../entity/Entity";

import "moment/locale/ja";
import "../../../../../assets/styles/OM/OM002.css";
import "../../../../../assets/styles/OM/OM004.css";
import "../../../../../assets/styles/OM/OM007.css";
import "../../../../../assets/styles/P/P01.css";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";

const BREADCRUMB_P01: any[] = [
  {
    title: "設定",
    url: "",
    icon: (
      <img style={{ paddingBottom: 2, paddingRight: 8 }} alt="" src={HomeSvg} />
    ),
  },
  {
    title: "プロフィール",
    url: "",
    icon: "",
  },
];

const { Content } = Layout;

interface props {
  handler: HandlerImpl;
}

const MainUI = ({ handler }: props) => {
  const isRefresh = useSelector((state: RootState) => state.p01.isRefresh);

  useEffect(() => {
    handler.getMemberDetail();
  }, [isRefresh]);

  useEffect(() => {
    handler.getCertificateType();
  }, []);

  useEffect(() => {
    if (handler.isDirty) {
      window.addEventListener("beforeunload", helpers.alertUser);
      return () => {
        window.removeEventListener("beforeunload", helpers.alertUser);
      };
    } else {
      window.removeEventListener("beforeunload", helpers.alertUser, true);
    }
  }, [handler.isDirty]);

  return (
    <MenuWrapperComponent
      chosenKey={74}
      openKey={7}
      contentClass="site-content-p01 "
      onClickMenu={handler.onClickMenu}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
        }}
      >
        <div className="om002-header-container">
          <BreadcrumbComponent data={BREADCRUMB_P01} />
          <div className="header-row-title" style={{ marginTop: 8 }}>
            プロフィール
          </div>
        </div>
        <div style={{ display: "flex" }}>
          {!handler.isEditing ? (
            <Button
              onClick={() => handler.changeEditStatus(true)}
              style={{ marginRight: 24, width: 98 }}
              size="large"
              type="primary"
              className="button-purple"
            >
              <div className="center-item">
                <img
                  alt=""
                  src={EditP01Icon}
                  style={{ marginRight: 8, fontWeight: 700, fontSize: 14 }}
                />
                <div className="export-button">
                  編集
                </div>
              </div>
            </Button>
          ) : null}
        </div>
      </div>
      <Layout
        style={{
          minHeight: "84vh",
          marginTop: 24,
          marginBottom: 24,
          backgroundColor: "#ffffff",
        }}
        className={"open-side"}
      >
        <Content
          style={{ margin: "0px 24px 24px 24px", backgroundColor: "#ffffff" }}
        >
          <div style={{ marginTop: 24 }}>
            <Form
              className="p01-form "
              onValuesChange={handler.checkDirty}
              form={handler.form}
              id="formUpdateMemberP02"
              layout="horizontal"
              onFinish={handler.updateMember}
              autoComplete="off"
            >
              {handler.isEditing ? (
                <UserFormComponent
                  memberName={handler.memberName}
                  detailMember={handler.detailMember}
                  form={handler.form}
                  onExpirationDateChange={handler.onExpirationDateChange}
                  getAvatarBinary={handler.getAvatarBinary}
                />
              ) : (
                <UserDetailComponent detailMember={handler.detailMember} />
              )}
              <div
                className="registration-title"
                style={{ marginLeft: 20, width: 114, height: 24, marginTop: 8 }}
              >
                保有資格
              </div>
              {handler.certType.map((element: CertificateEntity) =>
                handler.isEditing ? (
                  <React.Fragment key={element.id}>
                    <CertificationFormP01Component
                      form={handler.form}
                      initialLines={handler.certLines.lines[
                        `cert${element.id}`
                      ]?.map((element: any) => element.id)}
                      typeId={element.id + ""}
                      label={element.description}
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment key={element.id}>
                    <CertificationViewP01Component
                      initialLines={
                        handler.certLines.lines[`cert${element.id}`]
                      }
                      label={element.description}
                    />
                  </React.Fragment>
                )
              )}
            </Form>
          </div>
        </Content>
      </Layout>
      {handler.isEditing ? (
        <div
          className="sticky"
          style={{
            width: "100%",
            backgroundColor: "#ffffff",
            display: "flex",
            padding: 24,
            justifyContent: "flex-end",
          }}
        >
          <Button
            htmlType="submit"
            form="formUpdateMemberP02"
            size="large"
            className="button-purple"
            style={{ width: 76, letterSpacing: -1.5 }}
          >
            保存
          </Button>
          <Button
            size="large"
            style={{
              marginLeft: 16,
              width: 118,
              fontWeight: 400,
              fontSize: 14,
              lineHeight: 22,
              color: "#666666",
            }}
            onClick={() => handler.onCancel()}
          >
            キャンセル
          </Button>
        </div>
      ) : (
        <></>
      )}
    </MenuWrapperComponent>
  );
};

export default MainUI;
