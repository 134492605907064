import { AM024SliceType } from "./../type/Presenter";
import { createSlice } from "@reduxjs/toolkit";

const initialState: AM024SliceType = {
  loading: false,
  isVisible: false,
  requestId: undefined,
  statusCode:""
};

export const am024Slice = createSlice({
  name: "am024",
  initialState,
  reducers: {
    resetAM024: () => initialState,
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsVisibleAM024: (state, action) => {
      state.isVisible = action.payload;
    },
    setRequestIdAM024: (state, action) => {
      state.requestId = action.payload;
    },
    setStatusCodeAM024: (state, action) => {
      state.statusCode = action.payload;
    },
  },
});

export const {
  setLoading,
  setIsVisibleAM024,
  resetAM024,
  setRequestIdAM024,
  setStatusCodeAM024
} = am024Slice.actions;

export default am024Slice.reducer;
