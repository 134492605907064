import { API } from "./infrastructure/api/Api"
import { Handler } from "./presenter/handler/Handler";
import MainUI from "./presenter/ui/UI";
import { Service } from "./usecase/Service";
import "../../../assets/styles/CR/CR005.css"
const CR005Component = () => {
    const dataSource = API();
    const useCase = Service(dataSource)
    const handler = Handler(useCase)
    return <MainUI handler={handler} />
}

export default CR005Component