// Redux
import { useDispatch } from "react-redux";

// Services
import { CM0029UseCase } from "./../../usecase/ServiceImpl";
import { useState } from "react";
import { resetCM0029, setVisibleCM0029 } from "../slice/Slice";
import {
  ProjectAllsProjectState,
  ReSummaryProjectLaborCostRetrievePayload,
  ReSummarySubmittedProjectLaborCostTotalResults,
} from "../../entity/Entity";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import COMMON from "../../../../../common/constants/COMMON";
import ConfirmModal from "../../../../../common/components/modal/ConfirmModal";
import { ItemApprove } from "../../../../../common/components/approval-flow/ApprovalFlow";
import { showModalApproveConfirm } from "../../../../../common/components/modal-approve-confirm/presenter/slice/Slice";

const CM0029Handler = (service: CM0029UseCase) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [expandKey, setExpandKey] = useState<any[]>([]);
  const [summaryTotal, setSummaryTotal] =
    useState<ReSummarySubmittedProjectLaborCostTotalResults>({
      actualCost: 0,
      tax: 0,
    });
  const [projects, setProjects] = useState<ProjectAllsProjectState[]>([]);
  const [numPages, setNumPages] = useState<number>(1);
  const [projectPage, setProjectPage] = useState<number>(1);
  const projectSize = Number(COMMON.DEFAULT_SIZE);
  const [workflow, setWorkflow] = useState<ItemApprove[]>([]);
  const [currentUserApproval, setCurrentUserApproval] =
    useState<ItemApprove | null>(null);
  const getWorkflowSummaryLaborApprovalProcess = async (documentId: number) => {
    try {
      setLoading(true);
      const data = await service.getWorkflowSummaryLaborApprovalProcess({
        documentId,
      });
      if (data.results.currentUserApproval)
        setCurrentUserApproval({
          approveDate: data.results.currentUserApproval.approveDate,
          contact_address: "",
          email: data.results.currentUserApproval.email,
          id: data.results.currentUserApproval.id,
          isApprove: data.results.currentUserApproval.approve,
          reject: data.results.currentUserApproval.reject,
          position: "",
          priority: data.results.currentUserApproval.priority,
          username: data.results.currentUserApproval.username,
          note: data.results.currentUserApproval.note,
          fullName: data.results.currentUserApproval.fullName,
        });
      const workflowData: ItemApprove[] = data.results.listApprover.map(
        (element) => {
          let note = element.note;
          if (element.id === data.results?.currentUserApproval?.id)
            note = data.results.currentUserApproval.note;
          const result: ItemApprove = {
            approveDate: element.approveDate,
            contact_address: "",
            email: element.email,
            id: element.id,
            isApprove: element.approve,
            reject: element.reject,
            position: "",
            priority: element.priority,
            username: element.username,
            note: note,
            fullName: element.fullName,
          };
          return result;
        }
      );
      setWorkflow(workflowData);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      setLoading(false);
    }
  };

  const getReSummarySubmittedProjectLaborCost = async (params: {
    documentId: number;
    projectId: number;
  }) => {
    try {
      setLoading(true);
      return await service.getReSummarySubmittedProjectLaborCost(params);
    } catch (error: any) {
      ErrorNotification(error.message ?? NOTIFICATION_TITLE.ERROR);
      return [];
    }
  };

  const getProjectAlls = async (documentId: number) => {
    if (numPages < projectPage) return;
    try {
      setLoading(true);
      let projectData: any[] = [];
      const responseData =
        await service.getReSummarySubmittedLaborCostProjectList({
          documentId: documentId,
          page: projectPage,
          size: projectSize,
        });

      projectData = responseData.results;
      for (let i = 0; i < projectData.length; i++) {
        const costDetail = await getReSummarySubmittedProjectLaborCost({
          documentId: documentId,
          projectId: projectData[i].id,
        });
        let paymentAmountTaxIncluded: number = 0;
        let consumptionTax: number = 0;
        let paymentAmountExcludingTax: number = 0;
        costDetail.forEach((element) => {
          paymentAmountTaxIncluded += element?.actualCost ?? 0;
          consumptionTax += element?.tax ?? 0;
          paymentAmountExcludingTax +=
            (element?.actualCost ?? 0) + (element?.tax ?? 0);
        });

        projectData[i].costDetailData = costDetail;
        projectData[i].paymentAmountTaxIncluded = paymentAmountTaxIncluded;
        projectData[i].consumptionTax = consumptionTax;
        projectData[i].paymentAmountExcludingTax = paymentAmountExcludingTax;
      }
      if (projectPage === 1 && projectData.length > 0) {
        setExpandKey([projectData[0].id]);
      }
      setProjects([...projects, ...projectData]);
      setNumPages(responseData.pagination.numPages);
      setProjectPage(responseData.pagination.page + 1);
    } catch (error: any) {
      ErrorNotification(error.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      setLoading(false);
    }
  };

  const handleScroll = async (e: any, documentId: number) => {
    const bottom =
      Math.abs(
        Math.floor(e.target.scrollHeight - e.target.scrollTop) -
          Math.floor(e.target.clientHeight)
      ) < 2;
    if (e.target.scrollTop === 0) return;

    if (bottom) {
      await getProjectAlls(documentId);
    }
  };

  const getReSummarySubmittedProjectLaborCostTotal = async (params: {
    documentId: number;
  }): Promise<void> => {
    try {
      setLoading(true);
      const responseData =
        await service.getReSummarySubmittedProjectLaborCostTotal(params);
      setSummaryTotal(responseData);
    } catch (error: any) {
      ErrorNotification(error.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      setLoading(false);
    }
  };

  const putReSummaryProjectLaborCostRetrieve = async (
    data: ReSummaryProjectLaborCostRetrievePayload,
    afterSuccess?: () => void
  ): Promise<void> => {
    ConfirmModal({
      onOk: async () => {
        try {
          setLoading(true);
          const responseData =
            await service.putReSummaryProjectLaborCostRetrieve(data);
          if (responseData) {
            SuccessNotification(
              responseData?.message ?? NOTIFICATION_TITLE.SUCCESS
            );
            dispatch(resetCM0029());
            if (afterSuccess) afterSuccess();
          }
        } catch (error: any) {
          ErrorNotification(error.message ?? NOTIFICATION_TITLE.ERROR);
        } finally {
          setLoading(false);
        }
      },
      className: "confirm__modal",
      title: MESSAGE.MESSAGE_TITLE_CM016,
      description: MESSAGE.MESSAGE_DESCRIPTION_CR_3,
      extraDescription: MESSAGE.MESSAGE_DESCRIPTION_COMMON_QUESTION_1,
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_MODAL,
      isCenterWithoutMenu: true,
    });
  };

  const setExpandKeys = (key: any) => {
    const checkKey = checkKeyExpand(key);
    if (checkKey) {
      const newExpandKey = expandKey.filter(
        (keyExpand: any) => keyExpand !== key
      );
      setExpandKey(newExpandKey);
    } else {
      setExpandKey([...expandKey, key]);
    }
  };

  const checkKeyExpand = (key: any) => {
    if (!key || !expandKey) return false;
    return expandKey.some((element: any) => element === key);
  };

  const cancelModalCM0029 = () => {
    dispatch(setVisibleCM0029(false));
  };
  const showConfirmApproveModal = () => {
    dispatch(showModalApproveConfirm());
  };
  return {
    expandKey,
    summaryTotal,
    loading,
    workflow,
    currentUserApproval,
    projects,
    getProjectAlls,
    setExpandKeys,
    checkKeyExpand,
    cancelModalCM0029,
    putReSummaryProjectLaborCostRetrieve,
    getReSummarySubmittedProjectLaborCostTotal,
    getReSummarySubmittedProjectLaborCost,
    handleScroll,
    getWorkflowSummaryLaborApprovalProcess,
    showConfirmApproveModal,
  };
};

export default CM0029Handler;
