import React from 'react';
import CM0053Api from './infrastructure/api/Api';
import CM0053Service from './usecase/Service';
import CM053Handler from './presenter/handler/Handler';
import MainUI from './presenter/ui/UI';

import '../../../../assets/styles/CM/cm047.css';

const CM053Component = () => {
    const api = CM0053Api();
    const service = CM0053Service(api);
    const handler = CM053Handler(service);
    return <MainUI handler={handler} />;
};

export default CM053Component;
