import MESSAGE, {
  NOTIFICATION_TITLE,
} from "./../../../../../common/constants/MESSAGE";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "antd";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import { ChangeForgotPasswordUseCase } from "../../usecase/ServiceImpl";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import COMMON from "../../../../../common/constants/COMMON";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";

const ChangeForgotPasswordHandler = (
  aS003Service: ChangeForgotPasswordUseCase
) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [timer, setTimer] = useState(0);
  const [isSent, setIsSent] = useState<boolean>(false);

  const changePassword = async (formData: any) => {
    if (formData.newPassword !== formData.verifyPassword) {
      form.setFields([
        {
          name: "verifyPassword",
          errors: [MESSAGE.MESSAGE_PASSWORD_NOT_MATCH],
        },
      ]);
      return;
    }

    const timerOTP = localStorage.getItem("timerOTP");
    if (!timerOTP) {
      form.setFields([
        {
          name: "otp",
          errors: [""],
        },
      ]);
      return;
    }

    dispatch(setLoading(true));
    try {
      const bodyData = {
        newPassword: formData.newPassword,
        otpCode: formData.otp,
      };
      await aS003Service.changePassword(bodyData);
      localStorage.removeItem("timerOTP");
      localStorage.removeItem("forgotToken");
      localStorage.removeItem("user");
      SuccessNotification(MESSAGE.MESSAGE_CHANGE_PASSWORD_SUCCESS);
      navigate("/app-login");
    } catch (error: any) {
      const errorMessage = error?.message ?? "";
      form.setFields([
        {
          name: "otp",
          errors: [errorMessage],
        },
      ]);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const timerOTP = () => {
    dispatch(setLoading(true));
    const timer = localStorage.getItem("timerOTP");
    if (timer) {
      const timerParsed = parseInt(timer);
      setTimer(timerParsed);
    }
    dispatch(setLoading(false));
  };

  const sendOTP = async () => {
    dispatch(setLoading(true));
    try {
      await aS003Service.sendOTP();
      const date = new Date().getTime();
      localStorage.setItem("timerOTP", date + COMMON.ONE_MINUTE + "");
      setTimer(date + COMMON.ONE_MINUTE);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      setIsSent(true);
      dispatch(setLoading(false));
    }
  };

  const backLogin = () => {
    localStorage.removeItem("timerOTP");
    navigate("/app-login");
  };

  return {
    form,
    timer,
    isSent,
    changePassword,
    timerOTP,
    sendOTP,
    backLogin,
  };
};

export default ChangeForgotPasswordHandler;
