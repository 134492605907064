import { Button, Divider, Form, Input, Modal, Row, Table } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { PM002UIHandler } from "../handler/HandlerImpl";
import SearchSvg from "../../../../../assets/icons/search-icon.svg";
import PaginationComponentList from "../../../../../common/components/pagination/PaginationComponentList";

interface props {
  handler: PM002UIHandler;
}
const columns : any = [
  {
    key: "username",
    title: "名前",
    dataIndex: "username",
    sorter: (a: any, b: any) => a.username - b.username,
    sortDirections: ["ascend", "descend", "ascend"],
  },
  {
    key: "position",
    title: "役職",
    dataIndex: "position",
    sorter: (a: any, b: any) => a.position - b.position,
    sortDirections: ["ascend", "descend", "ascend"],
  },
  {
    key: "contact_address",
    title: "連絡先",
    dataIndex: "contact_address",
    sorter: (a: any, b: any) => a.contact_address - b.contact_address,
    sortDirections: ["ascend", "descend", "ascend"],
  },
  {
    key: "email",
    title: "メールアドレス",
    dataIndex: "email",
    sorter: (a: any, b: any) => a.email - b.email,
    sortDirections: ["ascend", "descend", "ascend"],
  },
];

const ModalInfo = ({ handler }: props) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const users = useSelector((state: RootState) => state.pm002.users);
  const visibleModal = useSelector((state: RootState) => state.pm002.showModal);
  const totalMembers = useSelector(
    (state: RootState) => state.pm002.totalMembers
  );
  const page = useSelector((state: RootState) => state.pm002.pageMember);
  const size = useSelector((state: RootState) => state.pm002.sizeMember);
  const onSelectChange = (newSelectedRowKeys: any) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const sortKey = useSelector((state: RootState) => state.pm002.sortKey);
  const sortType = useSelector((state: RootState) => state.pm002.sortType);
  const keywordMember = useSelector(
    (state: RootState) => state.pm002.keywordMember
  );
  useEffect(() => {
    handler.getAllUser(
      handler.getParamsMembers(keywordMember, page, size, sortKey, sortType)
    );
  }, [sortKey, sortType, keywordMember, page, size]);

  return (
    <Modal
      className="modal-form-container"
      visible={visibleModal}
      onCancel={handler.closeShowModal}
      width={1361}
      footer={[
        <>
          <Button
            size="large"
            className="button-green modal-button-green"
            htmlType="submit"
            form="b014"
            style={{
              marginRight: "20px",
              backgroundColor: "#605BFF",
              color: "#fff",
            }}
            onClick={(e: any) => {
              handler.handlerAddMember({
                projectId: handler.projectId,
                membersId: selectedRowKeys,
              });
              handler.closeShowModal();
              setSelectedRowKeys([]);
            }}
          >
            確認
          </Button>
          <Button
            size="large"
            className="button-red"
            onClick={(e: any) => {
              handler.closeShowModal();
              handler.resetKeyworkMember();
              setSelectedRowKeys([]);
            }}
          >
            キャンセル
          </Button>
        </>,
      ]}
      style={{
        marginTop: "auto",
      }}
    >
      <h3 className="modal-title">予約者情報編集</h3>
      <Divider />
      <Row
        className="modal-button"
        gutter={[16, 24]}
        style={{ marginTop: "32px", marginLeft: "1px", marginBottom: "33px" }}
      >
        <Button
          size="large"
          className="button-green modal-button-green"
          htmlType="submit"
          form="b014"
          style={{
            marginRight: "32px",
            backgroundColor: "#605BFF",
            color: "#fff",
          }}
        >
          招待待ちメンバー
        </Button>
        <Button size="large" className="button-red">
          招待済みメンバー
        </Button>
      </Row>
      <Form form={handler.form}>
        <Form.Item name={"text-search"}>
          <Input
            style={{ marginRight: 16, borderRadius: "3px" }}
            size="large"
            prefix={<img alt="" src={SearchSvg} />}
            placeholder="検索したい名前を入力してください"
            className="text-search"
            onChange={(e) => {
              handler.onSearchMembers(e.target.value);
            }}
          />
        </Form.Item>
      </Form>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={users}
        pagination={false}
        onChange={handler.onChangeSort}
        scroll={{
          y: 240,
        }}
      />
      <PaginationComponentList
        total={totalMembers}
        page={page}
        size={size}
        setPage={handler.onPageChangeMember}
        setSize={handler.onSizeChangeMember}
      />
    </Modal>
  );
};
export default ModalInfo;
