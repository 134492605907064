import { useRef, useState } from "react";
// Redux
import { useDispatch } from "react-redux";

// Services

import { AOP001UseCase } from "../../usecase/ServiceImpl";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import { DataTableType } from "../../entity/Entity";
import { useNavigate } from "react-router-dom";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import COMMON from "../../../../../common/constants/COMMON";
import DeleteAOPModal from "../../../../../common/components/modal-delete-AOP/DeleteAOPModal";
import { SYSTEM_ROLES } from "../../../../../common/constants/AUTHORIZATION";

const AOP001Handler = (service: AOP001UseCase) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const refTable: any = useRef();

  const [selectedRowsKey, setSelectedRowsKey] = useState<React.Key[]>([]);
  const [dataTable, setDataTable] = useState<DataTableType[]>([]);
  const [page, setPage] = useState(COMMON.DEFAULT_PAGE);
  const [numpages, setNumPages] = useState<number>(1);
  const [isRefresh, setIsRefresh] = useState<boolean>(false);

  const currentScroll: any = document.querySelector(".ant-table-body");

  if (currentScroll) {
    currentScroll.onscroll = function (e: any) {
      if (e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight) {
        if (numpages > page) {
          setPage((prev) => prev + 1);
        }
      }
    };
  }

  const setStateSelectedRowsKey = (values: React.Key[]) => {
    setSelectedRowsKey(values);
  };
  const getListRole = async (params: { page: number; size: number }) => {
    try {
      setLoading(true);
      const response: any = await service.getListRole(params);
      setNumPages(response.pagination.numPages);
      const foundEmployeeRole = response.results.find(
        (value: any) => value.code === SYSTEM_ROLES.EMPLOYEE
      );
      const results = response.results.filter(
        (item: any) => item.code !== SYSTEM_ROLES.EMPLOYEE
      );
      results.unshift(foundEmployeeRole);

      if (params.page === 1) {
        if (foundEmployeeRole) setDataTable(convertDataTable(results));
        else {
          setDataTable(convertDataTable(response.results));
        }
      } else {
        if (foundEmployeeRole)
          setDataTable([...dataTable, ...convertDataTable(results)]);
        else {
          setDataTable([...dataTable, ...convertDataTable(response.results)]);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const convertDataTable = (data: any) => {
    let results = data;
    return results.map((item: any, index: number) => {
      return {
        key: item.id,
        no:
          results.length == 10
            ? index + (page - 1) * 10
            : index + 1 + 10 * (page - 1),
        director: item.name,
        numberMember: item.userNumber,
        code: item.code,
      };
    });
  };

  const deleteRole = async (value: any) => {
    DeleteAOPModal({
      onOk: async () => {
        dispatch(setLoading(true));
        try {
          const response = await service.deleteRole({
            listId: [value],
          });
          const newData = dataTable
            .filter((element: any) => element.key !== value)
            .map((val, index) => {
              return {
                ...val,
                no: index + 1,
              };
            });

          if (page === COMMON.DEFAULT_PAGE || dataTable.length <= 10) {
            setPage(COMMON.DEFAULT_PAGE);
            setIsRefresh(!isRefresh);
          } else {
            setDataTable(newData);
          }
          SuccessNotification(
            response.data?.message ?? NOTIFICATION_TITLE.SUCCESS
          );
        } catch (error: any) {
          ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        } finally {
          dispatch(setLoading(false));
        }
      },
      onCancel: () => {},
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_DELETE,
    });
  };

  const deleteManyRole = async () => {
    DeleteAOPModal({
      onOk: async () => {
        dispatch(setLoading(true));
        try {
          const response = await service.deleteRole({
            listId: selectedRowsKey,
          });
          const newData = dataTable
            .filter((element: any) => !selectedRowsKey.includes(element.key))
            .map((val, index) => {
              return {
                ...val,
                no: index + 1,
              };
            });
          if (page === COMMON.DEFAULT_PAGE || dataTable.length <= 10) {
            setPage(COMMON.DEFAULT_PAGE);
            setIsRefresh(!isRefresh);
          } else {
            setDataTable(newData);
          }
          SuccessNotification(
            response.data?.message ?? NOTIFICATION_TITLE.SUCCESS
          );
        } catch (error: any) {
          ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        } finally {
          dispatch(setLoading(false));
        }
      },
      onCancel: () => {},
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_DELETE,
    });
  };

  const redirectAOP002 = () => {
    navigate(`/app-aop003/${0}`);
  };

  const redirectAOP003 = (groupId: number) => {
    navigate(`/app-aop003/${groupId}`);
  };

  const redirectAOP004 = (groupId: number, groupName: string) => {
    navigate(`/app-aop004?groupRoleId=${groupId}&groupRoleName=${groupName}`);
  };

  return {
    selectedRowsKey,
    dataTable,
    page,
    refTable,
    isRefresh,
    setStateSelectedRowsKey,
    getListRole,
    redirectAOP002,
    redirectAOP003,
    redirectAOP004,
    deleteRole,
    deleteManyRole,
  };
};
export default AOP001Handler;
