import axiosConfig from "../../../../../config/axiosConfig";
import {
  DetailMemberEntity,
  MemberUpdateEntity,
  S3ImageParams,
} from "../../entity/Entity";

const OM006Api = () => {
  const updateMember = async (data: MemberUpdateEntity): Promise<any> => {
    try {
      const url = "/member";
      const response = await axiosConfig.put(url, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getDetailMember = async (
    params: any
  ): Promise<DetailMemberEntity | null> => {
    try {
      const url = "/group/member";
      const response = await axiosConfig.get(url, { params });
      return response?.data?.results ?? null;
    } catch (error) {
      return null;
    }
  };

  const getS3MemberImage = async (params: S3ImageParams): Promise<any> => {
    try {
      const url = "/user/presign-link";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  return {
    updateMember,
    getDetailMember,
    getS3MemberImage,
  };
};

export default OM006Api;
