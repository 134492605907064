import React from 'react';
import CM0027_1Api from './infrastructure/api/Api';
import CM0027_1Handler from './presenter/handler/Handler';
import CM0027_1UI from './presenter/ui/UI';
import CM0027_1Service from './usecase/Service';

const CM0027_1Component = () => {
    const cm0027_1Api = CM0027_1Api();
    const cm0027_1Service = CM0027_1Service(cm0027_1Api);
    const cm0027_1Handler = CM0027_1Handler(cm0027_1Service);
    return <CM0027_1UI handler={cm0027_1Handler} />;
};

export default CM0027_1Component;
