import { CR003Api } from "./ServiceImpl";

const CR003Service = (cr003Api: CR003Api) => {
    const getCR003BasicInfo = async (params: any): Promise<any> => {
        try {
            return await cr003Api.getCR003BasicInfo(params);
        } catch (error) {
            throw error;
        }
    };
    const updateCR003BasicInfo = async (data: any): Promise<any> => {
        try {
            return await cr003Api.updateCR003BasicInfo(data);
        } catch (error) {
            throw error;
        }
    };
    const getScreenMaster = async (params: any): Promise<any> => {
        try {
            return await cr003Api.getScreenMaster(params);
        } catch (error) {
            throw error;
        }
    };
    const getListRelateMoney = async (params: any): Promise<any> => {
        try {
            return await cr003Api.getListRelateMoney(params);
        } catch (error) {
            throw error;
        }
    };
    const getListSelectCompany = async (params: any): Promise<any> => {
        try {
            return await cr003Api.getListSelectCompany(params);
        } catch (error) {
            throw error;
        }
    };
    const updateRelateMoney = async (data: any): Promise<any> => {
        try {
            return await cr003Api.updateRelateMoney(data);
        } catch (error) {
            throw error;
        }
    };
    const getListVersion = async (data: any): Promise<any> => {
        try {
            return await cr003Api.getListVersion(data);
        } catch (error) {
            throw error;
        }
    };
    const getListVersionApproval = async (data: any): Promise<any> => {
        try {
            return await cr003Api.getListVersionApproval(data);
        } catch (error) {
            throw error;
        }
    };
    const updateNoteVersion = async (data: any): Promise<any> => {
        try {
            return await cr003Api.updateNoteVersion(data);
        } catch (error) {
            throw error;
        }
    };

    const getVersionData = async (params: {
        documentPaymentConfirmDetailId: string | number;
    }): Promise<any> => {
        try {
            return await cr003Api.getVersionData(params);
        } catch (error) {
            throw error;
        }
    };

    const getApprovalProcess = async (params: any): Promise<any> => {
        try {
            return await cr003Api.getApprovalProcess(params);
        } catch (error) {
            throw error;
        }
    };

    const postApprovalProcess = async (data?: any): Promise<any> => {
        try {
            return await cr003Api.postApprovalProcess(data);
        } catch (error) {
            throw error;
        }
    };
    const resetApproval = async (data?: any): Promise<any> => {
        try {
            return await cr003Api.resetApproval(data);
        } catch (error) {
            throw error;
        }
    };

    const checkDuplicate = async (data?: any): Promise<any> => {
        try {
            return await cr003Api.checkDuplicate(data);
        } catch (error) {
            throw error;
        }
    };

    const getListMember = async (params: any): Promise<any> => {
        try {
            return await cr003Api.getListMember(params);
        } catch (error) {
            throw error;
        }
    };

    const getDetailProject = async (params: any): Promise<any> => {
        try {
            return await cr003Api.getDetailProject(params);
        } catch (error) {
            throw error;
        }
    };

    const exportTemplate = async (params: any): Promise<any> => {
        try {
            return await cr003Api.exportTemplate(params);
        } catch (error) {
            throw error;
        }
    };

    const uploadToS3 = async (url: any, data: any): Promise<any> => {
        const response = await cr003Api.uploadToS3(url, data);
        return response?.data;
    };

    const getPresignLinkS3 = async (params: any): Promise<any> => {
        const response = await cr003Api.getPresignLinkS3(params);
        return response?.data;
    };
    const getLinkPresignUpload = async (params: any): Promise<any> => {
        try {
            const response = await cr003Api.getLinkPresignUpload(params);
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    const deleteFileCI = async (keyName: string): Promise<any> => {
        return await cr003Api.deleteFileCI(keyName);
    };

    return {
        getCR003BasicInfo,
        updateCR003BasicInfo,
        getScreenMaster,
        getListRelateMoney,
        getListSelectCompany,
        updateRelateMoney,
        getListVersion,
        updateNoteVersion,
        getVersionData,
        getApprovalProcess,
        postApprovalProcess,
        resetApproval,
        checkDuplicate,
        getListMember,
        getDetailProject,
        exportTemplate,
        uploadToS3,
        getPresignLinkS3,
        getLinkPresignUpload,
        getListVersionApproval,
        deleteFileCI
    };
};

export default CR003Service;
