import {
  OrderingCompanyData,
  PM025CreateCompany,
  PM025DeleteCompany,
  PM025ParamsGetListCompany,
  PM025UpdateCompany,
} from "../entity/PM012Entity";
import { PM025Api } from "./ServiceImpl";

const PM025Service = (pm025Api: PM025Api) => {
  const getData = async (params: PM025ParamsGetListCompany): Promise<any> => {
    try {
      return await pm025Api.getData(params);
    } catch (error) {
      throw error;
    }
  };
  const createCompany = async (data: PM025CreateCompany): Promise<any> => {
    try {
      return await pm025Api.createCompany(data);
    } catch (error) {
      throw error;
    }
  };
  const updateCompany = async (data: PM025UpdateCompany): Promise<any> => {
    try {
      return await pm025Api.updateCompany(data);
    } catch (error) {
      throw error;
    }
  };
  const deleteCompany = async (data: PM025DeleteCompany): Promise<any> => {
    try {
      return await pm025Api.deleteCompany(data);
    } catch (error) {
      throw error;
    }
  };

  const getMaterialCompany = async (params: any): Promise<any> => {
    return await pm025Api.getMaterialCompany(params);
  };

  const createMaterialCompany = async (data: any): Promise<any> => {
    return await pm025Api.createMaterialCompany(data);
  };
  const updateMaterialCompany = async (data: any): Promise<any> => {
    return await pm025Api.updateMaterialCompany(data);
  };
  const deleteMaterialCompany = async (data: any): Promise<any> => {
    return await pm025Api.deleteMaterialCompany(data);
  };

  const deleteOrderingCompanyDelete = async (data: any): Promise<any> => {
    return await pm025Api.deleteOrderingCompanyDelete(data);
  };
  const getOrderingCompanyList = async (
    params: any
  ): Promise<OrderingCompanyData> => {
    return await pm025Api.getOrderingCompanyList(params);
  };
  return {
    getData,
    createCompany,
    updateCompany,
    deleteCompany,
    getMaterialCompany,
    createMaterialCompany,
    updateMaterialCompany,
    deleteMaterialCompany,
    deleteOrderingCompanyDelete,
    getOrderingCompanyList,
  };
};

export default PM025Service;
