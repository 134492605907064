import CM0024Api from './infrastructure/api/Api';
import CM0024Handler from './presenter/handler/Handler';
import CM0024Service from './usecase/Service';
import MainUI from './presenter/ui/UI';

import '../../../assets/styles/CM/cm024.css';
import '../../../assets/styles/CM/cm3854.css';
import '../../../assets/styles/CM/cm047.css';

const CM0024Component = () => {
    const api = CM0024Api();

    const service = CM0024Service(api);

    const handler = CM0024Handler(service);

    return <MainUI handler={handler} />;
};

export default CM0024Component;
