import Excel from "exceljs";
import FileSaver from "file-saver";
import moment from "moment";
import COMMON from "../../../constants/COMMON";
import {
  BORDER_STYLE_CELL,
  adjustBorderCell,
  adjustWithColumn,
  getColumnNameWithIndex,
} from "../common";
import { createOuterBorder } from "../../export-excel/common";

const BORDER_STYLE_CELL_DOUBLE_SUMMARY: any = {
  top: { style: "double", color: { argb: "000" } },
  bottom: { style: "double", color: { argb: "000" } },
  right: { style: "thin" },
};

const addBorder = (
  ws: any,
  startNumberColumn: number,
  endNumberColumn: number,
  start: number,
  limit: number
) => {
  const listCellBorder: string[] = [];

  for (let i = startNumberColumn; i <= endNumberColumn; i++) {
    for (let j = startNumberColumn; j <= limit + 1; j++) {
      listCellBorder.push(`${getColumnNameWithIndex(i)}${start + j}`);
    }
  }

  adjustBorderCell(ws, listCellBorder, BORDER_STYLE_CELL);
};

const addWidth = (
  ws: any,
  startNumberColumn: number,
  endNumberColumn: number,
  limit: number
) => {
  const arr: string[] = [];

  for (let i = startNumberColumn; i <= endNumberColumn; i++) {
    for (let j = startNumberColumn; j <= limit + 1; j++) {
      arr.push(`${getColumnNameWithIndex(i)}`);
    }
  }

  adjustWithColumn(ws, arr, limit);
};

const centerItem = (ws: any, start: number, end: number) => {
  for (let i = start; i <= end; i++) {
    ws.getColumn(`${getColumnNameWithIndex(i)}`).alignment = {
      vertical: "middle",
      horizontal: "center",
    };
  }
};

const writeTableOne = (ws: any, data: any) => {
  // ws.mergeCells("A3:B3");
  ws.mergeCells("B3:C3");
  // ws.mergeCells("A4:B4");
  ws.mergeCells("B4:D4");
  // ws.mergeCells("A5:B5");
  // ws.mergeCells("A6:B6");
  // ws.mergeCells("A7:B7");
  // ws.mergeCells("A8:B8");
  // ws.mergeCells("A9:B9");
  // ws.mergeCells("A10:B10");
  // ws.mergeCells("A11:B11");
  ws.mergeCells("B6:D6");
  ws.mergeCells("B7:D7");
  ws.mergeCells("B9:D9");

  ws.getCell("A3").value = "工事番号";
  ws.getCell("B3").value = data?.a ?? "";
  ws.getCell("D3").value = "見積番号";
  ws.getCell("E3").value = data?.b ?? "";
  ws.getCell("G3").value = "担当部署";
  ws.getCell("H3").value = data?.c ?? "";

  ws.getCell("A4").value = "工事名";
  ws.getCell("B4").value = data?.d ?? "";
  ws.getCell("G4").value = "工事担当（主）";
  ws.getCell("H4").value = data?.e ?? "";

  ws.getCell("A5").value = "工事区分 ";
  ws.getCell("B5").value = data.ee1 ?? "";
  ws.getCell("C5").value = data.ee2 ?? "";
  ws.getCell("D5").value = data.ee3 ?? "";
  ws.getCell("G5").value = "工事担当（副）";
  ws.getCell("H5").value = data?.f ?? "";

  ws.getCell("A6").value = "全体工事期間";
  ws.getCell("B6").value = data?.g ?? "";
  ws.getCell("G6").value = "営業担当";
  ws.getCell("H6").value = data?.h ?? "";

  ws.getCell("A7").value = "住所";
  ws.getCell("B7").value = data?.i ?? "";
  ws.getCell("G7").value = "TEL";
  ws.getCell("H7").value = data?.a1 ?? "";

  ws.getCell("A8").value = "発注元名称";
  ws.getCell("B8").value = data?.a11 ?? "";
  ws.getCell("C8").value = "客先担当者";
  ws.getCell("D8").value = data?.b1 ?? "";
  ws.getCell("G8").value = "FAX";
  ws.getCell("H8").value = data?.c1 ?? "";

  ws.getCell("A9").value = "契約工事期間";
  ws.getCell("B9").value = data?.d1 ?? "";
  ws.getCell("G9").value = "延床面積";
  ws.getCell("H9").value = (data?.e1 ?? "") + "㎡";

  ws.getCell("A10").value = "契約日";
  ws.getCell("B10").value = data?.f1 ?? "";
  ws.getCell("C10").value = "支払条件";
  ws.getCell("D10").value = data?.g1 ?? "";
  ws.getCell("G10").value = "見積/面積";
  ws.getCell("H10").value = (data?.h1 ?? "") + "円/㎡";

  ws.getCell("A11").value = "見積条件書";
  ws.getCell("B11").value = data?.a2 ?? "有";
  ws.getCell("C11").value = data?.b2 ?? "支給品";
  ws.getCell("D11").value = data?.c2 ?? "有";
  ws.getCell("E11").value = data?.d2 ?? "常駐有無";
  ws.getCell("F11").value = data?.e2 ?? "有";
  ws.getCell("G11").value = "原価/面積";
  ws.getCell("H11").value = (data?.f2 ?? "") + "円/㎡";
  addBorder(ws, 1, 8, 2, 8);
  createOuterBorder(ws, { row: 3, col: 1 }, { row: 11, col: 8 });
};

const writeTableTwo = (ws: any, data: any) => {
  // ws.mergeCells("A13:B13");
  // ws.mergeCells("A14:B14");
  ws.mergeCells("B13:E13");
  ws.mergeCells("B14:E14");
  ws.mergeCells("F13:F14");

  ws.getCell("A13").value = "見積金額";
  ws.getCell("A14").value = "契約金額";
  ws.getCell("B13").value = data?.a ?? "";
  ws.getCell("B14").value = data?.d ?? "";
  ws.getCell("F13").value = "人工単価（円）";
  ws.getCell("G13").value = "見積";
  ws.getCell("G14").value = "原価";
  ws.getCell("H13").value = data?.b ?? "";
  ws.getCell("H14").value = data?.c ?? "";

  addBorder(ws, 1, 8, 12, 1);
  centerItem(ws, 1, 10);
  createOuterBorder(ws, { row: 13, col: 1 }, { row: 14, col: 8 });
};

const writeTableThree = (ws: any, data: any) => {
  ws.mergeCells("A16:H16");
  ws.mergeCells("A18:H18");
  ws.getCell("A16").value = "直接工事費一覧";
  ws.getCell("A16").font = { bold: true };

  ws.getCell("A16").alignment = {
    vertical: "middle",
    horizontal: "left",
  };

  // table one

  ws.getCell("A18").value = "材料費";
  ws.getCell("A18").alignment = {
    vertical: "middle",
    horizontal: "left",
  };

  const START = 18;

  data.itemOne.forEach((item: any, idx: number) => {
    ws.getCell(`A${idx + START + 1}`).value = item?.a ?? "";
    ws.getCell(`B${idx + START + 1}`).value = item?.b ?? "";
    ws.getCell(`C${idx + START + 1}`).value = item?.c ?? "";
    ws.getCell(`D${idx + START + 1}`).value = item?.d ?? "";
    ws.getCell(`E${idx + START + 1}`).value = item?.e ?? "";
    ws.getCell(`F${idx + START + 1}`).value = item?.f ?? "";
    ws.getCell(`G${idx + START + 1}`).value = item?.g ?? "";
    ws.getCell(`H${idx + START + 1}`).value = item?.h ?? "";
  });

  ws.mergeCells(
    `A${START + 1 + data.itemOne.length}:C${START + 1 + data.itemOne.length}`
  );
  ws.getCell(`A${START + 1 + data.itemOne.length}`).value = "合計金額（円）";
  // Calculate total
  ws.getCell(`D${START + 1 + data.itemOne.length}`).value =
    data.totalItemOne?.a ?? "";
  ws.getCell(`E${START + 1 + data.itemOne.length}`).value =
    data.totalItemOne?.b ?? "";
  ws.getCell(`F${START + 1 + data.itemOne.length}`).value =
    data.totalItemOne?.c ?? "";
  ws.getCell(`G${START + 1 + data.itemOne.length}`).value =
    data.totalItemOne?.d ?? "";
  addBorder(ws, 1, 8, START, data.itemOne.length);
  ws.getCell(`A${START + 1 + data.itemOne.length}`).border =
    BORDER_STYLE_CELL_DOUBLE_SUMMARY;
  // Calculate total
  ws.getCell(`D${START + 1 + data.itemOne.length}`).border =
    BORDER_STYLE_CELL_DOUBLE_SUMMARY;
  data.totalItemOne?.a ?? "";
  ws.getCell(`E${START + 1 + data.itemOne.length}`).border =
    BORDER_STYLE_CELL_DOUBLE_SUMMARY;
  data.totalItemOne?.b ?? "";
  ws.getCell(`F${START + 1 + data.itemOne.length}`).border =
    BORDER_STYLE_CELL_DOUBLE_SUMMARY;
  data.totalItemOne?.c ?? "";
  ws.getCell(`G${START + 1 + data.itemOne.length}`).border =
    BORDER_STYLE_CELL_DOUBLE_SUMMARY;
  data.totalItemOne?.d ?? "";
  ws.getCell(`H${START + 1 + data.itemOne.length}`).border =
    BORDER_STYLE_CELL_DOUBLE_SUMMARY;
  data.totalItemOne?.d ?? "";

  ws.getCell(`A${START + 3 + data.itemOne.length}`).value = "外注費";

  ws.getCell(`A${START + 3 + data.itemOne.length}`).alignment = {
    vertical: "middle",
    horizontal: "left",
  };

  ws.getCell(`A${START + 4 + data.itemOne.length}`).value =
    "発注予算残金（円）";
  ws.getCell(`B${START + 4 + data.itemOne.length}`).value = "予算消化率（％）";
  ws.getCell(`C${START + 4 + data.itemOne.length}`).value = "";
  ws.getCell(`D${START + 4 + data.itemOne.length}`).value =
    "全体見積金額（円）";
  ws.getCell(`E${START + 4 + data.itemOne.length}`).value =
    "全体原価金額（円）";
  ws.getCell(`F${START + 4 + data.itemOne.length}`).value =
    "全体予算金額（円）";
  ws.getCell(`G${START + 4 + data.itemOne.length}`).value = "率（％）";
  ws.getCell(`H${START + 4 + data.itemOne.length}`).value =
    "全体発注金額（円）";

  // Content of title
  ws.getCell(`A${START + 5 + data.itemOne.length}`).value =
    data?.other?.a ?? "";
  ws.getCell(`B${START + 5 + data.itemOne.length}`).value =
    data?.other?.b ?? "";
  ws.getCell(`C${START + 5 + data.itemOne.length}`).value = "";
  ws.getCell(`D${START + 5 + data.itemOne.length}`).value =
    data?.other?.c ?? "";
  ws.getCell(`E${START + 5 + data.itemOne.length}`).value =
    data?.other?.d ?? "";
  ws.getCell(`F${START + 5 + data.itemOne.length}`).value =
    data?.other?.e ?? "";
  ws.getCell(`G${START + 5 + data.itemOne.length}`).value =
    data?.other?.f ?? "";
  ws.getCell(`H${START + 5 + data.itemOne.length}`).value =
    data?.other?.g ?? "";

  addBorder(ws, 1, 8, START + data.itemOne.length + 3, 1);

  let idxItemTwo = 0;
  data.itemTwo.forEach((item: any) => {
    if (idxItemTwo !== 0) {
      ws.mergeCells(
        `C${idxItemTwo - 1 + START + 7 + data.itemOne.length}:C${
          idxItemTwo + START + 7 + data.itemOne.length
        }`
      );
      ws.mergeCells(
        `D${idxItemTwo - 1 + START + 7 + data.itemOne.length}:D${
          idxItemTwo + START + 7 + data.itemOne.length
        }`
      );
      ws.mergeCells(
        `E${idxItemTwo - 1 + START + 7 + data.itemOne.length}:E${
          idxItemTwo + START + 7 + data.itemOne.length
        }`
      );
      ws.mergeCells(
        `F${idxItemTwo - 1 + START + 7 + data.itemOne.length}:F${
          idxItemTwo + START + 7 + data.itemOne.length
        }`
      );
      ws.mergeCells(
        `G${idxItemTwo - 1 + START + 7 + data.itemOne.length}:G${
          idxItemTwo + START + 7 + data.itemOne.length
        }`
      );
      ws.mergeCells(
        `H${idxItemTwo - 1 + START + 7 + data.itemOne.length}:H${
          idxItemTwo + START + 7 + data.itemOne.length
        }`
      );
      ws.mergeCells(
        `A${idxItemTwo - 1 + START + 7 + data.itemOne.length}:B${
          idxItemTwo - 1 + START + 7 + data.itemOne.length
        }`
      );
      ws.getCell(`A${idxItemTwo - 1 + START + 7 + data.itemOne.length}`).value =
        item?.a ?? "";
      ws.getCell(`A${idxItemTwo + START + 7 + data.itemOne.length}`).value =
        item?.date1 ?? "";
      ws.getCell(`B${idxItemTwo + START + 7 + data.itemOne.length}`).value =
        item?.date2 ?? "";
    } else {
      ws.mergeCells(
        `A${idxItemTwo + START + 7 + data.itemOne.length}:B${
          idxItemTwo + START + 7 + data.itemOne.length
        }`
      );
      ws.getCell(`A${idxItemTwo + START + 7 + data.itemOne.length}`).value =
        item?.a ?? "";
    }
    ws.getCell(`C${idxItemTwo + START + 7 + data.itemOne.length}`).value =
      item?.b ?? "";
    ws.getCell(`D${idxItemTwo + START + 7 + data.itemOne.length}`).value =
      item?.c ?? "";
    ws.getCell(`E${idxItemTwo + START + 7 + data.itemOne.length}`).value =
      item?.d ?? "";
    ws.getCell(`F${idxItemTwo + START + 7 + data.itemOne.length}`).value =
      item?.e ?? "";
    ws.getCell(`G${idxItemTwo + START + 7 + data.itemOne.length}`).value =
      item?.f ?? "";
    ws.getCell(`H${idxItemTwo + START + 7 + data.itemOne.length}`).value =
      item?.g ?? "";
    idxItemTwo += 2;
  });

  ws.mergeCells(
    `A${START + 7 + data.itemOne.length + data.itemTwo.length * 2 - 1}:C${
      START + 7 + data.itemOne.length + data.itemTwo.length * 2 - 1
    }`
  );
  ws.getCell(
    `A${START + 7 + data.itemOne.length + data.itemTwo.length * 2 - 1}`
  ).value = "合計金額（円）";
  // Calculate total
  ws.getCell(
    `D${START + 7 + data.itemOne.length + data.itemTwo.length * 2 - 1}`
  ).value = data.totalItemTwo?.a ?? "";
  ws.getCell(
    `E${START + 7 + data.itemOne.length + data.itemTwo.length * 2 - 1}`
  ).value = data.totalItemTwo?.b ?? "";
  ws.getCell(
    `F${START + 7 + data.itemOne.length + data.itemTwo.length * 2 - 1}`
  ).value = data.totalItemTwo?.c ?? "";
  ws.getCell(
    `G${START + 7 + data.itemOne.length + data.itemTwo.length * 2 - 1}`
  ).value = data.totalItemTwo?.d ?? "";

  addBorder(
    ws,
    1,
    8,
    START + 6 + data.itemOne.length,
    data.itemTwo.length * 2 - 1
  );
  // // Border double summary
  ws.getCell(
    `A${START + 7 + data.itemOne.length + data.itemTwo.length * 2 - 1}`
  ).border = BORDER_STYLE_CELL_DOUBLE_SUMMARY;
  ws.getCell(
    `D${START + 7 + data.itemOne.length + data.itemTwo.length * 2 - 1}`
  ).border = BORDER_STYLE_CELL_DOUBLE_SUMMARY;
  ws.getCell(
    `E${START + 7 + data.itemOne.length + data.itemTwo.length * 2 - 1}`
  ).border = BORDER_STYLE_CELL_DOUBLE_SUMMARY;
  ws.getCell(
    `F${START + 7 + data.itemOne.length + data.itemTwo.length * 2 - 1}`
  ).border = BORDER_STYLE_CELL_DOUBLE_SUMMARY;
  ws.getCell(
    `G${START + 7 + data.itemOne.length + data.itemTwo.length * 2 - 1}`
  ).border = BORDER_STYLE_CELL_DOUBLE_SUMMARY;
  ws.getCell(
    `H${START + 7 + data.itemOne.length + data.itemTwo.length * 2 - 1}`
  ).border = BORDER_STYLE_CELL_DOUBLE_SUMMARY;

  ws.getCell(
    `A${START + 9 + data.itemOne.length + data.itemTwo.length * 2 - 1}`
  ).value = "労務費";
  ws.getCell(
    `A${START + 9 + data.itemOne.length + data.itemTwo.length * 2 - 1}`
  ).alignment = {
    vertical: "middle",
    horizontal: "left",
  };

  data.itemThree.forEach((item: any, idx: number) => {
    ws.mergeCells(
      `A${
        idx + START + 10 + data.itemOne.length + data.itemTwo.length * 2 - 1
      }:B${
        idx + START + 10 + data.itemOne.length + data.itemTwo.length * 2 - 1
      }`
    );
    ws.getCell(
      `A${idx + START + 10 + data.itemOne.length + data.itemTwo.length * 2 - 1}`
    ).value = item?.a ?? "";
    ws.getCell(
      `C${idx + START + 10 + data.itemOne.length + data.itemTwo.length * 2 - 1}`
    ).value = item?.b ?? "";
    ws.getCell(
      `D${idx + START + 10 + data.itemOne.length + data.itemTwo.length * 2 - 1}`
    ).value = item?.c ?? "";
    ws.getCell(
      `E${idx + START + 10 + data.itemOne.length + data.itemTwo.length * 2 - 1}`
    ).value = item?.d ?? "";
    ws.getCell(
      `F${idx + START + 10 + data.itemOne.length + data.itemTwo.length * 2 - 1}`
    ).value = item?.e ?? "";
    ws.getCell(
      `G${idx + START + 10 + data.itemOne.length + data.itemTwo.length * 2 - 1}`
    ).value = item?.f ?? "";
    ws.getCell(
      `H${idx + START + 10 + data.itemOne.length + data.itemTwo.length * 2 - 1}`
    ).value = item?.g ?? "";
  });

  ws.mergeCells(
    `A${
      START +
      10 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length
    }:C${
      START +
      10 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length
    }`
  );
  ws.getCell(
    `A${
      START +
      10 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length
    }`
  ).value = "合計金額（円）";
  // Calculate total
  ws.getCell(
    `D${
      START +
      10 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length
    }`
  ).value = data.totalItemThree?.a ?? "";
  ws.getCell(
    `E${
      START +
      10 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length
    }`
  ).value = data.totalItemThree?.b ?? "";
  ws.getCell(
    `F${
      START +
      10 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length
    }`
  ).value = data.totalItemThree?.c ?? "";
  ws.getCell(
    `G${
      START +
      10 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length
    }`
  ).value = "";

  addBorder(
    ws,
    1,
    8,
    START + 9 + data.itemOne.length + data.itemTwo.length * 2 - 1,
    data.itemThree.length
  );

  ws.getCell(
    `A${
      START +
      10 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length
    }`
  ).border = BORDER_STYLE_CELL_DOUBLE_SUMMARY;

  ws.getCell(
    `D${
      START +
      10 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length
    }`
  ).border = BORDER_STYLE_CELL_DOUBLE_SUMMARY;
  ws.getCell(
    `E${
      START +
      10 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length
    }`
  ).border = BORDER_STYLE_CELL_DOUBLE_SUMMARY;
  ws.getCell(
    `F${
      START +
      10 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length
    }`
  ).border = BORDER_STYLE_CELL_DOUBLE_SUMMARY;
  ws.getCell(
    `G${
      START +
      10 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length
    }`
  ).border = BORDER_STYLE_CELL_DOUBLE_SUMMARY;
  ws.getCell(
    `H${
      START +
      10 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length
    }`
  ).border = BORDER_STYLE_CELL_DOUBLE_SUMMARY;

  ws.mergeCells(
    `A${
      START +
      12 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length
    }:H${
      START +
      12 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length
    }`
  );
  ws.getCell(
    `A${
      START +
      12 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length
    }`
  ).value = "直接工事費合計";
  ws.getCell(
    `A${
      START +
      12 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length
    }`
  ).alignment = {
    vertical: "middle",
    horizontal: "left",
  };
  ws.mergeCells(
    `A${
      START +
      13 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length
    }:C${
      START +
      13 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length
    }`
  );
  ws.getCell(
    `A${
      START +
      13 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length
    }`
  ).value = "";
  ws.getCell(
    `D${
      START +
      13 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length
    }`
  ).value = "見積金額（円）";
  ws.getCell(
    `E${
      START +
      13 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length
    }`
  ).value = "原価金額（円）";
  ws.getCell(
    `F${
      START +
      13 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length
    }`
  ).value = "予算金額（円）";
  ws.getCell(
    `G${
      START +
      13 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length
    }`
  ).value = "率（％）";
  ws.getCell(
    `H${
      START +
      13 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length
    }`
  ).value = "発注金額（円）";

  ws.mergeCells(
    `A${
      START +
      14 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length
    }:C${
      START +
      14 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length
    }`
  );
  ws.getCell(
    `A${
      START +
      14 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length
    }`
  ).value = "合計金額（円）";
  ws.getCell(
    `D${
      START +
      14 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length
    }`
  ).value = data?.totalDirect?.a ?? "";
  ws.getCell(
    `E${
      START +
      14 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length
    }`
  ).value = data?.totalDirect?.b ?? "";
  ws.getCell(
    `F${
      START +
      14 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length
    }`
  ).value = data?.totalDirect?.c ?? "";
  ws.getCell(
    `G${
      START +
      14 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length
    }`
  ).value = "";
  ws.getCell(
    `H${
      START +
      14 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length
    }`
  ).value = data?.totalDirect?.d ?? "";

  addBorder(
    ws,
    1,
    8,
    START +
      12 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length,
    1
  );

  ws.getCell(
    `A${
      START +
      14 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length
    }`
  ).border = BORDER_STYLE_CELL_DOUBLE_SUMMARY;
  ws.getCell(
    `D${
      START +
      14 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length
    }`
  ).border = BORDER_STYLE_CELL_DOUBLE_SUMMARY;
  ws.getCell(
    `E${
      START +
      14 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length
    }`
  ).border = BORDER_STYLE_CELL_DOUBLE_SUMMARY;
  ws.getCell(
    `F${
      START +
      14 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length
    }`
  ).border = BORDER_STYLE_CELL_DOUBLE_SUMMARY;
  ws.getCell(
    `G${
      START +
      14 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length
    }`
  ).border = BORDER_STYLE_CELL_DOUBLE_SUMMARY;
  ws.getCell(
    `H${
      START +
      14 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length
    }`
  ).border = BORDER_STYLE_CELL_DOUBLE_SUMMARY;

  ws.mergeCells(
    `A${
      START +
      16 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length
    }:H${
      START +
      16 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length
    }`
  );
  ws.getCell(
    `A${
      START +
      16 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length
    }`
  ).value = "間接工事費一覧";
  ws.getCell(
    `A${
      START +
      16 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length
    }`
  ).font = { bold: true };
  ws.getCell(
    `A${
      START +
      16 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length
    }`
  ).alignment = {
    vertical: "middle",
    horizontal: "left",
  };

  data.itemFour.forEach((item: any, idx: number) => {
    ws.mergeCells(
      `A${
        idx +
        START +
        17 +
        data.itemOne.length +
        data.itemTwo.length * 2 -
        1 +
        data.itemThree.length
      }:B${
        idx +
        START +
        17 +
        data.itemOne.length +
        data.itemTwo.length * 2 -
        1 +
        data.itemThree.length
      }`
    );
    ws.getCell(
      `A${
        idx +
        START +
        17 +
        data.itemOne.length +
        data.itemTwo.length * 2 -
        1 +
        data.itemThree.length
      }`
    ).value = item?.a ?? "";
    ws.getCell(
      `C${
        idx +
        START +
        17 +
        data.itemOne.length +
        data.itemTwo.length * 2 -
        1 +
        data.itemThree.length
      }`
    ).value = item?.b ?? "";
    ws.getCell(
      `D${
        idx +
        START +
        17 +
        data.itemOne.length +
        data.itemTwo.length * 2 -
        1 +
        data.itemThree.length
      }`
    ).value = item?.c ?? "";
    ws.getCell(
      `E${
        idx +
        START +
        17 +
        data.itemOne.length +
        data.itemTwo.length * 2 -
        1 +
        data.itemThree.length
      }`
    ).value = item?.d ?? "";
    ws.getCell(
      `F${
        idx +
        START +
        17 +
        data.itemOne.length +
        data.itemTwo.length * 2 -
        1 +
        data.itemThree.length
      }`
    ).value = item?.e ?? "";
    ws.getCell(
      `G${
        idx +
        START +
        17 +
        data.itemOne.length +
        data.itemTwo.length * 2 -
        1 +
        data.itemThree.length
      }`
    ).value = item?.f ?? "";
    ws.getCell(
      `H${
        idx +
        START +
        17 +
        data.itemOne.length +
        data.itemTwo.length * 2 -
        1 +
        data.itemThree.length
      }`
    ).value = item?.g ?? "";
  });

  ws.mergeCells(
    `A${
      START +
      17 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }:C${
      START +
      17 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  );
  ws.getCell(
    `A${
      START +
      17 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  ).value = "合計金額（円）";
  // Calculate total
  ws.getCell(
    `D${
      START +
      17 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  ).value = data.totalItemFour?.a ?? "";
  ws.getCell(
    `E${
      START +
      17 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  ).value = data.totalItemFour?.b ?? "";
  ws.getCell(
    `F${
      START +
      17 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  ).value = data.totalItemFour?.c ?? "";
  ws.getCell(
    `G${
      START +
      17 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  ).value = "";

  addBorder(
    ws,
    1,
    8,
    START +
      16 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length,
    data.itemFour.length
  );
  ws.getCell(
    `A${
      START +
      17 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  ).border = BORDER_STYLE_CELL_DOUBLE_SUMMARY;
  // Calculate total
  ws.getCell(
    `D${
      START +
      17 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  ).border = BORDER_STYLE_CELL_DOUBLE_SUMMARY;
  ws.getCell(
    `E${
      START +
      17 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  ).border = BORDER_STYLE_CELL_DOUBLE_SUMMARY;
  ws.getCell(
    `F${
      START +
      17 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  ).border = BORDER_STYLE_CELL_DOUBLE_SUMMARY;
  ws.getCell(
    `G${
      START +
      17 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  ).border = BORDER_STYLE_CELL_DOUBLE_SUMMARY;
  ws.getCell(
    `H${
      START +
      17 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  ).border = BORDER_STYLE_CELL_DOUBLE_SUMMARY;

  createOuterBorder(
    ws,
    { row: 17, col: 1 },
    {
      row:
        START +
        17 +
        data.itemOne.length +
        data.itemTwo.length * 2 -
        1 +
        data.itemThree.length +
        data.itemFour.length,
      col: 8,
    }
  );

  ws.mergeCells(
    `A${
      START +
      19 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }:H${
      START +
      19 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  );
  ws.getCell(
    `A${
      START +
      19 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  ).value = "粗利";
  ws.getCell(
    `A${
      START +
      19 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  ).font = { bold: true };
  ws.getCell(
    `A${
      START +
      19 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  ).alignment = {
    vertical: "middle",
    horizontal: "left",
  };

  ws.mergeCells(
    `A${
      START +
      20 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }:C${
      START +
      20 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  );
  ws.getCell(
    `A${
      START +
      20 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  ).value = "";
  ws.getCell(
    `D${
      START +
      20 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  ).value = "";
  ws.getCell(
    `E${
      START +
      20 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  ).value = "";
  ws.getCell(
    `F${
      START +
      20 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  ).value = "予想粗利金額（円）";
  ws.getCell(
    `G${
      START +
      20 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  ).value = "率（％）";

  ws.mergeCells(
    `A${
      START +
      21 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }:C${
      START +
      21 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  );
  ws.getCell(
    `A${
      START +
      21 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  ).value = "";
  ws.getCell(
    `D${
      START +
      21 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  ).value = "";
  ws.getCell(
    `E${
      START +
      21 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  ).value = "";
  ws.getCell(
    `F${
      START +
      21 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  ).value = data?.grossProfit?.a ?? "";
  ws.getCell(
    `G${
      START +
      21 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  ).value = "";

  addBorder(
    ws,
    1,
    8,
    START +
      19 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length,
    1
  );

  createOuterBorder(
    ws,
    {
      row:
        START +
        19 +
        data.itemOne.length +
        data.itemTwo.length * 2 -
        1 +
        data.itemThree.length +
        data.itemFour.length,
      col: 1,
    },
    {
      row:
        START +
        21 +
        data.itemOne.length +
        data.itemTwo.length * 2 -
        1 +
        data.itemThree.length +
        data.itemFour.length,
      col: 8,
    }
  );

  ws.mergeCells(
    `A${
      START +
      23 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }:H${
      START +
      23 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  );
  ws.getCell(
    `A${
      START +
      23 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  ).value = "総計";
  ws.getCell(
    `A${
      START +
      23 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  ).font = { bold: true };
  ws.getCell(
    `A${
      START +
      23 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  ).alignment = {
    vertical: "middle",
    horizontal: "left",
  };

  ws.mergeCells(
    `A${
      START +
      24 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }:C${
      START +
      24 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  );
  ws.getCell(
    `A${
      START +
      24 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  ).value = "";
  ws.getCell(
    `D${
      START +
      24 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  ).value = "見積金額（円）";
  ws.getCell(
    `E${
      START +
      24 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  ).value = "原価金額（円）";
  ws.getCell(
    `F${
      START +
      24 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  ).value = "予算金額（円）";
  ws.getCell(
    `G${
      START +
      24 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  ).value = "率（％）";
  ws.getCell(
    `H${
      START +
      24 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  ).value = "発注金額（円）";

  ws.mergeCells(
    `A${
      START +
      25 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }:C${
      START +
      25 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  );
  ws.getCell(
    `A${
      START +
      25 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  ).value = "";
  ws.getCell(
    `D${
      START +
      25 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  ).value = data.total?.a ?? "";
  ws.getCell(
    `E${
      START +
      25 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  ).value = data.total?.b ?? "";
  ws.getCell(
    `F${
      START +
      25 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  ).value = data.total?.c ?? "";
  ws.getCell(
    `G${
      START +
      25 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  ).value = "";

  addBorder(
    ws,
    1,
    8,
    START +
      23 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length,
    1
  );

  ws.getCell(
    `A${
      START +
      25 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  ).border = BORDER_STYLE_CELL_DOUBLE_SUMMARY;
  ws.getCell(
    `D${
      START +
      25 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  ).border = BORDER_STYLE_CELL_DOUBLE_SUMMARY;
  ws.getCell(
    `E${
      START +
      25 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  ).border = BORDER_STYLE_CELL_DOUBLE_SUMMARY;
  ws.getCell(
    `F${
      START +
      25 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  ).border = BORDER_STYLE_CELL_DOUBLE_SUMMARY;
  ws.getCell(
    `G${
      START +
      25 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  ).border = BORDER_STYLE_CELL_DOUBLE_SUMMARY;
  ws.getCell(
    `H${
      START +
      25 +
      data.itemOne.length +
      data.itemTwo.length * 2 -
      1 +
      data.itemThree.length +
      data.itemFour.length
    }`
  ).border = BORDER_STYLE_CELL_DOUBLE_SUMMARY;

  const borderIndex =
    START +
    23 +
    data.itemOne.length +
    data.itemTwo.length * 2 -
    1 +
    data.itemThree.length +
    data.itemFour.length;

  createOuterBorder(
    ws,
    {
      row:
        START +
        23 +
        data.itemOne.length +
        data.itemTwo.length * 2 -
        1 +
        data.itemThree.length +
        data.itemFour.length,
      col: 1,
    },
    {
      row:
        START +
        25 +
        data.itemOne.length +
        data.itemTwo.length * 2 -
        1 +
        data.itemThree.length +
        data.itemFour.length,
      col: 8,
    }
  );
  return borderIndex;
};

const writeTableFour = (ws: any, dataFour: any, startIndex: number) => {
  const out: string[] = [];
  dataFour?.role?.forEach((item: any, idx: number) => {
    out.push(`${getColumnNameWithIndex(idx + 2)}`);
  });

  ws.mergeCells(`A${startIndex}:${out[out.length - 1]}${startIndex}`);
  ws.getCell(`A${startIndex}`).value = "回覧・承認";
  ws.getCell(`A${startIndex + 1}`).value = "役職";
  ws.getCell(`A${startIndex + 2}`).value = "氏名";
  ws.getCell(`A${startIndex + 3}`).value = "承認日";

  // Write role

  const roles: string[] = [];

  dataFour?.role?.forEach((item: any, idx: number) => {
    ws.getCell(`${getColumnNameWithIndex(idx + 2)}${startIndex + 1}`).value =
      item;
    adjustWithColumn(ws, [`${getColumnNameWithIndex(idx + 2)}`], 20);
    ws.getCell(
      `${getColumnNameWithIndex(idx + 2)}}${startIndex + 1}`
    ).alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    roles.push(`${getColumnNameWithIndex(idx + 2)}}${startIndex + 1}`);
  });

  // Write name
  const names: string[] = [];

  dataFour?.name?.forEach((item: any, idx: number) => {
    ws.getCell(`${getColumnNameWithIndex(idx + 2)}}${startIndex + 2}`).value =
      item;
    ws.getCell(
      `${getColumnNameWithIndex(idx + 2)}}${startIndex + 2}`
    ).alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    names.push(`${getColumnNameWithIndex(idx + 2)}}${startIndex + 2}`);
  });

  // Write date

  const dates: string[] = [];
  dataFour?.date?.forEach((item: any, idx: number) => {
    ws.getCell(`${getColumnNameWithIndex(idx + 2)}}${startIndex + 3}`).value =
      item;
    ws.getCell(
      `${getColumnNameWithIndex(idx + 2)}}${startIndex + 3}`
    ).alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    dates.push(`${getColumnNameWithIndex(idx + 2)}}${startIndex + 3}`);
  });

  ws.getCell(`A${startIndex + 3}`).alignment = {
    vertical: "middle",
    horizontal: "center",
  };
  ws.getCell(`A${startIndex + 2}`).alignment = {
    vertical: "middle",
    horizontal: "center",
  };
  ws.getCell(`A${startIndex + 1}`).alignment = {
    vertical: "middle",
    horizontal: "center",
  };
  ws.getCell(`A${startIndex}`).alignment = {
    vertical: "middle",
    horizontal: "center",
  };
  ws.getCell(`A${startIndex}`).font = {
    bold: true,
  };

  adjustBorderCell(
    ws,
    [
      `A${startIndex}`,
      `A${startIndex + 1}`,
      `A${startIndex + 2}`,
      `A${startIndex + 3}`,
      ...roles,
      ...names,
      ...dates,
    ],
    BORDER_STYLE_CELL
  );
  createOuterBorder(
    ws,
    { row: startIndex, col: 1 },
    {
      row: startIndex + 3,
      col: (dataFour?.role?.length ?? 0) + 1,
    }
  );
};
const writeContent = (ws: any, data: any) => {
  // Write table 1
  writeTableOne(ws, data?.tableOne);
  writeTableTwo(ws, data?.tableTwo);
  const startFour = writeTableThree(ws, data?.tableThree);
  writeTableFour(ws, data.tableFour, startFour + 4);

  // Write title
  addWidth(ws, 1, 10, 18);
  ws.mergeCells(`A1:H1`);
  ws.getCell(`A1`).value = "実行予算書";
  ws.getCell(`A1`).alignment = {
    vertical: "middle",
    horizontal: "center",
  };
  ws.getCell(`A1`).font = { bold: true, size: 20 };
  ws.getRow(1).height = 25;

  ws.mergeCells(`A2:H2`);
  ws.getCell(`A2`).value = `出力日       ${moment().format(
    COMMON.FORMAT_DATE_CI
  )}`;
  ws.getCell(`A2`).alignment = { horizontal: "right" };
};

const exportCR = (
  sheetName: string,
  fileName: string,
  data: any,
  setLoading: Function
) => {
  const wb = new Excel.Workbook();
  const ws = wb.addWorksheet(sheetName);

  // Remove auto filter

  ws.autoFilter = undefined;

  // Write file
  writeContent(ws, data);

  setLoading(true);

  wb.xlsx
    .writeBuffer()
    .then((buffer) => FileSaver.saveAs(new Blob([buffer]), `${fileName}.xlsx`))
    .catch((err: any) => {
      throw err;
    })
    .finally(() => setLoading(false));
};

export { exportCR };
