import { ConstructionCompany } from "../type/Presenter";

const convertConstructionCompany = (value: any[]): ConstructionCompany[] => {
  const result: ConstructionCompany[] = [];
  value.forEach((element: any) => {
    result.push({
      companyId: element.company.companyInfo.id,
      constructionId: element.construction.id,
      isCheckAll: false,
      isOpenAll: false,
      totalMember: 0,
    });
  });
  return result;
};

const convertToPayload = (formValue: any, constructionCompany: any[]) => {
  // object form -> array
  const formData: any[] = [];
  for (const property in formValue) {
    if (formValue[`${property}`].listUserId.length > 0)
      formData.push({ ...formValue[`${property}`] });
  }

  // construction id array
  const listConstruction = constructionCompany.map(
    (element) => element.construction.id
  );

  // convert to payload
  const payloadData: any[] = [];
  listConstruction.forEach((constructId) => {
    const listChildren = formData.filter(
      (element) => element.constructionId === constructId
    );
    if (listChildren.length === 0) return;
    const constructionData = listChildren.map((element) => {
      // list user
      const listUserId = element.listUserId.map((element: any) => element.id);

      // data
      if (element.subConstructionId)
        return { constructionId: element.subConstructionId, listUserId };
      return { ...element, listUserId };
    });
    payloadData.push({
      constructionId: constructId,
      data: constructionData,
    });
  });
  return payloadData;
};
const findElementInTree = (arrayInput: any, wantedNodeInput: any) => {
  if (arrayInput?.length === 0) return [];
  let resultWantedNode: any = null;

  const getWantedNode = (array: any, wantedNode: any) => {
    if (array.length === 0 || resultWantedNode) return;
    for (let i = 0; i < array.length; i++) {
      if (resultWantedNode) {
        return;
      }
      if (array[i].id === wantedNode + "") {
        resultWantedNode = array[i];
        return;
      }
      if (array[i].children && array[i].children.length > 0) {
        getWantedNode(array[i].children, wantedNode);
      }
    }
  };

  getWantedNode(arrayInput, wantedNodeInput);
  if (!resultWantedNode) return [];
  const resultsFlat: any = [];
  if (resultWantedNode.children) {
    const flattenData = (array: any) => {
      if (array.length === 0) return;
      for (let i = 0; i < array.length; i++) {
        resultsFlat.push(array[i].id);
        if (array[i]?.children) flattenData(array[i].children);
      }
    };
    flattenData(resultWantedNode.children);
  }

  return resultsFlat;
};

const transformTreeData = (valueArr: any) => {
  const cloneValue: any[] = [...valueArr];
  let treeLevel = 1;
  const transformRecursion = (arr: any[], treeLevel: any) => {
    for (let i = 0; i < arr.length; i++) {
      arr[i].id = arr[i].id + "" + treeLevel;
      arr[i].treeLevel = treeLevel;
      if (arr[i]?.children) transformRecursion(arr[i].children, treeLevel + 1);
    }
  };
  transformRecursion(cloneValue, treeLevel);
  return cloneValue;
};

export const MAIN_CONSTRUCTION_TYPE = "main";
const sortConstructionList = (valueArr: any[]) => {
  const mainConstruction = valueArr.find(
    (element: any) => element?.constructionType?.code === MAIN_CONSTRUCTION_TYPE
  );
  if (!mainConstruction) return valueArr.reverse();
  const otherConstructions = valueArr.filter(
    (element: any) => element?.constructionType?.code !== MAIN_CONSTRUCTION_TYPE
  );
  const result: any[] = [mainConstruction, ...otherConstructions.reverse()];
  if (result.length !== valueArr.length) return valueArr;
  return result;
};

export {
  convertConstructionCompany,
  convertToPayload,
  findElementInTree,
  transformTreeData,
  sortConstructionList,
};
