import MenuApi from './infrastructure/api/Api';
import Handler from './presenter/handler/Handler';
import Service from './usecase/Service';
import React from 'react';
import '../../../assets/styles/AM/AM002.css';
import '../../../assets/styles/OM/OM002.css';
import { MainUI } from './presenter/ui/UI';

const WorkflowNotificationComponent = () => {
    const api = MenuApi();
    const service = Service(api);
    const handler = Handler(service);
    return <MainUI handler={handler} />;
};

export default WorkflowNotificationComponent;
