import { useSearchParams } from 'react-router-dom';

export const DEFAULT_TAB_VALUE = '1';
export const DEFAULT_TAB_KEY = 'tab';

const TabHandler = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const switchTab = (tab: string) => {
        searchParams.set(DEFAULT_TAB_KEY, tab);
        setSearchParams(searchParams);
    };

    return {
        switchTab,
    };
};

export default TabHandler;
