
import PM007Api from "./infrastructure/api/Api";
import PM007Handler from "./presenter/handler/Handler";
import PM007UI from "./presenter/ui/UI";
import PM007Service from "./usecase/Service";

const PM007Component = () => {
    const pm007Api = PM007Api();
    const pm007Service = PM007Service(pm007Api);
    const pm007Handler = PM007Handler(pm007Service);
    return <PM007UI handler={pm007Handler} />
}

export default PM007Component