import moment from "moment";
import COMMON from "../../../common/constants/COMMON";
import helpers from "../../../common/helpers/common";
import { DayEvent, DayEventState } from "./entity/Entity";

const getRangeDateFromToWithoutTimeZone = (
  fromDate: string,
  toDate: string
) => {
  const from = moment(fromDate).format(COMMON.FORMAT_DATE2);
  const to = moment(toDate).format(COMMON.FORMAT_DATE2);

  let now = moment(from).clone(),
    dates = [];

  while (now.isSameOrBefore(to)) {
    dates.push(moment(now).format(COMMON.FORMAT_DATE2));
    now.add(1, "days");
  }

  return dates;
};

const getRangeDateFromTo = (fromDate: string, toDate: string) => {
  const from = moment(fromDate);
  const to = moment(toDate);

  let now = from.clone(),
    dates = [];

  while (now.isSameOrBefore(to)) {
    dates.push(moment(now));
    now.add(1, "days");
  }

  return dates;
};

const getRangeDate = (date: string) => {
  const start = moment(date).startOf("week");
  const end = moment(date).endOf("week");

  let now = start.clone(),
    dates = [];
  while (now.isSameOrBefore(end)) {
    if (now.isSame(date)) {
      dates.push({
        isCurrent: true,
        date: now.format(COMMON.FORMAT_DAY),
        fullDate: now.format(COMMON.FORMAT_DATE2),
      });
    } else {
      dates.push({
        isCurrent: false,
        date: now.format(COMMON.FORMAT_DAY),
        fullDate: now.format(COMMON.FORMAT_DATE2),
      });
    }
    now.add(1, "days");
  }

  return dates;
};

const getRangeDateInMonth = (date: string) => {
  const start = moment(date).startOf("month");
  const end = moment(date).endOf("month");

  let now = start.clone(),
    dates = [];
  while (now.isSameOrBefore(end)) {
    if (now.isSame(date)) {
      dates.push({
        date: now.format(COMMON.FORMAT_DATE2),
      });
    } else {
      dates.push({
        date: now.format(COMMON.FORMAT_DATE2),
      });
    }
    now.add(1, "days");
  }

  return dates;
};

const getDayOfWeek = (date: string) => {
  const dates = getRangeDate(date);
  let obj: any = {};
  dates.forEach((item: any, idx: number) => {
    switch (idx) {
      case 6:
        obj["sun"] = item;
        break;
      case 0:
        obj["mon"] = item;
        break;
      case 1:
        obj["tue"] = item;
        break;
      case 2:
        obj["web"] = item;
        break;
      case 3:
        obj["thu"] = item;
        break;
      case 4:
        obj["fri"] = item;
        break;
      case 5:
        obj["sat"] = item;
        break;
      default:
        break;
    }
  });
  return obj;
};

const formatListEvent = (
  data: any[],
  currentDate: string,
  valueMember: any[]
) => {
  const out: any[] = [];

  const user = helpers.getObjectFromLocalStorage("user");

  const dt: any[] = [];

  data.forEach((item: any) => {
    let _mapper: any = {};
    const dates = getRangeDateFromTo(item.startTime, item.endTime);
    const dateZs = getRangeDateFromToWithoutTimeZone(
      item.startTime,
      item.endTime
    );
    let resultData: any[] = []; // Date output

    if (dates.length > dateZs.length) {
      dates.forEach(
        (date: any) =>
          (_mapper[`${moment(date).format(COMMON.FORMAT_DATE2)}`] = date)
      );
      dateZs.forEach((date: any) => {
        if (date in _mapper) {
          resultData.push(_mapper[date]);
        }
      });
    } else if (dates.length < dateZs.length) {
      dates.forEach(
        (date: any) =>
          (_mapper[`${moment(date).format(COMMON.FORMAT_DATE2)}`] = date)
      );
      dateZs.forEach((date: any) => {
        if (date in _mapper) {
          resultData.push(_mapper[date]);
        } else {
          const addDay = dates.pop()?.clone();
          resultData.push(addDay?.add(1, "days"));
        }
      });
    } else {
      resultData = dates;
    }

    resultData.forEach((date: any) => {
      dt.push({
        ...item,
        startTime: date,
      });
    });
  });

  if (dt.length === 0) {
    out.push({
      username: user.username,
    });
  } else {
    dt.forEach((item: any) => {
      if (item?.listMember && item?.listMember.length > 0) {
        item?.listMember.forEach((ite: any) => {
          out.push({
            ...ite,
            ...item,
          });
        });
      }
    });
  }

  let mapper: any = {};

  out.forEach((item: any) => {
    if (item?.startTime) {
      const compareDate = moment(item.startTime).format(COMMON.FORMAT_DATE2);
      if (`${item.username}-${item.fullName}` in mapper) {
        mapper[`${item.username}-${item.fullName}`].push({
          ...item,
          compareDate: compareDate,
        });
      } else {
        mapper[`${item.username}-${item.fullName}`] = [
          { ...item, compareDate: compareDate },
        ];
      }
    } else {
      mapper[`${item.username}-${item.fullName}`] = [];
    }
  });

  let store: any = {};

  if (valueMember.length === 0) {
    store[`${user.username}-${user.fullName}`] =
      mapper[`${user.username}-${user.fullName}`] ?? [];
  } else {
    store[`${user.username}-${user.fullName}`] =
      mapper[`${user.username}-${user.fullName}`] ?? [];

    valueMember.forEach((item: any) => {
      if (mapper[`${item.username}-${item.fullName}`] !== undefined) {
        store[`${item.username}-${item.fullName}`] =
          mapper[`${item.username}-${item.fullName}`] ?? [];
      } else {
        store[`${item.username}-${item.fullName}`] = [];
      }
    });
  }

  Object.keys(store).forEach((key: string) => {
    let _group: any = {};

    const dates = getRangeDate(currentDate);

    store[key].forEach((item: any) => {
      if (item.compareDate in _group) {
        _group[`${item.compareDate}`].push({ ...item });
      } else {
        _group[`${item.compareDate}`] = [{ ...item }];
      }
    });

    let _sub: any = {};

    dates.forEach((date: any) => {
      if (_group[date.fullDate] === undefined) {
        _sub[date.fullDate] = [];
      } else {
        _sub[date.fullDate] = _group[date.fullDate];
      }
    });

    let resp: any[] = [];

    for (const [key, value] of Object.entries(_sub)) {
      resp.push({
        date: key,
        data: value,
      });
    }

    // SORT: ASC

    resp.sort((a, b: any) => {
      return moment(a.date).diff(b.date);
    });

    store[key] = resp;
  });

  const resp: any[] = [];

  for (const [key, value] of Object.entries(store)) {
    resp.push({
      member: key.split("-")[0] ? key.split("-")[0] : "",
      fullName: key.split("-")[1] ? key.split("-")[1] : "",
      data: value,
    });
  }

  const finded = resp.find((item: any) => item.member === user.username);
  const foundIndx = resp.findIndex((el) => el.member === user.username);
  resp.splice(foundIndx, 1);
  resp.unshift(finded);

  return { origin: resp };
};

const isExist = (arr: any[], username: string) => {
  return arr.some((item: any) => item.username === username);
};

const formatListEventMonth = (
  data: any[],
  valueMember: any[],
  currentDate: string
) => {
  const out: any[] = [];

  const user = helpers.getObjectFromLocalStorage("user");

  const dt: any[] = [];

  data.forEach((item: any) => {
    let _mapper: any = {};
    const dates = getRangeDateFromTo(item.startTime, item.endTime);
    const dateZs = getRangeDateFromToWithoutTimeZone(
      item.startTime,
      item.endTime
    );
    let oD: any[] = []; // Date output

    if (dates.length > dateZs.length) {
      dates.forEach(
        (date: any) =>
          (_mapper[`${moment(date).format(COMMON.FORMAT_DATE2)}`] = date)
      );
      dateZs.forEach((date: any) => {
        if (date in _mapper) {
          oD.push(_mapper[date]);
        }
      });
    } else if (dates.length < dateZs.length) {
      dates.forEach(
        (date: any) =>
          (_mapper[`${moment(date).format(COMMON.FORMAT_DATE2)}`] = date)
      );
      dateZs.forEach((date: any) => {
        if (date in _mapper) {
          oD.push(_mapper[date]);
        } else {
          const addDay = dates.pop()?.clone();
          oD.push(addDay?.add(1, "days"));
        }
      });
    } else {
      oD = dates;
    }

    oD.forEach((date: any) => {
      dt.push({
        ...item,
        startTime: date,
      });
    });
  });

  dt.forEach((item: any) => {
    const compareDate = moment(item.startTime).format(COMMON.FORMAT_DATE2);
    if (item?.listMember && item?.listMember.length > 0) {
      item?.listMember.forEach((ite: any) => {
        out.push({
          ...ite,
          ...item,
          compareDate: compareDate,
          color: helpers.getRandomColor(),
        });
      });
    }
  });

  let _mapperDate: any = {};

  out.forEach((item: any) => {
    if (`${item.compareDate}` in _mapperDate) {
      _mapperDate[`${item.compareDate}`].push({ ...item });
    } else {
      _mapperDate[`${item.compareDate}`] = [{ ...item }];
    }
  });
  if (valueMember.length === 0) {
    Object.keys(_mapperDate).forEach((key: string) => {
      _mapperDate[key] = _mapperDate[key].filter(
        (item: any) => item.username === user.username
      );
    });
  } else {
    Object.keys(_mapperDate).forEach((key: string) => {
      const kq: any[] = [];
      const data = _mapperDate[key] ?? [];

      data.forEach((item: any) => {
        if (
          item.username === user.username ||
          isExist(valueMember, item.username)
        ) {
          kq.push(item);
        }
      });

      _mapperDate[key] = kq;
    });
  }

  const dates = getRangeDateInMonth(currentDate);
  let _out: any = {};
  dates.forEach((item: any) => (_out[item.date] = _mapperDate[item.date]));
  return _out;
};

const formatListEventDay = (input: DayEvent[], valueMember: any[]) => {
  // generate an array by listMember
  const inputArrayByMember: DayEventState[] = [];
  input.forEach((event) => {
    event.listMember?.forEach((member) => {
      inputArrayByMember.push({ ...event, member: member });
    });
  });

  // filter array by current logged in user or chosen member
  const user = helpers.getObjectFromLocalStorage("user");

  const inputFilteredByMember = inputArrayByMember.filter((element) => {
    return (
      element?.member?.username === user.username ||
      valueMember.some(
        (member) => member.username === element?.member?.username ?? ""
      )
    );
  });

  // sort full event and partial event
  const fullDayEvents: DayEventState[] = [];
  const partialDayEvents: DayEventState[] = [];

  inputFilteredByMember.forEach((event) => {
    if (event.isFullDay) fullDayEvents.push(event);
    else partialDayEvents.push(event);
  });

  const sortedFullDayEvents = fullDayEvents.sort(
    (a: DayEventState, b: DayEventState) => {
      const momentDiffStartDate = moment(a.startTime).diff(moment(b.startTime));
      if (momentDiffStartDate > 0) return 1;
      else if (momentDiffStartDate < 0) return -1;
      else {
        const momentDiffCreatedAt = moment(a.createdAt).diff(
          moment(b.createdAt)
        );
        if (momentDiffCreatedAt > 0) return 1;
        else if (momentDiffCreatedAt < 0) return -1;
        return 0;
      }
    }
  );

  const sortedPartialDayEvents = partialDayEvents.sort(
    (a: DayEventState, b: DayEventState) => {
      const momentDiffStartDate = moment(a.startTime).diff(moment(b.startTime));
      if (momentDiffStartDate > 0) return 1;
      else if (momentDiffStartDate < 0) return -1;
      else {
        const momentDiffCreatedAt = moment(a.createdAt).diff(
          moment(b.createdAt)
        );
        if (momentDiffCreatedAt > 0) return 1;
        else if (momentDiffCreatedAt < 0) return -1;
        return 0;
      }
    }
  );
  return [...sortedFullDayEvents, ...sortedPartialDayEvents];
};

const orderEventList = (input: DayEvent[]) => {
  // sort full event and partial event
  const fullDayEvents: DayEventState[] = [];
  const partialDayEvents: DayEventState[] = [];

  input.forEach((event) => {
    if (event.isFullDay) fullDayEvents.push(event);
    else partialDayEvents.push(event);
  });

  return [...fullDayEvents, ...partialDayEvents];
};
export {
  getDayOfWeek,
  formatListEvent,
  formatListEventMonth,
  formatListEventDay,
  orderEventList,
};
