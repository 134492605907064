import { PM012NavHandler } from '../handler/HandlerImpl';
import InfiniteScroll from 'react-infinite-scroll-component';

import { useState, useEffect } from 'react';
import { Tooltip } from 'antd';
import TooltipText from '../../../../../common/components/tooltip-text/TooltipText';

interface props {
    construction: any;
    handler: PM012NavHandler;
}

const SIZE = 10;

const MY_COMPANY = {
    id: 'my_company',
    name: '自社',
    level: 3,
    numberOfMember: 25,
};

function PM012NavItem({ construction, handler }: props) {
    const [page, setPage] = useState(1);
    const [dataNavItem, setDataNavItem] = useState<any[]>([]);

    useEffect(() => {
        const params = {
            //   page,
            //   size: SIZE,
            //   keyword: handler.keywordNav ? handler.keywordNav : handler.keywordNav,
            constructionId: construction?.id,
        };
        (async () => {
            const response = await handler.getMySubcontractor(params);
            if (page === 1) {
                setDataNavItem([MY_COMPANY, ...response]);
            } else {
                setDataNavItem([...dataNavItem, ...response]);
            }
        })();
    }, [construction, handler.keywordNav, handler.refresh]);

    return (
        <div className="pm012-nav-item" key={construction?.id}>
            <ul className="pm012-nav-item-content">
                {/* pm012-nav-item-content-company-active */}
                <InfiniteScroll
                    dataLength={dataNavItem?.length}
                    next={() => setPage(page + 1)}
                    hasMore={true}
                    height={dataNavItem?.length > 5 ? '25vh' : 'auto'}
                    loader={null}
                >
                    {dataNavItem?.map((subcontractor: any) => {
                        return (
                            <li
                                key={`${subcontractor?.id}subcontractor${construction?.id}`}
                                className={`pm012-nav-item-content-company item-text-long ${construction?.id === handler.chosenNavItem?.constructionId &&
                                    subcontractor?.id === handler?.chosenNavItem?.subcontractorId
                                    ? 'pm012-nav-item-content-company-active'
                                    : null
                                    }`}
                                onClick={() =>
                                    handler.handleClickNavItem(
                                        subcontractor?.id,
                                        construction?.id,
                                        subcontractor?.submitProjectId,
                                        subcontractor?.constructionId,
                                    )
                                }
                            >
                                {subcontractor.id !== MY_COMPANY.id ? (
                                    `${subcontractor?.company?.name || ''}${`（${subcontractor?.shortDescription || ''
                                        }）`}`.length > 10 ? (
                                        <TooltipText content={`${subcontractor?.company?.name || ''}${`（${subcontractor?.shortDescription || ''
                                            }）`}`} />
                                    ) : (
                                        <TooltipText content={`${subcontractor?.company?.name}
                                            ${subcontractor?.shortDescription
                                                ? `（${subcontractor?.shortDescription}）`
                                                : ''}`} />
                                    )
                                ) : (
                                    <TooltipText content={subcontractor.name || ""} />
                                )}
                            </li>
                        );
                    })}
                </InfiniteScroll>
            </ul>
        </div>
    );
}

export default PM012NavItem;
