
  import axiosConfig from "../../../../../config/axiosConfig";
  
  const AM024Api = () => {
    const getRequestLeave = async(params:any):Promise<any>=>{
      try {
        const url = "/request/leave";
        const response = await axiosConfig.get(url, { params });
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  
    return {
      getRequestLeave,
    };
  };
  
  export default AM024Api;
  