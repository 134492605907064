import { CM0047APIImpl } from './ServiceImpl';
import {
    BaseResponseType,
    ReReceiveMoneyListData,
    MoneyApproveReceiveMoneyPayload,
    MoneyRejectReceiveMoneyPayload,
} from '../entity/Entity';
import ErrorNotification from '../../../../../common/components/notification/ErrorNotification';
import { NOTIFICATION_TITLE } from '../../../../../common/constants/MESSAGE';

const CM0047Service = (api: CM0047APIImpl) => {
    const getDocumentReceiveMoneyReviewList = async (params: {
        statusId?: string;
        from?: string;
        to?: string;
        page: number;
        size: number;
        sortBy?: string;
        sortType?: string;
        keyword?: string;
    }): Promise<ReReceiveMoneyListData | null> => {
        try {
            const response = await api.getDocumentReceiveMoneyReviewList(params);
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };

    const putMoneyApproveReceiveMoney = async (
        payload: MoneyApproveReceiveMoneyPayload,
    ): Promise<BaseResponseType | null> => {
        try {
            const response = await api.putMoneyApproveReceiveMoney(payload);
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };
    const putMoneyRejectReceiveMoney = async (
        payload: MoneyRejectReceiveMoneyPayload,
    ): Promise<BaseResponseType | null> => {
        try {
            const response = await api.putMoneyRejectReceiveMoney(payload);
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };

    return {
        getDocumentReceiveMoneyReviewList,
        putMoneyApproveReceiveMoney,
        putMoneyRejectReceiveMoney,
    };
};

export default CM0047Service;
