import { Button, Empty, Form, Select } from "antd";
import {
  LABEL_COMPANY,
  LABEL_PROJECT,
  NODATA_TEXT,
} from "../../../../../../../common/constants/COMMON";
import React from "react";
import { FilterCCUSType } from "../../../../entity/Entity";

type Props = {
  onResetFilter: () => void;
  handleGetProject: () => void;
  handleScrollProject: (e: any) => void;
  handleGetCompany: () => void;
  handleScrollCompany: (e: any) => void;
  onChangeProject: (value: string) => void;
  onChangeCompany: (value: string) => void;
  isCollapsed: boolean;
  listProject: FilterCCUSType[];
  listCompany: FilterCCUSType[];
  loadingProject: boolean;
  loadingCompany: boolean;
};

const FilterComponent = ({
  onResetFilter,
  handleGetProject,
  handleGetCompany,
  handleScrollProject,
  handleScrollCompany,
  onChangeProject,
  onChangeCompany,
  listProject,
  listCompany,
  loadingProject,
  loadingCompany,
}: Props) => {
  const content = (
    <React.Fragment>
      <label className="filter-label">{LABEL_PROJECT}</label>
      <div className="item-filter-container">
        <Form.Item
          name="project"
          className="item-filter-child"
          labelAlign="right"
        >
          <Select
            size="large"
            listHeight={130}
            filterOption={() => true}
            loading={loadingProject}
            onDropdownVisibleChange={(open) => {
              if (open) handleGetProject();
            }}
            onPopupScroll={handleScrollProject}
            getPopupContainer={(trigger: any) => trigger.parentNode}
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={NODATA_TEXT}
              />
            }
            allowClear
            onChange={onChangeProject}
          >
            {listProject.map((select: FilterCCUSType) => {
              return (
                <Select.Option
                  style={{ alignItems: "center" }}
                  key={select.id}
                  value={select.id}
                >
                  {select.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      </div>

      <label className="filter-label">{LABEL_COMPANY}</label>
      <div className="item-filter-container">
        <Form.Item
          name="company"
          className="item-filter-child"
          labelAlign="right"
        >
          <Select
            size="large"
            listHeight={130}
            filterOption={() => true}
            loading={loadingCompany}
            onDropdownVisibleChange={(open) => {
              if (open) handleGetCompany();
            }}
            onPopupScroll={handleScrollCompany}
            getPopupContainer={(trigger: any) => trigger.parentNode}
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={NODATA_TEXT}
              />
            }
            allowClear
            onChange={onChangeCompany}
          >
            {listCompany.map((select: FilterCCUSType) => {
              return (
                <Select.Option
                  style={{ alignItems: "center" }}
                  key={select.id}
                  value={select.id}
                >
                  {select.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      </div>

      <div className="btn-filter-container">
        <Button
          htmlType="submit"
          size="large"
          className="btn-filter-width"
          type="primary"
        >
          絞込み
        </Button>
        <Button
          className="reset-filter-btn btn-filter-width"
          onClick={onResetFilter}
          size="large"
        >
          クリア
        </Button>
      </div>
    </React.Fragment>
  );

  return <main style={{ paddingTop: 24 }}>{content}</main>;
};

export default FilterComponent;
