import { createSlice } from '@reduxjs/toolkit';
import { Wrapper3453SliceType } from '../../entity/Entity';

export const initialState: Wrapper3453SliceType = {
    isRefreshCompany: false,
    isRefreshConstruction: false,
    isRefresh: false,
    isCollapsed: false,
    projectChosen: {},
    isRetrieve: true,
};

export const Wrapper3453Slice = createSlice({
    name: 'wrapper3453i',
    initialState,
    reducers: {
        setRetrieve: (state, action) => {
            state.isRetrieve = action.payload;
        },
    },
});

export const { setRetrieve } = Wrapper3453Slice.actions;

export default Wrapper3453Slice.reducer;
