import React from 'react';
import HistoryPaymentUI from './ui/UI';
import HistoryPaymentApi from '../../../infrastructure/api/ApiHistoryPayment';
import HistoryPaymentService from '../../../usecase/ServiceHistoryPayment';
import HistoryPaymentHandler from '../../handler/HandlerHistoryPayment';

const HistoryPaymentComponent = () => {
    const api = HistoryPaymentApi();
    const service = HistoryPaymentService(api);
    const handler = HistoryPaymentHandler(service);
    return <HistoryPaymentUI handler={handler} />;
};

export default HistoryPaymentComponent;
