import { Spin } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { PM014ContentHandler } from '../handler/HandlerImpl';
import { project, refreshPM014 } from '../slice/Slice';
import PM014Header from './PM014Header';
import PM014InviteMemberModal from './PM014InviteMemberModal';
import PM014NoData from './PM014NoData';
import PM014Table from './PM014Table';

interface props {
    handler: PM014ContentHandler;
}

function PM014Content({ handler }: props) {
    const [render, setRender] = React.useState<boolean>(false);
    const constructionId: number = handler.chosenNavItem.constructionId;
    const constractorConstructionId: number = Number(handler.chosenNavItem.subcontractorId);
    const isRefresh = useSelector(refreshPM014);

    React.useEffect(() => {
        handler.funcGetSubcontractorInformation(constructionId, constractorConstructionId, handler.currentTab !== "1");
        handler.setDataParentMemberPM014([]);
        setRender(!render);
    }, [
        handler.chosenNavItem.constructionId,
        handler.chosenNavItem.subcontractorId,
        isRefresh,
        handler.currentTab,
        handler.keySearch
    ]);

    return (
        <div className="pm012-content" key={`${render}`}>
            <PM014Header handler={handler} />
            <div className="pm012-body pm014-body">
                {handler.dataParentPM014 ? (
                    <>
                        <Spin spinning={handler.loadingPM014}>
                            <PM014Table handler={handler} render={`${isRefresh}`} />
                        </Spin>
                        <PM014InviteMemberModal
                            widthProp={1024}
                            marginLeftProp={260}
                            marginTopProp={200}
                            handler={handler}
                        />
                    </>
                ) : (
                    <PM014NoData />
                )}
            </div>
        </div>
    );
}

export default PM014Content;
