// message code must be in alphabetic order.
export const TEXT = {
  // Common message for frontend and backend:
  CCUS01: {
    title: '事業者ID認証'
  },
  MEMBER: {
    manual: "manual"
  }
};
