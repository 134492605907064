import { Button, Form, Input, InputNumber, Modal, Select, Spin } from "antd";
import EmptyDataSVG from "../../../../../assets/icons/empty-data.svg";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import CloseIconModal from "../../../../../assets/icons/close.png";
import Minus_cm0063 from "../../../../../assets/icons/Minus_cm0063.svg";
import Plus_cm0063 from "../../../../../assets/icons/Plus_cm0063.svg";
import { default as XIconModal } from "../../../../../assets/icons/x-icon-modal.svg";
import "../../../../../assets/styles/CM/CM0063.css";
import LoadingComponent from "../../../../../common/components/icons/LoadingComponent";
import COMMON from "../../../../../common/constants/COMMON";
import { RootState } from "../../../../../store";
import { CM0063Hanlder } from "../handler/HandlerImpl";
import { ParentCode } from "../type/Presenter";
import helpers from "../../../../../common/helpers/common";
import REGEX from "../../../../../common/constants/REGEX";
import MESSAGE from "../../../../../common/constants/MESSAGE";
import EditWhiteIcon from "../../../../../assets/icons/edit-white.svg";
type Props = {
  handler: CM0063Hanlder;
};

const CM0063UI = ({ handler }: Props) => {
  const formRef: any = useRef();
  const page = COMMON.DEFAULT_PAGE;
  const size = COMMON.DEFAULT_SIZE;
  const [isClickAdd, setIsClickAdd] = useState<number>(0);
  const isVisible = useSelector((state: RootState) => state.cm0063.isVisible);
  const loading = useSelector((state: RootState) => state.cm0063.loading);
  const orderCN = useSelector(
    (state: RootState) => state.cm0063.orderingCompanyName
  );
  const parentCode: ParentCode = useSelector(
    (state: RootState) => state.cm0063.parentCode
  );
  const [classNameViolateRule, setClassNameViolateRule] =
    useState<boolean>(false);

  useEffect(() => {
    if (isVisible && parentCode?.parentRecordId) {
      handler.handleGetListReceivedMoneyOffset(
        parentCode.parentRecordId,
        page,
        size
      );
      handler.handleGetSubCategory();
    }
  }, [isVisible, parentCode?.parentRecordId]);

  useEffect(() => {
    if (isClickAdd !== 0 && formRef.current) {
      formRef.current.scrollTo(0, formRef.current.scrollHeight);
    }
  }, [isClickAdd]);

  useEffect(() => {
    handler.handleCalculateChange(handler.listReceivedMoneyOffsetLocal);
  }, [`${JSON.stringify(handler.listReceivedMoneyOffsetLocal)}`]);

  return !isVisible ? null : (
    <Modal
      className="group-detail-modal"
      style={{ marginLeft: 300, marginTop: 40, marginBottom: 40 }}
      visible={isVisible}
      closable={false}
      maskClosable={false}
      width={"1027px"}
      centered={true}
      closeIcon={<img src={CloseIconModal} alt="close icon" />}
      footer={
        handler.typeModal ? (
          <>
            <Button
              disabled={loading}
              size="large"
              type="primary"
              style={{ marginLeft: 16, width: 92, letterSpacing: -1.5 }}
              htmlType="submit"
              form="formCM0063"
            >
              確定
            </Button>
            <Button
              disabled={loading}
              size="large"
              style={{
                marginLeft: 16,
                width: 118,
                letterSpacing: -1.5,
                fontWeight: 400,
                fontSize: 14,
                lineHeight: 22,
                color: "#666666",
              }}
              onClick={handler.onCancel}
            >
              キャンセル
            </Button>
          </>
        ) : (
          <Button
            disabled={loading}
            size="large"
            type="primary"
            style={{ marginLeft: 16, width: 92, letterSpacing: -1.5 }}
            onClick={handler.onCancel}
          >
            閉じる
          </Button>
        )
      }
    >
      <Spin indicator={LoadingComponent} spinning={loading}>
        <div
          className="w-100-percent header-modal-containter"
          style={{ height: 50 }}
        >
          <div className="header-modal-title-cm0063">相殺金額</div>
          <div style={{ cursor: "pointer" }} onClick={handler.onCancel}>
            <img alt="" src={XIconModal} style={{ height: 18, width: 18 }} />
          </div>
        </div>
        <div
          style={{ minHeight: 224, marginTop: 24 }}
          className="cm0063-content"
        >
          <div className="content-description">
            <div className="content-description-text">
              {typeof parentCode?.parentRecordId === "number" ? (
                <>
                  <div className="description-col">
                    <label> 発注元会社名</label>
                    <p>
                      {helpers.getShortTextWithToolTip(
                        handler.contentDescription?.orderingCompanyName ?? "",
                        10
                      )}
                    </p>
                  </div>
                  <div className="description-col">
                    <label> 対象期間</label>
                    <p>
                      {handler.contentDescription?.startDate &&
                        handler.contentDescription?.endDate &&
                        `${moment(handler.contentDescription?.startDate).format(
                          COMMON.FORMAT_DATE_CI
                        )} ~ ${moment(
                          handler.contentDescription?.endDate
                        ).format(COMMON.FORMAT_DATE_CI)}`}
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <div className="description-col">
                    <label> 発注元会社名</label>
                    <p>{orderCN}</p>
                  </div>
                  <div className="description-col">
                    <label> 対象期間</label>
                    <p></p>
                  </div>
                </>
              )}
            </div>
            {!handler.typeModal && (
              <div className="content-description-button">
                {typeof parentCode.parentRecordId === "number" &&
                  parentCode.status === true && (
                    <Button
                      size="large"
                      className="button-edit-project"
                      onClick={() => handler.handleChangeTypeModal(true)}
                      icon={
                        <img
                          alt=""
                          src={EditWhiteIcon}
                          style={{
                            paddingRight: 12,
                            border: "none",
                            marginBottom: 4,
                          }}
                        />
                      }
                    >
                      編集
                    </Button>
                  )}
              </div>
            )}
          </div>
          <div className="content-table">
            <div
              className="table-header table-row"
              style={{ paddingRight: "7px" }}
            >
              <div className="cell cell-order">No</div>
              <div className="cell cell-item">項目</div>
              <div className="cell cell-taxCategoryComsumption">消費税区分</div>
              <div className="cell cell-offsetAmount">相殺金額（税抜）</div>
            </div>
            {handler.typeModal && (
              <div
                className="add-item"
                style={{ fontWeight: 700, fontSize: 14, lineHeight: 22 }}
              >
                <img
                  src={Plus_cm0063}
                  alt=""
                  style={{
                    marginLeft: 16,
                    marginRight: 8,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handler.handleAddRowLocal();
                    setIsClickAdd(isClickAdd + 1);
                  }}
                />
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handler.handleAddRowLocal();
                    setIsClickAdd(isClickAdd + 1);
                  }}
                >
                  項目追加
                </div>
              </div>
            )}
            <div ref={formRef} className="form-cm0063">
              <Form
                form={handler.form}
                id="formCM0063"
                style={{
                  height: "100%",
                }}
                onFinish={(value: any) => handler.handleSubmitForm(value)}
                onValuesChange={handler.checkDirty}
              >
                {handler?.listReceivedMoneyOffsetLocal?.map((item, index) => {
                  return !handler.typeModal ? (
                    <div className="table-row" key={item.key}>
                      <div className="cell cell-order">{index + 1}</div>
                      <div className="cell cell-item">{item.item}</div>
                      <div className="cell cell-taxCategoryComsumption">
                        {item.taxCategoryComsumptionName}
                      </div>
                      <div className="cell cell-offsetAmount">
                        {`${helpers.formatCurrency(
                          Number(item.offsetAmount)
                        )}円`}
                      </div>
                    </div>
                  ) : (
                    <div
                      className={
                        classNameViolateRule
                          ? "table-row table-row-violate-rule"
                          : "table-row"
                      }
                      key={item.key}
                    >
                      <div className="cell cell-order">{index + 1}</div>
                      <div className="cell cell-item">
                        <Form.Item
                          name={`item${item.key}`}
                          rules={[
                            () => ({
                              validator(_, value) {
                                if (!REGEX.NOT_VIETNAM.test(value)) {
                                  setClassNameViolateRule(true);
                                  return Promise.reject(
                                    new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                                  );
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input maxLength={20} />
                        </Form.Item>
                      </div>
                      <div className="cell cell-taxCategoryComsumption">
                        <Form.Item name={`taxCategoryConsumption${item.key}`}>
                          <Select
                            defaultValue={`${handler.defaultCategory}`}
                            options={handler?.subCategory}
                            getPopupContainer={(trigger: any) =>
                              trigger.parentNode
                            }
                            listHeight={100}
                          />
                        </Form.Item>
                      </div>
                      <div className="cell cell-offsetAmount">
                        <Form.Item name={`offsetAmount${item.key}`}>
                          <InputNumber
                            controls={false}
                            maxLength={19}
                            addonAfter="円"
                            min={0}
                            onChange={() => {
                              handler.handleCalculateChange(
                                handler.listReceivedMoneyOffsetLocal
                              );
                            }}
                            formatter={(value) =>
                              value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                            parser={(value: any) =>
                              value.replace(/\$\s?|(,*)/g, "")
                            }
                          />
                        </Form.Item>
                        <img
                          src={Minus_cm0063}
                          alt=""
                          style={{
                            width: 22,
                            height: 22,
                            objectFit: "cover",
                            marginLeft: 8,
                            cursor: "pointer",
                          }}
                          onClick={() => handler.handleRemoveRowLocal(item.key)}
                        />
                      </div>
                    </div>
                  );
                })}
                {handler?.listReceivedMoneyOffsetLocal?.length === 0 && (
                  <div className="empty-data">
                    <img src={EmptyDataSVG} alt="" />
                  </div>
                )}
              </Form>
            </div>
            <div className="table-row table-row-blue">
              <div className="cell cell-order">合計金額</div>
              <div className="cell cell-item"></div>
              <div className="cell cell-taxCategoryComsumption"></div>
              <div className="cell cell-offsetAmount">{`${helpers.formatCurrency(
                handler.totalCost
              )}円`}</div>
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default CM0063UI;
