import MainUI from "./presenter/ui/UI";
import "../../../assets/styles/components/modal-approve-view.css"
import Handler from "./handler/Handler";

export interface propsApproveViewModal {
    isViewOnly?: boolean
    callbackOk?: (value: any) => Promise<any>;
    callbackCancel?: () => Promise<any>;

}
const ApproveViewModal = (props: propsApproveViewModal) => {
    const handler = Handler(props)
    return <MainUI handler={handler} isViewOnly={props.isViewOnly} />
}
export default ApproveViewModal 