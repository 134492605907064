import React from 'react';
import moment from 'moment';
import COMMON, { TAX_CODE } from '../../../../../common/constants/COMMON';
import helpers from '../../../../../common/helpers/common';
import { Empty } from 'antd';
import MESSAGE from '../../../../../common/constants/MESSAGE';
import { OffsetCost } from '../../entity/Entity';
import ConstructionTableComponent from './ConstructionTable';
import IconDown from '../../../../../assets/icons/icon-down-small.svg';
import TooltipText from '../../../../../common/components/tooltip-text/TooltipText';

type props = {
    projectList: any[];
    setProjectExpandKeys: (key: any) => void;
    checkProjectKeyExpand: (key: any) => boolean;
};

const ProjectRowComponent = ({
    projectList,
    setProjectExpandKeys,
    checkProjectKeyExpand,
}: props) => {
    return (
        <React.Fragment>
            {projectList.length === 0 ? (
                <tr>
                    <td colSpan={10}>
                        <Empty
                            style={{ width: '100%' }}
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={MESSAGE.MESSAGE_DATA_NOT_FOUND}
                        />
                    </td>
                </tr>
            ) : (
                projectList.map((element, index) => (
                    <React.Fragment key={`table${element.id}`}>
                        <tr
                            key={`table${element.id}`}
                            className="bg-fa button-style"
                            onClick={() => setProjectExpandKeys(element.id)}
                        >
                            <td colSpan={2}>{index + 1}</td>
                            <td>
                                <div style={{ width: 400 }}>
                                    <TooltipText content={element?.project?.name ?? ''} />
                                </div>
                            </td>
                            <td></td>
                            <td></td>
                            <td>{`${helpers.formatNumberMoney(element.actualCost)}円`}</td>
                            <td>{`${helpers.formatNumberMoney(element.consumptionTax)}円`}</td>
                            <td>{`${helpers.formatNumberMoney(
                                element.paymentAmountIncludingTax,
                            )}円`}</td>
                            <td>
                                <img
                                    alt=""
                                    src={IconDown}
                                    className={`cm024-icon ${
                                        !checkProjectKeyExpand(element.id)
                                            ? ''
                                            : 'cm024-icon-active'
                                    }`}
                                />
                            </td>
                        </tr>
                        {checkProjectKeyExpand(element.id) ? (
                            <ConstructionTableComponent
                                constructionList={element.constructionList}
                            />
                        ) : (
                            <></>
                        )}
                    </React.Fragment>
                ))
            )}
        </React.Fragment>
    );
};

export default ProjectRowComponent;
