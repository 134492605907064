import axiosConfig from '../../../../../config/axiosConfig';

const CM0062Api = () => {
    const getOffset = async (params: any): Promise<any> => {
        const url = '/re-summary/submitted/received-money/company/project/offset';
        return axiosConfig.get(url, { params });
    };

    const getOffsetProject = async (params: any): Promise<any> => {
        const url = '/re-summary/submitted/received-money/project/offset';
        return axiosConfig.get(url, { params });
    };

    return {
        getOffset,
        getOffsetProject,
    };
};

export default CM0062Api;
