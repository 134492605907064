import React, { useEffect } from "react";
import { HandlerImpl } from "../handler/HandlerImpl";
import { RootState } from "../../../../../store";
import { useLocation, useNavigate } from "react-router";
import { Badge, Button, Drawer, Spin } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import Setting from "../../../../../assets/icons/setting.svg";
import Close from "../../../../../assets/icons/close.svg";
import {
  CountNotificationCategory,
  NotificationAllResults,
  NotificationCategory,
} from "../../entity/Entity";
import BellPM009 from "../../../../../assets/icons/bell-pm009.svg";
import TooltipText from "../../../tooltip-text/TooltipText";
import { useSelector } from "react-redux";
import helpers from "../../../../helpers/common";
import NotificationBing from "../../../../../assets/icons/notification-bing.svg";
import { hasSomeElements } from "../../../../../router/AuthorizationRouter";
import { PAGE_AUTHORIZATION } from "../../../../constants/AUTHORIZATION";
import { PLAN_TYPE } from "../../../../constants/COMMON";
import XIconModal from "../../../../../assets/icons/x-icon-modal.svg";
import BackIconLight from "../../../../../assets/icons/back-icon-light.svg";
import { isRefreshPM002 } from "../../../../../pages/PM/PM002/presenter/slice/Slice";

interface props {
  handler: HandlerImpl;
}

export const MainUI = ({ handler }: props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isLoading = useSelector(
    (state: RootState) => state.notificationWorkflow.isLoading
  );
  const chosenCategoryWF = useSelector(
    (state: RootState) => state.notificationWorkflow.chosenCategoryWF
  );
  const notificationCategories = useSelector(
    (state: RootState) => state.notificationWorkflow.workflowCategory
  );
  const permissions = useSelector(
    (state: RootState) => state.auth.authData?.permission ?? []
  );
  const isOrderBasic =
    useSelector((state: RootState) => state.auth.authData?.order?.code) ===
    PLAN_TYPE.PLAN_BASIC;
  const refreshNotification = useSelector(
    (state: RootState) => state.common.refreshNotification
  );
  const refreshNoti = useSelector(
    (state: RootState) => state.pm009.refreshNoti
  );

  const isRefresh002 = useSelector(isRefreshPM002);
  useEffect(() => {
    handler.getNotificationCategory();
  }, []);

  useEffect(() => {
    if (chosenCategoryWF && handler.localVisible) {
      handler.getNotificationAll({
        categoryId: chosenCategoryWF.id,
        page: handler.page,
      });
    }
  }, [chosenCategoryWF, handler.page, handler.localVisible]);

  useEffect(() => {
    if (notificationCategories) {
      handler.getNotificationUnreadCount(notificationCategories);
    }
  }, [
    notificationCategories,
    handler.isRefresh,
    refreshNotification,
    refreshNoti,
    isRefresh002,
  ]);

  return (
    <>
      <div className="center-item">
        <Badge count={handler.unreadCount} className="avatar-badge-button">
          <Button
            type="link"
            style={{ border: "none" }}
            icon={<img alt="" style={{ width: "24px" }} src={BellPM009} />}
            onClick={(e: any) => {
              e.stopPropagation();
              handler.openPM009_1();
            }}
          ></Button>
        </Badge>
      </div>

      <Drawer
        title={
          chosenCategoryWF ? (
            <div className="pm009-header">
              {isOrderBasic ? (
                <div onClick={() => handler.onCloseCategory()}>
                  <img
                    alt=""
                    src={BackIconLight}
                    style={{ height: 24, width: 24 }}
                  />
                </div>
              ) : (
                <></>
              )}
              <div className="pm009-header-title">
                <div
                  className="pm009-header-title-h2"
                  style={{ marginBottom: 8 }}
                >
                  通知
                </div>
                <span>{chosenCategoryWF.description}</span>
              </div>
            </div>
          ) : (
            <div className="pm009-header">
              <div className="pm009-header-title">
                <div
                  className="pm009-header-title-h2"
                  style={{ marginBottom: 30 }}
                >
                  通知
                </div>
              </div>
            </div>
          )
        }
        placement="right"
        onClose={handler.closePM009_1}
        visible={handler.localVisible}
        closeIcon={
          <img alt="" src={XIconModal} style={{ height: 16, width: 16 }} />
        }
        className="pm009"
        footer={
          hasSomeElements(PAGE_AUTHORIZATION.SN01, permissions) &&
          isOrderBasic ? (
            <div className="pm009-footer">
              <h4>設定</h4>
              <img alt="" src={Setting} onClick={() => navigate(`/app-sn01`)} />
            </div>
          ) : null
        }
      >
        <div style={{ width: "100%", overflow: "hidden", height: "100%" }}>
          <Spin spinning={isLoading}>
            {!chosenCategoryWF ? (
              <div className="pm009-body-view">
                {handler.countNotiCategories?.map(
                  (categoryNoti: CountNotificationCategory) => {
                    return (
                      <div
                        key={categoryNoti.code}
                        className="pm009-item"
                        onClick={() => handler.onChooseCategory(categoryNoti)}
                      >
                        <Badge count={categoryNoti.count} offset={[12, 0]}>
                          <div className="pm009-item-text">
                            {categoryNoti.description}
                          </div>
                        </Badge>
                      </div>
                    );
                  }
                )}
              </div>
            ) : (
              <div className="pm009-body-detail">
                {handler.notifications.length ? (
                  <InfiniteScroll
                    dataLength={handler.notifications.length}
                    next={handler.onScrollData}
                    hasMore={handler.notifications.length % 10 === 0}
                    height={"100%"}
                    loader={null}
                  >
                    {handler.notifications.map(
                      (notification: NotificationAllResults) => {
                        return (
                          <div
                            key={notification.id}
                            className={`pm009-item-detail ${
                              !notification.isRead && "pm009-item-detail-active"
                            }`}
                            onClick={() => {
                              if (!notification.isRead)
                                handler.readNotification(notification);
                            }}
                          >
                            <div className="pm009-item-left">
                              <div> {notification.title}</div>
                              <div className="pm009-item-weight-400">
                                {notification.body}
                              </div>
                              <div
                                className="pm009-item-weight-400"
                                style={{ float: "right" }}
                              >
                                {helpers.convertTime(notification.createdAt)}
                              </div>
                            </div>
                            <div
                              className="pm009-item-close"
                              onClick={(e) => {
                                e.stopPropagation();
                                handler.deleteNotification({
                                  ...notification,
                                  page: handler.page,
                                  category: chosenCategoryWF?.id,
                                });
                              }}
                            >
                              <img alt="" src={Close} />
                            </div>
                          </div>
                        );
                      }
                    )}
                  </InfiniteScroll>
                ) : (
                  <div className="notification-no-data">
                    お知らせはありません。
                  </div>
                )}
              </div>
            )}
          </Spin>
        </div>
      </Drawer>
    </>
  );
};
