import { CM0039SliceType } from './../type/Presenter';
import { createSlice } from '@reduxjs/toolkit';

const initialState: CM0039SliceType = {
    isRefresh: false,
};

export const cm0039Slice = createSlice({
    name: 'cm0039',
    initialState,
    reducers: {
        resetCM0039: () => initialState,
        refreshCM0039: (state) => {
            state.isRefresh = !state.isRefresh;
        },
    },
});

export const { resetCM0039, refreshCM0039 } = cm0039Slice.actions;

export default cm0039Slice.reducer;
