import { useSearchParams } from "react-router-dom";
import PaginationComponentOM from "../../../../../common/components/pagination/PaginationComponentOM";
interface Props {
  total: number;
  onPageChange: (value: number) => void;
  onSizeChange: (value: number) => void;
}
const PaginationComponent = ({ total, onPageChange, onSizeChange }: Props) => {
  const [searchParams] = useSearchParams();

  const page = searchParams.get("page");
  const size = searchParams.get("size");
  return total !== 0 ? (
    <div style={{ paddingRight: 24, paddingLeft: 24}}>
      <PaginationComponentOM
        total={total}
        page={parseInt(page || "1")}
        size={parseInt(size || "10")}
        setPage={onPageChange}
        setSize={onSizeChange}
        sizeLabelPage="件"
        sizeLabelSize=" ページ"
        rangeLabel="件"
      />{" "}
    </div>
  ) : (
    <></>
  );
};

export default PaginationComponent;
