import { OM002Hanlder } from "../handler/HandlerImpl"
import OM002GroupListComponent from "./GroupList"
import OM002MemberTable from "./MemberTable"

type Props = {
    handler: OM002Hanlder
}

const OM002GroupMemberData = ({ handler }: Props) => {
    return (
        <div className="group-member-container" style={{ display: "flex" }}>
            <div style={{
                width: 242,
            }}>
                <OM002GroupListComponent handler={handler} />
            </div>
            <OM002MemberTable handlerTable={handler} handlerPagination={handler} />
        </div>
    )
}

export default OM002GroupMemberData
