import { Form, Input } from "antd";
import SearchSvg from "../../../../../../../assets/icons/search-icon.svg";
import CollapsedFalseSVG from "../../../../../../../assets/icons/collapsed-false.svg";
import CollapsedTrueSVG from "../../../../../../../assets/icons/collapsed-true.svg";

interface Props {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onCollapse: (value: boolean) => void;
  isCollapsed: boolean;
}

const SearchBarComponent = ({ onChange, onCollapse, isCollapsed }: Props) => {
  return isCollapsed ? (
    <main className="center-item" style={{ marginBottom: 27 }}>
      <div
        className="collapsed-button-open"
        onClick={() => onCollapse(!isCollapsed)}
      >
        <img alt="" src={CollapsedFalseSVG} />
      </div>
    </main>
  ) : (
    <main>
      <div
        className="searchbar-container"
        style={{ marginBottom: 3, marginLeft: 16 }}
      >
        <Form.Item name="text" style={{ margin: 0, padding: 0 }}>
          <Input
            style={{ width: 186 }}
            size="large"
            prefix={<img alt="" src={SearchSvg} />}
            placeholder="案件名を入力"
            className="search-bar-om001 "
            onChange={onChange}
            autoComplete="off"
          />
        </Form.Item>
        <div
          className="collapsed-button-close"
          onClick={() => onCollapse(!isCollapsed)}
        >
          {isCollapsed ? (
            <img alt="" src={CollapsedFalseSVG} />
          ) : (
            <img alt="" src={CollapsedTrueSVG} />
          )}
        </div>
      </div>
      <div className="group-list-title w-100-percent">案件一覧</div>
    </main>
  );
};
export default SearchBarComponent;
