import ErrorNotification from '../../../../common/components/notification/ErrorNotification';
import { NOTIFICATION_TITLE } from '../../../../common/constants/MESSAGE';
import { CM003Api } from './ServiceImpl';

const CM003Service = (cm003Api: CM003Api) => {
    const getAttendanceUserConstruction = async (params: any): Promise<any> => {
        try {
            const response = await cm003Api.getAttendanceUserConstruction({
                ...params,
            });
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return;
        }
    };

    const getSummaryReceiveMoney = async (params: any): Promise<any> => {
        try {
            const response = await cm003Api.getSummaryReceiveMoney({
                ...params,
            });
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return;
        }
    };

    const getProjectListConstruction = async (params: any): Promise<any> => {
        try {
            const response = await cm003Api.getProjectListConstruction({
                ...params,
            });
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return;
        }
    };

    const getConstructionReceivedMoney = async (params: any): Promise<any> => {
        try {
            const response = await cm003Api.getConstructionReceivedMoney({
                ...params,
            });
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return;
        }
    };

    const getReceivedMoneyOffset = async (params: any): Promise<any> => {
        try {
            const response = await cm003Api.getReceivedMoneyOffset({
                ...params,
            });
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return;
        }
    };

    const getPresignLinkS3 = async (params: any): Promise<any> => {
        try {
            const response = await cm003Api.getPresignLinkS3(params);
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            throw error;
        }
    };

    const doExportCM003 = async (params: any): Promise<any> => {
        return await cm003Api.doExportCM003(params);
    }

    return {
        getSummaryReceiveMoney,
        getAttendanceUserConstruction,
        getProjectListConstruction,
        getConstructionReceivedMoney,
        getReceivedMoneyOffset,
        getPresignLinkS3,
        doExportCM003,
    };
};

export default CM003Service;
