import { Breadcrumb } from 'antd';
import { useNavigate } from 'react-router-dom';
import React from 'react';
export interface BreadcrumbData {
    url: string;
    title: string;
    icon: any;
}
interface BreadcrumbProps {
    data: BreadcrumbData[];
    onClick?: (callback: () => void) => void;
}
const BreadcrumbComponent = ({ data, onClick }: BreadcrumbProps) => {
    const navigate = useNavigate();
    return (
        <Breadcrumb>
            {data?.map((element, index) => (
                <Breadcrumb.Item
                    key={index}
                    onClick={() =>
                        onClick
                            ? onClick(() => navigate(element.url))
                            : element.url && navigate(element.url)
                    }
                    className="breadcrumb"
                >
                    {element.icon}
                    {`${element.title}`}
                </Breadcrumb.Item>
            ))}
        </Breadcrumb>
    );
};

export default BreadcrumbComponent;
