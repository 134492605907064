
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AM016UseCase } from "../../usecase/ServiceImpl";
import { setLoading, resetAM016 } from "../slice/Slice";
import ConfirmModal from "../../../../../common/components/modal/ConfirmModal";
import MESSAGE, {
  LABEL_MESSAGE, NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";


const AM016Handler = (am016Service: AM016UseCase) => {
  // LIB FUNCTION
  const dispatch = useDispatch();

  // STATE
  const [requestAttendance, setRequestAttendance] = useState<any>({});


  // FUNCTION
  const handleGetRequestAttendance= async (requestId:any):Promise<any>=>{
    try{
      const params ={
        requestId:requestId
      }
      const res = await am016Service.getRequestAttendance(params);
      setRequestAttendance(res.results);
    }catch(error: any){
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  }


  const onCancel = () => {
    dispatch(resetAM016());
  };

  return {
    requestAttendance,
    onCancel,
    handleGetRequestAttendance
  };
};

export default AM016Handler;
