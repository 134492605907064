import COMMON from "../../../../common/constants/COMMON";
import { ProjectAllsResultsPagination } from "../../CM0029/entity/Entity";
import { WorkflowResponseData } from "../../CM008/entity/Entity";
import {
  ReSummaryCompanyProjectOutsourceCostRetrievePayload,
  ReSummaryMaterialCostProjectRetrieveResponse,
  ReSummarySubmittedMaterialCostProjectConstructionCompanyResults,
  ReSummarySubmittedMaterialCostProjectConstructionResults,
  ReSummarySubmittedMaterialCostProjectResponse,
  ReSummarySubmittedMaterialCostProjectSummaryResults,
  ReSummarySubmittedOutsourceCostCompanyProjectConstructionResults,
  ReSummarySubmittedOutsourceCostCompanyProjectResults,
  ReSummarySubmittedOutsourceCostCompanyResults,
} from "../entity/Entity";
import { APIImpl } from "./ServiceImpl";

const CM0023Service = (api: APIImpl) => {
  const getProjectAlls = async (params: {
    documentId: number;
    page?: number;
    size?: number;
    sortBy?: string;
    sortType?: string;
  }): Promise<any> => {
    try {
      const response = await api.getProjectAlls(params);
      const { results, pagination } = response.data;
      const responseData: ProjectAllsResultsPagination = {
        results,
        pagination,
      };
      return responseData;
    } catch (error) {
      throw error;
    }
  };
  const getReSummarySubmittedMaterialCostProjectConstruction = async (params: {
    documentId: number;
    projectId: number;
  }): Promise<ReSummarySubmittedMaterialCostProjectConstructionResults[]> => {
    try {
      const sizePage = COMMON.DEFAULT_SIZE;
      const response =
        await api.getReSummarySubmittedMaterialCostProjectConstruction({
          ...params,
          page: 1,
          size: sizePage,
        });
      let results: ReSummarySubmittedMaterialCostProjectConstructionResults[] =
        response.data?.results ?? [];

      const numberPages = response.data?.pagination?.numPages ?? 0;
      for (let i = 2; i <= numberPages; i++) {
        const response =
          await api.getReSummarySubmittedMaterialCostProjectConstruction({
            ...params,
            page: i,
            size: sizePage,
          });
        results = results.concat(response.data?.results ?? []);
      }

      return results ?? [];
    } catch (error: any) {
      throw error;
    }
  };
  const getReSummarySubmittedMaterialCostProjectConstructionCompany =
    async (params: {
      documentId: number;
      constructionId: number;
    }): Promise<
      ReSummarySubmittedMaterialCostProjectConstructionCompanyResults[]
    > => {
      try {
        const sizePage = COMMON.DEFAULT_SIZE;
        const response =
          await api.getReSummarySubmittedMaterialCostProjectConstructionCompany(
            {
              ...params,
              page: 1,
              size: sizePage,
            }
          );
        let results: ReSummarySubmittedMaterialCostProjectConstructionCompanyResults[] =
          response.data?.results ?? [];

        const numberPages = response.data?.pagination?.numPages ?? 0;
        for (let i = 2; i <= numberPages; i++) {
          const response =
            await api.getReSummarySubmittedMaterialCostProjectConstructionCompany(
              {
                ...params,
                page: i,
                size: sizePage,
              }
            );
          results = results.concat(response.data?.results ?? []);
        }

        return results ?? [];
      } catch (error: any) {
        throw error;
      }
    };
  const getReSummarySubmittedMaterialCostProjectSummary = async (params: {
    documentId: number;
  }): Promise<ReSummarySubmittedMaterialCostProjectSummaryResults> => {
    try {
      const response =
        await api.getReSummarySubmittedMaterialCostProjectSummary(params);
      return response.data?.results;
    } catch (error: any) {
      throw error;
    }
  };
  const putReSummaryMaterialCostProjectRetrieve = async (
    data: ReSummaryCompanyProjectOutsourceCostRetrievePayload
  ): Promise<ReSummaryMaterialCostProjectRetrieveResponse> => {
    try {
      const response = await api.putReSummaryMaterialCostProjectRetrieve(data);
      return response.data;
    } catch (error: any) {
      throw error;
    }
  };

  const getWorkflowSummaryMaterialProjectApprovalProcess = async (params: {
    documentId: number;
  }): Promise<WorkflowResponseData> => {
    try {
      const response =
        await api.getWorkflowSummaryMaterialProjectApprovalProcess(params);
      return response.data;
    } catch (error: any) {
      throw error;
    }
  };
  return {
    getProjectAlls,
    getReSummarySubmittedMaterialCostProjectConstruction,
    getReSummarySubmittedMaterialCostProjectConstructionCompany,
    getReSummarySubmittedMaterialCostProjectSummary,
    putReSummaryMaterialCostProjectRetrieve,
    getWorkflowSummaryMaterialProjectApprovalProcess,
  };
};

export default CM0023Service;
