import IC3Api from './infrastructure/api/Api';
import IC3Handler from './presenter/handler/Handler';
import IC3UI from './presenter/ui/UI';
import IC3Service from './usecase/Service';

const IC3Component = () => {
    const ic3Api = IC3Api();
    const ic3Service = IC3Service(ic3Api);
    const ic3Handler = IC3Handler(ic3Service);
    return <IC3UI handler={ic3Handler} />;
};

export default IC3Component;
