import { useDispatch } from "react-redux";
import { AttendanceServiceImpl } from "./../../usecase/ServiceImpl";
import { useState } from "react";
import { openModalStreetMap } from "../../../../../common/components/open-street-map/Slice";

const AttendanceHandler = (service: AttendanceServiceImpl) => {
  const dispatch = useDispatch();

  const [attendance, setAttendance] = useState<any>();
  const [dataSource, setDataSource] = useState<any[]>([]);

  const getAttendanceConstructionMyMemberTimeSheet = async (params: {
    constructionId: number;
    userId: number;
    from: string;
    to: string;
  }): Promise<any> => {
    const { results } =
      await service.getAttendanceConstructionMyMemberTimeSheet(params);
    setAttendance(results);
  };
  const openStreetMap = (lat: any, lng: any) => {
    dispatch(
      openModalStreetMap({
        lat: lat,
        lng: lng,
      })
    );
  };
  return {
    attendance,
    dataSource,
    setAttendance,
    setDataSource,
    getAttendanceConstructionMyMemberTimeSheet,
    openStreetMap,
  };
};

export default AttendanceHandler;
