export const SCREEN_ID = "3";
export const MESSAGE_REQUIRED = "";
export const REQUIRED_INPUT = false;
export const DEFAULT_PERCENT = "";
export const PARAMS = {
  VERSION_TYPE: "versionType",
  VERSION_ID: "versionId",
};

export const VERSION_UPDATE = "update";
export const VERSION_APPROVAL = "approval";
