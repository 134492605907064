import React from 'react';
import helpers from '../../../../../common/helpers/common';
import { Handler } from '../handler/HandlerImpl';
import COMMON, { ORDER_STATUS } from '../../../../../common/constants/COMMON';

interface props {
    handler: Handler;
}

const TableComponent = ({ handler }: props) => {
    let totalOrderCost =
        handler.dataOrderCompany?.orderSubscription?.reduce((total: number, item: any) => {
            return (total += item?.orderCost);
        }, 0) ?? 0;
    let totalDiscount =
        handler.dataOrderCompany?.orderSubscription?.reduce((total: number, item: any) => {
            return (total += item?.discount);
        }, 0) ?? 0;

    return (
        <div className="ic3-table">
            <table>
                <thead>
                    <tr>
                        <th colSpan={2} style={{ minWidth: 378 }}>
                            プラン・オプション機能
                        </th>
                        <th style={{ minWidth: 378 }}>有効期間</th>
                        <th style={{ minWidth: 378 }}>ステータス</th>
                        <th style={{ minWidth: 378 }}>月額料金</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="bg-fa">
                        <td colSpan={2}>
                            {handler.dataOrderCompany?.plan?.subCategory?.description}
                        </td>
                        <td>
                            {' '}
                            {helpers.formatDatePresenter(
                                handler.dataOrderCompany?.startDate,
                                COMMON.FORMAT_DATE_CM,
                            )}{' '}
                            ~
                            {helpers.formatDatePresenter(
                                handler.dataOrderCompany?.endDate,
                                COMMON.FORMAT_DATE_CM,
                            )}
                        </td>
                        <td
                            className={
                                handler.dataOrderCompany?.statusOrder?.code ===
                                ORDER_STATUS.ACTIVE_ORDER
                                    ? 'txt-table-valid'
                                    : 'txt-table-invalid'
                            }
                        >
                            {' '}
                            {handler.dataOrderCompany?.statusOrder?.name || '-'}
                        </td>
                        <td>
                            {`${helpers.formatNumberMoney(
                                handler.dataOrderCompany?.orderCost -
                                    handler.dataOrderCompany?.discount,
                            )}円`}
                        </td>
                    </tr>
                    {handler.dataOrderCompany?.orderSubscription?.map(
                        (record: any, index: number) => (
                            <tr key={record.id}>
                                <td>{index + 1}</td>
                                <td>
                                    <div style={{ width: '100%' }}>{record.name}</div>
                                </td>
                                <td>
                                    {' '}
                                    {helpers.formatDatePresenter(
                                        record?.startDate,
                                        COMMON.FORMAT_DATE_CM,
                                    )}{' '}
                                    ~
                                    {helpers.formatDatePresenter(
                                        record?.endDate,
                                        COMMON.FORMAT_DATE_CM,
                                    )}
                                </td>
                                <td
                                    className={
                                        record?.status?.code === ORDER_STATUS.ACTIVE_ORDER
                                            ? 'txt-table-valid'
                                            : 'txt-table-invalid'
                                    }
                                >
                                    {record?.status?.name ?? ''}
                                </td>
                                <td>
                                    {helpers.formatNumberMoney(
                                        record?.orderCost - record?.discount,
                                    )}
                                    円{' '}
                                </td>
                            </tr>
                        ),
                    )}
                </tbody>
                <tfoot>
                    <tr className="table-foot">
                        <td colSpan={2}>合計</td>
                        <td></td>
                        <td></td>
                        <td>
                            {helpers.formatNumberMoney(
                                totalOrderCost -
                                    totalDiscount +
                                    (handler.dataOrderCompany?.orderCost -
                                        handler.dataOrderCompany?.discount),
                            )}
                            円
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};

export default TableComponent;
