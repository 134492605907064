import { Calendar, DatePicker, Dropdown, Menu } from "antd";
import arrowDownIcon from "../../../../../assets/images/arrowDown.png";
import locale from "antd/es/date-picker/locale/ja_JP";
import AM010CalendarUnit from "./AM010CalendarUnit";

// Icon
import calendarIcon from "../../../../../assets/images/calendarIcon.svg";
import workBagIcon from "../../../../../assets/images/workBagIcon.svg";
import loginIcon from "../../../../../assets/images/loginIcon.svg";
import arrowLeftIcon from "../../../../../assets/images/arrowLeft.svg";
import arrowRightIcon from "../../../../../assets/images/arrowRight.svg";
// Common
import COMMON from "../../../../../common/constants/COMMON";
import { AM010Handler } from "../handler/HandlerImpl";
import {
  DROPDOWN_KEY_ONE,
  DROPDOWN_KEY_THREE,
  DROPDOWN_KEY_TWO,
} from "../handler/Handler";
import { STATUS_REQUEST } from "../../../../../common/constants/STATUS";
import { COLOR_STATUS_REQUEST } from "../../../../../common/constants/COLOR";
import moment from "moment";

type props = {
  handler: AM010Handler;
};
export default function AM010Calendar({ handler }: props) {
  const dateCellRender = (date: moment.Moment) => {
    const isFirstDay = date.format("DD") === "01";
    const isSameDay = date.isSame(moment(), "day");
    const listData = handler.getListData(date);
    return (
      <div
        className="date-cell-render date-cell-render-border"
        style={{
          backgroundColor: isSameDay ? "#f3f0ff" : "#fff",
        }}
      >
        <section className="date-cell-render-container">
          <div className={`pt-pb-8 date ${isSameDay ? "is-current" : ""}`}>
            {moment(date.toLocaleString()).format(isFirstDay ? "MM/DD" : "DD")}
          </div>
          <div className="content">
            {listData.map((item: any, index: number) => (
              <div
                key={index}
                style={{ paddingBottom: index === listData.length - 1 ? 0 : 8 }}
              >
                <AM010CalendarUnit
                  handler={handler}
                  key={index}
                  backgroundColor={
                    item.status === STATUS_REQUEST.REQUEST_WAITING_FOR_APPROVAL
                      ? COLOR_STATUS_REQUEST.WAITING_APPROVE
                      : item.status === STATUS_REQUEST.REQUEST_APPROVED
                      ? COLOR_STATUS_REQUEST.APPROVED
                      : item.status === STATUS_REQUEST.REQUEST_REJECT
                      ? COLOR_STATUS_REQUEST.REJECT
                      : COLOR_STATUS_REQUEST.NORMAL
                  }
                  borderLeftColor={
                    item.status === STATUS_REQUEST.REQUEST_WAITING_FOR_APPROVAL
                      ? COLOR_STATUS_REQUEST.WAITING_APPROVE_BORDER
                      : item.status === STATUS_REQUEST.REQUEST_APPROVED
                      ? COLOR_STATUS_REQUEST.APPROVED_BORDER
                      : item.status === STATUS_REQUEST.REQUEST_REJECT
                      ? COLOR_STATUS_REQUEST.REJECT_BORDER
                      : COLOR_STATUS_REQUEST.NORMAL_BORDER
                  }
                  dataRequest={item}
                />
              </div>
            ))}
          </div>
        </section>
      </div>
    );
  };
  const menu = (
    <Menu
      className="am010__dropdown-menu"
      onClick={(e: any) => handler.chooseDropdown(e)}
    >
      <Menu.Item className="am010__dropdown-menu-item" key={DROPDOWN_KEY_ONE}>
        <img src={calendarIcon} alt="calendar" />
        打刻申請
      </Menu.Item>
      <Menu.Item className="am010__dropdown-menu-item" key={DROPDOWN_KEY_TWO}>
        <img src={workBagIcon} alt="calendar" />
        休暇申請
      </Menu.Item>
      <Menu.Item className="am010__dropdown-menu-item" key={DROPDOWN_KEY_THREE}>
        <img src={loginIcon} alt="calendar" />
        遅刻・早退申請
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="am010__calendar-container">
      <h2>マイタイムレコーディング</h2>
      <div className="am010__calendar">
        <div className="am010__calendar-header">
          <div className="am010__calendar-status">
            <div className="am010__calendar-item">
              <div className="am010__calendar-circle1"></div>
              <div className="am010__calendar-status-name">出勤・退勤</div>
            </div>
            <div className="am010__calendar-item">
              <div className="am010__calendar-circle2"></div>
              <div className="am010__calendar-status-name">承認待ち</div>
            </div>
            <div className="am010__calendar-item">
              <div className="am010__calendar-circle3"></div>
              <div className="am010__calendar-status-name">承認済み</div>
            </div>
            <div className="am010__calendar-item">
              <div className="am010__calendar-circle4"></div>
              <div className="am010__calendar-status-name">否認</div>
            </div>
          </div>
          <div
            className="am010__calendar-filter"
            id="testPosition"
            style={{ position: "relative" }}
          >
            <Dropdown
              overlay={menu}
              placement="bottomRight"
              getPopupContainer={(e: any) =>
                e ? document.getElementById("testPosition") : e.parentNode
              }
            >
              <div className="am010__calendar-dropdown">
                <span>申請作成</span>
                <div style={{ marginTop: 2 }}>
                  <img src={arrowDownIcon} alt="" />
                </div>
              </div>
            </Dropdown>
            <div className="am010__calendar-month">
              <img
                src={arrowLeftIcon}
                alt=""
                onClick={() => handler.subtractMonth()}
              />
              <DatePicker
                key={`${handler.month}`}
                value={handler.month}
                className="am010__picker-month"
                allowClear={false}
                picker="month"
                format={COMMON.FORMAT_DATE_JA_MONTH}
                suffixIcon={<img src={calendarIcon} alt="calendar" />}
                onChange={(e: any) => handler.setMonthChoose(e)}
                getPopupContainer={(e: any) =>
                  e ? document.getElementById("testPosition") : e.parentNode
                }
                locale={locale}
              />
              <img
                src={arrowRightIcon}
                alt=""
                onClick={() => handler.addMonth()}
              />
            </div>
          </div>
        </div>
        <div className="am010__calendar-content">
          <Calendar
            locale={locale}
            mode="month"
            dateFullCellRender={dateCellRender}
            value={moment(handler.month)}
            onSelect={(e: any) => {
              if (
                moment(e).format(COMMON.FORMAT_YEAR_MONTH) !==
                moment(handler.month).format(COMMON.FORMAT_YEAR_MONTH)
              )
                handler.setMonthChoose(e);
            }}
          />
        </div>
      </div>
    </div>
  );
}
