import { DEFAULT_PAGINATION_RESULT } from "./../../../../common/constants/COMMON";
import ErrorNotification from "../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../common/constants/MESSAGE";
import { PaginationResult } from "../../../../common/entity/Entity";

import { FilterAPIImpl } from "./ServiceImpl";
import { ConstructionType } from "../entity/Entity";
const FilterService = (api: FilterAPIImpl) => {
  const getProjectAlls = async (params: {
    keyword?: string;
    page: string;
    size: string;
    sortBy?: string;
    sortType?: string;
  }): Promise<PaginationResult> => {
    try {
      const response = await api.getProjectAlls(params);
      return response.data || DEFAULT_PAGINATION_RESULT;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return DEFAULT_PAGINATION_RESULT;
    }
  };
  const getConstructionListByProject = async (params: {
    projectId: number;
  }): Promise<ConstructionType[]> => {
    try {
      const response = await api.getConstructionListByProject(params);
      return response?.data?.results ?? [];
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return [];
    }
  };

  return {
    getProjectAlls,
    getConstructionListByProject,
  };
};

export default FilterService;
