import React, { useMemo } from "react";
import { CM0032Handler } from "../handler/HandlerImpl";
import IconDown from "../../../../../assets/icons/icon-collapse-cm.svg";
import IconView from "../../../../../assets/icons/eyeIconBlack.svg";
import { Empty } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import helpers from "../../../../../common/helpers/common";
import COMMON, { TAX_CODE } from "../../../../../common/constants/COMMON";
import TooltipText from "../../../../../common/components/tooltip-text/TooltipText";

interface props {
  handler: CM0032Handler;
}
const NO_DATA = "データなし";

const Table = ({ handler }: props) => {
  const loading = useSelector((state: RootState) => state.common.loading);

  const contentTable = useMemo(() => {
    if (!handler.dataTableByTrader || handler.dataTableByTrader.length === 0)
      return !loading ? (
        <tr>
          <td colSpan={13}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={NO_DATA} />
          </td>
        </tr>
      ) : null;
    const elementTable: any = [];
    handler.dataTableByTrader.forEach((element) => {
      elementTable.push(
        <React.Fragment key={element.key}>
          <tr style={{ height: 4 }}></tr>
          <tr
            style={{
              cursor: "pointer",
            }}
            className="row-level-0"
            onClick={() => handler.funcSetExpendKeyByTrader(element.key)}
          >
            <td colSpan={2}>{element.no}</td>
            <td colSpan={5} style={{ textAlign: "left", paddingLeft: 50 }}>
              <TooltipText content={element.companyName} />
            </td>
            <td>{element.taxation}</td>
            <td>{element.taxExempt} </td>
            <td>{element.depositAmount} </td>
            <td>{element.consumptionTax} </td>
            <td>{element.taxInclude} </td>
            <td style={{ padding: "15px 0" }}>
              <img
                alt=""
                src={IconDown}
                className={`cm024-icon ${
                  handler.funcCheckKeyExpand(element.key)
                    ? ""
                    : "cm024-icon-active"
                }`}
              />
            </td>
          </tr>
        </React.Fragment>
      );

      if (element.children && element.children.length > 0) {
        if (handler.funcCheckKeyExpand(element.key))
          element.children.forEach((child1: any) => {
            elementTable.push(
              <tr
                key={child1.key}
                className="bg-fa"
                onClick={() => handler.funcSetExpendKeyByTrader(child1.key)}
                style={{
                  cursor: "pointer",
                }}
              >
                <td colSpan={2}>{child1.no}</td>
                <td
                  className="border-right-none"
                  colSpan={5}
                  style={{ textAlign: "left", paddingLeft: 50 }}
                >
                  <TooltipText content={child1.projectName} />
                </td>
                <td className="border-right-none">{child1.taxation}</td>
                <td>{child1.taxExempt}</td>
                <td>{child1.depositAmount}</td>
                <td>{child1.consumptionTax}</td>
                <td>{child1.taxInclude}</td>

                <td
                  style={{
                    textAlign: "center",
                    marginTop: 13,
                    padding: "15px 0",
                  }}
                >
                  <img
                    alt=""
                    src={IconDown}
                    className={`cm0028-icon ${
                      handler.funcCheckKeyExpand(child1.key)
                        ? ""
                        : "cm0028-icon-active"
                    }`}
                    onClick={() => handler.funcSetExpendKeyByTrader(child1.key)}
                  />
                </td>
              </tr>
            );
            if (
              handler.funcCheckKeyExpand(element.key) &&
              handler.funcCheckKeyExpand(child1.key)
            )
              elementTable.push(
                <tr key={`table${child1.key}`} className="bg-fa">
                  <td colSpan={2}></td>
                  <td colSpan={1} style={{ minWidth: 140 }}>
                    工事名
                  </td>
                  <td colSpan={2} style={{ minWidth: 200 }}>
                    対象期間
                  </td>
                  <td colSpan={2} style={{ minWidth: 140 }}>
                    入金日
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              );
            if (child1.children && child1.children.length > 0) {
              child1.children.forEach((child2: any) => {
                if (
                  handler.funcCheckKeyExpand(element.key) &&
                  handler.funcCheckKeyExpand(child1.key)
                )
                  elementTable.push(
                    <tr key={child2.key}>
                      <td style={{ width: 50 }}></td>
                      <td style={{ width: 50 }}>{child2.no}</td>
                      <td>
                        <TooltipText content={child2.constructionDescription} />
                      </td>
                      <td colSpan={3}>{`${helpers.formatDatePresenter(
                        child2?.startDate,
                        COMMON.FORMAT_DATE_CM
                      )}～${helpers.formatDatePresenter(
                        child2?.endDate,
                        COMMON.FORMAT_DATE_CM
                      )}`}</td>
                      <td>
                        {helpers.formatDatePresenter(
                          child2?.receiveAt,
                          COMMON.FORMAT_DATE_CM
                        )}
                      </td>

                      <td>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <span style={{ whiteSpace: "nowrap" }}>
                            {child2.taxation}
                          </span>
                          &nbsp;
                          <img
                            className="icon-view"
                            alt=""
                            src={IconView}
                            onClick={() =>
                              handler.handleViewDetail(child2.id, TAX_CODE.TAX)
                            }
                          />
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <span style={{ whiteSpace: "nowrap" }}>
                            {child2.taxExempt}
                          </span>
                          &nbsp;
                          <img
                            className="icon-view"
                            alt=""
                            src={IconView}
                            onClick={() =>
                              handler.handleViewDetail(
                                child2.id,
                                TAX_CODE.TAX_FREE
                              )
                            }
                          />
                        </div>
                      </td>
                      <td>{child2.depositAmount} </td>
                      <td>{child2.consumptionTax} </td>
                      <td>{child2.taxInclude} </td>
                      <td></td>
                    </tr>
                  );
              });
            } else {
              !loading &&
                elementTable.push(
                  <tr
                    key={`table${child1.key}nodata`}
                    style={{
                      display:
                        handler.funcCheckKeyExpand(element.key) &&
                        handler.funcCheckKeyExpand(child1.key)
                          ? undefined
                          : "none",
                    }}
                  >
                    <td colSpan={13}>
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={NO_DATA}
                      />
                    </td>
                  </tr>
                );
            }
          });
      } else {
        !loading &&
          elementTable.push(
            <tr
              key={`${element.key}nodata`}
              style={{
                display: handler.funcCheckKeyExpand(element.key)
                  ? undefined
                  : "none",
              }}
            >
              <td colSpan={13}>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={NO_DATA}
                />
              </td>
            </tr>
          );
      }
    });
    return elementTable;
  }, [handler.dataTableByTrader, handler.expandKey, loading]);

  React.useEffect(() => {
    const tableScroll = document.querySelector("#table-cm0032-company-scroll");
    if (
      tableScroll &&
      !handler.openFilter &&
      (helpers.getFilterTimeMonth(handler.from) !==
        handler.filterCurrent.from ||
        helpers.getFilterTimeMonthTo(handler.to) !== handler.filterCurrent.to)
    )
      tableScroll.scrollTop = 0;
  }, [handler.from, handler.to, handler.openFilter]);

  return (
    <div
      className="cm024-table"
      id="table-cm0032-company-scroll"
      onScroll={handler.handleScrollByTrader}
    >
      <table>
        <thead>
          <tr>
            <th colSpan={2} rowSpan={2} style={{ minWidth: "110px" }}>
              No
            </th>
            <th
              rowSpan={2}
              colSpan={5}
              style={{ textAlign: "left", paddingLeft: 50, minWidth: "580px" }}
            >
              発注元会社名
            </th>
            <th colSpan={2} style={{ minWidth: "185px" }}>
              相殺金額
            </th>
            <th rowSpan={2} style={{ minWidth: "170px" }}>
              入金額(税抜)
            </th>
            <th rowSpan={2} style={{ minWidth: "170px" }}>
              消費税
            </th>
            <th rowSpan={2} style={{ minWidth: "170px" }}>
              入金額(税込)
            </th>
            <th rowSpan={2} style={{ minWidth: "40px", padding: "10px" }}></th>
          </tr>
          <tr className="tr-border-first">
            <th style={{ minWidth: "120px" }}>課税</th>
            <th style={{ minWidth: "120px" }}>非課税</th>
          </tr>
        </thead>
        <tbody>{contentTable}</tbody>
        <tfoot>
          <tr className="table-foot">
            <td colSpan={7} style={{ textAlign: "left", paddingLeft: 40 }}>
              合計金額
            </td>
            <td>
              {helpers.formatCurrency(
                handler.totalReceiveCostByTrader.taxation
              )}
              円
            </td>
            <td>
              {helpers.formatCurrency(
                handler.totalReceiveCostByTrader.taxExempt
              )}
              円{" "}
            </td>
            <td>
              {helpers.formatCurrency(
                handler.totalReceiveCostByTrader.depositAmount
              )}
              円{" "}
            </td>
            <td>
              {helpers.formatCurrency(
                (handler.totalReceiveCostByTrader.depositAmount +
                  handler.totalReceiveCostByTrader.taxation) *
                  (handler.totalReceiveCostByTrader?.taxSetting ?? 1),
                true
              )}
              円
            </td>
            <td>
              {helpers.formatCurrency(
                (handler.totalReceiveCostByTrader.depositAmount +
                  handler.totalReceiveCostByTrader.taxation) *
                  (handler.totalReceiveCostByTrader?.taxSetting ?? 1) +
                  handler.totalReceiveCostByTrader.depositAmount,
                true
              )}
              円
            </td>
            <td></td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default Table;
