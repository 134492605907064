import axiosConfig from '../../../../../config/axiosConfig';

const DB001Api = () => {
    const getTurnOverAmount = async (params: any): Promise<any> => {
        const url = '/dashboard/turnover-amount';
        return axiosConfig.get(url, { params });
    };

    const getManDay = async (params: any): Promise<any> => {
        const url = '/dashboard/total-mandays';
        return axiosConfig.get(url, { params });
    };

    const getEmployeesAndOperatingCosts = async (params: any): Promise<any> => {
        const url = '/dashboard/total-cost/project/monthly';
        return axiosConfig.get(url, { params });
    };

    const getEmployeesOperatingAndOperatingCosts = async (params: any): Promise<any> => {
        const url = '/dashboard/total-cost/project/company-colab/monthly';
        return axiosConfig.get(url, { params });
    };

    const getPartnerCompany = async (params: any): Promise<any> => {
        const url = '/dashboard/project/company-colab';
        return axiosConfig.get(url, { params });
    };

    const getTransactionVolume = async (params: any): Promise<any> => {
        const url = '/dashboard/transaction-volume';
        return axiosConfig.get(url, { params });
    };

    const getBudgetUtil = async (params: any): Promise<any> => {
        const url = '/dashboard/budget-utilization-rate';
        return axiosConfig.get(url, { params });
    };

    return {
        getTurnOverAmount,
        getManDay,
        getEmployeesAndOperatingCosts,
        getEmployeesOperatingAndOperatingCosts,
        getTransactionVolume,
        getBudgetUtil,
        getPartnerCompany
    };
};

export default DB001Api;
