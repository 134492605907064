import { createSlice } from "@reduxjs/toolkit";
import { PM012SliceEntity } from "../type/Presenter";

const initialState: PM012SliceEntity = {
  loading: false,
  isOpen: false,
  currentPage: 1,
  pageSize: 0,
  project: {},
  refreshPM012: false,
  refreshPM014: false,
  allChecked: [],
  allCheckedApprove: [],
  enableChecked: false,
  keyCheckedHeader: [],
  keycheckedMember: [],
};

export const pm012Slice = createSlice({
  name: "pm012",
  initialState,
  reducers: {
    pm012ChangeOpen: (state, action) => {
      state.isOpen = action.payload;
      if (!action.payload) {
        state.refreshPM012 = !state.refreshPM012;
      }
    },
    setProject: (state, action) => {
      state.project = action.payload;
    },

    setRefreshPM014: (state, action) => {
      state.refreshPM014 = action.payload;
    },

    setAllChecked: (state, action) => {
      state.allChecked = action.payload;
    },

    setAllCheckedApprove: (state, action) => {
      state.allCheckedApprove = action.payload;
    },

    setEnableChecked: (state, action) => {
      state.enableChecked = action.payload;
    },

    setKeyCheckedHeader: (state, action) => {
      state.keyCheckedHeader = action.payload;
    },

    setKeyCheckedMember: (state, action) => {
      state.keycheckedMember = action.payload;
    },
  },
});

export const refreshPM014 = (state: any) => state.pm012.refreshPM014;
export const allChecked = (state: any) => state.pm012.allChecked;
export const allCheckedApprove = (state: any) => state.pm012.allCheckedApprove;
export const enableChecked = (state: any) => state.pm012.enableChecked;
export const project = (state: any) => state.pm012.project;
export const keyCheckedHeader = (state: any) => state.pm012.keyCheckedHeader;
export const keycheckedMember = (state: any) => state.pm012.keycheckedMember;

export const {
  pm012ChangeOpen,
  setProject,
  setRefreshPM014,
  setAllChecked,
  setEnableChecked,
  setAllCheckedApprove,
  setKeyCheckedHeader,
  setKeyCheckedMember,
} = pm012Slice.actions;

export default pm012Slice.reducer;
