import { message } from "antd";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { CompanyServiceImpl } from "../../usecase/ServiceImpl";
import { Form } from "antd";
import COMMON from "../../../../../common/constants/COMMON";
import { useDispatch } from "react-redux";
import moment from "moment";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../../common/constants/MESSAGE";

const CompanyHandler = (service: CompanyServiceImpl) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [companyList, setCompanyList] = useState<any[]>([]);
  const [totalCompany, setTotalCompany] = useState<number>(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedCompany = searchParams.get("selectedCompany");

  const getAttendanceCompanyColab = async (params: {
    keyword?: string;
    page: string;
    size: string;
    sortBy?: string;
    sortType?: "DESC" | "ASC";
  }) => {
    try {
      dispatch(setLoading(true));
      form.setFieldValue("text", params?.keyword?.trim() ?? "");
      const { results, pagination } = await service.getAttendanceCompanyColab(
        params
      );

      if (!selectedCompany && results.length > 0) {
        searchParams.set("selectedCompany", results[0].id);
        searchParams.set("selectedCompanyName", results[0].name);
        searchParams.set("selectedColabRefId", results[0]?.colabRefId ?? "0");

        setSearchParams(searchParams);
      }
      setCompanyList(results);
      setTotalCompany(pagination.count);
    } catch (error: any) {
      ErrorNotification(error.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  var myVar: any;
  const onSearch = () => {
    if (myVar) clearTimeout(myVar);
    myVar = setTimeout(function () {
      const searchText = form.getFieldValue("text");
      form.setFieldValue("text", searchText.trim());
      searchParams.set("companyKeyword", searchText.trim());
      searchParams.set("companyPage", COMMON.DEFAULT_PAGE + "");
      setSearchParams(searchParams);
    }, 1000);
  };

  const onChooseCompany = (value: any) => {
    searchParams.set("selectedCompany", value.id + "");
    searchParams.set("selectedCompanyName", value.name + "");
    searchParams.set("selectedColabRefId", value?.colabRefId ?? "0");

    setSearchParams(searchParams);
  };

  const onPageChange = (value: any) => {
    searchParams.set("companyPage", value);
    setSearchParams(searchParams);
  };

  const onCollapse = (value: boolean) => {
    searchParams.set("isCollapsed", value ? "isCollapsed" : "");
    setSearchParams(searchParams);
  };

  return {
    onChooseCompany,
    onSearch,
    onPageChange,
    onCollapse,
    getAttendanceCompanyColab,

    form,
    companyList,
    totalCompany,
  };
};

export default CompanyHandler;
