import { DEFAULT_PARAM } from './../../../../AM/AM005/presenter/handler/HandlerProject';
import { TableDataType } from './../../entity/Entity';
import { CM0034UseCase } from './../../usecase/ServiceImpl';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
    setDocumentIdCM006,
    setDocumentInfo,
    setIsVisibleCM006,
    setStatusCM006,
} from '../../../CM006/presenter/slice/Slice';
import { convertDataTable } from '../../helper';
import COMMON from '../../../../../common/constants/COMMON';

const PARAMS = {
    page: 'page1',
    size: 'size1',
    sortType: 'sortType1',
    sortBy: 'sortBy1',
};

const DEFAULT_PARAMS = {
    page: 1,
    size: 10,
};

export const FROM_DATE_FILTER = 'from1';
export const TO_DATE_FILTER = 'to1';
export const STATUS_FILTER = 'status1';
export const KEYWORD_FILTER = 'keyword1';

const CM0034Handler = (cm0034Service: CM0034UseCase) => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const page = +(searchParams.get(PARAMS.page) ?? DEFAULT_PARAMS.page);
    const size = +(searchParams.get(PARAMS.size) ?? DEFAULT_PARAMS.size);
    const sortType = searchParams.get(PARAMS.sortType);
    const sortBy = searchParams.get(PARAMS.sortBy);
    const [loading, setLoading] = useState(false);
    const [dataTable, setDataTable] = useState<TableDataType[]>([]);
    const [total, setTotal] = useState(0);
    const [visibleRejectView, setVisibleRejectView] = useState(false);
    const [rejectReason, setRejectReason] = useState('');

    const onPageChange = (page: number) => {
        searchParams.set(PARAMS.page, String(page));
        setSearchParams(searchParams);
    };

    const onSizeChange = (size: number) => {
        searchParams.set(PARAMS.page, String(COMMON.DEFAULT_PAGE));
        searchParams.set(PARAMS.size, String(size));
        setSearchParams(searchParams);
    };

    const funcCheckSortOrder = (
        columnName: string,
        sortType: string | null,
        sortBy: string | null,
    ): 'ASC' | 'DESC' | undefined => {
        if (columnName === sortBy) {
            return !sortType ? undefined : sortType === 'ASC' ? 'ASC' : 'DESC';
        }
    };
    const funcSortTable = (column: string, sortOrder: string) => {
        searchParams.set(PARAMS.sortBy, column);
        searchParams.set(PARAMS.sortType, sortOrder);
        setSearchParams(searchParams);
    };

    const handleViewDetail = (
        documentId: number,
        createdDocument: string,
        submittedDocument: string,
        status: any,
    ) => {
        dispatch(
            setDocumentInfo({
                createdDocument: createdDocument,
                submittedDocument: submittedDocument,
            }),
        );
        dispatch(setDocumentIdCM006(documentId));
        dispatch(setIsVisibleCM006(true));
        dispatch(setStatusCM006(status));
    };

    const handleGetData = async (params: any) => {
        setLoading(true);

        try {
            const response = await cm0034Service.getDocumentReceiveMoneySubmittedList({
                ...params,
            });
            setDataTable(convertDataTable(response.results));
            setTotal(response.pagination.count);
            setLoading(false);
        } catch (error) {
            throw error;
        } finally {
            setLoading(false);
        }
    };

    return {
        loading,
        dataTable,
        page,
        size,
        total,
        sortType,
        sortBy,
        rejectReason,
        visibleRejectView,

        onPageChange,
        onSizeChange,
        funcCheckSortOrder,
        funcSortTable,
        handleViewDetail,
        handleGetData,
        setVisibleRejectView,
        setRejectReason,
    };
};

export default CM0034Handler;
