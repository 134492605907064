import { createSlice } from "@reduxjs/toolkit";
import { CM0024SliceType } from "../../entity/Entity";

export const initialState: CM0024SliceType = {
  loading: false,
  isRefresh: false,
  visibleCM0026: false,
  isExportDataCM0024: null,
  isHavingData: false,
};

export const CM0024Slice = createSlice({
  name: "cm0024",
  initialState,
  reducers: {
    setLoadingCM0024: (state, action) => {
      state.loading = action.payload;
    },
    refreshCM0024: (state) => {
      state.isRefresh = !state.isRefresh;
    },
    setVisibleCM0026: (state, action) => {
      state.visibleCM0026 = action.payload;
    },
    setIsExportDataCM0024: (state, action) => {
      state.isExportDataCM0024 = action.payload;
    },
    setIsHavingData: (state, action) => {
      state.isHavingData = action.payload;
    },
  },
});

export const {
  setLoadingCM0024,
  refreshCM0024,
  setVisibleCM0026,
  setIsExportDataCM0024,
  setIsHavingData,
} = CM0024Slice.actions;

export default CM0024Slice.reducer;
