import { message, Upload, UploadProps } from "antd";

type Props = {
  children?: any;
  props: any;
};

const { Dragger } = Upload;

const UploadDragComponent = ({ children, props }: Props) => {
  return (
    <Dragger {...props} style={{ width: "100%" }}>
      {children}
    </Dragger>
  );
};

export default UploadDragComponent;
