import { CM0024APIImpl } from "./ServiceImpl";

const CM0024Service = (api: CM0024APIImpl) => {
  const getListReSummaryOutsourceCostCompany = async (
    params: any
  ): Promise<any> => {
    try {
      return await api.getListReSummaryOutsourceCostCompany(params);
    } catch (error) {
      throw error;
    }
  };

  const getListReSummaryOutsourceCostCompanyProject = async (
    params: any
  ): Promise<any> => {
    try {
      return await api.getListReSummaryOutsourceCostCompanyProject(params);
    } catch (error) {
      throw error;
    }
  };

  const getListReSummaryCompanyProjectOutsourceCost = async (
    params: any
  ): Promise<any> => {
    try {
      return await api.getListReSummaryCompanyProjectOutsourceCost(params);
    } catch (error) {
      throw error;
    }
  };

  const getSubCategory = async (): Promise<any> => {
    try {
      return await api.getSubCategory();
    } catch (error) {
      throw error;
    }
  };

  const getListMoneyDirectCostOutsourceOffset = async (
    params: any
  ): Promise<any> => {
    try {
      return await api.getListMoneyDirectCostOutsourceOffset(params);
    } catch (error) {
      throw error;
    }
  };

  const getStatus = async (params: any): Promise<any> => {
    try {
      return await api.getStatus(params);
    } catch (error) {
      throw error;
    }
  };

  const submit = async (data: any): Promise<any> => {
    try {
      return await api.submit(data);
    } catch (error) {
      throw error;
    }
  };

  const getStatusCM0026 = async (params: any): Promise<any> => {
    try {
      return await api.getStatusCM0026(params);
    } catch (error) {
      throw error;
    }
  };

  const submitCM0026 = async (data: any): Promise<any> => {
    try {
      return await api.submitCM0026(data);
    } catch (error) {
      throw error;
    }
  };

  const doExportCM0024 = async (params: any): Promise<any> => {
    return await api.doExportCM0024(params);
  };

  return {
    getListReSummaryOutsourceCostCompany,
    getListReSummaryOutsourceCostCompanyProject,
    getListReSummaryCompanyProjectOutsourceCost,
    getSubCategory,
    getListMoneyDirectCostOutsourceOffset,
    getStatus,
    submit,
    getStatusCM0026,
    submitCM0026,
    doExportCM0024,
  };
};

export default CM0024Service;
