import { Form, Input } from "antd"
import helpers from "../../../../../common/helpers/common"
import { MemberType } from "../../entity/Entity"
import React from "react";



type Props = {
    chosenMembers: MemberType[];
    name: string;
    isSubConstruction?: boolean
}

const MemberPreviewComponent = ({ chosenMembers, name, isSubConstruction = false }: Props) =>
    <>
        {chosenMembers.map((element: MemberType) =>
            <React.Fragment key={element.id}>
                <div className="pm011-member-item space-between-col" >
                    <div className="pm011-member-item-name pm014-table-4">
                        <span>{element?.fullName ?? ""}</span>
                    </div>
                    <div className="pm011-member-item-phone pm014-table-4">
                        <span>{element?.ccusId ? helpers.convertToCCUSFormat(element?.ccusId) : ""}</span>
                    </div>
                    <div className="pm011-member-item-phone pm014-table-4">
                        <span>{element?.phoneNumber ? helpers.toPhoneNumberFormat(element.phoneNumber) : ""}</span>
                    </div>
                    <div className="pm011-member-item-email pm014-table-4">
                        <span>{element?.email ?? ""}</span>
                    </div>
                </div>
            </React.Fragment>)}
        <Form.Item
            name={[name, "listUserId"]}
            hidden>
            <Input hidden />
        </Form.Item>
        <Form.Item
            name={[name, "constructionId"]}
            hidden>
            <Input hidden />
        </Form.Item>
        {
            isSubConstruction ?
                <Form.Item
                    name={[name, "subConstructionId"]}
                    hidden>
                    <Input hidden />
                </Form.Item>
                :
                <></>
        }
        <></>
    </>
export default MemberPreviewComponent