import {
  CHECKIN,
  CHECKOUT,
} from "../AM004/presenter/component/attendance/helper";
import helpers from "../../../common/helpers/common";

const convertUserTimeSheetData = (timesheet: any[]) => {
  let isWarningCheckIn: boolean = false;
  let isWarningCheckOut: boolean = false;

  // get company id
  const user = helpers.getObjectFromLocalStorage("user");
  const companyId = user.company.id;
  const companyName = user.company.name;

  // get all company in time sheet
  const otherCompanySet = new Set();
  timesheet.forEach((element) => {
    if (element.doubleCheck) {
      element.doubleCheck.forEach((ele: any) => {
        if (ele.companyId !== companyId)
          otherCompanySet.add(
            JSON.stringify({
              companyId: ele.companyId,
              companyName: ele.companyName,
            })
          );
      });
    }
  });
  const companyList: any[] = [...otherCompanySet].map((element: any) =>
    JSON.parse(element)
  );
  companyList.unshift({
    companyId: companyId,
    companyName: companyName,
  });

  let maxRow: number = 0;
  for (let i = 0; i < companyList.length; i++) {
    const companyTimeSheet = timesheet.map((element) => {
      let isDoubleCheck: boolean | null = null;
      if (element.doubleCheck) {
        // my company
        const foundDoubleCheck = element.doubleCheck.find(
          (ele: any) =>
            element?.category?.code === ele.categoryCode &&
            ele.companyId === companyList[i].companyId
        );
        if (foundDoubleCheck) isDoubleCheck = foundDoubleCheck.approve;
      }
      return { ...element, isDoubleCheck };
    });
    // return data
    const checkin: any[] = companyTimeSheet.filter(
      (element: any) => element.category.code === CHECKIN
    );
    const checkout: any[] = companyTimeSheet.filter(
      (element: any) => element.category.code === CHECKOUT
    );

    const maxRowCompany =
      checkin.length > checkout.length ? checkin.length : checkout.length;

    maxRow = maxRowCompany > maxRow ? maxRowCompany : maxRow;

    companyList[i].checkin = checkin;
    companyList[i].checkout = checkout;
  }

  const sttArray = [...Array(maxRow).keys()];
  sttArray.forEach((element) => {
    const checkCompanyCheckin: any[] = [];
    const checkCompanyCheckout: any[] = [];

    companyList.forEach((company) => {
      if (company["checkin"][element])
        checkCompanyCheckin.push(company["checkin"][element].isDoubleCheck);
      if (company["checkout"][element])
        checkCompanyCheckout.push(company["checkout"][element].isDoubleCheck);
    });

    // warning check in

    const elementNoNullWarningCheckin = checkCompanyCheckin.filter(
      (val) => val !== null
    );

    if (elementNoNullWarningCheckin.length > 1) {
      const isWarningCheckin = !elementNoNullWarningCheckin.every(
        (val, i, arr) => val === arr[0]
      );
      if (isWarningCheckin) isWarningCheckIn = true;
    }

    // warning checkout
    const elementNoNullWarningCheckout = checkCompanyCheckout.filter(
      (val) => val !== null
    );

    if (elementNoNullWarningCheckout.length > 1) {
      const isWarningCheckout = !elementNoNullWarningCheckout.every(
        (val, i, arr) => val === arr[0]
      );
      if (isWarningCheckout) isWarningCheckOut = true;
    }
  });
  return { sttArray, companyList, isWarningCheckIn, isWarningCheckOut };
};
export { convertUserTimeSheetData };
