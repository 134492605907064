import { Form, Input, InputNumber, Tooltip } from "antd";
import { memo } from "react";

import NewSvg from "../../../../../assets/icons/new.svg";
import SquarePurple from "../../../../../assets/icons/squarer-purple.svg";
import VectorMinus from "../../../../../assets/icons/vector-minus.svg";
import BuildingSVG from "../../../../../assets/icons/building-purple.svg";
import DocumentIconSvg from "../../../../../assets/icons/document-icon.svg";

import { CR001AmountRelateHandler } from "../handler/HandlerImpl";
import REGEX from "../../../../../common/constants/REGEX";
import MESSAGE from "../../../../../common/constants/MESSAGE";
import { onBlurTrimFormItem } from "../../../../../common/helpers/typeInput";
import helpers from "../../../../../common/helpers/common";
import COMMON from "../../../../../common/constants/COMMON";
import UploadEvidenceComponent from "../../../../../common/components/common-form-item-cm/upload-evidence";
import UploadFileCRComponent from "../../../../../common/components/upload-cr";
const { Item } = Form;

interface props {
  handler: CR001AmountRelateHandler;
}

const MESSAGE_REQUIRED = "";
const MAX_LENGTH_TEXT = 30;
const MIN_INPUT_NUMBER = 0;
const REQUIRED_INPUT = false;

const Material = ({ handler }: props) => {
  return (
    <div className="cr001-cost-registration">
      <div className="cr001-cost-registration-title bg-title-row padding-tb-15 border-bottom">
        <img src={NewSvg} alt="" />
        <span className="text-bold-14">材料費登録</span>
      </div>
      {handler.isEdit && (
        <div
          className="cr001-cr-item-add"
          onClick={handler.handleAddMaterialCost}
        >
          <img alt="" src={SquarePurple} />
          <span className="text-bold-14">項目追加</span>
        </div>
      )}
      <div className="cr001-cr-item bg-header-row">
        <div className="cr001-cr-item-child border-bottom border-right cr-6 padding-tb-15">
          項目名
        </div>
        <div className="cr001-cr-item-child border-bottom border-right cr-6 padding-tb-15">
          業者名
        </div>
        <div className="cr001-cr-item-child border-bottom border-right cr-6 padding-tb-15">
          見積金額（円）
        </div>
        <div className="cr001-cr-item-child border-bottom border-right cr-6 padding-tb-15">
          原価金額（円）
        </div>
        <div className="cr001-cr-item-child border-bottom border-right cr-6 padding-tb-15">
          予算金額（円）
        </div>
        <div className="cr001-cr-item-child border-bottom border-right cr-6 padding-tb-15">
          発注金額（円）
        </div>
        <div className="cr001-cr-item-child border-bottom border-right cr-6 padding-tb-15">
          ファイル
        </div>
      </div>
      {handler.materialCostList?.map((materialCost: any) => {
        return (
          <div
            className="cr001-cr-item cr001-icon-rotate color-gray"
            key={`materialCost${materialCost.key}`}
          >
            <div className="cr001-cr-item-child border-bottom border-right cr-6 padding-tb-15">
              {handler.isEdit ? (
                <div className="cr001-cr-item-bonus">
                  <img
                    alt=""
                    src={VectorMinus}
                    onClick={() =>
                      handler.handleDeleteMaterialCost(materialCost.key)
                    }
                    className="cr001-margin-icon"
                  />
                  <Item
                    name={[`materialCost${materialCost.key}`, "materialName"]}
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (value && value !== "") {
                            // CHECK INPUT VIETNAMESE
                            if (!REGEX.NOT_VIETNAM.test(value)) {
                              return Promise.reject(
                                new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                              );
                            }
                            if (value?.length > MAX_LENGTH_TEXT) {
                              return Promise.reject(new Error(""));
                            }
                            return Promise.resolve();
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input
                      maxLength={MAX_LENGTH_TEXT}
                      className="input-cr001"
                      autoComplete="off"
                      onChange={helpers.preventInputJapanese}
                      onBlur={() =>
                        onBlurTrimFormItem(handler.formAmount, [
                          `materialCost${materialCost.key}`,
                          "materialName",
                        ])
                      }
                    />
                  </Item>
                </div>
              ) : (
                <Tooltip
                  title={
                    handler.listTooltip?.includes(
                      `tooltip${materialCost.key}material`
                    )
                      ? materialCost?.materialName
                      : ""
                  }
                  getPopupContainer={(trigger: any) => trigger.parentNode}
                  overlayInnerStyle={{ color: "#fff", margin: "0 -4%" }}
                >
                  <span
                    className="cr-text-long-tooltip text-tooltip"
                    id={`tooltip${materialCost.key}material`}
                  >
                    {materialCost?.materialName}
                  </span>
                </Tooltip>
              )}
            </div>
            <div
              className={`cr001-cr-item-child border-bottom border-right cr-6 padding-tb-15 text-error ${
                handler.requiredMaterial?.includes(materialCost?.key)
                  ? "border-error"
                  : ""
              }`}
            >
              {handler.isEdit ? (
                <div className={`cr001-choose-building`}>
                  <div
                    className="cr001-choose-building-content"
                    onClick={() =>
                      handler.handleViewModalExternal(
                        `materialCost${materialCost.key}`,
                        materialCost?.materialCostCompany,
                        "materialSuppliers"
                      )
                    }
                  >
                    <Tooltip
                      title={
                        handler.listTooltip.includes(
                          `tooltip${materialCost.key}materialCompany`
                        )
                          ? materialCost?.materialCostCompany?.name
                          : ""
                      }
                    >
                      <span
                        className="text-tooltip color-purple"
                        id={`tooltip${materialCost.key}materialCompany`}
                      >
                        {materialCost?.materialCostCompany?.name
                          ? materialCost?.materialCostCompany?.name
                          : ""}
                      </span>
                    </Tooltip>
                    <img src={BuildingSVG} alt="" />
                  </div>
                </div>
              ) : (
                <Tooltip
                  title={
                    handler.listTooltip.includes(
                      `tooltip${materialCost.key}materialCompany`
                    )
                      ? materialCost?.materialCostCompany?.name
                      : ""
                  }
                  getPopupContainer={(trigger: any) => trigger.parentNode}
                  overlayInnerStyle={{ color: "#fff", margin: "0 -4%" }}
                >
                  <span
                    className="cr001-subcontract-company-name text-tooltip"
                    id={`tooltip${materialCost.key}materialCompany`}
                  >
                    {materialCost?.materialCostCompany?.name}
                  </span>
                </Tooltip>
              )}
            </div>
            <div className="cr001-cr-item-child border-bottom border-right cr-6 padding-tb-15 color-gray">
              {handler.isEdit ? (
                <Item
                  rules={[
                    {
                      required: REQUIRED_INPUT,
                      message: MESSAGE_REQUIRED,
                    },
                  ]}
                  name={[`materialCost${materialCost.key}`, "estimateMoney"]}
                >
                  <InputNumber
                    controls={false}
                    maxLength={COMMON.MAX_INPUT_NUMBER}
                    max={COMMON.MAX_NUMBER_VALUE}
                    min={MIN_INPUT_NUMBER}
                    className="input-cr001"
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Item>
              ) : (
                handler.checkFormatValue(
                  materialCost?.estimateMoney,
                  false,
                  true
                )
              )}
            </div>
            <div className="cr001-cr-item-child border-bottom border-right cr-6 padding-tb-15 color-gray">
              {handler.isEdit ? (
                <Item
                  rules={[
                    {
                      required: REQUIRED_INPUT,
                      message: MESSAGE_REQUIRED,
                    },
                  ]}
                  name={[`materialCost${materialCost.key}`, "costMoney"]}
                >
                  <InputNumber
                    controls={false}
                    maxLength={COMMON.MAX_INPUT_NUMBER}
                    max={COMMON.MAX_NUMBER_VALUE}
                    min={MIN_INPUT_NUMBER}
                    className="input-cr001"
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Item>
              ) : (
                handler.checkFormatValue(materialCost?.costMoney, false, true)
              )}
            </div>
            <div className="cr001-cr-item-child border-bottom border-right cr-6 padding-tb-15 cr003-item-percent color-gray">
              {handler.isEdit ? (
                <>
                  <Item
                    rules={[
                      {
                        required: REQUIRED_INPUT,
                        message: MESSAGE_REQUIRED,
                      },
                    ]}
                    name={[`materialCost${materialCost.key}`, "budgetMoney"]}
                  >
                    <InputNumber
                      controls={false}
                      maxLength={COMMON.MAX_INPUT_NUMBER}
                      max={COMMON.MAX_NUMBER_VALUE}
                      min={MIN_INPUT_NUMBER}
                      className="input-cr001"
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                    />
                  </Item>
                  <span className="cr001-percent-text">
                    {handler.checkFormatValue(
                      materialCost?.budgetMoneyPercent,
                      true,
                      true
                    )}
                  </span>
                </>
              ) : (
                handler.checkFormatValue(
                  materialCost?.budgetMoney,
                  false,
                  true
                ) +
                " " +
                handler.checkFormatValue(
                  materialCost?.budgetMoneyPercent,
                  true,
                  true
                )
              )}
            </div>
            <div className="cr001-cr-item-child border-bottom border-right cr-6 padding-tb-15 cr003-item-percent color-gray">
              {handler.isEdit ? (
                <>
                  <Item
                    rules={[
                      {
                        required: REQUIRED_INPUT,
                        message: MESSAGE_REQUIRED,
                      },
                    ]}
                    name={[`materialCost${materialCost.key}`, "orderMoney"]}
                  >
                    <InputNumber
                      controls={false}
                      maxLength={COMMON.MAX_INPUT_NUMBER}
                      max={COMMON.MAX_NUMBER_VALUE}
                      min={MIN_INPUT_NUMBER}
                      className="input-cr001"
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                    />
                  </Item>
                  <span className="cr001-percent-text">
                    {handler.checkFormatValue(
                      materialCost?.orderMoneyPercent,
                      true,
                      true
                    )}
                  </span>
                </>
              ) : (
                handler.checkFormatValue(
                  materialCost?.orderMoney,
                  false,
                  true
                ) +
                " " +
                handler.checkFormatValue(
                  materialCost?.orderMoneyPercent,
                  true,
                  true
                )
              )}
            </div>
            <div className="cr001-cr-item-child border-bottom border-right cr-6 padding-tb-15 cr003-item-percent color-gray">
              {handler.isEdit ? (
                <UploadFileCRComponent
                  nameForm={[
                    `materialCost${materialCost.key}`,
                    "documentMaterial",
                  ]}
                  fileNameDefault={materialCost.document}
                  form={handler.formAmount}
                  afterClear={() =>
                    handler.afterClear(
                      [`materialCost${materialCost.key}`, "documentMaterial"],
                      handler.listIndirect,
                      "materialCost"
                    )
                  }
                />
              ) : materialCost?.document?.length != 0 ? (
                <div
                  className="button-style"
                  onClick={() => {
                    handler.handleViewFile(materialCost, "material");
                  }}
                >
                  <img src={DocumentIconSvg} alt="" />
                </div>
              ) : null}
            </div>
          </div>
        );
      })}

      <div className="cr001-cr-item bg-header-row">
        <div className="cr001-cr-item-child border-bottom border-right cr-6 padding-tb-15">
          合計金額
        </div>
        <div className="cr001-cr-item-child border-bottom border-right cr-6 padding-tb-15"></div>
        <div className="cr001-cr-item-child border-bottom border-right cr-6 padding-tb-15 color-gray fw-700">
          {handler.checkFormatValue(
            handler.totalMaterialCost?.estimateMoney,
            false,
            true
          )}
        </div>
        <div className="cr001-cr-item-child border-bottom border-right cr-6 padding-tb-15 color-gray fw-700">
          {handler.checkFormatValue(
            handler.totalMaterialCost?.costMoney,
            false,
            true
          )}
        </div>
        <div className="cr001-cr-item-child border-bottom border-right cr-6 padding-tb-15 color-gray fw-700">
          {handler.checkFormatValue(
            handler.totalMaterialCost?.budgetMoney,
            false,
            true
          )}{" "}
          {handler.checkFormatValue(
            handler.totalMaterialCost?.budgetMoneyPercent,
            true,
            true
          )}
        </div>
        <div className="cr001-cr-item-child border-bottom border-right cr-6 padding-tb-15 color-gray fw-700">
          {handler.checkFormatValue(
            handler.totalMaterialCost?.orderMoney,
            false,
            true
          )}{" "}
          {handler.checkFormatValue(
            handler.totalMaterialCost?.orderMoneyPercent,
            true,
            true
          )}
        </div>
        <div className="cr001-cr-item-child border-bottom border-right cr-6 padding-tb-15"></div>
      </div>
    </div>
  );
};

export default memo(Material);
