import { IC4SliceType } from './../type/Presenter';
import { createSlice } from '@reduxjs/toolkit';

const initialState: IC4SliceType = {
    isRefresh: false,
    isOpenIC4: false,
};

export const ic4Slice = createSlice({
    name: 'ic4',
    initialState,
    reducers: {
        resetIC4: () => initialState,
        showIC4Modal: (state) => {
            state.isOpenIC4 = true;
        },
        hideIC4Modal: (state) => {
            state.isOpenIC4 = false;
        },
    },
});

export const { resetIC4, showIC4Modal, hideIC4Modal } = ic4Slice.actions;

export default ic4Slice.reducer;
