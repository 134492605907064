import { SM01Api } from './ServiceImpl';

const SM01Service = (api: SM01Api) => {
    const getListMember = async (params: any): Promise<any> => {
        try {
            return await api.getListMember(params);
        } catch (error) {
            throw error;
        }
    };

    const getListEvent = async (params: any): Promise<any> => {
        try {
            return await api.getListEvent(params);
        } catch (error) {
            throw error;
        }
    };

    return {
        getListMember,
        getListEvent,
    };
};

export default SM01Service;
