import { PM024Api } from "./ServiceImpl";

const PM024Service = (pm012Api: PM024Api) => {
  const getCompanyList = async (params: any): Promise<any> => {
    try {
      return await pm012Api.getCompanyList(params);
    } catch (error) {
      throw error;
    }
  };
  const addCompanyForList = async (data: any): Promise<any> => {
    try {
      return await pm012Api.addCompanyForList(data);
    } catch (error) {
      throw error;
    }
  };
  return {
    getCompanyList,
    addCompanyForList,
  };
};

export default PM024Service;
