import { OM007SliceType } from "./../type/Presenter";
import { createSlice } from "@reduxjs/toolkit";

const initialState: OM007SliceType = {
  loading: false,
  isVisible: false,
};

export const om007Slice = createSlice({
  name: "om007",
  initialState,
  reducers: {
    resetOM007: () => initialState,
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsVisibleOM007: (state, action) => {
      state.isVisible = action.payload;
    },
  },
});

export const { setLoading, setIsVisibleOM007, resetOM007 } = om007Slice.actions;

export default om007Slice.reducer;
