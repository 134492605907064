import { useState } from "react";
import { AM005ServiceImpl } from "./../../usecase/ServiceImpl";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../../store";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../../common/constants/MESSAGE";
import COMMON from "../../../../../common/constants/COMMON";
import { DEFAULT_PARAM } from "./HandlerProject";
import moment from "moment";
import { exportAttendanceProject } from "../../../../../common/helpers/exports/project";
import { useNavigate } from "react-router-dom";
import helpers from "../../../../../common/helpers/common";
import { doExportForResponse } from "../../../../../common/helpers/exports/common";

const AM005Handler = (service: AM005ServiceImpl) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState<any>([]);
  const [constructions, setConstructions] = useState<any[]>([]);

  const projectChosen = useSelector(
    (state: RootState) => state.am005.projectChosen
  );

  const projectList = useSelector(
    (state: RootState) => state.am005.projectList
  );

  const getListConstructionProject = async (params: {
    projectId: string | number;
    from?: string | undefined;
    to?: string | undefined;
  }) => {
    try {
      dispatch(setLoading(true));

      const response = await service.getListConstructionProject(params);
      setConstructions(response.data.results || []);
      if (response?.data?.results?.length > 0) {
        getListPartnerConstruction(
          {
            constructionId: response.data.results[0].rootConstructionId
              ? response.data.results[0].rootConstructionId
              : response.data.results[0].id,
            contractorConstructionId: response.data.results[0].id,
            from: params.from,
            to: params.to,
          },
          response.data.results
        );
        // setActiveKey([response.data.results[0].id?.toString()]);
      }
    } catch (error: any) {
      ErrorNotification(error.message || NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getListPartnerConstruction = async (
    params: {
      constructionId: number;
      contractorConstructionId: number;
      from?: string;
      to?: string;
    },
    constructions: any[],
    constructionExport?: any
  ) => {
    try {
      dispatch(setLoading(true));
      const response = await service.getListPartnerConstruction(params);

      if (response.data?.results?.length > 0) {
        const partnerConstruction = response.data?.results;
        const listSubConstruction: any = [];

        for (let i = 0; i < partnerConstruction.length; i++) {
          await getListSubConstruction(
            {
              constructionId: params.constructionId,
              contractorConstructionId: partnerConstruction[i].id,
              from: params?.from,
              to: params?.to,
            },
            listSubConstruction,
            partnerConstruction[i],
            0
          );
        }
        if (projectChosen) {
          const newConstructions = constructions.map((construction) => {
            const obj: { [keyword: string]: any } = {
              ...construction,
            };
            if (construction?.id === +params.constructionId)
              obj.subConstruction = listSubConstruction;
            return obj;
          });
          if (!constructionExport) {
            setConstructions(newConstructions);
          }
        }
        if (constructionExport) {
          return listSubConstruction;
        }
      }
    } catch (error: any) {
      ErrorNotification(error.message || NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getListSubConstruction = async (
    params: {
      constructionId: number;
      contractorConstructionId: number;
      from?: string | undefined;
      to?: string | undefined;
    },
    listSubConstruction: any,
    subConstruction: any,
    level: number
  ) => {
    try {
      dispatch(setLoading(true));
      const response = await service.getListSubConstruction(params);
      listSubConstruction.push({ ...subConstruction, level: level });
      if (response.data?.results?.length === 0) return;
      const subContractor = response.data?.results;

      for (let i = 0; i < subContractor.length; i++) {
        await getListSubConstruction(
          {
            constructionId: params.constructionId,
            contractorConstructionId: subContractor[i].id,
            from: params.from,
            to: params.to,
          },
          listSubConstruction,
          subContractor[i],
          level + 1
        );
      }
    } catch (error: any) {
      ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const funcChangeCollapse = (value: any, params: { from: any; to: any }) => {
    const keyOpen = value.find((element: any) => {
      return !activeKey.includes(element);
    });

    const findConstruction = constructions.find(
      (construction) => construction?.id === +keyOpen
    );

    if (keyOpen && findConstruction) {
      getListPartnerConstruction(
        {
          constructionId: findConstruction.rootConstructionId
            ? findConstruction.rootConstructionId
            : findConstruction.id,
          contractorConstructionId: keyOpen,
          from: params.from,
          to: params.to,
        },
        constructions
      );
    }
    setActiveKey(value);
  };

  const handleChangePage = (
    constructionId: number | string,
    contractorId?: any,
    constructionName?: string,
    subConstructionName?: string
  ) => {
    const searchParams = window.location.search?.replaceAll("&", "/");
    if (!contractorId)
      navigate(
        `/app-am006/${constructionId}?preParams=${searchParams}&title=${projectChosen?.projectName}&constructionName=${constructionName}`
      );
    else
      navigate(
        `/app-am007/${constructionId}/${contractorId}?preParams=${searchParams}&title=${projectChosen?.projectName} / ${constructionName} / ${subConstructionName}`
      );
  };

  const exportData = async (params: any) => {
    try {
      dispatch(setLoading(true));
      const response = await service.doExportAM005({
        ...params,
      } as any);
      doExportForResponse(
        response,
        `案件別勤務実績_${projectChosen?.projectName}_${moment().format(COMMON.FORMAT_DATE7)}.xlsx`
      );
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  return {
    projectChosen,
    activeKey,
    constructions,
    projectList,

    getListConstructionProject,
    exportData,
    getListPartnerConstruction,
    funcChangeCollapse,
    handleChangePage,
  };
};

export default AM005Handler;
