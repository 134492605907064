import DB003Api from './infrastructure/api/Api';
import DB003Handler from './presenter/handler/Handler';
import DB003UI from './presenter/ui/UI';
import DB003Service from './usecase/Service';

const DB003Component = () => {
    const db003Api = DB003Api();
    const db003Service = DB003Service(db003Api);
    const db003Handler = DB003Handler(db003Service);
    return <DB003UI handler={db003Handler} />;
};

export default DB003Component;
