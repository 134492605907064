import React, { useState, useEffect } from 'react';
import { Empty, Form, Select } from 'antd';
import MESSAGE from '../../../../common/constants/MESSAGE';
const { Item } = Form;
const { Option } = Select;

type props = {
    nameForm: string;
    options: {
        id: number;
        fullName: string;
        username: string;
    }[];

    handleSearchOption(value: string): void;
    handleScrollMember(e: any): void;
    onDropdownVisibleChange(open: boolean): void;
};
const ItemSelect = ({
    nameForm,
    options,
    handleSearchOption,
    handleScrollMember,
    onDropdownVisibleChange,
}: props) => {
    const [open, setOpen] = useState(false);
    useEffect(() => {
        const itemScroll = document.querySelector('#sw-scroll');
        const funcScroll = () => setOpen(false);
        if (itemScroll) {
            itemScroll.addEventListener('scroll', funcScroll);
        }
        return () => itemScroll?.removeEventListener('scroll', funcScroll);
    }, []);
    return (
        <Item name={nameForm} rules={[{ required: true, message: MESSAGE.MESSAGE_REQUIRED_FIELD }]}>
            <Select
                size="large"
                className="sw002-select"
                listHeight={156}
                open={open}
                onPopupScroll={handleScrollMember}
                onSearch={handleSearchOption}
                showSearch
                filterOption={() => true}
                onDropdownVisibleChange={(open) => {
                    onDropdownVisibleChange(open);
                    setOpen(open);
                }}
                notFoundContent={
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={MESSAGE.MESSAGE_NO_DATA}
                    />
                }
            >
                {options.map((item: any) => (
                    <Option key={item.id} value={item.id} className="sw02-member-option">
                        {item.fullName || item.username}
                    </Option>
                ))}
            </Select>
        </Item>
    );
};

export default ItemSelect;
