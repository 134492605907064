import { CRT001Api } from "./ServiceImpl";

const CRT001Service = (cr003Api: CRT001Api) => {
  const getCRT001Data = async (params: any): Promise<any> => {
    try {
      return await cr003Api.getCRT001Data(params);
    } catch (error) {
      throw error;
    }
  };

  return {
    getCRT001Data,
  };
};

export default CRT001Service;
