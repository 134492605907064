// Redux
import { useDispatch } from "react-redux";

// Services
import { ServiceImpl } from "./../../usecase/ServiceImpl";
import { useState } from "react";
import { resetCM0025, setVisibleCM0025 } from "../slice/Slice";

import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import {
  CompanyState,
  ReSummaryCompanyProjectOutsourceCostRetrievePayload,
  SummaryDataStateType,
} from "../../entity/Entity";
import helpers from "../../../../../common/helpers/common";
import { getDataWithFormula } from "../../helper";
import {
  setHeaderDataCM0025_1,
  setIsVisibleCM0025_1,
  setParentCodeCM0025_1,
} from "../../../CM0025_1/presenter/slice/Slice";
import {
  setIsVisibleCM0025_2,
  setParentCodeCM0025_2,
} from "../../../CM0025_2/presenter/slice/Slice";
import ConfirmModal from "../../../../../common/components/modal/ConfirmModal";
import { ItemApprove } from "../../../../../common/components/approval-flow/ApprovalFlow";
import { showModalApproveConfirm } from "../../../../../common/components/modal-approve-confirm/presenter/slice/Slice";

const CM0029Handler = (service: ServiceImpl) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [expandCompanyKey, setExpandCompanyKey] = useState<any[]>([]);
  const [expandProjectKey, setExpandProjectKey] = useState<any[]>([]);
  const [outsourceCompanies, setOutsourceCompanies] = useState<CompanyState[]>(
    []
  );
  const [summaryData, setSummaryData] = useState<SummaryDataStateType>({
    actualCost: 0,
    consumptionTax: 0,
    totalOffset: 0,
    paymentAmountExcludingTax: 0,
    paymentAmountIncludingTax: 0,
    offsetCostTax: 0,
    offsetCostTaxFree: 0,
    offsetCostSummaryTax: 0,
    offsetCostSummaryTaxFree: 0,
  });
  const [workflow, setWorkflow] = useState<ItemApprove[]>([]);
  const [currentUserApproval, setCurrentUserApproval] =
    useState<ItemApprove | null>(null);
  const getWorkflowSummaryOutsourceCompanyApprovalProcess = async (
    documentId: number
  ) => {
    try {
      setLoading(true);
      const data =
        await service.getWorkflowSummaryOutsourceCompanyApprovalProcess({
          documentId,
        });
      if (data.results.currentUserApproval)
        setCurrentUserApproval({
          approveDate: data.results.currentUserApproval.approveDate,
          contact_address: "",
          email: data.results.currentUserApproval.email,
          id: data.results.currentUserApproval.id,
          isApprove: data.results.currentUserApproval.approve,
          reject: data.results.currentUserApproval.reject,
          position: "",
          priority: data.results.currentUserApproval.priority,
          username: data.results.currentUserApproval.username,
          note: data.results.currentUserApproval.note,
          fullName: data.results.currentUserApproval.fullName,
        });
      const workflowData: ItemApprove[] = data.results.listApprover.map(
        (element) => {
          let note = element.note;
          if (element.id === data.results?.currentUserApproval?.id)
            note = data.results.currentUserApproval.note;
          const result: ItemApprove = {
            approveDate: element.approveDate,
            contact_address: "",
            email: element.email,
            id: element.id,
            isApprove: element.approve,
            reject: element.reject,
            position: "",
            priority: element.priority,
            username: element.username,
            fullName: element.fullName,
            note: note,
          };
          return result;
        }
      );
      setWorkflow(workflowData);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      setLoading(false);
    }
  };

  const getCompanyProjectOurSourceCost = async (params: {
    documentId: number;
    companyId?: number;
    colabRefId?: number;
  }) => {
    const projectList =
      await service.getReSummarySubmittedOutsourceCostCompanyProject(params);
    const outsourceCost = await Promise.all(
      projectList.map((element) => {
        return service.getReSummarySubmittedOutsourceCostCompanyProjectConstruction(
          {
            documentId: params.documentId,
            companyId: params.companyId,
            colabRefId: params.colabRefId,
            projectId: element?.project?.id,
          }
        );
      })
    );
    const result = projectList.map((element, index) => {
      return { ...element, outsourceCost: outsourceCost[index] };
    });
    return result;
  };

  const getInitialData = async (params: { documentId: number }) => {
    try {
      setLoading(true);

      // companies - LV1
      const companyResults =
        await service.getReSummarySubmittedOutsourceCostCompany(params);

      // project - LV2
      const projectPromiseAllData = await Promise.all(
        companyResults.map((element) => {
          let companyId = undefined;
          let colabRefId = undefined;
          if (element?.company?.id !== 0) companyId = element?.company?.id;
          else colabRefId = element?.company?.colabRefId;

          return getCompanyProjectOurSourceCost({
            documentId: params.documentId,
            companyId: companyId,
            colabRefId: colabRefId,
          });
        })
      );

      let outsourceCompanyData: CompanyState[] = companyResults.map(
        (element: any, index) => {
          return {
            ...element,
            projectList: projectPromiseAllData[index],
          };
        }
      );
      const { data, summary } = getDataWithFormula(outsourceCompanyData);
      if (data.length > 0) {
        setExpandCompanyKey([data[0].id]);
        if (data[0].projectList.length > 0)
          setExpandProjectKey([data[0].projectList[0].id]);
      }

      setSummaryData(summary);
      setOutsourceCompanies(data);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      setLoading(false);
    }
  };

  const cancelModalCM0025 = () => {
    dispatch(setVisibleCM0025(false));
  };

  // SRS - CM0017_1
  const openCM0025_2 = (value: any) => {
    dispatch(setIsVisibleCM0025_2(true));
    dispatch(
      setParentCodeCM0025_2({
        documentSummaryOutsourceCostCompanyDataId: value.id,
        categoryId: value.categoryId,
      })
    );
  };

  // SRS - CM0011
  const openCM0025_1 = (value: any) => {
    dispatch(setIsVisibleCM0025_1(true));
    dispatch(
      setParentCodeCM0025_1({
        categoryId: value.categoryId,
        documentSummaryOutsourceCostConstructionDataId: value.id,
      })
    );
    dispatch(
      setHeaderDataCM0025_1({
        traderName: value?.companyName ?? "",
        contractWorkName: value?.constructionColab?.description ?? "",
        targetPeriod: helpers.toTargetPeriodFormat(
          value.startDate,
          value.endDate
        ),
      })
    );
  };
  const setProjectExpandKeys = (key: any) => {
    const checkKey = checkProjectKeyExpand(key);
    if (checkKey) {
      const newExpandKey = expandProjectKey.filter(
        (keyExpand: any) => keyExpand !== key
      );
      setExpandProjectKey(newExpandKey);
    } else {
      setExpandProjectKey([...expandProjectKey, key]);
    }
  };

  const checkProjectKeyExpand = (key: any) => {
    if (!key || !expandProjectKey) return false;
    return expandProjectKey.some((element: any) => element === key);
  };

  const setCompanyExpandKeys = (key: any) => {
    const checkKey = checkCompanyKeyExpand(key);
    if (checkKey) {
      const newExpandKey = expandCompanyKey.filter(
        (keyExpand: any) => keyExpand !== key
      );
      setExpandCompanyKey(newExpandKey);
    } else {
      setExpandCompanyKey([...expandCompanyKey, key]);
    }
  };

  const checkCompanyKeyExpand = (key: any) => {
    if (!key || !expandCompanyKey) return false;
    return expandCompanyKey.some((element: any) => element === key);
  };
  const putReSummaryCompanyProjectOutsourceCostRetrieve = async (
    data: ReSummaryCompanyProjectOutsourceCostRetrievePayload,
    afterSuccess?: () => void
  ): Promise<void> => {
    ConfirmModal({
      onOk: async () => {
        try {
          setLoading(true);
          const responseData =
            await service.putReSummaryCompanyProjectOutsourceCostRetrieve(data);
          if (responseData) {
            SuccessNotification(
              responseData.message ?? NOTIFICATION_TITLE.SUCCESS
            );
            dispatch(resetCM0025());
            if (afterSuccess) afterSuccess();
          }
        } catch (error: any) {
          ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        } finally {
          setLoading(false);
        }
      },
      className: "confirm__modal",
      title: MESSAGE.MESSAGE_TITLE_CM016,
      description: MESSAGE.MESSAGE_DESCRIPTION_CR_2,
      extraDescription: MESSAGE.MESSAGE_DESCRIPTION_COMMON_QUESTION_1,
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_MODAL,
      isCenterWithoutMenu: true,
    });
  };
  const showConfirmApproveModal = () => {
    dispatch(showModalApproveConfirm());
  };
  return {
    outsourceCompanies,
    expandProjectKey,
    loading,
    expandCompanyKey,
    summaryData,
    workflow,
    currentUserApproval,
    cancelModalCM0025,
    getInitialData,
    openCM0025_2,
    openCM0025_1,
    checkProjectKeyExpand,
    setProjectExpandKeys,
    checkCompanyKeyExpand,
    setCompanyExpandKeys,
    putReSummaryCompanyProjectOutsourceCostRetrieve,
    getWorkflowSummaryOutsourceCompanyApprovalProcess,
    showConfirmApproveModal,
  };
};

export default CM0029Handler;
