// Built-in
import React, { useEffect } from 'react';

// 3rd party lib
import { Button, Layout } from 'antd';

// Source files
import MenuWrapperComponent from '../../../../../common/components/menu-wrapper';
import DescriptionComponent from './Description';
import TabComponent from './Tab';
import SubTabComponent from './SubTab';
import MaterialCostComponent from '../component/material-cost';
import { HandlerImpl } from '../handler/HandlerImpl';
import BreadcrumbComponent from '../../../../../common/components/breadcrumb/BreadcrumbComponent';

// Image files
import UploadLightSVG from '../../../../../assets/icons/upload-icon-purple.svg';
import CheckIconSVG from '../../../../../assets/icons/check-icon.svg';
import CompletedPaidAmountComponent from '../component/completed-paid-amount';
import { useSearchParams } from 'react-router-dom';
import CM005Component from '../../../CM005';
import CM007Component from '../../../CM007';
import CM0013Component from '../../../CM0013';
import CM009Component from '../../../CM009';
import CM0015Component from '../../../CM0015';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import ModalApprove from '../../../../../common/components/approval-flow/ModalApprove';
import MESSAGE from '../../../../../common/constants/MESSAGE';
import { getBreadCrumbCM004 } from '../../helper';
import { AUTHORIZATION_CODE, SYSTEM_ROLES } from '../../../../../common/constants/AUTHORIZATION';
import { isHasPrivilege } from '../../../../../common/helpers/privilege';
import { PRIVILEGE_PM } from '../../../../../common/constants/PRIVILEGE';

interface props {
    handler: HandlerImpl;
}

const MainUI = ({ handler }: props) => {
    const [searchParams] = useSearchParams();
    const tab = searchParams.get('tab') || '1';
    const subTab = searchParams.get('subTab') || '1';
    const constructionId = searchParams.get('constructionId');
    const projectId = searchParams.get('projectId');
    const previouspath = searchParams.get('previouspath');

    const isEnable = useSelector((state: RootState) => state.cm004.isEnable);
    const constructionInfo = useSelector((state: RootState) => state.cm004.constructionInfo);
    const permission = useSelector((state: RootState) => state.auth.authData?.permission);

    const isRoleAdmin = useSelector((state: RootState) => state.auth?.authData?.isRoleAdmin)

    const isRoleAdminSystem =
        useSelector((state: RootState) => state.auth?.authData?.isRoleAdminSystem)

    const userId = useSelector((state: RootState) => state.auth.authData?.id) ?? ''
    const detailProject = useSelector((state: RootState) => state.cm004.detailProject)
    const { chosenKey, openKey, breadcrumb } = getBreadCrumbCM004(previouspath);

    useEffect(() => {
        (async () => {
            if (constructionId && !isNaN(parseInt(constructionId)))
                await handler.getMoneyConstructionInformation({
                    constructionId: parseInt(constructionId),
                });
        })();
        return () => {
            handler.cleanEffect();
        };
    }, [constructionId]);

    useEffect(() => {
        (async () => {
            if (projectId && !isNaN(parseInt(projectId)))
                await handler.getDetailProject({
                    projectId: parseInt(projectId),
                });
        })();

    }, [projectId]);

    const showComponentByTab = (tab: string, subTab: string) => {
        const CM004 = (
            <>
                <CompletedPaidAmountComponent />
                <div style={{ height: 4 }}></div>
                <MaterialCostComponent />
            </>
        );
        const CM005 = <CM005Component />;
        const CM007 = <CM007Component />;
        const CM009 = <CM009Component />;
        const CM0013 = <CM0013Component />;
        const CM0015 = <CM0015Component />;
        switch (tab) {
            case '2':
                return CM005;
            case '3':
                switch (subTab) {
                    case '1':
                        return CM007;
                    case '2':
                        return CM009;
                    default:
                        return CM0013;
                }
            case '4':
                return CM0015;
            default:
                return CM004;
        }
    };

    return (
        <MenuWrapperComponent chosenKey={chosenKey} openKey={openKey}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'end',
                }}
            >
                <div className="om002-header-container">
                    <BreadcrumbComponent data={breadcrumb} />
                    <div className="header-row-title" style={{ marginTop: 8 }}>
                        工事詳細情報
                    </div>
                </div>
                <div></div>
            </div>
            <Layout style={{ minHeight: '84vh', marginTop: 24, background: '#fff', padding: 24 }}>
                <p className="title-cm">工事基本情報</p>
                <DescriptionComponent data={constructionInfo} />
                <div style={{ marginTop: 32, display: 'flex', justifyContent: 'space-between' }}>
                    <TabComponent onChange={handler.onTabChange} tab={tab} />
                    <div style={{ display: 'flex' }}>
                        {tab !== '1' && (permission?.includes(AUTHORIZATION_CODE.S49) || isHasPrivilege(detailProject, [PRIVILEGE_PM])) ? (
                            < Button
                                disabled={!isEnable}
                                style={{ marginRight: 24, width: 98 }}
                                size="large"
                                type="primary"
                                onClick={() => handler.onSubmit()}
                                className="button-purple"
                            >
                                <div className="center-item">
                                    <img
                                        alt=""
                                        src={CheckIconSVG}
                                        style={{ marginRight: 8, fontWeight: 700, fontSize: 14 }}
                                    />
                                    <div className="export-button">
                                        提出
                                    </div>
                                </div>
                            </Button>
                        ) : (
                            <></>
                        )}
                        {permission?.includes(AUTHORIZATION_CODE.B9) || isRoleAdmin || isRoleAdminSystem ? (
                            <Button
                                className="button-brown-3"
                                style={{ border: '1px solid #605BFF', width: 106 }}
                                size="large"
                                onClick={() => handler.onExport()}
                            >
                                <div className="center-item">
                                    <img
                                        alt=""
                                        src={UploadLightSVG}
                                        style={{
                                            marginRight: 8,
                                            fontWeight: 700,
                                            fontSize: 14,
                                        }}
                                    />
                                    <div
                                        className="export-button"
                                        style={{ color: "#605BFF" }}
                                    >
                                        出力
                                    </div>
                                </div>
                            </Button>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        {tab === '3' ? (
                            <div style={{ minWidth: 400 }}>
                                <SubTabComponent onChange={handler.onSubTabChange} tab={subTab} />
                            </div>
                        ) : (
                            <div></div>
                        )}
                        <div
                            className=" title-table-cm005"
                            style={{ textAlign: 'right', marginBottom: 8 }}
                        >
                            （税抜）
                        </div>
                    </div>
                    <div>{showComponentByTab(tab, subTab)}</div>
                </div>
            </Layout>

        </MenuWrapperComponent >
    );
};

export default MainUI;
