import { Table } from "antd";
import { COLUMN } from "./ColumsTableCCUS";
import { CCUS05Handler } from "../handler/HandlerImpl";
import EmptyData from "../../../../../assets/icons/empty-data.svg";

interface props {
  handler: CCUS05Handler;
}
const TableCCUS = ({ handler }: props) => {
  const { columnsTable, rowSelection } = COLUMN(handler);
  return (
    <>
      {handler.dataTable.length == 0 ? (
        <div className="empty-data-container">
          <img src={EmptyData} alt="" />
        </div>
      ) : (
        <div className="w-100-percent" style={{maxHeight: '63vh'}}>
          <Table
            columns={columnsTable}
            rowSelection={{
              ...rowSelection,
            }}
            dataSource={handler.dataTable}
            scroll={{ y: "60vh", x: "max-content" }}
            pagination={false}
            className="table-ccus"
          />
        </div>
      )}
    </>
  );
};

export default TableCCUS;
