import { ReceivedMoneyOffset } from '../entity/Entity';
import { CM0012Api } from './ServiceImpl';

const CM0012Service = (cm0012: CM0012Api) => {
    const getListMoneyDirectCostOutsourceOffset = async (params: any): Promise<any> => {
        try {
            return await cm0012.getListMoneyDirectCostOutsourceOffset(params);
        } catch (error) {
            throw error;
        }
    };

    const getSubCategory = async (): Promise<any> => {
        try {
            return await cm0012.getSubCategory();
        } catch (error) {
            throw error;
        }
    };

    const createReceivedMoneyOffset = async (data: any): Promise<any> => {
        try {
            return await cm0012.createReceivedMoneyOffset(data);
        } catch (error) {
            throw error;
        }
    };

    const deleteReceivedMoneyOffset = async (data: any): Promise<any> => {
        try {
            return await cm0012.deleteReceivedMoneyOffset(data);
        } catch (error) {
            throw error;
        }
    };

    const editReceivedMoneyOffset = async (data: any): Promise<any> => {
        try {
            return await cm0012.editReceivedMoneyOffset(data);
        } catch (error) {
            throw error;
        }
    };

    return {
        getListMoneyDirectCostOutsourceOffset,
        getSubCategory,
        createReceivedMoneyOffset,
        deleteReceivedMoneyOffset,
        editReceivedMoneyOffset,
    };
};

export default CM0012Service;
