import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CompanyServiceImpl } from '../../usecase/ServiceImpl';
import { Form } from 'antd';
import COMMON from '../../../../../common/constants/COMMON';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { RangeDateType } from '../../entity/Entity';

import { setCompanyListData } from '../slice/Slice';

const CompanyHandler = (service: CompanyServiceImpl) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const [companyList, setCompanyList] = useState<any[]>([]);
    const [totalCompany, setTotalCompany] = useState<number>(0);
    const [rangeDate, setRangeDate] = useState<RangeDateType>({
        from: moment().startOf('month'),
        to: moment(),
    });

    const onFromDateChange = (value: any) => {
        setRangeDate({ ...rangeDate, from: value });
    };
    const onToDateChange = (value: any) => {
        setRangeDate({ ...rangeDate, to: value });
    };

    const [searchParams, setSearchParams] = useSearchParams();
    const selectedCompany = searchParams.get('selectedCompany');
    const fromDate =
        searchParams.get('startDate') || moment().startOf('month').format(COMMON.FORMAT_DATE2);

    const toDate = searchParams.get('endDate') || moment().format(COMMON.FORMAT_DATE2);

    const getAttendanceCompanyColab = async (params: {
        keyword?: string;
        page: string;
        size: string;
        sortBy?: string;
        sortType?: 'DESC' | 'ASC';
    }) => {
        const { results, pagination } = await service.getAttendanceCompanyColab(params);
        if (!selectedCompany && results.length > 0) {
            searchParams.set('selectedCompany', results[0].id);
            searchParams.set('selectedCompanyName', results[0].name);
            setSearchParams(searchParams);
        }
        setCompanyList(results);
        dispatch(setCompanyListData(results));
        setTotalCompany(pagination.count);
    };

    const initiateFilter = () => {
        let fromMoment: moment.Moment = moment().startOf('month');
        if (fromDate === null) form.setFieldValue('startDate', moment().startOf('month'));
        else {
            form.setFieldValue('startDate', moment(fromDate));
            fromMoment = moment(fromDate);
        }

        form.setFieldValue('endDate', moment(toDate));
        setRangeDate({
            to: moment(toDate),
            from: fromMoment,
        });
    };

    var myVar: any;
    const onSearch = () => {
        if (myVar) clearTimeout(myVar);
        myVar = setTimeout(function () {
            const searchText = form.getFieldValue('text');
            form.setFieldValue('text', searchText.trim());
            searchParams.set('companyKeyword', searchText.trim());
            searchParams.set('companyPage', COMMON.DEFAULT_PAGE + '');
            setSearchParams(searchParams);
        }, 1000);
    };

    const onChooseCompany = (value: any) => {
        searchParams.set('selectedCompany', value.id + '');
        searchParams.set('selectedCompanyName', value.name + '');
        searchParams.set('page', COMMON.DEFAULT_PAGE + '');

        setSearchParams(searchParams);
    };

    const onPageChange = (value: any) => {
        searchParams.set('companyPage', value);
        setSearchParams(searchParams);
    };

    const onFilter = () => {
        searchParams.set('pageDate', COMMON.DEFAULT_PAGE + '');
        const startDate = form.getFieldValue('startDate');
        if (startDate) searchParams.set('startDate', moment(startDate).format(COMMON.FORMAT_DATE2));
        else searchParams.set('startDate', '');

        const endDate = form.getFieldValue('endDate');
        searchParams.set('endDate', moment(endDate).format(COMMON.FORMAT_DATE2));
        setSearchParams(searchParams);
    };

    const onResetFilter = () => {
        const initialStart = moment().startOf('month');
        const initialEnd = moment();

        form.setFields([
            {
                name: 'startDate',
                value: initialStart,
            },
            {
                name: 'endDate',
                value: initialEnd,
            },
        ]);
        searchParams.set('pageDate', COMMON.DEFAULT_PAGE + '');
        searchParams.set('startDate', initialStart.format(COMMON.FORMAT_DATE2));
        searchParams.set('endDate', initialEnd.format(COMMON.FORMAT_DATE2));
        setSearchParams(searchParams);
    };

    const onCollapse = (value: boolean) => {
        searchParams.set('isCollapsed', value ? 'isCollapsed' : '');
        setSearchParams(searchParams);
    };

    return {
        onChooseCompany,
        onSearch,
        onPageChange,
        onFilter,
        onResetFilter,
        onCollapse,
        initiateFilter,
        getAttendanceCompanyColab,
        onFromDateChange,
        onToDateChange,

        form,
        companyList,
        totalCompany,
        rangeDate,
    };
};

export default CompanyHandler;
