const SideBarDummpy = () => {
    const item = {
        key: 'N',
        name: 'Member',
    };

    return Array(5)
        .fill({ ...item })
        .map((item: any) => {
            return item;
        });
};

const roleMapperTabOne = (data: any[], dataMapperOne: any[], dataMapperTwo: any[]) => {
    let roleMapperOne = dataMapperOne;
    let roleMapperTwo = dataMapperTwo;

    const output = [roleMapperOne, roleMapperTwo].map((item: any[]) => {
        item.forEach((a: any) => {
            data.forEach((b: any) => {
                if (a.codeApprove === b.code) a.approve = b.flag;
            });
        });

        return item;
    });
    return output;
};

const roleMapperTabTwo = (
    data: any[],
    dataMapperOne: any[],
    dataMapperTwo: any[],
    dataMapperThree: any[],
    dataMapperFour: any[],
    dataMapperFive: any[],
    dataMapperSix: any[],
) => {
    let roleMapperOne = dataMapperOne;
    let roleMapperTwo = dataMapperTwo;
    let roleMapperThree = dataMapperThree;
    let roleMapperFour = dataMapperFour;
    let roleMapperFive = dataMapperFive;
    let roleMapperSix = dataMapperSix;

    const output = [
        roleMapperOne,
        roleMapperTwo,
        roleMapperThree,
        roleMapperFour,
        roleMapperFive,
        roleMapperSix,
    ].map((item: any[]) => {
        item.forEach((a: any) => {
            data.forEach((b: any) => {
                if (a.codeApprove === b.code) a.approve = b.flag;
                if (a.codeOption === b.code) a.option = b.flag;
                if (a.codePropose === b.code) a.propose = b.flag;
            });
        });

        return item;
    });

    return output;
};

export { SideBarDummpy, roleMapperTabOne, roleMapperTabTwo };
