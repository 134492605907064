import axiosConfig from '../../../../../config/axiosConfig';
import { ProfileCompanyPayload } from '../../entity/Entity';

const IC1Api = () => {
    const getCompanyProfile = async () => {
        const url = '/company/profile';
        return axiosConfig.get(url);
    };

    const putUpdateCompanyProfile = async (payload: ProfileCompanyPayload): Promise<any> => {
        const url = '/company-profile/update';
        return await axiosConfig.put(url, payload);
    };

    return {
        getCompanyProfile,
        putUpdateCompanyProfile
    };
};

export default IC1Api;
