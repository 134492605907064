import { AM012SliceType } from "./../type/Presenter";
import { createSlice } from "@reduxjs/toolkit";

const initialState: AM012SliceType = {
  loading: false,
  isVisible: false,
  refresh: false,
  typeModal: "create",
  requestId: undefined
};

export const am012Slice = createSlice({
  name: "am012",
  initialState,
  reducers: {
    resetAM012: () => initialState,
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsVisibleAM012: (state, action) => {
      state.isVisible = action.payload;
    },
    setRefreshAM012: (state) => {
      state.refresh = !state.refresh;
    },
    setTypeModalAM012:(state, action) =>{
      state.typeModal = action.payload;
    },
    setRequestIdAM012: (state, action) =>{
      state.requestId = action.payload;
    }
  },
});

export const {
  setLoading,
  setIsVisibleAM012,
  resetAM012,
  setRefreshAM012,
  setTypeModalAM012,
  setRequestIdAM012
} = am012Slice.actions;

export default am012Slice.reducer;
