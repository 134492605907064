import moment from "moment";
import lineBreakIcon from "../../../../../assets/images/lineBreak.png";
import dangerCircle from "../../../../../assets/icons/danger-circle.svg";
import { TYPE_REQUEST } from "../../../../../common/constants/STATUS";
import { AM010Handler } from "../handler/HandlerImpl";
import COMMON from "../../../../../common/constants/COMMON";
import { Tooltip } from "antd";
import helpers from "../../../../../common/helpers/common";

type props = {
  handler: AM010Handler;
  borderLeftColor: string;
  backgroundColor: string;
  dataRequest: any;
};

const MAX_LENGTH = 5;

export default function AM010CalendarUnit({
  handler,
  borderLeftColor,
  backgroundColor,
  dataRequest,
}: props) {
  return (
    <div
      className="am010__calendar-unit"
      style={{
        borderLeft: `3px solid ${borderLeftColor}`,
        background: `${backgroundColor}`,
      }}
      onClick={(e) => {
        e.stopPropagation();
        handler.clickAttendance(dataRequest);
      }}
    >
      {dataRequest?.constructionName && dataRequest?.projectName ? (
        <div className="am010__calendar-unit-title">
          <Tooltip
            title={
              dataRequest?.projectName?.length > MAX_LENGTH
                ? dataRequest?.projectName
                : ""
            }
          >
            {dataRequest?.projectName
              ? helpers.formatTextLong(dataRequest?.projectName, MAX_LENGTH)
              : ""}
          </Tooltip>
          ｜
          <Tooltip
            title={
              dataRequest?.constructionName?.length > MAX_LENGTH
                ? dataRequest?.constructionName
                : ""
            }
          >
            {dataRequest?.constructionName
              ? helpers.formatTextLong(
                  dataRequest?.constructionName,
                  MAX_LENGTH
                )
              : ""}
          </Tooltip>
        </div>
      ) : null}
      <div className="am010__calendar-unit-content">
        {dataRequest?.type === TYPE_REQUEST.CHECKIN_CHECKOUT.type ? (
          <>
            <div className="am010__calendar-attendance">
              出勤：
              <span
                style={{
                  fontWeight: "700",
                  fontSize: 12,
                }}
              >
                {dataRequest?.checkin
                  ? moment(dataRequest?.checkin).format(COMMON.FORMAT_TIME1)
                  : "-"}
              </span>
            </div>
            <img src={lineBreakIcon} alt="" />
            <div className="am010__calendar-attendance">
              退勤：
              <span
                style={{
                  fontWeight: "700",
                  fontSize: 12,
                }}
              >
                {dataRequest?.checkout &&
                moment(dataRequest?.checkout).format(COMMON.FORMAT_DATE) !=
                  moment(dataRequest?.date).format(COMMON.FORMAT_DATE) ? (
                  <span
                    style={{
                      fontWeight: "400",
                      fontSize: 12,
                    }}
                  >
                    {moment(dataRequest?.checkout).format("M/D")}
                  </span>
                ) : (
                  <></>
                )}
                &nbsp;
                {dataRequest?.checkout
                  ? moment(dataRequest?.checkout).format(COMMON.FORMAT_TIME1)
                  : "-"}
              </span>
              &nbsp;
              {dataRequest?.isMultipleCheckInOut ? (
                <img
                  style={{ width: 15, height: 15, marginTop: "-4px" }}
                  src={dangerCircle}
                  alt=""
                  onClick={() => {
                    handler.clickViewDetailShift(dataRequest);
                  }}
                />
              ) : null}
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                fontWeight: 700,
                fontSize: 12,
              }}
              className="am010__calendar-attendance"
            >
              {dataRequest?.type === TYPE_REQUEST.ATTENDANCE.type
                ? TYPE_REQUEST.ATTENDANCE.title
                : dataRequest?.type === TYPE_REQUEST.LATE_EARLY.type
                ? TYPE_REQUEST.LATE_EARLY.title
                : dataRequest?.type === TYPE_REQUEST.LEAVE.type
                ? TYPE_REQUEST.LEAVE.title
                : ""}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
