import { useState } from "react";
const Handler = () => {
  const [reason, setReason] = useState<null | string>(null);

  const handleDataListApproval = (listApprover: any[], currentUser: any) => {
    if (!listApprover) return [];

    //sort Data
    listApprover.sort((a: any, b: any) => {
      return a?.priority - b?.priority;
    });

    //handle data long
    let results = [...listApprover];
    const found = results.find((item: any) => item.isSubmitter)
    if (found) {
      const data = results
      const returnFromSplice = data.splice(1, 1, found)[0]
      data[0] = returnFromSplice
      results = data
    }
    const lengthApprove = results?.length;

    if (lengthApprove > 8) {
      const findCurrentUser = results.findIndex(
        (element: any) => currentUser?.id === element?.id
      );
      if (findCurrentUser <= (lengthApprove - 1) / 2) {
        const newResults = results.fill(
          "...",
          (lengthApprove - 1) / 2 + 1,
          lengthApprove - 2
        );
        return [...new Set(newResults)];
      } else {
        const newResults = results.fill("...", 1, (lengthApprove - 1) / 2);
        return [...new Set(newResults)];
      }
    }

    return results;
  };

  return {
    reason,
    setReason,
    handleDataListApproval,
  };
};

export default Handler;
