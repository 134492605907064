// Core library
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { RootState } from "../../../../../store";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { setLoading } from "../../../../../common/slice/CommonSlice";

// Services
import {
  AM002ServiceImpl,
  ConstructionServiceImpl,
} from "./../../usecase/ServiceImpl";

// Entity
import { ConstructionType } from "../../entity/Entity";
import { ExportDataAM002 } from "./../../../../../common/entity/Entity";

// Common
import { exportCompanyColab } from "../../../../../common/helpers/exports/company_colab";
import COMMON from "../../../../../common/constants/COMMON";
import helpers from "../../../../../common/helpers/common";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import MESSAGE, {
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import { getTitleByFromToDate } from "../../../AM008/helper";
import moment from "moment";
import { convertContentExport } from "../../helper";
import { doExportForResponse } from "../../../../../common/helpers/exports/common";

const AM002Handler = (service: AM002ServiceImpl) => {
  const dispatch = useDispatch();

  // STATE
  const [totalMoney, setTotalMoney] = useState<number>(0);
  const [totalWorkingDay, setTotalWorkingDay] = useState<number>(0);

  const [totalRecord, setTotalRecord] = useState<number>(20);
  const [listConstruction, setListConstruction] = useState<ConstructionType[]>(
    []
  );
  const [expandKey, setExpandKey] = useState<any[]>([]);

  // PARAMS
  const [searchParams, setSearchParams] = useSearchParams();
  const sortBy = searchParams.get(COMMON.PARAM_LIST.SORT_BY) || "";
  const sortType = searchParams.get(COMMON.PARAM_LIST.SORT_TYPE) || "";
  const companyList = useSelector(
    (state: RootState) => state.am002.companyList
  );
  const fromDate =
    searchParams.get("startDate") ||
    moment().startOf("month").format(COMMON.FORMAT_DATE2);
  const toDate =
    searchParams.get("endDate") || moment().format(COMMON.FORMAT_DATE2);

  // FUNCTION

  const isCollapsed = (record: any) => {
    return expandKey.some((element) => element === record.key);
  };

  const onChangeCollapseRow = (record: any, collapsed: boolean) => {
    const collapsedRow = collapsed
      ? [...expandKey, record.key]
      : expandKey.filter((element) => element !== record.key);

    setExpandKey(collapsedRow);
  };

  const handleLoadingExport = (value: boolean) => {
    dispatch(setLoading(value));
  };

  const exportData = async (params: any) => {
    const selectedCompanyId = searchParams.get("selectedCompany") ?? "";

    if (!helpers.checkIsNumber(selectedCompanyId))
      ErrorNotification(MESSAGE.MESSAGE_EXPORT_ERROR);

    // const companySelected = helpers.findElementWithId(
    //   companyList,
    //   Number(selectedCompanyId)
    // );

    // const titleContent: string[] = [
    //   `${companySelected?.name}`,
    //   `${getTitleByFromToDate(fromDate, toDate)}`,
    //   `合計金額：${totalMoney} 円`,
    // ];

    // const dataRaw: ExportDataAM002 = {
    //   title: titleContent,
    //   content: convertContentExport(listConstruction),
    // };

    // exportCompanyColab(
    //   dataRaw,
    //   `会社別勤務実績_${moment().format(COMMON.FORMAT_DATE2)}`,
    //   handleLoadingExport
    // );
    try {
      dispatch(setLoading(true));
      const companyName = searchParams.get("selectedCompanyName") ?? "";
      const response = await service.doExportAM002({
        ...params,
        companyName: companyName,
        from: helpers.getFilterTime(params.from),
        to: helpers.getFilterTimeTo(params.to),
      } as any);
      doExportForResponse(
        response,
        `会社別勤務実績_${companyName}_${moment().format(
          COMMON.FORMAT_DATE7
        )}.xlsx`
      );
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const onPageChange = async (value: number) => {
    searchParams.set(COMMON.PARAM_LIST.PAGE, value + "");
    setSearchParams(searchParams);
  };
  const onSizeChange = (value: number) => {
    searchParams.set(COMMON.PARAM_LIST.SIZE, value + "");
    searchParams.set(COMMON.PARAM_LIST.PAGE, COMMON.DEFAULT_PAGE + "");
    setSearchParams(searchParams);
  };

  const onTableChange = (__: any, _: any, sorter: any) => {
    if (sorter.order) {
      searchParams.set(COMMON.PARAM_LIST.SORT_TYPE, sorter.order);
      searchParams.set(COMMON.PARAM_LIST.SORT_BY, sorter.columnKey);
    } else {
      searchParams.delete(COMMON.PARAM_LIST.SORT_BY);
      searchParams.delete(COMMON.PARAM_LIST.SORT_TYPE);
    }
    searchParams.delete(COMMON.PARAM_LIST.PAGE);
    setSearchParams(searchParams);
  };

  const setDefaultSorter = (sortText: string) => {
    if (sortType === "") return null;
    if (sortText === sortBy) return sortType;
  };

  const getAttendanceCompanyColabSubConstruction = async (params: {
    companyId: number;
    keyword: string;
    page: string;
    size: string;
    // from: string; // 2006-01-02
    // to: string; // 2006-01-02
    sortBy?: string;
    sortType?: "DESC" | "ASC";
  }) => {
    try {
      dispatch(setLoading(true));
      const { results, pagination } =
        await service.getAttendanceCompanyColabSubConstruction(params);
      // set state
      setListConstruction(results);
      setTotalRecord(pagination.count);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getAttendanceCompanyColabTotalCost = async (params: {
    companyId: number;
    from: string; // 2006-01-02
    to: string; // 2006-01-02
  }) => {
    try {
      dispatch(setLoading(true));
      const responseData = await service.getAttendanceCompanyColabTotalCost(
        params
      );
      setTotalMoney(responseData.totalLaborCost);
      setTotalWorkingDay(responseData.totalWorkingDay);
    } finally {
      dispatch(setLoading(false));
    }
  };

  return {
    companyList,
    totalMoney,
    listConstruction,
    totalRecord,
    expandKey,
    totalWorkingDay,
    isCollapsed,
    onChangeCollapseRow,
    exportData,
    onSizeChange,
    onPageChange,
    setDefaultSorter,
    onTableChange,
    getAttendanceCompanyColabSubConstruction,
    getAttendanceCompanyColabTotalCost,
  };
};

export default AM002Handler;
