import React from 'react';
import IconDown from '../../../../../assets/icons/icon-down-small.svg';
import NoDataTableComponent from '../../../../../common/components/no-data/NoDataTable';
import { Empty } from 'antd';
import helpers from '../../../../../common/helpers/common';
import { OffsetCost } from '../../entity/Entity';
import { TAX_CODE } from '../../../../../common/constants/COMMON';
import EyeViewDetailSvg from '../../../../../assets/icons/eye-view-detail.svg';
import ProjectRowComponent from './ProjectRow';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import TooltipText from '../../../../../common/components/tooltip-text/TooltipText';

type props = {
    data: any;
    index: number;
    setCompanyExpandKeys: (key: any) => void;
    checkCompanyKeyExpand: (key: any) => boolean;
    setProjectExpandKeys: (key: any) => void;
    checkProjectKeyExpand: (key: any) => boolean;
    openCM0027_2: (value: any) => void;
    openCM0027_1: (value: any) => void;
};

const CompanyRowComponent = ({
    data,
    index,
    setCompanyExpandKeys,
    checkCompanyKeyExpand,
    setProjectExpandKeys,
    checkProjectKeyExpand,
    openCM0027_2,
    openCM0027_1,
}: props) => {
    const isShowOffset = useSelector((state: RootState) => state.cm0027.isShowOffset);

    return (
        <>
            <tr className="row-level-0 button-style" onClick={() => setCompanyExpandKeys(data.id)}>
                <td colSpan={2}>{index + 1}</td>
                <td>
                    <div style={{ width: 150 }}>
                        <TooltipText content={data?.projectName ?? ''} />
                    </div>
                </td>
                <td></td>
                <td></td>
                <td>{`${helpers.formatNumberMoney(data.actualCost)}円`}</td>
                {isShowOffset ? (
                    <>
                        <td>{`${helpers.formatNumberMoney(data.offsetValue)}円`}</td>
                        <td>{`${helpers.formatNumberMoney(data.offsetValueTaxFree)}円`}</td>
                        <td>{`${helpers.formatNumberMoney(data.offsetSummaryValue)}円`}</td>
                        <td>{`${helpers.formatNumberMoney(data.offsetSummaryValueTaxFree)}円`}</td>
                    </>
                ) : (
                    <></>
                )}
                <td>{`${helpers.formatNumberMoney(data.totalOffset)}円`}</td>
                <td>{`${helpers.formatNumberMoney(data.paymentAmountExcludingTax)}円`}</td>
                <td>{`${helpers.formatNumberMoney(data.consumptionTax)}円`}</td>
                <td>{`${helpers.formatNumberMoney(data.paymentAmountIncludingTax)}円`}</td>

                <td>
                    <img
                        alt=""
                        src={IconDown}
                        className={`cm024-icon ${
                            !checkCompanyKeyExpand(data.id) ? '' : 'cm024-icon-active'
                        }`}
                    />
                </td>
            </tr>
            {checkCompanyKeyExpand(data.id) ? (
                <ProjectRowComponent
                    openCM0027_1={openCM0027_1}
                    openCM0027_2={openCM0027_2}
                    checkProjectKeyExpand={checkProjectKeyExpand}
                    setProjectExpandKeys={setProjectExpandKeys}
                    constructions={data.constructions}
                />
            ) : (
                <></>
            )}
            <tr style={{ height: 4 }}></tr>
        </>
    );
};

export default CompanyRowComponent;
