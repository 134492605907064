import { useState } from "react";
import { useDispatch } from "react-redux";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import { CRT001UseCase } from "../../usecase/ServiceImpl";
import { NOTIFICATION_TITLE } from "../../../../../common/constants/MESSAGE";
import moment from "moment";
import COMMON from "../../../../../common/constants/COMMON";
import helpers from "../../../../../common/helpers/common";

const handleCalculator = (numerator: number, denominator: number) => {
  if (!numerator || !denominator || denominator === 0) return 0.0;
  return (numerator / denominator) * 100;
};

const DEFAULT_TOTAL = {
  totalDirect: {},
  totalMate: {},
  totalOutsource: {},
  totalLabor: {},
  totalIndirect: {},
  profit: {},
  totalAll: {},
};

const CRT001Handler = (cr003Service: CRT001UseCase) => {
  const dispatch = useDispatch();
  const [directList, setDirectList] = useState<any[]>([]);
  const [materialCostList, setMaterialCostList] = useState<any[]>([]);
  const [subcontractCostList, setSubcontractCostList] = useState<any[]>([]);
  const [laborCostList, setLaborCostList] = useState<any[]>([]);
  const [listIndirect, setListIndirect] = useState<any[]>([]);
  const [total, setTotal] = useState<any>(DEFAULT_TOTAL);
  const [dataBasic, setDataBasic] = useState<any>({
    main: {},
    other: [],
    totalContract: 0,
  });
  const [collapseChange, setCollapseChange] = useState<any[]>([]);

  const getCRT001Data = async (params: any) => {
    dispatch(setLoading(true));
    try {
      const response = await cr003Service.getCRT001Data(params);
      handleData(response.data.results);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleMapDataPercent = (
    listRaw: any[],
    contract: number,
    constructionName: string
  ) => {
    if (!listRaw) return [];

    const results = listRaw.map((element: any) => {
      const obj = {
        ...element,
        constructionName: constructionName,
        constructionNameChild: element.constructionName || "",
      };

      if (element.budgetMoney) {
        obj.budgetMoneyPercent = handleCalculator(
          element.budgetMoney,
          contract
        );
      }
      if (element.orderMoney) {
        obj.orderMoneyPercent = handleCalculator(element.orderMoney, contract);
      }
      return obj;
    });

    return results;
  };

  const handleDataTotal = (
    raw: any,
    contractMoney: number,
    ...listKey: any
  ) => {
    if (!raw) return {};
    const results: any = {};
    listKey.forEach((key: string) => {
      results[key] = raw.reduce((acc: any, cur: any) => {
        if (cur[key]) {
          return acc + cur[key];
        }
        return acc;
      }, 0);
    });

    if (results.budgetMoney) {
      results.budgetMoneyPercent = handleCalculator(
        results.budgetMoney,
        contractMoney
      );
    }

    if (results.orderMoney) {
      results.orderMoneyPercent = handleCalculator(
        results.orderMoney,
        contractMoney
      );
    }

    return results;
  };

  const handleData = (raw: any) => {
    let resultsDirect: any[] = [];
    let resultsMate: any[] = [];
    let resultSubs: any[] = [];
    let resultsLabor: any[] = [];
    let listIndirect: any[] = [];

    const rawData: any = [];

    raw.forEach((element: any) => {
      if (element?.constructionTypeCode === "main") {
        rawData.unshift(element);
      } else rawData.push(element);
    });

    const totalContractMoney = rawData.reduce(
      (accumulated: any, currentValue: any) => {
        if (currentValue?.estimateContract[0]?.contractMoney) {
          return accumulated + currentValue?.estimateContract[0]?.contractMoney;
        }
        return accumulated;
      },
      0
    );

    rawData.forEach((element: any) => {
      const contractMoney =
        element?.estimateContract?.length > 0
          ? element?.estimateContract[0]?.contractMoney
          : 0;

      const meteBonusPercent = handleMapDataPercent(
        element.materialRegister,
        contractMoney,
        element.constructionDescription
      );
      const outsourceBonusPercent = handleMapDataPercent(
        element.outsourceRegister,
        contractMoney,
        element.constructionDescription
      );
      const laborBonusPercent = handleMapDataPercent(
        element.laborRegister,
        contractMoney,
        element.constructionDescription
      );
      const indirectBonusPercent = handleMapDataPercent(
        element.indirectConstruct,
        contractMoney,
        element.constructionDescription
      );

      const directCost = handleDataTotal(
        [
          ...element.materialRegister,
          ...element.outsourceRegister,
          ...element.laborRegister,
        ],
        contractMoney,
        "estimateMoney",
        "costMoney",
        "budgetMoney",
        "orderMoney"
      );
      directCost.constructionName = element.constructionDescription;

      resultsDirect = [...resultsDirect, directCost];
      resultsMate = [...resultsMate, ...meteBonusPercent];
      resultSubs = [...resultSubs, ...outsourceBonusPercent];
      resultsLabor = [...resultsLabor, ...laborBonusPercent];
      listIndirect = [...listIndirect, ...indirectBonusPercent];
    });

    setDirectList(resultsDirect);
    setMaterialCostList(resultsMate);
    setSubcontractCostList(resultSubs);
    setLaborCostList(resultsLabor);
    setListIndirect(listIndirect);

    const totalMate = handleDataTotal(
      resultsMate,
      totalContractMoney,
      "estimateMoney",
      "costMoney",
      "budgetMoney",
      "orderMoney"
    );
    const totalOutsource = handleDataTotal(
      resultSubs,
      totalContractMoney,
      "estimateMoney",
      "costMoney",
      "budgetMoney",
      "orderMoney"
    );
    const totalLabor = handleDataTotal(
      resultsLabor,
      totalContractMoney,
      "estimateMoney",
      "costMoney",
      "budgetMoney"
    );
    const totalIndirect = handleDataTotal(
      listIndirect,
      totalContractMoney,
      "estimateMoney",
      "costMoney",
      "budgetMoney"
    );
    const totalDirect = handleDataTotal(
      [...resultsMate, ...resultSubs, ...resultsLabor],
      totalContractMoney,
      "estimateMoney",
      "costMoney",
      "budgetMoney",
      "orderMoney"
    );

    const totalAll = handleDataTotal(
      [...resultsMate, ...resultSubs, ...resultsLabor, ...listIndirect],
      totalContractMoney,
      "estimateMoney",
      "costMoney",
      "budgetMoney",
      "orderMoney"
    );

    const profit = {
      expectGross: totalContractMoney - totalAll?.budgetMoney ?? 0,
      grossProfit: handleCalculator(
        totalContractMoney - totalAll?.budgetMoney ?? 0,
        totalContractMoney
      ),
    };

    setTotal({
      totalDirect,
      totalMate,
      totalOutsource,
      totalLabor,
      totalIndirect,
      profit,
      totalAll,
    });

    const mainResults = {
      main: raw.find(
        (element: any) => element?.constructionTypeCode === "main"
      ),
      other: raw.filter(
        (element: any) => element?.constructionTypeCode !== "main"
      ),
      totalContract: totalContractMoney,
    };
    setDataBasic(mainResults);
  };

  const checkFormatValue = (value: number, isPercent: boolean) => {
    if (!value) {
      return "";
    }
    if (!isPercent) {
      return Number(value)?.toLocaleString("en-US", {
        maximumFractionDigits: 2,
      });
    }
    if (Number(value) < 0) {
      return `(-${helpers.funcFormatter(Math.abs(value), 2)}%)`;
    }
    return `(${helpers.funcFormatter(value, 2)}%)`;
  };

  const checkFormatDate = (date1: string, date2: string) => {
    if (!date1 || (!date2 && date2 !== "")) {
      return "";
    }
    if (date2 === "") return `${moment(date1).format(COMMON.FORMAT_DATE)}`;
    return `${moment(date1).format(COMMON.FORMAT_DATE)} - ${moment(
      date2
    ).format(COMMON.FORMAT_DATE)}`;
  };

  const handleChangeCollapse = (value: string[], collapse: string) => {
    if (value?.length === 0) {
      const results = collapseChange.filter(
        (element: string) => element !== collapse
      );
      setCollapseChange(results);
    } else {
      const found = collapseChange.some(
        (element: string) => element === collapse
      );
      if (!found) {
        setCollapseChange([...collapseChange, ...value]);
      }
    }
  };

  const handleCheckDisplayCollapse = (
    listCollapse: string[],
    collapse: string
  ) => {
    return listCollapse.some((element: string) => element === collapse);
  };

  return {
    getCRT001Data,
    checkFormatValue,
    checkFormatDate,
    handleChangeCollapse,
    handleCheckDisplayCollapse,

    directList,
    materialCostList,
    subcontractCostList,
    laborCostList,
    listIndirect,
    total,
    dataBasic,
    collapseChange,
  };
};

export default CRT001Handler;
