
  import { RequestCreateLateEarly, RequestUpdateLateEarly } from "../entity/Entity";
import { AM018Api } from "./ServiceImpl";
  
  const AM018Service = (am018: AM018Api) => {
    const createLateEarly= async (data:RequestCreateLateEarly): Promise<any> => {
      try{
       return await am018.createLateEarly(data);
      }catch(error){
       throw error;
      }
     };

     const updateLateEarly= async (data:RequestUpdateLateEarly): Promise<any> => {
      try{
       return await am018.updateLateEarly(data);
      }catch(error){
       throw error;
      }
     };
 
     const getLateEarlyCategory= async (): Promise<any> => {
       try{
         return await am018.getLateEarlyCategory();
       }catch(error){
        throw error;
       }
      };
   
      const getProjects = async (params: any): Promise<any> => {
       try {
         return await am018.getProjects(params);
       } catch (error) {
         throw error;
       }
     };

     const getRequestLateEarly = async(params: any) : Promise<any> =>{
      try{
        return await am018.getRequestLateEarly(params);
      }
      catch(error){
        throw(error);
      }
     }

     const getAttendanceUserConstruction = async (params:any): Promise<any> => {
      try{
        return await am018.getAttendanceUserConstruction(params);
      }catch(error){
        throw(error)
      }
    };

    const getProjectById = async (params:any): Promise<any> => {
      try{
        return await am018.getProjectById(params);
      }catch(error){
        throw(error)
      }
    };

    const getRequestApprover = async (): Promise<any> => {
      try{
        return await am018.getRequestApprover();
      }catch(error){
        throw(error)
      }
    };

    const getProjectStatus = async (params: any): Promise<any> => {
      try{
        return await am018.getProjectStatus(params);
      }catch(error){
        throw(error)
      }
    };
      
     return {
        createLateEarly,
        updateLateEarly,
        getLateEarlyCategory,
        getProjects,
        getRequestLateEarly,
        getAttendanceUserConstruction,
        getProjectById,
        getRequestApprover,
        getProjectStatus
     };
  };
  
  export default AM018Service;
  