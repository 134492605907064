import { PM005Api } from './ServiceImpl';

const PM005Service = (pM002Api: PM005Api) => {
    const getDetailProject = async (params: any): Promise<any> => {
        try {
            return await pM002Api.getDetailProject(params);
        } catch (error) {
            throw error;
        }
    };

    const postAddConstruction = async (params: { projectId: number }): Promise<any> => {
        try {
            return await pM002Api.postAddConstruction(params);
        } catch (error) {
            throw error;
        }
    };

    const getPdfMap = async (params: any): Promise<any> => {
        try {
            return await pM002Api.getPdfMap(params);
        } catch (error) {
            throw error;
        }
    };

    const deleteConstruction = async (data: any): Promise<any> => {
        try {
            return await pM002Api.deleteConstruction(data);
        } catch (error) {
            throw error;
        }
    };

    const updateNameConstruction = async (data: any): Promise<any> => {
        try {
            return await pM002Api.updateNameConstruction(data);
        } catch (error) {
            throw error;
        }
    };

    const getNoteConstruction = async (params: any): Promise<any> => {
        try {
            return await pM002Api.getNoteConstruction(params);
        } catch (error) {
            throw error;
        }
    };
    const updateNoteConstruction = async (data: any): Promise<any> => {
        try {
            return await pM002Api.updateNoteConstruction(data);
        } catch (error) {
            throw error;
        }
    };

    const getApprovalProcessCR = async (params: any): Promise<any> => {
        try {
            return await pM002Api.getApprovalProcessCR(params);
        } catch (error) {
            throw error;
        }
    };

    const getApprovalProcessCI = async (params: any): Promise<any> => {
        try {
            return await pM002Api.getApprovalProcessCI(params);
        } catch (error) {
            throw error;
        }
    };

    return {
        getDetailProject,
        postAddConstruction,
        getPdfMap,
        deleteConstruction,
        updateNameConstruction,
        getNoteConstruction,
        updateNoteConstruction,
        getApprovalProcessCI,
        getApprovalProcessCR,
    };
};

export default PM005Service;
