import React from 'react';
import { Form, Select } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { useEffect, useState } from 'react';
import { Rule } from 'antd/lib/form';
import { PropsInfiniteScrollSelectComponent } from '..';
import { HandlerImpl } from '../handler/HandlerImpl';

const { Option } = Select;

interface UIProps extends PropsInfiniteScrollSelectComponent {
    handler: HandlerImpl
}

const MainUI = ({
    handler,
    initialData,
    initialId,
    isFormCol,
    isCal50,
    rules,
    formLabel,
    formName,
    className,
    fetchData,
    modeProp,
    widthProp,
    valueProp,
    nameProp,
    placeholderProp,
    handleChangeProp,
    sizeProp,
    disabled,
    isReponsive = false,
    required = false,
}: UIProps) => {

    useEffect(() => {
        (async () => {
            await handler.loadMoreData({ fetchData });
        })();
    }, []);

    const formCol = !isFormCol
        ? {
            labelCol: { span: 6 },
            wrapperCol: { span: 18 },
        }
        : {};

    return (
        <Form.Item
            name={formName}
            {...formCol}
            style={{
                display: 'inline-block',
                width: !isCal50 ? 'calc(50%)' : '100%',
                marginBottom: 16,
            }}
            labelAlign="right"
            colon={false}
            className="form-item-label "
            label={
                <div className="w-100-percent pr-20 form-label-member">
                    {formLabel}
                    {required ? <span style={{ color: '#FE0909' }}>*</span> : <></>}
                </div>
            }
            rules={rules}
        >
            <Select
                getPopupContainer={(trigger: any) => trigger.parentNode}
                className={className}
                disabled={disabled ?? false}
                size={sizeProp}
                mode={modeProp}
                style={{ width: widthProp ?? '100%' }}
                placeholder={placeholderProp}
                onChange={handleChangeProp}
                onPopupScroll={(event) => handler.onScroll({ event, fetchData })}
                showSearch={false}
                maxTagCount={isReponsive ? 'responsive' : undefined}
                showArrow={true}
            >
                {!handler.loading
                    ? handler.data.map((element: any) => (
                        <Option
                            key={element[`${valueProp}`]}
                            value={element[`${valueProp}`] + ''}
                        >
                            {element[`${nameProp}`]}
                        </Option>
                    ))
                    : [
                        ...handler.data.map((element: any) => (
                            <Option
                                key={element[`${valueProp}`]}
                                value={element[`${valueProp}`] + ''}
                            >
                                {element[`${nameProp}`]}
                            </Option>
                        )),
                        <Option key="loading">Loading...</Option>,
                    ]}
            </Select>
        </Form.Item>
    );
};

export default MainUI;
