import CM0032Api from './infrastructure/api/Api';
import CM0032Handler from './presenter/handler/Handler';
import CM0032UI from './presenter/ui/UI';
import CM0032Service from './usecase/Service';
import '../../../assets/styles/CM/CM0032.css';

const CM0032Component = () => {
    const cm0032Api = CM0032Api();
    const cm0032Service = CM0032Service(cm0032Api);
    const cm0032Handler = CM0032Handler(cm0032Service);
    return <CM0032UI handler={cm0032Handler} />;
};

export default CM0032Component;
