 const BORDER_STYLE_CELL: any = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
};

const BORDER_STYLE_CELL_DOUBLE: any = {
    top: { style: 'double' },
    left: { style: 'double' },
    bottom: { style: 'double' },
    right: { style: 'double' },
};

const borderOuterOption = (top: string, right: string, bottom: string, left: string) => {
    const obj = {
        top     : { style: top , color: { argb: '000' } },
        right   : { style: right , color: { argb: '000' }  },
        bottom  : { style: bottom  , color: { argb: '000' } },
        left    : { style: left    , color: { argb: '000' } },
    }
    return obj
}


const getColumnNameWithIndex = (columnNumber: number): string => {
    // To store result (Excel column name)
    const columnName = [];

    while (columnNumber > 0) {
        // Find remainder
        const rem = columnNumber % 26;
        if (rem === 0) {
            columnName.push('Z');
            columnNumber = Math.floor(columnNumber / 26) - 1;
        } else {
            columnName.push(String.fromCharCode(rem - 1 + 'A'.charCodeAt(0)));
            columnNumber = Math.floor(columnNumber / 26);
        }
    }

    // Reverse the string and print result
    return columnName.reverse().join('').trim();
};

const mappingTitle = (ws: any, start: number, end: number, content: string[]) => {
    const titleStyle: any[] = [];

    for (let i = start; i <= end; i++) {
        titleStyle.push(`A${i}`);
        ws.getCell(`A${i}`).value = content[i - 1];
    }

    titleStyle.forEach((element: any) => {
        ws.getCell(element).font = {
            name: 'Arial Black',
            size: 12,
            color: { argb: '000' },
            bold: true,
        };
    });

    const rowTitle = ws.getRows(start, end);
    rowTitle?.forEach((element: any) => {
        element.height = 20;
    });
};

const adjustWithColumn = (ws: any, items: any[], value: number) => {
    items.forEach((element: any) => {
        ws.getColumn(element).width = value;
    });
};

const adjustBorderCell = (ws: any, items: any[], borderStyle: any) => {
    items.forEach((element: any) => {
        ws.getCell(element).border = borderStyle;
    });
};

const adjustMergeCell = (ws: any, items: any[]) => {
    items.forEach((element: any) => {
        ws.mergeCells(element);
    });
};

const findObjectInArray = (id: number, arr: any[]) => {
    return arr.find((e: any) => e.id === id);
};

const adjustElementCenter = (ws: any, items: any[]) => {
    items.forEach((item: any) => {
        ws.getCell(`${item}`).alignment = { vertical: 'middle', horizontal: 'center'};
    });
};

const adjustElementLeft = (ws: any, items: any[]) => {
    items.forEach((item: any) => {
        ws.getCell(`${item}`).alignment = { vertical: 'top', horizontal: 'left' };
    });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const drawTableInformation = (
    ws: any,
    indexStart: number,
    title: string,
    numberColumn: number,
    cellWidth: number,
    borderStyle: any,
) => {
    ws.getCell(`A${indexStart}`).value = `${title}`;
    // Merge title header
    ws.mergeCells(`A${indexStart}:${getColumnNameWithIndex(numberColumn)}${indexStart}`);
    // Add border Cell
    adjustBorderCell(ws, [`A${indexStart}`], borderStyle);
    // Adjust width cell
    const items: string[] = [];
    for (let i = 1; i <= numberColumn; i++) {
        items.push(`${getColumnNameWithIndex(i)}`);
    }
    adjustWithColumn(ws, items, cellWidth);

    ws.getCell(`A${indexStart}`).font = { bold: false };
};

const adjustFontFamily = (ws: any, columns: string[]) => {
    columns.forEach((item: string) => {
        for(let i = 1; i<= 18; i++) {
            if (i == 2) {
                ws.getCell(`${item}${i}`).font = { bold: true, size: 14, name: 'Yu Gothic' }
            } else {
                ws.getCell(`${item}${i}`).font = { name: 'Yu Gothic', size: 11, bold: false }
            }
        }
    })
}


const adjustHeightRows = (ws: any, start: number, end: number, heightRow: number) => {
    
    for (let i = start; i <= end; i++) {
        ws.getRow(i).height = heightRow
    }
};

function doExportForResponse(response: any, fileName: string) {
    // Create a temporary URL for the blob
    const url = window.URL.createObjectURL(new Blob([response.data]));
 
    // Create a hidden <a> element to trigger the download
    const a = document.createElement('a');
    a.href = url;
 
    // Extract the filename from the 'Content-Disposition' header (if available)
    const contentDisposition = response.headers['content-disposition'];

    if (contentDisposition) {

      const match = contentDisposition.match(/filename="(.+)"/);

      if (match) {
        a.download = match[1];
      }
    }

    // If 'Content-Disposition' header is not available, specify a default filename
    if (!a.download) {
      a.download = fileName; // Specify a default filename
    }

    document.body.appendChild(a);

    // Trigger the download
    a.click();

    // Clean up
    window.URL.revokeObjectURL(url);
 }

export {
    getColumnNameWithIndex,
    adjustBorderCell,
    adjustWithColumn,
    mappingTitle,
    adjustMergeCell,
    findObjectInArray,
    adjustElementCenter,
    adjustElementLeft,
    drawTableInformation,
    adjustHeightRows,
    borderOuterOption,
    adjustFontFamily,
    doExportForResponse,
    BORDER_STYLE_CELL,
    BORDER_STYLE_CELL_DOUBLE,
};
