import React, { useMemo } from 'react';
import { CM0028Hanlder } from '../handler/HandlerImpl';
import IconDown from '../../../../../assets/icons/icon-down-small.svg';
import IconView from '../../../../../assets/icons/eyeIconBlack.svg';
import SortTable from '../../../../../common/components/sort-table/SortTable';
import { COLUMN_SORT } from '../handler/Handler';
import ProjectRowComponent from './ProjectRow';
import helpers from '../../../../../common/helpers/common';
import { Empty } from 'antd';

interface props {
    projects: any[];
    summaryTotal: any;
    from: string;
    to: string;

    checkKeyExpand: (key: any) => boolean;
    setExpandKeys: (key: any) => void;
    handleScroll: (e: any, from: string, to: string) => Promise<void>;
}

const Table = ({
    projects,
    summaryTotal,
    checkKeyExpand,
    setExpandKeys,
    handleScroll,
    from,
    to,
}: props) => {
    return (
        <div
            className="cm024-table"
            onScroll={(e: any) => {
                handleScroll(e, from, to);
            }}
        >
            <table>
                <thead>
                    <tr>
                        <th colSpan={2}>No</th>
                        <th
                            colSpan={4}
                            style={{ minWidth: 630, textAlign: 'left', paddingLeft: 55 }}
                        >
                            案件名
                        </th>
                        <th style={{ minWidth: 150 }}>支払金額(税抜)</th>
                        <th style={{ minWidth: 150 }}>消費税</th>
                        <th style={{ minWidth: 150 }}>支払金額(税込)</th>
                        <th style={{ minWidth: 40 }}></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        projects?.length === 0 ?
                            <tr>
                                <td colSpan={10}>
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="データなし" />
                                </td>
                            </tr>
                            :
                            <>
                                {projects.map((element, index) => (
                                    <ProjectRowComponent
                                        key={element.id}
                                        checkKeyExpand={checkKeyExpand}
                                        setExpandKeys={setExpandKeys}
                                        index={index}
                                        project={element}
                                    />
                                ))}
                            </>
                    }

                </tbody>
                <tfoot>
                    <tr className="table-foot">
                        <td colSpan={2}>合計金額</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{summaryTotal.actualCost}</td>
                        <td>{summaryTotal.tax}</td>
                        <td>{summaryTotal?.taxIncluded}</td>
                        <td></td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};

export default Table;
