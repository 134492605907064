
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AM024UseCase } from "../../usecase/ServiceImpl";
import MESSAGE, {
  LABEL_MESSAGE, NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import { resetAM024 } from "../slice/Slice";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import { setIsVisibleAM027, setRequestIdAM027, setScreenRequestToAM027 } from "../../../AM027/presenter/slice/Slice";
import { setIsVisibleAM022, setRequestIdAM022, setTypeModalAM022 } from "../../../AM022/presenter/slice/Slice";

const AM024Handler = (am024Service: AM024UseCase) => {
  // LIB FUNCTION
  const dispatch = useDispatch();

  // STATE
  const [requestLeave, setRequestLeave] = useState<any>({});

  // FUNCTION

  const handleGetRequestLeave = async (requestId: number): Promise<any> => {
    try {
      const params = {
        requestId: requestId
      }
      const res = await am024Service.getRequestLeave(params);
      setRequestLeave(res.results);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  }

  const handleOpenModalConfirmDelete = (requestId: number[]) => {
    dispatch(setIsVisibleAM027(true));
    dispatch(setRequestIdAM027(requestId))
    dispatch(setScreenRequestToAM027("am024"))
  }

  const handleOpenModalUpdate = (requestId: number) => {
    dispatch(setRequestIdAM022(requestId))
    dispatch(setTypeModalAM022("update"));
    dispatch(setIsVisibleAM022(true))
  }

  const onCancel = () => {
    dispatch(resetAM024());
  };

  return {
    requestLeave,
    onCancel,
    handleOpenModalConfirmDelete,
    handleGetRequestLeave,
    handleOpenModalUpdate
  };
};

export default AM024Handler;
