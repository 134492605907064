import { Modal } from 'antd';
import React, { ReactNode } from 'react';
import { uuidv4 } from '@firebase/util';

import IconWarning from '../../../assets/icons/warning-modal.svg';
import '../../../assets/styles/components/error-modal.css';

type props = {
    icon?: ReactNode;
    okText?: string;
    cancelText?: string;
    className?: string;
    title: string;
    description: string | string[];

    onOk?: () => void;
    onCancel?: () => void;
};

const content = (content: string | string[]) => {
    if (typeof content === 'string') return content;
    return (
        <>
            {content.map((item) => (
                <div key={uuidv4()}>{item}</div>
            ))}
        </>
    );
};

const ErrorModal = ({
    icon,
    okText,
    title,
    description,
    cancelText,
    className,
    onCancel,
    onOk,
}: props) => {
    return Modal.error({
        centered: true,
        closable: true,
        className: 'error-modal ' + className,
        cancelText: cancelText,
        content: content(description),
        okText: okText,
        icon: icon ? icon : <img src={IconWarning} alt="" />,
        title: title,
        width: 498,
        zIndex: 9999,
        onOk: () => {
            if (onOk) {
                onOk();
            }
        },
        onCancel: () => {
            if (onCancel) {
                onCancel();
            }
        },
    });
};

export default ErrorModal;
