import OM004Api from "../OM004/infrastructure/api/Api";
import OM004Service from "../OM004/usecase/Service";
import OM006Api from "./infrastructure/api/Api"
import OM006Handler from "./presenter/handler/Handler";
import { MainUI } from "./presenter/ui/UI";
import OM006Service from "./usecase/Service";

const OM006Component = () => {
    const om006Api = OM006Api();
    const om004Api = OM004Api()
    const om006Service = OM006Service(om006Api, om004Api)
    const om004Service = OM004Service(om004Api)
    const om006Handler = OM006Handler(om006Service, om004Service)
    return <MainUI handler={om006Handler} />
}

export default OM006Component