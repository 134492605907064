import { Table, TableColumnsType } from 'antd';
import { DateColumn } from '../../../../entity/Entity';
import helpers from '../../../../../../../common/helpers/common';

const getColumns = (tableColumns: DateColumn[]) => {
  const columns: TableColumnsType<any> = [
    {
      title: '',
      key: 'attendanceName',
      width: '22.25%',
      align: 'center',
      render: (record) => {
        return (
          <main style={{ alignContent: 'center', width: '100%' }}>
            <div className="center-item">{record.name}</div>
            <div className="construction-name-font center-item">{`(${record.constructionName})`}</div>
          </main>
        );
      },
      onCell: () => {
        return {
          className: 'border-left-table',
        };
      },
    },
  ];
  for (let i = 0; i < tableColumns.length; i++) {
    columns.push({
      title: '',
      key: i,
      width: `${68.8 / tableColumns.length}%`,
      render: (record) => record[`${tableColumns[i].value}`],
    });
  }
  columns.push({
    title: '',
    key: 'empty1',
    width: '6.5%',
  });
  columns.push({
    title: '',
    key: 'collapsed',
    width: '2.45%',
  });
  return columns;
};

interface props {
  companyData: any[];
  columnsTable: DateColumn[];
}

const DoubleCheckUI = ({ companyData, columnsTable }: props) => {
  return (
    <Table
      className="am-main-table-construction attendance-table border-top-table double-check-border double-check-table-border "
      columns={getColumns(columnsTable)}
      dataSource={helpers.addKeyToDataSource(companyData)}
      pagination={false}
    />
  );
};

export default DoubleCheckUI;
