import { AxiosResponse } from 'axios';
import axiosConfig from '../../../../../config/axiosConfig';
import { MoneySubmitDirectCostMaterialPayload, ThresholdPutType } from '../../entity/Entity';

const MaterialCostApi = () => {
    const getMoneyDirectCostMaterialListCompany = async (params: {
        constructionId: number;
        keyword?: string;
        page?: number;
        size?: number;
        sortBy?: string;
        sortType?: string;
    }): Promise<AxiosResponse<any, any>> => {
        const url = '/money/direct-cost/material/list-company';
        return await axiosConfig.get(url, { params });
    };
    const putMoneyDirectCostMaterialEnable = async (
        data: ThresholdPutType,
    ): Promise<AxiosResponse<any, any>> => {
        const url = '/money/direct-cost/material/enable';
        return await axiosConfig.put(url, data);
    };
    const getMoneyDirectCostMaterialList = async (params: {
        documentDirectMaterialCostId: number;
        page?: number;
        size?: number;
        sortBy?: string;
        sortType?: string;
    }): Promise<AxiosResponse<any, any>> => {
        const url = '/money/direct-cost/material/list';
        return await axiosConfig.get(url, { params });
    };
    const putMoneySubmitDirectCostMaterial = async (
        data: MoneySubmitDirectCostMaterialPayload,
    ): Promise<AxiosResponse<any, any>> => {
        const url = '/money/submit/direct-cost/material';
        return await axiosConfig.put(url, data);
    };
    /**
     * 
     * @param params 
     * @returns 
     */
    const doExportCM007 = async (params: any): Promise<any> => {
        try {
            const url = '/money/exportCM007';
            const response = await axiosConfig.get(url, { params, responseType: 'blob'  });
            
            return response;
        } catch (error) {
            throw error;
        }
    };
    return {
        getMoneyDirectCostMaterialListCompany,
        putMoneyDirectCostMaterialEnable,
        getMoneyDirectCostMaterialList,
        putMoneySubmitDirectCostMaterial,
        doExportCM007,
    };
};

export default MaterialCostApi;
