import { Collapse } from "antd";

import BuildingSVG from "../../../../../assets/icons/building.svg";
import HomeBuilding from "../../../../../assets/icons/home-building.svg";
import DocumentBlue from "../../../../../assets/icons/document-blue.svg";
import { CRT001BasicUIHandler } from "../handler/HandlerImpl";
import IconDown from "../../../../../assets/icons/arrow.svg";

const { Panel } = Collapse;

interface props {
  handler: CRT001BasicUIHandler;
}

function CRTBasicInfo({ handler }: props) {
  return (
    <div className="crt001-basic-info">
      <h4 className="crt001-basic-info-title">工事一覧</h4>
      <Collapse
        className="cr001-collapse folder-collapse crt-collapse-padding crt-bg-main"
        onChange={(key) => handler.handleChangeCollapse(key, "basicInfo")}
        expandIcon={({ isActive }) => {
          return (
            <div
              className={`${
                isActive ? "collapse-icon-active" : ""
              } collapse-icon`}
            >
              <img src={IconDown} alt="" />
            </div>
          );
        }}
      >
        <Panel
          key="basicInfo"
          className="crt-collapse"
          header={
            <div className="crt001-header">
              <div className="crt001-header-left">
                <img alt="" src={BuildingSVG} />
                <span>{handler.dataBasic?.main?.constructionDescription}</span>
              </div>
              <div className="crt001-header-right">
                {!handler.handleCheckDisplayCollapse(
                  handler.collapseChange,
                  `basicInfo`
                ) && (
                  <>
                    <div className="crt001-header-right-item">
                      <span className="crt001-header-right-item-label">
                        契約金額:
                      </span>
                      <span className="crt001-header-right-item-text">
                        {handler.dataBasic?.main?.estimateContract
                          ? handler.checkFormatValue(
                              handler.dataBasic?.main?.estimateContract[0]
                                ?.contractMoney,
                              false
                            )
                          : ""}
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
          }
        >
          <ul className="crt001-basic-list">
            <li className="crt001-basic-item">
              工事名: <span>{handler.dataBasic?.main?.constructionName}</span>
            </li>
            <li className="crt001-basic-item">
              工事番号:{" "}
              <span>{handler.dataBasic?.main?.constructionNumber}</span>
            </li>
            <li className="crt001-basic-item">
              見積番号: <span>{handler.dataBasic?.main?.quoteNumber}</span>
            </li>
            <li className="crt001-basic-item">
              工期:{" "}
              <span>
                {handler.checkFormatDate(
                  handler.dataBasic?.main?.startTime,
                  handler.dataBasic?.main?.endTime
                )}
              </span>
            </li>
            <li className="crt001-basic-item">
              契約金額:{" "}
              <span>
                {handler.dataBasic?.main?.estimateContract
                  ? handler.checkFormatValue(
                      handler.dataBasic?.main?.estimateContract[0]
                        ?.contractMoney,
                      false
                    )
                  : ""}
              </span>
            </li>
          </ul>
        </Panel>
      </Collapse>
      <div className="crt-basic-child">
        {handler.dataBasic?.other?.map((element: any, index) => {
          return (
            <Collapse
              key={`addition${index}`}
              className="cr001-collapse folder-collapse crt001-collapse-item crt-collapse-padding crt-bt-child"
              onChange={(key) =>
                handler.handleChangeCollapse(key, `addition${index}`)
              }
              expandIcon={({ isActive }) => {
                return (
                  <div
                    className={`${
                      isActive ? "collapse-icon-active" : ""
                    } collapse-icon`}
                  >
                    <img src={IconDown} alt="" />
                  </div>
                );
              }}
            >
              <Panel
                key={`addition${index}`}
                header={
                  <div className="crt001-header">
                    <div className="crt001-header-left">
                      <img alt="" src={HomeBuilding} />
                      <span>{element?.constructionDescription}</span>
                    </div>
                    <div className="crt001-header-right">
                      {!handler.handleCheckDisplayCollapse(
                        handler.collapseChange,
                        `addition${index}`
                      ) && (
                        <>
                          <div className="crt001-header-right-item">
                            <span className="crt001-header-right-item-label">
                              契約金額:
                            </span>
                            <span className="crt001-header-right-item-text">
                              {element?.estimateContract
                                ? handler.checkFormatValue(
                                    element?.estimateContract[0]?.contractMoney,
                                    false
                                  )
                                : ""}
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                }
              >
                <ul className="crt001-basic-list">
                  <li className="crt001-basic-item">
                    工事名: <span>{element?.constructionName}</span>
                  </li>
                  <li className="crt001-basic-item">
                    工事番号: <span>{element.constructionNumber}</span>
                  </li>
                  <li className="crt001-basic-item">
                    見積番号: <span>{element?.quoteNumber}</span>
                  </li>
                  <li className="crt001-basic-item">
                    工期:{" "}
                    <span>
                      {handler.checkFormatDate(
                        element?.startTime,
                        element?.endTime
                      )}
                    </span>
                  </li>
                  <li className="crt001-basic-item">
                    契約金額:{" "}
                    <span>
                      {element?.estimateContract
                        ? handler.checkFormatValue(
                            element?.estimateContract[0]?.contractMoney,
                            false
                          )
                        : ""}
                    </span>
                  </li>
                </ul>
              </Panel>
            </Collapse>
          );
        })}

        <div
          className={`crt-item-other ${
            handler.dataBasic?.other?.length === 0 && "crt-item-other-not-child"
          }`}
        >
          <img alt="" src={DocumentBlue} />
          <span className="crt-item-other-label">合計契約金額</span>
          <span className="crt-item-other-number">
            {handler.checkFormatValue(handler.dataBasic.totalContract, false)}
          </span>
        </div>
      </div>
    </div>
  );
}

export default CRTBasicInfo;
