import { useEffect } from "react";
import BreadcrumbComponent from "../../../../../common/components/breadcrumb/BreadcrumbComponent";
import BREADCRUMB from "../../../../../common/constants/BREADCRUMB";
import { PM003UIHandler } from "../handler/HandlerImpl";
import DataTable from "./DataTable";
import { useLocation } from "react-router-dom";
import PM003HeaderUI from "./Header";
import PM003Pagination from "./Pagination";
import "../../../../../assets/styles/PM/PM003.css";
import MenuWrapperComponent from "../../../../../common/components/menu-wrapper";
import PdfModal from "./PdfModal";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import PM008UI from "../../../PM008/presenter/ui/UI";
import { PM008Hanlder } from "../../../PM008/presenter/handler/HandlerImpl";
import PM009Component from "../../../PM009";
import ImageViewerModalComponent from "../../../../../common/components/image-viewer-modal/ImageViewerComponent";
import PdfViewerModalComponent from "../../../../../common/components/pdf-viewer-modal/PdfViewerModalComponent";
import PM012Component from "../../../PM012";
interface props {
  handler: PM003UIHandler;
  modalHandler: PM008Hanlder;
}

const PM003UI = ({ handler, modalHandler }: props) => {
  const isPM008ModalOpen = useSelector(
    (state: RootState) => state.pm008.isModalOpen
  );

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const workspaceId = params.get("workspaceId");
  const statusId = params.get("statusId");
  const keyword = params.get("keyword");
  const page = params.get("page");
  const size = params.get("size");
  const refreshNotification = useSelector(
    (state: RootState) => state.common.refreshNotification
  );
  const refreshNoti = useSelector(
    (state: RootState) => state.pm009.refreshNoti
  );
  const isOpen = useSelector((state: RootState) => state.pm012.isOpen);
  const refreshPM012 = useSelector(
    (state: RootState) => state.pm012.refreshPM012
  );
  const data = useSelector((state: RootState) => state.pm003.data);

  useEffect(() => {
    handler.getData(handler.onParams());
  }, [
    workspaceId,
    statusId,
    keyword,
    page,
    size,
    refreshNotification,
    refreshNoti,
    refreshPM012,
    handler.refresh,
  ]);

  //   useEffect(() => {
  //     localStorage.removeItem("parentId");
  //   }, []);

  return (
    <MenuWrapperComponent chosenKey={81} openKey={8}>
      <BreadcrumbComponent data={BREADCRUMB.PM003} />
      <PM003HeaderUI handler={handler} />
      <div className="pm003-body">
        <DataTable handler={handler} />
        {data?.length > 0 ? <PM003Pagination handler={handler} /> : null}
      </div>
      <PM008UI handler={modalHandler} funcAfter={handler.funcAfter} />
      <PM009Component />
      {!isPM008ModalOpen ? (
        <>
          <ImageViewerModalComponent
            imageURL={handler.pdfUrl}
            title="現場案内図"
          />
          <PdfViewerModalComponent pdfURL={handler.pdfUrl} title="現場案内図" />
        </>
      ) : (
        <></>
      )}
      {isOpen ? <PM012Component /> : <></>}
    </MenuWrapperComponent>
  );
};
export default PM003UI;
