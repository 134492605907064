import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PaginationComponentList from '../../../../../common/components/pagination/PaginationComponentList';
import COMMON from '../../../../../common/constants/COMMON';
import { OM005Hanlder } from '../handler/HandlerImpl';
import { data, dataTableView, branchData, roleData, roleNameDefaultValue } from '../slice/Slice';
import { COLUMNS } from './Columns';
import OM005DataTable from './Datatable';

interface props {
    handler: OM005Hanlder;
}

const OM005Section3 = ({ handler }: props) => {
    const dataSource = useSelector(dataTableView);
    const branch = useSelector(branchData);
    const defaultRoleName = useSelector(roleNameDefaultValue);
    const role = useSelector(roleData);
    const [page, setPage] = React.useState(COMMON.DEFAULT_PAGE);
    const [size, setSize] = React.useState(COMMON.DEFAULT_SIZE);

    React.useEffect(() => {
        handler.getBranchList();
        handler.getRoleList();
    }, []);

    const getData = (current: any, pageSize: any) => {
        // Normally you should get the data from the server
        return dataSource.slice((current - 1) * pageSize, current * pageSize);
    };

    const resetPage = (e: number) => {
        setPage(e);
    };

    const resetSize = (e: number) => {
        setSize(e);
        setPage(1);
    };

    return (
        <div className="wrapper__section3">
            <OM005DataTable
                paddingProps={24}
                scrollProps={576}
                selectionOptionProps={true}
                data={getData(page, size)}
                getDataSource={handler.setDataUploadImport}
                getRowKeySelected={handler.handleRowkeySelected}
                columnCustomize={COLUMNS}
                branchDataProps={branch}
                roleDataProps={role}
                roleNameDefaultValue={defaultRoleName}
            />
            <PaginationComponentList
                total={dataSource.length}
                page={page}
                size={size}
                setPage={resetPage}
                setSize={resetSize}
                paddingProps={24}
                marginTopProps={-50}
                sizeLabelPage={' 人 '}
                sizeLabelSize={' ページ '}
            />
            {/* <OM005Footer handler={handler} /> */}
        </div>
    );
};

export default OM005Section3;
