import { Button, Modal, Spin } from 'antd';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import '../../../../../assets/styles/AM/AM020.css';
import LoadingComponent from '../../../../../common/components/icons/LoadingComponent';
import { RootState } from '../../../../../store';
import { AM020Hanlder } from '../handler/HandlerImpl';
import Delete from '../../../../../assets/icons/delete-red.svg';

import XIconModal from '../../../../../assets/icons/x-icon-modal.svg';
import AM027Component from '../../../AM027';
import COMMON from '../../../../../common/constants/COMMON';
import moment from 'moment';
import { STATUS_REQUEST } from '../../../../../common/constants/STATUS';
import MESSAGE from '../../../../../common/constants/MESSAGE';
type Props = {
    handler: AM020Hanlder;
};

const AM020UI = ({ handler }: Props) => {
    const isVisible = useSelector((state: RootState) => state.am020.isVisible);
    const loading = useSelector((state: RootState) => state.am020.loading);
    const requestId = useSelector((state: RootState) => state.am020.requestId);
    const statusCode = useSelector((state: RootState) => state.am020.statusCode);
    const haveProject = useSelector((state: RootState) => state.am010.haveProject);

    useEffect(() => {
        requestId && handler.handleGetRequestLateEarly(requestId);
    }, [requestId]);

    return (
        <Modal
            className="group-detail-modal"
            style={{ marginLeft: 300, marginTop: 40, marginBottom: 40 }}
            visible={isVisible}
            closable={false}
            maskClosable={false}
            width={'996px'}
            centered={true}
            footer={
                statusCode === STATUS_REQUEST.REQUEST_REJECT ? (
                    <>
                        <Button
                            disabled={loading}
                            size="large"
                            type="primary"
                            style={{ marginLeft: 16, width: 76, letterSpacing: -1.5 }}
                            onClick={() => requestId && handler.handleOpenModalUpdate(requestId)}
                        >
                            編集
                        </Button>
                        <Button
                            disabled={loading}
                            icon={<img alt="" src={Delete} style={{ marginRight: 12 }} />}
                            size="large"
                            style={{
                                marginLeft: 16,
                                width: 106,
                                backgroundColor: '#DAE2EE',
                                border: '1px solid rgba(255, 9, 9, 0.5)',
                                color: '#FF0909',
                                letterSpacing: -1.5,
                            }}
                            onClick={() =>
                                requestId && handler.handleOpenModalConfirmDelete([requestId])
                            }
                        >
                            削除
                        </Button>
                        <Button
                            disabled={loading}
                            size="large"
                            style={{
                                marginLeft: 16,
                                width: 118,
                                border: '1px solid #DAE2EE',
                                color: '#666666',
                            }}
                            onClick={handler.onCancel}
                        >
                            キャンセル
                        </Button>
                    </>
                ) : null
            }
        >
            <Spin indicator={LoadingComponent} spinning={loading}>
                <div className="w-100-percent header-modal-containter" style={{ height: 50 }}>
                    <div className="header-modal-title-om004 ">遅刻・早退申請</div>
                    <div style={{ cursor: 'pointer' }} onClick={handler.onCancel}>
                        <img alt="" src={XIconModal} style={{ height: 18, width: 18 }} />
                    </div>
                </div>
                <div
                    style={{ minHeight: 224, marginTop: 24 }}
                    className={
                        statusCode === STATUS_REQUEST.REQUEST_REJECT
                            ? 'am020-data'
                            : 'am020-data am020-data-margin-bot'
                    }
                >
                    <div className="row">
                        <div className="title">ステータス</div>
                        <div
                            className={
                                statusCode === STATUS_REQUEST.REQUEST_REJECT
                                    ? 'description description_first-child'
                                    : statusCode === STATUS_REQUEST.REQUEST_APPROVED
                                        ? 'description description_first-child description_approve'
                                        : 'description description_first-child description_waiting_approve'
                            }
                        >
                            {handler?.requestLateEarly?.status?.name}
                        </div>
                    </div>
                    <div className="row">
                        <div className="title">事由</div>
                        <div className="description">
                            {handler?.requestLateEarly?.category?.description}
                        </div>
                    </div>
                    <div className="row">
                        <div className="title">対象日</div>
                        <div className="description">
                            {handler?.requestLateEarly?.requestDate &&
                                moment(handler?.requestLateEarly?.requestDate).format(
                                    COMMON.FORMAT_DATE,
                                )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="title">時間</div>
                        <div className="description">{`${handler?.requestLateEarly?.timeRequest}分`}</div>
                    </div>
                    {handler?.requestLateEarly?.project !== null &&
                        handler?.requestLateEarly?.construction !== null && (
                            <>
                                <div className="row">
                                    <div className="title">案件名</div>
                                    <div className="description">
                                        {handler?.requestLateEarly?.project?.name}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="title">工事名</div>
                                    <div className="description">
                                        {handler?.requestLateEarly?.construction?.name}
                                    </div>
                                </div>
                            </>
                        )}
                    <div className="row">
                        <div className="title">承認者</div>
                        <div className="description">
                            {handler?.requestLateEarly?.approver?.fullName}
                        </div>
                    </div>
                    <div className="row">
                        <div className="title">備考</div>
                        <div className="description">
                            {handler?.requestLateEarly?.note ? handler.requestLateEarly.note : '-'}
                        </div>
                    </div>
                    {statusCode === STATUS_REQUEST.REQUEST_REJECT && (
                        <div className="row">
                            <div className="title">否認理由</div>
                            <div className="description">
                                {handler?.requestLateEarly?.rejectReason
                                    ? handler?.requestLateEarly?.rejectReason
                                    : '-'}
                            </div>
                        </div>
                    )}
                </div>
            </Spin>
            <AM027Component
                title={MESSAGE.MESSAGE_MODAL_DELETE_TITLE}
                description={MESSAGE.MESSAGE_DELETE_LATE_EARLY}
            />
        </Modal>
    );
};

export default AM020UI;
