import React from 'react';

// Library
import { Button, Form, Input } from 'antd';

// Media
import '../../../../../assets/styles/ORD/ORD001/header.css';
import SearchIcon from '../../../../../assets/images/ord_search.svg';
import DeleteIcon from '../../../../../assets/images/ord_delete.svg';
import { ORD001Handler } from '../handler/HandlerImpl';
import { SEARCH_KEY } from '../../contants';

type props = {
    handler: ORD001Handler;
};

const HeaderComponent = ({ handler }: props) => {
    return (
        <div className="ord--001__header">
            <div className="ord--001__component-title">オーダー管理</div>
            <div className="ord--001__component-action">
                <div className="ord--001__component-filter">
                    <Form form={handler.form}>
                        <Form.Item name={SEARCH_KEY}>
                            <Input
                                onChange={handler.onSearch}
                                prefix={<img src={SearchIcon} alt="search icon" />}
                                placeholder="検索したい情報を入力してください(例：会社名、ID、メールアドレス、アドミン名）"
                                autoComplete="off"
                            />
                        </Form.Item>
                    </Form>
                </div>
                <div className="ord--001__component-button">
                    <Button
                        className="ord--001__component-button--active"
                        onClick={() => handler.createNewOrder()}
                    >
                        オーダー追加
                    </Button>
                    <Button
                        className="ord--001__component-button--delete"
                        disabled={handler.selectedRowsKey.length === 0}
                        onClick={() => handler.deleteMultiple()}
                    >
                        <img src={DeleteIcon} alt="delete icon" />
                        <div className="ord-001__component-button-text">削除</div>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default HeaderComponent;
