function OperatingCostLengend({ barColor, name }: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
    >
      <rect
        x="0.5"
        width="12"
        height="12"
        rx="6"
        fill={`url(#paint0_linear_34702_10047_${name})`}
      />
      <defs>
        <linearGradient
          id={`paint0_linear_34702_10047_${name}`}
          x1="0.5"
          y1="6"
          x2="12.5"
          y2="6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={barColor[0]} />
          <stop offset="1" stopColor={barColor[1]} />
        </linearGradient>
      </defs>
    </svg>
  );
}

function NumberOfPeopleOperationLengend() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="8"
      viewBox="0 0 20 8"
      fill="none"
    >
      <rect
        x="5.5"
        width="8"
        height="8"
        rx="4"
        fill="url(#paint0_linear_34702_10050)"
      />
      <line
        x1="0.5"
        y1="4.5"
        x2="19.5"
        y2="4.5"
        stroke="url(#paint1_linear_34702_10050)"
        strokeDasharray="4 4"
      />
      <defs>
        <linearGradient
          id="paint0_linear_34702_10050"
          x1="13.5"
          y1="8"
          x2="5.5"
          y2="8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC53D" />
          <stop offset="1" stopColor="#EA6B38" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_34702_10050"
          x1="19.5"
          y1="6"
          x2="0.5"
          y2="6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC53D" />
          <stop offset="1" stopColor="#EA6B38" />
        </linearGradient>
      </defs>
    </svg>
  );
}

const CustomLegendComp = (props: any) => {
  const { barColor, name, legends } = props;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "0 40px",
        justifyContent: "center",
        marginTop: "24px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "0 12px",
        }}
      >
        <OperatingCostLengend barColor={barColor} name={name} />
        <span>{legends.laborCostLegend}</span>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "0 12px",
        }}
      >
        <NumberOfPeopleOperationLengend />
        <span>{legends.workingDayLegend}</span>
      </div>
    </div>
  );
};

export default CustomLegendComp;
