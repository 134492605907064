import { CM0062SliceType } from './../type/Presenter';
import { createSlice } from '@reduxjs/toolkit';

const initialState: CM0062SliceType = {
    loading: false,
    isVisible: false,
    refresh: false,
    input: {
        documentId: 0,
        categoryId: 0,
        projectName: '',
        companyName: '',
        time: '',
        option: true,
    },
};

export const cm0062Slice = createSlice({
    name: 'cm0062',
    initialState,
    reducers: {
        resetCM0062: () => initialState,
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setIsVisibleCM0062: (state, action) => {
            state.isVisible = action.payload;
        },
        setRefreshCM0062: (state) => {
            state.refresh = !state.refresh;
        },
        setInput: (state, action) => {
            state.input = action.payload;
        },
    },
});

export const { setLoading, setIsVisibleCM0062, resetCM0062, setRefreshCM0062, setInput } =
    cm0062Slice.actions;

export default cm0062Slice.reducer;
