// Media
import "../../../../../assets/styles/AOP/AOP001/unit.css";
import InfoIcon from "../../../../../assets/images/aop_icon_info.svg";
import { Table } from "antd";
import ColumnTable from "./ColumnTable";
import { AOP004Handler } from "../handler/HandlerImpl";
import {
  COLUMN_TABLE_FIVE,
  COLUMN_TABLE_FOUR,
  COLUMN_TABLE_ONE,
  COLUMN_TABLE_SIX,
  COLUMN_TABLE_THREE,
  COLUMN_TABLE_TWO,
} from "../../contants";
import { roleMapperTabTwo } from "../../helpers";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { AUTHORIZATION_CODE } from "../../../../../common/constants/AUTHORIZATION";

type props = {
  handler: AOP004Handler;
};

const UnitTwo = ({ handler }: props) => {
  const subscription =
    useSelector(
      (state: RootState) => state.auth?.authData?.order?.subscription
    ) ?? [];
  const isM19 = subscription.some(
    (element) => element.code === AUTHORIZATION_CODE.M19
  );
  const isM18 = subscription.some(
    (element) => element.code === AUTHORIZATION_CODE.M18
  );
  const isM17 = subscription.some(
    (element) => element.code === AUTHORIZATION_CODE.M17
  );

  const {
    columns,
    dataUnitTwoTableOne,
    dataUnitTwoTableTwo,
    dataUnitTwoTableThree,
    dataUnitTwoTableFour,
    dataUnitTwoTableFive,
    dataUnitTwoTableSix,
  } = ColumnTable();

  const colOne = columns(COLUMN_TABLE_ONE);
  const colTwo = columns(COLUMN_TABLE_TWO);
  const colThree = columns(COLUMN_TABLE_THREE);
  const colFour = columns(COLUMN_TABLE_FOUR);
  const colFive = columns(COLUMN_TABLE_FIVE);
  const colSix = columns(COLUMN_TABLE_SIX);

  const dataTable = roleMapperTabTwo(
    handler.dRole,
    dataUnitTwoTableOne,
    dataUnitTwoTableTwo,
    dataUnitTwoTableThree,
    dataUnitTwoTableFour,
    dataUnitTwoTableFive,
    dataUnitTwoTableSix
  );

  return (
    <div className="aop--001__unit-one">
      <div className="aop--001__unit-one-title">
        <div className="aop--001__unit-one-tt">部門・メンバー</div>
      </div>
      <Table
        bordered
        pagination={false}
        columns={colOne}
        dataSource={dataTable[0]}
      />
      <div className="aop--001__unit-one-title">
        <div className="aop--001__unit-one-tt">案件管理</div>
      </div>
      <Table
        bordered
        pagination={false}
        columns={colTwo}
        dataSource={dataTable[1]}
      />
      <div className="aop--001__unit-one-title">
        <div className="aop--001__unit-one-tt">協力会社管理</div>
        <div className="aop--001__unit-one-ti">
          <img
            src={InfoIcon}
            alt="icon info"
            onClick={() => handler.openModalH2()}
          />
        </div>
      </div>
      <Table
        bordered
        pagination={false}
        columns={colThree}
        dataSource={dataTable[2]}
      />
      <div className="aop--001__unit-one-title">
        <div className="aop--001__unit-one-tt">勤怠管理</div>
      </div>
      <Table
        bordered
        pagination={false}
        columns={colFour}
        dataSource={dataTable[3]}
      />
      {isM17 || isM18 || isM19 ? (
        <>
          <div className="aop--001__unit-one-title">
            <div className="aop--001__unit-one-tt">収支管理</div>
            <div className="aop--001__unit-one-ti">
              <img
                src={InfoIcon}
                alt="icon info"
                onClick={() => handler.openModalH3()}
              />
            </div>
          </div>
          <Table
            bordered
            pagination={false}
            columns={colFive}
            dataSource={dataTable[4]}
          />
        </>
      ) : (
        <></>
      )}
      <div className="aop--001__unit-one-title">
        <div className="aop--001__unit-one-tt">集計・出力</div>
      </div>
      <Table
        bordered
        pagination={false}
        columns={colSix}
        dataSource={dataTable[5]}
      />
    </div>
  );
};

export default UnitTwo;
