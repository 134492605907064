import { MenuServiceImpl } from "./../../usecase/ServiceImpl";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLoading } from "../../../../slice/CommonSlice";
import helpers from "../../../../helpers/common";
import ErrorNotification from "../../../notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../constants/MESSAGE";
import { resetAuth } from "../../../../slice/AuthSlice";
const MenuHandlerHandler = (service: MenuServiceImpl) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const redirectHome = () => navigate("/app-pm002");

  const logout = async () => {
    try {
      dispatch(setLoading(true));
      helpers.clearAllCookies();
      dispatch(resetAuth());
      localStorage.removeItem("user");
      await service.logout();
    } catch (error: any) {
      ErrorNotification(error?.message, NOTIFICATION_TITLE.ERROR);
    } finally {
      navigate("/app-login");
      dispatch(setLoading(false));
    }
  };

  const onChoseMenuItem = (value: any) => {
    const pathName = window.location.pathname;
    switch (parseInt(value.key)) {
      case 1:
        if (pathName === "/app-db001") break;
        navigate("/app-db001");
        break;
      case 81:
        if (pathName === "/app-pm002") break;
        navigate("/app-pm002");
        break;
      case 82:
        if (pathName === "/app-pm025") break;
        navigate("/app-pm025");
        break;
      case 72:
        if (pathName === "/app-om002") break;
        navigate("/app-om002?groupId=0");
        break;
      case 26:
        if (pathName === "/app-am028") break;
        navigate("/app-am028");
        break;
      case 24:
        if (pathName === "/app-am008") break;
        navigate("/app-am008");
        break;
      case 23:
        if (pathName === "/app-am005") break;
        navigate("/app-am005");
        break;
      case 25:
        if (pathName === "/app-am010") break;
        navigate("/app-am010");
        break;
      case 22:
        if (pathName === "/app-am002") break;
        navigate("/app-am002");
        break;
      case 21:
        if (pathName === "/app-am001") break;
        navigate("/app-am001");
        break;
      case 51:
        if (pathName === "/app-cm0061") break;
        navigate("/app-cm0061");
        break;
      case 52:
        if (pathName === "/app-cm0017") break;
        navigate("/app-cm0017");
        break;
      case 53:
        if (pathName === "/app-cm0019") break;
        navigate("/app-cm0019");
        break;
      case 54:
        if (pathName === "/app-cm0034-cm0053") break;
        navigate("/app-cm0034-cm0053");
        break;
      case 73:
        if (pathName === "/app-sw01") break;
        navigate("/app-sw01");
        break;
      case 76:
        if (pathName === "/app-sw02") break;
        navigate("/app-sw02");
        break;
      case 3:
        if (pathName === "/app-sm01") break;
        navigate("/app-sm01");
        break;
      case 75:
        if (pathName === "/app-sn01") break;
        navigate("/app-sn01");
        break;
      case 74:
        if (pathName === "/app-p01") break;
        navigate("/app-p01");
        break;
      case 61:
        if (pathName === "/app-ord001") break;
        navigate("/app-ord001");
        break;
      case 77:
        if (pathName === "/app-ic1") break;
        navigate("/app-ic1");
        break;
      case 62:
        if (pathName === "/app-ord007") break;
        navigate("/app-ord007");
        break;
      case 71:
        if (pathName === "/app-aop001") break;
        navigate("/app-aop001");
        break;
      case 78:
        if (pathName === "/app-t01") break;
        navigate("/app-t01");
        break;
      case 91:
        if (pathName === "/app-ccus01") break;
        navigate("/app-ccus01");
        break;
      case 92:
        if (pathName === "/app-ccus05") break;
        navigate("/app-ccus05");
        break;
      case 93:
        if (pathName === "/app-ccus02") break;
        navigate("/app-ccus02");
        break;
      case 94:
        if (pathName === "/app-ccus061") break;
        navigate("/app-ccus061");
        break;
    }
  };

  return { logout, onChoseMenuItem, redirectHome };
};

export default MenuHandlerHandler;
