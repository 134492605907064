// Core library
import { useState } from "react";

// Services
import { CM0013ServiceImpl } from "./../../usecase/ServiceImpl";
import { useDispatch } from "react-redux";
import { resetCM0013 } from "../slice/Slice";

const CM0013Handler = (service: CM0013ServiceImpl) => {
  const dispatch = useDispatch();
  const clearRedux = () => {
    dispatch(resetCM0013());
  };
  return { clearRedux };
};

export default CM0013Handler;
