import { OM003SliceType } from "./../type/Presenter";
import { createSlice } from "@reduxjs/toolkit";

const initialState: OM003SliceType = {
  isRefresh: false,
};

export const OM003Slice = createSlice({
  name: "OM003",
  initialState,
  reducers: {
    resetOM003: () => initialState,
    setRefreshOM003: (state) => {
      state.isRefresh = !state.isRefresh;
    },
  },
});

export const { setRefreshOM003, resetOM003 } = OM003Slice.actions;

export default OM003Slice.reducer;
