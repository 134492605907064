import { useState } from "react";
import ErrorNotification from "../../notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../constants/MESSAGE";

export const Handler = () => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const size = 100;

  const loadMoreData = async (params: {
    fetchData: (params: any) => Promise<any>;
  }) => {
    setLoading(true);
    const { results, pagination } = await params.fetchData({
      page: page,
      size: size,
    });
    setData([...data, ...results]);
    setTotal(pagination.count);
    setPage(page + 1);
    setLoading(false);
  };

  const loadMoreDataOnId = async (params: {
    id: any;
    fetchData: (params: any) => Promise<any>;
  }) => {
    try {
      setLoading(true);
      let localPage = 1;

      const { results, pagination } = await params.fetchData({
        page: localPage,
        size: size,
      });

      let numPages = Math.floor(pagination.count / size);
      if (pagination.count % size !== 0) numPages++;
      if (numPages === page) return;

      const isExisted = results.some(
        (element: any) => element.id === params.id
      );
      if (isExisted) {
        setData([...data, ...results]);
        setTotal(pagination.count);
        setPage(2);
      } else {
        let localData: any[] = [];
        localData = localData.concat(results);
        for (let i = 2; i <= numPages; i++) {
          const { results, pagination } = await params.fetchData({
            page: localPage,
            size: size,
          });
          const isExisted = results.some(
            (element: any) => element.id === params.id
          );
          if (isExisted) {
            setData([...localData, ...results]);
            setTotal(pagination.count);
            setPage(i);
            break;
          } else {
            localData = localData.concat(results);
          }
        }
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      setLoading(false);
    }
  };

  const onScroll = async (params: {
    event: any;
    fetchData: (params: any) => Promise<any>;
  }) => {
    const target = params.event.target;
    if (
      !loading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      if (data.length === total) return;
      target.scrollTo(0, target.scrollHeight);
      await loadMoreData({ fetchData: params.fetchData });
    }
  };

  return { onScroll, loadMoreData, data, loading };
};
