import React, { useEffect } from "react";
import { Handler } from "../handler/HandlerImpl";
import { Modal, Button, Row, Col, Spin } from "antd";
import XIconModal from "../../../../../assets/icons/x-icon-modal.svg";
import TableComponent from "./Table";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import "../../../../../assets/styles/DB/DB007.css";
import helpers from "../../../../../common/helpers/common";
import COMMON from "../../../../../common/constants/COMMON";
import moment from "moment";
import LoadingComponent from "../../../../../common/components/icons/LoadingComponent";
import { useSearchParams } from "react-router-dom";
import { PARAMS_PROJECT_NAME } from "../../../DB001/presenter/handler/Handler";

interface props {
  handler: Handler;
}

function MainUI({ handler }: props) {
  const visibleDB007 = useSelector(
    (state: RootState) => state.db007.isVisibleDB007
  );
  const popupDataDB007 = useSelector(
    (state: RootState) => state.db007.dataPopupTableDB007
  );

  const [searchParams] = useSearchParams();

  const projectName = searchParams.get(PARAMS_PROJECT_NAME) ?? "";
  const fromDate =
    searchParams.get("startDate") || moment().format(COMMON.FORMAT_DATE4);
  const toDate =
    searchParams.get("endDate") || moment().format(COMMON.FORMAT_DATE4);

  useEffect(() => {}, []);

  return (
    <Spin indicator={LoadingComponent} spinning={handler.loading}>
      <Modal
        style={{ marginLeft: 300, marginTop: 40, marginBottom: 40 }}
        visible={visibleDB007}
        centered
        maskClosable={false}
        width={"1571px"}
        closable={false}
        onCancel={handler.cancelModalDB007}
        footer={
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              color: "#666666",
            }}
          >
            <Button
              size="large"
              style={{ marginLeft: 16, width: 118 }}
              onClick={handler.cancelModalDB007}
            >
              キャンセル
            </Button>
          </div>
        }
      >
        <div
          className="w-100-percent header-modal-containter"
          style={{ height: 50 }}
        >
          <div className="header-modal-title-om004 ">
            {projectName}　稼働人工数・稼働原価
          </div>
          <div style={{ cursor: "pointer" }} onClick={handler.cancelModalDB007}>
            <img alt="" src={XIconModal} style={{ height: 18, width: 18 }} />
          </div>
        </div>
        <div
          style={{
            margin: "24px 24px 24px 24px",
            backgroundColor: "#ffffff",
            height: 300,
          }}
        >
          <Row style={{ marginBottom: 24 }}>
            <Col span={8} style={{ display: "flex" }}>
              <div style={{ marginRight: 24 }} className="CM0017_3-info-title">
                期間
              </div>
              <div className="CM0017_3-info-content">
                {" "}
                {helpers.formatDatePresenter(fromDate, COMMON.FORMAT_DATE_DB)}～
                {helpers.formatDatePresenter(toDate, COMMON.FORMAT_DATE_DB)}
              </div>
            </Col>
          </Row>
          <TableComponent data={popupDataDB007}></TableComponent>
        </div>
      </Modal>
    </Spin>
  );
}

export default MainUI;
