import { RootState } from "../../../../../../../store";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { UseCase } from "../../usecase/ServiceImpl";
import { FormInstance } from "antd";
import {
  addTotalMember,
  setCompanyActiveKey,
  setHardNumberChosenCheckBox,
  setIsCheckAll,
  setLoadingPM011,
  setNumberCheckBox,
  setNumberChosenCheckBox,
  updateAConstructionCompany,
  updateConstructionCountMembers,
} from "../../../../presenter/slice/Slice";
import ErrorNotification from "../../../../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../../../../common/constants/MESSAGE";

export const TYPE_ASC = "ASC";
export const TYPE_DESC = "DESC";

const INITIAL_STATE = {
  myCompanyMembers: [],
  chosenMyCompanyMembers: [],
  subConstructions: [],
  companyCollapse: [],
};

const Handler = (
  pm011Service: UseCase,
  form: FormInstance<any> | undefined
) => {
  const dispatch = useDispatch();

  // SELECTOR
  const numberCheckbox = useSelector(
    (state: RootState) => state.pm011.numberCheckbox
  );

  // STATE
  const [myCompanyMembers, setMyCompanyMember] = useState<any[]>(
    INITIAL_STATE.myCompanyMembers
  );
  const [chosenMyCompanyMembers, setChosenMyCompanyMembers] = useState<any[]>(
    INITIAL_STATE.chosenMyCompanyMembers
  );
  const [subConstructions, setSubConstructions] = useState<any[]>(
    INITIAL_STATE.subConstructions
  );
  const [companyCollapse, setCompanyCollapse] = useState<any[]>(
    INITIAL_STATE.companyCollapse
  );
  // FUNCTION

  // my company member
  const getMyListMember = async (constructionId: number) => {
    try {
      const params = {
        constructionId: constructionId,
      };
      const myListMember = await pm011Service.getListMyMember(params);
      if (myListMember.length === 0) return;
      const numberNotApproved = myListMember.filter(
        (element: any) => !element.approve
      );
      setMyCompanyMember(myListMember);
      dispatch(setNumberCheckBox(numberNotApproved.length));
      dispatch(addTotalMember(myListMember.length));
      dispatch(
        updateConstructionCountMembers({
          id: constructionId,
          totalMember: myListMember.length,
        })
      );
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  // recursive
  const recursion = async (
    object: any,
    array: any,
    results: any[],
    treeLevel: number,
    parentSubConstructionId: number
  ) => {
    if (array.length === 0) {
      // object[`children`] = [];
      return;
    } // STOP CONDITION
    object[`children`] = array;
    for (let i = 0; i < array.length; i++) {
      results.push({
        ...array[i],
        treeLevel: treeLevel,
        parentSubConstructionId,
      });
      // dispatch(addTotalMember(array[i].totalMember));
      const data = await pm011Service.getListSubStruction({
        constructionId: array[i].childConstructionId,
      });
      if (data)
        await recursion(
          array[i],
          data,
          results,
          treeLevel + 1,
          parentSubConstructionId
        );
    }
  };

  // get constructions
  const setInitialTreeView = async (constructionId: number) => {
    dispatch(setLoadingPM011(true));
    try {
      const data = await pm011Service.getListSubStruction({
        constructionId: constructionId,
      });
      if (data?.length > 0) {
        const flatArray: any[] = [];
        let treeLevel = 1;
        for (let i = 0; i < data.length; i++) {
          flatArray.push({
            ...data[i],
            treeLevel: treeLevel,
            parentSubConstructionId: data[i].id,
          });
          const contractorData = await pm011Service.getListSubStruction({
            constructionId: data[i].childConstructionId,
          });
          // dispatch(addTotalMember(data[i].totalMember));
          await recursion(
            data[i],
            contractorData,
            flatArray,
            treeLevel + 1,
            data[i].id
          );
        }
        setSubConstructions(flatArray);
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoadingPM011(false));
    }
  };

  const onChooseCheckbox = (value: any) => {
    const chosenMembers: any[] = [];
    value.forEach((element: any) => {
      const checkedMember = myCompanyMembers.find(
        (member: any) => member.id === element
      );
      if (checkedMember) chosenMembers.push(checkedMember);
    });
    if (chosenMyCompanyMembers.length > chosenMembers.length)
      dispatch(setNumberChosenCheckBox(-1));
    else dispatch(setNumberChosenCheckBox(+1));

    setChosenMyCompanyMembers(chosenMembers);
  };

  const setAllCheckBox = (value: boolean) => {
    // const isActiveKey = companyActiveKey.some(
    //   (element) => element === construction.id + ""
    // );
    // if (!isActiveKey) return;
    if (value)
      setChosenMyCompanyMembers(
        myCompanyMembers.filter((element: any) => !element.approve)
      );
    else setChosenMyCompanyMembers([]);
  };

  const onCheckAll = (checked: any) => {
    if (checked) dispatch(setHardNumberChosenCheckBox(numberCheckbox));
    else dispatch(setHardNumberChosenCheckBox(0));
  };

  const setCheckAllValue = (value: boolean) => {
    dispatch(setIsCheckAll(value));
  };

  const onCollapseChange = (value: any, constructionId: number) => {
    const payload = {
      constructionId: constructionId,
      isOpenAll: value.length !== 0,
    };
    setCompanyCollapse(value);
    dispatch(updateAConstructionCompany(payload));
    dispatch(setCompanyActiveKey(value));
  };

  const onPreview = (name: string, value: unknown) => {
    form?.setFieldValue(name, value);
  };

  return {
    setInitialTreeView,
    onCheckAll,
    onCollapseChange,
    onChooseCheckbox,
    setAllCheckBox,
    setCheckAllValue,
    getMyListMember,
    onPreview,
    setCompanyCollapse,
    subConstructions,
    form,
    myCompanyMembers,
    chosenMyCompanyMembers,
    companyCollapse,
  };
};

export default Handler;
