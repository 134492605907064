import { PM025ModalHandler } from "../handler/HandlerImpl";
import { Modal, Form, Input, Button } from "antd";
import REGEX from "../../../../../common/constants/REGEX";
import MESSAGE from "../../../../../common/constants/MESSAGE";
import COMMON, { MAX_LENGTH } from "../../../../../common/constants/COMMON";
import {
  inputPhoneNumberFormat,
  onBlurTrimFormItem,
  typeInteger,
} from "../../../../../common/helpers/typeInput";
import helpers from "../../../../../common/helpers/common";
import XIconModal from "../../../../../assets/icons/x-icon-modal.svg";

interface props {
  handler: PM025ModalHandler;
}

function PM025ModalMaterial({ handler }: props) {
  return !handler.isVisibleMaterial ? (
    <></>
  ) : (
    <Modal
      title={
        <div
          className="w-100-percent "
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignContent: "center",
          }}
        >
          <div className="header-modal-title-om004 ">
            {handler.isEditMaterial
              ? "材料業者マスタ編集"
              : "材料業者マスタ追加"}
          </div>
          <div
            style={{ cursor: "pointer" }}
            onClick={handler.handleCloseModalCompany}
          >
            <img alt="" src={XIconModal} style={{ height: 18, width: 18 }} />
          </div>
        </div>
      }
      style={{ marginLeft: 300, marginTop: 40, marginBottom: 40 }}
      closable={false}
      centered={true}
      maskClosable={false}
      visible={handler.isVisibleMaterial}
      onCancel={handler.handleCloseModalCompany}
      footer={null}
      width={995}
    >
      <Form
        name="pm025-modal"
        form={handler.formMaterial}
        onFinish={handler.handleSubmitFormCompany}
        onValuesChange={handler.handleChangeFormCompany}
      >
        <div className="pm025-modal-form-item">
          <div className="pm025-modal-form-item-label">
            会社名<span className="sterisk">*</span>
          </div>
          <Form.Item
            className="pm025-modal-form-item-input"
            name="companyName"
            rules={[
              () => ({
                validator(_, value) {
                  if (value && value !== "") {
                    // CHECK INPUT VIETNAMESE
                    if (!REGEX.NOT_VIETNAM.test(value)) {
                      return Promise.reject(
                        new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                      );
                    }
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("会社名を入力してください。")
                  );
                },
              }),
            ]}
          >
            <Input
              style={{ height: 40 }}
              placeholder="例：株式会社ONE Unit"
              size="large"
              maxLength={50}
              onBlur={() =>
                onBlurTrimFormItem(handler.formMaterial, "companyName")
              }
            />
          </Form.Item>
        </div>

        <div className="pm025-modal-form-item">
          <span className="pm025-modal-form-item-label">メールアドレス</span>
          <Form.Item
            className="pm025-modal-form-item-input"
            name="email"
            rules={[
              {
                pattern: new RegExp(REGEX.EMAIL_MASTER),
                message: "メールアドレスの形式が正しくありません。",
              },
            ]}
          >
            <Input
              style={{ height: 40 }}
              placeholder="例：user@oneunit.com"
              size="large"
              maxLength={50}
              onBlur={() => onBlurTrimFormItem(handler.formMaterial, "email")}
            />
          </Form.Item>
        </div>
        <div className="pm025-modal-form-item">
          <span className="pm025-modal-form-item-label">電話番号</span>
          <Form.Item
            className="pm025-modal-form-item-input"
            name="phoneNumber"
            rules={[
              () => ({
                validator(_, value) {
                  if (value && value !== "") {
                    value = value.trim().replaceAll(" ", "");
                    value = value.replaceAll("-", "");
                    if (!REGEX.PHONENUMBER_2.test(value))
                      return Promise.reject(MESSAGE.MESSAGE_WRONG_PHONE_FORMAT);
                    if (value?.length < 10) {
                      return Promise.reject(MESSAGE.MESSAGE_INVALID_INPUT);
                    }
                    return Promise.resolve();
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              style={{ height: 40 }}
              placeholder="例：09011112222　ハイフン(ー)なし　半角"
              size="large"
              maxLength={COMMON.MAX_LENGTH_PHONE_NUMBER}
              onKeyPress={typeInteger}
              onPaste={typeInteger}
              onBlur={() =>
                onBlurTrimFormItem(handler.formMaterial, "phoneNumber")
              }
              onChange={(e) => {
                helpers.preventInputJapanese(e);
                inputPhoneNumberFormat(e, "phoneNumber", handler.formMaterial);
              }}
            />
          </Form.Item>
        </div>
        <div className="pm025-modal-form-item">
          <span className="pm025-modal-form-item-label">住所</span>
          <Form.Item
            className="pm025-modal-form-item-input"
            name="address"
            rules={[
              () => ({
                validator(_, value) {
                  if (value && value !== "") {
                    // CHECK INPUT VIETNAMESE
                    if (!REGEX.NOT_VIETNAM.test(value)) {
                      return Promise.reject(
                        new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                      );
                    }
                    if (value?.length > MAX_LENGTH.ADDRESS) {
                      return Promise.reject(
                        new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                      );
                    }
                    return Promise.resolve();
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              style={{ height: 40 }}
              placeholder="例：東京都渋谷区神南1-2-3 シブヤビルディング101号室"
              size="large"
              maxLength={MAX_LENGTH.ADDRESS}
              onBlur={() => onBlurTrimFormItem(handler.formMaterial, "address")}
            />
          </Form.Item>
        </div>
        <div className="pm025-modal-btn">
          <Button
            htmlType="submit"
            size="large"
            className="button-purple"
            style={{ width: 76, letterSpacing: -1.5 }}
          >
            保存
          </Button>
          <Button
            size="large"
            onClick={handler.handleCloseModalCompany}
            style={{ color: "#666" }}
          >
            キャンセル
          </Button>
        </div>
      </Form>
    </Modal>
  );
}

export default PM025ModalMaterial;
