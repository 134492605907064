import React, { useEffect, useState } from "react";
import CloseSvg from "../../../assets/icons/close-modal.svg";
import { Modal, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import "../../../assets/styles/components/h4-modal.css";
import "../../../assets/styles/components/h1-modal.css";
import { NOTIFICATION_TITLE, TITLE } from "../../constants/MESSAGE";
import InfoRow from "../lable/InfoColumn";
import axiosConfig from "../../../config/axiosConfig";
import ErrorNotification from "../notification/ErrorNotification";
import { setOpenModalCcus061 } from "../../../pages/CCUS/CCUS061/presenter/slice/Slice";
import { DetailCCUS061EntityData } from "./DetailCCUS061Entity";
import helpers from "../../helpers/common";
import COMMON from "../../constants/COMMON";
import LoadingComponent from "../icons/LoadingComponent";

const DetailCCUS061Modal = (model: any) => {
  const dispatch = useDispatch();
  const isOpenDetailCCUS061Modal = useSelector(
    (state: RootState) => state.ccus062.isOpenModalCcus061
  );
  const userId = useSelector((state: RootState) => state.ccus062.userId);
  const [detailUserInformation, setDetailUserInformation] =
    useState<DetailCCUS061EntityData | null>({});
  const [loading, setLoading] = useState<boolean>(false);
  const handleCancel = () => {
    dispatch(setOpenModalCcus061(false));
  };

  const getCCusEmployeeProfile = async (params: {
    userId: number;
  }): Promise<any> => {
    try {
      const url = "/ccus/employee/profile";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getStateCCUSCompany = async (): Promise<any> => {
    try {
      setLoading(true);
      const response = await getCCusEmployeeProfile({ userId: userId });
      setDetailUserInformation(response.results);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userId) getStateCCUSCompany();
  }, [userId]);

  const residentStartDate =
    helpers.formatDatePresenter(
      detailUserInformation?.residentStartDate ?? "",
      COMMON.FORMAT_DATE_CM
    ) || "-";

  const residentEndDate =
    helpers.formatDatePresenter(
      detailUserInformation?.residentEndDate ?? "",
      COMMON.FORMAT_DATE_CM
    ) || "-";

  return (
    <Modal
      className="group-detail-modal h1-modal h1-modal-white-header"
      style={{ marginLeft: 300, marginTop: 40, marginBottom: 40 }}
      open={isOpenDetailCCUS061Modal}
      closable={false}
      maskClosable={false}
      width={1290}
      centered={true}
      footer={null}
    >
      <Spin indicator={LoadingComponent} spinning={loading}>
        <div style={{ display: "flex" }}>
          <div
            className="title-popup-ccus061"
            style={{
              flex: 1,
              textAlign: "center",
              fontWeight: 700,
              fontSize: 18,
            }}
          >
            {"技能者情報"}
          </div>
          <div style={{ cursor: "pointer" }} onClick={handleCancel}>
            <img alt="" src={CloseSvg} style={{ height: 18, width: 18 }} />
          </div>
        </div>
        <div className="container-popup-ccus061">
          <div className="info-column">
            <InfoRow
              title={TITLE.POPUP_CCUS061_NAME}
              value={
                detailUserInformation?.fullName
                  ? detailUserInformation?.fullName
                  : "-"
              }
              width={112}
            />
            <InfoRow
              title={TITLE.POPUP_CCUS061_NAME_KATAKANA}
              value={
                detailUserInformation?.fullNameKana
                  ? detailUserInformation?.fullNameKana
                  : "-"
              }
              width={112}
            />
            <InfoRow
              title={TITLE.POPUP_CCUS061_EMAIL_ADDRESS}
              value={
                detailUserInformation?.mailAddress
                  ? detailUserInformation?.mailAddress
                  : "-"
              }
              width={112}
            />
            <InfoRow
              title={TITLE.POPUP_CCUS061_CURRENT_ADDRESS}
              value={
                detailUserInformation?.currentAddress
                  ? detailUserInformation?.currentAddress
                  : "-"
              }
              width={112}
            />
          </div>
          <div className="info-column">
            <InfoRow
              title={TITLE.POPUP_CCUS061_DATEOFBIRTH}
              value={
                helpers.formatDatePresenter(
                  detailUserInformation?.birthday ?? "",
                  COMMON.FORMAT_DATE_CM
                ) || "-"
              }
              width={87}
            />
            <InfoRow
              title={TITLE.POPUP_CCUS061_GENDER}
              value={
                detailUserInformation?.sex ? detailUserInformation?.sex : "-"
              }
              width={87}
            />
            <InfoRow
              title={TITLE.POPUP_CCUS061_ABO_BLOOD_TYPE}
              value={
                detailUserInformation?.bloodType
                  ? detailUserInformation?.bloodType
                  : "-"
              }
              width={87}
            />
          </div>
          <div className="info-column">
            <InfoRow
              title={TITLE.POPUP_CCUS061_SKILLED_WORKER_ID}
              value={
                helpers.convertToCCUSFormat(
                  detailUserInformation?.ccusId ?? ""
                ) || "-"
              }
              width={112}
            />
            <InfoRow
              title={TITLE.POPUP_CCUS061_TELEPHONE_NUMBER_HOME}
              value={
                helpers.toPhoneNumberFormat(
                  detailUserInformation?.telNumberHome ?? ""
                ) || "-"
              }
              width={112}
            />
            <InfoRow
              title={TITLE.POPUP_CCUS061_MOBILE_NUMBER}
              value={
                helpers.toPhoneNumberFormat(
                  detailUserInformation?.telNumberMobile ?? ""
                ) || "-"
              }
              width={112}
            />
          </div>
          <div className="info-column">
            <InfoRow
              title={TITLE.POPUP_CCUS061_NATIONALITY}
              value={
                detailUserInformation?.nationality
                  ? detailUserInformation?.nationality
                  : "-"
              }
              width={56}
            />
            <InfoRow
              title={TITLE.POPUP_CCUS061_STATUS_OF_RESIDENCE}
              value={
                detailUserInformation?.residentStatus
                  ? detailUserInformation?.residentStatus
                  : "-"
              }
              width={56}
            />
            <InfoRow
              title={TITLE.POPUP_CCUS061_PERIOD_RESIDENCE}
              value={residentStartDate + " ~ " + residentStartDate}
              width={56}
            />
          </div>
        </div>

        {/* 緊急連絡先 */}
        <div className="container2" style={{ display: "flex" }}>
          <div className="info-column" style={{ flex: 1, paddingRight: 24 }}>
            <div className="title-popup-ccus061">
              {TITLE.POPUP_CCUS061_EMERGENCY_CONTACT}
            </div>
            <div className="contain-content-popup-ccus061">
              <div className="row-flex-1">
                <InfoRow
                  title={TITLE.POPUP_CCUS061_NAME}
                  value={
                    detailUserInformation?.emergencyContactFullName
                      ? detailUserInformation?.emergencyContactFullName
                      : "-"
                  }
                  width={30}
                />
                <InfoRow
                  title={TITLE.POPUP_CCUS061_ADDRESS}
                  value={
                    detailUserInformation?.emergencyContactAddress
                      ? detailUserInformation?.emergencyContactAddress
                      : "-"
                  }
                  width={30}
                />
                <InfoRow
                  title={TITLE.POPUP_CCUS061_RELATIONSHIP}
                  value={
                    detailUserInformation?.emergencyContactRelationship
                      ? detailUserInformation?.emergencyContactRelationship
                      : "-"
                  }
                  width={30}
                />
              </div>
              <div className="row-flex-1">
                <InfoRow
                  title={TITLE.POPUP_CCUS061_NAME_KATAKANA}
                  value={
                    detailUserInformation?.emergencyContactFullNameKana
                      ? detailUserInformation?.emergencyContactFullNameKana
                      : "-"
                  }
                  width={112}
                />
                <InfoRow
                  title={TITLE.POPUP_CCUS061_PHONE_NUMBER}
                  value={
                    helpers.toPhoneNumberFormat(
                      detailUserInformation?.emergencyContactTelNumber ?? ""
                    ) || "-"
                  }
                  width={112}
                />
              </div>
            </div>
          </div>

          {/* 健康保険 */}
          <div className="row-flex-1">
            <div className="title-popup-ccus061">
              {TITLE.POPUP_CCUS061_HEALTH_INSURANCE}
            </div>
            <div className="contain-content-popup-ccus061">
              <div className="row-flex-1">
                <InfoRow
                  title={TITLE.POPUP_CCUS061_ENROLLMENT_STATUS}
                  value={
                    detailUserInformation?.healthInsStatus
                      ? detailUserInformation?.healthInsStatus
                      : "-"
                  }
                  width={56}
                />
                <InfoRow
                  title={TITLE.POPUP_CCUS061_TYPE}
                  value={
                    detailUserInformation?.healthInsType
                      ? detailUserInformation?.healthInsType
                      : "-"
                  }
                  width={56}
                />
              </div>
              <div className="row-flex-1">
                <InfoRow
                  title={TITLE.POPUP_CCUS061_REASON_FOR_EXEMPTION}
                  value={
                    detailUserInformation?.healthInsExclusion
                      ? detailUserInformation?.healthInsExclusion
                      : "-"
                  }
                  width={100}
                />
                <InfoRow
                  title={TITLE.POPUP_CCUS061_NAME_INSURER}
                  value={
                    detailUserInformation?.healthInsInsurerName
                      ? detailUserInformation?.healthInsInsurerName
                      : "-"
                  }
                  width={100}
                />
              </div>
            </div>
          </div>
        </div>

        {/* 年金保険 */}
        <div className="container2" style={{ display: "flex" }}>
          <div className="row-flex-1" style={{ paddingRight: 24 }}>
            <div className="title-popup-ccus061">
              {TITLE.POPUP_CCUS061_PENSION_INSURANCE}
            </div>
            <div className="contain-content-popup-ccus061">
              <div className="row-flex-1">
                <InfoRow
                  title={TITLE.POPUP_CCUS061_ENROLLMENT_STATUS}
                  value={
                    detailUserInformation?.pensionInsStatus
                      ? detailUserInformation?.pensionInsStatus
                      : "-"
                  }
                  width={56}
                />
                <InfoRow
                  title={TITLE.POPUP_CCUS061_TYPE}
                  value={
                    detailUserInformation?.pensionInsType
                      ? detailUserInformation?.pensionInsType
                      : "-"
                  }
                  width={56}
                />
              </div>
              <div className="row-flex-1">
                <InfoRow
                  title={TITLE.POPUP_CCUS061_REASON_FOR_EXEMPTION}
                  value={
                    detailUserInformation?.pensionInsExclusion
                      ? detailUserInformation?.pensionInsExclusion
                      : "-"
                  }
                  width={98}
                />
              </div>
            </div>
          </div>

          {/* 雇用保険 */}
          <div className="row-flex-1">
            <div className="title-popup-ccus061">
              {TITLE.POPUP_CCUS061_EMPLOYMENT_INSURANCE}
            </div>
            <div className="contain-content-popup-ccus061">
              <div className="row-flex-1">
                <InfoRow
                  title={TITLE.POPUP_CCUS061_ENROLLMENT_STATUS}
                  value={
                    detailUserInformation?.employmentInsStatus
                      ? detailUserInformation?.employmentInsStatus
                      : "-"
                  }
                  width={126}
                />
                <InfoRow
                  title={TITLE.POPUP_CCUS061_CLASSIFICATION}
                  value={
                    detailUserInformation?.employmentInsType
                      ? detailUserInformation?.employmentInsType
                      : "-"
                  }
                  width={126}
                />
              </div>
              <div className="row-flex-1">
                <InfoRow
                  title={TITLE.POPUP_CCUS061_REASON_FOR_EXEMPTION}
                  value={
                    detailUserInformation?.employmentInsExclusion
                      ? detailUserInformation?.employmentInsExclusion
                      : "-"
                  }
                  width={98}
                />
              </div>
            </div>
          </div>
        </div>

        {/* 建設業退職金共済制度_加入状況 */}
        <div className="info_row row-flex-1">
          <div className="row-flex-1" style={{ paddingRight: 24 }}>
            <div className="title-popup-ccus061">
              {TITLE.POPUP_CCUS061_CONSTRUCTION_INDUSTRY_ENROLLMENT_STATUS}
            </div>
            <div className="contain-content-popup-ccus061">
              <div className="text-popup-ccus061">
                {detailUserInformation?.kentaikyoStatus
                  ? detailUserInformation?.kentaikyoStatus
                  : "-"}
              </div>
            </div>
          </div>

          {/* 中小企業退職金共済制度_加入状況 */}
          <div className="row-flex-1" style={{ paddingRight: 24 }}>
            <div className="title-popup-ccus061">
              {TITLE.POPUP_CCUS061_SMALL_AND_MEDIUM_ENROLLMENT_STATUS}
            </div>
            <div className="contain-content-popup-ccus061">
              <div className="text-popup-ccus061">
                {detailUserInformation?.chutaikyoStatus
                  ? detailUserInformation?.chutaikyoStatus
                  : "-"}
              </div>
            </div>
          </div>

          {/* 労災保険特別加入 */}
          <div className="row-flex-1">
            <div className="title-popup-ccus061">
              {TITLE.POPUP_CCUS061_ACCIDENT_COMPENSATION_INSURANCE}
            </div>
            <div className="contain-content-popup-ccus061">
              <div className="row-flex-1">
                <InfoRow
                  title={TITLE.POPUP_CCUS061_ENROLLMENT_STATUS}
                  value={
                    detailUserInformation?.workmansCompInsStatus
                      ? detailUserInformation?.workmansCompInsStatus
                      : "-"
                  }
                  width={56}
                />
              </div>
              <div className="row-flex-1">
                <InfoRow
                  title={TITLE.POPUP_CCUS061_INSURANCE_TYPE}
                  value={
                    detailUserInformation?.workmansCompInsType
                      ? detailUserInformation?.workmansCompInsType
                      : "-"
                  }
                  width={56}
                />
              </div>
            </div>
          </div>
        </div>

        {/* 一般健康診断受診日 */}
        <div className="info_row">
          <div className="row-flex-1" style={{ flex: 1, paddingRight: 24 }}>
            <div className="title-popup-ccus061">
              {TITLE.POPUP_CCUS061_GENERAL_HEALTH_CHECK_UP_DATE}
            </div>
            <div className="contain-content-popup-ccus061">
              <div className="text-popup-ccus061">
                {helpers.formatDatePresenter(
                  detailUserInformation?.generalMedicalCheckupDate ?? "",
                  COMMON.FORMAT_DATE_CM
                ) || "-"}
              </div>
            </div>
          </div>

          {/* 特殊健康診断情報リスト */}
          <div className="row-flex-1">
            <div className="title-popup-ccus061">
              {TITLE.POPUP_CCUS061_SPECIAL_HEALTH_EXAMINATION_INFORMATION_LIST}
            </div>
            <div className="contain-content-popup-ccus061">
              <div style={{ width: "100%" }}>
                {detailUserInformation?.specialtyMedical &&
                detailUserInformation.specialtyMedical.length > 0 ? (
                  detailUserInformation.specialtyMedical.map(
                    (specia, index) => (
                      <div
                        key={index}
                        style={{ marginBottom: "8px", display: "flex" }}
                      >
                        <div className="row-flex-1">
                          <InfoRow
                            title={TITLE.POPUP_CCUS061_TYPE_HEALTH}
                            value={
                              specia.specialtyMedicalCheckupTypeCode ?? "-"
                            }
                            width={28}
                          />
                        </div>
                        <div className="row-flex-1">
                          <InfoRow
                            title={TITLE.POPUP_CCUS061_DATE_OF_VISIT}
                            value={
                              helpers.formatDatePresenter(
                                specia.specialtyMedicalCheckupDate ?? "",
                                COMMON.FORMAT_DATE_CM
                              ) || "-"
                            }
                            width={42}
                          />
                        </div>
                      </div>
                    )
                  )
                ) : (
                  // Render fields with hyphens if the list is empty or null
                  <div style={{ display: "flex" }}>
                    <div className="row-flex-1">
                      <InfoRow
                        title={TITLE.POPUP_CCUS061_TYPE_HEALTH}
                        value="-"
                        width={28}
                      />
                    </div>
                    <div className="row-flex-1">
                      <InfoRow
                        title={TITLE.POPUP_CCUS061_DATE_OF_VISIT}
                        value="-"
                        width={42}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* じん肺健康診断受診日 */}
        <div className="info_row row-flex-1">
          <div className="row-flex-1" style={{ paddingRight: 24 }}>
            <div className="title-popup-ccus061">
              {TITLE.POPUP_CCUS061_PNEUMOCONIOSIS_MEDICAL_EXAMINATION_DATE}
            </div>
            <div className="contain-content-popup-ccus061">
              <div className="text-popup-ccus061">
                {helpers.formatDatePresenter(
                  detailUserInformation?.pneumMedicalCheckupDate ?? "",
                  COMMON.FORMAT_DATE_CM
                ) || "-"}
              </div>
            </div>
          </div>

          {/* 職種情報リスト */}
          <div className="row-flex-1">
            <div className="title-popup-ccus061">
              {TITLE.POPUP_CCUS061_JOB_INFORMATION_LIST}
            </div>
            <div className="contain-content-popup-ccus061">
              <div>
                {detailUserInformation?.jobCategory &&
                detailUserInformation.jobCategory.length > 0 ? (
                  detailUserInformation.jobCategory.map((job, index) => (
                    <div
                      key={index}
                      style={{ marginBottom: "8px", display: "flex" }}
                    >
                      <InfoRow
                        title={TITLE.POPUP_CCUS061_JOB_TITLE}
                        value={job ?? "-"}
                        width={42}
                      />
                    </div>
                  ))
                ) : (
                  // Render a default row with a hyphen if the array is empty or null
                  <InfoRow
                    title={TITLE.POPUP_CCUS061_JOB_TITLE}
                    value="-"
                    width={42}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        {/* 経験記入欄_技能者本人記入用 */}
        <div className="info_row row-flex-1">
          <div className="row-flex-1" style={{ paddingRight: 24 }}>
            <div className="title-popup-ccus061">
              {TITLE.POPUP_CCUS061_SKILLED_WORKER_TO_FILL_IN}
            </div>
            <div className="contain-content-popup-ccus061">
              <div className="text-popup-ccus061">
                {detailUserInformation?.experienceEntriesGinousya
                  ? detailUserInformation.experienceEntriesGinousya
                  : "-"}
              </div>
            </div>
          </div>

          {/* 経験記入欄_所属事業者記入用 */}
          <div className="row-flex-1" style={{ paddingRight: 24 }}>
            <div className="title-popup-ccus061">
              {TITLE.POPUP_CCUS061_COMPANY_AFFILIATION}
            </div>
            <div className="contain-content-popup-ccus061">
              <div className="text-popup-ccus061">
                {detailUserInformation?.experienceEntriesJigyousya
                  ? detailUserInformation?.experienceEntriesJigyousya
                  : "-"}
              </div>
            </div>
          </div>

          {/* 学歴_指定学科卒 */}
          <div className="row-flex-1" style={{ paddingRight: 24 }}>
            <div className="title-popup-ccus061">
              {TITLE.POPUP_CCUS061_BACKGROUND_GRADUATE_DESIGNATED_DEPARTMENT}
            </div>
            <div className="contain-content-popup-ccus061">
              <div className="text-popup-ccus061">
                {detailUserInformation?.educationGraduationAvailability
                  ? detailUserInformation?.educationGraduationAvailability
                  : "-"}
              </div>
            </div>
          </div>

          {/* 学歴_学歴 */}
          <div className="row-flex-1">
            <div className="title-popup-ccus061">
              {TITLE.POPUP_CCUS061_BACKGROUND_EDUCATIONAL_BACKGROUND}
            </div>
            <div className="contain-content-popup-ccus061">
              <div className="text-popup-ccus061">
                {detailUserInformation?.educationName
                  ? detailUserInformation?.educationName
                  : "-"}
              </div>
            </div>
          </div>
        </div>

        {/* 登録基幹技能者情報リスト */}
        <div className="container8" style={{ display: "flex" }}>
          <div className="col1" style={{ flex: 1 }}>
            <div className="title-popup-ccus061">
              {TITLE.POPUP_CCUS061_REGISTERED_KEY_TECHNICIAN_INFORMATION_LIST}
            </div>
            <div className="contain-content-popup-ccus061">
              <div style={{ width: "100%" }}>
                {detailUserInformation?.baseLicentiate &&
                detailUserInformation.baseLicentiate.length > 0 ? (
                  detailUserInformation.baseLicentiate.map((baseLic, index) => (
                    <div
                      key={index}
                      style={{ marginBottom: "8px", display: "flex" }}
                    >
                      <div style={{ flex: 2 }}>
                        <InfoRow
                          title={
                            TITLE.POPUP_CCUS061_REGISTERED_KEY_TECHNICIAN_NAME
                          }
                          value={baseLic?.baseLicentiateKeyGinousyaCode ?? "-"}
                          width={112}
                        />
                      </div>

                      <div className="row-flex-1">
                        <InfoRow
                          title={TITLE.POPUP_CCUS061_COMPLETION_DATE}
                          value={helpers.formatDatePresenter(
                            baseLic?.baseLicentiateAcquisitionDate ?? "-",
                            COMMON.FORMAT_DATE_CM
                          )}
                          width={98}
                        />
                      </div>

                      <div className="row-flex-1">
                        <InfoRow
                          title={TITLE.POPUP_CCUS061_DATE_EXPIRY}
                          value={helpers.formatDatePresenter(
                            baseLic?.baseLicentiateExpirationDate ?? "-",
                            COMMON.FORMAT_DATE_CM
                          )}
                          width={98}
                        />
                      </div>
                    </div>
                  ))
                ) : (
                  // Render default fields with hyphens if the array is empty or null
                  <div style={{ display: "flex" }}>
                    <div style={{ flex: 2 }}>
                      <InfoRow
                        title={
                          TITLE.POPUP_CCUS061_REGISTERED_KEY_TECHNICIAN_NAME
                        }
                        value="-"
                        width={112}
                      />
                    </div>

                    <div className="row-flex-1">
                      <InfoRow
                        title={TITLE.POPUP_CCUS061_COMPLETION_DATE}
                        value="-"
                        width={98}
                      />
                    </div>

                    <div className="row-flex-1">
                      <InfoRow
                        title={TITLE.POPUP_CCUS061_DATE_EXPIRY}
                        value="-"
                        width={98}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* 保有資格情報リスト */}
        <div className="container9" style={{ display: "flex", flex: 1 }}>
          <div className="col1" style={{ flex: 1 }}>
            <div className="title-popup-ccus061">
              {TITLE.POPUP_CCUS061_LIST_QUALIFICATIONS_HELD}
            </div>
            <div className="contain-content-popup-ccus061">
              <div style={{ width: "100%" }}>
                {detailUserInformation?.licentiate &&
                detailUserInformation.licentiate.length > 0 ? (
                  detailUserInformation.licentiate.map((lic, index) => (
                    <div
                      key={index}
                      style={{ marginBottom: "8px", display: "flex" }}
                    >
                      <div style={{ flex: 2 }}>
                        <InfoRow
                          title={TITLE.POPUP_CCUS061_QUALIFICATION_NAME}
                          value={lic?.licentiateName ?? "-"}
                          width={42}
                        />
                      </div>

                      <div className="row-flex-1">
                        <InfoRow
                          title={TITLE.POPUP_CCUS061_EXPIRATION_DATE}
                          value={helpers.formatDatePresenter(
                            lic?.expirationDate ?? "-",
                            COMMON.FORMAT_DATE_CM
                          )}
                          width={98}
                        />
                      </div>

                      <div className="row-flex-1">
                        <InfoRow
                          title={TITLE.POPUP_CCUS061_ACQUISITION_DATE}
                          value={helpers.formatDatePresenter(
                            lic?.acquisitionDate ?? "-",
                            COMMON.FORMAT_DATE_CM
                          )}
                          width={98}
                        />
                      </div>

                      <div className="row-flex-1">
                        <InfoRow
                          title={TITLE.POPUP_CCUS061_REGISTRATION_DATE}
                          value={helpers.formatDatePresenter(
                            lic?.registrationDate ?? "-",
                            COMMON.FORMAT_DATE_CM
                          )}
                          width={98}
                        />
                      </div>
                    </div>
                  ))
                ) : (
                  // Render default fields with hyphens if the array is empty or null
                  <div style={{ display: "flex" }}>
                    <div style={{ flex: 2 }}>
                      <InfoRow
                        title={TITLE.POPUP_CCUS061_QUALIFICATION_NAME}
                        value="-"
                        width={42}
                      />
                    </div>

                    <div className="row-flex-1">
                      <InfoRow
                        title={TITLE.POPUP_CCUS061_EXPIRATION_DATE}
                        value="-"
                        width={98}
                      />
                    </div>

                    <div className="row-flex-1">
                      <InfoRow
                        title={TITLE.POPUP_CCUS061_ACQUISITION_DATE}
                        value="-"
                        width={98}
                      />
                    </div>

                    <div className="row-flex-1">
                      <InfoRow
                        title={TITLE.POPUP_CCUS061_REGISTRATION_DATE}
                        value="-"
                        width={98}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default DetailCCUS061Modal;
