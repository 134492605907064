import { CM001SliceType } from './../type/Entity';
import { createSlice } from "@reduxjs/toolkit";

const initialState: CM001SliceType = {
  tab: "1"
};

export const cm001Slice = createSlice({
  name: "cm001",
  initialState,
  reducers: {
    resetCM001: () => initialState,
    setTab: (state, action) => {
        state.tab = action.payload;
    }
  },
});

export const { resetCM001, setTab } = cm001Slice.actions;

export default cm001Slice.reducer;
