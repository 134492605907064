import { FormInstance } from "antd";
import COMMON from "../../../common/constants/COMMON";
import MESSAGE from "../../../common/constants/MESSAGE";
import REGEX from "../../../common/constants/REGEX";
import moment from "moment";
import { uuidv4 } from "@firebase/util";
import helpers from "../../../common/helpers/common";
import { AUTHORIZATION_CODE } from "../../../common/constants/AUTHORIZATION";

const validateInput = (validate: {
  required?: boolean;
  maxLength?: number;
  regex?: RegExp;
}) => {
  return () => ({
    validator: async (_: any, value: any) => {
      if (value && value !== "") {
        // CHECK INPUT VIETNAMESE
        if (validate.regex && !validate.regex.test(value)) {
          return Promise.reject(new Error(MESSAGE.MESSAGE_INPUT_INVALID));
        }
        if (validate.maxLength && value?.length > validate.maxLength) {
          return Promise.reject(new Error(""));
        }
        return Promise.resolve();
      }
      if (validate.required) return Promise.reject(MESSAGE.MESSAGE_REQUIRED);
      return Promise.resolve();
    },
  });
};

const validateMail = () => {
  return () => ({
    validator(_: any, value: any) {
      // required
      if (!value || value.trim() === "")
        return Promise.reject(MESSAGE.MESSAGE_REQUIRED_FIELD);
      // test regex
      if (!REGEX.EMAIL_COMPANY.test(value.trim())) {
        return Promise.reject(MESSAGE.MESSAGE_WRONG_EMAIL_FORMAT);
      }
      return Promise.resolve();
    },
  });
};

const disabledStartDate = (
  current: moment.Moment,
  endDate: moment.Moment
): any => {
  if (!endDate || !current) return false;
  if (
    moment(current).format(COMMON.FORMAT_DATE_OM) ===
    moment(endDate).format(COMMON.FORMAT_DATE_OM)
  )
    return true;
  return current && endDate && current >= endDate;
};

const disabledEndDate = (
  current: moment.Moment,
  startDate: moment.Moment
): any => {
  if (!startDate || !current) return false;
  if (
    moment(current).format(COMMON.FORMAT_DATE_OM) ===
    moment(startDate).format(COMMON.FORMAT_DATE_OM)
  )
    return true;
  return current && startDate && current <= startDate;
};

const getDataTable = () => {
  const user = helpers.getObjectFromLocalStorage("user");
  const subscription = user?.order?.subscription ?? [];
  const isB6 = subscription.some(
    (element: any) => element.code === AUTHORIZATION_CODE.B6
  );
  const isM19 = subscription.some(
    (element: any) => element.code === AUTHORIZATION_CODE.M19
  );
  const isM18 = subscription.some(
    (element: any) => element.code === AUTHORIZATION_CODE.M18
  );
  const isM17 = subscription.some(
    (element: any) => element.code === AUTHORIZATION_CODE.M17
  );

  const TAB1_0 = [
    { column: ["3"], code: ["S14"], name: "工事引合書", key: uuidv4() },
    { column: ["3"], code: ["S18"], name: "実行予算書", key: uuidv4() },
  ];

  const TAB1_1 = [
    { column: ["3"], code: ["M9"], name: "案件別勤務実績", key: uuidv4() },
    { column: ["3"], code: ["M9"], name: "会社別勤務実績", key: uuidv4() },
    { column: ["3"], code: ["M10"], name: "自社勤務実績", key: uuidv4() },
  ];

  const TAB2_0 = [
    { column: ["4"], code: ["M12"], name: " 部門・メンバー", key: uuidv4() },
  ];
  const TAB2_1 = [
    { column: ["3"], code: ["B10"], name: "案件管理", key: uuidv4() },
    { column: ["4"], code: ["S1"], name: "新規案件", key: uuidv4() },
  ];
  const TAB2_2 = [
    {
      column: ["3", "4"],
      code: ["M6", "M7"],
      name: "マスタデータ",
      key: uuidv4(),
    },
  ];
  const TAB2_3 = [
    { column: ["3"], code: ["B13"], name: "案件別勤務実績", key: uuidv4() },
    { column: ["3"], code: ["B13"], name: "会社別勤務実績", key: uuidv4() },
    { column: ["3"], code: ["B11"], name: "自社勤務実績", key: uuidv4() },
  ];
  const TAB2_4 = [
    isM17
      ? {
          column: ["3", "4", "5"],
          code: ["S42", "S48", "S49"],
          name: "案件別収支管理",
          key: uuidv4(),
        }
      : null,
    isM18
      ? {
          column: ["3", "4", "5"],
          code: ["S43", "S44", "S45"],
          name: "工事代金明細書",
          key: uuidv4(),
        }
      : null,
    isM19
      ? {
          column: ["3", "5"],
          code: ["S46", "S47"],
          name: "社内収支",
          key: uuidv4(),
        }
      : null,
  ].filter((element) => element !== null);
  const TAB2_5 = [
    isB6
      ? {
          column: ["3"],
          code: ["B6"],
          name: "ダッシュボード",
          key: uuidv4(),
        }
      : null,
    { column: ["4"], code: ["B9"], name: "ファイル出力", key: uuidv4() },
  ].filter((element) => element !== null);
  const results = {
    TAB1: [TAB1_0, TAB1_1],
    TAB2: [TAB2_0, TAB2_1, TAB2_2, TAB2_3, TAB2_4, TAB2_5],
  };
  return results;
};

export {
  validateInput,
  validateMail,
  disabledStartDate,
  disabledEndDate,
  getDataTable,
};
