import { message } from "antd";
import type { UploadChangeParam } from "antd/es/upload";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { useState } from "react";
import MESSAGE from "../../../constants/MESSAGE";
import STATUS from "../../../constants/STATUS";

export const Handler = () => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();

  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error(MESSAGE.MESSAGE_UPLOAD_WRONG_FILE);
    }
    const isLt8M = file.size / 1024 / 1024 < 8;
    if (!isLt8M) {
      message.error(MESSAGE.MESSAGE_IMAGE_TOO_LARGE);
    }
    return isJpgOrPng && isLt8M;
  };

  const dummyRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleChange = (
    info: UploadChangeParam<UploadFile>,
    getBinary?: any
  ) => {
    if (info.file.status === STATUS.UPLOADING) {
      setLoading(true);
      return;
    }
    if (info.file.status === STATUS.DONE) {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        if (getBinary) getBinary(info.file.originFileObj);
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  return {
    loading,
    imageUrl,
    beforeUpload,
    dummyRequest,
    handleChange,
    setImageUrl,
  };
};
