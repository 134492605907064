import { useLocation } from 'react-router-dom';
// Core library
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RootState } from '../../../../../store';

// Redux
import { useDispatch } from 'react-redux';

// Services
import { CM0061ServiceImpl } from './../../usecase/ServiceImpl';

// Entity

export const DEFAULT_TAB_VALUE = '1';
export const PROJECT_PARAM_ID_KEY = 'projectId';
export const CODE_ONE = 'CODE_ONE';
export const CODE_TWO = 'CODE_TWO';
export const CODE_THREE = 'CODE_THREE';
export const CODE_FOUR = 'CODE_FOUR';
export const CODE_FIVE = 'CODE_FIVE';
export const CODE_SIX = 'CODE_SIX';

export const DEFAULT_TAB_KEY = 'tab';

const CM0061Handler = (service: CM0061ServiceImpl) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [searchParams, setSearchParams] = useSearchParams();
    const [tab, setTab] = useState<string>(DEFAULT_TAB_VALUE);

    const chooseTabView = (tab: string) => {
        setTab(tab);
        searchParams.set(DEFAULT_TAB_KEY, tab);
        setSearchParams(searchParams);
    };

    const redirectToCM003 = () => {
        const searchValueParam: string = location.search;
        navigate(`/app-cm003/cm0061${searchValueParam}`);
    };

    const redirectToCM004 = (constructionId: number) => {
        navigate(
            `/app-cm004?constructionId=${constructionId}&previouspath=${
                location.pathname + location.search.replaceAll('&', '|')
            }`,
        );
    };

    return {
        // variable
        tab,
        // Function
        chooseTabView,
        redirectToCM003,
        redirectToCM004,
    };
};

export default CM0061Handler;
