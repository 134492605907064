import axiosConfig from '../../../../../config/axiosConfig';
import { Params } from '../../presenter/type/Presenter';

const CM0027_1Api = () => {
    const getReSummarySubmittedProjectOutsourceOffsetCost = async (
        params: Params,
    ): Promise<any> => {
        try {
            const url = '/re-summary/submitted/project/outsource/offset-cost';
            const response = await axiosConfig.get(url, { params });
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    return {
        getReSummarySubmittedProjectOutsourceOffsetCost,
    };
};

export default CM0027_1Api;
