import { AxiosResponse } from "axios";
import axiosConfig from "../../../../../config/axiosConfig";
import { AM028Entity } from "../../entity/Entity";

const AM028Api = () => {
  const getOrganizationIsUserApprover = async (): Promise<
    AxiosResponse<any, any>
  > => {
    try {
      const url = "/organization/is-user-approver";
      return await axiosConfig.get(url);
    } catch (error) {
      throw error;
    }
  };

  const getListMonthlyAttendance = async (params: any): Promise<any[]> => {
    try {
      const url = "/request/attendance/monthly";
      return await axiosConfig.get(url);
    } catch (error) {
      throw error;
    }
  };

  const getListMyAttendance = async (params: any): Promise<any[]> => {
    try {
      const url = "/request/attendance/my-request";
      return await axiosConfig.get(url, { params });
    } catch (error) {
      throw error;
    }
  };
  const getListMyReviewAttendance = async (params: any): Promise<any[]> => {
    try {
      const url = "/request/attendance/my-review-request";
      return await axiosConfig.get(url, { params });
    } catch (error) {
      throw error;
    }
  };
  const getListMonthlyLateEarly = async (params: any): Promise<any[]> => {
    try {
      const url = "/request/late-early/monthly";
      return await axiosConfig.get(url, { params });
    } catch (error) {
      throw error;
    }
  };
  const getListMyLateEarly = async (params: any): Promise<any[]> => {
    try {
      const url = "/request/late-early/my-request";
      return await axiosConfig.get(url, { params });
    } catch (error) {
      throw error;
    }
  };

  const getListReviewLateEarly = async (params: any): Promise<any[]> => {
    try {
      const url = "/request/late-early/my-review-request";
      return await axiosConfig.get(url, { params });
    } catch (error) {
      throw error;
    }
  };

  const getListMonthlyLeave = async (params: any): Promise<any[]> => {
    try {
      const url = "/request/leave/monthly";
      return await axiosConfig.get(url, { params });
    } catch (error) {
      throw error;
    }
  };
  const getListMyLeave = async (params: any): Promise<any[]> => {
    try {
      const url = "/request/leave/my-request";
      return await axiosConfig.get(url, { params });
    } catch (error) {
      throw error;
    }
  };
  const getListMyReviewLeave = async (params: any): Promise<any[]> => {
    try {
      const url = "/request/leave/my-review-request";
      return await axiosConfig.get(url, { params });
    } catch (error) {
      throw error;
    }
  };

  const getListStatus = async (params: any): Promise<any[]> => {
    try {
      const url = "/request/status";
      return await axiosConfig.get(url, { params });
    } catch (error) {
      throw error;
    }
  };

  const getAttendanceCategory = async (params: any): Promise<any[]> => {
    try {
      const url = "/request/attendance/category";
      return await axiosConfig.get(url, { params });
    } catch (error) {
      throw error;
    }
  };
  const getLeaveCategory = async (params: any): Promise<any[]> => {
    try {
      const url = "/request/leave/category";
      return await axiosConfig.get(url, { params });
    } catch (error) {
      throw error;
    }
  };

  const getLateEarlyCategory = async (params: any): Promise<any[]> => {
    try {
      const url = "/request/late-early/category";
      return await axiosConfig.get(url, { params });
    } catch (error) {
      throw error;
    }
  };

  const approveAttendance = async (data: any): Promise<any[]> => {
    try {
      const url = "/request/approve/attendance";
      return await axiosConfig.post(url, data);
    } catch (error) {
      throw error;
    }
  };

  const approveLateEarly = async (data: any): Promise<any[]> => {
    try {
      const url = "/request/approve/late-early";
      return await axiosConfig.post(url, data);
    } catch (error) {
      throw error;
    }
  };
  const approveLeave = async (data: any): Promise<any[]> => {
    try {
      const url = "/request/approve/leave";
      return await axiosConfig.post(url, data);
    } catch (error) {
      throw error;
    }
  };

  const postRejectReason = async (data: any): Promise<any[]> => {
    try {
      const url = "/request/reject";
      return await axiosConfig.post(url, data);
    } catch (error) {
      throw error;
    }
  };

  return {
    getOrganizationIsUserApprover,
    getListMonthlyAttendance,
    getListMyAttendance,
    getListMyReviewAttendance,
    getListMonthlyLateEarly,
    getListMyLateEarly,
    getListReviewLateEarly,
    getListMonthlyLeave,
    getListMyLeave,
    getListMyReviewLeave,
    getListStatus,
    getAttendanceCategory,
    getLeaveCategory,
    getLateEarlyCategory,
    approveAttendance,
    approveLateEarly,
    approveLeave,
    postRejectReason,
  };
};

export default AM028Api;
