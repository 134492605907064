import Excel from "exceljs";
import FileSaver from "file-saver";
import COMMON from "../../../constants/COMMON";
import moment from "moment";
import {
  BORDER_STYLE_CELL,
  adjustBorderCell,
  adjustWithColumn,
  getColumnNameWithIndex,
} from "../common";

// ["得意先", "工事名", "施工場所"]
// ["施主", "設計管理業者", "建設業者", "工事費予想", "工期"]

const writeColumnName = (
  ws: any,
  startNameColumn: string,
  endNameColumn: string,
  valueNameColumn: string,
  valueEndName: string,
  listNameTitle: string[],
  start: number,
  step: number
) => {
  listNameTitle.forEach((item: any, idx: number) => {
    adjustWithColumn(ws, [`F`, "G", "H"], 20);

    ws.mergeCells(
      `${startNameColumn}${idx + start}:${endNameColumn}${idx + start}`
    );

    if (
      Number(idx + start) !== 17 &&
      Number(idx + start) !== 18 &&
      Number(idx + start) !== 19
    ) {
      ws.mergeCells(
        `${valueNameColumn}${idx + start + step}:${valueEndName}${
          idx + start + step
        }`
      );
      ws.getCell(`${startNameColumn}${idx + start}`).value = `${item.name}`;
      ws.getCell(`${startNameColumn}${idx + start}`).alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      ws.getCell(`${valueNameColumn}${idx + start}`).value = `${item.value}`;
      ws.getCell(`${valueNameColumn}${idx + start}`).alignment = {
        vertical: "middle",
        horizontal: "center",
      };
    } else {
      ws.getCell(`${"F"}${idx + start}`).value = `${item?.value1}`;
      ws.getCell(`${"F"}${idx + start}`).alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      ws.getCell(`${"G"}${idx + start}`).value = `${item?.value2}`;
      ws.getCell(`${"G"}${idx + start}`).alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      ws.getCell(`${"H"}${idx + start}`).value = `${item?.value3}`;
      ws.getCell(`${"H"}${idx + start}`).alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      ws.mergeCells(`C${idx + start}:E${idx + start}`);
      ws.mergeCells(`I${idx + start}:M${idx + start}`);
    }

    ws.getCell(`${startNameColumn}${idx + start}`).value = `${item.name}`;
    ws.getCell(`${startNameColumn}${idx + start}`).alignment = {
      vertical: "middle",
      horizontal: "center",
    };
  });
};

const writeMergeRow = (
  ws: any,
  startNameColumn: string,
  endNameColumn: string,
  start: number,
  end: number,
  name: string
) => {
  ws.mergeCells(`${startNameColumn}${start}:${endNameColumn}${end}`);
  ws.getCell(`${startNameColumn}${start}`).value = `${name}`;
  ws.getCell(`${startNameColumn}${start}`).alignment = {
    vertical: "middle",
    horizontal: "center",
  };
};

const addBorder = (
  ws: any,
  startNumberColumn: number,
  endNumberColumn: number,
  start: number,
  limit: number
) => {
  const listCellBorder: string[] = [];

  for (let i = startNumberColumn; i <= endNumberColumn; i++) {
    for (let j = startNumberColumn; j <= limit + 1; j++) {
      listCellBorder.push(`${getColumnNameWithIndex(i)}${start + j}`);
    }
  }

  adjustBorderCell(ws, listCellBorder, BORDER_STYLE_CELL);
};

const writeTableOne = (ws: any, otherField: string, dataOne: any[]) => {
  ws.mergeCells("C3:G3");
  ws.mergeCells("J3:M3");
  ws.mergeCells("H3:I3");
  ws.getCell("H3").value = "担当者様";
  ws.getCell("H3").alignment = {
    vertical: "middle",
    horizontal: "center",
  };
  ws.getCell("J3").value = otherField;
  ws.getCell("J3").alignment = {
    vertical: "middle",
    horizontal: "center",
  };
  writeColumnName(ws, "A", "B", "C", "M", dataOne, 3, 1);
  addBorder(ws, 1, 13, 0, 10);

  // ws.mergeCells("A12:M12")
};

const writeTableTwo = (ws: any, dataTwo: any[]) => {
  writeColumnName(ws, "A", "B", "C", "M", dataTwo, 13, 0);
  addBorder(ws, 1, 13, 12, 7);
};

const writeTableThree = (ws: any, dataThree: any) => {
  ws.mergeCells("A22:B22");
  ws.getCell("A22").value = "支払条件";
  ws.getCell("A22").fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "dddddddd" },
  };
  adjustBorderCell(ws, ["A22"], BORDER_STYLE_CELL);
  adjustWithColumn(ws, ["A"], 15);
  ws.getCell("A22").font = { bold: true, size: 10 };
  ws.getCell("A22").alignment = {
    vertical: "middle",
    horizontal: "center",
  };
  writeMergeRow(ws, "A", "B", 23, 24, "会社名");
  ws.mergeCells("C23:H23");
  ws.mergeCells("I24:J24");
  ws.mergeCells("I23:J23");
  ws.mergeCells("C26:H26");
  ws.mergeCells("I26:J26");
  ws.mergeCells("A25:B25");
  ws.mergeCells("A26:B26");
  ws.mergeCells("C25:M25");
  ws.mergeCells("K23:M23");
  ws.mergeCells("K24:M24");
  ws.mergeCells("K26:M26");
  ws.mergeCells("C24:H24");
  ws.getCell("C23").value = "　カナ" + dataThree?.nameKana ?? "";
  ws.getCell("C24").value = dataThree?.companyName ?? "";
  ws.getCell("I23").value = "Ｔ Ｅ Ｌ";
  ws.getCell("K23").value = dataThree?.tell ?? "";
  ws.getCell("K23").alignment = {
    vertical: "middle",
    horizontal: "center",
  };
  ws.getCell("I24").value = "Ｆ Ａ Ｘ";
  ws.getCell("K24").value = dataThree?.fax ?? "";
  ws.getCell("K24").alignment = {
    vertical: "middle",
    horizontal: "center",
  };
  ws.getCell("A25").value = "住所";
  ws.getCell("A25").alignment = {
    vertical: "middle",
    horizontal: "center",
  };
  ws.getCell("C25").value = "〒" + dataThree?.location ?? "";
  ws.getCell("A26").value = "確認先部署";
  ws.getCell("A26").alignment = {
    vertical: "middle",
    horizontal: "center",
  };
  ws.getCell("C26").value = dataThree?.departmentLocation ?? "";
  ws.getCell("I26").value = "確認先担当者名";
  ws.getCell("I26").alignment = {
    vertical: "middle",
    horizontal: "center",
  };
  ws.getCell("K26").value = dataThree?.picName ?? "";
  ws.getCell("K26").alignment = {
    vertical: "middle",
    horizontal: "center",
  };
  addBorder(ws, 1, 13, 22, 3);
};

const writeTableFour = (ws: any, dataFour: any) => {
  ws.mergeCells("A28:B29");
  ws.mergeCells("C28:M28");
  ws.mergeCells("C29:E29");
  ws.mergeCells("F29:M29");
  ws.mergeCells("A30:B30");
  ws.mergeCells("C30:M30");
  ws.mergeCells("A31:B31");
  ws.mergeCells("A32:B32");
  ws.mergeCells("C32:M32");
  ws.mergeCells("C31:M31");

  ws.getCell("A28").value = "締切日・支払日";
  ws.getCell("A28").alignment = {
    vertical: "middle",
    horizontal: "center",
  };
  ws.getCell("C28").value = dataFour?.stringDateOne ?? "";
  ws.getCell("C28").alignment = {
    vertical: "middle",
    horizontal: "center",
  };
  ws.getCell("C29").value = "銀行休業日の場合の支払日";
  ws.getCell("C29").alignment = {
    vertical: "middle",
    horizontal: "center",
  };
  ws.getCell("F29").value = dataFour?.categoryOne ?? "";
  ws.getCell("F29").alignment = {
    vertical: "middle",
    horizontal: "center",
  };
  ws.getCell("A30").value = "金　種";
  ws.getCell("A30").alignment = {
    vertical: "middle",
    horizontal: "center",
  };
  ws.getCell("C30").value = dataFour?.stringDateTwo ?? "";
  ws.getCell("C30").alignment = {
    vertical: "middle",
    horizontal: "center",
  };
  ws.getCell("A31").value = "備考";
  ws.getCell("C31").value = dataFour?.note ?? "";
  ws.getCell("A31").alignment = {
    vertical: "middle",
    horizontal: "center",
  };
  ws.getCell("A32").value = "手形集金方法";
  ws.getCell("C32").value = `${dataFour?.categoryTwo ?? ""}`;
  ws.getCell("A32").alignment = {
    vertical: "middle",
    horizontal: "center",
  };

  addBorder(ws, 1, 13, 27, 4);
};

const writeTableFive = (ws: any, dataFive: any) => {
  const out: string[] = [];
  dataFive?.role?.forEach((item: any, idx: number) => {
    out.push(`${getColumnNameWithIndex(idx + 2)}`);
  });

  ws.mergeCells(`A34:${out[out.length - 1]}34`);
  ws.getCell("A34").value = "回覧・承認";
  ws.getCell("A35").value = "役職";
  ws.getCell("A36").value = "氏名";
  ws.getCell("A37").value = "承認日";

  // Write role

  const roles: string[] = [];

  dataFive?.role?.forEach((item: any, idx: number) => {
    ws.getCell(`${getColumnNameWithIndex(idx + 2)}35`).value = item;
    adjustWithColumn(ws, [`${getColumnNameWithIndex(idx + 2)}`], 20);
    ws.getCell(`${getColumnNameWithIndex(idx + 2)}35`).alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    roles.push(`${getColumnNameWithIndex(idx + 2)}35`);
  });

  // Write name
  const names: string[] = [];

  dataFive?.name?.forEach((item: any, idx: number) => {
    ws.getCell(`${getColumnNameWithIndex(idx + 2)}36`).value = item;
    ws.getCell(`${getColumnNameWithIndex(idx + 2)}36`).alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    names.push(`${getColumnNameWithIndex(idx + 2)}36`);
  });

  // Write date

  const dates: string[] = [];
  dataFive?.date?.forEach((item: any, idx: number) => {
    ws.getCell(`${getColumnNameWithIndex(idx + 2)}37`).value = item;
    ws.getCell(`${getColumnNameWithIndex(idx + 2)}37`).alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    dates.push(`${getColumnNameWithIndex(idx + 2)}37`);
  });

  ws.getCell(`A37`).alignment = {
    vertical: "middle",
    horizontal: "center",
  };
  ws.getCell(`A36`).alignment = {
    vertical: "middle",
    horizontal: "center",
  };
  ws.getCell(`A35`).alignment = {
    vertical: "middle",
    horizontal: "center",
  };
  ws.getCell(`A34`).alignment = {
    vertical: "middle",
    horizontal: "center",
  };
  ws.getCell(`A34`).font = {
    bold: true,
  };

  adjustBorderCell(
    ws,
    ["A34", "A35", "A36", "A37", ...roles, ...names, ...dates],
    BORDER_STYLE_CELL
  );
};

const writeContent = (
  ws: any,
  tableOneOtherField: string,
  dataTableOne: any[],
  dataTableTwo: any[],
  dataThree: any,
  dataFour: any,
  dataFive: any
) => {
  // Write title

  ws.mergeCells(`A1:M1`);
  ws.getCell(`A1`).value = "工事引合書";
  ws.getCell(`A1`).alignment = {
    vertical: "middle",
    horizontal: "center",
  };
  ws.getCell(`A1`).font = { bold: true, size: 20 };
  ws.getRow(1).height = 25;

  ws.mergeCells(`A2:M2`);
  ws.getCell(`A2`).value = `出力日       ${moment().format(
    COMMON.FORMAT_DATE_CI
  )}`;
  ws.getCell(`A2`).alignment = { horizontal: "right" };

  // Write table 1
  writeTableOne(ws, tableOneOtherField, dataTableOne);

  // // Write table 2
  writeTableTwo(ws, dataTableTwo);

  // // Write table 3
  writeTableThree(ws, dataThree);

  // // Write table 4
  writeTableFour(ws, dataFour);

  // Wrive table 5
  writeTableFive(ws, dataFive);
};

const exportConstructionRequest = (
  raw: any,
  sheetName: string,
  fileName: string,
  data: any,
  setLoading: Function
) => {
  const wb = new Excel.Workbook();
  const ws = wb.addWorksheet(sheetName);

  // Remove auto filter

  ws.autoFilter = undefined;

  // Write file
  writeContent(
    ws,
    data?.tableOne?.otherField,
    data?.tableOne?.data,
    data?.tableTwo?.data,
    data?.tableThree,
    data?.tableFour,
    data?.tableFive
  );

  setLoading(true);

  wb.xlsx
    .writeBuffer()
    .then((buffer) => FileSaver.saveAs(new Blob([buffer]), `${fileName}.xlsx`))
    .catch((err: any) => {
      throw err;
    })
    .finally(() => setLoading(false));
};

export { exportConstructionRequest };
