import { AxiosResponse } from "axios";
import axiosConfig from "../../../../../config/axiosConfig";

const CompanyApi = () => {
  const getAttendanceCompanyColab = async (params: {
    keyword?: string;
    page: string;
    size: string;
    sortBy?: string;
    sortType?: "DESC" | "ASC";
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/re-summary/company/summary/outsource-cost/list-company";
    return await axiosConfig.get(url, { params });
  };

  return { getAttendanceCompanyColab };
};

export default CompanyApi;
