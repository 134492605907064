// Selection keys

export const OPTION_ONE = 'option1';
export const OPTION_TWO = 'option2';
export const OPTION_THREE = 'option3';
export const OPTION_FOUR = 'option4';

// Filter keys
export const SEARCH_KEY = 'text';

// Params keys
export const KEYWORD_PARAM = 'keyword';
export const PAGE_PARAM = 'page';
export const SIZE_PARAM = 'size';
export const SORT_TYPE = 'sortType';
export const SORT_BY = 'sortBy';
export const STATUS = 'status';
export const PLAN = 'plan';
export const SUBSCRIPTION = 'subscription';
export const TEXT_ALL_JP = 'すべて';
export const TEXT_VALID_JP = '有り';
export const TEXT_INVALID_JP = '無し';
export const CREATED_AT = 'createdAt';
export const RANGE_EXPIRED_DATE = 'rangeExpiredDate';
export const FROM_PARAM = 'from';
export const TO_PARAM = 'to';
export const SORT_TYPE_DESC = 'DESC';
export const SELECT_SUBSCRIPTION = [
    {
        code: 'valid',
        name: '有り',
        value: true,
    },
    {
        code: 'invalid',
        name: '無し',
        value: false,
    },
];
