import { createSlice } from "@reduxjs/toolkit";

type ApproveEditModalSlice = {
  document: any | null;
};
const initialState: ApproveEditModalSlice = {
  document: null,
};

export const ApproveEditModalSlice = createSlice({
  name: "ApproveEditModalSlice",
  initialState,
  reducers: {
    setDocumentEdit: (state, action) => {
      state.document = action.payload;
    },
    resetApproveEditModal: () => initialState,
  },
});

export const { setDocumentEdit, resetApproveEditModal } =
  ApproveEditModalSlice.actions;
export default ApproveEditModalSlice.reducer;
