import axios from "axios";
import axiosConfig from "../../../../../config/axiosConfig";

const PM007Api = () => {
  const getDataDetailProject = async (params: any): Promise<any> => {
    const url = "/project/detail";

    try {
      const response = await axiosConfig.get(url, { params });

      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getDataStatus = async (params: any): Promise<any> => {
    const url = "/project/status";

    try {
      const response = await axiosConfig.get(url, { params });

      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getDataMembers = async (params: any): Promise<any> => {
    const url = "/project/users";

    try {
      const response = await axiosConfig.get(url, { params });

      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const editDetailProject = async (data: any): Promise<any> => {
    const url = "/project/update";
    try {
      const response = await axiosConfig.put(url, data, {
        timeout: 40000,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  const getLinkPresignUpload = async (params: any): Promise<any> => {
    const url = "/project/presign-upload";

    try {
      const response = await axiosConfig.get(url, { params });

      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const uploadToS3 = async (url: any, data: any): Promise<any> => {
    try {
      const response = await axios.put(url, data, {
        headers: {
          "Content-Type": data.type,
        },
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  };
  const getPresignLinkS3 = async (params: any): Promise<any> => {
    const url = "/project/presign-link";

    try {
      const response = await axiosConfig.get(url, { params });

      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getPdfMap = async (params: any): Promise<any> => {
    try {
      const url = "/project/presign-link";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getListMember = async (params: any): Promise<any> => {
    try {
      const url = "/group/list-member-admin";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  return {
    getDataDetailProject,
    editDetailProject,
    getDataStatus,
    getDataMembers,
    getLinkPresignUpload,
    uploadToS3,
    getPresignLinkS3,
    getPdfMap,
    getListMember,
  };
};

export default PM007Api;
