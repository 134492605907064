import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import COMMON from "../../../../../../common/constants/COMMON";
import { useSearchParams } from "react-router-dom";
import { Form, Result } from "antd";
import { useState, useMemo } from "react";
import { usecase } from "../../usecase/ServiceImpl";
import { StatusElement } from "../../entity/Entity";
import { STATUS_CM_CHILD_TABLE } from "../../../../../../common/constants/COMMON";
import helpers from "../../../../../../common/helpers/common";
import {
  AUTHORIZATION_CODE,
  SYSTEM_ROLES,
} from "../../../../../../common/constants/AUTHORIZATION";
import { RootState } from "../../../../../../store";

export const MENU_ITEM = [
  {
    name: "請求出来高",
    key: "billingVolume",
  },
  {
    name: "工事原価",
    key: "constructionCost",
  },
  {
    name: "工事代金支払明細書",
    key: "paymentStatement",
  },
  {
    name: "社内収支",
    key: "internalBalance",
  },
];

const NUM_MENU = 8;

const STATUS_ALL = {
  name: "すべて",
  id: 0,
};

const PARAMS: any = {
  tab: "tab",
  menu: "menu",
};
const STATUS_SECOND = [
  STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE,
  STATUS_CM_CHILD_TABLE.MONEY_APPROVED.CODE,
  STATUS_CM_CHILD_TABLE.MONEY_REJECT.CODE,
];

const MONEY_NOT_APPROVED_CODE = "money_not_approved";
const MONEY_RETRIEVE_CODE = "money_retrieve";

for (let i = 1; i <= NUM_MENU; i++) {
  PARAMS[`keyword${i}`] = `keyword${i}`;
  PARAMS[`status${i}`] = `status${i}`;
  PARAMS[`from${i}`] = `from${i}`;
  PARAMS[`to${i}`] = `to${i}`;
  PARAMS[`clear${i}`] = `clear${i}`;
  PARAMS[`page${i}`] = `page${i}`;
}

export const DEFAULT_PARAM = {
  tab: "1",
  menu: MENU_ITEM[0].key,
  keyword: "",
  status: STATUS_ALL.id,
  from: moment().startOf("month").format(COMMON.FORMAT_DATE2),
  to: moment().format(COMMON.FORMAT_DATE2),
  clear: "0",
  page: 1,
  size: 10,
};

const funcCheckIndex = (tab: string, menu: string) => {
  let i = 1;
  if (tab === "1") {
    switch (menu) {
      case MENU_ITEM[0].key:
        i = 1;
        break;
      case MENU_ITEM[1].key:
        i = 2;
        break;
      case MENU_ITEM[2].key:
        i = 3;
        break;
      case MENU_ITEM[3].key:
        i = 4;
        break;
    }
  } else {
    switch (menu) {
      case MENU_ITEM[0].key:
        i = 5;
        break;
      case MENU_ITEM[1].key:
        i = 6;
        break;
      case MENU_ITEM[2].key:
        i = 7;
        break;
      case MENU_ITEM[3].key:
        i = 8;
        break;
    }
  }
  return i;
};

const Wrapper3453Handler = (service: usecase) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [formFilter] = Form.useForm();
  const [formSearch] = Form.useForm();

  const tab = searchParams.get(PARAMS.tab) || DEFAULT_PARAM.tab;
  const menu = searchParams.get(PARAMS.menu) || DEFAULT_PARAM.menu;
  const [statusData, setStatusData] = useState<StatusElement[]>([]);
  const [menuTab1, setMenuTab1] = useState<any[]>([]);
  const [menuTab2, setMenuTab2] = useState<any[]>([]);

  const authData = useSelector((state: RootState) => state.auth.authData);
  const menuItems = useSelector((state: RootState) => state.common.menuItems);
  const isRoleAdmin = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdmin
  );

  const isRoleAdminSystem = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdminSystem
  );

  const permission = useSelector(
    (state: RootState) => state.auth.authData?.permission
  );

  const subscription = useSelector(
    (state: RootState) => state.auth.authData?.order.subscription
  )?.map((item: any) => item.code);

  const isUserIsProjectManager = useSelector(
    (state: RootState) => state.auth.authData?.isUserIsProjectManager
  );
  const reCostWorkflow = useSelector(
    (state: RootState) => state.auth.authData?.reCostWorkflow
  );
  const reReceiveMoneyWorkflow = useSelector(
    (state: RootState) => state.auth.authData?.reReceiveMoneyWorkflow
  );
  const reSummaryCompanyOutsourceWorkflow = useSelector(
    (state: RootState) => state.auth.authData?.reSummaryCompanyOutsourceWorkflow
  );
  const reSummaryInternalWorkflow = useSelector(
    (state: RootState) => state.auth.authData?.reSummaryInternalWorkflow
  );
  const [tabItems, setTabItems] = useState<any[]>([]);
  const keyword: any = {};
  const status: any = {};
  const from: any = {};
  const to: any = {};
  const clear: any = {};
  const page: any = {};

  for (let i = 1; i <= NUM_MENU; i++) {
    keyword[i] =
      searchParams.get(PARAMS[`keyword${i}`]) || DEFAULT_PARAM.keyword;
    status[i] = searchParams.get(PARAMS[`status${i}`]) || DEFAULT_PARAM.status;
    from[i] = searchParams.get(PARAMS[`from${i}`]) || DEFAULT_PARAM.from;
    to[i] = searchParams.get(PARAMS[`to${i}`]) || DEFAULT_PARAM.to;
    clear[i] = +(searchParams.get(PARAMS[`clear${i}`]) || DEFAULT_PARAM.clear);
    page[i] = +(searchParams.get(PARAMS[`page${i}`]) || DEFAULT_PARAM.page);
  }

  const handleChangeMenuChosen = (menu: string) => {
    searchParams.set(PARAMS.menu, menu);
    setSearchParams(searchParams);
    const i = funcCheckIndex(tab, menu);
    formSearch.setFieldValue("keyword", keyword[i]);
    formFilter.setFieldsValue({
      status: Number(status[i]),
      targetPeriod: !clear[i] ? [moment(from[i]), moment(to[i])] : null,
    });
  };

  const handleFinishFormFilter = (filter: {
    keyword: string;
    status: string;
    targetPeriod: any[];
  }) => {
    const i = funcCheckIndex(tab, menu);
    searchParams.delete(PARAMS[`status${i}`]);
    searchParams.delete(PARAMS[`from${i}`]);
    searchParams.delete(PARAMS[`to${i}`]);

    if (filter.status && Number(filter.status) !== STATUS_ALL.id)
      searchParams.set(PARAMS[`status${i}`], filter.status);
    if (
      filter.targetPeriod &&
      filter.targetPeriod?.length > 0 &&
      (DEFAULT_PARAM.from !==
        moment(filter.targetPeriod[0]?._d).format(COMMON.FORMAT_DATE2) ||
        DEFAULT_PARAM.to !==
        moment(filter.targetPeriod[1]?._d).format(COMMON.FORMAT_DATE2))
    ) {
      searchParams.set(
        PARAMS[`from${i}`],
        moment(filter.targetPeriod[0]?._d).format(COMMON.FORMAT_DATE2)
      );
      searchParams.set(
        PARAMS[`to${i}`],
        moment(filter.targetPeriod[1]?._d).format(COMMON.FORMAT_DATE2)
      );
    }
    if (!filter.targetPeriod) searchParams.set(PARAMS[`clear${i}`], "1");
    else searchParams.delete(PARAMS[`clear${i}`]);

    // setDefault page
    searchParams.delete(PARAMS[`page${i}`]);

    setSearchParams(searchParams);
  };

  const handleChangeCurrentTab = (tab: any) => {
    searchParams.set(PARAMS.tab, tab);

    const defaultMenu = checkAuthorizationTab(tab);
    searchParams.set(PARAMS.menu, defaultMenu?.key ?? "");

    const i = funcCheckIndex(tab, defaultMenu?.key ?? "");
    formSearch.setFieldValue("keyword", keyword[i]);
    formFilter.setFieldsValue({
      keyword: keyword[i],
      status: Number(status[i]),
      targetPeriod: !clear[i] ? [moment(from[i]), moment(to[i])] : null,
    });
    setSearchParams(searchParams);
  };

  const handleClearFilter = () => {
    const i = funcCheckIndex(tab, menu);
    searchParams.delete(PARAMS[`status${i}`]);
    searchParams.delete(PARAMS[`from${i}`]);
    searchParams.delete(PARAMS[`to${i}`]);
    searchParams.delete(PARAMS[`clear${i}`]);
    searchParams.delete(PARAMS[`page${i}`]);

    // Delete params sort cm035
    searchParams.delete(`sortType${i}`);
    formFilter.setFieldValue("targetPeriod", [
      moment(DEFAULT_PARAM.from),
      moment(DEFAULT_PARAM.to),
    ]);
    formFilter.setFieldValue("status", Number(DEFAULT_PARAM.status));

    setSearchParams(searchParams);
  };

  const initFilter = () => {
    const i = funcCheckIndex(tab, menu);
    formFilter.setFieldsValue({
      keyword: keyword[i],
      status: Number(status[i]),
      targetPeriod: !clear[i] ? [moment(from[i]), moment(to[i])] : null,
    });
    formSearch.setFieldValue("keyword", keyword[i]);
  };

  let isSearching: any;
  const onSearch = () => {
    if (isSearching) clearTimeout(isSearching);
    isSearching = setTimeout(() => {
      const keyword = formSearch.getFieldValue("keyword");
      const i = funcCheckIndex(tab, menu);
      searchParams.set(PARAMS[`keyword${i}`], keyword);
      if (!keyword) searchParams.delete(PARAMS[`keyword${i}`]);
      searchParams.delete(PARAMS[`page${i}`]);

      formFilter.setFieldValue(
        "targetPeriod",
        !clear[i] ? [moment(from[i]), moment(to[i])] : null
      );
      formFilter.setFieldValue("status", Number(status[i]));
      setSearchParams(searchParams);
    }, 1000);
  };

  const getStatusData = async () => {
    const response = await service.getDocumentStatus();

    // if (tab === '1' && menu === 'constructionCost') {
    //   // setStatusData(
    //   //   [{ ...STATUS_ALL, code: STATUS_ALL.id }, ...response.results].filter(
    //   //     (item: StatusElement) => item.code !== MONEY_NOT_APPROVED_CODE
    //   //   )
    //   // );
    //   setStatusData(
    //     [{ ...STATUS_ALL, code: STATUS_ALL.id }, ...response.results]
    //   );
    // } else if (tab === '2' && menu === 'constructionCost') {
    //   setStatusData(
    //     [{ ...STATUS_ALL, code: STATUS_ALL.id }, ...response.results].filter(
    //       (item: StatusElement) =>
    //         item.code !== MONEY_NOT_APPROVED_CODE &&
    //         item.code !== MONEY_RETRIEVE_CODE
    //     )
    //   );
    // } else {
    //   setStatusData([
    //     { id: STATUS_ALL.id, name: STATUS_ALL.name },
    //     ...response.results,
    //   ]);
    // }

    setStatusData([
      { id: STATUS_ALL.id, name: STATUS_ALL.name },
      ...response.results,
    ]);
  };
  const statusList = useMemo(() => {
    if (tab === DEFAULT_PARAM.tab)
      return statusData.filter((status) => {
        return (
          status.code === STATUS_CM_CHILD_TABLE.MONEY_APPROVED.CODE ||
          status.code === STATUS_CM_CHILD_TABLE.MONEY_REJECT.CODE ||
          status.code ===
          STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE ||
          status.id === 0
        );
      });
    const results = statusData.filter((status) => {
      return STATUS_SECOND.includes(status.code) || status.id === STATUS_ALL.id;
    });
    return results;
  }, [statusData]);

  /*
  Tab 提出一覧 ( Gồm 4 tab 請求出来高 / 工事原価 / 工事代金支払明細書 / 社内収支 )
  Quyền fix cho PM được xem 2 Tab 請求出来高 và 工事原価
  Admin xem full 4 tab

  Tab 承認一覧 ( Gồm 4 tab 請求出来高 / 工事原価 / 工事代金支払明細書 / 社内収支 )
  API trả về true tab nào hiển thị tab đó
  Admin xem full 4 tab
  */

  const getDataForTab1 = () => {
    let tab1 = [];
    if (
      subscription?.includes(AUTHORIZATION_CODE.M17) &&
      (isRoleAdmin || isUserIsProjectManager)
      || permission?.includes(AUTHORIZATION_CODE.S49)
    )
      tab1.push(MENU_ITEM[0], MENU_ITEM[1]);

    if (
      subscription?.includes(AUTHORIZATION_CODE.M18) &&
      isRoleAdmin || permission?.includes(AUTHORIZATION_CODE.S45)
    )
      tab1.push(MENU_ITEM[2]);

    if (
      subscription?.includes(AUTHORIZATION_CODE.M19) &&
      isRoleAdmin || permission?.includes(AUTHORIZATION_CODE.S47)
    )
      tab1.push(MENU_ITEM[3]);
    return tab1;
  };

  const getDataForTab2 = () => {
    let tab2 = [];
    if (
      (subscription?.includes(AUTHORIZATION_CODE.M17) && isRoleAdmin) ||
      reReceiveMoneyWorkflow
    )
      tab2.push(MENU_ITEM[0]);

    if (
      (subscription?.includes(AUTHORIZATION_CODE.M17) && isRoleAdmin) ||
      reCostWorkflow
    )
      tab2.push(MENU_ITEM[1]);

    if (
      (subscription?.includes(AUTHORIZATION_CODE.M18) && isRoleAdmin) ||
      reSummaryCompanyOutsourceWorkflow
    )
      tab2.push(MENU_ITEM[2]);

    if (
      (subscription?.includes(AUTHORIZATION_CODE.M19) && isRoleAdmin) ||
      reSummaryInternalWorkflow
    )
      tab2.push(MENU_ITEM[3]);
    return tab2;
  };

  const initBigTab = (tab: string, menu?: string) => {
    let tab1 = getDataForTab1();

    let tab2 = getDataForTab2();

    setMenuTab1(tab1);
    setMenuTab2(tab2);
    if (tab1.length + tab2.length === 0) return;
    let defaultMenu = null;

    if (tab1.length === 0 && tab === "1") {
      searchParams.set(PARAMS.tab, "2");
      defaultMenu = tab2.length > 0 ? tab2[0] : null;
      setTabItems(tab2);
    } else if (tab2.length === 0 && tab === "2") {
      searchParams.set(PARAMS.tab, "1");
      defaultMenu = tab1.length > 0 ? tab1[0] : null;
      setTabItems(tab1);
    } else if (tab === "1") {
      setTabItems(tab1);
    } else if (tab === "2") {
      setTabItems(tab2);
    }
    if (defaultMenu) {
      searchParams.set(PARAMS.menu, defaultMenu?.key ?? "");
      const i = funcCheckIndex(tab, defaultMenu?.key ?? "");
      formSearch.setFieldValue("keyword", keyword[i]);
      formFilter.setFieldsValue({
        keyword: keyword[i],
        status: Number(status[i]),
        targetPeriod: !clear[i] ? [moment(from[i]), moment(to[i])] : null,
      });
    }
    setSearchParams(searchParams);
  };

  const checkAuthorizationTab = (tab: string, menu?: string) => {
    let data = tab === "1" ? getDataForTab1() : getDataForTab2();
    setTabItems(data);

    const isMenuInData = menu && data.some((element) => element.key === menu);
    if (!isMenuInData) {
      const defaultMenu = data.length > 0 ? data[0] : null;
      if (defaultMenu) {
        searchParams.set(PARAMS.menu, defaultMenu?.key ?? "");
        const i = funcCheckIndex(tab, defaultMenu?.key ?? "");
        formSearch.setFieldValue("keyword", keyword[i]);
        formFilter.setFieldsValue({
          keyword: keyword[i],
          status: Number(status[i]),
          targetPeriod: !clear[i] ? [moment(from[i]), moment(to[i])] : null,
        });
        setSearchParams(searchParams);
      }
    }

    return data.length > 0 ? data[0] : null;
  };

  return {
    formFilter,
    formSearch,
    tab,
    menu,
    status,
    page,
    keyword,
    statusList,
    tabItems,
    menuTab1,
    menuTab2,
    handleChangeMenuChosen,
    handleFinishFormFilter,
    handleChangeCurrentTab,
    handleClearFilter,
    initFilter,
    onSearch,
    getStatusData,
    checkAuthorizationTab,
    initBigTab,
  };
};

export default Wrapper3453Handler;
