import React from 'react';
import CompanyRowComponent from './CompanyRow';
import { RootState } from '../../../../../store';
import { useSelector } from 'react-redux';
import helpers from '../../../../../common/helpers/common';
import Handler from '../../../../AM/AM042/presenter/handler/Handler';

interface props {
    outsourceCompanies: any[];
    summaryTotal: any;
    setCompanyExpandKeys: (key: any) => void;
    checkCompanyKeyExpand: (key: any) => boolean;
    setProjectExpandKeys: (key: any) => void;
    checkProjectKeyExpand: (key: any) => boolean;
}

const Table = ({
    outsourceCompanies,
    summaryTotal,
    setCompanyExpandKeys,
    checkCompanyKeyExpand,
    setProjectExpandKeys,
    checkProjectKeyExpand,
}: props) => {
    return (
        <div className="cm024-table">
            <table>
                <thead>
                    <tr>
                        <th colSpan={2} style={{ minWidth: 150 }}>
                            No
                        </th>
                        <th>業者名</th>
                        <th style={{ minWidth: 300 }}></th>
                        <th style={{ minWidth: 200 }}></th>
                        <th style={{ minWidth: 250 }}>支払金額(税抜)</th>
                        <th style={{ minWidth: 250 }}>消費税</th>
                        <th style={{ minWidth: 250 }}>支払金額(税込)</th>
                        <th style={{ minWidth: 40 }}></th>
                    </tr>
                </thead>
                <tbody>
                    {outsourceCompanies.map((element, index) => (
                        <CompanyRowComponent
                            key={element.id}
                            index={index}
                            data={element}
                            setCompanyExpandKeys={setCompanyExpandKeys}
                            checkCompanyKeyExpand={checkCompanyKeyExpand}
                            setProjectExpandKeys={setProjectExpandKeys}
                            checkProjectKeyExpand={checkProjectKeyExpand}
                        />
                    ))}
                </tbody>
                <tfoot>
                    <tr className="table-foot">
                        <td colSpan={2}>合計金額</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{`${helpers.formatNumberMoney(summaryTotal.actualCost)}円`}</td>
                        <td>{`${helpers.formatNumberMoney(summaryTotal.consumptionTax)}円`}</td>
                        <td>{`${helpers.formatNumberMoney(
                            summaryTotal.paymentAmountIncludingTax,
                        )}円`}</td>
                        <td></td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};

export default Table;
