// Library
import { Button, Tabs } from 'antd';

// Components
import { CM0061_1ElementComponent } from './CM0061_1ElementComponent';
import { CM0061_2ElementComponent } from './CM0061_2ElementComponent';

// Icons
import UploadLightSVG from '../../../../../assets/icons/upload-icon-purple.svg';

// Handler

// Common
import { DEFAULT_TAB_KEY, DEFAULT_TAB_VALUE, PROJECT_PARAM_ID_KEY } from '../handler/Handler';
import { CM001Handler } from '../../../CM001/presenter/handler/HandlerImpl';
import { AUTHORIZATION_CODE, } from '../../../../../common/constants/AUTHORIZATION';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { useSearchParams } from 'react-router-dom';

type props = {
    handler: CM001Handler;
    projectName: string;
    rangeDate: string;
    tab: string;
};

export const CM0061ContentComponent = ({ handler, tab, projectName, rangeDate }: props) => {
    const [urlSearchParams] = useSearchParams();
    const projectId: string = urlSearchParams.get(PROJECT_PARAM_ID_KEY) ?? '0';
    const permission = useSelector((state: RootState) => state.auth.authData?.permission);
    const isRoleAdmin = useSelector((state: RootState) => state.auth?.authData?.isRoleAdmin)

    const isRoleAdminSystem =
        useSelector((state: RootState) => state.auth?.authData?.isRoleAdminSystem)

    return (
        <>
            <div className="cm0061-content-tab">
                <div className="tab">
                    <Tabs activeKey={tab} onChange={handler.chooseTabView}>
                        <Tabs.TabPane tab="案件" key="1"></Tabs.TabPane>
                        <Tabs.TabPane tab="工事別" key="2"></Tabs.TabPane>
                    </Tabs>
                </div>
                <div className="btn-export">
                    {permission?.includes(AUTHORIZATION_CODE.B9) || isRoleAdmin || isRoleAdminSystem ? (
                        <Button
                            className="button-brown-3"
                            style={{ border: '1px solid #605BFF', width: 106 }}
                            size="large"
                            onClick={() => handler.exportData(tab, projectName, rangeDate, projectId)}
                        >
                            <div className="center-item">
                                <img
                                    alt=""
                                    src={UploadLightSVG}
                                    style={{
                                        marginRight: 8,
                                        fontWeight: 700,
                                        fontSize: 14,
                                    }}
                                />
                                <div
                                    className="export-button"
                                    style={{ color: "#605BFF" }}
                                >
                                    出力
                                </div>
                            </div>
                        </Button>
                    ) : (
                        <div></div>
                    )}
                </div>
            </div>
            {tab === '1' ? <CM0061_1ElementComponent handler={handler} /> : <></>}
            {tab === '2' ? <CM0061_2ElementComponent handler={handler} /> : <></>}
        </>
    );
};
