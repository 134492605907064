import DB007Api from './infrastructure/api/Api';
import DB007Handler from './presenter/handler/Handler';
import DB007UI from './presenter/ui/UI';
import DB007Service from './usecase/Service';

const DB007Component = () => {
    const db007Api = DB007Api();
    const db007Service = DB007Service(db007Api);
    const db007Handler = DB007Handler(db007Service);
    return <DB007UI handler={db007Handler} />;
};

export default DB007Component;
