// Core library
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

// Redux
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../../common/slice/CommonSlice";

// Services
import { CM0017ServiceImpl } from "./../../usecase/ServiceImpl";

// Entity

// Common
import COMMON, { TAX_CODE } from "../../../../../common/constants/COMMON";
import helpers from "../../../../../common/helpers/common";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import moment from "moment";
import { Form } from "antd";
import {
  ReSummaryCompanySummaryOutsourceCostResult,
  ReSummaryCompanySummaryOutsourceCostDetail,
  ReSummaryCompanySummaryOutsourceCostDetailResult,
  ReSummaryCompanySummaryOutsourceCostTotalResult,
  ReSummaryCompanySummaryOutsourceCostSubmitPayload,
  ReSummaryCompanyDocumentOutsourceCostStatusResult,
  MoneyDirectCostOutsourceOffsetList,
  OffsetCost,
} from "../../entity/Entity";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import {
  setHeaderDataCM0012_2,
  setIsVisibleCM0012_2,
  setParentCodeCM0012_2,
} from "../../../CM0012_2/presenter/slice/Slice";
import { refreshCM0017 } from "../slice/Slice";
import ConfirmModalAsync from "../../../../../common/components/modal/ConfirmModalAsync";
import { SubCategory } from "../../../CM0024/entity/Entity";
import { doExportForResponse } from "../../../../../common/helpers/exports/common";
import {
  setIsVisibleCM017_2_1,
  setParentCodeCM017_2_1,
} from "../../../CM017_2_1/presenter/slice/Slice";
import {
  setIsUpdateCM017_2_2,
  setIsVisibleCM017_2_2,
  setParentCodeCM017_2_2,
} from "../../../CM017_2_2/presenter/slice/Slice";

const CM0017Handler = (service: CM0017ServiceImpl) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [costDetail, setCostDetail] =
    useState<ReSummaryCompanySummaryOutsourceCostDetail>();
  const [summaryTotal, setSummaryTotal] =
    useState<ReSummaryCompanySummaryOutsourceCostTotalResult>({
      actualCost: 0,
      consumptionTax: 0,
      cost: 0,
      deductionCost: 0,
      offsetCostWithTax: 0,
      offsetCostWithoutTax: 0,
      paidCost: 0,
      planCost: 0,
      reserveCost: 0,
      orderMoney: 0,
      tax: 0,
    });
  const [summaryCost, setSummaryCost] =
    useState<ReSummaryCompanySummaryOutsourceCostResult>();
  const [summaryStatus, setSummaryStatus] =
    useState<ReSummaryCompanyDocumentOutsourceCostStatusResult>();
  const [isSubmitVisible, setIsSubmitVisible] = useState<boolean>(false);
  const [subCategory, setSubCategory] = useState<SubCategory[]>([]);
  const [taxSetting, setTaxSetting] = useState<number>(0);

  const onCancelSubmitModal = () => {
    setIsSubmitVisible(false);
  };
  const onOpenSubmitModal = () => {
    setIsSubmitVisible(true);
  };
  const getReSummaryCompanySummaryOutsourceCostDetail = async (params: {
    companyId: number;
    colabRefId: number;

    keyword?: string;
    sortBy?: string;
    sortType?: string;
    page?: string;
    size?: string;
    from?: string;
    to?: string;
  }): Promise<any> => {
    try {
      dispatch(setLoading(true));
      const responseData =
        await service.getReSummaryCompanySummaryOutsourceCostDetail(params);
      let finalResult: ReSummaryCompanySummaryOutsourceCostDetailResult[] = [];
      const numPages = responseData?.pagination?.numPages ?? 1;
      finalResult = finalResult.concat(responseData.results);
      for (let i = 2; i <= numPages; i++) {
        const responseData2 =
          await service.getReSummaryCompanySummaryOutsourceCostDetail({
            ...params,
            page: i + "",
          });
        finalResult = finalResult.concat(responseData2.results);
      }
      const newResult = finalResult.map(
        (element: ReSummaryCompanySummaryOutsourceCostDetailResult) => {
          return {
            ...element,
            tax: element.actualCost - element.reserveCost,
          };
        }
      );
      setCostDetail({
        pagination: responseData?.pagination,
        results: newResult,
      });
    } catch (error: any) {
      ErrorNotification(error.message || NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getReSummaryCompanyDocumentOutsourceCostStatus = async (params: {
    colabRefId: number;
    companyId: number;
    from?: string;
    to?: string;
  }): Promise<any> => {
    try {
      dispatch(setLoading(true));
      const responseData =
        await service.getReSummaryCompanyDocumentOutsourceCostStatus(params);
      if (responseData) setSummaryStatus(responseData);
    } catch (error: any) {
      ErrorNotification(error.message || NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getReSummaryCompanySummaryOutsourceCostTotal = async (params: {
    companyId: number;
    colabRefId: number;

    from?: string;
    to?: string;
  }): Promise<any> => {
    try {
      dispatch(setLoading(true));
      const responseData =
        await service.getReSummaryCompanySummaryOutsourceCostTotal(params);
      if (responseData) setSummaryTotal(responseData);
    } catch (error: any) {
      ErrorNotification(error.message || NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getReSummaryCompanySummaryOutsourceCost = async (params: {
    companyId: number;
    colabRefId: number;

    from?: string;
    to?: string;
  }): Promise<any> => {
    try {
      dispatch(setLoading(true));
      const responseData =
        await service.getReSummaryCompanySummaryOutsourceCost(params);
      setSummaryCost(responseData);
    } catch (error: any) {
      ErrorNotification(error.message || NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };
  const postReSummaryCompanySummaryOutsourceCostSubmit = async (params: {
    colabRefId: number;
    endDate: string;
    startDate: string;
    companyId: number;
    fileName: string;
    companyName: string;
  }) => {
    ConfirmModalAsync({
      onOk: async () => {
        try {
          dispatch(setLoading(true));
          const payload: ReSummaryCompanySummaryOutsourceCostSubmitPayload = {
            endDate: params.endDate,
            fileName: params.fileName,
            startDate: params.startDate,
            companyId: params.companyId,
            colabRefId: params.colabRefId,
          };

          const response =
            await service.postReSummaryCompanySummaryOutsourceCostSubmit(
              payload
            );
          if (response) {
            dispatch(refreshCM0017());
            SuccessNotification(
              response?.message ?? NOTIFICATION_TITLE.SUCCESS
            );
          }
        } catch (error: any) {
          ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        } finally {
          setIsSubmitVisible(false);
          dispatch(setLoading(false));
        }
      },
      onCancel: () => {},
      title: MESSAGE.MESSAGE_TITLE_BEFORE_CREATE,
      description: params.companyName + MESSAGE.MESSAGE_DESCRIPTION_CR_14_2,
      description2: MESSAGE.MESSAGE_DESCRIPTION_COMMON_QUESTION_2,
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_MODAL_2,
    });
  };
  const openCM0017_2 = (value: {
    companyId: number;
    colabRefId: number;
    from?: string;
    to?: string;
  }) => {
    dispatch(setIsVisibleCM017_2_1(true));
    dispatch(
      setParentCodeCM017_2_1({
        categoryId: null,
        companyId: value.companyId,
        colabRefId: value.colabRefId,
      })
    );
  };

  const openCM0012_2 = (value: any) => {
    dispatch(setIsVisibleCM0012_2(true));
    dispatch(
      setParentCodeCM0012_2({
        categoryId: value.categoryId,
        outsourceCostInformationId:
          value.documentDirectOutsourceCostInformationId,
      })
    );
    dispatch(
      setHeaderDataCM0012_2({
        traderName: value?.companyName ?? "",
        contractWorkName: value?.construction?.name ?? "",
        targetPeriod: helpers.toTargetPeriodFormat(
          value.startDate,
          value.endDate
        ),
      })
    );
  };

  let myVar: any;
  const onDateFilterChange = (open: boolean) => {
    if (open) return;
    if (myVar) clearTimeout(myVar);
    myVar = setTimeout(function () {
      const filterDate = form.getFieldValue("filterDate");
      searchParams.set(
        "startDate",
        moment(filterDate[0]).format(COMMON.FORMAT_DATE4)
      );
      searchParams.set(
        "endDate",
        moment(filterDate[1]).format(COMMON.FORMAT_DATE4)
      );
      setSearchParams(searchParams);
    }, 1000);
  };

  const initiateFilter = (fromDate: string, toDate: string) => {
    form.setFieldValue("filterDate", [moment(fromDate), moment(toDate)]);
  };

  const refreshData = () => {
    dispatch(refreshCM0017());
  };

  const getMoneyDirectCostOutsourceOffsetList = async (
    costDetail: ReSummaryCompanySummaryOutsourceCostDetailResult
  ) => {
    try {
      if (!costDetail)
        return {
          tax: [],
          taxFree: [],
        };

      // can use promise to maximize effect
      const offsetCostWithTax = costDetail.offsetCost?.find(
        (element: OffsetCost) => element.category.code === TAX_CODE.TAX
      );
      const offsetCostWithoutTax = costDetail.offsetCost?.find(
        (element: OffsetCost) => element.category.code === TAX_CODE.TAX_FREE
      );

      const data = await Promise.all([
        service.getMoneyDirectCostOutsourceOffsetList({
          categoryId: offsetCostWithTax?.category.id,
          outsourceCostInformationId:
            costDetail.documentDirectOutsourceCostInformationId,
        }),
        service.getMoneyDirectCostOutsourceOffsetList({
          categoryId: offsetCostWithoutTax?.category.id,
          outsourceCostInformationId:
            costDetail.documentDirectOutsourceCostInformationId,
        }),
      ]);
      return {
        ...costDetail,
        tax: data[0].map((element) => ({
          ...element,
          constructionName: costDetail?.contractorConstructionName ?? "",
        })),
        taxFree: data[1].map((element) => ({
          ...element,
          constructionName: costDetail?.contractorConstructionName ?? "",
        })),
      };
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const getMoneyDirectCostOutsourceOffsetListPromiseAll = async (
    costDetail?: ReSummaryCompanySummaryOutsourceCostDetail
  ) => {
    try {
      if (!costDetail) return null;
      const responseData = await Promise.all(
        costDetail?.results.map((element) => {
          return getMoneyDirectCostOutsourceOffsetList(element);
        })
      );
      let tax: MoneyDirectCostOutsourceOffsetList[] = [];
      let taxFree: MoneyDirectCostOutsourceOffsetList[] = [];
      costDetail.results.forEach((element) => {
        const foundOffset = responseData.find(
          (offsetElement: any) =>
            offsetElement?.documentDirectOutsourceCostInformationId ===
            element.documentDirectOutsourceCostInformationId
        );
        if (foundOffset) {
          tax = tax.concat(foundOffset.tax);
          taxFree = taxFree.concat(foundOffset.taxFree);
        }
      });
      return {
        tax,
        taxFree,
      };
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const exportFile = async (
    companyId: number,
    companyName: string,
    from?: string,
    to?: string,
    colabRefId?: number
  ) => {
    try {
      dispatch(setLoading(true));
      const screenName = "工事代金支払明細書";
      const submitDate = moment().format(COMMON.FORMAT_DATE_SUBMIT);
      const response = await service.doExportCM0017({
        companyName: companyName,
        companyId,
        from,
        to,
        colabRefId,
      } as any);
      doExportForResponse(
        response,
        `${screenName}_${companyName}_${submitDate}.xlsx`
      );
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const convertCodeToCategoryId = (code: string) => {
    const res = subCategory?.find((item: SubCategory) => {
      return item.code === code;
    });
    return res?.value;
  };

  const handleFormatSubCategory = (data: any) => {
    const arr: SubCategory[] = data.map((item: any) => {
      return {
        label: item.description,
        value: item.id,
        code: item.code,
      };
    });
    return arr;
  };

  const handleGetSubCategory = async (): Promise<any> => {
    try {
      dispatch(setLoading(true));
      const res = await service.getSubCategory();
      if (res?.results) {
        setSubCategory(handleFormatSubCategory(res.results));
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const funcViewModalCM017_1 = (value: {
    companyId: number;
    colabRefId: number;

    code: string;
    from?: string;
    to?: string;
  }) => {
    const categoryId = convertCodeToCategoryId(value.code);
    dispatch(setIsVisibleCM017_2_2(true));
    dispatch(setIsUpdateCM017_2_2(true));
    dispatch(
      setParentCodeCM017_2_2({
        companyId: value.companyId,
        colabRefId: value.colabRefId,
        categoryId: categoryId,
        from: value.from ? helpers.getFilterTimeMonth(value.from) : undefined,
        to: value.to ? helpers.getFilterTimeMonthTo(value.to) : undefined,
      })
    );
  };
  const getTax = async () => {
    try {
      dispatch(setLoading(true));
      const response = await service.getTaxSetting({});
      setTaxSetting(response?.results?.taxSetting ?? 0);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };
  return {
    form,
    costDetail,
    summaryTotal,
    summaryCost,
    summaryStatus,
    isSubmitVisible,
    taxSetting,
    refreshData,
    openCM0012_2,
    openCM0017_2,
    initiateFilter,
    onDateFilterChange,
    getReSummaryCompanySummaryOutsourceCostDetail,
    getReSummaryCompanySummaryOutsourceCostTotal,
    getReSummaryCompanySummaryOutsourceCost,
    postReSummaryCompanySummaryOutsourceCostSubmit,
    getReSummaryCompanyDocumentOutsourceCostStatus,
    exportFile,
    onCancelSubmitModal,
    onOpenSubmitModal,
    handleGetSubCategory,
    funcViewModalCM017_1,
    getTax,
  };
};

export default CM0017Handler;
