import { CostType } from './../../../entity/Entity';
import { MoneySummaryConstructionCostInformationResult } from '../../../entity/Entity';
import helpers from '../../../../../../common/helpers/common';

const convertDataSource = (data: MoneySummaryConstructionCostInformationResult | null) => {
    if (!data) return [];
    return [
        {
            key: 'directCost',
            name: '直接工事費',
            ...data.directCost,
        },
        {
            key: 'indirectCost',
            name: '間接工事費',
            ...data.indirectCost,
        },
    ];
};

const convertDataSourceDirectCost = (data: {
    laborCost?: CostType;
    materialCost?: CostType;
    outsourceCost?: CostType;
}) => {
    if (!data) return [];
    const results: any[] = [];
    if (data.materialCost)
        results.push({
            key: 'materialCost',
            name: '材料費',
            ...data.materialCost,
        });
    if (data.outsourceCost)
        results.push({
            key: 'outsourceCost',
            name: '外注費',
            ...data.outsourceCost,
        });
    if (data.laborCost)
        results.push({
            key: 'laborCost',
            name: '労務費',
            ...data.laborCost,
        });

    return results;
};

const calculateBudgetUtilizationRate = (value: CostType) => {
    const result = (value.actualCost * 100) / value.planCost;
    if (isNaN(result)) return 0;
    return helpers.formatNumberMoney(Math.round(result * 100) / 100);
};
const calculateBudgetBalance = (value: CostType) => {
    const result = value.planCost - value.actualCost;
    if (isNaN(result)) return 0;
    return result;
};
export {
    convertDataSource,
    convertDataSourceDirectCost,
    calculateBudgetUtilizationRate,
    calculateBudgetBalance,
};
