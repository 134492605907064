import { ReceivedMoneyOffset } from '../entity/Entity';
import { CM017_2Api } from './ServiceImpl';

const CM017_2Service = (cm017_2: CM017_2Api) => {
    const getListOutsourceCostOffset = async (params: any): Promise<any> => {
        try {
            return await cm017_2.getListOutsourceCostOffset(params);
        } catch (error) {
            throw error;
        }
    };

    const getSubCategory = async (): Promise<any> => {
        try {
            return await cm017_2.getSubCategory();
        } catch (error) {
            throw error;
        }
    };

    const createReceivedMoneyOffset = async (data: any): Promise<any> => {
        try {
            return await cm017_2.createReceivedMoneyOffset(data);
        } catch (error) {
            throw error;
        }
    };

    const deleteReceivedMoneyOffset = async (data: any): Promise<any> => {
        try {
            return await cm017_2.deleteReceivedMoneyOffset(data);
        } catch (error) {
            throw error;
        }
    };

    const editReceivedMoneyOffset = async (data: any): Promise<any> => {
        try {
            return await cm017_2.editReceivedMoneyOffset(data);
        } catch (error) {
            throw error;
        }
    };

    return {
        getListOutsourceCostOffset,
        getSubCategory,
        createReceivedMoneyOffset,
        deleteReceivedMoneyOffset,
        editReceivedMoneyOffset,
    };
};

export default CM017_2Service;
