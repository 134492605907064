import { createSlice } from "@reduxjs/toolkit";

export type SM010SliceType = {
  isVisible: boolean;
  loading: boolean;
  refresh: boolean;
};

const initialState: SM010SliceType = {
  loading: false,
  isVisible: false,
  refresh: false,
};

export const SM010Slice = createSlice({
  name: "SM010",
  initialState,
  reducers: {
    resetSM010: () => initialState,
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsVisibleSM010: (state, action) => {
      state.isVisible = action.payload;
    },
    setRefreshSM010: (state) => {
      state.refresh = !state.refresh;
    },
  },
});

export const { setLoading, setIsVisibleSM010, resetSM010, setRefreshSM010 } =
  SM010Slice.actions;

export default SM010Slice.reducer;
