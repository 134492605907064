
import { FormInstance } from "antd";
import HandlerDoubleCheck from "../../handler/HandlerDoubleCheck";
import DoubleCheckButtonUI from "./ui/UI";

type props = {
  form: FormInstance,
  constructionId: number,
  doubleCheck: boolean | null,
  userTimeSheetId: number

}

const DoubleCheckComponent = ({ form, constructionId, doubleCheck, userTimeSheetId }: props) => {

  const handler = HandlerDoubleCheck()
  return <DoubleCheckButtonUI
    handler={handler}
    constructionId={constructionId}
    doubleCheck={doubleCheck}
    userTimeSheetId={userTimeSheetId}
    form={form}
  />

};

export default DoubleCheckComponent;
