import { Button, DatePicker, Form, Popover } from 'antd';
import CalendarSvg from '../../../../../../../assets/icons/calendar.svg';
import FilterIconSvg from '../../../../../../../assets/icons/filter-icon.svg';

import locale from 'antd/es/date-picker/locale/ja_JP';
import COMMON from '../../../../../../../common/constants/COMMON';
import moment from 'moment';
import React from 'react';
import { RangeDateType } from '../../../../../AM002/entity/Entity';

type Props = {
  onFilter: () => void;
  onResetFilter: () => void;
  onFromDateChange: (value: any) => void;
  onToDateChange: (value: any) => void;
  isCollapsed: boolean;
  rangeDate: RangeDateType;
};

const FilterComponent = ({
  onFilter,
  onResetFilter,
  onFromDateChange,
  onToDateChange,
  isCollapsed,
  rangeDate,
}: Props) => {
  const disabledFromDate = (current: any, rangeDate: RangeDateType) => {
    if (
      moment(current).format(COMMON.FORMAT_DATE_OM) ===
      moment(rangeDate.to).format(COMMON.FORMAT_DATE_OM)
    )
      return false;
    return current && current > rangeDate.to;
  };
  const disabledToDate = (current: any, rangeDate: RangeDateType) => {
    if (
      moment(current).format(COMMON.FORMAT_DATE_OM) ===
      moment(rangeDate.from).format(COMMON.FORMAT_DATE_OM)
    )
      return false;
    return (
      current &&
      (rangeDate.from
        ? current < rangeDate.from || current > moment().endOf('day')
        : current > moment().endOf('day'))
    );
  };
  const content = (
    <React.Fragment>
      <label className="filter-label">開始日</label>
      <Form.Item name={'startDate'} style={{ marginTop: 8 }}>
        <DatePicker
          allowClear={false}
          onChange={onFromDateChange}
          disabledDate={(current) => disabledFromDate(current, rangeDate)}
          format={(value: moment.Moment) => {
            const isToday = moment().isSame(value, 'day');
            if (isToday) return COMMON.TODAY;
            return value.format(COMMON.FORMAT_DATE_OM);
          }}
          className="ci001-date-picker w-100-percent"
          inputReadOnly
          locale={locale}
          getPopupContainer={(trigger: any) => trigger.parentNode}
          placeholder=""
          suffixIcon={<img alt="" src={CalendarSvg} className="icon-calender" />}
        />
      </Form.Item>
      <label className="filter-label">終了日</label>
      <Form.Item name={'endDate'} style={{ marginTop: 8 }}>
        <DatePicker
          allowClear={false}
          onChange={onToDateChange}
          disabledDate={(current) => disabledToDate(current, rangeDate)}
          format={(value: moment.Moment) => {
            const isToday = moment().isSame(value, 'day');
            if (isToday) return COMMON.TODAY;
            return value.format(COMMON.FORMAT_DATE_OM);
          }}
          className="ci001-date-picker w-100-percent"
          inputReadOnly
          locale={locale}
          getPopupContainer={(trigger: any) => trigger.parentNode}
          placeholder=""
          suffixIcon={<img alt="" src={CalendarSvg} className="icon-calender" />}
        />
      </Form.Item>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button onClick={onFilter} size="large" style={{ width: 97 }} type="primary">
          絞込み
        </Button>
        <Button
          className="reset-filter-btn"
          onClick={onResetFilter}
          size="large"
          style={{ width: 97 }}
        >
          クリア
        </Button>
      </div>
    </React.Fragment>
  );

  return <main style={{ paddingTop: 24 }}>{content}</main>;
};

export default FilterComponent;
