// Built-in
import React, { useEffect } from 'react';

// 3rd party lib
import { Table, DatePicker, Form } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import locale from 'antd/es/date-picker/locale/ja_JP';

// Source files
import { PlannedPaidAmountHandlerImpl } from '../../../handler/HandlerImpl';
import COMMON from '../../../../../../../common/constants/COMMON';

// Image files
import CalendarBlueSVG from '../../../../../../../assets/icons/calendar-blue.svg';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store';

const COLUMN_WIDTH_COMPLETED_PAID_AMOUNT = ['20%', '20%', '20%', '20%', '20%'];

type props = {
    handler: PlannedPaidAmountHandlerImpl;
};
const PlannedPaidAmountUI = ({ handler }: props) => {
    // Params
    const [searchParams] = useSearchParams();
    const constructionId = searchParams.get('constructionId');
    const fromDate = searchParams.get('filterDate') && moment(searchParams.get('filterDate')).isValid()
        ? moment(searchParams.get('filterDate')).startOf('month').format(COMMON.FORMAT_DATE2)
        : moment().startOf('month').format(COMMON.FORMAT_DATE2);
    const toDate = searchParams.get('filterDate') && moment(searchParams.get('filterDate')).isValid()
        ? moment(searchParams.get('filterDate')).endOf('month').format(COMMON.FORMAT_DATE2)
        : moment().endOf('month').format(COMMON.FORMAT_DATE2);
    const moneySummaryPlanCostResponse = useSelector(
        (state: RootState) => state.cm005.moneySummaryPlanCostResponse,
    );
    useEffect(() => {
        (async () => {
            if (constructionId && !isNaN(parseInt(constructionId)))
                await handler.getMoneySummaryPlanCost({
                    constructionId: parseInt(constructionId),
                    from: fromDate,
                    to: toDate,
                });
        })();
    }, [constructionId, fromDate, toDate]);

    useEffect(() => {
        handler.initiateFilter();
    }, []);

    const columns: ColumnsType<any> = [
        {
            title: '材料費',
            key: '材料費',
            align: 'center',
            width: COLUMN_WIDTH_COMPLETED_PAID_AMOUNT[0],
            render: (_, record, index) => {
                return record.materialPlanCost
                    ? `${record.materialPlanCost.toLocaleString('en-US', {
                        maximumFractionDigits: 2,
                    })}円`
                    : 0;
            },
        },
        {
            title: '外注費',
            key: '外注費',
            align: 'center',
            width: COLUMN_WIDTH_COMPLETED_PAID_AMOUNT[1],
            render: (_, record, index) => {
                return record.outsourcePlanCost
                    ? `${record.outsourcePlanCost.toLocaleString('en-US', {
                        maximumFractionDigits: 2,
                    })}円`
                    : 0;
            },
        },
        {
            title: '労務費',
            key: '労務費',
            align: 'center',
            width: COLUMN_WIDTH_COMPLETED_PAID_AMOUNT[2],
            render: (_, record, index) => {
                return record.laborPlanCost
                    ? `${record.laborPlanCost.toLocaleString('en-US', {
                        maximumFractionDigits: 2,
                    })}円`
                    : 0;
            },
        },
        {
            title: '間接工事費',
            key: '間接工事費',
            align: 'center',
            width: COLUMN_WIDTH_COMPLETED_PAID_AMOUNT[3],
            render: (_, record, index) => {
                return record.indirectPlanCost
                    ? `${record.indirectPlanCost.toLocaleString('en-US', {
                        maximumFractionDigits: 2,
                    })}円`
                    : 0;
            },
        },
        {
            title: '総工事費',
            key: '総工事費',
            align: 'center',
            width: COLUMN_WIDTH_COMPLETED_PAID_AMOUNT[4],
            render: (_, record, index) => {
                const total =
                    (record.materialPlanCost || 0) +
                    (record.outsourcePlanCost || 0) +
                    (record.laborPlanCost || 0) +
                    (record.indirectPlanCost || 0);
                return total
                    ? `${total.toLocaleString('en-US', {
                        maximumFractionDigits: 2,
                    })}円`
                    : 0;
            },
        },
    ];

    return (
        <Table
            title={() => (
                <main
                    className="cm005-title-header"
                    style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
                >
                    <div>支払予定金額</div>
                    <div>
                        <Form form={handler.form} style={{ margin: 0, padding: 0 }}>
                            <Form.Item name="filterDate" style={{ margin: 0, padding: 0 }}>
                                <DatePicker
                                    picker='month'
                                    onChange={() => handler.onFilterDateChange()}
                                    allowClear={false}
                                    placeholder=''
                                    inputReadOnly
                                    style={{
                                        borderRadius: 6,
                                        marginRight: 16,
                                        borderColor: '#605BFF',
                                        color: '#605BFF',
                                        width: 135,
                                        background: '#DAE2EE',
                                    }}
                                    locale={locale}
                                    className="cm005-datepicker-font"
                                    format={COMMON.FORMAT_DATE_DB}
                                    getPopupContainer={(trigger: any) => trigger.parentNode}
                                    suffixIcon={<img alt="" src={CalendarBlueSVG} />}
                                />
                            </Form.Item>
                        </Form>
                    </div>
                </main>
            )}
            bordered={true}
            columns={columns}
            dataSource={[
                {
                    key: 'planned-paid-amount',
                    ...moneySummaryPlanCostResponse,
                },
            ]}
            className="collapsed-table-header table-footer-inside cm005-table-header"
            pagination={false}
        />
    );
};

export default PlannedPaidAmountUI;
