import AOP003Api from './infrastructure/api/Api';
import AOP003Handler from './presenter/handler/Handler';
import AOP003Service from './usecase/Service';
import MainUI from './presenter/ui/UI';

import '../../../assets/styles/AOP/aop003.css';

const AOP003Component = () => {
    const api = AOP003Api();

    const service = AOP003Service(api);

    const handler = AOP003Handler(service);

    return <MainUI handler={handler} />;
};

export default AOP003Component;
