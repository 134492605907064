import axiosConfig from "../../../../../config/axiosConfig";

const DB004Api = () => {
  const getBudgetUtilizationRate = async (params: {
    projectId: number;
    from: string;
    to: string;
  }) => {
    const url = "/dashboard/budget-utilization-rate";
    return await axiosConfig.get(url, { params });
  };

  return {
    getBudgetUtilizationRate,
  };
};

export default DB004Api;
