import { H3EntityData } from './H3Entity';

const ROWS: H3EntityData[] = [
    {
        id: 1,
        name: '案件別収支管理　閲覧',
        allowProjectManager: true,
        allowDeputyProjectManager: false,
        allowItemCreator: false,
        allowProjectMember: false,
        isAdmin: true,
    },
    {
        id: 2,
        name: '案件別収支管理　追加・編集',
        allowProjectManager: true,
        allowDeputyProjectManager: false,
        allowItemCreator: false,
        allowProjectMember: false,
        isAdmin: true,
    },
    {
        id: 3,
        name: '案件別収支管理　提出',
        allowProjectManager: true,
        allowDeputyProjectManager: false,
        allowItemCreator: false,
        allowProjectMember: false,
        isAdmin: true,
    },
];
const convertToDisplayTable = (data: H3EntityData[]) => {
    return data;
};

export { ROWS, convertToDisplayTable };
