import React from 'react';
import CM009Handler from './presenter/handler/Handler';
import MainUI from './presenter/ui/UI';
import '../../../assets/styles/AM/AM028.css';
import '../../../assets/styles/AM/AM002.css';
import '../../../assets/styles/OM/OM002.css';
import '../../../assets/styles/CM/cm3854.css';
import '../../../assets/styles/CM/CM007.css';

const CM009Component = () => {
    const handler = CM009Handler();
    return <MainUI handler={handler} />;
};

export default CM009Component;
