import React from 'react';
import { Modal, Form, Input, Button, FormInstance } from 'antd';

import IconCircleRed from '../../../assets/icons/infor-circle.svg';
import helpers from '../../helpers/common';
import REGEX from '../../constants/REGEX';
import MESSAGE from '../../constants/MESSAGE';

import '../../../assets/styles/AM/AM028.css';

type props = {
    visible: boolean;
    form: FormInstance;

    funcSubmit(value: any): void;
    funcChangeValue(value: any): void;
    funcCancel(): void;
};

const ModalReject = ({ visible, form, funcSubmit, funcChangeValue, funcCancel }: props) => {
    return (
        <Modal
            className="am028-modal"
            centered
            visible={visible}
            width={'50%'}
            closeIcon={<></>}
            footer={null}
            maskClosable={false}
            zIndex={1001}
        >
            <div className="am028-modal-title">
                <img src={IconCircleRed} alt="" />
                <span>否認理由</span>
            </div>
            <Form
                name="rejectReason"
                form={form}
                onFinish={funcSubmit}
                onValuesChange={funcChangeValue}
            >
                <div className="am028-modal-form-item">
                    <span className="am028-modal-form-item-label">理由</span>
                    <Form.Item
                        name="rejectReason"
                        rules={[
                            () => ({
                                validator(_, value) {
                                    if (value && value !== '') {
                                        // CHECK INPUT VIETNAMESE
                                        if (!REGEX.NOT_VIETNAM.test(value)) {
                                            return Promise.reject(
                                                new Error(MESSAGE.MESSAGE_INPUT_INVALID),
                                            );
                                        }
                                        if (value.length > 200) {
                                            return Promise.reject(new Error(''));
                                        }
                                        return Promise.resolve();
                                    }
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                    >
                        <Input.TextArea
                            size="large"
                            autoSize={{
                                minRows: 1,
                                maxRows: 8,
                            }}
                            autoComplete={undefined}
                            onBlur={() => helpers.onBlurTrimFormItem(form, 'rejectReason')}
                            showCount={{
                                formatter: ({ count }: { count: number }) => `${count}/200`,
                            }}
                        />
                    </Form.Item>
                </div>
                <div className="am028-modal-form-btn">
                    <Button
                        size="large"
                        className="button-purple"
                        htmlType="submit"
                        style={{
                            letterSpacing: -1,
                            marginRight: 16,
                        }}
                    >
                        送信
                    </Button>
                    <Button size="large" onClick={funcCancel}>
                        キャンセル
                    </Button>
                </div>
            </Form>
        </Modal>
    );
};

export default ModalReject;
