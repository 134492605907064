import { uuidv4 } from '@firebase/util';
import { Form } from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ErrorNotification from '../../../../../common/components/notification/ErrorNotification';
import { NOTIFICATION_TITLE } from '../../../../../common/constants/MESSAGE';
import { RootState } from '../../../../../store';
import { ReSummarySubmittedOutsourceCostOffset } from '../../entity/Entity';
import { CM0017_3_1UseCase } from '../../usecase/ServiceImpl';
import { setIsVisibleCM0017_3_1, setLoadingCM0017_3_1 } from '../slice/Slice';

const CM0017_3_1Handler = (CM0017_3_1Service: CM0017_3_1UseCase) => {
    // LIB FUNCTION
    const dispatch = useDispatch();

    //STATE
    const [form] = Form.useForm();
    const typeModal = useSelector((state: RootState) => state.cm0017_3_1.typeModal);
    const [
        reSummarySubmittedOutsourceCostOffsetListLocal,
        setReSummarySubmittedOutsourceCostOffsetListLocal,
    ] = useState<ReSummarySubmittedOutsourceCostOffset[]>([]);
    const [totalCost, setTotalCost] = useState<number>(0);

    //function
    const getReSummarySubmittedOutsourceCostOffsetList = async (
        documentOutsourceCostDataInformationSummaryId: number,
        categoryId?: number,
    ): Promise<any> => {
        try {
            dispatch(setLoadingCM0017_3_1(true));
            const params = {
                documentOutsourceCostDataInformationSummaryId,
                categoryId,
            };
            const res = await CM0017_3_1Service.getReSummarySubmittedOutsourceCostOffsetList(
                params,
            );
            if (res?.results) {
                const data = formatReSummarySubmittedOutsourceCostOffsetList(res.results);
                setReSummarySubmittedOutsourceCostOffsetListLocal(data);
                handleCalculateChange(data);
            }
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        } finally {
            dispatch(setLoadingCM0017_3_1(false));
        }
    };

    const formatReSummarySubmittedOutsourceCostOffsetList = (data: any) => {
        const arr: ReSummarySubmittedOutsourceCostOffset[] = data.map(
            (item: any, index: number) => {
                return {
                    key: uuidv4(),
                    id: item?.id,
                    item: item?.name,
                    taxCategoryComsumptionId: item?.category?.id,
                    taxCategoryComsumptionName: item?.category?.description,
                    offsetAmount: item?.value,
                };
            },
        );
        return arr;
    };

    const handleCalculateChange = (data: ReSummarySubmittedOutsourceCostOffset[]) => {
        let total: number = 0;
        data.forEach((item) => {
            const cost = item?.offsetAmount
                ? item.offsetAmount
                : form.getFieldValue(`offsetAmount${item.key}`) ?? 0;
            if (!isNaN(cost)) total += cost;
        });
        setTotalCost(total);
    };

    const onCancel = () => {
        dispatch(setIsVisibleCM0017_3_1(false));
    };

    return {
        form,
        typeModal,
        reSummarySubmittedOutsourceCostOffsetListLocal,
        totalCost,
        onCancel,
        handleCalculateChange,
        getReSummarySubmittedOutsourceCostOffsetList,
    };
};

export default CM0017_3_1Handler;
