import { createSlice } from "@reduxjs/toolkit";

type ApproveViewModalSlice = {
  isEdit: boolean | null;
  document: any | null;
};
const initialState: ApproveViewModalSlice = {
  document: null,
  isEdit: null,
};

export const ApproveViewModalSlice = createSlice({
  name: "ApproveViewModalSlice",
  initialState,
  reducers: {
    setDocumentView: (state, action) => {
      state.document = action.payload;
    },
    setIsEdit: (state, action) => {
      state.isEdit = action.payload;
    },
    resetApproveViewModal: () => initialState,
  },
});

export const { setDocumentView, setIsEdit, resetApproveViewModal } =
  ApproveViewModalSlice.actions;
export default ApproveViewModalSlice.reducer;
