import React from 'react';
import { Tabs } from 'antd';

const TAB_DATA: any[] = [
    {
        id: 1,
        value: '工事収支',
    },
    {
        id: 2,
        value: '請求出来高',
    },
    {
        id: 3,
        value: '直接工事費',
    },
    {
        id: 4,
        value: '間接工事費',
    },
];

type props = {
    onChange: (value: any) => void;
    tab: string;
};

const TabComponent = ({ onChange, tab }: props) => {
    return (
        <Tabs onChange={onChange} activeKey={tab}>
            {TAB_DATA.map((element) => (
                <Tabs.TabPane tab={element.value} key={element.id + ''} />
            ))}
        </Tabs>
    );
};

export default TabComponent;
