// eslint-disable-next-line @typescript-eslint/no-unused-vars

import axiosConfig from '../../../../../config/axiosConfig';

const PM005Api = () => {
    const getDetailProject = async (params: any): Promise<any> => {
        try {
            const url = '/project/detail';
            const response = await axiosConfig.get(url, { params });
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    const postAddConstruction = async (params: { projectId: number }): Promise<any> => {
        try {
            const urlGet = '/project/generate-construction-name';
            const urlPost = '/project/create-construction-addition';
            const responseConstructionGet = await axiosConfig.get(urlGet, { params });
            const responseConstructionPost = await axiosConfig.post(urlPost, {
                ...params,
                stt: responseConstructionGet.data.results.id,
                constructionName: responseConstructionGet.data.results.generateName,
            });
            return responseConstructionPost;
        } catch (error) {
            throw error;
        }
    };

    const getPdfMap = async (params: any): Promise<any> => {
        try {
            const url = '/project/presign-link';
            const response = await axiosConfig.get(url, { params });
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    const deleteConstruction = async (data: any): Promise<any> => {
        try {
            const url = '/project/delete-construction';
            const response = await axiosConfig.delete(url, { data });

            return response;
        } catch (error) {
            throw error;
        }
    };

    const updateNameConstruction = async (data: any): Promise<any> => {
        try {
            const url = '/project/update-construction-name';
            const response = await axiosConfig.put(url, data);

            return response;
        } catch (error) {
            throw error;
        }
    };

    const getNoteConstruction = async (data: any): Promise<any> => {
        try {
            const url = '/';
            const response = await axiosConfig.get(url, data);

            return response;
        } catch (error) {
            throw error;
        }
    };

    const updateNoteConstruction = async (data: any): Promise<any> => {
        try {
            const url = '/project/add-note-for-construction';
            const response = await axiosConfig.put(url, data);

            return response;
        } catch (error) {
            throw error;
        }
    };

    const getApprovalProcessCR = async (params: any): Promise<any> => {
        try {
            const url = '/project/cr-approval-process';
            const response = await axiosConfig.get(url, {
                params,
            });
            return response;
        } catch (error) {
            throw error;
        }
    };

    const getApprovalProcessCI = async (params: any): Promise<any> => {
        try {
            const url = '/project/ci-approval-process';
            const response = await axiosConfig.get(url, {
                params,
            });
            return response;
        } catch (error) {
            throw error;
        }
    };

    return {
        getDetailProject,
        postAddConstruction,
        getPdfMap,
        deleteConstruction,
        updateNameConstruction,
        getNoteConstruction,
        updateNoteConstruction,
        getApprovalProcessCI,
        getApprovalProcessCR,
    };
};

export default PM005Api;
