// Built-in
import React, { useEffect, useRef, useState } from "react";

// 3rd party lib
import { useDispatch, useSelector } from "react-redux";

// Source files
import { MaterialCostServiceImpl } from "./../../usecase/ServiceImpl";
import {
  DocumentDirectMaterialData,
  MoneyDirectCostMaterialListCompanyResultState,
  MoneySubmitDirectCostMaterialPayload,
  ParentStatus,
} from "../../entity/Entity";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import {
  refreshCM007,
  setCheckedCollapseDirect,
  setCheckedCollapseParent,
  setParentStatus,
} from "../slice/Slice";
import moment from "moment";
import COMMON, {
  STATUS_CM_CHILD_TABLE,
  STATUS_CM_PARENT_TABLE,
} from "../../../../../common/constants/COMMON";
import { setIsEnable } from "../../../CM004/presenter/slice/Slice";
import { RootState } from "../../../../../store";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import ConfirmModalAsync from "../../../../../common/components/modal/ConfirmModalAsync";
import { useSearchParams } from "react-router-dom";
import { doExportForResponse } from "../../../../../common/helpers/exports/common";
import exportFileDataCM007 from "../../../../../common/helpers/export-excel/CM007";

const MaterialCostHandler = (service: MaterialCostServiceImpl) => {
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const projectName = searchParams.get("projectName");

  const [data, setData] = useState<
    MoneyDirectCostMaterialListCompanyResultState[]
  >([]);
  const [checkedList, setCheckedList] = useState<any[]>([]);
  const [checkAll, setCheckAll] = useState(false);
  const [expandKey, setExpandKey] = useState<any[]>([]);
  const parentStatus = useSelector(
    (state: RootState) => state.cm007.parentStatus
  );
  const constructionInfo = useSelector(
    (state: RootState) => state.cm004.constructionInfo
  );
  const checkedCollapse = useSelector(
    (state: RootState) => state.cm007.checkedCollapse
  );
  const screenName = COMMON.CM_FILE_STRUCTURE.SCREEN_NAME;
  const tab1 = COMMON.CM_FILE_STRUCTURE.PARENT_TAB.TAB_3.NAME;
  const tab2 = COMMON.CM_FILE_STRUCTURE.PARENT_TAB.TAB_3.CHILDREN_TAB[0];
  const submitDate = moment().format(COMMON.FORMAT_DATE_SUBMIT);
  const fileName: string = `${screenName}_${tab1}_${tab2}_${submitDate}`;
  /**
    Hiển thị: 提出
    Điều kiện enable button: Tồn tại record ở mục #8.3 có trạng thái 未承認
    Click button sẽ submit thông tin #8.3 thành 1 file tài liệu để gửi lên cấp trên duyệt
    Click button sẽ hiển thị popup confirm, thực hiện submit sẽ hiển thị message submit thành công giống màn CR001
    Tài liệu được lưu ở màn CM0034
    Sau khi submit thì chuyển trạng thái của record chưa submit ở mục #8.3 từ 未承認 sang 承認待ち
    Tên file submit đặt theo format: Tên màn_Tên tab_năm_tháng_ngày submit                                                                               "											
    */
  const checkIsEnableSubmitButton = (checkedList: any[]) => {
    const isEnable = checkedList?.length > 0 ? true : false;
    dispatch(setIsEnable(isEnable));
  };

  const putMoneySubmitDirectCostMaterial = async (): Promise<any> => {
    ConfirmModalAsync({
      onOk: async () => {
        try {
          dispatch(setLoading(true));
          const listDocumentDirectMaterialData: DocumentDirectMaterialData[] =
            checkedCollapse.map((element) => {
              const materialCost = data.find((ele) => ele.id === element.id);
              /*  [Tên dự án_Tên công trình_Tên màn_Tên tab1_Tên tab2_Tên công ty_năm_tháng_ngày submit] */
              const constructionName = constructionInfo?.constructionName ?? ""; //Tên công trình
              const companyName = materialCost?.materialCostCompany?.name ?? ""; //Tên công ty

              const fileName: string = `${projectName}_${constructionName}_${screenName}_${tab1}_${tab2}_${companyName}_${submitDate}`;

              const dataPayload: DocumentDirectMaterialData = {
                fileName: fileName,

                documentDirectMaterialCostId: element.id,
                listDocumentDirectMaterialCostInformationId:
                  element?.checked ?? [],
              };
              return dataPayload;
            });

          const response = await service.putMoneySubmitDirectCostMaterial({
            listDocumentDirectMaterialData,
          });
          setCheckedList([]);
          setCheckAll(false);
          dispatch(setCheckedCollapseDirect([]));
          dispatch(refreshCM007());
          SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
        } catch (error: any) {
          ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        } finally {
          dispatch(setLoading(false));
        }
      },
      onCancel: () => { },
      className: "confirm__modal confirm__modal-purple-oke",
      title: MESSAGE.MESSAGE_TITLE_BEFORE_CREATE,
      description: MESSAGE.MESSAGE_DESCRIPTION_CR_9_1,
      description2: MESSAGE.MESSAGE_DESCRIPTION_COMMON_QUESTION_2,

      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_MODAL_2,
    });
  };

  const putMoneyDirectCostMaterialEnable = async (
    payload: any
  ): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const response = await service.putMoneyDirectCostMaterialEnable({
        id: payload.id,
        enable: payload.enable,
        threshold: payload.threshold,
      });
      SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
    } catch (error: any) {
      ErrorNotification(NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const setDataAndParentStatus = async (params: {
    constructionId: number;
    keyword?: string;
    page?: number;
    size?: number;
    sortBy?: string;
    sortType?: string;
  }) => {
    try {
      dispatch(setLoading(true));
      const { parentStatusArr, finalResult } =
        await getMoneyDirectCostMaterialListCompany(params);
      dispatch(setParentStatus(parentStatusArr));
      setData(finalResult);
    } catch (error: any) {
      ErrorNotification(NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getMoneyDirectCostMaterialListCompany = async (params: {
    constructionId: number;
    keyword?: string;
    page?: number;
    size?: number;
    sortBy?: string;
    sortType?: string;
  }): Promise<any> => {
    try {
      const responseData = await service.getMoneyDirectCostMaterialListCompany(
        params
      );
      let finalResult: any[] = [];
      finalResult = finalResult.concat(responseData?.results ?? []);
      if (finalResult && finalResult.length > 0)
        setExpandKey([finalResult[0].id]);

      // get child
      for (let i = 0; i < finalResult.length; i++) {
        if (finalResult[i].id) {
          const childResponseData =
            await service.getMoneyDirectCostMaterialList({
              documentDirectMaterialCostId: finalResult[i].id,
            });
          finalResult[i].child = childResponseData;
        }
      }

      /*
            Có 3 giá trị: 承認済 & 承認待ち & 未承認
            Điều kiện hiển thị trạng thái 承認済: Khi tất cả các record ở mục #11.4 có trạng thái 承認済
            Điều kiện hiển thị trạng thái 承認待ち: Khi có ít nhất record ở mục #11.4 có trạng thái 承認待ち
            Điều kiện hiển thị trạng thái 未承認: Khi tồn tại 1 record ở mục #11.4 có 1 trong 3 trạng thái 未承認, 取り下げ, 否認
            */
      const parentStatusArr: ParentStatus[] = [];
      for (let i = 0; i < finalResult.length; i++) {
        let status = STATUS_CM_PARENT_TABLE.REJECTED.LABEL;
        let actualCost: number = 0;

        if (finalResult[i]?.child?.length > 0) {
          let isAllApproved: boolean = true;
          let isAllWaitingForApproval: boolean = false;
          for (let j = 0; j < finalResult[i].child.length; j++) {
            if (
              finalResult[i].child[j]?.status?.code !==
              STATUS_CM_CHILD_TABLE.MONEY_APPROVED.CODE
            ) {
              isAllApproved = false;
            } else if (
              finalResult[i].child[j]?.status?.code ===
              STATUS_CM_CHILD_TABLE.MONEY_APPROVED.CODE
            )
              actualCost += finalResult[i].child[j].actualCost || 0;

            if (
              finalResult[i].child[j].status.code ===
              STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE
            )
              isAllWaitingForApproval = true;
          }
          if (isAllApproved) status = STATUS_CM_PARENT_TABLE.APPROVED.LABEL;
          else if (isAllWaitingForApproval)
            status = STATUS_CM_PARENT_TABLE.PENDING.LABEL;
        }
        parentStatusArr.push({
          id: finalResult[i].id,
          status: status,
          actualCost: actualCost,
          childrenNumber: finalResult[i]?.child?.length ?? 0,
        });
      }
      return {
        parentStatusArr,
        finalResult,
      };
    } catch (error: any) {
      throw error;
    }
  };

  const isCollapsed = (record: any) => {
    return expandKey.some((element) => element === record.key);
  };

  const onChangeCollapseRow = (record: any, collapsed: boolean) => {
    const collapsedRow = collapsed
      ? [...expandKey, record.key]
      : expandKey.filter((element) => element !== record.key);
    setExpandKey(collapsedRow);
  };

  const exportFile = async (params: {
    constructionId: number;
    keyword?: string;
    page?: number;
    size?: number;
    sortBy?: string;
    sortType?: string;
    projectName: string;
  }) => {
    try {
      dispatch(setLoading(true));

      const response = await service.doExportCM007({
        constructionId: params.constructionId,
        keyword: params.keyword,
        page: params.page,
        size: params.size,
        sortBy: params.sortBy,
        sortType: params.sortType,
        projectName,
      } as any);
      doExportForResponse(response, fileName + ".xlsx");
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  /* Tackling checkbox function */
  const isCheckbox = (record: any): boolean =>
    checkedList.some((element) => element === record.key);

  const onCheckboxChange = (record: any, checked: boolean) => {
    
    // setCheckedList(list);
    let newCheckList: any[] = [];

    if (record) {
      if (checked) {
        newCheckList = [...checkedList, record.key];
      } else {
        const checkedData = checkedList.filter(
          (element: any) => element !== record.key
        );
        newCheckList = checkedData;
      }
    }
    
    const possibleItems: any[] = [];

    for (let element of parentStatus) {
      if (
        element?.status === STATUS_CM_PARENT_TABLE.APPROVED.LABEL ||
        element?.status === STATUS_CM_PARENT_TABLE.PENDING.LABEL ||
        element.childrenNumber === 0
      )
        continue;
      possibleItems.push(element.id);
    }
    newCheckList = [...new Set(newCheckList)];
    setCheckedList(newCheckList);
    setCheckAll(newCheckList.length === possibleItems.length);
    if (record) {
      dispatch(setCheckedCollapseParent({ id: record.id, checked: checked }));
    }
  };

  const onCheckAllChange = (checked: boolean) => {
    setCheckAll(checked);
    if (checked) {
      const checkedItems: any[] = [];
      for (let element of parentStatus) {
        if (
          element?.status === STATUS_CM_PARENT_TABLE.APPROVED.LABEL ||
          element?.status === STATUS_CM_PARENT_TABLE.PENDING.LABEL ||
          element.childrenNumber == 0
        )
          continue;
        checkedItems.push(element.id);
      }
      setCheckedList(checkedItems);

      // redux
      const checkedCollapseId = checkedCollapse.map((element) => element.id);
      const uncheckedData: any[] = [];
      checkedItems.forEach((element) => {
        const isExisted = checkedCollapseId.includes(element);
        if (!isExisted) uncheckedData.push({ id: element, checked: [] });
      });
      dispatch(
        setCheckedCollapseDirect([...checkedCollapse, ...uncheckedData])
      );
    } else {
      setCheckedList([]);
      dispatch(setCheckedCollapseDirect([]));
    }
  };

  return {
    data,
    checkedList,
    checkAll,
    expandKey,
    onCheckAllChange,
    onCheckboxChange,
    isCheckbox,
    isCollapsed,
    onChangeCollapseRow,
    putMoneyDirectCostMaterialEnable,
    putMoneySubmitDirectCostMaterial,
    checkIsEnableSubmitButton,
    exportFile,
    setDataAndParentStatus,
  };
};

export default MaterialCostHandler;
