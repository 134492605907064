import axiosConfig from "../../../../../../../config/axiosConfig";

const CollapseContractorApi = () => {
  // Checkbox Member
  const getListContractorMember = async (params: unknown): Promise<any> => {
    try {
      const url = "/invite/subconstruction/list-member";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  return { getListContractorMember };
};

export default CollapseContractorApi;
