import React, { useEffect, useRef } from "react";
import { HanlderConstructionImpl } from "../../../handler/HandlerImpl";
import { Empty, Table, TableColumnsType } from "antd";
import { useSearchParams } from "react-router-dom";
import { SubConstructionType } from "../../../../entity/Entity";
import moment from "moment";
import helpers from "../../../../../../../common/helpers/common";

interface props {
  handler: HanlderConstructionImpl;
  construction: any;
}

const ConstructionUI = ({ handler, construction }: props) => {
  // Avoid first render
  const firstRender = useRef(false);

  const [searchParams] = useSearchParams();
  const fromDate = searchParams.get("fromDate") || undefined;
  const toDate = searchParams.get("toDate") || undefined;

  useEffect(() => {
    if (construction.constructions && Array.isArray(construction.constructions))
      handler.setSubConstruction(construction.constructions);
  }, [JSON.stringify(construction.constructions)]);

  useEffect(() => {
    if (firstRender.current)
      (async () => {
        if (construction.id && construction.rootConstructionId) {
          handler.getAttendanceSubConstructionListSubConstruction({
            contractorConstructionId: construction.id,
            constructionId: construction.rootConstructionId,
            from: fromDate,
            to: toDate,
          });
        }
      })();
    firstRender.current = true;
  }, [
    construction.id,
    construction.rootConstructionId,
    fromDate,
    toDate,
    JSON.stringify(handler.filter),
  ]);

  const columns: TableColumnsType<SubConstructionType> = [
    {
      title: <div></div>,
      key: "ghost",
      width: "1%",
    },
    {
      title: "No",
      key: "No",
      width: "9%",
      render: (_, record, index) => index + 1,
    },
    {
      title: "工事名",
      key: "工事名",
      width: "18%",
      render: (record) => record.rootConstructionName || "",
    },
    {
      title: "契約工事名",
      key: "契約工事名",
      width: "18%",
      render: (record) => record.shortDescription || "",
    },
    {
      title: "工期",
      key: "工期",
      width: "18%",
      render: (record) =>
        `${moment(record.startTime).format("YYYY/MM/DD")} ~ ${moment(
          record.endTime
        ).format("YYYY/MM/DD")}`,
    },
    {
      title: "合計金額",
      key: "合計金額",
      width: "18%",
      sorter: (a, b) => a?.laborCost - b?.laborCost,
      showSorterTooltip: false,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (record) => helpers.formatCurrency(record?.laborCost) + "円",
    },
    {
      title: "稼働人工数",
      key: "稼働人工数",
      width: "18%",
      sorter: (a: any, b: any) => a.totalWorkingDay - b.totalWorkingDay,
      showSorterTooltip: false,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (record) =>
        `${record.numberOfMember ? record.totalWorkingDay : 0}人工`,
    },
  ];

  return (
    <Table
      onRow={(record) => {
        return {
          onClick: () => handler.onConstractorChoose(record, construction),
        };
      }}
      rowClassName={"button-style"}
      onChange={handler.onTableChange}
      className="am-main-table-construction "
      columns={columns}
      dataSource={helpers.addKeyToDataSource(handler.subConstruction)}
      pagination={false}
    />
  );
};

export default ConstructionUI;
