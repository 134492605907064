import { Col, Row } from 'antd';
import helpers from '../../../../../common/helpers/common';
import { InputNumberComponent } from './InputNumber';

type props = {
    isEditing: boolean;
    userNumber?: number;
};

export const FreePlanComponent = ({ isEditing, userNumber }: props) => {
    return (
        <>
            <section className="ord007-header-style " style={{ marginTop: 8 }}>
                無料プラン　発行ユーザー数
            </section>
            <section style={{ width: '100%', display: 'flex' }}>
                <div className="center-item ord007-cell-container ord007-border-left half-width">
                    ユーザー数
                </div>
                <div className="center-item ord007-cell-container half-width">
                    {isEditing ? (
                        <>
                            <InputNumberComponent name="userNumber" max={999} isRequired={true} />
                            <div style={{ color: '#666666', marginLeft: 8 }}>ID</div>
                        </>
                    ) : (
                        `${userNumber ? helpers.formatCurrency(userNumber) + 'ID' : ''}`
                    )}
                </div>
            </section>
        </>
    );
};
