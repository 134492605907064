import { useSelector } from "react-redux";
import BreadcrumbComponent from "../../../../../common/components/breadcrumb/BreadcrumbComponent";
import MenuWrapperComponent from "../../../../../common/components/menu-wrapper";
import BREADCRUMB from "../../../../../common/constants/BREADCRUMB";
import { RootState } from "../../../../../store";
import AM012Component from "../../../AM012";
import AM014Component from "../../../AM014";
import AM016Component from "../../../AM016";
import AM018Component from "../../../AM018";
import AM020Component from "../../../AM020";
import AM022Component from "../../../AM022";
import AM024Component from "../../../AM024";
import { AM010Handler } from "../handler/HandlerImpl";
import AM010Content from "./AM010Content";
import ModalDetailShift from "./ModalDetailShift";

type props = {
  handler: AM010Handler;
};

export default function AM010UI({ handler }: props) {
  const isVisibleAM012 = useSelector(
    (state: RootState) => state.am012.isVisible
  );
  const isVisibleAM018 = useSelector(
    (state: RootState) => state.am018.isVisible
  );
  const isVisibleAM014 = useSelector(
    (state: RootState) => state.am014.isVisible
  );
  const isVisibleAM016 = useSelector(
    (state: RootState) => state.am016.isVisible
  );
  const isVisibleAM022 = useSelector(
    (state: RootState) => state.am022.isVisible
  );
  const isVisibleAM020 = useSelector(
    (state: RootState) => state.am020.isVisible
  );
  const isVisibleAM024 = useSelector(
    (state: RootState) => state.am024.isVisible
  );
  const isVisibleDetailShift = useSelector(
    (state: RootState) => state.am010.isVisibleDetailShift
  );
  return (
    <>
      <MenuWrapperComponent chosenKey={25} openKey={2}>
        <BreadcrumbComponent data={BREADCRUMB.AM010} />
        <AM010Content handler={handler} />
        {isVisibleAM012 ? <AM012Component /> : <></>}
        {isVisibleAM014 ? <AM014Component /> : <></>}
        {isVisibleAM016 ? <AM016Component /> : <></>}
        {isVisibleAM018 ? <AM018Component /> : <></>}
        {isVisibleAM020 ? <AM020Component /> : <></>}
        {isVisibleAM022 ? <AM022Component /> : <></>}
        {isVisibleAM024 ? <AM024Component /> : <></>}
        {isVisibleDetailShift ? <ModalDetailShift handler={handler} /> : <></>}
      </MenuWrapperComponent>
    </>
  );
}
