import React, { memo } from 'react';
import Wrapper3453Handler from './presenter/handler/Handler';
import MainUI from './presenter/ui/UI';

import '../../../../assets/styles/CM/cm3854.css';
import '../../../../assets/styles/AM/AM028.css';
import api from './infrastructure/api/Api';
import service from './usecase/Service';

const CM3453Component = () => {
    const apiInstance = api();
    const serviceInstance = service(apiInstance);
    const handler = Wrapper3453Handler(serviceInstance);
    return <MainUI handler={handler} />;
};

export default memo(CM3453Component);
