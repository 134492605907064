import { ORD001SliceType } from './../type/Presenter';
import { createSlice } from '@reduxjs/toolkit';

const initialState: ORD001SliceType = {
    isRefreshORD001: false,
    listStatus: [],
    listPlan: [],
    dataDetailPlanSetting: null,
    selectedRowsKey: []
};

export const ord001Slice = createSlice({
    name: 'ord001',
    initialState,
    reducers: {
        setIsRefreshORD001: (state) => {
            state.isRefreshORD001 = !state.isRefreshORD001;
        },
        setListStatusOrder: (state, action) => {
            state.listStatus = action.payload;
        },
        setListPlanOrder: (state, action) => {
            state.listPlan = action.payload;
        },
        setDetailPlanSetting: (state, action) => {
            state.dataDetailPlanSetting = action.payload;
        },
        setSelectedRowsKey: (state, action) => {
            state.selectedRowsKey = action.payload;
        },
    },
});

export const { setIsRefreshORD001, setListStatusOrder, setListPlanOrder, setDetailPlanSetting, setSelectedRowsKey } =
    ord001Slice.actions;

export default ord001Slice.reducer;
