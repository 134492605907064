// Icons
import { useSearchParams } from "react-router-dom";
import ThresholdPopoverComponent from "../../../../../common/components/threshold-popover";
import { CM002ElementData } from "../../entity/Entity";
import { CM001Handler } from "../handler/HandlerImpl";
import {
  PROJECT_MANAGER_PARAM_ID_KEY,
  PROJECT_PARAM_ID_KEY,
  PROJECT_PARAM_KEY,
} from "../handler/Handler";
import React from "react";
import TooltipText from "../../../../../common/components/tooltip-text/TooltipText";
import helpers from "../../../../../common/helpers/common";

type props = {
  handler: CM001Handler;
  searchValueParams: string;
};

const CM002ElementComponent = ({ handler, searchValueParams }: props) => {
  const [searchParams] = useSearchParams();
  const projectId: string = searchParams.get(PROJECT_PARAM_ID_KEY) ?? "0";
  const projectName: string = searchParams.get(PROJECT_PARAM_KEY) ?? "";
  const projectManagerId: string =
    searchParams.get(PROJECT_MANAGER_PARAM_ID_KEY) ?? "";

  React.useEffect(() => {
    handler.getSummaryListConstructionMoney(
      { projectId: projectId }
    );
  }, [handler.refresh]);

  return (
    <div className="cm001-element">
      <div className="element-revenue">
        <div className="header">工事一覧</div>
        <div className="cm002-content">
          <div className="header-title">
            <div style={{ width: 120 }} className="item">
              No
            </div>
            <div className="item">工事名</div>
            <div className="item">契約日</div>
            <div className="item">契約金額</div>
            <div className="item">請求出来高</div>
            <div className="item">請求出来高(％)</div>
            <div className="item">請求残金</div>
          </div>
          {handler.summaryListConstruction.map(
            (item: CM002ElementData, idx: number) => (
              <div className="content-items" key={idx}>
                <div style={{ width: 120 }} className="item">
                  {idx + 1}
                </div>
                <div
                  className="item"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    handler.navigateCM004(
                      item.constructionId,
                      projectName,
                      projectId
                    )
                  }
                >
                  <u>
                    {helpers.getShortTextWithToolTip(item.constructionName, 13)}
                  </u>
                </div>
                <div className="item">{item.contractCreateDate}</div>
                <div className="item">{item.contractAmount}</div>
                <div className="item">{item.billingVolume}</div>
                <div className="item">
                  {item.threshold_view} &nbsp;{" "}
                  <ThresholdPopoverComponent
                    initialData={item}
                    update={handler.updateThresholdConstructionData}
                  />
                </div>
                <div className="item">{item.balanceCost}</div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export { CM002ElementComponent };
