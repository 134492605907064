import React, { useEffect } from 'react';
import { Layout } from 'antd';
import HomeSvg from '../../../../../assets/icons/home.svg';
import { HandlerImpl } from '../handler/HandlerImpl';
import MenuWrapperComponent from '../../../../../common/components/menu-wrapper';
import BreadcrumbComponent from '../../../../../common/components/breadcrumb/BreadcrumbComponent';
import CustomerDetailComponent from './CustomerDetail';

import 'moment/locale/ja';
import '../../../../../assets/styles/OM/OM002.css';
import '../../../../../assets/styles/OM/OM004.css';
import '../../../../../assets/styles/OM/OM007.css';
import '../../../../../assets/styles/P/P01.css';
import ParentTableComponent from './ParentTable';
import { useParams } from 'react-router-dom';

const BREADCRUMB_ORD006: any[] = [
    {
        title: '販売管理 ',
        url: '',
        icon: <img style={{ paddingBottom: 2, paddingRight: 8 }} alt="" src={HomeSvg} />,
    },
    {
        title: 'オーダー管理',
        url: '/app-ord001',
        icon: '',
    },
    {
        title: 'オーダー詳細',
        url: '',
        icon: '',
    },
];

export const RED_COLOR = '#FF0909';
export const GREEN_COLOR = '#0FA44A';
export const VALID = '有効';
export const INVALID = '無効';

const { Content } = Layout;

interface props {
    handler: HandlerImpl;
}

const MainUI = ({ handler }: props) => {
    const { orderId } = useParams();
    const { orderDetail, getOrderDetail } = handler;

    useEffect(() => {
        getOrderDetail({ orderId });
    }, []);

    return (
        <MenuWrapperComponent chosenKey={61} openKey={6} contentClass="site-content-p01 ">
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'end',
                }}
            >
                <div className="om002-header-container">
                    <BreadcrumbComponent data={BREADCRUMB_ORD006} />
                    <div className="header-row-title" style={{ marginTop: 8 }}>
                        オーダー詳細
                    </div>
                </div>
                <div style={{ display: 'flex' }}></div>
            </div>
            <Layout
                style={{
                    minHeight: '84vh',
                    marginTop: 24,
                    marginBottom: 24,
                    backgroundColor: '#ffffff',
                    borderRadius: 3,
                }}
                className={'open-side'}
            >
                <Content style={{ margin: '0px 24px 24px 24px', backgroundColor: '#ffffff' }}>
                    <div style={{ marginTop: 24 }}>
                        <div
                            className="registration-title"
                            style={{ width: 114, height: 24, marginTop: 8 }}
                        >
                            顧客情報
                        </div>
                    </div>
                    <CustomerDetailComponent
                        customer={handler.customer}
                        orderDetail={orderDetail}
                    />
                    <div style={{ marginTop: 24 }}>
                        <div
                            className="registration-title"
                            style={{ width: 114, height: 24, marginTop: 8 }}
                        >
                            利用情報
                        </div>
                    </div>
                    <ParentTableComponent orderDetail={orderDetail} data={handler.usage} />
                </Content>
            </Layout>
        </MenuWrapperComponent>
    );
};

export default MainUI;
