import React from 'react';
import { HandlerImpl } from '../handler/HandlerImpl';
import { Tabs } from 'antd';
import ProjectScope from './ProjectScope';
import WholeSystem from './WholeSystem';

import IconI from '../../../../../assets/icons/icon-i-aop.svg';
import { TABS, TYPE_I } from '../../constant';

const { TabPane } = Tabs;

interface props {
    handler: HandlerImpl;
}

function BodyRight({ handler }: props) {
    return (
        <div className="aop003-body-right">
            <div className="aop003-body-right-tile">
                <span>権限</span>
                <img
                    src={IconI}
                    className="icon-cursor"
                    alt=""
                    onClick={() => handler.handleClickI(TYPE_I.h4)}
                />
            </div>
            <Tabs
                activeKey={handler.currentTab}
                type="card"
                className="tab-card"
                onChange={handler.handleChangeCurrentTab}
            >
                <TabPane
                    tab={handler.permissionCategory[0]?.description ?? '案件範囲'}
                    key={handler.permissionCategory[0]?.code ?? TABS[0]}
                >
                    <ProjectScope handler={handler} />
                </TabPane>
                <TabPane
                    tab={handler.permissionCategory[1]?.description ?? 'システム全体'}
                    key={handler.permissionCategory[1]?.code ?? TABS[1]}
                >
                    <WholeSystem handler={handler} />
                </TabPane>
            </Tabs>
        </div>
    );
}

export default BodyRight;
