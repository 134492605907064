import { AM028MenuHandler } from "../handler/HandlerImpl";
import { MENU_ITEM } from "../handler/Handler";

interface props {
  handler: AM028MenuHandler;
}
function Menu({ handler }: props) {
  return (
    <ul className="am028-menu">
      {MENU_ITEM.map((menu) => {
        return (
          <li
            key={menu.key}
            className={`am028-menu-item ${
              menu.key === handler.menuChosen ? "am028-menu-item-active" : ""
            }`}
            onClick={() => handler.handleChangeMenuChosen(menu.key)}
          >
            {menu.name}
          </li>
        );
      })}
    </ul>
  );
}

export default Menu;
