import React from "react";
import { CM0032Handler } from "../handler/HandlerImpl";
import { MENU_ITEM } from "../handler/Handler";
import { DatePicker, Button, Form } from "antd";
import IconCircleCheck from "../../../../../assets/icons/akar-icons_circle-check-fill.svg";
import IconExport from "../../../../../assets/icons/upload-purple.svg";
import locale from "antd/es/date-picker/locale/ja_JP";
import "moment/locale/ja";
import moment from "moment";
import COMMON, {
  STATUS_CM_CHILD_TABLE,
} from "../../../../../common/constants/COMMON";
import CalendarSvg from "../../../../../assets/icons/calender-purple.svg";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import {
  AUTHORIZATION_CODE,
  SYSTEM_ROLES,
} from "../../../../../common/constants/AUTHORIZATION";
import UploadLightSVG from '../../../../../assets/icons/upload-icon-purple.svg';
import ApproveConfirmModal from "../../../../../common/components/modal-approve-confirm";

const { RangePicker } = DatePicker;

interface props {
  handler: CM0032Handler;
}

const Header = ({ handler }: props) => {
  const permission = useSelector(
    (state: RootState) => state.auth.authData?.permission
  );
  const isRoleAdmin = useSelector((state: RootState) => state.auth?.authData?.isRoleAdmin)

  const isRoleAdminSystem =
    useSelector((state: RootState) => state.auth?.authData?.isRoleAdminSystem)

  return (
    <div className="cm024-header">
      <div className="cm024-header-filter">
        <div className="am028-nav">
          <ul className="am028-menu">
            {MENU_ITEM.map((menu) => {
              return (
                <li
                  key={menu.key}
                  className={`am028-menu-item ${menu.key === handler.menu ? "am028-menu-item-active" : ""
                    }`}
                  onClick={() => handler.handleChangeMenuChosen(menu.key)}
                >
                  {menu.name}
                </li>
              );
            })}
          </ul>
        </div>
        <div className="cm024-header-filter-choose">
          <Form
            onValuesChange={handler.handleFilterTime}
            form={handler.formFilter}
          >
            <Form.Item name="filterTime6">
              <RangePicker
                allowClear={false}
                inputReadOnly
                size="large"
                locale={locale}
                picker="month"
                className="am028-range cm024-range cm0020-range"
                format={COMMON.FORMAT_DATE_JA_MONTH}
                suffixIcon={
                  <img
                    alt=""
                    src={CalendarSvg}
                    className="icon-calender"
                    style={{ width: 20 }}
                  />
                }
                onOpenChange={handler.handleOpenCalendar}
              />
            </Form.Item>
          </Form>
          {permission?.includes(AUTHORIZATION_CODE.S47) || isRoleAdmin || isRoleAdminSystem ? (
            <>
              <Button
                className="button-purple cm047-btn-submit cm024-btn-submit"
                icon={<img alt="" src={IconCircleCheck} />}
                size="large"
                onClick={handler.onOkSubmitData}
                disabled={
                  (handler.status?.code ===
                    STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE) ||
                  (
                    handler.menu === MENU_ITEM[0].key
                      ? handler.dataTableByTrader?.length === 0
                      : handler.dataTableByProject?.length === 0
                  )
                }
              >
                提出
              </Button>
            </>
          ) : null}
          {permission?.includes(AUTHORIZATION_CODE.B9) || isRoleAdmin || isRoleAdminSystem ? (
            <Button
              className="button-brown-3"
              style={{ border: '1px solid #605BFF', width: 106, marginLeft: 16 }}
              size="large"
              onClick={handler.exportExcelCM0032}
            >
              <div className="center-item">
                <img
                  alt=""
                  src={UploadLightSVG}
                  style={{
                    marginRight: 8,
                    fontWeight: 700,
                    fontSize: 14,
                  }}
                />
                <div
                  className="export-button"
                  style={{ marginTop: 2, color: '#605BFF' }}
                >
                  出力
                </div>
              </div>
            </Button>
          ) : (
            <div></div>
          )}
        </div>
      </div>
      <div className="cm024-header-title">
        <span className="cm024-header-label">ステータス:</span>
        <span
          className="cm024-header-content"
          style={{
            color:
              STATUS_CM_CHILD_TABLE[
                `${handler?.status?.code?.toUpperCase()}` as keyof typeof STATUS_CM_CHILD_TABLE
              ]?.COLOR,
          }}
        >
          {handler.status?.name}
        </span>
      </div>
    </div>
  );
};

export default Header;
