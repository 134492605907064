import React, { useEffect } from "react";
import { Button, Form, Modal, Spin } from "antd";
import { useSelector } from "react-redux";
import LocationGPSIcon from "../../../../../assets/icons/location-gps.svg";
import XIconModal from "../../../../../assets/icons/x-icon-modal.svg";
import LoadingComponent from "../../../../../common/components/icons/LoadingComponent";
import { RootState } from "../../../../../store";
import { HandlerImpl } from "../handler/HandlerImpl";
import COMMON from "../../../../../common/constants/COMMON";
import moment from "moment";
import XRedIcon from "../../../../../assets/icons/x-red.svg";
import CircleGreenIcon from "../../../../../assets/icons/circle-green.svg";
import DoubleCheckComponent from "../component/double-check";
import { OpenStreetMapComponent } from "../../../../../common/components/open-street-map/OpenStreetMapComponent";

interface Props {
  handler: HandlerImpl;
}
const getTextWithTime = (
  value: any,
  openStreetMap: (lat: any, lng: any) => void
) => (
  <main
    style={{
      display: "flex",
      marginBottom: 16,
      height: 22,
      justifyContent: "center",
    }}
  >
    <p className="modal-label" style={{ marginRight: 7 }}>
      {value.time ? moment(value.time).format(COMMON.FORMAT_TIME1) : ""}
    </p>
    {value.lat != 0 && value.long != 0 ? (
      <div
        className="center-item"
        onClick={() => openStreetMap(value.lat, value.long)}
      >
        <img alt="" src={LocationGPSIcon} />
      </div>
    ) : (
      <></>
    )}
  </main>
);

const getNoTime = () => (
  <main
    style={{
      display: "flex",
      marginBottom: 16,
      height: 22,
      justifyContent: "center",
    }}
  ></main>
);

const MainUI = ({ handler }: Props) => {
  const { loading, report } = handler;

  const params = useSelector((state: RootState) => state.am040.params);
  const isVisibleStreetMap = useSelector(
    (state: RootState) => state.openStreetMap.isVisible
  );

  useEffect(() => {
    (async () => {
      if (params) await handler.getAttendanceUserTimeSheet(params);
    })();
  }, [JSON.stringify(params)]);

  return (
    <Modal
      className="group-detail-modal "
      style={{ marginLeft: 300, marginTop: 40, marginBottom: 40 }}
      visible={params ? true : false}
      closable={false}
      maskClosable={false}
      width={996}
      centered={true}
      footer={
        <>
          {handler.isEdit ? (
            <Button
              className="button-purple"
              disabled={loading}
              size="large"
              type="primary"
              style={{ marginLeft: 16, width: 76, letterSpacing: -1.5 }}
              onClick={() => handler.postAttendanceDoubleCheck()}
            >
              保存
            </Button>
          ) : (
            <Button
              className="button-purple"
              disabled={loading}
              size="large"
              type="primary"
              style={{ marginLeft: 16, width: 76, letterSpacing: -1.5 }}
              onClick={() => handler.onEdit()}
            >
              編集
            </Button>
          )}
          <Button
            disabled={loading}
            size="large"
            style={{ marginLeft: 16, width: 118 }}
            onClick={handler.closeModal}
          >
            キャンセル
          </Button>
        </>
      }
    >
      <Spin indicator={LoadingComponent} spinning={loading}>
        <div
          className="w-100-percent header-modal-containter"
          style={{ height: 50 }}
        >
          <div className="header-modal-title-om004 ">複数打刻</div>
          <div style={{ cursor: "pointer" }} onClick={handler.closeModal}>
            <img alt="" src={XIconModal} style={{ height: 18, width: 18 }} />
          </div>
        </div>
        <div style={{ minHeight: 244, marginTop: 24 }}>
          <Form
            form={handler.form}
            id="formCreateMember"
            layout="horizontal"
            autoComplete="off"
          >
            <div style={{ display: "flex", marginLeft: 6 }}>
              <div style={{ minHeight: 174, width: 190 }}>
                <div style={{ marginBottom: 16, height: 22 }}></div>
                <p style={{ marginBottom: 16 }} className="modal-label">
                  出勤時刻
                </p>
                <p style={{ marginBottom: 16 }} className="modal-label">
                  退勤時刻
                </p>
                <p style={{ marginBottom: 16 }} className="modal-label">
                  担当者確認(出勤)
                </p>
                <p className="modal-label">担当者確認(退勤)</p>
                <div style={{ height: 24 }}></div>
              </div>
              <div
                style={{
                  width: 109 * 7,
                  height: 190,
                  display: "flex",
                  overflow: "auto",
                  overflowY: "hidden",
                }}
              >
                {report?.sttArray?.map((element: any, index: number) => (
                  <main
                    key={element}
                    style={{ minWidth: 109, height: 170, marginRight: 24 }}
                  >
                    <p
                      style={{
                        marginBottom: 16,
                        color: "#605BFF",
                        textAlign: "center",
                      }}
                      className="modal-label"
                    >{`${index + 1}回目`}</p>
                    {report.checkin[element]?.time
                      ? getTextWithTime(
                          report.checkin[element],
                          handler.openStreetMap
                        )
                      : getNoTime()}
                    {report.checkout[element]?.time
                      ? getTextWithTime(
                          report.checkout[element],
                          handler.openStreetMap
                        )
                      : getNoTime()}
                    {!handler.isEdit ? (
                      <>
                        <div
                          style={{
                            marginBottom: 16,
                            height: 22,
                            textAlign: "center",
                          }}
                        >
                          {report.checkin[element] &&
                          report.checkin[element].isDoubleCheck != null ? (
                            report.checkin[element].isDoubleCheck ? (
                              <img alt="" src={CircleGreenIcon} />
                            ) : (
                              <img alt="" src={XRedIcon} />
                            )
                          ) : (
                            <></>
                          )}
                        </div>
                        <div style={{ height: 22, textAlign: "center" }}>
                          {report.checkout[element] &&
                          report.checkout[element].isDoubleCheck != null ? (
                            report.checkout[element].isDoubleCheck ? (
                              <img alt="" src={CircleGreenIcon} />
                            ) : (
                              <img alt="" src={XRedIcon} />
                            )
                          ) : (
                            <></>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          style={{
                            marginBottom: 16,
                            height: 22,
                            textAlign: "center",
                          }}
                        >
                          {report.checkin[element] && params?.constructionId ? (
                            <DoubleCheckComponent
                              form={handler.form}
                              constructionId={parseInt(params.constructionId)}
                              doubleCheck={
                                report.checkin[element].isDoubleCheck
                              }
                              userTimeSheetId={report.checkin[element].id}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                        <div style={{ height: 22, textAlign: "center" }}>
                          {report.checkout[element] &&
                          params?.constructionId ? (
                            <DoubleCheckComponent
                              form={handler.form}
                              constructionId={parseInt(params.constructionId)}
                              doubleCheck={
                                report.checkout[element].isDoubleCheck
                              }
                              userTimeSheetId={report.checkout[element].id}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                      </>
                    )}
                  </main>
                ))}
              </div>
            </div>
          </Form>
        </div>
      </Spin>
      {isVisibleStreetMap ? <OpenStreetMapComponent /> : <></>}
    </Modal>
  );
};

export default MainUI;
