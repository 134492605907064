import CM0023API from './infrastructure/api/Api';
import CM0025Handler from './presenter/handler/Handler';
import CM0023Service from './usecase/Service';
import MainUI from './presenter/ui/UI';

import '../../../assets/styles/CM/cm024.css';
import '../../../assets/styles/CM/cm3854.css';
import '../../../assets/styles/CM/cm047.css';
type props = {
    afterSuccess?: () => void;
    onSubmit?: () => void;
    onReject?: () => void;
};
const CM0023Component = (props: props) => {
    const api = CM0023API();
    const service = CM0023Service(api);
    const handler = CM0025Handler(service);
    return <MainUI handler={handler} {...props} />;
};

export default CM0023Component;
