import { InviteListContractorToConstruction } from "../entity/Entity";
import { CR001MailContractorsApi } from "./ServiceImpl";

const CR001MailContractorsService = (
  cr001MailContractorsApi: CR001MailContractorsApi
) => {
  const addCompanyForList = async (data: any): Promise<any> => {
    const axiosCall = await cr001MailContractorsApi.addCompanyForList(data);
    return axiosCall.data;
  };

  const postInviteConstructionContractorSendEmail = async (
    data: InviteListContractorToConstruction
  ): Promise<any> => {
    const axiosCall =
      await cr001MailContractorsApi.postInviteConstructionContractorSendEmail(
        data
      );
    return axiosCall.data;
  };

  return {
    addCompanyForList,
    postInviteConstructionContractorSendEmail,
  };
};

export default CR001MailContractorsService;
