// Built-in
import React, { useRef } from "react";

// 3rd party lib
import { Checkbox, Table } from "antd";
import type { ColumnsType } from "antd/es/table";

// Source files
import CollapsedTableComponent from "../../collapsed-table";
import { MaterialCostHandlerImpl } from "../../../handler/HandlerImpl";

// Image files
import ExpandedIconSvg from "../../../../../../../assets/icons/expanded-icon.svg";
import ClosedIconSvg from "../../../../../../../assets/icons/closed-icon.svg";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import ThresholdPopoverComponent from "../../../../../../../common/components/threshold-popover/index";
import { STATUS_PARENT } from "../../../handler/HandlerMaterialCost";
import helpers from "../../../../../../../common/helpers/common";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../store";
import CheckBoxAllComponentCM0013CM0015 from "./CheckBoxAll";

type props = {
  handler: MaterialCostHandlerImpl;
};
export const COLUMN_WIDTH_CM0013: number[] = [
  100, 350, 200, 174, 174, 174, 174, 120,
];

const MaterialCostUI = ({ handler }: props) => {
  const [searchParams] = useSearchParams();
  const constructionId = searchParams.get("constructionId");
  const projectName = searchParams.get("projectName") || "";

  const isSubmit = useSelector((state: RootState) => state.cm004.isSubmit);
  const data = useSelector((state: RootState) => state.cm0013.dataMaterialCost);
  const isExport = useSelector((state: RootState) => state.cm004.isExport);

  const firstRender2 = useRef(false);
  useEffect(() => {
    if (firstRender2.current) {
      (async () => {
        if (constructionId && !isNaN(parseInt(constructionId)))
          await handler.exportFile({
            constructionId: parseInt(constructionId),
            sortBy: "createdAt",
            sortType: "DESC",
            projectName,
          });
      })();
      return;
    }
    firstRender2.current = true;
  }, [isExport]);

  const firstRender = useRef(false);
  useEffect(() => {
    if (firstRender.current) {
      handler.putMoneySubmitDirectCostLabor();
    }
    firstRender.current = true;
  }, [isSubmit]);

  useEffect(() => {
    handler.handleCheckEnableSubmit();
  }, [JSON.stringify(handler.checkedList)]);

  useEffect(() => {
    (async () => {
      if (constructionId && !isNaN(parseInt(constructionId)))
        await handler.getMoneyDirectCostLaborListManager({
          constructionId: parseInt(constructionId),
        });
    })();
  }, [constructionId, handler.refresh]);

  const columns: ColumnsType<any> = [
    {
      // title: (
      //     <CheckBoxAllComponentCM0013CM0015
      //         onCheckAllChange={handler.onCheckAllChange}
      //         checkAll={handler.checkAll}
      //         data={data}
      //     />
      // ),
      title: "No",
      key: "No",
      width: COLUMN_WIDTH_CM0013[0],
      render: (_, record, index) => {
        // const foundData = data.find((element) => element.id === record.id);
        // if (foundData?.child?.length === 0)
        //     return (
        //         <span
        //             className="cell-cm007-style white-space-nowrap"
        //             style={{ width: '100%', float: 'left', textAlign: 'left' }}
        //             onClick={(event) => {
        //                 event.stopPropagation();
        //             }}
        //         >
        //             <Checkbox
        //                 className="no-hover"
        //                 disabled={true}
        //                 style={{ marginRight: 8 }}
        //             />
        //             {record.no}
        //         </span>
        //     );
        return (
          <span
            className="cell-cm007-style white-space-nowrap"
            style={{ width: "100%", float: "left", textAlign: "left" }}
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            {/* {record.status.code === STATUS_PARENT.approved.code ||
                            record.status.code === STATUS_PARENT.waiting_for_approve.code ? (
                            <Checkbox
                                className="no-hover"
                                disabled={true}
                                style={{ marginRight: 8 }}
                                checked
                            />
                        ) : (
                            <Checkbox
                                onChange={(value: any) =>
                                    handler.onCheckboxChange(
                                        record,
                                        value?.target?.checked ? true : false,
                                    )
                                }
                                style={{ marginRight: 8 }}
                                checked={handler.isCheckbox(record)}
                            />
                        )} */}
            {index + 1}
          </span>
        );
      },
    },
    {
      title: "担当者名",
      key: "担当者名",
      width: COLUMN_WIDTH_CM0013[1],
      render: (record) => (
        <span className="cell-cm007-style white-space-nowrap">
          {record.name}
        </span>
      ),
    },

    {
      title: "予算金額",
      key: "予算金額",
      width: COLUMN_WIDTH_CM0013[2],
      render: (record) => (
        <span className="cell-cm007-style white-space-nowrap">{`${
          record.budgetAmount
            ? record.budgetAmount.toLocaleString("en-US", {
                maximumFractionDigits: 2,
              })
            : "0"
        }円`}</span>
      ),
    },

    {
      title: "実費用",
      key: "実費用",
      width: COLUMN_WIDTH_CM0013[3],
      render: (record) => (
        <span className="cell-cm007-style white-space-nowrap">{`${
          record.actualCost
            ? record.actualCost.toLocaleString("en-US", {
                maximumFractionDigits: 2,
              })
            : "0"
        }円`}</span>
      ),
    },
    {
      title: "予算残高",
      key: "予算残高",
      width: COLUMN_WIDTH_CM0013[4],
      render: (record) => (
        <span className="cell-cm007-style white-space-nowrap">{`${
          record.budgetBalance
            ? record.budgetBalance.toLocaleString("en-US", {
                maximumFractionDigits: 2,
              })
            : "0"
        }円`}</span>
      ),
    },
    {
      title: "予算消化率（％）",
      key: "予算消化率（％）",
      width: COLUMN_WIDTH_CM0013[5],
      render: (record) => {
        return (
          <div onClick={(e) => e.stopPropagation()} className="td-threshold">
            <span className="cell-cm007-style white-space-nowrap">{`${helpers.calculateRateAmount(
              record.budgetAmount,
              record.actualCost ?? 0
            )}%`}</span>
            <ThresholdPopoverComponent
              initialData={{
                ...record,
                constructionId: parseInt(constructionId ?? "0"),
              }}
              update={(payload) =>
                handler.putMoneyDirectCostLaborEnable(payload, record.id)
              }
            />
          </div>
        );
      },
    },

    {
      title: "ステータス",
      key: "ステータス",
      width: COLUMN_WIDTH_CM0013[6],
      render: (record) => (
        <span className="cell-cm007-style white-space-nowrap">
          {record.status?.name ?? ""}
        </span>
      ),
    },
    {
      title: "",
      key: "collapsed",
      width: COLUMN_WIDTH_CM0013[7],
      render: (record) => {
        return handler.isCollapsed(record) ? (
          <div
            style={{ cursor: "pointer" }}
            onClick={(e: any) => handler.onChangeCollapseRow(record, false)}
          >
            <img alt="" src={ExpandedIconSvg} />
          </div>
        ) : (
          <div
            style={{ cursor: "pointer" }}
            onClick={(e: any) => handler.onChangeCollapseRow(record, true)}
          >
            <img alt="" src={ClosedIconSvg} />
          </div>
        );
      },
    },
  ];
  return (
    <Table
      onRow={(record, rowIndex) => {
        return {
          onClick: (event) =>
            handler.isCollapsed(record)
              ? handler.onChangeCollapseRow(record, false)
              : handler.onChangeCollapseRow(record, true), // click row
        };
      }}
      rowClassName={() => {
        return "button-style";
      }}
      scroll={{ x: 0, y: 500 }}
      columns={columns}
      dataSource={data}
      id="main-table"
      className="cm007-table cm-main-table table-footer scroll-table"
      pagination={false}
      expandable={{
        showExpandColumn: false,
        expandedRowKeys: handler.expandKey,
        expandedRowRender: (record: any) => (
          <CollapsedTableComponent
            parentData={record}
            onParentCheckboxChange={handler.onCheckboxChange}
          />
        ),
      }}
      summary={(pageData) => {
        let budgetAmountTotal = 0;
        let actualCostTotal = 0;
        let budgetBalanceTotal = 0;

        pageData.forEach(
          ({ budgetAmount, orderAmount, actualCost, budgetBalance }) => {
            budgetAmountTotal += budgetAmount;
            actualCostTotal += actualCost;
            budgetBalanceTotal += budgetBalance;
          }
        );

        return (
          <Table.Summary fixed="bottom">
            <Table.Summary.Row
              className="parent-table-summary"
              style={{ background: "#605BFF" }}
            >
              <Table.Summary.Cell index={0} colSpan={2}>
                合計金額
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} colSpan={0}></Table.Summary.Cell>
              <Table.Summary.Cell index={2}>
                <span className="center-item white-space-nowrap">{`${budgetAmountTotal.toLocaleString(
                  "en-US",
                  {
                    maximumFractionDigits: 2,
                  }
                )}円`}</span>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3}>
                <span className="center-item white-space-nowrap">{`${actualCostTotal.toLocaleString(
                  "en-US",
                  {
                    maximumFractionDigits: 2,
                  }
                )}円`}</span>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4}>
                <span className="center-item white-space-nowrap">{`${budgetBalanceTotal.toLocaleString(
                  "en-US",
                  {
                    maximumFractionDigits: 2,
                  }
                )}円`}</span>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5}>
                <span className="center-item white-space-nowrap">{`${(actualCostTotal ===
                  0 || budgetAmountTotal === 0
                  ? 0
                  : (actualCostTotal / budgetAmountTotal) * 100
                ).toLocaleString("en-US", {
                  maximumFractionDigits: 2,
                })}%`}</span>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={6}></Table.Summary.Cell>
              <Table.Summary.Cell index={7}></Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        );
      }}
    />
  );
};

export default MaterialCostUI;
