import { DateColumn, DoubleCheck, TimeSheet } from '../../../entity/Entity';

import XRedSVG from '../../../../../../assets/icons/x-red.svg';
import CircleGreenSVG from '../../../../../../assets/icons/circle-green.svg';

import WarningTriangle from '../../../../../../assets/icons/warning-triangle.svg';
import LocationGPSIcon from '../../../../../../assets/icons/location-gps.svg';
import moment from 'moment';
import COMMON from '../../../../../../common/constants/COMMON';
import helpers from '../../../../../../common/helpers/common';
import ButtonWarningAM042Component from '../../../../AM042/presenter/component/button-warning';
import { Tooltip } from 'antd';
import IconI from '../../../../../../assets/icons/icon-i-danger.svg';

export const CHECKIN = 'checkin';
export const CHECKOUT = 'checkout';

const findAttributeByDate = (timesheet: TimeSheet[], date: string, attribute: string) => {
    const foundData: any = timesheet.find((element) =>
        moment(element.date).isSame(moment(date), 'day'),
    );
    if (foundData) return foundData[`${attribute}`];
    return '';
};

const convertToDataSource = (
    userId: number,
    contractorConstructionId: number,
    timesheet: TimeSheet[],
    tableColumns: DateColumn[],
    laborCost: number,
    constructionId: number,
    openStreetMap: (lat: any, lng: any) => void,
) => {
    const columnFrames: any[] = [
        {
            key: 1,
            id: 1,
            name: '複数打刻',
        },
        {
            key: 2,
            id: 2,
            name: '出勤時刻',
        },
        {
            key: 3,
            id: 3,
            name: '退勤時刻',
        },
        {
            key: 4,
            id: 4,
            name: '稼働時間',
        },
        {
            key: 5,
            id: 5,
            name:
                < section style={{ display: 'flex', alignItems: 'center' }}>
                    <div >
                        人工単価（円/日）
                    </div>
                    <Tooltip
                        placement='right'
                        overlayInnerStyle={{
                            width: 370
                        }}
                        title={<div style={{ width: 'fit-content' }}>
                            8時間を超える残業分については、<br />
                            設定した人工単価に1.25倍の時間単価で計算されます。<br />
                            ※休憩時間を1時間と仮定し計算しております。<br />
                            ※目安金額として参照ください。<br />
                        </div>} >
                        <img alt='' src={IconI} />
                    </Tooltip>
                </section >,
        },
        {
            key: 6,
            id: 6,
            name: <div>担当者確認(出勤)</div>,
            data: [],
        },
        {
            key: 7,
            id: 7,
            name: <div>担当者確認(退勤)</div>,
            data: [],
        },
    ];
    const result: any[] = [];

    // construction attendance data
    const distinctConstruction = new Set<string>();
    timesheet.forEach((timesheetElement) => {
        timesheetElement.doubleCheck?.forEach((element: any) => {
            distinctConstruction.add(
                JSON.stringify({
                    id: element.companyId,
                    name: element.companyName,
                    constructionName: element.constructionName,
                }),
            );
        });
    });
    // double check by date
    const doubleCheckDateData: any[] = [];
    timesheet.forEach((timesheetElement: TimeSheet) => {
        if (timesheetElement.doubleCheck && Array.isArray(timesheetElement.doubleCheck)) {
            timesheetElement.doubleCheck?.forEach((doubleCheckElement: DoubleCheck) => {
                doubleCheckDateData.push({
                    ...doubleCheckElement,
                    date: timesheetElement.date,
                });
            });
        }
    });

    const compareCheckIn: any[] = [];
    columnFrames[5].data = [...distinctConstruction].map((construction: string) => {
        const objectData = JSON.parse(construction);
        const objectDataComparision = JSON.parse(construction);
        tableColumns.forEach((col) => {

            const foundDoubleCheck = doubleCheckDateData.find(
                (doubleCheck: any) => {
                    return moment(doubleCheck.date).isSame(moment(helpers.getFilterTime(col.value)), 'day') &&
                        doubleCheck.companyId === objectData.id &&
                        doubleCheck.companyName === objectData.name &&
                        doubleCheck.constructionName === objectData.constructionName &&
                        doubleCheck.categoryCode === CHECKIN
                }
            );
            objectData[`${col.value}`] = !foundDoubleCheck ? (
                <></>
            ) : foundDoubleCheck?.approve ? (
                <img alt="" src={CircleGreenSVG} />
            ) : (
                <img alt="" src={XRedSVG} />
            );
            objectDataComparision[`${col.value}`] = !foundDoubleCheck
                ? null
                : foundDoubleCheck?.approve
                    ? true
                    : false;
        });
        compareCheckIn.push(objectDataComparision);
        return objectData;
    });

    tableColumns.forEach((col) => {
        const checkCompanyDate: any[] = [];
        compareCheckIn.forEach((company) => {
            checkCompanyDate.push(company[`${col.value}`]);
        });

        const elementNoNull = checkCompanyDate.filter((val) => val !== null);

        if (elementNoNull.length > 1) {
            const isWarning = !elementNoNull.every((val, i, arr) => val === arr[0]);
            if (isWarning)
                columnFrames[5].name = (
                    <div>
                        担当者確認(出勤)
                        <img alt="" src={WarningTriangle} style={{ marginLeft: 8 }} />
                    </div>
                )
        }
    });

    const compareCheckOut: any[] = [];
    columnFrames[6].data = [...distinctConstruction].map((construction: string) => {
        const objectData = JSON.parse(construction);
        const objectDataComparision = JSON.parse(construction);
        tableColumns.forEach((col) => {
            const foundDoubleCheck = doubleCheckDateData.find(
                (doubleCheck: any) =>
                    moment(doubleCheck.date).isSame(moment(helpers.getFilterTime(col.value)), 'day') &&
                    doubleCheck.companyId === objectData.id &&
                    doubleCheck.companyName === objectData.name &&
                    doubleCheck.constructionName === objectData.constructionName &&
                    doubleCheck.categoryCode === CHECKOUT,
            );
            objectData[`${col.value}`] = !foundDoubleCheck ? (
                <></>
            ) : foundDoubleCheck?.approve ? (
                <img alt="" src={CircleGreenSVG} />
            ) : (
                <img alt="" src={XRedSVG} />
            );
            objectDataComparision[`${col.value}`] = !foundDoubleCheck
                ? null
                : foundDoubleCheck?.approve
                    ? true
                    : false;
        });
        compareCheckOut.push(objectDataComparision);
        return objectData;
    });

    tableColumns.forEach((col) => {
        const checkCompanyDate: any[] = [];
        compareCheckOut.forEach((checkout) => {
            checkCompanyDate.push(checkout[`${col.value}`]);
        });

        const elementNoNull = checkCompanyDate.filter((val) => val !== null);

        if (elementNoNull.length > 1) {
            const isWarning = !elementNoNull.every((val, i, arr) => val === arr[0]);
            if (isWarning)
                columnFrames[6].name = (
                    <div>
                        担当者確認(退勤)
                        <img alt="" src={WarningTriangle} style={{ marginLeft: 8 }} />
                    </div>
                );
        }


    });
    // match data
    columnFrames.forEach((frame) => {
        const record: any = {};
        if (frame.id === 1)
            tableColumns.forEach((col) => {
                const foundData: any = timesheet.find((element) =>
                    moment(element.date).isSame(moment(col.value), 'day'),
                );
                const isMultipleCheckInOut = findAttributeByDate(
                    timesheet,
                    col.value,
                    'isMultipleCheckInOut',
                );
                record[`${col.value}`] = isMultipleCheckInOut ? (
                    <ButtonWarningAM042Component
                        contractorConstructionId={contractorConstructionId + ''}
                        constructionId={constructionId + ''}
                        date={moment(foundData.date).format(COMMON.FORMAT_DATE2)}
                        userId={userId + ''}
                    />
                ) : (
                    <></>
                );
            });
        else if (frame.id === 2) {
            tableColumns.forEach((col) => {
                const checkin = findAttributeByDate(timesheet, col.value, 'checkin');
                const checkinLocation = findAttributeByDate(
                    timesheet,
                    col.value,
                    'checkinLocation',
                );
                record[`${col.value}`] = checkin ? (
                    <main style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <div style={{ marginRight: 7 }}>{`${moment(checkin).format(
                            COMMON.FORMAT_TIME1,
                        )}`}</div>
                        {checkinLocation &&
                            checkinLocation?.lat !== 0 &&
                            checkinLocation?.long !== 0 ? (
                            <div
                                className="center-item"
                                onClick={() =>
                                    openStreetMap(checkinLocation?.lat, checkinLocation?.long)
                                }
                            >
                                <img alt="" src={LocationGPSIcon} />
                            </div>
                        ) : (
                            <></>
                        )}
                    </main>
                ) : (
                    <></>
                );
            });
        } else if (frame.id === 3) {
            tableColumns.forEach((col) => {
                const checkout = findAttributeByDate(timesheet, col.value, 'checkout');
                const checkoutLocation = findAttributeByDate(
                    timesheet,
                    col.value,
                    'checkoutLocation',
                );

                record[`${col.value}`] = checkout ? (
                    <main style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <div style={{ marginRight: 7 }}>{`${moment(checkout).format(
                            COMMON.FORMAT_TIME1,
                        )}`}</div>

                        {checkoutLocation &&
                            checkoutLocation?.lat !== 0 &&
                            checkoutLocation?.long !== 0 ? (
                            <div
                                className="center-item"
                                onClick={() =>
                                    openStreetMap(checkoutLocation?.lat, checkoutLocation?.long)
                                }
                            >
                                <img alt="" src={LocationGPSIcon} />
                            </div>
                        ) : (
                            <></>
                        )}
                    </main>
                ) : (
                    <></>
                );
            });
        } else if (frame.id === 4) {
            tableColumns.forEach((col) => {
                const checkin = findAttributeByDate(timesheet, col.value, 'checkin');
                const checkout = findAttributeByDate(timesheet, col.value, 'checkout');

                if (checkin && checkout) {
                    const duration = moment.duration(
                        moment(checkout, COMMON.FORMAT_DATE_4).diff(
                            moment(checkin, COMMON.FORMAT_DATE_4),
                        ),
                    );
                    const totalMinutes = duration.asMinutes();
                    record[`${col.value}`] = `${Math.round((totalMinutes / 60) * 100) / 100}`;
                } else record[`${col.value}`] = <></>;
            });
        } else if (frame.id === 5)
            tableColumns.forEach((col) => {
                const checkin = findAttributeByDate(timesheet, col.value, 'checkin');
                const checkout = findAttributeByDate(timesheet, col.value, 'checkout');
                const dailyLaborCost = findAttributeByDate(timesheet, col.value, 'dailyLaborCost');
                if (checkin && checkout)
                    record[`${col.value}`] = helpers.formatCurrency(dailyLaborCost ?? 0);

                else record[`${col.value}`] = '';
            });

        result.push({ ...frame, ...record });
    });
    // check in
    return result;
};
export { convertToDataSource };
