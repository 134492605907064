import React from "react";
import { Modal } from "antd";
import { ReactNode } from "react";
import { LegacyButtonType } from "antd/lib/button/button";
import "../../../assets/styles/components/confirm-modal.css";

type props = {
  onOk: () => void;
  onCancel?: () => void;
  title: string;
  description: string | JSX.Element;
  canceText: string;
  okText: string;
  icon?: ReactNode;
  extraDescription?: string;
  thirdDescription?: string;
  width?: number;
  height?: any;
  className?: string;
  okType?: LegacyButtonType;
  isCenterWithoutMenu?: boolean;
  notHaveClosable?: boolean;
};

const ConfirmModal = ({
  onOk,
  onCancel,
  title,
  description,
  canceText,
  okText,
  icon,
  width,
  height,
  className,
  okType,
  isCenterWithoutMenu,
  extraDescription,
  thirdDescription,
  notHaveClosable,
}: props) => {
  const descriptionComponent = (
    <>
      <div className="description-ccus01">{description}</div>
      <div className="description-ccus01">{extraDescription ?? ""}</div>
      <div className="description-ccus01">{thirdDescription ?? ""}</div>
    </>
  );

  return Modal.confirm({
    width: width ?? 479,
    style: {
      height: height ?? 272,
      marginLeft: isCenterWithoutMenu ? 300 : 0,
    },
    centered: true,
    className:
      className ??
      "confirm__modal confirm__modal-confirm confirm__modal-purple-oke ",
    title: title,
    icon: icon,
    content: descriptionComponent,
    okText: okText,
    okType: okType ? okType : "danger",
    closable: notHaveClosable ? false : true,
    cancelText: canceText,
    zIndex: 9999,
    onOk: () => {
      if (onOk) {
        onOk();
      }
    },
    onCancel: () => {
      if (onCancel) {
        onCancel();
      }
    },
  });
};

export default ConfirmModal;
