import PM005Api from "../PM005/infrastructure/api/Api"
import PM005Service from "../PM005/usecase/Service"
import PM008Api from "../PM008/infrastructure/api/Api"
import PM008Handler from "../PM008/presenter/handler/Handler"
import PM008Service from "../PM008/usecase/Service"
import PM002Api from "./infrastructure/api/Api"
import PM002Handler from "./presenter/handler/Handler"
import PM002UI from "./presenter/ui/UI"
import PM002Service from "./usecase/Service"

const PM002Component = () => {
    const pm002Api = PM002Api()
    const pm005Api = PM005Api()
    const pm008Api = PM008Api();

    const pm002Service = PM002Service(pm002Api)
    const pm005Service = PM005Service(pm005Api)
    const pm008Service = PM008Service(pm008Api);

    const pm002Handler = PM002Handler(pm002Service, pm005Service)
    const pm008Handler = PM008Handler(pm008Service);

    return <PM002UI handler={pm002Handler} modalHandler={pm008Handler} />

}

export default PM002Component