import { Params } from '../presenter/type/Presenter';
import { CM0017_3_1Api } from './ServiceImpl';

const CM0017_3_1Service = (cm0017_3_1: CM0017_3_1Api) => {
    const getReSummarySubmittedOutsourceCostOffsetList = async (params: Params): Promise<any> => {
        try {
            return await cm0017_3_1.getReSummarySubmittedOutsourceCostOffsetList(params);
        } catch (error) {
            throw error;
        }
    };

    return {
        getReSummarySubmittedOutsourceCostOffsetList,
    };
};

export default CM0017_3_1Service;
