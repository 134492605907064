import { DatePicker, Form, Input, InputNumber, Tooltip } from "antd";
import { memo, useEffect, useState } from "react";
import locale from "antd/es/date-picker/locale/ja_JP";
import "moment/locale/ja";

import NewSvg from "../../../../../assets/icons/new.svg";
import CalendarSvg from "../../../../../assets/icons/calendar.svg";
import SquarePurple from "../../../../../assets/icons/squarer-purple.svg";
import BuildingSVG from "../../../../../assets/icons/building-purple.svg";
import VectorMinus from "../../../../../assets/icons/vector-minus.svg";

import { CR001AmountRelateHandler } from "../handler/HandlerImpl";
import { onBlurTrimFormItem } from "../../../../../common/helpers/typeInput";
import COMMON from "../../../../../common/constants/COMMON";
import REGEX from "../../../../../common/constants/REGEX";
import MESSAGE from "../../../../../common/constants/MESSAGE";
import { useParams } from "react-router-dom";
import helpers from "../../../../../common/helpers/common";
import { MESSAGE_DUPLICATE } from "../handler/Handler";
import UploadEvidenceComponent from "../../../../../common/components/common-form-item-cm/upload-evidence";
import DocumentIconSvg from "../../../../../assets/icons/document-icon.svg";
import UploadFileCRComponent from "../../../../../common/components/upload-cr";

const { Item } = Form;
const { RangePicker } = DatePicker;

interface props {
  handler: CR001AmountRelateHandler;
}

const MESSAGE_REQUIRED = "";
const MIN_INPUT_NUMBER = 0;
const REQUIRED_INPUT = false;
const DEFAULT_PERCENT = "";
const INVITED = {
  invited: {
    name: "招待済",
    color: "#0FA44A",
  },
  uninvited: {
    name: "未招待",
    color: "#FF0909",
  },
};
const Subcontract = ({ handler }: props) => {
  const { summaryId } = useParams();

  return (
    <>
      <div className="cr001-cost-registration">
        <div className="cr001-cost-registration-title bg-title-row padding-tb-15 border-bottom">
          <img src={NewSvg} alt="" />
          <span className="text-bold-14">外注費登録</span>
        </div>
        <div className="cr001-cost-registration">
          <div className="cr001-cr-item bg-header-row">
            <div className="cr001-cr-item-child border-bottom border-right cr-3-all padding-tb-15">
              全体見積金額（円）
            </div>
            <div className="cr001-cr-item-child border-bottom border-right cr-3-all padding-tb-15">
              全体原価金額（円）
            </div>
            <div className="cr001-cr-item-child border-bottom border-right cr-3-all padding-tb-15">
              全体予算金額（円）
            </div>
          </div>
          <div
            className="cr001-cr-item cr001-icon-rotate"
            key={`outsourceRegisterCost${1}`}
          >
            <div className="cr001-cr-item-child border-bottom border-right cr-3-all padding-tb-15 color-gray">
              {handler.isEdit ? (
                <Item
                  rules={[
                    {
                      required: REQUIRED_INPUT,
                      message: MESSAGE_REQUIRED,
                    },
                  ]}
                  name={"outsourceTotalCost"}
                >
                  <InputNumber
                    controls={false}
                    maxLength={COMMON.MAX_INPUT_NUMBER}
                    max={COMMON.MAX_NUMBER_VALUE}
                    min={MIN_INPUT_NUMBER}
                    className="input-cr001"
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Item>
              ) : (
                handler.checkFormatValue(
                  handler.listRelateMoney.outsourceTotalCost,
                  false,
                  true
                )
              )}
            </div>

            <div className="cr001-cr-item-child border-bottom border-right cr-3-all padding-tb-15 color-gray">
              {handler.isEdit ? (
                <Item
                  rules={[
                    {
                      required: REQUIRED_INPUT,
                      message: MESSAGE_REQUIRED,
                    },
                  ]}
                  name={"outsourceEstimateCost"}
                >
                  <InputNumber
                    controls={false}
                    maxLength={COMMON.MAX_INPUT_NUMBER}
                    max={COMMON.MAX_NUMBER_VALUE}
                    min={MIN_INPUT_NUMBER}
                    className="input-cr001"
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Item>
              ) : (
                handler.checkFormatValue(
                  handler.listRelateMoney.outsourceEstimateCost,
                  false,
                  true
                )
              )}
            </div>

            <div className="cr001-cr-item-child border-bottom border-right cr-3-all padding-tb-15 color-gray">
              {handler.isEdit ? (
                <Item
                  rules={[
                    {
                      required: REQUIRED_INPUT,
                      message: MESSAGE_REQUIRED,
                    },
                  ]}
                  name={"outsourceBudgetCost"}
                >
                  <InputNumber
                    controls={false}
                    maxLength={COMMON.MAX_INPUT_NUMBER}
                    max={COMMON.MAX_NUMBER_VALUE}
                    min={MIN_INPUT_NUMBER}
                    className="input-cr001"
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Item>
              ) : (
                handler.checkFormatValue(
                  handler.listRelateMoney.outsourceBudgetCost,
                  false,
                  true
                )
              )}
            </div>
          </div>
        </div>
        {/*===============  */}
        <div className="cr001-hidden-child">
          <div className="cr001-subcontractor-child">
            <div className="cr001-cr-item bg-header-row">
              <div className="cr001-cr-item-child border-bottom border-right w-p-20 padding-tb-15">
                契約工事名
              </div>
              <div className="cr001-cr-item-child border-bottom border-right w-p-10 padding-tb-15">
                業者名
              </div>
              <div className="cr001-cr-item-child border-bottom border-right w-p-6 padding-tb-15">
                ステータス
              </div>
              <div className="cr001-cr-item-child border-bottom border-right w-p-10 padding-tb-15">
                契約日
              </div>
              <div className="cr001-cr-item-child border-bottom border-right w-p-10 padding-tb-15">
                工期
              </div>
              <div className="cr001-cr-item-child border-bottom border-right w-p-10 padding-tb-15">
                見積金額（円）
              </div>
              <div className="cr001-cr-item-child border-bottom border-right w-p-10 padding-tb-15">
                原価金額（円）
              </div>
              <div className="cr001-cr-item-child border-bottom border-right w-p-10 padding-tb-15">
                予算金額（円）
              </div>
              <div className="cr001-cr-item-child border-bottom border-right w-p-10 padding-tb-15">
                発注金額（円）
              </div>
              <div className="cr001-cr-item-child border-bottom border-right w-p-10 padding-tb-15">
                ファイル
              </div>
            </div>
            {handler.isEdit && (
              <div
                className="cr001-cr-item-add"
                onClick={handler.handleAddSubcontractCost}
              >
                <img alt="" src={SquarePurple} />
                <span className="text-bold-14">項目追加</span>
              </div>
            )}
            {handler.subcontractCostList?.map((subcontractCost: any) => {
              return (
                <div
                  className="cr001-cr-item cr001-icon-rotate"
                  key={`outsourceRegisterCost${subcontractCost.key}`}
                >
                  <div className="cr001-cr-item-child border-bottom border-right w-p-20 padding-tb-15">
                    {handler.isEdit ? (
                      <div className="cr001-choose-building">
                        <div className="cr001-choose-building-icon-bonus">
                          {!subcontractCost.isInvited ? (
                            <img
                              alt=""
                              src={VectorMinus}
                              onClick={() =>
                                handler.handleDeleteSubcontractCost(
                                  subcontractCost.key
                                )
                              }
                              className="cr001-margin-icon"
                            />
                          ) : null}
                        </div>
                        <Tooltip
                          title={
                            handler.listTooltip?.includes(
                              `tooltip${subcontractCost.key}sub`
                            )
                              ? handler.listRelateMoney?.constructionName
                              : ""
                          }
                          getPopupContainer={(trigger: any) =>
                            trigger.parentNode
                          }
                          overlayInnerStyle={{
                            color: "#fff",
                            margin: "0 -4%",
                          }}
                        >
                          <span
                            className="cr001-subcontract-company-name text-tooltip"
                            style={{
                              whiteSpace: "nowrap",
                              marginTop: 2,
                            }}
                            id={`tooltip${subcontractCost.key}sub`}
                          >
                            {handler.listRelateMoney?.constructionName}
                          </span>
                        </Tooltip>
                        <Item
                          name={[
                            `outsourceRegisterCost${subcontractCost.key}`,
                            "constructionName",
                          ]}
                          className="cr-construction-name"
                          rules={[
                            () => ({
                              validator(_, value) {
                                if (value && value !== "") {
                                  // CHECK INPUT VIETNAMESE
                                  if (!REGEX.NOT_VIETNAM.test(value)) {
                                    return Promise.reject(
                                      new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                                    );
                                  }
                                  if (value?.length > 30) {
                                    return Promise.reject(new Error(""));
                                  }
                                  const check =
                                    handler.subcontractCostList?.some(
                                      (ele) =>
                                        ele?.constructionName?.trim() ===
                                          value?.trim() &&
                                        subcontractCost.key !== ele.key
                                    );
                                  if (check) {
                                    return Promise.reject(MESSAGE_DUPLICATE);
                                  }

                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error(MESSAGE.MESSAGE_REQUIRED)
                                );
                              },
                            }),
                          ]}
                        >
                          <Input
                            maxLength={30}
                            className="input-cr001"
                            placeholder="業者との契約工事名を入力してください(例：〇〇工事)" // CR #344212
                            autoComplete="off"
                            onChange={helpers.preventInputJapanese}
                            onBlur={(e) => {
                              onBlurTrimFormItem(handler.formAmount, [
                                `outsourceRegisterCost${subcontractCost.key}`,
                                "constructionName",
                              ]);
                              handler.funcCheckDuplicate(
                                handler.subcontractCostList,
                                Number(summaryId || 0),
                                e.target.value,
                                subcontractCost.id,
                                `outsourceRegisterCost${subcontractCost.key}`
                              );
                            }}
                          />
                        </Item>
                      </div>
                    ) : (
                      <Tooltip
                        title={
                          handler.listTooltip?.includes(
                            `tooltip${subcontractCost.key}construction`
                          )
                            ? `${handler.listRelateMoney?.constructionName}${subcontractCost?.constructionName}`
                            : ""
                        }
                        getPopupContainer={(trigger: any) => trigger.parentNode}
                        overlayInnerStyle={{
                          color: "#fff",
                          margin: "0 -4%",
                        }}
                      >
                        <span
                          id={`tooltip${subcontractCost.key}construction`}
                          className="cr001-subcontract-company-name text-tooltip"
                          style={{ width: "80%" }}
                        >
                          {handler.listRelateMoney?.constructionName}{" "}
                          &nbsp;&nbsp;
                          {subcontractCost?.constructionName}
                        </span>
                      </Tooltip>
                    )}
                  </div>
                  <div className="cr001-cr-item-child border-bottom border-right w-p-10 padding-tb-15">
                    {handler.isEdit ? (
                      <div
                        className={`cr001-choose-building ${
                          subcontractCost.isInvited && "icon-disable"
                        }`}
                      >
                        <div
                          className="cr001-choose-building-content"
                          onClick={() =>
                            !subcontractCost.isInvited &&
                            handler.handleViewModalExternal(
                              `outsourceRegisterCost${subcontractCost.key}`,
                              subcontractCost?.company,
                              "subcontractor"
                            )
                          }
                        >
                          <Tooltip
                            title={
                              handler.listTooltip.includes(
                                `tooltip${subcontractCost.key}company`
                              )
                                ? subcontractCost?.company?.name
                                : ""
                            }
                          >
                            <span
                              className="text-tooltip"
                              id={`tooltip${subcontractCost.key}company`}
                            >
                              {subcontractCost?.company?.name
                                ? subcontractCost?.company?.name
                                : ""}
                            </span>
                          </Tooltip>
                          <img src={BuildingSVG} alt="" />
                        </div>
                      </div>
                    ) : (
                      <Tooltip
                        title={
                          handler.listTooltip.includes(
                            `tooltip${subcontractCost.key}company`
                          )
                            ? subcontractCost?.company?.name
                            : ""
                        }
                        getPopupContainer={(trigger: any) => trigger.parentNode}
                        overlayInnerStyle={{
                          color: "#fff",
                          margin: "0 -4%",
                        }}
                      >
                        <span
                          className="cr001-subcontract-company-name text-tooltip"
                          id={`tooltip${subcontractCost.key}company`}
                        >
                          {subcontractCost?.company?.name}
                        </span>
                      </Tooltip>
                    )}
                  </div>
                  <div className="cr001-cr-item-child border-bottom border-right w-p-6 padding-tb-15">
                    {subcontractCost.isInvited ? (
                      <span
                        style={{
                          color: INVITED.invited.color,
                          textAlign: "center",
                        }}
                      >
                        {INVITED.invited.name}
                      </span>
                    ) : (
                      <span
                        style={{
                          color: INVITED.uninvited.color,
                          textAlign: "center",
                        }}
                      >
                        {INVITED.uninvited.name}
                      </span>
                    )}
                  </div>
                  <div className="cr001-cr-item-child date-error-text border-bottom border-right w-p-10 padding-tb-15 date-text-error">
                    {handler.isEdit ? (
                      <Item
                        name={[
                          `outsourceRegisterCost${subcontractCost.key}`,
                          "contractTime",
                        ]}
                      >
                        <DatePicker
                          inputReadOnly
                          placeholder=""
                          className="cr001-choose-date"
                          // getPopupContainer={(trigger: any) =>
                          //   trigger.parentNode
                          // }
                          locale={locale}
                          format={COMMON.FORMAT_DATE}
                          suffixIcon={
                            <img
                              alt=""
                              src={CalendarSvg}
                              className="icon-calender"
                            />
                          }
                        />
                      </Item>
                    ) : (
                      handler.checkFormatDate(subcontractCost?.contractTime, "")
                    )}
                  </div>
                  <div className="cr001-cr-item-child border-bottom border-right w-p-10 padding-tb-15 range-text-error">
                    {handler.isEdit ? (
                      <Item
                        name={[
                          `outsourceRegisterCost${subcontractCost.key}`,
                          "timeSpace",
                        ]}
                        rules={[
                          () => ({
                            validator(_, value) {
                              if (
                                value &&
                                value?.length > 1 &&
                                value[0] &&
                                value[1]
                              ) {
                                const startDate = value[0].format(
                                  COMMON.FORMAT_DATE
                                );
                                const endDate = value[1].format(
                                  COMMON.FORMAT_DATE
                                );
                                if (startDate === endDate) {
                                  return Promise.reject("入力内容が不正です。");
                                }
                                return Promise.resolve();
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <RangePicker
                          inputReadOnly
                          placeholder={["", ""]}
                          className="cr001-choose-date margin-6"
                          locale={locale}
                          format={COMMON.FORMAT_DATE}
                          suffixIcon={
                            <img
                              alt=""
                              src={CalendarSvg}
                              className="icon-calender"
                            />
                          }
                          separator={
                            <span className="calender-separator">-</span>
                          }
                        />
                      </Item>
                    ) : (
                      handler.checkFormatDate(
                        subcontractCost?.startTime,
                        subcontractCost?.endTime
                      )
                    )}
                  </div>
                  <div className="cr001-cr-item-child border-bottom border-right w-p-10 padding-tb-15 color-gray">
                    {handler.isEdit ? (
                      <Item
                        rules={[
                          {
                            required: REQUIRED_INPUT,
                            message: MESSAGE_REQUIRED,
                          },
                        ]}
                        name={[
                          `outsourceRegisterCost${subcontractCost.key}`,
                          "estimateMoney",
                        ]}
                      >
                        <InputNumber
                          controls={false}
                          maxLength={COMMON.MAX_INPUT_NUMBER}
                          max={COMMON.MAX_NUMBER_VALUE}
                          min={MIN_INPUT_NUMBER}
                          className="input-cr001"
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          parser={(value: any) =>
                            value.replace(/\$\s?|(,*)/g, "")
                          }
                        />
                      </Item>
                    ) : (
                      handler.checkFormatValue(
                        subcontractCost?.estimateMoney,
                        false,
                        true
                      )
                    )}
                  </div>
                  <div className="cr001-cr-item-child border-bottom border-right w-p-10 padding-tb-15 color-gray">
                    {handler.isEdit ? (
                      <Item
                        rules={[
                          {
                            required: REQUIRED_INPUT,
                            message: MESSAGE_REQUIRED,
                          },
                        ]}
                        name={[
                          `outsourceRegisterCost${subcontractCost.key}`,
                          "costMoney",
                        ]}
                      >
                        <InputNumber
                          controls={false}
                          maxLength={COMMON.MAX_INPUT_NUMBER}
                          max={COMMON.MAX_NUMBER_VALUE}
                          min={MIN_INPUT_NUMBER}
                          className="input-cr001"
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          parser={(value: any) =>
                            value.replace(/\$\s?|(,*)/g, "")
                          }
                        />
                      </Item>
                    ) : (
                      handler.checkFormatValue(
                        subcontractCost?.costMoney,
                        false,
                        true
                      )
                    )}
                  </div>
                  <div className="cr001-cr-item-child border-bottom border-right w-p-10 padding-tb-15 cr003-item-percent color-gray">
                    {handler.isEdit ? (
                      <>
                        <Item
                          rules={[
                            {
                              required: REQUIRED_INPUT,
                              message: MESSAGE_REQUIRED,
                            },
                          ]}
                          name={[
                            `outsourceRegisterCost${subcontractCost.key}`,
                            "budgetMoney",
                          ]}
                        >
                          <InputNumber
                            controls={false}
                            maxLength={COMMON.MAX_INPUT_NUMBER}
                            max={COMMON.MAX_NUMBER_VALUE}
                            min={MIN_INPUT_NUMBER}
                            className="input-cr001"
                            formatter={(value) =>
                              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                            parser={(value: any) =>
                              value.replace(/\$\s?|(,*)/g, "")
                            }
                          />
                        </Item>
                        <span className="cr001-percent-text">
                          {handler.checkFormatValue(
                            subcontractCost?.budgetMoneyPercent,
                            true,
                            true
                          )}
                        </span>
                      </>
                    ) : (
                      handler.checkFormatValue(
                        subcontractCost?.budgetMoney,
                        false,
                        true
                      ) +
                      " " +
                      handler.checkFormatValue(
                        subcontractCost?.budgetMoneyPercent,
                        true,
                        true
                      )
                    )}
                  </div>
                  <div className="cr001-cr-item-child border-bottom border-right w-p-10 padding-tb-15 cr003-item-percent color-gray">
                    {handler.isEdit ? (
                      <>
                        <Item
                          rules={[
                            {
                              required: REQUIRED_INPUT,
                              message: MESSAGE_REQUIRED,
                            },
                          ]}
                          name={[
                            `outsourceRegisterCost${subcontractCost.key}`,
                            "orderMoney",
                          ]}
                        >
                          <InputNumber
                            controls={false}
                            maxLength={COMMON.MAX_INPUT_NUMBER}
                            max={COMMON.MAX_NUMBER_VALUE}
                            min={MIN_INPUT_NUMBER}
                            className="input-cr001"
                            formatter={(value) =>
                              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                            parser={(value: any) =>
                              value.replace(/\$\s?|(,*)/g, "")
                            }
                          />
                        </Item>
                        <span className="cr001-percent-text">
                          {handler.checkFormatValue(
                            subcontractCost?.orderMoneyPercent,
                            true,
                            true
                          )}
                        </span>
                      </>
                    ) : (
                      handler.checkFormatValue(
                        subcontractCost?.orderMoney,
                        false,
                        true
                      ) +
                      " " +
                      handler.checkFormatValue(
                        subcontractCost?.orderMoneyPercent,
                        true,
                        true
                      )
                    )}
                  </div>
                  <div className="cr001-cr-item-child border-bottom border-right w-p-10 padding-tb-15 cr003-item-percent color-gray">
                    {handler.isEdit ? (
                      <UploadFileCRComponent
                        nameForm={[
                          `outsourceRegisterCost${subcontractCost.key}`,
                          "documentOutsourceRegisterCost",
                        ]}
                        fileNameDefault={subcontractCost.document}
                        form={handler.formAmount}
                        afterClear={() =>
                          handler.afterClear(
                            [
                              `outsourceRegisterCost${subcontractCost.key}`,
                              "documentOutsourceRegisterCost",
                            ],
                            handler.subcontractCostList,
                            "outsourceRegisterCost"
                          )
                        }
                      />
                    ) : subcontractCost?.document?.length != 0 ? (
                      <div
                        className="button-style"
                        onClick={() => {
                          handler.handleViewFile(subcontractCost, "outsource");
                        }}
                      >
                        <img src={DocumentIconSvg} alt="" />
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            })}

            <div className="cr001-cr-item bg-header-row">
              <div className="cr001-cr-item-child border-bottom border-right w-p-20 padding-tb-15">
                合計金額
              </div>
              <div className="cr001-cr-item-child border-bottom w-p-10 padding-tb-15"></div>
              <div className="cr001-cr-item-child border-bottom w-p-6 padding-tb-15"></div>
              <div className="cr001-cr-item-child border-bottom w-p-10 padding-tb-15"></div>
              <div className="cr001-cr-item-child border-bottom border-right w-p-10 padding-tb-15"></div>
              <div className="cr001-cr-item-child border-bottom border-right w-p-10 padding-tb-15 color-gray fw-700">
                {handler.checkFormatValue(
                  handler.totalSubContract?.estimateMoney,
                  false,
                  true
                )}
              </div>
              <div className="cr001-cr-item-child border-bottom border-right w-p-10 padding-tb-15 color-gray fw-700">
                {handler.checkFormatValue(
                  handler.totalSubContract?.costMoney,
                  false,
                  true
                )}
              </div>
              <div className="cr001-cr-item-child border-bottom border-right w-p-10 padding-tb-15 color-gray fw-700">
                {handler.checkFormatValue(
                  handler.totalSubContract?.budgetMoney,
                  false,
                  true
                )}{" "}
                {handler.checkFormatValue(
                  handler.totalSubContract?.budgetMoneyPercent,
                  true,
                  true
                )}
              </div>
              <div className="cr001-cr-item-child border-bottom border-right w-p-10 padding-tb-15 color-gray fw-700">
                {handler.checkFormatValue(
                  handler.totalSubContract?.orderMoney,
                  false,
                  true
                )}{" "}
                {handler.checkFormatValue(
                  handler.totalSubContract?.orderMoneyPercent,
                  true,
                  true
                )}
              </div>
              <div className="cr001-cr-item-child border-bottom w-p-10 padding-tb-15"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="cr001-cr-item bg-header-row">
        <div className="cr001-cr-item-child border-bottom border-right cr-2 padding-tb-15">
          発注予算残金（円）
        </div>
        <div className="cr001-cr-item-child border-bottom border-right cr-2 padding-tb-15">
          発注予算消化率（％）
        </div>
      </div>
      <div className="cr001-cr-item ">
        <div className="cr001-cr-item-child border-bottom border-right cr-2 padding-tb-15 color-gray">
          {handler.checkFormatValue(handler.consumptionRate.value, false, true)}
        </div>
        <div className="cr001-cr-item-child border-bottom border-right cr-2 padding-tb-15 color-gray">
          {handler.consumptionRate.percent ||
          handler.consumptionRate.percent == 0
            ? `${handler.consumptionRate.percent.toLocaleString("en-US", {
                maximumFractionDigits: 2,
              })}%`
            : `${DEFAULT_PERCENT} %`}{" "}
        </div>
      </div>
    </>
  );
};

export default memo(Subcontract);
