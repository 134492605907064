import { PM005SliceEntity } from './../type/Presenter';
import { createSlice } from '@reduxjs/toolkit';

export const initialState: PM005SliceEntity = {
    isRefresh: false,
    projectDetail: {},
};

export const PM005Slice = createSlice({
    name: 'pm005',
    initialState,
    reducers: {
        resetPM005: () => initialState,
        refreshPM005: (state) => {
            state.isRefresh = !state.isRefresh;
        },

        setProjectDetail: (state, action) => {
            state.projectDetail = action.payload;
        },
    },
});

export const { refreshPM005, resetPM005, setProjectDetail } = PM005Slice.actions;

export default PM005Slice.reducer;
