import { useMemo } from "react";
import { HandlerImpl } from "../handler/HandlerImpl";
import BreadcrumbComponent from "../../../../../common/components/breadcrumb/BreadcrumbComponent";
import { Button, Empty, Layout } from "antd";
import UploadLightSVG from "../../../../../assets/icons/upload-icon-purple.svg";
import ProjectComponent from "../component/project";
import HomeSvg from "../../../../../assets/icons/home.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import MenuWrapperComponent from "../../../../../common/components/menu-wrapper";
import ConstructionItem from "./Construction";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { PARAM, DEFAULT_PARAM } from "../handler/HandlerProject";
import moment from "moment";
import COMMON from "../../../../../common/constants/COMMON";
import EmptyData from "../../../../../assets/icons/empty-data.svg";
import helpers from "../../../../../common/helpers/common";
import {
  AUTHORIZATION_CODE,
  SYSTEM_ROLES,
} from "../../../../../common/constants/AUTHORIZATION";
import { getTitleByFromToDate } from "../../../AM008/helper";
import NoDataTableComponent from "../../../../../common/components/no-data/NoDataTable";

interface props {
  handler: HandlerImpl;
}
const { Content, Sider } = Layout;

const BREADCRUMB_AM002: any[] = [
  {
    title: "勤怠管理",
    url: "",
    icon: (
      <img style={{ paddingBottom: 2, paddingRight: 8 }} alt="" src={HomeSvg} />
    ),
  },
  {
    title: "案件別勤務実績",
    url: "",
    icon: "",
  },
];
let currentParams: any = [];

const MainUI = ({ handler }: props) => {
  const isCollapsed = useSelector(
    (state: RootState) => state.am005.isCollapsed
  );
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");
  const projectName = searchParams.get("projectName");
  const startDate =
    searchParams.get(PARAM.startDate) || DEFAULT_PARAM.startDate;
  const endDate = searchParams.get(PARAM.endDate) || DEFAULT_PARAM.endDate;
  const clear = +(searchParams.get(PARAM.clear) || DEFAULT_PARAM.clear);
  const permission = useSelector(
    (state: RootState) => state.auth.authData?.permission
  );

  const isRoleAdmin = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdmin
  );

  const isRoleAdminSystem = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdminSystem
  );

  useEffect(() => {
    if (projectId) {
      handler.getListConstructionProject({
        projectId: projectId,
        from: !clear ? helpers.getFilterTime(startDate) : undefined,
        to: helpers.getFilterTimeTo(endDate),
      });
    }
  }, [projectId, startDate, endDate]);

  const totalLaborCost = useMemo(() => {
    if (!handler.constructions) return 0;
    const totalLabor = handler.constructions?.reduce(
      (acc: number, cur: any) => {
        return acc + (cur?.laborCost || 0);
      },
      0
    );

    return helpers.formatCurrency(totalLabor);
  }, [handler.constructions]);

  const totalWorkingDay = useMemo(() => {
    if (!handler.constructions) return 0;
    const totalLabor = handler.constructions?.reduce(
      (acc: number, cur: any) => {
        return acc + (cur?.totalWorkingDay || 0);
      },
      0
    );

    return helpers.formatCurrency(totalLabor);
  }, [handler.constructions]);

  const params = window.location.search;
  useEffect(() => {
    if (!currentParams.includes(params) && params) {
      currentParams.push(params);
    }
    return () => {
      if (!params) {
        currentParams = [];
      }
    };
  }, [params]);

  return (
    <MenuWrapperComponent chosenKey={23} openKey={2}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
        }}
      >
        <div className="om002-header-container">
          <BreadcrumbComponent data={BREADCRUMB_AM002} />
          <div className="header-row-title" style={{ marginTop: 8 }}>
            案件別勤務実績
          </div>
        </div>
        {permission?.includes(AUTHORIZATION_CODE.B9) ||
        isRoleAdmin ||
        isRoleAdminSystem ? (
          <Button
            className="button-brown-3"
            style={{ border: "1px solid #605BFF", width: 106 }}
            size="large"
            onClick={() =>
              handler.exportData({
                from: !clear ? helpers.getFilterTime(startDate) : "",
                to: helpers.getFilterTimeTo(endDate) ?? "",
                projectId: projectId,
                projectName: projectName,
              })
            }
          >
            <div className="center-item">
              <img
                alt=""
                src={UploadLightSVG}
                style={{
                  marginRight: 8,
                  fontWeight: 700,
                  fontSize: 14,
                }}
              />
              <div className="export-button" style={{ color: "#605BFF" }}>
                出力
              </div>
            </div>
          </Button>
        ) : (
          <div></div>
        )}
      </div>
      <Layout
        style={{ minHeight: "85vh", marginTop: 24 }}
        className={isCollapsed ? "close-side" : "open-side"}
      >
        <Sider>
          <ProjectComponent />
        </Sider>
        <Layout style={{ backgroundColor: "#ffffff" }}>
          {handler.constructions?.length === 0 ? (
            <div className="data-empty" style={{ height: "80%" }}>
              <img src={EmptyData} alt="" />
            </div>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginLeft: 24,
                  marginTop: 16,
                  marginBottom: 18,
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    className="total-money-style"
                    style={{ marginRight: 16, minWidth: 250 }}
                  >
                    合計金額：
                    {handler.projectList.length > 0 ? totalLaborCost : 0}円
                  </div>
                  <div className="total-money-style">
                    合計稼働人工数：
                    {handler.projectList.length > 0 ? totalWorkingDay : 0}人工
                  </div>
                </div>

                <div className="date-label" style={{ textAlign: "right" }}>
                  {getTitleByFromToDate(startDate, endDate)}
                </div>
              </div>

              <Content
                style={{
                  margin: "0px 24px 16px 24px",
                  backgroundColor: "#ffffff",
                  overflow: "auto",
                }}
              >
                {handler?.projectList?.length > 0 ? (
                  handler.constructions.map((construction) => {
                    return (
                      <ConstructionItem
                        key={construction.id}
                        construction={construction}
                        activeKey={handler.activeKey}
                        funcChangeCollapse={(value) =>
                          handler.funcChangeCollapse(value, {
                            from: !clear
                              ? helpers.getFilterTime(startDate)
                              : undefined,
                            to: helpers.getFilterTimeTo(endDate),
                          })
                        }
                        handleChangePage={handler.handleChangePage}
                      />
                    );
                  })
                ) : (
                    <NoDataTableComponent/>
                )}
              </Content>
            </>
          )}
        </Layout>
      </Layout>
    </MenuWrapperComponent>
  );
};

export default MainUI;
