import { Form, Select, DatePicker, Tag, Empty, Tooltip } from "antd";
import CloseIcon from "../../../../../assets/icons/x-icon-modal.svg";
import { CM003Handler } from "../handler/HandlerImpl";
import COMMON from "../../../../../common/constants/COMMON";
import moment from "moment";
import locale from "antd/es/date-picker/locale/ja_JP";
import { CONSTRUCTION_ID_KEY, PROJECT_PARAM_ID_KEY } from "../handler/Handler";
import { useSearchParams } from "react-router-dom";

type props = {
  handler: CM003Handler;
};

const CM003FilterComponent = ({ handler }: props) => {
  const { RangePicker } = DatePicker;
  const [searchParams] = useSearchParams();
  const constructionId: string = searchParams.get(CONSTRUCTION_ID_KEY) ?? "";
  const projectId: string = searchParams.get(PROJECT_PARAM_ID_KEY) ?? "";

  return (
    <Form name="formFilter" form={handler.form} onFinish={handler.handleFilter}>
      <div className="cm003-filter">
        <div className="construction">
          <div className="label text-nowrap">工事名</div>
          <div>
            <Form.Item name="construction">
              <Select
                allowClear
                className="item"
                placeholder=""
                defaultValue={
                  constructionId !== "" ? Number(constructionId) : null
                }
                showArrow
                showSearch
                listHeight={130}
                filterOption={() => true}
                loading={handler.loadingOption}
                onSearch={(e: any) =>
                  handler.handleSearchOptionConstruction(e, projectId)
                }
                getPopupContainer={(trigger: any) => trigger.parentNode}
                tagRender={({ label, closable, onClose }: any) => {
                  return (
                    <Tag
                      closable={closable}
                      onClose={onClose}
                      color="rgba(231, 230, 255, 1)"
                      closeIcon={
                        <img
                          style={{ width: 10, marginLeft: 6 }}
                          alt=""
                          src={CloseIcon}
                        />
                      }
                      style={{
                        margin: "2px 4px 2px 0",
                        display: "flex",
                        alignItems: "center",
                        height: 28,
                        borderRadius: 4,
                        color: "#222222",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      {label[0]?.props?.children}
                    </Tag>
                  );
                }}
                onPopupScroll={(e: any) =>
                  handler.handleScrollConstruction(e, projectId)
                }
                notFoundContent={
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="データなし"
                  />
                }
              >
                {handler.constructions.map((select) => {
                  return (
                    <Select.Option
                      // style={{ alignItems: 'center' }}
                      key={select.id}
                      value={select.id}
                    >
                      {/* <Tooltip title={select?.name} placement="topLeft">
                                                    {handler.formatLongString(
                                                        select?.name,
                                                        MAX_LENGTH_TEXT,
                                                    )}
                                                </Tooltip> */}
                      {select?.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
        </div>
        <div className="payment-date">
          <div className="label text-nowrap">入金日</div>
          <div>
            <Form.Item name="rangeDate">
              <RangePicker
                allowClear={true}
                locale={locale}
                inputReadOnly
                getPopupContainer={(trigger: any) => trigger.parentNode}
                className="item"
                format={COMMON.FORMAT_DATE_CI}
                placeholder={["開始日", "終了日"]}
                // defaultValue={[moment().startOf("month"), moment()]}
              />
            </Form.Item>
          </div>
        </div>
        <div className="action">
          <div className="approve" onClick={() => handler.form.submit()}>
            絞込み
          </div>
          <div className="clear" onClick={() => handler.handleResetForm()}>
            クリア
          </div>
        </div>
      </div>
    </Form>
  );
};

export { CM003FilterComponent };
