import React from 'react';
import { HandlerImpl } from '../handler/HandlerImpl';
import { Modal, Button } from 'antd';
import Table from './Table';

interface props {
    handler: HandlerImpl;
}

function ModalCM024({ handler }: props) {
    return (
        <Modal
            title="外注費"
            className="cm024-modal"
            visible={handler.visibleCM0027}
            centered
            footer={null}
            width={'98%'}
            onCancel={handler.handleCancelModal}
        >
            <div className="cm024-modal-header">
                <div className="cm024-modal-header-item">
                    <span className="cm024-modal-label">資料名</span>
                    <span className="cm024-modal-title">社内収支_材料費_業者別_202212</span>
                </div>
                <div className="cm024-modal-header-item">
                    <span className="cm024-modal-label">作成日</span>
                    <span className="cm024-modal-title">2022年12月12日</span>
                </div>
                <div className="cm024-modal-header-item">
                    <span className="cm024-modal-label">対象期間</span>
                    <span className="cm024-modal-title">2021年1月～2021年2月</span>
                </div>
            </div>
            <Table handler={handler} />
            <div className="cm024-modal-footer">
                <Button size="large" className="button-purple cm024-modal-btn-ok">
                    取り下げ
                </Button>
                <Button size="large" className="cm024-modal-btn-cancel">
                    キャンセル
                </Button>
            </div>
        </Modal>
    );
}

export default ModalCM024;
