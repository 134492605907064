// Built-in
import React, { useState } from "react";

// 3rd party lib
import { useDispatch, useSelector } from "react-redux";

// Source files
import { MaterialCostServiceImpl } from "./../../usecase/ServiceImpl";
import { RootState } from "../../../../../store";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import {
  refreshCM0015,
  setCheckedCollapseDirect,
  setCheckedCollapseParent,
  setMaterialCostData,
} from "../slice/Slice";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import COMMON from "../../../../../common/constants/COMMON";
import moment from "moment";
import {
  DataMaterialCostType,
  DocumentIndirectCostData,
} from "../../entity/Entity";
import { STATUS_CM_CHILD_TABLE } from "../../../../../common/constants/COMMON";
import { setIsEnable } from "../../../CM004/presenter/slice/Slice";
import { STATUS_PARENT } from "../../../CM0013/presenter/handler/HandlerMaterialCost";
import exportFileDataCM0015 from "../../../../../common/helpers/export-excel/CM0015";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import ConfirmModalAsync from "../../../../../common/components/modal/ConfirmModalAsync";
import { useSearchParams } from "react-router-dom";
import { doExportForResponse } from "../../../../../common/helpers/exports/common";

const MaterialCostHandler = (service: MaterialCostServiceImpl) => {
  const dispatch = useDispatch();
  const [checkedList, setCheckedList] = useState<any[]>([]);
  const [checkAll, setCheckAll] = useState(false);
  const [expandKey, setExpandKey] = useState<any[]>([]);
  const [refresh, setRefresh] = useState(false);
  const [searchParams] = useSearchParams();
  const projectName = searchParams.get("projectName");
  const constructionInfo = useSelector(
    (state: RootState) => state.cm004.constructionInfo
  );

  const checkedCollapse = useSelector(
    (state: RootState) => state.cm0015.checkedCollapse
  );

  const screenName = COMMON.CM_FILE_STRUCTURE.SCREEN_NAME;
  const tab1 = COMMON.CM_FILE_STRUCTURE.PARENT_TAB.TAB_4.NAME;
  const submitDate = moment().format(COMMON.FORMAT_DATE_SUBMIT);
  const fileName: string = `${screenName}_${tab1}_${submitDate}`;

  const isCollapsed = (record: any) => {
    return expandKey.some((element) => element === record.key);
  };
  const data = useSelector((state: RootState) => state.cm0015.dataMaterialCost);

  const getMoneyIndirectCostList = async (params: {
    constructionId: number;
    keyword?: string;
    sortBy?: string;
    sortType?: string;
  }): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const responseData = await service.getMoneyIndirectCostList(params);
      const finalResult: any[] = responseData ? responseData.results : [];

      // Có 3 giá trị: 承認済 & 承認待ち & 未承認
      // Điều kiện hiển thị trạng thái 承認済: Khi tất cả các record bảng con có trạng thái 承認済
      // Điều kiện hiển thị trạng thái 承認待ち: Khi có ít nhất 1 record ở bảng con có trạng thái 承認待ち
      // Điều kiện hiển thị trạng thái 未承認: Khi tồn tại 1 record ở bảng con có 1 trong 3 trạng thái 未承認, 取り下げ, 否認

      // get data for child
      for await (const [index, element] of finalResult.entries()) {
        const responseChild = await service.getMoneyIndirectCostInformationList(
          {
            indirectCostId: element.id,
            constructionId: params.constructionId,
            page: COMMON.DEFAULT_PAGE,
            size: COMMON.DEFAULT_SIZE,
            sortBy: "createdAt",
            sortType: "ASC",
          }
        );
        let checkApproved = true;
        let checkWaitingApproved = false;
        if (responseChild?.results?.length === 0) checkApproved = false;

        if (responseChild) {
          const dataChild = responseChild.results.map((data, i: number) => {
            if (data.status?.code !== STATUS_CM_CHILD_TABLE.MONEY_APPROVED.CODE)
              checkApproved = false;
            if (
              data.status?.code ===
              STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE
            )
              checkWaitingApproved = true;
            return {
              key: data.id,
              no: i + 1,
              id: data.id,
              isEnable: true,
              inputMethod: data.inputMethodCategory.id,
              inputMethodName: data.inputMethodCategory.description,
              inputMethodCode: data.inputMethodCategory.code,

              targetPeriodFrom: data.startDate
                ? moment(data.startDate).format(COMMON.FORMAT_DATE2)
                : "",
              targetPeriodTo: data.endDate
                ? moment(data.endDate).format(COMMON.FORMAT_DATE2)
                : "",
              datePayment: data.paymentDate
                ? moment(data.paymentDate).format(COMMON.FORMAT_DATE2)
                : "",
              manualInput: data.value,
              multiplicationFactor: data.multiplicationFactor,
              amountTotal: 1000,
              actualCost: data.actualCost,
              evidence: data.evidence,
              status: data.status,
              value: data.value,
              directCost: data.directCost,
              note: data.note,
            };
          });
          finalResult[index].child = dataChild;
        } else {
          finalResult[index].child = [];
        }
        if (checkApproved) finalResult[index].status = STATUS_PARENT.approved;
        else if (checkWaitingApproved)
          finalResult[index].status = STATUS_PARENT.waiting_for_approve;
        else finalResult[index].status = STATUS_PARENT.rejected;
      }

      const dataView: DataMaterialCostType[] = finalResult.map(
        (data, i: number) => {
          return {
            id: data.id,
            key: data.id,
            no: i + 1,
            name: data.item,
            budgetAmount: data.budgetMoney ?? 0,
            actualCost: data.actualCost ?? 0,
            budgetBalance: (data.budgetMoney ?? 0) - (data.actualCost ?? 0),
            threshold: data.threshold ?? 0,
            enable: data.enable,
            status: data.status,
            child: data.child,
          };
        }
      );
      dispatch(setMaterialCostData(dataView));
      setExpandKey([dataView[0]?.key]);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getMoneyIndirectCostListForExport = async (params: {
    constructionId: number;
    keyword?: string;
    sortBy?: string;
    sortType?: string;
  }): Promise<DataMaterialCostType[]> => {
    try {
      const responseData = await service.getMoneyIndirectCostList(params);
      const finalResult: any[] = responseData ? responseData.results : [];

      // Có 3 giá trị: 承認済 & 承認待ち & 未承認
      // Điều kiện hiển thị trạng thái 承認済: Khi tất cả các record bảng con có trạng thái 承認済
      // Điều kiện hiển thị trạng thái 承認待ち: Khi có ít nhất 1 record ở bảng con có trạng thái 承認待ち
      // Điều kiện hiển thị trạng thái 未承認: Khi tồn tại 1 record ở bảng con có 1 trong 3 trạng thái 未承認, 取り下げ, 否認

      // get data for child
      for await (const [index, element] of finalResult.entries()) {
        const responseChild = await service.getMoneyIndirectCostInformationList(
          {
            indirectCostId: element.id,
            constructionId: params.constructionId,
            page: COMMON.DEFAULT_PAGE,
            size: COMMON.DEFAULT_SIZE,
            sortBy: "createdAt",
            sortType: "ASC",
          }
        );
        let checkApproved = true;
        let checkWaitingApproved = false;
        if (responseChild?.results?.length === 0) checkApproved = false;

        if (responseChild) {
          const dataChild = responseChild.results.map((data, i: number) => {
            if (data.status?.code !== STATUS_CM_CHILD_TABLE.MONEY_APPROVED.CODE)
              checkApproved = false;
            if (
              data.status?.code ===
              STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE
            )
              checkWaitingApproved = true;
            return {
              key: data.id,
              no: i + 1,
              id: data.id,
              isEnable: true,
              inputMethod: data.inputMethodCategory.id,
              inputMethodName: data.inputMethodCategory.description,
              inputMethodCode: data.inputMethodCategory.code,

              targetPeriodFrom: data.startDate
                ? moment(data.startDate).format(COMMON.FORMAT_DATE2)
                : "",
              targetPeriodTo: data.endDate
                ? moment(data.endDate).format(COMMON.FORMAT_DATE2)
                : "",
              datePayment: data.paymentDate
                ? moment(data.paymentDate).format(COMMON.FORMAT_DATE2)
                : "",
              manualInput: data.value,
              multiplicationFactor: data.multiplicationFactor,
              amountTotal: 1000,
              actualCost: data.actualCost,
              evidence: data.evidence,
              status: data.status,
              value: data.value,
              directCost: data.directCost,
            };
          });
          finalResult[index].child = dataChild;
        } else {
          finalResult[index].child = [];
        }
        if (checkApproved) finalResult[index].status = STATUS_PARENT.approved;
        else if (checkWaitingApproved)
          finalResult[index].status = STATUS_PARENT.waiting_for_approve;
        else finalResult[index].status = STATUS_PARENT.rejected;
      }

      const result: DataMaterialCostType[] = finalResult.map(
        (data, i: number) => {
          return {
            id: data.id,
            key: data.id,
            no: i + 1,
            name: data.item,
            budgetAmount: data.budgetMoney ?? 0,
            actualCost: data.actualCost ?? 0,
            budgetBalance: (data.budgetMoney ?? 0) - (data.actualCost ?? 0),
            threshold: data.threshold ?? 0,
            enable: data.enable,
            status: data.status,
            child: data.child,
          };
        }
      );
      return result;
    } catch (error: any) {
      throw error;
    }
  };

  const onChangeCollapseRow = (record: any, collapsed: boolean) => {
    const collapsedRow = collapsed
      ? [...expandKey, record.key]
      : expandKey.filter((element) => element !== record.key);

    setExpandKey(collapsedRow);
  };
  const isCheckbox = (record: any): boolean =>
    checkedList.some((element) => element === record.key);

  const onCheckboxChange = (record: any, checked: boolean) => {
    // setCheckedList(list);
    let newCheckList: any[] = [];

    if (checked) {
      newCheckList = [...checkedList, record.key];
    } else {
      const checkedData = checkedList.filter(
        (element: any) => element !== record.key
      );
      newCheckList = checkedData;
    }
    const possibleItems: any[] = [];

    for (let element of data) {
      if (
        element.status?.code === STATUS_PARENT.rejected.code &&
        element?.child?.length > 0
      )
        possibleItems.push(element.id);
    }
    newCheckList = [...new Set(newCheckList)];
    setCheckedList(newCheckList);
    setCheckAll(newCheckList.length === possibleItems.length);
    dispatch(setCheckedCollapseParent({ id: record.id, checked: checked }));
  };

  const onCheckAllChange = (checked: boolean) => {
    setCheckAll(checked);
    if (checked) {
      const itemCheckList: any[] = [];
      for (const item of data) {
        if (
          item.status?.code === STATUS_PARENT.rejected.code &&
          item?.child?.length
        )
          itemCheckList.push(item.key);
      }
      setCheckedList(itemCheckList);

      // redux
      const checkedCollapseId = checkedCollapse.map((element) => element.id);
      const uncheckedData: any[] = [];
      itemCheckList.forEach((element) => {
        const isExisted = checkedCollapseId.includes(element);
        if (!isExisted) uncheckedData.push({ id: element, checked: [] });
      });
      dispatch(
        setCheckedCollapseDirect([...checkedCollapse, ...uncheckedData])
      );
    } else {
      setCheckedList([]);
      dispatch(setCheckedCollapseDirect([]));
    }
  };
  const putMoneyIndirectCostEnable = async (payload: any, id: number) => {
    try {
      dispatch(setLoading(true));
      const response = await service.putMoneyIndirectCostEnable({
        id: id,
        enable: payload.enable,
        threshold: payload.threshold,
      });
      SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const putMoneySubmitIndirectCost = async () => {
    ConfirmModalAsync({
      onOk: async () => {
        try {
          dispatch(setLoading(true));
          const listDocumentIndirectCostData: DocumentIndirectCostData[] =
            checkedCollapse.map((element) => {
              const indirectCost = data.find((ele) => ele.id === element.id);
              const constructionName = constructionInfo?.constructionName ?? "";
              const sectionName = indirectCost?.name ?? "";

              // [Tên dự án_Tên công trình_Tên màn_Tên tab_Tên mục_năm_tháng_ngày submit]
              const fileName: string = `${projectName}_${constructionName}_${screenName}_${tab1}_${sectionName}_${submitDate}`;

              const payloadData: DocumentIndirectCostData = {
                fileName: fileName,
                documentIndirectCostId: element.id,
                listDocumentIndirectCostInformationId: element.checked,
              };
              return payloadData;
            });
          const response = await service.putMoneySubmitIndirectCost({
            listDocumentIndirectCostData,
          });
          setCheckedList([]);
          setCheckAll(false);
          dispatch(setCheckedCollapseDirect([]));
          dispatch(refreshCM0015());
          SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
          setRefresh(!refresh);
        } catch (error: any) {
          ErrorNotification(NOTIFICATION_TITLE.ERROR);
        } finally {
          dispatch(setLoading(false));
        }
      },
      onCancel: () => {},
      className: "confirm__modal confirm__modal-purple-oke",
      title: MESSAGE.MESSAGE_TITLE_BEFORE_CREATE,
      description: MESSAGE.MESSAGE_DESCRIPTION_CR_12_1,
      description2: MESSAGE.MESSAGE_DESCRIPTION_COMMON_QUESTION_2,

      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_MODAL_2,
    });
  };

  const handleCheckEnableSubmit = () => {
    const isEnable = checkedList?.length > 0;
    dispatch(setIsEnable(isEnable));
  };

  const exportFile = async (params: {
    constructionId: number;
    keyword?: string;
    sortBy?: string;
    sortType?: string;
    projectName: string;
  }) => {
    try {
      dispatch(setLoading(true));
      const response = await service.doExportCM0015({
        constructionId: params.constructionId,
        keyword: params.keyword,
        sortBy: params.sortBy,
        sortType: params.sortType,
        projectName,
      } as any);
      doExportForResponse(response, fileName + ".xlsx");
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  return {
    checkedList,
    checkAll,
    expandKey,
    refresh,
    onCheckAllChange,
    onCheckboxChange,
    isCheckbox,
    isCollapsed,
    onChangeCollapseRow,
    getMoneyIndirectCostList,
    putMoneyIndirectCostEnable,
    putMoneySubmitIndirectCost,
    handleCheckEnableSubmit,
    exportFile,
  };
};

export default MaterialCostHandler;
