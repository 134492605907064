// Core library
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import { CM0022ServiceImpl } from "./../../usecase/ServiceImpl";
import helpers from "../../../../../common/helpers/common";
import moment from "moment";
import { uuidv4 } from "@firebase/util";
import {
  refreshCM0022,
  setStatusCM0022,
  setVisibleApproveCM0022,
} from "../slice/Slice";
import COMMON from "../../../../../common/constants/COMMON";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import { exportCM0022 } from "../../../../../common/helpers/exports/cm/template_cm0022";
import { RootState } from "../../../../../store";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import ConfirmModalAsync from "../../../../../common/components/modal/ConfirmModalAsync";
import {
  refreshAM0020,
  setIsHavingData,
} from "../../../CM0020/presenter/slice/Slice";
import { doExportForResponse } from "../../../../../common/helpers/exports/common";

export const MENU_ITEM = [
  {
    name: "業者別",
    key: "trader",
  },
  {
    name: "案件別",
    key: "project",
  },
];

const PARAMS = {
  menu: "menu2",
  from: "from22",
  to: "to22",
  clear: "clear22",
};

export const PARAMS_DEFAULT = {
  menu: MENU_ITEM[0].key,
  from: helpers.getFilterTime(moment()),
  to: helpers.getFilterTime(moment()),
};

export const COLUMN_SORT = [
  "paymentExcludingTax",
  "taxConsumption",
  "paymentExcludedTax",
];
const FILE_NAME = `社内収支_材料費_案件別_${moment().format(
  COMMON.FORMAT_DATE_SUBMIT
)}`;

const CM0022Handler = (service: CM0022ServiceImpl) => {
  const dispatch = useDispatch();
  const loading = useSelector((state: RootState) => state.common.loading);

  const [searchParams] = useSearchParams();
  const menu = searchParams.get(PARAMS.menu) || PARAMS_DEFAULT.menu;
  const from = searchParams.get(PARAMS.from) || PARAMS_DEFAULT.from;
  const to = searchParams.get(PARAMS.to) || PARAMS_DEFAULT.to;
  const clear = searchParams.get(PARAMS.clear);

  const [dataView, setDataView] = useState<any>([]);
  const [expandKey, setExpandKey] = useState<any>([]);
  const [refreshStatus, setRefreshStatus] = useState(false);
  const [filterCurrent, setFilterCurrent] = useState({ from: "", to: "" });
  const [pagination, setPagination] = useState({ numPages: 0, page: 1 });
  const [summary, setSummary] = useState<any[]>([]);

  const getMaterialCostProjectSummary = async (params: {
    from: string;
    to: string;
  }) => {
    try {
      dispatch(setLoading(true));
      const response = await service.getMaterialCostProjectSummary(params);
      setSummary(response?.results ?? []);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getMoneySummaryMaterialCostProjectConstructionList = async (params: {
    projectId: number;
    from: string;
    to: string;
    sortBy?: string;
    sortType?: string;
  }) => {
    const response =
      await service.getMoneySummaryMaterialCostProjectConstructionList({
        ...params,
        page: 1,
        size: COMMON.DEFAULT_SIZE,
      });
    if (response?.results && response.results.length > 0) {
      let dataFirst = [...response?.results];
      if (response.pagination.numPages > 1) {
        for (
          let pageFirst = 2;
          pageFirst <= response.pagination.numPages;
          pageFirst++
        ) {
          const responseRe =
            await service.getMoneySummaryMaterialCostProjectConstructionList({
              ...params,
              page: pageFirst,
              size: COMMON.DEFAULT_SIZE,
            });
          if (responseRe?.results && responseRe?.results?.length > 0) {
            dataFirst = [...dataFirst, ...responseRe?.results];
          }
        }
      }
      return dataFirst;
    }
    return [];
  };

  const getMoneySummaryMaterialCostProjectConstructionListMaterialCost =
    async (params: {
      constructionId: number;
      from: string;
      to: string;
      sortBy?: string;
      sortType?: string;
    }) => {
      const response =
        await service.getMoneySummaryMaterialCostProjectConstructionListMaterialCost(
          {
            ...params,
            page: 1,
            size: COMMON.DEFAULT_SIZE,
          }
        );
      if (response?.results && response.results.length > 0) {
        let dataFirst = [...response?.results];
        if (response.pagination.numPages > 1) {
          for (
            let pageFirst = 2;
            pageFirst <= response.pagination.numPages;
            pageFirst++
          ) {
            const responseRe =
              await service.getMoneySummaryMaterialCostProjectConstructionListMaterialCost(
                {
                  ...params,
                  page: pageFirst,
                  size: COMMON.DEFAULT_SIZE,
                }
              );
            if (responseRe?.results && responseRe?.results?.length > 0) {
              dataFirst = [...dataFirst, ...responseRe?.results];
            }
          }
        }
        return dataFirst;
      }
      return [];
    };

  const getDataTable = async (
    params: {
      page: number;
      size: number;
      from: string;
      to: string;
      sortBy?: string;
      sortType?: string;
    },
    isScroll?: boolean
  ) => {
    dispatch(setLoading(true));
    try {
      if (!isScroll) {
        if (
          filterCurrent.from === params.from &&
          filterCurrent.to === params.to
        )
          return;
        setFilterCurrent({ from: params.from, to: params.to });
      }
      const resFirst = await service.getMoneySummaryMaterialCostProjectList({
        page: params.page,
        size: params.size,
        from: params.from,
        to: params.to,
        sortBy: params.sortBy ?? COMMON.DEFAULT_SORT_BY,
        sortType: params.sortType ?? COMMON.DEFAULT_SORT_TYPE,
      });
      const results: any[] = [];
      const keyExpand: any[] = [];

      if (resFirst?.pagination) setPagination(resFirst.pagination);

      if (resFirst?.results && resFirst.results?.length > 0) {
        for await (const [index, item] of resFirst.results.entries()) {
          const objFirst = {
            ...item,
            key: uuidv4(),
            no: index + 1 + params.size * (params.page - 1),
            paymentAmountExcludeTax: 0,
            consumptionTax: 0,
            taxInclude: 0,
            children: [] as any[],
          };
          if (index === 0) keyExpand.push(objFirst.key);

          const resSecond =
            await getMoneySummaryMaterialCostProjectConstructionList({
              ...params,
              projectId: item.id,
            });

          if (resSecond.length > 0) {
            for await (const [indexChild, itemChild] of resSecond.entries()) {
              let objSecond = {
                ...itemChild,
                key: uuidv4(),
                no: indexChild + 1,
                consumptionTax: 0,
                taxInclude: 0,
                children: [] as any[],
              };
              if (indexChild === 0 && index === 0)
                keyExpand.push(objSecond.key);
              const resChildThird =
                await getMoneySummaryMaterialCostProjectConstructionListMaterialCost(
                  {
                    ...params,
                    constructionId: itemChild.id,
                  }
                );

              let consumptionTax = 0;
              let taxInclude = 0;

              objSecond.children = resChildThird?.map((element: any, index) => {
                consumptionTax +=
                  element.paymentAmountExcludeTax * (element?.taxSetting ?? 1);
                taxInclude +=
                  element.paymentAmountExcludeTax * (element?.taxSetting ?? 1) +
                  element.paymentAmountExcludeTax;

                return {
                  ...element,
                  key: uuidv4(),
                  no: index + 1,
                  consumptionTax: Math.floor(
                    element.paymentAmountExcludeTax * (element?.taxSetting ?? 1)
                  ),
                  taxInclude: Math.floor(
                    element.paymentAmountExcludeTax *
                    (element?.taxSetting ?? 1) +
                    element.paymentAmountExcludeTax
                  ),
                };
              });
              objSecond.consumptionTax = Math.floor(consumptionTax);
              objSecond.taxInclude = Math.floor(taxInclude);
              objFirst.paymentAmountExcludeTax +=
                objSecond?.paymentAmountExcludeTax ?? 0;
              objFirst.consumptionTax += objSecond?.consumptionTax ?? 0;
              objFirst.taxInclude += objSecond?.taxInclude ?? 0;
              objFirst.children.push(objSecond);
            }
          }

          results.push(objFirst);
        }
      }
      if (params.page === 1) setExpandKey(keyExpand);

      setDataView((preState: any[]) => {
        if (params.page === 1) return results;
        else return [...preState, ...results];
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getMoneySummaryMaterialCostProjectStatus = async (params: {
    from: string;
    to: string;
  }) => {
    try {
      setFilterCurrent({ from: params.from, to: params.to });
      const response = await service.getMoneySummaryMaterialCostProjectStatus(
        params
      );
      if (response?.results) dispatch(setStatusCM0022(response.results));
      else dispatch(setStatusCM0022({}));
    } catch (error) {
      dispatch(setStatusCM0022({}));
    }
  };

  const funcSetExpendKey = async (
    key: any,
    id: number,
    level: string,
    idParent?: number
  ) => {
    const checkKey = funcCheckKeyExpand(key);
    if (checkKey) {
      const newExpandKey = expandKey.filter(
        (keyExpand: any) => keyExpand !== key
      );
      setExpandKey(newExpandKey);
    } else {
      setExpandKey([...expandKey, key]);
    }
  };

  const funcCheckKeyExpand = (key: any) => {
    if (!key || !expandKey) return false;
    return expandKey.some((element: any) => element === key);
  };

  const onCancelSubmitModal = () => {
    dispatch(setVisibleApproveCM0022(false));
  };

  const onSubmit = async () => {
    ConfirmModalAsync({
      onOk: async () => {
        try {
          dispatch(setLoading(true));
          const fileName = `社内収支_材料費_案件別_${moment().format(
            COMMON.FORMAT_DATE_SUBMIT
          )}`;
          const response =
            await service.putMoneySummaryMaterialCostProjectSubmit({
              fileName,
              startDate: helpers.getFilterTimeMonth(from),
              endDate: helpers.getFilterTimeMonthTo(to),
            });

          SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
          dispatch(setVisibleApproveCM0022(false));
          setRefreshStatus((curState) => !curState);
          setFilterCurrent({ from: "", to: "" });
        } catch (error: any) {
          ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        } finally {
          dispatch(setLoading(false));
        }
      },
      onCancel: () => { },
      className: "confirm__modal confirm__modal-purple-oke",
      title: MESSAGE.MESSAGE_TITLE_BEFORE_CREATE,
      description: "外注費" + MESSAGE.MESSAGE_SUBMIT_CM0019,

      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_MODAL_2,
    });
  };

  const funcExportData = async () => {
    dispatch(setLoading(true));

    try {
      const response = await service.doExportCM0022({
        from: from ? helpers.getFilterTimeMonth(from) : "",
        to: to ? helpers.getFilterTimeMonthTo(to) : "",
        page: 1,
        size: COMMON.DEFAULT_SIZE,
        sortBy: "createdAt",
        sortType: "DESC"
      } as any);
      doExportForResponse(response, FILE_NAME + ".xlsx");
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleScrollTable = (e: any) => {
    const target = e.target;
    if (
      !loading &&
      target.scrollTop + target.offsetHeight >= target.scrollHeight &&
      pagination.page < pagination.numPages
    ) {
      getDataTable(
        {
          from: clear ? "" : helpers.getFilterTimeMonth(from),
          to: clear ? "" : helpers.getFilterTimeMonthTo(to),
          page: pagination.page + 1,
          size: COMMON.DEFAULT_SIZE,
        },
        true
      );
    }
  };
  const checkHavingData = (value: boolean) => {
    dispatch(setIsHavingData(value));
  };
  return {
    dataView,
    expandKey,
    menu,
    from,
    to,
    clear,
    refreshStatus,
    filterCurrent,
    summary,
    funcSetExpendKey,
    funcCheckKeyExpand,
    getDataTable,
    onCancelSubmitModal,
    onSubmit,
    getMoneySummaryMaterialCostProjectStatus,
    funcExportData,
    handleScrollTable,
    getMaterialCostProjectSummary,
    checkHavingData,
  };
};

export default CM0022Handler;
