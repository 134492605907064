import CCUS05Api from "./infrastructure/api/Api";
import CCUS05Handler from "./presenter/handler/Handler";
import MainUI from "./presenter/ui/UI";
import CCUS05Service from "./usecase/Service";
import "../../../assets/styles/CCUS/ccus005.css";

const CCUS05Component = () => {
  const api = CCUS05Api();
  const service = CCUS05Service(api);
  const handler = CCUS05Handler(service);

  return <MainUI handler={handler}></MainUI>;
};

export default CCUS05Component;
