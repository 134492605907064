import axios from "axios";
import queryString from "query-string";
import COMMON from "../common/constants/COMMON";
// https://lightrains.com/blogs/axios-intercepetors-react/

// control api call
const controller = new AbortController();
const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const AuthService = {
  getUserInfo: () => {
    return JSON.parse(localStorage.getItem("user") || "{}");
  },
  getAccessToken: () => {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    return user?.token;
  },
  getRefreshToken: () => {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    return user?.refreshToken;
  },
};

const axiosConfig = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: COMMON.AXIOS_TIMEOUT_REQUEST,
  cancelToken: source.token,
  signal: controller.signal,
  paramsSerializer: (params) => queryString.stringify(params),
});

axiosConfig.defaults.headers.common["Time-Offset"] = `${COMMON.TIME_ZONE}`;

axiosConfig.interceptors.request.use(
  (config) => {
    const token = AuthService.getAccessToken();
    if (token && config.headers) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    if (config.headers) {
      config.headers["Content-Type"] = "application/json";
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosConfig.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (!window.navigator.onLine) {
      window.location.reload();
      return;
    }
    if (error?.response?.status === 401) {
      try {
        controller.abort(); // stop other when
        const token = AuthService.getAccessToken();
        const axiosConfigAuthorization = axios.create({
          baseURL: process.env.REACT_APP_BASE_URL,
          timeout: COMMON.AXIOS_TIMEOUT_REQUEST,
          paramsSerializer: (params) => queryString.stringify(params),
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json"
          }
        });

        axiosConfigAuthorization.defaults.headers.common[
          "Time-Offset"
        ] = `${COMMON.TIME_ZONE}`;

        const url = "/auth/refresh";
        const response = await axiosConfigAuthorization.post(url, {
          refreshToken: AuthService.getRefreshToken(),
        });
        if (!response?.data?.results) throw new Error();

        const newUser = {
          ...JSON.parse(localStorage.getItem("user") || "{}"),
          ...response?.data?.results
        }

        localStorage.setItem("user", JSON.stringify(newUser));
        window.location.reload();

      } catch (error) {
        window.localStorage.clear();
        window.location.replace(window.location.origin + "/app-login");
      }
    } else {
      throw error?.response?.data ? error.response.data : error.response;
    }
  }
);

export default axiosConfig;
