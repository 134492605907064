import { useDispatch } from "react-redux";
import { AttendanceServiceImpl } from "./../../usecase/ServiceImpl";
import { useState } from "react";
import { openModalStreetMap } from "../../../../../common/components/open-street-map/Slice";

const AttendanceHandler = (service: AttendanceServiceImpl) => {
  const dispatch = useDispatch();

  const [attendance, setAttendance] = useState<any>();
  const [expandKeyAttendance, setExpandKeyAttendance] = useState<any[]>([]);
  const [dataSource, setDataSource] = useState<any[]>([]);

  const isCollapsedAttendance = (record: any) => {
    return expandKeyAttendance.some((element) => element === record.key);
  };

  const onChangeCollapseRowAttendance = (record: any, collapsed: boolean) => {
    const collapsedRow = collapsed
      ? [...expandKeyAttendance, record.key]
      : expandKeyAttendance.filter((element) => element !== record.key);

    setExpandKeyAttendance(collapsedRow);
  };

  const getAttendanceSubConstructionMemberTimeSheet = async (params: {
    constructionId: number;
    userId: number;
    contractorConstructionId: number;
    from: string; // 2006-01-02
    to: string; // 2006-01-02
  }): Promise<any> => {
    const { results } =
      await service.getAttendanceSubConstructionMemberTimeSheet(params);
    setAttendance(results);
  };

  const openStreetMap = (lat: any, lng: any) => {
    dispatch(
      openModalStreetMap({
        lat: lat,
        lng: lng,
      })
    );
  };
  return {
    attendance,
    expandKeyAttendance,
    dataSource,
    setDataSource,
    setAttendance,
    getAttendanceSubConstructionMemberTimeSheet,
    isCollapsedAttendance,
    onChangeCollapseRowAttendance,
    openStreetMap,
  };
};

export default AttendanceHandler;
