import axiosConfig from '../../../../../config/axiosConfig';
import { Params } from '../../presenter/type/Presenter';

const CM0027_2Api = () => {
    const getReSummarySubmittedProjectCompanyOffsetCost = async (params: Params): Promise<any> => {
        try {
            const url = '/re-summary/submitted/project/company/offset-cost';
            const response = await axiosConfig.get(url, { params });
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    return {
        getReSummarySubmittedProjectCompanyOffsetCost,
    };
};

export default CM0027_2Api;
