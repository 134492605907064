import CCUS02Api from "./infrastructure/api/Api";
import CCUS02Handler from "./presenter/handler/Handler";
import MainUI from "./presenter/ui/UI";
import CCUS02Service from "./usecase/Service";
import "../../../assets/styles/CCUS/ccus002.css";

const CCUS02Component = () => {
  const api = CCUS02Api();
  const service = CCUS02Service(api);
  const handler = CCUS02Handler(service);

  return <MainUI handler={handler}></MainUI>;
};

export default CCUS02Component;
