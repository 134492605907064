import { AxiosResponse } from 'axios';
import axiosConfig from '../../../../../config/axiosConfig';

const AM006Api = () => {
    const getAttendanceConstructionListMyMember = async (params: {
        constructionId: number;
        from: string; // 2006-01-02
        to: string; // 2006-01-02
        timeOffset: number;
    }): Promise<AxiosResponse<any, any>> => {
        const url = '/attendance/construction/list-my-member';
        return await axiosConfig.get(url, { params });
    };
    const getAttendanceUserTimeSheet = async (params: {
        date: string;
        constructionId?: string;
        contractorConstructionId?: string;
        userId: string;
        timeOffset: number;
    }): Promise<AxiosResponse<any, any>> => {
        const url = '/attendance/user-time-sheet';
        return await axiosConfig.get(url, { params });
    };
    const doExportAM006 = async (params: any): Promise<any> => {
      try {
          const url = '/attendance/exportAM006';
          const response = await axiosConfig.get(url, { params, responseType: 'blob'  });
          
          return response;
      } catch (error) {
          throw error;
      }
  };
    return { getAttendanceConstructionListMyMember, getAttendanceUserTimeSheet,doExportAM006 };
};

export default AM006Api;
