import { ColumnsType } from 'antd/es/table';
import { DataType } from '../../entity/Entity';

// Media
import EditIcon from '../../../../../assets/images/ord_001_edit_icon.svg';
import DeleteIcon from '../../../../../assets/images/ord_001_delete_icon.svg';
import DetailIcon from '../../../../../assets/images/ord_001_detail_icon.svg';
import { ORD001Handler } from '../handler/HandlerImpl';
import SortTable from '../../../../../common/components/sort-table/SortTable';
import TooltipText from '../../../../../common/components/tooltip-text/TooltipText';
import { TEXT_INVALID_JP, TEXT_VALID_JP } from '../../contants';
import { CREATED_AT } from '../../contants';
import { GREEN_COLOR, RED_COLOR } from '../../../ORD006/presenter/ui/UI';
import moment from 'moment';
import COMMON, { ORDER_STATUS } from '../../../../../common/constants/COMMON';
import { Button } from 'antd';
import helpers from '../../../../../common/helpers/common';

type props = {
    handler: ORD001Handler;
};

const STATUS_USER_ACTIVE = 'active';

const ColumnTable = ({ handler }: props) => {
    const columns: ColumnsType<DataType> = [
        {
            title: 'No',
            dataIndex: 'No',
            width: 70,
        },
        {
            title: '会社名',
            dataIndex: 'companyName',
            width: 160,
            render: (text) => {
                return (
                    <div style={{ color: '#222222', width: 160, margin: '0 auto' }}>
                        <TooltipText content={text} />
                    </div>
                );
            },
        },
        {
            title: 'ID',
            dataIndex: 'ID',
            width: 112,
            render: (text) => {
                return (
                    <div style={{ color: '#222222', width: 112, margin: '0 auto' }}>
                        <TooltipText content={text} />
                    </div>
                );
            },
        },
        {
            title: 'アドミン名',
            dataIndex: 'adminName',
            width: 112,
            render: (text) => {
                return (
                    <div style={{ color: '#222222', width: 112, margin: '0 auto' }}>
                        <TooltipText content={text} />
                    </div>
                );
            },
        },
        {
            title: 'メールアドレス',
            dataIndex: 'email',
            width: 140,
            render: (text) => {
                return (
                    <div style={{ color: '#222222', width: 140, margin: '0 auto' }}>
                        <TooltipText content={text} />
                    </div>
                );
            },
        },
        {
            title: '郵便番号',
            dataIndex: 'postCode',
            width: 120,
        },
        {
            title: '住所',
            dataIndex: 'address',
            width: 320,
        },
        {
            title: '電話番号',
            dataIndex: 'telephoneNumber',
            width: 120,
        },
        {
            title: 'FAX',
            dataIndex: 'fax',
            width: 120,
        },
        {
            title: 'プラン区分',
            dataIndex: 'planDivision',
            width: 112,
        },
        {
            title: 'オプション機能',
            dataIndex: 'optionalFeature',
            width: 120,
            render: (text) => {
                return <div>{text ? TEXT_VALID_JP : TEXT_INVALID_JP}</div>;
            },
        },
        {
            title: '開始日',
            dataIndex: 'startDate',
            width: 132,
            render: (record: string) => {
                if (record) return record;
                return '-';
            },
        },
        {
            title: '有効期限',
            dataIndex: 'endDate',
            width: 132,
            render: (record: string) => {
                if (record) return record;
                return '-';
            },
        },
        {
            title: 'ステータス',
            dataIndex: 'status',
            width: 132,
            render: (_, record) => {
                return (
                    <div
                        style={{
                            color:
                                record.statusCode === ORDER_STATUS.ACTIVE_ORDER
                                    ? GREEN_COLOR
                                    : RED_COLOR,
                        }}
                    >
                        {record.status}
                    </div>
                );
            },
        },
        {
            title: '導入',
            dataIndex: 'introduction',
            width: 112,
            render: (_, record) => {
                return (
                    <div
                        style={{
                            color:
                                record.introductionCode === STATUS_USER_ACTIVE
                                    ? GREEN_COLOR
                                    : RED_COLOR,
                        }}
                    >
                        {record.introduction}
                    </div>
                );
            },
        },

        {
            title: (
                <SortTable
                    columnTitle="作成日"
                    sortOrder={handler.funcCheckSortOrder(CREATED_AT)}
                    onChange={(sortOrder) => handler.funcSortTable(CREATED_AT, sortOrder)}
                />
            ),
            dataIndex: 'createdAt',
            width: 120,
            render: (record: string) => {
                if (record) return record;
                return '-';
            },
        },
        {
            title: '操作',
            width: 120,
            render: (_, record) => {
                return (
                    <div className="ord--001__table--operator">
                        {!handler.checkDisableEmail(record.email) ? (
                            <>
                                <Button
                                    onClick={() => handler.updateOrder(record.key)}
                                    type="link"
                                    style={{ border: 'none' }}
                                    icon={<img src={EditIcon} alt="edit icon" />}
                                />

                                <Button
                                    onClick={() => handler.deleteOne(record.key)}
                                    type="link"
                                    style={{ border: 'none' }}
                                    icon={<img src={DeleteIcon} alt="delete icon" />}
                                    disabled={
                                        moment(record.endDate, COMMON.FORMAT_DATE) >= moment()
                                    }
                                />
                            </>
                        ) : null}

                        <Button
                            onClick={() => handler.redirectORD006(record.key)}
                            type="link"
                            style={{ border: 'none' }}
                            icon={<img src={DetailIcon} alt="detail icon" />}
                            disabled={moment(record.endDate) >= moment()}
                        />
                    </div>
                );
            },
        },
    ];

    const rowSelection = {
        selectedRowKeys: handler.selectedRowsKey,
        onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
            handler.getSelectedKey(selectedRowKeys);
        },
        getCheckboxProps: (record: any) => ({
            disabled:
                moment(record.endDate, COMMON.FORMAT_DATE) >= moment() ||
                handler.checkDisableEmail(record.email),
        }),
    };

    return {
        columns,
        rowSelection,
    };
};

export default ColumnTable;
