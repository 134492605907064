import { useState } from "react";
import {
  AM004ServiceImpl,
  AttendanceServiceImpl,
} from "./../../usecase/ServiceImpl";
import { useSearchParams } from "react-router-dom";
import { Form } from "antd";
import moment from "moment";
import COMMON from "../../../../../common/constants/COMMON";
import { useDispatch } from "react-redux";
import { setTableColumns } from "../slice/Slice";
import { calculateCalendarTableHeader } from "../../../AM008/helper";
import { RangeDateType } from "../../../AM002/entity/Entity";
import helpers from "../../../../../common/helpers/common";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../../common/constants/MESSAGE";
import { doExportForResponse } from "../../../../../common/helpers/exports/common";
const AM004Handler = (
  service: AM004ServiceImpl,
  attendanceService: AttendanceServiceImpl
) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const sortBy = searchParams.get("sortby") || "";
  const sortType = searchParams.get("sorttype") || "";
  const pageDate = searchParams.get("pageDate") || COMMON.DEFAULT_PAGE + "";
  const fromDate =
    searchParams.get("startDate") ||
    moment().startOf("month").format(COMMON.FORMAT_DATE2);
  const toDate =
    searchParams.get("endDate") || moment().format(COMMON.FORMAT_DATE2);

  const [totalMoney, setTotalMoney] = useState<number>(0);
  const [totalPeople, setTotalPeople] = useState<number>(0);
  const [totalWorkingDay, setTotalWorkingDay] = useState<number>(0);
  const [listConstractor, setListConstractor] = useState<any[]>([]);
  const [rangeDate, setRangeDate] = useState<RangeDateType>({
    from: moment().startOf("month"),
    to: moment(),
  });
  const [expandKey, setExpandKey] = useState<any>([]);
  const [expandKeyMember, setExpandKeyMember] = useState<any[]>([]);

  const getAttendanceConstructionTotalCost = async (params: {
    constructionId: number;
    from: string;
    to: string;
    keyword: string;
  }): Promise<any> => {
    try {
      dispatch(setLoading(true));
      const responseResults = await service.getAttendanceConstructionTotalCost(
        params
      );

      setTotalMoney(responseResults.totalLaborCost);
      setTotalWorkingDay(responseResults.totalWorkingDay);
    } catch (error: any) {
      ErrorNotification(error.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const isCollapsedMember = (record: any) => {
    return expandKeyMember.some((element) => element === record.key);
  };

  const onChangeCollapseRowMember = (record: any, collapsed: boolean) => {
    const collapsedRow = collapsed
      ? [...expandKeyMember, record.key]
      : expandKeyMember.filter((element) => element !== record.key);

    setExpandKeyMember(collapsedRow);
  };

  const isCollapsed = (record: any) => {
    return expandKey.some((element: any) => element === record.key);
  };

  const onChangeCollapseRow = (record: any, collapsed: boolean) => {
    const collapsedRow = collapsed
      ? [...expandKey, record.key]
      : expandKey.filter((element: any) => element !== record.key);

    setExpandKey(collapsedRow);
  };

  const onFromDateChange = (value: any) => {
    setRangeDate({ ...rangeDate, from: value });
  };
  const onToDateChange = (value: any) => {
    setRangeDate({ ...rangeDate, to: value });
  };
  // recursive
  const recursion = async (
    param: any,
    array: any,
    results: any[],
    treeLevel: number
  ) => {
    if (array.length === 0)
      // object[`children`] = [];
      return;
    // STOP CONDITION
    for (let i = 0; i < array.length; i++) {
      results.push({
        ...array[i],
        treeLevel: treeLevel,
      });
      // dispatch(addTotalMember(array[i].totalMember));
      const contractorData =
        await service.getAttendanceSubConstructionListMember({
          ...param,
          contractorConstructionId: array[i].id,
        });

      if (contractorData.results)
        await recursion(param, contractorData.results, results, treeLevel + 1);
    }
  };

  const getAttendanceSubConstructionListMember = async (params: {
    constructionId: number;
    contractorConstructionId: number;
    from: string; // 2006-01-02
    to: string; // 2006-01-02
  }): Promise<any> => {
    try {
      dispatch(setLoading(true));
      let totalPeopleResult = 0;
      let totalMoneyResult = 0;
      let sumTotalWorkingDay = 0;
      const contractorData0 =
        await service.getAttendanceSubConstructionListMember({
          constructionId: params.constructionId,
          from: params.from,
          to: params.to,
          contractorConstructionId: params.constructionId,
        });

      const foundChosenContractor = contractorData0?.results?.find(
        (element: any) => element.id === params.contractorConstructionId
      );

      const contractorData1 =
        await service.getAttendanceSubConstructionListMember({
          constructionId: params.constructionId,
          from: params.from,
          to: params.to,
          contractorConstructionId: params.contractorConstructionId,
        });
      const flatArray: any[] = [];
      if (foundChosenContractor)
        flatArray.push({ ...foundChosenContractor, treeLevel: 1 });
      let treeLevel = 2;
      for (let i = 0; i < contractorData1.results.length; i++) {
        flatArray.push({
          ...contractorData1.results[i],
          treeLevel: treeLevel,
          parentSubConstructionId: contractorData1.results[i].id,
        });
        const contractorData2 =
          await service.getAttendanceSubConstructionListMember({
            constructionId: params.constructionId,
            contractorConstructionId: contractorData1.results[i].id,
            from: params.from,
            to: params.to,
          });
        const param = {
          constructionId: params.constructionId,
          from: params.from, // 2006-01-02
          to: params.to, // 2006-01-02
        };
        await recursion(
          param,
          contractorData2.results,
          flatArray,
          treeLevel + 1
        );
      }
      if (flatArray && Array.isArray(flatArray) && flatArray.length > 0) {
        flatArray.forEach((element: any) => {
          totalMoneyResult += element.totalLaborCost;
          totalPeopleResult += element.numberOfMember;
          sumTotalWorkingDay += element.totalWorkingDay;
        });

        for (let i = 0; i < flatArray.length; i++) {
          for (let j = 0; j < flatArray[i].contractorMembers.length; j++) {
            const attendanceParams = {
              contractorConstructionId: flatArray[i].id,
              constructionId: params.constructionId,
              userId: flatArray[i].contractorMembers[j].id,
              from: params.from,
              to: params.to,
            };
            const attendanceResult =
              await getAttendanceSubConstructionMemberTimeSheet(
                attendanceParams
              );
            if (attendanceResult && Array.isArray(attendanceResult.timesheet)) {
              for (let k = 0; k < attendanceResult.timesheet.length; k++) {
                if (attendanceResult.timesheet[k].isMultipleCheckInOut) {
                  const multipleCheckInOutData =
                    await service.getAttendanceUserTimeSheet({
                      date: moment(attendanceResult.timesheet[k].date).format(
                        COMMON.FORMAT_DATE2
                      ),
                      contractorConstructionId: flatArray[i].id + "",
                      constructionId: params.constructionId + "",
                      userId: flatArray[i].contractorMembers[j].id,
                    });
                  if (multipleCheckInOutData)
                    attendanceResult.timesheet[k].multipleCheckInOutData =
                      multipleCheckInOutData.map((element) => ({
                        ...element,
                      }));
                }
              }

              flatArray[i].contractorMembers[j].attendance = attendanceResult;
            }
          }
        }
        const memberKeys =
          flatArray[0]?.contractorMembers?.map((element: any) => element.id) ??
          [];
        // setExpandKey([flatArray[0]?.id]);
        // if (memberKeys && Array.isArray(memberKeys) && memberKeys.length > 0)
        //   setExpandKeyMember([memberKeys[0]]);
        setListConstractor(flatArray);
      } else {
        setExpandKey([]);
        setExpandKeyMember([]);
        setListConstractor([]);
      }
      setTotalMoney(totalMoneyResult);
      setTotalPeople(totalPeopleResult);
      setTotalWorkingDay(sumTotalWorkingDay);
    } catch (error: any) {
      ErrorNotification(error.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getAttendanceSubConstructionMemberTimeSheet = async (params: {
    constructionId: number;
    userId: number;
    contractorConstructionId: number;
    from: string; // 2006-01-02
    to: string; // 2006-01-02
  }): Promise<any> => {
    const { results } =
      await attendanceService.getAttendanceSubConstructionMemberTimeSheet(
        params
      );
    return results;
  };

  const handleLoadingExport = (value: boolean) => {
    dispatch(setLoading(value));
  };

  const exportData = async (params: any) => {
    const user = localStorage.getItem("user");
    let companyName: string = "";
    if (user) {
      companyName = JSON.parse(user)?.company?.name;
    }

    let fileName: string = `工事詳細勤務実績_${moment().format(
      COMMON.FORMAT_DATE7
    )}.xlsx`;
    // const titleCompanyColab: string = searchParams.get("title") ?? "";
    // let date_header: string[] = tableColumns?.columns.map(
    //   (item: any) => item?.name
    // );

    if (!window.location.pathname.includes("am007")) {
      companyName = searchParams.get("selectedCompanyName") ?? "";
      const title = searchParams.get("title") ?? "";
      const titleArr = title.split("/");
      fileName = `工事詳細勤務実績_${companyName}_${titleArr[0]?.trim()}_${titleArr[1]?.trim()}_${moment().format(
        COMMON.FORMAT_DATE7
      )}`;
      try {
        dispatch(setLoading(true));
        const response = await service.doExportAM004({
          ...params,
          from: helpers.getFilterTime(params.from),
          to: helpers.getFilterTimeTo(params.to),
          companyName,
        } as any);
        doExportForResponse(response, `${fileName}.xlsx`);
      } catch (error: any) {
        ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      } finally {
        dispatch(setLoading(false));
      }
    } else {
      const construction = listConstractor.find(
        (e) => e.id === Number(params.contractorConstructionId)
      );
      const title = searchParams.get("title") ?? "";
      const titleArr = title.split("/");
      fileName = `会社詳細勤務実績_${construction.companyName
        }_${titleArr[0]?.trim()}_${titleArr[1]?.trim()}_${moment().format(
          COMMON.FORMAT_DATE7
        )}`;
      try {
        dispatch(setLoading(true));
        const response = await service.doExportAM007({
          ...params,
          from: helpers.getFilterTime(params.from),
          to: helpers.getFilterTimeTo(params.to),
          companyName,
        } as any);
        doExportForResponse(response, `${fileName}.xlsx`);
      } catch (error: any) {
        ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      } finally {
        dispatch(setLoading(false));
      }
    }

    // const dataExport: ExportDataConstruction = {
    //   title: [
    //     `${companyName}`,
    //     titleCompanyColab,
    //     `期間: ${getTitleByFromToDate(fromDate, toDate)}`,
    //     `合計金額：${helpers.formatCurrency(
    //       totalMoney
    //     )}円　　人数：${totalPeople}人`,
    //   ],
    //   date_header: date_header,
    //   content: convertDataForExport(listConstractor),
    // };

    // exportConstruction(dataExport, fileName, handleLoadingExport);
  };
  const onPageChange = (value: number) => { };
  const onSizeChange = (value: number) => { };
  const onTableSort = (value: string) => { };
  const setDefaultSorter = (sortText: string) => {
    if (sortType === "") return null;
    if (sortText === sortBy) return sortType;
  };

  const initiateFilter = () => {
    form.setFieldValue("text", searchParams.get("keyword") || "");
    let fromMoment: moment.Moment = moment().startOf("month");
    if (fromDate === null)
      form.setFieldValue("startDate", moment().startOf("month"));
    else {
      form.setFieldValue("startDate", moment(fromDate));
      fromMoment = moment(fromDate);
    }

    form.setFieldValue("endDate", moment(toDate));
    setRangeDate({
      to: moment(toDate),
      from: fromMoment,
    });

    form.setFieldValue("endDate", moment(toDate));
    setRangeDate({
      to: moment(toDate),
      from: fromMoment,
    });
  };

  var myVar: any;
  const onSearch = () => {
    if (myVar) clearTimeout(myVar);
    myVar = setTimeout(function () {
      const searchText = form.getFieldValue("text");
      form.setFieldValue("text", searchText.trim());
      searchParams.set("keyword", searchText.trim());
      searchParams.set("page", COMMON.DEFAULT_PAGE + "");
      setSearchParams(searchParams);
    }, 1000);
  };
  const onFilter = () => {
    searchParams.set("pageDate", COMMON.DEFAULT_PAGE + "");
    const startDate = form.getFieldValue("startDate");
    if (startDate)
      searchParams.set(
        "startDate",
        moment(startDate).format(COMMON.FORMAT_DATE2)
      );
    else searchParams.set("startDate", "");

    const endDate = form.getFieldValue("endDate");
    searchParams.set("endDate", moment(endDate).format(COMMON.FORMAT_DATE2));
    setSearchParams(searchParams);
  };

  const calculateTableHeader = (
    startDate: string | null,
    endDate: string | null
  ) => {
    const result = calculateCalendarTableHeader(startDate, endDate, pageDate);
    dispatch(setTableColumns(result));
    return result;
  };

  const onResetFilter = () => {
    const initialStart = moment().startOf("month");
    const initialEnd = moment();

    form.setFields([
      {
        name: "startDate",
        value: initialStart,
      },
      {
        name: "endDate",
        value: initialEnd,
      },
    ]);
    searchParams.set("pageDate", COMMON.DEFAULT_PAGE + "");
    searchParams.set("startDate", initialStart.format(COMMON.FORMAT_DATE2));
    searchParams.set("endDate", initialEnd.format(COMMON.FORMAT_DATE2));
    setSearchParams(searchParams);
  };

  const onCollapse = (value: boolean) => {
    searchParams.set("isCollapsed", value ? "isCollapsed" : "");
    setSearchParams(searchParams);
  };
  const onNext = () => {
    const pageInt = parseInt(pageDate);
    searchParams.set("pageDate", pageInt + 1 + "");
    setSearchParams(searchParams);
  };

  const onPrev = () => {
    const pageInt = parseInt(pageDate);
    searchParams.set("pageDate", (pageInt - 1 <= 0 ? 1 : pageInt - 1) + "");
    setSearchParams(searchParams);
  };

  return {
    form,
    listConstractor,
    rangeDate,
    totalPeople,
    totalMoney,
    expandKey,
    expandKeyMember,
    totalWorkingDay,
    onResetFilter,
    onFilter,
    onSearch,
    getAttendanceSubConstructionListMember,
    exportData,
    onSizeChange,
    onPageChange,
    onTableSort,
    setDefaultSorter,

    onCollapse,
    initiateFilter,
    onNext,
    onPrev,
    calculateTableHeader,
    onFromDateChange,
    onToDateChange,
    setExpandKey,
    setExpandKeyMember,
    isCollapsedMember,
    onChangeCollapseRowMember,
    isCollapsed,
    onChangeCollapseRow,
    getAttendanceConstructionTotalCost,
  };
};

export default AM004Handler;
