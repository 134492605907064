import React from 'react';
import CM004Api from './infrastructure/api/Api';
import CM004Handler from './presenter/handler/Handler';
import CM004Service from './usecase/Service';
import MainUI from './presenter/ui/UI';

import '../../../assets/styles/AM/AM028.css';
import '../../../assets/styles/AM/AM002.css';
import '../../../assets/styles/OM/OM002.css';
import '../../../assets/styles/CM/cm3854.css';
import '../../../assets/styles/CM/CM007.css';

const CM004Component = () => {
    const api = CM004Api();
    const service = CM004Service(api);
    const handler = CM004Handler(service);
    return <MainUI handler={handler} />;
};

export default CM004Component;
