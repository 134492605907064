import axiosConfig from '../../../../../config/axiosConfig';
import {
    ParamsGroupMemberList,
    ParamsPermissionList,
    ParamsRoleDetail,
    ParamsRoleUserList,
    PermissionUpdatePayload,
    RoleCreatePayload,
    RoleUpdatePayload,
    RoleUserUpdatePayload,
} from '../../entity/Entity';

const AOP003Api = () => {
    const getGroupListMember = async (params: ParamsGroupMemberList) => {
        const url = '/role/company/user';
        return axiosConfig.get(url, { params });
    };

    const getRoleDetail = async (params: ParamsRoleDetail) => {
        const url = '/role/detail';
        return axiosConfig.get(url, { params });
    };

    const getRolePermissionCategory = async () => {
        const url = '/role/permision/category';
        return axiosConfig.get(url);
    };

    const getRoleUserList = async (params: ParamsRoleUserList) => {
        const url = '/role/user/list';
        return axiosConfig.get(url, { params });
    };

    const getPermissionList = async (params: ParamsPermissionList) => {
        const url = '/role/permission/list';
        return axiosConfig.get(url, { params });
    };

    const postRoleCreate = async (payload: RoleCreatePayload) => {
        const url = '/role/create';
        return axiosConfig.post(url, payload);
    };

    const putRoleUpdate = async (payload: RoleUpdatePayload) => {
        const url = '/role/update';
        return axiosConfig.put(url, payload);
    };
    const putRoleUserUpdate = async (payload: RoleUserUpdatePayload) => {
        const url = '/role/user/update';
        return axiosConfig.put(url, payload);
    };

    const postPermission = async (payload: PermissionUpdatePayload) => {
        const url = '/role/setting-permission';
        return axiosConfig.post(url, payload);
    };

    return {
        getGroupListMember,
        getRoleDetail,
        getRoleUserList,
        postRoleCreate,
        putRoleUpdate,
        postPermission,
        putRoleUserUpdate,
        getRolePermissionCategory,
        getPermissionList,
    };
};

export default AOP003Api;
