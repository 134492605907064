import { createSlice } from '@reduxjs/toolkit';

type H3ModalSlice = {
    isOpenH3: boolean;
};
const initialState: H3ModalSlice = {
    isOpenH3: false,
};

export const H3ModalSlice = createSlice({
    name: 'H3ModalSlice',
    initialState,
    reducers: {
        showH3Modal: (state) => {
            state.isOpenH3 = true;
        },
        hideH3Modal: (state) => {
            state.isOpenH3 = false;
        },
    },
});

export const { showH3Modal, hideH3Modal } = H3ModalSlice.actions;
export default H3ModalSlice.reducer;
