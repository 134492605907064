import { H1EntityData } from './H1Entity';

const ROWS: H1EntityData[] = [
    {
        id: 1,
        name: '案件情報',
        children: [
            {
                id: 11,
                name: '案件情報　閲覧',
                allowProjectManager: true,
                allowDeputyProjectManager: true,
                allowItemCreator: true,
                allowProjectMember: true,
                isAdmin: true,
            },
            {
                id: 12,
                name: '案件情報　編集',
                allowProjectManager: true,
                allowDeputyProjectManager: false,
                allowItemCreator: true,
                allowProjectMember: false,
                isAdmin: true,
            },
            {
                id: 13,
                name: 'ステータス　変更',
                allowProjectManager: true,
                allowDeputyProjectManager: false,
                allowItemCreator: true,
                allowProjectMember: false,
                isAdmin: true,
            },
        ],
    },
    {
        id: 2,
        name: '工事情報',
        children: [
            {
                id: 21,
                name: '工事一覧　閲覧',
                allowProjectManager: true,
                allowDeputyProjectManager: true,
                allowItemCreator: true,
                allowProjectMember: true,
                isAdmin: true,
            },
            {
                id: 22,
                name: '工事登録',
                allowProjectManager: true,
                allowDeputyProjectManager: false,
                allowItemCreator: true,
                allowProjectMember: false,
                isAdmin: true,
            },
            {
                id: 23,
                name: '工事削除',
                allowProjectManager: true,
                allowDeputyProjectManager: false,
                allowItemCreator: true,
                allowProjectMember: false,
                isAdmin: true,
            },

            {
                id: 24,
                name: '工事メンバーリスト　閲覧',
                allowProjectManager: true,
                allowDeputyProjectManager: true,
                allowItemCreator: true,
                allowProjectMember: true,
                isAdmin: true,
            },
            {
                id: 25,
                name: '工事メンバー　追加',
                allowProjectManager: true,
                allowDeputyProjectManager: true,
                allowItemCreator: true,
                allowProjectMember: false,
                isAdmin: true,
            },
            {
                id: 26,
                name: '工事メンバー　削除',
                allowProjectManager: true,
                allowDeputyProjectManager: true,
                allowItemCreator: true,
                allowProjectMember: false,
                isAdmin: true,
            },

            {
                id: 27,
                name: '工事メンバーリスト　共有',
                allowProjectManager: true,
                allowDeputyProjectManager: true,
                allowItemCreator: false,
                allowProjectMember: false,
                isAdmin: true,
            },
            {
                id: 28,
                name: '勤怠確認権限　設定',
                allowProjectManager: true,
                allowDeputyProjectManager: false,
                allowItemCreator: true,
                allowProjectMember: false,
                isAdmin: true,
            },
        ],
    },
    {
        id: 3,
        name: '工事引合書',
        children: [
            {
                id: 31,
                name: '工事引合書　閲覧',
                allowProjectManager: true,
                allowDeputyProjectManager: false,
                allowItemCreator: false,
                allowProjectMember: false,
                isAdmin: true,
            },
            {
                id: 32,
                name: '工事引合書　作成・編集',
                allowProjectManager: true,
                allowDeputyProjectManager: false,
                allowItemCreator: false,
                allowProjectMember: false,
                isAdmin: true,
            },
            {
                id: 33,
                name: '工事引合書　提出',
                allowProjectManager: true,
                allowDeputyProjectManager: false,
                allowItemCreator: false,
                allowProjectMember: false,
                isAdmin: true,
            },
        ],
    },
    {
        id: 4,
        name: '実行予算書',
        children: [
            {
                id: 41,
                name: '実行予算書　閲覧',
                allowProjectManager: true,
                allowDeputyProjectManager: false,
                allowItemCreator: false,
                allowProjectMember: false,
                isAdmin: true,
            },
            {
                id: 42,
                name: '実行予算書　作成・編集',
                allowProjectManager: true,
                allowDeputyProjectManager: false,
                allowItemCreator: false,
                allowProjectMember: false,
                isAdmin: true,
            },
            {
                id: 43,
                name: '実行予算書　提出',
                allowProjectManager: true,
                allowDeputyProjectManager: false,
                allowItemCreator: false,
                allowProjectMember: false,
                isAdmin: true,
            },
            {
                id: 44,
                name: '協力会社　招待',
                allowProjectManager: true,
                allowDeputyProjectManager: false,
                allowItemCreator: false,
                allowProjectMember: false,
                isAdmin: true,
            },
        ],
    },
];
const convertToDisplayTable = (data: H1EntityData[]) => {
    let results: any[] = [];
    data.forEach((element, index) => {
        const flatArray = [
            {
                id: element.id,
                name: `${index + 1}. ` + element.name,
                isHead: true,
            },
            ...element.children,
        ];
        results = results.concat(flatArray);
    });
    return results;
};

export { ROWS, convertToDisplayTable };
