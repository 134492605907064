import ErrorNotification from "../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../common/constants/MESSAGE";
import { CM0019APIImpl } from "./ServiceImpl";

const CM0019Service = (api: CM0019APIImpl) => {
  const getProjectAlls = async (params: {
    keyword?: string;
    statusId?: string;
    page?: number;
    size?: number;
    sortBy?: string;
    sortType?: string;
  }): Promise<any> => {
    try {
      const response = await api.getProjectAlls({
        ...params,
      });
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return;
    }
  };

  const getReSummaryCostProjectList = async (params: any): Promise<any> => {
    try {
      const response = await api.getReSummaryCostProjectList({
        ...params,
      });
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return;
    }
  };

  const getReSummaryCostProjectConstructionList = async (
    params: any
  ): Promise<any> => {
    try {
      const response = await api.getReSummaryCostProjectConstructionList({
        ...params,
      });
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return;
    }
  };

  const getLineSummary = async (params: any): Promise<any> => {
    try {
      const response = await api.getLineSummary({
        ...params,
      });
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return;
    }
  };

  const getTaxSetting = async (params: any) => {
    try {
      const response = await api.getTaxSetting({ ...params });
      return response.data;
    } catch (error: any) {
      return;
    }
  };

   const doExportCM0019 = async (params: any): Promise<any> => {
        return await api.doExportCM0019(params);
    }

  return {
    getProjectAlls,
    getReSummaryCostProjectList,
    getReSummaryCostProjectConstructionList,
    getLineSummary,
    getTaxSetting,
    doExportCM0019,
  };
};

export default CM0019Service;
