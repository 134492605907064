// Built-in
import React, { useState } from "react";
import { PlannedPaidAmountServiceImpl } from "../../usecase/ServiceImpl";
import { Form } from "antd";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import COMMON from "../../../../../common/constants/COMMON";
import moment from "moment";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../../common/constants/MESSAGE";
import { setMoneySummaryPlanCostResponse } from "../slice/Slice";

// Source files

const PlannedPaidAmountHandler = (service: PlannedPaidAmountServiceImpl) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  // Params
  const [searchParams, setSearchParams] = useSearchParams();
  const filterDate =
    searchParams.get("filterDate") || moment().format(COMMON.FORMAT_DATE2);

  // Function
  const getMoneySummaryPlanCost = async (params: {
    constructionId: number;
    from: string;
    to: string;
  }): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const responseData = await service.getMoneySummaryPlanCost(params);
      if (responseData) dispatch(setMoneySummaryPlanCostResponse(responseData));
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const initiateFilter = () => {
    let filterDateMoment: moment.Moment = moment(filterDate);
    form.setFieldValue("filterDate", filterDateMoment);
  };

  const onFilterDateChange = () => {
    const filterDate = form.getFieldValue("filterDate");
    searchParams.set("filterDate", filterDate.format(COMMON.FORMAT_DATE4));
    setSearchParams(searchParams);
  };

  return {
    form,
    getMoneySummaryPlanCost,
    initiateFilter,
    onFilterDateChange,
  };
};

export default PlannedPaidAmountHandler;
