import { createSlice } from "@reduxjs/toolkit";
import { PM003Slice } from "../type/PresenterType";
const initialState: PM003Slice = {
  data: [],
  page: 1,
  size: 10,
  total: 16,
  keyword: "",
  filter: "",
  status: [],
  statusId: 0,
  isModalOpen: false
};

export const pm003Slice = createSlice({
  name: "pm003",
  initialState,
  reducers: {
    resetPM003: () => initialState,
    setData: (state, action) => {
      state.data = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload.results.status;
    },
    setPageChange: (state, action) => {
      state.total = action.payload.pagination.count;
      state.page = action.payload.pagination.page;
      state.size = action.payload.pagination.displayRecord;
    },
    setKeyWordChange: (state, action) => {
      state.keyword = action.payload;
    },
    setStatusFilterChange: (state, action) => {
      state.statusId = action.payload;
    },
    setPageChangeCard: (state, action) => {
      state.page = action.payload;
    },
  },
});

export const {
  setData,
  resetPM003,
  setPageChange,
  setKeyWordChange,
  setStatus,
  setStatusFilterChange,
  setPageChangeCard
} = pm003Slice.actions;

export default pm003Slice.reducer;
