import { createSlice } from '@reduxjs/toolkit';
import { SliceType } from '../../entity/Entity';

const initialState: SliceType = {
    groupRoleId: 0,
    groupRoleName: '',
};

export const aop004Slice = createSlice({
    name: 'aop004',
    initialState,
    reducers: {
        resetSM01: () => initialState,
    },
});

export const { resetSM01 } = aop004Slice.actions;

export default aop004Slice.reducer;
