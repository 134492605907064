import axiosConfig from "../../../../../config/axiosConfig";
import { OffsetCostPayload, ReceivedMoneyOffset } from "../../entity/Entity";

const API = () => {
  const postReSummaryCompanyOutsourceCostOffsetCreate = async (
    data: OffsetCostPayload
  ) => {
    const url = "/re-summary/company/outsource-cost/offset/create";
    return await axiosConfig.post(url, data);
  };
  const getMoneyReceivedMoneyOffsetSubCategory = async () => {
    const url = "/money/received-money-offset/sub-category";
    return await axiosConfig.get(url);
  };
  return {
    postReSummaryCompanyOutsourceCostOffsetCreate,
    getMoneyReceivedMoneyOffsetSubCategory,
  };
};

export default API;
