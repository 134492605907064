import { CM0016SliceType } from './../type/Presenter';
import { createSlice } from '@reduxjs/toolkit';

const initialState: CM0016SliceType = {
    loading: false,
    isVisible: false,
    refresh: false,
    documentIdCM016: 0,
    documentOriginIdCM016: 0,
    constructionId: 0,
    summitDateCm016: '',
    statusCM016: '',
};

export const cm0016Slice = createSlice({
    name: 'cm0016',
    initialState,
    reducers: {
        resetCM0016: () => initialState,
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setIsVisibleCM0016: (state, action) => {
            state.isVisible = action.payload;
        },
        setRefreshCM0016: (state) => {
            state.refresh = !state.refresh;
        },
        setDocumentId: (state, action) => {
            state.documentIdCM016 = action.payload;
        },
        setDocumentOriginId: (state, action) => {
            state.documentOriginIdCM016 = action.payload;
        },
        setSubmittedDateCM016: (state, action) => {
            state.summitDateCm016 = action.payload;
        },
        setConstructionId: (state, action) => {
            state.constructionId = action.payload;
        },
        setStatusCM016: (state, action) => {
            state.statusCM016 = action.payload;
        },
    },
});

export const {
    setLoading,
    setIsVisibleCM0016,
    resetCM0016,
    setRefreshCM0016,
    setDocumentId,
    setSubmittedDateCM016,
    setDocumentOriginId,
    setConstructionId,
    setStatusCM016,
} = cm0016Slice.actions;

export default cm0016Slice.reducer;
