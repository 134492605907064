import {
  CompanyOutSourceCost,
  OffsetCostUpdateData,
  ReceivedMoneyOffset,
} from "../entity/Entity";
import { APIImpl } from "./ServiceImpl";

const Service = (api: APIImpl) => {
  const getReSummaryCompanyOutSourceCostOffsetList = async (params: {
    companyId: number;
    colabRefId: number;

    categoryId: number;
    from: string;
    to: string;
  }): Promise<CompanyOutSourceCost[]> => {
    const dataSource = await api.getReSummaryCompanyOutSourceCostOffsetList(
      params
    );
    return dataSource?.data?.results ?? [];
  };
  const getMoneyReceivedMoneyOffsetSubCategory = async (): Promise<any> => {
    const dataSource = await api.getMoneyReceivedMoneyOffsetSubCategory();
    return dataSource.data;
  };

  const putReSummaryCompanyOutsourceCostOffsetUpdate = async (data: {
    companyId: number;
    colabRefId: number;

    data: OffsetCostUpdateData;
  }): Promise<any> => {
    const dataSource = await api.putReSummaryCompanyOutsourceCostOffsetUpdate(
      data
    );
    return dataSource.data;
  };
  const deleteReSummaryCompanyOutsourceCostOffsetDelete = async (data: {
    id: number[];
  }): Promise<any> => {
    const dataSource =
      await api.deleteReSummaryCompanyOutsourceCostOffsetDelete(data);
    return dataSource.data;
  };
  return {
    getReSummaryCompanyOutSourceCostOffsetList,
    getMoneyReceivedMoneyOffsetSubCategory,
    putReSummaryCompanyOutsourceCostOffsetUpdate,
    deleteReSummaryCompanyOutsourceCostOffsetDelete,
  };
};

export default Service;
