import React, { useEffect } from "react";
import { HandlerProjectImpl } from "../../../handler/HandlerImpl";
import ProjectItem from "./ProjectItem";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../store";
import SearchBarComponent from "./SearchBar";
import { Empty, Form } from "antd";
import PaginationComponent from "./Pagination";
import FilterComponent from "./Filter";

interface props {
  handler: HandlerProjectImpl;
}

const ProjectUI = ({ handler }: props) => {
  const { onSearch, form } = handler;

  const isCollapsed = useSelector(
    (state: RootState) => state.am005.isCollapsed
  );

  useEffect(() => {
    handler.initiateFilter();
  }, []);

  useEffect(() => {
    handler.getListProject({
      page: handler.page,
      size: handler.size,
      keyword: handler.keyword || undefined,
    });
  }, [handler.page, handler.size, handler.keyword]);

  return (
    <main className="company-container">
      <Form form={form}>
        <SearchBarComponent
          onChange={onSearch}
          isCollapsed={isCollapsed}
          onCollapse={handler.onCollapse}
        />
        <div
          className={`am005-project ${
            isCollapsed ? "am005-project-min-scroll am005-project-min" : ""
          }`}
        >
          {handler.projects?.length > 0 ? (
            handler.projects.map((element: any) => (
              <React.Fragment key={element.id}>
                <ProjectItem
                  isCollapsed={isCollapsed}
                  project={element}
                  isChosen={element.id === handler.projectId}
                  onClick={() => handler.onChooseProject(element)}
                />
              </React.Fragment>
            ))
          ) : (
            <Empty
              style={{ marginTop: 170 }}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="データなし"
            />
          )}
        </div>
        {isCollapsed || handler.projects?.length === 0 ? (
          <></>
        ) : (
          <PaginationComponent
            total={handler.total}
            page={handler.page}
            size={handler.size}
            onPageChange={handler.onPageChange}
          />
        )}
        <FilterComponent
          onFromDateChange={handler.onFromDateChange}
          onToDateChange={handler.onToDateChange}
          onFilter={handler.onFilter}
          onResetFilter={handler.onResetFilter}
          isCollapsed={isCollapsed}
          rangeDate={handler.rangeDate}
        />
      </Form>
    </main>
  );
};

export default ProjectUI;
