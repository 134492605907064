import ErrorNotification from '../../../../common/components/notification/ErrorNotification';
import COMMON from '../../../../common/constants/COMMON';
import { NOTIFICATION_TITLE } from '../../../../common/constants/MESSAGE';
import {
    BaseResponseType,
    MoneySummaryMaterialCostCompanyListData,
    MoneySummaryMaterialCostCompanyProjectListData,
    MoneySummaryMaterialCostCompanyProjectListMaterialCostData,
    SummaryMaterialCostCompanySubmitPayload,
} from '../entity/Entity';
import { CM0020APIImpl } from './ServiceImpl';
const CM0020Service = (api: CM0020APIImpl) => {
    const getMoneySummaryMaterialCostCompanyList = async (params: {
        from: string;
        to: string;
        page: number;
        size: number;
        sortType?: string;
        sortBy?: string;
    }): Promise<MoneySummaryMaterialCostCompanyListData | null> => {
        try {
            const response = await api.getMoneySummaryMaterialCostCompanyList(params);
            return response?.data;
        } catch (error: any) {
            ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };

    const getMoneySummaryMaterialCostCompanyProjectList = async (params: {
        companyId: number;
        from: string;
        to: string;
        page: number;
        size: number;
        sortType?: string;
        sortBy?: string;
    }): Promise<MoneySummaryMaterialCostCompanyProjectListData | null> => {
        try {
            const response = await api.getMoneySummaryMaterialCostCompanyProjectList(params);
            return response?.data;
        } catch (error: any) {
            ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };

    const getMoneySummaryMaterialCostCompanyProjectListMaterialCost = async (params: {
        projectId: number;
        companyId: number;
        from: string;
        to: string;
        page: number;
        size: number;
        sortType?: string;
        sortBy?: string;
    }): Promise<MoneySummaryMaterialCostCompanyProjectListMaterialCostData | null> => {
        try {
            const response = await api.getMoneySummaryMaterialCostCompanyProjectListMaterialCost(
                params,
            );
            return response?.data;
        } catch (error: any) {
            ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };

    const getMoneySummaryMaterialCostCompanyStatus = async (params: {
        from: string;
        to: string;
    }): Promise<MoneySummaryMaterialCostCompanyListData | null> => {
        try {
            const response = await api.getMoneySummaryMaterialCostCompanyStatus(params);
            return response?.data;
        } catch (error: any) {
            ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };

    const putMoneySummaryMaterialCostCompanySubmit = async (
        payload: SummaryMaterialCostCompanySubmitPayload,
    ): Promise<BaseResponseType | null> => {
        try {
            const response = await api.putMoneySummaryMaterialCostCompanySubmit(payload);
            return response?.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };
    const getMaterialCostCompanySummary = async (params: { from: string; to: string }) => {
        try {
            const response = await api.getMaterialCostCompanySummary(params);
            return response?.data;
        } catch (error: any) {
            ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };
    const postMoneySummaryMaterialCostProjectSubmit = async (
        payload: SummaryMaterialCostCompanySubmitPayload,
    ): Promise<BaseResponseType | null> => {
        try {
            const response = await api.postMoneySummaryMaterialCostProjectSubmit(payload);
            return response?.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };

    const doExportCM0020 = async (params: any): Promise<any> => {
        return await api.doExportCM0020(params);
    }

    return {
        getMoneySummaryMaterialCostCompanyList,
        getMoneySummaryMaterialCostCompanyProjectList,
        getMoneySummaryMaterialCostCompanyProjectListMaterialCost,
        getMoneySummaryMaterialCostCompanyStatus,
        putMoneySummaryMaterialCostCompanySubmit,
        getMaterialCostCompanySummary,
        postMoneySummaryMaterialCostProjectSubmit,
        doExportCM0020,
    };
};

export default CM0020Service;
