import { Button, Modal, Spin } from "antd";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import "../../../../../assets/styles/AM/AM014.css";
import LoadingComponent from "../../../../../common/components/icons/LoadingComponent";
import { RootState } from "../../../../../store";
import { AM014Hanlder } from "../handler/HandlerImpl";
import Delete from "../../../../../assets/icons/delete-red.svg";
import XIconModal from "../../../../../assets/icons/x-icon-modal.svg";
import COMMON from "../../../../../common/constants/COMMON";
import moment from "moment";
import AM027Component from "../../../AM027";
import MESSAGE from "../../../../../common/constants/MESSAGE";

type Props = {
  handler: AM014Hanlder;
};

const AM014UI = ({ handler }: Props) => {
  const isVisible = useSelector((state: RootState) => state.am014.isVisible);
  const loading = useSelector((state: RootState) => state.am014.loading);
  const requestId = useSelector((state: RootState) => state.am014.requestId);
  const haveProject = useSelector(
    (state: RootState) => state.am010.haveProject
  );

  useEffect(() => {
    requestId && handler.handleGetRequestAttendance(requestId);
  }, [requestId]);

  return (
    <Modal
      className="group-detail-modal"
      style={{ marginLeft: 300, marginTop: 40, marginBottom: 40 }}
      visible={isVisible}
      closable={false}
      maskClosable={false}
      width={"996px"}
      centered={true}
      footer={
        <>
          <Button
            disabled={loading}
            size="large"
            type="primary"
            style={{ marginLeft: 16, width: 76, letterSpacing: -1.5 }}
            onClick={() => {
              requestId && handler.handleOpenModalUpdate(requestId);
            }}
          >
            編集
          </Button>
          <Button
            disabled={loading}
            icon={<img alt="" src={Delete} style={{ marginRight: 12 }} />}
            size="large"
            style={{
              marginLeft: 16,
              width: 106,
              backgroundColor: "#DAE2EE",
              border: "1px solid rgba(255, 9, 9, 0.5)",
              color: "#FF0909",
              letterSpacing: -1.5,
            }}
            onClick={() =>
              requestId && handler.handleOpenModalConfirmDelete([requestId])
            }
          >
            削除
          </Button>
          <Button
            disabled={loading}
            size="large"
            style={{ marginLeft: 16, width: 118 }}
            onClick={handler.onCancel}
          >
            キャンセル
          </Button>
        </>
      }
    >
      <Spin indicator={LoadingComponent} spinning={loading}>
        <div
          className="w-100-percent header-modal-containter"
          style={{ height: 50 }}
        >
          <div className="header-modal-title-om004 ">打刻申請</div>
          <div style={{ cursor: "pointer" }} onClick={handler.onCancel}>
            <img alt="" src={XIconModal} style={{ height: 18, width: 18 }} />
          </div>
        </div>
        <div style={{ minHeight: 224, marginTop: 24 }} className="am014-data">
          <div className="row">
            <div className="title">ステータス</div>
            <div className="description description_first-child">
              {handler?.requestAttendance?.status?.name}
            </div>
          </div>
          <div className="row">
            <div className="title">事由</div>
            <div className="description">
              {handler?.requestAttendance?.category?.description}
            </div>
          </div>
          <div className="row">
            <div className="title">対象日</div>
            <div className="description">
              {handler?.requestAttendance?.requestDate
                ? moment(handler.requestAttendance.requestDate).format(
                    COMMON.FORMAT_DATE
                  )
                : "-"}
            </div>
          </div>
          <div className="row">
            <div className="title">時間</div>
            <div className="description">
              {handler?.requestAttendance?.requestDate
                ? moment(handler.requestAttendance.requestDate).format(
                    COMMON.FORMAT_TIME1
                  )
                : "-"}
            </div>
          </div>
          {handler?.requestAttendance?.project !== null &&
            handler?.requestAttendance?.construction !== null && (
              <>
                <div className="row">
                  <div className="title">案件名</div>
                  <div className="description">
                    {handler?.requestAttendance?.project?.name}
                  </div>
                </div>
                <div className="row">
                  <div className="title">工事名</div>
                  <div className="description">
                    {handler?.requestAttendance?.construction?.name}
                  </div>
                </div>
              </>
            )}
          <div className="row">
            <div className="title">承認者</div>
            <div className="description">
              {handler?.requestAttendance?.approver?.fullName}
            </div>
          </div>
          <div className="row">
            <div className="title">備考</div>
            <div className="description">
              {handler?.requestAttendance?.note
                ? handler.requestAttendance.note
                : "-"}
            </div>
          </div>
          <div className="row">
            <div className="title">否認理由</div>
            <div className="description">
              {handler?.requestAttendance?.rejectReason
                ? handler.requestAttendance.rejectReason
                : "-"}
            </div>
          </div>
        </div>
      </Spin>
      <AM027Component
        title={MESSAGE.MESSAGE_MODAL_DELETE_TITLE}
        description={MESSAGE.MESSAGE_DELETE_ATTENDANCE}
      />
    </Modal>
  );
};

export default AM014UI;
