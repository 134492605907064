import AM027Api from './infrastructure/api/Api';
import AM027Handler from './presenter/handler/Handler';
import AM027UI from './presenter/ui/UI';
import AM027Service from './usecase/Service';

type ContentModal = {
    title: string;
    description: string;
};

const AM027Component = ({ title, description }: ContentModal) => {
    const am027Api = AM027Api();
    const am027Service = AM027Service(am027Api);
    const am027Handler = AM027Handler(am027Service);
    return <AM027UI handler={am027Handler} title={title} description={description} />;
};

export default AM027Component;
