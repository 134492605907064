import React from "react";
import HomeSvg from "../../assets/icons/home.svg";

const BREADCRUMB = {
  B008: [
    {
      title: "ダッシュボード",
      url: "",
      icon: (
        <img
          style={{ paddingBottom: 2, paddingRight: 8 }}
          alt=""
          src={HomeSvg}
        />
      ),
    },
    {
      title: "予約管理",
      url: "",
      icon: "",
    },
  ],
  B012: [
    {
      title: "ダッシュボード",
      url: "",
      icon: (
        <img
          style={{ paddingBottom: 2, paddingRight: 8 }}
          alt=""
          src={HomeSvg}
        />
      ),
    },
    {
      title: "予約者管理",
      url: "",
      icon: "",
    },
  ],
  B010: [
    {
      title: "ダッシュボード",
      url: "",
      icon: (
        <img
          style={{ paddingBottom: 2, paddingRight: 8 }}
          alt=""
          src={HomeSvg}
        />
      ),
    },
    {
      title: "予約新規作成",
      url: "",
      icon: "",
    },
  ],
  B011: [
    {
      title: "ダッシュボード",
      url: "",
      icon: (
        <img
          style={{ paddingBottom: 2, paddingRight: 8 }}
          alt=""
          src={HomeSvg}
        />
      ),
    },
    {
      title: "苦情管理",
      url: "",
      icon: "",
    },
    {
      title: "苦情作成",
      url: "",
      icon: "",
    },
  ],
  PM003: [
    {
      title: "案件管理",
      url: "",
      icon: (
        <img
          style={{ paddingBottom: 2, paddingRight: 8 }}
          alt=""
          src={HomeSvg}
        />
      ),
    },
  ],
  OM002: [
    {
      title: "設定 ",
      url: "",
      icon: (
        <img
          style={{ paddingBottom: 2, paddingRight: 8 }}
          alt=""
          src={HomeSvg}
        />
      ),
    },
    {
      title: "部門・メンバー",
      url: "",
      icon: "",
    },
  ],
  OM005: [
    {
      title: "設定",
      url: "",
      icon: (
        <img
          style={{ paddingBottom: 2, paddingRight: 8 }}
          alt=""
          src={HomeSvg}
        />
      ),
    },
    {
      title: "部門・メンバー",
      url: "/app-om002",
      icon: "",
    },
    {
      title: "一括メンバー追加",
      url: "",
      icon: "",
    },
  ],
  PM007: [
    {
      title: "案件管理",
      url: "/app-pm002",
      icon: (
        <img
          style={{ paddingBottom: 2, paddingRight: 8 }}
          alt=""
          src={HomeSvg}
        />
      ),
    },
    {
      title: "案件詳細",
      url: "",
      icon: "",
    },
  ],
  AM028: [
    {
      title: "勤怠管理",
      url: "",
      icon: (
        <img
          style={{ paddingBottom: 2, paddingRight: 8 }}
          alt=""
          src={HomeSvg}
        />
      ),
    },
    {
      title: "申請一覧",
      url: "",
      icon: "",
    },
  ],
  AM010: [
    {
      title: "勤務管理",
      url: "",
      icon: (
        <img
          style={{ paddingBottom: 2, paddingRight: 8 }}
          alt=""
          src={HomeSvg}
        />
      ),
    },
    {
      title: "個人勤務実績",
      url: "",
      icon: "",
    },
  ],
};

export default BREADCRUMB;
