import { useDispatch } from "react-redux";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Form } from "antd";
import ConfirmModal from "../../../../../../common/components/modal/ConfirmModal";
import MESSAGE, {
  NOTIFICATION_TITLE,
} from "../../../../../../common/constants/MESSAGE";
import { LABEL_MESSAGE } from "../../../../../../common/constants/MESSAGE";
import { setLoading } from "../../../../../../common/slice/CommonSlice";
import { CM0052ServiceImpl } from "../../usecase/ServiceImpl";
import { STATUS_CM_CHILD_TABLE } from "../../../../../../common/constants/COMMON";
import COMMON from "../../../../../../common/constants/COMMON";
import SuccessNotification from "../../../../../../common/components/notification/SuccessNotification";
import moment from "moment";
import { toTargetPeriod } from "../../../../CM0017/helper";
import {
  setDocumentInfoCM0017_3,
  setDocumentIdCM0017_3,
  setHideRetrieveButton,
} from "../../../../CM0017_3/presenter/slice/Slice";
import { DEFAULT_PARAM } from "../../../wrapper/presenter/handler/Handler";
import helpers from "../../../../../../common/helpers/common";
import ErrorNotification from "../../../../../../common/components/notification/ErrorNotification";

const PARAMS = {
  page: "page7",
  size: "size7",
  sortType: "sortType70",
  from: "from7",
  to: "to7",
  clear: "clear7",
  status: "status7",
  keyword: "keyword7",
};

const DEFAULT_PARAMS = {
  page: 1,
  size: 10,
};

const CM052Handler = (service: CM0052ServiceImpl) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = +(searchParams.get(PARAMS.page) ?? DEFAULT_PARAMS.page);
  const size = +(searchParams.get(PARAMS.size) ?? DEFAULT_PARAMS.size);
  const sortType = searchParams.get(PARAMS.sortType);
  const from = searchParams.get(PARAMS.from) ?? DEFAULT_PARAM.from;
  const to = searchParams.get(PARAMS.to) ?? DEFAULT_PARAM.to;
  const clear = searchParams.get(PARAMS.clear);
  const status = searchParams.get(PARAMS.status);
  const keyword = searchParams.get(PARAMS.keyword);

  const [dataTable, setDataTable] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [selectedRow, setSelectedRow] = useState<React.Key[]>([]);
  const [visibleReject, setVisibleReject] = useState(false);
  const [visibleRejectView, setVisibleRejectView] = useState(false);
  const [isChangeValueReject, setIsChangeValueReject] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [formReject] = Form.useForm();
  const [detailSubmit, setDetailSubmit] = useState<{
    id: number;
    status: string;
  } | null>(null);

  const getCompanyDocumentOutsourceCostReviewList = async (params: {
    page: number;
    size: number;
    sortType?: string;
    sortBy?: string;
    from?: string;
    to?: string;
  }) => {
    try {
      dispatch(setLoading(true));
      const response = await service.getCompanyDocumentOutsourceCostReviewList(
        params
      );
      if (response?.results) {
        const keyForCheckbox: any[] = [];
        const data = response.results.map((element, index) => {
          if (
            !(
              element?.status?.code ===
              STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE
            )
          )
            keyForCheckbox.push(element.id);
          return {
            ...element,
            no: index + 1 + size * (page - 1),
            key: element.id,
          };
        });
        setDataTable(data);
        setSelectedRow(keyForCheckbox);
      } else {
        setDataTable([]);
      }
      if (response?.pagination) {
        setTotal(response.pagination.count);
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

  const onPageChange = (page: number) => {
    searchParams.set(PARAMS.page, String(page));
    setSearchParams(searchParams);
  };

  const onSizeChange = (size: number) => {
    searchParams.set(PARAMS.size, String(size));
    searchParams.delete(PARAMS.page);
    setSearchParams(searchParams);
  };

  const handleChangeSelectedRow = (selectedRowKeys: React.Key[]) => {
    setSelectedRow(selectedRowKeys);
  };

  const handleCancelReject = () => {
    if (isChangeValueReject) {
      ConfirmModal({
        onOk: () => {
          setVisibleReject(false);
          formReject.resetFields();
          setIsChangeValueReject(false);
        },
        className: "confirm__modal",
        title: MESSAGE.MESSAGE_020,
        description: MESSAGE.MESSAGE_021,
        canceText: LABEL_MESSAGE.CANCEL_MODAL,
        okText: LABEL_MESSAGE.OK_MODAL,
        isCenterWithoutMenu: true,
      });
    } else {
      setVisibleReject(false);
    }
  };

  const handleDataSubmitOrReject = (keySelect: any[], data: any[]) => {
    if (!data) return [];
    const results: any[] = [];
    for (const element of data) {
      if (
        keySelect?.includes(element.id) &&
        element?.status?.code ===
          STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE
      ) {
        results.push(element.id);
      }
    }
    return results;
  };

  const handleSubmitData = async (id?: number) => {
    try {
      dispatch(setLoading(true));
      const response = await service.putCompanySummaryOutsourceCostApprove({
        listDocumentId: id
          ? [id]
          : handleDataSubmitOrReject(selectedRow, dataTable),
      });
      if (response?.status === `${COMMON.HTTP_STATUS_OK}`) {
        SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
        setRefresh(!refresh);
        dispatch(setDocumentIdCM0017_3(null));
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleSubmitReject = async (value: any) => {
    try {
      dispatch(setLoading(true));
      const response = await service.putCompanySummaryOutsourceCostReject({
        listDocumentId: detailSubmit?.id
          ? [detailSubmit?.id]
          : handleDataSubmitOrReject(selectedRow, dataTable),
        reason: value?.rejectReason,
      });
      if (response?.status === `${COMMON.HTTP_STATUS_OK}`) {
        SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
        formReject.resetFields();
        setRefresh(!refresh);
        setVisibleReject(false);
        dispatch(setDocumentIdCM0017_3(null));
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };
  const handleChangeValueReject = (value: any) => {
    setIsChangeValueReject(true);
  };
  const handleCloseViewRejectReason = () => {
    setVisibleRejectView(false);
  };
  const handleViewRejectReason = (reason: string) => {
    setRejectReason(reason);
    setVisibleRejectView(true);
  };
  const handleRejectData = (id?: number) => {
    setVisibleReject(true);
    if (!id) setDetailSubmit(null);
  };
  const handleSortTable = (sorter: any) => {
    searchParams.set(
      PARAMS.sortType,
      sorter.order === "ascend" ? "ASC" : "DESC"
    );
    searchParams.delete(PARAMS.page);
    setSearchParams(searchParams);
  };

  const funcDisableBtn = () => {
    return !dataTable?.some(
      (element) => element?.canApprove && selectedRow?.includes(element.id)
    );
  };

  const handleViewModal = (record: any) => {
    dispatch(setDocumentIdCM0017_3(record.id));
    setDetailSubmit({ id: record?.id, status: record?.status?.code });
    const targetPeriod = helpers.toTargetPeriodFormatYYYYMM(
      record?.startDate,
      record?.endDate
    );

    dispatch(
      setDocumentInfoCM0017_3({
        documentTitle: record?.fileName ?? "",
        createdDate: record?.createdAt
          ? moment(record.createAt).format(COMMON.FORMAT_DATE_OM)
          : "",
        targetPeriod: targetPeriod,
        paymentMonth: record.submittedDate
          ? moment(record.submittedDate).format(COMMON.FORMAT_DATE_JA_MONTH)
          : "",
        from: record?.startDate,
        to: record?.endDate,
      })
    );
    dispatch(setHideRetrieveButton(true));
  };

  return {
    dataTable,
    page,
    size,
    total,
    selectedRow,
    formReject,
    visibleReject,
    visibleRejectView,
    isChangeValueReject,
    rejectReason,
    sortType,
    refresh,
    from,
    to,
    clear,
    status,
    keyword,
    detailSubmit,

    onPageChange,
    onSizeChange,
    handleChangeSelectedRow,
    handleCancelReject,
    handleSubmitReject,
    handleChangeValueReject,
    handleCloseViewRejectReason,
    handleRejectData,
    handleViewRejectReason,
    handleSortTable,
    getCompanyDocumentOutsourceCostReviewList,
    funcDisableBtn,
    handleSubmitData,
    handleViewModal,
  };
};

export default CM052Handler;
