// Api
import AM022Api from "./infrastructure/api/Api";
// Service
import AM022Service from "./usecase/Service";
// Handler
import AM022Handler from "./presenter/handler/Handler";
// UI
import AM022UI from "./presenter/ui/UI";

const AM022Component = () => {
  const am022Api = AM022Api();
  const am022Service = AM022Service(am022Api);
  const am022Handler = AM022Handler(am022Service);
  return <AM022UI handler={am022Handler} />;
};

export default AM022Component;
