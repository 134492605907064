import CM0020Api from "./infrastructure/api/Api";
import CM0020Handler from "./presenter/handler/Handler";
import CM0020Service from "./usecase/Service";
import MainUI from "./presenter/ui/UI";

import "../../../assets/styles/AM/AM002.css";
import "../../../assets/styles/OM/OM002.css";


const CM0020Component = () => {
  const api = CM0020Api();

  const service = CM0020Service(api);

  const handler = CM0020Handler(service);

  return <MainUI handler={handler} />;
};

export default CM0020Component;
