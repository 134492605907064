import { AxiosResponse } from 'axios';
import axiosConfig from '../../../../../../config/axiosConfig';

const CM0042Api = () => {
    const getMaterialSubmittedList = async (): Promise<AxiosResponse<any, any>> => {
        const url = '/re-summary/material/submitted/list';
        return await axiosConfig.get(url);
    };
    const getOutsourceSubmittedList = async (): Promise<AxiosResponse<any, any>> => {
        const url = '/re-summary/outsource/submitted/list';
        return await axiosConfig.get(url);
    };
    const getLaborSubmittedList = async (): Promise<AxiosResponse<any, any>> => {
        const url = '/re-summary/labor/submitted/list';
        return await axiosConfig.get(url);
    };
    const getIndirectSubmittedList = async (): Promise<AxiosResponse<any, any>> => {
        const url = '/re-summary/indirect/submitted/list';
        return await axiosConfig.get(url);
    };
    const getReceiveMoneySubmittedList = async (): Promise<AxiosResponse<any, any>> => {
        const url = '/re-summary/receive-money/submitted/list';
        return await axiosConfig.get(url);
    };

    return {
        getMaterialSubmittedList,
        getOutsourceSubmittedList,
        getLaborSubmittedList,
        getIndirectSubmittedList,
        getReceiveMoneySubmittedList,
    };
};

export default CM0042Api;
