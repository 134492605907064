import axiosConfig from '../../../../../config/axiosConfig';

const SM04Api = () => {
    const getDetailSchedule = async (params: { scheduleId: number }): Promise<any> => {

        const url = '/schedule/detail';
        const response = await axiosConfig.get(url, { params });
        return response.data;
    };

    const postComment = async (payload: { content: string }, scheduleId: number) => {
        const url = `/schedule/${scheduleId}/comment`;
        return await axiosConfig.post(url, payload);
    };

    const getListComment = async (params: { page: number; size: number; sortBy: string }, scheduleId: number) => {
        const url = `/schedule/${scheduleId}/comment`;
        return await axiosConfig.get(url, { params });
    };

    const putEditComment = async (payload: { content: string }, scheduleId: number, commentId: number) => {
        const url = `/schedule/${scheduleId}/comment/${commentId}`;
        return await axiosConfig.put(url, payload);
    };

    const deleteComment = async (scheduleId: number, commentId: number) => {
        const url = `/schedule/${scheduleId}/comment/${commentId}`;
        return await axiosConfig.delete(url);
    };

    return {
        getDetailSchedule,
        postComment,
        getListComment,
        putEditComment,
        deleteComment
    };
};

export default SM04Api;
