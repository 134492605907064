import axiosConfig from "../../../../../config/axiosConfig";

const OM009Api = () => {
  const deleteGroupAndMembers = async (data: any): Promise<any> => {
    try {
      const url = "organization/delete-group-and-all-member";
      const response = await axiosConfig.delete(url, { data });
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  const deleteGroupButMember = async (data: any): Promise<any> => {
    try {
      const url = "organization/delete-group-and-move-out-member";
      const response = await axiosConfig.delete(url, { data });
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  return {
    deleteGroupButMember,
    deleteGroupAndMembers,
  };
};

export default OM009Api;
