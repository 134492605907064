import React from 'react';
import { HandlerImpl } from '../handler/HandlerImpl';
import { Button } from 'antd';
import IconCircleCheck from '../../../../../../assets/icons/akar-icons_circle-check-fill.svg';
import ModalReject from '../../../../../../common/components/modal-reject/ModalReject';
import ModalRejectView from '../../../../../../common/components/modal-reject/ModalRejectView';
import TableItem from './TableItem';
import { MATERIAL_COST, INDIRECT_COST, LABOR_COST, OUTSOURCING_COST } from '../handler/Handler';
import CM008Component from '../../../../CM008/index';
import CM0010Component from '../../../../CM0010/index';
import CM0014Component from '../../../../CM0014/index';
import CM0016Component from '../../../../CM0016/index';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DEFAULT_PARAM } from '../../../wrapper/presenter/handler/Handler';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import { setIsVisibleCM008 } from '../../../../CM008/presenter/slice/Slice';
import { setIsVisibleCM0014 } from '../../../../CM0014/presenter/slice/Slice';
import { setIsVisibleCM0010 } from '../../../../CM0010/presenter/slice/Slice';
import { setIsVisibleCM0016 } from '../../../../CM0016/presenter/slice/Slice';
import { STATUS_CM_CHILD_TABLE } from '../../../../../../common/constants/COMMON';

interface props {
    handler: HandlerImpl;
}

const MainUI = ({ handler }: props) => {
    const [searchParams] = useSearchParams();
    const status = searchParams.get('status6');
    const from = searchParams.get('from6') ?? DEFAULT_PARAM.from;
    const to = searchParams.get('to6') ?? DEFAULT_PARAM.to;
    const keyword = searchParams.get('keyword6');
    const clear = searchParams.get('clear6');
    const dispatch = useDispatch();

    const isVisibleCM008 = useSelector((state: RootState) => state.cm008.isVisible);
    const isVisibleCM0010 = useSelector((state: RootState) => state.cm0010.isVisible);
    const isVisibleCM0014 = useSelector((state: RootState) => state.cm0014.isVisible);
    const isVisibleCM0016 = useSelector((state: RootState) => state.cm0016.isVisible);

    useEffect(() => {
        handler.handleGetData();
    }, [handler.refresh]);

    const dataMaterialView: any[] = useMemo(() => {
        return handler.handleDataView(
            handler.materialList,
            handler.sortType61,
            status,
            from,
            to,
            keyword,
            clear,
        );
    }, [handler.materialList, handler.sortType61, status, from, to, keyword, clear]);

    const dataOutsourceView: any = useMemo(() => {
        return handler.handleDataView(
            handler.outsourceList,
            handler.sortType62,
            status,
            from,
            to,
            keyword,
            clear,
        );
    }, [handler.outsourceList, handler.sortType62, status, from, to, keyword, clear]);

    const dataLaborView: any = useMemo(() => {
        return handler.handleDataView(
            handler.laborList,
            handler.sortType63,
            status,
            from,
            to,
            keyword,
            clear,
        );
    }, [handler.laborList, handler.sortType63, status, from, to, keyword, clear]);

    const dataIndirectView: any = useMemo(() => {
        return handler.handleDataView(
            handler.indirectList,
            handler.sortType64,
            status,
            from,
            to,
            keyword,
            clear,
        );
    }, [handler.indirectList, handler.sortType64, status, from, to, keyword, clear]);

    React.useEffect(() => {
        return () => {
            dispatch(setIsVisibleCM008(false));
            dispatch(setIsVisibleCM0014(false));
            dispatch(setIsVisibleCM0010(false));
            dispatch(setIsVisibleCM0016(false));
        };
    }, []);

    return (
        <div className="cm047 cm048">
            {isVisibleCM008 ? (
                <CM008Component
                    disableSubmitOrReject={
                        !(
                            handler.detailSubmit?.statusCode ===
                            STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE
                        )
                    }
                    onReject={() => handler.handleRejectData(true)}
                    onSubmit={() => handler.handleSubmitDataDetail()}
                />
            ) : null}
            {isVisibleCM0010 ? (
                <CM0010Component
                    disableSubmitOrReject={
                        !(
                            handler.detailSubmit?.statusCode ===
                            STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE
                        )
                    }
                    onReject={() => handler.handleRejectData(true)}
                    onSubmit={handler.handleSubmitDataDetail}
                />
            ) : null}
            {isVisibleCM0014 ? (
                <CM0014Component
                    disableSubmitOrReject={
                        !(
                            handler.detailSubmit?.statusCode ===
                            STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE
                        )
                    }
                    onReject={() => handler.handleRejectData(true)}
                    onSubmit={handler.handleSubmitDataDetail}
                />
            ) : null}
            {isVisibleCM0016 ? (
                <CM0016Component
                    disableSubmitOrReject={
                        !(
                            handler.detailSubmit?.statusCode ===
                            STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE
                        )
                    }
                    onReject={() => handler.handleRejectData(true)}
                    onSubmit={handler.handleSubmitDataDetail}
                />
            ) : null}
            {handler.visibleReject ? (
                <ModalReject
                    visible={handler.visibleReject}
                    form={handler.formReject}
                    funcCancel={handler.handleCancelReject}
                    funcSubmit={handler.handleSubmitReject}
                    funcChangeValue={handler.handleChangeValueReject}
                />
            ) : null}
            <ModalRejectView
                visible={handler.visibleRejectView}
                funcClose={handler.handleCloseViewRejectReason}
                rejectReason={handler.rejectReason}
            />
            <TableItem
                dataSource={dataMaterialView}
                selectedRowKeys={handler.selectedRow.materialCost}
                type={MATERIAL_COST}
                sortOrder={
                    !handler.sortType61
                        ? undefined
                        : handler.sortType61 === 'ascend'
                        ? 'ascend'
                        : 'descend'
                }
                title="材料費"
                onChangeSelectKey={handler.handleChangeSelectedRow}
                handleSortTable={handler.handleSortTable}
                handleViewRejectReason={handler.handleViewRejectReason}
                handleViewModal={handler.funcClickDocumentMaterialCost}
            />
            <TableItem
                dataSource={dataOutsourceView}
                selectedRowKeys={handler.selectedRow.outsourcingCost}
                type={OUTSOURCING_COST}
                sortOrder={
                    !handler.sortType62
                        ? undefined
                        : handler.sortType62 === 'ascend'
                        ? 'ascend'
                        : 'descend'
                }
                title="外注費"
                onChangeSelectKey={handler.handleChangeSelectedRow}
                handleSortTable={handler.handleSortTable}
                handleViewRejectReason={handler.handleViewRejectReason}
                handleViewModal={handler.funcClickDocumentOutsourcingCost}
            />
            <TableItem
                dataSource={dataLaborView}
                selectedRowKeys={handler.selectedRow.laborCost}
                type={LABOR_COST}
                sortOrder={
                    !handler.sortType63
                        ? undefined
                        : handler.sortType63 === 'ascend'
                        ? 'ascend'
                        : 'descend'
                }
                title="労務費"
                onChangeSelectKey={handler.handleChangeSelectedRow}
                handleSortTable={handler.handleSortTable}
                handleViewRejectReason={handler.handleViewRejectReason}
                handleViewModal={handler.funcClickDocumentLaborCost}
            />
            <TableItem
                dataSource={dataIndirectView}
                selectedRowKeys={handler.selectedRow.indirectCost}
                type={INDIRECT_COST}
                sortOrder={
                    !handler.sortType64
                        ? undefined
                        : handler.sortType64 === 'ascend'
                        ? 'ascend'
                        : 'descend'
                }
                title="間接工事費"
                onChangeSelectKey={handler.handleChangeSelectedRow}
                handleSortTable={handler.handleSortTable}
                handleViewRejectReason={handler.handleViewRejectReason}
                handleViewModal={handler.funcClickDocumentIndirect}
            />

            {/* <div className="cm047-btn-wrapper">
                <div className="cm047-button">
                    <Button
                        className="button-purple cm047-btn-submit"
                        icon={<img alt="" src={IconCircleCheck} />}
                        size="large"
                        onClick={handler.handleSubmitData}
                        disabled={handler.funcCheckDisableSubmitReject()}
                    >
                        承認
                    </Button>
                    <Button
                        size="large"
                        className="button-warning cm047-btn-reject"
                        onClick={() => handler.handleRejectData()}
                        disabled={handler.funcCheckDisableSubmitReject()}
                    >
                        却下
                    </Button>
                </div>
            </div> */}
        </div>
    );
};

export default MainUI;
