import axiosConfig from "../../../../../config/axiosConfig";
import {
  OrderingCompanyData,
  PM025CreateCompany,
  PM025DeleteCompany,
  PM025ParamsGetListCompany,
  PM025UpdateCompany,
} from "../../entity/PM012Entity";

const PM025Api = () => {
  const getData = async (params: PM025ParamsGetListCompany): Promise<any> => {
    try {
      const url = "/company-colab/list";
      return await axiosConfig.get(url, { params });
    } catch (error) {
      throw error;
    }
  };

  const createCompany = async (data: PM025CreateCompany): Promise<any> => {
    try {
      const url = "/company-colab/add";
      return await axiosConfig.post(url, data);
    } catch (error) {
      throw error;
    }
  };

  const updateCompany = async (data: PM025UpdateCompany): Promise<any> => {
    try {
      const url = "/company-colab/update";
      return await axiosConfig.put(url, data);
    } catch (error) {
      throw error;
    }
  };

  const deleteCompany = async (data: PM025DeleteCompany): Promise<any> => {
    try {
      const url = "/company-colab/delete";
      return await axiosConfig.delete(url, { data });
    } catch (error) {
      throw error;
    }
  };

  const getMaterialCompany = async (params: any): Promise<any> => {
    try {
      const url = "/material-cost-company/list";
      return await axiosConfig.get(url, { params });
    } catch (error) {
      throw error;
    }
  };

  const createMaterialCompany = async (data: any): Promise<any> => {
    try {
      const url = "/material-cost-company/add";
      return await axiosConfig.post(url, data);
    } catch (error) {
      throw error;
    }
  };
  const updateMaterialCompany = async (data: any): Promise<any> => {
    try {
      const url = "/material-cost-company/update";
      return await axiosConfig.put(url, data);
    } catch (error) {
      throw error;
    }
  };
  const deleteMaterialCompany = async (data: any): Promise<any> => {
    try {
      const url = "/material-cost-company/delete";
      return await axiosConfig.delete(url, { data });
    } catch (error) {
      throw error;
    }
  };
  const deleteOrderingCompanyDelete = async (data: any): Promise<any> => {
    const url = "/ordering-company/delete";
    const response = await axiosConfig.delete(url, { data });
    return response.data;
  };

  const getOrderingCompanyList = async (
    params: any
  ): Promise<OrderingCompanyData> => {
    const url = "/ordering-company/list";
    const response = await axiosConfig.get(url, { params });
    return response?.data ?? null;
  };

  return {
    getData,
    createCompany,
    updateCompany,
    deleteCompany,
    getMaterialCompany,
    createMaterialCompany,
    updateMaterialCompany,
    deleteMaterialCompany,
    deleteOrderingCompanyDelete,
    getOrderingCompanyList,
  };
};

export default PM025Api;
