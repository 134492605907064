import { useEffect } from 'react';
import { HanlderAttendanceImpl } from '../../../handler/HandlerImpl';
import { Table, TableColumnsType } from 'antd';
import { DateColumn } from '../../../../entity/Entity';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store';
import { convertToDataSource } from '../../../../helper';

const getColumns = (tableColumns: DateColumn[]) => {
    const columns: TableColumnsType<any> = [
        {
            title: '',
            key: 'attendanceName',
            width: '25%',
            render: (record) => {
                return record.name;
            },
            onCell: () => {
                return {
                    className: 'border-left-table',
                };
            },
        },
        {
            title: '',
            key: 'prev',
            width: 70,
        },
    ];
    for (let i = 0; i < tableColumns.length; i++) {
        columns.push({
            title: '',
            key: i,
            width: `${72 / tableColumns.length}%`,
            render: (record) => record[`${tableColumns[i].value}`],
        });
    }
    columns.push({
        title: '',
        key: 'next',
        width: 29,
    });
    columns.push({
        title: '',
        key: 'ghost',
        width: '4%',
    });
    return columns;
};

interface Props {
    handler: HanlderAttendanceImpl;
    constructionId: number;
    userId: number;
    attendance: any;
}

const AttendanceUI = ({ handler, constructionId, userId, attendance }: Props) => {
    const tableColumns = useSelector((state: RootState) => state.am006.tableColumns);

    useEffect(() => {
        handler.setAttendance(attendance);
    }, [JSON.stringify(attendance)]);

    useEffect(() => {
        const convertedData = convertToDataSource(
            userId,
            handler.attendance?.timesheet ?? [],
            tableColumns.columns,
            handler.attendance?.laborCost ?? 0,
            handler.openStreetMap,
            constructionId,
        )
        handler.setDataSource(convertedData);
    }, [JSON.stringify(handler.attendance?.timesheet ?? []), JSON.stringify(tableColumns)]);

    return (
        <Table
            bordered={true}
            className="am-main-table-construction attendance-table"
            columns={getColumns(tableColumns.columns)}
            dataSource={handler.dataSource}
            pagination={false}
        />
    );
};

export default AttendanceUI;
