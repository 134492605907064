import axiosConfig from '../../../../../config/axiosConfig';
import { SummaryMaterialCostCompanySubmitPayload } from '../../entity/Entity';

const CM0020Api = () => {
    const getMoneySummaryMaterialCostCompanyList = async (params: {
        from: string;
        to: string;
        page: number;
        size: number;
        sortType?: string;
        sortBy?: string;
    }) => {
        const url = '/money/summary/material-cost/company/list';
        return await axiosConfig.get(url, { params });
    };

    const getMoneySummaryMaterialCostCompanyProjectList = async (params: {
        companyId: number;
        from: string;
        to: string;
        page: number;
        size: number;
        sortType?: string;
        sortBy?: string;
    }) => {
        const url = '/money/summary/material-cost/company/project/list';
        return await axiosConfig.get(url, { params });
    };

    const getMoneySummaryMaterialCostCompanyProjectListMaterialCost = async (params: {
        projectId: number;
        companyId: number;
        from: string;
        to: string;
        page: number;
        size: number;
        sortType?: string;
        sortBy?: string;
    }) => {
        const url = '/money/summary/material-cost/company/project/list-material-cost';
        return await axiosConfig.get(url, { params });
    };

    const getMoneySummaryMaterialCostCompanyStatus = async (params: {
        from: string;
        to: string;
    }) => {
        const url = '/re-summary/material-cost/company/document/status';
        return await axiosConfig.get(url, { params });
    };

    const putMoneySummaryMaterialCostCompanySubmit = async (
        data: SummaryMaterialCostCompanySubmitPayload,
    ) => {
        const url = '/re-summary/material-cost/company/submit';
        return await axiosConfig.post(url, data);
    };

    const postMoneySummaryMaterialCostProjectSubmit = async (
        data: SummaryMaterialCostCompanySubmitPayload,
    ) => {
        const url = '/money/summary/material-cost/project/submit';
        return await axiosConfig.post(url, data);
    };

    const getMaterialCostCompanySummary = async (params: { from: string; to: string }) => {
        const url = '/money/summary/material-cost/company/summary';
        return await axiosConfig.get(url, { params });
    };

    const doExportCM0020 = async (params: any): Promise<any> => {
      try {
          const url = '/money/exportCM0020';
          const response = await axiosConfig.get(url, { params, responseType: 'blob'  });
          
          return response;
      } catch (error) {
          throw error;
      }
    };
    
    return {
        getMoneySummaryMaterialCostCompanyList,
        getMoneySummaryMaterialCostCompanyProjectList,
        getMoneySummaryMaterialCostCompanyProjectListMaterialCost,
        getMoneySummaryMaterialCostCompanyStatus,
        putMoneySummaryMaterialCostCompanySubmit,
        getMaterialCostCompanySummary,
        postMoneySummaryMaterialCostProjectSubmit,
        doExportCM0020,
    };
};

export default CM0020Api;
