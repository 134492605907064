import CM0039Api from './infrastructure/api/Api';
import CM0039Handler from './presenter/handler/Handler';
import CM0039UI from './presenter/ui/UI';
import CM0039Service from './usecase/Service';

const CM0039Component = () => {
    const cm0039Api = CM0039Api();
    const cm0039Service = CM0039Service(cm0039Api);
    const cm0039Handler = CM0039Handler(cm0039Service);
    return <CM0039UI handler={cm0039Handler} />;
};

export default CM0039Component;
