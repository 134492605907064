import ErrorNotification from "../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../common/constants/MESSAGE";
import { WorkflowResponseData } from "../../CM008/entity/Entity";
import { RetrieveIndirectDocument } from "../entity/Entity";
import { CM0016Api } from "./ServiceImpl";

const CM0016Service = (cm0016: CM0016Api) => {
  const getListSubmittedIndirectCost = async (params: any): Promise<any> => {
    try {
      return await cm0016.getListSubmittedIndirectCost(params);
    } catch (error) {
      throw error;
    }
  };

  const getPresignLinkS3 = async (params: any): Promise<any> => {
    try {
      const response = await cm0016.getPresignLinkS3(params);
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      throw error;
    }
  };

  const getProjectSummaryReceivedMoney = async (params: any): Promise<any> => {
    try {
      const response = await cm0016.getProjectSummaryReceivedMoney(params);
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      throw error;
    }
  };

  const getConstructionSummaryCostInformation = async (
    params: any
  ): Promise<any> => {
    try {
      const response = await cm0016.getConstructionSummaryCostInformation(
        params
      );
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      throw error;
    }
  };

  const getMoneyIndirectCost = async (params: any): Promise<any> => {
    try {
      const response = await cm0016.getMoneyIndirectCost(params);
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      throw error;
    }
  };

  const retrieveIndirectDocument = async (
    data: RetrieveIndirectDocument
  ): Promise<any> => {
    try {
      const response = await cm0016.retrieveIndirectDocument(data);
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      throw error;
    }
  };
  const getWorkflowIndirectApprovalProcess = async (params: {
    documentId: number;
  }): Promise<WorkflowResponseData> => {
    try {
      const response = await cm0016.getWorkflowIndirectApprovalProcess(params);
      return response.data;
    } catch (error: any) {
      throw error;
    }
  };
  return {
    getListSubmittedIndirectCost,
    getPresignLinkS3,
    getProjectSummaryReceivedMoney,
    getConstructionSummaryCostInformation,
    getMoneyIndirectCost,
    retrieveIndirectDocument,
    getWorkflowIndirectApprovalProcess,
  };
};

export default CM0016Service;
