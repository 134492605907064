
export const MENU_ITEM = [
    {
        name: "技能者情報閲覧",
        code: "technicianInfo"
    },
    {
        name: "技能者認証",
        code: "issueAuthen"
    },

];

export const PARAMS = {
    state: "state",
    page: "page",
    size: "size",
    userId: "userId",
    groupId: "groupId",
    sortBy: "sortBy",
    sortType: "sortType",
};

export const DEFAULT_PARAM = {
    state: "success",
    page: 1,
    size: 10,
    userId: "",
    groupId: "",
    sortType: "",
    sortBy: "ASC",
};

export const FILTER_ISSUE_AUTHEN = [
    {
        name: "前回検索した技能者IDを除く",
        key: "lastDateTime"
    }
]

export const AUTHENTICATION_KEY = [
    {
        state: "wait",
        value: "未発行",
        key: 0
    },
    {
        state: "success",
        value: "認証済み",
        key: 10
    },
    {
        state: "error",
        value: "発行失敗",
        key: 20
    },
]