import React, { useEffect, useRef } from 'react';
import { Modal, } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import IconZoomIn from '../../../assets/icons/akar-icons_zoom-in.svg';
import IconZoomOutWhite from '../../../assets/icons/akar-icons_zoom-out-white.svg';
import XIconModal from '../../../assets/icons/x-icon-modal.svg'

import { RootState } from '../../../store';
import useImageViewer from './useImageViewer';
import useWindowDimensions from '../../helpers/windowDimension';
import { IMAGE_PDF_STANDARD_WIDTH, IMAGE_PDF_STANDARD_HEIGHT } from '../../constants/COMMON';

type Props = {
    imageURL: string;
    marginLeft?: number;
    title?: string;
};

const ImageViewerModalComponent = ({ imageURL, marginLeft = 300, title }: Props) => {
    const imgRef: any = useRef(null);

    const isOpenImageViewer = useSelector(
        (state: RootState) => state.imageViewerModal.isOpenImageViewer,
    );
    const { handleZoomOut, handleZoomIn, onHideImageViewerModal, setImgDimension,
        typeZoom, imgDimension } =
        useImageViewer();

    useEffect(() => {
        const image = new Image();
        image.src = imageURL;

        const handleImageLoad = () => {
            let imageWidth = image.naturalWidth
            let imageHeight = image.naturalHeight

            if ((imageWidth >= IMAGE_PDF_STANDARD_WIDTH && imageHeight >= IMAGE_PDF_STANDARD_HEIGHT) ||
                imageWidth > IMAGE_PDF_STANDARD_WIDTH && imageHeight < IMAGE_PDF_STANDARD_HEIGHT) {

                imageHeight = Math.floor((IMAGE_PDF_STANDARD_WIDTH / imageWidth) * imageHeight)
                imageWidth = IMAGE_PDF_STANDARD_WIDTH

            } else if (imageWidth < IMAGE_PDF_STANDARD_WIDTH && imageHeight > IMAGE_PDF_STANDARD_HEIGHT) {
                imageWidth = Math.floor((IMAGE_PDF_STANDARD_HEIGHT / imageHeight) * imageWidth)
                imageHeight = IMAGE_PDF_STANDARD_HEIGHT
            }

            setImgDimension({ width: imageWidth, height: imageHeight });
        };

        image.addEventListener('load', handleImageLoad);

        return () => {
            // Cleanup: Remove the event listener to avoid memory leaks
            image.removeEventListener('load', handleImageLoad);
        };
    }, [imageURL]); // Only re-run the effect if imageUrl changes

    const MainContent = () => <div style={{ width: 'auto', height: 'auto', }}>
        <img
            ref={imgRef}
            alt=""
            src={imageURL}
            style={{
                width: imgDimension.width * (typeZoom / 100),
                height: imgDimension.height * (typeZoom / 100),
            }}
        />
    </div>
    const { width } = useWindowDimensions();

    return (
        <Modal
            title={
                <div className="w-100-percent " style={{ display: 'flex', justifyContent: "space-between", alignContent: "center" }}>
                    <div className="header-modal-title-cm006">{title ? title : null}</div>
                    <div style={{ cursor: 'pointer', }} onClick={() => onHideImageViewerModal()}>
                        <img alt="" src={XIconModal} style={{ height: 18, width: 18 }} />
                    </div>
                </div>
            }
            closable={false}

            maskClosable={false}
            style={{ marginLeft: width > 1872 ? 300 : 0, marginTop: 40, marginBottom: 40 }}
            centered={width > 1700}
            width={1572}
            className="group-detail-modal modal-fixed-1572"
            visible={isOpenImageViewer}
            footer={null}
        >
            <div style={{
                justifyContent: imgDimension.width * (typeZoom / 100) > 1500 ? undefined : 'center',
                alignItems: imgDimension.height * (typeZoom / 100) > 703 ? undefined : 'center'
            }}
                className={`image-document-container`} >
                <MainContent />
            </div>
            <div className="pm005-modal-img-zoom">
                <div className="pm005-modal-img-zoom-left">Zoom: {typeZoom}%</div>
                <div className="pm005-modal-img-zoom-right ">
                    <button onClick={handleZoomOut} style={{ cursor: typeZoom === 30 ? 'not-allowed' : 'pointer' }} >
                        <img alt="" src={IconZoomOutWhite} />
                    </button>
                    <button onClick={handleZoomIn} style={{ cursor: typeZoom === 200 ? 'not-allowed' : 'pointer' }}>
                        <img alt="" src={IconZoomIn} />
                    </button>
                </div>
            </div>

        </Modal >
    );
};

export default ImageViewerModalComponent;
