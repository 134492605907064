import { data } from "./../../../OM/OM005/presenter/slice/Slice";
import { CM0017APIImpl } from "./ServiceImpl";
import helpers from "../../../../common/helpers/common";
import {
  CompanyProfile,
  MoneyDirectCostOutsourceOffsetList,
  ReSummaryCompanyDocumentOutsourceCostStatusResult,
  ReSummaryCompanyOutsourceCostOffsetList,
  ReSummaryCompanySummaryOutsourceCostDetail,
  ReSummaryCompanySummaryOutsourceCostResult,
  ReSummaryCompanySummaryOutsourceCostSubmitPayload,
  ReSummaryCompanySummaryOutsourceCostTotalResult,
} from "../entity/Entity";
import { CommonPutDeletePostResponse } from "../../CM007/entity/Entity";
import COMMON from "../../../../common/constants/COMMON";

const CM0017Service = (api: CM0017APIImpl) => {
  const getReSummaryCompanySummaryOutsourceCostDetail = async (params: {
    companyId: number;
    colabRefId: number;

    keyword?: string;
    sortBy?: string;
    sortType?: string;
    page?: string;
    size?: string;
    from?: string;
    to?: string;
  }): Promise<ReSummaryCompanySummaryOutsourceCostDetail> => {
    try {
      const response = await api.getReSummaryCompanySummaryOutsourceCostDetail({
        ...params,
        from: params.from ? helpers.getFilterTimeMonth(params.from) : undefined,
        to: params.to ? helpers.getFilterTimeMonthTo(params.to) : undefined,
      });
      const { pagination, results } = response.data;
      const responseData: ReSummaryCompanySummaryOutsourceCostDetail = {
        pagination,
        results,
      };
      return responseData;
    } catch (error) {
      throw error;
    }
  };
  const getReSummaryCompanySummaryOutsourceCostTotal = async (params: {
    companyId: number;
    colabRefId: number;

    from?: string;
    to?: string;
  }): Promise<ReSummaryCompanySummaryOutsourceCostTotalResult> => {
    try {
      const response = await api.getReSummaryCompanySummaryOutsourceCostTotal({
        ...params,
        from: params.from ? helpers.getFilterTimeMonth(params.from) : undefined,
        to: params.to ? helpers.getFilterTimeMonthTo(params.to) : undefined,
      });
      const { results } = response.data;
      return results;
    } catch (error) {
      throw error;
    }
  };
  const getReSummaryCompanyDocumentOutsourceCostStatus = async (params: {
    companyId: number;
    colabRefId: number;

    from?: string;
    to?: string;
  }): Promise<ReSummaryCompanyDocumentOutsourceCostStatusResult> => {
    try {
      const response = await api.getReSummaryCompanyDocumentOutsourceCostStatus(
        {
          ...params,
          from: params.from
            ? helpers.getFilterTimeMonth(params.from)
            : undefined,
          to: params.to ? helpers.getFilterTimeMonthTo(params.to) : undefined,
        }
      );
      const { results } = response.data;
      return results;
    } catch (error) {
      throw error;
    }
  };
  const getReSummaryCompanySummaryOutsourceCost = async (params: {
    companyId: number;
    colabRefId: number;

    from?: string;
    to?: string;
  }): Promise<ReSummaryCompanySummaryOutsourceCostResult> => {
    try {
      const response = await api.getReSummaryCompanySummaryOutsourceCost({
        ...params,
        from: params.from ? helpers.getFilterTimeMonth(params.from) : undefined,
        to: params.to ? helpers.getFilterTimeMonthTo(params.to) : undefined,
      });
      const { results } = response.data;
      return results;
    } catch (error) {
      throw error;
    }
  };

  const postReSummaryCompanySummaryOutsourceCostSubmit = async (
    data: ReSummaryCompanySummaryOutsourceCostSubmitPayload
  ): Promise<CommonPutDeletePostResponse> => {
    try {
      const response = await api.postReSummaryCompanySummaryOutsourceCostSubmit(
        {
          ...data,
          startDate: helpers.getFilterTimeMonth(data.startDate) ?? "",
          endDate: helpers.getFilterTimeMonthTo(data.endDate) ?? "",
        }
      );
      return response.data;
    } catch (error: any) {
      throw error;
    }
  };

  const getMoneyDirectCostOutsourceOffsetList = async (params: {
    categoryId?: number;
    outsourceCostInformationId: number;
  }): Promise<MoneyDirectCostOutsourceOffsetList[]> => {
    try {
      const response = await api.getMoneyDirectCostOutsourceOffsetList({
        ...params,
        page: COMMON.DEFAULT_PAGE,
        size: COMMON.DEFAULT_SIZE,
      });
      const numPages = response?.pagination?.numPages ?? 1;
      let finalResults: MoneyDirectCostOutsourceOffsetList[] =
        response?.data?.results ?? [];
      for (let i = 2; i <= numPages; i++) {
        const response = await api.getMoneyDirectCostOutsourceOffsetList({
          ...params,
          page: i,
          size: COMMON.DEFAULT_SIZE,
        });
        finalResults = finalResults.concat(response?.data?.results ?? []);
      }
      return finalResults;
    } catch (error: any) {
      throw error;
    }
  };

  const getCompanyProfile = async (): Promise<CompanyProfile> => {
    try {
      const data = await api.getCompanyProfile();
      return data.results;
    } catch (error: any) {
      throw error;
    }
  };

  const getSubCategory = async (): Promise<any> => {
    try {
      return await api.getSubCategory();
    } catch (error) {
      throw error;
    }
  };
  const getTaxSetting = async (params: any) => {
    try {
      const response = await api.getTaxSetting({ ...params });
      return response.data;
    } catch (error: any) {
      return;
    }
  };
  const doExportCM0017 = async (params: any): Promise<any> => {
    return await api.doExportCM0017({
      ...params,
      from: params.from ? helpers.getFilterTimeMonth(params.from) : undefined,
      to: params.to ? helpers.getFilterTimeMonthTo(params.to) : undefined,
    });
  };
  return {
    getReSummaryCompanySummaryOutsourceCostDetail,
    getReSummaryCompanySummaryOutsourceCostTotal,
    getReSummaryCompanySummaryOutsourceCost,
    postReSummaryCompanySummaryOutsourceCostSubmit,
    getReSummaryCompanyDocumentOutsourceCostStatus,
    getMoneyDirectCostOutsourceOffsetList,
    getCompanyProfile,
    getSubCategory,
    getTaxSetting,
    doExportCM0017,
  };
};

export default CM0017Service;
