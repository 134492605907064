import React, { MouseEventHandler } from 'react'
import XIconModal from '../../../assets/icons/x-icon-modal.svg';

interface props {
    title: string,
    onClick: MouseEventHandler<HTMLDivElement> | undefined
}

export const CommonHeaderModal = ({ title, onClick }: props) => <div className="w-100-percent header-modal-container-2"
    style={{ height: 26 }}>
    <div className="header-modal-title-om004 ">{title}</div>
    <div style={{ cursor: 'pointer' }} onClick={onClick}>
        <img alt="" src={XIconModal} style={{ height: 18, width: 18 }} />
    </div>
</div>