import { createSlice } from '@reduxjs/toolkit';
import { TabSliceType } from '../../entity/Entity';

export const initialState: TabSliceType = {};

export const tabCM020Slice = createSlice({
    name: 'tabCM0020',
    initialState,
    reducers: {},
});

export const {} = tabCM020Slice.actions;

export default tabCM020Slice.reducer;
