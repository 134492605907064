// Built-in
import React, { useEffect } from "react";

// Source files
import { HandlerImpl } from "../handler/HandlerImpl";

// Image files
import CompletedPaidAmountComponent from "../component/completed-paid-amount";
import PlannedPaidAmountComponent from "../component/planned-paid-amount";
import HistoryPaymentComponent from "../component/history-payment";
import PdfViewerModalComponent from "../../../../../common/components/pdf-viewer-modal/PdfViewerModalComponent";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import ImageViewerModalComponent from "../../../../../common/components/image-viewer-modal/ImageViewerComponent";
import COMMON from "../../../../../common/constants/COMMON";

interface props {
  handler: HandlerImpl;
}

const MainUI = ({ handler }: props) => {
  useEffect(() => {
    return () => {
      handler.clearRedux();
    };
  }, []);

  const evidenceLink = useSelector(
    (state: RootState) => state.cm005.evidenceLink
  );
  const isOpenImageViewer = useSelector(
    (state: RootState) => state.imageViewerModal.isOpenImageViewer
  );
  const evidenceName = useSelector(
    (state: RootState) => state.cm005.evidenceName
  );
  return (
    <div>
      <PlannedPaidAmountComponent />
      <CompletedPaidAmountComponent />
      <HistoryPaymentComponent />
      <PdfViewerModalComponent
        pdfURL={evidenceLink}
        title={evidenceName} />
      <ImageViewerModalComponent
        imageURL={evidenceLink}
        title={evidenceName}
      />
      <PdfViewerModalComponent
        pdfURL={evidenceLink}
        title={evidenceName} />

    </div>
  );
};

export default MainUI;
