import React from "react";
import IconEdit from "../../../../../assets/icons/edit-white-ic1.svg";
import { Button, Col, Row, Tabs, Tooltip } from "antd";
import { Handler } from "../handler/HandlerImpl";
import helpers from "../../../../../common/helpers/common";
import COMMON from "../../../../../common/constants/COMMON";
import moment from "moment";
import { SYSTEM_ROLES } from "../../../../../common/constants/AUTHORIZATION";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import IconVerifyCcus from "../../../../../assets/icons/icon_verify_ccus_company.svg";
import MESSAGE from "../../../../../common/constants/MESSAGE";
import { CCUS_VERRIFICATION_STATE } from "../../../../../common/constants/STATUS";

interface props {
  handler: Handler;
}

const InformationComponent = ({ handler }: props) => {
  const isRoleAdmin = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdmin
  );

  const isRoleAdminSystem = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdminSystem
  );
  const isVerifiedCompany = useSelector(
    (state: RootState) => state.auth?.authData?.isVerifiedCompany
  );
  return (
    <div className="ic1-body">
      <Row>
        <Col span={8}>
          <div className="content-wrapper-ic1">
            <div className="item-info">
              <div className="label-text">会社名</div>
              <div className="info-text">
                {handler?.profileCompany?.name || "-"}
              </div>
            </div>
            <div className="item-info">
              <div className="label-text">郵便番号</div>
              <div className="info-text">
                {handler?.profileCompany?.postNumber
                  ? `〒${
                      helpers.formatPostNumber(
                        handler?.profileCompany?.postNumber
                      ) ?? ""
                    }`
                  : "-"}
              </div>
            </div>
            <div className="item-info">
              <div className="label-text">住所</div>
              <div className="info-text">
                {handler?.profileCompany?.address || "-"}
              </div>
            </div>
            <div className="item-info">
              <div className="label-text">電話番号</div>
              <div className="info-text">
                {helpers.toPhoneNumberFormat(
                  handler?.profileCompany?.phoneNumber ?? ""
                ) || "-"}
              </div>
            </div>
            <div className="item-info">
              <div className="label-text">FAX</div>
              <div className="info-text">
                {helpers.toPhoneNumberFormat(
                  handler?.profileCompany?.fax ?? ""
                ) || "-"}
              </div>
            </div>
          </div>
        </Col>
        <Col span={14}>
          <div className="content-wrapper-ic1">
            <div className="item-info">
              <div className="label-text">ID</div>
              <div className="info-text">
                {handler?.profileCompany?.adminName || "-"}
              </div>
            </div>
            <div className="item-info">
              <div className="label-text">アドミン名</div>
              <div className="info-text">
                {handler?.profileCompany?.adminFullName || "-"}
              </div>
            </div>
            <div className="item-info">
              <div className="label-text">メールアドレス</div>
              <div className="info-text">
                {handler?.profileCompany?.email || "-"}
              </div>
            </div>
            <div className="item-info">
              <div className="label-text">CCUS事業者ID</div>
              <div className="info-text">
                {helpers.convertToCCUSFormat(
                  handler?.profileCompany?.ccusId || ""
                ) || "-"}
              </div>
              {handler?.profileCompany?.state ==
              CCUS_VERRIFICATION_STATE.DONE ? (
                <Tooltip
                  placement="top"
                  title={MESSAGE.TITLE_VERIFY_CCUS_COMPANY}
                >
                  <img
                    src={IconVerifyCcus}
                    alt="verified"
                    style={{ height: 18, width: 18 }}
                  />
                </Tooltip>
              ) : (
                <></>
              )}

              <div
                style={{
                  marginLeft: 24,
                }}
                className="label-text"
              >
                有効期限
              </div>
              <div className="info-text">
                {moment(
                  handler?.profileCompany?.ccusExpiredDate,
                  true
                ).isValid()
                  ? helpers.formatDatePresenter(
                      handler?.profileCompany?.ccusExpiredDate,
                      COMMON.FORMAT_DATE_CM
                    )
                  : "-"}
              </div>
            </div>
          </div>
        </Col>
        <Col span={2}>
          <div className="ic1-header-info">
            <div className="ic1-header-btn">
              <div style={{ display: "flex" }}>
                {isRoleAdmin || isRoleAdminSystem ? (
                  <Button
                    style={{ width: 98 }}
                    size="large"
                    type="primary"
                    className="button-purple"
                    onClick={handler.redirectEditInforCompany}
                  >
                    <div className="center-item">
                      <img
                        alt=""
                        src={IconEdit}
                        style={{
                          marginRight: 8,
                          fontWeight: 700,
                          fontSize: 14,
                        }}
                      />
                      <div className="edit-button-ic1">編集</div>
                    </div>
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default InformationComponent;
