import React, { useEffect, useLayoutEffect } from 'react';
import { HandlerImpl } from '../handler/HandlerImpl';
import MenuWrapperComponent from '../../../../../common/components/menu-wrapper';
import BreadcrumbComponent from '../../../../../common/components/breadcrumb/BreadcrumbComponent';
import { Button, Form } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import H1Modal from '../../../../../common/components/modal-H1/H1Modal';
import H2Modal from '../../../../../common/components/modal-H2/H2Modal';
import H3Modal from '../../../../../common/components/modal-H3/H3Modal';
import H4Modal from '../../../../../common/components/modal-H4/H4Modal';
import COMMON from '../../../../../common/constants/COMMON';
import { useParams, useSearchParams } from 'react-router-dom';
import { GROUP_ROLE_NAME } from '../../../AOP004/contants';
import BodyLeft from './BodyLeft';
import BodyRight from './BodyRight';
import HomeSvg from '../../../../../assets/icons/home.svg';

interface props {
    handler: HandlerImpl;
}

const description = (
    <>
        <div>行った変更は保存されません。</div>
        <div>このページを閉じてもよろしいでしょうか？</div>
    </>
);

const MainUI = ({ handler }: props) => {
    const isOpenH1Modal = useSelector((state: RootState) => state.h1Modal.isOpenH1);
    const isOpenH2Modal = useSelector((state: RootState) => state.h2Modal.isOpenH2);
    const isOpenH3Modal = useSelector((state: RootState) => state.h3Modal.isOpenH3);
    const isOpenH4Modal = useSelector((state: RootState) => state.h4Modal.isOpenH4);

    const { roleId } = useParams();
    const [searchParams] = useSearchParams();
    const groupRoleName = searchParams.get(GROUP_ROLE_NAME) ?? '';
    const fromDetail = window.location.pathname.includes('app-aop004') ? true : false;

    const BREADCRUMB_AOP003 = [
        {
            title: '設定',
            url: '',
            icon: (
                <img style={{ paddingBottom: 2, paddingRight: 8 }} alt="" src={HomeSvg} />
            ),
        },
        {
            title: '役職・権限',
            url: '/app-aop001',
            icon: <></>,
        },
        {
            title: roleId !== '0' ? '役職・権限編集' : '役職・権限追加',
            url: '',
            icon: <></>,
        },
    ];

    const BREADCRUMB_AOP003_DETAIL = [
        {
            title: '設定',
            url: '',
            icon: (
                <img style={{ paddingBottom: 2, paddingRight: 8 }} alt="" src={HomeSvg} />
            ),
        },
        {
            title: '役職・権限',
            url: '/app-aop001',
            icon: <></>,
        },
        {
            title: '役職・権限詳細',
            url: `/app-aop004?groupRoleId=${roleId}&groupRoleName=${groupRoleName}`,
            icon: <></>,
        },
        {
            title: roleId !== '0' ? '役職・権限編集' : '役職・権限追加',
            url: '',
            icon: <></>,
        },
    ];

    useLayoutEffect(() => {
        handler.getRolePermissionCategory();
    }, []);

    useEffect(() => {
        if (handler.roleId) {
            handler.getRoleUserList({
                page: COMMON.DEFAULT_PAGE,
                size: COMMON.DEFAULT_SIZE,
                roleId: +handler.roleId,
                keyword: '',
            });
        }
    }, [handler.roleId]);

    return (
        <MenuWrapperComponent chosenKey={71} openKey={7}>
            <BreadcrumbComponent data={fromDetail ? BREADCRUMB_AOP003_DETAIL : BREADCRUMB_AOP003} />
            <div className="aop003">
                <h3 className="aop003-title">
                    {roleId === '0' ? '役職・権限追加' : '役職・権限編集'}
                </h3>
                <Form
                    name="aop003-form"
                    form={handler.form}

                    onValuesChange={handler.onFormValuesChange}
                    onFinish={handler.handleSubmit}
                >
                    <div className="aop003-body">
                        <BodyLeft handler={handler} />
                        <BodyRight handler={handler} />
                    </div>
                    <div className="aop003-footer">
                        <Button
                            size="large"
                            className="button-purple aop003-btn-submit"
                            htmlType="submit"
                        >
                            保存
                        </Button>
                        <Button
                            size="large"
                            className="aop003-btn-cancel"
                            htmlType="button"
                            onClick={() =>
                                handler.handleCancelSave(
                                    description,
                                    fromDetail,
                                    String(roleId),
                                    groupRoleName,
                                )
                            }
                        >
                            キャンセル
                        </Button>
                    </div>
                </Form>
                {isOpenH1Modal ? <H1Modal /> : null}
                {isOpenH2Modal ? <H2Modal /> : null}
                {isOpenH3Modal ? <H3Modal /> : null}
                {isOpenH4Modal ? <H4Modal /> : null}
            </div>
        </MenuWrapperComponent>
    );
};

export default MainUI;
