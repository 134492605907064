import { useState } from "react";
import { useDispatch } from "react-redux";
import { SW01ServiceImpl } from "../../usecase/ServiceImpl";
import { Form } from "antd";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import COMMON from "../../../../../common/constants/COMMON";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import { NOTIFICATION_TITLE } from "../../../../../common/constants/MESSAGE";
import ErrorModal from "../../../../../common/components/modal/ErrorModal";

export const WORKFLOW_TYPE = {
  request_workflow: "request_workflow",
  ci_workflow: "ci_workflow",
  cr_workflow: "cr_workflow",
  re_document_workflow: "re_document_workflow",
  re_document_receive_money_workflow: "re_document_receive_money_workflow",
  re_summary_internal_workflow: "re_summary_internal_workflow",
  re_document_outsource_summary_workflow:
    "re_document_outsource_summary_workflow",
};

const DEFAULT_CATEGORY: any = {
  request_workflow: [],
  ci_workflow: [],
  cr_workflow: [],
  re_document_workflow: [],
  re_document_receive_money_workflow: [],
  re_summary_internal_workflow: [],
  re_document_outsource_summary_workflow: [],
};

const SW01Handler = (service: SW01ServiceImpl) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [categories, setCategories] = useState(DEFAULT_CATEGORY);
  const [currentValue, setCurrentValue] = useState<any>({
    request_workflow: null,
    ci_workflow: null,
    cr_workflow: null,
    re_document_workflow: null,
  });
  const getWorkflowType = async () => {
    try {
      dispatch(setLoading(true));
      const [response, responseDropdown] = await Promise.all([
        service.getWorkflowTypeList({}),
        service.getWorkflowList({}),
      ]);
      const categoriesData: any = {
        request_workflow: [],
        ci_workflow: [],
        cr_workflow: [],
        re_document_workflow: [],
        re_document_receive_money_workflow: [],
        re_summary_internal_workflow: [],
        re_document_outsource_summary_workflow: [],
      };
      if (response?.results) {
        for (const item of response.results) {
          const itemRe: any = { ...item, detail: null, option: [] };

          if (item.workflow) {
            form.setFieldValue(item.category.code, item.workflow.id);
            itemRe.option = [
              { value: item.workflow.id, name: item.workflow.name },
            ];
            setCurrentValue((prevState: any) => {
              return {
                ...prevState,
                [item.category.code]: item.workflow.id,
              };
            });
          }

          if (
            responseDropdown?.results &&
            responseDropdown.results.length > 0
          ) {
            for (const itemDropdown of responseDropdown?.results) {
              if (itemDropdown.id !== item.workflow?.id)
                itemRe.option.push({
                  name: itemDropdown.name,
                  value: itemDropdown.id,
                });
            }
          }

          if (
            item?.category?.code &&
            categoriesData.hasOwnProperty(item.category.code)
          )
            categoriesData[item.category.code].push(itemRe);
        }
      }
      setCategories(categoriesData);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const formatDescription = (message: string) => {
    const arr2 = message.split("。");
    const arr2Filtered = arr2.filter((element: string) => element !== "");
    const arr2WithDot = arr2Filtered.map((element: string) => element + "。");
    return arr2WithDot;
  };

  const putWorkflowSetting = async (
    payload: { workflowId: number; workflowTypeId: number },
    type: string
  ) => {
    try {
      dispatch(setLoading(true));
      const response = await service.putWorkflowSetting(payload);
      if (response?.status === `${COMMON.HTTP_STATUS_OK}`) {
        SuccessNotification(response.message || NOTIFICATION_TITLE.SUCCESS);
        setCurrentValue({ ...currentValue, [type]: payload.workflowId });
      } else if (currentValue[type])
        form.setFieldValue(type, currentValue[type]);
    } catch (error: any) {
      ErrorModal({
        title: "エラーメッセージ",
        description: formatDescription(error?.message ?? ""),
        okText: "キャンセル",
        onOk: () => {
          if (currentValue[type]) form.setFieldValue(type, currentValue[type]);
        },
      });
    } finally {
      dispatch(setLoading(false));
    }
  };
  return {
    form,
    categories,

    getWorkflowType,
    putWorkflowSetting,
  };
};

export default SW01Handler;
