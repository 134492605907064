import React from 'react';
import { useCountdown } from './useCountDown';

type propsCountdown = {
    targetDate: any,
    resendAction: any
}


const CountdownTimer = ({ targetDate, resendAction, }: propsCountdown) => {
    const [days, hours, minutes, seconds] = useCountdown(targetDate);

    if (days + hours + minutes + seconds <= 0) {
        //localStorage.removeItem("timerOTP")
        return <span className="timer-title">{resendAction}</span>
    } else {
        return <span className="timer-title">コード再送信 <span style={{ color: "rgba(96, 91, 255, 1)" }}>
            ({`0${minutes}:${seconds >= 10 ? seconds : "0" + seconds}`})</span></span>
    }
};



export default CountdownTimer;