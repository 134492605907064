import { WorkflowResponseData } from "../../CM008/entity/Entity";
import {
  ReSummarySubmittedReceiveMoneyCompanyList,
  ReSummarySubmittedReceiveMoneyProjectList,
} from "../entity/Entity";
import { CM0033Api } from "./ServiceImpl";

const CM0033Service = (cm0033: CM0033Api) => {
  const getReSummarySubmittedReceiveMoneyProjectList = async (params: {
    documentId: number;
    page: number;
    size: number;
  }): Promise<ReSummarySubmittedReceiveMoneyProjectList> => {
    try {
      const response =
        await cm0033.getReSummarySubmittedReceiveMoneyProjectList(params);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getReSummarySubmittedReceiveMoneyCompanyList = async (params: {
    documentId: number;
    page: number;
    size: number;
  }): Promise<ReSummarySubmittedReceiveMoneyCompanyList> => {
    try {
      const response =
        await cm0033.getReSummarySubmittedReceiveMoneyCompanyList(params);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getProjects = async (params: any): Promise<any> => {
    try {
      const response = await cm0033.getProjects({ ...params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getListMoneyReceive = async (params: any): Promise<any> => {
    try {
      const response = await cm0033.getListMoneyReceive({ ...params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getListMoneyReceiveCompany = async (params: any): Promise<any> => {
    try {
      const response = await cm0033.getListMoneyReceiveCompany({ ...params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getListOffset = async (params: any): Promise<any> => {
    try {
      const response = await cm0033.getListOffset({ ...params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const retrieveDocumentCompany = async (data: any): Promise<any> => {
    try {
      const response = await cm0033.retrieveDocumentCompany(data);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const retrieveDocumentProject = async (data: any): Promise<any> => {
    try {
      const response = await cm0033.retrieveDocumentProject(data);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getLineSummaryCompany = async (params: any): Promise<any> => {
    try {
      const response = await cm0033.getLineSummaryCompany({ ...params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getLineSummaryProject = async (params: any): Promise<any> => {
    try {
      const response = await cm0033.getLineSummaryProject({ ...params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  const getWorkflowSummaryReceiveMoneyCompanyApprovalProcess = async (params: {
    documentId: number;
  }): Promise<WorkflowResponseData> => {
    try {
      const response =
        await cm0033.getWorkflowSummaryReceiveMoneyCompanyApprovalProcess(
          params
        );
      return response.data;
    } catch (error: any) {
      throw error;
    }
  };
  const getWorkflowSummaryReceiveMoneyProjectApprovalProcess = async (params: {
    documentId: number;
  }): Promise<WorkflowResponseData> => {
    try {
      const response =
        await cm0033.getWorkflowSummaryReceiveMoneyProjectApprovalProcess(
          params
        );
      return response.data;
    } catch (error: any) {
      throw error;
    }
  };
  return {
    getProjects,
    getListMoneyReceive,
    getListOffset,
    retrieveDocumentCompany,
    retrieveDocumentProject,
    getListMoneyReceiveCompany,
    getLineSummaryProject,
    getLineSummaryCompany,
    getWorkflowSummaryReceiveMoneyCompanyApprovalProcess,
    getWorkflowSummaryReceiveMoneyProjectApprovalProcess,
    getReSummarySubmittedReceiveMoneyProjectList,
    getReSummarySubmittedReceiveMoneyCompanyList,
  };
};

export default CM0033Service;
