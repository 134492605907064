import { createSlice } from "@reduxjs/toolkit";
import { CM004SliceType } from "../../entity/Entity";

export const initialState: CM004SliceType = {
  isRefresh: false,
  isSubmit: false,
  isExport: false,
  isEnable: false,
  constructionInfo: null,
  completedWork: null,
  constructionCost: null,
  detailProject: null,
};

export const CM004Slice = createSlice({
  name: "cm004",
  initialState,
  reducers: {
    resetCM004: () => initialState,
    refreshCM004: (state) => {
      state.isRefresh = !state.isRefresh;
    },
    toggleIsSubmit: (state) => {
      state.isSubmit = !state.isSubmit;
    },
    toggleIsExport: (state) => {
      state.isExport = !state.isExport;
    },
    setIsEnable: (state, action) => {
      state.isEnable = action.payload;
    },
    setConstructionInfo: (state, action) => {
      state.constructionInfo = action.payload;
    },
    setCompletedWork: (state, action) => {
      state.completedWork = action.payload;
    },
    setConstructionCost: (state, action) => {
      state.constructionCost = action.payload;
    },
    setDetailProject: (state, action) => {
      state.detailProject = action.payload;
    },
  },
});

export const {
  resetCM004,
  refreshCM004,
  toggleIsSubmit,
  setIsEnable,
  setConstructionInfo,
  toggleIsExport,
  setCompletedWork,
  setConstructionCost,
  setDetailProject,
} = CM004Slice.actions;

export default CM004Slice.reducer;
