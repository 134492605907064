import moment from "moment";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { showImageViewerModal } from "../../../../../common/components/image-viewer-modal/ImageViewSlice";
import ConfirmModal from "../../../../../common/components/modal/ConfirmModal";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import COMMON, {
  STATUS_CM_CHILD_TABLE,
} from "../../../../../common/constants/COMMON";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import {
  TableConstructionContractBudget,
  TableSubmissionHistory,
} from "../../entity/Entity";
import { CM0010UseCase } from "../../usecase/ServiceImpl";
import { resetCM0010, setLoading } from "../slice/Slice";
import { DataTableConstructionBudgetBalance } from "../../../CM0016/entity/Entity";
import { setRefresh } from "../../../CM034-CM53/CM035/presenter/slice/Slice";
import { DataTableOverview } from "../../../CM006/entity/Entity";
import { CM006UseCase } from "../../../CM006/usecase/ServiceImpl";
import { convertDataOverview } from "../../../CM006/helper";
import { CM008UseCase } from "../../../CM008/usecase/ServiceImpl";
import { convertDataTableInformationCost } from "../../helper";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import { ItemApprove } from "../../../../../common/components/approval-flow/ApprovalFlow";
import { setDocumentView } from "../../../../../common/components/modal-approve-view/presenter/slice/Slice";
import { showModalApproveConfirm } from "../../../../../common/components/modal-approve-confirm/presenter/slice/Slice";
import { showPdfViewerModal } from "../../../../../common/components/pdf-viewer-modal/pdfViewerModalSlice";

const APPROVE_STATUS_CODE = "money_approved";
const SCREEN_NAME_VIEW = "CM009";

const CM0010Handler = (
  CM0010Service: CM0010UseCase,
  CM006Service: CM006UseCase,
  CM008Service: CM008UseCase
) => {
  // LIB FUNCTION
  const dispatch = useDispatch();

  //state
  const [isOpenViewer, setIsOpenViewer] = useState<boolean>(false);
  const [pdfUrl, setPdfUrl] = useState<any>();
  const [pdfName, setPdfName] = useState<string>("");

  const [dataSummary, setDataSummary] = useState<any>();
  const [workflow, setWorkflow] = useState<ItemApprove[]>([]);
  const [currentUserApproval, setCurrentUserApproval] =
    useState<ItemApprove | null>(null);
  const [dataReceivedMoney, setReceivedMoney] = useState<DataTableOverview[]>([
    {
      key: "0",
      contractValue: "0円",
      paymentValue: "0円",
      paymentValuePercent: "0%",
      invoiceBalance: "0円",
    },
  ]);
  const [constructionInformationCost, setConstructionInformationCost] =
    useState<DataTableConstructionBudgetBalance[]>([]);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedList, setCheckedList] = useState<any[]>([]);
  const [
    dataTableConstructionContractBudget,
    setDataTableConstructionContractBudget,
  ] = useState<TableConstructionContractBudget[]>([]);

  const [dataTableSubmissionHistory, setDataTableSubmissionHistory] = useState<
    TableSubmissionHistory[]
  >([]);

  const [dataSummaryTitle, setDataSummaryTitle] = useState<any>({});

  //function
  const getWorkflowOutsourceApprovalProcess = async (documentId: number) => {
    try {
      dispatch(setLoading(true));
      const data = await CM0010Service.getWorkflowOutsourceApprovalProcess({
        documentId,
      });
      if (data.results.currentUserApproval)
        setCurrentUserApproval({
          approveDate: data.results.currentUserApproval.approveDate,
          contact_address: "",
          email: data.results.currentUserApproval.email,
          id: data.results.currentUserApproval.id,
          isApprove: data.results.currentUserApproval.approve,
          reject: data.results.currentUserApproval.reject,
          position: "",
          priority: data.results.currentUserApproval.priority,
          username: data.results.currentUserApproval.username,
          note: data.results.currentUserApproval.note,
          fullName: data.results.currentUserApproval.fullName,
        });
      const workflowData: ItemApprove[] = data.results.listApprover.map(
        (element) => {
          let note = element.note;
          if (element.id === data.results?.currentUserApproval?.id)
            note = data.results.currentUserApproval.note;
          const result: ItemApprove = {
            approveDate: element.approveDate,
            contact_address: "",
            email: element.email,
            id: element.id,
            isApprove: element.approve,
            reject: element.reject,
            position: "",
            priority: element.priority,
            username: element.username,
            note: note,
            fullName: element.fullName,
          };
          return result;
        }
      );
      setWorkflow(workflowData);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getListSubmittedDirectCostOutsource = async (
    documentSubmitId: number,
    documentOriginId: number
  ): Promise<any> => {
    try {
      const params = {
        documentSubmitId,
      };
      const res = await CM0010Service.getListSubmittedDirectCostOutsource(
        params
      );
      if (res?.results) {
        const data: TableSubmissionHistory[] =
          formatListSubmittedDirectCostOutsource(res.results);
        if (data) setDataTableSubmissionHistory(data);

        if (res?.results.length !== 0) {
          setDataSummaryTitle(res.results[0].dataSummary);
          getDataReceivedMoney(
            res.results[0].dataSummary?.construction?.id,
            documentSubmitId
          );
          getMoneyDirectCostOutsourceInformationList(
            {
              constructionId: res.results[0].dataSummary?.construction?.id,
              sortType: "ASC",
              sortBy: "createdAt",
            },
            documentOriginId,
            res.results
          );
          getDataConstructionInformationCost(
            res.results[0].dataSummary?.construction?.id
          );
        }
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };
  const getMoneyDirectCostOutsourceInformationList = async (
    params: any,
    documentOriginId: number,
    dataSummary: any[]
  ) => {
    try {
      dispatch(setLoading(true));
      const response =
        await CM0010Service.getMoneyDirectCostOutsourceInformationList(params);
      const results: any[] = [];
      if (response?.data?.results?.length > 0) {
        for await (const element of response?.data?.results) {
          if (documentOriginId === element.id) {
            const obj = {
              key: element.id,
              budgetAmount: element.planCost,
              orderAmount: element.orderMoney,
              actualCost: 0,
              budgetBalance: 0,
              budgetRate: 0,
            };
            obj.actualCost = dataSummary?.reduce((acc, cur) => {
              if (cur.actualCost || cur.actualCost === 0)
                return acc + cur.actualCost;
              return acc;
            }, 0);

            obj.budgetBalance = (element.planCost ?? 0) - obj.actualCost;
            if ((obj.actualCost || obj.actualCost === 0) && element.planCost) {
              obj.budgetRate =
                Math.round((obj.actualCost * 10000) / element.planCost) / 100;
            }

            results.push(obj);
          }
        }
      }
      setDataTableConstructionContractBudget(results);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const formatListSubmittedDirectCostOutsource = (data: any) => {
    const dataFormat: TableSubmissionHistory[] = data.map(
      (item: any, index: number) => {
        return {
          id: item.id,
          status: item.status,
          note: item?.note,
          key: item?.id,
          paymentDate: `${
            item?.paymentDate
              ? moment(item?.paymentDate).format(COMMON.FORMAT_DATE_OM)
              : ""
          }`,
          targetStage: `${
            item.startDate
              ? moment(item.startDate).format(COMMON.FORMAT_DATE_OM)
              : ""
          }～${
            item.endDate
              ? moment(item.endDate).format(COMMON.FORMAT_DATE_OM)
              : ""
          }`,
          payAmount: item?.planOutsourceCost,
          attendanceCalculation: item?.laborCost ?? 0,
          billAmount: item?.paymentCost,
          evidence: item?.evidence,
          actualCost: item?.actualCost,
          amountHold: item?.reserveCost,
          offsetAmount: item?.offsetCost,
          netPaymentAmount:
            item?.actualCost - item?.reserveCost - item?.offsetCost,
        };
      }
    );
    return dataFormat;
  };

  const handleCalculateSummary = (data: TableSubmissionHistory[]) => {
    let totalPayAmount = 0;
    let totalAttendanceCalculation = 0;
    let totalBillAmount = 0;
    let totalActualCost = 0;
    let totalAmountHold = 0;
    let totalOffsetAmount = 0;
    let totalNetPaymentAmount = 0;

    data.forEach((element: any) => {
      totalPayAmount += element.payAmount;
      totalAttendanceCalculation += element.attendanceCalculation;
      totalBillAmount += element.billAmount;
      totalActualCost += element.actualCost;
      totalAmountHold += element.amountHold;
      totalOffsetAmount += element.offsetAmount;
      totalNetPaymentAmount += element.netPaymentAmount;
    });

    setDataSummary({
      totalPayAmount,
      totalAttendanceCalculation,
      totalBillAmount,
      totalActualCost,
      totalAmountHold,
      totalOffsetAmount,
      totalNetPaymentAmount,
    });
  };

  const handleOpenViewer = async (record: any) => {
    try {
      dispatch(setLoading(true));
      const companyId = JSON.parse(localStorage.getItem("user") ?? `{}`)
        ?.company?.id;
      const response = await CM0010Service.getPresignLinkS3({
        keyName: `re/${companyId}/${SCREEN_NAME_VIEW}/${Number(record.key)}/${
          record.evidence
        }`,
      });
      const checkFile = record.evidence?.split(".").pop();
      const evidenceName = record.evidence?.split(".").slice(0, -1).join(".");

      setPdfName(evidenceName);
      setPdfUrl(response.results);

      if (checkFile === "pdf") dispatch(showPdfViewerModal());
      else dispatch(showImageViewerModal());
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const onCancel = () => {
    dispatch(resetCM0010());
  };

  const retrieveDocument = (documentId: number) => {
    ConfirmModal({
      onOk: async () => {
        const response = await CM0010Service.retrieveOutsourceDocument({
          documentId: documentId,
          listDocumentDirectOutsourceCostInformationId: checkedList,
        });

        if (response.status === `${COMMON.HTTP_STATUS_OK}`) {
          dispatch(resetCM0010());
          dispatch(setRefresh());
        }
        SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
      },
      className: "confirm__modal",
      title: MESSAGE.MESSAGE_TITLE_CM016,
      description: MESSAGE.MESSAGE_DESCRIPTION_CR_2,
      extraDescription: MESSAGE.MESSAGE_DESCRIPTION_COMMON_QUESTION_1,
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_MODAL,
      isCenterWithoutMenu: true,
    });
  };

  const getDataReceivedMoney = async (
    constructionId: number,
    documentId: number
  ) => {
    const responseA = await CM006Service.getConstructionInformation({
      constructionId: constructionId,
      documentId: documentId,
    });

    const responseB = await CM006Service.getListConstructionReceivedMoney({
      constructionId: constructionId,
    });

    setReceivedMoney(convertDataOverview(responseA.results, responseB.results));
  };

  const getDataConstructionInformationCost = async (constructionId: number) => {
    const response = await CM008Service.getConstructionSummaryCostInformation({
      constructionId: constructionId,
    });

    setConstructionInformationCost(
      convertDataTableInformationCost([response.results])
    );
  };
  const onCheckAllChange = (checked: boolean) => {
    setCheckAll(checked);
    if (checked) {
      const checkedItems: any[] = [];
      for (let element of dataTableSubmissionHistory) {
        checkedItems.push(element.id);
      }
      setCheckedList(checkedItems);
    } else setCheckedList([]);
  };

  const isCheckbox = (record: any): boolean =>
    checkedList.some((element) => element === record.key);

  const onCheckboxChange = (record: any, checked: boolean) => {
    // setCheckedList(list);
    let newCheckList: any[] = [];

    if (checked) {
      newCheckList = [...checkedList, record.key];
    } else {
      const checkedData = checkedList.filter(
        (element: any) => element !== record.key
      );
      newCheckList = checkedData;
    }
    const possibleItems: any[] = [];

    for (let element of dataTableSubmissionHistory) {
      if (
        [
          STATUS_CM_CHILD_TABLE.MONEY_NOT_APPROVED.CODE,
          STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE,
        ].includes(element?.status?.code)
      )
        possibleItems.push(element.id);
    }
    setCheckedList(newCheckList);
    setCheckAll(newCheckList.length === possibleItems.length);
  };

  const viewApproveModal = (record: any) => {
    dispatch(setDocumentView(record));
  };

  const showConfirmApproveModal = () => {
    dispatch(showModalApproveConfirm());
  };
  return {
    dataTableConstructionContractBudget,
    dataTableSubmissionHistory,
    isOpenViewer,
    pdfUrl,
    dataSummary,
    dataReceivedMoney,
    constructionInformationCost,
    dataSummaryTitle,
    workflow,
    currentUserApproval,
    checkAll,
    checkedList,
    pdfName,
    handleOpenViewer,
    handleCalculateSummary,
    onCancel,
    getListSubmittedDirectCostOutsource,
    retrieveDocument,
    getWorkflowOutsourceApprovalProcess,
    onCheckAllChange,
    isCheckbox,
    onCheckboxChange,
    viewApproveModal,
    showConfirmApproveModal,
  };
};

export default CM0010Handler;
