import helpers from '../../../../common/helpers/common';
import { MoneySummaryPlanCostResponse } from '../entity/Entity';
import { PlannedPaidAmountAPIImpl } from './ServiceImpl';

const PlannedPaidAmountService = (api: PlannedPaidAmountAPIImpl) => {
    const getMoneySummaryPlanCost = async (params: {
        constructionId: number;
        from: string;
        to: string;
    }): Promise<MoneySummaryPlanCostResponse> => {
        try {
            const response = await api.getMoneySummaryPlanCost({
                constructionId: params.constructionId,
                from: helpers.getFilterTime(params.from),
                to: helpers.getFilterTimeTo(params.to),
            });
            const { results } = response.data;
            return results;
        } catch (error: any) {
            throw error;
        }
    };
    return { getMoneySummaryPlanCost };
};

export default PlannedPaidAmountService;
