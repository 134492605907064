interface props {
  tab: string;
}

const HeaderMemberRowComponent = ({ tab }: props) => {
  const constructionProjectTitle = (
    <>
      <div style={{ width: "25%" }}>案件名</div>
      <div style={{ width: "25%" }}>工事名</div>
    </>
  );
  const roleDepartment = (
    <>
      <div style={{ width: "25%" }}>役職</div>
      <div style={{ width: "25%" }}>部門</div>
    </>
  );
  return (
    <main
      className="table-cell-inside"
      style={{
        display: "flex",
        justifyContent: "space-between",
        paddingRight: 52,
      }}
    >
      <div style={{ display: "flex", width: "25%" }}>
        <span style={{ minWidth: 40 }}>No</span>
        <span style={{ marginLeft: 24 }}>名前</span>
      </div>
      {tab === "1" ? constructionProjectTitle : roleDepartment}
      <div style={{ width: "25%" }}>出勤日数</div>
    </main>
  );
};

export default HeaderMemberRowComponent;
