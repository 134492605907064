import { useState } from "react";
import {
  AM006ServiceImpl,
  AttendanceServiceImpl,
} from "./../../usecase/ServiceImpl";
import { useSearchParams } from "react-router-dom";
import { Form } from "antd";
import moment from "moment";
import COMMON from "../../../../../common/constants/COMMON";
import { useDispatch, useSelector } from "react-redux";
import { setTableColumns } from "../slice/Slice";
import {
  calculateCalendarTableHeader,
  getTitleByFromToDate,
} from "../../../AM008/helper";
import { RangeDateType } from "../../../AM002/entity/Entity";
import { MemberType } from "../../entity/Entity";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import { exportCompany } from "../../../../../common/helpers/exports/company";
import { ExportDataCompany } from "../../../../../common/entity/Entity";
import { RootState } from "../../../../../store";
import { convertDataForExport } from "../../helper";
import helpers from "../../../../../common/helpers/common";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../../common/constants/MESSAGE";
import { doExportForResponse } from "../../../../../common/helpers/exports/common";

const SCREEN_NAME = "会社詳細勤務実績";

const AM006Handler = (
  service: AM006ServiceImpl,
  attendanceService: AttendanceServiceImpl
) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const tableColumns = useSelector(
    (state: RootState) => state.am006.tableColumns
  );

  // PARAM
  const [searchParams, setSearchParams] = useSearchParams();
  const pageDate = searchParams.get("pageDate") || COMMON.DEFAULT_PAGE + "";
  const fromDate =
    searchParams.get("startDate") ||
    moment().startOf("month").format(COMMON.FORMAT_DATE2);
  const toDate =
    searchParams.get("endDate") || moment().format(COMMON.FORMAT_DATE2);

  // STATE
  const [totalRecord, setTotalRecord] = useState<number>(0);
  const [members, setMembers] = useState<MemberType[]>([]);
  const [fullMembers, setFullMembers] = useState<MemberType[]>([]);

  const [rangeDate, setRangeDate] = useState<RangeDateType>({
    from: moment().startOf("month"),
    to: moment(),
  });
  const [expandKey, setExpandKey] = useState<any[]>([]);

  // FUNCTION
  const onFromDateChange = (value: any) => {
    setRangeDate({ ...rangeDate, from: value });
  };
  const onToDateChange = (value: any) => {
    setRangeDate({ ...rangeDate, to: value });
  };
  const isCollapsed = (record: any) => {
    return expandKey.some((element) => element === record.key);
  };

  const onChangeCollapseRow = (record: any, collapsed: boolean) => {
    const collapsedRow = collapsed
      ? [...expandKey, record.key]
      : expandKey.filter((element) => element !== record.key);

    setExpandKey(collapsedRow);
  };
  const onPagingMember = (page: string, size: string, keyword: string) => {
    const searchMembers = fullMembers.filter((element) =>
      helpers
        .toASCII(element.fullName || "")
        .toUpperCase()
        .includes(helpers.toASCII(keyword || "").toUpperCase())
    );
    setMembers(searchMembers);
  };

  const getAttendanceConstructionListMyMember = async (params: {
    keyword: string;
    constructionId: number;
    from: string;
    to: string;
  }): Promise<any> => {
    try {
      dispatch(setLoading(true));
      const { results } = await service.getAttendanceConstructionListMyMember({
        constructionId: params.constructionId,
        from: params.from,
        to: params.to,
      });
      const { data } = results;
      if (data && Array.isArray(data)) {
        for (let i = 0; i < data.length; i++) {
          const attendanceParams = {
            constructionId: params.constructionId,
            userId: data[i].id,
            from: params.from,
            to: params.to,
          };
          const attendanceResult =
            await getAttendanceConstructionMyMemberTimeSheet(attendanceParams);
          if (attendanceResult && Array.isArray(attendanceResult.timesheet)) {
            for (let k = 0; k < attendanceResult.timesheet.length; k++) {
              if (attendanceResult.timesheet[k].isMultipleCheckInOut) {
                const multipleCheckInOutData =
                  await service.getAttendanceUserTimeSheet({
                    date: moment(attendanceResult.timesheet[k].date).format(
                      COMMON.FORMAT_DATE2
                    ),
                    contractorConstructionId: params.constructionId + "",
                    constructionId: params.constructionId + "",
                    userId: data[i].id,
                  });
                if (multipleCheckInOutData)
                  attendanceResult.timesheet[k].multipleCheckInOutData =
                    multipleCheckInOutData.map((element) => ({ ...element }));
              }
            }

            data[i].attendance = attendanceResult;
          }
        }
        // keyword
        const filterMembers = data.filter((element: any) =>
          helpers
            .toASCII(element.fullName || "")
            .toUpperCase()
            .includes(helpers.toASCII(params.keyword || "").toUpperCase())
        );

        // set state
        setMembers(filterMembers);
        setFullMembers(data);
        setTotalRecord(data.length);
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };
  const getAttendanceConstructionMyMemberTimeSheet = async (params: {
    constructionId: number;
    userId: number;
    from: string;
    to: string;
  }): Promise<any> => {
    const { results } =
      await attendanceService.getAttendanceConstructionMyMemberTimeSheet(
        params
      );
    return results;
  };

  const handleLoadingExport = (value: boolean) => {
    dispatch(setLoading(value));
  };

  const exportData = async (params: any) => {
    const user = localStorage.getItem("user");
    let companyName: string = "";
    if (user) {
      companyName = JSON.parse(user)?.company?.name;
    }

    const rangeDate: string = getTitleByFromToDate(fromDate, toDate);

    let date_header: string[] = tableColumns?.columns.map(
      (item: any) => item?.name
    );

    const constructionName: string = searchParams.get("constructionName") ?? "";
    const projectName: string = searchParams.get("title") ?? "";

    const dataExport: ExportDataCompany = {
      title: [
        `${projectName}/${constructionName}/自社`,
        `期間： ${rangeDate}`,
        `人数：${totalRecord}人`,
      ],
      date_header: date_header,
      content: convertDataForExport(members),
    };

    // exportCompany(
    //   dataExport,
    //   `${SCREEN_NAME}_${moment().format(COMMON.FORMAT_DATE)}`,
    //   handleLoadingExport
    // );
    try {
      dispatch(setLoading(true));
      const response = await service.doExportAM006({
        ...params,
        from: helpers.getFilterTime(params.from),
        to: helpers.getFilterTimeTo(params.to),
      } as any);
      doExportForResponse(
        response,
        `${SCREEN_NAME}_${companyName}_${projectName}_${constructionName}_${moment().format(COMMON.FORMAT_DATE7)}.xlsx`
      );
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const initiateFilter = () => {
    form.setFieldValue("text", searchParams.get("keyword") || "");

    let fromMoment: moment.Moment = moment().startOf("month");
    if (fromDate === null)
      form.setFieldValue("startDate", moment().startOf("month"));
    else {
      form.setFieldValue("startDate", moment(fromDate));
      fromMoment = moment(fromDate);
    }

    form.setFieldValue("endDate", moment(toDate));
    setRangeDate({
      to: moment(toDate),
      from: fromMoment,
    });
  };

  var myVar: any;
  const onSearch = () => {
    if (myVar) clearTimeout(myVar);
    myVar = setTimeout(function () {
      const searchText = form.getFieldValue("text");
      form.setFieldValue("text", searchText.trim());
      searchParams.set("keyword", searchText.trim());
      searchParams.set("page", COMMON.DEFAULT_PAGE + "");
      setSearchParams(searchParams);
    }, 1000);
  };

  const onFilter = () => {
    searchParams.set("pageDate", COMMON.DEFAULT_PAGE + "");
    const startDate = form.getFieldValue("startDate");
    if (startDate)
      searchParams.set(
        "startDate",
        moment(startDate).format(COMMON.FORMAT_DATE2)
      );
    else searchParams.set("startDate", "");

    const endDate = form.getFieldValue("endDate");
    searchParams.set("endDate", moment(endDate).format(COMMON.FORMAT_DATE2));
    setSearchParams(searchParams);
  };

  const calculateTableHeader = (
    startDate: string | null,
    endDate: string | null
  ) => {
    const result = calculateCalendarTableHeader(startDate, endDate, pageDate);
    dispatch(setTableColumns(result));
    return result;
  };

  const onResetFilter = () => {
    const initialStart = moment().startOf("month");
    const initialEnd = moment();

    form.setFields([
      {
        name: "startDate",
        value: initialStart,
      },
      {
        name: "endDate",
        value: initialEnd,
      },
    ]);
    searchParams.set("pageDate", COMMON.DEFAULT_PAGE + "");
    searchParams.set("startDate", initialStart.format(COMMON.FORMAT_DATE2));
    searchParams.set("endDate", initialEnd.format(COMMON.FORMAT_DATE2));
    setSearchParams(searchParams);
  };

  const onCollapse = (value: boolean) => {
    searchParams.set("isCollapsed", value ? "isCollapsed" : "");
    setSearchParams(searchParams);
  };

  const onNext = () => {
    const pageInt = parseInt(pageDate);
    searchParams.set("pageDate", pageInt + 1 + "");
    setSearchParams(searchParams);
  };

  const onPrev = () => {
    const pageInt = parseInt(pageDate);
    searchParams.set("pageDate", (pageInt - 1 <= 0 ? 1 : pageInt - 1) + "");
    setSearchParams(searchParams);
  };

  return {
    members,
    form,
    totalRecord,
    rangeDate,
    expandKey,
    onResetFilter,
    onFilter,
    onSearch,
    getAttendanceConstructionListMyMember,
    exportData,
    onCollapse,
    initiateFilter,
    onNext,
    onPrev,
    calculateTableHeader,
    onFromDateChange,
    onToDateChange,
    onPagingMember,
    isCollapsed,
    onChangeCollapseRow,
  };
};

export default AM006Handler;
