import React from 'react';
import CM0047Api from './infrastructure/api/Api';
import CM0047Handler from './presenter/handler/Handler';
import CM0047Service from './usecase/Service';
import MainUI from './presenter/ui/UI';

import '../../../../assets/styles/CM/cm047.css';

const CM047Component = () => {
    const api = CM0047Api();
    const service = CM0047Service(api);
    const handler = CM0047Handler(service);
    return <MainUI handler={handler} />;
};

export default CM047Component;
