import { PM009UseCase } from '../../usecase/ServiceImpl';
import { useDispatch, useSelector } from 'react-redux';
import SuccessNotification from '../../../../../common/components/notification/SuccessNotification';
import ErrorNotification from '../../../../../common/components/notification/ErrorNotification';

import { Form } from 'antd';
import { useState } from 'react';

import MESSAGE, {
    LABEL_MESSAGE,
    NOTIFICATION_TITLE,
} from '../../../../../common/constants/MESSAGE';
import { RootState } from '../../../../../store';
import { changeRefreshNoti, changeViewNoti } from '../slice/Slice';
import COMMON, { PLAN_TYPE } from '../../../../../common/constants/COMMON';
import ConfirmModalAsync from '../../../../../common/components/modal/ConfirmModalAsync';
import { isHasPrivilegeByPermission } from '../../../../../common/helpers/privilege';
import { AUTHORIZATION_CODE } from '../../../../../common/constants/AUTHORIZATION';

interface CategoryNotification {
    category: {
        code: string;
        description: string;
    };
    count: number;
}

interface Notification {
    id: number;
    title: string;
    body: string;
    isRead: boolean;
    createdAt: string;
    updatedAt: string;
}
const DEFAULT_TYPE = {
    code: '',
    description: '',
    id: 0,
};

const PM009Handler = (PM009Service: PM009UseCase) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    //notification
    const [isDetailNotification, setIsDetailNotification] = useState<boolean>(false);
    const [formNoti] = Form.useForm();
    const [notifications, setNotifications] = useState<Notification[] | []>([]);
    const [categoryNotification, setCategoryNotification] = useState<CategoryNotification[] | []>(
        [],
    );
    const [pageNoti, setPageNoti] = useState(COMMON.DEFAULT_PAGE);
    const [sizeNoti, setSizeNoti] = useState(COMMON.DEFAULT_SIZE);
    const [keywordNoti, seKeywordNoti] = useState('');
    const [typeNoti, setTypeNoti] = useState(DEFAULT_TYPE);
    const [totalNoti, setTotalNoti] = useState(0);
    const [loadingNotification, setLoadingNotification] = useState(false);

    const isViewNotifi = useSelector((state: RootState) => state.pm009.isViewNotifi);
    const projectNoti = useSelector((state: RootState) => state.pm009.projectNoti);
    const refreshNoti = useSelector((state: RootState) => state.pm009.refreshNoti);
    const isRoleAdminBasic = useSelector(
        (state: RootState) => state.auth?.authData?.isRoleAdminBasic
    );

    const isUserIsProjectManager = useSelector(
        (state: RootState) => state.auth.authData?.isUserIsProjectManager
    );

    const subscriptions =
        useSelector(
            (state: RootState) => state.auth?.authData?.order?.subscription
        ) ?? [];

    const checkResult = (isRoleAdminBasic && subscriptions.length > 0) ||
        (isUserIsProjectManager && subscriptions.length > 0) ||
        isHasPrivilegeByPermission([
            AUTHORIZATION_CODE.S28,
            AUTHORIZATION_CODE.S29,
            AUTHORIZATION_CODE.S30,
            AUTHORIZATION_CODE.S301,
            AUTHORIZATION_CODE.S31,
            AUTHORIZATION_CODE.S42,
            AUTHORIZATION_CODE.S48,
            AUTHORIZATION_CODE.S49,
        ])

    //Handle Notification
    const getCategoryNotification = async (params: { projectId: number }) => {
        setLoadingNotification(true);
        try {
            const response: any = await PM009Service.getCategoryNotification(params);
            const result = response.data.results.filter((item: any) => item.category.code != "notifi_re_document")

            setCategoryNotification(checkResult ? response.data.results : result);
            setLoadingNotification(false);
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            setCategoryNotification([]);
            setLoadingNotification(false);
        }
    };
    const getListNotification = async (params: any) => {
        try {
            const response: any = await PM009Service.getListNotification(params);
            if (params.page === 1) {
                setNotifications(handleDuplicateData(response.data.results));
                setTotalNoti(response.data.pagination.count);
            } else {
                setNotifications(handleDuplicateData([...notifications, ...response.data.results]));
            }
            setLoadingNotification(false);
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        }
    };
    const handleDuplicateData = (data: { id: number }[]) => {
        if (!data) return [];
        const check: { [keyword: number]: any } = {};
        const results: any[] = [];
        for (const item of data) {
            if (!(item.id in check)) {
                results.push(item);
                check[item.id] = item;
            }
        }
        return results;
    };

    const readNotification = async (data: any) => {
        try {
            await PM009Service.readNotification(data);
            dispatch(changeRefreshNoti());
            const newNotification = notifications?.map((notification) => {
                const objNoti = {
                    ...notification,
                };
                if (data?.listNotiId[0] === notification?.id) objNoti.isRead = true;
                return objNoti;
            });
            setNotifications(newNotification);
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        }
    };

    const deleteNotification = async (data: any) => {
        setLoadingNotification(true);
        try {
            await PM009Service.deleteNotification(data);
            dispatch(changeRefreshNoti());
            setLoadingNotification(false);
            SuccessNotification(NOTIFICATION_TITLE.MESSAGE_DELETE_NOTIFICATION_SUCCESS);
            const newNotification = notifications?.filter(
                (notification) => notification?.id !== data?.listNotiId[0],
            );
            setNotifications(newNotification);
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            setLoadingNotification(false);
        }
    };

    const handleChangePageNoti = (page: number) => {
        setPageNoti(page);
    };
    const handleChangePageSizeNoti = (size: number) => {
        setSizeNoti(size);
    };

    const handleClosePM009 = () => {
        dispatch(changeViewNoti(false));
        setIsDetailNotification(false);
        setPageNoti(1);
        setTypeNoti({
            description: '',
            id: 0,
            code: '',
        });
        setNotifications([]);
        setCategoryNotification([]);
    };

    const handleViewDetailNotification = (category: {
        code: string;
        description: string;
        id: number;
    }) => {
        setIsDetailNotification(true);
        setTypeNoti(category);
    };

    const handleLongText = (text: string, length: number) => {
        if (!text)
            return {
                isLong: false,
                text: '',
            };
        if (text?.length > length) {
            return { isLong: true, text: `${text.slice(0, length)}...` };
        }
        return {
            isLong: false,
            text: text,
        };
    };

    const handleScrollNotification = () => {
        setPageNoti(pageNoti + 1);
    };

    const handleReadNotification = (data: any) => {
        readNotification({
            listNotiId: [data],
        });
    };

    const handleDeleteNotification = (data: any) => {
        deleteNotification({
            listNotiId: [data?.id],
        });
    };
    const handleClickBack = () => {
        setIsDetailNotification(false);
        setTypeNoti(DEFAULT_TYPE);
        setPageNoti(COMMON.DEFAULT_PAGE);
        setSizeNoti(COMMON.DEFAULT_SIZE);
        setNotifications([]);
    };
    return {
        handleClosePM009,
        setIsDetailNotification,
        handleViewDetailNotification,
        getCategoryNotification,
        getListNotification,
        handleChangePageNoti,
        handleChangePageSizeNoti,
        handleLongText,
        handleScrollNotification,
        handleReadNotification,
        handleDeleteNotification,
        handleClickBack,

        isViewNotifi,
        isDetailNotification,
        form,
        totalNoti,
        pageNoti,
        sizeNoti,
        keywordNoti,
        notifications,
        categoryNotification,
        formNoti,
        projectNoti,
        typeNoti,
        loadingNotification,
        refreshNoti,
    };
};
export default PM009Handler;
