import ErrorNotification from "../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../common/constants/MESSAGE";
import {
  WorkflowCreatePayload,
  WorkflowDeletePayload,
  WorkflowUpdatePayload,
} from "../entity/Entity";
import { SW02APIImpl } from "./ServiceImpl";

const SW02Service = (api: SW02APIImpl) => {
  const getWorkflowList = async (params: { categoryId?: number }) => {
    try {
      const response = await api.getWorkflowList(params);
      return response?.data;
    } catch (error: any) {
      ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
      return null;
    }
  };
  const getWorkIndexName = async (): Promise<number> => {
    try {
      const response = await api.getWorkIndexName();
      return response?.data?.results ?? 0;
    } catch (error: any) {
      ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
      return 0;
    }
  };

  const getWorkflowDetail = async (params: { workflowId: number }) => {
    try {
      const response = await api.getWorkflowDetail(params);
      return response?.data;
    } catch (error: any) {
      ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
      return null;
    }
  };

  const postWorkflowCreate = async (payload: WorkflowCreatePayload) => {
    try {
      const response = await api.postWorkflowCreate(payload);
      return response?.data;
    } catch (error: any) {
      ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
      return null;
    }
  };

  const putWorkflowUpdate = async (payload: WorkflowUpdatePayload) => {
    try {
      const response = await api.putWorkflowUpdate(payload);
      return response?.data;
    } catch (error: any) {
      ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
      return null;
    }
  };

  const deleteWorkflow = async (payload: WorkflowDeletePayload) => {
    try {
      const response = await api.deleteWorkflow(payload);
      return response?.data;
    } catch (error: any) {
      ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
      return null;
    }
  };
  const getGroupListMember = async (params: {
    page: number;
    size: number;
    keyword?: string;
    sortBy?: string;
    sortType?: string;
  }) => {
    try {
      const response = await api.getGroupListMember(params);
      return response?.data;
    } catch (error: any) {
      ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
      return null;
    }
  };
  return {
    getWorkflowList,
    getWorkflowDetail,
    postWorkflowCreate,
    putWorkflowUpdate,
    deleteWorkflow,
    getGroupListMember,
    getWorkIndexName,
  };
};

export default SW02Service;
