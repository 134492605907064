import { Form, Tabs, Input } from 'antd';
import { useEffect } from 'react';
import { AM028HandlerUI } from '../handler/HandlerImpl';
import BreadcrumbComponent from '../../../../../common/components/breadcrumb/BreadcrumbComponent';
import BREADCRUMB from '../../../../../common/constants/BREADCRUMB';
import '../../../../../assets/styles/AM/AM028.css';
import Body from './Body';
import helpers from '../../../../../common/helpers/common';

import IconSearch from '../../../../../assets/icons/search-icon.svg';
import MenuWrapperComponent from '../../../../../common/components/menu-wrapper';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { AUTHORIZATION_CODE, SYSTEM_ROLES } from '../../../../../common/constants/AUTHORIZATION';

const { TabPane } = Tabs;

interface props {
    handler: AM028HandlerUI;
}

const AM028UI = ({ handler }: props) => {
    const permission = useSelector((state: RootState) => state.auth.authData?.permission);
    const isRoleAdmin =
        SYSTEM_ROLES.ADMIN === useSelector((state: RootState) => state.auth.authData?.role?.code) ||
        SYSTEM_ROLES.ADMIN_SYSTEM ===
        useSelector((state: RootState) => state.auth.authData?.role?.code);

    useEffect(() => {
        handler.handleGetData();
    }, [
        JSON.stringify(handler.page),
        JSON.stringify(handler.size),
        JSON.stringify(handler.keyword),
        handler.currentTab,
        handler.menuChosen,
        JSON.stringify(handler.sortBy),
        JSON.stringify(handler.sortType),
        JSON.stringify(handler.from),
        JSON.stringify(handler.to),
        JSON.stringify(handler.status),
        JSON.stringify(handler.clear),
        JSON.stringify(handler.category),
        handler.refresh,
    ]);

    useEffect(() => {
        Promise.all([
            handler.getOrganizationIsUserApprover(),
            handler.getListStatus({}),
            handler.funcSetDefaultFilter(),
        ])
    }, []);

    useEffect(() => {
        handler.getCategory({});
    }, [handler.menuChosen]);

    useEffect(() => {
        const i = handler.funcCheckIndex(handler.currentTab, handler.menuChosen);
        if (handler.keyword[i]) handler.formFilter.setFieldValue('keyword', handler.keyword[i]);
    }, []);

    return (
        <MenuWrapperComponent chosenKey={26} openKey={2}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 32
                }}
            >
                <div className="om002-header-container">
                    <BreadcrumbComponent data={BREADCRUMB.AM028} />
                    <div className="header-row-title" style={{ marginTop: 8 }}>
                        申請一覧
                    </div>
                </div>
                <div className="search-project"    >
                    <div >
                        {handler.currentTab === '2' ? (
                            <Form form={handler.formFilter}
                                style={{ margin: 0, padding: 0, }}
                            >
                                <Form.Item name="keyword" style={{ margin: 0, padding: 0, }}>
                                    <Input
                                        onBlur={() =>
                                            helpers.onBlurTrimFormItem(handler.formFilter, 'keyword')
                                        }
                                        onChange={handler.handleSearch}
                                        prefix={<img alt="" src={IconSearch} />}
                                        size="large"
                                        placeholder="検索したい名前を入力してください"  // CR #344212
                                        className="am028-search-input"
                                    />
                                </Form.Item>
                            </Form>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>
            <Tabs
                type="card"
                onChange={handler.handleChangeCurrentTab}
                activeKey={handler.currentTab}
                className="am028"
            >
                <TabPane tab="個人申請" key="1">
                    <Body handler={handler} />
                </TabPane>
                {isRoleAdmin || handler.isUserApprover ? (
                    <TabPane tab="申請全般" key="2">
                        <Body handler={handler} />
                    </TabPane>
                ) : null}
            </Tabs>
        </MenuWrapperComponent>
    );
};

export default AM028UI;
