import { CM0028Hanlder } from '../handler/HandlerImpl';
import Table from './Table';
import Header from './Header';
import CM0029Component from '../../../CM0029';

import { RootState } from '../../../../../store';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import helpers from '../../../../../common/helpers/common';
import ModalApprove from '../../../../../common/components/approval-flow/ModalApprove';
import MESSAGE from '../../../../../common/constants/MESSAGE';

interface props {
    handler: CM0028Hanlder;
}

const MainUI = ({ handler }: props) => {
    const visibleCM0029 = useSelector((state: RootState) => state.cm0029.isVisibleCM0029);
    useEffect(() => {
        if (
            !handler.openFilter &&
            (helpers.getFilterTimeMonth(handler.from) !== handler.filterCurrent.from ||
                helpers.getFilterTimeMonthTo(handler.to) !== handler.filterCurrent.to)
        ) {
            Promise.all([
                handler.getProjectAlls({
                    from: handler.clear ? '' : helpers.getFilterTimeMonth(handler.from),
                    to: handler.clear ? '' : helpers.getFilterTimeMonthTo(handler.to),
                }),
                handler.getReSummaryProjectLaborCostTotal({
                    from: handler.clear ? '' : helpers.getFilterTimeMonth(handler.from),
                    to: handler.clear ? '' : helpers.getFilterTimeMonthTo(handler.to),
                })
            ])
        }
    }, [handler.from, handler.to, handler.openFilter]);

    useEffect(() => {
        handler.initialFilter();
    }, []);

    useEffect(() => {
        if (!handler.openFilter) {
            handler.getLaborCostDocumentStatus({
                from: helpers.getFilterTimeMonth(handler.from),
                to: helpers.getFilterTimeMonthTo(handler.to),
            });
        }
    }, [handler.clear, handler.from, handler.to, handler.refreshStatus, handler.openFilter]);

    return (
        <div className="cm024">
            <Header handler={handler} />
            <Table
                summaryTotal={handler.summaryTotal}
                handleScroll={(e) =>
                    handler.handleScroll(
                        e,
                        helpers.getFilterTimeMonth(handler.from),
                        helpers.getFilterTimeMonthTo(handler.to),
                    )
                }
                projects={handler.projects}
                checkKeyExpand={handler.funcCheckKeyExpand}
                setExpandKeys={handler.funcSetExpendKey}
                from={helpers.getFilterTimeMonth(handler.from)}
                to={helpers.getFilterTimeMonthTo(handler.to)}
            />
            {visibleCM0029 ? <CM0029Component /> : null}
        </div>
    );
};

export default MainUI;
