import AM005Api from "./infrastructure/api/Api";
import AM005Handler from "./presenter/handler/Handler";
import MainUI from "./presenter/ui/UI";
import AM005Service from "./usecase/Service";

import "../../../assets/styles/AM/AM005.css";
import "../../../assets/styles/AM/AM002.css";
import "../../../assets/styles/OM/OM005.css";
import "../../../assets/styles/PM/PM011.css";

const AM005Component = () => {
  const api = AM005Api();
  const service = AM005Service(api);
  const handler = AM005Handler(service);
  return <MainUI handler={handler} />;
};

export default AM005Component;
