import { RequestUploadCCUSCompany } from '../entity/Entity';
import { CCUS01APIImpl } from './ServiceImpl';
const CCUS001Service = (api: CCUS01APIImpl) => {
    const uploadCCUSCompany= async (data:RequestUploadCCUSCompany): Promise<any> => {
        try{
         return await api.uploadCCUSCompany(data);
        }catch(error){
         throw error;
        }
       };

       const getStateCCUSCompany = async (): Promise<any> => {
        const response = await api.getStateCCUSCompany();
        return response
      };

    return {
        uploadCCUSCompany,
        getStateCCUSCompany
    };
};


export default CCUS001Service;
