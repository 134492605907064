import axiosConfig from '../../../../../config/axiosConfig';

const T01Api = () => {
  const getTaxSetting = async (params: any): Promise<any> => {
    const url = '/tax-setting';
    return await axiosConfig.get(url, { params });
  };

  const updateTaxSetting = async (data: any): Promise<any> => {
    const url = '/tax-setting/update';
    return await axiosConfig.put(url, data);
  };

  return {
    getTaxSetting,
    updateTaxSetting,
  };
};

export default T01Api;
