import { ChangeForgotPasswordUseCase } from "./../../../AS-Change-Forgot-Password/usecase/ServiceImpl";
import { useNavigate } from "react-router-dom";
import { Form } from "antd";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import { ForgotBody } from "../../entity/Entity";
import { ASForgotPasswordUseCase } from "../../usecase/ServiceImpl";
import { ForgotPasswordType } from "../type/TypePresenter";
import COMMON from "../../../../../common/constants/COMMON";

const ASForgotPasswordHandler = (
  asForgotPasswordService: ASForgotPasswordUseCase,
  asChangeForgotPasswrodService: ChangeForgotPasswordUseCase
) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const forgot = async (formData: ForgotPasswordType) => {
    try {
      dispatch(setLoading(true));

      const bodyData: ForgotBody = {
        email: formData.email,
      };
      await asForgotPasswordService.forgot(bodyData);
      navigate("/app-forgot-change-password");
    } catch (error: any) {
      let errorMessage = error?.message ?? "";
      form.setFields([
        {
          name: "email",
          errors: [errorMessage],
        },
      ]);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const backLogin = () => {
    localStorage.removeItem("timerOTP");
    navigate("/app-login");
  };

  return {
    form,
    forgot,
    backLogin,
  };
};

export default ASForgotPasswordHandler;
