import axiosConfig from "../../../../../config/axiosConfig";
import { RetrieveReceiveMoney } from "../../entity/Entity";

const CM006Api = () => {
  const getListSubmittedReceiveMoney = async (params: any): Promise<any> => {
    try {
      const url = "/re/submitted/receive-money/list";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getConstructionInformation = async (params: any): Promise<any> => {
    try {
      const url = "/money/construction/information";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getListConstructionReceivedMoney = async (
    params: any
  ): Promise<any> => {
    try {
      const url = "/money/received-money/list";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const retrieveReceiveMoney = async (
    data: RetrieveReceiveMoney
  ): Promise<any> => {
    try {
      const url = "/money/retrieve/receive-money";
      const response = await axiosConfig.put(url, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getPresignLinkS3 = async (params: any): Promise<any> => {
    const url = "/project/presign-link";
    return await axiosConfig.get(url, { params });
  };

  const getWorkflowReceiveMoneyApprovalProcess = async (params: {
    documentId: number;
  }): Promise<any> => {
    const url = "/workflow/receive-money-approval-process";
    return await axiosConfig.get(url, { params });
  };

  return {
    getListSubmittedReceiveMoney,
    getConstructionInformation,
    getListConstructionReceivedMoney,
    retrieveReceiveMoney,
    getPresignLinkS3,
    getWorkflowReceiveMoneyApprovalProcess,
  };
};

export default CM006Api;
