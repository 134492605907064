import { IC1SliceType } from './../type/Presenter';
import { createSlice } from '@reduxjs/toolkit';

const initialState: IC1SliceType = {
    isRefresh: false,
    profileCompany: {
        address: "",
        adminFullName: "",
        adminName: "",
        ccusExpiredDate: "",
        ccusId: "",
        createdAt: "",
        email: "",
        fax: "",
        id: 0,
        name: "",
        phoneNumber: "",
        postNumber: "",
        updatedAt: "",
        state: 0
    }
};

export const ic1Slice = createSlice({
    name: 'ic1',
    initialState,
    reducers: {
        resetIC1: () => initialState,
        setProfileCompany: (state, action) => {
            state.profileCompany = action.payload;
        },
    },
});

export const { resetIC1, setProfileCompany } = ic1Slice.actions;

export default ic1Slice.reducer;
