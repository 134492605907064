import React from "react";
import { Button, Pagination } from "antd";
import "../../../assets/styles/components/pagination.css";
import ArrowRight from "../../../assets/icons/icon-arrow-right.svg";
import ArrowLeft from "../../../assets/icons/icon-arrow-left.svg";
import { memo, useMemo } from "react";

type Props = {
  total: number;
  page: number;
  size: number;
  setPage: (value: number) => void;
  disabled?: boolean;
  rangeLabel?: string;
  paddingProps?: number;
  marginTopProps?: number;
};

const PaginationComponentDB = ({
  total,
  page,
  size,
  setPage,
  disabled,
  paddingProps,
  marginTopProps,
}: Props) => {
  const lastPage = useMemo(() => {
    const result = Math.floor(total / size);
    if (result < 1) return 1;
    if (total % size > 0) return result + 1;
    return result;
  }, [total, size]);

  return (
    <div
      className="pagination-component-list"
      style={{ padding: paddingProps, marginTop: marginTopProps }}
    >
      <div className="pagination-container-db  w-100-percent">
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            disabled={page === 1 || (disabled ?? false)}
            onClick={() => setPage(page - 1)}
            style={{
              width: 38,
              height: 38,
              borderRadius: "3px",
              border: "solid 1px #DAE2EE",
              margin: "0px 8px",
              backgroundColor: "transparent",
            }}
            icon={<img alt="" src={ArrowLeft} />}
          ></Button>
          <div className="size-text">前へ</div>
        </div>
        <div style={{ display: "flex" }}>
          <Pagination
            className="db001-pagination"
            disabled={disabled ?? false}
            showSizeChanger={false}
            onChange={setPage}
            current={page}
            pageSize={size}
            total={total}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="size-text">次へ</div>
          <Button
            disabled={page === lastPage || (disabled ?? false)}
            onClick={() => setPage(page + 1)}
            style={{
              width: 38,
              height: 38,
              borderRadius: "3px",
              border: "solid 1px #DAE2EE",
              margin: "0px 8px",
              backgroundColor: "transparent",
            }}
            icon={<img alt="" src={ArrowRight} />}
          ></Button>
        </div>
      </div>
    </div>
  );
};
export default memo(PaginationComponentDB);
