import React from 'react';

import CollapsedTableUI from './ui/UI';
import CollapsedTableApi from '../../../infrastructure/api/ApiCollapsedTable';
import CollapsedTableService from '../../../usecase/ServiceCollapsedTable';
import CollapsedTableHandler from '../../handler/HandlerCollapsedTable';

type props = {
    parentData: any;
    onParentCheckboxChange: (record: any, checked: boolean) => void;
};
const CollapsedTableComponent = ({ parentData, onParentCheckboxChange }: props) => {
    const api = CollapsedTableApi();
    const service = CollapsedTableService(api);
    const handler = CollapsedTableHandler(service);
    return <CollapsedTableUI handler={handler} parentData={parentData} onParentCheckboxChange={onParentCheckboxChange} />;
};

export default CollapsedTableComponent;
