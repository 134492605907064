import helpers from '../../../../../common/helpers/common';

interface props {
  record: any;
  index: number;
}

const MemberRowComponent = ({ record, index }: props) => {
  return (
    <main
      className="table-cell-inside"
      style={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <div style={{ display: 'flex', width: '25%' }}>
        <span style={{ minWidth: 40 }}>{helpers.getNoByIndex(index - 1)}</span>
        <span style={{ marginLeft: 24 }}> {record.fullName || ''}</span>
      </div>
      <div style={{ width: '25%' }}>
        {record?.construction?.projectName ?? record?.group?.name ?? ''}
      </div>
      <div style={{ width: '25%' }}>
        {record?.construction?.description ?? record?.role?.name ?? ''}
      </div>
      <div style={{ width: '25%', textAlign: 'right' }}>{`出勤日数: ${record.workingDays}日`}</div>
    </main>
  );
};

export default MemberRowComponent;
