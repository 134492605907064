import { CM0012SliceType } from './../type/Presenter';
import { createSlice } from '@reduxjs/toolkit';

const initialState: CM0012SliceType = {
    loading: false,
    isVisible: false,
    refresh: false,
    typeModal: true,
    dataLocal: [],
    parentCode: {
        parentRecordId: undefined,
        status: true,
    },
    headerData: {
        traderName: null,
        contractWorkName: null,
        targetPeriod: null,
    },
};

export const cm0012Slice = createSlice({
    name: 'cm0012',
    initialState,
    reducers: {
        resetCM0012: () => initialState,
        setLoadingCM0012: (state, action) => {
            state.loading = action.payload;
        },
        setIsVisibleCM0012: (state, action) => {
            state.isVisible = action.payload;
        },
        setRefreshCM0012: (state) => {
            state.refresh = !state.refresh;
        },
        setTypeModalCM0012: (state, action) => {
            state.typeModal = action.payload;
        },
        setDataLocalCM0012: (state, action) => {
            state.dataLocal = action.payload;
        },
        deleteDataLocalCM0012: (state, action) => {
            state.dataLocal = state.dataLocal.filter(
                (item) => item.parentRecordId !== action.payload,
            );
        },
        setParentCodeCM0012: (state, action) => {
            state.parentCode = action.payload;
            state.typeModal = typeof action.payload.parentRecordId === 'string' ? true : false;
        },
        setHeaderDataCM0012: (state, action) => {
            state.headerData = action.payload;
        },
    },
});

export const {
    deleteDataLocalCM0012,
    setLoadingCM0012,
    setIsVisibleCM0012,
    resetCM0012,
    setRefreshCM0012,
    setTypeModalCM0012,
    setDataLocalCM0012,
    setParentCodeCM0012,
    setHeaderDataCM0012,
} = cm0012Slice.actions;

export default cm0012Slice.reducer;
