import helpers from '../../../../../common/helpers/common';
import { SM01HandlerImpl } from '../handler/HandlerImpl';
import EventsOrganizingProcessSVG from '../../../../../assets/icons/events-organizing-process.svg';
import CommentSVG from '../../../../../assets/icons/comment.svg';

import { Badge, Button } from 'antd';
import { DefaultAvatarComponent } from '../../../../../common/components/default-avatar';
import TooltipText from '../../../../../common/components/tooltip-text/TooltipText';
import { DayEvent, DayEventState } from '../../entity/Entity';
import moment from 'moment';
import COMMON from '../../../../../common/constants/COMMON';
import { SYSTEM_ROLES } from '../../../../../common/constants/AUTHORIZATION';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';

const EmptyData = () =>
    <div style={{ height: '100%', width: '100%' }} className='center-item'>
        <div>
            <section className='center-item' >
                <img alt='' src={EventsOrganizingProcessSVG} />
            </section>
            <section style={{ marginTop: 40, textAlign: 'center' }}>
                イベントはありません
            </section>
        </div>
    </div>

const EventRow = (props: {
    event: DayEventState;
    openModalDetailSM04: (scheduleId: number) => void
}) => {
    const { event, openModalDetailSM04 } = props;

    const time = event.isFullDay ? `終日` : `${moment(event.startTime).format(COMMON.FORMAT_TIME3)} - ${moment(event.endTime).format(COMMON.FORMAT_TIME3)}`
    const name = event?.name ?? ""
    const mainColor = event?.category?.colorRgb ?? ""

    const fadedMainColor = helpers.hexToRGBA(mainColor, 0.2)
    const backgroundColor = event.isFullDay ? mainColor : fadedMainColor
    const titleColor = event.isFullDay ? '#fff' : mainColor

    const isRoleAdmin = event?.member?.role === SYSTEM_ROLES.ADMIN;
    const isRoleAdminSystem = event?.member?.role === SYSTEM_ROLES.ADMIN_SYSTEM;

    return <div className='event-row button-style'
        onClick={(e: any) => {
            e.stopPropagation();
            openModalDetailSM04(event.id)
        }}>
        <section className='event-frame-container'>
            <div className='event-frame center-item' style={{ background: backgroundColor, color: titleColor, marginRight: 24 }}>
                {time}
            </div>
            <div className='event-frame-title'>
                <TooltipText content={name} showTooltip={event.name} />
            </div>
        </section >
        <section className='comment-container'>
            <DefaultAvatarComponent
                size={40}
                isAdmin={(isRoleAdminSystem || isRoleAdmin)}
                avatar={event?.member?.avatar ?? undefined}
                value={(event?.member?.fullName ?? "")}
            />
            <Badge count={event.commentCount} className="avatar-badge-button" color={'#0050AE'} >
                <div
                    style={{ border: "none", marginLeft: 8, }}
                >
                    <img alt="" src={CommentSVG} />
                </div>
            </Badge>
        </section>
    </div >
}

const DaySchedule = (props: {
    handler: SM01HandlerImpl;
    currentDate: string;
}) => {
    const { handler, currentDate } = props
    const { dayEvents } = handler
    const isLoading = useSelector((state: RootState) => state.common.loading);
    return isLoading ? <></> : (
        <div style={{ height: '74vh', overflow: 'auto' }} >
            {
                dayEvents.length === 0 ?
                    <EmptyData /> :
                    <>
                        {
                            dayEvents.map((element) => {
                                return <EventRow key={element.id} event={element} openModalDetailSM04={handler.openModalDetailSM04} />
                            })
                        }
                    </>
            }
        </div>
    );
};

export default DaySchedule;
