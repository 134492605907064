import ErrorNotification from '../../../../common/components/notification/ErrorNotification';
import { NOTIFICATION_TITLE } from '../../../../common/constants/MESSAGE';
import { ProfileCompanyPayload } from '../entity/Entity';
import { IC1Api } from './ServiceImpl';

const IC1Service = (api: IC1Api) => {
    const getCompanyProfile = async () => {
        try {
            const response = await api.getCompanyProfile();
            return response?.data;
        } catch (error: any) {
            ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };

    const putUpdateCompanyProfile = async (payload: any): Promise<any> => {
        try {
            const response = await api.putUpdateCompanyProfile(payload);
            return response.data;
        } catch (error: any) {
            throw error;
        }
    };

    return {
        getCompanyProfile,
        putUpdateCompanyProfile
    };
};

export default IC1Service;
