import ErrorNotification from '../../../../common/components/notification/ErrorNotification';
import { NOTIFICATION_TITLE } from '../../../../common/constants/MESSAGE';
import { IC3Api } from './ServiceImpl';

const IC3Service = (api: IC3Api) => {
    const getOrderCompany = async () => {
        try {
            const response = await api.getOrderCompany();
            return response?.data;
        } catch (error: any) {
            ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };

    return {
        getOrderCompany,
    };
};

export default IC3Service;
