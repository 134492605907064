import { Button, DatePicker, Form, Input, Modal, Spin } from "antd";
import { useSelector } from "react-redux";
import React, { useEffect } from "react";

import LoadingComponent from "../../../../../common/components/icons/LoadingComponent";

import { RootState } from "../../../../../store";
import { HandlerImpl } from "../handler/HandlerImpl";
import "moment/locale/ja";

import XIconModal from "../../../../../assets/icons/x-icon-modal.svg";
import { StepComponent } from "./Step";

type Props = {
  handler: HandlerImpl;
};

const MainUI = ({ handler }: Props) => {
  const isVisible = useSelector((state: RootState) => state.cr005.isVisible);
  const versionId = useSelector((state: RootState) => state.cr005.versionId);

  useEffect(() => {
    if (isVisible && !!versionId) {
      (async () => {
        await handler.getProjectCRApprovalProcess({ versionId: versionId });
      })();
    }
  }, [isVisible]);

  return (
    <Modal
      className="group-detail-modal CR005"
      style={{ marginLeft: 300, marginTop: 40, marginBottom: 40 }}
      visible={isVisible}
      closable={false}
      maskClosable={false}
      width={604}
      centered={true}
      footer={null}
    >
      <Spin indicator={LoadingComponent} spinning={handler.loading}>
        <div className="w-100-percent header">
          <div className="header-modal-title-om004 ">承認フロー詳細</div>
          <div style={{ cursor: "pointer" }} onClick={handler.onCancel}>
            <img alt="" src={XIconModal} style={{ height: 18, width: 18 }} />
          </div>
        </div>
        <div className="body">
          {handler.historyData.map((history, index) => (
            <StepComponent
              data={history}
              isEnd={index === handler.historyData.length - 1}
              index={index}
            />
          ))}
        </div>
        <div className="footer">
          <Button
            disabled={handler.loading}
            size="large"
            style={{ marginLeft: 16, width: 118 }}
            onClick={handler.onCancel}
          >
            閉じる
          </Button>
        </div>
      </Spin>
    </Modal>
  );
};

export default MainUI;
