import { Checkbox } from "antd";
import React from "react";

interface props {
    onCheckAllChange: (value: boolean) => void;
    checkAll: boolean;
    isDisabled?: boolean;
    showCB?: boolean;

}

export const CheckBoxCollapseComponentCM007CM009 = ({ onCheckAllChange, checkAll, isDisabled, showCB = true }: props) => {
    const getCheckBox = () => {

        return isDisabled ? (
            <Checkbox className="no-hover" disabled={true} style={{ marginRight: 8 }} checked />
        ) : (
            <Checkbox
                style={{ marginRight: 8 }}
                onChange={(value: any) => onCheckAllChange(value?.target?.checked ? true : false)}
                checked={checkAll}
            ></Checkbox>
        );
    };

    return (
        <span style={{ width: '100%', float: 'left', textAlign: 'center' }}>{showCB ? getCheckBox() : null}No</span>
    );
};

;