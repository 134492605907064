import { TAX_CODE } from "../../../common/constants/COMMON";
import { OffsetCost, SummaryDataStateType } from "./entity/Entity";

const totalOffsetAmountCompany = (value: any) => {
  let result = 0;
  const offsetArray = (value?.offsetCostSummary ?? []).concat(
    value?.offsetCost ?? []
  );
  offsetArray.forEach((element: any) => {
    result += element?.value ?? 0;
  });
  return result;
};
const totalOffsetAmount = (value: any) => {
  let result = 0;
  const offsetArray = value?.offsetCost ?? [];
  offsetArray.forEach((element: any) => {
    result += element?.value ?? 0;
  });
  return result;
};

const getOffsetValue = (record: any, taxFree?: boolean) => {
  const noData = 0;

  if (!record.offsetCost) return noData;

  const offsetCost = record.offsetCost?.find(
    (element: OffsetCost) =>
      element.category.code === (taxFree ? TAX_CODE.TAX_FREE : TAX_CODE.TAX)
  );

  if (!offsetCost) return noData;

  return offsetCost?.value ?? 0;
};
const getOffsetSummaryValue = (record: any, taxFree?: boolean) => {
  const noData = 0;

  if (!record.offsetCostSummary) return noData;

  const offsetCostSummary = record.offsetCostSummary?.find(
    (element: OffsetCost) =>
      element.category.code === (taxFree ? TAX_CODE.TAX_FREE : TAX_CODE.TAX)
  );

  if (!offsetCostSummary) return noData;

  return offsetCostSummary?.value ?? 0;
};
const getDataWithFormula = (projectRawData: any[]) => {
  const results: any[] = projectRawData.map((project: any) => {
    let projecttotalOffset: number = 0;
    let projectoffsetValue: number = 0;
    let projectoffsetSummaryValue: number = 0;
    let projectpaymentAmountExcludingTax: number = 0;
    let projectconsumptionTax: number = 0;
    let projectpaymentAmountIncludingTax: number = 0;
    let projectoffsetValueTaxFree: number = 0;
    let projectsetSummaryValueTaxFree: number = 0;
    let projectactualCost: number = 0;
    const newConstruction = project.constructions.map((construction: any) => {
      let constructionotalOffset: number = 0;
      let constructionoffsetValue: number = 0;
      let constructionoffsetSummaryValue: number = 0;
      let constructionpaymentAmountExcludingTax: number = 0;
      let constructionconsumptionTax: number = 0;
      let constructionpaymentAmountIncludingTax: number = 0;
      let constructionoffsetValueTaxFree: number = 0;
      let constructionoffsetSummaryValueTaxFree: number = 0;
      let constructionactualCost: number = 0;

      const newSubConstruction = construction.subConstruction.map(
        (sub: any) => {
          let subtotalOffset: number = 0;
          let suboffsetValue: number = 0;
          let suboffsetSummaryValue: number = 0;
          let subpaymentAmountExcludingTax: number = 0;
          let subconsumptionTax: number = 0;
          let subpaymentAmountIncludingTax: number = 0;
          let suboffsetValueTaxFree: number = 0;
          let suboffsetSummaryValueTaxFree: number = 0;
          let subactualCost: number = 0;

          const newOutSourceCost = sub.outSourceCost.map((cost: any) => {
            subactualCost += cost?.actualCost ?? 0;

            const offsetValue = getOffsetValue(cost);
            suboffsetValue += offsetValue;

            const offsetValueTaxFree = getOffsetValue(cost, true);
            suboffsetValueTaxFree += offsetValueTaxFree;

            const offsetSummaryValue = getOffsetSummaryValue(cost);
            suboffsetSummaryValue += offsetSummaryValue;

            const offsetSummaryValueTaxFree = getOffsetSummaryValue(cost, true);
            suboffsetSummaryValueTaxFree += offsetSummaryValueTaxFree;
            const totalOffset =
              offsetValue +
              offsetValueTaxFree +
              offsetSummaryValue +
              offsetSummaryValueTaxFree;
            subtotalOffset += totalOffset;

            const paymentAmountExcludingTax =
              (cost?.actualCost ?? 0) - totalOffset;
            subpaymentAmountExcludingTax += paymentAmountExcludingTax;

            const consumptionTax = Math.floor(
              (paymentAmountExcludingTax + offsetValue + offsetSummaryValue) *
                (cost?.taxSetting ?? 1)
            );
            subconsumptionTax += consumptionTax;

            const paymentAmountIncludingTax =
              paymentAmountExcludingTax + consumptionTax;
            subpaymentAmountIncludingTax += paymentAmountIncludingTax;

            return {
              ...cost,
              totalOffset,
              offsetValue,
              offsetSummaryValue,
              paymentAmountExcludingTax,
              consumptionTax,
              paymentAmountIncludingTax,
              offsetSummaryValueTaxFree,
              offsetValueTaxFree,
              subConstructionName: sub?.name ?? "",
            };
          });
          constructionotalOffset += subtotalOffset;
          constructionoffsetValue += suboffsetValue;
          constructionoffsetSummaryValue += suboffsetSummaryValue;
          constructionpaymentAmountExcludingTax += subpaymentAmountExcludingTax;
          constructionconsumptionTax += subconsumptionTax;
          constructionpaymentAmountIncludingTax += subpaymentAmountIncludingTax;
          constructionoffsetValueTaxFree += suboffsetValueTaxFree;
          constructionoffsetSummaryValueTaxFree += suboffsetSummaryValueTaxFree;
          constructionactualCost += subactualCost;

          return {
            ...sub,
            actualCost: subactualCost,
            totalOffset: subtotalOffset,
            offsetValue: suboffsetValue,
            offsetSummaryValue: suboffsetSummaryValue,
            paymentAmountExcludingTax: subpaymentAmountExcludingTax,
            consumptionTax: subconsumptionTax,
            paymentAmountIncludingTax: subpaymentAmountIncludingTax,
            offsetValueTaxFree: suboffsetValueTaxFree,
            offsetSummaryValueTaxFree: suboffsetSummaryValueTaxFree,
            constructionName: construction.name ?? "",
            outSourceCost: newOutSourceCost,
          };
        }
      );
      projecttotalOffset += constructionotalOffset;
      projectoffsetValue += constructionoffsetValue;
      projectoffsetSummaryValue += constructionoffsetSummaryValue;
      projectpaymentAmountExcludingTax += constructionpaymentAmountExcludingTax;
      projectconsumptionTax += constructionconsumptionTax;
      projectpaymentAmountIncludingTax += constructionpaymentAmountIncludingTax;
      projectoffsetValueTaxFree += constructionoffsetValueTaxFree;
      projectsetSummaryValueTaxFree += constructionoffsetSummaryValueTaxFree;
      projectactualCost += constructionactualCost;
      return {
        ...construction,
        totalOffset: constructionotalOffset,
        offsetValue: constructionoffsetValue,
        offsetSummaryValue: constructionoffsetSummaryValue,
        paymentAmountExcludingTax: constructionpaymentAmountExcludingTax,
        consumptionTax: constructionconsumptionTax,
        paymentAmountIncludingTax: constructionpaymentAmountIncludingTax,
        offsetValueTaxFree: constructionoffsetValueTaxFree,
        actualCost: constructionactualCost,
        offsetSummaryValueTaxFree: constructionoffsetSummaryValueTaxFree,
        subConstruction: newSubConstruction,
      };
    });
    return {
      ...project,
      totalOffset: projecttotalOffset,
      offsetValue: projectoffsetValue,
      offsetSummaryValue: projectoffsetSummaryValue,
      paymentAmountExcludingTax: projectpaymentAmountExcludingTax,
      consumptionTax: projectconsumptionTax,
      paymentAmountIncludingTax: projectpaymentAmountIncludingTax,
      offsetValueTaxFree: projectoffsetValueTaxFree,
      offsetSummaryValueTaxFree: projectsetSummaryValueTaxFree,
      actualCost: projectactualCost,
      constructions: newConstruction,
    };
  });
  return results;
};

const getSubConstructionBasedOnConstruction = (constructions: any[]) => {
  let results: any[] = [];

  constructions.forEach((element) => {
    results = results.concat(element.subConstruction ?? []);
  });
  return results;
};

export {
  totalOffsetAmountCompany,
  totalOffsetAmount,
  getDataWithFormula,
  getSubConstructionBasedOnConstruction,
  getOffsetSummaryValue,
};
