import { AxiosResponse } from "axios";
import axiosConfig from "../../../../../config/axiosConfig";

const MenuApi = () => {
  const logout = async (): Promise<AxiosResponse<any, any>> => {
    try {
      const url = "/auth/logout";
      return axiosConfig.post(url);
    } catch (error) {
      throw error;
    }
  };
  return { logout };
};

export default MenuApi;
