import Handler from "./presenter/handler/Handler";
import MainUI from "./presenter/ui/UI";


export interface propsApproveEditModal {
    callbackOk?: (value: any) => Promise<any>;
    callbackCancel?: () => Promise<any>;

}
const ApproveEditModal = (props: propsApproveEditModal) => {
    const handler = Handler(props)
    return <MainUI handler={handler} />
}

export default ApproveEditModal