import React from 'react';
import { T01UseCase } from '../../usecase/ServiceImpl';
import SuccessNotification from '../../../../../common/components/notification/SuccessNotification';
import { NOTIFICATION_TITLE } from '../../../../../common/constants/MESSAGE';

const T01Handler = (service: T01UseCase) => {
  const [value, setValue] = React.useState<number>(0);
  const [refresh, setRefresh] = React.useState<boolean>(false);

  const getTax = async () => {
    try {
      const response = await service.getTaxSetting({});
      setValue(Math.round(response.results.taxSetting * 100));
    } catch (error: any) {
      throw error;
    }
  };

  const updateTax = async (value: number) => {
    try {
      await service.updateTaxSetting({ value: value / 100 });
      setRefresh(prev => !prev)
      SuccessNotification(NOTIFICATION_TITLE.SUCCESS);
    } catch (error: any) {
      throw error;
    }
  };

  return {
    value,
    refresh,

    getTax,
    updateTax,
  };
};

export default T01Handler;
