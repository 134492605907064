import { CM0010SliceType } from './../type/Presenter';
import { createSlice } from '@reduxjs/toolkit';

const initialState: CM0010SliceType = {
    loading: false,
    isVisible: false,
    refresh: false,
    documentIdCM010: 0,
    documentOriginIdCM010: 0,
    summitDateCM010: '',
    statusCM010: '',
};

export const cm0010Slice = createSlice({
    name: 'cm0010',
    initialState,
    reducers: {
        resetCM0010: () => initialState,
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setIsVisibleCM0010: (state, action) => {
            state.isVisible = action.payload;
        },
        setRefreshCM0010: (state) => {
            state.refresh = !state.refresh;
        },
        setDocumentCM010Id: (state, action) => {
            state.documentIdCM010 = action.payload;
        },
        setDocumentCM010OriginId: (state, action) => {
            state.documentOriginIdCM010 = action.payload;
        },
        setSubmittedDateCM010: (state, action) => {
            state.summitDateCM010 = action.payload;
        },
        setStatusCM010: (state, action) => {
            state.statusCM010 = action.payload;
        },
    },
});

export const {
    setLoading,
    setIsVisibleCM0010,
    resetCM0010,
    setRefreshCM0010,
    setDocumentCM010Id,
    setDocumentCM010OriginId,
    setSubmittedDateCM010,
    setStatusCM010,
} = cm0010Slice.actions;

export default cm0010Slice.reducer;
