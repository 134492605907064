import { useState } from "react";
import { useDispatch } from "react-redux";
import { AM014UseCase } from "../../usecase/ServiceImpl";
import { resetAM014} from "../slice/Slice";
import {NOTIFICATION_TITLE} from "../../../../../common/constants/MESSAGE";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import { setIsVisibleAM027, setRequestIdAM027, setScreenRequestToAM027 } from "../../../AM027/presenter/slice/Slice";
import { setIsVisibleAM012, setRequestIdAM012, setTypeModalAM012 } from "../../../AM012/presenter/slice/Slice";

const AM014Handler = (am014Service: AM014UseCase) => {
  // LIB FUNCTION
  const dispatch = useDispatch();
  // STATE
  const [requestAttendance, setRequestAttendance] = useState<any>({});
  // FUNCTION

  const handleGetRequestAttendance= async (requestId:any):Promise<any>=>{
    try{
      const params ={
        requestId:requestId
      }
      const res = await am014Service.getRequestAttendance(params);
      setRequestAttendance(res.results);
    }catch(error: any){
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  }

  const handleOpenModalConfirmDelete = (requestId:number[])=>{
    dispatch(setIsVisibleAM027(true));
    dispatch(setRequestIdAM027(requestId))
    dispatch(setScreenRequestToAM027("am014"))
  }

  const handleOpenModalUpdate = (requestId:number)=>{
    dispatch(setRequestIdAM012(requestId));
    dispatch(setTypeModalAM012("update"));
    dispatch(setIsVisibleAM012(true));
  }

  const onCancel = () => {
    dispatch(resetAM014());
  };
  return {
    requestAttendance,
    onCancel,
    handleGetRequestAttendance,
    handleOpenModalConfirmDelete,
    handleOpenModalUpdate
  };
};

export default AM014Handler;
