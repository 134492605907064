import React, { useEffect } from 'react';
import { Form, Select } from 'antd';
import { HandlerImpl } from '../handler/HandlerImpl';
import MenuWrapperComponent from '../../../../../common/components/menu-wrapper';
import BreadcrumbComponent from '../../../../../common/components/breadcrumb/BreadcrumbComponent';

import HomeSvg from '../../../../../assets/icons/home.svg';
import IconPlus from '../../../../../assets/icons/plus-purpose.svg';
import InputSelect from '../../../../../common/components/input-select/InputSelect';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import {
  AUTHORIZATION_CODE,
  PAGE_AUTHORIZATION,
  SYSTEM_ROLES,
} from '../../../../../common/constants/AUTHORIZATION';
import { PLAN_TYPE } from '../../../../../common/constants/COMMON';
import { hasSomeElements } from '../../../../../router/AuthorizationRouter';

interface props {
  handler: HandlerImpl;
}

const BREADCRUMB_CM3854: any[] = [
  {
    title: '設定',
    url: '',
    icon: (
      <img style={{ paddingBottom: 2, paddingRight: 8 }} alt="" src={HomeSvg} />
    ),
  },
  {
    title: 'ワークフロー',
    url: '',
    icon: '',
  },
];

const BTN_ADD = '新規追加';

const MainUI = ({ handler }: props) => {
  const navigate = useNavigate();

  const permissions = useSelector(
    (state: RootState) => state.auth.authData?.permission
  );

  const isRoleAdmin = useSelector((state: RootState) => state.auth?.authData?.isRoleAdmin)

  useEffect(() => {
    handler.getWorkflowType();
  }, []);
  return (
    <MenuWrapperComponent chosenKey={73} openKey={7}>
      <div className="sw01-wrapper">
        <BreadcrumbComponent data={BREADCRUMB_CM3854} />
        <h2 className="sw01-header">ワークフロー</h2>
        <div className="sw01-body">
          <Form name="sw01" form={handler.form}>
            <div className="sw01-item sw01-item-header">
              <div className="sw01-item-left sw01-item-title">項目</div>
              <div className="sw01-item-right sw01-item-title">
                ワークフロー
              </div>
            </div>
            <div className="sw01-item sw01-item-header-child">
              <div className="sw01-item-left sw01-item-title">案件管理</div>
              <div className="sw01-item-right"></div>
            </div>
            {permissions?.includes(AUTHORIZATION_CODE.S500) ? (
              handler.categories.ci_workflow?.map((item) => {
                return (
                  <div className="sw01-item" key={item.id}>
                    <div className="sw01-item-left">{item.name}</div>
                    <div className="sw01-item-right">
                      <Form.Item name={item.category?.code}>
                        <Select
                          listHeight={195}
                          className="sw01-select"
                          onChange={(value) => {
                            if (value === 'sw-add') {
                              navigate('/app-sw01/sw02');
                              return;
                            }
                            handler.putWorkflowSetting(
                              {
                                workflowId: +value,
                                workflowTypeId: item.id,
                              },
                              item.category?.code
                            );
                          }}
                          getPopupContainer={(trigger: any) =>
                            trigger.parentNode
                          }
                        >
                          {[
                            ...item.option,
                            { name: BTN_ADD, value: 'sw-add' },
                          ].map((element, index) => {
                            return (
                              <Select.Option
                                value={element.value}
                                key={element.value}
                                className="sw01-option"
                              >
                                <span>{element.name}</span>
                                {index === item.option.length ? (
                                  <img alt="" src={IconPlus} />
                                ) : null}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                );
              })
            ) : <></>}

            {permissions?.includes(AUTHORIZATION_CODE.S501) ? (
              handler.categories.cr_workflow?.map((item) => {
                return (
                  <div className="sw01-item" key={item.id}>
                    <div className="sw01-item-left">{item.name}</div>
                    <div className="sw01-item-right">
                      <Form.Item name={item.category.code}>
                        <Select
                          listHeight={195}
                          className="sw01-select"
                          onChange={(value) => {
                            if (value === 'sw-add') {
                              navigate('/app-sw01/sw02');
                              return;
                            }
                            handler.putWorkflowSetting(
                              {
                                workflowId: +value,
                                workflowTypeId: item.id,
                              },
                              item.category?.code
                            );
                          }}
                          getPopupContainer={(trigger: any) =>
                            trigger.parentNode
                          }
                        >
                          {[
                            ...item.option,
                            { name: BTN_ADD, value: 'sw-add' },
                          ].map((element, index) => {
                            return (
                              <Select.Option
                                value={element.value}
                                key={element.value}
                                className="sw01-option"
                              >
                                <span>{element.name}</span>
                                {index === item.option.length ? (
                                  <img alt="" src={IconPlus} />
                                ) : null}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                );
              })
            ) : <></>}
            {permissions?.includes(AUTHORIZATION_CODE.S28) ||
              permissions?.includes(AUTHORIZATION_CODE.S32) ||
              permissions?.includes(AUTHORIZATION_CODE.S36) ||
              permissions?.includes(AUTHORIZATION_CODE.S301) ? (
              <>
                <div className="sw01-item sw01-item-header-child">
                  <div className="sw01-item-left sw01-item-title">収支管理</div>
                  <div className="sw01-item-right"></div>
                </div>
                {permissions?.includes(AUTHORIZATION_CODE.S502) ? (
                  handler.categories.re_document_receive_money_workflow?.map((item) => {
                    return (
                      <div className="sw01-item" key={item.id}>
                        <div className="sw01-item-left">{item.name}</div>
                        <div className="sw01-item-right">
                          <Form.Item name={item.category.code}>
                            <Select
                              listHeight={195}
                              className="sw01-select"
                              onChange={(value) => {
                                if (value === 'sw-add') {
                                  navigate('/app-sw01/sw02');
                                  return;
                                }
                                handler.putWorkflowSetting(
                                  {
                                    workflowId: +value,
                                    workflowTypeId: item.id,
                                  },
                                  item.category?.code
                                );
                              }}
                              getPopupContainer={(trigger: any) =>
                                trigger.parentNode
                              }
                            >
                              {[
                                ...item.option,
                                { name: BTN_ADD, value: 'sw-add' },
                              ].map((element, index) => {
                                return (
                                  <Select.Option
                                    value={element.value}
                                    key={element.value}
                                    className="sw01-option"
                                  >
                                    <span>{element.name}</span>
                                    {index === item.option.length ? (
                                      <img alt="" src={IconPlus} />
                                    ) : null}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                    );
                  })
                ) : <></>}
                {permissions?.includes(AUTHORIZATION_CODE.S502) ? (
                  handler.categories.re_document_workflow?.map((item) => {
                    return (
                      <div className="sw01-item" key={item.id}>
                        <div className="sw01-item-left">{item.name}</div>
                        <div className="sw01-item-right">
                          <Form.Item name={item.category.code}>
                            <Select
                              listHeight={195}
                              className="sw01-select"
                              onChange={(value) => {
                                if (value === 'sw-add') {
                                  navigate('/app-sw01/sw02');
                                  return;
                                }
                                handler.putWorkflowSetting(
                                  {
                                    workflowId: +value,
                                    workflowTypeId: item.id,
                                  },
                                  item.category?.code
                                );
                              }}
                              getPopupContainer={(trigger: any) =>
                                trigger.parentNode
                              }
                            >
                              {[
                                ...item.option,
                                { name: BTN_ADD, value: 'sw-add' },
                              ].map((element, index) => {
                                return (
                                  <Select.Option
                                    value={element.value}
                                    key={element.value}
                                    className="sw01-option"
                                  >
                                    <span>{element.name}</span>
                                    {index === item.option.length ? (
                                      <img alt="" src={IconPlus} />
                                    ) : null}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                    );
                  })
                ) : <></>}
                {permissions?.includes(AUTHORIZATION_CODE.S502) ? (
                  handler.categories.re_document_outsource_summary_workflow?.map((item) => {
                    return (
                      <div className="sw01-item" key={item.id}>
                        <div className="sw01-item-left">{item.name}</div>
                        <div className="sw01-item-right">
                          <Form.Item name={item.category.code}>
                            <Select
                              listHeight={195}
                              className="sw01-select"
                              onChange={(value) => {
                                if (value === 'sw-add') {
                                  navigate('/app-sw01/sw02');
                                  return;
                                }
                                handler.putWorkflowSetting(
                                  {
                                    workflowId: +value,
                                    workflowTypeId: item.id,
                                  },
                                  item.category?.code
                                );
                              }}
                              getPopupContainer={(trigger: any) =>
                                trigger.parentNode
                              }
                            >
                              {[
                                ...item.option,
                                { name: BTN_ADD, value: 'sw-add' },
                              ].map((element, index) => {
                                return (
                                  <Select.Option
                                    value={element.value}
                                    key={element.value}
                                    className="sw01-option"
                                  >
                                    <span>{element.name}</span>
                                    {index === item.option.length ? (
                                      <img alt="" src={IconPlus} />
                                    ) : null}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                    );
                  })
                ) : <></>}
                {permissions?.includes(AUTHORIZATION_CODE.S502) ? (
                  handler.categories.re_summary_internal_workflow?.map((item) => {
                    return (
                      <div className="sw01-item" key={item.id}>
                        <div className="sw01-item-left">{item.name}</div>
                        <div className="sw01-item-right">
                          <Form.Item name={item.category.code}>
                            <Select
                              listHeight={195}
                              className="sw01-select"
                              onChange={(value) => {
                                if (value === 'sw-add') {
                                  navigate('/app-sw01/sw02');
                                  return;
                                }
                                handler.putWorkflowSetting(
                                  {
                                    workflowId: +value,
                                    workflowTypeId: item.id,
                                  },
                                  item.category?.code
                                );
                              }}
                              getPopupContainer={(trigger: any) =>
                                trigger.parentNode
                              }
                            >
                              {[
                                ...item.option,
                                { name: BTN_ADD, value: 'sw-add' },
                              ].map((element, index) => {
                                return (
                                  <Select.Option
                                    value={element.value}
                                    key={element.value}
                                    className="sw01-option"
                                  >
                                    <span>{element.name}</span>
                                    {index === item.option.length ? (
                                      <img alt="" src={IconPlus} />
                                    ) : null}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                    );
                  })
                ) : <></>}
              </>
            ) : null}
          </Form>
        </div>
      </div>
    </MenuWrapperComponent>
  );
};

export default MainUI;
