import { NOTIFICATION_TITLE } from './../../../../../common/constants/MESSAGE';
import { PM005UseCase } from './../../../PM005/usecase/ServiceImpl';
import { PM003UseCase } from '../../usecase/ServiceImpl';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
    setData,
    setKeyWordChange,
    setPageChange,
    setPageChangeCard,
    setStatus,
    setStatusFilterChange,
} from '../slice/Slice';
import DocumentSvg from '../../../../../assets/icons/document.svg';
import IconBuildOutlineSvg from '../../../../../assets/icons/ion_build-outline.svg';
import ShieldDoneSvg from '../../../../../assets/icons/Shield Done.svg';
import PaperFailSvg from '../../../../../assets/icons/Paper Fail.svg';
import { Form } from 'antd';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { RootState } from '../../../../../store';
import ErrorNotification from '../../../../../common/components/notification/ErrorNotification';
import { setLoading } from '../../../../../common/slice/CommonSlice';
import { setIsModalOpen } from '../../../PM008/presenter/slice/Slice';
import COMMON from '../../../../../common/constants/COMMON';
import { changeProjectNoti, changeViewNoti } from '../../../PM009/presenter/slice/Slice';
import { showImageViewerModal } from '../../../../../common/components/image-viewer-modal/ImageViewSlice';
import { showPdfViewerModal } from '../../../../../common/components/pdf-viewer-modal/pdfViewerModalSlice';
import { pm012ChangeOpen, setProject } from '../../../PM012/presenter/slice/Slice';
import { setProjectDetail } from '../../../PM005/presenter/slice/Slice';
const dataSrc = [
    {
        icon: DocumentSvg,
        code: 'estimated',
    },
    {
        icon: IconBuildOutlineSvg,
        code: 'underConstruction',
    },
    {
        icon: ShieldDoneSvg,
        code: 'complete',
    },
    {
        icon: PaperFailSvg,
        code: 'bot',
    },
];

const SORT_TYPE: string = 'DESC',
    SORT_BY: string = 'updatedAt';

const PM003Handler = (PM003Service: PM003UseCase, pM005Service: PM005UseCase) => {
    const [form] = Form.useForm();
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [newDataStatus, setNewDataStatus] = useState([]);
    const [totalCountDefault, setTotalCountDefault] = useState(0);
    const isModalOpen = useSelector((state: RootState) => state.pm008.isModalOpen);
    const [pdfUrl, setPdfUrl] = useState('');
    const [showPdf, setShowPdf] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const getPdfMap = async (params: any) => {
        try {
            const response = await pM005Service.getPdfMap(params);
            setPdfUrl(response.results);
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        }
    };
    const toOtherPageList = () => { };
    const toOtherPageCard = () => {
        const params = onParams();
        delete params.size;
        navigate({
            pathname: '/app-pm002',
            search: `?${createSearchParams(params)}`,
        });
    };

    const getData = async (params: any) => {
        try {
            const response = await PM003Service.getData(params);
            if (response) {
                const totalCount = response.results.status.reduce((acc: any, cur: any) => {
                    return acc + cur.count;
                }, 0);
                setTotalCountDefault(totalCount);
                const newDataStatus = response.results.status.map((status: any) => {
                    const objStatus = { ...status };
                    dataSrc.forEach((src) => {
                        if (src.code === status.code) {
                            objStatus.src = src.icon;
                        }
                    });
                    return objStatus;
                });
                setNewDataStatus(newDataStatus);
                const newData = response.results.project.map((element: any) => {
                    const objStatus = { ...element };
                    objStatus.statusName = element.status.name;
                    objStatus.key = element.id;
                    objStatus.constructionDate =
                        element.constructionFromDate && element?.constructionToDate
                            ? `${moment(element.constructionFromDate).format(
                                'YYYY 年 MM 月 DD 日',
                            )} - ${moment(element.constructionToDate).format(
                                'YYYY 年 MM 月 DD 日',
                            )}`
                            : '-';
                    return objStatus;
                });
                dispatch(setData(newData));
                dispatch(setPageChange(response));
                dispatch(setStatus(response));
            }
        } catch (error) { }
    };

    const onPageChange = async (page: number) => {
        searchParams.set('page', page + '');
        setSearchParams(searchParams);
    };
    const onSizeChange = (value: number) => {
        searchParams.set('size', value + '');
        searchParams.set('page', COMMON.DEFAULT_PAGE + '');
        setSearchParams(searchParams);
    };
    const onInfoCardChange = (statusId: number) => {
        dispatch(setStatusFilterChange(statusId));
        searchParams.set('statusId', statusId + '');
        setSearchParams(searchParams);
    };
    const onPageChangeCard = (page: number) => {
        dispatch(setPageChangeCard(page));
        searchParams.set('page', page + '');
        setSearchParams(searchParams);
    };

    var myVar: any;
    function onSearchChange() {
        if (myVar) clearTimeout(myVar);
        myVar = setTimeout(function () {
            const searchText = form.getFieldValue('text');
            searchParams.set('keyword', searchText);
            searchParams.set('page', COMMON.DEFAULT_PAGE.toString());
            setSearchParams(searchParams);
        }, 1000);
    }
    const onParams = () => {
        const searchText = form.getFieldValue('text');
        dispatch(setKeyWordChange(searchText));
        const pageParams = searchParams.get('page');
        if (!pageParams) {
            searchParams.set('page', COMMON.DEFAULT_PAGE + '');
            setSearchParams(searchParams);
        } else {
            const workspaceId = 1;
            const statusId = searchParams.get('statusId') || 0;
            const size = searchParams.get('size') || 10;
            const keywordParams = searchParams.get('keyword') || searchText;
            const pageParams = searchParams.get('page');
            const params: any = {
                workspaceId,
                statusId,
                page: pageParams,
                size: size,
                sortBy: SORT_BY,
                sortType: SORT_TYPE,
            };
            if (keywordParams) {
                params.keyword = keywordParams;
                form.setFieldValue('text', keywordParams);
            }
            return params;
        }
    };
    const onViewDetail = (record: any) => {
        return {
            onClick: () => navigate(`/app-pm005/${record.id}`),
        };
    };

    const showModal = () => {
        dispatch(setIsModalOpen(true));
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const openPdf = () => setShowPdf(true);

    const cancelPdf = () => {
        setShowPdf(false);
        setPdfUrl('');
    };

    const onShowPdfMap = async (record: any) => {
        dispatch(setLoading(true));
        setPdfUrl('');
        try {
            const rootId = record.rootId ? record.rootId : record.id;
            const response = await pM005Service.getPdfMap({
                keyName: 'project/' + rootId + '/' + record.document,
            });
            setPdfUrl(response.results);
            setShowPdf(true);
            const checkFile = response.results?.split('.').pop()?.split('?').shift();
            if (checkFile === 'pdf') {
                return dispatch(showPdfViewerModal());
            }
            dispatch(showImageViewerModal());
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleViewNotification = (project: any) => {
        dispatch(changeViewNoti(true));
        dispatch(changeProjectNoti(project));
    };
    const handleShowImageViewerModal = () => dispatch(showImageViewerModal());
    const handleShowPdfViwerModal = () => dispatch(showPdfViewerModal());

    const handleOpenAddMember = (member: any) => {
        dispatch(pm012ChangeOpen(true));
        dispatch(setProject(member));
        dispatch(setProjectDetail(member));
    };

    const funcAfter = () => {
        const keyword = searchParams.get('keyword');
        const page = searchParams.get('page');
        const statusId = searchParams.get('statusId');
        if (keyword || page || statusId) {
            form.setFieldValue('text', '');
            setSearchParams({});
        } else {
            setRefresh(!refresh);
        }
    };

    return {
        getData,
        onPageChange,
        onSearchChange,
        setSearchParams,
        onParams,
        toOtherPageList,
        toOtherPageCard,
        onSizeChange,
        onInfoCardChange,
        showModal,
        handleCancel,
        openPdf,
        cancelPdf,
        onViewDetail,
        getPdfMap,
        onPageChangeCard,
        onShowPdfMap,
        handleViewNotification,
        handleShowPdfViwerModal,
        handleShowImageViewerModal,
        handleOpenAddMember,
        funcAfter,

        totalCountDefault,
        form,
        searchParams,
        newDataStatus,
        isModalOpen,
        pdfUrl,
        showPdf,
        refresh,
    };
};
export default PM003Handler;
