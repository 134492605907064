import axiosConfig from "../../../../../config/axiosConfig";

const AM022Api = () => {
  const getLeaveCategory = async (params?: any): Promise<any> => {
    try {
      const url = "/request/leave/category";
      const response = await axiosConfig.get(url, { params });
      return response?.data;
    } catch (error) {
      throw error;
    }
  };

  const getLeaveSubCategory = async (params?: any): Promise<any> => {
    try {
      const url = "/request/leave/sub-category";
      const response = await axiosConfig.get(url, { params });
      return response?.data;
    } catch (error) {
      throw error;
    }
  };

  const createLeaveRequest = async (data: any): Promise<any> => {
    try {
      const url = "/request/create/leave";
      const response = await axiosConfig.post(url, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getRequestLeave = async (params: any): Promise<any> => {
    try {
      const url = "/request/leave";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getProjects = async (params: any): Promise<any> => {
    try {
      const url = "/money/project/list";
      const response = await axiosConfig.get(url, { params });
      return response?.data;
    } catch (error) {
      throw error;
    }
  };

  const updateLeave = async (data: any): Promise<any> => {
    try {
      const url = "/request/update/leave";
      const response = await axiosConfig.put(url, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getAttendanceUserConstruction = async (params: any): Promise<any> => {
    try {
      const url = "/attendance/user/construction";
      const response = await axiosConfig.get(url, { params });
      return response?.data;
    } catch (error) {
      throw error;
    }
  };

  const getProjectById = async (params: any): Promise<any> => {
    try {
      const url = "/project/detail";
      const response = await axiosConfig.get(url, { params });
      return response?.data;
    } catch (error) {
      throw error;
    }
  };

  const getRequestApprover = async (): Promise<any> => {
    try {
      const url = "/request/approver";
      const response = await axiosConfig.get(url);
      return response?.data;
    } catch (error) {
      throw error;
    }
  };

  const getProjectStatus = async (params: any): Promise<any> => {
    try {
      const url = "/project/status";
      const response = await axiosConfig.get(url, { params });
      return response?.data;
    } catch (error) {
      throw error;
    }
  };
  return {
    getLeaveCategory,
    getLeaveSubCategory,
    createLeaveRequest,
    getRequestLeave,
    getProjects,
    updateLeave,
    getAttendanceUserConstruction,
    getProjectById,
    getRequestApprover,
    getProjectStatus,
  };
};

export default AM022Api;
