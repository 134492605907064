import { useSearchParams } from "react-router-dom";
// Redux
import { useDispatch, useSelector } from "react-redux";

// Services
import moment from "moment";
import { useMemo, useState } from "react";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import COMMON from "../../../../../common/constants/COMMON";
import { NOTIFICATION_TITLE } from "../../../../../common/constants/MESSAGE";
import helpers from "../../../../../common/helpers/common";
import { CM0026_TemplateExport } from "../../../../../common/helpers/exports/cm/template_cm0026";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import { RootState } from "../../../../../store";
import {
  setHeaderDataCM0012_2,
  setIsVisibleCM0012_2,
  setParentCodeCM0012_2,
} from "../../../CM0012_2/presenter/slice/Slice";
import {
  setIsUpdate,
  setIsVisibleCM017_2,
  setParentCodeCM017_2,
  setTypeModalCM017_2,
} from "../../../CM017_2/presenter/slice/Slice";
import { convertDataView, convertLineSummary } from "../../helper";
import { setIsExportDataCM0026, setVisibleCM0027 } from "../slice/Slice";
import { CM0026ServiceImpl } from "./../../usecase/ServiceImpl";
import { doExportForResponse } from "../../../../../common/helpers/exports/common";
import { setIsHavingData } from "../../../CM0024/presenter/slice/Slice";

export const MENU_ITEM = [
  {
    name: "業者別",
    key: "trader",
  },
  {
    name: "案件別",
    key: "project",
  },
];

const PARAMS = {
  menu: "menu",
  sortType: "sortType2",
  sortBy: "sortBy2",
  from: "from2",
  to: "to2",
  clear: "clear2",
};

const PARAMS_DEFAULT = {
  menu: MENU_ITEM[0].key,
  from: helpers.getFilterTimeMonth(moment().format(COMMON.FORMAT_DATE2)),
  to: helpers.getFilterTimeMonthTo(moment().format(COMMON.FORMAT_DATE2)),
};

export const COLUMN_SORT = [
  "recordedAmount",
  "taxation1",
  "taxExempt1",
  "taxAtion2",
  "taxExempt2",
  "offsetAmount",
  "excludingTax",
  "consumptionTax",
  "taxInclude",
];

const CM0026Handler = (service: CM0026ServiceImpl) => {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const menu = searchParams.get(PARAMS.menu) || PARAMS_DEFAULT.menu;
  const sortType = searchParams.get(PARAMS.sortType);
  const sortBy = searchParams.get(PARAMS.sortBy);
  const from = helpers.getFilterTimeMonth(
    searchParams.get(PARAMS.from) ?? PARAMS_DEFAULT.from
  );
  const to = helpers.getFilterTimeMonthTo(
    searchParams.get(PARAMS.to) ?? PARAMS_DEFAULT.to
  );

  const [dataView, setDataView] = useState<any[]>([]);
  const [expandKey, setExpandKey] = useState<any[]>([]);
  const [isNarrow, setNarrow] = useState(true);
  const [isNarrowModal, setNarrowModal] = useState(true);
  const [visibleView, setVisibleView] = useState(false);
  const [sortTypeModal, setSortTypeModal] = useState<string | null>(null);
  const [sortByModal, setSortByModal] = useState<string | null>(null);
  const [render, setRender] = useState<number>(Math.random());
  const [footer, setFooter] = useState<any>({});
  const [page, setPage] = useState<number>(COMMON.DEFAULT_PAGE);
  // Set First time
  const [cFrom, setCFrom] = useState<string>("firstTime");
  const [cTo, setCTo] = useState<string>("firstTime");

  const visibleCM0027 = useSelector(
    (state: RootState) => state.cm0026.visibleCM0027
  );

  const funcSetExpendKey = (key: any) => {
    const checkKey = funcCheckKeyExpand(key);
    if (checkKey) {
      const newExpandKey = expandKey.filter(
        (keyExpand: any) => keyExpand !== key
      );
      setExpandKey(newExpandKey);
    } else {
      setExpandKey([...expandKey, key]);
    }
  };

  const funcCheckKeyExpand = (key: any) => {
    if (!key || !expandKey) return false;
    return expandKey.some((element: any) => element === key);
  };

  const handleChangeMenuChosen = (menu: string) => {
    searchParams.set(PARAMS.menu, menu);
  };

  const handleCancelModal = () => {
    dispatch(setVisibleCM0027(false));
  };

  const handleCheckNarrow = () => {
    if (visibleCM0027) {
      setNarrowModal(!isNarrowModal);
    } else {
      setNarrow(!isNarrow);
    }
  };

  const funcSetViewModal = () => {
    setVisibleView(true);
  };
  const funcCancelModalView = () => {
    setVisibleView(false);
  };

  const funcCheckSortOrder = (
    columnName: string
  ): "ASC" | "DESC" | undefined => {
    if (columnName === sortBy) {
      return !sortType ? undefined : sortType === "ASC" ? "ASC" : "DESC";
    }
  };
  const funcSortTable = (column: string, sortOrder: string) => {
    if (visibleCM0027) {
      setSortByModal(column);
      setSortTypeModal(sortOrder);
    } else {
      searchParams.set(PARAMS.sortBy, column);
      searchParams.set(PARAMS.sortType, sortOrder);
      setSearchParams(searchParams);
    }
  };

  const totalTable = useMemo(() => {
    if (!dataView) return [];
    if (dataView.length > 0) {
      const results = dataView.reduce((acc: any, cur: any) => {
        const obj = { ...acc };
        obj.recordedAmount += cur.recordedAmount ?? 0;
        obj.taxAtion1 += cur.taxAtion1 ?? 0;
        obj.taxExempt1 += cur.taxExempt1 ?? 0;
        obj.taxAtion2 += cur.taxAtion2 ?? 0;
        obj.taxExempt2 += cur.taxExempt2 ?? 0;
        obj.offsetAmount += cur.offsetAmount ?? 0;
        obj.excludingTax += cur.excludingTax ?? 0;
        obj.consumptionTax += cur.consumptionTax ?? 0;
        obj.taxInclude += cur.taxInclude ?? 0;
        return obj;
      });
      return results;
    }
  }, [dataView]);

  const handleViewModalCM0011 = (
    id: number,
    traderName: string,
    contractWorkName: string,
    targetPeriod: string,
    categoryId: number
  ) => {
    dispatch(setIsVisibleCM0012_2(true));
    dispatch(
      setParentCodeCM0012_2({
        categoryId: categoryId,
        outsourceCostInformationId: id,
      })
    );
    dispatch(
      setHeaderDataCM0012_2({
        traderName: traderName,
        contractWorkName: contractWorkName,
        targetPeriod: targetPeriod,
      })
    );
  };

  const getPromiseOutSourceCost = async (item: any, params: any) => {
    const responseConstructionProjects =
      await service.getReSummaryProjectConstruction({
        projectId: item.id,
        from: params.from,
        to: params.to,
      });

    let constructionData: any[] = [];

    responseConstructionProjects.forEach((element) => {
      constructionData.push({
        constructionName: element?.construction?.description ?? "",
        subConstructionName: element?.subConstructionName ?? "",
        constructionId: element.documentDirectOutsourceCostId,
      });
    });

    const promiseAlls = await Promise.all(
      constructionData.map((item: any) => {
        return service.getOutsourceCost({
          documentDirectOutsourceCostId: item.constructionId,
          from: params.from,
          to: params.to,
        });
      })
    );
    const childrenConstruction = constructionData.map((item, index) => {
      return {
        ...item,
        children: promiseAlls[index]?.results ?? [],
      };
    });

    return {
      projectName: item.projectName,
      children: childrenConstruction,
    };
  };

  const handleGetDataTable = async (params: any) => {
    try {
      dispatch(setLoading(true));
      let page = params.page;

      if (cFrom !== String(params.from) || cTo !== String(params.to)) {
        page = COMMON.DEFAULT_PAGE;
        setPage(page);
      }

      const responseProjectData = await service.getProjectData({
        size: params.size,
        page: page,
        sortBy: COMMON.DEFAULT_SORT_BY,
        sortType: COMMON.DEFAULT_SORT_TYPE,
        from: params.from,
        to: params.to,
      });

      const projects: any[] = responseProjectData?.results ?? [];

      let responseOutsourceCost: any[] = [];
      responseOutsourceCost = await Promise.all(
        projects.map(async (item: any) => {
          return getPromiseOutSourceCost(item, params);
        })
      );
      let data: any[] = [];

      if (cFrom !== String(params.from) || cTo !== String(params.to)) {
        const convertedData = convertDataView(responseOutsourceCost, 1);
        setCFrom(params.from);
        setCTo(params.to);
        data = convertedData;

        const keyDefault: number[] = [];
        if (data?.length > 0) {
          keyDefault.push(data[0].key);
          if (data[0].children) {
            for (const item of data[0].children) {
              keyDefault.push(item.key);
              if (item.children)
                for (const itemChild of item.children)
                  keyDefault.push(itemChild.key);
            }
          }
        }
        setRender(Math.random());
        setExpandKey([...keyDefault]);
      } else {
        const convertedData = convertDataView(
          responseOutsourceCost,
          dataView.length + 1
        );
        data = [...dataView, ...convertedData];
      }
      setDataView(data);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handlerScrollInfinitte = () => {
    setPage((prev) => prev + 1);
  };

  const openCM0017_2 = (value: {
    companyId?: number;
    colabRefId?: number;
    categoryId: string;
    from?: string | undefined;
    to?: string | undefined;
  }) => {
    dispatch(setIsVisibleCM017_2(true));
    dispatch(
      setParentCodeCM017_2({
        categoryId: value.categoryId,
        companyId: value.companyId,
        colabRefId: value.colabRefId,
        from,
        to,
      })
    );
    dispatch(setTypeModalCM017_2(false));
    dispatch(setIsUpdate(false));
  };

  const handleDeleteParams = () => {
    searchParams.delete(PARAMS.from);
    searchParams.delete(PARAMS.to);

    setSearchParams(searchParams);
  };

  const formatDataExportCM0026 = (data: any) => {
    const tableData = data.map((item: any, index: number) => {
      return {
        dataTotal: {
          no: index + 1,
          projectName: item?.projectName,
          recordedAmount: `${helpers.formatCurrency(item?.recordedAmount)}円`,
          taxAtion2: `${helpers.formatCurrency(item?.taxAtion2)}円`,
          taxExempt2: `${helpers.formatCurrency(item?.taxExempt2)}円`,
          excludingTax: `${helpers.formatCurrency(item?.excludingTax)}円`,
          consumptionTax: `${helpers.formatCurrency(item?.consumptionTax)}円`,
          taxIncluded: `${helpers.formatCurrency(item?.taxInclude)}円`,
        },
        child1: formatChild1(item?.children),
      };
    });
    return tableData;
  };

  const formatChild1 = (data: any) => {
    const arr = data.map((item: any, index: number) => {
      return {
        no: index + 1,
        projectName: item?.projectName,
        constructionName: item?.constructionName,
        contractWork: item?.contractWork,
        traderName:
          formatChild2(
            item?.children,
            item?.constructionName,
            item?.contractWork
          )[0]?.traderName ?? "",
        recordedAmount: `${helpers.formatCurrency(item?.recordedAmount)}円`,
        taxAtion2: `${helpers.formatCurrency(item?.taxAtion2)}円`,
        taxExempt2: `${helpers.formatCurrency(item?.taxExempt2)}円`,
        excludingTax: `${helpers.formatCurrency(item?.excludingTax)}円`,
        consumptionTax: `${helpers.formatCurrency(item?.consumptionTax)}円`,
        taxIncluded: `${helpers.formatCurrency(item?.taxInclude)}円`,
        child2: formatChild2(
          item?.children,
          item?.constructionName,
          item?.contractWork
        ),
      };
    });
    return arr;
  };

  const formatChild2 = (
    data: any,
    constructionName: string,
    contractWork: string
  ) => {
    const arr = data.map((item: any, index: number) => {
      return {
        no: index + 1,
        constructionName,
        contractWork,
        traderName: item?.traderName,
        paymentDate: item?.datePayment,
        targetPeriod: item?.targetPeriod,
        recordedAmount: `${helpers.formatCurrency(item?.recordedAmount)}円`,
        taxAtion2: `${helpers.formatCurrency(item?.taxAtion2)}円`,
        taxExempt2: `${helpers.formatCurrency(item?.taxExempt2)}円`,
        excludingTax: `${helpers.formatCurrency(item?.excludingTax)}円`,
        consumptionTax: `${helpers.formatCurrency(item?.consumptionTax)}円`,
        taxIncluded: `${helpers.formatCurrency(item?.taxInclude)}円`,
      };
    });
    return arr;
  };

  const exportDataCM0026 = async () => {
    try {
      dispatch(setLoading(true));
      // const data = {
      //   aggregationPeriod: {
      //     from: moment(from).startOf("month").format(COMMON.FORMAT_DATE2),
      //     to: moment(to).endOf("month").format(COMMON.FORMAT_DATE2),
      //   },
      //   outputDate: moment().format(COMMON.FORMAT_DATE_OM),
      //   tableData: formatDataExportCM0026(dataView),
      // };
      const fileName = `社内収支_外注費_案件別_${moment().format(
        COMMON.FORMAT_DATE_SUBMIT
      )}`;
      // await CM0026_TemplateExport(data, fileName, footer);
      const response = await service.doExportCM0026({
        // projectId: item.id,
        size: 10,
        page: page,
        sortBy: COMMON.DEFAULT_SORT_BY,
        sortType: COMMON.DEFAULT_SORT_TYPE,
        from: from,
        to: to,
      } as any);
      doExportForResponse(response, fileName + ".xlsx");
    } catch (e: any) {
      ErrorNotification(e?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setIsExportDataCM0026(false));
      dispatch(setLoading(false));
    }
  };

  const handleGetSummaryLine = async (params: any) => {
    try {
      const resp = await service.getLineSummary(params);
      setFooter(convertLineSummary(resp.results));
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };
  const checkHavingData = (value: boolean) => {
    dispatch(setIsHavingData(value));
  };
  return {
    dataView,
    expandKey,
    menu,
    MENU_ITEM,
    visibleCM0027,
    isNarrow,
    isNarrowModal,
    visibleView,
    sortType,
    sortBy,
    sortTypeModal,
    sortByModal,
    totalTable,
    from,
    to,
    page,
    render,
    footer,

    funcSetExpendKey,
    funcCheckKeyExpand,
    handleChangeMenuChosen,
    handleCancelModal,
    handleCheckNarrow,
    funcSetViewModal,
    funcCancelModalView,
    funcCheckSortOrder,
    funcSortTable,
    handleViewModalCM0011,
    handleGetDataTable,
    handlerScrollInfinitte,
    openCM0017_2,
    handleDeleteParams,
    exportDataCM0026,
    handleGetSummaryLine,
    checkHavingData,
  };
};

export default CM0026Handler;
