import React from 'react';
import { Modal } from 'antd';
import '../../../assets/styles/components/confirm-modal.css';
import InfoCircle from '../../../assets/icons/infor-circle.svg';

type props = {
  onOk: () => void;
  onCancel: () => void;
  canceText: string;
  okText: string;
};

const DeleteAOPModal = ({ onOk, onCancel, canceText, okText }: props) => {
  Modal.confirm({
    width: 773,
    style: {
      height: 190,
      marginLeft: 300,
    },
    centered: true,
    className: 'confirm__modal-aop',
    icon: null,
    content: (
      <section style={{ display: 'flex', justifyContent: 'start' }}>
        <div>
          <img alt="" src={InfoCircle} style={{ marginRight: 8 }} />
        </div>
        <div>
          <p style={{ margin: 0, padding: 0 }} className="h4-modal-title">
            削除確認
          </p>
          <div
            className="h4-content "
            style={{ marginTop: 16, marginBottom: 24 }}
          >
            <div>役職を削除すると、設定したユーザーの権限も失われます。</div>
            <div>削除してもよろしいでしょうか？ </div>
          </div>
        </div>
      </section>
    ),
    okText: okText,
    okType: 'primary',
    closable: false,
    cancelText: canceText,
    okButtonProps: {
      style: {
        background: '#ff7875',
      },
    },
    onOk: async () => {
      if (onOk) {
        await onOk();
      }
    },
    onCancel: () => {
      if (onCancel) {
        onCancel();
      }
    },
  });
};

export default DeleteAOPModal;
