import { MemberType } from "../../../entity/Entity";
import { CollapseContractorApi } from "./ServiceImpl";

const CollapseContractorService = (
  collapseContractorApi: CollapseContractorApi
) => {
  const getListContractorMember = async (
    params: any
  ): Promise<MemberType[]> => {
    try {
      const response = await collapseContractorApi.getListContractorMember(
        params
      );
      return response.results ?? [];

      // return (
      //   response.results.map((element: any) => ({
      //     ...element,
      //     approve: false,
      //   })) || []
      // );
    } catch (error) {
      return [];
    }
  };
  return { getListContractorMember };
};

export default CollapseContractorService;
