// Component
import {
  Button,
  DatePicker,
  Empty,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Spin,
  Tag,
} from "antd";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import TextArea from "antd/lib/input/TextArea";
import LoadingComponent from "../../../../../common/components/icons/LoadingComponent";
import { RootState } from "../../../../../store";

// Common
import locale from "antd/es/date-picker/locale/ja_JP";
import "moment/locale/ja";
import COMMON from "../../../../../common/constants/COMMON";
import helpers from "../../../../../common/helpers/common";
import MESSAGE from "../../../../../common/constants/MESSAGE";

// Icon
import CalendarSvg from "../../../../../assets/icons/calendar.svg";
import CloseIcon from "../../../../../assets/icons/x-icon-modal.svg";
import XIconModal from "../../../../../assets/icons/x-icon-modal.svg";

// Handler
import { AM022Handler } from "../handler/HandlerImpl";

// CSS
import "../../../../../assets/styles/AM/AM022.css";
import REGEX from "../../../../../common/constants/REGEX";

type Props = {
  handler: AM022Handler;
};

const AM022UI = ({ handler }: Props) => {
  const isVisible = useSelector((state: RootState) => state.am022.isVisible);
  const loading = useSelector((state: RootState) => state.am022.loading);
  const isPro = useSelector((state: RootState) => state.am010.haveProject);
  const requestId = useSelector((state: RootState) => state.am022.requestId);
  const typeModal = useSelector((state: RootState) => state.am022.typeModal);
  const isUserInWorkspace = useSelector(
    (state: RootState) => state.auth?.authData?.isUserInWorkspace
  );

  const isDisableSubmitButton = loading ||
    (handler?.requestLeave?.id ?
      !handler?.requestLeave?.approver?.fullName :
      !handler?.requestApprover?.fullName)

  useEffect(() => {
    handler.getProjectStatus();
  }, []);

  useEffect(() => {
    handler?.subCategories && handler.handleDayOffDefaultValue();
  }, [handler?.subCategories]);

  useEffect(() => {
    handler.getListSubCategoryLeave();
  }, []);

  useEffect(() => {
    handler?.requestLeave?.project?.id &&
      handler?.projectStatus &&
      handler.getProject(
        {
          page: COMMON.DEFAULT_PAGE,
          size: COMMON.DEFAULT_SIZE,
          keyword: undefined,
          statusId: handler.projectStatus,
        },
        handler.requestLeave.project.id
      );
  }, [handler?.requestLeave?.project?.id, handler?.projectStatus]);

  useEffect(() => {
    if (typeModal === "create") {
      handler.handleGetConstructionFirstOpenModal({
        page: COMMON.DEFAULT_PAGE,
        size: COMMON.DEFAULT_SIZE,
        keyword: undefined,
        statusId: handler.projectStatus,
      });
      handler.getRequestApprover();
    }
  }, [handler?.projectStatus]);

  useEffect(() => {
    handler?.requestApprover?.fullName &&
      handler.form.setFieldValue(
        "authorizedPerson",
        handler.requestApprover.fullName
      );
  }, [handler?.requestApprover]);

  useEffect(() => {
    if (typeModal === "create") {
      handler?.projectStatus && handler.getListCategoryLeave();
      handler?.projectStatus &&
        handler.handleGetProjectFirstOpenModal({
          page: COMMON.DEFAULT_PAGE,
          size: COMMON.DEFAULT_SIZE,
          statusId: handler.projectStatus,
        });
    }
  }, [handler?.projectStatus]);

  useEffect(() => {
    if (typeModal === "update")
      requestId && handler.handleGetRequestLeave(requestId);
  }, [typeModal, requestId]);

  useEffect(() => {
    if (handler?.requestLeave?.id) {
      handler.handleFillFormUpdate();
      handler.getListCategoryLeave();
    }
  }, [handler?.requestLeave?.id]);

  useEffect(() => {
    if (handler.isDirty) {
      window.addEventListener("beforeunload", helpers.alertUser);
      return () => {
        window.removeEventListener("beforeunload", helpers.alertUser);
      };
    } else {
      window.removeEventListener("beforeunload", helpers.alertUser, true);
    }
  }, [handler.isDirty]);

  return (
    <Modal
      className="group-detail-modal"
      style={{ marginLeft: 300, marginTop: 40, marginBottom: 40 }}
      visible={isVisible}
      closable={false}
      maskClosable={false}
      width={"996px"}
      centered={true}
      footer={
        <>
          <Button
            disabled={isDisableSubmitButton}
            size="large"
            type="primary"
            style={{ marginLeft: 16, width: 76, letterSpacing: -1.5 }}
            htmlType="submit"
            form="formAM022"
          >
            提出
          </Button>
          <Button
            disabled={loading}
            size="large"
            style={{ marginLeft: 16, width: 118 }}
            onClick={handler.onCancel}
          >
            キャンセル
          </Button>
        </>
      }
    >
      <Spin indicator={LoadingComponent} spinning={loading}>
        <div
          className="w-100-percent header-modal-containter"
          style={{ height: 50 }}
        >
          <div className="header-modal-title-om004 ">休暇申請</div>
          <div style={{ cursor: "pointer" }} onClick={handler.onCancel}>
            <img alt="" src={XIconModal} style={{ height: 18, width: 18 }} />
          </div>
        </div>
        <div style={{ minHeight: 224, marginTop: 24 }} className="AM022Form">
          <Form
            onValuesChange={handler.checkDirty}
            form={handler.form}
            id="formAM022"
            layout="horizontal"
            autoComplete="off"
            onFinish={(values: any) =>
              handler.handleCreateLeaveRequest(values, isUserInWorkspace)
            }
          >
            <Form.Item
              style={{ marginBottom: 16 }}
              name="category"
              labelAlign="right"
              colon={false}
              className="form-item-label"
              label={
                <div className="w-100-percent pr-20 form-label-member">
                  事由<span style={{ color: "#FE0909" }}>*</span>
                </div>
              }
              rules={[
                {
                  required: true,
                  message: MESSAGE.MESSAGE_REQUIRED_FIELD,
                },
              ]}
            >
              <Select
                className="am022__header-select"
                showArrow
                showSearch
                listHeight={210}
                filterOption={() => true}
                getPopupContainer={(trigger: any) => trigger.parentNode}
                tagRender={({ label, closable, onClose }: any) => {
                  return (
                    <Tag
                      closable={closable}
                      onClose={onClose}
                      color="rgba(231, 230, 255, 1)"
                      closeIcon={
                        <img
                          style={{ width: 10, marginLeft: 6 }}
                          alt=""
                          src={CloseIcon}
                        />
                      }
                      style={{
                        margin: "2px 4px 2px 0",
                        display: "flex",
                        alignItems: "center",
                        height: 28,
                        borderRadius: 4,
                        color: "#222222",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      {label[0]?.props?.children}
                    </Tag>
                  );
                }}
                notFoundContent={
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="該当なデータはありません"
                  />
                }
              >
                {handler.categories.map((select) => {
                  return (
                    <Select.Option
                      style={{ alignItems: "center" }}
                      key={select.categoryId}
                      value={select.categoryId}
                    >
                      {select?.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <div style={{ display: "flex" }} className="AM022Date">
              <Form.Item
                style={{ marginBottom: 16 }}
                name="dateStart"
                labelAlign="right"
                colon={false}
                className="form-item-label"
                label={
                  <div className="w-100-percent pr-20 form-label-member">
                    開始日<span style={{ color: "#FE0909" }}>*</span>
                  </div>
                }
                rules={[
                  {
                    required: true,
                    message: MESSAGE.MESSAGE_REQUIRED_FIELD,
                  },
                ]}
              >
                <DatePicker
                  placeholder=""
                  inputReadOnly
                  style={{ width: 368, height: 40 }}
                  locale={locale}
                  format={COMMON.FORMAT_DATE}
                  suffixIcon={
                    <img alt="" src={CalendarSvg} className="icon-calender" />
                  }
                  disabledDate={(value) =>
                    handler.form.getFieldValue("dateFinish") &&
                    value >=
                    handler.form.getFieldValue("dateFinish").endOf("day")
                  }
                  onChange={handler.handleChooseSecondDate}
                />
              </Form.Item>
              <Form.Item
                style={{ marginBottom: 16 }}
                name="dateFinish"
                labelAlign="right"
                colon={false}
                className="form-item-label-end-date"
                label={
                  <div className="w-100-percent pr-20 form-label-member">
                    終了日<span style={{ color: "#FE0909" }}>*</span>
                  </div>
                }
                rules={[
                  {
                    required: true,
                    message: MESSAGE.MESSAGE_REQUIRED_FIELD,
                  },
                ]}
              >
                <DatePicker
                  placeholder=""
                  inputReadOnly
                  style={{ width: 360, height: 40 }}
                  locale={locale}
                  format={COMMON.FORMAT_DATE}
                  suffixIcon={
                    <img alt="" src={CalendarSvg} className="icon-calender" />
                  }
                  disabledDate={(value) =>
                    value <= handler.form.getFieldValue("dateStart")
                  }
                  onChange={handler.handleChooseSecondDate}
                />
              </Form.Item>
            </div>
            <div className="AM022_DayOff">
              <Form.Item
                style={{ marginBottom: 16 }}
                name="dayOff"
                labelAlign="right"
                colon={false}
                className="form-item-label"
              >
                <Radio.Group onChange={handler.handleCountDayOff}>
                  {handler.subCategories.map((element: any) => (
                    <Radio
                      key={element?.categoryId}
                      value={element?.categoryId}
                    >
                      {element?.name}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
              <div
                style={{
                  marginBottom: 16,
                  marginLeft: 156,
                  fontWeight: 700,
                  fontSize: 14,
                  color: "#605BFF",
                }}
              >
                休暇日数 : {handler.numberOfDayOff}日
              </div>
            </div>
            {isUserInWorkspace ? (
              <>
                <Form.Item
                  style={{ marginBottom: 16 }}
                  name="projectName"
                  labelAlign="right"
                  colon={false}
                  className="form-item-label"
                  label={
                    <div className="w-100-percent pr-20 form-label-member">
                      案件名<span style={{ color: "#FE0909" }}>*</span>
                    </div>
                  }
                  rules={[
                    {
                      required: true,
                      message: MESSAGE.MESSAGE_REQUIRED_FIELD,
                    },
                  ]}
                >
                  <Select
                    showArrow
                    showSearch
                    listHeight={120}
                    filterOption={() => true}
                    loading={handler.loadingOption}
                    onSearch={handler.handleSearchOption}
                    onSelect={(e: any) => {
                      handler.form.setFieldValue("constructionName", null);
                      handler.resetProjectWhenSelectOrBlur(e);
                      handler.handleFindConstructions(e);
                    }}
                    onPopupScroll={handler.handleScrollProject}
                    getPopupContainer={(trigger: any) => trigger.parentNode}
                    tagRender={({ label, closable, onClose }: any) => {
                      return (
                        <Tag
                          closable={closable}
                          onClose={onClose}
                          color="rgba(231, 230, 255, 1)"
                          closeIcon={
                            <img
                              style={{ width: 10, marginLeft: 6 }}
                              alt=""
                              src={CloseIcon}
                            />
                          }
                          style={{
                            margin: "2px 4px 2px 0",
                            display: "flex",
                            alignItems: "center",
                            height: 28,
                            borderRadius: 4,
                            color: "#222222",
                            fontSize: 14,
                            fontWeight: 400,
                          }}
                        >
                          {label[0]?.props?.children}
                        </Tag>
                      );
                    }}
                    notFoundContent={
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="該当なデータはありません"
                      />
                    }
                  >
                    {handler.projects.map((select) => {
                      return (
                        <Select.Option
                          style={{
                            alignItems: "center",
                          }}
                          key={select.id}
                          value={select.id}
                        >
                          {select?.projectName}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: 16 }}
                  name="constructionName"
                  labelAlign="right"
                  colon={false}
                  className="form-item-label"
                  label={
                    <div className="w-100-percent pr-20 form-label-member">
                      工事名<span style={{ color: "#FE0909" }}>*</span>
                    </div>
                  }
                  rules={[
                    {
                      required: true,
                      message: MESSAGE.MESSAGE_REQUIRED_FIELD,
                    },
                  ]}
                >
                  <Select
                    showArrow
                    showSearch
                    listHeight={120}
                    filterOption={() => true}
                    loading={handler.loadingOption}
                    onSearch={handler.handleSearchOptionConstruction}
                    onPopupScroll={handler.handleScrollConstruction}
                    onSelect={(e: any) => {
                      handler.resetConstructionWhenSelectOrBlur(e);
                      handler.handleFindProjects(e);
                    }}
                    getPopupContainer={(trigger: any) => trigger.parentNode}
                    tagRender={({ label, closable, onClose }: any) => {
                      return (
                        <Tag
                          closable={closable}
                          onClose={onClose}
                          color="rgba(231, 230, 255, 1)"
                          closeIcon={
                            <img
                              style={{ width: 10, marginLeft: 6 }}
                              alt=""
                              src={CloseIcon}
                            />
                          }
                          style={{
                            margin: "2px 4px 2px 0",
                            display: "flex",
                            alignItems: "center",
                            height: 28,
                            borderRadius: 4,
                            color: "#222222",
                            fontSize: 14,
                            fontWeight: 400,
                          }}
                        >
                          {label[0]?.props?.children}
                        </Tag>
                      );
                    }}
                    notFoundContent={
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="該当なデータはありません"
                      />
                    }
                  >
                    {handler.constructions.map((select) => {
                      return (
                        <Select.Option
                          style={{ alignItems: "center" }}
                          key={select.id}
                          value={select.id}
                        >
                          {select?.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </>
            ) : null}

            <Form.Item
              style={{ marginBottom: 16 }}
              name="authorizedPerson"
              labelAlign="right"
              colon={false}
              className="form-item-label "
              label={
                <div className="w-100-percent pr-20 form-label-member">
                  承認者<span style={{ color: "#FE0909" }}>*</span>
                </div>
              }
            >
              <Input
                style={{ height: 40, width: 826 }}
                maxLength={50}
                disabled
              />
            </Form.Item>
            <Form.Item
              style={{ marginBottom: 16 }}
              name="comment"
              labelAlign="right"
              colon={false}
              className="form-item-label "
              label={
                <div className="w-100-percent pr-20 form-label-member">
                  備考 <span style={{ color: "#FE0909" }}></span>
                </div>
              }
              rules={[
                {
                  pattern: new RegExp(REGEX.EXCEPT_VIETNAMESE),
                  message: MESSAGE.MESSAGE_INVALID_INPUT,
                },
              ]}
            >
              <TextArea
                maxLength={3000}
                rows={4}
                placeholder="3000文字"
                style={{ resize: "none", marginBottom: 3 }}
                onBlur={(value: any) => {
                  handler.form.setFieldValue(
                    "comment",
                    value.target.value.trim()
                  );
                }}
              />
            </Form.Item>
          </Form>
        </div>
      </Spin>
    </Modal>
  );
};

export default AM022UI;
