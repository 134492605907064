import { Button, DatePicker, Form, Popover } from 'antd';
import CalendarSvg from '../../../../../../../assets/icons/calendar.svg';
import FilterIconSvg from '../../../../../../../assets/icons/filter-icon.svg';

import locale from 'antd/es/date-picker/locale/ja_JP';
import COMMON from '../../../../../../../common/constants/COMMON';
import type { RangePickerProps } from 'antd/es/date-picker';
import moment from 'moment';
import React from 'react';
import { RangeDateType } from '../../../../entity/Entity';

type Props = {
  onFilter: () => void;
  onResetFilter: () => void;
  onFromDateChange: (value: any) => void;
  onToDateChange: (value: any) => void;
  isCollapsed: boolean;
  rangeDate: RangeDateType;
};

const FilterComponent = ({
  onFilter,
  onResetFilter,
  onFromDateChange,
  onToDateChange,
  isCollapsed,
  rangeDate,
}: Props) => {
  const disabledFromDate = (current: any, rangeDate: RangeDateType) => {
    if (
      moment(current).format(COMMON.FORMAT_DATE_OM) ===
      moment(rangeDate.to).format(COMMON.FORMAT_DATE_OM)
    )
      return false;
    return current && current > rangeDate.to;
  };
  const disabledToDate = (current: any, rangeDate: RangeDateType) => {
    if (
      moment(current).format(COMMON.FORMAT_DATE_OM) ===
      moment(rangeDate.from).format(COMMON.FORMAT_DATE_OM)
    )
      return false;
    return (
      current &&
      (rangeDate.from
        ? current < rangeDate.from || current > moment().endOf('day')
        : current > moment().endOf('day'))
    );
  };
  const content = (
    <React.Fragment>
      <div className="company-filter pb-16 w-100-percent">絞込み</div>
      <label className="filter-label">開始日</label>
      <Form.Item name={'startDate'} style={{ marginTop: 8 }}>
        <DatePicker
          allowClear={false}
          format={(value: moment.Moment) => {
            const isToday = moment().isSame(value, 'day');
            if (isToday) return COMMON.TODAY;
            return value.format(COMMON.FORMAT_DATE_OM);
          }}
          onChange={onFromDateChange}
          disabledDate={(current) => disabledFromDate(current, rangeDate)}
          className="ci001-date-picker w-100-percent date-picker-border"
          inputReadOnly
          locale={locale}
          getPopupContainer={(trigger: any) => trigger.parentNode}
          placeholder=""
          suffixIcon={<img alt="" src={CalendarSvg} className="icon-calender" />}
        />
      </Form.Item>
      <label className="filter-label">終了日</label>
      <Form.Item name={'endDate'} style={{ marginTop: 8 }}>
        <DatePicker
          format={(value: moment.Moment) => {
            const isToday = moment().isSame(value, 'day');
            if (isToday) return COMMON.TODAY;
            return value.format(COMMON.FORMAT_DATE_OM);
          }}
          allowClear={false}
          onChange={onToDateChange}
          disabledDate={(current) => disabledToDate(current, rangeDate)}
          className="ci001-date-picker w-100-percent date-picker-border"
          inputReadOnly
          locale={locale}
          getPopupContainer={(trigger: any) => trigger.parentNode}
          placeholder=""
          suffixIcon={<img alt="" src={CalendarSvg} className="icon-calender" />}
        />
      </Form.Item>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button onClick={onFilter} size="large" style={{ width: 97 }} type="primary">
          絞込み
        </Button>
        <Button
          className="reset-filter-btn"
          onClick={onResetFilter}
          size="large"
          style={{ width: 97 }}
        >
          クリア
        </Button>
      </div>
    </React.Fragment>
  );

  return isCollapsed ? (
    <Popover
      getPopupContainer={(trigger: any) => trigger.parentNode}
      placement="rightTop"
      content={<div style={{ width: 258 - 24, padding: '8px 12px' }}>{content}</div>}
      trigger="click"
    >
      <div style={{ marginTop: 27, cursor: 'pointer' }} className="center-item">
        {' '}
        <img src={FilterIconSvg} alt="" />
      </div>
    </Popover>
  ) : (
    <main style={{ paddingLeft: 16, paddingRight: 16, paddingBottom: 34, paddingTop: 24 }}>
      {content}
    </main>
  );
};

export default FilterComponent;
