import React from "react";
import { Button, Collapse, DatePicker, Form, Input } from "antd";
import PlusSvg from "../../../assets/icons/plus-certificate.svg";
import CertDoc from "../../../assets/icons/certificate-doc.svg";
import SubSvg from "../../../assets/icons/sub-certificate.svg";
import CalendaSvg from "../../../assets/icons/calendar-icon.svg";
import locale from "antd/es/date-picker/locale/ja_JP";

import useCertificationFormItem from "./useCertificationFormItem";
import REGEX from "../../constants/REGEX";
import MESSAGE from "../../constants/MESSAGE";
import COMMON from "../../constants/COMMON";
import helpers from "../../helpers/common";
const { Panel } = Collapse;

type Props = {
  typeId: string;
  label: string;
  form?: any;
  initialLines?: number[] | null;
};
const CertificationFormItemComponent = ({
  typeId,
  label,
  form,
  initialLines,
}: Props) => {
  const {
    setValidIssueDate,
    setValidExpireDate,
    addLine,
    removeLine,
    lineQuantity,
  } = useCertificationFormItem(form, initialLines ?? []);

  return (
    <div className="w-100-percent collapse-no-bottom-border">
      <Collapse
        className="folder-collapse collapse-no-padding "
        defaultActiveKey={["1"]}
      >
        <Panel
          header={
            <div className="cert-header">
              <img alt="" src={CertDoc} />
              <div style={{ marginLeft: 8 }}>{label}</div>
            </div>
          }
          key="1"
        >
          {lineQuantity.map((element: any, index: number) => (
            <div
              className="input-item-container"
              key={element}
              style={{
                backgroundColor: (index + 1) % 2 === 0 ? "#FAFAFA" : "#ffffff",
              }}
            >
              <div style={{ width: 62, marginTop: 20 }}>
                <Button
                  onClick={() => removeLine(element, `cert${typeId}${element}`)}
                  style={{
                    border: "none",
                    marginRight: 11,
                    marginLeft: 19,
                    backgroundColor: "transparent",
                    boxShadow: "none",
                  }}
                  icon={<img alt="" src={SubSvg} />}
                ></Button>
              </div>
              <Form.Item
                name={[`cert${typeId}${element}`, `certTypeId`]}
                style={{ padding: 0, margin: 0 }}
                colon={false}
                initialValue={typeId}
              >
                <Input
                  hidden
                  style={{ height: 40, width: 188 }}
                  onChange={(e) => {
                    helpers.preventInputJapanese(e);
                  }}
                  maxLength={50}
                  placeholder=""
                />
              </Form.Item>
              <Form.Item
                name={[`cert${typeId}${element}`, `certId`]}
                style={{ padding: 0, margin: 0 }}
                colon={false}
                initialValue={initialLines ? element : "0"}
              >
                <Input
                  hidden
                  style={{ height: 40, width: 188 }}
                  maxLength={50}
                  placeholder=""
                />
              </Form.Item>
              <div className="form-label-certificate">項目名</div>
              <Form.Item
                className="form-item-cert "
                name={[`cert${typeId}${element}`, `name`]}
                style={{ padding: 0, margin: 0 }}
                colon={false}
                rules={[
                  {
                    whitespace: true,
                    message: MESSAGE.MESSAGE_INVALID_INPUT,
                  },
                  {
                    required: true,
                    message: MESSAGE.MESSAGE_REQUIRED_FIELD,
                  },
                  {
                    pattern: new RegExp(REGEX.EXCEPT_VIETNAMESE),
                    message: MESSAGE.MESSAGE_INVALID_INPUT,
                  },
                ]}
              >
                <Input
                  style={{ height: 40, width: 188 }}
                  maxLength={50}
                  onChange={(e) => {
                    helpers.preventInputJapanese(e);
                  }}
                  placeholder="項目名を入力してください" // CR #344212
                  onBlur={() =>
                    helpers.onBlurTrimFormItem(form, [
                      `cert${typeId}${element}`,
                      `name`,
                    ])
                  }
                />
              </Form.Item>
              <div className="form-label-certificate ml-40">取得年月日</div>
              <Form.Item
                className="form-item-cert"
                name={[`cert${typeId}${element}`, `issueDate`]}
                style={{ padding: 0, margin: 0 }}
                colon={false}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const expireDate = getFieldValue([
                        `cert${typeId}${element}`,
                        `expireDate`,
                      ]);
                      setValidExpireDate(`cert${typeId}${element}`);
                      if (expireDate && expireDate < value) {
                        return Promise.reject(new Error(MESSAGE.MESSAGE_76));
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <DatePicker
                  locale={locale}
                  format={COMMON.FORMAT_DATE}
                  suffixIcon={<img alt="" src={CalendaSvg} />}
                  style={{ height: 40, width: 160 }}
                  placeholder="[ ---- / -- / -- ]"
                />
              </Form.Item>
              <div className="form-label-certificate ml-40">取得番号</div>
              <Form.Item
                className="form-item-cert"
                name={[`cert${typeId}${element}`, `registrationCode`]}
                style={{ padding: 0, margin: 0 }}
                colon={false}
                rules={[
                  {
                    pattern: new RegExp(REGEX.EXCEPT_VIETNAMESE),
                    message: MESSAGE.MESSAGE_INVALID_INPUT,
                  },
                ]}
              >
                <Input
                  style={{ height: 40, width: 188 }}
                  maxLength={20}
                  onChange={(e) => {
                    helpers.preventInputJapanese(e);
                  }}
                  placeholder="取得番号を入力してください" // CR #344212
                  onBlur={() =>
                    helpers.onBlurTrimFormItem(form, [
                      `cert${typeId}${element}`,
                      `registrationCode`,
                    ])
                  }
                />
              </Form.Item>
              <div className="form-label-certificate ml-40">取得先</div>
              <Form.Item
                className="form-item-cert"
                name={[`cert${typeId}${element}`, `issuer`]}
                style={{ padding: 0, margin: 0 }}
                colon={false}
                rules={[
                  {
                    pattern: new RegExp(REGEX.EXCEPT_VIETNAMESE),
                    message: MESSAGE.MESSAGE_INVALID_INPUT,
                  },
                ]}
              >
                <Input
                  style={{ height: 40, width: 188 }}
                  maxLength={50}
                  onChange={(e) => {
                    helpers.preventInputJapanese(e);
                  }}
                  placeholder="取得先を入力してください"
                  onBlur={() =>
                    helpers.onBlurTrimFormItem(form, [
                      `cert${typeId}${element}`,
                      `issuer`,
                    ])
                  }
                />
              </Form.Item>
              <div className="form-label-certificate ml-40">有効期限</div>
              <Form.Item
                className="form-item-cert"
                name={[`cert${typeId}${element}`, `expireDate`]}
                style={{ padding: 0, margin: 0 }}
                colon={false}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value) return Promise.resolve();
                      const issueDate = getFieldValue([
                        `cert${typeId}${element}`,
                        `issueDate`,
                      ]);
                      setValidIssueDate(`cert${typeId}${element}`);
                      if (issueDate && issueDate > value) {
                        return Promise.reject(new Error(MESSAGE.MESSAGE_76));
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <DatePicker
                  locale={locale}
                  format={COMMON.FORMAT_DATE}
                  suffixIcon={<img alt="" src={CalendaSvg} />}
                  style={{ height: 40, width: 160 }}
                  placeholder="[ ---- / -- / -- ]"
                />
              </Form.Item>
              <Form.Item name="source" hidden>
                <Input />
              </Form.Item>
            </div>
          ))}
          <div className="plus-line">
            <Button
              onClick={addLine}
              style={{
                border: "none",
                marginRight: 11,
                marginLeft: 19,
                marginTop: 11,
                backgroundColor: "transparent",
                boxShadow: "none",
              }}
              icon={<img alt="" src={PlusSvg} />}
            ></Button>{" "}
            <div className="plus-label">項目追加</div>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

export default CertificationFormItemComponent;
