import PM011Api from "./infrastructure/api/Api";
import PM011Handler from "./presenter/handler/Handler";
import PM011UI from "./presenter/ui/UI";
import PM011Service from "./usecase/Service";

const PM011Component = () => {
  const pm011Api = PM011Api();
  const pm011Service = PM011Service(pm011Api);
  const pm011Handler = PM011Handler(pm011Service);
  return <PM011UI handler={pm011Handler} />;
};

export default PM011Component;
