import ErrorNotification from '../../../../common/components/notification/ErrorNotification';
import { NOTIFICATION_TITLE } from '../../../../common/constants/MESSAGE';
import { AOP001Api } from './ServiceImpl';

const AOP001Service = (api: AOP001Api) => {
    const getListRole = async (params: { page: number; size: number }) => {
        try {
            const response = await api.getListRole(params);
            return response?.data;
        } catch (error: any) {
            ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };

    const deleteRole = async (data: any): Promise<any> => {
        try {
            return await api.deleteRole(data);
        } catch (error) {
            throw error;
        }
    };

    return {
        getListRole,
        deleteRole,
    };
};

export default AOP001Service;
