import COMMON from "../../../common/constants/COMMON";
import { uuidv4 } from "@firebase/util";
import helpers from "../../../common/helpers/common";
import { AUTHORIZATION_CODE } from "../../../common/constants/AUTHORIZATION";

export const DEFAULT_OPTION = {
  page: COMMON.DEFAULT_PAGE,
  numPages: 0,
  keyword: "",
};

export const TYPE_I = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
};

export const TABS = ["project_permission", "system_permission"];
