import React from 'react';
import SW01Api from './infrastructure/api/Api';
import SW01Service from './usecase/Service';
import SW01Handler from './presenter/handler/Handler';
import MainUI from './presenter/ui/UI';

import '../../../assets/styles/SW/sw01.css';

const SW01Component = () => {
    const api = SW01Api();
    const service = SW01Service(api);
    const handler = SW01Handler(service);
    return <MainUI handler={handler} />;
};

export default SW01Component;
