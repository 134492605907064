import moment from "moment";
import helpers from "../../../../common/helpers/common";
import { Approval, HistoryApproval } from "../entity/Entity";
import { APIImpl } from "./ServiceImpl";
import COMMON from "../../../../common/constants/COMMON";

export const Service = (om004: APIImpl) => {
  const getProjectCRApprovalProcess = async (
    params: any
  ): Promise<HistoryApproval[]> => {
    const dataSource = await om004.getProjectCRApprovalProcess(params);
    const listApprover: Approval[] =
      dataSource.data?.results?.listApprover ?? [];
    const results: HistoryApproval[] = listApprover.map((element) => {
      const data: HistoryApproval = {
        id: element.id,
        date: element?.approveDate
          ? moment(element.approveDate).format(COMMON.FORMAT_DATE)
          : "",
        note: element.note,
        title: element.fullName,
        priority: element.priority
      };
      return data;
    });
    if (dataSource.data?.results?.submitter != null) {
      results.unshift({
        id: dataSource.data?.results?.submitter.id,
        date: dataSource.data?.results?.submitter?.approveDate
          ? moment(dataSource.data?.results?.submitter.approveDate).format(COMMON.FORMAT_DATE)
          : "",
        note: dataSource.data?.results?.submitter.note,
        title: dataSource.data?.results?.submitter.fullName,
        isSubmitter: true,
        priority: dataSource.data?.results?.submitter.priority
      })

    }
    return results;
  };

  return {
    getProjectCRApprovalProcess,
  };
};
