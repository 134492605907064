import axiosConfig from "../../../../../config/axiosConfig";
import { RetrieveOutsourceDocument } from "../../entity/Entity";

const CM0010Api = () => {
  const getListSubmittedDirectCostOutsource = async (
    params: any
  ): Promise<any> => {
    try {
      const url = "/re/submitted/direct-cost/outsource/list";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getPresignLinkS3 = async (params: any): Promise<any> => {
    const url = "/project/presign-link";
    return await axiosConfig.get(url, { params });
  };

  const retrieveOutsourceDocument = async (
    data: RetrieveOutsourceDocument
  ): Promise<any> => {
    const url = "/money/retrieve/direct-cost/outsource";
    return await axiosConfig.put(url, data);
  };

  const getMoneyDirectCostOutsourceInformationList = async (params: {
    constructionId: number;
    page?: number;
    size?: number;
    sortBy?: string;
    sortType?: string;
    keyword?: string;
  }): Promise<any> => {
    const url = "/money/direct-cost/outsource/information/list";
    return await axiosConfig.get(url, { params });
  };

  const getMoneyDirectCostOutsourceList = async (params: {
    outsourceCostId: number;
    page?: number;
    size?: number;
    sortBy?: string;
    sortType?: string;
  }): Promise<any> => {
    const url = "/money/direct-cost/outsource/list";
    return await axiosConfig.get(url, { params });
  };
  const getWorkflowOutsourceApprovalProcess = async (params: {
    documentId: number;
  }): Promise<any> => {
    const url = "/workflow/outsource-approval-process";
    return await axiosConfig.get(url, { params });
  };
  return {
    getListSubmittedDirectCostOutsource,
    getPresignLinkS3,
    retrieveOutsourceDocument,
    getMoneyDirectCostOutsourceInformationList,
    getMoneyDirectCostOutsourceList,
    getWorkflowOutsourceApprovalProcess,
  };
};

export default CM0010Api;
