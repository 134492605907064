import { OM009Api } from "./ServiceImpl";

const OM009Service = (om009: OM009Api) => {
  const deleteGroupButMember = async (data: any): Promise<any> => {
    try {
      return await om009.deleteGroupButMember(data);
    } catch (error) {
      throw error;
    }
  };
  const deleteGroupAndMembers = async (data: any): Promise<any> => {
    try {
      return await om009.deleteGroupAndMembers(data);
    } catch (error) {
      throw error;
    }
  };
  return { deleteGroupButMember, deleteGroupAndMembers };
};

export default OM009Service;
