import { Form, FormInstance, Input, Select } from 'antd';
import IconDown from '../../../../../assets/icons/arrow-down-2-plain.svg';
import REGEX from '../../../../../common/constants/REGEX';
import MESSAGE from '../../../../../common/constants/MESSAGE';
import {
    inputPhoneNumberFormat,
    onBlurTrimFormItem,
    typeInteger,
    typeIntegerORD,
} from '../../../../../common/helpers/typeInput';
import helpers from '../../../../../common/helpers/common';
import { validateID, validateInput, validateMail } from '../../helper';
import { PlanData } from '../../entity/Entity';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { MAX_LENGTH } from '../../../../../common/constants/COMMON';

const { Item } = Form;
const { Option } = Select;

interface props {
    form: FormInstance;
    planList: PlanData[];
    onPlanChange: (value: any) => void;
}
export const CommonFormItems = ({ form, planList, onPlanChange }: props) => {
    const orderId = useSelector((state: RootState) => state.ord002_1.orderId);
    return (
        <>
            <div className="ord-form-item">
                <div className="ord-form-label">
                    会社名 <span className="label-required">*</span>
                </div>
                <div className="ord-form-input">
                    <Item
                        name="companyName"
                        rules={[
                            validateInput({
                                maxLength: 50,
                                required: true,
                                regex: REGEX.NOT_VIETNAM,
                            }),
                        ]}
                    >
                        <Input
                            autoComplete="off"
                            size="large"
                            maxLength={50}
                            onBlur={() => onBlurTrimFormItem(form, 'companyName')}
                            onChange={(e) => {
                                helpers.preventInputJapanese(e);
                            }}
                        />
                    </Item>
                </div>
            </div>
            <div className="ord-form-item">
                <div className="ord-form-label">
                    ID <span className="label-required">*</span>
                </div>
                <div className="ord-form-input">
                    <Item name="adminName" rules={[validateID()]}>
                        <Input
                            autoComplete="off"
                            size="large"
                            maxLength={20}
                            onBlur={() => onBlurTrimFormItem(form, 'id')}
                            onChange={(e) => {
                                helpers.preventInputJapanese(e);
                            }}
                        />
                    </Item>
                </div>
            </div>
            <div className="ord-form-item">
                <div className="ord-form-label">
                    アドミン名 <span className="label-required">*</span>
                </div>
                <div className="ord-form-input">
                    <Item
                        name="adminFullName"
                        rules={[
                            validateInput({
                                maxLength: 50,
                                required: true,
                                regex: REGEX.NOT_VIETNAM,
                            }),
                        ]}
                    >
                        <Input
                            autoComplete="off"
                            size="large"
                            maxLength={50}
                            onBlur={() => onBlurTrimFormItem(form, 'adminFullName')}
                            onChange={(e) => {
                                helpers.preventInputJapanese(e);
                            }}
                        />
                    </Item>
                </div>
            </div>
            <div className="ord-form-item">
                <div className="ord-form-label">
                    メールアドレス <span className="label-required">*</span>
                </div>
                <div className="ord-form-input">
                    <Item
                        name="companyEmail"
                        rules={[
                            () => ({
                                validator(_, value) {
                                    // required
                                    if (!value || value.trim() === '')
                                        return Promise.reject(MESSAGE.MESSAGE_REQUIRED_FIELD);
                                    // test regex
                                    if (!REGEX.EMAIL_COMPANY.test(value.trim())) {
                                        return Promise.reject(MESSAGE.MESSAGE_WRONG_EMAIL_FORMAT);
                                    }
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                    >
                        <Input
                            // disabled={orderId ? true : false}
                            size="large"
                            maxLength={50}
                            onChange={(e) => {
                                helpers.preventInputJapanese(e);
                            }}
                            onBlur={() => helpers.onBlurTrimFormItem(form, 'companyEmail')}
                        />
                    </Item>
                </div>
            </div>
            <div className="ord-form-item">
                <div className="ord-form-label">
                    郵便番号 <span className="label-required">*</span>
                </div>
                <div className="ord-form-input">
                    <Item
                        name="postNumber"
                        rules={[
                            () => ({
                                validator(_, value) {
                                    if (value && value !== '') {
                                        value = value.trim().replaceAll(' ', '');
                                        value = value.replaceAll('-', '');
                                        if (!REGEX.REGEX_PHONE_FAX.test(value))
                                            return Promise.reject(MESSAGE.MESSAGE_LESS_THAN_7);
                                        if (value?.length < 7) {
                                            return Promise.reject(MESSAGE.MESSAGE_LESS_THAN_7);
                                        }
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        new Error(MESSAGE.MESSAGE_REQUIRED_FIELD),
                                    );
                                },
                            }),
                        ]}
                    >
                        <Input
                            size="large"
                            autoComplete="off"
                            maxLength={8}
                            onKeyPress={typeInteger}
                            onPaste={typeInteger}
                            onChange={(e) => {
                                helpers.formatZipCode(form, 'postNumber', e.target.value);
                                helpers.preventInputJapanese(e);
                            }}
                            onBlur={() => onBlurTrimFormItem(form, 'postNumber')}
                        />
                    </Item>
                </div>
            </div>
            <div className="ord-form-item">
                <div className="ord-form-label">
                    住所 <span className="label-required">*</span>
                </div>
                <div className="ord-form-input">
                    <Item
                        name="orderAddress"
                        rules={[
                            {
                                whitespace: true,
                                message: MESSAGE.MESSAGE_REQUIRED_FIELD,
                            },
                            {
                                required: true,
                                message: MESSAGE.MESSAGE_REQUIRED_FIELD,
                            },
                            {
                                max: 100,
                                message: '',
                            },
                        ]}
                    >
                        <Input
                            size="large"
                            onChange={(e) => {
                                helpers.preventInputJapanese(e);
                            }}
                            maxLength={MAX_LENGTH.ADDRESS}

                            onBlur={() => helpers.onBlurTrimFormItem(form, 'address')}
                        />
                    </Item>
                </div>
            </div>
            <div className="ord-form-item">
                <div className="ord-form-label">
                    電話番号 <span className="label-required">*</span>
                </div>
                <div className="ord-form-input">
                    <Item
                        name="phoneNumber"
                        rules={[
                            validateInput({
                                maxLength: 20,
                                required: true,
                                regex: REGEX.REGEX_PHONE_FAX_JP,
                            }),
                        ]}
                    >
                        <Input
                            onKeyPress={typeIntegerORD}
                            onPaste={typeIntegerORD}
                            autoComplete="off"
                            size="large"
                            maxLength={20}
                            onBlur={() => onBlurTrimFormItem(form, 'phoneNumber')}
                            onChange={(e) => {
                                helpers.preventInputJapanese(e);
                            }}
                        />
                    </Item>
                </div>
            </div>
            <div className="ord-form-item">
                <div className="ord-form-label">
                    FAX <span className="label-required"></span>
                </div>
                <div className="ord-form-input">
                    <Item
                        name="fax"
                        rules={[
                            validateInput({
                                maxLength: 20,
                                regex: REGEX.REGEX_PHONE_FAX_JP,
                            }),
                        ]}
                    >
                        <Input
                            autoComplete="off"
                            size="large"
                            maxLength={20}
                            onBlur={() => onBlurTrimFormItem(form, 'fax')}
                            onChange={(e) => {
                                helpers.preventInputJapanese(e);
                            }}
                            onKeyPress={typeIntegerORD}
                            onPaste={typeIntegerORD}
                        />
                    </Item>
                </div>
            </div>
            <div className="ord-form-item">
                <div className="ord-form-label">
                    プラン区分 <span className="label-required">*</span>
                </div>
                <div className="ord-form-input">
                    <Item
                        name="planId"
                        rules={[
                            {
                                required: true,
                                message: MESSAGE.MESSAGE_REQUIRED,
                            },
                        ]}
                    >
                        <Select
                            onChange={onPlanChange}
                            size="large"
                            suffixIcon={<img alt="" src={IconDown} />}
                            getPopupContainer={(trigger: any) => trigger.parentNode}
                        >
                            {planList.map((item) => (
                                <Option key={item.id} value={item.subCategory.code}>
                                    {item.subCategory.description}
                                </Option>
                            ))}
                        </Select>
                    </Item>
                </div>
            </div>
        </>
    );
};
