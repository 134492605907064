// Built-in
import React from 'react';

// Image files
import helpers from '../../../../../common/helpers/common';
import ChildTableComponent from './ChildTable';
import { GREEN_COLOR, RED_COLOR } from './UI';
import { OrderDetail } from '../../entity/Entity';
import COMMON, { ORDER_STATUS, PLAN_TYPE } from '../../../../../common/constants/COMMON';
import { ViewDateWithRange } from '../../../../../common/components/view-date/ViewDate';

type props = {
    data: any[];
    orderDetail?: OrderDetail;
};
const displayData = (orderDetail?: OrderDetail) => {
    let totalDiscount = orderDetail?.discount ?? 0;
    let totalOrderCost = orderDetail?.orderCost ?? 0;
    let totalDiscountSummary = 0;
    let totalOrderCostSummary = 0;
    orderDetail?.orderSubscription?.forEach((element) => {
        totalDiscountSummary += element.discount;
        totalOrderCostSummary += element.orderCost;
    });
    totalOrderCostSummary += totalOrderCost;
    totalDiscountSummary += totalDiscount;

    const isFree = orderDetail?.plan?.subCategory?.code === PLAN_TYPE.PLAN_FREE;

    const result = [
        {
            key: 1,
            planName: orderDetail?.plan?.subCategory?.description ?? '',
            startDate: isFree ? '' : orderDetail?.startDate,
            endDate: isFree ? '' : orderDetail?.endDate,
            statusOrder: orderDetail?.statusOrder,
            orderCost: totalOrderCost,
            discount: totalDiscount,
            child: orderDetail?.orderSubscription ?? [],
        },
    ];
    return {
        totalDiscount,
        totalOrderCost,
        tableData: result,
        totalOrderCostSummary,
        totalDiscountSummary,
    };
};

const ParentTableComponent = ({ data, orderDetail }: props) => {
    const {
        tableData,
        totalDiscount,
        totalOrderCost,
        totalOrderCostSummary,
        totalDiscountSummary,
    } = displayData(orderDetail);

    return (
        <div className="ord006-table">
            <table>
                <thead>
                    <tr>
                        <th colSpan={2} style={{ minWidth: 250 }}>
                            プラン・オプション機能
                        </th>
                        <th style={{ minWidth: 250 }}>有効期間</th>
                        <th style={{ minWidth: 250 }}>ステータス</th>
                        <th style={{ minWidth: 250 }}>基本料金</th>
                        <th style={{ minWidth: 250 }}>割引</th>
                        <th style={{ minWidth: 250 }}>月額料金</th>
                    </tr>
                </thead>
                <tbody>
                    {tableData?.map((record, index) => (
                        <React.Fragment key={record.key}>
                            <tr className="row-level-0">
                                <td colSpan={2}>{record.planName}</td>
                                <td>
                                    <ViewDateWithRange {...record} />
                                </td>
                                <td
                                    style={{
                                        color:
                                            record?.statusOrder?.code === ORDER_STATUS.ACTIVE_ORDER
                                                ? GREEN_COLOR
                                                : RED_COLOR,
                                    }}
                                >
                                    {record?.statusOrder?.name ?? ''}
                                </td>
                                <td>{`${helpers.formatNumberMoney(record.orderCost)}円`}</td>
                                <td>{`${helpers.formatNumberMoney(record.discount)}円`}</td>
                                <td>{`${helpers.formatNumberMoney(
                                    record.orderCost - record.discount,
                                )}円`}</td>
                            </tr>
                            <ChildTableComponent data={record.child} />
                        </React.Fragment>
                    ))}
                </tbody>
                <tfoot>
                    <tr className="table-foot">
                        <td colSpan={2}>合計</td>
                        <td></td>
                        <td></td>
                        <td>{`${helpers.formatNumberMoney(totalOrderCostSummary)}円`}</td>
                        <td>{`${helpers.formatNumberMoney(totalDiscountSummary)}円`}</td>
                        <td>{`${helpers.formatNumberMoney(
                            totalOrderCostSummary - totalDiscountSummary,
                        )}円`}</td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};

export default ParentTableComponent;
