import axiosConfig from "../../../../../config/axiosConfig";
import { ScheduleCategoryUpdate } from "../../entity/Entity";

const API = () => {
  const putScheduleCategoryUpdate = async (data: ScheduleCategoryUpdate) => {
    const url = "/schedule/category/update";
    return await axiosConfig.put(url, data);
  };

  const getScheduleCategoryList = async () => {
    const url = "/schedule/category/list";
    return await axiosConfig.get(url);
  };

  const getScheduleCategoryColors = async () => {
    const url = "/schedule/category/colors";
    return await axiosConfig.get(url);
  };

  return {
    putScheduleCategoryUpdate,
    getScheduleCategoryList,
    getScheduleCategoryColors,
  };
};

export default API;
