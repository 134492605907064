import PM024Api from "./infrastructure/api/Api";
import PM024Service from "./usecase/Service";
import PM024Handler from "./presenter/handler/Handler";
import PM024UI from "./presenter/ui/UI";

interface props {
  handleConfirm?: () => void;
  handleCancel?: () => void;
}

const PM024Component = (props: props) => {
  const pm024Api = PM024Api();
  const pm024Service = PM024Service(pm024Api);
  const pm024Handler = PM024Handler(pm024Service);
  return <PM024UI handler={pm024Handler} {...props} />;
};

export default PM024Component;
