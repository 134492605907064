import axiosConfig from "../../../../../config/axiosConfig";

const OM002Api = () => {
  const getGroup = async (): Promise<any> => {
    try {
      const url = "/organization/list-group";
      const response = await axiosConfig.get(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getGroupMember = async (params: any): Promise<any> => {
    try {
      const url = "/group/list-member";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const deleteMember = async (data: any): Promise<any> => {
    try {
      const url = "/member";
      const response = await axiosConfig.delete(url, { data });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  return {
    getGroup,
    getGroupMember,
    deleteMember,
  };
};

export default OM002Api;
