import { Button, Select } from "antd";

// images
import PlusIcon from "../../../../../assets/icons/SM01-button-plus.svg";
import { SM01HandlerImpl } from "../handler/HandlerImpl";
import { MODE } from "../handler/Handler";
import MonthSchedule from "./MonthSchedule";
import COMMON from "../../../../../common/constants/COMMON";
import moment from "moment";
import DaySchedule from "./DaySchedule";
import { WeekSchedule } from "./WeekSchedule";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";

type props = {
  handler: SM01HandlerImpl;
  filterDate: string;
  filterDateCalendar: string;
  dateCheck: string;
  mode: string;
};

const ScheduleComponent = ({
  handler,
  filterDate,
  filterDateCalendar,
  dateCheck,
  mode,
}: props) => {
  const isRoleAdmin = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdmin
  );

  return (
    <div className="sm01-calendar">
      <div className="calendar-wrapper">
        <div className="calendar__header">
          {mode === MODE.DAY.CODE ? (
            <div className="calendar-header-title-day">
              {moment(filterDate).format(COMMON.FORMAT_DATE_CM)}
            </div>
          ) : (
            <div className="calendar-header-title-day">
              {moment(filterDate).format(COMMON.FORMAT_DATE_JA_MONTH)}
            </div>
          )}

          <div className="calendar-header__filter">
            <div className="calendar-header__filter-label">モード</div>
            <div className="calendar-header__filter-select">
              <Select
                value={mode}
                options={[
                  {
                    value: MODE.WEEK.CODE,
                    label: MODE.WEEK.TEXT,
                  },
                  {
                    value: MODE.MONTH.CODE,
                    label: MODE.MONTH.TEXT,
                  },
                  {
                    value: MODE.DAY.CODE,
                    label: MODE.DAY.TEXT,
                  },
                ]}
                onChange={handler.onChangeMode}
                getPopupContainer={(trigger: any) => trigger.parentNode}
              />
            </div>
            {isRoleAdmin ? (
              <Button
                onClick={() => handler.openModalSM010()}
                size="large"
                className="button-FFFFFF "
              >
                カテゴリー管理
              </Button>
            ) : (
              <></>
            )}
            <Button
              size="large"
              icon={<img alt="" src={PlusIcon} style={{ marginRight: 8 }} />}
              type="primary"
              onClick={() => handler.openModalSM003()}
            >
              イベント追加
            </Button>
          </div>
        </div>
        {mode === MODE.WEEK.CODE ? (
          <div className="calendar-content">
            <WeekSchedule handler={handler} currentDate={dateCheck} />
          </div>
        ) : mode === MODE.MONTH.CODE ? (
          <div className="calendar-content-month">
            <MonthSchedule handler={handler} filterDate={filterDate} />
          </div>
        ) : (
          <div className="calendar-content-month">
            <DaySchedule handler={handler} currentDate={dateCheck} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ScheduleComponent;
