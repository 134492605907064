import moment from "moment";
import COMMON from "../../../../common/constants/COMMON";
import {
  ReSummaryCompanyProjectOutsourceCostRetrieve,
  ReSummaryCompanyProjectOutsourceCostRetrievePayload,
  ReSummarySubmittedMaterialCostCompanyProjectConstructionResults,
  ReSummarySubmittedMaterialCostCompanyProjectResults,
  ReSummarySubmittedMaterialCostCompanyResults,
} from "../entity/Entity";
import { APIImpl } from "./ServiceImpl";
import { WorkflowResponseData } from "../../CM008/entity/Entity";

const CM0021Service = (api: APIImpl) => {
  const getReSummarySubmittedMaterialCostCompany = async (params: {
    documentId: number;
  }): Promise<ReSummarySubmittedMaterialCostCompanyResults[]> => {
    try {
      const response = await api.getReSummarySubmittedMaterialCostCompany(
        params
      );
      const sortedData = (response.data?.results ?? []).sort(
        (a: any, b: any) =>
          moment(b.company.createdAt).valueOf() -
          moment(a.company.createdAt).valueOf()
      );
      return sortedData;
    } catch (error: any) {
      throw error;
    }
  };
  const getReSummarySubmittedMaterialCostCompanyProject = async (params: {
    documentCompanyId: number;
  }): Promise<ReSummarySubmittedMaterialCostCompanyProjectResults[]> => {
    try {
      const response =
        await api.getReSummarySubmittedMaterialCostCompanyProject(params);
      return response.data?.results ?? [];
    } catch (error: any) {
      throw error;
    }
  };
  const getReSummarySubmittedMaterialCostCompanyProjectConstruction =
    async (params: {
      documentProjectId: number;
    }): Promise<
      ReSummarySubmittedMaterialCostCompanyProjectConstructionResults[]
    > => {
      try {
        const sizePage = COMMON.DEFAULT_SIZE;
        const response =
          await api.getReSummarySubmittedMaterialCostCompanyProjectConstruction(
            {
              ...params,
              page: 1,
              size: sizePage,
            }
          );
        let results: ReSummarySubmittedMaterialCostCompanyProjectResults[] =
          response.data?.results ?? [];

        const numberPages = response.data?.pagination?.numPages ?? 0;
        for (let i = 2; i <= numberPages; i++) {
          const response =
            await api.getReSummarySubmittedMaterialCostCompanyProjectConstruction(
              {
                ...params,
                page: i,
                size: sizePage,
              }
            );
          results = results.concat(response.data?.results ?? []);
        }

        return response.data?.results ?? [];
      } catch (error: any) {
        throw error;
      }
    };
  const putReSummaryMaterialCostCompanyRetrieve = async (
    data: ReSummaryCompanyProjectOutsourceCostRetrievePayload
  ): Promise<ReSummaryCompanyProjectOutsourceCostRetrieve> => {
    try {
      const response = await api.putReSummaryMaterialCostCompanyRetrieve(data);
      return response.data;
    } catch (error: any) {
      throw error;
    }
  };
  const getWorkflowSummaryMaterialCompanyApprovalProcess = async (params: {
    documentId: number;
  }): Promise<WorkflowResponseData> => {
    try {
      const response =
        await api.getWorkflowSummaryMaterialCompanyApprovalProcess(params);
      return response.data;
    } catch (error: any) {
      throw error;
    }
  };
  return {
    putReSummaryMaterialCostCompanyRetrieve,
    getReSummarySubmittedMaterialCostCompanyProjectConstruction,
    getReSummarySubmittedMaterialCostCompanyProject,
    getReSummarySubmittedMaterialCostCompany,
    getWorkflowSummaryMaterialCompanyApprovalProcess,
  };
};

export default CM0021Service;
