import { useEffect, useMemo, useRef, useState } from "react";
import {
  Bar,
  Brush,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import helpers from "../../../helpers/common";
import CustomizedDotLineChart from "./CustomDotLineChart";
import CustomizedLabelXAxis from "./CustomLabelXAxis";
import CustomLegendComp from "./CustomLegend";
import CustomTooltip from "./Tooltip";

const ZOOM_AREA_SELECTOR = ".recharts-layer.recharts-brush-traveller";
const SCROLLBAR_AREA_SELECTOR = ".recharts-layer.recharts-brush";
const SLIDE_SELECTOR = ".recharts-brush-slide";

const roundedIntegerValue = (maxValueString: string) => {
  return (
    Math.ceil(
      Number(maxValueString) / Math.pow(10, maxValueString.length - 1)
    ) * Math.pow(10, maxValueString.length - 1)
  );
};

type ComposeChartDB001Props = {
  barColor: [string, string];
  chartName: string;
  chartData: any[];
  legends: {
    laborCostLegend: string;
    workingDayLegend: string;
  };
};

const removeZoomArea = (zoomAreaElements: NodeListOf<Element>) => {
  [...zoomAreaElements].forEach((el) => {
    if (el) el.remove();
  });
};

const styleScrollbar = (slideElms: NodeListOf<Element>) => {
  [...slideElms].forEach((el) => {
    el.setAttribute("rx", "4");
    el.setAttribute("ry", "4");
    el.setAttribute("style", "cursor: auto");
  });
};

const styleTrackScrollbar = (scrollAreaElements: NodeListOf<Element>) => {
  [...scrollAreaElements].forEach((el) => {
    if (el) {
      const firstRectEl = el.firstChild as Element & {
        width: { baseVal: { value: number } };
        x: { baseVal: { value: number } };
      };
      if (!firstRectEl) return;
      firstRectEl.setAttribute("rx", "4");
      firstRectEl.setAttribute("ry", "4");
      firstRectEl.setAttribute("stroke", "#f0f2f5");
      const slideItemWidthEdge = 5;
      firstRectEl.setAttribute(
        "width",
        (firstRectEl.width.baseVal.value - slideItemWidthEdge * 2).toString()
      );
      firstRectEl.setAttribute("fill", "#fbfbfb");
      firstRectEl.setAttribute(
        "x",
        (firstRectEl.x.baseVal.value + slideItemWidthEdge).toString()
      );
    }
  });
};

function ComposeChartDB001({
  barColor,
  chartName,
  chartData,
  legends,
}: ComposeChartDB001Props) {
  const containerChartRef = useRef<HTMLDivElement | null>(null);
  const [showTooltip, setShowTooltip] = useState(false);

  const maxLeftYAxis = useMemo(() => {
    const maxValueString = Math.max(
      ...chartData.map((val: any) => val.laborCost)
    )?.toString();
    return roundedIntegerValue(maxValueString);
  }, [chartData]);

  const maxRightYAxis = useMemo(() => {
    const maxValueString = Math.max(
      ...chartData.map((val: any) => val.workingDay)
    )?.toString();
    return roundedIntegerValue(maxValueString);
  }, [chartData]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!containerChartRef.current) return;

      const zoomAreaElements =
        containerChartRef.current.querySelectorAll(ZOOM_AREA_SELECTOR);
      const scrollAreaElements = containerChartRef.current.querySelectorAll(
        SCROLLBAR_AREA_SELECTOR
      );
      const slideElms =
        containerChartRef.current.querySelectorAll(SLIDE_SELECTOR);

      removeZoomArea(zoomAreaElements);
      styleScrollbar(slideElms);
      styleTrackScrollbar(scrollAreaElements);
    }, 100);

    return () => {
      timer && clearTimeout(timer);
    };
  }, [chartData]);

  return (
    <div
      ref={containerChartRef}
      style={{ width: "100%", height: "100%", position: "relative", overflow: 'hidden' }}
    >
      <div className="db001-composechart__left--label">稼働原価　円/月</div>
      <div className="db001-composechart__right--label">稼働人工数　人工/月</div>
      <ResponsiveContainer height={382}>
        <ComposedChart
          data={chartData}
          margin={{ left: 58, right: 17, top: 16 }}
          onMouseLeave={() => (showTooltip ? setShowTooltip(false) : null)}
        >
          <XAxis
            dataKey="month"
            tickLine={false}
            axisLine={false}
            domain={["dataMin", "dataMax"]}
            tick={<CustomizedLabelXAxis />}
            height={50}
            minTickGap={50}
          />
          <YAxis
            tickFormatter={(value: number) =>
              `${helpers.formatNumberWithCommas(value)}円`
            }
            axisLine={false}
            domain={[0, maxLeftYAxis]}
            yAxisId="left"
            tickLine={false}
          />
          <YAxis
            tickFormatter={(value: number) =>
              helpers.formatNumberWithCommas(value)
            }
            tickMargin={16}
            domain={[0, maxRightYAxis]}
            axisLine={false}
            yAxisId="right"
            orientation="right"
            tickLine={false}
          />
          <CartesianGrid vertical={false} stroke="#DAE2EE" strokeWidth={1} />
          <defs>
            <linearGradient id="lineColor" x1="0" y1="0" x2="1" y2="0">
              <stop offset="0%" stopColor="#FFC53D" />
              <stop offset="100%" stopColor="#EA6B38" />
            </linearGradient>
          </defs>
          <defs>
            <linearGradient
              id={`barColor_${chartName}`}
              x1="0"
              y1="0"
              x2="1"
              y2="0"
            >
              <stop offset="0%" stopColor={barColor[0]} />
              <stop offset="100%" stopColor={barColor[1]} />
            </linearGradient>
          </defs>
          <Bar
            yAxisId="left"
            dataKey="laborCost"
            barSize={40}
            fill={`url(#barColor_${chartName})`}
            // onMouseMove={() => (!showTooltip ? setShowTooltip(true) : null)}
            // onMouseOut={() => (showTooltip ? setShowTooltip(false) : null)}
            cursor="pointer"
          />
          <Line
            strokeDasharray="3 3"
            yAxisId="right"
            type="linear"
            dataKey="workingDay"
            stroke="url(#lineColor)"
            strokeWidth={1}
            dot={(props: any) => (
              <CustomizedDotLineChart {...props} size={8} />
            )}
            activeDot={(props: any) => (
              <CustomizedDotLineChart
                // onMouseMove={() => (!showTooltip ? setShowTooltip(true) : null)}
                // onMouseOut={() => (showTooltip ? setShowTooltip(false) : null)}
                {...props} size={12}
              />
            )}
          />
          <Tooltip
            // active={true}
            content={<CustomTooltip />}
            cursor={false}
          />
          {chartData.length > 12 && (
            <Brush
              dataKey="year"
              startIndex={0}
              endIndex={11}
              height={8}
            />
          )}

          <Legend
            content={(props) => (
              <CustomLegendComp
                barColor={barColor}
                name={chartName}
                legends={legends}
                {...props}
              />
            )}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}

export default ComposeChartDB001;
