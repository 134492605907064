import { createSlice } from "@reduxjs/toolkit";
import { CR001MailContractorSliceEntity } from "../type/Presenter";

const initialState: CR001MailContractorSliceEntity = {
  isVisible: false,
  selectedRowKeys: [],
  listCompany: [],
  total: 0,
};

export const cr001MailContractorSlice = createSlice({
  name: "cr001MailContractor",
  initialState,
  reducers: {
    setHideModalCR001: () => initialState,
    setViewModalCR001: (state) => {
      state.isVisible = true;
      state.selectedRowKeys = [];
    },
    setSelectedRowKeys: (state, action) => {
      state.selectedRowKeys = action.payload;
    },
    setListCompany: (state, action) => {
      state.listCompany = action.payload;
      state.total = action.payload.length;
    },
  },
});

export const {
  setHideModalCR001,
  setViewModalCR001,
  setSelectedRowKeys,
  setListCompany,
} = cr001MailContractorSlice.actions;

export default cr001MailContractorSlice.reducer;
