import ErrorNotification from '../../../../common/components/notification/ErrorNotification';
import COMMON from '../../../../common/constants/COMMON';
import { NOTIFICATION_TITLE } from '../../../../common/constants/MESSAGE';
import {
    BaseResponseType,
    ReceiveMoneyCompanyDocumentStatusData,
    ReceiveMoneyCompanySubmitPayload,
    ReceiveMoneyProjectDocumentStatusData,
    ReceiveMoneyProjectSubmitPayload,
} from '../entity/Entity';
import { CM0032Api } from './ServiceImpl';

const CM0032Service = (cm0032: CM0032Api) => {
    const getSummaryProjectReceiveMoneyList = async (params: any): Promise<any> => {
        try {
            const response = await cm0032.getSummaryProjectReceiveMoneyList({
                ...params,
            });
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return;
        }
    };

    const getSummaryProjectReceiveMoney = async (params: any): Promise<any> => {
        try {
            const response = await cm0032.getSummaryProjectReceiveMoney({
                ...params,
            });
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return;
        }
    };

    const getSummaryProjectReceiveMoneyTotal = async (params: any): Promise<any> => {
        try {
            const response = await cm0032.getSummaryProjectReceiveMoneyTotal({
                ...params,
            });
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return;
        }
    };

    // BY TRADER

    const getSummaryCompanyReceiveMoneyList = async (params: any): Promise<any> => {
        try {
            const response = await cm0032.getSummaryCompanyReceiveMoneyList({
                ...params,
            });
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return;
        }
    };

    const getSummaryCompanyProjectReceiveMoneyList = async (params: any): Promise<any> => {
        try {
            const response = await cm0032.getSummaryCompanyProjectReceiveMoneyList({
                ...params,
            });
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return;
        }
    };

    const getSummaryCompanyProjectReceiveMoney = async (params: any): Promise<any> => {
        try {
            const response = await cm0032.getSummaryCompanyProjectReceiveMoney({
                ...params,
            });
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return;
        }
    };

    const getSummaryCompanyProjectReceiveMoneyTotal = async (params: any): Promise<any> => {
        try {
            const response = await cm0032.getSummaryCompanyProjectReceiveMoneyTotal({
                ...params,
            });
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return;
        }
    };

    const getReceiveMoneyProjectDocumentStatus = async (params: {
        from: string;
        to: string;
    }): Promise<ReceiveMoneyProjectDocumentStatusData | null> => {
        try {
            const response = await cm0032.getReceiveMoneyProjectDocumentStatus(params);
            return response?.data;
        } catch (error: any) {
            ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };

    const putReceiveMoneyProjectSubmit = async (
        payload: ReceiveMoneyProjectSubmitPayload,
    ): Promise<BaseResponseType | null> => {
        try {
            const response = await cm0032.putReceiveMoneyProjectSubmit(payload);
            return response?.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };
    const getReceiveMoneyCompanyDocumentStatus = async (params: {
        from: string;
        to: string;
    }): Promise<ReceiveMoneyCompanyDocumentStatusData | null> => {
        try {
            const response = await cm0032.getReceiveMoneyCompanyDocumentStatus(params);
            return response?.data;
        } catch (error: any) {
            ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };

    const putReceiveMoneyCompanySubmit = async (
        payload: ReceiveMoneyCompanySubmitPayload,
    ): Promise<BaseResponseType | null> => {
        try {
            const response = await cm0032.putReceiveMoneyCompanySubmit(payload);
            return response?.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };

    const getReceivedMoneyOffset = async (params: any): Promise<any> => {
        try {
            const response = await cm0032.getReceivedMoneyOffset({
                ...params,
            });
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return;
        }
    };

    const doExportCM0032_1 = async (params: any): Promise<any> => {
        return await cm0032.doExportCM0032_1(params);
    }

    const doExportCM0032_2 = async (params: any): Promise<any> => {
        return await cm0032.doExportCM0032_2(params);
    }


    const getSummaryReceiveMoneyCompanyList = async (params: any): Promise<any> => {
        try {
            const response = await cm0032.getSummaryReceiveMoneyCompanyList({
                ...params,
            });
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return;
        }
    };

    const getMoneySummaryReceiveMoneyCompanyProjectList = async (params: any): Promise<any> => {
        try {
            const response = await cm0032.getMoneySummaryReceiveMoneyCompanyProjectList({
                ...params,
            });
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return;
        }
    };

    const getTaxSetting = async (params: any) => {
        try {
            const response = await cm0032.getTaxSetting({ ...params });
            return response.data;
        } catch (error: any) {
            return;
        }
    };

    return {
        getSummaryProjectReceiveMoneyList,
        getSummaryProjectReceiveMoney,
        getSummaryProjectReceiveMoneyTotal,
        getSummaryCompanyReceiveMoneyList,
        getSummaryCompanyProjectReceiveMoneyList,
        getSummaryCompanyProjectReceiveMoney,
        getSummaryCompanyProjectReceiveMoneyTotal,
        getReceiveMoneyProjectDocumentStatus,
        putReceiveMoneyProjectSubmit,
        getReceiveMoneyCompanyDocumentStatus,
        putReceiveMoneyCompanySubmit,
        getReceivedMoneyOffset,
        doExportCM0032_1,
        doExportCM0032_2,

        getSummaryReceiveMoneyCompanyList,
        getMoneySummaryReceiveMoneyCompanyProjectList,
        getTaxSetting

    };
};

export default CM0032Service;
