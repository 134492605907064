// Source files
import ErrorNotification from "../../../../common/components/notification/ErrorNotification";
import COMMON from "../../../../common/constants/COMMON";
import { NOTIFICATION_TITLE } from "../../../../common/constants/MESSAGE";
import helpers from "../../../../common/helpers/common";
import { UserTimeSheet } from "../../AM040/entity/Entity";
import { AttendanceConstructionTotalCost } from "../entity/Entity";
import { AM001APIImpl } from "./ServiceImpl";

const AM001Service = (api: AM001APIImpl) => {
  const getAttendanceSubConstructionListMember = async (params: {
    constructionId: number;
    contractorConstructionId: number;
    from: string; // 2006-01-02
    to: string; // 2006-01-02
  }): Promise<any> => {
    try {
      const timeOffset = COMMON.TIME_ZONE;
      const response = await api.getAttendanceSubConstructionListMember({
        ...params,
        timeOffset,
        from: helpers.getFilterTime(params.from),
        to: helpers.getFilterTimeTo(params.to),
      });
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return null;
    }
  };

  const getAttendanceUserTimeSheet = async (param: {
    date: string;
    constructionId?: string;
    contractorConstructionId?: string;
    userId: string;
  }): Promise<UserTimeSheet[]> => {
    try {
      const response = await api.getAttendanceUserTimeSheet({
        ...param,
        timeOffset: COMMON.TIME_ZONE,
      });
      const { results } = response.data;
      return results || [];
    } catch (error: any) {
      return [];
    }
  };
  const getAttendanceConstructionTotalCost = async (params: {
    constructionId: number;
    from: string;
    to: string;
    keyword: string;
  }): Promise<AttendanceConstructionTotalCost> => {
    try {
      const timeOffset = COMMON.TIME_ZONE;
      const response = await api.getAttendanceConstructionTotalCost({
        ...params,
        timeOffset,
        from: helpers.getFilterTime(params.from),
        to: helpers.getFilterTimeTo(params.to),
      });
      return (
        response?.data?.results ?? {
          totalLaborCost: 0,
          totalWorkingDay: 0,
        }
      );
    } catch (error: any) {
      throw error;
    }
  };
  return {
    getAttendanceSubConstructionListMember,
    getAttendanceUserTimeSheet,
    getAttendanceConstructionTotalCost,
  };
};

export default AM001Service;
