import { Button, Form, Layout } from 'antd';
import React from 'react';
import { useEffect } from 'react';
import HomeSvg from '../../../../../assets/icons/home.svg';
import helpers from '../../../../../common/helpers/common';
import { HandlerImpl } from '../handler/HandlerImpl';
import MenuWrapperComponent from '../../../../../common/components/menu-wrapper';
import BreadcrumbComponent from '../../../../../common/components/breadcrumb/BreadcrumbComponent';
import EditP01Icon from '../../../../../assets/icons/edit-full-white.svg';

import 'moment/locale/ja';
import '../../../../../assets/styles/OM/OM002.css';
import '../../../../../assets/styles/OM/OM004.css';
import '../../../../../assets/styles/OM/OM007.css';
import '../../../../../assets/styles/P/P01.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { BasicPlanComponent } from './BasicPlan';
import { PlanPersonComponent } from './UserNumber';
import { SubscriptionComponent } from './FunctionCharge';
import { FreePlanComponent } from './IssuedUser';
import { ExpirationDateComponent } from './ExpirationDate';

const BREADCRUMB_ORD007_1: any[] = [
    {
        title: '販売管理 ',
        url: '',
        icon: '',
    },
    {
        title: 'プラン設定',
        url: '',
        icon: '',
    },
];

const BREADCRUMB_ORD007: any[] = [
    {
        title: '販売管理 ',
        url: '',
        icon: '',
    },
    {
        title: 'プラン設定',
        url: '',
        icon: '',
    },
];
const { Content } = Layout;

interface props {
    handler: HandlerImpl;
}

const MainUI = ({ handler }: props) => {
    const {
        updateDataORD007,
        onCancel,
        checkDirty,
        getDataORD007,
        changeEditStatus,
        onClickMenu,
        isEditing,
        form,
        isDirty,
        isRefresh,
        planData,
        planSetting,
    } = handler;

    useEffect(() => {
        handler.getPlanSettingDetail();
    }, [isRefresh]);

    useEffect(() => {
        if (isDirty) {
            window.addEventListener('beforeunload', helpers.alertUser);
            return () => {
                window.removeEventListener('beforeunload', helpers.alertUser);
            };
        } else {
            window.removeEventListener('beforeunload', helpers.alertUser, true);
        }
    }, [isDirty]);

    return (
        <MenuWrapperComponent
            chosenKey={62}
            openKey={6}
            contentClass="site-content-p01 "
            onClickMenu={onClickMenu}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'end',
                }}
            >
                <div className="om002-header-container">
                    <BreadcrumbComponent
                        data={isEditing ? BREADCRUMB_ORD007 : BREADCRUMB_ORD007_1}
                    />
                    <div className="header-row-title" style={{ marginTop: 8 }}>
                        {isEditing ? 'プラン設定' : 'プラン設定'}
                    </div>
                </div>
                <div style={{ display: 'flex' }}>
                    {isEditing ? null : (
                        <Button
                            onClick={() => changeEditStatus(true)}
                            style={{ width: 106 }}
                            size="large"
                            type="primary"
                            className="button-purple"
                        >
                            <div className="center-item">
                                <img
                                    alt=""
                                    src={EditP01Icon}
                                    style={{ marginRight: 8, fontWeight: 700, fontSize: 14 }}
                                />
                                <div className="export-button" >
                                    編集
                                </div>
                            </div>
                        </Button>
                    )}
                </div>
            </div>
            <Layout
                style={{
                    minHeight: '84vh',
                    marginTop: 24,
                    marginBottom: 24,
                    backgroundColor: '#ffffff',
                    borderRadius: 6,
                }}
                className={'open-side'}
            >
                <Content style={{ margin: '0px 24px 24px 24px', backgroundColor: '#ffffff' }}>
                    <div style={{ marginTop: 24 }}>
                        <Form
                            className="p01-form ord007"
                            onValuesChange={checkDirty}
                            form={form}
                            id="formUpdateMemberP02"
                            layout="horizontal"
                            onFinish={updateDataORD007}
                            autoComplete="off"
                        >
                            <BasicPlanComponent
                                isEditing={isEditing}
                                basicPlanCost={planSetting?.basicPlan?.cost}
                            />
                            <PlanPersonComponent
                                isEditing={isEditing}
                                planPerson={planSetting?.basicPlan?.planPerson}
                                planUsers={planData?.planUsers ?? []}
                                planUserAddition={handler.planUserAddition}
                                addPlanUser={handler.addPlanUser}
                                removePlanUser={handler.removePlanUser}
                            />
                            <SubscriptionComponent
                                subscription={planSetting?.basicPlan?.subscription}
                                isEditing={isEditing}
                            />
                            <FreePlanComponent
                                isEditing={isEditing}
                                userNumber={planSetting?.freePlan?.userNumber}
                            />
                            <ExpirationDateComponent
                                isEditing={isEditing}
                                dueDate={planSetting?.basicPlan?.dueDate}
                            />
                        </Form>
                    </div>
                </Content>
            </Layout>
            {isEditing ? (
                <div
                    className="sticky"
                    style={{
                        width: '100%',
                        backgroundColor: '#ffffff',
                        display: 'flex',
                        padding: 24,
                        justifyContent: 'flex-end',
                    }}
                >
                    <Button
                        htmlType="submit"
                        form="formUpdateMemberP02"
                        size="large"
                        className="button-purple"
                        style={{ width: 76, letterSpacing: -1.5 }}
                    >
                        保存
                    </Button>
                    <Button
                        size="large"
                        style={{
                            marginLeft: 16,
                            width: 118,
                            fontWeight: 400,
                            fontSize: 14,
                            lineHeight: 22,
                            color: '#666666',
                        }}
                        onClick={() => onCancel()}
                    >
                        キャンセル
                    </Button>
                </div>
            ) : (
                <></>
            )}
        </MenuWrapperComponent>
    );
};

export default MainUI;
