import { Construction } from "../../entity/Entity";
import { SubConstructionType } from "./entity/Entity";
import CollapseContractorApi from "./infrastructure/api/Api";
import CollapseContractorHandler from "./presenter/handler/Handler";
import CollapseChildrenComponent from "./presenter/ui/UI";
import CollapseContractorService from "./usecase/Service";
import { FormInstance } from "antd";

type Props = {
  subconstruction: SubConstructionType,
  construction: Construction,
  form: FormInstance<any> | undefined;
  parentSubConstructionId: number
}
const CollapseContractorComponent = ({ subconstruction, form, construction, parentSubConstructionId }: Props) => {
  const collapseContractorApi = CollapseContractorApi();
  const collapseContractorService = CollapseContractorService(collapseContractorApi);
  const collapseContractorHandler = CollapseContractorHandler(collapseContractorService, subconstruction, form, parentSubConstructionId);
  return <CollapseChildrenComponent subConstruction={subconstruction} handler={collapseContractorHandler} construction={construction} />
};

export default CollapseContractorComponent;
