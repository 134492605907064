import moment from 'moment';
import COMMON from '../../../common/constants/COMMON';
import helpers from '../../../common/helpers/common';
import { DataTableOverview, TitleEntity } from './entity/Entity';

export const DOCUMENT_APPROVE_STATUS_CODE = 'money_approved';

const convertDataOverview = (information: any, data: any[]): DataTableOverview[] => {
    let totalByStatus = 0;

    data.forEach((element: any) => {
        if (element.status.code === DOCUMENT_APPROVE_STATUS_CODE) {
            totalByStatus += element?.contractorReceiveMoney ?? 0;
        }
    });
    const contractValue = information?.estimateContract
        ? information?.estimateContract[0]?.contractMoney ?? 0
        : 0;

    const obj: DataTableOverview = {
        key: '0',
        contractValue: `${helpers.formatCurrency(contractValue)}円`,
        paymentValue: `${helpers.formatCurrency(totalByStatus)}円`,
        paymentValuePercent:
            contractValue === 0
                ? 'N/A'
                : `${Math.round(((totalByStatus * 100) / contractValue) * 100) / 100}%`,
        invoiceBalance: `${helpers.formatCurrency(contractValue - totalByStatus)}円`,
    };

    return [obj];
};

const convertTitleData = (data: any): TitleEntity => {
    const obj: TitleEntity = {
        constructionId: data?.constructionId ?? 0,
        constructionName: data?.constructionName ?? '',
        expiredContract: `${moment(data?.project?.constructionFromDate).format(
            COMMON.FORMAT_DATE_CI,
        )} ～ ${moment(data?.project?.constructionToDate).format(COMMON.FORMAT_DATE_CI)}`,
        orderAddress: data?.project?.orderAddress,
        projectName: data?.project?.projectName,
        contractCreateDate: data?.contractCreateDate
            ? `${moment(data.contractCreateDate).format(COMMON.FORMAT_DATE_CI)}`
            : '',
    };

    return obj;
};

export { convertDataOverview, convertTitleData };
