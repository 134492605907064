import { memo, useEffect } from "react";
import { PM012ContentHandler } from "../handler/HandlerImpl";
import PM012Header from "./PM012Header";
import PM012Table from "./PM012Table";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";

interface props {
  handler: PM012ContentHandler;
}
function PM012Content({ handler }: props) {
  const isHasCreatedOM004 = useSelector((state: RootState) => state.om004.isHasCreatedOM004);
  const project = useSelector((state: RootState) => state.pm012.project);
  useEffect(() => {
    if (handler.chosenNavItem?.constructionId && handler.isViewNav) {
      handler.getMyInvitedMember({
        constructionId: handler.chosenNavItem?.constructionId,
        keyword: handler.keyword ? handler.keyword : undefined,
        roleId: handler?.filter ? handler.filter : undefined,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    handler.chosenNavItem?.constructionId,
    handler.keyword,
    handler.isViewNav,
    handler.refresh,
    isHasCreatedOM004,
    handler.filter,
    handler.refreshContent,
  ]);

  useEffect(() => {
    if (handler.chosenNavItem?.constructionId && !handler.isViewNav) {
      handler.getMyUninvitedMember({
        constructionId: handler.chosenNavItem?.constructionId,
        keyword: handler.keyword ? handler.keyword : undefined,
        roleId: handler?.filter ? handler.filter : undefined,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    handler.chosenNavItem?.constructionId,
    handler.keyword,
    handler.isViewNav,
    handler.refresh,
    isHasCreatedOM004,
    handler.filter,
    handler.refreshContent,
  ]);

  return (
    <div
      className={`pm012-content ${handler.isViewNav ? "" : "pm012-content-padding-none"
        }`}
    >
      <PM012Header handler={handler} />
      <div className="pm012-body">
        <PM012Table handler={handler} />
      </div>
    </div>
  );
}

export default PM012Content
