import axiosConfig from '../../../../../config/axiosConfig';
import { OrderCreationUpdatePayload } from '../../entity/Entity';

const ORD002_1Api = () => {
    const getPlanList = async () => {
        const url = '/plan/list';
        return await axiosConfig.get(url);
    };
    const getPlanSettingDetail = async () => {
        const url = '/plan/setting/detail';
        return await axiosConfig.get(url);
    };
    const postOrderCreate = async (payload: OrderCreationUpdatePayload) => {
        const url = '/order/create';
        return await axiosConfig.post(url, payload);
    };
    const putOrderUpdate = async (payload: OrderCreationUpdatePayload) => {
        const url = '/order/update';
        return await axiosConfig.put(url, payload);
    };

    const getListStatusOrder = async () => {
        const url = '/order/status/list';
        return await axiosConfig.get(url);
    };

    const getOrderDetail = async (params: { orderId: number }) => {
        const url = '/order/detail';
        return await axiosConfig.get(url, { params });
    };
    return {
        getPlanList,
        getPlanSettingDetail,
        postOrderCreate,
        putOrderUpdate,
        getListStatusOrder,
        getOrderDetail,
    };
};

export default ORD002_1Api;
