import COMMON, { PLAN_TYPE } from '../../../../../common/constants/COMMON';
import { DetailMemberEntity } from '../../../../OM/OM006/entity/Entity';
import IconKey from "../../../../../assets/icons/icon-key.svg";
import moment from 'moment';
import helpers from '../../../../../common/helpers/common';
import { DefaultAvatarComponent } from '../../../../../common/components/default-avatar';
import { RootState } from '../../../../../store';
import { useSelector } from 'react-redux';
import { CCUS_VERRIFICATION_STATE } from "../../../../../common/constants/STATUS";

type props = {
    detailMember: DetailMemberEntity | null;
};

const UserDetailComponent = ({ detailMember }: props) => {
    const isOrderBasic =
        useSelector((state: RootState) => state.auth.authData?.order?.code) ===
        PLAN_TYPE.PLAN_BASIC;

    const Avatar = () => <div
        style={{
            marginLeft: 242 + 24,
            marginBottom: 32,
        }}
    >
        <DefaultAvatarComponent
            avatar={detailMember?.avatar ?? undefined}
            value={detailMember?.fullName ?? ''}
            size={84}
        />
    </div>

    const CCUSComponent = () => <div style={{ display: 'flex', marginBottom: 16 }}>
        <div
            style={{ width: 242, textAlign: 'right', marginRight: 24 }}
            className="detail-label "
        >
            CCUS技能者ID
        </div>
        <div
            style={{ textAlign: 'left', marginRight: 24, minWidth: 129 }}
            className="detail-info "
        >
            {detailMember?.ccusId ? helpers.convertToCCUSFormat(detailMember?.ccusId) : '-'}
            {detailMember?.status == CCUS_VERRIFICATION_STATE.DONE ? (
          <img alt="" style={{ paddingLeft: 7 }} src={IconKey} />
        ) : (
          <></>
        )}
        </div>
        <div
            style={{ textAlign: 'right', marginRight: 24 }}
            className="detail-label "
        >
            有効期限
        </div>
        <div
            style={{ textAlign: 'left', marginRight: 24 }}
            className="detail-info "
        >
            {detailMember?.ccusExpiredDate
                ? moment(detailMember?.ccusExpiredDate).format(
                    COMMON.FORMAT_DATE,
                )
                : '-'}
        </div>
    </div>

    const RoleComponent = () => <div style={{ display: 'flex', marginBottom: 16 }}>
        <div
            style={{ width: 242, textAlign: 'right', marginRight: 24 }}
            className="detail-label "
        >
            役職
        </div>
        <div
            style={{ textAlign: 'left', marginRight: 24 }}
            className="detail-info "
        >
            {detailMember?.role?.name || '-'}
        </div>
    </div>
    return (
        <>
            <div>
                <Avatar />
            </div>
            <main className="w-100-percent" style={{ display: 'flex' }}>
                <section style={{ width: '100%' }}>
                    <div className="profile-attribute">
                        <div style={{ display: 'flex', marginBottom: 16 }}>
                            <div
                                style={{ width: 242, textAlign: 'right', marginRight: 24 }}
                                className="detail-label "
                            >
                                名前
                            </div>
                            <div
                                style={{ textAlign: 'left', marginRight: 24 }}
                                className="detail-info "
                            >
                                {detailMember?.fullName || '-'}
                            </div>
                        </div>
                        <div style={{ display: 'flex', marginBottom: 16 }}>
                            <div
                                style={{ width: 242, textAlign: 'right', marginRight: 24 }}
                                className="detail-label "
                            >
                                住所
                            </div>
                            <div
                                style={{ textAlign: 'left', marginRight: 24, width: 500 }}
                                className="detail-info "
                            >
                                {detailMember?.address || '-'}
                            </div>
                        </div>
                    </div>
                    <div className="profile-attribute">
                        <div style={{ display: 'flex', marginBottom: 16 }}>
                            <div
                                style={{ width: 242, textAlign: 'right', marginRight: 24 }}
                                className="detail-label "
                            >
                                部門
                            </div>
                            <div
                                style={{ textAlign: 'left', marginRight: 24 }}
                                className="detail-info "
                            >
                                {detailMember?.group?.name || '-'}
                            </div>
                        </div>
                        <div style={{ display: 'flex', marginBottom: 16 }}>
                            <div
                                style={{ width: 242, textAlign: 'right', marginRight: 24 }}
                                className="detail-label "
                            >
                                電話番号
                            </div>
                            <div
                                style={{ textAlign: 'left', marginRight: 24, width: 500 }}
                                className="detail-info "
                            >
                                {detailMember?.phoneNumber
                                    ? helpers.getPhoneNumberFormat(detailMember.phoneNumber)
                                    : '-'}
                            </div>
                        </div>
                    </div>
                    <div className="profile-attribute">
                        {
                            isOrderBasic ?
                                <RoleComponent />
                                :
                                <CCUSComponent />
                        }

                        <div style={{ display: 'flex', marginBottom: 16 }}>
                            <div
                                style={{ width: 242, textAlign: 'right', marginRight: 24 }}
                                className="detail-label "
                            >
                                ID
                            </div>
                            <div
                                style={{ textAlign: 'left', marginRight: 24, width: 500 }}
                                className="detail-info "
                            >
                                {detailMember?.username || '-'}
                            </div>
                        </div>
                    </div>
                    <div className="profile-attribute">
                        {
                            isOrderBasic ? <CCUSComponent /> : <div style={{ width: 371 }}></div>
                        }

                        <div style={{ display: 'flex', marginBottom: 16 }}>
                            <div
                                style={{ width: 242, textAlign: 'right', marginRight: 24 }}
                                className="detail-label "
                            >
                                メールアドレス
                            </div>
                            <div
                                style={{ textAlign: 'left', marginRight: 24, width: 500 }}
                                className="detail-info "
                            >
                                {detailMember?.emailAddress || '-'}
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};

export default UserDetailComponent;
