import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import ModalApprove from '../../../../../common/components/approval-flow/ModalApprove';
import MESSAGE from '../../../../../common/constants/MESSAGE';
import helpers from '../../../../../common/helpers/common';
import { RootState } from '../../../../../store';
import CM0012_2Component from '../../../CM0012_2';
import CM0026Component from '../../../CM0026/index';
import { MENU_ITEM } from '../../../CM0026/presenter/handler/Handler';
import CM017_2Component from '../../../CM017_2';
import { HandlerImpl } from '../handler/HandlerImpl';
import Header from './Header';
import Table from './Table';

interface props {
    handler: HandlerImpl;
}

const MainUI = ({ handler }: props) => {
    const isVisibleCM0017_2 = useSelector((state: RootState) => state.cm017_2.isVisible);
    const isVisibleCM0012_2 = useSelector((state: RootState) => state.cm0012_2.isVisible);
    const [searchParams, setSearchParams] = useSearchParams();
    const tab = searchParams.get('tab') || '';
    const isOpenCalendar = searchParams.get('openFilterCM0024');
    const isChangeFromTo = searchParams.get('isChangeFromTo');
    const isExportDataCM0024 = useSelector((state: RootState) => state.cm0024.isExportDataCM0024);

    useEffect(() => {
        if (tab === '3') {
            searchParams.set('menu', MENU_ITEM[0].key);
            setSearchParams(searchParams);
        }
    }, [tab]);

    useEffect(() => {
        searchParams.get(handler.PARAMS.from) === null &&
            searchParams.set(
                handler.PARAMS.from,
                helpers.getFilterTimeMonth(handler.PARAMS_DEFAULT.from),
            );

        searchParams.get(handler.PARAMS.to) === null &&
            searchParams.set(
                handler.PARAMS.to,
                helpers.getFilterTimeMonthTo(handler.PARAMS_DEFAULT.to),
            );

        searchParams.get(handler.PARAMS.from2) === null &&
            searchParams.set(
                handler.PARAMS.from2,
                helpers.getFilterTimeMonth(handler.PARAMS_DEFAULT.from),
            );

        searchParams.get(handler.PARAMS.to2) === null &&
            searchParams.set(
                handler.PARAMS.to2,
                helpers.getFilterTimeMonthTo(handler.PARAMS_DEFAULT.to),
            );
    }, []);

    useEffect(() => {
        if (handler?.menu === handler?.MENU_ITEM[0]?.key) {
            handler.handleToSetFromValue(searchParams.get(handler.PARAMS.from));
            handler.handleToSetToValue(searchParams.get(handler.PARAMS.to));
        } else {
            handler.handleToSetFromValue(searchParams.get(handler.PARAMS.from2));
            handler.handleToSetToValue(searchParams.get(handler.PARAMS.to2));
        }
    }, [handler?.menu]);

    useEffect(() => {
        if (tab === '3' && handler?.menu === handler?.MENU_ITEM[0]?.key) {
            !isOpenCalendar &&
                isChangeFromTo !== '0' &&
                handler.from &&
                handler.to &&
                handler.getListReSummaryOutsourceCostCompany(
                    helpers.getFilterTimeMonth(handler.from),
                    helpers.getFilterTimeMonthTo(handler.to),
                );
            handler.handleGetSubCategory();
            searchParams.delete('isChangeFromTo');
            setSearchParams(searchParams);
        }
    }, [handler.from, handler.to, isOpenCalendar, handler?.menu]);

    useEffect(() => {
        isExportDataCM0024 && handler.exportDataCM0024();
    }, [isExportDataCM0024]);

    useEffect(() => {
        tab === '3' &&
            !isOpenCalendar &&
            isChangeFromTo !== '0' &&
            handler.from &&
            handler.to &&
            handler?.menu === handler?.MENU_ITEM[0]?.key &&
            handler.getStatus(
                helpers.getFilterTimeMonth(handler.from),
                helpers.getFilterTimeMonthTo(handler.to),
            );
    }, [handler.from, handler.to, handler?.menu, isOpenCalendar]);

    useEffect(() => {
        tab === '3' &&
            !isOpenCalendar &&
            isChangeFromTo !== '0' &&
            handler.from2 &&
            handler.to2 &&
            handler?.menu === handler?.MENU_ITEM[1]?.key &&
            handler.getStatusCM0026(
                helpers.getFilterTimeMonth(handler.from2),
                helpers.getFilterTimeMonthTo(handler.to2),
            );
    }, [handler.from2, handler.to2, handler?.menu, isOpenCalendar]);
    useEffect(() => {
        handler.checkHavingData(handler.dataView?.length > 0)
    }, [JSON.stringify(handler.dataView)]);
    return (
        <div className="cm024">
            <Header handler={handler} />
            {handler.menu === MENU_ITEM[0].key ? (
                <Table handler={handler} />
            ) : (
                <CM0026Component />
            )}
            {isVisibleCM0017_2 ? <CM017_2Component /> : null}
            {isVisibleCM0012_2 ? <CM0012_2Component /> : null}
        </div>
    );
};

export default MainUI;
