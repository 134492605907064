import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { isRefreshPM002, setRefreshPM002 } from '../../../PM002/presenter/slice/Slice';
import { PM014FooterHandler } from '../handler/HandlerImpl';
import { pm012ChangeOpen } from '../slice/Slice';
import { isHasPrivilege } from '../../../../../common/helpers/privilege';
import { PRIVILEGE_CREATOR, PRIVILEGE_PM, PRIVILEGE_SUB_PM } from '../../../../../common/constants/PRIVILEGE';
import { RootState } from '../../../../../store';
import { SYSTEM_ROLES } from '../../../../../common/constants/AUTHORIZATION';

type props = {
  handler: PM014FooterHandler;
};

function PM014Footer({ handler }: props) {
  const dispatch = useDispatch();
  const isRefresh002 = useSelector(isRefreshPM002);
  const projectDetail = useSelector(
    (state: RootState) => state.pm005.projectDetail
  );

  const isRoleAdmin = useSelector((state: RootState) => state.auth?.authData?.isRoleAdmin)

  const isRoleAdminSystem =
    useSelector((state: RootState) => state.auth?.authData?.isRoleAdminSystem)


  return (
    <div className="pm014-footer">

      {isHasPrivilege(projectDetail, [
        PRIVILEGE_PM,
        PRIVILEGE_SUB_PM,
        PRIVILEGE_CREATOR,
      ]) || isRoleAdmin || isRoleAdminSystem ? (
        <>
          {handler.dataParentPM014 ? <h4>選択人数：{handler.checkedKeys.length}名</h4> : null}
          <Button
            size="large"
            style={{ letterSpacing: '-1px' }}
            className={`${handler.currentTab === '2' ? 'pm014-disable-button' : 'button-purple'}`}
            disabled={handler.checkedKeys.length === 0 || handler.currentTab === '2' ? true : false}
            onClick={() => handler.funcOpenModalInvite()}
          >
            確認
          </Button>
        </>
      ) : (
        <></>
      )}
      <Button
        className="pm014-button-cancel"
        onClick={() => {
          dispatch(pm012ChangeOpen(false));
          dispatch(setRefreshPM002(!isRefresh002)); // Refresh PM002
        }}
        size="large"
      >
        キャンセル
      </Button>
    </div>
  );
}

export default PM014Footer;
