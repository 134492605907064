import moment from 'moment';
import { MoneyConstructionInformationResults } from '../CM004/entity/Entity';
import { DataLocal } from '../CM0063/presenter/type/Presenter';
import {
    ExportOffsetData,
    MoneyReceivedMoneyListResultsData,
    MoneyReceivedMoneyOffset,
} from './entity/Entity';
import COMMON from '../../../common/constants/COMMON';
import helpers from '../../../common/helpers/common';
import { ExcelData } from '../../../common/helpers/export-excel/common';

const calculateEstimate = (data: MoneyConstructionInformationResults | null) => {
    if (!data || (data && !data.estimateContract)) return 0;
    let result: number = 0;
    data?.estimateContract.forEach((element) => {
        result += element.contractMoney;
    });
    return result;
};

const convertDataLocalToReceiveMoneyOffset = (value: DataLocal) => {
    let result: any = [];
    if (value?.arr && value.arr.length > 0) {
        result = value.arr.map((item: any) => {
            return {
                categoryId: Number(item.taxCategoryComsumptionId),
                name: item.item,
                value: item.offsetAmount,
            };
        });
    }
    return result;
};

export { calculateEstimate, convertDataLocalToReceiveMoneyOffset };
