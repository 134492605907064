import { NOTIFICATION_TITLE } from "../../../../common/constants/MESSAGE";
import {
  OrderCreationUpdatePayload,
  OrderDetail,
  OrderStatus,
} from "../entity/Entity";
import { ORD002_1APIImpl } from "./ServiceImpl";
const ORD002_1Service = (api: ORD002_1APIImpl) => {
  const getPlanList = async () => {
    try {
      const response = await api.getPlanList();
      return response?.data?.results ?? [];
    } catch (error: any) {
      throw error;
    }
  };

  const getPlanSettingDetail = async () => {
    try {
      const response = await api.getPlanSettingDetail();
      return response?.data?.results;
    } catch (error: any) {
      throw error;
    }
  };
  const postOrderCreate = async (
    payload: OrderCreationUpdatePayload
  ): Promise<string> => {
    try {
      const response = await api.postOrderCreate(payload);
      return response?.data?.message ?? NOTIFICATION_TITLE.SUCCESS;
    } catch (error: any) {
      throw error;
    }
  };
  const getListStatusOrder = async (): Promise<OrderStatus[]> => {
    try {
      const response = await api.getListStatusOrder();
      return response?.data?.results ?? [];
    } catch (error: any) {
      throw error;
    }
  };
  const getOrderDetail = async (params: {
    orderId: number;
  }): Promise<OrderDetail> => {
    try {
      const response = await api.getOrderDetail(params);
      return response?.data?.results;
    } catch (error: any) {
      throw error;
    }
  };
  const putOrderUpdate = async (
    payload: OrderCreationUpdatePayload
  ): Promise<string> => {
    try {
      const response = await api.putOrderUpdate(payload);
      return response?.data?.message ?? NOTIFICATION_TITLE.SUCCESS;
    } catch (error: any) {
      throw error;
    }
  };
  return {
    postOrderCreate,
    getPlanList,
    getPlanSettingDetail,
    putOrderUpdate,
    getListStatusOrder,
    getOrderDetail,
  };
};

export default ORD002_1Service;
