import CM0031Api from './infrastructure/api/Api';
import CM0031Handler from './presenter/handler/Handler';
import CM0031UI from './presenter/ui/UI';
import CM0031Service from './usecase/Service';
import '../../../assets/styles/CM/CM0028.css';

type props = {
    onSubmit?: () => void;
    onReject?: () => void;
};
const CM0031Component = (props: props) => {
    const cm0031Api = CM0031Api();
    const cm0031Service = CM0031Service(cm0031Api);
    const cm0031Handler = CM0031Handler(cm0031Service);
    return <CM0031UI handler={cm0031Handler} {...props} />;
};

export default CM0031Component;
