import { useEffect, useRef } from "react";
import BreadcrumbComponent from "../../../../../common/components/breadcrumb/BreadcrumbComponent";
import MenuWrapperComponent from "../../../../../common/components/menu-wrapper";
import { PM014Hanlder } from "../handler/HandlerImpl";
import EmptyData from "../../../../../assets/icons/empty-data.svg";
import "../../../../../assets/styles/PM/PM005.css";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import PM007Component from "../../../PM007";
import BREADCRUMB from "../../../../../common/constants/BREADCRUMB";
import ImageViewerModalComponent from "../../../../../common/components/image-viewer-modal/ImageViewerComponent";
import PdfViewerModalComponent from "../../../../../common/components/pdf-viewer-modal/PdfViewerModalComponent";
import ProjectInformation from "./ProjectInformation";
import ConstructionRegistrationComponent from "./ConstructionRegistration";
import PM012Component from "../../../PM012";
import { setIsModalOpen } from "../../../PM007/presenter/slice/Slice";
interface props {
  handler: PM014Hanlder;
}

const PM005UI = ({ handler }: props) => {
  const didMountRef = useRef(false);
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const isRefresh = useSelector((state: RootState) => state.pm005.isRefresh);
  const isShowPM007 = useSelector(
    (state: RootState) => state.pm007.isModalOpen
  );
  const isOpenPM011 = useSelector(
    (state: RootState) => state.pm011.isOpenPM011
  );
  const isOpen = useSelector((state: RootState) => state.pm012.isOpen);
  const refreshPM012 = useSelector(
    (state: RootState) => state.pm012.refreshPM012
  );

  useEffect(() => {
    if (isOpenPM011) handler.hideModalPM011();
    didMountRef.current = true;
    localStorage.removeItem("projectManagerId");
    return () => {
      dispatch(setIsModalOpen(false));
    };
  }, []);

  useEffect(() => {
    const body = document.getElementById("body-content");
    if (body) {
      body.style.scrollBehavior = "smooth";
      body?.scrollTo(0, body.scrollHeight * 2);
    }
  }, [handler.isScroll]);

  useEffect(() => {
    handler.getDetailProject({ projectId: projectId });
  }, [projectId, isRefresh, refreshPM012]);

  return (
    <MenuWrapperComponent chosenKey={81} openKey={8}>
      <div className="om002-header-container" style={{ marginBottom: 32 }}>
        <BreadcrumbComponent data={BREADCRUMB.PM007} />
        <div className="header-row-title" style={{ marginTop: 8 }}>
          案件詳細
        </div>
      </div>

      <div className="content-container">
        {Object.keys(handler.projectInfo)?.length > 0 ? (
          <>
            <ProjectInformation handler={handler} />
            <ConstructionRegistrationComponent handler={handler} />
          </>
        ) : (
          <div className="data-empty" style={{ height: "75vh" }}>
            <img src={EmptyData} alt="" />
          </div>
        )}
      </div>
      <PM007Component />
      {!isShowPM007 ? (
        <>
          <ImageViewerModalComponent
            imageURL={handler.pdfUrl}
            marginLeft={300}
            title="現場案内図"
          />
          <PdfViewerModalComponent
            pdfURL={handler.pdfUrl}
            marginLeft={300}
            title="現場案内図"
          />
        </>
      ) : (
        <></>
      )}
      {isOpen ? <PM012Component /> : <></>}
    </MenuWrapperComponent>
  );
};

export default PM005UI;
