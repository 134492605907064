import { ColumnsType } from "antd/lib/table";
import { AM028TableHandler } from "../handler/HandlerImpl";
import { STATUS_REQUEST, MENU_ITEM } from "../handler/Handler";
import moment from "moment";
import COMMON from "../../../../../common/constants/COMMON";
import helpers from "../../../../../common/helpers/common";

interface DataType {
  no: string;
  rejectReason: string;
  status: {
    code: string;
  };
  startDate: string;
  endDate: string;
  subCategory: {
    code: string;
  };
}

const SUB_CATEGORY_FULL_DAY = "full_day";
export const columns = (handler: AM028TableHandler): ColumnsType<DataType> => {
  let column: ColumnsType<DataType> = [];
  if (handler.menuChosen === MENU_ITEM[0].key) {
    column = [
      {
        key: "requestDate",
        dataIndex: "requestDate",
        title: "対象日",
        sorter: true,
        showSorterTooltip: false,
        sortDirections: ["ascend", "descend", "ascend"],
        sortOrder: handler.handleSetDefaultSorter("requestDate"),
        width: 150,
        render: (text) => {
          if (!text) return "-";
          return moment(text).format(COMMON.FORMAT_DATE);
        },
      },
      {
        key: "project",
        dataIndex: "project",
        title: "案件名",
        width: 200,
        render: (text) => {
          if (!text) return "-";
          return text?.name || "-";
        },
      },
      {
        key: "construction",
        dataIndex: "construction",
        title: "工事名",
        width: 200,
        render: (text) => {
          if (!text) return "-";
          return text?.name || "-";
        },
      },
      {
        key: "requestDate",
        dataIndex: "requestDate",
        title: "時間",
        width: 140,
        render: (text) => {
          if (!text) return "-";
          return moment(text).format(COMMON.FORMAT_TIME1);
        },
      },
      {
        key: "category",
        dataIndex: "category",
        title: "事由",
        width: 120,
        render: (text) => {
          if (!text) return "-";
          return text?.description || "-";
        },
      },
      {
        key: "status",
        dataIndex: "status",
        title: "ステータス",
        width: 120,
        render: (text, row) => {
          if (!text) return "-";
          const findStatus = STATUS_REQUEST.find(
            (status) => status.code === text?.code
          );
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ color: findStatus?.color }}>{text?.name}</span>
              {findStatus?.icon ? (
                <img
                  style={{ width: 18, marginLeft: 8, cursor: "pointer" }}
                  alt=""
                  src={findStatus.icon}
                  onClick={() => handler.funcViewModalAM038(row?.rejectReason)}
                />
              ) : null}
            </div>
          );
        },
      },
      {
        key: "note",
        dataIndex: "note",
        title: "備考",
        width: 140,
        className: "column-max column-min-50 column-max",
        render: (text) => {
          if (!text) return "-";
          return text;
        },
      },
      {
        key: "approver",
        dataIndex: "approver",
        title: "承認者",
        width: 140,

        className: "column-max column-min-50",
        render: (text) => {
          if (!text) return "-";
          return text?.fullName || text.username || "-";
        },
      },
    ];
  }
  if (handler.menuChosen === MENU_ITEM[1].key) {
    column = [
      {
        key: "startDate",
        dataIndex: "startDate",
        title: "開始日",
        width: 110,
        sorter: true,
        showSorterTooltip: false,
        sortDirections: ["ascend", "descend", "ascend"],
        sortOrder: handler.handleSetDefaultSorter("startDate"),
        render: (text) => {
          if (!text) return "-";
          return moment(text).format(COMMON.FORMAT_DATE);
        },
      },
      {
        key: "endDate",
        dataIndex: "endDate",
        title: "終了日",
        width: 110,
        sorter: true,
        showSorterTooltip: false,
        sortDirections: ["ascend", "descend", "ascend"],
        sortOrder: handler.handleSetDefaultSorter("endDate"),
        render: (text) => {
          if (!text) return "-";
          return moment(text).format(COMMON.FORMAT_DATE);
        },
      },
      {
        key: "category",
        dataIndex: "category",
        title: "事由",
        width: 100,
        render: (text) => {
          if (!text) return "-";
          return text?.description || "-";
        },
      },
      {
        key: "subCategory",
        dataIndex: "subCategory",
        title: "区分",
        width: 100,
        render: (text) => {
          if (!text) return "-";
          return text?.description || "-";
        },
      },
      {
        key: "vacationDay",
        dataIndex: "vacationDay",
        title: "休暇日数",
        width: 90,
        render: (_, row) => {
          if (!row?.startDate && !row?.endDate) return "-";
          const period =
            moment(row?.endDate).diff(moment(row?.startDate), "days") + 1;

          if (row.subCategory?.code !== SUB_CATEGORY_FULL_DAY)
            return `${period / 2}日`;
          return `${period}日`;
        },
      },
      {
        key: "project",
        dataIndex: "project",
        title: "案件名",
        width: 160,
        render: (text) => {
          if (!text) return "-";
          return text?.name || "-";
        },
      },
      {
        key: "construction",
        dataIndex: "construction",
        title: "工事名",
        width: 160,
        render: (text) => {
          if (!text) return "-";
          return text?.name || "-";
        },
      },
      {
        key: "status",
        dataIndex: "status",
        title: "ステータス",
        width: 120,
        render: (text, row) => {
          if (!text) return "-";
          const findStatus = STATUS_REQUEST.find(
            (status) => status.code === text?.code
          );
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ color: findStatus?.color }}>{text?.name}</span>
              {findStatus?.icon ? (
                <img
                  style={{ width: 18, marginLeft: 8, cursor: "pointer" }}
                  alt=""
                  src={findStatus.icon}
                  onClick={() => handler.funcViewModalAM038(row?.rejectReason)}
                />
              ) : null}
            </div>
          );
        },
      },
      {
        key: "note",
        dataIndex: "note",
        width: 140,
        title: "備考",
        className: "column-max column-min-50 column-max",
        render: (text) => {
          if (!text) return "-";
          return text;
        },
      },
      {
        key: "approver",
        dataIndex: "approver",
        width: 140,
        title: "承認者",
        render: (text) => {
          if (!text) return "-";
          return text?.fullName || text?.userName;
        },
      },
    ];
  }

  if (handler.menuChosen === MENU_ITEM[2].key) {
    column = [
      {
        key: "requestDate",
        dataIndex: "requestDate",
        title: "対象日",
        width: 150,
        sorter: true,
        showSorterTooltip: false,
        sortDirections: ["ascend", "descend", "ascend"],
        sortOrder: handler.handleSetDefaultSorter("requestDate"),
        render: (text) => {
          if (!text) return "-";
          return moment(text).format(COMMON.FORMAT_DATE);
        },
      },
      {
        key: "project",
        dataIndex: "project",
        title: "案件名",
        width: 200,
        render: (text) => {
          if (!text) return "-";
          return text?.name || "-";
        },
      },
      {
        key: "construction",
        dataIndex: "construction",
        title: "工事名",
        width: 200,
        render: (text) => {
          if (!text) return "-";
          return text?.name || "-";
        },
      },
      {
        key: "timeRequest",
        dataIndex: "timeRequest",
        title: "時間",
        className: "column-max column-min-50",
        render: (text) => {
          if (!text) return "-";
          return `${text}分`;
        },
      },
      {
        key: "category",
        dataIndex: "category",
        title: "事由",
        className: "column-max column-min-50",
        render: (text) => {
          if (!text) return "-";
          return text?.description || "-";
        },
      },
      {
        key: "status",
        dataIndex: "status",
        title: "ステータス",
        width: 120,
        render: (text, row) => {
          if (!text) return "-";
          const findStatus = STATUS_REQUEST.find(
            (status) => status.code === text?.code
          );
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ color: findStatus?.color }}>{text?.name}</span>
              {findStatus?.icon ? (
                <img
                  style={{ width: 18, marginLeft: 8, cursor: "pointer" }}
                  alt=""
                  src={findStatus.icon}
                  onClick={() => handler.funcViewModalAM038(row?.rejectReason)}
                />
              ) : null}
            </div>
          );
        },
      },
      {
        key: "note",
        dataIndex: "note",
        title: "備考",
        width: 140,
        className: "column-max column-min-50 column-max",
        render: (text) => {
          if (!text) return "-";
          return text;
        },
      },
      {
        key: "approver",
        dataIndex: "approver",
        className: "column-max column-min-50",
        title: "承認者",
        width: 140,
        render: (text) => {
          if (!text) return "-";
          return text?.fullName || text.username || "-";
        },
      },
    ];
  }

  if (handler.currentTab === "2") {
    column.unshift({
      key: "user",
      dataIndex: "user",
      title: "社員名",
      width: 120,
      render: (text) => {
        if (!text) return "-";
        return text?.fullName || text.username || "-";
      },
    });
  }
  return [
    {
      key: "no",
      dataIndex: "no",
      title: "No",
      width: 70,
      render: (text) => {
        if (!text) return "-";
        return text;
      },
    },
    ...column,
  ];
};
