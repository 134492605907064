import DoubleCheckApi from "../../../infrastructure/api/ApiDoubleCheck";
import DoubleCheckService from "../../../usecase/ServiceDoubleCheck";
import DoubleCheckHandler from "../../handler/HandlerDoubleCheck";
import DoubleCheckUI from "./ui/UI";

interface Props {
  doubleCheck: boolean | null,
  constructionId: number;
  userTimeSheetId: number;
}

const DoubleCheckComponent = ({ doubleCheck, constructionId, userTimeSheetId
}: Props) => {

  const api = DoubleCheckApi()
  const service = DoubleCheckService(api)
  const handler = DoubleCheckHandler(service)

  return (
    <DoubleCheckUI
      handler={handler}
      doubleCheck={doubleCheck}
      constructionId={constructionId}
      userTimeSheetId={userTimeSheetId}
    />
  );
};

export default DoubleCheckComponent;
