import React from "react";
import { Button, Modal, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { hideIC5Modal } from "./IC5ModalSlice";
import XIconModal from "../../../assets/icons/x-icon-modal.svg";
import "../../../assets/styles/components/h4-modal.css";
import "../../../assets/styles/components/h1-modal.css";
import "../../../assets/styles/components/ic5-modal.css";
import { showIC4Modal } from "../../../pages/IC/IC4/presenter/slice/Slice";

const IC5Modal = () => {
  const dispatch = useDispatch();
  const isOpenIC5Modal = useSelector(
    (state: RootState) => state.ic5Modal.isOpenIC5
  );

  const handleCancel = () => {
    dispatch(hideIC5Modal());
  };

  const handleSubmit = () => {
    dispatch(hideIC5Modal());
    dispatch(showIC4Modal());
  };

  return (
    <Modal
      className="group-detail-modal h1-modal ic5-modal"
      style={{ marginLeft: 300, marginTop: 40, marginBottom: 40 }}
      visible={isOpenIC5Modal}
      closable={false}
      maskClosable={false}
      width={"43%"}
      centered={true}
      footer={
        <Button
          size="large"
          type="primary"
          style={{ width: 76 }}
          onClick={() => handleSubmit()}
        >
          次へ
        </Button>
      }
    >
      <div
        className="w-100-percent header-modal-containter"
        style={{ height: 56 }}
      >
        <div className="header-modal-title-om004 ">
          重要なお知らせ：メールアドレスの変更に関するご案内
        </div>
        <div style={{ cursor: "pointer" }} onClick={handleCancel}>
          <img alt="" src={XIconModal} style={{ height: 18, width: 18 }} />
        </div>
      </div>
      <div className="ic5-modal-container">
        <div className="text-blue">※必ずご一読ください。</div>
        <div>
          <div className="text-content">
            ONE Unitをご利用いただき、ありがとうございます。
          </div>
          <div className="text-content">
            メールアドレスの変更について、いくつかお知らせ事項がございますので、ご注意お願いいたします。
          </div>
        </div>

        <div>
          <div className="text-content">
            1. アドミンのメールアドレス変更について
          </div>
          <div className="text-content">
            アドミン（管理者）のメールアドレスは、ユーザーでは変更できません。
          </div>
          <div className="text-content">
            メールアドレス変更依頼フォームよりユーザーが変更依頼したアドミンのメールアドレスは、
          </div>
          <div className="text-content">ONE Unitサポートが対応いたします。</div>
        </div>

        <div>
          <div style={{ marginBottom: 32 }}>
            <div className="text-content">
              2. メールアドレスの変更に際しての注意事項
            </div>
            <div className="text-content">
              メールアドレスの変更は、セキュリティと正確性が非常に重要な操作です。
            </div>
            <div className="text-content">
              以下の点に気をつけて変更依頼を行ってください。
            </div>
            <div className="text-content">
              ONE Unitサポートが対応いたします。
            </div>
          </div>
          <div>
            <div className="text-content">
              ・新しいメールアドレスを慎重に入力してください。誤った情報を提供しないようにご注意ください。
            </div>
            <div className="text-content">
              ・ONE
              Unitサポートからのメールに対してのみ操作を行い、不審なメールには絶対に反応しないでください。
            </div>
            <div className="text-content">
              ・メールアドレスの変更時にパスワードも更新し、セキュリティを強化してください。
            </div>
          </div>
        </div>

        <div>
          <div className="text-content">
            3. 変更依頼から完了までの時間がかかります
          </div>
          <div className="text-content">
            メールアドレスの変更依頼を受け付けてから、変更が完了するまでに時間がかかる場合があります。
          </div>
          <div className="text-content">
            これはセキュリティ対策の一環であり、アカウントの安全を確保するためです。
          </div>
          <div className="text-content">お手続き完了までお待ちください。</div>
        </div>

        <div>
          <div style={{ marginBottom: 32 }}>
            <div className="text-content">4. 変更完了メールの受信</div>
            <div className="text-content">
              変更が正常に完了した場合、新しいメールアドレスに変更完了メールが送信されます。
            </div>
            <div className="text-content">
              変更内容を確認するために、このメールをご確認いただきますようお願いします。
            </div>
          </div>
          <div>
            <div className="text-content">
              ご不明点や質問がございましたら、お気軽にONE
              Unitサポートまでお問い合わせください。
            </div>
            <div className="text-content">
              ONE Unitをご愛顧いただき、ありがとうございます。
            </div>
            <div className="text-content">引き続きご利用お願いいたします。</div>
          </div>
        </div>

        <div className="text-content">ONE Unitサポートより</div>
      </div>
    </Modal>
  );
};

export default IC5Modal;
