import React, { useEffect } from 'react';
import CM007Handler from './presenter/handler/Handler';
import MainUI from './presenter/ui/UI';

import '../../../assets/styles/AM/AM028.css';
import '../../../assets/styles/AM/AM002.css';
import '../../../assets/styles/OM/OM002.css';
import '../../../assets/styles/CM/cm3854.css';
import '../../../assets/styles/CM/CM007.css';

const CM007Component = () => {
    const handler = CM007Handler();
    return <MainUI handler={handler} />;
};

export default CM007Component;
