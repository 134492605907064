import { Button, Table, Badge } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import IconMap from "../../../../../assets/icons/ic_map.svg";
import NotificationBing from "../../../../../assets/icons/notification-bing.svg";
import AddUserSvg from "../../../../../assets/icons/Add User.svg";
import EmptyData from "../../../../../assets/icons/empty-data.svg";
import { PM003DataTableHandler } from "../handler/HandlerImpl";

export interface DataType {
  projectName: string;
  constructionName: string;
  picName: string;
  constructionToDate: string;
  location: string;
  orderAddress: string;
  statusId: string;
  notificationNumber: number;
  document: string;
}
interface props {
  handler: PM003DataTableHandler;
}

const PM003DataTableUI = ({ handler }: props) => {
  const data = useSelector((state: RootState) => state.pm003.data);

  const columns: ColumnsType<DataType> = [
    {
      title: "案件名",
      dataIndex: "projectName",
      key: "projectName",
      render: (text: any) => {
        if (!text) return "-";
        return text;
      },
      width: 160,
    },
    {
      title: "ステータス",
      key: "statusName",
      dataIndex: "statusName",
      width: 110,
      render: (text: any, record: any) => {
        if (!text) return "-";
        return (
          <div
            style={{
              color:
                record.statusName === "工事完了"
                  ? "#0FA44A"
                  : record.statusName === "不調"
                  ? "#FF0909"
                  : record.statusName === "見積中"
                  ? "#FF9054"
                  : "#0050AE",
            }}
          >
            {text}
          </div>
        );
      },
    },
    {
      title: "案件担当者",
      key: "projectManager",
      dataIndex: "projectManager",
      render: (text: any) => {
        if (!text) return "-";
        return <div>{text?.fullName || text?.username || "-"}</div>;
      },
      width: 110,
    },
    {
      title: "案件副担当者",
      key: "deputyProjectManager",
      dataIndex: "deputyProjectManager",
      width: 200,
      render: (text: any) => {
        if (!text || text.length === 0) return "-";
        let results = "";
        for (const str of text) {
          results += `${results ? "、" : ""}${str.fullName || str.username}`;
        }
        return results;
      },
    },

    {
      title: "発注元会社",
      dataIndex: "orderAddress",
      key: "orderAddress",
      render: (text) => {
        if (!text) return "-";
        return text;
      },
      width: 140,
    },
    {
      title: "契約工事期間",
      key: "constructionDate",
      dataIndex: "constructionDate",
      render: (text) => {
        if (!text) return "-";
        return text;
      },
      width: 300,
    },
    {
      title: "工事名",
      key: "constructions",
      dataIndex: "constructions",
      width: 180,
      render: (text: any, row: any) => {
        if (!text) return "-";
        const construction: any = [];
        text?.forEach((element: any) => {
          if (element.code === "main") {
            construction.unshift(element);
          } else construction.push(element);
        });

        let constructionStr = "";
        for (const item of construction)
          constructionStr += `${constructionStr ? "、" : ""}${item.name}`;
        return constructionStr;
      },
    },
    {
      title: "住所",
      key: "location",
      dataIndex: "location",
      render: (text: any) => {
        if (!text) return "-";
        return text;
      },
      width: 160,
    },
    {
      title: "案件作成者",
      key: "creator",
      dataIndex: "creator",
      render: (_, record: any) => {
        return record?.projectCreator?.fullName ?? "-";
      },
      width: 160,
    },
    {
      title: "操作",
      key: "operation",
      render: (record: DataType, row) => {
        return (
          <div
            className="table-action"
            style={{ display: "flex" }}
            onClick={(e) => e.stopPropagation()}
          >
            <Button
              onClick={(event) => {
                event.stopPropagation();
                if (row?.document) {
                  handler.onShowPdfMap(record);
                }
              }}
              type="link"
              style={{ border: "none" }}
              icon={
                <img
                  style={{ width: "30px", height: "24px" }}
                  alt=""
                  src={IconMap}
                />
              }
            ></Button>

            <div
              onClick={(e) => {
                e.stopPropagation();
                handler.handleViewNotification(row);
              }}
            >
              <Badge
                count={row?.notificationNumber}
                className="avatar-badge-button-table"
              >
                <Button
                  type="link"
                  style={{ border: "none" }}
                  icon={
                    <img
                      alt=""
                      style={{ width: "30px", height: "20px" }}
                      src={NotificationBing}
                    />
                  }
                ></Button>
              </Badge>
            </div>
            <Button
              type="link"
              onClick={() => handler.handleOpenAddMember(row)}
              style={{ border: "none" }}
              icon={<img alt="" style={{ width: "24px" }} src={AddUserSvg} />}
            ></Button>
          </div>
        );
      },
      width: 100,
    },
  ];
  return (
    <>
      {Object.keys(data)?.length > 0 ? (
        <Table
          className="pm003-table"
          pagination={false}
          dataSource={data}
          showSorterTooltip={false}
          columns={columns}
          onRow={handler.onViewDetail}
          scroll={{ x: "max-content", y: "50vh" }}
        />
      ) : (
        <div className="data-empty" style={{ height: "56vh" }}>
          <img src={EmptyData} alt="" />
        </div>
      )}
    </>
  );
};
export default PM003DataTableUI;
