import AM002Api from './infrastructure/api/Api';
import AM002Handler from './presenter/handler/Handler';
import MainUI from './presenter/ui/UI';
import AM002Service from './usecase/Service';

import '../../../assets/styles/AM/AM002.css';
import '../../../assets/styles/OM/OM002.css';

const AM002Component = () => {
  const api = AM002Api();

  const service = AM002Service(api);

  const handler = AM002Handler(service);

  return <MainUI handler={handler} />;
};

export default AM002Component;
