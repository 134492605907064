import CM0028Api from './infrastructure/api/Api';
import CM0028Handler from './presenter/handler/Handler';
import CM0028UI from './presenter/ui/UI';
import CM0028Service from './usecase/Service';
import '../../../assets/styles/CM/CM0028.css';
const CM0028Component = () => {
    const cm0028Api = CM0028Api();
    const cm0028Service = CM0028Service(cm0028Api);
    const cm0028Handler = CM0028Handler(cm0028Service);
    return <CM0028UI handler={cm0028Handler} />;
};

export default CM0028Component;
