import { useDispatch } from "react-redux";
// Library
import { useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

// Service
import { CM003UseCase } from "../../usecase/ServiceImpl";
import {
  setIsVisibleCM0063,
  setParentCodeCM0063,
} from "../../../CM0063/presenter/slice/Slice";
import COMMON from "../../../../../common/constants/COMMON";
import { Form } from "antd";
import moment from "moment";
import {
  ChildrenDataEntity,
  DataTotalSummaryEntity,
  SummaryDataEntity,
  TableDataEntity,
} from "../../entity/Entity";
import {
  convertDataExportCM003,
  convertDataTable,
  convertNestedData,
  convertSummaryTable,
} from "../../helper";
import { CM003TemplateExport } from "../../../../../common/helpers/exports/cm/template_cm003";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import { showImageViewerModal } from "../../../../../common/components/image-viewer-modal/ImageViewSlice";
import { doExportForResponse } from "../../../../../common/helpers/exports/common";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../../common/constants/MESSAGE";
import helpers from "../../../../../common/helpers/common";
import { showPdfViewerModal } from "../../../../../common/components/pdf-viewer-modal/pdfViewerModalSlice";

export const PROJECT_PARAM_ID_KEY = "projectId";
export const CONSTRUCTION_ID_KEY = "constructionId";
export const PROJECT_FILTER_PARAM = "projectFilterId";
export const FROM_DATE_FILTER = "fromDate";
export const TO_DATE_FILTER = "toDate";

const SCREEN_NAME_VIEW = "CM005";

const CM003Handler = (cm003Service: CM003UseCase) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [form] = Form.useForm();
  const [loadingOption, setLoadingOption] = useState(false);
  const [keywordOptionConstruction, setKeywordOptionConstruction] =
    useState<any>(undefined);
  const [pageOptionConstruction, setPageOptionConstruction] = useState(
    COMMON.DEFAULT_PAGE
  );
  const [sizeOptionConstruction, setSizeOptionConstruction] = useState(
    COMMON.DEFAULT_SIZE
  );
  const [constructions, setConstructions] = useState<any[]>([]);
  const [summaryData, setSummaryData] = useState<SummaryDataEntity>({
    contractAmount: "0",
    billingVolume: "0",
    threshold: "0",
    rateValue: "0",
  });
  const [data, setData] = useState<TableDataEntity[]>([]);
  const [dataTotal, setDataTotal] = useState<DataTotalSummaryEntity>({
    ReceiveMoneyTotal: 0,
    OffsetMoneyTotal: 0,
    ContractorMoneyTotal: 0,
    PlanningTotal: 0,
  });
  const [documentPath, setDocumentPath] = useState<string>("");
  const [pdfName, setPdfName] = useState<string>("");

  const openModalCM0063 = (documentId: number) => {
    dispatch(setIsVisibleCM0063(true));
    dispatch(
      setParentCodeCM0063({
        parentRecordId: documentId,
        status: false,
      })
    );
  };

  // Logical call service

  const formatConstructions = (data: any) => {
    const arr: any = [];
    data.map((item: any) => {
      arr.push({
        id: item.id,
        name: item.description,
        projectId: item.projectId,
      });
    });
    return arr;
  };

  const getListConstruction = async (params: {
    page: number;
    size: number;
    projectId: string;
    keyword?: string;
    statusId?: string;
  }): Promise<any> => {
    const paramsGet = { ...params };
    const res = await cm003Service.getAttendanceUserConstruction(paramsGet);
    if (res?.results) {
      const data = formatConstructions(res.results);
      return data;
    }
  };

  const getListConstructionFirstOpenModal = async (
    params: any
  ): Promise<any> => {
    let dataOutput: any[] = [];

    let stop = true;
    let index = 0;

    do {
      const data = await getListConstruction({
        page: Number(params.origin.page) + index,
        size: params.origin.size,
        projectId: params.projectId,
      });

      const objFinded = data.find(
        (item: any) => Number(item.id) === Number(params.constructionId)
      );

      dataOutput.push(...data);

      index += 1;

      if (objFinded || params.constructionId === "" || data.length === 0) {
        stop = false;
      }
    } while (stop);

    setConstructions(dataOutput);
  };

  let timeOut: any = useRef();
  const handleSearchOptionConstruction = (
    searchValue: string,
    projectId: string
  ) => {
    if (timeOut.current) clearTimeout(timeOut.current);
    timeOut.current = setTimeout(async () => {
      try {
        setLoadingOption(true);
        setKeywordOptionConstruction(searchValue);
        setPageOptionConstruction(COMMON.DEFAULT_PAGE);
        setSizeOptionConstruction(COMMON.DEFAULT_SIZE);
        const data = await getListConstruction({
          page: COMMON.DEFAULT_PAGE,
          size: COMMON.DEFAULT_SIZE,
          projectId: projectId,
          keyword: searchValue,
        });
        if (data) setConstructions([...data]);
      } catch (error: any) {
      } finally {
        setLoadingOption(false);
      }
    }, 1000);
  };

  const handleScrollConstruction = async (
    e: any,
    projectId: string
  ): Promise<any> => {
    try {
      setLoadingOption(true);
      const target = e.target;
      if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
        setPageOptionConstruction(pageOptionConstruction + 1);
        const data = await getListConstruction({
          page: pageOptionConstruction + 1,
          size: sizeOptionConstruction,
          projectId: projectId,
          keyword: keywordOptionConstruction
            ? keywordOptionConstruction
            : undefined,
        });
        if (data) setConstructions([...constructions, ...data]);
      }
    } catch (error: any) {
    } finally {
      setLoadingOption(false);
    }
  };

  const handleFilter = (values: any) => {
    if (values.construction) {
      searchParams.set(CONSTRUCTION_ID_KEY, values.construction);
    } else {
      searchParams.delete(CONSTRUCTION_ID_KEY);
    }

    if (values.rangeDate) {
      searchParams.set(
        FROM_DATE_FILTER,
        moment(values.rangeDate[0]).format(COMMON.FORMAT_DATE2)
      );
      searchParams.set(
        TO_DATE_FILTER,
        moment(values.rangeDate[1]).format(COMMON.FORMAT_DATE2)
      );
    } else {
      searchParams.delete(FROM_DATE_FILTER);
      searchParams.delete(TO_DATE_FILTER);
    }

    setSearchParams(searchParams);
  };

  const handleResetForm = () => {
    // searchParams.set(
    //   FROM_DATE_FILTER,
    //   moment().startOf("month").format(COMMON.FORMAT_DATE2)
    // );
    // searchParams.set(TO_DATE_FILTER, moment().format(COMMON.FORMAT_DATE2));
    searchParams.delete(CONSTRUCTION_ID_KEY);
    searchParams.delete(FROM_DATE_FILTER);
    searchParams.delete(TO_DATE_FILTER);

    setSearchParams(searchParams);

    form.resetFields();
  };

  const getSummaryProjectReceiveMoney = async (params: any) => {
    const response = await cm003Service.getSummaryReceiveMoney(params);
    setSummaryData(convertSummaryTable(response.results));
  };

  const getProjectListConstruction = async (params: any) => {
    const response = await cm003Service.getProjectListConstruction({
      projectId: params.projectId,
    });

    const output: any[] = [];

    if (response.results.length > 0) {
      const promises = response.results.map(async (item: any, idx: number) => {
        const resp = await cm003Service.getConstructionReceivedMoney({
          constructionId: item.id,
          from: params.from,
          to: params.to,
        });

        output.push({
          constructionId: item.id,
          constructionName: item.description,
          createdAt: item.createdAt,
          children: resp.results,
        });
      });

      await Promise.all(promises);
    }

    const resp = convertDataTable(output);

    if (params.constructionId) {
      setData(
        resp[0].filter(
          (item: any) => item.ConstructionId === Number(params.constructionId)
        )
      );
    } else {
      setData(resp[0]);
    }

    setDataTotal({
      ReceiveMoneyTotal: resp[1],
      OffsetMoneyTotal: resp[2],
      ContractorMoneyTotal: resp[3],
      PlanningTotal: resp[4],
    });
  };

  const handleLoadingExport = (value: boolean) => {
    dispatch(setLoading(value));
  };

  const exportDataCM003 = async (
    projectName: string,
    rangeDate: string,
    from: string,
    to: string,
    projectId: string,
    constructionId: string
  ) => {
    const fileNameCM003 = `案件出来高管理_${moment().format(
      COMMON.FORMAT_DATE2
    )}`;
    // const SheetNameCM003 = `案件出来高管理【請求出来高`;

    // // Implement call nested data

    // const output: any[] = [];

    // data.forEach(async (item: TableDataEntity) => {
    //   if (item.Children.length > 0) {
    //     item.Children.forEach((element: ChildrenDataEntity) => {
    //       output.push({
    //         constructionName: item.ConstructionName,
    //         constructionId: item.ConstructionId,
    //         receiveMoneyId: element.Id,
    //       });
    //     });
    //   }
    // });

    // const dConvert: any[] = [];

    // const promises = output.map(async (ite: any) => {
    //   const resp = await cm003Service.getReceivedMoneyOffset({
    //     receiveMoneyId: ite.receiveMoneyId,
    //   });

    //   const startTime = resp.results?.startDate
    //     ? moment(resp.results?.startDate).format(COMMON.FORMAT_DATE_CI)
    //     : "";
    //   const endTime = resp.results?.endDate
    //     ? moment(resp.results?.endDate).format(COMMON.FORMAT_DATE_CI)
    //     : "";

    //   const obj = {
    //     constructionMapper: `${ite.constructionName}-${ite.constructionId}-${resp.results?.orderingCompanyName}`,
    //     orderCompanyName: resp.results?.orderingCompanyName ?? "",
    //     dateTime: `${startTime} ～ ${endTime}`,
    //     nestedData: resp.results?.data ?? [],
    //     parentId: ite.receiveMoneyId,
    //   };
    //   dConvert.push(obj);
    // });

    // await Promise.all(promises);

    // // Group items

    // let _mapperChild: any = {};

    // dConvert.forEach((item: any) => {
    //   if (_mapperChild.hasOwnProperty(item.constructionMapper)) {
    //     _mapperChild[`${item.constructionMapper}`].push(item);
    //   } else {
    //     _mapperChild[`${item.constructionMapper}`] = [item];
    //   }
    // });

    // const dataExportCM003 = convertDataExportCM003(
    //   summaryData,
    //   dataTotal,
    //   data,
    //   convertNestedData(_mapperChild)
    // );

    // CM003TemplateExport(
    //   dataExportCM003,
    //   projectName,
    //   rangeDate,
    //   from,
    //   to,
    //   fileNameCM003,
    //   SheetNameCM003,
    //   handleLoadingExport
    // );
    try {
      dispatch(setLoading(true));
      const response = await cm003Service.doExportCM003({
        projectName,
        rangeDate,
        from: from ? helpers.getFilterTimeMonth(from) : "",
        to: to ? helpers.getFilterTimeMonthTo(to) : "",
        projectId,
        constructionId,
      } as any);
      doExportForResponse(response, fileNameCM003 + ".xlsx");
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleOpenViewer = async (documentId: number, evidence: string) => {
    try {
      dispatch(setLoading(true));

      const companyId = JSON.parse(localStorage.getItem("user") ?? `{}`)
        ?.company?.id;
      const response = await cm003Service.getPresignLinkS3({
        keyName: `re/${companyId}/${SCREEN_NAME_VIEW}/${Number(
          documentId
        )}/${evidence}`,
      });
      const checkFile = evidence?.split(".").pop();
      const evidenceName = evidence?.split(".").slice(0, -1).join(".");

      setDocumentPath(response.results);
      setPdfName(evidenceName);

      if (checkFile === "pdf") dispatch(showPdfViewerModal());
      else dispatch(showImageViewerModal());
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  return {
    // variable
    form,
    loadingOption,
    constructions,
    summaryData,
    data,
    dataTotal,
    documentPath,
    pdfName,
    // Function
    openModalCM0063,
    handleFilter,
    handleResetForm,
    getListConstructionFirstOpenModal,
    handleSearchOptionConstruction,
    handleScrollConstruction,
    getSummaryProjectReceiveMoney,
    getProjectListConstruction,
    exportDataCM003,
    handleOpenViewer,
  };
};

export default CM003Handler;
