import { CM0014SliceType } from './../type/Presenter';
import { createSlice } from '@reduxjs/toolkit';

const initialState: CM0014SliceType = {
    loading: false,
    isVisible: false,
    refresh: false,
    documentIdCM014: 0,
    documentOriginIdCM0014: 0,
    summitDateCM014: '',
    statusCM014: '',
};

export const cm0014Slice = createSlice({
    name: 'cm0014',
    initialState,
    reducers: {
        resetCM0014: () => initialState,
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setIsVisibleCM0014: (state, action) => {
            state.isVisible = action.payload;
        },
        setRefreshCM0014: (state) => {
            state.refresh = !state.refresh;
        },
        setDocumentCM014Id: (state, action) => {
            state.documentIdCM014 = action.payload;
        },
        setDocumentOriginIdCM014: (state, action) => {
            state.documentOriginIdCM0014 = action.payload;
        },
        setSubmittedDateCM014: (state, action) => {
            state.summitDateCM014 = action.payload;
        },
        setStatusCM014: (state, action) => {
            state.statusCM014 = action.payload;
        },
    },
});

export const {
    setLoading,
    setIsVisibleCM0014,
    resetCM0014,
    setRefreshCM0014,
    setDocumentCM014Id,
    setSubmittedDateCM014,
    setDocumentOriginIdCM014,
    setStatusCM014,
} = cm0014Slice.actions;

export default cm0014Slice.reducer;
