// Built-in
import React, { useEffect, useRef } from "react";

// 3rd party lib
import { Checkbox, Table } from "antd";
import type { ColumnsType } from "antd/es/table";

// Source files
import CollapsedTableComponent from "../../collapsed-table";
import { MaterialCostHandlerImpl } from "../../../handler/HandlerImpl";

// Image files
import ExpandedIconSvg from "../../../../../../../assets/icons/expanded-icon.svg";
import ClosedIconSvg from "../../../../../../../assets/icons/closed-icon.svg";
import { MoneyDirectCostMaterialListCompanyResult } from "../../../../entity/Entity";
import { useSearchParams } from "react-router-dom";
import ThresholdPopoverComponent from "../../../../../../../common/components/threshold-popover";
import helpers from "../../../../../../../common/helpers/common";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../store";
import { STATUS_CM_PARENT_TABLE } from "../../../../../../../common/constants/COMMON";
import { CheckBoxAllComponentCM007CM009 } from "./CheckBoxAll";
import { calculateBillingVolumePercent } from "../../../../../CM004/helper";

export const COLUMN_WIDTH_CM007: number[] = [
    100, 350, 200, 174, 174, 174, 174, 174, 120,
];

type props = {
    handler: MaterialCostHandlerImpl;
};

const MaterialCostUI = ({ handler }: props) => {
    // Params
    const [searchParams] = useSearchParams();

    const constructionId = searchParams.get("constructionId"); // test UI only
    const projectName = searchParams.get("projectName") || ""; // test UI only

    const parentStatus = useSelector(
        (state: RootState) => state.cm007.parentStatus
    );
    const isSubmit = useSelector((state: RootState) => state.cm004.isSubmit);
    const isEnable = useSelector((state: RootState) => state.cm004.isEnable);
    const isExport = useSelector((state: RootState) => state.cm004.isExport);
    const isRefresh = useSelector((state: RootState) => state.cm007.isRefresh);

    const firstRender2 = useRef(false);
    useEffect(() => {
        if (firstRender2.current) {
            (async () => {
                if (constructionId && !isNaN(parseInt(constructionId)))
                    await handler.exportFile({
                        constructionId: parseInt(constructionId),
                        projectName,
                    });
            })();
            return;
        }
        firstRender2.current = true;
    }, [isExport]);

    useEffect(() => {
        handler.checkIsEnableSubmitButton(handler.checkedList);
    }, [JSON.stringify(handler.checkedList)]);

    useEffect(() => {
        (async () => {
            if (constructionId && !isNaN(parseInt(constructionId)))
                await handler.setDataAndParentStatus({
                    constructionId: parseInt(constructionId),
                });
        })();
    }, [constructionId, isRefresh]);

    const firstRender = useRef(false);
    useEffect(() => {
        if (firstRender.current && isEnable) {
            (async () => {
                await handler.putMoneySubmitDirectCostMaterial();
            })();
            return;
        }
        firstRender.current = true;
    }, [isSubmit]);

    const columns: ColumnsType<MoneyDirectCostMaterialListCompanyResult> = [
        {
            /*
                      Checkbox: Có 2 giá trị
                      Uncheck & enable (Điều kiện: Status là 未承認 - Unapproved): Click chọn và bỏ chọn được
                      Checked và disable (Điều kiện: Status là 承認済-Approved hoặc 承認待ち-Approval pending): 
                      Khi record đã được chọn và submit thì ô checkbox sẽ được check & disable,  khi hover vào hiển thị icon cấm
                  */
            // title: (
            //     <CheckBoxAllComponentCM007CM009
            //         onCheckAllChange={handler.onCheckAllChange}
            //         checkAll={handler.checkAll}
            //         parentStatus={parentStatus}
            //     />
            // ),
            title: "No",
            key: "No",
            width: COLUMN_WIDTH_CM007[0],
            render: (_, record, index) => {
                // const foundData = parentStatus.find((element) => element.id === record.id);
                // if (foundData?.childrenNumber === 0)
                //     return (
                //         <span
                //             onClick={(event) => {
                //                 event.stopPropagation();
                //             }}
                //             className="cell-cm007-style white-space-nowrap"
                //             style={{ width: '100%', float: 'left', textAlign: 'left' }}
                //         >
                //             <Checkbox
                //                 className="no-hover"
                //                 disabled={true}
                //                 style={{ marginRight: 8 }}
                //             />
                //             {index + 1}
                //         </span>
                //     );
                return (
                    <span
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                        className="cell-cm007-style white-space-nowrap"
                        style={{ width: "100%", float: "left", textAlign: "left" }}
                    >
                        {/* {foundData?.status === STATUS_CM_PARENT_TABLE.APPROVED.LABEL ||
                            foundData?.status === STATUS_CM_PARENT_TABLE.PENDING.LABEL ? (
                            <Checkbox
                                className="no-hover"
                                disabled={true}
                                style={{ marginRight: 8 }}
                                checked
                            />
                        ) : (
                            <Checkbox
                                onChange={(value: any) =>
                                    handler.onCheckboxChange(
                                        record,
                                        value?.target?.checked ? true : false,
                                    )
                                }
                                style={{ marginRight: 8 }}
                                checked={handler.isCheckbox(record)}
                            />
                        )} */}
                        {index + 1}
                    </span>
                );
            },
        },
        {
            title: "業者名", //Hiển thị tên công ty vật liệu ở giấy dự toán CR001_thông tin tiền mới nhất và đã được duyệt
            key: "業者名",
            width: COLUMN_WIDTH_CM007[1],
            render: (record) => (
                <span className="cell-cm007-style white-space-nowrap">
                    {record?.materialCostCompany?.name ?? ""}
                </span>
            ),
        },
        {
            title: "予算金額", //Lấy từ trường 予算金額（円）của tất cả các record có hợp tác với công ty vật liệu ở giấy dự toán CR001_thông tin tiền mới nhất và đã được duyệt
            key: "予算金額",
            width: COLUMN_WIDTH_CM007[2],
            render: (record) => (
                <span className="cell-cm007-style white-space-nowrap">
                    {`${helpers.formatNumberMoney(record.budgetMoney)}円`}
                </span>
            ),
        },
        {
            title: "発注金額", //Lấy từ trường 発注金額（円）của tất cả các record có hợp tác với công ty vật liệu ở giấy dự toán CR001_thông tin tiền mới nhất và đã được duyệt
            key: "発注金額",
            width: COLUMN_WIDTH_CM007[3],
            render: (record) => (
                <span className="cell-cm007-style white-space-nowrap">{`${helpers.formatNumberMoney(
                    record.orderMoney
                )}円`}</span>
            ),
        },
        {
            title: "実費用", // Count tổng số tiền 実費用 của record có trạng thái 承認済 nhập ở mục #11.7
            key: "実費用",
            width: COLUMN_WIDTH_CM007[4],
            render: (record) => {
                const foundData = parentStatus.find(
                    (element) => element.id === record.id
                );
                return (
                    <span className="cell-cm007-style white-space-nowrap">{`${helpers.formatNumberMoney(
                        foundData?.actualCost ?? 0
                    )}円`}</span>
                );
            },
        },
        {
            title: "予算残高", //Công thức: 予算金額 - 実費用
            key: "予算残高",
            width: COLUMN_WIDTH_CM007[5],
            render: (record) => {
                const foundData = parentStatus.find(
                    (element) => element.id === record.id
                );
                const budgetMoney = record.budgetMoney;
                const result = (budgetMoney || 0) - (foundData?.actualCost || 0);
                return (
                    <span className="cell-cm007-style white-space-nowrap">{`${helpers.formatNumberMoney(
                        result
                    )}円`}</span>
                );
            },
        },
        {
            title: "予算消化率（％）", // Công thức: 実費用 x 100/予算金額
            key: "予算消化率（％）",
            width: COLUMN_WIDTH_CM007[6],
            render: (record) => {
                if (!record.budgetMoney)
                    return (
                        <span className="cell-cm007-style white-space-nowrap">{`N/A%`}</span>
                    );
                const foundData = parentStatus.find(
                    (element) => element.id === record.id
                );
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <span className="cell-cm007-style white-space-nowrap">{`${helpers.calculateRateAmount(
                            record.budgetMoney,
                            foundData?.actualCost ?? 0
                        )}%`}</span>
                        <ThresholdPopoverComponent
                            initialData={{ ...record, constructionId: parseInt(record.id) }}
                            update={handler.putMoneyDirectCostMaterialEnable}
                        />
                    </div>
                );
            },
        },
        {
            title: "ステータス",
            key: "ステータス",
            width: COLUMN_WIDTH_CM007[7],
            render: (record) => {
                const foundData = parentStatus.find(
                    (element) => element.id === record.id
                );
                return (
                    <span className="cell-cm007-style white-space-nowrap">{`${foundData?.status}`}</span>
                );
            },
        },
        {
            title: "",
            key: "collapsed",
            width: COLUMN_WIDTH_CM007[8],
            render: (record) => {
                return handler.isCollapsed(record) ? (
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={(e: any) => handler.onChangeCollapseRow(record, false)}
                    >
                        <img alt="" src={ExpandedIconSvg} />
                    </div>
                ) : (
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={(e: any) => handler.onChangeCollapseRow(record, true)}
                    >
                        <img alt="" src={ClosedIconSvg} />
                    </div>
                );
            },
        },
    ];
    return (
        <Table
            onRow={(record, rowIndex) => {
                return {
                    onClick: (event) =>
                        handler.isCollapsed(record)
                            ? handler.onChangeCollapseRow(record, false)
                            : handler.onChangeCollapseRow(record, true), // click row
                };
            }}
            rowClassName={() => {
                return "button-style";
            }}
            scroll={{ x: 0, y: 500 }}
            columns={columns}
            dataSource={helpers.addKeyToDataSource(handler.data)}
            id="main-table"
            className="am-main-table cm007-table cm-main-table table-footer scroll-table"
            pagination={false}
            expandable={{
                showExpandColumn: false,
                expandedRowKeys: handler.expandKey,
                expandedRowRender: (record: any) => (
                    <CollapsedTableComponent
                        parentData={record}
                        onParentCheckboxChange={handler.onCheckboxChange}
                    />
                ),
            }}
            summary={(pageData) => {
                let orderMoneyTotal = 0;
                let actualCostTotal = 0;
                let budgetMoneyTotal = 0;

                pageData.forEach(({ orderMoney, budgetMoney, id }) => {
                    budgetMoneyTotal += budgetMoney || 0;
                    orderMoneyTotal += orderMoney || 0;
                    const foundData = parentStatus.find((element) => element.id === id);
                    actualCostTotal += foundData?.actualCost ?? 0;
                });

                return (
                    <Table.Summary fixed="bottom">
                        <Table.Summary.Row
                            className="parent-table-summary"
                            style={{ background: "#605BFF" }}
                        >
                            <Table.Summary.Cell index={0} colSpan={2}>
                                合計金額
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} colSpan={0}></Table.Summary.Cell>
                            <Table.Summary.Cell index={2}>
                                <span className="center-item white-space-nowrap">{`${budgetMoneyTotal.toLocaleString(
                                    "en-US",
                                    { maximumFractionDigits: 2 }
                                )}円`}</span>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={3}>
                                <span className="center-item white-space-nowrap">{`${orderMoneyTotal.toLocaleString(
                                    "en-US",
                                    { maximumFractionDigits: 2 }
                                )}円`}</span>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={4}>
                                <span className="center-item white-space-nowrap">{`${actualCostTotal.toLocaleString(
                                    "en-US",
                                    { maximumFractionDigits: 2 }
                                )}円`}</span>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={5}>
                                <span className="center-item white-space-nowrap">{`${(
                                    budgetMoneyTotal - actualCostTotal
                                ).toLocaleString("en-US", {
                                    maximumFractionDigits: 2,
                                })}円`}</span>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={6}>
                                <span className="center-item white-space-nowrap">{`${(actualCostTotal ===
                                    0 || budgetMoneyTotal === 0
                                    ? 0
                                    : (actualCostTotal / budgetMoneyTotal) * 100
                                ).toLocaleString("en-US", {
                                    maximumFractionDigits: 2,
                                })}%`}</span>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={7}></Table.Summary.Cell>
                            <Table.Summary.Cell index={8}></Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Table.Summary>
                );
            }}
        />
    );
};

export default MaterialCostUI;
