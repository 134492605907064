import axiosConfig from '../../../../../../config/axiosConfig';

const CM0035Api = () => {
    const getDocumentSubmittedMaterialList = async (): Promise<any> => {
        const url = '/re/document/material/submitted/list';
        return await axiosConfig.get(url);
    };

    const getDocumentSubmittedOutsourceList = async (): Promise<any> => {
        const url = '/re/document/outsource/submitted/list';
        return await axiosConfig.get(url);
    };

    const getDocumentSubmittedLaborList = async (): Promise<any> => {
        const url = '/re/document/labor/submitted/list';
        return await axiosConfig.get(url);
    };

    const getDocumentSubmittedIndirectList = async (): Promise<any> => {
        const url = '/re/document/indirect/submitted/list';
        return await axiosConfig.get(url);
    };

    return {
        getDocumentSubmittedMaterialList,
        getDocumentSubmittedOutsourceList,
        getDocumentSubmittedLaborList,
        getDocumentSubmittedIndirectList,
    };
};

export default CM0035Api;
