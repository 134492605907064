import ErrorNotification from "../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../common/constants/MESSAGE";
import { DB004Api } from "./ServiceImpl";

const DM004Service = (api: DB004Api) => {
  const getBudgetUtilizationRate = async (params: {
    projectId: number;
    from: string;
    to: string;
  }) => {
    try {
      const response = await api.getBudgetUtilizationRate(params);
      return response?.data;
    } catch (error: any) {
      ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
      return null;
    }
  };

  return {
    getBudgetUtilizationRate,
  };
};

export default DM004Service;
