import { useEffect } from 'react';
import { AM010Handler } from '../handler/HandlerImpl';
import AM010Calendar from './AM010Calendar';
import AM010Header from './AM010Header';
import moment from 'moment';
import COMMON from '../../../../../common/constants/COMMON';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import helpers from '../../../../../common/helpers/common';

type props = {
    handler: AM010Handler;
};

export default function AM010Content({ handler }: props) {
    const refreshAM010 = useSelector((state: RootState) => state.am010.refresh);
    const timeOffset = COMMON.TIME_ZONE;

    useEffect(() => {
        handler.getHaveProject();
    }, []);

    useEffect(() => {
        handler.getSummaryRequest({
            month: moment(handler.month).format(COMMON.FORMAT_YEAR_MONTH),
            timeOffset,
        });
    }, [handler.month, refreshAM010]);

    useEffect(() => {
        const paramMonth = {
            month: moment(handler.month).format(COMMON.FORMAT_YEAR_MONTH),
            timeOffset,
        };
        handler.getRequestAttendanceMonthly(paramMonth);
        handler.getRequestLateEarlyMonthly(paramMonth);
        handler.getRequestLeaveMonthly(paramMonth);
    }, [handler.month, refreshAM010]);

    useEffect(() => {
        handler.getRequestInformation({
            // from: moment(handler.month).startOf("month").format(COMMON.FORMAT_DATE2),
            // to: moment(handler.month).endOf("month").format(COMMON.FORMAT_DATE2),
            timeOffset,
            from: helpers.getFilterTime(moment(handler.month).startOf('month')),
            to: helpers.getFilterTimeTo(moment(handler.month).endOf('month')),
        });
    }, [handler.month, refreshAM010]);

    return (
        <div className="am010__container">
            <AM010Header handler={handler} />
            <AM010Calendar handler={handler} />
        </div>
    );
}
