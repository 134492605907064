import PlusFullWhite from '../../../../../assets/icons/plus-full-white.svg';
import { Button } from 'antd';
import GroupItem from './GroupItem';
import { OM002GroupList } from '../handler/HandlerImpl';
import { useSearchParams } from 'react-router-dom';
import React from 'react';
import {
  AUTHORIZATION_CODE,
  SYSTEM_ROLES,
} from '../../../../../common/constants/AUTHORIZATION';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';

type Props = {
  handler: OM002GroupList;
};

const OM002GroupListComponent = ({ handler }: Props) => {
  const [searchParams] = useSearchParams();
  const groupId = searchParams.get('groupId') || '';
  const permission = useSelector(
    (state: RootState) => state.auth.authData?.permission
  );
  const isRoleAdmin = useSelector((state: RootState) => state.auth?.authData?.isRoleAdmin)

  const isRoleAdminSystem =
    useSelector((state: RootState) => state.auth?.authData?.isRoleAdminSystem)

  return (
    <div className="w-100-percent h-100-percent group-list">
      <div className="group-list-title w-100-percent">部門</div>
      <div className="group-list-container">
        <GroupItem
          group={handler.allMemberGroup}
          chooseGroup={() => handler.chooseGroup(handler.allMemberGroup)}
          deleteAction={() => handler.deleteGroup(handler.allMemberGroup)}
          viewAction={() => handler.viewGroup(handler.allMemberGroup)}
          isChoosen={groupId && groupId === '0' ? true : false}
        />
        {handler.groups.map((element: any) => (
          <React.Fragment key={element.id}>
            <GroupItem
              group={element}
              chooseGroup={() => handler.chooseGroup(element)}
              deleteAction={() => handler.deleteGroup(element)}
              viewAction={() => handler.viewGroup(element)}
              isChoosen={groupId ? groupId === element.id + '' : false}
            />
          </React.Fragment>
        ))}
      </div>
      <div className="group-list-add-container">
        {permission?.includes(AUTHORIZATION_CODE.M12) || isRoleAdmin || isRoleAdminSystem ? (
          <Button
            type="primary"
            onClick={handler.createGroup}
            icon={<img alt="" src={PlusFullWhite} />}
            className="w-100-percent group-list-add"
          >
            新規追加
          </Button>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default OM002GroupListComponent;
