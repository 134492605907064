import { createSlice } from "@reduxjs/toolkit";
import { ORD002_1SliceType } from "../../entity/Entity";

export const initialState: ORD002_1SliceType = {
  orderId: null,
  visible: false,
  chosenPlanFunction: [],
  isUsingAPI: false,
  chosenPlan: null,
  isFormValidating: false,
};

export const ORD002_1Slice = createSlice({
  name: "ord002_1",
  initialState,
  reducers: {
    createORD002: (state) => {
      state.visible = true;
    },
    updateORD002: (state, action) => {
      state.visible = true;
      state.orderId = action.payload;
    },
    setChosenPlan: (state, action) => {
      state.chosenPlan = action.payload;
    },
    setUsingAPI: (state, action) => {
      state.isUsingAPI = action.payload;
    },
    setChosenPlanFunction: (state, action) => {
      state.chosenPlanFunction = action.payload;
    },
    setIsFormValidating: (state, action) => {
      state.isFormValidating = action.payload;
    },
    closeORD002: () => initialState,
  },
});

export const {
  closeORD002,
  createORD002,
  updateORD002,
  setChosenPlan,
  setUsingAPI,
  setChosenPlanFunction,
  setIsFormValidating,
} = ORD002_1Slice.actions;

export default ORD002_1Slice.reducer;
