import axiosConfig from "../../../../../config/axiosConfig";
import { ReqBodySchedule } from "../../entity/Entity";
import { Params } from "../../presenter/type/Presenter";

const SM03Api = () => {
  const createSchedule = async (data: ReqBodySchedule): Promise<any> => {
    try {
      const url = "/schedule/create";
      const response = await axiosConfig.post(url, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getListMember = async (params: any): Promise<any> => {
    try {
      const url = "/group/list-member-admin";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getDetailSchedule = async (params: {
    scheduleId: number;
  }): Promise<any> => {
    try {
      const url = "/schedule/detail";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const updateSchedule = async (data: ReqBodySchedule): Promise<any> => {
    try {
      const url = "/schedule/update";
      const response = await axiosConfig.put(url, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getListCategory = async (): Promise<any> => {
    try {
      const url = "/schedule/category/list";
      const response = await axiosConfig.get(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getProjects = async (params: any): Promise<any> => {
    try {
      const url = "/schedule/projects";
      const response = await axiosConfig.get(url, { params });
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  const getConstruction = async (params: any): Promise<any> => {
    try {
      const url = "/schedule/constructions";
      const response = await axiosConfig.get(url, { params });
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  const getDetailProject = async (params: any): Promise<any> => {
    try {
      const url = "/project/detail";
      const response = await axiosConfig.get(url, { params });
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  return {
    createSchedule,
    getListMember,
    getDetailSchedule,
    updateSchedule,
    getListCategory,
    getProjects,
    getConstruction,
    getDetailProject
  };
};

export default SM03Api;
