import { Badge, Drawer, Spin, Tooltip } from 'antd';
import { useLayoutEffect, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';

import { RootState } from '../../../../../store';
import {
  PM009DrawerHandler,
  CategoryNotification,
  NotificationData,
} from '../handler/HandlerImpl';

import '../../../../../assets/styles/PM/PM009.css';
import ArrowLeft from '../../../../../assets/icons/arrow-left-2.svg';
import Setting from '../../../../../assets/icons/setting.svg';
import Close from '../../../../../assets/icons/close.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import TooltipText from '../../../../../common/components/tooltip-text/TooltipText';
import { changeViewNoti } from '../slice/Slice';
import helpers from '../../../../../common/helpers/common';
import XIconModal from "../../../../../assets/icons/x-icon-modal.svg";

interface props {
  handler: PM009DrawerHandler;
}

function PM009Drawer({ handler }: props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const refreshNotification = useSelector(
    (state: RootState) => state.common.refreshNotification
  );
  useEffect(() => {
    return () => {
      dispatch(changeViewNoti(false));
    };
  }, []);

  useLayoutEffect(() => {
    if (handler.isViewNotifi && handler?.projectNoti?.id !== 0) {
      handler.getCategoryNotification({
        projectId: handler.projectNoti?.id,
      });
    }
  }, [refreshNotification, handler.isViewNotifi, handler.refreshNoti]);

  useLayoutEffect(() => {
    if (
      handler.isViewNotifi &&
      handler?.projectNoti?.id !== 0 &&
      handler.typeNoti?.id !== 0
    ) {
      handler.getListNotification({
        page: handler.pageNoti,
        size: handler.sizeNoti,
        projectId: handler.projectNoti?.id,
        category: handler.typeNoti?.id,
      });
    }
  }, [
    handler.isViewNotifi,
    refreshNotification,
    handler.pageNoti,
    handler.sizeNoti,
    handler.typeNoti,
  ]);

  return (
    <Drawer
      title={
        <div className="pm009-header">
          {handler.isDetailNotification && (
            <img alt="" src={ArrowLeft} onClick={handler.handleClickBack} />
          )}
          <div className="pm009-header-title">
            <div className="pm009-header-title-h2" style={{ marginBottom: 8 }}>
              {/* <TooltipText content={handler.projectNoti?.projectName} /> */}
              {handler.projectNoti?.projectName}
            </div>
            {handler.isDetailNotification ? (
              <span>{handler.typeNoti.description}</span>
            ) : (
              <span>通知</span>
            )}
          </div>
        </div>
      }
      closeIcon={
        <img alt="" src={XIconModal} style={{ height: 16, width: 16 }} />
      }
      placement="right"
      onClose={handler.handleClosePM009}
      visible={handler.isViewNotifi}
      className="pm009"
      footer={
        <div className="pm009-footer">
          <h4>設定</h4>
          <img
            alt=""
            src={Setting}
            onClick={() => navigate(`${location.pathname}/sn01`)}
          />
        </div>
      }
    >
      <div style={{ width: '100%', overflow: 'hidden', height: '100%' }}>
        <Spin spinning={handler.loadingNotification}>
          {!handler.isDetailNotification ? (
            <div className="pm009-body-view">
              {handler.categoryNotification?.map(
                (categoryNoti: CategoryNotification) => {
                  return (
                    <div
                      key={categoryNoti.category.code}
                      className="pm009-item"
                      onClick={() =>
                        handler.handleViewDetailNotification(
                          categoryNoti.category
                        )
                      }
                    >
                      <Badge count={categoryNoti.count} offset={[12, 0]}>
                        <div className="pm009-item-text">
                          {categoryNoti.category.description}
                        </div>
                      </Badge>
                    </div>
                  );
                }
              )}
            </div>
          ) : (
            <div className="pm009-body-detail">
              {handler.notifications?.length > 0 ? (
                <InfiniteScroll
                  dataLength={handler.notifications?.length}
                  next={handler.handleScrollNotification}
                  hasMore={true}
                  height={'100%'}
                  loader={null}
                >
                  {handler.notifications?.map(
                    (notification: NotificationData) => {
                      return (
                        <div
                          key={notification.id}
                          className={`pm009-item-detail ${!notification.isRead && 'pm009-item-detail-active'
                            }`}
                          onClick={() => {
                            if (!notification.isRead)
                              handler.handleReadNotification(notification.id);
                          }}
                        >
                          <div className="pm009-item-left">
                            {/* <TooltipText content={notification.title} /> */}
                            {notification.title}
                            <div className="pm009-item-weight-400">
                              {/* <TooltipText
                                                                content={notification.body}
                                                            /> */}
                              {notification.body}
                            </div>
                            <div
                              className="pm009-item-weight-400"
                              style={{ float: 'right' }}
                            >
                              {helpers.convertTime(notification.createdAt)}
                            </div>
                          </div>
                          <div
                            className="pm009-item-close"
                            onClick={(e) => {
                              e.stopPropagation();
                              handler.handleDeleteNotification(notification);
                            }}
                          >
                            <img alt="" src={Close} />
                          </div>
                        </div>
                      );
                    }
                  )}
                </InfiniteScroll>
              ) : (
                <div className="notification-no-data">
                  お知らせはありません。
                </div>
              )}
            </div>
          )}
        </Spin>
      </div>
    </Drawer>
  );
}

export default PM009Drawer;
