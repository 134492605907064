/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { linearGradientDef } from "@nivo/core";
import helpers from "../../helpers/common";

type props = {
  data: any[];
  keys?: any[];
  graph?: string;
  keyAxisBottom?: any;
  maxAxis: number;
};

const MyResponsiveBarDup = ({
  data,
  keys,
  graph,
  keyAxisBottom,
  maxAxis,
}: props) => {
  const TotalLabels = ({ bars, yScale }: any) => {
    return bars.map(
      ({ data: { data, indexValue }, x, y, width, key }: any, i: any) => {
        let number = 0;
        let color = "";

        if (key.includes("actual")) {
          number = (data.actual * 100) / data.originPlan;
          color = "#0050AE";
        } else {
          number = -1;
          color = "#0050AE";
        }

        return (
          <g transform={`translate(${x}, ${y})`} key={`${indexValue}-${i}`}>
            <text
              x={width / 2}
              y={-10}
              textAnchor="middle"
              alignmentBaseline="central"
              style={{
                fill: color,
                fontSize: "10px",
                fontWeight: 700,
              }}
            >
              {number > 0 ? `${helpers.formatNumberMoney(number)}%` : ""}
            </text>
          </g>
        );
      }
    );
  };
  const marginLeft = useMemo(
    () => {
      const numberArray = data.map((element) => element.plan)
      const maxNumber = Math.max(...numberArray)
      const results = (maxNumber + '').length * 10 + 10
      return results > 83 ? results : 83
    },
    [JSON.stringify(data)]
  );
  return (
    <ResponsiveBar
      data={data}
      keys={["actual", "plan"]}
      indexBy={"projectName"}
      maxValue={maxAxis}
      margin={{ top: 20, right: 0, bottom: 50, left: marginLeft }}
      padding={0.1}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      defs={[
        linearGradientDef(
          "gradientA",
          [
            { offset: 0, color: "#FFC53D" },
            { offset: 100, color: "#EA6B38" },
          ],
          {
            gradientTransform: "rotate(90 0.5 0.5)",
          }
        ),
        linearGradientDef(
          "gradientB",
          [
            { offset: 0, color: "#2EC5C1" },
            { offset: 100, color: "#119CD3" },
          ],
          {
            gradientTransform: "rotate(90 0.5 0.5)",
          }
        ),
      ]}
      fill={[
        { match: { id: "plan" }, id: "gradientA" },
        { match: { id: "actual" }, id: "gradientB" },
        { match: { id: "billingVolume" }, id: "gradientA" },
        { match: { id: "billingBalance" }, id: "gradientB" },
      ]}
      axisTop={null}
      axisRight={null}
      axisBottom={
        graph === "graph3"
          ? {
            tickSize: 0,
            tickPadding: 15,
            tickRotation: 0,
            legendPosition: "middle",
            legendOffset: 32,
            format: (v) => `${keyAxisBottom[v]}`,
          }
          : null
      }
      axisLeft={{
        tickSize: 0,
        tickPadding: 8,
        tickRotation: 0,
        legend: "",
        legendPosition: "middle",
        legendOffset: -40,
        format: (v) => `${helpers.formatCurrency(v)}円`,
      }}
      layers={["grid", "axes", "bars", TotalLabels, "markers"]}
      label={(d) => `${helpers.formatCurrency(d?.value ?? 0)}`}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={(item) => {
        return graph === "graph3"
          ? item.data.id === "actual"
            ? "#fff"
            : "#000"
          : "#000";
      }}
      legends={[]}
      tooltip={(point) => {
        const planOrigin = point?.data?.originPlan ?? 0;
        const actual = point?.data?.actual ?? 0;
        const rate = planOrigin === 0 ? 0 : (actual * 100) / planOrigin;

        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: 5,
              alignItems: "flex-start",
              background: "#fff",
              columnGap: 5,
              borderRadius: 5,
              fontSize: "12px",
              padding: 15,
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              border: "1px solid #eee",
            }}
          >
            <div style={{ color: "#222", fontWeight: 800 }}>
              {point.indexValue in keyAxisBottom
                ? keyAxisBottom[point.indexValue]
                : ""}
            </div>
            <div>
              稼働原価 : {helpers.formatCurrency(point?.data?.actual ?? 0)}
            </div>
            <div>
              予算残高 : {helpers.formatCurrency(point?.data?.plan ?? 0)}
            </div>
            <div>予算消化率(％) : {`${helpers.formatNumberMoney(rate)}%`}</div>
          </div>
        );
      }}
      theme={{
        axis: {
          ticks: {
            text: {
              fill: "#666666",
            },
          },
        },
        labels: {
          text: {
            fontSize: 10,
            fontWeight: 700,
            fill: "#222222",
          },
        },
      }}
    />
  );
};

export default MyResponsiveBarDup;
