import { RootState } from "./../../../../../store";
import { useState } from "react";
import { Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import { refreshOM002 } from "../../../OM002/presenter/slice/Slice";
import { OM009UseCase } from "../../usecase/ServiceImpl";
import { setLoading, resetOM009 } from "../slice/Slice";
import { useSearchParams } from "react-router-dom";
import MESSAGE, {
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import COMMON from "../../../../../common/constants/COMMON";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";

const DEFAULT_DELETE_OPTION = "option1";

const OM009Handler = (om009Service: OM009UseCase) => {
  const [type, setType] = useState(DEFAULT_DELETE_OPTION);

  const [searchParams, setSearchParams] = useSearchParams();

  const groupIdParam = searchParams.get("groupId");
  const undefinedGroupId = useSelector(
    (state: RootState) => state.om002.undefinedGroupId
  );

  const groupId = useSelector((state: RootState) => state.om009.groupId);

  const onTypeChange = (e: any) => {
    setType(e.target.value);
  };

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onCancel = () => {
    setType(DEFAULT_DELETE_OPTION);
    dispatch(resetOM009());
  };

  const onDelete = async () => {
    dispatch(setLoading(true));
    try {
      let response: any;

      const params = { groupId: groupId.id };
      if (type === DEFAULT_DELETE_OPTION) {
        response = await om009Service.deleteGroupAndMembers(params);
        if (groupId.id === parseInt(groupIdParam ?? "")) {
          searchParams.delete("groupId");
          setSearchParams(searchParams);
        }
      } else {
        response = await om009Service.deleteGroupButMember(params);
        if (undefinedGroupId) {
          searchParams.set("groupId", undefinedGroupId + "");
          searchParams.set("page", COMMON.DEFAULT_PAGE + "");
          setSearchParams(searchParams);
        }
      }

      SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
      dispatch(refreshOM002());
      setType(DEFAULT_DELETE_OPTION);
      dispatch(resetOM009());
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  return {
    onDelete,
    onCancel,
    onTypeChange,
    form,
    type,
  };
};

export default OM009Handler;
