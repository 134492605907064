import axios, { AxiosResponse } from "axios";
import axiosConfig from "../../../../../config/axiosConfig";

const CI001Api = () => {
  const getBasicInformation = async (params: any): Promise<any> => {
    try {
      const url = "/project/document-quotation-summary";
      const response = await axiosConfig.get(url, {
        params,
      });
      return response;
    } catch (error) {
      throw error;
    }
  };

  const getScreenMaster = async (params: any): Promise<any> => {
    try {
      const url = "/screen/master";
      const response = await axiosConfig.get(url, {
        params,
      });
      return response;
    } catch (error) {
      throw error;
    }
  };

  const updateBasicInformation = async (data: any): Promise<any> => {
    try {
      const url = "/project/update-document-quotation-summary";
      const response = await axiosConfig.put(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const getPaymentTerm = async (params: any): Promise<any> => {
    try {
      const url = "/project/document-payment-summary";
      const response = await axiosConfig.get(url, {
        params,
      });
      return response;
    } catch (error) {
      throw error;
    }
  };

  const updatePaymentTerm = async (data: any): Promise<any> => {
    try {
      const url = "/project/update-document-payment-confirm-summary";
      const response = await axiosConfig.put(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const getApprovalProcess = async (params: any): Promise<any> => {
    try {
      const url = "/project/ci-approval-process";
      const response = await axiosConfig.get(url, {
        params,
      });
      return response;
    } catch (error) {
      throw error;
    }
  };
  const postApprovalProcess = async (data?: any): Promise<any> => {
    try {
      const url = "/project/ci-approve";
      const response = await axiosConfig.post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const resetApproval = async (data?: any): Promise<any> => {
    try {
      const url = "/project/reset-ci-approval";
      const response = await axiosConfig.put(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const getDetailProject = async (params: any): Promise<any> => {
    try {
      const url = "/project/detail";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const handExportTemplate = async (params: any): Promise<any> => {
    try {
      const url = "/project/exportCI001";
      const response = await axiosConfig.get(url, {
        params,
        responseType: "blob",
      });
      return response;
    } catch (error) {
      throw error;
    }
  };

  const getFileCI = async (params: any): Promise<any> => {
    const url = `/construction/${params.constructionId}/ci/${params.documentName}`;
    return await axiosConfig.get(url, {});
  };

  const uploadToS3 = async (url: any, data: any): Promise<any> => {
    return await axios.put(url, data, {
      headers: {
        "Content-Type": data.type,
      },
    });
  };
  const getPresignLinkS3 = async (params: any): Promise<any> => {
    const url = "/project/presign-link";
    return await axiosConfig.get(url, { params });
  };

  const getLinkPresignUpload = async (
    params: any
  ): Promise<AxiosResponse<any, any>> => {
    const url = "/project/presign-upload";
    return await axiosConfig.get(url, { params });
  };

  const deleteFileCI = async (keyName: string): Promise<any> => {
    const url = `/project/delete-construction-file?keyName=${keyName}`;
    const response = await axiosConfig.delete(url, {});
    return response.data;
  };

  return {
    getBasicInformation,
    getScreenMaster,
    updateBasicInformation,
    getPaymentTerm,
    updatePaymentTerm,
    getApprovalProcess,
    postApprovalProcess,
    resetApproval,
    getDetailProject,
    handExportTemplate,
    getFileCI,
    uploadToS3,
    getPresignLinkS3,
    getLinkPresignUpload,
    deleteFileCI
  };
};

export default CI001Api;
