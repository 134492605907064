import { AxiosResponse } from 'axios';
import axiosConfig from '../../../../../config/axiosConfig';
import axios from 'axios';

const CollapsedTableApi = () => {
    const getMoneyDirectCostLaborList = async (params: {
        documentDirectLaborCostId: number;
        sortBy?: string;
        sortType?: string;
        page: number;
        size: number;
    }): Promise<AxiosResponse<any, any>> => {
        const url = '/money/direct-cost/labor/list';
        return await axiosConfig.get(url, { params });
    };
    const deleteMoneyDirectCostLaborDelete = async (
        data: any,
    ): Promise<AxiosResponse<any, any>> => {
        const url = '/money/direct-cost/labor/delete';
        return await axiosConfig.delete(url, { data });
    };
    const postMoneyDirectCostLaborCreate = async (data: any): Promise<AxiosResponse<any, any>> => {
        const url = '/money/direct-cost/labor/create';
        return await axiosConfig.post(url, data);
    };
    const putMoneyDirectCostLaborUpdate = async (data: any): Promise<AxiosResponse<any, any>> => {
        const url = '/money/direct-cost/labor/update';
        return await axiosConfig.put(url, data);
    };
    const getLinkPresignUpload = async (params: any): Promise<AxiosResponse<any, any>> => {
        const url = '/project/presign-upload';
        return await axiosConfig.get(url, { params });
    };
    const uploadToS3 = async (url: any, data: any): Promise<AxiosResponse<any, any>> => {
        return await axios.put(url, data, {
            headers: {
                'Content-Type': data.type,
            },
        });
    };
    const getPresignLinkS3 = async (params: any): Promise<AxiosResponse<any, any>> => {
        const url = '/project/presign-link';
        return await axiosConfig.get(url, { params });
    };
    return {
        getMoneyDirectCostLaborList,
        deleteMoneyDirectCostLaborDelete,
        postMoneyDirectCostLaborCreate,
        putMoneyDirectCostLaborUpdate,
        uploadToS3,
        getPresignLinkS3,
        getLinkPresignUpload,
    };
};

export default CollapsedTableApi;
