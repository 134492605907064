import React from "react";
import { Button, Form, Input, InputNumber, Modal, Select, Spin } from "antd";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import CloseIconModal from "../../../../../assets/icons/close.png";
import EmptyDataSVG from "../../../../../assets/icons/empty-data.svg";
import Minus_cm0063 from "../../../../../assets/icons/Minus_cm0063.svg";
import Plus_cm0063 from "../../../../../assets/icons/Plus_cm0063.svg";
import { default as XIconModal } from "../../../../../assets/icons/x-icon-modal.svg";
import "../../../../../assets/styles/CM/CM0012_2.css";
import LoadingComponent from "../../../../../common/components/icons/LoadingComponent";
import helpers from "../../../../../common/helpers/common";
import { RootState } from "../../../../../store";
import { CM0012_2Hanlder } from "../handler/HandlerImpl";
import { HeaderData, ParentCode } from "../type/Presenter";

type Props = {
  handler: CM0012_2Hanlder;
};

const CM0012_2UI = ({ handler }: Props) => {
  const formRef: any = useRef();
  const [isClickAdd, setIsClickAdd] = useState<number>(0);
  const isVisible = useSelector((state: RootState) => state.cm0012_2.isVisible);
  const loading = useSelector((state: RootState) => state.cm0012_2.loading);
  const parentCode: ParentCode = useSelector(
    (state: RootState) => state.cm0012_2.parentCode
  );
  const headerData: HeaderData = useSelector(
    (state: RootState) => state.cm0012_2.headerData
  );
  const title = useSelector((state: RootState) => state.cm0012_2.title);

  useEffect(() => {
    if (isVisible) {
      parentCode?.outsourceCostInformationId &&
        parentCode?.categoryId &&
        handler.handleGetListReceivedMoneyOffset(
          parentCode.outsourceCostInformationId,
          parentCode.categoryId
        );
      handler.handleGetSubCategory();
    }
  }, [
    isVisible,
    parentCode?.outsourceCostInformationId,
    parentCode?.categoryId,
  ]);

  useEffect(() => {
    if (isClickAdd !== 0 && formRef.current) {
      formRef.current.scrollTo(0, formRef.current.scrollHeight);
    }
  }, [isClickAdd]);

  return (
    <>
      <Modal
        className="group-detail-modal"
        style={{ marginLeft: 300, marginTop: 40, marginBottom: 40 }}
        visible={true}
        closable={false}
        maskClosable={false}
        width={"1027px"}
        centered={true}
        closeIcon={<img src={CloseIconModal} alt="close icon" />}
        footer={
          <Button
            disabled={loading}
            size="large"
            type="primary"
            style={{ marginLeft: 16, width: 92, letterSpacing: -1.5 }}
            onClick={handler.onCancel}
          >
            閉じる
          </Button>
        }
      >
        <Spin indicator={LoadingComponent} spinning={loading}>
          <div
            className="w-100-percent header-modal-containter"
            style={{ height: 50 }}
          >
            <div className="header-modal-title-cm0012_2">
              {title ? title : "相殺金額"}
            </div>
            <div style={{ cursor: "pointer" }} onClick={handler.onCancel}>
              <img alt="" src={XIconModal} style={{ height: 18, width: 18 }} />
            </div>
          </div>
          <div
            style={{ minHeight: 224, marginTop: 24 }}
            className="cm0012_2-content"
          >
            <div className="content-description">
              <div
                className={
                  handler.typeModal
                    ? "content-description-text content-description-text-update"
                    : "content-description-text"
                }
              >
                <div className="description-col">
                  <label> 業者名</label>
                  <p>{headerData?.traderName}</p>
                </div>
                <div className="description-col">
                  <label> 契約工事名</label>
                  <p>{headerData?.contractWorkName}</p>
                </div>
                <div className="description-col">
                  <label> 対象期間</label>
                  <p>{headerData?.targetPeriod}</p>
                </div>
              </div>
            </div>
            <div className="content-table">
              <div
                className="table-header table-row"
                style={{ paddingRight: "7px" }}
              >
                <div className="cell cell-order">No</div>
                <div className="cell cell-item">項目</div>
                <div className="cell cell-taxCategoryComsumption">
                  消費税区分
                </div>
                <div className="cell cell-offsetAmount">相殺金額 (税抜)</div>
              </div>
              {handler.typeModal && (
                <div
                  className="add-item"
                  style={{ fontWeight: 700, fontSize: 14, lineHeight: 22 }}
                >
                  <img
                    src={Plus_cm0063}
                    alt=""
                    style={{
                      marginLeft: 16,
                      marginRight: 8,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handler.handleAddRowLocal();
                      setIsClickAdd(isClickAdd + 1);
                    }}
                  />
                  <span
                    onClick={() => {
                      handler.handleAddRowLocal();
                      setIsClickAdd(isClickAdd + 1);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {" "}
                    項目追加
                  </span>
                </div>
              )}
              <div ref={formRef} className="form-cm0012_2">
                <Form
                  form={handler.form}
                  id="formCM0012_2"
                  style={{
                    height: "100%",
                  }}
                  onFinish={(value: any) => handler.handleSubmitForm(value)}
                  onValuesChange={handler.checkDirty}
                >
                  {handler?.listReceivedMoneyOffsetLocal?.map((item, index) => {
                    return !handler.typeModal ? (
                      <div className="table-row" key={item.key}>
                        <div className="cell cell-order">{index + 1}</div>
                        <div className="cell cell-item">{item.item}</div>
                        <div className="cell cell-taxCategoryComsumption">
                          {item.taxCategoryComsumptionName}
                        </div>
                        <div className="cell cell-offsetAmount">
                          {item.offsetAmount &&
                            helpers.formatCurrency(item.offsetAmount)}
                          円
                        </div>
                      </div>
                    ) : (
                      <div className="table-row" key={item.key}>
                        <div className="cell cell-order">{index + 1}</div>
                        <div className="cell cell-item">
                          <Form.Item name={`item${item.key}`}>
                            <Input />
                          </Form.Item>
                        </div>
                        <div className="cell cell-taxCategoryComsumption">
                          <Form.Item name={`taxCategoryConsumption${item.key}`}>
                            <Select
                              options={handler?.subCategory}
                              getPopupContainer={(trigger: any) =>
                                trigger.parentNode
                              }
                              listHeight={100}
                            />
                          </Form.Item>
                        </div>
                        <div className="cell cell-offsetAmount">
                          <Form.Item name={`offsetAmount${item.key}`}>
                            <InputNumber
                              controls={false}
                              addonAfter="円"
                              min={0}
                              onChange={() => {
                                handler.handleCalculateChange(
                                  handler.listReceivedMoneyOffsetLocal
                                );
                              }}
                              formatter={(value) =>
                                value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              }
                              parser={(value: any) =>
                                value.replace(/\$\s?|(,*)/g, "")
                              }
                            />
                          </Form.Item>
                          <img
                            src={Minus_cm0063}
                            alt=""
                            style={{
                              width: 22,
                              height: 22,
                              objectFit: "cover",
                              marginLeft: 8,
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handler.handleRemoveRowLocal(item.key)
                            }
                          />
                        </div>
                      </div>
                    );
                  })}
                  {handler?.listReceivedMoneyOffsetLocal?.length === 0 && (
                    <div className="empty-data">
                      <img src={EmptyDataSVG} alt="" />
                    </div>
                  )}
                </Form>
              </div>
              <div className="table-row table-row-blue">
                <div className="cell cell-order">合計金額</div>
                <div className="cell cell-item"></div>
                <div className="cell cell-taxCategoryComsumption"></div>
                <div className="cell cell-offsetAmount">{`${helpers.formatCurrency(
                  handler.totalCost
                )}円`}</div>
              </div>
            </div>
          </div>
        </Spin>
      </Modal>
    </>
  );
};

export default CM0012_2UI;
