import { useDispatch } from "react-redux";
// Library
import { useState } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// Service
import { CM001UseCase } from "../../usecase/ServiceImpl";
import {
  CM001ProjectSummaryCost,
  CM001TitleData,
  CM002ElementData,
} from "../../entity/Entity";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import {
  convertDataExportCM002,
  mappingListConstructionData,
} from "../../helper";
import COMMON from "../../../../../common/constants/COMMON";
import moment from "moment";
import { doExportForResponse } from "../../../../../common/helpers/exports/common";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../../common/constants/MESSAGE";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";

export const DEFAULT_TAB_VALUE = "1";
export const DEFAULT_TAB_KEY = "tab";
export const PROJECT_PARAM_KEY = "projectName";
export const RANGE_DATE_PARAM_KEY = "rangeDate";
export const PROJECT_PARAM_ID_KEY = "projectId";
export const PROJECT_MANAGER_PARAM_ID_KEY = "projectManagerId";

// DEFINE CODE
export const CODE_ONE = "CODE_ONE";
export const CODE_TWO = "CODE_TWO";
export const CODE_THREE = "CODE_THREE";
export const CODE_FOUR = "CODE_FOUR";
export const CODE_FIVE = "CODE_FIVE";
export const CODE_SIX = "CODE_SIX";

export const MENU_ITEM = [
  {
    name: "案件",
    key: "billingVolume",
  },
  {
    name: "工事別",
    key: "constructionCost",
  },
];

const CM001Handler = (cm001Service: CM001UseCase) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [tab, setTab] = useState<string>(DEFAULT_TAB_VALUE);
  const [summaryData, setSummaryData] = useState<CM001TitleData>({
    contractAmount: 0,
    billingVolume: 0,
    threshold: 0,
    enable: false,
  });
  const [summaryDataCost, setSummaryDataCost] = useState<
    CM001ProjectSummaryCost | any
  >({});
  const [summaryListConstruction, setSummaryListConstruction] = useState<
    CM002ElementData[] | any[]
  >([]);
  const [refresh, setRefresh] = useState<boolean>(false);

  // Function for redirect UI

  const chooseTabView = (tab: string) => {
    setTab(tab);
    searchParams.set(DEFAULT_TAB_KEY, tab);
    setSearchParams(searchParams);
  };

  const navigateCM003 = (search: string) => {
    if (location.pathname.includes("/app-cm001")) {
      navigate(`/app-cm003/cm001${search}`);
    } else {
      navigate(`/app-cm003/pm0061${search}`);
    }
  };

  const navigateCM004 = (
    constructionId: number,
    projectName: string,
    projectId: string
  ) => {
    navigate(
      `/app-cm004?constructionId=${constructionId}&projectId=${projectId}&projectName=${projectName}&previouspath=${
        location.pathname + location.search.replaceAll("&", "|")
      }`
    );
  };

  const initialTab = (tab: string) => {
    // Get key tab on pathname
    setTab(tab);
  };

  const refreshPage = () => {
    setRefresh(!refresh);
  };

  // Function for logical

  const getSummaryProjectMoneyReceived = async (params: {
    projectId: string;
  }) => {
    try {
      dispatch(setLoading(true));

      const resp = await cm001Service.getProjectSummaryReceivedMoney(params);
      setSummaryData(resp.results);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getSummaryProjectCost = async (params: { projectId: string }) => {
    try {
      dispatch(setLoading(true));

      const resp = await cm001Service.getProjectSummaryCost(params);
      setSummaryDataCost(resp.results);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getSummaryListConstructionMoney = async (params: {
    projectId: string;
  }) => {
    try {
      dispatch(setLoading(true));

      const resp = await cm001Service.getListConstructionSummaryReceivedMoney(
        params
      );
      setSummaryListConstruction(mappingListConstructionData(resp.results));
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const updateThresholdData = async (data: any): Promise<void> => {
    try {
      dispatch(setLoading(true));

      let projectId = searchParams.get(PROJECT_PARAM_ID_KEY) ?? "0";

      if (isNaN(Number(projectId))) projectId = "0";

      const response = await cm001Service.updateThreshold({
        projectId: Number(projectId),
        threshold: data?.threshold ?? 0,
        enable: data?.enable ?? false,
      });
      SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
      refreshPage();
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const updateThresholdCostMaterialData = async (data: any): Promise<void> => {
    try {
      dispatch(setLoading(true));

      let projectId = searchParams.get(PROJECT_PARAM_ID_KEY) ?? "0";

      if (isNaN(Number(projectId))) projectId = "0";

      const response = await cm001Service.updateThresholdCostMaterial({
        projectId: Number(projectId),
        threshold: data?.threshold ?? 0,
        enable: data?.enable ?? false,
      });
      SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);

      refreshPage();
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const updateThresholdCostLaborData = async (data: any): Promise<void> => {
    try {
      dispatch(setLoading(true));

      let projectId = searchParams.get(PROJECT_PARAM_ID_KEY) ?? "0";

      if (isNaN(Number(projectId))) projectId = "0";

      const response = await cm001Service.updateThresholdCostLabor({
        projectId: Number(projectId),
        threshold: data?.threshold ?? 0,
        enable: data?.enable ?? false,
      });
      SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);

      refreshPage();
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const updateThresholdCostOutsourceData = async (data: any): Promise<void> => {
    try {
      dispatch(setLoading(true));

      let projectId = searchParams.get(PROJECT_PARAM_ID_KEY) ?? "0";

      if (isNaN(Number(projectId))) projectId = "0";

      const response = await cm001Service.updateThresholdCostOutsource({
        projectId: Number(projectId),
        threshold: data?.threshold ?? 0,
        enable: data?.enable ?? false,
      });
      SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
      refreshPage();
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const updateThresholdCostIndirectData = async (data: any): Promise<void> => {
    try {
      dispatch(setLoading(true));

      let projectId = searchParams.get(PROJECT_PARAM_ID_KEY) ?? "0";

      if (isNaN(Number(projectId))) projectId = "0";

      const response = await cm001Service.updateThresholdCostIndirect({
        projectId: Number(projectId),
        threshold: data?.threshold ?? 0,
        enable: data?.enable ?? false,
      });
      SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
      refreshPage();
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const updateThresholdCostTotalData = async (data: any): Promise<void> => {
    try {
      dispatch(setLoading(true));

      let projectId = searchParams.get(PROJECT_PARAM_ID_KEY) ?? "0";

      if (isNaN(Number(projectId))) projectId = "0";

      const response = await cm001Service.updateThresholdCostTotal({
        projectId: Number(projectId),
        threshold: data?.threshold ?? 0,
        enable: data?.enable ?? false,
      });
      SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
      refreshPage();
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const updateThresholdConstructionData = async (data: any): Promise<void> => {
    try {
      dispatch(setLoading(true));

      const response = await cm001Service.updateThresholdConstruction({
        constructionId: Number(data?.constructionId),
        threshold: data?.threshold ?? 0,
        enable: data?.enable ?? false,
      });
      SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
      refreshPage();
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleLoadingExport = (value: boolean) => {
    dispatch(setLoading(value));
  };

  const exportData = async (
    tab: string,
    projectName: string,
    rangeDate: string,
    projectId: string
  ) => {
    try {
      dispatch(setLoading(true));
      const fileNameCM001 = `案件別収支管理_${projectName}_${moment().format(
        COMMON.FORMAT_DATE_SUBMIT
      )}`;
      const fileNameCM002 = `案件別収支管理_工事別_${moment().format(
        COMMON.FORMAT_DATE_SUBMIT
      )}`;
      const sheetNameCM002 = "案件別収支管理【工事別】";

      const dataExportCM002 = convertDataExportCM002(summaryListConstruction);

      if (tab === "1") {
        const response = await cm001Service.doExportCM001({
          projectName,
          rangeDate,
          projectId,
        } as any);
        doExportForResponse(response, fileNameCM001 + ".xlsx");
      } else {
        const response = await cm001Service.doExportCM002({
          projectName,
          rangeDate,
          projectId,
        } as any);
        doExportForResponse(response, fileNameCM002 + ".xlsx");
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  return {
    // variable
    tab,
    summaryData,
    summaryDataCost,
    summaryListConstruction,
    refresh,

    // Function
    exportData,
    initialTab,
    chooseTabView,
    navigateCM003,
    navigateCM004,
    getSummaryProjectMoneyReceived,
    getSummaryProjectCost,
    getSummaryListConstructionMoney,
    updateThresholdData,
    updateThresholdCostMaterialData,
    updateThresholdCostLaborData,
    updateThresholdCostOutsourceData,
    updateThresholdCostIndirectData,
    updateThresholdCostTotalData,
    updateThresholdConstructionData,
  };
};

export default CM001Handler;
