import PaginationComponentOM from "../../../../../common/components/pagination/PaginationComponentOM";
interface Props {
    total: number,
    page: string,
    size: string
    onPageChange: (value: number) => void,
    onSizeChange: (value: number) => void,

}
const PaginationComponent = ({ total, page, size, onPageChange, onSizeChange }: Props) => {

    return <div style={{ paddingRight: 24, paddingLeft: 24, marginBottom: 24 }}>
        <PaginationComponentOM
            total={total}
            page={parseInt(page)}
            size={parseInt(size)}
            setPage={onPageChange}
            setSize={onSizeChange}
            sizeLabelPage="件"
            sizeLabelSize=" ページ"
            rangeLabel="件"
        />   </div>

}

export default PaginationComponent