import { Checkbox } from 'antd';
import React, { useEffect } from 'react';
import { PM014TableHandler } from '../handler/HandlerImpl';
import PM014Item from './PM014Item';
import PM014ItemHeader from './PM014ItemHeader';
import { PRIVILEGE_CREATOR, PRIVILEGE_PM, PRIVILEGE_SUB_PM } from '../../../../../common/constants/PRIVILEGE';
import { useSelector } from 'react-redux';
import { SYSTEM_ROLES } from '../../../../../common/constants/AUTHORIZATION';
import { RootState } from '../../../../../store';
import { isHasPrivilege } from '../../../../../common/helpers/privilege';

interface props {
    render: string;
    handler: PM014TableHandler;
}

function PM014Table({ handler, render }: props) {
    const [enable, setEnable] = React.useState<boolean>(false);
    const [checkAll, setCheckAll] = React.useState<boolean>(false);

    const isRoleAdmin = useSelector((state: RootState) => state.auth?.authData?.isRoleAdmin)

    const isRoleAdminSystem =
        useSelector((state: RootState) => state.auth?.authData?.isRoleAdminSystem)

    const projectDetail = useSelector(
        (state: RootState) => state.pm005.projectDetail
    );
    const listItemTreeView = handler.flatData.map((element: any, idx: number) => (
        <PM014Item
            constructionId={handler.chosenNavItem.constructionId}
            contractorConstructionId={element?.id}
            key={idx}
            handler={handler}
            name={`${element?.company?.name ?? ''} (${element?.shortDescription ?? ''})`}
            totalMember={element?.numberOfMember}
            titleLevel={element.titleLevel}
            treeLevel={element.treeLevel}
            color={handler.TREEVIEW_COLOR[`${element.treeLevel}`]}
            background={handler.TREEVIEW_BACKGROUND[`${element.treeLevel}`]}
            padding={32 * (element.treeLevel - 1)}
            id={element?.id}
        />
    ));

    useEffect(() => {
        const checkedList = handler.currentTab === '1' ? handler.keyAD : handler.keyAT

        if (handler.checkedKeys && handler.checkedKeys.length) {
            setCheckAll(checkedList.every(item => handler.checkedKeys.includes(item)))
        } else {
            setCheckAll(false)
        }

    }, [handler.checkedKeys, handler.keyAD, handler.keyAT])

    return (
        <div className="pm014-table" key={`${render}`}>
            <div className="pm014-table-header">
                {isHasPrivilege(projectDetail, [
                    PRIVILEGE_PM,
                    PRIVILEGE_SUB_PM,
                    PRIVILEGE_CREATOR,
                ]) || isRoleAdmin || isRoleAdminSystem ?
                    <div className="pm014-table-checkbox">
                        <Checkbox
                            disabled={
                                (handler.currentTab === '1'
                                    ? handler.keyAD.length === 0
                                        ? true
                                        : false
                                    : handler.keyAT.length === 0
                                        ? true
                                        : false)
                            }
                            checked={checkAll}
                            onChange={(e: any) => {
                                // if (e.target.checked) {
                                //     if (handler.currentTab === '1') {
                                //         handler.setCheckedKeys(handler.keyAD);
                                //     } else {
                                //         handler.setCheckedKeys(handler.keyAT);
                                //     }
                                // } else {
                                //     handler.setCheckedKeys([]);
                                // }
                                handler.changeCheckedKeys(e.target.checked, handler.currentTab === '1' ? handler.keyAD : handler.keyAT)
                            }}
                        />
                    </div>

                    : <></>}

                <div className="pm014-table-3 pm014-table-header-item">
                    <span className="pm011-text-bold">名前</span>
                </div>
                {handler.currentTab === '2' ? (
                    <>
                        <div className="pm014-table-3 pm014-table-header-item">
                            <span className="pm011-text-bold">CCUS技能者ID</span>
                        </div>
                        {handler.funcCheckRole([PRIVILEGE_PM, PRIVILEGE_CREATOR]) ? (
                            <div className="pm014-table-3 pm014-table-header-item">
                                <span className="pm011-text-bold">人工単価（円）</span>
                            </div>
                        ) : null}
                    </>
                ) : (
                    <>
                        <div className="pm014-table-3 pm014-table-header-item">
                            <span className="pm011-text-bold">CCUS技能者ID</span>
                        </div>
                    </>
                )}

                <div className="pm014-table-3 pm014-table-header-item">
                    <span className="pm011-text-bold">電話番号</span>
                </div>
                <div
                    className="pm014-table-3 pm014-table-header-item"
                    onClick={() => handler.handleSortTable(handler.sortType, 'email')}
                >
                    <span className="pm011-text-bold">メールアドレス</span>
                </div>
            </div>
            <Checkbox.Group
                value={
                    handler.currentTab === '1'
                        ? enable
                            ? handler.keyAD
                            : handler.checkedKeys
                        : enable
                            ? handler.keyAT
                            : handler.checkedKeys
                }
            >
                <div className="pm014-table-body pm011-body">
                    <PM014ItemHeader handler={handler} />
                    {listItemTreeView}
                </div>
            </Checkbox.Group>
        </div>
    );
}

export default PM014Table;
