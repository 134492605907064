import IC4Api from "./infrastructure/api/Api";
import IC4Handler from "./presenter/handler/Handler";
import IC4UI from "./presenter/ui/UI";
import IC4Service from "./usecase/Service";

const IC4Component = () => {
  const ic4Api = IC4Api();
  const ic4Service = IC4Service(ic4Api);
  const ic4Handler = IC4Handler(ic4Service);
  return <IC4UI handler={ic4Handler} />;
};

export default IC4Component;
