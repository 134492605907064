import { AxiosResponse } from "axios";
import axiosConfig from "../../../../../config/axiosConfig";
import { MoneySubmitDirectCostLaborPayload } from "../../entity/Entity";

const MaterialCostApi = () => {
  const getMoneyDirectCostLaborList = async (params: {
    documentDirectLaborCostId: number;
    sortBy?: string;
    sortType?: string;
    page: number;
    size: number;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/money/direct-cost/labor/list";
    return await axiosConfig.get(url, { params });
  };
  const getMoneyDirectCostLaborListManager = async (params: {
    constructionId: number;
    documentId: number;
    keyword?: string;
    sortBy?: string;
    sortType?: string;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/money/direct-cost/labor/list-manager";
    return await axiosConfig.get(url, { params });
  };
  const putMoneyDirectCostLaborEnable = async (
    data: any
  ): Promise<AxiosResponse<any, any>> => {
    const url = "/money/direct-cost/labor/enable";
    return await axiosConfig.put(url, data);
  };

  const putMoneySubmitDirectCostLabor = async (
    data: MoneySubmitDirectCostLaborPayload
  ): Promise<AxiosResponse<any, any>> => {
    const url = "/money/submit/direct-cost/labor";
    return await axiosConfig.put(url, data);
  };

  const doExportCM0013 = async (params: any): Promise<any> => {
    try {
      const url = "/money/exportCM0013";
      const response = await axiosConfig.get(url, {
        params,
        responseType: "blob",
      });

      return response;
    } catch (error) {
      throw error;
    }
  };
  return {
    getMoneyDirectCostLaborList,
    getMoneyDirectCostLaborListManager,
    putMoneyDirectCostLaborEnable,
    putMoneySubmitDirectCostLabor,
    doExportCM0013,
  };
};

export default MaterialCostApi;
