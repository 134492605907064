import { CM0029SliceType } from './../type/Presenter';
import { createSlice } from '@reduxjs/toolkit';

const initialState: CM0029SliceType = {
    isDisableRetrieveButton: true,
    isRefresh: false,
    isVisibleCM0029: false,
    documentId: null,
    documentInfo: {
        documentTitle: '',
        createdDate: '',
        targetPeriod: '',
        status: '',
    },
};

export const cm0029Slice = createSlice({
    name: 'cm0029',
    initialState,
    reducers: {
        resetCM0029: () => initialState,
        setVisibleCM0029: (state, action) => {
            state.isVisibleCM0029 = action.payload;
        },
        setDocumentIdCM0029: (state, action) => {
            state.documentId = action.payload;
        },
        setDocumentInfoCM0029: (state, action) => {
            state.documentInfo = action.payload;
        },
        setIsDisableRetrieveButtonCM0029: (state, action) => {
            state.isDisableRetrieveButton = action.payload;
        },
    },
});

export const {
    resetCM0029,
    setVisibleCM0029,
    setDocumentIdCM0029,
    setDocumentInfoCM0029,
    setIsDisableRetrieveButtonCM0029,
} = cm0029Slice.actions;

export default cm0029Slice.reducer;
