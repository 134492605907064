import { useState } from 'react';
// Redux
import { useDispatch } from 'react-redux';

// Services

import { IC4UseCase } from '../../usecase/ServiceImpl';
import { hideIC4Modal } from '../slice/Slice';
import { Form } from 'antd';
import ConfirmModal from '../../../../../common/components/modal/ConfirmModal';
import MESSAGE, { LABEL_MESSAGE, NOTIFICATION_TITLE } from '../../../../../common/constants/MESSAGE';
import { setLoading } from '../../../../../common/slice/CommonSlice';
import SuccessNotification from '../../../../../common/components/notification/SuccessNotification';
import ErrorNotification from '../../../../../common/components/notification/ErrorNotification';

export const DEFAULT_TAB_VALUE = '1';
export const DEFAULT_TAB_KEY = 'tab';

const IC4Handler = (service: IC4UseCase) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm()

    const [isEdit, setIsEdit] = useState<boolean>(false)

    const onCancelModalIC4 = () => {
        if (isEdit) {
            ConfirmModal({
                onOk: () => {
                    dispatch(hideIC4Modal())
                    form.resetFields();
                    setIsEdit(false);
                },
                className: "confirm__modal",
                title: MESSAGE.MESSAGE_020,
                description: MESSAGE.MESSAGE_022_1,
                extraDescription: MESSAGE.MESSAGE_022_2,
                canceText: LABEL_MESSAGE.CANCEL_MODAL,
                okText: LABEL_MESSAGE.OK_MODAL,
                isCenterWithoutMenu: true,
            });
        } else {
            dispatch(hideIC4Modal())
        }
    }

    const handleSubmitForm = async (value: any) => {
        const payload = {
            companyName: value.companyName,
            userNameLogRequest: value.name,
            phoneNumber: value.phoneNumber.replaceAll("-", ""),
            newEmail: value.email
        }
        try {
            dispatch(setLoading(true));
            const response = await service.postChangeMail(payload);
            SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
            form.resetFields();
            setIsEdit(false);
            dispatch(hideIC4Modal())
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        } finally {
            dispatch(setLoading(false));
        }
    }

    const checkDirty = (): void => {
        const formValue = form.getFieldsValue();
        const formProps = Object.keys(formValue);
        for (let i = 0; i < formProps.length; i++) {
            if (form.isFieldTouched(formProps[i])) {
                return setIsEdit(true);
            }
        }
        setIsEdit(false);
    };
    return {
        form,
        isEdit,
        onCancelModalIC4,
        handleSubmitForm,
        checkDirty
    };
};
export default IC4Handler;
