import React from 'react';
import { Form, Input } from 'antd';
import { HandlerImpl } from '../handler/HandlerImpl';

import IconClose from '../../../../../assets/icons/icon-aop.svg';
import MESSAGE from '../../../../../common/constants/MESSAGE';
import REGEX from '../../../../../common/constants/REGEX';
import InputSelect from '../../components/input-select/InputSelect';
import helpers from '../../../../../common/helpers/common';
import TooltipText from '../../../../../common/components/tooltip-text/TooltipText';
import { isPrivilegeEmployee } from '../../../../../common/helpers/privilege';
import { EMPLOYEE_ITEM } from '../handler/Handler';

const { Item } = Form;

interface props {
    handler: HandlerImpl;
}
function BodyLeft({ handler }: props) {
    return (
        <div className="aop003-body-left">
            <div className="aop003-item1">
                <span>役職名</span>
                <Item
                    name="roleName"
                    rules={[
                        {
                            required: true,
                            message: MESSAGE.MESSAGE_REQUIRED,
                        },
                        {
                            pattern: new RegExp(REGEX.EXCEPT_VIETNAMESE),
                            message: MESSAGE.MESSAGE_INVALID_INPUT,
                        },
                        {
                            max: 20,
                            message: '',
                        },
                    ]}
                >
                    <Input
                        placeholder="役職名を入力"
                        size="large"
                        maxLength={20}
                        autoComplete="off"
                        onChange={helpers.preventInputJapanese}
                    />
                </Item>
            </div>
            {handler.roleId !== '0' ? (
                <div className="aop003-item2">
                    <span className="aop003-item2-title">メンバー</span>
                    <InputSelect
                        form={handler.form}
                        name="aopMember"
                        option={handler.memberSelect}
                        onFocus={handler.getListMember}
                        onScrollPopup={handler.handleScrollMember}
                        onSearch={handler.handleSearchOption}
                        onChooseItem={handler.handleChosenMember}
                    />
                    <ul className="aop003-member-list">
                        {handler.memberChosen?.map((item) => (
                            <li key={item.value} className="op003-member-item">
                                <span style={{ width: '85%' }}>
                                    <TooltipText content={item.name} />
                                </span>
                                {handler.role !== EMPLOYEE_ITEM ? (
                                    <img
                                        src={IconClose}
                                        alt=""
                                        onClick={() => handler.handleRemoveMember(item)}
                                    />
                                ) : null}
                            </li>
                        ))}
                    </ul>
                </div>
            ) : null}
        </div>
    );
}

export default BodyLeft;
