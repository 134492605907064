import { createSlice } from "@reduxjs/toolkit";
import { PM009SliceEntity } from "../type/Presenter";
const initialState: PM009SliceEntity = {
  projectNoti: 0,
  refreshNoti: false,
  isViewNotifi: false,
};

export const pM009Slice = createSlice({
  name: "PM009",
  initialState,
  reducers: {
    resetPM009: () => initialState,
    changeProjectNoti: (state, action) => {
      state.projectNoti = action.payload;
    },
    changeRefreshNoti: (state) => {
      state.refreshNoti = !state.refreshNoti;
    },
    changeViewNoti: (state, action) => {
      state.isViewNotifi = action.payload;
    },
  },
});

export const { changeProjectNoti, changeRefreshNoti, changeViewNoti } =
  pM009Slice.actions;

export default pM009Slice.reducer;
