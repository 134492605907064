import MenuApi from './infrastructure/api/Api';
import MenuHandlerHandler from './presenter/handler/Handler';
import MenuService from './usecase/Service';
import React from 'react';
import '../../../assets/styles/AM/AM002.css';
import '../../../assets/styles/OM/OM002.css';
import { MenuUI } from './presenter/ui/UI';
interface Props {
    chosenKey: number;
    children?: JSX.Element | JSX.Element[];
    openKey?: number;
    contentClass?: string;
    onClickMenu?: (callback: any, value: any) => void;
}
const MenuWrapperComponent = ({
    chosenKey,
    children,
    openKey,
    contentClass,
    onClickMenu,
}: Props) => {
    const api = MenuApi();
    const service = MenuService(api);
    const handler = MenuHandlerHandler(service);
    return (
        <MenuUI
            chosenKey={chosenKey}
            openKey={openKey}
            onClickMenu={onClickMenu}
            handler={handler}
            contentClass={contentClass}
        >
            {children}
        </MenuUI>
    );
};

export default MenuWrapperComponent;
