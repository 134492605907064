import axiosConfig from '../../../../../config/axiosConfig';
import axios from 'axios';

const API = () => {
    const getOrderDetail = async (params: any): Promise<any> => {
        try {
            const url = '/order/detail';
            const response = await axiosConfig.get(url, { params });
            return response?.data;
        } catch (error) {
            throw error;
        }
    };
    return { getOrderDetail };
};

export default API;
