import { Button, Modal, SelectProps, Spin } from "antd";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import "../../../../../assets/styles/AM/AM024.css";
import LoadingComponent from "../../../../../common/components/icons/LoadingComponent";
import { RootState } from "../../../../../store";
import { AM024Hanlder } from "../handler/HandlerImpl";
import "moment/locale/ja";
import "../../../../../assets/styles/OM/OM002.css";
import "../../../../../assets/styles/OM/OM004.css";
import "../../../../../assets/styles/OM/OM007.css";
import Delete from "../../../../../assets/icons/delete-red.svg";
import XIconModal from "../../../../../assets/icons/x-icon-modal.svg";
import AM027Component from "../../../AM027";
import COMMON from "../../../../../common/constants/COMMON";
import moment from "moment";
import {
  SESSION_OF_DAY_CODE,
  STATUS_REQUEST,
} from "../../../../../common/constants/STATUS";
import helpers from "../../../../../common/helpers/common";
import MESSAGE from "../../../../../common/constants/MESSAGE";
type Props = {
  handler: AM024Hanlder;
};

const AM024UI = ({ handler }: Props) => {
  const isVisible = useSelector((state: RootState) => state.am024.isVisible);
  const loading = useSelector((state: RootState) => state.am024.loading);
  const requestId = useSelector((state: RootState) => state.am024.requestId);
  const statusCode = useSelector((state: RootState) => state.am024.statusCode);
  const haveProject = useSelector(
    (state: RootState) => state.am010.haveProject
  );
  useEffect(() => {
    requestId && handler.handleGetRequestLeave(requestId);
  }, [requestId]);

  return (
    <Modal
      className="group-detail-modal"
      style={{ marginLeft: 300, marginTop: 40, marginBottom: 40 }}
      visible={isVisible}
      closable={false}
      maskClosable={false}
      width={"996px"}
      centered={true}
      footer={
        statusCode === STATUS_REQUEST.REQUEST_REJECT ? (
          <>
            <Button
              disabled={loading}
              size="large"
              type="primary"
              style={{ marginLeft: 16, width: 76, letterSpacing: -1.5 }}
              onClick={() => {
                requestId && handler.handleOpenModalUpdate(requestId);
              }}
            >
              編集
            </Button>
            <Button
              disabled={loading}
              icon={<img alt="" src={Delete} style={{ marginRight: 12 }} />}
              size="large"
              style={{
                marginLeft: 16,
                width: 106,
                backgroundColor: "#DAE2EE",
                border: "1px solid rgba(255, 9, 9, 0.5)",
                color: "#FF0909",
                letterSpacing: -1.5,
              }}
              onClick={() =>
                requestId && handler.handleOpenModalConfirmDelete([requestId])
              }
            >
              削除
            </Button>
            <Button
              disabled={loading}
              size="large"
              style={{
                marginLeft: 16,
                width: 118,
                color: "#666666",
                border: "1px solid #DAE2EE",
              }}
              onClick={handler.onCancel}
            >
              キャンセル
            </Button>
          </>
        ) : null
      }
    >
      <Spin indicator={LoadingComponent} spinning={loading}>
        <div
          className="w-100-percent header-modal-containter"
          style={{ height: 50 }}
        >
          <div className="header-modal-title-om004 ">休暇申請</div>
          <div style={{ cursor: "pointer" }} onClick={handler.onCancel}>
            <img alt="" src={XIconModal} style={{ height: 18, width: 18 }} />
          </div>
        </div>
        <div
          style={{ minHeight: 224, marginTop: 24 }}
          className={
            statusCode === STATUS_REQUEST.REQUEST_REJECT
              ? "am024-data"
              : "am024-data am024-data-margin-bot"
          }
        >
          <div className="row">
            <div className="title">ステータス</div>
            <div
              className={
                statusCode === STATUS_REQUEST.REQUEST_REJECT
                  ? "description description_first-child"
                  : statusCode === STATUS_REQUEST.REQUEST_APPROVED
                    ? "description description_first-child description_approve"
                    : "description description_first-child description_waiting_approve"
              }
            >
              {handler?.requestLeave?.status?.name}
            </div>
          </div>
          <div className="row">
            <div className="title">事由</div>
            <div className="description">
              {handler?.requestLeave?.category?.description}
            </div>
          </div>
          <div className="row">
            <div className="title">開始日</div>
            <div className="description">
              {moment(handler?.requestLeave?.startDate).format(
                COMMON.FORMAT_DATE
              )}
            </div>
          </div>
          <div className="row">
            <div className="title">終了日</div>
            <div className="description">
              {moment(handler?.requestLeave?.endDate).format(
                COMMON.FORMAT_DATE
              )}
            </div>
          </div>
          <div className="row">
            <div className="title">区分</div>
            <div className="description">
              {handler?.requestLeave?.subCategory?.description}
            </div>
          </div>
          <div className="row">
            <div className="title">休暇日数</div>
            {/* <div className="description">{`${
              moment(handler?.requestLeave?.endDate).diff(
                moment(handler?.requestLeave?.startDate),
                "days"
              ) > 0 &&
              (handler?.requestLeave?.subCategory?.code === "half_day_mor" ||
                handler?.requestLeave?.subCategory?.code === "half_day_af")
                ? moment(handler?.requestLeave?.endDate).diff(
                    moment(handler?.requestLeave?.startDate),
                    "days"
                  ) / 2
                : moment(handler?.requestLeave?.endDate).diff(
                    moment(handler?.requestLeave?.startDate),
                    "days"
                  ) == 0
                ? 0.5
                : moment(handler?.requestLeave?.endDate).diff(
                    moment(handler?.requestLeave?.startDate),
                    "days"
                  )
            }日`}</div> */}
            <div className="description">{`${handler?.requestLeave?.subCategory?.code ===
              SESSION_OF_DAY_CODE.OFF_ALL_DAY_CODE
              ? // ? helpers.getBusinessDays(
              //       handler?.requestLeave?.startDate,
              //       handler?.requestLeave?.endDate,
              //   )
              // : helpers.getBusinessDays(
              //       handler?.requestLeave?.startDate,
              //       handler?.requestLeave?.endDate,
              //   ) / 2
              moment(handler?.requestLeave?.endDate).diff(
                moment(handler?.requestLeave?.startDate),
                "days"
              ) + 1
              : (moment(handler?.requestLeave?.endDate).diff(
                moment(handler?.requestLeave?.startDate),
                "days"
              ) +
                1) /
              2
              }日`}</div>
          </div>
          {handler?.requestLeave?.project !== null &&
            handler?.requestLeave?.construction !== null && (
              <>
                <div className="row">
                  <div className="title">案件名</div>
                  <div className="description">
                    {handler?.requestLeave?.project?.name}
                  </div>
                </div>
                <div className="row">
                  <div className="title">工事名</div>
                  <div className="description">
                    {handler?.requestLeave?.construction?.name}
                  </div>
                </div>
              </>
            )}
          <div className="row">
            <div className="title">承認者</div>
            <div className="description">
              {handler?.requestLeave?.approver?.fullName}
            </div>
          </div>
          <div className="row">
            <div className="title">備考</div>
            <div className="description">
              {handler?.requestLeave?.note ? handler.requestLeave.note : "-"}
            </div>
          </div>
          {statusCode === STATUS_REQUEST.REQUEST_REJECT && (
            <div className="row">
              <div className="title">否認理由</div>
              <div className="description">
                {handler?.requestLeave?.rejectReason
                  ? handler?.requestLeave?.rejectReason
                  : "-"}
              </div>
            </div>
          )}
        </div>
      </Spin>
      <AM027Component
        title={MESSAGE.MESSAGE_MODAL_DELETE_TITLE}
        description={MESSAGE.MESSAGE_DELETE_LEAVE}
      />
    </Modal>
  );
};

export default AM024UI;
