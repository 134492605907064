import { useRef, useState } from "react";
import { RootState } from "./../../../../../store";
import { Form, InputRef } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import { refreshGroupOM002 } from "../../../OM002/presenter/slice/Slice";
import { OM008UseCase } from "../../usecase/ServiceImpl";
import { setLoading, resetOM008 } from "../slice/Slice";
import { GroupEntity } from "../../entity/Entity";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import ConfirmModal from "../../../../../common/components/modal/ConfirmModal";

const OM008Handler = (om008Service: OM008UseCase) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const groupInfo = useSelector((state: RootState) => state.om008.groupInfo);
  const [isDirty, setIsDirty] = useState(false);
  const explanationRef = useRef<InputRef>(null);

  const updateGroup = async () => {
    try {
      dispatch(setLoading(true));
      const data: GroupEntity = {
        explanation: form.getFieldValue("explanation"),
        id: groupInfo.id,
        name: form.getFieldValue("name"),
        approverId: Number(form.getFieldValue("approverId")),
      };
      const response = await om008Service.updateGroup(data);
      setIsDirty(false);
      dispatch(resetOM008());
      dispatch(refreshGroupOM002());
      SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const onCancel = () => {
    try {
      const isNameTouched = form.getFieldValue("name") !== groupInfo.name;
      const isExplanationTouched =
        form.getFieldValue("explanation") !== groupInfo.explanation;
      const isTouched = isNameTouched || isExplanationTouched ? true : false;
      if (isTouched) {
        ConfirmModal({
          onOk: () => {
            setIsDirty(false);
            form.resetFields();
            dispatch(resetOM008());
          },
          onCancel: () => {},
          title: MESSAGE.MESSAGE_020,
          description: MESSAGE.MESSAGE_021,
          canceText: LABEL_MESSAGE.CANCEL_MODAL,
          okText: LABEL_MESSAGE.OK_MODAL,
        });
      } else {
        setIsDirty(false);
        form.resetFields();
        dispatch(resetOM008());
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const initialOM008 = () => {
    form.setFieldsValue({
      name: groupInfo.name,
      explanation: groupInfo.explanation,
      approverId: groupInfo?.approver?.id
        ? groupInfo.approver.id + ""
        : undefined,
    });
  };
  const checkDirty = () => {
    const isNameTouched = form.getFieldValue("name") !== groupInfo.name;
    const isExplanationTouched =
      form.getFieldValue("explanation") !== groupInfo.explanation;
    const isTouched = isNameTouched || isExplanationTouched ? true : false;
    setIsDirty(isTouched);
  };
  return {
    updateGroup,
    onCancel,
    initialOM008,
    checkDirty,
    explanationRef,
    form,
    isDirty,
  };
};

export default OM008Handler;
