import { Form, InputRef } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import { refreshOM002 } from "../../../OM002/presenter/slice/Slice";
import { GroupEntity } from "../../entity/Entity";
import { OM007UseCase } from "../../usecase/ServiceImpl";
import { setLoading, setIsVisibleOM007, resetOM007 } from "../slice/Slice";
import ConfirmModal from "../../../../../common/components/modal/ConfirmModal";
import { useRef } from "react";
import { RootState } from "../../../../../store";

const OM007Handler = (om007Service: OM007UseCase) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const explanationRef = useRef<InputRef>(null);
  const authData = useSelector((state: RootState) => state.auth.authData);

  const createNewGroup = async () => {
    dispatch(setLoading(true));
    try {
      const data: GroupEntity = {
        explanation: form.getFieldValue("explanation"),
        name: form.getFieldValue("name"),
        approverId: Number(form.getFieldValue("approverId")),
      };
      const response = await om007Service.createNewGroup(data);
      SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
      form.resetFields();
      dispatch(resetOM007());
      dispatch(refreshOM002());
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };
  const getProjectUsers = async (params: any) => {
    return await om007Service.getProjectUsers({
      ...params,
      companyId: authData?.company?.id,
    });
  };

  const onCancel = () => {
    const isExplanationDirty = form.isFieldTouched("explanation");
    const isNameDirty = form.isFieldTouched("name");
    if (isExplanationDirty || isNameDirty) {
      ConfirmModal({
        onOk: () => {
          form.resetFields();
          dispatch(resetOM007());
          dispatch(setIsVisibleOM007(false));
        },
        onCancel: () => {},
        title: MESSAGE.MESSAGE_020,
        description: MESSAGE.MESSAGE_021,
        canceText: LABEL_MESSAGE.CANCEL_MODAL,
        okText: LABEL_MESSAGE.OK_MODAL,
      });
    } else {
      form.resetFields();
      dispatch(resetOM007());
      dispatch(setIsVisibleOM007(false));
    }
  };

  return {
    createNewGroup,
    onCancel,
    getProjectUsers,
    explanationRef,
    form,
  };
};

export default OM007Handler;
