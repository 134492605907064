import { Button, Empty, Form, Layout } from "antd";
import React from "react";
import { useEffect } from "react";
import HomeSvg from "../../../../../assets/icons/home.svg";
import helpers from "../../../../../common/helpers/common";
import { HandlerImpl } from "../handler/HandlerImpl";
import MenuWrapperComponent from "../../../../../common/components/menu-wrapper";
import BreadcrumbComponent from "../../../../../common/components/breadcrumb/BreadcrumbComponent";
import EditP01Icon from "../../../../../assets/icons/edit-p01.svg";
import UserDetailComponent from "./UserDetail";
import CertificationViewP01Component from "../../../../../common/components/certification-view-P01-component/CertificationViewP01Component";
import { CertificateEntity } from "../../entity/Entity";

import "moment/locale/ja";
import "../../../../../assets/styles/OM/OM002.css";
import "../../../../../assets/styles/OM/OM004.css";
import "../../../../../assets/styles/OM/OM007.css";
import "../../../../../assets/styles/P/P01.css";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import {
  AUTHORIZATION_CODE,
} from "../../../../../common/constants/AUTHORIZATION";
import { useParams } from "react-router-dom";
import MESSAGE from "../../../../../common/constants/MESSAGE";
import OM006Component from "../../../OM006";

const BREADCRUMB_OM003: any[] = [
  {
    title: "設定",
    url: "",
    icon: (
      <img style={{ paddingBottom: 2, paddingRight: 8 }} alt="" src={HomeSvg} />
    ),
  },
  {
    title: "部門・メンバー",
    url: "/app-om002",
    icon: "",
  },
  {
    title: "メンバー情報",
    url: "",
    icon: "",
  },
];

const { Content } = Layout;

interface props {
  handler: HandlerImpl;
}

const MainUI = ({ handler }: props) => {
  const { memberId } = useParams();
  const isRefresh = useSelector((state: RootState) => state.om003.isRefresh);
  const isHasUpdatedOM006 = useSelector((state: RootState) => state.om006.isHasUpdatedOM006);
  const isVisibleOM006 = useSelector(
    (state: RootState) => state.om006.isVisible
  );

  const permission = useSelector(
    (state: RootState) => state.auth.authData?.permission
  );
  const isRoleAdmin = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdmin
  );

  useEffect(() => {
    handler.getMemberDetail(Number(memberId));
  }, [isRefresh, isHasUpdatedOM006]);

  useEffect(() => {
    handler.getCertificateType();
  }, []);

  useEffect(() => {
    if (handler.isDirty) {
      window.addEventListener("beforeunload", helpers.alertUser);
      return () => {
        window.removeEventListener("beforeunload", helpers.alertUser);
      };
    } else {
      window.removeEventListener("beforeunload", helpers.alertUser, true);
    }
  }, [handler.isDirty]);

  return (
    <MenuWrapperComponent
      chosenKey={72}
      openKey={7}
      contentClass="site-content-p01 "
      onClickMenu={handler.onClickMenu}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
        }}
      >
        <div className="om002-header-container">
          <BreadcrumbComponent data={BREADCRUMB_OM003} />
          <div className="header-row-title" style={{ marginTop: 8 }}>
            メンバー情報
          </div>
        </div>
        <div style={{ display: "flex" }}>
          {!handler.isEditing &&
            (permission?.includes(AUTHORIZATION_CODE.M12) || isRoleAdmin) ? (
            <Button
              onClick={() => handler.openModalEdit(Number(memberId))}
              style={{ width: 98 }}
              size="large"
              type="primary"
              className="button-purple"
            >
              <div className="center-item">
                <img
                  alt=""
                  src={EditP01Icon}
                  style={{ marginRight: 8, fontWeight: 700, fontSize: 14 }}
                />
                <div className="export-button" >
                  編集
                </div>
              </div>
            </Button>
          ) : null}
        </div>
      </div>
      <Layout
        style={{
          minHeight: "84vh",
          marginTop: 24,
          marginBottom: 24,
          backgroundColor: "#ffffff",
        }}
        className={"open-side"}
      >
        {!handler.detailMember ? (
          <Content
            className="center-item"
            style={{
              width: "100%",
              backgroundColor: "#ffffff",
              height: "100%",
            }}
          >
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={MESSAGE.MESSAGE_NO_DATA}
            />
          </Content>
        ) : (
          <Content
            style={{ margin: "0px 24px 24px 24px", backgroundColor: "#ffffff" }}
          >
            <div style={{ marginTop: 24 }}>
              <Form
                className="p01-form "
                onValuesChange={handler.checkDirty}
                form={handler.form}
                id="formUpdateMemberP02"
                layout="horizontal"
                onFinish={handler.updateMember}
                autoComplete="off"
              >
                <UserDetailComponent detailMember={handler.detailMember} />
                <div
                  className="registration-title"
                  style={{
                    marginLeft: 20,
                    width: 114,
                    height: 24,
                    marginTop: 8,
                  }}
                >
                  保有資格
                </div>
                {handler.certType.map((element: CertificateEntity) =>
                  <React.Fragment key={element.id}>
                    <CertificationViewP01Component
                      initialLines={
                        handler.certLines.lines[`cert${element.id}`]
                      }
                      label={element.description}
                    />
                  </React.Fragment>
                )}
              </Form>
            </div>
          </Content>
        )}
      </Layout>

      <div
        className="sticky"
        style={{
          width: "100%",
          backgroundColor: "#ffffff",
          display: "flex",
          padding: 24,
          justifyContent: "flex-end",
        }}
      >
        <Button
          size="large"
          style={{
            marginLeft: 16,
            width: 118,
            fontWeight: 400,
            fontSize: 14,
            lineHeight: 22,
            color: "#666666",
          }}
          onClick={() => handler.backToListMemberPage()}
        >
          キャンセル
        </Button>
      </div>
      {isVisibleOM006 ? <OM006Component /> : <></>}
    </MenuWrapperComponent>
  );
};

export default MainUI;
