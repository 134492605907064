
import React from 'react'
import { API } from "./infrastructure/api/Api"
import { Handler } from "./presenter/handler/Handler"
import { Service } from "./usecase/Service"
import { MainUI } from './presenter/ui/UI'


const DynamicAuthenticationComponent = () => {
    const dataSource = API()
    const useCase = Service(dataSource)
    const handler = Handler(useCase)
    return <MainUI handler={handler} />
}

export default DynamicAuthenticationComponent