import AM006Api from "./infrastructure/api/Api";
import AM006Handler from "./presenter/handler/Handler";
import MainUI from "./presenter/ui/UI";
import AM006Service from "./usecase/Service";

import "../../../assets/styles/AM/AM002.css";
import "../../../assets/styles/OM/OM002.css";
import "../../../assets/styles/AM/AM004.css";
import AttendanceApi from "./infrastructure/api/ApiAttendance";
import AttendanceService from "./usecase/ServiceAttendance";

const AM006Component = () => {
  const api = AM006Api();
  const attendanceApi = AttendanceApi();

  const service = AM006Service(api);
  const attendanceService = AttendanceService(attendanceApi);

  const handler = AM006Handler(service, attendanceService);
  return <MainUI handler={handler} />;
};

export default AM006Component;
