import { Button, Checkbox, Divider, Modal, Spin, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import 'moment/locale/ja';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloseIconModal from '../../../../../assets/icons/close.png';
import PaperBlue from '../../../../../assets/icons/paper_blue.svg';
import ShowIcon from '../../../../../assets/icons/Show_bold.svg';
import { default as XIconModal } from '../../../../../assets/icons/x-icon-modal.svg';
import '../../../../../assets/styles/CM/CM006.css';
import LoadingComponent from '../../../../../common/components/icons/LoadingComponent';
import ImageViewerModalComponent from '../../../../../common/components/image-viewer-modal/ImageViewerComponent';
import PdfViewerModalComponent from '../../../../../common/components/pdf-viewer-modal/PdfViewerModalComponent';
import helpers from '../../../../../common/helpers/common';
import { RootState } from '../../../../../store';
import { DataTableHistory, DataTableOverview } from '../../entity/Entity';
import { CM006Handler } from '../handler/HandlerImpl';
import { CM006TableHasSummary } from './CM006TableHasSummary';
import { resetCM006 } from '../slice/Slice';
import IconCircleCheck from '../../../../../assets/icons/akar-icons_circle-check-fill.svg';
import CM0063Component from '../../../CM0063';
import { STATUS_CM_CHILD_TABLE } from '../../../../../common/constants/COMMON';
import { CheckBoxCollapseComponentCM007CM009 } from '../../../CM007/presenter/component/collapsed-table/ui/CheckBoxAll';
import YellowEyeIcon from '../../../../../assets/icons/yellow-eye-icon.svg'
import IconI from '../../../../../assets/icons/icon-i-red.svg'
import ApproveViewModal from '../../../../../common/components/modal-approve-view';
import EyeApprovedIcon from '../../../../../assets/icons/eye-approved.svg'
import EyeNotApprovedIcon from '../../../../../assets/icons/eye-not-approved.svg'
import EyeRejectIcon from '../../../../../assets/icons/eye-reject.svg'
import ApprovalFlow from '../../../../../common/components/approval-flow/ApprovalFlow';
import ApproveConfirmModal from '../../../../../common/components/modal-approve-confirm';

type Props = {
    handler: CM006Handler;
    onSubmit?: () => void;
    onReject?: () => void;
    disableSubmitOrReject?: boolean;
    isCheckbox?: boolean
};

const CM006UI = ({ handler, disableSubmitOrReject, isCheckbox = false, onSubmit, onReject }: Props) => {
    const isVisible = useSelector((state: RootState) => state.cm006.isVisible);
    const dispatch = useDispatch();
    const loading = useSelector((state: RootState) => state.cm006.loading);
    const documentId = useSelector((state: RootState) => state.cm006.documentId);
    const documentInfo = useSelector((state: RootState) => state.cm006.documentInfo);
    const status = useSelector((state: RootState) => state.cm006.status);
    const visiableCM0063 = useSelector((state: RootState) => state.cm0063.isVisible);
    const isDisabledCheckAll = handler.dataHistory.some((element) => element?.status?.code === STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE)
    useEffect(() => {
        Promise.all([
            handler.getListSubmittedReceiveMoney(documentId),
            handler.getWorkflowReceiveMoneyApprovalProcess(documentId)
        ])

        return () => {
            dispatch(resetCM006());
        };
    }, [documentInfo.createdDocument, documentInfo.submittedDocument]);

    const columnTableOverview: ColumnsType<any> = [
        {
            title: '契約金額',
            dataIndex: 'contractValue',
            align: 'center',
        },
        {
            title: '請求出来高',
            dataIndex: 'paymentValue',
            className: 'column-money',
            align: 'center',
        },
        {
            title: '請求出来高(％)',
            dataIndex: 'paymentValuePercent',
            align: 'center',
        },
        {
            title: '請求残金',
            dataIndex: 'invoiceBalance',
            align: 'center',
        },
    ];

    const columnTableHistory: ColumnsType<DataTableHistory> = [
        {
            /*
            Hiển thị text: 項目追加
            Click button sẽ thêm 1 record, xem mục #8.3.10
            Điều kiện enable button: Khi status của record không ở status 承認待ち"				
            4/14/2023							
            */
            title: !isCheckbox ? 'No' : <CheckBoxCollapseComponentCM007CM009
                onCheckAllChange={handler.onCheckAllChange}
                checkAll={handler.checkAll}
                isDisabled={!isDisabledCheckAll}
            />,
            key: 'No',
            align: 'center',
            width: 100,
            render: (_, record, index) => {
                if (!isCheckbox) return <span className="cell-cm007-style white-space-nowrap"
                    style={{ width: '100%', textAlign: 'center' }} >
                    {index + 1}
                </span>
                const { MONEY_APPROVED, MONEY_REJECT, MONEY_RETRIEVE } = STATUS_CM_CHILD_TABLE

                return <span
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                    className="cell-cm007-style white-space-nowrap"
                    style={{ width: '100%', textAlign: 'center' }}
                >
                    {[
                        MONEY_REJECT.CODE,
                        MONEY_RETRIEVE.CODE,
                        MONEY_APPROVED.CODE].includes(record?.status?.code ?? '') ? (
                        <Checkbox
                            className="no-hover"
                            disabled={true}
                            style={{ marginRight: 8 }}
                            checked
                        />
                    ) : (
                        <Checkbox
                            onChange={(value: any) =>
                                handler.onCheckboxChange(
                                    record,
                                    value?.target?.checked ? true : false,
                                )
                            }
                            style={{ marginRight: 8 }}
                            checked={handler.isCheckbox(record)}
                        />
                    )}
                    {index + 1}
                </span>
            },
        },
        {
            title: '入金日',
            dataIndex: 'paymentDay',
            align: 'center',
            render: (value) => {
                return <span>{value}</span>;
            },
        },
        {
            title: '対象期間',
            dataIndex: 'targetStage',
            className: 'column-money',
            align: 'center',
            render: (value) => {
                return <span>{value}</span>;
            },
        },
        {
            title: '請求予定金額',
            dataIndex: 'billAmountPlan',
            align: 'center',
            render: (value) => {
                return <span>{`${helpers.formatCurrency(value)}円`}</span>;
            },
        },
        {
            title: '請求金額',
            dataIndex: 'billAmount',
            align: 'center',
            render: (value) => {
                return <span>{`${helpers.formatCurrency(value)}円`}</span>;
            },
        },
        {
            title: '相殺金額',
            dataIndex: 'offsetAmount',
            align: 'center',
            render: (value, record) => (
                <span>
                    {`${helpers.formatCurrency(value)}円`}
                    <img
                        src={ShowIcon}
                        alt=""
                        style={{
                            width: 24,
                            height: 24,
                            objectFit: 'cover',
                            marginLeft: 8,
                            cursor: 'pointer',
                        }}
                        onClick={() => handler.openModalOffsetAmount(record)}
                    />
                </span>
            ),
        },
        {
            title: '入金額',
            dataIndex: 'depositAmount',
            align: 'center',
            render: (value) => {
                return <span>{`${helpers.formatCurrency(value)}円`}</span>;
            },
        },
        {
            title: 'エビデンス',
            // dataIndex: 'evidence',
            align: 'center',
            render: (value) =>
                value?.evidence && (
                    <img
                        src={PaperBlue}
                        style={{ width: 24, height: 24, objectFit: 'cover', cursor: 'pointer' }}
                        onClick={() => handler.handleOpenViewer(value.id, value.evidence)}
                    ></img>
                ),
        },
        {
            title: 'ステータス',
            key: 'ステータス',
            align: 'center',
            width: 150,
            render: (record) => {
                if (record?.status?.code === false) return <></>;
                const color = STATUS_CM_CHILD_TABLE.hasOwnProperty(
                    `${record?.status?.code?.toUpperCase()}`,
                )
                    ? {
                        color: STATUS_CM_CHILD_TABLE[`${record?.status?.code?.toUpperCase()}` as keyof typeof STATUS_CM_CHILD_TABLE]
                            .COLOR,
                    }
                    : {};
                return (
                    <div className="collapsed-table-row-style center-item" style={color}>
                        {`${record.status.name}` || ''}
                        <div style={{ marginLeft: 8 }} className='center-item'>
                            {
                                ([STATUS_CM_CHILD_TABLE.MONEY_APPROVED.CODE, STATUS_CM_CHILD_TABLE.MONEY_NOT_APPROVED.CODE, STATUS_CM_CHILD_TABLE.MONEY_REJECT.CODE].includes(record?.status?.code) && record?.note !== "")
                                    ?
                                    <div className='button-style' onClick={() => handler.viewApproveModal(record)} >
                                        <img alt='' src={renderEye(record?.status?.code)} />
                                    </div> : <></>
                            }
                        </div>
                    </div >
                );
            },
        },
    ];

    useEffect(() => {
        handler?.dataHistory && handler.handleCaculateSummary(handler.dataHistory);
    }, [handler?.dataHistory]);

    const renderEye = (status: string) => {
        if (status === STATUS_CM_CHILD_TABLE.MONEY_APPROVED.CODE) return EyeApprovedIcon
        else if (status === STATUS_CM_CHILD_TABLE.MONEY_NOT_APPROVED.CODE) return EyeNotApprovedIcon
        else return IconI
    }

    return (
        <Modal
            className="group-detail-modal"
            style={{ marginLeft: 300, marginTop: 40, marginBottom: 40 }}
            visible={isVisible}
            closable={false}
            maskClosable={false}
            width={'1520px'}
            centered={true}
            closeIcon={<img src={CloseIconModal} alt="close icon" />}
            footer={
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    {onSubmit && onReject ? (
                        <>
                            {/* DONE */}
                            <ApproveConfirmModal onSubmit={onSubmit} />
                            <Button
                                className="button-purple cm047-btn-submit"
                                icon={<img alt="" src={IconCircleCheck} />}
                                size="large"
                                onClick={handler.showConfirmApproveModal}
                                disabled={
                                    disableSubmitOrReject ||
                                    !(
                                        handler.currentUserApproval &&
                                        helpers.checkApproveInWorkFlow(handler.currentUserApproval)
                                    )
                                } style={{ marginLeft: 16 }}
                            >
                                承認
                            </Button>
                            <Button
                                size="large"
                                className="button-warning cm047-btn-reject"
                                onClick={onReject}
                                disabled={
                                    disableSubmitOrReject ||
                                    !(
                                        handler.currentUserApproval &&
                                        helpers.checkApproveInWorkFlow(handler.currentUserApproval)
                                    )
                                }                            >
                                否認
                            </Button>
                        </>
                    ) : null}
                    {onSubmit && onReject ? null : (
                        <Button
                            disabled={
                                loading ||
                                handler.checkedList.length === 0
                            }
                            size="large"
                            className="button-purple button-dsb"
                            style={{ marginLeft: 16, width: 120, letterSpacing: -1.5 }}
                            onClick={() => handler.retrieveReceiveMoney(documentId)}
                        >
                            取り下げ
                        </Button>
                    )}
                    <Button
                        disabled={loading}
                        size="large"
                        style={{
                            marginLeft: 16,
                            width: 118,
                            color: '#666',
                            fontWeight: 400,
                            fontSize: 14,
                        }}
                        onClick={handler.onCancel}
                    >
                        キャンセル
                    </Button>
                </div>
            }
        >
            <Spin indicator={LoadingComponent} spinning={loading}>
                <div className="w-100-percent header-modal-containter" style={{ height: 50 }}>
                    <div className="header-modal-title-cm006">請求出来高</div>
                    <div style={{ cursor: 'pointer' }} onClick={handler.onCancel}>
                        <img alt="" src={XIconModal} style={{ height: 18, width: 18 }} />
                    </div>
                </div>
                <div style={{ marginTop: 24, marginBottom: 8 }}>
                    <ApprovalFlow
                        isNotCheckingUser={true}
                        breadcrumb={[]}
                        listApprove={handler.workflow}
                        currentUserApproval={handler.currentUserApproval}
                    />
                </div>
                <div style={{ minHeight: 224, marginTop: 24 }} className="cm006-content">
                    <div className="content-description">
                        <div className="description-col" style={{ maxWidth: '30%' }}>
                            <div className="description-row" style={{ minHeight: 36 }}>
                                <label>案件名</label>
                                <p>{handler.title.projectName}</p>
                            </div>
                            <div className="description-row" style={{ minHeight: 36 }}>
                                <label>発注元会社名</label>
                                <p>{handler.title.orderAddress}</p>
                            </div>
                        </div>
                        <div className="description-col" style={{ maxWidth: '40%' }}>
                            <div className="description-row" style={{ minHeight: 36 }}>
                                <label>工事名</label>
                                <p>{handler.title.constructionName}</p>
                            </div>
                            <div className="description-row" style={{ minHeight: 36 }}>
                                <label>契約工事期間</label>
                                <p>{handler.title.expiredContract}</p>
                            </div>
                        </div>

                        <div className="description-col">
                            <div className="description-row" style={{ minHeight: 36 }}>
                                <label>契約日</label>
                                <p>{handler.title.contractCreateDate}</p>
                            </div>
                            <div className="description-row" style={{ minHeight: 36 }}>
                                <label>作成日</label>
                                <p>{documentInfo.submittedDocument}</p>
                            </div>
                        </div>
                    </div>
                    <div className="content-table-cm006">
                        <div className="table-top">
                            <Table
                                columns={columnTableOverview}
                                dataSource={handler.dataOverview}
                                bordered
                                title={() => '工事出来高'}
                                pagination={false}
                            />
                        </div>

                        <Divider style={{ padding: '0px 24px', margin: '32px 0px' }}></Divider>
                        <CM006TableHasSummary
                            columns={columnTableHistory}
                            data={handler.dataHistory}
                            handler={handler}
                            title="提出履歴"
                        />
                    </div>
                </div>
                <ImageViewerModalComponent
                    imageURL={handler.pdfUrl}
                    title={handler.pdfName}
                />
                <PdfViewerModalComponent
                    pdfURL={handler.pdfUrl}
                    title={handler.pdfName}
                />

            </Spin>
            {visiableCM0063 ? <CM0063Component /> : null}
            <ApproveViewModal isViewOnly={true} />
        </Modal>
    );
};

export default CM006UI;
