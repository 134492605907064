import BreadcrumbComponent from "../../../../../common/components/breadcrumb/BreadcrumbComponent";
import HomeSvg from "../../../../../assets/icons/home.svg";
import BackgroundImage from "../../../../../assets/images/background_ccus01.png";
import MenuWrapperComponent from "../../../../../common/components/menu-wrapper";
import { Button, Form, Input } from "antd";
import MESSAGE, { TITLE_CCUS01 } from "../../../../../common/constants/MESSAGE";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { CCUS01Handler } from "../handler/HandlerImpl";
import REGEX from "../../../../../common/constants/REGEX";
import { MAX_LENGTH } from "../../../../../common/constants/COMMON";
import {
  formatCCUSID,
  typeInteger,
} from "../../../../../common/helpers/typeInput";
import { useEffect, useState } from "react";
import WaitingCCUS01UI from "./WaitingCCUS";
import { text } from "../../../../../common/global";

interface props {
  handler: CCUS01Handler;
}


const BREADCRUMB_CCUS01: any[] = [
  {
    title: "建設キャリアアップシステム",
    url: "",
    icon: (
      <img style={{ paddingBottom: 2, paddingRight: 8 }} alt="" src={HomeSvg} />
    ),
  },
  {
    title: "事業者ID認証",
    url: "",
    icon: "",
  },
];

const CCUS01UI = ({ handler }: props) => {
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    handler.getStateCCUSCompany();
  }, []);

  useEffect(() => {
    setIsFormValid(handler.resetCcusCompanyScreen);
  }, [handler.resetCcusCompanyScreen]);

  const handleFieldsChange = () => {
    const values = handler.form.getFieldsValue();
    const hasErrors = handler.form
      .getFieldsError()
      .some(({ errors }) => errors.length > 0);
    const requiredFieldsFilled =
      values.ccusid &&
      values.confirmCcusid &&
      values.password &&
      values.confirmPassword;

    setIsFormValid(!hasErrors && requiredFieldsFilled);
  };

  return (
    <>
      <MenuWrapperComponent chosenKey={91} openKey={9}>
        <div>
          <div className="om002-header-container">
            <BreadcrumbComponent data={BREADCRUMB_CCUS01} />
            <div className="header-row-title-ccus02">
              {text("CCUS01.title")}
            </div>
          </div>

          {handler.ccusCompanyInformation?.state != null ? (
            <WaitingCCUS01UI handler={handler} />
          ) : (
            <div className="container-tab-ccus01">
              <div className="container-content-ccus01">
                <div
                  className="header-title-ccus01"
                  dangerouslySetInnerHTML={{
                    __html: TITLE_CCUS01.LABEL_TITLE_CCUS01,
                  }}
                />
                <div className="description-ccus01">
                  <span>{TITLE_CCUS01.LABEL_SUBTITLE_CCUS01_REGISTER1}</span>
                </div>

                <div className="description-ccus01 mb-16">
                  <span>{TITLE_CCUS01.LABEL_SUBTITLE_CCUS01_REGISTER2}</span>
                </div>

                <Form
                  layout="vertical"
                  id="uploadCCUSID"
                  name="uploadCCUSID"
                  form={handler.form}
                  onFinish={handler.onSubmit}
                  initialValues={{ isRemembered: true }}
                  onFieldsChange={handleFieldsChange}
                >
                  <Form.Item
                    name={"ccusid"}
                    className="input-style"
                    style={{ border: "none", outlineColor: "#ffffff" }}
                    label={
                      <span className="label-input-modal">
                        {TITLE_CCUS01.CCUISID}
                        <span className="sterisk">*</span>
                      </span>
                    }
                    rules={[
                      {
                        required: true,
                        message: MESSAGE.MESSAGE_LESS_THAN_14_CCUS,
                      },
                      () => ({
                        validator(_, value) {
                          if (value && value !== "") {
                            value = value.replaceAll(" ", "");
                            if (value?.length < MAX_LENGTH.CCUSID) {
                              return Promise.reject(
                                new Error(MESSAGE.MESSAGE_LESS_THAN_14_CCUS)
                              );
                            }
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input
                      size="large"
                      onKeyPress={typeInteger}
                      maxLength={MAX_LENGTH.FORMAT_CCUSID}
                      placeholder={TITLE_CCUS01.CCUISID_PLACEHOLDER}
                      onChange={(e) => {
                        formatCCUSID(e, "ccusid", handler.form);
                      }}
                      onInput={(e: React.FormEvent<HTMLInputElement>) => {
                        const inputElement = e.currentTarget;
                        const inputValue = inputElement.value;
                        const filteredValue = inputValue.replace(/[^0-9]/g, '');
                        inputElement.value = filteredValue.slice(0,14);
                        formatCCUSID(e, "ccusid", handler.form);
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    name={"confirmCcusid"}
                    className="input-style"
                    style={{ border: "none", outlineColor: "#ffffff" }}
                    label={
                      <span className="label-input-modal">
                        {TITLE_CCUS01.CCUS_BUSINESS_ID}
                        <span className="sterisk">*</span>
                      </span>
                    }
                    dependencies={["ccusid"]}
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (getFieldValue("ccusid").replaceAll(" ", "") === value.replaceAll(" ", "")) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(MESSAGE.MESSAGE_CCUSID_DO_NOT_MATCH)
                          );
                        },
                      }),
                    ]}
                  >
                    <Input
                      size="large"
                      onKeyPress={typeInteger}
                      onChange={(e) => {
                        formatCCUSID(e, "confirmCcusid", handler.form);
                      }}
                      maxLength={MAX_LENGTH.FORMAT_CCUSID}
                      placeholder={TITLE_CCUS01.CCUISID_PLACEHOLDER}
                      onInput={(e: React.FormEvent<HTMLInputElement>) => {
                        const inputElement = e.currentTarget;
                        const inputValue = inputElement.value;
                        const filteredValue = inputValue.replace(/[^0-9]/g, '');
                        inputElement.value = filteredValue.slice(0,14);
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    name={"password"}
                    className="input-password-style"
                    style={{ marginBottom: 16 }}
                    label={
                      <span className="label-input-modal">
                        {TITLE_CCUS01.CCUS_PASSWORD}{" "}
                        <span className="sterisk">*</span>
                      </span>
                    }
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (value) {
                            if (!handler.validatePasswordCCUS(value)) {
                              return Promise.reject(new Error(MESSAGE.MESSAGE_8_24_CHARACTERS));
                            }
                          }
                          return Promise.resolve();
                        },
                      })
                    ]}
                  >
                    <Input.Password
                      placeholder={TITLE_CCUS01.CCUS_PASSWORD_PLACEHOLDER}
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                      size="large"
                    />
                  </Form.Item>

                  <Form.Item
                    name={"confirmPassword"}
                    className="input-password-style"
                    style={{ marginBottom: 16 }}
                    label={
                      <span className="label-input-modal">
                        {TITLE_CCUS01.CCUS_BUSINESS_PASSWORD}
                        <span className="sterisk">*</span>
                      </span>
                    }
                    dependencies={["password"]}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(MESSAGE.MESSAGE_PASSWORDS_DO_NOT_MATCH)
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      placeholder={TITLE_CCUS01.CCUS_PASSWORD_PLACEHOLDER}
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                      size="large"
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      htmlType="submit"
                      size="large"
                      form="uploadCCUSID"
                      style={{ marginTop: 24 }}
                      className={`button-purple w-100-percent ${
                        !isFormValid ? "button-brown-ccus" : "cm047-btn-submit"
                      }`}
                      disabled={
                        handler.resetCcusCompanyScreen ? false : !isFormValid
                      }
                    >
                      確認
                    </Button>
                  </Form.Item>
                </Form>
              </div>
              <div className="contaner-background-ccus01" >
                <img className="img-background-ccus01" alt="" src={BackgroundImage} style={{objectFit: 'contain'}}/>
              </div>
            </div>
          )}
        </div>
      </MenuWrapperComponent>
    </>
  );
};

export default CCUS01UI;
