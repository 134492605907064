import { createSlice } from '@reduxjs/toolkit';
import { SM01SliceType } from '../../entity/Entity';

const initialState: SM01SliceType = {
    refresh: false,
};

export const sm01Slice = createSlice({
    name: 'sm01',
    initialState,
    reducers: {
        resetSM01: () => initialState,
        refreshPage: (state) => {
            state.refresh = !state.refresh;
        },
    },
});

export const { refreshPage } = sm01Slice.actions;

export default sm01Slice.reducer;
