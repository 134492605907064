import moment from "moment";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { showImageViewerModal } from "../../../../../common/components/image-viewer-modal/ImageViewSlice";
import ConfirmModal from "../../../../../common/components/modal/ConfirmModal";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import COMMON, {
  STATUS_CM_CHILD_TABLE,
} from "../../../../../common/constants/COMMON";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import {
  TableBudgetBalanceContact,
  TableSubmissionHistory,
} from "../../entity/Entity";
import { CM0014UseCase } from "../../usecase/ServiceImpl";
import { resetCM0014, setLoading } from "../slice/Slice";
import { DataTableConstructionBudgetBalance } from "../../../CM0016/entity/Entity";
import {
  convertDataTable,
  convertDataTableInformationCost,
} from "../../helper";
import { setRefresh } from "../../../CM034-CM53/CM035/presenter/slice/Slice";
import { showPdfViewerModal } from "../../../../../common/components/pdf-viewer-modal/pdfViewerModalSlice";
import { DataTableOverview } from "../../../CM006/entity/Entity";
import { CM006UseCase } from "../../../CM006/usecase/ServiceImpl";
import { convertDataOverview } from "../../../CM006/helper";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import { ItemApprove } from "../../../../../common/components/approval-flow/ApprovalFlow";
import { setDocumentView } from "../../../../../common/components/modal-approve-view/presenter/slice/Slice";
import { showModalApproveConfirm } from "../../../../../common/components/modal-approve-confirm/presenter/slice/Slice";

const APPROVE_STATUS_CODE = "money_approved";
const SCREEN_NAME_VIEW = "CM0013";

const CM0014Handler = (
  CM0014Service: CM0014UseCase,
  CM006Service: CM006UseCase
) => {
  // LIB FUNCTION
  const dispatch = useDispatch();
  //state

  const [isOpenViewer, setIsOpenViewer] = useState<boolean>(false);
  const [pdfUrl, setPdfUrl] = useState<any>();
  const [pdfName, setPdfName] = useState<string>("");

  const [dataSummary, setDataSummary] = useState<any>();
  const [dataReceivedMoney, setReceivedMoney] = useState<DataTableOverview[]>(
    []
  );
  const [constructionInformationCost, setConstructionInformationCost] =
    useState<DataTableConstructionBudgetBalance[]>([]);

  const [dataTableBudgetBalanceContact, setDataTableBudgetBalanceContact] =
    useState<TableBudgetBalanceContact[]>([]);

  const [dataTableSubmissionHistory, setDataTableSubmissionHistory] = useState<
    TableSubmissionHistory[]
  >([]);

  const [dataSummaryTitle, setDataSummaryTitle] = useState<any>({});
  const [workflow, setWorkflow] = useState<ItemApprove[]>([]);
  const [currentUserApproval, setCurrentUserApproval] =
    useState<ItemApprove | null>(null);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedList, setCheckedList] = useState<any[]>([]);
  //function
  const getWorkflowLaborApprovalProcess = async (documentId: number) => {
    try {
      dispatch(setLoading(true));
      const data = await CM0014Service.getWorkflowLaborApprovalProcess({
        documentId,
      });
      if (data.results.currentUserApproval)
        setCurrentUserApproval({
          approveDate: data.results.currentUserApproval.approveDate,
          contact_address: "",
          email: data.results.currentUserApproval.email,
          id: data.results.currentUserApproval.id,
          isApprove: data.results.currentUserApproval.approve,
          reject: data.results.currentUserApproval.reject,
          position: "",
          priority: data.results.currentUserApproval.priority,
          username: data.results.currentUserApproval.username,
          fullName: data.results.currentUserApproval.fullName,
          note: data.results.currentUserApproval.note,
        });
      const workflowData: ItemApprove[] = data.results.listApprover.map(
        (element) => {
          let note = element.note;
          if (element.id === data.results?.currentUserApproval?.id)
            note = data.results.currentUserApproval.note;
          const result: ItemApprove = {
            approveDate: element.approveDate,
            contact_address: "",
            email: element.email,
            id: element.id,
            isApprove: element.approve,
            reject: element.reject,
            position: "",
            priority: element.priority,
            username: element.username,
            fullName: element.fullName,
            note: note,
          };
          return result;
        }
      );
      setWorkflow(workflowData);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getListSubmittedDirectCostLabor = async (
    documentSubmitId: number,
    documentOriginId: number
  ): Promise<any> => {
    try {
      const params = {
        documentSubmitId,
      };
      const res = await CM0014Service.getListSubmittedDirectCostLabor(params);
      if (res?.results) {
        const data: TableSubmissionHistory[] =
          formatListSubmittedDirectCostLabor(res.results);
        let totalActualCost = 0;
        if (data) {
          data.forEach((item: TableSubmissionHistory) => {
            totalActualCost += item.actualCost;
          });
          setDataTableSubmissionHistory(data);
        }

        // Make call other api
        if (res?.results.length !== 0) {
          getDataReceivedMoney(
            res.results[0].dataSummary.construction.id,
            documentSubmitId
          );
          getDataConstructionInformationCost(
            res.results[0].dataSummary.construction.id
          );
          getDataLaborListManager(
            res.results[0].dataSummary.construction.id,
            documentSubmitId,
            documentOriginId,
            totalActualCost
          );

          setDataSummaryTitle(res?.results[0]?.dataSummary);
        }
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const formatListSubmittedDirectCostLabor = (data: any) => {
    const dataFormat: TableSubmissionHistory[] = data.map(
      (item: any, index: number) => {
        return {
          id: item.id,
          key: item.id,
          status: item?.status,
          note: item?.note,
          paymentDate: `${
            item?.paymentDate
              ? moment(item?.paymentDate).format(COMMON.FORMAT_DATE_OM)
              : ""
          }`,
          targetStage: `${
            item.startDate
              ? moment(item.startDate).format(COMMON.FORMAT_DATE_OM)
              : ""
          }～${
            item.endDate
              ? moment(item.endDate).format(COMMON.FORMAT_DATE_OM)
              : ""
          }`,
          payAmount: item?.planLaborCost,
          actualCost: item?.actualCost,
          evidence: item?.evidence,
        };
      }
    );
    return dataFormat;
  };

  const handleOpenViewer = async (record: any) => {
    try {
      dispatch(setLoading(true));
      const companyId = JSON.parse(localStorage.getItem("user") ?? `{}`)
        ?.company?.id;
      const response = await CM0014Service.getPresignLinkS3({
        keyName: `re/${companyId}/${SCREEN_NAME_VIEW}/${record.id}/${record.evidence}`,
      });
      const checkFile = record.evidence?.split(".").pop();
      const evidenceName = record.evidence?.split(".").slice(0, -1).join(".");

      setPdfName(evidenceName);
      setPdfUrl(response.results);

      if (checkFile === "pdf") dispatch(showPdfViewerModal());
      else dispatch(showImageViewerModal());
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleCaculateSummary = (data: TableSubmissionHistory[]) => {
    let totalPayAmount = 0;
    let totalActualCost = 0;
    data.forEach((element: any) => {
      totalPayAmount += element.payAmount;
      totalActualCost += element.actualCost;
    });

    setDataSummary({
      totalPayAmount,
      totalActualCost,
    });
  };

  const onCancel = () => {
    dispatch(resetCM0014());
  };

  const getDataReceivedMoney = async (
    constructionId: number,
    documentId: number
  ) => {
    const responseA = await CM006Service.getConstructionInformation({
      constructionId: constructionId,
      documentId: documentId,
    });

    const responseB = await CM006Service.getListConstructionReceivedMoney({
      constructionId: constructionId,
    });

    setReceivedMoney(convertDataOverview(responseA.results, responseB.results));
  };

  const getDataConstructionInformationCost = async (constructionId: number) => {
    const response = await CM0014Service.getConstructionSummaryCostInformation({
      constructionId: constructionId,
    });

    setConstructionInformationCost(
      convertDataTableInformationCost([response.results])
    );
  };

  const getDataLaborListManager = async (
    constructionId: number,
    documentSubmitId: number,
    documentOriginId: number,
    actualCost: number
  ) => {
    const response = await CM0014Service.getLaborListManager({
      constructionId: constructionId,
      documentId: documentSubmitId,
    });

    let planCostTotal = 0;

    response.results.forEach((element: any) => {
      if (element.id === documentOriginId) {
        planCostTotal += element?.planCost ?? 0;
      }
    });

    setDataTableBudgetBalanceContact(
      convertDataTable(planCostTotal, actualCost)
    );
  };

  const retrieveDocument = (documentId: number) => {
    ConfirmModal({
      onOk: async () => {
        const payload = {
          documentId: documentId,
          listDocumentDirectLaborCostInformationId: checkedList,
        };
        const response = await CM0014Service.retrieveLaborDocument(payload);

        if (response.status === `${COMMON.HTTP_STATUS_OK}`) {
          dispatch(resetCM0014());
          dispatch(setRefresh());
        }
        SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
      },
      className: "confirm__modal",
      title: MESSAGE.MESSAGE_TITLE_CM016,
      description: MESSAGE.MESSAGE_DESCRIPTION_CR_3,
      extraDescription: MESSAGE.MESSAGE_DESCRIPTION_COMMON_QUESTION_1,
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_MODAL,
      isCenterWithoutMenu: true,
    });
  };
  const onCheckAllChange = (checked: boolean) => {
    setCheckAll(checked);
    if (checked) {
      const checkedItems: any[] = [];
      for (let element of dataTableSubmissionHistory) {
        checkedItems.push(element.id);
      }
      setCheckedList(checkedItems);
    } else setCheckedList([]);
  };

  const isCheckbox = (record: any): boolean =>
    checkedList.some((element) => element === record.key);

  const onCheckboxChange = (record: any, checked: boolean) => {
    let newCheckList: any[] = [];

    if (checked) {
      newCheckList = [...checkedList, record.key];
    } else {
      const checkedData = checkedList.filter(
        (element: any) => element !== record.key
      );
      newCheckList = checkedData;
    }
    const possibleItems: any[] = [];

    for (let element of dataTableSubmissionHistory) {
      if (
        [
          STATUS_CM_CHILD_TABLE.MONEY_NOT_APPROVED.CODE,
          STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE,
        ].includes(element?.status?.code)
      )
        possibleItems.push(element.id);
    }
    setCheckedList(newCheckList);
    setCheckAll(newCheckList.length === possibleItems.length);
  };

  const viewApproveModal = (record: any) => {
    dispatch(setDocumentView(record));
  };

  const showConfirmApproveModal = () => {
    dispatch(showModalApproveConfirm());
  };
  return {
    dataTableBudgetBalanceContact,
    dataTableSubmissionHistory,
    isOpenViewer,
    pdfUrl,
    pdfName,
    dataSummary,
    dataReceivedMoney,
    constructionInformationCost,
    dataSummaryTitle,
    workflow,
    currentUserApproval,
    checkAll,
    checkedList,
    handleOpenViewer,
    handleCaculateSummary,
    onCancel,
    getListSubmittedDirectCostLabor,
    getDataConstructionInformationCost,
    getDataLaborListManager,
    retrieveDocument,
    getWorkflowLaborApprovalProcess,
    onCheckAllChange,
    isCheckbox,
    onCheckboxChange,
    viewApproveModal,
    showConfirmApproveModal,
  };
};

export default CM0014Handler;
