import type { ColumnsType } from "antd/es/table";
import helpers from "../../../../../common/helpers/common";
import { Checkbox } from "antd";
import IconEdit from "../../../../../assets/icons/edit-blue.svg";
import IconCloseRed from "../../../../../assets/icons/icon-close-red.svg";
import IconEditGreen from "../../../../../assets/icons/icon-edit-green.svg";
import { PM012TableHandler } from "../handler/HandlerImpl";
import { isHasPrivilege } from "../../../../../common/helpers/privilege";
import {
  PRIVILEGE_CREATOR,
  PRIVILEGE_PM,
} from "../../../../../common/constants/PRIVILEGE";
import { SYSTEM_ROLES } from "../../../../../common/constants/AUTHORIZATION";
import { RootState } from "../../../../../store";
import { useSelector } from "react-redux";
import { PLAN_TYPE } from "../../../../../common/constants/COMMON";

interface DataType {
  key: string | number;
  name: string;
  position: string;
  affiliation: string;
  contact: string;
  email: string;
  check: boolean;
  checkPermission: boolean;
  userWorkspaceRole: {
    id: number;
    name: string;
    roleCode: string;
  };
}

export const COLUMNS = (handler: PM012TableHandler): ColumnsType<DataType> => {
  const isOrderBasic =
    useSelector((state: RootState) => state.auth.authData?.order?.code) ===
    PLAN_TYPE.PLAN_BASIC;

  let columns: ColumnsType<DataType> = [
    {
      title: "名前",
      dataIndex: "fullName",
      key: "fullName",
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: true,
      showSorterTooltip: false,
      sortOrder: handler.handleSortOrder("fullName"),
      render: (_, row: any) => {
        return <span className="pm012-text-purple">{row?.fullName}</span>;
      },
    },
    {
      title: "CCUS技能者ID",
      key: "ccusId",
      render: (_, row: any) =>
        row?.ccusId ? helpers.convertToCCUSFormat(row?.ccusId) : "",
    },
    {
      title: "役職",
      dataIndex: "role",
      key: "role",
      render: (_, row: any) => row?.userWorkspaceRole?.name,
    },
    {
      title: "部門",
      dataIndex: "branch",
      key: "branch",
      sorter: true,
      showSorterTooltip: false,
      sortOrder: handler.handleSortOrder("branch"),
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_, row: any) => row?.group?.name || "",
    },
    {
      title: "電話番号",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (_, row: any) => helpers.getPhoneNumberFormat(row?.phoneNumber),
    },
    {
      title: "メールアドレス",
      dataIndex: "email",
      key: "email",
      sorter: true,
      showSorterTooltip: false,
      sortOrder: handler.handleSortOrder("email"),
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_, row: any) => row?.email,
    },
  ];
  if (
    handler.isViewNav &&
    handler.funcCheckRole([PRIVILEGE_PM, PRIVILEGE_CREATOR])
  ) {
    columns.push({
      title: () => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span>勤務チェック権限</span>

            <img
              style={{
                marginLeft: 15,
                cursor: handler.isEditDoubleCheck ? "not-allowed" : "pointer",
                width: 22,
              }}
              alt=""
              src={IconEdit}
              onClick={handler.handleCheckEditDoubleCheck}
            />

            {handler.isEditDoubleCheck ? (
              <div
                ref={handler.refDoubleCheckShadow}
                className="action-double-check"
              >
                <div ref={handler.refDoubleCheck}>
                  <img
                    style={{ marginLeft: 5, cursor: "pointer" }}
                    alt=""
                    src={IconEditGreen}
                    onClick={handler.handleSubmitDoubleCheck}
                  />
                </div>
                <div>
                  <img
                    style={{ marginLeft: 5, cursor: "pointer" }}
                    alt=""
                    src={IconCloseRed}
                    onClick={handler.handleCancelDoubleCheck}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        );
      },
      width: 220,
      align: "right",
      key: "checkbox",
      render: (_, record) => {
        return (
          <div style={{ marginRight: "50%" }}>
            <Checkbox
              onChange={(e) => handler.handleCheckboxDouble(e, record.key)}
              checked={record.check}
              disabled={
                !handler.isEditDoubleCheck ||
                !record?.checkPermission ||
                record.userWorkspaceRole.roleCode === SYSTEM_ROLES.ADMIN
              }
            />
          </div>
        );
      },
    });
  }

  if (!isOrderBasic) columns = columns.filter((col) => col.key !== "role")

  return columns;
};
