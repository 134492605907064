import { uuidv4 } from '@firebase/util';
import { Form } from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmModal from '../../../../../common/components/modal/ConfirmModal';
import ErrorNotification from '../../../../../common/components/notification/ErrorNotification';
import MESSAGE, {
    LABEL_MESSAGE,
    NOTIFICATION_TITLE,
} from '../../../../../common/constants/MESSAGE';
import { RootState } from '../../../../../store';
import { ReceivedMoneyOffset, SubCategory } from '../../entity/Entity';
import { CM0012_2UseCase } from '../../usecase/ServiceImpl';
import { setIsVisibleCM0012_2, setLoadingCM0012_2, setTypeModalCM0012_2 } from '../slice/Slice';

const CM0012_2Handler = (CM0012_2Service: CM0012_2UseCase) => {
    // LIB FUNCTION
    const dispatch = useDispatch();

    //STATE
    const [form] = Form.useForm();
    const [isDirty, setIsDirty] = useState(false);
    const typeModal = useSelector((state: RootState) => state.cm0012_2.typeModal);
    const [listReceivedMoneyOffset, setListReceivedMoneyOffset] = useState<ReceivedMoneyOffset[]>(
        [],
    );
    const [listReceivedMoneyOffsetLocal, setListReceivedMoneyOffsetLocal] = useState<
        ReceivedMoneyOffset[]
    >([]);

    const [subCategory, setSubCategory] = useState<SubCategory[]>([]);
    const [totalCost, setTotalCost] = useState<number>(0);

    //function
    const handleGetListReceivedMoneyOffset = async (
        outsourceCostInformationId: number,
        categoryId: number,
    ): Promise<any> => {
        try {
            dispatch(setLoadingCM0012_2(true));
            const params = {
                outsourceCostInformationId,
                categoryId,
            };
            const res = await CM0012_2Service.getListReceivedMoneyOffset(params);
            if (res?.results) {
                const data = formatReceivedMoneyOffset(res.results);
                setListReceivedMoneyOffset(data);
                setListReceivedMoneyOffsetLocal(data);
                handleFillForm(data);
                handleCalculateChange(data);
            }
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        } finally {
            dispatch(setLoadingCM0012_2(false));
        }
    };

    const formatReceivedMoneyOffset = (data: any) => {
        const arr: ReceivedMoneyOffset[] = data.map((item: any, index: number) => {
            return {
                key: index + 1 + '',
                id: item.id,
                item: item.name,
                taxCategoryComsumptionId: item?.category?.id,
                taxCategoryComsumptionName: item?.category?.description,
                offsetAmount: item.value,
            };
        });
        return arr;
    };

    const handleGetSubCategory = async (): Promise<any> => {
        try {
            dispatch(setLoadingCM0012_2(true));
            const res = await CM0012_2Service.getSubCategory();
            if (res?.results) {
                setSubCategory(handleFormatSubCategory(res.results));
            }
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        } finally {
            dispatch(setLoadingCM0012_2(false));
        }
    };

    const handleFormatSubCategory = (data: any) => {
        const arr: SubCategory[] = data.map((item: any) => {
            return {
                label: item.description,
                value: item.id,
                code: item.code,
            };
        });
        return arr;
    };

    const handleFillForm = (data: ReceivedMoneyOffset[]) => {
        data.map((item) => {
            form.setFieldValue(`item${item.key}`, item.item);
            form.setFieldValue(`taxCategoryConsumption${item.key}`, item.taxCategoryComsumptionId);
            form.setFieldValue(`offsetAmount${item.key}`, item.offsetAmount);
        });
    };

    const handleCalculateChange = (data: ReceivedMoneyOffset[]) => {
        let total: number = 0;
        data.forEach((item) => {
            const cost = form.getFieldValue(`offsetAmount${item.key}`);
            if (!isNaN(cost)) total += cost;
        });
        setTotalCost(total);
    };

    const handleAddRowLocal = () => {
        const uniqueId = uuidv4();

        const data: ReceivedMoneyOffset = {
            key: uniqueId,
            item: undefined,
            taxCategoryComsumptionId: undefined,
            offsetAmount: undefined,
        };
        setListReceivedMoneyOffsetLocal([...listReceivedMoneyOffsetLocal, data]);
        form.setFieldValue(`taxCategoryConsumption${uniqueId}`, subCategory[0]?.value);
    };

    const handleRemoveRowLocal = (key: string) => {
        if (
            !form.getFieldValue(`item${key}`) &&
            form.getFieldValue(`taxCategoryConsumption${key}`) === subCategory[0]?.value &&
            !form.getFieldValue(`offsetAmount${key}`)
        ) {
            const arr = listReceivedMoneyOffsetLocal.filter((item) => item.key !== key);
            setListReceivedMoneyOffsetLocal([...arr]);
            handleCalculateChange(arr);
            return;
        }
        ConfirmModal({
            onOk: async () => {
                const arr = listReceivedMoneyOffsetLocal.filter((item) => item.key !== key);
                setListReceivedMoneyOffsetLocal(arr);
                handleCalculateChange(arr);
                setIsDirty(true);
            },
            className: 'confirm__modal',
            title: MESSAGE.MESSAGE_MODAL_DELETE_TITLE,
            description: MESSAGE.MESSAGE_MODAL_DELETE_DESCRIPTION_CM,
            canceText: LABEL_MESSAGE.CANCEL_MODAL,
            okText: LABEL_MESSAGE.OK_MODAL,
            isCenterWithoutMenu: true,
        });
    };

    const handleSubmitForm = async (value: any) => {
        let listData: ReceivedMoneyOffset[] = [];
        listReceivedMoneyOffsetLocal.forEach((item) => {
            listData.push({
                key: item.key + '',
                item: form.getFieldValue(`item${item.key}`),
                taxCategoryComsumptionId: form.getFieldValue(`taxCategoryConsumption${item.key}`),
                taxCategoryComsumptionName: convertCategoryIdToCategoryName(
                    form.getFieldValue(`taxCategoryConsumption${item.key}`),
                ),
                offsetAmount: form.getFieldValue(`offsetAmount${item.key}`),
            });
        });

        setListReceivedMoneyOffsetLocal(listData);
        form.resetFields();
        setIsDirty(false);
        dispatch(setTypeModalCM0012_2(false));
        return;
    };

    const convertCategoryIdToCategoryName = (id: number) => {
        const res = subCategory.find((item) => item.value === id);
        return res?.label;
    };

    const handleChangeTypeModal = (type: boolean) => {
        if (type) handleFillForm(listReceivedMoneyOffsetLocal);
        dispatch(setTypeModalCM0012_2(type));
    };

    const checkDirty = (): void => {
        const formValue = form.getFieldsValue();
        const formProps = Object.keys(formValue);
        for (let i = 0; i < formProps.length; i++) {
            if (form.isFieldTouched(formProps[i])) {
                return setIsDirty(true);
            }
        }
        setIsDirty(false);
    };

    const onCancel = () => {
        if (!isDirty) {
            if (!typeModal) dispatch(setIsVisibleCM0012_2(false));
            else {
                dispatch(setTypeModalCM0012_2(false));
            }
            return;
        }
        ConfirmModal({
            onOk: async () => {
                if (!typeModal) {
                    dispatch(setIsVisibleCM0012_2(false));
                } else {
                    dispatch(setTypeModalCM0012_2(false));
                    form.resetFields();
                }
                handleCalculateChange(listReceivedMoneyOffset);
                setListReceivedMoneyOffsetLocal(listReceivedMoneyOffset);
                setIsDirty(false);
            },
            className: 'confirm__modal',
            title: MESSAGE.MESSAGE_020,
            description: MESSAGE.MESSAGE_021,
            canceText: LABEL_MESSAGE.CANCEL_MODAL,
            okText: LABEL_MESSAGE.OK_MODAL,
            isCenterWithoutMenu: true,
        });
    };

    return {
        form,
        typeModal,
        listReceivedMoneyOffsetLocal,
        totalCost,
        subCategory,
        onCancel,
        checkDirty,
        handleAddRowLocal,
        handleRemoveRowLocal,
        handleSubmitForm,
        handleCalculateChange,
        handleGetListReceivedMoneyOffset,
        handleGetSubCategory,
        handleChangeTypeModal,
    };
};

export default CM0012_2Handler;
