import { Checkbox, Input } from 'antd';
import React from 'react';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import helpers from '../../../../../common/helpers/common';
import { PM014TableHandler } from '../handler/HandlerImpl';
import { enableChecked } from '../slice/Slice';
import PM014ItemUnit from './PM014ItemUnit';
import DisabledCheckboxSvg from '../../../../../assets/icons/disabled-checkbox.svg';
import { PRIVILEGE_CREATOR, PRIVILEGE_PM, PRIVILEGE_SUB_PM } from '../../../../../common/constants/PRIVILEGE';
import { RootState } from '../../../../../store';
import { SYSTEM_ROLES } from '../../../../../common/constants/AUTHORIZATION';
import { isHasPrivilege } from '../../../../../common/helpers/privilege';

interface props {
    data: any[];
    constructionId: number;
    contractorConstructionId: number;
    handler: PM014TableHandler;
}

const PM014ItemMember = ({ data, handler, constructionId, contractorConstructionId }: props) => {

    const projectDetail = useSelector(
        (state: RootState) => state.pm005.projectDetail
    );

    const isRoleAdmin = useSelector((state: RootState) => state.auth?.authData?.isRoleAdmin)

    const isRoleAdminSystem =
        useSelector((state: RootState) => state.auth?.authData?.isRoleAdminSystem)

    const childrens: any[] = [];
    data.map((item: any, idx: number) => {
        childrens.push(
            <div className="pm011-member-item" key={idx}>
                {
                    isHasPrivilege(projectDetail, [
                        PRIVILEGE_PM,
                        PRIVILEGE_SUB_PM,
                        PRIVILEGE_CREATOR,
                    ]) || isRoleAdmin || isRoleAdminSystem
                        ?
                        <div className="pm011-member-item-checkbox">
                            {item?.approve && handler.currentTab === '1' ? (
                                <img alt="" src={DisabledCheckboxSvg} style={{ width: 16, height: 16 }} />
                            ) : (
                                <Checkbox
                                    value={`${contractorConstructionId}-${item?.id}`}
                                    onChange={(e: any) => {
                                        handler.funcAddCheckbox(e);
                                    }}
                                />
                            )}
                        </div>
                        :
                        <div ></div>
                }
                <div className="pm011-member-item-name pm014-table-3">
                    <span>{item?.fullName}</span>
                </div>
                <div className="pm011-member-item-ccus-id pm014-table-3">
                    <span>{helpers.convertToCCUSFormat(item?.ccusId)}</span>
                </div>
                {handler.funcCheckRole([PRIVILEGE_PM, PRIVILEGE_CREATOR]) ? (
                    handler.currentTab === '2' ? (
                        <PM014ItemUnit
                            handler={handler}
                            value={item?.laborCost}
                            constructionId={constructionId}
                            contractorConstructionId={contractorConstructionId}
                            id={item?.id}
                        />
                    ) : null
                ) : null}
                <div className="pm011-member-item-phone pm014-table-3">
                    <span>{helpers.getPhoneNumberFormat(item?.phoneNumber)}</span>
                </div>
                <div className="pm011-member-item-email pm014-table-3">
                    <span>{item?.email}</span>
                </div>
            </div>,
        );
    });

    return <div className="pm011-list-member">{childrens}</div>;
};

export default PM014ItemMember;
