import { ConstructionCompany, PM011liceType } from "./../type/Presenter";
import { createSlice } from "@reduxjs/toolkit";

export const initialState: PM011liceType = {
  projectDetail: null,
  isOpenPM011: false,
  isUpdating: true,
  isCheckAll: false,
  eventCheckAll: false,
  loading: false,
  listCheckedMembers: [],
  numberChosenCheckbox: 0,
  totalMember: 0,
  isRefresh: false,
  companyActiveKey: [],
  numberCheckbox: 0,
  constructionCompany: [],
  constructionCountMembers: [],
};

export const PM011Slice = createSlice({
  name: "pm011",
  initialState,
  reducers: {
    resetPM011: () => initialState,
    resetWithoutCloseModal: (state) => {
      state.isUpdating = true;
      state.isCheckAll = false;
      state.loading = false;
      state.listCheckedMembers = [];
      state.companyActiveKey = [];
      state.numberCheckbox = 0;
      state.numberChosenCheckbox = 0;
      state.isRefresh = !state.isRefresh;
      state.constructionCompany = [];
      state.totalMember = 0;
    },
    setConstructionCompanySlice: (state, action) => {
      state.constructionCompany = action.payload;
    },
    updateAConstructionCompany: (state, action) => {
      const desiredUpdate = action.payload;
      const result = state.constructionCompany.map(
        (element: ConstructionCompany) => {
          if (
            desiredUpdate.constructionId &&
            element.constructionId === desiredUpdate.constructionId
          ) {
            const result = { ...element };
            if (desiredUpdate.hasOwnProperty("isOpenAll"))
              result.isOpenAll = desiredUpdate.isOpenAll;
            if (desiredUpdate.hasOwnProperty("isCheckAll"))
              result.isCheckAll = desiredUpdate.isCheckAll;
            if (desiredUpdate.hasOwnProperty("totalMember"))
              result.totalMember = desiredUpdate.totalMember;
            return result;
          }
          return element;
        }
      );
      state.constructionCompany = result;
      if (desiredUpdate.isCheckAll) state.eventCheckAll = !state.eventCheckAll;
    },
    refreshPM011: (state) => {
      state.isRefresh = !state.isRefresh;
    },
    showPM011Modal: (state, action) => {
      state.projectDetail = action.payload.projectDetail;
      state.isOpenPM011 = true;
    },
    hidePM011Modal: (state) => {
      state.isOpenPM011 = false;
    },
    setIsUpdating: (state, action) => {
      state.isUpdating = action.payload;
    },
    setIsCheckAll: (state, action) => {
      state.isCheckAll = action.payload;
    },
    setLoadingPM011: (state, action) => {
      state.loading = action.payload;
    },
    setListCheckedMembers: (state, action) => {
      state.listCheckedMembers = action.payload;
    },
    addTotalMember: (state, action) => {
      if (!isNaN(action.payload)) state.totalMember += action.payload;
    },
    setCompanyActiveKey: (state, action) => {
      const payloadData = action.payload;
      if (payloadData.length === 0) return;
      const temp = [...state.companyActiveKey, payloadData[0]];
      state.companyActiveKey = [...new Set(temp)];
    },
    setNumberCheckBox: (state, action) => {
      state.numberCheckbox += action.payload;
    },
    setNumberChosenCheckBox: (state, action) => {
      state.numberChosenCheckbox += action.payload;
    },
    setHardNumberCheckBox: (state, action) => {
      state.numberCheckbox = action.payload;
      state.eventCheckAll = !state.eventCheckAll;
    },
    setHardNumberChosenCheckBox: (state, action) => {
      state.numberChosenCheckbox = action.payload;
      state.eventCheckAll = !state.eventCheckAll;
    },
    toggleEventCheckAll: (state) => {
      state.eventCheckAll = !state.eventCheckAll;
    },

    setConstructionCountMembers: (state, action) => {
      state.constructionCountMembers = action.payload;
    },
    updateConstructionCountMembers: (state, action) => {
      const id = action.payload.id;
      const totalMember = action.payload.totalMember;

      state.constructionCountMembers = state.constructionCountMembers.map(
        (element) => {
          return {
            ...element,
            totalMember:
              element.id === id
                ? element.totalMember + totalMember
                : element.totalMember,
          };
        }
      );
    },
  },
});

export const {
  showPM011Modal,
  hidePM011Modal,
  resetPM011,
  setIsCheckAll,
  setIsUpdating,
  setLoadingPM011,
  setListCheckedMembers,
  setCompanyActiveKey,
  setNumberCheckBox,
  addTotalMember,
  setNumberChosenCheckBox,
  setHardNumberChosenCheckBox,
  setHardNumberCheckBox,
  toggleEventCheckAll,
  refreshPM011,
  resetWithoutCloseModal,
  setConstructionCompanySlice,
  updateAConstructionCompany,
  setConstructionCountMembers,
  updateConstructionCountMembers,
} = PM011Slice.actions;

export default PM011Slice.reducer;
