import { Button, Form, Input, InputNumber } from "antd";
import React from "react";
import MESSAGE from "../../../../../common/constants/MESSAGE";
import helpers from "../../../../../common/helpers/common";
import { typeInteger } from "../../../../../common/helpers/typeInput";
import { PM014TableHandler } from "../handler/HandlerImpl";

import ACCEPT from "../../../../../assets/icons/icon-accept.svg";
import DELETE from "../../../../../assets/icons/icon-delete-X.svg";
import IconEdit from "../../../../../assets/icons/icon-edit-small.svg";
import { format } from "path";
import { useDispatch, useSelector } from "react-redux";
import { refreshPM014, setRefreshPM014 } from "../slice/Slice";

type props = {
  handler: PM014TableHandler;
  value: any;
  constructionId: number;
  contractorConstructionId: number;
  id: number;
};

const PM014ItemUnit = ({
  handler,
  value,
  constructionId,
  contractorConstructionId,
  id,
}: props) => {
  const [enableEdit, setEnableEdit] = React.useState<boolean>(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const isRefresh = useSelector(refreshPM014);

  const handleUpdateCost = async (values: any) => {
    try {
      handler.funcUpdateLaborCost(
        constructionId,
        contractorConstructionId,
        Number(values?.cost),
        id
      );
      dispatch(setRefreshPM014(!isRefresh));
      setEnableEdit(false);
    } catch (error) {
      throw error;
    }
  };

  return (
    <div className="pm011-member-item-phone pm014-table-3">
      {enableEdit ? (
        <div style={{ display: "flex" }}>
          <Form
            form={form}
            name="thang guc nga"
            initialValues={{ cost: value }}
            onFinish={handleUpdateCost}
          >
            <Form.Item
              name="cost"
              rules={[
                {
                  required: true,
                  message: MESSAGE.MESSAGE_REQUIRED,
                },
              ]}
              style={{ marginBottom: 0 }}
            >
              <InputNumber controls={false}
                autoComplete="off"
                className="pm014-input-edit"
                onKeyPress={typeInteger}
                min={0}
                max={9999999999}
                formatter={helpers.formatInputNumber}
                parser={helpers.parserInputNumber}
                maxLength={15}
                addonAfter={
                  <>
                    <img
                      onClick={() => form.submit()}
                      style={{ cursor: "pointer" }}
                      src={ACCEPT}
                      alt="accept"
                    />
                    &nbsp;
                    <img
                      style={{ cursor: "pointer" }}
                      src={DELETE}
                      alt="delete"
                      onClick={() => setEnableEdit(false)}
                    />
                  </>
                }
              />
            </Form.Item>
          </Form>
        </div>
      ) : (
        // </div>
        <>
          <span>{helpers.formatNumberWithCommas(value)}</span>
          &nbsp;
          <img
            src={IconEdit}
            onClick={() => setEnableEdit(true)}
            style={{ cursor: "pointer" }}
          />
        </>
      )}
    </div>
  );
};

export default PM014ItemUnit;
