import { AxiosResponse } from 'axios';
import axiosConfig from '../../../../../config/axiosConfig';

const CCUS05Api = () => {

    const getTimesheetSyncData = async (params: {
        projectId?: number;
        companyId?: number;
        state?: string;
        sortBy?: string;
        sortType?: string;
        page?: number;
        size?: number;
    }): Promise<AxiosResponse<any, any>> => {
        const url = "/ccus/timesheet/sync-data";
        return await axiosConfig.get(url, { params });
    };

    const getProjectListCCUS = async (params: {
        companyId?: number;
        approverId?: number;
        state?: string;
        page: number;
        size: number;
    }): Promise<AxiosResponse<any, any>> => {
        const url = "/ccus/project/list";
        return await axiosConfig.get(url, { params });
    };

    const getDependedCompanyListCCUS = async (params: {
        projectId?: number;
        approverId?: number;
        state?: string;
        page: number;
        size: number;
    }): Promise<AxiosResponse<any, any>> => {
        const url = "/ccus/depended-company/list";
        return await axiosConfig.get(url, { params });
    };

    const approveCCUS = async (payload: { id: number[] }) => {
        const url = '/ccus/approve';
        return await axiosConfig.post(url, payload);
    };

    return {
        getTimesheetSyncData,
        getProjectListCCUS,
        getDependedCompanyListCCUS,
        approveCCUS
    };
};

export default CCUS05Api;
