import ErrorNotification from '../../../../common/components/notification/ErrorNotification';
import { NOTIFICATION_TITLE } from '../../../../common/constants/MESSAGE';
import { WorkflowCreatePayload, WorkflowSettingPayload } from '../entity/Entity';
import { SW01APIImpl } from './ServiceImpl';

const SW01Service = (api: SW01APIImpl) => {
    const getWorkflowList = async (params: { categoryId?: number }) => {
        try {
            const response = await api.getWorkflowList(params);
            return response?.data;
        } catch (error: any) {
            ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };

    const getWorkflowTypeList = async (params: {}) => {
        try {
            const response = await api.getWorkflowTypeList(params);
            return response?.data;
        } catch (error: any) {
            ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };

    const postWorkflowCreate = async (payload: WorkflowCreatePayload) => {
        try {
            const response = await api.postWorkflowCreate(payload);
            return response?.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };

    const putWorkflowSetting = async (payload: WorkflowSettingPayload) => {
        try {
            const response = await api.putWorkflowSetting(payload);
            return response?.data;
        } catch (error: any) {
            throw error;
        }
    };
    return { getWorkflowList, getWorkflowTypeList, postWorkflowCreate, putWorkflowSetting };
};

export default SW01Service;
