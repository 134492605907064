// Built-in
import React from 'react';

// 3rd party lib
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';

// Source files
import CollapsedTableComponent from '../../collapsed-table';
import { MaterialCostHandlerImpl } from '../../../handler/HandlerImpl';

// Image files
import ExpandedIconSvg from '../../../../../../../assets/icons/expanded-icon.svg';
import ClosedIconSvg from '../../../../../../../assets/icons/closed-icon.svg';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store';
import helpers from '../../../../../../../common/helpers/common';
import TooltipText from '../../../../../../../common/components/tooltip-text/TooltipText';
import ThresholdPopoverComponent from '../../../../../../../common/components/threshold-popover';

type props = {
    handler: MaterialCostHandlerImpl;
};
export const COLUMN_WIDTH_CM0015: number[] = [
    100, 200, 350, 200, 120, 120, 120, 150, 150, 174, 120,
];

const MaterialCostUI = ({ handler }: props) => {
    const [searchParams] = useSearchParams();
    const constructionId = searchParams.get('constructionId');
    const projectName = searchParams.get('projectName') || "";
    const isSubmit = useSelector((state: RootState) => state.cm004.isSubmit);
    const data = useSelector((state: RootState) => state.cm0015.dataMaterialCost);
    const isExport = useSelector((state: RootState) => state.cm004.isExport);

    const firstRender2 = useRef(false);
    useEffect(() => {
        if (firstRender2.current) {
            (async () => {
                if (constructionId && !isNaN(parseInt(constructionId)))
                    await handler.exportFile({
                        constructionId: parseInt(constructionId),
                        sortBy: 'createdAt',
                        sortType: 'ASC',
                        projectName
                    });
            })();
            return;
        }
        firstRender2.current = true;
    }, [isExport]);

    const isCheckRef = useRef(false);
    useEffect(() => {
        if (isCheckRef.current) {
            handler.putMoneySubmitIndirectCost();
        }
        isCheckRef.current = true;
    }, [isSubmit]);

    useEffect(() => {
        handler.handleCheckEnableSubmit();
    }, [JSON.stringify(handler.checkedList)]);

    useEffect(() => {
        (async () => {
            if (constructionId && !isNaN(parseInt(constructionId)))
                await handler.getMoneyIndirectCostList({
                    constructionId: parseInt(constructionId),
                    sortBy: 'createdAt',
                    sortType: 'DESC',
                });
        })();
    }, [constructionId, handler.refresh]);
    const columns: ColumnsType<any> = [
        {
            // title: (
            //     <CheckBoxAllComponentCM0013CM0015
            //         onCheckAllChange={handler.onCheckAllChange}
            //         checkAll={handler.checkAll}
            //         data={data}
            //     />
            // ),
            title: 'No',
            key: 'No',
            width: COLUMN_WIDTH_CM0015[0],
            render: (_, record, index) => {
                // const foundData = data.find((element) => element.id === record.id);
                // if (foundData?.child?.length === 0)
                //     return (
                //         <span
                //             className="cell-cm007-style white-space-nowrap"
                //             style={{ width: '100%', float: 'left', textAlign: 'left' }}
                //             onClick={(event) => {
                //                 event.stopPropagation();
                //             }}
                //         >
                //             <Checkbox
                //                 className="no-hover"
                //                 disabled={true}
                //                 style={{ marginRight: 8 }}
                //             />
                //             {record.no}
                //         </span>
                //     );
                return (
                    <span
                        className="cell-cm007-style white-space-nowrap"
                        style={{ width: '100%', float: 'left', textAlign: 'left' }}
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        {/* {record.status.code === STATUS_PARENT.approved.code ||
                            record.status.code === STATUS_PARENT.waiting_for_approve.code ? (
                            <Checkbox
                                className="no-hover"
                                disabled={true}
                                style={{ marginRight: 8 }}
                                checked
                            />
                        ) : (
                            <Checkbox
                                onChange={(value: any) =>
                                    handler.onCheckboxChange(
                                        record,
                                        value?.target?.checked ? true : false,
                                    )
                                }
                                style={{ marginRight: 8 }}
                                checked={handler.isCheckbox(record)}
                            />
                        )} */}
                        {index + 1}
                    </span>
                );
            },
        },
        {
            title: '項目名',
            key: '項目名',
            width: COLUMN_WIDTH_CM0015[1],
            render: (record) => (
                <span className="cell-cm007-style white-space-nowrap">{record.name}</span>
            ),
        },
        {
            title: '',
            key: 'blank1',
            width: COLUMN_WIDTH_CM0015[2],
            render: (record) => <div></div>,
        },
        {
            title: '',
            key: 'blank2',
            width: COLUMN_WIDTH_CM0015[3],
            render: (record) => <div></div>,
        },
        {
            title: '',
            key: 'blank3',
            width: COLUMN_WIDTH_CM0015[4],
            render: (record) => <div></div>,
        },
        {
            title: '予算金額',
            key: '予算金額',
            width: COLUMN_WIDTH_CM0015[5],
            render: (record) => (
                <span className="cell-cm007-style white-space-nowrap">{`${helpers.formatNumberMoney(
                    record.budgetAmount,
                )}円`}</span>
            ),
        },
        {
            title: '実費用',
            key: '実費用',
            width: COLUMN_WIDTH_CM0015[6],
            render: (record) => (
                <span
                    className="cell-cm007-style white-space-nowrap"
                    style={{ textAlign: 'center' }}
                >
                    {`${helpers.formatNumberMoney(record.actualCost)}円`}
                </span>
            ),
        },
        {
            title: '予算残高',
            key: '予算残高',
            width: COLUMN_WIDTH_CM0015[7],
            render: (record) => (
                <span
                    className="cell-cm007-style white-space-nowrap"
                    style={{ textAlign: 'center' }}
                >
                    {`${helpers.formatNumberMoney(record.budgetBalance)}円`}
                </span>
            ),
        },
        {
            title: '予算消化率（％）',
            key: '予算消化率（％）',
            width: COLUMN_WIDTH_CM0015[8],
            render: (record) => (
                <div onClick={(e) => e.stopPropagation()}>
                    <span className="cell-cm007-style white-space-nowrap">{`${helpers.calculateRateAmount(
                        record.budgetAmount,
                        record.actualCost ?? 0,
                    )}%`}</span>
                    <ThresholdPopoverComponent
                        initialData={{
                            ...record,
                            constructionId: parseInt(constructionId ?? '0'),
                        }}
                        update={(payload) => handler.putMoneyIndirectCostEnable(payload, record.id)}
                    />
                </div>
            ),
        },
        {
            title: 'ステータス',
            key: 'ステータス',
            width: COLUMN_WIDTH_CM0015[9],
            render: (record) => (
                <span className="cell-cm007-style white-space-nowrap">{record.status.name}</span>
            ),
        },
        {
            title: '',
            key: 'collapsed',
            width: COLUMN_WIDTH_CM0015[10],
            render: (record) => {
                return handler.isCollapsed(record) ? (
                    <div
                        style={{ cursor: 'pointer' }}
                        onClick={(e: any) => handler.onChangeCollapseRow(record, false)}
                    >
                        <img alt="" src={ExpandedIconSvg} />
                    </div>
                ) : (
                    <div
                        style={{ cursor: 'pointer' }}
                        onClick={(e: any) => handler.onChangeCollapseRow(record, true)}
                    >
                        <img alt="" src={ClosedIconSvg} />
                    </div>
                );
            },
        },
    ];
    return (
        <Table
            onRow={(record, rowIndex) => {
                return {
                    onClick: (event) =>
                        handler.isCollapsed(record)
                            ? handler.onChangeCollapseRow(record, false)
                            : handler.onChangeCollapseRow(record, true), // click row
                };
            }}
            rowClassName={() => {
                return 'button-style';
            }}
            scroll={{ x: 0, y: 500 }}
            columns={columns}
            dataSource={data}
            id="main-table"
            className="cm007-table cm-main-table table-footer scroll-table"
            pagination={false}
            expandable={{
                showExpandColumn: false,
                expandedRowKeys: handler.expandKey,
                expandedRowRender: (record: any) => <CollapsedTableComponent parentData={record} onParentCheckboxChange={handler.onCheckboxChange} />,
            }}
            summary={(pageData) => {
                let budgetAmountTotal = 0;
                let orderAmountTotal = 0;
                let actualCostTotal = 0;
                let budgetBalanceTotal = 0;

                pageData.forEach(({ budgetAmount, orderAmount, actualCost, budgetBalance }) => {
                    budgetAmountTotal += budgetAmount;
                    orderAmountTotal += orderAmount;
                    actualCostTotal += actualCost;
                    budgetBalanceTotal += budgetBalance;
                });

                return (
                    <Table.Summary fixed="bottom">
                        <Table.Summary.Row
                            className="parent-table-summary"
                            style={{ background: '#605BFF' }}
                        >
                            <Table.Summary.Cell index={0} colSpan={5}>
                                合計金額
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} colSpan={0}></Table.Summary.Cell>
                            <Table.Summary.Cell index={2} colSpan={0}></Table.Summary.Cell>
                            <Table.Summary.Cell index={3} colSpan={0}></Table.Summary.Cell>
                            <Table.Summary.Cell index={4} colSpan={0}></Table.Summary.Cell>
                            <Table.Summary.Cell index={5}>
                                <span
                                    className="center-item white-space-nowrap"
                                    style={{ textAlign: 'center' }}
                                >
                                    {`${helpers.formatCurrency(budgetAmountTotal)}円`}
                                </span>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={6}>
                                <span
                                    className="center-item white-space-nowrap"
                                    style={{ textAlign: 'center' }}
                                >
                                    {`${helpers.formatCurrency(actualCostTotal)}円`}
                                </span>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={7}>
                                <span
                                    className="center-item white-space-nowrap"
                                    style={{ textAlign: 'center' }}
                                >
                                    {`${helpers.formatCurrency(budgetBalanceTotal)}円`}
                                </span>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={8}>
                                <span className="center-item white-space-nowrap" style={{ textAlign: 'center' }}>{`${(
                                    actualCostTotal === 0 || budgetAmountTotal === 0 ? 0 : (actualCostTotal / budgetAmountTotal) * 100
                                ).toLocaleString(
                                    'en-US',
                                    {
                                        maximumFractionDigits: 2,
                                    },
                                )}%`}</span>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={9}></Table.Summary.Cell>
                            <Table.Summary.Cell index={10}></Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Table.Summary>
                );
            }}
        />
    );
};

export default MaterialCostUI;
