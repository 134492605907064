import {
    ReSummaryCompanyDocumentOutSourceCostSubmitedList,
    ReSummaryCompanyDocumentOutSourceCostSubmitedListResult,
} from '../entity/Entity';
import { CM0039Api } from './ServiceImpl';

const CM0039Service = (api: CM0039Api) => {
    const getReSummaryCompanyDocumentOutSourceCostSubmitedList = async (params: {
        statusId?: number;
        page?: number;
        size?: number;
        sortBy?: string;
        sortType?: string;
        from?: string;
        to?: string;
        keyword?: string;
    }): Promise<ReSummaryCompanyDocumentOutSourceCostSubmitedList> => {
        try {
            const response = await api.getReSummaryCompanyDocumentOutSourceCostSubmitedList(params);
            return response?.data;
        } catch (error: any) {
            throw error;
        }
    };

    return { getReSummaryCompanyDocumentOutSourceCostSubmitedList };
};

export default CM0039Service;
