import AM001Api from "./infrastructure/api/Api";
import AM001Handler from "./presenter/handler/Handler";
import MainUI from "./presenter/ui/UI";
import AM001Service from "./usecase/Service";

import "../../../assets/styles/AM/AM002.css";
import "../../../assets/styles/OM/OM002.css";
import "../../../assets/styles/OM/OM004.css";
import "../../../assets/styles/AM/AM004.css";
import "../../../assets/styles/AM/AM001.css";
import AttendanceApi from "./infrastructure/api/ApiAttendance";
import AttendanceService from "./usecase/ServiceAttendance";

const AM001Component = () => {
  const api = AM001Api();
  const attendanceApi = AttendanceApi()

  const service = AM001Service(api);
  const attendanceService = AttendanceService(attendanceApi)

  const handler = AM001Handler(service, attendanceService);
  return <MainUI handler={handler} />;
};

export default AM001Component;
