import { Entity } from '../entity/Entity';
import { CM0062Api } from './ServiceImpl';

const CM0062Service = (api: CM0062Api) => {
    const getOffset = async (params: any): Promise<any> => {
        try {
            const response = await api.getOffset(params);
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    const getOffsetProject = async (params: any): Promise<any> => {
        try {
            const response = await api.getOffsetProject(params);
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    return {
        getOffset,
        getOffsetProject,
    };
};

export default CM0062Service;
