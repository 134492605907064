import { Avatar } from "antd"
import { AVATAR_ADMIN } from "../../constants/COMMON"

interface Props {
    size: number,
    value: string,
    avatar?: string
    isAdmin?: boolean
    style?: React.CSSProperties

}

export const DefaultAvatarComponent = ({ size, value, avatar, isAdmin = false, style = {} }: Props) => {

    if (isAdmin)
        return <Avatar style={style} size={size} className="avatar-text">
            {AVATAR_ADMIN}
        </Avatar>
    else if (avatar)

        return <Avatar
            style={style}
            size={size}
            src={avatar}
        />
    else return <Avatar style={style} size={size} className="avatar-text">
        {value}
    </Avatar>
}