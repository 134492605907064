import React from 'react';
import CM042Handler from './presenter/handler/Handler';
import MainUI from './presenter/ui/UI';

import '../../../../assets/styles/CM/cm042.css';
import CM0042Api from './infrastructure/api/Api';
import CM0042Service from './usecase/Service';

const CM042Component = () => {
    const api = CM0042Api();
    const service = CM0042Service(api);
    const handler = CM042Handler(service);
    return <MainUI handler={handler} />;
};

export default CM042Component;
