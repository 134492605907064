import { uuidv4 } from "@firebase/util";
export const convertDataTableExpenditure = (
  data: any,
  dataProject: any,
  dataCostProject: any,
  dataReceivedMoney: any
) => {
  const resultsTableExpenditure: any = [];
  //budget talbe overview
  let materialBudgetOverView = 0;
  let outsourceBudgetOverView = 0;
  let laborBudgetOverView = 0;
  let indrectBudgetOverView = 0;
  //operatingCost table overview

  let materialOperatingCostOverView = 0;
  let outsourceOperatingCostOverView = 0;
  let laborOperatingCostOverView = 0;
  let indrectOperatingCostOverView = 0;

  data.forEach((item: any) => {
    const objParent: any = {
      key: uuidv4(),
      time: item.monthYear,
      item: "全体",
      budget: 0,
      operatingCost: 0,
      amountGAP: 0,
      rateGAP: 0,
      manPower: item.workingDays,
      billingAmount: item.contractorReceiveMoney,
      grossProfit: 0,
      grossProfitMargin: 0,
      children: [] as any,
    };

    const objMaterialCost = {
      key: uuidv4(),
      item: "材料費",
      budget: item?.materialCost?.materialPlanCost,
      operatingCost: item?.materialCost?.materialActualCost,
      amountGAP:
        item?.materialCost?.materialPlanCost -
        item?.materialCost?.materialActualCost,
      rateGAP:
        ((item?.materialCost?.materialPlanCost -
          item?.materialCost?.materialActualCost) *
          100) /
        item?.materialCost?.materialPlanCost,
    };
    const objOutsourceCost = {
      key: uuidv4(),
      item: "外注費",
      budget: item?.outsourceCost?.outsourcePlanCost,
      operatingCost: item?.outsourceCost?.outsourceActualCost,
      amountGAP:
        item?.outsourceCost?.outsourcePlanCost -
        item?.outsourceCost?.outsourceActualCost,
      rateGAP:
        ((item?.outsourceCost?.outsourcePlanCost -
          item?.outsourceCost?.outsourceActualCost) *
          100) /
        item?.outsourceCost?.outsourcePlanCost,
    };

    const objLaborCost = {
      key: uuidv4(),
      item: "労務費",
      budget: item?.laborCost?.laborPlanCost,
      operatingCost: item?.laborCost?.laborActualCost,
      amountGAP:
        item?.laborCost?.laborPlanCost - item?.laborCost?.laborActualCost,
      rateGAP:
        ((item?.laborCost?.laborPlanCost - item?.laborCost?.laborActualCost) *
          100) /
        item?.laborCost?.laborPlanCost,
    };

    const objIndirectCost = {
      key: uuidv4(),
      item: "間接工事費",
      budget: item?.indrectCost?.indirectPlanCost,
      operatingCost: item?.indrectCost?.indirectActualCost,
      amountGAP:
        item?.indrectCost?.indirectPlanCost -
        item?.indrectCost?.indirectActualCost,
      rateGAP:
        ((item?.indrectCost?.indirectPlanCost -
          item?.indrectCost?.indirectActualCost) *
          100) /
        item?.indrectCost?.indirectPlanCost,
    };
    objParent.children = [
      objMaterialCost,
      objOutsourceCost,
      objLaborCost,
      objIndirectCost,
    ];

    objParent.budget =
      objMaterialCost.budget +
      objOutsourceCost.budget +
      objLaborCost.budget +
      objIndirectCost.budget;
    objParent.operatingCost =
      objMaterialCost.operatingCost +
      objOutsourceCost.operatingCost +
      objLaborCost.operatingCost +
      objIndirectCost.operatingCost;
    objParent.amountGAP = objParent.budget - objParent.operatingCost;
    objParent.rateGAP = (objParent.amountGAP * 100) / objParent.budget;
    objParent.grossProfit = objParent.billingAmount - objParent.operatingCost;
    objParent.grossProfitMargin =
      (objParent.grossProfit * 100) / objParent.billingAmount;

    resultsTableExpenditure.push(objParent);

    materialBudgetOverView += item?.materialCost?.materialPlanCost;
    outsourceBudgetOverView += item?.outsourceCost?.outsourcePlanCost;
    laborBudgetOverView += item?.laborCost?.laborPlanCost;
    indrectBudgetOverView += item?.indrectCost?.indirectPlanCost;

    materialOperatingCostOverView += item?.materialCost?.materialActualCost;
    outsourceOperatingCostOverView += item?.outsourceCost?.outsourceActualCost;
    laborOperatingCostOverView += item?.laborCost?.laborActualCost;
    indrectOperatingCostOverView += item?.indrectCost?.indirectActualCost;
  });

  let totalBudgetExpenditure = resultsTableExpenditure.reduce(
    (total: number, item: any) => {
      return (total += item?.budget);
    },
    0
  );

  let totalOperatingCostExpenditure = resultsTableExpenditure.reduce(
    (total: number, item: any) => {
      return (total += item?.operatingCost);
    },
    0
  );

  let totalManPowerExpenditure = resultsTableExpenditure.reduce(
    (total: number, item: any) => {
      return (total += item?.manPower);
    },
    0
  );

  let totalBillingAmountExpenditure = resultsTableExpenditure.reduce(
    (total: number, item: any) => {
      return (total += item?.billingAmount);
    },
    0
  );
  let totalGrossProfitExpenditure = resultsTableExpenditure.reduce(
    (total: number, item: any) => {
      return (total += item?.grossProfit);
    },
    0
  );

  const objTotal: any = {
    totalBudgetExpenditure,
    totalOperatingCostExpenditure,
    totalManPowerExpenditure,
    totalBillingAmountExpenditure,
    totalGrossProfitExpenditure,
    totalGrossProfitMarginExpenditure:
      (totalGrossProfitExpenditure * 100) / totalBillingAmountExpenditure,
  };
  //DATA TABLE OVERVIEW
  const objMaterialCost: any = {
    key: uuidv4(),
    item: "材料費",
    budget: dataCostProject?.materialCost?.planCost,
    operatingCost: materialOperatingCostOverView,
    rateBudget:
      (dataCostProject?.materialCost?.planCost * 100) /
      dataProject.contractCost,
    rateUseBudget:
      (materialOperatingCostOverView * 100) /
      dataCostProject?.materialCost?.planCost,
  };
  const objOutsourceCost = {
    key: uuidv4(),
    item: "外注費",
    budget: dataCostProject?.outsourceCost?.planCost,
    operatingCost: outsourceOperatingCostOverView,
    rateBudget:
      (dataCostProject?.outsourceCost?.planCost * 100) /
      dataProject.contractCost,
    rateUseBudget:
      (outsourceOperatingCostOverView * 100) /
      dataCostProject?.outsourceCost?.planCost,
  };

  const objLaborCost = {
    key: uuidv4(),
    item: "労務費",
    budget: dataCostProject?.laborCost?.planCost,
    operatingCost: laborOperatingCostOverView,
    rateBudget:
      (dataCostProject?.laborCost?.planCost * 100) / dataProject.contractCost,
    rateUseBudget:
      (laborOperatingCostOverView * 100) / dataCostProject?.laborCost?.planCost,
  };

  const objIndirectCost = {
    key: uuidv4(),
    item: "間接工事費",
    budget: dataCostProject?.indirectCost?.planCost,
    operatingCost: indrectOperatingCostOverView,
    rateBudget:
      (dataCostProject?.indirectCost?.planCost * 100) /
      dataProject.contractCost,
    rateUseBudget:
      (indrectOperatingCostOverView * 100) /
      dataCostProject?.indirectCost?.planCost,
  };
  const resultTableOverview = [
    objMaterialCost,
    objOutsourceCost,
    objLaborCost,
    objIndirectCost,
  ];

  let totalBudgetOverview = resultTableOverview.reduce(
    (total: number, item: any) => {
      return (total += item?.budget);
    },
    0
  );

  let totalOperatingCostOverview = resultTableOverview.reduce(
    (total: number, item: any) => {
      return (total += item?.operatingCost);
    },
    0
  );

  const objMainOverview: any = {
    key: uuidv4(),
    item: "全体",
    contractCost: dataProject.contractCost,
    budget: totalBudgetOverview,
    operatingCost: totalOperatingCostOverview,
    rateBudget: (totalBudgetOverview * 100) / dataProject.contractCost,
    rateUseBudget: (totalOperatingCostOverview * 100) / totalBudgetOverview,
    manPower: totalManPowerExpenditure,
    depositAmount: dataReceivedMoney.billingVolume,
    completionVolume:
      (dataReceivedMoney.billingVolume * 100) / dataProject.contractCost,
    grossProfit: totalGrossProfitExpenditure,
    grossProfitMargin:
      (totalGrossProfitExpenditure * 100) / dataReceivedMoney.billingVolume,
  };
  resultTableOverview.unshift(objMainOverview);

  const { contractCost, budget, depositAmount, operatingCost } =
    objMainOverview;

  /*Cột 予算:
    予算 = 契約金額 (lấy ở trường 契約金額 hàng 全体) ー予算 (lấy ở trường 予算 hàng 全体)*/
  const budgetOverview = contractCost - budget;

  // 稼働原価 = 入金額 ー 稼働原価(lấy ở trường 稼働原価 hàng 全体)
  const operatingCostOverview = depositAmount - operatingCost;

  /*Cột 予算(％):
    予算(％) = ((契約金額 (lấy ở trường 契約金額 hàng 全体) ー予算 (lấy ở trường 予算 hàng 全体)) / 契約金額 (lấy ở trường 契約金額 hàng 全体))*100 */
  const rateBudgetValueOverview = budgetOverview / contractCost * 100;

  const objTotalOverview: any = {
    key: uuidv4(),
    item: "粗利",
    budget: budgetOverview,
    rateBudget: rateBudgetValueOverview,
    operatingCost: operatingCostOverview,
  };
  resultTableOverview.push(objTotalOverview);
  return {
    resultsTableExpenditure,
    totalTableExpenditure: objTotal,
    resultTableOverview,
  };
};
