const CustomizedDotLineChart = (props: {
  cx: number;
  cy: number;
  size: number;
  onMouseMove: (data: any) => void;
  onMouseOut: (data: any) => void;
}) => {
  const { cx, cy, size, onMouseMove, onMouseOut } = props;
  return (
    <svg
      onMouseMove={onMouseMove}
      onMouseOut={onMouseOut}
      x={cx - size / 2}
      y={cy - size / 2}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      cursor="pointer"
    >
      <rect
        width={size}
        height={size}
        rx={size / 2}
        fill="url(#paint0_linear_34702_10035)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_34702_10035"
          x1={size}
          y1={size}
          x2="0"
          y2={size}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC53D" />
          <stop offset="1" stopColor="#EA6B38" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CustomizedDotLineChart;
