import { UserIsReWorkflow } from "../entity/Entity";
import { APIImpl } from "./ServiceImpl";

export const Service = (dataSourceAPI: APIImpl) => {
  const getUserIsReWorkflow = async (): Promise<UserIsReWorkflow> => {
    return await dataSourceAPI.getUserIsReWorkflow();
  };
  const getUserIsDoubleCheck = async (): Promise<boolean> => {
    return await dataSourceAPI.getUserIsDoubleCheck();
  };

  const getProjectCheckUserInWorkspace = async (): Promise<boolean> => {
    return await dataSourceAPI.getProjectCheckUserInWorkspace();
  };
  const getUserIsProjectManager = async (): Promise<boolean> => {
    return await dataSourceAPI.getUserIsProjectManager();
  };
  const getWorkFlowUserIsInWorkFlows = async (): Promise<boolean> => {
    return await dataSourceAPI.getWorkFlowUserIsInWorkFlows();
  };
  const getIsVerifiedCompany = async (): Promise<boolean> => {
    return await dataSourceAPI.getIsVerifiedCompany();
  };
  return {
    getUserIsReWorkflow,
    getUserIsDoubleCheck,
    getProjectCheckUserInWorkspace,
    getUserIsProjectManager,
    getWorkFlowUserIsInWorkFlows,
    getIsVerifiedCompany
  };
};
