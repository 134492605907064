// Params

export const SWITCH_TAB_KEY = 'TAB';
export const SWITCH_TAB_ONE = 'TAB_ONE';
export const SWITCH_TAB_TWO = 'TAB_TWO';
export const GROUP_ROLE_ID = 'groupRoleId';
export const GROUP_ROLE_NAME = 'groupRoleName';
export const PAGE = 'page';
export const KEYWORD_PARAM = 'keyword';

// Columns
export const COLUMN_TABLE_ONE = ['No', '機能', '', '追加・編集', ''];
export const COLUMN_TABLE_TWO = ['No', '機能', '閲覧', '作成', ''];
export const COLUMN_TABLE_THREE = ['No', '機能', '閲覧', '追加・編集', ''];
export const COLUMN_TABLE_FOUR = ['No', '機能', '閲覧', '', ''];
export const COLUMN_TABLE_FIVE = ['No', '機能', '閲覧', '追加・編集', '提出'];
export const COLUMN_TABLE_SIX = ['No', '機能', '閲覧', '出力', ''];

// Key
export const PERMISSION_PROJECT_KEY = 'project_permission';
export const PERMISSION_SYSTEM_KEY = 'system_permission';
