import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { NOTIFICATION_TITLE } from '../../../../../common/constants/MESSAGE';
import { CM0062UseCase } from '../../usecase/ServiceImpl';
import { resetCM0062 } from '../slice/Slice';
import ErrorNotification from '../../../../../common/components/notification/ErrorNotification';
import helpers from '../../../../../common/helpers/common';

const CM0062Handler = (CM0062Service: CM0062UseCase) => {
    // LIB FUNCTION
    const dispatch = useDispatch();
    const [data, setData] = useState<any[]>([]);

    const onCancel = () => {
        dispatch(resetCM0062());
    };

    const getOffsetData = async (
        documentId: number,
        categoryId: number,
        projectName: string,
        option: boolean,
    ) => {
        try {
            const data: any[] = [];

            let output: any[] = [];

            if (option) {
                const resp = await CM0062Service.getOffset({
                    documentReceiveMoneyId: documentId,
                    categoryId: categoryId,
                });

                output = resp.results;
            } else {
                const resp = await CM0062Service.getOffsetProject({
                    documentReceiveMoneyId: documentId,
                    categoryId: categoryId,
                });

                output = resp.results;
            }

            if (output.length > 0) {
                output.forEach((item: any, idx: number) => {
                    const obj: any = {
                        key: Math.random(),
                        order: idx + 1,
                        item: item?.name ?? 'wait data from api',
                        taxCategoryComsumption: item?.category?.description ?? '',
                        offsetAmount: item?.value ?? 0,
                    };

                    data.push(obj);
                });
            }

            setData(data);
        } catch (error: any) {
            ErrorNotification(error?.message, NOTIFICATION_TITLE.ERROR);
        }
    };

    return {
        data,

        onCancel,
        getOffsetData,
    };
};

export default CM0062Handler;
