import { useSearchParams } from "react-router-dom";
import PaginationComponentOM from "../../../../../common/components/pagination/PaginationComponentOM";
import { OM002Hanlder, OM002Pagination } from "../handler/HandlerImpl";
type Props = {
    handler: OM002Pagination
}
const OM002PaginationComponent = ({ handler }: Props) => {
    const [searchParams] = useSearchParams();

    const page = searchParams.get("page");
    const size = searchParams.get("size");

    return handler.total !== 0 ? <div className="om002-pagination">
        <PaginationComponentOM
            total={handler.total}
            page={parseInt(page || "1")}
            size={parseInt(size || "10")}
            setPage={handler.onPageChange}
            setSize={handler.onSizeChange}
            sizeLabelPage="件"
            sizeLabelSize=" ページ"
            rangeLabel="件"
        />   </div> : <></>


}

export default OM002PaginationComponent