import CM0048Api from './infrastructure/api/Api';
import CM0048Handler from './presenter/handler/Handler';
import CM0048Service from './usecase/Service';
import MainUI from './presenter/ui/UI';

import '../../../../assets/styles/CM/cm047.css';

const CM048Component = () => {
    const api = CM0048Api();
    const service = CM0048Service(api);
    const handler = CM0048Handler(service);
    return <MainUI handler={handler} />;
};

export default CM048Component;
