import axios, { AxiosResponse } from "axios";
import axiosConfig from "../../../../../config/axiosConfig";
import { MoneySubmitReceiveMoneyPayload } from "../../../CM004/entity/Entity";
/*
    Hiển thị: 提出
    Điều kiện enable button: Tồn tại record ở mục #8.3 có trạng thái 未承認
    Click button sẽ submit thông tin #8.3 thành 1 file tài liệu để gửi lên cấp trên duyệt
    Click button sẽ hiển thị popup confirm, thực hiện submit sẽ hiển thị message submit thành công giống màn CR001
    Tài liệu được lưu ở màn CM0034
    Sau khi submit thì chuyển trạng thái của record chưa submit ở mục #8.3 từ 未承認 sang 承認待ち
    Tên file submit đặt theo format: Tên màn_Tên tab_năm_tháng_ngày submit                                                                               "											
    */
const HistoryPaymentApi = () => {
  const deleteMoneyReceivedMoneyDelete = async (
    data: any
  ): Promise<AxiosResponse<any, any>> => {
    // tested
    const url = "/money/received-money/delete";
    return await axiosConfig.delete(url, { data });
  };
  const postMoneyReceivedMoneyCreate = async (
    data: any
  ): Promise<AxiosResponse<any, any>> => {
    const url = "/money/received-money/create";
    return await axiosConfig.post(url, data);
  }; // 500
  const putMoneyReceivedMoneyEdit = async (
    data: any
  ): Promise<AxiosResponse<any, any>> => {
    const url = "/money/received-money/edit";
    return await axiosConfig.put(url, data);
  };
  const putMoneySubmitReceiveMoney = async (
    data: MoneySubmitReceiveMoneyPayload
  ): Promise<AxiosResponse<any, any>> => {
    const url = "/money/submit/receive-money";
    return await axiosConfig.put(url, data);
  };
  const getMoneyReceivedMoneyList = async (params: {
    constructionId: number;
    page?: number;
    size?: number;
    sortBy?: string;
    sortType?: string;
    from?: string;
    to?: string;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/money/received-money/list";
    return await axiosConfig.get(url, { params });
  };
  const getMoneyReceivedMoneyOffsetList = async (params: {
    receiveMoneyId: number;
    page?: number;
    size?: number;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/money/received-money-offset/list";
    return await axiosConfig.get(url, { params });
  };
  const getLinkPresignUpload = async (
    params: any
  ): Promise<AxiosResponse<any, any>> => {
    const url = "/project/presign-upload";
    return await axiosConfig.get(url, { params });
  };
  const uploadToS3 = async (
    url: any,
    data: any
  ): Promise<AxiosResponse<any, any>> => {
    return await axios.put(url, data, {
      headers: {
        "Content-Type": data.type,
      },
    });
  };
  const getPresignLinkS3 = async (
    params: any
  ): Promise<AxiosResponse<any, any>> => {
    const url = "/project/presign-link";
    return await axiosConfig.get(url, { params });
  };

  const doExportCM005 = async (params: any): Promise<any> => {
      try {
          const url = '/money/exportCM005';
          const response = await axiosConfig.get(url, { params, responseType: 'blob'  });
          
          return response;
      } catch (error) {
          throw error;
      }
  };

  return {
    deleteMoneyReceivedMoneyDelete,
    postMoneyReceivedMoneyCreate,
    putMoneyReceivedMoneyEdit,
    getMoneyReceivedMoneyList,
    putMoneySubmitReceiveMoney,
    getLinkPresignUpload,
    uploadToS3,
    getPresignLinkS3,
    getMoneyReceivedMoneyOffsetList,
    doExportCM005,
  };
};

export default HistoryPaymentApi;
