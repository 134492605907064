import React from 'react';
import { Button, Modal, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { hideH1Modal } from './H1ModalSlice';
import type { ColumnsType } from 'antd/es/table';
import { ROWS, convertToDisplayTable } from './helpers';
import helpers from '../../helpers/common';

import XIconModal from '../../../assets/icons/x-icon-modal.svg';
import SwooshIcon from '../../../assets/icons/swoosh-purple.svg';
import '../../../assets/styles/components/h4-modal.css';
import '../../../assets/styles/components/h1-modal.css';

export const COLUMN_WIDTH_H1_MODAL: any[] = ['23.5%', '15.3%'];

const H1Modal = () => {
    const dispatch = useDispatch();
    const isOpenH1Modal = useSelector((state: RootState) => state.h1Modal.isOpenH1);

    const handleCancel = () => {
        dispatch(hideH1Modal());
    };

    const getDisplayContent = (value: boolean) => {
        return value ? <img src={SwooshIcon} alt="" /> : '-';
    };

    const columns: ColumnsType<any> = [
        {
            title: '機能',
            key: '機能',
            width: COLUMN_WIDTH_H1_MODAL[0],
            align: 'center',
            render: (record) => {
                const classNameRecord = record.isHead ? 'header-row-h1-modal' : 'h1-row-cell';
                return (
                    <span
                        className={` ${classNameRecord} white-space-nowrap header-style-row`}
                        style={{ width: '100%', textAlign: 'right' }}
                    >
                        {record.name}
                    </span>
                );
            },
            onCell: (record) => ({
                colSpan: record.isHead ? 6 : 1,
            }),
        },
        {
            title: '案件担当者',
            key: '案件担当者',
            width: COLUMN_WIDTH_H1_MODAL[1],
            align: 'center',
            render: (record) => getDisplayContent(record.allowProjectManager),
            onCell: (record) => ({
                colSpan: record.isHead ? 0 : 1,
            }),
        },
        {
            title: '副案件担当者',
            key: '副案件担当者',
            width: COLUMN_WIDTH_H1_MODAL[1],
            align: 'center',
            render: (record) => getDisplayContent(record.allowDeputyProjectManager),
            onCell: (record) => ({
                colSpan: record.isHead ? 0 : 1,
            }),
        },
        {
            title: '案件作成者',
            key: '案件作成者',
            width: COLUMN_WIDTH_H1_MODAL[1],
            align: 'center',
            render: (record) => getDisplayContent(record.allowItemCreator),
            onCell: (record) => ({
                colSpan: record.isHead ? 0 : 1,
            }),
        },
        {
            title: '案件メンバー',
            key: '案件メンバー',
            width: COLUMN_WIDTH_H1_MODAL[1],
            align: 'center',
            render: (record) => getDisplayContent(record.allowProjectMember),
            onCell: (record) => ({
                colSpan: record.isHead ? 0 : 1,
            }),
        },
        {
            title: 'アドミン',
            key: 'アドミン',
            width: COLUMN_WIDTH_H1_MODAL[1],
            align: 'center',
            render: (record) => getDisplayContent(record.isAdmin),
            onCell: (record) => ({
                colSpan: record.isHead ? 0 : 1,
            }),
        },
    ];

    return (
        <Modal
            className="group-detail-modal h1-modal h1-modal-white-header"
            style={{ marginLeft: 300, marginTop: 40, marginBottom: 40 }}
            visible={isOpenH1Modal}
            closable={false}
            maskClosable={false}
            width={'56.2%'}
            centered={true}
            footer={
                <Button size="large" style={{ marginLeft: 16, width: 118 }} onClick={handleCancel}>
                    キャンセル
                </Button>
            }
        >
            <div className="w-100-percent header-modal-containter" style={{ height: 50 }}>
                <div className="header-modal-title-om004 ">固定権限一覧</div>
                <div style={{ cursor: 'pointer' }} onClick={handleCancel}>
                    <img alt="" src={XIconModal} style={{ height: 18, width: 18 }} />
                </div>
            </div>
            <div style={{ marginTop: 24, marginBottom: 24 }}>
                <Table
                    bordered={true}
                    dataSource={helpers.addKeyIndexToDataSource(convertToDisplayTable(ROWS))}
                    title={() => <main className="cm005-title-header">案件管理</main>}
                    columns={columns}
                    id="main-table"
                    rowClassName={(record) =>
                        record.isHead ? 'header-style-row' : 'normal-style-row'
                    }
                    pagination={false}
                />
            </div>
        </Modal>
    );
};

export default H1Modal;
