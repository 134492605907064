import COMMON, {
  DEFAULT_PAGINATION_RESULT,
  STATUS_PROJECT_CODE,
} from "../../../../common/constants/COMMON";
import ErrorNotification from "../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../common/constants/MESSAGE";
import { PaginationResult } from "../../../../common/entity/Entity";
import { FilterAPIImpl } from "./ServiceImpl";

const FilterService = (api: FilterAPIImpl) => {
  const getAttendanceProjectConstruction = async (params: {
    projectId: number;
    keyword: string;
    page: string;
    size: string;
  }): Promise<PaginationResult> => {
    try {
      const response = await api.getAttendanceProjectConstruction(params);
      return response.data || DEFAULT_PAGINATION_RESULT;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return DEFAULT_PAGINATION_RESULT;
    }
  };
  const getProjectAlls = async (params: {
    page: number;
    size: number;
    sortBy?: string;
    sortType?: string;
    keyword?: string;
  }): Promise<PaginationResult> => {
    try {
      const response = await api.getProjectAlls(params);
      return response.data || DEFAULT_PAGINATION_RESULT;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return DEFAULT_PAGINATION_RESULT;
    }
  };

  const getUnderConstructionId = async (): Promise<number> => {
    try {
      const response = await api.getProjectStatus({
        page: COMMON.DEFAULT_PAGE,
        size: COMMON.DEFAULT_SIZE,
      });
      const results = response?.data?.results ?? [];
      const underConstruction = results.find(
        (element: any) =>
          element.code === STATUS_PROJECT_CODE.UNDER_CONSTRUCTION
      );
      return underConstruction?.id ?? 0;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return 0;
    }
  };

  return {
    getAttendanceProjectConstruction,
    getProjectAlls,
    getUnderConstructionId,
  };
};

export default FilterService;
