import { AM014SliceType } from "./../type/Presenter";
import { createSlice } from "@reduxjs/toolkit";

const initialState: AM014SliceType = {
  loading: false,
  isVisible: false,
  refresh: false,
  requestId: undefined,
};

export const am014Slice = createSlice({
  name: "am014",
  initialState,
  reducers: {
    resetAM014: () => initialState,
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsVisibleAM014: (state, action) => {
      state.isVisible = action.payload;
    },
    setRefreshAM014: (state) => {
      state.refresh = !state.refresh;
    },
    setRequestIdAM014: (state, action) =>{
      state.requestId = action.payload;
    }
  },
});

export const {
  setLoading,
  setIsVisibleAM014,
  resetAM014,
  setRefreshAM014,
  setRequestIdAM014
} = am014Slice.actions;

export default am014Slice.reducer;
