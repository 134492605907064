import { createSlice } from "@reduxjs/toolkit";
import { CI001SliceEntity } from "../type/Presenter";

const initialState: CI001SliceEntity = {
  data: [],
  loading: false,
  currentPage: 1,
  pageSize: 0,
};

export const ci001Slice = createSlice({
  name: "ci001",
  initialState,
  reducers: {
    setBasicInformation: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setBasicInformation } = ci001Slice.actions;

export default ci001Slice.reducer;
