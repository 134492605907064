import AM018Api from "./infrastructure/api/Api";
import AM018Handler from "./presenter/handler/Handler";
import AM018UI from "./presenter/ui/UI";
import AM018Service from "./usecase/Service";

const AM018Component = () => {
  const am018Api = AM018Api();
  const am018Service = AM018Service(am018Api);
  const am018Handler = AM018Handler(am018Service);
  return <AM018UI handler={am018Handler} />;
};

export default AM018Component;
