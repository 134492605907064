export const COLOR_STATUS_REQUEST = {
  WAITING_APPROVE: "#FFEEEE",
  APPROVED: "#33CC991A 10%",
  REJECT: "#FF98611A 10%",
  NORMAL: "rgba(96, 91, 255, 0.1)",

  WAITING_APPROVE_BORDER: "#FF9861",
  APPROVED_BORDER: "#33CC99",
  REJECT_BORDER: "#FF5555",
  NORMAL_BORDER: "#605BFF",
};

export const LEVEL_TREE_COLOR: any = {
  LEVEL1: {
    color: "#418FEB",
    padding: 0,
    background: "rgba(65, 143, 235, 0.12)",
  },
  LEVEL2: {
    color: "#33CC99",
    padding: 32,
    background: "rgba(51, 204, 153, 0.12)",
  },
  LEVEL3: {
    color: "#FF9861",
    padding: 32 * 2,
    background: "rgba(255, 152, 97, 0.12)",
  },
  LEVEL4: {
    color: "#FF5555",
    padding: 32 * 3,
    background: "rgba(255, 85, 85, 0.12)",
  },
  LEVEL5: {
    color: "#33CC99",
    padding: 32 * 4,
    background: "rgba(51, 204, 153, 0.12)",
  },
};
