import ErrorNotification from "../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../common/constants/MESSAGE";
import { WorkflowResponseData } from "../../CM008/entity/Entity";
import { RetrieveReceiveMoney } from "../entity/Entity";
import { CM006Api } from "./ServiceImpl";

const CM006Service = (service: CM006Api) => {
  const getListSubmittedReceiveMoney = async (params: any): Promise<any> => {
    try {
      return await service.getListSubmittedReceiveMoney(params);
    } catch (error) {
      throw error;
    }
  };

  const getConstructionInformation = async (params: any): Promise<any> => {
    try {
      return await service.getConstructionInformation(params);
    } catch (error) {
      throw error;
    }
  };

  const getListConstructionReceivedMoney = async (
    params: any
  ): Promise<any> => {
    try {
      return await service.getListConstructionReceivedMoney(params);
    } catch (error) {
      throw error;
    }
  };

  const retrieveReceiveMoney = async (
    data: RetrieveReceiveMoney
  ): Promise<any> => {
    try {
      return await service.retrieveReceiveMoney(data);
    } catch (error) {
      throw error;
    }
  };

  const getPresignLinkS3 = async (params: any): Promise<any> => {
    try {
      const response = await service.getPresignLinkS3(params);
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      throw error;
    }
  };
  const getWorkflowReceiveMoneyApprovalProcess = async (params: {
    documentId: number;
  }): Promise<WorkflowResponseData> => {
    try {
      const response = await service.getWorkflowReceiveMoneyApprovalProcess(
        params
      );
      return response.data;
    } catch (error: any) {
      throw error;
    }
  };
  return {
    getListSubmittedReceiveMoney,
    getConstructionInformation,
    getListConstructionReceivedMoney,
    retrieveReceiveMoney,
    getPresignLinkS3,
    getWorkflowReceiveMoneyApprovalProcess,
  };
};

export default CM006Service;
