import ErrorNotification from '../../../../../common/components/notification/ErrorNotification';
import { NOTIFICATION_TITLE } from '../../../../../common/constants/MESSAGE';
import { api } from './ServiceImpl';

const service = (api: api) => {
    const getDocumentStatus = async (): Promise<any> => {
        try {
            const response = await api.getDocumentStatus();
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return;
        }
    };

    return {
        getDocumentStatus,
    };
};

export default service;
