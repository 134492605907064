import React, { useEffect, useState } from 'react';
import { DatePicker, Form, FormInstance, Input, InputNumber, Select, Tooltip } from 'antd';

import IconMinus from '../../../../../../assets/icons/minus-purple.svg';
import IconCalendar from '../../../../../../assets/icons/icon-calendar-ord.svg';
import IconDown from '../../../../../../assets/icons/arrow-down-2-plain.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import MESSAGE from '../../../../../../common/constants/MESSAGE';
import { disabledEndDate, disabledStartDate } from '../../../helper';
import COMMON, { ORDER_STATUS } from '../../../../../../common/constants/COMMON';
import helpers from '../../../../../../common/helpers/common';
import { HandlerImpl } from './Handler';
import { OrderStatus, PlanSettingDetail } from '../../../entity/Entity';
import locale from 'antd/es/date-picker/locale/ja_JP';
import moment from 'moment';
import { PLAN_BASIC_SUBSCRIPTION } from '../../../../../../common/constants/SUBSCRIPTION';

const { Item } = Form;
const { Option } = Select;

interface props {
    handler: HandlerImpl;
    form: FormInstance;
    planDetail?: PlanSettingDetail;
    statusOrder: OrderStatus[];
    formName: string;
    data?: any;
    deleteAPlanFunction(id: string, name: string): void;
    getChosenPlanFunctionOnFormChange: () => void
}

export const MainUI = ({
    deleteAPlanFunction,
    getChosenPlanFunctionOnFormChange,
    handler,
    planDetail,
    form,
    statusOrder,
    formName,
    data,
}: props) => {
    const {
        onChangeDiscount,
        setSubscriptionState,
        onChangeSubscription,
        setExistedData,
        checkDisabledPlanSubscription,
        currentCost,
        currentTotalMAmount,
        subscriptionState,
        disabledOptions
    } = handler;

    const chosenPlanFunction = useSelector((state: RootState) => state.ord002_1.chosenPlanFunction);

    useEffect(() => {
        if (data) setExistedData(data);
        else form.setFieldValue([`${formName}`, `statusId`], ORDER_STATUS.ACTIVE_ORDER);
    }, [data]);

    useEffect(() => {
        setSubscriptionState(planDetail?.basicPlan?.subscription ?? []);
    }, [JSON.stringify(planDetail?.basicPlan?.subscription)]);

    useEffect(() => {
        form.setFieldValue([`${formName}`, `currentCost`], currentCost);
    }, [currentCost]);

    useEffect(() => {
        checkDisabledPlanSubscription(subscriptionState, chosenPlanFunction)
    }, [JSON.stringify(subscriptionState), JSON.stringify(chosenPlanFunction)]);

    const changeStatusWhenEndDateChange = (endDate: any) => {
        if (endDate && endDate.endOf('day').diff(moment().endOf('day')) >= 0)
            form.setFieldValue([`${formName}`, 'statusId'], ORDER_STATUS.ACTIVE_ORDER);
        if (endDate && endDate.endOf('day').diff(moment().endOf('day')) < 0)
            form.setFieldValue([`${formName}`, 'statusId'], ORDER_STATUS.INACTIVE_ORDER);
    };

    const checkStartDateAndEndDate = (startDate: any, endDate: any, value: any) => {
        if (!startDate && !endDate) return Promise.resolve();
        else if (startDate && endDate) {
            if (startDate.startOf('day').diff(value) <= 0 && endDate.endOf('day').diff(value) >= 0)
                return Promise.resolve();
        } else if (startDate) {
            if (startDate.startOf('day').diff(value) <= 0) return Promise.resolve();
        } else if (endDate) {
            if (endDate.endOf('day').diff(value) >= 0) return Promise.resolve();
        }
        return Promise.reject(new Error(MESSAGE.MESSAGE_81));
    };

    return (
        <div className="ord-add-item">
            <div className="ord-add-row">
                <div className="ord-add-label label-minus">
                    <img
                        src={IconMinus}
                        alt=""
                        onClick={() => {
                            deleteAPlanFunction(formName, '');
                        }}
                    />
                    <span>
                        機能名 <span className="label-required">*</span>
                    </span>
                </div>
                <div className="ord-add-input">
                    <Item
                        name={[`${formName}`, `id`]}
                        rules={[

                            () => ({
                                validator(_, value) {
                                    if (!value) return Promise.reject(
                                        new Error(MESSAGE.MESSAGE_REQUIRED)
                                    );

                                    const { M17, M18, M19, B6 } = PLAN_BASIC_SUBSCRIPTION;
                                    let foundM17: any, foundM18: any, foundM19: any, foundB6: any
                                    subscriptionState.forEach(element => {
                                        if (element.code === M17) foundM17 = element
                                        else if (element.code === M18) foundM18 = element
                                        else if (element.code === M19) foundM19 = element
                                        else if (element.code === B6) foundB6 = element
                                    })

                                    const isM17Chosen = chosenPlanFunction.some((element) => element === foundM17.id)
                                    if (!isM17Chosen) {
                                        if (value === foundM18.id) return Promise.reject(
                                            new Error(MESSAGE.MESSAGE_ORD_2_1_ERROR_1)
                                        );

                                        if (value === foundM19.id) return Promise.reject(
                                            new Error(MESSAGE.MESSAGE_ORD_2_1_ERROR_2)
                                        );

                                        if (value === foundB6.id) return Promise.reject(
                                            new Error(MESSAGE.MESSAGE_ORD_2_1_ERROR_3)
                                        );
                                    }

                                    const isM18Chosen = chosenPlanFunction.some((element) => element === foundM18.id)
                                    const isM19Chosen = chosenPlanFunction.some((element) => element === foundM19.id)

                                    if ((!isM18Chosen || !isM19Chosen) && value === foundB6.id) return Promise.reject(
                                        new Error(MESSAGE.MESSAGE_ORD_2_1_ERROR_3)
                                    );


                                    return Promise.resolve();
                                },
                            }),
                        ]}
                    >
                        <Select
                            size="large"
                            suffixIcon={<img alt="" src={IconDown} />}
                            getPopupContainer={(trigger: any) => trigger.parentNode}
                            onChange={(value: any, option: any) => {
                                getChosenPlanFunctionOnFormChange()
                                onChangeSubscription(option, value, form, formName)
                            }}
                        >
                            {subscriptionState?.map((item) => {
                                if (chosenPlanFunction?.includes(item.id)) return <Option
                                    key={item.id}
                                    value={item.id}
                                    disabled={true}
                                >
                                    {item.name}
                                </Option>

                                const isDisabled =
                                    (disabledOptions.hasOwnProperty(`${item.code}`) && disabledOptions[`${item.code}`])

                                const { M17, M18, M19, B6 } = PLAN_BASIC_SUBSCRIPTION

                                if (isDisabled) {
                                    if ([M18, M19].includes(item.code)) return <Option
                                        key={item.id}
                                        value={item.id}
                                        disabled={isDisabled}
                                    >
                                        <Tooltip

                                            placement='bottom'
                                            overlayInnerStyle={{
                                                width: 370,
                                            }}
                                            title={<div style={{ width: 'fit-content' }}>

                                                「{item.name}」機能を登録するため、<br />
                                                「案件別収支管理」機能を登録する必要があります。
                                            </div>} >
                                            <div style={{ width: '100%' }}>{item.name}</div>
                                        </Tooltip>
                                    </Option>

                                    else if ([B6].includes(item.code)) return <Option
                                        key={item.id}
                                        value={item.id}
                                        disabled={isDisabled}
                                    >
                                        <Tooltip
                                            placement='bottom'

                                            overlayInnerStyle={{
                                                width: 450,
                                            }}
                                            title={<div style={{ width: 'fit-content' }}>

                                                「ダッシュボード」機能を登録するため、「案件別収支管理」、<br />
                                                「工事代金明細書」、「社内収支」機能を登録する必要があります。
                                            </div>} >
                                            <div style={{ width: '100%' }}>{item.name}</div>
                                        </Tooltip>
                                    </Option>
                                }

                                return <Option
                                    key={item.id}
                                    value={item.id}
                                    disabled={isDisabled}
                                >
                                    {item.name}
                                </Option>
                            })}
                        </Select>
                    </Item>
                </div>
            </div>
            <div className="ord-add-row row-child">
                <div className="ord-add-row-left">
                    <div className="ord-add-label">
                        開始日 <span className="label-required">*</span>
                    </div>
                    <div className="ord-add-input">
                        <Item
                            name={[`${formName}`, `startDate`]}
                            rules={[
                                {
                                    required: true,
                                    message: MESSAGE.MESSAGE_REQUIRED,
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        const startDate = getFieldValue('startDate');
                                        const endDate = getFieldValue('endDate');
                                        return checkStartDateAndEndDate(startDate, endDate, value);
                                    },
                                }),
                            ]}
                        >
                            <DatePicker
                                size="large"
                                className="ord-date-picker"
                                placeholder=""
                                locale={locale}
                                inputReadOnly
                                format={COMMON.FORMAT_DATE_CM}
                                suffixIcon={<img alt="" src={IconCalendar} />}
                                getPopupContainer={(trigger: any) => trigger.parentNode}
                                disabledDate={(current) =>
                                    disabledStartDate(
                                        current,
                                        form.getFieldValue([`${formName}`, `endDate`]),
                                    )
                                }
                            />
                        </Item>
                    </div>
                </div>
                <div className="ord-add-row-right">
                    <div className="ord-add-label">
                        有効期限 <span className="label-required">*</span>
                    </div>
                    <div className="ord-add-input">
                        <Item
                            name={[`${formName}`, `endDate`]}
                            rules={[
                                {
                                    required: true,
                                    message: MESSAGE.MESSAGE_REQUIRED,
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        const startDate = getFieldValue('startDate');
                                        const endDate = getFieldValue('endDate');

                                        return checkStartDateAndEndDate(startDate, endDate, value);
                                    },
                                }),
                            ]}
                        >
                            <DatePicker
                                onChange={changeStatusWhenEndDateChange}
                                size="large"
                                className="ord-date-picker"
                                placeholder=""
                                inputReadOnly
                                locale={locale}
                                format={COMMON.FORMAT_DATE_CM}
                                suffixIcon={<img alt="" src={IconCalendar} />}
                                getPopupContainer={(trigger: any) => trigger.parentNode}
                                disabledDate={(current) =>
                                    disabledEndDate(
                                        current,
                                        form.getFieldValue([`${formName}`, `startDate`]),
                                    )
                                }
                            />
                        </Item>
                    </div>
                </div>
            </div>
            <div className="ord-add-row row-child">
                <div className="ord-add-label">
                    ステータス <span className="label-required">*</span>
                </div>
                <div className="ord-add-input">
                    <Item
                        name={[`${formName}`, 'statusId']}
                        rules={[
                            {
                                required: true,
                                message: MESSAGE.MESSAGE_REQUIRED,
                            },
                        ]}
                    >
                        <Select
                            size="large"
                            suffixIcon={<img alt="" src={IconDown} />}
                            getPopupContainer={(trigger: any) => trigger.parentNode}
                        >
                            {statusOrder?.map((item) => (
                                <Option key={item.id} value={item.code}>
                                    {item.name}
                                </Option>
                            ))}
                        </Select>
                    </Item>
                </div>
            </div>
            <div className="ord-add-row col-3">
                <div className="ord-form-item-3-left">
                    <span className="ord-form-label">基本料金</span>
                    <span className="ord-form-content">
                        {helpers.formatCurrency(currentCost)}円
                        <Item hidden name={[`${formName}`, `currentCost`]}>
                            <Input />
                        </Item>
                    </span>
                </div>
                <div className="ord-form-item-3-center">
                    <span className="ord-form-label">割引 </span>
                    <div className="ord-form-input input-number">
                        <Item name={[`${formName}`, `discount`]}>
                            <InputNumber controls={false}
                                onChange={onChangeDiscount}
                                size="large"
                                addonAfter="円"
                                max={99999}
                                formatter={(value) =>
                                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                }
                                parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                            />
                        </Item>
                    </div>
                </div>
                <div className="ord-form-item-3-right">
                    <span className="ord-form-label">月額料金</span>
                    <span className="ord-form-content">
                        {helpers.formatCurrency(currentTotalMAmount)}円
                    </span>
                </div>
            </div>
        </div >
    );
};
