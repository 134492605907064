import { Button, Collapse, Divider, Form, Input, Empty } from "antd";
import { memo } from "react";
import { PM014ContentHandler } from "../handler/HandlerImpl";
import { useParams } from "react-router-dom";

import HomeBuilding from "../../../../../assets/icons/home-building.svg";
import MinusSvg from "../../../../../assets/icons/minus-purple.svg";
import PlusPurpose from "../../../../../assets/icons/plus-purpose.svg";
import REGEX from "../../../../../common/constants/REGEX";
import MESSAGE from "../../../../../common/constants/MESSAGE";
import DocumentGraySVG from "../../../../../assets/icons/document-gray.svg";
import EditBlue from "../../../../../assets/icons/edit-blue.svg";
import DeleteRed from "../../../../../assets/icons/delete-red.svg";
import IconDown from "../../../../../assets/icons/arrow.svg";
import helpers from "../../../../../common/helpers/common";
import { STATUS_APPROVE } from "../handler/Handler";
import { onBlurTrimFormItem } from "../../../../../common/helpers/typeInput";
import { isHasPrivilege } from "../../../../../common/helpers/privilege";
import {
  PRIVILEGE_CREATOR,
  PRIVILEGE_PM,
} from "../../../../../common/constants/PRIVILEGE";
import {
  AUTHORIZATION_CODE,
  SYSTEM_ROLES,
} from "../../../../../common/constants/AUTHORIZATION";
import { RootState } from "../../../../../store";
import { useSelector } from "react-redux";
import { PLAN_TYPE } from "../../../../../common/constants/COMMON";

const { Panel } = Collapse;

interface props {
  handler: PM014ContentHandler;
}
const MAX_LENGTH_NOTE = 300;

const SubConstructionComponent = ({ handler }: props) => {
  const { projectId } = useParams();
  const permission = useSelector(
    (state: RootState) => state.auth.authData?.permission
  );
  const isCloneProject = handler.projectInfo.parentId !== 0;
  const isRoleAdmin = useSelector((state: RootState) => state.auth?.authData?.isRoleAdmin)
  const order = useSelector((state: RootState) => state.auth?.authData?.order?.code)  //action.payload?.order?.code;
  const isOrderBasic = order === PLAN_TYPE.PLAN_BASIC;
  const isRoleAdminSystem =
    useSelector((state: RootState) => state.auth?.authData?.isRoleAdminSystem)

  return (
    <div
      className={`folder-container folder-container-active
          `}
      style={{ backgroundColor: "#ffffff" }}
    >
      <div style={{ display: "inline-block", width: "100%" }}>
        {handler.constructionUI?.other.map((element, index) => {
          const conditionButtonCI = ((permission?.includes(AUTHORIZATION_CODE.S14) &&
            permission?.includes(AUTHORIZATION_CODE.S0)) ||
            permission?.includes(AUTHORIZATION_CODE.B10) ||
            isHasPrivilege(handler.projectInfo, [PRIVILEGE_PM]) ||
            handler.approverCIMapper[`${element?.id}`]) &&
            isOrderBasic

          const conditionButtonCR = ((permission?.includes(AUTHORIZATION_CODE.S18) &&
            permission?.includes(AUTHORIZATION_CODE.S0)) ||
            permission?.includes(AUTHORIZATION_CODE.B10) ||
            isHasPrivilege(handler.projectInfo, [PRIVILEGE_PM]) ||
            handler.approverCRMapper[`${element?.id}`]) &&
            isOrderBasic

          const notShowCollapse = !conditionButtonCR && !conditionButtonCI

          return <div
            key={element.id}
            style={{
              display: "flex",
              width: "calc(100% + 2px)",
              marginTop: 8,
              marginLeft: -1,
              marginRight: -1,
            }}
          >
            <Collapse
              className="folder-collapse folder-collapse-child"
              defaultActiveKey={notShowCollapse ? undefined : `${element.code}${element.id}`}
              collapsible={notShowCollapse ? 'disabled' : undefined}
              expandIcon={({ isActive }) => {
                if (notShowCollapse) return <></>
                return (
                  <div
                    className={`${isActive ? "collapse-icon-active" : ""
                      } collapse-icon`}
                  >
                    <img src={IconDown} alt="" />
                  </div>
                );
              }}
            >
              <Panel
                collapsible={
                  !(handler.noteEdit === element?.id) ? undefined : "disabled"
                }
                header={
                  <div
                    className="tag-wrapper"
                  // onClick={(e: any) => e.stopPropagation()}
                  >
                    {handler.isDisabledMinusButton(
                      handler.projectInfo,
                      element
                    ) ? (
                      <Button
                        className="button-gray-small"
                        icon={<img alt="" src={MinusSvg} />}
                        style={{ marginRight: 17.83 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handler.deleteConstruction(
                            {
                              constructionId: element.id,
                            },
                            element?.name
                          );
                        }}
                      ></Button>
                    ) : (
                      <div style={{ width: 37 }}></div>
                    )}
                    <img style={{ marginRight: 8 }} alt="" src={HomeBuilding} />
                    <span style={{ marginLeft: 12 }}>
                      {handler.projectInfo?.parentConstruction?.name || ""}
                    </span>
                    <span className="pm005-item-collapse-name">
                      {element.name}
                    </span>

                    {handler.chooseConstruction?.id !== element.id ? (
                      <></>
                    ) : (
                      <div className="tag-btn-save-wrapper">
                        <Form.Item className="tag-form-item">
                          <Button
                            className="button-purple tag-btn-save"
                            htmlType="submit"
                          >
                            保 存
                          </Button>
                        </Form.Item>
                        <Button
                          style={{
                            marginLeft: 12,
                            borderRadius: 4,
                          }}
                          onClick={handler.handleCancelUpdateConstruction}
                        >
                          キャンセル
                        </Button>
                      </div>
                    )}
                    <div
                      className="pm005-note"
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      <img
                        src={DocumentGraySVG}
                        alt=""
                        onMouseMove={() =>
                          handler.noteEdit === 0 &&
                          handler.handleChangeNoteView(element?.id)
                        }
                      />
                      {handler.noteView === element?.id && (
                        <div
                          className="pm005-note-wrapper"
                          onMouseLeave={() =>
                            handler.handleChangeNoteView(handler?.noteEdit)
                          }
                        >
                          {handler.noteEdit === element?.id ||
                            element?.note === "" ? (
                            <Form.Item
                              name={`addition${element.id}`}
                              className="pm005-input-note"
                            >
                              <Input.TextArea
                                disabled={!(handler.noteEdit === element?.id)}
                                placeholder="メモを入力してください。"
                                autoSize
                                maxLength={MAX_LENGTH_NOTE}
                                onChange={(e: any) =>
                                  handler.handleChangeInputArea(
                                    e,
                                    `addition${element.id}`
                                  )
                                }
                                onBlur={() => {
                                  onBlurTrimFormItem(
                                    handler.form,
                                    `addition${element.id}`
                                  );
                                  handler.handleCloseNoteConstruction();
                                }}
                                onMouseDown={(e) => e.stopPropagation()}
                                ref={handler.refNote}
                              />
                            </Form.Item>
                          ) : (
                            <span className="pm005-note-text">
                              {element?.note ? (
                                element?.note
                              ) : (
                                <Empty
                                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                                  description="データなし"
                                  className="note-transform"
                                />
                              )}
                            </span>
                          )}
                          {helpers.checkRoleUpdate(
                            element?.userId,
                            handler?.projectInfo?.projectManager?.id
                          ) ? (
                            <>
                              <Divider className="pm005-note-divider" />
                              {!(handler.noteEdit === element?.id) ? (
                                <div className="pm005-note-btn">
                                  <div
                                    className="pm005-note-edit"
                                    onClick={() =>
                                      handler.setNoteEdit(element?.id)
                                    }
                                  >
                                    <img
                                      src={EditBlue}
                                      alt=""
                                      className="pm005-note-icon-edit"
                                    />
                                    <span>編集</span>
                                  </div>
                                  {element?.note !== "" && (
                                    <div
                                      className="pm005-note-delete"
                                      onClick={() =>
                                        handler.handleDeleteNoteConstruction(
                                          element?.id
                                        )
                                      }
                                    >
                                      <img
                                        src={DeleteRed}
                                        alt=""
                                        className="pm005-note-icon-delete"
                                      />
                                      <span>削除</span>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div className="pm005-note-save">
                                  <span
                                    className="pm005-note-save-text"
                                    onClick={() => {
                                      handler.handleSaveUpdateNote(
                                        element?.id,
                                        `addition${element.id}`
                                      );
                                    }}
                                  >
                                    保存
                                  </span>
                                  <span
                                    className="pm005-note-cancel-text"
                                    onClick={handler.handleCancelUpdateNote}
                                  >
                                    キャンセル
                                  </span>
                                </div>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                }
                key={`${element.code}${element.id}`}
              >
                <div className="tag-contaner-folder">
                  {conditionButtonCI ? <div
                    className="tag-white-container pm005-btn-view"
                    onClick={() =>
                      handler.onViewConstructionInquiry(
                        element.id,
                        projectId || ""
                      )
                    }
                  >
                    <span>工事引合書</span>
                    <div
                      className="pm005-status-approve"
                      style={{
                        color:
                          STATUS_APPROVE[
                            element.ciDocumentStatus?.code || "reject"
                          ].color,
                        backgroundColor:
                          STATUS_APPROVE[
                            element.ciDocumentStatus?.code || "reject"
                          ].background_color,
                      }}
                    >
                      {element.ciDocumentStatus?.name}
                    </div>
                  </div>
                    : <></>
                  }
                  {conditionButtonCR ?
                    <div
                      className="tag-white-container pm005-btn-view"
                      onClick={() =>
                        handler.onViewRunningBudget(
                          element.id,
                          projectId || ""
                        )
                      }
                    >
                      <span>実行予算書</span>
                      <div
                        className="pm005-status-approve"
                        style={{
                          color:
                            STATUS_APPROVE[
                              element.crDocumentStatus?.code || "reject"
                            ].color,
                          backgroundColor:
                            STATUS_APPROVE[
                              element.crDocumentStatus?.code || "reject"
                            ].background_color,
                        }}
                      >
                        {element.crDocumentStatus?.name}
                      </div>
                    </div>
                    : <></>
                  }
                </div>
              </Panel>
            </Collapse>
          </div>
        })}
        {/* không là dự án clone VÀ là (PM hoặc người tạo hoặc admin) */}
        {!isCloneProject &&
          (isHasPrivilege(handler.projectInfo, [PRIVILEGE_PM, PRIVILEGE_CREATOR]) || isRoleAdmin || isRoleAdminSystem) &&
          (isOrderBasic || permission?.some((element) => [AUTHORIZATION_CODE.S14, AUTHORIZATION_CODE.S18].includes(element))) ? (
          <div
            style={{
              display: "inline-flex",
              columnGap: "12px",
              alignItems: "center",
              marginLeft: "14px",
              cursor: "pointer",
              paddingTop: 22,
              paddingBottom: 22,
            }}
            onClick={(e) => {
              if (e.detail === 1) {
                projectId &&
                  handler.postAddConstruction({
                    projectId: Number(projectId),
                  });
              }
            }}
          >
            <div className="button-gray-small">
              <img alt="" src={PlusPurpose} />
            </div>
            <div className="add-construction">
              <span>工事追加</span>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SubConstructionComponent;
