import PM008Api from "./infrastructure/api/Api";
import PM008Handler from "./presenter/handler/Handler";
import PM008UI from "./presenter/ui/UI";
import PM008Service from "./usecase/Service";

const PM008Component = ({ funcAfter }: { funcAfter?: () => void }) => {
  const pm008Api = PM008Api();
  const pm008Service = PM008Service(pm008Api);
  const pm008Handler = PM008Handler(pm008Service);
  return <PM008UI handler={pm008Handler} funcAfter={funcAfter} />;
};

export default PM008Component;
