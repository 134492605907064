// Built-in
import React, { useState } from "react";

// 3rd party lib
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MaterialCostServiceImpl } from "../../usecase/ServiceImpl";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import {
  DocumentDirectLaborData,
  MoneyDirectCostLaborListManagerType,
  MoneySubmitDirectCostLaborPayload,
} from "../../entity/Entity";
import { RootState } from "../../../../../store";
import {
  refreshCM0013,
  setCheckedCollapseDirect,
  setCheckedCollapseParent,
  setDataMaterialCost,
} from "../slice/Slice";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import COMMON, {
  STATUS_CM_PARENT_TABLE,
} from "../../../../../common/constants/COMMON";
import moment from "moment";
import { STATUS_CM_CHILD_TABLE } from "../../../../../common/constants/COMMON";
import { setIsEnable } from "../../../CM004/presenter/slice/Slice";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import exportFileDataCM0013 from "../../../../../common/helpers/export-excel/CM0013";
import ConfirmModalAsync from "../../../../../common/components/modal/ConfirmModalAsync";
import { doExportForResponse } from "../../../../../common/helpers/exports/common";

export const STATUS_PARENT = {
  approved: {
    name: STATUS_CM_PARENT_TABLE.APPROVED.LABEL,
    code: STATUS_CM_PARENT_TABLE.APPROVED.VALUE,
  },
  waiting_for_approve: {
    name: STATUS_CM_PARENT_TABLE.PENDING.LABEL,
    code: STATUS_CM_PARENT_TABLE.PENDING.VALUE,
  },
  rejected: {
    name: STATUS_CM_PARENT_TABLE.REJECTED.LABEL,
    code: STATUS_CM_PARENT_TABLE.REJECTED.VALUE,
  },
};

const MaterialCostHandler = (service: MaterialCostServiceImpl) => {
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const projectName = searchParams.get("projectName");

  const [checkedList, setCheckedList] = useState<any[]>([]);
  const [checkAll, setCheckAll] = useState(false);
  const [expandKey, setExpandKey] = useState<any[]>([]);
  const [refresh, setRefresh] = useState(false);

  const data = useSelector((state: RootState) => state.cm0013.dataMaterialCost);
  const constructionInfo = useSelector(
    (state: RootState) => state.cm004.constructionInfo
  );
  const checkedCollapse = useSelector(
    (state: RootState) => state.cm0013.checkedCollapse
  );

  const screenName = COMMON.CM_FILE_STRUCTURE.SCREEN_NAME;
  const tab1 = COMMON.CM_FILE_STRUCTURE.PARENT_TAB.TAB_3.NAME;
  const tab2 = COMMON.CM_FILE_STRUCTURE.PARENT_TAB.TAB_3.CHILDREN_TAB[2];
  const submitDate = moment().format(COMMON.FORMAT_DATE_SUBMIT);
  const fileName: string = `${screenName}_${tab1}_${tab2}_${submitDate}`;

  const getMoneyDirectCostLaborListManager = async (params: {
    constructionId: number;
    keyword?: string;
    sortBy?: string;
    sortType?: string;
  }): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const responseData = await service.getMoneyDirectCostLaborListManager(
        params
      );
      const finalResult: any[] = responseData ? responseData.results : [];

      // Có 3 giá trị: 承認済 & 承認待ち & 未承認
      // Điều kiện hiển thị trạng thái 承認済: Khi tất cả các record bảng con có trạng thái 承認済
      // Điều kiện hiển thị trạng thái 承認待ち: Khi có ít nhất 1 record ở bảng con có trạng thái 承認待ち
      // Điều kiện hiển thị trạng thái 未承認: Khi tồn tại 1 record ở bảng con có 1 trong 3 trạng thái 未承認, 取り下げ, 否認

      // get data for child
      for await (const [index, element] of finalResult.entries()) {
        const responseChild = await service.getMoneyDirectCostLaborList({
          documentDirectLaborCostId: element.id,
          page: COMMON.DEFAULT_PAGE,
          size: COMMON.DEFAULT_SIZE,
        });
        let checkApproved = true;
        let checkWaitingApproved = false;

        if (responseChild?.results?.length === 0) checkApproved = false;

        if (responseChild) {
          finalResult[index].actualCost = 0;
          finalResult[index].budgetBalance = 0;
          const dataChild = responseChild.results.map((data, i: number) => {
            if (
              data.status?.code === STATUS_CM_CHILD_TABLE.MONEY_APPROVED.CODE
            ) {
              finalResult[index].actualCost += data.actualCost ?? 0;
            }
            if (data.status?.code !== STATUS_CM_CHILD_TABLE.MONEY_APPROVED.CODE)
              checkApproved = false;
            if (
              data.status?.code ===
              STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE
            )
              checkWaitingApproved = true;
            return {
              key: data.id,
              no: i + 1,
              id: data.id,
              targetPeriodFrom: data.startDate
                ? moment(data.startDate).format(COMMON.FORMAT_DATE2)
                : "",
              targetPeriodTo: data.endDate
                ? moment(data.endDate).format(COMMON.FORMAT_DATE2)
                : "",
              datePayment: data.paymentDate
                ? moment(data.paymentDate).format(COMMON.FORMAT_DATE2)
                : "",
              amountPaid: data.planLaborCost,
              evidence: data.evidence,
              actualCost: data.actualCost,
              status: data.status,
              note: data?.note ?? "",
            };
          });
          finalResult[index].budgetBalance =
            finalResult[index].planCost - finalResult[index].actualCost;
          finalResult[index].child = dataChild;
        } else {
          finalResult[index].child = [];
        }
        if (checkApproved) finalResult[index].status = STATUS_PARENT.approved;
        else if (checkWaitingApproved)
          finalResult[index].status = STATUS_PARENT.waiting_for_approve;
        else finalResult[index].status = STATUS_PARENT.rejected;
      }

      const dataView = finalResult.map((data, i: number) => {
        return {
          id: data.id,
          key: data.id,
          no: i + 1,
          name: data.manager.fullName ?? "",
          budgetAmount: data.planCost ?? 0,
          actualCost: data.actualCost,
          budgetBalance: data.budgetBalance,
          threshold: data.threshold ?? 0,
          status: data.status,
          child: data.child,
          enable: data.enable,
        };
      });

      dispatch(setDataMaterialCost(dataView));
      setExpandKey([dataView[0]?.key]);
    } catch (error: any) {
      ErrorNotification(error.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const isCollapsed = (record: any) => {
    return expandKey.some((element) => element === record.key);
  };

  const onChangeCollapseRow = (record: any, collapsed: boolean) => {
    const collapsedRow = collapsed
      ? [...expandKey, record.key]
      : expandKey.filter((element) => element !== record.key);

    setExpandKey(collapsedRow);
  };
  const isCheckbox = (record: any): boolean =>
    checkedList.some((element) => element === record.key);

  const onCheckboxChange = (record: any, checked: boolean) => {
    // setCheckedList(list);
    let newCheckList: any[] = [];

    if (checked) {
      newCheckList = [...checkedList, record.key];
    } else {
      const checkedData = checkedList.filter(
        (element: any) => element !== record.key
      );
      newCheckList = checkedData;
    }
    const possibleItems: any[] = [];

    for (let element of data) {
      if (
        element.status?.code === STATUS_PARENT.rejected.code &&
        element?.child?.length > 0
      )
        possibleItems.push(element.id);
    }
    newCheckList = [...new Set(newCheckList)];
    setCheckedList(newCheckList);
    setCheckAll(newCheckList.length === possibleItems.length);
    dispatch(setCheckedCollapseParent({ id: record.id, checked: checked }));
  };

  const onCheckAllChange = (checked: boolean) => {
    setCheckAll(checked);
    if (checked) {
      const itemCheckList: any[] = [];
      for (const item of data) {
        if (
          item.status?.code === STATUS_PARENT.rejected.code &&
          item?.child?.length
        )
          itemCheckList.push(item.key);
      }
      setCheckedList(itemCheckList);
      // redux
      const checkedCollapseId = checkedCollapse.map((element) => element.id);
      const uncheckedData: any[] = [];
      itemCheckList.forEach((element) => {
        const isExisted = checkedCollapseId.includes(element);
        if (!isExisted) uncheckedData.push({ id: element, checked: [] });
      });
      dispatch(
        setCheckedCollapseDirect([...checkedCollapse, ...uncheckedData])
      );
    } else {
      setCheckedList([]);
      dispatch(setCheckedCollapseDirect([]));
    }
  };

  const putMoneyDirectCostLaborEnable = async (payload: any, id: number) => {
    try {
      dispatch(setLoading(true));
      const response = await service.putMoneyDirectCostLaborEnable({
        id: id,
        enable: payload.enable,
        threshold: payload.threshold,
      });
      SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const putMoneySubmitDirectCostLabor = async () => {
    ConfirmModalAsync({
      onOk: async () => {
        try {
          dispatch(setLoading(true));
          const payload: MoneySubmitDirectCostLaborPayload = {
            listDocumentDirectLaborData: checkedCollapse.map((element) => {
              const laborCost = data.find((ele) => ele.id === element.id);
              const constructionName = constructionInfo?.constructionName ?? ""; // Tên công trình
              const personInCharge = laborCost?.name ?? ""; // Tên người phụ trách

              // [Tên dự án_Tên công trình_Tên màn_Tên tab1 _Tên tab 2_Tên người phụ trách_năm_tháng_ngày submit]
              const fileName: string = `${projectName}_${constructionName}_${screenName}_${tab1}_${tab2}_${personInCharge}_${submitDate}`;

              const dataPayload: DocumentDirectLaborData = {
                fileName: fileName,
                documentDirectLaborCostId: element.id,
                listDocumentDirectLaborCostInformationId: element.checked,
              };
              return dataPayload;
            }),
          };

          const response = await service.putMoneySubmitDirectCostLabor(payload);
          setCheckedList([]);
          setCheckAll(false);
          dispatch(setCheckedCollapseDirect([]));
          dispatch(refreshCM0013());
          SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
          setRefresh(!refresh);
        } catch (error: any) {
          ErrorNotification(NOTIFICATION_TITLE.ERROR);
        } finally {
          dispatch(setLoading(false));
        }
      },
      onCancel: () => { },
      className: "confirm__modal confirm__modal-purple-oke",
      title: MESSAGE.MESSAGE_TITLE_BEFORE_CREATE,
      description: MESSAGE.MESSAGE_DESCRIPTION_CR_11_1,
      description2: MESSAGE.MESSAGE_DESCRIPTION_COMMON_QUESTION_2,
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_MODAL_2,
    });
  };

  const handleCheckEnableSubmit = () => {
    const isEnable = checkedList?.length > 0;
    dispatch(setIsEnable(isEnable));
  };
  const exportFile = async (params: {
    constructionId: number;
    keyword?: string;
    sortBy?: string;
    sortType?: string;
    projectName: string;
  }) => {
    try {
      dispatch(setLoading(true));

      // const finalResult = await getMoneyDirectCostLaborListManagerForExport({
      //   constructionId: params.constructionId,
      //   keyword: params.keyword,
      //   sortBy: params.sortBy,
      //   sortType: params.sortType,
      // });

      // await exportFileDataCM0013({
      //   data: finalResult,
      //   constructionInfo: constructionInfo,
      //   fileName,
      //   projectName: params.projectName,
      // });
      const response = await service.doExportCM0013({
        constructionId: params.constructionId,
        keyword: params.keyword,
        sortBy: params.sortBy,
        sortType: params.sortType,
        projectName,
      } as any);
      doExportForResponse(response, fileName + ".xlsx");
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  return {
    checkedList,
    checkAll,
    expandKey,
    refresh,
    onCheckAllChange,
    onCheckboxChange,
    isCheckbox,
    isCollapsed,
    onChangeCollapseRow,
    getMoneyDirectCostLaborListManager,
    putMoneyDirectCostLaborEnable,
    putMoneySubmitDirectCostLabor,
    handleCheckEnableSubmit,
    exportFile,
  };
};

export default MaterialCostHandler;
