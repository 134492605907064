import { DEFAULT_PAGINATION_RESULT } from './../../../../common/constants/COMMON';
import { PaginationResult } from '../../../../common/entity/Entity';
import { CompanyAPIImpl } from './ServiceImpl';

const CompanyService = (api: CompanyAPIImpl) => {
    const getAttendanceCompanyColab = async (params: {
        keyword?: string;
        page: string;
        size: string;
        sortBy?: string;
        sortType?: 'DESC' | 'ASC';
    }): Promise<PaginationResult> => {
        try {
            const response = await api.getAttendanceCompanyColab(params);
            return response.data || DEFAULT_PAGINATION_RESULT;
        } catch (error) {
            return DEFAULT_PAGINATION_RESULT;
        }
    };

    return { getAttendanceCompanyColab };
};

export default CompanyService;
