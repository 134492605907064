// Library
import { Tabs } from "antd";
import { useSearchParams } from "react-router-dom";

// Service
import { HandlerImpl } from "../handler/HandlerImpl";
import {
  CM0019_HOLD_KEY,
  CM0020_HOLD_KEY,
  CM0024_HOLD_KEY,
  CM0028_HOLD_KEY,
  CM0030_HOLD_KEY,
  CM0032_HOLD_KEY,
  DEFAULT_TAB_KEY,
  FROM_MONTH_KEY,
  PROJECT_PARAM_ID_KEY,
  TO_MONTH_KEY,
} from "../handler/Handler";

// Components
import BreadcrumbComponent from "../../../../../common/components/breadcrumb/BreadcrumbComponent";
import MenuWrapperComponent from "../../../../../common/components/menu-wrapper";
import TabComponent from "../../../CM0020/components/tab";
import CM0024Component from "../../../CM0024";
import CM0028Component from "../../../CM0028";
import CM0030Component from "../../../CM0030";
import Body from "./Body";

// Icons
import HomeSvg from "../../../../../assets/icons/home.svg";
import EmptyData from "../../../../../assets/icons/empty-data.svg";
import CM0032Component from "../../../CM0032";
import CM0020Component from "../../../CM0020";
import { useMemo, useEffect } from "react";
import COMMON from "../../../../../common/constants/COMMON";
import moment from "moment";
import helpers from "../../../../../common/helpers/common";

interface props {
  handler: HandlerImpl;
}

const BREADCRUMB: any[] = [
  {
    title: "収支管理",
    url: "",
    icon: (
      <img style={{ paddingBottom: 2, paddingRight: 8 }} alt="" src={HomeSvg} />
    ),
  },
  {
    title: "社内収支",
    url: "",
    icon: "",
  },
];

const MENU_ITEM = [
  {
    name: "業者別",
    key: "trader",
  },
  {
    name: "案件別",
    key: "project",
  },
];

const MainUI = ({ handler }: props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const tab: string = searchParams.get(DEFAULT_TAB_KEY) ?? "1";
  const projectId: string = searchParams.get(PROJECT_PARAM_ID_KEY) ?? "";
  const from =
    searchParams.get(FROM_MONTH_KEY) ??
    `${helpers.getFilterTimeMonth(
      moment().startOf("month").format(COMMON.FORMAT_DATE4)
    )}`;
  const to =
    searchParams.get(TO_MONTH_KEY) ??
    `${helpers.getFilterTimeMonthTo(moment().format(COMMON.FORMAT_DATE4))}`;
  const openFilter = searchParams.get("openFilter");
  const page = searchParams.get("page") ?? "1";

  useEffect(() => {
    searchParams.set(FROM_MONTH_KEY, from);
    searchParams.set(TO_MONTH_KEY, to);
    setSearchParams(searchParams);
  }, []);

  useEffect(() => {
    if (tab === CM0019_HOLD_KEY)
      handler.getListProjectFirstOpenModal({
        page: COMMON.DEFAULT_PAGE,
        size: COMMON.DEFAULT_SIZE,
        from: helpers.getFilterTimeMonth(from),
        to: helpers.getFilterTimeMonthTo(to),
        projectId: projectId,
      });
  }, [tab, handler.disableBtn]);

  useEffect(() => {
    if (tab === CM0019_HOLD_KEY)
      if (
        (!openFilter &&
          (handler.cFrom !== from ||
            handler.cTo !== to ||
            String(handler.page) !== page ||
            handler.cProjectId !== projectId)) ||
        handler.isChangedTax
      ) {
        handler.handleGetProjectListData({
          projectId: projectId,
          from: helpers.getFilterTimeMonth(from),
          to: helpers.getFilterTimeMonthTo(to),
          page: handler.page,
        });

        handler.getLineSummary({
          projectId: projectId ?? undefined,
          from: helpers.getFilterTimeMonth(from),
          to: helpers.getFilterTimeMonthTo(to),
        });

        handler.handleSearchOptionConstruction('', helpers.getFilterTimeMonth(from), helpers.getFilterTimeMonthTo(to))
      }
  }, [handler.page, projectId, tab, openFilter, handler.disableBtn]);

  useEffect(() => {
    handler.initialState(from, to);
  }, [from, to, handler.listProject]);

  const Element = useMemo(() => {
    switch (tab) {
      case CM0019_HOLD_KEY:
        return <Body handler={handler} />;
      case CM0020_HOLD_KEY:
        return <CM0020Component />;
      case CM0024_HOLD_KEY:
        return <CM0024Component />;
      case CM0028_HOLD_KEY:
        return <CM0028Component />;
      case CM0030_HOLD_KEY:
        return <CM0030Component />;
      case CM0032_HOLD_KEY:
        return <CM0032Component />;
      default:
        <></>;
    }
    return (
      <div className="cm-no-data">
        <img alt="" src={EmptyData} />
      </div>
    );
  }, [tab, handler]);

  return (
    <MenuWrapperComponent chosenKey={53} openKey={5}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
          marginBottom: 32,
        }}
      >
        <div className="om002-header-container">
          <BreadcrumbComponent data={BREADCRUMB} />
          <div className="header-row-title" style={{ marginTop: 8 }}>
            社内収支
          </div>
        </div>
      </div>

      <TabComponent />
      {Element}
    </MenuWrapperComponent>
  );
};

export default MainUI;
