
  import { AM016Api } from "./ServiceImpl";
  
  const AM016Service = (am016: AM016Api) => {
    const getRequestAttendance = async (params:any): Promise<any> => {
      try{
        return await am016.getRequestAttendance(params);
      }catch(error){
        throw(error)
      }
    };
  
    return {
      getRequestAttendance,
    };
  };
  
  export default AM016Service;
  