import DB002Api from './infrastructure/api/Api';
import DB002Handler from './presenter/handler/Handler';
import DB002UI from './presenter/ui/UI';
import DB002Service from './usecase/Service';

const DB002Component = () => {
    const db002Api = DB002Api();
    const db002Service = DB002Service(db002Api);
    const db002Handler = DB002Handler(db002Service);
    return <DB002UI handler={db002Handler} />;
};

export default DB002Component;
