import { PM014liceEntity } from "./../type/Presenter";
import { createSlice } from "@reduxjs/toolkit";

export const initialState: PM014liceEntity = {
  isOpenPM014: false,
};

export const PM014Slice = createSlice({
  name: "pm014",
  initialState,
  reducers: {
    resetPM014: () => initialState,
  },
});

export const { resetPM014 } = PM014Slice.actions;

export default PM014Slice.reducer;
