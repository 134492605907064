import { Modal, Checkbox, Button, Spin, Form } from "antd";
import { PM011Handler } from "../handler/HandlerImpl";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";

import "../../../../../assets/styles/PM/PM011.css";
import React, { useEffect } from "react";
import LoadingComponent from "../../../../../common/components/icons/LoadingComponent";
import CompanyComponent from "../../components/construction";

interface props {
  handler: PM011Handler;
}

const NORMAL_TITLE = "メンバー共有リスト";
const PREVIEW_TITLE = "メンバー共有リスト確認";

const PM011UI = ({ handler }: props) => {
  const isUpdating = useSelector((state: RootState) => state.pm011.isUpdating);

  const companyActiveKey = useSelector(
    (state: RootState) => state.pm011.companyActiveKey
  );
  const loadingPM011 = useSelector((state: RootState) => state.pm011.loading);
  const isOpenPM011 = useSelector(
    (state: RootState) => state.pm011.isOpenPM011
  );
  const isCheckAll = useSelector((state: RootState) => state.pm011.isCheckAll);
  const numberChosenCheckbox = useSelector(
    (state: RootState) => state.pm011.numberChosenCheckbox
  );
  const numberCheckbox = useSelector(
    (state: RootState) => state.pm011.numberCheckbox
  );
  const totalMember = useSelector(
    (state: RootState) => state.pm011.totalMember
  );

  const {
    form,
    isFetched,
    hidePM011,
    onSubmit,
    onCheckAll,
    onFinishUpdating,
    onBackUpdating,
    setCheckAllValue,
    getConstructionCompany,
    resetStatePM011,
  } = handler;

  useEffect(() => {
    if (isOpenPM011) getConstructionCompany();
    else resetStatePM011();
  }, [isOpenPM011, isFetched]);

  useEffect(() => {
    setCheckAllValue(
      numberCheckbox !== 0 &&
      numberChosenCheckbox !== 0 &&
      numberCheckbox === numberChosenCheckbox
    );
  }, [numberCheckbox, numberChosenCheckbox, companyActiveKey.length]);

  return (
    <Modal
      className="pm005-modal-member"
      title={isUpdating ? NORMAL_TITLE : PREVIEW_TITLE}
      onCancel={hidePM011}
      visible={isOpenPM011}
      footer={null}
      forceRender
      width="65%"
      maskClosable={false}
    >
      <Spin indicator={LoadingComponent} spinning={loadingPM011}>
        <div className="pm005-modal-member-wrapper">
          <div className="pm005-modal-member-body">
            <div className="pm005-modal-table">
              <h3 className="pm005-modal-member-title">
                選択人数：{numberChosenCheckbox}名
              </h3>
              <div
                className="pm014-table"
                style={{
                  border: "1px solid #DAE2EE",
                  borderRadius: 3,
                }}
              >
                <div className="pm014-table-header">
                  <div className="pm014-table-checkbox">
                    {isUpdating ? (
                      <Checkbox
                        checked={
                          isCheckAll
                        }
                        onChange={(event: any) =>
                          onCheckAll(event.target.checked)
                        }
                        disabled={
                          numberCheckbox === 0
                        }
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="pm014-table-4 pm014-table-header-item">
                    <span className="pm011-text-bold">名前</span>
                  </div>
                  <div className="pm014-table-4 pm014-table-header-item">
                    <span className="pm011-text-bold">CCUS技能者ID</span>
                  </div>
                  <div className="pm014-table-4 pm014-table-header-item">
                    <span className="pm011-text-bold">電話番号</span>
                  </div>
                  <div className="pm014-table-4 pm014-table-header-item">
                    <span className="pm011-text-bold">メールアドレス</span>
                    <div className="pm014-table-sorter"></div>
                  </div>
                </div>
                <div className="pm011-table-body pm011-body">
                  <Form form={form} id="inviteForm">
                    {handler.constructionCompany.map((element) => (
                      <React.Fragment key={element.construction.id + ""}>
                        <CompanyComponent
                          form={form}
                          company={element.company}
                          construction={element.construction}
                        />
                      </React.Fragment>
                    ))}
                  </Form>
                </div>
              </div>
            </div>
            <p className="total-case-font" style={{ marginTop: 24 }}>
              トータル：{totalMember}件
            </p>
          </div>
          <div className="pm025-modal-member-footer">
            {isUpdating ? (
              <div>
                <Button
                  size="large"
                  className="button-purple"
                  style={{ letterSpacing: -1 }}
                  onClick={(e: any) => {
                    onFinishUpdating();
                    e.stopPropagation();
                  }}
                  disabled={numberChosenCheckbox === 0}
                >
                  確認
                </Button>
                <Button size="large" onClick={hidePM011}>
                  キャンセル
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  size="large"
                  className="button-purple"
                  onClick={onSubmit}
                >
                  リスト共有
                </Button>
                <Button size="large" onClick={onBackUpdating}>
                  キャンセル
                </Button>
              </div>
            )}
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default PM011UI;
