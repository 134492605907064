import ChangeForgotPasswordApi from "../AS-Change-Forgot-Password/infrastructure/api/Api"
import ChangeForgotPasswordService from "../AS-Change-Forgot-Password/usecase/Service"
import ASForgotPasswordApi from "./infrastructure/api/Api"
import ASForgotPasswordHandler from "./presenter/handler/Handler"
import ASForgotPasswordUI from "./presenter/ui/UI"
import ASForgotPasswordService from "./usecase/Service"

const ASForgotPasswordComponent = () => {
    const asForgotPasswordApi = ASForgotPasswordApi()
    const asChangeForgotPasswordApi = ChangeForgotPasswordApi()
    const asForgotPasswordService = ASForgotPasswordService(asForgotPasswordApi)
    const asChangeForgotPasswordService = ChangeForgotPasswordService(asChangeForgotPasswordApi)
    const asForgotPasswordHandler = ASForgotPasswordHandler(asForgotPasswordService, asChangeForgotPasswordService)
    return <ASForgotPasswordUI handler={asForgotPasswordHandler} />
}

export default ASForgotPasswordComponent