import { Checkbox, Table } from "antd";
import { PM025TableHandler } from "../handler/HandlerImpl";
import { COLUMN } from "./PM025Columns";

import EmptyData from "../../../../../assets/icons/empty-data.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import {
  AUTHORIZATION_CODE,
  SYSTEM_ROLES,
} from "../../../../../common/constants/AUTHORIZATION";

interface props {
  handler: PM025TableHandler;
}

function PM025Table({ handler }: props) {
  const permission = useSelector(
    (state: RootState) => state.auth.authData?.permission
  );
  const isRoleAdmin = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdmin
  );
  const isRoleAdminBasic = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdminBasic
  );
  return (
    <div className="pm025-table pm025-table-bottom-border">
      <Table
        pagination={false}
        columns={COLUMN(handler)}
        dataSource={handler.data}
        scroll={{
          y: "65vh",
          x: "max-content",
        }}
        onChange={handler.handleChangeTablePM025}
        rowSelection={
          permission?.includes(AUTHORIZATION_CODE.M6) || isRoleAdminBasic
            ? {
                selectedRowKeys: handler.selectedRowKeys,
                onChange: handler.handleChangeSelectedRowKey,
                columnWidth: 66,
                // - Checkbox : Có 2 trạng thái
                //      + Enable và uncheck: Khi công ty chưa được chọn vào dự án nào ở popup PM008
                //      + Disable và uncheck: Khi công ty đã được chọn vào dự án ở popup PM008
                getCheckboxProps: (record) => ({
                  disabled: false,
                }),
              }
            : undefined
        }
        locale={{ emptyText: <img src={EmptyData} alt="" /> }}
      />
    </div>
  );
}

export default PM025Table;
