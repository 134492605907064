import ErrorNotification from '../../../../common/components/notification/ErrorNotification';
import { NOTIFICATION_TITLE } from '../../../../common/constants/MESSAGE';
import { CM0034Api } from './ServiceImpl';

const CM0034Service = (cm0034: CM0034Api) => {
    const getDocumentReceiveMoneySubmittedList = async (params: any): Promise<any> => {
        try {
            const response = await cm0034.getDocumentReceiveMoneySubmittedList({ ...params });
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return;
        }
    };

    return {
        getDocumentReceiveMoneySubmittedList,
    };
};

export default CM0034Service;
