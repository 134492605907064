import CM0029Api from './infrastructure/api/Api';
import CM0029Handler from './presenter/handler/Handler';
import CM0029UI from './presenter/ui/UI';
import CM0029Service from './usecase/Service';
import '../../../assets/styles/CM/CM0028.css';

type props = {
    afterSuccess?: () => void;
    onSubmit?: () => void;
    onReject?: () => void;
};
const CM0029Component = (props: props) => {
    const cm0029Api = CM0029Api();
    const cm0029Service = CM0029Service(cm0029Api);
    const cm0029Handler = CM0029Handler(cm0029Service);
    return <CM0029UI handler={cm0029Handler} {...props} />;
};

export default CM0029Component;
