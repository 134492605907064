import React from "react";
import { notification } from "antd";
import { NotificationPlacement } from "antd/lib/notification";

const FirebaseNotification = (title: string, body: string) => {
  const placement: NotificationPlacement = "topRight";
  notification.open({
    message: title,
    description: body,
    duration: 2,
    placement,
  });
};

export default FirebaseNotification;
