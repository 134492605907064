import React from 'react'
import { STATUS_CM_CHILD_TABLE } from '../../../../../../../common/constants/COMMON';

import EyeApprovedIcon from "../../../../../../../assets/icons/eye-approved.svg";
import EyeNotApprovedIcon from "../../../../../../../assets/icons/eye-not-approved.svg";
import EyeRetrieveIcon from "../../../../../../../assets/icons/eye-retrieve.svg";
import EyeWaitingIcon from "../../../../../../../assets/icons/eye-waiting-for-approval.svg";
import IconI from "../../../../../../../assets/icons/icon-i-red.svg";


export const EyeNote = (props:
    {
        record: any,
        viewApproveModal: (record: any) => void
    }
) => {

    const { record, viewApproveModal } = props
    const renderEye = (status: string) => {
        switch (status) {
            case STATUS_CM_CHILD_TABLE.MONEY_APPROVED.CODE:
                return EyeApprovedIcon
            case STATUS_CM_CHILD_TABLE.MONEY_NOT_APPROVED.CODE:
                return EyeNotApprovedIcon;
            case STATUS_CM_CHILD_TABLE.MONEY_REJECT.CODE:
                return IconI;
            case STATUS_CM_CHILD_TABLE.MONEY_RETRIEVE.CODE:
                return EyeRetrieveIcon;
            case STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE:
                return EyeWaitingIcon;
        }
    };
    return (
        <div style={{ marginLeft: 8 }} className="center-item">
            {record?.note !== "" ? (
                <div
                    className="button-style"
                    onClick={() => viewApproveModal(record)}
                >
                    <img alt="" src={renderEye(record?.status?.code)} />
                </div>
            ) : (
                <></>
            )}
        </div>
    )
}