import { Empty } from "antd";
import helpers from "../../../../../common/helpers/common";
import { HeaderItem } from "../type/Presenter";

interface props {
  data: {
    headers: HeaderItem[]
    data: [number[], number[], number[]]
  }
}

const TableComponent = ({ data }: props) => {
  const hasRowData = data.data[0].length > 0 && data.data[1].length > 0 && data.data[2].length > 0 
  return (
    <>
      {data.headers?.length > 0 && hasRowData ? (
        <div className="db007-table">
          <table>
            <thead>
              <tr>
                <th style={{ width: "140px" }}></th>
                {data.headers.map((header: HeaderItem, index: number) => (
                  <th
                    colSpan={header.numOfChildCol}
                    key={index}
                    style={{ width: "140px", textAlign: "center" }}
                  >
                    {header.label}年
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr style={{ background: "#fff" }} className="db007-row-last">
                <td style={{ width: 140, textAlign: "left" }}>月</td>
                {data.data[0].map((colValue: number, index: number) => (
                  <td key={index} style={{ width: 140, textAlign: "center" }}>
                    {colValue}月
                  </td>
                ))}
              </tr>
              <tr className="bg-fa db007-row-last">
                <td style={{ width: 140, textAlign: "left" }}>稼働原価</td>
                {data.data[1].map((colValue: number, index: number) => (
                  <td key={index} style={{ width: 140, textAlign: "center" }}>
                    {helpers.formatNumberWithCommas(colValue)}円
                  </td>
                ))}
              </tr>
              <tr className="db007-row-last">
                <td style={{ width: 140, textAlign: "left" }}>稼働人工数</td>
                {data.data[2].map((colValue: number, index: number) => (
                  <td key={index} style={{ width: 140, textAlign: "center" }}>
                    {helpers.formatNumberWithCommas(colValue)}人工
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <div style={{ paddingTop: 60 }}>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="データなし"
          />
        </div>
      )}
    </>
  );
};

export default TableComponent;
