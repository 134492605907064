import { AxiosResponse } from 'axios';
import axiosConfig from '../../../../../config/axiosConfig';

const CCUS02Api = () => {

    const getTimesheetSyncData = async (params: {
        projectId?: number;
        companyId?: number;
        approverId?: number;
        state?: string;
        from?: string;
        to?: string;
        syncTime?: string;
        registerType?: string;
        sortBy?: string;
        sortType?: string;
        page?: number;
        size?: number;
    }): Promise<AxiosResponse<any, any>> => {
        const url = "/ccus/timesheet/sync-data";
        return await axiosConfig.get(url, { params });
    };

    const getProjectListCCUS = async (params: {
        companyId?: number;
        approverId?: number;
        state?: string;
        page: number;
        size: number;
    }): Promise<AxiosResponse<any, any>> => {
        const url = "/ccus/project/list";
        return await axiosConfig.get(url, { params });
    };

    const getDependedCompanyListCCUS = async (params: {
        projectId?: number;
        approverId?: number;
        state?: string;
        page: number;
        size: number;
    }): Promise<AxiosResponse<any, any>> => {
        const url = "/ccus/depended-company/list";
        return await axiosConfig.get(url, { params });
    };

    const getApproverListCCUS = async (params: {
        companyId?: number;
        projectId?: number;
        state?: string;
        page: number;
        size: number;
    }): Promise<AxiosResponse<any, any>> => {
        const url = "/ccus/approver/list";
        return await axiosConfig.get(url, { params });
    };

    const getCCUSTimesheet = async (params: {
        projectId?: number;
        companyId?: number;
        approverId?: number;
        state?: string;
        syncTime?: string;
        registerType?: string;
        from?: string;
        to?: string;
    }): Promise<AxiosResponse<any, any>> => {
        const url = "/ccus/timesheet/sync-data/range-attendance-date";
        return await axiosConfig.get(url, { params });
    };

    return {
        getTimesheetSyncData,
        getProjectListCCUS,
        getDependedCompanyListCCUS,
        getApproverListCCUS,
        getCCUSTimesheet
    };
};

export default CCUS02Api;
