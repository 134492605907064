import { CM003SliceType } from './../type/Entity';
import { createSlice } from '@reduxjs/toolkit';

const initialState: CM003SliceType = {};

export const cm003Slice = createSlice({
    name: 'cm003',
    initialState,
    reducers: {
        resetCM003: () => initialState,
    },
});

export const {} = cm003Slice.actions;

export default cm003Slice.reducer;
