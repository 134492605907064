import { MouseEventHandler } from "react"
import XIconModal from "../../../assets/icons/x-icon-modal.svg"

import "../../../assets/styles/OM/OM004.css"
type Props = {
    title: string,
    onCancel: MouseEventHandler<HTMLDivElement>
}

const OMHeaderModalComponent = ({ onCancel, title }: Props) => {
    return (
        <div className="w-100-percent header-modal-containter" style={{ height: 49 }}>
            <div className="header-modal-title-om004" style={{ marginLeft: 24 }}>{title}</div>
            <div style={{ cursor: "pointer", marginRight: 24 }} onClick={onCancel}>
                <img alt="" src={XIconModal} style={{ height: 18, width: 18 }} />
            </div>
        </div>
    )
}

export default OMHeaderModalComponent