import helpers from '../../../common/helpers/common';
import { DataTableConstructionBudgetBalance } from '../CM0016/entity/Entity';

const convertDataTableInformationCost = (data: any[]): DataTableConstructionBudgetBalance[] => {
    const output: DataTableConstructionBudgetBalance[] = [];

    data.forEach((item: any, idx: number) => {
        const budget = item?.materialCost?.planCost ?? 0;
        const actual = item?.materialCost?.actualCost ?? 0;

        const obj: DataTableConstructionBudgetBalance = {
            key: idx,
            budgetAmount: budget,
            actualCost: actual,
            budgetBalance: budget - actual,
            budgetRate: helpers.calculateRateAmount(budget, actual),
        };

        output.push(obj);
    });
    return output;
};

export { convertDataTableInformationCost };
