import React from 'react';

// Handler
import { AM010Handler } from '../handler/HandlerImpl';

// Common
import moment from 'moment';
import COMMON from '../../../../../common/constants/COMMON';
import ClockComponent from '../../../../../common/components/clock/ClockComponent';
import TimeComponent from '../../../../../common/components/clock/TimeComponent';
import helpers from '../../../../../common/helpers/common';

type props = {
  handler: AM010Handler;
};

export default function AM010HeaderTimerNoProject({ handler }: props) {
  return (
    <div className="am010__timer-container">
      <div className="am010__timer-box1">
        <div className="am010__timer-select">
          <div className="am010__timer-title-no">
            <h4>{moment().format(COMMON.FORMAT_DATE_OM)}</h4>
          </div>
          <div className="am010__timer-counter-no">
            <p>
              {moment().locale('en').format('A')} &nbsp;
              <TimeComponent />
            </p>
          </div>
        </div>
        <div className="am010__timer-clock">
          <ClockComponent />
        </div>
      </div>
      <div className="am010__timer-box2">
        <div className="am010__timer-action-no">
          <div className="am010__action-register1" onClick={() => handler.handleCheckInNoProject()}>
            出勤登録
          </div>
          <div
            className="am010__action-register2"
            onClick={() => handler.handleCheckOutNoProject()}
          >
            退勤登録
          </div>
        </div>
      </div>
    </div>
  );
}
