import { SYSTEM_ROLES } from "../../../common/constants/AUTHORIZATION";
import { PLAN_TYPE } from "../../../common/constants/COMMON";
import helpers from "../../../common/helpers/common";
import { APIImpl } from "./ServiceImpl";

export const Service = (dataSourceAPI: APIImpl) => {
  const postRefreshToken = async (data: {
    refreshToken: string;
  }): Promise<any> => {
    const response = await dataSourceAPI.postRefreshToken(data);

    const userLocalStorage = helpers.getObjectFromLocalStorage("user");

    localStorage.setItem(
      "user",
      JSON.stringify({
        ...response.results,
        isRemembered: userLocalStorage.isRemembered,
      })
    );

    const authData = response.results;

    const isRoleAdminBasic =
      authData?.role?.code === SYSTEM_ROLES.ADMIN &&
      authData?.order?.code === PLAN_TYPE.PLAN_BASIC;
    const isRoleAdmin = authData?.role?.code === SYSTEM_ROLES.ADMIN;
    const isRoleAdminSystem =
      authData?.role?.code === SYSTEM_ROLES.ADMIN_SYSTEM;

    return {
      ...authData,
      isReWorkflow: false,
      isDoubleCheck: false,
      isUserInWorkspace: false,
      isRoleAdminBasic,
      isRoleAdmin,
      isRoleAdminSystem,
    };
  };

  return { postRefreshToken };
};
