import { CommonPutDeletePostResponse } from "../../CM007/entity/Entity";
import { WorkflowResponseData } from "../../CM008/entity/Entity";
import {
  ProjectAllsResultsPagination,
  ReSummaryProjectLaborCostRetrievePayload,
  ReSummarySubmittedLaborCostProject,
  ReSummarySubmittedProjectLaborCostResults,
  ReSummarySubmittedProjectLaborCostTotalResults,
} from "../entity/Entity";
import { CM0029Api } from "./ServiceImpl";

const CM0029Service = (api: CM0029Api) => {
  const getReSummarySubmittedProjectLaborCost = async (params: {
    documentId: number;
    projectId: number;
  }): Promise<ReSummarySubmittedProjectLaborCostResults> => {
    try {
      const response = await api.getReSummarySubmittedProjectLaborCost(params);
      return response.data?.results;
    } catch (error) {
      throw error;
    }
  };
  const getReSummarySubmittedLaborCostProjectList = async (params: {
    documentId: number;
    page?: number;
    size?: number;
  }): Promise<ReSummarySubmittedLaborCostProject> => {
    try {
      const response = await api.getReSummarySubmittedLaborCostProjectList(
        params
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  const getReSummarySubmittedProjectLaborCostTotal = async (params: {
    documentId: number;
  }): Promise<ReSummarySubmittedProjectLaborCostTotalResults> => {
    try {
      const response = await api.getReSummarySubmittedProjectLaborCostTotal(
        params
      );
      return response.data?.results;
    } catch (error) {
      throw error;
    }
  };
  const putReSummaryProjectLaborCostRetrieve = async (
    data: ReSummaryProjectLaborCostRetrievePayload
  ): Promise<CommonPutDeletePostResponse> => {
    try {
      const response = await api.putReSummaryProjectLaborCostRetrieve(data);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getWorkflowSummaryLaborApprovalProcess = async (params: {
    documentId: number;
  }): Promise<WorkflowResponseData> => {
    try {
      const response = await api.getWorkflowSummaryLaborApprovalProcess(params);
      return response.data;
    } catch (error: any) {
      throw error;
    }
  };
  return {
    getReSummarySubmittedProjectLaborCostTotal,
    getReSummarySubmittedProjectLaborCost,
    putReSummaryProjectLaborCostRetrieve,
    getReSummarySubmittedLaborCostProjectList,
    getWorkflowSummaryLaborApprovalProcess,
  };
};

export default CM0029Service;
