import { PM013SliceType } from "./../type/Presenter";
import { createSlice } from "@reduxjs/toolkit";

const initialState: PM013SliceType = {
  loading: false,
  isVisible: false,
  groupId: null,
};

export const pm013Slice = createSlice({
  name: "pm013",
  initialState,
  reducers: {
    resetPM013: () => initialState,
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsVisiblePM013: (state, action) => {
      state.isVisible = action.payload;
    },
    setGroupIdPM013: (state, action) => {
      state.groupId = action.payload;
    },
  },
});

export const { setLoading, setIsVisiblePM013, resetPM013, setGroupIdPM013 } =
  pm013Slice.actions;

export default pm013Slice.reducer;
