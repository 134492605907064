import ProjectApi from '../../../infrastructure/api/ProjectsApi';
import ProjectService from '../../../usecase/ProjectService';
import ProjectHandler from '../../handler/HandlerProject';
import ProjectUI from './ui/UI';

const ProjectComponent = () => {
    const api = ProjectApi();
    const service = ProjectService(api);
    const handler = ProjectHandler(service);
    return <ProjectUI handler={handler} />;
};

export default ProjectComponent;
