import { createSlice } from "@reduxjs/toolkit";
import { CM0015SliceType } from "../../entity/Entity";

export const initialState: CM0015SliceType = {
  isRefresh: false,
  dataMaterialCost: [],
  checkedCollapse: [],
  constructionDirectCost: [],
};

export const CM0015Slice = createSlice({
  name: "cm0015",
  initialState,
  reducers: {
    refreshCM0015: (state) => {
      state.isRefresh = !state.isRefresh;
    },
    setMaterialCostData: (state, action) => {
      state.dataMaterialCost = action.payload;
    },
    setCheckedCollapseDirect: (state, action) => {
      state.checkedCollapse = action.payload;
    },
    setCheckedCollapse: (state, action) => {
      const data = action.payload;
      const isExisted = state.checkedCollapse.some(
        (element) => element.id === data.id
      );
      if (isExisted) {
        state.checkedCollapse = state.checkedCollapse.map((element) => {
          if (element.id === data.id) return data;
          return element;
        });
      } else state.checkedCollapse = [...state.checkedCollapse, data];
    },
    setCheckedCollapseParent: (state, action) => {
      const id = action.payload.id;
      const isChecked = action.payload.checked;

      if (!isChecked) {
        state.checkedCollapse = state.checkedCollapse.filter(
          (element) => element.id !== id
        );
      } else {
        const isExisted = state.checkedCollapse.some(
          (element) => element.id === id
        );

        if (!isExisted) {
          state.checkedCollapse = [
            ...state.checkedCollapse,
            { id: id, checked: [] },
          ];
        }
      }
    },
    setConstructionDirectCost: (state, action) => {
      state.constructionDirectCost = action.payload;
    },
    resetCM0015: () => initialState,
  },
});

export const {
  refreshCM0015,
  setMaterialCostData,
  setCheckedCollapse,
  resetCM0015,
  setCheckedCollapseParent,
  setCheckedCollapseDirect,
  setConstructionDirectCost,
} = CM0015Slice.actions;

export default CM0015Slice.reducer;
