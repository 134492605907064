import React from 'react';
import { Modal, Button } from 'antd';

import IconCircleRed from '../../../assets/icons/infor-circle.svg';
import '../../../assets/styles/AM/AM028.css';

type props = {
    visible: boolean;
    rejectReason: string;
    funcClose(): void;
};
const ModalRejectView = ({ visible, rejectReason, funcClose }: props) => {
    return (
        <Modal
            className="am028-modal"
            width="38%"
            closeIcon={<></>}
            centered
            visible={visible}
            footer={false}
            maskClosable={false}
        >
            <div className="am038-title">
                <img src={IconCircleRed} alt="" />
                <span>否認理由</span>
            </div>
            <div className="am038-content">
                <span className="am038-content-label">理由：</span>
                <span className="am038-content-title">{rejectReason}</span>
            </div>
            <div className="am038-btn">
                <Button className="button-purple" size="large" onClick={funcClose}>
                    閉じる
                </Button>
            </div>
        </Modal>
    );
};

export default ModalRejectView;
