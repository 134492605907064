import { List, Spin } from "antd";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { TodoHanlder } from "../handler/HandlerImpl";
import { RootState } from "../../../../store";

interface props {
  handler: TodoHanlder;
}

const TodoUI = ({ handler }: props) => {
  const todos = useSelector((state: RootState) => state.todo.data);
  const loading = useSelector((state: RootState) => state.common.loading);

  useEffect(() => {
    handler.getTodos();
  }, []);

  return (
    <Spin spinning={!!loading}>
      <List
        size="small"
        header={<h1>Danh sách Todos</h1>}
        bordered
        dataSource={todos}
        renderItem={(item) => <List.Item>{item.title}</List.Item>}
      />
    </Spin>
  );
};

export default TodoUI;
