import { CM0028SliceType } from './../type/Presenter';
import { createSlice } from '@reduxjs/toolkit';

const initialState: CM0028SliceType = {
    isRefresh: false,
};

export const cm0028Slice = createSlice({
    name: 'cm0028',
    initialState,
    reducers: {
        resetCM0028: () => initialState,
    },
});

export const { resetCM0028 } = cm0028Slice.actions;

export default cm0028Slice.reducer;
