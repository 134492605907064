// Built-in
import React from 'react';

// 3rd party lib
import { Form, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';

// Source files
import { COLUMN_WIDTH_CM007 } from '../../material-cost/ui/UI';

// Image files
import {
    CostType,
    MoneySummaryConstructionCostInformationResult,
    ThresholdPutType,
} from '../../../../entity/Entity';
import {
    calculateBudgetBalance,
    calculateBudgetUtilizationRate,
    convertDataSourceDirectCost,
} from '../helper';
import ThresholdPopoverComponent from '../../../../../../../common/components/threshold-popover';
import helpers from '../../../../../../../common/helpers/common';

type props = {
    // laborCost: CostType;
    // materialCost: CostType;
    // outsourceCost: CostType;
    constructionCost: MoneySummaryConstructionCostInformationResult | null;
    constructionId: any;
    putMoneySummaryConstructionCostMaterialThresholdUpdate: (
        payload: ThresholdPutType,
    ) => Promise<void>;
    putMoneySummaryConstructionCostOutsourceThresholdUpdate: (
        payload: ThresholdPutType,
    ) => Promise<void>;
    putMoneySummaryConstructionCostLaborThresholdUpdate: (
        payload: ThresholdPutType,
    ) => Promise<void>;
};

const CollapsedTableComponent = ({
    constructionCost,
    constructionId,
    putMoneySummaryConstructionCostMaterialThresholdUpdate,
    putMoneySummaryConstructionCostLaborThresholdUpdate,
    putMoneySummaryConstructionCostOutsourceThresholdUpdate,
}: props) => {
    const columns: ColumnsType<any> = [
        {
            title: 'No',
            key: 'No',
            width: COLUMN_WIDTH_CM007[0],
            render: (_, record, index) => {
                return <div></div>;
            },
        },
        {
            title: '項目',
            key: '項目',
            width: COLUMN_WIDTH_CM007[1],
            render: (record) => {
                return <span className="collapsed-table-row-style">{record.name}</span>;
            },
        },
        {
            title: '予算金額',
            key: '予算金額',
            width: COLUMN_WIDTH_CM007[2],
            render: (record) => {
                return (
                    <span className="collapsed-table-row-style">
                        {`${helpers.formatNumberMoney(record.planCost)}円`}
                    </span>
                );
            },
        },
        {
            title: '実費用',
            key: '実費用',
            width: COLUMN_WIDTH_CM007[3],
            render: (record) => {
                return (
                    <span className="collapsed-table-row-style">
                        {`${helpers.formatNumberMoney(record.actualCost)}円`}
                    </span>
                );
            },
        },
        {
            title: '予算残高',
            key: '予算残高',
            width: COLUMN_WIDTH_CM007[4],
            render: (record) => {
                return (
                    <span className="collapsed-table-row-style">
                        {`${helpers.formatNumberMoney(calculateBudgetBalance(record))}円`}
                    </span>
                );
            },
        },
        {
            title: '予算消化率（％）',
            key: '予算消化率（％）',
            width: COLUMN_WIDTH_CM007[5],
            render: (record) => {
                let updateFunction = putMoneySummaryConstructionCostMaterialThresholdUpdate;
                if (record.key === 'laborCost')
                    updateFunction = putMoneySummaryConstructionCostLaborThresholdUpdate;
                else if (record.key === 'outsourceCost')
                    updateFunction = putMoneySummaryConstructionCostOutsourceThresholdUpdate;
                return (
                    <div>
                        <span className="cell-cm007-style white-space-nowrap">{`${calculateBudgetUtilizationRate(
                            record,
                        )}%`}</span>
                        <ThresholdPopoverComponent
                            initialData={{ ...record, constructionId: constructionId }}
                            update={updateFunction}
                        />
                    </div>
                );
            },
        },
    ];

    return (
        <Form style={{ padding: 0, margin: 0 }}>
            <Table
                showHeader={false}
                dataSource={convertDataSourceDirectCost({
                    laborCost: constructionCost?.laborCost,
                    materialCost: constructionCost?.materialCost,
                    outsourceCost: constructionCost?.outsourceCost,
                })}
                bordered={true}
                columns={columns}
                className="collapsed-table-header table-footer-inside "
                pagination={false}
            />
        </Form>
    );
};

export default CollapsedTableComponent;
