import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Handler } from "../handler/HandlerImpl";
import helpers from "../../../../../common/helpers/common";

interface props {
  handler: Handler;
}

const TableOverviewComponent = ({ handler }: props) => {
  const contentTable = useMemo(() => {
    const elementTable: any = [];
    handler.dataTableOverview.forEach((element, index) => {
      elementTable.push(
        <React.Fragment key={element.key}>
          <div style={{ height: 4 }}></div>
          <tr className={index % 2 === 0 ? "bg-ff" : "bg-fa"}>
            <td>{element.item}</td>
            <td>
              {element.contractCost
                ? `${helpers.formatNumberMoney(element.contractCost)}円`
                : ""}
            </td>
            <td>{helpers.formatNumberMoney(element.budget)}円</td>
            <td>
              {isFinite(element.rateBudget)
                ? helpers.formatNumberMoney(element.rateBudget)
                : 0}
              %
            </td>
            <td>{helpers.formatNumberMoney(element.operatingCost)}円</td>
            <td>
              {element.item !== "粗利"
                ? `${isFinite(element.rateUseBudget)
                  ? helpers.formatNumberMoney(element.rateUseBudget)
                  : 0
                }%`
                : ""}
            </td>
            <td> {index == 0 ? `${element.manPower}人工` : ""} </td>
            <td>
              {index == 0
                ? `${helpers.formatNumberMoney(element?.depositAmount)}円`
                : ""}
            </td>
            <td>
              {index == 0
                ? `${isFinite(element.completionVolume)
                  ? helpers.formatNumberMoney(element.completionVolume)
                  : 0
                }%`
                : ""}
            </td>
            <td>
              {index == 0
                ? `${helpers.formatNumberMoney(element.grossProfit)}円`
                : ""}
            </td>
            <td>
              {index == 0
                ? `${isFinite(element.grossProfitMargin)
                  ? helpers.formatNumberMoney(element.grossProfitMargin)
                  : 0
                }%`
                : ""}
            </td>
          </tr>
        </React.Fragment>
      );
    });
    return elementTable;
  }, [handler.dataTableOverview]);

  return (
    <div className="db002-table">
      <table>
        <thead>
          <tr className="row-title">
            <th>項目</th>
            <th>契約金額</th>
            <th>予算</th>
            <th>予算(％)</th>
            <th>稼働原価</th>
            <th>予算消化率(％)</th>
            <th>稼働人工数</th>
            <th>入金額</th>
            <th>完成出来高(％)</th>
            <th>粗利</th>
            <th>粗利率(％)</th>
          </tr>
        </thead>
        <tbody>{contentTable}</tbody>
      </table>
    </div>
  );
};

export default TableOverviewComponent;
