import axiosConfig from "../../../../../config/axiosConfig";

  const AM020Api = () => {
    const getRequestLateEarly = async(params:any):Promise<any>=>{
      try {
        const url = "/request/late-early";
        const response = await axiosConfig.get(url, { params });
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  
    return {
      getRequestLateEarly,
    };
  };
  
  export default AM020Api;
  