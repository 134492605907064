import { Form } from "antd";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { CCUS05ServiceImpl } from "../../usecase/ServiceImpl";
import { useState } from "react";
import MESSAGE, { LABEL_MESSAGE, NOTIFICATION_TITLE } from "../../../../../common/constants/MESSAGE";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import { DataTableType, FilterCCUSType, ResultDataType } from "../../entity/Entity";
import moment from "moment";
import { DEFAULT_PARAM, PARAMS, STATE_PENDING } from "../../constants";
import COMMON from "../../../../../common/constants/COMMON";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import { REGISTER_TYPE } from "../../../CCUS02/constants";
import ModalConfirmCCUS from "../../../../../common/components/modal/ModalConfirmCCUS";
import IconSuccess from "../../../../../assets/icons/icon-success.svg";
const CCUS005Handler = (service: CCUS05ServiceImpl) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const [dataTable, setDataTable] = useState<DataTableType[]>([]);
  const [totalRecord, setTotalRecord] = useState<number>(0);

  const sortType = searchParams.get(PARAMS.sortType);
  const sortBy = searchParams.get(PARAMS.sortBy);
  const page = searchParams.get(PARAMS.page) || COMMON.DEFAULT_PAGE + "";
  const size = searchParams.get(PARAMS.size) || COMMON.DEFAULT_SIZE + "";

  const [listProject, setListProject] = useState<FilterCCUSType[]>([]);
  const [listCompany, setListCompany] = useState<FilterCCUSType[]>([]);

  const [pageProject, setPageProject] = useState<number>(DEFAULT_PARAM.page);
  const [pageCompany, setPageCompany] = useState<number>(DEFAULT_PARAM.page);

  const [sizeProject, setSizeProject] = useState<number>(DEFAULT_PARAM.size);
  const [sizeCompany, setSizeCompany] = useState<number>(DEFAULT_PARAM.size);

  const [loadingProject, setLoadingProject] = useState<boolean>(false);
  const [loadingCompany, setLoadingCompany] = useState<boolean>(false);

  const [selectedRowsKey, setSelectedRowsKey] = useState<React.Key[]>([])
  const [isRefreshCCUS05, setIsRefreshCCUS05] = useState<boolean>(false)

  const [selectedProject, setSelectedProject] = useState<string>("")
  const [selectedCompany, setSelectedCompany] = useState<string>("")

  const currentScroll: any = document.querySelector(".ant-table-body");


  const onPageChange = (value: number) => {
    if (currentScroll) {
      currentScroll.scrollTo(0, 0)
    }
    setSelectedRowsKey([])
    searchParams.set(PARAMS.page, value + "");
    setSearchParams(searchParams);
  };
  const onSizeChange = (value: number) => {
    searchParams.set(PARAMS.page, "1");
    searchParams.set(PARAMS.size, value + "");
    setSearchParams(searchParams);
  };

  const getTimesheetSyncData = async (params: {
    projectId?: number;
    companyId?: number;
    state?: string;
    sortBy?: string;
    sortType?: string;
    page?: number;
    size?: number;
  }) => {
    dispatch(setLoading(true))
    try {
      const response = await service.getTimesheetSyncData(params);
      if (response.results.length === 0 && parseInt(page) > 1) {
        searchParams.set(PARAMS.page, parseInt(page ?? "2") - 1 + "");
        setSearchParams(searchParams);
        return;
      }
      setDataTable(convertDataTable(response.results));
      setTotalRecord(response.pagination.count || 0)
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false))

    }
  };

  const convertDataTable = (data: ResultDataType[]) => {
    const result: DataTableType[] = data.map((item: ResultDataType, index: number) => {
      return {
        ...item,
        key: item.id,
        No: index + 1 + (Number(page) - 1) * Number(size),
        project: item?.project.name,
        company: item?.company.name,
        user: item?.user.fullName,
        attendanceDate: moment(item?.attendanceDate).format(COMMON.FORMAT_DATE),
        checkin: moment(item.checkin, true).isValid()
          ? moment(item.checkin).format(COMMON.FORMAT_TIME1)
          : "-",
        checkout: moment(item.checkout, true).isValid()
          ? moment(item.checkout).format(COMMON.FORMAT_TIME1)
          : "-",
        registerType: item?.registerType === REGISTER_TYPE[0].value ? REGISTER_TYPE[0].name : REGISTER_TYPE[1].name,
        syncTime: moment(item.syncTime, true).isValid()
          ? moment(item.syncTime).format(COMMON.FORMAT_TIME1)
          : "-",
        approver: item?.approver?.fullName,
        approveAt: moment(item.approveAt, true).isValid()
          ? moment(item.approveAt).format(COMMON.FORMAT_TIME1)
          : "-",
      }
    })
    return result
  }

  const funcCheckSortOrder = (
    columnName: string,
    sortType: string | null,
    sortBy: string | null,
  ): 'ASC' | 'DESC' | undefined => {
    if (columnName === sortBy) {
      return !sortType ? undefined : sortType === 'ASC' ? 'ASC' : 'DESC';
    }
  };
  const funcSortTable = (column: string, sortOrder: string) => {
    searchParams.set(PARAMS.sortBy, column);
    searchParams.set(PARAMS.sortType, sortOrder);
    setSearchParams(searchParams);
  };

  const onFilter = (filter: any) => {
    searchParams.set(PARAMS.projectId, filter.project || "");
    searchParams.set(PARAMS.companyId, filter.company || "");
    searchParams.set(PARAMS.page, DEFAULT_PARAM.page + "");
    setSearchParams(searchParams);
  };

  const onResetFilter = () => {
    searchParams.delete(PARAMS.projectId);
    searchParams.delete(PARAMS.companyId);
    searchParams.delete(PARAMS.sortBy);
    searchParams.delete(PARAMS.sortType);
    searchParams.set(PARAMS.page, COMMON.DEFAULT_PAGE + "");
    setSelectedProject("")
    setSelectedCompany("")
    setSearchParams(searchParams);
    form.resetFields()
  };

  const onCollapse = (value: boolean) => {
    searchParams.set("isCollapsed", value ? "isCollapsed" : "");
    setSearchParams(searchParams);
  };

  const handleGetProject = () => {
    const param: any = {
      page: DEFAULT_PARAM.page,
      size: DEFAULT_PARAM.size,
      state: STATE_PENDING,
    }
    if (selectedCompany) param.companyId = selectedCompany;

    setListProject([]);
    setPageProject(1);
    setSizeProject(10);
    getProjectListCCUS(param);
  };

  const handleScrollProject = (e: any) => {
    const target = e.target;

    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      setPageProject(pageProject + 1);
      const param: any = {
        state: STATE_PENDING,
        page: pageProject + 1,
        size: sizeProject,
      }
      if (selectedCompany) param.companyId = selectedCompany;

      getProjectListCCUS(param);
    }
  };

  const handleGetCompany = () => {
    const param: any = {
      page: DEFAULT_PARAM.page,
      size: DEFAULT_PARAM.size,
      state: STATE_PENDING,
    }
    if (selectedProject) param.projectId = selectedProject;
    setListCompany([]);
    setPageCompany(1);
    setSizeCompany(10);
    getDependedCompanyListCCUS(param);
  };

  const handleScrollCompany = (e: any) => {
    const target = e.target;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      setPageCompany(pageProject + 1);
      const param: any = {
        state: STATE_PENDING,
        page: pageCompany + 1,
        size: sizeCompany,
      }
      if (selectedProject) param.projectId = selectedProject;

      getDependedCompanyListCCUS(param);
    }
  };

  const getProjectListCCUS = async (params: {
    companyId?: number;
    approverId?: number;
    state?: string;
    page: number;
    size: number;
  }) => {
    setLoadingProject(true)
    try {
      const response = await service.getProjectListCCUS(params);
      if (params.page === 1) {
        setListProject(response);
      } else {
        setListProject([...listProject, ...response]);
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      setLoadingProject(false)
    }
  };

  const getDependedCompanyListCCUS = async (params: {
    projectId?: number;
    approverId?: number;
    state?: string;
    page: number;
    size: number;
  }) => {
    setLoadingCompany(true)
    try {
      const response = await service.getDependedCompanyListCCUS(params);
      if (params.page === 1) {
        setListCompany(response);
      } else {
        setListCompany([...listCompany, ...response]);
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      setLoadingCompany(false)
    }
  };

  const getSelectedKey = (values: React.Key[]) => {
    setSelectedRowsKey(values)
  };

  const handlePostCCUS = async (selectedKeys: React.Key[]) => {
    try {

      const listId: number[] = selectedKeys.map(key => Number(key));
      const response = await service.approveCCUS({ id: listId });
      SuccessNotification(
        response?.data?.message ?? NOTIFICATION_TITLE.SUCCESS
      );
      setIsRefreshCCUS05(!isRefreshCCUS05)
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  }

  const onApproveCCUS = () => {
    ModalConfirmCCUS({
      onOk: () => {
        handlePostCCUS(selectedRowsKey)
        setSelectedRowsKey([])
      },
      onCancel: () => { },
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_MODAL,
      description: MESSAGE.DESCRIPTION_APPROVE_CCUS,
      title: MESSAGE.TITLE_APPROVE_CCUS,
      iconUrl: IconSuccess
    })
  };

  const onChangeProject = (value: string) => {
    setSelectedProject(value);
  };

  const onChangeCompany = (value: string) => {
    setSelectedCompany(value);
  };

  return {
    sortType,
    sortBy,
    dataTable,
    form,
    listProject,
    listCompany,
    loadingProject,
    loadingCompany,
    totalRecord,
    selectedRowsKey,
    isRefreshCCUS05,
    onPageChange,
    onSizeChange,
    getTimesheetSyncData,
    funcCheckSortOrder,
    funcSortTable,
    onFilter,
    onResetFilter,
    onCollapse,
    getProjectListCCUS,
    getDependedCompanyListCCUS,
    handleGetProject,
    handleGetCompany,
    handleScrollProject,
    handleScrollCompany,
    getSelectedKey,
    onApproveCCUS,
    onChangeProject,
    onChangeCompany
  };
};

export default CCUS005Handler;
