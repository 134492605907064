import CM0027Api from './infrastructure/api/Api';
import CM0027Handler from './presenter/handler/Handler';
import Service from './usecase/Service';
import MainUI from './presenter/ui/UI';

import '../../../assets/styles/CM/cm024.css';
import '../../../assets/styles/CM/cm3854.css';
import '../../../assets/styles/CM/cm047.css';

type props = {
    onSubmit?: () => void;
    onReject?: () => void;
    afterSuccess?: () => void;
};

const CM0027Component = (props: props) => {
    const api = CM0027Api();
    const service = Service(api);
    const handler = CM0027Handler(service);
    return <MainUI handler={handler} {...props} />;
};

export default CM0027Component;
