import { TAX_CODE } from "../../../common/constants/COMMON";
import { OffsetCost, SummaryDataStateType } from "./entity/Entity";

const totalOffsetAmountCompany = (value: any) => {
  let result = 0;
  const offsetArray = (value?.offsetCostSummary ?? []).concat(
    value?.offsetCost ?? []
  );
  offsetArray.forEach((element: any) => {
    result += element?.value ?? 0;
  });
  return result;
};
const totalOffsetAmount = (value: any) => {
  let result = 0;
  const offsetArray = value?.offsetCost ?? [];
  offsetArray.forEach((element: any) => {
    result += element?.value ?? 0;
  });
  return result;
};

const getDataWithFormula = (outsourceCompanyData: any[]) => {
  const summaryData: SummaryDataStateType = {
    actualCost: 0,
    consumptionTax: 0,
    paymentAmountIncludingTax: 0,
  };
  const results: any[] = outsourceCompanyData.map((company: any) => {
    const formulatedProjectList = company?.projectList.map((project: any) => {
      const constructionList = project?.constructionList.map(
        (construction: any) => {
          const consumptionTax = Math.floor(
            (construction?.actualCost ?? 0) * (construction?.taxSetting ?? 1)
          );

          const paymentAmountIncludingTax =
            (construction?.actualCost ?? 0) + consumptionTax;
          return {
            ...construction,
            paymentAmountIncludingTax,
            consumptionTax,
          };
        }
      );

      const consumptionTax = Math.floor(
        (project?.actualCost ?? 0) * (company?.taxSetting ?? 1)
      ); //(Tổng thuế của các dự án ở #9.4 + 業者別相殺金額(課税)x% thuế setting
      const paymentAmountIncludingTax =
        (project?.actualCost ?? 0) + consumptionTax;
      return {
        ...project,
        constructionList: constructionList,
        paymentAmountIncludingTax,
        consumptionTax,
      };
    });

    const consumptionTax = Math.floor(
      (company?.actualCost ?? 0) * (company?.taxSetting ?? 1)
    ); //(Tổng thuế của các dự án ở #9.4 + 業者別相殺金額(課税)x% thuế setting
    const paymentAmountIncludingTax =
      (company?.actualCost ?? 0) + consumptionTax;

    summaryData.actualCost += company?.actualCost ?? 0;
    summaryData.consumptionTax += consumptionTax;
    summaryData.paymentAmountIncludingTax += paymentAmountIncludingTax;
    return {
      ...company,
      projectList: formulatedProjectList,
      paymentAmountIncludingTax,
      consumptionTax,
    };
  });
  return { data: results, summary: summaryData };
};

export { totalOffsetAmountCompany, totalOffsetAmount, getDataWithFormula };
