import React from "react";
import { Modal } from "antd";
import { ReactNode } from "react";
import "../../../assets/styles/components/confirm-modal.css";
import { LegacyButtonType } from "antd/lib/button/button";

type props = {
  onOk: () => void;
  onCancel: () => void;
  title: string;
  description: any;
  description2?: any;

  canceText: string;
  okText: string;
  icon?: ReactNode;

  width?: number;
  height?: number;
  className?: string;
  okType?: LegacyButtonType;
};

const ConfirmModalAsync = ({
  onOk,
  onCancel,
  title,
  description,
  canceText,
  okText,
  icon,
  width,
  height,
  className,
  description2,
  okType,
}: props) => {
  Modal.confirm({
    width: width ?? 479,
    style: {
      height: height ?? 272,
    },
    centered: true,
    className:
      className ??
      "confirm__modal confirm__modal-confirm confirm__modal-purple-oke ",
    title: title,
    icon: icon,
    content: (
      <>
        <div>{description}</div>
        {description2 && <div>{description2}</div>}
      </>
    ),
    okText: okText,
    okType: "danger",
    closable: true,
    cancelText: canceText,
    onOk: async () => {
      if (onOk) {
        await onOk();
      }
    },
    onCancel: () => {
      if (onCancel) {
        onCancel();
      }
    },
  });
};

export default ConfirmModalAsync;
