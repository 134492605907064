import ErrorNotification from '../../../../common/components/notification/ErrorNotification';
import { DoubleCheckPayload, UserTimeSheet } from '../entity/Entity';
import { AM040APIImpl } from './ServiceImpl';
import { NOTIFICATION_TITLE } from '../../../../common/constants/MESSAGE';
import COMMON from '../../../../common/constants/COMMON';

const Service = (api: AM040APIImpl) => {
    const getAttendanceUserTimeSheet = async (param: {
        date: string;
        constructionId?: string;
        contractorConstructionId?: string;
        userId: string;
    }): Promise<UserTimeSheet[]> => {
        try {
            const response = await api.getAttendanceUserTimeSheet({
                ...param,
                timeOffset: COMMON.TIME_ZONE,
            });
            const { results } = response.data;
            return results || [];
        } catch (error: any) {
            return [];
        }
    };

    const postAttendanceDoubleCheck = async (data: DoubleCheckPayload[]): Promise<any> => {
        try {
            return await api.postAttendanceDoubleCheck(data);
        } catch (error: any) {
            throw error;
        }
    };

    return {
        getAttendanceUserTimeSheet,
        postAttendanceDoubleCheck,
    };
};

export default Service;
