import { CM0048APIImpl } from './ServiceImpl';
import {
    ReMaterialListData,
    BaseResponseType,
    ReOutsourceListData,
    ReLaborListData,
    ReIndirectListData,
    MoneyRejectCostPayload,
    MoneyApproveCostPayload,
} from '../entity/Entity';
import ErrorNotification from '../../../../../common/components/notification/ErrorNotification';
import { NOTIFICATION_TITLE } from '../../../../../common/constants/MESSAGE';

const CM0048Service = (api: CM0048APIImpl) => {
    const getReMaterialList = async (): Promise<ReMaterialListData | null> => {
        try {
            const response = await api.getReMaterialList();
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };
    const getReOutsourceList = async (): Promise<ReOutsourceListData | null> => {
        try {
            const response = await api.getReOutsourceList();
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };
    const getReLaborList = async (): Promise<ReLaborListData | null> => {
        try {
            const response = await api.getReLaborList();
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };
    const getReIndirectList = async (): Promise<ReIndirectListData | null> => {
        try {
            const response = await api.getReIndirectList();
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };

    const putMoneyRejectCost = async (
        payload: MoneyRejectCostPayload,
    ): Promise<BaseResponseType | null> => {
        try {
            const response = await api.putMoneyRejectCost(payload);
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };
    const putMoneyApproveCost = async (
        payload: MoneyApproveCostPayload,
    ): Promise<BaseResponseType | null> => {
        try {
            const response = await api.putMoneyApproveCost(payload);
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };

    return {
        getReMaterialList,
        getReOutsourceList,
        getReLaborList,
        getReIndirectList,
        putMoneyRejectCost,
        putMoneyApproveCost,
    };
};

export default CM0048Service;
