import {
  MoneyReceivedMoneyEditPayload,
  MoneyReceivedMoneyOffset,
  MoneyReceivedMoneyOffsetList,
  MoneyReceivedMoneyOffsetListResults,
  OffsetDataExport,
  MoneyReceiveMoneyOffsetListResultsData,
} from "./../../entity/Entity";
import { RootState } from "./../../../../../store";
import { useSelector } from "react-redux";
// Built-in
import React, { useState } from "react";

// 3rd party lib
import moment from "moment";
import { Form } from "antd";
import { useDispatch } from "react-redux";

// Source files
import { setLoading } from "../../../../../common/slice/CommonSlice";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import ConfirmModalAsync from "../../../../../common/components/modal/ConfirmModalAsync";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import { HistoryPaymentServiceImpl } from "../../usecase/ServiceImpl";
import {
  MoneyReceivedMoneyCreatePayload,
  MoneyReceivedMoneyDelete,
  MoneyReceivedMoneyListResultsData,
  MoneyReceivedMoneyListResultsRecord,
  ReceiveMoneyOffset,
} from "../../entity/Entity";
import ConfirmModal from "../../../../../common/components/modal/ConfirmModal";
import {
  deleteDataLocalCM0063,
  setIsVisibleCM0063,
  setParentCodeCM0063,
} from "../../../CM0063/presenter/slice/Slice";
import COMMON, {
  STATUS_CM_CHILD_TABLE,
} from "../../../../../common/constants/COMMON";
import { MoneySubmitReceiveMoneyPayload } from "../../../CM004/entity/Entity";
import { setIsEnable } from "../../../CM004/presenter/slice/Slice";
import { refreshCM005, setEvidenceLink, setEvidenceName } from "../slice/Slice";
import helpers from "../../../../../common/helpers/common";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import { showImageViewerModal } from "../../../../../common/components/image-viewer-modal/ImageViewSlice";
import { convertDataLocalToReceiveMoneyOffset } from "../../helper";
import { showPdfViewerModal } from "../../../../../common/components/pdf-viewer-modal/pdfViewerModalSlice";
import exportFileDataCM005 from "../../../../../common/helpers/export-excel/CM005";
import {
  setDocumentView,
  setIsEdit,
} from "../../../../../common/components/modal-approve-view/presenter/slice/Slice";
import { setDocumentEdit } from "../../../../../common/components/modal-approve-edit/presenter/slice/Slice";
import { useSearchParams } from "react-router-dom";
import { doExportForResponse } from "../../../../../common/helpers/exports/common";

const SCREEN_NAME = "CM005";

const HistoryPaymentHandler = (service: HistoryPaymentServiceImpl) => {
  const [form] = Form.useForm();

  const [searchParams] = useSearchParams();
  const projectName = searchParams.get("projectName");

  const dispatch = useDispatch();
  const [data, setData] = useState<MoneyReceivedMoneyListResultsData[]>([]);
  const [originalData, setOriginalData] = useState<
    MoneyReceivedMoneyListResultsData[]
  >([]);
  const [editingKeys, setEditingKeys] = useState<any[]>([]);
  const [newLines, setNewLines] = useState<any[]>([]);
  const [newCount, setNewCount] = useState<number>(0);
  const [saveKeys, setSaveKeys] = useState<any[]>([]);
  const dataLocal = useSelector((state: RootState) => state.cm0063.dataLocal);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedList, setCheckedList] = useState<any[]>([]);

  const constructionInfo = useSelector(
    (state: RootState) => state.cm004.constructionInfo
  );
  const moneySummaryPlanCostResponse = useSelector(
    (state: RootState) => state.cm005.moneySummaryPlanCostResponse
  );
  const moneyConstructionWorkAmountCompletedResult = useSelector(
    (state: RootState) => state.cm005.moneyConstructionWorkAmountCompletedResult
  );

  const screenName = COMMON.CM_FILE_STRUCTURE.SCREEN_NAME;
  const tab1 = COMMON.CM_FILE_STRUCTURE.PARENT_TAB.TAB_2.NAME;
  const submitDate = moment().format(COMMON.FORMAT_DATE_SUBMIT);
  const fileName: string = `${screenName}_${tab1}_${submitDate}`;

  const isEnableSaveButton = (record: any): boolean =>
    saveKeys.some((element) => element === record.key + "");

  const checkEnableButtons = (changedValues: any, values: any) => {
    const recordChangedValue = changedValues[Object.keys(changedValues)[0]];
    const changedValue = recordChangedValue[Object.keys(recordChangedValue)[0]];
    const recordKeyName = Object.keys(changedValues)[0]?.replaceAll(
      "record",
      ""
    );
    if (changedValue !== undefined && changedValue !== null) {
      // find in saveKeys
      const foundKey = saveKeys.find((key: any) => {
        return recordKeyName === key;
      });
      if (foundKey) return;
      setSaveKeys([...saveKeys, recordKeyName]);
    } else {
      const changedValueObj = values[Object.keys(changedValues)[0]];
      const isAllUndefined = Object.values(changedValueObj).every(
        (x) => x === undefined || x === null
      );
      if (isAllUndefined) {
        const newSaveKeys = saveKeys.filter(
          (key: any) => key !== recordKeyName
        );
        setSaveKeys(newSaveKeys);
      } else {
        const foundKey = saveKeys.find((key: any) => {
          return recordKeyName === key;
        });
        if (foundKey) return;
        setSaveKeys([...saveKeys, recordKeyName]);
      }
    }
  };

  const afterClearEvidence = (formName: any) => {
    const evidenceChangeValue = form.getFieldValue(formName[0]);
    const recordKeyName = formName[0]?.replaceAll("record", "");

    const isAllUndefined = Object.values(evidenceChangeValue).every(
      (x) => x === undefined || x === null
    );
    if (isAllUndefined) {
      const newSaveKeys = saveKeys.filter((key: any) => key !== recordKeyName);
      setSaveKeys(newSaveKeys);
    } else {
      const foundKey = saveKeys.find((key: any) => {
        return recordKeyName === key;
      });
      if (foundKey) return;
      setSaveKeys([...saveKeys, recordKeyName]);
    }
  };
  const putMoneySubmitReceiveMoney = async (): Promise<any> => {
    ConfirmModalAsync({
      onOk: async () => {
        try {
          dispatch(setLoading(true));

          // Tên màn_Tên tab_năm_tháng_ngày submit
          const listId: number[] = [];
          checkedList.forEach((element) => {
            listId.push(element);
          });

          const payload: MoneySubmitReceiveMoneyPayload = {
            fileName: `${projectName}_${
              constructionInfo?.constructionName ?? ""
            }_${constructionInfo?.orderingCompanyName ?? ""}_${fileName}`,
            listId,
          };
          const response = await service.putMoneySubmitReceiveMoney(payload);
          setCheckedList([]);
          dispatch(refreshCM005());
          SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
        } catch (error: any) {
          ErrorNotification(NOTIFICATION_TITLE.ERROR);
        } finally {
          dispatch(setLoading(false));
        }
      },
      onCancel: () => {},
      className: "confirm__modal confirm__modal-purple-oke",
      title: MESSAGE.MESSAGE_TITLE_BEFORE_CREATE,
      description: MESSAGE.MESSAGE_DESCRIPTION_CR_8_1,
      description2: MESSAGE.MESSAGE_DESCRIPTION_COMMON_QUESTION_2,

      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_MODAL_2,
    });
  };

  /*
    Hiển thị: 提出
    Điều kiện enable button: Tồn tại record ở mục #8.3 có trạng thái 未承認
    Click button sẽ submit thông tin #8.3 thành 1 file tài liệu để gửi lên cấp trên duyệt
    Click button sẽ hiển thị popup confirm, thực hiện submit sẽ hiển thị message submit thành công giống màn CR001
    Tài liệu được lưu ở màn CM0034
    Sau khi submit thì chuyển trạng thái của record chưa submit ở mục #8.3 từ 未承認 sang 承認待ち
    Tên file submit đặt theo format: Tên màn_Tên tab_năm_tháng_ngày submit                                                                               "											
    */
  const getMoneyReceivedMoneyList = async (params: {
    constructionId: number;
    page?: number;
    size?: number;
    sortBy?: string;
    sortType?: string;
    from?: string;
    to?: string;
  }): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const responseData = await service.getMoneyReceivedMoneyList(params);
      let finalResult: MoneyReceivedMoneyListResultsData[] = [];
      finalResult = finalResult.concat(responseData?.results ?? []);

      // check to enable submit button - this screen is always enable
      // let isEnableSubmit: boolean = true;
      // dispatch(setIsEnable(isEnableSubmit));

      // using deep copy to avoid 2 state aim the same memory address
      setData(JSON.parse(JSON.stringify(finalResult)));
      setOriginalData(JSON.parse(JSON.stringify(finalResult)));
      /*
            14/04/2023
            Hiển thị: 提出
            Điều kiện enable button: Tồn tại record ở mục #8.3 có trạng thái 未承認
            Click button sẽ submit thông tin #8.3 thành 1 file tài liệu để gửi lên cấp trên duyệt
            Click button sẽ hiển thị popup confirm, thực hiện submit sẽ hiển thị message submit thành công giống màn CR001
            (Thay nội dung message confirm thành: 資料を提出してもよろしいですか？)
            Tài liệu được lưu ở màn CM0034
            Sau khi submit thì chuyển trạng thái của record chưa submit ở mục #8.3 từ 未承認 sang 承認待ち
            Tên file submit đặt theo format: Tên màn_Tên tab_năm_tháng_ngày submit                                                                               "											
            */
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };
  const checkIsEnableSubmitButton = () => {
    const isHavingMoneyNotApproved = data.some(
      (element) =>
        element.status.code === STATUS_CM_CHILD_TABLE.MONEY_NOT_APPROVED.CODE ||
        element.status.code === STATUS_CM_CHILD_TABLE.MONEY_REJECT.CODE
    );
    if (isHavingMoneyNotApproved && checkedList?.length > 0)
      dispatch(setIsEnable(true));
    else {
      dispatch(setIsEnable(false));
    }
  };
  const onDataLocalCM0063Change = (dataLocal: any) => {
    // new data
    const newDataTouched: any[] = [];
    const newNewLines = newLines.map((element) => {
      const foundData = dataLocal.find(
        (item: any) => item.parentRecordId == element.id
      );
      let total = 0;
      if (foundData) {
        total = foundData.arr.reduce(function (acc: any, obj: any) {
          return acc + obj.offsetAmount;
        }, 0);
        if (foundData?.arr?.length > 0) newDataTouched.push(element.id);
        return { ...element, offsetMoney: total };
      }
      return element;
    });
    const newSaveKeys = [...new Set([...saveKeys, ...newDataTouched])];
    setSaveKeys(newSaveKeys);
    setNewLines(newNewLines);

    // old data
    const newData = data.map((element) => {
      const foundData = dataLocal.find(
        (item: any) => item.parentRecordId == element.id
      );
      let total = 0;
      if (foundData) {
        total = foundData.arr.reduce(function (acc: any, obj: any) {
          return acc + obj.offsetAmount;
        }, 0);
        return { ...element, offsetMoney: total };
      }
      return element;
    });
    setData(newData);
  };

  const onValueNumberChange = (record: any, property: string, e: any) => {
    const value = e;
    const toNumber = Number(value);

    if (String(record.id).includes("new")) {
      // new data
      const newNewLines = newLines.map((element) => {
        if (element.id === record.id)
          element[`${property}`] = isNaN(toNumber) ? 0 : toNumber;
        return element;
      });
      setNewLines(newNewLines);
    } else {
      // old data
      const newData = data.map((element) => {
        if (element.id === record.id)
          (element as any)[`${property}`] = isNaN(toNumber) ? 0 : toNumber;
        return element;
      });
      setData(newData);
    }
  };

  const addNewLine = (constructionId: string | number) => {
    const newLineKey = `new${newCount}${constructionId}`;
    const newLinesData: MoneyReceivedMoneyListResultsRecord = {
      key: newLineKey,
      id: newLineKey,
    };
    setNewLines([...newLines, newLinesData]);
    setEditingKeys([...editingKeys, newLineKey]);
    setNewCount(newCount + 1);
  };

  const isEditing = (record: any): boolean =>
    editingKeys.some((element) => element === record.key);

  const edit = (record: any) => {
    setEditingKeys([...editingKeys, record.key]);
    form.setFields([
      {
        name: [`record${record.id}`, `targetPeriod`],
        value: [
          record.startDate ? moment(record.startDate) : undefined,
          record.endDate ? moment(record.endDate) : undefined,
        ],
      },
      {
        name: [`record${record.id}`, `receiveAt`],
        value: record.receiveAt ? moment(record.receiveAt) : undefined,
      },
      {
        name: [`record${record.id}`, `planReceiveMoney`],
        value: record.planReceiveMoney,
      },
      {
        name: [`record${record.id}`, `receiveMoney`],
        value: record.receiveMoney,
      },
      {
        name: [`record${record.id}`, `contractorReceiveMoney`],
        value: record.contractorReceiveMoney,
      },
      {
        name: [`record${record.id}`, `evidence`],
        value: [
          {
            name: record.evidence,
            uid: Math.random(),
            key: Math.random(),
          },
        ],
      },
    ]);
  };

  const getMoneyReceivedMoneyOffsetList = async (params: {
    receiveMoneyId: number;
  }): Promise<ReceiveMoneyOffset[]> => {
    try {
      let page = COMMON.DEFAULT_PAGE;
      let size = COMMON.DEFAULT_SIZE;

      const response = await service.getMoneyReceivedMoneyOffsetList({
        receiveMoneyId: params.receiveMoneyId,
        page,
        size,
      });

      let finalResult: MoneyReceiveMoneyOffsetListResultsData[] =
        response?.results?.data ?? [];
      let numPages = response.pagination.numPages;
      for (let i = 2; i <= numPages; i++) {
        const response = await service.getMoneyReceivedMoneyOffsetList({
          receiveMoneyId: params.receiveMoneyId,
          page: i,
          size,
        });
        finalResult = finalResult.concat(response?.results?.data ?? []);
      }
      const result: ReceiveMoneyOffset[] = finalResult.map(
        (element: MoneyReceiveMoneyOffsetListResultsData) => {
          const receiveMoneyOffset: ReceiveMoneyOffset = {
            categoryId: element.category.id,
            name: element.name,
            value: element.value,
          };
          return receiveMoneyOffset;
        }
      );
      return result;
    } catch (error: any) {
      throw error;
    }
  };

  const afterPostPutSuccess = async (key: any) => {
    const filteredKeys = editingKeys.filter((element: any) => element !== key);
    setEditingKeys(filteredKeys);
    dispatch(refreshCM005());
  };

  const saveRecord = async (record: any, constructionId: number) => {
    try {
      dispatch(setLoading(true));
      const recordFormValue = form.getFieldValue(`record${record.id}`);

      let targetPeriod,
        receiveAt,
        planReceiveMoney,
        receiveMoney,
        contractorReceiveMoney,
        evidence;
      if (recordFormValue) {
        targetPeriod = recordFormValue.targetPeriod;
        receiveAt = recordFormValue.receiveAt;
        planReceiveMoney = recordFormValue.planReceiveMoney;
        receiveMoney = recordFormValue.receiveMoney;
        contractorReceiveMoney = recordFormValue.contractorReceiveMoney;
        evidence = recordFormValue.evidence;
      }

      const startDate =
        targetPeriod && targetPeriod[0]
          ? helpers.getFilterTime(targetPeriod[0])
          : null; // blank case
      const endDate =
        targetPeriod && targetPeriod[1]
          ? helpers.getFilterTimeTo(targetPeriod[1])
          : null; // blank case

      const foundDataLocal = dataLocal.find(
        (item) => item.parentRecordId == record.id
      );
      if (String(record.id).includes("new")) {
        const receiveMoneyOffset: ReceiveMoneyOffset[] = foundDataLocal
          ? convertDataLocalToReceiveMoneyOffset(foundDataLocal)
          : [];

        const payload: MoneyReceivedMoneyCreatePayload = {
          data: [
            {
              id: 0,
              constructionId: constructionId,
              contractorReceiveMoney: contractorReceiveMoney,
              documentPath: "",
              startDate: startDate, // blank case
              endDate: endDate, // blank case
              evidence: evidence?.file?.name ? evidence.file.name : "",
              planReceiveMoney: planReceiveMoney,
              receiveAt: receiveAt ? helpers.getFilterTime(receiveAt) : null,
              receiveMoney: receiveMoney,
              receiveMoneyOffset,
            },
          ],
        };
        const response = await service.postMoneyReceivedMoneyCreate(payload);
        if (response && response.status === `${COMMON.HTTP_STATUS_OK}`) {
          if (evidence?.file?.originFileObj) {
            const companyId = JSON.parse(localStorage.getItem("user") ?? `{}`)
              ?.company?.id;
            const linkPreSignUpload = await getLinkPresignUpload({
              keyName: `re/${companyId}/${SCREEN_NAME}/${response.results}/${evidence?.file?.name}`,
            });
            await uploadTos3(linkPreSignUpload, evidence.file?.originFileObj);
          }
          // remove from new lines
          const filterNewLines = newLines.filter(
            (element: any) => element.key !== record.key
          );
          setNewLines(filterNewLines);
          SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
          const checkedData = checkedList.filter(
            (element: any) => element !== record.key
          );
          setCheckedList(checkedData);
          setCheckAll(false);
          return await afterPostPutSuccess(record.key);
        } else ErrorNotification(NOTIFICATION_TITLE.ERROR);
      } else {
        // update
        const receiveMoneyOffset: ReceiveMoneyOffset[] = foundDataLocal
          ? convertDataLocalToReceiveMoneyOffset(foundDataLocal)
          : await getMoneyReceivedMoneyOffsetList({
              receiveMoneyId: record.id,
            });

        const payload: MoneyReceivedMoneyEditPayload = {
          id: record.id,
          constructionId: constructionId,
          contractorReceiveMoney: contractorReceiveMoney,
          documentPath: "",
          endDate: endDate, // blank case
          startDate: startDate, // blank case
          evidence: evidence?.file?.name ? evidence.file.name : "",
          planReceiveMoney: planReceiveMoney,
          receiveAt: receiveAt ? helpers.getFilterTime(receiveAt) : null,
          receiveMoney: receiveMoney,
          receiveMoneyOffset,
          note: record?.note ?? "",
        };
        if (
          record?.status?.code === STATUS_CM_CHILD_TABLE.MONEY_APPROVED.CODE
        ) {
          dispatch(setDocumentEdit(payload));
          return;
        }
        const response = await service.putMoneyReceivedMoneyEdit(payload);
        if (response && response.status === `${COMMON.HTTP_STATUS_OK}`) {
          if (evidence?.file?.originFileObj) {
            const companyId = JSON.parse(localStorage.getItem("user") ?? `{}`)
              ?.company?.id;
            const linkPreSignUpload = await getLinkPresignUpload({
              keyName: `re/${companyId}/${SCREEN_NAME}/${record.id}/${evidence?.file?.name}`,
            });
            await uploadTos3(linkPreSignUpload, evidence.file?.originFileObj);
          }
          SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
          const checkedData = checkedList.filter(
            (element: any) => element !== record.key
          );

          setCheckedList(checkedData);
          setCheckAll(false);
          return await afterPostPutSuccess(record.key);
        } else ErrorNotification(NOTIFICATION_TITLE.ERROR);
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const updateOnNoteModal = async (payload: any) => {
    const response = await service.putMoneyReceivedMoneyEdit(payload);
    if (response && response.status === `${COMMON.HTTP_STATUS_OK}`) {
      if (payload.evidence?.file?.originFileObj) {
        const companyId = JSON.parse(localStorage.getItem("user") ?? `{}`)
          ?.company?.id;
        const linkPreSignUpload = await getLinkPresignUpload({
          keyName: `re/${companyId}/${SCREEN_NAME}/${payload.id}/${payload.evidence?.file?.name}`,
        });
        await uploadTos3(
          linkPreSignUpload,
          payload.evidence.file?.originFileObj
        );
      }
      await afterPostPutSuccess(payload.id);
      SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
    } else throw new Error(NOTIFICATION_TITLE.ERROR);
  };
  const viewApproveModal = async (record: any, constructionId: number) => {
    const foundDataLocal = dataLocal.find(
      (item) => item.parentRecordId == record.id
    );
    // update
    const receiveMoneyOffset: ReceiveMoneyOffset[] = foundDataLocal
      ? convertDataLocalToReceiveMoneyOffset(foundDataLocal)
      : await getMoneyReceivedMoneyOffsetList({
          receiveMoneyId: record.id,
        });

    const payload: MoneyReceivedMoneyEditPayload = {
      id: record.id,
      constructionId: constructionId,
      contractorReceiveMoney: record.contractorReceiveMoney,
      documentPath: record.documentPath,
      endDate: record.endDate, // blank case
      startDate: record.startDate, // blank case
      evidence: record.evidence,
      planReceiveMoney: record.planReceiveMoney,
      receiveAt: record.receiveAt,
      receiveMoney: record.receiveMoney,
      receiveMoneyOffset,
      note: record.note,
    };
    dispatch(
      setDocumentView({
        ...payload,
        status: record.status,
      })
    );
  };

  const onCancelRecord = (record: any) => {
    if (String(record.id).includes("new")) {
      const calcelNewLines = newLines.filter(
        (element) => element.id !== record.id
      );
      setNewLines(calcelNewLines);
    } else {
      // case update
      const originalRecord = originalData.find(
        (element) => element.id === record.id
      );
      if (originalRecord) {
        const cancelEditData: MoneyReceivedMoneyListResultsData[] = data.map(
          (element) => {
            if (originalRecord.id === element.id) return originalRecord;
            return element;
          }
        );
        setData(JSON.parse(JSON.stringify(cancelEditData)));
      }
    }
    const filteredKeys = editingKeys.filter(
      (element: any) => element !== record.key
    );
    setEditingKeys(filteredKeys);
    dispatch(deleteDataLocalCM0063(record.id));
  };

  const cancelOperation = (record: any) => {
    // check if user has change input

    const isTouchedTargetPeriod = form.isFieldsTouched([
      `record${record.id}`,
      `targetPeriod`,
    ]);
    const isTouchedReceiveAt = form.isFieldsTouched([
      `record${record.id}`,
      `receiveAt`,
    ]);
    const isTouchedPlanReceiveMoney = form.isFieldsTouched([
      `record${record.id}`,
      `planReceiveMoney`,
    ]);
    const isTouchedReceiveMoney = form.isFieldsTouched([
      `record${record.id}`,
      `receiveMoney`,
    ]);
    const isTouchedContractorReceiveMoney = form.isFieldsTouched([
      `record${record.id}`,
      `contractorReceiveMoney`,
    ]);

    const isRecordTouched = [
      isTouchedTargetPeriod,
      isTouchedReceiveAt,
      isTouchedPlanReceiveMoney,
      isTouchedReceiveMoney,
      isTouchedContractorReceiveMoney,
    ].some((element) => element === true);

    if (isRecordTouched) {
      ConfirmModal({
        onOk: () => onCancelRecord(record),
        onCancel: () => {},
        title: MESSAGE.MESSAGE_020,
        description: MESSAGE.MESSAGE_022_1,
        extraDescription: MESSAGE.MESSAGE_022_2,
        canceText: LABEL_MESSAGE.CANCEL_MODAL,
        okText: LABEL_MESSAGE.OK_MODAL,
      });
    } else onCancelRecord(record);
    // case new
  };

  const deleteRecord = async (value: any) => {
    ConfirmModalAsync({
      onOk: async () => {
        try {
          dispatch(setLoading(true));
          // call api delete
          const payload: MoneyReceivedMoneyDelete = {
            id: [value.id],
          };
          const responseData = await service.deleteMoneyReceivedMoneyDelete(
            payload
          );
          if (responseData) {
            // delete local
            const newData = data.filter((element) => element.id !== value.id);
            setData(newData);
            dispatch(refreshCM005());
            SuccessNotification(
              responseData?.message ?? NOTIFICATION_TITLE.SUCCESS
            );
          }
        } catch (error: any) {
          ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        } finally {
          dispatch(setLoading(false));
        }
      },
      className: "confirm__modal",
      onCancel: () => {},
      title: MESSAGE.MESSAGE_MODAL_DELETE_TITLE,
      description: MESSAGE.MESSAGE_DESCRIPTION_CR_7,
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_DELETE,
    });
  };

  const viewDetail = (record: any) => {
    const { MONEY_REJECT, MONEY_NOT_APPROVED, MONEY_RETRIEVE, MONEY_APPROVED } =
      STATUS_CM_CHILD_TABLE;
    const parentCode = {
      parentRecordId: record.id,
      status: isEditing(record)
        ? [
            MONEY_REJECT.CODE,
            MONEY_NOT_APPROVED.CODE,
            MONEY_RETRIEVE.CODE,
            MONEY_APPROVED.CODE,
          ].includes(record?.status?.code ?? "")
        : false,
    };
    dispatch(setParentCodeCM0063(parentCode));
    dispatch(setIsVisibleCM0063(true));
  };

  const getLinkPresignUpload = async (params: any) => {
    try {
      const response = await service.getLinkPresignUpload(params);
      if (response) return response.results;
    } catch (error: any) {
      throw error;
    }
  };

  const uploadTos3 = async (url: any, data: any) => {
    try {
      const response = await service.uploadToS3(url, data);
      return response.results;
    } catch (error: any) {
      throw error;
    }
  };

  const handleViewEvidence = async (record: any) => {
    try {
      dispatch(setLoading(true));
      const companyId = helpers.getCompanyIdFromLocalStorage();
      if (!companyId) return ErrorNotification(NOTIFICATION_TITLE.ERROR);
      const response = await service.getPresignLinkS3({
        keyName: `re/${companyId}/${SCREEN_NAME}/${record.id}/${record.evidence}`,
      });
      dispatch(setEvidenceLink(response.results));
      const checkFile = record.evidence?.split(".").pop();
      const evidenceName = record.evidence?.split(".").slice(0, -1).join(".");
      dispatch(setEvidenceName(evidenceName));
      if (checkFile === "pdf") {
        dispatch(showPdfViewerModal());
      } else dispatch(showImageViewerModal());
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getMoneyReceivedMoneyOffsetListForExport = async (
    receiveMoneyId: number
  ): Promise<MoneyReceivedMoneyOffsetList[]> => {
    try {
      let finalResults: MoneyReceivedMoneyOffsetList[] = [];

      let page = 1;
      const size = 100;
      let numPages = 1;
      const responseData = await service.getMoneyReceivedMoneyOffsetList({
        receiveMoneyId: receiveMoneyId,
        page,
        size,
      });
      finalResults.push(responseData);
      numPages = responseData.pagination.numPages;
      if (numPages <= 1) return finalResults;
      const pageArr = Array.from({ length: numPages - 1 }, (_, i) => i + 2);

      const promiseAllData = await Promise.all(
        pageArr.map((element) =>
          service.getMoneyReceivedMoneyOffsetList({
            receiveMoneyId: 1,
            page: element,
            size,
          })
        )
      );
      return finalResults.concat(promiseAllData);
    } catch (error: any) {
      return [];
    }
  };

  const exportFile = async (from: string, to: string, projectName: string) => {
    try {
      dispatch(setLoading(true));
      const response = await service.doExportCM005({
        constructionId: constructionInfo?.constructionId,
        from: from ? helpers.getFilterTime(from) : undefined,
        to: to ? helpers.getFilterTimeTo(to) : undefined,
        projectName: projectName,
      } as any);
      doExportForResponse(response, `${screenName}_${tab1}_${submitDate}.xlsx`);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const onCheckAllChange = (checked: boolean) => {
    setCheckAll(checked);
    if (checked) {
      const checkedItems: any[] = [];
      for (let element of data) {
        if(!(element?.status?.code ===
          STATUS_CM_CHILD_TABLE.MONEY_APPROVED.CODE ||
          element?.status?.code ===
          STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE ||
          element?.status?.code === STATUS_CM_CHILD_TABLE.MONEY_REJECT.CODE ||
          element?.status?.code ===
          STATUS_CM_CHILD_TABLE.MONEY_RETRIEVE.CODE))
        checkedItems.push(element.id);
      }
      setCheckedList(checkedItems);
    } else setCheckedList([]);
  };

  const isCheckbox = (record: any): boolean =>
    checkedList.some((element) => element === record.key);

  const onCheckboxChange = (record: any, checked: boolean) => {

    // setCheckedList(list);
    let newCheckList: any[] = [];

    if (checked) {
      newCheckList = [...checkedList, record.key];
    } else {
      const checkedData = checkedList.filter(
        (element: any) => element !== record.key
      );
      newCheckList = checkedData;
    }
    const possibleItems: any[] = [];

    for (let element of data) {
      if (
        element?.status?.code === STATUS_CM_CHILD_TABLE.MONEY_NOT_APPROVED.CODE
      )
        possibleItems.push(element.id);
    }
    setCheckedList(newCheckList);

    setCheckAll(newCheckList.length === possibleItems.length);
  };

  return {
    form,
    editingKeys,
    data,
    newLines,
    saveKeys,
    checkAll,
    checkedList,
    isEditing,
    edit,
    saveRecord,
    cancelOperation,
    addNewLine,
    onValueNumberChange,
    deleteRecord,
    getMoneyReceivedMoneyList,
    viewDetail,
    putMoneySubmitReceiveMoney,
    onDataLocalCM0063Change,
    handleViewEvidence,
    checkEnableButtons,
    isEnableSaveButton,
    afterClearEvidence,
    exportFile,
    onCheckAllChange,
    isCheckbox,
    onCheckboxChange,
    viewApproveModal,
    updateOnNoteModal,
    checkIsEnableSubmitButton,
  };
};

export default HistoryPaymentHandler;
