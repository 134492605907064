import ErrorNotification from "../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../common/constants/MESSAGE";
import { DB002Api } from "./ServiceImpl";

const DM002Service = (api: DB002Api) => {
  const getReMoneyMonthly = async (params: {
    projectId: number;
    from: string;
    to: string;
  }) => {
    try {
      const response = await api.getReMoneyMonthly(params);
      return response?.data;
    } catch (error: any) {
      ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
      return null;
    }
  };

  const getProjectSummaryCost = async (params: {
    projectId: number;
    from: string;
    to: string;
  }) => {
    try {
      const response = await api.getProjectSummaryCost(params);
      return response?.data;
    } catch (error: any) {
      ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
      return null;
    }
  };

  const getProjectSummaryReceivedMoney = async (params: {
    projectId: number;
    from: string;
    to: string;
  }) => {
    try {
      const response = await api.getProjectSummaryReceivedMoney(params);
      return response?.data;
    } catch (error: any) {
      ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
      return null;
    }
  };
  return {
    getReMoneyMonthly,
    getProjectSummaryCost,
    getProjectSummaryReceivedMoney,
  };
};

export default DM002Service;
