import { Row, Col, Form, Input, Button, Menu, Dropdown } from "antd";
import BreadcrumbComponent from "../../../../../common/components/breadcrumb/BreadcrumbComponent";
import BREADCRUMB from "../../../../../common/constants/BREADCRUMB";

// img
import SearchSvg from "../../../../../assets/icons/search-icon.svg";
import PlusFullWhite from "../../../../../assets/icons/plus-full-white.svg";
import Filter2 from "../../../../../assets/icons/Filter-2.svg";
import DeleteRed from "../../../../../assets/icons/delete-red.svg";

import { OM002Header } from "../handler/HandlerImpl";

import { useSearchParams } from "react-router-dom";
import "../../../../../assets/styles/OM/OM002.css";
import {
  AUTHORIZATION_CODE,
  SYSTEM_ROLES,
} from "../../../../../common/constants/AUTHORIZATION";
import { RootState } from "../../../../../store";
import { useSelector } from "react-redux";
import { PLAN_TYPE } from "../../../../../common/constants/COMMON";

type Props = {
  handler: OM002Header;
};

const getMenuRoleFiler = (
  arr: any[],
  roleId: string | null,
  onFilterRole: Function
) => {
  const results: any[] = [];
  arr.forEach((element: any) => {
    results.push({
      key: element.id + "",
      label: (
        <div
          onClick={() => onFilterRole(element.id + "")}
          className={`${
            roleId && roleId === element.id + ""
              ? "dropdown-item-chosen"
              : "dropdown-item"
          } center-item `}
        >
          {element.name}
        </div>
      ),
    });
  });
  return results;
};

const OM002HeaderComponent = ({ handler }: Props) => {
  const [searchParams] = useSearchParams();
  const roleId = searchParams.get("roleId");
  const groupId = searchParams.get("groupId") || "";
  const permission = useSelector(
    (state: RootState) => state.auth.authData?.permission
  );
  const isRoleAdmin = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdmin
  );

  const isRoleAdminSystem = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdminSystem
  );
  const isOrderBasic =
    useSelector((state: RootState) => state.auth.authData?.order?.code) ===
    PLAN_TYPE.PLAN_BASIC;
  return (
    <>
      <Row className="om002-header-container">
        <Col span={4}>
          <BreadcrumbComponent data={BREADCRUMB.OM002} />
          <div className="header-row-title" style={{ marginTop: 8 }}>
            部門・メンバー
          </div>
        </Col>
        <Col span={10}>
          <div className="vertical-center ">
            <Form form={handler.form}>
              <Form.Item name="text" style={{ margin: 0, padding: 0 }}>
                <Input
                  size="large"
                  prefix={<img alt="" src={SearchSvg} />}
                  placeholder="検索したい名前を入力してください" // CR #344212
                  className="search-bar-om001 w-100-percent"
                  onChange={handler.onSearch}
                />
              </Form.Item>
            </Form>
          </div>
        </Col>
        <Col span={10}>
          <div
            className="vertical-center"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            {permission?.includes(AUTHORIZATION_CODE.M12) ||
            isRoleAdmin ||
            isRoleAdminSystem ? (
              <>
                <Button
                  onClick={() => handler.createMember(parseInt(groupId))}
                  size="large"
                  style={{ marginRight: 16 }}
                  className="button-E9EDF2 "
                >
                  メンバー追加
                </Button>
                <Button
                  size="large"
                  icon={
                    <img
                      alt=""
                      src={PlusFullWhite}
                      style={{ marginRight: 9 }}
                    />
                  }
                  style={{ marginRight: 16 }}
                  type="primary"
                  onClick={() => handler.redirectToOM005(groupId)}
                >
                  一括メンバー追加
                </Button>
                <Button
                  onClick={handler.deleteManyMember}
                  size="large"
                  icon={
                    <img
                      alt=""
                      src={DeleteRed}
                      style={{ marginRight: 9, marginBottom: 0 }}
                    />
                  }
                  style={{ width: 162 }}
                  className="button-E9EDF2-red-border "
                  disabled={handler.chosenRecord.length === 0}
                >
                  メンバー削除
                </Button>
              </>
            ) : (
              <div></div>
            )}
            {isOrderBasic ? (
              <Dropdown
                overlayStyle={{
                  width: 180,
                  paddingRight: 0,
                  paddingLeft: 0,
                  marginRight: 0,
                  marginLeft: 0,
                }}
                overlay={
                  <Menu
                    style={{ maxHeight: 500, overflow: "auto" }}
                    selectable
                    selectedKeys={roleId ? [roleId] : ["0"]}
                    items={getMenuRoleFiler(
                      handler.filterRole,
                      roleId,
                      handler.onFilterRole
                    )}
                  />
                }
                placement="bottomLeft"
                arrow
              >
                <Button
                  size="large"
                  style={{ marginLeft: 16, width: 42 }}
                  className="button-E9EDF2-no-border"
                  icon={<img alt="" src={Filter2} />}
                ></Button>
              </Dropdown>
            ) : (
              <></>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default OM002HeaderComponent;
