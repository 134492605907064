import React from "react";
import { CM0033Handler } from "../handler/HandlerImpl";
import { Modal, Button } from "antd";
import Table from "./Table";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import TableByProject from "./TableByProject";
import { TYPE_FILE } from "../handler/Handler";
import IconCircleCheck from "../../../../../assets/icons/akar-icons_circle-check-fill.svg";
import { STATUS_CM_CHILD_TABLE } from "../../../../../common/constants/COMMON";

import CloseIcon from "../../../../../assets/icons/close-icon-cm033.svg";
import CM0062Component from "../../../CM0062";
import ApprovalFlow from "../../../../../common/components/approval-flow/ApprovalFlow";
import helpers from "../../../../../common/helpers/common";
import XIconModal from "../../../../../assets/icons/x-icon-modal.svg";
import ApproveConfirmModal from "../../../../../common/components/modal-approve-confirm";

interface props {
  handler: CM0033Handler;
  onSubmit?: () => void;
  onReject?: () => void;
}

function MainUI({ handler, onSubmit, onReject }: props) {
  const visibleCM0062 = useSelector(
    (state: RootState) => state.cm0062.isVisible
  );
  const title = useSelector((state: RootState) => state.cm0033.input);

  const range =
    title.startTimeFilter && title.endTimeFilter
      ? `${title.startTimeFilter}～${title.endTimeFilter}`
      : "";

  React.useEffect(() => {
    (async () => {
      if (title.documentId && title.documentType)
        await handler.getDataView({
          page: handler.page,
          documentId: title.documentId,
          documentType: title.documentType,
        });
    })();
  }, [handler.page, JSON.stringify(title)]);

  React.useEffect(() => {
    handler.getLineSummary({
      documentId: title.documentId,
      documentType: title.documentType,
    });
  }, []);

  React.useEffect(() => {
    handler.getWorkflow({
      documentId: title.documentId,
      documentType: title.documentType,
    });
  }, []);

  return (
    <>
      <Modal
        style={{ marginLeft: 300, marginTop: 40, marginBottom: 40 }}
        className="group-detail-modal"
        visible={handler.visibleCM0033}
        centered
        footer={
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {onSubmit && onReject ? (
              <>
                {/* DONE */}
                <ApproveConfirmModal onSubmit={onSubmit} />
                <Button
                  className="button-purple cm047-btn-submit"
                  icon={<img alt="" src={IconCircleCheck} />}
                  size="large"
                  onClick={handler.showConfirmApproveModal}
                  disabled={
                    !(
                      title?.documentStatus ===
                      STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE
                    ) ||
                    !(
                      handler.currentUserApproval &&
                      helpers.checkApproveInWorkFlow(
                        handler.currentUserApproval
                      )
                    )
                  }
                  style={{ marginLeft: 16 }}
                >
                  承認
                </Button>
                <Button
                  size="large"
                  className="button-warning cm047-btn-reject"
                  onClick={onReject}
                  disabled={
                    !(
                      title?.documentStatus ===
                      STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE
                    ) ||
                    !(
                      handler.currentUserApproval &&
                      helpers.checkApproveInWorkFlow(
                        handler.currentUserApproval
                      )
                    )
                  }
                >
                  否認
                </Button>
              </>
            ) : (
              <Button
                size="large"
                className="button-purple cm0028-modal-btn-ok cm047-btn-submit"
                onClick={() => {
                  title.documentType === TYPE_FILE.COMPANY
                    ? handler.retrieveDocumentCompany(title.documentId)
                    : handler.retrieveDocumentProject(title.documentId);
                }}
                disabled={
                  title.documentStatus ===
                  STATUS_CM_CHILD_TABLE.MONEY_REJECT.CODE ||
                  title.documentStatus ===
                  STATUS_CM_CHILD_TABLE.MONEY_APPROVED.CODE
                }
              >
                取り下げ
              </Button>
            )}

            <Button
              onClick={handler.handleCancelModal}
              size="large"
              className="cm0028-modal-btn-cancel"
            >
              キャンセル
            </Button>
          </div>
        }
        width={1572}
        closable={false}
        maskClosable={false}
      >
        <div
          className="w-100-percent header-modal-containter"
          style={{ height: 50 }}
        >
          <div className="header-modal-title-om004 ">請求金額</div>
          <div
            style={{ cursor: "pointer" }}
            onClick={handler.handleCancelModal}
          >
            <img alt="" src={XIconModal} style={{ height: 18, width: 18 }} />
          </div>
        </div>
        <div style={{ marginTop: 24, marginBottom: 8 }}>
          <ApprovalFlow
            isNotCheckingUser={true}
            breadcrumb={[]}
            listApprove={handler.workflow}
            currentUserApproval={handler.currentUserApproval}
          />
        </div>
        <div className="cm0028-modal-header">
          <div className="cm0028-modal-header-item">
            <span className="cm0028-modal-label">資料名</span>
            <span className="cm0028-modal-title">{title.documentName}</span>
          </div>
          <div className="cm0028-modal-header-item">
            <span className="cm0028-modal-label">作成日</span>
            <span className="cm0028-modal-title">{title.submittedDate}</span>
          </div>
          <div className="cm0028-modal-header-item">
            <span className="cm0028-modal-label">対象期間</span>
            <span className="cm0028-modal-title">{range}</span>
          </div>
        </div>

        {title.documentType === TYPE_FILE.COMPANY ? (
          <Table handler={handler} />
        ) : (
          <TableByProject handler={handler} />
        )}
      </Modal>
      {visibleCM0062 ? <CM0062Component /> : null}
    </>
  );
}

export default MainUI;
