import CompanyApi from "../../../infrastructure/api/CompanyApi"
import CompanyService from "../../../usecase/CompanyService"
import CompanyHandler from "../../handler/HandlerCompany"
import CompanyUI from "./ui/UI"


const CompanyComponent = () => {
    const api = CompanyApi()
    const service = CompanyService(api)
    const handler = CompanyHandler(service)
    return <CompanyUI handler={handler} />
}

export default CompanyComponent