import { createSlice } from "@reduxjs/toolkit";
import { CR002SliceEntity } from "../type/Presenter";

const initialState: CR002SliceEntity = {
  isViewChooseCompany: false,
  chosenCompany: 0,
  refreshCompany: false,
  listCompany: [],
  type: "",
};

export const cr002Slice = createSlice({
  name: "cr002",
  initialState,
  reducers: {
    resetStateCR002: (state) => initialState,
    setViewChooseCompany: (state, action) => {
      state.isViewChooseCompany = action.payload;
    },
    setChangeChosenCompany: (state, action) => {
      state.chosenCompany = action.payload;
    },
    setChangeRefreshCompany: (state) => {
      state.refreshCompany = !state.refreshCompany;
    },
    setListCompany: (state, action) => {
      state.listCompany = action.payload;
    },
    setTypeModal: (state, action) => {
      state.type = action.payload;
    },
  },
});

export const {
  resetStateCR002,
  setViewChooseCompany,
  setChangeChosenCompany,
  setChangeRefreshCompany,
  setListCompany,
  setTypeModal,
} = cr002Slice.actions;

export default cr002Slice.reducer;
