// Built-in
import React from 'react';

// 3rd party lib
import type { TableColumnsType } from 'antd';
import { Table } from 'antd';

// Source files
import { DateColumn } from '../../entity/Entity';
import { getHeaderTitle } from '../../../AM008/presenter/ui/Table';
import MemberTable from './MemberTable';
import helpers from '../../../../../common/helpers/common';
import CompanyRowComponent from './CompanyRow';

// Image files
import DoublePrevious from '../../../../../assets/icons/double-previous.svg';
import DoubleNext from '../../../../../assets/icons/double-next.svg';
import ExpandedIconSvg from '../../../../../assets/icons/expanded-icon.svg';
import ClosedIconSvg from '../../../../../assets/icons/closed-icon.svg';

interface props {
  onNext: () => void;
  onPrev: () => void;
  onChoose: (value: any) => void;
  setExpandKey(value: any): void;
  setExpandKeyMember: (value: any) => void;
  isCollapsed: (record: any) => boolean;
  onChangeCollapseRow: (record: any, collapsed: boolean) => void;
  isCollapsedMember: (record: any) => boolean;
  onChangeCollapseRowMember: (record: any, collapsed: boolean) => void;

  columnsTable: DateColumn[];
  dataSource: any[];
  isPrev: boolean;
  isNext: boolean;
  constructionId: number;
  expandKey: any[];
  expandKeyMember: any[];
}

const ConstractorTableComponent = ({
  constructionId,
  isPrev,
  isNext,
  columnsTable,
  dataSource,
  expandKey,
  expandKeyMember,
  onNext,
  onPrev,
  onChoose,
  setExpandKey,
  setExpandKeyMember,
  isCollapsed,
  onChangeCollapseRow,
  onChangeCollapseRowMember,
  isCollapsedMember,
}: props) => {
  const getColumns = () => {
    const columns: TableColumnsType<any> = [
      {
        title: <div style={{ paddingLeft: 16 }}>日付</div>,
        key: 'date',
        width: '23%',
        onCell: (_, index) => ({
          colSpan: columnsTable.length + 3,
        }),
        render: (record) => <CompanyRowComponent record={record} />,
      },
    ];
    columns.push({
      title: isPrev ? (
        <div
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            justifyContent: 'flex-end',
          }}
          onClick={onPrev}
        >
          <img alt="" src={DoublePrevious} />
        </div>
      ) : (
        <></>
      ),
      key: 'prev',
      width: 29,
      onCell: (_, index) => ({
        colSpan: 0,
      }),
    });
    for (let i = 0; i < columnsTable.length; i++) {
      columns.push({
        title: getHeaderTitle(columnsTable[i]),
        key: i,
        width: `${77 / columnsTable.length}%`,
        onCell: (_, index) => ({
          colSpan: 0,
        }),
      });
    }
    columns.push({
      title: (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {isNext ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={onNext}
            >
              <img alt="" src={DoubleNext} />
            </div>
          ) : (
            <></>
          )}
        </div>
      ),
      key: 'next',
      width: 70,
      onCell: (_, index) => ({
        colSpan: 0,
      }),
    });
    columns.push({
      title: '',
      key: 'collapsed',
      width: 40,
      render: (record) => {
        return isCollapsed(record) ? (
          <div
            style={{ cursor: 'pointer' }}
            onClick={(e: any) => onChangeCollapseRow(record, false)}
          >
            <img alt="" src={ExpandedIconSvg} />
          </div>
        ) : (
          <div
            style={{ cursor: 'pointer' }}
            onClick={(e: any) => onChangeCollapseRow(record, true)}
          >
            <img alt="" src={ClosedIconSvg} />
          </div>
        );
      },
    });
    return columns;
  };

  return (
    <div className="pm025-table ">
      <Table
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) =>
              isCollapsed(record)
                ? onChangeCollapseRow(record, false)
                : onChangeCollapseRow(record, true), // click row
          };
        }}
        rowClassName={() => {
          return 'row-company button-style';
        }}
        scroll={{
          x: 0,
          y: 600,
        }}
        id="main-table"
        className="am-main-table calendar-header"
        columns={getColumns()}
        dataSource={helpers.addKeyToDataSource(dataSource)}
        pagination={false}
        expandable={{
          showExpandColumn: false,
          expandedRowKeys: expandKey,
          onExpandedRowsChange: (value) => setExpandKey(value),
          expandedRowRender: (record: any) => (
            <MemberTable
              isCollapsedMember={isCollapsedMember}
              onChangeCollapseRowMember={onChangeCollapseRowMember}
              expandKeyMember={expandKeyMember}
              setExpandKeyMember={setExpandKeyMember}
              onChoose={onChoose}
              constructionId={constructionId}
              columnsTable={columnsTable}
              contractorMembers={record.contractorMembers}
              contractorConstructionId={record.id}
            />
          ),
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <div style={{ cursor: 'pointer' }} onClick={(e: any) => onExpand(record, e)}>
                <img alt="" src={ExpandedIconSvg} />
              </div>
            ) : (
              <div style={{ cursor: 'pointer' }} onClick={(e: any) => onExpand(record, e)}>
                <img alt="" src={ClosedIconSvg} />
              </div>
            ),
        }}
      />
    </div>
  );
};

export default ConstractorTableComponent;
