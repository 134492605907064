import { PM025HandlerUI } from '../handler/HandlerImpl';
import MenuWrapperComponent from '../../../../../common/components/menu-wrapper';
import BreadcrumbComponent from '../../../../../common/components/breadcrumb/BreadcrumbComponent';
import PM025Header from './PM025Header';
import PM025Table from './PM025Table';
import PM025Pagination from './PM025Pagination';

import '../../../../../assets/styles/PM/PM025.css';
import HomeSvg from '../../../../../assets/icons/home.svg';
import PM025Modal from './PM025Modal';
import { useEffect } from 'react';
import { PM025ParamsGetListCompany } from '../../entity/PM012Entity';
import { useSearchParams } from 'react-router-dom';
import { Tabs } from 'antd';
import { TABS_KEY } from '../handler/Handler';
import PM025ModalMaterial from './PM025ModalMaterial';
import PM028Component from '../../../PM028';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';

interface props {
  handler: PM025HandlerUI;
}

const BREADCRUMB = [
  {
    title: '協力会社管理',
    url: '',
    icon: (
      <img style={{ paddingBottom: 2, paddingRight: 8 }} alt="" src={HomeSvg} />
    ),
  },
];

const PM012UI = ({ handler }: props) => {

  const isRefreshPM025 = useSelector((state: RootState) => state.pm025.isRefreshPM025)

  useEffect(() => {
    const unloadCallback = (event: any) => {
      event.preventDefault();
      event.returnValue = '';
      return '';
    };

    if (handler.selectedRowKeys?.length > 0 || handler.isChangeForm) {
      window.addEventListener('beforeunload', unloadCallback);
    } else {
      window.removeEventListener('beforeunload', unloadCallback);
    }
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, [handler.selectedRowKeys, handler.isChangeForm]);

  useEffect(() => {
    (async () => {
      const params: PM025ParamsGetListCompany = {
        page: handler.page,
        size: handler.size,
      };
      if (handler.sortBy !== '') params.sortBy = handler.sortBy;
      if (handler.sortType !== '')
        params.sortType = handler.sortType === 'descend' ? 'DESC' : 'ASC';
      if (handler.keyword !== '') params.keyword = handler.keyword;

      switch (handler.tab) {
        case TABS_KEY[1].key:
          return await handler.getMaterialCompany(params);
        case TABS_KEY[2].key:
          return await handler.getOrderingCompanyList(params);
        default:
          return await handler.getData(params);
      }
    })()
  }, [
    handler.page,
    handler.size,
    handler.keyword,
    handler.sortBy,
    handler.sortType,
    handler.isRefresh,
    handler.tab,
    isRefreshPM025
  ]);

  return (
    <MenuWrapperComponent chosenKey={82} openKey={8}>
      <BreadcrumbComponent data={BREADCRUMB} />
      <PM025Header handler={handler} />
      <PM025Modal handler={handler} />
      <PM025ModalMaterial handler={handler} />
      <PM028Component />
      <Tabs
        type="card"
        activeKey={handler.tab}
        className="pm025-tab"
        onChange={handler.onChangeTab}
      >
        <Tabs.TabPane tab={TABS_KEY[0].name} key={TABS_KEY[0].key}>
          <div className="pm025-body">
            <PM025Table handler={handler} />
            {handler.data?.length > 0 ? (
              <PM025Pagination handler={handler} />
            ) : null}
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab={TABS_KEY[1].name} key={TABS_KEY[1].key}>
          <div className="pm025-body">
            <PM025Table handler={handler} />
            {handler.data?.length > 0 ? (
              <PM025Pagination handler={handler} />
            ) : null}
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab={TABS_KEY[2].name} key={TABS_KEY[2].key}>
          <div className="pm025-body">
            <PM025Table handler={handler} />
            {handler.data?.length > 0 ? (
              <PM025Pagination handler={handler} />
            ) : null}
          </div>
        </Tabs.TabPane>
      </Tabs>
    </MenuWrapperComponent>
  );
};

export default PM012UI;
