import API from "./infrastructure/api/Api"
import Handler from "./presenter/handler/Handler";
import SM010UI from "./presenter/ui/UI";
import Service from "./usecase/Service";
import "../../../assets/styles/SM/SM010.css"

const SM010Component = () => {
    const dataSource = API();
    const service = Service(dataSource)
    const handler = Handler(service)
    return <SM010UI handler={handler} />
}

export default SM010Component