import React from 'react';
import { Button, Form, Input, Modal } from 'antd';

import IconCheckGreen from '../../../../../assets/icons/akar-icons_circle-check-fill-green.svg';
import IconCloseModal from '../../../../../assets/icons/icon-close-flow.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { HandlerImpl } from '../handler/HandlerImpl';


interface props {
    handler: HandlerImpl,
    onSubmit: () => void
}

export const MainUI = ({ handler, onSubmit }: props) => {
    const visible = useSelector((state: RootState) => state.modalApproveConfirm.isVisible)
    const description = useSelector((state: RootState) => state.modalApproveConfirm.description)

    return !visible ? null : (
        <Modal
            centered
            className="modal-approve"
            visible={visible}
            onCancel={handler.onCancel}
            footer={null}
            closeIcon={<img alt="" src={IconCloseModal} />}
            maskClosable={false}
        >
            <div className="modal-approve-header">
                <img alt="" src={IconCheckGreen} />
                <h3>提出確認</h3>
            </div>

            <Form name="workflow-reject" >
                <div className="modal-approve-body">
                    {
                        Array.isArray(description) ?
                            <>
                                {
                                    description.map((element) => <div className="modal-approve-body-title">
                                        {element}
                                    </div>)
                                }
                            </>
                            :
                            <div className="modal-approve-body-title">{description}</div>
                    }

                </div>
                <div className="modal-approve-btn">
                    <Button size="large" className="button-purple" onClick={() => {
                        onSubmit()
                        handler.onCancel()
                    }}>
                        はい
                    </Button>
                    <Button
                        size="large"
                        onClick={handler.onCancel}
                        style={{ color: '#666' }}
                        htmlType="button"
                    >
                        キャンセル
                    </Button>
                </div>
            </Form>
        </Modal>
    );
}

