import { Button, Form, Input, InputNumber, Modal, Select, Spin } from "antd";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import CloseIconModal from "../../../../../assets/icons/close.png";
import EmptyDataSVG from "../../../../../assets/icons/empty-data.svg";
import Minus_cm0063 from "../../../../../assets/icons/Minus_cm0063.svg";
import Plus_cm0063 from "../../../../../assets/icons/Plus_cm0063.svg";
import { default as XIconModal } from "../../../../../assets/icons/x-icon-modal.svg";
import "../../../../../assets/styles/CM/CM0012.css";
import LoadingComponent from "../../../../../common/components/icons/LoadingComponent";
import helpers from "../../../../../common/helpers/common";
import { RootState } from "../../../../../store";
import { CM0012Hanlder } from "../handler/HandlerImpl";
import { HeaderData, ParentCode } from "../type/Presenter";
import MESSAGE from "../../../../../common/constants/MESSAGE";
import REGEX from "../../../../../common/constants/REGEX";
import COMMON from "../../../../../common/constants/COMMON";
import EditWhiteIcon from "../../../../../assets/icons/edit-white.svg";

type Props = {
  handler: CM0012Hanlder;
};

const CM0012UI = ({ handler }: Props) => {
  const formRef: any = useRef();
  const [isClickAdd, setIsClickAdd] = useState<number>(0);
  const isVisible = useSelector((state: RootState) => state.cm0012.isVisible);
  const loading = useSelector((state: RootState) => state.cm0012.loading);
  const parentCode: ParentCode = useSelector(
    (state: RootState) => state.cm0012.parentCode
  );
  const headerData: HeaderData = useSelector(
    (state: RootState) => state.cm0012.headerData
  );
  const [classNameViolateRule, setClassNameViolateRule] =
    useState<boolean>(false);

  useEffect(() => {
    if (isVisible && parentCode?.parentRecordId) {
      handler.handleGetListMoneyDirectCostOutsourceOffset(
        parentCode.parentRecordId,
        COMMON.DEFAULT_PAGE,
        COMMON.DEFAULT_SIZE
      );
      handler.handleGetSubCategory();
    }
  }, [isVisible, parentCode?.parentRecordId]);

  useEffect(() => {
    if (isClickAdd !== 0 && formRef.current) {
      formRef.current.scrollTo(0, formRef.current.scrollHeight);
    }
  }, [isClickAdd]);

  useEffect(() => {
    handler.handleCalculateChange(
      handler.listMoneyDirectCostOutsourceOffsetLocal
    );
  }, [`${JSON.stringify(handler.listMoneyDirectCostOutsourceOffsetLocal)}`]);

  return (
    <Modal
      className="group-detail-modal modal-cm012"
      style={{ marginLeft: 300, marginTop: 40, marginBottom: 40 }}
      visible={isVisible}
      closable={false}
      maskClosable={false}
      width={"1027px"}
      centered={true}
      closeIcon={<img src={CloseIconModal} alt="close icon" />}
      footer={
        handler.typeModal ? (
          <>
            <Button
              disabled={loading}
              size="large"
              type="primary"
              style={{ marginLeft: 16, width: 92, letterSpacing: -1.5 }}
              htmlType="submit"
              form="formCM0012"
            >
              確定
            </Button>
            <Button
              disabled={loading}
              size="large"
              style={{
                marginLeft: 16,
                width: 118,
                letterSpacing: -1.5,
                fontWeight: 400,
                fontSize: 14,
                lineHeight: 22,
                color: "#666666",
              }}
              onClick={handler.onCancel}
            >
              キャンセル
            </Button>
          </>
        ) : (
          <Button
            disabled={loading}
            size="large"
            type="primary"
            style={{ marginLeft: 16, width: 92, letterSpacing: -1.5 }}
            onClick={handler.onCancel}
          >
            閉じる
          </Button>
        )
      }
    >
      <Spin indicator={LoadingComponent} spinning={loading}>
        <div
          className="w-100-percent header-modal-containter"
          style={{ height: 50 }}
        >
          <div className="header-modal-title-cm0012">相殺金額</div>
          <div style={{ cursor: "pointer" }} onClick={handler.onCancel}>
            <img alt="" src={XIconModal} style={{ height: 18, width: 18 }} />
          </div>
        </div>
        <div
          style={{ minHeight: 224, marginTop: 24 }}
          className="cm0012-content"
        >
          <div className="content-description">
            <div
              className={
                handler.typeModal
                  ? "content-description-text content-description-text-update"
                  : "content-description-text"
              }
            >
              <div className="description-col">
                <label> 業者名</label>
                <p>{headerData?.traderName}</p>
              </div>
              <div className="description-col">
                <label> 契約工事名</label>
                <p>{headerData?.contractWorkName}</p>
              </div>
              <div className="description-col">
                <label> 対象期間</label>
                <p>{headerData?.targetPeriod}</p>
              </div>
            </div>
            {!handler.typeModal && parentCode.status && (
              <div className="content-description-button">
                <Button
                  style={{
                    letterSpacing: -1.5,
                  }}
                  onClick={() => handler.handleChangeTypeModal(true)}
                  size="large"
                  className="button-edit-project"
                  icon={
                    <img
                      alt=""
                      src={EditWhiteIcon}
                      style={{
                        paddingRight: 12,
                        border: "none",
                        marginBottom: 4,
                      }}
                    />
                  }
                >
                  編集
                </Button>
              </div>
            )}
          </div>
          <div className="content-table">
            <div className="table-header table-row">
              <div className="cell cell-order">No</div>
              <div className="cell cell-item">項目</div>
              <div className="cell cell-taxCategoryComsumption">消費税区分</div>
              <div className="cell cell-offsetAmount">相殺金額 (税抜)</div>
            </div>
            {handler.typeModal && (
              <div
                className="add-item"
                style={{ fontWeight: 700, fontSize: 14, lineHeight: 22 }}
              >
                <img
                  src={Plus_cm0063}
                  alt=""
                  style={{
                    marginLeft: 16,
                    marginRight: 8,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handler.handleAddRowLocal();
                    setIsClickAdd(isClickAdd + 1);
                  }}
                />
                <span
                  onClick={() => {
                    handler.handleAddRowLocal();
                    setIsClickAdd(isClickAdd + 1);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {" "}
                  項目追加{" "}
                </span>
              </div>
            )}
            <div
              ref={formRef}
              className="form-cm0012"
            // onScroll={(e) => {
            //     parentCode?.parentRecordId &&
            //         handler.handleScrollListMoneyDirectCostOutsourceOffset(
            //             e,
            //             parentCode.parentRecordId,
            //         );
            // }}
            >
              <Form
                form={handler.form}
                id="formCM0012"
                style={{
                  height: "100%",
                }}
                onFinish={(value: any) => handler.handleSubmitForm(value)}
                onValuesChange={handler.checkDirty}
              >
                {handler?.listMoneyDirectCostOutsourceOffsetLocal?.map(
                  (item, index) => {
                    return !handler.typeModal ? (
                      <div className="table-row" key={item.key}>
                        <div className="cell cell-order">{index + 1}</div>
                        <div className="cell cell-item">{item.item}</div>
                        <div className="cell cell-taxCategoryComsumption">
                          {item.taxCategoryComsumptionName}
                        </div>
                        <div className="cell cell-offsetAmount">
                          {`${helpers.formatCurrency(
                            Number(item.offsetAmount)
                          )}円`}
                        </div>
                      </div>
                    ) : (
                      <div
                        className={
                          classNameViolateRule
                            ? "table-row table-row-violate-rule"
                            : "table-row"
                        }
                        key={item.key}
                      >
                        <div className="cell cell-order">{index + 1}</div>
                        <div className="cell cell-item">
                          <Form.Item
                            name={`item${item.key}`}
                            rules={[
                              () => ({
                                validator(_, value) {
                                  if (!REGEX.NOT_VIETNAM.test(value)) {
                                    setClassNameViolateRule(true);
                                    return Promise.reject(
                                      new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              }),
                            ]}
                          >
                            <Input maxLength={20} />
                          </Form.Item>
                        </div>
                        <div className="cell cell-taxCategoryComsumption">
                          <Form.Item name={`taxCategoryConsumption${item.key}`}>
                            <Select
                              options={handler?.subCategory}
                              getPopupContainer={(trigger: any) =>
                                trigger.parentNode
                              }
                              listHeight={100}
                            />
                          </Form.Item>
                        </div>
                        <div className="cell cell-offsetAmount">
                          <Form.Item name={`offsetAmount${item.key}`}>
                            <InputNumber controls={false}
                              maxLength={19}
                              addonAfter="円"
                              min={0}
                              onChange={() => {
                                handler.handleCalculateChange(
                                  handler.listMoneyDirectCostOutsourceOffsetLocal
                                );
                              }}
                              formatter={(value) =>
                                value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              }
                              parser={(value: any) =>
                                value.replace(/\$\s?|(,*)/g, "")
                              }
                            />
                          </Form.Item>
                          <img
                            src={Minus_cm0063}
                            alt=""
                            style={{
                              width: 22,
                              height: 22,
                              objectFit: "cover",
                              marginLeft: 8,
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handler.handleRemoveRowLocal(item.key)
                            }
                          />
                        </div>
                      </div>
                    );
                  }
                )}
                {handler?.listMoneyDirectCostOutsourceOffsetLocal?.length ===
                  0 && (
                    <div className="empty-data">
                      <img src={EmptyDataSVG} alt="" />
                    </div>
                  )}
              </Form>
            </div>
            <div className="table-row table-row-blue">
              <div className="cell cell-order">合計金額</div>
              <div className="cell cell-item"></div>
              <div className="cell cell-taxCategoryComsumption"></div>
              <div className="cell cell-offsetAmount">{`${helpers.formatCurrency(
                handler.totalCost
              )}円`}</div>
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default CM0012UI;
