import { BaseResponseType } from "../../CM004/entity/Entity";
import {
  CommonPutDeletePostResponse,
  MoneyDirectCostOutsourceEnable,
  MoneyDirectCostOutsourceInformationListResponse,
  MoneyDirectCostOutsourceListResult,
  MoneyDirectCostOutsourceOffsetListResponseData,
  MoneySubmitDirectCostOutsourcePayload,
} from "../entity/Entity";
import { MaterialCostAPIImpl, MaterialCostServiceImpl } from "./ServiceImpl";

const MaterialCostService = (api: MaterialCostAPIImpl) => {
  const getMoneyDirectCostOutsourceOffsetList = async (params: {
    outsourceCostInformationId: number;
    categoryId?: number;
    keyword?: number;
    sortBy?: string;
    sortType?: string;
    page?: number;
    size?: number;
  }): Promise<MoneyDirectCostOutsourceOffsetListResponseData> => {
    try {
      const response = await api.getMoneyDirectCostOutsourceOffsetList(params);
      const { results, pagination } = response.data;
      const responseData: MoneyDirectCostOutsourceOffsetListResponseData = {
        pagination,
        results,
      };
      return responseData;
    } catch (error: any) {
      throw error;
    }
  };
  const getMoneyDirectCostOutsourceInformationList = async (params: {
    constructionId: number;
    page?: number;
    size?: number;
    sortBy?: string;
    sortType?: string;
    keyword?: string;
  }): Promise<MoneyDirectCostOutsourceInformationListResponse> => {
    try {
      const response = await api.getMoneyDirectCostOutsourceInformationList(
        params
      );
      return response.data;
    } catch (error: any) {
      throw error;
    }
  };
  const putMoneyDirectCostOutsourceEnable = async (
    data: MoneyDirectCostOutsourceEnable
  ): Promise<CommonPutDeletePostResponse> => {
    try {
      const response = await api.putMoneyDirectCostOutsourceEnable(data);
      return response.data;
    } catch (error: any) {
      throw error;
    }
  };
  const getMoneyDirectCostOutsourceList = async (params: {
    outsourceCostId: number;
    page?: number;
    size?: number;
    sortBy?: string;
    sortType?: string;
  }): Promise<MoneyDirectCostOutsourceListResult[]> => {
    try {
      const response = await api.getMoneyDirectCostOutsourceList({
        outsourceCostId: params.outsourceCostId,
      });
      const { results } = response.data;
      return results;
    } catch (error: any) {
      throw error;
    }
  };
  const putMoneySubmitDirectCostOutsource = async (
    payload: MoneySubmitDirectCostOutsourcePayload
  ): Promise<BaseResponseType> => {
    try {
      const response = await api.putMoneySubmitDirectCostOutsource(payload);
      return response?.data;
    } catch (error: any) {
      throw error;
    }
  };

    const doExportCM009 = async (params: any): Promise<any> => {
        return await api.doExportCM009(params);
      }

    return {
        getMoneyDirectCostOutsourceInformationList,
        putMoneyDirectCostOutsourceEnable,
        getMoneyDirectCostOutsourceList,
        putMoneySubmitDirectCostOutsource,
        getMoneyDirectCostOutsourceOffsetList,
        doExportCM009,
    };
};

export default MaterialCostService;
