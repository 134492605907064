import ORD002_1Api from './infrastructure/api/Api';
import ORD002_1Handler from './presenter/handler/Handler';
import ORD002_1Service from './usecase/Service';
import MainUI from './presenter/ui/UI';

import '../../../assets/styles/ORD/ord002_1.css';

const ORD002_1Component = () => {
    const api = ORD002_1Api();

    const service = ORD002_1Service(api);

    const handler = ORD002_1Handler(service);

    return <MainUI handler={handler} />;
};

export default ORD002_1Component;
