import API from './infrastructure/api/Api';
import Handler from './presenter/handler/Handler';
import MainUI from './presenter/ui/UI';
import Service from './usecase/Service';

const P01Component = () => {
    const serverAPI = API();
    const service = Service(serverAPI);
    const handler = Handler(service);
    return <MainUI handler={handler} />;
};

export default P01Component;
