import axiosConfig from '../../../../../config/axiosConfig';

const DB007Api = () => {
    const getTotalMandays = async (params: {
        projectId: number;
        from: string;
        to: string;
        page: number;
        size: number;
    }) => {
        const url = '/dashboard/total-mandays';
        return await axiosConfig.get(url, { params });
    };

    return {
        getTotalMandays,
    };
};

export default DB007Api;
