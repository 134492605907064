import { configureStore } from "@reduxjs/toolkit";
import todoReducer from "./pages/template/presenter/slice/Slice";

import pm002Reducer from "./pages/PM/PM002/presenter/slice/Slice";
import ci001Reducer from "./pages/CI/CI001/presenter/slice/Slice";
import pm003Reducer from "./pages/PM/PM003/presenter/slice/Slice";
import pm008Reducer from "./pages/PM/PM008/presenter/slice/Slice";
import pm007Reducer from "./pages/PM/PM007/presenter/slice/Slice";
import om003Reducer from "./pages/OM/OM003/presenter/slice/Slice";
import om002Reducer from "./pages/OM/OM002/presenter/slice/Slice";
import om001Reducer from "./pages/OM/OM001/presenter/slice/Slice";
import om007Reducer from "./pages/OM/OM007/presenter/slice/Slice";
import om008Reducer from "./pages/OM/OM008/presenter/slice/Slice";
import om009Reducer from "./pages/OM/OM009/presenter/slice/Slice";
import om004Reducer from "./pages/OM/OM004/presenter/slice/Slice";
import om005Reducer from "./pages/OM/OM005/presenter/slice/Slice";
import om006Reducer from "./pages/OM/OM006/presenter/slice/Slice";
import pm012Reducer from "./pages/PM/PM012/presenter/slice/Slice";
import pm011Reducer from "./pages/PM/PM011/presenter/slice/Slice";
import pm013Reducer from "./pages/PM/PM013/presenter/slice/Slice";
import pm025Reducer from "./pages/PM/PM025/presenter/slice/Slice";
import pm005Reducer from "./pages/PM/PM005/presenter/slice/Slice";
import pm014Reducer from "./pages/PM/PM014/presenter/slice/Slice";
import pm024Reducer from "./pages/PM/PM024/presenter/slice/Slice";
import pm028Reducer from "./pages/PM/PM028/presenter/slice/Slice";
import cr002Reducer from "./pages/CR/CR002/presenter/slice/Slice";
import cr003Reducer from "./pages/CR/CR003/presenter/slice/Slice";
import pm008_1Reducer from "./pages/PM/PM008_1/presenter/slice/Slice";

// AM
import am042Reducer from "./pages/AM/AM042/presenter/slice/Slice";
import am040Reducer from "./pages/AM/AM040/presenter/slice/Slice";
import am008Reducer from "./pages/AM/AM008/presenter/slice/Slice";
import am006Reducer from "./pages/AM/AM006/presenter/slice/Slice";
import am001Reducer from "./pages/AM/AM001/presenter/slice/Slice";
import am004Reducer from "./pages/AM/AM004/presenter/slice/Slice";
import am002Reducer from "./pages/AM/AM002/presenter/slice/Slice";
import am005Reducer from "./pages/AM/AM005/presenter/slice/Slice";
import am010Reducer from "./pages/AM/AM010/presenter/slice/Slice";
import am012Reducer from "./pages/AM/AM012/presenter/slice/Slice";
import am014Reducer from "./pages/AM/AM014/presenter/slice/Slice";
import am016Reducer from "./pages/AM/AM016/presenter/slice/Slice";
import am018Reducer from "./pages/AM/AM018/presenter/slice/Slice";
import am020Reducer from "./pages/AM/AM020/presenter/slice/Slice";
import am022Reducer from "./pages/AM/AM022/presenter/slice/Slice";
import am024Reducer from "./pages/AM/AM024/presenter/slice/Slice";
import am027Reducer from "./pages/AM/AM027/presenter/slice/Slice";
import am028Reducer from "./pages/AM/AM028/presenter/slice/Slice";

// CM
import cm001Reducer from "./pages/CM/CM001/presenter/slice/Slice";
import cm004Reducer from "./pages/CM/CM004/presenter/slice/Slice";
import cm003Reducer from "./pages/CM/CM003/presenter/slice/Slice";
import cm0015Reducer from "./pages/CM/CM0015/presenter/slice/Slice";
import cm0013Reducer from "./pages/CM/CM0013/presenter/slice/Slice";

import cm009Reducer from "./pages/CM/CM009/presenter/slice/Slice";
import cm005Reducer from "./pages/CM/CM005/presenter/slice/Slice";
import cm007Reducer from "./pages/CM/CM007/presenter/slice/Slice";
import cm0017Reducer from "./pages/CM/CM0017/presenter/slice/Slice";
import cm0019Reducer from "./pages/CM/CM0019/presenter/slice/Slice";
import cm0020Reducer from "./pages/CM/CM0020/presenter/slice/Slice";
import cm0021Reducer from "./pages/CM/CM0021/presenter/slice/Slice";
import cm0022Reducer from "./pages/CM/CM0022/presenter/slice/Slice";
import cm0023Reducer from "./pages/CM/CM0023/presenter/slice/Slice";
import cm0061Reducer from "./pages/CM/CM0061/presenter/slice/Slice";
import cm0016Reducer from "./pages/CM/CM0016/presenter/slice/Slice";
import cm0062Reducer from "./pages/CM/CM0062/presenter/slice/Slice";
import cm0063Reducer from "./pages/CM/CM0063/presenter/slice/Slice";
import cm008Reducer from "./pages/CM/CM008/presenter/slice/Slice";
import cm0024Reducer from "./pages/CM/CM0024/presenter/slice/Slice";
import cm0026Reducer from "./pages/CM/CM0026/presenter/slice/Slice";
import cm0047Reducer from "./pages/CM/CM034-CM53/CM047/presenter/slice/Slice";
import cm0010Reducer from "./pages/CM/CM0010/presenter/slice/Slice";
import cm0014Reducer from "./pages/CM/CM0014/presenter/slice/Slice";
import cm0032Reducer from "./pages/CM/CM0032/presenter/slice/Slice";
import cm0028Reducer from "./pages/CM/CM0028/presenter/slice/Slice";
import cm0029Reducer from "./pages/CM/CM0029/presenter/slice/Slice";
import cm0030Reducer from "./pages/CM/CM0030/presenter/slice/Slice";
import cm0031Reducer from "./pages/CM/CM0031/presenter/slice/Slice";
import cm0033Reducer from "./pages/CM/CM0033/presenter/slice/Slice";
import cm006Reducer from "./pages/CM/CM006/presenter/slice/Slice";
import cm0025Reducer from "./pages/CM/CM0025/presenter/slice/Slice";
import cm0025_1Reducer from "./pages/CM/CM0025_1/presenter/slice/Slice";
import cm0025_2Reducer from "./pages/CM/CM0025_2/presenter/slice/Slice";
import cm0027Reducer from "./pages/CM/CM0027/presenter/slice/Slice";
import cm0027_1Reducer from "./pages/CM/CM0027_1/presenter/slice/Slice";
import cm0027_2Reducer from "./pages/CM/CM0027_2/presenter/slice/Slice";
import cm0012Reducer from "./pages/CM/CM0012/presenter/slice/Slice";
import cm0012_2Reducer from "./pages/CM/CM0012_2/presenter/slice/Slice";
import cm017_2Reducer from "./pages/CM/CM017_2/presenter/slice/Slice";
import cm017_2_1Reducer from "./pages/CM/CM017_2_1/presenter/slice/Slice";
import cm017_2_2Reducer from "./pages/CM/CM017_2_2/presenter/slice/Slice";

import cm0017_3Reducer from "./pages/CM/CM0017_3/presenter/slice/Slice";
import cm0017_3_1Reducer from "./pages/CM/CM0017_3_1/presenter/slice/Slice";
import cm0017_3_2Reducer from "./pages/CM/CM0017_3_2/presenter/slice/Slice";
import cm0039Reducer from "./pages/CM/CM0039/presenter/slice/Slice";

import tabCM020Reducer from "./pages/CM/CM0020/components/tab/presenter/slice/Slice";
import cm03453Reducer from "./pages/CM/CM034-CM53/CM035/presenter/slice/Slice";
import cr001MailContractorReducer from "./pages/CR/CR001-mail-contractors/presenter/slice/Slice";
import cr005Reducer from "./pages/CR/CR005/presenter/slice/Slice";

import imageViewerModalReducer from "./common/components/image-viewer-modal/ImageViewSlice";
import pdfViewerModalReducer from "./common/components/pdf-viewer-modal/pdfViewerModalSlice";
import pm009Reducer from "./pages/PM/PM009/presenter/slice/Slice";
import openStreetMapReducer from "./common/components/open-street-map/Slice";
import wrapper3453Reducer from "./pages/CM/CM034-CM53/wrapper/presenter/slice/Slice";
import cm034Reducer from "./pages/CM/CM0034/presenter/slice/Slice";
import modalH4Reducer from "./common/components/modal-H4/H4ModalSlice";
import modalH1Reducer from "./common/components/modal-H1/H1ModalSlice";
import modalH2Reducer from "./common/components/modal-H2/H2ModalSlice";
import modalH3Reducer from "./common/components/modal-H3/H3ModalSlice";
import modalApproveEditReducer from "./common/components/modal-approve-edit/presenter/slice/Slice";
import modalApproveViewReducer from "./common/components/modal-approve-view/presenter/slice/Slice";
import modalApproveConfirmReducer from "./common/components/modal-approve-confirm/presenter/slice/Slice";

import notificationWorkflowReducer from "./common/components/workflow-notification/presenter/slice/Slice";

import deleteAOPModalSliceReducer from "./common/components/modal-delete-AOP/DeleteAOPModalSlice";
//Modal IC
import modalIC5Reducer from "./common/components/modal-IC5/IC5ModalSlice";
import modalIC4Reducer from "./pages/IC/IC4/presenter/slice/Slice";
import ic1Reducer from "./pages/IC/IC1/presenter/slice/Slice";
// P
import p01Reducer from "./pages/P/P01/presenter/slice/Slice";
//SM
import sm03Reducer from "./pages/SM/SM03/presenter/slice/Slice";
import sm04Reducer from "./pages/SM/SM04/presenter/slice/Slice";
import sm06Reducer from "./pages/SM/SM06/presenter/slice/Slice";
import sm01Reducer from "./pages/SM/SM01/presenter/slice/Slice";
import sm010Reducer from "./pages/SM/SM010/presenter/slice/Slice";

//DB
import db004Reducer from "./pages/DB/DB004/presenter/slice/Slice";
import db003Reducer from "./pages/DB/DB003/presenter/slice/Slice";
import db007Reducer from "./pages/DB/DB007/presenter/slice/Slice";
import db006Reducer from "./pages/DB/DB006/presenter/slice/Slice";
import db001Reducer from "./pages/DB/DB001/presenter/slice/Slice";
import db002Reducer from "./pages/DB/DB002/presenter/slice/Slice";

//ORD
import ord002_1Reducer from "./pages/ORD/ORD002_1/presenter/slice/Slice";
import ord001Reducer from "./pages/ORD/ORD001/presenter/slice/Slice";

//CCUS
import ccus062Reducer from "./pages/CCUS/CCUS061/presenter/slice/Slice";

//AOP
import aop003Reducer from "./pages/AOP/AOP003/presenter/slice/Slice";
import aop004Reducer from "./pages/AOP/AOP004/presenter/slice/Slice";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { authReducer } from "./common/slice/AuthSlice";
import { commonReducer } from "./common/slice/CommonSlice";

const persistConfig = {
  key: "root",
  storage,
};

export const store = configureStore({
  reducer: {
    /* AUTH */
    auth: persistReducer(persistConfig, authReducer),

    /* CI */
    ci001: ci001Reducer,

    /* CR */
    cr002: cr002Reducer,
    cr003: cr003Reducer,
    cr005: cr005Reducer,

    om003: om003Reducer,
    om002: om002Reducer,
    om001: om001Reducer,
    om007: om007Reducer,
    om008: om008Reducer,
    om009: om009Reducer,
    om004: om004Reducer,
    om005: om005Reducer,
    om006: om006Reducer,

    /* PM */
    pm012: pm012Reducer,
    pm011: pm011Reducer,
    pm013: pm013Reducer,
    pm005: pm005Reducer,
    pm014: pm014Reducer,
    pm009: pm009Reducer,
    pm024: pm024Reducer,
    pm025: pm025Reducer,
    pm002: pm002Reducer,
    pm008: pm008Reducer,
    pm007: pm007Reducer,
    pm028: pm028Reducer,
    pm008_1: pm008_1Reducer,
    pm003: pm003Reducer,

    /* AM */
    am005: am005Reducer,
    am028: am028Reducer,
    am010: am010Reducer,
    am012: am012Reducer,
    am014: am014Reducer,
    am016: am016Reducer,
    am018: am018Reducer,
    am020: am020Reducer,
    am022: am022Reducer,
    am024: am024Reducer,
    am027: am027Reducer,
    am004: am004Reducer,
    am001: am001Reducer,
    am006: am006Reducer,
    am008: am008Reducer,
    am040: am040Reducer,
    am042: am042Reducer,
    am002: am002Reducer,

    /* CM */
    cm001: cm001Reducer,
    cm003: cm003Reducer,
    cm004: cm004Reducer,
    cm009: cm009Reducer,
    cm005: cm005Reducer,
    cm007: cm007Reducer,
    cm0015: cm0015Reducer,
    cm0012: cm0012Reducer,
    cm0012_2: cm0012_2Reducer,
    cm0013: cm0013Reducer,
    cm0017: cm0017Reducer,
    cm0017_3: cm0017_3Reducer,
    cm0017_3_1: cm0017_3_1Reducer,
    cm0017_3_2: cm0017_3_2Reducer,
    cm0019: cm0019Reducer,
    cm0020: cm0020Reducer,
    cm0021: cm0021Reducer,
    cm0022: cm0022Reducer,
    cm0023: cm0023Reducer,
    cm0016: cm0016Reducer,
    cm006: cm006Reducer,
    cm0061: cm0061Reducer,
    cm0062: cm0062Reducer,
    cm0063: cm0063Reducer,
    cm008: cm008Reducer,
    wrapper3453: cm03453Reducer,
    wrapper3453i: wrapper3453Reducer,
    cm0047: cm0047Reducer,
    cm0024: cm0024Reducer,
    cm0026: cm0026Reducer,
    cm0010: cm0010Reducer,
    cm0014: cm0014Reducer,
    cm0032: cm0032Reducer,
    cm0028: cm0028Reducer,
    cm0029: cm0029Reducer,
    cm0030: cm0030Reducer,
    cm0031: cm0031Reducer,
    cm0033: cm0033Reducer,
    cm0034: cm034Reducer,
    cm0025: cm0025Reducer,
    cm0025_1: cm0025_1Reducer,
    cm0025_2: cm0025_2Reducer,
    cm0027: cm0027Reducer,
    cm0027_1: cm0027_1Reducer,
    cm0027_2: cm0027_2Reducer,
    cm017_2: cm017_2Reducer,
    cm017_2_1: cm017_2_1Reducer,
    cm017_2_2: cm017_2_2Reducer,
    cm0039: cm0039Reducer,
    tabCM020: tabCM020Reducer,

    /* IC */
    ic5Modal: modalIC5Reducer,
    ic4Modal: modalIC4Reducer,
    ic1: ic1Reducer,

    /* SM */
    sm03: sm03Reducer,
    sm04: sm04Reducer,
    sm06: sm06Reducer,
    sm01: sm01Reducer,
    sm010: sm010Reducer,

    /* DB */
    db003: db003Reducer,
    db004: db004Reducer,
    db006: db006Reducer,
    db007: db007Reducer,
    db002: db002Reducer,
    db001: db001Reducer,

    /* COMPONENTS */
    common: commonReducer,
    cr001MailContractor: cr001MailContractorReducer,
    imageViewerModal: imageViewerModalReducer,
    pdfViewerModal: pdfViewerModalReducer,
    openStreetMap: openStreetMapReducer,
    modalApproveEdit: modalApproveEditReducer,
    modalApproveView: modalApproveViewReducer,
    modalApproveConfirm: modalApproveConfirmReducer,
    todo: todoReducer,
    notificationWorkflow: notificationWorkflowReducer,
    h4Modal: modalH4Reducer,
    h1Modal: modalH1Reducer,
    h2Modal: modalH2Reducer,
    h3Modal: modalH3Reducer,

    /* P */
    p01: p01Reducer,

    /* ORD */
    ord002_1: ord002_1Reducer,
    ord001: ord001Reducer,

    /* AOP */
    aop003: aop003Reducer,
    aop004: aop004Reducer,
    deleteAOPModal: deleteAOPModalSliceReducer,

    // CCUS
    ccus062: ccus062Reducer

  },
  middleware: [],
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
