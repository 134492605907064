// Built-in
import React from 'react';

import helpers from '../../../../../common/helpers/common';
import { GREEN_COLOR, INVALID, RED_COLOR, VALID } from './UI';
import COMMON, { ORDER_STATUS } from '../../../../../common/constants/COMMON';
import moment from 'moment';

type props = {
    data: any[];
};

const ChildTableComponent = ({ data }: props) => {
    return (
        <>
            {data.map((recordChild: any, index: number) => (
                <tr key={recordChild.id}>
                    <td>{index + 1}</td>
                    <td>
                        <div style={{ width: '100%' }}>{recordChild.name}</div>
                    </td>
                    <td>
                        {`${
                            recordChild.startDate
                                ? moment(recordChild.startDate).format(COMMON.FORMAT_DATE_OM)
                                : ''
                        }
                    ~
                    ${
                        recordChild.endDate
                            ? moment(recordChild.endDate).format(COMMON.FORMAT_DATE_OM)
                            : ''
                    }`}
                    </td>
                    <td
                        style={{
                            color:
                                recordChild.status.code === ORDER_STATUS.ACTIVE_ORDER
                                    ? GREEN_COLOR
                                    : RED_COLOR,
                        }}
                    >
                        {recordChild?.status?.name ?? ''}
                    </td>
                    <td>{`${helpers.formatNumberMoney(recordChild.orderCost)}円`}</td>
                    <td>{`${helpers.formatNumberMoney(recordChild.discount)}円`}</td>
                    <td>{`${helpers.formatNumberMoney(
                        recordChild.orderCost - recordChild.discount,
                    )}円`}</td>
                </tr>
            ))}
        </>
    );
};

export default ChildTableComponent;
