import CM006Api from '../CM006/infrastructure/api/Api';
import CM006Service from '../CM006/usecase/Service';
import CM008Api from '../CM008/infrastructure/api/Api';
import CM008Service from '../CM008/usecase/Service';
import CM0010Api from './infrastructure/api/Api';
import CM0010Handler from './presenter/handler/Handler';
import CM0010UI from './presenter/ui/UI';
import CM0010Service from './usecase/Service';

type props = {
    onSubmit?: () => void;
    onReject?: () => void;
    disableSubmitOrReject?: boolean;
    isCheckbox?: boolean
};

const CM0010Component = (props: props) => {
    const cm006Api = CM006Api();
    const cm006Service = CM006Service(cm006Api);

    const cm008Api = CM008Api();
    const cm008service = CM008Service(cm008Api);

    const cm0010Api = CM0010Api();
    const cm0010Service = CM0010Service(cm0010Api);

    const cm0010Handler = CM0010Handler(cm0010Service, cm006Service, cm008service);
    return <CM0010UI handler={cm0010Handler} {...props} />;
};

export default CM0010Component;
