import { CM0052APIImpl } from './ServiceImpl';
import {
    BaseResponseType,
    CompanyDocumentOutsourceCostReviewList,
    CompanySummaryOutsourceCostApprovePayload,
    CompanySummaryOutsourceCostRejectPayload,
} from '../entity/Entity';
import ErrorNotification from '../../../../../common/components/notification/ErrorNotification';
import { NOTIFICATION_TITLE } from '../../../../../common/constants/MESSAGE';

const CM0052Service = (api: CM0052APIImpl) => {
    const getCompanyDocumentOutsourceCostReviewList = async (params: {
        page: number;
        size: number;
        sortType?: string;
        sortBy?: string;
        from?: string;
        to?: string;
        statusId?: string;
        keyword?: string;
    }): Promise<CompanyDocumentOutsourceCostReviewList | null> => {
        try {
            const response = await api.getCompanyDocumentOutsourceCostReviewList(params);
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };

    const putCompanySummaryOutsourceCostReject = async (
        payload: CompanySummaryOutsourceCostRejectPayload,
    ): Promise<BaseResponseType | null> => {
        try {
            const response = await api.putCompanySummaryOutsourceCostReject(payload);
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };
    const putCompanySummaryOutsourceCostApprove = async (
        payload: CompanySummaryOutsourceCostApprovePayload,
    ): Promise<BaseResponseType | null> => {
        try {
            const response = await api.putCompanySummaryOutsourceCostApprove(payload);
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };

    return {
        getCompanyDocumentOutsourceCostReviewList,
        putCompanySummaryOutsourceCostReject,
        putCompanySummaryOutsourceCostApprove,
    };
};

export default CM0052Service;
