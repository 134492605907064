import { AM010Api } from "./ServiceImpl";
import COMMON from "../../../../common/constants/COMMON";

const AM010Service = (am010Api: AM010Api) => {
  const getProjects = async (params: {
    page: number;
    size: number;
    sortBy?: string;
    sortType?: string;
    keyword?: string;
  }): Promise<any> => {
    try {
      return await am010Api.getProjects(params);
    } catch (error) {
      throw error;
    }
  };

  const checkIn = async (params: any): Promise<any> => {
    try {
      return await am010Api.checkIn(params);
    } catch (error) {
      throw error;
    }
  };

  const checkOut = async (params: any): Promise<any> => {
    try {
      return await am010Api.checkOut(params);
    } catch (error) {
      throw error;
    }
  };

  const getSummaryRequest = async (params: any): Promise<any> => {
    try {
      return await am010Api.getSummaryRequest(params);
    } catch (error) {
      throw error;
    }
  };

  const getRequestAttendanceMonthly = async (params: any): Promise<any> => {
    try {
      return await am010Api.getRequestAttendanceMonthly(params);
    } catch (error) {
      throw error;
    }
  };

  const getRequestLateEarlyMonthly = async (params: any): Promise<any> => {
    try {
      return await am010Api.getRequestLateEarlyMonthly(params);
    } catch (error) {
      throw error;
    }
  };

  const getRequestLeaveMonthly = async (params: any): Promise<any> => {
    try {
      return await am010Api.getRequestLeaveMonthly(params);
    } catch (error) {
      throw error;
    }
  };

  const getRequestInformation = async (params: any): Promise<any> => {
    try {
      return await am010Api.getRequestInformation({
        ...params,
        // timeOffset: COMMON.TIME_ZONE,
      });
    } catch (error) {
      throw error;
    }
  };

  const getAttendanceReport = async (params: any): Promise<any> => {
    try {
      return await am010Api.getAttendanceReport({
        ...params,
        // timeOffset: COMMON.TIME_ZONE,
      });
    } catch (error) {
      throw error;
    }
  };

  const getAttendanceUserConstruction = async (params: any): Promise<any> => {
    try {
      return await am010Api.getAttendanceUserConstruction(params);
    } catch (error) {
      throw error;
    }
  };

  const getProjectById = async (params: any): Promise<any> => {
    try {
      return await am010Api.getProjectById(params);
    } catch (error) {
      throw error;
    }
  };

  const getProjectStatus = async (params: any): Promise<any> => {
    try {
      return await am010Api.getProjectStatus(params);
    } catch (error) {
      throw error;
    }
  };

  return {
    getProjects,
    checkIn,
    checkOut,
    getSummaryRequest,
    getRequestAttendanceMonthly,
    getRequestLateEarlyMonthly,
    getRequestLeaveMonthly,
    getRequestInformation,
    getAttendanceReport,
    getAttendanceUserConstruction,
    getProjectById,
    getProjectStatus,
  };
};

export default AM010Service;
