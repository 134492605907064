import axiosConfig from '../../../../../config/axiosConfig';
import { ReceivedMoneyOffset } from '../../entity/Entity';

const CM0012_2Api = () => {
    const getListReceivedMoneyOffset = async (params: any): Promise<any> => {
        try {
            const url = '/money/direct-cost/outsource/offset/list';
            const response = await axiosConfig.get(url, { params });
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    const getSubCategory = async (): Promise<any> => {
        try {
            const url = '/money/received-money-offset/sub-category';
            const response = await axiosConfig.get(url);
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    const createReceivedMoneyOffset = async (data: any): Promise<any> => {
        try {
            const url = '/money/received-money-offset/create';
            const response = await axiosConfig.post(url, { data });
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    const deleteReceivedMoneyOffset = async (data: any): Promise<any> => {
        try {
            const url = '/money/received-money-offset/delete';
            const response = await axiosConfig.delete(url, { data });
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    const editReceivedMoneyOffset = async (data: any): Promise<any> => {
        try {
            const url = '/money/received-money-offset/edit';
            const response = await axiosConfig.put(url, { data });
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    return {
        getListReceivedMoneyOffset,
        getSubCategory,
        createReceivedMoneyOffset,
        deleteReceivedMoneyOffset,
        editReceivedMoneyOffset,
    };
};

export default CM0012_2Api;
