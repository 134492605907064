import { Modal, Form, Input, Button } from "antd";

import IconCircleRed from "../../../../../assets/icons/infor-circle.svg";
import { AM028ModalHandler } from "../handler/HandlerImpl";
import REGEX from "../../../../../common/constants/REGEX";
import MESSAGE from "../../../../../common/constants/MESSAGE";
import helpers from "../../../../../common/helpers/common";

interface props {
  handler: AM028ModalHandler;
}
const ModalAM037 = ({ handler }: props) => {
  return (
    <Modal
      className="am028-modal"
      centered
      visible={handler.visibleAM037}
      width={"50%"}
      closeIcon={<></>}
      footer={null}
      maskClosable={false}
    >
      <div className="am028-modal-title">
        <img src={IconCircleRed} alt="" />
        <span>否認理由</span>
      </div>
      <Form
        name="rejectReason"
        form={handler.formReject}
        onFinish={handler.postRejectReason}
        onValuesChange={() => handler.setChangeReject(true)}
      >
        <div className="am028-modal-form-item">
          <span className="am028-modal-form-item-label">理由</span>
          <Form.Item
            name="rejectReason"
            rules={[
              () => ({
                validator(_, value) {
                  if (value && value !== "") {
                    // CHECK INPUT VIETNAMESE
                    if (!REGEX.NOT_VIETNAM.test(value)) {
                      return Promise.reject(
                        new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                      );
                    }
                    if (value.length > 200) {
                      return Promise.reject(new Error(""));
                    }
                    return Promise.resolve();
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input.TextArea
              size="large"
              maxLength={200}
              autoSize={{ minRows: 1, maxRows: 8 }}
              autoComplete={undefined}
              onBlur={() =>
                helpers.onBlurTrimFormItem(handler.formReject, "rejectReason")
              }
              showCount={{
                formatter: ({ count }: { count: number }) => `${count}/200`,
              }}
            />
          </Form.Item>
        </div>
        <div className="am028-modal-form-btn">
          <Button
            size="large"
            className="button-purple"
            htmlType="submit"
            style={{
              letterSpacing: -1,
              marginRight: 16,
            }}
          >
            送信
          </Button>
          <Button size="large" onClick={handler.handleCancelAM037}>
            キャンセル
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalAM037;
