import React from "react";
import ExpandedIconSvg from "../../../../../assets/icons/expanded-icon.svg";
import ClosedIconSvg from "../../../../../assets/icons/closed-icon.svg";
import { ConfigProvider } from "antd";
import type { TableColumnsType } from "antd";
import { Table } from "antd";
import ConstructionComponent from "../component/construction";
import { ConstructionType } from "../../entity/Entity";
import helpers from "../../../../../common/helpers/common";
import locale from "antd/es/date-picker/locale/ja_JP";

type props = {
  onTableChange: (pagination: any, filters: any, sorter: any) => void;
  setDefaultSorter(sortBy: string): any;
  isCollapsed: (record: any) => boolean;
  onChangeCollapseRow: (record: any, collapsed: boolean) => void;
  dataSource: ConstructionType[];
  expandKey: any[];
};

const TableComponent = ({
  onTableChange,
  setDefaultSorter,
  isCollapsed,
  onChangeCollapseRow,
  dataSource,
  expandKey,
}: props) => {
  const columns: TableColumnsType<ConstructionType> = [
    {
      title: "No",
      key: "id",
      width: "10%",
      render: (_, record, index) => helpers.getNoByIndex(index),
    },
    {
      title: "案件名",
      key: "案件名",
      width: "30%",
      render: (record) => <span>{record.name}</span>,
    },
    {
      title: "合計金額",
      key: "total_cost",
      sorter: true,
      showSorterTooltip: false,
      sortDirections: ["ascend", "descend", "ascend"],
      width: "30%",
      sortOrder: setDefaultSorter("total_cost"),
      render: (record) => `${helpers.formatCurrency(record?.laborCost)}円`,
    },
    {
      title: "稼働人工数",
      key: "working_day",
      sorter: true,
      showSorterTooltip: false,
      sortDirections: ["ascend", "descend", "ascend"],
      width: "30%",
      sortOrder: setDefaultSorter("working_day"),
      render: (record) => `${helpers.formatCurrency(record.totalWorkingDay || 0)}人工`,
    },

    {
      title: "",
      key: "collapsed",
      width: 40,
      render: (record) => {
        return isCollapsed(record) ? (
          <div
            style={{ cursor: "pointer" }}
            onClick={(e: any) => onChangeCollapseRow(record, false)}
          >
            <img alt="" src={ExpandedIconSvg} />
          </div>
        ) : (
          <div
            style={{ cursor: "pointer" }}
            onClick={(e: any) => onChangeCollapseRow(record, true)}
          >
            <img alt="" src={ClosedIconSvg} />
          </div>
        );
      },
    },
  ];
  return (
    <div className="pm025-table row-member-background">
      <Table
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) =>
              isCollapsed(record)
                ? onChangeCollapseRow(record, false)
                : onChangeCollapseRow(record, true), // click row
          };
        }}
        rowClassName={() => {
          return "button-style";
        }}
        scroll={{ x: 0, y: 580 }}
        bordered={false}
        id="main-table"
        onChange={onTableChange}
        className="am-main-table"
        columns={columns}
        dataSource={helpers.addKeyToDataSource(dataSource)}
        pagination={false}
        expandable={{
          showExpandColumn: false,
          expandedRowKeys: expandKey,
          expandedRowRender: (value: any) => (
            <ConstructionComponent construction={value} />
          ),
        }}
      />
    </div>
  );
};

export default TableComponent;
