import { Modal, Button } from "antd";

import IconCircleRed from "../../../../../assets/icons/infor-circle.svg";
import { AM028ModalHandler } from "../handler/HandlerImpl";

interface props {
  handler: AM028ModalHandler;
}

const ModalAM038 = ({ handler }: props) => {
  return (
    <Modal
      className="am028-modal"
      width="38%"
      closeIcon={<></>}
      centered
      visible={handler.visibleAM038}
      footer={false}
      maskClosable={false}
    >
      <div className="am038-title">
        <img src={IconCircleRed} alt="" />
        <span>否認理由</span>
      </div>
      <div className="am038-content">
        <span className="am038-content-label">理由：</span>
        <span className="am038-content-title">{handler.rejectReason}</span>
      </div>
      <div className="am038-btn">
        <Button
          className="button-purple"
          size="large"
          onClick={handler.handleOkAM038}
        >
          閉じる
        </Button>
      </div>
    </Modal>
  );
};

export default ModalAM038;
