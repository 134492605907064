import React, { useMemo } from 'react';
import { CM0033Handler } from '../handler/HandlerImpl';
import IconDown from '../../../../../assets/icons/icon-collapse-cm.svg';
import IconView from '../../../../../assets/icons/eyeIconBlack.svg';
import { Empty } from 'antd';
import helpers from '../../../../../common/helpers/common';
import InfiniteScroll from 'react-infinite-scroll-component';
import { TAX_CODE } from '../../../../../common/constants/COMMON';

interface props {
    handler: CM0033Handler;
}

const NO_DATA = 'データなし';

const Table = ({ handler }: props) => {
    const contentTable = useMemo(() => {
        if (!handler.dataView) return <></>;
        const elementTable: any = [];
        handler.dataView.forEach((element: any, idx: number) => {
            elementTable.push(
                <React.Fragment key={element.key}>
                    <div style={{ height: 4 }}></div>
                    <tr className="row-level-0 button-style" onClick={() => handler.funcSetExpendKey(element.key)}>
                        <td colSpan={2}>{idx + 1}</td>
                        <td colSpan={5} style={{ textAlign: 'left', paddingLeft: 50 }}>
                            {helpers.getShortTextWithToolTip(element.traderName, 15)}
                        </td>
                        <td>{helpers.formatCurrency(element.taxAtion)}円</td>
                        <td>{helpers.formatCurrency(element.taxExempt)}円 </td>
                        <td>{helpers.formatCurrency(element.depositAmount)}円 </td>
                        <td>{helpers.formatCurrency(element.consumptionTax)}円 </td>
                        <td>{helpers.formatCurrency(element.totalTax)}円 </td>
                        <td>
                            <img
                                alt=""
                                src={IconDown}
                                className={`cm024-icon ${handler.funcCheckKeyExpand(element.key)
                                    ? ''
                                    : 'cm024-icon-active'
                                    }`}
                            />
                        </td>
                    </tr>
                </React.Fragment>,
            );

            if (element.children) {
                element.children.forEach((child1: any, idx: number) => {
                    elementTable.push(
                        <tr
                            key={child1.key}
                            className="bg-fa button-style"
                            style={{
                                display: handler.funcCheckKeyExpand(element.key)
                                    ? undefined
                                    : 'none',
                            }}
                            onClick={() => handler.funcSetExpendKey(child1.key)}

                        >
                            <td colSpan={2}>{idx + 1}</td>
                            <td
                                className="border-right-none"
                                colSpan={5}
                                style={{ textAlign: 'left', paddingLeft: 50 }}
                            >
                                {helpers.getShortTextWithToolTip(child1.traderName, 15)}
                            </td>
                            <td className="border-right-none">
                                {helpers.formatCurrency(child1.taxAtion)}円
                            </td>
                            <td>{helpers.formatCurrency(child1.taxExempt)}円</td>
                            <td>{helpers.formatCurrency(child1.depositAmount)}円</td>
                            <td>{helpers.formatCurrency(child1.consumptionTax)}円</td>
                            <td>{helpers.formatCurrency(child1.totalTax)}円</td>

                            <td>
                                <img
                                    alt=""
                                    src={IconDown}
                                    className={`cm024-icon ${handler.funcCheckKeyExpand(child1.key)
                                        ? ''
                                        : 'cm024-icon-active'
                                        }`}
                                />
                            </td>
                        </tr>,
                    );
                    elementTable.push(
                        <tr
                            key={`table${child1.key}`}
                            className="bg-fa"
                            style={{
                                display:
                                    handler.funcCheckKeyExpand(element.key) &&
                                        handler.funcCheckKeyExpand(child1.key)
                                        ? undefined
                                        : 'none',
                            }}
                        >
                            <td colSpan={2}></td>
                            <td colSpan={1} style={{ width: 140 }}>
                                工事名
                            </td>
                            <td colSpan={2} style={{ width: 200 }}>
                                対象期間
                            </td>
                            <td colSpan={2} style={{ width: 140 }}>
                                入金日
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>,
                    );
                    if (child1.children && child1.children.length > 0) {
                        child1.children.forEach((child2: any, idx: number) => {
                            elementTable.push(
                                <tr
                                    key={child2.key}
                                    style={{
                                        display:
                                            handler.funcCheckKeyExpand(element.key) &&
                                                handler.funcCheckKeyExpand(child1.key)
                                                ? undefined
                                                : 'none',
                                    }}
                                >
                                    <td style={{ width: 50 }}></td>
                                    <td style={{ width: 50 }}>{idx + 1}</td>
                                    <td>
                                        {helpers.getShortTextWithToolTip(
                                            child2.constructionDescription,
                                            15,
                                        )}
                                    </td>
                                    <td
                                        colSpan={3}
                                    >{`${child2?.startDate}～${child2?.endDate}`}</td>
                                    <td>{child2.receiveAt}</td>
                                    <td>
                                        {helpers.formatCurrency(child2.taxAtion)}円{' '}
                                        <img
                                            className="icon-view"
                                            alt=""
                                            src={IconView}
                                            onClick={() =>
                                                handler.handleViewDetail(
                                                    child2.id,
                                                    child2.taxId,
                                                    child1.traderName,
                                                    element.traderName,
                                                    `${child2?.startDate}～${child2?.endDate}`,
                                                    true,
                                                )
                                            }
                                        />
                                    </td>
                                    <td>
                                        {helpers.formatCurrency(child2.taxExempt)}円{' '}
                                        <img
                                            className="icon-view"
                                            alt=""
                                            src={IconView}
                                            onClick={() =>
                                                handler.handleViewDetail(
                                                    child2.id,
                                                    child2.taxFreeId,
                                                    child1.traderName,
                                                    element.traderName,
                                                    `${child2?.startDate}～${child2?.endDate}`,
                                                    true,
                                                )
                                            }
                                        />
                                    </td>
                                    <td>{helpers.formatCurrency(child2.depositAmount)}円</td>
                                    <td>{helpers.formatCurrency(child2.consumptionTax)}円</td>
                                    <td>{helpers.formatCurrency(child2.totalTax)}円</td>
                                    <td></td>
                                </tr>,
                            );
                        });
                    } else {
                        elementTable.push(
                            <tr
                                key={`table${element.key}nodata`}
                                style={{
                                    display: handler.funcCheckKeyExpand(element.key)
                                        ? undefined
                                        : 'none',
                                }}
                            >
                                <td colSpan={13}>
                                    <Empty
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        description={NO_DATA}
                                    />
                                </td>
                            </tr>,
                        );
                    }
                });
            }
        });
        return elementTable;
    }, [handler.dataView, handler.expandKey]);

    return (
        <div className="cm024-table" style={{ overflowY: 'hidden' }}>
            <InfiniteScroll
                dataLength={handler.dataView.length}
                hasMore={true}
                next={handler.handlerScrollInfinity}
                height={'60vh'}
                loader={null}
            >
                <table>
                    <thead>
                        <tr>
                            <th colSpan={2} rowSpan={2} style={{ minWidth: '110px' }}>
                                No
                            </th>
                            <th
                                rowSpan={2}
                                colSpan={5}
                                style={{ textAlign: 'left', paddingLeft: 50, minWidth: '580px' }}
                            >
                                発注元会社名
                            </th>
                            <th colSpan={2} style={{ minWidth: '185px' }}>
                                相殺金額
                            </th>
                            <th rowSpan={2} style={{ minWidth: '170px' }}>
                                入金額(税抜)
                            </th>
                            <th rowSpan={2} style={{ minWidth: '170px' }}>
                                消費税
                            </th>
                            <th rowSpan={2} style={{ minWidth: '170px' }}>
                                入金額(税込)
                            </th>
                            <th rowSpan={2} style={{ minWidth: '40px', padding: '10px' }}></th>
                        </tr>
                        <tr className="tr-border-first">
                            <th style={{ minWidth: '120px' }}>課税</th>
                            <th style={{ minWidth: '120px' }}>非課税</th>
                        </tr>
                    </thead>
                    <tbody>{contentTable}</tbody>
                    <tfoot>
                        <tr className="table-foot">
                            <td colSpan={7} style={{ textAlign: 'left', paddingLeft: 40 }}>
                                合計金額
                            </td>
                            <td>{helpers.formatCurrency(handler.footer.taxAtion)}円</td>
                            <td>{helpers.formatCurrency(handler.footer.taxExempt)}円 </td>
                            <td>{helpers.formatCurrency(handler.footer.depositAmount)}円 </td>
                            <td>{helpers.formatCurrency(handler.footer.consumptionTax)}円 </td>
                            <td>{helpers.formatCurrency(handler.footer.totalTax)}円 </td>
                            <td></td>
                        </tr>
                    </tfoot>
                </table>
            </InfiniteScroll>
        </div>
    );
};

export default Table;
