import AM008Api from "./infrastructure/api/Api"
import AM008Handler from "./presenter/handler/Handler"
import MainUI from "./presenter/ui/UI"
import AM008Service from "./usecase/Service"

import "../../../assets/styles/AM/AM002.css"
import "../../../assets/styles/OM/OM002.css";
import "../../../assets/styles/AM/AM004.css";
import AttendanceApi from "./infrastructure/api/ApiAttendance"
import AttendanceService from "./usecase/ServiceAttendance"

const AM008Component = () => {
    const api = AM008Api()
    const attendanceApi = AttendanceApi()

    const service = AM008Service(api)
    const attendanceService = AttendanceService(attendanceApi)

    const handler = AM008Handler(service, attendanceService)
    return <MainUI handler={handler} />
}

export default AM008Component