import COMMON from "../../../../common/constants/COMMON";
import { ProjectAllsResultsPagination } from "../../CM0029/entity/Entity";
import { WorkflowResponseData } from "../../CM008/entity/Entity";
import {
  ReSummaryCompanyProjectOutsourceCostRetrievePayload,
  ReSummaryCompanyProjectOutsourceCostRetrieveResults,
  ReSummarySubmittedOutsourceCostCompanyProjectConstructionResults,
  ReSummarySubmittedOutsourceCostCompanyProjectResults,
  ReSummarySubmittedOutsourceCostCompanyResults,
  ReSummarySubmittedOutsourceCostProjectResult,
  ReSummarySubmittedProjectConstructionOutsourceCostResults,
  ReSummarySubmittedProjectConstructionOutsourceCostResultsData,
  ReSummarySubmittedProjectOutsourceCostTotalResults,
} from "../entity/Entity";
import { APIImpl } from "./ServiceImpl";

const Service = (api: APIImpl) => {
  const getReSummarySubmittedOutsourceCostProject = async (params: {
    documentId: number;
    page?: number;
    size?: number;
  }): Promise<ReSummarySubmittedOutsourceCostProjectResult> => {
    try {
      const response = await api.getReSummarySubmittedOutsourceCostProject(
        params
      );
      const { results, pagination } = response.data;
      const responseData: ReSummarySubmittedOutsourceCostProjectResult = {
        results,
        pagination,
      };
      return responseData;
    } catch (error) {
      throw error;
    }
  };
  const getReSummarySubmittedProjectConstructionOutsourceCost = async (params: {
    documentId: number;
    projectId: number;
  }): Promise<
    ReSummarySubmittedProjectConstructionOutsourceCostResultsData[]
  > => {
    try {
      const response =
        await api.getReSummarySubmittedProjectConstructionOutsourceCost(params);
      return response.data?.results ?? [];
    } catch (error: any) {
      throw error;
    }
  };
  const getReSummarySubmittedProjectOutsourceCostTotal = async (params: {
    documentId: number;
  }): Promise<ReSummarySubmittedProjectOutsourceCostTotalResults> => {
    try {
      const response = await api.getReSummarySubmittedProjectOutsourceCostTotal(
        params
      );
      return (
        response.data?.results ?? {
          actualCost: 0,
          offsetCostSummaryWithTax: 0,
          offsetCostSummaryWithoutTax: 0,
          offsetCostWithTax: 0,
          offsetCostWithoutTax: 0,
          tax: 0,
        }
      );
    } catch (error: any) {
      throw error;
    }
  };
  const putReSummaryProjectOutsourceCostRetrieve = async (
    data: ReSummaryCompanyProjectOutsourceCostRetrievePayload
  ): Promise<ReSummaryCompanyProjectOutsourceCostRetrieveResults> => {
    try {
      const response = await api.putReSummaryProjectOutsourceCostRetrieve(data);
      return response.data;
    } catch (error: any) {
      throw error;
    }
  };
  const getWorkflowSummaryOutsourceProjectApprovalProcess = async (params: {
    documentId: number;
  }): Promise<WorkflowResponseData> => {
    try {
      const response =
        await api.getWorkflowSummaryOutsourceProjectApprovalProcess(params);
      return response.data;
    } catch (error: any) {
      throw error;
    }
  };
  return {
    putReSummaryProjectOutsourceCostRetrieve,
    getReSummarySubmittedOutsourceCostProject,
    getReSummarySubmittedProjectConstructionOutsourceCost,
    getReSummarySubmittedProjectOutsourceCostTotal,
    getWorkflowSummaryOutsourceProjectApprovalProcess,
  };
};

export default Service;
