// package
import { Button, Form, Input } from 'antd';
import { ASForgotPasswordHandler } from '../handler/HandlerImpl';

// css
import '../../../../../assets/styles/S-001/B001.css';

// icon
import LogoBlack from '../../../../../assets/images/logo-black.svg';
import AuthenticationSideImage from '../../../../../common/components/authentication-side-image/AuthenticationSideImage';
import REGEX from '../../../../../common/constants/REGEX';

interface props {
  handler: ASForgotPasswordHandler;
}

const ASForgotPasswordUI = ({ handler }: props) => {
  return (
    <div className="login-container">
      <div className="half-container">
        <div className="login-form">
          <div style={{ marginBottom: 64 }}>
            <img alt="" src={LogoBlack} />
          </div>
          <div className="login-title" style={{ marginBottom: 32 }}>
            パスワードを変更または忘れた場合
          </div>
          <Form
            layout="vertical"
            form={handler.form}
            onFinish={handler.forgot}
            initialValues={{ remember: false }}
          >
            <Form.Item
              name="email"
              className="input-style"
              style={{ border: 'none', outlineColor: '#ffffff' }}
              label="ID/メールアドレス"
              rules={[
                {
                  pattern: new RegExp(REGEX.USERNAME_EMAIL_REGEX),
                  message: 'アカウントまたはメールアドレスの形式が不正です。',
                },
                {
                  required: true,
                  message: '',
                },
              ]}
            >
              <Input
                placeholder="IDまたはメールアドレスを入力してください"
                size="large"
              />
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                size="large"
                className="button-purple w-100-percent "
              >
                次へ
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div
          style={{
            position: 'fixed',
            bottom: 32,
            width: '50%',
            textAlign: 'center',
          }}
        >
          <Button className="" type="link" onClick={handler.backLogin}>
            <div style={{ borderBottom: '1px solid #605BFF', height: 19 }}>
              ログイン
            </div>
          </Button>
        </div>
      </div>
      <AuthenticationSideImage />
    </div>
  );
};

export default ASForgotPasswordUI;
