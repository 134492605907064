import AM012Api from "./infrastructure/api/Api";
import AM012Handler from "./presenter/handler/Handler";
import AM012UI from "./presenter/ui/UI";
import AM012Service from "./usecase/Service";

const AM012Component = () => {
  const am012Api = AM012Api();
  const am012Service = AM012Service(am012Api);
  const am012Handler = AM012Handler(am012Service);
  return <AM012UI handler={am012Handler} />;
};

export default AM012Component;
