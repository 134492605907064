import { useState } from "react";
import { useDispatch } from "react-redux";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../../common/constants/MESSAGE";
import { setIsVisibleAM018, setRequestIdAM018, setTypeModalAM018 } from "../../../AM018/presenter/slice/Slice";
import { setIsVisibleAM027, setRequestIdAM027, setScreenRequestToAM027 } from "../../../AM027/presenter/slice/Slice";
import { AM020UseCase } from "../../usecase/ServiceImpl";
import { resetAM020 } from "../slice/Slice";


const AM020Handler = (am020Service: AM020UseCase) => {
  // LIB FUNCTION
  const dispatch = useDispatch();

  // STATE
  const [requestLateEarly, setRequestLateEarly] = useState<any>({});

  // FUNCTION
  const handleGetRequestLateEarly= async (requestId:any):Promise<any>=>{
    try{
      const params ={
        requestId:requestId
      }
      const res = await am020Service.getRequestLateEarly(params);
      setRequestLateEarly(res.results);
    }catch(error: any){
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  }

  const handleOpenModalConfirmDelete = (requestId:number[])=>{
    dispatch(setIsVisibleAM027(true));
    dispatch(setRequestIdAM027(requestId))
    dispatch(setScreenRequestToAM027("am020"))
  }

  const handleOpenModalUpdate = (requestId: number) =>{
    dispatch(setIsVisibleAM018(true));
    dispatch(setTypeModalAM018("update"));
    dispatch(setRequestIdAM018(requestId));
  }

  const onCancel = () => {
    dispatch(resetAM020());
  };

  return {
    requestLateEarly,
    onCancel,
    handleGetRequestLateEarly,
    handleOpenModalConfirmDelete,
    handleOpenModalUpdate
  };
};

export default AM020Handler;
