import React, { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { HandlerImpl } from "../handler/HandlerImpl";
import BreadcrumbComponent from "../../../../../common/components/breadcrumb/BreadcrumbComponent";
import { Button, Form, Layout, Tabs } from "antd";
import UploadLightSVG from "../../../../../assets/icons/upload-icon-purple.svg";
import HomeSvg from "../../../../../assets/icons/home.svg";
import TableComponent from "./Table";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import MenuWrapperComponent from "../../../../../common/components/menu-wrapper";
import FilterComponent from "../component/filter";
import PaginationComponent from "./Pagination";
import moment from "moment";
import { getTitleByFromToDate } from "../../helper";
import COMMON, { PLAN_TYPE } from "../../../../../common/constants/COMMON";
import SearchBarComponent from "../component/filter/ui/SearchBar";
import AM042Component from "../../../AM042";
import { OpenStreetMapComponent } from "../../../../../common/components/open-street-map/OpenStreetMapComponent";
import {
  AUTHORIZATION_CODE,
  SYSTEM_ROLES,
} from "../../../../../common/constants/AUTHORIZATION";
import NoDataTableComponent from "../../../../../common/components/no-data/NoDataTable";

interface props {
  handler: HandlerImpl;
}
const { Content, Sider } = Layout;

const BREADCRUMB_AM002: any[] = [
  {
    title: "勤怠管理",
    url: "",
    icon: (
      <img style={{ paddingBottom: 2, paddingRight: 8 }} alt="" src={HomeSvg} />
    ),
  },
  {
    title: "自社勤務実績",
    url: "",
    icon: "",
  },
];

const MainUI = ({ handler }: props) => {
  // Ref
  const mountFirst = useRef(false);

  const isVisibleStreetMap = useSelector(
    (state: RootState) => state.openStreetMap.isVisible
  );
  const tableColumns = useSelector(
    (state: RootState) => state.am008.tableColumns
  );
  const loading = useSelector((state: RootState) => state.common.loading);
  // Params
  const [searchParams] = useSearchParams();
  const fromDate =
    searchParams.get("startDate") ||
    moment().startOf("month").format(COMMON.FORMAT_DATE2);
  const toDate =
    searchParams.get("endDate") || moment().format(COMMON.FORMAT_DATE2);
  const pageDate = searchParams.get("pageDate");
  const keyword = searchParams.get("keyword") || "";
  const tab = searchParams.get("tab") || "1";
  const constructionId =
    searchParams.get("selectedConstructionId") || undefined;
  const page = searchParams.get("page") || COMMON.DEFAULT_PAGE + "";
  const size = searchParams.get("size") || COMMON.DEFAULT_SIZE + "";
  const isCollapsed = searchParams.get("isCollapsed") ? true : false;
  const permission = useSelector(
    (state: RootState) => state.auth.authData?.permission
  );

  const emptyontructions = useSelector(
    (state: RootState) => state.am008.emptyConstruction
  );

  const isRoleAdmin = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdmin
  );

  const isRoleAdminSystem = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdminSystem
  );

  const isOrderBasic =
    useSelector((state: RootState) => state.auth.authData?.order?.code) ===
    PLAN_TYPE.PLAN_BASIC;

  useEffect(() => {
    handler.initiateFilter();
  }, []);

  useEffect(() => {
    if (mountFirst.current) handler.onPagingMember(page, size, keyword);
    mountFirst.current = true;
  }, [page, keyword, size]);

  useEffect(() => {
    handler.calculateTableHeader(fromDate, toDate);
  }, [fromDate, toDate, pageDate]);

  useEffect(() => {
    (async () => {
      if (tableColumns.columns.length === 0) return;
      const params: any = {
        page: page,
        size: size,
        from: fromDate,
        to: toDate,
        keyword: keyword,
      };
      if (tab === "1") {
        if (constructionId) {
          params.constructionId = parseInt(constructionId);
          await handler.getAttendanceConstructionListMyMember(params);
        } else {
          handler.resetData();
        }
      } else await handler.getAttendanceOffsiteListMyMember(params);
    })();
  }, [
    tab,
    JSON.stringify(tableColumns),
    constructionId,
    fromDate,
    toDate,
    keyword,
  ]);

  return (
    <>
      <MenuWrapperComponent chosenKey={24} openKey={2}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
          }}
        >
          <div className="om002-header-container">
            <BreadcrumbComponent data={BREADCRUMB_AM002} />

            <div className="header-row-title" style={{ marginTop: 8 }}>
              自社勤務実績
            </div>
          </div>
          <div>
            <Form form={handler.form}>
              <SearchBarComponent onSearch={handler.onSearch} width={1082} />
            </Form>
          </div>
          {(permission?.includes(AUTHORIZATION_CODE.B9) || isRoleAdmin) &&
          isOrderBasic ? (
            <Button
              className="button-brown-3"
              style={{ border: "1px solid #605BFF", width: 106 }}
              size="large"
              onClick={() =>
                handler.exportData({
                  page: page,
                  size: 1000,
                  from: fromDate,
                  to: toDate,
                  keyword: keyword,
                  constructionId: tab === "1" ? constructionId : undefined,
                })
              }
              disabled={!handler.members.length}
            >
              <div className="center-item">
                <img
                  alt=""
                  src={UploadLightSVG}
                  style={{
                    marginRight: 8,
                    fontWeight: 700,
                    fontSize: 14,
                  }}
                />
                <div className="export-button" style={{ color: "#605BFF" }}>
                  出力
                </div>
              </div>
            </Button>
          ) : (
            <div></div>
          )}
        </div>
        <Layout
          style={{ minHeight: "84vh", marginTop: 24 }}
          className={isCollapsed ? "close-side" : "open-side"}
        >
          <Sider>
            <FilterComponent />
          </Sider>
          <Layout style={{ backgroundColor: "#ffffff" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginLeft: 24,
                marginTop: 24,
                marginBottom: 18,
              }}
            >
              <div style={{ display: "flex", minWidth: "20%" }}>
                <Tabs onChange={handler.onTabChange} activeKey={tab}>
                  <Tabs.TabPane tab="現場出勤" key="1" />
                  <Tabs.TabPane tab="非現場出勤" key="2" />
                </Tabs>
              </div>
              <div className="date-label" style={{ minWidth: "20%" }}>
                {getTitleByFromToDate(fromDate, toDate)}
              </div>
            </div>
            <Content
              style={{
                margin: "0px 24px 16px 24px",
                backgroundColor: "#ffffff",
              }}
            >
              {loading ? (
                <></>
              ) : (
                <TableComponent
                  isCollapsed={handler.isCollapsed}
                  onChangeCollapseRow={handler.onChangeCollapseRow}
                  expandKey={handler.expandKey}
                  setExpandKey={handler.setExpandKey}
                  tab={tab}
                  constructionId={tab === "1" ? constructionId : undefined}
                  onNext={handler.onNext}
                  onPrev={handler.onPrev}
                  dataSource={handler.members}
                  columnsTable={tableColumns.columns}
                  isPrev={tableColumns.isPrev}
                  isNext={tableColumns.isNext}
                  noData={handler.projectList.length == 0 || emptyontructions}
                />
              )}
            </Content>
            {!emptyontructions && handler.projectList.length != 0 ? (
              <PaginationComponent
                onPageChange={handler.onPageChange}
                onSizeChange={handler.onSizeChange}
                total={handler.totalRecord}
              />
            ) : (
              <></>
            )}
          </Layout>
        </Layout>
      </MenuWrapperComponent>
      <AM042Component />
      {isVisibleStreetMap ? <OpenStreetMapComponent /> : <></>}
    </>
  );
};

export default MainUI;
