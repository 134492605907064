import { Checkbox } from "antd";
import {
  AUTHORIZATION_CODE,
  SYSTEM_ROLES,
} from "../../../../../common/constants/AUTHORIZATION";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { PLAN_TYPE } from "../../../../../common/constants/COMMON";

const InfoTable = () => {
  const subscription =
    useSelector(
      (state: RootState) => state.auth?.authData?.order?.subscription
    ) ?? [];

  const isB6 = subscription.some(
    (element) => element.code === AUTHORIZATION_CODE.B6
  );
  const isM19 = subscription.some(
    (element) => element.code === AUTHORIZATION_CODE.M19
  );
  const isM18 = subscription.some(
    (element) => element.code === AUTHORIZATION_CODE.M18
  );
  const isM17 = subscription.some(
    (element) => element.code === AUTHORIZATION_CODE.M17
  );

  const columns = (columnStr: string[]) => {
    return [
      {
        title: columnStr[0],
        width: 112,
        render: (_: any, record: any, index: any) => index + 1,
      },
      {
        title: columnStr[1],
        dataIndex: "function",
        width: 400,
      },
      {
        title: columnStr[2],
        dataIndex: "approve",
        width: 250,
        render: (record: any) => {
          return (
            record !== undefined && (
              <Checkbox disabled defaultChecked={record} />
            )
          );
        },
      },
      {
        title: columnStr[3],
        dataIndex: "option",
        width: 250,
        render: (record: any) => {
          return (
            record !== undefined && (
              <Checkbox disabled defaultChecked={record} />
            )
          );
        },
      },
      {
        title: columnStr[4],
        dataIndex: "propose",
        width: 250,
        render: (record: any) => {
          return (
            record !== undefined && (
              <Checkbox disabled defaultChecked={record} />
            )
          );
        },
      },
    ];
  };

  const dataUnitTableOne = [
    {
      key: Math.random(),
      no: "1",
      function: "工事引合書",
      approve: undefined,
      codeApprove: "S14",
    },
    {
      key: Math.random(),
      no: "2",
      function: "実行予算書",
      approve: undefined,
      codeApprove: "S18",
    },
  ];

  const dataUnitTableTwo = [
    {
      key: Math.random(),
      no: "1",
      function: "案件別勤務実績",
      approve: undefined,
      codeApprove: "M9",
    },
    {
      key: Math.random(),
      no: "2",
      function: "会社別勤務実績",
      approve: undefined,
      codeApprove: "M9",
    },
    {
      key: Math.random(),
      no: "3",
      function: "自社勤務実績",
      approve: undefined,
      codeApprove: "M10",
    },
  ];

  const dataUnitTwoTableOne = [
    {
      key: Math.random(),
      no: "1",
      function: "部門・メンバー",
      option: undefined,
      codeOption: "M12",
    },
  ];

  const dataUnitTwoTableTwo = [
    {
      key: Math.random(),
      no: "1",
      function: "案件管理",
      approve: undefined,
      codeApprove: "B10",
    },
    {
      key: Math.random(),
      no: "2",
      function: "新規案件",
      option: undefined,
      codeOption: "S1",
    },
  ];

  const dataUnitTwoTableThree = [
    {
      key: Math.random(),
      no: "1",
      function: "マスタデータ",
      approve: undefined,
      option: undefined,
      codeApprove: "M7",
      codeOption: "M6",
    },
  ];

  const dataUnitTwoTableFour = [
    {
      key: Math.random(),
      no: "1",
      function: "案件別勤務実績",
      approve: undefined,
      codeApprove: "B13",
    },
    {
      key: Math.random(),
      no: "2",
      function: "会社別勤務実績",
      approve: undefined,
      codeApprove: "B13",
    },
    {
      key: Math.random(),
      no: "3",
      function: "自社勤務実績",
      approve: undefined,
      codeApprove: "B11",
    },
  ];

  const dataUnitTwoTableFive1 = isM17
    ? {
        key: Math.random(),
        no: "1",
        function: "案件別収支管理",
        approve: undefined,
        option: undefined,
        propose: undefined,
        codeApprove: "S42",
        codeOption: "S48",
        codePropose: "S49",
      }
    : null;
  const dataUnitTwoTableFive2 = isM18
    ? {
        key: Math.random(),
        no: "2",
        function: "工事代金支払明細書",
        approve: undefined,
        option: undefined,
        propose: undefined,
        codeApprove: "S43",
        codeOption: "S44",
        codePropose: "S45",
      }
    : null;
  const dataUnitTwoTableFive3 = isM19
    ? {
        key: Math.random(),
        no: "3",
        function: "社内収支",
        approve: undefined,
        propose: undefined,
        codeApprove: "S46",
        codePropose: "S47",
      }
    : null;
  const dataUnitTwoTableFive = [
    dataUnitTwoTableFive1,
    dataUnitTwoTableFive2,
    dataUnitTwoTableFive3,
  ].filter((element) => element !== null);

  const dataUnitTwoTableSix1 = isB6
    ? {
        key: Math.random(),
        no: "1",
        function: "ダッシュボード",
        approve: undefined,
        codeApprove: "B6",
      }
    : null;
  const dataUnitTwoTableSix2 = {
    key: Math.random(),
    no: "2",
    function: "ファイル出力",
    option: undefined,
    codeOption: "B9",
  };

  const dataUnitTwoTableSix = [
    dataUnitTwoTableSix1,
    dataUnitTwoTableSix2,
  ].filter((element) => element !== null);

  return {
    columns,
    dataUnitTableOne,
    dataUnitTableTwo,
    dataUnitTwoTableSix,
    dataUnitTwoTableFive,
    dataUnitTwoTableFour,
    dataUnitTwoTableThree,
    dataUnitTwoTableTwo,
    dataUnitTwoTableOne,
  };
};

export default InfoTable;
