import React, { useEffect, useRef } from 'react';
import IconSortUp from '../../../../../../assets/icons/bx_bxs-up-arrow.svg';
import { memo } from 'react';
import moment from 'moment';
import COMMON, {
  STATUS_CM_CHILD_TABLE,
} from '../../../../../../common/constants/COMMON';
import { Empty } from 'antd';
import WarningIcon from '../../../../../../assets/icons/warning-red.svg';

interface props {
  title: string;
  data: {
    createdAt: string;
    documentType: string;
    endDate: string;
    fileName: string;
    id: number;
    reason: string;
    startDate: string;
    status: {
      code: string;
      id: number;
      name: string;
      type: string;
    };
    submittedDate: string;
    updatedAt: string;
    user: {
      branchName: string;
      email: string;
      fullName: string;
      id: number;
      username: string;
    };
  }[];
  sortType: string | null;
  type: string;

  funcSort?: (sortType: string) => void;
  onClickDocument(document: any): void;
  handleViewRejectReason(reason: string): void;
}

const TableView = ({
  title,
  data,
  sortType,
  funcSort,
  onClickDocument,
  handleViewRejectReason,
}: props) => {
  const refTable: any = useRef(null);
  useEffect(() => {
    if (refTable?.current) {
      refTable.current.scrollTop = 0;
    }
  }, [data]);
  return (
    <div className="cm042-item">
      <div className="cm042-item-header">{title}</div>
      <div className="cm042-table-header">
        <table className="cm042-table cm034-table-bg">
          <thead>
            <tr>
              <th className="td-w10">No</th>
              <th className="td-w4">資料名</th>
              <th className="td-w4">ステータス</th>
              <th
                className="td-w4 table-cursor"
                onClick={() => {
                  if (funcSort) {
                    funcSort(!sortType || sortType === 'DESC' ? 'ASC' : 'DESC');
                  }
                }}
              >
                <div className="table-sort-wrapper">
                  提出日
                  <div className="table-sort">
                    <img
                      alt=""
                      src={IconSortUp}
                      className={`icon-up ${
                        sortType === 'ASC' ? 'icon-sorted' : ''
                      }`}
                    />
                    <img
                      alt=""
                      src={IconSortUp}
                      className={`icon-down ${
                        sortType === 'DESC' ? 'icon-sorted' : ''
                      }`}
                    />
                  </div>
                </div>
              </th>
              <th className="td-w4 th-not-border-right">提出者</th>
              <th className="th-scroll"></th>
            </tr>
          </thead>
        </table>
      </div>
      <div className="cm042-table-body" ref={refTable}>
        <table className="cm042-table">
          <tbody>
            {data?.length > 0 ? (
              data?.map((item, index) => (
                <tr key={item.id + (item.documentType ?? '')}>
                  <td className="td-w10">{index + 1}</td>
                  <td
                    className="td-w4 table-cursor"
                    onClick={() => onClickDocument(item)}
                  >
                    {item.fileName}
                  </td>
                  <td className="td-w4">
                    <span
                      style={{
                        color:
                          item.status?.code ===
                          STATUS_CM_CHILD_TABLE.MONEY_REJECT.CODE
                            ? STATUS_CM_CHILD_TABLE.MONEY_REJECT.COLOR
                            : item.status?.code ===
                              STATUS_CM_CHILD_TABLE.MONEY_APPROVED.CODE
                            ? STATUS_CM_CHILD_TABLE.MONEY_APPROVED.COLOR
                            : '#FF9054',
                      }}
                    >{`${item?.status?.name ?? ''}`}</span>

                    {item.status?.code ===
                    STATUS_CM_CHILD_TABLE.MONEY_REJECT.CODE ? (
                      <img
                        alt=""
                        src={WarningIcon}
                        className="cm053-icon"
                        onClick={() => handleViewRejectReason(item.reason)}
                      />
                    ) : null}
                  </td>
                  <td className="td-w4">
                    {item.submittedDate
                      ? moment(item.submittedDate).format(COMMON.FORMAT_DATE_OM)
                      : ''}
                  </td>
                  <td className="td-w4">{`${item?.user.fullName ?? ''}${
                    item?.user?.branchName
                      ? `（${item?.user?.branchName}）`
                      : ''
                  }`}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="no-data-cm0042">
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="データなし"
                  />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default memo(TableView);
