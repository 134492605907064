import SM01Api from './infrastructure/api/Api';
import SM01Handler from './presenter/handler/Handler';
import MainUI from './presenter/ui/UI';
import SM01Service from './usecase/Service';

const SM01Component = () => {
    const api = SM01Api();
    const service = SM01Service(api);
    const handler = SM01Handler(service);

    return <MainUI handler={handler} />;
};

export default SM01Component;
