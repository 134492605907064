import moment from 'moment';
import COMMON from '../../../common/constants/COMMON';
import { ReSummaryCompanySummaryOutsourceCostResult } from '../CM0017/entity/Entity';
import { ReSummarySubmittedCompanyOutsourceCostResults } from './entity/Entity';

const convertMoneyCompanySummaryOutsourceCostResult = (
    value?: ReSummarySubmittedCompanyOutsourceCostResults,
    from?: string,
    to?: string,
) => {
    if (!value) return [];
    /*
    + 支払月: Hiển thị tháng & năm theo filter #7
    Nếu 2 tháng giống nhau tháng a ~ tháng a thì hiển thị tháng a 
    Nếu 2 tháng khác nhau thì hiển thị từ tháng a ~ tháng b
    */
    let paymentDate: string = '';

    if (from && to) {
        const isSameDate = moment(from).isSame(moment(to), 'month');
        paymentDate = isSameDate
            ? `${moment(from).format(COMMON.FORMAT_DATE_JP)}`
            : `${moment(from).format(COMMON.FORMAT_DATE_JP)} ~ ${moment(to).format(
                  COMMON.FORMAT_DATE_JP,
              )}`;
    } else {
        paymentDate = `${!from ? '' : moment(from).format(COMMON.FORMAT_DATE_JP)} ~ ${
            !to ? '' : moment(to).format(COMMON.FORMAT_DATE_JP)
        }`;
    }
    //+ 今回支払額: Cộng tổng tiền 今回支払金額 ở bảng #11

    /*
    + 相殺金額合計
    + 案件別相殺合計金額
    Gồm 2 cột nhỏ: 課税 (Cộng tổng tiền 課税 ở bảng #11) & 非課税 (Cộng tổng tiền 非課税 ở bảng #11)
    */
    const totalOffsetAmountByProject = {};

    /*
    + 業者別相殺合計金額
    Click icon mắt mở ra màn CM0017_1, xem mục 10.1
    Gồm 2 cột nhỏ: 課税 (Cộng tổng tiền 相殺金額 của mục có type 課税 ở màn CM017_1) & 非課税 (Cộng tổng tiền 相殺金額 của mục có type 非課税 ở màn CM017_1)
    + 差引支払額
    Công thức: 今回支払額 - 案件別相殺合計金額(Gồm 2 cột 課税 & 非課税) - 業者別相殺合計金額(Gồm 2 cột 課税 & 非課税)"											
    */

    return [
        {
            ...value,
            key: 'summary',
            paymentDate,
            offsetCostSummaryWithTax: value?.offsetCostSummaryWithTax ?? 0,
            offsetCostSummaryWithoutTax: value?.offsetCostSummaryWithoutTax ?? 0,
            offsetCostWithTax: value?.offsetCostWithTax ?? 0,
            offsetCostWithoutTax: value?.offsetCostWithoutTax ?? 0,
            totalCost: value?.totalCost ?? 0,
        },
    ];
};

export { convertMoneyCompanySummaryOutsourceCostResult };
