import { Button, Form, Input, Modal, Spin } from 'antd';
import { HandlerImpl } from '../handler/HandlerImpl';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import LoadingComponent from '../../../../../common/components/icons/LoadingComponent';
import { useEffect } from 'react';
import MESSAGE from '../../../../../common/constants/MESSAGE';
import OMHeaderModalComponent from '../../../../../common/components/om-header-modal/OMHeaderModalComponent';
import REGEX from '../../../../../common/constants/REGEX';
import helpers from '../../../../../common/helpers/common';
interface props {
    handler: HandlerImpl;
}

const alertUser = (e: any) => {
    e.preventDefault();
    e.returnValue = '';
};

const MainUI = ({ handler }: props) => {
    const document = useSelector((state: RootState) => state.modalApproveEdit.document);

    useEffect(() => {
        if (handler.isDirty) {
            window.addEventListener('beforeunload', alertUser);
            return () => {
                window.removeEventListener('beforeunload', alertUser);
            };
        } else {
            window.removeEventListener('beforeunload', alertUser, true);
        }
        return () => {
            window.removeEventListener('beforeunload', alertUser, true);

        }
    }, [handler.isDirty]);

    return document === null ? null : (
        <Modal
            style={{ marginLeft: 300, marginTop: 40 }}
            className="group-detail-modal no-margin-modal"
            closable={false}
            visible={true}
            maskClosable={false}
            width={996}
            centered={true}
            footer={null}
        >
            <Spin indicator={LoadingComponent} spinning={handler.localLoading}>
                <OMHeaderModalComponent title="変更内容" onCancel={handler.onCancel} />
                <div className=" w-100-percent" style={{ padding: '0px 24px 0px 24px', marginTop: 40.5 }}>
                    <Form
                        form={handler.form}
                        layout="horizontal"
                    >
                        <Form.Item
                            style={{ margin: 0, padding: 0 }}
                            name="explanation"
                            labelAlign="right"
                            colon={false}
                            className="form-item-label form-label-height-40"
                            label={
                                <div
                                    className="om007-form-label pr-20 center-item"
                                    style={{ marginLeft: 80 }}
                                >
                                    内容
                                </div>
                            }
                            rules={[
                                {
                                    required: true,
                                    message: MESSAGE.MESSAGE_REQUIRED_FIELD,
                                },
                                {
                                    pattern: new RegExp(REGEX.EXCEPT_VIETNAMESE),
                                    message: MESSAGE.MESSAGE_INVALID_INPUT,
                                },
                            ]}
                        >
                            <Input
                                placeholder='変更内容を入力してください'
                                onChange={(e: any) => {
                                    helpers.preventInputJapanese(e);
                                    handler.checkDirty();
                                }}
                                maxLength={300}
                                style={{ height: 40 }}
                            />
                        </Form.Item>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: 39.5 }}>
                            <Button
                                size="large"
                                type="primary"
                                style={{ marginLeft: 16, width: 76, letterSpacing: -1.5 }}
                                onClick={async () => await handler.callAPI()}
                            >
                                保存
                            </Button>
                            <Button size="large" className="om-cancel-button" onClick={handler.onCancel} style={{ marginRight: 24 }}>
                                キャンセル
                            </Button>
                        </div>
                    </Form>
                </div>
            </Spin>
        </Modal>
    );
};

export default MainUI;
