import { createSlice } from "@reduxjs/toolkit";
import { TodoSliceEntity } from "../type/Presenter";

const initialState: TodoSliceEntity = {
  data: [],
  loading: false,
  currentPage: 1,
  pageSize: 0,
};

export const todoSlice = createSlice({
  name: "todo",
  initialState,
  reducers: {
    setTodoList: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setTodoList } = todoSlice.actions;

export default todoSlice.reducer;
