import axiosConfig from '../../../../../config/axiosConfig';

const IC3Api = () => {
    const getOrderCompany = async () => {
        const url = '/order/company';
        return axiosConfig.get(url);
    };

    return { getOrderCompany };
};

export default IC3Api;
