// Built-in
import React, { useEffect, useRef } from 'react';

// 3rd party lib
import { useSearchParams } from 'react-router-dom';
import { ConfigProvider, Form } from 'antd';

// Source files
import ConstructionItem from './ConstructionItem';
import SearchBarComponent from './SearchBar';
import PaginationComponent from '../../../../../AM002/presenter/component/company/ui/Pagination';
import FilterComponent from '../../../../../AM002/presenter/component/company/ui/Filter';
import AMSideNoDataComponent from '../../../../../../../common/components/am-side-no-data/AMSideNoData';
import { HanlderConstructionImpl } from '../../../handler/HandlerImpl';

interface props {
  handler: HanlderConstructionImpl;
}

const FilterUI = ({ handler }: props) => {
  const { onProjectChoose, form, constructionList } = handler;
  const [searchParams] = useSearchParams();
  const selectedProjectId = searchParams.get('selectedProjectId') || undefined;
  const selectedConstructionId = searchParams.get('selectedConstructionId') || undefined;

  const size = '6';
  const keywordConstruction = searchParams.get('keywordConstruction') || '';
  const pageConstruction = searchParams.get('pageConstruction') || '1';
  const searchDropDown = searchParams.get('searchDropDown') || '';
  const isCollapsed = searchParams.get('isCollapsed') ? true : false;

  useEffect(() => {
    (async () => {
      handler.initiateFilter();
      await handler.initiateProjectConstruction(
        searchDropDown,
        keywordConstruction,
        selectedProjectId,
        selectedConstructionId,
      );
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await handler.getAttendanceProjectConstruction({
        keyword: keywordConstruction,
        page: pageConstruction,
        size: size,
      });
    })();
  }, [JSON.stringify(constructionList), keywordConstruction, pageConstruction]);

  const firstMount = useRef(false);
  useEffect(() => {
    (async () => {
      if (firstMount.current) await handler.searchDropDown(searchDropDown);
      firstMount.current = true;
    })();
  }, [searchDropDown]);

  const constructionListComponent = (
    <main>
      <div style={{ maxHeight: 64 * 6 }}>
        {constructionList.displayConstructions.map((element: any) => (
          <ConstructionItem
            key={element.id}
            isCollapsed={isCollapsed}
            construction={element}
            isChosen={element.id + '' === selectedConstructionId}
            onClick={() => handler.onConstructionChoose(element)}
          />
        ))}
      </div>
      {isCollapsed ? (
        <></>
      ) : (
        <PaginationComponent
          page={pageConstruction}
          size={size}
          total={handler.total}
          onPageChange={handler.onPageChange}
        />
      )}
    </main>
  );

  return (
    <ConfigProvider direction="ltr">
      <main className="company-container">
        <Form form={form}>
          <SearchBarComponent
            searchDropDownValue={handler.searchDropDownValue}
            onSearchDropDown={handler.onSearchDropDown}
            onSearch={handler.onSearch}
            onChange={onProjectChoose}
            isCollapsed={isCollapsed}
            onCollapse={handler.onCollapse}
            data={handler.infiData}
            loading={handler.infiLoading}
            onScroll={handler.onScroll}
          />
          <div
            style={{
              maxHeight: 64 * 2 + 80,
              overflow: 'scroll',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            {handler.filterLoadng ? (
              <></>
            ) : constructionList.displayConstructions.length === 0 ? (
              <AMSideNoDataComponent />
            ) : (
              constructionListComponent
            )}
          </div>

          <FilterComponent
            onFromDateChange={handler.onFromDateChange}
            onToDateChange={handler.onToDateChange}
            onFilter={handler.onFilter}
            onResetFilter={handler.onResetFilter}
            isCollapsed={isCollapsed}
            rangeDate={handler.rangeDate}
          />
        </Form>
      </main>
    </ConfigProvider>
  );
};

export default FilterUI;
