import { Params } from '../presenter/type/Presenter';
import { CM0027_1Api } from './ServiceImpl';

const CM0027_1Service = (cm0027_1: CM0027_1Api) => {
    const getReSummarySubmittedProjectOutsourceOffsetCost = async (
        params: Params,
    ): Promise<any> => {
        try {
            return await cm0027_1.getReSummarySubmittedProjectOutsourceOffsetCost(params);
        } catch (error) {
            throw error;
        }
    };

    return {
        getReSummarySubmittedProjectOutsourceOffsetCost,
    };
};

export default CM0027_1Service;
