/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect } from "react";
import { HandlerImpl } from "../handler/HandlerImpl";
import MaterialCostComponent from "../component/material-cost";
import ImageViewerModalComponent from "../../../../../common/components/image-viewer-modal/ImageViewerComponent";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import COMMON from "../../../../../common/constants/COMMON";
import PdfViewerModalComponent from "../../../../../common/components/pdf-viewer-modal/PdfViewerModalComponent";
import CM0012Component from "../../../CM0012";

interface props {
  handler: HandlerImpl;
}

const MainUI = ({ handler }: props) => {
  const evidenceLink = useSelector(
    (state: RootState) => state.cm009.evidenceLink
  );
  const isVisibleCM0012 = useSelector(
    (state: RootState) => state.cm0012.isVisible
  );
  const evidenceName = useSelector(
    (state: RootState) => state.cm005.evidenceName
  );
  useEffect(() => {
    return () => {
      handler.clearRedux();
    };
  }, []);

  return (
    <>
      <MaterialCostComponent />
      <PdfViewerModalComponent
        pdfURL={evidenceLink}
        title={evidenceName} />
      <ImageViewerModalComponent
        imageURL={evidenceLink}
        title={evidenceName}
      />
      {isVisibleCM0012 ? <CM0012Component /> : <></>}
    </>
  );
};

export default MainUI;
