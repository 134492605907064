import React from 'react';
import { HandlerImpl } from '../handler/HandlerImpl';
import { Modal, Button } from 'antd';
import CloseModal from '../../../../../assets/icons/close-modal.svg';

interface props {
    handler: HandlerImpl;
}

function ModalView({ handler }: props) {
    return (
        <Modal
            title="業者別相殺合計金額"
            className="cm024-modal cm0026-modal"
            visible={handler.visibleView}
            centered
            footer={null}
            closeIcon={<img alt="" src={CloseModal} />}
            width={'68%'}
            onCancel={handler.funcCancelModalView}
        >
            <div className="cm0026-hr"></div>
            <div className="cm026-modal-table">
                <table>
                    <thead>
                        <tr>
                            <th>No</th>
                            <th>項目名</th>
                            <th>消費税区分</th>
                            <th>相殺金額</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>安全管理費</td>
                            <td>課税</td>
                            <td>1000円</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>安全管理費</td>
                            <td>課税</td>
                            <td>1000円</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>合計金額</td>
                            <td></td>
                            <td></td>
                            <td>1000円</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div className="cm024-modal-footer">
                <Button
                    size="large"
                    className="button-purple cm024-modal-btn-ok"
                    style={{ width: 106 }}
                    onClick={handler.funcCancelModalView}
                >
                    閉じる
                </Button>
            </div>
        </Modal>
    );
}

export default ModalView;
