import { createSlice } from "@reduxjs/toolkit";
import { DB006SliceType, HeaderItem } from "../type/Presenter";
import { getNumOfColByYear } from "../../../DB001/helpers";
import { createRangeArrayNumber } from "../../../DB001/presenter/handler/Handler";
import { DataPopupTableDB007 } from "../../../DB007/presenter/type/Presenter";
import moment from "moment";
import COMMON from "../../../../../common/constants/COMMON";

const initialState: DB006SliceType = {
  isVisibleDB006: false,
  companyName: "",
  dataPopupTableDB006: {
    headers: [],
    data: [[], [], []],
  },
};
export const db006Slice = createSlice({
  name: "db006",
  initialState,
  reducers: {
    setVisibleDB006: (state, action) => {
      state.isVisibleDB006 = action.payload;
    },
    setDataPopupTableDB006: (state, action) => {
      const {rawData, parserData, from: fromParams, to: toParams} = action.payload;
      const headersNumberOfCol = getNumOfColByYear(parserData)

      const from = moment(fromParams).format(COMMON.FORMAT_DATE4)
      const to = moment(toParams).format(COMMON.FORMAT_DATE4)

      const startYear = +from.split("-")[0]
      const endYear = +to.split("-")[0]
      const headers = createRangeArrayNumber(startYear, endYear, 1).map((year: number) => ({
        numOfChildCol: headersNumberOfCol[year],
        label: year
      })) || []
      const headersSorted = headers.sort((a: HeaderItem, b: HeaderItem) => a.label - b.label)
      const listMonth = parserData.map((item: any) => item.month);
      const laborCostList = parserData.map((item: any) => item.laborCost);
      const workingDayList = parserData.map((item: any) => item.workingDay);
      const dataPopupTableDB006: DataPopupTableDB007 = {
        headers: headersSorted,
        data: [listMonth, laborCostList, workingDayList],
      };
      state.dataPopupTableDB006 = dataPopupTableDB006;
    },
    setCompanyName: (state, action) => {
      state.companyName = action.payload
    }
  },
});

export const {
  setDataPopupTableDB006,
  setVisibleDB006,
  setCompanyName
} = db006Slice.actions;

export default db006Slice.reducer;
