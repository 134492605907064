import LogoFooter from "../../../assets/icons/logo-footer.svg";


const AuthenticationSideImage = () => {
    return (
        <div className="half-container login-logo a footer-font" style={{ position: "relative" }}>
            <div className="w-100-percent center-item" style={{ position: "absolute", bottom: 62 }}>

                ONE Unit Pro <img alt="" src={LogoFooter} style={{ marginLeft: 8, marginRight: 8 }} />ONE Unit
            </div>
            <div className="w-100-percent center-item" style={{ position: "absolute", bottom: 32 }}>
                Copyright ©2023 Produced by YMA Information Technology Department
            </div>
        </div>
    )
}

export default AuthenticationSideImage  