import { OM004SliceType } from "./../type/Presenter";
import { createSlice } from "@reduxjs/toolkit";

const initialState: OM004SliceType = {
  loading: false,
  isVisible: false,
  groupId: null,
  isHasCreatedOM004: false,
};

export const om004Slice = createSlice({
  name: "om004",
  initialState,
  reducers: {
    resetOM004: (state) => {
      state.loading = false;
      state.isVisible = false;
      state.groupId = null;
      state.isHasCreatedOM004 = !state.isHasCreatedOM004;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsVisibleOM004: (state, action) => {
      state.isVisible = action.payload;
    },
    setGroupIdOM004: (state, action) => {
      state.groupId = action.payload;
    },
    toggleIsHasCreatedOM004: (state) => {
      state.isHasCreatedOM004 = !state.isHasCreatedOM004;
    },
  },
});

export const {
  setLoading,
  setIsVisibleOM004,
  resetOM004,
  setGroupIdOM004,
  toggleIsHasCreatedOM004,
} = om004Slice.actions;

export default om004Slice.reducer;
