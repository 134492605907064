import CM017_2Api from './infrastructure/api/Api';
import CM017_2Handler from './presenter/handler/Handler';
import CM017_2UI from './presenter/ui/UI';
import CM017_2Service from './usecase/Service';

type props = {
    refreshData?: () => void;
};

const CM017_2Component = ({ refreshData }: props) => {
    const cm017_2Api = CM017_2Api();
    const cm017_2Service = CM017_2Service(cm017_2Api);
    const cm017_2Handler = CM017_2Handler(cm017_2Service, refreshData);
    return <CM017_2UI handler={cm017_2Handler} />;
};

export default CM017_2Component;
