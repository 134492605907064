// Built-in
import React, { useEffect } from 'react';

// 3rd party lib

// Source files
import MaterialCostComponent from '../component/material-cost';
import { HandlerImpl } from '../handler/HandlerImpl';

// Image files
import HomeSvg from '../../../../../assets/icons/home.svg';
import { useSearchParams } from 'react-router-dom';

interface props {
    handler: HandlerImpl;
}
const MainUI = ({ handler }: props) => {

    const [searchParams] = useSearchParams();
    const constructionId = searchParams.get("constructionId");

    useEffect(() => {
        return () => {
            handler.clearRedux()
        }
    }, [])

    useEffect(() => {
        (async () => {
            if (constructionId) await handler.getMoneyIndirectCostConstructionDirectCost({ constructionId: Number(constructionId) })
        })()
    }, [constructionId])
    return <MaterialCostComponent />;
};

export default MainUI;
