import { SYSTEM_ROLES } from "../../../../common/constants/AUTHORIZATION";
import { PLAN_TYPE } from "../../../../common/constants/COMMON";
import { LoginBody, LoginBodyData } from "./../entity/Entity";
import { ASLoginApi } from "./ServiceImpl";

const ASLoginService = (asLoginApi: ASLoginApi) => {
  const login = async (formData: LoginBody): Promise<any> => {
    try {
      const dataBody: LoginBodyData = {
        username: formData.username,
        password: formData.password,
      };
      const response = await asLoginApi.login(dataBody);
      localStorage.setItem(
        "user",
        JSON.stringify({
          ...response.results,
          isRemembered: formData.isRemembered ? true : false,
        })
      );

      const authData = response.results;

      const isRoleAdminBasic =
        authData?.role?.code === SYSTEM_ROLES.ADMIN &&
        authData?.order?.code === PLAN_TYPE.PLAN_BASIC;
      const isRoleAdmin = authData?.role?.code === SYSTEM_ROLES.ADMIN;
      const isRoleAdminSystem =
        authData?.role?.code === SYSTEM_ROLES.ADMIN_SYSTEM;

      return {
        ...authData,
        isReWorkflow: false,
        isDoubleCheck: false,
        isUserInWorkspace: false,
        isRoleAdminBasic,
        isRoleAdmin,
        isRoleAdminSystem,
      };
    } catch (error) {
      throw error;
    }
  };

  const sendOTP = async (): Promise<any> => {
    try {
      return await asLoginApi.sendOTP();
    } catch (error) {
      throw error;
    }
  };

  return { login, sendOTP };
};

export default ASLoginService;
