import IC1Api from './infrastructure/api/Api';
import IC1Handler from './presenter/handler/Handler';
import IC1UI from './presenter/ui/UI';
import IC1Service from './usecase/Service';

const IC1Component = () => {
    const ic1Api = IC1Api();
    const ic1Service = IC1Service(ic1Api);
    const ic1Handler = IC1Handler(ic1Service);
    return <IC1UI handler={ic1Handler} />;
};

export default IC1Component;
