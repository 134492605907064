type Props = {
    onClick: (value: any) => void
    company: any,
    isChosen: boolean,
    isCollapsed: boolean
}

const CompanyItem = ({ onClick, company, isChosen, isCollapsed }: Props) => {
    const companyFirstLetter = company.name && company.name?.length >= 1 ? company.name[0] : ""

    return isCollapsed ?
        <main className={`company-item-container ${isChosen ? "chosen" : ""}`} onClick={onClick}>
            <div className="company-item-avt">
                <p className="company-item-avt-text">{companyFirstLetter}</p>
            </div>
        </main>
        : <main className={`company-item-container ${isChosen ? "chosen" : ""}`} onClick={onClick}>
            <div className="company-item-avt" style={{ marginRight: 16 }}>
                <p className="company-item-avt-text">{companyFirstLetter}</p>
            </div>
            <p className="company-item-text"> {company.name}</p>
        </main>
}

export default CompanyItem