import { AxiosResponse } from 'axios';
import axiosConfig from '../../../../../config/axiosConfig';

const CCUS061Api = () => {
    const getTechnicianListCCUS = async (params: {
        groupId?: number;
        page: number;
        size: number;
        keyword?: string;
    }): Promise<AxiosResponse<any, any>> => {
        const url = "/ccus/employee/list";
        return await axiosConfig.get(url, { params });
    };

    const getDepartmentListCCUS = async (params: {
        userId?: number;
        page: number;
        size: number;
    }): Promise<AxiosResponse<any, any>> => {
        const url = "/ccus/group/list";
        return await axiosConfig.get(url, { params });
    };

    const getListEmployeeVerification = async (params: {
        groupId?: number;
        userId?: number;
        keyword?: string;
        sortBy?: string;
        sortType?: string;
        page?: number;
        size?: number;
    }): Promise<AxiosResponse<any, any>> => {
        const url = "/ccus/employee-verification/list";
        return await axiosConfig.get(url, { params });
    };

    const getListCompanyEmployee = async (params: {
        lastReferred?: boolean;
    }): Promise<AxiosResponse<any, any>> => {
        const url = "/ccus/company/employee";
        return await axiosConfig.get(url, { params });
    };

    const postEmployeeCompanyVerify = async (payload: any) => {
        const url = '/ccus/company/employee/verify';
        return await axiosConfig.post(url, payload);
    };
    return {
        getTechnicianListCCUS,
        getDepartmentListCCUS,
        getListEmployeeVerification,
        getListCompanyEmployee,
        postEmployeeCompanyVerify
    };
};

export default CCUS061Api;
