import { RootState } from "./../../../../../store";
import { useDispatch, useSelector } from "react-redux";
import { OM001UseCase } from "../../usecase/ServiceImpl";
import { resetOM001 } from "../slice/Slice";
import {
  setGroupInfo,
  setIsVisibleOM008,
} from "../../../OM008/presenter/slice/Slice";
import {
  setGroupIdOM009,
  setIsVisibleOM009,
} from "../../../OM009/presenter/slice/Slice";

const OM001Handler = (om001Service: OM001UseCase) => {
  const dispatch = useDispatch();
  const detailGroup = useSelector(
    (state: RootState) => state.om001.detailGroup
  );

  const onCancel = () => {
    dispatch(resetOM001());
  };

  const onDelete = () => {
    dispatch(setGroupIdOM009(detailGroup));
    dispatch(setIsVisibleOM009(true));
    dispatch(resetOM001());
  };

  const onUpdate = () => {
    dispatch(setGroupInfo(detailGroup));
    dispatch(setIsVisibleOM008(true));
    dispatch(resetOM001());
  };

  return {
    onCancel,
    onDelete,
    onUpdate,
  };
};

export default OM001Handler;
