import { CM0010UseCase } from "./../../../CM0010/usecase/ServiceImpl";
import moment from "moment";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { showImageViewerModal } from "../../../../../common/components/image-viewer-modal/ImageViewSlice";
import ConfirmModal from "../../../../../common/components/modal/ConfirmModal";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import COMMON, {
  STATUS_CM_CHILD_TABLE,
} from "../../../../../common/constants/COMMON";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import { TableHistory, WorkflowResult } from "../../entity/Entity";
import { CM008UseCase } from "../../usecase/ServiceImpl";
import { resetCM008, setLoading } from "../slice/Slice";
import { convertDataTable } from "../../../CM0010/helper";
import { DataTableConstructionBudgetBalance } from "../../../CM0016/entity/Entity";
import { TableConstructionContractBudget } from "../../../CM0010/entity/Entity";
import { setRefresh } from "../../../CM034-CM53/CM035/presenter/slice/Slice";
import { CM006UseCase } from "../../../CM006/usecase/ServiceImpl";
import { DataTableOverview } from "../../../CM006/entity/Entity";
import { convertDataOverview } from "../../../CM006/helper";
import { convertDataTableInformationCost } from "../../helper";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import { ItemApprove } from "../../../../../common/components/approval-flow/ApprovalFlow";
import { setDocumentView } from "../../../../../common/components/modal-approve-view/presenter/slice/Slice";
import { showModalApproveConfirm } from "../../../../../common/components/modal-approve-confirm/presenter/slice/Slice";
import { showPdfViewerModal } from "../../../../../common/components/pdf-viewer-modal/pdfViewerModalSlice";

const SCREEN_NAME_VIEW = "CM007";

const CM008Handler = (
  CM008Service: CM008UseCase,
  CM006Service: CM006UseCase
) => {
  // LIB FUNCTION
  const dispatch = useDispatch();

  //state
  const [isOpenViewer, setIsOpenViewer] = useState<boolean>(false);
  const [pdfUrl, setPdfUrl] = useState<any>();
  const [pdfName, setPdfName] = useState<string>("");

  const [dataSummary, setDataSummary] = useState<any>();

  const [dataReceivedMoney, setReceivedMoney] = useState<DataTableOverview[]>([
    {
      key: "0",
      contractValue: "0円",
      paymentValue: "0円",
      paymentValuePercent: "0%",
      invoiceBalance: "0円",
    },
  ]);
  const [constructionInformationCost, setConstructionInformationCost] =
    useState<DataTableConstructionBudgetBalance[]>([]);

  const [
    dataTableConstructionContractBudget,
    setDataTableConstructionContractBudget,
  ] = useState<TableConstructionContractBudget[]>([]);

  const [dataTableHistory, setDataTableHistory] = useState<TableHistory[]>([]);
  const [dataSummaryTitle, setDataSummaryTitle] = useState<any>({});
  const [workflow, setWorkflow] = useState<ItemApprove[]>([]);
  const [currentUserApproval, setCurrentUserApproval] =
    useState<ItemApprove | null>(null);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedList, setCheckedList] = useState<any[]>([]);
  //function

  const getWorkflowMaterialApprovalProcess = async (documentId: number) => {
    try {
      dispatch(setLoading(true));
      const data = await CM008Service.getWorkflowMaterialApprovalProcess({
        documentId,
      });
      if (data.results.currentUserApproval)
        setCurrentUserApproval({
          approveDate: data.results.currentUserApproval.approveDate,
          contact_address: "",
          email: data.results.currentUserApproval.email,
          id: data.results.currentUserApproval.id,
          isApprove: data.results.currentUserApproval.approve,
          reject: data.results.currentUserApproval.reject,
          position: "",
          priority: data.results.currentUserApproval.priority,
          username: data.results.currentUserApproval.username,
          note: data.results.currentUserApproval.note,
          fullName: data.results.currentUserApproval.fullName,
        });
      const workflowData: ItemApprove[] = data.results.listApprover.map(
        (element) => {
          let note = element.note;
          if (element.id === data.results?.currentUserApproval?.id)
            note = data.results.currentUserApproval.note;
          const result: ItemApprove = {
            approveDate: element.approveDate,
            contact_address: "",
            email: element.email,
            id: element.id,
            isApprove: element.approve,
            reject: element.reject,
            position: "",
            priority: element.priority,
            username: element.username,
            note: note,
            fullName: element.fullName,
          };
          return result;
        }
      );
      setWorkflow(workflowData);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getListSubmittedDirectCost = async (
    documentSubmitId: number,
    documentOriginId: number
  ): Promise<any> => {
    try {
      const params = {
        documentSubmitId,
      };
      const res = await CM008Service.getListSubmittedDirectCost(params);
      if (res?.results) {
        const data: TableHistory[] = formatListSubmittedDirectCost(res.results);
        let actualCostTotal = 0;
        if (data) {
          data.forEach((item: TableHistory) => {
            actualCostTotal += item.actualCost;
          });
          setDataTableHistory(data);
        }

        if (res?.results.length !== 0) {
          getDataReceivedMoney(
            res.results[0].dataSummary.construction.id,
            documentSubmitId
          );
          getDataConstructionInformationCost(
            res.results[0].dataSummary.construction.id
          );
          getDataMaterialListCompany(
            res.results[0].dataSummary.construction.id,
            documentOriginId,
            actualCostTotal
          );

          setDataSummaryTitle(res.results[0].dataSummary);
        }
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const formatListSubmittedDirectCost = (data: any) => {
    const dataFormat: TableHistory[] = data.map((item: any, index: number) => {
      return {
        key: item?.id,
        id: item?.id,
        paymentDate: `${
          item?.paymentDate
            ? moment(item?.paymentDate).format(COMMON.FORMAT_DATE_OM)
            : ""
        }`,
        targetStage: `${
          item.startDate
            ? moment(item.startDate).format(COMMON.FORMAT_DATE_OM)
            : ""
        }～${
          item.endDate ? moment(item.endDate).format(COMMON.FORMAT_DATE_OM) : ""
        }`,
        billAmount: item?.paidCost,
        payAmount: item?.planMaterialCost,
        actualCost: item?.actualCost,
        evidence: item?.evidence,
        status: item.status,
        note: item?.note,
      };
    });
    return dataFormat;
  };

  const handleOpenViewer = async (record: any) => {
    try {
      dispatch(setLoading(true));

      const companyId = JSON.parse(localStorage.getItem("user") ?? `{}`)
        ?.company?.id;
      const response = await CM008Service.getPresignLinkS3({
        keyName: `re/${companyId}/${SCREEN_NAME_VIEW}/${Number(record.key)}/${
          record.evidence
        }`,
      });
      const checkFile = record.evidence?.split(".").pop();
      const evidenceName = record.evidence?.split(".").slice(0, -1).join(".");

      setPdfName(evidenceName);
      setPdfUrl(response.results);

      if (checkFile === "pdf") dispatch(showPdfViewerModal());
      else dispatch(showImageViewerModal());
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleCaculateSummary = (data: TableHistory[]) => {
    let totalPayAmount = 0;
    let totalBillAmount = 0;
    let totalActualCost = 0;
    data.forEach((element: any) => {
      totalPayAmount += element.payAmount;
      totalBillAmount += element.billAmount;
      totalActualCost += element.actualCost;
    });

    setDataSummary({
      totalPayAmount,
      totalBillAmount,
      totalActualCost,
    });
  };

  const onCancel = () => {
    dispatch(resetCM008());
  };

  const getDataReceivedMoney = async (
    constructionId: number,
    documentId: number
  ) => {
    const responseA = await CM006Service.getConstructionInformation({
      constructionId: constructionId,
      documentId: documentId,
    });

    const responseB = await CM006Service.getListConstructionReceivedMoney({
      constructionId: constructionId,
    });

    setReceivedMoney(convertDataOverview(responseA.results, responseB.results));
  };

  const getDataConstructionInformationCost = async (constructionId: number) => {
    const response = await CM008Service.getConstructionSummaryCostInformation({
      constructionId: constructionId,
    });

    setConstructionInformationCost(
      convertDataTableInformationCost([response.results])
    );
  };

  const getDataMaterialListCompany = async (
    constructionId: number,
    documentId: number,
    actualCost: number
  ) => {
    const response = await CM008Service.getMaterialListCompany({
      constructionId: constructionId,
    });

    let planCostTotal = 0;
    let orderMoneyTotal = 0;

    response.results.forEach((element: any) => {
      if (element.id === documentId) {
        planCostTotal += element?.planCost ?? 0;
        orderMoneyTotal += element?.orderMoney ?? 0;
      }
    });

    setDataTableConstructionContractBudget(
      convertDataTable(planCostTotal, orderMoneyTotal, actualCost)
    );
  };

  const retrieveDocument = (documentId: number) => {
    ConfirmModal({
      onOk: async () => {
        const response = await CM008Service.retrieveMaterialDocument({
          documentId: documentId,
          listDocumentDirectMaterialCostInformationId: checkedList,
        });

        if (response.status === `${COMMON.HTTP_STATUS_OK}`) {
          dispatch(resetCM008());
          dispatch(setRefresh());
        }
        SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
      },
      className: "confirm__modal",
      title: MESSAGE.MESSAGE_TITLE_CM016,
      description: MESSAGE.MESSAGE_DESCRIPTION_CR_1,
      extraDescription: MESSAGE.MESSAGE_DESCRIPTION_COMMON_QUESTION_1,
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_MODAL,
      isCenterWithoutMenu: true,
    });
  };
  const onCheckAllChange = (checked: boolean) => {
    setCheckAll(checked);
    if (checked) {
      const checkedItems: any[] = [];
      for (let element of dataTableHistory) {
        checkedItems.push(element.id);
      }
      setCheckedList(checkedItems);
    } else setCheckedList([]);
  };

  const isCheckbox = (record: any): boolean =>
    checkedList.some((element) => element === record.key);

  const onCheckboxChange = (record: any, checked: boolean) => {
    // setCheckedList(list);
    let newCheckList: any[] = [];

    if (checked) {
      newCheckList = [...checkedList, record.key];
    } else {
      const checkedData = checkedList.filter(
        (element: any) => element !== record.key
      );
      newCheckList = checkedData;
    }
    const possibleItems: any[] = [];

    for (let element of dataTableHistory) {
      if (
        [
          STATUS_CM_CHILD_TABLE.MONEY_NOT_APPROVED.CODE,
          STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE,
        ].includes(element?.status?.code)
      )
        possibleItems.push(element.id);
    }
    setCheckedList(newCheckList);
    setCheckAll(newCheckList.length === possibleItems.length);
  };

  const viewApproveModal = (record: any) => {
    dispatch(setDocumentView(record));
  };

  const showConfirmApproveModal = () => {
    dispatch(showModalApproveConfirm());
  };
  return {
    dataTableHistory,
    isOpenViewer,
    pdfUrl,
    pdfName,
    dataSummary,
    dataReceivedMoney,
    constructionInformationCost,
    dataTableConstructionContractBudget,
    dataSummaryTitle,
    workflow,
    currentUserApproval,
    checkAll,
    checkedList,
    handleOpenViewer,
    handleCaculateSummary,
    onCancel,
    getListSubmittedDirectCost,
    getDataConstructionInformationCost,
    getDataMaterialListCompany,
    retrieveDocument,
    getWorkflowMaterialApprovalProcess,
    viewApproveModal,
    onCheckboxChange,
    isCheckbox,
    onCheckAllChange,
    showConfirmApproveModal,
  };
};

export default CM008Handler;
