/* eslint-disable no-useless-escape */
const REGEX = {
  EMAIL:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  POSTALCODE: /[0-9]{3}-?[0-9]{4}/,
  PHONENUMBER: /[0-9]/,
  PHONENUMBER_2: /^[+\d](?:.*\d)?$/,
  NAME: "^[一-龠ぁ-ゔァ-ヴー、ぁ-ん' '　'a-zA-Z0-9ゝゞヽヾ々ｑｗｅｒｔｙｕｉｏｐａｓｄｆｇｈｊｋｌｚｘｃｖｂｎｍＱＷＥＲＴＹＵＩＯＰＡＳＤＦＧＨＪＫＬＺＸＣＶＢＮＭ０１２３４５６７８９]*$",
  NAMEOTHER:
    "^[一-龠ぁ-ゔァ-ヴー、ぁ-ん' '　'a-zA-Z0-9ゝゞヽヾ々ｑｗｅｒｔｙｕｉｏｐａｓｄｆｇｈｊｋｌｚｘｃｖｂｎｍＱＷＥＲＴＹＵＩＯＰＡＳＤＦＧＨＪＫＬＺＸＣＶＢＮＭ０１２３４５６７８９-]*$",
  NAME_FULL_SIZE:
    "^[一-龠ぁ-ゔァ-ヴー、ぁ-ん' ''　'a-zA-Z0-9ゝゞヽヾ々ｑｗｅｒｔｙｕｉｏｐａｓｄｆｇｈｊｋｌｚｘｃｖｂｎｍＱＷＥＲＴＹＵＩＯＰＡＳＤＦＧＨＪＫＬＺＸＣＶＢＮＭ１２３４５６７８９]*$",
  CHARACTER_JP:
    "^[一-龠ぁ-ゔァ-ヴー、ぁ-ん' '　'a-zA-Z0-9ｑｗｅｒｔｙｕｉｏｐａｓｄｆｇｈｊｋｌｚｘｃｖｂｎｍＱＷＥＲＴＹＵＩＯＰＡＳＤＦＧＨＪＫＬＺＸＣＶＢＮＭ０１２３４５６７８９]*$",
  ID: "^[a-zA-Z0-9ｑｗｅｒｔｙｕｉｏｐａｓｄｆｇｈｊｋｌｚｘｃｖｂｎｍＱＷＥＲＴＹＵＩＯＰＡＳＤＦＧＨＪＫＬＺＸＣＶＢＮＭ０１２３４５６７８９]*$",
  EXCEPT_VIETNAMESE:
    "^[^ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễếệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹý]*$",
  CHARACTER_JP_HALF_FULL:
    "^[ｧ-ﾝﾞﾟ一-龠ぁ-ゔァ-ヴー、ぁ-ん' '　'a-zA-Z0-9ｑｗｅｒｔｙｕｉｏｐａｓｄｆｇｈｊｋｌｚｘｃｖｂｎｍＱＷＥＲＴＹＵＩＯＰＡＳＤＦＧＨＪＫＬＺＸＣＶＢＮＭ０１２３４５６７８９]*$",
  ONLY_LATIN: "^[a-zA-Z0-9]*$",
  ONLY_LATIN_2: /^[a-zA-Z0-9]*$/,
  ONLY_KATAKANA: /^[ァ-ンｧ-ﾝﾞﾟー]+$/,
  ONLY_KATAKANA_WITH_NUMBER: /^[ァ-ンｧ-ﾝﾞﾟー0-9]+$/,
  REGEX_KATAKANA: /[\u30A0-\u30FF]/g,
  REGEX_CHECK_KATAKANA: /^[\u30A0-\u30FF]*$/,
  REGEX_NOT_KATAKANA: /^(?!.*[\u30A0-\u30FF]).*$/,
  REGEX_ONLY_KATAKANA: /^[\u30A0-\u30FF]+$/,
  REGEX_ONLY_NUMBER: /^\d*$/,
  REGEX_LATIN_DIGITS_KATAKANA_ONLY: /^[A-Za-z0-9\u30A0-\u30FF]+$/,

  MEMBER_NAME_REGEX:
    "^[一-龠々ヽヾゝゞ〃仝々〆ぁ-ゔァ-ヴー、ぁ-ん 々〆〤ヶ' '　'a-zA-ZｑｗｅｒｔｙｕｉｏｐａｓｄｆｇｈｊｋｌｚｘｃｖｂｎｍＱＷＥＲＴＹＵＩＯＰＡＳＤＦＧＨＪＫＬＺＸＣＶＢＮＭ﨑()\u4E00-\u9FFF]*$",
  LANTIN_NUMBER_SPECIAL_CHARACTERS:
    /[a-zA-Z0-9\t\n ./<>?;:"'`!@#$%^&*()\[\]{}_+=|\\-]/,
  USERNAME_EMAIL_REGEX: "^[a-zA-Z0-9.@-]*$",
  ONLY_NUMBER: "^[0-9]*$",
  NAME_JAPANESE_ONLY: "^[一-龠ぁ-ゔァ-ヴー、ぁ-ん' '　']*$",
  NOT_VIETNAM:
    /^[^ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễếệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ]*$/,
  PASSWORD_REGEX:
    /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[~`@$!%*?&#(){}^_+=\[\];:,<.>\'\"\\\|\/\-])([a-zA-Z0-9@$!%*~`?&#(){}^_+=\[\];:,<.>\'\"\\\|\/\-]{8,16})$/,
  EMAIL_MASTER:
    /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/,
  EXCEPT_SPECIAL_CHARACTER:
    "^[^ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễếệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹý~`@$!%*?&#(){}^_+=[];:,<.>'\"\\|/-]]*$",

  EMAIL_COMPANY:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})|(([a-zA-Z\-0-9]+\.)+([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/,
  REGEX_PHONE_FAX: /^[0-9\.\-\/]+$/,
  REGEX_PHONE_FAX_JP: /^[0-9\.\-－ー０１２３４５６７８９\/]+$/,
  REGEX_DETECTING_FULL_WIDTH:
    /[\u{FF01}-\u{FF5E}\u{3040}-\u{309F}\u{30A0}-\u{30FF}\u{31F0}-\u{31FF}\u{1B000}-\u{1B0FF}\u{1F200}-\u{1F2FF}\u{1F300}-\u{1F5FF}\u{1F600}-\u{1F64F}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{2E80}-\u{2EFF}\u{2F00}-\u{2FDF}\u{3000}-\u{303F}\u{31C0}-\u{31EF}\u{3200}-\u{32FF}\u{3300}-\u{33FF}\u{3400}-\u{4DBF}\u{4E00}-\u{9FFF}\u{F900}-\u{FAFF}\u{FE30}-\u{FE4F}\u{20000}-\u{2A6DF}\u{2A700}-\u{2B73F}\u{2B740}-\u{2B81F}\u{2B820}-\u{2CEAF}\u{2CEB0}-\u{2EBEF}\u{2EBF0}-\u{2F7FF}\u{2F800}-\u{2FA1F}]/u,
};

export default REGEX;
