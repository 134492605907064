import React, { useEffect, useRef, useState } from 'react';

type Props = {
    imageURL: string;
    height: number;
    width: number;
    typeZoom?: number;
};

const PreviewImageComponent = ({ imageURL, height, width, typeZoom = 100 }: Props) => {
    const imgRef: any = useRef(null);

    const [imgDimension, setImgDimension] = useState<any>({
        height: 0,
        width: 0,
    });

    useEffect(() => {
        const image = new Image();
        image.src = imageURL;

        const handleImageLoad = () => {
            let imageWidth = image.naturalWidth
            let imageHeight = image.naturalHeight
            imageHeight = Math.floor((width / imageWidth) * imageHeight)
            imageWidth = width
            if (imageHeight > height) {
                imageWidth = Math.floor((height / imageHeight) * imageWidth)
                imageHeight = height
            }
            setImgDimension({ width: imageWidth, height: imageHeight });
        };

        image.addEventListener('load', handleImageLoad);

        return () => {
            // Cleanup: Remove the event listener to avoid memory leaks
            image.removeEventListener('load', handleImageLoad);
        };
    }, [imageURL]); // Only re-run the effect if imageUrl changes


    return <img
        ref={imgRef}
        alt=""
        src={imageURL}
        style={{
            width: imgDimension.width * (typeZoom / 100),
            height: imgDimension.height * (typeZoom / 100),
        }}
    />


};

export default PreviewImageComponent;
