import {
  DetailMemberEntity,
  MemberUpdateEntity,
} from "../../../OM/OM006/entity/Entity";
import {
  BranchPaginationEntity,
  MemberEntity,
  RolePaginationEntity,
} from "../entity/Entity";
import { P01API } from "./ServiceImpl";

const Service = (om004: P01API) => {
  const createNewMember = async (data: MemberEntity): Promise<any> => {
    try {
      return await om004.createNewMember(data);
    } catch (error) {
      throw error;
    }
  };

  const getPresignAvatarUpload = async (params: any): Promise<any> => {
    try {
      return await om004.getPresignAvatarUpload(params);
    } catch (error) {
      throw error;
    }
  };

  const uploadAvatar = async (
    data: any,
    url: string,
    type: string
  ): Promise<any> => {
    try {
      return await om004.uploadAvatar(data, url, type);
    } catch (error) {
      throw error;
    }
  };

  const getBranchList = async (
    params: any
  ): Promise<BranchPaginationEntity> => {
    return await om004.getBranchList(params);
  };

  const getRoleList = async (params: any): Promise<RolePaginationEntity> => {
    return await om004.getRoleList(params);
  };

  const getCertificateType = async (): Promise<any> =>
    await om004.getCertificateType();

  const updateMemberAvatar = async (params: any): Promise<any> => {
    try {
      return await om004.updateMemberAvatar(params);
    } catch (error) {
      throw error;
    }
  };

  const getDetailMember = async (
    params: any
  ): Promise<DetailMemberEntity | null> => {
    const detailMember = await om004.getDetailMember(params);
    if (detailMember)
      return { ...detailMember, s3ImageUrl: detailMember?.avatar };
    return detailMember;
  };

  const getS3MemberImage = async (params: any): Promise<any> => {
    try {
      return await om004.getS3MemberImage(params);
    } catch (error) {
      throw error;
    }
  };
  const updateMember = async (data: MemberUpdateEntity): Promise<any> => {
    try {
      return await om004.updateMember(data);
    } catch (error) {
      throw error;
    }
  };
  return {
    createNewMember,
    getBranchList,
    getRoleList,
    getCertificateType,
    getPresignAvatarUpload,
    uploadAvatar,
    updateMemberAvatar,
    getDetailMember,
    getS3MemberImage,
    updateMember,
  };
};

export default Service;
