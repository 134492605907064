import { SM06SliceType } from './../type/Presenter';
import { createSlice } from '@reduxjs/toolkit';

const initialState: SM06SliceType = {
    isVisible: false,
    loading: false,
    scheduleId: null,
    screenRequestToSM06: '',
};

export const sm06Slice = createSlice({
    name: 'sm06',
    initialState,
    reducers: {
        resetSM06: () => initialState,
        setIsVisibleSM06: (state, action) => {
            state.isVisible = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setScheduleIdSM06: (state, action) => {
            state.scheduleId = action.payload;
        },
        setScreenRequestToSM06: (state, action) => {
            state.screenRequestToSM06 = action.payload;
        },
    },
});

export const {
    setIsVisibleSM06,
    resetSM06,
    setLoading,
    setScheduleIdSM06,
    setScreenRequestToSM06,
} = sm06Slice.actions;

export default sm06Slice.reducer;
