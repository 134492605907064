// Built-in
import React, { useEffect } from 'react';

// Source files
import { HandlerDoubleCheckImpl } from '../../../handler/HandlerImpl';

// Image files
import CircleCheckSVG from '../../../../../../../assets/icons/am-001-circle-check.svg';
import CircleUnCheckSVG from '../../../../../../../assets/icons/am-001-circle-uncheck.svg';
import XCheckSVG from '../../../../../../../assets/icons/am-001-x-check.svg';
import XUnCheckSVG from '../../../../../../../assets/icons/am-001-x-uncheck.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store';

interface Props {
    handler: HandlerDoubleCheckImpl;
    doubleCheck: boolean | null;
    constructionId: number;
    userTimeSheetId: number;
}

const DoubleCheckUI = ({ handler, doubleCheck, constructionId, userTimeSheetId }: Props) => {

    const permissionDoubleCheck = useSelector((state: RootState) => state.am001.permissionDoubleCheck)

    useEffect(() => {
        handler.initiateData(doubleCheck);
    }, [doubleCheck]);

    return permissionDoubleCheck ?
        <main style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <div
                className="button-style"
                onClick={() => {
                    let doubleCheck: null | boolean = true;
                    if (handler.isDoubleCheck === true) doubleCheck = null;
                    handler.postAttendanceSubConstructionMemberDoubleCheck({
                        constructionId,
                        doubleCheck,
                        userTimeSheetId,
                    });
                }}
            >
                {handler.isDoubleCheck === null ? (
                    <img alt="" src={CircleUnCheckSVG} />
                ) : handler.isDoubleCheck ? (
                    <img alt="" src={CircleCheckSVG} />
                ) : (
                    <img alt="" src={CircleUnCheckSVG} />
                )}
            </div>
            <div
                className="button-style mr-8"
                onClick={() => {
                    let doubleCheck: null | boolean = false;
                    if (handler.isDoubleCheck === false) doubleCheck = null;
                    handler.postAttendanceSubConstructionMemberDoubleCheck({
                        constructionId,
                        doubleCheck,
                        userTimeSheetId,
                    });
                }}
            >
                {handler.isDoubleCheck === null ? (
                    <img alt="" src={XUnCheckSVG} />
                ) : handler.isDoubleCheck ? (
                    <img alt="" src={XUnCheckSVG} />
                ) : (
                    <img alt="" src={XCheckSVG} />
                )}
            </div>
        </main>
        :
        <main style={{ display: 'flex', justifyContent: 'flex-start', cursor: 'not-allowed' }}>
            <div  >
                {handler.isDoubleCheck === null ? (
                    <img alt="" src={CircleUnCheckSVG} />
                ) : handler.isDoubleCheck ? (
                    <img alt="" src={CircleCheckSVG} />
                ) : (
                    <img alt="" src={CircleUnCheckSVG} />
                )}
            </div>
            <div className=" mr-8" >
                {handler.isDoubleCheck === null ? (
                    <img alt="" src={XUnCheckSVG} />
                ) : handler.isDoubleCheck ? (
                    <img alt="" src={XUnCheckSVG} />
                ) : (
                    <img alt="" src={XCheckSVG} />
                )}
            </div>
        </main>
};

export default DoubleCheckUI;
