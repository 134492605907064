import { FormInstance } from "antd";
import API from "./infrastructure/api/Api"
import Handler from "./presenter/handler/Handler";
import UI from "./presenter/ui/UI";
import Service from "./usecase/Service";


export type Props = {
    company: any,
    construction: any,
    form: FormInstance<any> | undefined

}

const CompanyComponent = ({ company, construction, form }: Props) => {
    const api = API();
    const service = Service(api)
    const handler = Handler(service, form);
    return <UI handler={handler} company={company} construction={construction} />
}

export default CompanyComponent