import { AM005APIImpl } from './ServiceImpl';
import COMMON from '../../../../common/constants/COMMON';

const AM005Service = (api: AM005APIImpl) => {
  const getListPartnerConstruction = async (params: {
    constructionId: number;
    contractorConstructionId: number;
    from?: string;
    to?: string;
    timeOffset?: number;
  }): Promise<any> => {
    try {
      return await api.getListPartnerConstruction({
        ...params,
        timeOffset: COMMON.TIME_ZONE,
      });
    } catch (error) {
      throw error;
    }
  };
  const getListSubConstruction = async (params: {
    constructionId: number;
    contractorConstructionId: number;
    from?: string;
    to?: string;
  }): Promise<any> => {
    try {
      return await api.getListSubConstruction({
        ...params,
        timeOffset: COMMON.TIME_ZONE,
        approve: true,
      });
    } catch (error) {
      throw error;
    }
  };

  const getListConstructionProject = async (params: {
    projectId: string | number;
    from?: string;
    to?: string;
    timeOffset?: number;
  }) => {
    return await api.getListConstructionProject({
      ...params,
      timeOffset: COMMON.TIME_ZONE,
    });
  };

   const doExportAM005 = async (params: any): Promise<any> => {
      return await api.doExportAM005(params);
  }
  return {
    getListPartnerConstruction,
    getListSubConstruction,
    getListConstructionProject,
    doExportAM005,
  };
};

export default AM005Service;
