import axiosConfig from "../../../../config/axiosConfig";

export const API = () => {
  const postRefreshToken = async (data: { refreshToken: string }) => {
    const url = "/auth/refresh";
    const response = await axiosConfig.post(url, data);
    return response.data;
  };

  return {
    postRefreshToken,
  };
};
