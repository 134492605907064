// Media
import '../../../../../assets/styles/AOP/AOP001/unit.css';
import InfoIcon from '../../../../../assets/images/aop_icon_info.svg';
import { Table } from 'antd';
import ColumnTable from './ColumnTable';
import { AOP004Handler } from '../handler/HandlerImpl';
import { COLUMN_TABLE_FOUR } from '../../contants';
import { roleMapperTabOne } from '../../helpers';

type props = {
    handler: AOP004Handler;
};

const UnitOne = ({ handler }: props) => {
    const { columns, dataUnitTableOne, dataUnitTableTwo } = ColumnTable();
    const cols = columns(COLUMN_TABLE_FOUR);
    const dataTable = roleMapperTabOne(handler.dRole, dataUnitTableOne, dataUnitTableTwo);

    return (
        <div className="aop--001__unit-one">
            <div className="aop--001__unit-one-title">
                <div className="aop--001__unit-one-tt">案件管理</div>
                <div className="aop--001__unit-one-ti">
                    <img src={InfoIcon} alt="icon info" onClick={() => handler.openModalH1()} />
                </div>
            </div>
            <Table bordered pagination={false} columns={cols} dataSource={dataTable[0]} />
            <div className="aop--001__unit-one-title">
                <div className="aop--001__unit-one-tt">勤怠管理</div>
            </div>
            <Table bordered pagination={false} columns={cols} dataSource={dataTable[1]} />
        </div>
    );
};

export default UnitOne;
