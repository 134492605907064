import { CI001Api } from "./ServiceImpl";

const CI001Service = (ci001Api: CI001Api) => {
  const getBasicInformation = async (params: any): Promise<any> => {
    try {
      return await ci001Api.getBasicInformation(params);
    } catch (error) {
      throw error;
    }
  };

  const getScreenMaster = async (params: any): Promise<any> => {
    try {
      return await ci001Api.getScreenMaster(params);
    } catch (error) {
      throw error;
    }
  };

  const updateBasicInformation = async (data: any): Promise<any> => {
    try {
      return await ci001Api.updateBasicInformation(data);
    } catch (error) {
      throw error;
    }
  };

  const getPaymentTerm = async (params: any): Promise<any> => {
    try {
      return await ci001Api.getPaymentTerm(params);
    } catch (error) {
      throw error;
    }
  };

  const updatePaymentTerm = async (data: any): Promise<any> => {
    try {
      return await ci001Api.updatePaymentTerm(data);
    } catch (error) {
      throw error;
    }
  };

  const getApprovalProcess = async (params: any): Promise<any> => {
    try {
      return await ci001Api.getApprovalProcess(params);
    } catch (error) {
      throw error;
    }
  };

  const postApprovalProcess = async (data?: any): Promise<any> => {
    try {
      return await ci001Api.postApprovalProcess(data);
    } catch (error) {
      throw error;
    }
  };
  const resetApproval = async (data?: any): Promise<any> => {
    try {
      return await ci001Api.resetApproval(data);
    } catch (error) {
      throw error;
    }
  };

  const getDetailProject = async (params: any): Promise<any> => {
    try {
      return await ci001Api.getDetailProject(params);
    } catch (error) {
      throw error;
    }
  };

  const handExportTemplate = async (params: any): Promise<any> => {
    try {
      return await ci001Api.handExportTemplate(params);
    } catch (error) {
      throw error;
    }
  };

  const getFileCI = async (params: any) => {
    const response = await ci001Api.getFileCI(params);
    return response?.data;
  };

  const uploadToS3 = async (url: any, data: any): Promise<any> => {
    const response = await ci001Api.uploadToS3(url, data);
    return response?.data;
  };

  const getPresignLinkS3 = async (params: any): Promise<any> => {
    const response = await ci001Api.getPresignLinkS3(params);
    return response?.data;
  };
  const getLinkPresignUpload = async (params: any): Promise<any> => {
    try {
      const response = await ci001Api.getLinkPresignUpload(params);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  const deleteFileCI = async (keyName: string): Promise<any> => {
    return await ci001Api.deleteFileCI(keyName);
  };

  return {
    getBasicInformation,
    getScreenMaster,
    updateBasicInformation,
    getPaymentTerm,
    updatePaymentTerm,
    getApprovalProcess,
    postApprovalProcess,
    resetApproval,
    getDetailProject,
    handExportTemplate,
    getFileCI,
    uploadToS3,
    getPresignLinkS3,
    getLinkPresignUpload,
    deleteFileCI
  };
};

export default CI001Service;
