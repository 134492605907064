import { PM008Api } from "./ServiceImpl";

const PM008Service = (PM008Api: PM008Api) => {
  const createNewProject = async (data: any): Promise<any> => {
    try {
      return await PM008Api.createNewProject(data);
    } catch (error) {
      throw error;
    }
  };

  const getDataMembers = async (params: any): Promise<any> => {
    try {
      return await PM008Api.getDataMembers(params);
    } catch (error) {
      throw error;
    }
  };

  const getDataStatus = async (params: any): Promise<any> => {
    try {
      return await PM008Api.getDataStatus(params);
    } catch (error) {
      throw error;
    }
  };
  const getLinkPresignUpload = async (params: any): Promise<any> => {
    try {
      return await PM008Api.getLinkPresignUpload(params);
    } catch (error) {
      throw error;
    }
  };

  const uploadToS3 = async (url: any, data: any): Promise<any> => {
    try {
      return await PM008Api.uploadToS3(url, data);
    } catch (error) {
      throw error;
    }
  };

  const getPresignLinkS3 = async (params: any): Promise<any> => {
    try {
      return await PM008Api.getPresignLinkS3(params);
    } catch (error) {
      throw error;
    }
  };
  const getListMember = async (params: any): Promise<any> => {
    try {
      return await PM008Api.getListMember(params);
    } catch (error) {
      throw error;
    }
  };
  return {
    createNewProject,
    getDataMembers,
    getDataStatus,
    getLinkPresignUpload,
    uploadToS3,
    getPresignLinkS3,
    getListMember,
  };
};

export default PM008Service;
