import CM006Api from './infrastructure/api/Api';
import CM006Handler from './presenter/handler/Handler';
import CM006UI from './presenter/ui/UI';
import CM006Service from './usecase/Service';

type props = {
    onSubmit?: () => void;
    onReject?: () => void;
    disableSubmitOrReject?: boolean;
    isCheckbox?: boolean
};

const CM006Component = (props: props) => {
    const cm006Api = CM006Api();
    const cm006Service = CM006Service(cm006Api);
    const cm006Handler = CM006Handler(cm006Service);
    return <CM006UI handler={cm006Handler} {...props} />;
};

export default CM006Component;
