import React from "react";

import { FormInstance } from "antd";
import UploadEvidenceUI from "./presenter/ui/UI";
import UploadEvidenceHandler from "./handler/Handler";

type props = {
  nameForm: string | string[];
  form: FormInstance;
  fileNameDefault?: string;
  afterClear?: (fileName: any) => void;
};
const UploadFileCRComponent = ({
  nameForm,
  form,
  fileNameDefault,
  afterClear,
}: props) => {
  const handler = UploadEvidenceHandler();
  return (
    <UploadEvidenceUI
      handler={handler}
      nameForm={nameForm}
      form={form}
      fileNameDefault={fileNameDefault}
      afterClear={afterClear}
    />
  );
};

export default UploadFileCRComponent;
