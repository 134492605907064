import axiosConfig from "../../../../../config/axiosConfig";

const AOP001Api = () => {
  const getListRole = async (params: { page: number; size: number }) => {
    const url = "/role/list/except-admin";
    return await axiosConfig.get(url, { params });
  };

  const deleteRole = async (data: any) => {
    const url = "/role/delete-many";
    return await axiosConfig.delete(url, { data });
  };

  return {
    getListRole,
    deleteRole,
  };
};

export default AOP001Api;
