import ErrorNotification from "../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../common/constants/MESSAGE";
import { MoneyIndirectCostConstructionDirectCost } from "../entity/Entity";
import { CM0015APIImpl } from "./ServiceImpl";

const CM0015Service = (api: CM0015APIImpl) => {
  const getMoneyIndirectCostConstructionDirectCost = async (params: {
    constructionId: number;
  }): Promise<MoneyIndirectCostConstructionDirectCost | null> => {
    try {
      const response = await api.getMoneyIndirectCostConstructionDirectCost(
        params
      );
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return null;
    }
  };
  return { getMoneyIndirectCostConstructionDirectCost };
};

export default CM0015Service;
