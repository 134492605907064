import { notification } from "antd";
import { NotificationPlacement } from "antd/lib/notification";
import SuccessIcon from "../../../assets/icons/successful.svg";
const SuccessNotification = (message: string | string[], description?: string) => {
  let messageDescription = Array.isArray(message) ?
    <div>{message.map((element, index) => <div key={index}>{element}</div>)}</div> :
    <div>{message}</div>

  const placement: NotificationPlacement = "topRight";
  notification.open({
    duration: 2,
    icon: <img alt="" src={SuccessIcon} style={{ marginBottom: 10 }} />,
    message: messageDescription,
    description: description,
    placement,
    style: {
      borderTop: "2px solid #0FA048",
    },
  });
};

export default SuccessNotification;
