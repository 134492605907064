import axiosConfig from "../../../../../config/axiosConfig";

const SM01Api = () => {
  const getListMember = async (params: any): Promise<any> => {
    try {
      const url = "/group/list-member-admin";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getListEvent = async (params: any): Promise<any> => {
    try {
      const url = "/schedule/list";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  return {
    getListMember,
    getListEvent,
  };
};

export default SM01Api;
