import { createSlice } from "@reduxjs/toolkit";
import { AM001SliceType } from "../../entity/Entity";

export const initialState: AM001SliceType = {
  isRefreshFullApi: true,
  isRefresh: false,
  tableColumns: {
    columns: [],
    isNext: true,
    isPrev: true,
  },
  permissionDoubleCheck: false,
};

export const AM001Slice = createSlice({
  name: "am001",
  initialState,
  reducers: {
    refreshAM001: (state) => {
      state.isRefresh = !state.isRefresh;
    },

    setTableColumns: (state, action) => {
      state.tableColumns = action.payload;
    },
    setIsRefreshFullApi: (state, action) => {
      state.isRefreshFullApi = action.payload;
    },
    setPermissionDoubleCheck: (state, action) => {
      state.permissionDoubleCheck = action.payload;
    },
  },
});

export const {
  refreshAM001,
  setTableColumns,
  setIsRefreshFullApi,
  setPermissionDoubleCheck,
} = AM001Slice.actions;

export default AM001Slice.reducer;
