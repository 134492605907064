import CR003Api from "./infrastructure/api/Api";
import CR003Service from "./usecase/Service";
import CR003Handler from "./presenter/handler/Handler";
import CR003UI from "./presenter/ui/CR003UI";

function CR003Component() {
  const cr003Api = CR003Api();
  const cr003Service = CR003Service(cr003Api);
  const cr003Handler = CR003Handler(cr003Service);
  return <CR003UI handler={cr003Handler} />;
}

export default CR003Component;
