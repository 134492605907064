import { Modal, Spin } from 'antd';
import { useSelector } from 'react-redux';
import '../../../../../assets/styles/AM/AM016.css';
import LoadingComponent from '../../../../../common/components/icons/LoadingComponent';
import { RootState } from '../../../../../store';
import { AM016Hanlder } from '../handler/HandlerImpl';
import 'moment/locale/ja';
import XIconModal from '../../../../../assets/icons/x-icon-modal.svg';
import { useEffect } from 'react';
import COMMON from '../../../../../common/constants/COMMON';
import moment from 'moment';
import { STATUS_REQUEST } from '../../../../../common/constants/STATUS';

type Props = {
  handler: AM016Hanlder;
};

const AM016UI = ({ handler }: Props) => {
  const isVisible = useSelector((state: RootState) => state.am016.isVisible);
  const statusCode = useSelector((state: RootState) => state.am016.statusCode);
  const loading = useSelector((state: RootState) => state.am016.loading);
  const requestId = useSelector((state: RootState) => state.am016.requestId);
  const haveProject = useSelector((state: RootState) => state.am010.haveProject);

  useEffect(() => {
    requestId && handler.handleGetRequestAttendance(requestId);
  }, [requestId]);

  return (
    <Modal
      className="group-detail-modal"
      style={{ marginLeft: 300, marginTop: 40, marginBottom: 40 }}
      visible={isVisible}
      closable={false}
      maskClosable={false}
      width={'996px'}
      centered={true}
      footer={null}
    >
      <Spin indicator={LoadingComponent} spinning={loading}>
        <div className="w-100-percent header-modal-containter" style={{ height: 50 }}>
          <div className="header-modal-title-om004 ">打刻申請</div>
          <div style={{ cursor: 'pointer' }} onClick={handler.onCancel}>
            <img alt="" src={XIconModal} style={{ height: 18, width: 18 }} />
          </div>
        </div>
        <div style={{ minHeight: 224, marginTop: 24 }} className="am016-data">
          <div className="row">
            <div className="title">ステータス</div>
            <div
              className={
                statusCode === STATUS_REQUEST.REQUEST_APPROVED
                  ? 'description description_approve'
                  : 'description description_waiting_approve'
              }
            >
              {handler?.requestAttendance?.status?.name}
            </div>
          </div>
          <div className="row">
            <div className="title">事由</div>
            <div className="description">{handler?.requestAttendance?.category?.description}</div>
          </div>
          <div className="row">
            <div className="title">対象日</div>
            <div className="description">
              {handler?.requestAttendance?.requestDate &&
                moment(handler.requestAttendance.requestDate).format(COMMON.FORMAT_DATE)}
            </div>
          </div>
          <div className="row">
            <div className="title">時間</div>
            <div className="description">
              {handler?.requestAttendance?.requestDate &&
                moment(handler.requestAttendance.requestDate).format(COMMON.FORMAT_TIME1)}
            </div>
          </div>
          {handler?.requestAttendance?.project !== null &&
            handler?.requestAttendance?.construction !== null && (
              <>
                <div className="row">
                  <div className="title">案件名</div>
                  <div className="description">{handler?.requestAttendance?.project?.name}</div>
                </div>
                <div className="row">
                  <div className="title">工事名</div>
                  <div className="description">
                    {handler?.requestAttendance?.construction?.name}
                  </div>
                </div>
              </>
            )}
          <div className="row">
            <div className="title">承認者</div>
            <div className="description">{handler?.requestAttendance?.approver?.fullName}</div>
          </div>
          <div className="row">
            <div className="title">備考</div>
            <div className="description">
              {handler?.requestAttendance?.note ? handler?.requestAttendance?.note : '-'}
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default AM016UI;
