import { createSlice } from "@reduxjs/toolkit";
import { CM0022SliceType } from "../../entity/Entity";

export const initialState: CM0022SliceType = {
  isRefresh: false,
  isVisibleApproveCM0022: false,
  isExport: false,
  statusCM0022: {
    code: "",
    createdAt: "",
    description: "",
    id: 0,
    name: "",
    type: "",
    updatedAt: "",
  },
};

export const CM0022Slice = createSlice({
  name: "CM0022",
  initialState,
  reducers: {
    refreshCM0022: (state) => {
      state.isRefresh = !state.isRefresh;
    },
    setVisibleApproveCM0022: (state, action) => {
      state.isVisibleApproveCM0022 = action.payload;
    },
    setStatusCM0022: (state, action) => {
      state.statusCM0022 = action.payload;
    },
    setIsExport: (state) => {
      state.isExport = !state.isExport;
    },
  },
});

export const {
  refreshCM0022,
  setVisibleApproveCM0022,
  setStatusCM0022,
  setIsExport,
} = CM0022Slice.actions;

export default CM0022Slice.reducer;
