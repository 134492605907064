import { HandlerImpl } from '../handler/HandlerImpl';

import MaterialCostComponent from '../component/material-cost';
import { useEffect } from 'react';

interface props {
    handler: HandlerImpl;
}

const MainUI = ({ handler }: props) => {
    useEffect(() => {
        return () => {
            handler.clearRedux()
        }
    }, [])

    return <MaterialCostComponent />;
};

export default MainUI;
