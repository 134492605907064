import { CM008SliceType } from './../type/Presenter';
import { createSlice } from '@reduxjs/toolkit';

const initialState: CM008SliceType = {
    loading: false,
    isVisible: false,
    refresh: false,
    documentIdCM08: 0,
    documentOriginIdCM08: 0,
    summitDateCM08: '',
    statusCM008: '',
};

export const cm008Slice = createSlice({
    name: 'cm008',
    initialState,
    reducers: {
        resetCM008: () => initialState,
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setIsVisibleCM008: (state, action) => {
            state.isVisible = action.payload;
        },
        setRefreshCM008: (state) => {
            state.refresh = !state.refresh;
        },
        setDocumentCM08Id: (state, action) => {
            state.documentIdCM08 = action.payload;
        },
        setDocumentCM08OriginId: (state, action) => {
            state.documentOriginIdCM08 = action.payload;
        },
        setSubmittedDateCM08: (state, action) => {
            state.summitDateCM08 = action.payload;
        },
        setStatusCM008: (state, action) => {
            state.statusCM008 = action.payload;
        },
    },
});

export const {
    setLoading,
    setIsVisibleCM008,
    resetCM008,
    setRefreshCM008,
    setDocumentCM08Id,
    setSubmittedDateCM08,
    setDocumentCM08OriginId,
    setStatusCM008,
} = cm008Slice.actions;

export default cm008Slice.reducer;
