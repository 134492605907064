import React from "react";
import "antd/dist/antd.min.css";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import TodoComponent from "../pages/template";
import OM002Component from "../pages/OM/OM002";
import CI001Component from "../pages/CI/CI001";
import CR003Component from "../pages/CR/CR003";
import OM005Component from "../pages/OM/OM005";
import CRT001Component from "../pages/CR/CRT001";
import PM005Component from "../pages/PM/PM005";
import ASChangeForgotPasswordComponent from "../pages/AS/AS-Change-Forgot-Password";
import ASChangePasswordComponent from "../pages/AS/AS-Change-Password";
import ASForgotPasswordComponent from "../pages/AS/AS-Forgot-Password";
import ASLoginComponent from "../pages/AS/AS-Login";
import PM002Component from "../pages/PM/PM002";
import PM003Component from "../pages/PM/PM003";
import PM008Component from "../pages/PM/PM008";
import PM007Component from "../pages/PM/PM007";
import PM025Component from "../pages/PM/PM025";
import AM002Component from "../pages/AM/AM002";
import AM028Component from "../pages/AM/AM028";
import AM010Component from "../pages/AM/AM010";
import AM004Component from "../pages/AM/AM004";
import AM001Component from "../pages/AM/AM001";
import AM006Component from "../pages/AM/AM006";
import AM008Component from "../pages/AM/AM008";
import AM005Component from "../pages/AM/AM005";
import CM007Component from "../pages/CM/CM007";
import CM0017Component from "../pages/CM/CM0017";
import CM0061Component from "../pages/CM/CM0061";
import CM0019Component from "../pages/CM/CM0019";
import CM0020Component from "../pages/CM/CM0020";
import CM009Component from "../pages/CM/CM009";
import CM0015Component from "../pages/CM/CM0015";

// CM
import CM001Component from "../pages/CM/CM001";
import CM003Component from "../pages/CM/CM003";
import CM004Component from "../pages/CM/CM004";
import CM005Component from "../pages/CM/CM005";
import CM0013Component from "../pages/CM/CM0013";
import CM0030Component from "../pages/CM/CM0030";
import CM0032Component from "../pages/CM/CM0032";
import CM3453Component from "../pages/CM/CM034-CM53/wrapper/index";
import P01Component from "../pages/P/P01";

//DB
import DB001Component from "../pages/DB/DB001";
//SW
import SW01Component from "../pages/SW/SW01";
import SW02Component from "../pages/SW/SW02";
import SN01Component from "../pages/SN/SN01";

// SM
import SM01Component from "../pages/SM/SM01";
import ORD006Component from "../pages/ORD/ORD006";
import IC1Component from "../pages/IC/IC1";
import ORD001Component from "../pages/ORD/ORD001";
import ORD007Component from "../pages/ORD/ORD007";
import AOP004Component from "../pages/AOP/AOP004";
import AOP003Component from "../pages/AOP/AOP003";
import AOP001Component from "../pages/AOP/AOP001";
import { ForbiddenPage } from "./ForbiddenPage";
import {
  AuthorizationPermissionExcludeAminRouter,
  AuthorizationPermissionRouter,
  AuthorizationRoleRouter,
} from "./AuthorizationRouter";
import {
  LIST_ORDER_ROLE,
  PAGE_AUTHORIZATION,
  SYSTEM_ROLES,
} from "../common/constants/AUTHORIZATION";
import T01Component from "../pages/T/T01";
import DynamicAuthorizationComponent from "./dynamic-authorization";
import OM003Component from "../pages/OM/OM003";
import DynamicAuthenticationComponent from "./dynamic-authentication";
import CCUS02Component from "../pages/CCUS/CCUS02";
import CCUS01Component from "../pages/CCUS/CCUS01";
import CCUS05Component from "../pages/CCUS/CCUS05";
import CCUS061Component from "../pages/CCUS/CCUS061";

const BaseRouter = () => {
  // UI
  return (
    <Routes>
      <Route path="/app-login" element={<ASLoginComponent />} />
      <Route
        path="/app-change-password"
        element={<ASChangePasswordComponent />}
      />
      <Route path="/app-forgot" element={<ASForgotPasswordComponent />} />
      <Route
        path="/app-forgot-change-password"
        element={<ASChangeForgotPasswordComponent />}
      />
      <Route path="/app-error-403" element={<ForbiddenPage />} />
      <Route path="/app-template" element={<TodoComponent />} />
      <Route element={<DynamicAuthenticationComponent />}>
        <Route element={<DynamicAuthorizationComponent />}>
          {/* PM */}
          <Route path="/" element={<Navigate to="/app-pm002" />} />
          <Route path="/app-pm007" element={<PM007Component />} />
          <Route path="/app-pm008" element={<PM008Component />} />
          <Route path="/app-pm003" element={<PM003Component />} />
          <Route path="/app-pm002" element={<PM002Component />} />
          <Route path="/app-am001" element={<AM001Component />} />
          {/* <Route element={<AuthorizationPermissionWithoutAdminRouter allowedPermissions={PAGE_AUTHORIZATION.AM002} />}> */}
          <Route path="/app-am002" element={<AM002Component />} />
          {/* </Route> */}
          <Route
            path="/app-am004/:constructionId/:contractorConstructionId"
            element={<AM004Component />}
          />
          <Route
            path="/app-am007/:constructionId/:contractorConstructionId"
            element={<AM004Component />}
          />
          <Route
            path="/app-am006/:constructionId"
            element={<AM006Component />}
          />
          {/* <Route element={<AuthorizationPermissionWithoutAdminRouter allowedPermissions={PAGE_AUTHORIZATION.AM008} />}> */}
          <Route path="/app-am008" element={<AM008Component />} />
          {/* </Route> */}
          {/* <Route element={<AuthorizationPermissionWithoutAdminRouter allowedPermissions={PAGE_AUTHORIZATION.AM005} />}> */}
          <Route path="/app-am005" element={<AM005Component />} />
          {/* </Route> */}
          <Route path="/app-am028" element={<AM028Component />} />
          <Route path="/app-pm005/:projectId" element={<PM005Component />} />
          <Route
            path="/app-ci001/:projectId/:constructionId/:userId/:projectManagerId"
            element={<CI001Component />}
          />
          <Route
            path="/app-cr003/:projectId/:summaryId/:userId/:projectManagerId"
            element={<CR003Component />}
          />
          <Route
            element={
              <AuthorizationPermissionRouter
                allowedPermissions={PAGE_AUTHORIZATION.OM005}
              />
            }
          >
            <Route path="/app-om005/:groupId" element={<OM005Component />} />
          </Route>
          <Route path="/app-am010" element={<AM010Component />} />

          {/* CM */}
          <Route
            element={
              <AuthorizationPermissionRouter
                allowedPermissions={PAGE_AUTHORIZATION.CM}
              />
            }
          >
            <Route
              element={
                <AuthorizationPermissionRouter
                  allowedPermissions={PAGE_AUTHORIZATION.CM}
                />
              }
            >
              <Route path="/app-cm005" element={<CM005Component />} />
              <Route path="/app-cm0015" element={<CM0015Component />} />
              <Route path="/app-cm0013" element={<CM0013Component />} />
              <Route path="/app-cm009" element={<CM009Component />} />
              <Route path="/app-cm007" element={<CM007Component />} />
            </Route>
          </Route>
          <Route
            element={
              <AuthorizationPermissionRouter
                allowedPermissions={PAGE_AUTHORIZATION.CM3}
              />
            }
          >
            <Route path="/app-cm0019" element={<CM0019Component />} />
            <Route path="/app-cm0020" element={<CM0020Component />} />
            <Route path="/app-cm0030" element={<CM0030Component />} />
            <Route path="/app-cm0032" element={<CM0032Component />} />
          </Route>
          <Route path="/app-cm003/pm0061" element={<CM003Component />} />
          <Route path="/app-cm003/cm001" element={<CM003Component />} />
          <Route path="/app-cm004" element={<CM004Component />} />
          <Route path="/app-cm001/pm005" element={<CM001Component />} />
          <Route path="/app-cm0034-cm0053" element={<CM3453Component />} />
          <Route path="/app-cm0061" element={<CM0061Component />} />

          <Route path="/app-cm0017" element={<CM0017Component />} />

          {/* P */}
          <Route element={<AuthorizationPermissionExcludeAminRouter />}>
            <Route path="/app-p01" element={<P01Component />} />
          </Route>

          {/* DB */}
          <Route
            element={
              <AuthorizationPermissionRouter
                allowedPermissions={PAGE_AUTHORIZATION.DB001}
              />
            }
          >
            <Route path="/app-db001" element={<DB001Component />} />
          </Route>
          <Route
            element={
              <AuthorizationPermissionRouter
                allowedPermissions={PAGE_AUTHORIZATION.SW01}
              />
            }
          >
            <Route path="/app-sw01" element={<SW01Component />} />
          </Route>
          <Route
            element={
              <AuthorizationPermissionRouter
                allowedPermissions={PAGE_AUTHORIZATION.SW02}
              />
            }
          >
            <Route path="/app-sw02" element={<SW02Component />} />
            <Route path="/app-sw01/sw02" element={<SW02Component />} />
          </Route>

          {/* SM */}
          <Route path="/app-sm01" element={<SM01Component />} />

          {/* SN */}
          {/* <Route element={<AuthorizationPermissionWithoutAdminRouter allowedPermissions={PAGE_AUTHORIZATION.SN01} />}> */}
          <Route path="/app-sn01" element={<SN01Component />} />
          <Route path="/app-pm002/sn01" element={<SN01Component />} />
          <Route path="/app-pm003/sn01" element={<SN01Component />} />
          {/* </Route> */}

          {/* ORD */}
          <Route
            element={
              <AuthorizationRoleRouter roles={[SYSTEM_ROLES.ADMIN_SYSTEM]} />
            }
          >
            <Route path="/app-ord001" element={<ORD001Component />} />
            <Route path="/app-ord006/:orderId" element={<ORD006Component />} />
            <Route path="/app-ord007" element={<ORD007Component />} />
          </Route>

          {/* IC */}
          <Route path="/app-ic1" element={<IC1Component />} />

          {/* AOP */}
          <Route
            element={<AuthorizationPermissionRouter allowedPermissions={[]} />}
          >
            <Route path="/app-aop001" element={<AOP001Component />} />
          </Route>
          <Route path="/app-aop003/:roleId" element={<AOP003Component />} />
          <Route
            path="/app-aop003/app-aop004/:roleId"
            element={<AOP003Component />}
          />
          <Route path="/app-aop004" element={<AOP004Component />} />
          <Route
            element={<AuthorizationPermissionRouter allowedPermissions={[]} />}
          >
            <Route path="/app-t01" element={<T01Component />} />
          </Route>
          <Route path="/app-om002" element={<OM002Component />} />
          <Route path="/app-om003/:memberId" element={<OM003Component />} />

          <Route path="/app-crt001/:projectId" element={<CRT001Component />} />
          <Route path="/app-pm025" element={<PM025Component />} />
          <Route path="/app-ccus01" element={<CCUS01Component />} />
          <Route path="/app-ccus02" element={<CCUS02Component />} />
          <Route path="/app-ccus05" element={<CCUS05Component />} />
          <Route path="/app-ccus061" element={<CCUS061Component />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default BaseRouter;
