import { MaterialCostAPIImpl } from "./ServiceImpl";
import ErrorNotification from "../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../common/constants/MESSAGE";
import { MoneyDirectCostLaborListData } from "../entity/Entity";
import {
  BaseResponseType,
  MoneyDirectCostLaborEnablePayload,
  MoneySubmitDirectCostLaborPayload,
  MoneyDirectCostLaborListManagerData,
} from "../entity/Entity";

const MaterialCostService = (api: MaterialCostAPIImpl) => {
  const getMoneyDirectCostLaborListManager = async (params: {
    constructionId: number;
    keyword?: string;
    sortBy?: string;
    sortType?: string;
  }): Promise<MoneyDirectCostLaborListManagerData | null> => {
    try {
      const response = await api.getMoneyDirectCostLaborListManager({
        ...params,
        documentId: 0,
      });
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return null;
    }
  };
  const getMoneyDirectCostLaborList = async (params: {
    documentDirectLaborCostId: number;
    sortBy?: string;
    sortType?: string;
    page: number;
    size: number;
  }): Promise<MoneyDirectCostLaborListData | null> => {
    try {
      const response = await api.getMoneyDirectCostLaborList(params);
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return null;
    }
  };

  const putMoneyDirectCostLaborEnable = async (
    payload: MoneyDirectCostLaborEnablePayload
  ): Promise<BaseResponseType | null> => {
    try {
      const response = await api.putMoneyDirectCostLaborEnable(payload);
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return null;
    }
  };

  const putMoneySubmitDirectCostLabor = async (
    payload: MoneySubmitDirectCostLaborPayload
  ): Promise<BaseResponseType | null> => {
    try {
      const response = await api.putMoneySubmitDirectCostLabor(payload);
      return response.data;
    } catch (error: any) {
      throw error
    }
  };

  const doExportCM0013 = async (params: any): Promise<any> => {
    return await api.doExportCM0013(params);
  };

  return {
    getMoneyDirectCostLaborListManager,
    putMoneyDirectCostLaborEnable,
    getMoneyDirectCostLaborList,
    putMoneySubmitDirectCostLabor,
    doExportCM0013,
  };
};

export default MaterialCostService;
