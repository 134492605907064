import { useDispatch } from "react-redux";
import { useState } from "react";
// Built-in
import React from "react";
import { CompletedPaidAmountServiceImpl } from "../../usecase/ServiceImpl";
import { MoneyConstructionWorkAmountCompletedResult } from "../../entity/Entity";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../../common/constants/MESSAGE";
import { setMoneyConstructionWorkAmountCompletedResult } from "../slice/Slice";

// Source files

const CompletedPaidAmountHandler = (
  service: CompletedPaidAmountServiceImpl
) => {
  const dispatch = useDispatch();

  const getMoneyConstructionWorkAmountCompleted = async (params: {
    constructionId: number;
    from?: string;
    to?: string;
  }): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const responseData =
        await service.getMoneyConstructionWorkAmountCompleted({
          constructionId: params.constructionId,
          from: params.from,
          to: params.to,
        });
      if (responseData)
        dispatch(setMoneyConstructionWorkAmountCompletedResult(responseData));
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  return {
    getMoneyConstructionWorkAmountCompleted,
  };
};

export default CompletedPaidAmountHandler;
