import { CollapsedTableAPIImpl } from './ServiceImpl';
import {
    MoneyDirectCostLaborListData,
    MoneyDirectCostLaborCreatePayload,
    MoneyDirectCostLaborDeletePayload,
    MoneyDirectCostLaborUpdatePayload,
} from '../entity/Entity';
import ErrorNotification from '../../../../common/components/notification/ErrorNotification';
import { NOTIFICATION_TITLE } from '../../../../common/constants/MESSAGE';
import { BaseResponseType } from '../entity/Entity';

const CollapsedTableService = (api: CollapsedTableAPIImpl) => {
    const getMoneyDirectCostLaborList = async (params: {
        documentDirectLaborCostId: number;
        sortBy?: string;
        sortType?: string;
        page: number;
        size: number;
    }): Promise<MoneyDirectCostLaborListData | null> => {
        try {
            const response = await api.getMoneyDirectCostLaborList(params);
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };
    const postMoneyDirectCostLaborCreate = async (
        payload: MoneyDirectCostLaborCreatePayload,
    ): Promise<BaseResponseType | null> => {
        try {
            const response = await api.postMoneyDirectCostLaborCreate(payload);
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };

    const deleteMoneyDirectCostLaborDelete = async (
        payload: MoneyDirectCostLaborDeletePayload,
    ): Promise<BaseResponseType | null> => {
        try {
            const response = await api.deleteMoneyDirectCostLaborDelete(payload);
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };
    const putMoneyDirectCostLaborUpdate = async (
        payload: MoneyDirectCostLaborUpdatePayload,
    ): Promise<BaseResponseType | null> => {
        try {
            const response = await api.putMoneyDirectCostLaborUpdate(payload);
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };

    const getLinkPresignUpload = async (params: any): Promise<any> => {
        try {
            const response = await api.getLinkPresignUpload(params);
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    const uploadToS3 = async (url: any, data: any): Promise<any> => {
        try {
            const response = await api.uploadToS3(url, data);
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            throw error;
        }
    };

    const getPresignLinkS3 = async (params: any): Promise<any> => {
        try {
            const response = await api.getPresignLinkS3(params);
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            throw error;
        }
    };
    return {
        getMoneyDirectCostLaborList,
        deleteMoneyDirectCostLaborDelete,
        postMoneyDirectCostLaborCreate,
        putMoneyDirectCostLaborUpdate,
        getLinkPresignUpload,
        uploadToS3,
        getPresignLinkS3,
    };
};

export default CollapsedTableService;
