import helpers from "../../../common/helpers/common";

const getSearchMembers = (
  contractorMembers: any[],
  keyword: string,
  contractorConstructionId: any
) => {
  const memberDataSource = helpers.addKeyToDataSource(contractorMembers);
  const memberKey = memberDataSource.map((element) => ({
    ...element,
    key: element.key + "" + contractorConstructionId,
  }));
  const searchMembers = memberKey.filter((element) =>
    helpers
      .toASCII(element.fullName || "")
      .toUpperCase()
      .includes(helpers.toASCII(keyword || "").toUpperCase())
  );
  return searchMembers;
};
export { getSearchMembers };
