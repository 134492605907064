import React from 'react';
import moment from 'moment';
import COMMON, { TAX_CODE } from '../../../../../common/constants/COMMON';
import helpers from '../../../../../common/helpers/common';
import { Empty } from 'antd';
import MESSAGE from '../../../../../common/constants/MESSAGE';
import { OffsetCost } from '../../entity/Entity';
import CostTableComponent from './CostTable';
import IconDown from '../../../../../assets/icons/icon-down-small.svg';
import TooltipText from '../../../../../common/components/tooltip-text/TooltipText';

type props = {
    constructionList: any[];
    setProjectExpandKeys: (key: any) => void;
    checkProjectKeyExpand: (key: any) => boolean;
};

const ProjectRowComponent = ({
    constructionList,
    setProjectExpandKeys,
    checkProjectKeyExpand,
}: props) => {
    return (
        <React.Fragment>
            {constructionList.length === 0 ? (
                <tr>
                    <td colSpan={10}>
                        <Empty
                            style={{ width: '100%' }}
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={MESSAGE.MESSAGE_DATA_NOT_FOUND}
                        />
                    </td>
                </tr>
            ) : (
                constructionList.map((element, index) => (
                    <React.Fragment key={`table${element.id}`}>
                        <tr
                            key={`table${element.id}`}
                            className="bg-fa button-style"
                            onClick={() => {
                                setProjectExpandKeys(element.id);
                            }}
                        >
                            <td colSpan={2}>{index + 1}</td>
                            <td colSpan={2}></td>
                            <td>
                                <div style={{ width: 200 }}>
                                    <TooltipText content={element?.description ?? ''} />
                                </div>
                            </td>
                            <td>{`${helpers.formatNumberMoney(
                                element.paymentAmountExcludeTax,
                            )}円`}</td>
                            <td>{`${helpers.formatNumberMoney(element.taxAmount)}円`}</td>
                            <td>{`${helpers.formatNumberMoney(
                                element.paymentAmountExcludeTax + element.taxAmount,
                            )}円`}</td>
                            <td>
                                <img
                                    alt=""
                                    src={IconDown}
                                    className={`cm024-icon ${
                                        !checkProjectKeyExpand(element.id)
                                            ? ''
                                            : 'cm024-icon-active'
                                    }`}
                                />
                            </td>
                        </tr>
                        {checkProjectKeyExpand(element.id) ? (
                            <CostTableComponent
                                constructionCompanyList={element.constructionCompanyList}
                            />
                        ) : (
                            <></>
                        )}
                    </React.Fragment>
                ))
            )}
        </React.Fragment>
    );
};

export default ProjectRowComponent;
