import {
  Button,
  Form,
  Input,
  DatePicker,
  Select,
  InputNumber,
  Card,
  List,
} from "antd";
import React, { useMemo } from "react";
import EditWhiteSvg from "../../../../../assets/icons/edit-white.svg";
import CalendarSvg from "../../../../../assets/icons/calendar.svg";
import REGEX from "../../../../../common/constants/REGEX";
import { CR003BasicInfoUIHandler } from "../handler/HandlerImpl";
import {
  onBlurTrimFormItem,
  typeInteger,
} from "../../../../../common/helpers/typeInput";
import moment from "moment";
import COMMON, {
  MAX_LENGTH,
  USER_FLOW,
} from "../../../../../common/constants/COMMON";
import locale from "antd/es/date-picker/locale/ja_JP";
import "moment/locale/ja";
import MESSAGE from "../../../../../common/constants/MESSAGE";
import helpers from "../../../../../common/helpers/common";
import { useParams } from "react-router-dom";
import { isHasPrivilege } from "../../../../../common/helpers/privilege";
import { PRIVILEGE_PM } from "../../../../../common/constants/PRIVILEGE";
import { SYSTEM_ROLES } from "../../../../../common/constants/AUTHORIZATION";
import { RootState } from "../../../../../store";
import { useSelector } from "react-redux";
import Dragger from "antd/lib/upload/Dragger";
import IconFile from "../../../../../assets/icons/ant-design_file-pdf-filled.svg";
import CloudUpIcon from "../../../../../assets/icons/cloud-up-icon.svg";
import PdfIcon from "../../../../../assets/icons/pdf-icon.svg";
import TooltipText from "../../../../../common/components/tooltip-text/TooltipText";
import IconDelete from "../../../../../assets/icons/icon-trash-red.svg";

const { Item } = Form;
const { RangePicker } = DatePicker;

const RESIDENT_PRESENCE = [
  {
    name: "有",
    value: "yes",
  },
  {
    name: "無",
    value: "no",
  },
];

const REGEX_NAME = {
  regex: REGEX.NAME,
  message: MESSAGE.MESSAGE_INVALID_INPUT,
};

const MAX_LENGTH_1 = 30;
const MAX_LENGTH_2 = 100;
const MAX_LENGTH_3 = 50;

const dummyRequest = (value: any) => {
  setTimeout(() => {
    value.onSuccess("ok");
  }, 0);
};

interface props {
  handler: CR003BasicInfoUIHandler;
}
function CR003BasicInformation({ handler }: props) {
  const parentId = localStorage.getItem("parentId");
  const { projectManagerId } = useParams();

  const isRoleAdmin = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdmin
  );

  const isRoleAdminSystem = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdminSystem
  );

  const checkBtn = useMemo(() => {
    return helpers.checkButtonWithWorkflowRolePM(
      handler.currentUserApproval,
      handler.listApprovalProcess,
      +(projectManagerId ?? 0)
    );
  }, [
    handler.currentUserApproval,
    handler.listApprovalProcess,
    projectManagerId,
  ]);

  return (
    <Form
      name="cr003Basic"
      form={handler.form}
      onFinish={handler.onSubmitBasic}
      onValuesChange={handler.onChangeForm}
      onKeyDown={helpers.preventEnterForm}
    >
      <div className="ci001-basic">
        {(checkBtn === USER_FLOW.MANAGER_SUBMIT ||
          isHasPrivilege(handler.detailProject, [PRIVILEGE_PM]) ||
          isRoleAdmin ||
          isRoleAdminSystem) &&
        !handler.isEdit &&
        handler.checkApproved(handler.listApprovalProcess) ? (
          <div className="ci001-basic-header">
            <Button
              size="large"
              className="button-purple"
              onClick={() => handler.onClickCheckEdit(!handler.isEdit)}
              icon={
                <img
                  style={{ paddingRight: 12, paddingLeft: 2 }}
                  alt=""
                  src={EditWhiteSvg}
                />
              }
            >
              編集
            </Button>
          </div>
        ) : null}

        <div className="ci001-ifo">
          <div className="ci001-ifo-left">
            <div
              className={`ci001-item ${handler.isEdit && "ci001-item-edit"}`}
            >
              <span className="ci001-item-label">見積番号</span>
              <span className="ci001-item-text">
                {handler.isEdit ? (
                  <Item
                    name="estimationCode"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (value && value !== "") {
                            // CHECK INPUT VIETNAMESE
                            if (!REGEX.NOT_VIETNAM.test(value)) {
                              return Promise.reject(
                                new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                              );
                            }
                            if (value?.length > MAX_LENGTH_1) {
                              return Promise.reject(new Error(""));
                            }
                            return Promise.resolve();
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input
                      autoComplete="off"
                      className="ci001-input"
                      maxLength={MAX_LENGTH_1}
                      onChange={helpers.preventInputJapanese}
                      onBlur={() =>
                        onBlurTrimFormItem(handler.form, "estimationCode")
                      }
                    />
                  </Item>
                ) : (
                  handler.basicInfo?.estimationCode
                )}
              </span>
            </div>
            <div
              className={`ci001-item ${handler.isEdit && "ci001-item-edit"}`}
            >
              <span className="ci001-item-label">工事番号</span>
              <span className="ci001-item-text">
                {handler.isEdit ? (
                  <Item
                    name="constructionCode"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (value && value !== "") {
                            // CHECK INPUT VIETNAMESE
                            if (!REGEX.NOT_VIETNAM.test(value)) {
                              return Promise.reject(
                                new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                              );
                            }
                            if (value?.length > MAX_LENGTH_1) {
                              return Promise.reject(new Error(""));
                            }
                            return Promise.resolve();
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input
                      autoComplete="off"
                      size="large"
                      style={{ width: "65%" }}
                      min={0}
                      maxLength={MAX_LENGTH_1}
                      onBlur={() =>
                        onBlurTrimFormItem(handler.form, "constructionCode")
                      }
                    />
                  </Item>
                ) : (
                  handler.basicInfo?.constructionCode
                )}
              </span>
            </div>
            <div
              className={`ci001-item ${handler.isEdit && "ci001-item-edit"}`}
            >
              <span className="ci001-item-label">
                工事名
                {handler.isEdit && <span style={{ color: "red" }}>*</span>}
              </span>
              <span className="ci001-item-text">
                {handler.isEdit ? (
                  <Item
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (value && value !== "") {
                            // CHECK INPUT VIETNAMESE
                            if (!REGEX.NOT_VIETNAM.test(value)) {
                              return Promise.reject(
                                new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                              );
                            }
                            if (value?.length > MAX_LENGTH_2) {
                              return Promise.reject(new Error(""));
                            }
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "空白のままにすることはできません。データを入力してください"
                            )
                          );
                        },
                      }),
                    ]}
                    name="constructionName"
                  >
                    <Input
                      autoComplete="off"
                      className="ci001-input"
                      maxLength={MAX_LENGTH_2}
                      disabled={parentId !== null}
                      onChange={helpers.preventInputJapanese}
                      onBlur={() =>
                        onBlurTrimFormItem(handler.form, "constructionName")
                      }
                    />
                  </Item>
                ) : (
                  handler.basicInfo?.constructionName
                )}
              </span>
            </div>
            <div
              className={`ci001-item ${handler.isEdit && "ci001-item-edit"}`}
            >
              <span className="ci001-item-label">担当部署</span>
              <span className="ci001-item-text">
                {handler.isEdit ? (
                  <Item
                    name="departmentIc"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (value && value !== "") {
                            // CHECK INPUT VIETNAMESE
                            if (!REGEX.NOT_VIETNAM.test(value)) {
                              return Promise.reject(
                                new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                              );
                            }
                            if (value?.length > MAX_LENGTH_1) {
                              return Promise.reject(new Error(""));
                            }
                            return Promise.resolve();
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input
                      autoComplete="off"
                      className="ci001-input"
                      maxLength={MAX_LENGTH_1}
                      onChange={helpers.preventInputJapanese}
                      onBlur={() =>
                        onBlurTrimFormItem(handler.form, "departmentIc")
                      }
                    />
                  </Item>
                ) : (
                  handler.basicInfo?.departmentIc
                )}
              </span>
            </div>
            <div
              className={`ci001-item ${handler.isEdit && "ci001-item-edit"}`}
            >
              <span className="ci001-item-label">工事担当（主）</span>
              <span className="ci001-item-text">
                {" "}
                {handler.isEdit ? (
                  <Item
                    name="picMainName"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (value && value !== "") {
                            // CHECK INPUT VIETNAMESE
                            if (!REGEX.NOT_VIETNAM.test(value)) {
                              return Promise.reject(
                                new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                              );
                            }
                            if (value?.length > MAX_LENGTH_1) {
                              return Promise.reject(new Error(""));
                            }
                            return Promise.resolve();
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input
                      autoComplete="off"
                      className="ci001-input"
                      maxLength={MAX_LENGTH_1}
                      onChange={helpers.preventInputJapanese}
                      onBlur={() =>
                        onBlurTrimFormItem(handler.form, "picMainName")
                      }
                    />
                  </Item>
                ) : (
                  handler.basicInfo?.picMainName
                )}
              </span>
            </div>
            <div
              className={`ci001-item ${handler.isEdit && "ci001-item-edit"}`}
            >
              <span className="ci001-item-label">工事担当（副）</span>
              <span className="ci001-item-text">
                {handler.isEdit ? (
                  <Item
                    name="picSubName"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (value && value !== "") {
                            // CHECK INPUT VIETNAMESE
                            if (!REGEX.NOT_VIETNAM.test(value)) {
                              return Promise.reject(
                                new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                              );
                            }
                            if (value?.length > 100) {
                              return Promise.reject(new Error(""));
                            }
                            return Promise.resolve();
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input
                      autoComplete="off"
                      className="ci001-input"
                      maxLength={100}
                      onChange={helpers.preventInputJapanese}
                      onBlur={() =>
                        onBlurTrimFormItem(handler.form, "picSubName")
                      }
                    />
                  </Item>
                ) : (
                  handler.basicInfo?.picSubName
                )}
              </span>
            </div>
            <div
              className={`ci001-item ${handler.isEdit && "ci001-item-edit"}`}
            >
              <span className="ci001-item-label">営業担当</span>
              <span className="ci001-item-text">
                {handler.isEdit ? (
                  <Item
                    name="picBusinessName"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (value && value !== "") {
                            // CHECK INPUT VIETNAMESE
                            if (!REGEX.NOT_VIETNAM.test(value)) {
                              return Promise.reject(
                                new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                              );
                            }
                            if (value?.length > MAX_LENGTH_1) {
                              return Promise.reject(new Error(""));
                            }
                            return Promise.resolve();
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input
                      autoComplete="off"
                      className="ci001-input"
                      maxLength={MAX_LENGTH_1}
                      onChange={helpers.preventInputJapanese}
                      onBlur={() =>
                        onBlurTrimFormItem(handler.form, "picBusinessName")
                      }
                    />
                  </Item>
                ) : (
                  handler.basicInfo?.picBusinessName
                )}
              </span>
            </div>
            <div
              className={`ci001-item ${handler.isEdit && "ci001-item-edit"}`}
            >
              <span className="ci001-item-label">発注元名称</span>
              <span className="ci001-item-text">
                {handler.isEdit ? (
                  <Item
                    name="rootOrderName"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (value && value !== "") {
                            // CHECK INPUT VIETNAMESE
                            if (!REGEX.NOT_VIETNAM.test(value)) {
                              return Promise.reject(
                                new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                              );
                            }
                            if (value?.length > MAX_LENGTH_1) {
                              return Promise.reject(new Error(""));
                            }
                            return Promise.resolve();
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input
                      autoComplete="off"
                      className="ci001-input"
                      maxLength={MAX_LENGTH_1}
                      onChange={helpers.preventInputJapanese}
                      onBlur={() =>
                        onBlurTrimFormItem(handler.form, "rootOrderName")
                      }
                    />
                  </Item>
                ) : (
                  handler.basicInfo?.rootOrderName
                )}
              </span>
            </div>
            <div
              className={`ci001-item ${handler.isEdit && "ci001-item-edit"}`}
            >
              <span className="ci001-item-label">客先担当者</span>
              <span className="ci001-item-text">
                {handler.isEdit ? (
                  <Item
                    name="picCustomerName"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (value && value !== "") {
                            // CHECK INPUT VIETNAMESE
                            if (!REGEX.NOT_VIETNAM.test(value)) {
                              return Promise.reject(
                                new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                              );
                            }
                            if (value?.length > MAX_LENGTH_1) {
                              return Promise.reject(new Error(""));
                            }
                            return Promise.resolve();
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input
                      autoComplete="off"
                      className="ci001-input"
                      maxLength={MAX_LENGTH_1}
                      onChange={helpers.preventInputJapanese}
                      onBlur={() =>
                        onBlurTrimFormItem(handler.form, "picCustomerName")
                      }
                    />
                  </Item>
                ) : (
                  handler.basicInfo?.picCustomerName
                )}
              </span>
            </div>
            <div
              className={`ci001-item ${handler.isEdit && "ci001-item-edit"}`}
            >
              <span className="ci001-item-label">住所</span>
              <span className="ci001-item-text">
                {handler.isEdit ? (
                  <Item
                    name="location"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (value && value !== "") {
                            // CHECK INPUT VIETNAMESE
                            if (!REGEX.NOT_VIETNAM.test(value)) {
                              return Promise.reject(
                                new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                              );
                            }
                            if (value?.length > MAX_LENGTH_3) {
                              return Promise.reject(new Error(""));
                            }
                            return Promise.resolve();
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input
                      autoComplete="off"
                      className="ci001-input"
                      maxLength={MAX_LENGTH.ADDRESS}
                      onChange={helpers.preventInputJapanese}
                      placeholder="例：東京都渋谷区神南1-2-3 シブヤビルディング101号室" // CR #344212
                      onBlur={() =>
                        onBlurTrimFormItem(handler.form, "location")
                      }
                    />
                  </Item>
                ) : (
                  handler.basicInfo?.location
                )}
              </span>
            </div>
          </div>
          <div className="ci001-ifo-right">
            <div
              className={`ci001-item ${handler.isEdit && "ci001-item-edit"}`}
            >
              <span className="ci001-item-label">TEL</span>
              <span className="ci001-item-text">
                {handler.isEdit ? (
                  <Item
                    name="phoneNumber"
                    rules={[
                      {
                        pattern: REGEX.REGEX_PHONE_FAX,
                        message: MESSAGE.MESSAGE_INVALID_INPUT,
                      },
                    ]}
                  >
                    <Input
                      autoComplete="off"
                      className="ci001-input"
                      maxLength={COMMON.MAX_LENGTH_PHONE_NUMBER}
                      placeholder="例：09011112222　ハイフン(ー)なし　半角" // CR #344212
                      onKeyPress={typeInteger}
                      onChange={(e: any) =>
                        handler.form.setFieldValue(
                          "phoneNumber",
                          helpers.toPhoneNumberFormat(e?.target?.value)
                        )
                      }
                      onBlur={() =>
                        onBlurTrimFormItem(handler.form, "phoneNumber")
                      }
                    />
                  </Item>
                ) : (
                  handler.basicInfo?.phoneNumber
                )}
              </span>
            </div>
            <div
              className={`ci001-item ${handler.isEdit && "ci001-item-edit"}`}
            >
              <span className="ci001-item-label">FAX</span>
              <span className="ci001-item-text">
                {handler.isEdit ? (
                  <Item
                    name="fax"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (value && value !== "") {
                            value = value.trim().replaceAll(" ", "");
                            value = value.replaceAll("-", "");
                            if (!REGEX.PHONENUMBER_2.test(value))
                              return Promise.reject(
                                MESSAGE.MESSAGE_WRONG_FAX_FORMAT
                              );
                            if (
                              value?.length >
                              COMMON.MAX_LENGTH_PHONE_NUMBER - 2
                            ) {
                              return Promise.reject(
                                MESSAGE.MESSAGE_VALIDATE_FAX
                              );
                            }
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input
                      autoComplete="off"
                      className="ci001-input"
                      placeholder="例：09011112222　ハイフン(ー)なし　半角" // CR #344212
                      maxLength={100000}
                      onKeyPress={typeInteger}
                      onChange={(e: any) =>
                        handler.form.setFieldValue(
                          "fax",
                          helpers.toPhoneNumberFormat(e?.target?.value)
                        )
                      }
                      onBlur={() => onBlurTrimFormItem(handler.form, "fax")}
                    />
                  </Item>
                ) : (
                  handler.basicInfo?.fax
                )}
              </span>
            </div>
            <div
              className={`ci001-item ${handler.isEdit && "ci001-item-edit"}`}
            >
              <span className="ci001-item-label">工事区分</span>
              <span
                className={`ci001-item-text cr003-margin ${
                  !handler.isEdit &&
                  handler.categoryValue?.category1?.description &&
                  "cr003-item-text-list-style"
                }`}
              >
                {handler.isEdit ? (
                  <Item name="category1">
                    <Select
                      className="ci001-select ci001-select-custom"
                      getPopupContainer={(trigger: any) => trigger.parentNode}
                      // onChange={}
                    >
                      {handler.dropDownBasicInfo?.dropDown1 &&
                        handler.dropDownBasicInfo?.dropDown1.map(
                          (option: any) => (
                            <Select.Option key={option?.id} value={option?.id}>
                              {option?.description}
                            </Select.Option>
                          )
                        )}
                    </Select>
                  </Item>
                ) : (
                  handler.categoryValue?.category1?.description
                )}
              </span>
            </div>
            <div
              className={`ci001-item ${handler.isEdit && "ci001-item-edit"}`}
            >
              <span className="ci001-item-label"></span>
              <span
                className={`ci001-item-text ${
                  !handler.categoryValue?.category2?.description &&
                  "cr003-margin"
                } ${
                  !handler.isEdit &&
                  handler.categoryValue?.category2?.description &&
                  "cr003-item-text-list-style"
                }`}
              >
                {handler.isEdit ? (
                  <Item name="category2">
                    <Select
                      className="ci001-select ci001-select-custom"
                      getPopupContainer={(trigger: any) => trigger.parentNode}
                      // onChange={}
                    >
                      {handler.dropDownBasicInfo?.dropDown2 &&
                        handler.dropDownBasicInfo?.dropDown2.map(
                          (option: any) => (
                            <Select.Option key={option.id} value={option.id}>
                              {option.description}
                            </Select.Option>
                          )
                        )}
                    </Select>
                  </Item>
                ) : (
                  handler.categoryValue?.category2?.description
                )}
              </span>
            </div>
            <div
              className={`ci001-item ${handler.isEdit && "ci001-item-edit"}`}
            >
              <span className="ci001-item-label"></span>
              <span
                className={`ci001-item-text cr003-margin ${
                  !handler.isEdit &&
                  handler.categoryValue?.category3?.description &&
                  "cr003-item-text-list-style"
                }`}
              >
                {handler.isEdit ? (
                  <Item name="category3">
                    <Select
                      className="ci001-select ci001-select-custom"
                      getPopupContainer={(trigger: any) => trigger.parentNode}
                      // onChange={}
                    >
                      {handler.dropDownBasicInfo?.dropDown3 &&
                        handler.dropDownBasicInfo?.dropDown3.map(
                          (option: any) => (
                            <Select.Option key={option.id} value={option.id}>
                              {option.description}
                            </Select.Option>
                          )
                        )}
                    </Select>
                  </Item>
                ) : (
                  handler.categoryValue?.category3?.description
                )}
              </span>
            </div>
            <div
              className={`ci001-item ${handler.isEdit && "ci001-item-edit"}`}
            >
              <span className="ci001-item-label">契約日</span>
              <span className="ci001-item-text">
                {handler.isEdit ? (
                  <Item name="contractCreateDate">
                    <DatePicker
                      // defaultValue={[moment('2015/01/01', dateFormat), moment('2015/01/01', dateFormat)]}
                      format={COMMON.FORMAT_DATE_CI}
                      className="ci001-date-picker"
                      placeholder=""
                      locale={locale}
                      suffixIcon={
                        <img
                          alt=""
                          src={CalendarSvg}
                          className="icon-calender"
                        />
                      }
                      inputReadOnly
                      getPopupContainer={(trigger: any) => trigger.parentNode}
                    />
                  </Item>
                ) : handler.basicInfo?.contractCreateDate &&
                  handler.basicInfo?.contractCreateDate !== "" ? (
                  `${moment(handler.basicInfo?.contractCreateDate).format(
                    COMMON.FORMAT_DATE_CI
                  )}`
                ) : (
                  ""
                )}
              </span>
            </div>
            <div
              className={`ci001-item ${handler.isEdit && "ci001-item-edit"}`}
            >
              <span className="ci001-item-label">支払条件</span>
              <span className="ci001-item-text">
                {handler.isEdit ? (
                  <Item
                    name="conditionPayment"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (value && value !== "") {
                            // CHECK INPUT VIETNAMESE
                            if (!REGEX.NOT_VIETNAM.test(value)) {
                              return Promise.reject(
                                new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                              );
                            }
                            if (value?.length > MAX_LENGTH_1) {
                              return Promise.reject(new Error(""));
                            }
                            return Promise.resolve();
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input
                      autoComplete="off"
                      placeholder="例：毎月 末 〆 翌月 20日 払" // CR #344212
                      size="large"
                      style={{ width: "57%" }}
                      min={0}
                      max={MAX_LENGTH_1}
                      onBlur={() =>
                        onBlurTrimFormItem(handler.form, "conditionPayment")
                      }
                    />
                  </Item>
                ) : (
                  handler.basicInfo?.conditionPayment
                )}
              </span>
            </div>
            <div
              className={`ci001-item ${handler.isEdit && "ci001-item-edit"}`}
            >
              <span className="ci001-item-label">延床面積</span>
              <span className="ci001-item-text">
                {handler.isEdit ? (
                  <Item
                    name="areaFloor"
                    rules={[
                      {
                        pattern: new RegExp(REGEX_NAME.regex),
                        message: REGEX_NAME.message,
                      },
                      // {
                      //   maxLength: MAX_LENGTH_4,
                      //   message: "",
                      // },
                    ]}
                  >
                    <InputNumber
                      controls={false}
                      autoComplete="off"
                      size="large"
                      style={{ width: "57%" }}
                      min={0}
                      max={9999999999}
                      formatter={helpers.formatInputNumber}
                      parser={helpers.parserInputNumber}
                      addonAfter={<span className="cr003-icon-input">m²</span>}
                    />
                  </Item>
                ) : (
                  `
                  ${
                    handler.basicInfo?.areaFloor ||
                    handler.basicInfo?.areaFloor === 0
                      ? Number(handler.basicInfo?.areaFloor)?.toLocaleString(
                          "en-US",
                          {
                            maximumFractionDigits: 2,
                          }
                        )
                      : ""
                  }
                  m²`
                )}
              </span>
            </div>
            <div
              className={`ci001-item ${handler.isEdit && "ci001-item-edit"}`}
            >
              <span className="ci001-item-label">原価/面積</span>
              <span className="ci001-item-text">
                {handler.isEdit ? (
                  <Item
                    name="costArea"
                    rules={[
                      {
                        pattern: new RegExp(REGEX_NAME.regex),
                        message: REGEX_NAME.message,
                      },
                      // {
                      //   max: MAX_LENGTH_4,
                      //   message: "",
                      // },
                    ]}
                  >
                    <InputNumber
                      controls={false}
                      autoComplete="off"
                      size="large"
                      style={{ width: "57%" }}
                      min={0}
                      max={9999999999}
                      formatter={helpers.formatInputNumber}
                      parser={helpers.parserInputNumber}
                      addonAfter={
                        <span className="cr003-icon-input">円 / m²</span>
                      }
                    />
                  </Item>
                ) : (
                  `${
                    handler.basicInfo?.costArea ||
                    handler.basicInfo?.costArea === 0
                      ? Number(handler.basicInfo?.costArea)?.toLocaleString(
                          "en-US",
                          {
                            maximumFractionDigits: 2,
                          }
                        )
                      : ""
                  } 円 / m²`
                )}
              </span>
            </div>
            <div
              className={`ci001-item ${handler.isEdit && "ci001-item-edit"}`}
            >
              <span className="ci001-item-label">見積/面積</span>
              <span className="ci001-item-text">
                {handler.isEdit ? (
                  <Item
                    name="estimateArea"
                    rules={[
                      {
                        pattern: new RegExp(REGEX_NAME.regex),
                        message: REGEX_NAME.message,
                      },
                      // {
                      //   max: MAX_LENGTH_4,
                      //   message: "",
                      // },
                    ]}
                  >
                    <InputNumber
                      controls={false}
                      autoComplete="off"
                      size="large"
                      style={{ width: "57%" }}
                      min={0}
                      max={9999999999}
                      formatter={helpers.formatInputNumber}
                      parser={helpers.parserInputNumber}
                      addonAfter={
                        <span className="cr003-icon-input">円 / m²</span>
                      }
                    />
                  </Item>
                ) : (
                  `${
                    handler.basicInfo?.estimateArea ||
                    handler.basicInfo?.estimateArea === 0
                      ? Number(handler.basicInfo?.estimateArea)?.toLocaleString(
                          "en-US",
                          {
                            maximumFractionDigits: 2,
                          }
                        )
                      : ""
                  } 円 / m²`
                )}
              </span>
            </div>
          </div>
        </div>
        <div className="ci001-contact">
          <div className="ci001-contact-header">
            <span>建設時間</span>
            <div className="ci001-contact-header-divider"></div>
          </div>
          <div className={`ci001-item ${handler.isEdit && "ci001-item-edit"}`}>
            <div className="ci001-item-left">
              <span className="ci001-item-label">全体工事期間</span>
              <span className="ci001-item-text">
                {handler.isEdit ? (
                  <Item name="constructionSummaryDate">
                    <RangePicker
                      disabled={parentId !== null}
                      format={COMMON.FORMAT_DATE_CI}
                      className="ci001-date-picker"
                      placeholder={["", ""]}
                      inputReadOnly
                      locale={locale}
                      suffixIcon={
                        <img
                          alt=""
                          src={CalendarSvg}
                          className="icon-calender"
                        />
                      }
                      separator={<span className="calender-separator">-</span>}
                      getPopupContainer={(trigger: any) => trigger.parentNode}
                    />
                  </Item>
                ) : handler.basicInfo?.constructionSummaryFromDate &&
                  handler.basicInfo?.constructionSummaryFromDate !== "" &&
                  handler.basicInfo?.constructionSummaryToDate &&
                  handler.basicInfo?.constructionSummaryToDate !== "" ? (
                  `${moment(
                    handler.basicInfo?.constructionSummaryFromDate
                  ).format(COMMON.FORMAT_DATE_CI)} - ${moment(
                    handler.basicInfo?.constructionSummaryToDate
                  ).format(COMMON.FORMAT_DATE_CI)}`
                ) : (
                  ""
                )}
              </span>
            </div>
            <div className="ci001-item-right">
              <span className="ci001-item-label">契約工事期間</span>
              <span className="ci001-item-text">
                {handler.isEdit ? (
                  <Item name="startEndDate">
                    <RangePicker
                      // disabled={parentId !== null}
                      // defaultValue={[moment('2015/01/01', dateFormat), moment('2015/01/01', dateFormat)]}
                      format={COMMON.FORMAT_DATE_CI}
                      className="ci001-date-picker cr003-date-picker"
                      placeholder={["", ""]}
                      getPopupContainer={(trigger: any) => trigger.parentNode}
                      inputReadOnly
                      locale={locale}
                      separator={<span className="calender-separator">-</span>}
                      suffixIcon={
                        <img
                          alt=""
                          src={CalendarSvg}
                          className="icon-calender"
                        />
                      }
                    />
                  </Item>
                ) : handler.basicInfo?.startDate &&
                  handler.basicInfo?.startDate !== "" &&
                  handler.basicInfo?.endDate &&
                  handler.basicInfo?.endDate !== "" ? (
                  `${moment(handler.basicInfo?.startDate).format(
                    COMMON.FORMAT_DATE_CI
                  )} - ${moment(handler.basicInfo?.endDate).format(
                    COMMON.FORMAT_DATE_CI
                  )}`
                ) : (
                  ""
                )}
              </span>
            </div>
          </div>
          <div className="ci001-contact-header">
            <span>追加情報</span>
            <div className="ci001-contact-header-divider"></div>
          </div>
          <div className={`ci001-item ${handler.isEdit && "ci001-item-edit"}`}>
            <div className="ci001-item-left">
              <span className="ci001-item-label">見積条件書</span>
              <span className="ci001-item-text">
                {handler.isEdit ? (
                  <Item name="requireEstimation">
                    <Select
                      className="ci001-select "
                      getPopupContainer={(trigger: any) => trigger.parentNode}
                      // onChange={}
                    >
                      {RESIDENT_PRESENCE.map(
                        (option: { name: string; value: string }) => (
                          <Select.Option
                            key={option.value}
                            value={option.value}
                          >
                            {option.name}
                          </Select.Option>
                        )
                      )}
                    </Select>
                  </Item>
                ) : handler.basicInfo?.requireEstimation === null ? (
                  ""
                ) : handler.basicInfo?.requireEstimation ? (
                  "有"
                ) : (
                  "無"
                )}
              </span>
            </div>
            <div className="ci001-item-right">
              <span className="ci001-item-label">支給品</span>
              <span className="ci001-item-text">
                {handler.isEdit ? (
                  <Item
                    name="requireItem"
                    rules={[
                      {
                        pattern: new RegExp(REGEX_NAME.regex),
                        message: REGEX_NAME.message,
                      },
                    ]}
                  >
                    <Select
                      className="ci001-select ci001-select-custom"
                      getPopupContainer={(trigger: any) => trigger.parentNode}
                      // onChange={}
                    >
                      {RESIDENT_PRESENCE.map(
                        (option: { name: string; value: string }) => (
                          <Select.Option
                            key={option.value}
                            value={option.value}
                          >
                            {option.name}
                          </Select.Option>
                        )
                      )}
                    </Select>
                  </Item>
                ) : handler.basicInfo?.requireItem === null ? (
                  ""
                ) : handler.basicInfo?.requireItem ? (
                  "有"
                ) : (
                  "無"
                )}
              </span>
            </div>
          </div>
          <div className={`ci001-item ${handler.isEdit && "ci001-item-edit"}`}>
            <div className="ci001-item-left">
              <span className="ci001-item-label">常駐有無</span>
              <span className="ci001-item-text">
                {handler.isEdit ? (
                  <Item name="requireDirect">
                    <Select
                      className="ci001-select "
                      getPopupContainer={(trigger: any) => trigger.parentNode}
                      // onChange={}
                    >
                      {RESIDENT_PRESENCE.map(
                        (option: { name: string; value: string }) => (
                          <Select.Option
                            key={option.value}
                            value={option.value}
                          >
                            {option.name}
                          </Select.Option>
                        )
                      )}
                    </Select>
                  </Item>
                ) : handler.basicInfo?.requireDirect === null ? (
                  ""
                ) : handler.basicInfo?.requireDirect ? (
                  "有"
                ) : (
                  "無"
                )}
              </span>
            </div>
          </div>
        </div>

        <div className={`ci001-item ${handler.isEdit && "ci001-item-edit"}`}>
          <div style={{ width: "100%", display: "flex", gap: 8 }}>
            <div className="label-upload">ファイル</div>

            <div className="ci001-item-text">
              {!handler.isEdit && handler.fileList.length == 0 ? (
                <></>
              ) : (
                <Card
                  bodyStyle={{
                    padding: "0 12px 12px 12px",
                    maxWidth: "1309px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    background: "#fafafa",
                  }}
                  bordered={true}
                  style={{ border: "1px dashed #DAE2EE" }}
                >
                  {handler.isEdit && (
                    <Dragger
                      customRequest={dummyRequest}
                      multiple={true}
                      fileList={[]}
                      onChange={handler.onChangeUpload}
                      onRemove={handler.onDropUpload}
                      iconRender={() => <img alt="" src={IconFile} />}
                      style={{ maxWidth: "1309px", border: "none" }}
                      disabled={handler.fileList.length >= 20}
                      accept="application/pdf, image/png, image/jpeg"
                    >
                      <div className="content-upload">
                        <p className="ant-upload-drag-icon">
                          <img alt="" src={CloudUpIcon} />
                        </p>
                        <p
                          className="ci-upload-text"
                          style={{ width: 424, textAlign: "center" }}
                        >
                          ここにファイルをドラッグ・ドロップ (.pdf または
                          .png/.jpeg/.jpg)
                        </p>
                        <div className="ci-upload-text">
                          （各ファイルサイズ：8MB）
                        </div>
                        <div className="text-gray">または</div>
                        <Button
                          className="btn-upload-custom"
                          style={{
                            borderRadius: "3px",
                            padding: "0 24px",
                            width: "fit-content",
                            border: "solid 1px #DAE2EE",
                            marginTop: 5,
                          }}
                          disabled={handler.fileList.length >= 20}
                        >
                          <span
                            style={{
                              fontSize: 14,
                              fontWeight: 700,
                              color: "#605BFF",
                            }}
                          >
                            ファイルを選択する
                          </span>
                          <span
                            style={{
                              fontSize: 14,
                              fontWeight: 400,
                              color: "#605BFF",
                            }}
                          >
                            (.pdf または .png/.jpeg/.jpg)
                          </span>
                        </Button>
                      </div>
                    </Dragger>
                  )}
                  {handler.fileList.length > 0 && (
                    <div style={{ width: "100%", padding: "0 16px" }}>
                      <List
                        style={{
                          marginTop: 16,
                          maxWidth: "100%",
                          fontSize: 14,
                          fontWeight: 700,
                          color: "#605BFF",
                          border: "1px solid #DAE2EE",
                          maxHeight: 250,
                          overflowY: "auto",
                        }}
                        bordered
                        dataSource={handler.fileList}
                        renderItem={(item: any) => (
                          <List.Item
                            style={{
                              color: "#605BFF",
                              maxWidth: "1309px",
                              overflowX: "hidden",
                            }}
                          >
                            <div
                              style={{
                                cursor: "pointer",
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                onClick={() => handler.getPdfMap(item)}
                                style={{
                                  cursor: "pointer",
                                  width: handler.isEdit ? "97%" : "100%",
                                }}
                              >
                                <TooltipText isLarge content={item.name} />
                              </div>
                              {handler.isEdit && (
                                <img
                                  style={{ cursor: "pointer" }}
                                  src={IconDelete}
                                  onClick={() => handler.onDropUpload(item)}
                                />
                              )}
                            </div>
                          </List.Item>
                        )}
                      />
                    </div>
                  )}
                </Card>
              )}
            </div>
          </div>
        </div>
      </div>
      {handler.isEdit && (
        <div className="ci001-form-submit">
          <Item>
            <Button
              size="large"
              className="button-purple"
              htmlType="submit"
              style={{ marginRight: 16 }}
            >
              保存
            </Button>
          </Item>
          <Button size="large" onClick={handler.onCancelSubmitFormBasic}>
            キャンセル
          </Button>
        </div>
      )}
    </Form>
  );
}

export default CR003BasicInformation;
