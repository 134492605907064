import React from 'react';
import { Tabs } from 'antd';

type props = {
    onChange: (value: any) => void;
    tab: string;
};
const SubTabComponent = ({ onChange, tab }: props) => {
    const TAB_DATA: any[] = [
        {
            id: 1,
            value: '材料費',
        },
        {
            id: 2,
            value: '外注費',
        },
        {
            id: 3,
            value: '労務費',
        },
    ];

    return (
        <Tabs type="card" onChange={onChange} activeKey={tab} className="am028">
            {TAB_DATA.map((element) => (
                <Tabs.TabPane tab={element.value} key={element.id + ''} />
            ))}
        </Tabs>
    );
};
export default SubTabComponent;
