import PM009Api from "./infrastructure/api/Api";
import PM009Handler from "./presenter/handler/Handler";
import PM009Service from "./usecase/Service";
import PM009Drawer from "./presenter/ui/UI";

const PM009Component = () => {
  const pm009Api = PM009Api();

  const pm009Service = PM009Service(pm009Api);

  const pm009Handler = PM009Handler(pm009Service);

  return <PM009Drawer handler={pm009Handler} />;
};

export default PM009Component;
