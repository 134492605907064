import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SM04UseCase } from '../../usecase/ServiceImpl';
import { resetSM04, setIsVisibleSM04, setLoadingSM04 } from '../slice/Slice';
import MESSAGE, { LABEL_MESSAGE, NOTIFICATION_TITLE } from '../../../../../common/constants/MESSAGE';
import ErrorNotification from '../../../../../common/components/notification/ErrorNotification';
import { setIsVisibleSM06, setLoading, setScheduleIdSM06 } from '../../../SM06/presenter/slice/Slice';
import { setIsVisibleSM03, setScheduleIdSM03 } from '../../../SM03/presenter/slice/Slice';
import { setTypeModalSM03 } from '../../../SM03/presenter/slice/Slice';
import { DataView } from '../type/Presenter';
import COMMON from '../../../../../common/constants/COMMON';
import moment from 'moment';
import helpers from '../../../../../common/helpers/common';
import { Form } from 'antd';
import { RootState } from '../../../../../store';
import SuccessNotification from '../../../../../common/components/notification/SuccessNotification';
import { DataCommentType } from '../../entity/Entity';
import ConfirmModal from '../../../../../common/components/modal/ConfirmModal';
import { refreshPage } from '../../../SM01/presenter/slice/Slice';

const SM04Handler = (sm04Service: SM04UseCase) => {
    // LIB FUNCTION
    const [form] = Form.useForm()
    const [formEdit] = Form.useForm()
    const dispatch = useDispatch();
    const scheduleId = useSelector((state: RootState) => state.sm04.scheduleId) ?? 0;
    // STATE
    const [disabledBtn, setDisabledBtn] = useState<boolean>(true);
    const [dataView, setDataView] = useState<DataView>();
    const [listComment, setListComment] = useState<DataCommentType[]>([]);
    const [numpages, setNumPages] = useState<number>(1);
    const [page, setPage] = useState(COMMON.DEFAULT_PAGE);
    const [isRefresh, setIsRefresh] = useState<boolean>(false);
    const [editingKeys, setEditingKeys] = useState<any[]>([]);

    // FUNCTION

    const edit = (record: any) => {
        setEditingKeys([...editingKeys, record.id]);
        formEdit.setFields([{
            name: `commentUpdate${record.id}`,
            value: record.content
        }])
    };
    const isEditing = (record: any): boolean =>
        editingKeys.some((element: any) => element === record.id);

    const getDetailSchedule = async (scheduleId: number) => {
        try {
            dispatch(setLoadingSM04(true));
            const params = {
                scheduleId,
            };
            const res = await sm04Service.getDetailSchedule(params);
            if (res.results) formatDataView(res.results);
        } catch (e: any) {
            ErrorNotification(e?.message ?? NOTIFICATION_TITLE.ERROR);
        } finally {
            dispatch(setLoadingSM04(false));
        }
    };

    const getListComment = async (params: { page: number; size: number; sortBy: string }) => {
        try {
            setLoading(true);
            const response: any = await sm04Service.getListComment(params, scheduleId);
            setNumPages(response.pagination.numPages);
            if (response?.results && response?.results?.length > 0) {
                if (params.page === 1) {
                    setListComment(response.results);
                } else {
                    setListComment([...listComment, ...response.results]);
                }
            }
        } finally {
            setLoading(false);
        }
    };

    const formatDataView = (data: any) => {
        const newData: DataView = {
            id: data?.id,
            name: data?.name,
            note: data?.note,
            date:
                moment(data?.startTime).format(COMMON.FORMAT_DATE_CI) ===
                    moment(data?.endTime).format(COMMON.FORMAT_DATE_CI)
                    ? moment(data?.startTime).format(COMMON.FORMAT_DATE_CI)
                    : `${moment(data?.startTime).format(COMMON.FORMAT_DATE_CI)} - ${moment(
                        data?.endTime,
                    ).format(COMMON.FORMAT_DATE_CI)}`,
            time: !data?.isFullDay
                ? `${moment(data?.startTime).format(COMMON.FORMAT_TIME1)} - ${moment(
                    data?.endTime,
                ).format(COMMON.FORMAT_TIME1)}`
                : '終日',
            listMember: data?.listMember,
            project: data?.project,
            construction: data?.construction,
            notification: data?.notification,
            problem: data?.problem,
            category: data?.category,
            owner: data?.owner
        };
        setDataView(newData);
        const username = helpers.getLocalUsername();
        if (data?.owner?.username === username) setDisabledBtn(false);
    };

    const handleOpenModalConfirmDelete = (scheduleId: number) => {
        dispatch(setIsVisibleSM06(true));
        dispatch(setScheduleIdSM06(scheduleId));
    };

    const handleOpenModalEdit = (scheduleId: number) => {
        // dispatch modal edit
        dispatch(setIsVisibleSM03(true));
        dispatch(setTypeModalSM03(true));
        dispatch(setScheduleIdSM03(scheduleId));
    };

    const onCancel = () => {
        dispatch(setIsVisibleSM04(false));
    };

    const sendComment = async (value: any) => {

        const contentComment = value.newComment
        try {
            dispatch(setLoading(true));
            const response = await sm04Service.postComment({ content: contentComment ?? "" }, scheduleId)
            form.resetFields(["newComment"])
            setListComment([])
            setPage(1)
            setIsRefresh(!isRefresh)
            dispatch(refreshPage());
            SuccessNotification(
                response?.message ?? NOTIFICATION_TITLE.SUCCESS
            );
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        } finally {
            dispatch(setLoading(false));
        }
    }

    const editComment = async (value: any, commentId?: number) => {

        const contentComment = value[`commentUpdate${commentId}`]

        try {
            dispatch(setLoading(true));
            const response = await sm04Service.putEditComment({ content: contentComment }, scheduleId, commentId ?? 0)
            formEdit.resetFields([value[`commentUpdate${commentId}`]])

            const result = editingKeys.filter((item: any) => item != commentId)
            setEditingKeys(result)

            setListComment([])
            setPage(1)
            setIsRefresh(!isRefresh)
            SuccessNotification(
                response?.message ?? NOTIFICATION_TITLE.SUCCESS
            );
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        } finally {
            dispatch(setLoading(false));
        }
    }

    const handlerScrollInfinity = () => {

        if (numpages > page) {
            setPage((prev) => prev + 1);
        }
    };

    const cancelEdit = (commentId: number) => {
        const result = editingKeys.filter((item: any) => item != commentId)
        setEditingKeys(result)
    }

    const deleteComment = (commentId: number) => {
        ConfirmModal({
            onOk: async () => {
                dispatch(setLoading(true));
                try {
                    const response = await sm04Service.deleteComment(scheduleId, commentId ?? 0);
                    SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
                    setListComment([])
                    setPage(1)
                    dispatch(refreshPage());
                    setIsRefresh(!isRefresh)
                } catch (error: any) {
                    ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
                } finally {
                    dispatch(setLoading(false));

                }
            },
            className: 'confirm__modal',
            title: MESSAGE.MESSAGE_MODAL_DELETE_TITLE,
            description: MESSAGE.MESSAGE_DELETE_MANY_DES_1,
            extraDescription: MESSAGE.MESSAGE_DESCRIPTION_SM04,
            canceText: LABEL_MESSAGE.CANCEL_MODAL,
            okText: LABEL_MESSAGE.OK_DELETE,
            isCenterWithoutMenu: true,
        });
    }

    return {
        dataView,
        disabledBtn,
        form,
        formEdit,
        listComment,
        page,
        isRefresh,
        isEditing,
        edit,
        getDetailSchedule,
        onCancel,
        handleOpenModalConfirmDelete,
        handleOpenModalEdit,
        sendComment,
        getListComment,
        handlerScrollInfinity,
        cancelEdit,
        editComment,
        deleteComment
    };
};

export default SM04Handler;
