import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { AM010Handler } from "../handler/HandlerImpl";
import AM010HeaderTimerNoProject from "./AM010HeaderTimerNoProject";
import AM010HeaderTimerProject from "./AM010HeaderTimerProject";

type props = {
  handler: AM010Handler;
};

export default function AM010HeaderTimer({ handler }: props) {
  const isUserInWorkspace = useSelector((state: RootState) => state.auth.authData?.isUserInWorkspace);

  return (
    <div className="am010__header-timer">
      {isUserInWorkspace ? (
        <AM010HeaderTimerProject handler={handler} />
      ) : (
        <AM010HeaderTimerNoProject handler={handler} />
      )}
    </div>
  );
}
