import helpers from "../../../../common/helpers/common";
import { ASChangePasswordApi } from "./ServiceImpl";

const ASChangePasswordService = (asChangePasswordApi: ASChangePasswordApi) => {
  const changePassword = async (bodyData: any): Promise<any> => {
    try {
      const response = await asChangePasswordApi.changePassword(bodyData);
      // save local storage hear
      if (response) {
        const user = helpers.getObjectFromLocalStorage("user");
        user.isFirstLogin = false;
        localStorage.setItem("user", JSON.stringify(response));
        return response;
      }
      // save local storage hear
    } catch (error) {
      throw error;
    }
  };
  return { changePassword };
};

export default ASChangePasswordService;
