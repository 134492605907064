import { createSlice } from "@reduxjs/toolkit";
import { CCUS062SliceType } from "../../entity/Entity";

export const initialState: CCUS062SliceType = {
  isRefresh: false,
  shouldFetch: false,
  isOpenModalCcus061: false,
  userId: 0
};

export const CCUS062Slice = createSlice({
  name: "ccus062",
  initialState,
  reducers: {
    refreshCCUS062: (state) => {
      state.isRefresh = !state.isRefresh;
    },
    setShouldFetch: (state, action) => {
      state.shouldFetch = action.payload;
    },

    setOpenModalCcus061: (state, action) => {
      state.isOpenModalCcus061 = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
  },
});

export const { refreshCCUS062, setShouldFetch, setOpenModalCcus061, setUserId } = CCUS062Slice.actions;

export default CCUS062Slice.reducer;

