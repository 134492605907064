import CR002Api from "./infrastructure/api/Api";
import CR002Service from "./usecase/Service";
import CR002Handler from "./presenter/handler/Handler";
import CR002UI from "./presenter/ui/UI";

interface props {
  handleConfirm?: () => void;
  handleCancel?: () => void;
}

const CR002Component = (props: props) => {
  const cr002Api = CR002Api();
  const cr002Service = CR002Service(cr002Api);
  const cr002Handler = CR002Handler(cr002Service);
  return <CR002UI handler={cr002Handler} {...props} />;
};

export default CR002Component;
