import { createSlice } from "@reduxjs/toolkit";
import { CM0017_3SliceType } from "../../entity/Entity";

export const initialState: CM0017_3SliceType = {
  isRefresh: false,
  documentId: null,
  enableRetrieveButton: false,
  hideRetrieveButton: false,
  documentInfo: {
    documentTitle: "",
    createdDate: "",
    targetPeriod: "",
    paymentMonth: "",
    from: "",
    to: "",
  },
};

export const CM0017_3Slice = createSlice({
  name: "CM0017_3",
  initialState,
  reducers: {
    resetCM0017_3: () => initialState,
    refreshCM0017_3: (state) => {
      state.isRefresh = !state.isRefresh;
    },
    setDocumentIdCM0017_3: (state, action) => {
      state.documentId = action.payload;
    },
    setDocumentInfoCM0017_3: (state, action) => {
      state.documentInfo = action.payload;
    },
    setEnableRetrieveButton: (state, action) => {
      state.enableRetrieveButton = action.payload;
    },
    setHideRetrieveButton: (state, action) => {
      state.hideRetrieveButton = action.payload;
    },
  },
});

export const {
  resetCM0017_3,
  refreshCM0017_3,
  setDocumentIdCM0017_3,
  setDocumentInfoCM0017_3,
  setEnableRetrieveButton,
  setHideRetrieveButton,
} = CM0017_3Slice.actions;

export default CM0017_3Slice.reducer;
