import React from 'react';
import Handler from './presenter/handler/Handler';
import UI from './presenter/ui/UI';

import '../../../assets/styles/CM/cm004.css';

export const DEFAULT_THRESHOLD = 70

type props = {
    initialData: any;
    update: (data: any) => Promise<void>;
};

const ThresholdPopoverComponent = ({ initialData, update }: props) => {
    const handler = Handler({ update: update });
    return <UI initialData={initialData} handler={handler} />;
};

export default ThresholdPopoverComponent;
