import axiosConfig from "../../../../../config/axiosConfig";
import { RetrieveIndirectDocument } from "../../entity/Entity";

const CM0016Api = () => {
  const getListSubmittedIndirectCost = async (params: any): Promise<any> => {
    try {
      const url = "/re/submitted/indirect-cost/list";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getPresignLinkS3 = async (params: any): Promise<any> => {
    const url = "/project/presign-link";
    return await axiosConfig.get(url, { params });
  };

  const getProjectSummaryReceivedMoney = async (params: any): Promise<any> => {
    const url = "/money/project/summary/received-money";
    return await axiosConfig.get(url, { params });
  };

  const getConstructionSummaryCostInformation = async (
    params: any
  ): Promise<any> => {
    const url = "/money/summary/construction/cost/information";
    return await axiosConfig.get(url, { params });
  };

  const getMoneyIndirectCost = async (params: any): Promise<any> => {
    const url = "/money/indirect-cost/list";
    return await axiosConfig.get(url, { params });
  };

  const retrieveIndirectDocument = async (
    data: RetrieveIndirectDocument
  ): Promise<any> => {
    const url = "/money/retrieve/indirect-cost";
    return await axiosConfig.put(url, data);
  };
  const getWorkflowIndirectApprovalProcess = async (params: {
    documentId: number;
  }): Promise<any> => {
    const url = "/workflow/indirect-approval-process";
    return await axiosConfig.get(url, { params });
  };
  return {
    getListSubmittedIndirectCost,
    getPresignLinkS3,
    getProjectSummaryReceivedMoney,
    getConstructionSummaryCostInformation,
    getMoneyIndirectCost,
    retrieveIndirectDocument,
    getWorkflowIndirectApprovalProcess,
  };
};

export default CM0016Api;
