import { AM040APIImpl } from './ServiceImpl';
import { UserTimeSheet } from '../../AM040/entity/Entity';
import COMMON from '../../../../common/constants/COMMON';

const Service = (api: AM040APIImpl) => {
    const getAttendanceUserTimeSheet = async (param: {
        date: string;
        constructionId?: string;
        contractorConstructionId?: string;
        userId: string;
    }): Promise<UserTimeSheet[]> => {
        try {
            const response = await api.getAttendanceUserTimeSheet({
                ...param,
                timeOffset: COMMON.TIME_ZONE,
            });
            const { results } = response.data;
            return results || [];
        } catch (error: any) {
            return [];
        }
    };

    return {
        getAttendanceUserTimeSheet,
    };
};

export default Service;
