import COMMON from '../../../../../common/constants/COMMON';
import { DetailMemberEntity } from '../../../../OM/OM006/entity/Entity';
import LightCamera from '../../../../../assets/images/demo.svg';
import moment from 'moment';
import helpers from '../../../../../common/helpers/common';
import { OrderDetail } from '../../entity/Entity';

type props = {
    customer: any;
    orderDetail?: OrderDetail;
};

const CustomerDetailComponent = ({ customer, orderDetail }: props) => {
    return (
        <>
            <main className="ord006-container" style={{ borderRadius: 3 }}>
                <div style={{ width: 540 }}>
                    <div className="ord006-content-container">
                        <p className="ord006-label">会社名</p>
                        <p className="ord006-content">{orderDetail?.companyName ?? ''}</p>
                    </div>
                    <div className="ord006-content-container">
                        <p className="ord006-label">郵便番号</p>
                        <p className="ord006-content">〒{orderDetail?.postNumber ?? ''}</p>
                    </div>
                    <div className="ord006-content-container">
                        <p className="ord006-label">住所</p>
                        <p className="ord006-content">{orderDetail?.orderAddress ?? ''}</p>
                    </div>
                    <div className="ord006-content-container">
                        <p className="ord006-label">電話番号</p>
                        <p className="ord006-content">{orderDetail?.phoneNumber ?? ''}</p>
                    </div>
                    <div className="ord006-content-container">
                        <p className="ord006-label">FAX</p>
                        <p className="ord006-content">{orderDetail?.fax ?? ''}</p>
                    </div>
                </div>
                <div>
                    <div className="ord006-content-container">
                        <p className="ord006-label">ID</p>
                        <p className="ord006-content">{orderDetail?.adminName ?? ''}</p>
                    </div>
                    <div className="ord006-content-container">
                        <p className="ord006-label">アドミン名</p>
                        <p className="ord006-content">{orderDetail?.adminFullName ?? ''}</p>
                    </div>
                    <div className="ord006-content-container">
                        <p className="ord006-label">メールアドレス</p>
                        <p className="ord006-content">{orderDetail?.companyEmail ?? ''}</p>
                    </div>
                    <div className="ord006-content-container">
                        <p className="ord006-label">ユーザー数</p>
                        <p className="ord006-content">{orderDetail?.planPerson.userNumber ?? 0}</p>
                    </div>
                    <div className="ord006-content-container">
                        <p className="ord006-label"></p>
                        <p className="ord006-content">
                            {orderDetail?.userNumber ?? 0}{' '}
                            <span style={{ color: '#0FA44A', marginRight: 24 }}>(発行済数)</span>
                            {(orderDetail?.planPerson.userNumber ?? 0) -
                                (orderDetail?.userNumber ?? 0)}{' '}
                            <span
                                style={{
                                    color: '#FF0909',
                                }}
                            >
                                (残発行数)
                            </span>
                        </p>
                    </div>
                </div>
            </main>
        </>
    );
};

export default CustomerDetailComponent;
