import React, { useMemo } from "react";
import { HandlerImpl } from "../handler/HandlerImpl";
import IconDown from "../../../../../assets/icons/icon-collapse-cm.svg";
import IconView from "../../../../../assets/icons/eyeIconBlack.svg";
import IconExtend from "../../../../../assets/icons/extend-purple.svg";
import IconNarrow from "../../../../../assets/icons/narrow-purple.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import helpers from "../../../../../common/helpers/common";
import { Empty } from "antd";

interface props {
  handler: HandlerImpl;
}

const NO_DATA = "データなし";

const Table = ({ handler }: props) => {
  const checkNarrow = useMemo(() => {
    if (handler.visibleCM0027) return handler.isNarrowModal;
    return handler.isNarrow;
  }, [handler.isNarrow, handler.isNarrowModal, handler.visibleCM0027]);

  const contentTable = useMemo(() => {
    if (!handler.dataView || handler.dataView.length === 0)
      return (
        <tr>
          <td colSpan={16}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={NO_DATA} />
          </td>
        </tr>
      );
    const elementTable: any = [];
    handler.dataView.forEach((element: any) => {
      elementTable.push(
        <React.Fragment key={element.key}>
          <tr style={{ height: 4 }}></tr>
          <tr
            className="row-level-0"
            onClick={() => handler.funcSetExpendKey(element.key)}
          >
            <td colSpan={2}>{element?.no}</td>
            <td>{element.projectName}</td>
            <td></td>
            <td></td>
            <td>{helpers.formatCurrency(element.recordedAmount)}円</td>
            {checkNarrow ? (
              <>
                <td>{helpers.formatCurrency(element.taxAtion1)}円</td>
                <td>{helpers.formatCurrency(element.taxExempt1)}円</td>
                <td>{helpers.formatCurrency(element.taxAtion2)}円</td>
                <td>{helpers.formatCurrency(element.taxExempt2)}円</td>
              </>
            ) : null}
            <td>{helpers.formatCurrency(element.offsetAmount)}円</td>
            <td>{helpers.formatCurrency(element.excludingTax)}円</td>
            <td>{helpers.formatCurrency(element.consumptionTax)}円</td>
            <td>{helpers.formatCurrency(element.taxInclude)}円</td>
            <td>
              <img
                alt=""
                src={IconDown}
                className={`cm024-icon ${handler.funcCheckKeyExpand(element.key)
                  ? ""
                  : "cm024-icon-active"
                  }`}
                onClick={() => handler.funcSetExpendKey(element.key)}
              />
            </td>
          </tr>
        </React.Fragment>
      );

      if (element.children && element.children.length > 0) {
        element.children.forEach((child1: any) => {
          elementTable.push(
            <tr
              key={child1.key}
              className="bg-fa"
              style={{
                display: handler.funcCheckKeyExpand(element.key)
                  ? undefined
                  : "none",
                cursor: "pointer",
              }}
              onClick={() => handler.funcSetExpendKey(child1.key)}
            >
              <td colSpan={2}>{child1.no}</td>
              <td></td>
              <td>
                {helpers.getShortTextWithToolTip(child1.constructionName, 20)}
              </td>
              <td>
                {helpers.getShortTextWithToolTip(child1.contractWork, 20)}
              </td>
              <td>{helpers.formatCurrency(child1.recordedAmount)}円</td>
              {checkNarrow ? (
                <>
                  <td>{helpers.formatCurrency(child1.taxAtion1)}円</td>
                  <td>{helpers.formatCurrency(child1.taxExempt1)}円</td>
                  <td>{helpers.formatCurrency(child1.taxAtion2)}円</td>
                  <td>{helpers.formatCurrency(child1.taxExempt2)}円</td>
                </>
              ) : null}
              <td>{helpers.formatCurrency(child1.offsetAmount)}円</td>
              <td>{helpers.formatCurrency(child1.excludingTax)}円</td>
              <td>{helpers.formatCurrency(child1.consumptionTax)}円</td>
              <td>{helpers.formatCurrency(child1.taxInclude)}円</td>
              <td>
                <img
                  alt=""
                  src={IconDown}
                  className={`cm024-icon ${handler.funcCheckKeyExpand(child1.key)
                    ? ""
                    : "cm024-icon-active"
                    }`}
                  onClick={() => handler.funcSetExpendKey(child1.key)}
                />
              </td>
            </tr>
          );
          elementTable.push(
            <tr
              key={`table${child1.key}`}
              className="bg-fa"
              style={{
                display:
                  handler.funcCheckKeyExpand(element.key) &&
                    handler.funcCheckKeyExpand(child1.key)
                    ? undefined
                    : "none",
              }}
            >
              <td colSpan={2}></td>
              <td>業者名</td>
              <td>対象期間</td>
              <td>支払日</td>
              <td></td>
              {checkNarrow ? (
                <>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </>
              ) : null}
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          );
          if (child1.children && child1.children.length > 0) {
            child1.children.forEach((child2: any) => {
              elementTable.push(
                <tr
                  key={child2.key}
                  style={{
                    display:
                      handler.funcCheckKeyExpand(element.key) &&
                        handler.funcCheckKeyExpand(child1.key)
                        ? undefined
                        : "none",
                  }}
                >
                  <td style={{ width: 50 }}></td>
                  <td style={{ width: 50 }}>{child2.no}</td>
                  <td>
                    {child2.traderName}
                  </td>
                  <td>{child2.targetPeriod}</td>
                  <td>{child2.datePayment}</td>
                  <td>{helpers.formatCurrency(child2.recordedAmount)}円</td>
                  {checkNarrow ? (
                    <>
                      <td>
                        {helpers.formatCurrency(child2.taxAtion1)}円{" "}
                        <img
                          className="icon-view"
                          alt=""
                          src={IconView}
                          onClick={() =>
                            handler.handleViewModalCM0011(
                              child2.id,
                              child2.traderName,
                              child1.contractWork,
                              child2.targetPeriod,
                              child2.taxId1
                            )
                          }
                        />
                      </td>
                      <td>
                        {helpers.formatCurrency(child2.taxExempt1)}円{" "}
                        <img
                          className="icon-view"
                          alt=""
                          src={IconView}
                          onClick={() =>
                            handler.handleViewModalCM0011(
                              child2.id,
                              child2.traderName,
                              child1.contractWork,
                              child2.targetPeriod,
                              child2.taxId2
                            )
                          }
                        />
                      </td>
                      <td>
                        {helpers.formatCurrency(child2.taxAtion2)}円{" "}
                        <img
                          className="icon-view"
                          alt=""
                          src={IconView}
                          onClick={() =>
                            handler.openCM0017_2(
                              {
                                companyId: child2.companyId,
                                categoryId: child2.taxId3,
                                colabRefId: child2.colabRefId,
                              }
                            )
                          }
                        />
                      </td>
                      <td>
                        {helpers.formatCurrency(child2.taxExempt2)}円{" "}
                        <img
                          className="icon-view"
                          alt=""
                          src={IconView}
                          onClick={() =>
                            handler.openCM0017_2(
                              {
                                companyId: child2.companyId,
                                categoryId: child2.taxId4,
                                colabRefId: child2.colabRefId,
                              }
                            )
                          }
                        />
                      </td>
                    </>
                  ) : null}
                  <td>{helpers.formatCurrency(child2.offsetAmount)}円</td>
                  <td>{helpers.formatCurrency(child2.excludingTax)}円</td>
                  <td>{helpers.formatCurrency(child2.consumptionTax)}円</td>
                  <td>{helpers.formatCurrency(child2.taxInclude)}円</td>
                  <td></td>
                </tr>
              );
            });
          } else {
            elementTable.push(
              <tr
                key={`table${child1.key}nodata`}
                style={{
                  display:
                    handler.funcCheckKeyExpand(element.key) &&
                      handler.funcCheckKeyExpand(child1.key)
                      ? undefined
                      : "none",
                }}
              >
                <td colSpan={16}>
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={NO_DATA}
                  />
                </td>
              </tr>
            );
          }
        });
      } else {
        elementTable.push(
          <tr
            key={`${element.key}nodata`}
            style={{
              display: handler.funcCheckKeyExpand(element.key)
                ? undefined
                : "none",
            }}
          >
            <td colSpan={16}>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={NO_DATA}
              />
            </td>
          </tr>
        );
      }
    });
    return elementTable;
  }, [
    `${JSON.stringify(handler.dataView)}`,
    `${JSON.stringify(handler.expandKey)}`,
    checkNarrow,
  ]);
  return (
    <div
      className="cm024-table"
      style={{ overflowY: "hidden" }}
      key={`${handler.render}`}
    >
      <InfiniteScroll
        dataLength={handler.dataView.length}
        hasMore={true}
        next={handler.handlerScrollInfinitte}
        height={"60vh"}
        loader={null}
      >
        <table>
          <thead>
            <tr>
              <th colSpan={2} rowSpan={2} style={{ width: 100 }}>
                No
              </th>
              <th rowSpan={2}>案件名</th>
              <th rowSpan={2}>工事名</th>
              <th rowSpan={2}>契約工事名</th>
              <th rowSpan={2}>計上金額</th>
              {checkNarrow ? (
                <>
                  <th colSpan={2} className="td-border-top">
                    案件別相殺金額
                  </th>
                  <th colSpan={2} className="td-border-top">
                    業者別相殺金額
                  </th>
                </>
              ) : null}
              <th rowSpan={2} className="td-border-top">
                <div className="th-narrow">
                  相殺合計金額
                  {checkNarrow ? (
                    <img
                      src={IconExtend}
                      className="icon-narrow"
                      onClick={handler.handleCheckNarrow}
                      alt=""
                    />
                  ) : (
                    <img
                      src={IconNarrow}
                      className="icon-narrow"
                      onClick={handler.handleCheckNarrow}
                      alt=""
                    />
                  )}
                </div>
              </th>
              <th rowSpan={2}>支払金額(税抜)</th>
              <th rowSpan={2}>消費税</th>
              <th rowSpan={2}>支払金額(税込)</th>
              <th rowSpan={2} style={{ width: 60 }}></th>
            </tr>
            {checkNarrow ? (
              <tr className="tr-border-first">
                <th>課税</th>
                <th>非課税</th>
                <th>課税</th>
                <th>非課税</th>
              </tr>
            ) : null}
          </thead>
          <tbody>{contentTable}</tbody>
          <tfoot>
            <tr className="table-foot">
              <td colSpan={2}>合計金額</td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                {helpers.formatCurrency(handler.footer?.recordedAmount ?? 0)}円
              </td>
              {checkNarrow ? (
                <>
                  <td>
                    {helpers.formatCurrency(handler.footer?.taxAtion1 ?? 0)}円
                  </td>
                  <td>
                    {helpers.formatCurrency(handler.footer?.taxExempt1 ?? 0)}円
                  </td>
                  <td>
                    {helpers.formatCurrency(handler.footer?.taxAtion2 ?? 0)}円
                  </td>
                  <td>
                    {helpers.formatCurrency(handler.footer?.taxExempt2 ?? 0)}円
                  </td>
                </>
              ) : null}
              <td>
                {helpers.formatCurrency(handler.footer?.offsetAmount ?? 0)}円
              </td>
              <td>
                {helpers.formatCurrency(handler.footer?.excludingTax ?? 0)}円
              </td>
              <td>
                {helpers.formatCurrency(handler.footer?.consumptionTax ?? 0)}円
              </td>
              <td>
                {helpers.formatCurrency(handler.footer?.taxInclude ?? 0)}円
              </td>
              <td></td>
            </tr>
          </tfoot>
        </table>
      </InfiniteScroll>
    </div>
  );
};

export default Table;
