import { Form, Select } from 'antd';
import IconDown from '../../../../../assets/icons/arrow-down-2-plain.svg';
import MESSAGE from '../../../../../common/constants/MESSAGE';
import { OrderStatus } from '../../entity/Entity';

const { Item } = Form;
const { Option } = Select;

interface props {
    statusOrder: OrderStatus[];
}

export const FreePlanFormItems = ({ statusOrder }: props) => {
    return (
        <div className="ord-form-item">
            <div className="ord-form-label">
                ステータス <span className="label-required">*</span>
            </div>
            <div className="ord-form-input">
                <Item
                    name="statusId"
                    rules={[
                        {
                            required: true,
                            message: MESSAGE.MESSAGE_REQUIRED,
                        },
                    ]}
                >
                    <Select
                        size="large"
                        suffixIcon={<img alt="" src={IconDown} />}
                        getPopupContainer={(trigger: any) => trigger.parentNode}
                    >
                        {statusOrder.map((item) => (
                            <Option key={item.id} value={item.code}>
                                {item.name}
                            </Option>
                        ))}
                    </Select>
                </Item>
            </div>
        </div>
    );
};
