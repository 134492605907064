import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CR003HandlerUI } from "../handler/HandlerImpl";
import { RootState } from "../../../../../store";
import CR003Body from "./CR003Body";
import MenuWrapperComponent from "../../../../../common/components/menu-wrapper";
import { useParams, useSearchParams } from "react-router-dom";

import "../../../../../assets/styles/CR/CR003.css";
import { resetStateCR002 } from "../../../CR002/presenter/slice/Slice";
import helpers from "../../../../../common/helpers/common";
import { TYPE_USER } from "../handler/Handler";
import ModalApprove from "../../../../../common/components/approval-flow/ModalApprove";
import MESSAGE from "../../../../../common/constants/MESSAGE";
import { CR003Header } from "./CR003Header";
import CR005Component from "../../../CR005";
import ImageViewerModalComponent from "../../../../../common/components/image-viewer-modal/ImageViewerComponent";
import PdfViewerModalComponent from "../../../../../common/components/pdf-viewer-modal/PdfViewerModalComponent";
import { PARAMS, SCREEN_ID } from "../CONSTANT";

interface props {
  handler: CR003HandlerUI;
}

const CR003UI = ({ handler }: props) => {
  const isRefreshCR003 = useSelector(
    (state: RootState) => state.cr003.isRefreshCR003
  );
  const visibleCR005 = useSelector((state: RootState) => state.cr005.isVisible);
  const { projectId, summaryId } = useParams();
  const dispatch = useDispatch();

  const fileName = useSelector((state: RootState) => state.cr003.fileName);
  const fileLink = useSelector((state: RootState) => state.cr003.fileLink);

  const [searchParams, setSearchParams] = useSearchParams();
  const versionType = searchParams.get(PARAMS.VERSION_TYPE) || "";
  const versionId = searchParams.get(PARAMS.VERSION_ID) || "";

  useEffect(() => {
    if (projectId) handler.getProjectForPermission(projectId);
  }, []);

  useEffect(() => {
    if (versionId) {
      (async () => {
        handler.getApprovalProcess({ versionId: versionId });
      })();
    } else {
      (async () => {
        handler.getApprovalProcess({ constructionId: summaryId });
      })();
    }
  }, [summaryId, handler.refreshApprove, versionId]);

  useEffect(() => {
    handler.resetScreen();
    dispatch(resetStateCR002());
  }, []);

  useEffect(() => {
    (async () => {
      await handler.getListRelateMoney({
        constructionId: summaryId,
        versionId: versionId,
      });
    })();
  }, [
    summaryId,
    handler.isRefreshAmount,
    handler.currentTab,
    handler.refreshApprove,
    isRefreshCR003,
  ]);

  useEffect(() => {
    handler.getCR003BasicInfo({ summaryId });
  }, [summaryId, handler.isRefresh, handler.currentTab]);

  useEffect(() => {
    handler.getScreenMaster({ screenId: SCREEN_ID });
  }, [handler.isRefresh]);

  useEffect(() => {
    if (handler.isEdit && handler.isChangeEdit) {
      window.addEventListener("beforeunload", helpers.unloadCallback);
    }
    return () =>
      window.removeEventListener("beforeunload", helpers.unloadCallback);
  }, [handler.isEdit, handler.isChangeEdit]);

  return (
    <MenuWrapperComponent
      chosenKey={81}
      openKey={8}
      onClickMenu={handler.onClickMenu}
    >
      <CR003Header handler={handler} />
      <CR003Body handler={handler} />
      <ModalApprove
        isApproveOrReject={handler.isApproveOrReject}
        visible={handler.isApprove}
        handleCancel={handler.handleCancelApprove}
        handleOk={(note) => handler.handleOkApprove(summaryId, note)}
        form={handler.formReject}
        // description={
        //   handler.checkAutApprove(
        //     handler.currentUserApproval,
        //     handler.listApprovalProcess
        //   ) === TYPE_USER.owner_submit
        //     ? MESSAGE.MESSAGE_DESCRIPTION_CR_CONFIRM
        //     : ""
        // }
        description={handler.descriptionPopup}
        title={handler.titlePopup}
      />
      {visibleCR005 ? <CR005Component /> : <></>}
      <ImageViewerModalComponent
        imageURL={
          handler.currentTab == "1"
            ? handler.pdfUrl
            : handler.pdfUrlAmountRelate
        }
        title={fileName}
      />
      <PdfViewerModalComponent
        pdfURL={
          handler.currentTab == "1"
            ? handler.pdfUrl
            : handler.pdfUrlAmountRelate
        }
        title={fileName}
      />
    </MenuWrapperComponent>
  );
};

export default CR003UI;
