import { PM011Handler } from "../handler/HandlerImpl";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../store";

import "../../../../../../../assets/styles/PM/PM011.css";

import React, { useEffect } from "react";
import CollapseContractorComponent from "../../../sub-construction";
import MyCompanyComponent from "./MyCompany";
import MyConstructionComponent from "./MyConstruction";

interface props {
  handler: PM011Handler;
  company: any;
  construction: any;
}
const UI = ({ handler, company, construction }: props) => {
  const {
    form,
    subConstructions,
    chosenMyCompanyMembers,
    getMyListMember,
    setInitialTreeView,
    setAllCheckBox,
    onPreview,
  } = handler;
  const eventCheckAll = useSelector(
    (state: RootState) => state.pm011.eventCheckAll
  );
  const isOpenPM011 = useSelector(
    (state: RootState) => state.pm011.isOpenPM011
  );
  const isCheckAll = useSelector((state: RootState) => state.pm011.isCheckAll);
  const isUpdating = useSelector((state: RootState) => state.pm011.isUpdating);

  useEffect(() => {
    (async () => {
      if (construction.id) {
        await setInitialTreeView(construction.id);
        await getMyListMember(construction.id);
      }
    })();
  }, [JSON.stringify(construction.id)]);

  useEffect(() => {
    setAllCheckBox(!isCheckAll);
  }, [eventCheckAll, isOpenPM011]);

  useEffect(() => {
    if (!isUpdating) {
      onPreview(`construction${company.constructionId + ""}`, {
        listUserId: chosenMyCompanyMembers,
        constructionId: construction.id,
      });
    }
  }, [isUpdating]);

  return (
    <>
      <MyConstructionComponent handler={handler} construction={construction} />
      <MyCompanyComponent handler={handler} company={company} />
      {subConstructions.map((element: any) => (
        <React.Fragment key={element.id + "" + element.level}>
          <CollapseContractorComponent
            form={form}
            subconstruction={element}
            construction={construction}
            parentSubConstructionId={element.parentSubConstructionId}
          />
        </React.Fragment>
      ))}
    </>
  );
};

export default UI;
