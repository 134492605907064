import { Row, Col, } from "antd";
import BreadcrumbComponent from "../../../../../common/components/breadcrumb/BreadcrumbComponent";
import BREADCRUMB from "../../../../../common/constants/BREADCRUMB";


const OM005HeaderComponent = () => {
  return (
    <>
      <Row>
        <Col span={24}>
          <BreadcrumbComponent data={BREADCRUMB.OM005} />
          <div
            className="header-row-title"
            style={{ marginTop: 8, marginBottom: 32 }}
          >
            一括メンバー追加
          </div>
        </Col>
      </Row>
    </>
  );
};

export default OM005HeaderComponent;
