// Built-in
import React, { useState } from "react";

// 3rd party lib
import moment from "moment";
import { Form } from "antd";
import { useDispatch } from "react-redux";
import { RootState } from "./../../../../../store";
import { useSelector } from "react-redux";

// Source files
import { setLoading } from "../../../../../common/slice/CommonSlice";
import { CollapsedTableServiceImpl } from "./../../usecase/ServiceImpl";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import ConfirmModalAsync from "../../../../../common/components/modal/ConfirmModalAsync";
import {
  MoneyDirectCostOutSourceCreatePayload,
  MoneyDirectCostOutsourceDeletePayload,
  MoneyDirectCostOutsourceListResult,
  MoneyDirectCostOutsourceOffsetListResultsData,
  MoneyDirectCostOutsourceUpdatePayload,
  OutsourceOffsetCost,
} from "../../entity/Entity";
import {
  editParentStatus,
  setCheckedCollapse,
  setEvidenceLink,
} from "../slice/Slice";
import ConfirmModal from "../../../../../common/components/modal/ConfirmModal";
import {
  deleteDataLocalCM0012,
  setHeaderDataCM0012,
  setIsVisibleCM0012,
  setParentCodeCM0012,
} from "../../../CM0012/presenter/slice/Slice";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import COMMON, {
  STATUS_CM_CHILD_TABLE,
  STATUS_CM_PARENT_TABLE,
} from "../../../../../common/constants/COMMON";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import { convertDataLocalToReceiveMoneyOffset } from "../../helper";
import { showImageViewerModal } from "../../../../../common/components/image-viewer-modal/ImageViewSlice";
import helpers from "../../../../../common/helpers/common";
import { showPdfViewerModal } from "../../../../../common/components/pdf-viewer-modal/pdfViewerModalSlice";
import { HeaderData } from "../../../CM0012/presenter/type/Presenter";
import { setDocumentView } from "../../../../../common/components/modal-approve-view/presenter/slice/Slice";
import { setDocumentEdit } from "../../../../../common/components/modal-approve-edit/presenter/slice/Slice";
import { setEvidenceName } from "../../../CM005/presenter/slice/Slice";

const SCREEN_NAME = "CM009";

const CollapsedTableHandler = (service: CollapsedTableServiceImpl) => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  // STATE
  const [parentId, setParentId] = useState<any>();
  const [parentData, setParentData] = useState<any>();
  const [data, setData] = useState<MoneyDirectCostOutsourceListResult[]>([]);
  const [originalData, setOriginalData] = useState<
    MoneyDirectCostOutsourceListResult[]
  >([]);
  const [editingKeys, setEditingKeys] = useState<any[]>([]);
  const [newLines, setNewLines] = useState<any[]>([]);
  const [newCount, setNewCount] = useState<number>(0);
  const [saveKeys, setSaveKeys] = useState<any[]>([]);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedList, setCheckedList] = useState<any[]>([]);
  // REDUX STATE
  const dataLocal = useSelector((state: RootState) => state.cm0012.dataLocal);

  // FUNCTION
  const isEnableSaveButton = (record: any): boolean =>
    saveKeys.some((element) => element === record.key + "");

  const checkEnableButtons = (changedValues: any, values: any) => {
    const recordChangedValue = changedValues[Object.keys(changedValues)[0]];
    const changedValue = recordChangedValue[Object.keys(recordChangedValue)[0]];
    const recordKeyName = Object.keys(changedValues)[0]?.replaceAll(
      "record",
      ""
    );
    if (changedValue !== undefined && changedValue !== null) {
      // find in saveKeys
      const foundKey = saveKeys.find((key: any) => {
        return recordKeyName === key;
      });
      if (foundKey) return;
      setSaveKeys([...saveKeys, recordKeyName]);
    } else {
      const changedValueObj = values[Object.keys(changedValues)[0]];
      const isAllUndefined = Object.values(changedValueObj).every(
        (x) => x === undefined || x === null
      );
      if (isAllUndefined) {
        const newSaveKeys = saveKeys.filter(
          (key: any) => key !== recordKeyName
        );
        setSaveKeys(newSaveKeys);
      } else {
        const foundKey = saveKeys.find((key: any) => {
          return recordKeyName === key;
        });
        if (foundKey) return;
        setSaveKeys([...saveKeys, recordKeyName]);
      }
    }
  };

  const afterClearEvidence = (formName: any) => {
    const evidenceChangeValue = form.getFieldValue(formName[0]);
    const recordKeyName = formName[0]?.replaceAll("record", "");

    const isAllUndefined = Object.values(evidenceChangeValue).every(
      (x) => x === undefined || x === null
    );
    if (isAllUndefined) {
      const newSaveKeys = saveKeys.filter((key: any) => key !== recordKeyName);
      setSaveKeys(newSaveKeys);
    } else {
      const foundKey = saveKeys.find((key: any) => {
        return recordKeyName === key;
      });
      if (foundKey) return;
      setSaveKeys([...saveKeys, recordKeyName]);
    }
  };

  const getMoneyDirectCostOutsourceListLocal = async (
    childData: any
  ): Promise<void> => {
    try {
      dispatch(setLoading(true));
      // using deep copy to avoid 2 state aim the same memory address
      setData(JSON.parse(JSON.stringify(childData)));
      setOriginalData(JSON.parse(JSON.stringify(childData)));
    } catch (error: any) {
      ErrorNotification(NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getMoneyDirectCostOutsourceListApi = async (params: {
    outsourceCostId: number;
    page?: number;
    size?: number;
    sortBy?: string;
    sortType?: string;
  }): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const responseData = await service.getMoneyDirectCostOutsourceList({
        ...params,
        sortBy: "createdAt",
        sortType: "ASC",
      });
      // using deep copy to avoid 2 state aim the same memory address
      setData(JSON.parse(JSON.stringify(responseData)));
      setOriginalData(JSON.parse(JSON.stringify(responseData)));

      let status = STATUS_CM_PARENT_TABLE.REJECTED.LABEL;
      let actualCost: number = 0;
      let isAllApproved: boolean = true;
      let isAllWaitingForApproval: boolean = false;
      if (responseData) {
        const totalRecord = [...responseData, ...newLines];
        for (let i = 0; i < totalRecord.length; i++) {
          if (
            totalRecord[i]?.status?.code &&
            totalRecord[i].status.code !==
            STATUS_CM_CHILD_TABLE.MONEY_APPROVED.CODE
          )
            isAllApproved = false;
          else if (
            totalRecord[i]?.status?.code &&
            totalRecord[i].status.code ===
            STATUS_CM_CHILD_TABLE.MONEY_APPROVED.CODE
          )
            actualCost += totalRecord[i].actualCost || 0;

          if (
            totalRecord[i]?.status?.code &&
            totalRecord[i].status.code ===
            STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE
          )
            isAllWaitingForApproval = true;
        }
        if (responseData.length > 0) {
          if (isAllApproved) status = STATUS_CM_PARENT_TABLE.APPROVED.LABEL;
          else if (isAllWaitingForApproval)
            status = STATUS_CM_PARENT_TABLE.PENDING.LABEL;
        }

        dispatch(
          editParentStatus({
            id: parentId,
            actualCost,
            status,
            childrenNumber: responseData.length,
          })
        );
      }
    } catch (error: any) {
      ErrorNotification(NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const onValueNumberChange = (record: any, property: string, e: any) => {
    const value = e;
    const toNumber = Number(value);

    if (String(record.id).includes("new")) {
      // new data
      const newNewLines = newLines.map((element) => {
        if (element.id === record.id)
          element[`${property}`] = isNaN(toNumber) ? 0 : toNumber;
        return element;
      });
      // calculate total redux
      setNewLines(newNewLines);
    } else {
      // old data
      const newData = data.map((element: any) => {
        if (element.id === record.id)
          element[`${property}`] = isNaN(toNumber) ? 0 : toNumber;
        return element;
      });
      setData(newData);
    }
  };

  const onAttendanceLaborCostChange = async (
    record: any,
    property: string,
    value: any,
    projectId: number,
    constructionId: number
  ) => {
    try {
      if (value?.length !== 2) return;
      const attendances = await service.getAttendanceProjectConstruction({
        constructionId: constructionId,
        colabCompanyId: projectId,
        from: helpers.getFilterTime(value[0]),
        to: helpers.getFilterTimeTo(value[1]),
        timeOffset: 7,
      });

      const foundConstruction = attendances.find(
        (element) => element.id === constructionId
      );
      if (String(record.id).includes("new")) {
        // new data
        const newNewLines = newLines.map((element) => {
          if (element.id === record.id)
            element[`laborCost`] = foundConstruction?.laborCost ?? 0;
          return element;
        });
        // calculate total redux
        setNewLines(newNewLines);
      } else {
        // old data
        const newData = data.map((element: any) => {
          if (element.id === record.id)
            element[`laborCost`] = foundConstruction?.laborCost ?? 0;
          return element;
        });
        setData(newData);
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return;
    }
  };

  const addNewLine = (id: number | string) => {
    const newLineKey = `new${newCount}${id}`;

    const newLinesData: any = {
      key: newLineKey,
      id: newLineKey,
    };
    setNewLines([...newLines, newLinesData]);
    setEditingKeys([...editingKeys, newLineKey]);
    setNewCount(newCount + 1);
  };

  const isEditing = (record: any): boolean =>
    editingKeys.some((element) => element === record.key);

  const edit = (record: any) => {
    setEditingKeys([...editingKeys, record.key]);
    form.setFields([
      {
        name: [`record${record.id}`, `targetPeriod`],
        value: [
          record.startDate ? moment(record.startDate) : undefined,
          record.endDate ? moment(record.endDate) : undefined,
        ],
      },
      {
        name: [`record${record.id}`, `paymentDate`],
        value: record.paymentDate ? moment(record.paymentDate) : undefined,
      },
      {
        name: [`record${record.id}`, `planOutsourceCost`],
        value: record.planOutsourceCost,
      },
      {
        name: [`record${record.id}`, `paymentCost`],
        value: record.paymentCost,
      },
      {
        name: [`record${record.id}`, `actualCost`],
        value: record.actualCost,
      },
      {
        name: [`record${record.id}`, `reserveCost`],
        value: record.reserveCost,
      },
      {
        name: [`record${record.id}`, `evidence`],
        value: [
          {
            name: record.evidence,
            uid: Math.random(),
            key: Math.random(),
          },
        ],
      },
    ]);
  };
  const getLinkPresignUpload = async (params: any) => {
    try {
      const response = await service.getLinkPresignUpload(params);

      if (response) {
        return response.results;
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const uploadTos3 = async (url: any, data: any) => {
    try {
      const response = await service.uploadToS3(url, data);
      return response.results;
    } catch (error) {
      throw error;
    }
  };
  const getMoneyDirectCostOutsourceOffsetList = async (params: {
    outsourceCostInformationId: number;
  }): Promise<OutsourceOffsetCost[]> => {
    try {
      let page = COMMON.DEFAULT_PAGE;
      let size = COMMON.DEFAULT_SIZE;

      const response = await service.getMoneyDirectCostOutsourceOffsetList({
        outsourceCostInformationId: params.outsourceCostInformationId,
        page,
        size,
      });

      let finalResult: MoneyDirectCostOutsourceOffsetListResultsData[] =
        response?.results ?? [];
      let numPages = response.pagination.numPages;
      for (let i = 2; i <= numPages; i++) {
        const response = await service.getMoneyDirectCostOutsourceOffsetList({
          outsourceCostInformationId: params.outsourceCostInformationId,
          page: i,
          size,
        });
        finalResult = finalResult.concat(response?.results ?? []);
      }
      const result: OutsourceOffsetCost[] = finalResult.map(
        (element: MoneyDirectCostOutsourceOffsetListResultsData) => {
          const receiveMoneyOffset: OutsourceOffsetCost = {
            categoryId: element.category.id,
            name: element.name,
            value: element.value,
          };
          return receiveMoneyOffset;
        }
      );
      return result;
      //  return response.data;
    } catch (error: any) {
      throw error;
    }
  };

  const afterPostPutSuccess = async (key: any) => {
    const filteredKeys = editingKeys.filter((element: any) => element !== key);
    setEditingKeys(filteredKeys);

    // recall data
    await getMoneyDirectCostOutsourceListApi({
      outsourceCostId: parentId,
    });
  };

  const saveRecord = async (record: any, id: number) => {
    try {
      dispatch(setLoading(true));
      const recordFormValue = form.getFieldValue(`record${record.id}`);

      let targetPeriod,
        paymentDate,
        actualCost,
        reserveCost,
        planOutsourceCost,
        paymentCost,
        evidence;

      if (recordFormValue) {
        targetPeriod = recordFormValue.targetPeriod;
        paymentDate = recordFormValue.paymentDate;
        actualCost = recordFormValue.actualCost;
        reserveCost = recordFormValue.reserveCost;
        planOutsourceCost = recordFormValue.planOutsourceCost;
        paymentCost = recordFormValue.paymentCost;
        evidence = recordFormValue.evidence;
      }

      const startDate =
        targetPeriod && targetPeriod[0]
          ? helpers.getFilterTime(targetPeriod[0])
          : null; // blank case
      const endDate =
        targetPeriod && targetPeriod[1]
          ? helpers.getFilterTimeTo(targetPeriod[1])
          : null; // blank case

      const foundDataLocal = dataLocal.find(
        (element: any) => element.parentRecordId == record.id
      );

      if (String(record.id).includes("new")) {
        const outsourceOffsetCost: OutsourceOffsetCost[] = foundDataLocal
          ? convertDataLocalToReceiveMoneyOffset(foundDataLocal)
          : [];
        // create
        const payload: MoneyDirectCostOutSourceCreatePayload = {
          data: [
            {
              startDate: startDate,
              endDate: endDate,
              paymentDate: paymentDate
                ? helpers.getFilterTime(paymentDate)
                : null,
              evidence: evidence?.file?.name ? evidence.file.name : "",
              outsourceOffsetCost: outsourceOffsetCost,
              paymentCost: paymentCost || 0,
              planOutsourceCost: planOutsourceCost || 0,
              reserveCost: reserveCost || 0,
              actualCost: actualCost || 0,
            },
          ],
          documentDirectOutsourceCostId: parentId,
        };
        const response = await service.postMoneyDirectCostOutSourceCreate(
          payload
        );
        if (response && response.status === `${COMMON.HTTP_STATUS_OK}`) {
          if (evidence?.file?.originFileObj) {
            const companyId = JSON.parse(localStorage.getItem("user") ?? `{}`)
              ?.company?.id;
            const linkPreSignUpload = await getLinkPresignUpload({
              keyName: `re/${companyId}/${SCREEN_NAME}/${response.results}/${evidence?.file?.name}`,
            });
            await uploadTos3(linkPreSignUpload, evidence.file?.originFileObj);
          }

          // remove from new lines
          const filterNewLines = newLines.filter(
            (element: any) => element.key !== record.key
          );
          setNewLines(filterNewLines);
          SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
          return await afterPostPutSuccess(record.key);
        } else ErrorNotification(NOTIFICATION_TITLE.ERROR);
      } else {
        const outsourceOffsetCost: OutsourceOffsetCost[] = foundDataLocal
          ? convertDataLocalToReceiveMoneyOffset(foundDataLocal)
          : await getMoneyDirectCostOutsourceOffsetList({
            outsourceCostInformationId: record.id,
          });
        // update
        const payload: MoneyDirectCostOutsourceUpdatePayload = {
          paymentCost: paymentCost || 0,
          planOutsourceCost: planOutsourceCost || 0,
          reserveCost: reserveCost || 0,
          actualCost: actualCost || 0,
          id: record.id,
          startDate: startDate, // blank case
          endDate: endDate, // blank case
          evidence: evidence?.file?.name || (evidence && evidence[0] && evidence[0].name ? evidence[0].name : '') || '',
          outsourceOffsetCost: outsourceOffsetCost,
          paymentDate: paymentDate ? helpers.getFilterTime(paymentDate) : null,
          note: record?.note ?? "",
        };

        if (
          record?.status?.code === STATUS_CM_CHILD_TABLE.MONEY_APPROVED.CODE
        ) {
          dispatch(setDocumentEdit(payload));
          return;
        }
        const response = await service.postMoneyDirectCostOutsourceUpdate(
          payload
        );
        if (response && response.status === `${COMMON.HTTP_STATUS_OK}`) {
          if (evidence?.file?.originFileObj) {
            const companyId = JSON.parse(localStorage.getItem("user") ?? `{}`)
              ?.company?.id;
            const linkPreSignUpload = await getLinkPresignUpload({
              keyName: `re/${companyId}/${SCREEN_NAME}/${record.id}/${evidence?.file?.name}`,
            });
            await uploadTos3(linkPreSignUpload, evidence.file?.originFileObj);
          }
          await afterPostPutSuccess(record.key);
          SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
        } else ErrorNotification(NOTIFICATION_TITLE.ERROR);
      }
    } catch (error: any) {
      ErrorNotification(NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const updateOnNoteModal = async (payload: any) => {
    const response = await service.postMoneyDirectCostOutsourceUpdate(payload);
    if (response && response.status === `${COMMON.HTTP_STATUS_OK}`) {
      if (payload.evidence?.file?.originFileObj) {
        const companyId = JSON.parse(localStorage.getItem("user") ?? `{}`)
          ?.company?.id;
        const linkPreSignUpload = await getLinkPresignUpload({
          keyName: `re/${companyId}/${SCREEN_NAME}/${payload.id}/${payload.evidence?.file?.name}`,
        });
        await uploadTos3(
          linkPreSignUpload,
          payload.evidence.file?.originFileObj
        );
      }
      await afterPostPutSuccess(payload.id);
      SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
    } else throw new Error(NOTIFICATION_TITLE.ERROR);
  };

  const viewApproveModal = async (record: any) => {
    const foundDataLocal = dataLocal.find(
      (element: any) => element.parentRecordId == record.id
    );
    const outsourceOffsetCost: OutsourceOffsetCost[] = foundDataLocal
      ? convertDataLocalToReceiveMoneyOffset(foundDataLocal)
      : await getMoneyDirectCostOutsourceOffsetList({
        outsourceCostInformationId: record.id,
      });
    // update
    const payload: MoneyDirectCostOutsourceUpdatePayload = {
      paymentCost: record.paymentCost,
      planOutsourceCost: record.planOutsourceCost,
      reserveCost: record.reserveCost,
      actualCost: record.actualCost,
      id: record.id,
      startDate: record.startDate, // blank case
      endDate: record.endDate, // blank case
      evidence: record.evidence,
      outsourceOffsetCost: outsourceOffsetCost,
      paymentDate: record.paymentDate,
      note: record.note,
    };

    dispatch(
      setDocumentView({
        ...payload,
        status: record.status,
      })
    );
  };

  const deleteRecord = async (value: any) => {
    ConfirmModalAsync({
      onOk: async () => {
        try {
          dispatch(setLoading(true));
          // call api delete
          const payload: MoneyDirectCostOutsourceDeletePayload = {
            id: value.id,
          };
          const response = await service.deleteMoneyDirectCostOutsourceDelete(
            payload
          );
          SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);

          await getMoneyDirectCostOutsourceListApi({
            outsourceCostId: parentId,
          });
        } catch (error: any) {
          ErrorNotification(NOTIFICATION_TITLE.ERROR);
        } finally {
          dispatch(setLoading(false));
        }
      },
      onCancel: () => { },
      title: MESSAGE.MESSAGE_MODAL_DELETE_TITLE,
      description: MESSAGE.MESSAGE_DESCRIPTION_CR_7,
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_DELETE,
      className: "confirm__modal",
    });
  };
  const onCancelRecord = (record: any) => {
    if (String(record.id).includes("new")) {
      const calcelNewLines = newLines.filter(
        (element) => element.id !== record.id
      );
      setNewLines(calcelNewLines);
    } else {
      // case update
      const originalRecord = originalData.find(
        (element) => element.id === record.id
      );
      if (originalRecord) {
        const cancelEditData: any[] = data.map((element) => {
          if (originalRecord.id === element.id) return originalRecord;
          return element;
        });
        setData(JSON.parse(JSON.stringify(cancelEditData)));
      }
    }
    const filteredKeys = editingKeys.filter(
      (element: any) => element !== record.key
    );
    setEditingKeys(filteredKeys);
    dispatch(deleteDataLocalCM0012(record.id));
  };
  const cancelOperation = (record: any) => {
    // check if user has change input
    const isTouchedTargetPeriod = form.isFieldsTouched([
      `record${record.id}`,
      `targetPeriod`,
    ]);
    const isTouchedReceiveAt = form.isFieldsTouched([
      `record${record.id}`,
      `receiveAt`,
    ]);
    const isTouchedPlanReceiveMoney = form.isFieldsTouched([
      `record${record.id}`,
      `planReceiveMoney`,
    ]);
    const isTouchedReceiveMoney = form.isFieldsTouched([
      `record${record.id}`,
      `receiveMoney`,
    ]);
    const isTouchedContractorReceiveMoney = form.isFieldsTouched([
      `record${record.id}`,
      `contractorReceiveMoney`,
    ]);

    const isRecordTouched = [
      isTouchedTargetPeriod,
      isTouchedReceiveAt,
      isTouchedPlanReceiveMoney,
      isTouchedReceiveMoney,
      isTouchedContractorReceiveMoney,
    ].some((element) => element === true);

    if (isRecordTouched) {
      ConfirmModal({
        onOk: () => onCancelRecord(record),
        onCancel: () => { },
        title: MESSAGE.MESSAGE_020,
        description: MESSAGE.MESSAGE_022_1,
        extraDescription: MESSAGE.MESSAGE_022_2,
        canceText: LABEL_MESSAGE.CANCEL_MODAL,
        okText: LABEL_MESSAGE.OK_MODAL,
      });
    } else onCancelRecord(record);
    // case new
  };

  const viewDetail = (record: any) => {
    const headerData: HeaderData = {
      traderName: parentData?.company?.name ?? "",
      contractWorkName: parentData?.constructionName ?? "",
      targetPeriod:
        !record.startDate && !record.endDate
          ? ""
          : `
                    ${record.startDate
            ? moment(record.startDate).format(COMMON.FORMAT_DATE_OM)
            : ""
          }
                    ~
                    ${record.endDate
            ? moment(record.endDate).format(COMMON.FORMAT_DATE_OM)
            : ""
          }`,
    };
    dispatch(setHeaderDataCM0012(headerData));
    const { MONEY_REJECT, MONEY_NOT_APPROVED, MONEY_RETRIEVE, MONEY_APPROVED } =
      STATUS_CM_CHILD_TABLE;

    const parentCode = {
      parentRecordId: record.id,
      status: isEditing(record)
        ? [
          MONEY_REJECT.CODE,
          MONEY_NOT_APPROVED.CODE,
          MONEY_RETRIEVE.CODE,
          MONEY_APPROVED.CODE,
        ].includes(record?.status?.code ?? "")
        : false,
    };
    dispatch(setParentCodeCM0012(parentCode));
    dispatch(setIsVisibleCM0012(true));
  };
  const handleViewEvidence = async (record: any) => {
    try {
      dispatch(setLoading(true));
      const companyId = helpers.getCompanyIdFromLocalStorage();
      if (!companyId) return ErrorNotification(NOTIFICATION_TITLE.ERROR);
      const response = await service.getPresignLinkS3({
        keyName: `re/${companyId}/${SCREEN_NAME}/${record.id}/${record.evidence}`,
      });
      dispatch(setEvidenceLink(response.results));
      const checkFile = record.evidence?.split(".").pop();
      const evidenceName = record.evidence?.split(".").slice(0, -1).join(".");
      dispatch(setEvidenceName(evidenceName));
      if (checkFile === "pdf") {
        dispatch(showPdfViewerModal());
      } else dispatch(showImageViewerModal());
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const onDataLocalCM0012Change = (dataLocal: any) => {
    // new data
    const newDataTouched: any[] = [];
    const newNewLines = newLines.map((element) => {
      const foundData = dataLocal.find(
        (item: any) => item.parentRecordId == element.id
      );
      let total = 0;
      if (foundData) {
        total = foundData.arr.reduce(function (acc: any, obj: any) {
          return acc + obj.offsetAmount;
        }, 0);
        if (foundData?.arr?.length > 0) newDataTouched.push(element.id);
        return { ...element, offsetCost: total };
      }
      return element;
    });
    const newSaveKeys = [...new Set([...saveKeys, ...newDataTouched])];
    setSaveKeys(newSaveKeys);
    setNewLines(newNewLines);

    // old data
    const newData = data.map((element) => {
      const foundData = dataLocal.find(
        (item: any) => item.parentRecordId == element.id
      );
      let total = 0;
      if (foundData) {
        total = foundData.arr.reduce(function (acc: any, obj: any) {
          return acc + obj.offsetAmount;
        }, 0);
        return { ...element, offsetCost: total };
      }
      return element;
    });
    setData(newData);
  };
  const resetEditingLines = () => {
    setNewLines([]);
    setEditingKeys([]);
  };

  const onCheckAllChange = (checked: boolean) => {
    setCheckAll(checked);
    if (checked) {
      const checkedItems: any[] = [];
      for (let element of data) {
        if (
          element?.status?.code ===
          STATUS_CM_CHILD_TABLE.MONEY_NOT_APPROVED.CODE
        )
          checkedItems.push(element.id);
      }
      setCheckedList(checkedItems);
      dispatch(
        setCheckedCollapse({
          id: parentData.id,
          checked: checkedItems,
        })
      );
    } else {
      setCheckedList([]);
      dispatch(
        setCheckedCollapse({
          id: parentData.id,
          checked: [],
        })
      );
    }
  };
  const isCheckbox = (record: any): boolean =>
    checkedList.some((element) => element === record.key);

  const onCheckboxChange = (record: any, checked: boolean) => {
    // setCheckedList(list);
    let newCheckList: any[] = [];

    if (checked) {
      newCheckList = [...checkedList, record.key];
    } else {
      const checkedData = checkedList.filter(
        (element: any) => element !== record.key
      );
      newCheckList = checkedData;
    }
    const possibleItems: any[] = [];

    for (let element of data) {
      if (
        element?.status?.code === STATUS_CM_CHILD_TABLE.MONEY_NOT_APPROVED.CODE
      )
        possibleItems.push(element.id);
    }
    setCheckedList(newCheckList);
    setCheckAll(newCheckList.length === possibleItems.length);
    dispatch(
      setCheckedCollapse({
        id: parentData.id,
        checked: newCheckList,
      })
    );
  };
  const checkParentCBChange = (
    parentData: any,
    checkedCollapse: { id: number; checked: number[] }[]
  ) => {
    const isChecked = checkedCollapse.some(
      (element) => element.id === parentData.id
    );
    if (!isChecked) {
      setCheckAll(false);
      setCheckedList([]);
    }
  };

  return {
    form,
    editingKeys,
    data,
    newLines,
    saveKeys,
    checkAll,
    checkedList,
    isEditing,
    edit,
    saveRecord,
    addNewLine,
    onValueNumberChange,
    deleteRecord,
    getMoneyDirectCostOutsourceListApi,
    cancelOperation,
    setParentId,
    getMoneyDirectCostOutsourceListLocal,
    viewDetail,
    handleViewEvidence,
    checkEnableButtons,
    isEnableSaveButton,
    afterClearEvidence,
    setParentData,
    onDataLocalCM0012Change,
    resetEditingLines,
    onCheckAllChange,
    isCheckbox,
    onCheckboxChange,
    checkParentCBChange,
    viewApproveModal,
    updateOnNoteModal,
    onAttendanceLaborCostChange,
  };
};

export default CollapsedTableHandler;
