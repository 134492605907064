import CM0021API from './infrastructure/api/Api';
import CM0021Handler from './presenter/handler/Handler';
import CM0021Service from './usecase/Service';
import MainUI from './presenter/ui/UI';

import '../../../assets/styles/CM/cm024.css';
import '../../../assets/styles/CM/cm3854.css';
import '../../../assets/styles/CM/cm047.css';

type props = {
    afterSuccess?: () => void;
    onSubmit?: () => void;
    onReject?: () => void;
};
const CM0021Component = (props: props) => {
    const api = CM0021API();
    const service = CM0021Service(api);
    const handler = CM0021Handler(service);
    return <MainUI handler={handler} {...props} />;
};

export default CM0021Component;
