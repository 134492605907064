import { Button, Checkbox, Divider, Modal, Spin } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import 'moment/locale/ja';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloseIconModal from '../../../../../assets/icons/close.png';
import PaperBlue from '../../../../../assets/icons/paper_blue.svg';
import { default as XIconModal } from '../../../../../assets/icons/x-icon-modal.svg';
import '../../../../../assets/styles/CM/CM0010.css';
import LoadingComponent from '../../../../../common/components/icons/LoadingComponent';
import ImageViewerModalComponent from '../../../../../common/components/image-viewer-modal/ImageViewerComponent';
import PdfViewerModalComponent from '../../../../../common/components/pdf-viewer-modal/PdfViewerModalComponent';
import helpers from '../../../../../common/helpers/common';
import { RootState } from '../../../../../store';
import {
    TableConstructionBudget,
    TableConstructionContractBudget,
    TableSubmissionHistory,
} from '../../entity/Entity';
import { CM0010Handler } from '../handler/HandlerImpl';
import { resetCM0010 } from '../slice/Slice';
import { CM0010Table } from './CM0010Table';
import { CM0010TableHasSummary } from './CM0010TableHasSummary';
import COMMON, { STATUS_CM_CHILD_TABLE } from '../../../../../common/constants/COMMON';
import { DataTableOverview } from '../../../CM006/entity/Entity';
import IconCircleCheck from '../../../../../assets/icons/akar-icons_circle-check-fill.svg';
import ApprovalFlow, {
} from '../../../../../common/components/approval-flow/ApprovalFlow';
import { CheckBoxCollapseComponentCM007CM009 } from '../../../CM007/presenter/component/collapsed-table/ui/CheckBoxAll';
import IconI from '../../../../../assets/icons/icon-i-red.svg'
import ApproveViewModal from '../../../../../common/components/modal-approve-view';

import EyeApprovedIcon from '../../../../../assets/icons/eye-approved.svg'
import EyeNotApprovedIcon from '../../../../../assets/icons/eye-not-approved.svg'
import ApproveConfirmModal from '../../../../../common/components/modal-approve-confirm';

type Props = {
    handler: CM0010Handler;
    onSubmit?: () => void;
    onReject?: () => void;
    disableSubmitOrReject?: boolean;
    isCheckbox?: boolean
};

const CM0010UI = ({ handler, disableSubmitOrReject, isCheckbox = false, onSubmit, onReject }: Props) => {
    const dispatch = useDispatch();
    const isVisible = useSelector((state: RootState) => state.cm0010.isVisible);
    const loading = useSelector((state: RootState) => state.cm0010.loading);
    const documentId = useSelector((state: RootState) => state.cm0010.documentIdCM010);
    const documentOriginId = useSelector((state: RootState) => state.cm0010.documentOriginIdCM010);
    const submittedDate = useSelector((state: RootState) => state.cm0010.summitDateCM010);
    const isDisabledCheckAll = handler.dataTableSubmissionHistory.some((element) => element?.status?.code === STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE)
    const isShowRetrieve = useSelector((state: RootState) => state.wrapper3453i.isRetrieve);
    const statusCM010 = useSelector((state: RootState) => state.cm0010.statusCM010);

    useEffect(() => {
        Promise.all([
            handler.getListSubmittedDirectCostOutsource(documentId, documentOriginId),
            handler.getWorkflowOutsourceApprovalProcess(documentId),
        ]);
        return () => {
            dispatch(resetCM0010());
        };
    }, []);

    const renderEye = (status: string) => {
        if (status === STATUS_CM_CHILD_TABLE.MONEY_APPROVED.CODE) return EyeApprovedIcon
        else if (status === STATUS_CM_CHILD_TABLE.MONEY_NOT_APPROVED.CODE) return EyeNotApprovedIcon
        else return IconI
    }

    const isOpenImageViewer = useSelector(
        (state: RootState) => state.imageViewerModal.isOpenImageViewer,
    );

    const columnConstructionWork: ColumnsType<DataTableOverview> = [
        {
            title: '契約金額',
            dataIndex: 'contractValue',
            align: 'center',
            width: '25%',
        },
        {
            title: '請求出来高',
            dataIndex: 'paymentValue',
            align: 'center',
            width: '25%',
        },
        {
            title: '請求出来高(％)',
            dataIndex: 'paymentValuePercent',
            align: 'center',
            width: '25%',
        },
        {
            title: '請求残金',
            dataIndex: 'invoiceBalance',
            align: 'center',
            width: '25%',
        },
    ];

    const columnConstructionBudget: ColumnsType<TableConstructionBudget> = [
        {
            title: '予算金額',
            dataIndex: 'budgetAmount',
            align: 'center',
            width: '25%',
            render: (value) => {
                return <span>{`${helpers.formatCurrency(value)}円`}</span>;
            },
        },
        {
            title: '実費用',
            dataIndex: 'actualCost',
            align: 'center',
            width: '25%',
            render: (value) => {
                return <span>{`${helpers.formatCurrency(value)}円`}</span>;
            },
        },
        {
            title: '予算残高',
            dataIndex: 'budgetBalance',
            align: 'center',
            width: '25%',
            render: (value) => {
                return <span>{`${helpers.formatCurrency(value)}円`}</span>;
            },
        },
        {
            title: '予算消化率（％）',
            dataIndex: 'budgetRate',
            align: 'center',
            width: '25%',
            render: (value) => {
                return <span>{`${value}％`}</span>;
            },
        },
    ];

    const columnConstructionContractBudget: ColumnsType<TableConstructionContractBudget> = [
        {
            title: '予算金額',
            dataIndex: 'budgetAmount',
            align: 'center',
            width: '20%',
            render: (value) => {
                return <span>{`${helpers.formatCurrency(value)}円`}</span>;
            },
        },
        {
            title: '発注金額',
            dataIndex: 'orderAmount',
            align: 'center',
            width: '20%',
            render: (value) => {
                return <span>{`${helpers.formatCurrency(value)}円`}</span>;
            },
        },
        {
            title: '実費用',
            dataIndex: 'actualCost',
            align: 'center',
            width: '20%',
            render: (value) => {
                return <span>{`${helpers.formatCurrency(value)}円`}</span>;
            },
        },
        {
            title: '予算残高',
            dataIndex: 'budgetBalance',
            align: 'center',
            width: '20%',
            render: (value) => {
                return <span>{`${helpers.formatCurrency(value)}円`}</span>;
            },
        },
        {
            title: '予算消化率（％）',
            dataIndex: 'budgetRate',
            align: 'center',
            width: '20%',
            render: (value) => {
                return <span>{`${value}％`}</span>;
            },
        },
    ];

    const columnSubmissionHistory: ColumnsType<TableSubmissionHistory> = [
        {
            /*
            Hiển thị text: 項目追加
            Click button sẽ thêm 1 record, xem mục #8.3.10
            Điều kiện enable button: Khi status của record không ở status 承認待ち"				
            4/14/2023							
            */
            title: !isCheckbox ? 'No' : <CheckBoxCollapseComponentCM007CM009
                onCheckAllChange={handler.onCheckAllChange}
                checkAll={handler.checkAll}
                isDisabled={!isDisabledCheckAll}
            />,
            key: 'No',
            align: 'center',
            width: 100,
            render: (_, record, index) => {
                if (!isCheckbox) return <span className="cell-cm007-style white-space-nowrap"
                    style={{ width: '100%', textAlign: 'center' }} >
                    {index + 1}
                </span>

                const { MONEY_APPROVED, MONEY_REJECT, MONEY_RETRIEVE } = STATUS_CM_CHILD_TABLE

                return <span
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                    className="cell-cm007-style white-space-nowrap"
                    style={{ width: '100%', textAlign: 'center' }} >
                    {[
                        MONEY_REJECT.CODE,
                        MONEY_RETRIEVE.CODE,
                        MONEY_APPROVED.CODE].includes(record?.status?.code ?? '') ? (
                        <Checkbox
                            className="no-hover"
                            disabled={true}
                            style={{ marginRight: 8 }}
                            checked
                        />
                    ) : (
                        <Checkbox
                            onChange={(value: any) =>
                                handler.onCheckboxChange(
                                    record,
                                    value?.target?.checked ? true : false,
                                )
                            }
                            style={{ marginRight: 8 }}
                            checked={handler.isCheckbox(record)}
                        />
                    )}
                    {index + 1}
                </span>
            },
        },
        {
            title: '支払日',
            dataIndex: 'paymentDate',
            align: 'center',
            render: (value) => {
                return <span>{value}</span>;
            },
        },
        {
            title: '対象期間',
            dataIndex: 'targetStage',
            align: 'center',
            render: (value) => {
                return <span>{value}</span>;
            },
        },
        {
            title: '支払予定金額',
            dataIndex: 'payAmount',
            align: 'center',
            render: (value) => {
                return <span>{`${helpers.formatCurrency(value)}円`}</span>;
            },
        },
        {
            title: '勤怠計算',
            dataIndex: 'attendanceCalculation',
            align: 'center',
            render: (value) => {
                return <span>{`${helpers.formatCurrency(value)}円`}</span>;
            },
        },
        {
            title: '請求金額',
            dataIndex: 'billAmount',
            align: 'center',
            render: (value) => {
                return <span>{`${helpers.formatCurrency(value)}円`}</span>;
            },
        },
        {
            title: 'エビデンス',
            dataIndex: 'evidence',
            align: 'center',
            render: (value, raw) =>
                value && (
                    <img
                        src={PaperBlue}
                        style={{ width: 24, height: 24, objectFit: 'cover', cursor: 'pointer' }}
                        onClick={() => handler.handleOpenViewer(raw)}
                    ></img>
                ),
        },
        {
            title: '実費用',
            dataIndex: 'actualCost',
            align: 'center',
            render: (value) => {
                return <span>{`${helpers.formatCurrency(value)}円`}</span>;
            },
        },
        {
            title: '保留額',
            dataIndex: 'amountHold',
            align: 'center',
            render: (value) => {
                return <span>{`${helpers.formatCurrency(value)}円`}</span>;
            },
        },
        {
            title: '相殺金額',
            dataIndex: 'offsetAmount',
            align: 'center',
            render: (value) => {
                return <span>{`${helpers.formatCurrency(value)}円`}</span>;
            },
        },
        {
            title: '差引支払額',
            dataIndex: 'netPaymentAmount',
            align: 'center',
            render: (value) => {
                return <span>{`${helpers.formatCurrency(value)}円`}</span>;
            },
        },
        {
            title: 'ステータス',
            key: 'ステータス',
            align: 'center',
            width: 150,
            render: (record) => {
                if (record?.status?.code === false) return <></>;
                const color = STATUS_CM_CHILD_TABLE.hasOwnProperty(
                    `${record?.status?.code?.toUpperCase()}`,
                )
                    ? {
                        color: STATUS_CM_CHILD_TABLE[`${record?.status?.code?.toUpperCase()}` as keyof typeof STATUS_CM_CHILD_TABLE]
                            .COLOR,
                    }
                    : {};
                return (
                    <div className="collapsed-table-row-style center-item" style={color}>
                        {`${record.status.name}` || ''}
                        <div style={{ marginLeft: 8 }} className='center-item'>
                            {
                                ([STATUS_CM_CHILD_TABLE.MONEY_APPROVED.CODE, STATUS_CM_CHILD_TABLE.MONEY_NOT_APPROVED.CODE, STATUS_CM_CHILD_TABLE.MONEY_REJECT.CODE].includes(record?.status?.code) && record?.note !== "")
                                    ?
                                    <div className='button-style' onClick={() => handler.viewApproveModal(record)} >
                                        <img alt='' src={renderEye(record?.status?.code)} />
                                    </div> : <></>
                            }
                        </div>
                    </div >
                );
            },
        },
    ];

    useEffect(() => {
        handler?.dataTableSubmissionHistory &&
            handler.handleCalculateSummary(handler.dataTableSubmissionHistory);
    }, [handler?.dataTableSubmissionHistory]);

    return (
        <Modal
            className="group-detail-modal"
            style={{ marginLeft: 300, marginTop: 40, marginBottom: 40 }}
            visible={isVisible}
            closable={false}
            maskClosable={false}
            width={'1520px'}
            centered={true}
            closeIcon={<img src={CloseIconModal} alt="close icon" />}
            footer={
                <div className="cm0010-footer">
                    {isShowRetrieve ? (
                        <Button
                            disabled={
                                loading ||
                                handler.checkedList.length === 0
                            }
                            size="large"
                            className="button-purple button-dsb"
                            style={{ marginLeft: 16, width: 120, letterSpacing: -1.5 }}
                            onClick={() => handler.retrieveDocument(documentId)}
                        >
                            取り下げ
                        </Button>
                    ) : null}
                    {onSubmit && onReject ? (
                        <>
                            {/* DONE */}
                            <ApproveConfirmModal onSubmit={onSubmit} />
                            <Button
                                className="button-purple cm047-btn-submit"
                                icon={<img alt="" src={IconCircleCheck} />}
                                size="large"
                                onClick={handler.showConfirmApproveModal}
                                disabled={
                                    disableSubmitOrReject ||
                                    !(
                                        handler.currentUserApproval &&
                                        helpers.checkApproveInWorkFlow(handler.currentUserApproval)
                                    )
                                }
                                style={{ marginLeft: 16 }}
                            >
                                承認
                            </Button>
                            <Button
                                size="large"
                                className="button-warning cm047-btn-reject"
                                onClick={onReject}
                                disabled={
                                    disableSubmitOrReject ||
                                    !(
                                        handler.currentUserApproval &&
                                        helpers.checkRejectInWorkFlow(handler.currentUserApproval)
                                    )
                                }
                            >
                                否認
                            </Button>
                        </>
                    ) : null}
                    <Button
                        disabled={loading}
                        size="large"
                        style={{
                            marginLeft: 16,
                            width: 118,
                            color: '#666',
                            fontWeight: 400,
                            fontSize: 14,
                        }}
                        onClick={handler.onCancel}
                    >
                        キャンセル
                    </Button>
                </div>
            }
        >
            <Spin indicator={LoadingComponent} spinning={loading}>
                <div className="w-100-percent header-modal-containter" style={{ height: 50 }}>
                    <div className="header-modal-title-cm0010">外注費</div>
                    <div style={{ cursor: 'pointer' }} onClick={handler.onCancel}>
                        <img alt="" src={XIconModal} style={{ height: 18, width: 18 }} />
                    </div>
                </div>
                <div style={{ marginTop: 24, marginBottom: 8 }}>
                    <ApprovalFlow
                        isNotCheckingUser={true}
                        breadcrumb={[]}
                        listApprove={handler.workflow}
                        currentUserApproval={handler.currentUserApproval}
                    />
                </div>
                <div style={{ minHeight: 224, marginTop: 24 }} className="cm0010-content">
                    <div className="content-description">
                        <div className="description-col">
                            <div className="description-row">
                                <label>案件名</label>
                                <p>{handler.dataSummaryTitle?.project?.name ?? ''}</p>
                            </div>
                            <div className="description-row">
                                <label>業者名</label>
                                <p>{handler.dataSummaryTitle?.outsourceCompany?.name ?? ''}</p>
                            </div>
                        </div>

                        <div className="description-col">
                            <div className="description-row">
                                <label>工事名</label>
                                <p>{handler.dataSummaryTitle?.construction?.name ?? ''}</p>
                            </div>
                            <div className="description-row">
                                <label>作成日</label>
                                <p>{submittedDate}</p>
                            </div>
                        </div>
                        <div className="description-col">
                            <div className="description-row">
                                <label>契約工事名</label>
                                <p>{handler.dataSummaryTitle?.contractorConstructionName ?? ''}</p>
                            </div>
                        </div>
                    </div>
                    <div className="content-table">
                        <CM0010Table
                            data={handler.dataReceivedMoney}
                            columns={columnConstructionWork}
                            title="工事出来高"
                        />
                        <CM0010Table
                            data={handler.constructionInformationCost}
                            columns={columnConstructionBudget}
                            title="工事全体外注費予算残高"
                        />
                        <CM0010Table
                            data={handler.dataTableConstructionContractBudget}
                            columns={columnConstructionContractBudget}
                            title="契約工事別予算残高"
                        />

                        <Divider style={{ padding: '0px 24px', margin: '32px 0px' }}></Divider>
                        <CM0010TableHasSummary
                            data={handler.dataTableSubmissionHistory}
                            columns={columnSubmissionHistory}
                            title="提出履歴"
                            handler={handler}
                        />
                    </div>
                </div>

                <ImageViewerModalComponent
                    imageURL={handler.pdfUrl}
                    title={handler.pdfName}
                />
                <PdfViewerModalComponent
                    pdfURL={handler.pdfUrl}
                    title={handler.pdfName} />
            </Spin>
            <ApproveViewModal isViewOnly={true} />
        </Modal>
    );
};

export default CM0010UI;
