import { NOTIFICATION_TITLE } from "./../../../../common/constants/MESSAGE";
import ErrorNotification from "../../../../common/components/notification/ErrorNotification";
import { CM0030Api } from "./ServiceImpl";
import {
  BaseResponseType,
  IndirectCostProjectDocumentStatusData,
  IndirectCostProjectSubmitPayload,
} from "../entity/Entity";
import COMMON from "../../../../common/constants/COMMON";

const CM0030Service = (cm0030: CM0030Api) => {
  const getListSummaryIndirectCost = async (params: any): Promise<any> => {
    try {
      const response = await cm0030.getListSummaryIndirectCost({
        ...params,
      });
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return;
    }
  };

  const getSummaryIndirectCost = async (params: any): Promise<any> => {
    try {
      const response = await cm0030.getSummaryIndirectCost({
        ...params,
      });
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return;
    }
  };

  const getStatusIndirectCost = async (params: any): Promise<any> => {
    try {
      const response = await cm0030.getStatusIndirectCost({
        ...params,
      });
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return;
    }
  };

  const getTotalIndirectCost = async (params: any): Promise<any> => {
    try {
      const response = await cm0030.getTotalIndirectCost({
        ...params,
      });
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return;
    }
  };

  const getIndirectCostProjectDocumentStatus = async (params: {
    from: string;
    to: string;
  }): Promise<IndirectCostProjectDocumentStatusData | null> => {
    try {
      const response = await cm0030.getIndirectCostProjectDocumentStatus(
        params
      );
      return response?.data;
    } catch (error: any) {
      ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
      return null;
    }
  };

  const putIndirectCostProjectSubmit = async (
    payload: IndirectCostProjectSubmitPayload
  ): Promise<BaseResponseType | null> => {
    try {
      const response = await cm0030.putIndirectCostProjectSubmit(payload);
      return response?.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return null;
    }
  };

  const getProjectAlls = async (params: {
    page: number;
    size: number;
    from: string;
    to: string;
    sortBy: string;
    sortType: string;
  }): Promise<any> => {
    try {
      const response = await cm0030.getProjectAlls({
        ...params,
      });
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return;
    }
  };

  const doExportCM0030 = async (params: any): Promise<any> => {
    return await cm0030.doExportCM0030(params);
  }

  return {
    getListSummaryIndirectCost,
    getSummaryIndirectCost,
    getStatusIndirectCost,
    getTotalIndirectCost,
    getIndirectCostProjectDocumentStatus,
    putIndirectCostProjectSubmit,
    getProjectAlls,
    doExportCM0030,
  };
};

export default CM0030Service;
