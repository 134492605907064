import ErrorNotification from "../../../../common/components/notification/ErrorNotification";
import COMMON from "../../../../common/constants/COMMON";
import { NOTIFICATION_TITLE } from "../../../../common/constants/MESSAGE";
import {
  BaseResponseType,
  LaborCostDocumentStatusData,
  MoneySummaryLaborCostProjectSubmitPayload,
} from "../entity/Entity";
import { CM0028Api } from "./ServiceImpl";

const CM0028Service = (cm0028: CM0028Api) => {
  const getReSummaryProjectLaborCost = async (params: any): Promise<any> => {
    try {
      const response = await cm0028.getReSummaryProjectLaborCost({
        ...params,
      });
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return;
    }
  };

  const getProjectAlls = async (params: {
    page: number;
    size: number;
    from: string;
    to: string;
    sortBy: string;
    sortType: string;
  }): Promise<any> => {
    try {
      const response = await cm0028.getProjectAlls({
        ...params,
      });
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return;
    }
  };

  const getReSummaryProjectLaborCostTotal = async (
    params: any
  ): Promise<any> => {
    try {
      const response = await cm0028.getReSummaryProjectLaborCostTotal({
        ...params,
      });
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return;
    }
  };
  const postMoneySummaryLaborCostProjectSubmit = async (
    payload: MoneySummaryLaborCostProjectSubmitPayload
  ): Promise<BaseResponseType | null> => {
    try {
      const response = await cm0028.postMoneySummaryLaborCostProjectSubmit(
        payload
      );
      return response?.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return null;
    }
  };

  const getLaborCostDocumentStatus = async (params: {
    from: string;
    to: string;
  }): Promise<LaborCostDocumentStatusData | null> => {
    try {
      const response = await cm0028.getLaborCostDocumentStatus(params);
      return response?.data;
    } catch (error: any) {
      ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
      return null;
    }
  };

  const doExportCM0028 = async (params: any): Promise<any> => {
      return await cm0028.doExportCM0028(params);
  }
  return {
    getReSummaryProjectLaborCost,
    getProjectAlls,
    getReSummaryProjectLaborCostTotal,
    postMoneySummaryLaborCostProjectSubmit,
    getLaborCostDocumentStatus,
    doExportCM0028,
  };
};

export default CM0028Service;
