// 3rd party lib
import { useDispatch, useSelector } from "react-redux";

// Source files
import { MaterialCostServiceImpl } from "./../../usecase/ServiceImpl";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import { setConstructionCost } from "../slice/Slice";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../../common/constants/MESSAGE";
import { RootState } from "../../../../../store";
import COMMON from "../../../../../common/constants/COMMON";
import moment from "moment";
import { doExportForResponse } from "../../../../../common/helpers/exports/common";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";

const MaterialCostHandler = (service: MaterialCostServiceImpl) => {
  const dispatch = useDispatch();

  const constructionInfo = useSelector(
    (state: RootState) => state.cm004.constructionInfo
  );
  const constructionCost = useSelector(
    (state: RootState) => state.cm004.constructionCost
  );
  const completedWork = useSelector(
    (state: RootState) => state.cm004.completedWork
  );
  const getMoneySummaryConstructionCostInformation = async (params: {
    constructionId: number;
  }): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const responseData =
        await service.getMoneySummaryConstructionCostInformation(params);
      dispatch(setConstructionCost(responseData));
    } catch (error: any) {
      ErrorNotification(error.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const putMoneySummaryConstructionCostDirectThresholdUpdate = async (
    payload: any
  ): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const response =
        await service.putMoneySummaryConstructionCostDirectThresholdUpdate({
          constructionId: Number(payload.constructionId),
          enable: payload.enable,
          threshold: payload.threshold,
        });
    } catch (error: any) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

  const putMoneySummaryConstructionCostMaterialThresholdUpdate = async (
    payload: any
  ): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const response =
        await service.putMoneySummaryConstructionCostMaterialThresholdUpdate({
          constructionId: Number(payload.constructionId),
          enable: payload.enable,
          threshold: payload.threshold,
        });
      // SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
    } catch (error: any) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

  const putMoneySummaryConstructionCostOutsourceThresholdUpdate = async (
    payload: any
  ): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const response =
        await service.putMoneySummaryConstructionCostOutsourceThresholdUpdate({
          constructionId: Number(payload.constructionId),
          enable: payload.enable,
          threshold: payload.threshold,
        });
      // SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
    } catch (error: any) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

  const putMoneySummaryConstructionCostLaborThresholdUpdate = async (
    payload: any
  ): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const response =
        await service.putMoneySummaryConstructionCostLaborThresholdUpdate({
          constructionId: Number(payload.constructionId),
          enable: payload.enable,
          threshold: payload.threshold,
        });
      // SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
    } catch (error: any) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };

  const putMoneySummaryConstructionCostIndirectThresholdUpdate = async (
    payload: any
  ): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const response =
        await service.putMoneySummaryConstructionCostIndirectThresholdUpdate({
          constructionId: Number(payload.constructionId),
          enable: payload.enable,
          threshold: payload.threshold,
        });
      //SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
    } catch (error: any) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };
  const exportFile = async (params: { projectName: string }) => {
    try {
      dispatch(setLoading(true));
      const screenName = COMMON.CM_FILE_STRUCTURE.SCREEN_NAME;
      const tab1 = COMMON.CM_FILE_STRUCTURE.PARENT_TAB.TAB_1.NAME;
      const submitDate = moment().format(COMMON.FORMAT_DATE_SUBMIT);

      const response = await service.doExportCM004({
        constructionId: constructionInfo?.constructionId,
        projectName: params.projectName,
      } as any);
      doExportForResponse(response, `${screenName}_${tab1}_${submitDate}.xlsx`);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };
  return {
    getMoneySummaryConstructionCostInformation,
    putMoneySummaryConstructionCostDirectThresholdUpdate,
    putMoneySummaryConstructionCostMaterialThresholdUpdate,
    putMoneySummaryConstructionCostOutsourceThresholdUpdate,
    putMoneySummaryConstructionCostLaborThresholdUpdate,
    putMoneySummaryConstructionCostIndirectThresholdUpdate,
    exportFile,
  };
};

export default MaterialCostHandler;
