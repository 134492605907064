import TooltipText from '../../../../../../../common/components/tooltip-text/TooltipText';

type Props = {
    onClick: (value: any) => void;
    construction: any;
    isChosen: boolean;
    isCollapsed: boolean;
};

const ConstructionItem = ({ onClick, construction, isChosen, isCollapsed }: Props) => {
    const firstLetter =
        construction.name && construction.name?.length >= 1 ? construction.name[0] : '';

    return isCollapsed ? (
        <main className={`company-item-container ${isChosen ? 'chosen' : ''}`} onClick={onClick}>
            <div className="company-item-avt">
                <p className="company-item-avt-text">{firstLetter}</p>
            </div>
        </main>
    ) : (
        <main
            className={`company-item-container ${
                isChosen ? 'chosen company-item-text-chosen' : 'company-item-text'
            }`}
            onClick={onClick}
        >
            <TooltipText content={construction.name} />
        </main>
    );
};

export default ConstructionItem;
