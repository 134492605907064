import { exportCM0032_2 } from "./../../../../../common/helpers/exports/cm/template_cm0032_2";
import { uuidv4 } from "@firebase/util";
import { useSearchParams } from "react-router-dom";
// Redux
import { useDispatch, useSelector } from "react-redux";

// Services
import { CM0032UseCase } from "./../../usecase/ServiceImpl";
import { useState } from "react";
import moment from "moment";
import helpers from "../../../../../common/helpers/common";

import {
  setIsVisibleCM0063,
  setParentCodeCM0063,
  setScreen,
  setTaxCodeCM0063,
  setTypeModalCM0063,
} from "../../../CM0063/presenter/slice/Slice";
import { Form } from "antd";
import COMMON from "../../../../../common/constants/COMMON";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import { SCREENCM0032 } from "../../../../../common/constants/SCREENS";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import { exportCM0032_1 } from "../../../../../common/helpers/exports/cm/template_cm0032_1";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import ConfirmModalAsync from "../../../../../common/components/modal/ConfirmModalAsync";
import { doExportForResponse } from "../../../../../common/helpers/exports/common";
import { RootState } from "../../../../../store";

export const MENU_ITEM = [
  {
    name: "業者別",
    key: "trader",
  },
  {
    name: "案件別",
    key: "project",
  },
];

const PARAMS = {
  menu: "menu6",
  from: "from61",
  to: "to61",
  clear: "clear61",
  from2: "from62",
  to2: "to62",
  clear2: "clear62",
  openFilter: "openFilter6",
};

const PARAMS_DEFAULT = {
  menu: MENU_ITEM[0].key,
  from: helpers.getFilterTime(moment()),
  to: helpers.getFilterTime(moment()),
};

const DEFAULT_STATUS = {
  id: 0,
  code: "",
  name: "",
  type: "",
};

const FILE_NAME_BY_COMPANY = `社内収支_入金額_業者別_${moment().format(
  COMMON.FORMAT_DATE_SUBMIT
)}`;
const FILE_NAME_BY_PROJECT = `社内収支_入金額_案件別_${moment().format(
  COMMON.FORMAT_DATE_SUBMIT
)}`;

const CM0032Handler = (service: CM0032UseCase) => {
  const dispatch = useDispatch();
  const loading = useSelector((state: RootState) => state.common.loading);

  const [formFilter] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const menu = searchParams.get(PARAMS.menu) || PARAMS_DEFAULT.menu;
  const from = searchParams.get(PARAMS.from) || PARAMS_DEFAULT.from;
  const to = searchParams.get(PARAMS.to) || PARAMS_DEFAULT.to;
  const clear = searchParams.get(PARAMS.clear);
  const from2 = searchParams.get(PARAMS.from2) || PARAMS_DEFAULT.from;
  const to2 = searchParams.get(PARAMS.to2) || PARAMS_DEFAULT.to;
  const clear2 = searchParams.get(PARAMS.clear2);

  const [dataTableByProject, setDataTableByProject] = useState<any>([]);
  const [dataTableByTrader, setDataTableByTrader] = useState<any>([]);
  const [expandKey, setExpandKey] = useState<any>([]);
  const [totalReceiveCost, setTotalReceiveCost] = useState({});
  const [totalReceiveCostByTrader, setTotalReceiveCostByTrader] = useState({});
  const [numPages, setNumPages] = useState<number>(1);
  const [numPagesByProject, setNumPagesByProject] = useState<number>(1);
  const [numPagesByTrader, setNumPagesByTrader] = useState<number>(1);
  const [visibleApprove, setVisibleApprove] = useState<boolean>(false);
  const [filterCurrent, setFilterCurrent] = useState({ from: "", to: "" });
  const [status, setStatus] = useState(DEFAULT_STATUS);
  const [refreshStatus, setRefreshStatus] = useState(false);
  const [paginationByCompany, setPaginationByCompany] = useState({
    numPages: 0,
    page: 1,
  });

  const openFilter = searchParams.get(PARAMS.openFilter);
  const funcSetExpendKey = (key: any) => {
    const checkKey = funcCheckKeyExpand(key);
    if (checkKey) {
      const newExpandKey = expandKey.filter(
        (keyExpand: any) => keyExpand !== key
      );
      setExpandKey(newExpandKey);
    } else {
      setExpandKey([...expandKey, key]);
    }
  };

  const funcCheckKeyExpand = (key: any) => {
    if (!key || !expandKey) return false;
    return expandKey.some((element: any) => element === key);
  };

  const handleChangeMenuChosen = (menuValue: string) => {
    setFilterCurrent({ from: "", to: "" });
    searchParams.set(PARAMS.menu, menuValue);
    setSearchParams(searchParams);
    if (menuValue === menu) return;
    else {
      setNumPagesByProject(COMMON.DEFAULT_PAGE);
      setNumPagesByTrader(COMMON.DEFAULT_PAGE);
    }
  };

  const initialFilter = () => {
    if (menu === MENU_ITEM[0].key)
      formFilter.setFieldValue(
        "filterTime6",
        clear ? null : [moment(from), moment(to)]
      );
    else
      formFilter.setFieldValue(
        "filterTime6",
        clear2 ? null : [moment(from2), moment(to2)]
      );
  };

  const handleFilterTime = (filter: any) => {
    setNumPagesByProject(COMMON.DEFAULT_PAGE);
    setNumPagesByTrader(COMMON.DEFAULT_PAGE);
    const value = filter.filterTime6;
    setFilterCurrent({ from: "", to: "" });
    if (menu === MENU_ITEM[0].key) {
      searchParams.delete(PARAMS.from);
      searchParams.delete(PARAMS.to);
      searchParams.set(PARAMS.clear, "1");
      if (value) {
        searchParams.delete(PARAMS.clear);
        const fromValue = helpers.getFilterTimeMonth(value[0]);
        const toValue = helpers.getFilterTimeMonthTo(value[1]);
        if (fromValue !== PARAMS_DEFAULT.from) {
          searchParams.set(PARAMS.from, fromValue);
        }
        if (toValue !== PARAMS_DEFAULT.to) {
          searchParams.set(PARAMS.to, toValue);
        }
      }
    } else {
      searchParams.delete(PARAMS.from);
      searchParams.delete(PARAMS.to);
      searchParams.set(PARAMS.clear2, "1");
      if (value) {
        searchParams.delete(PARAMS.clear2);
        const fromValue = helpers.getFilterTimeMonth(value[0]);
        const toValue = helpers.getFilterTimeMonthTo(value[1]);
        if (fromValue !== PARAMS_DEFAULT.from) {
          searchParams.set(PARAMS.from2, fromValue);
        }
        if (toValue !== PARAMS_DEFAULT.to) {
          searchParams.set(PARAMS.to2, toValue);
        }
      }
    }
    setSearchParams(searchParams);
  };

  const getSummaryProjectReceiveMoneyTotal = async (params: {
    from: string;
    to: string;
  }) => {
    try {
      const response = await service.getSummaryProjectReceiveMoneyTotal(params);
      if (response?.results) setTotalReceiveCost(response.results);
      else setTotalReceiveCost({});
    } catch (error) {
      setTotalReceiveCost({});
    }
  };

  const getSummaryProjectReceiveMoneyTotalByTrader = async (params: {
    from: string;
    to: string;
  }) => {
    try {
      if (filterCurrent.from === params.from && filterCurrent.to === params.to)
        return;
      setFilterCurrent({ from: params.from, to: params.to });
      const response = await service.getSummaryProjectReceiveMoneyTotal(params);
      if (response?.results) setTotalReceiveCostByTrader(response.results);
      else setTotalReceiveCostByTrader({});
    } catch (error) {
      setTotalReceiveCostByTrader({});
    }
  };

  const getSummaryProjectReceiveMoneyList = async (params: {
    from: string;
    to: string;
  }) => {
    try {
      dispatch(setLoading(true));

      setFilterCurrent({ from: params.from, to: params.to });
      if (numPages < numPagesByProject) return;
      let projectData: any[] = [];
      const response = await service.getSummaryProjectReceiveMoneyList({
        ...params,
        page: numPagesByProject,
        size: Number(COMMON.DEFAULT_SIZE),
        sortBy: "createdAt",
        sortType: "DESC",
      });

      if (response?.results) {
        const newData = response.results.map((item: any) => {
          return {
            ...item,
            key: uuidv4(),
            taxInclude: `${helpers.formatCurrency(
              (item?.depositAmount + item.taxation) * (item?.taxSetting ?? 1) +
              item.depositAmount,
              true
            )}円`,
            taxation: `${helpers.formatCurrency(item?.taxation)}円`,
            taxExempt: `${helpers.formatCurrency(item?.taxExempt)}円`,
            depositAmount: `${helpers.formatCurrency(item?.depositAmount)}円`,
            consumptionTax: `${helpers.formatCurrency(
              (item?.depositAmount + item.taxation) * (item?.taxSetting ?? 1),
              true
            )}円`,
          };
        });
        projectData = newData;

        for (let i = 0; i < newData.length; i++) {
          const costDetail: any = await getSummaryProjectReceiveMoney({
            ...params,
            projectId: newData[i].id,
          });

          const dataChild = costDetail.map((ele: any, index: number) => {
            return {
              ...ele,
              key: uuidv4(),
              no: index + 1,
              taxInclude: `${helpers.formatCurrency(
                (ele?.depositAmount + ele.taxation) * (ele?.taxSetting ?? 1) +
                ele?.depositAmount,
                true
              )}円`,
              taxation: `${helpers.formatCurrency(ele?.taxation)}円`,
              taxExempt: `${helpers.formatCurrency(ele?.taxExempt)}円`,
              depositAmount: `${helpers.formatCurrency(ele?.depositAmount)}円`,
              consumptionTax: `${helpers.formatCurrency(
                (ele?.depositAmount + ele.taxation) * (ele?.taxSetting ?? 1),
                true
              )}円`,
            };
          });

          newData[i].children = dataChild;
        }

        numPagesByProject === COMMON.DEFAULT_PAGE
          ? setDataTableByProject(projectData)
          : setDataTableByProject([...dataTableByProject, ...projectData]);

        if (numPagesByProject === 1) {
          setExpandKey([newData[0].key, `itemThird${projectData[0].key}`]);
        }
      } else setDataTableByProject([]);
      if (response?.pagination?.numPages && response.pagination.numPages !== 0)
        setNumPages(response.pagination.numPages);
      setNumPagesByProject(response.pagination.page + 1);
    } catch (error) {
      setDataTableByProject([]);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getSummaryProjectReceiveMoney = async (params: {
    projectId: number;
    from: string;
    to: string;
    sortBy?: string;
    sortType?: string;
  }) => {
    const response = await service.getSummaryProjectReceiveMoney({
      ...params,
      page: 1,
      size: COMMON.DEFAULT_SIZE,
    });
    if (response?.results && response.results.length > 0) {
      let dataFirst = [...response?.results];
      if (response.pagination.numPages > 1) {
        for (
          let pageFirst = 2;
          pageFirst <= response.pagination.numPages;
          pageFirst++
        ) {
          const responseRe = await service.getSummaryProjectReceiveMoney({
            ...params,
            page: pageFirst,
            size: COMMON.DEFAULT_SIZE,
          });
          if (responseRe?.results && responseRe?.results?.length > 0) {
            dataFirst = [...dataFirst, ...responseRe?.results];
          }
        }
      }
      return dataFirst;
    }
    return [];
  };

  const getReceiveMoneyProjectDocumentStatus = async (params: {
    from: string;
    to: string;
  }) => {
    try {
      if (filterCurrent.from === params.from && filterCurrent.to === params.to)
        return;
      setFilterCurrent({ from: params.from, to: params.to });
      const response = await service.getReceiveMoneyProjectDocumentStatus(
        params
      );
      if (response?.results) setStatus(response.results);
      else setStatus(DEFAULT_STATUS);
    } catch (error) {
      setStatus(DEFAULT_STATUS);
    }
  };
  const getReceiveMoneyCompanyDocumentStatus = async (params: {
    from: string;
    to: string;
  }) => {
    try {
      if (filterCurrent.from === params.from && filterCurrent.to === params.to)
        return;
      setFilterCurrent({ from: params.from, to: params.to });
      const response = await service.getReceiveMoneyCompanyDocumentStatus(
        params
      );
      if (response?.results) setStatus(response.results);
      else setStatus(DEFAULT_STATUS);
    } catch (error) {
      setStatus(DEFAULT_STATUS);
    }
  };

  const handleGetStatus = (
    params: { from: string; to: string },
    menu: string
  ) => {
    if (menu === MENU_ITEM[0].key) getReceiveMoneyCompanyDocumentStatus(params);
    else getReceiveMoneyProjectDocumentStatus(params);
  };

  const handleScroll = async (e: any, from: string, to: string) => {
    var target = e.target;
    const bottom =
      target.scrollTop + target.offsetHeight + 1 >= target.scrollHeight;
    if (e.target.scrollTop === 0) return;
    if (bottom) {
      await getSummaryProjectReceiveMoneyList({ from, to });
    }
  };

  const handleViewDetail = (id: number, tax: string) => {
    dispatch(setIsVisibleCM0063(true));
    dispatch(setTypeModalCM0063(false));
    dispatch(setScreen(SCREENCM0032));
    dispatch(
      setParentCodeCM0063({
        parentRecordId: id,
        status: false,
      })
    );
    dispatch(setTaxCodeCM0063(tax));
  };

  const handleConfirmData = async () => {
    setVisibleApprove(true);
  };

  const onCancelConfirmModal = () => {
    setVisibleApprove(false);
  };

  const onOkSubmitData = async () => {
    ConfirmModalAsync({
      onOk: async () => {
        try {
          dispatch(setLoading(true));
          let response: any;
          if (menu === MENU_ITEM[0].key)
            response = await service.putReceiveMoneyCompanySubmit({
              fileName: FILE_NAME_BY_COMPANY,
              startDate: helpers.getFilterTimeMonth(from),
              endDate: helpers.getFilterTimeMonthTo(to),
            });
          else
            response = await service.putReceiveMoneyProjectSubmit({
              fileName: FILE_NAME_BY_PROJECT,
              startDate: helpers.getFilterTimeMonth(from2),
              endDate: helpers.getFilterTimeMonthTo(to2),
            });
          if (response.status === `${COMMON.HTTP_STATUS_OK}`) {
            SuccessNotification(
              response?.message ?? NOTIFICATION_TITLE.SUCCESS
            );
            setVisibleApprove(false);
            setRefreshStatus((curState) => !curState);
            setFilterCurrent({ from: "", to: "" });
          }
        } catch (error: any) {
          ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        } finally {
          dispatch(setLoading(false));
        }
      },
      onCancel: () => { },
      className: "confirm__modal confirm__modal-purple-oke",
      title: MESSAGE.MESSAGE_TITLE_BEFORE_CREATE,
      description: "入金額" + MESSAGE.MESSAGE_SUBMIT_CM0019,

      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_MODAL_2,
    });
  };

  // BY TRADER

  const getSummaryProjectReceiveMoneyListByTrader = async (
    params: {
      page: number;
      size: number;
      from: string;
      to: string;
      sortType?: string;
      sortBy?: string;
    },
    isScroll?: boolean
  ) => {
    dispatch(setLoading(true));
    const taxSetting = await getTax();
    try {
      if (!isScroll) {
        if (
          filterCurrent.from === params.from &&
          filterCurrent.to === params.to
        )
          return;
        setFilterCurrent({ from: params.from, to: params.to });
      }
      const resFirst = await service.getSummaryReceiveMoneyCompanyList(params);

      const results: any[] = [];
      const keyExpand: any[] = [];

      if (resFirst?.pagination) setPaginationByCompany(resFirst.pagination);

      if (resFirst?.results && resFirst.results?.length > 0) {
        for await (const [index, item] of resFirst.results.entries()) {
          let objFirst = {
            ...item,
            key: uuidv4(),
            no: index + 1 + params.size * (params.page - 1),

            taxation: `${helpers.formatCurrency(item?.taxation)}円`,
            taxExempt: `${helpers.formatCurrency(item?.taxExempt)}円`,
            depositAmount: `${helpers.formatCurrency(item?.depositAmount)}円`,
            consumptionTax: `${helpers.formatCurrency(
              (item?.depositAmount + item.taxation) * (taxSetting ?? 1),
              true
            )}円`,
            taxInclude: `${helpers.formatCurrency(
              (item?.depositAmount + item.taxation) * (taxSetting ?? 1) +
              item.depositAmount,
              true
            )}円`,
            children: [] as any[],
          };
          if (index === 0) keyExpand.push(objFirst.key);

          const resSecond = await getMoneySummaryReceiveMoneyCompanyProjectList(
            {
              ...params,
              companyId: item.id,
            }
          );

          if (resSecond.length > 0) {
            for await (const [indexChild, itemChild] of resSecond.entries()) {
              let objSecond = {
                ...itemChild,
                key: uuidv4(),
                no: indexChild + 1,

                taxation: `${helpers.formatCurrency(itemChild?.taxation)}円`,
                taxExempt: `${helpers.formatCurrency(itemChild?.taxExempt)}円`,
                depositAmount: `${helpers.formatCurrency(
                  itemChild?.depositAmount
                )}円`,
                consumptionTax: `${helpers.formatCurrency(
                  (itemChild?.depositAmount + itemChild.taxation) *
                  (taxSetting ?? 1),
                  true
                )}円`,
                taxInclude: `${helpers.formatCurrency(
                  (itemChild?.depositAmount + itemChild.taxation) *
                  (taxSetting ?? 1) +
                  itemChild.depositAmount,
                  true
                )}円`,
                children: [] as any[],
              };
              if (indexChild === 0 && index === 0)
                keyExpand.push(objSecond.key);
              const resChildThird = await getSummaryProjectReceiveMoney({
                ...params,
                projectId: itemChild.id,
              });


              objSecond.children = resChildThird?.map((element: any, index) => {
                return {
                  ...element,
                  key: uuidv4(),
                  no: index + 1,

                  taxation: `${helpers.formatCurrency(element?.taxation)}円`,
                  taxExempt: `${helpers.formatCurrency(element?.taxExempt)}円`,
                  depositAmount: `${helpers.formatCurrency(
                    element?.depositAmount
                  )}円`,
                  consumptionTax: `${helpers.formatCurrency(
                    (element?.depositAmount + element.taxation) *
                    (taxSetting ?? 1),
                    true
                  )}円`,
                  taxInclude: `${helpers.formatCurrency(
                    (element?.depositAmount + element.taxation) *
                    (taxSetting ?? 1) +
                    element.depositAmount,
                    true
                  )}円`,
                };
              });

              objFirst.children.push(objSecond);
            }
          }

          results.push(objFirst);
        }
      }

      if (params.page === 1) setExpandKey(keyExpand);
      setDataTableByTrader((preState: any[]) => {
        if (params.page === 1) return results;
        else return [...preState, ...results];
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getMoneySummaryReceiveMoneyCompanyProjectList = async (params: {
    companyId: number;
    from: string;
    to: string;
    sortBy?: string;
    sortType?: string;
  }) => {
    const response =
      await service.getMoneySummaryReceiveMoneyCompanyProjectList({
        ...params,
        page: 1,
        size: COMMON.DEFAULT_SIZE,
      });
    if (response?.results && response.results.length > 0) {
      let dataFirst = [...response?.results];
      if (response.pagination.numPages > 1) {
        for (
          let pageFirst = 2;
          pageFirst <= response.pagination.numPages;
          pageFirst++
        ) {
          const responseRe =
            await service.getMoneySummaryReceiveMoneyCompanyProjectList({
              ...params,
              page: pageFirst,
              size: COMMON.DEFAULT_SIZE,
            });
          if (responseRe?.results && responseRe?.results?.length > 0) {
            dataFirst = [...dataFirst, ...responseRe?.results];
          }
        }
      }
      return dataFirst;
    }
    return [];
  };

  const handleScrollByTrader = async (e: any) => {
    const target = e.target;
    if (
      !loading &&
      target.scrollTop + target.offsetHeight >= target.scrollHeight &&
      paginationByCompany.page < paginationByCompany.numPages
    ) {
      await getSummaryProjectReceiveMoneyListByTrader(
        {
          from: clear ? "" : helpers.getFilterTimeMonth(from),
          to: clear ? "" : helpers.getFilterTimeMonthTo(to),
          page: paginationByCompany.page + 1,
          size: COMMON.DEFAULT_SIZE,
        },
        true
      );
    }
  };

  const funcSetExpendKeyByTrader = (key: any) => {
    const checkKey = funcCheckKeyExpand(key);
    if (checkKey) {
      const newExpandKey = expandKey.filter(
        (keyExpand: any) => keyExpand !== key
      );
      setExpandKey(newExpandKey);
    } else {
      setExpandKey([...expandKey, key]);
    }
  };

  const handleOpenCalendar = (open: boolean) => {
    if (menu === MENU_ITEM[0].key) {
      if (open) searchParams.set("openFilter6", "1");
      else {
        if (
          helpers.getFilterTimeMonth(from) === filterCurrent.from ||
          helpers.getFilterTimeMonthTo(to) === filterCurrent.to
        ) {
          searchParams.delete("openFilter6");
          setSearchParams(searchParams);
          return;
        } else {
          setDataTableByTrader([]);
          searchParams.delete("openFilter6");
          setSearchParams(searchParams);
        }
      }
    }
    if (menu === MENU_ITEM[1].key) {
      if (open) searchParams.set("openFilter6", "1");
      else {
        if (
          helpers.getFilterTimeMonth(from2) === filterCurrent.from ||
          helpers.getFilterTimeMonthTo(to2) === filterCurrent.to
        ) {
          searchParams.delete("openFilter6");
          setSearchParams(searchParams);
          return;
        } else {
          setDataTableByProject([]);
          searchParams.delete("openFilter6");
          setSearchParams(searchParams);
        }
      }
    }
  };

  const getReceivedMoneyOffset = async (params: { receiveMoneyId: number }) => {
    const response = await service.getReceivedMoneyOffset({
      ...params,
      page: 1,
      size: COMMON.DEFAULT_SIZE,
    });
    if (response?.results && response.results.data.length > 0) {
      let dataFirst = {
        startDate: response.results?.startDate,
        endDate: response.results?.endDate,
        orderingCompanyName: response.results?.orderingCompanyName,
        data: [...response?.results?.data],
      };

      if (response.pagination.numPages > 1) {
        for (
          let pageFirst = 2;
          pageFirst <= response.pagination.numPages;
          pageFirst++
        ) {
          const responseRe = await service.getReceivedMoneyOffset({
            ...params,
            page: pageFirst,
            size: COMMON.DEFAULT_SIZE,
          });
          if (responseRe?.results && responseRe?.results?.data?.length > 0) {
            dataFirst.data = [...dataFirst.data, ...responseRe?.results?.data];
          }
        }
      }
      return dataFirst;
    }
    return {};
  };

  const convertDataExportMoneyOffsetByTrader = async (data: any) => {
    const output: any[] = [];

    data.forEach(async (item: any) => {
      if (item.children.length > 0) {
        item.children.forEach((element: any) => {
          output.push({
            projectName: item.projectName,
            constructionName: element.constructionDescription,
            receiveMoneyId: element.id,
            orderAddress: item.orderAddress,
          });
        });
      }
    });

    const dConvert: any[] = [];

    const promises = output.map(async (ite: any) => {
      const resp: any = await getReceivedMoneyOffset({
        receiveMoneyId: ite.receiveMoneyId,
      });

      const startTime = resp?.startDate
        ? moment(resp?.startDate).format(COMMON.FORMAT_DATE_CI)
        : "";
      const endTime = resp?.endDate
        ? moment(resp?.endDate).format(COMMON.FORMAT_DATE_CI)
        : "";

      const obj = {
        constructionMapper: `${ite.constructionName}-${resp?.orderingCompanyName}-${ite?.orderAddress}-${ite.projectName}`,
        orderCompanyName: resp?.orderingCompanyName ?? "",
        dateTime: `${startTime} ～ ${endTime}`,
        nestedData: resp?.data ?? [],
        parentId: ite.receiveMoneyId,
      };
      if (resp?.data?.length > 0) {
        dConvert.push(obj);
      }
    });

    await Promise.all(promises);
    // Group items

    let _mapperChild: any = {};

    dConvert.forEach((item: any) => {
      if (_mapperChild.hasOwnProperty(item.constructionMapper)) {
        _mapperChild[`${item.constructionMapper}`].push(item);
      } else {
        _mapperChild[`${item.constructionMapper}`] = [item];
      }
    });

    const dataConvert = convertNestedData(_mapperChild);
    const results: any[] = [];
    let count = 0;
    const check: any = {};
    for (const item of dataConvert) {
      const nestedData = item.nestedData.map((ele: any) => {
        return {
          ...ele,
          constructionName: item.constructionName,
        };
      });
      if (!(item.projectName in check)) {
        check[item.projectName] = count++;
        results.push({ ...item, nestedData: nestedData ?? [] });
      } else {
        if (nestedData) {
          results[check[item.projectName]].nestedData = [
            ...results[check[item.projectName]].nestedData,
            ...nestedData,
          ];
        }
      }
    }
    return results;
  };

  const convertNestedData = (data: any) => {
    const nestedData: any[] = [];

    for (const [key, value] of Object.entries(data)) {
      const _name = key.split("-");

      const _obj = {
        constructionName: _name.length > 0 ? _name[0] : "",
        orderCompanyName: _name.length > 0 ? _name[1] : "",
        orderAddress: _name.length > 0 ? _name[2] : "",
        projectName: _name.length > 0 ? _name[3] : "",
        nestedData: nested(value),
      };

      nestedData.push(_obj);
    }

    return nestedData;
  };

  const nested = (items: any) => {
    const nestedData: any[] = [];

    items.sort((a: any, b: any) => {
      return b.parentId - a.parentId;
    }); // sort desc with parent id

    items.forEach((ele: any) => {
      const elements: any[] = [];
      let footerTotal = 0;

      ele.nestedData.forEach((a: any, idx: number) => {
        const offset = a?.value ?? 0;

        const _e = {
          no: idx + 1,
          item: a?.name ?? "",
          tax: a?.category?.description ?? "",
          offsetAmount: `${helpers.formatCurrency(offset)}円`,
        };
        footerTotal += offset;
        elements.push(_e);
      });

      elements.unshift({
        no: "No",
        item: "項目",
        tax: "消費税区分",
        offsetAmount: "相殺金額(税抜)",
      });

      const _obj = {
        dateTime: ele?.dateTime ?? "",
        elements: elements,
        footer: `${helpers.formatCurrency(footerTotal)}円`,
      };

      nestedData.push(_obj);
    });

    return nestedData;
  };

  const exportExcelCM0032 = async () => {
    if (menu === MENU_ITEM[0].key) {
      try {
        dispatch(setLoading(true));

        const response = await service.doExportCM0032_1({
          from: from ? helpers.getFilterTimeMonth(from) : "",
          to: to ? helpers.getFilterTimeMonthTo(to) : "",
        } as any);
        doExportForResponse(response, FILE_NAME_BY_COMPANY + ".xlsx");
      } catch (error: any) {
        ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      } finally {
        dispatch(setLoading(false));
      }
    } else {
      try {
        dispatch(setLoading(true));

        const response = await service.doExportCM0032_2({
          from: from2 ? helpers.getFilterTimeMonth(from2) : "",
          to: to2 ? helpers.getFilterTimeMonthTo(to2) : "",
        } as any);
        doExportForResponse(response, FILE_NAME_BY_PROJECT + ".xlsx");
      } catch (error: any) {
        ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      } finally {
        dispatch(setLoading(false));
      }
    }
  };

  const getTax = async () => {
    try {
      dispatch(setLoading(true));
      const response = await service.getTaxSetting({});
      return response?.results?.taxSetting ?? 0;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return 0;
    } finally {
      dispatch(setLoading(false));
    }
  };

  return {
    dataTableByProject,
    dataTableByTrader,
    expandKey,
    menu,
    from,
    to,
    clear,
    from2,
    to2,
    clear2,
    formFilter,
    totalReceiveCost,
    totalReceiveCostByTrader,
    visibleApprove,
    openFilter,
    filterCurrent,
    status,
    refreshStatus,
    funcSetExpendKey,
    funcCheckKeyExpand,
    handleChangeMenuChosen,
    handleFilterTime,
    handleConfirmData,
    handleViewDetail,
    initialFilter,
    getSummaryProjectReceiveMoneyTotal,
    handleScroll,
    getSummaryProjectReceiveMoneyList,
    getSummaryProjectReceiveMoneyListByTrader,
    getSummaryProjectReceiveMoneyTotalByTrader,
    onCancelConfirmModal,
    handleScrollByTrader,
    funcSetExpendKeyByTrader,
    handleOpenCalendar,
    exportExcelCM0032,
    handleGetStatus,
    onOkSubmitData,
    getTax,
  };
};

export default CM0032Handler;
