import React, { useMemo } from 'react';
import { CM0032Handler } from '../handler/HandlerImpl';
import IconDown from '../../../../../assets/icons/icon-collapse-cm.svg';
import IconView from '../../../../../assets/icons/eyeIconBlack.svg';
import SortTable from '../../../../../common/components/sort-table/SortTable';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { Empty } from 'antd';
import helpers from '../../../../../common/helpers/common';
import COMMON, { TAX_CODE } from '../../../../../common/constants/COMMON';

interface props {
    handler: CM0032Handler;
}
const NO_DATA = 'データなし';

const TableByProject = ({ handler }: props) => {
    const loading = useSelector((state: RootState) => state.common.loading);
    const contentTable = useMemo(() => {
        if (!handler.dataTableByProject || handler.dataTableByProject.length === 0)
            return !loading ? (
                <tr>
                    <td colSpan={13}>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={NO_DATA} />
                    </td>
                </tr>
            ) : null;
        const elementTable: any = [];
        handler.dataTableByProject.forEach((element: any, indx) => {
            elementTable.push(
                <React.Fragment key={element.key}>
                    <tr style={{ height: 4 }}></tr>
                    <tr
                        className="row-level-0 button-style"
                        onClick={() => handler.funcSetExpendKey(element.key)}
                    >
                        <td colSpan={2}>{indx + 1}</td>
                        <td colSpan={5} style={{ textAlign: 'left', paddingLeft: 50 }}>
                            {helpers.getShortTextWithToolTip(element.projectName, 20)}
                        </td>
                        <td>{element.taxation}</td>
                        <td>{element.taxExempt} </td>
                        <td>{element.depositAmount}</td>
                        <td>{element.consumptionTax}</td>
                        <td>{element?.taxInclude}</td>
                        <td>
                            <img
                                alt=""
                                src={IconDown}
                                className={`cm024-icon ${handler.funcCheckKeyExpand(element.key)
                                        ? ''
                                        : 'cm024-icon-active'
                                    }`}
                                onClick={() => {
                                    handler.funcSetExpendKey(element.key);
                                }}
                            />
                        </td>
                    </tr>
                </React.Fragment>,
            );

            if (element.children) {
                elementTable.push(
                    <tr
                        key={`table${element.key}`}
                        className="bg-fa"
                        style={{
                            display: handler.funcCheckKeyExpand(element.key) ? undefined : 'none',
                        }}
                    >
                        <td colSpan={2}></td>
                        <td style={{ minWidth: 140 }}>工事名</td>
                        <td style={{ minWidth: 140 }}>発注元会社名</td>
                        <td colSpan={2} className="" style={{ minWidth: 140 }}>
                            対象期間
                        </td>
                        <td className="" style={{ minWidth: 160 }}>
                            入金日
                        </td>
                        <td></td>
                        <td></td>
                        <td style={{ minWidth: 100 }}></td>
                        <td style={{ minWidth: 100 }}></td>
                        <td style={{ minWidth: 100 }}></td>
                        <td></td>
                    </tr>,
                );
                if (element?.children && element.children.length > 0) {
                    element.children.forEach((child2: any) => {
                        elementTable.push(
                            <tr
                                key={child2.key}
                                style={{
                                    display: handler.funcCheckKeyExpand(element.key)
                                        ? undefined
                                        : 'none',
                                }}
                            >
                                <td style={{ width: 50 }}></td>
                                <td style={{ width: 50 }}>{child2.no}</td>
                                <td>
                                    {helpers.getShortTextWithToolTip(
                                        child2.constructionDescription,
                                        13,
                                    )}{' '}
                                </td>
                                <td>{helpers.getShortTextWithToolTip(child2.orderAddress, 13)}</td>
                                <td colSpan={2}>{`${helpers.formatDatePresenter(
                                    child2?.startDate,
                                    COMMON.FORMAT_DATE_CM,
                                )}～${helpers.formatDatePresenter(
                                    child2?.endDate,
                                    COMMON.FORMAT_DATE_CM,
                                )}`}</td>
                                <td>
                                    {helpers.formatDatePresenter(
                                        child2?.receiveAt,
                                        COMMON.FORMAT_DATE_CM,
                                    )}
                                </td>
                                <td>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                        }}
                                    >
                                        <span style={{ whiteSpace: 'nowrap' }}>
                                            {child2.taxation}
                                        </span>
                                        &nbsp;
                                        <img
                                            className="icon-view"
                                            alt=""
                                            src={IconView}
                                            onClick={() =>
                                                handler.handleViewDetail(child2.id, TAX_CODE.TAX)
                                            }
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                        }}
                                    >
                                        <span style={{ whiteSpace: 'nowrap' }}>
                                            {child2.taxExempt}
                                        </span>
                                        &nbsp;
                                        <img
                                            className="icon-view"
                                            alt=""
                                            src={IconView}
                                            onClick={() =>
                                                handler.handleViewDetail(
                                                    child2.id,
                                                    TAX_CODE.TAX_FREE,
                                                )
                                            }
                                        />
                                    </div>
                                </td>
                                <td>{child2.depositAmount} </td>
                                <td>{child2.consumptionTax} </td>
                                <td>{child2.taxInclude}</td>
                                <td></td>
                            </tr>,
                        );
                    });
                } else {
                    !loading &&
                        elementTable.push(
                            <tr
                                key={`table${element.key}nodata`}
                                style={{
                                    display: handler.funcCheckKeyExpand(element.key)
                                        ? undefined
                                        : 'none',
                                }}
                            >
                                <td colSpan={13}>
                                    <Empty
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        description={NO_DATA}
                                    />
                                </td>
                            </tr>,
                        );
                }
            }
        });
        return elementTable;
    }, [handler.dataTableByProject, handler.expandKey, loading]);

    return (
        <div
            className="cm024-table"
            onScroll={(e: any) => {
                handler.handleScroll(
                    e,
                    helpers.getFilterTimeMonth(handler.from2),
                    helpers.getFilterTimeMonthTo(handler.to2),
                );
            }}
        >
            <table>
                <thead>
                    <tr>
                        <th colSpan={2} rowSpan={2} style={{ minWidth: 100 }}>
                            No
                        </th>
                        <th
                            rowSpan={2}
                            colSpan={5}
                            style={{ textAlign: 'left', paddingLeft: 50, minWidth: '580px' }}
                        >
                            案件名
                        </th>
                        <th colSpan={2} style={{ minWidth: '185px' }}>
                            相殺金額
                        </th>
                        <th rowSpan={2} style={{ minWidth: '170px' }}>
                            入金額(税抜)
                        </th>
                        <th rowSpan={2} style={{ minWidth: '130px' }}>
                            消費税
                        </th>
                        <th rowSpan={2} style={{ minWidth: '130px' }}>
                            入金額(税込)
                        </th>
                        <th rowSpan={2} style={{ minWidth: '40px' }}></th>
                    </tr>
                    <tr className="tr-border-first">
                        <th>課税</th>
                        <th>非課税</th>
                    </tr>
                </thead>
                <tbody>{contentTable}</tbody>
                <tfoot>
                    <tr className="table-foot">
                        <td colSpan={7} style={{ textAlign: 'left', paddingLeft: 40 }}>
                            合計金額
                        </td>
                        <td>{helpers.formatCurrency(handler.totalReceiveCost.taxation)}円</td>
                        <td>{helpers.formatCurrency(handler.totalReceiveCost.taxExempt)}円 </td>
                        <td>{helpers.formatCurrency(handler.totalReceiveCost.depositAmount)}円 </td>
                        <td>
                            {helpers.formatCurrency(
                                (handler.totalReceiveCost.depositAmount +
                                    handler.totalReceiveCost.taxation) *
                                (handler.totalReceiveCost?.taxSetting ?? 1), true
                            )}
                            円{' '}
                        </td>
                        <td>
                            {helpers.formatCurrency(
                                (handler.totalReceiveCost.depositAmount +
                                    handler.totalReceiveCost.taxation) *
                                (handler.totalReceiveCost?.taxSetting ?? 1) +
                                handler.totalReceiveCost.depositAmount, true
                            )}
                            円
                        </td>
                        <td></td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};

export default TableByProject;
