import { Collapse } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../store";
import { MyCompanyHandler } from "../handler/HandlerImpl";
import IconDown from "../../../../../../../assets/icons/arrow.svg";
import MemberCheckBoxComponent from "../../../member/MemberCheckbox";
import MemberPreviewComponent from "../../../member/MemberPreview";
const { Panel } = Collapse;

type Props = {
  handler: MyCompanyHandler;
  company: any,
};

const MyCompanyComponent = ({ handler, company }: Props) => {
  const { myCompanyMembers, chosenMyCompanyMembers, setCompanyCollapse, onChooseCheckbox } = handler;
  const isUpdating = useSelector((state: RootState) => state.pm011.isUpdating);
  return (
    <Collapse
      className="folder-collapse pm011-collapse-header pm011-company"
      activeKey={handler.companyCollapse}
      onChange={(value: any) => setCompanyCollapse(value)}
      expandIcon={({ isActive }) => {
        return (
          <div
            className={`${isActive ? "collapse-icon-active" : ""
              } collapse-icon`}
          >
            <img src={IconDown} alt="" />
          </div>
        );
      }}
      style={{ marginBottom: 2 }}
    >
      <Panel
        forceRender={true}
        className="pm011-panel"
        key={company?.constructionId + "" ?? "0"}
        header={
          <div
            className="pm011-panel-header-title"
          >
            <div className="pm011-panel-header-title-left">
              自社
            </div>
            <div className="pm011-panel-header-title-right">
              人数：{company?.totalMember ?? 0}人
            </div>
          </div>
        }
      >
        {
          isUpdating
            ?
            <MemberCheckBoxComponent chosenMembers={chosenMyCompanyMembers} members={myCompanyMembers} onChooseCheckbox={onChooseCheckbox} />
            :
            <MemberPreviewComponent chosenMembers={chosenMyCompanyMembers} name={`construction${company.constructionId + ""}`} />
        }      </Panel>
    </Collapse>
  );
};

export default MyCompanyComponent;
