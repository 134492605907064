import MainUI from "./presenter/ui/UI";

// CSS
import "../../../assets/styles/CM/cm001.css";
import CM001Api from "./infrastructure/api/Api";
import CM001Service from "./usecase/Service";
import CM001Handler from "./presenter/handler/Handler";

const CM001Component = () => {
  const cm001Api = CM001Api();
  const cm001Service = CM001Service(cm001Api);
  const cm001Handler = CM001Handler(cm001Service);

  return <MainUI handler={cm001Handler}/>;
};

export default CM001Component;
