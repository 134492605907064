// Redux
import { useDispatch, useSelector } from "react-redux";

// Services
import { ServiceImpl } from "./../../usecase/ServiceImpl";
import { useState } from "react";
import { resetCM0027, setIsShowOffset, setVisibleCM0027 } from "../slice/Slice";

import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import COMMON from "../../../../../common/constants/COMMON";
import {
  CompanyState,
  ReSummaryCompanyProjectOutsourceCostRetrievePayload,
  SummaryTotal,
} from "../../entity/Entity";

import helpers from "../../../../../common/helpers/common";
import {
  getDataWithFormula,
  getSubConstructionBasedOnConstruction,
} from "../../helper";
import {
  setIsVisibleCM0027_2,
  setParentCodeCM0027_2,
} from "../../../CM0027_2/presenter/slice/Slice";
import {
  setHeaderDataCM0027_1,
  setIsVisibleCM0027_1,
  setParentCodeCM0027_1,
} from "../../../CM0027_1/presenter/slice/Slice";
import { RootState } from "../../../../../store";
import ConfirmModal from "../../../../../common/components/modal/ConfirmModal";
import { ItemApprove } from "../../../../../common/components/approval-flow/ApprovalFlow";
import { showModalApproveConfirm } from "../../../../../common/components/modal-approve-confirm/presenter/slice/Slice";

const CM0029Handler = (service: ServiceImpl) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [expandCompanyKey, setExpandCompanyKey] = useState<any[]>([]);
  const [expandProjectKey, setExpandProjectKey] = useState<any[]>([]);
  const [outsourceCompanies, setOutsourceCompanies] = useState<CompanyState[]>(
    []
  );
  const [summaryData, setSummaryData] = useState<SummaryTotal>({
    actualCost: 0,
    offsetCostSummaryWithTax: 0,
    offsetCostSummaryWithoutTax: 0,
    offsetCostWithTax: 0,
    offsetCostWithoutTax: 0,
    taxSetting: 0,
    consumptionTax: 0,
    totalOffset: 0,
    paymentAmountExcludingTax: 0,
    paymentAmountIncludingTax: 0,
  });
  const [projects, setProjects] = useState<any[]>([]);
  const [numPages, setNumPages] = useState<number>(1);
  const [projectPage, setProjectPage] = useState<number>(1);
  const projectSize = Number(COMMON.DEFAULT_SIZE);
  const documentId = useSelector((state: RootState) => state.cm0027.documentId);
  const [workflow, setWorkflow] = useState<ItemApprove[]>([]);
  const [currentUserApproval, setCurrentUserApproval] =
    useState<ItemApprove | null>(null);

  const getWorkflowSummaryOutsourceProjectApprovalProcess = async (
    documentId: number
  ) => {
    try {
      setLoading(true);
      const data =
        await service.getWorkflowSummaryOutsourceProjectApprovalProcess({
          documentId,
        });
      if (data.results.currentUserApproval)
        setCurrentUserApproval({
          approveDate: data.results.currentUserApproval.approveDate,
          contact_address: "",
          email: data.results.currentUserApproval.email,
          id: data.results.currentUserApproval.id,
          isApprove: data.results.currentUserApproval.approve,
          reject: data.results.currentUserApproval.reject,
          position: "",
          priority: data.results.currentUserApproval.priority,
          username: data.results.currentUserApproval.username,
          note: data.results.currentUserApproval.note,
          fullName: data.results.currentUserApproval.fullName,
        });
      const workflowData: ItemApprove[] = data.results.listApprover.map(
        (element) => {
          let note = element.note;
          if (element.id === data.results?.currentUserApproval?.id)
            note = data.results.currentUserApproval.note;
          const result: ItemApprove = {
            approveDate: element.approveDate,
            contact_address: "",
            email: element.email,
            id: element.id,
            isApprove: element.approve,
            reject: element.reject,
            position: "",
            priority: element.priority,
            username: element.username,
            fullName: element.fullName,
            note: note,
          };
          return result;
        }
      );
      setWorkflow(workflowData);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      setLoading(false);
    }
  };

  const getReSummarySubmittedProjectOutsourceCostTotal = async (
    documentId: number
  ) => {
    try {
      setLoading(true);
      const summaryData =
        await service.getReSummarySubmittedProjectOutsourceCostTotal({
          documentId: documentId,
        });
      const totalOffset =
        summaryData.offsetCostSummaryWithoutTax +
        summaryData.offsetCostSummaryWithTax +
        summaryData.offsetCostWithTax +
        summaryData.offsetCostWithoutTax;
      const paymentAmountExcludingTax =
        (summaryData?.actualCost ?? 0) - totalOffset;
      const consumptionTax = Math.floor(
        (paymentAmountExcludingTax +
          summaryData.offsetCostWithTax +
          summaryData.offsetCostSummaryWithTax) *
          summaryData?.taxSetting
      );
      const paymentAmountIncludingTax =
        paymentAmountExcludingTax + consumptionTax;
      setSummaryData({
        ...summaryData,
        totalOffset,
        paymentAmountExcludingTax,
        consumptionTax,
        paymentAmountIncludingTax,
      });
    } catch (error: any) {
      ErrorNotification(error.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      setLoading(false);
    }
  };

  const getProjectAlls = async (documentId: number) => {
    if (numPages < projectPage) return;
    try {
      setLoading(true);
      let projectData: any[] = [];
      const responseData =
        await service.getReSummarySubmittedOutsourceCostProject({
          page: projectPage,
          size: projectSize,
          documentId: documentId,
        });

      projectData = responseData.results ?? [];

      const constructionProject = await Promise.all(
        projectData.map((element) => {
          return service.getReSummarySubmittedProjectConstructionOutsourceCost({
            documentId,
            projectId: element.id,
          });
        })
      );
      // deal with consruction
      projectData = projectData.map((project, index1) => {
        return {
          ...project,
          projectName: project.name,
          constructions: constructionProject[index1].map(
            (constructionElement, index2) => {
              return {
                ...constructionElement.construction,
                name: constructionElement.construction.description,
                subConstruction: [
                  {
                    id: index1 + "" + index2,
                    name: constructionElement.subConstructionName,
                    outSourceCost: constructionElement.listData,
                  },
                ],
              };
            }
          ),
        };
      });

      const projectOutSourceCost = projectData;
      const formulatedData = getDataWithFormula(projectOutSourceCost);
      if (projectPage === 1 && formulatedData.length > 0) {
        setCompanyExpandKeys(projectData[0].id);

        const listSubConstructions = getSubConstructionBasedOnConstruction(
          projectData[0].constructions
        );
        if (listSubConstructions.length > 0)
          setProjectExpandKeys(listSubConstructions[0].id);
      }
      setProjects([...projects, ...formulatedData]);
      setNumPages(responseData.pagination.numPages);
      setProjectPage(responseData.pagination.page + 1);
    } catch (error: any) {
      ErrorNotification(error.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      setLoading(false);
    }
  };

  const handleScroll = async (e: any, documentId: number) => {
    const bottom =
      Math.abs(
        Math.floor(e.target.scrollHeight - e.target.scrollTop) -
          Math.floor(e.target.clientHeight)
      ) < 2;
    if (e.target.scrollTop === 0) return;

    if (bottom) {
      await getProjectAlls(documentId);
    }
  };

  const cancelModalCM0027 = () => {
    dispatch(setVisibleCM0027(false));
    showOffsetColumn(false);
  };

  const openCM0027_2 = (value: any) => {
    dispatch(setIsVisibleCM0027_2(true));
    dispatch(
      setParentCodeCM0027_2({
        documentId: documentId,
        companyId: value?.company?.id,
        categoryId: value?.categoryId,
      })
    );
  };

  const openCM0027_1 = (value: any) => {
    dispatch(setIsVisibleCM0027_1(true));
    dispatch(
      setParentCodeCM0027_1({
        categoryId: value.categoryId,
        documentProjectOutsourceCostConstructionSummaryDataId: value.id,
      })
    );
    dispatch(
      setHeaderDataCM0027_1({
        traderName: value?.company?.name ?? "",
        contractWorkName: value?.subConstructionName ?? "",
        targetPeriod: helpers.toTargetPeriodFormat(
          value.startDate,
          value.endDate
        ),
      })
    );
  };
  const setProjectExpandKeys = (key: any) => {
    const checkKey = checkProjectKeyExpand(key);
    if (checkKey) {
      const newExpandKey = expandProjectKey.filter(
        (keyExpand: any) => keyExpand !== key
      );
      setExpandProjectKey(newExpandKey);
    } else {
      setExpandProjectKey([...expandProjectKey, key]);
    }
  };

  const checkProjectKeyExpand = (key: any) => {
    if (!key || !expandProjectKey) return false;
    return expandProjectKey.some((element: any) => element === key);
  };

  const setCompanyExpandKeys = (key: any) => {
    const checkKey = checkCompanyKeyExpand(key);
    if (checkKey) {
      const newExpandKey = expandCompanyKey.filter(
        (keyExpand: any) => keyExpand !== key
      );
      setExpandCompanyKey(newExpandKey);
    } else {
      setExpandCompanyKey([...expandCompanyKey, key]);
    }
  };

  const checkCompanyKeyExpand = (key: any) => {
    if (!key || !expandCompanyKey) return false;
    return expandCompanyKey.some((element: any) => element === key);
  };
  const putReSummaryProjectOutsourceCostRetrieve = async (
    data: ReSummaryCompanyProjectOutsourceCostRetrievePayload,
    afterSuccess?: () => void
  ): Promise<void> => {
    ConfirmModal({
      onOk: async () => {
        try {
          setLoading(true);
          const responseData =
            await service.putReSummaryProjectOutsourceCostRetrieve(data);
          if (responseData) {
            SuccessNotification(
              responseData.message ?? NOTIFICATION_TITLE.SUCCESS
            );
            dispatch(resetCM0027());
            if (afterSuccess) afterSuccess();
          }
        } catch (error: any) {
          ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        } finally {
          setLoading(false);
        }
      },
      className: "confirm__modal",
      title: MESSAGE.MESSAGE_TITLE_CM016,
      description: MESSAGE.MESSAGE_DESCRIPTION_CR_2,
      extraDescription: MESSAGE.MESSAGE_DESCRIPTION_COMMON_QUESTION_1,
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_MODAL,
      isCenterWithoutMenu: true,
    });
  };

  const showOffsetColumn = (isShow: boolean) => {
    dispatch(setIsShowOffset(isShow));
  };

  const showConfirmApproveModal = () => {
    dispatch(showModalApproveConfirm());
  };
  return {
    outsourceCompanies,
    expandProjectKey,
    loading,
    expandCompanyKey,
    summaryData,
    projects,
    workflow,
    currentUserApproval,
    cancelModalCM0027,
    openCM0027_2,
    openCM0027_1,
    checkProjectKeyExpand,
    setProjectExpandKeys,
    checkCompanyKeyExpand,
    setCompanyExpandKeys,
    putReSummaryProjectOutsourceCostRetrieve,
    getReSummarySubmittedProjectOutsourceCostTotal,
    getProjectAlls,
    handleScroll,
    showOffsetColumn,
    getWorkflowSummaryOutsourceProjectApprovalProcess,
    showConfirmApproveModal,
  };
};

export default CM0029Handler;
