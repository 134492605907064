
import FilterApi from "../../../infrastructure/api/ApiFilter"
import FilterService from "../../../usecase/ServiceFilter"
import FilterHandler from "../../handler/HandlerFilter"
import FilterUI from "./ui/UI"


const FilterComponent = () => {
    const api = FilterApi()
    const service = FilterService(api)
    const handler = FilterHandler(service)
    return <FilterUI handler={handler} />
}

export default FilterComponent