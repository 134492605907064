import { Button, Pagination, Select } from "antd";
import "../../../assets/styles/components/pagination.css";
import LeftIcon from "../../../assets/icons/left.svg";
import RightIcon from "../../../assets/icons/right.svg";

import DoubleLeftIcon from "../../../assets/icons/double-left.svg";
import DoubleRightIcon from "../../../assets/icons/double-right.svg";
import type { PaginationProps } from "antd";
import { memo, useMemo } from "react";

type Props = {
    total: number;
    page: number;
    size: number;
    setPage: (value: number) => void;
    setSize: (value: number) => void;
    disabled?: boolean;
    sizeLabelPage?: string;
    sizeLabelSize?: string;
    rangeLabel?: string
}

const { Option } = Select;

const sizeOptions: number[] = [10,
    20,
    50,
    100]

const PaginationComponentOM = ({
    total,
    page,
    size,
    setPage,
    setSize,
    disabled,
    sizeLabelSize,
    sizeLabelPage,
    rangeLabel
}: Props) => {
    const itemRender: PaginationProps["itemRender"] = (
        value,
        type,
        originalElement
    ) => {
        if (type === "prev") return <div className="page-style center-item" style={{ paddingTop: 3 }} >
            <img style={{ width: 8, height: 8 }} alt="" src={LeftIcon} />
        </div>;
        if (type === "next") return <div className="page-style center-item" style={{ paddingTop: 3 }}> <img alt="" style={{ width: 8, height: 8, }} src={RightIcon} /></div>;
        return originalElement;
    };

    const lastPage = useMemo(() => {
        const result = Math.floor(total / size)
        if (result < 1) return 1
        if (total % size > 0) return result + 1
        return result;
    }, [total, size]);

    return (
        <div className="pagination-component-list om-paging">
            <div className="pagination-container  w-100-percent">
                {/* total */}
                <div className="pagination-total" style={{
                    marginTop: 7
                }}>
                    <span className="size-text">
                        {(page - 1) * size +
                            1 +
                            "-" +
                            (page * size > total ? total : page * size) +
                            "/" +
                            total}
                        {rangeLabel ?? "件"}
                    </span>
                </div>
                {/* page */}
                {
                    total - size > 0 ?
                        <div style={{ display: "flex" }}>

                            <Button
                                style={{ marginRight: 8 }}
                                className="double-page "
                                disabled={page === 1 || (disabled ?? false)}
                                onClick={() => setPage(1)}
                                icon={
                                    <img
                                        alt=""
                                        src={DoubleLeftIcon}
                                    />
                                }
                            ></Button>
                            <Pagination
                                disabled={disabled ?? false}
                                showSizeChanger={false}
                                itemRender={itemRender}
                                onChange={setPage}
                                current={page}
                                pageSize={size}
                                total={total}
                            />
                            <Button
                                disabled={page === lastPage || (disabled ?? false)}
                                onClick={() => setPage(lastPage)}
                                style={{ marginLeft: 8 }}
                                className="double-page "
                                icon={
                                    <img
                                        alt=""
                                        src={DoubleRightIcon}
                                    />
                                }
                            ></Button>
                        </div> : <></>
                }

                {/* size */}
                <div style={{ marginTop: 7 }}>
                    <Select
                        disabled={disabled ?? false}
                        className="pagination-size"
                        dropdownMatchSelectWidth={false}
                        placement={"topRight"}
                        value={size}
                        onChange={setSize}
                    >
                        {
                            sizeOptions.map((element: number) => <Option key={element} value={element}>{`${element}${sizeLabelPage ?? "件"}/1${sizeLabelSize ?? "ページ"}`}</Option>
                            )
                        }
                    </Select>
                </div>
            </div>
        </div>
    );
};
export default memo(PaginationComponentOM);
