import { DoubleCheckAPIImpl } from "./ServiceImpl";

const DoubleCheckService = (api: DoubleCheckAPIImpl) => {
  const postAttendanceSubConstructionMemberDoubleCheck = async (data: {
    constructionId: number;
    doubleCheck: null | boolean;
    userTimeSheetId: number;
  }): Promise<any> => {
    try {
      const response = await api.postAttendanceSubConstructionMemberDoubleCheck(
        data
      );
      return response.data;
    } catch (error: any) {
      throw error;
    }
  };

  return { postAttendanceSubConstructionMemberDoubleCheck };
};

export default DoubleCheckService;
