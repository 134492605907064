import ShowIcon1 from '../../../../../assets/icons/show-icon-1.svg';
import DeleteIcon2 from '../../../../../assets/icons/delete-icon-2.svg';
import DeleteIcon1 from '../../../../../assets/icons/delete-button-icon.svg';
import WhiteEyeSvg from '../../../../../assets/icons/white-eye.svg';

import { Button } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import {
    AUTHORIZATION_CODE,
    SYSTEM_ROLES,
} from '../../../../../common/constants/AUTHORIZATION';
interface Props {
    group: any;
    isChoosen: boolean;

    viewAction: (value: any) => void;
    deleteAction: (value: any) => void;
    chooseGroup: (value: any) => void;
}

const GroupItem = ({ group, viewAction, deleteAction, chooseGroup, isChoosen = false }: Props) => {
    const undefinedGroupId = useSelector((state: RootState) => state.om002.undefinedGroupId);
    const permission = useSelector(
        (state: RootState) => state.auth.authData?.permission
    );
    const isRoleAdmin = useSelector((state: RootState) => state.auth?.authData?.isRoleAdmin)

    const isRoleAdminSystem =
        useSelector((state: RootState) => state.auth?.authData?.isRoleAdminSystem)


    return (
        <div
            className={`group-item btn-group choosen-group-btn ${isChoosen && 'choosen-group'}`}
            style={{ display: 'flex' }}
            onClick={() => chooseGroup(group)}
        >
            <div className="center-vertical text-eliipsis">{group.name}</div>

            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <Button
                    className={`button-fcfcfc `}
                    style={{ border: 'none' }}
                    icon={<img alt="" src={isChoosen ? WhiteEyeSvg : ShowIcon1} />}
                    onClick={(event: any) => {
                        event.stopPropagation();
                        viewAction(group);
                    }}
                ></Button>
                {((group.id !== 0 && group.id !== undefinedGroupId) && (isRoleAdmin || isRoleAdminSystem || permission?.includes(AUTHORIZATION_CODE.M12))) ? (
                    <Button
                        className="button-fcfcfc"
                        style={{ border: 'none', marginLeft: 10 }}
                        icon={<img alt="" src={isChoosen ? DeleteIcon2 : DeleteIcon1} />}
                        onClick={(event: any) => {
                            event.stopPropagation();
                            deleteAction(group);
                        }}
                    ></Button>
                ) : (
                    <div style={{ width: 42 }}></div>
                )}
            </div>
        </div>
    );
};
export default GroupItem;
