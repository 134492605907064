import CM0025Api from './infrastructure/api/Api';
import CM0025Handler from './presenter/handler/Handler';
import Service from './usecase/Service';
import MainUI from './presenter/ui/UI';

import '../../../assets/styles/CM/cm024.css';
import '../../../assets/styles/CM/cm3854.css';
import '../../../assets/styles/CM/cm047.css';

type props = {
    onSubmit?: () => void;
    onReject?: () => void;
    afterSuccess?: () => void;
};

const CM0025Component = (props: props) => {
    const api = CM0025Api();
    const service = Service(api);
    const handler = CM0025Handler(service);
    return <MainUI handler={handler} {...props} />;
};

export default CM0025Component;
