import { createSlice } from '@reduxjs/toolkit';
import { CM047SliceType } from '../../entity/Entity';

export const initialState: CM047SliceType = {
    loading: false,
    selectedRow: [],
};

export const CM0047lice = createSlice({
    name: 'cm047',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setSelectedRowCM0047: (state, action) => {
            state.selectedRow = action.payload;
        },
    },
});

export const { setLoading, setSelectedRowCM0047 } = CM0047lice.actions;

export default CM0047lice.reducer;
