import { HistoryApproval } from "../../entity/Entity";
import StepIcon from "../../../../../assets/icons/step.svg";
import StepEndIcon from "../../../../../assets/icons/step-end.svg";
import TooltipText from "../../../../../common/components/tooltip-text/TooltipText";
import IconSubmitter from "../../../../../assets/icons/icon-submit.svg";

import { Divider } from "antd";
import React from "react";

export const StepComponent = (props: {
  data: HistoryApproval;
  isEnd: boolean;
  index: number;
}) => {
  const { data, isEnd, index } = props;

  const StepElement = () => (
    <div>
      <section className="title">
        {data.isSubmitter && <img src={IconSubmitter} />}&nbsp; {data.title}
      </section>
      <section className="content">{data.date}</section>
      <section className="content" style={{ maxWidth: 520 }}>
        {data.note}
      </section>
    </div>
  );

  return isEnd ? (
    <section className="step-end">
      <div className="image">
        <div className="mile-stone"></div>
      </div>
      <StepElement />
    </section>
  ) : index == 0 ? (
    <React.Fragment key={`${data?.id}-${data.priority}`}></React.Fragment>
  ) : (
    <section className="step">
      <div className="image">
        <div className="mile-stone"></div>
        <div className="mile-stone-stick"></div>
      </div>
      <StepElement />
    </section>
  );
};
