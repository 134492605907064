import { Col, Row } from 'antd';
import helpers from '../../../../../common/helpers/common';
import { InputNumberComponent } from './InputNumber';
import { PlanEntity, Subscription } from '../../entity/Entity';
import React from 'react';

type props = {
    isEditing: boolean;
    subscription?: Subscription[];
};

export const SubscriptionComponent = ({ isEditing, subscription }: props) => {
    return (
        <>
            <section className="ord007-header-style" style={{ marginTop: 8 }}>
                オプション機能　基本料金
            </section>
            {subscription?.map((element) => (
                <section style={{ width: '100%', display: 'flex' }} key={element.id}>
                    <div className="center-item ord007-cell-container ord007-border-left half-width">
                        {element.name}
                    </div>
                    <div className="center-item ord007-cell-container half-width">
                        {isEditing ? (
                            <>
                                <InputNumberComponent
                                    name={['subscription', `element${element.id}`]}
                                    max={99999}
                                    isRequired={true}
                                />
                                <div style={{ color: '#666666', marginLeft: 8 }}>円</div>
                            </>
                        ) : (
                            `${helpers.formatCurrency(element.cost) + '円'}`
                        )}
                    </div>
                </section>
            ))}
        </>
    );
};
