import React, { useEffect, useMemo } from 'react';
import { HandlerImpl } from '../handler/HandlerImpl';
import TableView from './Table';
import CM0027Component from '../../../../CM0027';
import CM0025Component from '../../../../CM0025';
import CM0029Component from '../../../../CM0029';
import CM0031Component from '../../../../CM0031';
import CM0033Component from '../../../../CM0033';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import CM0021Component from '../../../../CM0021';
import CM0023Component from '../../../../CM0023';
import { setVisibleCM0021 } from '../../../../CM0021/presenter/slice/Slice';
import { setVisibleCM0023 } from '../../../../CM0023/presenter/slice/Slice';
import { setVisibleCM0025 } from '../../../../CM0025/presenter/slice/Slice';
import { setVisibleCM0027 } from '../../../../CM0027/presenter/slice/Slice';
import { setVisibleCM0029 } from '../../../../CM0029/presenter/slice/Slice';
import { setVisibleCM0031 } from '../../../../CM0031/presenter/slice/Slice';
import { setVisibleCM0033 } from '../../../../CM0033/presenter/slice/Slice';
import ModalRejectView from '../../../../../../common/components/modal-reject/ModalRejectView';
import { TYPE_TABLE } from '../handler/Handler';

interface props {
    handler: HandlerImpl;
}

const MainUI = ({ handler }: props) => {
    const dispatch = useDispatch();
    const visibleCM0021 = useSelector((state: RootState) => state.cm0021.isVisibleCM0021);
    const visibleCM0023 = useSelector((state: RootState) => state.cm0023.isVisibleCM0023);
    const visibleCM0025 = useSelector((state: RootState) => state.cm0025.isVisibleCM0025);
    const visibleCM0027 = useSelector((state: RootState) => state.cm0027.isVisibleCM0027);
    const visibleCM0029 = useSelector((state: RootState) => state.cm0029.isVisibleCM0029);
    const visibleCM0031 = useSelector((state: RootState) => state.cm0031.isVisibleCM0031);
    const visibleCM0033 = useSelector((state: RootState) => state.cm0033.isVisibleCM0033);
    const refreshCM0031 = useSelector((state: RootState) => state.cm0031.refresh);
    const refreshCM0033 = useSelector((state: RootState) => state.cm0033.isRefresh);

    useEffect(() => {
        return () => {
            dispatch(setVisibleCM0021(false));
            dispatch(setVisibleCM0023(false));
            dispatch(setVisibleCM0025(false));
            dispatch(setVisibleCM0027(false));
            dispatch(setVisibleCM0029(false));
            dispatch(setVisibleCM0031(false));
            dispatch(setVisibleCM0033(false));
        };
    }, []);

    useEffect(() => {
        handler.getMaterialSubmittedList();
    }, [handler.refresh.material]);

    useEffect(() => {
        handler.getOutsourceSubmittedList();
    }, [handler.refresh.outsource]);

    useEffect(() => {
        handler.getLaborSubmittedList();
    }, [handler.refresh.labor]);

    useEffect(() => {
        handler.getIndirectSubmittedList();
    }, [refreshCM0031]);

    useEffect(() => {
        handler.getReceiveMoneySubmittedList();
    }, [refreshCM0033]);

    const dataMaterial = useMemo(() => {
        return handler.handleDataView(
            handler.data.material,
            handler.sortType1,
            handler.status,
            handler.from,
            handler.to,
            handler.keyword,
            handler.clear,
        );
    }, [
        handler.data.material,
        handler.from,
        handler.to,
        handler.sortType1,
        handler.clear,
        handler.status,
        handler.keyword,
    ]);

    const dataOutsource = useMemo(() => {
        return handler.handleDataView(
            handler.data.outsource,
            handler.sortType2,
            handler.status,
            handler.from,
            handler.to,
            handler.keyword,
            handler.clear,
        );
    }, [
        handler.data.outsource,
        handler.from,
        handler.to,
        handler.sortType2,
        handler.clear,
        handler.status,
        handler.keyword,
    ]);

    const dataLabor = useMemo(() => {
        return handler.handleDataView(
            handler.data.labor,
            handler.sortType3,
            handler.status,
            handler.from,
            handler.to,
            handler.keyword,
            handler.clear,
        );
    }, [
        handler.data.labor,
        handler.from,
        handler.to,
        handler.sortType3,
        handler.clear,
        handler.status,
        handler.keyword,
    ]);

    const dataIndirect = useMemo(() => {
        return handler.handleDataView(
            handler.data.indirect,
            handler.sortType4,
            handler.status,
            handler.from,
            handler.to,
            handler.keyword,
            handler.clear,
        );
    }, [
        handler.data.indirect,
        handler.from,
        handler.to,
        handler.sortType4,
        handler.clear,
        handler.status,
        handler.keyword,
    ]);

    const dataReceive = useMemo(() => {
        return handler.handleDataView(
            handler.data.receive,
            handler.sortType5,
            handler.status,
            handler.from,
            handler.to,
            handler.keyword,
            handler.clear,
        );
    }, [
        handler.data.receive,
        handler.from,
        handler.to,
        handler.sortType5,
        handler.clear,
        handler.status,
        handler.keyword,
    ]);

    return (
        <div className="cm042">
            {visibleCM0021 ? (
                <CM0021Component
                    afterSuccess={() => handler.funcAfterSuccess(TYPE_TABLE.MATERIAL_COST)}
                />
            ) : null}
            {visibleCM0023 ? (
                <CM0023Component
                    afterSuccess={() => handler.funcAfterSuccess(TYPE_TABLE.MATERIAL_COST)}
                />
            ) : null}
            {visibleCM0025 ? (
                <CM0025Component
                    afterSuccess={() => handler.funcAfterSuccess(TYPE_TABLE.OUTSOURCE_COST)}
                />
            ) : null}
            {visibleCM0027 ? (
                <CM0027Component
                    afterSuccess={() => handler.funcAfterSuccess(TYPE_TABLE.OUTSOURCE_COST)}
                />
            ) : null}
            {visibleCM0029 ? (
                <CM0029Component
                    afterSuccess={() => handler.funcAfterSuccess(TYPE_TABLE.LABOR_COST)}
                />
            ) : null}
            {visibleCM0031 ? <CM0031Component /> : null}
            {visibleCM0033 ? <CM0033Component /> : null}
            <ModalRejectView
                visible={handler.visibleRejectView}
                funcClose={() => handler.setVisibleRejectView(false)}
                rejectReason={handler.rejectReason}
            />
            <TableView
                title="材料費"
                data={dataMaterial}
                sortType={handler.sortType1}
                funcSort={(sortType: string) => handler.funcSort('table1', sortType)}
                onClickDocument={handler.funcClickDocumentMaterialCost}
                type="material"
                handleViewRejectReason={(reason: string) => {
                    handler.setRejectReason(reason);
                    handler.setVisibleRejectView(true);
                }}
            />
            <TableView
                title="外注費"
                sortType={handler.sortType2}
                data={dataOutsource}
                funcSort={(sortType: string) => handler.funcSort('table2', sortType)}
                onClickDocument={handler.funcClickDocumentOutsourcingCost}
                type="outsource"
                handleViewRejectReason={(reason: string) => {
                    handler.setRejectReason(reason);
                    handler.setVisibleRejectView(true);
                }}
            />
            <TableView
                title="労務費"
                data={dataLabor}
                sortType={handler.sortType3}
                funcSort={(sortType: string) => handler.funcSort('table3', sortType)}
                onClickDocument={handler.funcClickDocumentLaborCost}
                type="labor"
                handleViewRejectReason={(reason: string) => {
                    handler.setRejectReason(reason);
                    handler.setVisibleRejectView(true);
                }}
            />
            <TableView
                title="間接工事費"
                data={dataIndirect}
                sortType={handler.sortType4}
                funcSort={(sortType: string) => handler.funcSort('table4', sortType)}
                onClickDocument={handler.funcClickDocumentIndirect}
                type="indirect"
                handleViewRejectReason={(reason: string) => {
                    handler.setRejectReason(reason);
                    handler.setVisibleRejectView(true);
                }}
            />
            <TableView
                title="入金額"
                data={dataReceive}
                sortType={handler.sortType5}
                funcSort={(sortType: string) => handler.funcSort('table5', sortType)}
                onClickDocument={handler.funcClickDocumentBillingAmount}
                type="receive"
                handleViewRejectReason={(reason: string) => {
                    handler.setRejectReason(reason);
                    handler.setVisibleRejectView(true);
                }}
            />
        </div>
    );
};

export default MainUI;
