import { OM008Api } from "./ServiceImpl";

const OM008Service = (api: OM008Api) => {
  const updateGroup = async (data: any): Promise<any> => {
    try {
      return await api.updateGroup(data);
    } catch (error) {
      throw error;
    }
  };
  return { updateGroup };
};

export default OM008Service;
