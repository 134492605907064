import { createSlice } from "@reduxjs/toolkit";
import {
  AUTHORIZATION_CODE,
  PAGE_AUTHORIZATION,
} from "../constants/AUTHORIZATION";
import { ORDER_STATUS, PLAN_TYPE } from "../constants/COMMON";
import { hasSomeElements } from "../../router/AuthorizationRouter";
import { MENU_ITEM } from "../../pages/CM/CM034-CM53/wrapper/presenter/handler/Handler";

type MenuItem = {
  label: string;
  key: number;
  icon?: string;
  children?: any[];
  disabled?: boolean;
};

type CommonSliceEntity = {
  loading: number;
  refreshNotification: boolean;
  scroll: number;
  menuItems: any[];
};

const initialState: CommonSliceEntity = {
  loading: 0,
  refreshNotification: false,
  scroll: 0,
  menuItems: [],
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      if (action.payload) state.loading += 1;
      else state.loading -= 1;
    },

    setRefreshNotification: (state, action) => {
      state.refreshNotification = action.payload;
    },
    setScroll: (state) => {
      state.scroll += Math.random();
    },
    setMenuItems: (state, action) => {
      const permissions = action.payload?.permission ?? [];

      const isRoleAdmin = action.payload?.isRoleAdmin;

      const isRoleAdminBasic = action.payload?.isRoleAdminBasic;

      const order = action.payload?.order?.code;

      const isOrderBasic = order === PLAN_TYPE.PLAN_BASIC;

      const isDoubleCheck = action.payload?.isDoubleCheck;

      const isReWorkflow = action.payload?.isReWorkflow;

      const isWorkFlowUserIsInWorkFlows =
        action.payload?.isWorkFlowUserIsInWorkFlows;

      const isUserInWorkspace = action.payload?.isUserInWorkspace;

      const isUserIsProjectManager = action.payload?.isUserIsProjectManager;

      const isVerifiedCompany = action.payload?.isVerifiedCompany;


      const reCostWorkflow = action.payload?.reCostWorkflow;
      const reReceiveMoneyWorkflow = action.payload?.reReceiveMoneyWorkflow;
      const reSummaryCompanyOutsourceWorkflow =
        action.payload?.reSummaryCompanyOutsourceWorkflow;
      const reSummaryInternalWorkflow =
        action.payload?.reSummaryInternalWorkflow;

      const subscription = action.payload?.order?.subscription ?? [];

      const roleCCUS = isVerifiedCompany && (isUserIsProjectManager || isRoleAdmin)

      const showCCUSMenu = process.env.REACT_APP_SHOW_CCUS_MENU === '1';

      /* ticket pending */
      let isSubB6,
        isSubM17,
        isSubM18,
        isSubM19 = false;

      for (let element of subscription) {
        if (element.code === AUTHORIZATION_CODE.B6) isSubB6 = true;
        else if (
          element.code === AUTHORIZATION_CODE.M17 &&
          element?.status?.code === ORDER_STATUS.ACTIVE_ORDER
        )
          isSubM17 = true;
        else if (
          element.code === AUTHORIZATION_CODE.M18 &&
          element?.status?.code === ORDER_STATUS.ACTIVE_ORDER
        )
          isSubM18 = true;
        else if (
          element.code === AUTHORIZATION_CODE.M19 &&
          element?.status?.code === ORDER_STATUS.ACTIVE_ORDER
        )
          isSubM19 = true;
      }

      const getDataForTab1 = () => {
        let tab1 = [];
        const subscriptionCodeArr = subscription.map((item: any) => item.code);
        if (
          (subscriptionCodeArr?.includes(AUTHORIZATION_CODE.M17) &&
            (isRoleAdmin || isUserIsProjectManager)) ||
          permissions?.includes(AUTHORIZATION_CODE.S49)
        )
          tab1.push(MENU_ITEM[0], MENU_ITEM[1]);

        if (
          (subscriptionCodeArr?.includes(AUTHORIZATION_CODE.M18) &&
            isRoleAdmin) ||
          permissions?.includes(AUTHORIZATION_CODE.S45)
        )
          tab1.push(MENU_ITEM[2]);

        if (
          (subscriptionCodeArr?.includes(AUTHORIZATION_CODE.M19) &&
            isRoleAdmin) ||
          permissions?.includes(AUTHORIZATION_CODE.S47)
        )
          tab1.push(MENU_ITEM[3]);
        return tab1;
      };

      const getDataForTab2 = () => {
        let tab2 = [];
        const subscriptionCodeArr = subscription.map((item: any) => item.code);
        if (
          (subscriptionCodeArr?.includes(AUTHORIZATION_CODE.M17) &&
            isRoleAdmin) ||
          reReceiveMoneyWorkflow
        )
          tab2.push(MENU_ITEM[0]);

        if (
          (subscriptionCodeArr?.includes(AUTHORIZATION_CODE.M17) &&
            isRoleAdmin) ||
          reCostWorkflow
        )
          tab2.push(MENU_ITEM[1]);

        if (
          (subscriptionCodeArr?.includes(AUTHORIZATION_CODE.M18) &&
            isRoleAdmin) ||
          reSummaryCompanyOutsourceWorkflow
        )
          tab2.push(MENU_ITEM[2]);

        if (
          (subscriptionCodeArr?.includes(AUTHORIZATION_CODE.M19) &&
            isRoleAdmin) ||
          reSummaryInternalWorkflow
        )
          tab2.push(MENU_ITEM[3]);
        return tab2;
      };

      const tab1Data = getDataForTab1();
      const tab2Data = getDataForTab2();

      /* 1 */

      const MENU_ITEM_1: MenuItem | null =
        isSubB6 && permissions.includes(AUTHORIZATION_CODE.B6)
          ? {
            label: "ダッシュボード",
            key: 1,
            icon: "CategoryWhite",
          }
          : null;

      /* 2 */
      const MENU_ITEM_2_1: MenuItem | null = {
        label: "案件管理",
        key: 81,
        icon: "FolderWhite",
      };

      const MENU_ITEM_2_2: MenuItem | null = permissions?.includes(
        AUTHORIZATION_CODE.M7
      )
        ? {
          label: "協力会社管理",
          key: 82,
          icon: "KeyWhite",
        }
        : null;

      /* 3 */
      const MENU_ITEM_3: MenuItem | null = isOrderBasic
        ? //  || permissions.includes(AUTHORIZATION_CODE.S620)
        {
          label: "スケジュール",
          key: 3,
          icon: "CalendarWhite",
        }
        : null;

      /* 4 */
      const MENU_ITEM_4_1: MenuItem | null =
        isDoubleCheck || (isRoleAdmin && isOrderBasic)
          ? {
            label: "メンバー勤務チェック",
            key: 21,
          }
          : null;
      const MENU_ITEM_4_2: MenuItem | null =
        permissions.includes(AUTHORIZATION_CODE.M9) ||
          permissions.includes(AUTHORIZATION_CODE.B13)
          ? {
            label: "会社別勤務実績",
            key: 22,
          }
          : null;
      const MENU_ITEM_4_3: MenuItem | null =
        permissions.includes(AUTHORIZATION_CODE.M9) ||
          permissions.includes(AUTHORIZATION_CODE.B13)
          ? {
            label: "案件別勤務実績",
            key: 23,
          }
          : null;
      const MENU_ITEM_4_4: MenuItem | null =
        permissions.includes(AUTHORIZATION_CODE.M10) ||
          permissions.includes(AUTHORIZATION_CODE.B11)
          ? {
            label: "自社勤務実績",
            key: 24,
          }
          : null;
      const MENU_ITEM_4_5: MenuItem | null = {
        label: "個人勤務実績",
        key: 25,
      };
      const MENU_ITEM_4_6: MenuItem | null = {
        label: "申請一覧",
        key: 26,
      };
      const MENU_ITEM_4_CHILDREN = [
        MENU_ITEM_4_5,
        MENU_ITEM_4_6,
        MENU_ITEM_4_1,
        MENU_ITEM_4_2,
        MENU_ITEM_4_3,
        MENU_ITEM_4_4,
      ].filter((element) => element !== null);
      const MENU_ITEM_4: MenuItem | null =
        MENU_ITEM_4_CHILDREN.length === 0
          ? null
          : {
            label: "勤怠管理",
            key: 2,
            icon: "DocumentWhite",
            children: MENU_ITEM_4_CHILDREN,
          };

      /* 6 */
      const MENU_ITEM_6_1: MenuItem | null = {
        label: "オーダー管理",
        key: 61,
      };
      const MENU_ITEM_6_2: MenuItem | null = {
        label: "プラン設定",
        key: 62,
      };

      const MENU_ITEM_6: MenuItem | null =
        permissions?.includes(AUTHORIZATION_CODE.B16) && isRoleAdmin
          ? {
            label: "販売管理",
            key: 6,
            icon: "Budget",
            children: [MENU_ITEM_6_1, MENU_ITEM_6_2].filter(
              (element) => element !== null
            ),
          }
          : null;

      /* 7 */
      const MENU_ITEM_7_1: MenuItem | null =
        (isOrderBasic &&
          !!isSubM17 &&
          (permissions.includes(AUTHORIZATION_CODE.S42) ||
            permissions.includes(AUTHORIZATION_CODE.S28) ||
            isRoleAdmin ||
            isUserIsProjectManager)) ||
          (!isOrderBasic &&
            (permissions.includes(AUTHORIZATION_CODE.S42) ||
              permissions.includes(AUTHORIZATION_CODE.S28)) &&
            (isRoleAdmin || isUserIsProjectManager))
          ? {
            label: "案件別収支管理",
            key: 51,
          }
          : null;
      const MENU_ITEM_7_2: MenuItem | null =
        isOrderBasic &&
          !!isSubM18 &&
          (permissions.includes(AUTHORIZATION_CODE.S43) || isRoleAdmin)
          ? {
            label: "工事代金支払明細書",
            key: 52,
          }
          : null;

      const MENU_ITEM_7_3: MenuItem | null =
        isOrderBasic &&
          !!isSubM19 &&
          (permissions.includes(AUTHORIZATION_CODE.S46) || isRoleAdmin)
          ? {
            label: "社内収支",
            key: 53,
          }
          : null;
      const MENU_ITEM_7_4: MenuItem | null =
        tab1Data?.length + tab2Data?.length > 0
          ? {
            label: "提出・承認",
            key: 54,
          }
          : null;
      const MENU_ITEM_7_CHILDREN = [
        MENU_ITEM_7_1,
        MENU_ITEM_7_2,
        MENU_ITEM_7_3,
        MENU_ITEM_7_4,
      ].filter((element) => element !== null);
      const MENU_ITEM_7: MenuItem | null =
        MENU_ITEM_7_CHILDREN.length !== 0
          ? {
            label: "収支管理",
            key: 5,
            icon: "GraphWhite",
            children: MENU_ITEM_7_CHILDREN,
          }
          : null;

      /* 8 */
      const MENU_ITEM_8_1: MenuItem | null =
        (isRoleAdmin && isOrderBasic) ||
          (isRoleAdmin && permissions.includes(AUTHORIZATION_CODE.S26))
          ? {
            label: "役職・権限",
            key: 71,
          }
          : null;
      const MENU_ITEM_8_2: MenuItem | null = {
        label: "部門・メンバー",
        key: 72,
      };
      const MENU_ITEM_8_3: MenuItem | null =
        isOrderBasic &&
          (permissions.includes(AUTHORIZATION_CODE.S500) ||
            permissions.includes(AUTHORIZATION_CODE.S501) ||
            permissions.includes(AUTHORIZATION_CODE.S502) ||
            isRoleAdmin)
          ? {
            label: "ワークフロー",
            key: 73,
          }
          : null;
      const MENU_ITEM_8_4: MenuItem | null = isRoleAdmin
        ? null
        : {
          label: "プロフィール",
          key: 74,
        };
      const MENU_ITEM_8_5: MenuItem | null =
        (permissions.includes(AUTHORIZATION_CODE.S52) && isRoleAdmin) ||
          (isRoleAdmin && isOrderBasic)
          ? {
            label: "ワークフローマスタ",
            key: 76,
          }
          : null;
      const MENU_ITEM_8_6: MenuItem | null =
        hasSomeElements(PAGE_AUTHORIZATION.SN01, permissions) && isOrderBasic
          ? {
            label: "通知設定",
            key: 75,
          }
          : null;
      const MENU_ITEM_8_7: MenuItem | null = {
        label: "会社情報",
        key: 77,
      };

      const MENU_ITEM_8_8: MenuItem | null =
        isRoleAdmin && isOrderBasic && subscription?.length > 0
          ? {
            label: "消費税率",
            key: 78,
          }
          : null;

      const MENU_ITEM_8_CHILDREN = [
        MENU_ITEM_8_4,
        MENU_ITEM_8_7,
        MENU_ITEM_8_1,
        MENU_ITEM_8_2,
        MENU_ITEM_8_3,
        MENU_ITEM_8_5,
        MENU_ITEM_8_8,
        MENU_ITEM_8_6,
      ].filter((element) => element !== null);
      const MENU_ITEM_8: MenuItem | null =
        MENU_ITEM_8_CHILDREN.length === 0
          ? null
          : {
            label: "設定",
            key: 7,
            icon: "SettingWhite",
            children: MENU_ITEM_8_CHILDREN,
          };
      // MENU CCUS
      const MENU_ITEM_9_1: MenuItem | null =
      {
        label: "事業者ID認証",
        key: 91,
        disabled: !isRoleAdmin
      }

      const MENU_ITEM_9_2: MenuItem | null =
      {
        label: "就業履歴情報登録",
        key: 92,
        disabled: !roleCCUS
      }


      const MENU_ITEM_9_3: MenuItem | null =
      {
        label: "就業履歴情報登録結果",
        key: 93,
        disabled: !roleCCUS
      }

      const MENU_ITEM_9_4: MenuItem | null =
      {
        label: "技能者情報閲覧",
        key: 94,
        disabled: !(isVerifiedCompany && isRoleAdmin)
      }

      const MENU_ITEM_9_CHILDREN = [
        MENU_ITEM_9_1,
        MENU_ITEM_9_2,
        MENU_ITEM_9_3,
        MENU_ITEM_9_4,
      ]
      const MENU_ITEM_9: MenuItem | null =
        showCCUSMenu && (isOrderBasic && (isUserIsProjectManager || isRoleAdmin)) ? {
          label: "建設キャリアアップシステム",
          key: 9,
          icon: "CCUS",
          children: MENU_ITEM_9_CHILDREN,
        } : null

      state.menuItems = [
        MENU_ITEM_1,
        MENU_ITEM_2_1,
        MENU_ITEM_2_2,
        MENU_ITEM_4,
        MENU_ITEM_7,
        MENU_ITEM_3,
        MENU_ITEM_6,
        MENU_ITEM_8,
        MENU_ITEM_9,
      ];
    },
  },
});

export const { setLoading, setRefreshNotification, setScroll, setMenuItems } =
  commonSlice.actions;

export const commonReducer = commonSlice.reducer;
