import React from 'react';

import { Handler } from '../handler/HandlerImpl';
import type { ColumnsType } from 'antd/es/table';
import { Table } from 'antd';
import { DataTableType } from '../../entity/Entity';

interface props {
    handler: Handler;
}

const columns: ColumnsType<DataTableType> = [
    {
        title: '',
        dataIndex: 'item',
        key: 'item',
        width: 195,
        render: (text) => {
            return <div style={{ color: '#222222' }}>{text}</div>;
        },
    },
    {
        title: '予算',
        dataIndex: 'budget',
        key: 'budget',
        width: 195,
        align: 'center',
        render: (text) => {
            return <div style={{ color: '#222222' }}>{text}</div>;
        },
    },
    {
        title: '稼働原価',
        dataIndex: 'operatingCost',
        key: 'operatingCost',
        width: 195,
        align: 'center',
        render: (text) => {
            return <div style={{ color: '#222222' }}>{text}</div>;
        },
    },
    {
        title: '予算残高',
        dataIndex: 'budgetBalance',
        key: 'budgetBalance',
        width: 195,
        align: 'center',
        render: (text) => {
            return <div style={{ color: '#222222' }}>{text}</div>;
        },
    },
    {
        title: '予算消化率(％)',
        dataIndex: 'rateBudget',
        key: 'rateBudget',
        width: 195,
        align: 'center',
        render: (text) => {
            return <div style={{ color: '#222222' }}>{text}</div>;
        },
    },
];

const TableComponent = ({ handler }: props) => {
    return (
        <div className="db004-table">
            <Table
                columns={columns}
                dataSource={handler.dataTable}
                pagination={false}
                bordered={true}
                size="large"
            />
        </div>
    );
};

export default TableComponent;
