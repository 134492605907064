//Component
import { Button } from 'antd';

// Media
import '../../../../../assets/styles/AOP/AOP001/header.css';
import EditIcon from '../../../../../assets/images/aop-edit-icon.svg';
import { AOP004Handler } from '../handler/HandlerImpl';
import { SYSTEM_ROLES } from '../../../../../common/constants/AUTHORIZATION';

type props = {
    handler: AOP004Handler;
    roleId: string;
    roleName: string;
};

const HeaderUnit = ({ handler, roleId, roleName }: props) => {

    return (
        <div className="aop--001__header-unit">
            <div className="aop--001__header-title">役職・権限詳細</div>
            {handler.role !== SYSTEM_ROLES.ADMIN ? (
                <Button
                    className="aop--001__header-action"
                    onClick={() => handler.redirectAOP003(roleId, roleName)}
                >
                    <img src={EditIcon} alt="action-edit" />
                    <div className="aop--001__header-action--text">編集</div>
                </Button>
            ) : null}
        </div>
    );
};

export default HeaderUnit;
