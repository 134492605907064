import axiosConfig from "../../../../config/axiosConfig";
import { UserIsReWorkflow } from "../../entity/Entity";

export const API = () => {
  const getUserIsReWorkflow = async (): Promise<UserIsReWorkflow> => {
    const url = "/user/is-re-workflow";
    const response = await axiosConfig.get(url);
    return response.data?.results;
  };
  const getUserIsDoubleCheck = async (): Promise<boolean> => {
    const url = "/user/is-double-check";
    const response = await axiosConfig.get(url);
    return response.data?.results ? true : false;
  };
  const getProjectCheckUserInWorkspace = async (): Promise<boolean> => {
    const url = "/project/check-user-in-workspace";
    const response = await axiosConfig.get(url);
    return response.data?.results ? true : false;
  };
  const getUserIsProjectManager = async (): Promise<boolean> => {
    const url = "/user/is-project-manager";
    const response = await axiosConfig.get(url);
    return response.data?.results ? true : false;
  };
  const getWorkFlowUserIsInWorkFlows = async (): Promise<boolean> => {
    const url = "/workflow/user/is-in-workflows";
    const response = await axiosConfig.get(url);
    return response.data?.results ? true : false;
  };
  const getIsVerifiedCompany = async (): Promise<boolean> => {
    const url = "/ccus/is-verified-company";
    const response = await axiosConfig.get(url);
    return response.data?.results ? true : false;
  };
  return {
    getUserIsReWorkflow,
    getUserIsDoubleCheck,
    getProjectCheckUserInWorkspace,
    getUserIsProjectManager,
    getWorkFlowUserIsInWorkFlows,
    getIsVerifiedCompany
  };
};
