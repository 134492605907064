import ErrorNotification from "../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../common/constants/MESSAGE";
import { WorkflowResponseData } from "../../CM008/entity/Entity";
import { RetrieveLaborDocument } from "../entity/Entity";
import { CM0014Api } from "./ServiceImpl";

const CM0014Service = (api: CM0014Api) => {
  const getListSubmittedDirectCostLabor = async (params: any): Promise<any> => {
    try {
      return await api.getListSubmittedDirectCostLabor(params);
    } catch (error) {
      throw error;
    }
  };

  const getPresignLinkS3 = async (params: any): Promise<any> => {
    try {
      const response = await api.getPresignLinkS3(params);
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      throw error;
    }
  };

  const getProjectSummaryReceivedMoney = async (params: any): Promise<any> => {
    try {
      const response = await api.getProjectSummaryReceivedMoney(params);
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      throw error;
    }
  };

  const getConstructionSummaryCostInformation = async (
    params: any
  ): Promise<any> => {
    try {
      const response = await api.getConstructionSummaryCostInformation(params);
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      throw error;
    }
  };

  const getLaborListManager = async (params: any): Promise<any> => {
    try {
      const response = await api.getLaborListManager(params);
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      throw error;
    }
  };

  const getLaborList = async (params: any): Promise<any> => {
    try {
      const response = await api.getLaborList(params);
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      throw error;
    }
  };

  const retrieveLaborDocument = async (
    data: RetrieveLaborDocument
  ): Promise<any> => {
    try {
      const response = await api.retrieveLaborDocument(data);
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      throw error;
    }
  };
  const getWorkflowLaborApprovalProcess = async (params: {
    documentId: number;
  }): Promise<WorkflowResponseData> => {
    try {
      const response = await api.getWorkflowLaborApprovalProcess(params);
      return response.data;
    } catch (error: any) {
      throw error;
    }
  };
  return {
    getListSubmittedDirectCostLabor,
    getPresignLinkS3,
    getProjectSummaryReceivedMoney,
    getConstructionSummaryCostInformation,
    getLaborListManager,
    getLaborList,
    retrieveLaborDocument,
    getWorkflowLaborApprovalProcess,
  };
};

export default CM0014Service;
