import axiosConfig from '../../../../../config/axiosConfig';

const SM06Api = () => {
    const deleteSchedule = async (data: any): Promise<any> => {
        try {
            const url = '/schedule/delete';
            const response = await axiosConfig.delete(url, { data });
            return response?.data;
        } catch (error) {
            throw error;
        }
    };

    return {
        deleteSchedule,
    };
};

export default SM06Api;
