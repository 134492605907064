import React, { useMemo, useState } from "react";

import BreadcrumbComponent, {
  BreadcrumbData,
} from "../breadcrumb/BreadcrumbComponent";

import IconVersion from "../../../assets/icons/icon-version.svg";
import IconApproveRed from "../../../assets/icons/icon-approve-red.svg";
import DocumentRed from "../../../assets/icons/document-red.svg";
import DocumentBlue from "../../../assets/icons/icon-document-blue.svg";
import IconApproveGray from "../../../assets/icons/icon-approve-gray.svg";
import IconCircleRed from "../../../assets/icons/infor-circle.svg";
import IconCloseModal from "../../../assets/icons/icon-close-flow.svg";
import IconCheckGreen from "../../../assets/icons/akar-icons_circle-check-fill-green.svg";
import IconSubmitter from "../../../assets/icons/icon-submit.svg";
import IconSubmitterReject from "../../../assets/icons/submitter-reject.png";

import moment from "moment";
import COMMON from "../../constants/COMMON";
import Handler from "./Handler";
import { Button, Modal } from "antd";

export interface ItemApprove {
  approveDate: string;
  contact_address?: string;
  email?: string;
  id: number;
  isApprove: boolean;
  reject: boolean;
  position?: string;
  priority?: number;
  username: string;
  note: string;
  fullName: string;
}

interface ApprovalFlowProps {
  breadcrumb: BreadcrumbData[];
  listApprove: ItemApprove[];
  currentUserApproval: ItemApprove | null;
  isHiddenFirst?: boolean;
  isNotCheckingUser?: boolean;

  onClickBreadcrumb?: (callback: () => void) => void;
}

type ModalRejectView = {
  visible: boolean;
  reason: string;
  isReject: boolean;
  handleCancel(): void;
};

const ModalViewReject = ({
  visible,
  reason,
  isReject,
  handleCancel,
}: ModalRejectView) => {
  return (
    <Modal
      className="modal-approve"
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      closeIcon={<img alt="" src={IconCloseModal} />}
      maskClosable={false}
      centered
    >
      <>
        {isReject ? (
          <div className="modal-approve-header">
            <img alt="" src={IconCircleRed} />
            <h3>否認理由</h3>
          </div>
        ) : (
          <div className="modal-approve-header">
            <img alt="" src={IconCheckGreen} />
            <h3>コメント</h3>
          </div>
        )}
      </>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "22px",
          paddingLeft: "36px",
        }}
      >
        <div
          style={{
            color: "#666",
            width: "56px",
            fontWeight: 400,
            lineHeight: "22px",
          }}
        >
          {isReject ? "理由" : "コメント"}
        </div>
        <div
          style={{
            color: "#222",
            marginLeft: 0,
            wordBreak: "break-all",
            fontWeight: 400,
            lineHeight: "22px",
            width: "86%",
          }}
        >
          {reason}
        </div>
      </div>
      <div className="modal-approve-btn">
        <Button
          size="large"
          onClick={handleCancel}
          style={{ color: "#666", width: 118, fontWeight: 400 }}
        >
          キャンセル
        </Button>
      </div>
    </Modal>
  );
};

function ApprovalFlow({
  breadcrumb,
  listApprove,
  currentUserApproval,
  isHiddenFirst,
  isNotCheckingUser,
  onClickBreadcrumb,
}: ApprovalFlowProps) {
  const { reason, setReason, handleDataListApproval } = Handler();
  const listApproveView = useMemo(() => {
    return handleDataListApproval(listApprove, currentUserApproval);
  }, [listApprove, currentUserApproval]);

  const user = JSON.parse(localStorage.getItem("user") ?? "{}");

  const [isReject, setIsRecject] = useState<boolean>(false);

  const priorityReject = useMemo(() => {
    let priority = 0;
    for (const item of listApproveView)
      if (item.reject && item.priority > priority) priority = item.priority;

    return priority;
  }, [listApproveView]);

  return (
    <>
      <div className="content-header">
        <BreadcrumbComponent data={breadcrumb} onClick={onClickBreadcrumb} />
        <div className="content-header-version">
          {listApproveView?.map(
            (approval: ItemApprove | any, index: number) => {
              if (isHiddenFirst && index === 0)
                return (
                  <React.Fragment
                    key={`${approval?.id}-${approval.priority}`}
                  ></React.Fragment>
                );

              if (approval === "...") {
                return (
                  <div
                    key={`${approval?.id}-${approval.priority}`}
                    className="content-header-version-item"
                  >
                    <div className="content-header-version-item-title">...</div>
                    {listApproveView?.length !== index + 1 && (
                      <>
                        <img alt="" src={IconApproveGray} />
                      </>
                    )}
                  </div>
                );
              }

              const prevUser =
                listApprove[index - 1]?.reject ||
                listApprove[index - 1]?.isApprove;

              const isAuthenUser =
                currentUserApproval?.id === approval.id &&
                approval.id == user?.id;

              let icon = <img alt="" src={IconApproveGray} />;
              if (approval.reject || approval.priority < priorityReject) {
                icon = <img alt="" src={IconApproveRed} />;
              } else if (
                (isNotCheckingUser
                  ? approval.isApprove
                  : approval.isApprove ||
                    (isAuthenUser && (index === 0 || prevUser))) &&
                !(approval.priority < priorityReject)
              ) {
                icon = <img alt="" src={IconVersion} />;
              }
              return (
                <div
                  key={`${approval?.id}-${approval.priority}`}
                  className="content-header-version-item"
                >
                  {index !== (isHiddenFirst ? 1 : 0) && icon}
                  <div className="content-header-version-item-title">
                    <h3
                      className={`${
                        (isNotCheckingUser
                          ? approval.isApprove
                          : approval.isApprove ||
                            (isAuthenUser && (index === 0 || prevUser))) &&
                        "color-violet"
                      } ${
                        (approval.reject ||
                          approval.priority < priorityReject) &&
                        "color-red"
                      }`}
                    >
                      {approval?.isSubmitter &&
                        approval.isApprove &&
                        approval.priority >= priorityReject && (
                          <img src={IconSubmitter} />
                        )}
                      {approval?.isSubmitter &&
                        (approval.reject ||
                          approval.priority < priorityReject) && (
                          <img
                            src={IconSubmitterReject}
                            width={16}
                            height={16}
                          />
                        )}
                      &nbsp;
                      {approval?.fullName || approval?.username || ""}
                    </h3>
                    <span
                      className={`${approval.isApprove && "color-violet"} ${
                        (approval.reject ||
                          approval.priority < priorityReject) &&
                        "color-red"
                      }`}
                    >
                      {(approval.isApprove || approval.reject) &&
                      approval.approveDate
                        ? moment(approval.approveDate).format(
                            COMMON.FORMAT_DATE
                          )
                        : ""}{" "}
                      {(approval.reject ||
                        approval.priority < priorityReject) &&
                        approval.note.length != 0 && (
                          <div className="document-reject">
                            <img
                              alt=""
                              src={DocumentRed}
                              onClick={() => {
                                setReason(approval.note);
                                setIsRecject(approval.reject);
                              }}
                            />
                            {/* <div className="note-reject">
                                                    <p>{approval.note}</p>
                                                </div> */}
                          </div>
                        )}
                      {approval.isApprove &&
                        approval.priority >= priorityReject &&
                        approval.note.length != 0 && (
                          <div className="document-reject">
                            <img
                              alt=""
                              src={DocumentBlue}
                              onClick={() => {
                                setReason(approval.note);
                                setIsRecject(approval.reject);
                              }}
                            />
                          </div>
                        )}
                    </span>
                  </div>
                </div>
              );
            }
          )}
        </div>
      </div>
      <ModalViewReject
        visible={reason !== null}
        reason={reason ?? ""}
        isReject={isReject}
        handleCancel={() => {
          setReason(null);
        }}
      />
    </>
  );
}

export default ApprovalFlow;
