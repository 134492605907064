import { Button, DatePicker, Form, FormInstance, Input, InputNumber, Modal, Select } from 'antd';

import IconClose from '../../../../../../assets/icons/icon-close-ord.svg';
import IconCalendar from '../../../../../../assets/icons/icon-calendar-ord.svg';
import IconDown from '../../../../../../assets/icons/arrow-down-2-plain.svg';

import { useSelector } from 'react-redux';

import { useEffect, useState } from 'react';
import IconAdd from '../../../../../../assets/icons/plus-purpose.svg';
import { OrderDetail, OrderStatus, PlanData, PlanSettingDetail } from '../../../entity/Entity';
import { RootState } from '../../../../../../store';
import MESSAGE from '../../../../../../common/constants/MESSAGE';
import COMMON, { ORDER_STATUS, PLAN_TYPE } from '../../../../../../common/constants/COMMON';
import helpers from '../../../../../../common/helpers/common';
import { disabledEndDate, disabledStartDate } from '../../../helper';
import { HandlerImpl } from './Handler';
import PlanFunction from '../plan-function';
import locale from 'antd/es/date-picker/locale/ja_JP';
import moment from 'moment';

const { Item } = Form;
const { Option } = Select;

interface props {
    handler: HandlerImpl;
    statusOrder: OrderStatus[];
    newPlanFunctions: any[];
    existedPlanFunctions: any[];
    form: FormInstance;
    planDetail?: PlanSettingDetail;
    totalDiscount: number;
    totalCost: number;
    orderDetail?: OrderDetail;
    addAPlanFunction(): void;
    deleteAPlanFunction(id: string, name: string): void;
    getChosenPlanFunctionOnFormChange: () => void
}

export const MainUI = ({
    handler,
    form,
    planDetail,
    statusOrder,
    newPlanFunctions,
    existedPlanFunctions,
    totalCost,
    totalDiscount,
    orderDetail,
    deleteAPlanFunction,
    addAPlanFunction,
    getChosenPlanFunctionOnFormChange
}: props) => {
    const chosenPlan = useSelector((state: RootState) => state.ord002_1.chosenPlan);
    const orderId = useSelector((state: RootState) => state.ord002_1.orderId);

    const {
        currentTotalMAmount,
        currentCost,
        planPerson,
        endDateState,
        startDateState,
        onChangePersonPlan,
        onChangeDiscount,
        setPlanPerson,
        initiateDetailOrder,
        setEndDateState,
        setStartDateState,
    } = handler;

    useEffect(() => {
        initiateDetailOrder(form, orderDetail);
    }, [JSON.stringify(orderDetail)]);

    useEffect(() => {
        setPlanPerson(planDetail?.basicPlan?.planPerson ?? []);
    }, [JSON.stringify(planDetail?.basicPlan?.planPerson)]);

    useEffect(() => {
        form.setFieldValue('orderCost', currentCost);
    }, [JSON.stringify(currentCost)]);

    useEffect(() => {
        if (
            planPerson.length > 0 &&
            (!orderId || orderDetail?.plan.subCategory.code === PLAN_TYPE.PLAN_FREE)
        ) {
            onChangePersonPlan(planPerson[0].id, form, chosenPlan);
        }
    }, [planPerson, orderDetail, orderId]);
    const changeStatusWhenEndDateChange = (endDate: any) => {
        if (endDate && endDate.endOf('day').diff(moment().endOf('day')) >= 0)
            form.setFieldValue('statusId', ORDER_STATUS.ACTIVE_ORDER);
        if (endDate && endDate.endOf('day').diff(moment().endOf('day')) < 0)
            form.setFieldValue('statusId', ORDER_STATUS.INACTIVE_ORDER);
    };
    return (
        <>
            <div className="ord-form-item item-child">
                <div className="ord-form-item-left">
                    <div className="ord-form-label">
                        開始日 <span className="label-required">*</span>
                    </div>
                    <div className="ord-form-input">
                        <Item
                            name="startDate"
                            rules={[
                                {
                                    required: true,
                                    message: MESSAGE.MESSAGE_REQUIRED,
                                },
                            ]}
                        >
                            <DatePicker
                                size="large"
                                suffixIcon={<img alt="" src={IconCalendar} />}
                                className="ord-date-picker"
                                placeholder=""
                                allowClear={false}
                                locale={locale}
                                inputReadOnly
                                disabledDate={(current) =>
                                    disabledStartDate(current, form.getFieldValue('endDate'))
                                }
                                getPopupContainer={(trigger: any) => trigger.parentNode}
                                format={COMMON.FORMAT_DATE_CM}
                            />
                        </Item>
                    </div>
                </div>
                <div className="ord-form-item-right">
                    <div className="ord-form-label">
                        有効期限 <span className="label-required">*</span>
                    </div>
                    <div className="ord-form-input">
                        <Item
                            name="endDate"
                            rules={[
                                {
                                    required: true,
                                    message: MESSAGE.MESSAGE_REQUIRED,
                                },
                            ]}
                        >
                            <DatePicker
                                onChange={changeStatusWhenEndDateChange}
                                size="large"
                                suffixIcon={<img alt="" src={IconCalendar} />}
                                className="ord-date-picker"
                                placeholder=""
                                locale={locale}
                                inputReadOnly
                                disabledDate={(current) =>
                                    disabledEndDate(current, form.getFieldValue('startDate'))
                                }
                                allowClear={false}
                                getPopupContainer={(trigger: any) => trigger.parentNode}
                                format={COMMON.FORMAT_DATE_CM}
                            />
                        </Item>
                    </div>
                </div>
            </div>
            <div className="ord-form-item item-child">
                <div className="ord-form-item-left">
                    <div className="ord-form-label two-row">
                        <span>
                            {' '}
                            ユーザー数 <span className="label-required">*</span>
                        </span>
                        <span className="label-other">（ID）</span>
                    </div>
                    <div className="ord-form-input">
                        <Item
                            name="planPersonId"
                            rules={[
                                {
                                    required: true,
                                    message: MESSAGE.MESSAGE_REQUIRED,
                                },
                            ]}
                        >
                            <Select
                                onChange={(value: any) =>
                                    onChangePersonPlan(value, form, chosenPlan)
                                }
                                size="large"
                                suffixIcon={<img alt="" src={IconDown} />}
                                getPopupContainer={(trigger: any) => trigger.parentNode}
                            >
                                {planPerson.map((item) => (
                                    <Option key={item.id} value={item.id}>
                                        {item.userNumber}
                                    </Option>
                                ))}
                            </Select>
                        </Item>
                    </div>
                </div>
                <div className="ord-form-item-right">
                    <div className="ord-form-label">
                        ステータス <span className="label-required">*</span>
                    </div>
                    <div className="ord-form-input">
                        <Item
                            name="statusId"
                            rules={[
                                {
                                    required: true,
                                    message: MESSAGE.MESSAGE_REQUIRED,
                                },
                            ]}
                        >
                            <Select
                                size="large"
                                suffixIcon={<img alt="" src={IconDown} />}
                                getPopupContainer={(trigger: any) => trigger.parentNode}
                            >
                                {statusOrder.map((item) => (
                                    <Option key={item.id} value={item.code}>
                                        {item.name}
                                    </Option>
                                ))}
                            </Select>
                        </Item>
                    </div>
                </div>
            </div>
            <div className="ord-form-item col-3">
                <div className="ord-form-item-3-left">
                    <span className="ord-form-label">基本料金</span>
                    <span className="ord-form-content">
                        {helpers.formatCurrency(currentCost)}円
                        <Item hidden name={'orderCost'}>
                            <Input />
                        </Item>
                    </span>
                </div>
                <div className="ord-form-item-3-center">
                    <span className="ord-form-label">割引 </span>
                    <div className="ord-form-input input-number">
                        <Item name="discount">
                            <InputNumber controls={false}
                                onChange={onChangeDiscount}
                                size="large"
                                addonAfter="円"
                                max={99999}
                                formatter={(value) =>
                                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                }
                                parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                            />
                        </Item>
                    </div>
                </div>
                <div className="ord-form-item-3-right">
                    <span className="ord-form-label">月額料金</span>
                    <span className="ord-form-content">
                        {helpers.formatCurrency(currentTotalMAmount)}円
                    </span>
                </div>
            </div>
            <div className="ord-form-add">
                {[...existedPlanFunctions, ...newPlanFunctions].length >=
                    (planDetail?.basicPlan?.subscription ?? []).length ? (
                    <div style={{ height: 38 }}></div>
                ) : (
                    <div className="ord-btn-add" onClick={addAPlanFunction}>
                        <img src={IconAdd} alt="" />
                        <span>オプション機能</span>
                    </div>
                )}
                {[...newPlanFunctions, ...existedPlanFunctions]?.map((plan) => (
                    <PlanFunction
                        key={plan.key}
                        data={plan?.data}
                        form={form}
                        planDetail={planDetail}
                        statusOrder={statusOrder}
                        formName={plan.key}
                        deleteAPlanFunction={deleteAPlanFunction}
                        getChosenPlanFunctionOnFormChange={getChosenPlanFunctionOnFormChange}
                    />
                ))}
            </div>
            <div className="ord-footer">
                <span className="ord-footer-title">合計</span>
                <div className="ord-footer-content">
                    <div className="ord-footer-1">
                        <span className="ord-footer-label">基本料金</span>
                        <span className="ord-footer-text">
                            {helpers.formatCurrency(currentCost + totalCost)}円
                        </span>
                    </div>
                    <div className="ord-footer-2">
                        <span className="ord-footer-label">割引</span>
                        <span className="ord-footer-text">
                            {helpers.formatCurrency(totalDiscount)}円
                        </span>
                    </div>
                    <div className="ord-footer-3">
                        <span className="ord-footer-label">月額料金</span>
                        <span className="ord-footer-text">
                            {helpers.formatCurrency(currentCost + totalCost - totalDiscount)}円
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};
