import axiosConfig from "../../../../../config/axiosConfig";

const CM0033Api = () => {
  const getReSummarySubmittedReceiveMoneyProjectList = async (params: {
    documentId: number;
    page: number;
    size: number;
  }) => {
    try {
      const url = "/re-summary/submitted/receive-money/project/list";
      return axiosConfig.get(url, { params });
    } catch (error) {
      throw error;
    }
  };

  const getReSummarySubmittedReceiveMoneyCompanyList = async (params: {
    documentId: number;
    page: number;
    size: number;
  }) => {
    try {
      const url = "/re-summary/submitted/receive-money/company/list";
      return axiosConfig.get(url, { params });
    } catch (error) {
      throw error;
    }
  };
  const getProjects = async (params: any): Promise<any> => {
    try {
      const url = "/money/summary/received-money/project/list";
      return axiosConfig.get(url, { params });
    } catch (error) {
      throw error;
    }
  };

  const getListMoneyReceive = async (params: any): Promise<any> => {
    try {
      const url =
        "/re-summary/submitted/received-money/project/list-receive-money";
      return axiosConfig.get(url, { params });
    } catch (error) {
      throw error;
    }
  };

  const getListMoneyReceiveCompany = async (params: any): Promise<any> => {
    try {
      const url =
        "/re-summary/submitted/received-money/company/project/list-receive-money";
      return axiosConfig.get(url, { params });
    } catch (error) {
      throw error;
    }
  };

  const getListOffset = async (params: any): Promise<any> => {
    try {
      const url = "/re-summary/submitted/received-money/company/project/offset";
      return axiosConfig.get(url, { params });
    } catch (error) {
      throw error;
    }
  };

  const retrieveDocumentCompany = async (data: any): Promise<any> => {
    try {
      const url = "/re-summary/received-money/company/project/retrieve";
      return axiosConfig.put(url, data);
    } catch (error) {
      throw error;
    }
  };

  const retrieveDocumentProject = async (data: any): Promise<any> => {
    try {
      const url = "/re-summary/received-money/project/retrieve";
      return axiosConfig.put(url, data);
    } catch (error) {
      throw error;
    }
  };

  const getLineSummaryCompany = async (params: any): Promise<any> => {
    try {
      const url = "/re-summary/submitted/received-money/company/project/total";
      return axiosConfig.get(url, { params });
    } catch (error) {
      throw error;
    }
  };

  const getLineSummaryProject = async (params: any): Promise<any> => {
    try {
      const url = "/re-summary/submitted/received-money/project/total";
      return axiosConfig.get(url, { params });
    } catch (error) {
      throw error;
    }
  };
  const getWorkflowSummaryReceiveMoneyCompanyApprovalProcess = async (params: {
    documentId: number;
  }): Promise<any> => {
    const url = "/workflow/summary/receive-money/company/approval-process";
    return await axiosConfig.get(url, { params });
  };
  const getWorkflowSummaryReceiveMoneyProjectApprovalProcess = async (params: {
    documentId: number;
  }): Promise<any> => {
    const url = "/workflow/summary/receive-money/project/approval-process";
    return await axiosConfig.get(url, { params });
  };
  return {
    getProjects,
    getListMoneyReceive,
    getListOffset,
    retrieveDocumentCompany,
    retrieveDocumentProject,
    getListMoneyReceiveCompany,
    getLineSummaryCompany,
    getLineSummaryProject,
    getWorkflowSummaryReceiveMoneyProjectApprovalProcess,
    getWorkflowSummaryReceiveMoneyCompanyApprovalProcess,
    getReSummarySubmittedReceiveMoneyProjectList,
    getReSummarySubmittedReceiveMoneyCompanyList,
  };
};

export default CM0033Api;
