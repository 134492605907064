import axiosConfig from '../../../../../config/axiosConfig';
import { OrderingCompanyCreationPayload } from '../../entity/Entity';

const PM008_1Api = () => {
    const getListOrderingCompany = async (params: {
        page?: number;
        size?: number;
        sortBy?: string;
        sortType?: string;
        keyword?: string;
    }) => {
        const url = '/ordering-company/list';
        return axiosConfig.get(url, { params });
    };

    const addCompanyForList = async (payload: OrderingCompanyCreationPayload) => {
        const url = '/ordering-company/add';
        return await axiosConfig.post(url, payload);
    };
    return {
        getListOrderingCompany,
        addCompanyForList
    };
};

export default PM008_1Api;
