import { useState } from "react";
import { useDispatch } from "react-redux";
import { showImageViewerModal } from "../../../../../common/components/image-viewer-modal/ImageViewSlice";
import ConfirmModal from "../../../../../common/components/modal/ConfirmModal";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import {
  DataTableConstructionBudgetBalance,
  DataTableItemizedBudgetBalance,
  DataTableSubmissionHistory,
  DataTableWorkCompleted,
} from "../../entity/Entity";
import { CM0016UseCase } from "../../usecase/ServiceImpl";
import { resetCM0016, setLoading } from "../slice/Slice";
import {
  convertDataTableIndirectCost,
  convertDataTableInformationCost,
  convertDataTableReceivedMoney,
} from "../../helper";
import COMMON, {
  STATUS_CM_CHILD_TABLE,
} from "../../../../../common/constants/COMMON";
import moment from "moment";
import { setRefresh } from "../../../CM034-CM53/CM035/presenter/slice/Slice";
import { DataTableOverview } from "../../../CM006/entity/Entity";
import { CM006UseCase } from "../../../CM006/usecase/ServiceImpl";
import { convertDataOverview } from "../../../CM006/helper";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import { ItemApprove } from "../../../../../common/components/approval-flow/ApprovalFlow";
import { setDocumentView } from "../../../../../common/components/modal-approve-view/presenter/slice/Slice";
import { showModalApproveConfirm } from "../../../../../common/components/modal-approve-confirm/presenter/slice/Slice";
import { showPdfViewerModal } from "../../../../../common/components/pdf-viewer-modal/pdfViewerModalSlice";

const SCREEN_NAME_VIEW = "CM0015";

const CM0016Handler = (
  CM0016Service: CM0016UseCase,
  CM006Service: CM006UseCase
) => {
  // LIB FUNCTION
  const dispatch = useDispatch();

  //state
  const [isOpenViewer, setIsOpenViewer] = useState<boolean>(false);
  const [pdfUrl, setPdfUrl] = useState<any>();
  const [pdfName, setPdfName] = useState<string>("");

  const [dataSummary, setDataSummary] = useState<any>();
  const [dataReceivedMoney, setReceivedMoney] = useState<DataTableOverview[]>(
    []
  );
  const [constructionInformationCost, setConstructionInformationCost] =
    useState<DataTableConstructionBudgetBalance[]>([]);
  const [indirectCost, setIndirectCost] = useState<
    DataTableItemizedBudgetBalance[]
  >([]);

  const [dataTableSubmissionHistory, setDataTableSubmissionHistory] = useState<
    DataTableSubmissionHistory[]
  >([]);

  const [checkAll, setCheckAll] = useState(false);
  const [checkedList, setCheckedList] = useState<any[]>([]);
  const [dataSummaryTitle, setDataSummaryTitle] = useState<any>({});
  const [workflow, setWorkflow] = useState<ItemApprove[]>([]);
  const [currentUserApproval, setCurrentUserApproval] =
    useState<ItemApprove | null>(null);

  const getWorkflowIndirectApprovalProcess = async (documentId: number) => {
    try {
      dispatch(setLoading(true));
      const data = await CM0016Service.getWorkflowIndirectApprovalProcess({
        documentId,
      });
      if (data.results.currentUserApproval)
        setCurrentUserApproval({
          approveDate: data.results.currentUserApproval.approveDate,
          contact_address: "",
          email: data.results.currentUserApproval.email,
          id: data.results.currentUserApproval.id,
          isApprove: data.results.currentUserApproval.approve,
          reject: data.results.currentUserApproval.reject,
          position: "",
          priority: data.results.currentUserApproval.priority,
          username: data.results.currentUserApproval.username,
          fullName: data.results.currentUserApproval.fullName,
          note: data.results.currentUserApproval.note,
        });
      const workflowData: ItemApprove[] = data.results.listApprover.map(
        (element) => {
          let note = element.note;
          if (element.id === data.results?.currentUserApproval?.id)
            note = data.results.currentUserApproval.note;
          const result: ItemApprove = {
            approveDate: element.approveDate,
            contact_address: "",
            email: element.email,
            id: element.id,
            isApprove: element.approve,
            reject: element.reject,
            position: "",
            priority: element.priority,
            username: element.username,
            fullName: element.fullName,
            note: note,
          };
          return result;
        }
      );
      setWorkflow(workflowData);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getListSubmittedIndirectCost = async (
    documentSubmitId: number,
    documentOriginId: number,
    constructionId: number
  ): Promise<any> => {
    try {
      const res = await CM0016Service.getListSubmittedIndirectCost({
        documentSubmitId: documentSubmitId,
        constructionId: constructionId,
      });
      if (res?.results) {
        const data: DataTableSubmissionHistory[] =
          formatListSubmittedIndirectCost(res.results);
        let totalActualCost = 0;
        if (data) {
          data.forEach((item: DataTableSubmissionHistory) => {
            totalActualCost += item?.actualCost ?? 0;
          });
          setDataTableSubmissionHistory(data);
        }

        if (res?.results.length !== 0) {
          getDataReceivedMoney(
            res.results[0].dataSummary.construction.id,
            documentSubmitId
          );
          getDataConstructionInformationCost(
            res.results[0].dataSummary.construction.id
          );
          getDataIndirectCost(
            res.results[0].dataSummary.construction.id,
            documentOriginId,
            documentSubmitId,
            totalActualCost
          );

          setDataSummaryTitle(res?.results[0]?.dataSummary);
        }
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const formatListSubmittedIndirectCost = (data: any) => {
    const dataFormat: DataTableSubmissionHistory[] = data.map(
      (item: any, index: number) => {
        let paymentAmount = 0;

        const calculator = 1 - item?.multiplicationFactor;
        if (calculator !== 0) {
          paymentAmount =
            item?.directCost / (1 - item?.multiplicationFactor) -
            item?.directCost;
        }
        return {
          no: index + 1,
          key: item?.id,
          id: item.id,
          status: item.status,
          inputMethod: item?.inputMethodCategory?.description,
          paymentDate: `${
            item?.paymentDate
              ? moment(item?.paymentDate).format(COMMON.FORMAT_DATE_OM)
              : ""
          }`,
          targetStage: `${
            item.startDate
              ? moment(item.startDate).format(COMMON.FORMAT_DATE_OM)
              : ""
          }～${
            item.endDate
              ? moment(item.endDate).format(COMMON.FORMAT_DATE_OM)
              : ""
          }`,
          manualInput: item?.value,
          multiplier: item?.multiplicationFactor,
          payAmount: paymentAmount,
          actualCost: item?.actualCost,
          evidence: item?.evidence,
          note: item?.note,
        };
      }
    );
    return dataFormat;
  };

  const handleCalculateSummary = (data: DataTableSubmissionHistory[]) => {
    let totalManualInput = 0;
    let totalPayAmount = 0;
    let totalActualCost = 0;
    data.forEach((element: DataTableSubmissionHistory) => {
      totalManualInput += element.manualInput ?? 0;
      totalPayAmount += element.payAmount ?? 0;
      totalActualCost += element.actualCost ?? 0;
    });
    setDataSummary({
      totalManualInput,
      totalPayAmount,
      totalActualCost,
    });
  };

  const handleOpenViewer = async (record: any) => {
    try {
      dispatch(setLoading(true));
      const companyId = JSON.parse(localStorage.getItem("user") ?? `{}`)
        ?.company?.id;
      const response = await CM0016Service.getPresignLinkS3({
        keyName: `re/${companyId}/${SCREEN_NAME_VIEW}/${Number(record.key)}/${
          record.evidence
        }`,
      });
      const checkFile = record.evidence?.split(".").pop();
      const evidenceName = record.evidence?.split(".").slice(0, -1).join(".");

      setPdfName(evidenceName);
      setPdfUrl(response.results);

      if (checkFile === "pdf") dispatch(showPdfViewerModal());
      else dispatch(showImageViewerModal());
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const onCancel = () => {
    dispatch(resetCM0016());
  };

  const getDataReceivedMoney = async (
    constructionId: number,
    documentId: number
  ) => {
    const responseA = await CM006Service.getConstructionInformation({
      constructionId: constructionId,
      documentId: documentId,
    });

    const responseB = await CM006Service.getListConstructionReceivedMoney({
      constructionId: constructionId,
    });

    setReceivedMoney(convertDataOverview(responseA.results, responseB.results));
  };

  const getDataConstructionInformationCost = async (constructionId: number) => {
    const response = await CM0016Service.getConstructionSummaryCostInformation({
      constructionId: constructionId,
    });

    setConstructionInformationCost(
      convertDataTableInformationCost([response.results])
    );
  };

  const getDataIndirectCost = async (
    constructionId: number,
    documentOriginId: number,
    documentSubmitId: number,
    actualCost: number
  ) => {
    const response = await CM0016Service.getMoneyIndirectCost({
      constructionId: constructionId,
      documentId: documentSubmitId,
    });

    setIndirectCost(
      convertDataTableIndirectCost(
        response.results,
        documentOriginId,
        actualCost
      )
    );
  };

  const retrieveDocument = (documentId: number) => {
    ConfirmModal({
      onOk: async () => {
        const response = await CM0016Service.retrieveIndirectDocument({
          documentId: documentId,
          listDocumentIndirectCostInformationId: checkedList,
        });

        if (response.status === `${COMMON.HTTP_STATUS_OK}`) {
          dispatch(resetCM0016());
          dispatch(setRefresh());
        }
        SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
      },
      className: "confirm__modal",
      title: MESSAGE.MESSAGE_TITLE_CM016,
      description: MESSAGE.MESSAGE_DESCRIPTION_CR_4,
      extraDescription: MESSAGE.MESSAGE_DESCRIPTION_COMMON_QUESTION_1,
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_MODAL,
      isCenterWithoutMenu: true,
    });
  };

  const onCheckAllChange = (checked: boolean) => {
    setCheckAll(checked);
    if (checked) {
      const checkedItems: any[] = [];
      for (let element of dataTableSubmissionHistory) {
        checkedItems.push(element.id);
      }
      setCheckedList(checkedItems);
    } else setCheckedList([]);
  };

  const isCheckbox = (record: any): boolean =>
    checkedList.some((element) => element === record.key);

  const onCheckboxChange = (record: any, checked: boolean) => {
    // setCheckedList(list);
    let newCheckList: any[] = [];

    if (checked) {
      newCheckList = [...checkedList, record.key];
    } else {
      const checkedData = checkedList.filter(
        (element: any) => element !== record.key
      );
      newCheckList = checkedData;
    }
    const possibleItems: any[] = [];

    for (let element of dataTableSubmissionHistory) {
      if (
        [
          STATUS_CM_CHILD_TABLE.MONEY_NOT_APPROVED.CODE,
          STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE,
        ].includes(element?.status?.code)
      )
        possibleItems.push(element.id);
    }
    setCheckedList(newCheckList);
    setCheckAll(newCheckList.length === possibleItems.length);
  };

  const viewApproveModal = (record: any) => {
    dispatch(setDocumentView(record));
  };

  const showConfirmApproveModal = () => {
    dispatch(showModalApproveConfirm());
  };
  return {
    dataTableSubmissionHistory,
    isOpenViewer,
    pdfUrl,
    dataSummary,
    dataReceivedMoney,
    constructionInformationCost,
    indirectCost,
    dataSummaryTitle,
    workflow,
    currentUserApproval,
    checkAll,
    checkedList,
    pdfName,
    onCancel,
    handleOpenViewer,
    handleCalculateSummary,
    getListSubmittedIndirectCost,
    getDataConstructionInformationCost,
    getDataIndirectCost,
    retrieveDocument,
    getWorkflowIndirectApprovalProcess,
    onCheckAllChange,
    isCheckbox,
    onCheckboxChange,
    viewApproveModal,
    showConfirmApproveModal,
  };
};

export default CM0016Handler;
