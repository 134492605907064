import CM0034Api from './infrastructure/api/Api';
import CM0034Handler from './presenter/handler/Handler';
import CM0034UI from './presenter/ui/UI';
import CM0034Service from './usecase/Service';

const CM0034Component = () => {
    const cm0034Api = CM0034Api();
    const cm0034Service = CM0034Service(cm0034Api);
    const cm0034Handler = CM0034Handler(cm0034Service);
    return <CM0034UI handler={cm0034Handler} />;
};

export default CM0034Component;
