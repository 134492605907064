import { SM06Api } from './ServiceImpl';

const SM06Service = (sm06: SM06Api) => {
    const deleteSchedule = async (data: any): Promise<any> => {
        try {
            return await sm06.deleteSchedule(data);
        } catch (error) {
            throw error;
        }
    };

    return {
        deleteSchedule,
    };
};

export default SM06Service;
