import { AxiosResponse } from "axios";
import axiosConfig from "../../../../../config/axiosConfig";
import { MoneySubmitIndirectCostPayload } from "../../entity/Entity";

const MaterialCostApi = () => {
  const getMoneyIndirectCostList = async (params: {
    constructionId: number;
    documentId: number;
    sortBy?: string;
    sortType?: string;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/money/indirect-cost/list";
    return await axiosConfig.get(url, { params });
  };
  const putMoneyIndirectCostEnable = async (
    data: any
  ): Promise<AxiosResponse<any, any>> => {
    const url = "/money/indirect-cost/enable";
    return await axiosConfig.put(url, data);
  };
  const getMoneyIndirectCostInformationList = async (params: {
    indirectCostId: number;
    constructionId: number;
    sortBy?: string;
    sortType?: string;
    from?: string;
    to?: string;
    page: number;
    size: number;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/money/indirect-cost/information/list";
    return await axiosConfig.get(url, { params });
  };

  const putMoneySubmitIndirectCost = async (
    payload: MoneySubmitIndirectCostPayload
  ): Promise<AxiosResponse<any, any>> => {
    const url = "/money/submit/indirect-cost";
    return await axiosConfig.put(url, payload);
  };

  /**
   *
   * @param params
   * @returns
   */
  const doExportCM0015 = async (params: any): Promise<any> => {
    try {
      const url = "/money/exportCM0015";
      const response = await axiosConfig.get(url, {
        params,
        responseType: "blob",
      });

      return response;
    } catch (error) {
      throw error;
    }
  };

  return {
    getMoneyIndirectCostList,
    putMoneyIndirectCostEnable,
    getMoneyIndirectCostInformationList,
    putMoneySubmitIndirectCost,
    doExportCM0015,
  };
};

export default MaterialCostApi;
