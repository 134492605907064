import axiosConfig from "../../../../../config/axiosConfig";

import axios from "axios";
import {
  RequestCreateLateEarly,
  RequestUpdateLateEarly,
} from "../../entity/Entity";

const AM018Api = () => {
  const createLateEarly = async (
    data: RequestCreateLateEarly
  ): Promise<any> => {
    try {
      const url = "/request/create/late-early";
      const response = await axiosConfig.post(url, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const updateLateEarly = async (
    data: RequestUpdateLateEarly
  ): Promise<any> => {
    try {
      const url = "/request/update/late-early";
      const response = await axiosConfig.put(url, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getLateEarlyCategory = async (): Promise<any> => {
    try {
      const url = "/request/late-early/category";
      const response = await axiosConfig.get(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getProjects = async (params: any): Promise<any> => {
    try {
      const url = "/money/project/list";
      const response = await axiosConfig.get(url, { params });
      return response?.data;
    } catch (error) {
      throw error;
    }
  };

  const getRequestLateEarly = async (params: any): Promise<any> => {
    try {
      const url = "/request/late-early";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getAttendanceUserConstruction = async (params: any): Promise<any> => {
    try {
      const url = "/attendance/user/construction";
      const response = await axiosConfig.get(url, { params });
      return response?.data;
    } catch (error) {
      throw error;
    }
  };

  const getProjectById = async (params: any): Promise<any> => {
    try {
      const url = "/project/detail";
      const response = await axiosConfig.get(url, { params });
      return response?.data;
    } catch (error) {
      throw error;
    }
  };

  const getRequestApprover = async (): Promise<any> => {
    try {
      const url = "/request/approver";
      const response = await axiosConfig.get(url);
      return response?.data;
    } catch (error) {
      throw error;
    }
  };

  const getProjectStatus = async (params: any): Promise<any> => {
    try {
      const url = "/project/status";
      const response = await axiosConfig.get(url, { params });
      return response?.data;
    } catch (error) {
      throw error;
    }
  };

  return {
    createLateEarly,
    updateLateEarly,
    getLateEarlyCategory,
    getProjects,
    getRequestLateEarly,
    getAttendanceUserConstruction,
    getProjectById,
    getRequestApprover,
    getProjectStatus,
  };
};

export default AM018Api;
