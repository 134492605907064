import axiosConfig from '../../../../../config/axiosConfig';

const ORD001Api = () => {
    const getListOrder = async (params: {
        page?: number;
        size?: number;
        planId?: number;
        statusId?: number;
        subcription?: string;
        from?: string;
        to?: string;
        sortBy?: string;
        sortType?: string;
    }) => {
        const url = '/order/list';
        return await axiosConfig.get(url, { params });
    };

    const getListPlan = async () => {
        const url = '/plan/list';
        return axiosConfig.get(url);
    };

    const getListStatusOrder = async () => {
        const url = '/order/status/list';
        return axiosConfig.get(url);
    };

    const getDetailPlanSetting = async () => {
        const url = '/plan/setting/detail';
        return axiosConfig.get(url);
    };

    const deleteOrder = async (data: any) => {
        const url = '/order/delete';
        return await axiosConfig.delete(url, { data });
    };

    return {
        getListOrder,
        getListPlan,
        getListStatusOrder,
        getDetailPlanSetting,
        deleteOrder,
    };
};

export default ORD001Api;
