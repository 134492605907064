import { CM001Api } from "./ServiceImpl";
import ErrorNotification from "../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../common/constants/MESSAGE";

const CM001Service = (cm001Api: CM001Api) => {
  const getProjectSummaryReceivedMoney = async (params: {
    projectId: string;
  }): Promise<any> => {
    try {
      const response = await cm001Api.getProjectSummaryReceivedMoney({
        ...params,
      });
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return;
    }
  };

  const getProjectSummaryCost = async (params: {
    projectId: string;
  }): Promise<any> => {
    try {
      const response = await cm001Api.getProjectSummaryCost({
        ...params,
      });
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return;
    }
  };

  const getListConstructionSummaryReceivedMoney = async (params: {
    projectId: string;
  }): Promise<any> => {
    try {
      const response = await cm001Api.getListConstructionSummaryReceivedMoney({
        ...params,
      });
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return;
    }
  };

  const updateThreshold = async (data: any) => {
    try {
      const response = await cm001Api.updateThreshold(data);
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return;
    }
  };

  const updateThresholdCostMaterial = async (data: any) => {
    try {
      const response = await cm001Api.updateThresholdCostMaterial(data);
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return;
    }
  };

  const updateThresholdCostLabor = async (data: any) => {
    try {
      const response = await cm001Api.updateThresholdCostLabor(data);
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return;
    }
  };

  const updateThresholdCostOutsource = async (data: any) => {
    try {
      const response = await cm001Api.updateThresholdCostOutsource(data);
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return;
    }
  };

  const updateThresholdCostIndirect = async (data: any) => {
    try {
      const response = await cm001Api.updateThresholdCostIndirect(data);
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return;
    }
  };

  const updateThresholdCostTotal = async (data: any) => {
    try {
      const response = await cm001Api.updateThresholdCostTotal(data);
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return;
    }
  };

  const updateThresholdConstruction = async (data: any) => {
    try {
      const response = await cm001Api.updateThresholdConstruction(data);
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return;
    }
  };

  const doExportCM001 = async (params: any): Promise<any> => {
    return await cm001Api.doExportCM001(params);
  };

  const doExportCM002 = async (params: any): Promise<any> => {
    return await cm001Api.doExportCM002(params);
  };

  return {
    getProjectSummaryReceivedMoney,
    getProjectSummaryCost,
    getListConstructionSummaryReceivedMoney,
    updateThreshold,
    updateThresholdCostMaterial,
    updateThresholdCostLabor,
    updateThresholdCostOutsource,
    updateThresholdCostIndirect,
    updateThresholdCostTotal,
    updateThresholdConstruction,
    doExportCM001,
    doExportCM002,
  };
};

export default CM001Service;
