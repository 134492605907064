import { AxiosResponse } from "axios";
import axiosConfig from "../../../../../config/axiosConfig";
import { ReSummaryCompanyProjectOutsourceCostRetrievePayload } from "../../entity/Entity";

const CM0023API = () => {
  const getProjectAlls = async (params: {
    documentId: number;
    page?: number;
    size?: number;
    sortBy?: string;
    sortType?: string;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/re-summary/submitted/material-cost/project/list";
    return await axiosConfig.get(url, { params });
  };
  const getReSummarySubmittedMaterialCostProjectConstruction = async (params: {
    documentId: number;
    projectId: number;
    page?: number;
    size?: number;
    sortBy?: string;
    sortType?: string;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/re-summary/submitted/material-cost/project/construction/list";
    return await axiosConfig.get(url, { params });
  };
  const getReSummarySubmittedMaterialCostProjectConstructionCompany =
    async (params: {
      documentId: number;
      constructionId: number;
      page?: number;
      size?: number;
      sortBy?: string;
      sortType?: string;
    }): Promise<AxiosResponse<any, any>> => {
      const url =
        "/re-summary/submitted/material-cost/project/construction/company/list";
      return await axiosConfig.get(url, { params });
    };
  const getReSummarySubmittedMaterialCostProjectSummary = async (params: {
    documentId: number;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/re-summary/submitted/material-cost/project/summary";
    return await axiosConfig.get(url, { params });
  };

  const putReSummaryMaterialCostProjectRetrieve = async (
    data: ReSummaryCompanyProjectOutsourceCostRetrievePayload
  ): Promise<AxiosResponse<any, any>> => {
    const url = "/re-summary/material-cost/project/retrieve";
    return await axiosConfig.put(url, data);
  };
  const getWorkflowSummaryMaterialProjectApprovalProcess = async (params: {
    documentId: number;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/workflow/summary/material/project/approval-process";
    return await axiosConfig.get(url, { params });
  };
  return {
    getProjectAlls,
    getReSummarySubmittedMaterialCostProjectConstruction,
    getReSummarySubmittedMaterialCostProjectConstructionCompany,
    getReSummarySubmittedMaterialCostProjectSummary,
    putReSummaryMaterialCostProjectRetrieve,
    getWorkflowSummaryMaterialProjectApprovalProcess,
  };
};

export default CM0023API;
