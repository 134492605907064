import { CollapsedTableAPIImpl } from "./ServiceImpl";
import {
  MoneyIndirectCostInformationListData,
  MoneyIndirectCostInformationCreatePayload,
  BaseResponseType,
  MoneyIndirectCostInformationDeletePayload,
  MoneyIndirectCostInformationEditPayload,
  MoneyIndirectCostInformationInputCategoryData,
  MoneyIndirectCostConstructionDirectCost,
} from "../entity/Entity";
import ErrorNotification from "../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../common/constants/MESSAGE";

const CollapsedTableService = (api: CollapsedTableAPIImpl) => {
  const getMoneyIndirectCostInformationList = async (params: {
    indirectCostId: number;
    constructionId: number;
    sortBy?: string;
    sortType?: string;
    from?: string;
    to?: string;
    page: number;
    size: number;
  }): Promise<MoneyIndirectCostInformationListData | null> => {
    try {
      const response = await api.getMoneyIndirectCostInformationList(params);
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return null;
    }
  };

  const postMoneyIndirectCostInformationCreate = async (
    payload: MoneyIndirectCostInformationCreatePayload
  ): Promise<BaseResponseType | null> => {
    try {
      const response = await api.postMoneyIndirectCostInformationCreate(
        payload
      );
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return null;
    }
  };

  const deleteMoneyIndirectCostInformationDelete = async (
    payload: MoneyIndirectCostInformationDeletePayload
  ): Promise<BaseResponseType | null> => {
    try {
      const response = await api.deleteMoneyIndirectCostInformationDelete(
        payload
      );
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return null;
    }
  };
  const putMoneyIndirectCostInformationEdit = async (
    payload: MoneyIndirectCostInformationEditPayload
  ): Promise<BaseResponseType | null> => {
    try {
      const response = await api.putMoneyIndirectCostInformationEdit(payload);
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return null;
    }
  };

  const getMoneyIndirectCostInformationInputCategory =
    async (): Promise<MoneyIndirectCostInformationInputCategoryData | null> => {
      try {
        const response =
          await api.getMoneyIndirectCostInformationInputCategory();
        return response.data;
      } catch (error: any) {
        ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        return null;
      }
    };
  const getLinkPresignUpload = async (params: any): Promise<any> => {
    try {
      const response = await api.getLinkPresignUpload(params);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const uploadToS3 = async (url: any, data: any): Promise<any> => {
    try {
      const response = await api.uploadToS3(url, data);
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      throw error;
    }
  };

  const getPresignLinkS3 = async (params: any): Promise<any> => {
    try {
      const response = await api.getPresignLinkS3(params);
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      throw error;
    }
  };
  return {
    getMoneyIndirectCostInformationList,
    postMoneyIndirectCostInformationCreate,
    deleteMoneyIndirectCostInformationDelete,
    putMoneyIndirectCostInformationEdit,
    getMoneyIndirectCostInformationInputCategory,
    getLinkPresignUpload,
    uploadToS3,
    getPresignLinkS3,
  };
};

export default CollapsedTableService;
