import { PM003Api } from "./ServiceImpl";

const PM003Service = (pm003Api: PM003Api) => {
  const getData = async (params: any): Promise<any> => {
    try {
      return await pm003Api.getData(params);
    } catch (error) {
      throw error;
    }
  };

  return { getData };
};

export default PM003Service;