import helpers from '../../../../common/helpers/common';
import { MoneyConstructionWorkAmountCompletedResult } from '../../CM005/entity/Entity';
import { CompletedPaidAmountAPIImpl } from './ServiceImpl';

const CompletedPaidAmountService = (api: CompletedPaidAmountAPIImpl) => {
    const getMoneyConstructionWorkAmountCompleted = async (params: {
        constructionId: number;
        from: string;
        to: string;
    }): Promise<MoneyConstructionWorkAmountCompletedResult | null> => {
        try {
            const response = await api.getMoneyConstructionWorkAmountCompleted({
                constructionId: params.constructionId,
            });
            const { results } = response.data;
            return results;
        } catch (error: any) {
            return null;
        }
    };
    return {
        getMoneyConstructionWorkAmountCompleted,
    };
};

export default CompletedPaidAmountService;
