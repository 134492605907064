import { Button, Form, Input, Modal, Spin, Tooltip } from 'antd';
import { OM007HandlerImpl, OM008HandlerImpl } from '../handler/HandlerImpl';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import LoadingComponent from '../../../../../common/components/icons/LoadingComponent';
import { useEffect } from 'react';
import MESSAGE from '../../../../../common/constants/MESSAGE';
import IconI from "../../../../../assets/icons/icon-i-om.svg"
import '../../../../../assets/styles/OM/OM002.css';
import '../../../../../assets/styles/OM/OM001.css';
import '../../../../../assets/styles/OM/OM007.css';
import '../../../../../assets/styles/OM/OM004.css';
import OMHeaderModalComponent from '../../../../../common/components/om-header-modal/OMHeaderModalComponent';
import REGEX from '../../../../../common/constants/REGEX';
import helpers from '../../../../../common/helpers/common';
import InfiniteScrollSelectComponent from '../../../../../common/components/infinity-scroll-select';
interface props {
    handler008: OM008HandlerImpl;
    handler007: OM007HandlerImpl;

}

const { TextArea } = Input;

const alertUser = (e: any) => {
    e.preventDefault();
    e.returnValue = '';
};

const OM008UI = ({ handler008, handler007 }: props) => {
    const isVisible = useSelector((state: RootState) => state.om008.isVisible);
    const loading = useSelector((state: RootState) => state.om008.loading);
    const groupInfo = useSelector((state: RootState) => state.om008.groupInfo);
    const { explanationRef } = handler008;
    useEffect(() => {
        handler008.initialOM008();
    }, [groupInfo.id]);

    //https://stackoverflow.com/questions/50026028/react-how-to-detect-page-refresh-f5
    useEffect(() => {
        if (handler008.isDirty) {
            window.addEventListener('beforeunload', alertUser);
            return () => {
                window.removeEventListener('beforeunload', alertUser);
            };
        } else {
            window.removeEventListener('beforeunload', alertUser, true);
        }
    }, [handler008.isDirty]);

    return !isVisible ? null : (
        <Modal
            style={{ marginLeft: 300, marginTop: 40 }}
            className="group-detail-modal no-margin-modal"
            closable={false}
            visible={true}
            maskClosable={false}
            width={996}
            centered={true}
            footer={
                <>
                    <Button
                        size="large"
                        type="primary"
                        style={{ marginLeft: 16, width: 76, letterSpacing: -1.5 }}
                        htmlType="submit"
                        form="formUpdateGroup"
                    >
                        保存
                    </Button>
                    <Button size="large" className="om-cancel-button" onClick={handler008.onCancel}>
                        キャンセル
                    </Button>
                </>
            }
        >
            <Spin indicator={LoadingComponent} spinning={loading}>
                <OMHeaderModalComponent title="部門編集" onCancel={handler008.onCancel} />
                <div className=" w-100-percent om007-form-container">
                    <Form
                        form={handler008.form}
                        id="formUpdateGroup"
                        layout="horizontal"
                        onFinish={handler008.updateGroup}
                    >
                        <Form.Item
                            name="name"
                            labelAlign="right"
                            colon={false}
                            className="form-item-label form-label-height-40"
                            label={
                                <div
                                    className="om007-form-label pr-20 center-item"
                                    style={{ marginLeft: 80 }}
                                >
                                    部門名
                                </div>
                            }
                            rules={[
                                {
                                    required: true,
                                    message: MESSAGE.MESSAGE_REQUIRED_FIELD,
                                },
                                {
                                    pattern: new RegExp(REGEX.EXCEPT_VIETNAMESE),
                                    message: MESSAGE.MESSAGE_INVALID_INPUT,
                                },
                            ]}
                        >
                            <Input
                                onChange={(e: any) => {
                                    helpers.preventInputJapanese(e);
                                    handler008.checkDirty();
                                }}
                                maxLength={50}
                                style={{ height: 40 }}
                            />
                        </Form.Item>
                        <div style={{ marginLeft: 42 }}>
                            {/* 
                            - Tên người duyệt
                            + Nếu là member: Lấy trường 名前 màn OM002
                            + Nếu là admin: Lấy trường アドミン名 màn IC1
                            */}
                            <InfiniteScrollSelectComponent
                                initialId={groupInfo?.approver?.id + ""}
                                isFormCol={true}
                                formLabel={<div style={{ display: 'flex' }}>
                                    <section>承認者<span style={{ color: 'rgba(255, 9, 9, 1)' }}>*</span></section>
                                    <section className='button-style' style={{ marginLeft: 8 }}>
                                        <Tooltip
                                            placement='topLeft'
                                            overlayInnerStyle={{ width: 330 }}
                                            title={<div >
                                                勤怠管理申請の承認者 <br />
                                                申請内容：打刻申請、休暇申請、遅刻・早退申請
                                            </div>} >
                                            <img alt='' src={IconI} />
                                        </Tooltip>
                                    </section>
                                </div>}
                                formName="approverId"
                                className="filter-table"
                                nameProp="fullName"
                                valueProp="id"
                                placeholderProp=""
                                sizeProp="large"
                                fetchData={handler007.getProjectUsers}
                                isCal50={true}
                                rules={[
                                    {
                                        required: true,
                                        message: MESSAGE.MESSAGE_REQUIRED_FIELD,
                                    },
                                ]}
                            />
                        </div>
                        <Form.Item
                            name="explanation"
                            labelAlign="right"
                            label={
                                <div className="om007-form-label pr-20" style={{ marginLeft: 94 }}>
                                    説明
                                </div>
                            }
                            colon={false}
                            rules={[
                                {
                                    pattern: new RegExp(REGEX.EXCEPT_VIETNAMESE),
                                    message: MESSAGE.MESSAGE_INVALID_INPUT,
                                },
                            ]}
                        >
                            <TextArea
                                ref={explanationRef}
                                onChange={(e: any) => {
                                    helpers.checkMaxLengthTextArea(
                                        handler008.form,
                                        explanationRef,
                                        'explanation',
                                        300,
                                    );
                                    handler008.checkDirty();
                                }}
                                maxLength={300}
                                rows={3}
                                style={{ width: '100%', minHeight: 84 }}
                            />
                        </Form.Item>
                    </Form>
                </div>
            </Spin>
        </Modal>
    );
};

export default OM008UI;
