import axiosConfig from "../../../../../config/axiosConfig";

const PM024Api = () => {
  const getCompanyList = async (params: any): Promise<any> => {
    try {
      const url = "/company-colab/list";
      return await axiosConfig.get(url, { params });
    } catch (error) {
      throw error;
    }
  };

  const addCompanyForList = async (data: any): Promise<any> => {
    try {
      const url = "/company-colab/add";
      return await axiosConfig.post(url, data);
    } catch (error) {
      throw error;
    }
  };

  return {
    getCompanyList,
    addCompanyForList,
  };
};

export default PM024Api;
