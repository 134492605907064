import FilterApi from "../../../infrastructure/api/ApiFilter"
import FilterService from "../../../usecase/ServiceFilter"
import FilterHandler from "../../handler/HandlerFilter"
import FilterSearchUI from "./ui/UI"


const FilterComponent = () => {
  const api = FilterApi()
  const service = FilterService(api)
  const handler = FilterHandler(service)
  return <FilterSearchUI handler={handler} />
}

export default FilterComponent