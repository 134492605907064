// Library
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";

// Components
import BreadcrumbComponent from "../../../../../common/components/breadcrumb/BreadcrumbComponent";
import MenuWrapperComponent from "../../../../../common/components/menu-wrapper";
import { CM001TitleComponent } from "../../../CM001/presenter/ui/CM001TitleComponent";
import { CM003FilterComponent } from "./CM003FilterComponent";
import { CM003ContentComponent } from "./CM003ContentComponent";

// Services
import {
  CONSTRUCTION_ID_KEY,
  FROM_DATE_FILTER,
  PROJECT_PARAM_ID_KEY,
  TO_DATE_FILTER,
} from "../handler/Handler";
import { CM003Handler } from "../handler/HandlerImpl";

// Commons

// icons
import HomeSvg from "../../../../../assets/icons/home.svg";
import COMMON from "../../../../../common/constants/COMMON";
import CM0063Component from "../../../CM0063";
import { RootState } from "../../../../../store";
import moment from "moment";
import helpers from "../../../../../common/helpers/common";
import {
  PROJECT_PARAM_KEY,
  RANGE_DATE_PARAM_KEY,
} from "../../../CM001/presenter/handler/Handler";
import ImageViewerModalComponent from "../../../../../common/components/image-viewer-modal/ImageViewerComponent";
import PdfViewerModalComponent from "../../../../../common/components/pdf-viewer-modal/PdfViewerModalComponent";

type props = {
  handler: CM003Handler;
};

const MainUI = ({ handler }: props) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const projectId: string = searchParams.get(PROJECT_PARAM_ID_KEY) ?? "";
  const fromDate: string = searchParams.get(FROM_DATE_FILTER) ?? "";
  const toDate: string = searchParams.get(TO_DATE_FILTER) ?? "";
  const constructionId: string = searchParams.get(CONSTRUCTION_ID_KEY) ?? "";
  const projectName: string = searchParams.get(PROJECT_PARAM_KEY) ?? "";
  const rangeDate: string = searchParams.get(RANGE_DATE_PARAM_KEY) ?? "";
  const isVisibleCM0063 = useSelector(
    (state: RootState) => state.cm0063.isVisible
  );
  const isOpenImageViewer = useSelector(
    (state: RootState) => state.imageViewerModal.isOpenImageViewer
  );

  React.useEffect(() => {
    handler.getListConstructionFirstOpenModal({
      origin: {
        page: COMMON.DEFAULT_PAGE,
        size: COMMON.DEFAULT_SIZE,
      },
      constructionId: constructionId,
      projectId: projectId,
    });
  }, []);

  React.useEffect(() => {
    if (projectId !== "") {
      handler.getSummaryProjectReceiveMoney({
        projectId: projectId,
        // from: helpers.getFilterTime(fromDate),
        // to: helpers.getFilterTimeTo(toDate),
      });

      handler.getProjectListConstruction({
        projectId: projectId,
        constructionId: constructionId,
        from: helpers.getFilterTime(fromDate),
        to: helpers.getFilterTimeTo(toDate),
      });
    }
  }, [projectId, fromDate, toDate, constructionId]);

  // Define Breadcrumb
  let BREADCRUMB_CM003: any[] = [];
  let chosenKey = 51;
  let openKey = 5;

  switch (location.pathname) {
    case "/app-cm003/cm001":
      chosenKey = 81;
      openKey = 8;

      BREADCRUMB_CM003 = [
        {
          title: "案件管理",
          url: ``,
          icon: (
            <img
              style={{ paddingBottom: 2, paddingRight: 8 }}
              alt=""
              src={HomeSvg}
            />
          ),
        },
        {
          title: "案件一覧",
          url: "/app-pm002",
          icon: "",
        },
        {
          title: "案件詳細",
          url: `/app-pm005/${projectId}`,
          icon: "",
        },
        {
          title: "案件別収支管理",
          url: `/app-cm001/pm005${location.search}`,
          icon: "",
        },
        {
          title: "案件出来高管理",
          url: "",
          icon: "",
        },
      ];
      break;
    default:
      BREADCRUMB_CM003 = [
        {
          title: "収支管理",
          url: "",
          icon: (
            <img
              style={{ paddingBottom: 2, paddingRight: 8 }}
              alt=""
              src={HomeSvg}
            />
          ),
        },
        {
          title: "案件別収支管理",
          url: `/app-cm0061${location.search}`,
          icon: "",
        },
        {
          title: "案件出来高管理",
          url: "",
          icon: "",
        },
      ];
      break;
  }

  return (
    <MenuWrapperComponent chosenKey={chosenKey} openKey={openKey}>
      <BreadcrumbComponent data={BREADCRUMB_CM003} />
      <div className="cm001-wrapper">
        <h1>案件出来高管理</h1>
        <div className="cm001-content">
          <CM001TitleComponent
            projectName={projectName}
            rangeDate={rangeDate}
          />
          <CM003FilterComponent handler={handler} />
          <CM003ContentComponent
            handler={handler}
            projectName={projectName}
            rangeDate={rangeDate}
            from={fromDate}
            to={toDate}
          />
          {isVisibleCM0063 ? <CM0063Component /> : null}

          <ImageViewerModalComponent
            imageURL={handler.documentPath}
            title={handler.pdfName}
          />
          <PdfViewerModalComponent
            pdfURL={handler.documentPath}
            title={handler.pdfName} />

        </div>
      </div>
    </MenuWrapperComponent>
  );
};

export default MainUI;
