import { createSlice } from '@reduxjs/toolkit';
import { AOP003SliceType } from '../../entity/Entity';

export const initialState: AOP003SliceType = {
    roleChosen: null,
};

export const AOP003Slice = createSlice({
    name: 'AOP003',
    initialState,
    reducers: {
        setRoleDetail(state, action) {
            state.roleChosen = action.payload;
        },
    },
});

export const { setRoleDetail } = AOP003Slice.actions;

export default AOP003Slice.reducer;
