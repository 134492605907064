import axios, { AxiosResponse } from "axios";
import axiosConfig from "../../../../../config/axiosConfig";
const CR003Api = () => {
  const getCR003BasicInfo = async (params: any): Promise<any> => {
    try {
      const url = "/project/document-quotation-detail";
      const response = await axiosConfig.get(url, { params });
      return response;
    } catch (error) {
      throw error;
    }
  };
  const updateCR003BasicInfo = async (data: any): Promise<any> => {
    try {
      const url = "/project/update-document-quotation-detail";
      const response = await axiosConfig.put(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  };
  const getScreenMaster = async (params: any): Promise<any> => {
    try {
      const url = "/screen/master";
      const response = await axiosConfig.get(url, {
        params,
      });
      return response;
    } catch (error) {
      throw error;
    }
  };

  const getListRelateMoney = async (params: any) => {
    try {
      const url = "/project/list-relate-money";
      return await axiosConfig.get(url, { params });
    } catch (error) {
      throw error;
    }
  };

  const getListSelectCompany = async (params: any) => {
    try {
      const url = "/company-colab/list";
      return await axiosConfig.get(url, { params });
    } catch (error) {
      throw error;
    }
  };

  const updateRelateMoney = async (data: any) => {
    try {
      const url = "/project/update-relate-money";
      return await axiosConfig.put(url, data);
    } catch (error) {
      throw error;
    }
  };

  const getListVersion = async (params: any) => {
    try {
      const url = "/project/document-payment-confirm-detail/list-version";
      return await axiosConfig.get(url, { params });
    } catch (error) {
      throw error;
    }
  };

  const getListVersionApproval = async (params: any) => {
    try {
      const url =
        "project/document-payment-confirm-detail/list-version-approved";
      return await axiosConfig.get(url, { params });
    } catch (error) {
      throw error;
    }
  };
  const updateNoteVersion = async (data: any) => {
    try {
      const url = "/project/document-payment-confirm-detail/note";
      return await axiosConfig.put(url, data);
    } catch (error) {
      throw error;
    }
  };

  const getVersionData = async (params: {
    documentPaymentConfirmDetailId: number | string;
  }) => {
    try {
      const url = "project/list-relate-money-version";
      return await axiosConfig.get(url, { params });
    } catch (error) {
      throw error;
    }
  };
  const getApprovalProcess = async (params: any): Promise<any> => {
    try {
      const url = "/project/cr-approval-process";
      const response = await axiosConfig.get(url, {
        params,
      });
      return response;
    } catch (error) {
      throw error;
    }
  };
  const postApprovalProcess = async (data?: any): Promise<any> => {
    try {
      const url = "/project/cr-approve";
      const response = await axiosConfig.post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const resetApproval = async (data?: any): Promise<any> => {
    try {
      const url = "/project/reset-cr-approval";
      const response = await axiosConfig.put(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const checkDuplicate = async (data?: any): Promise<any> => {
    try {
      const url = "/project/duplicate-construction-name";
      const response = await axiosConfig.post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const getListMember = async (params: any): Promise<any> => {
    try {
      const url = "/group/list-member-admin";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getDetailProject = async (params: any): Promise<any> => {
    try {
      const url = "/project/detail";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const exportTemplate = async (params: any): Promise<any> => {
    try {
      const url = '/project/export';
      const response = await axiosConfig.get(url, { params, responseType: 'blob' });
      return response;
    } catch (error) {
      throw error;
    }
  };

  const uploadToS3 = async (url: any, data: any): Promise<any> => {
    return await axios.put(url, data, {
      headers: {
        "Content-Type": data.type,
      },
    });
  };
  const getPresignLinkS3 = async (params: any): Promise<any> => {
    const url = "/project/presign-link";
    return await axiosConfig.get(url, { params });
  };

  const getLinkPresignUpload = async (
    params: any
  ): Promise<AxiosResponse<any, any>> => {
    const url = "/project/presign-upload";
    return await axiosConfig.get(url, { params });
  };

  const deleteFileCI = async (keyName: string): Promise<any> => {
    const url = `/project/delete-construction-file?keyName=${keyName}`;
    const response = await axiosConfig.delete(url, {});
    return response.data;
  };

  return {
    getCR003BasicInfo,
    updateCR003BasicInfo,
    getScreenMaster,
    getListRelateMoney,
    getListSelectCompany,
    updateRelateMoney,
    getListVersion,
    updateNoteVersion,
    getVersionData,
    getApprovalProcess,
    postApprovalProcess,
    resetApproval,
    checkDuplicate,
    getListMember,
    getDetailProject,
    exportTemplate,
    getPresignLinkS3,
    uploadToS3,
    getLinkPresignUpload,
    getListVersionApproval,
    deleteFileCI
  };
};

export default CR003Api;
