import CM006Api from '../CM006/infrastructure/api/Api';
import CM006Service from '../CM006/usecase/Service';
import CM0016Api from './infrastructure/api/Api';
import CM0016Handler from './presenter/handler/Handler';
import CM0016UI from './presenter/ui/UI';
import CM0016Service from './usecase/Service';

type props = {
    onSubmit?: () => void;
    onReject?: () => void;
    disableSubmitOrReject?: boolean;
    isCheckbox?: boolean
};

const CM0016Component = (props: props) => {
    const cm006Api = CM006Api();
    const cm006Service = CM006Service(cm006Api);

    const cm0016Api = CM0016Api();
    const cm0016Service = CM0016Service(cm0016Api);

    const cm0016Handler = CM0016Handler(cm0016Service, cm006Service);
    return <CM0016UI handler={cm0016Handler} {...props} />;
};

export default CM0016Component;
