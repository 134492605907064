import axiosConfig from "../../../../../config/axiosConfig";

const PM002Api = () => {
  const getData = async (params: any): Promise<any> => {
    try {
      const url = "/project/alls";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  const getStatus = async (params: any): Promise<any> => {
    try {
      const url = "/project/status";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  const getAllUser = async (params: any): Promise<any> => {
    try {
      const url = "/project/users";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  const updateProjectInfoStatus = async (data: any): Promise<any> => {
    const url = "/project/update-status";
    try {
      const response = await axiosConfig.put(url, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  const handlerAddMember = async (data: any): Promise<any> => {
    const url = "/project/add-member";
    try {
      const response = await axiosConfig.put(url, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getCategoryNotification = async (params: any) => {
    try {
      const url = "/notification/project/number-by-category";
      return await axiosConfig.get(url, { params });
    } catch (error) {
      throw error;
    }
  };

  const getListNotification = async (params: any) => {
    try {
      const url = "/notification/project/list";
      return await axiosConfig.get(url, { params });
    } catch (error) {
      throw error;
    }
  };

  const readNotification = async (data: any) => {
    try {
      const url = "/notification/mark-read";
      return await axiosConfig.put(url, data);
    } catch (error) {
      throw error;
    }
  };

  const deleteNotification = async (data: any) => {
    try {
      const url = "/notification/delete";
      return await axiosConfig.delete(url, { data });
    } catch (error) {
      throw error;
    }
  };

  return {
    getData,
    getStatus,
    getAllUser,
    updateProjectInfoStatus,
    handlerAddMember,
    getCategoryNotification,
    getListNotification,
    readNotification,
    deleteNotification,
  };
};
export default PM002Api;
