const HeaderMemberRowComponent = () => {
  return (
    <main
      className="table-cell-inside"
      style={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <div style={{ display: 'flex' }}>
        <span style={{ minWidth: 40 }}>No</span>
        <span style={{ marginLeft: 24 }}>名前</span>
      </div>
      <div>
        <span style={{ marginRight: 10 }}></span>
        <span className="bold-text-number "></span>
      </div>
    </main>
  );
};

export default HeaderMemberRowComponent;
