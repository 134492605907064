import React from 'react';
import { Row, Col, Radio, Input, Form } from 'antd';
import { OM005Section1Handler } from '../handler/HandlerImpl';
import MESSAGE from '../../../../../common/constants/MESSAGE';
import REGEX from '../../../../../common/constants/REGEX';
import COMMON from '../../../../../common/constants/COMMON';

const REGEX_MAX_PASSWORD = {
    max: 16,
    message: MESSAGE.MESSAGE_INVALID_PASSWORD,
};

const MESSAGE_PASSWORD = 'パスワードには数字、大文字、小文字、特殊文字を入力する必要があります。';

const REGEX_MIN_PASSWORD = {
    min: 8,
    message: MESSAGE.MESSAGE_INVALID_PASSWORD,
};

interface props {
    handler: OM005Section1Handler;
}

const OM005Section1 = ({ handler }: props) => {
    const [radioValue, setRadioValue] = React.useState(2);
    const [password, setPassword] = React.useState('');
    const [rePassword, setRePassword] = React.useState('');

    const onChange = (e: any) => {
        setRadioValue(e.target.value);
        setPassword('');
        setRePassword('');
    };

    const onChangePassword = (e: any) => {
        setPassword(e.target.value);
    };

    const onChangeRePassword = (e: any) => {
        setRePassword(e.target.value);
    };

    return (
        <div className="wrapper__section1">
            <Row>
                <Col span={24}>
                    <div className="section1__radio">
                        <Form.Item name="checkRequired">
                            <Radio.Group
                                onChange={onChange}
                                value={radioValue}
                            // defaultValue={radioValue}
                            >
                                <Radio value={1}>パスワードを作成する</Radio>
                                <Radio value={2} style={{ marginLeft: 500 }}>
                                    パスワードを自動的に生成する
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className="section1__input">
                        <div className="section1__input-password">
                            <span className="section1__input-span">パスワード入力</span>
                            <Form.Item
                                name="password"
                                rules={[
                                    () => ({
                                        validator(_, value) {
                                            if (value && value !== '') {
                                                // CHECK INPUT VIETNAMESE
                                                if (
                                                    !/^[^ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễếệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ]*$/.test(
                                                        value,
                                                    )
                                                ) {
                                                    return Promise.reject(
                                                        new Error(MESSAGE.MESSAGE_INPUT_INVALID),
                                                    );
                                                }

                                                // CHECK INPUT JAPANESE
                                                if (
                                                    /^[一-龠ぁ-ゔァ-ヴー、ぁ-ん' '　']*$/.test(
                                                        value,
                                                    )
                                                ) {
                                                    return Promise.reject(
                                                        new Error(MESSAGE.MESSAGE_INPUT_INVALID),
                                                    );
                                                }

                                                if (value?.length < REGEX_MIN_PASSWORD.min) {
                                                    return Promise.reject(
                                                        new Error(REGEX_MIN_PASSWORD.message),
                                                    );
                                                } else if (value?.length > REGEX_MAX_PASSWORD.max) {
                                                    return Promise.reject(
                                                        new Error(REGEX_MAX_PASSWORD.message),
                                                    );
                                                } else {
                                                    const found = REGEX.PASSWORD_REGEX.test(value);
                                                    if (!found) {
                                                        return Promise.reject(
                                                            new Error(MESSAGE_PASSWORD),
                                                        );
                                                    } else {
                                                        return Promise.resolve();
                                                    }
                                                }
                                            } else {
                                                if (radioValue === 1) {
                                                    return Promise.reject(
                                                        new Error(MESSAGE.MESSAGE_REQUIRED),
                                                    );
                                                } else {
                                                    return Promise.resolve();
                                                }
                                            }
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password
                                    disabled={radioValue === 2 ? true : false}
                                    style={{ width: 375, height: 40 }}
                                    placeholder="パスワードを入力してください"
                                    value={password}
                                    maxLength={REGEX_MAX_PASSWORD.max}
                                    onChange={onChangePassword}
                                />
                            </Form.Item>
                        </div>

                        <div className="section1__input-repassword">
                            <span className="section1__input-span">パスワード再入力</span>
                            <Form.Item
                                name="passwordConfirm"
                                rules={[
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (value && value !== '') {
                                                // CHECK INPUT VIETNAMESE
                                                if (
                                                    !/^[^ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễếệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ]*$/.test(
                                                        value,
                                                    )
                                                ) {
                                                    return Promise.reject(
                                                        new Error(MESSAGE.MESSAGE_INPUT_INVALID),
                                                    );
                                                }

                                                // CHECK INPUT JAPANESE
                                                if (
                                                    /^[一-龠ぁ-ゔァ-ヴー、ぁ-ん' '　']*$/.test(
                                                        value,
                                                    )
                                                ) {
                                                    return Promise.reject(
                                                        new Error(MESSAGE.MESSAGE_INPUT_INVALID),
                                                    );
                                                }

                                                if (!value || getFieldValue('password') === value) {
                                                    if (value?.length < REGEX_MIN_PASSWORD.min) {
                                                        return Promise.reject(
                                                            new Error(REGEX_MIN_PASSWORD.message),
                                                        );
                                                    }
                                                    if (value?.length > REGEX_MAX_PASSWORD.max) {
                                                        return Promise.reject(
                                                            new Error(REGEX_MAX_PASSWORD.message),
                                                        );
                                                    }
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(
                                                    new Error(MESSAGE.MESSAGE_PASSWORD_NOT_MATCH),
                                                );
                                            } else {
                                                if (radioValue === 1) {
                                                    return Promise.reject(
                                                        new Error(MESSAGE.MESSAGE_REQUIRED),
                                                    );
                                                } else {
                                                    return Promise.resolve();
                                                }
                                            }
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password
                                    disabled={
                                        radioValue === 2 ||
                                        handler.password?.length < 8 ||
                                        !REGEX.PASSWORD_REGEX.test(handler.password)
                                    }
                                    style={{ width: 375, height: 40 }}
                                    placeholder="パスワードを再入力してください"
                                    value={rePassword}
                                    maxLength={REGEX_MAX_PASSWORD.max}
                                    onChange={onChangeRePassword}
                                />
                            </Form.Item>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default OM005Section1;
