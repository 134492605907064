import "../../../../../assets/styles/CM/CM0034.css";
import { CM0039Handler } from "../handler/HandlerImpl";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import PaginationComponentList from "../../../../../common/components/pagination/PaginationComponentList";
import {
  ReSummaryCompanyDocumentOutSourceCostSubmitedListResult,
  TableColumsType,
} from "../../entity/Entity";
import SortTable from "../../../../../common/components/sort-table/SortTable";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import CM017_2Component from "../../../CM017_2";
import CM0017_3Component from "../../../CM0017_3";
import moment from "moment";
import COMMON, {
  STATUS_CM_CHILD_TABLE,
} from "../../../../../common/constants/COMMON";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import helpers from "../../../../../common/helpers/common";
import ModalRejectView from "../../../../../common/components/modal-reject/ModalRejectView";
import WarningIcon from "../../../../../assets/icons/warning-red.svg";

type Props = {
  handler: CM0039Handler;
};

export const CM003_PARAM_SUFFIX = "3";

const CM0039UI = ({ handler }: Props) => {
  const [searchParams] = useSearchParams();
  const documentIdCM0017_3 = useSelector(
    (state: RootState) => state.cm0017_3.documentId
  );
  const visibleCM00172 = useSelector(
    (state: RootState) => state.cm017_2.isVisible
  );
  const size =
    searchParams.get("size" + CM003_PARAM_SUFFIX) || COMMON.DEFAULT_SIZE + "";
  const page =
    searchParams.get("page" + CM003_PARAM_SUFFIX) || COMMON.DEFAULT_PAGE;
  const statusId = searchParams.get("status" + CM003_PARAM_SUFFIX);
  const keyword = searchParams.get("keyword" + CM003_PARAM_SUFFIX) || "";
  const sortBy = searchParams.get("sortBy" + CM003_PARAM_SUFFIX);
  const sortType = searchParams.get("sortType" + CM003_PARAM_SUFFIX);
  const fromDate =
    searchParams.get("from" + CM003_PARAM_SUFFIX) ||
    moment().startOf("month").format(COMMON.FORMAT_DATE2);
  const toDate =
    searchParams.get("to" + CM003_PARAM_SUFFIX) ||
    moment().format(COMMON.FORMAT_DATE2);
  const isRefresh = useSelector((state: RootState) => state.cm0039.isRefresh);
  useEffect(() => {
    (async () => {
      await handler.getReSummaryCompanyDocumentOutSourceCostSubmitedList({
        from: helpers.getFilterTime(fromDate),
        to: helpers.getFilterTimeTo(toDate),
        statusId:
          statusId && Number(statusId) !== 0 ? Number(statusId) : undefined,
        page: page ? Number(page) : undefined,
        size: size ? Number(size) : undefined,
        sortBy: sortBy ?? undefined,
        sortType: sortType ?? undefined,
        keyword,
      });
    })();
  }, [
    keyword,
    sortBy,
    sortType,
    fromDate,
    toDate,
    size,
    page,
    statusId,
    isRefresh,
  ]);

  const columns: ColumnsType<TableColumsType> = [
    {
      key: "No",
      title: "No",
      width: 110,
      align: "center",
      render: (_, __, index) => {
        return (
          <span className="collapsed-table-row-style">{`${
            index + 1 + (Number(page) - 1) * Number(size)
          }`}</span>
        );
      },
    },
    {
      key: "資料名",
      title: "資料名",
      align: "center",
      width: 343,
      render: (
        record: ReSummaryCompanyDocumentOutSourceCostSubmitedListResult
      ) => {
        return (
          <span className="collapsed-table-row-style">
            {record?.fileName ?? ""}
          </span>
        );
      },
    },
    {
      key: "ステータス",
      title: "ステータス",
      align: "center",
      width: 343,
      render: (
        record: ReSummaryCompanyDocumentOutSourceCostSubmitedListResult
      ) => {
        return (
          <span className="collapsed-table-row-style">
            <span
              style={{
                color:
                  record.status?.code ===
                  STATUS_CM_CHILD_TABLE.MONEY_REJECT.CODE
                    ? STATUS_CM_CHILD_TABLE.MONEY_REJECT.COLOR
                    : record.status?.code ===
                      STATUS_CM_CHILD_TABLE.MONEY_APPROVED.CODE
                    ? STATUS_CM_CHILD_TABLE.MONEY_APPROVED.COLOR
                    : "#FF9054",
              }}
            >{`${record?.status?.name ?? ""}`}</span>
            {record.status?.code === STATUS_CM_CHILD_TABLE.MONEY_REJECT.CODE ? (
              <img
                alt=""
                src={WarningIcon}
                className="cm053-icon"
                onClick={(e) => {
                  e.stopPropagation();
                  handler.setRejectReason(record.reason);
                  handler.setVisibleRejectView(true);
                }}
              />
            ) : null}
          </span>
        );
      },
    },
    {
      key: "提出日",
      title: (
        <SortTable
          columnTitle="提出日"
          sortOrder={handler.checkSortOrder("filingDate", sortType, sortBy)}
          onChange={(sortOrder) => handler.onTableSort("filingDate", sortOrder)}
        />
      ),
      align: "center",
      width: 343,
      render: (
        record: ReSummaryCompanyDocumentOutSourceCostSubmitedListResult
      ) => {
        return (
          <span className="collapsed-table-row-style">
            {record.submittedDate
              ? moment(record.submittedDate).format(COMMON.FORMAT_DATE_OM)
              : ""}
          </span>
        );
      },
    },
    {
      key: "提出者",
      title: "提出者",
      align: "center",
      width: 343,
      render: (
        record: ReSummaryCompanyDocumentOutSourceCostSubmitedListResult
      ) => {
        return (
          <span className="collapsed-table-row-style">
            {`${record?.user?.fullName ?? ""}${
              record?.user?.branchName ? `（${record?.user?.branchName}）` : ""
            }`}
          </span>
        );
      },
    },
  ];

  return (
    <div className="cm0034-table">
      <Table
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) =>
              handler.openCM0017_3({ ...record, from: fromDate, to: toDate }),
          };
        }}
        rowClassName="button-style"
        bordered
        columns={columns}
        pagination={false}
        dataSource={helpers.addKeyToDataSource(handler.costList)}
        scroll={{}}
      />
      {helpers.addKeyToDataSource(handler.costList).length > 0 ? (
        <PaginationComponentList
          total={handler.total}
          page={Number(page)}
          size={Number(size)}
          setPage={handler.onPageChange}
          setSize={handler.onSizeChange}
        />
      ) : (
        <></>
      )}
      {documentIdCM0017_3 ? (
        <CM0017_3Component
          afterSuccess={handler.afterCM0017_3Success}
          hasWorkflow={true}
        />
      ) : (
        <></>
      )}
      <ModalRejectView
        visible={handler.visibleRejectView}
        funcClose={() => handler.setVisibleRejectView(false)}
        rejectReason={handler.rejectReason}
      />
    </div>
  );
};

export default CM0039UI;
