import AttendanceApi from "../../../infrastructure/api/ApiAttendance";
import AttendanceService from "../../../usecase/ServiceAttendance";
import AttendanceHandler from "../../handler/HandlerAttendance";
import AttendanceUI from "./ui/UI";

interface Props {
  constructionId: number;
  userId: number;
  attendance: any;
}

const AttendanceComponent = ({ constructionId, userId, attendance }: Props) => {
  const api = AttendanceApi();
  const service = AttendanceService(api);
  const handler = AttendanceHandler(service);
  return (
    <AttendanceUI
      handler={handler}
      constructionId={constructionId}
      userId={userId}
      attendance={attendance}
    />
  );
};

export default AttendanceComponent;
