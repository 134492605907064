import AM020Api from "./infrastructure/api/Api";
import AM020Handler from "./presenter/handler/Handler";
import AM020UI from "./presenter/ui/UI";
import AM020Service from "./usecase/Service";

const AM020Component = () => {
  const am020Api = AM020Api();
  const am020Service = AM020Service(am020Api);
  const am020Handler = AM020Handler(am020Service);
  return <AM020UI handler={am020Handler} />;
};

export default AM020Component;
