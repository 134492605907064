import { AxiosResponse } from 'axios';
import axiosConfig from '../../../../../config/axiosConfig';

const PlannedPaidAmountApi = () => {
    const getMoneySummaryPlanCost = async (params: {
        constructionId: number;
        from?: string;
        to?: string;
    }): Promise<AxiosResponse<any, any>> => {
        const url = '/money/summary/plan-cost';
        return await axiosConfig.get(url, { params });
    }; // tested

    return { getMoneySummaryPlanCost };
};

export default PlannedPaidAmountApi;
