import { useEffect, useState, useRef } from 'react';
import { Tooltip } from 'antd';

type props = {
    name: string;
    titleLevel: string;
    laborCost: number | string;
    member: string;
    description: string;
    totalWorkingDay: string;
    handleChangePage(): void;
};
const CooperationCompany = ({
    name,
    titleLevel,
    laborCost,
    member,
    description,
    totalWorkingDay,
    handleChangePage,
}: props) => {
    const ref: any = useRef(null);
    const [checkTooltip, setCheckTooltip] = useState(false);

    useEffect(() => {
        if (ref && ref.current)
            setCheckTooltip(ref?.current?.scrollWidth > ref?.current?.offsetWidth);
    }, []);

    return (
        <Tooltip title="クリックして詳細を表示します。" align={{ offset: [0, 20] }}>
            <div className="am005-cooperation flex-box am005-cursor" onClick={handleChangePage}>
                <div className="am005-cooperation-left flex-box">
                    <div className="flex-box">
                        {/* <svg
              width="2"
              height="20"
              viewBox="0 0 2 20"
              fill="#418FEB"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginRight: 12 }}
            >
              <line
                x1="1"
                y1="1"
                x2="0.999999"
                y2="19"
                stroke="#418FEB"
                stroke-width="2"
                stroke-linecap="round"
              ></line>
            </svg> */}
                        <Tooltip
                            title={checkTooltip ? `${name || ''}（${description || ''}）` : ''}
                            overlayInnerStyle={{
                                color: '#fff',
                                margin: '0 -4%',
                            }}
                        >
                            <div ref={ref} className="am005-text-long">
                                <span className="am005-text-bold-14 ">{name}</span>
                                <span className="am005-cooperation-left-text">
                                    ( {description})
                                </span>
                            </div>
                        </Tooltip>
                    </div>
                    <div className="am005-level">（{titleLevel}）</div>
                </div>
                <div className="am005-cooperation-right flex-box">
                    <div className="am005-header-item">
                        <div className="am005-separator"></div>
                        <div className="am005-header-item-content">
                            <span>合計金額: </span>
                            <span className="am005-text-bold-14">{laborCost}円</span>
                        </div>
                    </div>
                    <div className="am005-header-item">
                        <div className="am005-separator"></div>
                        <div className="am005-header-item-content">
                            <span>稼働人工数:</span>
                            <span className="am005-text-bold-14 ml-8">{totalWorkingDay}人工</span>
                        </div>
                    </div>
                </div>
            </div>
        </Tooltip>
    );
};

export default CooperationCompany;
