import axiosConfig from "../../../../../config/axiosConfig";
  
  const AM014Api = () => {
    const getRequestAttendance = async(params:any):Promise<any>=>{
      try {
        const url = "/request/attendance";
        const response = await axiosConfig.get(url, { params });
        return response.data;
      } catch (error) {
        throw error;
      }
    }
    return {
      getRequestAttendance
    };
  };
  
  export default AM014Api;
  