import ErrorNotification from "../../../../common/components/notification/ErrorNotification";
import SuccessNotification from "../../../../common/components/notification/SuccessNotification";
import { NOTIFICATION_TITLE } from "../../../../common/constants/MESSAGE";
import {
  BaseResponseType,
  MoneySummaryConstructionCostInformationResult,
  ThresholdPutType,
} from "../entity/Entity";
import { MaterialCostAPIImpl } from "./ServiceImpl";

const MaterialCostService = (api: MaterialCostAPIImpl) => {
  const getMoneySummaryConstructionCostInformation = async (params: {
    constructionId: number;
  }): Promise<MoneySummaryConstructionCostInformationResult | null> => {
    try {
      const response = await api.getMoneySummaryConstructionCostInformation(
        params
      );
      return response?.data?.results ?? null;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return null;
    }
  };
  const putMoneySummaryConstructionCostDirectThresholdUpdate = async (
    data: ThresholdPutType
  ): Promise<BaseResponseType> => {
    try {
      const response =
        await api.putMoneySummaryConstructionCostDirectThresholdUpdate(data);
      SuccessNotification(
        response?.data?.message ?? NOTIFICATION_TITLE.SUCCESS
      );
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      throw error;
    }
  };
  const putMoneySummaryConstructionCostMaterialThresholdUpdate = async (
    data: ThresholdPutType
  ): Promise<BaseResponseType> => {
    try {
      const response =
        await api.putMoneySummaryConstructionCostMaterialThresholdUpdate(data);
      SuccessNotification(
        response?.data?.message ?? NOTIFICATION_TITLE.SUCCESS
      );
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      throw error;
    }
  };
  const putMoneySummaryConstructionCostOutsourceThresholdUpdate = async (
    data: ThresholdPutType
  ): Promise<BaseResponseType> => {
    try {
      const response =
        await api.putMoneySummaryConstructionCostOutsourceThresholdUpdate(data);
      SuccessNotification(
        response?.data?.message ?? NOTIFICATION_TITLE.SUCCESS
      );
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      throw error;
    }
  };
  const putMoneySummaryConstructionCostLaborThresholdUpdate = async (
    data: ThresholdPutType
  ): Promise<BaseResponseType> => {
    try {
      const response =
        await api.putMoneySummaryConstructionCostLaborThresholdUpdate(data);
      SuccessNotification(
        response?.data?.message ?? NOTIFICATION_TITLE.SUCCESS
      );
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      throw error;
    }
  };
  const putMoneySummaryConstructionCostIndirectThresholdUpdate = async (
    data: ThresholdPutType
  ): Promise<BaseResponseType> => {
    try {
      const response =
        await api.putMoneySummaryConstructionCostIndirectThresholdUpdate(data);
      SuccessNotification(
        response?.data?.message ?? NOTIFICATION_TITLE.SUCCESS
      );
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      throw error;
    }
  };

  const doExportCM004 = async (params: any): Promise<any> => {
        return await api.doExportCM004(params);
    }

  return {
    getMoneySummaryConstructionCostInformation,
    putMoneySummaryConstructionCostDirectThresholdUpdate,
    putMoneySummaryConstructionCostMaterialThresholdUpdate,
    putMoneySummaryConstructionCostOutsourceThresholdUpdate,
    putMoneySummaryConstructionCostLaborThresholdUpdate,
    putMoneySummaryConstructionCostIndirectThresholdUpdate,
    doExportCM004,
  };
};

export default MaterialCostService;
