import CR001MailContractorApi from "./infrastructure/api/Api";
import CR001MailContractorService from "./usecase/Service";
import CR001MailContractorHandler from "./presenter/handler/Handler";
import CR001MailContractor from "./presenter/ui/UI";

interface props {
  afterSendMail?: (keyItem: React.Key[]) => void;
}

const CR001MailContractorComponent = ({ afterSendMail }: props) => {
  const cr001MailContractorApi = CR001MailContractorApi();
  const cr001MailContractorService = CR001MailContractorService(
    cr001MailContractorApi
  );
  const cr001MailContractorHandler = CR001MailContractorHandler(
    cr001MailContractorService,
    afterSendMail
  );
  return <CR001MailContractor handler={cr001MailContractorHandler} />;
};

export default CR001MailContractorComponent;
