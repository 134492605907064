// Built-in
import React from 'react';

type props = {
    value: number;
};

const NumberFormatTextComponent = ({ value }: props) => (
    <span className="collapsed-table-row-style">
        {`${value ? value.toLocaleString('en-US', { maximumFractionDigits: 2 }) : 0}円`}
    </span>
);

export default NumberFormatTextComponent;
