import React, { memo, useEffect } from "react";
import { HandlerImpl } from "../handler/HandlerImpl";
import { Table, Button, Empty } from "antd";
import { ColumnsType } from "antd/lib/table";
import PaginationComponentList from "../../../../../../common/components/pagination/PaginationComponentList";
import IconCircleCheck from "../../../../../../assets/icons/akar-icons_circle-check-fill.svg";
import EmptyData from "../../../../../../assets/icons/empty-data.svg";
import ModalReject from "../../../../../../common/components/modal-reject/ModalReject";
import ModalRejectView from "../../../../../../common/components/modal-reject/ModalRejectView";
import WarningIcon from "../../../../../../assets/icons/warning-red.svg";
import { STATUS_CM_CHILD_TABLE } from "../../../../../../common/constants/COMMON";
import moment from "moment";
import COMMON from "../../../../../../common/constants/COMMON";
import CM0017_3Component from "../../../../CM0017_3";
import { RootState } from "../../../../../../store";
import { useSelector } from "react-redux";
import helpers from "../../../../../../common/helpers/common";
import {
  AUTHORIZATION_CODE,
  SYSTEM_ROLES,
} from "../../../../../../common/constants/AUTHORIZATION";
interface props {
  handler: HandlerImpl;
}

type DataType = {
  no: number;
  fileName: string;
  status: {
    id: number;
    name: string;
    code: string;
    type: string;
  };
  filingDate: string;
  submitter: string;
  reason: string;
  canApprove: boolean;
};

const MainUI = ({ handler }: props) => {
  const documentIdCM0017_3 = useSelector(
    (state: RootState) => state.cm0017_3.documentId
  );

  useEffect(() => {
    handler.getCompanyDocumentOutsourceCostReviewList({
      page: handler.page,
      size: handler.size,
      sortType: handler.sortType ? handler.sortType : undefined,
      sortBy: handler.sortType ? "submittedDate" : undefined,
      from: !handler.clear ? helpers.getFilterTime(handler.from) : undefined,
      to: !handler.clear ? helpers.getFilterTimeTo(handler.to) : undefined,
      statusId: handler.status ? handler.status : undefined,
      keyword: handler.keyword ? handler.keyword : undefined,
    });
  }, [
    handler.sortType,
    handler.page,
    handler.size,
    handler.refresh,
    handler.from,
    handler.to,
    handler.clear,
    handler.keyword,
    handler.status,
  ]);

  const columns: ColumnsType<DataType> = [
    {
      key: "no",
      dataIndex: "no",
      title: "No",
      width: 60,
      align: "center",
    },
    {
      key: "fileName",
      dataIndex: "fileName",
      title: "資料名",
      align: "center",
      onCell: (record) => {
        return {
          onClick: () => handler.handleViewModal(record),
          style: { cursor: "pointer" },
        };
      },
    },
    {
      key: "status",
      dataIndex: "status",
      title: "ステータス",
      align: "center",
      render: (record, row) => {
        return (
          <>
            <span
              style={{
                color:
                  record.code === STATUS_CM_CHILD_TABLE.MONEY_REJECT.CODE
                    ? STATUS_CM_CHILD_TABLE.MONEY_REJECT.COLOR
                    : record.code === STATUS_CM_CHILD_TABLE.MONEY_APPROVED.CODE
                    ? STATUS_CM_CHILD_TABLE.MONEY_APPROVED.COLOR
                    : "#FF9054",
              }}
            >{`${record.name ?? ""}`}</span>

            {record.code === STATUS_CM_CHILD_TABLE.MONEY_REJECT.CODE ? (
              <img
                alt=""
                src={WarningIcon}
                className="cm053-icon"
                onClick={() => handler.handleViewRejectReason(row.reason)}
              />
            ) : null}
          </>
        );
      },
    },
    {
      key: "submittedDate",
      dataIndex: "submittedDate",
      title: "提出日",
      align: "center",
      sorter: true,
      showSorterTooltip: false,
      sortDirections: ["ascend", "descend", "ascend"],
      sortOrder: !handler.sortType
        ? undefined
        : handler.sortType === "ASC"
        ? "ascend"
        : "descend",
      render: (record) => {
        if (!record) return "";
        return moment(record).format(COMMON.FORMAT_DATE_OM);
      },
    },
    {
      key: "user",
      dataIndex: "user",
      title: "提出者",
      align: "center",
      render: (record) => {
        return `${record.fullName ?? ""}${
          record?.branchName ? `（${record?.branchName}）` : ""
        }`;
      },
    },
  ];
  return (
    <div className="cm047">
      {documentIdCM0017_3 ? (
        <CM0017_3Component
          hasWorkflow={true}
          disableSubmitOrReject={
            !(
              handler.detailSubmit?.status ===
              STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE
            )
          }
          onReject={() => handler.handleRejectData(handler.detailSubmit?.id)}
          onSubmit={() => handler.handleSubmitData(handler.detailSubmit?.id)}
        />
      ) : (
        <></>
      )}
      <ModalReject
        visible={handler.visibleReject}
        form={handler.formReject}
        funcCancel={handler.handleCancelReject}
        funcSubmit={handler.handleSubmitReject}
        funcChangeValue={handler.handleChangeValueReject}
      />
      <ModalRejectView
        visible={handler.visibleRejectView}
        funcClose={handler.handleCloseViewRejectReason}
        rejectReason={handler.rejectReason}
      />
      <div
        className="cm047-table"
        style={{
          minHeight: 520,
        }}
      >
        <Table
          bordered
          columns={columns}
          pagination={false}
          dataSource={handler.dataTable}
          scroll={{
            y: "50vh",
          }}
          // rowSelection={{
          //     selectedRowKeys: handler.selectedRow,
          //     onChange: handler.handleChangeSelectedRow,
          //     getCheckboxProps: (record) => {
          //         return {
          //             disabled: !record?.canApprove,
          //         };
          //     },
          // }}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="データなし"
              />
            ),
          }}
          onChange={(_, filter, sorter) => handler.handleSortTable(sorter)}
        />
        {handler.dataTable.length !== 0 && <PaginationComponentList
          disabled={handler.dataTable.length !== 0 ? false : true}
          total={handler.total}
          page={handler.page}
          size={handler.size}
          setPage={handler.onPageChange}
          setSize={handler.onSizeChange}
        />}
      </div>
      {/* <div className="cm047-btn-wrapper">
        <div className="cm047-button">
          {permission?.includes(AUTHORIZATION_CODE.S35) || isRoleAdmin ? (
            <Button
              className="button-purple cm047-btn-submit"
              icon={<img alt="" src={IconCircleCheck} />}
              size="large"
              disabled={handler.funcDisableBtn()}
              onClick={() => handler.handleSubmitData()}
            >
              承認
            </Button>
          ) : (
            <div></div>
          )}

          <Button
            size="large"
            className="button-warning cm047-btn-reject"
            onClick={() => handler.handleRejectData()}
            disabled={handler.funcDisableBtn()}
          >
            承認
          </Button>
        </div>
      </div> */}
    </div>
  );
};

export default memo(MainUI);
