import { useDispatch } from "react-redux";
import { useState } from "react";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import { PM025UseCase } from "../../usecase/ServiceImpl";
import { useSearchParams } from "react-router-dom";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import MESSAGE, {
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import { Form } from "antd";
import ConfirmModal from "../../../../../common/components/modal/ConfirmModal";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import helpers from "../../../../../common/helpers/common";
import {
  setIsVisiblePM028,
  setOrderIdPM028,
} from "../../../PM028/presenter/slice/Slice";

export const PARAMS = {
  page: "page",
  size: "size",
  sort_type: "sort_type",
  sort_by: "sort_by",
  keyword: "keyword",
  tab: "tab",
};

export const TABS_KEY = [
  {
    name: "外注業者一覧",
    key: "subcontractors",
  },
  {
    name: "材料業者一覧",
    key: "materialSupplier",
  },
  {
    name: "発注元会社一覧",
    key: "orderingCompanies",
  },
];

export const PARAM_SORT_BY_DEFAULT = "createdAt";
export const PARAM_SORT_TYPE_DEFAULT = "ASC";

const PM025Handler = (pm025Service: PM025UseCase) => {
  const dispatch = useDispatch();
  const [formCompany] = Form.useForm();
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const [total, setTotal] = useState(0);
  const [isOpenModalCompany, setIsOpenModalCompany] = useState(false);
  const [typeAction, setTypeAction] = useState("");
  const [companyUpdate, setCompanyUpdate] = useState<any>({});
  const [isRefresh, setRefresh] = useState(false);
  const [isChangeForm, setIsChangeForm] = useState(false);

  const [formMaterial] = Form.useForm();
  const [isVisibleMaterial, setVisibleMaterial] = useState(false);
  const [isEditCompany, setIsEditCompany] = useState<boolean>(false);
  const [isEditMaterial, setIsEditMaterial] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("");

  const [searchParams, setSearchParams] = useSearchParams();
  const page = Number(searchParams.get(PARAMS.page)) || 1;
  const size = Number(searchParams.get(PARAMS.size)) || 10;
  const sortBy = searchParams.get(PARAMS.sort_by) || PARAM_SORT_BY_DEFAULT;
  const sortType =
    searchParams.get(PARAMS.sort_type) || PARAM_SORT_TYPE_DEFAULT;
  const keyword = searchParams.get(PARAMS.keyword) || "";
  const tab = searchParams.get(PARAMS.tab) || TABS_KEY[0].key;

  const getData = async (params: any) => {
    try {
      dispatch(setLoading(true));
      const response = await pm025Service.getData(params);
      if (response.data.results.length === 0 && params.page > 1) {
        searchParams.set("page", page - 1 + "");
        setSearchParams(searchParams);
        return;
      }
      setData(handleDataRaw(response.data.results));
      setTotal(response?.data?.pagination?.count);
      handlePageDataEmpty(response.data.results, params);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };
  const createCompany = async (data: any) => {
    try {
      dispatch(setLoading(true));
      const response = await pm025Service.createCompany(data);
      SuccessNotification(response?.data.message, "");
      setRefresh(!isRefresh);
      setIsOpenModalCompany(false);
      setSearchParams({});
      setSelectedRowKeys([]);
      setIsChangeForm(false);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };
  const updateCompany = async (data: any) => {
    try {
      dispatch(setLoading(true));
      const response = await pm025Service.updateCompany(data);
      SuccessNotification(response?.data.message, "");
      setRefresh(!isRefresh);
      setIsOpenModalCompany(false);
      setIsChangeForm(false);
      searchParams.delete(PARAMS.page);
      setSearchParams(searchParams);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };
  const deleteCompany = async (data: any) => {
    try {
      dispatch(setLoading(true));
      const response = await pm025Service.deleteCompany(data);
      SuccessNotification(response?.data.message, "");
      setRefresh(!isRefresh);
      setSelectedRowKeys([]);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getMaterialCompany = async (params: any) => {
    try {
      dispatch(setLoading(true));
      const response = await pm025Service.getMaterialCompany(params);
      if (response.data.results.length === 0 && params.page > 1) {
        searchParams.set("page", page - 1 + "");
        setSearchParams(searchParams);
        return;
      }
      setData(handleDataRaw(response.data.results));
      setTotal(response?.data?.pagination?.count);
      handlePageDataEmpty(response.data.results, params);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getOrderingCompanyList = async (params: any) => {
    try {
      dispatch(setLoading(true));
      const data = await pm025Service.getOrderingCompanyList(params);
      if ((data?.results ?? []).length === 0 && params.page > 1) {
        searchParams.set("page", page - 1 + "");
        setSearchParams(searchParams);
        return;
      }
      setData(handleDataRaw(data.results));
      setTotal(data?.pagination?.count);
      handlePageDataEmpty(data.results, params);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const deleteOrderingCompanyDelete = async (data: any) => {
    try {
      dispatch(setLoading(true));
      const responseData = await pm025Service.deleteOrderingCompanyDelete(data);
      SuccessNotification(responseData?.message, "");
      setRefresh(!isRefresh);
      setSelectedRowKeys([]);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };
  const createMaterialCompany = async (data: any) => {
    dispatch(setLoading(true));
    try {
      const response = await pm025Service.createMaterialCompany(data);
      SuccessNotification(response?.data.message, "");
      setRefresh(!isRefresh);
      setVisibleMaterial(false);
      setIsChangeForm(false);
      setSearchParams({ tab: tab });
      setSelectedRowKeys([]);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };
  const updateMaterialCompany = async (data: any) => {
    dispatch(setLoading(true));
    try {
      const response = await pm025Service.updateMaterialCompany(data);
      SuccessNotification(response?.data.message, "");
      setRefresh(!isRefresh);
      setVisibleMaterial(false);
      setIsChangeForm(false);
      searchParams.delete(PARAMS.page);
      setSearchParams(searchParams);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };
  const deleteMaterialCompany = async (data: any) => {
    dispatch(setLoading(true));
    try {
      const response = await pm025Service.deleteMaterialCompany(data);
      SuccessNotification(response?.data.message, "");
      setRefresh(!isRefresh);
      setSelectedRowKeys([]);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleDataRaw = (raw: any) => {
    if (!raw) return [];
    const results = raw?.map((element: any) => {
      return {
        ...element,
        key: element?.id,
      };
    });
    return results;
  };

  const handlePageDataEmpty = (raw: any[], params: { page: number }) => {
    if (!raw) searchParams.delete(PARAMS.page);
    if (raw?.length === 0 && params.page > 1) {
      searchParams.delete(PARAMS.page);
    }
    setSearchParams(searchParams);
  };

  const handleChangeSelectedRowKey = (newSelectedRowKeys: any) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleChangePage = (page: number) => {
    searchParams.set(PARAMS.page, `${page}`);
    setSearchParams(searchParams);
    setSelectedRowKeys([]);
  };

  const handleChangeSize = (sizeValue: number) => {
    searchParams.set(PARAMS.size, `${sizeValue}`);
    searchParams.delete(PARAMS.page);
    setSearchParams(searchParams);
    setSelectedRowKeys([]);
  };

  const handleSetDefaultSorter = (sortText: string) => {
    if (sortType === "") return null;
    if (sortText === sortBy) return sortType;
  };

  const handleChangeTablePM025 = (
    pagination: any,
    filters: any,
    sorter: any
  ) => {
    let order = "";
    if (sorter.order) order = sorter.order;
    searchParams.set(PARAMS.sort_type, order);
    searchParams.set(PARAMS.sort_by, sorter.field);
    searchParams.delete(PARAMS.page);

    if (order === "") {
      searchParams.delete(PARAMS.sort_by);
      searchParams.delete(PARAMS.sort_type);
    }
    setSelectedRowKeys([]);
    setSearchParams(searchParams);
  };

  let myVar: any;
  const [formSearch] = Form.useForm();
  function onSearchChange() {
    if (myVar) clearTimeout(myVar);
    myVar = setTimeout(function () {
      const searchText = formSearch.getFieldValue("keyword");
      formSearch.setFieldValue("keyword", searchText?.trim());
      onSearch(searchText?.trim());
    }, 1000);
  }

  const onSearch = (searchText: string) => {
    searchParams.set(PARAMS.keyword, searchText);
    if (searchText === "") {
      searchParams.delete(PARAMS.keyword);
    }
    searchParams.delete(PARAMS.page);
    setSelectedRowKeys([]);
    setSearchParams(searchParams);
  };
  const handleSubmitFormCompany = (value: any) => {
    if (tab === TABS_KEY[0].key) {
      const results: { [keyword: string]: any } = {
        name: value.companyName,
        email: value.email,
        address: value.address ?? "",
        phoneNumber: value.phoneNumber?.replaceAll("-", "") ?? "",
      };

      if (typeAction === "create") {
        createCompany(results);
      } else {
        results.id = companyUpdate?.id;
        updateCompany(results);
      }
    } else {
      const results: { [keyword: string]: any } = {
        name: value.companyName,
        email: value.email,
        address: value.address ?? "",
        phoneNumber: value.phoneNumber?.replaceAll("-", "") ?? "",
      };

      if (typeAction === "create") {
        createMaterialCompany(results);
      } else {
        results.id = companyUpdate?.id;
        updateMaterialCompany(results);
      }
    }
  };

  const handleChangeFormCompany = () => {
    setIsChangeForm(true);
  };

  const handleOpenModalCompany = () => {
    switch (tab) {
      case TABS_KEY[0].key:
        setIsOpenModalCompany(true);
        formCompany.resetFields();
        break;
      case TABS_KEY[1].key:
        setVisibleMaterial(true);
        formMaterial.resetFields();
        break;
      default:
        dispatch(setIsVisiblePM028(true));
        break;
    }
    setTypeAction("create");
  };

  const handleCloseModalCompany = () => {
    if (isChangeForm) {
      ConfirmModal({
        onOk: () => {
          setIsOpenModalCompany(false);
          setIsChangeForm(false);
          setVisibleMaterial(false);
          setIsEditCompany(false);
          setIsEditMaterial(false);
        },
        onCancel: () => { },
        className: "confirm__modal modal-ml",
        title: MESSAGE.MESSAGE_020,
        description: MESSAGE.MESSAGE_022_1,
        extraDescription: MESSAGE.MESSAGE_022_2,
        canceText: "キャンセル",
        okText: "はい",
      });
    } else {
      setIsOpenModalCompany(false);
      setVisibleMaterial(false);
      setIsEditCompany(false);
      setIsEditMaterial(false);
    }
  };
  const handleDeleteCompany = (row: any, description: any) => {
    ConfirmModal({
      onOk: () => {
        if (tab === TABS_KEY[0].key) {
          deleteCompany({
            listId: [row?.id],
          });
        } else if (tab === TABS_KEY[2].key) {
          deleteOrderingCompanyDelete({
            listId: [row?.id],
          });
        } else {
          deleteMaterialCompany({
            listId: [row?.id],
          });
        }
      },
      onCancel: () => { },
      title: "削除確認",
      className: "confirm__modal ",
      description: description,
      canceText: "キャンセル",
      okText: "削除",
    });
  };
  const handleEditCompany = (row: any) => {
    if (tab === TABS_KEY[0].key) {
      const results = {
        companyName: row?.name,
        email: row?.email,
        phoneNumber: row?.phoneNumber
          ? helpers.getPhoneNumberFormat(row?.phoneNumber)
          : "",
        address: row?.address ?? "",
      };
      setStatus(row.status?.code)
      formCompany.setFieldsValue(results);
      setIsOpenModalCompany(true);
      setIsEditCompany(true);
    } else if (tab === TABS_KEY[2].key) {
      dispatch(setOrderIdPM028(row.id));
    } else {
      const results = {
        companyName: row?.name,
        email: row?.email,
        phoneNumber: row?.phoneNumber
          ? helpers.getPhoneNumberFormat(row?.phoneNumber)
          : "",
        address: row?.address ?? "",
      };
      formMaterial.setFieldsValue(results);
      setVisibleMaterial(true);
      setIsEditMaterial(true);
    }

    setCompanyUpdate(row);
    setTypeAction("update");
  };

  const handleDeleteMultiCompany = (description: any) => {
    ConfirmModal({
      onOk: () => {
        if (tab === TABS_KEY[0].key)
          deleteCompany({
            listId: selectedRowKeys,
          });
        else if (tab === TABS_KEY[2].key) {
          deleteOrderingCompanyDelete({
            listId: selectedRowKeys,
          });
        } else
          deleteMaterialCompany({
            listId: selectedRowKeys,
          });
      },
      onCancel: () => { },
      title: "削除確認",
      description: description,
      canceText: "キャンセル",
      okText: "削除",
      className: "confirm__modal ",
    });
  };

  const onChangeTab = (tab: string) => {
    searchParams.set(PARAMS.tab, tab);
    searchParams.delete(PARAMS.page);
    searchParams.delete(PARAMS.size);
    searchParams.delete(PARAMS.keyword);
    searchParams.delete(PARAMS.sort_by);
    searchParams.delete(PARAMS.sort_type);
    formSearch.resetFields();
    setSearchParams(searchParams);
    setSelectedRowKeys([]);
  };

  return {
    data,
    selectedRowKeys,
    page,
    size,
    total,
    sortBy,
    sortType,
    keyword,
    isOpenModalCompany,
    formSearch,
    formCompany,
    isRefresh,
    isChangeForm,
    tab,
    formMaterial,
    isVisibleMaterial,
    isEditMaterial,
    isEditCompany,
    status,

    getData,
    getMaterialCompany,
    handleChangeSelectedRowKey,
    handleChangePage,
    handleChangeSize,
    handleOpenModalCompany,
    handleCloseModalCompany,
    handleSetDefaultSorter,
    handleChangeTablePM025,
    onSearchChange,
    handleSubmitFormCompany,
    handleDeleteCompany,
    handleEditCompany,
    handleDeleteMultiCompany,
    handleChangeFormCompany,
    onChangeTab,
    getOrderingCompanyList,
    deleteOrderingCompanyDelete,
  };
};

export default PM025Handler;
