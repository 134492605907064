import axiosConfig from "../../../../../config/axiosConfig";
import {
  OrderingCompany,
  OrderingCompanyUpdateCreate,
} from "../../../PM025/entity/PM012Entity";

const API = () => {
  const postOrderingCompanyAdd = async (
    data: OrderingCompanyUpdateCreate
  ): Promise<any> => {
    const url = "/ordering-company/add";
    const response = await axiosConfig.post(url, data);
    return response.data;
  };

  const putOrderingCompanyUpdate = async (
    data: OrderingCompanyUpdateCreate
  ): Promise<any> => {
    const url = "/ordering-company/update";
    const response = await axiosConfig.put(url, data);
    return response.data;
  };

  const getOrderingCompanyInformation = async (
    params: any
  ): Promise<OrderingCompany> => {
    const url = "/ordering-company/information";
    const response = await axiosConfig.get(url, { params });
    return response?.data?.results ?? null;
  };

  return {
    postOrderingCompanyAdd,
    getOrderingCompanyInformation,
    putOrderingCompanyUpdate,
  };
};

export default API;
