import axiosConfig from "../../../../../config/axiosConfig";
import { InviteListContractorToConstruction } from "../../entity/Entity";

const CR001MailContractorsApi = () => {
  const addCompanyForList = async (data: any): Promise<any> => {
    const url = "/invite/construction/contractor";
    return await axiosConfig.post(url, data);
  };
  const postInviteConstructionContractorSendEmail = async (
    data: InviteListContractorToConstruction
  ) => {
    const url = "/invite/construction/contractor/send-email";
    return await axiosConfig.post(url, data);
  };
  return {
    addCompanyForList,
    postInviteConstructionContractorSendEmail,
  };
};

export default CR001MailContractorsApi;
