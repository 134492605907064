import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
    setDocumentCM010Id,
    setDocumentCM010OriginId,
    setIsVisibleCM0010,
    setStatusCM010,
    setSubmittedDateCM010,
} from '../../../../CM0010/presenter/slice/Slice';
import {
    setDocumentCM014Id,
    setDocumentOriginIdCM014,
    setIsVisibleCM0014,
    setStatusCM014,
    setSubmittedDateCM014,
} from '../../../../CM0014/presenter/slice/Slice';
import {
    setConstructionId,
    setDocumentId,
    setDocumentOriginId,
    setIsVisibleCM0016,
    setStatusCM016,
    setSubmittedDateCM016,
} from '../../../../CM0016/presenter/slice/Slice';
import {
    setDocumentCM08Id,
    setDocumentCM08OriginId,
    setIsVisibleCM008,
    setStatusCM008,
    setSubmittedDateCM08,
} from '../../../../CM008/presenter/slice/Slice';
import { CM0035UseCase } from '../../usecase/ServiceImpl';
import { convertTableData, reAssignNoTable } from '../../helper';
import { TableData } from '../../entity/Entity';
import moment from 'moment';
import COMMON from '../../../../../../common/constants/COMMON';
import { setRetrieve } from '../../../wrapper/presenter/slice/Slice';

const DESC = 'DESC';
const ASC = 'ASC';

const CM035Handler = (service: CM0035UseCase) => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const sortType1 = searchParams.get('sortType1');
    const sortType2 = searchParams.get('sortType2');
    const sortType3 = searchParams.get('sortType3');
    const sortType4 = searchParams.get('sortType4');
    const [material, setMaterial] = useState<TableData[]>([]);
    const [outsource, setOutsource] = useState<TableData[]>([]);
    const [labor, setLabor] = useState<TableData[]>([]);
    const [indirect, setIndirect] = useState<TableData[]>([]);

    const [materialOrigin, setMaterialOrigin] = useState<TableData[]>([]);
    const [outsourceOrigin, setOutsourceOrigin] = useState<TableData[]>([]);
    const [laborOrigin, setLaborOrigin] = useState<TableData[]>([]);
    const [indirectOrigin, setIndirectOrigin] = useState<TableData[]>([]);
    const [visibleRejectView, setVisibleRejectView] = useState(false);
    const [rejectReason, setRejectReason] = useState('');

    const funcSort = (sortBy: string, sortType: string) => {
        switch (sortBy) {
            case 'table1':
                searchParams.set('sortType1', sortType);
                break;
            case 'table2':
                searchParams.set('sortType2', sortType);
                break;
            case 'table3':
                searchParams.set('sortType3', sortType);
                break;
            case 'table4':
                searchParams.set('sortType4', sortType);
                break;
        }
        setSearchParams(searchParams);
    };

    const funcClickDocumentMaterialCost = (
        documentId: number,
        documentOriginId: number,
        submittedDate: string,
        statusCode: string,
    ) => {
        dispatch(setDocumentCM08Id(documentId));
        dispatch(setDocumentCM08OriginId(documentOriginId));
        dispatch(setSubmittedDateCM08(moment(submittedDate).format(COMMON.FORMAT_DATE_CI)));
        dispatch(setIsVisibleCM008(true));
        dispatch(setStatusCM008(statusCode));
    };
    const funcClickDocumentOutsourcingCost = (
        documentId: number,
        documentOriginId: number,
        submittedDate: string,
        statusCode: string,
    ) => {
        dispatch(setDocumentCM010Id(documentId));
        dispatch(setDocumentCM010OriginId(documentOriginId));
        dispatch(setSubmittedDateCM010(moment(submittedDate).format(COMMON.FORMAT_DATE_CI)));
        dispatch(setIsVisibleCM0010(true));
        dispatch(setStatusCM010(statusCode));
    };
    const funcClickDocumentLaborCost = (
        documentId: number,
        documentOriginId: number,
        submittedDate: string,
        statusCode: string,
    ) => {
        dispatch(setDocumentCM014Id(documentId));
        dispatch(setDocumentOriginIdCM014(documentOriginId));
        dispatch(setSubmittedDateCM014(moment(submittedDate).format(COMMON.FORMAT_DATE_CI)));
        dispatch(setIsVisibleCM0014(true));
        dispatch(setStatusCM014(statusCode));
    };
    const funcClickDocumentIndirect = (
        documentId: number,
        documentOriginId: number,
        submittedDate: string,
        statusCode: string,
        constructionId?: number,
    ) => {
        dispatch(setDocumentId(documentId));
        dispatch(setDocumentOriginId(documentOriginId));
        dispatch(setConstructionId(constructionId));
        dispatch(setSubmittedDateCM016(moment(submittedDate).format(COMMON.FORMAT_DATE_CI)));
        dispatch(setIsVisibleCM0016(true));
        dispatch(setStatusCM016(statusCode));
    };

    const getDataMaterialList = async (
        statusId: string,
        from: string,
        to: string,
        keyword: string,
        sortType1: string,
    ) => {
        const response = await service.getDocumentSubmittedMaterialList();

        let material = convertTableData(response.results);

        if (statusId !== '') {
            material = material
                .filter(
                    (item: TableData) =>
                        item.statusId === Number(statusId) &&
                        moment(item.submittedDate).isSameOrAfter(from, 'day') &&
                        moment(item.submittedDate).isSameOrBefore(to, 'day') &&
                        item.fileName.toLowerCase().includes(keyword.toLowerCase()),
                )
                .sort((a, b: TableData) => {
                    if (sortType1 === 'ASC') return moment(a.submittedDate).diff(b.submittedDate);
                    else return moment(b.submittedDate).diff(a.submittedDate);
                });
            material = reAssignNoTable(material);
        } else {
            material = material
                .filter(
                    (item: TableData) =>
                        moment(item.submittedDate).isSameOrAfter(from, 'day') &&
                        moment(item.submittedDate).isSameOrBefore(to, 'day') &&
                        item.fileName.toLowerCase().includes(keyword.toLowerCase()),
                )
                .sort((a, b: TableData) => {
                    if (sortType1 === 'ASC') return moment(a.submittedDate).diff(b.submittedDate);
                    else return moment(b.submittedDate).diff(a.submittedDate);
                });
            material = reAssignNoTable(material);
        }
        setMaterial(material);
        setMaterialOrigin(convertTableData(response.results));
        dispatch(setRetrieve(true));
    };

    const getDataOutsourceList = async (
        statusId: string,
        from: string,
        to: string,
        keyword: string,
        sortType2: string,
    ) => {
        const response = await service.getDocumentSubmittedOutsourceList();

        let resp = convertTableData(response.results);
        if (statusId !== '') {
            resp = resp
                .filter(
                    (item: TableData) =>
                        item.statusId === Number(statusId) &&
                        moment(item.submittedDate).isSameOrAfter(from, 'day') &&
                        moment(item.submittedDate).isSameOrBefore(to, 'day') &&
                        item.fileName.toLowerCase().includes(keyword.toLowerCase()),
                )
                .sort((a, b: TableData) => {
                    if (sortType2 === 'ASC') return moment(a.submittedDate).diff(b.submittedDate);
                    else return moment(b.submittedDate).diff(a.submittedDate);
                });
            resp = reAssignNoTable(resp);
        } else {
            resp = resp
                .filter(
                    (item: TableData) =>
                        moment(item.submittedDate).isSameOrAfter(from, 'day') &&
                        moment(item.submittedDate).isSameOrBefore(to, 'day') &&
                        item.fileName.toLowerCase().includes(keyword.toLowerCase()),
                )
                .sort((a, b: TableData) => {
                    if (sortType2 === 'ASC') return moment(a.submittedDate).diff(b.submittedDate);
                    else return moment(b.submittedDate).diff(a.submittedDate);
                });
            resp = reAssignNoTable(resp);
        }
        setOutsource(resp);
        setOutsourceOrigin(convertTableData(response.results));
    };

    const getDataLaborList = async (
        statusId: string,
        from: string,
        to: string,
        keyword: string,
        sortType3: string,
    ) => {
        const response = await service.getDocumentSubmittedLaborList();
        let resp = convertTableData(response.results);
        if (statusId !== '') {
            resp = resp
                .filter(
                    (item: TableData) =>
                        item.statusId === Number(statusId) &&
                        moment(item.submittedDate).isSameOrAfter(from, 'day') &&
                        moment(item.submittedDate).isSameOrBefore(to, 'day') &&
                        item.fileName.toLowerCase().includes(keyword.toLowerCase()),
                )
                .sort((a, b: TableData) => {
                    if (sortType3 === 'ASC') return moment(a.submittedDate).diff(b.submittedDate);
                    else return moment(b.submittedDate).diff(a.submittedDate);
                });
            resp = reAssignNoTable(resp);
        } else {
            resp = resp
                .filter(
                    (item: TableData) =>
                        moment(item.submittedDate).isSameOrAfter(from, 'day') &&
                        moment(item.submittedDate).isSameOrBefore(to, 'day') &&
                        item.fileName.toLowerCase().includes(keyword.toLowerCase()),
                )
                .sort((a, b: TableData) => {
                    if (sortType3 === 'ASC') return moment(a.submittedDate).diff(b.submittedDate);
                    else return moment(b.submittedDate).diff(a.submittedDate);
                });
            resp = reAssignNoTable(resp);
        }
        setLabor(resp);
        setLaborOrigin(convertTableData(response.results));
    };

    const getDataIndirectList = async (
        statusId: string,
        from: string,
        to: string,
        keyword: string,
        sortType4: string,
    ) => {
        const response = await service.getDocumentSubmittedIndirectList();
        let resp = convertTableData(response.results);

        if (statusId !== '') {
            resp = resp
                .filter(
                    (item: TableData) =>
                        item.statusId === Number(statusId) &&
                        moment(item.submittedDate).isSameOrAfter(from, 'day') &&
                        moment(item.submittedDate).isSameOrBefore(to, 'day') &&
                        item.fileName.toLowerCase().includes(keyword.toLowerCase()),
                )
                .sort((a, b: TableData) => {
                    if (sortType4 === 'ASC') return moment(a.submittedDate).diff(b.submittedDate);
                    else return moment(b.submittedDate).diff(a.submittedDate);
                });
            resp = reAssignNoTable(resp);
        } else {
            resp = resp
                .filter(
                    (item: TableData) =>
                        moment(item.submittedDate).isSameOrAfter(from, 'day') &&
                        moment(item.submittedDate).isSameOrBefore(to, 'day') &&
                        item.fileName.toLowerCase().includes(keyword.toLowerCase()),
                )
                .sort((a, b: TableData) => {
                    if (sortType4 === 'ASC') return moment(a.submittedDate).diff(b.submittedDate);
                    else return moment(b.submittedDate).diff(a.submittedDate);
                });
            resp = reAssignNoTable(resp);
        }
        setIndirect(resp);
        setIndirectOrigin(convertTableData(response.results));
    };

    const filterDataView = (
        statusId: string,
        from: string,
        to: string,
        keyword: string,
        sortType1: string,
        sortType2: string,
        sortType3: string,
        sortType4: string,
    ) => {
        if (statusId === '') {
            let materialD = materialOrigin
                .filter(
                    (item: TableData) =>
                        moment(item.submittedDate).isSameOrAfter(from, 'day') &&
                        moment(item.submittedDate).isSameOrBefore(to, 'day') &&
                        item.fileName.toLowerCase().includes(keyword.toLowerCase()),
                )
                .sort((a, b: TableData) => {
                    if (sortType1 === 'ASC') return moment(a.submittedDate).diff(b.submittedDate);
                    else return moment(b.submittedDate).diff(a.submittedDate);
                });

            materialD = reAssignNoTable(materialD);

            let outsourceD = outsourceOrigin
                .filter(
                    (item: TableData) =>
                        moment(item.submittedDate).isSameOrAfter(from, 'day') &&
                        moment(item.submittedDate).isSameOrBefore(to, 'day') &&
                        item.fileName.toLowerCase().includes(keyword.toLowerCase()),
                )
                .sort((a, b: TableData) => {
                    if (sortType2 === 'ASC') return moment(a.submittedDate).diff(b.submittedDate);
                    else return moment(b.submittedDate).diff(a.submittedDate);
                });

            outsourceD = reAssignNoTable(outsourceD);

            let laborD = laborOrigin
                .filter(
                    (item: TableData) =>
                        moment(item.submittedDate).isSameOrAfter(from, 'day') &&
                        moment(item.submittedDate).isSameOrBefore(to, 'day') &&
                        item.fileName.toLowerCase().includes(keyword.toLowerCase()),
                )
                .sort((a, b: TableData) => {
                    if (sortType3 === 'ASC') return moment(a.submittedDate).diff(b.submittedDate);
                    else return moment(b.submittedDate).diff(a.submittedDate);
                });
            laborD = reAssignNoTable(laborD);

            let indirectD = indirectOrigin
                .filter(
                    (item: TableData) =>
                        moment(item.submittedDate).isSameOrAfter(from, 'day') &&
                        moment(item.submittedDate).isSameOrBefore(to, 'day') &&
                        item.fileName.toLowerCase().includes(keyword.toLowerCase()),
                )
                .sort((a, b: TableData) => {
                    if (sortType4 === 'ASC') return moment(a.submittedDate).diff(b.submittedDate);
                    else return moment(b.submittedDate).diff(a.submittedDate);
                });
            indirectD = reAssignNoTable(indirectD);

            setMaterial(materialD);
            setOutsource(outsourceD);
            setLabor(laborD);
            setIndirect(indirectD);
        } else {
            let materialData = materialOrigin
                .filter(
                    (item: TableData) =>
                        item.statusId === Number(statusId) &&
                        moment(item.submittedDate).isSameOrAfter(from, 'day') &&
                        moment(item.submittedDate).isSameOrBefore(to, 'day') &&
                        item.fileName.toLowerCase().includes(keyword.toLowerCase()),
                )
                .sort((a, b: TableData) => {
                    if (sortType1 === 'ASC') return moment(a.submittedDate).diff(b.submittedDate);
                    else return moment(b.submittedDate).diff(a.submittedDate);
                });
            materialData = reAssignNoTable(materialData);

            let outsourceData = outsourceOrigin
                .filter(
                    (item: TableData) =>
                        item.statusId === Number(statusId) &&
                        moment(item.submittedDate).isSameOrAfter(from, 'day') &&
                        moment(item.submittedDate).isSameOrBefore(to, 'day') &&
                        item.fileName.toLowerCase().includes(keyword.toLowerCase()),
                )
                .sort((a, b: TableData) => {
                    if (sortType2 === 'ASC') return moment(a.submittedDate).diff(b.submittedDate);
                    else return moment(b.submittedDate).diff(a.submittedDate);
                });

            outsourceData = reAssignNoTable(outsourceData);

            let laborData = laborOrigin
                .filter(
                    (item: TableData) =>
                        item.statusId === Number(statusId) &&
                        moment(item.submittedDate).isSameOrAfter(from, 'day') &&
                        moment(item.submittedDate).isSameOrBefore(to, 'day') &&
                        item.fileName.toLowerCase().includes(keyword.toLowerCase()),
                )
                .sort((a, b: TableData) => {
                    if (sortType3 === 'ASC') return moment(a.submittedDate).diff(b.submittedDate);
                    else return moment(b.submittedDate).diff(a.submittedDate);
                });

            laborData = reAssignNoTable(laborData);

            let indirectData = indirectOrigin
                .filter(
                    (item: TableData) =>
                        item.statusId === Number(statusId) &&
                        moment(item.submittedDate).isSameOrAfter(from, 'day') &&
                        moment(item.submittedDate).isSameOrBefore(to, 'day') &&
                        item.fileName.toLowerCase().includes(keyword.toLowerCase()),
                )
                .sort((a, b: TableData) => {
                    if (sortType4 === 'ASC') return moment(a.submittedDate).diff(b.submittedDate);
                    else return moment(b.submittedDate).diff(a.submittedDate);
                });

            indirectData = reAssignNoTable(indirectData);

            setMaterial(materialData);
            setOutsource(outsourceData);
            setLabor(laborData);
            setIndirect(indirectData);
        }
    };

    return {
        sortType1,
        sortType2,
        sortType3,
        sortType4,
        material,
        outsource,
        labor,
        indirect,
        rejectReason,
        visibleRejectView,

        funcSort,
        funcClickDocumentMaterialCost,
        funcClickDocumentOutsourcingCost,
        funcClickDocumentLaborCost,
        funcClickDocumentIndirect,
        getDataMaterialList,
        getDataOutsourceList,
        getDataLaborList,
        getDataIndirectList,
        filterDataView,
        setVisibleRejectView,
        setRejectReason,
    };
};

export default CM035Handler;
