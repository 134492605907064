import CM0030Api from './infrastructure/api/Api';
import CM0030Handler from './presenter/handler/Handler';
import CM0030UI from './presenter/ui/UI';
import CM0030Service from './usecase/Service';

const CM0030Component = () => {
    const cm0030Api = CM0030Api();
    const cm0030Service = CM0030Service(cm0030Api);
    const cm0030Handler = CM0030Handler(cm0030Service);
    return <CM0030UI handler={cm0030Handler} />;
};

export default CM0030Component;
