import helpers from '../../../common/helpers/common';
import { DataTableConstructionBudgetBalance } from '../CM0016/entity/Entity';
import { TableConstructionContractBudget } from './entity/Entity';

const convertDataTable = (
    planCost: number,
    orderMoney: number,
    planMaterialCost: number,
): TableConstructionContractBudget[] => {
    const obj: TableConstructionContractBudget = {
        key: 0,
        budgetAmount: planCost,
        orderAmount: orderMoney,
        actualCost: planMaterialCost,
        budgetBalance: planCost - planMaterialCost,
        budgetRate: helpers.calculateRateAmount(planCost, planMaterialCost),
    };

    return [obj];
};

const convertDataTableInformationCost = (data: any[]): DataTableConstructionBudgetBalance[] => {
    const output: DataTableConstructionBudgetBalance[] = [];

    data.forEach((item: any, idx: number) => {
        const budget = item?.outsourceCost?.planCost ?? 0;
        const actual = item?.outsourceCost?.actualCost ?? 0;

        const obj: DataTableConstructionBudgetBalance = {
            key: idx,
            budgetAmount: budget,
            actualCost: actual,
            budgetBalance: budget - actual,
            budgetRate: helpers.calculateRateAmount(budget, actual),
        };

        output.push(obj);
    });
    return output;
};

export { convertDataTable, convertDataTableInformationCost };
