import axiosConfig from "../../../../../config/axiosConfig";

const CR002Api = () => {
  const getCompanyList = async (params: any): Promise<any> => {
    try {
      const url = "/company-colab/list";
      return await axiosConfig.get(url, { params });
    } catch (error) {
      throw error;
    }
  };

  const addCompanyForList = async (data: any): Promise<any> => {
    try {
      const url = "/company-colab/add";
      return await axiosConfig.post(url, data);
    } catch (error) {
      throw error;
    }
  };

  const getMaterialSupplier = async (params: any): Promise<any> => {
    try {
      const url = "/material-cost-company/list";
      return await axiosConfig.get(url, { params });
    } catch (error) {
      throw error;
    }
  };

  const addMaterialSupplier = async (data: any): Promise<any> => {
    try {
      const url = "/material-cost-company/add";
      return await axiosConfig.post(url, data);
    } catch (error) {
      throw error;
    }
  };

  return {
    getCompanyList,
    addCompanyForList,
    getMaterialSupplier,
    addMaterialSupplier,
  };
};

export default CR002Api;
