import { createSlice } from '@reduxjs/toolkit';
type ImageViewerModalSliceType = {
    isOpenImageViewer: boolean;
    loadingImg: boolean;
};

const initialState: ImageViewerModalSliceType = {
    isOpenImageViewer: false,
    loadingImg: false,
};

export const ImageViewerModalSlice = createSlice({
    name: 'imageViewer',
    initialState,
    reducers: {
        showImageViewerModal: (state) => {
            state.isOpenImageViewer = true;
            state.loadingImg = true;
        },
        hideImageViewerModal: (state) => {
            state.isOpenImageViewer = false;
        },
        setLoadingImg: (state) => {
            state.loadingImg = false;
        },
    },
});
export const { showImageViewerModal, hideImageViewerModal, setLoadingImg } =
    ImageViewerModalSlice.actions;
export default ImageViewerModalSlice.reducer;
