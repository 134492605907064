import { createSlice } from "@reduxjs/toolkit";
import { AM040SliceType } from "../../entity/Entity";

export const initialState: AM040SliceType = {
  params: null,
};

export const AM040Slice = createSlice({
  name: "am040",
  initialState,
  reducers: {
    resetAM040: () => initialState,
    setAM040: (state, action) => {
      state.params = action.payload;
    },
  },
});

export const { resetAM040, setAM040 } = AM040Slice.actions;

export default AM040Slice.reducer;
