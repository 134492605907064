import { SM03SliceType } from './../type/Presenter';
import { createSlice } from '@reduxjs/toolkit';

const initialState: SM03SliceType = {
    loading: false,
    isVisible: false,
    typeModal: false,
    scheduleId: null,
};

export const sm03Slice = createSlice({
    name: 'sm03',
    initialState,
    reducers: {
        resetSM03: () => initialState,
        setLoadingSM03: (state, action) => {
            state.loading = action.payload;
        },
        setIsVisibleSM03: (state, action) => {
            state.isVisible = action.payload;
        },
        setTypeModalSM03: (state, action) => {
            state.typeModal = action.payload;
        },
        setScheduleIdSM03: (state, action) => {
            state.scheduleId = action.payload;
        },
    },
});

export const { setLoadingSM03, setIsVisibleSM03, resetSM03, setTypeModalSM03, setScheduleIdSM03 } =
    sm03Slice.actions;

export default sm03Slice.reducer;
