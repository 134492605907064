 import { RequestCreateAttendance, RequestUpdateAttendance } from "../entity/Entity";
import { AM012Api } from "./ServiceImpl";
  
  const AM012Service = (am012: AM012Api) => {
    const createAttendance= async (data:RequestCreateAttendance): Promise<any> => {
     try{
      return await am012.createAttendance(data);
     }catch(error){
      throw error;
     }
    };

    const updateAttendance= async (data:RequestUpdateAttendance): Promise<any> => {
      try{
       return await am012.updateAttendance(data);
      }catch(error){
       throw error;
      }
     };

    const getAttendanceCategory= async (): Promise<any> => {
      try{
        return await am012.getAttendanceCategory();
      }catch(error){
       throw error;
      }
     };
  
     const getProjects = async (params: any): Promise<any> => {
      try {
        return await am012.getProjects(params);
      } catch (error) {
        throw error;
      }
    };

    const getRequestAttendance = async (params:any): Promise<any> => {
      try{
        return await am012.getRequestAttendance(params);
      }catch(error){
        throw(error)
      }
    };

    const getAttendanceUserConstruction = async (params:any): Promise<any> => {
      try{
        return await am012.getAttendanceUserConstruction(params);
      }catch(error){
        throw(error)
      }
    };

    const getProjectById = async (params:any): Promise<any> => {
      try{
        return await am012.getProjectById(params);
      }catch(error){
        throw(error)
      }
    };

    const getRequestApprover = async (): Promise<any> => {
      try{
        return await am012.getRequestApprover();
      }catch(error){
        throw(error)
      }
    };

    const getProjectStatus = async (params: any): Promise<any> => {
      try{
        return await am012.getProjectStatus(params);
      }catch(error){
        throw(error)
      }
    };
  
     
    return {
      createAttendance,
      updateAttendance,
      getAttendanceCategory,
      getProjects,
      getRequestAttendance,
      getAttendanceUserConstruction,
      getProjectById,
      getRequestApprover,
      getProjectStatus
    };
  };
  
  export default AM012Service;
  