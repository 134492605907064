import ConstructionApi from "../../../infrastructure/api/ConstructionApi"
import ConstructionService from "../../../usecase/ConstructionService"
import ConstructionHandler from "../../handler/HandlerConstruction"
import ConstructionUI from "./ui/UI"

interface Props {
    construction: any,
}

const ConstructionComponent = ({ construction }: Props) => {
    const api = ConstructionApi()
    const service = ConstructionService(api)
    const handler = ConstructionHandler(service)
    return <ConstructionUI handler={handler} construction={construction} />
}

export default ConstructionComponent