import { Form } from "antd";
import { uuidv4 } from "@firebase/util";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConfirmModal from "../../../../../common/components/modal/ConfirmModal";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import { RootState } from "../../../../../store";
import { ReceivedMoneyOffset, SubCategory } from "../../entity/Entity";
import { CM017_2UseCase } from "../../usecase/ServiceImpl";
import {
  setIsUpdate,
  setIsVisibleCM017_2,
  setLoadingCM017_2,
  setTypeModalCM017_2,
} from "../slice/Slice";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import moment from "moment";

const CM017_2Handler = (
  CM017_2Service: CM017_2UseCase,
  refreshData?: () => void
) => {
  // LIB FUNCTION
  const dispatch = useDispatch();

  //STATE
  const [form] = Form.useForm();
  const [isDirty, setIsDirty] = useState(false);
  const typeModal = useSelector((state: RootState) => state.cm017_2.typeModal);
  const parentCode = useSelector(
    (state: RootState) => state.cm017_2.parentCode
  );

  const [contentDescription, setContentDescription] = useState<any>();
  const [listReceivedMoneyOffset, setListReceivedMoneyOffset] = useState<
    ReceivedMoneyOffset[]
  >([]);
  const [listReceivedMoneyOffsetLocal, setListReceivedMoneyOffsetLocal] =
    useState<ReceivedMoneyOffset[]>([]);

  const [subCategory, setSubCategory] = useState<SubCategory[]>([]);
  const [totalCost, setTotalCost] = useState<number>(0);

  //function
  const handleGetListOutsourceCostOffset = async (params: {
    companyId?: number;
    colabRefId?: number;
    categoryId?: number | null;
    from?: string;
    to?: string;
  }): Promise<any> => {
    try {
      dispatch(setLoadingCM017_2(true));
      const res = await CM017_2Service.getListOutsourceCostOffset(params);
      if (res?.results) {
        const contentDescription = {
          startDate: res.results.startDate,
          endDate: res.results.endDate,
          orderingCompanyName: res.results.orderingCompanyName,
        };
        setContentDescription(contentDescription);
        const data = formatReceivedMoneyOffset(res.results);
        setListReceivedMoneyOffset(data);
        setListReceivedMoneyOffsetLocal(data);
        handleFillForm(data);
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoadingCM017_2(false));
    }
  };

  const formatReceivedMoneyOffset = (data: any) => {
    const arr: ReceivedMoneyOffset[] = data.map((item: any, index: number) => {
      return {
        key: uuidv4(),
        id: item.id,
        item: item.name,
        taxCategoryComsumptionId: item?.category?.id,
        taxCategoryComsumptionName: item?.category?.description,
        offsetAmount: item.value,
        date: item.date,
      };
    });
    return arr;
  };

  const handleGetSubCategory = async (): Promise<any> => {
    try {
      dispatch(setLoadingCM017_2(true));
      const res = await CM017_2Service.getSubCategory();
      if (res?.results) {
        setSubCategory(handleFormatSubCategory(res.results));
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoadingCM017_2(false));
    }
  };

  const handleFormatSubCategory = (data: any) => {
    const arr: SubCategory[] = data.map((item: any) => {
      return {
        label: item.description,
        value: item.id,
        code: item.code,
      };
    });
    return arr;
  };

  const handleFillForm = (data: ReceivedMoneyOffset[]) => {
    data.map((item) => {
      form.setFieldValue(`item${item.key}`, item.item);
      form.setFieldValue(
        `taxCategoryConsumption${item.key}`,
        item.taxCategoryComsumptionId
      );
      form.setFieldValue(`offsetAmount${item.key}`, item.offsetAmount);
      form.setFieldValue(
        `targetDate${item.key}`,
        moment(item.date, true).isValid() ? moment(item.date) : null
      );
    });
  };

  const handleCalculateChange = (data: ReceivedMoneyOffset[]) => {
    let total: number = 0;
    data.forEach((item) => {
      const cost = form.getFieldValue(`offsetAmount${item.key}`);
      if (!isNaN(cost)) total += cost;
    });
    setTotalCost(total);
  };

  const handleAddRowLocal = () => {
    const uniqueId = uuidv4();
    const data: ReceivedMoneyOffset = {
      key: uniqueId,
      item: undefined,
      taxCategoryComsumptionId: undefined,
      offsetAmount: undefined,
      date: undefined,
    };
    setListReceivedMoneyOffsetLocal([...listReceivedMoneyOffsetLocal, data]);
    form.setFieldValue(
      `taxCategoryConsumption${uniqueId}`,
      subCategory[0]?.value
    );
  };

  const handleRemoveRowLocal = (key: string) => {
    if (
      !form.getFieldValue(`item${key}`) &&
      form.getFieldValue(`taxCategoryConsumption${key}`) ===
        subCategory[0]?.value &&
      !form.getFieldValue(`offsetAmount${key}`)
    ) {
      const arr = listReceivedMoneyOffsetLocal.filter(
        (item) => item.key !== key
      );
      setListReceivedMoneyOffsetLocal([...arr]);
      handleCalculateChange(arr);
      return;
    }
    ConfirmModal({
      onOk: async () => {
        const arr = listReceivedMoneyOffsetLocal.filter(
          (item) => item.key !== key
        );
        setListReceivedMoneyOffsetLocal(arr);
        handleCalculateChange(arr);
        setIsDirty(true);
      },
      className: "confirm__modal",
      title: MESSAGE.MESSAGE_MODAL_DELETE_TITLE,
      description: MESSAGE.MESSAGE_MODAL_DELETE_DESCRIPTION_CM,
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_MODAL,
      isCenterWithoutMenu: true,
    });
  };

  const handleSubmitForm = async (value: any) => {
    let listData: ReceivedMoneyOffset[] = [];
    const dataSubmit: any = [];
    listReceivedMoneyOffsetLocal.forEach((item) => {
      listData.push({
        key: item.key,
        item: form.getFieldValue(`item${item.key}`),
        taxCategoryComsumptionId: form.getFieldValue(
          `taxCategoryConsumption${item.key}`
        ),
        taxCategoryComsumptionName: convertCategoryIdToCategoryName(
          form.getFieldValue(`taxCategoryConsumption${item.key}`)
        ),
        offsetAmount: form.getFieldValue(`offsetAmount${item.key}`),
      });
      dataSubmit.push({
        name: form.getFieldValue(`item${item.key}`),
        categoryId: form.getFieldValue(`taxCategoryConsumption${item.key}`),
        value: form.getFieldValue(`offsetAmount${item.key}`),
        date:
          moment(form.getFieldValue(`targetDate${item.key}`)).toISOString() ??
          "",
      });
    });
    await handleEditReSummaryCompanyOutsorceCost(dataSubmit);
    setListReceivedMoneyOffsetLocal(listData);
    form.resetFields();
    setIsDirty(false);
    dispatch(setTypeModalCM017_2(false));
    dispatch(setIsVisibleCM017_2(false));
    if (refreshData) refreshData();
  };

  const handleEditReSummaryCompanyOutsorceCost = async (
    data: any
  ): Promise<any> => {
    try {
      dispatch(setLoadingCM017_2(true));
      const dataSubmit: any = {
        companyId: parentCode?.companyId,
        data,
      };
      const res = await CM017_2Service.editReceivedMoneyOffset(dataSubmit);
      if (res) SuccessNotification(res?.message ?? NOTIFICATION_TITLE.SUCCESS);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoadingCM017_2(false));
    }
  };

  const convertCategoryIdToCategoryName = (id: number) => {
    const res = subCategory.find((item) => item.value === id);
    return res?.label;
  };

  const handleChangeTypeModal = (type: boolean) => {
    if (type) handleFillForm(listReceivedMoneyOffsetLocal);
    dispatch(setTypeModalCM017_2(type));
  };

  const checkDirty = (): void => {
    const formValue = form.getFieldsValue();
    const formProps = Object.keys(formValue);
    for (let i = 0; i < formProps.length; i++) {
      if (form.isFieldTouched(formProps[i])) {
        return setIsDirty(true);
      }
    }
    setIsDirty(false);
  };

  const onCancel = () => {
    if (!isDirty) {
      if (!typeModal) dispatch(setIsVisibleCM017_2(false));
      else {
        const newResults = listReceivedMoneyOffsetLocal.filter(
          (value: any) => value.item
        );
        setListReceivedMoneyOffsetLocal(newResults);
        dispatch(setTypeModalCM017_2(false));
      }
      return;
    }
    ConfirmModal({
      onOk: async () => {
        if (!typeModal) {
          dispatch(setIsVisibleCM017_2(false));
        } else {
          dispatch(setTypeModalCM017_2(false));
          form.resetFields();
        }
        handleCalculateChange(listReceivedMoneyOffset);
        setListReceivedMoneyOffsetLocal([...listReceivedMoneyOffset]);
        setIsDirty(false);
      },
      className: "confirm__modal",
      title: MESSAGE.MESSAGE_020,
      description: MESSAGE.MESSAGE_022_1,
      extraDescription: MESSAGE.MESSAGE_022_2,
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_MODAL,
      isCenterWithoutMenu: true,
    });
  };

  return {
    form,
    typeModal,
    contentDescription,
    listReceivedMoneyOffsetLocal,
    totalCost,
    subCategory,
    onCancel,
    checkDirty,
    handleAddRowLocal,
    handleRemoveRowLocal,
    handleSubmitForm,
    handleCalculateChange,
    handleGetListOutsourceCostOffset,
    handleGetSubCategory,
    handleChangeTypeModal,
  };
};

export default CM017_2Handler;
