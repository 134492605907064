import { Handler } from "./presenter/handler/Handler";
import { MainUI } from "./presenter/ui/UI";

interface props {
    onSubmit: () => void
}

const ApproveConfirmModal = ({ onSubmit }: props) => {
    const handler = Handler()
    return <MainUI handler={handler} onSubmit={onSubmit} />
}

export default ApproveConfirmModal