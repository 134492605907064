// BUILT-IN
import React, { useRef, useEffect } from "react";

// DEPENDENCY
import { Button, Checkbox, Form, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// SOURCE
import COMMON from "../../../../../../../common/constants/COMMON";
import { COLUMN_WIDTH_CM0015 } from "../../material-cost/ui/UI";
import { CollapsedTableHandlerImpl } from "../../../handler/HandlerImpl";
import { INPUT_CATEGORY_CODE } from "../../../handler/HandlerCollapsedTable";
import { STATUS_CM_CHILD_TABLE } from "../../../../../../../common/constants/COMMON";
import ImageViewerModalComponent from "../../../../../../../common/components/image-viewer-modal/ImageViewerComponent";
import PdfViewerModalComponent from "../../../../../../../common/components/pdf-viewer-modal/PdfViewerModalComponent";
import InputFormMoneyItemComponent from "../../../../../../../common/components/common-form-item-cm/InputFormMoneyItemComponent";
import SelectFormItemComponent from "../../../../../../../common/components/common-form-item-cm/SelectFormItemComponent";
import UploadEvidenceComponent from "../../../../../../../common/components/common-form-item-cm/upload-evidence";
import DateRangePickerCMComponent from "../../../../../../../common/components/common-form-item-cm/date-range-picker";
import DatePickerCMComponent from "../../../../../../../common/components/common-form-item-cm/date-picker";
import { hideImageViewerModal } from "../../../../../../../common/components/image-viewer-modal/ImageViewSlice";
import { hidePdfViewerModal } from "../../../../../../../common/components/pdf-viewer-modal/pdfViewerModalSlice";
import { RootState } from "../../../../../../../store";
import helpers from "../../../../../../../common/helpers/common";
import TooltipText from "../../../../../../../common/components/tooltip-text/TooltipText";
import { AUTHORIZATION_CODE } from "../../../../../../../common/constants/AUTHORIZATION";
import { PRIVILEGE_PM } from "../../../../../../../common/constants/PRIVILEGE";
import { isHasPrivilege } from "../../../../../../../common/helpers/privilege";
import { EyeNote } from "../../../../../CM007/presenter/component/collapsed-table/ui/EyeNote";
import { CheckBoxCollapseComponentCM007CM009 } from "../../../../../CM007/presenter/component/collapsed-table/ui/CheckBoxAll";
import ApproveEditModal from "../../../../../../../common/components/modal-approve-edit";
import ApproveViewModal from "../../../../../../../common/components/modal-approve-view";

// IMAGE
import EditButtonIconSvg from "../../../../../../../assets/icons/edit-button-icon.svg";
import DocumentIconSvg from "../../../../../../../assets/icons/document-icon.svg";
import DeleteButtonIconSvg from "../../../../../../../assets/icons/delete-button-icon.svg";
import AddRowButtonIconSvg from "../../../../../../../assets/icons/add-row-button.svg";
import IconI from "../../../../../../../assets/icons/icon-i-red.svg";
import EyeApprovedIcon from "../../../../../../../assets/icons/eye-approved.svg";
import EyeNotApprovedIcon from "../../../../../../../assets/icons/eye-not-approved.svg";

type props = {
  handler: CollapsedTableHandlerImpl;
  parentData: any;
  onParentCheckboxChange: (record: any, checked: boolean) => void;
};

const CollapsedTableUI = ({
  handler,
  parentData,
  onParentCheckboxChange,
}: props) => {
  const dispatch = useDispatch();
  const isCheckRef = useRef(false);
  const [searchParams] = useSearchParams();
  const constructionId = searchParams.get("constructionId");
  const isRefresh = useSelector((state: RootState) => state.cm0013.isRefresh);
  const permission = useSelector(
    (state: RootState) => state.auth.authData?.permission
  );
  const isDisabledCheckAll = handler.data.some(
    (element) =>
      element?.status?.code === STATUS_CM_CHILD_TABLE.MONEY_NOT_APPROVED.CODE
  );
  const checkedCollapse = useSelector(
    (state: RootState) => state.cm0015.checkedCollapse
  );
  const detailProject = useSelector(
    (state: RootState) => state.cm004.detailProject
  );
  const evidenceName = useSelector(
    (state: RootState) => state.cm005.evidenceName
  );

  const isRoleAdmin = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdmin
  );

  const checkButton =
    isRoleAdmin ||
    isHasPrivilege(detailProject, [PRIVILEGE_PM]) ||
    permission?.includes(AUTHORIZATION_CODE.S48);

  useEffect(() => {
    handler.checkParentCBChange(parentData, checkedCollapse);
  }, [JSON.stringify(checkedCollapse)]);

  useEffect(() => {
    if (handler.checkedList.length > 0)
      onParentCheckboxChange(parentData, true);
  }, [JSON.stringify(handler.checkedList)]);

  useEffect(() => {
    handler.resetEditingLines();
  }, [isRefresh]);

  useEffect(() => {
    return () => {
      dispatch(hideImageViewerModal());
      dispatch(hidePdfViewerModal());
    };
  }, []);

  useEffect(() => {
    handler.setParentData(parentData);

    if (isCheckRef.current) {
      (async () => {
        if (parentData.id) {
          await handler.getMoneyIndirectCostInformationList({
            indirectCostId: parentData.id,
            constructionId: Number(constructionId ?? "0"),
            page: COMMON.DEFAULT_PAGE,
            size: COMMON.DEFAULT_SIZE,
            sortBy: "createdAt",
            sortType: "ASC",
          });
        }
      })();
    }
    isCheckRef.current = true;
  }, [parentData.id, handler.refresh]);

  useEffect(() => {
    handler.getMoneyIndirectCostInformationInputCategory();
  }, []);

  useEffect(() => {
    handler.handleFirstRender(parentData.child);
  }, [JSON.stringify(parentData.child)]);

  const renderEye = (status: string) => {
    if (status === STATUS_CM_CHILD_TABLE.MONEY_APPROVED.CODE)
      return EyeApprovedIcon;
    else if (status === STATUS_CM_CHILD_TABLE.MONEY_NOT_APPROVED.CODE)
      return EyeNotApprovedIcon;
    else return IconI;
  };
  const editActionButton = (record: any) => (
    <div>
      <Button
        type="primary"
        size="small"
        className="cm-save"
        onClick={() => handler.save(record, parentData.id)}
        disabled={
          String(record.id).includes("new")
            ? !handler.isEnableSaveButton(record)
            : false
        }
      >
        保存
      </Button>
      <Button
        size="small"
        className="cm-cancel"
        onClick={() => handler.cancelEditing(record)}
      >
        キャンセル
      </Button>
    </div>
  );

  const columns: ColumnsType<any> = [
    {
      title: (
        <CheckBoxCollapseComponentCM007CM009
          onCheckAllChange={handler.onCheckAllChange}
          checkAll={handler.checkAll}
          isDisabled={!isDisabledCheckAll}
          showCB={handler.data?.length > 0}
        />
      ),
      key: "No",
      width: COLUMN_WIDTH_CM0015[0],
      render: (_, record, index) => {
        const conditionCR = true;
        if (record.key === "add")
          return conditionCR ? (
            checkButton ? (
              <div
                className="add-row-style button-style"
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "fit-content",
                }}
                onClick={() => handler.addNewLine()}
              >
                <img
                  src={AddRowButtonIconSvg}
                  alt=""
                  style={{ marginRight: 8 }}
                />
                <span>項目追加</span>
              </div>
            ) : (
              <></>
            )
          ) : (
            <div
              className="add-row-style disable-style"
              style={{
                display: "flex",
                alignItems: "center",
                width: "fit-content",
              }}
            >
              <img
                src={AddRowButtonIconSvg}
                alt=""
                style={{ marginRight: 8 }}
              />
              <span>項目追加</span>
            </div>
          );
        const {
          MONEY_RETRIEVE,
          MONEY_REJECT,
          MONEY_WAITING_FOR_APPROVAL,
          MONEY_APPROVED,
        } = STATUS_CM_CHILD_TABLE;

        return (
          <span
            onClick={(event) => {
              event.stopPropagation();
            }}
            className="cell-cm007-style white-space-nowrap"
            style={{ width: "100%", float: "left", textAlign: "left" }}
          >
            {[
              MONEY_REJECT.CODE,
              MONEY_WAITING_FOR_APPROVAL.CODE,
              MONEY_RETRIEVE.CODE,
              MONEY_APPROVED.CODE,
            ].includes(record?.status?.code ?? "") ? (
              <Checkbox
                className="no-hover"
                disabled={true}
                style={{ marginRight: 8 }}
                checked
              />
            ) : (
              <Checkbox
                onChange={(value: any) =>
                  handler.onCheckboxChange(
                    record,
                    value?.target?.checked ? true : false
                  )
                }
                style={{ marginRight: 8 }}
                  checked={handler.isCheckbox(record)}
                  disabled={!record?.status}
              />
            )}
            {index}
          </span>
        );
      },
      onCell: (record) => ({
        colSpan: record.key === "add" ? 11 : 1,
      }),
    },
    {
      title: "入力方法",
      key: "入力方法",
      width: COLUMN_WIDTH_CM0015[1],
      render: (record) => {
        return handler.isEditing(record) ? (
          <SelectFormItemComponent
            options={handler.inputCategories}
            name="inputMethod"
            record={record}
          />
        ) : (
          <span className="collapsed-table-row-style">
            {record.inputMethodName || ""}
          </span>
        );
      },
      onCell: (record) => ({
        colSpan: record.key === "add" ? 0 : 1,
      }),
    },
    {
      title: "対象期間",
      key: "対象期間",
      width: COLUMN_WIDTH_CM0015[2],
      render: (record) => {
        return handler.isEditing(record) ? (
          <DateRangePickerCMComponent
            name="targetPeriod"
            record={record}
            onAttendanceLaborCostChange={(record, property, value) => {
              if (constructionId)
                handler.onAttendanceLaborCostChange(
                  record,
                  property,
                  value,
                  1,
                  parseInt(constructionId)
                );
            }}
          />
        ) : (
          <span className="collapsed-table-row-style text-nowrap">
            {`${
              record.targetPeriodFrom
                ? moment(record.targetPeriodFrom).format(COMMON.FORMAT_DATE_OM)
                : ""
            }
                    -
                    ${
                      record.targetPeriodTo
                        ? moment(record.targetPeriodTo).format(
                            COMMON.FORMAT_DATE_OM
                          )
                        : ""
                    }`}
          </span>
        );
      },
      onCell: (record) => ({
        colSpan: record.key === "add" ? 0 : 1,
      }),
    },
    {
      title: "支払日",
      key: "支払日",
      width: COLUMN_WIDTH_CM0015[3],
      render: (record) => {
        return handler.isEditing(record) ? (
          <DatePickerCMComponent record={record} name="datePayment" />
        ) : (
          <span className="collapsed-table-row-style">
            {`${
              record.datePayment
                ? moment(record.datePayment).format(COMMON.FORMAT_DATE_OM)
                : ""
            }
                            `}
          </span>
        );
      },
      onCell: (record) => ({
        colSpan: record.key === "add" ? 0 : 1,
      }),
    },
    {
      title: "支払予定金額",
      key: "支払予定金額",
      children: [
        {
          title: "手入力",
          key: "手入力",
          width: COLUMN_WIDTH_CM0015[4],
          render: (record) => {
            const codeCategoryInput = handler.inputCategories.find(
              (input) => input.id === record?.inputMethod
            )?.code;
            if (codeCategoryInput === INPUT_CATEGORY_CODE.percentage) return "";

            return handler.isEditing(record) ? (
              <InputFormMoneyItemComponent
                record={record}
                name="manualInput"
                onValueNumberChange={handler.onValueNumberChange}
                disabled={codeCategoryInput === INPUT_CATEGORY_CODE.percentage}
              />
            ) : (
              <span className="collapsed-table-row-style">{`${
                record.manualInput
                  ? record.manualInput.toLocaleString("en-US", {
                      maximumFractionDigits: 2,
                    })
                  : 0
              }円`}</span>
            );
          },
          onCell: (record) => ({
            colSpan: record.key === "add" ? 0 : 1,
          }),
        },
        {
          title: "掛け係数",
          key: "掛け係数",
          width: COLUMN_WIDTH_CM0015[5],
          render: (record) => {
            const codeCategoryInput = handler.inputCategories.find(
              (input) => input.id === record?.inputMethod
            )?.code;

            return handler.isEditing(record) ? (
              <InputFormMoneyItemComponent
                record={record}
                name="multiplicationFactor"
                onValueNumberChange={handler.onValueNumberChange}
                disabled={codeCategoryInput === INPUT_CATEGORY_CODE.manualInput}
              />
            ) : (
              <span className="collapsed-table-row-style">{`${
                record.multiplicationFactor
                  ? record.multiplicationFactor.toLocaleString("en-US", {
                      maximumFractionDigits: 2,
                    })
                  : ""
              }`}</span>
            );
          },
          onCell: (record) => ({
            colSpan: record.key === "add" ? 0 : 1,
          }),
        },
        {
          /*
          Trường 金額(％集計): Tính tự động
          Công thức: 
          Tổng tiền phí trực tiếp trong 1 tháng /(1 - 掛け係数) -
          Tổng tiền phí trực tiếp trong 1 tháng
          Tổng tiền phí trực tiếp trong 1 tháng = Tổng tiền 実費用 có status 承認済 ở màn CM007  +
          Tổng tiền 実費用 có status 承認済 ở màn CM009 +
          Tổng tiền 実費用 có status 承認済 ở màn CM0013
          (*Chỉ lấy số tiền ở record có ngày 対象期間 của màn CM007, CM009, CM0013 trùng với ngày 対象期間 của record đang trọn)"											
          */
          title: "金額(％集計)",
          key: "金額(％集計)",
          width: COLUMN_WIDTH_CM0015[6],
          render: (record) => {
            const codeCategoryInput = handler.inputCategories.find(
              (input) => input.id === record?.inputMethod
            )?.code;
            if (codeCategoryInput === INPUT_CATEGORY_CODE.manualInput)
              return "";
            if (1 - record.multiplicationFactor === 0)
              return <span className="collapsed-table-row-style">0円</span>;
            const amountTotal: number =
              record.directCost / (1 - record.multiplicationFactor) -
              record.directCost;
            return (
              <span className="collapsed-table-row-style">{`${
                amountTotal
                  ? amountTotal.toLocaleString("en-US", {
                      maximumFractionDigits: 2,
                    })
                  : 0
              }円`}</span>
            );
          },
          onCell: (record) => ({
            colSpan: record.key === "add" ? 0 : 1,
          }),
        },
      ],
    },

    {
      title: "実費用",
      key: "実費用",
      width: COLUMN_WIDTH_CM0015[7],
      render: (record) => {
        return handler.isEditing(record) ? (
          <InputFormMoneyItemComponent
            record={record}
            name="actualCost"
            onValueNumberChange={handler.onValueNumberChange}
          />
        ) : (
          <div
            className="collapsed-table-row-style"
            style={{ textAlign: "center" }}
          >
            {`${helpers.formatNumberMoney(record.actualCost)}円`}
          </div>
        );
      },
      onCell: (record) => ({
        colSpan: record.key === "add" ? 0 : 1,
      }),
    },

    {
      title: "エビデンス",
      key: "エビデンス",
      width: COLUMN_WIDTH_CM0015[8],
      render: (record) =>
        handler.isEditing(record) ? (
          <UploadEvidenceComponent
            nameForm={[`record${record.id}`, "evidence"]}
            fileNameDefault={record.evidence}
            form={handler.form}
            afterClear={handler.afterClearEvidence}
          />
        ) : record.evidence ? (
          <div
            className="button-style"
            onClick={() => handler.handleViewEvidence(record)}
          >
            <img src={DocumentIconSvg} alt="" />
          </div>
        ) : (
          ""
        ),
      onCell: (record) => ({
        colSpan: record.key === "add" ? 0 : 1,
      }),
    },

    {
      title: "ステータス",
      key: "ステータス",
      align: "center",
      width: COLUMN_WIDTH_CM0015[9],
      render: (record) => {
        if (record.key === "add") return;
        const checkEditing = handler.isEditing(record);
        if (checkEditing) return editActionButton(record);
        if (record?.status?.code === false) return <></>;

        const color = STATUS_CM_CHILD_TABLE.hasOwnProperty(
          `${record?.status?.code?.toUpperCase()}`
        )
          ? {
              color:
                STATUS_CM_CHILD_TABLE[
                  `${record?.status?.code?.toUpperCase()}` as keyof typeof STATUS_CM_CHILD_TABLE
                ].COLOR,
            }
          : {};
        return (
          <div className="collapsed-table-row-style center-item" style={color}>
            {`${record.status.name}` || ""}
            <EyeNote
              record={record}
              viewApproveModal={handler.viewApproveModal}
            />
          </div>
        );
      },
      onCell: (record) => ({
        colSpan: record.key === "add" ? 0 : handler.isEditing(record) ? 2 : 1,
      }),
    },
    {
      title: "",
      key: "action",
      width: COLUMN_WIDTH_CM0015[10],
      render: (record) => {
        /**
                 + Điều kiện hiển thị button edit: Khi record có trạng thái 否認 (Tài liệu submit bị reject) 
                hoặc 取り下げ (Tài liệu submit bị thu hồi) hoặc 未承認 (Tài liệu chưa được duyệt)
                 */
        const {
          MONEY_REJECT,
          MONEY_NOT_APPROVED,
          MONEY_RETRIEVE,
          MONEY_APPROVED,
        } = STATUS_CM_CHILD_TABLE;

        const editButton = [
          MONEY_REJECT.CODE,
          MONEY_NOT_APPROVED.CODE,
          MONEY_RETRIEVE.CODE,
          MONEY_APPROVED.CODE,
        ].includes(record?.status?.code ?? "") ? (
          <div className="button-style">
            <img
              alt=""
              src={EditButtonIconSvg}
              onClick={() => handler.edit(record)}
            />
          </div>
        ) : (
          <></>
        );
        /**
                + Điều kiện hiển thị button xóa: Khi record có trạng thái 否認 hoặc 取り下げ hoặc 未承認
                 */
        const deleteButton = [
          MONEY_REJECT.CODE,
          MONEY_NOT_APPROVED.CODE,
          MONEY_RETRIEVE.CODE,
        ].includes(record?.status?.code ?? "") ? (
          <div
            className="button-style"
            style={{ marginLeft: 8 }}
            onClick={() => handler.deleteRecord(record)}
          >
            <img alt="" src={DeleteButtonIconSvg} />
          </div>
        ) : (
          <></>
        );

        return (
          <div className="center-item">
            {checkButton ? (
              <>
                {editButton}
                {deleteButton}
              </>
            ) : (
              <></>
            )}
          </div>
        );
      },
      onCell: (record) => ({
        colSpan: record.key === "add" ? 0 : handler.isEditing(record) ? 0 : 1,
      }),
    },
  ];

  return (
    <Form
      form={handler.form}
      style={{ padding: 0, margin: 0 }}
      onValuesChange={(changedValues: any, values: any) => {
        handler.checkEnableButtons(changedValues, values);
      }}
    >
      <Table
        scroll={{ x: "max-content", y: 320 }}
        rowClassName={(record) =>
          handler.isEditing(record) ? "row-no-padding" : ""
        }
        bordered={true}
        columns={columns}
        dataSource={[{ key: "add" }, ...handler.data, ...handler.newLines]}
        className="collapsed-table-header table-footer-inside hide-scroll-bar"
        style={{ display: "flex", flexDirection: "column-reverse" }}
        pagination={false}
        summary={(pageData) => {
          let manualInputTotal = 0;
          let amountTotalTotal = 0;
          let actualCostTotal = 0;
          const idInputCategoryPercent = handler.inputCategories.find(
            (input) => input.code === INPUT_CATEGORY_CODE.percentage
          )?.id;

          pageData.forEach(
            ({
              manualInput,
              actualCost,
              inputMethod,
              directCost,
              multiplicationFactor,
            }) => {
              const amountTotalCal: number =
                !directCost ||
                multiplicationFactor === undefined ||
                1 - multiplicationFactor === 0
                  ? 0
                  : directCost / (1 - multiplicationFactor) - directCost;
              if (inputMethod !== idInputCategoryPercent)
                manualInputTotal += manualInput ?? 0;
              amountTotalTotal += amountTotalCal;
              if (actualCost) actualCostTotal += actualCost;
            }
          );

          return (
            <Table.Summary fixed>
              <Table.Summary.Row
                className="children-table-summary"
                style={{ background: "#FAFAFA" }}
              >
                <Table.Summary.Cell index={0} colSpan={4}>
                  合計金額
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={2} colSpan={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={3} colSpan={0}></Table.Summary.Cell>

                <Table.Summary.Cell index={4}>
                  <span
                    className="center-item text-nowrap"
                    style={{ textAlign: "center" }}
                  >
                    {`${manualInputTotal.toLocaleString("en-US", {
                      maximumFractionDigits: 2,
                    })}円`}
                  </span>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5}></Table.Summary.Cell>
                <Table.Summary.Cell index={6}>
                  <span
                    className="center-item text-nowrap"
                    style={{ textAlign: "center" }}
                  >
                    {`${amountTotalTotal.toLocaleString("en-US", {
                      maximumFractionDigits: 2,
                    })}円`}
                  </span>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7} className="text-center">
                  <span
                    className="center-item text-nowrap"
                    style={{ textAlign: "center" }}
                  >
                    {`${actualCostTotal.toLocaleString("en-US", {
                      maximumFractionDigits: 2,
                    })}円`}
                  </span>
                </Table.Summary.Cell>
                <Table.Summary.Cell
                  index={8}
                  align="center"
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={9}
                  align="center"
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={10}
                  align="center"
                ></Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
      />
      <ImageViewerModalComponent
        imageURL={handler.linkEvidence}
        title={evidenceName}
      />
      <PdfViewerModalComponent
        pdfURL={handler.linkEvidence}
        title={evidenceName}
      />
      <ApproveEditModal callbackOk={handler.updateOnNoteModal} />
      <ApproveViewModal callbackOk={handler.updateOnNoteModal} />
    </Form>
  );
};

export default CollapsedTableUI;
