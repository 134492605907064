import React, { useEffect } from "react";
import { Modal, Button, Row, Col, Spin } from "antd";
import Table from "./Table";
import { RootState } from "../../../../../store";
import { useSelector } from "react-redux";
import LoadingComponent from "../../../../../common/components/icons/LoadingComponent";
import XIconModal from "../../../../../assets/icons/x-icon-modal.svg";
import { HandlerImpl } from "../handler/HandlerImpl";
import CM017_2Component from "../../../CM017_2";
import CM0012_2Component from "../../../CM0012_2";
import IconCircleCheck from "../../../../../assets/icons/akar-icons_circle-check-fill.svg";
import { STATUS_CM_CHILD_TABLE } from "../../../../../common/constants/COMMON";
import ApprovalFlow from "../../../../../common/components/approval-flow/ApprovalFlow";
import helpers from "../../../../../common/helpers/common";
import ApproveConfirmModal from "../../../../../common/components/modal-approve-confirm";
import useWindowDimensions from "../../../../../common/helpers/windowDimension";

interface props {
  handler: HandlerImpl;
  afterSuccess?: () => void;
  onSubmit?: () => void;
  onReject?: () => void;
}

function MainUI({ handler, afterSuccess, onSubmit, onReject }: props) {
  const isVisibleCM0023 = useSelector(
    (state: RootState) => state.cm0023.isVisibleCM0023
  );
  const documentId = useSelector((state: RootState) => state.cm0023.documentId);
  const documentInfo = useSelector(
    (state: RootState) => state.cm0023.documentInfo
  );
  const isVisibleCM0017_2 = useSelector(
    (state: RootState) => state.cm017_2.isVisible
  );
  const isVisibleCM0012_2 = useSelector(
    (state: RootState) => state.cm0012_2.isVisible
  );
  const isDisableRetrieveButton = useSelector(
    (state: RootState) => state.cm0023.isDisableRetrieveButton
  );
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (isVisibleCM0023 && documentId) {
      Promise.all([
        handler.getProjectAlls(documentId),
        handler.getWorkflowSummaryMaterialProjectApprovalProcess(documentId),
      ]);
    }
  }, [isVisibleCM0023, documentId]);

  return (
    <Modal
      style={{ marginLeft: width > 1872 ? 300 : 0, marginTop: 40, marginBottom: 40 }}
      className="group-detail-modal modal-fixed-1572"
      visible={isVisibleCM0023}
      centered={width > 1700}
      maskClosable={false}
      width={1572}
      closable={false}
      onCancel={handler.cancelModalCM0023}
      footer={
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {onSubmit && onReject ? (
            <>
              {/* DONE */}
              <ApproveConfirmModal onSubmit={onSubmit} />
              <Button
                className="button-purple cm047-btn-submit"
                icon={<img alt="" src={IconCircleCheck} />}
                size="large"
                onClick={handler.showConfirmApproveModal}
                disabled={
                  !(
                    documentInfo?.status ===
                    STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE
                  ) ||
                  !(
                    handler.currentUserApproval &&
                    helpers.checkApproveInWorkFlow(handler.currentUserApproval)
                  )
                }
                style={{ marginLeft: 16 }}
              >
                承認
              </Button>
              <Button
                size="large"
                className="button-warning cm047-btn-reject"
                onClick={onReject}
                disabled={
                  !(
                    documentInfo?.status ===
                    STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE
                  ) ||
                  !(
                    handler.currentUserApproval &&
                    helpers.checkApproveInWorkFlow(handler.currentUserApproval)
                  )
                }
              >
                否認
              </Button>
            </>
          ) : (
            <Button
              disabled={isDisableRetrieveButton}
              className="button-purple cm047-btn-submit"
              size="large"
              type="primary"
              style={{ marginLeft: 16, width: 120 }}
              onClick={() =>
                documentId
                  ? handler.putReSummaryMaterialCostProjectRetrieve(
                    { documentId: documentId },
                    afterSuccess
                  )
                  : null
              }
            >
              取り下げ
            </Button>
          )}

          <Button
            size="large"
            style={{ marginLeft: 16, width: 118 }}
            onClick={handler.cancelModalCM0023}
          >
            キャンセル
          </Button>
        </div>
      }
    >
      <Spin indicator={LoadingComponent} spinning={handler.loading}>
        <div
          className="w-100-percent header-modal-containter"
          style={{ height: 50 }}
        >
          <div className="header-modal-title-om004 ">材料費</div>
          <div
            style={{ cursor: "pointer" }}
            onClick={handler.cancelModalCM0023}
          >
            <img alt="" src={XIconModal} style={{ height: 18, width: 18 }} />
          </div>
        </div>
        <div style={{ marginTop: 24, marginBottom: 8 }}>
          <ApprovalFlow
            isNotCheckingUser={true}
            breadcrumb={[]}
            listApprove={handler.workflow}
            currentUserApproval={handler.currentUserApproval}
          />
        </div>
        <div
          style={{ margin: "24px 24px 24px 24px", backgroundColor: "#ffffff" }}
        >
          <Row style={{ marginBottom: 24 }}>
            <Col span={8} style={{ display: "flex" }}>
              <div style={{ marginRight: 24 }} className="CM0017_3-info-title">
                資料名
              </div>
              <div className="CM0017_3-info-content">
                {documentInfo.documentTitle}
              </div>
            </Col>
            <Col span={8} style={{ display: "flex" }}>
              <div style={{ marginRight: 24 }} className="CM0017_3-info-title">
                作成日
              </div>
              <div className="CM0017_3-info-content">
                {documentInfo.createdDate}
              </div>
            </Col>
            <Col span={8} style={{ display: "flex" }}>
              <div style={{ marginRight: 24 }} className="CM0017_3-info-title">
                対象期間
              </div>
              <div className="CM0017_3-info-content">
                {documentInfo.targetPeriod}
              </div>
            </Col>
          </Row>
          <Table
            handleScroll={handler.handleScroll}
            summaryTotal={handler.summaryData}
            materialCostProjectList={handler.materialCostProjectList}
            setCompanyExpandKeys={handler.setCompanyExpandKeys}
            checkCompanyKeyExpand={handler.checkCompanyKeyExpand}
            setProjectExpandKeys={handler.setProjectExpandKeys}
            checkProjectKeyExpand={handler.checkProjectKeyExpand}
          />
        </div>
        {isVisibleCM0017_2 ? <CM017_2Component /> : <></>}
        {isVisibleCM0012_2 ? <CM0012_2Component /> : <></>}
      </Spin>
    </Modal>
  );
}

export default MainUI;
