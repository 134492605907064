import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Form } from 'antd';
import ConfirmModal from '../../../../../../common/components/modal/ConfirmModal';
import MESSAGE from '../../../../../../common/constants/MESSAGE';
import { LABEL_MESSAGE, NOTIFICATION_TITLE } from '../../../../../../common/constants/MESSAGE';
import {
    setDocumentCM08Id,
    setDocumentCM08OriginId,
    setIsVisibleCM008,
    setSubmittedDateCM08,
} from '../../../../CM008/presenter/slice/Slice';
import {
    setDocumentCM010Id,
    setDocumentCM010OriginId,
    setIsVisibleCM0010,
    setSubmittedDateCM010,
} from '../../../../CM0010/presenter/slice/Slice';
import {
    setDocumentCM014Id,
    setDocumentOriginIdCM014,
    setIsVisibleCM0014,
    setSubmittedDateCM014,
} from '../../../../CM0014/presenter/slice/Slice';
import {
    setConstructionId,
    setDocumentId,
    setDocumentOriginId,
    setIsVisibleCM0016,
    setSubmittedDateCM016,
} from '../../../../CM0016/presenter/slice/Slice';
import { setLoading } from '../../../../../../common/slice/CommonSlice';
import { CM0048ServiceImpl } from '../../usecase/ServiceImpl';
import moment from 'moment';
import SuccessNotification from '../../../../../../common/components/notification/SuccessNotification';
import COMMON from '../../../../../../common/constants/COMMON';
import finalPropsSelectorFactory from 'react-redux/es/connect/selectorFactory';
import { setRetrieve } from '../../../wrapper/presenter/slice/Slice';
const PARAMS = {
    sortType61: 'sortType61',
    sortType62: 'sortType62',
    sortType63: 'sortType63',
    sortType64: 'sortType64',
};

type SelectedRow = {
    materialCost: number[];
    outsourcingCost: number[];
    laborCost: number[];
    indirectCost: number[];
};

type DetailSubmit = {
    type: string;
    id: number;
    statusCode: string;
} | null;

const DEFAULT_SELECTED_ROW: SelectedRow = {
    materialCost: [],
    outsourcingCost: [],
    laborCost: [],
    indirectCost: [],
};

export const STATUS_CODE = {
    pending: 'money_waiting_for_approval',
    approve: 'money_approved',
    withdrawal: 'money_retrieve',
    reject: 'money_reject',
};

export const MATERIAL_COST = 'materialCost';
export const OUTSOURCING_COST = 'outsourcingCost';
export const LABOR_COST = 'laborCost';
export const INDIRECT_COST = 'indirectCost';

const CM048Handler = (service: CM0048ServiceImpl) => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const sortType61 = searchParams.get(PARAMS.sortType61);
    const sortType62 = searchParams.get(PARAMS.sortType62);
    const sortType63 = searchParams.get(PARAMS.sortType63);
    const sortType64 = searchParams.get(PARAMS.sortType64);

    const [total, setTotal] = useState(100);
    const [selectedRow, setSelectedRow] = useState<SelectedRow>(DEFAULT_SELECTED_ROW);
    const [visibleReject, setVisibleReject] = useState(false);
    const [visibleRejectView, setVisibleRejectView] = useState(false);
    const [isChangeValueReject, setIsChangeValueReject] = useState(false);
    const [rejectReason, setRejectReason] = useState('');
    const [materialList, setMaterialList] = useState<any[]>([]);
    const [outsourceList, setOutsourceList] = useState<any[]>([]);
    const [laborList, setLaborList] = useState<any[]>([]);
    const [indirectList, setIndirectList] = useState<any[]>([]);
    const [refresh, setRefresh] = useState(false);
    const [detailSubmit, setDetailSubmit] = useState<DetailSubmit>(null);

    const [formReject] = Form.useForm();

    const getReMaterialList = async (): Promise<void> => {
        try {
            dispatch(setLoading(true));
            const response = await service.getReMaterialList();
            if (response?.results) {
                const selectedKey: any[] = [];
                const data = response.results.map((element, index) => {
                    if (!(element.status.code === STATUS_CODE.pending))
                        selectedKey.push(element.id);
                    return {
                        ...element,
                        key: element.id,
                        no: index + 1,
                    };
                });
                setSelectedRow((preState) => {
                    return { ...preState, materialCost: selectedKey };
                });
                setMaterialList(data);
                return;
            }
            setMaterialList([]);
        } finally {
            dispatch(setLoading(false));
        }
    };

    const getReOutsourceList = async (): Promise<void> => {
        try {
            dispatch(setLoading(true));
            const response = await service.getReOutsourceList();
            if (response?.results) {
                const selectedKey: any[] = [];
                const data = response.results.map((element, index) => {
                    if (!(element.status.code === STATUS_CODE.pending))
                        selectedKey.push(element.id);
                    return {
                        ...element,
                        key: element.id,
                        no: index + 1,
                    };
                });
                setSelectedRow((preState) => {
                    return { ...preState, outsourcingCost: selectedKey };
                });
                setOutsourceList(data);
                return;
            }
            setOutsourceList([]);
        } finally {
            dispatch(setLoading(false));
        }
    };

    const getReLaborList = async (): Promise<void> => {
        try {
            dispatch(setLoading(true));
            const response = await service.getReLaborList();
            if (response?.results) {
                const selectedKey: any[] = [];
                const data = response.results.map((element, index) => {
                    if (!(element.status.code === STATUS_CODE.pending))
                        selectedKey.push(element.id);
                    return {
                        ...element,
                        key: element.id,
                        no: index + 1,
                    };
                });
                setLaborList(data);
                setSelectedRow((preState) => {
                    return { ...preState, laborCost: selectedKey };
                });
                return;
            }
            setLaborList([]);
        } finally {
            dispatch(setLoading(false));
        }
    };
    const getReIndirectList = async (): Promise<void> => {
        try {
            dispatch(setLoading(true));
            const response = await service.getReIndirectList();
            if (response?.results) {
                const selectedKey: any[] = [];
                const data = response.results.map((element, index) => {
                    if (!(element.status.code === STATUS_CODE.pending))
                        selectedKey.push(element.id);
                    return {
                        ...element,
                        key: element.id,
                        no: index + 1,
                    };
                });
                setSelectedRow((preState) => {
                    return { ...preState, indirectCost: selectedKey };
                });
                setIndirectList(data);
                return;
            }
            setIndirectList([]);
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleGetData = () => {
        dispatch(setRetrieve(false));

        getReMaterialList();
        getReOutsourceList();
        getReLaborList();
        getReIndirectList();
    };

    const handleDataView = (
        data: any[],
        sortType: string | null,
        status: string | null,
        from: string | null,
        to: string | null,
        keyword: string | null,
        clear: string | null,
    ): any[] => {
        if (!data) return [];
        let results = [...data];
        if (sortType) {
            if (sortType === 'ascend') {
                results = [...data].sort((a, b) => {
                    const A = moment(a.submittedDate);
                    const B = moment(b.submittedDate);
                    return A.valueOf() - B.valueOf();
                });
            } else {
                results = [...data].sort((a, b) => {
                    const A = moment(a.submittedDate);
                    const B = moment(b.submittedDate);
                    return B.valueOf() - A.valueOf();
                });
            }
        }
        if (status) {
            results = [...results].filter((element) => element.status.id === Number(status));
        }

        if (from && to && !clear) {
            results = [...results].filter(
                (element) =>
                    (moment(element.submittedDate) >= moment(from, COMMON.FORMAT_DATE2) ||
                        moment(element.submittedDate).format(COMMON.FORMAT_DATE2) === from) &&
                    (moment(element.submittedDate) <= moment(to, COMMON.FORMAT_DATE2) ||
                        moment(element.submittedDate).format(COMMON.FORMAT_DATE2) === to),
            );
        }

        if (keyword) {
            results = [...results].filter((element) => element.fileName?.includes(keyword));
        }
        // handle no
        results = results.map((element, index) => {
            return {
                ...element,
                no: index + 1,
                originId:
                    element?.documentDirectMaterialCostId ??
                    element?.documentDirectOutsourceCostId ??
                    element?.documentDirectLaborCostId ??
                    element?.documentIndirectCostId ??
                    0,
            };
        });
        return results;
    };

    const handleChangeSelectedRow = (selectedRowKeys: number[], type: string) => {
        switch (type) {
            case MATERIAL_COST:
                setSelectedRow({ ...selectedRow, materialCost: selectedRowKeys });
                break;
            case OUTSOURCING_COST:
                setSelectedRow({ ...selectedRow, outsourcingCost: selectedRowKeys });
                break;
            case LABOR_COST:
                setSelectedRow({ ...selectedRow, laborCost: selectedRowKeys });
                break;
            case INDIRECT_COST:
                setSelectedRow({ ...selectedRow, indirectCost: selectedRowKeys });
                break;
        }
    };

    const handleCancelReject = () => {
        if (isChangeValueReject) {
            ConfirmModal({
                onOk: () => {
                    setVisibleReject(false);
                    setIsChangeValueReject(false);
                    formReject.resetFields();
                },
                className: 'confirm__modal',
                title: MESSAGE.MESSAGE_020,
                description: MESSAGE.MESSAGE_021,
                canceText: LABEL_MESSAGE.CANCEL_MODAL,
                okText: LABEL_MESSAGE.OK_MODAL,
                isCenterWithoutMenu: true,
            });
        } else {
            setVisibleReject(false);
        }
    };
    const handleSubmitReject = async (value: any) => {
        try {
            dispatch(setLoading(true));
            const data = {
                documentDirectLaborCost: {
                    listDocumentId: [] as number[],
                    reason: value.rejectReason,
                },
                documentDirectMaterialCost: {
                    listDocumentId: [] as number[],
                    reason: value.rejectReason,
                },
                documentDirectOutsourceCost: {
                    listDocumentId: [] as number[],
                    reason: value.rejectReason,
                },
                documentIndirectCostCost: {
                    listDocumentId: [] as number[],
                    reason: value.rejectReason,
                },
            };
            if (!detailSubmit?.type) {
                for (const item of materialList)
                    if (
                        item?.status?.code === STATUS_CODE.pending &&
                        selectedRow.materialCost?.includes(item.id)
                    )
                        data.documentDirectMaterialCost.listDocumentId.push(item.id);
                for (const item of outsourceList)
                    if (
                        item?.status?.code === STATUS_CODE.pending &&
                        selectedRow.outsourcingCost?.includes(item.id)
                    )
                        data.documentDirectOutsourceCost.listDocumentId.push(item.id);
                for (const item of laborList)
                    if (
                        item?.status?.code === STATUS_CODE.pending &&
                        selectedRow.laborCost?.includes(item.id)
                    )
                        data.documentDirectLaborCost.listDocumentId.push(item.id);
                for (const item of indirectList)
                    if (
                        item?.status?.code === STATUS_CODE.pending &&
                        selectedRow.indirectCost?.includes(item.id)
                    )
                        data.documentIndirectCostCost.listDocumentId.push(item.id);
            } else if (detailSubmit?.id) {
                switch (detailSubmit?.type) {
                    case MATERIAL_COST:
                        data.documentDirectMaterialCost.listDocumentId.push(detailSubmit?.id);
                        break;
                    case OUTSOURCING_COST:
                        data.documentDirectOutsourceCost.listDocumentId.push(detailSubmit?.id);
                        break;
                    case LABOR_COST:
                        data.documentDirectLaborCost.listDocumentId.push(detailSubmit?.id);
                        break;
                    case INDIRECT_COST:
                        data.documentIndirectCostCost.listDocumentId.push(detailSubmit?.id);
                        break;
                }
            }
            const response = await service.putMoneyRejectCost(data);
            SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
            setRefresh(!refresh);
            setVisibleReject(false);
            formReject.resetFields();
            dispatch(setIsVisibleCM008(false));
            dispatch(setIsVisibleCM0010(false));
            dispatch(setIsVisibleCM0014(false));
            dispatch(setIsVisibleCM0016(false));
        } finally {
            dispatch(setLoading(false));
        }
    };
    const handleChangeValueReject = (value: any) => {
        setIsChangeValueReject(true);
    };
    const handleCloseViewRejectReason = () => {
        setVisibleRejectView(false);
    };
    const handleRejectData = (isDetail?: boolean) => {
        setVisibleReject(true);
        if (!isDetail) setDetailSubmit(null);
    };

    const handleSortTable = (sorter: any, type: string) => {
        switch (type) {
            case MATERIAL_COST:
                searchParams.set(PARAMS.sortType61, sorter.order);
                break;
            case OUTSOURCING_COST:
                searchParams.set(PARAMS.sortType62, sorter.order);
                break;
            case LABOR_COST:
                searchParams.set(PARAMS.sortType63, sorter.order);
                break;
            case INDIRECT_COST:
                searchParams.set(PARAMS.sortType64, sorter.order);
                break;
        }
        setSearchParams(searchParams);
    };
    const handleViewRejectReason = (rejectReason: string) => {
        setVisibleRejectView(true);
        setRejectReason(rejectReason);
    };

    const funcClickDocumentMaterialCost = (
        documentId: number,
        documentOriginId: number,
        submittedDate: string,
        statusCode: string,
    ) => {
        dispatch(setDocumentCM08Id(documentId));
        dispatch(setDocumentCM08OriginId(documentOriginId));
        dispatch(setSubmittedDateCM08(moment(submittedDate).format(COMMON.FORMAT_DATE_CI)));
        dispatch(setIsVisibleCM008(true));
        setDetailSubmit({
            type: MATERIAL_COST,
            id: documentId,
            statusCode,
        });
    };
    const funcClickDocumentOutsourcingCost = (
        documentId: number,
        documentOriginId: number,
        submittedDate: string,
        statusCode: string,
    ) => {
        dispatch(setDocumentCM010Id(documentId));
        dispatch(setDocumentCM010OriginId(documentOriginId));
        dispatch(setSubmittedDateCM010(moment(submittedDate).format(COMMON.FORMAT_DATE_CI)));
        dispatch(setIsVisibleCM0010(true));
        setDetailSubmit({
            type: OUTSOURCING_COST,
            id: documentId,
            statusCode,
        });
    };
    const funcClickDocumentLaborCost = (
        documentId: number,
        documentOriginId: number,
        submittedDate: string,
        statusCode: string,
    ) => {
        dispatch(setDocumentCM014Id(documentId));
        dispatch(setDocumentOriginIdCM014(documentOriginId));
        dispatch(setSubmittedDateCM014(moment(submittedDate).format(COMMON.FORMAT_DATE_CI)));
        dispatch(setIsVisibleCM0014(true));
        setDetailSubmit({
            type: LABOR_COST,
            id: documentId,
            statusCode,
        });
    };
    const funcClickDocumentIndirect = (
        documentId: number,
        documentOriginId: number,
        submittedDate: string,
        statusCode: string,
        constructionId?: number,
    ) => {
        dispatch(setDocumentId(documentId));
        dispatch(setDocumentOriginId(documentOriginId));
        dispatch(setConstructionId(constructionId));
        dispatch(setSubmittedDateCM016(moment(submittedDate).format(COMMON.FORMAT_DATE_CI)));
        dispatch(setIsVisibleCM0016(true));
        setDetailSubmit({
            type: INDIRECT_COST,
            id: documentId,
            statusCode,
        });
    };

    const handleSubmitData = async () => {
        try {
            dispatch(setLoading(true));
            setDetailSubmit(null);
            const data = {
                documentDirectLaborCost: {
                    listDocumentId: [] as number[],
                },
                documentDirectMaterialCost: {
                    listDocumentId: [] as number[],
                },
                documentDirectOutsourceCost: {
                    listDocumentId: [] as number[],
                },
                documentIndirectCostCost: {
                    listDocumentId: [] as number[],
                },
            };
            for (const item of materialList)
                if (
                    item?.status?.code === STATUS_CODE.pending &&
                    selectedRow.materialCost?.includes(item.id)
                )
                    data.documentDirectMaterialCost.listDocumentId.push(item.id);
            for (const item of outsourceList)
                if (
                    item?.status?.code === STATUS_CODE.pending &&
                    selectedRow.outsourcingCost?.includes(item.id)
                )
                    data.documentDirectOutsourceCost.listDocumentId.push(item.id);
            for (const item of laborList)
                if (
                    item?.status?.code === STATUS_CODE.pending &&
                    selectedRow.laborCost?.includes(item.id)
                )
                    data.documentDirectLaborCost.listDocumentId.push(item.id);
            for (const item of indirectList)
                if (
                    item?.status?.code === STATUS_CODE.pending &&
                    selectedRow.indirectCost?.includes(item.id)
                )
                    data.documentIndirectCostCost.listDocumentId.push(item.id);

            const response = await service.putMoneyApproveCost(data);
            SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
            setRefresh(!refresh);
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleSubmitDataDetail = async () => {
        try {
            dispatch(setLoading(true));
            const data = {
                documentDirectLaborCost: {
                    listDocumentId: [] as number[],
                },
                documentDirectMaterialCost: {
                    listDocumentId: [] as number[],
                },
                documentDirectOutsourceCost: {
                    listDocumentId: [] as number[],
                },
                documentIndirectCostCost: {
                    listDocumentId: [] as number[],
                },
            };
            switch (detailSubmit?.type) {
                case MATERIAL_COST:
                    data.documentDirectMaterialCost.listDocumentId.push(detailSubmit?.id);
                    break;
                case OUTSOURCING_COST:
                    data.documentDirectOutsourceCost.listDocumentId.push(detailSubmit?.id);
                    break;
                case LABOR_COST:
                    data.documentDirectLaborCost.listDocumentId.push(detailSubmit?.id);
                    break;
                case INDIRECT_COST:
                    data.documentIndirectCostCost.listDocumentId.push(detailSubmit?.id);
                    break;
            }

            const response = await service.putMoneyApproveCost(data);
            SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
            setRefresh(!refresh);
            dispatch(setIsVisibleCM008(false));
            dispatch(setIsVisibleCM0010(false));
            dispatch(setIsVisibleCM0014(false));
            dispatch(setIsVisibleCM0016(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const funcCheckDisableSubmitReject = () => {
        const foundPendingMaterial = materialList.some(
            (ele) => ele?.canApprove && selectedRow.materialCost?.includes(ele?.id),
        );
        const foundPendingOutsource = outsourceList.some(
            (ele) => ele?.canApprove && selectedRow.outsourcingCost?.includes(ele?.id),
        );
        const foundPendingLabor = laborList.some(
            (ele) => ele?.canApprove && selectedRow.laborCost?.includes(ele?.id),
        );
        const foundPendingIndirect = indirectList.some(
            (ele) => ele?.canApprove && selectedRow.indirectCost?.includes(ele?.id),
        );
        return (
            !foundPendingMaterial &&
            !foundPendingOutsource &&
            !foundPendingLabor &&
            !foundPendingIndirect
        );
    };

    return {
        materialList,
        outsourceList,
        laborList,
        indirectList,
        total,
        selectedRow,
        formReject,
        visibleReject,
        visibleRejectView,
        isChangeValueReject,
        sortType61,
        sortType62,
        sortType63,
        sortType64,
        rejectReason,
        refresh,
        detailSubmit,

        handleChangeSelectedRow,
        handleCancelReject,
        handleSubmitReject,
        handleChangeValueReject,
        handleCloseViewRejectReason,
        handleRejectData,
        handleViewRejectReason,
        handleSortTable,
        handleGetData,
        handleDataView,
        handleSubmitData,
        funcCheckDisableSubmitReject,
        funcClickDocumentMaterialCost,
        funcClickDocumentOutsourcingCost,
        funcClickDocumentLaborCost,
        funcClickDocumentIndirect,
        handleSubmitDataDetail,
    };
};

export default CM048Handler;
