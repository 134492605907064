import CM0017_3Api from './infrastructure/api/Api';
import CM0017_3Handler from './presenter/handler/Handler';
import MainUI from './presenter/ui/UI';
import CM0017_3Service from './usecase/Service';

import '../../../assets/styles/AM/AM002.css';
import '../../../assets/styles/OM/OM002.css';
import '../../../assets/styles/CM/CM0017.css';

type props = {
    afterSuccess?: () => void;
    onSubmit?: () => void;
    onReject?: () => void;
    disableSubmitOrReject?: boolean;
    hasWorkflow?: boolean
};

const CM0017_3Component = (props: props) => {
    const api = CM0017_3Api();
    const service = CM0017_3Service(api);
    const handler = CM0017_3Handler(service);
    return <MainUI handler={handler} {...props} />;
};

export default CM0017_3Component;
