import { useState } from 'react';
import { UseCaseInterface } from '../../usecase/ServiceImpl';
import moment from 'moment';
import COMMON from '../../../../../common/constants/COMMON';
import { useDispatch } from 'react-redux';
import { OrderDetail } from '../../entity/Entity';
import { setLoading } from '../../../../../common/slice/CommonSlice';
import { NOTIFICATION_TITLE } from '../../../../../common/constants/MESSAGE';
import ErrorNotification from '../../../../../common/components/notification/ErrorNotification';

const Handler = (service: UseCaseInterface) => {
    const dispatch = useDispatch();
    const [orderDetail, setOrderDetail] = useState<OrderDetail>();

    const getOrderDetail = async (params: any) => {
        try {
            dispatch(setLoading(true));
            const results = await service.getOrderDetail(params);
            setOrderDetail(results);
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        } finally {
            dispatch(setLoading(false));
        }
    };

    const [customer, setCustomer] = useState<any>({
        companyName: 'VTI株式会社',
        postCode: '〒301-0003',
        address: '東京',
        phoneNumber: '03-6450-1681',
        fax: '03-6450-1681',
        ID: 'thumua',
        name: '渋川',
        mail: 'trangdt@vti,com.vn',
        numberUser: {
            issued: 100,
            notIssued: 20,
        },
    });
    const [usage, setUsage] = useState<any[]>([
        {
            key: 1,
            planName: 'ベープランシックプラン',
            startDate: moment().format(COMMON.FORMAT_DATE_CM),
            endDate: moment().format(COMMON.FORMAT_DATE_CM),
            isValid: true,
            basicCharge: 4000,
            discount: 2000,
            monthlyFee: 2000,
            child: [
                {
                    key: 1,
                    id: 1,
                    planFeature: '収支管理',
                    startDate: moment().format(COMMON.FORMAT_DATE_CM),
                    endDate: moment().format(COMMON.FORMAT_DATE_CM),
                    isValid: false,
                    basicCharge: 2000,
                    discount: 2000,
                    monthlyFee: 0,
                },
                {
                    key: 2,
                    id: 2,
                    planFeature: 'ダッシュボード',
                    startDate: moment().format(COMMON.FORMAT_DATE_CM),
                    endDate: moment().format(COMMON.FORMAT_DATE_CM),
                    isValid: false,
                    basicCharge: 2000,
                    discount: 0,
                    monthlyFee: 2000,
                },
            ],
        },
    ]);

    return { customer, usage, orderDetail, getOrderDetail };
};

export default Handler;
