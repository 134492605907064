import { Form, Input } from 'antd';
import SearchSvg from '../../../../../../../assets/icons/search-icon.svg';
import useWindowDimensions from '../../../../../../../common/helpers/windowDimension';

interface Props {
  onSearch: React.ChangeEventHandler<HTMLInputElement>;
  width?: number;
  responsiveRate?: number
}
const SearchBarComponent = ({ responsiveRate = 1.2, width = 400, onSearch }: Props) => {
  const windowDimension = useWindowDimensions()
  const responsiveWidth = width * (windowDimension.width > 1750 ? 1 : (windowDimension.width / (1750 * responsiveRate)))
  return (
    <div className="searchbar-container ">
      <Form.Item name="text" style={{ margin: 0, padding: 0, width: responsiveWidth }}>
        <Input
          autoComplete="off"
          size="large"
          prefix={<img alt="" src={SearchSvg} />}
          placeholder="検索したい名前を入力してください"  // CR #344212
          className="search-bar-om001 w-100-percent "
          onChange={onSearch}
        />
      </Form.Item>
    </div>
  );
};
export default SearchBarComponent;
