import { AxiosResponse } from "axios";
import axiosConfig from "../../../../../config/axiosConfig";

const AM002Api = () => {
  const getAttendanceCompanyColabSubConstruction = async (params: {
    companyId: number;
    keyword: string;
    page: string;
    size: string;
    from?: string; // 2006-01-02
    to?: string; // 2006-01-02
    timeOffset: number;
    sortBy?: string;
    sortType?: "DESC" | "ASC";
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/attendance/company-colab/subconstruction";
    return await axiosConfig.get(url, { params });
  };

  const getAttendanceCompanyColabTotalCost = async (params: {
    companyId: number;
    from: string; // 2006-01-02
    to: string; // 2006-01-02
    timeOffset: number;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "attendance/company-colab/total-cost";
    return await axiosConfig.get(url, { params });
  };

  const doExportAM002 = async (params: any): Promise<any> => {
        try {
            const url = '/attendance/exportAM002';
            const response = await axiosConfig.get(url, { params, responseType: 'blob'  });
            
            return response;
        } catch (error) {
            throw error;
        }
    };

  return {
    getAttendanceCompanyColabSubConstruction,
    getAttendanceCompanyColabTotalCost,
    doExportAM002,
  };
};

export default AM002Api;
