import ErrorNotification from '../../../../common/components/notification/ErrorNotification';
import { NOTIFICATION_TITLE } from '../../../../common/constants/MESSAGE';
import { T01Api } from './ServiceImpl';

const T01Service = (api: T01Api) => {
  const getTaxSetting = async (params: any) => {
    try {
      const response = await api.getTaxSetting({ ...params });
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return;
    }
  };

  const updateTaxSetting = async (data: any) => {
    try {
      const response = await api.updateTaxSetting(data);
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return;
    }
  };

  return {
    getTaxSetting,
    updateTaxSetting,
  };
};

export default T01Service;
