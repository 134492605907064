import { ConfigProvider, Form, Popover } from "antd";
import CollapsedFalseSVG from "../../../../../../../assets/icons/collapsed-false.svg";
import CollapsedTrueSVG from "../../../../../../../assets/icons/collapsed-true.svg";
import { HandlerFilterImpl } from "../../../handler/HandlerImpl";
import { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import FilterComponent from "./Filter";
import React from "react";
import ProjectDropDownComponent from "./ProjectDropdown";
import FilterIconSvg from "../../../../../../../assets/icons/filter-icon.svg";

type Props = {
  handler: HandlerFilterImpl;
};

const FilterSearchUI = ({ handler }: Props) => {
  const { form } = handler;

  const [searchParams] = useSearchParams();
  const selectedProjectId = searchParams.get("selectedProjectId") || undefined;
  const selectedConstructionId =
    searchParams.get("selectedConstructionId") || undefined;
  const tab = searchParams.get("tab") || "1";
  const keywordConstruction = searchParams.get("keywordConstruction") || "";
  const isCollapsed = searchParams.get("isCollapsed") ? true : false;
  const searchDropDown = searchParams.get("searchDropDown") || "";

  useEffect(() => {
    (async () => {
      handler.initiateFilter();
      await handler.initiateProjectConstruction(
        searchDropDown,
        keywordConstruction,
        selectedProjectId,
        selectedConstructionId
      );
    })();
  }, []);

  const firstMount1 = useRef(false);
  useEffect(() => {
    if (firstMount.current) {
      handler.onResetFilter();
    }
    firstMount1.current = true;
  }, [tab]);

  const firstMount = useRef(false);
  useEffect(() => {
    (async () => {
      if (firstMount.current) await handler.searchDropDown(searchDropDown);
      firstMount.current = true;
    })();
  }, [searchDropDown]);

  return (
    <ConfigProvider direction="ltr">
      <main className="company-container">
        <Form form={form}>
          {isCollapsed ? (
            <React.Fragment>
              <main className="center-item" style={{ marginBottom: 27 }}>
                <div
                  className="collapsed-button-open"
                  onClick={() => handler.onCollapse(false)}
                >
                  <img alt="" src={CollapsedFalseSVG} />
                </div>
              </main>
              <Popover
                getPopupContainer={(trigger: any) => trigger.parentNode}
                placement="rightTop"
                content={
                  <div style={{ width: 258 - 24, padding: "8px 12px" }}>
                    {
                      <React.Fragment>
                        <main>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: 16,
                            }}
                          >
                            <span className="group-list-title-text">
                              絞込み
                            </span>
                          </div>
                        </main>
                        {tab === "2" ? (
                          <></>
                        ) : (
                          <ProjectDropDownComponent
                            searchDropDownValue={handler.searchDropDownValue}
                            onSearchDropDown={handler.onSearchDropDown}
                            style={{ marginTop: 32 }}
                            onProjectChoose={handler.onProjectChoose}
                            onConstructionChoose={handler.onConstructionChoose}
                            project={handler.infiData}
                            construction={
                              handler.constructionList.displayConstructions
                            }
                            loading={handler.infiLoading}
                            onScroll={handler.onScroll}
                          />
                        )}
                        <FilterComponent
                          onFromDateChange={handler.onFromDateChange}
                          onToDateChange={handler.onToDateChange}
                          onFilter={handler.onFilter}
                          onResetFilter={handler.onResetFilter}
                          isCollapsed={isCollapsed}
                          rangeDate={handler.rangeDate}
                        />
                      </React.Fragment>
                    }
                  </div>
                }
                trigger="click"
              >
                <div
                  style={{ marginTop: 27, cursor: "pointer" }}
                  className="center-item"
                >
                  {" "}
                  <img src={FilterIconSvg} alt="" />
                </div>
              </Popover>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <main>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: 16,
                  }}
                >
                  <span
                    className="group-list-title-text"
                    style={{ marginLeft: 16 }}
                  >
                    絞込み
                  </span>
                  <div
                    className="collapsed-button-close"
                    onClick={() => handler.onCollapse(true)}
                  >
                    <img alt="" src={CollapsedTrueSVG} />
                  </div>
                </div>
              </main>
              <div style={{ paddingLeft: 16, paddingRight: 16 }}>
                {tab === "2" ? (
                  <></>
                ) : (
                  <ProjectDropDownComponent
                    searchDropDownValue={handler.searchDropDownValue}
                    onSearchDropDown={handler.onSearchDropDown}
                    style={{ marginTop: 32 }}
                    onProjectChoose={handler.onProjectChoose}
                    onConstructionChoose={handler.onConstructionChoose}
                    project={handler.infiData}
                    construction={handler.constructionList.displayConstructions}
                    loading={handler.infiLoading}
                    onScroll={handler.onScroll}
                  />
                )}
                <FilterComponent
                  onFromDateChange={handler.onFromDateChange}
                  onToDateChange={handler.onToDateChange}
                  onFilter={handler.onFilter}
                  onResetFilter={handler.onResetFilter}
                  isCollapsed={isCollapsed}
                  rangeDate={handler.rangeDate}
                />
              </div>
            </React.Fragment>
          )}
        </Form>
      </main>
    </ConfigProvider>
  );
};

export default FilterSearchUI;
