import {
  Button,
  Card,
  Col,
  DatePicker,
  Empty,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Tag,
  Tooltip,
} from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PM008Hanlder } from "../handler/HandlerImpl";
import { RootState } from "../../../../../store";
import FormItem from "antd/es/form/FormItem";
import locale from "antd/es/date-picker/locale/ja_JP";
import CloudUpIcon from "../../../../../assets/icons/cloud-up-icon.svg";
import IconFile from "../../../../../assets/icons/ant-design_file-pdf-filled.svg";
import "../../../../../../src/assets/styles/PM-007/PM007.css";
import Dragger from "antd/lib/upload/Dragger";
import "moment/locale/ja";
import moment from "moment";
import PdfViewerModalComponent from "../../../../../common/components/pdf-viewer-modal/PdfViewerModalComponent";
import ImageViewerModalComponent from "../../../../../common/components/image-viewer-modal/ImageViewerComponent";
import {
  formatCCUSID,
  onBlurTrimFormItem,
  typeInteger,
} from "../../../../../common/helpers/typeInput";
import CloseIcon from "../../../../../assets/icons/x-icon-modal.svg";
import REGEX from "../../../../../common/constants/REGEX";
import MESSAGE, { TITLE } from "../../../../../common/constants/MESSAGE";
import helpers from "../../../../../common/helpers/common";
import PM008_1Component from "../../../PM008_1";
import BuildingSVG from "../../../../../assets/icons/building-purple.svg";
import IconCloseModal from "../../../../../assets/icons/icon-close-modal.svg";
import XIconModal from "../../../../../assets/icons/x-icon-modal.svg";
import LoadingComponent from "../../../../../common/components/icons/LoadingComponent";
import { MAX_LENGTH, PLAN_TYPE } from "../../../../../common/constants/COMMON";
import IconTooltip from "../../../../../assets/icons/icon-tooltip.svg";

interface props {
  handler: PM008Hanlder;
  funcAfter?: () => void;
}

const dummyRequest = (value: any) => {
  setTimeout(() => {
    value.onSuccess("ok");
  }, 0);
};

moment.locale("ja", {
  week: {
    dow: 1, /// Date offset
  },
});

const PM008UI = ({ handler, funcAfter }: props, data: any) => {
  const isModalOpen = useSelector(
    (state: RootState) => state.pm008.isModalOpen
  );
  const isPlanFree =
    useSelector((state: RootState) => state.auth.authData?.order?.code) ===
    PLAN_TYPE.PLAN_FREE;
  const loading = useSelector((state: RootState) => state.common.loading);
  useEffect(() => {
    handler.getDataStatus({
      page: handler.page,
      size: "5",
    });
  }, [handler.page]);
  useEffect(() => {
    const unloadCallback = (event: any) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };
    if (handler.isEdit) {
      window.addEventListener("beforeunload", unloadCallback);
    } else {
      window.removeEventListener("beforeunload", unloadCallback);
    }
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, [handler.isEdit]);

  return (
    <>
      <PM008_1Component handleConfirm={handler.handleConfirmChooseCompany} />
      <Modal
        // title={<p className="title-modal">新しい案件を作成する</p>}
        visible={isModalOpen}
        onOk={handler.createNewProject}
        onCancel={handler.handleCancel}
        width="996px"
        style={{ marginLeft: 300 }}
        centered={true}
        closable={false}
        footer={
          <>
            <Button
              htmlType="submit"
              type="primary"
              form="formCreatePM008"
              size="large"
              style={{ letterSpacing: -1, marginRight: 8 }}
            >
              作成
            </Button>
            <Button size="large" key="back" onClick={handler.handleCancel}>
              キャンセル
            </Button>
          </>
        }
      >
        <Spin spinning={!!loading} indicator={LoadingComponent}>
          <div
            className="w-100-percent header-modal-containter"
            style={{ height: 50, marginBottom: 24 }}
          >
            <div className="header-modal-title-om004 ">
              新しい案件を作成する
            </div>
            <div style={{ cursor: "pointer" }} onClick={handler.handleCancel}>
              <img alt="" src={XIconModal} style={{ height: 18, width: 18 }} />
            </div>
          </div>
          <Form
            layout="vertical"
            form={handler.form}
            id="formCreatePM008"
            name="formCreatePM008"
            onFinish={(value) => handler.createNewProject(value, funcAfter)}
            onValuesChange={() => handler.setIsEdit(true)}
            onKeyDown={helpers.preventEnterForm}
          >
            <Row gutter={25}>
              <Col span={3}>
                <span className="label-input-modal">
                  ステータス<span className="sterisk">*</span>
                </span>
              </Col>
              <Col span={21}>
                <Form.Item
                  name={"statusId"}
                  rules={[
                    { required: true, message: MESSAGE.MESSAGE_REQUIRED },
                  ]}
                >
                  <Select
                    className="container-dropdown"
                    size="large"
                    style={{
                      minWidth: "100%",
                      color: handler.handleCheckColor(handler?.status),
                    }}
                    onChange={handler.handleChangeStatus}
                    onPopupScroll={handler.onScrollLocation}
                  >
                    {handler.dataStatus.map((element: any, index: number) => {
                      return (
                        <Select.Option
                          key={element?.id + index}
                          value={element?.id}
                          className="controller__update--option"
                        >
                          {element?.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            {!isPlanFree && (
              <Row gutter={25}>
                   <Col span={3} style={{ paddingLeft: 4 }}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Tooltip
                      placement="topLeft"
                      title={
                        <div style={{ width: "fit-content" }}>
                          {MESSAGE.MESSAGE_CCUSID_TUTORIAL}
                        </div>
                      }
                    >
                      <img src={IconTooltip} alt="" />
                    </Tooltip>
                    <span className="label-input-modal">{TITLE.CCUSID}</span>
                  </div>
                </Col>
                <Col span={21}>
                  <Form.Item
                    name="projectCcus"
                    rules={[
                      { required: false, message: "" },
                      () => ({
                        validator(_, value) {
                          if (value && value !== "") {
                            value = value.replaceAll(" ", "");
                            if (value.length < MAX_LENGTH.CCUSID) {
                              return Promise.reject(
                                new Error(MESSAGE.MESSAGE_LESS_THAN_14)
                              );
                            }
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input
                      size="large"
                      maxLength={MAX_LENGTH.FORMAT_CCUSID}
                      onChange={(e) => {
                        formatCCUSID(e, "projectCcus", handler.form);
                      }}
                      onKeyPress={typeInteger}
                      onInput={(e: React.FormEvent<HTMLInputElement>) => {
                        const inputElement = e.currentTarget;
                        const inputValue = inputElement.value;
                        const filteredValue = inputValue.replace(/[^0-9]/g, '');
                        inputElement.value = filteredValue.slice(0,14);
                      }}
                      onBlur={() =>
                        onBlurTrimFormItem(handler.form, "projectCcus")
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}

            <Row gutter={25}>
              <Col span={3}>
                <span className="label-input-modal">
                  案件名<span className="sterisk">*</span>
                </span>
              </Col>
              <Col span={21}>
                <Form.Item
                  name={"projectName"}
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (value && value !== "") {
                          // CHECK INPUT VIETNAMESE
                          if (!REGEX.NOT_VIETNAM.test(value)) {
                            return Promise.reject(
                              new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                            );
                          }
                          if (value?.length > MAX_LENGTH.ADDRESS) {
                            return Promise.reject(new Error(""));
                          }
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(MESSAGE.MESSAGE_REQUIRED)
                        );
                      },
                    }),
                  ]}
                >
                  <Input
                    size="large"
                    onBlur={() =>
                      onBlurTrimFormItem(handler.form, "projectName")
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={25}>
              <Col span={3}>
                <span className="label-input-modal">
                  案件担当者<span className="sterisk">*</span>
                </span>
              </Col>
              <Col span={21}>
                <Form.Item
                  name={"projectManager"}
                  rules={[
                    { required: true, message: MESSAGE.MESSAGE_REQUIRED },
                  ]}
                >
                  <Select
                    size="large"
                    listHeight={210}
                    showSearch
                    filterOption={() => true}
                    loading={handler.loadingOption}
                    onDropdownVisibleChange={(open) => {
                      if (open) handler.handleGetMember();
                    }}
                    onSearch={handler.handleSearchOption}
                    onPopupScroll={handler.handleScrollMember}
                    getPopupContainer={(trigger: any) => trigger.parentNode}
                    notFoundContent={
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="該当なデータはありません"
                      />
                    }
                    className=""
                  >
                    {handler.memberProjectManager.map((select) => {
                      return (
                        <Select.Option
                          style={{ alignItems: "center" }}
                          key={select.id}
                          value={select.id}
                        >
                          <div
                            style={{
                              fontSize: 14,
                              fontWeight: 600,
                              color: "#121212",
                            }}
                          >
                            {select.fullName || select.username}
                          </div>
                          <div
                            style={{
                              fontSize: 12,
                              fontWeight: 400,
                              color: "#666",
                            }}
                          >
                            {select.emailAddress}
                          </div>
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={25}>
              <Col span={3}>
                <span className="label-input-modal" style={{ marginRight: 4 }}>
                  案件副担当者
                </span>
              </Col>
              <Col span={21}>
                <Form.Item
                  name={"deputyProjectManager"}
                  rules={[{ required: false, message: "" }]}
                >
                  <Select
                    mode="multiple"
                    size="large"
                    showArrow
                    showSearch
                    listHeight={210}
                    filterOption={() => true}
                    loading={handler.loadingOption}
                    onSearch={handler.handleSearchOption}
                    onDropdownVisibleChange={(open) => {
                      if (open) handler.handleGetMember();
                    }}
                    getPopupContainer={(trigger: any) => trigger.parentNode}
                    tagRender={({ label, closable, onClose }: any) => {
                      return (
                        <Tag
                          closable={closable}
                          onClose={onClose}
                          color="rgba(231, 230, 255, 1)"
                          closeIcon={
                            <img
                              style={{ width: 10, marginLeft: 6 }}
                              alt=""
                              src={CloseIcon}
                            />
                          }
                          style={{
                            margin: "2px 4px 2px 0",
                            display: "flex",
                            alignItems: "center",
                            height: 28,
                            borderRadius: 4,
                            color: "#222222",
                            fontSize: 14,
                            fontWeight: 400,
                          }}
                        >
                          {label[0]?.props?.children}
                        </Tag>
                      );
                    }}
                    onPopupScroll={handler.handleScrollMember}
                    notFoundContent={
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="該当なデータはありません"
                      />
                    }
                  >
                    {handler.memberDeputyProjectManager.map((select) => {
                      return (
                        <Select.Option
                          style={{ alignItems: "center" }}
                          key={select.id}
                          value={select.id}
                        >
                          <div
                            style={{
                              fontSize: 14,
                              fontWeight: 600,
                              color: "#121212",
                            }}
                          >
                            {select.fullName || select.username}
                          </div>
                          <span
                            style={{
                              fontSize: 12,
                              fontWeight: 400,
                              color: "#666",
                            }}
                          >
                            {select.emailAddress}
                          </span>
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={25}>
              <Col span={3}>
                <span className="label-input-modal">
                  発注元会社<span className="sterisk">*</span>
                </span>
              </Col>
              <Col span={21} onClick={() => handler.handleOpenChooseCompany()}>
                <Form.Item
                  name={"orderAddress"}
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (value && value !== "") {
                          // CHECK INPUT VIETNAMESE
                          if (!REGEX.NOT_VIETNAM.test(value)) {
                            return Promise.reject(
                              new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                            );
                          }
                          if (value?.length > MAX_LENGTH.ADDRESS) {
                            return Promise.reject(new Error(""));
                          }
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(MESSAGE.MESSAGE_REQUIRED)
                        );
                      },
                    }),
                  ]}
                >
                  <Input
                    readOnly
                    suffix={
                      <img
                        src={BuildingSVG}
                        style={{ cursor: "pointer" }}
                        onClick={() => handler.handleOpenChooseCompany()}
                      />
                    }
                    size="large"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={25}>
              <Col span={3}>
                <span className="label-input-modal">
                  契約工事期間<span className="sterisk">*</span>
                </span>
              </Col>
              <Col span={21}>
                <Form.Item
                  name={"ConstructionPeriod"}
                  rules={[
                    { required: true, message: MESSAGE.MESSAGE_REQUIRED },
                  ]}
                >
                  <DatePicker.RangePicker
                    style={{ width: "100%", height: "40px" }}
                    format={"YYYY年 MM月 DD日"}
                    locale={locale}
                    placement="bottomRight"
                    inputReadOnly
                    placeholder={["開始日", "終了日"]} // CR #344212
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={25}>
              <Col span={3}>
                <span className="label-input-modal">
                  住所<span className="sterisk">*</span>
                </span>
              </Col>
              <Col span={21}>
                <Form.Item
                  name={"location"}
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (value && value !== "") {
                          // CHECK INPUT VIETNAMESE
                          if (!REGEX.NOT_VIETNAM.test(value)) {
                            return Promise.reject(
                              new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                            );
                          }
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(MESSAGE.MESSAGE_REQUIRED)
                        );
                      },
                    }),
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="例：東京都渋谷区神南1-2-3 シブヤビルディング101号室" // CR #344212
                    onBlur={() => onBlurTrimFormItem(handler.form, "location")}
                    maxLength={MAX_LENGTH.ADDRESS}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={25}>
              <Col span={3}>
                <span className="label-input-modal">現場案内図</span>
              </Col>
              <Col span={21}>
                <Card
                  bodyStyle={{ paddingLeft: "11px" }}
                  className={`${
                    handler.fileList?.length !== 0 && "pm005-upload-file"
                  }`}
                >
                  <FormItem name={"file"}>
                    <Dragger
                      onPreview={async (file) =>
                        await handler.previewFile(file)
                      }
                      customRequest={dummyRequest}
                      fileList={[...handler.fileList]}
                      onChange={handler.onChangeUpload}
                      onRemove={handler.onDropUpload}
                      iconRender={() => <img alt="" src={IconFile} />}
                      accept="application/pdf, image/png, image/jpeg"
                    >
                      {handler.fileList?.length === 0 && (
                        <>
                          <p className="ant-upload-drag-icon">
                            <img alt="" src={CloudUpIcon} />
                          </p>
                          <p className="ant-upload-text">
                            ここにファイルをドラッグ・ドロップ (.pdf または
                            .png/.jpeg/.jpg)
                          </p>
                          <p
                            className="ant-upload-hint"
                            style={{ margin: "6px 0px 10px 0px" }}
                          >
                            または
                          </p>
                          <div className="btn-upload-custom">
                            ファイルを選択する（.pdf または .png/.jpeg/.jpg）
                          </div>
                        </>
                      )}
                    </Dragger>
                  </FormItem>
                </Card>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
      {isModalOpen ? (
        <>
          <PdfViewerModalComponent pdfURL={handler.fileUrl} />
          <ImageViewerModalComponent
            imageURL={handler.fileUrl}
            title="現場案内図"
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default PM008UI;
