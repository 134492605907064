import axiosConfig from "../../../../../config/axiosConfig";
import { RetrieveMaterialDocument } from "../../entity/Entity";

const CM008Api = () => {
  const getListSubmittedDirectCost = async (params: any): Promise<any> => {
    try {
      const url = "/re/submitted/direct-cost/material/list";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getPresignLinkS3 = async (params: any): Promise<any> => {
    const url = "/project/presign-link";
    return await axiosConfig.get(url, { params });
  };

  const getProjectSummaryReceivedMoney = async (params: any): Promise<any> => {
    const url = "/money/project/summary/received-money";
    return await axiosConfig.get(url, { params });
  };

  const getConstructionSummaryCostInformation = async (
    params: any
  ): Promise<any> => {
    const url = "/money/summary/construction/cost/information";
    return await axiosConfig.get(url, { params });
  };

  const getMaterialListCompany = async (params: any): Promise<any> => {
    const url = "/money/direct-cost/material/list-company";
    return await axiosConfig.get(url, { params });
  };

  const getMaterialList = async (params: any): Promise<any> => {
    const url = "/money/direct-cost/material/list";
    return await axiosConfig.get(url, { params });
  };

  const retrieveMaterialDocument = async (
    data: RetrieveMaterialDocument
  ): Promise<any> => {
    const url = "/money/retrieve/direct-cost/material";
    return await axiosConfig.put(url, data);
  };
  const getWorkflowMaterialApprovalProcess = async (params: {
    documentId: number;
  }): Promise<any> => {
    const url = "/workflow/material-approval-process";
    return await axiosConfig.get(url, { params });
  };

  return {
    getListSubmittedDirectCost,
    retrieveMaterialDocument,
    getProjectSummaryReceivedMoney,
    getConstructionSummaryCostInformation,
    getMaterialListCompany,
    getMaterialList,
    getPresignLinkS3,
    getWorkflowMaterialApprovalProcess,
  };
};

export default CM008Api;
