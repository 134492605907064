import { OrderDetail } from '../entity/Entity';
import { APIInterface } from './ServiceImpl';

const Service = (api: APIInterface) => {
    const getOrderDetail = async (params: any): Promise<OrderDetail> => {
        try {
            const data = await api.getOrderDetail(params);
            return data?.results;
        } catch (error) {
            throw error;
        }
    };
    return { getOrderDetail };
};

export default Service;
