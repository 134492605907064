import COMMON from '../../../../common/constants/COMMON';
import { ForgotBody } from '../entity/Entity';
import { ASForgotPasswordApi } from './ServiceImpl';

const ASForgotPasswordService = (asForgotPasswordApi: ASForgotPasswordApi) => {
    const forgot = async (bodyData: ForgotBody): Promise<any> => {
        try {
            const response = await asForgotPasswordApi.forgot(bodyData);
            if (response?.results?.token)
                localStorage.setItem('forgotToken', response.results.token);
            return response;
        } catch (error) {
            throw error;
        }
    };
    return { forgot };
};

export default ASForgotPasswordService;
