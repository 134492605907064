import { useDispatch } from "react-redux";
import { setLoading } from "../../../../common/slice/CommonSlice";
import { TodoUseCase } from "../../usecase/ServiceImpl";
import { setTodoList } from "../slice/Slice";

const TodoHandler = (todoService: TodoUseCase) => {
  const dispatch = useDispatch();

  const getTodos = async () => {
    dispatch(setLoading(true));
    try {
      const response = await todoService.getTodoList();
      dispatch(setTodoList(response));
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };

  return {
    getTodos,
  };
};

export default TodoHandler;
