import React from 'react';
import { Modal } from 'antd';
import InfoCircle from '../../../assets/icons/info-circle-24.svg';
import XIcon24 from '../../../assets/icons/x-icon-24.svg';

import '../../../assets/styles/components/confirm-modal.css';

type props = {
    onOk: () => void;
    onCancel?: () => void;
    title: string;
    description: string | string[] | JSX.Element;
    canceText: string;
    okText: string;
    width?: number;
    height?: number;
    className?: string;
};

const WarningModalCR001 = ({
    onOk,
    onCancel,
    title,
    description,
    canceText,
    okText,
    width,
    height,
    className,
}: props) => {
    const descriptionComponent = Array.isArray(description) ?
        description.map((element, index) => (<div key={index}>{element}</div>))
        : description


    const modal = Modal.confirm({
        icon: null,
        width: 773,
        style: {
            marginLeft: 300,
        },
        centered: true,
        className: className ?? 'confirm__modal-CR001',
        content: (
            <section>
                <section style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    height: 46,
                    padding: '0px 24px 0px 24px',
                    marginTop: 24,
                    borderBottom: '1px solid #DAE2EE'
                }}>
                    <div style={{ display: 'flex' }} className="h4-modal-title">
                        <img alt="" src={InfoCircle} style={{ marginRight: 8, height: 24, width: 24 }} />
                        {title}
                    </div>
                    <div className='button-style' onClick={() => modal.destroy()}>
                        <img src={XIcon24} alt='' />
                    </div>
                </section>
                <div
                    className="text"
                    style={{ padding: '0px 21px 0px 24px', marginTop: 30, marginBottom: 15 }}
                >
                    <div>{descriptionComponent}</div>
                </div>
            </section>
        ),
        okText: okText,
        okType: 'primary',
        closable: false,
        cancelText: canceText,
        zIndex: 9999,
        okButtonProps: {
            style: {
                background: '#605BFF',
            },
        },
        onOk: async () => {
            if (onOk)
                await onOk();
            else modal.destroy()
        },
    });
    return modal
};

export default WarningModalCR001;
