import { uuidv4 } from '@firebase/util';
import { Form } from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ErrorNotification from '../../../../../common/components/notification/ErrorNotification';
import { NOTIFICATION_TITLE } from '../../../../../common/constants/MESSAGE';
import { RootState } from '../../../../../store';
import { ReSummarySubmittedCompanyOutsourceCostOffsetList } from '../../entity/Entity';
import { CM0017_3_2UseCase } from '../../usecase/ServiceImpl';
import { setIsVisibleCM0017_3_2, setLoadingCM0017_3_2 } from '../slice/Slice';

const CM0017_3_2Handler = (CM0017_3_2Service: CM0017_3_2UseCase) => {
    // LIB FUNCTION
    const dispatch = useDispatch();

    //STATE
    const [form] = Form.useForm();
    const typeModal = useSelector((state: RootState) => state.cm0017_3_2.typeModal);

    const [
        reSummarySubmittedCompanyOutsourceCostOffsetListLocal,
        setReSummarySubmittedCompanyOutsourceCostOffsetListLocal,
    ] = useState<ReSummarySubmittedCompanyOutsourceCostOffsetList[]>([]);
    const [totalCost, setTotalCost] = useState<number>(0);

    //function
    const getReSummarySubmittedCompanyOutsourceCostOffsetList = async (
        documentOutsourceCostDataSummaryId: number,
        categoryId?: number,
    ): Promise<any> => {
        try {
            dispatch(setLoadingCM0017_3_2(true));
            const params = {
                documentOutsourceCostDataSummaryId,
                categoryId,
            };
            const res = await CM0017_3_2Service.getReSummarySubmittedCompanyOutsourceCostOffsetList(
                params,
            );
            if (res?.results) {
                const data = formatReSummarySubmittedCompanyOutsourceCostOffsetListLocal(
                    res.results,
                );
                setReSummarySubmittedCompanyOutsourceCostOffsetListLocal(data);
            }
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        } finally {
            dispatch(setLoadingCM0017_3_2(false));
        }
    };

    const formatReSummarySubmittedCompanyOutsourceCostOffsetListLocal = (data: any) => {
        const arr: ReSummarySubmittedCompanyOutsourceCostOffsetList[] = data.map(
            (item: any, index: number) => {
                return {
                    key: uuidv4(),
                    id: item?.id,
                    item: item.name,
                    taxCategoryComsumptionId: item?.category?.id,
                    taxCategoryComsumptionName: item?.category?.description,
                    offsetAmount: item?.value,
                };
            },
        );
        return arr;
    };

    const handleCalculateChange = (data: ReSummarySubmittedCompanyOutsourceCostOffsetList[]) => {
        let total: number = 0;
        data.forEach((item) => {
            const cost = item?.offsetAmount
                ? item.offsetAmount
                : form.getFieldValue(`offsetAmount${item.key}`) ?? 0;
            if (!isNaN(cost)) total += cost;
        });
        setTotalCost(total);
    };

    const onCancel = () => {
        dispatch(setIsVisibleCM0017_3_2(false));
    };

    return {
        form,
        typeModal,
        reSummarySubmittedCompanyOutsourceCostOffsetListLocal,
        totalCost,
        onCancel,
        handleCalculateChange,
        getReSummarySubmittedCompanyOutsourceCostOffsetList,
    };
};

export default CM0017_3_2Handler;
