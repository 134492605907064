import { AxiosResponse } from "axios";
import axiosConfig from "../../../../../config/axiosConfig";

const FilterApi = () => {
  const getProjectAlls = async (params: {
    keyword?: string;
    page: string;
    size: string;
    sortBy?: string;
    sortType?: string;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/project/list-by-permission";
    return await axiosConfig.get(url, { params });
  };
  const getConstructionListByProject = async (params: {
    projectId: number;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/construction/list-by-project";
    return await axiosConfig.get(url, { params });
  };

  return {
    getProjectAlls,
    getConstructionListByProject,
  };
};

export default FilterApi;
