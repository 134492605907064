import React from 'react';

// Services
import { useDispatch } from 'react-redux';
import { resetCM0012 } from '../../../CM0012/presenter/slice/Slice';
import { resetCM009 } from '../slice/Slice';

const CM009Handler = () => {
    const dispatch = useDispatch();

    const clearRedux = () => {
        dispatch(resetCM0012());
        dispatch(resetCM009());
    };
    return { clearRedux };
};

export default CM009Handler;
