import COMMON from "../../../../common/constants/COMMON";
import { WorkflowResponseData } from "../../CM008/entity/Entity";
import {
  ReSummaryCompanyProjectOutsourceCostRetrievePayload,
  ReSummaryCompanyProjectOutsourceCostRetrieveResults,
  ReSummarySubmittedOutsourceCostCompanyProjectConstructionResults,
  ReSummarySubmittedOutsourceCostCompanyProjectResults,
  ReSummarySubmittedOutsourceCostCompanyResults,
} from "../entity/Entity";
import { APIImpl } from "./ServiceImpl";

const Service = (api: APIImpl) => {
  const getReSummarySubmittedOutsourceCostCompany = async (params: {
    documentId: number;
  }): Promise<ReSummarySubmittedOutsourceCostCompanyResults[]> => {
    try {
      const response = await api.getReSummarySubmittedOutsourceCostCompany(
        params
      );
      return response.data?.results ?? [];
    } catch (error: any) {
      throw error;
    }
  };
  const getReSummarySubmittedOutsourceCostCompanyProject = async (params: {
    documentId: number;
    companyId?: number;
    colabRefId?: number;

    page?: number;
    size?: number;
  }): Promise<ReSummarySubmittedOutsourceCostCompanyProjectResults[]> => {
    try {
      const sizePage = COMMON.DEFAULT_SIZE;
      const response =
        await api.getReSummarySubmittedOutsourceCostCompanyProject({
          ...params,
          page: 1,
          size: sizePage,
        });
      let results: ReSummarySubmittedOutsourceCostCompanyProjectResults[] =
        response.data?.results ?? [];

      const numberPages = response.data?.pagination?.numPages ?? 0;
      for (let i = 2; i <= numberPages; i++) {
        const response =
          await api.getReSummarySubmittedOutsourceCostCompanyProject({
            ...params,
            page: i,
            size: sizePage,
          });
        results = results.concat(response.data?.results ?? []);
      }

      return results ?? [];
    } catch (error: any) {
      throw error;
    }
  };
  const getReSummarySubmittedOutsourceCostCompanyProjectConstruction =
    async (params: {
      documentId: number;
      companyId?: number;
      colabRefId?: number;
      projectId: number;
    }): Promise<
      ReSummarySubmittedOutsourceCostCompanyProjectConstructionResults[]
    > => {
      try {
        const response =
          await api.getReSummarySubmittedOutsourceCostCompanyProjectConstruction(
            params
          );
        return response.data?.results ?? [];
      } catch (error: any) {
        throw error;
      }
    };
  const putReSummaryCompanyProjectOutsourceCostRetrieve = async (
    data: ReSummaryCompanyProjectOutsourceCostRetrievePayload
  ): Promise<ReSummaryCompanyProjectOutsourceCostRetrieveResults> => {
    try {
      const response =
        await api.putReSummaryCompanyProjectOutsourceCostRetrieve(data);
      return response.data;
    } catch (error: any) {
      throw error;
    }
  };
  const getWorkflowSummaryOutsourceCompanyApprovalProcess = async (params: {
    documentId: number;
  }): Promise<WorkflowResponseData> => {
    try {
      const response =
        await api.getWorkflowSummaryOutsourceCompanyApprovalProcess(params);
      return response.data;
    } catch (error: any) {
      throw error;
    }
  };
  return {
    putReSummaryCompanyProjectOutsourceCostRetrieve,
    getReSummarySubmittedOutsourceCostCompanyProjectConstruction,
    getReSummarySubmittedOutsourceCostCompany,
    getReSummarySubmittedOutsourceCostCompanyProject,
    getWorkflowSummaryOutsourceCompanyApprovalProcess,
  };
};

export default Service;
