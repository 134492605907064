import { useDispatch } from 'react-redux';
// Built-in
import React from 'react';
import { CompletedPaidAmountServiceImpl } from '../../usecase/ServiceImpl';
import { setLoading } from '../../../../../common/slice/CommonSlice';
import { setCompletedWork } from '../slice/Slice';
import ErrorNotification from '../../../../../common/components/notification/ErrorNotification';
import { NOTIFICATION_TITLE } from '../../../../../common/constants/MESSAGE';

// Source files

const CompletedPaidAmountHandler = (service: CompletedPaidAmountServiceImpl) => {
    const dispatch = useDispatch();

    const getMoneyConstructionWorkAmountCompleted = async (params: {
        constructionId: number;
        from: string;
        to: string;
    }): Promise<void> => {
        try {
            dispatch(setLoading(true));
            const responseData = await service.getMoneyConstructionWorkAmountCompleted(params);
            dispatch(
                setCompletedWork({
                    billingVolume: responseData?.billingVolume ?? 0,
                    billBalance: responseData?.billBalance ?? 0,
                }),
            );
        } catch (error: any) {
            ErrorNotification(error.message ?? NOTIFICATION_TITLE.ERROR);
        } finally {
            dispatch(setLoading(false));
        }
    };

    return {
        getMoneyConstructionWorkAmountCompleted,
    };
};

export default CompletedPaidAmountHandler;
