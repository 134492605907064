import { Avatar, Button, Layout, Menu, Tooltip } from "antd";
import React from "react";
import type { MenuProps } from "antd";

// images
import MenuLogo from "../../../../../assets/images/logo-menu.svg";

// icons
import CategoryWhite from "../../../../../assets/icons/category-white.svg";
import CalendarWhite from "../../../../../assets/icons/calendar-white.svg";
import DocumentWhite from "../../../../../assets/icons/document-white.svg";
import WorkWhite from "../../../../../assets/icons/work-white.svg";
import GraphWhite from "../../../../../assets/icons/graph-white.svg";
import SettingWhite from "../../../../../assets/icons/setting-white.svg";
import FolderWhite from "../../../../../assets/icons/icon-base.svg";
import Logout from "../../../../../assets/icons/logout.svg";
import Budget from "../../../../../assets/icons/budget_icon.svg";
import KeyWhite from "../../../../../assets/icons/Icon_key_white.svg";
import IconCCUS from "../../../../../assets/icons/icon-ccus.svg";
// css
import "../../../../../assets/styles/components/menu-wrapper.css";
import { HandlerImpl } from "../handler/HandlerImpl";
import COMMON, { AVATAR_ADMIN, PLAN_TYPE } from "../../../../constants/COMMON";
import { useDispatch, useSelector } from "react-redux";
import { setScroll } from "../../../../slice/CommonSlice";
import { RootState } from "../../../../../store";
import WorkflowNotificationComponent from "../../../workflow-notification";
import { useNavigate } from "react-router-dom";
import { DefaultAvatarComponent } from "../../../default-avatar";
import TooltipText from "../../../tooltip-text/TooltipText";
import { SYSTEM_ROLES } from "../../../../constants/AUTHORIZATION";

const { Content, Sider } = Layout;

const IMAGE_MENU_ITEMS: any = {
  CategoryWhite: <img alt="" src={CategoryWhite} />,
  FolderWhite: <img alt="" src={FolderWhite} />,
  CalendarWhite: <img alt="" src={CalendarWhite} />,
  DocumentWhite: <img alt="" src={DocumentWhite} />,
  WorkWhite: <img alt="" src={WorkWhite} />,
  Budget: <img alt="" src={Budget} />,
  GraphWhite: <img alt="" src={GraphWhite} />,
  SettingWhite: <img alt="" src={SettingWhite} />,
  KeyWhite: <img alt="" src={KeyWhite} />,
  CCUS: <img alt="" src={IconCCUS} />,
};

const generateMenuItems = (menuItems: any[]) => {
  const results = menuItems.map((element) => {
    if (element?.icon) {
      return { ...element, icon: IMAGE_MENU_ITEMS[`${element.icon}`] };
    }
    return element;
  });
  return results;
};

interface Props {
  chosenKey: number;
  handler: HandlerImpl;
  children?: JSX.Element | JSX.Element[];
  openKey?: number;
  contentClass?: string;
  onClickMenu?: (callback: any, value: any) => void;
}

const MenuUI = ({
  chosenKey,
  children,
  openKey,
  handler,
  contentClass,
  onClickMenu,
}: Props) => {
  const { logout, onChoseMenuItem, redirectHome } = handler;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authData = useSelector((state: RootState) => state.auth.authData);
  const menuItems = useSelector((state: RootState) => state.common.menuItems);
  const isRoleAdmin = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdmin
  );
  const isRoleAdminSystem = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdminSystem
  );
  const order = useSelector(
    (state: RootState) => state.auth?.authData?.order?.code
  ); //action.payload?.order?.code;
  const isWorkFlowUserIsInWorkFlows = useSelector(
    (state: RootState) => state.auth.authData?.isWorkFlowUserIsInWorkFlows
  );

  const isOrderFree = order === PLAN_TYPE.PLAN_FREE;
  const isOrderBasic = order === PLAN_TYPE.PLAN_BASIC;
  const isAdminOrderBasic = isOrderBasic && isRoleAdmin;
  return (
    <Layout id="menu-wrapper" className="menu-wrapper">
      <div
        id="noscrollbar"
        style={{
          backgroundColor: "#131125",
          height: "100vh",
          width: 300,
          overflow: "hidden",
        }}
      >
        <Sider
          className="side__menu"
          // collapsible
          style={{
            backgroundColor: "#131125",
            height: "100vh",
            width: 300,
          }}
        >
          <section
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: 32,
              paddingRight: 24,
            }}
          >
            <div
              onClick={redirectHome}
              style={{
                marginTop: 32,
                marginBottom: 35,
                cursor: "pointer",
              }}
            >
              <Tooltip title={process.env.REACT_APP_VERSION ?? ""}>
                <img alt="" src={MenuLogo} />
              </Tooltip>
            </div>
            <WorkflowNotificationComponent />
          </section>
          <Menu
            theme="dark"
            style={{ backgroundColor: "#131125", paddingBottom: 72 }}
            selectedKeys={[chosenKey + ""]}
            defaultOpenKeys={openKey ? [openKey + ""] : []}
            mode="inline"
            items={generateMenuItems(menuItems)}
            onClick={(value) =>
              onClickMenu
                ? onClickMenu(onChoseMenuItem, value)
                : onChoseMenuItem(value)
            }
          ></Menu>
          <div style={{ height: 90, backgroundColor: "#0D0A35" }}></div>
        </Sider>
      </div>
      <div className="sider-profile">
        <div className="profile-avatar">
          <DefaultAvatarComponent
            isAdmin={isRoleAdminSystem || isRoleAdmin}
            avatar={authData?.avatar ?? undefined}
            value={authData?.fullName ?? ""}
            size={40}
          />
        </div>
        <div className="profile-infor ">
          <div className="profile-name">{authData?.fullName}</div>
          <div className="profile-email">
            <TooltipText content={authData?.email ?? ""} />
          </div>
        </div>
        <div className="logout center-item">
          <Button
            style={{ border: "none", backgroundColor: "transparent" }}
            onClick={logout}
            icon={<img alt="" src={Logout} />}
          ></Button>
        </div>
      </div>
      <Layout>
        <Content
          className={contentClass ?? "site-content"}
          id="yma-body"
          onScroll={() => dispatch(setScroll())}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export { MenuUI };
