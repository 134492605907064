import { OM009SliceType } from "./../type/Presenter";
import { createSlice } from "@reduxjs/toolkit";

const initialState: OM009SliceType = {
  groupId: null,
  loading: false,
  isVisible: false,
};

export const om009Slice = createSlice({
  name: "om009",
  initialState,
  reducers: {
    resetOM009: () => initialState,
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsVisibleOM009: (state, action) => {
      state.isVisible = action.payload;
    },
    setGroupIdOM009: (state, action) => {
      state.groupId = action.payload;
    },
  },
});

export const { setLoading, setIsVisibleOM009, setGroupIdOM009, resetOM009 } =
  om009Slice.actions;

export default om009Slice.reducer;
