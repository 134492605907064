import MainUI from './presenter/ui/UI';

// CSS
import '../../../assets/styles/CM/cm001.css';
import '../../../assets/styles/CM/cm003.css';

// Service
import CM003Api from './infrastructure/api/Api';
import CM003Service from './usecase/Service';
import CM003Handler from './presenter/handler/Handler';

const CM003Component = () => {
    const cm003Api = CM003Api();
    const cm003Service = CM003Service(cm003Api);
    const cm003Handler = CM003Handler(cm003Service);

    return <MainUI handler={cm003Handler} />;
};

export default CM003Component;
