import COMMON from '../../../common/constants/COMMON';
import helpers from '../../../common/helpers/common';

const converDataTable = (data: any, page: number, size: number) => {
    const results = data.map((element: any, index: number) => {
        return {
            key: element.id,
            No: index + 1 + (page - 1) * size,
            companyName: element.companyName,
            ID: element.adminName,
            adminName: element.adminFullName,
            email: element.companyEmail,
            postCode: `〒${helpers.formatPostNumber(element.postNumber) ?? ''}`,
            address: element.orderAddress,
            telephoneNumber: element.phoneNumber ?? '',
            fax: element.fax ?? '',
            planDivision: element.plan.subCategory.description,
            optionalFeature: element.isSubscription,
            startDate: helpers.formatDatePresenter(element.startDate, COMMON.FORMAT_DATE_CM),
            endDate: helpers.formatDatePresenter(element.endDate, COMMON.FORMAT_DATE_CM),
            status: element.statusOrder.name,
            statusCode: element.statusOrder.code,
            introduction: element.statusUser.name,
            introductionCode: element.statusUser.code,
            createdAt: helpers.formatDatePresenter(element.createdAt, COMMON.FORMAT_DATE_CM),
        };
    });
    return results;
};

export { converDataTable };
