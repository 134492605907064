import BreadcrumbComponent from "../../../../../common/components/breadcrumb/BreadcrumbComponent";
import MenuWrapperComponent from "../../../../../common/components/menu-wrapper";
import BREADCRUMB from "../../../../../common/constants/BREADCRUMB";
import PM002HeaderUI from "../../../PM002/presenter/ui/Header";
import { PM002UIHandler } from "../handler/HandlerImpl";
import PM002Pagination from "./Pagination";
import CardList from "./CardList";
import "../../../../../assets/styles/PM/PM002.css";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import PdfModal from "./PdfModal";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { PM008Hanlder } from "../../../PM008/presenter/handler/HandlerImpl";
import PM008UI from "../../../PM008/presenter/ui/UI";
import PM012Component from "../../../PM012";
import PM009Component from "../../../PM009";
import ImageViewerModalComponent from "../../../../../common/components/image-viewer-modal/ImageViewerComponent";
import PdfViewerModalComponent from "../../../../../common/components/pdf-viewer-modal/PdfViewerModalComponent";
import { isRefreshPM002 } from "../slice/Slice";
import { requestForToken } from "../../../../../firebase";
interface props {
  handler: PM002UIHandler;
  modalHandler: PM008Hanlder;
}

const PM002UI = ({ handler, modalHandler }: props) => {
  const isPM008ModalOpen = useSelector(
    (state: RootState) => state.pm008.isModalOpen
  );

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const workspaceId = params.get("workspaceId");
  const statusId = params.get("statusId");
  const keyword = params.get("keyword");
  const page = params.get("page");
  const size = params.get("size");
  const isCreated = useSelector((state: RootState) => state.pm008.isCreated);
  const refreshNotification = useSelector(
    (state: RootState) => state.common.refreshNotification
  );
  const refreshNoti = useSelector(
    (state: RootState) => state.pm009.refreshNoti
  );
  const isOpen = useSelector((state: RootState) => state.pm012.isOpen);
  const isRefresh002 = useSelector(isRefreshPM002);
  const refreshPM012 = useSelector(
    (state: RootState) => state.pm012.refreshPM012
  );

  useEffect(() => {
    handler.getData(handler.onParams());
    handler.getStatus(handler.onParams());
    // handler.getAllUser(handler.onParams());
  }, [
    workspaceId,
    statusId,
    keyword,
    page,
    size,
    isCreated,
    handler.isRefresh,
    refreshNotification,
    refreshNoti,
    isRefresh002,
    refreshPM012,
  ]);

  useEffect(() => {
    localStorage.removeItem("parentId");
  }, []);

  useEffect(() => {
    requestForToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.localStorage.getItem("fbToken")]);

  return (
    <MenuWrapperComponent chosenKey={81} openKey={8}>
      <PM002HeaderUI handler={handler} />
      <CardList handler={handler} />
      <PM002Pagination handler={handler} />
      <PM008UI handler={modalHandler} funcAfter={handler.funcAfter} />
      {isOpen ? <PM012Component /> : <></>}
      <PM009Component />
      {!isPM008ModalOpen ? (
        <>
          <ImageViewerModalComponent
            imageURL={handler.pdfUrl}
            title="現場案内図"
          />
          <PdfViewerModalComponent pdfURL={handler.pdfUrl} title="現場案内図" />
        </>
      ) : (
        <></>
      )}
    </MenuWrapperComponent>
  );
};

export default PM002UI;
