import { CM0032SliceType } from './../type/Presenter';
import { createSlice } from '@reduxjs/toolkit';

const initialState: CM0032SliceType = {
    isRefresh: false,
};

export const cm0032Slice = createSlice({
    name: 'cm0032',
    initialState,
    reducers: {
        resetCM0032: () => initialState,
    },
});

export const { resetCM0032 } = cm0032Slice.actions;

export default cm0032Slice.reducer;
