import { Empty, Form, Select, Tag, Tooltip } from "antd";
import { AM010Handler } from "../handler/HandlerImpl";
import MESSAGE from "../../../../../common/constants/MESSAGE";

// Icon
import CloseIcon from "../../../../../assets/icons/x-icon-modal.svg";
import COMMON from "../../../../../common/constants/COMMON";
import { useEffect } from "react";
import { MAX_LENGTH_TEXT } from "../handler/Handler";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import TooltipText from "../../../../../common/components/tooltip-text/TooltipText";

type props = {
  handler: AM010Handler;
};

export default function AM010HeaderTimerForm({ handler }: props) {
  useEffect(() => {
    const location = window.navigator && window.navigator.geolocation;
    if (location) {
      location.getCurrentPosition(
        (position) => {
          handler.handleSetLatLng(
            position.coords.latitude,
            position.coords.longitude
          );
        },
        (error) => {}
      );
    }
  }, []);

  useEffect(() => {
    handler.getProjectStatus();
  }, []);

  useEffect(() => {
    if (handler.projectStatus) {
      handler.handleGetProjectFirstOpenModal({
        page: COMMON.DEFAULT_PAGE,
        size: COMMON.DEFAULT_SIZE,
      });

      handler.handleGetConstructionFirstOpenModal({
        page: COMMON.DEFAULT_PAGE,
        size: COMMON.DEFAULT_SIZE,
        keyword: undefined,
      });
    }
  }, [handler?.projectStatus]);

  return (
    <Form
      onFinish={(e: any) => {
        if (handler.buttonType) {
          handler.handleCheckIn(e);
        } else {
          handler.handleCheckOut(e);
        }
      }}
      name="basic"
      autoComplete="off"
      colon={false}
      form={handler.form}
      className="am010_form"
    >
      <Form.Item
        label="案件名"
        name="projectName"
        rules={[
          {
            required: true,
            message: MESSAGE.MESSAGE_REQUIRED_PROJECT,
          },
        ]}
      >
        <Select
          className="am010__header-select"
          showArrow
          showSearch
          listHeight={130}
          filterOption={() => true}
          loading={handler.loadingOption}
          onSearch={handler.handleSearchOption}
          onSelect={(e: any) => {
            handler.form.setFieldValue("constructionName", null);
            handler.resetProjectWhenSelectOrBlur(e);
            handler.handleFindConstructions(e);
          }}
          getPopupContainer={(trigger: any) => trigger.parentNode}
          tagRender={({ label, closable, onClose }: any) => {
            return (
              <Tag
                closable={closable}
                onClose={onClose}
                color="rgba(231, 230, 255, 1)"
                closeIcon={
                  <img
                    style={{ width: 10, marginLeft: 6 }}
                    alt=""
                    src={CloseIcon}
                  />
                }
                style={{
                  margin: "2px 4px 2px 0",
                  display: "flex",
                  alignItems: "center",
                  height: 28,
                  borderRadius: 4,
                  color: "#222222",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                {label[0]?.props?.children}
              </Tag>
            );
          }}
          onPopupScroll={handler.handleScrollProject}
          notFoundContent={
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="該当なデータはありません"
            />
          }
        >
          {handler.projects.map((select, index) => {
            return (
              <Select.Option key={select.id} value={select.id}>
                <div className="custom-option">{select?.projectName}</div>
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item
        label="工事名"
        name="constructionName"
        rules={[
          {
            required: true,
            message: MESSAGE.MESSAGE_REQUIRED_CONSTRUCTION,
          },
        ]}
      >
        <Select
          className="am010__header-select"
          showArrow
          showSearch
          listHeight={130}
          filterOption={() => true}
          loading={handler.loadingOption}
          onSearch={handler.handleSearchOptionConstruction}
          onSelect={(e: any) => {
            handler.resetConstructionWhenSelectOrBlur(e);
            handler.handleFindProjects(e);
          }}
          getPopupContainer={(trigger: any) => trigger.parentNode}
          tagRender={({ label, closable, onClose }: any) => {
            return (
              <Tag
                closable={closable}
                onClose={onClose}
                color="rgba(231, 230, 255, 1)"
                closeIcon={
                  <img
                    style={{ width: 10, marginLeft: 6 }}
                    alt=""
                    src={CloseIcon}
                  />
                }
                style={{
                  margin: "2px 4px 2px 0",
                  display: "flex",
                  alignItems: "center",
                  height: 28,
                  borderRadius: 4,
                  color: "#222222",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                {label[0]?.props?.children}
              </Tag>
            );
          }}
          onPopupScroll={handler.handleScrollConstruction}
          notFoundContent={
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="該当なデータはありません"
            />
          }
        >
          {handler.constructions.map((select, index) => {
            return (
              <Select.Option key={select.id} value={select.id}>
                <div className="custom-option">{select?.name}</div>
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    </Form>
  );
}
