import axiosConfig from "../../../../../config/axiosConfig";

const OM001Api = () => {
  const getDetailGroup = async (params: any): Promise<any> => {
    try {
      const url = "/organization/detail-group";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  return {
    getDetailGroup,
  };
};

export default OM001Api;
