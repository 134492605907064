import { PM009Api } from "./ServiceImpl";

const PM009Service = (pM009Api: PM009Api) => {
  const getCategoryNotification = async (params: any): Promise<any> => {
    try {
      return await pM009Api.getCategoryNotification(params);
    } catch (error) {
      throw error;
    }
  };

  const getListNotification = async (params: any): Promise<any> => {
    try {
      return await pM009Api.getListNotification(params);
    } catch (error) {
      throw error;
    }
  };

  const readNotification = async (params: any): Promise<any> => {
    try {
      return await pM009Api.readNotification(params);
    } catch (error) {
      throw error;
    }
  };

  const deleteNotification = async (params: any): Promise<any> => {
    try {
      return await pM009Api.deleteNotification(params);
    } catch (error) {
      throw error;
    }
  };

  return {
    getListNotification,
    getCategoryNotification,
    readNotification,
    deleteNotification,
  };
};

export default PM009Service;
