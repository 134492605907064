import { RootState } from "./../../../../../store";

import { useSearchParams } from "react-router-dom";
// Redux
import { useDispatch, useSelector } from "react-redux";

// Services
import { CM0028UseCase } from "./../../usecase/ServiceImpl";
import { useState } from "react";
import helpers from "../../../../../common/helpers/common";
import moment from "moment";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import COMMON from "../../../../../common/constants/COMMON";
import { Form } from "antd";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import { exportCM0028 } from "../../../../../common/helpers/exports/cm/template_cm0028";
import ConfirmModalAsync from "../../../../../common/components/modal/ConfirmModalAsync";
import { doExportForResponse } from "../../../../../common/helpers/exports/common";

const PARAMS = {
  from: "from4",
  to: "to4",
  clear: "clear4",
  openFilter: "openFilter4",
};

const PARAMS_DEFAULT = {
  from: helpers.getFilterTime(moment()),
  to: helpers.getFilterTime(moment()),
};

export const COLUMN_SORT = [
  "paymentExcludingTax",
  "taxConsumption",
  "paymentExcludedTax",
];

const FILE_NAME = `社内収支_労務費_${moment().format(
  COMMON.FORMAT_DATE_SUBMIT
)}`;

const CM0028Handler = (service: CM0028UseCase) => {
  const dispatch = useDispatch();

  const [formFilter] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const from = searchParams.get(PARAMS.from) || PARAMS_DEFAULT.from;
  const to = searchParams.get(PARAMS.to) || PARAMS_DEFAULT.to;
  const clear = searchParams.get(PARAMS.clear);
  const [expandKey, setExpandKey] = useState<any>([]);
  const [filterCurrent, setFilterCurrent] = useState({ from: "", to: "" });
  const [summaryTotal, setSummaryTotal] = useState({});
  const [projects, setProjects] = useState<any>([]);
  const [numPages, setNumPages] = useState<number>(1);
  const [projectPage, setProjectPage] = useState<number>(1);
  const [visibleApprove, setVisibleApprove] = useState<boolean>(false);
  const [isScroll, setIsScroll] = useState<boolean>(false);
  const [status, setStatus] = useState<any>({});
  const [refreshStatus, setRefreshStatus] = useState(false);

  const projectSize = Number(COMMON.DEFAULT_SIZE);
  const openFilter = searchParams.get(PARAMS.openFilter);
  const funcSetExpendKey = (key: any) => {
    const checkKey = funcCheckKeyExpand(key);
    if (checkKey) {
      const newExpandKey = expandKey.filter(
        (keyExpand: any) => keyExpand !== key
      );
      setExpandKey(newExpandKey);
    } else {
      setExpandKey([...expandKey, key]);
    }
  };

  const funcCheckKeyExpand = (key: any) => {
    if (!key || !expandKey) return false;
    return expandKey.some((element: any) => element === key);
  };

  const initialFilter = () => {
    formFilter.setFieldValue(
      "filterTime4",
      clear ? null : [moment(from), moment(to)]
    );
  };

  const handleFilterTime = (filter: any) => {
    setProjectPage(COMMON.DEFAULT_PAGE);
    const value = filter.filterTime4;
    searchParams.delete(PARAMS.from);
    searchParams.delete(PARAMS.to);
    searchParams.set(PARAMS.clear, "1");
    if (value) {
      setFilterCurrent({ from: "", to: "" });
      searchParams.delete(PARAMS.clear);
      const fromValue = helpers.getFilterTime(value[0]);
      const toValue = helpers.getFilterTime(value[1]);
      if (fromValue !== PARAMS_DEFAULT.from) {
        searchParams.set(PARAMS.from, fromValue);
      }
      if (toValue !== PARAMS_DEFAULT.to) {
        searchParams.set(PARAMS.to, toValue);
      }
    }
    setSearchParams(searchParams);
  };

  const getReSummaryProjectLaborCost = async (params: {
    from: string;
    to: string;
    projectId: number;
  }) => {
    try {
      dispatch(setLoading(true));
      return await service.getReSummaryProjectLaborCost(params);
    } catch (error: any) {
      ErrorNotification(error.message ?? NOTIFICATION_TITLE.ERROR);
      return [];
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getProjectAlls = async (params: any) => {
    if (numPages < projectPage) return;
    try {
      dispatch(setLoading(true));
      let projectData: any[] = [];
      const responseData = await service.getProjectAlls({
        page: projectPage,
        size: projectSize,
        from: params.from,
        to: params.to,
        sortBy: COMMON.DEFAULT_SORT_BY,
        sortType: COMMON.DEFAULT_SORT_TYPE,
      });
      projectData = responseData.results;

      for (let i = 0; i < projectData.length; i++) {
        const costDetail = await getReSummaryProjectLaborCost({
          ...params,
          projectId: projectData[i].id,
        });

        const resultCostDetail = costDetail?.results.map((value: any) => {
          return {
            ...value,
            actualCost: helpers.formatCurrency(value?.actualCost),
            tax: helpers.formatCurrency(value?.tax),
            paymentAmountExcluded: helpers.formatCurrency(
              value?.actualCost + value?.tax
            ),
          };
        });

        let paymentAmountTaxIncluded: number = 0;
        let consumptionTax: number = 0;
        let paymentAmountExcludingTax: number = 0;

        costDetail?.results?.forEach((element: any) => {
          paymentAmountTaxIncluded += element?.actualCost ?? 0;
          consumptionTax += element?.tax ?? 0;
          paymentAmountExcludingTax +=
            (element?.actualCost ?? 0) + (element?.tax ?? 0);
        });

        projectData[i].costDetailData = resultCostDetail;
        projectData[i].paymentAmountTaxIncluded = helpers.formatCurrency(
          paymentAmountTaxIncluded,
          true
        );
        projectData[i].consumptionTax = helpers.formatCurrency(
          consumptionTax,
          true
        );
        projectData[i].paymentAmountExcludingTax = helpers.formatCurrency(
          paymentAmountExcludingTax,
          true
        );
      }
      projectPage === COMMON.DEFAULT_PAGE
        ? setProjects(projectData)
        : setProjects([...projects, ...projectData]);
      if (numPages === COMMON.DEFAULT_PAGE && projectData[0]?.id)
        setExpandKey([projectData[0].id]);
      if (
        responseData?.pagination?.numPages &&
        responseData.pagination.numPages !== 0
      )
        setNumPages(responseData?.pagination?.numPages);
      setProjectPage(responseData.pagination.page + 1);
      if (filterCurrent.from === params.from && filterCurrent.to === params.to)
        return;
      setFilterCurrent({ from: params.from, to: params.to });
    } catch (error: any) {
      ErrorNotification(error.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleScroll = async (e: any, from: string, to: string) => {
    var target = e.target;
    const bottom =
      target.scrollTop + target.offsetHeight + 1 >= target.scrollHeight;
    if (e.target.scrollTop === 0) return;
    setIsScroll(true);
    if (bottom) {
      await getProjectAlls({ from, to });
    }
  };

  const getReSummaryProjectLaborCostTotal = async (params: {
    from: string;
    to: string;
  }): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const responseData = await service.getReSummaryProjectLaborCostTotal({
        ...params,
      });
      setSummaryTotal({
        actualCost: `${helpers.formatCurrency(
          responseData.results.actualCost,
          true
        )}円`,
        tax: `${helpers.formatCurrency(responseData.results.tax, true)}円`,
        taxIncluded: `${helpers.formatCurrency(
          responseData.results.tax + responseData.results.actualCost,
          true
        )}円`,
      });
    } catch (error: any) {
      ErrorNotification(error.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleConfirmData = () => {
    setVisibleApprove(true);
  };

  const onCancelConfirmModal = () => {
    setVisibleApprove(false);
  };

  const onSubmit = async () => {
    ConfirmModalAsync({
      onOk: async () => {
        try {
          dispatch(setLoading(true));
          const fileName = `社内収支_労務費_${moment().format(
            COMMON.FORMAT_DATE_SUBMIT
          )}`;
          const response = await service.postMoneySummaryLaborCostProjectSubmit(
            {
              fileName,
              startDate: helpers.getFilterTimeMonth(from),
              endDate: helpers.getFilterTimeMonthTo(to),
            }
          );
          if (response?.message) {
            SuccessNotification(
              response?.message ?? NOTIFICATION_TITLE.SUCCESS
            );
            setVisibleApprove(false);
            setRefreshStatus((curState) => !curState);
            setFilterCurrent({ from: "", to: "" });
          }
        } catch (error: any) {
          ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        } finally {
          dispatch(setLoading(false));
        }
      },
      onCancel: () => {},
      className: "confirm__modal confirm__modal-purple-oke",
      title: MESSAGE.MESSAGE_TITLE_BEFORE_CREATE,
      description: "労務費" + MESSAGE.MESSAGE_SUBMIT_CM0019,

      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_MODAL_2,
    });
  };

  const handleOpenCalendar = (open: boolean) => {
    if (open) searchParams.set("openFilter4", "1");
    else {
      if (
        helpers.getFilterTimeMonth(from) === filterCurrent.from ||
        helpers.getFilterTimeMonthTo(to) === filterCurrent.to
      ) {
        return;
      } else setProjects([]);
      searchParams.delete("openFilter4");
    }
    setSearchParams(searchParams);
  };

  const exportExcel = async () => {
    dispatch(setLoading(true));

    try {
      const response = await service.doExportCM0028({
        from: from ? helpers.getFilterTimeMonth(from) : "",
        to: to ? helpers.getFilterTimeMonthTo(to) : "",
        page: 1,
        size: projectSize,
        sortBy: COMMON.DEFAULT_SORT_BY,
        sortType: COMMON.DEFAULT_SORT_TYPE,
      } as any);
      doExportForResponse(response, FILE_NAME + ".xlsx");
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getLaborCostDocumentStatus = async (params: {
    from: string;
    to: string;
  }) => {
    try {
      if (filterCurrent.from === params.from && filterCurrent.to === params.to)
        return;
      setFilterCurrent({ from: params.from, to: params.to });
      const response = await service.getLaborCostDocumentStatus(params);
      if (response?.results) setStatus(response.results);
      else setStatus({});
    } catch (error) {
      setStatus({});
    }
  };

  return {
    from,
    to,
    clear,
    summaryTotal,
    projects,
    formFilter,
    expandKey,
    visibleApprove,
    openFilter,
    filterCurrent,
    isScroll,
    status,
    refreshStatus,
    funcSetExpendKey,
    funcCheckKeyExpand,
    handleFilterTime,
    handleConfirmData,
    handleScroll,
    getReSummaryProjectLaborCost,
    getProjectAlls,
    getReSummaryProjectLaborCostTotal,
    initialFilter,
    onCancelConfirmModal,
    onSubmit,
    handleOpenCalendar,
    exportExcel,
    getLaborCostDocumentStatus,
  };
};

export default CM0028Handler;
