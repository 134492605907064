import React from 'react';
import DatePickerUI from './presenter/ui/UI';
import DatePickerHandler from './handler/Handler';

type props = {
    record: any;
    name: string;
    onAttendanceLaborCostChange?: (record: any, property: string, value: any) => Promise<void> | void;

};
const DatePickerCMComponent = ({ record, name, onAttendanceLaborCostChange }: props) => {
    const handler = DatePickerHandler();
    return <DatePickerUI handler={handler} record={record} name={name} onAttendanceLaborCostChange={onAttendanceLaborCostChange} />;
};

export default DatePickerCMComponent;
