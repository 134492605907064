// Redux
import { useDispatch } from "react-redux";

// Services
import { ServiceImpl } from "./../../usecase/ServiceImpl";
import { useState } from "react";
import { resetCM0023, setVisibleCM0023 } from "../slice/Slice";

import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import COMMON from "../../../../../common/constants/COMMON";
import {
  CompanyState,
  ReSummaryCompanyProjectOutsourceCostRetrievePayload,
  ReSummarySubmittedMaterialCostProjectSummaryResults,
} from "../../entity/Entity";
import ConfirmModal from "../../../../../common/components/modal/ConfirmModal";
import { ItemApprove } from "../../../../../common/components/approval-flow/ApprovalFlow";
import { showModalApproveConfirm } from "../../../../../common/components/modal-approve-confirm/presenter/slice/Slice";

const CM0023Handler = (service: ServiceImpl) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [expandCompanyKey, setExpandCompanyKey] = useState<any[]>([]);
  const [expandProjectKey, setExpandProjectKey] = useState<any[]>([]);

  const [summaryData, setSummaryData] = useState<any>({
    taxAmount: 0,
    paymentAmountExcludeTax: 0,
  });
  const [materialCostProjectList, setMaterialCostProjectList] = useState<any[]>(
    []
  );
  const [numPages, setNumPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const projectSize = Number(COMMON.DEFAULT_SIZE);
  const [workflow, setWorkflow] = useState<ItemApprove[]>([]);
  const [currentUserApproval, setCurrentUserApproval] =
    useState<ItemApprove | null>(null);

  const getWorkflowSummaryMaterialProjectApprovalProcess = async (
    documentId: number
  ) => {
    try {
      setLoading(true);
      const data =
        await service.getWorkflowSummaryMaterialProjectApprovalProcess({
          documentId,
        });
      if (data.results.currentUserApproval)
        setCurrentUserApproval({
          approveDate: data.results.currentUserApproval.approveDate,
          contact_address: "",
          email: data.results.currentUserApproval.email,
          id: data.results.currentUserApproval.id,
          isApprove: data.results.currentUserApproval.approve,
          reject: data.results.currentUserApproval.reject,
          position: "",
          priority: data.results.currentUserApproval.priority,
          username: data.results.currentUserApproval.username,
          fullName: data.results.currentUserApproval.fullName,
          note: data.results.currentUserApproval.note,
        });
      const workflowData: ItemApprove[] = data.results.listApprover.map(
        (element) => {
          let note = element.note;
          if (element.id === data.results?.currentUserApproval?.id)
            note = data.results.currentUserApproval.note;
          const result: ItemApprove = {
            approveDate: element.approveDate,
            contact_address: "",
            email: element.email,
            id: element.id,
            isApprove: element.approve,
            reject: element.reject,
            position: "",
            priority: element.priority,
            username: element.username,
            fullName: element.fullName,
            note: note,
          };
          return result;
        }
      );
      setWorkflow(workflowData);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      setLoading(false);
    }
  };

  const getReSummarySubmittedMaterialCostProjectSummary = async (params: {
    documentId: number;
  }): Promise<void> => {
    try {
      setLoading(true);
      const summaryData =
        await service.getReSummarySubmittedMaterialCostProjectSummary({
          documentId: params.documentId,
        });
      setSummaryData(summaryData);
    } catch (error: any) {
    } finally {
      setLoading(false);
    }
  };

  const getConstructionAndConstructionCompanyData = async (params: {
    documentId: number;
    projectId: number;
  }) => {
    const constructionList =
      await service.getReSummarySubmittedMaterialCostProjectConstruction(
        params
      );
    const constructionCompany = await Promise.all(
      constructionList.map((element) => {
        return service.getReSummarySubmittedMaterialCostProjectConstructionCompany(
          {
            documentId: params.documentId,
            constructionId: element.id,
          }
        );
      })
    );
    const result = constructionList.map((element, index) => {
      let paymentAmountExcludeTax = 0;
      let taxAmountConstruction = 0;
      const constructionCompanyData = constructionCompany[index].map(
        (company) => {
          const taxAmount =
            company?.actualCost * company?.consumptionTax;

          paymentAmountExcludeTax += company?.actualCost;
          taxAmountConstruction += taxAmount;
          return { ...company, taxAmount };
        }
      );
      return {
        ...element,
        paymentAmountExcludeTax,
        taxAmount: taxAmountConstruction,
        constructionCompanyList: constructionCompanyData,
      };
    });
    return result;
  };

  // call companies
  const getProjectAlls = async (documentId: number) => {
    if (numPages < currentPage) return;
    try {
      setLoading(true);
      const responseData = await service.getProjectAlls({
        documentId: documentId,
        page: currentPage,
        size: projectSize,
        sortBy: "createdAt",
        sortType: "DESC"
      });

      const listProject = responseData?.results ?? [];

      const constructionPromiseAllData = await Promise.all(
        listProject.map((element: any) => {
          return getConstructionAndConstructionCompanyData({
            documentId: documentId,
            projectId: element.id,
          });
        })
      );

      let projectData: any[] = listProject.map((element: any, index: any) => {
        let paymentAmountExcludeTax = 0;
        let taxAmount = 0;
        constructionPromiseAllData[index].forEach((element: any) => {
          paymentAmountExcludeTax += element?.paymentAmountExcludeTax;
          taxAmount += element?.taxAmount;
        });
        return {
          ...element,
          paymentAmountExcludeTax,
          taxAmount,
          constructionList: constructionPromiseAllData[index],
        };
      });
      if (currentPage === 1 && projectData.length > 0) {
        setCompanyExpandKeys(projectData[0].id);
        if (projectData[0]?.constructionList.length > 0) {
          setProjectExpandKeys(projectData[0]?.constructionList[0].id);
        }
      }
      calculateSummaryData([...materialCostProjectList, ...projectData]);
      setMaterialCostProjectList([...materialCostProjectList, ...projectData]);
      setNumPages(responseData.pagination.numPages);
      setCurrentPage(responseData.pagination.page + 1);
    } catch (error: any) {
      ErrorNotification(error.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      setLoading(false);
    }
  };

  const calculateSummaryData = (data: any[]) => {
    let paymentAmountExcludeTax = 0;
    let taxAmount = 0;
    data.forEach((element) => {
      paymentAmountExcludeTax += element?.paymentAmountExcludeTax;
      taxAmount += element?.taxAmount;
    });
    setSummaryData({
      paymentAmountExcludeTax,
      taxAmount,
    });
  };

  const handleScroll = async (e: any, documentId: number) => {
    const bottom =
      Math.abs(
        Math.floor(e.target.scrollHeight - e.target.scrollTop) -
        Math.floor(e.target.clientHeight)
      ) < 2;
    if (e.target.scrollTop === 0) return;

    if (bottom) {
      await getProjectAlls(documentId);
    }
  };

  const cancelModalCM0023 = () => {
    dispatch(setVisibleCM0023(false));
  };

  const setProjectExpandKeys = (key: any) => {
    const checkKey = checkProjectKeyExpand(key);
    if (checkKey) {
      const newExpandKey = expandProjectKey.filter(
        (keyExpand: any) => keyExpand !== key
      );
      setExpandProjectKey(newExpandKey);
    } else {
      setExpandProjectKey([...expandProjectKey, key]);
    }
  };

  const checkProjectKeyExpand = (key: any) => {
    if (!key || !expandProjectKey) return false;
    return expandProjectKey.some((element: any) => element === key);
  };

  const setCompanyExpandKeys = (key: any) => {
    const checkKey = checkCompanyKeyExpand(key);
    if (checkKey) {
      const newExpandKey = expandCompanyKey.filter(
        (keyExpand: any) => keyExpand !== key
      );
      setExpandCompanyKey(newExpandKey);
    } else {
      setExpandCompanyKey([...expandCompanyKey, key]);
    }
  };

  const checkCompanyKeyExpand = (key: any) => {
    if (!key || !expandCompanyKey) return false;
    return expandCompanyKey.some((element: any) => element === key);
  };
  const putReSummaryMaterialCostProjectRetrieve = async (
    data: ReSummaryCompanyProjectOutsourceCostRetrievePayload,
    afterSuccess?: () => void
  ): Promise<void> => {
    ConfirmModal({
      onOk: async () => {
        try {
          setLoading(true);
          const responseData =
            await service.putReSummaryMaterialCostProjectRetrieve(data);
          if (responseData) {
            SuccessNotification(
              responseData?.message ?? NOTIFICATION_TITLE.SUCCESS
            );
            dispatch(resetCM0023());
            if (afterSuccess) afterSuccess();
          }
        } catch (error: any) {
          ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        } finally {
          setLoading(false);
        }
      },
      className: "confirm__modal",
      title: MESSAGE.MESSAGE_TITLE_CM016,
      description: MESSAGE.MESSAGE_DESCRIPTION_CR_1,
      extraDescription: MESSAGE.MESSAGE_DESCRIPTION_COMMON_QUESTION_1,
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_MODAL,
      isCenterWithoutMenu: true,
    });
  };
  const showConfirmApproveModal = () => {
    dispatch(showModalApproveConfirm());
  };
  return {
    expandProjectKey,
    loading,
    expandCompanyKey,
    summaryData,
    materialCostProjectList,
    workflow,
    currentUserApproval,
    cancelModalCM0023,
    checkProjectKeyExpand,
    setProjectExpandKeys,
    checkCompanyKeyExpand,
    setCompanyExpandKeys,
    putReSummaryMaterialCostProjectRetrieve,
    getReSummarySubmittedMaterialCostProjectSummary,
    getProjectAlls,
    handleScroll,
    getWorkflowSummaryMaterialProjectApprovalProcess,
    showConfirmApproveModal,
  };
};

export default CM0023Handler;
