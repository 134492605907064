import { createSlice } from "@reduxjs/toolkit";
import { CM0020SliceType } from "../../entity/Entity";

export const initialState: CM0020SliceType = {
  isRefresh: false,
  isHavingData: false,
};

export const AM0020Slice = createSlice({
  name: "am0020",
  initialState,
  reducers: {
    refreshAM0020: (state) => {
      state.isRefresh = !state.isRefresh;
    },
    setIsHavingData: (state, action) => {
      state.isHavingData = action.payload;
    },
  },
});

export const { refreshAM0020, setIsHavingData } = AM0020Slice.actions;

export default AM0020Slice.reducer;
