import { Button, Form, Input, Modal, Spin, Tooltip } from 'antd';
import { OM007Hanlder } from '../handler/HandlerImpl';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import LoadingComponent from '../../../../../common/components/icons/LoadingComponent';
import MESSAGE from '../../../../../common/constants/MESSAGE';
import OMHeaderModalComponent from '../../../../../common/components/om-header-modal/OMHeaderModalComponent';
import REGEX from '../../../../../common/constants/REGEX';
import { onBlurTrimFormItem } from '../../../../../common/helpers/typeInput';
import helpers from '../../../../../common/helpers/common';
import IconI from "../../../../../assets/icons/icon-i-om.svg"

import '../../../../../assets/styles/OM/OM002.css';
import '../../../../../assets/styles/OM/OM001.css';
import '../../../../../assets/styles/OM/OM007.css';
import '../../../../../assets/styles/OM/OM004.css';
import InfiniteScrollSelectComponent from '../../../../../common/components/infinity-scroll-select';
interface props {
    handler: OM007Hanlder;
}

const { TextArea } = Input;

const OM007UI = ({ handler }: props) => {
    const isVisible = useSelector((state: RootState) => state.om007.isVisible);
    const loading = useSelector((state: RootState) => state.om007.loading);

    const { explanationRef } = handler;

    return !isVisible ? null : (
        <Modal
            style={{ marginLeft: 300, marginTop: 40 }}
            className="group-detail-modal no-margin-modal"
            closable={false}
            visible={true}
            maskClosable={false}
            width={996}
            centered={true}
            footer={
                <>
                    <Button
                        size="large"
                        type="primary"
                        style={{ marginLeft: 16, width: 132 }}
                        htmlType="submit"
                        form="formCreateGroup"
                    >
                        部門追加
                    </Button>
                    <Button size="large" className="om-cancel-button" onClick={handler.onCancel}>
                        キャンセル
                    </Button>
                </>
            }
        >
            <Spin indicator={LoadingComponent} spinning={loading}>
                <OMHeaderModalComponent title="部門新規追加" onCancel={handler.onCancel} />
                <div className=" w-100-percent om007-form-container">
                    <Form
                        form={handler.form}
                        id="formCreateGroup"
                        layout="horizontal"
                        onFinish={handler.createNewGroup}
                    >
                        <Form.Item
                            name="name"
                            labelAlign="right"
                            colon={false}
                            className="form-item-label form-label-height-40"
                            label={
                                <div
                                    className="om007-form-label pr-20 center-item"
                                    style={{ marginLeft: 74 }}
                                >
                                    部門名<span style={{ color: 'rgba(255, 9, 9, 1)' }}>*</span>
                                </div>
                            }
                            rules={[
                                {
                                    required: true,
                                    message: MESSAGE.MESSAGE_5,
                                },
                                {
                                    pattern: new RegExp(REGEX.EXCEPT_VIETNAMESE),
                                    message: MESSAGE.MESSAGE_59,
                                },
                            ]}
                        >
                            <Input
                                onBlur={() => onBlurTrimFormItem(handler.form, 'name')}
                                onChange={(e: any) => {
                                    helpers.preventInputJapanese(e);
                                }}
                                maxLength={50}
                                style={{ height: 40 }}
                            />
                        </Form.Item>
                        <div style={{ marginLeft: 42 }}>
                            {/* 
                            - Tên người duyệt
                            + Nếu là member: Lấy trường 名前 màn OM002
                            + Nếu là admin: Lấy trường アドミン名 màn IC1
                            */}
                            <InfiniteScrollSelectComponent
                                isFormCol={true}
                                formLabel={<div style={{ display: 'flex' }}>
                                    <section>承認者<span style={{ color: 'rgba(255, 9, 9, 1)' }}>*</span></section>
                                    <section className='button-style' style={{ marginLeft: 8 }}>
                                        <Tooltip
                                            overlayInnerStyle={{ width: 330 }}

                                            placement='topLeft'
                                            title={<div >
                                                勤怠管理申請の承認者 <br />
                                                申請内容：打刻申請、休暇申請、遅刻・早退申請
                                            </div>} >
                                            <img alt='' src={IconI} />
                                        </Tooltip>
                                    </section>
                                </div>}
                                formName="approverId"
                                className="filter-table"
                                nameProp="fullName"
                                valueProp="id"
                                placeholderProp=""
                                sizeProp="large"
                                fetchData={handler.getProjectUsers}
                                isCal50={true}
                                rules={[
                                    {
                                        required: true,
                                        message: MESSAGE.MESSAGE_REQUIRED_FIELD,
                                    },
                                ]}
                            />
                        </div>
                        <Form.Item
                            name="explanation"
                            labelAlign="right"
                            label={
                                <div className="om007-form-label pr-20" style={{ marginLeft: 94 }}>
                                    説明
                                </div>
                            }
                            colon={false}
                            rules={[
                                {
                                    pattern: new RegExp(REGEX.EXCEPT_VIETNAMESE),
                                    message: MESSAGE.MESSAGE_INVALID_INPUT,
                                },
                            ]}
                        >
                            <TextArea
                                rows={3}
                                onBlur={() => onBlurTrimFormItem(handler.form, 'explanation')}
                                onChange={() =>
                                    helpers.checkMaxLengthTextArea(
                                        handler.form,
                                        explanationRef,
                                        'explanation',
                                        300,
                                    )
                                }
                                ref={explanationRef}
                                maxLength={300}
                                style={{ width: '100%', minHeight: 84 }}
                            />
                        </Form.Item>
                    </Form>
                </div>
            </Spin >
        </Modal >
    );
};

export default OM007UI;
