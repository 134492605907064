import { AM027Api } from "./ServiceImpl";
  
const AM027Service = (am027: AM027Api) => {
  const deleteRequest = async (data:any): Promise<any> => {
    try{
      return await am027.deleteRequest(data)
    }
    catch(error){
      throw(error)
    }
  };

  return {
    deleteRequest,
  };
};

export default AM027Service;


  