import React, { useEffect, useLayoutEffect, } from "react";
import { Button, Form } from "antd";
import { HandlerImpl } from "../handler/HandlerImpl";
import MenuWrapperComponent from "../../../../../common/components/menu-wrapper";
import BreadcrumbComponent from "../../../../../common/components/breadcrumb/BreadcrumbComponent";

import HomeSvg from "../../../../../assets/icons/home.svg";
import EditWhiteSvg from "../../../../../assets/icons/edit-white.svg";
import BodyLeft from "./BodyLeft";
import BodyRight from "./BodyRight";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";

interface props {
  handler: HandlerImpl;
}

const BREADCRUMB_SW02: any[] = [
  {
    title: "設定",
    url: "",
    icon: (
      <img style={{ paddingBottom: 2, paddingRight: 8 }} alt="" src={HomeSvg} />
    ),
  },
  {
    title: "ワークフローマスタ",
    url: "",
    icon: "",
  },
];

const BREADCRUMB_SW01_02: any[] = [
  {
    title: "設定",
    url: "",
    icon: (
      <img style={{ paddingBottom: 2, paddingRight: 8 }} alt="" src={HomeSvg} />
    ),
  },
  {
    title: "ワークフロー",
    url: "/app-sw01",
    icon: "",
  },
  {
    title: "ワークフローマスタ",
    url: "",
    icon: "",
  },
];

export const description = (
  <div style={{ textAlign: "center" }}>
    <div>行った変更は保存されません。</div>
    <div>このページを閉じてもよろしいでしょうか？</div>
  </div>
);

const PATH = "/app-sw01/sw02";

const MainUI = ({ handler }: props) => {
  const location = useLocation();

  useEffect(() => {
    handler.getWorkflowList();
  }, [handler.refreshLeft, handler.refresh]);

  const isRoleAdminBasic = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdminBasic
  );
  return (
    <MenuWrapperComponent
      chosenKey={location.pathname === PATH ? 73 : 76}
      openKey={7}
    >
      <div className="sw02-wrapper">
        <Form
          name="sw02"
          form={handler.form}
          onValuesChange={handler.handleChangeForm}
          onFinish={handler.handleSubmit}
        >
          <BreadcrumbComponent
            data={
              location.pathname === PATH ? BREADCRUMB_SW01_02 : BREADCRUMB_SW02
            }
          />
          <div className="sw02-header">
            <h2 className="sw02-header-title">ワークフローマスタ</h2>
            {!handler.isEdit &&
              (!handler.dataLeft?.length || isRoleAdminBasic) ? (
              <Button
                className="sw02-header-btn"
                size="large"
                icon={<img alt="" src={EditWhiteSvg} />}
                onClick={() => handler.setIsEdit(true)}
                disabled={handler.isEditBtn}
              >
                編集
              </Button>
            ) : null}
          </div>
          <div className="sw02-body">
            <BodyLeft handler={handler} />
            <BodyRight handler={handler} />
          </div>
          {handler.isEdit ? (
            <div className="sw02-footer">
              <Button
                size="large"
                className="button-purple sw02-btn-submit"
                htmlType="submit"
              >
                保存
              </Button>
              <Button
                size="large"
                className="sw02-btn-cancel"
                onClick={() => handler.handleCancelEdit(description)}
                htmlType="button"
              >
                キャンセル
              </Button>
            </div>
          ) : null}
        </Form>
      </div>
    </MenuWrapperComponent>
  );
};

export default MainUI;
