import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Handler } from "../handler/HandlerImpl";
import helpers from "../../../../../common/helpers/common";
import COMMON from "../../../../../common/constants/COMMON";

interface props {
  handler: Handler;
}

const TableRevenueExpenditure = ({ handler }: props) => {
  const contentTable = useMemo(() => {
    const elementTable: any = [];
    handler.dataTableExpenditure.forEach((element, index) => {
      elementTable.push(
        <React.Fragment key={element.key}>
          <tr className="bg-f0">
            <td>
              {helpers.formatDatePresenter(element.time, COMMON.FORMAT_DATE_DB)}{" "}
            </td>
            <td>{element.item}</td>
            <td>{helpers.formatNumberMoney(element.budget)}円</td>
            <td>{helpers.formatNumberMoney(element.operatingCost)}円</td>
            <td className={element.amountGAP < 0 ? "text-red" : "text-blue"}>
              {helpers.formatNumberMoney(element.amountGAP)}円
            </td>
            <td className={element.rateGAP < 0 ? "text-red" : "text-blue"}>
              {isFinite(element.rateGAP)
                ? helpers.formatNumberMoney(element.rateGAP)
                : 0}
              %
            </td>
            <td>{element.manPower}人工</td>
            <td>{helpers.formatNumberMoney(element.billingAmount)}円</td>
            <td className={element.grossProfit < 0 ? "text-red" : "text-blue"}>
              {helpers.formatNumberMoney(element.grossProfit)}円
            </td>
            <td
              className={
                element.grossProfitMargin < 0 ? "text-red" : "text-blue"
              }
            >
              {isFinite(element.grossProfitMargin)
                ? helpers.formatNumberMoney(element.grossProfitMargin)
                : 0}
              %
            </td>
          </tr>
        </React.Fragment>
      );

      if (element.children) {
        element.children.forEach((child2: any, indexChild: number) => {
          elementTable.push(
            <tr
              key={`table${child2.key}`}
              className={indexChild % 2 === 0 ? "bg-fa" : "bg-ff"}
            >
              <td></td>
              <td>{child2.item}</td>
              <td>{helpers.formatNumberMoney(child2.budget)}円</td>
              <td>{helpers.formatNumberMoney(child2.operatingCost)}円</td>
              <td className={child2.amountGAP < 0 ? "text-red" : "text-blue"}>
                {helpers.formatNumberMoney(child2.amountGAP)}円
              </td>
              <td className={child2.rateGAP < 0 ? "text-red" : "text-blue"}>
                {isFinite(child2.rateGAP)
                  ? helpers.formatNumberMoney(child2.rateGAP)
                  : 0}
                %
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          );
        });
      }
    });
    return elementTable;
  }, [handler.dataTableExpenditure]);

  return (
    <div className="db002-table">
      <table>
        <thead>
          <tr className="row-title">
            <th>月別</th>
            <th>項目</th>
            <th>支払予定金額</th>
            <th>稼働原価</th>
            <th>GAP金額</th>
            <th>GAP(％)</th>
            <th>稼働人工数</th>
            <th>請求金額</th>
            <th>粗利</th>
            <th>粗利率(％)</th>
          </tr>
        </thead>
        <tbody>{contentTable}</tbody>
        <tfoot>
          <tr className="table-foot">
            <td colSpan={2} style={{ textAlign: "left", paddingLeft: 65 }}>
              合計
            </td>
            <td>
              {helpers.formatNumberMoney(
                handler.totalTableExpenditure.totalBudgetExpenditure
              )}
              円
            </td>
            <td>
              {helpers.formatNumberMoney(
                handler.totalTableExpenditure.totalOperatingCostExpenditure
              )}
              円
            </td>
            <td></td>
            <td></td>
            <td>
              {helpers.formatNumberMoney(
                handler.totalTableExpenditure.totalManPowerExpenditure
              )}
              人工
            </td>
            <td>
              {helpers.formatNumberMoney(
                handler.totalTableExpenditure.totalBillingAmountExpenditure
              )}
              円
            </td>
            <td>
              {helpers.formatNumberMoney(
                handler.totalTableExpenditure.totalGrossProfitExpenditure
              )}
              円
            </td>
            <td>
              {isFinite(
                handler.totalTableExpenditure.totalGrossProfitMarginExpenditure
              )
                ? helpers.formatNumberMoney(
                    handler.totalTableExpenditure
                      .totalGrossProfitMarginExpenditure
                  )
                : 0}
              %
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default TableRevenueExpenditure;
