import { createSlice } from '@reduxjs/toolkit';
import { CM0021SliceType } from '../../entity/Entity';

export const initialState: CM0021SliceType = {
    isDisableRetrieveButton: true,
    isRefresh: false,
    isVisibleCM0021: false,
    documentId: null,
    documentInfo: {
        documentTitle: '',
        createdDate: '',
        targetPeriod: '',
        status: '',
    },
};

export const CM0021Slice = createSlice({
    name: 'CM0021',
    initialState,
    reducers: {
        resetCM0021: () => initialState,
        setVisibleCM0021: (state, action) => {
            state.isVisibleCM0021 = action.payload;
        },
        setDocumentIdCM0021: (state, action) => {
            state.documentId = action.payload;
        },
        setDocumentInfoCM0021: (state, action) => {
            state.documentInfo = action.payload;
        },
        setIsDisableRetrieveButtonCM0021: (state, action) => {
            state.isDisableRetrieveButton = action.payload;
        },
    },
});

export const {
    resetCM0021,
    setVisibleCM0021,
    setDocumentIdCM0021,
    setDocumentInfoCM0021,
    setIsDisableRetrieveButtonCM0021,
} = CM0021Slice.actions;

export default CM0021Slice.reducer;
