import { useSearchParams } from "react-router-dom";
import BreadcrumbComponent from "../../../../../common/components/breadcrumb/BreadcrumbComponent";
import { Layout } from "antd";
import MenuWrapperComponent from "../../../../../common/components/menu-wrapper";
import FilterComponent from "../component/filter";
import PaginationComponent from "./Pagination";
import moment from "moment";
import COMMON from "../../../../../common/constants/COMMON";
import { CCUS02Handler } from "../handler/HandlerImpl";
import TableCCUS from "./Table";
import { useEffect } from "react";
import { getTitleByFromToDate, MENU_ITEM, PARAMS } from "../../constants";
import helpers from "../../../../../common/helpers/common";
import HomeSvg from "../../../../../assets/icons/home.svg";

interface props {
  handler: CCUS02Handler;
}
const { Content, Sider } = Layout;

const BREADCRUMB_CCUS02: any[] = [
  {
    title: "建設キャリアアップシステム",
    url: "",
    icon: (
      <img style={{ paddingBottom: 2, paddingRight: 8 }} alt="" src={HomeSvg} />
    ),
  },
  {
    title: "就業履歴情報登録結果",
    url: "",
    icon: "",
  },
];

const MainUI = ({ handler }: props) => {
  const [searchParams] = useSearchParams();
  const isCollapsed = searchParams.get("isCollapsed") ? true : false;

  const fromDate =
    searchParams.get("startDate") ??
    moment().subtract(1, "days").format(COMMON.FORMAT_DATE2);
  const toDate =
    searchParams.get("endDate") ??
    moment().subtract(1, "days").format(COMMON.FORMAT_DATE2);
  const page = searchParams.get(PARAMS.page) || COMMON.DEFAULT_PAGE + "";
  const size = searchParams.get(PARAMS.size) || COMMON.DEFAULT_SIZE + "";

  const projectId = searchParams.get(PARAMS.projectId) || "";
  const companyId = searchParams.get(PARAMS.companyId) || "";
  const approverId = searchParams.get(PARAMS.approverId) || "";
  const sortType = searchParams.get(PARAMS.sortType);
  const sortBy = searchParams.get(PARAMS.sortBy);
  const syncTime = searchParams.get(PARAMS.syncTime) || "";
  const registerType = searchParams.get(PARAMS.registerType) || "";

  useEffect(() => {
    const params: any = {
      page: +page,
      size: +size,
      state: handler.state,
    };
    if (projectId) params.projectId = +projectId;
    if (companyId) params.companyId = +companyId;
    if (approverId) params.approverId = +approverId;
    if (syncTime) params.syncTime = helpers.getFilterTime(moment(syncTime));
    if (registerType) params.registerType = registerType;
    if (sortType) params.sortType = sortType;
    if (sortBy) params.sortBy = sortBy;
    if (fromDate) params.from = helpers.getFilterTime(moment(fromDate));
    if (toDate) params.to = helpers.getFilterTimeTo(moment(toDate));
    handler.getTimesheetSyncData(params);
  }, [
    handler.state,
    page,
    size,
    projectId,
    companyId,
    approverId,
    sortBy,
    sortType,
    registerType,
    syncTime,
    fromDate,
    toDate,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      const params: any = {
        state: handler.state,
      };
      if (projectId) params.projectId = +projectId;
      if (companyId) params.companyId = +companyId;
      if (approverId) params.approverId = +approverId;
      if (syncTime) params.syncTime = helpers.getFilterTime(moment(syncTime));
      if (registerType) params.registerType = registerType;
      if (fromDate) params.from = helpers.getFilterTime(moment(fromDate));
      if (toDate) params.to = helpers.getFilterTimeTo(moment(toDate));
      handler.getCCUSTimesheet(params);
    };
    fetchData();
  }, [
    handler.state,
    projectId,
    companyId,
    approverId,
    registerType,
    syncTime,
    fromDate,
    toDate,
  ]);

  return (
    <>
      <MenuWrapperComponent chosenKey={93} openKey={9}>
        <div>
          <div className="om002-header-container">
            <BreadcrumbComponent data={BREADCRUMB_CCUS02} />

            <div className="header-row-title-ccus02">就業履歴情報登録結果</div>
          </div>

          <div
            className="container-tab-ccus02"
            style={{ height: "85vh", display: "flex" }}
          >
            <Layout className={isCollapsed ? "close-side" : "open-side"}>
              <Sider>
                <FilterComponent />
              </Sider>
              <Layout className="bg-fff">
                <Content className="content-table-ccus">
                  <div className="row-infor-tab-ccus02">
                    <div className="ccus02-nav">
                      <ul className="am028-menu">
                        {MENU_ITEM.map(
                          (menu: { name: string; code: string }) => {
                            return (
                              <li
                                key={menu.code}
                                className={`am028-menu-item ${
                                  menu.code == handler.state
                                    ? "am028-menu-item-active"
                                    : ""
                                }`}
                                onClick={() =>
                                  handler.handleChangeMenuChosen(menu.code)
                                }
                              >
                                {menu.name}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                    <div className="date-label-ccus02">
                      {moment(
                        handler.timeSheet.attendanceDateLeast,
                        true
                      ).isValid() ||
                      moment(
                        handler.timeSheet.attendanceDateGreatest,
                        true
                      ).isValid() ? (
                        <>
                          {moment(
                            handler.timeSheet.attendanceDateLeast,
                            true
                          ).isValid() && (
                            <span>
                              {moment(
                                handler.timeSheet.attendanceDateLeast
                              ).format(COMMON.FORMAT_DATE_OM)}
                            </span>
                          )}
                          ～
                          {moment(
                            handler.timeSheet.attendanceDateGreatest,
                            true
                          ).isValid() && (
                            <span>
                              {moment(
                                handler.timeSheet.attendanceDateGreatest
                              ).format(COMMON.FORMAT_DATE_OM)}
                            </span>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="ccus-pagination">
                    <TableCCUS handler={handler} />

                    <PaginationComponent
                      onPageChange={handler.onPageChange}
                      onSizeChange={handler.onSizeChange}
                      total={handler.totalRecord}
                    />
                  </div>
                </Content>
              </Layout>
            </Layout>
          </div>
        </div>
      </MenuWrapperComponent>
    </>
  );
};

export default MainUI;
