import ORD001Api from './infrastructure/api/Api';
import ORD001Handler from './presenter/handler/Handler';
import MainUI from './presenter/ui/UI';
import ORD001Service from './usecase/Service';

const ORD001Component = () => {
    const api = ORD001Api();
    const service = ORD001Service(api);
    const handler = ORD001Handler(service);

    return <MainUI handler={handler}></MainUI>;
};

export default ORD001Component;
