import {
  DetailProject,
  MoneyConstructionInformationResults,
} from "../entity/Entity";
import { CM004APIImpl } from "./ServiceImpl";

const CM004Service = (api: CM004APIImpl) => {
  const getMoneyConstructionInformation = async (params: {
    constructionId: number;
  }): Promise<MoneyConstructionInformationResults | null> => {
    try {
      const response = await api.getMoneyConstructionInformation({
        constructionId: params.constructionId,
        documentId: 0,
      });
      const { results } = response.data;
      return results;
    } catch (error: any) {
      return null;
    }
  };
  const getDetailProject = async (params: {
    projectId: number;
  }): Promise<DetailProject | null> => {
    try {
      const response = await api.getDetailProject(params);
      return response?.data?.results ?? null;
    } catch (error: any) {
      return null;
    }
  };
  return { getMoneyConstructionInformation, getDetailProject };
};

export default CM004Service;
