import { DB001Api } from './ServiceImpl';

const DB001Service = (api: DB001Api) => {
    const getTurnOverAmount = async (params: any): Promise<any> => {
        try {
            const response = await api.getTurnOverAmount(params);
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    const getManDay = async (params: any): Promise<any> => {
        try {
            const response = await api.getManDay(params);
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    const getEmployeesAndOperatingCosts = async (params: any): Promise<any> => {
        try {
            const response = await api.getEmployeesAndOperatingCosts(params);
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    const getPartnerCompany = async (params: any): Promise<any> => {
        try {
            const response = await api.getPartnerCompany(params);
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    const getEmployeesOperatingAndOperatingCosts = async (params: any): Promise<any> => {
        try {
            const response = await api.getEmployeesOperatingAndOperatingCosts(params);
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    const getTransactionVolume = async (params: any): Promise<any> => {
        try {
            const response = await api.getTransactionVolume(params);
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    const getBudgetUtil = async (params: any): Promise<any> => {
        try {
            const response = await api.getBudgetUtil(params);
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    return {
        getTurnOverAmount,
        getManDay,
        getTransactionVolume,
        getBudgetUtil,
        getEmployeesAndOperatingCosts,
        getEmployeesOperatingAndOperatingCosts,
        getPartnerCompany
    };
};

export default DB001Service;
