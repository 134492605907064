import axiosConfig from '../../../../../../config/axiosConfig';

const api = () => {
    const getDocumentStatus = async (): Promise<any> => {
        const url = '/re/status';
        return await axiosConfig.get(url);
    };

    return {
        getDocumentStatus,
    };
};

export default api;
