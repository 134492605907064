import { TodoEntity } from "../entity/TodoEntity";
import { TodoApi } from "./ServiceImpl";

const TodoService = (todoApi: TodoApi) => {
  const getTodoList = async (): Promise<TodoEntity[]> => {
    try {
      return await todoApi.getTodos();
    } catch (error) {
      throw error;
    }
  };
  return { getTodoList };
};

export default TodoService;
