import { AM022SliceType } from "./../type/Presenter";
import { createSlice } from "@reduxjs/toolkit";

const initialState: AM022SliceType = {
  loading: false,
  isVisible: false,
  refresh: false,
  requestId: undefined,
  typeModal:"create"
};

export const am022Slice = createSlice({
  name: "am022",
  initialState,
  reducers: {
    resetAM022: () => initialState,
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsVisibleAM022: (state, action) => {
      state.isVisible = action.payload;
    },
    setRefreshAM022: (state) => {
      state.refresh = !state.refresh;
    },
    setRequestIdAM022: (state, action) => {
      state.requestId = action.payload;
    },
    setTypeModalAM022:(state, action) =>{
      state.typeModal = action.payload;
    },
  },
});

export const { setLoading, setIsVisibleAM022, resetAM022, setRefreshAM022, setRequestIdAM022, setTypeModalAM022 } =
  am022Slice.actions;

export default am022Slice.reducer;
