import { Button, DatePicker, Form, Input, Modal, Spin } from "antd";
import { useSelector } from "react-redux";
import React, { useEffect } from "react";

import LoadingComponent from "../../../../../common/components/icons/LoadingComponent";
import helpers from "../../../../../common/helpers/common";
import { RootState } from "../../../../../store";
import { HandlerImpl } from "../handler/HandlerImpl";
import locale from "antd/es/date-picker/locale/ja_JP";
import "moment/locale/ja";
import "../../../../../assets/styles/OM/OM002.css";
import "../../../../../assets/styles/OM/OM004.css";
import "../../../../../assets/styles/OM/OM007.css";
import BoldPlusSVG from "../../../../../assets/icons/bold-plus.svg";

import XIconModal from "../../../../../assets/icons/x-icon-modal.svg";

import { FormRow } from "./FormRow";
import { ColorPickerModal } from "./ColorPickerModal";
type Props = {
  handler: HandlerImpl;
};

const SM010UI = ({ handler }: Props) => {
  const isVisible = useSelector((state: RootState) => state.sm010.isVisible);
  const loading = useSelector((state: RootState) => state.sm010.loading);

  useEffect(() => {
    if (isVisible) handler.initiatingData();
  }, [isVisible]);

  useEffect(() => {
    if (handler.isDirty) {
      window.addEventListener("beforeunload", helpers.alertUser);
      return () => {
        window.removeEventListener("beforeunload", helpers.alertUser);
      };
    } else {
      window.removeEventListener("beforeunload", helpers.alertUser, true);
    }
  }, [handler.isDirty]);

  return <Modal
    title={
      <div className="w-100-percent title-modal-container" >
        <div className="header-modal-title-om004 ">カテゴリー管理</div>
        <div style={{ cursor: "pointer" }} onClick={handler.onCancel}>
          <img alt="" src={XIconModal} style={{ height: 18, width: 18 }} />
        </div>
      </div>
    }
    className="group-detail-modal modal-header-no-padding"
    style={{ marginLeft: 300, marginTop: 40, marginBottom: 40 }}
    visible={isVisible}
    closable={false}
    maskClosable={false}
    width={515}
    centered={true}
    footer={
      <>
        <Button
          disabled={loading}
          size="large"
          type="primary"
          style={{ marginLeft: 16, width: 76, letterSpacing: -1.5 }}
          htmlType="submit"
          form="formScheduleCategory"
        >
          保存
        </Button>
        <Button
          disabled={loading}
          size="large"
          style={{ marginLeft: 16, width: 118 }}
          onClick={handler.onCancel}
        >
          キャンセル
        </Button>
      </>
    }
  >
    <Spin indicator={LoadingComponent} spinning={loading}>
      <Form
        style={{ margin: 0, padding: 0 }}
        onValuesChange={handler.checkDirty}
        form={handler.form}
        id="formScheduleCategory"
        layout="horizontal"
        onFinish={handler.putScheduleCategoryUpdate}
        autoComplete="off"
      >
        {
          [...handler.scheduleCategories, ...handler.newCategories].map((category) =>
            <FormRow
              key={category.id}
              data={category}
              deleteRow={handler.onDeleteCategory}
              form={handler.form}
            />
          )
        }
        {
          handler.availableColors.length === 0 ? <></> :
            <Button
              type="text"
              style={{ marginLeft: 38 }}
              onClick={() => handler.openColorPickerModal()}
              icon={
                <img alt='' src={BoldPlusSVG} />
              } />
        }
      </Form>
    </Spin>
    <ColorPickerModal
      onChooseColor={handler.chooseNewColor}
      colors={handler.availableColors}
      isVisible={handler.isColorModal}
      onCancel={handler.closeColorPickerModal} />
  </Modal>
};

export default SM010UI;
