import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal, Button, Spin } from 'antd';
import OM005DataTable from './Datatable';
import PaginationComponentList from '../../../../../common/components/pagination/PaginationComponentList';
import COMMON from '../../../../../common/constants/COMMON';
import { COLUMNS_RESULTS } from './Columns';
import {
    showModalResult,
    setShowModalResult,
    dataModalSuccess,
    dataModalFailure,
    resetOM005,
    branchData,
    roleData,
} from '../slice/Slice';
import LoadingComponent from '../../../../../common/components/icons/LoadingComponent';
import { OM005Hanlder } from '../handler/HandlerImpl';

type props = {
    handler: OM005Hanlder;
};

const OM005ModalResult = ({ handler }: props) => {
    const navigate = useNavigate();
    const { groupId } = useParams();
    const dispatch = useDispatch();
    const [loadingModal, setLoadingModal] = React.useState(false);
    const [activeButton, setActiveButton] = React.useState(1);
    const [page, setPage] = React.useState(COMMON.DEFAULT_PAGE);
    const [size, setSize] = React.useState(COMMON.DEFAULT_SIZE);
    const showModal = useSelector(showModalResult);
    const dataSuccess = useSelector(dataModalSuccess);
    const dataError = useSelector(dataModalFailure);
    const dataBra = useSelector(branchData);
    const dataRo = useSelector(roleData);

    const onCancel = () => {
        dispatch(setShowModalResult(false));
        dispatch(resetOM005());
        navigate(`/app-om002?groupId=${groupId}`);
    };

    const fakeSpinLoading = () => {
        setLoadingModal(true);
        setTimeout(() => {
            setLoadingModal(false);
        }, 250);
    };

    const getData = (current: any, pageSize: any) => {
        // Normally you should get the data from the server
        let data: any[] = activeButton === 1 ? dataSuccess : dataError
        const result = data.slice((current - 1) * pageSize, current * pageSize)
        return result
    };

    const resetPage = (e: number) => {
        setPage(e);
    };

    const resetSize = (e: number) => {
        setSize(e);
    };

    return (
        <>
            <Modal
                title="追加されたメンバーリスト"
                centered
                width={1500}
                visible={showModal}
                footer={null}
                onCancel={onCancel}
            >
                <div className="om005__modal-button">
                    <Button
                        onClick={() => {
                            setActiveButton(1);
                            fakeSpinLoading();
                        }}
                        className={
                            activeButton === 1
                                ? `om005__modal-button-success om005__button-active`
                                : `om005__modal-button-success`
                        }
                        style={{ letterSpacing: '-1px' }}
                    >
                        成功
                    </Button>
                    <Button
                        onClick={() => {
                            setActiveButton(2);
                            fakeSpinLoading();
                        }}
                        className={
                            activeButton === 2
                                ? `om005__modal-button-failure om005__button-active`
                                : `om005__modal-button-failure`
                        }
                        style={{ letterSpacing: '-1px' }}
                    >
                        失敗
                    </Button>
                </div>
                <Spin spinning={loadingModal} indicator={LoadingComponent}>
                    <OM005DataTable
                        paddingProps={0}
                        scrollProps={450}
                        selectionOptionProps={false}
                        data={getData(page, size)}
                        columnCustomize={COLUMNS_RESULTS(handler, dataBra, dataRo)}
                    />
                    {(activeButton === 1 && dataSuccess?.length > 0) ||
                        (activeButton === 2 && dataError?.length > 0) ? (
                        <PaginationComponentList
                            total={activeButton === 1 ? dataSuccess.length : dataError.length}
                            page={page}
                            size={size}
                            setPage={resetPage}
                            setSize={resetSize}
                            sizeLabelPage={' 人 '}
                            sizeLabelSize={' ページ '}
                        />
                    ) : null}
                </Spin>
            </Modal>
        </>
    );
};

export default OM005ModalResult;
