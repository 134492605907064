import { useDispatch, useSelector } from "react-redux";
import { SN01ServiceImpl } from "../../usecase/ServiceImpl";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../../common/constants/MESSAGE";
import { setLoadingSN01 } from "../slice/Slice";
import { useState } from "react";
import { NotificationType } from "../type/Presenter";
import { Form } from "antd";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import { RootState } from "../../../../../store";
import { PLAN_TYPE } from "../../../../../common/constants/COMMON";
import { AUTHORIZATION_CODE } from "../../../../../common/constants/AUTHORIZATION";
import { isHasPrivilegeByPermission } from "../../../../../common/helpers/privilege";

export const NOTIFICATION_CODE = {
  //table left top
  CREATE_PROJECT: "create_project",
  UPDATE_PROJECT: "update_project",
  CREATE_DOCUMENT_SUMMARY: "create_document_summary",
  UPDATE_DOCUMENT_SUMMARY: "update_document_summary",
  CREATE_DOCUMENT_DETAIL: "create_document_detail",
  UPDATE_DOCUMENT_DETAIL: "update_document_detail",

  //table left bot
  RE_CREATE_MATERIAL: "re_create_material",
  RE_UPDATE_MATERIAL: "re_update_material",
  RE_CREATE_OUTSOURCE: "re_create_outsource",
  RE_UPDATE_OUTSOURCE: "re_update_outsource",
  RE_CREATE_LABOR: "re_create_labor",
  RE_UPDATE_LABOR: "re_update_labor",
  RE_CREATE_INDIRECT: "re_create_indirect",
  RE_UPDATE_INDIRECT: "re_update_indirect",
  RE_CREATE_RECEIVED: "re_create_received",
  RE_UPDATE_RECEIVED: "re_update_received",
  //table right top
  ATTENDANCE: "attendance",

  //table right bot
  UPDATE_WORKFLOW: "update_workflow",
  SCHEDULE_COMMENT: "schedule_comment",
};

const SN01Handler = (service: SN01ServiceImpl) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [dataNotification, setDataNotification] = useState<any>();
  const [dataTableLeftTop, setDataTableLeftTop] = useState<any>();
  const [dataTableLeftBot, setDataTableLeftBot] = useState<any>();
  const [dataTableRightTop, setDataTableRightTop] = useState<any>();
  const [dataTableRightBot, setDataTableRightBot] = useState<any>();
  const [dataTableRightBot2, setDataTableRightBot2] = useState<any>();

  const isPlanFree =
    useSelector((state: RootState) => state.auth.authData?.order?.code) ===
    PLAN_TYPE.PLAN_FREE;
  const permission = useSelector(
    (state: RootState) => state.auth.authData?.permission
  );

  const isRoleAdminBasic = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdminBasic
  );

  const isUserInWorkspace = useSelector(
    (state: RootState) => state.auth.authData?.isUserInWorkspace
  );

  const isUserIsProjectManager = useSelector(
    (state: RootState) => state.auth.authData?.isUserIsProjectManager
  );

  const subscriptions =
    useSelector(
      (state: RootState) => state.auth?.authData?.order?.subscription
    ) ?? [];

  const getListNotification = async (): Promise<any> => {
    try {
      dispatch(setLoadingSN01(true));
      const res = await service.getListNotification();
      if (res.results) formatNotification(res.results);
    } catch (e: any) {
      ErrorNotification(e?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoadingSN01(false));
    }
  };

  const formatNotification = (data: any) => {
    setDataNotification(data);
    // table left top
    const dataTableLeftTop =
      isHasPrivilegeByPermission([
        AUTHORIZATION_CODE.S11,
        AUTHORIZATION_CODE.S14,
        AUTHORIZATION_CODE.S18,
        AUTHORIZATION_CODE.B10,
      ]) ||
      isUserIsProjectManager ||
      isRoleAdminBasic ||
      isUserInWorkspace
        ? [
            isHasPrivilegeByPermission([
              AUTHORIZATION_CODE.S11,
              AUTHORIZATION_CODE.B10,
            ]) ||
            isUserIsProjectManager ||
            isRoleAdminBasic ||
            isUserInWorkspace
              ? {
                  key: 1,
                  title: "案件基本情報",
                  create: NOTIFICATION_CODE.CREATE_PROJECT,
                  edit: NOTIFICATION_CODE.UPDATE_PROJECT,
                }
              : null,
            (isHasPrivilegeByPermission([
              AUTHORIZATION_CODE.S14,
              AUTHORIZATION_CODE.B10,
            ]) ||
              isUserIsProjectManager ||
              isRoleAdminBasic) &&
            !isPlanFree
              ? {
                  key: 2,
                  title: "工事引合書",
                  create: NOTIFICATION_CODE.CREATE_DOCUMENT_SUMMARY,
                  edit: NOTIFICATION_CODE.UPDATE_DOCUMENT_SUMMARY,
                }
              : null,
            (isHasPrivilegeByPermission([
              AUTHORIZATION_CODE.S18,
              AUTHORIZATION_CODE.B10,
            ]) ||
              isUserIsProjectManager ||
              isRoleAdminBasic) &&
            !isPlanFree
              ? {
                  key: 3,
                  title: "実行予算書",
                  create: NOTIFICATION_CODE.CREATE_DOCUMENT_DETAIL,
                  edit: NOTIFICATION_CODE.UPDATE_DOCUMENT_DETAIL,
                }
              : null,
          ]
        : [];
    setDataTableLeftTop(dataTableLeftTop.filter((element) => element !== null));
    form.setFieldValue(
      NOTIFICATION_CODE.CREATE_PROJECT,
      getNotificationByCode(data, NOTIFICATION_CODE.CREATE_PROJECT)
        ?.enableNotification
    );
    form.setFieldValue(
      NOTIFICATION_CODE.UPDATE_PROJECT,
      getNotificationByCode(data, NOTIFICATION_CODE.UPDATE_PROJECT)
        ?.enableNotification
    );

    form.setFieldValue(
      NOTIFICATION_CODE.CREATE_DOCUMENT_SUMMARY,
      getNotificationByCode(data, NOTIFICATION_CODE.CREATE_DOCUMENT_SUMMARY)
        ?.enableNotification
    );
    form.setFieldValue(
      NOTIFICATION_CODE.UPDATE_DOCUMENT_SUMMARY,
      getNotificationByCode(data, NOTIFICATION_CODE.UPDATE_DOCUMENT_SUMMARY)
        ?.enableNotification
    );

    form.setFieldValue(
      NOTIFICATION_CODE.CREATE_DOCUMENT_DETAIL,
      getNotificationByCode(data, NOTIFICATION_CODE.CREATE_DOCUMENT_DETAIL)
        ?.enableNotification
    );
    form.setFieldValue(
      NOTIFICATION_CODE.UPDATE_DOCUMENT_DETAIL,
      getNotificationByCode(data, NOTIFICATION_CODE.UPDATE_DOCUMENT_DETAIL)
        ?.enableNotification
    );

    //table left bot
    const dataTableLeftBot: any =
      isRoleAdminBasic ||
      (isUserIsProjectManager && subscriptions.length > 0) ||
      isHasPrivilegeByPermission([
        AUTHORIZATION_CODE.S28,
        AUTHORIZATION_CODE.S29,
        AUTHORIZATION_CODE.S30,
        AUTHORIZATION_CODE.S301,
        AUTHORIZATION_CODE.S31,
        AUTHORIZATION_CODE.S42,
        AUTHORIZATION_CODE.S48,
        AUTHORIZATION_CODE.S49,
      ])
        ? [
            {
              key: 1,
              title: "請求履歴",
              create: NOTIFICATION_CODE.RE_CREATE_RECEIVED,
              edit: NOTIFICATION_CODE.RE_UPDATE_RECEIVED,
            },
            {
              key: 2,
              title: "材料費支払履歴",
              create: NOTIFICATION_CODE.RE_CREATE_MATERIAL,
              edit: NOTIFICATION_CODE.RE_UPDATE_MATERIAL,
            },
            {
              key: 3,
              title: "外注費支払履歴",
              create: NOTIFICATION_CODE.RE_CREATE_OUTSOURCE,
              edit: NOTIFICATION_CODE.RE_UPDATE_OUTSOURCE,
            },
            {
              key: 4,
              title: "労務費支払履歴",
              create: NOTIFICATION_CODE.RE_CREATE_LABOR,
              edit: NOTIFICATION_CODE.RE_UPDATE_LABOR,
            },
            {
              key: 5,
              title: "間接工事費支払履歴",
              create: NOTIFICATION_CODE.RE_CREATE_INDIRECT,
              edit: NOTIFICATION_CODE.RE_UPDATE_INDIRECT,
            },
          ]
        : null;
    setDataTableLeftBot(dataTableLeftBot);
    form.setFieldValue(
      NOTIFICATION_CODE.RE_CREATE_MATERIAL,
      getNotificationByCode(data, NOTIFICATION_CODE.RE_CREATE_MATERIAL)
        ?.enableNotification
    );
    form.setFieldValue(
      NOTIFICATION_CODE.RE_UPDATE_MATERIAL,
      getNotificationByCode(data, NOTIFICATION_CODE.RE_UPDATE_MATERIAL)
        ?.enableNotification
    );

    form.setFieldValue(
      NOTIFICATION_CODE.RE_CREATE_OUTSOURCE,
      getNotificationByCode(data, NOTIFICATION_CODE.RE_CREATE_OUTSOURCE)
        ?.enableNotification
    );
    form.setFieldValue(
      NOTIFICATION_CODE.RE_UPDATE_OUTSOURCE,
      getNotificationByCode(data, NOTIFICATION_CODE.RE_UPDATE_OUTSOURCE)
        ?.enableNotification
    );

    form.setFieldValue(
      NOTIFICATION_CODE.RE_CREATE_LABOR,
      getNotificationByCode(data, NOTIFICATION_CODE.RE_CREATE_LABOR)
        ?.enableNotification
    );
    form.setFieldValue(
      NOTIFICATION_CODE.RE_UPDATE_LABOR,
      getNotificationByCode(data, NOTIFICATION_CODE.RE_UPDATE_LABOR)
        ?.enableNotification
    );
    form.setFieldValue(
      NOTIFICATION_CODE.RE_CREATE_INDIRECT,
      getNotificationByCode(data, NOTIFICATION_CODE.RE_CREATE_INDIRECT)
        ?.enableNotification
    );
    form.setFieldValue(
      NOTIFICATION_CODE.RE_UPDATE_INDIRECT,
      getNotificationByCode(data, NOTIFICATION_CODE.RE_UPDATE_INDIRECT)
        ?.enableNotification
    );
    form.setFieldValue(
      NOTIFICATION_CODE.RE_CREATE_RECEIVED,
      getNotificationByCode(data, NOTIFICATION_CODE.RE_CREATE_RECEIVED)
        ?.enableNotification
    );
    form.setFieldValue(
      NOTIFICATION_CODE.RE_UPDATE_RECEIVED,
      getNotificationByCode(data, NOTIFICATION_CODE.RE_UPDATE_RECEIVED)
        ?.enableNotification
    );
    //table right top
    const dataTableRightTop: any = isPlanFree
      ? null
      : [
          {
            key: 1,
            title: "外部メンバー打刻",
            engraving: NOTIFICATION_CODE.ATTENDANCE,
          },
        ];
    setDataTableRightTop(dataTableRightTop);

    form.setFieldValue(
      NOTIFICATION_CODE.ATTENDANCE,
      getNotificationByCode(data, NOTIFICATION_CODE.ATTENDANCE)
        ?.enableNotification
    );

    //table right bot
    const dataTableRightBot: any = [];
    if (!isPlanFree) {
      dataTableRightBot.push({
        key: 1,
        title: "ワークフローマスタ",
        edit: NOTIFICATION_CODE.UPDATE_WORKFLOW,
      });
    }
    setDataTableRightBot(dataTableRightBot);
    form.setFieldValue(
      NOTIFICATION_CODE.UPDATE_WORKFLOW,
      getNotificationByCode(data, NOTIFICATION_CODE.UPDATE_WORKFLOW)
        ?.enableNotification
    );

    //table right bot
    const dataTableRightBot2: any = [];
    if (!isPlanFree) {
      dataTableRightBot2.push({
        key: 1,
        title: "イベント",
        edit: NOTIFICATION_CODE.SCHEDULE_COMMENT,
      });
    }
    setDataTableRightBot2(dataTableRightBot2);
    form.setFieldValue(
      NOTIFICATION_CODE.SCHEDULE_COMMENT,
      getNotificationByCode(data, NOTIFICATION_CODE.SCHEDULE_COMMENT)
        ?.enableNotification
    );
  };

  const getNotificationByCode = (data: any, code: string) => {
    const res = data.find((item: any) => item?.code === code);
    return res;
  };

  const handleChangeValue = (value: any) => {
    const data = {
      id: getNotificationByCode(dataNotification, Object.keys(value)[0])?.id,
      enableNotification: form.getFieldValue(Object.keys(value)[0]),
    };
    settingNotification(data);
  };

  const settingNotification = async (data: {
    enableNotification: boolean;
    id: number;
  }): Promise<any> => {
    try {
      dispatch(setLoadingSN01(true));
      const res = await service.settingNotification(data);
      if (res.message)
        SuccessNotification(res?.data?.message ?? NOTIFICATION_TITLE.SUCCESS);
    } catch (e: any) {
      ErrorNotification(e?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoadingSN01(false));
    }
  };

  return {
    form,
    NOTIFICATION_CODE,
    dataTableLeftTop,
    dataTableLeftBot,
    dataTableRightTop,
    dataTableRightBot,
    dataTableRightBot2,
    getListNotification,
    handleChangeValue,
  };
};

export default SN01Handler;
