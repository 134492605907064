import { AxiosResponse } from "axios";
import axiosConfig from "../../../../../config/axiosConfig";
import { ReSummaryCompanySummaryOutsourceCostSubmitPayload } from "../../entity/Entity";

const CM0017Api = () => {
  const getReSummaryCompanySummaryOutsourceCostDetail = async (params: {
    companyId: number;
    colabRefId: number;

    keyword?: string;
    sortBy?: string;
    sortType?: string;
    page?: string;
    size?: string;
    from?: string;
    to?: string;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/re-summary/company/summary/outsource-cost/detail";
    return await axiosConfig.get(url, { params });
  };
  const getReSummaryCompanySummaryOutsourceCostTotal = async (params: {
    companyId: number;
    colabRefId: number;
    from?: string;
    to?: string;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/re-summary/company/summary/outsource-cost/total";
    return await axiosConfig.get(url, { params });
  };
  const getReSummaryCompanyDocumentOutsourceCostStatus = async (params: {
    companyId: number;
    colabRefId: number;

    from?: string;
    to?: string;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/re-summary/company/document/outsource-cost/status";
    return await axiosConfig.get(url, { params });
  };
  const getReSummaryCompanySummaryOutsourceCost = async (params: {
    companyId: number;
    colabRefId: number;
    from?: string;
    to?: string;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/re-summary/company/summary/outsource-cost";
    return await axiosConfig.get(url, { params });
  };
  const postReSummaryCompanySummaryOutsourceCostSubmit = async (
    data: ReSummaryCompanySummaryOutsourceCostSubmitPayload
  ): Promise<AxiosResponse<any, any>> => {
    const url = "/re-summary/company/summary/outsource-cost/submit";
    return await axiosConfig.post(url, data);
  };

  const getMoneyDirectCostOutsourceOffsetList = async (params: {
    categoryId?: number;
    outsourceCostInformationId: number;
    size: number;
    page: number;
  }): Promise<any> => {
    try {
      const url = "/money/direct-cost/outsource/offset/list";
      return await axiosConfig.get(url, { params });
    } catch (error) {
      throw error;
    }
  };

  const getCompanyProfile = async (): Promise<any> => {
    try {
      const url = "/company/profile";
      const response = await axiosConfig.get(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getSubCategory = async (): Promise<any> => {
    try {
      const url = "/money/received-money-offset/sub-category";
      const response = await axiosConfig.get(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  const getTaxSetting = async (params: any): Promise<any> => {
    const url = "/tax-setting";
    return await axiosConfig.get(url, { params });
  };

  const doExportCM0017 = async (params: any): Promise<any> => {
    try {
      const url = "/re-summary/exportCM0017";
      const response = await axiosConfig.get(url, {
        params,
        responseType: "blob",
      });

      return response;
    } catch (error) {
      throw error;
    }
  };
  return {
    getReSummaryCompanySummaryOutsourceCostDetail,
    getReSummaryCompanySummaryOutsourceCostTotal,
    getReSummaryCompanySummaryOutsourceCost,
    postReSummaryCompanySummaryOutsourceCostSubmit,
    getReSummaryCompanyDocumentOutsourceCostStatus,
    getMoneyDirectCostOutsourceOffsetList,
    getCompanyProfile,
    getSubCategory,
    getTaxSetting,
    doExportCM0017,
  };
};

export default CM0017Api;
