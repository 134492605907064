import AM0019Api from './infrastructure/api/Api';
import CM0019Handler from './presenter/handler/Handler';
import CM0019Service from './usecase/Service';
import MainUI from './presenter/ui/UI';

import '../../../assets/styles/AM/AM002.css';
import '../../../assets/styles/OM/OM002.css';
import '../../../assets/styles/CM/cm0019.css';

const AM0019Component = () => {
    const api = AM0019Api();

    const service = CM0019Service(api);

    const handler = CM0019Handler(service);

    return <MainUI handler={handler} />;
};

export default AM0019Component;
