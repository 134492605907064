import AM024Api from "./infrastructure/api/Api";
import AM024Handler from "./presenter/handler/Handler";
import AM024UI from "./presenter/ui/UI";
import AM024Service from "./usecase/Service";

const AM024Component = () => {
  const am024Api = AM024Api();
  const am024Service = AM024Service(am024Api);
  const am024Handler = AM024Handler(am024Service);
  return <AM024UI handler={am024Handler} />;
};

export default AM024Component;
