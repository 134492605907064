import { createSlice } from '@reduxjs/toolkit';

type OpenStreetMapSliceType = {
    isVisible: boolean;
    lat: number;
    lng: number;
};

const initialState: OpenStreetMapSliceType = {
    isVisible: false,
    lat: 0,
    lng: 0,
};

export const openStreetMapSlice = createSlice({
    name: 'openStreetMap',
    initialState,
    reducers: {
        openModalStreetMap: (state, action) => {
            const { lat, lng } = action.payload;
            state.lat = lat;
            state.lng = lng;
            state.isVisible = true;
        },
        closeModalStreetMap: () => initialState,
    },
});

export const { closeModalStreetMap, openModalStreetMap } = openStreetMapSlice.actions;

export default openStreetMapSlice.reducer;
