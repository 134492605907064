import { Input, Popconfirm } from 'antd';
import EditIcon from '../../../../../assets/icons/t01-edit.svg';
import EditCancel from '../../../../../assets/icons/t01-cancel.svg';
import EditConfirm from '../../../../../assets/icons/t01-confirm.svg';
import React from 'react';
import { T01HandlerImpl } from '../handler/HandlerImpl';
import { typeInteger } from '../../../../../common/helpers/typeInput';

type props = {
  handler: T01HandlerImpl;
};

const TPopover = ({ handler }: props) => {
  const [val, setVal] = React.useState<number>(handler.value);
  const onChange = (e: any) => {
    setVal(e.target.value);
  };

  const onSubmit = () => {
    handler.updateTax(Number(val));
    setKey(Math.random());
  };

  const content = (
    <div className="t01__popover">
      <Input
        defaultValue={handler.value}
        onChange={onChange}
        onKeyPress={typeInteger}
        minLength={1}
        maxLength={2}
      />
      <img
        src={EditConfirm}
        alt="edit icon confirm"
        onClick={() => onSubmit()}
      />
      <img src={EditCancel} onClick={() => onCancel()} alt="edit icon cancel" />
    </div>
  );

  const [key, setKey] = React.useState<number>(Math.random());

  const onCancel = () => {
    setKey(Math.random());
  };

  return (
    <Popconfirm
      key={key}
      placement="bottom"
      title={null}
      okText={null}
      icon={content}
      onVisibleChange={(e: boolean) => { if (!e) onCancel()}}
    >
      <img src={EditIcon} alt="icon edit" />
    </Popconfirm>
  );
};

export default TPopover;
