import axiosConfig from "../../../../../config/axiosConfig";

const CM001Api = () => {
  const getProjectSummaryReceivedMoney = async (params: {
    projectId: string;
  }): Promise<any> => {
    const url = "/money/project/summary/received-money";
    return await axiosConfig.get(url, { params });
  };

  const getProjectSummaryCost = async (params: {
    projectId: string;
  }): Promise<any> => {
    const url = "/money/summary/project/cost";
    return await axiosConfig.get(url, { params });
  };

  const getListConstructionSummaryReceivedMoney = async (params: {
    projectId: string;
  }): Promise<any> => {
    const url = "/money/summary/list-construction/received-money";
    return await axiosConfig.get(url, { params });
  };

  const updateThreshold = async (data: any) => {
    const url = "/money/summary/project/receive-money/threshold/update";
    return await axiosConfig.put(url, data);
  };

  const updateThresholdCostMaterial = async (data: any) => {
    const url = "/money/summary/project/cost/material/threshold/update";
    return await axiosConfig.put(url, data);
  };

  const updateThresholdCostLabor = async (data: any) => {
    const url = "/money/summary/project/cost/labor/threshold/update";
    return await axiosConfig.put(url, data);
  };

  const updateThresholdCostOutsource = async (data: any) => {
    const url = "/money/summary/project/cost/outsource/threshold/update";
    return await axiosConfig.put(url, data);
  };

  const updateThresholdCostIndirect = async (data: any) => {
    const url = "/money/summary/project/cost/indirect/threshold/update";
    return await axiosConfig.put(url, data);
  };

  const updateThresholdCostTotal = async (data: any) => {
    const url = "/money/summary/project/cost/total/threshold/update";
    return await axiosConfig.put(url, data);
  };

  const updateThresholdConstruction = async (data: any) => {
    const url = "/money/summary/construction/receive-money/threshold/update";
    return await axiosConfig.put(url, data);
  };

  const doExportCM001 = async (params: any): Promise<any> => {
    try {
      const url = "/money/exportCM001";
      const response = await axiosConfig.get(url, {
        params,
        responseType: "blob",
      });

      return response;
    } catch (error) {
      throw error;
    }
  };

  const doExportCM002 = async (params: any): Promise<any> => {
    try {
      const url = "/money/exportCM002";
      const response = await axiosConfig.get(url, {
        params,
        responseType: "blob",
      });

      return response;
    } catch (error) {
      throw error;
    }
  };

  return {
    getProjectSummaryReceivedMoney,
    getProjectSummaryCost,
    getListConstructionSummaryReceivedMoney,
    updateThreshold,
    updateThresholdCostMaterial,
    updateThresholdCostLabor,
    updateThresholdCostOutsource,
    updateThresholdCostIndirect,
    updateThresholdCostTotal,
    updateThresholdConstruction,
    doExportCM001,
    doExportCM002,
  };
};

export default CM001Api;
