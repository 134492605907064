import { H2EntityData } from './H2Entity';

const ROWS: H2EntityData[] = [
    {
        id: 1,
        name: 'マスタデータ　閲覧',
        allowProjectManager: true,
        allowDeputyProjectManager: false,
        allowItemCreator: true,
        allowProjectMember: false,
        isAdmin: true,
    },
];
const convertToDisplayTable = (data: H2EntityData[]) => {
    return data;
};

export { ROWS, convertToDisplayTable };
