import axiosConfig from "../../../../../config/axiosConfig";
import { AxiosResponse } from "axios";

const API = () => {
  const getNotificationCategory = async (): Promise<
    AxiosResponse<any, any>
  > => {
    const url = "/notification/category";
    return await axiosConfig.get(url);
  };
  const getNotificationUnreadCount = async (params: {
    categoryId: number;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/notification/unread/count";
    return await axiosConfig.get(url, { params });
  };
  const getNotificationAll = async (params: {
    categoryId: number;
    keyword?: string;
    page?: string | number;
    size?: string | number;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/notification/all";
    return await axiosConfig.get(url, { params });
  };

  const putNotificationMarkRead = async (data: { listNotiId: number[] }) => {
    const url = "/notification/mark-read";
    return await axiosConfig.put(url, data);
  };

  const deleteNotificationDelete = async (data: { listNotiId: number[] }) => {
    const url = "/notification/delete";
    return await axiosConfig.delete(url, { data });
  };
  return {
    getNotificationCategory,
    getNotificationAll,
    putNotificationMarkRead,
    deleteNotificationDelete,
    getNotificationUnreadCount,
  };
};

export default API;
