import { PaginationResult } from "../entity/Entity";
import moment from "moment";

const COMMON = {
  ISO_8601_HH_MM_SS: "T00:00:00.000Z",
  AXIOS_TIMEOUT_REQUEST: 10000,
  HTTP_STATUS_OK: 200,
  DEFAULT_PAGE: 1,
  DEFAULT_SIZE: 10,
  DEFAULT_SORT_BY: "createdAt",
  DEFAULT_SORT_TYPE: "DESC",
  DEFAULT_SIDE_SIZE: 6,
  FORMAT_DATE_4: "YYYY-MM-DD HH:mm",
  FORMAT_DATE: "YYYY/MM/DD",
  FORMAT_DATE2: "YYYY-MM-DD",
  FORMAT_DATE3: "DD/MM/YYYY",
  FORMAT_DATE4: "YYYY-MM",
  FORMAT_DATE5: "YYYY/MM",
  FORMAT_DATE6: "MM月DD日",
  FORMAT_DATE7: "YYYYMMDD",
  FORMAT_DATE_SUBMIT: "YYYY_MM_DD",
  FORMAT_DATE_JP: "YYYY年MM月",
  FORMAT_DATE_JP2: "YYYY年MM月",
  FORMAT_TIME: "HH時mm分",
  FORMAT_TIME3: "H:mm",
  FORMAT_TIME2: "hh:mm",
  FORMAT_TIME1: "HH:mm",
  FORMAT_DATE_TIME: "YYYY/MM/DD HH:mm",
  FORMAT_DATE_TIME2: "YYYY/MM/DD HH:mm:ss",
  FORMAT_DATE_CCUS062: "YYYYMMDD",
  FORMAT_DATE_CCUS: "YYYY-MM-DD",
  ALLOW_TYPE_FILE: [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "text/csv",
  ],
  FORMAT_YEAR_MONTH: "YYYY-MM",
  EXCEL_KEY_TYPE: "xlsx",
  CSV_KEY_TYPE: "csv",
  FORMAT_DATE_CI: "YYYY 年 MM 月 DD 日",
  FORMAT_DATE_CM: "YYYY年MM月DD日",
  FORMAT_DATE_DB: "YYYY年MM月",
  MAX_LENGTH_PHONE_NUMBER: 14,
  DEFAULT_ROLE_CODE: "employee",
  UNDEFINED_GROUP: "Undefined group",
  ONE_MINUTE: 60 * 1000,
  FORMAT_DATE_OM: "YYYY年MM月DD日",
  MAX_INPUT_NUMBER: 19,
  MAX_NUMBER_VALUE: 999999999999999,
  PARENT_CONSTRUCTION: "parentConstructionName",
  USER_ID: "userId",
  PROJECT_MANAGER: "projectManagerId",
  FORMAT_DATE_JA_MONTH: "YYYY年MM月",
  FORMAT_DATE_TIME_STRING: "YYYY年MM月DD日　HH:mm",
  REQUEST_CONSTANT: {
    ATTENDANCE: {
      title: "打刻申請合計",
      color: "#33CC99",
      icon: "icon1",
      parentValue: 0,
      request_waiting_for_approval: 0,
      request_approved: 0,
      request_reject: 0,
    },
    LATE_EARLY: {
      title: "遅刻・早退申請合計",
      color: "#FF9054",
      icon: "icon2",
      parentValue: 0,
      request_waiting_for_approval: 0,
      request_approved: 0,
      request_reject: 0,
    },
    LEAVE: {
      title: "休暇申請合計",
      color: "#0050AE",
      icon: "icon3",
      parentValue: 0,
      request_waiting_for_approval: 0,
      request_approved: 0,
      request_reject: 0,
    },
  },
  PARAM_LIST: {
    PAGE: "page",
    SIZE: "size",
    SORT_BY: "sortBy",
    SORT_TYPE: "sortType",
    KEYWORD: "keyword",
    CREATED_AT: "createdAt",
    DESC: "desc",
  },
  TODAY: "現在",
  TIME_ZONE: Number(moment().format("Z")?.slice(0, 3)) ?? 0,
  MODAL_IMAGE_TITLE: "現場案内図",
  MODAL_IMAGE_TITLE_CM: "アップロードしたファイル名",
  CM_FILE_STRUCTURE: {
    SCREEN_NAME: "工事詳細情報",
    PARENT_TAB: {
      TAB_1: {
        NAME: "工事収支",
        CHILDREN_TAB: [],
      },
      TAB_2: {
        NAME: "請求出来高",
        CHILDREN_TAB: [],
      },
      TAB_3: {
        NAME: "直接工事費",
        CHILDREN_TAB: ["材料費", "外注費", "労務費"],
      },
      TAB_4: {
        NAME: "間接工事費",
        CHILDREN_TAB: [],
      },
    },
  },
  MONEY_APPROVED_CODE: "money_retrieve",
  INPUT_SEARCH: "-input",
  NA: "N/A",
  FORMAT_DAY: "DD",
  SCROLL_EVENT: "SCROLL_EV",
  ADMIN_CODE: "admin",
  PLAN_ORDER_BASIC: "plan_basic",
};

export const DEFAULT_PAGINATION_RESULT: PaginationResult = {
  pagination: {
    count: 0,
    displayRecord: 0,
    numPages: 0,
    page: 1,
  },
  results: [],
};

export default COMMON;

export const STATUS_CM_CHILD_TABLE = {
  MONEY_APPROVED: {
    VALUE: "承認済",
    COLOR: "#0FA44A",
    CODE: "money_approved",
  },
  MONEY_REJECT: {
    VALUE: "否認",
    COLOR: "#FF0909",
    CODE: "money_reject",
  },
  MONEY_RETRIEVE: {
    VALUE: "取り下げ",
    COLOR: "#3234a8",
    CODE: "money_retrieve",
  },
  MONEY_WAITING_FOR_APPROVAL: {
    VALUE: "承認待ち",
    COLOR: "#FF9054",
    CODE: "money_waiting_for_approval",
  },
  MONEY_NOT_APPROVED: {
    VALUE: "未承認",
    COLOR: "#7932a8",
    CODE: "money_not_approved",
  },
};

export const STATUS_CM_PARENT_TABLE = {
  APPROVED: {
    VALUE: "approved",
    LABEL: "承認済",
  },
  PENDING: {
    VALUE: "pending",
    LABEL: "承認待ち",
  },
  REJECTED: {
    VALUE: "rejected",
    LABEL: "未承認",
  },
};

export const TAX_CODE = {
  TAX_FREE: "tax_free",
  TAX: "tax",
};

export const USER_FLOW = {
  USER: "user",
  MANAGER_SUBMIT: "manager_submit",
  MANAGER_SUBMITTED: "manager_submitted",
  MANAGER_FLOW_SUBMIT: "manager_flow_submit",
  MANAGER_FLOW_SUBMITTED: "manager_flow_submitted",
  FLOW_SUBMIT: "flow_submit",
  FLOW_SUBMITTED: "flow_submitted",
};

export const ORDER_STATUS = {
  ACTIVE_ORDER: "active_order",
  INACTIVE_ORDER: "inactive_order",
};

export const PLAN_TYPE = {
  PLAN_FREE: "plan_free",
  PLAN_BASIC: "plan_basic",
};

export const COMPANY_COLAB_STATUS = {
  ACTIVE_PLAN: "active_plan",
  DEACTIVE_PLAN: "deactive_plan",
};

export const STATUS_PROJECT_CODE = {
  ESTIMATED: "estimated",
  UNDER_CONSTRUCTION: "underConstruction",
  COMPLETE: "complete",
  BOT: "bot",
};

export const AVATAR_ADMIN = "アドミン";

export const FILE_NAME = {
  CI001: "工事引合書",
  CR003: "実行予算書",
};

export const MAX_LENGTH = {
  ADDRESS: 50,
  CCUSID: 14,
  FORMAT_CCUSID: 17,
};

export const IMAGE_PDF_STANDARD_WIDTH = 1516;
export const IMAGE_PDF_STANDARD_HEIGHT = 703;

export const NODATA_TEXT = "該当なデータはありません";
export const LABEL_PROJECT = "案件名";
export const LABEL_COMPANY = "所属事業者名";
export const LABEL_APPROVER = "元請事業者承認者";
export const LABEL_REGISTER_TYPE = "登録区分";

