import { useDispatch, useSelector } from "react-redux";
// Built-in
import React, { useState } from "react";

// Source files
import { CM004ServiceImpl } from "./../../usecase/ServiceImpl";
import { useSearchParams } from "react-router-dom";
import {
  setConstructionInfo,
  toggleIsExport,
  toggleIsSubmit,
  resetCM004,
  setDetailProject,
} from "../slice/Slice";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../../common/constants/MESSAGE";
import { RootState } from "../../../../../store";
import { setOrderingCompanyName } from "../../../CM0063/presenter/slice/Slice";

const CM004Handler = (service: CM004ServiceImpl) => {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const [isSubmitVisible, setIsSubmitVisible] = useState<boolean>(false);

  const onCancelSubmitModal = () => {
    setIsSubmitVisible(false);
  };
  const onOpenSubmitModal = () => {
    setIsSubmitVisible(true);
  };
  const onExport = () => {
    dispatch(toggleIsExport());
  };

  const onSubmit = () => {
    dispatch(toggleIsSubmit());
    setIsSubmitVisible(false);
  };
  const getMoneyConstructionInformation = async (params: {
    constructionId: number;
  }) => {
    try {
      dispatch(setLoading(true));
      const responseData = await service.getMoneyConstructionInformation({
        constructionId: params.constructionId,
      });
      dispatch(setConstructionInfo(responseData));

      // set orderingCompanyName
      dispatch(setOrderingCompanyName(responseData?.orderingCompanyName ?? ""));
    } catch (error: any) {
      ErrorNotification(error.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };
  const getDetailProject = async (params: { projectId: number }) => {
    try {
      dispatch(setLoading(true));
      const responseData = await service.getDetailProject({
        projectId: params.projectId,
      });
      dispatch(setDetailProject(responseData));
    } catch (error: any) {
      ErrorNotification(error.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const cleanEffect = () => {
    dispatch(resetCM004());
  };

  const onTabChange = (value: any) => {
    if (value !== 2) searchParams.delete("subTab");
    else searchParams.set("subTab", "1");
    searchParams.set("tab", value + "");
    setSearchParams(searchParams);
  };

  const onSubTabChange = (value: any) => {
    searchParams.set("subTab", value + "");
    setSearchParams(searchParams);
  };

  return {
    isSubmitVisible,
    getMoneyConstructionInformation,
    onTabChange,
    onSubTabChange,
    onSubmit,
    onCancelSubmitModal,
    onOpenSubmitModal,
    onExport,
    cleanEffect,
    getDetailProject,
  };
};

export default CM004Handler;
