import { AxiosResponse } from 'axios';
import axiosConfig from '../../../../../config/axiosConfig';

const SN01Api = () => {
    const getListNotification = async (): Promise<any> => {
        try {
            const url = '/notification/list-notification-type';
            const response = await axiosConfig.get(url);
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    const settingNotification = async (data: any): Promise<any> => {
        try {
            const url = '/notification/setting';
            const response = await axiosConfig.put(url, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    return {
        getListNotification,
        settingNotification,
    };
};

export default SN01Api;
