import { CM0053APIImpl } from './ServiceImpl';
import {
    BaseResponseType,
    MaterialReviewListData,
    OutsourceReviewListData,
    LaborReviewListData,
    IndirectReviewListData,
    ReceiveMoneyReviewListData,
    InternalApprovalPayload,
    InternalRejectPayload,
} from '../entity/Entity';
import ErrorNotification from '../../../../../common/components/notification/ErrorNotification';
import { NOTIFICATION_TITLE } from '../../../../../common/constants/MESSAGE';

const CM0053Service = (api: CM0053APIImpl) => {
    const getMaterialReviewList = async (): Promise<MaterialReviewListData | null> => {
        try {
            const response = await api.getMaterialReviewList();
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };
    const getOutsourceReviewList = async (): Promise<OutsourceReviewListData | null> => {
        try {
            const response = await api.getOutsourceReviewList();
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };
    const getLaborReviewList = async (): Promise<LaborReviewListData | null> => {
        try {
            const response = await api.getLaborReviewList();
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };
    const getIndirectReviewList = async (): Promise<IndirectReviewListData | null> => {
        try {
            const response = await api.getIndirectReviewList();
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };

    const getReceiveMoneyReviewList = async (): Promise<ReceiveMoneyReviewListData | null> => {
        try {
            const response = await api.getReceiveMoneyReviewList();
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };

    const putInternalApproval = async (
        payload: InternalApprovalPayload,
    ): Promise<BaseResponseType | null> => {
        try {
            const response = await api.putInternalApproval(payload);
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };
    const putInternalReject = async (
        payload: InternalRejectPayload,
    ): Promise<BaseResponseType | null> => {
        try {
            const response = await api.putInternalReject(payload);
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };

    return {
        getMaterialReviewList,
        getOutsourceReviewList,
        getLaborReviewList,
        getIndirectReviewList,
        getReceiveMoneyReviewList,
        putInternalApproval,
        putInternalReject,
    };
};

export default CM0053Service;
