import axiosConfig from "../../../../../config/axiosConfig";

const DB002Api = () => {
  const getReMoneyMonthly = async (params: {
    projectId: number;
    from: string;
    to: string;
  }) => {
    const url = "/dashboard/re/monthly";
    return await axiosConfig.get(url, { params });
  };

  const getProjectSummaryCost = async (params: {
    projectId: number;
    from: string;
    to: string;
  }) => {
    const url = "/money/summary/project/cost";
    return await axiosConfig.get(url, { params });
  };
  const getProjectSummaryReceivedMoney = async (params: {
    projectId: number;
    from: string;
    to: string;
  }) => {
    const url = "/money/project/summary/received-money";
    return await axiosConfig.get(url, { params });
  };
  return {
    getReMoneyMonthly,
    getProjectSummaryCost,
    getProjectSummaryReceivedMoney,
  };
};

export default DB002Api;
