export const AUTHORIZATION_CODE = {
  B1: "B1",
  M1: "M1",
  S0: "S0",
  S1: "S1",
  S2: "S2",
  S3: "S3",
  S4: "S4",
  S5: "S5",
  S6: "S6",
  S7: "S7",
  S8: "S8",
  S9: "S9",
  S11: "S11",
  S12: "S12",
  M2: "M2",
  S14: "S14",
  S15: "S15",
  S16: "S16",
  S17: "S17",
  S18: "S18",
  S19: "S19",
  S20: "S20",
  S21: "S21",
  S22: "S22",
  B2: "B2",
  M6: "M6",
  M7: "M7",
  B3: "B3",
  M8: "M8",
  M9: "M9",
  M10: "M10",
  M100: "M100",
  S100: "S100",
  S101: "S101",
  S102: "S102",
  M11: "M11",
  S110: "S110",
  S24: "S24",
  S25: "S25",
  M12: "M12",
  M13: "M13",
  M14: "M14",

  M15: "M15",
  S26: "S26",
  S27: "S27",
  M16: "M16",
  S500: "S500",
  S501: "S501",
  S502: "S502",
  S51: "S51",
  S52: "S52",
  M230: "M230",
  S67: "S67",
  S68: "S68",
  S69: "S69",
  S70: "S70",
  B6: "B6",
  B7: "B7",
  M17: "M17",
  S28: "S28",
  S29: "S29",
  S30: "S30",
  S301: "S301",
  S31: "S31",
  M18: "M18",
  S32: "S32",
  S33: "S33",
  S34: "S34",
  S341: "S341",
  S35: "S35",
  M19: "M19",
  S36: "S36",
  S37: "S37",
  S371: "S371",
  S38: "S38",
  B8: "B8",
  M20: "M20",
  S39: "S39",
  M21: "M21",
  S40: "S40",
  S41: "S41",
  B9: "B9",
  B10: "B10",
  B13: "B13",
  B11: "B11",
  B12: "B12",
  B14: "B14",
  B15: "B15",
  M22: "M22",
  M23: "M23",
  M24: "M24",
  M200: "M200",
  M210: "M210",
  S42: "S42",
  S48: "S48",
  S49: "S49",
  S43: "S43",
  S44: "S44",
  S45: "S45",
  S46: "S46",
  S47: "S47",
  S600: "S600",
  S610: "S610",
  S620: "S620",
  S650: "S650",
  B16: "B16",
  ADMIN: "admin",
  ADMIN_SYSTEM: "admin_system",
};

export const PAGE_AUTHORIZATION = {
  SW01: [
    AUTHORIZATION_CODE.S500,
    AUTHORIZATION_CODE.S501,
    AUTHORIZATION_CODE.S502,
  ],
  SW02: [AUTHORIZATION_CODE.S52],
  CM: [AUTHORIZATION_CODE.S28],
  CM2: [AUTHORIZATION_CODE.S32],
  CM3: [AUTHORIZATION_CODE.S36, AUTHORIZATION_CODE.S46],
  CM4: [AUTHORIZATION_CODE.S371],

  DB001: [AUTHORIZATION_CODE.B6],
  AM002: [AUTHORIZATION_CODE.M9, AUTHORIZATION_CODE.B13],
  AM005: [AUTHORIZATION_CODE.M9, AUTHORIZATION_CODE.B13],
  AM008: [AUTHORIZATION_CODE.M10, AUTHORIZATION_CODE.B11],
  SN01: [
    AUTHORIZATION_CODE.S11,
    AUTHORIZATION_CODE.S14,
    AUTHORIZATION_CODE.S18,
    AUTHORIZATION_CODE.S42,
    AUTHORIZATION_CODE.S48,
    AUTHORIZATION_CODE.S49,
    AUTHORIZATION_CODE.S28,
    AUTHORIZATION_CODE.S29,
    AUTHORIZATION_CODE.S30,
    AUTHORIZATION_CODE.S301,
    AUTHORIZATION_CODE.S31,
    AUTHORIZATION_CODE.M8,
    AUTHORIZATION_CODE.M9,
    AUTHORIZATION_CODE.B10,
    AUTHORIZATION_CODE.B13,
  ],
  OM005: [AUTHORIZATION_CODE.M12],
  CM061: [
    AUTHORIZATION_CODE.S28,
    AUTHORIZATION_CODE.S29,
    AUTHORIZATION_CODE.S30,
    AUTHORIZATION_CODE.S301,
    AUTHORIZATION_CODE.S31,
    AUTHORIZATION_CODE.S32,
    AUTHORIZATION_CODE.S33,
    AUTHORIZATION_CODE.S34,
    AUTHORIZATION_CODE.S341,
    AUTHORIZATION_CODE.S35,
    AUTHORIZATION_CODE.S36,
    AUTHORIZATION_CODE.S37,
    AUTHORIZATION_CODE.S371,
    AUTHORIZATION_CODE.S38,
  ],
};

export const SYSTEM_ROLES = {
  ADMIN_SYSTEM: "admin",
  ADMIN: "admin",
  EMPLOYEE: "employee",
};

export const LIST_ORDER_ROLE = {
  M15: [AUTHORIZATION_CODE.S26, AUTHORIZATION_CODE.S27],
};
