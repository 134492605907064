import { useSearchParams } from "react-router-dom";
import BreadcrumbComponent from "../../../../../common/components/breadcrumb/BreadcrumbComponent";
import { Layout } from "antd";
import MenuWrapperComponent from "../../../../../common/components/menu-wrapper";
import FilterComponent from "../component/filter";
import COMMON from "../../../../../common/constants/COMMON";
import { CCUS061Handler } from "../handler/HandlerImpl";
import TableCCUS from "./Table";
import { useEffect, useState } from "react";
import { MENU_ITEM, PARAMS } from "../../constants";
import HomeSvg from "../../../../../assets/icons/home.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { setShouldFetch } from "../slice/Slice";
import DetailCCUS061Modal from "../../../../../common/components/modal-detail-ccus061/DetailCCUS061Modal";

interface props {
  handler: CCUS061Handler;
}
const { Content, Sider } = Layout;

const BREADCRUMB_CCUS02: any[] = [
  {
    title: "建設キャリアアップシステム",
    url: "",
    icon: (
      <img style={{ paddingBottom: 2, paddingRight: 8 }} alt="" src={HomeSvg} />
    ),
  },
  {
    title: "技能者情報閲覧",
    url: "",
    icon: "",
  },
];

const MainUI = ({ handler }: props) => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const shouldFetch = useSelector(
    (state: RootState) => state.ccus062.shouldFetch
  );
  const isOpenDetailCCUS061Modal = useSelector(
    (state: RootState) => state.ccus062.isOpenModalCcus061
  );

  const page = searchParams.get(PARAMS.page) || COMMON.DEFAULT_PAGE + "";
  const size = searchParams.get(PARAMS.size) || COMMON.DEFAULT_SIZE + "";

  const userId = searchParams.get(PARAMS.userId) || "";
  const groupId = searchParams.get(PARAMS.groupId) || "";
  const sortType = searchParams.get(PARAMS.sortType);
  const sortBy = searchParams.get(PARAMS.sortBy);
  const lastReferred = searchParams.get("lastReferred") === "true";

  useEffect(() => {
    if (handler.state === MENU_ITEM[0].code) {
      const params: any = {
        page: +page,
        size: +size,
      };
      if (userId) params.userId = +userId;
      if (groupId) params.groupId = +groupId;
      if (sortType) params.sortType = sortType;
      if (sortBy) params.sortBy = sortBy;
      handler.getListEmployeeVerification(params);
    }
  }, [handler.state, page, size, userId, groupId, sortBy, sortType]);

  useEffect(() => {
    if (handler.isNotSubmit) {
      handler.formTab2.submit()
    }
  }, [handler.isNotSubmit]);

  useEffect(() => {
    if (shouldFetch && handler.state === MENU_ITEM[1].code) {
      const params: any = {
        lastReferred: lastReferred,
      };
      handler.getListCompanyEmployee(params);
      dispatch(setShouldFetch(false));
    }
  }, [lastReferred, shouldFetch, handler.isRefresh]);

  return (
    <>
      <MenuWrapperComponent chosenKey={94} openKey={9}>
        <div>
          <div className="om002-header-container">
            <BreadcrumbComponent data={BREADCRUMB_CCUS02} />

            <div className="header-row-title-ccus02">技能者情報閲覧</div>
          </div>

          <div className="container-tab-ccus06">
            <Layout style={{ minHeight: "85vh" }} className="open-side-ccus06">
              <Sider>
                <div className="row-infor-tab-ccus062">
                  <div className="ccus02-nav">
                    <ul className="am028-menu">
                      {MENU_ITEM.map((menu: { name: string; code: string }) => {
                        return (
                          <li
                            key={menu.code}
                            className={`am028-menu-item ${
                              menu.code == handler.state
                                ? "am028-menu-item-active"
                                : ""
                            }`}
                            onClick={() =>
                              handler.handleChangeMenuChosen(menu.code)
                            }
                          >
                            {menu.name}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <FilterComponent />
              </Sider>
              <Layout className="bg-fff">
                <Content className="content-table-ccus06">
                  <TableCCUS handler={handler} />
                </Content>
              </Layout>
            </Layout>
          </div>
        </div>
        {isOpenDetailCCUS061Modal ? <DetailCCUS061Modal/> : <></>}
      </MenuWrapperComponent>
    </>
  );
};

export default MainUI;
