import { Button, Form, Input, Tooltip } from "antd";
import { PM025HeaderHandler } from "../handler/HandlerImpl";

import IconSearch from "../../../../../assets/icons/search-icon.svg";
import PlusIcon from "../../../../../assets/icons/plus-full-white.svg";
import IconDelete from "../../../../../assets/icons/delete-red.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import {
  AUTHORIZATION_CODE,
  SYSTEM_ROLES,
} from "../../../../../common/constants/AUTHORIZATION";
import { TABS_KEY } from "../handler/Handler";

interface props {
  handler: PM025HeaderHandler;
}

const descriptionDeleteMulti = (
  <>
    <div>削除すると、復元できません。</div>
    <div>会社マスタを削除してもよろしいでしょうか？</div>
  </>
);

function PM025Header({ handler }: props) {
  const permission = useSelector(
    (state: RootState) => state.auth.authData?.permission
  );
  const isRoleAdmin = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdmin
  );

  const isRoleAdminBasic = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdminBasic
  );
  return (
    <div className="pm025-header">
      <h2 className="pm025-header-title">協力会社管理</h2>
      <div className="pm025-header-filter">
        <Form
          form={handler.formSearch}
          style={{
            width:
              permission?.includes(AUTHORIZATION_CODE.M6) || isRoleAdminBasic
                ? "100%"
                : "80%",
          }}
          name="pm025-search"
        >
          <Form.Item name={"keyword"} style={{ margin: 0 }}>
            <Input
              size="large"
              prefix={<img alt="" src={IconSearch} />}
              className="pm025-input-search"
              placeholder={
                "検索したい情報を入力してください（例：会社名、メールアドレス）"
              }
              onChange={handler.onSearchChange}
            />
          </Form.Item>
        </Form>

        <Tooltip
          title={
            handler.tab === TABS_KEY[0].key
              ? "勤怠管理と収支管理へデータ連携するための正規データとして参照されます"
              : "収支管理へデータ連携するための正規データとして参照されます"
          }
          overlayInnerStyle={{
            width: 254,
            // textAlign: handler.tab === TABS_KEY[2].key ? 'left' : 'center',
            textAlign: "center",
            fontSize: 12,
            fontWeight: 400,
            background: "#222222",
            borderRadius: 3,
            padding: 10,
          }}
          placement="bottomRight"
        >
          {permission?.includes(AUTHORIZATION_CODE.M6) || isRoleAdminBasic ? (
            <Button
              className="button-purple pm012-btn-flex pm025-btn-add"
              size="large"
              icon={<img alt="" src={PlusIcon} />}
              onClick={handler.handleOpenModalCompany}
            >
              マスタ追加
            </Button>
          ) : null}
        </Tooltip>

        {permission?.includes(AUTHORIZATION_CODE.M6) || isRoleAdminBasic ? (
          <Button
            className="button-warning pm012-btn-delete pm012-btn-flex"
            size="large"
            icon={<img alt="" src={IconDelete} />}
            onClick={() =>
              handler.handleDeleteMultiCompany(descriptionDeleteMulti)
            }
            disabled={handler.selectedRowKeys?.length === 0}
          >
            削除
          </Button>
        ) : null}
      </div>
    </div>
  );
}

export default PM025Header;
