import { ReqBodySchedule } from '../entity/Entity';
import { Params } from '../presenter/type/Presenter';
import { SM03Api } from './ServiceImpl';

const SM03Service = (sm03: SM03Api) => {
    const createSchedule = async (data: ReqBodySchedule): Promise<any> => {
        try {
            return await sm03.createSchedule(data);
        } catch (error) {
            throw error;
        }
    };

    const getListMember = async (params: any): Promise<any> => {
        try {
            return await sm03.getListMember(params);
        } catch (error) {
            throw error;
        }
    };

    const getDetailSchedule = async (params: { scheduleId: number }): Promise<any> => {
        try {
            return await sm03.getDetailSchedule(params);
        } catch (error) {
            throw error;
        }
    };

    const updateSchedule = async (data: ReqBodySchedule): Promise<any> => {
        try {
            return await sm03.updateSchedule(data);
        } catch (error) {
            throw error;
        }
    };

    const getListCategory = async (): Promise<any> => {
        try {
            return await sm03.getListCategory();
        } catch (error) {
            throw error;
        }
    };

    const getProjects = async (params: any): Promise<any> => {
        try {
            return await sm03.getProjects(params);
        } catch (error) {
            throw error;
        }
    };

    const getConstruction = async (params: any): Promise<any> => {
        try {
            return await sm03.getConstruction(params);
        } catch (error) {
            throw (error)
        }
    };

    const getDetailProject = async (params: any): Promise<any> => {
        try {
            return await sm03.getDetailProject(params);
        } catch (error) {
            throw (error)
        }
    };

    return {
        createSchedule,
        getListMember,
        getDetailSchedule,
        updateSchedule,
        getListCategory,
        getProjects,
        getConstruction,
        getDetailProject
    };
};

export default SM03Service;
