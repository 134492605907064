import { Button } from "antd";
import { type } from "os";
import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "../../../assets/styles/components/pdf.css";
const options = {
    standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
    cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
};
type props = {
    url: string;
    height: number;
    width: number;
    className?: string;
};


const PreviewPdfComponent = ({ height, width, url }: props) => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [pdfDimension, setPdfDimension] = useState<any>({
        height: undefined,
        width: undefined,
    });

    /*When document gets loaded successfully*/
    const onDocumentLoadSuccess = async (pdfObject: any) => {
        // If you want to get the dimension for page 1 (1-indexed)
        const page = await pdfObject.getPage(1);
        setNumPages(pdfObject.numPages);
        setPageNumber(1);

        let pdfWidth = page._pageInfo.view[2]
        let pdfHeight = page._pageInfo.view[3]

        if (Math.floor((width / pdfWidth) * pdfHeight) > height) {
            pdfWidth = undefined
            pdfHeight = height
        } else {
            pdfHeight = undefined
            pdfWidth = width
        }
        setPdfDimension({ width: pdfWidth, height: pdfHeight });
    }

    return (<div className="pdf-preview">
        <Document file={url} onLoadSuccess={onDocumentLoadSuccess} options={options}
        >
            <Page pageNumber={pageNumber} {...pdfDimension} />
        </Document>
    </div>
    );
}
export default PreviewPdfComponent;
