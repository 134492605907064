// Built-in
import React from 'react';

// 3rd party lib
import type { TableColumnsType } from 'antd';
import { Table } from 'antd';

// Source files
import { DateColumn } from '../../entity/Entity';
import AttendanceComponent from '../component/attendance';
import MemberRowComponent from './MemberRow';
import { getHeaderTitle } from '../../../AM008/presenter/ui/Table';
import helpers from '../../../../../common/helpers/common';
import { ConstractorMember } from '../../../AM004/entity/Entity';

// Image files
import ExpandedIconSvg from '../../../../../assets/icons/expanded-icon.svg';
import ClosedIconSvg from '../../../../../assets/icons/closed-icon.svg';

interface props {
  columnsTable: DateColumn[];
  contractorMembers: ConstractorMember[];
  contractorConstructionId: number;
  constructionId: number;
  expandKeyMember: any[];
  isCollapsedMember: (record: any) => boolean;
  onChangeCollapseRowMember: (record: any, collapsed: boolean) => void;
  onChoose: (value: any) => void;
  setExpandKeyMember: (value: any) => void;
}

const MemberTableComponent = ({
  columnsTable,
  contractorMembers,
  contractorConstructionId,
  constructionId,
  expandKeyMember,
  onChoose,
  setExpandKeyMember,
  isCollapsedMember,
  onChangeCollapseRowMember,
}: props) => {
  const getColumns = () => {
    const columns: TableColumnsType<any> = [
      {
        title: <div style={{ paddingLeft: 16 }}>日付</div>,
        key: 'date',
        width: '23%',
        onCell: (_, index) => ({
          colSpan: columnsTable.length + 3,
        }),
        render: (_, record, index) => (
          <MemberRowComponent index={index + 1} record={record} onChoose={onChoose} />
        ),
      },
    ];
    columns.push({
      title: '',
      key: 'prev',
      width: 29,
      onCell: (_, index) => ({
        colSpan: 0,
      }),
    });
    for (let i = 0; i < columnsTable.length; i++) {
      columns.push({
        title: getHeaderTitle(columnsTable[i]),
        key: i,
        width: `${77 / columnsTable.length}%`,
        onCell: (_, index) => ({
          colSpan: 0,
        }),
      });
    }
    columns.push({
      title: '',
      key: 'next',
      width: 70,
      onCell: (_, index) => ({
        colSpan: 0,
      }),
    });
    columns.push({
      title: '',
      key: 'collapsed',
      width: 40,
      render: (record) => {
        return isCollapsedMember(record) ? (
          <div
            style={{ cursor: 'pointer' }}
            onClick={(e: any) => onChangeCollapseRowMember(record, false)}
          >
            <img alt="" src={ExpandedIconSvg} />
          </div>
        ) : (
          <div
            style={{ cursor: 'pointer' }}
            onClick={(e: any) => onChangeCollapseRowMember(record, true)}
          >
            <img alt="" src={ClosedIconSvg} />
          </div>
        );
      },
    });
    return columns;
  };

  const getSearchMembers = (contractorMembers: any[], keyword: string) => {
    const memberDataSource = helpers.addKeyToDataSource(contractorMembers);
    const memberKey = memberDataSource.map((element) => ({
      ...element,
      key: element.key + '' + contractorConstructionId,
    }));
    const searchMembers = memberKey.filter((element) =>
      helpers
        .toASCII(element.fullName || '')
        .toUpperCase()
        .includes(helpers.toASCII(keyword || '').toUpperCase()),
    );
    return searchMembers;
  };
  const dataSource = getSearchMembers(contractorMembers, '')

  return (
    <Table
      onRow={(record, rowIndex) => {
        return {
          onClick: (event) =>
            isCollapsedMember(record)
              ? onChangeCollapseRowMember(record, false)
              : onChangeCollapseRowMember(record, true), // click row
        };
      }}
      rowClassName={() => {
        return 'row-company button-style';
      }}
      id="main-table"
      className={`am-main-table-constractor-member  attendance-table 
        ${dataSource.length === 0 ? 'table-is-empty' : ''}`}
      columns={getColumns()}
      dataSource={dataSource}
      pagination={false}
      expandable={{
        expandedRowClassName: () => {
          return 'no-table-cell-padding';
        },
        showExpandColumn: false,
        expandedRowKeys: expandKeyMember,
        onExpandedRowsChange: (value) => setExpandKeyMember(value),
        expandedRowRender: (record: any) => (
          <AttendanceComponent
            attendance={record.attendance}
            contractorConstructionId={contractorConstructionId}
            userId={record.id}
            constructionId={constructionId}
            columnsTable={columnsTable}
          />
        ),
      }}
    />
  );
};

export default MemberTableComponent;
