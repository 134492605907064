import { DB003SliceType } from "./../type/Presenter";
import { createSlice } from "@reduxjs/toolkit";

const initialState: DB003SliceType = {
  isVisibleDB003: false,
  isRefresh: false,
  projectId: null,
};

export const db003Slice = createSlice({
  name: "db003",
  initialState,
  reducers: {
    resetDB003: () => initialState,
    setVisibleDB003: (state, action) => {
      state.isVisibleDB003 = action.payload;
    },
    setParamsDB003: (state, action) => {
      const { projectId, from, to } = action.payload;
      state.projectId = projectId;
      state.from = from;
      state.to = to;
    },
  },
});

export const { resetDB003, setVisibleDB003, setParamsDB003 } =
  db003Slice.actions;

export default db003Slice.reducer;
