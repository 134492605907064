import axiosConfig from "../../../../../config/axiosConfig";
  
  const AM027Api = () => {
    const deleteRequest = async (data:any): Promise<any> => {
      try {
        const url = "/request/delete";
        const response = await axiosConfig.delete(url, {data});
        return response?.data;
      } catch (error) {
        throw error;
      }
    };
  
    return {
      deleteRequest,
    };
  };
  
  export default AM027Api;
  