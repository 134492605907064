import { Collapse } from 'antd';
import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store';
import { SubConstructionType } from '../../entity/Entity';
import { CollapseContractorHandler } from '../handler/HandlerImpl';
import FirstLevelSvg from '../../../../../../../assets/icons/first-level.svg';
import IconDown from '../../../../../../../assets/icons/arrow.svg';
import { Construction } from '../../../../entity/Entity';
import MemberCheckBoxComponent from '../../../member/MemberCheckbox';
import MemberPreviewComponent from '../../../member/MemberPreview';
const { Panel } = Collapse;

type Props = {
    handler: CollapseContractorHandler;
    subConstruction: SubConstructionType;
    construction: Construction;
};

export const LEVEL_COLOR: any = {
    LEVEL1: {
        color: '#418FEB',
        padding: 0,
        background: 'rgba(65, 143, 235, 0.12)',
    },
    LEVEL2: {
        color: '#33CC99',
        padding: 32,
        background: 'rgba(51, 204, 153, 0.12)',
    },
    LEVEL3: {
        color: '#FF9861',
        padding: 32 * 2,
        background: 'rgba(255, 152, 97, 0.12)',
    },
    LEVEL4: {
        color: '#FF5555',
        padding: 32 * 3,
        background: 'rgba(255, 85, 85, 0.12)',
    },
    LEVEL5: {
        color: '#33CC99',
        padding: 32 * 4,
        background: 'rgba(51, 204, 153, 0.12)',
    },
};

const getDirectoryIcon = (color: string) => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17.875 13.2913L12.375 18.7913L11.0733 17.4897L14.3642 14.208H4.125V3.20801H5.95833V12.3747H14.3642L11.0733 9.09301L12.375 7.79134L17.875 13.2913Z"
            fill={color ?? 'transparent'}
        />
    </svg>
);

const CollapseChildrenComponent = ({ subConstruction, handler, construction }: Props) => {
    const {
        members,
        chosenMembers,
        activeKey,
        setAllCheckBox,
        onOpenCollapse,
        onChooseCheckbox,
        onCompanyOpen,
        getListMember,
        onPreview,
    } = handler;

    const { id, shortDescription, treeLevel, company } = subConstruction;

    const isCheckAll = useSelector((state: RootState) => state.pm011.isCheckAll);
    const eventCheckAll = useSelector((state: RootState) => state.pm011.eventCheckAll);
    const isUpdating = useSelector((state: RootState) => state.pm011.isUpdating);
    const isOpenPM011 = useSelector((state: RootState) => state.pm011.isOpenPM011);
    const constructionCompany = useSelector((state: RootState) => state.pm011.constructionCompany);

    useEffect(() => {
        if (!isUpdating) {
            onPreview(`contractor${subConstruction.id + '' + subConstruction.treeLevel}`, {
                listUserId: chosenMembers,
                subConstructionId: subConstruction.childConstructionId,
                constructionId: construction.id,
            });
        }
    }, [isUpdating]);

    useEffect(() => {
        setAllCheckBox(!isCheckAll);
    }, [eventCheckAll, isOpenPM011]);

    useEffect(() => {
        (async () => {
            await onCompanyOpen(construction.id);
        })();
    }, [JSON.stringify(constructionCompany)]);

    useEffect(() => {
        (async () => {
            await getListMember(construction.id);
        })();
    }, [construction.id]);

    return (
        <Collapse
            className="folder-collapse pm011-collapse-item"
            onChange={onOpenCollapse}
            activeKey={activeKey}
            expandIcon={({ isActive }) => {
                return (
                    <div className={`${isActive ? 'collapse-icon-active' : ''} collapse-icon`}>
                        <img src={IconDown} alt="" />
                    </div>
                );
            }}
        >
            <Panel
                forceRender={true}
                collapsible={handler.members.length === 0 ? 'disabled' : undefined}
                className="pm011-panel "
                key={id + '' + treeLevel}
                header={
                    <div
                        className="pm011-panel-header-title w-100-percent"
                        // onClick={(e) => e.stopPropagation()}
                        style={{
                            paddingLeft: `calc(16px + ${LEVEL_COLOR[`LEVEL${treeLevel}`].padding
                                }px)`,
                            width: '100%',
                        }}
                    >
                        <div className="pm011-panel-header-title-left">
                            <div className="pm011-panel-header-icon">
                                {treeLevel === 1 ? (
                                    <img alt="" src={FirstLevelSvg} />
                                ) : (
                                    getDirectoryIcon(LEVEL_COLOR[`LEVEL${treeLevel}`].color)
                                )}
                            </div>
                            <span className="pm011-panel-header-item-text">
                                {company.name}({shortDescription})
                            </span>
                        </div>
                        <div className="pm011-panel-header-title-right">
                            人数：{handler.members.length}人
                        </div>
                    </div>
                }
            >
                {isUpdating ? (
                    <MemberCheckBoxComponent
                        chosenMembers={chosenMembers}
                        members={members}
                        onChooseCheckbox={onChooseCheckbox}
                    />
                ) : (
                    <MemberPreviewComponent
                        chosenMembers={chosenMembers}
                        name={`contractor${id + '' + treeLevel}`}
                        isSubConstruction={true}
                    />
                )}
            </Panel>
        </Collapse>
    );
};

export default CollapseChildrenComponent