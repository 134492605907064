// Library
import { Button, Tabs } from 'antd';

// Components
import { CM001ElementComponent } from './CM001ElementComponent';
import { CM002ElementComponent } from './CM002ElementComponent';

// Icons
import UploadLightSVG from '../../../../../assets/icons/upload-icon-purple.svg';

// Handler
import { CM001Handler } from '../handler/HandlerImpl';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { AUTHORIZATION_CODE, SYSTEM_ROLES } from '../../../../../common/constants/AUTHORIZATION';
import { useSearchParams } from 'react-router-dom';
import { PROJECT_PARAM_ID_KEY } from '../handler/Handler';

// Common

type props = {
    handler: CM001Handler;
    searchParams: string;
    projectName: string;
    rangeDate: string;
    tab: string;
};

const CM001ContentComponent = ({ handler, searchParams, projectName, rangeDate, tab }: props) => {
    const [urlSearchParams] = useSearchParams();
    const projectId: string = urlSearchParams.get(PROJECT_PARAM_ID_KEY) ?? '0';
    const permission = useSelector((state: RootState) => state.auth.authData?.permission);
    const isRoleAdmin = useSelector((state: RootState) => state.auth?.authData?.isRoleAdmin)

    const isRoleAdminSystem =
        useSelector((state: RootState) => state.auth?.authData?.isRoleAdminSystem)

    return (
        <>
            <div className="cm001-content-tab">
                <div className="tab">
                    <Tabs activeKey={handler.tab} onChange={handler.chooseTabView}>
                        <Tabs.TabPane tab="案件" key="1"></Tabs.TabPane>
                        <Tabs.TabPane tab="工事別" key="2"></Tabs.TabPane>
                    </Tabs>
                </div>
                {permission?.includes(AUTHORIZATION_CODE.B9) || isRoleAdmin || isRoleAdminSystem ? (
                    <Button
                        className="button-brown-3"
                        style={{ border: '1px solid #605BFF', width: 106 }}
                        size="large"
                        onClick={() => handler.exportData(tab, projectName, rangeDate, projectId)}
                    >
                        <div className="center-item">
                            <img
                                alt=""
                                src={UploadLightSVG}
                                style={{
                                    marginRight: 8,
                                    fontWeight: 700,
                                    fontSize: 14,
                                }}
                            />
                            <div
                                className="export-button"
                                style={{ color: '#605BFF' }}
                            >
                                出力
                            </div>
                        </div>
                    </Button>
                ) : (
                    <div></div>
                )}
            </div>
            {tab === '1' ? (
                <CM001ElementComponent handler={handler} searchValueParams={searchParams} />
            ) : (
                <></>
            )}

            {tab === '2' ? (
                <CM002ElementComponent handler={handler} searchValueParams={searchParams} />
            ) : (
                <></>
            )}
        </>
    );
};

export { CM001ContentComponent };
