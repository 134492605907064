import { SliceType } from "./../type/Presenter";
import { createSlice } from "@reduxjs/toolkit";

const initialState: SliceType = {
  loading: false,
  isVisible: false,
  orderId: null,
  refresh: false,
};

export const PM028Slice = createSlice({
  name: "PM028",
  initialState,
  reducers: {
    resetPM028: () => initialState,
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsVisiblePM028: (state, action) => {
      state.isVisible = action.payload;
    },
    setOrderIdPM028: (state, action) => {
      state.orderId = action.payload;
      state.isVisible = true;
    },
    setRefreshPM028: (state) => {
      state.refresh = !state.refresh;
    },
  },
});

export const {
  setLoading,
  setIsVisiblePM028,
  resetPM028,
  setOrderIdPM028,
  setRefreshPM028,
} = PM028Slice.actions;

export default PM028Slice.reducer;
