import { Button, Modal, Radio, Space, Spin } from 'antd';
import { OM009Hanlder } from '../handler/HandlerImpl';

import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import LoadingComponent from '../../../../../common/components/icons/LoadingComponent';

import '../../../../../assets/styles/OM/OM002.css';
import '../../../../../assets/styles/OM/OM009.css';
import '../../../../../assets/styles/OM/OM007.css';
import '../../../../../assets/styles/OM/OM004.css';
import OMHeaderModalComponent from '../../../../../common/components/om-header-modal/OMHeaderModalComponent';

interface props {
  handler: OM009Hanlder;
}

const OM009UI = ({ handler }: props) => {
  const isVisible = useSelector((state: RootState) => state.om009.isVisible);
  const loading = useSelector((state: RootState) => state.om009.loading);

  return !isVisible ? null : (
    <Modal
      style={{ marginLeft: 300, marginTop: 40 }}
      className="group-detail-modal no-margin-modal"
      visible={true}
      maskClosable={false}
      width={996}
      centered={true}
      closable={false}
      footer={
        <>
          <Button
            size="large"
            disabled={loading}
            type="primary"
            danger
            style={{ marginLeft: 16, width: 76, letterSpacing: -1.5 }}
            onClick={handler.onDelete}
          >
            削除
          </Button>
          <Button
            size="large"
            disabled={loading}
            style={{
              marginLeft: 16,
              width: 118,
              border: '1px solid #E9EDF2',
            }}
            onClick={handler.onCancel}
          >
            キャンセル
          </Button>
        </>
      }
    >
      <Spin indicator={LoadingComponent} spinning={loading}>
        <OMHeaderModalComponent title="削除確認" onCancel={handler.onCancel} />
        <div
          className=" w-100-percent"
          style={{ minHeight: 120, paddingLeft: 71, marginTop: 24 }}
        >
          <Radio.Group
            value={handler.type + ''}
            onChange={handler.onTypeChange}
          >
            <Space direction="vertical">
              <Radio value="option1" style={{ marginBottom: 8 }}>
                <div className="radio-label">
                  部門と部門の全メンバーを削除する
                </div>
              </Radio>
              <Radio value="option2">
                <div className="radio-label">
                  部門を削除し、部門の全メンバーを未定部門に移動する
                </div>
              </Radio>
            </Space>
          </Radio.Group>
        </div>
      </Spin>
    </Modal>
  );
};

export default OM009UI;
