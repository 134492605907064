import {
  Button,
  Checkbox,
  DatePicker,
  Empty,
  Form,
  Input,
  Modal,
  Select,
  Spin,
  Tag,
  TimePicker,
  Tooltip,
} from "antd";
import locale from "antd/es/date-picker/locale/ja_JP";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import CalendarSvg from "../../../../../assets/icons/calendar.svg";
import CloseIconModal from "../../../../../assets/icons/close.png";
import closeIcon from "../../../../../assets/icons/close.svg";
import { default as XIconModal } from "../../../../../assets/icons/x-icon-modal.svg";
import "../../../../../assets/styles/SM/SM03.css";
import LoadingComponent from "../../../../../common/components/icons/LoadingComponent";
import COMMON from "../../../../../common/constants/COMMON";
import MESSAGE from "../../../../../common/constants/MESSAGE";
import REGEX from "../../../../../common/constants/REGEX";
import helpers from "../../../../../common/helpers/common";
import { RootState } from "../../../../../store";
import { SM03Hanlder } from "../handler/HandlerImpl";
import { Guest } from "../type/Presenter";
import CloseIcon from "../../../../../assets/icons/x-icon-modal.svg";
import { Moment } from 'moment';

type Props = {
  handler: SM03Hanlder;
};

const { Option } = Select;

const SM03UI = ({ handler }: Props) => {
  const formRef: any = useRef();
  const isVisible = useSelector((state: RootState) => state.sm03.isVisible);
  const loading = useSelector((state: RootState) => state.sm03.loading);
  const typeModal = useSelector((state: RootState) => state.sm03.typeModal);
  const scheduleId = useSelector((state: RootState) => state.sm03.scheduleId);

  useEffect(() => {
    if (scheduleId && typeModal) handler.getDetailSchedule(scheduleId);
    handler.getListCategory();
  }, []);

  useEffect(() => {
    if (handler.isDirty) {
      window.addEventListener("beforeunload", helpers.alertUser);
      return () => {
        window.removeEventListener("beforeunload", helpers.alertUser);
      };
    } else {
      window.removeEventListener("beforeunload", helpers.alertUser, true);
    }
  }, [handler.isDirty]);

  useEffect(() => {
    if (!typeModal) {
      handler.handleGetConstructionFirstOpenModal({
        page: COMMON.DEFAULT_PAGE,
        size: COMMON.DEFAULT_SIZE,
        keyword: undefined,
      });
    }
  }, []);

  useEffect(() => {
    if (!typeModal) {
      handler.handleGetProjectFirstOpenModal({
        page: COMMON.DEFAULT_PAGE,
        size: COMMON.DEFAULT_SIZE,
      });
    }
  }, []);

  useEffect(() => {
    if (handler?.dataDetailSchedule?.id && scheduleId && typeModal) {
      handler.handleFillFormUpdate();
      handler.getProject(
        {
          page: COMMON.DEFAULT_PAGE,
          size: COMMON.DEFAULT_SIZE,
          keyword: undefined,
          scheduleId,
        },
        handler?.dataDetailSchedule?.id
      );
    }
  }, [handler?.dataDetailSchedule?.id]);

  return (
    <>
      <Modal
        className="sm03-modal"
        style={{ marginLeft: 300, marginTop: 40, marginBottom: 40 }}
        visible={isVisible}
        closable={false}
        maskClosable={false}
        width={"1072px"}
        centered={true}
        closeIcon={<img src={CloseIconModal} alt="close icon" />}
        footer={
          <>
            <Button
              disabled={loading}
              size="large"
              type="primary"
              style={{ marginLeft: 16, width: 76, letterSpacing: -1.5 }}
              onClick={() => handler.form.submit()}
            >
              保存
            </Button>
            <Button
              className="sm03-cancel-btn"
              disabled={loading}
              size="large"
              style={{ marginLeft: 16, width: 118, letterSpacing: -1.5 }}
              onClick={handler.onCancel}
            >
              キャンセル
            </Button>
          </>
        }
      >
        <Spin indicator={LoadingComponent} spinning={loading}>
          <div
            className="w-100-percent header-modal-containter"
            style={{ height: 50 }}
          >
            <div className="modal-title">
              {typeModal ? "イベント編集" : "イベント作成"}
            </div>
            <div style={{ cursor: "pointer" }} onClick={handler.onCancel}>
              <img alt="" src={XIconModal} style={{ height: 18, width: 18 }} />
            </div>
          </div>
          <div
            style={{ minHeight: 224, marginTop: 24 }}
            className="sm03-content"
          >
            <Form
              onValuesChange={handler.checkDirty}
              form={handler.form}
              id="formSM03"
              layout="horizontal"
              autoComplete="off"
              onFinish={handler.submitForm}
            >
              <Form.Item
                style={{ marginBottom: 16 }}
                name="title"
                colon={false}
                className="form-item-label"
                label={
                  <div
                    className="pr-20 form-label-member"
                    style={{ width: 66, marginRight: 18 }}
                  >
                    タイトル<span style={{ color: "#FE0909" }}>*</span>
                  </div>
                }
                rules={[
                  { required: true, message: MESSAGE.MESSAGE_REQUIRED_FIELD },
                  {
                    pattern: new RegExp(REGEX.EXCEPT_VIETNAMESE),
                    message: MESSAGE.MESSAGE_INVALID_INPUT,
                  },
                ]}
              >
                <Input
                  style={{ height: 40, width: 920 }}
                  maxLength={200}
                  placeholder="イベント名を入力してください" // CR #344212
                  onBlur={(value: any) =>
                    handler.trimValueInput(value, "title")
                  }
                />
              </Form.Item>

              <Form.Item
                style={{ marginBottom: 16 }}
                name="category"
                colon={false}
                className="form-item-label"
                label={
                  <div
                    className="pr-20 form-label-member"
                    style={{ width: 66, marginRight: 18 }}
                  >
                    カテゴリー
                  </div>
                }
              >
                <Select
                  style={{ width: 920 }}
                  getPopupContainer={(trigger: any) => trigger.parentNode}
                  className="sm03-select-category"
                >
                  {handler.listCategory.map((option: any) => (
                    <Select.Option key={option.id} value={option.id}>
                      <div
                        style={{
                          display: "flex",
                          gap: 8,
                          alignItems: "center",
                        }}
                      >
                        <div
                          className="sm04-circle"
                          style={{
                            backgroundColor: option?.color?.colorRgb,
                            width: 24,
                            height: 24,
                          }}
                        ></div>
                        <div>{option.name}</div>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                style={{ marginBottom: 16 }}
                name="project"
                labelAlign="right"
                colon={false}
                className="form-item-label"
                label={
                  <div
                    className="form-label-member"
                    style={{ width: 66, marginRight: 18 }}
                  >
                    案件名
                  </div>
                }
              >
                <Select
                  showArrow
                  showSearch
                  listHeight={120}
                  filterOption={() => true}
                  loading={handler.loadingOptionProject}
                  onSearch={handler.handleSearchOptionProject}
                  getPopupContainer={(trigger: any) => trigger.parentNode}
                  onPopupScroll={handler.handleScrollProject}
                  placeholder="案件を選択"
                  notFoundContent={
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description="該当なデータはありません"
                    />
                  }
                  onSelect={(e: any) => {
                    handler.form.setFieldValue("construction", null);
                    handler.resetProjectWhenSelectOrBlur(e);
                    handler.handleFindConstructions(e);
                  }}
                  style={{ width: 920 }}
                  allowClear
                >
                  {handler.projects.map((select) => {
                    return (
                      <Option
                        key={select.id}
                        value={select.id}
                        disabled={select?.isMemberDeleted}
                      >
                        <div
                          style={{
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#121212",
                          }}
                        >
                          {select?.name}
                        </div>
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                style={{ marginBottom: 16 }}
                name="construction"
                labelAlign="right"
                colon={false}
                className="form-item-label"
                label={
                  <div
                    className="form-label-member"
                    style={{ width: 66, marginRight: 18 }}
                  >
                    工事名
                  </div>
                }
              >
                <Select
                  className="am010__header-select"
                  showArrow
                  showSearch
                  listHeight={120}
                  filterOption={() => true}
                  onSearch={handler.handleSearchOptionConstruction}
                  onPopupScroll={handler.handleScrollConstruction}
                  loading={handler.loadingOption}
                  getPopupContainer={(trigger: any) => trigger.parentNode}
                  placeholder="工事を選択"
                  tagRender={({ label, closable, onClose }: any) => {
                    return (
                      <Tag
                        closable={closable}
                        onClose={onClose}
                        color="rgba(231, 230, 255, 1)"
                        closeIcon={
                          <img
                            style={{ width: 10, marginLeft: 6 }}
                            alt=""
                            src={CloseIcon}
                          />
                        }
                        style={{
                          margin: "2px 4px 2px 0",
                          display: "flex",
                          alignItems: "center",
                          height: 28,
                          borderRadius: 4,
                          color: "#222222",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        {label[0]?.props?.children}
                      </Tag>
                    );
                  }}
                  notFoundContent={
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description="該当なデータはありません"
                    />
                  }
                  onSelect={(e: any) => {
                    handler.resetConstructionWhenSelectOrBlur(e);
                    handler.handleFindProjects(e);
                  }}
                  style={{ width: 920 }}
                  allowClear
                >
                  {handler.constructions.map((select) => {
                    return (
                      <Option
                        style={{ alignItems: "center" }}
                        key={select.id}
                        value={select.id}
                        disabled={select.isMemberDeleted}
                      >
                        <div
                          style={{
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#121212",
                          }}
                        >
                          {select?.name}
                        </div>
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <div className="content-date-time">
                <Form.Item
                  style={{ marginBottom: 16 }}
                  name="date"
                  colon={false}
                  className="form-item-label"
                  label={
                    <div
                      className="form-label-member"
                      style={{ width: 66, marginRight: 18 }}
                    >
                      日付<span style={{ color: "#FE0909" }}>*</span>
                    </div>
                  }
                  rules={[
                    { required: true, message: MESSAGE.MESSAGE_REQUIRED_FIELD },
                  ]}
                >
                  <DatePicker.RangePicker
                    format={COMMON.FORMAT_DATE_CI}
                    className="ci001-date-picker cr003-date-picker sm03-date-picker"
                    placeholder={["", ""]}
                    getPopupContainer={(trigger: any) => trigger.parentNode}
                    inputReadOnly
                    locale={locale}
                    separator={<span className="calender-separator">-</span>}
                    suffixIcon={
                      <img alt="" src={CalendarSvg} className="icon-calender" />
                    }
                    placement="bottomLeft"
                    onChange={handler.onRangePickerChange}
                  />
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: 16, marginLeft: 63 }}
                  name="isFullDay"
                  colon={false}
                  className="form-item-label checkbox"
                  label={
                    <div className="w-100-percent pr-20 form-label-member"></div>
                  }
                >
                  <Checkbox
                    checked={handler?.disableTime}
                    onChange={(e) => handler.handleCheckbox(e.target.checked)}
                  >
                    終日
                  </Checkbox>
                </Form.Item>

                <div
                  className="start-end-time"
                  style={{
                    display: "flex",
                  }}
                >
                  <Form.Item
                    style={{ marginBottom: 16 }}
                    name="startTime"
                    colon={false}
                    className="form-item-label"
                    label={
                      <div
                        className="form-label-member"
                        style={{ width: 66, marginRight: 18 }}
                      >
                        時間<span style={{ color: "#FE0909" }}>*</span>
                      </div>
                    }
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!handler.disableTime) {
                            if (!value) return Promise.reject(
                              MESSAGE.MESSAGE_REQUIRED_FIELD
                            );

                            const endTime: Moment = handler.form.getFieldValue("endTime");
                            const startTime: Moment = value

                            let [startDate, endDate] = handler.form.getFieldValue("date") ?? []

                            if (((!startDate && !endDate) || startDate.isSame(endDate, 'day')) &&
                              endTime &&
                              startTime.isAfter(endTime)) {
                              return Promise.reject(
                                MESSAGE.MESSAGE_START_END_TIME_ERROR
                              );
                            }
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <TimePicker
                      style={{
                        width: 438,
                        height: 40,
                      }}
                      format={COMMON.FORMAT_TIME1}
                      placeholder={""}
                      disabled={handler?.disableTime}
                      onChange={() => {
                        if (handler.form.getFieldValue("endTime"))
                          handler.form.validateFields(["endTime"]);
                      }}
                      locale={locale}
                      popupClassName={"sm003-popup-timmer"}
                    />
                  </Form.Item>

                  <span
                    style={{
                      width: 44,
                      height: 40,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    ~
                  </span>

                  <Form.Item
                    style={{ marginBottom: 16 }}
                    name="endTime"
                    colon={false}
                    className="form-item-label"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!handler.disableTime) {
                            if (!value) return Promise.reject(
                              MESSAGE.MESSAGE_REQUIRED_FIELD
                            );

                            const endTime: Moment = value;
                            const startTime: Moment = handler.form.getFieldValue("startTime")

                            let [startDate, endDate] = handler.form.getFieldValue("date") ?? []

                            if (((!startDate && !endDate) || startDate.isSame(endDate, 'day')) &&
                              startTime &&
                              startTime.isAfter(endTime)) {
                              return Promise.reject(
                                MESSAGE.MESSAGE_START_END_TIME_ERROR
                              );
                            }
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <TimePicker
                      style={{ width: 438, height: 40 }}
                      format={COMMON.FORMAT_TIME1}
                      placeholder={""}
                      disabled={handler?.disableTime}
                      onChange={(e: any) => {
                        if (handler.form.getFieldValue("startTime"))
                          handler.form.validateFields(["startTime"]);
                      }}
                      locale={locale}
                      popupClassName={"sm003-popup-timmer"}
                    />
                  </Form.Item>
                </div>
              </div>

              <Form.Item
                style={{ marginBottom: 16 }}
                name="guest"
                colon={false}
                className="form-item-label "
                label={
                  <div
                    className="form-label-member"
                    style={{ width: 66, marginRight: 18 }}
                  >
                    参加者
                  </div>
                }
              >
                <Select
                  showArrow
                  showSearch
                  mode="multiple"
                  placeholder="名前またはメールアドレスを入力してください" // CR #344212
                  style={{ width: 920, height: 40 }}
                  listHeight={220}
                  filterOption={() => true}
                  defaultActiveFirstOption={false}
                  getPopupContainer={(trigger: any) => trigger.parentNode}
                  notFoundContent={
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description="該当なデータはありません"
                    />
                  }
                  tagRender={({ label, value, closable, onClose }: any) => {
                    return <></>;
                  }}
                  loading={handler.loadingOption}
                  onDropdownVisibleChange={(open) => {
                    if (open) handler.handleGetMember();
                  }}
                  onPopupScroll={handler.handleScrollMember}
                  onSearch={handler.handleSearchOption}
                  onSelect={handler.onSelect}
                  onDeselect={handler.onDeSelect}
                >
                  {handler.memberDeputyProjectManager.map((select) => {
                    return (
                      <Select.Option
                        style={{ alignItems: "center" }}
                        key={select.id}
                        value={select.id}
                      >
                        <div
                          style={{
                            fontSize: 14,
                            fontWeight: 600,
                            color: "#121212",
                          }}
                        >
                          {select.fullName || select.username}
                        </div>
                        <span
                          style={{
                            fontSize: 12,
                            fontWeight: 400,
                            color: "#666",
                          }}
                        >
                          {select.emailAddress}
                        </span>
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <div className="list-guest">
                {handler?.listGuest?.map((item: Guest) => {
                  const idOwner = handler.form.getFieldValue("owner");
                  if (idOwner === item.id) return null;
                  return (
                    <div className="guest-item">
                      <Tooltip placement="bottomLeft" title={item?.name}>
                        <span className="guest-text">
                          {helpers.formatTextLong(item?.name, 20)}
                        </span>
                      </Tooltip>

                      <img
                        alt=""
                        className="close-icon"
                        src={closeIcon}
                        onClick={() => handler.removeSelect(item.id)}
                      />
                    </div>
                  );
                })}
              </div>
              <Form.Item
                style={{ marginBottom: 16 }}
                name="notification"
                colon={false}
                className="form-item-label sm03-textarea"
                label={
                  <div
                    className="form-label-member"
                    style={{ width: 66, marginRight: 18 }}
                  >
                    連絡事項 <span style={{ color: "#FE0909" }}></span>
                  </div>
                }
                rules={[
                  {
                    pattern: new RegExp(REGEX.EXCEPT_VIETNAMESE),
                    message: MESSAGE.MESSAGE_INVALID_INPUT,
                  },
                ]}
              >
                <TextArea
                  rows={4}
                  style={{ resize: "none", marginBottom: 3, width: 920 }}
                  maxLength={3000}
                  onBlur={(value: any) =>
                    handler.trimValueInput(value, "notification")
                  }
                />
              </Form.Item>
              <Form.Item
                style={{ marginBottom: 16 }}
                name="problem"
                colon={false}
                className="form-item-label sm03-textarea"
                label={
                  <div
                    className="form-label-member"
                    style={{ width: 66, marginRight: 18 }}
                  >
                    問題点 <span style={{ color: "#FE0909" }}></span>
                  </div>
                }
                rules={[
                  {
                    pattern: new RegExp(REGEX.EXCEPT_VIETNAMESE),
                    message: MESSAGE.MESSAGE_INVALID_INPUT,
                  },
                ]}
              >
                <TextArea
                  rows={4}
                  style={{ resize: "none", marginBottom: 3, width: 920 }}
                  maxLength={3000}
                  onBlur={(value: any) =>
                    handler.trimValueInput(value, "problem")
                  }
                />
              </Form.Item>
              <Form.Item
                style={{ marginBottom: 16 }}
                name="note"
                colon={false}
                className="form-item-label sm03-textarea"
                label={
                  <div
                    className="form-label-member"
                    style={{ width: 66, marginRight: 18 }}
                  >
                    説明 <span style={{ color: "#FE0909" }}></span>
                  </div>
                }
                rules={[
                  {
                    pattern: new RegExp(REGEX.EXCEPT_VIETNAMESE),
                    message: MESSAGE.MESSAGE_INVALID_INPUT,
                  },
                ]}
              >
                <TextArea
                  rows={4}
                  style={{ resize: "none", marginBottom: 3, width: 920 }}
                  maxLength={3000}
                  onBlur={(value: any) => handler.trimValueInput(value, "note")}
                />
              </Form.Item>
            </Form>
          </div>
        </Spin>
      </Modal>
    </>
  );
};

export default SM03UI;
