import { useDispatch } from 'react-redux';
import { useState } from 'react';

import { AttendanceServiceImpl } from './../../usecase/ServiceImpl';
import { openModalStreetMap } from '../../../../../common/components/open-street-map/Slice';

const AttendanceHandler = (service: AttendanceServiceImpl) => {
    const dispatch = useDispatch();
    const [dataSource, setDataSource] = useState<any[]>([]);
    const [attendance, setAttendance] = useState<any>();
    const getAttendanceSubConstructionMemberTimeSheet = async (params: {
        constructionId: number;
        userId: number;
        contractorConstructionId: number;
        from: string; // 2006-01-02
        to: string; // 2006-01-02
    }): Promise<any> => {
        const { results } = await service.getAttendanceSubConstructionMemberTimeSheet(params);
        setAttendance(results);
    };

    const openStreetMap = (lat: any, lng: any) => {
        dispatch(
            openModalStreetMap({
                lat: lat,
                lng: lng,
            }),
        );
    };

    return {
        attendance,
        dataSource,
        setDataSource,
        setAttendance,
        getAttendanceSubConstructionMemberTimeSheet,
        openStreetMap,
    };
};

export default AttendanceHandler;
