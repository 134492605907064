import { useEffect, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  setParamsDB003,
  setVisibleDB003,
} from "../../../DB003/presenter/slice/Slice";
import {
  setParamsDB004,
  setVisibleDB004,
} from "../../../DB004/presenter/slice/Slice";
import {
  setDataPopupTableDB007,
  setInforProject,
  setVisibleDB007,
} from "../../../DB007/presenter/slice/Slice";
import {
  setDataProjectDB002,
  setVisibleDB002,
} from "../../../DB002/presenter/slice/Slice";
import { useState } from "react";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import { DB001UseCase } from "../../usecase/ServiceImpl";
import {
  convertDataTable,
  convertGraph3,
  convertGraph4,
  convertKeysBudgetChart,
  convertKeysChartTransactionVolume,
  convertLineChart,
  convertPieChart,
} from "../../helpers";
import { useSearchParams } from "react-router-dom";
import helpers from "../../../../../common/helpers/common";
import moment from "moment";
import { PAGE_PARAM } from "../../../../ORD/ORD001/contants";
import COMMON from "../../../../../common/constants/COMMON";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import { Form } from "antd";
import {
  setCompanyName,
  setDataPopupTableDB006,
  setVisibleDB006,
} from "../../../DB006/presenter/slice/Slice";

const DB003: string = "DB003",
  DB004: string = "DB004",
  DB007: string = "DB007",
  DB006: string = "DB006",
  DB002: string = "DB002";

export const DEFAULT_MAX_SLIDER = 100;
export const DEFAULT_MIN_SLIDER = 10;
export const DEFAULT_SIZE = 6;

export const PARAM_COMPANY_ID = "companyId";
export const PARAM_START_DATE = "startDate";
export const PARAM_END_DATE = "endDate";
export const PARAM_START_DATE_PROJECT = "startDateProject";
export const PARAM_END_DATE_PROJECT = "endDateProject";

export const BUDGET_KEY = {
  materialCost: "材料費",
  outsourceCost: "外注費",
  laborCost: "労務費",
  indirectCost: "間接工事費",
};

export const PARAMS_FILTER_MODE = "filterMode";
export const PARAMS_PROJECT = "projectId";
export const PARAMS_PROJECT_NAME = "projectName";
export const PARAMS_PROJECT_FROM = "constructionFromDate";

export const createRangeArrayNumber = (
  start: number,
  stop: number,
  step: number
) =>
  Array.from(
    { length: (stop - start) / step + 1 },
    (_, index) => start + index * step
  );

const DB001Handler = (service: DB001UseCase) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [turnOver, setTurnOver] = useState<any[]>([]);
  const [manOfDays, setManOfDays] = useState<any[]>([
    {
      id: "稼働人工数",
      data: [],
    },
  ]);
  const [employeesAndOperatingCosts, setEmployeesAndOperatingCosts] =
    useState<any>([]);
  const [
    employeesOperatingAndOperatingCosts,
    setEmployeesOperatingAndOperatingCosts,
  ] = useState<any[]>([]);
  const [partnerCompany, setPartnerCompany] = useState<any[]>([]);
  const [selectedPartnerCompanyId, setSelectedPartnerCompanyId] = useState<
    number | null
  >(null);
  const selectedPartnerCompanyRealtime =
    partnerCompany.find((item: any) => item.id === selectedPartnerCompanyId)
      ?.id ||
    partnerCompany[0]?.id ||
    null;
  const [budget, setBudget] = useState<any[]>([]);
  const [mapperGraph4, setMapperGraph4] = useState<any>({});
  const [titleGraph2, setTitleGraph2] = useState<string>("");
  const [titleGraph3, setTitleGraph3] = useState<string>("");
  const [titleGraph4, setTitleGraph4] = useState<any>({});
  const [dataTable, setDataTable] = useState<any[]>([]);
  const [keysChartTransactionVolume, setkeysChartTransactionVolume] = useState<
    any[]
  >([]);
  const [keysBudgetChart, setKeysBudgetChart] = useState<any[]>([]);
  const [budgetIsNull, setBudgetIsNull] = useState<boolean>(false);
  const [maxSlider, setMaxSlider] = useState<number>(DEFAULT_MAX_SLIDER);
  const [minSlider, steMinSlider] = useState<number>(DEFAULT_MIN_SLIDER);
  const [maxBudget, setMaxBudget] = useState<number>(0);
  const [maxTransactionVolume, setMaxTransactionVolume] = useState<number>(0);
  const [totalRecord, setTotalRecord] = useState<number>(0);

  const updateSelectedPartnerCompany = (id: number | null) => {
    setSelectedPartnerCompanyId(id);
  };

  const openPopupDetail = (type: string, data: any) => {
    switch (type) {
      case DB002:
        dispatch(setDataProjectDB002(data));
        dispatch(setVisibleDB002(true));
        break;
      case DB003:
        dispatch(setParamsDB003(data));
        dispatch(setVisibleDB003(true));
        break;
      case DB004:
        dispatch(setParamsDB004(data.projectId));
        dispatch(setVisibleDB004(true));
        break;
      case DB007:
        dispatch(
          setInforProject({
            projectName: data.name,
            constructionFromDate: data.constructionFromDate,
          })
        );
        dispatch(setVisibleDB007(true));
        break;
      case DB006:
        dispatch(setVisibleDB006(true));
        const companyName =
          partnerCompany?.find(
            (company: any) => company.id === selectedPartnerCompanyRealtime
          )?.name || null;
        dispatch(setCompanyName(companyName));
        break;
    }
  };

  const getFilterModeLabel = (id: string, arr: any[]) => {
    const foundMode = arr.find((element) => element.id + "" === id);
    return foundMode?.name ?? arr[0].name;
  };

  const getTurnOver = async (params: any) => {
    try {
      const response = await service.getTurnOverAmount({ ...params });
      setTurnOver(convertPieChart(response.results));
      setTitleGraph2(response.results?.projectName ?? "");
    } catch (error: any) {
      ErrorNotification(error?.message ?? "");
    }
  };

  const transferDataForComposeChart = (
    data: any,
    params: { from: string; to: string }
  ) => {
    if (!data?.length) {
      return [];
    }
    const { from: fromParams, to: toParams } = params;
    const from = moment(fromParams).format(COMMON.FORMAT_DATE4)
    const to = moment(toParams).format(COMMON.FORMAT_DATE4)
    const completeData = [...data];
    const expectData: any[] = [];

    const startYear = +from.split("-")[0];
    const startMonth = +from.split("-")[1];

    const endYear = +to.split("-")[0];
    const endMonth = +to.split("-")[1];

    const rangeYearArr = createRangeArrayNumber(startYear, endYear, 1);
    const allYearsFromResponse =
      data?.map((yearData: any) => yearData.year) || [];

    rangeYearArr.forEach((year: number) => {
      if (!allYearsFromResponse.includes(year)) {
        completeData.push({
          year,
          months: [],
        });
      }
    });

    completeData.forEach((item: any) => {
      let monthsData = [...item.months] || [];
      let monthsMustHaveData = createRangeArrayNumber(1, 12, 1);
      if(startYear === endYear) monthsMustHaveData = createRangeArrayNumber(startMonth, endMonth, 1)
      else if(item.year === startYear) monthsMustHaveData = createRangeArrayNumber(startMonth, 12, 1);
      else if(item.year === endYear) monthsMustHaveData = createRangeArrayNumber(1, endMonth, 1);
      // get all month data of year exists data
      const currentMonthHaveData: number[] = monthsData.map(
        (monthData: any) => monthData.month
      );

      // add months if missing
      monthsMustHaveData.forEach((month: number) => {
        if (!currentMonthHaveData.includes(month)) {
          monthsData.push({
            month: month,
            laborCost: 0,
            workingDay: 0,
          });
        }
      });

      monthsData.forEach((month: any) => {
        expectData.push({
          year: item.year,
          ...month,
        });
      });
    });

    const sortedEmployeesAndOperatingCosts = expectData.sort(
      (a: any, b: any) => {
        // create date by year, month, fist day of month and compare
        return (
          new Date(a.year, a.month, 1).getTime() -
          new Date(b.year, b.month, 1).getTime()
        );
      }
    );

    return sortedEmployeesAndOperatingCosts
  };

  const getEmployeesAndOperatingCosts = async (params: any) => {
    try {
      dispatch(setLoading(true));
      const {from, projectId, to} = params
      const response = await service.getEmployeesAndOperatingCosts({
        from,
        projectId,
        to
      });
      if (response?.results) {
        const data = response.results;
        const dataTransform = transferDataForComposeChart(data, {
          from: params.initDatePayload.from,
          to: params.initDatePayload.to,
        })
        const finalConvertArr = dataTransform.map((item) => ({...item, month: `${item.year}-${item.month}`}))
        setEmployeesAndOperatingCosts(finalConvertArr);
        dispatch(
          setDataPopupTableDB007({
            rawData: data,
            parserData: transferDataForComposeChart(data, {
              from,
              to,
            }),
            from: params.initDatePayload.from,
            to: params.initDatePayload.to,
          })
        );
      }
    } catch (error) {
      if (error instanceof Error) ErrorNotification(error?.message ?? "");
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getPartnerCompany = async (params: any) => {
    try {
      dispatch(setLoading(true));
      const response = await service.getPartnerCompany({ ...params });
      const data = response.results;
      setPartnerCompany(data);
    } catch (error) {
      if (error instanceof Error) ErrorNotification(error?.message ?? "");
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getEmployeesOperatingAndOperatingCosts = async (params: any) => {
    try {
      dispatch(setLoading(true));
      if (!params.companyId) {
        setEmployeesOperatingAndOperatingCosts([]);
      } else {
        const {from, projectId, to, companyId} = params
        const response = await service.getEmployeesOperatingAndOperatingCosts(
          {
            from,
            projectId,
            to,
            companyId
          }
        );
        if (response?.results) {
          const data = response.results;
          const dataTransform =  transferDataForComposeChart(data, {
            from: params.initDatePayload.from,
            to: params.initDatePayload.to,
          })
          const finalConvertArr = dataTransform.map((item) => ({...item, month: `${item.year}-${item.month}`}))
          setEmployeesOperatingAndOperatingCosts(finalConvertArr);
          dispatch(
            setDataPopupTableDB006({
              rawData: data,
              parserData: transferDataForComposeChart(data, {
                from,
                to
              }),
              from: params.initDatePayload.from,
              to: params.initDatePayload.to,
            })
          );
        }
      }
    } catch (error) {
      if (error instanceof Error) ErrorNotification(error?.message ?? "");
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getManOfDays = async (params: any) => {
    try {
      let index = 0;
      let start = true;
      let data: any[] = [];

      do {
        const response = await service.getManDay({
          ...params,
          page: index + 1,
        });
        setTitleGraph4({
          name: response.results?.name ?? "",
          constructionFromDate: response.results?.constructionFromDate ?? "",
        });

        if (response.results && response.results.length !== 0)
          data = [...data, ...response.results];
        else start = false;

        index++;
      } while (start);
      setManOfDays(convertLineChart(data));
    } catch (error: any) {
      ErrorNotification(error?.message ?? "");
    }
  };

  const getBudgetData = async (params: any) => {
    try {
      const response = await service.getBudgetUtil({ ...params });
      setTitleGraph3(response.results?.projectName ?? "");
      setBudget(convertGraph3(response.results, setBudgetIsNull, setMaxBudget));
      setKeysBudgetChart(
        convertKeysBudgetChart(
          convertGraph3(
            response.results,
            () => {},
            () => {}
          )[0]
        )
      );
    } catch (error: any) {
      ErrorNotification(error?.message ?? "");
    }
  };

  const getDataTable = async (params: any) => {
    try {
      const filterMode = searchParams.get(PARAMS_FILTER_MODE) ?? "0";
      dispatch(setLoading(true));
      const response = await service.getTransactionVolume({
        page: params.page,
        size: params.size,
        ... filterMode === "1" && {
          from: helpers.getFilterTime(params.from),
          to: helpers.getFilterTimeTo(params.to)
        }
      });
      const responseData = response?.results ?? [];
      setTotalRecord(response?.pagination?.count ?? 0);
      setDataTable(convertDataTable(responseData, setMaxTransactionVolume));
      if (responseData.length > 0 && !params.projectId) {
        let projectId = 0;
        let projectName = "";
        let fromDate = "";
        if (Number(params.projectId) === 0 && filterMode === "0") {
          projectId = responseData[0].projectId;
          projectName = responseData[0].projectName;
          const responseFromDate = responseData[0].constructionFromDate
          fromDate = moment(responseFromDate).startOf("month").format(COMMON.FORMAT_DATE_TIME)
          searchParams.set(PARAMS_PROJECT, String(projectId));
          searchParams.set(PARAMS_PROJECT_NAME, projectName);
          searchParams.set(PARAM_START_DATE, fromDate);
          searchParams.set(PARAM_START_DATE_PROJECT, fromDate);

          if(filterMode === "0") {
            const endDateProject = moment().endOf("month").format(COMMON.FORMAT_DATE);
            searchParams.set(PARAM_END_DATE_PROJECT, endDateProject);
          }

          setSearchParams(searchParams);
        }
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? "");
    } finally {
      dispatch(setLoading(false));
    }
  };

  const changeSlider = (step: number) => {
    setMaxSlider(DEFAULT_MAX_SLIDER + step);
    steMinSlider(DEFAULT_MIN_SLIDER + step);
  };

  const chooseProject = (project: any) => {
    searchParams.set(PARAMS_PROJECT, String(project.projectId));
    searchParams.set(PARAMS_PROJECT_NAME, project.projectName);


    searchParams.set(PARAM_START_DATE_PROJECT, project.constructionFromDate);

    const filterMode = searchParams.get(PARAMS_FILTER_MODE) ?? "0";
    if(filterMode === "0") {
      searchParams.set(PARAM_START_DATE, moment(project.constructionFromDate).startOf("month").format(COMMON.FORMAT_DATE2));
    }

    setSearchParams(searchParams);
  };

  const clearParams = () => {
    searchParams.delete(PARAMS_PROJECT);
    setSearchParams(searchParams);
  };
  const onPageChange = (page: number) => {
    searchParams.delete(PARAMS_PROJECT);
    searchParams.set(PAGE_PARAM, `${page}`);
    setSearchParams(searchParams);
  };

  
  const onDateFilterChange = (open: boolean) => {
    if (open) return;
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(function () {
      const filterDate = form.getFieldValue("filterDate");
      searchParams.set(
        "startDate",
        moment(filterDate[0]).startOf("month").format(COMMON.FORMAT_DATE)
      );
      searchParams.set(
        "endDate",
        moment(filterDate[1]).endOf("month").format(COMMON.FORMAT_DATE)
      );
      setSearchParams(searchParams);
    }, 1000);
  };

  useEffect(() => {
    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current)
    }
  }, [])

  const initiateFilter = (fromDate: string, toDate: string) => {
    form.setFieldValue("filterDate", [moment(fromDate), moment(toDate)]);
  };

  const chooseDropdown = (value: any) => {
    if (value.key === "0") {
      searchParams.set(
        PARAM_START_DATE,
        searchParams.get(PARAM_START_DATE_PROJECT) ?? ""
      );
      searchParams.set(
        PARAM_END_DATE,
        searchParams.get(PARAM_END_DATE_PROJECT) ?? ""
      );
    } else {
      const fromDate = moment().startOf("year").format(COMMON.FORMAT_DATE);
      const toDate = moment().endOf("month").format(COMMON.FORMAT_DATE);
      searchParams.set(PARAM_START_DATE, fromDate);
      searchParams.set(PARAM_END_DATE, toDate);

      initiateFilter(fromDate, toDate);
    }

    searchParams.set(PARAMS_FILTER_MODE, value.key + "");
    setSearchParams(searchParams);
  };

  return {
    turnOver,
    budget,
    titleGraph2,
    titleGraph3,
    titleGraph4,
    manOfDays,
    employeesAndOperatingCosts,
    employeesOperatingAndOperatingCosts,
    mapperGraph4,
    dataTable,
    keysChartTransactionVolume,
    keysBudgetChart,
    maxSlider,
    minSlider,
    budgetIsNull,
    maxBudget,
    maxTransactionVolume,
    totalRecord,
    partnerCompany,
    selectedPartnerCompanyRealtime,
    form,
    updateSelectedPartnerCompany,
    chooseProject,
    clearParams,
    openPopupDetail,
    getTurnOver,
    getManOfDays,
    getEmployeesAndOperatingCosts,
    getEmployeesOperatingAndOperatingCosts,
    getPartnerCompany,
    getDataTable,
    changeSlider,
    onPageChange,
    onDateFilterChange,
    initiateFilter,
    getFilterModeLabel,
    chooseDropdown,
    getBudgetData,
  };
};

export default DB001Handler;
