import ErrorNotification from "../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../common/constants/MESSAGE";
import {
  RetrieveMaterialDocument,
  WorkflowResponseData,
} from "../entity/Entity";
import { CM008Api } from "./ServiceImpl";

const CM008Service = (cm008: CM008Api) => {
  const getListSubmittedDirectCost = async (params: any): Promise<any> => {
    try {
      return await cm008.getListSubmittedDirectCost(params);
    } catch (error) {
      throw error;
    }
  };

  const retrieveMaterialDocument = async (
    data: RetrieveMaterialDocument
  ): Promise<any> => {
    try {
      const response = await cm008.retrieveMaterialDocument(data);
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      throw error;
    }
  };

  const getProjectSummaryReceivedMoney = async (params: any): Promise<any> => {
    try {
      const response = await cm008.getProjectSummaryReceivedMoney(params);
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      throw error;
    }
  };

  const getConstructionSummaryCostInformation = async (
    params: any
  ): Promise<any> => {
    try {
      const response = await cm008.getConstructionSummaryCostInformation(
        params
      );
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      throw error;
    }
  };

  const getMaterialListCompany = async (params: any): Promise<any> => {
    try {
      const response = await cm008.getMaterialListCompany(params);
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      throw error;
    }
  };

  const getMaterialList = async (params: any): Promise<any> => {
    try {
      const response = await cm008.getMaterialList(params);
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      throw error;
    }
  };

  const getPresignLinkS3 = async (params: any): Promise<any> => {
    try {
      const response = await cm008.getPresignLinkS3(params);
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      throw error;
    }
  };
  const getWorkflowMaterialApprovalProcess = async (params: {
    documentId: number;
  }): Promise<WorkflowResponseData> => {
    try {
      const response = await cm008.getWorkflowMaterialApprovalProcess(params);
      return response.data;
    } catch (error: any) {
      throw error;
    }
  };

  return {
    getListSubmittedDirectCost,
    retrieveMaterialDocument,
    getProjectSummaryReceivedMoney,
    getConstructionSummaryCostInformation,
    getMaterialListCompany,
    getMaterialList,
    getPresignLinkS3,
    getWorkflowMaterialApprovalProcess,
  };
};

export default CM008Service;
