import ErrorNotification from "../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../common/constants/MESSAGE";
import { WorkflowResponseData } from "../../CM008/entity/Entity";
import { RetrieveOutsourceDocument } from "../entity/Entity";
import { CM0010Api } from "./ServiceImpl";

const CM0010Service = (api: CM0010Api) => {
  const getListSubmittedDirectCostOutsource = async (
    params: any
  ): Promise<any> => {
    try {
      return await api.getListSubmittedDirectCostOutsource(params);
    } catch (error) {
      throw error;
    }
  };

  const getPresignLinkS3 = async (params: any): Promise<any> => {
    try {
      const response = await api.getPresignLinkS3(params);
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      throw error;
    }
  };

  const retrieveOutsourceDocument = async (
    data: RetrieveOutsourceDocument
  ): Promise<any> => {
    try {
      const response = await api.retrieveOutsourceDocument(data);
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      throw error;
    }
  };

  const getMoneyDirectCostOutsourceInformationList = async (
    params: any
  ): Promise<any> => {
    try {
      return await api.getMoneyDirectCostOutsourceInformationList(params);
    } catch (error) {
      throw error;
    }
  };
  const getMoneyDirectCostOutsourceList = async (params: any): Promise<any> => {
    try {
      return await api.getMoneyDirectCostOutsourceList(params);
    } catch (error) {
      throw error;
    }
  };
  const getWorkflowOutsourceApprovalProcess = async (params: {
    documentId: number;
  }): Promise<WorkflowResponseData> => {
    try {
      const response = await api.getWorkflowOutsourceApprovalProcess(params);
      return response.data;
    } catch (error: any) {
      throw error;
    }
  };
  return {
    getListSubmittedDirectCostOutsource,
    getPresignLinkS3,
    retrieveOutsourceDocument,
    getMoneyDirectCostOutsourceInformationList,
    getMoneyDirectCostOutsourceList,
    getWorkflowOutsourceApprovalProcess,
  };
};

export default CM0010Service;
