import React, { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { HandlerImpl } from '../handler/HandlerImpl';
import BreadcrumbComponent from '../../../../../common/components/breadcrumb/BreadcrumbComponent';
import { Button, Form, Layout } from 'antd';
import UploadLightSVG from '../../../../../assets/icons/upload-icon-purple.svg';
import HomeSvg from '../../../../../assets/icons/home.svg';
import ConstractorTableComponent from './ConstractorTable';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import MenuWrapperComponent from '../../../../../common/components/menu-wrapper';
import FilterSearchComponent from './FilterSearch';
import moment from 'moment';
import COMMON from '../../../../../common/constants/COMMON';
import { getTitleByFromToDate } from '../../../AM008/helper';
import helpers from '../../../../../common/helpers/common';
import AM042Component from '../../../AM042';
import SearchBarComponent from '../../../AM008/presenter/component/filter/ui/SearchBar';
import { OpenStreetMapComponent } from '../../../../../common/components/open-street-map/OpenStreetMapComponent';
import { AUTHORIZATION_CODE, SYSTEM_ROLES } from '../../../../../common/constants/AUTHORIZATION';

interface props {
    handler: HandlerImpl;
}
const { Content, Sider } = Layout;

const BREADCRUMB_AM004: any[] = [
    {
        title: '勤怠管理',
        url: '',
        icon: <img style={{ paddingBottom: 2, paddingRight: 8 }} alt="" src={HomeSvg} />,
    },
    {
        title: '会社別勤務実績',
        url: '/app-am002',
        icon: '',
    },
    {
        title: '工事詳細勤務実績',
        url: '',
        icon: '',
    },
];

const BREADCRUMB_AM007 = (params: string) => {
    return [
        {
            title: '勤怠管理',
            url: '',
            icon: <img style={{ paddingBottom: 2, paddingRight: 8 }} alt="" src={HomeSvg} />,
        },
        {
            title: '案件別勤務実績',
            url: '/app-am005',
            icon: '',
        },
        {
            title: '会社詳細勤務実績',
            url: '',
            icon: '',
        },
    ];
};

const MainUI = ({ handler }: props) => {
    // Selector

    const tableColumns = useSelector((state: RootState) => state.am004.tableColumns);
    const isVisibleStreetMap = useSelector((state: RootState) => state.openStreetMap.isVisible);

    // Params
    const { constructionId, contractorConstructionId } = useParams();
    const [searchParams] = useSearchParams();
    const fromDate =
        searchParams.get('startDate') || moment().startOf('month').format(COMMON.FORMAT_DATE2);
    const toDate = searchParams.get('endDate') || moment().format(COMMON.FORMAT_DATE2);
    const pageDate = searchParams.get('pageDate');
    const title = searchParams.get('title');
    const companyName = searchParams.get('selectedCompanyName');
    const keyword = searchParams.get('keyword') || '';
    const isCollapsed = searchParams.get('isCollapsed') ? true : false;
    const preParams = searchParams.get('preParams') || '';

    const isRoleAdmin = useSelector((state: RootState) => state.auth?.authData?.isRoleAdmin)

    const isRoleAdminSystem =
        useSelector((state: RootState) => state.auth?.authData?.isRoleAdminSystem)

    const permission = useSelector((state: RootState) => state.auth.authData?.permission);
    useEffect(() => {
        handler.initiateFilter();
    }, []);

    useEffect(() => {
        handler.calculateTableHeader(fromDate, toDate);
    }, [fromDate, toDate, pageDate]);

    useEffect(() => {
        (async () => {
            const param: any = {
                from: fromDate,
                to: toDate
            };
            if (constructionId) param.constructionId = parseInt(constructionId);
            if (contractorConstructionId)
                param.contractorConstructionId = parseInt(contractorConstructionId);

            await handler.getAttendanceSubConstructionListMember(param);
        })();
    }, [fromDate, toDate,]);
    return (
        <>
            <MenuWrapperComponent
                chosenKey={window.location.pathname.includes('am004') ? 22 : 23}
                openKey={2}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'end',
                    }}
                >
                    <div className="om002-header-container">
                        <BreadcrumbComponent
                            data={
                                window.location.pathname.includes('004')
                                    ? BREADCRUMB_AM004
                                    : BREADCRUMB_AM007(preParams)
                            }
                        />

                        <div className="header-row-title" style={{ marginTop: 8 }}>
                            {window.location.pathname.includes('004')
                                ? '工事詳細勤務実績'
                                : '会社詳細勤務実績'}
                        </div>
                    </div>
                    <div>
                        <Form form={handler.form}>
                            <SearchBarComponent onSearch={handler.onSearch} width={1082} responsiveRate={1.4} />
                        </Form>
                    </div>
                    {permission?.includes(AUTHORIZATION_CODE.B9) || isRoleAdmin || isRoleAdminSystem ? (
                        <Button
                            className="button-brown-3"
                            style={{ border: '1px solid #605BFF', width: 106 }}
                            size="large"
                            onClick={() => handler.exportData({
                                from: fromDate,
                                to: toDate,
                                constructionId: constructionId || '',
                                contractorConstructionId: contractorConstructionId || '',
                                title,
                                companyName,
                            })}
                        >
                            <div className="center-item">
                                <img
                                    alt=""
                                    src={UploadLightSVG}
                                    style={{
                                        marginRight: 8,
                                        fontWeight: 700,
                                        fontSize: 14,
                                    }}
                                />
                                <div
                                    className="export-button"
                                    style={{ color: '#605BFF' }}
                                >
                                    出力
                                </div>
                            </div>
                        </Button>
                    ) : (
                        <></>
                    )}
                </div>
                <Layout
                    style={{ minHeight: '84vh', marginTop: 24 }}
                    className={isCollapsed ? 'close-side' : 'open-side'}
                >
                    <Sider>
                        <FilterSearchComponent
                            onFromDateChange={handler.onFromDateChange}
                            onToDateChange={handler.onToDateChange}
                            rangeDate={handler.rangeDate}
                            onFilter={handler.onFilter}
                            onResetFilter={handler.onResetFilter}
                            onCollapse={handler.onCollapse}
                            onChange={handler.onSearch}
                            isCollapsed={isCollapsed}
                            form={handler.form}
                        />
                    </Sider>
                    <Layout style={{ backgroundColor: '#ffffff' }}>
                        <p className="construction-name">{title}</p>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginLeft: 24,
                                marginTop: 16,
                                marginBottom: 18,
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div className="total-money-style" style={{ marginRight: 16, minWidth: 250 }}>
                                    合計金額：{helpers.formatCurrency(handler.totalMoney)}円
                                </div>
                                <div className="total-money-style">
                                    合計稼働人工数：{helpers.formatCurrency(handler.totalWorkingDay)}人工
                                </div>
                            </div>

                            <div
                                className="date-label"
                                style={{ textAlign: 'right' }}
                            >
                                {getTitleByFromToDate(fromDate, toDate)}
                            </div>
                        </div>
                        <Content
                            style={{ margin: '0px 24px 16px 24px', backgroundColor: '#ffffff' }}
                        >
                            <ConstractorTableComponent
                                isCollapsed={handler.isCollapsed}
                                isCollapsedMember={handler.isCollapsedMember}
                                onChangeCollapseRow={handler.onChangeCollapseRow}
                                onChangeCollapseRowMember={handler.onChangeCollapseRowMember}
                                expandKeyMember={handler.expandKeyMember}
                                setExpandKeyMember={handler.setExpandKeyMember}
                                constructionId={parseInt(constructionId || '')}
                                onNext={handler.onNext}
                                onPrev={handler.onPrev}
                                setExpandKey={handler.setExpandKey}
                                dataSource={handler.listConstractor}
                                columnsTable={tableColumns.columns}
                                isPrev={tableColumns.isPrev}
                                isNext={tableColumns.isNext}
                                expandKey={handler.expandKey}
                            />
                        </Content>
                    </Layout>
                </Layout>
            </MenuWrapperComponent>
            <AM042Component />
            {isVisibleStreetMap ? <OpenStreetMapComponent /> : <></>}
        </>
    );
};

export default MainUI;
