import { AxiosResponse } from "axios";
import axiosConfig from "../../../../../config/axiosConfig";
import { LoginBodyData } from "../../entity/Entity";

const ASLoginApi = () => {
  const login = async (data: LoginBodyData): Promise<any> => {
    const url = "/auth/login";
    try {
      const response = await axiosConfig.post(url, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const sendOTP = async (): Promise<any> => {
    const url = "/auth/changePassword/otp";
    try {
      const response = await axiosConfig.post(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  const getUserIsReWorkflow = async (): Promise<boolean> => {
    const url = "/user/is-re-workflow";
    try {
      const response = await axiosConfig.get(url);
      return response.data?.results ? true : false;
    } catch (error) {
      throw error;
    }
  };
  const getUserIsDoubleCheck = async (): Promise<boolean> => {
    const url = "/user/is-double-check";
    try {
      const response = await axiosConfig.get(url);
      return response.data.results ? true : false;
    } catch (error) {
      throw error;
    }
  };
  const getProjectCheckUserInWorkspace = async (): Promise<boolean> => {
    const url = "/project/check-user-in-workspace";
    try {
      const response = await axiosConfig.get(url);
      return response.data.results ? true : false;
    } catch (error) {
      throw error;
    }
  };
  return {
    login,
    sendOTP,
    getUserIsReWorkflow,
    getUserIsDoubleCheck,
    getProjectCheckUserInWorkspace,
  };
};

export default ASLoginApi;
