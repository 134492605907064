import React, { useEffect, useRef, useState } from "react";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import moment from "moment";
import COMMON, { TAX_CODE } from "../../../../../common/constants/COMMON";

import helpers from "../../../../../common/helpers/common";
import {
  OffsetCost,
  ReSummaryCompanySummaryOutsourceCostDetailResult,
  ReSummaryCompanySummaryOutsourceCostTotalResult,
} from "../../../CM0017/entity/Entity";
import EyeViewDetailSvg from "../../../../../assets/icons/eye-view-detail.svg";
import TooltipText from "../../../../../common/components/tooltip-text/TooltipText";

type props = {
  openCM0012_2: (value: any) => void;
  data: ReSummaryCompanySummaryOutsourceCostDetailResult[];
  summaryTotal: ReSummaryCompanySummaryOutsourceCostTotalResult;
  from?: string;
  to?: string;
  companyName?: string;
  targetPeriod?: string;
};
export const TABLE_CELL_WIDTH_CM0017: number[] = [
  100, 174, 174, 180, 200, 174, 200, 174, 174, 150, 174, 174, 174, 174, 174,
  174, 174,
];

const TableBelowComponent = ({
  data,
  summaryTotal,
  companyName,
  targetPeriod,
  openCM0012_2,
}: props) => {
  const columns: ColumnsType<any> = [
    {
      title: "No",
      key: "No",
      align: "center",
      width: TABLE_CELL_WIDTH_CM0017[0],
      render: (_, __, index) => {
        return (
          <span className="collapsed-table-row-style">{`${index + 1}`}</span>
        );
      },
    },
    {
      title: "案件名",
      key: "案件名",
      align: "center",
      width: TABLE_CELL_WIDTH_CM0017[1],
      render: (record: ReSummaryCompanySummaryOutsourceCostDetailResult) => {
        return (
          <span className="collapsed-table-row-style">
            <TooltipText content={record?.construction?.projectname ?? ""} />
          </span>
        );
      },
    },
    {
      title: "工事名",
      key: "工事名",
      align: "center",
      width: TABLE_CELL_WIDTH_CM0017[2],
      render: (record: ReSummaryCompanySummaryOutsourceCostDetailResult) => {
        return (
          <span className="collapsed-table-row-style">
            <TooltipText content={record?.construction?.name ?? ""} />
          </span> // api hasn't returned
        );
      },
    },
    {
      title: "契約工事名",
      key: "契約工事名",
      align: "center",
      width: TABLE_CELL_WIDTH_CM0017[3],
      render: (record: ReSummaryCompanySummaryOutsourceCostDetailResult) => {
        return (
          <span className="collapsed-table-row-style">
            <TooltipText content={record?.contractorConstructionName ?? ""} />
          </span>
        );
      },
    },
    {
      title: "支払日",
      key: "支払日",
      align: "center",
      width: TABLE_CELL_WIDTH_CM0017[4],
      render: (record: ReSummaryCompanySummaryOutsourceCostDetailResult) => {
        return (
          <span className="collapsed-table-row-style">{`${moment(
            record.paymentDate
          ).format(COMMON.FORMAT_DATE_OM)}`}</span>
        );
      },
    },
    {
      title: "契約金額",
      key: "契約金額",
      align: "center",
      width: TABLE_CELL_WIDTH_CM0017[5],
      render: (record: ReSummaryCompanySummaryOutsourceCostDetailResult) => {
        return (
          <span className="collapsed-table-row-style">{`${helpers.formatNumberMoney(
            record.orderMoney
          )}円`}</span>
        );
      },
    },
    {
      title: "前回迄出来高",
      key: "前回迄出来高",
      align: "center",
      width: TABLE_CELL_WIDTH_CM0017[6],
      render: (record: ReSummaryCompanySummaryOutsourceCostDetailResult) => {
        return (
          <span className="collapsed-table-row-style">{`${helpers.formatNumberMoney(
            record.paidCost
          )}円`}</span>
        );
      },
    },
    {
      title: "今回出来高",
      key: "今回出来高",
      align: "center",
      width: TABLE_CELL_WIDTH_CM0017[7],
      render: (record: ReSummaryCompanySummaryOutsourceCostDetailResult) => {
        return (
          <span className="collapsed-table-row-style">{`${helpers.formatNumberMoney(
            record.actualCost
          )}円`}</span>
        );
      },
    },

    {
      title: "今回保留金額",
      key: "今回保留金額",
      align: "center",
      width: TABLE_CELL_WIDTH_CM0017[8],
      render: (record: ReSummaryCompanySummaryOutsourceCostDetailResult) => {
        return (
          <span className="collapsed-table-row-style">{`${helpers.formatNumberMoney(
            record.reserveCost
          )}円`}</span>
        );
      },
    },
    {
      title: "消費税額",
      key: "消費税額",
      align: "center",
      width: TABLE_CELL_WIDTH_CM0017[9],
      render: (record: ReSummaryCompanySummaryOutsourceCostDetailResult) => {
        return (
          <span className="collapsed-table-row-style">{`${helpers.formatNumberMoney(
            record.tax
          )}円`}</span>
        );
      },
    },
    {
      title: "今回支払金額",
      key: "今回支払金額",
      align: "center",
      width: TABLE_CELL_WIDTH_CM0017[10],
      render: (record: ReSummaryCompanySummaryOutsourceCostDetailResult) => {
        return (
          <span className="collapsed-table-row-style">{`${helpers.formatNumberMoney(
            record.cost
          )}円`}</span>
        );
      },
    },
    {
      title: "相殺金額",
      key: "相殺金額",
      align: "center",
      children: [
        {
          title: "課税",
          key: "課税",
          align: "center",
          width: TABLE_CELL_WIDTH_CM0017[11],
          render: (
            record: ReSummaryCompanySummaryOutsourceCostDetailResult
          ) => {
            const offsetCostWithTax = record?.offsetCost?.find(
              (element: OffsetCost) => element.category.code === TAX_CODE.TAX
            );

            return (
              <main className="center-item">
                <div className="collapsed-table-row-style">
                  {`${helpers.formatNumberMoney(
                    offsetCostWithTax?.value ?? 0
                  )}円`}
                </div>
                <div
                  style={{ marginLeft: 10 }}
                  className="button-style"
                  onClick={() =>
                    openCM0012_2({
                      ...record,
                      categoryId: offsetCostWithTax?.category.id,
                      targetPeriod: targetPeriod,
                      companyName: companyName,
                    })
                  }
                >
                  <img alt="" src={EyeViewDetailSvg} />
                </div>
              </main>
            );
          },
        },
        {
          title: "消費税",
          key: "消費税",
          align: "center",
          width: TABLE_CELL_WIDTH_CM0017[12],
          render: (
            record: ReSummaryCompanySummaryOutsourceCostDetailResult
          ) => {
            return (
              <span className="collapsed-table-row-style">{`${helpers.formatNumberMoney(
                record.consumptionTax
              )}円`}</span>
            );
          },
        },
        {
          title: "非課税",
          key: "非課税",
          align: "center",
          width: TABLE_CELL_WIDTH_CM0017[13],
          render: (
            record: ReSummaryCompanySummaryOutsourceCostDetailResult
          ) => {
            const offsetCostWithoutTax = record?.offsetCost?.find(
              (element: OffsetCost) =>
                element.category.code === TAX_CODE.TAX_FREE
            );

            return (
              <main className="center-item">
                <div className="collapsed-table-row-style">
                  {`${helpers.formatNumberMoney(
                    offsetCostWithoutTax?.value ?? 0
                  )}円`}
                </div>
                <div
                  style={{ marginLeft: 10 }}
                  className="button-style"
                  onClick={() =>
                    openCM0012_2({
                      ...record,
                      categoryId: offsetCostWithoutTax?.category.id,
                      targetPeriod: targetPeriod,
                      companyName: companyName,
                    })
                  }
                >
                  <img alt="" src={EyeViewDetailSvg} />
                </div>
              </main>
            );
          },
        },
      ],
    },
    {
      title: "差引支払額",
      key: "差引支払額",
      align: "center",
      width: TABLE_CELL_WIDTH_CM0017[14],
      render: (record: ReSummaryCompanySummaryOutsourceCostDetailResult) => {
        return (
          <span className="collapsed-table-row-style">{`${helpers.formatNumberMoney(
            record.deductionCost
          )}円`}</span>
        );
      },
    },
  ];

  return (
    <Table
      bordered={true}
      columns={columns}
      dataSource={helpers.addKeyToDataSource(data)}
      className="collapsed-table-header table-footer-inside summary-border-purple"
      pagination={false}
      scroll={{
        y: 600,
      }}
      summary={() => {
        const {
          actualCost,
          consumptionTax,
          cost,
          deductionCost,
          offsetCostWithTax,
          offsetCostWithoutTax,
          paidCost,
          planCost,
          reserveCost,
          tax,
        } = summaryTotal;
        return (
          <Table.Summary fixed="bottom">
            <Table.Summary.Row
              className="parent-table-summary"
              style={{ background: "#605BFF" }}
            >
              <Table.Summary.Cell index={0}>合計金額</Table.Summary.Cell>
              <Table.Summary.Cell index={1}></Table.Summary.Cell>
              <Table.Summary.Cell index={2}> </Table.Summary.Cell>
              <Table.Summary.Cell index={3}> </Table.Summary.Cell>
              <Table.Summary.Cell index={4}> </Table.Summary.Cell>
              <Table.Summary.Cell index={5}>
                <span className="center-item white-space-nowrap">{`${helpers.formatNumberMoney(
                  planCost
                )}円`}</span>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={6}>
                <span className="center-item white-space-nowrap">{`${helpers.formatNumberMoney(
                  paidCost
                )}円`}</span>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={7}>
                <span className="center-item white-space-nowrap">{`${helpers.formatNumberMoney(
                  actualCost
                )}円`}</span>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={8}>
                <span className="center-item white-space-nowrap">{`${helpers.formatNumberMoney(
                  reserveCost
                )}円`}</span>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={9}>
                <span className="center-item white-space-nowrap">{`${helpers.formatNumberMoney(
                  tax
                )}円`}</span>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={10}>
                <span className="center-item white-space-nowrap">{`${helpers.formatNumberMoney(
                  cost
                )}円`}</span>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={11}>
                <span className="center-item white-space-nowrap">{`${helpers.formatNumberMoney(
                  offsetCostWithTax
                )}円`}</span>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={12}>
                <span className="center-item white-space-nowrap">{`${helpers.formatNumberMoney(
                  consumptionTax
                )}円`}</span>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={13}>
                <span className="center-item white-space-nowrap">{`${helpers.formatNumberMoney(
                  offsetCostWithoutTax
                )}円`}</span>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={14}>
                <span className="center-item white-space-nowrap">{`${helpers.formatNumberMoney(
                  deductionCost
                )}円`}</span>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        );
      }}
    />
  );
};

export default TableBelowComponent;
