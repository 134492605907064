import { CM0063SliceType } from './../type/Presenter';
import { createSlice } from '@reduxjs/toolkit';

const initialState: CM0063SliceType = {
    loading: false,
    isVisible: false,
    refresh: false,
    typeModal: false,
    dataLocal: [],
    parentCode: {
        parentRecordId: undefined,
        status: true,
    },
    orderingCompanyName: '',
    taxCode: null,
    originDataLocal: [],
    screen: '',
};

export const cm0063Slice = createSlice({
    name: 'cm0063',
    initialState,
    reducers: {
        resetCM0063: () => initialState,
        setLoadingCM0063: (state, action) => {
            state.loading = action.payload;
        },
        setIsVisibleCM0063: (state, action) => {
            state.isVisible = action.payload;
        },
        setRefreshCM0063: (state) => {
            state.refresh = !state.refresh;
        },
        setTypeModalCM0063: (state, action) => {
            state.typeModal = action.payload;
        },
        setDataLocalCM0063: (state, action) => {
            state.dataLocal = action.payload;
        },
        deleteDataLocalCM0063: (state, action) => {
            state.dataLocal = state.dataLocal.filter(
                (item) => item.parentRecordId !== action.payload,
            );
        },
        setParentCodeCM0063: (state, action) => {
            state.parentCode = action.payload;
            state.typeModal = typeof action.payload.parentRecordId === 'string' ? true : false;
        },
        setOrderingCompanyName: (state, action) => {
            state.orderingCompanyName = action.payload;
        },
        setTaxCodeCM0063: (state, action) => {
            state.taxCode = action.payload;
        },
        setOriginDataLocal: (state, action) => {
            state.originDataLocal = action.payload;
        },
        setScreen: (state, action) => {
            state.screen = action.payload;
        },
    },
});

export const {
    setLoadingCM0063,
    setIsVisibleCM0063,
    resetCM0063,
    setRefreshCM0063,
    setTypeModalCM0063,
    setDataLocalCM0063,
    setParentCodeCM0063,
    deleteDataLocalCM0063,
    setOrderingCompanyName,
    setTaxCodeCM0063,
    setOriginDataLocal,
    setScreen,
} = cm0063Slice.actions;

export default cm0063Slice.reducer;
