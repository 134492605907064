import Excel, { BorderStyle } from 'exceljs';

export type FrameExcel = {
    column: string;
    width: number;
};

export type ExcelData = {
    key: number;
    receiveAt: string;
    targetPeriod: string;
    planReceiveMoney: string;
    receiveMoney: string;
    offsetMoney: string;
    contractorReceiveMoney: string;
    status: string;
};

const BORDER_STYLE_CELL_DOUBLE_SUMMARY: any = {
    top: { style: 'double', color: { argb: '000' } },
    left: { style: 'thin', color: { argb: '000' } },
    bottom: { style: 'thin', color: { argb: '000' } },
    right: { style: 'thin', color: { argb: '000' } },
};

const ROW_HEIGHT_1 = 18;
const ROW_HEIGHT_2 = 22.5;
const ROW_HEIGHT_3 = 24;
const ROW_HEIGHT_4 = 14.25;
const ROW_HEIGHT_5 = 21.75;
const ROW_HEIGHT_6 = 22.5;
const ROW_HEIGHT_7 = 28.5;
const ROW_HEIGHT_8 = 33;
const ROW_HEIGHT_9 = 27.75;
const ROW_HEIGHT_10 = 10.5;
const ROW_HEIGHT_11 = 36;
const ROW_HEIGHT_12 = 17.25;
const FONT1: Partial<Excel.Font> = {
    bold: true,
    size: 14,
    name: 'MS PGothic',
};
const FONT2: Partial<Excel.Font> = {
    size: 11,
    name: 'MS PGothic',
};
const FONT3: Partial<Excel.Font> = {
    size: 11,
    name: '游ゴシック',
};
const MS_PGothic = 'MS PGothic';
const MS_PMincho = 'MS PMincho';
const Calibri = 'Calibri';
const Yu_gothic = 'Yu gothic';
const Arial = 'Arial';

const M_S_P = 'ＭＳ Ｐ明朝';
const SIZE1 = 24;
const SIZE2 = 18;
const SIZE3 = 16;
const SIZE4 = 14;
const SIZE5 = 12;
const SIZE6 = 11;
const SIZE7 = 13;
const SIZE8 = 22;

const getFont = (size: number, name: string): Partial<Excel.Font> => {
    const result: Partial<Excel.Font> = {
        size: size,
        name: name,
    };
    return result;
};

const createOuterBorder = (
    worksheet: Excel.Worksheet,
    start = { row: 1, col: 1 },
    end = { row: 1, col: 1 },
    borderWidth: BorderStyle = 'medium',
) => {
    const borderStyle = {
        style: borderWidth,
    };
    for (let i = start.row; i <= end.row; i++) {
        const leftBorderCell = worksheet.getCell(i, start.col);
        const rightBorderCell = worksheet.getCell(i, end.col);
        leftBorderCell.border = {
            ...leftBorderCell.border,
            left: borderStyle,
        };
        rightBorderCell.border = {
            ...rightBorderCell.border,
            right: borderStyle,
        };
    }

    for (let i = start.col; i <= end.col; i++) {
        const topBorderCell = worksheet.getCell(start.row, i);
        const bottomBorderCell = worksheet.getCell(end.row, i);
        topBorderCell.border = {
            ...topBorderCell.border,
            top: borderStyle,
        };
        bottomBorderCell.border = {
            ...bottomBorderCell.border,
            bottom: borderStyle,
        };
    }
};
const DIFFERENT_NUMBER = 8;
const DIFFERENT_NUMBER_2 = 1;

const createFrame = (worksheet: Excel.Worksheet, frames: FrameExcel[]) => {
    frames.forEach((element: FrameExcel) => {
        const column = worksheet.getColumn(element.column);
        column.width = element.width;
    });
};

export {
    getFont,
    createFrame,
    createOuterBorder,
    BORDER_STYLE_CELL_DOUBLE_SUMMARY,
    ROW_HEIGHT_1,
    ROW_HEIGHT_2,
    ROW_HEIGHT_3,
    FONT1,
    FONT2,
    FONT3,
    DIFFERENT_NUMBER,
    SIZE1,
    SIZE2,
    SIZE3,
    SIZE4,
    SIZE5,
    SIZE6,
    SIZE7,
    MS_PGothic,
    MS_PMincho,
    Calibri,
    Yu_gothic,
    Arial,
    M_S_P,
    ROW_HEIGHT_4,
    ROW_HEIGHT_5,
    ROW_HEIGHT_6,
    ROW_HEIGHT_7,
    ROW_HEIGHT_8,
    ROW_HEIGHT_9,
    ROW_HEIGHT_10,
    ROW_HEIGHT_11,
    ROW_HEIGHT_12,
    SIZE8,
    DIFFERENT_NUMBER_2,
};
