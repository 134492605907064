import { AxiosResponse } from "axios";
import axiosConfig from "../../../../../config/axiosConfig";

const FilterApi = () => {
  const getAttendanceProjectConstruction = async (params: {
    projectId: number;
    keyword: string;
    page: string;
    size: string;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "attendance/project/construction";
    return await axiosConfig.get(url, { params });
  };
  const getProjectAlls = async (params: {
    page: number;
    size: number;
    sortBy?: string;
    sortType?: string;
    keyword?: string;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/project/double-check/list";
    return await axiosConfig.get(url, { params });
  };
  const getProjectStatus = async (params: {
    page: number;
    size: number;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/project/status";
    return await axiosConfig.get(url, { params });
  };

  return {
    getAttendanceProjectConstruction,
    getProjectAlls,
    getProjectStatus,
  };
};

export default FilterApi;
