// Built-in
import React, { useEffect } from "react";

// 3rd party lib
import { Table, TableColumnsType } from "antd";

// Source files
import { HanlderAttendanceImpl } from "../../../handler/HandlerImpl";
import { convertToDataSource } from "../helper";
import { DateColumn } from "../../../../entity/Entity";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../store";

export interface Props {
  handler: HanlderAttendanceImpl;
  contractorConstructionId: number;
  userId: number;
  columnsTable: DateColumn[];
  constructionId: number;
  attendance: any;
}

const AttendanceUI = ({
  handler,
  contractorConstructionId,
  userId,
  columnsTable,
  constructionId,
  attendance,
}: Props) => {
  useEffect(() => {
    handler.setAttendance(attendance);
  }, [JSON.stringify(attendance)]);

  useEffect(() => {
    const convertedData = convertToDataSource(
      userId,
      contractorConstructionId,
      handler.attendance?.timesheet ?? [],
      columnsTable,
      handler.attendance?.laborCost ?? 0,
      constructionId,
      handler.openStreetMap
    );
    handler.setDataSource(convertedData);
  }, [
    JSON.stringify(handler.attendance?.timesheet ?? []),
    JSON.stringify(columnsTable),
  ]);

  const getColumns = (tableColumns: DateColumn[]) => {
    const columns: TableColumnsType<any> = [
      {
        title: "",
        key: "attendanceName",
        width: "21.2%",
        render: (record) => {
          return record.name;
        },
        onCell: () => {
          return {
            className: "border-left-table",
          };
        },
      },
      {
        title: "",
        key: "prev",
        width: 70,
      },
    ];
    for (let i = 0; i < tableColumns.length; i++) {
      columns.push({
        title: "",
        key: i,
        width: `${73.3 / tableColumns.length}%`,
        render: (record) => record[`${tableColumns[i].value}`],
      });
    }
    columns.push({
      title: "",
      key: "next",
      width: 29,
    });
    columns.push({
      title: "",
      key: "ghost",
      width: "5.5%",
    });
    return columns;
  };

  return (
    <Table
      bordered={true}
      className="am-main-table-construction attendance-table attendance-table-row-white"
      columns={getColumns(columnsTable)}
      dataSource={handler.dataSource}
      pagination={false}
    />
  );
};

export default AttendanceUI;
