import { Button, Tabs } from "antd";
import React from "react";
import { CI001BodyHandler } from "../handler/HandlerImpl";
import CI001BasicInformation from "./CI001BasicInformation";
import CI001PaymentTerm from "./CI001PaymentTerm";
import { useEffect, useMemo } from "react";

import IconCircleCheck from "../../../../../assets/icons/akar-icons_circle-check-fill.svg";
import { useParams } from "react-router-dom";
import helpers from "../../../../../common/helpers/common";
import { USER_FLOW } from "../../../../../common/constants/COMMON";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { AUTHORIZATION_CODE } from "../../../../../common/constants/AUTHORIZATION";
import UploadLightSVG from "../../../../../assets/icons/upload-icon-purple.svg";
import { isHasPrivilege } from "../../../../../common/helpers/privilege";
import { PRIVILEGE_PM } from "../../../../../common/constants/PRIVILEGE";
import MESSAGE from "../../../../../common/constants/MESSAGE";

const { TabPane } = Tabs;

interface props {
  handler: CI001BodyHandler;
}

function CI001BodyUI({ handler }: props) {
  const { constructionId, projectManagerId } = useParams();
  const permission = useSelector(
    (state: RootState) => state.auth.authData?.permission
  );
  const isRoleAdmin = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdmin
  );
  const isUserIsProjectManager = useSelector(
    (state: RootState) => state.auth.authData?.isUserIsProjectManager
  );

  const listApprovalProcessWithoutSubmitter =
    handler.listApprovalProcess.filter((item: any) => !item.isSubmitter);

  const approvalProcessSubmitter = handler.listApprovalProcess.find(
    (item: any) => item.isSubmitter
  );
  const user = helpers.getObjectFromLocalStorage("user");
  const isCloneWithoutWorkflow =
    !!handler.detailProject?.parentProject?.id &&
    handler.listApprovalProcess.length === 0 &&
    !handler.currentUserApproval;
  const checkButtonWithWorkflowRolePM = useMemo(() => {
    return helpers.checkButtonWithWorkflowRolePM(
      handler.currentUserApproval,
      listApprovalProcessWithoutSubmitter,
      +(projectManagerId ?? 0)
    );
  }, [
    handler.currentUserApproval,
    handler.listApprovalProcess,
    projectManagerId,
  ]);

  const checkButtonWithWorkflowRolePMAdmin = useMemo(() => {
    return helpers.checkButtonWithWorkflowRolePMAdmin(
      handler.currentUserApproval,
      listApprovalProcessWithoutSubmitter,
      +(projectManagerId ?? 0)
    );
  }, [
    handler.currentUserApproval,
    handler.listApprovalProcess,
    projectManagerId,
  ]);

  useEffect(() => {
    handler.setIsEdit(false);
  }, []);

  return (
    <div className="ci001-body">
      <Tabs
        defaultActiveKey="1"
        type="card"
        onChange={handler.onChangeCurrentTab}
        activeKey={handler.currentTab}
      >
        <TabPane tab={"基本情報"} key="1">
          <CI001BasicInformation handler={handler} />
        </TabPane>
        <TabPane tab={"支払条件確認書"} key="2">
          <CI001PaymentTerm handler={handler} />
        </TabPane>
      </Tabs>
      {handler.isEdit ? (
        <></>
      ) : (
        <div className="btn-approve">
          {!(checkButtonWithWorkflowRolePM === USER_FLOW.USER) &&
            !(checkButtonWithWorkflowRolePM === USER_FLOW.MANAGER_SUBMIT) &&
            !(
              checkButtonWithWorkflowRolePM === USER_FLOW.MANAGER_SUBMITTED
            ) && (
              <>
                <Button
                  size="large"
                  className="button-purple cr001-header-btn-ok"
                  icon={<img alt="" src={IconCircleCheck} />}
                  onClick={() =>
                    handler.handleApproveCI(
                      MESSAGE.MESSAGE_DESCRIPTION_CI_CONFIRM_APPROVE,
                      "承認確認"
                    )
                  }
                  disabled={
                    checkButtonWithWorkflowRolePM ===
                      USER_FLOW.MANAGER_FLOW_SUBMITTED ||
                    checkButtonWithWorkflowRolePM === USER_FLOW.FLOW_SUBMITTED
                  }
                  style={{ marginLeft: 16 }}
                >
                  承認
                </Button>
                <Button
                  size="large"
                  className="btn-reject"
                  onClick={() => handler.handleRejectCI()}
                  style={{
                    backgroundColor: "#dae2ee",
                    letterSpacing: -1.5,
                    marginLeft: 16,
                  }}
                  disabled={
                    checkButtonWithWorkflowRolePM ===
                      USER_FLOW.MANAGER_FLOW_SUBMITTED ||
                    checkButtonWithWorkflowRolePM === USER_FLOW.FLOW_SUBMITTED
                  }
                >
                  否認
                </Button>
              </>
            )}
          {[
            USER_FLOW.MANAGER_SUBMITTED,
            USER_FLOW.MANAGER_FLOW_SUBMITTED,
            USER_FLOW.MANAGER_FLOW_SUBMIT,
            USER_FLOW.FLOW_SUBMITTED,
            USER_FLOW.FLOW_SUBMIT,
            USER_FLOW.USER,
          ].includes(checkButtonWithWorkflowRolePMAdmin) &&
            (isRoleAdmin ||
              +user.id === approvalProcessSubmitter?.id ||
              isHasPrivilege(handler.detailProject, [PRIVILEGE_PM])) && (
              <Button
                size="large"
                className="btn-withdraw"
                onClick={() => handler.handleWithDraw(constructionId)}
                style={{ marginLeft: 16 }}
              >
                取り下げ
              </Button>
            )}
          {checkButtonWithWorkflowRolePMAdmin === USER_FLOW.MANAGER_SUBMIT &&
            !isCloneWithoutWorkflow &&
            (isRoleAdmin ||
              isHasPrivilege(handler.detailProject, [PRIVILEGE_PM])) &&
            !handler.isEdit && (
              <Button
                size="large"
                className="button-purple cr001-header-btn-ok"
                icon={<img alt="" src={IconCircleCheck} />}
                onClick={() =>
                  handler.handleApproveCI(
                    MESSAGE.MESSAGE_DESCRIPTION_CI_CONFIRM_SUBMIT,
                    "提出確認"
                  )
                }
                style={{ marginLeft: 16 }}
              >
                提出
              </Button>
            )}
          {!handler.isEdit && permission?.includes(AUTHORIZATION_CODE.B9) && (
            <Button
              className="button-brown-3"
              style={{
                border: "1px solid #605BFF",
                width: 106,
                marginLeft: 16,
              }}
              size="large"
              onClick={() =>
                handler.handExportTemplate(projectManagerId, constructionId)
              }
            >
              <div className="center-item">
                <img
                  alt=""
                  src={UploadLightSVG}
                  style={{
                    marginRight: 8,
                    fontWeight: 700,
                    fontSize: 14,
                  }}
                />
                <div className="export-button" style={{ color: "#605BFF" }}>
                  出力
                </div>
              </div>
            </Button>
          )}
        </div>
      )}
    </div>
  );
}

export default CI001BodyUI;
