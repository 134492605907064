import N0_DATA from "../../../../../assets/images/pm014_nodata.svg";

const PM014NoData = () => {
  return (
    <div className="pm014__nodata center-item">
      <img src={N0_DATA} alt="" />
    </div>
  );
};

export default PM014NoData;
