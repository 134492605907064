// Built-in
import { useState } from "react";

// 3rd party lib
import { useSearchParams } from "react-router-dom";
import { Form } from "antd";
import moment from "moment";

// Source files
import COMMON from "../../../../../common/constants/COMMON";
import helpers from "../../../../../common/helpers/common";
import { FilterServiceImpl } from "../../usecase/ServiceImpl";
import { RangeDateType } from "../../../AM002/entity/Entity";
import { ConstructionData } from "../../entity/Entity";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../../common/constants/MESSAGE";
import { setPermissionDoubleCheck } from "../slice/Slice";

const FilterHandler = (service: FilterServiceImpl) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [total, setTotal] = useState(30);
  const [constructionList, setConstructionList] = useState<ConstructionData>({
    displayConstructions: [],
    rawConstructions: [],
  });
  const [searchDropDownValue, setSearchDropDownValue] = useState<string>("");
  const [rangeDate, setRangeDate] = useState<RangeDateType>({
    from: moment().startOf("month"),
    to: moment(),
  });
  const [filterLoadng, setFilterLoading] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const fromDate =
    searchParams.get("startDate") ||
    moment().startOf("month").format(COMMON.FORMAT_DATE2);
  const toDate =
    searchParams.get("endDate") || moment().format(COMMON.FORMAT_DATE2);

  // Infinity scroll
  const [infiData, setInifiData] = useState<any[]>([]);
  const [infiLoading, setInfiLoading] = useState<boolean>(false);
  const [infiPage, setInfiPage] = useState(1);
  const [infiTotal, setInfiTotal] = useState(0);

  const [selectedOption, setSelectedOption] = useState(false);
  const onFromDateChange = (value: any) => {
    setRangeDate({ ...rangeDate, from: value });
  };
  const onToDateChange = (value: any) => {
    setRangeDate({ ...rangeDate, to: value });
  };

  var myVar1: any;
  const onSearchDropDown = () => {
    setSelectedOption(false);
    if (myVar1) clearTimeout(myVar1);
    myVar1 = setTimeout(async () => {
      const searchText = form.getFieldValue("project") ?? "";
      // form.setFieldValue("searchDropDown", searchText.trim());
      searchParams.set("searchDropDown", searchText.trim());
      setSearchParams(searchParams);
    }, 1000);
  };

  const onScroll = async (event: any) => {
    var target = event.target;
    if (
      !infiLoading &&
      target.scrollTop + target.offsetHeight + 1 >= target.scrollHeight
    ) {
      if (infiData.length === infiTotal) return;
      target.scrollTo(0, target.scrollHeight);
      await getProjectAlls();
    }
  };

  const getProjectAlls = async () => {
    setInfiLoading(true);
    const response = await service.getProjectAlls({
      keyword: searchParams.get("searchDropDown") || "",
      page: infiPage,
      size: COMMON.DEFAULT_SIZE,
    });
    const results = response?.results ?? [];
    const pagination = response.pagination;

    setInifiData([...infiData, ...results]);
    setInfiTotal(pagination?.count ?? 0);
    setInfiPage(infiPage + 1);
    setInfiLoading(false);
    if (infiPage === 1) {
      form.setFieldValue("project", results[0]?.projectName);
      searchParams.set("selectedProjectId", results[0]?.id + "");
      setSearchParams(searchParams);
    }
  };

  const searchDropDown = async (keyword: string) => {
    setInfiLoading(true);
    const { results, pagination } = await service.getProjectAlls({
      keyword: !selectedOption ? keyword : "",
      page: COMMON.DEFAULT_PAGE,
      size: COMMON.DEFAULT_SIZE,
    });
    setInfiPage(2);
    setInifiData(results);
    setInfiTotal(pagination.count);
    setInfiLoading(false);
  };

  const initiateProjectConstruction = async (
    searchDropDown: string,
    keyword: string,
    projectId?: string | number,
    constructionId?: string | number
  ) => {
    try {
      dispatch(setLoading(true));
      setSearchDropDownValue(searchDropDown);
      setInfiLoading(true);

      if (!projectId) {
        const { results, pagination } = await service.getProjectAlls({
          keyword: searchDropDown,
          page: infiPage,
          size: COMMON.DEFAULT_SIZE,
        });
        setInifiData([...results]);
        setInfiTotal(pagination.count);
        setInfiPage(infiPage + 1);

        if (results?.length > 0) {
          form.setFieldValue("project", results[0]?.projectName);
          searchParams.set("selectedProjectId", results[0]?.id + "");
          onProjectChoose(results[0]);
          setSearchParams(searchParams);
        }
      } else {
        const { results, pagination } = await service.getProjectAlls({
          page: COMMON.DEFAULT_PAGE,
          size: COMMON.DEFAULT_SIZE,
        });
        setInfiTotal(pagination.count);

        // SMALL FUNCTION
        const checkFoundDefaultParams = smallFunction(
          results,
          keyword,
          projectId,
          constructionId
        );

        if (checkFoundDefaultParams) {
          setInifiData([...results]);
          return;
        }
        const totalPages = Math.floor(
          pagination.count / COMMON.DEFAULT_SIZE +
          (pagination.count % COMMON.DEFAULT_SIZE > 0 ? 1 : 0)
        );
        let projects: any[] = [...results];
        for (let i = 2; i <= totalPages; i++) {
          const { results } = await service.getProjectAlls({
            page: i,
            size: COMMON.DEFAULT_SIZE,
          });
          projects = projects.concat(results);
          const checkFoundDefaultParams = smallFunction(
            results,
            keyword,
            projectId,
            constructionId
          );
          if (checkFoundDefaultParams) {
            setInifiData(projects);
            return;
          }
        }
      }
    } catch (error: any) {
      ErrorNotification(error.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
      setInfiLoading(false);
    }
  };

  const smallFunction = (
    results: any,
    keyword: string,
    projectId: any,
    constructionId: any
  ): boolean => {
    const foundProject: any = results.find(
      (project: any) => project.id + "" === projectId + ""
    );
    if (foundProject) {
      form.setFieldValue("project", foundProject.projectName);
      const constructions = foundProject?.constructions ?? [];

      // keyword
      const filteredConstructions = constructions.filter((element: any) =>
        helpers
          .toASCII(element.name || "")
          .toUpperCase()
          .includes(helpers.toASCII(keyword || "").toUpperCase())
      );
      setTotal(filteredConstructions.length);

      // find the shit
      const foundConstructionIndex = filteredConstructions.findIndex(
        (element: any) => element.id + "" === constructionId + ""
      );

      if (foundConstructionIndex >= 0) {
        dispatch(
          setPermissionDoubleCheck(
            !!filteredConstructions[foundConstructionIndex]
              .permissionDoubleCheck
          )
        );

        // calculate current page 17 - 34
        let currentPage =
          foundConstructionIndex < COMMON.DEFAULT_SIDE_SIZE
            ? 0
            : Math.floor(foundConstructionIndex / COMMON.DEFAULT_SIDE_SIZE);

        searchParams.set("pageConstruction", currentPage + 1 + "");
        setSearchParams(searchParams);

        const pagingConstructions = filteredConstructions.slice(
          currentPage * COMMON.DEFAULT_SIDE_SIZE,
          (currentPage + 1) * COMMON.DEFAULT_SIDE_SIZE
        );
        setConstructionList({
          displayConstructions: pagingConstructions,
          rawConstructions: constructions,
        });
      } else if (filteredConstructions.length > 0) {
        // paging
        const pagingConstructions = filteredConstructions.slice(0, 6);
        setConstructionList({
          displayConstructions: pagingConstructions,
          rawConstructions: constructions,
        });
        dispatch(
          setPermissionDoubleCheck(!!constructions[0].permissionDoubleCheck)
        );
        searchParams.set("pageConstruction", "1");
        searchParams.set("selectedConstructionId", constructions[0].id + "");
        setSearchParams(searchParams);
      }
      return true;
    }
    return false;
  };

  var myVar: any;
  const onSearch = () => {
    if (myVar) clearTimeout(myVar);
    myVar = setTimeout(function () {
      const searchText = form.getFieldValue("text");
      form.setFieldValue("text", searchText.trim());
      searchParams.set("keywordConstruction", searchText.trim());
      searchParams.set("pageConstruction", COMMON.DEFAULT_PAGE + "");
      setSearchParams(searchParams);
    }, 1000);
  };

  const getAttendanceProjectConstruction = async (params: {
    keyword: string;
    page: string;
    size: string;
  }) => {
    setFilterLoading(true);
    const constructions = constructionList.rawConstructions;

    // keyword
    const filteredConstructions = constructions.filter((element: any) =>
      helpers
        .toASCII(element.name || "")
        .toUpperCase()
        .includes(helpers.toASCII(params.keyword || "").toUpperCase())
    );
    setTotal(filteredConstructions.length);

    // paging
    const pagingConstructions = filteredConstructions.slice(
      (parseInt(params.page) - 1) * parseInt(params.size),
      parseInt(params.page) * parseInt(params.size)
    );

    setConstructionList({
      displayConstructions: pagingConstructions,
      rawConstructions: constructions,
    });
    setFilterLoading(false);
  };

  const initiateFilter = () => {
    let fromMoment: moment.Moment = moment().startOf("month");
    if (fromDate === null)
      form.setFieldValue("startDate", moment().startOf("month"));
    else {
      form.setFieldValue("startDate", moment(fromDate));
      fromMoment = moment(fromDate);
    }

    form.setFieldValue("endDate", moment(toDate));
    setRangeDate({
      to: moment(toDate),
      from: fromMoment,
    });
  };

  const onProjectChoose = (value: any) => {
    if (!value) return;
    setSelectedOption(true)
    form.setFieldValue("project", value.projectName);
    setFilterLoading(true);
    // keyword
    const constructions = value.constructions;

    setConstructionList({
      ...constructionList,
      rawConstructions: value.constructions,
    });
    if (constructions.length > 0) {
      searchParams.set("selectedConstructionId", constructions[0].id + "");
      dispatch(
        setPermissionDoubleCheck(!!constructions[0].permissionDoubleCheck)
      );
    }
    searchParams.set("pageConstruction", COMMON.DEFAULT_PAGE + "");
    searchParams.set("selectedProjectId", value.id + "");
    setSearchParams(searchParams);
    setFilterLoading(false);
  };

  const onConstructionChoose = (value: any) => {
    dispatch(setPermissionDoubleCheck(!!value.permissionDoubleCheck));
    searchParams.set("selectedConstructionId", value.id + "");
    setSearchParams(searchParams);
  };

  const onPageChange = (value: any) => {
    searchParams.set("pageConstruction", value);
    setSearchParams(searchParams);
  };

  const onPageSizeChange = (value: any) => { };

  const onFilter = () => {
    searchParams.set("pageDate", COMMON.DEFAULT_PAGE + "");
    const startDate = form.getFieldValue("startDate");
    if (startDate)
      searchParams.set(
        "startDate",
        moment(startDate).format(COMMON.FORMAT_DATE2)
      );
    else searchParams.set("startDate", "");

    const endDate = form.getFieldValue("endDate");
    searchParams.set("endDate", moment(endDate).format(COMMON.FORMAT_DATE2));
    setSearchParams(searchParams);
  };

  const onResetFilter = () => {
    const initialStart = moment().startOf("month");
    const initialEnd = moment();

    form.setFields([
      {
        name: "startDate",
        value: initialStart,
      },
      {
        name: "endDate",
        value: initialEnd,
      },
    ]);
    searchParams.set("pageDate", COMMON.DEFAULT_PAGE + "");
    searchParams.set("startDate", initialStart.format(COMMON.FORMAT_DATE2));
    searchParams.set("endDate", initialEnd.format(COMMON.FORMAT_DATE2));
    setSearchParams(searchParams);
  };

  const onCollapse = (value: boolean) => {
    searchParams.set("isCollapsed", value ? "isCollapsed" : "");
    setSearchParams(searchParams);
  };

  return {
    onConstructionChoose,
    onProjectChoose,
    onPageChange,
    onPageSizeChange,
    onFilter,
    onResetFilter,
    onCollapse,
    initiateFilter,
    getAttendanceProjectConstruction,
    getProjectAlls,
    onScroll,
    onFromDateChange,
    onToDateChange,
    onSearch,
    initiateProjectConstruction,
    onSearchDropDown,
    searchDropDown,
    setSearchDropDownValue,
    form,
    total,
    infiTotal,
    infiData,
    infiLoading,
    infiPage,
    rangeDate,
    constructionList,
    searchDropDownValue,
    filterLoadng,
  };
};

export default FilterHandler;
