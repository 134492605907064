import { DB004SliceType } from "./../type/Presenter";
import { createSlice } from "@reduxjs/toolkit";

const initialState: DB004SliceType = {
  isVisibleDB004: false,
  isRefresh: false,
  projectId: null,
};

export const db004Slice = createSlice({
  name: "db004",
  initialState,
  reducers: {
    resetDB004: () => initialState,
    setVisibleDB004: (state, action) => {
      state.isVisibleDB004 = action.payload;
    },
    setParamsDB004: (state, action) => {
      const { projectId, from, to } = action.payload;
      state.projectId = projectId;
      state.from = from;
      state.to = to;
    },
  },
});

export const { resetDB004, setVisibleDB004, setParamsDB004 } =
  db004Slice.actions;

export default db004Slice.reducer;
