import { Table } from 'antd';

type Props = {
    data: any;
    columns: any;
    title?: string;
};

export const CM0010Table = ({ data, columns, title }: Props) => {
    return (
        <div className="table-top">
            <Table
                columns={columns}
                dataSource={data}
                bordered
                title={() => title}
                pagination={false}
            />
        </div>
    );
};
