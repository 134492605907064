import { CRT001BodyUIHandler } from "../handler/HandlerImpl";
import CRT001AmountRelated from "./CRT001AmountRelated";
import CRTBasicInfo from "./CRTBasicInfo";

interface props {
  handler: CRT001BodyUIHandler;
}

function CRT001Body({ handler }: props) {
  return (
    <div className="crt001">
      <CRTBasicInfo handler={handler} />
      <CRT001AmountRelated handler={handler} />
    </div>
  );
}

export default CRT001Body;
