import API from "./infrastructure/api/Api"
import Handler from "./presenter/handler/Handler"
import MainUI from "./presenter/ui/UI"
import Service from "./usecase/Service"


const PM028Component = () => {
    const api = API()
    const service = Service(api)
    const handler = Handler(service)
    return <MainUI handler={handler} />
}

export default PM028Component