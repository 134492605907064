import { createSlice } from '@reduxjs/toolkit';

type H4ModalSlice = {
    isOpenH4: boolean;
};
const initialState: H4ModalSlice = {
    isOpenH4: false,
};

export const H4ModalSlice = createSlice({
    name: 'H4ModalSlice',
    initialState,
    reducers: {
        showH4Modal: (state) => {
            state.isOpenH4 = true;
        },
        hideH4Modal: (state) => {
            state.isOpenH4 = false;
        },
    },
});

export const { showH4Modal, hideH4Modal } = H4ModalSlice.actions;
export default H4ModalSlice.reducer;
