import React from 'react';
import CompanyRowComponent from './CompanyRow';
import { RootState } from '../../../../../store';
import { useSelector } from 'react-redux';
import helpers from '../../../../../common/helpers/common';
import Handler from '../../../../AM/AM042/presenter/handler/Handler';

interface props {
    outsourceCompanies: any[];
    summaryTotal: any;
    openCM0025_2: (value: any) => void;
    openCM0025_1: (value: any) => void;
    setCompanyExpandKeys: (key: any) => void;
    checkCompanyKeyExpand: (key: any) => boolean;
    setProjectExpandKeys: (key: any) => void;
    checkProjectKeyExpand: (key: any) => boolean;
}

const Table = ({
    outsourceCompanies: outsourceCompanies,
    summaryTotal,
    openCM0025_2,
    openCM0025_1,
    setCompanyExpandKeys,
    checkCompanyKeyExpand,
    setProjectExpandKeys,
    checkProjectKeyExpand,
}: props) => {
    return (
        <div className="cm024-table">
            <table>
                <thead>
                    <tr>
                        <th rowSpan={2} colSpan={2} style={{ minWidth: 150 }}>
                            No
                        </th>
                        <th rowSpan={2} style={{ minWidth: 160 }}>
                            業者名
                        </th>
                        <th rowSpan={2} style={{ minWidth: 160 }}>
                            相殺金額合計
                        </th>
                        <th colSpan={4} style={{ minWidth: 600 }}>
                            業者別相殺金額
                        </th>
                        <th rowSpan={2} style={{ minWidth: 180 }}>
                            計上金額
                        </th>
                        <th colSpan={2} style={{ minWidth: 300 }}>
                            案件別相殺金額
                        </th>
                        <th rowSpan={2} style={{ minWidth: 150 }}>
                            支払金額(税抜)
                        </th>
                        <th rowSpan={2} style={{ minWidth: 150 }}>
                            消費税
                        </th>
                        <th rowSpan={2} style={{ minWidth: 150 }}>
                            支払金額(税込)
                        </th>
                        <th rowSpan={2} style={{ minWidth: 40 }}></th>
                    </tr>
                    <tr>
                        <th colSpan={2}>課税</th>
                        <th colSpan={2}>非課税</th>
                        <th>課税</th>
                        <th>非課税</th>
                    </tr>
                </thead>
                <tbody>
                    {outsourceCompanies.map((element, index) => (
                        <CompanyRowComponent
                            openCM0025_2={openCM0025_2}
                            openCM0025_1={openCM0025_1}
                            key={element.id}
                            index={index}
                            data={element}
                            setCompanyExpandKeys={setCompanyExpandKeys}
                            checkCompanyKeyExpand={checkCompanyKeyExpand}
                            setProjectExpandKeys={setProjectExpandKeys}
                            checkProjectKeyExpand={checkProjectKeyExpand}
                        />
                    ))}
                </tbody>
                <tfoot>
                    <tr className="table-foot">
                        <td colSpan={2}>合計金額</td>
                        <td></td>
                        <td>{`${helpers.formatNumberMoney(summaryTotal.totalOffset)}円`}</td>
                        <td colSpan={2}>{`${helpers.formatNumberMoney(
                            summaryTotal.offsetCostSummaryTax,
                        )}円`}</td>
                        <td colSpan={2}>{`${helpers.formatNumberMoney(
                            summaryTotal.offsetCostSummaryTaxFree,
                        )}円`}</td>
                        <td>{`${helpers.formatNumberMoney(summaryTotal.actualCost)}円`}</td>
                        <td>{`${helpers.formatNumberMoney(summaryTotal.offsetCostTax)}円`}</td>
                        <td>{`${helpers.formatNumberMoney(summaryTotal.offsetCostTaxFree)}円`}</td>
                        <td>{`${helpers.formatNumberMoney(
                            summaryTotal.paymentAmountExcludingTax,
                        )}円`}</td>
                        <td>{`${helpers.formatNumberMoney(summaryTotal.consumptionTax)}円`}</td>
                        <td>{`${helpers.formatNumberMoney(
                            summaryTotal.paymentAmountIncludingTax,
                        )}円`}</td>

                        <td></td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};

export default Table;
