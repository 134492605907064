import { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import { CI001UseCase } from "../../usecase/ServiceImpl";
import ConfirmModal from "../../../../../common/components/modal/ConfirmModal";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import { Form, Checkbox, message } from "antd";
import moment from "moment";
import MESSAGE, {
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import helpers from "../../../../../common/helpers/common";
import { useSearchParams, useParams } from "react-router-dom";
import { exportConstructionRequest } from "../../../../../common/helpers/exports/cr_ci/construction_request";
import COMMON, { FILE_NAME } from "../../../../../common/constants/COMMON";
import { doExportForResponse } from "../../../../../common/helpers/exports/common";
import { RcFile } from "antd/es/upload";
import { showPdfViewerModal } from "../../../../../common/components/pdf-viewer-modal/pdfViewerModalSlice";
import { showImageViewerModal } from "../../../../../common/components/image-viewer-modal/ImageViewSlice";
import ConfirmModalAsync from "../../../../../common/components/modal/ConfirmModalAsync";

const DROP_1 = "estimation";
const DROP_2 = "order";
const DROP_3 = "payment";

const DROP_1_PAYMENT = "payment_day";
const DROP_2_PAYMENT = "payment_method";

export const TYPE_USER = {
  owner_submit: "owner_submit",
  owner_not_submit: "owner_not_submit",
  approve: "approve",
  view: "view",
  user_create: "user_create",
  manager_approve: "manager_approve",
};

const PARAM_TAB = {
  current: "1",
  name: "tab",
};

const CI001Handler = (ci001Service: CI001UseCase) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [formPayment] = Form.useForm();
  const [formReject] = Form.useForm();
  const [basicInformation, setBasicInformation] = useState<any>({});
  const [screenMaster, setScreenMaster] = useState<any>({});
  const [screenMasterPayment, setScreenMasterPayment] = useState<any>({});
  const [paymentTerm, setPaymentTerm] = useState<any>({});
  const [inquiryCategory, setInquiryCategory] = useState<any>("");
  const [decisionMethod, setDecisionMethod] = useState<any>("");
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [checkbox, setCheckbox] = useState<any[]>([]);
  const [moneySelect, setMoneySelect] = useState<any>({});
  const [summaryId, setSummaryId] = useState<string>("");
  const [isRefresh, setIsRefresh] = useState<boolean>(false);
  const [isRefreshPayment, setIsRefreshPayment] = useState<boolean>(false);
  const [isChangeEdit, setIsChangeEdit] = useState(false);
  const [listApprovalProcess, setListApprovalProcess] = useState<any[]>([]);
  const [currentUserApproval, setCurrentUserApproval] = useState<any>({});
  const [isApprove, setIsApprove] = useState(false);
  const [isApproveOrReject, setIsApproveOrReject] = useState(true);
  const [noteApprove, setNoteApprove] = useState<string>("");
  const [refreshApprove, setRefreshApprove] = useState(false);
  const [detailProject, setDetailProject] = useState<any>({});
  const [fileList, setFileList] = useState<any[]>([]);
  const [pdfUrl, setPdfUrl] = useState("");
  const [fileListPaymentTerm, setFileListPaymentTerm] = useState<any[]>([]);
  const [pdfUrlPaymentTerm, setPdfUrlPaymentTerm] = useState("");
  const [descriptionPopup, setDescriptionPopup] = useState("");
  const [titlePopup, setTitlePopup] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileNamePaymentTerm, setFileNamePaymentTerm] = useState("");

  //params
  const [searchParams, setSearchParams] = useSearchParams();
  const currentTab = searchParams.get(PARAM_TAB.name) || PARAM_TAB.current;

  const { userId, projectManagerId, constructionId } = useParams();

  const dropDownBasicInfo = useMemo(() => {
    if (Object.keys(screenMaster)?.length === 0)
      return {
        dropDown1: [],
        dropDown2: [],
        dropDown3: [],
      };
    const results1 = screenMaster?.categorys?.filter(
      (category: any) => category.type === DROP_1
    );
    const results2 = screenMaster?.categorys?.filter(
      (category: any) => category.type === DROP_2
    );
    const results3 = screenMaster?.categorys?.filter(
      (category: any) => category.type === DROP_3
    );

    return {
      dropDown1: results1,
      dropDown2: results2,
      dropDown3: results3,
    };
  }, [screenMaster]);

  const categoryValue = useMemo(() => {
    const category1 = basicInformation?.categories?.find((category: any) => {
      return dropDownBasicInfo.dropDown1?.some(
        (ele: any) => category.categoryId === ele.id
      );
    });
    const category1Data = dropDownBasicInfo.dropDown1?.find((category: any) => {
      return basicInformation?.categories?.some(
        (ele: any) => ele.categoryId === category.id
      );
    });
    const category2 = basicInformation?.categories?.find((category: any) => {
      return dropDownBasicInfo.dropDown2?.some(
        (ele: any) => category.categoryId === ele.id
      );
    });
    const category2Data = dropDownBasicInfo.dropDown2?.find((category: any) => {
      return basicInformation?.categories?.some(
        (ele: any) => ele.categoryId === category.id
      );
    });
    const category3 = basicInformation?.categories?.find((category: any) => {
      return dropDownBasicInfo.dropDown3?.some(
        (ele: any) => category.categoryId === ele.id
      );
    });
    const category3Data = dropDownBasicInfo.dropDown3?.find((category: any) => {
      return basicInformation?.categories?.some(
        (ele: any) => ele.categoryId === category.id
      );
    });

    return {
      category1: { ...category1, ...category1Data },
      category2: { ...category2, ...category2Data },
      category3: { ...category3, ...category3Data },
    };
  }, [basicInformation, dropDownBasicInfo]);

  const dropDownPayment = useMemo(() => {
    if (Object.keys(screenMasterPayment)?.length === 0)
      return {
        dropDown1: [],
        dropDown2: [],
      };
    const results1 = screenMasterPayment?.categorys?.filter(
      (category: any) => category.type === DROP_1_PAYMENT
    );
    const results2 = screenMasterPayment?.categorys?.filter(
      (category: any) => category.type === DROP_2_PAYMENT
    );

    return {
      dropDown1: results1,
      dropDown2: results2,
    };
  }, [screenMasterPayment]);

  const categoryPayment = useMemo(() => {
    const category1 = paymentTerm?.categories?.find((category: any) => {
      return dropDownPayment.dropDown1?.some(
        (ele: any) => category.categoryId === ele.id
      );
    });
    const category1Data = dropDownPayment.dropDown1?.find((category: any) => {
      return paymentTerm?.categories?.some(
        (ele: any) => ele.categoryId === category.id
      );
    });
    const category2 = paymentTerm?.categories?.find((category: any) => {
      return dropDownPayment.dropDown2?.some(
        (ele: any) => category.categoryId === ele.id
      );
    });
    const category2Data = dropDownPayment.dropDown2?.find((category: any) => {
      return paymentTerm?.categories?.some(
        (ele: any) => ele.categoryId === category.id
      );
    });

    return {
      category1: { ...category1, ...category1Data },
      category2: { ...category2, ...category2Data },
    };
  }, [paymentTerm, dropDownPayment]);

  useEffect(() => {
    if (currentTab === "1") {
      const valueFormNew = { ...basicInformation };
      if (
        basicInformation.constructionFromDate &&
        basicInformation.constructionToDate
      ) {
        valueFormNew.constructionFromToDate = [
          moment(basicInformation.constructionFromDate),
          moment(basicInformation.constructionToDate),
        ];
      } else {
        valueFormNew.constructionFromToDate = ["", ""];
      }
      if (basicInformation.quotationFromDate) {
        valueFormNew.quotationFromDate = moment(
          basicInformation.quotationFromDate
        );
      } else {
        valueFormNew.quotationFromToDate = "";
      }

      if (basicInformation.estimationBidFromDate) {
        valueFormNew.estimationBidFromDate = moment(
          basicInformation.estimationBidFromDate
        );
      } else {
        valueFormNew.estimationBidFromToDate = "";
      }

      if (basicInformation.constructionDetailFromDate) {
        valueFormNew.constructionDetailFromDate = moment(
          basicInformation.constructionDetailFromDate
        );
      } else {
        valueFormNew.constructionDetailFromToDate = "";
      }

      if (valueFormNew.constructionDetailHour) {
        valueFormNew.constructionDetailHour = moment(
          valueFormNew.constructionDetailHour
        );
      } else {
        valueFormNew.constructionDetailHour = "";
      }
      if (valueFormNew.quotationHour) {
        valueFormNew.quotationHour = moment(valueFormNew.quotationHour);
      } else {
        valueFormNew.quotationHour = "";
      }
      if (valueFormNew.estimationBidHour) {
        valueFormNew.estimationBidHour = moment(valueFormNew.estimationBidHour);
      } else {
        valueFormNew.estimationBidHour = "";
      }

      valueFormNew.constructionEstimate = valueFormNew.constructionEstimate || valueFormNew.constructionEstimate === 0 ? valueFormNew.constructionEstimate : "";

      const category1 = basicInformation?.categories?.find((category: any) => {
        return dropDownBasicInfo.dropDown1?.some(
          (ele: any) => category.categoryId === ele.id
        );
      });
      const category2 = basicInformation?.categories?.find((category: any) => {
        return dropDownBasicInfo.dropDown2?.some(
          (ele: any) => category.categoryId === ele.id
        );
      });
      const category3 = basicInformation?.categories?.find((category: any) => {
        return dropDownBasicInfo.dropDown3?.some(
          (ele: any) => category.categoryId === ele.id
        );
      });

      if (category1) {
        valueFormNew.estimation = {
          select: category1.categoryId,
          inputOther: category1.note || "",
        };
        setInquiryCategory(
          dropDownBasicInfo.dropDown1?.find(
            (ele: any) => category1.categoryId === ele.id
          )
        );
      } else {
        setInquiryCategory({});
      }
      if (category2) {
        valueFormNew.order = category2.categoryId;
      }

      if (category3) {
        valueFormNew.payment = {
          select: category3.categoryId,
          inputOther: category3.note || "",
        };
        setDecisionMethod(
          dropDownBasicInfo.dropDown3?.find(
            (ele: any) => category3.categoryId === ele.id
          )
        );
      } else {
        setDecisionMethod({});
      }

      if (basicInformation?.tags?.length > 0) {
        const filterValueTag = basicInformation?.tags?.filter(
          (element: { isOption: boolean }) => !element.isOption
        );
        valueFormNew.tags = {
          checkbox: filterValueTag?.map((tag: { note: string }) =>
            tag.note?.trim()
          ),
        };
        valueFormNew.inputOtherTag = basicInformation?.tags?.find(
          (ele: { isOption: boolean }) => ele.isOption
        )?.note;
      } else {
        valueFormNew.inputOtherTag = "";
      }
      setCheckbox(
        screenMaster?.tags?.filter((element: { note: string }) =>
          basicInformation?.tags?.some(
            (tag: { note: string }) => tag.note === element.note
          )
        )
      );

      form.setFieldsValue(valueFormNew);
    } else {
      const results = { ...paymentTerm };
      const category1 = dropDownPayment.dropDown1?.find((ele: any) =>
        paymentTerm?.categories?.some(
          (value: any) => value.categoryId === ele.id
        )
      );
      const category2 = dropDownPayment.dropDown2?.find((ele: any) =>
        paymentTerm?.categories?.some(
          (value: any) => value.categoryId === ele.id
        )
      );
      const noteCategory2 = paymentTerm?.categories?.find((ele: any) =>
        dropDownPayment.dropDown2?.some(
          (value: any) => value.id === ele.categoryId
        )
      );

      if (category1) {
        results.payment_day = category1.id;
      } else {
        results.payment_day = dropDownPayment.dropDown1?.find(
          (ele: any) => ele.default
        )?.id;
      }
      if (category2) {
        results.payment_method = {
          select: category2.id,
          selectBonus: "",
          inputOther: noteCategory2.note || "",
        };
        if (
          paymentTerm.subCategories?.length > 0 &&
          category2.subCategory?.length > 0
        ) {
          const subCategory = category2.subCategory?.find(
            (sub: any) => sub.id === paymentTerm.subCategories[0].subCategoryId
          );
          if (subCategory) {
            results.payment_method = {
              ...results.payment_method,
              selectBonus: subCategory.id,
            };
          }
        }
        setMoneySelect(category2);
      } else {
        results.payment_method = {
          // select: dropDownPayment.dropDown2?.find((ele: any) => ele.default)?.id,
          select: "",
          selectBonus: "",
          inputOther: "",
        };
      }

      formPayment.setFieldsValue({
        ...results,
        phoneNumber: results?.phoneNumber
          ? helpers.toPhoneNumberFormat(results.phoneNumber.replaceAll("-", ""))
          : undefined,
        fax: results?.fax
          ? helpers.toPhoneNumberFormat(results.fax.replaceAll("-", ""))
          : undefined,
      });
    }
  }, [
    currentTab,
    basicInformation,
    screenMaster,
    paymentTerm,
    screenMasterPayment,
  ]);
  const getBasicInformation = async (params: any) => {
    try {
      dispatch(setLoading(true));
      const response = await ci001Service.getBasicInformation(params);
      if (response.data.results) {
        setBasicInformation(response.data.results);
        setSummaryId(response.data.results?.id);
        if (response.data.results?.documentCi?.length != 0) {

          const result = response.data.results?.documentCi.map((item: any, index: number) => {
            return {
              key: `${Math.random()} +${index}`,
              uid: `${Math.random()} +${index}`,
              name: item,
              path: `construction/${constructionId}/ci_quotation_summary/${item}`,
            }
          })
          setFileList(result);
        } else {
          setFileList([]);
        }
      } else {
        setBasicInformation({});
        setSummaryId("");
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      setBasicInformation({});
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getScreenMaster = async () => {
    try {
      dispatch(setLoading(true));
      const [screen1, screen2] = await Promise.all([
        ci001Service.getScreenMaster({ screenId: "1" }),
        ci001Service.getScreenMaster({ screenId: "2" }),
      ]);

      setScreenMaster(screen1.data.results);
      setScreenMasterPayment(screen2.data.results);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getPaymentTerm = async (params: any) => {
    try {
      dispatch(setLoading(true));
      const response = await ci001Service.getPaymentTerm(params);
      if (response.data.results?.documentCi?.length != 0) {

        const result = response.data.results?.documentCi.map((item: any, index: number) => {
          return {
            key: `payment${Math.random()}`,
            uid: `payment${Math.random()}`,
            name: item,
            path: `construction/${constructionId}/ci_payment_summary/${item}`,
          }
        })
        setFileListPaymentTerm(result);
      } else {
        setFileListPaymentTerm([]);
      }
      setPaymentTerm(response.data.results);

    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      setPaymentTerm({});
    } finally {
      dispatch(setLoading(false));
    }
  };

  const updateBasicInformation = async (params: any) => {
    try {
      dispatch(setLoading(true));
      const response = await ci001Service.updateBasicInformation(params);
      const resultDocument = fileList.filter((element: any) => element.isAddNew)
      if (resultDocument.length != 0) {
        try {
          handleUploadFile(resultDocument)
          dispatch(setLoading(true));
        } catch (error: any) {
          ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        }
        finally {
          dispatch(setLoading(false));
        }
      }
      setIsRefresh(!isRefresh);
      setIsEdit(false);
      setIsChangeEdit(false);
      SuccessNotification(
        response?.data?.message ?? NOTIFICATION_TITLE.SUCCESS
      );
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const updatePaymentTerm = async (params: any) => {
    try {
      dispatch(setLoading(true));
      const response = await ci001Service.updatePaymentTerm(params);

      const resultDocument = fileListPaymentTerm.filter((element: any) => element.isAddNew)
      if (resultDocument.length != 0) {
        try {
          handleUploadFile(resultDocument)
          dispatch(setLoading(true));
        } catch (error: any) {
          ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        }
        finally {
          dispatch(setLoading(false));
        }
      }
      setIsRefreshPayment(!isRefreshPayment);
      setIsEdit(false);
      setIsChangeEdit(false);
      SuccessNotification(
        response?.data?.message ?? NOTIFICATION_TITLE.SUCCESS
      );
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getApprovalProcess = async (params: any) => {
    try {
      dispatch(setLoading(true));
      const response = await ci001Service.getApprovalProcess(params);
      const listApproverResult = response?.data?.results.listApprover ?? [];
      const submitter = {
        ...response?.data?.results.submitter,
        isSubmitter: true
      }
      if (listApproverResult.length > 0) {
        if (response?.data?.results.submitter) {
          setListApprovalProcess([submitter, ...listApproverResult]);
        }
        else {
          setListApprovalProcess(listApproverResult);
        }
      }
      setCurrentUserApproval(response?.data?.results?.currentUserApproval);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const postApprovalProcess = async (data?: any) => {
    try {
      dispatch(setLoading(true));

      const response = await ci001Service.postApprovalProcess(data);
      SuccessNotification(
        response?.data?.message ?? NOTIFICATION_TITLE.SUCCESS
      );
      formReject.resetFields();
      setRefreshApprove(!refreshApprove);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };
  const resetApproval = async (data: any) => {
    try {
      dispatch(setLoading(true));

      const response = await ci001Service.resetApproval(data);
      SuccessNotification(
        response?.data?.message ?? NOTIFICATION_TITLE.SUCCESS
      );
      setRefreshApprove(!refreshApprove);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const onChangeInquiryCategorySelect = (value: any) => {
    const results = screenMaster?.categorys.find(
      (element: any) => element.id === value
    );
    setInquiryCategory(results);
  };

  const onChangeDecisionMethod = (value: any) => {
    const results = screenMaster?.categorys.find(
      (element: any) => element.id === value
    );
    setDecisionMethod(results);
  };

  const onClickCheckEdit = (value: boolean) => {
    setIsEdit(value);
  };

  const onChangeCurrentTab = (value: string) => {
    if (isEdit && isChangeEdit) {
      ConfirmModal({
        onOk: () => {
          searchParams.set(PARAM_TAB.name, value);
          setSearchParams(searchParams);
          setIsEdit(false);
          setIsChangeEdit(false);
        },
        onCancel: () => { },
        title: MESSAGE.MESSAGE_020,
        description: MESSAGE.MESSAGE_022_1,
        extraDescription: MESSAGE.MESSAGE_022_2,
        canceText: "キャンセル",
        okText: "はい",
      });
    } else {
      searchParams.set(PARAM_TAB.name, value);
      setSearchParams(searchParams);
      setIsEdit(false);
    }
  };

  const handleCombineDateTime = (date: any) => {
    if (!date) return null;
    return date?._d;
  };

  const onSubmitBasic = (value: any) => {
    const results = { ...basicInformation };
    results.address1 = value.address1 ?? "";
    results.address2 = value.address2 ?? "";
    results.address3 = value.address3 ?? "";
    results.categoryNote = value.categoryNote ?? "";
    results.cicName = value.cicName ?? "";
    results.constructionCompanyName = value.constructionCompanyName ?? "";
    results.constructionFromDate = value.constructionFromToDate
      ? value.constructionFromToDate[0]?._d
      : null;
    results.constructionToDate = value.constructionFromToDate
      ? value.constructionFromToDate[1]?._d
      : null;
    results.description = value.description ?? "";
    results.customerName = value.customerName ?? "";
    results.constructionName = value.constructionName ?? "";
    results.picName = value.picName ?? "";
    results.constructionLocation = value.constructionLocation ?? "";
    results.cicName = value.cicName ?? "";
    results.investorName = value.investorName ?? "";
    results.designCompanyName = value.designCompanyName ?? "";
    results.constructionCompanyName = value.constructionCompanyName ?? "";
    results.constructionEstimate = value.constructionEstimate === "" || value.constructionEstimate === null || value.constructionEstimate === undefined
      ? null
      : Number(value.constructionEstimate);
    results.constructionDetailFromDate = handleCombineDateTime(
      value.constructionDetailFromDate
    );
    results.constructionDetailHour = handleCombineDateTime(
      value.constructionDetailHour
    );

    results.picEstimateName = value.picEstimateName ?? "";
    results.quotationFromDate = handleCombineDateTime(value.quotationFromDate);
    results.quotationHour = handleCombineDateTime(value.quotationHour);
    results.estimationBidFromDate = handleCombineDateTime(
      value.estimationBidFromDate
    );
    results.estimationBidHour = handleCombineDateTime(value.estimationBidHour);
    results.tags = value.tags.checkbox?.map((tag: string) => {
      return {
        note: tag,
      };
    });

    const filterTag = screenMaster?.tags?.filter((element: { note: string }) =>
      value.tags.checkbox?.some((ele: string) => ele === element.note)
    );

    const found = filterTag?.some(
      (ele: { showNote: boolean }) => ele?.showNote
    );

    if (value.inputOtherTag && found) {
      results.tags.push({ note: value.inputOtherTag });
    }
    results.categories = [];
    const category1 = screenMaster.categorys?.find(
      (ele: any) => ele.id === value.estimation.select
    );
    const category2 = screenMaster.categorys?.find(
      (ele: any) => ele.id === value.order
    );
    const category3 = screenMaster.categorys?.find(
      (ele: any) => ele.id === value.payment.select
    );

    results.categories = [];

    if (category1) {
      results.categories.push({
        categoryId: category1.id,
        note: value.estimation.inputOther || "",
      });
    }
    if (category2) {
      results.categories.push({
        categoryId: category2.id,
        note: "",
      });
    }
    if (category3) {
      results.categories.push({
        categoryId: category3.id,
        note: value.payment.inputOther || "",
      });
    }

    results.documentCi = fileList.map((item: any) => item.name)

    updateBasicInformation(results);
  };

  const onSubmitPaymentTerm = (value: any) => {
    const results = { ...paymentTerm };
    results.description = value.description ?? "";
    results.companyName = value.companyName ?? "";
    results.furiganaName = value.furiganaName ?? "";
    results.departmentLocation = value.departmentLocation ?? "";
    results.picName = value.picName ?? "";
    results.phoneNumber = value.phoneNumber ?? "";
    results.fax = value.fax ?? "";
    results.location = value.location ?? "";
    results.expireDate = value.expireDate ?? "";
    results.purchaseDate = value.purchaseDate ?? "";
    results.month = value.month + "" ?? "";
    results.cashPercent = value.cashPercent ?? null;
    results.billPercent = value.billPercent ?? null;
    results.site = value.site ?? "";
    results.note = value.note ?? "";
    results.categories = [];
    results.subCategories = [];

    const category1 = screenMasterPayment?.categorys?.find(
      (ele: any) => ele.id === value.payment_day
    );
    const category2 = screenMasterPayment?.categorys?.find(
      (ele: any) => ele.id === value.payment_method.select
    );

    if (category1) {
      results.categories.push({
        categoryId: category1.id,
        note: "",
      });
    }
    if (category2) {
      results.categories.push({
        categoryId: category2.id,
        note: value.payment_method.inputOther || "",
      });

      if (category2.subCategory?.length > 0) {
        const findSubCategory = category2.subCategory.find(
          (ele: any) => ele.id === value.payment_method.selectBonus
        )?.id;
        if (findSubCategory) {
          results.subCategories.push({
            subCategoryId: findSubCategory,
            note: "",
          });
        }
      }
    }

    results.documentCi = fileListPaymentTerm.map((item: any) => item.name)
    updatePaymentTerm(results);
  };

  const handleCancelSubmit = () => {
    if (isEdit && isChangeEdit) {
      ConfirmModal({
        onOk: () => {
          setIsEdit(false);
          setIsChangeEdit(false);
          setIsRefresh(!isRefresh);
          setIsRefreshPayment(!isRefreshPayment);
        },
        onCancel: () => { },
        title: MESSAGE.MESSAGE_020,
        description: MESSAGE.MESSAGE_022_1,
        extraDescription: MESSAGE.MESSAGE_022_2,
        canceText: "キャンセル",
        okText: "はい",
      });
    } else {
      setIsEdit(false);
    }
  };

  const onClickBreadcrumb = (callback: () => void) => {
    if (isEdit && isChangeEdit) {
      ConfirmModal({
        onOk: () => {
          setIsEdit(false);
          setIsChangeEdit(false);
          callback();
        },
        onCancel: () => { },
        title: MESSAGE.MESSAGE_020,
        description: MESSAGE.MESSAGE_022_1,
        extraDescription: MESSAGE.MESSAGE_022_2,
        canceText: "キャンセル",
        okText: "はい",
      });
    } else {
      callback();
      setIsEdit(false);
    }
  };

  const onClickMenu = (callback: (value: any) => void, value: any) => {
    if (isEdit && isChangeEdit) {
      ConfirmModal({
        onOk: () => {
          setIsEdit(false);
          setIsChangeEdit(false);
          callback(value);
        },
        onCancel: () => { },
        title: MESSAGE.MESSAGE_020,
        description: MESSAGE.MESSAGE_022_1,
        extraDescription: MESSAGE.MESSAGE_022_2,
        canceText: "キャンセル",
        okText: "はい",
      });
    } else {
      callback(value);
      setIsEdit(false);
    }
  };

  const onChangeCheckBox = (value: any) => {
    const filterCheckBox = screenMaster?.tags?.filter(
      (element: { note: string }) =>
        value.some((tag: string) => tag === element.note)
    );
    setCheckbox(filterCheckBox);
    const found = filterCheckBox?.some(
      (element: { showNote: boolean }) => element?.showNote
    );
    if (!found) {
      form.setFieldValue("inputOtherTag", "");
    }
  };

  const onChangeMoneySelect = (value: string) => {
    const results = screenMasterPayment?.categorys?.find(
      (screen: any) => screen.id === value
    );
    setMoneySelect(results);
  };

  const handleFormatPhone = (e: any, key: string) => {
    const phone = e.target.value;
    let results = "";
    let checkPhone = 0;
    if (phone?.length > 0) {
      const phoneList = phone.split("").filter((item: string) => item !== "-");
      if (phoneList.length <= 10) {
        phoneList.forEach((item: string, index: number) => {
          if (index === 5) {
            checkPhone = index;
          }
          if (index <= 2 || index === 4 || index === 5) {
            results = `${results}${item}`;
          } else if (index === 3) {
            results = `${results}-${item}`;
          } else {
            if (index === checkPhone + 1) {
              results = `${results}-${item}`;
            } else {
              results = `${results}${item}`;
            }
            if (index === checkPhone + 4) {
              checkPhone = index;
            }
          }
        });
      } else if (phoneList.length === 11) {
        phoneList.splice(3, 0, "-");
        phoneList.splice(8, 0, "-");
        results = phoneList.join("");
      } else {
        phoneList.forEach((item: string, index: number) => {
          if (index === 3) {
            checkPhone = index;
          }
          if (index <= 3) {
            results = `${results}${item}`;
          } else {
            if (index === checkPhone + 1) {
              results = `${results}-${item}`;
            } else {
              results = `${results}${item}`;
            }
            if (index === checkPhone + 4) {
              checkPhone = index;
            }
          }
        });
      }
    }
    form.setFieldValue([key], results);
    formPayment.setFieldValue([key], results);
  };

  const onChangeForm = () => {
    setIsChangeEdit(true);
  };

  const tagRender = useMemo(() => {
    if (!basicInformation.tags) return "";

    const foundOther = basicInformation?.tags?.find(
      (element: { note: string }) => {
        const find = screenMaster.tags?.find(
          (ele: { note: string }) => element.note === ele.note
        );
        return find?.showNote;
      }
    );
    if (!foundOther) {
      return basicInformation?.tags
        ?.map((element: { note: string }) => element.note)
        ?.join("、");
    }

    const foundValueOther = basicInformation?.tags?.find(
      (element: { isOption: boolean }) => element.isOption
    );
    const foundValueNotOther = basicInformation?.tags?.filter(
      (element: { note: string; isOption: boolean }) => {
        return !element.isOption && element.note !== foundOther.note;
      }
    );
    if (foundValueNotOther?.length === 0)
      return `その他 ${foundValueOther ? `(${foundValueOther.note})` : ""}`;

    return `${foundValueNotOther
      ?.map((ele: { note: string }) => ele?.note)
      ?.join("、")}、その他 ${foundValueOther ? `(${foundValueOther.note})` : ""
      }`;
  }, [basicInformation]);

  const handleApproveCI = (descPopup: string, titlePopup: string) => {
    setTitlePopup(titlePopup)
    setDescriptionPopup(descPopup)
    setIsApprove(true);
    setIsApproveOrReject(true);
  };

  const handleCancelApprove = () => {
    setIsApprove(false);
  };
  const handleRejectCI = () => {
    setDescriptionPopup("工事引合書を否認してもよろしいでしょうか？")
    setIsApprove(true);
    setIsApproveOrReject(false);
  };
  const checkAutApprove = (currentUser: any, listApprover: any[]) => {
    const checkAut = JSON.parse(localStorage.getItem("user") || "{}");

    if (projectManagerId == checkAut?.id) {
      if (checkAut?.id == currentUser?.id) {
        if (currentUser.priority !== 1) return TYPE_USER.manager_approve;
        return TYPE_USER.owner_submit;
      }
      return TYPE_USER.owner_not_submit;
    } else {
      if (userId == checkAut?.id) return TYPE_USER.user_create;
      if (checkAut?.id == currentUser?.id) {
        if (currentUser.reject) {
          return TYPE_USER.view;
        }
        return TYPE_USER.approve;
      } else {
        return TYPE_USER.view;
      }
    }
  };
  const funcCheckEdit = (currentUser: any) => {
    const checkAut = +JSON.parse(localStorage.getItem("user") || "{}")?.id;
    if (!checkAut || !projectManagerId || !userId) return false;
    return (
      checkAut === +projectManagerId && currentUser?.id === +projectManagerId
    );
  };
  const handleOkApprove = (constructionId: string, note: any) => {
    setIsApprove(false);
    if (isApproveOrReject) {
      postApprovalProcess({
        constructionId: Number(constructionId),
        approval: true,
        note: note.rejectReason,
      });
    } else {
      postApprovalProcess({
        constructionId: Number(constructionId),
        approval: false,
        note: note.rejectReason,
      });
    }
  };

  const handleChangeNoteApprove = (e: any) => {
    setNoteApprove(helpers.toLowerCaseNonAccentVietnamese(e.target.value));
  };

  const handleWithDraw = (constructionId: string | undefined) => {
    ConfirmModal({
      onOk: () => {
        resetApproval({
          constructionId: Number(constructionId),
        });
      },
      onCancel: () => { },
      title: MESSAGE.MESSAGE_TITLE_CM016,
      description: MESSAGE.MESSAGE_DESCRIPTION_CI,
      canceText: "キャンセル",
      okText: "はい",
    });
  };

  const findElementWithCode = (items: any, code: string) => {
    const element = items.find((item: any) => item.code === code);

    if (element?.note && element?.note !== "") {
      return `${element?.description ? element.description : ""} (${element.note
        })`;
    } else {
      return element?.description ? element.description : "";
    }
  };

  const joinArr = (items: any[]) => {
    const listCRL: string[] = [];
    const referL: string[] = [
      "建物概要",
      "現場案内図",
      "その他",
      "平面図",
      "総合工程表",
      "電灯コンセント図",
    ];
    let cm = "";
    let isTrue = false;
    items.forEach((item: any) => {
      if (referL.includes(item.note)) {
        if (item.note !== "その他") {
          listCRL.push(item.note);
        } else {
          isTrue = true;
        }
      } else {
        cm = item.note;
      }
    });

    if (isTrue) listCRL.push(`その他 (${cm})`);

    return listCRL.join(",");
  };

  const joinCategory = (ca: any[], sub: any[]) => {
    const textA = ca.find((item: any) => item.code === "payment_method");

    const textB =
      sub.find((item: any) => item.code === "payment_method")?.description ??
      "";

    let textLA = "";
    if (textA?.description === "その他") {
      if (textA.note !== "") {
        textLA = `その他 (${textA.note})`;
      } else {
        textLA = "その他";
      }
    } else {
      textLA = textA?.description ?? "";
    }

    return textLA !== "" ? `${textLA} ${textB !== "" ? `(${textB})` : ""}` : "";
  };

  const handleLoadingExport = (value: boolean) => {
    dispatch(setLoading(value));
  };

  const handleExportData = () => {
    const raw: any = {};
    const sheetName = "工事引合書_テンプレート ";
    const fileName = "工事引合書_テンプレート ";

    const data: any = {
      tableOne: {
        otherField: basicInformation?.picName ?? "",
        data: [
          {
            name: "得意先",
            value: basicInformation?.customerName ?? "",
          },
          {
            name: "工事名",
            value: basicInformation?.constructionName ?? "",
          },
          {
            name: "施工場所",
            value: basicInformation?.constructionLocation ?? "",
          },
          {
            name: "工事内容",
            value: basicInformation?.description ?? "",
          },
          {
            name: "施主",
            value: basicInformation?.investorName ?? "",
          },
          {
            name: "設計管理業者",
            value: basicInformation?.designCompanyName ?? "",
          },
          {
            name: "建設業者",
            value: basicInformation?.cicName ?? "",
          },
          {
            name: "工事費予想",
            value: basicInformation?.constructionEstimate
              ? `${basicInformation?.constructionEstimate} 円`
              : "",
          },
          {
            name: "工期",
            value: ` ${basicInformation?.constructionFromDate &&
              basicInformation?.constructionFromDate !== ""
              ? moment(basicInformation?.constructionFromDate).format(
                COMMON.FORMAT_DATE_CI
              )
              : "　　　　　　年　　　　　月　　　　　日 　　　　　"
              } 〜 ${basicInformation?.constructionToDate &&
                basicInformation?.constructionToDate !== ""
                ? moment(basicInformation?.constructionToDate).format(
                  COMMON.FORMAT_DATE_CI
                )
                : "　　　　　　年　　　　　月　　　　　日"
              }`,
          },
        ],
      },
      tableTwo: {
        data: [
          {
            name: "参加業者",
            value: basicInformation?.constructionCompanyName ?? "",
          },
          {
            name: "引合区分",
            value: findElementWithCode(
              basicInformation?.categories,
              "estimation"
            ),
          },
          {
            name: "発注区分",
            value: findElementWithCode(basicInformation?.categories, "order"),
          },
          {
            name: "決定方法",
            value: findElementWithCode(basicInformation?.categories, "payment"),
          },
          {
            name: "引合",
            value1: `${basicInformation?.quotationFromDate &&
              basicInformation?.quotationFromDate !== ""
              ? moment(basicInformation?.quotationFromDate).format(
                COMMON.FORMAT_DATE_CI
              )
              : "　　　月　　　日"
              }`,
            value2: `${basicInformation?.quotationHour &&
              basicInformation?.quotationHour !== ""
              ? moment(basicInformation?.quotationHour).format(
                COMMON.FORMAT_TIME
              )
              : "　　　時　　　分"
              }`,
            value3: `場所   ${basicInformation?.address1}`,
          },
          {
            name: "現場説明",
            value1: `${basicInformation?.estimationBidFromDate &&
              basicInformation?.estimationBidFromDate !== ""
              ? moment(basicInformation?.estimationBidFromDate).format(
                COMMON.FORMAT_DATE_CI
              )
              : "　　　月　　　日"
              }`,
            value2: `${basicInformation?.estimationBidHour &&
              basicInformation?.estimationBidHour !== ""
              ? moment(basicInformation?.estimationBidHour).format(
                COMMON.FORMAT_TIME
              )
              : "　　　時　　　分"
              }`,
            value3: `場所   ${basicInformation?.address2}`,
          },
          {
            name: "見積入札",
            value1: `${basicInformation?.constructionDetailFromDate &&
              basicInformation?.constructionDetailFromDate !== ""
              ? moment(basicInformation?.constructionDetailFromDate).format(
                COMMON.FORMAT_DATE_CI
              )
              : "　　　月　　　日"
              }`,
            value2: `${basicInformation?.constructionDetailHour &&
              basicInformation?.constructionDetailHour !== ""
              ? moment(basicInformation?.constructionDetailHour).format(
                COMMON.FORMAT_TIME
              )
              : "　　　時　　　分"
              }`,
            value3: `場所   ${basicInformation?.address3}`,
          },
          {
            name: "現場資料",
            value: `${joinArr(basicInformation?.tags)}` ?? "",
          },
        ],
      },
      tableThree: {
        companyName: paymentTerm?.companyName,
        nameKana: paymentTerm?.furiganaName ?? "",
        tell: paymentTerm?.phoneNumber ?? "",
        fax: paymentTerm?.fax ?? "",
        location: paymentTerm?.location ?? "",
        departmentLocation: paymentTerm?.departmentLocation ?? "",
        picName: paymentTerm?.picName ?? "",
      },
      tableFour: {
        stringDateOne: `（${paymentTerm?.expireDate}）日　締切 （${paymentTerm?.month}）ヶ月後 （${paymentTerm?.purchaseDate}）日　支払`,
        stringDateTwo: `現　金 （${Number(
          paymentTerm?.cashPercent
        )?.toLocaleString("en-US", {
          maximumFractionDigits: 2,
        })}） ％ 手　形 （${Number(paymentTerm?.billPercent)?.toLocaleString(
          "en-US",
          {
            maximumFractionDigits: 2,
          }
        )}） ％ サイト （${paymentTerm?.site}）日`,
        note: paymentTerm?.note ?? "",
        categoryOne:
          paymentTerm?.categories.find(
            (item: any) => item.code === "payment_day"
          )?.description ?? "",
        categoryTwo: joinCategory(
          paymentTerm?.categories,
          paymentTerm?.subCategories
        ),
      },
      tableFive: {
        role: listApprovalProcess.map((item: any) => item?.role?.name ?? ""),
        name: listApprovalProcess.map((item: any) => item.fullName),
        date: listApprovalProcess.map((item: any) =>
          item.approveDate !== ""
            ? moment(item.approveDate).format(COMMON.FORMAT_DATE_CI)
            : ""
        ),
      },
    };
    exportConstructionRequest(
      raw,
      sheetName,
      fileName,
      data,
      handleLoadingExport
    );
  };

  const getProjectForPermission = async (projectId: string) => {
    dispatch(setLoading(true));
    try {
      const response = await ci001Service.getDetailProject({
        projectId: projectId,
      });
      setDetailProject(response.results);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const checkApproved = (listApproval: any[]) => {
    let flag = true;
    if (listApproval.length !== 0) {
      listApproval.forEach((element: any) => {
        if (element.isApprove || element.reject) flag = false;
      });
    }
    return flag;
  };

  const handExportTemplate = async (
    projectManagerId: string | undefined,
    constructionId: string | undefined
  ) => {
    try {
      dispatch(setLoading(true));

      const projectName = searchParams.get("projectName");
      const params = {
        projectId: projectManagerId,
        constructionId: constructionId,
        projectName,
      };
      const response = await ci001Service.handExportTemplate(params);

      doExportForResponse(
        response,
        projectName +
        "_" +
        basicInformation.constructionName +
        "_" +
        FILE_NAME.CI001 +
        ".xlsx"
      );
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const beforeUpload = (file: RcFile): boolean => {
    const isJpgOrPng = ["image/jpeg", "image/png", "application/pdf", "image/jpg"].includes(file.type);
    const fileSizeInMB = file.size / 1024 / 1024
    const isLt8M = fileSizeInMB < 8;

    if (!isJpgOrPng || !isLt8M) {
      if (!errorDisplayed) {
        if (!isJpgOrPng) {
          message.error("ファイルの形式が不正です。");
        } else if (!isLt8M) {
          message.error("8MB以上のファイルはアップロードできません。");
        }
        errorDisplayed = true;
      }
      return false;
    }
    return true;
  };

  const checkDuplicateFileName = (fileList: RcFile[], newFiles: RcFile[]): boolean => {
    const existingFileNames = fileList.map(file => file.name);
    return newFiles.some(file => existingFileNames.includes(file.name));
  };

  let errorDisplayed = false;
  let messageDisplayed = false;

  const onChangeUpload = (value: any): void => {
    if (value?.fileList && value?.fileList.length > 0) {
      let filteredFiles: RcFile[] = value.fileList.filter((file: RcFile) => beforeUpload(file));
      const maxFiles = 20;

      if (currentTab === PARAM_TAB.current) {
        handleFileUpload(fileList, setFileList, filteredFiles, maxFiles);
      } else {
        handleFileUpload(fileListPaymentTerm, setFileListPaymentTerm, filteredFiles, maxFiles);
      }
    }
    setIsChangeEdit(true)
  };

  const handleFileUpload = (
    fileList: RcFile[],
    setFileList: React.Dispatch<React.SetStateAction<RcFile[]>>,
    filteredFiles: RcFile[],
    maxFiles: number
  ): void => {
    const currentFileListCount = fileList.length;
    const totalFilesCount = currentFileListCount + filteredFiles.length;

    // Remove duplicate file names
    const hasDuplicateFileName = checkDuplicateFileName(fileList, filteredFiles);
    if (hasDuplicateFileName) {
      if (!messageDisplayed) {
        message.error("ファイル名が重複しております。");
        messageDisplayed = true;
      }
      filteredFiles = filteredFiles.filter(file => !checkDuplicateFileName(fileList, [file]));
    }

    // Check for max file count
    if (totalFilesCount > maxFiles) {
      if (!messageDisplayed) {
        message.error("最大20ファイルしかアップロードできません。");
        messageDisplayed = true;
      }
      filteredFiles = filteredFiles.slice(0, maxFiles - currentFileListCount);
    }

    const resultForFileList = filteredFiles.map(item => ({ ...item, isAddNew: true }));
    setFileList((prevFileList) => {
      // Filter out files with duplicate names before adding new files
      const filteredPrevFileList = prevFileList.filter(
        prevFile => !resultForFileList.some(newFile => newFile.name === prevFile.name)
      );
      return [...filteredPrevFileList, ...resultForFileList];
    });
  };


  const onDropUpload = (value: any) => {
    ConfirmModalAsync({
      onOk: async () => {
        if (currentTab === PARAM_TAB.current) {

          if (value.isAddNew) {
            const result = fileList.filter((item: any) => item.name != value.name)
            setFileList(result)
            SuccessNotification(NOTIFICATION_TITLE.SUCCESS);
          } else {
            await deleteFileCI(
              `construction/${constructionId}/ci_quotation_summary/${value.name}`
            )
            const result = fileList.filter((item: any) => item.name != value.name)
            setFileList(result)
          }
        } else {


          if (value.isAddNew) {
            const result = fileListPaymentTerm.filter((item: any) => item.name != value.name)
            setFileListPaymentTerm(result)
            SuccessNotification(NOTIFICATION_TITLE.SUCCESS);
          } else {
            await deleteFileCI(
              `construction/${constructionId}/ci_payment_summary/${value.name}`
            )
            const result = fileListPaymentTerm.filter((item: any) => item.name != value.name)
            setFileListPaymentTerm(result)

          }
        }

      },
      onCancel: () => { },
      title: "削除確認",
      className: "confirm__modal ",
      description: "このファイルを削除してもよろしいでしょうか？",
      canceText: "キャンセル",
      okText: "削除",
    });
  };

  const deleteFileCI = async (keyName: any) => {
    try {
      dispatch(setLoading(true));
      const response = await ci001Service.deleteFileCI(keyName);
      SuccessNotification(response?.message, "");
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getPdfMap = async (value: any) => {
    const fileNameModal = value.name?.split(".").slice(0, -1).join(".");
    if (value.path)
      try {
        const params = {
          keyName: value.path,
        };

        const response = await ci001Service.getPresignLinkS3(params);

        if (currentTab === PARAM_TAB.current) {
          setPdfUrl(response.results);
          setFileName(fileNameModal)
        } else {
          setPdfUrlPaymentTerm(response.results);
          setFileNamePaymentTerm(fileNameModal)
        }
        const checkFile = response.results
          ?.split(".")
          .pop()
          ?.split("?")
          .shift();

        if (checkFile === "pdf") return dispatch(showPdfViewerModal());
        dispatch(showImageViewerModal());
      } catch (error: any) {
        ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      }
    else {
      if (!value.url && !value.preview) {
        value.preview = await helpers.getBase64(value.originFileObj as RcFile);
      }

      if (currentTab === PARAM_TAB.current) {
        setPdfUrl(value.url || (value.preview as string));
        setFileName(fileNameModal)

      } else {
        setPdfUrlPaymentTerm(value.url || (value.preview as string));
        setFileNamePaymentTerm(fileNameModal)

      }
      if (value.type.includes("image/")) {
        dispatch(showImageViewerModal());
      } else {
        dispatch(showPdfViewerModal());
      }
    }
  };

  const getLinkPresignUpload = async (params: any) => {
    try {
      const response = await ci001Service.getLinkPresignUpload(params);

      if (response) {
        return response.results;
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const uploadTos3 = async (url: any, data: any) => {
    try {
      const response = await ci001Service.uploadToS3(url, data);
      return response.results;
    } catch (error) {
      throw error;
    }
  };
  const uploadToAwsS3 = async (keyName: string, originFileObj: any) => {

    try {
      const linkPreSignUpload = await getLinkPresignUpload({
        keyName: keyName,
      });
      await uploadTos3(linkPreSignUpload, originFileObj);
      dispatch(setLoading(true));
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));

    }
  };

  const handleUploadFile = async (data: any) => {

    const promiseAllCollection: any[] = [];
    data.forEach(
      (element: any) => {
        if (element?.originFileObj?.name)
          promiseAllCollection.push(
            uploadToAwsS3(
              `construction/${constructionId}/${currentTab === PARAM_TAB.current ? "ci_quotation_summary" : "ci_payment_summary"}/${element?.originFileObj?.name}`
              ,
              element?.originFileObj
            )
          );
      }
    );

    await Promise.all(promiseAllCollection);
  };

  return {
    getBasicInformation,
    getScreenMaster,
    getPaymentTerm,
    updateBasicInformation,
    updatePaymentTerm,
    onChangeInquiryCategorySelect,
    onChangeDecisionMethod,
    onClickCheckEdit,
    onChangeCurrentTab,
    onSubmitBasic,
    onChangeCheckBox,
    onChangeMoneySelect,
    handleFormatPhone,
    handleCancelSubmit,
    onSubmitPaymentTerm,
    onChangeForm,
    setIsEdit,
    getApprovalProcess,
    postApprovalProcess,
    handleApproveCI,
    handleRejectCI,
    handleCancelApprove,
    checkAutApprove,
    handleOkApprove,
    handleChangeNoteApprove,
    handleWithDraw,
    onClickBreadcrumb,
    onClickMenu,
    funcCheckEdit,
    handleExportData,
    getProjectForPermission,
    checkApproved,
    handExportTemplate,
    onChangeUpload,
    onDropUpload,
    getPdfMap,

    form,
    formPayment,
    formReject,
    isRefresh,
    isRefreshPayment,
    checkbox,
    moneySelect,
    categoryValue,
    dropDownPayment,
    categoryPayment,
    tagRender,
    basicInformation,
    screenMaster,
    dropDownBasicInfo,
    summaryId,
    paymentTerm,
    inquiryCategory,
    decisionMethod,
    isEdit,
    isChangeEdit,
    currentTab,
    listApprovalProcess,
    currentUserApproval,
    isApprove,
    isApproveOrReject,
    noteApprove,
    refreshApprove,
    detailProject,
    fileList,
    pdfUrl,
    fileListPaymentTerm,
    pdfUrlPaymentTerm,
    descriptionPopup,
    titlePopup,
    fileName,
    fileNamePaymentTerm
  };
};

export default CI001Handler;
