import { RootState } from "./../../../../../store";
import { useState } from "react";
import { Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";

import ConfirmModal from "../../../../../common/components/modal/ConfirmModal";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import moment from "moment";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import helpers from "../../../../../common/helpers/common";
import {
  CertificateMemberEntity,
  DetailMemberEntity,
  MemberUpdateEntity,
} from "../../../../OM/OM006/entity/Entity";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import {
  CertificateEntity,
  MemberCertificateEntity,
} from "../../entity/Entity";
import { setRefreshP01 } from "../slice/Slice";
import { setAuth } from "../../../../../common/slice/AuthSlice";
import { P01UseCase } from "../../usecase/ServiceImpl";
import { text } from "../../../../../common/global";

export const CERT_LINE_DEFAULT = {
  isDone: false,
  lines: {},
};

const DEFAULT_STATE = {
  certLines: CERT_LINE_DEFAULT,
  certType: [],
  memberName: "",
  detailMember: null,
  isDirty: false,
  isEditing: false,
};

const Handler = (service: P01UseCase) => {
  // LIB FUNCTION
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  // STATE
  const [fileData, setFileData] = useState<any>();
  const [isDirty, setIsDirty] = useState(DEFAULT_STATE.isDirty);
  const [certType, setCertType] = useState<CertificateEntity[]>(
    DEFAULT_STATE.certType
  );
  const [detailMember, setDetailMember] = useState<DetailMemberEntity | null>(
    DEFAULT_STATE.detailMember
  );
  const [certLines, setCertLines] = useState<any>(DEFAULT_STATE.certLines);
  const [memberName, setMemberName] = useState<string>(
    DEFAULT_STATE.memberName
  );
  const [isEditing, setIsEditing] = useState<boolean>(DEFAULT_STATE.isEditing);
  const authUser = useSelector((state: RootState) => state.auth.authData);

  // FUNCTION
  const getAvatarBinary = (value: any) => {
    setFileData(value);
  };

  const onNameChange = (e: any) => {
    setMemberName(e.target.value);
  };

  const setInitialForm = (detailMember: DetailMemberEntity | null) => {
    if (!detailMember) return;
    // default form item value
    const defaultValue: any = {
      name: detailMember.fullName,
      id: detailMember.username,
      email: detailMember.emailAddress,
      phoneNumber: helpers.toPhoneNumberFormat(detailMember.phoneNumber),
      address: detailMember.address,
    };

    if (detailMember.branch)
      defaultValue.branchId = detailMember.branch.id + "";

    if (detailMember?.role?.id) defaultValue.roleId = detailMember.role.id + "";

    // CCUSID
    if (detailMember.ccusId) {
      defaultValue.CCUSID = helpers.convertToCCUSFormat(detailMember.ccusId);
      if (detailMember.ccusExpiredDate)
        defaultValue.expirationDate = moment(detailMember.ccusExpiredDate);
    }

    // certifactes
    (detailMember?.certificates ?? []).forEach(
      (element: CertificateMemberEntity) => {
        defaultValue[`cert${element.category.id}${element.id}`] = {
          name: element.name,
          issueDate: element?.issueDate ? moment(element.issueDate) : null,
          registrationCode: element.registrationCode,
          issuer: element.issuer,
          expireDate: element?.expireDate ? moment(element.expireDate) : null,
          source: element?.source
        };
      }
    );

    form.setFieldsValue(defaultValue);
  };

  const setCertificateSection = (detailMember: DetailMemberEntity | null) => {
    // certifactes
    const certTypes: any = {};
    (detailMember?.certificates ?? []).forEach(
      (element: CertificateMemberEntity) => {
        if (certTypes[`cert${element.category.id}`])
          certTypes[`cert${element.category.id}`].push(element);
        else {
          certTypes[`cert${element.category.id}`] = [element];
        }
      }
    );
    setCertLines({
      isDone: true,
      lines: certTypes,
    });

    setCertLines((prevState: any) => ({ ...prevState, lines: certTypes }));

    setCertLines((prevState: any) => ({
      ...prevState,
      isDone: true,
    }));
  };

  const getMemberDetail = async () => {
    try {
      dispatch(setLoading(true));

      const userId = authUser?.id;
      if (!userId) return;
      const detailMemberData = await service.getDetailMember({
        id: userId,
      });

      // check avatar with auth information
      const authDataObj = structuredClone(authUser);
      if (detailMemberData?.avatar !== authUser.avatar) {
        authDataObj.avatar = detailMemberData?.avatar ?? "";
      }
      if (detailMemberData?.fullName !== authUser.fullName) {
        authDataObj.fullName = detailMemberData?.fullName ?? "";
      }
      dispatch(setAuth(authDataObj));
      setMemberName(detailMemberData?.fullName ?? "");
      setCertificateSection(detailMemberData);
      setDetailMember(detailMemberData);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getCertificateType = async () => {
    try {
      dispatch(setLoading(true));
      const certificateTypes = await service.getCertificateType();
      setCertType(certificateTypes);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getRoleList = async (params: any) => {
    return await service.getRoleList(params);
  };

  const getBranchList = async (params: any) => {
    return await service.getBranchList(params);
  };

  const updateMember = async (formData: any) => {
    try {
      dispatch(setLoading(true));

      if (!detailMember?.id) return;
      // avatar
      const avatarFormValueArr = formData?.avatar ?? [];
      const avatarFileName =
        avatarFormValueArr.length === 0
          ? undefined
          : avatarFormValueArr[avatarFormValueArr.length - 1];

      // certificates
      const memberCertificates: MemberCertificateEntity[] = [];
      const formProperties = Object.keys(formData);
      formProperties.forEach((element: string) => {
        if (element.startsWith("cert") && formData[`${element}`].name) {
          memberCertificates.push({
            categoryId: parseInt(formData[`${element}`].certTypeId),
            expireDate: formData[`${element}`].expireDate
              ? formData[`${element}`].expireDate.toISOString()
              : "",
            id: parseInt(formData[`${element}`].certId),
            issueDate: formData[`${element}`].issueDate
              ? formData[`${element}`].issueDate.toISOString()
              : "",
            issuer: formData[`${element}`].issuer ?? "",
            registrationCode: formData[`${element}`].registrationCode ?? "",
            name: formData[`${element}`].name ?? "",
            source: formData[`${element}`].source ?? text('MEMBER.manual')
          });
        }
      });

      const data: MemberUpdateEntity = {
        avatar: avatarFileName?.name ?? undefined,
        id: detailMember?.id ?? 0,
        certificates: memberCertificates,
        email: formData.email,
        fullName: formData.name,
        username: formData.id,
        phoneNumber: formData.phoneNumber.replaceAll("-", ""),
        roleId: detailMember?.role?.id ?? null,
        address: formData?.address ?? "",
        ccusId: formData.CCUSID || "",
        ccusExpiredDate: formData?.expirationDate?.toISOString() || "",
        groupId: detailMember?.group?.id,
      };
      const response = await service.updateMember(data);
      if (formData.avatar) {
        // Nếu người dùng nhập avatar
        const preSignResult = await service.getPresignAvatarUpload({
          avatar: avatarFileName.name,
          userId: detailMember.id,
        });
        await service.uploadAvatar(
          fileData,
          preSignResult.results,
          fileData?.type
        );
        await service.updateMemberAvatar({
          avatar: fileData?.name ?? "",
          userId: detailMember.id,
        });
      } else if (!formData.avatar && !formData.linkAvatar) {
        await service.updateMemberAvatar({
          avatar: "",
          userId: detailMember.id,
        });
      }
      resetAllState();
      dispatch(setRefreshP01());
      SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const resetAllState = () => {
    form.resetFields();
    setIsDirty(DEFAULT_STATE.isDirty);
    setIsEditing(DEFAULT_STATE.isEditing);
    setFileData(undefined);
    setDetailMember(DEFAULT_STATE.detailMember);
    setCertLines(DEFAULT_STATE.certLines);
    setMemberName(DEFAULT_STATE.memberName);
  };

  const resetState = () => {
    form.resetFields();
    setIsDirty(DEFAULT_STATE.isDirty);
    setIsEditing(DEFAULT_STATE.isEditing);
  };

  const onCancel = () => {
    if (!isDirty) {
      resetState();
      return;
    }
    ConfirmModal({
      onOk: () => resetState(),
      className: "confirm__modal",
      title: MESSAGE.MESSAGE_020,
      description: MESSAGE.MESSAGE_022_1,
      extraDescription: MESSAGE.MESSAGE_022_2,
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_MODAL,
      isCenterWithoutMenu: true,
    });
  };

  const checkDirty = (): void => {
    const formValue = form.getFieldsValue();
    const formProps = Object.keys(formValue);
    for (let i = 0; i < formProps.length; i++) {
      if (form.isFieldTouched(formProps[i])) {
        return setIsDirty(true);
      }
    }
    setIsDirty(false);
  };
  const changeEditStatus = (value: boolean) => {
    if (value) setInitialForm(detailMember);

    setIsEditing(value);
  };
  const onExpirationDateChange = (
    value: moment.Moment | null,
    dateString: string
  ) => {
    const ccusid = form.getFieldValue("CCUSID");
    if (!ccusid && !value)
      return form.setFields([
        {
          name: "CCUSID",
          errors: [],
        },
      ]);

    if (!ccusid)
      return form.setFields([
        {
          name: "CCUSID",
          errors: [MESSAGE.MESSAGE_LESS_THAN_14],
        },
      ]);
  };

  const onClickMenu = (callback: (value: any) => void, value: any) => {
    if (isEditing && isDirty) {
      ConfirmModal({
        onOk: () => {
          callback(value);
        },
        onCancel: () => { },
        title: MESSAGE.MESSAGE_020,
        description: MESSAGE.MESSAGE_021,
        canceText: LABEL_MESSAGE.CANCEL_MODAL,
        okText: LABEL_MESSAGE.OK_MODAL,
      });
    } else {
      callback(value);
    }
  };

  const removeAvatar = () => {
    form.resetFields(["avatar"]);
  };

  return {
    getBranchList,
    getRoleList,
    updateMember,
    onCancel,
    checkDirty,
    getCertificateType,
    getMemberDetail,
    getAvatarBinary,
    onNameChange,
    onExpirationDateChange,
    changeEditStatus,
    onClickMenu,
    removeAvatar,
    isEditing,
    memberName,
    detailMember,
    form,
    isDirty,
    certType,
    certLines,
  };
};

export default Handler;
