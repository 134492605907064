import { AxiosResponse } from 'axios';
import axiosConfig from '../../../../config/axiosConfig';
import { DoubleCheckPayload } from '../entity/Entity';

const Api = () => {
    const getAttendanceUserTimeSheet = async (params: {
        date: string;
        constructionId?: string;
        contractorConstructionId?: string;
        userId: string;
        timeOffset: number;
    }): Promise<AxiosResponse<any, any>> => {
        const url = '/attendance/user-time-sheet';
        return await axiosConfig.get(url, { params });
    };

    const postAttendanceDoubleCheck = async (
        data: DoubleCheckPayload[],
    ): Promise<AxiosResponse<any, any>> => {
        const url = '/attendance/double-check';
        return await axiosConfig.post(url, data);
    };

    return {
        getAttendanceUserTimeSheet,
        postAttendanceDoubleCheck,
    };
};

export default Api;
