import {
  Button,
  Form,
  Input,
  DatePicker,
  Select,
  Checkbox,
  Row,
  Col,
  InputNumber,
  TimePicker,
  Card,
  List,
  Upload,
} from "antd";
import React from "react";
import EditWhiteSvg from "../../../../../assets/icons/edit-white.svg";
import CalendarSvg from "../../../../../assets/icons/calendar.svg";
import EmptyData from "../../../../../assets/icons/empty-data.svg";
import { CI001BasicInfoHandler } from "../handler/HandlerImpl";
import REGEX from "../../../../../common/constants/REGEX";
import { useParams } from "react-router-dom";
import { useMemo } from "react";
import moment from "moment";
import COMMON, { USER_FLOW } from "../../../../../common/constants/COMMON";
import locale from "antd/es/date-picker/locale/ja_JP";
import "moment/locale/ja";
import helpers from "../../../../../common/helpers/common";
import MESSAGE from "../../../../../common/constants/MESSAGE";
import {
  onBlurTrimFormItem,
  typeInteger,
} from "../../../../../common/helpers/typeInput";
import { isHasPrivilege } from "../../../../../common/helpers/privilege";
import { PRIVILEGE_PM } from "../../../../../common/constants/PRIVILEGE";
import { SYSTEM_ROLES } from "../../../../../common/constants/AUTHORIZATION";
import { RootState } from "../../../../../store";
import { useSelector } from "react-redux";
import Dragger from "antd/lib/upload/Dragger";
import IconFile from "../../../../../assets/icons/ant-design_file-pdf-filled.svg";
import CloudUpIcon from "../../../../../assets/icons/cloud-up-icon.svg";
import PdfIcon from "../../../../../assets/icons/pdf-icon.svg";
import IconDelete from "../../../../../assets/icons/icon-trash-red.svg";
import TooltipText from "../../../../../common/components/tooltip-text/TooltipText";

const { Item } = Form;
const { RangePicker } = DatePicker;
const MESSAGE_REQUIRED = "";
export const MIN_INPUT_NUMBER = 0;
const REQUIRED_INPUT = false;
const MAX_LENGTH = 50;
const MESSAGE_MAX_LENGTH = "";
const REGEX_NAME = {
  regex: REGEX.NAME,
  message: MESSAGE.MESSAGE_INVALID_INPUT,
};

const MAX_LENGTH_NAME = {
  regex: 20,
  message: "",
};

const MAX_LENGTH_DETAIL = {
  regex: 300,
  message: "",
};

const dummyRequest = (value: any) => {
  setTimeout(() => {
    value.onSuccess("ok");
  }, 0);
};

interface props {
  handler: CI001BasicInfoHandler;
}
function CI001BasicInformation({ handler }: props) {
  const { projectManagerId } = useParams();

  const isRoleAdmin = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdmin
  );

  const isRoleAdminSystem = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdminSystem
  );

  const parentId = localStorage.getItem("parentId");
  const checkButtonWorkflowRolePM = useMemo(() => {
    return helpers.checkButtonWithWorkflowRolePM(
      handler.currentUserApproval,
      handler.listApprovalProcess,
      +(projectManagerId ?? 0)
    );
  }, [
    handler.currentUserApproval,
    handler.listApprovalProcess,
    projectManagerId,
  ]);

  return (
    <>
      <Form
        name="ci001Basic"
        onFinish={handler.onSubmitBasic}
        form={handler.form}
        onValuesChange={handler.onChangeForm}
        onKeyDown={helpers.preventEnterForm}
      >
        <div className="ci001-basic">
          {Object.keys(handler.basicInformation)?.length > 0 ? (
            <>
              {(checkButtonWorkflowRolePM === USER_FLOW.MANAGER_SUBMIT ||
                isHasPrivilege(handler.detailProject, [PRIVILEGE_PM]) ||
                isRoleAdmin ||
                isRoleAdminSystem) &&
              !handler.isEdit &&
              handler.checkApproved(handler.listApprovalProcess) ? (
                <div className="ci001-basic-header">
                  <Button
                    size="large"
                    className="button-purple"
                    onClick={() => handler.onClickCheckEdit(!handler.isEdit)}
                    icon={
                      <img
                        style={{ paddingRight: 12, paddingLeft: 2 }}
                        alt=""
                        src={EditWhiteSvg}
                      />
                    }
                  >
                    編集
                  </Button>
                </div>
              ) : null}

              <div className="ci001-ifo">
                <div className="ci001-ifo-left">
                  <div
                    className={`ci001-item ${
                      handler.isEdit && "ci001-item-edit"
                    }`}
                  >
                    <span className="ci001-item-label">得意先</span>
                    <span className="ci001-item-text">
                      {handler.isEdit ? (
                        <Item
                          name="customerName"
                          rules={[
                            () => ({
                              validator(_, value) {
                                if (value && value !== "") {
                                  // CHECK INPUT VIETNAMESE
                                  if (!REGEX.NOT_VIETNAM.test(value)) {
                                    return Promise.reject(
                                      new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                                    );
                                  }
                                  if (value?.length > MAX_LENGTH_NAME.regex) {
                                    return Promise.reject(
                                      new Error(MAX_LENGTH_NAME.message)
                                    );
                                  }
                                  return Promise.resolve();
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input
                            autoComplete="off"
                            className="ci001-input"
                            maxLength={MAX_LENGTH_NAME.regex}
                            onBlur={() =>
                              onBlurTrimFormItem(handler.form, "customerName")
                            }
                          />
                        </Item>
                      ) : (
                        handler.basicInformation?.customerName
                      )}
                    </span>
                  </div>
                  <div
                    className={`ci001-item ${
                      handler.isEdit && "ci001-item-edit"
                    }`}
                  >
                    <span className="ci001-item-label">
                      工事名
                      {handler.isEdit && (
                        <span style={{ color: "red" }}>*</span>
                      )}
                    </span>

                    <span className="ci001-item-text">
                      {handler.isEdit ? (
                        <Item
                          name="constructionName"
                          rules={[
                            () => ({
                              validator(_, value) {
                                if (value && value !== "") {
                                  // CHECK INPUT VIETNAMESE
                                  if (!REGEX.NOT_VIETNAM.test(value)) {
                                    return Promise.reject(
                                      new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                                    );
                                  }
                                  if (value?.length > MAX_LENGTH) {
                                    return Promise.reject(
                                      new Error(MESSAGE_MAX_LENGTH)
                                    );
                                  }
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error(
                                    "空白のままにすることはできません。データを入力してください"
                                  )
                                );
                              },
                            }),
                          ]}
                        >
                          <Input
                            autoComplete="off"
                            className="ci001-input"
                            maxLength={MAX_LENGTH}
                            onBlur={() =>
                              onBlurTrimFormItem(
                                handler.form,
                                "constructionName"
                              )
                            }
                          />
                        </Item>
                      ) : (
                        handler.basicInformation?.constructionName
                      )}
                    </span>
                  </div>
                  <div
                    className={`ci001-item ${
                      handler.isEdit && "ci001-item-edit"
                    }`}
                  >
                    <span className="ci001-item-label">担当者様</span>
                    <span className="ci001-item-text">
                      {handler.isEdit ? (
                        <Item
                          name="picName"
                          rules={[
                            () => ({
                              validator(_, value) {
                                if (value && value !== "") {
                                  // CHECK INPUT VIETNAMESE
                                  if (!REGEX.NOT_VIETNAM.test(value)) {
                                    return Promise.reject(
                                      new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                                    );
                                  }
                                  if (value?.length > MAX_LENGTH_NAME.regex) {
                                    return Promise.reject(
                                      new Error(MAX_LENGTH_NAME.message)
                                    );
                                  }
                                  return Promise.resolve();
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input
                            autoComplete="off"
                            className="ci001-input"
                            maxLength={MAX_LENGTH_NAME.regex}
                            onBlur={() =>
                              onBlurTrimFormItem(handler.form, "picName")
                            }
                          />
                        </Item>
                      ) : (
                        handler.basicInformation?.picName
                      )}
                    </span>
                  </div>
                  <div
                    className={`ci001-item ${
                      handler.isEdit && "ci001-item-edit"
                    }`}
                  >
                    <span className="ci001-item-label">施工場所</span>
                    <span className="ci001-item-text">
                      {handler.isEdit ? (
                        <Item
                          name="constructionLocation"
                          rules={[
                            () => ({
                              validator(_, value) {
                                if (value && value !== "") {
                                  // CHECK INPUT VIETNAMESE
                                  if (!REGEX.NOT_VIETNAM.test(value)) {
                                    return Promise.reject(
                                      new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                                    );
                                  }
                                  if (value?.length > MAX_LENGTH) {
                                    return Promise.reject(
                                      new Error(MESSAGE_MAX_LENGTH)
                                    );
                                  }
                                  return Promise.resolve();
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input
                            autoComplete="off"
                            className="ci001-input"
                            maxLength={MAX_LENGTH}
                            onBlur={() =>
                              onBlurTrimFormItem(
                                handler.form,
                                "constructionLocation"
                              )
                            }
                          />
                        </Item>
                      ) : (
                        handler.basicInformation?.constructionLocation
                      )}
                    </span>
                  </div>
                  <div
                    className={`ci001-item ${
                      handler.isEdit && "ci001-item-edit"
                    }`}
                  >
                    <span className="ci001-item-label">参加業者</span>
                    <span className="ci001-item-text">
                      {" "}
                      {handler.isEdit ? (
                        <Item
                          name="constructionCompanyName"
                          rules={[
                            () => ({
                              validator(_, value) {
                                if (value && value !== "") {
                                  // CHECK INPUT VIETNAMESE
                                  if (!REGEX.NOT_VIETNAM.test(value)) {
                                    return Promise.reject(
                                      new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                                    );
                                  }
                                  if (value?.length > MAX_LENGTH) {
                                    return Promise.reject(
                                      new Error(MESSAGE_MAX_LENGTH)
                                    );
                                  }
                                  return Promise.resolve();
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input
                            autoComplete="off"
                            className="ci001-input"
                            maxLength={MAX_LENGTH}
                            onBlur={() =>
                              onBlurTrimFormItem(
                                handler.form,
                                "constructionCompanyName"
                              )
                            }
                          />
                        </Item>
                      ) : (
                        handler.basicInformation?.constructionCompanyName
                      )}
                    </span>
                  </div>
                  <div
                    className={`ci001-item ${
                      handler.isEdit && "ci001-item-edit"
                    }`}
                  >
                    <span className="ci001-item-label">工事内容</span>
                    <span className="ci001-item-text">
                      {handler.isEdit ? (
                        <Item
                          name="description"
                          rules={[
                            () => ({
                              validator(_, value) {
                                if (value && value !== "") {
                                  // CHECK INPUT VIETNAMESE
                                  if (!REGEX.NOT_VIETNAM.test(value)) {
                                    return Promise.reject(
                                      new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                                    );
                                  }
                                  if (value?.length > MAX_LENGTH_DETAIL.regex) {
                                    return Promise.reject(
                                      new Error(MAX_LENGTH_DETAIL.message)
                                    );
                                  }
                                  return Promise.resolve();
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input
                            autoComplete="off"
                            className="ci001-input"
                            maxLength={MAX_LENGTH_DETAIL.regex}
                            onBlur={() =>
                              onBlurTrimFormItem(handler.form, "description")
                            }
                          />
                        </Item>
                      ) : (
                        handler.basicInformation?.description
                      )}
                    </span>
                  </div>
                </div>
                <div className="ci001-ifo-right">
                  <div
                    className={`ci001-item ${
                      handler.isEdit && "ci001-item-edit"
                    }`}
                  >
                    <span className="ci001-item-label">施主</span>
                    <span className="ci001-item-text">
                      {handler.isEdit ? (
                        <Item
                          name="investorName"
                          rules={[
                            () => ({
                              validator(_, value) {
                                if (value && value !== "") {
                                  // CHECK INPUT VIETNAMESE
                                  if (!REGEX.NOT_VIETNAM.test(value)) {
                                    return Promise.reject(
                                      new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                                    );
                                  }
                                  if (value?.length > MAX_LENGTH_NAME.regex) {
                                    return Promise.reject(
                                      new Error(MAX_LENGTH_NAME.message)
                                    );
                                  }
                                  return Promise.resolve();
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input
                            autoComplete="off"
                            className="ci001-input"
                            maxLength={MAX_LENGTH_NAME.regex}
                            onBlur={() =>
                              onBlurTrimFormItem(handler.form, "investorName")
                            }
                          />
                        </Item>
                      ) : (
                        handler.basicInformation?.investorName
                      )}
                    </span>
                  </div>
                  <div
                    className={`ci001-item ${
                      handler.isEdit && "ci001-item-edit"
                    }`}
                  >
                    <span className="ci001-item-label">設計管理業者</span>
                    <span className="ci001-item-text">
                      {handler.isEdit ? (
                        <Item
                          name="designCompanyName"
                          rules={[
                            () => ({
                              validator(_, value) {
                                if (value && value !== "") {
                                  // CHECK INPUT VIETNAMESE
                                  if (!REGEX.NOT_VIETNAM.test(value)) {
                                    return Promise.reject(
                                      new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                                    );
                                  }
                                  if (value?.length > MAX_LENGTH) {
                                    return Promise.reject(
                                      new Error(MESSAGE_MAX_LENGTH)
                                    );
                                  }
                                  return Promise.resolve();
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input
                            autoComplete="off"
                            className="ci001-input"
                            maxLength={MAX_LENGTH}
                            onBlur={() =>
                              onBlurTrimFormItem(
                                handler.form,
                                "designCompanyName"
                              )
                            }
                          />
                        </Item>
                      ) : (
                        handler.basicInformation?.designCompanyName
                      )}
                    </span>
                  </div>
                  <div
                    className={`ci001-item ${
                      handler.isEdit && "ci001-item-edit"
                    }`}
                  >
                    <span className="ci001-item-label">建設業者</span>
                    <span className="ci001-item-text">
                      {handler.isEdit ? (
                        <Item
                          name="cicName"
                          rules={[
                            () => ({
                              validator(_, value) {
                                if (value && value !== "") {
                                  // CHECK INPUT VIETNAMESE
                                  if (!REGEX.NOT_VIETNAM.test(value)) {
                                    return Promise.reject(
                                      new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                                    );
                                  }
                                  if (value?.length > MAX_LENGTH) {
                                    return Promise.reject(
                                      new Error(MESSAGE_MAX_LENGTH)
                                    );
                                  }
                                  return Promise.resolve();
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input
                            autoComplete="off"
                            className="ci001-input"
                            maxLength={MAX_LENGTH}
                            onBlur={() =>
                              onBlurTrimFormItem(handler.form, "cicName")
                            }
                          />
                        </Item>
                      ) : (
                        handler.basicInformation?.cicName
                      )}
                    </span>
                  </div>
                  <div
                    className={`ci001-item ${
                      handler.isEdit && "ci001-item-edit"
                    }`}
                  >
                    <span className="ci001-item-label">工事費予想</span>
                    <span className="ci001-item-text">
                      {handler.isEdit ? (
                        <Item
                          name="constructionEstimate"
                          rules={[
                            {
                              required: REQUIRED_INPUT,
                              message: MESSAGE_REQUIRED,
                            },
                          ]}
                        >
                          <InputNumber
                            controls={false}
                            maxLength={COMMON.MAX_INPUT_NUMBER}
                            max={COMMON.MAX_NUMBER_VALUE}
                            min={MIN_INPUT_NUMBER}
                            formatter={(value) =>
                              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                            parser={(value: any) =>
                              value.replace(/\$\s?|(,*)/g, "")
                            }
                            addonAfter={
                              <span className="cr003-icon-input">円</span>
                            }
                            size="large"
                            style={{ width: "57%" }}
                          />
                        </Item>
                      ) : (
                        `${
                          handler.basicInformation?.constructionEstimate ||
                          handler.basicInformation?.constructionEstimate === 0
                            ? Number(
                                handler.basicInformation?.constructionEstimate
                              )?.toLocaleString("en-US", {
                                maximumFractionDigits: 2,
                              })
                            : ""
                        } 円`
                      )}
                    </span>
                  </div>
                  <div
                    className={`ci001-item ${
                      handler.isEdit && "ci001-item-edit"
                    }`}
                  >
                    <span className="ci001-item-label">工期</span>
                    <span className="ci001-item-text">
                      {handler.isEdit ? (
                        <Item
                          name="constructionFromToDate"
                          // rules={[{ required: true, message: "" }]}
                        >
                          <RangePicker
                            // defaultValue={[moment('2015/01/01', dateFormat), moment('2015/01/01', dateFormat)]}
                            format={COMMON.FORMAT_DATE_CI}
                            className="ci001-date-picker"
                            placeholder={["", ""]}
                            inputReadOnly
                            locale={locale}
                            suffixIcon={
                              <img
                                alt=""
                                src={CalendarSvg}
                                className="icon-calender"
                              />
                            }
                            separator={
                              <span className="calender-separator">-</span>
                            }
                            getPopupContainer={(trigger: any) =>
                              trigger.parentNode
                            }
                          />
                        </Item>
                      ) : handler.basicInformation.constructionFromDate &&
                        handler.basicInformation.constructionFromDate !== "" &&
                        handler.basicInformation.constructionToDate &&
                        handler.basicInformation.constructionToDate !== "" ? (
                        `${moment(
                          handler.basicInformation.constructionFromDate
                        ).format(COMMON.FORMAT_DATE_CI)} - ${moment(
                          handler.basicInformation.constructionToDate
                        ).format(COMMON.FORMAT_DATE_CI)}`
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                  <div
                    className={`ci001-item ${
                      handler.isEdit && "ci001-item-edit"
                    }`}
                  >
                    <span className="ci001-item-label">備考欄</span>
                    <span className="ci001-item-text">
                      {handler.isEdit ? (
                        <Item
                          name="categoryNote"
                          rules={[
                            () => ({
                              validator(_, value) {
                                if (value && value !== "") {
                                  // CHECK INPUT VIETNAMESE
                                  if (!REGEX.NOT_VIETNAM.test(value)) {
                                    return Promise.reject(
                                      new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                                    );
                                  }
                                  if (value?.length > MAX_LENGTH_DETAIL.regex) {
                                    return Promise.reject(
                                      new Error(MAX_LENGTH_DETAIL.message)
                                    );
                                  }
                                  return Promise.resolve();
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input
                            autoComplete="off"
                            className="ci001-input"
                            maxLength={MAX_LENGTH_DETAIL.regex}
                            onBlur={() =>
                              onBlurTrimFormItem(handler.form, "categoryNote")
                            }
                          />
                        </Item>
                      ) : (
                        handler.basicInformation.categoryNote
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="ci001-contact">
                <div className="ci001-contact-header">
                  <span>引合</span>
                  <div className="ci001-contact-header-divider"></div>
                </div>
                <div
                  className={`ci001-item ${
                    handler.isEdit && "ci001-item-edit"
                  }`}
                >
                  <div className="ci001-item-left">
                    <span className="ci001-item-label">引合担当者</span>
                    <span className="ci001-item-text">
                      {handler.isEdit ? (
                        <Item
                          name="picEstimateName"
                          rules={[
                            () => ({
                              validator(_, value) {
                                if (value && value !== "") {
                                  // CHECK INPUT VIETNAMESE
                                  if (!REGEX.NOT_VIETNAM.test(value)) {
                                    return Promise.reject(
                                      new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                                    );
                                  }
                                  if (value?.length > MAX_LENGTH_NAME.regex) {
                                    return Promise.reject(
                                      new Error(MAX_LENGTH_NAME.message)
                                    );
                                  }
                                  return Promise.resolve();
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input
                            autoComplete="off"
                            className="ci001-input"
                            maxLength={MAX_LENGTH_NAME.regex}
                            onBlur={() =>
                              onBlurTrimFormItem(
                                handler.form,
                                "picEstimateName"
                              )
                            }
                          />
                        </Item>
                      ) : (
                        handler.basicInformation.picEstimateName
                      )}
                    </span>
                  </div>
                  <div className="ci001-item-right">
                    <span className="ci001-item-label">場所</span>
                    <span className="ci001-item-text">
                      {handler.isEdit ? (
                        <Item
                          name="address1"
                          rules={[
                            () => ({
                              validator(_, value) {
                                if (value && value !== "") {
                                  // CHECK INPUT VIETNAMESE
                                  if (!REGEX.NOT_VIETNAM.test(value)) {
                                    return Promise.reject(
                                      new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                                    );
                                  }
                                  if (value?.length > MAX_LENGTH) {
                                    return Promise.reject(
                                      new Error(MAX_LENGTH_NAME.message)
                                    );
                                  }
                                  return Promise.resolve();
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input
                            autoComplete="off"
                            className="ci001-input"
                            maxLength={MAX_LENGTH}
                            onBlur={() =>
                              onBlurTrimFormItem(handler.form, "address1")
                            }
                          />
                        </Item>
                      ) : (
                        handler.basicInformation.address1
                      )}
                    </span>
                  </div>
                </div>
                <div
                  className={`ci001-item ${
                    handler.isEdit && "ci001-item-edit"
                  }`}
                >
                  <div className="ci001-item-left">
                    <span className="ci001-item-label">日付</span>
                    <span className="ci001-item-text">
                      {handler.isEdit ? (
                        <Item
                          name="quotationFromDate"
                          // rules={[{ required: true, message: "" }]}
                        >
                          <DatePicker
                            // defaultValue={[moment('2015/01/01', dateFormat), moment('2015/01/01', dateFormat)]}
                            format={COMMON.FORMAT_DATE_CI}
                            className="ci001-date-picker"
                            placeholder={""}
                            inputReadOnly
                            locale={locale}
                            getPopupContainer={(trigger: any) =>
                              trigger.parentNode
                            }
                            suffixIcon={
                              <img
                                alt=""
                                src={CalendarSvg}
                                className="icon-calender"
                              />
                            }
                          />
                        </Item>
                      ) : handler.basicInformation.quotationFromDate &&
                        handler.basicInformation.quotationFromDate !== "" ? (
                        `${moment(
                          handler.basicInformation.quotationFromDate
                        ).format(COMMON.FORMAT_DATE_CI)}`
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </div>
                <div
                  className={`ci001-item ${
                    handler.isEdit && "ci001-item-edit"
                  }`}
                >
                  <div className="ci001-item-left">
                    <span className="ci001-item-label">時刻</span>
                    <span className="ci001-item-text">
                      {handler.isEdit ? (
                        <Item
                          name="quotationHour"
                          // rules={[{ required: true, message: "" }]}
                        >
                          <TimePicker
                            className="ci001-date-picker"
                            placeholder={""}
                            inputReadOnly
                            getPopupContainer={(trigger: any) =>
                              trigger.parentNode
                            }
                            format={COMMON.FORMAT_TIME}
                            locale={locale}
                          />
                        </Item>
                      ) : handler.basicInformation.quotationHour &&
                        handler.basicInformation.quotationHour !== "" ? (
                        `${moment(
                          handler.basicInformation.quotationHour
                        ).format(COMMON.FORMAT_TIME)}`
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </div>
                <div className="ci001-contact-header">
                  <span>現場説明</span>
                  <div className="ci001-contact-header-divider"></div>
                </div>
                <div
                  className={`ci001-item ${
                    handler.isEdit && "ci001-item-edit"
                  }`}
                >
                  <div className="ci001-item-left">
                    <span className="ci001-item-label">日付</span>
                    <span className="ci001-item-text">
                      {handler.isEdit ? (
                        <Item
                          name="estimationBidFromDate"
                          // rules={[{ required: true, message: "" }]}
                        >
                          <DatePicker
                            // defaultValue={[moment('2015/01/01', dateFormat), moment('2015/01/01', dateFormat)]}
                            format={COMMON.FORMAT_DATE_CI}
                            className="ci001-date-picker"
                            placeholder=""
                            inputReadOnly
                            locale={locale}
                            suffixIcon={
                              <img
                                alt=""
                                src={CalendarSvg}
                                className="icon-calender"
                              />
                            }
                            getPopupContainer={(trigger: any) =>
                              trigger.parentNode
                            }
                          />
                        </Item>
                      ) : handler.basicInformation.estimationBidFromDate &&
                        handler.basicInformation.estimationBidFromDate !==
                          "" ? (
                        `${moment(
                          handler.basicInformation.estimationBidFromDate
                        ).format(COMMON.FORMAT_DATE_CI)}`
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                  <div className="ci001-item-right">
                    <span className="ci001-item-label">場所</span>
                    <span className="ci001-item-text">
                      {handler.isEdit ? (
                        <Item
                          name="address2"
                          rules={[
                            () => ({
                              validator(_, value) {
                                if (value && value !== "") {
                                  // CHECK INPUT VIETNAMESE
                                  if (!REGEX.NOT_VIETNAM.test(value)) {
                                    return Promise.reject(
                                      new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                                    );
                                  }
                                  if (value?.length > MAX_LENGTH) {
                                    return Promise.reject(
                                      new Error(MESSAGE_MAX_LENGTH)
                                    );
                                  }
                                  return Promise.resolve();
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input
                            autoComplete="off"
                            className="ci001-input"
                            maxLength={MAX_LENGTH}
                            onBlur={() =>
                              onBlurTrimFormItem(handler.form, "address2")
                            }
                          />
                        </Item>
                      ) : (
                        handler.basicInformation.address2
                      )}
                    </span>
                  </div>
                </div>
                <div
                  className={`ci001-item ${
                    handler.isEdit && "ci001-item-edit"
                  }`}
                >
                  <div className="ci001-item-left">
                    <span className="ci001-item-label">時刻</span>
                    <span className="ci001-item-text">
                      {handler.isEdit ? (
                        <Item name="constructionDetailHour">
                          <TimePicker
                            className="ci001-date-picker"
                            placeholder=""
                            inputReadOnly
                            getPopupContainer={(trigger: any) =>
                              trigger.parentNode
                            }
                            format={COMMON.FORMAT_TIME}
                            locale={locale}
                          />
                        </Item>
                      ) : handler.basicInformation.constructionDetailHour &&
                        handler.basicInformation.constructionDetailHour !==
                          "" ? (
                        `${moment(
                          handler.basicInformation.constructionDetailHour
                        ).format(COMMON.FORMAT_TIME)}`
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </div>
                <div className="ci001-contact-header">
                  <span>見積入札</span>
                  <div className="ci001-contact-header-divider"></div>
                </div>
                <div
                  className={`ci001-item ${
                    handler.isEdit && "ci001-item-edit"
                  }`}
                >
                  <div className="ci001-item-left">
                    <span className="ci001-item-label">日付</span>
                    <span className="ci001-item-text">
                      {handler.isEdit ? (
                        <Item
                          name="constructionDetailFromDate"
                          // rules={[{ required: true, message: "" }]}
                        >
                          <DatePicker
                            // defaultValue={[moment('2015/01/01', dateFormat), moment('2015/01/01', dateFormat)]}
                            format={COMMON.FORMAT_DATE_CI}
                            className="ci001-date-picker"
                            placeholder=""
                            inputReadOnly
                            locale={locale}
                            suffixIcon={
                              <img
                                alt=""
                                src={CalendarSvg}
                                className="icon-calender"
                              />
                            }
                            getPopupContainer={(trigger: any) =>
                              trigger.parentNode
                            }
                          />
                        </Item>
                      ) : handler.basicInformation.constructionDetailFromDate &&
                        handler.basicInformation.constructionDetailFromDate !==
                          "" ? (
                        `${moment(
                          handler.basicInformation.constructionDetailFromDate
                        ).format(COMMON.FORMAT_DATE_CI)}`
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                  <div className="ci001-item-right">
                    <span className="ci001-item-label">場所</span>
                    <span className="ci001-item-text">
                      {handler.isEdit ? (
                        <Item
                          name="address3"
                          rules={[
                            () => ({
                              validator(_, value) {
                                if (value && value !== "") {
                                  // CHECK INPUT VIETNAMESE
                                  if (!REGEX.NOT_VIETNAM.test(value)) {
                                    return Promise.reject(
                                      new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                                    );
                                  }
                                  if (value?.length > MAX_LENGTH) {
                                    return Promise.reject(
                                      new Error(MESSAGE_MAX_LENGTH)
                                    );
                                  }
                                  return Promise.resolve();
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input
                            autoComplete="off"
                            className="ci001-input"
                            maxLength={MAX_LENGTH}
                            onBlur={() =>
                              onBlurTrimFormItem(handler.form, "address3")
                            }
                          />
                        </Item>
                      ) : (
                        handler.basicInformation.address3
                      )}
                    </span>
                  </div>
                </div>
                <div
                  className={`ci001-item ${
                    handler.isEdit && "ci001-item-edit"
                  }`}
                >
                  <div className="ci001-item-left">
                    <span className="ci001-item-label">時刻</span>
                    <span className="ci001-item-text">
                      {handler.isEdit ? (
                        <Item
                          name="estimationBidHour"
                          // rules={[{ required: true, message: "" }]}
                        >
                          <TimePicker
                            className="ci001-date-picker"
                            placeholder=""
                            inputReadOnly
                            getPopupContainer={(trigger: any) =>
                              trigger.parentNode
                            }
                            format={COMMON.FORMAT_TIME}
                            locale={locale}
                          />
                        </Item>
                      ) : handler.basicInformation.estimationBidHour &&
                        handler.basicInformation.estimationBidHour !== "" ? (
                        `${moment(
                          handler.basicInformation.estimationBidHour
                        ).format(COMMON.FORMAT_TIME)}`
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="ci001-other">
                <div
                  className={`ci001-item ${
                    handler.isEdit && "ci001-item-edit"
                  }`}
                >
                  <div className="ci001-item-left">
                    <span className="ci001-item-label">引合区分</span>
                    <span className="ci001-item-text">
                      {handler.isEdit ? (
                        <div className="ci001-wrapper-select">
                          <Item name={["estimation", "select"]}>
                            <Select
                              className="ci001-select"
                              onChange={handler.onChangeInquiryCategorySelect}
                              getPopupContainer={(trigger: any) =>
                                trigger.parentNode
                              }
                            >
                              {handler.dropDownBasicInfo?.dropDown1?.map(
                                (option: any) => (
                                  <Select.Option
                                    key={option.id}
                                    value={option.id}
                                  >
                                    {option.description}
                                  </Select.Option>
                                )
                              )}
                            </Select>
                          </Item>
                          {handler.inquiryCategory.showNote && (
                            <div className="item-other-input">
                              <Item
                                className="ci001-item-form-other"
                                name={["estimation", "inputOther"]}
                                rules={[
                                  () => ({
                                    validator(_, value) {
                                      if (value && value !== "") {
                                        // CHECK INPUT VIETNAMESE
                                        if (!REGEX.NOT_VIETNAM.test(value)) {
                                          return Promise.reject(
                                            new Error(
                                              MESSAGE.MESSAGE_INPUT_INVALID
                                            )
                                          );
                                        }
                                        if (value?.length > MAX_LENGTH) {
                                          return Promise.reject(
                                            new Error(MESSAGE_MAX_LENGTH)
                                          );
                                        }
                                        return Promise.resolve();
                                      } else {
                                        if (handler.inquiryCategory.showNote) {
                                          return Promise.reject(new Error(""));
                                        } else {
                                          return Promise.resolve();
                                        }
                                      }
                                    },
                                  }),
                                ]}
                              >
                                <Input
                                  autoComplete="off"
                                  className="ci001-input ci001-select-other-input ci001-input-45"
                                  maxLength={MAX_LENGTH}
                                  onBlur={() =>
                                    onBlurTrimFormItem(handler.form, [
                                      "estimation",
                                      "inputOther",
                                    ])
                                  }
                                />
                              </Item>
                            </div>
                          )}
                        </div>
                      ) : (
                        `${
                          handler.categoryValue?.category1?.description || ""
                        } ${
                          handler.categoryValue?.category1?.note &&
                          handler.categoryValue?.category1?.note !== ""
                            ? `(${handler.categoryValue?.category1?.note}) `
                            : ""
                        } `
                      )}
                    </span>
                  </div>
                </div>
                <div
                  className={`ci001-item ${
                    handler.isEdit && "ci001-item-edit"
                  }`}
                >
                  <div className="ci001-item-left">
                    <span className="ci001-item-label">発注区分</span>
                    <span className="ci001-item-text">
                      {handler.isEdit ? (
                        <div className="ci001-wrapper-select">
                          <Item
                            name="order"
                            // rules={[{ required: true, message: "" }]}
                          >
                            <Select
                              className="ci001-select"
                              getPopupContainer={(trigger: any) =>
                                trigger.parentNode
                              }
                            >
                              {handler.dropDownBasicInfo?.dropDown2?.map(
                                (option: any) => (
                                  <Select.Option
                                    key={option.id}
                                    value={option.id}
                                  >
                                    {option.description}
                                  </Select.Option>
                                )
                              )}
                            </Select>
                          </Item>
                        </div>
                      ) : (
                        `${
                          handler.categoryValue?.category2?.description || ""
                        } `
                      )}
                    </span>
                  </div>
                </div>
                <div
                  className={`ci001-item ${
                    handler.isEdit && "ci001-item-edit"
                  }`}
                >
                  <div className="ci001-item-left">
                    <span className="ci001-item-label">決定方法</span>
                    <span className="ci001-item-text">
                      {handler.isEdit ? (
                        <div className="ci001-wrapper-select">
                          <Item
                            name={["payment", "select"]}
                            // rules={[{ required: true, message: "" }]}
                          >
                            <Select
                              className="ci001-select"
                              onChange={handler.onChangeDecisionMethod}
                              getPopupContainer={(trigger: any) =>
                                trigger.parentNode
                              }
                            >
                              {handler.dropDownBasicInfo?.dropDown3?.map(
                                (option: any) => (
                                  <Select.Option
                                    key={option.id}
                                    value={option.id}
                                  >
                                    {option.description}
                                  </Select.Option>
                                )
                              )}
                            </Select>
                          </Item>
                          {handler.decisionMethod.showNote && (
                            <div className="item-other-input">
                              <Item
                                className="ci001-item-form-other"
                                name={["payment", "inputOther"]}
                                rules={[
                                  () => ({
                                    validator(_, value) {
                                      if (value && value !== "") {
                                        // CHECK INPUT VIETNAMESE
                                        if (!REGEX.NOT_VIETNAM.test(value)) {
                                          return Promise.reject(
                                            new Error(
                                              MESSAGE.MESSAGE_INPUT_INVALID
                                            )
                                          );
                                        }
                                        if (value?.length > MAX_LENGTH) {
                                          return Promise.reject(
                                            new Error(MESSAGE_MAX_LENGTH)
                                          );
                                        }
                                        return Promise.resolve();
                                      } else {
                                        if (handler.inquiryCategory.showNote) {
                                          return Promise.reject(new Error(""));
                                        } else {
                                          return Promise.resolve();
                                        }
                                      }
                                    },
                                  }),
                                ]}
                              >
                                <Input
                                  autoComplete="off"
                                  className="ci001-input ci001-select-other-input ci001-input-45"
                                  maxLength={MAX_LENGTH}
                                  onBlur={() =>
                                    onBlurTrimFormItem(handler.form, [
                                      "payment",
                                      "inputOther",
                                    ])
                                  }
                                />
                              </Item>
                            </div>
                          )}
                        </div>
                      ) : (
                        `${
                          handler.categoryValue?.category3?.description || ""
                        } ${
                          handler.categoryValue?.category3?.note &&
                          handler.categoryValue?.category3?.note !== ""
                            ? `(${handler.categoryValue?.category3?.note}) `
                            : ""
                        } `
                      )}
                    </span>
                  </div>
                </div>
                {handler.isEdit && (
                  <div className="ci001-contact-header">
                    <span></span>
                    <div className="ci001-contact-header-divider"></div>
                  </div>
                )}
                <div
                  className={`ci001-item-checkbox ${
                    handler.isEdit && "ci001-item-edit"
                  }`}
                >
                  <span className="ci001-item-label">現場資料</span>
                  <span className="ci001-item-text">
                    {handler.isEdit ? (
                      <>
                        <Item name={["tags", "checkbox"]}>
                          <Checkbox.Group
                            onChange={handler.onChangeCheckBox}
                            name="checkbox"
                          >
                            <Row
                              className="ci001-checkbox-group"
                              gutter={[0, 16]}
                            >
                              {handler.screenMaster?.tags?.map(
                                (tag: { note: string; showNote: boolean }) => {
                                  return (
                                    <Col
                                      className="ci001-checkbox-col"
                                      key={tag.note}
                                    >
                                      <Checkbox
                                        value={tag.note?.trim()}
                                        className="ci001-checkbox"
                                      >
                                        {tag.note}
                                      </Checkbox>
                                    </Col>
                                  );
                                }
                              )}

                              <Col className="ci001-checkbox-col">
                                <Item
                                  name="inputOtherTag"
                                  rules={[
                                    () => ({
                                      validator(_, value) {
                                        if (value && value !== "") {
                                          // CHECK INPUT VIETNAMESE
                                          if (!REGEX.NOT_VIETNAM.test(value)) {
                                            return Promise.reject(
                                              new Error(
                                                MESSAGE.MESSAGE_INPUT_INVALID
                                              )
                                            );
                                          }
                                          if (value?.length > MAX_LENGTH) {
                                            return Promise.reject(
                                              new Error(MESSAGE_MAX_LENGTH)
                                            );
                                          }
                                          return Promise.resolve();
                                        } else {
                                          if (
                                            handler?.checkbox?.some(
                                              (element: {
                                                showNote: boolean;
                                              }) => element.showNote
                                            )
                                          ) {
                                            return Promise.reject(
                                              new Error("")
                                            );
                                          } else {
                                            return Promise.resolve();
                                          }
                                        }
                                      },
                                    }),
                                  ]}
                                >
                                  <Input
                                    autoComplete="off"
                                    className="ci001-input ci001-select-other-input ci001-input-242"
                                    disabled={
                                      !handler?.checkbox?.some(
                                        (element: { showNote: boolean }) =>
                                          element.showNote
                                      )
                                    }
                                    onBlur={() =>
                                      onBlurTrimFormItem(
                                        handler.form,
                                        "inputOtherTag"
                                      )
                                    }
                                    maxLength={MAX_LENGTH}
                                  />
                                </Item>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Item>
                      </>
                    ) : (
                      handler.tagRender || ""
                    )}
                  </span>
                </div>

                <div
                  className={`ci001-item ${
                    handler.isEdit && "ci001-item-edit"
                  }`}
                >
                  <div style={{ width: "100%", display: "flex", gap: 8 }}>
                    <div className="label-upload">ファイル</div>

                    <div className="ci001-item-text">
                      {!handler.isEdit && handler.fileList.length == 0 ? (
                        <></>
                      ) : (
                        <Card
                          bodyStyle={{
                            padding: "0 12px 12px 12px",
                            maxWidth: "1309px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            background: "#fafafa",
                          }}
                          bordered={true}
                          style={{ border: "1px dashed #DAE2EE" }}
                        >
                          {handler.isEdit && (
                            <Dragger
                              customRequest={dummyRequest}
                              multiple={true}
                              fileList={[]}
                              onChange={handler.onChangeUpload}
                              onRemove={handler.onDropUpload}
                              iconRender={() => <img alt="" src={IconFile} />}
                              style={{ maxWidth: "1309px", border: "none" }}
                              disabled={handler.fileList.length >= 20}
                              accept="application/pdf, image/png, image/jpeg"
                            >
                              <div className="content-upload">
                                <p className="ant-upload-drag-icon">
                                  <img alt="" src={CloudUpIcon} />
                                </p>
                                <p
                                  className="ci-upload-text"
                                  style={{ width: 424, textAlign: "center" }}
                                >
                                  ここにファイルをドラッグ・ドロップ (.pdf
                                  または .png/.jpeg/.jpg)
                                </p>
                                <div className="ci-upload-text">
                                  （各ファイルサイズ：8MB）
                                </div>
                                <div className="text-gray">または</div>
                                <Button
                                  className="btn-upload-custom"
                                  style={{
                                    borderRadius: "3px",
                                    padding: "0 24px",
                                    width: "fit-content",
                                    border: "solid 1px #DAE2EE",
                                    marginTop: 5,
                                  }}
                                  disabled={handler.fileList.length >= 20}
                                >
                                  <span
                                    style={{
                                      fontSize: 14,
                                      fontWeight: 700,
                                      color: "#605BFF",
                                    }}
                                  >
                                    ファイルを選択する
                                  </span>
                                  <span
                                    style={{
                                      fontSize: 14,
                                      fontWeight: 400,
                                      color: "#605BFF",
                                    }}
                                  >
                                    (.pdf または .png/.jpeg/.jpg)
                                  </span>
                                </Button>
                              </div>
                            </Dragger>
                          )}
                          {handler.fileList.length > 0 && (
                            <div style={{ width: "100%", padding: "0 16px" }}>
                              <List
                                style={{
                                  marginTop: 16,
                                  maxWidth: "100%",
                                  fontSize: 14,
                                  fontWeight: 700,
                                  color: "#605BFF",
                                  border: "1px solid #DAE2EE",
                                  maxHeight: 250,
                                  overflowY: "auto",
                                }}
                                bordered
                                dataSource={handler.fileList}
                                renderItem={(item: any) => (
                                  <List.Item
                                    style={{
                                      color: "#605BFF",
                                      maxWidth: "1309px",
                                      overflowX: "hidden",
                                    }}
                                  >
                                    <div
                                      style={{
                                        cursor: "pointer",
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div
                                        onClick={() => handler.getPdfMap(item)}
                                        style={{
                                          cursor: "pointer",
                                          width: handler.isEdit
                                            ? "97%"
                                            : "100%",
                                        }}
                                      >
                                        <TooltipText
                                          isLarge
                                          content={item.name}
                                        />
                                      </div>
                                      {handler.isEdit && (
                                        <img
                                          style={{ cursor: "pointer" }}
                                          src={IconDelete}
                                          onClick={() =>
                                            handler.onDropUpload(item)
                                          }
                                        />
                                      )}
                                    </div>
                                  </List.Item>
                                )}
                              />
                            </div>
                          )}
                        </Card>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="data-empty" style={{ height: "65vh" }}>
              <img src={EmptyData} alt="" />
            </div>
          )}
        </div>
        {handler.isEdit && (
          <div className="ci001-form-submit">
            <Item>
              <Button
                size="large"
                className="button-purple"
                htmlType="submit"
                style={{ marginRight: 16 }}
              >
                保存
              </Button>
            </Item>
            <Button size="large" onClick={handler.handleCancelSubmit}>
              キャンセル
            </Button>
          </div>
        )}
      </Form>
    </>
  );
}

export default CI001BasicInformation;
