import CRT001Api from "./infrastructure/api/Api";
import CRT001Service from "./usecase/Service";
import CRT001Handler from "./presenter/handler/Handler";
import CRT001UI from "./presenter/ui/CRT001UI";

function CRT001Component() {
  const crt001Api = CRT001Api();
  const crt001Service = CRT001Service(crt001Api);
  const crt001Handler = CRT001Handler(crt001Service);
  return <CRT001UI handler={crt001Handler} />;
}

export default CRT001Component;
