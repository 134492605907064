import MenuWrapperComponent from "../../../../../common/components/menu-wrapper";
import { OM002Hanlder } from "../handler/HandlerImpl";
import "../../../../../assets/styles/OM/OM002.css";
import OM002HeaderComponent from "./Header";
import OM002GroupMemberData from "./GroupMemberData";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import OM001Component from "../../../OM001";
import OM007Component from "../../../OM007";
import OM009Component from "../../../OM009";
import OM004Component from "../../../OM004";
import OM008Component from "../../../OM008";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import OM006Component from "../../../OM006";
import COMMON from "../../../../../common/constants/COMMON";
interface props {
  handler: OM002Hanlder;
}

const OM002UI = ({ handler }: props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") || "1";
  const size = searchParams.get("size") || "10";
  const filter = searchParams.get("filter");
  const keyword = searchParams.get("keyword");
  const sortBy = searchParams.get("sortBy");
  const sortType = searchParams.get("sortType");
  const groupId = searchParams.get("groupId") || "";
  const roleId = searchParams.get("roleId") || "";

  const isRefresh = useSelector((state: RootState) => state.om002.isRefresh);
  const isRefreshGroup = useSelector(
    (state: RootState) => state.om002.isRefreshGroup
  );
  const isRefreshMember = useSelector(
    (state: RootState) => state.om002.isRefreshMember
  );
  const isHasUpdatedOM006 = useSelector(
    (state: RootState) => state.om006.isHasUpdatedOM006
  );
  const isHasCreatedOM004 = useSelector(
    (state: RootState) => state.om004.isHasCreatedOM004
  );

  useEffect(() => {
    handler.getRoleList();
  }, []);

  useEffect(() => {
    handler.getGroups();
  }, [isRefresh, isRefreshGroup,]);

  useEffect(() => {
    if (!groupId) {
      searchParams.set("groupId", "0");
      setSearchParams(searchParams);
    }
  }, [groupId]);

  useEffect(() => {
    if (groupId) {
      const params = {
        page: page,
        size: size,
        filter: filter ?? undefined,
        keyword: keyword ?? undefined,
        sortBy: sortBy ?? undefined,
        sortType: sortType ?? undefined,
        groupId: groupId !== "0" ? groupId : undefined,
        roleId: roleId && roleId !== "0" ? roleId : undefined,
      };
      handler.getGroupMember(params);
    }
  }, [
    page,
    size,
    filter,
    keyword,
    sortBy,
    sortType,
    groupId,
    isRefresh,
    roleId,
    isHasUpdatedOM006,
    isHasCreatedOM004,
  ]);

  useEffect(() => {
    searchParams.set("page", COMMON.DEFAULT_PAGE + "");
    setSearchParams(searchParams);
  }, [isHasUpdatedOM006, isHasCreatedOM004]);

  return (
    <MenuWrapperComponent chosenKey={72} openKey={7}>
      <OM002HeaderComponent handler={handler} />
      <OM002GroupMemberData handler={handler} />
      <OM001Component />
      <OM007Component />
      <OM009Component />
      <OM004Component />
      <OM008Component />
      <OM006Component />
    </MenuWrapperComponent>
  );
};

export default OM002UI;
