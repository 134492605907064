import { useState } from 'react';
// Redux
import { useDispatch } from 'react-redux';

// Services

import { setVisibleDB007 } from '../slice/Slice';
import { DB007UseCase } from '../../usecase/ServiceImpl';
import COMMON from '../../../../../common/constants/COMMON';

const DB007Handler = (service: DB007UseCase) => {
    const dispatch = useDispatch();

    const [dataTable, setDataTable] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const cancelModalDB007 = () => {
        dispatch(setVisibleDB007(false));
    };

    const getTotalMandays = async (params: { projectId: number; from: string; to: string }) => {
        const response = await service.getTotalMandays({
            ...params,
            page: 1,
            size: COMMON.DEFAULT_SIZE,
        });
        if (response?.results && response.results.length > 0) {
            let dataFirst = [...response?.results];
            if (response.pagination.numPages > 1) {
                for (let pageFirst = 2; pageFirst <= response.pagination.numPages; pageFirst++) {
                    const responseRe = await service.getTotalMandays({
                        ...params,
                        page: pageFirst,
                        size: COMMON.DEFAULT_SIZE,
                    });
                    if (responseRe?.results && responseRe?.results?.length > 0) {
                        dataFirst = [...dataFirst, ...responseRe?.results];
                    }
                }
            }
            return dataFirst;
        }
        return [];
    };

    const handleTotalMandays = async (params: { projectId: number; from: string; to: string }) => {
        try {
            setLoading(true);
            const response = await getTotalMandays(params);
            setDataTable(convertDataTable(response));
        } finally {
            setLoading(false);
        }
    };
    const convertDataTable = (data: any) => {
        const results: any = [];
        if (data && data.length > 0) {
            for (const item of data) {
                const found = results.find((ele: any) => ele?.year === item.year);
                if (!found) {
                    results.push({
                        year: item.year,
                        data: [item],
                    });
                } else {
                    found.data.push(item);
                }
            }
        }
        return results;
    };
    return {
        dataTable,
        loading,
        cancelModalDB007,
        handleTotalMandays,
    };
};
export default DB007Handler;
