import CCUS01Api from "./infrastructure/api/Api";
import CCUS01Handler from "./presenter/handler/Handler";
import MainUI from "./presenter/ui/UI";
import CCUS01Service from "./usecase/Service";
import "../../../assets/styles/CCUS/ccus001.css";

const CCUS01Component = () => {
  const api = CCUS01Api();
  const service = CCUS01Service(api);
  const handler = CCUS01Handler(service);

  return <MainUI handler={handler}></MainUI>;
};

export default CCUS01Component;
