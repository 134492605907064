import { AxiosResponse } from "axios";
import axiosConfig from "../../../../../config/axiosConfig";

const CM0028Api = () => {
  const getReSummaryProjectLaborCost = async (params: any): Promise<any> => {
    const url = "/re-summary/project/labor-cost";
    return await axiosConfig.get(url, { params });
  };

  const getProjectAlls = async (params: {
    page: number;
    size: number;
    from: string;
    to: string;
    sortBy: string;
    sortType: string;
  }): Promise<any> => {
    const url = "/re-summary/project/labor";
    return await axiosConfig.get(url, { params });
  };

  const getReSummaryProjectLaborCostTotal = async (
    params: any
  ): Promise<any> => {
    const url = "/re-summary/project/labor-cost/total";
    return await axiosConfig.get(url, { params });
  };

  const postMoneySummaryLaborCostProjectSubmit = async (data: {
    fileName: string;
    startDate: string;
    endDate: string;
  }) => {
    const url = "/re-summary/project/labor-cost/submit";
    return await axiosConfig.post(url, data);
  };

  const getLaborCostDocumentStatus = async (params: {
    from: string;
    to: string;
  }) => {
    const url = "/re-summary/project/labor-cost/status";
    return await axiosConfig.get(url, { params });
  };

  const doExportCM0028 = async (params: any): Promise<any> => {
    try {
        const url = '/re-summary/exportCM0028';
        const response = await axiosConfig.get(url, { params, responseType: 'blob'  });
        
        return response;
    } catch (error) {
        throw error;
    }
  };

  return {
    getReSummaryProjectLaborCost,
    getProjectAlls,
    getReSummaryProjectLaborCostTotal,
    postMoneySummaryLaborCostProjectSubmit,
    getLaborCostDocumentStatus,
    doExportCM0028,
  };
};

export default CM0028Api;
