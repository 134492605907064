import { AM016SliceType } from "./../type/Presenter";
import { createSlice } from "@reduxjs/toolkit";

const initialState: AM016SliceType = {
  loading: false,
  isVisible: false,
  statusCode: null,
  refresh: false,
  requestId: undefined
};

export const am016Slice = createSlice({
  name: "am016",
  initialState,
  reducers: {
    resetAM016: () => initialState,
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsVisibleAM016: (state, action) => {
      state.isVisible = action.payload;
    },
    setStatusCodeAM016: (state, action) => {
      state.statusCode = action.payload;
    },
    setRefreshAM016: (state) => {
      state.refresh = !state.refresh;
    },
    setRequestIdAM016: (state, action) => {
      state.requestId = action.payload;
    }
  },
});

export const {
  setLoading,
  setIsVisibleAM016,
  resetAM016,
  setStatusCodeAM016,
  setRefreshAM016,
  setRequestIdAM016
} = am016Slice.actions;

export default am016Slice.reducer;
