import { uuidv4 } from '@firebase/util';
import { Form } from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ErrorNotification from '../../../../../common/components/notification/ErrorNotification';
import { NOTIFICATION_TITLE } from '../../../../../common/constants/MESSAGE';
import { RootState } from '../../../../../store';
import { ReSummarySubmittedProjectCompanyOffsetCost } from '../../entity/Entity';
import { CM0027_2UseCase } from '../../usecase/ServiceImpl';
import { setIsVisibleCM0027_2, setLoadingCM0027_2 } from '../slice/Slice';

const CM0027_2Handler = (CM0027_2Service: CM0027_2UseCase) => {
    // LIB FUNCTION
    const dispatch = useDispatch();

    //STATE
    const [form] = Form.useForm();
    const typeModal = useSelector((state: RootState) => state.cm0027_2.typeModal);

    const [
        listReSummarySubmittedProjectCompanyOffsetCostLocal,
        setListReSummarySubmittedProjectCompanyOffsetCostLocal,
    ] = useState<ReSummarySubmittedProjectCompanyOffsetCost[]>([]);
    const [totalCost, setTotalCost] = useState<number>(0);

    //function
    const getReSummarySubmittedProjectCompanyOffsetCost = async (
        documentId: number,
        companyId: number,
        categoryId?: number,
    ): Promise<any> => {
        try {
            dispatch(setLoadingCM0027_2(true));
            const params = {
                documentId,
                companyId,
                categoryId,
            };
            const res = await CM0027_2Service.getReSummarySubmittedProjectCompanyOffsetCost(params);
            if (res?.results) {
                const data = formatReSummarySubmittedProjectCompanyOffsetCost(res.results);
                setListReSummarySubmittedProjectCompanyOffsetCostLocal(data);
            }
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        } finally {
            dispatch(setLoadingCM0027_2(false));
        }
    };

    const formatReSummarySubmittedProjectCompanyOffsetCost = (data: any) => {
        const arr: ReSummarySubmittedProjectCompanyOffsetCost[] = data.map(
            (item: any, index: number) => {
                return {
                    key: uuidv4(),
                    id: item?.id,
                    item: item.name,
                    taxCategoryComsumptionId: item?.category?.id,
                    taxCategoryComsumptionName: item?.category?.description,
                    offsetAmount: item?.value,
                };
            },
        );
        return arr;
    };

    const handleCalculateChange = (data: ReSummarySubmittedProjectCompanyOffsetCost[]) => {
        let total: number = 0;
        data.forEach((item) => {
            const cost = item?.offsetAmount
                ? item.offsetAmount
                : form.getFieldValue(`offsetAmount${item.key}`) ?? 0;
            if (!isNaN(cost)) total += cost;
        });
        setTotalCost(total);
    };

    const onCancel = () => {
        dispatch(setIsVisibleCM0027_2(false));
    };

    return {
        form,
        typeModal,
        listReSummarySubmittedProjectCompanyOffsetCostLocal,
        totalCost,
        onCancel,
        handleCalculateChange,
        getReSummarySubmittedProjectCompanyOffsetCost,
    };
};

export default CM0027_2Handler;
