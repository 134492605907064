import { useSelector } from "react-redux";

import { useEffect, useState } from "react";
import { RootState } from "../../../../../../store";
import {
  OrderDetail,
  PlanData,
  PlanSettingDetail,
} from "../../../entity/Entity";
import { FormInstance } from "antd";
import ErrorNotification from "../../../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../../../common/constants/MESSAGE";

export const Handler = () => {
  const [currentCost, setCurrentCost] = useState<number>(0);
  const [currentTotalMAmount, setCurrentTotalAmount] = useState<number>(0);
  const [planPerson, setPlanPerson] = useState<any[]>([]);
  const [startDateState, setStartDateState] = useState();
  const [endDateState, setEndDateState] = useState();

  const onChangePersonPlan = (
    value: any,
    form: FormInstance,
    chosenPlan?: PlanData | null
  ) => {
    form.resetFields(["discount"]);
    form.setFieldValue("planPersonId", value);
    const foundPersonPlan = planPerson.find((element) => element.id === value);
    const cost = (foundPersonPlan?.cost ?? 0) + (chosenPlan?.cost ?? 0);
    setCurrentCost(cost);
    setCurrentTotalAmount(cost);
  };

  const onChangeDiscount = (value: any) => {
    setCurrentTotalAmount(currentCost - value);
  };

  const initiateDetailOrder = (
    form: FormInstance,
    orderDetail?: OrderDetail
  ) => {
    if (!orderDetail) return;
    setCurrentCost(orderDetail.orderCost ?? 0);
    form.setFieldValue("orderCost", orderDetail.orderCost ?? 0);
    setCurrentTotalAmount(
      (orderDetail.orderCost ?? 0) - (orderDetail.discount ?? 0)
    );
  };

  return {
    startDateState,
    currentTotalMAmount,
    currentCost,
    planPerson,
    endDateState,
    onChangePersonPlan,
    onChangeDiscount,
    setPlanPerson,
    setCurrentTotalAmount,
    setCurrentCost,
    initiateDetailOrder,
    setStartDateState,
    setEndDateState,
  };
};

export interface HandlerImpl {
  currentTotalMAmount: number;
  currentCost: number;
  planPerson: any[];
  startDateState: undefined;
  endDateState: undefined;
  onChangePersonPlan: (
    value: any,
    form: FormInstance,
    chosenPlan?: PlanData | null
  ) => void;
  onChangeDiscount: (value: any) => void;
  setPlanPerson: React.Dispatch<React.SetStateAction<any[]>>;
  setCurrentTotalAmount: React.Dispatch<React.SetStateAction<number>>;
  setCurrentCost: React.Dispatch<React.SetStateAction<number>>;
  initiateDetailOrder: (form: FormInstance, orderDetail?: OrderDetail) => void;
  setStartDateState: React.Dispatch<React.SetStateAction<undefined>>;
  setEndDateState: React.Dispatch<React.SetStateAction<undefined>>;
}
