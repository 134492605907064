// package
import { Button, Form, Input } from 'antd';
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';

// css
import '../../../../../assets/styles/S-001/B001.css';

// icon
import LogoBlack from '../../../../../assets/images/logo-black.svg';
import { ASChangePasswordHandler } from '../handler/HandlerImpl';
import CountdownTimer from '../../../../../common/components/count-down/CountDownComponent';
import { useEffect } from 'react';
import AuthenticationSideImage from '../../../../../common/components/authentication-side-image/AuthenticationSideImage';
import MESSAGE from '../../../../../common/constants/MESSAGE';
import REGEX from '../../../../../common/constants/REGEX';
import { typeInteger } from '../../../../../common/helpers/typeInput';

interface props {
  handler: ASChangePasswordHandler;
}

const ASChangePasswordUI = ({ handler }: props) => {
  useEffect(() => {
    handler.timerOTP();
  }, []);

  return (
    <div className="login-container">
      <div className="half-container">
        <div className="login-form">
          <div style={{ marginBottom: 64 }}>
            <img alt="" src={LogoBlack} />
          </div>
          <div className="login-title mb-75-px">初回ログイン時のパスワード変更</div>
          <Form
            layout="vertical"
            form={handler.form}
            onFinish={handler.changePassword}
            initialValues={{ remember: false }}
          >
            <Form.Item
              name={'newPassword'}
              className="input-password-style"
              style={{ marginBottom: 16 }}
              label="新しいパスワード"
              rules={[
                {
                  pattern: new RegExp(REGEX.PASSWORD_REGEX),
                  message: MESSAGE.MESSAGE_INVALID_PASSWORD_REGEX,
                },
                {
                  required: true,
                  message: '',
                },
              ]}
            >
              <Input.Password
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                placeholder="パスワード入力（半角英数字、大・小文字を含めた８文字以上）"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                size="large"
              />
            </Form.Item>
            <Form.Item
              name={'verifyPassword'}
              className="input-password-style"
              style={{ marginBottom: 16 }}
              label="新しいパスワード再入力"
              rules={[
                {
                  pattern: new RegExp(REGEX.PASSWORD_REGEX),
                  message: MESSAGE.MESSAGE_INVALID_PASSWORD_REGEX,
                },
                {
                  required: true,
                  message: '',
                },
              ]}
            >
              <Input.Password
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                placeholder="パスワード再入力"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                size="large"
              />
            </Form.Item>
            <Form.Item
              name={'otp'}
              className="input-password-style"
              style={{ marginBottom: 8 }}
              label={
                <div
                  className="w-100-percent"
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <span>認証コード</span>
                  <CountdownTimer
                    targetDate={handler.timer}
                    resendAction={
                      <span
                        style={{
                          color: 'rgba(96, 91, 255, 1)',
                          cursor: 'pointer',
                        }}
                        onClick={handler.sendOTP}
                      >
                        メールで認証コードを受け取る
                      </span>
                    }
                  />
                </div>
              }
              rules={[
                {
                  len: 6,
                  message: '',
                },
                {
                  required: true,
                  message: '',
                },
              ]}
            >
              <Input
                onKeyPress={typeInteger}
                maxLength={6}
                placeholder="認証コード入力"
                size="large"
              />
            </Form.Item>
            {
              handler.isSent ? <div
                className="w-100-percent change-password-note"
                style={{ textAlign: 'right' }}
              >
                入力されたメールに認証コードを送信しました。ご確認ください。
              </div> : <></>
            }
            <Form.Item>
              <Button
                htmlType="submit"
                size="large"
                className="button-purple w-100-percent "
              >
                変更する
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div
          style={{
            position: 'fixed',
            bottom: 32,
            width: '50%',
            textAlign: 'center',
          }}
        >
          <Button className="" type="link" onClick={handler.backLogin}>
            <div style={{ borderBottom: '1px solid #605BFF', height: 19 }}>
              ログイン
            </div>
          </Button>
        </div>
      </div>
      <AuthenticationSideImage />
    </div>
  );
};

export default ASChangePasswordUI;
