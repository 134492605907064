import MiniPaginationComponent from "../../../../../../../common/components/pagination/MiniPaginationComponent";

type Props = {
  total: number;
  page: number;
  size: number;
  onPageChange: (value: any) => void;
};

const PaginationComponent = ({ page, size, total, onPageChange }: Props) => {
  return (
    <div className="company-pagination-container w-100-percent center-item">
      <MiniPaginationComponent
        total={total}
        page={page}
        size={size}
        setPage={onPageChange}
        sizeLabelPage="件"
        sizeLabelSize=" ページ"
        rangeLabel="件"
      />
    </div>
  );
};

export default PaginationComponent;
