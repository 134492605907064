import React, { useMemo } from "react";
import { HandlerImpl } from "../handler/HandlerImpl";
import IconDown from "../../../../../assets/icons/icon-collapse-cm.svg";
import IconView from "../../../../../assets/icons/eyeIconBlack.svg";
import helpers from "../../../../../common/helpers/common";
import { TAX_CODE } from "../../../../../common/constants/COMMON";
import { Tooltip, Empty } from "antd";

interface props {
  handler: HandlerImpl;
}
const NO_DATA = "データなし";

const Table = ({ handler }: props) => {
  const contentTable = useMemo(() => {
    if (!handler.dataView || handler.dataView.length === 0)
      return (
        <tr>
          <td colSpan={15}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={NO_DATA} />
          </td>
        </tr>
      );
    const elementTable: any = [];
    handler.dataView.forEach((element: any) => {
      elementTable.push(
        <React.Fragment key={element.key}>
          <tr style={{ height: 4 }}></tr>
          <tr
            className="row-level-0"
            onClick={() => handler.funcSetExpendKey(element.key)}
          >
            <td colSpan={2}>{element?.no}</td>
            <td>{element.traderName}</td>
            <td>{helpers.formatCurrency(element.totalAmount)}円</td>
            <td colSpan={2}>
              {helpers.formatCurrency(element.taxation1)}円{" "}
              <img
                className="icon-view"
                alt=""
                src={IconView}
                onClick={(e) => {
                  e.stopPropagation();
                  handler.funcViewModalCM017_1({
                    companyId: element?.companyId,
                    colabRefId: element?.colabRefId,
                    code: TAX_CODE.TAX,
                    from: handler.from,
                    to: handler.to,
                  });
                }}
              />
            </td>
            <td colSpan={2}>
              {helpers.formatCurrency(element.taxExempt1)}円{" "}
              <img
                className="icon-view"
                alt=""
                src={IconView}
                onClick={(e) => {
                  e.stopPropagation();
                  handler.funcViewModalCM017_1({
                    companyId: element?.companyId,
                    colabRefId: element?.colabRefId,
                    code: TAX_CODE.TAX_FREE,
                    from: handler.from,
                    to: handler.to,
                  });
                }}
              />
            </td>
            <td>{helpers.formatCurrency(element.recordedAmount)}円 </td>
            <td>{helpers.formatCurrency(element.taxation2)}円 </td>
            <td>{helpers.formatCurrency(element.taxExempt2)}円</td>
            <td>{helpers.formatCurrency(element.excludingTax)}円</td>
            <td>{helpers.formatCurrency(element.consumptionTax, true)}円</td>
            <td>{helpers.formatCurrency(element.taxIncluded)}円</td>
            <td>
              <img
                alt=""
                src={IconDown}
                className={`cm024-icon ${
                  handler.funcCheckKeyExpand(element.key)
                    ? ""
                    : "cm024-icon-active"
                }`}
                onClick={() => handler.funcSetExpendKey(element.key)}
              />
            </td>
          </tr>
        </React.Fragment>
      );

      if (element.children && element.children.length > 0) {
        element.children.forEach((child1: any) => {
          elementTable.push(
            <tr
              key={child1.key}
              className="bg-fa"
              style={{
                display: handler.funcCheckKeyExpand(element.key)
                  ? undefined
                  : "none",
                cursor: "pointer",
              }}
              onClick={() => handler.funcSetExpendKey(child1.key)}
            >
              <td colSpan={2}>{child1.no}</td>
              <td>
                <Tooltip title={child1.traderName}>
                  <span className="cm0024-td-long-text">
                    {child1.traderName}
                  </span>
                </Tooltip>
              </td>
              <td className="border-left-none"></td>
              <td className="border-left-none"></td>
              <td className="border-left-none"></td>
              <td className="border-left-none"></td>
              <td className="border-left-none"></td>
              <td>{helpers.formatCurrency(child1.recordedAmount)}円</td>
              <td>{helpers.formatCurrency(child1.taxation)}円</td>
              <td>{helpers.formatCurrency(child1.taxExempt)}円</td>
              <td>{helpers.formatCurrency(child1.excludingTax)}円</td>
              <td>{helpers.formatCurrency(child1.consumptionTax, true)}円</td>
              <td>{helpers.formatCurrency(child1.taxIncluded)}円</td>
              <td>
                <img
                  alt=""
                  src={IconDown}
                  className={`cm024-icon ${
                    handler.funcCheckKeyExpand(child1.key)
                      ? ""
                      : "cm024-icon-active"
                  }`}
                  onClick={() => handler.funcSetExpendKey(child1.key)}
                />
              </td>
            </tr>
          );
          elementTable.push(
            <tr
              key={`table${child1.key}`}
              className="bg-fa"
              style={{
                display:
                  handler.funcCheckKeyExpand(element.key) &&
                  handler.funcCheckKeyExpand(child1.key)
                    ? undefined
                    : "none",
              }}
            >
              <td colSpan={2}></td>
              <td>工事名</td>
              <td>契約工事名</td>
              <td colSpan={3}>対象期間</td>
              <td>支払日</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          );
          if (child1.children && child1.children.length > 0) {
            child1.children.forEach((child2: any) => {
              elementTable.push(
                <tr
                  key={child2.key}
                  style={{
                    display:
                      handler.funcCheckKeyExpand(element.key) &&
                      handler.funcCheckKeyExpand(child1.key)
                        ? undefined
                        : "none",
                  }}
                >
                  <td style={{ width: 50 }}></td>
                  <td style={{ width: 50 }}>{child2.no}</td>
                  <td>
                    {helpers.getShortTextWithToolTip(
                      child2.constructionName,
                      15
                    )}
                  </td>
                  <td>{child2.contractWork}</td>
                  <td colSpan={3}>{child2.targetPeriod}</td>
                  <td>{child2.datePayment}</td>
                  <td>{helpers.formatCurrency(child2.recordedAmount)}円 </td>
                  <td>
                    {helpers.formatCurrency(child2.taxation)}円{" "}
                    <img
                      className="icon-view"
                      alt=""
                      src={IconView}
                      onClick={() =>
                        handler.funcViewModalCM0012_2(
                          child2?.outsourceCostInformationId,
                          TAX_CODE.TAX,
                          {
                            traderName: element?.traderName,
                            contractWork: child2?.contractWork,
                            targetPeriod: child2?.targetPeriod,
                          }
                        )
                      }
                    />
                  </td>
                  <td>
                    {helpers.formatCurrency(child2.taxExempt)}円{" "}
                    <img
                      className="icon-view"
                      alt=""
                      src={IconView}
                      onClick={() =>
                        handler.funcViewModalCM0012_2(
                          child2?.outsourceCostInformationId,
                          TAX_CODE.TAX_FREE,
                          {
                            traderName: element?.traderName,
                            contractWork: child2?.contractWork,
                            targetPeriod: child2?.targetPeriod,
                          }
                        )
                      }
                    />
                  </td>
                  <td>{helpers.formatCurrency(child2.excludingTax)}円</td>
                  <td>
                    {helpers.formatCurrency(child2.consumptionTax, true)}円
                  </td>
                  <td>{helpers.formatCurrency(child2.taxIncluded)}円</td>
                  <td></td>
                </tr>
              );
            });
          } else {
            elementTable.push(
              <tr
                key={`table${child1.key}nodata`}
                style={{
                  display:
                    handler.funcCheckKeyExpand(element.key) &&
                    handler.funcCheckKeyExpand(child1.key)
                      ? undefined
                      : "none",
                }}
              >
                <td colSpan={15}>
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={NO_DATA}
                  />
                </td>
              </tr>
            );
          }
        });
      } else {
        elementTable.push(
          <tr
            key={`${element.key}nodata`}
            style={{
              display: handler.funcCheckKeyExpand(element.key)
                ? undefined
                : "none",
            }}
          >
            <td colSpan={15}>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={NO_DATA}
              />
            </td>
          </tr>
        );
      }
    });
    return elementTable;
  }, [handler.dataView, handler.expandKey]);

  const sortType = useMemo(() => {
    if (handler.visibleCM0024) return handler.sortTypeModal;
    return handler.sortType;
  }, [handler.sortType, handler.sortTypeModal, handler.visibleCM0024]);
  const sortBy = useMemo(() => {
    if (handler.visibleCM0024) return handler.sortByModal;
    return handler.sortBy;
  }, [handler.sortBy, handler.sortByModal, handler.visibleCM0024]);

  return (
    <div className="cm024-table">
      <table>
        <thead>
          <tr>
            <th colSpan={2} rowSpan={2} style={{ width: 100 }}>
              No
            </th>
            <th rowSpan={2}>業者名</th>
            <th rowSpan={2}>相殺金額合計</th>
            <th colSpan={4}>業者別相殺金額</th>
            <th rowSpan={2}>計上金額</th>
            <th colSpan={2}>案件別相殺金額</th>
            <th rowSpan={2}>支払金額(税抜)</th>
            <th rowSpan={2}>消費税</th>
            <th rowSpan={2}>支払金額(税込)</th>
            <th rowSpan={2} style={{ width: 40 }}></th>
          </tr>
          <tr className="tr-border-first">
            <th colSpan={2}>課税</th>
            <th colSpan={2}>非課税</th>
            <th>課税</th>
            <th>非課税</th>
          </tr>
        </thead>
        <tbody>{contentTable}</tbody>
        <tfoot>
          <tr className="table-foot">
            <td colSpan={2}>合計金額</td>
            <td></td>
            <td>{helpers.formatCurrency(handler.totalTable?.totalAmount)}円</td>
            <td colSpan={2}>
              {helpers.formatCurrency(handler.totalTable?.taxation1)}円
            </td>
            <td colSpan={2}>
              {helpers.formatCurrency(handler.totalTable?.taxExempt1)}円
            </td>
            <td>
              {helpers.formatCurrency(handler.totalTable?.recordedAmount)}円
            </td>
            <td>{helpers.formatCurrency(handler.totalTable?.taxation2)}円</td>
            <td>{helpers.formatCurrency(handler.totalTable?.taxExempt2)}円</td>
            <td>
              {helpers.formatCurrency(handler.totalTable?.excludingTax)}円
            </td>
            <td>
              {helpers.formatCurrency(handler.totalTable?.consumptionTax, true)}
              円
            </td>
            <td>{helpers.formatCurrency(handler.totalTable?.taxIncluded)}円</td>
            <td></td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default Table;
