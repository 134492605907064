import timerTableIcon from "../../../../../assets/images/timerTable.png";
import alertSymbolIcon from "../../../../../assets/images/alertSymbol.png";
import sunBathingIcon from "../../../../../assets/images/sunBathing.png";
import { ICON_CODE_1, ICON_CODE_2 } from "../handler/Handler";
import React from "react";

type props = {
  title: string;
  color: string;
  icon: string;
  parentValue: string;
  childrenValue1: string;
  childrenValue2: string;
  childrenValue3: string;
};

const AM010HeaderUnit = React.memo(
  ({
    title,
    color,
    icon,
    parentValue,
    childrenValue1,
    childrenValue2,
    childrenValue3,
  }: props) => {
    return (
      <div
        className="am010__header-unit"
        style={{
          borderLeft: `4px solid ${color}`,
        }}
      >
        <div className="am010__unit-title">
          <div className="am010__unit-number">
            <h4>{title}</h4>
            <span style={{ color: color }}>
              {parentValue}
              <span className="am010__unit-inner">申請</span>
            </span>
          </div>
          <div className="am010__unit-icon">
            <img
              src={
                icon === ICON_CODE_1
                  ? timerTableIcon
                  : icon === ICON_CODE_2
                  ? alertSymbolIcon
                  : sunBathingIcon
              }
              alt="icon"
            />
          </div>
        </div>
        <div className="am010__unit-parameter">
          <div className="am010__unit-parameter-unit">
            <span className="am010__unit-span">承認待ち</span>
            <span>
              <b>{childrenValue1}</b>{" "}
              <span style={{ color: "#666666" }}>申請</span>
            </span>
          </div>
          <div className="am010__unit-parameter-unit">
            <span className="am010__unit-span">承認済み</span>
            <span>
              <b>{childrenValue2}</b>{" "}
              <span style={{ color: "#666666" }}>申請</span>
            </span>
          </div>
          <div className="am010__unit-parameter-unit">
            <span style={{ marginRight: 52 }} className="am010__unit-span">
              否認
            </span>
            <span>
              <b>{childrenValue3}</b>{" "}
              <span style={{ color: "#666666" }}>申請</span>
            </span>
          </div>
        </div>
      </div>
    );
  }
);

export default AM010HeaderUnit;
