import { Button, Col, Modal, Row, Spin } from "antd";
import { useSelector } from "react-redux";
import React from "react";

import LoadingComponent from "../../../../../common/components/icons/LoadingComponent";
import { RootState } from "../../../../../store";

import "moment/locale/ja";
import "../../../../../assets/styles/OM/OM002.css";
import "../../../../../assets/styles/OM/OM004.css";
import "../../../../../assets/styles/OM/OM007.css";

import XIconModal from "../../../../../assets/icons/x-icon-modal.svg";
import { AM010Handler } from "../handler/HandlerImpl";
import moment from "moment";
import COMMON from "../../../../../common/constants/COMMON";
import helpers from "../../../../../common/helpers/common";

type Props = {
  handler: AM010Handler;
};

const timeOffset = COMMON.TIME_ZONE;

const ModalDetailShift = ({ handler }: Props) => {
  const isVisible = useSelector(
    (state: RootState) => state.am010.isVisibleDetailShift
  );
  const loading = useSelector((state: RootState) => state.am010.loading);

  React.useEffect(() => {
    handler.getAttendanceReport({
      timeOffset,
      date: helpers.getFilterTime(moment(handler.paramDetail.date)),
      constructionId: handler.paramDetail.constructionId,
    });
  }, [handler.paramDetail.date]);

  const witdhContent = (quantity: number) => {
    const widthDefault = 948;
    const quantityDefault = 3;
    if (quantity < 4) return widthDefault;
    else {
      const widthTb = widthDefault / quantity;
      const newWidth = widthDefault + widthTb * quantity;
      return newWidth;
    }
  };

  return (
    <Modal
      className="group-detail-modal "
      style={{ marginLeft: 300, marginTop: 40, marginBottom: 40 }}
      visible={isVisible}
      closable={false}
      maskClosable={false}
      width={996}
      centered={true}
      footer={
        <>
          <Button
            disabled={loading}
            size="large"
            style={{ marginLeft: 16, width: 118 }}
            onClick={handler.onCancel}
          >
            キャンセル
          </Button>
        </>
      }
    >
      <Spin indicator={LoadingComponent} spinning={loading}>
        <div
          className="w-100-percent header-modal-containter"
          style={{ height: 50 }}
        >
          <div className="header-modal-title-om004 ">複数打刻</div>
          <div style={{ cursor: "pointer" }} onClick={handler.onCancel}>
            <img alt="" src={XIconModal} style={{ height: 18, width: 18 }} />
          </div>
        </div>
        <div
          style={{
            width: "100%",
            height: "150px",
            overflowX: `${
              handler.dataDetailShift?.length < 4 ? "hidden" : "scroll"
            }`,
            overflowY: "hidden",
          }}
        >
          <div
            style={{
              width: witdhContent(handler.dataDetailShift?.length),
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {handler.dataDetailShift.map((element: any, index: number) => {
              return (
                <div style={{ width: "440px" }}>
                  <div style={{ marginTop: 24, padding: "0 20px" }}>
                    <p style={{ marginLeft: "13%" }}>{index + 1}回目</p>
                    <div>
                      <span>出勤時刻</span>{" "}
                      <span
                        style={{
                          fontWeight: "700",
                        }}
                      >
                        {element?.checkin?.time
                          ? moment(element.checkin.time).format(
                              COMMON.FORMAT_TIME1
                            )
                          : "-"}
                      </span>
                    </div>
                    <div>
                      <span>退勤時刻</span>{" "}
                      <span
                        style={{
                          fontWeight: "400",
                        }}
                      >
                        {element?.checkout?.time &&
                          moment(element?.checkout?.time).format(
                            COMMON.FORMAT_DATE
                          ) !=
                            moment(handler.paramDetail.date).format(
                              COMMON.FORMAT_DATE
                            ) &&
                          moment(element?.checkout?.time).format("M/D")}
                      </span>
                      &nbsp;
                      <span
                        style={{
                          fontWeight: "700",
                        }}
                      >
                        {element?.checkout?.time
                          ? moment(element.checkout.time).format(
                              COMMON.FORMAT_TIME1
                            )
                          : "-"}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default ModalDetailShift;
