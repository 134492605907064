
import { Button, Divider } from 'antd';
import { type } from 'os';
import React, { useEffect, useRef, useState } from 'react';
import { Document, Page, } from 'react-pdf';
import "../../../assets/styles/components/pdf.css"
import ToLeft from "../../../assets/icons/to-left.svg"
import ToRight from "../../../assets/icons/to-right.svg"

import IconZoomIn from '../../../assets/icons/akar-icons_zoom-in.svg';
import IconZoomOutWhite from '../../../assets/icons/akar-icons_zoom-out-white.svg';
import { IMAGE_PDF_STANDARD_WIDTH } from '../../constants/COMMON';
import { pdfjs } from 'react-pdf';
const options = {
    standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
    cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
};
type props = {
    url: string,
}

const ZOOM_SCALE = [0.2, 0.3, 0.5, 0.8, 1, 1.2, 1.5, 2]

export default function PdfViewerComponent({ url }: props) {
    pdfjs.GlobalWorkerOptions.workerSrc =
        `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [scale, setScale] = useState(4)
    const [dimension, setDimension] = useState<any>({
        height: 0,
        width: 0
    })
    /*When document gets loaded successfully*/
    const onDocumentLoadSuccess = async (pdfObject: any) => {
        // If you want to get the dimension for page 1 (1-indexed)
        const page = await pdfObject.getPage(1);
        setNumPages(pdfObject.numPages);
        setPageNumber(1);

        if (page?._pageInfo?.length < 4) return;
        const originalDimension = {
            width: page._pageInfo.view[2], height: page._pageInfo.view[3]
        }

        const editedDimension = {
            width: IMAGE_PDF_STANDARD_WIDTH,
            height: Math.floor((IMAGE_PDF_STANDARD_WIDTH / originalDimension.width) * originalDimension.height)
        }
        setDimension(editedDimension)
    }

    const changePage = (offset: any) => {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    const previousPage = () => {
        changePage(-1);
    }

    const nextPage = () => {
        changePage(1);
    }

    const zoomIn = () => {
        if (scale > 1) {
            setScale(scale - 1)
        }
    }

    const zoomOut = () => {
        if (scale < ZOOM_SCALE.length - 1) {
            setScale(scale + 1)
        }
    }

    return (
        <div className="main">
            <div className='pdf-document-container '
                style={{
                    justifyContent: dimension.width * ZOOM_SCALE[scale] > IMAGE_PDF_STANDARD_WIDTH ? '' : 'center',
                    alignItems: dimension.height * ZOOM_SCALE[scale] > 703 ? '' : 'center',
                }} >
                <div style={{ width: "fit-content" }} >
                    <Document
                        file={url}
                        onLoadSuccess={onDocumentLoadSuccess}
                        options={options}
                    >
                        <Page scale={ZOOM_SCALE[scale]} pageNumber={pageNumber} width={IMAGE_PDF_STANDARD_WIDTH}
                            renderTextLayer={true} />
                    </Document>


                </div>
            </div>

            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 16 }}>
                <div className="pm005-modal-img-zoom">
                    <div className="pm005-modal-img-zoom-left">
                        <Button style={{ border: "none" }} disabled={pageNumber <= 1}
                            icon={<img alt='' src={ToLeft} />}
                            onClick={previousPage} type="link">

                        </Button>

                        <div className="pagec">
                            {pageNumber || (numPages ? 1 : '--')} / {numPages || '--'}
                        </div>
                        <Button icon={<img alt='' src={ToRight} />}
                            style={{ border: "none" }} disabled={pageNumber >= (numPages ?? 0)}
                            onClick={nextPage}
                            type="link">
                        </Button>
                    </div>

                    <div className="pm005-modal-img-zoom-left">Zoom: {ZOOM_SCALE[scale] * 100 + "%"}</div>
                    <div className="pm005-modal-img-zoom-right">
                        <button onClick={zoomIn}>
                            <img alt="" src={IconZoomOutWhite} />
                        </button>
                        <button onClick={zoomOut}>
                            <img alt="" src={IconZoomIn} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}