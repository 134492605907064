import SM03Api from './infrastructure/api/Api';
import SM03Handler from './presenter/handler/Handler';
import SM03UI from './presenter/ui/UI';
import SM03Service from './usecase/Service';

const SM03Component = () => {
    const sm03Api = SM03Api();
    const sm03Service = SM03Service(sm03Api);
    const sm03Handler = SM03Handler(sm03Service);
    return <SM03UI handler={sm03Handler} />;
};

export default SM03Component;
