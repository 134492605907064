import { useDispatch } from "react-redux";
import { setAM040 } from "../slice/Slice";

const ButtonWarningHandler = () => {
  const dispatch = useDispatch();

  const onClickWarningButton = (data: {
    date: string;
    constructionId: string;
    contractorConstructionId: string;
    userId: string;
  }) => {
    dispatch(setAM040(data));
  };

  return { onClickWarningButton };
};

export default ButtonWarningHandler;
