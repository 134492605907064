import { AxiosResponse } from "axios";
import axiosConfig from "../../../../../config/axiosConfig";

const AM001Api = () => {
  const getAttendanceSubConstructionListMember = async (params: {
    constructionId: number;
    contractorConstructionId: number;
    from: string; // 2006-01-02
    to: string; // 2006-01-02,
    timeOffset: number;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/attendance/subconstruction/list-member";
    return await axiosConfig.get(url, { params });
  };

  const getAttendanceUserTimeSheet = async (params: {
    date: string;
    constructionId?: string;
    contractorConstructionId?: string;
    userId: string;
    timeOffset: number;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/attendance/user-time-sheet";
    return await axiosConfig.get(url, { params });
  };
  const getAttendanceConstructionTotalCost = async (params: {
    constructionId: number;
    timeOffset: number;
    from: string; // 2006-01-02
    to: string; // 2006-01-02,
    keyword: string;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/attendance/construction/total-cost";
    return await axiosConfig.get(url, { params });
  };
  return {
    getAttendanceSubConstructionListMember,
    getAttendanceUserTimeSheet,
    getAttendanceConstructionTotalCost,
  };
};

export default AM001Api;
