import React, { useEffect, useRef } from 'react';
import { HandlerImpl } from '../handler/HandlerImpl';
import { Empty, Form, Input } from 'antd';

import IconMinus from '../../../../../assets/icons/minus-purple.svg';
import IconPlus from '../../../../../assets/icons/plus-purpose.svg';
import ItemSelect from '../../components/ItemSelect';
import { onBlurTrimFormItem } from '../../../../../common/helpers/typeInput';
import MESSAGE from '../../../../../common/constants/MESSAGE';
import REGEX from '../../../../../common/constants/REGEX';

const { Item } = Form;
interface props {
    handler: HandlerImpl;
}
const BodyRight = ({ handler }: props) => {
    const thScroll: any = useRef(null);
    const tableScroll: any = useRef(null);

    useEffect(() => {
        if (thScroll?.current && tableScroll?.current) {
            if (tableScroll.current.clientHeight === tableScroll.current.scrollHeight)
                thScroll.current.style.display = 'none';
            else thScroll.current.style.display = 'table-cell';
        }
    }, [handler.dataRightView, handler.isEdit]);

    useEffect(() => {
        if (tableScroll?.current && handler.isAddDataRight.isAdd) {
            tableScroll.current.scrollTo(0, tableScroll.current.scrollHeight);
        }
    }, [handler.isAddDataRight.isChange]);

    return (
        <div className="sw02-body-right">
            {handler.dataLeft?.length > 0 ? (
                <>
                    <div className="sw02-body-right-header">
                        <span className="label">ワークフロー名</span>
                        {!handler.isEdit ? (
                            <span className="content">
                                {
                                    handler.dataLeft.find(
                                        (item) => `${item.id}` === `${handler.menu}`,
                                    )?.name
                                }
                            </span>
                        ) : (
                            <Item
                                name="name"
                                rules={[
                                    () => ({
                                        validator(_, value) {
                                            if (value && value !== '') {
                                                // CHECK INPUT VIETNAMESE
                                                if (!REGEX.NOT_VIETNAM.test(value)) {
                                                    return Promise.reject(
                                                        new Error(MESSAGE.MESSAGE_SW_NAME),
                                                    );
                                                }
                                                if (value?.length > 20) {
                                                    return Promise.reject(new Error(''));
                                                }
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error(MESSAGE.MESSAGE_REQUIRED_FIELD),
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <Input
                                    size="large"
                                    className="sw002-input"
                                    maxLength={20}
                                    onBlur={() => onBlurTrimFormItem(handler.form, 'name')}
                                    placeholder="ワークフロー名を入力してください"  // CR #344212
                                />
                            </Item>
                        )}
                    </div>
                    <div className="sw02-table">
                        <table>
                            <thead>
                                <tr>
                                    {handler.isEdit ? <th style={{ width: 80 }}></th> : null}
                                    <th>承認フロー</th>
                                    <th>名前</th>
                                    <th>役職</th>
                                    <th>部門</th>
                                    <th ref={thScroll} className="th-scroll"></th>
                                </tr>
                            </thead>
                        </table>
                        <div
                            className={`sw02-table-scroll ${!handler.isEdit ? 'no-edit' : ''}`}
                            ref={tableScroll}
                            id="sw-scroll"
                        >
                            <table>
                                <tbody>
                                    {handler.dataRightView.map((item) => {
                                        return (
                                            <tr key={item.key}>
                                                {handler.isEdit ? (
                                                    <td width={80}>
                                                        {item.priority !== 1 ? (
                                                            <img
                                                                src={IconMinus}
                                                                alt=""
                                                                className="sw02-icon-minus"
                                                                onClick={() =>
                                                                    handler.handleRemoveDataRight(
                                                                        item.key,
                                                                        item.priority,
                                                                    )
                                                                }
                                                            />
                                                        ) : null}
                                                    </td>
                                                ) : null}
                                                <td>{`第${item.priority}承認者`}</td>
                                                <td className={handler.isEdit ? 'edit' : ''}>
                                                    {!handler.isEdit ? (
                                                        item.fullName || '-'
                                                    ) : (
                                                        <ItemSelect
                                                            nameForm={item.key}
                                                            options={item.options}
                                                            handleSearchOption={
                                                                handler.handleSearchOption
                                                            }
                                                            handleScrollMember={
                                                                handler.handleScrollMember
                                                            }
                                                            onDropdownVisibleChange={(open) => {
                                                                if (open)
                                                                    handler.getListMember(item);
                                                            }}
                                                        />
                                                    )}
                                                </td>
                                                <td>{item.role?.name || '-'}</td>
                                                <td>{item.group?.name || '-'}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        {handler.isEdit ? (
                            <div className="sw02-table-footer">
                                <div
                                    className="sw02-right-add"
                                    onClick={handler.handleAddDataRight}
                                >
                                    <img src={IconPlus} alt="" />
                                    <span>承認者追加</span>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </>
            ) : (
                <div className="sw01-body-right-empty">
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={MESSAGE.MESSAGE_NO_DATA}
                    />
                </div>
            )}
        </div>
    );
};

export default BodyRight;
