import { Collapse } from "antd";
import NewSvg from "../../../../../assets/icons/new.svg";
import { CRT001AmountUIHandler } from "../handler/HandlerImpl";
import IconDown from "../../../../../assets/icons/arrow.svg";

const { Panel } = Collapse;

interface props {
  handler: CRT001AmountUIHandler;
}

function CRT001AmountRelated({ handler }: props) {
  return (
    <div className="cr001-scroll">
      <h3 className="crt001-title-amount">金額関係</h3>
      <Collapse
        className="cr001-collapse folder-collapse folder-collapse-child"
        defaultActiveKey={[
          "contract-amount",
          "construction-cost",
          "list-indirect",
        ]}
        expandIcon={({ isActive }) => {
          return (
            <div
              className={`${
                isActive ? "collapse-icon-active" : ""
              } collapse-icon`}
            >
              <img src={IconDown} alt="" />
            </div>
          );
        }}
      >
        <Panel
          key="construction-cost"
          header="直接工事費一覧"
          className="cr001-contract-amount-panel cr001-construction-cost-panel cr-text-header-collapse"
        >
          <div className="cr001-construction-cost-item bg-header-row">
            <div className="cr001-construction-cost-item-child crt-6 not-border-right crt-text-first">
              工事名
            </div>
            <div className="cr001-construction-cost-item-child crt-6"></div>
            <div className="cr001-construction-cost-item-child crt-6">
              見積金額（円）
            </div>
            <div className="cr001-construction-cost-item-child crt-6">
              原価金額（円）
            </div>
            <div className="cr001-construction-cost-item-child crt-6">
              予算金額（円）
            </div>
            <div className="cr001-construction-cost-item-child crt-6">
              発注金額（円）
            </div>
          </div>
          {handler.directList?.map((direct: any, index: number) => {
            return (
              <div
                className="cr001-construction-cost-item"
                key={`direct${index}`}
              >
                <div className="cr001-construction-cost-item-child crt-6 not-border-right crt-text-first">
                  {direct?.constructionName}
                </div>
                <div className="cr001-construction-cost-item-child crt-6"></div>
                <div className="cr001-construction-cost-item-child crt-6">
                  {handler.checkFormatValue(direct?.estimateMoney, false)}
                </div>
                <div className="cr001-construction-cost-item-child crt-6">
                  {handler.checkFormatValue(direct?.costMoney, false)}
                </div>
                <div className="cr001-construction-cost-item-child crt-6">
                  {handler.checkFormatValue(direct.budgetMoney, false)}{" "}
                  {handler.checkFormatValue(direct.budgetMoneyPercent, true)}
                </div>
                <div className="cr001-construction-cost-item-child crt-6">
                  {handler.checkFormatValue(direct?.orderMoney, false)}{" "}
                  {handler.checkFormatValue(direct?.orderMoneyPercent, true)}
                </div>
              </div>
            );
          })}
          <div className="cr001-construction-cost-item bg-header-row">
            <div className="cr001-construction-cost-item-child crt-6 not-border-right crt-text-first">
              合計金額
            </div>
            <div className="cr001-construction-cost-item-child crt-6"></div>
            <div className="cr001-construction-cost-item-child crt-6">
              {handler.checkFormatValue(
                handler.total?.totalDirect?.estimateMoney,
                false
              )}
            </div>
            <div className="cr001-construction-cost-item-child crt-6">
              {handler.checkFormatValue(
                handler.total?.totalDirect?.costMoney,
                false
              )}
            </div>
            <div className="cr001-construction-cost-item-child crt-6">
              {handler.checkFormatValue(
                handler.total?.totalDirect.budgetMoney,
                false
              )}{" "}
              {handler.checkFormatValue(
                handler.total?.totalDirect.budgetMoneyPercent,
                true
              )}
            </div>
            <div className="cr001-construction-cost-item-child crt-6">
              {handler.checkFormatValue(
                handler.total?.totalDirect?.orderMoney,
                false
              )}{" "}
              {handler.checkFormatValue(
                handler.total?.totalDirect?.orderMoneyPercent,
                true
              )}
            </div>
          </div>
        </Panel>
      </Collapse>
      <div className="cr001-cost-registration">
        <Collapse
          className="folder-collapse folder-collapse-child"
          defaultActiveKey={["material-cost"]}
          expandIcon={({ isActive }) => {
            return (
              <div
                className={`${
                  isActive ? "collapse-icon-active" : ""
                } collapse-icon`}
              >
                <img src={IconDown} alt="" />
              </div>
            );
          }}
        >
          <Panel
            key="material-cost"
            header={
              <div className="crt-header-panel">
                <img src={NewSvg} alt="" />
                <span>材料費登録</span>
              </div>
            }
            className="cr001-contract-amount-panel cr001-construction-cost-panel cr-text-header-collapse"
          >
            <div className="cr001-cr-item bg-header-row">
              <div className="cr001-cr-item-child border-bottom border-right crt-7 padding-tb-15 crt-text-first">
                工事名
              </div>
              <div className="cr001-cr-item-child border-bottom border-right crt-7 padding-tb-15 crt-text-first">
                項目名
              </div>
              <div className="cr001-cr-item-child border-bottom border-right crt-7 padding-tb-15">
                業者名
              </div>
              <div className="cr001-cr-item-child border-bottom border-right crt-7 padding-tb-15">
                見積金額（円）
              </div>
              <div className="cr001-cr-item-child border-bottom border-right crt-7 padding-tb-15">
                原価金額（円）
              </div>
              <div className="cr001-cr-item-child border-bottom border-right crt-7 padding-tb-15">
                予算金額（円）
              </div>
              <div className="cr001-cr-item-child border-bottom border-right crt-7 padding-tb-15">
                発注金額（円）
              </div>
            </div>
            {handler.materialCostList?.map(
              (materialCost: any, index: number) => {
                return (
                  <div
                    className="cr001-cr-item cr001-icon-rotate"
                    key={`materialCost${index}`}
                  >
                    <div className="cr001-cr-item-child border-bottom border-right crt-7 padding-tb-15 crt-text-first">
                      {materialCost.constructionName}
                    </div>
                    <div className="cr001-cr-item-child border-bottom border-right crt-7 padding-tb-15 crt-text-first">
                      {materialCost.materialName}
                    </div>
                    <div className="cr001-cr-item-child border-bottom border-right crt-7 padding-tb-15">
                      {materialCost.materialCostCompany?.name}
                    </div>
                    <div className="cr001-cr-item-child border-bottom border-right crt-7 padding-tb-15">
                      {handler.checkFormatValue(
                        materialCost.estimateMoney,
                        false
                      )}
                    </div>
                    <div className="cr001-cr-item-child border-bottom border-right crt-7 padding-tb-15">
                      {handler.checkFormatValue(materialCost.costMoney, false)}
                    </div>
                    <div className="cr001-cr-item-child border-bottom border-right crt-7 padding-tb-15 cr003-item-percent">
                      {handler.checkFormatValue(
                        materialCost.budgetMoney,
                        false
                      )}{" "}
                      {handler.checkFormatValue(
                        materialCost.budgetMoneyPercent,
                        true
                      )}
                    </div>
                    <div className="cr001-cr-item-child border-bottom border-right crt-7 padding-tb-15 cr003-item-percent">
                      {handler.checkFormatValue(materialCost.orderMoney, false)}{" "}
                      {handler.checkFormatValue(
                        materialCost.orderMoneyPercent,
                        true
                      )}
                    </div>
                  </div>
                );
              }
            )}
            <div className="cr001-cr-item bg-header-row">
              <div className="cr001-cr-item-child border-bottom crt-7 padding-tb-15 crt-text-first">
                合計金額
              </div>
              <div className="cr001-cr-item-child border-bottom crt-7 padding-tb-15"></div>
              <div className="cr001-cr-item-child border-bottom border-right crt-7 padding-tb-15"></div>
              <div className="cr001-cr-item-child border-bottom border-right crt-7 padding-tb-15">
                {handler.checkFormatValue(
                  handler.total?.totalMate?.estimateMoney,
                  false
                )}
              </div>
              <div className="cr001-cr-item-child border-bottom border-right crt-7 padding-tb-15">
                {handler.checkFormatValue(
                  handler.total?.totalMate?.costMoney,
                  false
                )}
              </div>
              <div className="cr001-cr-item-child border-bottom border-right crt-7 padding-tb-15">
                {handler.checkFormatValue(
                  handler.total?.totalMate?.budgetMoney,
                  false
                )}{" "}
                {handler.checkFormatValue(
                  handler.total?.totalMate?.budgetMoneyPercent,
                  true
                )}
              </div>
              <div className="cr001-cr-item-child border-bottom border-right crt-7 padding-tb-15">
                {handler.checkFormatValue(
                  handler.total?.totalMate?.orderMoney,
                  false
                )}{" "}
                {handler.checkFormatValue(
                  handler.total?.totalMate?.orderMoneyPercent,
                  true
                )}
              </div>
            </div>
          </Panel>
        </Collapse>
      </div>
      <div className="cr001-cost-registration">
        <Collapse
          className="folder-collapse folder-collapse-child"
          defaultActiveKey={["subcontract-cost"]}
          expandIcon={({ isActive }) => {
            return (
              <div
                className={`${
                  isActive ? "collapse-icon-active" : ""
                } collapse-icon`}
              >
                <img src={IconDown} alt="" />
              </div>
            );
          }}
        >
          <Panel
            key="subcontract-cost"
            header={
              <div className="crt-header-panel">
                <img src={NewSvg} alt="" />
                <span>外注費登録</span>
              </div>
            }
            className="cr001-contract-amount-panel cr001-construction-cost-panel cr-text-header-collapse"
          >
            <div className="cr001-cr-item bg-header-row">
              <div className="cr001-cr-item-child border-bottom border-right crt-8-9 padding-tb-15 crt-text-first">
                工事名
              </div>
              <div className="cr001-cr-item-child border-bottom border-right crt-8-9 padding-tb-15 crt-text-first">
                契約工事名
              </div>
              <div className="cr001-cr-item-child border-bottom border-right crt-8-9 padding-tb-15 ">
                業者名
              </div>
              <div className="cr001-cr-item-child border-bottom border-right crt-8-9 padding-tb-15">
                契約日
              </div>
              <div className="cr001-cr-item-child border-bottom border-right crt-8 padding-tb-15">
                工期
              </div>
              <div className="cr001-cr-item-child border-bottom border-right crt-8-9 padding-tb-15">
                見積金額（円）
              </div>
              <div className="cr001-cr-item-child border-bottom border-right crt-8-9 padding-tb-15">
                原価金額（円）
              </div>
              <div className="cr001-cr-item-child border-bottom border-right crt-8-9 padding-tb-15">
                予算金額（円）
              </div>
              <div className="cr001-cr-item-child border-bottom border-right crt-8-9 padding-tb-15">
                発注金額（円）
              </div>
            </div>
            {handler.subcontractCostList?.map(
              (subcontractCost: any, index: number) => {
                return (
                  <div
                    className="cr001-cr-item cr001-icon-rotate"
                    key={`outsourceRegisterCost${index}`}
                  >
                    <div className="cr001-cr-item-child border-bottom border-right crt-8-9 padding-tb-15 crt-text-first">
                      {subcontractCost?.constructionName}
                    </div>
                    <div
                      className="cr001-cr-item-child border-bottom border-right crt-8-9 padding-tb-15 crt-text-first"
                      style={{ wordWrap: "break-word" }}
                    >
                      {subcontractCost?.constructionNameChild}
                    </div>
                    <div className="cr001-cr-item-child border-bottom border-right crt-8-9 padding-tb-15">
                      {subcontractCost.company?.name}
                    </div>
                    <div className="cr001-cr-item-child border-bottom border-right crt-8-9 padding-tb-15">
                      {handler.checkFormatDate(
                        subcontractCost.contractTime,
                        ""
                      )}
                    </div>
                    <div className="cr001-cr-item-child border-bottom border-right crt-8 padding-tb-15">
                      {handler.checkFormatDate(
                        subcontractCost.startTime,
                        subcontractCost.endTime
                      )}
                    </div>
                    <div className="cr001-cr-item-child border-bottom border-right crt-8-9 padding-tb-15">
                      {handler.checkFormatValue(
                        subcontractCost.estimateMoney,
                        false
                      )}
                    </div>
                    <div className="cr001-cr-item-child border-bottom border-right crt-8-9 padding-tb-15">
                      {handler.checkFormatValue(
                        subcontractCost.costMoney,
                        false
                      )}
                    </div>
                    <div className="cr001-cr-item-child border-bottom border-right crt-8-9 padding-tb-15 cr003-item-percent">
                      {handler.checkFormatValue(
                        subcontractCost.budgetMoney,
                        false
                      )}{" "}
                      {handler.checkFormatValue(
                        subcontractCost.budgetMoneyPercent,
                        true
                      )}
                    </div>
                    <div className="cr001-cr-item-child border-bottom border-right crt-8-9 padding-tb-15 cr003-item-percent">
                      {handler.checkFormatValue(
                        subcontractCost.orderMoney,
                        false
                      )}{" "}
                      {handler.checkFormatValue(
                        subcontractCost.orderMoneyPercent,
                        true
                      )}
                    </div>
                  </div>
                );
              }
            )}
            <div className="cr001-cr-item bg-header-row">
              <div className="cr001-cr-item-child border-bottom crt-8-9 padding-tb-15 crt-text-first">
                合計金額
              </div>
              <div className="cr001-cr-item-child border-bottom crt-8-9 padding-tb-15"></div>
              <div className="cr001-cr-item-child border-bottom crt-8-9 padding-tb-15"></div>
              <div className="cr001-cr-item-child border-bottom crt-8-9 padding-tb-15"></div>
              <div className="cr001-cr-item-child border-bottom border-right crt-8 padding-tb-15"></div>
              <div className="cr001-cr-item-child border-bottom border-right crt-8-9 padding-tb-15">
                {handler.checkFormatValue(
                  handler.total?.totalOutsource?.estimateMoney,
                  false
                )}
              </div>
              <div className="cr001-cr-item-child border-bottom border-right crt-8-9 padding-tb-15">
                {handler.checkFormatValue(
                  handler.total?.totalOutsource?.costMoney,
                  false
                )}
              </div>
              <div className="cr001-cr-item-child border-bottom border-right crt-8-9 padding-tb-15">
                {handler.checkFormatValue(
                  handler.total?.totalOutsource?.budgetMoney,
                  false
                )}{" "}
                {handler.checkFormatValue(
                  handler.total?.totalOutsource?.budgetMoneyPercent,
                  true
                )}
              </div>
              <div className="cr001-cr-item-child border-bottom border-right crt-8-9 padding-tb-15">
                {handler.checkFormatValue(
                  handler.total?.totalOutsource?.orderMoney,
                  false
                )}{" "}
                {handler.checkFormatValue(
                  handler.total?.totalOutsource?.orderMoneyPercent,
                  true
                )}
              </div>
            </div>
          </Panel>
        </Collapse>
      </div>
      <div className="cr001-cost-registration">
        <Collapse
          className="folder-collapse folder-collapse-child"
          defaultActiveKey={["subcontract-cost"]}
          expandIcon={({ isActive }) => {
            return (
              <div
                className={`${
                  isActive ? "collapse-icon-active" : ""
                } collapse-icon`}
              >
                <img src={IconDown} alt="" />
              </div>
            );
          }}
        >
          <Panel
            key="subcontract-cost"
            header={
              <div className="crt-header-panel">
                <img src={NewSvg} alt="" />
                <span>労務費登録</span>
              </div>
            }
            className="cr001-contract-amount-panel cr001-construction-cost-panel cr-text-header-collapse"
          >
            <div className="cr001-cr-item bg-header-row">
              <div className="cr001-cr-item-child border-bottom border-right crt-5 padding-tb-15 crt-text-first crt-text-first">
                工事名
              </div>
              <div className="cr001-cr-item-child border-bottom border-right crt-5 padding-tb-15">
                担当者
              </div>
              <div className="cr001-cr-item-child border-bottom border-right crt-5 padding-tb-15">
                見積金額（円）
              </div>
              <div className="cr001-cr-item-child border-bottom border-right crt-5 padding-tb-15">
                原価金額（円）
              </div>
              <div className="cr001-cr-item-child border-bottom border-right crt-5 padding-tb-15">
                予算金額（円）
              </div>
            </div>
            {handler.laborCostList?.map((laborCost: any, index: number) => {
              return (
                <div
                  className="cr001-cr-item cr001-icon-rotate "
                  key={`laborCost${index}`}
                >
                  <div className="cr001-cr-item-child border-bottom border-right crt-5 padding-tb-15 crt-text-first">
                    {laborCost?.constructionName}
                  </div>
                  <div className="cr001-cr-item-child border-bottom border-right crt-5 padding-tb-15">
                    {laborCost?.manager?.fullName ||
                      laborCost?.manager?.username}
                  </div>
                  <div className="cr001-cr-item-child border-bottom border-right crt-5 padding-tb-15">
                    {handler.checkFormatValue(laborCost.estimateMoney, false)}
                  </div>
                  <div className="cr001-cr-item-child border-bottom border-right crt-5 padding-tb-15">
                    {handler.checkFormatValue(laborCost.costMoney, false)}
                  </div>
                  <div className="cr001-cr-item-child border-bottom border-right crt-5 padding-tb-15 cr003-item-labor-cost-percent">
                    {handler.checkFormatValue(laborCost.budgetMoney, false)}{" "}
                    {handler.checkFormatValue(
                      laborCost.budgetMoneyPercent,
                      true
                    )}
                  </div>
                </div>
              );
            })}
            <div className="cr001-cr-item bg-header-row">
              <div className="cr001-cr-item-child border-bottom crt-5 padding-tb-15 crt-text-first">
                合計金額
              </div>
              <div className="cr001-cr-item-child border-bottom border-right crt-5 padding-tb-15"></div>
              <div className="cr001-cr-item-child border-bottom border-right crt-5 padding-tb-15">
                {handler.checkFormatValue(
                  handler.total?.totalLabor?.estimateMoney,
                  false
                )}
              </div>
              <div className="cr001-cr-item-child border-bottom border-right crt-5 padding-tb-15">
                {handler.checkFormatValue(
                  handler.total?.totalLabor?.costMoney,
                  false
                )}
              </div>
              <div className="cr001-cr-item-child border-bottom border-right crt-5 padding-tb-15">
                {handler.checkFormatValue(
                  handler.total?.totalLabor?.budgetMoney,
                  false
                )}{" "}
                {handler.checkFormatValue(
                  handler.total?.totalLabor?.budgetMoneyPercent,
                  true
                )}
              </div>
            </div>
          </Panel>
        </Collapse>
      </div>
      <div className="cr001-cost-registration">
        <Collapse
          className="folder-collapse folder-collapse-child"
          defaultActiveKey={["indirect-cost"]}
          expandIcon={({ isActive }) => {
            return (
              <div
                className={`${
                  isActive ? "collapse-icon-active" : ""
                } collapse-icon`}
              >
                <img src={IconDown} alt="" />
              </div>
            );
          }}
        >
          <Panel
            key="indirect-cost"
            header={
              <div className="crt-header-panel">
                <span
                  style={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: "#222",
                  }}
                >
                  間接工事費一覧
                </span>
              </div>
            }
            className="cr001-contract-amount-panel cr001-construction-cost-panel cr-text-header-collapse"
          >
            <div className="cr001-cr-item bg-header-row">
              <div className="cr001-cr-item-child border-bottom border-right crt-5 padding-tb-15 crt-text-first">
                工事名
              </div>
              <div className="cr001-cr-item-child border-bottom border-right crt-5 padding-tb-15">
                項目名
              </div>
              <div className="cr001-cr-item-child border-bottom border-right crt-5 padding-tb-15">
                見積金額（円）
              </div>
              <div className="cr001-cr-item-child border-bottom border-right crt-5 padding-tb-15">
                原価金額（円）
              </div>
              <div className="cr001-cr-item-child border-bottom border-right crt-5 padding-tb-15">
                予算金額（円）
              </div>
            </div>
            {handler.listIndirect?.map((indirect: any, index: number) => {
              return (
                <div
                  className="cr001-cr-item cr001-icon-rotate"
                  key={`indirect${index}`}
                >
                  <div className="cr001-cr-item-child border-bottom border-right crt-5 padding-tb-15 crt-text-first">
                    {indirect?.constructionName}
                  </div>
                  <div className="cr001-cr-item-child border-bottom border-right crt-5 padding-tb-15">
                    {indirect?.item}
                  </div>
                  <div className="cr001-cr-item-child border-bottom border-right crt-5 padding-tb-15">
                    {handler.checkFormatValue(indirect.estimateMoney, false)}
                  </div>
                  <div className="cr001-cr-item-child border-bottom border-right crt-5 padding-tb-15">
                    {handler.checkFormatValue(indirect.costMoney, false)}
                  </div>
                  <div className="cr001-cr-item-child border-bottom border-right crt-5 padding-tb-15 cr003-item-labor-cost-percent">
                    {handler.checkFormatValue(indirect.budgetMoney, false)}{" "}
                    {handler.checkFormatValue(
                      indirect.budgetMoneyPercent,
                      true
                    )}
                  </div>
                </div>
              );
            })}
            <div className="cr001-cr-item bg-header-row">
              <div className="cr001-cr-item-child border-bottom crt-5 padding-tb-15 crt-text-first">
                合計金額
              </div>
              <div className="cr001-cr-item-child border-bottom border-right crt-5 padding-tb-15"></div>
              <div className="cr001-cr-item-child border-bottom border-right crt-5 padding-tb-15">
                {handler.checkFormatValue(
                  handler.total?.totalIndirect?.estimateMoney,
                  false
                )}
              </div>
              <div className="cr001-cr-item-child border-bottom border-right crt-5 padding-tb-15">
                {handler.checkFormatValue(
                  handler.total?.totalIndirect?.costMoney,
                  false
                )}
              </div>
              <div className="cr001-cr-item-child border-bottom border-right crt-5 padding-tb-15">
                {handler.checkFormatValue(
                  handler.total?.totalIndirect?.budgetMoney,
                  false
                )}{" "}
                {handler.checkFormatValue(
                  handler.total?.totalIndirect?.budgetMoneyPercent,
                  true
                )}
              </div>
            </div>
          </Panel>
        </Collapse>
      </div>
      <div className="cr001-other-total">
        <div className="cr001-cost-registration-title bg-title-row padding-tb-15 border-bottom">
          <span>粗利</span>
        </div>
        <div className="cr001-cr-item bg-header-row">
          <div className="cr001-cr-item-child border-bottom border-right cr-2 padding-tb-15">
            予想粗利金額（円）
          </div>
          <div className="cr001-cr-item-child border-bottom border-right cr-2 padding-tb-15">
            粗利率
          </div>
        </div>
        <div className="cr001-cr-item ">
          <div className="cr001-cr-item-child border-bottom border-right cr-2 padding-tb-15">
            {handler.checkFormatValue(
              handler.total?.profit?.expectGross,
              false
            )}
          </div>
          <div className="cr001-cr-item-child border-bottom border-right cr-2 padding-tb-15">
            {`${handler.checkFormatValue(
              handler.total?.profit?.grossProfit,
              true
            )}`
              .replace("(", "")
              .replace(")", "")}
          </div>
        </div>
        <div className="cr001-cost-registration-title bg-title-row padding-tb-15 border-bottom">
          <span>総計</span>
        </div>
        <div className="cr001-cr-item bg-header-row">
          <div className="cr001-cr-item-child border-bottom border-right cr-4 padding-tb-15">
            見積金額（円）
          </div>
          <div className="cr001-cr-item-child border-bottom border-right cr-4 padding-tb-15">
            原価金額（円）
          </div>
          <div className="cr001-cr-item-child border-bottom border-right cr-4 padding-tb-15">
            予算金額（円）
          </div>
          <div className="cr001-cr-item-child border-bottom border-right cr-4 padding-tb-15">
            発注金額（円）
          </div>
        </div>
        <div className="cr001-cr-item ">
          <div className="cr001-cr-item-child border-bottom border-right cr-4 padding-tb-15">
            {handler.checkFormatValue(
              handler.total?.totalAll?.estimateMoney,
              false
            )}
          </div>
          <div className="cr001-cr-item-child border-bottom border-right cr-4 padding-tb-15">
            {handler.checkFormatValue(
              handler.total?.totalAll?.costMoney,
              false
            )}
          </div>
          <div className="cr001-cr-item-child border-bottom border-right cr-4 padding-tb-15">
            {handler.checkFormatValue(
              handler.total?.totalAll?.budgetMoney,
              false
            )}{" "}
            {handler.checkFormatValue(
              handler.total?.totalAll?.budgetMoneyPercent,
              true
            )}
          </div>
          <div className="cr001-cr-item-child border-bottom border-right cr-4 padding-tb-15">
            {handler.checkFormatValue(
              handler.total?.totalAll?.orderMoney,
              false
            )}{" "}
            {handler.checkFormatValue(
              handler.total?.totalAll?.orderMoneyPercent,
              true
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CRT001AmountRelated;
