import { Button, Modal, Input, Spin, Form } from "antd";
import { memo, useEffect, useRef } from "react";
import { CR001ModalHistoryHandler } from "../handler/HandlerImpl";

const { TextArea } = Input;

interface props {
  handler: CR001ModalHistoryHandler;
}

function CR001ModalHistory({ handler }: props) {
  const ref = useRef<null | HTMLElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, []);

  return (
    <Modal
      title="変更内容"
      className="cr001-history-modal"
      visible={handler.isViewModalHistory}
      onCancel={handler.handleCloseNoteHistory}
      footer={null}
      width={"60%"}
      centered
    >
      <Spin spinning={handler.loadingNote}>
        <Form name="note-version" onFinish={handler.handleKeepNote}>
          <div>
            <div className="cr001-modal-detail-history">
              <span className="cr001-modal-detail-history-label">
                バージョン
              </span>
              <span className="cr001-modal-detail-history-title">
                {handler.isEditNote
                  ? handler.versionNew.versionNumber + 1
                  : handler.versionNew?.versionNumber}
              </span>
            </div>
            <div className="cr001-modal-detail-history-note">
              <span className="cr001-modal-history-note-text">変更内容</span>
              {handler.isEditNote ? (
                <Form.Item
                  name="input-version"
                  rules={[{ required: true, message: "" }]}
                  style={{ width: "100%" }}
                >
                  <TextArea
                    ref={ref}
                    rows={8}
                    value={handler.detailHistoryNote}
                    onChange={handler.onChangeDetailHistoryNote}
                    maxLength={300}
                  />
                </Form.Item>
              ) : (
                <p>{handler.detailHistoryNote}</p>
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "24px 24px 0 24px",
              margin: "0 -24px",
              borderTop: "1px solid #e6eaf0",
            }}
          >
            {handler.isEditNote ? (
              <>
                <Button
                  size="large"
                  className="button-purple"
                  loading={handler.loadingNote}
                  htmlType="submit"
                  style={{ letterSpacing: -1, marginRight: 16 }}
                >
                  保存
                </Button>
                <Button size="large" onClick={handler.handleCloseNoteHistory}>
                  キャンセル
                </Button>
              </>
            ) : (
              <Button
                size="large"
                htmlType="button"
                className="button-basic"
                onClick={handler.handleFinishNote}
                style={{ letterSpacing: -2 }}
              >
                終了
              </Button>
            )}
          </div>
        </Form>
      </Spin>
    </Modal>
  );
}

export default memo(CR001ModalHistory);
