import { createSlice } from '@reduxjs/toolkit';
import { CM0025SliceType } from '../../entity/Entity';

export const initialState: CM0025SliceType = {
    isDisableRetrieveButton: true,
    isRefresh: false,
    isVisibleCM0025: false,
    documentId: null,
    documentInfo: {
        documentTitle: '',
        createdDate: '',
        targetPeriod: '',
        status: '',
    },
};

export const CM0025Slice = createSlice({
    name: 'CM0025',
    initialState,
    reducers: {
        resetCM0025: () => initialState,
        setVisibleCM0025: (state, action) => {
            state.isVisibleCM0025 = action.payload;
        },
        setDocumentIdCM0025: (state, action) => {
            state.documentId = action.payload;
        },
        setDocumentInfoCM0025: (state, action) => {
            state.documentInfo = action.payload;
        },
        setIsDisableRetrieveButtonCM0025: (state, action) => {
            state.isDisableRetrieveButton = action.payload;
        },
    },
});

export const {
    resetCM0025,
    setVisibleCM0025,
    setDocumentIdCM0025,
    setDocumentInfoCM0025,
    setIsDisableRetrieveButtonCM0025,
} = CM0025Slice.actions;

export default CM0025Slice.reducer;
