import { useDispatch, useSelector } from "react-redux";
import { useState, useRef, useMemo } from "react";
import { PM012UseCase } from "../../usecase/ServiceImpl";
import {
  allChecked,
  allCheckedApprove,
  pm012ChangeOpen,
  setAllChecked,
  setAllCheckedApprove,
} from "../slice/Slice";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import { Form } from "antd";
import ConfirmModal from "../../../../../common/components/modal/ConfirmModal";
import {
  setChangeChosenCompany,
  setViewChooseCompany,
} from "../../../PM024/presenter/slice/Slice";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import { RootState } from "../../../../../store";
import { setIsVisibleOM004 } from "../../../../OM/OM004/presenter/slice/Slice";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { showPM011Modal } from "../../../PM011/presenter/slice/Slice";
import moment from "moment";
import { isHasPrivilege } from "../../../../../common/helpers/privilege";
import helpers from "../../../../../common/helpers/common";

export const TYPE_ASC = "ASC";
export const TYPE_DESC = "DESC";

const PM012Handler = (pm012Service: PM012UseCase) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [isViewNav, setIsViewNav] = useState(true);
  const [isViewPM014, setIsViewPM014] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [refreshContent, setRefreshContent] = useState(false);
  const refDoubleCheck: any = useRef();
  const refDoubleCheckShadow: any = useRef();

  //nav
  const [listConstruction, setListConstruction] = useState<any>([]);
  const [detailProject, setDetailProject] = useState<any>({});
  const [keywordNav, setKeywordNav] = useState("");
  const [chosenNavItem, setChosenNavItem] = useState<{
    constructionId: number;
    subcontractorId: number | string;
    submitProjectId: number;
    parentConstructionId: number;
  }>({
    constructionId: 0,
    subcontractorId: "my_company",
    submitProjectId: 0,
    parentConstructionId: 0,
  });
  const [navItemActiveKey, setNavItemActiveKey] = useState<any[]>([]);
  const [chosenConstructionAddCompany, setChosenConstructionAddCompany] =
    useState(0);

  //table
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [checkedList, setCheckedList] = useState<any>([]);
  const [checkAll, setCheckAll] = useState(false);
  const [dataMemberPM012, setDataMemberPM012] = useState<any[]>([]);
  const [isEditDoubleCheck, setIsEditDoubleCheck] = useState(false);

  //filter
  const [formSearch] = Form.useForm();
  const [keyword, setKeyword] = useState("");
  const [sortType, setSortType] = useState<string>("");
  const [sortBy, setSortBy] = useState<string>("");
  const [filter, setFilter] = useState("");
  const [listRole, setListRole] = useState<any[]>([]);

  //pagination
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(100);

  //subcontract
  const [dataSubcontract, setDataSubcontract] = useState<any>([]);
  const [dataCurrent, setDataCurrent] = useState<any[]>([]);

  // Checkbox all
  const [isCheckBoxAll, setCheckBoxAll] = useState<boolean>(true);

  const activeCheckboxAll = (value: boolean) => {
    setCheckBoxAll(value);
  };

  const [isRenderContainer, setRenderContainer] = useState<string>(
    String(moment().unix())
  );

  //redux
  const chosenCompany = useSelector(
    (state: RootState) => state?.pm024.chosenCompany
  );
  const listCompany = useSelector(
    (state: RootState) => state?.pm024.listCompany
  );

  const getListConstruction = async (params: any) => {
    setLoading(true);
    try {
      const response = await pm012Service.getListConstruction(params);
      setLoading(false);
      setDetailProject(response.data.results);
      const results = response?.data.results?.constructions;
      const findMain = results?.find((element: any) => element.code === "main");
      const findNotMain = results?.filter(
        (element: any) => element.code !== "main"
      );

      const data = [findMain, ...findNotMain];

      setListConstruction(data);
      if (data?.length > 0) {
        setChosenNavItem({
          ...chosenNavItem,
          constructionId: data[0]?.id,
        });
        if (navItemActiveKey?.length === 0) {
          setNavItemActiveKey([`${data[0]?.id}`]);
        }
      }
    } catch (error: any) {
      setLoading(false);
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const getMySubcontractor = async (params: any) => {
    try {
      const response = await pm012Service.getMySubcontractor(params);
      return response.data.results;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return [];
    }
  };

  const getListRole = async (params?: any) => {
    try {
      const response = await pm012Service.getListRole(params);
      const results = response?.data?.results?.map((element: any) => {
        return {
          ...element,
          value: element?.id,
        };
      });
      setListRole([{ name: "全て", id: Math.random(), value: "" }, ...results]);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      setListRole([]);
    }
  };

  const getMyInvitedMember = async (params: any) => {
    setLoading(true);
    try {
      const userId = +JSON.parse(localStorage.getItem("user") || "{}")?.id;
      if (userId) params.ignoreUser = userId;
      const response = await pm012Service.getMyInvitedMember(params);
      setDataMemberPM012(handleDataTableMember(response.data.results));
      setDataCurrent(handleDataTableMember(response.data.results));
      setTotal(response?.data?.pagination?.count);
      setLoading(false);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      setLoading(false);
      setDataMemberPM012([]);
    }
  };
  const getMyUninvitedMember = async (params: any) => {
    setLoading(true);
    try {
      const userId = +JSON.parse(localStorage.getItem("user") || "{}")?.id;
      if (userId) params.ignoreUser = userId;
      const response = await pm012Service.getMyUninvitedMember(params);
      setDataMemberPM012(handleDataTableMember(response.data.results));
      setTotal(response?.data?.pagination?.count);
      setLoading(false);
      const listApprove = response.data?.results?.filter(
        (element: any) => element?.approve
      );
      const listIdApprove = listApprove.map((element: any) => element.id);
      setSelectedRowKeys(listIdApprove);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      setDataMemberPM012([]);
      setLoading(false);
    }
  };

  const handleDataTableMember = (raw: any) => {
    if (!raw) return [];
    const results = raw?.map((element: any) => {
      return {
        ...element,
        key: element?.id,
      };
    });
    return results;
  };

  const handleConfirmChosenCompany = async () => {
    setLoading(true);
    try {
      const companyChosen = listCompany.find(
        (company: any) => company?.id === chosenCompany
      );
      const data = {
        contractorId: companyChosen?.id,
        constructionId: chosenConstructionAddCompany,
        email: companyChosen?.email,
      };
      const response = await pm012Service.addCompanyForConstruction(data);
      SuccessNotification(response.data?.message ?? NOTIFICATION_TITLE.SUCCESS);
      setLoading(false);
      setRefresh(!refresh);
      dispatch(setViewChooseCompany(false));
      dispatch(setChangeChosenCompany(0));
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      setLoading(false);
    }
  };

  const invitedMember = async (data: any) => {
    setLoading(true);
    try {
      const response = await pm012Service.inviteMemberToConstruction(data);
      SuccessNotification(response.data?.message ?? NOTIFICATION_TITLE.SUCCESS);
      setLoading(false);
      setIsViewNav(true);
      setSelectedRowKeys([]);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      setLoading(false);
    }
  };

  const unInvitedMember = async (data: any) => {
    setLoading(true);
    try {
      const response = await pm012Service.unInviteMemberToConstruction(data);
      SuccessNotification(response.data?.message ?? NOTIFICATION_TITLE.SUCCESS);
      setLoading(false);
      setSelectedRowKeys([]);

      setRefreshContent(!refreshContent);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      setLoading(false);
    }
  };

  const updateDoubleCheck = async (data: any) => {
    try {
      const response = await pm012Service.updateDoubleCheck(data);
      SuccessNotification(response.data?.message ?? NOTIFICATION_TITLE.SUCCESS);
      setLoading(false);
      setRefresh(!refresh);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      setLoading(false);
      setDataMemberPM012(dataCurrent);
    }
  };

  const handleClose = () => {
    if (isEditDoubleCheck) {
      handleWarningDoubleCheck();
    } else {
      dispatch(pm012ChangeOpen(false));
      setSelectedRowKeys([]);
    }
  };

  //nav
  const handleChangeCollapseNav = (value: any) => {
    setNavItemActiveKey(value);
  };
  const handleClickNavItem = (
    subcontractorId: number,
    constructionId: number,
    submitProjectId: number,
    parentConstructionId: number
  ) => {
    setSelectedRowKeys([]);
    setCheckedKeys([]);
    setKeyword('')
    setKeySearch('')
    formSearch.setFieldValue("keyword", '');
    formSearch.setFieldValue("keySearch", '');
    if (isEditDoubleCheck) {
      handleWarningDoubleCheck();
    } else {
      setChosenNavItem({
        constructionId,
        subcontractorId,
        submitProjectId,
        parentConstructionId,
      });
    }
  };

  //pagination
  const handleChangePage = (page: number) => {
    setPage(page);
    setSelectedRowKeys([]);
  };
  const handleChangeSize = (sizeValue: number) => {
    setSize(sizeValue);
    setPage(1);
    setSelectedRowKeys([]);
  };

  //table
  const handleChangeSelected = (selectedRowKeys: any[]) => {
    if (isEditDoubleCheck) {
      handleWarningDoubleCheck();
    } else {
      setSelectedRowKeys(selectedRowKeys);
    }
  };

  const handleSortOrder = (column: string): "descend" | "ascend" | null => {
    if (column === sortBy) {
      if (sortType === "ascend") return "ascend";
      else return "descend";
    }
    return null;
  };
  const handleChangeFilterTable = (
    pagination: any,
    filters: any,
    sorter: any
  ) => {
    if (isEditDoubleCheck) {
      handleWarningDoubleCheck();
    } else {
      setPage(1);
      let listKeyCheck = [];
      if (!isViewNav) {
        const listCheck = dataMemberPM012.filter(
          (element: any) => element.approve
        );
        listKeyCheck = listCheck?.map((element: any) => element.key);
      }
      setSelectedRowKeys(listKeyCheck);
      if (sorter) {
        setSortBy(sorter?.field);
        setSortType(sorter.order);
        if (dataMemberPM012.length > 0) {
          if (sorter.order === "ascend") {
            if (sorter.field !== "branch") {
              dataMemberPM012.sort((a: any, b: any) => {
                const A = a[sorter.field]?.toUpperCase();
                const B = b[sorter.field]?.toUpperCase();
                return A > B ? 1 : A < B ? -1 : 0;
              });
            } else {
              dataMemberPM012.sort((a: any, b: any) => {
                const A = a.group?.name?.toUpperCase();
                const B = b.group?.name?.toUpperCase();
                return A > B ? 1 : A < B ? -1 : 0;
              });
            }
          } else {
            if (sorter.field !== "branch") {
              dataMemberPM012.sort((a: any, b: any) => {
                const A = a[sorter.field]?.toUpperCase();
                const B = b[sorter.field]?.toUpperCase();
                return A > B ? -1 : A < B ? 1 : 0;
              });
            } else {
              dataMemberPM012.sort((a: any, b: any) => {
                const A = a.group?.name?.toUpperCase();
                const B = b.group?.name?.toUpperCase();
                return A > B ? -1 : A < B ? 1 : 0;
              });
            }
          }
        }
        setDataMemberPM012([...dataMemberPM012]);
      } else {
        setSortBy("");
        setSortType("");
      }
    }
  };

  const handleSortTable = (sortTypeValue: string, sortByValue: string) => {
    setSortBy(sortByValue);
    setPage(1);
    if (sortTypeValue === "") {
      setSortType(TYPE_ASC);
    } else {
      if (sortTypeValue === TYPE_ASC) {
        setSortType(TYPE_DESC);
      } else {
        setSortType(TYPE_ASC);
      }
    }
  };
  const handleCheckSorter = (sortTypeValue: string, sortByValue: string) => {
    if (sortBy === sortByValue && sortType === sortTypeValue) {
      return "btn-sorter-active";
    }
    return "";
  };

  const handleChangeCheckboxGroup = (list: any) => {
    let lengthChild = 0;
    dataSubcontract.forEach((element: any) => {
      lengthChild += element.children ? element.children?.length : 0;
    });
    setCheckedList(list);
    setCheckAll(list?.length === lengthChild);
  };

  const handleCheckAllChange = (e: any) => {
    const getKeyData: any[] = [];
    dataSubcontract.forEach((element: any) => {
      if (element?.children) {
        element.children?.forEach((ele: any) => getKeyData.push(ele.key));
      }
    });
    setCheckedList(e.target.checked ? getKeyData : []);
    setCheckAll(e.target.checked);
  };

  let myVar: any;
  function onSearchChange() {
    if (isEditDoubleCheck) {
      handleWarningDoubleCheck();
      formSearch.setFieldValue("keyword", keyword);
    } else {
      if (myVar) clearTimeout(myVar);
      myVar = setTimeout(function () {
        const searchText = formSearch.getFieldValue("keyword");
        formSearch.setFieldValue("keyword", searchText?.trim());
        onSearch(searchText?.trim());
      }, 1000);
    }
  }

  const onSearch = (searchText: string) => {
    setKeyword(searchText);
    setPage(1);
    setSelectedRowKeys([]);
  };

  const handleMemberInvitation = () => {
    if (isEditDoubleCheck) {
      handleWarningDoubleCheck();
    } else {
      setIsViewNav(false);
      setSelectedRowKeys([]);
      setFilter("");
      setDataMemberPM012([]);
      setSortBy("");
      setSortType("");
    }
  };

  const handleCancelMemberInvitation = () => {
    setIsViewNav(true);
    setSelectedRowKeys([]);
    setFilter("");
    setDataMemberPM012([]);
  };

  const handleViewModalAddMember = () => {
    dispatch(setIsVisibleOM004(true));
  };

  const handleOpenChooseCompany = (id: number) => {
    dispatch(setViewChooseCompany(true));
    setChosenConstructionAddCompany(id);
  };

  const handleCancelChosenCompany = () => {
    dispatch(setViewChooseCompany(false));
    dispatch(setChangeChosenCompany(0));
  };

  const handleUnInvitedMember = (description: any) => {
    if (isEditDoubleCheck) {
      handleWarningDoubleCheck();
    } else {
      ConfirmModal({
        onOk: () => {
          unInvitedMember({
            constructionId: chosenNavItem?.constructionId,
            listUserId: selectedRowKeys,
          });
        },
        onCancel: () => { },
        className: "confirm__modal confirm__modal-confirm",
        title: "削除確認",
        description: description,
        canceText: LABEL_MESSAGE.CANCEL_MODAL,
        okText: LABEL_MESSAGE.OK_DELETE,
      });
    }
  };

  const handleInvitedMember = () => {
    const results = selectedRowKeys?.filter((selectKey: any) => {
      const find = dataMemberPM012.find(
        (element: any) => element.id === selectKey
      );
      return !find.approve;
    });
    invitedMember({
      constructionId: chosenNavItem?.constructionId,
      listUserId: results,
    });
  };
  const checkedUnInvitedMember = useMemo(() => {
    const results = selectedRowKeys?.filter((selectKey: any) => {
      const find = dataMemberPM012.find(
        (element: any) => element.id === selectKey
      );
      return !find.approve;
    });
    return results;
  }, [selectedRowKeys, dataMemberPM012]);

  const refFilter: any = useRef();

  const handleFilterMyCompany = (filter: string) => {
    if (isEditDoubleCheck) {
      handleWarningDoubleCheck();
    } else {
      setFilter(filter);
      setSelectedRowKeys([]);
    }
    if (refFilter) {
      refFilter.current.style.display = "none";
    }
  };
  const handleMouseOverFilter = () => {
    if (refFilter) {
      refFilter.current.style.display = "block";
    }
  };
  const handleMouseLeaveFilter = () => {
    if (refFilter) {
      refFilter.current.style.display = "none";
    }
  };
  const handleCheckEditDoubleCheck = () => {
    if (!isEditDoubleCheck) setIsEditDoubleCheck(true);
  };

  const handleCheckboxDouble = (
    e: CheckboxChangeEvent,
    key: string | number
  ) => {
    const results = dataMemberPM012.map((element) => {
      const obj = { ...element };
      if (element.key === key) {
        obj.check = e.target.checked;
      }
      return obj;
    });
    setDataMemberPM012(results);
  };
  const handleCancelDoubleCheck = () => {
    setDataMemberPM012(dataCurrent);
    setIsEditDoubleCheck(false);
    handleRemoveWarningDoubleCheck();
  };

  const handleSubmitDoubleCheck = async () => {
    const dataUpdate: any = {
      check: true,
      constructionId: chosenNavItem.constructionId,
      memberId: [],
    };
    dataMemberPM012.forEach((element) => {
      if (element.check) {
        dataUpdate.memberId.push(element.id);
      }
    });
    try {
      const response = await pm012Service.updateDoubleCheck(dataUpdate);
      SuccessNotification(response.data?.message ?? NOTIFICATION_TITLE.SUCCESS);
      setLoading(false);
      // setRefresh(!refresh);
      setDataCurrent(dataMemberPM012);
      setIsEditDoubleCheck(false);
      handleRemoveWarningDoubleCheck();
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      setLoading(false);
      setDataMemberPM012(dataCurrent);
    }
  };
  const handleViewModalSendMember = () => {
    if (isEditDoubleCheck) {
      handleWarningDoubleCheck();
    } else {
      dispatch(
        showPM011Modal({
          projectDetail: detailProject ?? null,
        })
      );
    }
  };

  let checkShake: any;
  const handleWarningDoubleCheck = () => {
    if (refDoubleCheck && refDoubleCheck.current) {
      refDoubleCheck.current.classList.add("animation-shake");
    }
    if (refDoubleCheckShadow && refDoubleCheckShadow.current) {
      refDoubleCheckShadow.current.classList.add("action-double-check-active");
    }

    if (checkShake) clearTimeout(checkShake);
    checkShake = setTimeout(() => {
      if (refDoubleCheck && refDoubleCheck.current) {
        refDoubleCheck.current.classList.remove("animation-shake");
      }
    }, 500);
  };

  const handleRemoveWarningDoubleCheck = () => {
    if (refDoubleCheck && refDoubleCheck.current) {
      refDoubleCheck.current.classList.remove("animation-shake");
    }
    if (refDoubleCheckShadow && refDoubleCheckShadow.current) {
      refDoubleCheckShadow.current.classList.remove(
        "action-double-check-active"
      );
    }
  };

  const funcCheckRoleCreate = (): boolean => {
    const user = localStorage.getItem("user");
    if (!user) return false;
    const userId = +JSON.parse(user)?.id;
    let isSubPM = false;
    let isAdmin = false;

    // Check admin

    if (JSON.parse(user)?.role.code === "admin") isAdmin = true;

    const subPM = detailProject?.deputyProjectManager?.find(
      (item: any) => Number(userId) === Number(item.id)
    );

    if (subPM) isSubPM = true;

    return (
      userId === detailProject?.userId ||
      userId === detailProject?.projectManager?.id ||
      isSubPM ||
      isAdmin
    );
  };

  const funcCheckRole = (permissions: string[]): boolean => {
    const user = helpers.getObjectFromLocalStorage("user");
    let isAdminOrder = true;
    if (user.role.code === "admin" && user.order.code === "plan_free") {
      isAdminOrder = false;
    }

    return isHasPrivilege(detailProject, permissions) && isAdminOrder;
  };

  // Handler PM014

  const [dataParentPM014, setDataParentPM014] = useState<any>({});
  const [loadingPM014, setLoadingPM014] = useState<boolean>(false);
  const [enableEdit, setEnableEdit] = useState<boolean>(false);
  const [dataParentMemberPM014, setDataParentMemberPM014] = useState<any[]>([]);
  const [flatData, setFlatData] = useState<any[]>([]);
  const [keyCollapse, setKeyCollapse] = useState<any[]>([]);
  const [expandAllCollapse, setExpandAllCollapse] = useState<boolean>(false);
  const [checkedKeys, setCheckedKeys] = useState<string[]>([]);
  const [visibleModalInvite, setVisibleModalInvite] = useState<boolean>(false);
  const [formInvite] = Form.useForm();
  const [currentTab, setCurrentTab] = useState("1");

  const __checked = useSelector(allChecked);
  const __checkedApprove = useSelector(allCheckedApprove);

  const [keyCanChoose, setKeyCanChoose] = useState<any[]>([]);
  const [isRefreshPM014, setRefreshPM014] = useState<boolean>(false);
  const [keySearch, setKeySearch] = useState<string>("");
  const [keyAD, setKeyAD] = useState<string[]>([]);
  const [keyAT, setKeyAT] = useState<string[]>([]);
  const [showNoData, setShowNoData] = useState<boolean>(false);
  const [showNoDataMember, setShowNoDataMember] = useState<boolean>(false);

  // DEFINE COLOR

  const TREEVIEW_COLOR: any = {
    1: "#33CC99",
    2: "#FF9861",
    3: "#FF5555",
    4: "#33CC99",
  };

  const TREEVIEW_BACKGROUND: any = {
    1: "rgba(51, 204, 153, 0.12)",
    2: "rgba(255, 152, 97, 0.12)",
    3: "rgba(255, 85, 85, 0.12)",
    4: "rgba(51, 204, 153, 0.12)",
  };

  const funcChangeEnableEdit = () => {
    setEnableEdit(!enableEdit);
  };

  const recursion = async (
    array: any,
    results: any[],
    treeLevel: number,
    constructionId: number
  ) => {
    if (array.length === 0) {
      return;
    } // STOP CONDITION
    for (let i = 0; i < array.length; i++) {
      results.push({
        ...array[i],
        treeLevel: treeLevel,
      });
      const data = await pm012Service.getListSubcontractor({
        constructionId: constructionId,
        contractorConstructionId: array[i].id,
      });
      if (data) await recursion(data, results, treeLevel + 1, constructionId);
    }
  };

  const funcGetSubcontractorInformation = async (
    constructionId: number,
    contractorConstructionId: number,
    isApprove: boolean
  ) => {
    const keyAT: string[] = [];
    const keyAD: string[] = [];

    setDataParentPM014([]);
    setLoadingPM014(true);
    setShowNoData(false);
    setShowNoDataMember(false);
    try {
      const response = await pm012Service.getSubcontractorinformation({
        constructionId: constructionId,
        contractorConstructionId: contractorConstructionId,
      });

      const respParent = await pm012Service.getSubcontractorListMemberParent({
        constructionId: constructionId,
        contractorConstructionId: contractorConstructionId,
        isApprove: isApprove,
      });

      if (respParent?.data?.results.length !== 0) {
        setShowNoDataMember(true);
      }

      respParent?.data?.results.forEach((item: any) => {
        if (item.fullName.toUpperCase().includes(keySearch.toUpperCase()) ||
          item.phoneNumber
            .toUpperCase()
            .includes(keySearch.toUpperCase()) ||
          item.email.toUpperCase().includes(keySearch.toUpperCase())) {
          if (item?.approve) {
            keyAT.push(`${contractorConstructionId}-${item?.id}`);
          } else {
            keyAD.push(`${contractorConstructionId}-${item?.id}`);
          }
        }
      });

      setDataParentPM014(response);
      setFlatData([]);
      setKeyCollapse([]);
      setExpandAllCollapse(false);

      // Recursion TreeView Data

      try {
        const data = await pm012Service.getListSubcontractor({
          constructionId: constructionId,
          contractorConstructionId: contractorConstructionId,
        });

        if (data?.length > 0) {
          const flatArray: any[] = [];
          let treeLevel = 1;
          for (let i = 0; i < data.length; i++) {
            flatArray.push({
              ...data[i],
              treeLevel: treeLevel,
            });
            const contractorData = await pm012Service.getListSubcontractor({
              constructionId: constructionId,
              contractorConstructionId: data[i]?.id,
            });

            await recursion(
              contractorData,
              flatArray,
              treeLevel + 1,
              constructionId
            );
          }

          // SET COLLAPSE KEY
          const collapses: string[] = [];
          flatArray.forEach((ele: any) => {
            collapses.push(`${ele.treeLevel}-${ele.id}`);
          });

          // Call All API

          const results: any[] = await Promise.all(
            flatArray.map(async (ele: any) => {
              const response: any =
                await pm012Service.getSubcontractorListMemberParent({
                  constructionId: constructionId,
                  contractorConstructionId: ele?.id,
                  isApprove: isApprove,
                });

              return {
                data: response?.data?.results,
                contractorConstructionId: ele?.id,
              };
            })
          );

          results.forEach((item: any) => {
            if (item.data.length !== 0) {
              item.data.forEach((ite: any) => {
                if (ite.fullName.toUpperCase().includes(keySearch.toUpperCase()) ||
                  ite.phoneNumber
                    .toUpperCase()
                    .includes(keySearch.toUpperCase()) ||
                  ite.email.toUpperCase().includes(keySearch.toUpperCase())) {
                  if (ite?.approve) {
                    keyAT.push(`${item?.contractorConstructionId}-${ite?.id}`);
                  } else {
                    keyAD.push(`${item?.contractorConstructionId}-${ite?.id}`);
                  }
                }
              });
            }
          });

          setKeyCollapse(collapses);
          setFlatData(flatArray);

          if (keyAT.length === 0 && currentTab === "2") {
            setShowNoDataMember(false);
          }
        }
      } catch (error: any) {
        setLoadingPM014(false);
        ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      } finally {
        setKeyAD(keyAD);
        setKeyAT(keyAT);
        setLoadingPM014(false);
      }

      setLoadingPM014(false);
    } catch (error: any) {
      setLoadingPM014(false);
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const funcGetSubcontractorMemberParent = async (
    constructionId: number,
    contractorConstructionId: number,
    getMember?: Function,
    getMemberApprove?: Function,
    keySearch?: string,
    isApprove?: boolean
  ) => {
    setLoadingPM014(true);
    dispatch(setAllChecked([]));
    dispatch(setAllCheckedApprove([]));
    try {
      const response = await pm012Service.getSubcontractorListMemberParent({
        constructionId: constructionId,
        contractorConstructionId: contractorConstructionId,
        isApprove: !!isApprove,
      });

      const memberApprove: any[] = [];

      // Add Member To List Check All

      response?.data?.results.forEach((element: any) => {
        if (element.approve) {
          memberApprove.push(element);
        }
      });

      if (getMember) {
        let result;
        if (keySearch && keySearch !== "") {
          result = response?.data?.results.filter(
            (ele: any) =>
              ele.fullName.toUpperCase().includes(keySearch.toUpperCase()) ||
              ele.phoneNumber
                .toUpperCase()
                .includes(keySearch.toUpperCase()) ||
              ele.email.toUpperCase().includes(keySearch.toUpperCase()))
        } else {
          result = response?.data?.results
        }
        getMember(result);
      }
      if (getMemberApprove) {
        let result;
        if (keySearch && keySearch !== "") {
          result = memberApprove.filter(
            (ele: any) =>
              ele.fullName.toUpperCase().includes(keySearch.toUpperCase()) ||
              ele.phoneNumber
                .toUpperCase()
                .includes(keySearch.toUpperCase()) ||
              ele.email.toUpperCase().includes(keySearch.toUpperCase())
          )
        } else {
          result = memberApprove
        }
        getMemberApprove(result);
      }

      setLoadingPM014(false);
    } catch (error: any) {
      setLoadingPM014(false);
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const funcUpdateContractorLevel = async (
    constructionId: number,
    contractorConstructionId: number,
    titleLevel: string
  ) => {
    try {
      const response = await pm012Service.updateContractorLevel({
        constructionId: constructionId,
        contractorConstructionId: contractorConstructionId,
        titleLevel: titleLevel,
        subConstructionId: dataParentPM014?.subConstructionId,
      });
      setRefreshPM014(!isRefreshPM014);
      SuccessNotification(response);
    } catch (error: any) {
      ErrorNotification(error ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const funcInviteSubcontractorMember = async (
    constructionId: number,
    data: any[],
    laborCost: number
  ) => {
    try {
      const response = await pm012Service.inviteSubcontractorMember({
        constructionId: constructionId,
        data: data,
        laborCost: laborCost,
      });
      setCurrentTab("2");
      SuccessNotification(response);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const funcUnInviteSubcontractorMember = async (
    constructionId: number,
    data: any[]
  ) => {
    try {
      const response = await pm012Service.uninviteSubcontractorMember({
        constructionId: constructionId,
        data: data,
      });
      SuccessNotification(response);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const funcRemoveSubcontractorMember = async (
    dataSource: any[],
    constructionId: number
  ) => {
    try {
      const response = await pm012Service.removeSubcontractorMember({
        data: dataSource,
        constructionId: constructionId,
      });
      SuccessNotification(response);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const funcUpdateLaborCost = async (
    constructionId: number,
    contractorConstructionId: number,
    laborCost: number,
    userId: number
  ) => {
    try {
      const response = await pm012Service.updateLaborCost({
        constructionId: constructionId,
        contractorConstructionId: contractorConstructionId,
        laborCost: laborCost,
        userId: userId,
      });
      SuccessNotification(response);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const funcExpandCollapseAll = () => {
    setExpandAllCollapse(!expandAllCollapse);
  };

  const funcAddCheckbox = (e: any) => {
    let mapper: any = {};

    checkedKeys.forEach((ele: any) => {
      mapper[`${ele}`] = true;
    });

    if (e.target.checked) {
      var newItem = mapper[`${e.target.value}`] === undefined;
      if (newItem) mapper[`${e.target.value}`] = true;
    } else {
      delete mapper[`${e.target.value}`];
    }
    setCheckedKeys(Object.keys(mapper));
  };

  const funcOpenModalInvite = () => {
    setVisibleModalInvite(!visibleModalInvite);
  };

  const funcHandleMapperInviteData = (data: any[]) => {
    let mapper: any = {};

    data.forEach((item: any) => {
      const element: any[] = item.split("-");

      if (element[0] in mapper) {
        mapper[`${element[0]}`] = mapper[`${element[0]}`].concat(
          Number(element[1])
        );
      } else {
        mapper[`${element[0]}`] = [Number(element[1])];
      }
    });

    const results: any[] = [];

    for (const [key, value] of Object.entries(mapper)) {
      results.push({
        contractorConstructionId: Number(key),
        listUserId: value,
      });
    }

    return results;
  };

  const funcFindKeySearch = (e: string) => {
    if (myVar) clearTimeout(myVar);
    myVar = setTimeout(function () {
      formSearch.setFieldValue("keySearch", e?.trim());
      setKeySearch(e.trim())
    }, 1000);

  };

  const funcCountMemberInside = (
    key: string,
    arrayString: string[]
  ): number => {
    let count: number = 0;

    if (arrayString.length !== 0) {
      arrayString.forEach((item: string) => {
        let element: string[] = item.split("-");
        if (element[0] === key) {
          count++;
        }
      });
    }
    return count;
  };

  const funcChangeEdit = (value: boolean) => {
    setEnableEdit(value);
  };
  const funcFilterKeySuccess = (rawKey: any[], raw: any[]) => {
    if (!raw) return [];
    const listData = raw.filter(
      (row) =>
        !row.approve && rawKey?.includes(row.id)
    );
    const listKey = listData?.map((element) => {
      return element.id;
    });

    return listKey;
  };

  const changeCheckedKeys = (isChecked: boolean, listCheck: any[]) => {
    let newCheckedKeys = []
    if (isChecked) {
      newCheckedKeys = [...new Set([...checkedKeys, ...listCheck])];
    } else {
      newCheckedKeys = checkedKeys.filter(item => !listCheck.includes(item));
    }

    setCheckAll(isChecked)
    setCheckedKeys(newCheckedKeys)
  }
  return {
    loading,
    refresh,
    refreshContent,
    isViewNav,
    isViewPM014,
    page,
    size,
    total,
    selectedRowKeys,
    sortType,
    sortBy,
    keyword,
    formSearch,
    currentTab,
    checkedList,
    checkAll,
    dataSubcontract,
    listConstruction,
    keywordNav,
    dataMemberPM012,
    chosenNavItem,
    navItemActiveKey,
    refFilter,
    filter,
    listRole,
    isEditDoubleCheck,
    checkedUnInvitedMember,
    refDoubleCheck,
    refDoubleCheckShadow,
    detailProject,

    setIsViewNav,
    setIsViewPM014,
    handleClose,
    handleChangePage,
    handleChangeSize,
    handleChangeSelected,
    handleChangeFilterTable,
    setCurrentTab,
    handleChangeCheckboxGroup,
    handleCheckAllChange,
    handleSortTable,
    handleCheckSorter,
    handleViewModalAddMember,
    handleMemberInvitation,
    handleCancelMemberInvitation,
    handleChangeCollapseNav,
    handleClickNavItem,
    onSearchChange,
    handleOpenChooseCompany,
    handleConfirmChosenCompany,
    handleCancelChosenCompany,
    handleFilterMyCompany,
    handleMouseOverFilter,
    handleMouseLeaveFilter,
    handleCheckEditDoubleCheck,
    handleCheckboxDouble,
    handleCancelDoubleCheck,
    handleSubmitDoubleCheck,
    handleViewModalSendMember,
    handleSortOrder,
    funcCheckRoleCreate,

    //get
    getListConstruction,
    getMySubcontractor,
    getMyInvitedMember,
    getMyUninvitedMember,
    handleUnInvitedMember,
    handleInvitedMember,
    getListRole,

    // SERVICE FOR PM014

    dataParentPM014,
    loadingPM014,
    enableEdit,
    dataParentMemberPM014,
    flatData,
    TREEVIEW_COLOR,
    TREEVIEW_BACKGROUND,
    keyCollapse,
    expandAllCollapse,
    checkedKeys,
    __checked,
    __checkedApprove,
    visibleModalInvite,
    formInvite,
    keyCanChoose,
    isRefreshPM014,
    keySearch,
    keyAT,
    keyAD,
    showNoData,
    showNoDataMember,
    isCheckBoxAll,
    isRenderContainer,

    funcGetSubcontractorInformation,
    funcChangeEnableEdit,
    funcGetSubcontractorMemberParent,
    setDataParentMemberPM014,
    funcExpandCollapseAll,
    funcUpdateContractorLevel,
    funcAddCheckbox,
    setCheckedKeys,
    funcOpenModalInvite,
    funcInviteSubcontractorMember,
    funcHandleMapperInviteData,
    funcUpdateLaborCost,
    funcUnInviteSubcontractorMember,
    setKeyCanChoose,
    funcRemoveSubcontractorMember,
    setRefreshPM014,
    funcFindKeySearch,
    funcCountMemberInside,
    activeCheckboxAll,
    funcChangeEdit,
    funcCheckRole,
    funcFilterKeySuccess,
    changeCheckedKeys
  };
};

export default PM012Handler;
