import axios from "axios";
import COMMON from "../../../../../common/constants/COMMON";
import axiosConfig from "../../../../../config/axiosConfig";
import { ImportMemberEntity } from "../../entity/Entity";

const OM005Api = () => {
  const importMember = async (data: ImportMemberEntity): Promise<any> => {
    try {
      const url = "/group/import-member";
      const response = await axiosConfig.post(url, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getGroupList = async (params: any): Promise<any> => {
    try {
      const url = "/organization/list-group";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getRoleList = async (params: any): Promise<any> => {
    try {
      const url = "/role/list/except-admin";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const exportTemplateCSV = async (): Promise<any> => {
    const url = process.env.REACT_APP_TEMPLATE_CSV_URL || "";
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "text/csv",
        },
        responseType: "blob",
      });

      const href = URL.createObjectURL(response.data);
      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", "メンバー追加.csv"); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const exportTemplateEXCEL = async (): Promise<any> => {
    const url = process.env.REACT_APP_TEMPLATE_EXCEL_URL || "";
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "text/csv",
        },
        responseType: "blob",
      });

      const href = URL.createObjectURL(response.data);
      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", "メンバー追加.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  return {
    importMember,
    getGroupList,
    getRoleList,
    exportTemplateEXCEL,
    exportTemplateCSV,
  };
};

export default OM005Api;
