import React, { memo, useCallback } from 'react';
import ExpandedIconSvg from '../../../../../assets/icons/expanded-icon.svg';
import ClosedIconSvg from '../../../../../assets/icons/closed-icon.svg';
import { ConfigProvider } from 'antd';
import type { TableColumnsType } from 'antd';
import { Table } from 'antd';
import { ConstractorMember, DateColumn } from '../../entity/Entity';
import AttendanceComponent from '../component/attendance';
import MemberRowComponent from './MemberRow';
import { getHeaderTitle } from '../../../AM008/presenter/ui/Table';
import helpers from '../../../../../common/helpers/common';
import { useSearchParams } from 'react-router-dom';
import { getSearchMembers } from '../../helpers';
interface props {
  columnsTable: DateColumn[];
  contractorMembers: ConstractorMember[];
  contractorConstructionId: number;
  constructionId: number;
  expandKeyMember: any[];
  setExpandKeyMember: (value: any) => void;
  isCollapsedMember: (record: any) => boolean;
  onChangeCollapseRowMember: (record: any, collapsed: boolean) => void;
}

const MemberTableComponent = ({
  columnsTable,
  contractorMembers,
  contractorConstructionId,
  constructionId,
  expandKeyMember,
  isCollapsedMember,
  onChangeCollapseRowMember,
}: props) => {
  const [searchParams] = useSearchParams();
  const keyword = searchParams.get('keyword') || '';
  const dataSource = getSearchMembers(contractorMembers, keyword, contractorConstructionId)
  const getColumns = () => {
    const columns: TableColumnsType<any> = [
      {
        title: <div style={{ paddingLeft: 16 }}>日付</div>,
        key: 'date',
        width: '23%',
        onCell: (_, index) => ({
          colSpan: columnsTable.length + 3,
        }),
        render: (_, record, index) => <MemberRowComponent record={record} index={index + 1} />,
      },
    ];
    columns.push({
      title: '',
      key: 'prev',
      width: 29,
      onCell: (_, index) => ({
        colSpan: 0,
      }),
    });
    for (let i = 0; i < columnsTable.length; i++) {
      columns.push({
        title: getHeaderTitle(columnsTable[i]),
        key: i,
        width: `${77 / columnsTable.length}%`,
        onCell: (_, index) => ({
          colSpan: 0,
        }),
      });
    }
    columns.push({
      title: '',
      key: 'next',
      width: 70,
      onCell: (_, index) => ({
        colSpan: 0,
      }),
    });
    columns.push({
      title: '',
      key: 'collapsed',
      width: 40,
      render: (record) => {
        return isCollapsedMember(record) ? (
          <div
            style={{ cursor: 'pointer' }}
            onClick={(e: any) => onChangeCollapseRowMember(record, false)}
          >
            <img alt="" src={ExpandedIconSvg} />
          </div>
        ) : (
          <div
            style={{ cursor: 'pointer' }}
            onClick={(e: any) => onChangeCollapseRowMember(record, true)}
          >
            <img alt="" src={ClosedIconSvg} />
          </div>
        );
      },
    });
    return columns;
  };



  return (
    <ConfigProvider direction="ltr">
      <Table
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) =>
              isCollapsedMember(record)
                ? onChangeCollapseRowMember(record, false)
                : onChangeCollapseRowMember(record, true), // click row
          };
        }}
        rowClassName={() => {
          return 'row-company button-style';
        }}
        id="main-table"
        className={`am-main-table-constractor-member  attendance-table 
        ${dataSource.length === 0 ? 'table-is-empty' : ''}`}
        columns={getColumns()}
        dataSource={dataSource}
        pagination={false}
        expandable={{
          expandedRowClassName: () => {
            return 'no-table-cell-padding';
          },
          showExpandColumn: false,
          expandedRowKeys: expandKeyMember,
          expandedRowRender: (record: any) => (
            <AttendanceComponent
              attendance={record.attendance}
              contractorConstructionId={contractorConstructionId}
              userId={record.id}
              constructionId={constructionId}
              columnsTable={columnsTable}
            />
          ),
        }}
      />
    </ConfigProvider>
  );
};

export default MemberTableComponent;
