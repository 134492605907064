import { SM04SliceType } from './../type/Presenter';
import { createSlice } from '@reduxjs/toolkit';

const initialState: SM04SliceType = {
    loading: false,
    isVisible: false,
    refresh: false,
    scheduleId: null,
};

export const sm04Slice = createSlice({
    name: 'sm04',
    initialState,
    reducers: {
        resetSM04: () => initialState,
        setLoadingSM04: (state, action) => {
            state.loading = action.payload;
        },
        setIsVisibleSM04: (state, action) => {
            state.isVisible = action.payload;
        },
        setRefreshSM04: (state) => {
            state.refresh = !state.refresh;
        },
        setScheduleId: (state, action) => {
            state.scheduleId = action.payload;
        },
    },
});

export const { setLoadingSM04, setIsVisibleSM04, resetSM04, setRefreshSM04, setScheduleId } =
    sm04Slice.actions;

export default sm04Slice.reducer;
