import {
  MoneyDirectCostMaterialListCompanyResponse,
  MoneyDirectCostMaterialListResult,
  MoneySubmitDirectCostMaterialPayload,
  ThresholdPutType,
} from "../entity/Entity";
import { MaterialCostAPIImpl } from "./ServiceImpl";
import { BaseResponseType } from "../../CM004/entity/Entity";

const MaterialCostService = (api: MaterialCostAPIImpl) => {
  const getMoneyDirectCostMaterialListCompany = async (params: {
    constructionId: number;
    keyword?: string;
    page?: number;
    size?: number;
    sortBy?: string;
    sortType?: string;
  }): Promise<MoneyDirectCostMaterialListCompanyResponse> => {
    try {
      const response = await api.getMoneyDirectCostMaterialListCompany(params);
      const { results, pagination } = response.data;
      const responseData: MoneyDirectCostMaterialListCompanyResponse = {
        pagination,
        results,
      };
      return responseData;
    } catch (error: any) {
      throw error;
    }
  };

  const putMoneyDirectCostMaterialEnable = async (
    data: ThresholdPutType
  ): Promise<BaseResponseType> => {
    try {
      const response = await api.putMoneyDirectCostMaterialEnable(data);
      return response?.data;
    } catch (error: any) {
      throw error;
    }
  };

  const putMoneySubmitDirectCostMaterial = async (
    payload: MoneySubmitDirectCostMaterialPayload
  ): Promise<BaseResponseType> => {
    try {
      const response = await api.putMoneySubmitDirectCostMaterial(payload);
      return response?.data;
    } catch (error: any) {
      throw error;
    }
  };

  const getMoneyDirectCostMaterialList = async (payload: {
    documentDirectMaterialCostId: number;
    page?: number;
    size?: number;
    sortBy?: string;
    sortType?: string;
  }): Promise<MoneyDirectCostMaterialListResult[]> => {
    try {
      const response = await api.getMoneyDirectCostMaterialList(payload);
      const { results } = response.data;
      return results;
    } catch (error: any) {
      throw error;
    }
  };

  const doExportCM007 = async (params: any): Promise<any> => {
    return await api.doExportCM007(params);
  }

  return {
    getMoneyDirectCostMaterialListCompany,
    putMoneyDirectCostMaterialEnable,
    getMoneyDirectCostMaterialList,
    putMoneySubmitDirectCostMaterial,
    doExportCM007,
  };
};

export default MaterialCostService;
