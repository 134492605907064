import {
  Button,
  Form,
  Input,
  DatePicker,
  Select,
  InputNumber,
  Card,
  List,
} from "antd";
import { useEffect, useMemo } from "react";
import EditWhiteSvg from "../../../../../assets/icons/edit-white.svg";
import { CI001PaymentTermHandler } from "../handler/HandlerImpl";
import MessageRed from "../../../../../assets/icons/fluent_comment-error-24-regular.svg";
import MoneyIcon from "../../../../../assets/icons/ant-design_money-collect-outlined.svg";
import EmptyData from "../../../../../assets/icons/empty-data.svg";
import REGEX from "../../../../../common/constants/REGEX";
import {
  onBlurTrimFormItem,
  typeInteger,
} from "../../../../../common/helpers/typeInput";
import { useParams } from "react-router-dom";
import COMMON, {
  MAX_LENGTH,
  USER_FLOW,
} from "../../../../../common/constants/COMMON";
import { TYPE_USER } from "../handler/Handler";
import MESSAGE from "../../../../../common/constants/MESSAGE";
import helpers from "../../../../../common/helpers/common";
import { isHasPrivilege } from "../../../../../common/helpers/privilege";
import { PRIVILEGE_PM } from "../../../../../common/constants/PRIVILEGE";
import { RootState } from "../../../../../store";
import { useSelector } from "react-redux";
import { SYSTEM_ROLES } from "../../../../../common/constants/AUTHORIZATION";
import Dragger from "antd/lib/upload/Dragger";
import IconFile from "../../../../../assets/icons/ant-design_file-pdf-filled.svg";
import CloudUpIcon from "../../../../../assets/icons/cloud-up-icon.svg";
import PdfIcon from "../../../../../assets/icons/pdf-icon.svg";
import TooltipText from "../../../../../common/components/tooltip-text/TooltipText";
import IconDelete from "../../../../../assets/icons/icon-trash-red.svg";

const { Item } = Form;

const REGEX_NAME = {
  regex: REGEX.NAME,
  message: MESSAGE.MESSAGE_INVALID_INPUT,
};

const MAX_LENGTH_NAME = {
  regex: 20,
  message: "",
};

const MAX_LENGTH_30 = {
  regex: 30,
  message: "",
};

const MAX_LENGTH_DETAIL = {
  regex: 300,
  message: "",
};

const SCREEN_ID = "2";

const dummyRequest = (value: any) => {
  setTimeout(() => {
    value.onSuccess("ok");
  }, 0);
};

interface props {
  handler: CI001PaymentTermHandler;
}
function CI001PaymentTerm({ handler }: props) {
  const { projectManagerId } = useParams();

  const projectDetail = useSelector(
    (state: RootState) => state.pm005.projectDetail
  );

  const isRoleAdmin = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdmin
  );

  const isRoleAdminSystem = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdminSystem
  );

  const nameSubCategories = useMemo(() => {
    if (
      !handler.paymentTerm?.subCategories ||
      handler.paymentTerm?.subCategories?.length === 0
    )
      return "";

    const listName = handler.paymentTerm?.subCategories?.map(
      (category: { description: string }) => category?.description
    );
    return `(${listName.join("、")})`;
  }, [handler.paymentTerm?.subCategories]);

  const checkBtn = useMemo(() => {
    return helpers.checkButtonWithWorkflowRolePM(
      handler.currentUserApproval,
      handler.listApprovalProcess,
      +(projectManagerId ?? 0)
    );
  }, [
    handler.currentUserApproval,
    handler.listApprovalProcess,
    projectManagerId,
  ]);

  return (
    <>
      <Form
        name="ci001PaymentTerm"
        form={handler.formPayment}
        onFinish={handler.onSubmitPaymentTerm}
        onValuesChange={handler.onChangeForm}
        onKeyDown={helpers.preventEnterForm}
      >
        <div className="ci001-basic ci001-payment">
          {Object.keys(handler.paymentTerm)?.length > 0 ? (
            <>
              {(checkBtn === USER_FLOW.MANAGER_SUBMIT ||
                isHasPrivilege(handler.detailProject, [PRIVILEGE_PM]) ||
                isRoleAdmin ||
                isRoleAdminSystem) &&
              !handler.isEdit &&
              handler.checkApproved(handler.listApprovalProcess) ? (
                <div className="ci001-basic-header">
                  <Button
                    size="large"
                    className="button-purple"
                    onClick={() => handler.onClickCheckEdit(!handler.isEdit)}
                    icon={
                      <img
                        style={{ paddingRight: 12, paddingLeft: 2 }}
                        alt=""
                        src={EditWhiteSvg}
                      />
                    }
                  >
                    編集
                  </Button>
                </div>
              ) : null}

              <div className="ci001-ifo">
                <div className="ci001-ifo-left">
                  <div
                    className={`ci001-item ${
                      handler.isEdit && "ci001-item-edit"
                    }`}
                  >
                    <span className="ci001-item-label">会社名</span>
                    <span className="ci001-item-text">
                      {handler.isEdit ? (
                        <Item
                          name="companyName"
                          rules={[
                            () => ({
                              validator(_, value) {
                                if (value && value !== "") {
                                  // CHECK INPUT VIETNAMESE
                                  if (!REGEX.NOT_VIETNAM.test(value)) {
                                    return Promise.reject(
                                      new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                                    );
                                  }
                                  if (value?.length > MAX_LENGTH.ADDRESS) {
                                    return Promise.reject(new Error(""));
                                  }
                                  return Promise.resolve();
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input
                            autoComplete="off"
                            className="ci001-input"
                            maxLength={MAX_LENGTH.ADDRESS}
                            onBlur={() =>
                              onBlurTrimFormItem(
                                handler.formPayment,
                                "companyName"
                              )
                            }
                          />
                        </Item>
                      ) : (
                        handler.paymentTerm?.companyName
                      )}
                    </span>
                  </div>
                  <div
                    className={`ci001-item ${
                      handler.isEdit && "ci001-item-edit"
                    }`}
                  >
                    <span className="ci001-item-label">フリガナ</span>
                    <span className="ci001-item-text">
                      {handler.isEdit ? (
                        <Item
                          name="furiganaName"
                          rules={[
                            () => ({
                              validator(_, value) {
                                if (value && value !== "") {
                                  value = value.trim();
                                  value = value.replaceAll(" ", "");
                                  value = value.replaceAll("　", "");

                                  if (!REGEX.ONLY_KATAKANA.test(value))
                                    return Promise.reject(
                                      MESSAGE.MESSAGE_PM027_MSG_012
                                    );
                                  return Promise.resolve();
                                }
                                return Promise.reject(MESSAGE.MESSAGE_REQUIRED);
                              },
                            }),
                          ]}
                        >
                          <Input
                            autoComplete="off"
                            className="ci001-input"
                            maxLength={MAX_LENGTH.ADDRESS}
                            onBlur={() =>
                              onBlurTrimFormItem(
                                handler.formPayment,
                                "furiganaName"
                              )
                            }
                          />
                        </Item>
                      ) : (
                        handler.paymentTerm?.furiganaName
                      )}
                    </span>
                  </div>
                  <div
                    className={`ci001-item ${
                      handler.isEdit && "ci001-item-edit"
                    }`}
                  >
                    <span className="ci001-item-label">確認先部署</span>
                    <span className="ci001-item-text">
                      {handler.isEdit ? (
                        <Item
                          name="departmentLocation"
                          rules={[
                            () => ({
                              validator(_, value) {
                                if (value && value !== "") {
                                  // CHECK INPUT VIETNAMESE
                                  if (!REGEX.NOT_VIETNAM.test(value)) {
                                    return Promise.reject(
                                      new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                                    );
                                  }
                                  if (value?.length > MAX_LENGTH_NAME.regex) {
                                    return Promise.reject(
                                      new Error(MAX_LENGTH_NAME.message)
                                    );
                                  }
                                  return Promise.resolve();
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input
                            autoComplete="off"
                            className="ci001-input"
                            maxLength={MAX_LENGTH_NAME.regex}
                            onBlur={() =>
                              onBlurTrimFormItem(
                                handler.formPayment,
                                "departmentLocation"
                              )
                            }
                          />
                        </Item>
                      ) : (
                        handler.paymentTerm?.departmentLocation
                      )}
                    </span>
                  </div>
                  <div
                    className={`ci001-item ${
                      handler.isEdit && "ci001-item-edit"
                    }`}
                  >
                    <span className="ci001-item-label">確認先担当者名</span>
                    <span className="ci001-item-text">
                      {handler.isEdit ? (
                        <Item
                          name="picName"
                          rules={[
                            () => ({
                              validator(_, value) {
                                if (value && value !== "") {
                                  // CHECK INPUT VIETNAMESE
                                  if (!REGEX.NOT_VIETNAM.test(value)) {
                                    return Promise.reject(
                                      new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                                    );
                                  }
                                  if (value?.length > MAX_LENGTH_NAME.regex) {
                                    return Promise.reject(
                                      new Error(MAX_LENGTH_NAME.message)
                                    );
                                  }
                                  return Promise.resolve();
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input
                            autoComplete="off"
                            className="ci001-input"
                            maxLength={MAX_LENGTH_NAME.regex}
                            onBlur={() =>
                              onBlurTrimFormItem(handler.formPayment, "picName")
                            }
                          />
                        </Item>
                      ) : (
                        handler.paymentTerm?.picName
                      )}
                    </span>
                  </div>
                </div>
                <div className="ci001-ifo-right">
                  <div
                    className={`ci001-item ${
                      handler.isEdit && "ci001-item-edit"
                    }`}
                  >
                    <span className="ci001-item-label">TEL</span>
                    <span className="ci001-item-text">
                      {handler.isEdit ? (
                        <Item
                          name="phoneNumber"
                          rules={[
                            {
                              pattern: REGEX.REGEX_PHONE_FAX,
                              message: MESSAGE.MESSAGE_INVALID_INPUT,
                            },
                          ]}
                        >
                          <Input
                            autoComplete="off"
                            className="ci001-input"
                            placeholder="例：09011112222　ハイフン(ー)なし　半角" // CR #344212
                            maxLength={COMMON.MAX_LENGTH_PHONE_NUMBER}
                            onKeyPress={typeInteger}
                            onPaste={typeInteger}
                            onChange={(e) =>
                              handler.handleFormatPhone(e, "phoneNumber")
                            }
                            onBlur={() =>
                              onBlurTrimFormItem(
                                handler.formPayment,
                                "phoneNumber"
                              )
                            }
                          />
                        </Item>
                      ) : handler?.paymentTerm?.phoneNumber ? (
                        helpers.toPhoneNumberFormat(
                          handler.paymentTerm.phoneNumber
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                  <div
                    className={`ci001-item ${
                      handler.isEdit && "ci001-item-edit"
                    }`}
                  >
                    <span className="ci001-item-label">FAX</span>
                    <span className="ci001-item-text">
                      {handler.isEdit ? (
                        <Item
                          name="fax"
                          rules={[
                            () => ({
                              validator(_, value) {
                                if (value && value !== "") {
                                  value = value.trim().replaceAll(" ", "");
                                  value = value.replaceAll("-", "");
                                  if (!REGEX.PHONENUMBER_2.test(value))
                                    return Promise.reject(
                                      MESSAGE.MESSAGE_WRONG_FAX_FORMAT
                                    );
                                  if (
                                    value?.length >
                                    COMMON.MAX_LENGTH_PHONE_NUMBER - 2
                                  ) {
                                    return Promise.reject(
                                      MESSAGE.MESSAGE_VALIDATE_FAX
                                    );
                                  }
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input
                            autoComplete="off"
                            className="ci001-input"
                            maxLength={100000}
                            onKeyPress={typeInteger}
                            placeholder="例：09011112222　ハイフン(ー)なし　半角" // CR #344212
                            onChange={(e) =>
                              handler.handleFormatPhone(e, "fax")
                            }
                            onBlur={() =>
                              onBlurTrimFormItem(handler.formPayment, "fax")
                            }
                          />
                        </Item>
                      ) : handler?.paymentTerm?.fax ? (
                        helpers.toPhoneNumberFormat(handler.paymentTerm.fax)
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                  <div
                    className={`ci001-item ${
                      handler.isEdit && "ci001-item-edit"
                    }`}
                  >
                    <span className="ci001-item-label">住所</span>
                    <span className="ci001-item-text">
                      {handler.isEdit ? (
                        <Item
                          name="location"
                          rules={[
                            () => ({
                              validator(_, value) {
                                if (value && value !== "") {
                                  // CHECK INPUT VIETNAMESE
                                  if (!REGEX.NOT_VIETNAM.test(value)) {
                                    return Promise.reject(
                                      new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                                    );
                                  }
                                  if (value?.length > MAX_LENGTH.ADDRESS) {
                                    return Promise.reject(new Error(""));
                                  }
                                  return Promise.resolve();
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input
                            autoComplete="off"
                            className="ci001-input"
                            maxLength={MAX_LENGTH.ADDRESS}
                            onBlur={() =>
                              onBlurTrimFormItem(
                                handler.formPayment,
                                "location"
                              )
                            }
                          />
                        </Item>
                      ) : (
                        handler.paymentTerm?.location
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="ci001-contact">
                <div className="ci001-contact-header">
                  <span>締切日・支払日</span>
                  <div className="ci001-contact-header-divider"></div>
                </div>
                <div
                  className={`ci001-item ${
                    handler.isEdit && "ci001-item-edit"
                  }`}
                >
                  <div className="ci001-item-left">
                    <span className="ci001-item-label ci001-black">
                      {!handler.isEdit ? handler.paymentTerm?.expireDate : ""}
                    </span>
                    <span className="ci001-item-text ci001-gray ci001-item-text-custom ">
                      {handler.isEdit ? (
                        <>
                          <Item
                            name="expireDate"
                            rules={[
                              () => ({
                                validator(_, value) {
                                  if (value && value !== "") {
                                    // CHECK INPUT VIETNAMESE
                                    if (!REGEX.NOT_VIETNAM.test(value)) {
                                      return Promise.reject(
                                        new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                                      );
                                    }
                                    if (value?.length > MAX_LENGTH_30.regex) {
                                      return Promise.reject(
                                        new Error(MAX_LENGTH_30.message)
                                      );
                                    }
                                    return Promise.resolve();
                                  }
                                  return Promise.resolve();
                                },
                              }),
                            ]}
                          >
                            <Input
                              autoComplete="off"
                              className="ci001-input"
                              maxLength={MAX_LENGTH_30.regex}
                              onBlur={() =>
                                onBlurTrimFormItem(
                                  handler.formPayment,
                                  "expireDate"
                                )
                              }
                            />
                          </Item>
                          <span className="ci001-text-custom ci001-black">
                            日締切
                          </span>
                        </>
                      ) : (
                        "日締切"
                      )}
                    </span>
                  </div>
                  <div className="ci001-item-right">
                    <span className="ci001-item-label ci001-black">
                      {!handler.isEdit ? handler.paymentTerm?.month : ""}
                    </span>
                    <span className="ci001-item-text ci001-gray ci001-item-text-custom">
                      {handler.isEdit ? (
                        <>
                          <Item
                            name="month"
                            rules={[
                              () => ({
                                validator(_, value) {
                                  if (value && value !== "") {
                                    // CHECK INPUT VIETNAMESE
                                    if (!REGEX.NOT_VIETNAM.test(value)) {
                                      return Promise.reject(
                                        new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                                      );
                                    }
                                    if (value?.length > MAX_LENGTH_30.regex) {
                                      return Promise.reject(
                                        new Error(MAX_LENGTH_30.message)
                                      );
                                    }
                                    return Promise.resolve();
                                  }
                                  return Promise.resolve();
                                },
                              }),
                            ]}
                          >
                            <Input
                              autoComplete="off"
                              className="ci001-input"
                              maxLength={MAX_LENGTH_30.regex}
                              onBlur={() =>
                                onBlurTrimFormItem(handler.formPayment, "month")
                              }
                            />
                          </Item>
                          <span className="ci001-text-custom ci001-black">
                            ヶ月後
                          </span>
                        </>
                      ) : (
                        "ヶ月後"
                      )}
                    </span>
                  </div>
                </div>
                <div
                  className={`ci001-item ${
                    handler.isEdit && "ci001-item-edit"
                  }`}
                >
                  <div className="ci001-item-left">
                    <span className="ci001-item-label ci001-black">
                      {!handler.isEdit ? handler.paymentTerm?.purchaseDate : ""}
                    </span>
                    <span className="ci001-item-text ci001-gray ci001-item-text-custom">
                      {handler.isEdit ? (
                        <>
                          <Item
                            name="purchaseDate"
                            rules={[
                              () => ({
                                validator(_, value) {
                                  if (value && value !== "") {
                                    // CHECK INPUT VIETNAMESE
                                    if (!REGEX.NOT_VIETNAM.test(value)) {
                                      return Promise.reject(
                                        new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                                      );
                                    }
                                    if (value?.length > MAX_LENGTH_30.regex) {
                                      return Promise.reject(
                                        new Error(MAX_LENGTH_30.message)
                                      );
                                    }
                                    return Promise.resolve();
                                  }
                                  return Promise.resolve();
                                },
                              }),
                            ]}
                          >
                            <Input
                              autoComplete="off"
                              className="ci001-input"
                              maxLength={MAX_LENGTH_30.regex}
                              onBlur={() =>
                                onBlurTrimFormItem(
                                  handler.formPayment,
                                  "purchaseDate"
                                )
                              }
                            />
                          </Item>{" "}
                          <span className="ci001-text-custom ci001-black">
                            日支払
                          </span>
                        </>
                      ) : (
                        "日支払"
                      )}
                    </span>
                  </div>
                </div>
                <div className="ci001-contact-header">
                  <span>金種</span>
                  <div className="ci001-contact-header-divider"></div>
                </div>
                <div
                  className={`ci001-item ${
                    handler.isEdit && "ci001-item-edit"
                  }`}
                >
                  <div className="ci001-item-left">
                    <span className="ci001-item-label">現金（％）</span>
                    <span className="ci001-item-text">
                      {handler.isEdit ? (
                        <Item
                          name="cashPercent"
                          // rules={[
                          //   {
                          //     pattern: /^[\d]{0,30}$/,
                          //     message: "",
                          //   },
                          // ]}
                        >
                          <InputNumber
                            controls={false}
                            autoComplete="off"
                            size="large"
                            className="ci001-input"
                            max={100}
                            min={0}
                            formatter={helpers.formatInputNumber}
                            parser={helpers.parserInputNumber}
                            onKeyPress={typeInteger}
                            addonAfter={
                              <span className="cr003-icon-input">%</span>
                            }
                          />
                        </Item>
                      ) : typeof handler.paymentTerm?.cashPercent ===
                        "number" ? (
                        `${Number(
                          handler.paymentTerm?.cashPercent
                        )?.toLocaleString("en-US", {
                          maximumFractionDigits: 2,
                        })}%`
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                  <div className="ci001-item-right">
                    <span className="ci001-item-label">手形（％）</span>
                    <span className="ci001-item-text">
                      {handler.isEdit ? (
                        <Item
                          name="billPercent"
                          // rules={[
                          //   {
                          //     pattern:
                          //       /^[\d]{0,999999999999999999999999999999}$/,
                          //     message: "",
                          //   },
                          // ]}
                        >
                          <InputNumber
                            controls={false}
                            autoComplete="off"
                            size="large"
                            style={{ width: "57%" }}
                            // className="ci001-input"
                            max={100}
                            min={0}
                            formatter={helpers.formatInputNumber}
                            parser={helpers.parserInputNumber}
                            onKeyPress={typeInteger}
                            addonAfter={
                              <span className="cr003-icon-input">%</span>
                            }
                          />
                        </Item>
                      ) : typeof handler.paymentTerm?.billPercent ===
                        "number" ? (
                        `${Number(
                          handler.paymentTerm?.billPercent
                        )?.toLocaleString("en-US", {
                          maximumFractionDigits: 2,
                        })}%`
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </div>
                <div
                  className={`ci001-item ${
                    handler.isEdit && "ci001-item-edit"
                  }`}
                >
                  <div className="ci001-item-left">
                    <span className="ci001-item-label">支払サイト</span>
                    <span className="ci001-item-text">
                      {handler.isEdit ? (
                        <Item
                          name="site"
                          rules={[
                            () => ({
                              validator(_, value) {
                                if (value && value !== "") {
                                  if (
                                    REGEX.REGEX_DETECTING_FULL_WIDTH.test(value)
                                  ) {
                                    return Promise.reject(
                                      new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                                    );
                                  }
                                  // CHECK INPUT VIETNAMESE
                                  if (!REGEX.NOT_VIETNAM.test(value)) {
                                    return Promise.reject(
                                      new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                                    );
                                  }
                                  if (value?.length > MAX_LENGTH.ADDRESS) {
                                    return Promise.reject(new Error(""));
                                  }
                                  return Promise.resolve();
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input
                            autoComplete="off"
                            className="ci001-input"
                            suffix={"日"}
                            maxLength={30}
                            onBlur={() =>
                              onBlurTrimFormItem(handler.formPayment, "site")
                            }
                          />
                        </Item>
                      ) : (
                        handler.paymentTerm?.site + "日"
                      )}
                    </span>
                  </div>
                  <div className="ci001-item-right">
                    <span className="ci001-item-label">備考欄</span>
                    <span className="ci001-item-text">
                      {handler.isEdit ? (
                        <Item
                          name="note"
                          rules={[
                            () => ({
                              validator(_, value) {
                                if (value && value !== "") {
                                  // CHECK INPUT VIETNAMESE
                                  if (!REGEX.NOT_VIETNAM.test(value)) {
                                    return Promise.reject(
                                      new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                                    );
                                  }
                                  if (value?.length > MAX_LENGTH_DETAIL.regex) {
                                    return Promise.reject(
                                      new Error(MAX_LENGTH_DETAIL.message)
                                    );
                                  }
                                  return Promise.resolve();
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input
                            autoComplete="off"
                            className="ci001-input"
                            maxLength={MAX_LENGTH_DETAIL.regex}
                            onBlur={() =>
                              onBlurTrimFormItem(handler.formPayment, "note")
                            }
                          />
                        </Item>
                      ) : (
                        handler.paymentTerm?.note
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="ci001-payment-other" style={{ paddingBottom: 8 }}>
                <div className="ci001-payment-other-left">
                  <div className="ci001-payment-other-icon">
                    <img alt="" src={MessageRed} />
                  </div>
                  <div className="ci001-payment-other-content">
                    <span className="ci001-payment-other-content-top">
                      支払日が銀行休業日の場合の支払日の選択
                    </span>
                    <span className="ci001-payment-other-content-bottom">
                      {handler.isEdit ? (
                        <Item
                          name="payment_day"
                          // rules={[{ required: true, message: "" }]}
                        >
                          <Select
                            className="ci001-select"
                            getPopupContainer={(trigger: any) =>
                              trigger.parentNode
                            }
                            // onChange={}
                          >
                            {handler.dropDownPayment?.dropDown1?.map(
                              (option: any) => (
                                <Select.Option
                                  key={option.id}
                                  value={option.id}
                                >
                                  {option.description}
                                </Select.Option>
                              )
                            )}
                          </Select>
                        </Item>
                      ) : (
                        handler.categoryPayment?.category1?.description
                      )}
                    </span>
                  </div>
                </div>
                <div className="ci001-payment-other-right">
                  <div className="ci001-payment-other-icon-money">
                    <img alt="" src={MoneyIcon} />
                  </div>
                  <div className="ci001-payment-other-content">
                    <span className="ci001-payment-other-content-top">
                      手形集金方法
                    </span>
                    <span className="ci001-payment-other-content-bottom ci001-payment-other-content-bottom-custom">
                      {handler.isEdit ? (
                        <>
                          <Item
                            name={["payment_method", "select"]}
                            // rules={[{ required: true, message: "" }]}
                          >
                            <Select
                              className="ci001-select ci001-select-custom"
                              onChange={handler.onChangeMoneySelect}
                              getPopupContainer={(trigger: any) =>
                                trigger.parentNode
                              }
                            >
                              {handler.dropDownPayment?.dropDown2?.map(
                                (option: any) => (
                                  <Select.Option
                                    key={option.id}
                                    value={option.id}
                                  >
                                    {option.description}
                                  </Select.Option>
                                )
                              )}
                            </Select>
                          </Item>
                          {handler.dropDownPayment.dropDown2.find(
                            (ele: any) => ele.id === handler.moneySelect.id
                          )?.subCategory?.length > 0 && (
                            <div className="ci001-payment-other-content-bottom-custom-select">
                              <span style={{ marginBottom: 8 }}>領収証</span>
                              <Item
                                name={["payment_method", "selectBonus"]}
                                rules={[{ required: true, message: "" }]}
                              >
                                <Select
                                  className="ci001-select ci001-select-custom"
                                  getPopupContainer={(trigger: any) =>
                                    trigger.parentNode
                                  }
                                  // onChange={}
                                >
                                  {handler.dropDownPayment.dropDown2
                                    .find(
                                      (ele: any) =>
                                        ele.id === handler.moneySelect.id
                                    )
                                    ?.subCategory?.map((option: any) => (
                                      <Select.Option
                                        key={option.id}
                                        value={option.id}
                                      >
                                        {option.description}
                                      </Select.Option>
                                    ))}
                                </Select>
                              </Item>
                            </div>
                          )}
                          {handler.moneySelect?.showNote && (
                            <Item
                              className="ci001-payment-other-content-bottom-custom-input"
                              name={["payment_method", "inputOther"]}
                              rules={[
                                () => ({
                                  validator(_, value) {
                                    if (value && value !== "") {
                                      // CHECK INPUT VIETNAMESE
                                      if (!REGEX.NOT_VIETNAM.test(value)) {
                                        return Promise.reject(
                                          new Error(
                                            MESSAGE.MESSAGE_INPUT_INVALID
                                          )
                                        );
                                      }
                                      if (value?.length > MAX_LENGTH.ADDRESS) {
                                        return Promise.reject(new Error(""));
                                      }
                                      return Promise.resolve();
                                    }
                                    return Promise.reject("");
                                  },
                                }),
                              ]}
                            >
                              <Input
                                autoComplete="off"
                                className="ci001-input ci001-select-other-input "
                                style={{ width: "100%" }}
                                maxLength={MAX_LENGTH.ADDRESS}
                                placeholder=""
                                onBlur={() =>
                                  onBlurTrimFormItem(handler.formPayment, [
                                    "payment_method",
                                    "inputOther",
                                  ])
                                }
                              />
                            </Item>
                          )}
                        </>
                      ) : (
                        `${
                          handler.categoryPayment?.category2?.description
                            ? handler.categoryPayment?.category2?.description
                            : ""
                        } ${nameSubCategories} ${
                          handler.categoryPayment?.category2?.note &&
                          handler.categoryPayment?.category2?.note !== ""
                            ? `(${handler.categoryPayment?.category2?.note})`
                            : ""
                        }`
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div
                className={`ci001-item ${handler.isEdit && "ci001-item-edit"}`}
              >
                <div style={{ width: "100%", display: "flex", gap: 8 }}>
                  <div className="label-upload">ファイル</div>

                  <div className="ci001-item-text">
                    {!handler.isEdit &&
                    handler.fileListPaymentTerm.length == 0 ? (
                      <></>
                    ) : (
                      <Card
                        bodyStyle={{
                          padding: "0 12px 12px 12px",
                          maxWidth: "1309px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          background: "#fafafa",
                        }}
                        bordered={true}
                        style={{ border: "1px dashed #DAE2EE" }}
                      >
                        {handler.isEdit && (
                          <Dragger
                            customRequest={dummyRequest}
                            multiple={true}
                            fileList={[]}
                            onChange={handler.onChangeUpload}
                            onRemove={handler.onDropUpload}
                            iconRender={() => <img alt="" src={IconFile} />}
                            style={{ maxWidth: "1309px", border: "none" }}
                            disabled={handler.fileListPaymentTerm.length >= 20}
                            accept="application/pdf, image/png, image/jpeg"
                          >
                            <div className="content-upload">
                              <p className="ant-upload-drag-icon">
                                <img alt="" src={CloudUpIcon} />
                              </p>
                              <p
                                className="ci-upload-text"
                                style={{ width: 424, textAlign: "center" }}
                              >
                                ここにファイルをドラッグ・ドロップ (.pdf または
                                .png/.jpeg/.jpg)
                              </p>
                              <div className="ci-upload-text">
                                （各ファイルサイズ：8MB）
                              </div>
                              <div className="text-gray">または</div>
                              <Button
                                className="btn-upload-custom"
                                style={{
                                  borderRadius: "3px",
                                  padding: "0 24px",
                                  width: "fit-content",
                                  border: "solid 1px #DAE2EE",
                                  marginTop: 5,
                                }}
                                disabled={
                                  handler.fileListPaymentTerm.length >= 20
                                }
                              >
                                <span
                                  style={{
                                    fontSize: 14,
                                    fontWeight: 700,
                                    color: "#605BFF",
                                  }}
                                >
                                  ファイルを選択する
                                </span>
                                <span
                                  style={{
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: "#605BFF",
                                  }}
                                >
                                  (.pdf または .png/.jpeg/.jpg)
                                </span>
                              </Button>
                            </div>
                          </Dragger>
                        )}
                        {handler.fileListPaymentTerm.length > 0 && (
                          <div style={{ width: "100%", padding: "0,16px" }}>
                            <List
                              style={{
                                marginTop: 16,
                                maxWidth: "100%",
                                fontSize: 14,
                                fontWeight: 700,
                                color: "#605BFF",
                                border: "1px solid #DAE2EE",
                                maxHeight: 250,
                                overflowY: "auto",
                              }}
                              bordered
                              dataSource={handler.fileListPaymentTerm}
                              renderItem={(item: any) => (
                                <List.Item
                                  style={{
                                    color: "#605BFF",
                                    maxWidth: "1309px",
                                    overflowX: "hidden",
                                  }}
                                >
                                  <div
                                    style={{
                                      cursor: "pointer",
                                      width: "100%",
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div
                                      onClick={() => handler.getPdfMap(item)}
                                      style={{
                                        cursor: "pointer",
                                        width: handler.isEdit ? "97%" : "100%",
                                      }}
                                    >
                                      <TooltipText
                                        isLarge
                                        content={item.name}
                                      />
                                    </div>
                                    {handler.isEdit && (
                                      <img
                                        style={{ cursor: "pointer" }}
                                        src={IconDelete}
                                        onClick={() =>
                                          handler.onDropUpload(item)
                                        }
                                      />
                                    )}
                                  </div>
                                </List.Item>
                              )}
                            />
                          </div>
                        )}
                      </Card>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="data-empty" style={{ height: "65vh" }}>
              <img src={EmptyData} alt="" />
            </div>
          )}
        </div>
        {handler.isEdit && (
          <div className="ci001-form-submit">
            <Item>
              <Button
                size="large"
                className="button-purple"
                htmlType="submit"
                style={{ marginRight: 16 }}
              >
                保存
              </Button>
            </Item>
            <Button size="large" onClick={handler.handleCancelSubmit}>
              キャンセル
            </Button>
          </div>
        )}
      </Form>
    </>
  );
}

export default CI001PaymentTerm;
