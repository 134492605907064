import { NotificationAll, NotificationCategory } from "../entity/Entity";
import { APIImpl } from "./ServiceImpl";

const Service = (api: APIImpl) => {
  const getNotificationCategory = async (): Promise<NotificationCategory[]> => {
    const response = await api.getNotificationCategory();
    return response?.data?.results ?? [];
  };
  const getNotificationUnreadCount = async (params: {
    categoryId: number;
  }): Promise<any> => {
    const response = await api.getNotificationUnreadCount(params);
    return response?.data?.results ?? 0;
  };
  const getNotificationAll = async (params: {
    categoryId: number;
    keyword?: string;
    page?: string | number;
    size?: string | number;
  }): Promise<NotificationAll> => {
    const response = await api.getNotificationAll(params);
    return response?.data;
  };

  const putNotificationMarkRead = async (data: {
    listNotiId: number[];
  }): Promise<any> => {
    return await api.putNotificationMarkRead(data);
  };

  const deleteNotificationDelete = async (data: {
    listNotiId: number[];
  }): Promise<any> => {
    return await api.deleteNotificationDelete(data);
  };

  return {
    getNotificationAll,
    getNotificationCategory,
    putNotificationMarkRead,
    deleteNotificationDelete,
    getNotificationUnreadCount,
  };
};

export default Service;
