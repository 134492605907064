import { AxiosResponse } from "axios";
import axiosConfig from "../../../../../config/axiosConfig";
import { ReSummaryCompanySummaryOutsourceCostRetrievePayload } from "../../entity/Entity";

const CM0017_3Api = () => {
  const getReSummarySubmittedCompanyOutsourceCostDetail = async (params: {
    documentId: number;
    page?: number;
    size?: number;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/re-summary/submitted/company/outsource-cost/detail";
    return await axiosConfig.get(url, { params });
  };
  const getReSummarySubmittedCompanyOutsourceCostTotal = async (params: {
    documentId: number;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/re-summary/submitted/company/outsource-cost/total";
    return await axiosConfig.get(url, { params });
  };
  const getReSummarySubmittedCompanyOutsourceCost = async (params: {
    documentId: number;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/re-summary/submitted/company/outsource-cost";
    return await axiosConfig.get(url, { params });
  };
  const putReSummaryCompanySummaryOutsourceCostRetrieve = async (
    data: ReSummaryCompanySummaryOutsourceCostRetrievePayload
  ): Promise<AxiosResponse<any, any>> => {
    const url = "/re-summary/company/summary/outsource-cost/retrieve";
    return await axiosConfig.put(url, data);
  };
  const getWorkflowSummaryCompanyOutsourceApprovalProcess = async (params: {
    documentId: number;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/workflow/summary/company/outsource/approval-process";
    return await axiosConfig.get(url, { params });
  };
  const getSubCategory = async (): Promise<any> => {
    const url = "/money/received-money-offset/sub-category";
    const response = await axiosConfig.get(url);
    return response.data;
  };
  return {
    getReSummarySubmittedCompanyOutsourceCostDetail,
    getReSummarySubmittedCompanyOutsourceCostTotal,
    getReSummarySubmittedCompanyOutsourceCost,
    putReSummaryCompanySummaryOutsourceCostRetrieve,
    getWorkflowSummaryCompanyOutsourceApprovalProcess,
    getSubCategory,
  };
};

export default CM0017_3Api;
