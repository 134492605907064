import OM001Api from "./infrastructure/api/Api"
import OM001Handler from "./presenter/handler/Handler";
import OM001UI from "./presenter/ui/UI";
import OM001Service from "./usecase/Service";

const OM001Component = () => {
    const om001Api = OM001Api();
    const om001Service = OM001Service(om001Api)
    const om001Handler = OM001Handler(om001Service)
    return <OM001UI handler={om001Handler} />
}

export default OM001Component