import { MenuAPIImpl } from "./ServiceImpl";

const MenuService = (api: MenuAPIImpl) => {
  const logout = async (): Promise<any> => {
    try {
      return await api.logout();
    } catch (error) {
      throw error;
    }
  };
  return { logout };
};

export default MenuService;
