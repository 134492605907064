import React from 'react';
import { Handler } from '../handler/HandlerImpl';
import type { ColumnsType } from 'antd/es/table';
import { Table } from 'antd';
import { DataTableType } from '../../entity/Entity';
import { TYPE_ITEM } from '../handler/Handler';
import helpers from '../../../../../common/helpers/common';

interface props {
    handler: Handler;
}

const columns: ColumnsType<DataTableType> = [
    {
        title: '項目',
        dataIndex: 'item',
        key: 'item',
        width: 490,
        render: (_, record) => {
            return <div style={{ color: '#222222' }}>{record.item}</div>;
        },
    },
    {
        title: '金額',
        dataIndex: 'amountMoney',
        key: 'amountMoney',
        width: 490,
        align: 'center',
        render: (_, record) => {
            return (
                <div style={{ color: '#222222' }}>
                    {record.key === TYPE_ITEM.billingVolumeRate.key ||
                    record.key === TYPE_ITEM.billingBalanceRate.key
                        ? `${helpers.formatCurrency(record.amountMoney)}%`
                        : `${helpers.formatCurrency(record.amountMoney)}円`}
                </div>
            );
        },
    },
];

const TableComponent = ({ handler }: props) => {
    return (
        <div className="db003-table">
            <Table
                columns={columns}
                dataSource={handler.dataTable}
                pagination={false}
                bordered={true}
                size="large"
            />
        </div>
    );
};

export default TableComponent;
