import FilterApi from "../../../infrastructure/api/Api";
import FilterService from "../../../usecase/Service";
import FilterHandler from "../../handler/Handler";
import FilterSearchUI from "./ui/UI";

const FilterComponent = () => {
  const api = FilterApi();
  const service = FilterService(api);
  const handler = FilterHandler(service);
  return <FilterSearchUI handler={handler} />;
};

export default FilterComponent;
