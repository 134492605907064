// 3rd party lib
import { AutoComplete, Form, Input } from 'antd';

// Image files
import SearchSvg from '../../../../../../../assets/icons/search-icon.svg';
import CollapsedFalseSVG from '../../../../../../../assets/icons/collapsed-false.svg';
import CollapsedTrueSVG from '../../../../../../../assets/icons/collapsed-true.svg';

interface Props {
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    onSearch: React.ChangeEventHandler<HTMLInputElement>;
    onSearchDropDown: () => void;
    onCollapse: (value: boolean) => void;
    onScroll: (event: any) => Promise<void>;
    isCollapsed: boolean;
    loading: boolean;
    data: any[];
    searchDropDownValue: string;
}
const SearchBarComponent = ({
    onChange,
    onSearch,
    onCollapse,
    onScroll,
    onSearchDropDown,
    loading,
    data,
    isCollapsed,
    searchDropDownValue,
}: Props) => {
    return isCollapsed ? (
        <main className="center-item" style={{ marginBottom: 27 }}>
            <div className="collapsed-button-open" onClick={() => onCollapse(!isCollapsed)}>
                <img alt="" src={CollapsedFalseSVG} />
            </div>
        </main>
    ) : (
        <main>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
                <span className="group-list-title-text" style={{ marginLeft: 16 }}>
                    案件名
                </span>
                <div className="collapsed-button-close" onClick={() => onCollapse(!isCollapsed)}>
                    <img alt="" src={CollapsedTrueSVG} />
                </div>
            </div>
            <div className="searchbar-container pl-16 pr-16" style={{ marginBottom: 3 }}>
                <Form.Item
                    name="project"
                    style={{ margin: 0, padding: 0, width: '100%' }}
                    labelAlign="right"
                >
                    <AutoComplete
                        allowClear={true}
                        getPopupContainer={(trigger: any) => trigger.parentNode}
                        onChange={onSearchDropDown}
                        size="large"
                        className="search-bar-om001  "
                        placeholder=""
                        onSelect={(value: any, option: any) => {
                            onChange(option);
                        }}
                        onPopupScroll={onScroll}
                        options={data.map((element) => ({
                            key: element.id,
                            value: element.id + '',
                            label: element.projectName,
                            ...element,
                        }))}
                        notFoundContent={
                            <div style={{ width: '100%', textAlign: 'center' }}>データなし</div>
                        }
                        showArrow={true}
                    />
                </Form.Item>
            </div>

            <div
                className="group-list-title-text"
                style={{
                    paddingLeft: 16,
                    paddingTop: 16,
                    marginBottom: 24,
                    marginTop: 16,
                    borderTop: '1px solid #DAE2EE',
                }}
            >
                工事一覧
            </div>
            <div className="searchbar-container pl-16 pr-16" style={{ marginBottom: 3 }}>
                <Form.Item name="text" style={{ margin: 0, padding: 0 }}>
                    <Input
                        autoComplete="off"
                        size="large"
                        prefix={<img alt="" src={SearchSvg} />}
                        placeholder="工事名を入力"
                        className="search-bar-om001 w-100-percent "
                        onChange={onSearch}
                    />
                </Form.Item>
            </div>

            <div className=" w-100-percent" style={{ marginBottom: 24 }}></div>
        </main>
    );
};
export default SearchBarComponent;
