import { uuidv4 } from "@firebase/util";
import { Form } from "antd";
import { useState, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import { RootState } from "../../../../../store";
import { SM03UseCase } from "../../usecase/ServiceImpl";
import { resetSM03, setIsVisibleSM03, setLoadingSM03 } from "../slice/Slice";
import ConfirmModal from "../../../../../common/components/modal/ConfirmModal";
import COMMON from "../../../../../common/constants/COMMON";
import moment from "moment";
import { Guest, Member } from "../type/Presenter";
import { ReqBodySchedule } from "../../entity/Entity";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import helpers from "../../../../../common/helpers/common";
import { setIsVisibleSM04 } from "../../../SM04/presenter/slice/Slice";
import { refreshPage } from "../../../SM01/presenter/slice/Slice";

const SM03Handler = (SM03Service: SM03UseCase) => {
  // LIB FUNCTION
  const dispatch = useDispatch();
  //STATE
  const [form] = Form.useForm();
  const [isDirty, setIsDirty] = useState(false);
  const [listGuest, setListGuest] = useState<Guest[]>([]);
  const [disableTime, setDisableTime] = useState<boolean>(false);
  const [loadingOption, setLoadingOption] = useState<boolean>(false);
  const [loadingOptionProject, setLoadingOptionProject] =
    useState<boolean>(false);

  const [pageOption, setPageOption] = useState(COMMON.DEFAULT_PAGE);
  const [sizeOption, setSizeOption] = useState(COMMON.DEFAULT_SIZE);
  const [keywordOption, setKeywordOption] = useState<any>(undefined);
  const [pageOptionProject, setPageOptionProject] = useState(
    COMMON.DEFAULT_PAGE
  );
  const [sizeOptionProject, setSizeOptionProject] = useState(
    COMMON.DEFAULT_SIZE
  );
  const [keywordOptionProject, setKeywordOptionProject] =
    useState<any>(undefined);
  const [members, setMembers] = useState<any[]>([]);
  const [totalPage, setTotalPage] = useState<number>();
  const typeModal = useSelector((state: RootState) => state.sm03.typeModal);
  const scheduleId = useSelector((state: RootState) => state.sm03.scheduleId);
  const [listCategory, setListCategory] = useState<any>([]);
  const [projects, setProjects] = useState<any[]>([]);
  const [constructions, setConstructions] = useState<any[]>([]);
  const [chosenProject, setChosenProject] = useState<boolean>(false);
  const [pageOptionConstruction, setPageOptionConstruction] = useState(
    COMMON.DEFAULT_PAGE
  );
  const [sizeOptionConstruction, setSizeOptionConstruction] = useState(
    COMMON.DEFAULT_SIZE
  );
  const [keywordOptionConstruction, setKeywordOptionConstruction] =
    useState<any>(undefined);
  const [currentProject, setCurrentProject] = useState<any>();
  const [dataDetailSchedule, setDataDetailSchedule] = useState<any>();
  const [projectIdChoosed, setProjectIdChoosed] = useState<
    number | undefined
  >();
  const [
    pageOptionConstructionChoosedProject,
    setPageOptionConstructionChoosedProject,
  ] = useState(COMMON.DEFAULT_PAGE);

  //function
  const submitForm = () => {
    const data: ReqBodySchedule = {
      startTime: helpers.combineDateTime(
        form.getFieldValue("date")[0],
        form.getFieldValue("startTime")
      ),
      endTime: helpers.combineDateTime(
        form.getFieldValue("date")[1],
        form.getFieldValue("endTime")
      ),
      isFullDay: disableTime,
      listMember: form.getFieldValue("guest"),
      name: form.getFieldValue("title"),
      note: form.getFieldValue("note"),
      projectId: +form.getFieldValue("project"),
      constructionId: +form.getFieldValue("construction") || null,
      problem: form.getFieldValue("problem"),
      notification: form.getFieldValue("notification"),
      categoryId: form.getFieldValue("category"),
    };
    if (disableTime) {
      data.startTime = helpers.getFilterTime(form.getFieldValue("date")[0]);
      data.endTime = helpers.getFilterTimeTo(form.getFieldValue("date")[1]);
    }
    if (typeModal && scheduleId) {
      data.id = scheduleId;
      updateSchedule(data);
    } else createSchedule(data);
  };

  const createSchedule = async (data: ReqBodySchedule) => {
    try {
      dispatch(setLoadingSM03(true));
      const res = await SM03Service.createSchedule(data);
      dispatch(refreshPage());
      SuccessNotification(res?.data?.message ?? NOTIFICATION_TITLE.SUCCESS);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoadingSM03(false));
      form.resetFields();
      dispatch(setIsVisibleSM03(false));
    }
  };

  const updateSchedule = async (data: ReqBodySchedule) => {
    try {
      dispatch(setLoadingSM03(true));
      const res = await SM03Service.updateSchedule(data);
      dispatch(refreshPage());
      SuccessNotification(res?.data?.message ?? NOTIFICATION_TITLE.SUCCESS);
      dispatch(setIsVisibleSM04(false));
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoadingSM03(false));
      form.resetFields();
      dispatch(setIsVisibleSM03(false));
    }
  };

  const trimValueInput = (item: any, field: string) => {
    form.setFieldValue(field, item.target.value.trim());
  };

  const getListMember = async (params: {
    page: number;
    size: number;
    keyword?: string;
  }) => {
    setLoadingOption(true);
    try {
      const response = await SM03Service.getListMember(params);
      setTotalPage(response?.pagination?.numPages);
      if (params.page === 1) {
        setMembers(response.results);
      } else {
        setMembers([...members, ...response.results]);
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      setLoadingOption(false);
    }
  };

  const handleGetMember = () => {
    setMembers([]);
    setKeywordOption(undefined);
    setPageOption(1);
    setSizeOption(10);
    getListMember({ page: 1, size: 10 });
  };

  let timeOut: any = useRef();
  const handleSearchOption = (searchValue: string) => {
    if (timeOut.current) clearTimeout(timeOut.current);
    timeOut.current = setTimeout(async () => {
      try {
        setLoadingOption(true);
        getListMember({
          page: COMMON.DEFAULT_PAGE,
          size: COMMON.DEFAULT_SIZE,
          keyword: searchValue,
        });
        setKeywordOption(searchValue);
        setPageOption(COMMON.DEFAULT_PAGE);
        setSizeOption(COMMON.DEFAULT_SIZE);
      } catch (error: any) {
        ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      } finally {
        setLoadingOption(false);
      }
    }, 1000);
  };

  const handleScrollMember = async (e: any): Promise<any> => {
    try {
      setLoadingOption(true);
      const target = e.target;
      if (
        target.scrollTop + target.offsetHeight === target.scrollHeight &&
        totalPage &&
        pageOption + 1 <= totalPage
      ) {
        setPageOption(pageOption + 1);
        setPageOption(pageOption + 1);
        getListMember({
          page: pageOption + 1,
          size: sizeOption,
          keyword: keywordOption ? keywordOption : undefined,
        });
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      setLoadingOption(false);
    }
  };

  const memberDeputyProjectManager = useMemo(() => {
    const checkDuplicate: any = {};
    const results: any[] = [];
    for (const member of members) {
      if (
        !(member.id in checkDuplicate) &&
        member?.username !== helpers.getLocalUsername()
      ) {
        results.push(member);
        checkDuplicate[member.id] = member;
      }
    }
    return results;
  }, [members]);

  const onSelect = (guestId: number) => {
    setKeywordOption(undefined);
    let checkExist = false;
    listGuest.forEach((item: any) => {
      if (item.id === guestId) checkExist = true;
    });
    if (!checkExist) {
      const guest: any = memberDeputyProjectManager.find(
        (item) => item.id === guestId
      );
      setListGuest([...listGuest, { id: guest.id, name: guest.fullName }]);
    }
  };

  const onDeSelect = (guestId: number) => {
    removeSelect(guestId);
  };

  const removeSelect = (guestId: number) => {
    const res: Guest[] = listGuest.filter((item: Guest) => item.id !== guestId);
    const listId = res.map((item: Guest) => item.id);
    form.setFieldValue("guest", [...listId]);
    setListGuest(res);
  };

  const handleCheckbox = (value: boolean) => {
    setDisableTime(value);
    if (value) {
      form.validateFields(["startTime", "endTime"]);
      form.resetFields(["startTime", "endTime"]);
    }
  };

  const getDetailSchedule = async (scheduleId: number) => {
    try {
      dispatch(setLoadingSM03(true));
      const params = {
        scheduleId,
      };
      const res = await SM03Service.getDetailSchedule(params);
      if (res.results) setDataDetailSchedule(res.results);
    } catch (e: any) {
      ErrorNotification(e?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoadingSM03(false));
    }
  };

  const handleFillFormUpdate = async () => {
    form.setFieldValue("title", dataDetailSchedule?.name);
    form.setFieldValue("date", [
      moment(dataDetailSchedule?.startTime),
      moment(dataDetailSchedule?.endTime),
    ]);
    setDisableTime(dataDetailSchedule?.isFullDay);
    if (!dataDetailSchedule?.isFullDay) {
      form.setFieldValue("startTime", moment(dataDetailSchedule?.startTime));
      form.setFieldValue("endTime", moment(dataDetailSchedule?.endTime));
    }
    form.setFieldValue("owner", dataDetailSchedule?.owner?.id);
    fillMember(dataDetailSchedule?.listMember);
    form.setFieldValue("note", dataDetailSchedule?.note);
    form.setFieldValue("category", dataDetailSchedule?.category?.id);
    form.setFieldValue("problem", dataDetailSchedule?.problem);
    form.setFieldValue("notification", dataDetailSchedule?.notification);

    const data = await handleFetchApiGetAllProject(
      {
        page: COMMON.DEFAULT_PAGE,
        size: COMMON.DEFAULT_SIZE,
        keyword: undefined,
        scheduleId: scheduleId || undefined,
      },
      dataDetailSchedule?.project?.id
    );

    const found = data.find(
      (item: any) => item.id === dataDetailSchedule?.project?.id
    );

    if (found) {
      const response = await handleGetDetailProjectById(
        dataDetailSchedule?.project?.id
      );
      form.setFieldValue("project", found.id);

      await getProject(
        {
          page: COMMON.DEFAULT_PAGE,
          size: COMMON.DEFAULT_SIZE,
          keyword: undefined,
          scheduleId: scheduleId,
        },
        dataDetailSchedule?.project?.id
      );
      const foundConstruction = response?.constructions.find(
        (item: any) => item.id === dataDetailSchedule?.construction?.id
      );
      if (foundConstruction) {
        form.setFieldValue("construction", foundConstruction.id);
      }
      setConstructions([...response.constructions]);
      setChosenProject(true);
    }
    if (!found) {
      await handleGetProjectFirstOpenModal({
        page: COMMON.DEFAULT_PAGE,
        size: COMMON.DEFAULT_SIZE,
      });
      projects.unshift({
        id: dataDetailSchedule?.project?.id,
        name: dataDetailSchedule?.project?.name,
        isMemberDeleted: true,
      });
      setProjects(projects);
      form.setFieldValue("project", dataDetailSchedule?.project?.id);
      if (dataDetailSchedule?.construction?.id) {
        constructions.unshift({
          id: dataDetailSchedule?.construction?.id,
          name: dataDetailSchedule?.construction?.name,
          isMemberDeleted: true,
        });
        setConstructions(constructions);
        form.setFieldValue(
          "construction",
          dataDetailSchedule?.construction?.id
        );
      }
    }
    if (dataDetailSchedule?.project == null) {
      await handleGetProjectFirstOpenModal({
        page: COMMON.DEFAULT_PAGE,
        size: COMMON.DEFAULT_SIZE,
        scheduleId: scheduleId,
      });
      await handleGetConstructionFirstOpenModal({
        page: COMMON.DEFAULT_PAGE,
        size: COMMON.DEFAULT_SIZE,
        keyword: undefined,
        scheduleId: scheduleId,
      });
    }
  };

  const fillMember = (data: any) => {
    const arr: number[] = [];
    const listGuest: Guest[] = data?.map((item: any) => {
      arr.push(item?.id);
      return {
        id: item?.id,
        name: item?.fullName ?? item?.username,
      };
    });
    form.setFieldValue("guest", arr);
    setListGuest(listGuest);
  };

  const checkDirty = (): void => {
    const formValue = form.getFieldsValue();
    const formProps = Object.keys(formValue);
    for (let i = 0; i < formProps.length; i++) {
      if (form.isFieldTouched(formProps[i])) {
        return setIsDirty(true);
      }
    }
    setIsDirty(false);
  };

  const onCancel = () => {
    if (!isDirty) {
      dispatch(resetSM03());
      return;
    }
    ConfirmModal({
      onOk: () => {
        dispatch(setIsVisibleSM03(false));
      },
      className: "confirm__modal",
      title: MESSAGE.MESSAGE_020,
      description: MESSAGE.MESSAGE_022_1,
      extraDescription: MESSAGE.MESSAGE_022_2,
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_MODAL,
      isCenterWithoutMenu: true,
    });
  };

  const getListCategory = async () => {
    try {
      dispatch(setLoadingSM03(true));
      const response = await SM03Service.getListCategory();
      if (response.results) {
        setListCategory(response.results);
        if (!typeModal) {
          const found = response?.results.find(
            (element: any) => element?.isDefault
          );
          form.setFieldValue("category", found?.id);
        }
      }
    } catch (e: any) {
      ErrorNotification(e?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoadingSM03(false));
    }
  };

  const getListProject = async (params: {
    page: number;
    size: number;
    keyword?: string;
    scheduleId?: number;
  }): Promise<any> => {
    const paramsGet: any = { ...params };
    const response = await SM03Service.getProjects(paramsGet);
    if (response?.results) {
      return response.results;
    }
  };

  const handleGetProjectFirstOpenModal = async (params: any): Promise<any> => {
    try {
      const data = await getListProject(params);
      setProjects([...data]);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const formatConstructions = (data: any) => {
    const arr: any = [];
    data.map((item: any) => {
      arr.push({
        id: item.id,
        name: item.name,
        projectId: item.projectId,
      });
    });
    return arr;
  };

  const getListConstruction = async (params: {
    page: number;
    size: number;
    keyword?: string;
    projectId?: number;
    scheduleId?: number;
  }): Promise<any> => {
    try {
      const paramsGet = { ...params };
      const res = await SM03Service.getConstruction(paramsGet);
      if (res?.results) {
        const data = formatConstructions(res.results);
        return data;
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const handleGetConstructionFirstOpenModal = async (
    params: any
  ): Promise<any> => {
    try {
      const data = await getListConstruction(params);
      setConstructions([...data]);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const handleScrollConstruction = async (e: any): Promise<any> => {
    if (!typeModal && !chosenProject) {
      try {
        setLoadingOption(true);
        const target = e.target;
        if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
          setPageOptionConstruction(pageOptionConstruction + 1);
          const data = await getListConstruction({
            page: pageOptionConstruction + 1,
            size: sizeOptionConstruction,
            keyword: keywordOptionConstruction
              ? keywordOptionConstruction
              : undefined,
          });
          if (data) setConstructions([...constructions, ...data]);
        }
      } catch (error: any) {
        ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      } finally {
        setLoadingOption(false);
      }
    }
    {
      try {
        setLoadingOption(true);
        const target = e.target;
        if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
          setPageOptionConstructionChoosedProject(
            pageOptionConstructionChoosedProject + 1
          );
          const data = await getListConstruction({
            page: pageOptionConstructionChoosedProject + 1,
            size: sizeOptionConstruction,
            keyword: keywordOptionConstruction
              ? keywordOptionConstruction
              : undefined,
            projectId: projectIdChoosed,
            scheduleId: scheduleId || undefined,
          });
          if (data) setConstructions([...constructions, ...data]);
        }
      } catch (error: any) {
        ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      } finally {
        setLoadingOption(false);
      }
    }
  };

  const handleSearchOptionProject = (searchValue: string) => {
    if (timeOut.current) clearTimeout(timeOut.current);
    timeOut.current = setTimeout(async () => {
      try {
        setLoadingOptionProject(true);
        setKeywordOptionProject(searchValue);
        setPageOptionProject(COMMON.DEFAULT_PAGE);
        setSizeOptionProject(COMMON.DEFAULT_SIZE);
        const data = await getListProject({
          page: COMMON.DEFAULT_PAGE,
          size: COMMON.DEFAULT_SIZE,
          keyword: searchValue,
        });
        if (data) setProjects([...data]);
        setKeywordOption(searchValue)
      } catch (error: any) {
        ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      } finally {
        setLoadingOptionProject(false);
      }
    }, 1000);
  };

  const handleSearchOptionConstruction = (searchValue: string) => {
    if (!chosenProject) {
      if (timeOut.current) clearTimeout(timeOut.current);
      timeOut.current = setTimeout(async () => {
        if (!chosenProject) {
          try {
            setLoadingOption(true);
            setKeywordOptionConstruction(searchValue);
            setPageOptionConstruction(COMMON.DEFAULT_PAGE);
            setSizeOptionConstruction(COMMON.DEFAULT_SIZE);
            const data = await getListConstruction({
              page: COMMON.DEFAULT_PAGE,
              size: COMMON.DEFAULT_SIZE,
              keyword: searchValue,
              scheduleId: scheduleId || undefined,
            });
            if (data) setConstructions([...data]);
          } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
          } finally {
            setLoadingOption(false);
          }
        }
      }, 1000);
    } else {
      if (timeOut.current) clearTimeout(timeOut.current);
      timeOut.current = setTimeout(async () => {
        if (chosenProject) {
          try {
            setLoadingOption(true);
            setKeywordOptionConstruction(searchValue);
            setPageOptionConstructionChoosedProject(COMMON.DEFAULT_PAGE);

            const data = await getListConstruction({
              page: COMMON.DEFAULT_PAGE,
              size: COMMON.DEFAULT_SIZE,
              keyword: searchValue,
              projectId: projectIdChoosed,
              scheduleId: scheduleId || undefined,
            });
            if (data) setConstructions([...data]);
          } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
          } finally {
            setLoadingOption(false);
          }
        }
      }, 1000);
    }
  };

  const handleFindProjects = async (constructionId: number) => {
    if (!chosenProject && constructions.length > 0) {
      const data = await handleFetchApiGetAllConstruction(
        {
          page: COMMON.DEFAULT_PAGE,
          size: COMMON.DEFAULT_SIZE,
          keyword: undefined,
          scheduleId: scheduleId || undefined,
        },
        constructionId
      );
      if (data) {
        const newData = formatConstructions(data);
        setConstructions([...newData]);
      }
      const goal: any = constructions.find(
        (element: any) => element.id === constructionId
      );
      if (goal?.projectId) {
        const data = await handleFetchApiGetAllProject(
          {
            page: COMMON.DEFAULT_PAGE,
            size: COMMON.DEFAULT_SIZE,
            keyword: undefined,
            scheduleId: scheduleId || undefined,
          },
          goal.projectId
        );
        if (data) setProjects([...data]);
        form.setFieldValue("project", goal.projectId);
      }
    }
  };

  const resetConstructionWhenSelectOrBlur = async (
    constructionId: any
  ): Promise<any> => {
    if (chosenProject) {
      try {
        setLoadingOption(true);
        setKeywordOptionConstruction(undefined);
        setPageOptionConstructionChoosedProject(COMMON.DEFAULT_PAGE);

        const data = await getListConstruction({
          page: COMMON.DEFAULT_PAGE,
          size: COMMON.DEFAULT_SIZE,
          keyword: undefined,
          projectId: projectIdChoosed,
          scheduleId: scheduleId || undefined,
        });
        if (data) setConstructions(data);
      } catch (error: any) {
        ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      } finally {
        setLoadingOption(false);
      }
    } else {
      try {
        setKeywordOptionConstruction(undefined);
        const data = await handleFetchApiGetAllConstruction(
          {
            page: COMMON.DEFAULT_PAGE,
            size: COMMON.DEFAULT_SIZE,
            keyword: undefined,
            scheduleId: scheduleId || undefined,
          },
          constructionId
        );
        if (data) setConstructions([...data]);
      } catch (error: any) {
        ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      }
    }
  };

  const handleScrollProject = async (e: any): Promise<any> => {
    try {
      setLoadingOption(true);
      const target = e.target;
      if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
        setPageOption(pageOption + 1);
        const data = await getListProject({
          page: pageOption + 1,
          size: sizeOption,
          keyword: keywordOption ? keywordOption : undefined,
          scheduleId: scheduleId || undefined,
        });
        if (data) setProjects([...projects, ...data]);
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      setLoadingOption(false);
    }
  };

  const resetProjectWhenSelectOrBlur = async (
    projectId: number
  ): Promise<any> => {
    try {
      setKeywordOption(undefined);
      const data = await handleFetchApiGetAllProject(
        {
          page: COMMON.DEFAULT_PAGE,
          size: COMMON.DEFAULT_SIZE,
          keyword: undefined,
          scheduleId: scheduleId || undefined,
        },
        projectId
      );
      if (data) setProjects([...data]);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const handleFindConstructions = async (projectId: number): Promise<any> => {
    try {
      setChosenProject(true);
      setProjectIdChoosed(projectId);
      if (projects.length !== 0) {
        let param = {
          page: COMMON.DEFAULT_PAGE,
          size: COMMON.DEFAULT_SIZE,
          projectId,
          scheduleId: scheduleId || undefined,
        };
        const response = await getListConstruction(param);
        setConstructions(response ?? []);
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const handleFetchApiGetAllConstruction = async (
    params: any,
    constructionId: number
  ): Promise<any> => {
    try {
      const res = await SM03Service.getConstruction(params);
      if (res?.results.length > 0) {
        if (handleCheckExistConstruction(res.results, constructionId))
          return res.results;
        else {
          const param: any = {
            page: params.page + 1,
            size: params.size,
            keyword: params.keyword,
            scheduleId: scheduleId || undefined,
          };
          setPageOptionConstruction(param.page);
          return res.results.concat(
            await handleFetchApiGetAllConstruction(param, constructionId)
          );
        }
      } else return [];
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const handleCheckExistConstruction = (data: any, constructionId: number) => {
    const res = data.find((item: any) => item?.id === constructionId);
    if (res) return true;
    else return false;
  };

  const handleFetchApiGetAllProject = async (
    params: any,
    projectId: number
  ): Promise<any> => {
    try {
      const res = await SM03Service.getProjects(params);
      if (res?.results?.length > 0) {
        if (handleCheckExistProject(res.results, projectId)) return res.results;
        else {
          const param: any = {
            page: params.page + 1,
            size: params.size,
            keyword: params.keyword,
            scheduleId: scheduleId || undefined,
          };
          setPageOption(param.page);
          return res.results.concat(
            await handleFetchApiGetAllProject(param, projectId)
          );
        }
      } else return [];
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const handleCheckExistProject = (data: any, projectId: number) => {
    const res = data.find((item: any) => item?.id === projectId);
    if (res) {
      setCurrentProject(res);
      return true;
    } else return false;
  };

  const handleGetDetailProjectById = async (
    projectId: number
  ): Promise<any> => {
    try {
      const res = await SM03Service.getDetailProject({ projectId });
      if (
        res?.results?.id &&
        res?.results?.projectName &&
        res?.results?.constructions
      ) {
        const data = {
          id: res.results.id,
          projectName: res.results.projectName,
          constructions: res.results.constructions,
        };
        return data;
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const getProject = async (params: any, projectId: number): Promise<any> => {
    const res = await handleFetchApiGetAllProject(params, projectId);
    if (res) setProjects([...res]);
  };

  const onRangePickerChange = () => {
    form.resetFields(["startTime", "endTime"]);
  };

  return {
    form,
    isDirty,
    listGuest,
    disableTime,
    loadingOption,
    memberDeputyProjectManager,
    listCategory,
    projects,
    constructions,
    dataDetailSchedule,
    loadingOptionProject,

    submitForm,
    trimValueInput,
    handleGetMember,
    handleSearchOption,
    onSelect,
    onDeSelect,
    removeSelect,
    handleCheckbox,
    handleScrollMember,
    getDetailSchedule,
    checkDirty,
    onCancel,
    getListCategory,
    handleGetProjectFirstOpenModal,
    handleGetConstructionFirstOpenModal,
    handleScrollConstruction,
    handleSearchOptionConstruction,
    handleFindProjects,
    resetConstructionWhenSelectOrBlur,
    handleScrollProject,
    resetProjectWhenSelectOrBlur,
    handleFindConstructions,
    getProject,
    handleFillFormUpdate,
    handleSearchOptionProject,
    onRangePickerChange,
  };
};

export default SM03Handler;
