import { createSlice } from "@reduxjs/toolkit";
import { AM010SliceType } from "../type/Presenter";

const initialState: AM010SliceType = {
  loading: false,
  isVisible: false,
  refresh: false,
  haveProject: false,
  isVisibleDetailShift: false
};

export const am010Slice = createSlice({
  name: "am010",
  initialState,
  reducers: {
    resetAM010: () => initialState,
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setRefreshAM010: (state) => {
      state.refresh = !state.refresh;
    },
    setHaveProject: (state, action) => {
      state.haveProject = action.payload;
    },
    setIsVisibleDetailShift: (state, action) => {
      state.isVisibleDetailShift = action.payload;
    },
  },
});

export const {
  setLoading,
  resetAM010,
  setRefreshAM010,
  setHaveProject,
  setIsVisibleDetailShift } =
  am010Slice.actions;

export default am010Slice.reducer;
