import { AM022Api } from "./ServiceImpl";

const AM022Service = (am022Api: AM022Api) => {
  const getLeaveCategory = async (params?: any): Promise<any> => {
    try {
      return await am022Api.getLeaveCategory(params);
    } catch (error) {
      throw error;
    }
  };

  const getLeaveSubCategory = async (params?: any): Promise<any> => {
    try {
      return await am022Api.getLeaveSubCategory(params);
    } catch (error) {
      throw error;
    }
  };

  const createLeaveRequest = async (data: any): Promise<any> => {
    try {
      return await am022Api.createLeaveRequest(data);
    } catch (error) {
      throw error;
    }
  };

  const getRequestLeave = async (params:any): Promise<any> => {
    try{
      return await am022Api.getRequestLeave(params);
    }catch(error){
      throw(error)
    }
  };

  const getProjects = async (params: any): Promise<any> => {
    try {
      return await am022Api.getProjects(params);
    } catch (error) {
      throw error;
    }
  };

  const updateLeave= async (data:any): Promise<any> => {
    try{
     return await am022Api.updateLeave(data);
    }catch(error){
     throw error;
    }
   };

   const getAttendanceUserConstruction = async (params:any): Promise<any> => {
    try{
      return await am022Api.getAttendanceUserConstruction(params);
    }catch(error){
      throw(error)
    }
  };

  const getProjectById = async (params:any): Promise<any> => {
    try{
      return await am022Api.getProjectById(params);
    }catch(error){
      throw(error)
    }
  };

  const getRequestApprover = async (): Promise<any> => {
    try{
      return await am022Api.getRequestApprover();
    }catch(error){
      throw(error)
    }
  };

  const getProjectStatus = async (params: any): Promise<any> => {
    try{
      return await am022Api.getProjectStatus(params);
    }catch(error){
      throw(error)
    }
  };

  return {
    getLeaveCategory,
    getLeaveSubCategory,
    createLeaveRequest,
    getRequestLeave,
    getProjects,
    updateLeave,
    getAttendanceUserConstruction,
    getProjectById,
    getRequestApprover,
    getProjectStatus
  };
};

export default AM022Service;
