import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import "firebase/messaging";
import axiosConfig from "./config/axiosConfig";
import ErrorNotification from "./common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "./common/constants/MESSAGE";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const { REACT_APP_VAPID_KEY } = process.env;

const firebaseConfig = {
  apiKey: "AIzaSyAkxWt4dRkW2MwVJU3kHvXSeuJXLL7oUoM",
  authDomain: "one-unit-4b9fb.firebaseapp.com",
  projectId: "one-unit-4b9fb",
  storageBucket: "one-unit-4b9fb.appspot.com",
  messagingSenderId: "712174216173",
  appId: "1:712174216173:web:4f5b1f14ef7905ebcebde4",
  measurementId: "G-H0F9L8G0T8",
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

// const { REACT_APP_VAPID_KEY } = process.env;
const publicKey = REACT_APP_VAPID_KEY;

let tokenFCM: string;
export const requestForToken = () => {
  return getToken(messaging, { vapidKey: publicKey })
    .then((currentToken) => {
      if (currentToken) {
        window.localStorage.setItem("fbToken", currentToken);
        const user = localStorage.getItem("user");
        // Perform any other neccessary action with the token
        if (tokenFCM !== currentToken && user) {
          postFCMRegister({ fcm: currentToken });
        }
        tokenFCM = currentToken;
        if (!user) {
          tokenFCM = "";
        }
      }
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

const postFCMRegister = async (data: { fcm: string }) => {
  try {
    const url = "/auth/register-fcm";
    await axiosConfig.post(url, data);
  } catch (error: any) {
    ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
  }
};
