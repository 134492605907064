import {
  MoneyDirectCostOutSourceCreatePayload,
  MoneyDirectCostOutsourceDeletePayload,
  MoneyDirectCostOutsourceUpdatePayload,
  CommonPutDeletePostResponse,
  MoneyDirectCostOutsourceListResult,
  MoneyDirectCostOutsourceOffsetListResponseData,
  AttendanceProjectConstructionResult,
} from "../entity/Entity";
import { CollapsedTableAPIImpl } from "./ServiceImpl";

const CollapsedTableService = (api: CollapsedTableAPIImpl) => {
  const getMoneyDirectCostOutsourceList = async (params: {
    outsourceCostId: number;
    page?: number;
    size?: number;
    sortBy?: string;
    sortType?: string;
  }): Promise<MoneyDirectCostOutsourceListResult[]> => {
    try {
      const response = await api.getMoneyDirectCostOutsourceList({
        outsourceCostId: params.outsourceCostId,
      });
      const { results } = response.data;
      return results;
    } catch (error: any) {
      return [];
    }
  };

  const getAttendanceProjectConstruction = async (params: {
    constructionId: number;
    colabCompanyId: number;
    from: string;
    to: string;
    timeOffset: number;
  }): Promise<AttendanceProjectConstructionResult[]> => {
    try {
      const response = await api.getAttendanceProjectConstruction(params);
      const { results } = response.data;
      return results;
    } catch (error: any) {
      return [];
    }
  };

  const deleteMoneyDirectCostOutsourceDelete = async (
    data: MoneyDirectCostOutsourceDeletePayload
  ): Promise<CommonPutDeletePostResponse> => {
    try {
      const response = await api.deleteMoneyDirectCostOutsourceDelete(data);
      return response.data;
    } catch (error: any) {
      throw error;
    }
  };
  const postMoneyDirectCostOutSourceCreate = async (
    data: MoneyDirectCostOutSourceCreatePayload
  ): Promise<CommonPutDeletePostResponse> => {
    try {
      const response = await api.postMoneyDirectCostOutSourceCreate(data);
      return response.data;
    } catch (error: any) {
      throw error;
    }
  };

  const postMoneyDirectCostOutsourceUpdate = async (
    data: MoneyDirectCostOutsourceUpdatePayload
  ): Promise<CommonPutDeletePostResponse> => {
    try {
      const response = await api.postMoneyDirectCostOutsourceUpdate(data);
      return response.data;
    } catch (error: any) {
      throw error;
    }
  };
  const getLinkPresignUpload = async (params: any): Promise<any> => {
    try {
      const response = await api.getLinkPresignUpload(params);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const uploadToS3 = async (url: any, data: any): Promise<any> => {
    try {
      const response = await api.uploadToS3(url, data);
      return response.data;
    } catch (error: any) {
      throw error;
    }
  };

  const getPresignLinkS3 = async (params: any): Promise<any> => {
    try {
      const response = await api.getPresignLinkS3(params);
      return response.data;
    } catch (error: any) {
      throw error;
    }
  };
  const getMoneyDirectCostOutsourceOffsetList = async (params: {
    outsourceCostInformationId: number;
    page?: number;
    size?: number;
  }): Promise<MoneyDirectCostOutsourceOffsetListResponseData> => {
    try {
      const response = await api.getMoneyDirectCostOutsourceOffsetList(params);
      return response.data;
    } catch (error: any) {
      throw error;
    }
  };
  return {
    getMoneyDirectCostOutsourceList,
    postMoneyDirectCostOutsourceUpdate,
    postMoneyDirectCostOutSourceCreate,
    deleteMoneyDirectCostOutsourceDelete,
    getLinkPresignUpload,
    uploadToS3,
    getPresignLinkS3,
    getMoneyDirectCostOutsourceOffsetList,
    getAttendanceProjectConstruction,
  };
};

export default CollapsedTableService;
