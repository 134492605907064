import { Button, Modal } from 'antd';
import { useSelector } from 'react-redux';
import '../../../../../assets/styles/AM/AM027.css';
import { RootState } from '../../../../../store';
import { AM027Hanlder } from '../handler/HandlerImpl';
import InfoCircle from '../../../../../assets/icons/InfoCircle.svg';

type Props = {
    handler: AM027Hanlder;
    title: string;
    description: string;
};

const AM027UI = ({ handler, title, description }: Props) => {
    const isVisible = useSelector((state: RootState) => state.am027.isVisible);

    return (
        <Modal
            className="am027_modal"
            style={{ marginLeft: 300, marginTop: 40, marginBottom: 40 }}
            visible={isVisible}
            closable={false}
            maskClosable={false}
            width={'560px'}
            centered={true}
            footer={
                <>
                    <Button
                        size="large"
                        type="primary"
                        style={{ marginLeft: 16, width: 95, letterSpacing: -1.5 }}
                        onClick={handler.handleDeleteRequest}
                    >
                        削除
                    </Button>
                    <Button
                        size="large"
                        style={{ marginLeft: 16, width: 118 }}
                        onClick={handler.onCancel}
                    >
                        キャンセル
                    </Button>
                </>
            }
        >
            <div className="header">
                <img src={InfoCircle} alt="" style={{ marginRight: 14 }} />
                {title}
            </div>
            <div className="body">{description}</div>
        </Modal>
    );
};

export default AM027UI;
