import OM005Api from "./infrastructure/api/Api"
import OM005Handler from "./presenter/handler/Handler"
import OM005UI from "./presenter/ui/UI"
import OM005Service from "./usecase/Service"

const OM005Component = () => {
    const om005Api = OM005Api()
    const om005Service = OM005Service(om005Api)
    const om005Handler = OM005Handler(om005Service)

    return <OM005UI handler={om005Handler} />
}

export default OM005Component