import ErrorNotification from "../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../common/constants/MESSAGE";
import { WorkflowResponseData } from "../../CM008/entity/Entity";
import {
  BaseResponseType,
  ProjectAllsData,
  ReSummarySubmittedIndirectCostProjectListByDocument,
  ReSummarySubmittedIndirectCostProjectListByDocumentResult,
  SubmittedIndirectCostProjectListData,
  SubmittedIndirectCostProjectRetrievePayload,
  SubmittedIndirectCostProjectTotalData,
} from "../entity/Entity";
import { CM0031Api } from "./ServiceImpl";

const CM0031Service = (api: CM0031Api) => {
  const getSubmittedIndirectCostProjectList = async (params: {
    documentId: string;
    projectId: string;
  }): Promise<SubmittedIndirectCostProjectListData | null> => {
    try {
      const response = await api.getSubmittedIndirectCostProjectList(params);
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return null;
    }
  };
  const getReSummarySubmittedIndirectCostProjectListByDocument =
    async (params: {
      page?: number;
      size?: number;
      documentId: number;
    }): Promise<ReSummarySubmittedIndirectCostProjectListByDocument> => {
      const response =
        await api.getReSummarySubmittedIndirectCostProjectListByDocument(
          params
        );
      return response.data;
    };

  const postSubmittedIndirectCostProjectRetrieve = async (
    payload: SubmittedIndirectCostProjectRetrievePayload
  ): Promise<BaseResponseType | null> => {
    try {
      const response = await api.postSubmittedIndirectCostProjectRetrieve(
        payload
      );
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return null;
    }
  };
  const getSubmittedIndirectCostProjectTotal = async (params: {
    documentId: number;
  }): Promise<SubmittedIndirectCostProjectTotalData | null> => {
    try {
      const response = await api.getSubmittedIndirectCostProjectTotal(params);
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return null;
    }
  };
  const getWorkflowSummaryIndirectApprovalProcess = async (params: {
    documentId: number;
  }): Promise<WorkflowResponseData> => {
    try {
      const response = await api.getWorkflowSummaryIndirectApprovalProcess(
        params
      );
      return response.data;
    } catch (error: any) {
      throw error;
    }
  };
  return {
    getSubmittedIndirectCostProjectList,
    getReSummarySubmittedIndirectCostProjectListByDocument,
    postSubmittedIndirectCostProjectRetrieve,
    getSubmittedIndirectCostProjectTotal,
    getWorkflowSummaryIndirectApprovalProcess,
  };
};

export default CM0031Service;
