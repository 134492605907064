import { Params } from '../presenter/type/Presenter';
import { CM0025_1Api } from './ServiceImpl';

const CM0025_1Service = (cm0025_1: CM0025_1Api) => {
    const getReSummarySubmittedOutsourceOffsetCost = async (params: Params): Promise<any> => {
        try {
            return await cm0025_1.getReSummarySubmittedOutsourceOffsetCost(params);
        } catch (error) {
            throw error;
        }
    };

    return {
        getReSummarySubmittedOutsourceOffsetCost,
    };
};

export default CM0025_1Service;
