// Component
import { Empty, Input } from 'antd';
import MiniPaginationComponent from '../../../../../common/components/pagination/MiniPaginationComponent';
import UnitTwo from './UnitTwo';
import UnitOne from './UnitOne';

// Media
import '../../../../../assets/styles/AOP/AOP001/content.css';
import InfoIcon from '../../../../../assets/images/aop_icon_info.svg';
import SearchIcon from '../../../../../assets/images/ord_search.svg';

// Service
import { AOP004Handler } from '../handler/HandlerImpl';
import { SWITCH_TAB_ONE, SWITCH_TAB_TWO } from '../../contants';
import React from 'react';
import COMMON from '../../../../../common/constants/COMMON';
import helpers from '../../../../../common/helpers/common';
import MESSAGE from '../../../../../common/constants/MESSAGE';
import { DefaultAvatarComponent } from '../../../../../common/components/default-avatar';
import { SYSTEM_ROLES } from '../../../../../common/constants/AUTHORIZATION';

type props = {
    handler: AOP004Handler;
    groupRoleId: string;
    groupRoleName: string;
    page: string;
    keyword: string;
    tabKey: string;
};

const ContentUnit = ({ handler, groupRoleId, groupRoleName, page, keyword, tabKey }: props) => {
    React.useEffect(() => {
        handler.init();

        if (groupRoleId) {
            handler.getMemRoleList({
                roleId: groupRoleId,
                page: page,
                keyword: keyword,
                tab: tabKey,
            });
        }
    }, [page, keyword, tabKey]);

    return (
        <div className="aop--001__content">
            <div className="aop--001__sidebar">
                <div className="aop--001__sidebar--filter">
                    <div className="aop--001__sidebar--filter-text">役職名</div>
                    <div className="aop--001__sidebar--filter-heading">{groupRoleName}</div>
                    <div className="aop--001__sidebar--list-title">
                        <div className="aop--001__sidebar--list-text">メンバー</div>
                        <div className="aop--001__sidebar--list-text">
                            {handler.totalMember}人
                        </div>
                    </div>
                    <Input
                        className="aop--001__sidebar--filter-search"
                        prefix={<img src={SearchIcon} alt="search member" />}
                        placeholder="社員名を入力"
                        defaultValue={keyword}
                        onChange={(e: any) => handler.onSearch(e)}
                    />
                </div>
                <div className="aop--001__sidebar--list">
                    {handler.members.length !== 0 ? (
                        <div className="aop--001__sidebar--list-member">
                            {handler.members.map((item, idx) => {
                                const isRoleAdmin = [SYSTEM_ROLES.ADMIN_SYSTEM, SYSTEM_ROLES.ADMIN].includes(
                                    item?.role?.code ?? ''
                                );
                                return (
                                    <div
                                        className="aop--001__sidebar--list-member-unit"
                                        key={idx}
                                    >
                                        <div className="aop--001__sidebar-lmb-unit-wrapper">
                                            <DefaultAvatarComponent
                                                isAdmin={(isRoleAdmin)}
                                                avatar={item?.avatar ?? undefined}
                                                value={(item?.fullName ?? "")}
                                                size={32}
                                            />
                                            <div className="aop--001__sidebar-lmb-text">
                                                {helpers.getShortTextWithToolTip(
                                                    item?.fullName ?? '',
                                                    7,
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    ) : (
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={MESSAGE.MESSAGE_NO_DATA}
                        />
                    )}
                </div>
                <div className="aop--001__sidebar-pagination">
                    {handler.members.length !== 0 ? (
                        <MiniPaginationComponent
                            total={handler.totalMember}
                            page={Number(page)}
                            size={COMMON.DEFAULT_SIZE}
                            setPage={handler.onChangePage}
                        />
                    ) : null}
                </div>
            </div>
            <div className="aop--001__unit">
                <div className="aop--001__unit-title">
                    <div className="aop--001__unit-title--text">権限</div>
                    <div className="aop--001__unit-title--info">
                        <img src={InfoIcon} alt="icon info" onClick={() => handler.openModalH4()} />
                    </div>
                </div>
                <div className="aop--001__unit-tab">
                    <div className="aop--001__unit-tab-switch">
                        <div
                            className={`${handler.tabKey === SWITCH_TAB_ONE
                                ? 'aop--001__unit-tab-sw1'
                                : 'aop--001__unit-tab-sw2'
                                }`}
                            onClick={() => handler.switchTab(SWITCH_TAB_ONE)}
                        >
                            案件範囲
                        </div>
                        <div
                            className={`${handler.tabKey !== SWITCH_TAB_ONE
                                ? 'aop--001__unit-tab-sw1'
                                : 'aop--001__unit-tab-sw2'
                                }`}
                            onClick={() => handler.switchTab(SWITCH_TAB_TWO)}
                        >
                            システム全体
                        </div>
                    </div>
                    {handler.tabKey === SWITCH_TAB_ONE ? (
                        <>
                            <UnitOne handler={handler} />
                        </>
                    ) : (
                        <>
                            <UnitTwo handler={handler} />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ContentUnit;
