import React from 'react';
import { MoneyConstructionInformationResults } from '../../entity/Entity';
import COMMON from '../../../../../common/constants/COMMON';
import moment from 'moment';
import { calculateEstimate } from '../../../CM005/helper';

import '../../../../../assets/styles/CM/cm004.css';

type props = {
    data?: MoneyConstructionInformationResults | null;
};

const DescriptionComponent = ({ data }: props) => {
    return (
        <main className="w-100-percent cm004-main-description">
            <div className="w-100-percent cm004-description-item">
                <div
                    className="center-item cm004-description-label "
                    style={{ background: '#F0F0F0', borderBottom: '1px solid #DAE2EE' }}
                >
                    <span className="description-title">工事名称</span>
                </div>
                <div
                    className="center-item cm004-description-content"
                    style={{ background: '#FAFAFA', borderBottom: '1px solid #DAE2EE' }}
                >
                    <span className="description-content">{data?.constructionName ?? ''}</span>
                </div>
            </div>
            <div className="w-100-percent cm004-description-item">
                <div
                    className="center-item cm004-description-label"
                    style={{ background: '#F0F0F0', borderBottom: '1px solid #DAE2EE' }}
                >
                    <span className="description-title">工事番号</span>
                </div>
                <div
                    className="center-item cm004-description-content"
                    style={{ background: '#FFFFFF', borderBottom: '1px solid #DAE2EE' }}
                >
                    <span className="description-content">{data?.constructionCode ?? ''}</span>
                </div>
            </div>
            <div className="w-100-percent cm004-description-item">
                <div
                    className="center-item cm004-description-label"
                    style={{ background: '#F0F0F0', borderBottom: '1px solid #DAE2EE' }}
                >
                    <span className="description-title">見積番号</span>
                </div>
                <div
                    className="center-item cm004-description-content"
                    style={{ background: '#FAFAFA', borderBottom: '1px solid #DAE2EE' }}
                >
                    <span className="description-content"> {data?.estimateCode ?? ''}</span>
                </div>
            </div>
            <div className="w-100-percent cm004-description-item">
                <div
                    className="center-item cm004-description-label"
                    style={{ background: '#F0F0F0', borderBottom: '1px solid #DAE2EE' }}
                >
                    <span className="description-title">契約工事期間</span>
                </div>
                <div
                    className="center-item cm004-description-content"
                    style={{ background: '#FFFFFF', borderBottom: '1px solid #DAE2EE' }}
                >
                    <span className="description-content">
                        {`${
                            data?.startDate
                                ? moment(data?.startDate).format(COMMON.FORMAT_DATE)
                                : ''
                        }
                         ~ 
                         ${data?.endDate ? moment(data?.endDate).format(COMMON.FORMAT_DATE) : ''}`}
                    </span>{' '}
                </div>
            </div>
            <div className="w-100-percent cm004-description-item">
                <div
                    className="center-item cm004-description-label"
                    style={{ background: '#F0F0F0' }}
                >
                    <span className="description-title">契約金額</span>
                </div>
                <div
                    className="center-item cm004-description-content"
                    style={{ background: '#FAFAFA' }}
                >
                    <span className="description-content">
                        {data
                            ? calculateEstimate(data).toLocaleString('en-US', {
                                  maximumFractionDigits: 2,
                              })
                            : 0}
                        円
                    </span>
                </div>
            </div>
        </main>
    );
};
export default DescriptionComponent;
