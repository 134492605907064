import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  setDocumentIdCM0025,
  setDocumentInfoCM0025,
  setIsDisableRetrieveButtonCM0025,
  setVisibleCM0025,
} from "../../../../CM0025/presenter/slice/Slice";
import {
  setDocumentIdCM0027,
  setDocumentInfoCM0027,
  setIsDisableRetrieveButtonCM0027,
  setVisibleCM0027,
} from "../../../../CM0027/presenter/slice/Slice";
import {
  setDocumentIdCM0029,
  setDocumentInfoCM0029,
  setIsDisableRetrieveButtonCM0029,
  setVisibleCM0029,
} from "../../../../CM0029/presenter/slice/Slice";
import {
  setDocumentCM0031,
  setVisibleCM0031,
} from "../../../../CM0031/presenter/slice/Slice";
import {
  setInputCM0033,
  setVisibleCM0033,
} from "../../../../CM0033/presenter/slice/Slice";
import { CM0042ServiceImpl } from "../../usecase/ServiceImpl";
import { DEFAULT_PARAM } from "../../../wrapper/presenter/handler/Handler";
import { setLoading } from "../../../../../../common/slice/CommonSlice";
import moment from "moment";
import COMMON, {
  STATUS_CM_CHILD_TABLE,
} from "../../../../../../common/constants/COMMON";
import {
  setDocumentIdCM0021,
  setDocumentInfoCM0021,
  setIsDisableRetrieveButtonCM0021,
  setVisibleCM0021,
} from "../../../../CM0021/presenter/slice/Slice";
import {
  setDocumentIdCM0023,
  setDocumentInfoCM0023,
  setIsDisableRetrieveButtonCM0023,
  setVisibleCM0023,
} from "../../../../CM0023/presenter/slice/Slice";
import helpers from "../../../../../../common/helpers/common";

const PARAMS = {
  from: "from4",
  to: "to4",
  clear: "clear4",
  status: "status4",
  keyword: "keyword4",
};

type Data = {
  material: any[];
  outsource: any[];
  labor: any[];
  indirect: any[];
  receive: any[];
};

const DEFAULT_DATA: Data = {
  material: [],
  outsource: [],
  labor: [],
  indirect: [],
  receive: [],
};

const TYPE_FILE = {
  company: "re_summary_document_company",
  project: "re_summary_document_project",
};

export const TYPE_TABLE = {
  MATERIAL_COST: "material",
  OUTSOURCE_COST: "outsource",
  LABOR_COST: "labor",
  INDIRECT_COST: "indirect",
  RECEIVE_COST: "receive",
};

const CM042Handler = (service: CM0042ServiceImpl) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const sortType1 = searchParams.get("sortType41");
  const sortType2 = searchParams.get("sortType42");
  const sortType3 = searchParams.get("sortType43");
  const sortType4 = searchParams.get("sortType44");
  const sortType5 = searchParams.get("sortType45");
  const from = searchParams.get(PARAMS.from) ?? DEFAULT_PARAM.from;
  const to = searchParams.get(PARAMS.to) ?? DEFAULT_PARAM.to;
  const clear = searchParams.get(PARAMS.clear);
  const status = searchParams.get(PARAMS.status);
  const keyword = searchParams.get(PARAMS.keyword);

  const [data, setData] = useState(DEFAULT_DATA);
  const [visibleRejectView, setVisibleRejectView] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [refresh, setRefresh] = useState({
    material: false,
    outsource: false,
    labor: false,
    indirect: false,
    receive: false,
  });

  const getMaterialSubmittedList = async () => {
    try {
      dispatch(setLoading(true));
      const response = await service.getMaterialSubmittedList();
      if (response?.results) {
        setData((prevState) => {
          return { ...prevState, material: response.results };
        });
      } else
        setData((prevState) => {
          return { ...prevState, material: [] };
        });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getOutsourceSubmittedList = async () => {
    try {
      dispatch(setLoading(true));
      const response = await service.getOutsourceSubmittedList();
      if (response?.results) {
        setData((prevState) => {
          return { ...prevState, outsource: response.results };
        });
      } else
        setData((prevState) => {
          return { ...prevState, outsource: [] };
        });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getLaborSubmittedList = async () => {
    try {
      dispatch(setLoading(true));
      const response = await service.getLaborSubmittedList();
      if (response?.results) {
        setData((prevState) => {
          return { ...prevState, labor: response.results };
        });
      } else
        setData((prevState) => {
          return { ...prevState, labor: [] };
        });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getIndirectSubmittedList = async () => {
    try {
      dispatch(setLoading(true));
      const response = await service.getIndirectSubmittedList();
      if (response?.results) {
        setData((prevState) => {
          return { ...prevState, indirect: response.results };
        });
      } else
        setData((prevState) => {
          return { ...prevState, indirect: [] };
        });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getReceiveMoneySubmittedList = async () => {
    try {
      dispatch(setLoading(true));
      const response = await service.getReceiveMoneySubmittedList();
      if (response?.results) {
        setData((prevState) => {
          return { ...prevState, receive: response.results };
        });
      } else
        setData((prevState) => {
          return { ...prevState, receive: [] };
        });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const funcSort = (sortBy: string, sortType: string) => {
    switch (sortBy) {
      case "table1":
        searchParams.set("sortType41", sortType);
        break;
      case "table2":
        searchParams.set("sortType42", sortType);
        break;
      case "table3":
        searchParams.set("sortType43", sortType);
        break;
      case "table4":
        searchParams.set("sortType44", sortType);
        break;
      case "table5":
        searchParams.set("sortType45", sortType);
        break;
    }
    setSearchParams(searchParams);
  };

  const funcClickDocumentMaterialCost = (document: any) => {
    const modalInfo = {
      documentTitle: document?.fileName ?? "",
      createdDate: helpers.formatDatePresenter(
        document?.createdAt,
        COMMON.FORMAT_DATE_CM
      ),
      targetPeriod: helpers.toTargetPeriodFormatYYYYMM(
        document.startDate,
        document.endDate
      ),
    };
    const isRetrieved =
      document?.status?.code === STATUS_CM_CHILD_TABLE.MONEY_REJECT.CODE
      || document?.status?.code === STATUS_CM_CHILD_TABLE.MONEY_APPROVED.CODE
    if (document.documentType === TYPE_FILE.company) {
      dispatch(setDocumentInfoCM0021(modalInfo));
      dispatch(setDocumentIdCM0021(document.id));
      dispatch(setIsDisableRetrieveButtonCM0021(isRetrieved));
      dispatch(setVisibleCM0021(true));
    } else {
      dispatch(setDocumentInfoCM0023(modalInfo));
      dispatch(setDocumentIdCM0023(document.id));
      dispatch(setIsDisableRetrieveButtonCM0023(isRetrieved));
      dispatch(setVisibleCM0023(true));
    }
  };
  const funcClickDocumentOutsourcingCost = (document: any) => {
    const modalInfo = {
      documentTitle: document?.fileName ?? "",
      createdDate: helpers.formatDatePresenter(
        document?.createdAt,
        COMMON.FORMAT_DATE_CM
      ),
      targetPeriod: helpers.toTargetPeriodFormatYYYYMM(
        document.startDate,
        document.endDate
      ),
    };
    const isRetrieved = document?.status?.code === STATUS_CM_CHILD_TABLE.MONEY_REJECT.CODE || document?.status?.code === STATUS_CM_CHILD_TABLE.MONEY_APPROVED.CODE

    if (document.documentType === TYPE_FILE.company) {
      dispatch(setDocumentInfoCM0025(modalInfo));
      dispatch(setDocumentIdCM0025(document.id));
      dispatch(setIsDisableRetrieveButtonCM0025(isRetrieved));
      dispatch(setVisibleCM0025(true));
    } else {
      dispatch(setDocumentInfoCM0027(modalInfo));
      dispatch(setDocumentIdCM0027(document.id));
      dispatch(setIsDisableRetrieveButtonCM0027(isRetrieved));
      dispatch(setVisibleCM0027(true));
    }
  };
  const funcClickDocumentLaborCost = (document: any) => {
    const modalInfo = {
      documentTitle: document?.fileName ?? "",
      createdDate: helpers.formatDatePresenter(
        document?.createdAt,
        COMMON.FORMAT_DATE_CM
      ),
      targetPeriod: helpers.toTargetPeriodFormatYYYYMM(
        document.startDate,
        document.endDate
      ),
    };
    const isRetrieved =
      document?.status?.code === STATUS_CM_CHILD_TABLE.MONEY_REJECT.CODE
      || document?.status?.code === STATUS_CM_CHILD_TABLE.MONEY_APPROVED.CODE
    dispatch(setVisibleCM0029(true));
    dispatch(setDocumentIdCM0029(document.id));
    dispatch(setIsDisableRetrieveButtonCM0029(isRetrieved));
    dispatch(setDocumentInfoCM0029(modalInfo));
  };
  const funcClickDocumentIndirect = (document: any) => {
    dispatch(setVisibleCM0031(true));
    dispatch(
      setDocumentCM0031({
        id: document?.id,
        submittedDate: document?.submittedDate,
        from: document.startDate,
        to: document.endDate,
        documentName: document?.fileName,
        status: document?.status?.code,
        documentType: document?.documentType,
      })
    );
  };
  const funcClickDocumentBillingAmount = (document: any) => {
    dispatch(setVisibleCM0033(true));
    dispatch(
      setInputCM0033({
        documentId: document?.id,
        documentName: document?.fileName,
        documentType: document?.documentType,
        submittedDate: document?.submittedDate
          ? moment(document.submittedDate).format(COMMON.FORMAT_DATE_CI)
          : "",
        startTimeFilter: document.startDate
          ? moment(document.startDate).format(COMMON.FORMAT_DATE_JA_MONTH)
          : "",
        endTimeFilter: document.endDate
          ? moment(document.endDate).format(COMMON.FORMAT_DATE_JA_MONTH)
          : "",
        documentStatus: document.status?.code,
      })
    );
  };

  const handleDataView = (
    data: any[],
    sortType: string | null,
    status: string | null,
    from: string | null,
    to: string | null,
    keyword: string | null,
    clear: string | null
  ): any[] => {
    if (!data) return [];
    let results = [...data];
    if (sortType) {
      if (sortType === "ASC") {
        results = [...data].sort((a, b) => {
          const A = moment(a.submittedDate);
          const B = moment(b.submittedDate);
          return A.valueOf() - B.valueOf();
        });
      } else {
        results = [...data].sort((a, b) => {
          const A = moment(a.submittedDate);
          const B = moment(b.submittedDate);
          return B.valueOf() - A.valueOf();
        });
      }
    } else {
      results = [...data].sort((a, b) => {
        const A = moment(a.createdAt);
        const B = moment(b.createdAt);
        return B.valueOf() - A.valueOf();
      });
    }
    if (status) {
      results = [...results].filter(
        (element) => element.status.id === Number(status)
      );
    }

    if (from && to && !clear) {
      results = [...results].filter(
        (element) =>
          (moment(element.submittedDate) >= moment(from, COMMON.FORMAT_DATE2) ||
            moment(element.submittedDate).format(COMMON.FORMAT_DATE2) ===
            from) &&
          (moment(element.submittedDate) <= moment(to, COMMON.FORMAT_DATE2) ||
            moment(element.submittedDate).format(COMMON.FORMAT_DATE2) === to)
      );
    }

    if (keyword) {
      results = [...results].filter((element) =>
        element.fileName?.includes(keyword)
      );
    }
    // handle no
    results = results.map((element, index) => {
      return {
        ...element,
        no: index + 1,
        originId:
          element?.documentDirectMaterialCostId ??
          element?.documentDirectOutsourceCostId ??
          element?.documentDirectLaborCostId ??
          element?.documentIndirectCostId ??
          0,
      };
    });
    return results;
  };

  const funcAfterSuccess = (type: string) => {
    switch (type) {
      case TYPE_TABLE.MATERIAL_COST:
        setRefresh((prevState) => {
          return { ...prevState, material: !prevState.material };
        });
        break;
      case TYPE_TABLE.OUTSOURCE_COST:
        setRefresh((prevState) => {
          return { ...prevState, outsource: !prevState.outsource };
        });
        break;
      case TYPE_TABLE.LABOR_COST:
        setRefresh((prevState) => {
          return { ...prevState, labor: !prevState.labor };
        });
        break;
      case TYPE_TABLE.INDIRECT_COST:
        setRefresh((prevState) => {
          return { ...prevState, indirect: !prevState.indirect };
        });
        break;
      case TYPE_TABLE.RECEIVE_COST:
        setRefresh((prevState) => {
          return { ...prevState, receive: !prevState.receive };
        });
        break;
    }
  };

  return {
    sortType1,
    sortType2,
    sortType3,
    sortType4,
    sortType5,
    from,
    to,
    clear,
    status,
    data,
    keyword,
    rejectReason,
    visibleRejectView,
    refresh,

    funcSort,
    funcClickDocumentMaterialCost,
    funcClickDocumentOutsourcingCost,
    funcClickDocumentLaborCost,
    funcClickDocumentIndirect,
    funcClickDocumentBillingAmount,
    getMaterialSubmittedList,
    getOutsourceSubmittedList,
    getLaborSubmittedList,
    getIndirectSubmittedList,
    getReceiveMoneySubmittedList,
    handleDataView,
    setVisibleRejectView,
    setRejectReason,
    funcAfterSuccess,
  };
};

export default CM042Handler;
