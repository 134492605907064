import { ProjectAPIImpl } from './ServiceImpl';

const ProjectService = (api: ProjectAPIImpl) => {
    const getListProject = async (params: {
        page: number;
        size: number;
        statusId?: number;
        keyword?: string;
    }) => {
        try {
            return await api.getListProject(params);
        } catch (error) {
            throw error;
        }
    };
    return {
        getListProject,
    };
};

export default ProjectService;
