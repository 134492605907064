import React from 'react';
import BreadcrumbComponent from '../../../../../common/components/breadcrumb/BreadcrumbComponent';
import MenuWrapperComponent from '../../../../../common/components/menu-wrapper';
import { T01HandlerImpl } from '../handler/HandlerImpl';
import TPopover from './TPopover';
import HomeSvg from '../../../../../assets/icons/home.svg';

interface props {
  handler: T01HandlerImpl;
}

const BREADCRUMB_T01: any[] = [
  {
    title: '設定',
    url: '',
    icon: (
      <img style={{ paddingBottom: 2, paddingRight: 8 }} alt="" src={HomeSvg} />
    ),
  },
  {
    title: '消費税率',
    url: '',
    icon: '',
  },
];

const MainUI = ({ handler }: props) => {
  React.useEffect(() => {
    handler.getTax();
  }, [handler.refresh]);

  return (
    <MenuWrapperComponent chosenKey={78} openKey={7}>
      <div className="sn01-wrapper">
        <BreadcrumbComponent data={BREADCRUMB_T01} />
        <h2 className="sn01-header">消費税率</h2>
        <div className="sn01-body">
          <div className="t01__wrapper">
            <div className="t01__item--header">税率設定</div>
            <div className="t01__item t01__border-bottom">
              <div className="t01__item--left">区分</div>
              <div className="t01__item--right">%</div>
            </div>
            <div className="t01__item">
              <div className="t01__item--left">消費税率</div>
              <div className="t01__item--right t01__fw400">
                <div>{handler.value}</div>
                <TPopover handler={handler} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </MenuWrapperComponent>
  );
};

export default MainUI;
