import moment from 'moment';
import COMMON, { STATUS_CM_CHILD_TABLE } from '../../../../common/constants/COMMON';
import { DocumentResponse, TableData } from './entity/Entity';

const convertTableData = (data: any[]): TableData[] => {
    let output: TableData[] = [];

    data.forEach((item: any, idx: number) => {
        const obj: TableData = {
            no: idx + 1,
            id: item?.id ?? 0,
            constructionId: item?.constructionId ?? 0,
            originId:
                item?.documentDirectMaterialCostId ??
                item?.documentDirectOutsourceCostId ??
                item?.documentDirectLaborCostId ??
                item?.documentIndirectCostId ??
                0,
            fileName: item?.fileName ?? '',
            statusId: item?.status?.id ?? 0,
            statusCode: item?.status?.code ?? '',
            statusName: item?.status?.name ?? '',
            submittedDate: item?.submittedDate ?? '',
            approver:
                (item?.user.fullName ?? '') +
                (item?.user?.branchName ? `（${item?.user?.branchName}）` : ''),
            dataSummary: item?.dataSummary ?? {},
            reason: item.reason,
        };

        output.push(obj);
    });

    return output;
};

const formatDateView = (stringDate: string): string => {
    return moment(stringDate).format(COMMON.FORMAT_DATE_CI);
};

const reAssignNoTable = (data: TableData[]): TableData[] => {
    const output: TableData[] = [];

    data.forEach((item: TableData, idx: number) => {
        const obj: TableData = {
            ...item,
            no: idx + 1,
        };
        output.push(obj);
    });

    return output;
};

export { convertTableData, formatDateView, reAssignNoTable };
