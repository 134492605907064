import ErrorNotification from "../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../common/constants/MESSAGE";
import { DB003Api } from "./ServiceImpl";

const DM003Service = (api: DB003Api) => {
  const getTurnoverAmount = async (params: {
    projectId: number;
    from: string;
    to: string;
  }) => {
    try {
      const response = await api.getTurnoverAmount(params);
      return response?.data;
    } catch (error: any) {
      ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
      return null;
    }
  };

  return {
    getTurnoverAmount,
  };
};

export default DM003Service;
