import CCUS061Api from "./infrastructure/api/Api";
import CCUS061Handler from "./presenter/handler/Handler";
import MainUI from "./presenter/ui/UI";
import CCUS061Service from "./usecase/Service";
import "../../../assets/styles/CCUS/ccus002.css";
import "../../../assets/styles/CCUS/ccus06.css";

const CCUS061Component = () => {
  const api = CCUS061Api();
  const service = CCUS061Service(api);
  const handler = CCUS061Handler(service);

  return <MainUI handler={handler}></MainUI>;
};

export default CCUS061Component;
