import { memo } from "react";
import { Button, Input, Modal, Radio, Form, Spin, Tooltip } from "antd";
import { CR002ModalCompanyHandler } from "../handler/HandlerImpl";
import { useEffect } from "react";

import "../../../../../assets/styles/PM/PM024.css";
import REGEX from "../../../../../common/constants/REGEX";
import MESSAGE from "../../../../../common/constants/MESSAGE";
import InfiniteScroll from "react-infinite-scroll-component";

import PlusPurple from "../../../../../assets/icons/plus-purpose.svg";
import IconSearch from "../../../../../assets/icons/search-icon.svg";
import IconDeleteRed from "../../../../../assets/icons/delete-red.svg";
import IconCheck from "../../../../../assets/icons/akar-icons_circle-check-fill-purple.svg";
import EmptyData from "../../../../../assets/icons/empty-data.svg";
import IconCloseModal from "../../../../../assets/icons/icon-close-modal.svg";
import { onBlurTrimFormItem } from "../../../../../common/helpers/typeInput";
import { COMPANY_COLAB_STATUS } from "../../../../../common/constants/COMMON";
import { AUTHORIZATION_CODE } from "../../../../../common/constants/AUTHORIZATION";
import { RootState } from "../../../../../store";
import { useSelector } from "react-redux";

interface props {
  handler: CR002ModalCompanyHandler;
  handleConfirm?: () => void;
  handleCancel?: () => void;
}
function CR002ModalCompany({ handler, handleCancel, handleConfirm }: props) {
  const permissions = useSelector(
    (state: RootState) => state.auth.authData?.permission
  );

  useEffect(() => {
    if (handler.isViewChooseCompany) {
      const params = {
        page: handler.page,
        size: handler.size,
        keyword: handler.keyword ? handler.keyword : undefined,
        sortBy: "createdAt",
        sortType: "asc",
      };
      if (handler.type === "subcontractor") {
        handler.getCompanyList(params);
      } else {
        handler.getMaterialSupplier(params);
      }
    }
  }, [
    handler.page,
    handler.keyword,
    handler.isViewChooseCompany,
    handler.refCompany,
    handler.refreshModal,
  ]);

  return (
    <Modal
      title={handler.type === "subcontractor" ? "外注業者選択" : "材料業者選択"}
      className="cr001-history-modal"
      width={"70%"}
      visible={handler.isViewChooseCompany}
      onCancel={handleCancel}
      closeIcon={<img alt="" src={IconCloseModal} />}
      centered
      footer={
        <>
          <Button
            size="large"
            className="button-purple"
            onClick={handleConfirm}
            style={{ letterSpacing: -1 }}
          >
            確認
          </Button>
          <Button
            size="large"
            onClick={!handler.loading ? handleCancel : () => {}}
            className="btn-text-color"
          >
            キャンセル
          </Button>
        </>
      }
    >
      <Spin spinning={handler.loading}>
        <Form form={handler.form} style={{ width: "100%" }} name="pm012-search">
          <Form.Item name={"keyword"} style={{ margin: 0 }}>
            <Input
              size="large"
              allowClear
              prefix={<img alt="" src={IconSearch} />}
              className="pm012-input-search pm018-search pm012-input-search-company"
              placeholder="検索したい情報を入力してください（例：会社名、メールアドレス）"
              onChange={handler.onSearchCompanyChange}
            />
          </Form.Item>
        </Form>

        <div className="cr001-external pm018-company">
          <div className="cr001-external-header">
            <span className="pm018-external-header-left">会社名</span>
            <span className="pm018-external-header-center">メールアドレス</span>
            {handler.type === "subcontractor" ? (
              <span className="pm018-external-header-right">ステータス</span>
            ) : null}
          </div>
          <div
            className="cr001-external-body pm018-company-body"
            ref={handler.refCompany}
          >
            {handler.listCompany?.length > 0 ? (
              <Radio.Group
                onChange={handler.handleChangeChosenCompany}
                value={handler.chosenCompany}
              >
                <InfiniteScroll
                  dataLength={handler.listCompany?.length}
                  next={handler.handleScrollListCompany}
                  hasMore={true}
                  height={"40vh"}
                  loader={null}
                >
                  {handler.listCompany?.map((company: any) => {
                    return (
                      <div
                        className="cr001-external-item pm018-item"
                        key={`name${company.id}`}
                      >
                        <Radio value={company?.id}>
                          <div className="pm018-item-radio">
                            <span className="pm018-item-radio-name">
                              {company.name}
                            </span>
                            <div className="pm018-item-radio-email">
                              {company.email}
                            </div>
                            {handler.type === "subcontractor" ? (
                              <div
                                className={`pm018-text-status ${
                                  company.status?.code ===
                                  COMPANY_COLAB_STATUS.ACTIVE_PLAN
                                    ? "pm018-text-status-activate"
                                    : "pm018-text-status-deactivate"
                                }`}
                                key={`email${company?.id}`}
                              >
                                {company?.status?.name}
                              </div>
                            ) : null}
                          </div>
                        </Radio>
                      </div>
                    );
                  })}
                </InfiniteScroll>
              </Radio.Group>
            ) : (
              <div className="pm012-empty-data">
                <img src={EmptyData} alt="" />
              </div>
            )}
            {handler.isAddCompany ? (
              <div className="cr001-external-item cr001-company-item-input pm024-bg-white">
                <Form
                  name="company-colab-add"
                  className="pm024-form"
                  onFinish={handler.handleSaveAddCompany}
                  form={handler.form}
                >
                  <Form.Item
                    className="pm018-modal-form-item-name"
                    name="name"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (value && value !== "") {
                            // CHECK INPUT VIETNAMESE
                            if (!REGEX.NOT_VIETNAM.test(value)) {
                              return Promise.reject(
                                new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                              );
                            }
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(MESSAGE.MESSAGE_REQUIRED_FIELD)
                          );
                        },
                      }),
                    ]}
                  >
                    <Input
                      placeholder="会社名を入力してください"
                      className="pm018-company-name-input"
                      maxLength={50}
                      size="large"
                      onBlur={() => onBlurTrimFormItem(handler.form, "name")}
                      onPressEnter={(e) => e.preventDefault()}
                    />
                  </Form.Item>
                  <Form.Item
                    className="pm018-modal-form-item"
                    name="email"
                    rules={[
                      {
                        pattern: new RegExp(REGEX.EMAIL_COMPANY),
                        message: MESSAGE.MESSAGE_EMAIL_INVALID,
                      },
                    ]}
                  >
                    <Input
                      placeholder="メールアドレスを入力してください"
                      className="pm018-company-email-input"
                      maxLength={50}
                      size="large"
                      onPressEnter={(e) => e.preventDefault()}
                    />
                  </Form.Item>
                  <div className="pm018-action-btn">
                    <button type="submit">
                      <img alt="" src={IconCheck} />
                    </button>
                    <button
                      type="button"
                      onClick={() => handler.handleDeleteAddCompanies()}
                    >
                      <img alt="" src={IconDeleteRed} />
                    </button>
                  </div>
                </Form>
              </div>
            ) : null}
          </div>
        </div>

        {!handler?.isAddCompany &&
        permissions?.includes(AUTHORIZATION_CODE.M7) &&
        permissions?.includes(AUTHORIZATION_CODE.M6) ? (
          <div
            className="pm018-btn-bonus-company button-style"
            onClick={handler.handleAddCompanies}
          >
            <Tooltip
              title={
                handler.type === "subcontractor"
                  ? "勤怠管理と収支管理へデータ連携するための正規データとして参照されます"
                  : "収支管理へデータ連携するための正規データとして参照されます"
              }
              placement="bottomLeft"
              overlayInnerStyle={{
                width: 254,
                textAlign: "center",
                fontSize: 12,
                fontWeight: 400,
                background: "#222222",
                borderRadius: 3,
                padding: 10,
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  className="pm012-btn-add-company"
                  style={{ marginRight: 8 }}
                >
                  <img alt="" src={PlusPurple} />
                </div>
                <span style={{ marginTop: 3 }}>マスタ追加 </span>
              </div>
            </Tooltip>
          </div>
        ) : null}
      </Spin>
    </Modal>
  );
}

export default memo(CR002ModalCompany);
