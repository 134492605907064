import { OM005SliceType } from "../type/TypeSlice";
import { createSlice } from "@reduxjs/toolkit";

const initialState: OM005SliceType = {
  data: [],
  progress: 0,
  rowKeySelected: [],
  dataUpload: [],
  showModalResult: false,
  dataModalSuccess: [],
  dataModalFailure: [],
  branchData: [],
  roleData: [],
  roleDefaultValue: 0,
  roleNameDefaultValue: "",
  dataTableView: [],
};

export const om005Slice = createSlice({
  name: "om005",
  initialState,
  reducers: {
    resetOM005: () => initialState,

    setDataTable: (state, action) => {
      state.data = action.payload;
    },

    setProgress: (state, action) => {
      state.progress = action.payload;
    },

    setRowKeySelected: (state, action) => {
      state.rowKeySelected = action.payload;
    },

    setDataUpload: (state, action) => {
      state.dataUpload = action.payload;
    },

    setShowModalResult: (state, action) => {
      state.showModalResult = action.payload;
    },

    setDataModalSuccess: (state, action) => {
      state.dataModalSuccess = action.payload;
    },

    setDataModalFailure: (state, action) => {
      state.dataModalFailure = action.payload;
    },

    setBranchData: (state, action) => {
      state.branchData = action.payload;
    },

    setRoleData: (state, action) => {
      state.roleData = action.payload;
    },

    setRoleDefaultValue: (state, action) => {
      state.roleDefaultValue = action.payload;
    },

    setRoleNameDefaultValue: (state, action) => {
      state.roleNameDefaultValue = action.payload;
    },

    setDataTableView: (state, action) => {
      state.dataTableView = action.payload;
    },
  },
});

export const data = (state: any) => state.om005.data;
export const progress = (state: any) => state.om005.progress;
export const rowKeySelected = (state: any) => state.om005.rowKeySelected;
export const dataUpload = (state: any) => state.om005.dataUpload;
export const showModalResult = (state: any) => state.om005.showModalResult;
export const dataModalSuccess = (state: any) => state.om005.dataModalSuccess;
export const dataModalFailure = (state: any) => state.om005.dataModalFailure;
export const branchData = (state: any) => state.om005.branchData;
export const roleData = (state: any) => state.om005.roleData;
export const roleDefaultValue = (state: any) => state.om005.roleDefaultValue;
export const roleNameDefaultValue = (state: any) =>
  state.om005.roleNameDefaultValue;
export const dataTableView = (state: any) => state.om005.dataTableView;

export const {
  setDataTable,
  setProgress,
  setRowKeySelected,
  resetOM005,
  setDataUpload,
  setShowModalResult,
  setDataModalSuccess,
  setDataModalFailure,
  setRoleData,
  setBranchData,
  setRoleDefaultValue,
  setRoleNameDefaultValue,
  setDataTableView,
} = om005Slice.actions;

export default om005Slice.reducer;
