import React from 'react';

type props = {
    projectName: string;
    rangeDate: string;
};

export const CM0061TitleComponent = React.memo(({ projectName, rangeDate }: props) => {
    return (
        <div className="cm0061-title">
            <div className="column-title">
                <div>
                    <p>案件名</p>
                </div>
                <div>
                    <p>契約工事期間</p>
                </div>
            </div>
            <div className="column-value">
                <div>
                    <p>{projectName}</p>
                </div>
                <div>
                    <p>{rangeDate}</p>
                </div>
            </div>
        </div>
    );
});
