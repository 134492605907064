import { SN01APIImpl } from './ServiceImpl';

const SN01Service = (api: SN01APIImpl) => {
    const getListNotification = async (): Promise<any> => {
        try {
            return await api.getListNotification();
        } catch (error) {
            throw error;
        }
    };

    const settingNotification = async (data: any): Promise<any> => {
        try {
            return await api.settingNotification(data);
        } catch (error) {
            throw error;
        }
    };
    return {
        getListNotification,
        settingNotification,
    };
};

export default SN01Service;
