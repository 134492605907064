import { createSlice } from "@reduxjs/toolkit";
import { NotificationCategory } from "../../entity/Entity";

type SN01SliceType = {
  isVisible: boolean;
  isLoading: boolean;
  workflowCategory: NotificationCategory[];
  chosenCategoryWF: NotificationCategory | null;
};

export const initialState: SN01SliceType = {
  isVisible: false,
  isLoading: false,
  workflowCategory: [],
  chosenCategoryWF: null,
};

export const NotificationWorkflow = createSlice({
  name: "notificationWorkflow",
  initialState,
  reducers: {
    setIsVisibleWorkflow: (state, action) => {
      state.isVisible = action.payload;
    },
    setLoadingWorkflow: (state, action) => {
      state.isLoading = action.payload;
    },
    setWorkflowCategory: (state, action) => {
      state.workflowCategory = action.payload;
    },
    setChosenCategoryWF: (state, action) => {
      state.chosenCategoryWF = action.payload;
    },
  },
});

export const {
  setIsVisibleWorkflow,
  setLoadingWorkflow,
  setWorkflowCategory,
  setChosenCategoryWF,
} = NotificationWorkflow.actions;

export default NotificationWorkflow.reducer;
