import React, { useEffect } from 'react';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, Input, Upload } from 'antd';
import type { UploadFile, UploadListType } from 'antd/es/upload/interface';
import LightCamera from '../../../../assets/icons/light-camera.svg';
import XRedIcon from '../../../../assets/icons/small-x-icon.svg';
import { HandlerImpl } from '../handler/HandlerImpl';
import { UploadAvatarImpl } from '..';

export interface Props extends UploadAvatarImpl {
    handler: HandlerImpl;
};

export const MainUI = ({ handler, getBinary, initialImageUrl, style, form }: Props) => {
    const { loading, imageUrl, beforeUpload, dummyRequest, handleChange, setImageUrl } = handler

    useEffect(() => {
        if (initialImageUrl) {
            setImageUrl(initialImageUrl)
            form.setFieldValue('linkAvatar', initialImageUrl)
        }
    }, [initialImageUrl])

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );
    const normFile = (e: any) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };
    return (
        <div style={{ ...style, display: 'flex', position: 'relative' }}>
            <Form.Item
                name="avatar"
                labelAlign="right"
                colon={false}
                className="form-item-label "
                label={<></>}

                valuePropName="fileList"
                getValueFromEvent={normFile}
            >
                <Upload
                    listType="picture-card"
                    className={`upload-component ${imageUrl ? '' : 'upload-component-image'}`}
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    onChange={(info) => handleChange(info, getBinary)}
                    customRequest={dummyRequest}
                    iconRender={(file: UploadFile, listType?: UploadListType) => (
                        <img alt="" src={LightCamera} />
                    )}
                >
                    {imageUrl ? (
                        <img
                            src={imageUrl}
                            alt="avatar"
                            style={{ width: 84, height: 84, borderRadius: '50%', objectFit: 'cover' }}
                        />
                    ) : (
                        uploadButton
                    )}
                </Upload>
            </Form.Item>
            <Form.Item name="linkAvatar" hidden>
                <Input />
            </Form.Item>
            {
                imageUrl ?
                    <div className='button-style'
                        style={{
                            borderRadius: '50%',
                            position: 'absolute',
                            top: 0,
                            left: 70,
                            background: "#fff",
                            border: '1px solid rgba(255, 9, 9, 1)'
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            form.resetFields(['avatar', 'linkAvatar'])
                            setImageUrl(undefined)
                        }}
                    >
                        <img alt='' src={XRedIcon} />
                    </div> :
                    <></>

            }
        </div>

    );
};

