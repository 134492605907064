import React, { useEffect } from "react";
import { Table, TableColumnsType, Tooltip } from "antd";

import { HanlderAttendanceImpl } from "../../../handler/HandlerImpl";
import { DateColumn } from "../../../../entity/Entity";
import { convertToDataSource } from "../helper";
import DoubleCheckUI from "./DoubleCheck";

import ExpandedIconSvg from "../../../../../../../assets/icons/expanded-icon.svg";
import ClosedIconSvg from "../../../../../../../assets/icons/closed-icon.svg";
import TooltipText from "../../../../../../../common/components/tooltip-text/TooltipText";
interface Props {
  handler: HanlderAttendanceImpl;
  contractorConstructionId: number;
  userId: number;
  columnsTable: DateColumn[];
  constructionId: number;
  attendance: any;
}

const AttendanceUI = ({
  handler,
  contractorConstructionId,
  userId,
  columnsTable,
  constructionId,
  attendance,
}: Props) => {
  useEffect(() => {
    handler.setAttendance(attendance);
  }, [JSON.stringify(attendance)]);

  useEffect(() => {
    const convertedData = convertToDataSource(
      userId,
      contractorConstructionId,
      handler.attendance?.timesheet ?? [],
      columnsTable,
      handler.attendance?.laborCost ?? 0,
      constructionId,
      handler.openStreetMap
    );
    handler.setDataSource(convertedData);
  }, [
    JSON.stringify(handler.attendance?.timesheet ?? []),
    JSON.stringify(columnsTable),
  ]);

  const getColumns = (tableColumns: DateColumn[]) => {
    const columns: TableColumnsType<any> = [
      {
        title: "",
        key: "attendanceName",
        width: "23.5%",
        render: (record) => {
          return record.name;
        },
        onCell: () => {
          return {
            className: "border-left-table",
          };
        },
      },
    ];
    for (let i = 0; i < tableColumns.length; i++) {
      columns.push({
        title: "",
        key: i,
        width: `${71.3 / tableColumns.length}%`,
        render: (record) => {
          const value = record[`${tableColumns[i].value}`]
          return <div style={{ width: 55, height: '100%' }}>
            {typeof value === 'string' ? <TooltipText content={value} /> : value}
          </div>
        }
      });
    }
    columns.push({
      title: "",
      key: "empty1",
      width: "5.47%",
    });
    columns.push({
      title: "",
      key: "collapsed",
      render: (record) => {
        return record.id === 6 || record.id === 7 ? (
          handler.isCollapsedAttendance(record) ? (
            <div
              style={{ cursor: "pointer" }}
              onClick={(e: any) =>
                handler.onChangeCollapseRowAttendance(record, false)
              }
            >
              <img alt="" src={ExpandedIconSvg} />
            </div>
          ) : (
            <div
              style={{ cursor: "pointer" }}
              onClick={(e: any) =>
                handler.onChangeCollapseRowAttendance(record, true)
              }
            >
              <img alt="" src={ClosedIconSvg} />
            </div>
          )
        ) : (
          <></>
        );
      },
    });
    return columns;
  };

  return (
    <Table
      onRow={(record, rowIndex) => {
        if (record?.id > 5)
          return {
            onClick: (event) =>
              handler.isCollapsedAttendance(record)
                ? handler.onChangeCollapseRowAttendance(record, false)
                : handler.onChangeCollapseRowAttendance(record, true), // click row
          };
        return {};
      }}
      rowClassName={(record) => {
        return record?.id > 5 ? "button-style" : "";
      }}
      bordered={false}
      className="am-main-table-construction attendance-table attendance-table-row-white attendance-table-border"
      columns={getColumns(columnsTable)}
      dataSource={handler.dataSource}
      pagination={false}
      expandable={{
        showExpandColumn: false,
        expandedRowKeys: handler.expandKeyAttendance,
        expandedRowRender: (record: any) => (
          <DoubleCheckUI
            companyData={record.data}
            columnsTable={columnsTable}
          />
        ),
      }}
    />
  );
};

export default AttendanceUI;
