import React from "react";
import IconSortUp from "../../../assets/icons/bx_bxs-up-arrow.svg";

type props = {
  sortOrder: "ASC" | "DESC" | undefined;
  columnTitle: string;
  onChange?: (sortOrder: "ASC" | "DESC") => void;
  justifyContent?: "start" | "center" | "end";
};

const SortTable = ({
  sortOrder,
  columnTitle,
  justifyContent,
  onChange,
}: props) => {
  return (
    <div
      className="table-sort-wrapper"
      style={{ justifyContent: justifyContent ? justifyContent : "center" }}
      onClick={() => onChange && onChange(sortOrder === "ASC" ? "DESC" : "ASC")}
    >
      {columnTitle}
      <div className="table-sort">
        <img
          alt=""
          src={IconSortUp}
          className={`icon-up ${sortOrder === "ASC" ? "icon-sorted" : ""}`}
        />
        <img
          alt=""
          src={IconSortUp}
          className={`icon-down ${sortOrder === "DESC" ? "icon-sorted" : ""}`}
        />
      </div>
    </div>
  );
};

export default SortTable;
