import axiosConfig from '../../../../../config/axiosConfig';

const CM0034Api = () => {
    const getDocumentReceiveMoneySubmittedList = async (params: any): Promise<any> => {
        const url = '/re/document/receive-money/submitted/list';
        return await axiosConfig.get(url, { params });
    };

    return {
        getDocumentReceiveMoneySubmittedList,
    };
};

export default CM0034Api;
