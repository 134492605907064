import { Button, Col, Input, Row, Form } from "antd";
import SearchSvg from "../../../../../assets/icons/search-icon.svg";
import PlusVectorGreenSvg from "../../../../../assets/icons/plus-vector-green.svg";
import GraphSvg from "../../../../../assets/icons/graph.svg";
import { PM003HeaderHandler } from "../handler/HandlerImpl";
import IconGrid from "../../../../../assets/icons/IconGrid.svg";
import IconList from "../../../../../assets/icons/IconList.svg";
import { useLocation, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { AUTHORIZATION_CODE } from "../../../../../common/constants/AUTHORIZATION";
import { RootState } from "../../../../../store";
import { useSelector } from "react-redux";
import { isHasPrivilege } from "../../../../../common/helpers/privilege";
import { PLAN_TYPE } from "../../../../../common/constants/COMMON";

interface props {
  handler: PM003HeaderHandler;
}

const PM003HeaderUI = ({ handler }: props) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [searchParams, setSearchParams] = useSearchParams();
  const keyword = searchParams.get("keyword") || "";
  const permissions = useSelector(
    (state: RootState) => state.auth.authData?.permission
  );
  const order = useSelector((state: RootState) => state.auth?.authData?.order?.code)  //action.payload?.order?.code;
  const isOrderFree = order === PLAN_TYPE.PLAN_FREE;
  const isOrderBasic = order === PLAN_TYPE.PLAN_BASIC;
  useEffect(() => {
    handler.form.setFieldsValue({ text: keyword });
  }, []);
  const statusId = params.get("statusId") || "0";
  return (
    <>
      <div>
        <Row gutter={[16, 24]} className="header-row">
          <Col className="header-row-title" span={4}>
            案件管理
          </Col>
          <Col className="filter-col" span={20} style={{ width: "100%" }}>
            <Form form={handler.form}>
              <Form.Item name={"text"}>
                <Input
                  style={{ marginRight: 16, borderRadius: "3px" }}
                  size="large"
                  autoComplete="off"
                  prefix={<img alt="" src={SearchSvg} />}
                  placeholder="検索したい情報を入力してください "
                  className="search"
                  onChange={handler.onSearchChange}
                  allowClear
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Row className="header-info-number" gutter={[16, 20]}>
          <Col
            style={{ width: "20%" }}
            onClick={() => handler.onInfoCardChange(0)}
          >
            <div
              className={`header-info-card ${statusId == "0" ? "info-card-default" : ""
                }`}
            >
              {/* <div className="header-info-card"> */}
              <Button
                className="header-button-icon"
                size="large"
                icon={<img alt="" src={GraphSvg} />}
              ></Button>
              <div className="header-content">
                <div className="header-content-number">
                  {handler.totalCountDefault}
                </div>
                <div>全部</div>
              </div>
            </div>
          </Col>
          {handler.newDataStatus.map((element: any) => (
            <Col
              style={{ width: "20%" }}
              key={element.id}
              onClick={() => {
                handler.onInfoCardChange(element.id);
                handler.onPageChangeCard(1);
              }}
            >
              <div
                className={`header-info-card ${statusId == element.id ? "info-card-default-child" : ""
                  }`}
              >
                <Button
                  className="header-button-icon"
                  size="large"
                  icon={<img alt="" src={element.src} />}
                ></Button>
                <div className="header-content">
                  <div className="header-content-number">{element.count}</div>
                  <div>{element.name}</div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
        {/* row 3 */}
        <Row className="header-button" gutter={[16, 20]}>
          <Col span={4} className="header-button-title">
            案件一覧
          </Col>
          <Col span={20}>
            <div style={{ float: "right" }}>
              <Button
                size="large"
                type="link"
                style={{ marginRight: 8, border: "none" }}
                icon={<img alt="" src={IconGrid} />}
                onClick={() => {
                  handler.onPageChangeCard(1);
                  handler.toOtherPageCard();
                }}
              ></Button>
              <Button
                type="link"
                style={{
                  marginRight: 24,
                  border: "none",
                  cursor: "context-menu",
                }}
                size="large"
                icon={<img alt="" src={IconList} />}
                onClick={handler.toOtherPageList}
              ></Button>

              {(permissions?.includes(AUTHORIZATION_CODE.S1) ||
                isHasPrivilege()) && !isOrderFree ? (
                <Button
                  size="large"
                  className="button-purple"
                  onClick={handler.showModal}
                  icon={
                    <img
                      alt=""
                      src={PlusVectorGreenSvg}
                      style={{
                        paddingRight: 12,
                        border: "none",
                        marginBottom: 4,
                      }}
                    />
                  }
                >
                  新規案件作成
                </Button>
              ) : null}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default PM003HeaderUI;
