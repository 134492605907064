import TodoApi from "./infrastructure/api/Api";
import TodoService from "./usecase/Service";
import TodoHandler from "./presenter/handler/Handler";
import TodoUI from "./presenter/ui/UI";

const TodoComponent = () => {
    const todoApi = TodoApi();
    const todoService = TodoService(todoApi);
    const todoHandler = TodoHandler(todoService);
    return <TodoUI handler={todoHandler} />
}

export default TodoComponent