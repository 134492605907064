import CM0012Api from './infrastructure/api/Api';
import CM0012Handler from './presenter/handler/Handler';
import CM0012UI from './presenter/ui/UI';
import CM0012Service from './usecase/Service';

const CM0012Component = () => {
    const cm0012Api = CM0012Api();
    const cm0012Service = CM0012Service(cm0012Api);
    const cm0012Handler = CM0012Handler(cm0012Service);
    return <CM0012UI handler={cm0012Handler} />;
};

export default CM0012Component;
