interface props {
  record: any;
  index: number;
  onChoose: (value: any) => void;
}

const MemberRowComponent = ({ record, index, onChoose }: props) => {
  return (
    <main
      onClick={() => onChoose(record)}
      className="table-cell-inside button-style"
      style={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <div style={{ display: 'flex' }}>
        <span style={{ minWidth: 40 }}> {index}</span>

        <span style={{ marginLeft: 24 }}> {record.fullName || ''}</span>
      </div>
      <div>
        <span style={{ marginRight: 10 }}>出勤日数:</span>
        <span className="bold-text-number ">{record.workingDays}日</span>
      </div>
    </main>
  );
};

export default MemberRowComponent;
