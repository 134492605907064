import { CM0030Hanlder } from '../handler/HandlerImpl';
import Table from './Table';
import Header from './Header';
import CM0031Component from '../../../CM0031';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { useEffect } from 'react';
import helpers from '../../../../../common/helpers/common';
import ModalApprove from '../../../../../common/components/approval-flow/ModalApprove';
import MESSAGE from '../../../../../common/constants/MESSAGE';

interface props {
    handler: CM0030Hanlder;
}

const MainUI = ({ handler }: props) => {
    const visibleCM0031 = useSelector((state: RootState) => state.cm0031.isVisibleCM0031);

    useEffect(() => {
        if (
            !handler.openFilter &&
            (helpers.getFilterTimeMonth(handler.from) !== handler.filterCurrent.from ||
                helpers.getFilterTimeMonthTo(handler.to) !== handler.filterCurrent.to)
        ) {
            Promise.all([
                handler.funcHandleDataTable({
                    from: handler.clear ? '' : helpers.getFilterTimeMonth(handler.from),
                    to: handler.clear ? '' : helpers.getFilterTimeMonthTo(handler.to),
                }),
                handler.getTotalIndirectCost({
                    from: handler.clear ? '' : helpers.getFilterTimeMonth(handler.from),
                    to: handler.clear ? '' : helpers.getFilterTimeMonthTo(handler.to),
                })
            ])
        }
    }, [handler.from, handler.to, , handler.openFilter]);

    useEffect(() => {
        if (!handler.openFilter) {
            handler.getIndirectCostProjectDocumentStatus({
                from: helpers.getFilterTimeMonth(handler.from),
                to: helpers.getFilterTimeMonthTo(handler.to),
            });
        }
    }, [handler.clear, handler.from, handler.to, handler.refreshStatus, handler.openFilter]);

    useEffect(() => {
        handler.initialFilter();
    }, []);

    return (
        <div className="cm024">
            <Header handler={handler} />
            <Table handler={handler} />
            {visibleCM0031 ? <CM0031Component /> : null}
        </div>
    );
};

export default MainUI;
