
import PM005Api from "./infrastructure/api/Api";
import PM005Handler from "./presenter/handler/Handler";
import PM005Service from "./usecase/Service";
import PM005UI from "./presenter/ui/UI";

function PM005Component() {
  const pm005Api = PM005Api();

  const pm005Service = PM005Service(pm005Api);

  const pm005Handler = PM005Handler(pm005Service);

  return <PM005UI handler={pm005Handler} />;
}

export default PM005Component;
