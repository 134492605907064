import { NOTIFICATION_TITLE } from "./../../../../common/constants/MESSAGE";
import ErrorNotification from "../../../../common/components/notification/ErrorNotification";
import COMMON, {
  DEFAULT_PAGINATION_RESULT,
} from "./../../../../common/constants/COMMON";
import { PaginationResult } from "./../../../../common/entity/Entity";
import { AM002APIImpl } from "./ServiceImpl";
import helpers from "../../../../common/helpers/common";
import { AttendanceConstructionTotalCost } from "../../AM001/entity/Entity";

const AM002Service = (api: AM002APIImpl) => {
  const getAttendanceCompanyColabSubConstruction = async (params: {
    companyId: number;
    keyword: string;
    page: string;
    size: string;
    from?: string; // 2006-01-02
    to?: string; // 2006-01-02
    sortBy?: string;
    sortType?: "DESC" | "ASC";
  }): Promise<PaginationResult> => {
    try {
      const timeOffset = COMMON.TIME_ZONE;
      const response = await api.getAttendanceCompanyColabSubConstruction({
        ...params,
        timeOffset,
        from: params?.from ? helpers.getFilterTime(params.from) : undefined,
        to: params?.to ? helpers.getFilterTimeTo(params.to) : undefined,
      });
      return response.data || DEFAULT_PAGINATION_RESULT;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return DEFAULT_PAGINATION_RESULT;
    }
  };
  const getAttendanceCompanyColabTotalCost = async (params: {
    companyId: number;
    from: string; // 2006-01-02
    to: string; // 2006-01-02
  }): Promise<AttendanceConstructionTotalCost> => {
    try {
      const timeOffset = COMMON.TIME_ZONE;
      const response = await api.getAttendanceCompanyColabTotalCost({
        ...params,
        timeOffset,
        from: helpers.getFilterTime(params.from),
        to: helpers.getFilterTimeTo(params.to),
      });
      return (
        response?.data?.results ?? {
          totalLaborCost: 0,
          totalWorkingDay: 0,
        }
      );
    } catch (error: any) {
      throw error;
    }
  };
  const doExportAM002 = async (params: any): Promise<any> => {
      return await api.doExportAM002(params);
  }
  return {
    getAttendanceCompanyColabSubConstruction,
    getAttendanceCompanyColabTotalCost,
    doExportAM002,
  };
};

export default AM002Service;
