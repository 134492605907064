import { createSlice } from "@reduxjs/toolkit";
import { AM040SliceType } from "../../../AM040/entity/Entity";

export const initialState: AM040SliceType = {
  params: null,
};

export const AM042Slice = createSlice({
  name: "am042",
  initialState,
  reducers: {
    resetAM042: () => initialState,
    setAM042: (state, action) => {
      state.params = action.payload;
    },
  },
});

export const { resetAM042, setAM042 } = AM042Slice.actions;

export default AM042Slice.reducer;
