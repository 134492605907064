import MiniPaginationComponent from "../../../../../../../common/components/pagination/MiniPaginationComponent";

type Props = {
    page: string,
    size: string,
    total: number
    onPageChange: (value: any) => void,
}

const PaginationComponent = ({ size, page, total, onPageChange }: Props) => {

    return total > parseInt(size) ?
        <div className="company-pagination-container w-100-percent center-item">
            <MiniPaginationComponent
                total={total}
                page={parseInt(page || "1")}
                size={parseInt(size)}
                setPage={onPageChange}
                sizeLabelPage="件"
                sizeLabelSize="ページ"
                rangeLabel="件"
            />
        </div>
        :
        <div></div>
}

export default PaginationComponent