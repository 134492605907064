import ErrorNotification from '../../../../common/components/notification/ErrorNotification';
import { NOTIFICATION_TITLE } from '../../../../common/constants/MESSAGE';
import { ChangeMailPayload } from '../entity/Entity';
import { IC4Api } from './ServiceImpl';

const IC4Service = (api: IC4Api) => {

    const postChangeMail = async (
        payload: ChangeMailPayload
    ): Promise<any> => {
        try {
            const response = await api.postChangeMail(payload);
            return response?.data
        } catch (error: any) {
            throw error;
        }
    };

    return {
        postChangeMail
    };
};

export default IC4Service;
