import React from 'react';
import IconDown from '../../../../../assets/icons/icon-down-small.svg';
import CostTableComponent from './CostTable';
import helpers from '../../../../../common/helpers/common';
import TooltipText from '../../../../../common/components/tooltip-text/TooltipText';

type props = {
    project: any;
    index: number;
    checkKeyExpand: (key: any) => boolean;
    setExpandKeys: (key: any) => void;
};

const ProjectRowComponent = ({ project, index, checkKeyExpand, setExpandKeys }: props) => {
    return (
        <>
            <tr className="row-level-0 button-style" onClick={() => setExpandKeys(project.id)}>
                <td colSpan={2}>{index + 1}</td>
                <td colSpan={4} style={{ textAlign: 'left', paddingLeft: 54 }}>
                    <div style={{ width: 150 + 300 + 150 + 140 }}>
                        <TooltipText content={project?.name ?? ''} />
                    </div>
                </td>
                <td>{helpers.formatNumberMoney(project.paymentAmountTaxIncluded)}円</td>
                <td>{helpers.formatNumberMoney(project.consumptionTax)}円</td>
                <td>{helpers.formatNumberMoney(project.paymentAmountExcludingTax)}円</td>
                <td>
                    <img
                        alt=""
                        src={IconDown}
                        className={`cm024-icon ${!checkKeyExpand(project.id) ? '' : 'cm024-icon-active'
                            }`}
                    />
                </td>
            </tr>
            {checkKeyExpand(project.id) ? (
                <CostTableComponent costDetailData={project.costDetailData} />
            ) : (
                <></>
            )}
            <tr style={{ height: 4 }}></tr>
        </>
    );
};

export default ProjectRowComponent;
