import { GroupEntity, ProjectUserResponse } from "./../../entity/Entity";
import axiosConfig from "../../../../../config/axiosConfig";

const OM007Api = () => {
  const createNewGroup = async (data: GroupEntity): Promise<any> => {
    try {
      const url = "/organization/create-group";
      const response = await axiosConfig.post(url, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  const getProjectUsers = async (params: any): Promise<ProjectUserResponse> => {
    try {
      const url = "/project/users";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  return {
    createNewGroup,
    getProjectUsers,
  };
};

export default OM007Api;
