import ErrorNotification from '../../../../../common/components/notification/ErrorNotification';
import { NOTIFICATION_TITLE } from '../../../../../common/constants/MESSAGE';
import { CM0035Api } from './ServiceImpl';
const CM0035Service = (cm0035Api: CM0035Api) => {
    const getDocumentSubmittedMaterialList = async (): Promise<any> => {
        try {
            const response = await cm0035Api.getDocumentSubmittedMaterialList();
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return;
        }
    };

    const getDocumentSubmittedOutsourceList = async (): Promise<any> => {
        try {
            const response = await cm0035Api.getDocumentSubmittedOutsourceList();
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return;
        }
    };

    const getDocumentSubmittedLaborList = async (): Promise<any> => {
        try {
            const response = await cm0035Api.getDocumentSubmittedLaborList();
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return;
        }
    };

    const getDocumentSubmittedIndirectList = async (): Promise<any> => {
        try {
            const response = await cm0035Api.getDocumentSubmittedIndirectList();
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return;
        }
    };

    return {
        getDocumentSubmittedMaterialList,
        getDocumentSubmittedOutsourceList,
        getDocumentSubmittedLaborList,
        getDocumentSubmittedIndirectList,
    };
};

export default CM0035Service;
