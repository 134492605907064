import axiosConfig from "../../../../../config/axiosConfig";

const PM003Api = () => {
  const getData = async (params: any): Promise<any> => {
    try {
      const url = "/project/alls";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  return {
    getData,
  };
};
export default PM003Api;
