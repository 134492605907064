import { createSlice } from "@reduxjs/toolkit";
import { AM002SliceType } from "../../entity/Entity";

export const initialState: AM002SliceType = {
  selectedCompanyId: 1,
  isRefreshCompany: false,
  isRefreshConstruction: false,
  isRefresh: false,
  companyList: [],
};

export const AM002Slice = createSlice({
  name: "am002",
  initialState,
  reducers: {
    setSelectedCompanyId: (state, action) => {
      state.selectedCompanyId = action.payload;
    },
    refreshAM002Company: (state) => {
      state.isRefreshCompany = !state.isRefreshCompany;
    },
    refreshAM002Construction: (state) => {
      state.isRefreshConstruction = !state.isRefreshConstruction;
    },
    refreshAM002: (state) => {
      state.isRefresh = !state.isRefresh;
    },
    setCompanyListData: (state, action) => {
      state.companyList = action.payload;
    },
  },
});

export const {
  setSelectedCompanyId,
  refreshAM002Company,
  refreshAM002Construction,
  refreshAM002,
  setCompanyListData,
} = AM002Slice.actions;

export default AM002Slice.reducer;
