import T01Api from './infrastructure/api/Api';
import T01Handler from './presenter/handler/Handler';
import MainUI from './presenter/ui/UI';
import T01Service from './usecase/Service';

import '../../../assets/styles/T/T01.css';

const T01Component = () => {
  const api = T01Api();
  const service = T01Service(api);
  const handler = T01Handler(service);
  return <MainUI handler={handler} />;
};

export default T01Component;
