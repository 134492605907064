import { AxiosResponse } from "axios";
import axiosConfig from "../../../../../config/axiosConfig";
import { SummaryMaterialCostProjectSubmitPayload } from "../../entity/Entity";

const CM0022Api = () => {
  const getMoneySummaryMaterialCostProjectList = async (params: {
    page: number;
    size: number;
    from: string;
    to: string;
    sortBy: string;
    sortType: string;
  }) => {
    const url = "/re-summary/project/material";
    return await axiosConfig.get(url, { params });
  };

  const getMoneySummaryMaterialCostProjectConstructionList = async (params: {
    projectId: number;
    from: string;
    to: string;
    page: number;
    size: number;
    sortBy?: string;
    sortType?: string;
  }) => {
    const url = "money/summary/material-cost/project/construction/list";
    return await axiosConfig.get(url, { params });
  };

  const getMoneySummaryMaterialCostProjectConstructionListMaterialCost =
    async (params: {
      constructionId: number;
      from: string;
      to: string;
      page: number;
      size: number;
      sortBy?: string;
      sortType?: string;
    }) => {
      const url =
        "money/summary/material-cost/project/construction/list-material-cost";
      return await axiosConfig.get(url, { params });
    };

  const getMoneySummaryMaterialCostProjectStatus = async (params: {
    from: string;
    to: string;
  }) => {
    const url = "money/summary/material-cost/project/status";
    return await axiosConfig.get(url, { params });
  };

  const putMoneySummaryMaterialCostProjectSubmit = async (
    data: SummaryMaterialCostProjectSubmitPayload
  ) => {
    const url = "money/summary/material-cost/project/submit";
    return await axiosConfig.post(url, data);
  };
  const getMaterialCostProjectSummary = async (params: {
    from: string;
    to: string;
  }) => {
    const url = "/money/summary/material-cost/project/summary";
    return await axiosConfig.get(url, { params });
  };
  const doExportCM0022 = async (params: any): Promise<any> => {
      try {
          const url = '/money/exportCM0022';
          const response = await axiosConfig.get(url, { params, responseType: 'blob'  });
          
          return response;
      } catch (error) {
          throw error;
      }
    };
  return {
    getMoneySummaryMaterialCostProjectList,
    getMoneySummaryMaterialCostProjectConstructionList,
    getMoneySummaryMaterialCostProjectConstructionListMaterialCost,
    putMoneySummaryMaterialCostProjectSubmit,
    getMoneySummaryMaterialCostProjectStatus,
    getMaterialCostProjectSummary,
    doExportCM0022,
  };
};

export default CM0022Api;
