import axiosConfig from "../../../../../config/axiosConfig";

const AM005Api = () => {
  const getListConstructionProject = async (params: {
    projectId: number | number;
    from?: string;
    to?: string;
    timeOffset?: number;
  }) => {
    const url = "attendance/project/construction";
    return await axiosConfig.get(url, { params });
  };

  const getListPartnerConstruction = async (params: {
    constructionId: number;
    contractorConstructionId: number;
    from?: string;
    to?: string;
    timeOffset?: number;
  }) => {
    try {
      const url = "/attendance/subconstruction/list-subconstruction";
      return await axiosConfig.get(url, { params });
    } catch (error) {
      throw error;
    }
  };

  const getListSubConstruction = async (params: {
    constructionId: number;
    contractorConstructionId: number;
    from?: string;
    to?: string;
  }) => {
    try {
      const url = "/invite/subconstruction/list-subconstruction/filter";
      return await axiosConfig.get(url, { params });
    } catch (error) {
      throw error;
    }
  };

  const doExportAM005 = async (params: any): Promise<any> => {
      try {
          const url = '/attendance/exportAM005';
          const response = await axiosConfig.get(url, { params, responseType: 'blob'  });
          
          return response;
      } catch (error) {
          throw error;
      }
  };

  return {
    getListPartnerConstruction,
    getListSubConstruction,
    getListConstructionProject,
    doExportAM005,
  };
};

export default AM005Api;
