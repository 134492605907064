import React from 'react';
import moment from 'moment';
import COMMON, { TAX_CODE } from '../../../../../common/constants/COMMON';
import helpers from '../../../../../common/helpers/common';
import { Empty } from 'antd';
import { OffsetCost } from '../../entity/Entity';
import EyeViewDetailSvg from '../../../../../assets/icons/eye-view-detail.svg';
import TooltipText from '../../../../../common/components/tooltip-text/TooltipText';
import MESSAGE from '../../../../../common/constants/MESSAGE';

type props = {
    outsourceCost: any[];
    openCM0025_1: (value: any) => void;
};

const CostTableComponent = ({ outsourceCost, openCM0025_1 }: props) => {
    const getOffsetValue = (record: any, taxFree?: boolean) => {
        const offsetCost = record.offsetCost?.find(
            (element: OffsetCost) =>
                element.category.code === (taxFree ? TAX_CODE.TAX_FREE : TAX_CODE.TAX),
        );

        return (
            <main className="center-item">
                <div className="collapsed-table-row-style">
                    {`${helpers.formatNumberMoney(offsetCost?.value ?? 0)}円`}
                </div>
                <div
                    style={{ marginLeft: 10 }}
                    className="button-style"
                    onClick={(event) => {
                        event.stopPropagation();
                        openCM0025_1({
                            ...record,
                            categoryId: offsetCost?.category?.id ?? 0,
                        });
                    }}
                >
                    <img alt="" src={EyeViewDetailSvg} />
                </div>
            </main>
        );
    };
    return (
        <React.Fragment>
            <tr className="bg-fa">
                <td colSpan={2}></td>
                <td>工事名</td>
                <td>契約工事名</td>

                <td colSpan={2}>対象期間</td>
                <td colSpan={2}>支払日</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td style={{ width: '40px', textAlign: 'center', marginTop: 13 }}></td>
            </tr>
            {outsourceCost.length === 0 ? (
                <tr>
                    <td colSpan={16}>
                        <Empty
                            style={{ width: '100%' }}
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={MESSAGE.MESSAGE_DATA_NOT_FOUND}
                        />
                    </td>
                </tr>
            ) : (
                outsourceCost.map((element, index) => (
                    <tr key={`table${element.id}`} className="">
                        <td style={{ width: 75 }}></td>

                        {/*
                        + STT
                        */}
                        <td style={{ width: 75 }}> {index + 1}</td>

                        {/*
                        + 工事名
                        Tên công trình chính/phụ đang hợp tác với công ty thuê ngoài #8.3 
                        Tên công trình lấy từ màn PM005, sắp xếp theo thứ tự tạo mới nhất đến cũ nhất
                        */}
                        <td>
                            <div style={{ width: 160 }}>
                                <TooltipText content={element?.construction?.description ?? ''} />
                            </div>
                        </td>

                        {/*
                        + 契約工事名
                        Tên công trình hợp tác đang hợp tác với công ty thuê ngoài #8.3 
                        Tên công trình lấy từ màn CR001_thông tin tiền mới nhất đã được duyệt (Chỉ lấy tên nhập thêm ở textbox), lấy thứ tự trong màn CR001
                        */}
                        <td>
                            <div style={{ width: 160 }}>
                                <TooltipText
                                    content={element?.constructionColab?.shortDescription ?? ''}
                                />
                            </div>
                        </td>

                        {/*
                        + 対象期間 
                        Lấy giá trị trường 対象期間 có ngày 支払日 với status 承認済 ở màn CM009, lấy những 対象期間 có ngày 支払日 thuộc phạm vi filter tại mục #9.8
                        */}
                        <td colSpan={2}>
                            {helpers.getStartEndDate(element.startDate, element.endDate)}
                        </td>

                        {/*
                        + 支払日
                        Lấy ngày 支払日 ở màn CM009 theo 対象期間 bên trên
                        */}
                        <td colSpan={2}>
                            {element.paymentDate
                                ? moment(element.paymentDate).format(COMMON.FORMAT_DATE_OM)
                                : ''}
                        </td>

                        {/*
                       + 計上金額
                        Cộng tổng số tiền của trường 実費用 ở màn CM009 có status 承認済
                        */}
                        <td>{helpers.formatNumberMoney(element?.actualCost ?? 0)}円</td>

                        {/*
                        + 案件別相殺金額
                        Gồm 2 cột con: 課税 & 非課税
                        Count tổng số tiền từ popup CM0011
                        Click icon mắt của cột 課税 sẽ mở ra popup CM0011, popup ẩn button edit & chỉ hiển thị các mục có giá trị 課税 ở trường 消費税区分
                        Click icon mắt của cột 非課税 sẽ mở ra popup CM0011, popup ẩn button edit & chỉ hiển thị các mục có giá trị 非課税 ở trường 消費税区分
                        Chi tiết popup CM011 xem mục #13, sheet Chi tiết chi phí 1 công trình_CM004~CM0013_Web
                        */}
                        <td>{getOffsetValue(element)}</td>
                        <td>{getOffsetValue(element, true)}</td>

                        {/*
                        + 支払金額(税抜)
                        支払金額（税抜）＝計上金額ー案件別相殺金額（課税＋非課税）
                        */}
                        <td>{`${helpers.formatNumberMoney(
                            element.paymentAmountExcludingTax,
                        )}円`}</td>

                        {/*
                        + 消費税
                        Công thức: （支払金額(税抜) + 案件別相殺金額(課税)） x % thuế setting ở sprint sau
                        */}
                        <td>{`${helpers.formatNumberMoney(element.consumptionTax)}円`}</td>

                        {/*
                        + 支払金額(税込)
                        Công thức: 支払金額(税抜) + 消費税
                        */}
                        <td>{`${helpers.formatNumberMoney(
                            element.paymentAmountIncludingTax,
                        )}円`}</td>
                        <td></td>
                    </tr>
                ))
            )}
        </React.Fragment>
    );
};

export default CostTableComponent;
