import CM0033Api from './infrastructure/api/Api';
import CM0033Handler from './presenter/handler/Handler';
import CM0033UI from './presenter/ui/UI';
import CM0033Service from './usecase/Service';
import '../../../assets/styles/CM/CM0032.css';

type props = {
    onSubmit?: () => void;
    onReject?: () => void;
};

const CM0033Component = (props: props) => {
    const cm0033Api = CM0033Api();
    const cm0033Service = CM0033Service(cm0033Api);
    const cm0033Handler = CM0033Handler(cm0033Service);
    return <CM0033UI handler={cm0033Handler} {...props} />;
};

export default CM0033Component;
