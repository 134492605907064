import React, { useEffect } from "react";
import { Handler } from "../handler/HandlerImpl";
import "../../../../../assets/styles/IC/IC3.css";
import { Button, Tabs } from "antd";
import TableComponent from "./Table";
import EmailIcon from "../../../../../assets/icons/write-email.svg";
import IC5Modal from "../../../../../common/components/modal-IC5/IC5Modal";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import IC4Component from "../../../IC4";

interface props {
  handler: Handler;
}

function MainUI({ handler }: props) {
  const isOpenIC5Modal = useSelector(
    (state: RootState) => state.ic5Modal.isOpenIC5
  );
  const isOpenIC4Modal = useSelector(
    (state: RootState) => state.ic4Modal.isOpenIC4
  );
  useEffect(() => {
    handler.getOrderCompany();
  }, []);
  return (
    <div className="ic1-body">
      <div className="wrapper-table-ic3">
        <p className="title-table-ic3">利用情報</p>
        <TableComponent handler={handler} />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "32px",
        }}
      >
        <div className="content-text-infor">
          <div style={{ display: "flex", gap: "24px" }}>
            <p className="text-item txt-666">ユーザー数</p>
            <p className="text-item">
              {handler.dataOrderCompany?.planPerson?.userNumber ?? 0}{" "}
            </p>
          </div>
          <div className="text-item">
            <span>{handler.dataOrderCompany?.userNumber ?? 0}&nbsp;</span>{" "}
            <span className="txt-valid-color">(発行済数)</span>
          </div>
          <div className="text-item">
            <span>
              {!handler?.dataOrderCompany?.planPerson?.userNumber &&
              !handler?.dataOrderCompany?.userNumber
                ? 0
                : handler?.dataOrderCompany?.planPerson?.userNumber -
                  handler?.dataOrderCompany?.userNumber}
              &nbsp;
            </span>{" "}
            <span className="txt-invalid-color">(残発行数)</span>
          </div>
        </div>

        <Button
          size="large"
          icon={<img alt="" src={EmailIcon} style={{ marginRight: 9 }} />}
          type="primary"
          style={{ width: 294 }}
          onClick={() => handler.openModalIC5()}
        >
          アドミン メールアドレス変更依頼
        </Button>
      </div>
      {isOpenIC5Modal ? <IC5Modal /> : <></>}
      {isOpenIC4Modal ? <IC4Component /> : <></>}
    </div>
  );
}

export default MainUI;
