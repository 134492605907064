import ErrorNotification from "../../../../common/components/notification/ErrorNotification";
import COMMON from "../../../../common/constants/COMMON";
import { NOTIFICATION_TITLE } from "../../../../common/constants/MESSAGE";
import {
  BaseResponseType,
  MoneySummaryMaterialCostProjectConstructionListData,
  MoneySummaryMaterialCostProjectConstructionListMaterialCost,
  MoneySummaryMaterialCostProjectListData,
  SummaryMaterialCostProjectSubmitPayload,
} from "../entity/Entity";
import { CM0022APIImpl } from "./ServiceImpl";

const CM0022Service = (api: CM0022APIImpl) => {
  const getMoneySummaryMaterialCostProjectList = async (params: {
    page: number;
    size: number;
    from: string;
    to: string;
    sortBy: string;
    sortType: string;
  }): Promise<MoneySummaryMaterialCostProjectListData | null> => {
    try {
      const response = await api.getMoneySummaryMaterialCostProjectList(params);
      return response?.data;
    } catch (error: any) {
      ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
      return null;
    }
  };

  const getMoneySummaryMaterialCostProjectConstructionList = async (params: {
    projectId: number;
    from: string;
    to: string;
    page: number;
    size: number;
    sortBy?: string;
    sortType?: string;
  }): Promise<MoneySummaryMaterialCostProjectConstructionListData | null> => {
    try {
      const response =
        await api.getMoneySummaryMaterialCostProjectConstructionList(params);
      return response?.data;
    } catch (error: any) {
      ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
      return null;
    }
  };

  const getMoneySummaryMaterialCostProjectConstructionListMaterialCost =
    async (params: {
      constructionId: number;
      from: string;
      to: string;
      page: number;
      size: number;
      sortBy?: string;
      sortType?: string;
    }): Promise<MoneySummaryMaterialCostProjectConstructionListMaterialCost | null> => {
      try {
        const response =
          await api.getMoneySummaryMaterialCostProjectConstructionListMaterialCost(
            params
          );
        return response?.data;
      } catch (error: any) {
        ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
        return null;
      }
    };

  const getMoneySummaryMaterialCostProjectStatus = async (params: {
    from: string;
    to: string;
  }): Promise<any | null> => {
    try {
      const response = await api.getMoneySummaryMaterialCostProjectStatus(
        params
      );
      return response?.data;
    } catch (error: any) {
      ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
      return null;
    }
  };

  const putMoneySummaryMaterialCostProjectSubmit = async (
    payload: SummaryMaterialCostProjectSubmitPayload
  ): Promise<BaseResponseType | null> => {
    try {
      const response = await api.putMoneySummaryMaterialCostProjectSubmit(
        payload
      );
      return response?.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return null;
    }
  };
  const getMaterialCostProjectSummary = async (params: {
    from: string;
    to: string;
  }) => {
    try {
      const response = await api.getMaterialCostProjectSummary(params);
      return response?.data;
    } catch (error: any) {
      ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
      return null;
    }
  };

  const doExportCM0022 = async (params: any): Promise<any> => {
        return await api.doExportCM0022(params);
    }
  return {
    getMoneySummaryMaterialCostProjectList,
    getMoneySummaryMaterialCostProjectConstructionList,
    getMoneySummaryMaterialCostProjectConstructionListMaterialCost,
    getMoneySummaryMaterialCostProjectStatus,
    putMoneySummaryMaterialCostProjectSubmit,
    getMaterialCostProjectSummary,
    doExportCM0022,
  };
};

export default CM0022Service;
