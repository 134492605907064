import helpers from '../../../common/helpers/common';
import {
    DataTableConstructionBudgetBalance,
    DataTableItemizedBudgetBalance,
    DataTableWorkCompleted,
} from './entity/Entity';

const convertDataTableReceivedMoney = (data: any[]): DataTableWorkCompleted[] => {
    const output: DataTableWorkCompleted[] = [];

    data.forEach((item: any, idx: number) => {
        const obj: DataTableWorkCompleted = {
            key: idx,
            contractValue: item?.contractAmount ?? 0,
            paymentValue: item?.billingVolume ?? 0,
            paymentValuePercent: helpers.calculateRateAmount(
                item?.contractAmount,
                item?.billingVolume,
            ),
            billBalance: item?.contractAmount - item?.billingVolume,
        };

        output.push(obj);
    });

    return output;
};

const convertDataTableInformationCost = (data: any[]): DataTableConstructionBudgetBalance[] => {
    const output: DataTableConstructionBudgetBalance[] = [];

    data.forEach((item: any, idx: number) => {
        const budget = item?.indirectCost?.planCost ?? 0;
        const actual = item?.indirectCost?.actualCost ?? 0;

        const obj: DataTableConstructionBudgetBalance = {
            key: idx,
            budgetAmount: budget,
            actualCost: actual,
            budgetBalance: budget - actual,
            budgetRate: helpers.calculateRateAmount(budget, actual),
        };

        output.push(obj);
    });
    return output;
};

const convertDataTableIndirectCost = (
    data: any[],
    documentId: number,
    actualCost: number,
): DataTableItemizedBudgetBalance[] => {
    const output: DataTableItemizedBudgetBalance[] = [];

    let budgetTotal = 0;

    data.forEach((item: any, idx: number) => {
        if (item.id === documentId) {
            budgetTotal += item?.budgetMoney ?? 0;
        }
    });

    const obj: DataTableItemizedBudgetBalance = {
        key: 0,
        budgetAmount: budgetTotal,
        actualCost: actualCost,
        budgetRate: helpers.calculateRateAmount(budgetTotal, actualCost),
        budgetBalance: budgetTotal - actualCost,
    };

    output.push(obj);

    return output;
};

export {
    convertDataTableReceivedMoney,
    convertDataTableInformationCost,
    convertDataTableIndirectCost,
};
