import React from "react";
import { Rule } from 'antd/lib/form';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { Handler } from "./handler/Handler";
import MainUI from "./ui/MainUI";

export interface PropsInfiniteScrollSelectComponent {
    fetchData: (params: any) => Promise<any>;
    modeProp?: 'multiple' | 'tags' | undefined;
    widthProp?: number | string;
    nameProp: string;
    valueProp: string;
    sizeProp?: SizeType;
    placeholderProp: string | '';
    handleChangeProp?: any;
    disabled?: boolean;
    className?: string | '';
    isReponsive?: boolean;
    formName: string;
    formLabel: any;
    rules?: Rule[] | undefined;
    isFormCol?: boolean;
    isCal50?: boolean;
    required?: boolean;
    initialData?: any,
    initialId?: any,
}

const InfiniteScrollSelectComponent = (props: PropsInfiniteScrollSelectComponent) => {
    const handler = Handler()
    return <MainUI {...{ ...props, handler }} />
}

export default InfiniteScrollSelectComponent