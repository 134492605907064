import { Tooltip } from "antd";
import { useRef, useState, useEffect } from "react";
interface props {
  level: {
    levelNumber: "LEVEL1" | "LEVEL2" | "LEVEL3" | "LEVEL4" | string;
    levelTitle: string;
  };
  constructionName: string;
  member: number;
  laborCost?: string,
  totalWorkingDay?: string,
  handleChangePage(): void;
}

const LEVEL_COLOR: any = {
  LEVEL1: {
    color: "#33CC99",
    padding: 0,
    background: "rgba(51, 204, 153, 0.12)",
  },
  LEVEL2: {
    color: "#FF9861",
    padding: 32,
    background: "rgba(255, 152, 97, 0.12)",
  },
  LEVEL3: {
    color: "#FF5555",
    padding: 32 * 2,
    background: "rgba(255, 85, 85, 0.12)",
  },
  LEVEL4: {
    color: "#33CC99",
    padding: 32 * 3,
    background: "rgba(51, 204, 153, 0.12)",
  },
  LEVEL5: {
    color: "#FF9861",
    padding: 32 * 4,
    background: "rgba(255, 152, 97, 0.12)",
  },
  LEVEL6: {
    color: "#FF5555",
    padding: 32 * 5,
    background: "rgba(255, 85, 85, 0.12)",
  },
};

const getDirectoryIcon = (color: string) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.875 13.2913L12.375 18.7913L11.0733 17.4897L14.3642 14.208H4.125V3.20801H5.95833V12.3747H14.3642L11.0733 9.09301L12.375 7.79134L17.875 13.2913Z"
      fill={color ?? "transparent"}
    />
  </svg>
);

const SubConstructionItem = ({
  level,
  constructionName,
  member,
  laborCost,
  totalWorkingDay,
  handleChangePage,
}: props) => {
  const ref: any = useRef(null);
  const [checkTooltip, setCheckTooltip] = useState(false);

  useEffect(() => {
    if (ref && ref.current)
      setCheckTooltip(ref?.current?.scrollWidth > ref?.current?.offsetWidth);
  }, []);

  return (
    <div
      className={`am005-subConstruction flex-box ${
        level.levelNumber === "LEVEL1" ? "bg-level" : ""
      }`}
    >
      <div
        className="am005-subConstruction-left flex-box"
        style={{
          paddingLeft: LEVEL_COLOR[level.levelNumber].padding,
        }}
      >
        {getDirectoryIcon(LEVEL_COLOR[level.levelNumber].color)}
        <Tooltip
          title={checkTooltip ? constructionName : ""}
          getPopupContainer={(trigger: any) => trigger.parentNode}
          overlayInnerStyle={{
            color: "#fff",
            margin: "0 -4%",
          }}
        >
          <span ref={ref} className="am005-subConstruction-text">
            {constructionName}
          </span>
        </Tooltip>
        <div
          className="am005-level"
          style={{
            color: LEVEL_COLOR[level.levelNumber].color,
            backgroundColor: LEVEL_COLOR[level.levelNumber].background,
          }}
        >
          （{level.levelTitle}）
        </div>
      </div>
      <div className="am005-subConstruction-left flex-box">
        <div className="am005-subConstruction-left__item">
            <div className="am005-separator"></div>
            <span>合計金額:</span>
            <span className="am005-text-bold-14 ml-8">{laborCost}円</span>
        </div>
        <div className="am005-subConstruction-left__item">
          <div className="am005-separator"></div>
          <span>稼働人工数:</span>
          <span className="am005-text-bold-14 ml-8">{totalWorkingDay}人工</span>
        </div>
      </div>
    </div>
  );
};

export default SubConstructionItem;
