import { Form, Input, InputNumber, Select, Tooltip, Empty } from "antd";
import { memo } from "react";
import "moment/locale/ja";

import NewSvg from "../../../../../assets/icons/new.svg";
import SquarePurple from "../../../../../assets/icons/squarer-purple.svg";
import VectorMinus from "../../../../../assets/icons/vector-minus.svg";

import { CR001AmountRelateHandler } from "../handler/HandlerImpl";
import REGEX from "../../../../../common/constants/REGEX";
import MESSAGE from "../../../../../common/constants/MESSAGE";
import { onBlurTrimFormItem } from "../../../../../common/helpers/typeInput";
import helpers from "../../../../../common/helpers/common";
import COMMON from "../../../../../common/constants/COMMON";
import UploadEvidenceComponent from "../../../../../common/components/common-form-item-cm/upload-evidence";
import DocumentIconSvg from "../../../../../assets/icons/document-icon.svg";
import UploadFileCRComponent from "../../../../../common/components/upload-cr";

const { Item } = Form;

interface props {
  handler: CR001AmountRelateHandler;
}

const MESSAGE_REQUIRED = "";
const MAX_LENGTH_TEXT = 30;
const MIN_INPUT_NUMBER = 0;
const REQUIRED_INPUT = false;

const Labor = ({ handler }: props) => {
  return (
    <div className="cr001-cost-registration">
      <div className="cr001-cost-registration-title bg-title-row padding-tb-15 border-bottom">
        <img src={NewSvg} alt="" />
        <span className="text-bold-14">労務費登録</span>
      </div>
      <div className="cr001-cr-item bg-header-row">
        <div
          style={{ width: "20%" }}
          className="cr001-cr-item-child border-bottom border-right cr-7 padding-tb-15"
        >
          担当者
        </div>
        <div
          style={{ width: "20%" }}
          className="cr001-cr-item-child border-bottom border-right cr-3 padding-tb-15"
        >
          見積金額（円）
        </div>
        <div
          style={{ width: "20%" }}
          className="cr001-cr-item-child border-bottom border-right cr-3 padding-tb-15"
        >
          原価金額（円）
        </div>
        <div
          style={{ width: "20%" }}
          className="cr001-cr-item-child border-bottom border-right cr-3 padding-tb-15"
        >
          予算金額（円）
        </div>
        <div
          style={{ width: "20%" }}
          className="cr001-cr-item-child border-bottom border-right cr-7 padding-tb-15"
        >
          ファイル
        </div>
      </div>

      {handler.isEdit && (
        <div className="cr001-cr-item-add" onClick={handler.handleAddLaborCost}>
          <img alt="" src={SquarePurple} />
          <span className="text-bold-14">項目追加</span>
        </div>
      )}
      {handler.laborCostList?.map((laborCost: any) => {
        return (
          <div
            className="cr001-cr-item cr001-icon-rotate"
            key={`laborCost${laborCost.key}`}
          >
            <div
              style={{ width: "20%" }}
              className="cr001-cr-item-child border-bottom border-right cr-7 padding-tb-15"
            >
              {handler.isEdit ? (
                <div className="cr001-cr-item-bonus">
                  <img
                    alt=""
                    src={VectorMinus}
                    onClick={() => handler.handleDeleteLaborCost(laborCost.key)}
                    className="cr001-margin-icon"
                  />
                  <Item
                    name={[`laborCost${laborCost.key}`, "managerId"]}
                    style={{ width: "100%", paddingLeft: 14, maxWidth: 180 }}
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (value && value !== "") {
                            // CHECK INPUT VIETNAMESE
                            if (!REGEX.NOT_VIETNAM.test(value)) {
                              return Promise.reject(
                                new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                              );
                            }
                            if (value?.length > MAX_LENGTH_TEXT) {
                              return Promise.reject(new Error(""));
                            }
                            return Promise.resolve();
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Select
                      size="large"
                      listHeight={210}
                      showSearch
                      filterOption={() => true}
                      onDropdownVisibleChange={(open) => {
                        if (open) handler.handleGetListMember();
                      }}
                      onSearch={handler.handleSearchOption}
                      onPopupScroll={handler.handleScrollMember}
                      getPopupContainer={(trigger: any) => trigger.parentNode}
                      notFoundContent={
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description="該当なデータはありません"
                          style={{ color: "#999da0" }}
                        />
                      }
                      className="cr003-select-member"
                    >
                      {handler.members?.map((select) => {
                        return (
                          <Select.Option
                            style={{ alignItems: "center" }}
                            key={select?.id}
                            value={select?.id}
                          >
                            <div
                              style={{
                                fontSize: 14,
                                fontWeight: 600,
                                color: "#121212",
                              }}
                            >
                              {select?.fullName || select?.username}
                            </div>
                            <div
                              style={{
                                fontSize: 12,
                                fontWeight: 400,
                                color: "#666",
                              }}
                            >
                              {select?.emailAddress}
                            </div>
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Item>
                </div>
              ) : (
                <Tooltip
                  title={
                    handler.listTooltip?.includes(`tooltip${laborCost.key}`)
                      ? laborCost?.manager?.fullName ||
                        laborCost?.manager?.username
                      : ""
                  }
                  getPopupContainer={(trigger: any) => trigger.parentNode}
                  overlayInnerStyle={{ color: "#fff", margin: "0 -4%" }}
                >
                  <span
                    className="cr-text-long-tooltip text-tooltip"
                    id={`tooltip${laborCost.key}`}
                  >
                    {laborCost?.manager?.fullName ||
                      laborCost?.manager?.username}
                  </span>
                </Tooltip>
              )}
            </div>
            <div
              style={{ width: "20%" }}
              className="cr001-cr-item-child border-bottom border-right cr-3 padding-tb-15 color-gray"
            >
              {handler.isEdit ? (
                <Item
                  rules={[
                    {
                      required: REQUIRED_INPUT,
                      message: MESSAGE_REQUIRED,
                    },
                  ]}
                  name={[`laborCost${laborCost.key}`, "estimateMoney"]}
                >
                  <InputNumber
                    controls={false}
                    maxLength={COMMON.MAX_INPUT_NUMBER}
                    max={COMMON.MAX_NUMBER_VALUE}
                    min={MIN_INPUT_NUMBER}
                    className="input-cr001 input-width-40"
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Item>
              ) : (
                handler.checkFormatValue(laborCost?.estimateMoney, false, true)
              )}
            </div>
            <div
              style={{ width: "20%" }}
              className="cr001-cr-item-child border-bottom border-right cr-3 padding-tb-15 color-gray"
            >
              {handler.isEdit ? (
                <Item
                  rules={[
                    {
                      required: REQUIRED_INPUT,
                      message: MESSAGE_REQUIRED,
                    },
                  ]}
                  name={[`laborCost${laborCost.key}`, "costMoney"]}
                >
                  <InputNumber
                    controls={false}
                    maxLength={COMMON.MAX_INPUT_NUMBER}
                    max={COMMON.MAX_NUMBER_VALUE}
                    min={MIN_INPUT_NUMBER}
                    className="input-cr001 input-width-40"
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Item>
              ) : (
                handler.checkFormatValue(laborCost?.costMoney, false, true)
              )}
            </div>
            <div
              style={{ width: "20%" }}
              className="cr001-cr-item-child border-bottom border-right cr-3 padding-tb-15 cr003-item-labor-cost-percent color-gray"
            >
              {handler.isEdit ? (
                <>
                  <Item
                    rules={[
                      {
                        required: REQUIRED_INPUT,
                        message: MESSAGE_REQUIRED,
                      },
                    ]}
                    name={[`laborCost${laborCost.key}`, "budgetMoney"]}
                  >
                    <InputNumber
                      controls={false}
                      maxLength={COMMON.MAX_INPUT_NUMBER}
                      max={COMMON.MAX_NUMBER_VALUE}
                      min={MIN_INPUT_NUMBER}
                      className="input-cr001 input-width-40"
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                    />
                  </Item>
                  {handler.checkFormatValue(
                    laborCost?.budgetMoneyPercent,
                    true,
                    true
                  )}
                </>
              ) : (
                handler.checkFormatValue(laborCost?.budgetMoney, false, true) +
                " " +
                handler.checkFormatValue(
                  laborCost?.budgetMoneyPercent,
                  true,
                  true
                )
              )}
            </div>
            <div
              style={{ width: "20%" }}
              className="cr001-cr-item-child border-bottom border-right cr-3 padding-tb-15 cr003-item-labor-cost-percent color-gray"
            >
              {handler.isEdit ? (
                <UploadFileCRComponent
                  nameForm={[`laborCost${laborCost.key}`, "documentLaborCost"]}
                  fileNameDefault={laborCost.document}
                  form={handler.formAmount}
                  afterClear={() =>
                    handler.afterClear(
                      [`laborCost${laborCost.key}`, "documentLaborCost"],
                      handler.laborCostList,
                      "laborCost"
                    )
                  }
                />
              ) : laborCost?.document?.length != 0 ? (
                <div
                  className="button-style"
                  onClick={() => {
                    handler.handleViewFile(laborCost, "labor");
                  }}
                >
                  <img src={DocumentIconSvg} alt="" />
                </div>
              ) : null}
            </div>
          </div>
        );
      })}

      <div className="cr001-cr-item bg-header-row">
        <div
          style={{ width: "20%" }}
          className="cr001-cr-item-child border-bottom border-right cr-7 padding-tb-15"
        >
          合計金額
        </div>
        <div
          style={{ width: "20%" }}
          className="cr001-cr-item-child border-bottom border-right cr-3 padding-tb-15 color-gray fw-700"
        >
          {handler.checkFormatValue(
            handler.totalLaborList?.estimateMoney,
            false,
            true
          )}
        </div>
        <div
          style={{ width: "20%" }}
          className="cr001-cr-item-child border-bottom border-right cr-3 padding-tb-15 color-gray fw-700"
        >
          {handler.checkFormatValue(
            handler.totalLaborList?.costMoney,
            false,
            true
          )}
        </div>
        <div
          style={{ width: "20%" }}
          className="cr001-cr-item-child border-bottom border-right cr-3 padding-tb-15 color-gray fw-700"
        >
          {handler.checkFormatValue(
            handler.totalLaborList?.budgetMoney,
            false,
            true
          )}{" "}
          {handler.checkFormatValue(
            handler.totalLaborList?.budgetMoneyPercent,
            true,
            true
          )}
        </div>
        <div
          style={{ width: "20%" }}
          className="cr001-cr-item-child border-bottom border-right cr-3 padding-tb-15 color-gray fw-700"
        ></div>
      </div>
    </div>
  );
};

export default memo(Labor);
