import OM004Api from "../OM004/infrastructure/api/Api"
import OM004Service from "../OM004/usecase/Service"
import OM002Api from "./infrastructure/api/Api"
import OM002Handler from "./presenter/handler/Handler"
import OM002UI from "./presenter/ui/UI"
import OM002Service from "./usecase/Service"

const OM002Component = () => {
    const om002Api = OM002Api()
    const om004Api = OM004Api()
    const om004Service = OM004Service(om004Api)
    const om002Service = OM002Service(om002Api)
    const om002Handler = OM002Handler(om002Service, om004Service)

    return <OM002UI handler={om002Handler} />
}

export default OM002Component