import { UploadEvidenceHandlerImpl } from '../../handler/HandlerImpl';
import React, { useEffect } from 'react';
import { Tooltip, Upload, Form, FormInstance } from 'antd';
import UploadDocumentSvg from '../../../../../../assets/icons/upload-document.svg';
import IconClose from '../../../../../../assets/icons/clear-circle.svg';
import { uuidv4 } from '@firebase/util';

type props = {
    handler: UploadEvidenceHandlerImpl;
    nameForm: string | string[];
    form: FormInstance;
    fileNameDefault?: string;
    afterClear?: (fileName: any) => void;
};

const UploadEvidenceUI = ({ handler, nameForm, fileNameDefault, form, afterClear }: props) => {
    const {
        fileName,
        beforeUpload,
        dummyRequest,
        handleChange,
        handleClearForm,
        handleAddDefaultFileName,
        setCurrentValue,
    } = handler;

    useEffect(() => {
        handleAddDefaultFileName(fileNameDefault);
    }, []);
    useEffect(() => {
        if (fileNameDefault) {
            setCurrentValue({
                file: {
                    uid: uuidv4(),
                    key: uuidv4(),
                    name: fileNameDefault,
                },
            });
        }
    }, [fileNameDefault]);
    return (
        <Form.Item name={nameForm} style={{ padding: 0, margin: 0 }} valuePropName="file">
            <Upload
                className="upload-no-height"
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={(info: any) => handleChange(info, form, nameForm, afterClear)}
                customRequest={dummyRequest}
                accept=".jpg, .png, .jpeg, .jpn, .pdf"
            >
                <Tooltip title={fileName}>
                    <div className="button-style center-item">
                        <img src={UploadDocumentSvg} alt="" />
                    </div>
                    {fileName ? (
                        <div className="name-file">
                            <div className="file-name-long">{fileName}</div>
                            <div
                                className="icon-clear-file"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleClearForm(form, nameForm, afterClear);
                                }}
                            >
                                <img src={IconClose} alt="" />
                            </div>
                        </div>
                    ) : null}
                </Tooltip>
            </Upload>
        </Form.Item>
    );
};

export default UploadEvidenceUI;
