import { LoginBody } from "./../../entity/Entity";
import { useState } from "react";
import { Form } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import { ASLoginUseCase } from "../../usecase/ServiceImpl";
import { LoginFormType } from "../type/TypePresenter";
import COMMON from "../../../../../common/constants/COMMON";
import { setAuth } from "../../../../../common/slice/AuthSlice";
import helpers from "../../../../../common/helpers/common";

const ASLoginHandler = (asLoginService: ASLoginUseCase) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [inputType, setInputType] = useState<"email" | "id">("id");

  const [form] = Form.useForm();

  const changeInputType = (value: string) => {
    form.resetFields();
    if (value === "id") setInputType(value);
    else setInputType("email");
  };

  const checkLogin = () => {
    dispatch(setLoading(true));
    const user = helpers.getObjectFromLocalStorage("user");
    if (user && user?.isFirstLogin === false) navigate("/");
    dispatch(setLoading(false));
  };

  const login = async (formData: LoginFormType) => {
    try {
      dispatch(setLoading(true));

      const requestBody: LoginBody = {
        username: inputType === "id" ? formData.username : formData.email,
        password: formData.password,
        isRemembered: formData.isRemembered,
      };

      const responseResults = await asLoginService.login(requestBody);
      dispatch(setAuth(responseResults));
      if (!responseResults?.isFirstLogin) {
        return navigate("/app-pm002");
      }
      await asLoginService.sendOTP();
      const date = new Date().getTime();
      localStorage.setItem("timerOTP", date + COMMON.ONE_MINUTE + "");
      navigate("/app-change-password");
    } catch (error: any) {
      let errorMessage = error?.message ?? "";

      if (error?.status == "ER002") {
        form.setFields([
          {
            name: "password",
            errors: [errorMessage],
          },
        ]);
      } else if (error?.status != "ER002" && inputType === "id") {
        form.setFields([
          {
            name: "username",
            errors: [errorMessage],
          },
        ]);
      } else if (error?.status != "ER002" && inputType === "email") {
        form.setFields([
          {
            name: "email",
            errors: [errorMessage],
          },
        ]);
      }


    } finally {
      dispatch(setLoading(false));
    }
  };

  const redirectToForgotPasswordPage = () => {
    form.resetFields();
    navigate("/app-forgot");
  };

  return {
    checkLogin,
    login,
    redirectToForgotPasswordPage,
    changeInputType,
    inputType,
    form,
  };
};

export default ASLoginHandler;
