import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Form } from "antd";
import ConfirmModal from "../../../../../../common/components/modal/ConfirmModal";
import MESSAGE, {
  NOTIFICATION_TITLE,
} from "../../../../../../common/constants/MESSAGE";
import { LABEL_MESSAGE } from "../../../../../../common/constants/MESSAGE";
import { RootState } from "../../../../../../store";
import { setSelectedRowCM0047 } from "../slice/Slice";
import {
  setDocumentIdCM006,
  setDocumentInfo,
  setIsVisibleCM006,
} from "../../../../CM006/presenter/slice/Slice";
import { CM0047ServiceImpl } from "../../usecase/ServiceImpl";
import { setLoading } from "../../../../../../common/slice/CommonSlice";
import { DEFAULT_PARAM } from "../../../wrapper/presenter/handler/Handler";
import COMMON, {
  STATUS_CM_CHILD_TABLE,
} from "../../../../../../common/constants/COMMON";
import SuccessNotification from "../../../../../../common/components/notification/SuccessNotification";
import moment from "moment";
import ErrorNotification from "../../../../../../common/components/notification/ErrorNotification";

export const PARAMS = {
  page: "page5",
  size: "size5",
  sortType: "sortType50",
  status: "status5",
  from: "from5",
  to: "to5",
  keyword: "keyword5",
  clear: "clear5",
};

const CM047Handler = (service: CM0047ServiceImpl) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [formReject] = Form.useForm();
  const page = +(searchParams.get(PARAMS.page) ?? DEFAULT_PARAM.page);
  const size = +(searchParams.get(PARAMS.size) ?? DEFAULT_PARAM.size);
  const sortType = searchParams.get(PARAMS.sortType);
  const keyword = searchParams.get(PARAMS.keyword);
  const status = searchParams.get(PARAMS.status);
  const from = searchParams.get(PARAMS.from) ?? DEFAULT_PARAM.from;
  const to = searchParams.get(PARAMS.to) ?? DEFAULT_PARAM.to;
  const clear = searchParams.get(PARAMS.clear);

  const [dataTable, setDataTable] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [visibleReject, setVisibleReject] = useState(false);
  const [isChangeValueReject, setIsChangeValueReject] = useState(false);
  const [visibleRejectView, setVisibleRejectView] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [detailSubmit, setDetailSubmit] = useState<{
    id: number;
    status: string;
  } | null>(null);
  const [refresh, setRefresh] = useState(false);

  const selectedRow = useSelector(
    (state: RootState) => state.cm0047.selectedRow
  );

  const getDataView = async (params: {
    statusId?: string;
    from?: string;
    to?: string;
    page: number;
    size: number;
    sortBy?: string;
    sortType?: string;
    keyword?: string;
  }) => {
    try {
      dispatch(setLoading(true));
      const response = await service.getDocumentReceiveMoneyReviewList(params);
      if (response?.results) {
        const keyForCheckbox: any[] = [];
        const results = response.results.map((element, index) => {
          if (
            !(
              element?.status?.code ===
              STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE
            )
          )
            keyForCheckbox.push(element.id);
          return {
            ...element,
            key: element.id,
            no: index + 1 + size * (page - 1),
          };
        });
        dispatch(setSelectedRowCM0047(keyForCheckbox));
        setDataTable(results);
      } else {
        setDataTable([]);
      }
      if (response?.pagination) setTotal(response.pagination.count);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const onPageChange = (page: number) => {
    searchParams.set(PARAMS.page, String(page));
    setSearchParams(searchParams);
    dispatch(setSelectedRowCM0047([]));
  };

  const onSizeChange = (size: number) => {
    searchParams.set(PARAMS.size, String(size));
    searchParams.delete(PARAMS.page);
    setSearchParams(searchParams);
    dispatch(setSelectedRowCM0047([]));
  };

  const handleChangeSelectedRow = (selectedRowKeys: React.Key[]) => {
    dispatch(setSelectedRowCM0047(selectedRowKeys));
  };

  const handleRejectData = (id?: number) => {
    setVisibleReject(true);
    if (!id) setDetailSubmit(null);
  };
  const handleSubmitReject = async (value: any) => {
    try {
      dispatch(setLoading(true));
      const response = await service.putMoneyRejectReceiveMoney({
        listDocumentId: detailSubmit?.id
          ? [detailSubmit?.id]
          : handleDataSubmitOrReject(selectedRow, dataTable),
        reason: value?.rejectReason,
      });
      if (response?.status === `${COMMON.HTTP_STATUS_OK}`) {
        SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
        formReject.resetFields();
        setRefresh(!refresh);
        setVisibleReject(false);
        dispatch(setIsVisibleCM006(false));
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleChangeValueReject = (value: any) => {
    setIsChangeValueReject(true);
  };
  const handleCancelReject = () => {
    if (isChangeValueReject) {
      ConfirmModal({
        onOk: () => {
          setVisibleReject(false);
          setIsChangeValueReject(false);
          formReject.resetFields()
        },
        className: "confirm__modal",
        title: MESSAGE.MESSAGE_020,
        description: MESSAGE.MESSAGE_021,
        canceText: LABEL_MESSAGE.CANCEL_MODAL,
        okText: LABEL_MESSAGE.OK_MODAL,
        isCenterWithoutMenu: true,
      });
    } else {
      setVisibleReject(false);
    }
  };

  const handleDataSubmitOrReject = (keySelect: any[], data: any[]) => {
    if (!data) return [];
    const results: any[] = [];
    for (const element of data) {
      if (
        keySelect?.includes(element.id) &&
        element?.status?.code ===
        STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE
      ) {
        results.push(element.id);
      }
    }
    return results;
  };

  const handleSubmitData = async (id?: number) => {
    try {
      dispatch(setLoading(true));
      const response = await service.putMoneyApproveReceiveMoney({
        listDocumentId: id
          ? [id]
          : handleDataSubmitOrReject(selectedRow, dataTable),
      });
      if (response?.status === `${COMMON.HTTP_STATUS_OK}`) {
        SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
        setRefresh(!refresh);
        dispatch(setIsVisibleCM006(false));
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleCloseViewRejectReason = () => {
    setVisibleRejectView(false);
  };
  const handleViewRejectReason = (reason: string) => {
    setRejectReason(reason);
    setVisibleRejectView(true);
  };
  const handleSortTable = (sorter: any) => {
    searchParams.set(
      PARAMS.sortType,
      sorter.order === "ascend" ? "ASC" : "DESC"
    );
    setSearchParams(searchParams);
  };

  const handleViewModalCM006 = (record: any) => {
    dispatch(setIsVisibleCM006(true));
    dispatch(
      setDocumentInfo({
        createdDocument: record?.createdAt
          ? moment(record.createdAt).format(COMMON.FORMAT_DATE_CI)
          : "",
        submittedDocument: record?.submittedDate
          ? moment(record.submittedDate).format(COMMON.FORMAT_DATE_CI)
          : "",
      })
    );
    dispatch(setDocumentIdCM006(record?.id));
    setDetailSubmit({
      id: record?.id,
      status: record?.status?.code,
    });
  };

  const funcDisableBtn = () => {
    return !dataTable?.some(
      (element) => element?.canApprove && selectedRow?.includes(element.id)
    );
  };

  return {
    dataTable,
    page,
    size,
    total,
    selectedRow,
    formReject,
    visibleReject,
    isChangeValueReject,
    visibleRejectView,
    rejectReason,
    sortType,
    status,
    keyword,
    from,
    to,
    clear,
    refresh,
    detailSubmit,

    onPageChange,
    onSizeChange,
    handleChangeSelectedRow,
    handleSubmitReject,
    handleChangeValueReject,
    handleCancelReject,
    handleRejectData,
    handleCloseViewRejectReason,
    handleViewRejectReason,
    handleSortTable,
    handleViewModalCM006,
    getDataView,
    handleSubmitData,
    funcDisableBtn,
  };
};

export default CM047Handler;
