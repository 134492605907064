import { useDispatch } from "react-redux";
import { ServiceImpl } from "../../usecase/ServiceImpl";
import { setLoading } from "../../../../common/slice/CommonSlice";
import ErrorNotification from "../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../common/constants/MESSAGE";
import { setAuth } from "../../../../common/slice/AuthSlice";
import helpers from "../../../../common/helpers/common";
import { useState } from "react";
import moment from "moment";

export const Handler = (service: ServiceImpl) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const dispatch = useDispatch();

  const callRefreshToken = async () => {
    const userLocalStorage = helpers.getObjectFromLocalStorage("user");

    const responseResults = await service.postRefreshToken({
      refreshToken: userLocalStorage.refreshToken,
    });
    dispatch(setAuth(responseResults));
  };

  const checkRefreshToken = async () => {
    try {
      dispatch(setLoading(true));
      const userLocalStorage = helpers.getObjectFromLocalStorage("user");
      if (!userLocalStorage) return;

      const shouldRefreshAtDate = moment(userLocalStorage.shouldRefreshAt);
      if (!shouldRefreshAtDate) await callRefreshToken();
      else {
        const currentDate = moment();
        const diffNumber = currentDate.diff(shouldRefreshAtDate);
        if (diffNumber > 0) await callRefreshToken();
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
      setIsChecked(true);
    }
  };
  return {
    checkRefreshToken,
    isChecked,
  };
};
