import { Button, DatePicker, Form, Input, Modal, Spin } from "antd";
import { useSelector } from "react-redux";
import React, { useEffect } from "react";

import LoadingComponent from "../../../../../common/components/icons/LoadingComponent";
import COMMON, { MAX_LENGTH, PLAN_TYPE } from "../../../../../common/constants/COMMON";
import REGEX from "../../../../../common/constants/REGEX";
import helpers from "../../../../../common/helpers/common";
import CertificationFormItemComponent from "../../../../../common/components/certification-form-item/CertificationFormItemComponent";
import {
  inputPhoneNumberFormat,
  typeInteger,
} from "../../../../../common/helpers/typeInput";
import { CertificateEntity } from "../../entity/Entity";
import { RootState } from "../../../../../store";
import { OM004Hanlder } from "../handler/HandlerImpl";
import locale from "antd/es/date-picker/locale/ja_JP";
import "moment/locale/ja";
import "../../../../../assets/styles/OM/OM002.css";
import "../../../../../assets/styles/OM/OM004.css";
import "../../../../../assets/styles/OM/OM007.css";
import CalendarSvg from "../../../../../assets/icons/calendar.svg";

import XIconModal from "../../../../../assets/icons/x-icon-modal.svg";
import MESSAGE from "../../../../../common/constants/MESSAGE";
import UploadAvatarComponent from "../../../../../common/components/upload-image";
import InfiniteScrollSelectComponent from "../../../../../common/components/infinity-scroll-select";
import useWindowDimensions from "../../../../../common/helpers/windowDimension";
type Props = {
  handler: OM004Hanlder;
};

const OM004UI = ({ handler }: Props) => {
  const isVisible = useSelector((state: RootState) => state.om004.isVisible);
  const loading = useSelector((state: RootState) => state.om004.loading);
  const isPlanFree =
    useSelector((state: RootState) => state.auth.authData?.order?.code) ===
    PLAN_TYPE.PLAN_FREE;

  useEffect(() => {
    if (isVisible) handler.getCertificateType();
  }, [isVisible]);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (handler.isDirty) {
      window.addEventListener("beforeunload", helpers.alertUser);
      return () => {
        window.removeEventListener("beforeunload", helpers.alertUser);
      };
    } else {
      window.removeEventListener("beforeunload", helpers.alertUser, true);
    }
  }, [handler.isDirty]);

  return !isVisible ? null : (
    <Modal
      className="group-detail-modal modal-fixed-1572"
      style={{ marginLeft: width > 1872 ? 300 : 0, marginTop: 40, marginBottom: 40 }}
      visible={true}
      closable={false}
      maskClosable={false}
      centered={width > 1700}
      width={1572}
      footer={
        <>
          <Button
            disabled={loading}
            size="large"
            type="primary"
            style={{ marginLeft: 16, width: 76, letterSpacing: -1.5 }}
            htmlType="submit"
            form="formCreateMember"
          >
            保存
          </Button>
          <Button
            disabled={loading}
            size="large"
            style={{ marginLeft: 16, width: 118 }}
            onClick={handler.onCancel}
          >
            キャンセル
          </Button>
        </>
      }
    >
      <Spin indicator={LoadingComponent} spinning={loading}>
        <div
          className="w-100-percent header-modal-containter"
          style={{ height: 50 }}
        >
          <div className="header-modal-title-om004 ">メンバー追加</div>
          <div style={{ cursor: "pointer" }} onClick={handler.onCancel}>
            <img alt="" src={XIconModal} style={{ height: 18, width: 18 }} />
          </div>
        </div>
        <div style={{ minHeight: 224, marginTop: 24 }}>
          <Form
            onValuesChange={handler.checkDirty}
            form={handler.form}
            id="formCreateMember"
            layout="horizontal"
            onFinish={handler.createNewMember}
            autoComplete="off"
          >
            <div style={{ display: "flex" }}>
              <div>
                <UploadAvatarComponent
                  getBinary={handler.getAvatarBinary}
                  style={{ marginLeft: 158 }}
                  form={handler.form}
                />
              </div>
              <div className="center-item avatar-username">New User</div>
            </div>
            <div style={{ display: "flex" }}>
              <div>
                <Form.Item
                  name="name"
                  labelAlign="right"
                  colon={false}
                  className="form-item-label "
                  label={
                    <div
                      className="w-100-percent pr-20 form-label-member"
                      style={{ marginLeft: 208 }}
                    >
                      名前<span style={{ color: "#FE0909" }}>*</span>
                    </div>
                  }
                  style={{ marginBottom: 16 }}
                  rules={[
                    {
                      whitespace: true,
                      message: MESSAGE.MESSAGE_REQUIRED_FIELD,
                    },
                    {
                      required: true,
                      message: MESSAGE.MESSAGE_REQUIRED_FIELD,
                    },
                    {
                      pattern: new RegExp(REGEX.MEMBER_NAME_REGEX),
                      message: MESSAGE.MESSAGE_60,
                    },
                    () => ({
                      validator(_, value) {
                        if (!value || !value.trim()) return Promise.resolve();
                        const valueArr = value.split("");
                        const isOnlyBracket = valueArr.every(
                          (element: any) => element === "(" || element === ")"
                        );
                        return isOnlyBracket
                          ? Promise.reject(new Error(MESSAGE.MESSAGE_60))
                          : Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    style={{ height: 40, width: 375 }}
                    maxLength={20}
                    onChange={(e) => {
                      helpers.preventInputJapanese(e);
                    }}
                    placeholder="例：山田　太郎"
                    onBlur={() =>
                      helpers.onBlurTrimFormItem(handler.form, "name")
                    }
                  />
                </Form.Item>
                <div style={{ marginLeft: 215 }}>
                  <InfiniteScrollSelectComponent
                    isFormCol={true}
                    widthProp={375}
                    formLabel="部門"
                    formName="branchId"
                    className="filter-table"
                    nameProp="name"
                    valueProp="id"
                    placeholderProp="所属部門"
                    sizeProp="large"
                    fetchData={handler.getGroups}
                    isCal50={true}
                  />
                </div>
                {!isPlanFree ? (
                  <div style={{ marginLeft: 208 }}>
                    <InfiniteScrollSelectComponent
                      isFormCol={true}
                      widthProp={375}
                      formLabel="役職"
                      formName="roleId"
                      className="filter-table"
                      nameProp="name"
                      valueProp="id"
                      placeholderProp="役職"
                      sizeProp="large"
                      fetchData={handler.getRoleList}
                      isCal50={true}
                      required={true}
                      rules={[
                        {
                          required: true,
                          message: MESSAGE.MESSAGE_REQUIRED_FIELD,
                        },
                      ]}
                    />
                  </div>
                ) : (
                  <></>
                )}
                <div style={{ display: "flex", marginLeft: 152 }}>
                  <Form.Item
                    name="CCUSID"
                    labelAlign="right"
                    colon={false}
                    className="form-item-label error-message-300"
                    label={
                      <div className="w-100-percent pr-20 form-label-member">
                        CCUS技能者ID
                      </div>
                    }
                    style={{ marginBottom: 16, width: 280 }}
                    rules={[
                      () => ({
                        validator(_, value) {
                          const expirationDate =
                            handler.form.getFieldValue("expirationDate");
                          if (
                            (!value && expirationDate) ||
                            (value && value.length < 17) ||
                            (value &&
                              !REGEX.PHONENUMBER.test(
                                value.replaceAll(" ", "")
                              ))
                          )
                            return Promise.reject(MESSAGE.MESSAGE_LESS_THAN_14);
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input
                      onKeyPress={typeInteger}
                      onPaste={typeInteger}
                      onChange={(e: any) => {
                        helpers.preventInputJapanese(e);
                        helpers.inputCCUSFormat(e, "CCUSID", handler.form);
                      }}
                      style={{ height: 40, width: 159 }}
                      maxLength={17}
                      placeholder="xxxx xxxx xxxx xx"
                    />
                  </Form.Item>
                  <Form.Item
                    name="expirationDate"
                    labelAlign="right"
                    colon={false}
                    className="form-item-label "
                    label={
                      <div className="w-100-percent  form-label-member">
                        有効期限
                      </div>
                    }
                    style={{ marginBottom: 16, marginLeft: 14, width: 200 }}
                  >
                    <DatePicker
                      onChange={handler.onExpirationDateChange}
                      format={COMMON.FORMAT_DATE}
                      className="ci001-date-picker"
                      inputReadOnly
                      locale={locale}
                      getPopupContainer={(trigger: any) => trigger.parentNode}
                      placeholder="[ ---- / -- / -- ]"
                      suffixIcon={
                        <img
                          alt=""
                          src={CalendarSvg}
                          className="icon-calender"
                        />
                      }
                      style={{ width: 136 }}
                    />
                  </Form.Item>
                </div>
              </div>
              <div>
                <Form.Item
                  style={{ marginLeft: 237, marginBottom: 16 }}
                  name="address"
                  labelAlign="right"
                  colon={false}
                  className="form-item-label "
                  label={
                    <div className="w-100-percent pr-20 form-label-member">
                      住所
                    </div>
                  }
                  rules={[
                    {
                      whitespace: true,
                      message: MESSAGE.MESSAGE_REQUIRED_FIELD,
                    },
                    {
                      pattern: new RegExp(REGEX.EXCEPT_VIETNAMESE),
                      message: MESSAGE.MESSAGE_INVALID_INPUT,
                    },
                  ]}
                >
                  <Input
                    style={{ height: 40, width: 375 }}
                    maxLength={MAX_LENGTH.ADDRESS}
                    onChange={(e) => {
                      helpers.preventInputJapanese(e);
                    }}
                    placeholder="例：東京都渋谷区神南1-2-3 シブヤビルディング101号室"
                    onBlur={() =>
                      helpers.onBlurTrimFormItem(handler.form, "address")
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="phoneNumber"
                  labelAlign="right"
                  colon={false}
                  style={{ marginBottom: 16, marginLeft: 202 }}
                  className="form-item-label "
                  label={
                    <div className="w-100-percent pr-20 form-label-member">
                      電話番号<span style={{ color: "#FE0909" }}>*</span>
                    </div>
                  }
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (value && value !== "") {
                          value = value.trim().replaceAll(" ", "");
                          value = value.replaceAll("-", "");
                          if (!REGEX.PHONENUMBER_2.test(value))
                            return Promise.reject(
                              MESSAGE.MESSAGE_WRONG_PHONE_FORMAT
                            );
                          if (value?.length < 10) {
                            return Promise.reject(
                              MESSAGE.MESSAGE_INVALID_INPUT
                            );
                          }

                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(MESSAGE.MESSAGE_REQUIRED_FIELD)
                        );
                      },
                    }),
                  ]}
                >
                  <Input
                    style={{ height: 40, width: 375 }}
                    placeholder="例：09011112222　ハイフン(ー)なし　半角"
                    maxLength={COMMON.MAX_LENGTH_PHONE_NUMBER}
                    onKeyPress={typeInteger}
                    onPaste={typeInteger}
                    onChange={(e) => {
                      helpers.preventInputJapanese(e);
                      inputPhoneNumberFormat(e, "phoneNumber", handler.form);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  style={{ marginLeft: 245, marginBottom: 16 }}
                  name="id"
                  labelAlign="right"
                  colon={false}
                  className="form-item-label "
                  label={
                    <div className="w-100-percent pr-20 form-label-member">
                      ID<span style={{ color: "#FE0909" }}>*</span>
                    </div>
                  }
                  rules={[
                    () => ({
                      validator(_, value) {
                        // required
                        if (!value || value.trim() === "")
                          return Promise.reject(MESSAGE.MESSAGE_REQUIRED_FIELD);
                        // more than 6
                        if (value.trim().length < 6)
                          return Promise.reject(
                            MESSAGE.MESSAGE_INVALID_MORE_THAN_SIX
                          );
                        // test regex
                        if (!REGEX.ONLY_LATIN_2.test(value.trim())) {
                          return Promise.reject(
                            MESSAGE.MESSAGE_ID_SPECIAL_CHARACTER
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    style={{ height: 40, width: 375 }}
                    maxLength={20}
                    onChange={(e) => {
                      helpers.preventInputJapanese(e);
                    }}
                    placeholder="例：yamadataro"
                    onBlur={() =>
                      helpers.onBlurTrimFormItem(handler.form, "id")
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="email"
                  style={{ marginLeft: 160, marginBottom: 16 }}
                  labelAlign="right"
                  colon={false}
                  className="form-item-label "
                  label={
                    <div className="w-100-percent pr-20 form-label-member">
                      メールアドレス
                      <span style={{ color: "#FE0909" }}>*</span>
                    </div>
                  }
                  rules={[
                    () => ({
                      validator(_, value) {
                        // required
                        if (!value || value.trim() === "")
                          return Promise.reject(MESSAGE.MESSAGE_REQUIRED_FIELD);
                        // test regex
                        if (!REGEX.EMAIL_COMPANY.test(value.trim())) {
                          return Promise.reject(
                            MESSAGE.MESSAGE_WRONG_EMAIL_FORMAT
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    style={{ height: 40, width: 375 }}
                    maxLength={50}
                    onChange={(e) => {
                      helpers.preventInputJapanese(e);
                    }}
                    placeholder="例：user@oneunit.com"
                    onBlur={() =>
                      helpers.onBlurTrimFormItem(handler.form, "email")
                    }
                  />
                </Form.Item>
              </div>
            </div>
            <div
              className="registration-title"
              style={{ marginLeft: 20, width: 114, height: 24, marginTop: 8 }}
            >
              保有資格
            </div>
            {handler.certType.map((element: CertificateEntity) => (
              <React.Fragment key={element.id}>
                <CertificationFormItemComponent
                  form={handler.form}
                  typeId={element.id + ""}
                  label={element.description}
                />
              </React.Fragment>
            ))}
          </Form>
        </div>
      </Spin>
    </Modal>
  );
};

export default OM004UI;
