import MESSAGE from "../constants/MESSAGE";
import { TEXT } from "../constants/TEXT";

type Codes = keyof typeof TEXT;

/**
 * @deprecated use `text` function instead(`msg` will be renamed to `text`)
 */
export function msg(code: string, ...args: (string | number)[]): string {

  if (!code) {
    return '';
  }

  let rawMsg = TEXT as any

  code.split('.').forEach(item => {
    rawMsg = rawMsg[item]
  })
  // const rawMsg = TEXT[code] as string;



  return rawMsg.replace(/{(\d+)}/g, function (match: any, number: number) {
    return args[`${number}`] ? args[`${number}`] : match;
  });
}

/**
 * Note: This function is declared as global function, so there is no need to import.
 */
export function text(code: string, ...args: (string | number)[]): string {
  return msg(code, ...args);
}
