import CM0026Api from './infrastructure/api/Api';
import CM0026Handler from './presenter/handler/Handler';
import CM0026Service from './usecase/Service';
import MainUI from './presenter/ui/UI';

import '../../../assets/styles/CM/cm024.css';
import '../../../assets/styles/CM/cm026.css';
import '../../../assets/styles/CM/cm3854.css';
import '../../../assets/styles/CM/cm047.css';

const CM0026Component = () => {
    const api = CM0026Api();

    const service = CM0026Service(api);

    const handler = CM0026Handler(service);

    return <MainUI handler={handler} />;
};

export default CM0026Component;
