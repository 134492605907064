import { AxiosResponse } from "axios";
import axiosConfig from "../../../../../config/axiosConfig";
import {
  MoneyDirectCostMaterialCreatePayload,
  MoneyDirectCostMaterialDeletePayload,
  MoneyDirectCostMaterialUpdatePayload,
} from "../../entity/Entity";
import axios from "axios";

const CollapsedTableApi = () => {
  const postMoneyDirectCostMaterialCreate = async (
    data: MoneyDirectCostMaterialCreatePayload
  ): Promise<AxiosResponse<any, any>> => {
    const url = "/money/direct-cost/material/create";
    return await axiosConfig.post(url, data);
  };

  const getMoneyDirectCostMaterialList = async (params: {
    documentDirectMaterialCostId: number;
    page?: number;
    size?: number;
    sortBy?: string;
    sortType?: string;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/money/direct-cost/material/list";
    return await axiosConfig.get(url, { params });
  };

  const putMoneyDirectCostMaterialUpdate = async (
    data: MoneyDirectCostMaterialUpdatePayload
  ): Promise<AxiosResponse<any, any>> => {
    const url = "/money/direct-cost/material/update";
    return await axiosConfig.put(url, data);
  };

  const deleteMoneyDirectCostMaterialDelete = async (
    data: MoneyDirectCostMaterialDeletePayload
  ): Promise<AxiosResponse<any, any>> => {
    const url = "/money/direct-cost/material/delete";
    return await axiosConfig.delete(url, { data });
  };

  const getLinkPresignUpload = async (
    params: any
  ): Promise<AxiosResponse<any, any>> => {
    const url = "/project/presign-upload";
    return await axiosConfig.get(url, { params });
  };

  const uploadToS3 = async (
    url: any,
    data: any
  ): Promise<AxiosResponse<any, any>> => {
    return await axios.put(url, data, {
      headers: {
        "Content-Type": data.type,
      },
    });
  };

  const getPresignLinkS3 = async (
    params: any
  ): Promise<AxiosResponse<any, any>> => {
    const url = "/project/presign-link";
    return await axiosConfig.get(url, { params });
  };
  return {
    postMoneyDirectCostMaterialCreate,
    getMoneyDirectCostMaterialList,
    putMoneyDirectCostMaterialUpdate,
    deleteMoneyDirectCostMaterialDelete,
    getLinkPresignUpload,
    uploadToS3,
    getPresignLinkS3,
  };
};

export default CollapsedTableApi;
