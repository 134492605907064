import { createSlice } from '@reduxjs/toolkit';

type DeleteAOPModalModalSlice = {
    isOpenDeleteAOPModal: boolean;
};
const initialState: DeleteAOPModalModalSlice = {
    isOpenDeleteAOPModal: false,
};

export const DeleteAOPModalModalSlice = createSlice({
    name: 'DeleteAOPModalModalSlice',
    initialState,
    reducers: {
        showDeleteAOPModalModal: (state) => {
            state.isOpenDeleteAOPModal = true;
        },
        hideDeleteAOPModalModal: (state) => {
            state.isOpenDeleteAOPModal = false;
        },
    },
});

export const { showDeleteAOPModalModal, hideDeleteAOPModalModal } =
    DeleteAOPModalModalSlice.actions;
export default DeleteAOPModalModalSlice.reducer;
