import helpers from "../../../helpers/common";

const CustomTooltip = ({ active, payload }: any) => {
  const data = payload[0]?.payload;
  if (active && payload && payload.length) {
    return (
      <div className="db001-composechart__tooltip--custom">
        <span className="db001-composechart__tooltip--content">
          {data.year}年{data.month.split("-")[1]}月
        </span>
        <span className="db001-composechart__tooltip--content">
          稼働原価: {helpers.formatNumberWithCommas(data.laborCost || 0)}円
        </span>
        <span className="db001-composechart__tooltip--content">
          稼働人工数: {helpers.formatNumberWithCommas(data.workingDay || 0)}人工
        </span>
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
