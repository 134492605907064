import React from "react";
import DoublePrevious from "../../../../../assets/icons/double-previous.svg";
import DoubleNext from "../../../../../assets/icons/double-next.svg";
import ExpandedIconSvg from "../../../../../assets/icons/expanded-icon.svg";
import ClosedIconSvg from "../../../../../assets/icons/closed-icon.svg";
import { ConfigProvider } from "antd";
import type { TableColumnsType } from "antd";
import { Table } from "antd";
import { DateColumn } from "../../entity/Entity";
import AttendanceComponent from "../component/attendance";
import moment from "moment";
import COMMON from "../../../../../common/constants/COMMON";
import HeaderMemberRowComponent from "./HeaderMemberRow";
import MemberRowComponent from "./MemberRow";
import { convertToDataSourceList } from "../../../AM006/helper";
import NoDataTableComponent from "../../../../../common/components/no-data/NoDataTable";

export const getHeaderTitle = (inputDate: DateColumn) => {
  const value = inputDate.name.split("(");
  const currentDate = moment().format(COMMON.FORMAT_DATE2);
  return (
    <main
      style={{
        display: "flex",
        justifyContent: "flex-start",
        color: currentDate === inputDate.value ? "#605BFF" : "#666666",
        paddingBottom: 16,
      }}
    >
      <div
        style={{
          textAlign: "center",
          float: "left",
          borderTop:
            currentDate === inputDate.value
              ? "3px solid #605BFF"
              : "3px solid #f0f0f0",
          paddingTop: 13,
        }}
      >
        <span>{`${value[0]}`}</span>
        <br></br>
        <span>{`(${value[1]}`}</span>
      </div>
    </main>
  );
};
interface props {
  onNext: () => void;
  onPrev: () => void;
  setExpandKey(value: any): void;
  isCollapsed: (record: any) => boolean;
  onChangeCollapseRow: (record: any, collapsed: boolean) => void;
  columnsTable: DateColumn[];
  dataSource: any[];
  isPrev: boolean;
  isNext: boolean;
  constructionId?: string;
  tab: string;
  expandKey: any[];
  noData: boolean;
}

const TableComponent = ({
  tab,
  isPrev,
  isNext,
  columnsTable,
  dataSource,
  constructionId,
  expandKey,
  onNext,
  onPrev,
  setExpandKey,
  onChangeCollapseRow,
  isCollapsed,
  noData,
}: props) => {
  const getColumns = () => {
    const columns: TableColumnsType<any> = [
      {
        title: <div style={{ paddingLeft: 16 }}>日付</div>,
        key: "date",
        width: "23%",
        onCell: (_, index) => ({
          colSpan: columnsTable.length + 3,
        }),
        render: (_, record, index) => {
          // if (record.key === 'header') return <HeaderMemberRowComponent tab={tab} />;
          if (record.key === "nodata") return <NoDataTableComponent />;
          else return <MemberRowComponent record={record} index={index + 1} />;
        },
      },
    ];
    columns.push({
      title: isPrev ? (
        <div
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            justifyContent: "flex-end",
          }}
          onClick={onPrev}
        >
          <img alt="" src={DoublePrevious} />
        </div>
      ) : (
        <></>
      ),
      key: "prev",
      width: 29,
      onCell: (_, index) => ({
        colSpan: 0,
      }),
    });
    for (let i = 0; i < columnsTable.length; i++) {
      columns.push({
        title: getHeaderTitle(columnsTable[i]),
        key: i,
        width: `${77 / columnsTable.length}%`,
        onCell: (_, index) => ({
          colSpan: 0,
        }),
      });
    }
    columns.push({
      title: (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {isNext ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={onNext}
            >
              <img alt="" src={DoubleNext} />
            </div>
          ) : (
            <></>
          )}
        </div>
      ),
      key: "next",
      width: 70,
      onCell: (_, index) => ({
        colSpan: 0,
      }),
    });
    columns.push({
      title: "",
      key: "collapsed",
      width: 40,
      render: (record) => {
        return isCollapsed(record) ? (
          <div
            style={{ cursor: "pointer" }}
            onClick={(e: any) => onChangeCollapseRow(record, false)}
          >
            <img alt="" src={ExpandedIconSvg} />
          </div>
        ) : (
          <div
            style={{ cursor: "pointer" }}
            onClick={(e: any) => onChangeCollapseRow(record, true)}
          >
            <img alt="" src={record.key === "nodata" ? "" : ClosedIconSvg} />
          </div>
        );
      },
    });
    return columns;
  };

  return (
    <div className="pm025-table ">
      <Table
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) =>
              isCollapsed(record)
                ? onChangeCollapseRow(record, false)
                : onChangeCollapseRow(record, true), // click row
          };
        }}
        scroll={{
          x: 0,
          y: 500,
        }}
        bordered={false}
        rowClassName={(record) => {
          if (record.key === "header") return "row-member-header button-style";
          else if (record.key === "nodata")
            return "row-no-data no-click button-style";
          return "row-company button-style";
        }}
        id="main-table"
        className={`am-main-table calendar-header ${
          noData && "no-border-table"
        }`}
        style={{ display: "flex", flexDirection: "column-reverse" }}
        columns={getColumns()}
        dataSource={convertToDataSourceList(
          noData && tab == "1" ? [] : dataSource
        )}
        pagination={false}
        expandable={{
          expandedRowKeys: expandKey,
          showExpandColumn: false,
          expandRowByClick: true,
          onExpandedRowsChange: (value) => setExpandKey(value),
          expandedRowRender: (record: any) => (
            <AttendanceComponent
              attendance={record.attendance}
              columnsTable={columnsTable}
              constructionId={
                constructionId ? parseInt(constructionId || "") : undefined
              }
              userId={record.id}
            />
          ),
          expandIcon: ({ expanded, onExpand, record }) => {
            if (record.key === "header" || record.key === "nodata")
              return <></>;
            return expanded ? (
              <div
                style={{ cursor: "pointer" }}
                onClick={(e: any) => onExpand(record, e)}
              >
                <img alt="" src={ExpandedIconSvg} />
              </div>
            ) : (
              <div
                style={{ cursor: "pointer" }}
                onClick={(e: any) => onExpand(record, e)}
              >
                <img alt="" src={ClosedIconSvg} />
              </div>
            );
          },
        }}
        summary={() => {
          return (
            <Table.Summary fixed="top">
              <Table.Summary.Row style={{ background: "#F0F0F0" }}>
                {columnsTable.map((element, index) => (
                  <Table.Summary.Cell
                    key={index}
                    index={index + 1}
                    colSpan={0}
                  ></Table.Summary.Cell>
                ))}
                <Table.Summary.Cell index={0} colSpan={columnsTable.length + 4}>
                  <HeaderMemberRowComponent tab={tab} />
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
      />
    </div>
  );
};

export default TableComponent;
