import ErrorNotification from '../../../../common/components/notification/ErrorNotification';
import COMMON from '../../../../common/constants/COMMON';
import { NOTIFICATION_TITLE } from '../../../../common/constants/MESSAGE';
import {
    ParamsGroupMemberList,
    ParamsPermissionList,
    ParamsRoleDetail,
    ParamsRoleUserList,
    PermissionUpdatePayload,
    RoleCreatePayload,
    RoleUpdatePayload,
    RoleUserUpdatePayload,
} from '../entity/Entity';
import { AOP003APIImpl } from './ServiceImpl';
const AOP003Service = (api: AOP003APIImpl) => {
    const getGroupListMember = async (params: ParamsGroupMemberList) => {
        try {
            const response = await api.getGroupListMember(params);
            return response?.data;
        } catch (error: any) {
            ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };
    const getRoleDetail = async (params: ParamsRoleDetail) => {
        try {
            const response = await api.getRoleDetail(params);
            return response?.data;
        } catch (error: any) {
            ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };

    const getRoleUserList = async (params: ParamsRoleUserList) => {
        try {
            const response = await api.getRoleUserList(params);
            return response?.data;
        } catch (error: any) {
            ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };

    const getPermissionList = async (params: ParamsPermissionList) => {
        try {
            const response = await api.getPermissionList(params);
            return response?.data;
        } catch (error: any) {
            ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };

    const getRolePermissionCategory = async () => {
        try {
            const response = await api.getRolePermissionCategory();
            return response?.data;
        } catch (error: any) {
            ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };

    const postRoleCreate = async (payload: RoleCreatePayload) => {
        try {
            const response = await api.postRoleCreate(payload);
            return response?.data;
        } catch (error: any) {
            throw error;
        }
    };

    const putRoleUpdate = async (payload: RoleUpdatePayload) => {
        try {
            const response = await api.putRoleUpdate(payload);
            return response?.data;
        } catch (error: any) {
            throw error;
        }
    };
    const putRoleUserUpdate = async (payload: RoleUserUpdatePayload) => {
        try {
            const response = await api.putRoleUserUpdate(payload);
            return response?.data;
        } catch (error: any) {
            throw error;
        }
    };

    const postPermission = async (payload: PermissionUpdatePayload) => {
        try {
            const response = await api.postPermission(payload);
            return response?.data;
        } catch (error: any) {
            throw error;
        }
    };
    return {
        getGroupListMember,
        getRoleDetail,
        getRoleUserList,
        getPermissionList,
        getRolePermissionCategory,
        postRoleCreate,
        putRoleUpdate,
        postPermission,
        putRoleUserUpdate,
    };
};

export default AOP003Service;
