/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-var */
import React from "react";
import COMMON, { PLAN_TYPE, USER_FLOW } from "../constants/COMMON";
import { Tooltip, message, FormInstance, InputRef } from "antd";
import type { RcFile } from "antd/es/upload/interface";
import MESSAGE from "../constants/MESSAGE";
import { NamePath } from "antd/lib/form/interface";
import moment, { Moment } from "moment";
import { ItemApprove } from "../components/approval-flow/ApprovalFlow";
import { SYSTEM_ROLES } from "../constants/AUTHORIZATION";

const helpers = {
  useScript: (url: string) => {
    const script = document.createElement("script");
    script.src = url;
    script.async = true;
    document.head.appendChild(script);
  },
  toYearMonthDayHourMinuteDate: (dateString: string) => {
    const date = new Date(dateString);
    return (
      date.getFullYear() +
      "年" +
      (date.getMonth() > 10 ? date.getMonth() : "0" + date.getMonth()) +
      "月" +
      (date.getDate() > 10 ? date.getDate() : "0" + date.getDate()) +
      "日 " +
      (date.getHours() > 10 ? date.getHours() : "0" + date.getHours()) +
      ":" +
      (date.getMinutes() > 10 ? date.getMinutes() : "0" + date.getMinutes())
    );
  },
  toYearMonthDay: (dateString: string) => {
    const date = new Date(dateString);
    return (
      date.getFullYear() +
      "年" +
      (date.getMonth() > 10 ? date.getMonth() : "0" + date.getMonth()) +
      "月" +
      (date.getDate() > 10 ? date.getDate() : "0" + date.getDate()) +
      "日"
    );
  },
  checkLogin: () => {
    const user = localStorage.getItem("user");
    return user ? true : false;
  },
  getObjectFromLocalStorage: (value: any) => {
    const objectLS = JSON.parse(localStorage.getItem(value) || "{}");
    return Object.keys(objectLS).length > 0 ? objectLS : null;
  },
  clearAllCookies: () => {
    var displayCookies = document.getElementById("display");
    if (displayCookies) {
      displayCookies.innerHTML = document.cookie;
      var allCookies = document.cookie.split(";");

      // The "expire" attribute of every cookie is
      // Set to "Thu, 01 Jan 1970 00:00:00 GMT"
      for (var i = 0; i < allCookies.length; i++)
        document.cookie =
          allCookies[i] + "=;expires=" + new Date(0).toUTCString();

      displayCookies.innerHTML = document.cookie;
    }
  },

  checkFileType: (file: any) => {
    let typeFile: string = "";

    switch (true) {
      case file.type === COMMON.ALLOW_TYPE_FILE[0]:
        typeFile = COMMON.EXCEL_KEY_TYPE;
        break;
      case file.type === COMMON.ALLOW_TYPE_FILE[1]:
        typeFile = COMMON.CSV_KEY_TYPE;
        break;
      default:
        typeFile = COMMON.EXCEL_KEY_TYPE;
    }

    return typeFile;
  },
  getPhoneNumberFormat: (value: string) => {
    const rawValue = value.replaceAll("-", "");

    if (rawValue.length === 10)
      return (
        rawValue
          .slice(0, 6)
          .match(/.{1,3}/g)
          ?.join("-") +
        "-" +
        rawValue.slice(6, 10)
      );
    if (rawValue.length === 11)
      return (
        rawValue.slice(0, 3) +
        "-" +
        rawValue
          .slice(3, 11)
          .match(/.{1,4}/g)
          ?.join("-")
      );
    if (rawValue.length > 11) {
      return rawValue.match(/.{1,4}/g)?.join("-");
    }
    return rawValue;
  },
  getShortTextWithToolTip: (value?: string, limit?: number) => {
    if (!value || !limit) return "";
    if (value.length <= limit) return value;
    return (
      <Tooltip placement="top" title={value}>
        {value.slice(0, limit)}...
      </Tooltip>
    );
  },
  alertUser: (e: any) => {
    e.preventDefault();
    e.returnValue = "";
  },
  toLowerCaseNonAccentVietnamese(str: string) {
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    // Some system encode vietnamese combining accent as individual utf-8 characters
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
    return str;
  },
  formatInputNumber: (value: any) =>
    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
  parserInputNumber: (value: any) => value.replace(/\$\s?|(,*)/g, ""),
  formatTextLong: (text: string, maxLength: number) => {
    if (!text) return "";
    if (text?.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    } else {
      return text;
    }
  },
  toPhoneNumberFormat: (value: string) => {
    let results = "";
    let checkPhone = 0;
    if (value?.length > 0) {
      const phoneList = value.split("").filter((item: string) => item !== "-");
      if (phoneList.length <= 10) {
        phoneList.forEach((item: string, index: number) => {
          if (index === 5) {
            checkPhone = index;
          }
          if (index <= 2 || index === 4 || index === 5) {
            results = `${results}${item}`;
          } else if (index === 3) {
            results = `${results}-${item}`;
          } else {
            if (index === checkPhone + 1) {
              results = `${results}-${item}`;
            } else {
              results = `${results}${item}`;
            }
            if (index === checkPhone + 4) {
              checkPhone = index;
            }
          }
        });
      } else if (phoneList.length === 11) {
        phoneList.splice(3, 0, "-");
        phoneList.splice(8, 0, "-");
        results = phoneList.join("");
      } else {
        phoneList.forEach((item: string, index: number) => {
          if (index === 3) {
            checkPhone = index;
          }
          if (index <= 3) {
            results = `${results}${item}`;
          } else {
            if (index === checkPhone + 1) {
              results = `${results}-${item}`;
            } else {
              results = `${results}${item}`;
            }
            if (index === checkPhone + 4) {
              checkPhone = index;
            }
          }
        });
      }
    }
    return results;
  },

  beforeUpload: (file: RcFile) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf" ||
      file.type === "image/jpg";
    if (!isJpgOrPng) {
      message.error(MESSAGE.MESSAGE_UPLOAD_WRONG_FILE_2);
    }
    const isLt8M = file.size / 1024 / 1024 < 8;
    if (!isLt8M) {
      message.error(MESSAGE.MESSAGE_IMAGE_TOO_LARGE);
    }
    return isJpgOrPng && isLt8M;
  },
  checkRoleUpdate(id: number, projectManagerId?: number) {
    const user: any = localStorage.getItem("user");
    if (!user) return false;
    const idUser = +JSON.parse(user)?.id;
    const isAdmin =
      JSON.parse(user)?.role.code === COMMON.ADMIN_CODE ? true : false;
    const order =
      JSON.parse(user)?.order.code === COMMON.PLAN_ORDER_BASIC ? true : false;
    if (projectManagerId) {
      return idUser === id || idUser === projectManagerId || (isAdmin && order);
    }
    return idUser === id;
  },

  getBase64: (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    }),

  formatNumberWithCommas: (stringNumber: any): string => {
    return stringNumber.toLocaleString("en-US", { maximumFractionDigits: 1 });
  },
  formatNumberMoney: (
    value: number,
    fraction?: number,
    isFloor?: boolean
  ): string => {
    if (!value) return "0";
    if (isFloor) return Math.floor(value) + "";
    return value.toLocaleString("en-US", {
      maximumFractionDigits: fraction ?? 2,
    });
  },
  reFormatNumberCommas: (stringNumber: string): number => {
    return parseFloat(stringNumber.replace(/,/g, ""));
  },

  removeDuplicateItem: (stringArray: string[]): string[] => {
    let mapper: any = {};

    stringArray.forEach((item: any) => {
      mapper[item] = true;
    });

    return Object.keys(mapper);
  },

  inputCCUSFormat: (e: any, key: string, form: any) => {
    // Input: 12345678910112
    // Expect: 1234 5678 9101 12 -> 17 characters

    const inputValue = e.target.value?.trim();
    const value = inputValue ? inputValue.replaceAll(" ", "") : inputValue;
    if (!value || value.trim() === "") return;
    const parts = value.match(/.{1,4}/g);
    form.setFieldValue([key], parts.join(" "));
  },
  convertToCCUSFormat: (value: string) => {
    // Input: 12345678910112
    // Expect: 1234 5678 9101 12 -> 17 characters

    if (!value || value.trim() === "") return "";
    const parts = value.replaceAll(" ", "").match(/.{1,4}/g);
    return parts?.join(" ") || "";
  },
  onBlurTrimFormItem: (
    form: FormInstance<any>,
    formName: string | NamePath
  ) => {
    if (form.getFieldValue(formName))
      form.setFieldValue(formName, form.getFieldValue(formName).trim());
  },

  formatDateStringJA: (value: string) => {
    return moment(value).format(COMMON.FORMAT_DATE_OM);
  },
  preventInputJapanese: (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      e?.target?.value &&
      e.target.value.length > Number(e.target.getAttribute("maxLength"))
    ) {
      e.target.blur();
    }
  },

  convertStringDateUpdate: (dateString: string) => {
    if (dateString === "") return "";
    return moment(dateString).format(COMMON.FORMAT_DATE2);
  },
  checkMaxLengthTextArea: (
    form: FormInstance<any>,
    ref: React.RefObject<InputRef>,
    itemName: string,
    maxLength: number
  ) => {
    const value = form.getFieldValue(itemName);
    if (!value) return;
    if (value.length >= maxLength) ref.current?.blur();
  },

  unloadCallback: (event: any) => {
    event.preventDefault();
    event.returnValue = "";
    return "";
  },
  funcFormatter(num: number, digits: number) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "B" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item
      ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
      : num.toLocaleString("en-US", {
          maximumFractionDigits: 2,
        });
  },
  addKeyToDataSource: (input: any[]) => {
    if (Array.isArray(input))
      return input.map((element: any) => ({ ...element, key: element.id }));
    return [];
  },
  addKeyIndexToDataSource: (input: any[]) => {
    if (Array.isArray(input))
      return input.map((element: any, index: number) => ({
        ...element,
        key: index,
      }));
    return [];
  },
  combineDateTime: (date: any, time: any) => {
    if (!date && !time) return "";
    if (!date) return time?._d;
    if (!time) return date?._d;

    const dateValue =
      moment(date?._d).format(COMMON.FORMAT_DATE2) +
      " " +
      moment(time?._d).format("HH:mm");
    return moment(dateValue).toDate();
  },

  preventEnterForm: (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.code === "Enter" || e.code === "NumpadEnter" || e.key === "Enter")
      e.preventDefault();
  },

  checkIsNumber: (value: string): boolean => {
    if (!value) return false;
    if (isNaN(Number(value))) return false;
    return true;
  },

  findElementWithId: (arr: any[], id: number) => {
    if (arr.length === 0) return undefined;
    return arr.find((element: any) => element.id === id);
  },

  formatDatePresenter: (value: string, formatPattern: string) => {
    if (value) return moment(value).format(formatPattern);
    return "";
  },
  formatCurrency: (currency: number, isFloor?: boolean) => {
    if (!isFinite(currency)) return "0";
    if (!currency || isNaN(currency)) return "0";
    return Number(isFloor ? Math.floor(currency) : currency)?.toLocaleString(
      "en-US",
      {
        maximumFractionDigits: 2,
      }
    );
  },

  leftPad(number: number, targetLength: number) {
    var output = number + "";
    while (output.length < targetLength) {
      output = "0" + output;
    }
    return output;
  },
  toHoursMinutes: (totalMinutes: number) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = Math.floor(totalMinutes % 60);
    return { hours, minutes };
  },
  getNoByIndex: (index: number) => {
    const urlParams = new URLSearchParams(window.location.search);
    const page = urlParams.get("page") || COMMON.DEFAULT_PAGE + "";
    const size = urlParams.get("size") || COMMON.DEFAULT_SIZE + "";
    const moreNo = isNaN((parseInt(page) - 1) * parseInt(size))
      ? 0
      : (parseInt(page) - 1) * parseInt(size);
    return moreNo + (index + 1);
  },
  getDirectoryIcon: (color: string) => (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.875 13.2913L12.375 18.7913L11.0733 17.4897L14.3642 14.208H4.125V3.20801H5.95833V12.3747H14.3642L11.0733 9.09301L12.375 7.79134L17.875 13.2913Z"
        fill={color ?? "transparent"}
      />
    </svg>
  ),
  getBusinessDays: (startDate: string, endDate: string) => {
    const lastDay = moment(
      moment(endDate).format(COMMON.FORMAT_DATE) + " 00:00",
      COMMON.FORMAT_DATE_TIME
    );
    const firstDay = moment(
      moment(startDate).format(COMMON.FORMAT_DATE) + " 00:00",
      COMMON.FORMAT_DATE_TIME
    );
    let calcBusinessDays =
      1 +
      (lastDay.diff(firstDay, "days") * 5 -
        (firstDay.day() - lastDay.day()) * 2) /
        7;

    if (lastDay.day() === 6) calcBusinessDays--; //SAT
    if (firstDay.day() === 0) calcBusinessDays--; //SUN

    return calcBusinessDays;
  },
  getFilterTime: (time: string | Moment) => {
    if (!time) return "";
    const timeFilter = moment(
      moment(time).format(COMMON.FORMAT_DATE) + " 00:00",
      COMMON.FORMAT_DATE_TIME
    );

    return timeFilter.utc().format();
  },
  getFilterTimeTo: (time: string | Moment) => {
    if (!time) return "";
    const timeFilter = moment(
      moment(time).format(COMMON.FORMAT_DATE) + " 00:00",
      COMMON.FORMAT_DATE_TIME
    );
    return timeFilter
      .utc()
      .add(24 * 60 * 60 - 1, "seconds")
      .format();
  },

  getFilterTimeMonth: (time: string | Moment) => {
    if (!time) return "";
    const timeFilter = moment(time).startOf("month");
    return timeFilter.utc().format();
  },
  getFilterTimeMonthTo: (time: string | Moment) => {
    if (!time) return "";
    const timeFilter = moment(time).endOf("month");
    return timeFilter.utc().format();
  },

  toASCII: (chars: string) => {
    var ascii = "";
    for (var i = 0, l = chars.length; i < l; i++) {
      var c = chars[i].charCodeAt(0);

      // make sure we only convert half-full width char
      if (c >= 0xff00 && c <= 0xffef) {
        c = 0xff & (c + 0x20);
      }

      ascii += String.fromCharCode(c);
    }

    return ascii;
  },
  getCompanyIdFromLocalStorage: () => {
    const userLocalStorage = helpers.getObjectFromLocalStorage("user");
    if (userLocalStorage) return userLocalStorage?.company?.id ?? null;
    return null;
  },
  getStartDayOfMonthParams: (value: string): string | undefined => {
    if (!moment(value).isValid()) return undefined;
    const startOfMonth = moment(value)
      .startOf("month")
      .format(COMMON.FORMAT_DATE2);
    return startOfMonth + COMMON.ISO_8601_HH_MM_SS;
  },
  getEndDayOfMonthParams: (value: string): string | undefined => {
    if (!moment(value).isValid()) return undefined;
    const endOfMonth = moment(value).endOf("month").format(COMMON.FORMAT_DATE2);
    return endOfMonth + COMMON.ISO_8601_HH_MM_SS;
  },
  calculateRateAmount: (a: number, b: number): string => {
    if (a !== 0)
      return `${helpers.formatNumberMoney(
        Math.round((b / a) * 100 * 100) / 100
      )}`;
    return "0";
  },
  toTargetPeriodFormat: (startDate?: string, endDate?: string) => {
    if (!startDate && !endDate) return "";
    return `${
      startDate ? moment(startDate).format(COMMON.FORMAT_DATE_OM) : ""
    } ~ ${endDate ? moment(endDate).format(COMMON.FORMAT_DATE_OM) : ""}`;
  },
  getStartEndDate: (startDate?: string, endDate?: string) => {
    if (!startDate && !endDate) return "";
    return `${
      startDate ? moment(startDate).format(COMMON.FORMAT_DATE_OM) : ""
    }～${endDate ? moment(endDate).format(COMMON.FORMAT_DATE_OM) : ""}`;
  },
  toTargetPeriodFormatYYYYMM: (startDate?: string, endDate?: string) => {
    if (!startDate && !endDate) return "";
    return `${
      startDate ? moment(startDate).format(COMMON.FORMAT_DATE_JA_MONTH) : ""
    }～${endDate ? moment(endDate).format(COMMON.FORMAT_DATE_JA_MONTH) : ""}`;
  },
  getRandomColor: () => {
    const WHITE_HEX = "ffffff";
    let color = "";

    do {
      color = Math.floor(Math.random() * 16777215).toString(16);

      if (color.length < 6) color += "a";
    } while (color === WHITE_HEX);

    return color;
  },

  getLocalUsername: () => {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    return user?.username ?? null;
  },
  checkButtonWithWorkflowRolePM: (
    currentUser: any,
    listApprover: any[],
    managerId: number
  ) => {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const checkManagerOther = listApprover.some(
      (ele) => ele.id === managerId && ele.priority !== 1
    );
    const checkUserFlow = listApprover.some((ele) => ele.id === +user?.id);

    if (+user?.id === managerId) {
      if (!checkManagerOther) {
        if (currentUser?.id === +user?.id) return USER_FLOW.MANAGER_SUBMIT;
        return USER_FLOW.MANAGER_SUBMITTED;
      } else {
        if (currentUser?.priority === 1) return USER_FLOW.MANAGER_SUBMIT;
        if (currentUser?.id === +user?.id && !currentUser?.reject)
          return USER_FLOW.MANAGER_FLOW_SUBMIT;
        return USER_FLOW.MANAGER_FLOW_SUBMITTED;
      }
    } else {
      if (checkUserFlow) {
        if (currentUser?.id === +user?.id && !currentUser?.reject)
          return USER_FLOW.FLOW_SUBMIT;
        return USER_FLOW.FLOW_SUBMITTED;
      }
      return USER_FLOW.USER;
    }
  },
  checkButtonWithWorkflowRolePMAdmin: (
    currentUser: any,
    listApprover: any[],
    managerId: number
  ) => {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const isRoleAdminBasic =
      user?.role?.code === SYSTEM_ROLES.ADMIN ||
      user?.order?.code === PLAN_TYPE.PLAN_BASIC;

    const checkManagerOther = listApprover.some(
      (ele) => ele.id === managerId && ele.priority !== 1
    );
    const checkUserFlow = listApprover.some((ele) => ele.id === +user?.id);

    if (isRoleAdminBasic) {
      const checkApprovedFlow = listApprover.some((ele) => !!ele?.isApprove);
      if (!checkApprovedFlow) return USER_FLOW.MANAGER_SUBMIT;
    }
    if (+user?.id === managerId) {
      if (!checkManagerOther) {
        if (currentUser?.id === +user?.id) return USER_FLOW.MANAGER_SUBMIT;
        return USER_FLOW.MANAGER_SUBMITTED;
      } else {
        if (currentUser?.priority === 1) return USER_FLOW.MANAGER_SUBMIT;
        if (currentUser?.id === +user?.id && !currentUser?.reject)
          return USER_FLOW.MANAGER_FLOW_SUBMIT;
        return USER_FLOW.MANAGER_FLOW_SUBMITTED;
      }
    } else {
      if (checkUserFlow) {
        if (currentUser?.id === +user?.id && !currentUser?.reject)
          return USER_FLOW.FLOW_SUBMIT;
        return USER_FLOW.FLOW_SUBMITTED;
      }
      return USER_FLOW.USER;
    }
  },
  checkUsernameWorkflow: (currentUserApproval: ItemApprove) => {
    const user = helpers.getObjectFromLocalStorage("user");
    if (!user.username) return false;
    if (user.username !== currentUserApproval.username) return false;
    return true;
  },
  checkApproveInWorkFlow: (currentUserApproval: ItemApprove) => {
    if (!helpers.checkUsernameWorkflow(currentUserApproval)) return false;
    if (currentUserApproval.isApprove) return false;
    return true;
  },
  checkRejectInWorkFlow: (currentUserApproval: ItemApprove) => {
    if (!helpers.checkUsernameWorkflow(currentUserApproval)) return false;
    if (currentUserApproval.reject) return false;
    return true;
  },
  roundFloatNumber: (num: number, decimal: number) => {
    if (Number.isInteger(num)) return num;
    return num.toFixed(decimal);
  },

  getMaxValueYAxis: (num: number) => {
    const x = Math.floor(Math.log10(num)) + 1;

    let _max = num;
    switch (x) {
      case 2:
        _max += 10;
        break;
      case 3:
        _max += 100;
        break;
      case 4:
        _max += 1000;
        break;
      case 5:
        _max += 10000;
        break;
      case 6:
        _max += 100000;
        break;
      case 7:
        _max += 100000;
        break;
      case 8:
        _max += 1000000;
        break;
      case 9:
        _max += 100000000;
        break;
      default:
        break;
    }

    return _max;
  },
  handleTimeCompare: (time: Moment | string) => {
    if (!time) return "";
    return moment(moment(time).format("HH:mm"), "HH:mm");
  },
  formatZipCode: (form: FormInstance, name: string, value: string) => {
    if (!value) return "";
    const clear = value.replaceAll("-", "");
    const matchValue: any = clear.match(/^(\d{1,3})(\d{0,4})$/);
    let results = matchValue[1];
    if (matchValue[2]) results += `-${matchValue[2]}`;
    return form.setFieldValue(name, results);
  },
  checkElementInArrayInOtherArray: (array1: any[], array2: any[]) => {
    return array1.some((r) => array2.includes(r));
  },
  convertTime: (time: string) => {
    return moment(time).format(COMMON.FORMAT_DATE_TIME_STRING);
  },
  hexToRGBA: (hex: string, alpha: number) => {
    // Remove the hash (#) if present
    hex = hex.replace(/^#/, "");

    // Parse the hex value to obtain RGB components
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    // Check if an alpha value is provided, otherwise default to 1
    const a = alpha !== undefined ? alpha : 1;

    // Construct the RGBA string
    const rgba = `rgba(${r}, ${g}, ${b}, ${a})`;

    return rgba;
  },
  formatPostNumber: (inputStr: string) => {
    const digits: string = inputStr.replace(/\D/g, "");
    const formattedStr: string = `${digits.substr(0, 3)}-${digits.substr(3)}`;
    return formattedStr;
  },
};

export default helpers;
