import { Form } from "antd";
import { uuidv4 } from "@firebase/util";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConfirmModal from "../../../../../common/components/modal/ConfirmModal";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import { RootState } from "../../../../../store";
import { ReceivedMoneyOffset, SubCategory } from "../../entity/Entity";
import { ServiceImpl } from "../../usecase/ServiceImpl";
import { setIsVisibleCM017_2_1, setLoadingCM017_2_1 } from "../slice/Slice";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import moment from "moment";

const Handler = (service: ServiceImpl, refreshData?: () => void) => {
  // LIB FUNCTION
  const dispatch = useDispatch();

  //STATE
  const [form] = Form.useForm();
  const [isDirty, setIsDirty] = useState(false);
  const typeModal = useSelector(
    (state: RootState) => state.cm017_2_1.typeModal
  );
  const parentCode = useSelector(
    (state: RootState) => state.cm017_2_1.parentCode
  );
  const [isClickAdd, setIsClickAdd] = useState<number>(0);
  const [contentDescription, setContentDescription] = useState<any>();
  const [listReceivedMoneyOffsetLocal, setListReceivedMoneyOffsetLocal] =
    useState<ReceivedMoneyOffset[]>([]);

  const [subCategory, setSubCategory] = useState<SubCategory[]>([]);
  const [totalCost, setTotalCost] = useState<number>(0);

  const handleGetSubCategory = async (): Promise<any> => {
    try {
      dispatch(setLoadingCM017_2_1(true));
      const res = await service.getMoneyReceivedMoneyOffsetSubCategory();
      if (res?.results) {
        setSubCategory(handleFormatSubCategory(res.results));
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoadingCM017_2_1(false));
    }
  };

  const handleFormatSubCategory = (data: any) => {
    const arr: SubCategory[] = data.map((item: any) => {
      return {
        label: item.description,
        value: item.id,
        code: item.code,
      };
    });
    return arr;
  };

  const handleFillForm = (data: ReceivedMoneyOffset[]) => {
    data.map((item) => {
      form.setFieldValue(`item${item.key}`, item.item);
      form.setFieldValue(
        `taxCategoryConsumption${item.key}`,
        item.taxCategoryComsumptionId
      );
      form.setFieldValue(`offsetAmount${item.key}`, item.offsetAmount);
      form.setFieldValue(
        `targetDate${item.key}`,
        moment(item.date, true).isValid() ? moment(item.date) : null
      );
    });
  };

  const handleCalculateChange = (data: ReceivedMoneyOffset[]) => {
    let total: number = 0;
    data.forEach((item) => {
      const cost = form.getFieldValue(`offsetAmount${item.key}`);
      if (!isNaN(cost)) total += cost;
    });
    setTotalCost(total);
  };

  const handleAddRowLocal = () => {
    const uniqueId = uuidv4();
    const data: ReceivedMoneyOffset = {
      key: uniqueId,
      item: undefined,
      taxCategoryComsumptionId: undefined,
      offsetAmount: undefined,
      date: undefined,
    };
    setListReceivedMoneyOffsetLocal([...listReceivedMoneyOffsetLocal, data]);
    form.setFieldValue(
      `taxCategoryConsumption${uniqueId}`,
      subCategory[0]?.value
    );
  };

  const handleRemoveRowLocal = (key: string) => {
    if (
      !form.getFieldValue(`item${key}`) &&
      form.getFieldValue(`taxCategoryConsumption${key}`) ===
      subCategory[0]?.value &&
      !form.getFieldValue(`offsetAmount${key}`)
    ) {
      const arr = listReceivedMoneyOffsetLocal.filter(
        (item) => item.key !== key
      );
      setListReceivedMoneyOffsetLocal([...arr]);
      handleCalculateChange(arr);
      return;
    }
    ConfirmModal({
      onOk: async () => {
        const arr = listReceivedMoneyOffsetLocal.filter(
          (item) => item.key !== key
        );
        setListReceivedMoneyOffsetLocal(arr);
        handleCalculateChange(arr);
        setIsDirty(true);
      },
      className: "confirm__modal",
      title: MESSAGE.MESSAGE_MODAL_DELETE_TITLE,
      description: MESSAGE.MESSAGE_MODAL_DELETE_DESCRIPTION_CM,
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_MODAL,
      isCenterWithoutMenu: true,
    });
  };

  const handleSubmitForm = async (value: any) => {
    try {
      if (!parentCode?.companyId && !parentCode?.colabRefId)
        throw new Error(NOTIFICATION_TITLE.ERROR);

      dispatch(setLoadingCM017_2_1(true));
      if (
        parentCode.companyId === undefined ||
        parentCode.colabRefId === undefined
      )
        throw new Error(NOTIFICATION_TITLE.ERROR);

      let listData: ReceivedMoneyOffset[] = [];
      const dataSubmit: any = [];
      listReceivedMoneyOffsetLocal.forEach((item) => {
        listData.push({
          key: item.key,
          item: form.getFieldValue(`item${item.key}`),
          taxCategoryComsumptionId: form.getFieldValue(
            `taxCategoryConsumption${item.key}`
          ),
          taxCategoryComsumptionName: convertCategoryIdToCategoryName(
            form.getFieldValue(`taxCategoryConsumption${item.key}`)
          ),
          offsetAmount: form.getFieldValue(`offsetAmount${item.key}`),
        });
        dataSubmit.push({
          name: form.getFieldValue(`item${item.key}`),
          categoryId: form.getFieldValue(`taxCategoryConsumption${item.key}`),
          value: form.getFieldValue(`offsetAmount${item.key}`),
          date:
            moment(form.getFieldValue(`targetDate${item.key}`)).toISOString() ??
            "",
        });
      });

      const response =
        await service.postReSummaryCompanyOutsourceCostOffsetCreate({
          companyId: parentCode.companyId,
          colabRefId: parentCode.colabRefId,
          data: dataSubmit,
        });
      SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
      setListReceivedMoneyOffsetLocal(listData);
      form.resetFields();
      setIsDirty(false);
      dispatch(setIsVisibleCM017_2_1(false));
      refreshData && refreshData();
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoadingCM017_2_1(false));
    }
  };

  const convertCategoryIdToCategoryName = (id: number) => {
    const res = subCategory.find((item) => item.value === id);
    return res?.label;
  };

  const checkDirty = (): void => {
    const formValue = form.getFieldsValue();
    const formProps = Object.keys(formValue);
    for (let i = 0; i < formProps.length; i++) {
      if (form.isFieldTouched(formProps[i])) {
        return setIsDirty(true);
      }
    }
    setIsDirty(false);
  };

  const onCancel = () => {
    if (!isDirty) {
      dispatch(setIsVisibleCM017_2_1(false));
      return;
    }
    ConfirmModal({
      onOk: async () => {
        dispatch(setIsVisibleCM017_2_1(false));
      },
      className: "confirm__modal",
      title: MESSAGE.MESSAGE_020,
      description: MESSAGE.MESSAGE_022_1,
      extraDescription: MESSAGE.MESSAGE_022_2,
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_MODAL,
      isCenterWithoutMenu: true,
    });
  };

  return {
    form,
    typeModal,
    contentDescription,
    listReceivedMoneyOffsetLocal,
    totalCost,
    subCategory,
    isClickAdd,
    onCancel,
    checkDirty,
    handleAddRowLocal,
    handleRemoveRowLocal,
    handleSubmitForm,
    handleCalculateChange,
    handleGetSubCategory,
    setIsClickAdd,
  };
};

export default Handler;
