import { AxiosResponse } from 'axios';
import axiosConfig from '../../../../../../config/axiosConfig';

const CM0052Api = () => {
    const getCompanyDocumentOutsourceCostReviewList = async (params: {
        page: number;
        size: number;
        sortType?: string;
        sortBy?: string;
        from?: string;
        to?: string;
        statusId?: string;
        keyword?: string;
    }): Promise<AxiosResponse<any, any>> => {
        const url = '/re-summary/company/document/outsource-cost/review/list';
        return await axiosConfig.get(url, { params });
    };

    const putCompanySummaryOutsourceCostReject = async (
        data: any,
    ): Promise<AxiosResponse<any, any>> => {
        const url = '/re-summary/company/summary/outsource-cost/reject';
        return await axiosConfig.put(url, data);
    };

    const putCompanySummaryOutsourceCostApprove = async (
        data: any,
    ): Promise<AxiosResponse<any, any>> => {
        const url = '/re-summary/company/summary/outsource-cost/approve';
        return await axiosConfig.put(url, data);
    };

    return {
        getCompanyDocumentOutsourceCostReviewList,
        putCompanySummaryOutsourceCostReject,
        putCompanySummaryOutsourceCostApprove,
    };
};

export default CM0052Api;
