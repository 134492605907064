import axiosConfig from "../../../../../config/axiosConfig";
import { ForgotBody } from "../../entity/Entity";

const ASForgotPasswordApi = () => {
  const forgot = async (data: ForgotBody): Promise<any> => {
    const url = "/auth/forgot-password/email";
    try {
      const response = await axiosConfig.post(url, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  return {
    forgot,
  };
};

export default ASForgotPasswordApi;
