import React from 'react';
import { Handler } from './Handler';
import { MainUI } from './UI';
import { OrderDetail, OrderStatus, PlanData, PlanSettingDetail } from '../../../entity/Entity';
import { FormInstance } from 'antd';

interface props {
    statusOrder: OrderStatus[];
    newPlanFunctions: any[];
    existedPlanFunctions: any[];
    form: FormInstance;
    planDetail?: PlanSettingDetail;
    totalDiscount: number;
    totalCost: number;
    orderDetail?: OrderDetail;

    addAPlanFunction(): void;
    deleteAPlanFunction(id: string, name: string): void;
    getChosenPlanFunctionOnFormChange: () => void
}

export const BasicPlanFormItems = (props: props) => {
    const handler = Handler();
    return <MainUI {...{ ...props, handler: handler }} />;
};
