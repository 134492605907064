import React from 'react';
import PlannedPaidAmountUI from './ui/UI';
import PlannedPaidAmountApi from '../../../infrastructure/api/ApiPlannedPaidAmount';
import PlannedPaidAmountService from '../../../usecase/ServicePlannedPaidAmount';
import PlannedPaidAmountHandler from '../../handler/HandlerPlannedPaidAmount';

const PlannedPaidAmountComponent = () => {
    const api = PlannedPaidAmountApi();
    const service = PlannedPaidAmountService(api);
    const handler = PlannedPaidAmountHandler(service);
    return <PlannedPaidAmountUI handler={handler} />;
};

export default PlannedPaidAmountComponent;
