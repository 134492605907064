import { AxiosResponse } from "axios";
import axiosConfig from "../../../../../config/axiosConfig";
import { ReSummaryCompanyProjectOutsourceCostRetrievePayload } from "../../entity/Entity";

const CM0027Api = () => {
  const getReSummarySubmittedOutsourceCostProject = async (params: {
    documentId: number;
    page?: number;
    size?: number;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/re-summary/submitted/outsource-cost/project";
    return await axiosConfig.get(url, { params });
  };
  const getReSummarySubmittedProjectConstructionOutsourceCost = async (params: {
    documentId: number;
    projectId: number;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/re-summary/submitted/project/construction/outsource-cost";
    return await axiosConfig.get(url, { params });
  };
  const getReSummarySubmittedProjectOutsourceCostTotal = async (params: {
    documentId: number;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/re-summary/submitted/project/outsource-cost/total";
    return await axiosConfig.get(url, { params });
  };
  const putReSummaryProjectOutsourceCostRetrieve = async (
    data: ReSummaryCompanyProjectOutsourceCostRetrievePayload
  ): Promise<AxiosResponse<any, any>> => {
    const url = "/re-summary/project/outsource-cost/retrieve";
    return await axiosConfig.put(url, data);
  };

  const getWorkflowSummaryOutsourceProjectApprovalProcess = async (params: {
    documentId: number;
  }): Promise<any> => {
    const url = "/workflow/summary/outsource/project/approval-process";
    return await axiosConfig.get(url, { params });
  };
  return {
    getReSummarySubmittedOutsourceCostProject,
    getReSummarySubmittedProjectConstructionOutsourceCost,
    getReSummarySubmittedProjectOutsourceCostTotal,
    putReSummaryProjectOutsourceCostRetrieve,
    getWorkflowSummaryOutsourceProjectApprovalProcess,
  };
};

export default CM0027Api;
