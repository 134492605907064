import { Avatar, Button, Col, Row, Tooltip } from "antd";

import { PM014ContentHandler } from "../handler/HandlerImpl";
import EditWhiteIcon from "../../../../../assets/icons/edit-white.svg";

import moment from "moment";
import AvatarComponent from "./Avatar";
import helpers from "../../../../../common/helpers/common";
import { isHasPrivilege } from "../../../../../common/helpers/privilege";
import {
  PRIVILEGE_CREATOR,
  PRIVILEGE_MEMBER,
  PRIVILEGE_PM,
  PRIVILEGE_SUB_PM,
} from "../../../../../common/constants/PRIVILEGE";
import COMMON, {
  AVATAR_ADMIN,
  PLAN_TYPE,
} from "../../../../../common/constants/COMMON";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { DefaultAvatarComponent } from "../../../../../common/components/default-avatar";
import { SYSTEM_ROLES } from "../../../../../common/constants/AUTHORIZATION";
import { CCUS_VERRIFICATION_STATE } from "../../../../../common/constants/STATUS";
import MESSAGE, { TITLE } from "../../../../../common/constants/MESSAGE";

import PreviewImageComponent from "../../../../../common/components/preview-image";
import PreviewPdfComponent from "../../../../../common/components/preview-pdf";
import IconTooltip from "../../../../../assets/icons/icon-tooltip.svg";
import IconTooltipRed from "../../../../../assets/icons/icon-tooltip-red.svg";

interface props {
  handler: PM014ContentHandler;
}

const ProjectInformation = ({ handler }: props) => {
  const checkFile = handler.pdfUrl?.split(".").pop()?.split("?").shift();
  const userID =
    useSelector((state: RootState) => state.auth.authData?.id) ?? "";
  const isRoleAdmin = [
    SYSTEM_ROLES.ADMIN || SYSTEM_ROLES.ADMIN_SYSTEM,
  ].includes(
    useSelector((state: RootState) => state.auth.authData?.role?.code) ?? ""
  );
  const isPlanFree =
    useSelector((state: RootState) => state.auth.authData?.order?.code) ===
    PLAN_TYPE.PLAN_FREE;
  const isVerifiedCompany = useSelector(
    (state: RootState) => state.auth?.authData?.isVerifiedCompany
  );
  const PreviewImage = () => {
    if (!handler.pdfUrl) return <></>;
    return checkFile === "pdf" ? (
      <div
        className="pm005-map button-style"
        onClick={handler.handleShowPdfViwerModal}
      >
        <PreviewPdfComponent height={242} width={401} url={handler.pdfUrl} />
      </div>
    ) : (
      <div
        className="pm005-map button-style"
        onClick={handler.handleShowImageViewerModal}
      >
        <PreviewImageComponent
          height={242}
          width={401}
          imageURL={handler.pdfUrl}
        />
      </div>
    );
  };

  return (
    <>
      <Row style={{ marginBottom: 32 }}>
        <Col span={16}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <DefaultAvatarComponent
              isAdmin={[SYSTEM_ROLES.ADMIN, SYSTEM_ROLES.ADMIN_SYSTEM].includes(
                handler.projectInfo?.projectManager?.roleCode ?? ""
              )}
              avatar={handler.projectInfo?.projectManager?.avatar ?? undefined}
              value={handler.projectInfo?.projectManager?.fullName ?? ""}
              size={54}
            />
            <div style={{ height: "100%", marginLeft: 16 }}>
              <div className="sagamihara">
                {handler.projectInfo?.projectName}
              </div>
              {/* <div className="data-center">データセンター</div> */}
              {handler.projectInfo?.parentId !== 0 ? (
                <div className="pm002-subcontractor pm002-subcontractor-detail">
                  <Tooltip
                    placement="bottomLeft"
                    // title={`${handler.projectInfo?.rootContractor?.company?.name}
                    //           から招待された案件`}
                  >
                    <span>
                      {handler.projectInfo?.rootContractor?.company?.name}
                      から招待された案件
                    </span>
                  </Tooltip>
                </div>
              ) : null}
            </div>
          </div>
        </Col>
        <Col span={8} className="pm005-avatar">
          <AvatarComponent handler={handler} />
        </Col>
      </Row>
      <div className="pm005-btn-edit-construction">
        {isHasPrivilege(handler.projectInfo, [
          PRIVILEGE_PM,
          PRIVILEGE_CREATOR,
        ]) || isRoleAdmin ? (
          <Button
            size="large"
            className="button-edit-project"
            onClick={handler.showModal}
            icon={
              <img
                alt=""
                src={EditWhiteIcon}
                style={{
                  paddingRight: 12,
                  border: "none",
                  marginBottom: 4,
                }}
              />
            }
          >
            編集
          </Button>
        ) : null}
      </div>
      <Row className="basic-content">
        <Col span={14}>
          <div className="basic-content-title">
            <div style={{ width: "100%", marginBottom: 16 }}>案件基本情報</div>

            <div className="pm005-info-content">
              <div className="pm005-info-content-item mb-8">
                <div className="mb-title">ステータス</div>
                <div
                  className="mb-text"
                  style={{
                    color: `${handler.checkColor(
                      handler.projectInfo?.status?.name
                    )}`,
                  }}
                >
                  {handler.projectInfo?.status?.name || "-"}
                </div>
              </div>

              {!isPlanFree && handler.projectInfo?.parentId == 0 ? (
                <div className="pm005-info-content-item mb-8">
                  <div className="mb-title">{TITLE.CCUSID}</div>
                  <div className="mb-text">
                    {handler.projectInfo?.ccusVerification
                      ? helpers.convertToCCUSFormat(
                          handler.projectInfo?.ccusVerification?.ccusCode
                        )
                      : "-"}
                  </div>
                  <div className="mp005-tooltip ml-8">
                    {(() => {
                      let verificationState =
                        handler.projectInfo?.ccusVerification
                          ?.verificationState;

                      if (verificationState != null && isVerifiedCompany) {
                        return (
                          <Tooltip
                            placement="right"
                            title={
                              <div style={{ width: "fit-content" }}>
                                {(() => {
                                  switch (verificationState) {
                                    case CCUS_VERRIFICATION_STATE.INITIAL:
                                      return MESSAGE.MESSAGE_CCUSID_INITIAL;
                                    case CCUS_VERRIFICATION_STATE.DONE:
                                      return MESSAGE.MESSAGE_CCUSID_DONE;
                                    case CCUS_VERRIFICATION_STATE.INVALID:
                                      return MESSAGE.MESSAGE_CCUSID_ERROR;
                                    default:
                                      return "";
                                  }
                                })()}
                              </div>
                            }
                          >
                            <img
                              src={
                                verificationState ===
                                  CCUS_VERRIFICATION_STATE.ERROR ||
                                verificationState ===
                                  CCUS_VERRIFICATION_STATE.INVALID
                                  ? IconTooltipRed
                                  : IconTooltip
                              }
                              alt=""
                              className="mb-8"
                            />
                          </Tooltip>
                        );
                      } else {
                        return null; // or any other fallback content if needed
                      }
                    })()}
                  </div>
                </div>
              ) : (
                <></>
              )}

              <div className="pm005-info-content-item mb-8">
                <div className="mb-title">案件名</div>
                <div className="mb-text">
                  {handler.projectInfo?.projectName || "-"}
                </div>
              </div>
              <div className="pm005-info-content-item mb-8">
                <div className="mb-title">案件担当者</div>
                <div className="mb-text">
                  {handler.projectInfo?.projectManager?.fullName ||
                    handler.projectInfo?.projectManager?.username ||
                    "-"}
                </div>

                <div className="mp005-tooltip ml-8">
                  {handler.projectInfo?.ccusVerification?.ccusCode != null &&
                  isVerifiedCompany ? (
                    (() => {
                      let verificationState =
                        handler.projectInfo?.ccusVerification
                          ?.verificationState;

                      let root =
                        handler.projectInfo?.ccusVerification?.isRootProject;

                      let iconSrc = IconTooltipRed;
                      if (
                        verificationState === CCUS_VERRIFICATION_STATE.INVALID
                      ) {
                        iconSrc = IconTooltip;
                      } else if (
                        verificationState === CCUS_VERRIFICATION_STATE.DONE &&
                        root
                      ) {
                        iconSrc = IconTooltip;
                      } else {
                        iconSrc = IconTooltipRed;
                      }

                      return (
                        <Tooltip
                          placement="right"
                          title={
                            <div style={{ width: "fit-content" }}>
                              {(() => {
                                if (
                                  verificationState ==
                                  CCUS_VERRIFICATION_STATE.INITIAL
                                ) {
                                  return MESSAGE.MESSAGE_CCUSID_PM_INITIAL;
                                } else if (
                                  verificationState ==
                                    CCUS_VERRIFICATION_STATE.DONE &&
                                  handler.projectInfo?.ccusVerification
                                    ?.isRootProject == true
                                ) {
                                  return MESSAGE.MESSAGE_CCUSID_PM_DONE;
                                } else if (
                                  verificationState ==
                                    CCUS_VERRIFICATION_STATE.DONE &&
                                  handler.projectInfo?.ccusVerification
                                    ?.isRootProject == false
                                ) {
                                  return MESSAGE.MESSAGE_CCUSID_PM_ERROR1;
                                } else {
                                  return MESSAGE.MESSAGE_CCUSID_PM_ERROR2;
                                }
                              })()}
                            </div>
                          }
                        >
                          <img src={iconSrc} alt="" className="mb-8" />
                        </Tooltip>
                      );
                    })()
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="pm005-info-content-item mb-8">
                <div className="mb-title">案件副担当者</div>
                <div className="mb-text">
                  {handler.projectInfo.deputyProjectManager?.length > 0
                    ? handler.projectInfo.deputyProjectManager
                        ?.map((ele: any) => ele.fullName || ele.username)
                        .join("、")
                    : "-"}
                </div>
              </div>

              <div className="pm005-info-content-item mb-8">
                <div className="mb-title">発注元会社</div>
                <div className="mb-text">
                  {handler.projectInfo?.orderAddress || "-"}
                </div>
              </div>
              <div className="pm005-info-content-item mb-8">
                <div className="mb-title">契約工事期間</div>
                <div className="mb-text">
                  {handler.projectInfo?.constructionFromDate &&
                  handler.projectInfo?.constructionToDate
                    ? `${moment(
                        handler.projectInfo?.constructionFromDate
                      ).format(COMMON.FORMAT_DATE_CI)} - ${moment(
                        handler.projectInfo?.constructionToDate
                      ).format(COMMON.FORMAT_DATE_CI)}`
                    : "-"}
                </div>
              </div>
              <div className="pm005-info-content-item mb-8">
                <div className="mb-title">住所</div>
                <div className="mb-text">
                  {handler.projectInfo?.location || "-"}
                </div>
              </div>
              <div className="pm005-info-content-item mb-8">
                <div className="mb-title">案件作成者</div>
                <div className="mb-text">
                  {handler.projectInfo?.projectCreator?.fullName}
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col span={10}>
          <PreviewImage />
        </Col>
      </Row>
    </>
  );
};

export default ProjectInformation;
