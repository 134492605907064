import axiosConfig from "../../../../../config/axiosConfig";

const PM009Api = () => {
  const getCategoryNotification = async (params: any) => {
    try {
      const url = "/notification/project/number-by-category";
      return await axiosConfig.get(url, { params });
    } catch (error) {
      throw error;
    }
  };

  const getListNotification = async (params: any) => {
    try {
      const url = "/notification/project/list";
      return await axiosConfig.get(url, { params });
    } catch (error) {
      throw error;
    }
  };

  const readNotification = async (data: any) => {
    try {
      const url = "/notification/mark-read";
      return await axiosConfig.put(url, data);
    } catch (error) {
      throw error;
    }
  };

  const deleteNotification = async (data: any) => {
    try {
      const url = "/notification/delete";
      return await axiosConfig.delete(url, { data });
    } catch (error) {
      throw error;
    }
  };

  return {
    getCategoryNotification,
    getListNotification,
    readNotification,
    deleteNotification,
  };
};
export default PM009Api;
