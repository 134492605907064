import React from 'react';
import CM0017_3_1Api from './infrastructure/api/Api';
import CM0017_3_1Handler from './presenter/handler/Handler';
import CM0017_3_1UI from './presenter/ui/UI';
import CM0017_3_1Service from './usecase/Service';

const CM0017_3_1Component = () => {
    const cm0017_3_1Api = CM0017_3_1Api();
    const cm0017_3_1Service = CM0017_3_1Service(cm0017_3_1Api);
    const cm0017_3_1Handler = CM0017_3_1Handler(cm0017_3_1Service);
    return <CM0017_3_1UI handler={cm0017_3_1Handler} />;
};

export default CM0017_3_1Component;
