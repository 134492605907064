import CM0062Api from './infrastructure/api/Api';
import CM0062Handler from './presenter/handler/Handler';
import CM0062UI from './presenter/ui/UI';
import CM0062Service from './usecase/Service';

const CM0062Component = () => {
    const cm0062Api = CM0062Api();
    const cm0062Service = CM0062Service(cm0062Api);
    const cm0062Handler = CM0062Handler(cm0062Service);
    return <CM0062UI handler={cm0062Handler} />;
};

export default CM0062Component;
