import ASLoginApi from "./infrastructure/api/Api"
import ASLoginHandler from "./presenter/handler/Handler";
import ASLoginUI from "./presenter/ui/UI";
import ASLoginService from "./usecase/Service";

const ASLoginComponent = () => {
    const asLoginApi = ASLoginApi();
    const asLoginService = ASLoginService(asLoginApi)
    const asLoginHandler = ASLoginHandler(asLoginService)

    return <ASLoginUI handler={asLoginHandler} />
}

export default ASLoginComponent