import { AxiosResponse } from 'axios';
import { CCUS02APIImpl } from './ServiceImpl';
import { ResultDataType } from '../entity/Entity';

const CCUS002Service = (api: CCUS02APIImpl) => {

    const getTimesheetSyncData = async (params: {
        projectId?: number;
        companyId?: number;
        approverId?: number;
        state?: string;
        from?: string;
        to?: string;
        syncTime?: string;
        registerType?: string;
        sortBy?: string;
        sortType?: string;
        page?: number;
        size?: number;
    }): Promise<any> => {
        try {
            const response = await api.getTimesheetSyncData(params);
            return response?.data;
        } catch (error: any) {
            throw error;
        }
    };

    const getProjectListCCUS = async (params: {
        companyId?: number;
        approverId?: number;
        state?: string;
        page: number;
        size: number;
    }): Promise<any> => {
        try {
            const response = await api.getProjectListCCUS(params);
            return response?.data?.results ?? [];
        } catch (error: any) {
            throw error;
        }
    };

    const getDependedCompanyListCCUS = async (params: {
        projectId?: number;
        approverId?: number;
        state?: string;
        page: number;
        size: number;
    }): Promise<any> => {
        try {
            const response = await api.getDependedCompanyListCCUS(params);
            return response?.data?.results ?? [];
        } catch (error: any) {
            throw error;
        }
    };

    const getApproverListCCUS = async (params: {
        companyId?: number;
        projectId?: number;
        state?: string;
        page: number;
        size: number;
    }): Promise<any> => {
        try {
            const response = await api.getApproverListCCUS(params);
            return response?.data?.results ?? [];
        } catch (error: any) {
            throw error;
        }
    };

    const getCCUSTimesheet = async (params: {
        projectId?: number;
        companyId?: number;
        approverId?: number;
        state?: string;
        syncTime?: string;
        registerType?: string;
        from?: string;
        to?: string;
    }): Promise<any> => {
        try {
            const response = await api.getCCUSTimesheet(params);
            return response?.data;
        } catch (error: any) {
            throw error;
        }
    };

    return {
        getTimesheetSyncData,
        getProjectListCCUS,
        getDependedCompanyListCCUS,
        getApproverListCCUS,
        getCCUSTimesheet
    };
};

export default CCUS002Service;
