import {
  MoneyReceiveMoneyOffsetListResponse,
  MoneyReceivedMoneyCreatePayload,
  MoneyReceivedMoneyDelete,
  MoneyReceivedMoneyEditPayload,
  MoneyReceivedMoneyList,
  MoneyReceivedMoneyOffsetList,
} from "../entity/Entity";
import { HistoryPaymentAPIImpl } from "./ServiceImpl";
import { MoneySubmitReceiveMoneyPayload } from "../../CM004/entity/Entity";
import helpers from "../../../../common/helpers/common";

const HistoryPaymentService = (api: HistoryPaymentAPIImpl) => {
  const postMoneyReceivedMoneyCreate = async (
    payload: MoneyReceivedMoneyCreatePayload
  ): Promise<any> => {
    try {
      const response = await api.postMoneyReceivedMoneyCreate(payload);
      return response.data;
    } catch (error: any) {
      throw error;
    }
  };

  const deleteMoneyReceivedMoneyDelete = async (
    payload: MoneyReceivedMoneyDelete
  ): Promise<any> => {
    try {
      const response = await api.deleteMoneyReceivedMoneyDelete(payload);
      return response.data;
    } catch (error: any) {
      throw error;
    }
  };
  const putMoneyReceivedMoneyEdit = async (
    payload: MoneyReceivedMoneyEditPayload
  ): Promise<any> => {
    try {
      const response = await api.putMoneyReceivedMoneyEdit(payload);
      return response.data;
    } catch (error: any) {
      throw error;
    }
  };
  const putMoneySubmitReceiveMoney = async (
    payload: MoneySubmitReceiveMoneyPayload
  ): Promise<any> => {
    try {
      const response = await api.putMoneySubmitReceiveMoney(payload);
      return response.data;
    } catch (error: any) {
      throw error;
    }
  };
  const getMoneyReceivedMoneyList = async (params: {
    constructionId: number;
    page?: number;
    size?: number;
    sortBy?: string;
    sortType?: string;
    from?: string;
    to?: string;
  }): Promise<MoneyReceivedMoneyList> => {
    try {
      const response = await api.getMoneyReceivedMoneyList({
        ...params,
        from: params.from ? helpers.getFilterTime(params.from) : undefined,
        to: params.to ? helpers.getFilterTimeTo(params.to) : undefined,
      });
      return response.data;
    } catch (error: any) {
      throw error;
    }
  };
  const getLinkPresignUpload = async (params: any): Promise<any> => {
    try {
      const response = await api.getLinkPresignUpload(params);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const uploadToS3 = async (url: any, data: any): Promise<any> => {
    try {
      const response = await api.uploadToS3(url, data);
      return response.data;
    } catch (error: any) {
      throw error;
    }
  };

  const getPresignLinkS3 = async (params: any): Promise<any> => {
    try {
      const response = await api.getPresignLinkS3(params);
      return response.data;
    } catch (error: any) {
      throw error;
    }
  };

  const getMoneyReceivedMoneyOffsetList = async (params: {
    receiveMoneyId: number;
    page?: number;
    size?: number;
  }): Promise<MoneyReceiveMoneyOffsetListResponse> => {
    try {
      const response = await api.getMoneyReceivedMoneyOffsetList(params);
      return response.data;
    } catch (error: any) {
      throw error;
    }
  };

  const doExportCM005 = async (params: any): Promise<any> => {
        return await api.doExportCM005(params);
    }

  return {
    postMoneyReceivedMoneyCreate,
    deleteMoneyReceivedMoneyDelete,
    putMoneyReceivedMoneyEdit,
    getMoneyReceivedMoneyList,
    putMoneySubmitReceiveMoney,
    getLinkPresignUpload,
    uploadToS3,
    getPresignLinkS3,
    getMoneyReceivedMoneyOffsetList,
    doExportCM005,
  };
};

export default HistoryPaymentService;
