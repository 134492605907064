import { AM028FilterHandler } from "../handler/HandlerImpl";
import { Button, Form, Select, DatePicker } from "antd";

import CalendarSvg from "../../../../../assets/icons/calendar.svg";
import moment from "moment";
import COMMON from "../../../../../common/constants/COMMON";
import locale from "antd/es/date-picker/locale/ja_JP";
import "moment/locale/ja";

const { Item } = Form;
const { Option } = Select;
const { RangePicker } = DatePicker;

interface props {
  handler: AM028FilterHandler;
}
const Filter = ({ handler }: props) => {
  return (
    <Form
      name="am028-filter"
      className="am028-filter"
      form={handler.formFilter}
      onValuesChange={handler.handleChangeFormFilter}
      onFinish={handler.handleFinishFormFilter}
    >
      <div className="am028-filter-chosen">
        <div className="am028-filter-item">
          <span className="am028-filter-item-label">事由</span>
          <Item name="category">
            <Select
              size="large"
              className="am028-select"
              getPopupContainer={(trigger: any) => trigger.parentNode}
            >
              {handler.categories.map((select) => {
                return (
                  <Option
                    key={select.categoryId}
                    value={select.categoryId?.toString()}
                  >
                    {select.name}
                  </Option>
                );
              })}
            </Select>
          </Item>
        </div>
        <div className="am028-filter-item">
          <span className="am028-filter-item-label">ステータス</span>
          <Item name="status">
            <Select
              size="large"
              className="am028-select am028-select-status"
              getPopupContainer={(trigger: any) => trigger.parentNode}
            >
              {handler.listStatus.map((select) => {
                return (
                  <Option key={select.id} value={select.id?.toString()}>
                    {select.name}
                  </Option>
                );
              })}
            </Select>
          </Item>
        </div>
        <div className="am028-filter-item">
          <span className="am028-filter-item-label">対象期間</span>
          <Item name="targetPeriod">
            <RangePicker
              inputReadOnly
              size="large"
              locale={locale}
              defaultValue={[moment().startOf("month"), moment()]}
              className="am028-range"
              style={{ maxWidth: 308 }}
              format={COMMON.FORMAT_DATE_OM}
              placeholder={["開始日", "終了日"]}
              getPopupContainer={(trigger: any) => trigger.parentNode}
              suffixIcon={
                <img alt="" src={CalendarSvg} className="icon-calender" />
              }
            />
          </Item>
        </div>
      </div>
      <div className="am028-filter-btn">
        <Item>
          <Button
            htmlType="submit"
            size="large"
            className="btn-withdraw am028-btn"
          >
            絞込み
          </Button>
        </Item>
        <Button
          htmlType="button"
          size="large"
          className="am028-btn btn-text-gray"
          onClick={handler.handleClearFilter}
        >
          クリア
        </Button>
      </div>
    </Form>
  );
};

export default Filter;
