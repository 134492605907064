import { createSlice } from '@reduxjs/toolkit';

type IC5ModalSlice = {
    isOpenIC5: boolean;
};
const initialState: IC5ModalSlice = {
    isOpenIC5: false,
};

export const IC5ModalSlice = createSlice({
    name: 'IC5ModalSlice',
    initialState,
    reducers: {
        showIC5Modal: (state) => {
            state.isOpenIC5 = true;
        },
        hideIC5Modal: (state) => {
            state.isOpenIC5 = false;
        },
    },
});

export const { showIC5Modal, hideIC5Modal } = IC5ModalSlice.actions;
export default IC5ModalSlice.reducer;
