import { HandlerImpl } from '../handler/HandlerImpl';
import TableView from './Table';
import CM008Component from '../../../../CM008/index';
import CM0010Component from '../../../../CM0010/index';
import CM0016Component from '../../../../CM0016/index';
import CM0014Component from '../../../../CM0014/index';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import COMMON from '../../../../../../common/constants/COMMON';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import { setIsVisibleCM008 } from '../../../../CM008/presenter/slice/Slice';
import { setIsVisibleCM0014 } from '../../../../CM0014/presenter/slice/Slice';
import { setIsVisibleCM0010 } from '../../../../CM0010/presenter/slice/Slice';
import { setIsVisibleCM0016 } from '../../../../CM0016/presenter/slice/Slice';
import ModalRejectView from '../../../../../../common/components/modal-reject/ModalRejectView';

interface props {
    handler: HandlerImpl;
}

const MainUI = ({ handler }: props) => {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const keyword = searchParams.get('keyword2') ?? '';
    const statusCode = searchParams.get('status2') ?? '';
    const from = searchParams.get('from2') ?? moment().startOf('month').format(COMMON.FORMAT_DATE2);
    const to = searchParams.get('to2') ?? moment().format(COMMON.FORMAT_DATE2);
    const sortType1 = searchParams.get('sortType1') ?? '';
    const sortType2 = searchParams.get('sortType2') ?? '';
    const sortType3 = searchParams.get('sortType3') ?? '';
    const sortType4 = searchParams.get('sortType4') ?? '';

    const isVisibleCM08 = useSelector((state: RootState) => state.cm008.isVisible);
    const isVisibleCM010 = useSelector((state: RootState) => state.cm0010.isVisible);
    const isVisibleCM014 = useSelector((state: RootState) => state.cm0014.isVisible);
    const isVisibleCM016 = useSelector((state: RootState) => state.cm0016.isVisible);

    const refreshCM3453 = useSelector((state: RootState) => state.wrapper3453.refresh);

    React.useEffect(() => {
        handler.getDataMaterialList(statusCode, from, to, keyword, sortType1);
        handler.getDataOutsourceList(statusCode, from, to, keyword, sortType2);
        handler.getDataLaborList(statusCode, from, to, keyword, sortType3);
        handler.getDataIndirectList(statusCode, from, to, keyword, sortType4);
    }, [refreshCM3453]);

    React.useEffect(() => {
        // Filter data local
        handler.filterDataView(
            statusCode,
            from,
            to,
            keyword,
            sortType1,
            sortType2,
            sortType3,
            sortType4,
        );
    }, [statusCode, from, to, keyword, sortType1, sortType2, sortType3, sortType4, refreshCM3453]);
    React.useEffect(() => {
        return () => {
            dispatch(setIsVisibleCM008(false));
            dispatch(setIsVisibleCM0014(false));
            dispatch(setIsVisibleCM0010(false));
            dispatch(setIsVisibleCM0016(false));
        };
    }, []);

    return (
        <div className="cm047 cm048">
            {isVisibleCM08 ? <CM008Component isCheckbox={true} /> : null}
            {isVisibleCM010 ? <CM0010Component isCheckbox={true} /> : null}
            {isVisibleCM014 ? <CM0014Component isCheckbox={true} /> : null}
            {isVisibleCM016 ? <CM0016Component isCheckbox={true} /> : null}
            <ModalRejectView
                visible={handler.visibleRejectView}
                funcClose={() => handler.setVisibleRejectView(false)}
                rejectReason={handler.rejectReason}
            />
            <TableView
                title="材料費"
                data={handler.material}
                sortType={handler.sortType1}
                funcSort={(sortType: string) => handler.funcSort('table1', sortType)}
                onClickDocument={handler.funcClickDocumentMaterialCost}
                handleViewRejectReason={(reason: string) => {
                    handler.setRejectReason(reason);
                    handler.setVisibleRejectView(true);
                }}
            />
            <TableView
                title="外注費"
                data={handler.outsource}
                sortType={handler.sortType2}
                funcSort={(sortType: string) => handler.funcSort('table2', sortType)}
                onClickDocument={handler.funcClickDocumentOutsourcingCost}
                handleViewRejectReason={(reason: string) => {
                    handler.setRejectReason(reason);
                    handler.setVisibleRejectView(true);
                }}
            />
            <TableView
                title="労務費"
                data={handler.labor}
                sortType={handler.sortType3}
                funcSort={(sortType: string) => handler.funcSort('table3', sortType)}
                onClickDocument={handler.funcClickDocumentLaborCost}
                handleViewRejectReason={(reason: string) => {
                    handler.setRejectReason(reason);
                    handler.setVisibleRejectView(true);
                }}
            />
            <TableView
                title="間接工事費"
                data={handler.indirect}
                sortType={handler.sortType4}
                funcSort={(sortType: string) => handler.funcSort('table4', sortType)}
                onClickDocument={handler.funcClickDocumentIndirect}
                handleViewRejectReason={(reason: string) => {
                    handler.setRejectReason(reason);
                    handler.setVisibleRejectView(true);
                }}
            />
        </div>
    );
};

export default MainUI;
