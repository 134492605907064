import React, { useEffect } from "react";
import { HandlerImpl } from "../handler/HandlerImpl";
import { MENU_ITEM } from "../handler/Handler";
import { DatePicker, Button } from "antd";
import IconCircleCheck from "../../../../../assets/icons/akar-icons_circle-check-fill.svg";
import IconExport from "../../../../../assets/icons/upload-purple.svg";
import locale from "antd/es/date-picker/locale/ja_JP";
import "moment/locale/ja";
import moment from "moment";
import COMMON from "../../../../../common/constants/COMMON";
import CalendarSvg from "../../../../../assets/icons/calender-purple.svg";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { RootState } from "../../../../../store";
import { useSelector } from "react-redux";
import {
  AUTHORIZATION_CODE,
  SYSTEM_ROLES,
} from "../../../../../common/constants/AUTHORIZATION";
import UploadLightSVG from "../../../../../assets/icons/upload-icon-purple.svg";
import ApproveConfirmModal from "../../../../../common/components/modal-approve-confirm";

const { RangePicker } = DatePicker;

interface props {
  handler: HandlerImpl;
}

const Header = ({ handler }: props) => {
  const permission = useSelector(
    (state: RootState) => state.auth.authData?.permission
  );
  const isRoleAdmin = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdmin
  );

  const isRoleAdminSystem = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdminSystem
  );

  const isHavingData = useSelector(
    (state: RootState) => state.cm0024.isHavingData
  );

  const filterRange: any = useMemo(() => {
    if (handler.menu === MENU_ITEM[0].key)
      return handler.clear ? null : [moment(handler.from), moment(handler.to)];
    else {
      return handler.clear2
        ? null
        : [moment(handler.from2), moment(handler.to2)];
    }
  }, [
    handler.clear,
    handler.clear2,
    handler.from,
    handler.from2,
    handler.to,
    handler.to2,
    handler.menu,
  ]);

  return (
    <div className="cm024-header">
      <div className="cm024-header-filter">
        <div className="am028-nav">
          <ul className="am028-menu">
            {MENU_ITEM.map((menu) => {
              return (
                <li
                  key={menu.key}
                  className={`am028-menu-item ${menu.key === handler.menu ? "am028-menu-item-active" : ""
                    }`}
                  onClick={() => handler.handleChangeMenuChosen(menu.key)}
                >
                  {menu.name}
                </li>
              );
            })}
          </ul>
        </div>
        <div className="cm024-header-filter-choose">
          <RangePicker
            inputReadOnly
            picker="month"
            size="large"
            locale={locale}
            value={filterRange}
            className="am028-range cm024-range"
            format={COMMON.FORMAT_DATE_JA_MONTH}
            getPopupContainer={(trigger: any) => trigger.parentNode}
            suffixIcon={
              <img
                alt=""
                src={CalendarSvg}
                className="icon-calender"
                style={{ width: 20 }}
              />
            }
            onChange={handler.funcFilterTime}
            allowClear={false}
            onOpenChange={handler.handleOpenCalendar}
          />
          {permission?.includes(AUTHORIZATION_CODE.S47) ||
            isRoleAdmin ||
            isRoleAdminSystem ? (
            <>
              {/* DONE */}
              {/* <ApproveConfirmModal onSubmit={handler.onSubmit} /> */}
              <Button
                className="button-purple cm047-btn-submit cm024-btn-submit"
                icon={<img alt="" src={IconCircleCheck} />}
                size="large"
                onClick={handler.onSubmit}
                disabled={
                  (handler.status &&
                    handler.checkConditionSubmit(handler?.status)) ||
                  !isHavingData
                }
              >
                提出
              </Button>
            </>
          ) : null}
          {permission?.includes(AUTHORIZATION_CODE.B9) ||
            isRoleAdmin ||
            isRoleAdminSystem ? (
            <Button
              className="button-brown-3"
              style={{
                border: "1px solid #605BFF",
                width: 106,
                marginLeft: 16,
              }}
              size="large"
              onClick={handler.exportData}
            >
              <div className="center-item">
                <img
                  alt=""
                  src={UploadLightSVG}
                  style={{
                    marginRight: 8,
                    fontWeight: 700,
                    fontSize: 14,
                  }}
                />
                <div
                  className="export-button"
                  style={{ color: "#605BFF" }}
                >
                  出力
                </div>
              </div>
            </Button>
          ) : (
            <div></div>
          )}
        </div>
      </div>
      <div className="cm024-header-title">
        <span className="cm024-header-label">ステータス:</span>
        <span
          className="cm024-header-content"
          style={{ color: `${handler.getColorStatus(handler?.status)}` }}
        >
          {handler?.status?.name ?? ""}
        </span>
      </div>
    </div>
  );
};

export default Header;
