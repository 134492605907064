import ButtonWarningHandler from "../../handler/HandlerButtonWarning";
import ButtonWarningUI from "./ui/UI";

type props = {
    date: string,
    constructionId?: string;
    contractorConstructionId?: string
    userId: string
}
const ButtonWarningAM042Component = ({ date, constructionId, userId, contractorConstructionId }: props) => {
    const handler = ButtonWarningHandler()
    return <ButtonWarningUI
        contractorConstructionId={contractorConstructionId}
        handler={handler}
        date={date}
        constructionId={constructionId}
        userId={userId}
    />
};

export default ButtonWarningAM042Component;
