import { CM0025_2SliceType } from './../type/Presenter';
import { createSlice } from '@reduxjs/toolkit';

const initialState: CM0025_2SliceType = {
    loading: false,
    isVisible: false,
    refresh: false,
    typeModal: false,
    parentCode: {
        documentSummaryOutsourceCostCompanyDataId: 108,
        categoryId: 13,
    },
    isUpdate: true,
    modalTitle: null,
};

export const cm0025_2Slice = createSlice({
    name: 'cm0025_2',
    initialState,
    reducers: {
        resetCM0025_2: () => initialState,
        setLoadingCM0025_2: (state, action) => {
            state.loading = action.payload;
        },
        setIsVisibleCM0025_2: (state, action) => {
            state.isVisible = action.payload;
        },
        setRefreshCM0025_2: (state) => {
            state.refresh = !state.refresh;
        },
        setTypeModalCM0025_2: (state, action) => {
            state.typeModal = action.payload;
        },
        setParentCodeCM0025_2: (state, action) => {
            state.parentCode = action.payload;
        },
        setIsUpdate: (state, action) => {
            state.isUpdate = action.payload;
        },
        setModalTitleCM0025_2: (state, action) => {
            state.modalTitle = action.payload;
        },
    },
});

export const {
    setIsUpdate,
    setLoadingCM0025_2,
    setIsVisibleCM0025_2,
    resetCM0025_2,
    setRefreshCM0025_2,
    setTypeModalCM0025_2,
    setParentCodeCM0025_2,
    setModalTitleCM0025_2,
} = cm0025_2Slice.actions;

export default cm0025_2Slice.reducer;
