import AM028Api from "./infrastructure/api/Api";
import AM028Service from "./usecase/Service";
import AM028Handler from "./presenter/handler/Handler";
import AM028UI from "./presenter/ui/UI";

const AM028Component = () => {
  const am028Api = AM028Api();
  const am028Service = AM028Service(am028Api);
  const am028Handler = AM028Handler(am028Service);
  return <AM028UI handler={am028Handler} />;
};

export default AM028Component;
