import moment from 'moment';
import COMMON from '../../../common/constants/COMMON';
import { TableColumnsType } from './entity/Entity';
import { URLSearchParamsInit } from 'react-router-dom';
import {
    ExportDataAttendanceConstructionChildren,
    ExportDataAttendanceConstructionElement,
} from '../../../common/entity/Entity';
import helpers from '../../../common/helpers/common';
import { FormInstance } from 'antd';

const CHECK_IN_CODE: string = 'checkin';
const initiateKeyword = (form: FormInstance, keyword: string, formName: string) => {
    form.setFieldValue(formName, keyword);
};

const enumerateDaysBetweenDates = (startDate: string, endDate: string) => {
    const currentDate = moment(startDate).startOf('day');
    const lastDate = moment(endDate).startOf('day');

    const dates = [
        {
            name: currentDate.locale('ja').format('MM月DD日(ddd)'),
            value: currentDate.format(COMMON.FORMAT_DATE2),
        },
    ];
    while (currentDate.add(1, 'days').diff(lastDate) <= 0) {
        dates.push({
            name: currentDate.locale('ja').format('MM月DD日(ddd)'),
            value: currentDate.format(COMMON.FORMAT_DATE2),
        });
    }
    return dates;
};

const calculateCalendarTableHeader = (
    startDate: string | null,
    endDate: string | null,
    pageDate: any,
) => {
    const isStartDate = moment(startDate).isValid();
    const isEndDate = moment(endDate).isValid();

    let result: TableColumnsType = {
        columns: [],
        isNext: false,
        isPrev: false,
    };

    // calculate table header
    if (startDate && endDate && isStartDate && isEndDate) {
        const dateRange = enumerateDaysBetweenDates(startDate, endDate);
        const isNext = dateRange.length / 7 > parseInt(pageDate);
        const isPrev = pageDate !== '1' && dateRange.length > 7;

        let fromIndex = (parseInt(pageDate) - 1) * 7;
        let toIndex = fromIndex + 7;
        if (toIndex > dateRange.length) toIndex = dateRange.length;
        const columns = dateRange.slice(fromIndex, toIndex);

        result = {
            columns: columns,
            isPrev: isPrev,
            isNext: isNext,
        };
    } else if (!startDate && !endDate) {
        const firstDayOfMonth = moment().startOf('month');
        const currentDate = moment();
        const dateRange = enumerateDaysBetweenDates(
            firstDayOfMonth.format(COMMON.FORMAT_DATE2),
            currentDate.format(COMMON.FORMAT_DATE2),
        );

        const toIndex = dateRange.length - (parseInt(pageDate) - 1) * 7;
        const columns = dateRange.slice(toIndex - 7 >= 0 ? toIndex - 7 : 0, toIndex);
        const isNext = pageDate !== '1' && dateRange.length > 7;
        const isPrev = dateRange.length / 7 > parseInt(pageDate);
        result = {
            columns: columns,
            isPrev: isPrev,
            isNext: isNext,
        };
    } else if (startDate && !endDate) {
        const currentDate = moment();
        const dateRange = enumerateDaysBetweenDates(
            startDate,
            currentDate.format(COMMON.FORMAT_DATE2),
        );

        const toIndex = dateRange.length - (parseInt(pageDate) - 1) * 7;
        const columns = dateRange.slice(toIndex - 7 >= 0 ? toIndex - 7 : 0, toIndex);
        const isNext = pageDate !== '1' && dateRange.length > 7;
        const isPrev = dateRange.length / 7 > parseInt(pageDate);
        result = {
            columns: columns,
            isPrev: isPrev,
            isNext: isNext,
        };
    } else if (!startDate && endDate) {
        const startDate = moment(endDate)
            .subtract((parseInt(pageDate) - 1) * 7 + 6, 'days')
            .format(COMMON.FORMAT_DATE2);
        const currentDate = moment(endDate);
        const dateRange = enumerateDaysBetweenDates(
            startDate,
            currentDate.subtract((parseInt(pageDate) - 1) * 7, 'day').format(COMMON.FORMAT_DATE2),
        );
        const columns = dateRange;
        const isNext = pageDate !== '1';
        result = {
            columns: columns,
            isPrev: true,
            isNext: isNext,
        };
    }
    return result;
};

const checkMissParamsOnURL = (
    searchParams: URLSearchParams,
    setSearchParams: (
        nextInit: URLSearchParamsInit,
        navigateOptions?:
            | {
                  replace?: boolean | undefined;
                  state?: any;
              }
            | undefined,
    ) => void,
) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const entries = urlParams.entries();
    for (const entry of entries) {
        searchParams.set(entry[0], entry[1]);
    }
    setSearchParams(searchParams);
};

const getTitleByFromToDate = (fromDate: any, toDate: any) =>
    !fromDate && !toDate
        ? `${moment().startOf('month').format(COMMON.FORMAT_DATE_OM)}～${moment().format(
              COMMON.FORMAT_DATE_OM,
          )}`
        : `${
              fromDate === ''
                  ? ''
                  : fromDate === null
                  ? moment().startOf('month').format(COMMON.FORMAT_DATE_OM)
                  : moment(fromDate).format(COMMON.FORMAT_DATE_OM)
          }～${toDate ? moment(toDate).format(COMMON.FORMAT_DATE_OM) : ''}`;

const subtractInOutTime = (checkIn: string, checkOut: string) => {
    if (checkIn && checkOut) {
        const duration = moment.duration(
            moment(checkOut, COMMON.FORMAT_DATE_4).diff(moment(checkIn, COMMON.FORMAT_DATE_4)),
        );
        const value: number = duration.hours() + duration.minutes() / 60;
        return `${value.toFixed(2)}`;
    } else return '';
};

const convertMultipleTimeSheet = (data: any[]) => {
    const dataCheckIn: string[] = [];
    const dataCheckOut: string[] = [];
    const results: any[] = [];

    data.forEach((element: any) => {
        if (element?.category?.code === CHECK_IN_CODE) dataCheckIn.push(element?.time);
        else dataCheckOut.push(element?.time);
    });

    if (dataCheckIn.length > dataCheckOut.length) {
        dataCheckIn.forEach((item: any, idx: number) => {
            const checkIn: string = dataCheckIn[idx] ?? '';
            const checkOut: string = dataCheckOut[idx] ?? '';

            results.push({
                check_in: checkIn,
                check_out: checkOut,
                total_hour: subtractInOutTime(checkIn, checkOut),
            });
        });
    } else {
        dataCheckOut.forEach((item: any, idx: number) => {
            const checkIn: string = dataCheckIn[idx] ?? '';
            const checkOut: string = dataCheckOut[idx] ?? '';

            results.push({
                check_in: checkIn,
                check_out: checkOut,
                total_hour: subtractInOutTime(checkIn, checkOut),
            });
        });
    }

    return results;
};

const convertDataForExport = (data: any[]) => {
    const results: ExportDataAttendanceConstructionElement[] = [];

    data.forEach((element: any, idx: number) => {
        let obj: ExportDataAttendanceConstructionElement = {
            employee_name: element?.fullName,
            project_name: element?.construction?.projectName ?? element?.group?.name ?? '',
            construction_name: element?.construction?.description ?? element?.role?.name ?? '',
            total_work_day: element?.workingDays,
            children: [],
        };

        let listItems: ExportDataAttendanceConstructionChildren[] = [];

        element?.attendance?.timesheet.forEach((item: any) => {
            let objItem: ExportDataAttendanceConstructionChildren = {
                check_in: item?.checkin,
                check_out: item?.checkout,
                total_hour: subtractInOutTime(item?.checkin, item?.checkout),
                // TODO: Mapping data session 2
                items: item?.isMultipleCheckInOut
                    ? convertMultipleTimeSheet(item?.multipleCheckInOutData ?? [])
                    : [],
            };

            listItems.push(objItem);
        });

        obj['children'] = listItems;

        results.push(obj);
    });

    return results;
};

export {
    enumerateDaysBetweenDates,
    calculateCalendarTableHeader,
    checkMissParamsOnURL,
    getTitleByFromToDate,
    subtractInOutTime,
    convertDataForExport,
    initiateKeyword,
};
