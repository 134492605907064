import { AM027SliceType } from "./../type/Presenter";
import { createSlice } from "@reduxjs/toolkit";

const initialState: AM027SliceType = {
  isVisible: false,
  loading:false,
  requestId: [],
  screenRequestToAM027:""
};

export const am027Slice = createSlice({
  name: "am027",
  initialState,
  reducers: {
    resetAM027: () => initialState,
    setIsVisibleAM027: (state, action) => {
      state.isVisible = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setRequestIdAM027:(state, action) => {
      state.requestId = action.payload;
    },
    setScreenRequestToAM027:(state, action) =>{
      state.screenRequestToAM027 = action.payload;
    }
  },
});

export const {
  setIsVisibleAM027,
  resetAM027,
  setLoading,
  setRequestIdAM027,
  setScreenRequestToAM027
} = am027Slice.actions;

export default am027Slice.reducer;

