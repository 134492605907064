import React from 'react';
import { ReSummarySubmittedProjectLaborCostTotalResults } from '../../entity/Entity';
import ProjectRowComponent from './ProjectRow';
import { RootState } from '../../../../../store';
import { useSelector } from 'react-redux';
import helpers from '../../../../../common/helpers/common';

interface props {
    projects: any[];
    summaryTotal: ReSummarySubmittedProjectLaborCostTotalResults;

    checkKeyExpand: (key: any) => boolean;
    setExpandKeys: (key: any) => void;
    handleScroll: (e: any, documentId: number) => Promise<void>;
}

const Table = ({ projects, summaryTotal, checkKeyExpand, setExpandKeys, handleScroll }: props) => {
    const documentId = useSelector((state: RootState) => state.cm0029.documentId);

    return (
        <div
            className="cm024-table"
            onScroll={(e: any) => {
                if (documentId) handleScroll(e, documentId);
            }}
        >
            <table>
                <thead>
                    <tr>
                        <th colSpan={2}>No</th>
                        <th style={{ minWidth: 150 }}>案件名</th>
                        <th style={{ minWidth: 150 }}></th>
                        <th style={{ minWidth: 300 }}></th>
                        <th style={{ minWidth: 180 }}></th>
                        <th style={{ minWidth: 150 }}>支払金額(税抜)</th>
                        <th style={{ minWidth: 150 }}>消費税</th>
                        <th style={{ minWidth: 150 }}>支払金額(税込)</th>
                        <th style={{ minWidth: 40 }}></th>
                    </tr>
                </thead>
                <tbody>
                    {projects.map((element, index) => (
                        <ProjectRowComponent
                            key={element.id}
                            checkKeyExpand={checkKeyExpand}
                            setExpandKeys={setExpandKeys}
                            index={index}
                            project={element}
                        />
                    ))}
                </tbody>
                <tfoot>
                    <tr className="table-foot">
                        <td colSpan={2}>合計金額</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{helpers.formatNumberMoney(summaryTotal.actualCost)}円</td>
                        <td>{helpers.formatNumberMoney(summaryTotal.tax)}円</td>
                        <td>
                            {helpers.formatNumberMoney(
                                (summaryTotal?.actualCost ?? 0) + (summaryTotal?.tax ?? 0),
                            )}
                            円
                        </td>
                        <td></td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};

export default Table;
