import React from 'react';

import IconI from '../../../../../assets/icons/icon-i-aop.svg';
import { Table, Form, Checkbox } from 'antd';
import { HandlerImpl } from '../handler/HandlerImpl';
import { TYPE_I } from '../../constant';

const { Item } = Form;

interface props {
    handler: HandlerImpl;
}

function ProjectScope({ handler }: props) {
    const columns = [
        {
            title: 'No',
            key: 'no',
            width: 112,
            render: (row: any) => {
                return row.no ?? '-';
            },
        },
        {
            title: '機能',
            key: 'name',
            width: 400,
            render: (row: any) => {
                return row.name ?? '-';
            },
        },
        {
            title: '閲覧',
            key: 'browse',
            width: 257,
            render: (row: any) => {
                if (row.column?.includes('3')) {
                    const findIndex = row.column.findIndex((ele: any) => ele === '3');
                    return (
                        <Item name={row.code[findIndex]} valuePropName="checked">
                            <Checkbox />
                        </Item>
                    );
                }
                return '';
            },
        },
        {
            title: '',
            key: 'empty1',
        },
        {
            title: '',
            key: 'empty2',
        },
    ];

    return (
        <div className="aop-tab-list">
            <div className="aop-tab-item">
                <div className="aop-tab-title">
                    <span>案件管理</span>
                    <img src={IconI} alt="" onClick={() => handler.handleClickI(TYPE_I.h1)} />
                </div>
                <Table
                    bordered
                    pagination={false}
                    dataSource={handler.dataTab1[0]}
                    columns={columns}
                />
            </div>
            <div className="aop-tab-item">
                <div className="aop-tab-title">
                    <span>勤怠管理</span>
                </div>
                <Table
                    bordered
                    pagination={false}
                    dataSource={handler.dataTab1[1]}
                    columns={columns}
                />
            </div>
        </div>
    );
}

export default ProjectScope;
