import { useState } from "react";
import { FormInstance } from "antd";
import ConfirmModalAsync from "../modal/ConfirmModalAsync";
import ErrorNotification from "../notification/ErrorNotification";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../constants/MESSAGE";
const useCertificationFormP01Component = () => {
  const [lines, setLines] = useState<any[]>([]);
  const [maxKey, setMaxKey] = useState(1);

  const addLine = () => {
    setLines([...lines, maxKey + 1]);
    setMaxKey(maxKey + 1);
  };

  const removeLine = (form: FormInstance, value: number, formName: string) => {
    const hasName = !!form.getFieldValue([formName, `name`]);

    const isRowTouched = form.isFieldsTouched([
      [formName, `issueDate`],
      [formName, `registrationCode`],
      [formName, `issuer`],
      [formName, `expireDate`],
    ]);

    if (hasName || isRowTouched)
      ConfirmModalAsync({
        onOk: async () => {
          try {
            executeRemoveCertRow(value);
          } catch (error: any) {
            ErrorNotification(NOTIFICATION_TITLE.ERROR);
          }
        },
        onCancel: () => {},

        className: "confirm__modal",
        title: MESSAGE.MESSAGE_MODAL_DELETE_TITLE,
        description: MESSAGE.MESSAGE_DESCRIPTION3_DELETE_ORD001,
        canceText: LABEL_MESSAGE.CANCEL_MODAL,
        okText: LABEL_MESSAGE.OK_DELETE,
      });
    else executeRemoveCertRow(value);
  };

  const executeRemoveCertRow = (value: number) => {
    const cloneLineQuantity = [...lines];
    const index = cloneLineQuantity.findIndex((element) => element === value);
    if (index > -1) {
      cloneLineQuantity.splice(index, 1);
    }
    setLines(cloneLineQuantity);
  };

  const setValidExpireDate = (form: FormInstance, cert: string) => {
    form.setFields([
      {
        name: [cert, "expireDate"],
        errors: [],
      },
    ]);
  };
  const setValidIssueDate = (form: FormInstance, cert: string) => {
    form.setFields([
      {
        name: [cert, "issueDate"],
        errors: [],
      },
    ]);
  };

  return {
    setMaxKey,
    setLineQuantity: setLines,
    addLine,
    removeLine,
    setValidIssueDate,
    setValidExpireDate,
    lineQuantity: lines,
  };
};

export default useCertificationFormP01Component;
