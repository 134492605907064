import { Table } from "antd";
import { COLUMN } from "./ColumsTableCCUS";
import { CCUS02Handler } from "../handler/HandlerImpl";
import EmptyData from "../../../../../assets/icons/empty-data.svg";

interface props {
  handler: CCUS02Handler;
}
const TableCCUS = ({ handler }: props) => {
  return (
    <>
      {handler.dataTable.length == 0 ? (
        <div className="empty-data-container">
          <img src={EmptyData} alt="" />
        </div>
      ) : (
        <div className="w-100-percent" style={{height: 600}}>
          <Table
            columns={COLUMN(handler)}
            dataSource={handler.dataTable}
            pagination={false}
            className="table-ccus"
            scroll={{ y: "60vh", x: "max-content" }}
          />
        </div>
      )}
    </>
  );
};

export default TableCCUS;
