import { useState } from "react";
// Redux
import { useDispatch } from "react-redux";

// Services

import { setVisibleDB004 } from "../slice/Slice";
import { DB004UseCase } from "../../usecase/ServiceImpl";
import { DataTableType } from "../../entity/Entity";
import helpers from "../../../../../common/helpers/common";

export const TYPE_ITEM = {
  materialCost: {
    key: "materialCost",
    value: "材料費",
  },
  outsourceCost: {
    key: "outsourceCost",
    value: "外注費",
  },
  laborCost: {
    key: "laborCost",
    value: "労務費",
  },
  indirectCost: {
    key: "indirectCost",
    value: "間接工事費",
  },
};

const DB004Handler = (service: DB004UseCase) => {
  const dispatch = useDispatch();

  const [dataTable, setDataTable] = useState<DataTableType[]>([]);
  const [dataInformation, setDataInformation] = useState({});
  const [loading, setLoading] = useState<boolean>(false);

  const cancelModalDB004 = () => {
    dispatch(setVisibleDB004(false));
  };

  const getBudgetUtilizationRate = async (params: {
    projectId: number;
    from: string;
    to: string;
  }) => {
    try {
      setLoading(true);
      const response = await service.getBudgetUtilizationRate(params);
      setDataInformation({
        projectName: response?.results?.projectName,
        constructionFromDate: response?.results?.constructionFromDate,
      });
      setDataTable(converDataTable(response?.results));
    } finally {
      setLoading(false);
    }
  };

  const converDataTable = (data: any) => {
    let results: any = [];
    delete data.projectName;
    delete data.constructionFromDate;
    for (const key in data) {
      results.push({
        key: key,
        item:
          key === TYPE_ITEM.materialCost.key
            ? TYPE_ITEM.materialCost.value
            : key === TYPE_ITEM.outsourceCost.key
            ? TYPE_ITEM.outsourceCost.value
            : key === TYPE_ITEM.laborCost.key
            ? TYPE_ITEM.laborCost.value
            : key === TYPE_ITEM.indirectCost.key
            ? TYPE_ITEM.indirectCost.value
            : "",
        budget: `${helpers.formatCurrency(data[key].planCost) ?? 0}円`,
        operatingCost: `${helpers.formatCurrency(data[key].actualCost) ?? 0}円`,
        budgetBalance: `${helpers.formatCurrency(
          data[key].planCost - data[key].actualCost ?? 0
        )}円`,
        rateBudget: `${helpers.formatCurrency(
          (data[key].actualCost * 100) / data[key].planCost
        )}%`,
      });
    }
    return results;
  };

  return {
    loading,
    dataTable,
    dataInformation,
    cancelModalDB004,
    getBudgetUtilizationRate,
  };
};
export default DB004Handler;
