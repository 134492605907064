import COMMON from '../../../../common/constants/COMMON';
import helpers from '../../../../common/helpers/common';
import { SubConstructionType } from '../entity/Entity';
import { ConstructionAPIImpl } from './ServiceImpl';

const ConstructionService = (api: ConstructionAPIImpl) => {
    const getAttendanceSubConstructionListSubConstruction = async (params: {
        contractorConstructionId: number;
        constructionId: number;
        from?: string;
        to?: string;
    }): Promise<SubConstructionType[]> => {
        try {
            const timeOffset = COMMON.TIME_ZONE;
            const response = await api.getAttendanceSubConstructionListSubConstruction({
                ...params,
                from: params.from ? helpers.getFilterTime(params.from) : undefined,
                to: params.from ? helpers.getFilterTimeTo(params.from) : undefined,
                timeOffset: params.from || params.to ? timeOffset : undefined,
            });
            return response.data.results || [];
        } catch (error) {
            return [];
        }
    };
    return { getAttendanceSubConstructionListSubConstruction };
};

export default ConstructionService;
