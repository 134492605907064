import { createSlice } from '@reduxjs/toolkit';
import { getNumOfColByYear } from '../../../DB001/helpers';
import { createRangeArrayNumber } from '../../../DB001/presenter/handler/Handler';
import { DB007SliceType, DataPopupTableDB007, HeaderItem } from './../type/Presenter';
import moment from 'moment';
import COMMON from '../../../../../common/constants/COMMON';

const initialState: DB007SliceType = {
    isVisibleDB007: false,
    isRefresh: false,
    inforProject: {
        projectName: '',
        constructionFromDate: null,
    },
    dataPopupTableDB007: {
        headers: [],
        data: [[], [], []]
    }
};

export const db007Slice = createSlice({
    name: 'db007',
    initialState,
    reducers: {
        resetDB007: () => initialState,
        setVisibleDB007: (state, action) => {
            state.isVisibleDB007 = action.payload;
        },
        setInforProject: (state, action) => {
            state.inforProject = action.payload;
        },
        setDataPopupTableDB007: (state, action) => {
            const {rawData, parserData, from: fromParams, to: toParams} = action.payload;
            const headersNumberOfCol = getNumOfColByYear(parserData)

            const from = moment(fromParams).format(COMMON.FORMAT_DATE4)
            const to = moment(toParams).format(COMMON.FORMAT_DATE4)
            
            const startYear = +from.split("-")[0]
            const endYear = +to.split("-")[0]

            const headers = createRangeArrayNumber(startYear, endYear, 1).map((year: number) => ({
                numOfChildCol: headersNumberOfCol[year],
                label: year
            })) || []
            const headersSorted = headers.sort((a: HeaderItem, b: HeaderItem) => a.label - b.label)
            const listMonth = parserData.map((item: any) => item.month)
            const laborCostList = parserData.map((item: any) =>  item.laborCost)
            const workingDayList = parserData.map((item: any) =>  item.workingDay)
            const dataPopupTableDB007: DataPopupTableDB007 = {
                headers: headersSorted,
                data: [listMonth, laborCostList, workingDayList]
            }
            state.dataPopupTableDB007 = dataPopupTableDB007
        }
    },
});

export const { resetDB007, setVisibleDB007, setInforProject, setDataPopupTableDB007 } = db007Slice.actions;

export default db007Slice.reducer;
