import { AM018SliceType } from "./../type/Presenter";
import { createSlice } from "@reduxjs/toolkit";

const initialState: AM018SliceType = {
  loading: false,
  isVisible: false,
  refresh: false,
  typeModal: "create",
  requestId: undefined
};

export const am018Slice = createSlice({
  name: "am018",
  initialState,
  reducers: {
    resetAM018: () => initialState,
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsVisibleAM018: (state, action) => {
      state.isVisible = action.payload;
    },
    setRefreshAM018: (state) => {
      state.refresh = !state.refresh;
    },
    setTypeModalAM018:(state, action) =>{
      state.typeModal = action.payload;
    },
    setRequestIdAM018:(state, action) =>{
      state.requestId = action.payload;
    }
  },
});

export const {
  setLoading,
  setIsVisibleAM018,
  resetAM018,
  setRefreshAM018,
  setTypeModalAM018,
  setRequestIdAM018
} = am018Slice.actions;

export default am018Slice.reducer;
