import { PM002Api } from "./ServiceImpl";

const PM002Service = (pM002Api: PM002Api) => {
  const getData = async (params: any): Promise<any> => {
    try {
      return await pM002Api.getData(params);
    } catch (error) {
      throw error;
    }
  };
  const getStatus = async (params: any): Promise<any> => {
    try {
      return await pM002Api.getStatus(params);
    } catch (error) {
      throw error;
    }
  };
  const getAllUser = async (params: any): Promise<any> => {
    try {
      return await pM002Api.getAllUser(params);
    } catch (error) {
      throw error;
    }
  };
  const updateProjectInfoStatus = async (data: any): Promise<any> => {
    try {
      return await pM002Api.updateProjectInfoStatus(data);
    } catch (error) {
      throw error;
    }
  };
  const handlerAddMember = async (data: any): Promise<any> => {
    try {
      return await pM002Api.handlerAddMember(data);
    } catch (error) {
      throw error;
    }
  };

  const getCategoryNotification = async (params: any): Promise<any> => {
    try {
      return await pM002Api.getCategoryNotification(params);
    } catch (error) {
      throw error;
    }
  };

  const getListNotification = async (params: any): Promise<any> => {
    try {
      return await pM002Api.getListNotification(params);
    } catch (error) {
      throw error;
    }
  };

  const readNotification = async (params: any): Promise<any> => {
    try {
      return await pM002Api.readNotification(params);
    } catch (error) {
      throw error;
    }
  };

  const deleteNotification = async (params: any): Promise<any> => {
    try {
      return await pM002Api.deleteNotification(params);
    } catch (error) {
      throw error;
    }
  };

  return {
    getData,
    getStatus,
    getAllUser,
    updateProjectInfoStatus,
    handlerAddMember,
    getListNotification,
    getCategoryNotification,
    readNotification,
    deleteNotification,
  };
};

export default PM002Service;
