import { AxiosResponse } from "axios";
import axiosConfig from "../../../../../config/axiosConfig";

const CM004Api = () => {
  const getMoneyConstructionInformation = async (params: {
    constructionId: number;
    documentId: number;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/money/construction/information";
    return await axiosConfig.get(url, { params });
  };

  const getDetailProject = async (params: {
    projectId: number;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/project/detail";
    return await axiosConfig.get(url, { params });
  };

  return { getMoneyConstructionInformation, getDetailProject };
};

export default CM004Api;
