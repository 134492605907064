import { CM0017_3_1SliceType } from './../type/Presenter';
import { createSlice } from '@reduxjs/toolkit';

const initialState: CM0017_3_1SliceType = {
    loading: false,
    isVisible: false,
    refresh: false,
    typeModal: false,
    parentCode: {
        categoryId: null,
        documentOutsourceCostDataInformationSummaryId: null,
    },
    headerData: {
        traderName: null,
        contractWorkName: null,
        targetPeriod: null,
    },
    title: null,
};

export const cm0017_3_1Slice = createSlice({
    name: 'cm0017_3_1',
    initialState,
    reducers: {
        resetCM0017_3_1: () => initialState,
        setLoadingCM0017_3_1: (state, action) => {
            state.loading = action.payload;
        },
        setIsVisibleCM0017_3_1: (state, action) => {
            state.isVisible = action.payload;
        },
        setRefreshCM0017_3_1: (state) => {
            state.refresh = !state.refresh;
        },
        setTypeModalCM0017_3_1: (state, action) => {
            state.typeModal = action.payload;
        },
        setParentCodeCM0017_3_1: (state, action) => {
            state.parentCode = action.payload;
        },
        setHeaderDataCM0017_3_1: (state, action) => {
            state.headerData = action.payload;
        },
        setTitleCM0017_3_1: (state, action) => {
            state.title = action.payload;
        },
    },
});

export const {
    setLoadingCM0017_3_1,
    setIsVisibleCM0017_3_1,
    resetCM0017_3_1,
    setRefreshCM0017_3_1,
    setTypeModalCM0017_3_1,
    setParentCodeCM0017_3_1,
    setHeaderDataCM0017_3_1,
    setTitleCM0017_3_1,
} = cm0017_3_1Slice.actions;

export default cm0017_3_1Slice.reducer;
