import API from './infrastructure/api/Api';
import Handler from './presenter/handler/Handler';
import MainUI from './presenter/ui/UI';
import Service from './usecase/Service';

type props = {
    refreshData?: () => void;
};

const CM017_2_1Component = ({ refreshData }: props) => {
    const api = API();
    const service = Service(api);
    const handler = Handler(service, refreshData);
    return <MainUI handler={handler} />;
};

export default CM017_2_1Component;
