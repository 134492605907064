import React from 'react';

// Service

import { ORD001Handler } from '../handler/HandlerImpl';

// Component
import MenuWrapperComponent from '../../../../../common/components/menu-wrapper';
import BreadcrumbComponent from '../../../../../common/components/breadcrumb/BreadcrumbComponent';
import HeaderComponent from './Header';

// Media
import '../../../../../assets/styles/ORD/ORD001/style.css';
import ContentComponent from './Content';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import ORD002_1Component from '../../../ORD002_1';
import { useSearchParams } from 'react-router-dom';
import {
    FROM_PARAM,
    KEYWORD_PARAM,
    PAGE_PARAM,
    PLAN,
    SIZE_PARAM,
    SORT_BY,
    SORT_TYPE,
    SORT_TYPE_DESC,
    STATUS,
    SUBSCRIPTION,
    TO_PARAM,
} from '../../contants';
import COMMON from '../../../../../common/constants/COMMON';

type props = {
    handler: ORD001Handler;
};

const MainUI = ({ handler }: props) => {
    const BREADCRUMB_ORD001 = [
        {
            title: '販売管理',
            url: '',
            icon: <></>,
        },
        {
            title: 'オーダー管理',
            url: '',
            icon: <></>,
        },
    ];

    const ord002 = useSelector((state: RootState) => state.ord002_1.visible);
    const isRefreshORD001 = useSelector((state: RootState) => state.ord001.isRefreshORD001);
    const [searchParams] = useSearchParams();
    const page = searchParams.get(PAGE_PARAM) || COMMON.DEFAULT_PAGE;
    const size = searchParams.get(SIZE_PARAM) || COMMON.DEFAULT_SIZE;
    const keyword = searchParams.get(KEYWORD_PARAM) || '';
    const sortType = searchParams.get(SORT_TYPE) || SORT_TYPE_DESC;
    const sortBy = searchParams.get(SORT_BY) || '';
    const status = searchParams.get(STATUS) || '';
    const plan = searchParams.get(PLAN) || '';
    const subscription = searchParams.get(SUBSCRIPTION) || '';
    const from = searchParams.get(FROM_PARAM) || '';
    const to = searchParams.get(TO_PARAM) || '';

    React.useEffect(() => {
        handler.init();
        handler.getListPlan();
        handler.getListStatusOrder();
        handler.getDetailPlanSetting();
    }, []);
    React.useEffect(() => {
        const params: { [key: string]: any } = {
            page,
            size,
            sortType,
        };
        if (keyword !== '') params.keyword = keyword;
        if (sortBy !== '') params.sortBy = sortBy;
        if (status !== '') params.statusId = status;
        if (plan !== '') params.planId = plan;
        if (subscription !== '') params.subscription = subscription;
        if (from !== '') params.from = from;
        if (to !== '') params.to = to;
        handler.getListOrder(params);
    }, [
        page,
        size,
        keyword,
        sortType,
        sortBy,
        status,
        plan,
        subscription,
        from,
        to,
        isRefreshORD001,
    ]);

    return (
        <MenuWrapperComponent chosenKey={61} openKey={6}>
            <BreadcrumbComponent data={BREADCRUMB_ORD001} />
            <div className="ord--001__wrapper">
                <div className="ord--001__form">
                    <HeaderComponent handler={handler} />
                    <ContentComponent handler={handler} />
                </div>
            </div>
            {ord002 ? <ORD002_1Component /> : <></>}
        </MenuWrapperComponent>
    );
};

export default MainUI;
