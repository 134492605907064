import { uuidv4 } from '@firebase/util';
import { Form } from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ErrorNotification from '../../../../../common/components/notification/ErrorNotification';
import { NOTIFICATION_TITLE } from '../../../../../common/constants/MESSAGE';
import { RootState } from '../../../../../store';
import { ReSummarySubmittedOutsourceOffsetCost } from '../../entity/Entity';
import { CM0025_1UseCase } from '../../usecase/ServiceImpl';
import { setIsVisibleCM0025_1, setLoadingCM0025_1 } from '../slice/Slice';

const CM0025_1Handler = (CM0025_1Service: CM0025_1UseCase) => {
    // LIB FUNCTION
    const dispatch = useDispatch();

    //STATE
    const [form] = Form.useForm();
    const typeModal = useSelector((state: RootState) => state.cm0025_1.typeModal);
    const [
        listReSummarySubmittedOutsourceOffsetCostLocal,
        setListReSummarySubmittedOutsourceOffsetCostLocal,
    ] = useState<ReSummarySubmittedOutsourceOffsetCost[]>([]);
    const [totalCost, setTotalCost] = useState<number>(0);

    //function
    const getReSummarySubmittedOutsourceOffsetCost = async (
        documentSummaryOutsourceCostConstructionDataId: number,
        categoryId: number,
    ): Promise<any> => {
        try {
            dispatch(setLoadingCM0025_1(true));
            const params = {
                documentSummaryOutsourceCostConstructionDataId,
                categoryId,
            };
            const res = await CM0025_1Service.getReSummarySubmittedOutsourceOffsetCost(params);
            if (res?.results) {
                const data = formatReSummarySubmittedOutsourceOffsetCost(res.results);
                setListReSummarySubmittedOutsourceOffsetCostLocal(data);
                handleCalculateChange(data);
            }
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        } finally {
            dispatch(setLoadingCM0025_1(false));
        }
    };

    const formatReSummarySubmittedOutsourceOffsetCost = (data: any) => {
        const arr: ReSummarySubmittedOutsourceOffsetCost[] = data.map(
            (item: any, index: number) => {
                return {
                    key: uuidv4(),
                    id: item?.id,
                    item: item?.name,
                    taxCategoryComsumptionId: item?.category?.id,
                    taxCategoryComsumptionName: item?.category?.description,
                    offsetAmount: item?.value,
                };
            },
        );
        return arr;
    };

    const handleCalculateChange = (data: ReSummarySubmittedOutsourceOffsetCost[]) => {
        let total: number = 0;
        data.forEach((item) => {
            const cost = item?.offsetAmount
                ? item.offsetAmount
                : form.getFieldValue(`offsetAmount${item.key}`) ?? 0;
            if (!isNaN(cost)) total += cost;
        });

        setTotalCost(total);
    };

    const onCancel = () => {
        dispatch(setIsVisibleCM0025_1(false));
    };

    return {
        form,
        typeModal,
        listReSummarySubmittedOutsourceOffsetCostLocal,
        totalCost,
        onCancel,
        handleCalculateChange,
        getReSummarySubmittedOutsourceOffsetCost,
    };
};

export default CM0025_1Handler;
