import { Collapse, Tooltip } from 'antd';
import React from 'react';

import IconDown from '../../../../../assets/icons/arrow.svg';
import CooperationCompany from './CooperationCompany';
import SubConstructionItem from './SubConstructionItem';
import helpers from '../../../../../common/helpers/common';
import TooltipText from '../../../../../common/components/tooltip-text/TooltipText';

const { Panel } = Collapse;

interface props {
    construction: any;
    activeKey: number[] | string[];

    funcChangeCollapse(value: any): void;
    handleChangePage(
        constructionId: any,
        contractorId?: any,
        constructionName?: string,
        subConstructionName?: string,
    ): void;
}

const ConstructionItem = ({
    construction,
    activeKey,
    funcChangeCollapse,
    handleChangePage,
}: props) => {
    return (
        <Collapse
            style={{ minWidth: 1000 }}
            className="folder-collapse am005-collapse"
            activeKey={activeKey}
            onChange={funcChangeCollapse}
            expandIcon={({ isActive }) => {
                return (
                    <div className={`${isActive ? 'collapse-icon-active' : ''} collapse-icon`}>
                        <img src={IconDown} alt="" />
                    </div>
                );
            }}
        >
            <Panel
                key={`${construction.id}`}
                header={
                    <div className="am005-construction-panel-header">
                        <span className="am005-text-bold-16" >
                            <TooltipText content={construction?.description} />
                        </span>
                        <div className="am005-construction-panel-header-right">
                            <div className="am005-header-item">
                                <div className="am005-separator"></div>
                                <div className="am005-header-item-content">
                                    <span>合計金額: </span>
                                    <span className="am005-text-bold-14">
                                        {helpers.formatCurrency(construction?.laborCost)}円
                                    </span>
                                </div>
                            </div>
                            <div className="am005-header-item">
                                <div className="am005-separator"></div>
                                <div className="am005-header-item-content">
                                    <span>稼働人工数: </span>
                                    <span className="am005-text-bold-14">
                                        {helpers.formatCurrency(construction?.totalWorkingDay || 0)}人工
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            >
                <Tooltip title="クリックして詳細を表示します。" align={{ offset: [0, 15] }}>
                    <div
                        className="am005-my-company am005-cursor"
                        onClick={() =>
                            handleChangePage(construction.id, null, construction.description)
                        }
                    >
                        <span className="am005-text-bold-14" style={{ cursor: 'pointer' }}>
                            自社
                        </span>
                    </div>
                </Tooltip>
                {construction?.subConstruction?.map((sub: any) => {
                    if (sub?.level === 0) {
                        return (
                            <React.Fragment key={sub?.id}>
                                <CooperationCompany
                                    name={sub?.company?.name}
                                    titleLevel={sub?.titleLevel}
                                    totalWorkingDay={helpers.formatCurrency(sub.totalWorkingDay || 0)}
                                    laborCost={helpers.formatCurrency(sub?.totalLaborCost || 0)}
                                    member={sub?.numberOfMember}
                                    description={sub?.description}
                                    handleChangePage={() =>
                                        handleChangePage(
                                            construction.id,
                                            sub.id,
                                            construction?.description,
                                            sub?.description,
                                        )
                                    }
                                />
                            </React.Fragment>
                        );
                    }
                    return (
                        <React.Fragment key={sub?.id}>
                            <SubConstructionItem
                                level={{
                                    levelNumber: `LEVEL${sub?.level}`,
                                    levelTitle: sub?.titleLevel,
                                }}
                                laborCost={helpers.formatCurrency(sub?.totalLaborCost || 0)}
                                totalWorkingDay={helpers.formatCurrency(sub.totalWorkingDay || 0)}
                                constructionName={`${sub?.company?.name}（${sub?.description}）`}
                                member={sub?.numberOfMember}
                                handleChangePage={() => handleChangePage(construction.id)}
                            />
                        </React.Fragment>
                    );
                })}
            </Panel>
        </Collapse>
    );
};

export default ConstructionItem;
