// Built-in
import React from 'react';

// Source files
import { CM007ServiceImpl } from './../../usecase/ServiceImpl';
import { useDispatch } from 'react-redux';
import { resetCM007 } from '../slice/Slice';

const CM007Handler = () => {
    const dispatch = useDispatch();

    const resetRedux = () => {
        dispatch(resetCM007());
    };
    return { resetRedux };
};

export default CM007Handler;
