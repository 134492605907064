import React from "react";
import { CM0031Hanlder } from "../handler/HandlerImpl";
import { Modal, Button } from "antd";
import Table from "./Table";
import { RootState } from "../../../../../store";
import { useDispatch, useSelector } from "react-redux";
import COMMON, {
  STATUS_CM_CHILD_TABLE,
} from "../../../../../common/constants/COMMON";
import moment from "moment";
import IconCircleCheck from "../../../../../assets/icons/akar-icons_circle-check-fill.svg";
import { resetCM0031 } from "../slice/Slice";
import ApprovalFlow from "../../../../../common/components/approval-flow/ApprovalFlow";
import helpers from "../../../../../common/helpers/common";
import XIconModal from "../../../../../assets/icons/x-icon-modal.svg";
import ApproveConfirmModal from "../../../../../common/components/modal-approve-confirm";

interface props {
  handler: CM0031Hanlder;
  onSubmit?: () => void;
  onReject?: () => void;
}

const SORT_TYPE: string = "DESC",
  SORT_BY: string = "updatedAt";

function MainUI({ handler, onReject, onSubmit }: props) {
  const dispatch = useDispatch();

  const visibleCM0031 = useSelector(
    (state: RootState) => state.cm0031.isVisibleCM0031
  );
  const document = useSelector((state: RootState) => state.cm0031.document);
  React.useEffect(() => {
    handler.getProjectAll({
      page: COMMON.DEFAULT_PAGE,
      size: COMMON.DEFAULT_SIZE,
      sortType: SORT_TYPE,
      sortBy: SORT_BY,
      workspaceId: "1",
      statusId: 0,
    });
  }, []);
  React.useEffect(() => {
    if (document.id && visibleCM0031)
      Promise.all([
        handler.getSubmittedIndirectCostProjectTotal({
          documentId: document.id,
        }),
        handler.getWorkflowSummaryIndirectApprovalProcess(document.id),
      ]);
    return () => {
      dispatch(resetCM0031());
    };
  }, [visibleCM0031, JSON.stringify(document)]);

  return (
    <Modal
      style={{ marginLeft: 300, marginTop: 40, marginBottom: 40 }}
      className="group-detail-modal"
      visible={visibleCM0031}
      centered
      maskClosable={false}
      closable={false}
      width={1572}
      onCancel={handler.handleCancelModal}
      footer={
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {onSubmit && onReject ? (
            <>
              {/* DONE */}
              <ApproveConfirmModal onSubmit={onSubmit} />
              <Button
                className="button-purple cm047-btn-submit"
                icon={<img alt="" src={IconCircleCheck} />}
                size="large"
                onClick={handler.showConfirmApproveModal}
                disabled={
                  !(
                    document?.status ===
                    STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE
                  ) ||
                  !(
                    handler.currentUserApproval &&
                    helpers.checkApproveInWorkFlow(handler.currentUserApproval)
                  )
                }
                style={{ marginLeft: 16 }}
              >
                承認
              </Button>
              <Button
                size="large"
                className="button-warning cm047-btn-reject"
                onClick={onReject}
                disabled={
                  !(
                    document?.status ===
                    STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE
                  ) ||
                  !(
                    handler.currentUserApproval &&
                    helpers.checkApproveInWorkFlow(handler.currentUserApproval)
                  )
                }
              >
                否認
              </Button>
            </>
          ) : (
            <Button
              size="large"
              className="button-purple cm0028-modal-btn-ok button-dsb"
              onClick={handler.handleRetrieve}
              disabled={
                document?.status === STATUS_CM_CHILD_TABLE.MONEY_REJECT.CODE ||
                document?.status === STATUS_CM_CHILD_TABLE.MONEY_APPROVED.CODE
              }
            >
              取り下げ
            </Button>
          )}
          <Button
            onClick={handler.handleCancelModal}
            size="large"
            className="cm0028-modal-btn-cancel"
          >
            キャンセル
          </Button>
        </div>
      }
    >
      <div
        className="w-100-percent header-modal-containter"
        style={{ height: 50 }}
      >
        <div className="header-modal-title-om004 ">間接工事費</div>
        <div style={{ cursor: "pointer" }} onClick={handler.handleCancelModal}>
          <img alt="" src={XIconModal} style={{ height: 18, width: 18 }} />
        </div>
      </div>
      <div style={{ marginTop: 24, marginBottom: 8 }}>
        <ApprovalFlow
          isNotCheckingUser={true}
          breadcrumb={[]}
          listApprove={handler.workflow}
          currentUserApproval={handler.currentUserApproval}
        />
      </div>
      <div className="cm0028-modal-header">
        <div className="cm0028-modal-header-item">
          <span className="cm0028-modal-label">資料名</span>
          <span className="cm0028-modal-title">{document?.documentName}</span>
        </div>
        <div className="cm0028-modal-header-item">
          <span className="cm0028-modal-label">作成日</span>
          <span className="cm0028-modal-title">
            {document.submittedDate
              ? moment(document.submittedDate).format(COMMON.FORMAT_DATE_CM)
              : ""}
          </span>
        </div>
        <div className="cm0028-modal-header-item">
          <span className="cm0028-modal-label">対象期間</span>
          <span className="cm0028-modal-title">
            {`${document.from
                ? moment(document.from).format(COMMON.FORMAT_DATE_JA_MONTH)
                : ""
              }～${document.to
                ? moment(document.to).format(COMMON.FORMAT_DATE_JA_MONTH)
                : ""
              }`}
          </span>
        </div>
      </div>

      <Table handler={handler} />
    </Modal>
  );
}

export default MainUI;
