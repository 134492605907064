import HomeSvg from '../../../assets/icons/home.svg';

const getProjectIdFromPreviousPath = (previouspath: string) => {
    const splitPath = previouspath.split('|');
    const findProjectId = splitPath.find((element) => element.includes('projectId='));
    if (findProjectId) return findProjectId.replaceAll('projectId=', '');
    return '';
};

const getBreadCrumbCM004 = (previouspath: string | null) => {
    if (!previouspath)
        return {
            chosenKey: 51,
            openKey: 5,
            breadcrumb: [
                {
                    title: '収支管理',
                    url: '',
                    icon: '',
                },
                {
                    title: '案件別収支管理',
                    url: '',
                    icon: '',
                },
                {
                    title: '工事詳細情報',
                    icon: '',
                    url: '',
                },
            ],
        };

    const bread1: string = '/app-pm002';
    const bread2: string = '/app-pm005/' + getProjectIdFromPreviousPath(previouspath);
    const bread3: string = previouspath.replaceAll('|', '&');

    if (previouspath.includes('/app-cm0061')) {
        return {
            chosenKey: 51,
            openKey: 5,
            breadcrumb: [
                {
                    title: '案件管理',
                    url: '',
                    icon: (
                        <img style={{ paddingBottom: 2, paddingRight: 8 }} alt="" src={HomeSvg} />
                    ),
                },
                {
                    title: '案件別収支管理',
                    url: bread3,
                    icon: '',
                },
                {
                    title: '工事詳細情報',
                    url: '',
                    icon: '',
                },
            ],
        };
    }

    return {
        chosenKey: 81,
        openKey: 8,
        breadcrumb: [
            {
                title: '案件管理',
                url: '',
                icon: <img style={{ paddingBottom: 2, paddingRight: 8 }} alt="" src={HomeSvg} />,
            },
            {
                title: '案件一覧',
                url: bread1,
                icon: '',
            },
            {
                title: '案件詳細',
                icon: '',
                url: bread2,
            },
            {
                title: '案件別収支管理',
                url: bread3,
                icon: '',
            },
            {
                title: '工事詳細情報',
                url: '',
                icon: '',
            },
        ],
    };
};

const calculateBillingVolumePercent = (billingVolume: number, estimateCost: number) => {
    if (estimateCost === 0) return 'N/A';
    return (billingVolume * 100) / estimateCost;
};

export { getBreadCrumbCM004, calculateBillingVolumePercent };
