import axiosConfig from "../../../../../config/axiosConfig";
import { RequestCreateAttendance, RequestUpdateAttendance } from "../../entity/Entity";

const AM012Api = () => {
  const createAttendance = async (data: RequestCreateAttendance): Promise<any> => {
    try {
      const url = "/request/create/attendance";
      const response = await axiosConfig.post(url, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const updateAttendance = async (data: RequestUpdateAttendance): Promise<any> => {
    try {
      const url = "/request/update/attendance";
      const response = await axiosConfig.put(url, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getAttendanceCategory = async (): Promise<any> => {
    try {
      const url = "/request/attendance/category";
      const response = await axiosConfig.get(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  const getProjects = async (params: any): Promise<any> => {
    try {
      const url = "/money/project/list";
      const response = await axiosConfig.get(url, { params });
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  const getRequestAttendance = async (params: any): Promise<any> => {
    try {
      const url = "/request/attendance";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  const getAttendanceUserConstruction = async (params: any): Promise<any> => {
    try {
      const url = "/attendance/user/construction";
      const response = await axiosConfig.get(url, { params });
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  const getProjectById = async (params: any): Promise<any> => {
    try {
      const url = "/project/detail";
      const response = await axiosConfig.get(url, { params });
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  const getRequestApprover = async (): Promise<any> => {
    try {
      const url = "/request/approver";
      const response = await axiosConfig.get(url);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  const getProjectStatus = async (params: any): Promise<any> => {
    try {
      const url = "/project/status";
      const response = await axiosConfig.get(url, { params });
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  return {
    createAttendance,
    updateAttendance,
    getAttendanceCategory,
    getProjects,
    getRequestAttendance,
    getAttendanceUserConstruction,
    getProjectById,
    getRequestApprover,
    getProjectStatus
  };
};

export default AM012Api;
