import React from "react";
import { Button, Collapse, Form, Input } from "antd";
import { memo } from "react";
import { PM014TableHandler } from "../handler/HandlerImpl";
import PM011ItemMember from "./PM014ItemMember";
import { useSelector } from "react-redux";
import { project } from "../slice/Slice";

import IconDown from "../../../../../assets/icons/arrow.svg";
import PM014ItemMember from "./PM014ItemMember";
import { onBlurTrimFormItem } from "../../../../../common/helpers/typeInput";

const { Panel } = Collapse;

interface props {
  color?: string;
  padding?: number;
  background?: string;
  handler: PM014TableHandler;
  name: string;
  totalMember: number;
  titleLevel: string;
  treeLevel: number;
  constructionId: number;
  contractorConstructionId: number;
  id: number;
}

const PM014Item = ({
  color,
  padding,
  background,
  name,
  totalMember,
  titleLevel,
  treeLevel,
  handler,
  constructionId,
  contractorConstructionId,
  id,
}: props) => {
  color = color ? color : "#33CC99";
  padding = padding ? padding : 0;
  background = background ? background : "rgba(51, 204, 153, 0.12)";

  const [enableEdit, setEnableEdit] = React.useState<boolean>(false);
  const [members, setMembers] = React.useState<any[]>([]);
  const [memberApprove, setMemberApprove] = React.useState<any[]>([]);
  const [activeKeys, setActiveKeys] = React.useState<any[]>([]);
  const [openCollapse, setOpenCollapse] = React.useState<boolean>(false);
  const [render, setRender] = React.useState<boolean>(false);
  const [titleLevelBT, setTitleLevelBT] = React.useState<string>(titleLevel);
  const [titleLevelBTSave, setTitleLevelBTSave] = React.useState<string>(titleLevel);
  const [totalApprove, setTotalApprove] = React.useState<number>(0);
  const [formTitleLevelBT] = Form.useForm();
  React.useEffect(() => {
    if (handler.keySearch && handler.keySearch !== "") {
      handler.funcGetSubcontractorMemberParent(
        constructionId,
        contractorConstructionId,
        setMembers,
        setMemberApprove,
        handler.keySearch,
        handler.currentTab !== "1"
      );
    } else {
      handler.funcGetSubcontractorMemberParent(
        constructionId,
        contractorConstructionId,
        setMembers,
        setMemberApprove,
        undefined,
        handler.currentTab !== "1"
      );
    }
    setActiveKeys(handler.keyCollapse);
    setTotalApprove(handler.funcCountMemberInside(`${id}`, handler.keyAT));
  }, [handler.currentTab, handler.keySearch]);

  React.useEffect(() => {
    if (openCollapse) {
      if (handler.keySearch && handler.keySearch !== "") {
        handler.funcGetSubcontractorMemberParent(
          constructionId,
          contractorConstructionId,
          setMembers,
          setMemberApprove,
          handler.keySearch,
          handler.currentTab !== "1"
        );
      } else {
        handler.funcGetSubcontractorMemberParent(
          constructionId,
          contractorConstructionId,
          setMembers,
          setMemberApprove,
          undefined,
          handler.currentTab !== "1"
        );
      }
      setActiveKeys(handler.keyCollapse);
    } else {
      setActiveKeys([]);
    }

    setTotalApprove(handler.funcCountMemberInside(`${id}`, handler.keyAT));
  }, [
    openCollapse,
    handler.currentTab,
    handler.keySearch,
    handler.isRefreshPM014,
  ]);

  const funcUpdateTitleLevel = () => {
    handler.funcUpdateContractorLevel(
      Number(constructionId),
      Number(contractorConstructionId),
      titleLevelBT
    );
    setTitleLevelBTSave(titleLevelBT)
    setEnableEdit(false);
    setRender(!render);
  };

  return (
    <Collapse
      key={`${activeKeys.length}${render}`}
      className="folder-collapse pm011-collapse-item"
      onChange={(e: any) => {
        if (e.length !== 0) {
          setOpenCollapse(true);
        } else {
          setOpenCollapse(false);
        }
      }}
      defaultActiveKey={activeKeys}
      expandIcon={({ isActive }) => {
        return (
          <div
            className={`${isActive ? "collapse-icon-active" : ""
              } collapse-icon`}
          >
            <img src={IconDown} alt="" />
          </div>
        );
      }}
    >
      <Panel
        className="pm011-panel"
        key={`${treeLevel}-${contractorConstructionId}`}
        disabled={members.length == 0}
        header={
          <div
            className="pm011-panel-header-title"
            style={{
              paddingLeft: `calc(16px + ${padding}px)`,
            }}
          >
            <div className="pm011-panel-header-title-left">
              <div className="pm011-panel-header-icon">
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.875 13.2918L12.375 18.7918L11.0733 17.4902L14.3642 14.2085H4.125V3.2085H5.95833V12.3752H14.3642L11.0733 9.0935L12.375 7.79183L17.875 13.2918Z"
                    fill={color}
                  />
                </svg>
              </div>
              <span className="pm011-panel-header-item-text">{name}</span>
              {enableEdit ? (
                <>
                  <>
                    <div
                      style={{
                        marginLeft: 10,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Form form={formTitleLevelBT}>
                        <Form.Item
                          name="titleLevelBT"
                          style={{ marginBottom: 0 }}
                        >
                          <Input
                            maxLength={20}
                            defaultValue={
                              titleLevel ? titleLevel : titleLevelBT
                            }
                            onChange={(e: any) =>
                              setTitleLevelBT(e.target.value.trim())
                            }
                            onBlur={() =>
                              onBlurTrimFormItem(
                                formTitleLevelBT,
                                "titleLevelBT"
                              )
                            }
                          />
                        </Form.Item>
                      </Form>
                      <Button
                        className="button-purple"
                        style={{ marginLeft: 10, letterSpacing: "-1px" }}
                        onClick={funcUpdateTitleLevel}
                      >
                        保存
                      </Button>
                      <Button
                        style={{ marginLeft: 5, borderRadius: 3 }}
                        onClick={() => {
                          setEnableEdit(!enableEdit);
                          setTitleLevelBT(titleLevelBTSave);
                          formTitleLevelBT.setFieldValue('titleLevelBT', titleLevelBTSave)
                        }}
                      >
                        キャンセル
                      </Button>
                    </div>
                  </>
                </>
              ) : (
                <>
                  <div
                    className="pm011-panel-button"
                    style={{ backgroundColor: background }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <span style={{ color: color }}>
                      （{titleLevelBT ? titleLevelBT : titleLevel}）
                    </span>
                    <div className="pm011-panel-button-icon">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ cursor: "pointer" }}
                        onClick={() => setEnableEdit(!enableEdit)}
                      >
                        <path
                          d="M9.16504 13.6287H14.0001"
                          stroke={color}
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M8.52001 2.52986C9.0371 1.91186 9.96666 1.82124 10.5975 2.32782C10.6324 2.35531 11.753 3.22586 11.753 3.22586C12.446 3.64479 12.6613 4.5354 12.2329 5.21506C12.2102 5.25146 5.87463 13.1763 5.87463 13.1763C5.66385 13.4393 5.34389 13.5945 5.00194 13.5982L2.57569 13.6287L2.02902 11.3149C1.95244 10.9895 2.02902 10.6478 2.2398 10.3849L8.52001 2.52986Z"
                          stroke={color}
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7.34717 4.00049L10.982 6.7919"
                          stroke={color}
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="pm011-panel-header-title-right">
              人数：
              {members.length || 0}人
            </div>
          </div>
        }
      >
        <PM014ItemMember
          data={handler.currentTab === "1" ? members : memberApprove}
          handler={handler}
          constructionId={constructionId}
          contractorConstructionId={contractorConstructionId}
        />
      </Panel>
    </Collapse>
  );
};

export default PM014Item;
