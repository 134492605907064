import CM0022Api from './infrastructure/api/Api';
import CM0022Handler from './presenter/handler/Handler';
import CM0022Service from './usecase/Service';
import MainUI from './presenter/ui/UI';

import '../../../assets/styles/AM/AM002.css';
import '../../../assets/styles/OM/OM002.css';

const CM0022Component = () => {
    const api = CM0022Api();

    const service = CM0022Service(api);

    const handler = CM0022Handler(service);

    return <MainUI handler={handler} />;
};

export default CM0022Component;
