import AM014Api from "./infrastructure/api/Api";
import AM014Handler from "./presenter/handler/Handler";
import AM014UI from "./presenter/ui/UI";
import AM014Service from "./usecase/Service";

const AM014Component = () => {
  const am014Api = AM014Api();
  const am014Service = AM014Service(am014Api);
  const am014Handler = AM014Handler(am014Service);
  return <AM014UI handler={am014Handler} />;
};

export default AM014Component;
