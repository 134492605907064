import {
  BranchPaginationEntity,
  RolePaginationEntity,
} from "./../../entity/Entity";
import axiosConfig from "../../../../../config/axiosConfig";
import { CertificateEntity, MemberEntity } from "../../entity/Entity";
import axios from "axios";
import {
  DetailMemberEntity,
  MemberUpdateEntity,
  S3ImageParams,
} from "../../../../OM/OM006/entity/Entity";

const API = () => {
  const createNewMember = async (data: MemberEntity): Promise<any> => {
    try {
      const url = "/member";
      const response = await axiosConfig.post(url, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getBranchList = async (
    params: any
  ): Promise<BranchPaginationEntity> => {
    try {
      const url = "/branch/list";
      const response = await axiosConfig.get(url, { params });
      return {
        results: response?.data?.results ?? [],
        pagination: response?.data?.pagination ?? {
          count: 0,
          displayRecord: 0,
          numPages: 0,
          page: 0,
        },
      };
    } catch (error) {
      return {
        pagination: {
          count: 0,
          displayRecord: 0,
          numPages: 0,
          page: 0,
        },
        results: [],
      };
    }
  };

  const getRoleList = async (params: any): Promise<RolePaginationEntity> => {
    try {
      const url = "/role/list";
      const response = await axiosConfig.get(url, { params });
      return {
        results: response?.data?.results ?? [],
        pagination: response?.data?.pagination ?? {
          count: 0,
          displayRecord: 0,
          numPages: 0,
          page: 0,
        },
      };
    } catch (error) {
      return {
        pagination: {
          count: 0,
          displayRecord: 0,
          numPages: 0,
          page: 0,
        },
        results: [],
      };
    }
  };

  const getCertificateType = async (): Promise<CertificateEntity[]> => {
    try {
      const url = "/certificate/type";
      const response = await axiosConfig.get(url);
      return response?.data?.results ?? [];
    } catch (error) {
      return [];
    }
  };

  const getPresignAvatarUpload = async (params: any): Promise<any> => {
    try {
      const url = "/user/presign-avatar-upload";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  const uploadAvatar = async (
    data: any,
    url: string,
    type: string
  ): Promise<any> => {
    try {
      const response = await axios.put(url, data, {
        headers: {
          "Content-Type": type,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const updateMemberAvatar = async (data: any) => {
    const url = "/member/avatar";
    try {
      const response = await axiosConfig.put(url, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  const updateMember = async (data: MemberUpdateEntity): Promise<any> => {
    try {
      const url = "/member";
      const response = await axiosConfig.put(url, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getDetailMember = async (
    params: any
  ): Promise<DetailMemberEntity | null> => {
    try {
      const url = "/group/member";
      const response = await axiosConfig.get(url, { params });
      return response?.data?.results ?? null;
    } catch (error) {
      return null;
    }
  };

  const getS3MemberImage = async (params: S3ImageParams): Promise<any> => {
    try {
      const url = "/user/presign-link";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const deleteMember = async (data: any): Promise<any> => {
    try {
      const url = "/member";
      const response = await axiosConfig.delete(url, { data });
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  const getGroup = async (): Promise<any> => {
    try {
      const url = "/organization/list-group";
      const response = await axiosConfig.get(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  return {
    createNewMember,
    getRoleList,
    getBranchList,
    getCertificateType,
    getPresignAvatarUpload,
    uploadAvatar,
    updateMemberAvatar,
    updateMember,
    getDetailMember,
    getS3MemberImage,
    deleteMember,
    getGroup,
  };
};

export default API;
