// Built-in
import React from "react";

// Source files
import { CM0015ServiceImpl } from "./../../usecase/ServiceImpl";
import { useDispatch } from "react-redux";
import { resetCM0015, setConstructionDirectCost } from "../slice/Slice";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../../common/constants/MESSAGE";

const CM0015Handler = (service: CM0015ServiceImpl) => {
  const dispatch = useDispatch();
  const clearRedux = () => {
    dispatch(resetCM0015());
  };

  const getMoneyIndirectCostConstructionDirectCost = async (params: {
    constructionId: number;
  }) => {
    try {
      dispatch(setLoading(true));
      const response = await service.getMoneyIndirectCostConstructionDirectCost(
        params
      );
      dispatch(setConstructionDirectCost(response?.results ?? []));
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  return { clearRedux, getMoneyIndirectCostConstructionDirectCost };
};

export default CM0015Handler;
