import { useSearchParams } from "react-router-dom";
import { Form } from "antd";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import { ORD002_1ServiceImpl } from "../../usecase/ServiceImpl";
import { useState } from "react";
import { uuidv4 } from "@firebase/util";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import COMMON, {
  ORDER_STATUS,
  PLAN_TYPE,
} from "../../../../../common/constants/COMMON";
import ConfirmModal from "../../../../../common/components/modal/ConfirmModal";
import {
  OrderCreationUpdatePayload,
  OrderDetail,
  OrderStatus,
  PlanData,
  PlanSettingDetail,
} from "../../entity/Entity";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import {
  closeORD002,
  setChosenPlan,
  setChosenPlanFunction,
  setIsFormValidating,
} from "../slice/Slice";
import helpers from "../../../../../common/helpers/common";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import { RootState } from "../../../../../store";
import { setIsRefreshORD001, setSelectedRowsKey } from "../../../ORD001/presenter/slice/Slice";

const CM0020Handler = (service: ORD002_1ServiceImpl) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedRowsKey = useSelector((state: RootState) => state.ord001.selectedRowsKey);
  const [isEdit, setIsEdit] = useState(false);

  const [planList, setPlanList] = useState<PlanData[]>([]);
  const [statusOrder, setStatusOrder] = useState<OrderStatus[]>([]);
  const [planDetail, setPlanDetail] = useState<PlanSettingDetail>();
  const [orderDetail, setOrderDetail] = useState<OrderDetail>();
  const [existedPlanFunctions, setExistedPlanFunctions] = useState<any[]>([]);
  const [newPlanFunctions, setNewPlanFunctions] = useState<any>([]);

  const [totalDiscount, setTotalDiscount] = useState<number>(0);
  const [totalCost, setTotalCost] = useState<number>(0);

  const chosenPlan = useSelector(
    (state: RootState) => state.ord002_1.chosenPlan
  );

  const defaultValueForm = () => {
    form.setFieldValue("planId", PLAN_TYPE.PLAN_BASIC);
  };

  const getDataFromLocalSource = (
    planList: PlanData[],
    statusOrder: OrderStatus[],
    planDetail: PlanSettingDetail | null
  ) => {
    setPlanList(planList);
    setStatusOrder(statusOrder);
    if (planDetail) setPlanDetail(planDetail);
  };

  const submitForm = async (value: any, orderId?: number) => {
    try {
      dispatch(setLoading(true));
      const listPlan = [...newPlanFunctions, ...existedPlanFunctions];
      const newListPlan: any[] = [];
      listPlan.forEach((element) => {
        if (element.key.includes("PlanFunction")) {
          newListPlan.push(value[`${element.key}`]);
        }
      });
      const startDate = helpers.getFilterTime(value?.startDate);
      const endDate = helpers.getFilterTimeTo(value?.endDate); // blank case
      const statusId = statusOrder.find(
        (element) => element.code === value?.statusId
      );
      const planId = planList.find(
        (element) => element.subCategory.code === value?.planId
      );

      const payloadData: OrderCreationUpdatePayload = {
        adminFullName: value?.adminFullName,
        adminName: value?.adminName,
        companyEmail: value?.companyEmail,
        companyName: value?.companyName,
        fax: value?.fax, //.replaceAll('-', ''),
        listSubcription: [],
        orderAddress: value?.orderAddress,
        orderCost: value?.orderCost,
        phoneNumber: value?.phoneNumber, //.replaceAll('-', ''),
        postNumber: value?.postNumber.replaceAll("-", ""),
        statusId: statusId?.id ?? 0,
        id: orderId,
        planId: planId?.id ?? 0,
        discount:
          chosenPlan?.subCategory.code !== PLAN_TYPE.PLAN_BASIC
            ? 0
            : value?.discount,
        endDate:
          chosenPlan?.subCategory.code !== PLAN_TYPE.PLAN_BASIC ? "" : endDate,
        planPersonId:
          chosenPlan?.subCategory.code !== PLAN_TYPE.PLAN_BASIC
            ? undefined
            : value?.planPersonId,
        startDate:
          chosenPlan?.subCategory.code !== PLAN_TYPE.PLAN_BASIC
            ? ""
            : startDate,
      };
      if (chosenPlan?.subCategory.code === PLAN_TYPE.PLAN_BASIC)
        payloadData.listSubcription = newListPlan.map((item: any) => {
          const startDate = helpers.getFilterTime(item?.startDate);
          const endDate = helpers.getFilterTimeTo(item?.endDate); // blank case
          const statusId = statusOrder.find(
            (element) => element.code === item?.statusId
          );
          return {
            discount: item.discount,
            endDate: endDate,
            id: item?.id,
            startDate: startDate,
            statusId: statusId?.id ?? 0,
          };
        });
      let responseMessage = NOTIFICATION_TITLE.SUCCESS;
      if (orderId) {
        if (chosenPlan?.subCategory.code !== PLAN_TYPE.PLAN_BASIC)
          payloadData.planPersonId = orderDetail?.planPerson.id;
        responseMessage = await service.putOrderUpdate(payloadData);
        let newSelectedRowsKey = selectedRowsKey.filter((item: any) => item != orderId)
        dispatch(setSelectedRowsKey(newSelectedRowsKey))
      } else responseMessage = await service.postOrderCreate(payloadData);

      const splittedMessage = responseMessage.split("、");
      if (splittedMessage.length > 1) {
        SuccessNotification(
          splittedMessage.map((message, index) => {
            if (index === splittedMessage.length - 1) return message;
            return message + "、";
          })
        );
      } else SuccessNotification(responseMessage);

      dispatch(setIsRefreshORD001());
      resetAllState();
      dispatch(closeORD002());
      searchParams.set("page", COMMON.DEFAULT_PAGE + "");
      setSearchParams(searchParams);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  // update function
  const getOrderDetail = async (params: { orderId: number }) => {
    try {
      dispatch(setLoading(true));
      const results = await service.getOrderDetail(params);
      setOrderDetail(results);
      // set form value
      if (!results) return;
      form.setFieldsValue({
        companyName: results.companyName ?? "",
        adminName: results.adminName ?? "",
        adminFullName: results.adminFullName ?? "",
        companyEmail: results.companyEmail ?? "",
        postNumber: helpers.formatPostNumber(results.postNumber),
        orderAddress: results.orderAddress ?? "",
        phoneNumber: results.phoneNumber,
        fax: results.fax,
        planId: results.plan.subCategory.code ?? "",
        statusId: results.statusOrder.code ?? "",
      });

      if (results.plan.subCategory.code === PLAN_TYPE.PLAN_BASIC) {
        form.setFieldsValue({
          startDate: results.startDate ? moment(results.startDate) : undefined,
          endDate: results.endDate ? moment(results.endDate) : undefined,
          planPersonId: results.planPerson.id ?? "",
          discount: results.discount ?? "",
        });

        // subscription
        let subTotalDiscount = 0;
        let subTotalCost = 0;
        const existedDataSub: any[] = [];
        const existedPlanFunc: number[] = [];
        results?.orderSubscription?.forEach((element) => {
          const key = `existedPlanFunction${uuidv4()}`;
          subTotalDiscount += element.discount ?? 0;
          subTotalCost += element.orderCost ?? 0;

          form.setFields([
            {
              name: [`${key}`, "startDate"],
              value: element.startDate ? moment(element.startDate) : undefined,
            },
            {
              name: [`${key}`, "endDate"],
              value: element.endDate ? moment(element.endDate) : undefined,
            },
            {
              name: [`${key}`, "statusId"],
              value: element.status.code,
            },
            {
              name: [`${key}`, "discount"],
              value: element.discount,
            },
            {
              name: [`${key}`, "id"],
              value: element.id,
            },
          ]);
          existedPlanFunc.push(element.id);
          existedDataSub.push({ key: key, data: element });
        });
        setTotalDiscount(subTotalDiscount);
        setTotalCost(subTotalCost);
        setExistedPlanFunctions(existedDataSub);
        dispatch(setChosenPlanFunction(existedPlanFunc));
      } else {
        addAPlanFunction();
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  // common
  const getListStatusOrder = async () => {
    try {
      dispatch(setLoading(true));
      const results = await service.getListStatusOrder();
      setStatusOrder(results);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  // common
  const getPlanList = async () => {
    try {
      dispatch(setLoading(true));
      const results = await service.getPlanList();
      setPlanList(results);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  // common
  const getPlanSettingDetail = async () => {
    try {
      dispatch(setLoading(true));
      const results = await service.getPlanSettingDetail();
      setPlanDetail(results);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const onStatusIdChange = () => {
    const endPlan = form.getFieldValue("endPlan");
    if (
      endPlan &&
      moment(endPlan) < moment() &&
      moment(endPlan).format(COMMON.FORMAT_DATE) !==
      moment().format(COMMON.FORMAT_DATE)
    )
      form.setFieldValue("statusId", ORDER_STATUS.ACTIVE_ORDER);
    setIsEdit(true);
  };

  const resetAllState = () => {
    // form.resetFields();
    // setIsDirty(DEFAULT_STATE.isDirty);
    // setIsEditing(DEFAULT_STATE.isEditing);
    // setPlanUserAddition(DEFAULT_STATE.planUserAddition);
  };

  const addAPlanFunction = () => {
    const newPlanFunction = { key: "newPlanFunction" + uuidv4() };
    setNewPlanFunctions([newPlanFunction, ...newPlanFunctions]);
  };

  const onValidatePlanFunction = async (planFunction: any[]) => {
    try {
      const filteredData = planFunction.filter((plan) => {
        return !!form.getFieldValue([`${plan.key}`, `id`]);
      });

      const fieldData = filteredData.map((plan) => {
        return [`${plan.key}`, `id`];
      });
      await form.validateFields(fieldData);
    } catch (error: any) {
    } finally {
      dispatch(setIsFormValidating(false));
    }
  };

  const removePlanFunction = (id: string) => {
    if (id.includes("new")) {
      const newPlanFunction = newPlanFunctions.filter(
        (item: any) => item.key !== id
      );
      setNewPlanFunctions(newPlanFunction);
    } else {
      const newExistedPlanFunction = existedPlanFunctions.filter(
        (item: any) => item.key !== id
      );
      setExistedPlanFunctions(newExistedPlanFunction);
    }
    form.resetFields([`${id}`]);
    calculateTotalCostDiscount();
    getChosenPlanFunctionOnFormChange();
    dispatch(setIsFormValidating(true));
  };

  const deleteAPlanFunction = (id: string, name: string) => {
    const formValue = form.getFieldValue([`${id}`, `id`]);

    if (!formValue) removePlanFunction(id);
    else {
      const chosenPlanFunc: any = planDetail?.basicPlan?.subscription?.find(
        (element) => element.id === formValue
      );
      ConfirmModal({
        onOk: () => {
          removePlanFunction(id);
        },
        className: "confirm__modal",
        title: MESSAGE.MESSAGE_MODAL_DELETE_TITLE,
        description: `${chosenPlanFunc?.name ?? ""}${MESSAGE.MESSAGE_DESCRIPTION_DELETE_ORD
          }`,
        canceText: LABEL_MESSAGE.CANCEL_MODAL,
        okText: LABEL_MESSAGE.OK_DELETE,
        isCenterWithoutMenu: true,
      });
    }
  };

  const onCloseModal = (description: any) => {
    if (isEdit) {
      ConfirmModal({
        onOk: () => {
          dispatch(closeORD002());
          setIsEdit(false);
        },
        className: "confirm__modal",
        title: MESSAGE.MESSAGE_020,
        description: description,
        canceText: LABEL_MESSAGE.CANCEL_MODAL,
        okText: LABEL_MESSAGE.OK_MODAL,
        isCenterWithoutMenu: true,
      });
    } else {
      dispatch(closeORD002());
    }
  };

  const calculateTotalCostDiscount = () => {
    const value = form.getFieldsValue();
    let totalDiscount = value?.discount ?? 0;
    let totalCost = 0;
    for (let key in value) {
      if (key.includes("PlanFunction")) {
        totalDiscount += value[key]?.discount ?? 0;
        totalCost += value[key]?.currentCost ?? 0;
      }
    }
    setTotalCost(totalCost);
    setTotalDiscount(totalDiscount);
  };

  const getChosenPlanFunctionOnFormChange = () => {
    const planFunctionAll = [...existedPlanFunctions, ...newPlanFunctions].map(
      (element) => {
        return form.getFieldValue([`${element.key}`, "id"]);
      }
    );
    const truthyElement = planFunctionAll.filter(
      (element) => element !== undefined
    );
    dispatch(setChosenPlanFunction(truthyElement));
  };

  const onFormChange = () => {
    getChosenPlanFunctionOnFormChange();
    setIsEdit(true);
  };

  const onPlanChange = (value: any) => {
    const foundPlan = planList.find(
      (element) => element.subCategory.code === value
    );
    if (foundPlan) dispatch(setChosenPlan(foundPlan));
  };

  const initiatePlanSetting = (planList: PlanData[], planCode: string) => {
    const foundPlan = planList.find(
      (element) => element.subCategory.code === planCode
    );
    if (foundPlan) dispatch(setChosenPlan(foundPlan));
  };

  return {
    form,
    planList,
    statusOrder,
    planDetail,
    existedPlanFunctions,
    newPlanFunctions,
    totalDiscount,
    totalCost,
    orderDetail,
    addAPlanFunction,
    deleteAPlanFunction,
    onCloseModal,
    defaultValueForm,
    onFormChange,
    submitForm,
    getListStatusOrder,
    getPlanList,
    onStatusIdChange,
    getPlanSettingDetail,
    getOrderDetail,
    onPlanChange,
    initiatePlanSetting,
    getDataFromLocalSource,
    getChosenPlanFunctionOnFormChange,
    calculateTotalCostDiscount,
    onValidatePlanFunction,
  };
};

export default CM0020Handler;
