import React from 'react'
import { ScheduleCategoryColors } from '../../entity/Entity';
import { Modal } from 'antd';
import XIconModal from "../../../../../assets/icons/x-icon-modal.svg";

interface props {
    onCancel: () => void
    onChooseColor: (value: ScheduleCategoryColors) => void;
    colors: ScheduleCategoryColors[];
    isVisible: boolean
}

export const ColorPickerModal = ({ colors, isVisible, onCancel, onChooseColor }: props) => {
    return !isVisible ? null : <Modal
        className="group-detail-modal"
        style={{ marginLeft: 300, marginTop: 40, marginBottom: 40 }}
        visible={true}
        closable={false}
        maskClosable={false}
        width={232}
        centered={true}
        footer={null}
    >
        <div className="w-100-percent" style={{ display: 'flex', justifyContent: "flex-end" }} >
            <div style={{ cursor: "pointer" }} onClick={onCancel}>
                <img alt="" src={XIconModal} style={{ height: 18, width: 18 }} />
            </div>
        </div>
        <div style={{ display: "flex", gap: "16px", flexDirection: 'row', flexWrap: 'wrap', marginTop: 16 }}>
            {
                colors.map((color) =>
                    <div key={color.id} className='color-view-container button-style'
                        style={{ background: color.colorRgb }}
                        onClick={() => onChooseColor(color)}
                    >
                    </div>
                )
            }
        </div>

    </Modal>
}