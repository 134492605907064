import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { rowKeySelected, resetOM005, dataUpload } from "../slice/Slice";
import { Button, Form } from "antd";
import { OM005Hanlder } from "../handler/HandlerImpl";

interface props {
  handler: OM005Hanlder;
}

const OM005Footer = ({ handler }: props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { groupId } = useParams();
  const rowKeyed = useSelector(rowKeySelected);

  const onNavigateBack = () => {
    dispatch(resetOM005());
    navigate(`/app-om002?groupId=${groupId}`);
  };

  return (
    <div className="om005__footer">
      <div className="om005__footer-wrapper">
        <Form.Item>
          <Button
            htmlType="submit"
            className="om005__footer-addition"
            disabled={rowKeyed.length === 0 ? true : false}
            style={{ letterSpacing: "-1px" }}
          >
            追加
          </Button>
        </Form.Item>
        <Button className="om005__footer-cancel" onClick={onNavigateBack}>
          キャンセル
        </Button>
      </div>
    </div>
  );
};

export default OM005Footer;
