import { useSearchParams } from "react-router-dom";

import BreadcrumbComponent from "../../../../../common/components/breadcrumb/BreadcrumbComponent";
import { Button, Layout } from "antd";
import MenuWrapperComponent from "../../../../../common/components/menu-wrapper";
import FilterComponent from "../component/filter";
import PaginationComponent from "./Pagination";
import COMMON from "../../../../../common/constants/COMMON";
import { CCUS05Handler } from "../handler/HandlerImpl";
import TableCCUS from "./Table";
import { useEffect } from "react";
import { PARAMS, STATE_PENDING } from "../../constants";
import HomeSvg from "../../../../../assets/icons/home.svg";

interface props {
  handler: CCUS05Handler;
}
const { Content, Sider } = Layout;

const BREADCRUMB_CCUS05: any[] = [
  {
    title: "建設キャリアアップシステム",
    url: "",
    icon: (
      <img style={{ paddingBottom: 2, paddingRight: 8 }} alt="" src={HomeSvg} />
    ),
  },
  {
    title: "就業履歴情報登録",
    url: "",
    icon: "",
  },
];

const MainUI = ({ handler }: props) => {
  const [searchParams] = useSearchParams();

  const isCollapsed = searchParams.get("isCollapsed") ? true : false;
  const page = searchParams.get(PARAMS.page) || COMMON.DEFAULT_PAGE + "";
  const size = searchParams.get(PARAMS.size) || COMMON.DEFAULT_SIZE + "";
  const projectId = searchParams.get(PARAMS.projectId) || "";
  const companyId = searchParams.get(PARAMS.companyId) || "";
  const approverId = searchParams.get(PARAMS.approverId) || "";
  const sortType = searchParams.get(PARAMS.sortType);
  const sortBy = searchParams.get(PARAMS.sortBy);

  useEffect(() => {
    const params: any = {
      page: +page,
      size: +size,
      state: STATE_PENDING,
    };
    if (projectId) params.projectId = +projectId;
    if (companyId) params.companyId = +companyId;
    if (approverId) params.approverId = +approverId;
    if (sortType) params.sortType = sortType;
    if (sortBy) params.sortBy = sortBy;

    handler.getTimesheetSyncData(params);
  }, [
    handler.isRefreshCCUS05,
    page,
    size,
    projectId,
    companyId,
    sortBy,
    sortType,
  ]);

  return (
    <>
      <MenuWrapperComponent chosenKey={92} openKey={9}>
        <div>
          <div className="om002-header-container">
            <BreadcrumbComponent data={BREADCRUMB_CCUS05} />

            <div className="header-row-title-ccus02">就業履歴情報登録</div>
          </div>

          <div className="container-tab-ccus05">
            <Layout
              style={{ minHeight: "85vh" }}
              className={isCollapsed ? "close-side" : "open-side"}
            >
              <Sider>
                <FilterComponent />
              </Sider>
              <Layout className="bg-fff">
                <Content className="content-table-ccus05">
                  <div className="right-content-ccus05">
                    <div className="btn-container">
                      <Button
                        className="button-purple btn-approve-ccus05 cm047-btn-submit cm024-btn-submit"
                        disabled={handler.selectedRowsKey.length == 0}
                        type="primary"
                        onClick={() => handler.onApproveCCUS()}
                      >
                        承認
                      </Button>
                    </div>
                    <div className="ccus-table-wrapper">
                      <TableCCUS handler={handler} />
                      <PaginationComponent
                        onPageChange={handler.onPageChange}
                        onSizeChange={handler.onSizeChange}
                        total={handler.totalRecord}
                      />
                    </div>
                  </div>
                </Content>
              </Layout>
            </Layout>
          </div>
        </div>
      </MenuWrapperComponent>
    </>
  );
};

export default MainUI;
