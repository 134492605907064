import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Form } from "antd";
import moment from "moment";
import COMMON from "../../../../../common/constants/COMMON";
import { FilterServiceImpl } from "../../usecase/ServiceImpl";
import { RangeDateType } from "../../../AM002/entity/Entity";
import { ConstructionData } from "../../../AM001/entity/Entity";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../../common/constants/MESSAGE";
import { setProjectListData, setContructionListData } from "../slice/Slice";

const FilterHandler = (service: FilterServiceImpl) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [total, setTotal] = useState(0);
  const [constructionList, setConstructionList] = useState<ConstructionData>({
    displayConstructions: [],
    rawConstructions: [],
  });
  const [rangeDate, setRangeDate] = useState<RangeDateType>({
    from: moment().startOf("month"),
    to: moment(),
  });
  const [searchDropDownValue, setSearchDropDownValue] = useState<string>("");

  const [searchParams, setSearchParams] = useSearchParams();
  const fromDate =
    searchParams.get("startDate") ||
    moment().startOf("month").format(COMMON.FORMAT_DATE2);
  const toDate =
    searchParams.get("endDate") || moment().format(COMMON.FORMAT_DATE2);

  // Infinity scroll
  const [infiData, setInifiData] = useState<any[]>([]);
  const [infiLoading, setInfiLoading] = useState<boolean>(false);
  const [infiPage, setInfiPage] = useState(1);
  const [infiTotal, setInfiTotal] = useState(0);
  const INFINITY_SCROLL_SIZE = 10;
  const onScroll = async (event: any) => {
    var target = event.target;
    if (
      !infiLoading &&
      target.scrollTop + target.offsetHeight + 1 >= target.scrollHeight
    ) {
      if (infiData.length === infiTotal) return;
      target.scrollTo(0, target.scrollHeight);
      await getProjectAlls();
    }
  };

  const onFromDateChange = (value: any) => {
    setRangeDate({ ...rangeDate, from: value });
  };
  const onToDateChange = (value: any) => {
    setRangeDate({ ...rangeDate, to: value });
  };

  var myVar1: any;
  const onSearchDropDown = () => {
    if (myVar1) clearTimeout(myVar1);
    myVar1 = setTimeout(async () => {
      const searchText = form.getFieldValue("project");
      // form.setFieldValue("searchDropDown", searchText.trim());
      searchParams.set("searchDropDown", searchText.trim());
      setSearchParams(searchParams);
    }, 1000);
  };

  var myVar: any;
  const onSearch = () => {
    if (myVar) clearTimeout(myVar);
    myVar = setTimeout(function () {
      const searchText = form.getFieldValue("text");
      form.setFieldValue("text", searchText.trim());
      searchParams.set("keywordConstruction", searchText.trim());
      searchParams.set("page", COMMON.DEFAULT_PAGE + "");
      setSearchParams(searchParams);
    }, 1000);
  };

  const getAttendanceProjectConstruction = async (params: {
    projectId: number;
    keyword: string;
    page: string;
    size: string;
  }) => {
    const foundProject = infiData.find(
      (project: any) => project.id + "" === params.projectId + ""
    );
    if (!foundProject) return;
    const { constructions } = foundProject;

    // keyword
    const filteredConstructions = constructions;

    // paging
    const pagingConstructions = filteredConstructions;

    setConstructionList({
      displayConstructions: pagingConstructions,
      rawConstructions: constructions,
    });
    dispatch(setContructionListData(pagingConstructions))
    if (pagingConstructions.length > 0) {
      form.setFieldValue("construction", pagingConstructions[0].name);
      searchParams.set("selectedConstructionId", pagingConstructions[0].id);
      setSearchParams(searchParams);
    }
  };

  const getProjectAlls = async () => {
    setInfiLoading(true);
    const { results, pagination } = await service.getProjectAlls({
      page: infiPage + "",
      size: INFINITY_SCROLL_SIZE + "",
    });
    setInifiData([...infiData, ...results]);
    dispatch(setProjectListData([...infiData, ...results]))
    setInfiTotal(pagination.count);
    setInfiPage(infiPage + 1);
    setInfiLoading(false);
    if (infiPage === 1) {
      form.setFieldValue("project", results[0]?.projectName);
      searchParams.set("selectedProjectId", results[0]?.id + "");
      setSearchParams(searchParams);
    }
  };

  const searchDropDown = async (keyword: string) => {
    setInfiLoading(true);
    const { results, pagination } = await service.getProjectAlls({
      keyword: keyword,
      page: COMMON.DEFAULT_PAGE + "",
      size: COMMON.DEFAULT_SIZE + "",
    });
    setInfiPage(2);
    setInifiData(results);
    dispatch(setProjectListData(results))
    setInfiTotal(pagination.count);
    setInfiLoading(false);
  };

  const initiateProjectConstruction = async (
    searchDropDown: string,
    keyword: string,
    projectId?: string | number,
    constructionId?: string | number
  ) => {
    try {
      dispatch(setLoading(true));
      setSearchDropDownValue(searchDropDown);
      setInfiLoading(true);
      if (!projectId) {
        const response = await service.getProjectAlls({
          keyword: searchDropDown,
          page: infiPage + "",
          size: COMMON.DEFAULT_SIZE + "",
        });
        const results = response?.results ?? [];
        const pagination = response.pagination;
        setInifiData([...results]);
        dispatch(setProjectListData([...results]))
        setInfiTotal(pagination?.count ?? 0);
        setInfiPage(infiPage + 1);
        if (results?.length > 0) {
          form.setFieldValue("project", results[0]?.projectName);
          searchParams.set("selectedProjectId", results[0]?.id + "");
          onProjectChoose(results[0]);
          setSearchParams(searchParams);
        }
      } else {
        const { results, pagination } = await service.getProjectAlls({
          page: COMMON.DEFAULT_PAGE + "",
          size: COMMON.DEFAULT_SIZE + "",
        });
        setInfiTotal(pagination?.count ?? 0);

        // SMALL FUNCTION
        const checkFoundDefaultParams = await smallFunction(
          results,
          keyword,
          projectId,
          constructionId
        );

        // Trường hợp nếu tìm thấy project ở page 1
        if (checkFoundDefaultParams) {
          setInfiPage(2);
          setInifiData([...results]);
          dispatch(setProjectListData([...results]))
          return;
        }

        // project ở các trang sau
        const totalPages = Math.floor(
          pagination.count / COMMON.DEFAULT_SIZE +
            (pagination.count % COMMON.DEFAULT_SIZE > 0 ? 1 : 0)
        );
        let page = 0;
        let projects: any[] = [...results];
        for (let i = 2; i <= totalPages; i++) {
          page = i;
          const { results } = await service.getProjectAlls({
            page: page + "",
            size: COMMON.DEFAULT_SIZE + "",
          });
          projects = projects.concat(results);
          const checkFoundDefaultParams = await smallFunction(
            results,
            keyword,
            projectId,
            constructionId
          );
          if (checkFoundDefaultParams) break;
        }
        setInfiPage(page + 1);
        setInifiData(projects);
        dispatch(setProjectListData(projects))
      }
    } catch (error: any) {
      ErrorNotification((error.message ?? NOTIFICATION_TITLE.ERROR) + "1111");
    } finally {
      dispatch(setLoading(false));
      setInfiLoading(false);
    }
  };

  const smallFunction = async (
    results: any,
    keyword: string,
    projectId: any,
    constructionId: any
  ): Promise<boolean> => {
    const foundProject = results.find(
      (project: any) => project.id + "" === projectId + ""
    );
    if (foundProject) {
      form.setFieldValue("project", foundProject.projectName);
      const constructions = await service.getConstructionListByProject({
        projectId: foundProject.id,
      });
      // keyword
      const filteredConstructions = constructions.map((element) => {
        return { ...element, name: element.description };
      });

      // find the shit
      const foundConstruction = filteredConstructions.find(
        (element: any) => element.id + "" === constructionId + ""
      );

      if (foundConstruction) {
        // keyword
        const pagingConstructions = filteredConstructions;

        setConstructionList({
          displayConstructions: pagingConstructions,
          rawConstructions: constructions,
        });
        dispatch(setContructionListData(pagingConstructions))
        form.setFieldValue("construction", foundConstruction.name);
      } else if (filteredConstructions.length > 0) {
        // paging

        setConstructionList({
          displayConstructions: filteredConstructions,
          rawConstructions: constructions,
        });
        dispatch(setContructionListData(filteredConstructions))
        form.setFieldValue("construction", filteredConstructions[0].name);
        searchParams.set("selectedConstructionId", constructions[0].id + "");
        setSearchParams(searchParams);
      }
      return true;
    }
    return false;
  };
  const initiateFilter = () => {
    let fromMoment: moment.Moment = moment().startOf("month");
    if (fromDate === null)
      form.setFieldValue("startDate", moment().startOf("month"));
    else {
      form.setFieldValue("startDate", moment(fromDate));
      fromMoment = moment(fromDate);
    }

    form.setFieldValue("endDate", moment(toDate));
    setRangeDate({
      to: moment(toDate),
      from: fromMoment,
    });
  };

  const onProjectChoose = async (value: any) => {
    if (!value) return;

    form.setFieldValue("project", value.projectName);

    const constructionData = await service.getConstructionListByProject({
      projectId: value.id,
    });

    const constructions = constructionData.map((element) => {
      return { ...element, name: element.description };
    });

    setConstructionList({
      displayConstructions: constructions,
      rawConstructions: constructions,
    });
    dispatch(setContructionListData(constructions))
    if (constructions.length > 0) {
      form.setFieldValue("construction", constructions[0].name);
      searchParams.set("selectedConstructionId", constructions[0].id + "");
      setSearchParams(searchParams);
    }
    searchParams.set("selectedProjectId", value.id);
    setSearchParams(searchParams);
  };

  const onConstructionChoose = (value: any) => {
    form.setFieldValue("construction", value.name);
    searchParams.set("selectedConstructionId", value.id + "");
    setSearchParams(searchParams);
  };

  const onPageChange = (value: any) => {
    searchParams.set("pageConstruction", value + "");
    setSearchParams(searchParams);
  };

  const onFilter = () => {
    let from = "";
    let to = "";
    let pageDate = COMMON.DEFAULT_PAGE;
    const startDate = form.getFieldValue("startDate");
    if (startDate) from = moment(startDate).format(COMMON.FORMAT_DATE2);
    const endDate = form.getFieldValue("endDate");
    to = moment(endDate).format(COMMON.FORMAT_DATE2);

    searchParams.set("startDate", from);
    searchParams.set("endDate", to);
    searchParams.set("pageDate", pageDate + "");
    setSearchParams(searchParams);
  };

  const onResetFilter = () => {
    const initialStart = moment().startOf("month");
    const initialEnd = moment();

    form.setFields([
      {
        name: "startDate",
        value: initialStart,
      },
      {
        name: "endDate",
        value: initialEnd,
      },
    ]);
    searchParams.set("pageDate", COMMON.DEFAULT_PAGE + "");
    searchParams.set("startDate", initialStart.format(COMMON.FORMAT_DATE2));
    searchParams.set("endDate", initialEnd.format(COMMON.FORMAT_DATE2));
    setSearchParams(searchParams);
  };

  const onCollapse = (value: boolean) => {
    searchParams.set("isCollapsed", value ? "isCollapsed" : "");
    setSearchParams(searchParams);
  };

  return {
    onConstructionChoose,
    onProjectChoose,
    onPageChange,
    onFilter,
    onResetFilter,
    onCollapse,
    initiateFilter,
    getAttendanceProjectConstruction,
    getProjectAlls,
    onScroll,
    onFromDateChange,
    onToDateChange,
    onSearch,
    initiateProjectConstruction,
    onSearchDropDown,
    searchDropDown,
    setSearchDropDownValue,
    form,
    total,
    infiTotal,
    infiData,
    infiLoading,
    infiPage,
    rangeDate,
    constructionList,
    searchDropDownValue,
  };
};

export default FilterHandler;
