import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import PaginationComponentList from "../../../../../common/components/pagination/PaginationComponentList";
import { RootState } from "../../../../../store";
import { PM003PaginationHandler } from "../handler/HandlerImpl";
interface props {
  handler: PM003PaginationHandler;
}

const PM003Pagination = ({ handler }: props) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const page = params.get("page");
  const size = params.get("size");
  const total = useSelector((state: RootState) => state.pm003.total);
  const data = useSelector((state: RootState) => state.pm003.data);

  return (
    <PaginationComponentList
    disabled={data.length !==0 ? false: true}
      total={total}
      page={parseInt(page||"1")}
      size={parseInt(size|| "10")}
      setPage={handler.onPageChange}
      setSize={handler.onSizeChange}
    />
  );
};
export default PM003Pagination;
