import axiosConfig from "../../../../../config/axiosConfig";

const ChangeForgotPasswordApi = () => {
  const changePassword = async (data: any): Promise<any> => {
    const url = "/auth/forgot-password";
    try {
      const forgotToken = localStorage.getItem("forgotToken");
      const response = await axiosConfig.post(url, data, {
        headers: {
          Authorization: "Bearer " + forgotToken,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const sendOTP = async (): Promise<any> => {
    const url = "/auth/forgot-password/otp";
    try {
      const forgotToken = localStorage.getItem("forgotToken");
      const response = await axiosConfig.post(url, null, {
        headers: {
          Authorization: "Bearer " + forgotToken,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  return {
    changePassword,
    sendOTP,
  };
};

export default ChangeForgotPasswordApi;
