import axiosConfig from '../../../../../config/axiosConfig';

const AOP004Api = () => {
    const getRoleListMember = async (params: any): Promise<any> => {
        try {
            const url = '/role/user/list';
            const response = await axiosConfig.get(url, { params });
            return response.data;
        } catch (error: any) {
            throw error;
        }
    };

    const getRoleDetail = async (params: any): Promise<any> => {
        try {
            const url = '/role/detail';
            const response = await axiosConfig.get(url, { params });
            return response.data;
        } catch (error: any) {
            throw error;
        }
    };

    const getRoleCategory = async (params: any): Promise<any> => {
        try {
            const url = '/role/permision/category';
            const response = await axiosConfig.get(url, { params });
            return response.data;
        } catch (error: any) {
            throw error;
        }
    };

    return {
        getRoleListMember,
        getRoleDetail,
        getRoleCategory,
    };
};

export default AOP004Api;
