import { createSlice } from "@reduxjs/toolkit";

export type AuthSliceEntity = {
  authData?: {
    company: {
      createdAt: string;
      description: string;
      id: number;
      name: string;
      updatedAt: string;
    };
    shouldRefreshAt: string;
    avatar: string;
    email: string;
    fullName: string;
    id: string;
    isDoubleCheck: boolean;
    isFirstLogin: boolean;
    isReWorkflow: boolean;
    isUserInWorkspace: boolean;
    isUserIsProjectManager: boolean;
    isWorkFlowUserIsInWorkFlows: boolean;
    isVerifiedCompany: boolean;

    permission: string[];
    refreshToken: string;
    token: string;
    username: string;
    role: {
      id: number;
      code: string;
      name: string;
    };
    order: {
      id: number;
      code: string;
      description: string;
      subscription?: any[] | null;
    };
    reCostWorkflow: boolean;
    reReceiveMoneyWorkflow: boolean;
    reSummaryCompanyOutsourceWorkflow: boolean;
    reSummaryInternalWorkflow: boolean;
    isRoleAdminBasic: boolean;
    isRoleAdmin: boolean;
    isRoleAdminSystem: boolean;
  };
  isRefreshDoubleCheck: boolean;
  isRefreshReWorkflow: boolean;
  isRefreshUserInWorkspace: boolean;
  isRefreshUserIsProjectManager: boolean;
  isRefreshWorkFlowUserIsInWorkFlows: boolean;
  isRefreshIsVerifiedCompany: boolean;

};

const initialState: AuthSliceEntity = {
  authData: undefined,

  isRefreshDoubleCheck: false,
  isRefreshReWorkflow: false,
  isRefreshUserInWorkspace: false,
  isRefreshUserIsProjectManager: false,
  isRefreshWorkFlowUserIsInWorkFlows: false,
  isRefreshIsVerifiedCompany: false
};

export const authSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    resetAuth: () => initialState,
    setAuth: (state, action) => {
      state.authData = action.payload;
    },
    refreshDoubleCheck: (state) => {
      state.isRefreshDoubleCheck = !state.isRefreshDoubleCheck;
    },
    refreshReWorkflow: (state) => {
      state.isRefreshReWorkflow = !state.isRefreshReWorkflow;
    },
    refreshUserInWorkspace: (state) => {
      state.isRefreshUserInWorkspace = !state.isRefreshUserInWorkspace;
    },
    refreshUserIsProjectManager: (state) => {
      state.isRefreshUserIsProjectManager = !state.isRefreshUserInWorkspace;
    },
    refreshIsRefreshWorkFlowUserIsInWorkFlows: (state) => {
      state.isRefreshWorkFlowUserIsInWorkFlows =
        !state.isRefreshWorkFlowUserIsInWorkFlows;
    },
    refreshIsVerifiedCompany: (state) => {
      state.isRefreshIsVerifiedCompany = !state.isRefreshIsVerifiedCompany;
    },
    setIsDoubleCheck: (state, action) => {
      if (state.authData)
        state.authData = { ...state.authData, isDoubleCheck: action.payload };
    },
    setIsReWorkflow: (state, action) => {
      if (state.authData)
        state.authData = {
          ...state.authData,
          isReWorkflow: action.payload.isReWorkflow,
          reCostWorkflow: action.payload.reCostWorkflow,
          reReceiveMoneyWorkflow: action.payload.reReceiveMoneyWorkflow,
          reSummaryCompanyOutsourceWorkflow:
            action.payload.reSummaryCompanyOutsourceWorkflow,
          reSummaryInternalWorkflow: action.payload.reSummaryInternalWorkflow,
        };
    },
    setIsUserInWorkspace: (state, action) => {
      if (state.authData)
        state.authData = {
          ...state.authData,
          isUserInWorkspace: action.payload,
        };
    },
    setIsUserIsProjectManager: (state, action) => {
      if (state.authData)
        state.authData = {
          ...state.authData,
          isUserIsProjectManager: action.payload,
        };
    },
    setIsWorkFlowUserIsInWorkFlows: (state, action) => {
      if (state.authData)
        state.authData = {
          ...state.authData,
          isWorkFlowUserIsInWorkFlows: action.payload,
        };
    },
    setIsVerifiedCompany: (state, action) => {
      if (state.authData)
        state.authData = {
          ...state.authData,
          isVerifiedCompany: action.payload,
        };
    },
  },
});

export const {
  resetAuth,
  setAuth,
  refreshDoubleCheck,
  refreshReWorkflow,
  refreshUserInWorkspace,
  refreshIsVerifiedCompany,
  setIsDoubleCheck,
  setIsReWorkflow,
  setIsUserInWorkspace,
  refreshUserIsProjectManager,
  setIsUserIsProjectManager,
  refreshIsRefreshWorkFlowUserIsInWorkFlows,
  setIsWorkFlowUserIsInWorkFlows,
  setIsVerifiedCompany
} = authSlice.actions;

export const authReducer = authSlice.reducer;
