import React, { useEffect, useMemo } from 'react';
import { HandlerImpl } from '../handler/HandlerImpl';
import { Button } from 'antd';
import IconCircleCheck from '../../../../../../assets/icons/akar-icons_circle-check-fill.svg';

import ModalReject from '../../../../../../common/components/modal-reject/ModalReject';
import ModalRejectView from '../../../../../../common/components/modal-reject/ModalRejectView';
import TableItem from './TableItem';
import {
    MATERIAL_COST,
    OUTSOURCING_COST,
    LABOR_COST,
    INDIRECT_COST,
    RECEIVE_MONEY,
} from '../handler/Handler';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import CM0025Component from '../../../../CM0025';
import CM0027Component from '../../../../CM0027';
import CM0029Component from '../../../../CM0029';
import CM0031Component from '../../../../CM0031';
import CM0033Component from '../../../../CM0033';
import CM0021Component from '../../../../CM0021';
import CM0023Component from '../../../../CM0023';
import { AUTHORIZATION_CODE } from '../../../../../../common/constants/AUTHORIZATION';

interface props {
    handler: HandlerImpl;
}

const MainUI = ({ handler }: props) => {
    const visibleCM0021 = useSelector((state: RootState) => state.cm0021.isVisibleCM0021);
    const visibleCM0023 = useSelector((state: RootState) => state.cm0023.isVisibleCM0023);
    const visibleCM0025 = useSelector((state: RootState) => state.cm0025.isVisibleCM0025);
    const visibleCM0027 = useSelector((state: RootState) => state.cm0027.isVisibleCM0027);
    const visibleCM0029 = useSelector((state: RootState) => state.cm0029.isVisibleCM0029);
    const visibleCM0031 = useSelector((state: RootState) => state.cm0031.isVisibleCM0031);
    const visibleCM0033 = useSelector((state: RootState) => state.cm0033.isVisibleCM0033);
    const permission = useSelector((state: RootState) => state.auth.authData?.permission);
    useEffect(() => {
        handler.getMaterialReviewList();
        handler.getOutsourceReviewList();
        handler.getLaborReviewList();
        handler.getIndirectReviewList();
        handler.getReceiveMoneyReviewList();
    }, [handler.refresh]);

    const dataMaterial = useMemo(() => {
        return handler.handleDataView(
            handler.data.material,
            handler.sortType81,
            handler.status,
            handler.from,
            handler.to,
            handler.keyword,
            handler.clear,
        );
    }, [
        handler.data.material,
        handler.from,
        handler.to,
        handler.sortType81,
        handler.clear,
        handler.status,
        handler.keyword,
    ]);

    const dataOutsource = useMemo(() => {
        return handler.handleDataView(
            handler.data.outsource,
            handler.sortType82,
            handler.status,
            handler.from,
            handler.to,
            handler.keyword,
            handler.clear,
        );
    }, [
        handler.data.outsource,
        handler.from,
        handler.to,
        handler.sortType82,
        handler.clear,
        handler.status,
        handler.keyword,
    ]);

    const dataLabor = useMemo(() => {
        return handler.handleDataView(
            handler.data.labor,
            handler.sortType83,
            handler.status,
            handler.from,
            handler.to,
            handler.keyword,
            handler.clear,
        );
    }, [
        handler.data.labor,
        handler.from,
        handler.to,
        handler.sortType83,
        handler.clear,
        handler.status,
        handler.keyword,
    ]);

    const dataIndirect = useMemo(() => {
        return handler.handleDataView(
            handler.data.indirect,
            handler.sortType84,
            handler.status,
            handler.from,
            handler.to,
            handler.keyword,
            handler.clear,
        );
    }, [
        handler.data.indirect,
        handler.from,
        handler.to,
        handler.sortType84,
        handler.clear,
        handler.status,
        handler.keyword,
    ]);

    const dataReceive = useMemo(() => {
        return handler.handleDataView(
            handler.data.receive,
            handler.sortType85,
            handler.status,
            handler.from,
            handler.to,
            handler.keyword,
            handler.clear,
        );
    }, [
        handler.data.receive,
        handler.from,
        handler.to,
        handler.sortType85,
        handler.clear,
        handler.status,
        handler.keyword,
    ]);

    return (
        <div className="cm047 cm048">
            {visibleCM0021 ? (
                <CM0021Component
                    onReject={handler.handleRejectData}
                    onSubmit={() => handler.handleSubmitDataDetail()}
                />
            ) : null}
            {visibleCM0023 ? (
                <CM0023Component
                    onReject={handler.handleRejectData}
                    onSubmit={() => handler.handleSubmitDataDetail()}
                />
            ) : null}
            {visibleCM0025 ? (
                <CM0025Component
                    onReject={handler.handleRejectData}
                    onSubmit={() => handler.handleSubmitDataDetail()}
                />
            ) : null}
            {visibleCM0027 ? (
                <CM0027Component
                    onReject={handler.handleRejectData}
                    onSubmit={() => handler.handleSubmitDataDetail()}
                />
            ) : null}
            {visibleCM0029 ? (
                <CM0029Component
                    onReject={handler.handleRejectData}
                    onSubmit={() => handler.handleSubmitDataDetail()}
                />
            ) : null}
            {visibleCM0031 ? (
                <CM0031Component
                    onReject={handler.handleRejectData}
                    onSubmit={() => handler.handleSubmitDataDetail()}
                />
            ) : null}
            {visibleCM0033 ? (
                <CM0033Component
                    onReject={handler.handleRejectData}
                    onSubmit={() => handler.handleSubmitDataDetail()}
                />
            ) : null}
            <ModalReject
                visible={handler.visibleReject}
                form={handler.formReject}
                funcCancel={handler.handleCancelReject}
                funcSubmit={handler.handleSubmitReject}
                funcChangeValue={handler.handleChangeValueReject}
            />
            <ModalRejectView
                visible={handler.visibleRejectView}
                funcClose={handler.handleCloseViewRejectReason}
                rejectReason={handler.rejectReason}
            />
            <TableItem
                dataSource={dataMaterial}
                selectedRowKeys={handler.selectedRow.materialCost}
                type={MATERIAL_COST}
                sortOrder={
                    !handler.sortType81
                        ? undefined
                        : handler.sortType81 === 'ascend'
                        ? 'ascend'
                        : 'descend'
                }
                title="材料費"
                onChangeSelectKey={handler.handleChangeSelectedRow}
                handleSortTable={handler.handleSortTable}
                handleViewRejectReason={handler.handleViewRejectReason}
                onClickDocument={handler.funcClickDocumentMaterialCost}
            />
            <TableItem
                dataSource={dataOutsource}
                selectedRowKeys={handler.selectedRow.outsourcingCost}
                type={OUTSOURCING_COST}
                sortOrder={
                    !handler.sortType82
                        ? undefined
                        : handler.sortType82 === 'ascend'
                        ? 'ascend'
                        : 'descend'
                }
                title="外注費"
                onChangeSelectKey={handler.handleChangeSelectedRow}
                handleSortTable={handler.handleSortTable}
                handleViewRejectReason={handler.handleViewRejectReason}
                onClickDocument={handler.funcClickDocumentOutsourcingCost}
            />
            <TableItem
                dataSource={dataLabor}
                selectedRowKeys={handler.selectedRow.laborCost}
                type={LABOR_COST}
                sortOrder={
                    !handler.sortType83
                        ? undefined
                        : handler.sortType83 === 'ascend'
                        ? 'ascend'
                        : 'descend'
                }
                title="労務費"
                onChangeSelectKey={handler.handleChangeSelectedRow}
                handleSortTable={handler.handleSortTable}
                handleViewRejectReason={handler.handleViewRejectReason}
                onClickDocument={handler.funcClickDocumentLaborCost}
            />
            <TableItem
                dataSource={dataIndirect}
                selectedRowKeys={handler.selectedRow.indirectCost}
                type={INDIRECT_COST}
                sortOrder={
                    !handler.sortType84
                        ? undefined
                        : handler.sortType84 === 'ascend'
                        ? 'ascend'
                        : 'descend'
                }
                title="間接工事費"
                onChangeSelectKey={handler.handleChangeSelectedRow}
                handleSortTable={handler.handleSortTable}
                handleViewRejectReason={handler.handleViewRejectReason}
                onClickDocument={handler.funcClickDocumentIndirect}
            />
            <TableItem
                dataSource={dataReceive}
                selectedRowKeys={handler.selectedRow.receive}
                type={RECEIVE_MONEY}
                sortOrder={
                    !handler.sortType85
                        ? undefined
                        : handler.sortType85 === 'ascend'
                        ? 'ascend'
                        : 'descend'
                }
                title="入金額"
                onChangeSelectKey={handler.handleChangeSelectedRow}
                handleSortTable={handler.handleSortTable}
                handleViewRejectReason={handler.handleViewRejectReason}
                onClickDocument={handler.funcClickDocumentBillingAmount}
            />

            {/* <div className="cm047-btn-wrapper">
                <div className="cm047-button">
                    {permission?.includes(AUTHORIZATION_CODE.S38) ? (
                        <>
                            <Button
                                className="button-purple cm047-btn-submit"
                                icon={<img alt="" src={IconCircleCheck} />}
                                size="large"
                                onClick={handler.handleSubmitData}
                                disabled={handler.funcCheckDisableSubmitReject()}
                            >
                                承認
                            </Button>
                            <Button
                                size="large"
                                className="button-warning cm047-btn-reject"
                                onClick={handler.handleRejectData}
                                disabled={handler.funcCheckDisableSubmitReject()}
                            >
                                承認
                            </Button>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            </div> */}
        </div>
    );
};

export default MainUI;
