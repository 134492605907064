import { createSlice } from "@reduxjs/toolkit";
import { CM0019SliceType } from "../../entity/Entity";

export const initialState: CM0019SliceType = {
  isRefresh: false,
};

export const AM0019Slice = createSlice({
  name: "am0019",
  initialState,
  reducers: {
    refreshAM0019: (state) => {
      state.isRefresh = !state.isRefresh;
    },
  },
});

export const { refreshAM0019 } = AM0019Slice.actions;

export default AM0019Slice.reducer;
