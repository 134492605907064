import PaginationComponentList from "../../../../../common/components/pagination/PaginationComponentList";
import { PM025PaginationHandler } from "../handler/HandlerImpl";

interface props {
  handler: PM025PaginationHandler;
}
function PM025Pagination({ handler }: props) {
  return (
    <div className="pm025-pagination">
      <PaginationComponentList
        total={handler.total}
        page={handler.page}
        size={handler.size}
        setPage={handler.handleChangePage}
        setSize={handler.handleChangeSize}
      />
    </div>
  );
}

export default PM025Pagination;
