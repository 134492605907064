import { createSlice } from '@reduxjs/toolkit';
import { SN01SliceType } from '../../entity/Entity';

export const initialState: SN01SliceType = {
    loading: false,
};

export const SN01lice = createSlice({
    name: 'sn01',
    initialState,
    reducers: {
        setLoadingSN01: (state, action) => {
            state.loading = action.payload;
        },
    },
});

export const { setLoadingSN01 } = SN01lice.actions;

export default SN01lice.reducer;
