import { P01SliceType } from "./../type/Presenter";
import { createSlice } from "@reduxjs/toolkit";

const initialState: P01SliceType = {
  isRefresh: false,
};

export const p01Slice = createSlice({
  name: "p01",
  initialState,
  reducers: {
    resetP01: () => initialState,

    setRefreshP01: (state) => {
      state.isRefresh = !state.isRefresh;
    },
  },
});

export const { resetP01, setRefreshP01 } = p01Slice.actions;

export default p01Slice.reducer;
