import { FormInstance } from 'antd';
import { useState } from 'react';
import COMMON from '../../../../../common/constants/COMMON';
const Handler = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentValue, setCurrentValue] = useState<{
        name: string;
        value: string | number;
    } | null>(null);

    const handleFocus = (e: any) => {
        setIsOpen(true);
    };
    const handleBlur = (name: string, form: FormInstance) => {
        setIsOpen(false);
    };
    const handleClickItem = (
        item: { name: string; value: string | number },
        name: string,
        form: FormInstance,
    ) => {
        form.setFieldValue(name, '');
        form.setFieldValue(`${name}${COMMON.INPUT_SEARCH}`, '');
        setIsOpen(false);
        setCurrentValue(item);
    };

    return {
        isOpen,

        handleFocus,
        handleBlur,
        handleClickItem,
    };
};

export default Handler;
