
import { CCUS061APIImpl } from './ServiceImpl';

const CCUS002Service = (api: CCUS061APIImpl) => {

    const getTechnicianListCCUS = async (params: {
        groupId?: number;
        page: number;
        size: number;
        keyword?: string;
    }): Promise<any> => {
        try {
            const response = await api.getTechnicianListCCUS(params);
            return response?.data?.results ?? [];
        } catch (error: any) {
            throw error;
        }
    };

    const getDepartmentListCCUS = async (params: {
        userId?: number;
        page: number;
        size: number;
    }): Promise<any> => {
        try {
            const response = await api.getDepartmentListCCUS(params);
            return response?.data?.results ?? [];
        } catch (error: any) {
            throw error;
        }
    };

    const getListEmployeeVerification = async (params: {
        groupId?: number;
        userId?: number;
        keyword?: string;
        sortBy?: string;
        sortType?: string;
        page?: number;
        size?: number;
    }): Promise<any> => {
        try {
            const response = await api.getListEmployeeVerification(params);
            return response?.data;
        } catch (error: any) {
            throw error;
        }
    };

    const getListCompanyEmployee = async (params: {
        lastReferred?: boolean
    }): Promise<any> => {
        try {
            const response = await api.getListCompanyEmployee(params);
            return response?.data;
        } catch (error: any) {
            throw error;
        }
    };

    const postEmployeeCompanyVerify = async (
        payload: any
    ): Promise<any> => {
        try {
            const response = await api.postEmployeeCompanyVerify(payload);
            return response?.data;
        } catch (error: any) {
            throw error;
        }
    };

    return {
        getTechnicianListCCUS,
        getDepartmentListCCUS,
        getListEmployeeVerification,
        postEmployeeCompanyVerify,
        getListCompanyEmployee
    };
};

export default CCUS002Service;
