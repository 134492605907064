import { uuidv4 } from '@firebase/util';
import { Form } from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ErrorNotification from '../../../../../common/components/notification/ErrorNotification';
import { NOTIFICATION_TITLE } from '../../../../../common/constants/MESSAGE';
import { RootState } from '../../../../../store';
import { ReSummarySubmittedProjectOutsourceOffsetCost } from '../../entity/Entity';
import { CM0027_1UseCase } from '../../usecase/ServiceImpl';
import { setIsVisibleCM0027_1, setLoadingCM0027_1 } from '../slice/Slice';

const CM0027_1Handler = (CM0027_1Service: CM0027_1UseCase) => {
    // LIB FUNCTION
    const dispatch = useDispatch();

    //STATE
    const [form] = Form.useForm();
    const typeModal = useSelector((state: RootState) => state.cm0027_1.typeModal);
    const [
        listReSummarySubmittedProjectOutsourceOffsetCostLocal,
        setListReSummarySubmittedProjectOutsourceOffsetCostLocal,
    ] = useState<ReSummarySubmittedProjectOutsourceOffsetCost[]>([]);
    const [totalCost, setTotalCost] = useState<number>(0);

    //function
    const getReSummarySubmittedProjectOutsourceOffsetCost = async (
        documentProjectOutsourceCostConstructionSummaryDataId: number,
        categoryId: number,
    ): Promise<any> => {
        try {
            dispatch(setLoadingCM0027_1(true));
            const params = {
                documentProjectOutsourceCostConstructionSummaryDataId,
                categoryId,
            };
            const res = await CM0027_1Service.getReSummarySubmittedProjectOutsourceOffsetCost(
                params,
            );
            if (res?.results) {
                const data = formatReSummarySubmittedProjectOutsourceOffsetCost(res.results);
                setListReSummarySubmittedProjectOutsourceOffsetCostLocal(data);
                handleCalculateChange(data);
            }
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        } finally {
            dispatch(setLoadingCM0027_1(false));
        }
    };

    const formatReSummarySubmittedProjectOutsourceOffsetCost = (data: any) => {
        const arr: ReSummarySubmittedProjectOutsourceOffsetCost[] = data.map(
            (item: any, index: number) => {
                return {
                    key: uuidv4(),
                    id: item?.id,
                    item: item?.name,
                    taxCategoryComsumptionId: item?.category?.id,
                    taxCategoryComsumptionName: item?.category?.description,
                    offsetAmount: item?.value,
                };
            },
        );
        return arr;
    };

    const handleCalculateChange = (data: ReSummarySubmittedProjectOutsourceOffsetCost[]) => {
        let total: number = 0;
        data.forEach((item) => {
            const cost = item?.offsetAmount
                ? item.offsetAmount
                : form.getFieldValue(`offsetAmount${item.key}`) ?? 0;
            if (!isNaN(cost)) total += cost;
        });
        setTotalCost(total);
    };

    const onCancel = () => {
        dispatch(setIsVisibleCM0027_1(false));
    };

    return {
        form,
        typeModal,
        listReSummarySubmittedProjectOutsourceOffsetCostLocal,
        totalCost,
        onCancel,
        handleCalculateChange,
        getReSummarySubmittedProjectOutsourceOffsetCost,
    };
};

export default CM0027_1Handler;
