// Built-in
import React, { useState } from "react";

// 3rd party lib
import { useDispatch, useSelector } from "react-redux";
import { MaterialCostServiceImpl } from "../../usecase/ServiceImpl";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import {
  DocumentDirectOutsourceData,
  MoneyDirectCostOutsourceInformationListResult,
  MoneyDirectCostOutsourceInformationListState,
  MoneyDirectCostOutsourceListResult,
  MoneyDirectCostOutsourceOffsetListResults,
} from "../../entity/Entity";
import { ParentStatus } from "../../../CM007/entity/Entity";
import {
  refreshCM009,
  setCheckedCollapseDirect,
  setCheckedCollapseParent,
  setParentStatus,
} from "../slice/Slice";
import moment from "moment";
import COMMON, {
  STATUS_CM_CHILD_TABLE,
  STATUS_CM_PARENT_TABLE,
} from "../../../../../common/constants/COMMON";
import { RootState } from "../../../../../store";
import { setIsEnable } from "../../../CM004/presenter/slice/Slice";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import exportFileDataCM009 from "../../../../../common/helpers/export-excel/CM009";
import ConfirmModalAsync from "../../../../../common/components/modal/ConfirmModalAsync";
import { useSearchParams } from "react-router-dom";
import { doExportForResponse } from "../../../../../common/helpers/exports/common";

const MaterialCostHandler = (service: MaterialCostServiceImpl) => {
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const projectName = searchParams.get("projectName");

  const [data, setData] = useState<
    MoneyDirectCostOutsourceInformationListState[]
  >([]);
  const [checkedList, setCheckedList] = useState<any[]>([]);
  const [checkAll, setCheckAll] = useState(false);
  const [expandKey, setExpandKey] = useState<any[]>([]);
  const parentStatus = useSelector(
    (state: RootState) => state.cm009.parentStatus
  );
  const constructionInfo = useSelector(
    (state: RootState) => state.cm004.constructionInfo
  );
  const checkedCollapse = useSelector(
    (state: RootState) => state.cm009.checkedCollapse
  );

  const screenName = COMMON.CM_FILE_STRUCTURE.SCREEN_NAME;
  const tab1 = COMMON.CM_FILE_STRUCTURE.PARENT_TAB.TAB_3.NAME;
  const tab2 = COMMON.CM_FILE_STRUCTURE.PARENT_TAB.TAB_3.CHILDREN_TAB[1];
  const submitDate = moment().format(COMMON.FORMAT_DATE_SUBMIT);
  const fileName: string = `${screenName}_${tab1}_${tab2}_${submitDate}`;

  const checkIsEnableSubmitButton = (checkedList: any[]) => {
    const isEnable = checkedList?.length > 0 ? true : false;
    dispatch(setIsEnable(isEnable));
  };

  const putMoneySubmitDirectCostMaterial = async (): Promise<any> => {
    ConfirmModalAsync({
      onOk: async () => {
        try {
          dispatch(setLoading(true));

          const listDocumentDirectOutsourceData: DocumentDirectOutsourceData[] =
            checkedCollapse.map((element) => {
              const outsourceCost = data.find((ele) => ele.id === element.id);

              //  [Tên dự án_Tên công trình_Tên công trình hợp tác_Tên màn_Tên tab1 _Tên tab 2_Tên công ty_năm_tháng_ngày submit]
              const constructionName: string =
                constructionInfo?.constructionName ?? ""; // Tên công trình
              const companyName: string = outsourceCost?.company?.name ?? ""; // Tên công ty
              const partnerCompanyName: string =
                outsourceCost?.constructionName ?? ""; // Tên công trình hợp tác

              const fileName: string = `${projectName}_${constructionName}_${partnerCompanyName}_${screenName}_${tab1}_${tab2}_${companyName}_${submitDate}`;
              const payloadData: DocumentDirectOutsourceData = {
                fileName: fileName,
                documentDirectOutsourceCostId: element.id,
                listDocumentDirectOutsourceCostInformationId: element.checked,
              };
              return payloadData;
            });
          const response = await service.putMoneySubmitDirectCostOutsource({
            listDocumentDirectOutsourceData,
          });
          setCheckedList([]);
          setCheckAll(false);
          dispatch(setCheckedCollapseDirect([]));
          dispatch(refreshCM009());
          SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
        } catch (error: any) {
          ErrorNotification(NOTIFICATION_TITLE.ERROR);
        } finally {
          dispatch(setLoading(false));
        }
      },
      onCancel: () => {},
      className: "confirm__modal confirm__modal-purple-oke",
      title: MESSAGE.MESSAGE_TITLE_BEFORE_CREATE,
      description: MESSAGE.MESSAGE_DESCRIPTION_CR_10_1,
      description2: MESSAGE.MESSAGE_DESCRIPTION_COMMON_QUESTION_2,

      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_MODAL_2,
    });
  };

  const putMoneyDirectCostMaterialEnable = async (
    payload: any
  ): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const response = await service.putMoneyDirectCostOutsourceEnable({
        id: payload.id,
        enable: payload.enable,
        threshold: payload.threshold,
      });
      SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
    } catch (error: any) {
      ErrorNotification(NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };
  const setDataAndParentStatus = async (params: {
    constructionId: number;
    page?: number;
    size?: number;
    sortBy?: string;
    sortType?: string;
    from?: string;
    to?: string;
  }) => {
    try {
      dispatch(setLoading(true));
      const { parentStatus, data } =
        await getMoneyDirectCostOutsourceInformationList(params);
      dispatch(setParentStatus(parentStatus));
      setData(data);
    } catch (error: any) {
      ErrorNotification(NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getMoneyDirectCostOutsourceInformationList = async (params: {
    constructionId: number;
    page?: number;
    size?: number;
    sortBy?: string;
    sortType?: string;
    from?: string;
    to?: string;
  }): Promise<{
    parentStatus: ParentStatus[];
    data: MoneyDirectCostOutsourceInformationListState[];
  }> => {
    try {
      const responseData =
        await service.getMoneyDirectCostOutsourceInformationList(params);
      let parentData: MoneyDirectCostOutsourceInformationListState[] = [];
      if (!responseData.results)
        return {
          parentStatus: [],
          data: [],
        };
      parentData = responseData.results?.map(
        (element: MoneyDirectCostOutsourceInformationListResult) => {
          const result: MoneyDirectCostOutsourceInformationListState = {
            ...element,
            child: [],
          };
          return result;
        }
      );
      if (parentData && parentData.length > 0) setExpandKey([parentData[0].id]);

      // get child
      const childResponseData = await Promise.all(
        parentData.map((element) => {
          return service.getMoneyDirectCostOutsourceList({
            outsourceCostId: element.id,
            sortBy: "createdAt",
            sortType: "ASC",
          });
        })
      );
      for (let i = 0; i < parentData.length; i++) {
        parentData[i].child = childResponseData[i];
      }

      // Có 3 giá trị: 承認済 & 承認待ち & 未承認
      // Điều kiện hiển thị trạng thái 承認済: Khi tất cả các record ở mục #11.4 có trạng thái 承認済
      // Điều kiện hiển thị trạng thái 承認待ち: Khi có ít nhất record ở mục #11.4 có trạng thái 承認待ち
      // Điều kiện hiển thị trạng thái 未承認: Khi tồn tại 1 record ở mục #11.4 có 1 trong 3 trạng thái 未承認, 取り下げ, 否認
      const parentStatus: ParentStatus[] = [];
      for (let i = 0; i < parentData.length; i++) {
        let status = STATUS_CM_PARENT_TABLE.REJECTED.LABEL;
        let actualCost: number = 0;
        if (parentData[i]?.child?.length > 0) {
          let isAllApproved: boolean = true;
          let isAllWaitingForApproval: boolean = false;
          for (let j = 0; j < parentData[i].child.length; j++) {
            if (
              parentData[i].child[j].status.code !==
              STATUS_CM_CHILD_TABLE.MONEY_APPROVED.CODE
            )
              isAllApproved = false;
            else if (
              parentData[i].child[j].status.code ===
              STATUS_CM_CHILD_TABLE.MONEY_APPROVED.CODE
            )
              actualCost += parentData[i].child[j].actualCost || 0;

            if (
              parentData[i].child[j].status.code ===
              STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE
            )
              isAllWaitingForApproval = true;
          }
          if (isAllApproved) status = STATUS_CM_PARENT_TABLE.APPROVED.LABEL;
          else if (isAllWaitingForApproval)
            status = STATUS_CM_PARENT_TABLE.PENDING.LABEL;
        }
        parentStatus.push({
          id: parentData[i].id,
          status: status,
          actualCost: actualCost,
          childrenNumber: parentData[i]?.child?.length ?? 0,
        });
      }
      return {
        parentStatus,
        data: parentData,
      };
    } catch (error: any) {
      throw error;
    }
  };
  const getMoneyDirectCostOutsourceOffsetList = async (
    params: {
      outsourceCostInformationId: number;
      categoryId?: number;
      keyword?: number;
      sortBy?: string;
      sortType?: string;
      page?: number;
      size?: number;
    },
    childData: MoneyDirectCostOutsourceListResult
  ): Promise<MoneyDirectCostOutsourceListResult> => {
    try {
      const size = params?.size ?? COMMON.DEFAULT_SIZE;
      let finalResult: MoneyDirectCostOutsourceOffsetListResults[] = [];
      const responseData = await service.getMoneyDirectCostOutsourceOffsetList({
        ...params,
        page: 1,
        size,
      });
      finalResult = finalResult.concat(responseData?.results);
      const numPages = responseData?.pagination?.numPages;
      for (let i = 2; i <= numPages; i++) {
        const responseData =
          await service.getMoneyDirectCostOutsourceOffsetList({
            ...params,
            page: i,
            size,
          });
        finalResult = finalResult.concat(responseData?.results);
      }

      if (childData) childData.grandChild = finalResult ?? [];
      return childData;
    } catch (error: any) {
      ErrorNotification(error.message ?? NOTIFICATION_TITLE.ERROR);
      return childData;
    }
  };

  const isCollapsed = (record: any) => {
    return expandKey.some((element) => element === record.key);
  };

  const onChangeCollapseRow = (record: any, collapsed: boolean) => {
    const collapsedRow = collapsed
      ? [...expandKey, record.key]
      : expandKey.filter((element) => element !== record.key);
    setExpandKey(collapsedRow);
  };
  const isCheckbox = (record: any): boolean =>
    checkedList.some((element) => element === record.key);

  const onCheckboxChange = (record: any, checked: boolean) => {
    // setCheckedList(list);
    let newCheckList: any[] = [];

    if (checked) {
      newCheckList = [...checkedList, record.key];
    } else {
      const checkedData = checkedList.filter(
        (element: any) => element !== record.key
      );
      newCheckList = checkedData;
    }
    const possibleItems: any[] = [];
    for (let element of parentStatus) {
      if (
        element?.status === STATUS_CM_PARENT_TABLE.APPROVED.LABEL ||
        element?.status === STATUS_CM_PARENT_TABLE.PENDING.LABEL ||
        element.childrenNumber === 0
      )
        continue;
      possibleItems.push(element.id);
    }
    newCheckList = [...new Set(newCheckList)];
    setCheckedList(newCheckList);
    setCheckAll(newCheckList.length === possibleItems.length);
    dispatch(setCheckedCollapseParent({ id: record.id, checked: checked }));
  };

  const onCheckAllChange = (checked: boolean) => {
    setCheckAll(checked);
    if (checked) {
      const checkedItems: any[] = [];
      for (let element of parentStatus) {
        if (
          element?.status === STATUS_CM_PARENT_TABLE.APPROVED.LABEL ||
          element?.status === STATUS_CM_PARENT_TABLE.PENDING.LABEL ||
          element.childrenNumber === 0
        )
          continue;
        checkedItems.push(element.id);
      }
      setCheckedList(checkedItems);
      // redux
      const checkedCollapseId = checkedCollapse.map((element) => element.id);
      const uncheckedData: any[] = [];
      checkedItems.forEach((element) => {
        const isExisted = checkedCollapseId.includes(element);
        if (!isExisted) uncheckedData.push({ id: element, checked: [] });
      });
      dispatch(
        setCheckedCollapseDirect([...checkedCollapse, ...uncheckedData])
      );
    } else {
      setCheckedList([]);
      dispatch(setCheckedCollapseDirect([]));
    }
  };

  const getPromiseAllOffsetData = async (data: any) => {
    const dataOffset = await Promise.all(
      data.child.map((element: MoneyDirectCostOutsourceListResult) => {
        return getMoneyDirectCostOutsourceOffsetList(
          {
            outsourceCostInformationId: element.id,
          },
          element
        );
      })
    );

    for (let i = 0; i < data.child.length; i++) {
      const foundOffset = dataOffset.find(
        (element) => element.id === data.child[i].id
      );
      if (foundOffset) data.child[i] = foundOffset;
    }
    return data;
  };

  const exportFile = async (params: {
    constructionId: number;
    page?: number;
    size?: number;
    sortBy?: string;
    sortType?: string;
    from?: string;
    to?: string;
    projectName: string;
  }) => {
    try {
      dispatch(setLoading(true));

      const response = await service.doExportCM009({
        constructionId: params.constructionId,
        page: params.page,
        size: params.size,
        sortBy: params.sortBy,
        sortType: params.sortType,
        from: params.from,
        to: params.to,
        projectName,
      } as any);
      doExportForResponse(response, fileName + ".xlsx");
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };
  return {
    data,
    checkedList,
    checkAll,
    expandKey,
    onCheckAllChange,
    onCheckboxChange,
    isCheckbox,
    isCollapsed,
    onChangeCollapseRow,
    putMoneyDirectCostMaterialEnable,
    putMoneySubmitDirectCostMaterial,
    checkIsEnableSubmitButton,
    exportFile,
    setDataAndParentStatus,
  };
};

export default MaterialCostHandler;
