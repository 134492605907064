import { createSlice } from "@reduxjs/toolkit";
import { PM007Slice } from "../type/Presenter";

const initialState: PM007Slice = {
  isModalOpen: false,
  projectId: null,
};

export const pm007Slice = createSlice({
  name: "toggleModalEdit",
  initialState,
  reducers: {
    resetPM007: () => initialState,
    setIsModalOpen: (state, action) => {
      state.isModalOpen = action.payload;
    },
    setProjectId: (state, action) => {
      state.projectId = action.payload;
    },
  },
});

export const { setIsModalOpen, setProjectId, resetPM007 } = pm007Slice.actions;

export default pm007Slice.reducer;
