import { CR003HandlerUI } from "../handler/HandlerImpl";
import { useParams, useSearchParams } from "react-router-dom";
import ApprovalFlow from "../../../../../common/components/approval-flow/ApprovalFlow";
import HomeSvg from "../../../../../assets/icons/home.svg";
import BreadcrumbComponent from "../../../../../common/components/breadcrumb/BreadcrumbComponent";
import "../../../../../assets/styles/CR/CR003.css";
import { PARAMS, VERSION_APPROVAL } from "../CONSTANT";

interface props {
    handler: CR003HandlerUI;
}

export const CR003Header = ({ handler }: props) => {

    const { projectId, } = useParams();

    const [searchParams, setSearchParams] = useSearchParams();
    const versionType = searchParams.get(PARAMS.VERSION_TYPE) || "";
    const BREADCRUMB_CR003 = [
        {
            title: "案件管理",
            url: "/app-pm002",
            icon: (
                <img
                    style={{ paddingBottom: 2, paddingRight: 8 }}
                    alt=""
                    src={HomeSvg}
                />
            ),
        },
        {
            title: "案件詳細",
            url: `/app-pm005/${projectId}`,
            icon: "",
        },
        {
            title: "実行予算書",
            url: "",
            icon: "",
        },
    ];

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "start",
                marginBottom: 32
            }}
        >
            <div className="om002-header-container">
                <BreadcrumbComponent data={BREADCRUMB_CR003} />
                <div className="header-row-title" style={{ marginTop: 8 }}>
                    実行予算書
                </div>
            </div>
            <ApprovalFlow
                isHiddenFirst={true}
                isNotCheckingUser={true}
                breadcrumb={[]}
                listApprove={handler.listApprovalProcess}
                currentUserApproval={handler.currentUserApproval}
                onClickBreadcrumb={handler.onClickBreadcrumb}
            />
        </div>
    );
};

