import React, { useEffect } from 'react';
import { ConfigProvider, Empty, Spin } from 'antd';
import 'antd/dist/antd.min.css';
import { useDispatch, useSelector } from 'react-redux';
import './App.css';
import './App.less';
import LoadingComponent from './common/components/icons/LoadingComponent';
import { onMessageListener } from './firebase';

import BaseRouter from './router/Router';
import { RootState } from './store';
import FirebaseNotification from './common/components/notification/FirebaseNotification';
import { setMenuItems, setRefreshNotification } from './common/slice/CommonSlice';
import helpers from './common/helpers/common';

function App() {
  const dispatch = useDispatch();
  const loading = useSelector((state: RootState) => state.common.loading);
  const authData = useSelector((state: RootState) => state.auth.authData);
  useEffect(() => {
    const session = sessionStorage.getItem('inSession');
    const user = helpers.getObjectFromLocalStorage('user');
    if (!user?.isRemembered && session == null) localStorage.removeItem('user');
    sessionStorage.setItem('inSession', '1');
  }, []);

  useEffect(() => {
    dispatch(setMenuItems(authData));
  }, [JSON.stringify(authData)]);

  const refreshNotification = useSelector(
    (state: RootState) => state.common.refreshNotification
  );
  const [notification, setNotification] = React.useState<any>({
    title: "",
    body: "",
  });
  // Listener Event Firebase

  onMessageListener()
    .then((payload: any) => {
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
      });
      dispatch(setRefreshNotification(!refreshNotification));
    })
    .catch((err) => { });

  const notify = () =>
    FirebaseNotification(notification?.title, notification?.body);

  React.useEffect(() => {
    if (notification.title !== "" && notification.body !== "") {
      notify();
      setNotification({
        title: "",
        body: "",
      })
    }

  }, [notification.title, notification.body]);

  return (
    <ConfigProvider
      renderEmpty={() => (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="データなし" />
      )}
    >
      <Spin indicator={LoadingComponent} spinning={!!loading}>
        <BaseRouter />
      </Spin>
    </ConfigProvider>
  );
}

export default App;
