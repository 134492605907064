import { FormInstance } from 'antd';
import COMMON from '../../../common/constants/COMMON';
import MESSAGE from '../../../common/constants/MESSAGE';
import REGEX from '../../../common/constants/REGEX';
import moment from 'moment';

const validateInput = (validate: { required?: boolean; maxLength?: number; regex?: RegExp }) => {
    return () => ({
        validator: async (_: any, value: any) => {
            if (value && value !== '') {
                // CHECK INPUT VIETNAMESE
                if (validate.regex && !validate.regex.test(value)) {
                    return Promise.reject(new Error(MESSAGE.MESSAGE_INPUT_INVALID));
                }
                if (validate.maxLength && value?.length > validate.maxLength) {
                    return Promise.reject(new Error(''));
                }
                return Promise.resolve();
            }
            if (validate.required) return Promise.reject(MESSAGE.MESSAGE_REQUIRED);
            return Promise.resolve();
        },
    });
};

const validateMail = () => {
    return () => ({
        validator(_: any, value: any) {
            // required
            if (!value || value.trim() === '')
                return Promise.reject(MESSAGE.MESSAGE_REQUIRED_FIELD);
            // test regex
            if (!REGEX.EMAIL_COMPANY.test(value.trim())) {
                return Promise.reject(MESSAGE.MESSAGE_WRONG_EMAIL_FORMAT);
            }
            return Promise.resolve();
        },
    });
};

const validateID = () => {
    return () => ({
        validator(_: any, value: any) {
            // required
            if (!value || value.trim() === '')
                return Promise.reject(MESSAGE.MESSAGE_REQUIRED_FIELD);
            // test regex
            if (!REGEX.ONLY_LATIN_2.test(value.trim())) {
                return Promise.reject(MESSAGE.MESSAGE_ID_SPECIAL_CHARACTER);
            }
            // more than 6
            if (value.trim().length < 6)
                return Promise.reject(MESSAGE.MESSAGE_INVALID_MORE_THAN_SIX);
            return Promise.resolve();
        },
    });
};

const disabledStartDate = (current: moment.Moment, endDate: moment.Moment): any => {
    if (!endDate || !current) return false;
    if (
        moment(current).format(COMMON.FORMAT_DATE_OM) ===
        moment(endDate).format(COMMON.FORMAT_DATE_OM)
    )
        return true;
    return current && endDate && current >= endDate;
};

const disabledEndDate = (current: moment.Moment, startDate: moment.Moment): any => {
    if (!startDate || !current) return false;
    if (
        moment(current).format(COMMON.FORMAT_DATE_OM) ===
        moment(startDate).format(COMMON.FORMAT_DATE_OM)
    )
        return true;
    return current && startDate && current <= startDate;
};

export { validateInput, validateMail, validateID, disabledStartDate, disabledEndDate };
