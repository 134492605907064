import { createSlice } from "@reduxjs/toolkit";

type PdfViewerModalSliceType = {
  isOpenPdfViewer: boolean;
};
const initialState: PdfViewerModalSliceType = {
  isOpenPdfViewer: false,
};

export const PdfViewerModalSlice = createSlice({
  name: "pdfViewer",
  initialState,
  reducers: {
    showPdfViewerModal: (state) => {
      state.isOpenPdfViewer = true;
    },
    hidePdfViewerModal: (state) => {
      state.isOpenPdfViewer = false;
    },
  },
});

export const { showPdfViewerModal, hidePdfViewerModal } =
  PdfViewerModalSlice.actions;
export default PdfViewerModalSlice.reducer;
