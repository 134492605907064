import moment from 'moment'
import React from 'react'
import COMMON from '../../constants/COMMON'

export const ViewDateWithRange = (props: {
    startDate?: string,
    endDate?: string,
}) => {
    return <>
        {`${props.startDate ? moment(props.startDate).format(COMMON.FORMAT_DATE_OM) : ''}${props.endDate ? ' - ' + moment(props.endDate).format(COMMON.FORMAT_DATE_OM) : ''}`}
    </>
}