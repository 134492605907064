import DB004Api from './infrastructure/api/Api';
import DB004Handler from './presenter/handler/Handler';
import DB004UI from './presenter/ui/UI';
import DB004Service from './usecase/Service';

const DB004Component = () => {
    const db004Api = DB004Api();
    const db004Service = DB004Service(db004Api);
    const db004Handler = DB004Handler(db004Service);
    return <DB004UI handler={db004Handler} />;
};

export default DB004Component;
