import { HandlerImpl } from '../handler/HandlerImpl';
import Table from './Table';

import { useEffect, useRef } from 'react';
import helpers from '../../../../../common/helpers/common';
import ModalApprove from '../../../../../common/components/approval-flow/ModalApprove';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import MESSAGE from '../../../../../common/constants/MESSAGE';
import { useSearchParams } from 'react-router-dom';
import COMMON from '../../../../../common/constants/COMMON';

interface props {
    handler: HandlerImpl;
}

const MainUI = ({ handler }: props) => {
    const [searchParams] = useSearchParams();
    const isVisibleApprove = useSelector((state: RootState) => state.cm0022.isVisibleApproveCM0022);
    const openFilter = searchParams.get('openFilter');
    const isExport = useSelector((state: RootState) => state.cm0022.isExport);
    const isRefresh = useSelector((state: RootState) => state.cm0022.isRefresh);

    const refExport: any = useRef(false);

    useEffect(() => {
        if (refExport && refExport?.current) {
            handler.funcExportData();
        }
        refExport.current = true;
    }, [isExport]);

    useEffect(() => {
        (async () => {
            if (!openFilter) {
                handler.getDataTable({
                    from: handler.clear ? '' : helpers.getFilterTimeMonth(handler.from),
                    to: handler.clear ? '' : helpers.getFilterTimeMonthTo(handler.to),
                    sortBy: 'createdAt',
                    sortType: 'DESC',
                    page: COMMON.DEFAULT_PAGE,
                    size: COMMON.DEFAULT_SIZE,
                });
                handler.getMaterialCostProjectSummary({
                    from: handler.clear ? '' : helpers.getFilterTimeMonth(handler.from),
                    to: handler.clear ? '' : helpers.getFilterTimeMonthTo(handler.to),
                });
            }
        })();
    }, [handler.from, handler.to, handler.clear, openFilter, isRefresh]);
    useEffect(() => {
        (async () => {
            if (!openFilter)
                handler.getMoneySummaryMaterialCostProjectStatus({
                    from: handler.clear ? '' : helpers.getFilterTimeMonth(handler.from),
                    to: handler.clear ? '' : helpers.getFilterTimeMonthTo(handler.to),
                });
        })();
    }, [handler.from, handler.to, handler.clear, handler.refreshStatus, openFilter, isRefresh]);

    useEffect(() => {
        handler.checkHavingData(handler.dataView?.length > 0)
    }, [JSON.stringify(handler.dataView)]);

    return (
        <Table handler={handler} />
    );
};

export default MainUI;
