import ErrorNotification from "../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../common/constants/MESSAGE";
import { ReSummaryProjectConstruction } from "../entity/Entity";
import { CM0026APIImpl } from "./ServiceImpl";

const CM0026Service = (api: CM0026APIImpl) => {
  const getProjectData = async (params: {
    page: number;
    size: number;
    from: string;
    to: string;
    sortBy: string;
    sortType: string;
  }): Promise<any> => {
    try {
      const response = await api.getProjectData(params);
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
      return null;
    }
  };

  const getOutsourceCost = async (params: {
    documentDirectOutsourceCostId: number;
    from: string;
    to: string;
  }): Promise<any> => {
    try {
      const response = await api.getOutsourceCost({ ...params });
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
      return null;
    }
  };

  const getLineSummary = async (params: any): Promise<any> => {
    try {
      const response = await api.getLineSummary({ ...params });
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
      return null;
    }
  };

  const getReSummaryProjectConstruction = async (params: {
    projectId: number;
    from: string;
    to: string;
  }): Promise<ReSummaryProjectConstruction[]> => {
    const response = await api.getReSummaryProjectConstruction({ ...params });
    return response?.data?.results ?? [];
  };

  const doExportCM0026 = async (params: any): Promise<any> => {
    return await api.doExportCM0026(params);
  };

  return {
    getProjectData,
    getOutsourceCost,
    getLineSummary,
    doExportCM0026,
    getReSummaryProjectConstruction,
  };
};

export default CM0026Service;
