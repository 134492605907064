// Built-in
import React, { useEffect } from 'react';

// 3rd party lib
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';

// Source files
import { CompletedPaidAmountHandlerImpl } from '../../../handler/HandlerImpl';
import { useSearchParams } from 'react-router-dom';
import COMMON from '../../../../../../../common/constants/COMMON';
import moment from 'moment';
import { calculateEstimate } from '../../../../../CM005/helper';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store';
import helpers from '../../../../../../../common/helpers/common';

// Image files

type props = {
    handler: CompletedPaidAmountHandlerImpl;
};

const COLUMN_WIDTH_COMPLETED_PAID_AMOUNT = [`${100 / 3}`, `${100 / 3}`, `${100 / 3}`];

const CompletedPaidAmountUI = ({ handler }: props) => {
    // Params
    const [searchParams] = useSearchParams();
    const constructionId = searchParams.get('constructionId');
    const constructionInfo = useSelector((state: RootState) => state.cm004.constructionInfo);

    const fromDate =
        searchParams.get('startDate') === null
            ? moment().startOf('month').format(COMMON.FORMAT_DATE2)
            : searchParams.get('startDate');
    const toDate = searchParams.get('endDate') || moment().format(COMMON.FORMAT_DATE2);
    const completedWork = useSelector((state: RootState) => state.cm004.completedWork);

    useEffect(() => {
        (async () => {
            if (constructionId && !isNaN(parseInt(constructionId)))
                await handler.getMoneyConstructionWorkAmountCompleted({
                    constructionId: parseInt(constructionId),
                    from: fromDate || '',
                    to: toDate || '',
                });
        })();
    }, [constructionId, fromDate, toDate]);

    const columns: ColumnsType<any> = [
        {
            title: '請求出来高',
            key: '請求出来高',
            align: 'center',
            width: COLUMN_WIDTH_COMPLETED_PAID_AMOUNT[0],
            render: (record) => {
                return (
                    <span className="collapsed-table-row-style">{`${helpers.formatNumberMoney(
                        record.billingVolume,
                    )}円`}</span>
                );
            },
        },
        {
            title: '請求出来高(％)',
            key: '請求出来高(％)',
            align: 'center',
            width: COLUMN_WIDTH_COMPLETED_PAID_AMOUNT[1],
            render: (record) => {
                const estimateCost = calculateEstimate(constructionInfo);
                return `${helpers.calculateRateAmount(estimateCost, record.billingVolume)}%`;
            },
        },
        {
            title: '請求残金',
            key: '請求残金',
            align: 'center',
            width: COLUMN_WIDTH_COMPLETED_PAID_AMOUNT[2],
            render: (record) => {
                const billBalance = calculateEstimate(constructionInfo) - record.billingVolume;
                return (
                    <span className="collapsed-table-row-style">
                        {`${helpers.formatNumberMoney(billBalance)}円`}
                    </span>
                );
            },
        },
    ];

    return (
        <Table
            title={() => <main className="cm005-title-header">工事出来高</main>}
            bordered={true}
            columns={columns}
            dataSource={[
                {
                    key: 'completed-paid-amount',
                    billingVolume: completedWork?.billingVolume ?? 0,
                },
            ]}
            className="collapsed-table-header table-footer-inside cm005-table-header"
            pagination={false}
        />
    );
};

export default CompletedPaidAmountUI;
