import { Button } from "antd";
import { PM012FooterHandler } from "../handler/HandlerImpl";

interface props {
  handler: PM012FooterHandler;
}

function PM012Footer({ handler }: props) {
  return (
    <div className="pm012-footer">
      <span className="pm012-footer-text">
        選択人数：
        {
          handler.funcFilterKeySuccess(
            handler?.selectedRowKeys,
            handler.dataMemberPM012
          ).length
        }
        名
      </span>
      <div className="pm012-footer-btn">
        <Button
          size="large"
          className="button-purple"
          onClick={handler.handleInvitedMember}
          disabled={handler.checkedUnInvitedMember?.length === 0}
          style={{ width: 76, letterSpacing: -1, height: 40 }}
        >
          招待
        </Button>
        <Button
          style={{ width: 118, height: 40 }}
          size="large"
          onClick={handler.handleCancelMemberInvitation}
        >
          キャンセル
        </Button>
      </div>
    </div>
  );
}

export default PM012Footer;
