import { AxiosResponse } from 'axios';
import axiosConfig from '../../../../../config/axiosConfig';

const CompletedPaidAmountApi = () => {
    const getMoneyConstructionWorkAmountCompleted = async (params: {
        constructionId: number;
        from?: string;
        to?: string;
    }): Promise<AxiosResponse<any, any>> => {
        const url = '/money/construction/work-amount-completed';
        return await axiosConfig.get(url, { params });
    }; // tested

    return { getMoneyConstructionWorkAmountCompleted };
};

export default CompletedPaidAmountApi;
