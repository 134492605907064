import { CM0042APIImpl } from './ServiceImpl';
import {
    MaterialSubmittedListData,
    OutsourceSubmittedListData,
    LaborSubmittedListData,
    IndirectSubmittedListData,
    ReceiveMoneySubmittedListData,
} from '../entity/Entity';
import ErrorNotification from '../../../../../common/components/notification/ErrorNotification';
import { NOTIFICATION_TITLE } from '../../../../../common/constants/MESSAGE';

const CM0042Service = (api: CM0042APIImpl) => {
    const getMaterialSubmittedList = async (): Promise<MaterialSubmittedListData | null> => {
        try {
            const response = await api.getMaterialSubmittedList();
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };

    const getOutsourceSubmittedList = async (): Promise<OutsourceSubmittedListData | null> => {
        try {
            const response = await api.getOutsourceSubmittedList();
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };

    const getLaborSubmittedList = async (): Promise<LaborSubmittedListData | null> => {
        try {
            const response = await api.getLaborSubmittedList();
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };

    const getIndirectSubmittedList = async (): Promise<IndirectSubmittedListData | null> => {
        try {
            const response = await api.getIndirectSubmittedList();
            return response.data;
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };

    const getReceiveMoneySubmittedList =
        async (): Promise<ReceiveMoneySubmittedListData | null> => {
            try {
                const response = await api.getReceiveMoneySubmittedList();
                return response.data;
            } catch (error: any) {
                ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
                return null;
            }
        };

    return {
        getMaterialSubmittedList,
        getOutsourceSubmittedList,
        getLaborSubmittedList,
        getIndirectSubmittedList,
        getReceiveMoneySubmittedList,
    };
};

export default CM0042Service;
