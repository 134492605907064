import axiosConfig from '../../../../../config/axiosConfig';

const CM003Api = () => {
    const getAttendanceUserConstruction = async (params: any): Promise<any> => {
        const url = '/attendance/user/construction';
        return await axiosConfig.get(url, { params });
    };

    const getSummaryReceiveMoney = async (params: any): Promise<any> => {
        const url = '/money/project/summary/received-money';
        return await axiosConfig.get(url, { params });
    };

    const getProjectListConstruction = async (params: any): Promise<any> => {
        const url = '/money/project/list-construction';
        return await axiosConfig.get(url, { params });
    };

    const getConstructionReceivedMoney = async (params: any): Promise<any> => {
        const url = 'money/received-money/list';
        return await axiosConfig.get(url, { params });
    };

    const getReceivedMoneyOffset = async (params: any): Promise<any> => {
        const url = 'money/received-money-offset/list';
        return await axiosConfig.get(url, { params });
    };

    const getPresignLinkS3 = async (params: any): Promise<any> => {
        const url = '/project/presign-link';
        return await axiosConfig.get(url, { params });
    };

    const doExportCM003 = async (params: any): Promise<any> => {
        try {
            const url = '/money/exportCM003';
            const response = await axiosConfig.get(url, { params, responseType: 'blob'  });
            
            return response;
        } catch (error) {
            throw error;
        }
    };

    return {
        getSummaryReceiveMoney,
        getAttendanceUserConstruction,
        getProjectListConstruction,
        getConstructionReceivedMoney,
        getReceivedMoneyOffset,
        getPresignLinkS3,
        doExportCM003,
    };
};

export default CM003Api;
