import { createSlice } from '@reduxjs/toolkit';
import { CM0061SliceType } from '../../entity/Entity';

export const initialState: CM0061SliceType = {
    isRefresh: false,
    isCollapsed: false,
    projectChosen: {},
};

export const CM0061Slice = createSlice({
    name: 'cm0061',
    initialState,
    reducers: {
        refreshCM0061: (state) => {
            state.isRefresh = !state.isRefresh;
        },
        setIsCollapsed: (state, action) => {
            state.isCollapsed = action.payload;
        },
        setProjectChosen: (state, action) => {
            state.projectChosen = action.payload;
        },
    },
});

export const { refreshCM0061, setIsCollapsed, setProjectChosen } = CM0061Slice.actions;

export default CM0061Slice.reducer;
