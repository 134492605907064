import axiosConfig from "../../../../../config/axiosConfig";

const DB003Api = () => {
  const getTurnoverAmount = async (params: {
    projectId: number;
    from: string;
    to: string;
  }) => {
    const url = "/dashboard/turnover-amount";
    return await axiosConfig.get(url, { params });
  };

  return {
    getTurnoverAmount,
  };
};

export default DB003Api;
