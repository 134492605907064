import { useState } from "react";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../constants/MESSAGE";
import { FormInstance } from "antd";
import ConfirmModalAsync from "../modal/ConfirmModalAsync";
import ErrorNotification from "../notification/ErrorNotification";
const useCertificationFormItem = (
  form: FormInstance<any>,
  initialLines: number[]
) => {
  const [lineQuantity, setLineQuantity] = useState<number[]>(initialLines);
  const [maxKey, setMaxKey] = useState(
    initialLines.length === 0 ? 1 : Math.max(...initialLines)
  );

  const addLine = () => {
    setLineQuantity([...lineQuantity, maxKey + 1]);
    setMaxKey(maxKey + 1);
  };

  const executeRemoveCertRow = (value: number) => {
    const cloneLineQuantity = [...lineQuantity];
    const index = cloneLineQuantity.indexOf(value);
    if (index > -1) {
      cloneLineQuantity.splice(index, 1);
    }
    setLineQuantity(cloneLineQuantity);
  };

  const removeLine = (value: number, formName: string) => {
    const hasName = !!form.getFieldValue([formName, `name`]);

    const isRowTouched = form.isFieldsTouched([
      [formName, `issueDate`],
      [formName, `registrationCode`],
      [formName, `issuer`],
      [formName, `expireDate`],
    ]);

    if (hasName || isRowTouched)
      ConfirmModalAsync({
        onOk: async () => {
          try {
            executeRemoveCertRow(value);
          } catch (error: any) {
            ErrorNotification(NOTIFICATION_TITLE.ERROR);
          }
        },
        onCancel: () => {},

        className: "confirm__modal",
        title: MESSAGE.MESSAGE_MODAL_DELETE_TITLE,
        description: MESSAGE.MESSAGE_DESCRIPTION3_DELETE_ORD001,
        canceText: LABEL_MESSAGE.CANCEL_MODAL,
        okText: LABEL_MESSAGE.OK_DELETE,
      });
    else executeRemoveCertRow(value);
  };

  const setValidExpireDate = (cert: string) => {
    form.setFields([
      {
        name: [cert, "expireDate"],
        errors: [],
      },
    ]);
  };
  const setValidIssueDate = (cert: string) => {
    form.setFields([
      {
        name: [cert, "issueDate"],
        errors: [],
      },
    ]);
  };

  return {
    addLine,
    removeLine,
    setValidIssueDate,
    setValidExpireDate,
    lineQuantity,
  };
};

export default useCertificationFormItem;
