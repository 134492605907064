import { Button, Form, Modal } from "antd";
import { HandlerImpl } from "../handler/HandlerImpl";
import IconClose from "../../../../../assets/icons/icon-close-ord.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { useEffect } from "react";
import { CommonFormItems } from "./CommonFormItems";
import { FreePlanFormItems } from "./FreePlanFormItems";
import { BasicPlanFormItems } from "../component/basic-plan-form-items";
import {
  ORDER_STATUS,
  PLAN_TYPE,
} from "../../../../../common/constants/COMMON";
import XIconModal from '../../../../../assets/icons/x-icon-modal.svg';
import { CommonHeaderModal } from "../../../../../common/components/modal/CommonHeaderModal";

const CUSTOM_DESCRIPTION = (
  <div>
    <div>行った変更は保存されません。</div>
    <div>このページを閉じてもよろしいでしょうか？</div>
  </div>
);

interface props {
  handler: HandlerImpl;
}

const MainUI = ({ handler }: props) => {
  const visible = useSelector((state: RootState) => state.ord002_1.visible);
  const orderId = useSelector((state: RootState) => state.ord002_1.orderId);
  const chosenPlan = useSelector(
    (state: RootState) => state.ord002_1.chosenPlan
  );
  const isUsingAPI = useSelector(
    (state: RootState) => state.ord002_1.isUsingAPI
  );
  const isFormValidating = useSelector(
    (state: RootState) => state.ord002_1.isFormValidating
  );
  const listStatusORD001 = useSelector(
    (state: RootState) => state.ord001.listStatus
  );
  const listPlanORD001 = useSelector(
    (state: RootState) => state.ord001.listPlan
  );
  const dataDetailPlanSettingORD001 = useSelector(
    (state: RootState) => state.ord001.dataDetailPlanSetting
  );
  const loading = useSelector((state: RootState) => state.common.loading);

  const {
    form,
    planDetail,
    planList,
    statusOrder,
    newPlanFunctions,
    existedPlanFunctions,
    totalDiscount,
    totalCost,
    orderDetail,

    addAPlanFunction,
    deleteAPlanFunction,
    onCloseModal,
    defaultValueForm,
    onFormChange,
    submitForm,
    getListStatusOrder,
    getPlanSettingDetail,
    getOrderDetail,
    getPlanList,
    onPlanChange,
    initiatePlanSetting,
    getDataFromLocalSource,
    getChosenPlanFunctionOnFormChange,
    calculateTotalCostDiscount,
    onValidatePlanFunction
  } = handler;

  useEffect(() => {
    defaultValueForm();
    if (isUsingAPI)
      Promise.all([
        getPlanList(),
        getListStatusOrder(),
        getPlanSettingDetail(),
      ]);
    else
      getDataFromLocalSource(
        listPlanORD001,
        listStatusORD001,
        dataDetailPlanSettingORD001
      );
  }, []);

  useEffect(() => {
    (async () => {
      if (orderId) {
        await getOrderDetail({ orderId });
        return;
      } else addAPlanFunction();
    })();
  }, [orderId]);

  useEffect(() => {
    if (planList.length === 0) return;
    else if (orderDetail)
      initiatePlanSetting(planList, orderDetail.plan.subCategory.code);
    else onPlanChange(PLAN_TYPE.PLAN_BASIC);
  }, [JSON.stringify(planList), orderDetail]);

  useEffect(() => {
    (async () => {
      if (isFormValidating)
        await onValidatePlanFunction([...existedPlanFunctions, ...newPlanFunctions])
    })()
  }, [JSON.stringify([...existedPlanFunctions, ...newPlanFunctions]), isFormValidating]);

  useEffect(() => {
    if (statusOrder.length > 0 && !orderDetail && !orderId) {
      form.setFieldValue("statusId", ORDER_STATUS.ACTIVE_ORDER);
    }
  }, [JSON.stringify(statusOrder), orderDetail, orderId]);

  return (
    <Modal
      title={
        <CommonHeaderModal
          title={orderId ? "オーダー編集" : "オーダー登録"}
          onClick={() => onCloseModal(CUSTOM_DESCRIPTION)}
        />
      }
      closable={false}
      visible={visible}
      width={1072}
      footer={
        <>
          <Button
            disabled={!!loading}
            size="large"
            type="primary"
            style={{ marginLeft: 16, width: 76, letterSpacing: -1.5 }}
            htmlType="submit"
            form="ord002_1Form"
          >
            保存
          </Button>
          <Button
            disabled={!!loading}
            size="large"
            style={{ marginLeft: 16, width: 118 }}
            onClick={() => handler.onCloseModal(CUSTOM_DESCRIPTION)}
          >
            キャンセル
          </Button>
        </>
      }
      centered
      maskClosable={false}
      className="ord002_1"
      style={{ marginLeft: 300 }}

    >
      <Form
        id="ord002_1Form"
        name="ord002_1-form"
        form={form}
        onValuesChange={onFormChange}
        onFieldsChange={calculateTotalCostDiscount}
        onFinish={(value: any) => submitForm(value, orderId ?? undefined)}
      >
        <CommonFormItems
          form={form}
          planList={planList}
          onPlanChange={onPlanChange}
        />
        {chosenPlan === null ? (
          <></>
        ) : chosenPlan?.subCategory.code !== PLAN_TYPE.PLAN_BASIC ? (
          <FreePlanFormItems statusOrder={statusOrder} />
        ) : (
          <BasicPlanFormItems
            orderDetail={orderDetail}
            totalCost={totalCost}
            totalDiscount={totalDiscount}
            statusOrder={statusOrder}
            newPlanFunctions={newPlanFunctions}
            existedPlanFunctions={existedPlanFunctions}
            form={form}
            planDetail={planDetail}
            addAPlanFunction={addAPlanFunction}
            deleteAPlanFunction={deleteAPlanFunction}
            getChosenPlanFunctionOnFormChange={getChosenPlanFunctionOnFormChange}
          />
        )}

      </Form>
    </Modal>
  );
};

export default MainUI;
