import { HandlerImpl } from '../handler/HandlerImpl';
import Table from './Table';
import ModalCM024 from './Modal';
import ModalView from './ModaView';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import React from 'react';
import CM017_2Component from '../../../CM017_2';
import { useSearchParams } from 'react-router-dom';
import CM0012_2Component from '../../../CM0012_2';
import COMMON from '../../../../../common/constants/COMMON';


interface props {
    handler: HandlerImpl;
}

const MainUI = ({ handler }: props) => {
    const isVisibleCm0011 = useSelector((state: RootState) => state.cm0012_2.isVisible);
    const isVisibleCM0017_2 = useSelector((state: RootState) => state.cm017_2.isVisible);
    const [searchParams, setSearchParams] = useSearchParams();
    const isOpenCalendar = searchParams.get('openFilterCM0024');
    const isChangeFromTo = searchParams.get('isChangeFromTo');
    const isExportDataCM0026 = useSelector((state: RootState) => state.cm0026.isExportDataCM0026);

    React.useEffect(() => {
        if (handler?.menu === handler?.MENU_ITEM[1]?.key) {
            !isOpenCalendar &&
                isChangeFromTo !== '0' &&
                handler.handleGetDataTable({
                    from: handler.from,
                    to: handler.to,
                    page: handler.page,
                    size: COMMON.DEFAULT_SIZE,
                });
            handler.handleGetSummaryLine({
                from: handler.from,
                to: handler.to,
            });
            searchParams.delete('isChangeFromTo');
            setSearchParams(searchParams);
        }

    }, [`${handler.from}${handler.to}`, isOpenCalendar, handler.page, handler.menu]);

    React.useEffect(() => {
        isExportDataCM0026 && handler.exportDataCM0026();
    }, [isExportDataCM0026]);

    React.useEffect(() => {
        handler.checkHavingData(handler.dataView?.length > 0)
    }, [JSON.stringify(handler.dataView)]);
    return (
        <div className="cm026">
            <Table handler={handler} />
            <ModalCM024 handler={handler} />
            <ModalView handler={handler} />
            {isVisibleCm0011 ? <CM0012_2Component /> : null}
            {isVisibleCM0017_2 ? <CM017_2Component /> : <></>}
        </div>
    );
};

export default MainUI;
