import { Form } from "antd";
import moment from "moment";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import COMMON, { PLAN_TYPE } from "../../../../../common/constants/COMMON";
import MESSAGE, {
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import REGEX from "../../../../../common/constants/REGEX";
import { OM005UseCase } from "../../usecase/ServiceImpl";
import {
  setDataTable,
  setDataUpload,
  setRowKeySelected,
  setShowModalResult,
  setDataModalSuccess,
  setDataModalFailure,
  setBranchData,
  setRoleData,
  setRoleDefaultValue,
  setRoleNameDefaultValue,
  rowKeySelected,
  dataUpload,
  setDataTableView,
  data,
  resetOM005,
} from "../slice/Slice";
import helpers from "../../../../../common/helpers/common";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import { RootState } from "../../../../../store";

const VALID_CERT: number[] = [1, 2, 3, 4, 5];

const ROLE_DEFAULT_CODE_MAPPING = "employee";

const OM005Handler = (OM005Service: OM005UseCase) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { groupId } = useParams();
  const rowKeyed = useSelector(rowKeySelected);
  const dataUploadT = useSelector(dataUpload);
  const dataUploadReat: any = useSelector(data);
  const [isRequiredPassword, setIsRequiredPassword] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");

  const order = useSelector(
    (state: RootState) => state.auth?.authData?.order?.code
  ); //action.payload?.order?.code;
  const isOrderFree = order === PLAN_TYPE.PLAN_FREE;
  const isOrderBasic = order === PLAN_TYPE.PLAN_BASIC;

  const handleGroupItems = (items: any, fileType: string) => {
    var results: any = []; // Using save items complete

    if (items.length === 0) {
      return results;
    }

    items.forEach((item: any, index: number) => {
      let isValid = true;

      const checkIssueDate = (
        value: string,
        value2: string,
        format1: string,
        format2: string
      ) => {
        if (value) {
          if (value && (value + "").trim() === "") {
            isValid = false;
            return MESSAGE.MESSAGE_REQUIRED_FIELD + "INVALID";
          }

          const convertedDate = moment(value).format(format2);
          const convertedDate2 = moment(value2).format(format2);

          if (convertedDate === "Invalid date") {
            isValid = false;
            return (
              "日付は必ずyyyy/mm/ddの形式で指定してください。" +
              "INVALID" +
              value
            );
          }
          if (
            convertedDate2 !== "Invalid date" &&
            convertedDate2 < convertedDate
          ) {
            isValid = false;
            return "情報が不正です。" + "INVALID" + value;
          }
          return convertedDate;
        }

        return String(value ?? "");
      };

      const checkExpiredDate = (
        value: string,
        value2: string,
        format1: string,
        format2: string
      ) => {
        if (value) {
          if (value && (value + "").trim() === "") {
            isValid = false;
            return MESSAGE.MESSAGE_REQUIRED_FIELD + "INVALID";
          }

          const convertedDate = moment(value).format(format2);
          const convertedDate2 = moment(value2).format(format2);

          if (convertedDate === "Invalid date") {
            isValid = false;
            return (
              "日付は必ずyyyy/mm/ddの形式で指定してください。" +
              "INVALID" +
              value
            );
          }
          if (
            convertedDate2 !== "Invalid date" &&
            convertedDate2 > convertedDate
          ) {
            isValid = false;
            return "情報が不正です。" + "INVALID" + value;
          }
          return convertedDate;
        }

        return String(value ?? "");
      };

      const checkName = (value: string) => {
        if (!value || (value + "").trim() === "") {
          isValid = false;
          return MESSAGE.MESSAGE_REQUIRED_FIELD + "INVALID";
        }
        if (value.length > 20) {
          isValid = false;
          return (
            "メンバーの名前は20文字を超えることができません。" +
            "INVALID" +
            value.substring(0, 20) +
            "..."
          );
        }
        const regex = new RegExp(REGEX.MEMBER_NAME_REGEX);
        const valueArr = value.split("");
        const isOnlyBracket = valueArr.every(
          (element: any) => element === "(" || element === ")"
        );
        if (!regex.test(value) || isOnlyBracket) {
          isValid = false;
          return "形式が不正です。" + "INVALID" + value;
        }
        return String(value);
      };

      const checkUserName = (value: string) => {
        if (!value || (value + "").trim() === "") {
          isValid = false;
          return MESSAGE.MESSAGE_REQUIRED_FIELD + "INVALID";
        }
        if (value.length > 50) {
          isValid = false;
          return (
            "IDは20文字を超えることができません。" +
            "INVALID" +
            value.substring(0, 50) +
            "..."
          );
        }
        const regex = new RegExp(REGEX.ONLY_LATIN);
        if (!regex.test(value)) {
          isValid = false;
          return "形式が不正です。" + "INVALID" + value;
        }
        return String(value);
      };

      const checkEmail = (value: string) => {
        if (!value || (value + "").trim() === "") {
          isValid = false;
          return MESSAGE.MESSAGE_REQUIRED_FIELD + "INVALID";
        }
        if (value.length > 50) {
          isValid = false;
          return (
            "メールアドレスは50文字を超えることができません。" +
            "INVALID" +
            value.substring(0, 50) +
            "..."
          );
        }
        const regex = new RegExp(REGEX.EMAIL_COMPANY);
        if (!regex.test(value)) {
          isValid = false;
          return "メールアドレスの形式が不正です。" + "INVALID" + value;
        }
        return String(value);
      };

      const checkPhonenumber = (value: string) => {
        if (!value || (value + "").trim() === "") {
          isValid = false;
          return MESSAGE.MESSAGE_REQUIRED_FIELD + "INVALID";
        }
        if (value.length < 10 || value.length > 15) {
          isValid = false;
          return (
            "電話番号が不正です。" +
            "INVALID" +
            (value.length > 15 ? value.substring(0, 20) + "..." : value)
          );
        }
        const regex = new RegExp(REGEX.ONLY_NUMBER);
        if (!regex.test(value)) {
          isValid = false;
          return "電話番号が不正です。" + "INVALID" + value;
        }
        return String(value);
      };

      const checkAddress = (value: string) => {
        if (!value || (value + "").trim() === "") {
          return "";
        }
        if (value.length > 50) {
          isValid = false;
          return (
            "住所は50文字を超えることができません。" +
            "INVALID" +
            value.substring(0, 50) +
            "..."
          );
        }
        const regex = new RegExp(REGEX.EXCEPT_VIETNAMESE);
        if (!regex.test(value)) {
          isValid = false;
          return "入力内容が不正です。" + "INVALID" + value;
        }
        return String(value);
      };

      const checkCertName = (value: string) => {
        if (value && value.length > 50) {
          isValid = false;
          return (
            "項目名は50文字を超えることができません。" +
            "INVALID" +
            value.substring(0, 50) +
            "..."
          );
        }
        const regex = new RegExp(REGEX.EXCEPT_VIETNAMESE);
        if (!regex.test(value)) {
          isValid = false;
          return MESSAGE.MESSAGE_INVALID_INPUT + "INVALID" + value;
        }
        return String(value ?? "");
      };

      const checkCertCode = (value: string) => {
        if (value && value.length > 20) {
          isValid = false;
          return (
            "取得NO.が不正です。" + "INVALID" + value.substring(0, 20) + "..."
          );
        }

        return String(value ?? "");
      };

      const checkCertIssuer = (value: string) => {
        if (value && value.length > 50) {
          isValid = false;
          return (
            "取得先は50文字を超えることができません。" +
            "INVALID" +
            value.substring(0, 50) +
            "..."
          );
        }
        const regex = new RegExp(REGEX.EXCEPT_VIETNAMESE);
        if (!regex.test(value)) {
          isValid = false;
          return MESSAGE.MESSAGE_INVALID_INPUT + "INVALID" + value;
        }
        return String(value ?? "");
      };

      const checkImportCode = (value: string) => {
        if (value) {
          if (value.length > 50) {
            isValid = false;
            return (
              "取得先は50文字を超えることができません。" +
              "INVALID" +
              value.substring(0, 50) +
              "..."
            );
          }
          if (!VALID_CERT.includes(parseInt(value))) {
            isValid = false;
            return "情報が不正です。" + "INVALID" + value;
          }
        }

        return String(value ?? "");
      };

      const checkCCUSID = (value: string, ccuidDate: string) => {
        if (!value) value = "";

        if (ccuidDate && value.length === 0 && ccuidDate.length !== 0) {
          isValid = false;
          return (
            MESSAGE.MESSAGE_CUSSID_FORMAT +
            "INVALID" +
            (value.length > 14 ? value.substring(0, 14) + "..." : value)
          );
        }

        if (value.length !== 0 && (value.length > 14 || value.length < 14)) {
          isValid = false;
          return (
            MESSAGE.MESSAGE_CUSSID_FORMAT +
            "INVALID" +
            (value.length > 14 ? value.substring(0, 14) + "..." : value)
          );
        }
        return String(value ?? "");
      };

      const checkCCUSDate = (value: string, format: string) => {
        if (value) {
          const convertDate = moment(value).format(format);

          if (convertDate === "Invalid date") {
            isValid = false;
            return "日付は必ずyyyy/mm/ddの形式で指定してください。" + "INVALID";
          }
        }

        return String(value ?? "");
      };
      if (
        (item?.NO !== undefined &&
          //   item?.ID !== undefined &&
          //   item?.電話番号 !== undefined &&
          //   item?.メール !== undefined &&
          //   item?.住所 !== undefined &&
          //   item?.CCUS技術者ID !== undefined &&
          //   item?.CCUS技術者IDの有効期限 !== undefined &&
          fileType === COMMON.EXCEL_KEY_TYPE) ||
        (item?.NO !== "" &&
          //   item?.ID !== "" &&
          //   item?.電話番号 !== "" &&
          //   item?.メール !== "" &&
          //   item?.住所 !== "" &&
          //   item?.CCUS技術者ID !== "" &&
          //   item?.CCUS技術者IDの有効期限 !== "" &&
          fileType === COMMON.CSV_KEY_TYPE)
      ) {
        // Kiểm tra sự tồn tại của key trong object. Điều kiện để check item complete or not
        // Mapping key japanese with expect key
        let obj: any = {
          no: item.NO || "",
          name: checkName(item?.名前),
          username: checkUserName(item?.ID),
          phone: checkPhonenumber(item?.電話番号),
          email: checkEmail(item?.メール),
          address: checkAddress(item?.住所),
          ccusId: checkCCUSID(item?.CCUS技術者ID, item?.CCUS技術者IDの有効期限),
          ccusDate: checkCCUSDate(
            item?.CCUS技術者IDの有効期限,
            COMMON.FORMAT_DATE2
          ),
          certificates: [
            {
              importCode: checkImportCode(item?.資格グループ),
              name: checkCertName(item?.資格登録),
              issueDate: checkIssueDate(
                item.取得年月日,
                item.有効期限,
                COMMON.FORMAT_DATE3,
                COMMON.FORMAT_DATE2
              ),
              registrationCode: checkCertCode(item["取得NO."]),
              issuer: checkCertIssuer(item?.取得先),
              expireDate: checkExpiredDate(
                item.有効期限,
                item.取得年月日,
                COMMON.FORMAT_DATE3,
                COMMON.FORMAT_DATE2
              ),
              id: 0,
            },
          ],
        };
        obj.isValid = isValid;
        results.push(obj);
      } else {
        /*
          - Lấy phẩn tử mảng hoàn thiện cuối cùng để thêm những item certificate vào trong mảng
          - Lấy những item khuyết thuyết được thêm vào phần tử hoàn chỉnh cuối cùng của mảng
        */
        let isValid = true;
        let objCertificate = {
          importCode: checkImportCode(item?.資格グループ),
          name: checkCertName(item?.資格登録),
          issueDate: item?.取得年月日
            ? checkIssueDate(
                item.取得年月日,
                item.有効期限,
                COMMON.FORMAT_DATE3,
                COMMON.FORMAT_DATE2
              )
            : "",
          registrationCode: checkCertCode(item["取得NO."]),
          issuer: checkCertIssuer(item?.取得先),
          expireDate: item?.有効期限
            ? checkExpiredDate(
                item.有効期限,
                item.取得年月日,
                COMMON.FORMAT_DATE3,
                COMMON.FORMAT_DATE2
              )
            : "",
          id: 0,
        };
        if (results[results.length - 1]?.isValid) {
          results[results.length - 1].isValid = isValid;
        }
        results[results.length - 1]?.certificates.push(objCertificate);
      }
    });
    results = results.map((element: any) => {
      if (
        (element.certificates.length === 1 && !element.certificates[0].name) ||
        !element.certificates[0].importCode
      ) {
        return { ...element, certificates: null };
      }
      return element;
    });
    return results;
  };

  const convertTableView = (items: any) => {
    const output: any = [];
    items.forEach((aT: any) => {
      let objectT = {
        key: `${aT.username},${aT.name}`,
        no: aT.no || "",
        name: aT.name || "",
        username: aT.username || "",
        phone: aT.phone || "",
        email: aT.email || "",
        address: aT.address || "",
        ccusId: aT.ccusId || "",
        ccusDate: aT.ccusDate || "",
        isValid: aT.isValid,
      };

      const certificate1: any[] = [];
      const certificate2: any[] = [];
      const certificate3: any[] = [];
      const certificate4: any[] = [];
      const certificate5: any[] = [];
      const certificate6: any[] = [];

      aT.certificates?.forEach((aTB: any) => {
        certificate1.push(String(aTB.importCode));
        certificate2.push(aTB.name);
        certificate3.push(aTB.issueDate);
        certificate4.push(aTB.registrationCode);
        certificate5.push(aTB.issuer);
        certificate6.push(aTB.expireDate);
      });

      output.push({
        ...objectT,
        certificate1: certificate1,
        certificate2: certificate2,
        certificate3: certificate3,
        certificate4: certificate4,
        certificate5: certificate5,
        certificate6: certificate6,
      });
    });

    dispatch(setDataTableView(output));
  };

  const convertDataTable = (obj: any, fileType: string) => {
    const convertedData = handleGroupItems(obj, fileType);
    convertTableView(convertedData);
    dispatch(setDataTable(convertedData));
  };

  const setDataUploadImport = (value: any) => {
    dispatch(setDataUpload(value));
  };

  const handleRowkeySelected = (value: any) => {
    dispatch(setRowKeySelected(value));
  };

  const getRoleList = async () => {
    try {
      const response = await OM005Service.getRoleList({});
      dispatch(setRoleData(response.results));

      if (response.results.length !== 0) {
        response.results.map((item: any) => {
          if (item.code === ROLE_DEFAULT_CODE_MAPPING) {
            dispatch(setRoleDefaultValue(item.id));
            dispatch(setRoleNameDefaultValue(item.name));
          }
        });
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const getBranchList = async () => {
    try {
      const response = await OM005Service.getGroupList({});
      dispatch(setBranchData(response.results?.groups));
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const handleChangeForm = (e: any) => {
    if (e.checkRequired) {
      setIsRequiredPassword(e.checkRequired === 1);
      if (e.checkRequired === 2) {
        form.resetFields();
      }
    }
    if (e.password || e.password === "") {
      setPassword(e.password);
      if (e.password?.length < 8) {
        form.resetFields(["passwordConfirm"]);
      }
    }
  };

  const handleSubmitForm = async (value: any) => {
    try {
      dispatch(setLoading(true));
      let password: string = "";

      if (value.password) {
        password = value.password;
      }
      const mapperRowKey: any = {};

      rowKeyed.map((item: any) => {
        mapperRowKey[item] = true;
      });

      const dataUpload: any = [];

      dataUploadReat.forEach((item: any) => {
        let obj: any = {};

        dataUploadT.forEach((aT: any) => {
          if (
            `${item.username},${item.name}` in mapperRowKey &&
            item.username === aT.username
          ) {
            obj = {
              address: item.address,
              password: password,
              groupId: aT.branch,
              roleId: isOrderBasic ? aT.role : undefined,
              email: item.email,
              phoneNumber: item.phone,
              username: item.username,
              fullName: item.name,
              certificates: item.certificates,
              ccusId: helpers.convertToCCUSFormat(item.ccusId),
              ccusExpiredDate: helpers.convertStringDateUpdate(item?.ccusDate),
            };

            dataUpload.push(obj);
          }
        });
      });

      const response = await OM005Service.importMember({
        groupId: groupId !== "" && groupId ? parseInt(groupId) : 0,
        data: dataUpload,
      });
      if (response.status === `${COMMON.HTTP_STATUS_OK}`) {
        dispatch(setShowModalResult(true));
        dispatch(setDataModalSuccess(response?.results?.success));
        dispatch(setDataModalFailure(response?.results?.error));
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }

    return dataUpload;
  };

  const exportCSV = async () => {
    try {
      await OM005Service.exportTemplateCSV();
    } catch (error) {
      throw error;
    }
  };

  const exportEXCEL = async () => {
    try {
      await OM005Service.exportTemplateEXCEL();
    } catch (error) {
      throw error;
    }
  };

  const mappingName = (arr: any[], key: number) => {
    let result: string = "";
    arr.forEach((ele: any) => {
      if (ele?.id === key) result = ele?.name;
    });

    return result;
  };

  const clearReduxOM005 = () => {
    dispatch(resetOM005());
  };

  return {
    convertDataTable,
    setDataUploadImport,
    handleRowkeySelected,
    getBranchList,
    getRoleList,
    handleChangeForm,
    handleSubmitForm,
    exportCSV,
    exportEXCEL,
    mappingName,
    clearReduxOM005,

    form,
    password,
    isRequiredPassword,
  };
};

export default OM005Handler;
