import React, { useEffect } from 'react';

import CircleCheckSVG from '../../../../../../../assets/icons/am-001-circle-check.svg';
import CircleUnCheckSVG from '../../../../../../../assets/icons/am-001-circle-uncheck.svg';
import XCheckSVG from '../../../../../../../assets/icons/am-001-x-check.svg';
import XUnCheckSVG from '../../../../../../../assets/icons/am-001-x-uncheck.svg';
import { Form, FormInstance, Input } from 'antd';
import { HandlerDoubleCheckButtonImpl } from '../../../handler/HandlerImpl';

type props = {
    form: FormInstance;
    constructionId: number;
    doubleCheck: boolean | null;
    userTimeSheetId: number;
    handler: HandlerDoubleCheckButtonImpl;
};

const DoubleCheckButtonUI = ({
    handler,
    form,
    constructionId,
    doubleCheck,
    userTimeSheetId,
}: props) => {
    useEffect(() => {
        handler.changeDoubleCheck({
            form: form,
            constructionId: constructionId,
            doubleCheck: doubleCheck,
            userTimeSheetId: userTimeSheetId,
            isFirst: false,
        });
    }, []);

    return (
        <main style={{ display: 'flex', justifyContent: 'center' }}>
            <div
                className="button-style  mr-8"
                onClick={() => {
                    let doubleCheck: null | boolean = true;
                    if (handler.doubleCheckState === true) doubleCheck = null;
                    handler.changeDoubleCheck({
                        form: form,
                        constructionId: constructionId,
                        doubleCheck,
                        userTimeSheetId: userTimeSheetId,
                        isFirst: true,
                    });
                }}
            >
                {!handler.doubleCheckState || handler.doubleCheckState === null ? (
                    <img alt="" src={CircleUnCheckSVG} />
                ) : (
                    <img alt="" src={CircleCheckSVG} />
                )}
            </div>
            <div
                className="button-style"
                onClick={() => {
                    let doubleCheck: null | boolean = false;
                    if (handler.doubleCheckState === false) doubleCheck = null;
                    handler.changeDoubleCheck({
                        form: form,
                        constructionId: constructionId,
                        doubleCheck,
                        userTimeSheetId: userTimeSheetId,
                        isFirst: true,
                    });
                }}
            >
                {handler.doubleCheckState || handler.doubleCheckState === null ? (
                    <img alt="" src={XUnCheckSVG} />
                ) : (
                    <img alt="" src={XCheckSVG} />
                )}
            </div>
            <Form.Item hidden={true} name={`timesheet${userTimeSheetId}`}>
                <Input />
            </Form.Item>
        </main>
    );
};

export default DoubleCheckButtonUI;
