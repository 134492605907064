import React, { useState } from "react";

import { message, FormInstance } from "antd";
import type { UploadChangeParam } from "antd/es/upload";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { uuidv4 } from "@firebase/util";
import MESSAGE from "../../../constants/MESSAGE";
import STATUS from "../../../constants/STATUS";


const UploadEvidenceHandler = () => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const [fileName, setFileName] = useState<string>("");
  const [currentValue, setCurrentValue] = useState<any>(null);

  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "application/pdf";
    if (!isJpgOrPng) {
      message.error("ファイルの形式が不正です。");
    }
    const isLt8M = file.size / 1024 / 1024 < 8;
    if (!isLt8M) {
      message.error("8MB以上のファイルはアップロードできません。");
    }
    return isJpgOrPng && isLt8M;
  };

  const dummyRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleChange = (
    info: UploadChangeParam<UploadFile>,
    form: FormInstance,
    nameForm: string | string[],
    afterClear?: (fileName: any) => void
  ) => {
    if (info.file) {
      const isJpgOrPng =
        info.file.type === "image/jpeg" ||
        info.file.type === "image/png" ||
        info.file.type === "image/jpeg" ||
        info.file.type === "application/pdf";
      const isLt8M = (info.file.size as number) / 1024 / 1024 < 8;
      if (isJpgOrPng && isLt8M) {
        setCurrentValue(info);
        setFileName(info.file.name || "");
        if (info.file.status === STATUS.UPLOADING) {
          setLoading(true);
          return;
        }
        if (info.file.status === STATUS.DONE) {
          // Get this url from response in real world.
          getBase64(info.file.originFileObj as RcFile, (url) => {
            setLoading(false);
            setImageUrl(url);
          });
        }
      } else {
        if (!currentValue?.file?.originFileObj && afterClear) {
          afterClear(nameForm);
        }
        if (!currentValue) {
          form.resetFields([nameForm]);
          if (afterClear) afterClear(nameForm);
        } else {
          form.setFieldValue(nameForm, currentValue);
        }
      }
    }
  };

  const handleClearForm = (
    form: FormInstance,
    nameForm: string | string[],
    afterClear?: (fileName: any) => void
  ) => {
    if (nameForm && form) {
      form.resetFields([nameForm]);
      if (afterClear) afterClear(nameForm);
      setFileName("");
    }
  };
  const handleAddDefaultFileName = (fileNameDefault?: string) => {
    setFileName(fileNameDefault ?? "");
  };

  return {
    loading,
    imageUrl,
    fileName,
    beforeUpload,
    dummyRequest,
    handleChange,
    handleClearForm,
    handleAddDefaultFileName,
    setCurrentValue,
  };
};

export default UploadEvidenceHandler;
