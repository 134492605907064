import { AxiosResponse } from "axios";
import axiosConfig from "../../../../../config/axiosConfig";

const CM0031Api = () => {
  const getSubmittedIndirectCostProjectList = async (params: {
    documentId: string;
    projectId: string;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "re-summary/submitted/indirect-cost/project/list";
    return await axiosConfig.get(url, { params });
  };
  const getReSummarySubmittedIndirectCostProjectListByDocument =
    async (params: {
      page?: number;
      size?: number;
      documentId: number;
    }): Promise<AxiosResponse<any, any>> => {
      const url =
        "/re-summary/submitted/indirect-cost/project/list-by-document";
      return await axiosConfig.get(url, { params });
    };
  const postSubmittedIndirectCostProjectRetrieve = async (
    data: any
  ): Promise<AxiosResponse<any, any>> => {
    const url = "/re-summary/submitted/indirect-cost/project/retrieve";
    return await axiosConfig.put(url, data);
  };

  const getSubmittedIndirectCostProjectTotal = async (params: {
    documentId: number;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/re-summary/submitted/indirect-cost/project/total";
    return await axiosConfig.get(url, { params });
  };
  const getWorkflowSummaryIndirectApprovalProcess = async (params: {
    documentId: number;
  }): Promise<any> => {
    const url = "/workflow/summary/indirect/approval-process";
    return await axiosConfig.get(url, { params });
  };
  return {
    getReSummarySubmittedIndirectCostProjectListByDocument,
    getSubmittedIndirectCostProjectList,
    postSubmittedIndirectCostProjectRetrieve,
    getSubmittedIndirectCostProjectTotal,
    getWorkflowSummaryIndirectApprovalProcess,
  };
};

export default CM0031Api;
