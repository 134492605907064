import axiosConfig from "../../../../../config/axiosConfig";
import {
  ReceiveMoneyCompanySubmitPayload,
  ReceiveMoneyProjectSubmitPayload,
} from "../../entity/Entity";

const CM0032Api = () => {

  const getSummaryReceiveMoneyCompanyList = async (params: any) => {
    const url = '/money/summary/received-money/company/list';
    return await axiosConfig.get(url, { params });
  };

  const getMoneySummaryReceiveMoneyCompanyProjectList = async (params: any) => {
    const url = '/money/summary/received-money/company/project/list';
    return await axiosConfig.get(url, { params });
  };


  const getSummaryProjectReceiveMoneyList = async (
    params: any
  ): Promise<any> => {
    const url = "/money/summary/received-money/project/list";
    return await axiosConfig.get(url, { params });
  };

  const getSummaryProjectReceiveMoney = async (params: any): Promise<any> => {
    const url = "/money/summary/received-money/project/list-receive-money";
    return await axiosConfig.get(url, { params });
  };

  const getSummaryProjectReceiveMoneyTotal = async (params: any) => {
    const url = "/money/summary/received-money/project/summary";
    return await axiosConfig.get(url, { params });
  };
  //BY TRADER
  const getSummaryCompanyReceiveMoneyList = async (params: any) => {
    const url = "/money/summmary/received-money/company/list";
    return await axiosConfig.get(url, { params });
  };

  const getSummaryCompanyProjectReceiveMoneyList = async (params: any) => {
    const url = "/money/summary/received-money/company/project/list";
    return await axiosConfig.get(url, { params });
  };

  const getSummaryCompanyProjectReceiveMoney = async (params: any) => {
    const url = "/money/summary/received-money/project/list-receive-money";
    return await axiosConfig.get(url, { params });
  };

  const getSummaryCompanyProjectReceiveMoneyTotal = async (params: any) => {
    const url = "/money/summary/received-money/project/summary";
    return await axiosConfig.get(url, { params });
  };

  const getReceiveMoneyProjectDocumentStatus = async (params: {
    from: string;
    to: string;
  }) => {
    const url = "/re-summary/received-money/project/document/status";
    return await axiosConfig.get(url, { params });
  };

  const getReceiveMoneyCompanyDocumentStatus = async (params: {
    from: string;
    to: string;
  }) => {
    const url = "/re-summary/received-money/company/document/status";
    return await axiosConfig.get(url, { params });
  };

  const putReceiveMoneyProjectSubmit = async (
    data: ReceiveMoneyProjectSubmitPayload
  ) => {
    const url = "/re-summary/received-money/project/submit";
    return await axiosConfig.post(url, data);
  };

  const putReceiveMoneyCompanySubmit = async (
    data: ReceiveMoneyCompanySubmitPayload
  ) => {
    const url = "/re-summary/received-money/company/project/submit";
    return await axiosConfig.post(url, data);
  };

  const getReceivedMoneyOffset = async (params: any): Promise<any> => {
    const url = "money/received-money-offset/list";
    return await axiosConfig.get(url, { params });
  };

  const doExportCM0032_1 = async (params: any): Promise<any> => {
    try {
      const url = "/money/exportCM0032_1";
      const response = await axiosConfig.get(url, {
        params,
        responseType: "blob",
      });

      return response;
    } catch (error) {
      throw error;
    }
  };

  const doExportCM0032_2 = async (params: any): Promise<any> => {
    try {
      const url = "/money/exportCM0032_2";
      const response = await axiosConfig.get(url, {
        params,
        responseType: "blob",
      });

      return response;
    } catch (error) {
      throw error;
    }
  };

  const getTaxSetting = async (params: any): Promise<any> => {
    const url = "/tax-setting";
    return await axiosConfig.get(url, { params });
  };

  return {
    getSummaryProjectReceiveMoneyList,
    getSummaryProjectReceiveMoney,
    getSummaryProjectReceiveMoneyTotal,
    getSummaryCompanyReceiveMoneyList,
    getSummaryCompanyProjectReceiveMoneyList,
    getSummaryCompanyProjectReceiveMoney,
    getSummaryCompanyProjectReceiveMoneyTotal,
    getReceiveMoneyProjectDocumentStatus,
    putReceiveMoneyProjectSubmit,

    getReceiveMoneyCompanyDocumentStatus,
    putReceiveMoneyCompanySubmit,
    getReceivedMoneyOffset,
    doExportCM0032_1,
    doExportCM0032_2,

    getSummaryReceiveMoneyCompanyList,
    getMoneySummaryReceiveMoneyCompanyProjectList,
    getTaxSetting
  };
};

export default CM0032Api;
