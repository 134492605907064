import { CM0025_1SliceType } from './../type/Presenter';
import { createSlice } from '@reduxjs/toolkit';

const initialState: CM0025_1SliceType = {
    loading: false,
    isVisible: false,
    refresh: false,
    typeModal: false,
    parentCode: {
        categoryId: 13,
        documentSummaryOutsourceCostConstructionDataId: 104,
    },
    headerData: {
        traderName: null,
        contractWorkName: null,
        targetPeriod: null,
    },
    title: null,
};

export const cm0025_1Slice = createSlice({
    name: 'cm0025_1',
    initialState,
    reducers: {
        resetCM0025_1: () => initialState,
        setLoadingCM0025_1: (state, action) => {
            state.loading = action.payload;
        },
        setIsVisibleCM0025_1: (state, action) => {
            state.isVisible = action.payload;
        },
        setRefreshCM0025_1: (state) => {
            state.refresh = !state.refresh;
        },
        setTypeModalCM0025_1: (state, action) => {
            state.typeModal = action.payload;
        },
        setParentCodeCM0025_1: (state, action) => {
            state.parentCode = action.payload;
        },
        setHeaderDataCM0025_1: (state, action) => {
            state.headerData = action.payload;
        },
        setTitleCM0025_1: (state, action) => {
            state.title = action.payload;
        },
    },
});

export const {
    setLoadingCM0025_1,
    setIsVisibleCM0025_1,
    resetCM0025_1,
    setRefreshCM0025_1,
    setTypeModalCM0025_1,
    setParentCodeCM0025_1,
    setHeaderDataCM0025_1,
    setTitleCM0025_1,
} = cm0025_1Slice.actions;

export default cm0025_1Slice.reducer;
