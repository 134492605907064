// Built-in
import React, { useEffect, useRef } from 'react';

// 3rd party lib
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';

// Source files
import { MaterialCostHandlerImpl } from '../../../handler/HandlerImpl';

// Image files
import CollapsedTableComponent from './DirectCostTable';
import {
    calculateBudgetBalance,
    calculateBudgetUtilizationRate,
    convertDataSource,
} from '../helper';
import { useSearchParams } from 'react-router-dom';
import ThresholdPopoverComponent from '../../../../../../../common/components/threshold-popover';
import helpers from '../../../../../../../common/helpers/common';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store';

type props = {
    handler: MaterialCostHandlerImpl;
};
export const COLUMN_WIDTH_CM007: any[] = ['5%', '19%', '19%', '19%', '19%', '19%'];

const MaterialCostUI = ({ handler }: props) => {
    const [searchParams] = useSearchParams();
    const constructionId = searchParams.get('constructionId');
    const projectName = searchParams.get('projectName') || "";

    const constructionCost = useSelector((state: RootState) => state.cm004.constructionCost);

    const isExport = useSelector((state: RootState) => state.cm004.isExport);

    useEffect(() => {
        let isMounted = true;
        if (isMounted)
            (async () => {
                if (constructionId)
                    await handler.getMoneySummaryConstructionCostInformation({
                        constructionId: parseInt(constructionId),
                    });
            })();

        return () => {
            isMounted = false;
        };
    }, []);

    const firstRender2 = useRef(false);
    useEffect(() => {
        if (firstRender2.current) {
            (async () => {
                await handler.exportFile({ projectName: projectName });
            })();
            return;
        }
        firstRender2.current = true;
    }, [isExport]);

    const columns: ColumnsType<any> = [
        {
            title: 'No',
            key: 'No',
            width: COLUMN_WIDTH_CM007[0],
            render: (_, record, index) => (
                <span className="cell-cm007-style white-space-nowrap">{index + 1}</span>
            ),
        },
        {
            title: '項目',
            key: '項目',
            width: COLUMN_WIDTH_CM007[1],
            render: (record) => (
                <span className="cell-cm007-style white-space-nowrap">{record.name}</span>
            ),
        },
        {
            title: '予算金額',
            key: '予算金額',
            width: COLUMN_WIDTH_CM007[2],
            render: (record) => (
                <span className="cell-cm007-style white-space-nowrap">{`${helpers.formatNumberMoney(
                    record.planCost,
                )}円`}</span>
            ),
        },
        {
            title: '実費用',
            key: '実費用',
            width: COLUMN_WIDTH_CM007[3],
            render: (record) => (
                <span className="cell-cm007-style white-space-nowrap">{`${helpers.formatNumberMoney(
                    record.actualCost,
                )}円`}</span>
            ),
        },
        {
            title: '予算残高',
            key: '予算残高',
            width: COLUMN_WIDTH_CM007[4],
            render: (record) => (
                <span className="cell-cm007-style white-space-nowrap">{`${helpers.formatNumberMoney(
                    calculateBudgetBalance(record),
                )}円`}</span>
            ),
        },
        {
            title: '予算消化率（％）',
            key: '予算消化率（％）',
            width: COLUMN_WIDTH_CM007[5],
            render: (record) => {
                let updateFunction = handler.putMoneySummaryConstructionCostIndirectThresholdUpdate;
                if (record.key === 'directCost')
                    updateFunction = handler.putMoneySummaryConstructionCostDirectThresholdUpdate;
                return (
                    <div>
                        <span className="cell-cm007-style white-space-nowrap">{`${calculateBudgetUtilizationRate(
                            record,
                        )}%`}</span>
                        <ThresholdPopoverComponent
                            initialData={{
                                ...record,
                                constructionId: constructionId ? parseInt(constructionId) : 0,
                            }}
                            update={updateFunction}
                        />
                    </div>
                );
            },
        },
    ];

    return (
        <Table
            title={() => <main className="cm005-title-header">予算残高一覧</main>}
            rowClassName={() => {
                return 'button-style';
            }}
            columns={columns}
            dataSource={convertDataSource(constructionCost)}
            id="main-table"
            className="cm007-table cm-main-table table-footer cm005-table-header"
            pagination={false}
            expandable={{
                showExpandColumn: false,
                expandedRowKeys: ['directCost'],
                expandedRowRender: (record: any) => (
                    <CollapsedTableComponent
                        constructionId={constructionId}
                        putMoneySummaryConstructionCostMaterialThresholdUpdate={
                            handler.putMoneySummaryConstructionCostMaterialThresholdUpdate
                        }
                        putMoneySummaryConstructionCostOutsourceThresholdUpdate={
                            handler.putMoneySummaryConstructionCostOutsourceThresholdUpdate
                        }
                        putMoneySummaryConstructionCostLaborThresholdUpdate={
                            handler.putMoneySummaryConstructionCostLaborThresholdUpdate
                        }
                        constructionCost={constructionCost}
                    />
                ),
            }}
            summary={(pageData) => {
                let actualCostTotal = 0;
                let planCostTotal = 0;
                pageData.forEach(({ actualCost, planCost }) => {
                    actualCostTotal += actualCost || 0;
                    planCostTotal += planCost || 0;
                });
                return (
                    <Table.Summary fixed="bottom">
                        <Table.Summary.Row
                            className="parent-table-summary"
                            style={{ background: '#605BFF' }}
                        >
                            <Table.Summary.Cell index={0} align="left" colSpan={2}>
                                合計金額
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} colSpan={0}></Table.Summary.Cell>
                            <Table.Summary.Cell index={2}>
                                <span className="center-item white-space-nowrap">{`${planCostTotal.toLocaleString(
                                    'en-US',
                                    { maximumFractionDigits: 2 },
                                )}円`}</span>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={3}>
                                <span className="center-item white-space-nowrap">{`${actualCostTotal.toLocaleString(
                                    'en-US',
                                    { maximumFractionDigits: 2 },
                                )}円`}</span>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={4}>
                                <span className="center-item white-space-nowrap">{`${calculateBudgetBalance(
                                    {
                                        actualCost: actualCostTotal,
                                        planCost: planCostTotal,
                                        threshold: 0,
                                        enable: false,
                                    },
                                ).toLocaleString('en-US', { maximumFractionDigits: 2 })}円`}</span>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={5}>
                                <span className="center-item white-space-nowrap">{`${calculateBudgetUtilizationRate(
                                    {
                                        actualCost: actualCostTotal,
                                        planCost: planCostTotal,
                                        threshold: 0,
                                        enable: false,
                                    },
                                )}%`}</span>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Table.Summary>
                );
            }}
        />
    );
};

export default MaterialCostUI;
