import axios, { AxiosResponse } from "axios";
import axiosConfig from "../../../../../config/axiosConfig";
import {
  MoneyDirectCostOutSourceCreatePayload,
  MoneyDirectCostOutsourceDeletePayload,
  MoneyDirectCostOutsourceUpdatePayload,
  MoneySubmitDirectCostOutsourcePayload,
} from "../../entity/Entity";

const CollapsedTableApi = () => {
  const deleteMoneyDirectCostOutsourceDelete = async (
    data: MoneyDirectCostOutsourceDeletePayload
  ): Promise<AxiosResponse<any, any>> => {
    const url = "/money/direct-cost/outsource/delete";
    return await axiosConfig.delete(url, { data });
  };

  const postMoneyDirectCostOutSourceCreate = async (
    data: MoneyDirectCostOutSourceCreatePayload
  ): Promise<AxiosResponse<any, any>> => {
    const url = "/money/direct-cost/outsource/create";
    return await axiosConfig.post(url, data);
  };

  const postMoneyDirectCostOutsourceUpdate = async (
    data: MoneyDirectCostOutsourceUpdatePayload
  ): Promise<AxiosResponse<any, any>> => {
    const url = "/money/direct-cost/outsource/update";
    return await axiosConfig.put(url, data);
  };

  const getMoneyDirectCostOutsourceOffsetList = async (params: {
    outsourceCostInformationId: number;
    page?: number;
    size?: number;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/money/direct-cost/outsource/offset/list";
    return await axiosConfig.get(url, { params });
  };
  const getAttendanceProjectConstruction = async (params: {
    constructionId: number;
    colabCompanyId: number;

    from: string;
    to: string;
    timeOffset: number;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/attendance/project/construction";
    return await axiosConfig.get(url, { params });
  };

  const getMoneyDirectCostOutsourceList = async (params: {
    outsourceCostId: number;
    page?: number;
    size?: number;
    sortBy?: string;
    sortType?: string;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/money/direct-cost/outsource/list";
    return await axiosConfig.get(url, { params });
  };
  const getLinkPresignUpload = async (
    params: any
  ): Promise<AxiosResponse<any, any>> => {
    const url = "/project/presign-upload";
    return await axiosConfig.get(url, { params });
  };
  const uploadToS3 = async (
    url: any,
    data: any
  ): Promise<AxiosResponse<any, any>> => {
    return await axios.put(url, data, {
      headers: {
        "Content-Type": data.type,
      },
    });
  };
  const getPresignLinkS3 = async (
    params: any
  ): Promise<AxiosResponse<any, any>> => {
    const url = "/project/presign-link";
    return await axiosConfig.get(url, { params });
  };
  return {
    deleteMoneyDirectCostOutsourceDelete,
    postMoneyDirectCostOutSourceCreate,
    postMoneyDirectCostOutsourceUpdate,
    getMoneyDirectCostOutsourceList,
    getLinkPresignUpload,
    uploadToS3,
    getPresignLinkS3,
    getMoneyDirectCostOutsourceOffsetList,
    getAttendanceProjectConstruction,
  };
};

export default CollapsedTableApi;
