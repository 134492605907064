import React, { useState } from "react";
import EditIcon from "../../../../../assets/icons/edit-bold.svg";
import DeleteIcon from "../../../../../assets/icons/delete-bold.svg";
import DetailIcon from "../../../../../assets/icons/show-bold.svg";

import { ColumnsType } from "antd/es/table";
import { DataTableType } from "../../entity/Entity";
import { Table } from "antd";
import { Handler } from "../handler/HandlerImpl";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { AUTHORIZATION_CODE, SYSTEM_ROLES } from "../../../../../common/constants/AUTHORIZATION";

interface props {
  handler: Handler;
}

const TableComponent = ({ handler }: props) => {
  const permission = useSelector(
    (state: RootState) => state.auth.authData?.permission
  );
  const columns: ColumnsType<DataTableType> = [
    {
      title: "No",
      dataIndex: "no",
      width: 20,
      render: (_, record, index) => {
        return <div style={{ color: "#222" }}>{index + 1}</div>;
      },
    },
    {
      title: "役職",
      dataIndex: "director",
      width: 180,
      render: (_, record) => {
        return <div style={{ color: "#605BFF" }}>{record.director}</div>;
      },
    },
    {
      title: "メンバー数",
      dataIndex: "numberMember",
      width: 180,
      render: (_, record) => {
        return <div style={{ color: "#222" }}>{record.numberMember}人</div>;
      },
    },
    {
      title: "操作",
      width: 130,
      render: (record: any, _, index: number) => {
        return (
          <div className="wrapper-action-aop001">
            {record.code !== SYSTEM_ROLES.ADMIN &&
              permission?.includes(AUTHORIZATION_CODE.S26) ? (
              <img
                src={EditIcon}
                alt="edit icon"
                onClick={() => handler.redirectAOP003(record.key)}
              />
            ) : null}

            <img
              src={DetailIcon}
              alt="detail icon"
              onClick={() =>
                handler.redirectAOP004(record.key, record.director)
              }
            />
            {record.code !== SYSTEM_ROLES.EMPLOYEE
              ? record.code !== SYSTEM_ROLES.ADMIN &&
              permission?.includes(AUTHORIZATION_CODE.S26) && (
                <img
                  src={DeleteIcon}
                  alt="delete icon"
                  onClick={() => handler.deleteRole(record.key)}
                />
              )
              : null}
          </div>
        );
      },
    },
  ];
  const rowSelection = {
    ...handler.selectedRowsKey,
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataTableType[]) => {
      handler.setStateSelectedRowsKey(selectedRowKeys);
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.code === SYSTEM_ROLES.EMPLOYEE,
    }),
  };

  return (
    <div className="aop001-table">
      <Table
        rowSelection={{
          ...rowSelection,
        }}
        columns={columns}
        dataSource={handler.dataTable}
        pagination={false}
        rowClassName={(record: any, index: number) =>
          record.code === SYSTEM_ROLES.EMPLOYEE ? "hide-checkbox" : ""
        }
        ref={handler.refTable}
        scroll={{
          scrollToFirstRowOnChange: true,
          x: "max-content",
          y: "60vh",
        }}
      />
    </div>
  );
};

export default TableComponent;
