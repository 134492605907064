import moment from "moment";
import helpers from "../../../../../common/helpers/common";
import { CM017_2SliceType } from "./../type/Presenter";
import { createSlice } from "@reduxjs/toolkit";

const initialState: CM017_2SliceType = {
  loading: false,
  isVisible: false,
  refresh: false,
  typeModal: false,
  parentCode: {
    from: helpers.getFilterTimeMonth(moment()),
    to: helpers.getFilterTimeMonthTo(moment()),
  },
  isUpdate: true,
  modalTitle: null,
};

export const cm017_2Slice = createSlice({
  name: "cm017_2",
  initialState,
  reducers: {
    resetCM017_2: () => initialState,
    setLoadingCM017_2: (state, action) => {
      state.loading = action.payload;
    },
    setIsVisibleCM017_2: (state, action) => {
      state.isVisible = action.payload;
    },
    setRefreshCM017_2: (state) => {
      state.refresh = !state.refresh;
    },
    setTypeModalCM017_2: (state, action) => {
      state.typeModal = action.payload;
    },
    setParentCodeCM017_2: (state, action) => {
      state.parentCode = action.payload;
    },
    setIsUpdate: (state, action) => {
      state.isUpdate = action.payload;
    },
    setModalTitleCM017_2: (state, action) => {
      state.modalTitle = action.payload;
    },
  },
});

export const {
  setIsUpdate,
  setLoadingCM017_2,
  setIsVisibleCM017_2,
  resetCM017_2,
  setRefreshCM017_2,
  setTypeModalCM017_2,
  setParentCodeCM017_2,
  setModalTitleCM017_2,
} = cm017_2Slice.actions;

export default cm017_2Slice.reducer;
