import { AxiosResponse } from "axios";
import axiosConfig from "../../../../../config/axiosConfig";

const DoubleCheckApi = () => {
  const postAttendanceSubConstructionMemberDoubleCheck = async (data: {
    constructionId: number;
    doubleCheck: null | boolean;
    userTimeSheetId: number;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/attendance/subconstruction/member/double-check";
    return await axiosConfig.post(url, data);
  };

  return {
    postAttendanceSubConstructionMemberDoubleCheck,
  };
};

export default DoubleCheckApi;
