import React, { useEffect } from "react";
import { Handler } from "../handler/HandlerImpl";
import "../../../../../assets/styles/IC/IC1.css";
import MenuWrapperComponent from "../../../../../common/components/menu-wrapper";
import BreadcrumbComponent from "../../../../../common/components/breadcrumb/BreadcrumbComponent";
import { useSearchParams } from "react-router-dom";
import {
  DEFAULT_TAB_KEY,
  DEFAULT_TAB_VALUE,
  TYPE_COMPANY_INFOR,
  TYPE_PARAM,
} from "../handler/Handler";
import { Tabs } from "antd";
import InformationComponent from "./InformationComponent";
import IC3Component from "../../../IC3";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { AUTHORIZATION_CODE } from "../../../../../common/constants/AUTHORIZATION";
import EditInformation from "./EditInformation";
import helpers from "../../../../../common/helpers/common";
import HomeSvg from "../../../../../assets/icons/home.svg"

const { TabPane } = Tabs;

interface props {
  handler: Handler;
}
const BREADCRUMB_IC1 = [
  {
    title: "設定",
    url: "",
    icon: <img style={{ paddingBottom: 2, paddingRight: 8 }} alt="" src={HomeSvg} />,
  },
  {
    title: "会社情報",
    url: "",
    icon: "",
  },
];
function MainUI({ handler }: props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get(DEFAULT_TAB_KEY) ?? DEFAULT_TAB_VALUE;
  const role = useSelector(
    (state: RootState) => state.auth.authData?.role.code
  );
  const typeInformationCompany =
    searchParams.get(TYPE_PARAM) || TYPE_COMPANY_INFOR.view;
  useEffect(() => {
    handler.getCompanyProfile();
  }, [typeInformationCompany]);

  useEffect(() => {
    if (handler.isEdit) {
      window.addEventListener("beforeunload", helpers.alertUser);
      return () => {
        window.removeEventListener("beforeunload", helpers.alertUser);
      };
    } else {
      window.removeEventListener("beforeunload", helpers.alertUser, true);
    }
  }, [handler.isEdit]);

  useEffect(() => {
    searchParams.set(TYPE_PARAM, TYPE_COMPANY_INFOR.view);
    setSearchParams(searchParams);
  }, []);

  return (
    <MenuWrapperComponent
      chosenKey={77}
      openKey={7}
      onClickMenu={handler.onClickMenu}
    >
      <div className="ic1-wrapper">
        <BreadcrumbComponent data={BREADCRUMB_IC1} />
        <h2 className="ic1-header">会社情報</h2>
        <Tabs
          type="card"
          onChange={handler.switchTab}
          className="am028"
          activeKey={tab}
          defaultActiveKey={DEFAULT_TAB_VALUE}
        >
          <TabPane tab="会社情報" key="companyInfor">
            {handler.typeInformationCompany === TYPE_COMPANY_INFOR.edit ? (
              <EditInformation handler={handler} />
            ) : (
              <InformationComponent handler={handler} />
            )}
          </TabPane>
          {role?.includes(AUTHORIZATION_CODE.ADMIN) ? (
            <TabPane tab="プラン情報" key="planInfor">
              <IC3Component />
            </TabPane>
          ) : null}
        </Tabs>
      </div>
    </MenuWrapperComponent>
  );
}

export default MainUI;
