import {
  CODE_ONE,
  CODE_TWO,
  CODE_THREE,
  CODE_FOUR,
  CODE_FIVE,
  CODE_SIX,
} from "./presenter/handler/Handler";
import moment from "moment";
import helpers from "../../../common/helpers/common";
import {
  CM001ElementData,
  CM001ElementInnerData,
  CM001ProjectSummaryCost,
  CM001TitleData,
  CM002ElementData,
} from "./entity/Entity";
import COMMON from "../../../common/constants/COMMON";

const subtractAmount = (a: number, b: number): number => {
  return a - b;
};

const MATERIAL_COST_KEY = "materialCost";
const OUTSOURCE_COST_KEY = "outsourceCost";
const LABOR_COST_KEY = "laborCost";
const INDIRECT_COST_KEY = "indirectCost";
const TOTAL_COST_KEY = "totalCost";

const mappingDataProjectSummary = (
  data: CM001ProjectSummaryCost,
  contractAmount: number,
  billingVolume: number
): CM001ElementData[] => {
  const resp: CM001ElementData[] = [];
  let sumPlanCost = 0;
  let sumActualCost = 0;

  for (const [key, value] of Object.entries(data)) {
    const innerObject: CM001ElementInnerData = {
      planCost: value?.planCost,
      actualCost: value?.actualCost,
      balanceCost: value?.planCost - value?.actualCost,
      threshold: value?.threshold,
      threshold_view: helpers.calculateRateAmount(
        value?.planCost,
        value?.actualCost
      ),
      enable: value?.enable,
    };

    switch (key) {
      case MATERIAL_COST_KEY:
        resp.push({
          name: "材料費",
          code: CODE_ONE,
          ...innerObject,
        });
        break;
      case OUTSOURCE_COST_KEY:
        resp.push({
          name: "外注費",
          code: CODE_TWO,
          ...innerObject,
        });
        break;
      case LABOR_COST_KEY:
        resp.push({
          name: "労務費",
          code: CODE_THREE,
          ...innerObject,
        });
        break;
      case INDIRECT_COST_KEY:
        // For indirect cost
        resp.push({
          name: "間接工事費",
          code: CODE_FOUR,
          ...innerObject,
        });
        break;
      case TOTAL_COST_KEY:
        resp.push({
          name: "総工事費合計",
          code: CODE_FIVE,
          ...innerObject,
        });
        sumPlanCost = value?.planCost ?? 0;
        sumActualCost = value?.actualCost ?? 0;

        break;
    }
  }

  resp.push({
    name: "粗利",
    code: CODE_SIX,
    planCost: contractAmount - sumPlanCost,
    actualCost: billingVolume - sumActualCost,
    balanceCost: 0,
    threshold: 0,
    threshold_view: "0",
    enable: false,
  });
  return resp;
};

const mappingListConstructionData = (data: any[]): CM002ElementData[] => {
  const resp: CM002ElementData[] = [];

  data.forEach((item: any, idx: number) => {
    const inner: CM002ElementData = {
      constructionId: Number(item?.constructionId),
      constructionName: item?.constructionName,
      contractCreateDate: item?.contractCreateDate
        ? moment(item?.contractCreateDate).format(COMMON.FORMAT_DATE_CM)
        : "",
      contractAmount: `${helpers.formatNumberWithCommas(
        item?.contractAmount
      )}円`,
      billingVolume: `${helpers.formatNumberWithCommas(item?.billingVolume)}円`,
      balanceCost: `${helpers.formatNumberWithCommas(
        item?.contractAmount - item?.billingVolume
      )}円`,
      threshold_view: `${helpers.calculateRateAmount(
        item?.contractAmount,
        item?.billingVolume
      )}%`,
      threshold: item?.threshold,
      enable: item?.enable,
    };
    resp.push(inner);
  });

  return resp;
};

const convertDataExportCM001 = (
  dataSummary: CM001TitleData,
  dataSummaryCost: CM001ProjectSummaryCost
) => {
  const elementData: any[] = [];

  mappingDataProjectSummary(
    dataSummaryCost,
    dataSummary?.contractAmount,
    dataSummary?.billingVolume
  ).forEach((item: any) => {
    const element: any = {
      budget_amount: `${helpers.formatCurrency(item.planCost)}円`,
      actual_cost: `${helpers.formatCurrency(item.actualCost)}円`,
      budget_balance: `${helpers.formatCurrency(item.balanceCost)}円`,
      budget_util_rate: `${item.threshold_view}%`,
    };

    switch (item.code) {
      case "CODE_ONE":
        elementData.push({
          name: "材料費",
          ...element,
        });
        break;
      case "CODE_TWO":
        elementData.push({
          name: "外注費",
          ...element,
        });
        break;
      case "CODE_THREE":
        elementData.push({
          name: "労務費",
          ...element,
        });
        break;
      case "CODE_FOUR":
        elementData.push({
          name: "間接工事費",
          ...element,
        });
        break;
      case "CODE_FIVE":
        elementData.push({
          name: "総工事費合計",
          ...element,
        });
        break;

      default:
        elementData.push({
          name: "粗利",
          ...element,
          budget_balance: ``,
          budget_util_rate: ``,
        });
        break;
    }
  });

  const come_out_rate = `${helpers.calculateRateAmount(
    dataSummary.contractAmount,
    dataSummary.billingVolume
  )}%`;
  const bill_balance = `${helpers.formatCurrency(
    dataSummary.contractAmount - dataSummary.billingVolume
  )}円`;

  const dataExport: any = {
    information: {
      headers: ["契約金額", "請求出来高", "請求出来高(％)", "請求残金"],
      contents: [
        {
          contract_amount: `${helpers.formatCurrency(
            dataSummary.contractAmount
          )}円`,
          request_come_out: `${helpers.formatCurrency(
            dataSummary.billingVolume
          )}円`,
          request_come_out_rate: come_out_rate,
          bill_balance: bill_balance,
        },
      ],
    },
    content: {
      headers: ["", "予算金額", "実費用", "予算残高", "予算消化率(％)"],
      elements: elementData,
    },
  };

  return dataExport;
};

const convertDataExportCM002 = (summaryListConstruction: any[]) => {
  const elements: any[] = [];

  summaryListConstruction.forEach((ele: any, idx: number) => {
    elements.push({
      construction_name: ele?.constructionName ?? "",
      contract_date: ele?.contractCreateDate ?? "",
      contract_amount: ele?.contractAmount ?? "",
      request_come_out: ele?.billingVolume ?? "",
      request_come_out_rate: ele?.threshold_view ?? "",
      bill_balance: ele?.balanceCost ?? "",
    });
  });

  const dataExport: any = {
    information: {},
    content: {
      headers: [
        "No",
        "工事名",
        "契約日",
        "契約金額",
        "請求出来高",
        "請求出来高(％)",
        "請求残金",
      ],
      elements: elements,
    },
  };

  return dataExport;
};

export {
  subtractAmount,
  mappingDataProjectSummary,
  mappingListConstructionData,
  convertDataExportCM001,
  convertDataExportCM002,
};
