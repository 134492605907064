import { RootState } from "../../../../../../../store";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import ErrorNotification from "../../../../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../../../../common/constants/MESSAGE";
import { MemberType } from "../../../../entity/Entity";
import { SubConstructionType } from "../../entity/Entity";
import { CollapseContractorUseCase } from "../../usecase/ServiceImpl";
import {
  addTotalMember,
  setLoadingPM011,
  setNumberCheckBox,
  setNumberChosenCheckBox,
  updateConstructionCountMembers,
} from "../../../../presenter/slice/Slice";
import { FormInstance } from "antd";

const CollapseContractorHandler = (
  collapseContractorService: CollapseContractorUseCase,
  subconstruction: SubConstructionType,
  form: FormInstance<any> | undefined,
  parentSubConstructionId: number
) => {
  const dispatch = useDispatch();
  const [members, setMembers] = useState<MemberType[]>([]);
  const [chosenMembers, setChosenMembers] = useState<MemberType[]>([]);
  const [isFetched, setIsFetched] = useState<boolean>(false);
  const [activeKey, setActiveKey] = useState<string[]>([]);

  const constructionCompany = useSelector(
    (state: RootState) => state.pm011.constructionCompany
  );

  const onOpenCollapse = async (value: any) => {
    setActiveKey(value);
  };

  const onCompanyOpen = async (constructionId: number) => {
    if (members.length === 0) return;
    const foundConstruction = constructionCompany.find(
      (element) => element.constructionId === constructionId
    );
    if (foundConstruction && foundConstruction.isOpenAll)
      return setActiveKey([
        subconstruction.id + "" + subconstruction.treeLevel,
      ]);

    return setActiveKey([]);
  };

  const getListMember = async (constructionId: number) => {
    if (isFetched) return;
    dispatch(setLoadingPM011(true));
    try {
      const subConstructionMembers =
        await collapseContractorService.getListContractorMember({
          constructionId: subconstruction.childConstructionId,
          parentSubConstructionId,
        });

      setIsFetched(true);
      if (subConstructionMembers.length === 0) return;
      // setActiveKey([subconstruction.id + "" + subconstruction.treeLevel]);
      setMembers(subConstructionMembers);
      const numberNotApproved = subConstructionMembers.filter(
        (element: any) => !element.approve
      );
      dispatch(addTotalMember(subConstructionMembers.length));
      dispatch(setNumberCheckBox(numberNotApproved.length));
      dispatch(
        updateConstructionCountMembers({
          id: constructionId,
          totalMember: subConstructionMembers.length,
        })
      );
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoadingPM011(false));
    }
  };

  const onChooseCheckbox = (value: any) => {
    const chosenCheckboxes: any[] = [];
    value.forEach((element: any) => {
      const checkedMember = members.find(
        (member: any) => member.id === element
      );
      if (checkedMember) chosenCheckboxes.push(checkedMember);
    });
    if (chosenCheckboxes.length > chosenMembers.length)
      dispatch(setNumberChosenCheckBox(1));
    else dispatch(setNumberChosenCheckBox(-1));
    setChosenMembers(chosenCheckboxes);
  };

  const setAllCheckBox = (value: boolean) => {
    if (value)
      setChosenMembers(members.filter((element: any) => !element.approve));
    else setChosenMembers([]);
  };

  const onPreview = (name: string, value: unknown) => {
    form?.setFieldValue(name, value);
  };

  return {
    members,
    chosenMembers,
    activeKey,
    onOpenCollapse,
    onChooseCheckbox,
    setAllCheckBox,
    onCompanyOpen,
    onPreview,
    getListMember,
  };
};

export default CollapseContractorHandler;
