import CM0017_3_2Api from './infrastructure/api/Api';
import CM0017_3_2Handler from './presenter/handler/Handler';
import CM0017_3_2UI from './presenter/ui/UI';
import CM0017_3_2Service from './usecase/Service';

const CM0017_3_2Component = () => {
    const cm0017_3_2Api = CM0017_3_2Api();
    const cm0017_3_2Service = CM0017_3_2Service(cm0017_3_2Api);
    const cm0017_3_2Handler = CM0017_3_2Handler(cm0017_3_2Service);
    return <CM0017_3_2UI handler={cm0017_3_2Handler} />;
};

export default CM0017_3_2Component;
