import { AxiosResponse } from 'axios';
import axiosConfig from '../../../../../config/axiosConfig';
import { ThresholdPutType } from '../../entity/Entity';

const MaterialCostApi = () => {
    const getMoneySummaryConstructionCostInformation = async (params: {
        constructionId: number;
    }): Promise<AxiosResponse<any, any>> => {
        const url = '/money/summary/construction/cost/information';
        return await axiosConfig.get(url, { params });
    };
    const putMoneySummaryConstructionCostDirectThresholdUpdate = async (
        data: ThresholdPutType,
    ): Promise<AxiosResponse<any, any>> => {
        const url = '/money/summary/construction/cost/direct/threshold/update';
        return await axiosConfig.put(url, data);
    };
    const putMoneySummaryConstructionCostMaterialThresholdUpdate = async (
        data: ThresholdPutType,
    ): Promise<AxiosResponse<any, any>> => {
        const url = '/money/summary/construction/cost/material/threshold/update';
        return await axiosConfig.put(url, data);
    };
    const putMoneySummaryConstructionCostOutsourceThresholdUpdate = async (
        data: ThresholdPutType,
    ): Promise<AxiosResponse<any, any>> => {
        const url = '/money/summary/construction/cost/outsource/threshold/update';
        return await axiosConfig.put(url, data);
    };
    const putMoneySummaryConstructionCostLaborThresholdUpdate = async (
        data: ThresholdPutType,
    ): Promise<AxiosResponse<any, any>> => {
        const url = '/money/summary/construction/cost/labor/threshold/update';
        return await axiosConfig.put(url, data);
    };
    const putMoneySummaryConstructionCostIndirectThresholdUpdate = async (
        data: ThresholdPutType,
    ): Promise<AxiosResponse<any, any>> => {
        const url = '/money/summary/construction/cost/indirect/threshold/update';
        return await axiosConfig.put(url, data);
    };

    const doExportCM004 = async (params: any): Promise<any> => {
        try {
            const url = '/money/exportCM004';
            const response = await axiosConfig.get(url, { params, responseType: 'blob'  });
            
            return response;
        } catch (error) {
            throw error;
        }
    };

    return {
        getMoneySummaryConstructionCostInformation,
        putMoneySummaryConstructionCostDirectThresholdUpdate,
        putMoneySummaryConstructionCostMaterialThresholdUpdate,
        putMoneySummaryConstructionCostOutsourceThresholdUpdate,
        putMoneySummaryConstructionCostLaborThresholdUpdate,
        putMoneySummaryConstructionCostIndirectThresholdUpdate,
        doExportCM004,
    };
};

export default MaterialCostApi;
