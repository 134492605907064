import { RootState } from "./../../../../../store";
import { useState } from "react";
import { Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import { OM004UseCase, OM006UseCase } from "../../usecase/ServiceImpl";
import { setLoading, resetOM006 } from "../slice/Slice";
import {
  CertificateMemberEntity,
  DetailMemberEntity,
  MemberUpdateEntity,
} from "../../entity/Entity";
import ConfirmModal from "../../../../../common/components/modal/ConfirmModal";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import {
  CertificateEntity,
  MemberCertificateEntity,
} from "../../../OM004/entity/Entity";
import moment from "moment";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import helpers from "../../../../../common/helpers/common";
import { text } from "../../../../../common/global";

export const CERT_LINE_DEFAULT = {
  isDone: false,
  lines: {},
};

const DEFAULT_STATE = {
  certLines: {
    isDone: false,
    lines: {},
  },
  certType: [],
  memberName: "",
  detailMember: null,
  isDirty: false,
};

const OM006Handler = (
  om006Service: OM006UseCase,
  om004Service: OM004UseCase
) => {
  // LIB FUNCTION
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const memberId = useSelector((state: RootState) => state.om006.memberId);
  const [fileData, setFileData] = useState<any>();

  // STATE
  const [isDirty, setIsDirty] = useState(DEFAULT_STATE.isDirty);
  const [certType, setCertType] = useState<CertificateEntity[]>(
    DEFAULT_STATE.certType
  );
  const [detailMember, setDetailMember] = useState<DetailMemberEntity | null>(
    DEFAULT_STATE.detailMember
  );
  const [certLines, setCertLines] = useState<any>(DEFAULT_STATE.certLines);
  const [memberName, setMemberName] = useState<string>(
    DEFAULT_STATE.memberName
  );

  // FUNCTION
  const getAvatarBinary = (value: any) => {
    setFileData(value);
  };
  const onNameChange = (e: any) => {
    setMemberName(e.target.value);
  };
  // FUNCTION

  const getGroups = async () => {
    dispatch(setLoading(true));
    try {
      const response = await om004Service.getGroup();
      if (response?.results?.groups?.length > 0) {
        return {
          results: response?.results?.groups,
          pagination: {
            count: response?.results?.groups?.length,
          },
        };
      }
      return {
        results: response?.results?.groups,
        pagination: {
          count: response?.results?.groups?.length,
        },
      };
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const setInitalForm = (detailMember: DetailMemberEntity | null) => {
    if (!detailMember) return;
    // member info
    setMemberName(detailMember.fullName);

    // basic info
    const defaultValue: any = {
      name: detailMember.fullName,
      id: detailMember.username,
      email: detailMember.emailAddress,
      phoneNumber: helpers.toPhoneNumberFormat(detailMember.phoneNumber),
      address: detailMember.address,
    };

    // group
    if (detailMember?.group?.id)
      defaultValue.branchId = detailMember.group.id + "";

    // role
    if (detailMember?.role?.id) {
      defaultValue.roleId = detailMember.role.id + "";
    }

    // CCUSID
    if (detailMember.ccusId) {
      defaultValue.CCUSID = helpers.convertToCCUSFormat(detailMember.ccusId);
      if (detailMember.ccusExpiredDate)
        defaultValue.expirationDate = moment(detailMember.ccusExpiredDate);
    }

    if (detailMember.certificates) {
      // certifactes

      const certTypes: any = {};
      detailMember.certificates.forEach((element: CertificateMemberEntity) => {
        if (certTypes[`cert${element.category.id}`])
          certTypes[`cert${element.category.id}`].push(element.id);
        else {
          certTypes[`cert${element.category.id}`] = [element.id];
        }
        defaultValue[`cert${element.category.id}${element.id}`] = {
          name: element.name,
          issueDate: element?.issueDate ? moment(element.issueDate) : null,
          registrationCode: element.registrationCode,
          issuer: element.issuer,
          expireDate: element?.expireDate ? moment(element.expireDate) : null,
          source: element?.source
        };
      });
      setCertLines({
        isDone: true,
        lines: certTypes,
      });
      setCertLines((prevState: any) => ({ ...prevState, lines: certTypes }));
    }
    form.setFieldsValue(defaultValue);
    setCertLines((prevState: any) => ({
      ...prevState,
      isDone: true,
    }));
  };
  const getMemberDetail = async () => {
    try {
      dispatch(setLoading(true));
      const detailMemberData = await om006Service.getDetailMember({
        id: memberId,
      });
      setInitalForm(detailMemberData);
      setDetailMember(detailMemberData);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getCertificateType = async () => {
    try {
      dispatch(setLoading(true));
      const certificateTypes = await om006Service.getCertificateType();
      setCertType(certificateTypes);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getRoleList = async (params: any) => {
    const response = await om006Service.getRoleList(params);
    return {
      results: response.results?.filter((element) => element?.code !== "admin"),
      pagination: response.pagination,
    };
  };

  const getBranchList = async (params: any) => {
    return await om006Service.getBranchList(params);
  };

  // Trường hợp nếu không truyền avatar vào thì sao
  const updateMember = async (formData: any) => {
    try {
      dispatch(setLoading(true));

      if (!detailMember?.id) return;
      // avatar
      const avatarFormValueArr = formData?.avatar ?? [];
      const avatarFileName =
        avatarFormValueArr.length === 0
          ? undefined
          : avatarFormValueArr[avatarFormValueArr.length - 1];

      // certificates
      const memberCertificates: MemberCertificateEntity[] = [];
      const formProperties = Object.keys(formData);
      formProperties.forEach((element: string) => {
        if (element.startsWith("cert") && formData[`${element}`].name) {
          memberCertificates.push({
            categoryId: parseInt(formData[`${element}`].certTypeId),
            expireDate: formData[`${element}`].expireDate
              ? formData[`${element}`].expireDate.toISOString()
              : "",
            id: parseInt(formData[`${element}`].certId),
            issueDate: formData[`${element}`].issueDate
              ? formData[`${element}`].issueDate.toISOString()
              : "",
            issuer: formData[`${element}`].issuer ?? "",
            registrationCode: formData[`${element}`].registrationCode ?? "",
            name: formData[`${element}`].name ?? "",
            source: formData[`${element}`].source ?? text('MEMBER.manual')
          });
        }
      });

      const data: MemberUpdateEntity = {
        avatar: avatarFileName?.name ?? undefined,
        id: detailMember?.id ?? 0,
        certificates: memberCertificates,
        email: formData.email,
        fullName: formData.name,
        username: formData.id,
        phoneNumber: formData.phoneNumber.replaceAll("-", ""),
        roleId: formData.roleId
          ? Number(formData.roleId)
          : detailMember?.role?.id,
        address: formData?.address ?? "",
        ccusId: formData.CCUSID || "",
        ccusExpiredDate: formData?.expirationDate?.toISOString() || "",
      };

      if (formData.branchId) data.groupId = Number(formData.branchId);
      const response = await om006Service.updateMember(data);
      if (formData.avatar) {
        // If user wants to update avatar
        const preSignResult = await om004Service.getPresignAvatarUpload({
          avatar: avatarFileName.name,
          userId: detailMember.id,
        });
        await om004Service.uploadAvatar(
          fileData,
          preSignResult.results,
          fileData?.type
        );
        await om004Service.updateMemberAvatar({
          avatar: fileData?.name ?? "",
          userId: detailMember.id,
        });
      } else if (!formData.avatar && !formData.linkAvatar) {
        await om004Service.updateMemberAvatar({
          avatar: "",
          userId: detailMember.id,
        });
      }
      resetState();
      SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const resetState = () => {
    form.resetFields();
    setIsDirty(DEFAULT_STATE.isDirty);
    setCertType(DEFAULT_STATE.certType);
    setDetailMember(DEFAULT_STATE.detailMember);
    setCertLines(DEFAULT_STATE.certLines);
    setMemberName(DEFAULT_STATE.memberName);
    dispatch(resetOM006());
  };

  const onCancel = () => {
    if (!isDirty) {
      resetState();
      return;
    }
    ConfirmModal({
      onOk: () => resetState(),
      className: "confirm__modal",
      title: MESSAGE.MESSAGE_020,
      description: MESSAGE.MESSAGE_022_1,
      extraDescription: MESSAGE.MESSAGE_022_2,
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_MODAL,
      isCenterWithoutMenu: true,
    });
  };

  const checkDirty = (): void => {
    const formValue = form.getFieldsValue();
    const formProps = Object.keys(formValue);
    for (let i = 0; i < formProps.length; i++) {
      if (form.isFieldTouched(formProps[i])) {
        return setIsDirty(true);
      }
    }
    setIsDirty(false);
  };
  const onExpirationDateChange = (value: string) => {
    const ccusid = form.getFieldValue("CCUSID");
    if (!ccusid && !value)
      return form.setFields([
        {
          name: "CCUSID",
          errors: [],
        },
      ]);

    if (!ccusid)
      return form.setFields([
        {
          name: "CCUSID",
          errors: [MESSAGE.MESSAGE_LESS_THAN_14],
        },
      ]);
  };
  return {
    getBranchList,
    getRoleList,
    updateMember,
    onCancel,
    checkDirty,
    getCertificateType,
    getMemberDetail,
    getAvatarBinary,
    onNameChange,
    onExpirationDateChange,
    getGroups,
    memberName,
    detailMember,
    form,
    isDirty,
    certType,
    certLines,
  };
};

export default OM006Handler;
