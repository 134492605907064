
  import { AM014Api } from "./ServiceImpl";
  
  const AM014Service = (am014: AM014Api) => {
    const getRequestAttendance = async (params:any): Promise<any> => {
      try{
        return await am014.getRequestAttendance(params);
      }catch(error){
        throw(error)
      }
    };
  
  
    return {
      getRequestAttendance
    };
  };
  
  export default AM014Service;
  