import { Button, Col, Row, Table, Tooltip } from "antd";
import type { ColumnsType } from "antd/es/table";

// svg
import BlueEdit from "../../../../../assets/icons/blue-edit.svg";
import IconKey from "../../../../../assets/icons/icon-key.svg";
import DangerTriangle from "../../../../../assets/icons/danger-triangle.svg";
import RedDelete from "../../../../../assets/icons/red-delete.svg";
import helpers from "../../../../../common/helpers/common";
import useWindowDimensions from "../../../../../common/helpers/windowDimension";
import { MemberEntity } from "../../entity/Entity";
import { OM002Pagination, OM002Table } from "../handler/HandlerImpl";
import OM002PaginationComponent from "./Pagination";
import moment from "moment";
import COMMON, { PLAN_TYPE } from "../../../../../common/constants/COMMON";
import { CCUS_VERRIFICATION_STATE } from "../../../../../common/constants/STATUS";
import {
  AUTHORIZATION_CODE,
  SYSTEM_ROLES,
} from "../../../../../common/constants/AUTHORIZATION";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";

type Props = {
  handlerTable: OM002Table;
  handlerPagination: OM002Pagination;
};

export const getEmailAdressToolTip = (value: string, length: number) => {
  if (!value || length < 1) return "";
  if (value.length <= length) return value;
  return (
    <Tooltip
      placement="topLeft"
      title={value}
      overlayStyle={{ whiteSpace: "nowrap" }}
    >
      {value.substring(0, length)}...
    </Tooltip>
  );
};

export const getExpirationDate = (inputDate: string) => {
  const inputMoment = moment().add(6, "months");

  return moment(inputDate) < inputMoment ? (
    <div>
      <img alt="" src={DangerTriangle} />{" "}
      <span style={{ color: "#FF0909", marginLeft: 10 }}>
        {moment(inputDate).format(COMMON.FORMAT_DATE_OM)}
      </span>
    </div>
  ) : (
    moment(inputDate).format(COMMON.FORMAT_DATE_OM)
  );
};

export const getExpirationDateCcus061 = (inputDate: string) => {
  const inputMoment = moment().add(6, "months");

  return moment(inputDate) < inputMoment ? (
    <div>
      <img alt="" src={DangerTriangle} />
      <span style={{ color: "#FF0909", marginLeft: 10 }}>
        {moment(inputDate).format(COMMON.FORMAT_DATE)}
      </span>
    </div>
  ) : (
    moment(inputDate).format(COMMON.FORMAT_DATE)
  );
};

const OM002MemberTable = ({ handlerTable, handlerPagination }: Props) => {
  const { height } = useWindowDimensions();
  const permission = useSelector(
    (state: RootState) => state.auth.authData?.permission
  );
  const isRoleAdmin = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdmin
  );

  const isOrderBasic =
    useSelector((state: RootState) => state.auth.authData?.order?.code) ===
    PLAN_TYPE.PLAN_BASIC;

  const columns: ColumnsType<MemberEntity> = [
    {
      title: "名前",
      key: "name",
      width: 150 + 24,
      render: (record: MemberEntity) => (
        <div
          style={{ color: "#605BFF", cursor: "pointer" }}
          onClick={() => handlerTable.viewDetailMember(record)}
        >
          {record.fullName}
        </div>
      ),
    },
    {
      title: "CCUS技能者ID",
      key: "CCUS",
      width: 130.5 + 24 + 20,
      render: (record: MemberEntity) => (
        <div>
          {helpers.convertToCCUSFormat(record.ccusId || "")}
          {record.status == CCUS_VERRIFICATION_STATE.DONE ? (
            <img alt="" style={{ paddingLeft: 5 }} src={IconKey} />
          ) : (
            <></>
          )}
        </div>
      ) 
     },
    {
      title: "有効期限",
      key: "expirationDate",
      width: 150 + 24,
      render: (record: MemberEntity) =>
        record.ccusExpiredDate ? getExpirationDate(record.ccusExpiredDate) : "",
    },
    isOrderBasic
      ?
      {
        title: "役職",
        key: "role",
        width: 112,

        render: (record: MemberEntity) =>
          record?.role?.name ?? "",
      }
      : {},
    {
      title: "部門",
      key: "branch",
      render: (record: MemberEntity) =>
        record?.group?.name ? record.group.name : "",
      width: 130.5,
    },
    {
      title: "電話番号",
      key: "phonenumber",
      render: (record: MemberEntity) =>
        record?.phoneNumber
          ? helpers.getPhoneNumberFormat(record.phoneNumber)
          : "",
      width: 170.5 + 24,
    },
    {
      title: "メールアドレス",
      key: "email",
      width: 130.5 + 24,
      render: (record: MemberEntity) =>
        getEmailAdressToolTip(record.emailAddress, 15),
    },
    {
      title: "操作",
      key: "operations",
      width: 100 + 16,
      render: (record: MemberEntity) => (
        <div style={{ display: "flex" }}>
          {(permission?.includes(AUTHORIZATION_CODE.M12) || isRoleAdmin) &&
            !handlerTable.checkUsingAction(record) ? (
            <>
              <Button
                onClick={() => handlerTable.updateMember(record.id)}
                style={{
                  marginRight: 20,
                  border: "none",
                  boxShadow: "none",
                  backgroundColor: "transparent",
                }}
                icon={<img alt="" src={BlueEdit} />}
              ></Button>
              <Button
                onClick={() => handlerTable.deleteMember(record)}
                style={{
                  border: "none",
                  boxShadow: "none",
                  backgroundColor: "transparent",
                }}
                icon={<img alt="" src={RedDelete} />}
              ></Button>
            </>
          ) : (
            <div></div>
          )}
        </div>
      ),
    },
  ].filter((value) => Object.keys(value).length !== 0);

  return (
    <Row className="w-100-percent member-table">
      <Col span={24}>
        <Table
          pagination={false}
          dataSource={handlerTable.groupMembers}
          showSorterTooltip={false}
          columns={columns}
          onChange={handlerTable.onTableChange}
          scroll={{
            x: "max-content",
            y:
              handlerTable.groupMembers.length > 9 && height > 900
                ? "62vh"
                : handlerTable.groupMembers.length > 8 && height < 900
                  ? "60vh"
                  : undefined,
          }}
          rowSelection={
            permission?.includes(AUTHORIZATION_CODE.M12) || isRoleAdmin
              ? {
                selectedRowKeys: handlerTable.chosenRecord,
                type: "checkbox",
                onChange: handlerTable.onSelectRow,
              }
              : undefined
          }
        />
      </Col>
      <Col
        style={{
          position: "absolute",
          bottom: 32,
          width: "100%",
          paddingRight: 48,
        }}
      >
        <OM002PaginationComponent handler={handlerPagination} />
      </Col>
    </Row>
  );
};

export default OM002MemberTable;
