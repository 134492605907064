import { useState } from "react";
import { Form } from "antd";
import { useDispatch } from "react-redux";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";

import ConfirmModal from "../../../../../common/components/modal/ConfirmModal";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import moment from "moment";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import helpers from "../../../../../common/helpers/common";
import { UseCaseInterface } from "../../usecase/ServiceImpl";
import {
  CertificateMemberEntity,
  DetailMemberEntity,
} from "../../../../OM/OM006/entity/Entity";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import {
  PlanEntity,
  PlanPerson,
  PlanSetting,
  PlanSettingPayload,
  SubscriptionPayload,
} from "../../entity/Entity";
import { uuidv4 } from "@firebase/util";

const DEFAULT_STATE = {
  isDirty: false,
  isEditing: false,
  planData: null,
  planUserAddition: [],
  isRefresh: false,
};

const Handler = (service: UseCaseInterface) => {
  // LIB FUNCTION
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  // STATE
  const [isRefresh, setIsRefresh] = useState(DEFAULT_STATE.isRefresh);
  const [isDirty, setIsDirty] = useState(DEFAULT_STATE.isDirty);
  const [isEditing, setIsEditing] = useState<boolean>(DEFAULT_STATE.isEditing);
  const [planData, setPlanData] = useState<PlanEntity | null>(
    DEFAULT_STATE.planData
  );
  const [planSetting, setPlanSetting] = useState<PlanSetting | null>(
    DEFAULT_STATE.planData
  );
  const [planUserAddition, setPlanUserAddition] = useState<string[]>(
    DEFAULT_STATE.planUserAddition
  );

  const addPlanUser = () => {
    setPlanUserAddition([...planUserAddition, uuidv4()]);
  };
  const removePlanUser = (value: string) => {
    const removedData = planUserAddition.filter((element) => element !== value);
    setPlanUserAddition(removedData);
  };

  // FUNCTION
  const getPlanSettingDetail = async () => {
    try {
      dispatch(setLoading(true));
      const results = await service.getPlanSettingDetail({});
      setPlanSetting(results);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const setInitialForm = () => {
    if (!planSetting) return;

    const subscription: any = {};
    planSetting?.basicPlan?.subscription?.forEach((element) => {
      subscription[`element${element.id}`] = element.cost;
    });

    const defaultValue: any = {
      basicPlanCost: planSetting?.basicPlan?.cost,
      subscription: subscription,
      userNumber: planSetting?.freePlan?.userNumber,
      dueDate: planSetting?.basicPlan?.dueDate,
    };

    planSetting?.basicPlan?.planPerson?.forEach((element) => {
      defaultValue[`existedPlanUsers${element.id}`] = {
        cost: element.cost,
        userNumber: element.userNumber,
        id: element.id,
      };
    });

    form.setFieldsValue(defaultValue);
  };

  const getDataORD007 = async () => {
    try {
      dispatch(setLoading(true));
      const responseData = await service.getDataORD007();
      setPlanData(responseData);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const updateDataORD007 = async (formData: any) => {
    try {
      dispatch(setLoading(true));

      const subscription: SubscriptionPayload[] = [];
      const formSubscription = formData["subscription"];
      for (let key in formSubscription) {
        subscription.push({
          id: Number(key.replaceAll("element", "")),
          cost: formSubscription[key],
        });
      }

      const planPerson: PlanPerson[] = [];

      for (let key in formData) {
        if (key.includes("existedPlanUsers")) {
          planPerson.push(formData[key]);
        }
        if (key.includes("newPlanUsers")) {
          planPerson.push({ ...formData[key], id: 0 });
        }
      }

      const freePlanId =
        planSetting?.freePlan?.userNumber === formData.userNumber
          ? planSetting?.freePlan.id ?? 0
          : 0;

      const planSettingPayload: PlanSettingPayload = {
        basicPlan: {
          cost: formData.basicPlanCost,
          dueDate: formData.dueDate,
          planPerson: planPerson,
          subscription: subscription,
        },
        freePlan: {
          id: freePlanId,
          userNumber: formData.userNumber,
        },
      };
      const response = await service.postPlanSetting(planSettingPayload);
      resetAllState();
      setIsRefresh(!isRefresh);
      SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const resetAllState = () => {
    form.resetFields();
    setIsDirty(DEFAULT_STATE.isDirty);
    setIsEditing(DEFAULT_STATE.isEditing);
    setPlanUserAddition(DEFAULT_STATE.planUserAddition);
  };

  const resetState = () => {
    form.resetFields();
    setIsDirty(DEFAULT_STATE.isDirty);
    setIsEditing(DEFAULT_STATE.isEditing);
    setPlanUserAddition(DEFAULT_STATE.planUserAddition);
  };

  const onCancel = () => {
    if (!isDirty) {
      resetState();
      return;
    }
    ConfirmModal({
      onOk: () => resetState(),
      className: "confirm__modal",
      title: MESSAGE.MESSAGE_020,
      description: MESSAGE.MESSAGE_022_1,
      extraDescription: MESSAGE.MESSAGE_022_2,
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_MODAL,
      isCenterWithoutMenu: true,
    });
  };

  const checkDirty = (): void => {
    const formValue = form.getFieldsValue();
    const formProps = Object.keys(formValue);
    for (let i = 0; i < formProps.length; i++) {
      if (form.isFieldTouched(formProps[i])) {
        return setIsDirty(true);
      }
    }
    setIsDirty(false);
  };
  const changeEditStatus = (value: boolean) => {
    if (value) setInitialForm();
    setIsEditing(value);
  };

  const onClickMenu = (callback: (value: any) => void, value: any) => {
    if (isEditing && isDirty) {
      ConfirmModal({
        onOk: () => {
          callback(value);
        },
        onCancel: () => {},
        title: MESSAGE.MESSAGE_020,
        description: MESSAGE.MESSAGE_022_1,
        extraDescription: MESSAGE.MESSAGE_022_2,
        canceText: LABEL_MESSAGE.CANCEL_MODAL,
        okText: LABEL_MESSAGE.OK_MODAL,
      });
    } else {
      callback(value);
    }
  };
  return {
    updateDataORD007,
    getDataORD007,
    onCancel,
    checkDirty,
    changeEditStatus,
    onClickMenu,
    addPlanUser,
    removePlanUser,
    getPlanSettingDetail,
    isEditing,
    form,
    isDirty,
    planData,
    planUserAddition,
    planSetting,
    isRefresh,
  };
};

export default Handler;
