import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Form } from "antd";
import ConfirmModal from "../../../../../../common/components/modal/ConfirmModal";
import MESSAGE, {
  NOTIFICATION_TITLE,
} from "../../../../../../common/constants/MESSAGE";
import { LABEL_MESSAGE } from "../../../../../../common/constants/MESSAGE";
import { SortOrder } from "antd/lib/table/interface";
import {
  setDocumentIdCM0027,
  setDocumentInfoCM0027,
  setVisibleCM0027,
} from "../../../../CM0027/presenter/slice/Slice";
import {
  setDocumentIdCM0029,
  setDocumentInfoCM0029,
  setVisibleCM0029,
} from "../../../../CM0029/presenter/slice/Slice";
import {
  setDocumentCM0031,
  setVisibleCM0031,
} from "../../../../CM0031/presenter/slice/Slice";
import {
  setInputCM0033,
  setVisibleCM0033,
} from "../../../../CM0033/presenter/slice/Slice";
import { CM0053ServiceImpl } from "../../usecase/ServiceImpl";
import { setLoading } from "../../../../../../common/slice/CommonSlice";
import moment from "moment";
import COMMON, {
  STATUS_CM_CHILD_TABLE,
} from "../../../../../../common/constants/COMMON";
import { DEFAULT_PARAM } from "../../../wrapper/presenter/handler/Handler";
import SuccessNotification from "../../../../../../common/components/notification/SuccessNotification";
import {
  setDocumentIdCM0021,
  setDocumentInfoCM0021,
  setVisibleCM0021,
} from "../../../../CM0021/presenter/slice/Slice";
import {
  setDocumentIdCM0023,
  setDocumentInfoCM0023,
  setVisibleCM0023,
} from "../../../../CM0023/presenter/slice/Slice";
import {
  setDocumentIdCM0025,
  setDocumentInfoCM0025,
  setVisibleCM0025,
} from "../../../../CM0025/presenter/slice/Slice";
import { RootState } from "../../../../../../store";
import {
  InternalApprovalPayload,
  InternalRejectPayload,
} from "../../entity/Entity";
import helpers from "../../../../../../common/helpers/common";
import { uuidv4 } from "@firebase/util";
import ErrorNotification from "../../../../../../common/components/notification/ErrorNotification";

const PARAMS = {
  sortType81: "sortType81",
  sortType82: "sortType82",
  sortType83: "sortType83",
  sortType84: "sortType84",
  sortType85: "sortType85",
  from: "from8",
  to: "to8",
  clear: "clear8",
  status: "status8",
  keyword: "keyword8",
};

type SelectedRow = {
  materialCost: React.Key[];
  outsourcingCost: React.Key[];
  laborCost: React.Key[];
  indirectCost: React.Key[];
  receive: React.Key[];
};

const SELECTED_ROW: SelectedRow = {
  materialCost: [],
  outsourcingCost: [],
  laborCost: [],
  indirectCost: [],
  receive: [],
};

type Data = {
  material: any[];
  outsource: any[];
  labor: any[];
  indirect: any[];
  receive: any[];
};

const DEFAULT_DATA: Data = {
  material: [],
  outsource: [],
  labor: [],
  indirect: [],
  receive: [],
};

export const MATERIAL_COST = "materialCost";
export const OUTSOURCING_COST = "outsourcingCost";
export const LABOR_COST = "laborCost";
export const INDIRECT_COST = "indirectCost";
export const RECEIVE_MONEY = "receive";

const TYPE_FILE = {
  company: "re_summary_document_company",
  project: "re_summary_document_project",
};

const CM053Handler = (service: CM0053ServiceImpl) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const sortType81 = searchParams.get(PARAMS.sortType81);
  const sortType82 = searchParams.get(PARAMS.sortType82);
  const sortType83 = searchParams.get(PARAMS.sortType83);
  const sortType84 = searchParams.get(PARAMS.sortType84);
  const sortType85 = searchParams.get(PARAMS.sortType85);
  const from = searchParams.get(PARAMS.from) ?? DEFAULT_PARAM.from;
  const to = searchParams.get(PARAMS.to) ?? DEFAULT_PARAM.to;
  const clear = searchParams.get(PARAMS.clear);
  const status = searchParams.get(PARAMS.status);
  const keyword = searchParams.get(PARAMS.keyword);

  const [selectedRow, setSelectedRow] = useState<SelectedRow>(SELECTED_ROW);
  const [visibleReject, setVisibleReject] = useState(false);
  const [visibleRejectView, setVisibleRejectView] = useState(false);
  const [isChangeValueReject, setIsChangeValueReject] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [data, setData] = useState(DEFAULT_DATA);
  const [refresh, setRefresh] = useState(false);
  const [documentType, setDocumentType] = useState("");

  const visibleCM0021 = useSelector(
    (state: RootState) => state.cm0021.isVisibleCM0021
  );
  const visibleCM0023 = useSelector(
    (state: RootState) => state.cm0023.isVisibleCM0023
  );
  const visibleCM0025 = useSelector(
    (state: RootState) => state.cm0025.isVisibleCM0025
  );
  const visibleCM0027 = useSelector(
    (state: RootState) => state.cm0027.isVisibleCM0027
  );
  const visibleCM0029 = useSelector(
    (state: RootState) => state.cm0029.isVisibleCM0029
  );
  const visibleCM0031 = useSelector(
    (state: RootState) => state.cm0031.isVisibleCM0031
  );
  const visibleCM0033 = useSelector(
    (state: RootState) => state.cm0033.isVisibleCM0033
  );

  const documentIdCM0021 = useSelector(
    (state: RootState) => state.cm0021.documentId
  );
  const documentIdCM0023 = useSelector(
    (state: RootState) => state.cm0023.documentId
  );
  const documentIdCM0025 = useSelector(
    (state: RootState) => state.cm0025.documentId
  );
  const documentIdCM0027 = useSelector(
    (state: RootState) => state.cm0027.documentId
  );
  const documentIdCM0029 = useSelector(
    (state: RootState) => state.cm0029.documentId
  );
  const document = useSelector((state: RootState) => state.cm0031.document);
  const document33 = useSelector((state: RootState) => state.cm0033.input);

  const [formReject] = Form.useForm();

  const getDefaultSelected = (data: any[]) => {
    const results = [];
    for (const item of data) {
      if (
        item.status.code !==
        STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE
      ) {
        results.push(item.key);
      }
    }
    return results;
  };

  const funcAddKeyData = (data: any[]) => {
    if (!data) return [];
    return data.map((item) => {
      return {
        ...item,
        key: uuidv4(),
      };
    });
  };

  const getMaterialReviewList = async () => {
    try {
      dispatch(setLoading(true));
      const response = await service.getMaterialReviewList();
      if (response?.results) {
        const results = funcAddKeyData(response.results);
        setData((prevState) => {
          return { ...prevState, material: results };
        });
        setSelectedRow((prevState) => {
          return { ...prevState, materialCost: getDefaultSelected(results) };
        });
      } else
        setData((prevState) => {
          return { ...prevState, material: [] };
        });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getOutsourceReviewList = async () => {
    try {
      dispatch(setLoading(true));
      const response = await service.getOutsourceReviewList();
      if (response?.results) {
        const results = funcAddKeyData(response.results);
        setData((prevState) => {
          return { ...prevState, outsource: results };
        });
        setSelectedRow((prevState) => {
          return { ...prevState, outsourcingCost: getDefaultSelected(results) };
        });
      } else
        setData((prevState) => {
          return { ...prevState, outsource: [] };
        });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getLaborReviewList = async () => {
    try {
      dispatch(setLoading(true));
      const response = await service.getLaborReviewList();
      if (response?.results) {
        const results = funcAddKeyData(response.results);
        setData((prevState) => {
          return { ...prevState, labor: results };
        });
        setSelectedRow((prevState) => {
          return { ...prevState, laborCost: getDefaultSelected(results) };
        });
      } else
        setData((prevState) => {
          return { ...prevState, labor: [] };
        });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getIndirectReviewList = async () => {
    try {
      dispatch(setLoading(true));
      const response = await service.getIndirectReviewList();
      if (response?.results) {
        const results = funcAddKeyData(response.results);
        setData((prevState) => {
          return { ...prevState, indirect: results };
        });
        setSelectedRow((prevState) => {
          return { ...prevState, indirectCost: getDefaultSelected(results) };
        });
      } else
        setData((prevState) => {
          return { ...prevState, indirect: [] };
        });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getReceiveMoneyReviewList = async () => {
    try {
      dispatch(setLoading(true));
      const response = await service.getReceiveMoneyReviewList();
      if (response?.results) {
        const results = funcAddKeyData(response.results);
        setData((prevState) => {
          return { ...prevState, receive: results };
        });
        setSelectedRow((prevState) => {
          return { ...prevState, receive: getDefaultSelected(results) };
        });
      } else
        setData((prevState) => {
          return { ...prevState, receive: [] };
        });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleChangeSelectedRow = (
    selectedRowKeys: React.Key[],
    type: string
  ) => {
    switch (type) {
      case MATERIAL_COST:
        setSelectedRow({ ...selectedRow, materialCost: selectedRowKeys });
        break;
      case OUTSOURCING_COST:
        setSelectedRow({ ...selectedRow, outsourcingCost: selectedRowKeys });
        break;
      case LABOR_COST:
        setSelectedRow({ ...selectedRow, laborCost: selectedRowKeys });
        break;
      case INDIRECT_COST:
        setSelectedRow({ ...selectedRow, indirectCost: selectedRowKeys });
        break;
      case RECEIVE_MONEY:
        setSelectedRow({ ...selectedRow, receive: selectedRowKeys });
        break;
    }
  };

  const handleCancelReject = () => {
    if (isChangeValueReject) {
      ConfirmModal({
        onOk: () => {
          setVisibleReject(false);
          formReject.resetFields()
          setIsChangeValueReject(false)
        },
        className: "confirm__modal",
        title: MESSAGE.MESSAGE_020,
        description: MESSAGE.MESSAGE_021,
        canceText: LABEL_MESSAGE.CANCEL_MODAL,
        okText: LABEL_MESSAGE.OK_MODAL,
        isCenterWithoutMenu: true,
      });
    } else {
      setVisibleReject(false);
    }
  };
  const handleSubmitReject = async (value: any) => {
    try {
      dispatch(setLoading(true));
      const dataSubmit: InternalRejectPayload = {
        indirectData: {
          documentData: [],
          reason: value.rejectReason,
        },
        laborData: {
          documentData: [],
          reason: value.rejectReason,
        },
        materialData: {
          documentData: [],
          reason: value.rejectReason,
        },
        outsourceData: {
          documentData: [],
          reason: value.rejectReason,
        },
        receiveMoneyData: {
          documentData: [],
          reason: value.rejectReason,
        },
      };
      if (
        !visibleCM0031 &&
        !visibleCM0021 &&
        !visibleCM0023 &&
        !visibleCM0025 &&
        !visibleCM0027 &&
        !visibleCM0029 &&
        !visibleCM0033
      ) {
        for (const item of data.material)
          if (
            item?.status?.code ===
            STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE &&
            selectedRow.materialCost?.includes(item.key)
          )
            dataSubmit.materialData.documentData.push({
              documentType: item.documentType,
              id: item.id,
            });
        for (const item of data.outsource)
          if (
            item?.status?.code ===
            STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE &&
            selectedRow.outsourcingCost?.includes(item.key)
          )
            dataSubmit.outsourceData.documentData.push({
              documentType: item.documentType,
              id: item.id,
            });
        for (const item of data.labor)
          if (
            item?.status?.code ===
            STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE &&
            selectedRow.laborCost?.includes(item.key)
          )
            dataSubmit.laborData.documentData.push({
              documentType: item.documentType,
              id: item.id,
            });
        for (const item of data.indirect)
          if (
            item?.status?.code ===
            STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE &&
            selectedRow.indirectCost?.includes(item.key)
          )
            dataSubmit.indirectData.documentData.push({
              documentType: item.documentType,
              id: item.id,
            });

        for (const item of data.receive)
          if (
            item?.status?.code ===
            STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE &&
            selectedRow.receive?.includes(item.key)
          )
            dataSubmit.receiveMoneyData.documentData.push({
              documentType: item.documentType,
              id: item.id,
            });
      }
      if (visibleCM0031) {
        dataSubmit.indirectData.documentData.push({
          documentType: documentType,
          id: document.id,
        });
      }
      if (visibleCM0021 && documentIdCM0021)
        dataSubmit.materialData.documentData.push({
          documentType: documentType,
          id: documentIdCM0021,
        });
      if (visibleCM0023 && documentIdCM0023)
        dataSubmit.materialData.documentData.push({
          documentType: documentType,
          id: documentIdCM0023,
        });
      if (visibleCM0025 && documentIdCM0025)
        dataSubmit.outsourceData.documentData.push({
          documentType: documentType,
          id: documentIdCM0025,
        });
      if (visibleCM0027 && documentIdCM0027)
        dataSubmit.outsourceData.documentData.push({
          documentType: documentType,
          id: documentIdCM0027,
        });
      if (visibleCM0029 && documentIdCM0029)
        dataSubmit.laborData.documentData.push({
          documentType: documentType,
          id: documentIdCM0029,
        });
      if (visibleCM0033)
        dataSubmit.receiveMoneyData.documentData.push({
          documentType: documentType,
          id: document33.documentId,
        });
      const response = await service.putInternalReject(dataSubmit);
      if (response?.status === `${COMMON.HTTP_STATUS_OK}`) {
        SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
        setRefresh(!refresh);
        setVisibleReject(false);
        formReject.resetFields();
        dispatch(setVisibleCM0021(false));
        dispatch(setVisibleCM0023(false));
        dispatch(setVisibleCM0025(false));
        dispatch(setVisibleCM0027(false));
        dispatch(setVisibleCM0029(false));
        dispatch(setVisibleCM0031(false));
        dispatch(setVisibleCM0033(false));
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleSubmitData = async () => {
    try {
      dispatch(setLoading(true));
      const dataSubmit: InternalApprovalPayload = {
        indirectData: [],
        laborData: [],
        materialData: [],
        outsourceData: [],
        receiveMoneyData: [],
      };
      for (const item of data.material)
        if (
          item?.status?.code ===
          STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE &&
          selectedRow.materialCost?.includes(item.key)
        )
          dataSubmit.materialData.push({
            documentType: item.documentType,
            id: item.id,
          });
      for (const item of data.outsource)
        if (
          item?.status?.code ===
          STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE &&
          selectedRow.outsourcingCost?.includes(item.key)
        )
          dataSubmit.outsourceData.push({
            documentType: item.documentType,
            id: item.id,
          });
      for (const item of data.labor)
        if (
          item?.status?.code ===
          STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE &&
          selectedRow.laborCost?.includes(item.key)
        )
          dataSubmit.laborData.push({
            documentType: item.documentType,
            id: item.id,
          });
      for (const item of data.indirect)
        if (
          item?.status?.code ===
          STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE &&
          selectedRow.indirectCost?.includes(item.key)
        )
          dataSubmit.indirectData.push({
            documentType: item.documentType,
            id: item.id,
          });
      for (const item of data.receive)
        if (
          item?.status?.code ===
          STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE &&
          selectedRow.receive?.includes(item.key)
        )
          dataSubmit.receiveMoneyData.push({
            documentType: item.documentType,
            id: item.id,
          });

      const response = await service.putInternalApproval(dataSubmit);
      if (response?.status === `${COMMON.HTTP_STATUS_OK}`) {
        SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
        setRefresh(!refresh);
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleSubmitDataDetail = async () => {
    try {
      dispatch(setLoading(true));
      const dataSubmit: InternalApprovalPayload = {
        indirectData: [],
        laborData: [],
        materialData: [],
        outsourceData: [],
        receiveMoneyData: [],
      };
      if (visibleCM0031)
        dataSubmit.indirectData.push({
          documentType: document.documentType,
          id: document.id,
        });
      if (visibleCM0021 && documentIdCM0021)
        dataSubmit.materialData.push({
          documentType: documentType,
          id: documentIdCM0021,
        });
      if (visibleCM0023 && documentIdCM0023)
        dataSubmit.materialData.push({
          documentType: documentType,
          id: documentIdCM0023,
        });
      if (visibleCM0025 && documentIdCM0025)
        dataSubmit.outsourceData.push({
          documentType: documentType,
          id: documentIdCM0025,
        });
      if (visibleCM0027 && documentIdCM0027)
        dataSubmit.outsourceData.push({
          documentType: documentType,
          id: documentIdCM0027,
        });
      if (visibleCM0029 && documentIdCM0029)
        dataSubmit.laborData.push({
          documentType: documentType,
          id: documentIdCM0029,
        });
      if (visibleCM0033)
        dataSubmit.receiveMoneyData.push({
          documentType: documentType,
          id: document33.documentId,
        });

      const response = await service.putInternalApproval(dataSubmit);
      if (response?.status === `${COMMON.HTTP_STATUS_OK}`) {
        SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
        setRefresh(!refresh);
        dispatch(setVisibleCM0021(false));
        dispatch(setVisibleCM0023(false));
        dispatch(setVisibleCM0025(false));
        dispatch(setVisibleCM0027(false));
        dispatch(setVisibleCM0029(false));
        dispatch(setVisibleCM0031(false));
        dispatch(setVisibleCM0033(false));
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };
  const handleChangeValueReject = (value: any) => {
    setIsChangeValueReject(true);
  };
  const handleViewRejectReason = (rejectReason: string) => {
    setVisibleRejectView(true);
    setRejectReason(rejectReason);
  };
  const handleCloseViewRejectReason = () => {
    setVisibleRejectView(false);
  };
  const handleRejectData = () => {
    setVisibleReject(true);
  };

  const handleSortTable = (sorter: any, type: string) => {
    switch (type) {
      case MATERIAL_COST:
        searchParams.set(PARAMS.sortType81, sorter.order);
        break;
      case OUTSOURCING_COST:
        searchParams.set(PARAMS.sortType82, sorter.order);
        break;
      case LABOR_COST:
        searchParams.set(PARAMS.sortType83, sorter.order);
        break;
      case INDIRECT_COST:
        searchParams.set(PARAMS.sortType84, sorter.order);
        break;
      case RECEIVE_MONEY:
        searchParams.set(PARAMS.sortType85, sorter.order);
        break;
    }
    setSearchParams(searchParams);
  };

  const funcClickDocumentMaterialCost = (document: any) => {
    setDocumentType(document.documentType);
    const modalInfo = {
      documentTitle: document?.fileName ?? "",
      createdDate: helpers.formatDatePresenter(
        document?.createdAt,
        COMMON.FORMAT_DATE_CM
      ),
      targetPeriod: helpers.toTargetPeriodFormatYYYYMM(
        document.startDate,
        document.endDate
      ),
      status: document.status?.code,
    };
    if (document.documentType === TYPE_FILE.company) {
      dispatch(setDocumentInfoCM0021(modalInfo));
      dispatch(setDocumentIdCM0021(document.id));
      dispatch(setVisibleCM0021(true));
    } else {
      dispatch(setDocumentInfoCM0023(modalInfo));
      dispatch(setDocumentIdCM0023(document.id));
      dispatch(setVisibleCM0023(true));
    }
  };
  const funcClickDocumentOutsourcingCost = (document: any) => {
    setDocumentType(document.documentType);
    const modalInfo = {
      documentTitle: document?.fileName ?? "",
      createdDate: helpers.formatDatePresenter(
        document?.createdAt,
        COMMON.FORMAT_DATE_CM
      ),
      targetPeriod: helpers.toTargetPeriodFormatYYYYMM(
        document.startDate,
        document.endDate
      ),
      status: document.status?.code,
    };
    if (document.documentType === TYPE_FILE.company) {
      dispatch(setDocumentInfoCM0025(modalInfo));
      dispatch(setDocumentIdCM0025(document.id));
      dispatch(setVisibleCM0025(true));
    } else {
      dispatch(setDocumentInfoCM0027(modalInfo));
      dispatch(setDocumentIdCM0027(document.id));
      dispatch(setVisibleCM0027(true));
    }
  };
  const funcClickDocumentLaborCost = (document: any) => {
    setDocumentType(document.documentType);
    const modalInfo = {
      documentTitle: document?.fileName ?? "",
      createdDate: helpers.formatDatePresenter(
        document?.createdAt,
        COMMON.FORMAT_DATE_CM
      ),
      targetPeriod: helpers.toTargetPeriodFormatYYYYMM(
        document.startDate,
        document.endDate
      ),
      status: document.status?.code,
    };
    dispatch(setVisibleCM0029(true));
    dispatch(setDocumentIdCM0029(document.id));
    dispatch(setDocumentInfoCM0029(modalInfo));
  };
  const funcClickDocumentIndirect = (document: any) => {
    setDocumentType(document.documentType);
    dispatch(setVisibleCM0031(true));
    dispatch(
      setDocumentCM0031({
        id: document?.id,
        submittedDate: document?.submittedDate,
        from: document.startDate,
        to: document.endDate,
        documentName: document?.fileName,
        status: document?.status?.code,
        documentType: document?.documentType,
      })
    );
  };
  const funcClickDocumentBillingAmount = (document: any) => {
    setDocumentType(document.documentType);
    dispatch(setVisibleCM0033(true));
    dispatch(
      setInputCM0033({
        documentId: document?.id,
        documentName: document?.fileName,
        documentType: document?.documentType,
        submittedDate: document?.submittedDate
          ? moment(document.submittedDate).format(COMMON.FORMAT_DATE_CI)
          : "",
        startTimeFilter: document.startDate
          ? moment(document.startDate).format(COMMON.FORMAT_DATE_JA_MONTH)
          : "",
        endTimeFilter: document.endDate
          ? moment(document.endDate).format(COMMON.FORMAT_DATE_JA_MONTH)
          : "",
        documentStatus: document.status?.code,
      })
    );
  };

  const handleDataView = (
    data: any[],
    sortType: string | null,
    status: string | null,
    from: string | null,
    to: string | null,
    keyword: string | null,
    clear: string | null
  ): any[] => {
    if (!data) return [];
    let results = [...data];
    if (sortType) {
      if (sortType === "ascend") {
        results = [...data].sort((a, b) => {
          const A = moment(a.submittedDate);
          const B = moment(b.submittedDate);
          return A.valueOf() - B.valueOf();
        });
      } else {
        results = [...data].sort((a, b) => {
          const A = moment(a.submittedDate);
          const B = moment(b.submittedDate);
          return B.valueOf() - A.valueOf();
        });
      }
    } else {
      results = [...data].sort((a, b) => {
        const A = moment(a.createdAt);
        const B = moment(b.createdAt);
        return B.valueOf() - A.valueOf();
      });
    }
    if (status) {
      results = [...results].filter(
        (element) => element.status.id === Number(status)
      );
    }

    if (from && to && !clear) {
      results = [...results].filter(
        (element) =>
          (moment(element.submittedDate) >= moment(from, COMMON.FORMAT_DATE2) ||
            moment(element.submittedDate).format(COMMON.FORMAT_DATE2) ===
            from) &&
          (moment(element.submittedDate) <= moment(to, COMMON.FORMAT_DATE2) ||
            moment(element.submittedDate).format(COMMON.FORMAT_DATE2) === to)
      );
    }
    if (keyword) {
      results = [...results].filter((element) =>
        element.fileName?.includes(keyword)
      );
    }
    // handle no
    results = results.map((element, index) => {
      return {
        ...element,
        no: index + 1,
        originId:
          element?.documentDirectMaterialCostId ??
          element?.documentDirectOutsourceCostId ??
          element?.documentDirectLaborCostId ??
          element?.documentIndirectCostId ??
          0,
      };
    });
    return results;
  };

  const funcCheckDisableSubmitReject = () => {
    const foundPendingMaterial = data.material.some(
      (ele) => ele?.canApprove && selectedRow.materialCost?.includes(ele?.key)
    );
    const foundPendingOutsource = data.outsource.some(
      (ele) =>
        ele?.canApprove && selectedRow.outsourcingCost?.includes(ele?.key)
    );
    const foundPendingLabor = data.labor.some(
      (ele) => ele?.canApprove && selectedRow.laborCost?.includes(ele?.key)
    );
    const foundPendingIndirect = data.indirect.some(
      (ele) => ele?.canApprove && selectedRow.indirectCost?.includes(ele?.key)
    );

    const foundPendingReceive = data.receive.some(
      (ele) => ele?.canApprove && selectedRow.receive?.includes(ele?.key)
    );
    return (
      !foundPendingMaterial &&
      !foundPendingOutsource &&
      !foundPendingLabor &&
      !foundPendingIndirect &&
      !foundPendingReceive
    );
  };

  return {
    selectedRow,
    formReject,
    visibleReject,
    visibleRejectView,
    isChangeValueReject,
    rejectReason,
    sortType81,
    sortType82,
    sortType83,
    sortType84,
    sortType85,
    data,
    from,
    to,
    clear,
    status,
    keyword,
    refresh,

    handleChangeSelectedRow,
    handleCancelReject,
    handleSubmitReject,
    handleChangeValueReject,
    handleCloseViewRejectReason,
    handleRejectData,
    handleViewRejectReason,
    handleSortTable,
    funcClickDocumentMaterialCost,
    funcClickDocumentOutsourcingCost,
    funcClickDocumentLaborCost,
    funcClickDocumentIndirect,
    funcClickDocumentBillingAmount,
    getMaterialReviewList,
    getOutsourceReviewList,
    getLaborReviewList,
    getIndirectReviewList,
    getReceiveMoneyReviewList,
    handleDataView,
    handleSubmitData,
    funcCheckDisableSubmitReject,
    handleSubmitDataDetail,
  };
};

export default CM053Handler;
