import ErrorNotification from '../../../../common/components/notification/ErrorNotification';
import { NOTIFICATION_TITLE } from '../../../../common/constants/MESSAGE';
import { OrderingCompanyCreationPayload } from '../entity/Entity';
import { PM008_1Api } from './ServiceImpl';

const PM008_1Service = (api: PM008_1Api) => {
    const getListOrderingCompany = async (params: {
        page?: number;
        size?: number;
        sortBy?: string;
        sortType?: string;
        keyword?: string;
    }) => {
        try {
            const response = await api.getListOrderingCompany(params);
            return response?.data;
        } catch (error: any) {
            ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };

    const addCompanyForList = async (payload: OrderingCompanyCreationPayload): Promise<void> => {
        const response = await api.addCompanyForList(payload);
        return response?.data;
    };

    return {
        getListOrderingCompany,
        addCompanyForList
    };
};

export default PM008_1Service;
