/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  PRIVILEGE_CREATOR,
  PRIVILEGE_MEMBER,
  PRIVILEGE_PM,
  PRIVILEGE_SUB_PM,
} from "../constants/PRIVILEGE";
import helpers from "./common";

const isHasPrivilegeByPermission = (privileges?: string[]) => {
  const user = helpers.getObjectFromLocalStorage("user");
  const permission = user?.permission ?? [];
  const result = permission.some((element: string) =>
    privileges?.includes(element)
  );
  return result;
};

const isHasPrivilege = (data?: any, privileges?: string[]) => {
  const user = helpers.getObjectFromLocalStorage("user");

  let flag = false;

  // Is Admin
  if (user?.role?.code === "admin") return true;

  if (privileges !== undefined && data !== undefined) {
    // privileges.forEach((item: string) => {
    for (const item of privileges) {
      switch (item) {
        case PRIVILEGE_PM:
          flag = Number(data?.projectManager?.id) === Number(user?.id);
          break;
        case PRIVILEGE_SUB_PM:
          if (Array.isArray(data?.deputyProjectManager)) {
            flag = data?.deputyProjectManager.some(
              (item: any) => Number(item.id) === Number(user?.id)
            );
          }
          break;
        case PRIVILEGE_CREATOR:
          flag = Number(data?.projectCreator?.id) === Number(user?.id);
          break;
        case PRIVILEGE_MEMBER:
          if (Array.isArray(data?.members)) {
            flag = data?.members.some(
              (item: any) => Number(item.id) === Number(user?.id)
            );
          }
          break;
        default:
          break;
      }
      if (flag) break;
    }
  }
  return flag;
};

const isPrivilegeEmployee = () => {
  const user = helpers.getObjectFromLocalStorage("user");
  let flag = false;
  // Is Employee
  if (user?.role?.code === "employee") flag = true;
  return flag;
};

const isMemberPlanBasic = () => {
  const user = helpers.getObjectFromLocalStorage("user");
  let flag = false;
  // Is Employee
  if (user?.role?.code === "employee" && user?.order?.code === "plan_basic")
    flag = true;
  return flag;
};

export {
  isHasPrivilege,
  isPrivilegeEmployee,
  isMemberPlanBasic,
  isHasPrivilegeByPermission,
};
