import { useDispatch } from "react-redux";
import { hidePdfViewerModal } from "./pdfViewerModalSlice";

const usePdfViewerModal = () => {
  const dispath = useDispatch();
  const onHidePdfViwerModal = () => dispath(hidePdfViewerModal());
  return { onHidePdfViwerModal };
};

export default usePdfViewerModal;
