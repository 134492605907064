import { createSlice } from "@reduxjs/toolkit";
import { PM008_1SliceEntity } from "../type/Presenter";

const initialState: PM008_1SliceEntity = {
    isViewChooseCompany: false,
    chosenCompany: 0,
    refreshCompany: false,
    listCompany: [],
    orderingCompanyId: 0

};

export const pm008_1Slice = createSlice({
    name: "pm008_1",
    initialState,
    reducers: {
        resetStatePM008_1: () => initialState,
        setViewChooseCompany: (state, action) => {
            state.isViewChooseCompany = action.payload;
        },
        setChangeChosenCompany: (state, action) => {
            state.chosenCompany = action.payload;
        },
        setChangeRefreshCompany: (state) => {
            state.refreshCompany = !state.refreshCompany;
        },
        setListCompany: (state, action) => {
            state.listCompany = action.payload;
        },

    },
});

export const {
    resetStatePM008_1,
    setViewChooseCompany,
    setChangeChosenCompany,
    setChangeRefreshCompany,
    setListCompany,

} = pm008_1Slice.actions;

export default pm008_1Slice.reducer;
