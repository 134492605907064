import { useDispatch, useSelector } from "react-redux";
import { useRef, useState } from "react";
import { PM008_1UseCase } from "../../usecase/ServiceImpl";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../../common/constants/MESSAGE";
import { Form } from "antd";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import { RootState } from "../../../../../store";
import { resetStatePM008_1, setChangeChosenCompany, setListCompany, setViewChooseCompany } from "../slice/Slice";
import { resetStateCR002 } from "../../../../CR/CR002/presenter/slice/Slice";
import { useSearchParams } from "react-router-dom";
import { PAGE_PARAM } from "../../../../ORD/ORD001/contants";
import COMMON from "../../../../../common/constants/COMMON";

const COMPANY_ADD = {
    name: "",
    email: "",
};

const CR002Handler = (pm008_1Service: PM008_1UseCase) => {
    const dispatch = useDispatch();

    const size = COMMON.DEFAULT_SIZE
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [addCompanies, setAddCompanies] = useState<{
        name: string;
        email: string;
    }>(COMPANY_ADD);
    const [keyword, setKeyword] = useState("");
    const [form] = Form.useForm();
    const refCompany: any = useRef(null);
    const [isAddCompany, setIsAddCompany] = useState(false);
    const [refreshModal, setRefreshModal] = useState(false);
    const [numPages, setNumPages] = useState<number>(1)
    //pagination
    const [page, setPage] = useState(1);

    //redux
    const isViewChooseCompany = useSelector(
        (state: RootState) => state?.pm008_1.isViewChooseCompany
    );
    const chosenCompany = useSelector(
        (state: RootState) => state?.pm008_1.chosenCompany
    );
    const listCompany = useSelector(
        (state: RootState) => state?.pm008_1.listCompany
    );


    const addCompanyForList = async (data: any) => {
        setLoading(true);
        const result = {
            address: "",
            companyName: data.name,
            confirmationDepartment: "",
            fax: "",
            furiganaName: "",
            mail: data.email,
            managerName: "",
            phoneNumber: ""
        }
        try {
            const response = await pm008_1Service.addCompanyForList(result);
            if (refCompany) {
                refCompany?.current?.scrollTo(0, 0);
            }
            SuccessNotification(response.message ?? NOTIFICATION_TITLE.SUCCESS);
            setIsAddCompany(false);
            form.resetFields(["name", "email"]);

            setRefreshModal(!refreshModal);
            setPage(1);
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        } finally {
            setLoading(false);
        }
    };



    //modal choose company
    const handleOpenChooseCompany = () => {
        dispatch(setViewChooseCompany(true));
    };

    const handleCloseChooseCompany = () => {
        dispatch(setViewChooseCompany(false));
        setPage(1);
        dispatch(resetStateCR002());
    };

    const handleChangeChosenCompany = (e: any) => {
        dispatch(setChangeChosenCompany(e.target.value));
    };

    const handleConfirmChosenCompany = () => { };

    const handleAddCompanies = () => {
        setIsAddCompany(true);
    };

    const handleDeleteAddCompanies = () => {
        setIsAddCompany(false);
        form.resetFields(["name", "email"]);
    };

    let myVarCompany: any;
    const onSearchCompanyChange = () => {
        if (myVarCompany) clearTimeout(myVarCompany);
        myVarCompany = setTimeout(function () {
            const searchText = form.getFieldValue("keyword");
            form.setFieldValue("keyword", searchText.trim());
            setPage(1);
            onSearchCompany(searchText?.trim());
        }, 1000);
    };

    const onSearchCompany = (stringWord: string) => {
        setKeyword(stringWord);
    };

    const handleChangePage = (page: number) => {
        setPage(page);
    };

    const handleSaveAddCompany = (value: any) => {
        addCompanyForList({
            name: value?.name,
            email: value?.email,
        });

    };
    const handleScrollListCompany = () => {
        if (numPages > page) setPage(page + 1);
    };

    const getListOrderingCompany = async (params: {
        page?: number;
        size?: number;
        sortBy?: string;
        sortType?: string;
        keyword?: string;
    }) => {
        setLoading(true);
        try {
            const response = await pm008_1Service.getListOrderingCompany(params);
            setLoading(false);
            if (params.page === 1) {
                setNumPages(response?.pagination?.numPages)
                return dispatch(setListCompany(response.results));
            }
            dispatch(setListCompany([...listCompany, ...response.results]));
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            dispatch(setListCompany([]));
        } finally {
            setLoading(false);
        }
    };
    const handleCancelChooseCompany = (orderingCompanyId?: number) => {
        dispatch(setViewChooseCompany(false))
        if (orderingCompanyId != chosenCompany) {
            dispatch(setChangeChosenCompany(orderingCompanyId));
        }
        form.resetFields()
    };
    return {
        loading,
        isViewChooseCompany,
        listCompany,
        chosenCompany,
        addCompanies,
        page,
        size,
        keyword,
        form,
        refCompany,
        isAddCompany,
        refreshModal,

        handleOpenChooseCompany,
        handleCloseChooseCompany,
        handleChangeChosenCompany,
        handleConfirmChosenCompany,
        handleAddCompanies,
        handleDeleteAddCompanies,
        onSearchCompanyChange,
        handleChangePage,
        handleSaveAddCompany,
        handleScrollListCompany,
        getListOrderingCompany,
        handleCancelChooseCompany
    };
};

export default CR002Handler;
