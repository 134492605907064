import helpers from "../../../../common/helpers/common";
import { MoneyConstructionWorkAmountCompletedResult } from "../entity/Entity";
import { CompletedPaidAmountAPIImpl } from "./ServiceImpl";

const CompletedPaidAmountService = (api: CompletedPaidAmountAPIImpl) => {
  const getMoneyConstructionWorkAmountCompleted = async (params: {
    constructionId: number;
    from?: string;
    to?: string;
  }): Promise<MoneyConstructionWorkAmountCompletedResult> => {
    try {
      const response = await api.getMoneyConstructionWorkAmountCompleted({
        constructionId: params.constructionId,
        from: params.from ? helpers.getFilterTime(params.from) : undefined,
        to: params.to ? helpers.getFilterTimeTo(params.to) : undefined,
      });
      const { results } = response.data;
      return results;
    } catch (error: any) {
      throw error;
    }
  };
  return {
    getMoneyConstructionWorkAmountCompleted,
  };
};

export default CompletedPaidAmountService;
