import React from 'react';
import IconDown from '../../../../../assets/icons/icon-collapse-cm.svg';
import CostTableComponent from './CostTable';
import NoDataTableComponent from '../../../../../common/components/no-data/NoDataTable';
import { Empty } from 'antd';
import helpers from '../../../../../common/helpers/common';

type props = {
    project: any;
    index: number;
    checkKeyExpand: (key: any) => boolean;
    setExpandKeys: (key: any) => void;
};

const ProjectRowComponent = ({ project, index, checkKeyExpand, setExpandKeys }: props) => {
    return (
        <>
            <div style={{ height: 4 }}></div>
            <tr
                style={{ background: 'red' }}
                className="row-level-0 button-style"
                onClick={() => setExpandKeys(project.id)}
            >
                <td colSpan={2}>{index + 1}</td>
                <td colSpan={4} style={{ minWidth: 630, textAlign: 'left', paddingLeft: 55 }}>
                    {helpers.getShortTextWithToolTip(project?.projectName, 30)}
                </td>
                <td style={{ minWidth: 150 }}>
                    {helpers.formatNumberMoney(project.paymentAmountTaxIncluded)}円
                </td>
                <td style={{ minWidth: 150 }}>
                    {helpers.formatNumberMoney(project.consumptionTax)}円
                </td>
                <td style={{ minWidth: 150 }}>
                    {helpers.formatNumberMoney(project.paymentAmountExcludingTax)}円
                </td>
                <td style={{ minWidth: 40 }}>
                    <img
                        alt=""
                        src={IconDown}
                        className={`cm024-icon ${checkKeyExpand(project.id) ? '' : 'cm024-icon-active'
                            }`}
                    />
                </td>
            </tr>
            {checkKeyExpand(project.id) ? (
                <CostTableComponent costDetailData={project?.costDetailData ?? []} />
            ) : (
                <></>
            )}
        </>
    );
};

export default ProjectRowComponent;
