/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { linearGradientDef } from "@nivo/core";
import helpers from "../../helpers/common";

type props = {
  data: any[];
  maxAxis: number;
};

const MyResponsiveBar = ({ data, maxAxis }: props) => {
  const TotalLabels = ({ bars, yScale }: any) => {
    const check = new Map();
    return bars.map(
      ({ data: { data, indexValue }, x, y, width, height }: any, i: any) => {
        let number = 0;
        let color = "";
        let primaryData = 0;
        if (check.has(data.key)) {
          number = data.billingVolumeRate;
          primaryData = data.billingVolume
          color = "#222222";
        } else {
          check.set(data.key, data);
          number = data.billingBalanceRate;
          color = "#222";
          primaryData = data.billingBalance
        }
        return (
          <g transform={`translate(${x}, ${y + height / 2 + 10})`} key={`${indexValue}-${i}`}>
            <text
              x={width / 2}
              y={number < 0 ? 10 : -10}
              textAnchor="middle"
              alignmentBaseline="central"
              style={{
                fill: color,
                fontSize: "10px",
                fontWeight: 700
              }}
            >
              {primaryData? helpers.formatNumberMoney(primaryData): ''} 
              {number ?` (${helpers.formatNumberMoney(number)}%)`: ""}
            </text>
          </g>
        );
      }
    );
  };
  const marginLeft = useMemo(() => {
    const numberArray = data.map((element) => element.billingBalance);
    const maxNumber = Math.max(...numberArray);
    const results = (maxNumber + "").length * 10 + 10;
    return results > 83 ? results : 83;
  }, [JSON.stringify(data)]);

  return (
    <ResponsiveBar
      data={data}
      keys={["billingBalance", "billingVolume"]}
      indexBy={"projectId"}
      margin={{ right: 24, bottom: 16, left: marginLeft }}
      padding={0.2}
      maxValue={maxAxis}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      defs={[
        linearGradientDef(
          "gradientA",
          [
            { offset: 0, color: "#FFC53D" },
            { offset: 100, color: "#EA6B38" },
          ],
          {
            gradientTransform: "rotate(90 0.5 0.5)",
          }
        ),
        linearGradientDef(
          "gradientB",
          [
            { offset: 0, color: "#2EC5C1" },
            { offset: 100, color: "#119CD3" },
          ],
          {
            gradientTransform: "rotate(90 0.5 0.5)",
          }
        ),
        linearGradientDef(
          "gradientC",
          [
            { offset: 0, color: "#7B61FFCC" },
            { offset: 100, color: "#6648FF" },
          ],
          { gradientTransform: "rotate(90 0.5 0.5)" }
        ),
      ]}
      fill={[
        { match: { id: "billingVolume" }, id: "gradientA" },
        { match: { id: "billingBalance" }, id: "gradientC" },
      ]}
      axisTop={null}
      axisRight={null}
      axisBottom={null}
      axisLeft={{
        tickSize: 0,
        tickPadding: 8,
        tickRotation: 0,
        legend: "",
        legendPosition: "middle",
        legendOffset: -40,
        format: (v) => `${helpers.formatCurrency(v)}円`,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      legends={[]}
      labelTextColor={(item) => {
        return item.data.id === "billingBalance" ? "#222" : "#222";
      }}
      layers={["grid", "axes", "bars", "markers"]}
      label={(d) => {
        let number = 0
        if(d.id === "billingBalance") {
          number = d.data.billingBalanceRate
        } else if(d.id === "billingVolume") {
          number = d.data.billingVolumeRate
        }
        return  `${helpers.formatCurrency(d?.value ?? 0)} (${helpers.formatNumberMoney(number)}%)`
      }}
      tooltip={(point) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: 5,
              alignItems: "flex-start",
              background: "#fff",
              columnGap: 5,
              borderRadius: 5,
              fontSize: "12px",
              padding: 15,
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              border: "1px solid #eee",
            }}
          >
            <div style={{ color: "#222", fontWeight: 800 }}>
              {point?.data?.projectName}
            </div>
            <div>
              完成出来高 :{" "}
              {helpers.formatCurrency(point?.data?.billingVolume ?? 0)}
            </div>
            <div>
              完成出来高(％) :{" "}
              {helpers.formatCurrency(point?.data?.billingVolumeRate ?? 0)}％
            </div>
            <div>
              請求残高 :{" "}
              {helpers.formatCurrency(point?.data?.billingBalance ?? 0)}
            </div>
            <div>
              請求残高(％) :{" "}
              {helpers.formatCurrency(point?.data?.billingBalanceRate ?? 0)}％
            </div>
          </div>
        );
      }}
      theme={{
        axis: {
          ticks: {
            text: {
              fill: "#666666",
            },
          },
        },
        labels: {
          text: {
            fontSize: 10,
            fontWeight: 700,
            fill: "#222222",
          },
        },
      }}
    />
  );
};

export default MyResponsiveBar;
