import { Form } from "antd";
import CcusidModal from "../../../../../common/components/modal/ModalCCUS";
import { CCUS01ServiceImpl } from "../../usecase/ServiceImpl";
import { useState } from "react";
import { useDispatch } from "react-redux";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import { CCUSCompanyInputType, RequestUploadCCUSCompany, ResponseCCUSCompanyInformation } from "../../entity/Entity";
import { NOTIFICATION_TITLE } from "../../../../../common/constants/MESSAGE";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import helpers from "../../../../../common/helpers/common";

const CCUS001Handler = (service: CCUS01ServiceImpl) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm()
  const [resetCcusCompanyScreen, setResetCcusCompanyScreen] =  useState(false)
  const [ccusCompanyInformation, setCcusCompanyInformation] = useState<ResponseCCUSCompanyInformation| null>({
    state: 0,
    ccusId: "",
    ccusCode: ""
  });

  const [valueFormCCUS, setValueFormCCUS] = useState<CCUSCompanyInputType | null>({
    ccusid: "",
    confirmCcusid: "",
    password: "",
    confirmPassword: "",
});


const resetCcusCompany = () => {
  form.setFieldsValue(valueFormCCUS)
  setCcusCompanyInformation(null)
  setResetCcusCompanyScreen(true)
}

  const getStateCCUSCompany = async (): Promise<any> => {
    try {
      dispatch(setLoading(true));
      const response = await service.getStateCCUSCompany();
      setCcusCompanyInformation(response?.results)

      form.setFieldsValue({
        ccusid: helpers.convertToCCUSFormat(response?.results?.ccusId),
        confirmCcusid: helpers.convertToCCUSFormat(response?.results?.ccusId),
        password: response?.results?.ccusCode,
        confirmPassword: response?.results?.ccusCode,
      })

      setValueFormCCUS({
        ccusid: helpers.convertToCCUSFormat(response?.results?.ccusId),
        confirmCcusid: helpers.convertToCCUSFormat(response?.results?.ccusId),
        password: response?.results?.ccusCode,
        confirmPassword: response?.results?.ccusCode,
      })
        } catch (error: any) {
        ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
  }
};

  const handleUploadCCUSCompany = async (data: RequestUploadCCUSCompany): Promise<any> => {
    try {
      dispatch(setLoading(true));
      const response = await service.uploadCCUSCompany(data);
      SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
    } catch (error: any) {
        ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
  }
};

  const onSubmit = (value: any) => {
    const dataRequestUploadCCUSCompany: RequestUploadCCUSCompany= {
      ccusId: value.ccusid.replaceAll(" ", ""),
      ccusCode: value.password
  };
    CcusidModal({
      onOk: async () => {
       await handleUploadCCUSCompany(dataRequestUploadCCUSCompany)
        getStateCCUSCompany()
    },
      title: "建設キャリアアップシステム(CCUS) \n 事業者ID認証",
      description: "下記のIDおよびパスワードを認証するため \n 建設キャリアアップシステムへ送信してもよろしいでしょうか？",
      ccusId: value.ccusid,
      ccusPassword: value.password,
      canceText: "キャンセル",
      okText: "送信",
    });
  };

  const validatePasswordCCUS = (value: any) => {
    const hasValidLength = value.length >= 8 && value.length <= 24;
    const hasUppercase = /[A-Z]/.test(value);
    const hasLowercase = /[a-z]/.test(value);
    const hasNumber = /[0-9]/.test(value);
    const hasSymbol = /[!@#$%^&*()_+{}\[\]:;<>,.?~`-]/.test(value);
    const typeCount = [hasUppercase, hasLowercase, hasNumber, hasSymbol].filter(Boolean).length;
    return hasValidLength && typeCount >= 3; 
  };
  
  return {
    ccusCompanyInformation,
    resetCcusCompanyScreen,
    getStateCCUSCompany,
    resetCcusCompany,
    validatePasswordCCUS,
    valueFormCCUS,
    onSubmit,
    form,
  };
};

export default CCUS001Handler;
