import axiosConfig from "../../../../../config/axiosConfig";

const ProjectApi = () => {
  const getListProject = async (params: {
    page: number;
    size: number;
    sortBy?: string;
    sortType?: string;
    keyword?: string;
  }): Promise<any> => {
    try {
      const url = "/project/manager";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  return {
    getListProject,
  };
};

export default ProjectApi;
