import React from 'react';

import MaterialCostUI from './ui/UI';
import MaterialCostApi from '../../../infrastructure/api/ApiMaterialCost';
import MaterialCostService from '../../../usecase/ServiceMaterialCost';
import MaterialCostHandler from '../../handler/HandlerMaterialCost';

const MaterialCostComponent = () => {
    const api = MaterialCostApi();
    const service = MaterialCostService(api);
    const handler = MaterialCostHandler(service);
    return <MaterialCostUI handler={handler} />;
};

export default MaterialCostComponent;
