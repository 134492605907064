import { useRef, useState } from "react";
import { RootState } from "./../../../../../store";
import { Form, InputRef } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import ConfirmModal from "../../../../../common/components/modal/ConfirmModal";
import { resetApproveEditModal } from "../slice/Slice";
import { propsApproveEditModal } from "../..";

const Handler = ({ callbackOk, callbackCancel }: propsApproveEditModal) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const document = useSelector(
    (state: RootState) => state.modalApproveEdit.document
  );

  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [localLoading, setLocalLoading] = useState<boolean>(false);

  const resetModal = () => {
    form.resetFields();
    setIsDirty(false);
    setLocalLoading(false);
    dispatch(resetApproveEditModal());
  };

  const callAPI = async () => {
    try {
      setLocalLoading(true);
      if (callbackOk)
        await callbackOk({
          ...document,
          note: form.getFieldValue("explanation"),
        });
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      resetModal();
      setLocalLoading(false);
    }
  };

  const onCancel = () => {
    const isTouched = (form.getFieldValue("explanation") ?? "") === "";
    if (!isTouched) {
      ConfirmModal({
        onOk: () => {
          resetModal();
          if (callbackCancel) callbackCancel();
        },
        onCancel: () => {},
        title: MESSAGE.MESSAGE_020,
        description: MESSAGE.MESSAGE_021,
        canceText: LABEL_MESSAGE.CANCEL_MODAL,
        okText: LABEL_MESSAGE.OK_MODAL,
      });
    } else {
      resetModal();
      if (callbackCancel) callbackCancel();
    }
  };

  const checkDirty = () => {
    const isTouched = form.getFieldValue("explanation") !== "" ? true : false;
    setIsDirty(isTouched);
  };

  return {
    callAPI,
    onCancel,
    checkDirty,
    form,
    isDirty,
    localLoading,
  };
};

export default Handler;
