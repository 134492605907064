import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Spin,
} from "antd";
import EmptyDataSVG from "../../../../../assets/icons/empty-data.svg";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import CloseIconModal from "../../../../../assets/icons/close.png";
import Minus_cm0063 from "../../../../../assets/icons/Minus_cm0063.svg";
import Plus_cm0063 from "../../../../../assets/icons/Plus_cm0063.svg";
import { default as XIconModal } from "../../../../../assets/icons/x-icon-modal.svg";
import "../../../../../assets/styles/CM/CM017_2.css";
import LoadingComponent from "../../../../../common/components/icons/LoadingComponent";
import { RootState } from "../../../../../store";
import { CM017_2Hanlder } from "../handler/HandlerImpl";
import { ParentCode } from "../type/Presenter";
import helpers from "../../../../../common/helpers/common";
import MESSAGE from "../../../../../common/constants/MESSAGE";
import REGEX from "../../../../../common/constants/REGEX";
import moment from "moment";
import COMMON from "../../../../../common/constants/COMMON";
import CalendarSvg from "../../../../../assets/icons/calendar.svg";
import locale from "antd/es/date-picker/locale/ja_JP";
import EditWhiteIcon from "../../../../../assets/icons/edit-white.svg";
import { AUTHORIZATION_CODE } from "../../../../../common/constants/AUTHORIZATION";

type Props = {
  handler: CM017_2Hanlder;
};

const MainUI = ({ handler }: Props) => {
  const formRef: any = useRef();
  const [isClickAdd, setIsClickAdd] = useState<number>(0);
  const isVisible = useSelector(
    (state: RootState) => state.cm017_2_2.isVisible
  );
  const loading = useSelector((state: RootState) => state.cm017_2_2.loading);
  const parentCode: ParentCode = useSelector(
    (state: RootState) => state.cm017_2_2.parentCode
  );
  const isUpdate = useSelector((state: RootState) => state.cm017_2_2.isUpdate);
  const modalTitle = useSelector(
    (state: RootState) => state.cm017_2_2.modalTitle
  );

  const permission = useSelector(
    (state: RootState) => state.auth.authData?.permission
  );

  const isRoleAdmin = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdmin
  );

  const [classNameViolateRule, setClassNameViolateRule] =
    useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (
        isVisible &&
        parentCode.companyId !== undefined &&
        parentCode.colabRefId !== undefined &&
        parentCode.categoryId !== undefined &&
        parentCode.from !== undefined &&
        parentCode.to !== undefined
      )
        await handler.handleGetListOutsourceCostOffset({
          companyId: parentCode.companyId,
          colabRefId: parentCode.colabRefId,
          categoryId: parentCode.categoryId,
          from: parentCode.from,
          to: parentCode.to,
        });
    })();
  }, [isVisible, JSON.stringify(parentCode)]);

  useEffect(() => {
    (async () => {
      if (isVisible) await handler.handleGetSubCategory();
    })();
  }, [isVisible]);

  useEffect(() => {
    if (isClickAdd !== 0 && formRef.current) {
      formRef.current.scrollTo(0, formRef.current.scrollHeight);
    }
  }, [isClickAdd]);

  useEffect(() => {
    handler?.listReceivedMoneyOffsetLocal &&
      handler.handleCalculateChange(handler.listReceivedMoneyOffsetLocal);
  }, [handler?.listReceivedMoneyOffsetLocal]);

  return (
    <Modal
      className="group-detail-modal"
      style={{
        marginLeft: 300,
        marginTop: 40,
        marginBottom: 40,
        minHeight: 500,
      }}
      visible={isVisible}
      closable={false}
      maskClosable={false}
      width={"1027px"}
      centered={true}
      closeIcon={<img src={CloseIconModal} alt="close icon" />}
      footer={
        handler.typeModal ? (
          <>
            <Button
              disabled={loading}
              size="large"
              type="primary"
              style={{ marginLeft: 16, width: 92, letterSpacing: -1.5 }}
              htmlType="submit"
              form="formCM017_2"
            >
              保存
            </Button>
            <Button
              disabled={loading}
              size="large"
              style={{
                marginLeft: 16,
                width: 118,
                letterSpacing: -1.5,
                fontWeight: 400,
                fontSize: 14,
                lineHeight: 22,
                color: "#666666",
              }}
              onClick={handler.onCancel}
            >
              キャンセル
            </Button>
          </>
        ) : (
          <Button
            disabled={loading}
            size="large"
            type="primary"
            style={{ marginLeft: 16, width: 92, letterSpacing: -1.5 }}
            onClick={handler.onCancel}
          >
            閉じる
          </Button>
        )
      }
    >
      <Spin indicator={LoadingComponent} spinning={loading}>
        <div
          className="w-100-percent header-modal-containter"
          style={{ height: 50 }}
        >
          <div className="header-modal-title-cm017_2">
            {modalTitle ?? "業者別相殺合計金額"}
          </div>
          <div style={{ cursor: "pointer" }} onClick={handler.onCancel}>
            <img alt="" src={XIconModal} style={{ height: 18, width: 18 }} />
          </div>
        </div>
        <div style={{ marginTop: 24 }} className="cm017_2-content">
          <div
            className={
              handler.typeModal
                ? "content-description content-description-update"
                : "content-description"
            }
          >
            {!handler.typeModal &&
            isUpdate &&
            ((permission?.includes(AUTHORIZATION_CODE.S33) && isRoleAdmin) ||
              permission?.includes(AUTHORIZATION_CODE.S44)) ? (
              <div className="content-description-button">
                <Button
                  style={{
                    letterSpacing: -1.5,
                  }}
                  onClick={() => handler.handleChangeTypeModal(true)}
                  size="large"
                  className="button-edit-project"
                  icon={
                    <img
                      alt=""
                      src={EditWhiteIcon}
                      style={{
                        paddingRight: 12,
                        border: "none",
                        marginBottom: 4,
                      }}
                    />
                  }
                >
                  編集
                </Button>
              </div>
            ) : null}
          </div>
          <div className="content-table">
            <div
              className="table-header table-row"
              style={{ paddingRight: "7px" }}
            >
              <div className="cell cell-order">No</div>
              <div className="cell cell-item">項目名</div>
              <div className="cell cell-item">対象年月</div>
              <div className="cell cell-taxCategoryComsumption">消費税区分</div>
              <div className="cell cell-offsetAmount">相殺金額（税抜）</div>
            </div>
            <div ref={formRef} className="form-cm017_2">
              <Form
                form={handler.form}
                id="formCM017_2"
                style={{
                  height: "100%",
                }}
                onFinish={(value: any) => handler.handleSubmitForm(value)}
                onValuesChange={handler.checkDirty}
              >
                {handler?.listReceivedMoneyOffsetLocal?.map((item, index) => {
                  return !handler.typeModal ? (
                    <div className="table-row " key={item.key}>
                      <div className="cell cell-order">{index + 1}</div>
                      <div className="cell cell-item">{item.item}</div>
                      <div className="cell cell-item">
                        {" "}
                        {moment(item.date, true).isValid()
                          ? moment(item.date).format(COMMON.FORMAT_DATE_DB)
                          : "-"}
                      </div>
                      <div className="cell cell-taxCategoryComsumption">
                        {item.taxCategoryComsumptionName}
                      </div>
                      <div className="cell cell-offsetAmount">
                        {item.offsetAmount &&
                          `${helpers.formatCurrency(item.offsetAmount)}円`}
                      </div>
                    </div>
                  ) : (
                    <div
                      className={
                        classNameViolateRule
                          ? "table-row table-row-violate-rule"
                          : "table-row"
                      }
                      key={item.key}
                    >
                      <div className="cell cell-order">{index + 1}</div>
                      <div className="cell cell-item">
                        <Form.Item
                          name={`item${item.key}`}
                          rules={[
                            {
                              required: true,
                              message: "",
                            },
                            () => ({
                              validator(_, value) {
                                if (!REGEX.NOT_VIETNAM.test(value)) {
                                  setClassNameViolateRule(true);
                                  return Promise.reject(
                                    new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                                  );
                                }
                                setClassNameViolateRule(false);
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input maxLength={20} />
                        </Form.Item>
                      </div>
                      <div className="cell cell-item">
                        <Form.Item
                          name={`targetDate${item.key}`}
                          rules={[
                            {
                              required: true,
                              message: "",
                            },
                          ]}
                        >
                          <DatePicker
                            style={{ height: 38, width: 167.25 }}
                            inputReadOnly
                            locale={locale}
                            picker="month"
                            format={COMMON.FORMAT_DATE_DB}
                            placeholder="[ ---- / -- ]"
                            suffixIcon={
                              <img
                                alt=""
                                src={CalendarSvg}
                                className="icon-calender"
                              />
                            }
                          />
                        </Form.Item>
                      </div>
                      <div className="cell cell-taxCategoryComsumption">
                        <Form.Item name={`taxCategoryConsumption${item.key}`}>
                          <Select
                            options={handler?.subCategory}
                            listHeight={100}
                          />
                        </Form.Item>
                      </div>
                      <div className="cell cell-offsetAmount">
                        <Form.Item name={`offsetAmount${item.key}`}>
                          <InputNumber
                            controls={false}
                            maxLength={19}
                            addonAfter="円"
                            min={0}
                            onChange={() => {
                              handler.handleCalculateChange(
                                handler.listReceivedMoneyOffsetLocal
                              );
                            }}
                            formatter={(value) =>
                              value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                            parser={(value: any) =>
                              value.replace(/\$\s?|(,*)/g, "")
                            }
                          />
                        </Form.Item>
                        <img
                          src={Minus_cm0063}
                          alt=""
                          style={{
                            width: 22,
                            height: 22,
                            objectFit: "cover",
                            marginLeft: 8,
                            cursor: "pointer",
                          }}
                          onClick={() => handler.handleRemoveRowLocal(item.key)}
                        />
                      </div>
                    </div>
                  );
                })}
                {handler?.listReceivedMoneyOffsetLocal?.length === 0 && (
                  <div className="empty-data">
                    <img src={EmptyDataSVG} alt="" />
                  </div>
                )}
              </Form>
            </div>
            <div className="table-row table-row-blue">
              <div className="cell cell-order" style={{ width: 78.5 }}>
                合計金額
              </div>
              <div className="cell cell-item" style={{ width: 221.88 }}></div>
              <div className="cell cell-item" style={{ width: 221.88 }}></div>
              <div
                className="cell cell-taxCategoryComsumption"
                style={{ width: 221.88 }}
              ></div>
              <div
                className="cell cell-offsetAmount"
                style={{ width: 221.88 }}
              >{`${helpers.formatCurrency(handler.totalCost)}円`}</div>
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default MainUI;
