import { AxiosResponse } from 'axios';
import axiosConfig from '../../../../../../config/axiosConfig';

const CM0053Api = () => {
    const getMaterialReviewList = async (): Promise<AxiosResponse<any, any>> => {
        const url = '/re-summary/material/review/list';
        return await axiosConfig.get(url);
    };
    const getOutsourceReviewList = async (): Promise<AxiosResponse<any, any>> => {
        const url = '/re-summary/outsource/review/list';
        return await axiosConfig.get(url);
    };

    const getLaborReviewList = async (): Promise<AxiosResponse<any, any>> => {
        const url = '/re-summary/labor/review/list';
        return await axiosConfig.get(url);
    };

    const getIndirectReviewList = async (): Promise<AxiosResponse<any, any>> => {
        const url = '/re-summary/indirect/review/list';
        return await axiosConfig.get(url);
    };

    const getReceiveMoneyReviewList = async (): Promise<AxiosResponse<any, any>> => {
        const url = '/re-summary/receive-money/review/list';
        return await axiosConfig.get(url);
    };

    const putInternalApproval = async (data: any): Promise<AxiosResponse<any, any>> => {
        const url = '/re-summary/internal/approval';
        return await axiosConfig.put(url, data);
    };

    const putInternalReject = async (data: any): Promise<AxiosResponse<any, any>> => {
        const url = '/re-summary/internal/reject';
        return await axiosConfig.put(url, data);
    };

    return {
        getMaterialReviewList,
        getOutsourceReviewList,
        getLaborReviewList,
        getIndirectReviewList,
        getReceiveMoneyReviewList,
        putInternalApproval,
        putInternalReject,
    };
};

export default CM0053Api;
