import React, { useState } from "react";
import { FormInstance } from "antd";

import { PlanData } from "../../../entity/Entity";
import { PLAN_BASIC_SUBSCRIPTION } from "../../../../../../common/constants/SUBSCRIPTION";
import { useDispatch } from "react-redux";
import { setIsFormValidating } from "../../slice/Slice";

export const Handler = () => {
  const dispatch = useDispatch();
  const [currentCost, setCurrentCost] = useState<number>(0);
  const [currentTotalMAmount, setCurrentTotalAmount] = useState<number>(0);
  const [subscriptionState, setSubscriptionState] = useState<any[]>([]);
  const [disabledOptions, setDisabledOptions] = useState<any>({
    M17: true,
    M18: true,
    M19: true,
    B6: true,
  });

  const onChangeSubscription = (
    option: any,
    value: any,
    form: FormInstance,
    formName: string
  ) => {
    setCurrentTotalAmount(0);
    const foundSubscription = subscriptionState.find(
      (element) => element.id === value
    );
    const discount = form.getFieldValue([`${formName}`, "discount"]) ?? 0;
    const cost = foundSubscription?.cost ?? 0;
    setCurrentCost(cost);
    setCurrentTotalAmount(cost - discount);
    dispatch(setIsFormValidating(true));
  };

  const onChangeDiscount = (value: any) => {
    setCurrentTotalAmount(currentCost - value);
  };

  const setExistedData = (data: any) => {
    if (!data) return;
    setCurrentCost(data.orderCost ?? 0);
    setCurrentTotalAmount((data.orderCost ?? 0) - (data.discount ?? 0));
  };

  const checkDisabledPlanSubscription = (
    subscriptionState: any[],
    chosenPlanFunction: number[]
  ) => {
    if (subscriptionState.length === 0) return;
    const disabledOptions = {
      M17: false,
      M18: false,
      M19: false,
      B6: false,
    };

    const { M17, M18, M19, B6 } = PLAN_BASIC_SUBSCRIPTION;

    // check xem item M17 đã được chọn hay chưa
    const foundM17 = subscriptionState.find((element) => element.code === M17);
    const isChosenM17 = chosenPlanFunction.find(
      (element) => foundM17.id === element
    ); // M17 khong được chọn
    if (!isChosenM17) {
      disabledOptions.M18 = true;
      disabledOptions.M19 = true;
      disabledOptions.B6 = true;
      setDisabledOptions(disabledOptions);
      return;
    }

    const foundM18 = subscriptionState.find((element) => element.code === M18);
    const foundM19 = subscriptionState.find((element) => element.code === M19);

    const resultB6 = [foundM18.id, foundM19.id].every((element) =>
      chosenPlanFunction.some((plan) => plan === element)
    );
    disabledOptions.B6 = !resultB6;
    setDisabledOptions(disabledOptions);
  };

  return {
    onChangeDiscount,
    setSubscriptionState,
    onChangeSubscription,
    setExistedData,
    checkDisabledPlanSubscription,
    currentCost,
    currentTotalMAmount,
    subscriptionState,
    disabledOptions,
  };
};

export interface HandlerImpl {
  onChangeDiscount: (value: any) => void;
  setSubscriptionState: React.Dispatch<React.SetStateAction<any[]>>;
  onChangeSubscription: (
    option: any,
    value: any,
    form: FormInstance,
    formName: string
  ) => void;
  setExistedData: (data: any) => void;
  checkDisabledPlanSubscription: (
    subscriptionState: any[],
    chosenPlanFunction: number[]
  ) => void;
  currentCost: number;
  currentTotalMAmount: number;
  subscriptionState: any[];
  disabledOptions: any;
}
