import MainUI from './presenter/ui/UI';
import DB001Service from './usecase/Service';
import DB001Api from './infrastructure/api/Api';
import DB001Handler from './presenter/handler/Handler';
const DB001Component = () => {
    const api = DB001Api();
    const service = DB001Service(api);
    const handler = DB001Handler(service);

    return <MainUI handler={handler}></MainUI>;
};

export default DB001Component;
