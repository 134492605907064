import { AxiosResponse } from "axios";
import axiosConfig from "../../../../../config/axiosConfig";

const AM004Api = () => {
  const getAttendanceSubConstructionListMember = async (params: {
    constructionId: number;
    contractorConstructionId: number;
    from: string; // 2006-01-02
    to: string; // 2006-01-02;
    timeOffset: number;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/attendance/subconstruction/list-member";
    return await axiosConfig.get(url, { params });
  };
  const getAttendanceUserTimeSheet = async (params: {
    date: string;
    constructionId?: string;
    contractorConstructionId?: string;
    userId: string;
    timeOffset: number;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/attendance/user-time-sheet";
    return await axiosConfig.get(url, { params });
  };
  const getAttendanceConstructionTotalCost = async (params: {
    constructionId: number;
    timeOffset: number;
    from: string; // 2006-01-02
    to: string; // 2006-01-02,
    keyword: string;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/attendance/construction/total-cost";
    return await axiosConfig.get(url, { params });
  };

  const doExportAM004 = async (params: any): Promise<any> => {
      try {
          const url = '/attendance/exportAM004';
          const response = await axiosConfig.get(url, { params, responseType: 'blob'  });
          
          return response;
      } catch (error) {
          throw error;
      }
  };
  const doExportAM007 = async (params: any): Promise<any> => {
      try {
          const url = '/attendance/exportAM007';
          const response = await axiosConfig.get(url, { params, responseType: 'blob'  });
          
          return response;
      } catch (error) {
          throw error;
      }
  };
  return {
    getAttendanceSubConstructionListMember,
    getAttendanceUserTimeSheet,
    getAttendanceConstructionTotalCost,
    doExportAM004,
    doExportAM007,
  };
};

export default AM004Api;
