import React from 'react';
import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';

export const ForbiddenPage = () => {
    const navigate = useNavigate();
    return (
        <div style={{ height: '100vh', width: '100vw' }} className="center-item">
            <Result
                status="403"
                title="403"
                subTitle="このサイトにアクセスできません"
                extra={
                    <Button onClick={() => navigate('/')} type="primary">
                        Back Home
                    </Button>
                }
            />
        </div>
    );
};
