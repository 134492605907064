function CustomizedLabelXAxis(props: any) {
  const { x, y, payload } = props;
  const year = +payload.value.split("-")[0]
  const month = payload.value.split("-")[1]
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={-12} y={0} dy={25} fill={year % 2 !== 0? "#222": "#666"}>
        {month}月
      </text>
    </g>
  );
}
export default CustomizedLabelXAxis;
