import { useDispatch, useSelector } from "react-redux";
import { ServiceImpl } from "./../../usecase/ServiceImpl";
import {
  setChosenCategoryWF,
  setIsVisibleWorkflow,
  setLoadingWorkflow,
  setWorkflowCategory,
} from "../slice/Slice";
import ErrorNotification from "../../../notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../constants/MESSAGE";
import { useState } from "react";
import COMMON, { PLAN_TYPE } from "../../../../constants/COMMON";
import {
  CountNotificationCategory,
  NotificationAllResults,
  NotificationCategory,
} from "../../entity/Entity";
import SuccessNotification from "../../../notification/SuccessNotification";
import { setLoading } from "../../../../slice/CommonSlice";
import { RootState } from "../../../../../store";

const NOTIFI_WORKFLOW = "notifi_workflow";
const NOTIFI_SCHEDULE = "notifi_schedule";
const Handler = (service: ServiceImpl) => {
  const dispatch = useDispatch();
  const [localVisible, setLocalVisible] = useState(false);
  const [page, setPage] = useState(COMMON.DEFAULT_PAGE);
  const [keyword, setKeyword] = useState("");
  const [total, setTotal] = useState(0);
  const [notifications, setNotifications] = useState<NotificationAllResults[]>(
    []
  );
  const [countNotiCategories, setCountNotiCategories] = useState<
    CountNotificationCategory[]
  >([]);
  const [unreadCount, setUnreadCount] = useState<number>(0);
  const [isRefresh, setIsRefresh] = useState<boolean>(false);
  const isOrderBasic =
    useSelector((state: RootState) => state.auth.authData?.order?.code) ===
    PLAN_TYPE.PLAN_BASIC;

  const onCloseCategory = () => {
    dispatch(setChosenCategoryWF(null));
    setPage(COMMON.DEFAULT_PAGE);
    setTotal(0);
    setNotifications([]);
  };

  const onChooseCategory = (categoryNoti: CountNotificationCategory) => {
    dispatch(setChosenCategoryWF(categoryNoti));
  };

  const getNotificationUnreadCount = async (
    notificationCategories: NotificationCategory[]
  ) => {
    try {
      dispatch(setLoading(true));
      const promiseCalls = notificationCategories.map((element) =>
        service.getNotificationUnreadCount({ categoryId: element.id })
      );
      const promiseFire = await Promise.all(promiseCalls);
      let countingData = 0;
      const countingCategories: CountNotificationCategory[] = [];
      promiseFire.forEach((responseData, index) => {
        countingData += responseData;
        countingCategories.push({
          ...notificationCategories[index],
          count: responseData,
        });
      });
      setUnreadCount(countingData);
      setCountNotiCategories(countingCategories);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const closePM009_1 = () => {
    if (isOrderBasic) dispatch(setChosenCategoryWF(null));
    setNotifications([]);
    dispatch(setIsVisibleWorkflow(false));
    setLocalVisible(false);
  };
  const openPM009_1 = () => {
    dispatch(setIsVisibleWorkflow(true));
    setLocalVisible(true);
  };
  const onScrollData = () => {
    setPage(page + 1);
  };
  const getNotificationAll = async (params: {
    categoryId: number;
    keyword?: string;
    page?: string | number;
    size?: string | number;
  }) => {
    try {
      dispatch(setLoadingWorkflow(true));
      if (notifications.length === total && page !== COMMON.DEFAULT_PAGE)
        return;
      const response = await service.getNotificationAll({
        categoryId: params.categoryId,
        page: params.page,
        size: COMMON.DEFAULT_SIZE,
        keyword: keyword,
      });
      setNotifications([...notifications, ...(response.results ?? [])]);
      setTotal(response.pagination.count);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoadingWorkflow(false));
    }
  };

  const getNotificationCategory = async () => {
    try {
      dispatch(setLoadingWorkflow(true));
      const categoryNotification = await service.getNotificationCategory();

      const desiredCategories = isOrderBasic
        ? [NOTIFI_SCHEDULE, NOTIFI_WORKFLOW]
        : [NOTIFI_WORKFLOW];

      const workflowCategory = categoryNotification.filter((element) =>
        desiredCategories.includes(element.code)
      );
      if (workflowCategory.length > 0) {
        dispatch(setWorkflowCategory(workflowCategory));
        if (!isOrderBasic) onChooseCategory(workflowCategory[0] as any);
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoadingWorkflow(false));
    }
  };

  const refreshDataAfterDeleting = async (params: {
    category: number;
    keyword?: string;
    page?: string | number;
    size?: string | number;
  }) => {
    try {
      dispatch(setLoadingWorkflow(true));

      const firstCall = await service.getNotificationAll({
        categoryId: params.category,
        page: COMMON.DEFAULT_PAGE,
        size: COMMON.DEFAULT_SIZE,
        keyword: keyword,
      });
      setTotal(firstCall?.pagination?.count ?? 0);
      let data: NotificationAllResults[] = firstCall.results;

      if (firstCall?.pagination?.numPages > 1 && page > 1) {
        const promiseTimes: number[] = [];
        for (let i = 2; i <= page; i++) {
          promiseTimes.push(i);
        }

        const dataPromiseAll = await Promise.all(
          promiseTimes.map((page) => {
            return service.getNotificationAll({
              categoryId: params.category,
              page: page,
              size: COMMON.DEFAULT_SIZE,
              keyword: keyword,
            });
          })
        );

        dataPromiseAll.forEach((responseData) => {
          data = [...data, ...responseData.results];
        });
      }

      setNotifications(data);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoadingWorkflow(false));
    }
  };

  const deleteNotification = async (data: any) => {
    try {
      dispatch(setLoadingWorkflow(true));
      const response = await service.deleteNotificationDelete({
        listNotiId: [data?.id],
      });
      if (data.category)
        await refreshDataAfterDeleting({
          category: data.category,
          page: data.page,
        });
      setIsRefresh(!isRefresh);
      SuccessNotification(
        response?.data?.message ??
          NOTIFICATION_TITLE.MESSAGE_DELETE_NOTIFICATION_SUCCESS
      );
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoadingWorkflow(false));
    }
  };

  const readNotification = async (data: any) => {
    try {
      const response = await service.putNotificationMarkRead({
        listNotiId: [data.id],
      });
      const newNotification = notifications?.map((notification) => {
        const objNoti = {
          ...notification,
        };
        if (data.id === notification?.id) objNoti.isRead = true;
        return objNoti;
      });
      setNotifications(newNotification);
      setIsRefresh(!isRefresh);
      SuccessNotification(
        response?.data?.message ??
          NOTIFICATION_TITLE.MESSAGE_DELETE_NOTIFICATION_SUCCESS
      );
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoadingWorkflow(false));
    }
  };
  return {
    closePM009_1,
    onScrollData,
    deleteNotification,
    readNotification,
    openPM009_1,
    getNotificationCategory,
    getNotificationAll,
    refreshDataAfterDeleting,
    getNotificationUnreadCount,
    onChooseCategory,
    onCloseCategory,
    countNotiCategories,
    localVisible,
    notifications,
    page,
    unreadCount,
    isRefresh,
  };
};

export default Handler;
