import { AxiosResponse } from 'axios';
import axiosConfig from '../../../../../config/axiosConfig';

const AM008Api = () => {
    const getAttendanceConstructionListMyMember = async (params: {
        constructionId?: number;
        from: string; // 2006-01-02
        to: string; // 2006-01-02
        timeOffset: number;
    }): Promise<AxiosResponse<any, any>> => {
        const url = '/attendance/construction/list-my-member';
        return await axiosConfig.get(url, { params });
    };

    const getAttendanceOffsiteListMyMember = async (params: {
        from: string; // 2006-01-02
        to: string; // 2006-01-02
        timeOffset: number;
    }): Promise<AxiosResponse<any, any>> => {
        const url = '/attendance/offsite/list-my-member';
        return await axiosConfig.get(url, { params });
    };

    const getAttendanceUserTimeSheet = async (params: {
        date: string;
        constructionId?: string;
        contractorConstructionId?: string;
        userId: string;
        timeOffset: number;
    }): Promise<AxiosResponse<any, any>> => {
        const url = '/attendance/user-time-sheet';
        return await axiosConfig.get(url, { params });
    };

    const doExportAM008 = async (params: any): Promise<any> => {
      try {
          const url = '/attendance/exportAM008';
          const response = await axiosConfig.get(url, { params, responseType: 'blob'  });
          
          return response;
      } catch (error) {
          throw error;
      }
    };
    const doExportAM009 = async (params: any): Promise<any> => {
      try {
          const url = '/attendance/exportAM009';
          const response = await axiosConfig.get(url, { params, responseType: 'blob'  });
          
          return response;
      } catch (error) {
          throw error;
      }
    };

    return {
        getAttendanceConstructionListMyMember,
        getAttendanceOffsiteListMyMember,
        getAttendanceUserTimeSheet,
        doExportAM008,
        doExportAM009,
    };
};

export default AM008Api;
