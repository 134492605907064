import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ExcelIcon from "../../../../../assets/icons/excel-icon.svg";
import CsvIcon from "../../../../../assets/icons/csv-icon.svg";
import Copy2 from "../../../../../assets/icons/copy-2.svg";
import DeleteIcon from "../../../../../assets/icons/remove-icon.svg";

import { Button, Progress } from "antd";
import UploadDragComponent from "../../../../../common/components/upload-drag/UploadDragComponent";
import COMMON from "../../../../../common/constants/COMMON";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import MESSAGE, {
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import helpers from "../../../../../common/helpers/common";
import { useInterval } from "usehooks-ts";
import * as XLSX from "xlsx";
import { OM005Hanlder } from "../handler/HandlerImpl";
import {
  setDataTable,
  progress,
  setProgress,
  setRoleNameDefaultValue,
  setDataTableView,
  setDataUpload,
} from "../slice/Slice";
import Papa from "papaparse";

interface props {
  handler: OM005Hanlder;
}

const DELAY_TIME = 500;

const OM005Section2 = ({ handler }: props) => {
  const dispatch = useDispatch();
  const prog = useSelector(progress);
  const [fileList, setFileList] = React.useState<any>([]);
  const [fileType, setFileType] = React.useState<any>("");
  const [data, setData] = React.useState<any[]>([]);
  const [isRun, setRun] = React.useState<boolean>(false);

  // ON/OFF
  const [isPlaying, setPlaying] = React.useState<boolean>(false);

  useInterval(
    () => {
      // Your custom logic here
      dispatch(setProgress(prog + 20));
    },
    // Delay in milliseconds or null to stop it
    isPlaying ? (prog < 100 ? DELAY_TIME : null) : null
  );

  const uploadDragComponentProps = {
    accept:
      ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
    beforeUpload: (file: any) => {
      // CHECK TYPE FILE (CSV | XLSX)
      const isPNG = COMMON.ALLOW_TYPE_FILE.includes(file.type);

      if (!isPNG) {
        ErrorNotification(MESSAGE.MESSAGE_UPLOAD, "");
        return false;
      }

      // SET FILE TYPE

      setFileType(helpers.checkFileType(file));

      setFileList([file]);

      return false;
    },
    fileList,
  };

  const removeFile = () => {
    setFileList([]);
  };

  const handleProgressXLSX = async () => {
    setPlaying(true);
    const data = await fileList[0].arrayBuffer();
    const workbook: any = XLSX.read(data);

    var sheet_name_list = workbook.SheetNames;
    const resp: any = XLSX.utils.sheet_to_json(
      workbook.Sheets[sheet_name_list[0]]
    );
    handler.convertDataTable(resp, fileType);
  };

  const handleProgressCSV = () => {
    setPlaying(true);
    Papa.parse(fileList[0], {
      header: true,
      skipEmptyLines: true,
      complete: async (results: any) => {
        setData(results.data);
        setRun(!isRun);
      },
    });
  };

  const handleCancelProgresUploadFile = () => {
    // REMOVE FILE IN FILELIST
    // dispatch(setRoleNameDefaultValue(""));
    dispatch(setDataTable([]));
    dispatch(setDataTableView([]));
    dispatch(setDataUpload([]));
    setFileList([]);
    dispatch(setProgress(0));
    setPlaying(false);
  };

  React.useEffect(() => {
    handler.convertDataTable(data, fileType);
  }, [isRun]);

  return (
    <div className="section2-container">
      <div className="w-100-percent section2-title">
        サンプルファイルをダウンロードし、ファイル内のフォーマットに沿ってメンバー情報を追加してください。
      </div>
      <section className="w-100-percent center-item ">
        <div
          className="section2-file-option center-item file-chosen mr-24"
          onClick={() => handler.exportCSV()}
          style={{ cursor: "pointer" }}
        >
          <img src={CsvIcon} alt="" style={{ marginRight: 8 }} />{" "}
          CSVサンプルをダウンロード
        </div>
        <div
          className="section2-file-option center-item "
          onClick={() => handler.exportEXCEL()}
          style={{ cursor: "pointer" }}
        >
          <img src={ExcelIcon} alt="" style={{ marginRight: 8 }} />{" "}
          XLSXサンプルをダウンロード
        </div>
      </section>
      <section className="w-100-percent mt-51">
        {fileList.length === 0 && prog === 0 && (
          <UploadDragComponent props={uploadDragComponentProps}>
            <div className="w-100-percent center-item mt-16">
              <img alt="" src={Copy2} />
            </div>
            <div className="w-100-percent center-item mt-16 upload-file-title">
              ファイル選択、またはドラッグ＆ドロップでファイルを追加してください。
            </div>
            <Button size="large" className="mt-32  mb-16">
              ファイルを選択
            </Button>
          </UploadDragComponent>
        )}
        {prog === 0 && fileList.length !== 0 && (
          <div className="om005__upload-preview center-item">
            <div className="om005__upload-card">
              <img
                alt=""
                src={fileType === COMMON.EXCEL_KEY_TYPE ? ExcelIcon : CsvIcon}
              />
              <p>{fileList[0].name}</p>
              <img
                alt=""
                src={DeleteIcon}
                style={{ cursor: "pointer" }}
                onClick={removeFile}
              />
            </div>
          </div>
        )}
        {prog > 0 && (
          <div className="om005__upload-progress">
            <div className="om005__upload-progress-text center-item">
              {prog < 100 ? (
                <>
                  アップロード中 &nbsp;{" "}
                  <b style={{ color: "green", fontSize: 16 }}>{prog}%</b>
                </>
              ) : (
                <>
                  <b style={{ color: "green", fontSize: 16 }}>
                    アップロード完了
                  </b>
                </>
              )}
            </div>
            <div className="om005__upload-progess-bar center-item">
              <Progress
                style={{ marginTop: 30, width: 553 }}
                percent={prog}
                showInfo={false}
              />
            </div>
          </div>
        )}
      </section>
      <section className="w-100-percent center-item mt-32">
        {prog == 0 && (
          <Button
            size="large"
            type="primary"
            style={{ width: 76 }}
            onClick={() => {
              if (fileType === COMMON.EXCEL_KEY_TYPE) {
                handleProgressXLSX();
              } else {
                handleProgressCSV();
              }
            }}
            disabled={fileList.length === 0 ? true : false}
          >
            次へ
          </Button>
        )}
        {prog < 100 && prog > 0 && (
          <Button
            className="om005__button-cancel"
            size="large"
            onClick={() => {
              handleCancelProgresUploadFile();
            }}
          >
            キャンセル
          </Button>
        )}
        {prog === 100 && (
          <Button
            size="large"
            type="primary"
            onClick={() => {
              handleCancelProgresUploadFile();
            }}
          >
            ファイル変更
          </Button>
        )}
      </section>
    </div>
  );
};

export default OM005Section2;
