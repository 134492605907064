import { AM020SliceType } from "./../type/Presenter";
import { createSlice } from "@reduxjs/toolkit";

const initialState: AM020SliceType = {
  loading: false,
  isVisible: false,
  refresh: false,
  requestId: undefined,
  statusCode:""
};

export const am020Slice = createSlice({
  name: "am020",
  initialState,
  reducers: {
    resetAM020: () => initialState,
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsVisibleAM020: (state, action) => {
      state.isVisible = action.payload;
    },
    setRefreshAM020: (state) => {
      state.refresh = !state.refresh;
    },
    setRequestIdAM020: (state, action) => {
      state.requestId = action.payload;
    },
    setStatusCodeAM020: (state, action) => {
      state.statusCode = action.payload;
    },
  },
});

export const {
  setLoading,
  setIsVisibleAM020,
  resetAM020,
  setRequestIdAM020,
  setRefreshAM020,
  setStatusCodeAM020
} = am020Slice.actions;

export default am020Slice.reducer;
