// Built-in
import React from 'react';

// Source files
import { useDispatch } from 'react-redux';
import { resetCM0063 } from '../../../CM0063/presenter/slice/Slice';
import { resetCM005 } from '../slice/Slice';

const CM005Handler = () => {
    const dispatch = useDispatch();

    const clearRedux = () => {
        dispatch(resetCM0063());
        dispatch(resetCM005());
    };
    return { clearRedux };
};

export default CM005Handler;
