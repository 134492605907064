import { TAX_CODE } from "../../../common/constants/COMMON";
import { OffsetCost, SummaryDataStateType } from "./entity/Entity";

const totalOffsetSummary = (value: any) => {
  let result = 0;
  // const offsetArray = (value?.offsetCostSummary ?? []).concat(value?.offsetCost ?? []);
  const offsetArray = value?.offsetCostSummary ?? [];
  offsetArray.forEach((element: any) => {
    result += element?.value ?? 0;
  });
  return result;
};
const totalOffsetAmount = (value: any) => {
  let result = 0;
  const offsetArray = value?.offsetCost ?? [];
  offsetArray.forEach((element: any) => {
    result += element?.value ?? 0;
  });
  return result;
};

const getOffsetValue = (record: any, taxFree?: boolean) => {
  const noData = 0;

  if (!record.offsetCost) return noData;

  const offsetCost = record.offsetCost?.find(
    (element: OffsetCost) =>
      element.category.code === (taxFree ? TAX_CODE.TAX_FREE : TAX_CODE.TAX)
  );

  if (!offsetCost) return noData;

  return offsetCost?.value ?? 0;
};
const getOffsetSummaryValue = (record: any, taxFree?: boolean) => {
  const noData = 0;

  if (!record.offsetCostSummary) return noData;

  const offsetCostSummary = record.offsetCostSummary?.find(
    (element: OffsetCost) =>
      element.category.code === (taxFree ? TAX_CODE.TAX_FREE : TAX_CODE.TAX)
  );

  if (!offsetCostSummary) return noData;

  return offsetCostSummary?.value ?? 0;
};
const getDataWithFormula = (outsourceCompanyData: any[]) => {
  const summaryData: SummaryDataStateType = {
    actualCost: 0,
    consumptionTax: 0,
    totalOffset: 0,
    paymentAmountExcludingTax: 0,
    paymentAmountIncludingTax: 0,
    offsetCostTax: 0,
    offsetCostTaxFree: 0,
    offsetCostSummaryTax: 0,
    offsetCostSummaryTaxFree: 0,
  };
  const results: any[] = outsourceCompanyData.map((companyElement: any) => {
    let totalTaxCompany: number = 0; // Tổng thuế của các dự án ở #9.4
    const formulatedProjectList = companyElement?.projectList.map(
      (project: any) => {
        let totalTaxProject: number = 0;
        const formulatedOutsourceCost = project?.outsourceCost.map(
          (cost: any) => {
            const totalOffset =
              totalOffsetAmount(cost) + totalOffsetSummary(cost);
            const paymentAmountExcludingTax =
              (cost?.actualCost ?? 0) - totalOffset;
            const consumptionTax = Math.floor(
              (paymentAmountExcludingTax +
                getOffsetValue(cost) +
                getOffsetSummaryValue(cost)) *
                (cost?.taxSetting ?? 1)
            );
            const paymentAmountIncludingTax =
              paymentAmountExcludingTax + consumptionTax;
            totalTaxProject += consumptionTax;
            return {
              ...cost,
              totalOffset,
              paymentAmountExcludingTax,
              paymentAmountIncludingTax,
              consumptionTax,
              companyName: companyElement?.company?.name ?? "",
            };
          }
        );

        const totalOffset =
          totalOffsetAmount(project) + totalOffsetSummary(project);
        const paymentAmountExcludingTax =
          (project?.actualCost ?? 0) - totalOffset;
        const consumptionTax = Math.floor(
          (paymentAmountExcludingTax +
            getOffsetValue(project) +
            getOffsetSummaryValue(project)) *
            (companyElement?.taxSetting ?? 1)
        ); //(Tổng thuế của các dự án ở #9.4 + 業者別相殺金額(課税)x% thuế setting
        const paymentAmountIncludingTax =
          paymentAmountExcludingTax + consumptionTax;
        totalTaxCompany += consumptionTax;
        return {
          ...project,
          outsourceCost: formulatedOutsourceCost,
          totalOffset,
          paymentAmountExcludingTax,
          paymentAmountIncludingTax,
          consumptionTax,
        };
      }
    );

    const totalOffset =
      totalOffsetAmount(companyElement) + totalOffsetSummary(companyElement);
    const paymentAmountExcludingTax =
      (companyElement?.actualCost ?? 0) - totalOffset;
    const consumptionTax = Math.floor(
      (totalTaxCompany + getOffsetSummaryValue(companyElement)) *
        (companyElement?.taxSetting ?? 1)
    ); //(Tổng thuế của các dự án ở #9.4 + 業者別相殺金額(課税)x% thuế setting
    const paymentAmountIncludingTax =
      paymentAmountExcludingTax + consumptionTax;

    summaryData.actualCost += companyElement?.actualCost;
    summaryData.consumptionTax += consumptionTax;
    summaryData.totalOffset += totalOffset;
    summaryData.paymentAmountExcludingTax += paymentAmountExcludingTax;
    summaryData.paymentAmountIncludingTax += paymentAmountIncludingTax;
    summaryData.offsetCostTax += getOffsetValue(companyElement);
    summaryData.offsetCostTaxFree += getOffsetValue(companyElement, true);
    summaryData.offsetCostSummaryTax += getOffsetSummaryValue(companyElement);
    summaryData.offsetCostSummaryTaxFree += getOffsetSummaryValue(
      companyElement,
      true
    );
    return {
      ...companyElement,
      projectList: formulatedProjectList,
      totalOffset,
      paymentAmountExcludingTax,
      paymentAmountIncludingTax,
      consumptionTax,
    };
  });
  return { data: results, summary: summaryData };
};

export {
  totalOffsetSummary as totalOffsetAmountCompany,
  totalOffsetAmount,
  getDataWithFormula,
};
