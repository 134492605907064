import { Tooltip } from "antd";
import { TooltipPlacement } from "antd/es/tooltip";
import React, { useState, useRef, useEffect } from "react";

type props = {
  content: string;
  showTooltip?: string;
  placement?: TooltipPlacement;
  colorText?: string;
  isLarge?: boolean;
};

function TooltipText({
  content,
  showTooltip,
  placement,
  colorText,
  isLarge,
}: props) {
  const [isViewTooltip, setIsViewTooltip] = useState(false);
  const refText: any = useRef(null);

  useEffect(() => {
    if (
      refText &&
      refText.current &&
      refText.current.clientWidth !== refText.current.scrollWidth
    ) {
      setIsViewTooltip(true);
    }
  }, [content]);

  return (
    <Tooltip
      placement={placement}
      title={isViewTooltip ? showTooltip ?? content : ""}
      overlayInnerStyle={{ width: isLarge ? 700 : undefined }}
    >
      <div
        ref={refText}
        className="tooltip-text"
        style={{ color: colorText ? colorText : undefined }}
      >
        {content}
      </div>
    </Tooltip>
  );
}

export default TooltipText;
