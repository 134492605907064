import { useEffect } from "react";
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import L from "leaflet";
import { Button, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { closeModalStreetMap } from "./Slice";
import XIconModal from "../../../assets/icons/x-icon-modal.svg";

const OpenStreetMapComponent = () => {
  const dispatch = useDispatch();

  const DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
  });

  L.Marker.prototype.options.icon = DefaultIcon;

  const isVisible = useSelector(
    (state: RootState) => state.openStreetMap.isVisible
  );
  const lat = useSelector((state: RootState) => state.openStreetMap.lat);
  const lng = useSelector((state: RootState) => state.openStreetMap.lng);

  const ResizeMap = () => {
    const map = useMap();
    useEffect(() => {
      map.invalidateSize();
    }, [map]);
    return null;
  };

  return (
    <Modal
      closable={false}
      cancelButtonProps={{ style: { display: "none" } }}
      open={isVisible}
      width={1000}
      onCancel={() => dispatch(closeModalStreetMap())}
      onOk={() => dispatch(closeModalStreetMap())}
      footer={
        <Button
          size="large"
          type="primary"
          style={{ marginLeft: 16, width: 92, letterSpacing: -1.5 }}
          onClick={() => dispatch(closeModalStreetMap())}
        >
          閉じる
        </Button>
      }
    >
      <div
        className="w-100-percent header-modal-containter"
        style={{
          height: 50,
          borderBottom: "1px solid rgba(218, 226, 238, 1)",
          marginBottom: 24,
        }}
      >
        <div className="header-modal-title-om004 ">登録地点</div>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => dispatch(closeModalStreetMap())}
        >
          <img alt="" src={XIconModal} style={{ height: 18, width: 18 }} />
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <MapContainer
          center={[lat, lng]}
          zoom={30}
          scrollWheelZoom={false}
          style={{ width: "100%", height: "calc(60vh - 4rem)" }}
        >
          <ResizeMap />
          <TileLayer
            url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={[lat, lng]}>
            <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
            </Popup>
          </Marker>
        </MapContainer>
      </div>
    </Modal>
  );
};

export { OpenStreetMapComponent };
