import axiosConfig from "../../../../../config/axiosConfig";
import { GroupEntity } from "../../entity/Entity";

const OM008Api = () => {
  const updateGroup = async (data: GroupEntity): Promise<any> => {
    try {
      const url = "/organization/update-group";
      const response = await axiosConfig.put(url, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  return {
    updateGroup,
  };
};

export default OM008Api;
