import axiosConfig from '../../../../../config/axiosConfig';
import { Params } from '../../presenter/type/Presenter';

const CM0012_2Api = () => {
    const getReSummarySubmittedOutsourceOffsetCost = async (params: Params): Promise<any> => {
        try {
            const url = '/re-summary/submitted/outsource/offset-cost';
            const response = await axiosConfig.get(url, { params });
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    return {
        getReSummarySubmittedOutsourceOffsetCost,
    };
};

export default CM0012_2Api;
