import TabHandler from './presenter/handler/Handler';
import { MainUI } from './presenter/ui/UI';

const TabComponent = () => {
    const handler = TabHandler();

    return <MainUI handler={handler} />;
};

export default TabComponent;
