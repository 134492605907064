import DB006Api from "./infrastructure/api/Api";
import DB006Handler from "./presenter/handler/Handler";
import DB006UI from "./presenter/ui/UI";
import DM006Service from "./usecase/Service";

const DB006Component = () => {
  const db006Api = DB006Api();
  const db006Service = DM006Service(db006Api);
  const db006Handler = DB006Handler(db006Service);
  return <DB006UI handler={db006Handler} />;
};

export default DB006Component;
