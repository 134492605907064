import { useState } from "react";
// Redux
import { useDispatch, useSelector } from "react-redux";

// Services

import { IC1UseCase } from "../../usecase/ServiceImpl";
import { useSearchParams } from "react-router-dom";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import { ProfileCompanyType } from "../../entity/Entity";
import { Form } from "antd";
import ConfirmModal from "../../../../../common/components/modal/ConfirmModal";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import moment from "moment";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import helpers from "../../../../../common/helpers/common";
import { setProfileCompany } from "../slice/Slice";
import { RootState } from "../../../../../store";

export const DEFAULT_TAB_VALUE = "companyInfor";
export const DEFAULT_TAB_KEY = "tab";
export const TYPE_PARAM = "type";
export const TYPE_COMPANY_INFOR = {
  view: "viewInfor",
  edit: "editInfor",
};
const IC1Handler = (service: IC1UseCase) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [searchParams, setSearchParams] = useSearchParams();
  const profileCompany = useSelector(
    (state: RootState) => state.ic1.profileCompany
  );

  const [isEdit, setIsEdit] = useState(false);

  const typeInformationCompany =
    searchParams.get(TYPE_PARAM) || TYPE_COMPANY_INFOR.view;

  const switchTab = (tab: string) => {
    searchParams.set(DEFAULT_TAB_KEY, tab);
    setSearchParams(searchParams);
  };

  const getCompanyProfile = async () => {
    try {
      dispatch(setLoading(true));
      const response: any = await service.getCompanyProfile();
      dispatch(setProfileCompany(response.results));
    } finally {
      dispatch(setLoading(false));
    }
  };
  const redirectEditInforCompany = () => {
    searchParams.set(TYPE_PARAM, TYPE_COMPANY_INFOR.edit);
    setSearchParams(searchParams);
  };
  const onCancel = () => {
    if (isEdit) {
      ConfirmModal({
        onOk: () => {
          searchParams.set(TYPE_PARAM, TYPE_COMPANY_INFOR.view);
          setSearchParams(searchParams);
          form.resetFields();
          setIsEdit(false);
        },
        className: "confirm__modal",
        title: MESSAGE.MESSAGE_020,
        description: MESSAGE.MESSAGE_022_1,
        extraDescription: MESSAGE.MESSAGE_022_2,
        canceText: LABEL_MESSAGE.CANCEL_MODAL,
        okText: LABEL_MESSAGE.OK_MODAL,
        isCenterWithoutMenu: true,
      });
    } else {
      searchParams.set(TYPE_PARAM, TYPE_COMPANY_INFOR.view);
      setSearchParams(searchParams);
    }
  };

  const checkDirty = (): void => {
    const formValue = form.getFieldsValue();
    const formProps = Object.keys(formValue);
    for (let i = 0; i < formProps.length; i++) {
      if (form.isFieldTouched(formProps[i])) {
        return setIsEdit(true);
      }
    }
    setIsEdit(false);
  };

  const onClickMenu = (callback: (value: any) => void, value: any) => {
    if (isEdit) {
      ConfirmModal({
        onOk: () => {
          callback(value);
        },
        onCancel: () => { },
        title: MESSAGE.MESSAGE_020,
        description: MESSAGE.MESSAGE_021,
        canceText: LABEL_MESSAGE.CANCEL_MODAL,
        okText: LABEL_MESSAGE.OK_MODAL,
      });
    } else {
      callback(value);
    }
  };

  const fillValueForm = () => {
    form.setFieldsValue({
      name: profileCompany.name ?? "",
      postNumber: helpers.formatPostNumber(profileCompany.postNumber),
      address: profileCompany.address ?? "",
      phoneNumber: profileCompany.phoneNumber
        ? helpers.toPhoneNumberFormat(profileCompany.phoneNumber)
        : "",
      fax: profileCompany.fax
        ? helpers.toPhoneNumberFormat(profileCompany.fax)
        : "",
      adminFullName: profileCompany.adminFullName ?? "",
      ccusId: profileCompany.ccusId
        ? helpers.convertToCCUSFormat(profileCompany.ccusId)
        : "",
      ccusExpiredDate: moment(profileCompany.ccusExpiredDate, true).isValid()
        ? moment(profileCompany.ccusExpiredDate)
        : null,
    });
  };

  const submitForm = async (value: any) => {
    const payload = {
      address: value.address,
      ccusExpiredDate: value?.ccusExpiredDate?._d?.toISOString() ?? "",
      ccusId: value.ccusId ?? "",
      fax: value.fax.replaceAll("-", "") ?? "",
      fullName: value.adminFullName,
      name: value.name,
      phoneNumber: value.phoneNumber.replaceAll("-", ""),
      postNumber: value.postNumber.replaceAll("-", ""),
      username: profileCompany?.adminName,
    };
    try {
      dispatch(setLoading(true));
      const response = await service.putUpdateCompanyProfile(payload);
      SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
      searchParams.set(TYPE_PARAM, TYPE_COMPANY_INFOR.view);
      setSearchParams(searchParams);
      form.resetFields();
      setIsEdit(false);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  return {
    profileCompany,
    typeInformationCompany,
    form,
    isEdit,
    switchTab,
    getCompanyProfile,
    redirectEditInforCompany,
    onCancel,
    checkDirty,
    onClickMenu,
    submitForm,
    fillValueForm,
  };
};
export default IC1Handler;
