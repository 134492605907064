import { DataLocal } from '../CM0012/presenter/type/Presenter';
import { OutsourceOffsetCost } from './entity/Entity';

const convertDataLocalToReceiveMoneyOffset = (value: DataLocal) => {
    let result: OutsourceOffsetCost[] = [];
    if (value?.arr && value.arr.length > 0) {
        result = value.arr.map((element: any) => {
            const convertedData: OutsourceOffsetCost = {
                categoryId: element.taxCategoryComsumptionId,
                name: element.item,
                value: element.offsetAmount,
            };
            return convertedData;
        });
    }
    return result;
};

export { convertDataLocalToReceiveMoneyOffset };
