import moment from 'moment';
import COMMON from '../../../common/constants/COMMON';

const formatRangeDate = (startDate: string, toDate: string): string => {
  return `${moment(startDate).format(COMMON.FORMAT_DATE_CI)}～${moment(toDate).format(
    COMMON.FORMAT_DATE_CI,
  )}`;
};

export { formatRangeDate };
