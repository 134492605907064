import { Collapse, Divider, Form, Input, Empty } from "antd";

import { PM014ContentHandler } from "../handler/HandlerImpl";
import { useNavigate, useParams } from "react-router-dom";
import REGEX from "../../../../../common/constants/REGEX";
import MESSAGE from "../../../../../common/constants/MESSAGE";
import SubConstructionComponent from "./SubConstructionComponent";
import helpers from "../../../../../common/helpers/common";
import { useEffect, useMemo } from "react";
import { STATUS_APPROVE } from "../handler/Handler";
import { onBlurTrimFormItem } from "../../../../../common/helpers/typeInput";
import COMMON, { PLAN_TYPE } from "../../../../../common/constants/COMMON";

// CSS
import "../../../../../assets/styles/PM/PM005.css";

// Icons
import EditBlue from "../../../../../assets/icons/edit-blue.svg";
import DeleteRed from "../../../../../assets/icons/delete-red.svg";
import IconDown from "../../../../../assets/icons/arrow.svg";
import BuildingSVG from "../../../../../assets/icons/building.svg";
import DocumentGraySVG from "../../../../../assets/icons/document-gray.svg";
import ChartIcon from "../../../../../assets/icons/chartPiePM005Icon.svg";
import {
  isHasPrivilege,
  isMemberPlanBasic,
} from "../../../../../common/helpers/privilege";
import {
  PRIVILEGE_CREATOR,
  PRIVILEGE_MEMBER,
  PRIVILEGE_PM,
  PRIVILEGE_SUB_PM,
} from "../../../../../common/constants/PRIVILEGE";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import {
  AUTHORIZATION_CODE,
  SYSTEM_ROLES,
} from "../../../../../common/constants/AUTHORIZATION";

const { Panel } = Collapse;

interface props {
  handler: PM014ContentHandler;
}
const MESSAGE_REQUIRED =
  "空白のままにすることはできません。データを入力してください。";

const REGEX_NAME = {
  regex: REGEX.NAME_FULL_SIZE,
  message: MESSAGE.MESSAGE_INVALID_INPUT,
};

const MAX_LENGTH = {
  regex: 30,
  message: "",
};
const MAX_LENGTH_NOTE = 300;

const TEXT_USE = "工事略称を入力してください";

const ConstructionRegistrationComponent = ({ handler }: props) => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const order = useSelector((state: RootState) => state.auth?.authData?.order?.code)  //action.payload?.order?.code;
  const isOrderBasic = order === PLAN_TYPE.PLAN_BASIC;
  useEffect(() => {
    if (handler.refNote && handler.refNote.current) {
      handler.refNote.current.focus();
    }
  }, [handler.refNote, handler.noteEdit, handler.noteView]);
  const authDataId = useSelector((state: RootState) => state.auth.authData?.id);
  const permission = useSelector(
    (state: RootState) => state.auth.authData?.permission
  );
  const isRoleAdmin = useSelector((state: RootState) => state.auth?.authData?.isRoleAdmin)

  const isRoleAdminSystem =
    useSelector((state: RootState) => state.auth?.authData?.isRoleAdminSystem)

  const subscription =
    useSelector((state: RootState) => state.auth?.authData?.order?.subscription) ?? []

  const isProjectUserId =
    authDataId === handler.projectInfo?.projectManager?.id + "";

  const userIsPlanFree = useMemo(() => {
    const user = helpers.getObjectFromLocalStorage("user");
    return user.order.code === PLAN_TYPE.PLAN_FREE;
  }, []);

  const canExecutionBudgetList =
    ((permission?.includes(AUTHORIZATION_CODE.S0) &&
      permission?.includes(AUTHORIZATION_CODE.S18)) ||
      permission?.includes(AUTHORIZATION_CODE.B10) ||
      isRoleAdmin || isRoleAdminSystem ||
      isHasPrivilege(handler.projectInfo, [PRIVILEGE_PM])) &&
    !userIsPlanFree;


  const ButtonProjectBalance = (props: { subscription: any[] }) => {
    /* ticket pending */
    let isSubB6,
      isSubM17,
      isSubM18,
      isSubM19 = false;

    for (let element of props.subscription) {
      if (element.code === AUTHORIZATION_CODE.B6) isSubB6 = true;
      else if (element.code === AUTHORIZATION_CODE.M17) isSubM17 = true;
      else if (element.code === AUTHORIZATION_CODE.M18) isSubM18 = true;
      else if (element.code === AUTHORIZATION_CODE.M19) isSubM19 = true;
    }
    return (isOrderBasic && isSubM17 &&
      isHasPrivilege(handler.projectInfo, [PRIVILEGE_PM])) ||
      permission?.includes(AUTHORIZATION_CODE.S42) ? (
      <div
        className="btn-redirect-cm001"
        onClick={() => handler.redirectToCM001()}
      >
        <span>案件収支</span> <img src={ChartIcon} alt="charticon" />
      </div>
    ) : null
  }

  return (
    <>
      <ButtonProjectBalance subscription={subscription} />

      <div className="build-register">工事一覧</div>

      <Form
        id="pm005form"
        form={handler.form}
        name="pm005form"
        onFinish={handler.onSaveConstruction}
      >
        <div className="build-register-container pm005-collapse-border">
          {handler.constructionUI?.main?.map(
            (element: {
              id: number;
              name: string;
              note: string;
              userId: number;
              ciDocumentStatus: {
                name: string;
                code: string;
              };
              crDocumentStatus: {
                name: string;
                code: string;
              };
            }) => {

              const conditionButtonCI = ((permission?.includes(AUTHORIZATION_CODE.S0) &&
                permission?.includes(AUTHORIZATION_CODE.S14)) ||
                permission?.includes(AUTHORIZATION_CODE.B10) ||
                isProjectUserId ||
                isRoleAdmin || isRoleAdminSystem ||
                isHasPrivilege(handler.projectInfo, [
                  PRIVILEGE_PM,
                ]) ||
                handler.approverCIMapper[`${element?.id}`]) &&
                isOrderBasic

              const conditionButtonCR = ((permission?.includes(AUTHORIZATION_CODE.S0) &&
                permission?.includes(AUTHORIZATION_CODE.S18)) ||
                permission?.includes(AUTHORIZATION_CODE.B10) ||
                isProjectUserId ||
                handler.approverCRMapper[`${element?.id}`] ||
                isRoleAdmin || isRoleAdminSystem ||
                isHasPrivilege(handler.projectInfo, [PRIVILEGE_PM])) &&
                isOrderBasic

              const notShowCollapse = !conditionButtonCR && !conditionButtonCI
              return (
                <Collapse
                  key={element.id}
                  className="folder-collapse collapse-main"
                  collapsible={notShowCollapse ? 'disabled' : undefined}
                  activeKey={notShowCollapse ? undefined : handler.keyCollapseMain}
                  onChange={notShowCollapse ? undefined : handler.handleChangeCollapseMain}
                  expandIcon={({ isActive }) => {
                    if (notShowCollapse) return <></>
                    return (
                      <div
                        className={`${isActive ? "collapse-icon-active" : ""
                          } collapse-icon`}
                      >
                        <img src={IconDown} alt="" />
                      </div>
                    );
                  }}
                >
                  <Panel
                    collapsible={
                      !(handler.noteEdit === element?.id)
                        ? undefined
                        : "disabled"
                    }
                    key={`main${element?.id}`}
                    header={
                      <>
                        <div
                          className="tag-wrapper"
                        // onClick={(e: any) => e.stopPropagation()}
                        >
                          <div className="tag-contaner">
                            <img src={BuildingSVG} alt="" />
                            <span style={{ marginLeft: 12 }}>
                              {handler.projectInfo?.parentConstruction?.name ||
                                ""}
                            </span>
                            <div>{element?.name}</div>

                            <div
                              className="pm005-note"
                              onMouseDown={(e) => e.preventDefault()}
                            >
                              <img
                                src={DocumentGraySVG}
                                alt=""
                                onMouseMove={() =>
                                  handler.noteEdit === 0 &&
                                  handler.handleChangeNoteView(element?.id)
                                }
                              />
                              {handler.noteView === element?.id &&
                                element?.id !== 0 && (
                                  <div
                                    className="pm005-note-wrapper"
                                    onMouseLeave={() =>
                                      handler.handleChangeNoteView(
                                        handler?.noteEdit
                                      )
                                    }
                                  >
                                    {handler.noteEdit === element?.id ||
                                      element?.note === "" ? (
                                      <Form.Item
                                        name={`main${element?.id}`}
                                        className="pm005-input-note"
                                      >
                                        <Input.TextArea
                                          disabled={
                                            !(handler.noteEdit === element?.id)
                                          }
                                          placeholder="メモを入力してください。"
                                          autoSize
                                          maxLength={MAX_LENGTH_NOTE}
                                          onChange={(e: any) =>
                                            handler.handleChangeInputArea(
                                              e,
                                              `main${element?.id}`
                                            )
                                          }
                                          onBlur={() => {
                                            handler.handleCloseNoteConstruction();
                                            onBlurTrimFormItem(
                                              handler.form,
                                              `main${element?.id}`
                                            );
                                          }}
                                          onMouseDown={(e) =>
                                            e.stopPropagation()
                                          }
                                          ref={handler.refNote}
                                        />
                                      </Form.Item>
                                    ) : (
                                      <span className="pm005-note-text">
                                        {element?.note ? (
                                          element?.note
                                        ) : (
                                          <Empty
                                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                                            description="データなし"
                                            className="note-transform"
                                          />
                                        )}
                                      </span>
                                    )}

                                    {helpers.checkRoleUpdate(
                                      element?.userId,
                                      handler.projectInfo?.projectManager?.id
                                    ) ? (
                                      <>
                                        <Divider className="pm005-note-divider" />
                                        {!(handler.noteEdit === element?.id) ? (
                                          <div className="pm005-note-btn">
                                            <div
                                              className="pm005-note-edit"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                handler.setNoteEdit(
                                                  element?.id
                                                );
                                              }}
                                            >
                                              <img
                                                src={EditBlue}
                                                alt=""
                                                className="pm005-note-icon-edit"
                                              />
                                              <span>編集</span>
                                            </div>
                                            {element?.note !== "" && (
                                              <div
                                                className="pm005-note-delete"
                                                onClick={() =>
                                                  handler.handleDeleteNoteConstruction(
                                                    element?.id
                                                  )
                                                }
                                              >
                                                <img
                                                  src={DeleteRed}
                                                  alt=""
                                                  className="pm005-note-icon-delete"
                                                />
                                                <span>削除</span>
                                              </div>
                                            )}
                                          </div>
                                        ) : (
                                          <div className="pm005-note-save">
                                            <span
                                              className="pm005-note-save-text"
                                              onClick={() => {
                                                handler.handleSaveUpdateNote(
                                                  element?.id,
                                                  `main${element?.id}`
                                                );
                                              }}
                                            >
                                              保存
                                            </span>
                                            <span
                                              className="pm005-note-cancel-text"
                                              onClick={
                                                handler.handleCancelUpdateNote
                                              }
                                            >
                                              キャンセル
                                            </span>
                                          </div>
                                        )}
                                      </>
                                    ) : null}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </>
                    }
                  >
                    <div className="tag-contaner-white">
                      {
                        conditionButtonCI ?
                          <div
                            style={{ cursor: "pointer" }}
                            className="tag-white-container pm005-btn-view"
                            onClick={() => handler.onViewConstructionInquiry(element?.id, projectId || "")}
                          >
                            <span>工事引合書</span>
                            <div
                              className="pm005-status-approve"
                              style={{
                                color:
                                  STATUS_APPROVE[
                                    element.ciDocumentStatus?.code || "reject"
                                  ].color,
                                backgroundColor:
                                  STATUS_APPROVE[
                                    element.ciDocumentStatus?.code || "reject"
                                  ].background_color,
                              }}
                            >
                              {element.ciDocumentStatus?.name}
                            </div>
                          </div>
                          : <></>
                      }
                      {
                        conditionButtonCR ?
                          <div
                            style={{ cursor: conditionButtonCR ? "pointer" : "not-allowed", }}
                            className="tag-white-container pm005-btn-view"
                            onClick={() => conditionButtonCR && handler.onViewRunningBudget(element?.id, projectId || "")
                            }
                          >
                            <span>実行予算書</span>
                            <div
                              className="pm005-status-approve"
                              style={{
                                color:
                                  STATUS_APPROVE[
                                    element.crDocumentStatus?.code || "reject"
                                  ].color,
                                backgroundColor:
                                  STATUS_APPROVE[
                                    element.crDocumentStatus?.code || "reject"
                                  ].background_color,
                              }}
                            >
                              {element.crDocumentStatus?.name}
                            </div>
                          </div>
                          : <></>
                      }

                    </div>
                  </Panel>
                </Collapse>
              );
            }
          )}
          <SubConstructionComponent handler={handler} />
          {
            canExecutionBudgetList ?
              <div
                className="tag-contaner pm005-document-view"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/app-crt001/${projectId}`);
                  localStorage.setItem(
                    COMMON.PARENT_CONSTRUCTION,
                    handler.projectInfo?.parentConstruction?.name
                  );
                }}
              >
                <img src={DocumentGraySVG} alt="" />
                <div
                  style={{
                    marginLeft: 16,
                  }}
                >
                  実行予算一覧
                </div>
              </div>
              : <></>
          }

        </div>
      </Form>
    </>
  );
};

export default ConstructionRegistrationComponent;
