import { useRef, useState } from "react";
import { RootState } from "../../../../store";
import { Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ErrorNotification from "../../notification/ErrorNotification";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../constants/MESSAGE";
import ConfirmModal from "../../modal/ConfirmModal";
import { resetApproveViewModal, setIsEdit } from "../presenter/slice/Slice";
import { propsApproveViewModal } from "..";

const Handler = ({ callbackCancel, callbackOk }: propsApproveViewModal) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [localLoading, setLocalLoading] = useState<boolean>(false);
  const document = useSelector(
    (state: RootState) => state.modalApproveView.document
  );

  const resetModal = () => {
    setIsDirty(false);
    setLocalLoading(false);
    dispatch(resetApproveViewModal());
    form.resetFields();
  };

  const callAPI = async () => {
    try {
      setLocalLoading(true);
      if (callbackOk) {
        const payload = {
          ...document,
          note: form.getFieldValue("explanation"),
        };
        delete payload.status;
        await callbackOk(payload);
        resetModal();
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      setLocalLoading(false);
    }
  };

  const onCancel = () => {
    const isTouched = (form.getFieldValue("explanation") ?? "") === "";
    if (!isTouched) {
      ConfirmModal({
        onOk: () => {
          resetModal();
          if (callbackCancel) callbackCancel();
        },
        onCancel: () => {},
        title: MESSAGE.MESSAGE_020,
        description: MESSAGE.MESSAGE_021,
        canceText: LABEL_MESSAGE.CANCEL_MODAL,
        okText: LABEL_MESSAGE.OK_MODAL,
      });
    } else {
      resetModal();
      if (callbackCancel) callbackCancel();
    }
  };

  const checkDirty = () => {
    const isTouched = form.getFieldValue("explanation") !== "" ? true : false;
    setIsDirty(isTouched);
  };

  const changeToEditState = () => {
    form.setFieldValue("explanation", document?.note ?? "");
    dispatch(setIsEdit(true));
  };

  return {
    callAPI,
    onCancel,
    checkDirty,
    changeToEditState,
    form,
    isDirty,
    localLoading,
  };
};

export default Handler;
