import { CM0039UseCase } from "./../../usecase/ServiceImpl";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../../common/constants/MESSAGE";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import {
  setDocumentIdCM0017_3,
  setDocumentInfoCM0017_3,
  setEnableRetrieveButton,
  setHideRetrieveButton,
} from "../../../CM0017_3/presenter/slice/Slice";
import COMMON, {
  STATUS_CM_CHILD_TABLE,
} from "../../../../../common/constants/COMMON";
import moment from "moment";
import { CM003_PARAM_SUFFIX } from "../ui/UI";
import { refreshCM0039 } from "../slice/Slice";
import helpers from "../../../../../common/helpers/common";

const CM0039Handler = (service: CM0039UseCase) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [total, setTotal] = useState(0);
  const [costList, setCostList] = useState<any[]>([]);
  const [visibleRejectView, setVisibleRejectView] = useState(false);
  const [rejectReason, setRejectReason] = useState("");

  const getReSummaryCompanyDocumentOutSourceCostSubmitedList = async (params: {
    statusId?: number;
    page?: number;
    size?: number;
    sortBy?: string;
    sortType?: string;
    from?: string;
    to?: string;
    keyword?: string;
  }): Promise<any> => {
    try {
      dispatch(setLoading(true));
      const responseData =
        await service.getReSummaryCompanyDocumentOutSourceCostSubmitedList(
          params
        );
      let filteredResult = responseData?.results ?? [];
      setCostList(filteredResult);
      setTotal(responseData.pagination.count);
    } catch (error: any) {
      ErrorNotification(error.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const onPageChange = (page: number) => {
    searchParams.set("page" + CM003_PARAM_SUFFIX, String(page));
    setSearchParams(searchParams);
  };

  const onSizeChange = (size: number) => {
    searchParams.set("size" + CM003_PARAM_SUFFIX, String(size));
    searchParams.set("page" + CM003_PARAM_SUFFIX, String(COMMON.DEFAULT_PAGE));

    setSearchParams(searchParams);
  };

  const checkSortOrder = (
    columnName: string,
    sortType: string | null,
    sortBy: string | null
  ): "ASC" | "DESC" | undefined => {
    if (columnName === sortBy) {
      return !sortType ? undefined : sortType === "ASC" ? "ASC" : "DESC";
    }
  };
  const onTableSort = (column: string, sortOrder: string) => {
    searchParams.set("sortBy" + CM003_PARAM_SUFFIX, column);
    searchParams.set("sortType" + CM003_PARAM_SUFFIX, sortOrder);
    setSearchParams(searchParams);
  };

  const openCM0017_3 = (value: any) => {
    dispatch(setDocumentIdCM0017_3(value.id));
    const targetPeriod = helpers.toTargetPeriodFormatYYYYMM(
      value?.startDate,
      value?.endDate
    );
    dispatch(
      setDocumentInfoCM0017_3({
        documentTitle: value?.fileName ?? "",
        createdDate: value?.createdAt
          ? moment(value.createAt).format(COMMON.FORMAT_DATE_OM)
          : "",
        targetPeriod: targetPeriod,
        paymentMonth: value.submittedDate
          ? moment(value.submittedDate).format(COMMON.FORMAT_DATE_JA_MONTH)
          : "",
        from: value.startDate,
        to: value.endDate
      })
    );
    dispatch(
      setEnableRetrieveButton(
        value?.status?.code === STATUS_CM_CHILD_TABLE.MONEY_REJECT.CODE
        || value?.status?.code === STATUS_CM_CHILD_TABLE.MONEY_APPROVED.CODE
      )
    );
    dispatch(setHideRetrieveButton(false));
  };
  const afterCM0017_3Success = () => {
    dispatch(refreshCM0039());
  };
  return {
    total,
    costList,
    visibleRejectView,
    rejectReason,
    getReSummaryCompanyDocumentOutSourceCostSubmitedList,
    onPageChange,
    onSizeChange,
    checkSortOrder,
    onTableSort,
    openCM0017_3,
    afterCM0017_3Success,
    setVisibleRejectView,
    setRejectReason,
  };
};

export default CM0039Handler;
