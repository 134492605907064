import { useDispatch } from "react-redux";
import { ServiceImpl } from "../../usecase/ServiceImpl";
import { setLoading } from "../../../../common/slice/CommonSlice";
import ErrorNotification from "../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../common/constants/MESSAGE";
import {
  setIsDoubleCheck,
  setIsReWorkflow,
  setIsUserInWorkspace,
  setIsUserIsProjectManager,
  setIsVerifiedCompany,
  setIsWorkFlowUserIsInWorkFlows,
} from "../../../../common/slice/AuthSlice";
import { UserIsReWorkflow } from "../../entity/Entity";

export const Handler = (service: ServiceImpl) => {
  const dispatch = useDispatch();

  const getUserIsReWorkflow = async () => {
    try {
      dispatch(setLoading(true));
      const responseResults = await service.getUserIsReWorkflow();
      let isReWorkflow: boolean = Object.keys(responseResults).some(
        (property) => {
          return !!responseResults[`${property}` as keyof UserIsReWorkflow];
        }
      );

      dispatch(setIsReWorkflow({ ...responseResults, isReWorkflow }));
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };
  const getUserIsDoubleCheck = async () => {
    try {
      dispatch(setLoading(true));
      const responseResults = await service.getUserIsDoubleCheck();
      dispatch(setIsDoubleCheck(responseResults));
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };
  const getProjectCheckUserInWorkspace = async () => {
    try {
      dispatch(setLoading(true));
      const responseResults = await service.getProjectCheckUserInWorkspace();
      dispatch(setIsUserInWorkspace(responseResults));
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getUserIsProjectManager = async (isHaveSub: boolean) => {
    try {
      if (!isHaveSub) dispatch(setIsUserIsProjectManager(false));
      dispatch(setLoading(true));
      const responseResults = await service.getUserIsProjectManager();
      dispatch(setIsUserIsProjectManager(responseResults));
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getWorkFlowUserIsInWorkFlows = async () => {
    try {
      dispatch(setLoading(true));
      const responseResults = await service.getWorkFlowUserIsInWorkFlows();
      dispatch(setIsWorkFlowUserIsInWorkFlows(responseResults));
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getIsVerifiedCompany = async () => {
    try {
      dispatch(setLoading(true));
      const responseResults = await service.getIsVerifiedCompany();
      dispatch(setIsVerifiedCompany(responseResults));
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };
  return {
    getUserIsReWorkflow,
    getUserIsDoubleCheck,
    getProjectCheckUserInWorkspace,
    getUserIsProjectManager,
    getWorkFlowUserIsInWorkFlows,
    getIsVerifiedCompany
  };
};
