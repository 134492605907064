import { AxiosResponse } from "axios";
import axiosConfig from "../../../../../config/axiosConfig";

const CM0015Api = () => {
  const getMoneyIndirectCostConstructionDirectCost = async (params: {
    constructionId: number;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/money/indirect-cost/construction/direct-cost";
    return await axiosConfig.get(url, { params });
  };
  return { getMoneyIndirectCostConstructionDirectCost };
};

export default CM0015Api;
