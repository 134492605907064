import AM0061Api from './infrastructure/api/Api';
import CM0061Handler from './presenter/handler/Handler';
import CM0061Service from './usecase/Service';
import MainUI from './presenter/ui/UI';
import '../../../assets/styles/CM/CM0061.css';
import CM001Api from '../CM001/infrastructure/api/Api';
import CM001Service from '../CM001/usecase/Service';
import CM001Handler from '../CM001/presenter/handler/Handler';

const AM0061Component = () => {
    const api = AM0061Api();
    const service = CM0061Service(api);
    const handler = CM0061Handler(service);

    const cm001Api = CM001Api();
    const cm001Service = CM001Service(cm001Api);
    const cm001Handler = CM001Handler(cm001Service);

    return <MainUI handler={handler} cm001Handler={cm001Handler} />;
};

export default AM0061Component;
