import moment from 'moment';
import COMMON from '../../../common/constants/COMMON';
import helpers from '../../../common/helpers/common';

const convertPieChart = (item: any) => {
    return [
        {
            id: '完成出来高',
            label: '完成出来高',
            code: 'tv',
            value: item?.billingVolumeRate ?? 0,
        },
        {
            id: '請求残高',
            label: '請求残高',
            code: 'cl',
            value: item?.billingBalanceRate ?? 0,
        },
    ];
};

const convertGraph4 = (items: any[]) => {
    const rs: any[] = [];
    let mapper: any = {};
    let mapperMonth: any = {};

    items.forEach((item: any) => {
        const month = `${item.year}年${item.month < 10 ? '0' : ''}${item.month}月`;
        if (mapperMonth[month] !== undefined) mapperMonth[month] += item?.totalMandays ?? 0;
        else mapperMonth[month] = item?.totalMandays ?? 0;
    });

    Object.keys(mapperMonth).forEach((key: string, idx: number) => {
        rs.push({
            x: 10 + idx * 10,
            y: mapperMonth[key],
        });
        mapper[`${10 + idx * 10}`] = key;
    });

    const dt = [
        {
            id: '稼働人工数',
            data: rs,
        },
    ];

    return [dt, mapper];
};

const convertLineChart = (items: any[]) => {
    const rs: any[] = [];
    items.forEach((ite: any) => {
        rs.push({
            date: `${ite?.year ?? ''}年${ite?.month ?? ''}月`,
            人工: ite?.totalMandays ?? 0,
        });
    });
    return rs;
};

const convertDataTable = (items: any[], callbackF: Function) => {
    const rs: any[] = [];

    items.forEach((item: any, idx: number) => {
        rs.push({
            key: idx + Math.random(),
            projectId: item.projectId,
            projectName: item?.projectName ?? '',
            contractCost: item?.contractCost ?? 0,
            billingVolume: item?.billingVolume ?? 0,
            billingVolumeRate: item?.billingVolumeRate ?? 0,
            billingBalance: item?.billingBalance ?? 0,
            billingBalanceRate: item?.billingBalanceRate ?? 0,
            constructionFromDate: item?.constructionFromDate ?? '',
        });
    });

    const newRs: any = [];
    for (const ele of rs) {
        newRs.push(ele.billingBalance + ele.billingVolume);
    }
    let maxValue = 0;
    newRs.forEach((item: number) => (maxValue < item ? (maxValue = item) : (maxValue = maxValue)));
    callbackF(helpers.getMaxValueYAxis(maxValue));
    return rs;
};

const convertGraph3 = (item: any, callback: Function, callbackF: Function) => {
    if (
        item['materialCost'].planCost === 0 &&
        item['materialCost'].actualCost === 0 &&
        item['outsourceCost'].planCost === 0 &&
        item['outsourceCost'].actualCost === 0 &&
        item['laborCost'].planCost === 0 &&
        item['laborCost'].actualCost === 0 &&
        item['indirectCost'].planCost === 0 &&
        item['indirectCost'].actualCost === 0
    ) {
        callback(true);
    } else callback(false);

    let maxValue = 0;

    const data = [
        item['materialCost'].planCost,
        item['materialCost'].actualCost,
        item['outsourceCost'].planCost,
        item['outsourceCost'].actualCost,
        item['laborCost'].planCost,
        item['laborCost'].actualCost,
        item['indirectCost'].planCost,
        item['indirectCost'].actualCost,
    ];

    data.forEach((item: number) => (maxValue < item ? (maxValue = item) : (maxValue = maxValue)));

    callbackF(helpers.getMaxValueYAxis(maxValue));

    return [
        {
            projectName: 'materialCost',
            originPlan: item['materialCost'].planCost,
            plan: item['materialCost'].planCost - item['materialCost'].actualCost,
            actual: item['materialCost'].actualCost,
        },
        {
            projectName: 'outsourceCost',
            originPlan: item['outsourceCost'].planCost,
            plan: item['outsourceCost'].planCost - item['outsourceCost'].actualCost,
            actual: item['outsourceCost'].actualCost,
        },
        {
            projectName: 'laborCost',
            originPlan: item['laborCost'].planCost,
            plan: item['laborCost'].planCost - item['laborCost'].actualCost,
            actual: item['laborCost'].actualCost,
        },
        {
            projectName: 'indirectCost',
            originPlan: item['indirectCost'].planCost,
            plan: item['indirectCost'].planCost - item['indirectCost'].actualCost,
            actual: item['indirectCost'].actualCost,
        },
    ];
};

const convertKeysChartTransactionVolume = (data: any) => {
    const resultKeys: any = [];
    Object.keys(data).map((item: any) => {
        resultKeys.push(item);
    });
    return resultKeys.slice(3);
};

const convertKeysBudgetChart = (data: any) => {
    const resultKeys: any = [];
    Object.keys(data).map((item: any) => {
        resultKeys.push(item);
    });
    return resultKeys.slice(1).reverse();
};

/**
 * 
 * @param data 
 * @returns sample ouput
 * {
 *      2022: 3,
 *      2023: 12
 * }
 */
const getNumOfColByYear = (data: any[] = []) => {
    const result: any = {}
    data.forEach((item: any) => {
        if(result[item.year] != undefined) {
            result[item.year] ++
        }else {
            result[item.year] = 1
        }
    })
    return result
}

export {
    convertPieChart,
    convertGraph4,
    convertDataTable,
    convertGraph3,
    convertKeysChartTransactionVolume,
    convertKeysBudgetChart,
    convertLineChart,
    getNumOfColByYear
};
