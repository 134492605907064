import axiosConfig from "../../../../../config/axiosConfig";
import {
  WorkflowCreatePayload,
  WorkflowDeletePayload,
  WorkflowUpdatePayload,
} from "../../entity/Entity";

const SW02Api = () => {
  const getWorkflowList = async (params: { categoryId?: number }) => {
    const url = "/workflow/list";
    return axiosConfig.get(url, { params });
  };

  const getWorkflowDetail = async (params: { workflowId: number }) => {
    const url = "/workflow/detail";
    return axiosConfig.get(url, { params });
  };
  const getWorkIndexName = async () => {
    const url = "/workflow/index-name";
    return axiosConfig.get(url);
  };
  const postWorkflowCreate = async (payload: WorkflowCreatePayload) => {
    const url = "/workflow/create";
    return axiosConfig.post(url, payload);
  };

  const putWorkflowUpdate = async (payload: WorkflowUpdatePayload) => {
    const url = "workflow/update";
    return axiosConfig.put(url, payload);
  };

  const deleteWorkflow = async (payload: WorkflowDeletePayload) => {
    const url = "/workflow/delete";
    return axiosConfig.delete(url, { data: payload });
  };
  const getGroupListMember = async (params: {
    page: number;
    size: number;
    keyword?: string;
    sortBy?: string;
    sortType?: string;
  }) => {
    const url = "/group/list-member-admin";
    return axiosConfig.get(url, { params });
  };
  return {
    getWorkflowList,
    getWorkflowDetail,
    postWorkflowCreate,
    putWorkflowUpdate,
    deleteWorkflow,
    getGroupListMember,
    getWorkIndexName,
  };
};

export default SW02Api;
