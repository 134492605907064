
  import { AM020Api } from "./ServiceImpl";
  
  const AM020Service = (am020: AM020Api) => {
    const getRequestLateEarly = async (params:any): Promise<any> => {
      try{
        return await am020.getRequestLateEarly(params);
      }catch(error){
        throw(error)
      }
    };
    return {
      getRequestLateEarly,
    };

  };
  
  export default AM020Service;
  