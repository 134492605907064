import { CM006SliceType } from './../type/Presenter';
import { createSlice } from '@reduxjs/toolkit';

const initialState: CM006SliceType = {
    loading: false,
    isVisible: false,
    refresh: false,
    documentId: 0,
    documentInfo: {
        createdDocument: '',
        submittedDocument: '',
    },
    status: null,
};

export const cm006Slice = createSlice({
    name: 'cm006',
    initialState,
    reducers: {
        resetCM006: () => initialState,
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setIsVisibleCM006: (state, action) => {
            state.isVisible = action.payload;
        },
        setRefreshCM006: (state) => {
            state.refresh = !state.refresh;
        },
        setDocumentIdCM006: (state, action) => {
            state.documentId = action.payload;
        },
        setDocumentInfo: (state, action) => {
            state.documentInfo = action.payload;
        },
        setStatusCM006: (state, action) => {
            state.status = action.payload;
        },
    },
});

export const {
    setLoading,
    setIsVisibleCM006,
    resetCM006,
    setRefreshCM006,
    setDocumentIdCM006,
    setDocumentInfo,
    setStatusCM006,
} = cm006Slice.actions;

export default cm006Slice.reducer;
