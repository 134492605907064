import AM004Api from './infrastructure/api/Api';
import AM004Handler from './presenter/handler/Handler';
import MainUI from './presenter/ui/UI';
import AM004Service from './usecase/Service';
import AttendanceApi from './infrastructure/api/ApiAttendance';
import AttendanceService from './usecase/ServiceAttendance';

import '../../../assets/styles/AM/AM002.css';
import '../../../assets/styles/OM/OM002.css';
import '../../../assets/styles/OM/OM004.css';
import '../../../assets/styles/AM/AM004.css';
import '../../../assets/styles/AM/AM001.css';

const AM004Component = () => {
  const api = AM004Api();
  const attendanceApi = AttendanceApi();

  const service = AM004Service(api);
  const attendanceService = AttendanceService(attendanceApi);

  const handler = AM004Handler(service, attendanceService);
  return <MainUI handler={handler} />;
};

export default AM004Component;
