import React from 'react';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import EyeViewDetailSvg from '../../../../../assets/icons/eye-view-detail.svg';
import {
    ReSummaryCompanySummaryOutsourceCostResult,
    ReSummaryCompanySummaryOutsourceCostTotalResult,
} from '../../entity/Entity';
import {
    convertMoneyCompanySummaryOutsourceCostResult,
    netPaymentAmountFormula,
} from '../../helper';
import helpers from '../../../../../common/helpers/common';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { AUTHORIZATION_CODE, SYSTEM_ROLES } from '../../../../../common/constants/AUTHORIZATION';
import IconView from '../../../../../assets/icons/eyeIconBlack.svg';
import COMMON, { TAX_CODE } from '../../../../../common/constants/COMMON';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';

type props = {
    openCM0017_2: () => void;
    funcViewModalCM017_1: (value: {
        companyId: number;
        colabRefId: number;
        code: string;
        from?: string | undefined;
        to?: string | undefined;
    }) => void
    taxSetting: number;
    summaryCost: ReSummaryCompanySummaryOutsourceCostResult | undefined;
    summaryTotal: ReSummaryCompanySummaryOutsourceCostTotalResult;

    from?: string;
    to?: string;
};
export const TABLE_CELL_WIDTH_CM0015: number[] = [100, 260, 180, 150, 120, 174, 174, 174, 174, 120];

const TableAboveComponent = ({ summaryTotal, summaryCost, from, to, taxSetting, openCM0017_2, funcViewModalCM017_1 }: props) => {

    const [searchParams] = useSearchParams();
    const companyId = searchParams.get("selectedCompany") ?? "0";
    const colabRefId = searchParams.get("selectedColabRefId") ?? "0";
    const fromDate = searchParams.get('startDate') || moment().format(COMMON.FORMAT_DATE4);
    const toDate = searchParams.get('endDate') || moment().format(COMMON.FORMAT_DATE4);
    const columns: ColumnsType<any> = [
        {
            title: '支払月',
            key: '支払月',
            align: 'center',
            // width: TABLE_CELL_WIDTH_CM0015[0],
            render: (record) => (
                <span className="collapsed-table-row-style">{`${record.paymentDate}`}</span>
            ),
        },
        {
            title: '今回支払額',
            key: '今回支払額',
            align: 'center',
            // width: TABLE_CELL_WIDTH_CM0015[1],
            render: (record) => {
                return (
                    <span className="collapsed-table-row-style">{`${helpers.formatNumberMoney(
                        record.totalCost,
                    )}円`}</span>
                );
            },
        },
        {
            title: '相殺金額合計',
            key: '相殺金額合計',
            align: 'center',
            // width: TABLE_CELL_WIDTH_CM0015[4] + TABLE_CELL_WIDTH_CM0015[5] + TABLE_CELL_WIDTH_CM0015[6],
            children: [
                {
                    title: '案件別相殺合計金額',
                    key: '案件別相殺合計金額',
                    // width: TABLE_CELL_WIDTH_CM0015[6],
                    children: [
                        {
                            title: '課税',
                            key: '課税',
                            align: 'center',
                            // width: TABLE_CELL_WIDTH_CM0015[6],]
                            render: (record) => {
                                return (
                                    <span className="collapsed-table-row-style">
                                        {`${helpers.formatNumberMoney(record.offsetCostWithTax)}円`}
                                    </span>
                                );
                            },
                        },
                        {
                            title: '非課税',
                            key: '非課税',
                            align: 'center',
                            // width: TABLE_CELL_WIDTH_CM0015[5],
                            render: (record) => {
                                return (
                                    <span className="collapsed-table-row-style">
                                        {`${helpers.formatNumberMoney(
                                            record.offsetCostWithoutTax,
                                        )}円`}
                                    </span>
                                );
                            },
                        },
                    ],
                },
                {
                    title: (
                        <main className="center-item">
                            <div>業者別相殺合計金額</div>
                            {/* {permission?.includes(AUTHORIZATION_CODE.S33) || isRoleAdmin ? (
                                <div
                                    style={{ marginLeft: 10 }}
                                    className="button-style"
                                    onClick={() => openCM0017_2()}
                                >
                                    <img alt="" src={EyeViewDetailSvg} />
                                </div>
                            ) : (
                                <div></div>
                            )} */}
                        </main>
                    ),
                    key: '業者別相殺合計金額',
                    align: 'center',
                    // width: TABLE_CELL_WIDTH_CM0015[5],
                    children: [
                        {
                            title: (
                                <div className='center-item'>
                                    <div style={{ marginRight: 8 }}>課税</div>
                                    <img
                                        className="icon-view"
                                        alt=""
                                        src={IconView}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            funcViewModalCM017_1(
                                                {
                                                    companyId: Number(companyId),
                                                    colabRefId: Number(colabRefId),
                                                    code: TAX_CODE.TAX,
                                                    from: from,
                                                    to: to,
                                                }
                                            );
                                        }}
                                    />
                                </div>
                            ),
                            key: '課税',
                            align: 'center',
                            // width: TABLE_CELL_WIDTH_CM0015[6],
                            render: (record) => {
                                return (
                                    <span className="collapsed-table-row-style">
                                        {`${helpers.formatNumberMoney(
                                            record.offsetCostSummaryWithTax,
                                        )}円`}
                                    </span>
                                );
                            },
                        },
                        {
                            title: (
                                <div className='center-item'>
                                    <div style={{ marginRight: 8 }}>非課税</div>
                                    <img
                                        className="icon-view"
                                        alt=""
                                        src={IconView}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            funcViewModalCM017_1(
                                                {
                                                    companyId: Number(companyId),
                                                    colabRefId: Number(colabRefId),
                                                    code: TAX_CODE.TAX_FREE,
                                                    from: from,
                                                    to: to,
                                                }
                                            );
                                        }}
                                    />
                                </div>
                            ),
                            key: '非課税',
                            align: 'center',
                            // width: TABLE_CELL_WIDTH_CM0015[5],
                            render: (record) => {
                                return (
                                    <span className="collapsed-table-row-style">
                                        {`${helpers.formatNumberMoney(
                                            record.offsetCostSummaryWithoutTax,
                                        )}円`}

                                    </span>
                                );
                            },
                        },
                    ],
                },
            ],
        },
        {
            title: '差引支払額', //Công thức: 今回支払額 - 案件別相殺合計金額(Gồm 2 cột 課税 & 非課税) - 業者別相殺合計金額(Gồm 2 cột 課税 & 非課税)
            key: '差引支払額',
            align: 'center',
            // width: TABLE_CELL_WIDTH_CM0015[8],
            render: (record) => {
                const formulaCalculation = netPaymentAmountFormula(
                    taxSetting,
                    summaryTotal.cost,
                    record.offsetCostWithTax,
                    record.offsetCostWithoutTax,
                    record.offsetCostSummaryWithTax,
                    record.offsetCostSummaryWithoutTax,
                );
                return (
                    <span className="collapsed-table-row-style">
                        {`${helpers.formatNumberMoney(formulaCalculation)}円`}
                    </span>
                );
            },
        },
    ];

    return (
        <Table
            bordered={true}
            columns={columns}
            dataSource={convertMoneyCompanySummaryOutsourceCostResult(summaryCost, from, to)}
            className="collapsed-table-header table-footer-inside "
            pagination={false}
        />
    );
};

export default TableAboveComponent;
