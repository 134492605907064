import { useDispatch } from "react-redux";
import { resetApproveConfirmModal } from "../slice/Slice";

export const Handler = () => {
  const dispatch = useDispatch();

  const onCancel = () => {
    dispatch(resetApproveConfirmModal());
  };

  return {
    onCancel,
  };
};
