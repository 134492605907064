import { Table } from 'antd';
import helpers from '../../../../../common/helpers/common';
import { TableHistory } from '../../entity/Entity';
import { CM008Handler } from '../handler/HandlerImpl';

type Props = {
    data: TableHistory[];
    columns: any;
    title?: string;
    handler: CM008Handler;
};

export const CM008TableHasSummary = ({ data, columns, title, handler }: Props) => {
    return (
        <div className="table-bot">
            <Table
                columns={columns}
                dataSource={data}
                bordered
                title={() => title}
                pagination={false}
                summary={() => {
                    return (
                        <Table.Summary.Row
                            className="children-table-summary"
                            style={{
                                background: '#605BFF',
                                color: '#FFFFFF',
                            }}
                        >
                            <Table.Summary.Cell index={1} align="center">
                                <span>合計金額</span>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={2} align="center"></Table.Summary.Cell>
                            <Table.Summary.Cell index={3} align="center"></Table.Summary.Cell>
                            <Table.Summary.Cell index={4} align="center">
                                <span>{`${helpers.formatCurrency(
                                    handler?.dataSummary?.totalPayAmount,
                                )}円`}</span>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={5} align="center">
                                <span>{`${helpers.formatCurrency(
                                    handler?.dataSummary?.totalBillAmount,
                                )}円`}</span>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={6} align="center"></Table.Summary.Cell>
                            <Table.Summary.Cell index={7} className="text-center">
                                <span className="center-item">{`${helpers.formatCurrency(
                                    handler?.dataSummary?.totalActualCost,
                                )}円`}</span>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={8} align="center"></Table.Summary.Cell>
                        </Table.Summary.Row>
                    );
                }}
            />
        </div>
    );
};
