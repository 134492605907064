import { createSlice } from "@reduxjs/toolkit";
import { AM006SliceType } from "../../entity/Entity";

export const initialState: AM006SliceType = {
  isRefresh: false,
  isCollapsed: false,
  tableColumns: {
    columns: [],
    isNext: true,
    isPrev: true,
  },
};

export const AM006Slice = createSlice({
  name: "am006",
  initialState,
  reducers: {
    refreshAM006: (state) => {
      state.isRefresh = !state.isRefresh;
    },
    setIsCollapsed: (state, action) => {
      state.isCollapsed = action.payload;
    },
    setTableColumns: (state, action) => {
      state.tableColumns = action.payload;
    },
  },
});

export const { refreshAM006, setIsCollapsed, setTableColumns } =
  AM006Slice.actions;

export default AM006Slice.reducer;
