import HomeSvg from '../../../../../../assets/icons/home.svg';
import MenuWrapperComponent from '../../../../../../common/components/menu-wrapper';
import { HandlerImpl } from '../handler/HandlerImpl';
import BreadcrumbComponent from '../../../../../../common/components/breadcrumb/BreadcrumbComponent';
import IconSearch from '../../../../../../assets/icons/search-icon.svg';
import { Tabs, Form, Input } from 'antd';
import Body from './Body';
import { useEffect } from 'react';
import helpers from '../../../../../../common/helpers/common';

const { TabPane } = Tabs;

interface props {
    handler: HandlerImpl;
}

const BREADCRUMB_CM3854: any[] = [
    {
        title: '収支管理',
        url: '',
        icon: <img style={{ paddingBottom: 2, paddingRight: 8 }} alt="" src={HomeSvg} />,
    },
    {
        title: '提出・承認',
        url: '',
        icon: '',
    },
];

const MainUI = ({ handler }: props) => {
    useEffect(() => {
        handler.initFilter();
    }, [JSON.stringify(handler.page), JSON.stringify(handler.keyword)]);

    useEffect(() => {
        handler.initBigTab(handler.tab, handler.menu);
    }, []);

    useEffect(() => {
        handler.getStatusData();
    }, [handler.tab, handler.menu]);

    return (
        <MenuWrapperComponent chosenKey={54} openKey={5}>
            <BreadcrumbComponent data={BREADCRUMB_CM3854} />
            <div className="cm-wrapper-header">
                <h2 className="cm3453-title">提出・承認</h2>
                <Form
                    name="cm034-cm053-search"
                    className="cm034-cm053-search-form"
                    form={handler.formSearch}
                >
                    <Form.Item name="keyword">
                        <Input
                            onBlur={() => helpers.onBlurTrimFormItem(handler.formFilter, 'keyword')}
                            prefix={<img alt="" src={IconSearch} />}
                            size="large"
                            placeholder="検索したい資料名を入力してください"    // CR #344212
                            className="am028-search-input cm03854-input-search"
                            onChange={handler.onSearch}
                        />
                    </Form.Item>
                </Form>
            </div>
            <Tabs
                type="card"
                onChange={handler.handleChangeCurrentTab}
                activeKey={handler.tab}
                className="am028 cm-btn-disable"
            >
                {handler.menuTab1.length > 0 && <TabPane tab="提出一覧" key="1"></TabPane>}
                {handler.menuTab2.length > 0 && <TabPane tab="承認一覧" key="2"></TabPane>}
            </Tabs>
            {
                handler.menuTab1.length + handler.menuTab2.length === 0 ?
                    <></> : <Body handler={handler} />
            }
        </MenuWrapperComponent>
    );
};

export default MainUI;
