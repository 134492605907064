import ErrorNotification from "../../../../common/components/notification/ErrorNotification";
import COMMON from "../../../../common/constants/COMMON";
import { NOTIFICATION_TITLE } from "../../../../common/constants/MESSAGE";
import helpers from "../../../../common/helpers/common";
import { AttendanceAPIImpl } from "./ServiceImpl";

const AttendanceService = (api: AttendanceAPIImpl) => {
  const getAttendanceSubConstructionMemberTimeSheet = async (params: {
    constructionId: number;
    userId: number;
    contractorConstructionId: number;
    from: string; // 2006-01-02
    to: string; // 2006-01-02
  }): Promise<any> => {
    try {
      const timeOffset = COMMON.TIME_ZONE;
      const response = await api.getAttendanceSubConstructionMemberTimeSheet({
        ...params,
        timeOffset,
        from: helpers.getFilterTime(params.from),
        to: helpers.getFilterTimeTo(params.to),
      });
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return null;
    }
  };
  return { getAttendanceSubConstructionMemberTimeSheet };
};

export default AttendanceService;
