import { RootState } from "./../../../../../store";
import { Form } from "antd";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import { PM008UseCase } from "../../usecase/ServiceImpl";
import { setIsCreated, setIsModalOpen } from "../slice/Slice";
import COMMON from "../../../../../common/constants/COMMON";
import MESSAGE, {
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import { refreshPM005 } from "../../../PM005/presenter/slice/Slice";
import { STATUS_PM008 } from "../../../../../common/constants/STATUS";
import helpers from "../../../../../common/helpers/common";
import { showImageViewerModal } from "../../../../../common/components/image-viewer-modal/ImageViewSlice";
import { showPdfViewerModal } from "../../../../../common/components/pdf-viewer-modal/pdfViewerModalSlice";
import { RcFile } from "antd/es/upload";
import { useSearchParams } from "react-router-dom";
import ConfirmModal from "../../../../../common/components/modal/ConfirmModal";
import {
  resetStatePM008_1,
  setViewChooseCompany,
} from "../../../PM008_1/presenter/slice/Slice";
import { refreshUserInWorkspace } from "../../../../../common/slice/AuthSlice";

const PM008Handler = (PM008Service: PM008UseCase) => {
  const [form] = Form.useForm();
  const [_, setSearchParams] = useSearchParams();

  // REDUX
  const dispatch = useDispatch();
  const isCreated = useSelector((state: RootState) => state.pm008.isCreated);
  const chosenCompany = useSelector(
    (state: RootState) => state?.pm008_1.chosenCompany
  );
  const listCompany = useSelector(
    (state: RootState) => state?.pm008_1.listCompany
  );

  // STATE
  const [dataMembers, setDataMembers] = useState([]);
  const [dataStatus, setDataStatus] = useState([]);
  const [fileList, setFileList] = useState<any>([]);
  const [status, setStatus] = useState("");
  const [page, setPage] = useState(COMMON.DEFAULT_PAGE);
  const [fileUrl, setFileUrl] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [members, setMembers] = useState<any[]>([]);
  const [pageOption, setPageOption] = useState(COMMON.DEFAULT_PAGE);
  const [sizeOption, setSizeOption] = useState(COMMON.DEFAULT_SIZE);
  const [keywordOption, setKeywordOption] = useState("");
  const [loadingOption, setLoadingOption] = useState(false);
  // FUNCTION
  const getDataMembers = async (params: any) => {
    dispatch(setLoading(true));
    try {
      const response = await PM008Service.getDataMembers(params);

      if (response) {
        setDataMembers(response.results);

        return response.data;
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getDataStatus = async (params: any) => {
    dispatch(setLoading(true));
    try {
      const response = await PM008Service.getDataStatus(params);
      if (response) {
        setDataStatus(response.results);
        return response.data;
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };
  const getLinkPresignUpload = async (params: any) => {
    dispatch(setLoading(true));
    try {
      const response = await PM008Service.getLinkPresignUpload(params);

      if (response) {
        return response.results;
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const uploadTos3 = async (url: any, data: any) => {
    try {
      const response = await PM008Service.uploadToS3(url, data);
      return response.results;
    } catch (error) {
      throw error;
    }
  };

  const createNewProject = async (
    data: any,
    funcAfter?: () => void
  ): Promise<any> => {
    const valueForm = data;
    const newData = {
      statusId: valueForm.statusId,
      workspaceId: 1,
      projectName: valueForm.projectName,
      constructionName: valueForm.constructionName || "",
      orderAddress: chosenCompany + "",
      constructionFromDate: helpers.getFilterTime(
        valueForm.ConstructionPeriod[0]._d
      ),
      constructionToDate: helpers.getFilterTime(
        valueForm.ConstructionPeriod[1]._d
      ),
      location: valueForm.location,
      document:
        !valueForm.file || valueForm.file.fileList?.length === 0
          ? ""
          : valueForm.file.fileList[0].originFileObj.name,
      projectManager: { userId: valueForm.projectManager },
      deputyProjectManager:
        valueForm.deputyProjectManager &&
          valueForm.deputyProjectManager?.length > 0
          ? {
            userId: valueForm.deputyProjectManager,
          }
          : null,
      picName: "案件担当",
      projectCcus: valueForm?.projectCcus ? valueForm.projectCcus.replaceAll(" ","") : "" 
    };

    dispatch(setLoading(true));
    try {
      const response = await PM008Service.createNewProject(newData);
      if (valueForm.file && valueForm.file.fileList?.length !== 0) {
        const linkPresignUpload = await getLinkPresignUpload({
          keyName: `project/${response.results}/${valueForm.file.fileList[0].originFileObj.name}`,
        });
        if (linkPresignUpload) {
          uploadTos3(
            linkPresignUpload,
            valueForm.file.fileList[0].originFileObj
          );
        }
      }
      SuccessNotification(
        response?.message ??
        NOTIFICATION_TITLE.MESSAGE_DELETE_NOTIFICATION_SUCCESS
      );
      dispatch(setIsModalOpen(false));
      setFileList([]);
      form.resetFields();
      dispatch(refreshPM005());
      dispatch(resetStatePM008_1());
      dispatch(refreshUserInWorkspace());
      if (funcAfter) funcAfter();
      return response;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getListMember = async (params: {
    page: number;
    size: number;
    keyword?: string;
  }) => {
    setLoadingOption(true);
    try {
      const response = await PM008Service.getListMember(params);
      if (params.page === 1) {
        setMembers(response.results);
      } else {
        setMembers([...members, ...response.results]);
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      setLoadingOption(false);
    }
  };

  const handleChangeStatus = (value: string) => {
    setStatus(value);
  };

  const showModal = () => {
    dispatch(setIsModalOpen(true));
  };

  const handleCancel = () => {
    if (isEdit) {
      ConfirmModal({
        onOk: () => {
          dispatch(setIsModalOpen(false));
          setFileList([]);
          form.resetFields();
          dispatch(resetStatePM008_1());
          setIsEdit(false);
        },
        onCancel: () => { },
        className: "confirm__modal modal-ml",
        title: MESSAGE.MESSAGE_020,
        description: MESSAGE.MESSAGE_022_1,
        extraDescription: MESSAGE.MESSAGE_022_2,
        canceText: "キャンセル",
        okText: "はい",
      });
    } else {
      dispatch(setIsModalOpen(false));
      setFileList([]);
      dispatch(resetStatePM008_1());
      form.resetFields();
      setIsEdit(false);
    }
  };
  const onScrollLocation = (e: any) => {
    const target = e.target;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      setPage(page + 1);
    }
  };

  const onChangeUpload = (value: any) => {
    if (value?.file?.status === "removed") {
      setFileList([]);
    } else {
      if (value?.file) {
        const found = helpers.beforeUpload(value?.file);
        if (found) {
          setFileList([value.file]);
        }
      }
    }
  };
  const onDropUpload = (value: any) => {
    setFileList([]);
  };

  const handleCheckColor = (status: number) => {
    if (status === STATUS_PM008.RED) {
      return "#FF0909";
    }
    if (status === STATUS_PM008.GREEN) {
      return "#0FA44A";
    }
    if (status === STATUS_PM008.BLUE) {
      return "#0050AE";
    }

    return "#FF9054";
  };

  const previewFile = async (value: any) => {
    if (!value.url && !value.preview) {
      value.preview = await helpers.getBase64(value.originFileObj as RcFile);
    }
    setFileUrl(value.url || (value.preview as string));
    if (value.type.includes("image/")) {
      dispatch(showImageViewerModal());
    } else {
      dispatch(showPdfViewerModal());
    }
  };

  const handleGetMember = () => {
    setMembers([]);
    setPageOption(1);
    setSizeOption(10);
    getListMember({ page: 1, size: 10 });
  };

  const handleScrollMember = (e: any) => {
    const target = e.target;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      setPageOption(pageOption + 1);
      getListMember({
        page: pageOption + 1,
        size: sizeOption,
        keyword: keywordOption ? keywordOption : undefined,
      });
    }
  };

  const handleSearchOption = (searchValue: string) => {
    getListMember({
      page: 1,
      size: 10,
      keyword: searchValue,
    });
    setKeywordOption(searchValue);
    setPageOption(1);
    setSizeOption(10);
  };

  const memberProjectManager = useMemo(() => {
    const idDeputyProjectManager = form.getFieldValue("deputyProjectManager");
    const checkDuplicate: any = {};
    const results: any[] = [];
    for (const member of members) {
      if (
        !(member.id in checkDuplicate) &&
        !idDeputyProjectManager?.includes(member.id)
      ) {
        results.push(member);
        checkDuplicate[member.id] = member;
      }
    }
    return results;
  }, [members]);

  const memberDeputyProjectManager = useMemo(() => {
    const idProjectManager = form.getFieldValue("projectManager");
    const checkDuplicate: any = {};
    const results: any[] = [];
    for (const member of members) {
      if (!(member.id in checkDuplicate) && idProjectManager !== member.id) {
        results.push(member);
        checkDuplicate[member.id] = member;
      }
    }
    return results;
  }, [members]);

  const handleOpenChooseCompany = () => {
    dispatch(setViewChooseCompany(true));
  };

  const handleCancelChooseCompany = () => {
    dispatch(setViewChooseCompany(false));
  };

  const handleConfirmChooseCompany = () => {
    const company = listCompany.find(
      (company: any) => company.id === chosenCompany
    );
    dispatch(setViewChooseCompany(false));
    form.setFields([
      {
        name: "orderAddress",
        value: company?.companyName,
        errors: undefined,
      },
    ]);
  };

  return {
    createNewProject,
    getDataStatus,
    handleChangeStatus,
    showModal,
    handleCancel,
    getDataMembers,
    getLinkPresignUpload,
    onScrollLocation,
    handleCheckColor,
    onDropUpload,
    onChangeUpload,
    previewFile,
    setIsEdit,
    handleGetMember,
    handleScrollMember,
    handleSearchOption,
    handleOpenChooseCompany,
    handleCancelChooseCompany,
    handleConfirmChooseCompany,
    form,
    dataStatus,
    status,
    dataMembers,
    page,
    fileList,
    fileUrl,
    isEdit,
    members,
    loadingOption,
    memberProjectManager,
    memberDeputyProjectManager,
  };
};

export default PM008Handler;
