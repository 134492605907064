import CM0063Api from './infrastructure/api/Api';
import CM0063Handler from './presenter/handler/Handler';
import CM0063UI from './presenter/ui/UI';
import CM0063Service from './usecase/Service';

const CM0063Component = () => {
    const cm0063Api = CM0063Api();
    const cm0063Service = CM0063Service(cm0063Api);
    const cm0063Handler = CM0063Handler(cm0063Service);
    return <CM0063UI handler={cm0063Handler} />;
};

export default CM0063Component;
