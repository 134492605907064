import { Spin } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import BreadcrumbComponent from "../../../../../common/components/breadcrumb/BreadcrumbComponent";
import MenuWrapperComponent from "../../../../../common/components/menu-wrapper";
import "../../../../../assets/styles/CR/CRT001.css";
import { useParams } from "react-router-dom";
import { CRT001UIHandler } from "../handler/HandlerImpl";
import CRT001Body from "./CRT001Body";
import { useEffect } from "react";
import HomeSvg from "../../../../../assets/icons/home.svg";

interface props {
  handler: CRT001UIHandler;
}

const CR003UI = ({ handler }: props) => {
  const loading = useSelector((state: RootState) => state.common.loading);
  const { projectId } = useParams();

  const Breadcrumb = [
    {
      title: "案件管理",
      url: "/app-pm002",
      icon: (
        <img
          style={{ paddingBottom: 2, paddingRight: 8 }}
          alt=""
          src={HomeSvg}
        />
      ),
    },
    {
      title: "案件詳細",
      url: `/app-pm005/${projectId}`,
      icon: "",
    },
    {
      title: "実行予算一覧",
      url: "",
      icon: "",
    },
  ];

  useEffect(() => {
    handler.getCRT001Data({ projectId });
  }, [projectId]);

  return (
    <MenuWrapperComponent chosenKey={81} openKey={8}>
      <Spin spinning={!!loading}>
        <BreadcrumbComponent data={Breadcrumb} />
        <h3 className="ci001-title">実行予算一覧</h3>
        <CRT001Body handler={handler} />
      </Spin>
    </MenuWrapperComponent>
  );
};

export default CR003UI;
