import axiosConfig from '../../../../../config/axiosConfig';
import { ChangeMailPayload } from '../../entity/Entity';

const IC4Api = () => {

    const postChangeMail = async (payload: ChangeMailPayload): Promise<any> => {
        const url = '/order/request-change-email';
        return await axiosConfig.post(url, payload);
    };

    return {
        postChangeMail
    };
};

export default IC4Api;
