import { OM001SliceType } from "./../type/Presenter";
import { createSlice } from "@reduxjs/toolkit";

export const initialState: OM001SliceType = {
  detailGroup: {
    explanation: "",
    id: 0,
    name: "",
    totalMember: 0,
    status: {
      code: "",
      description: "",
      id: 0,
      name: "",
      type: "",
    },
    approver: {
      id: 0,
      fullName: "",
      username: "",
      address: "",
      email: "",
      phoneNumber: "",
    },
  },
  loading: false,
  isVisible: false,
};

export const om001Slice = createSlice({
  name: "om001",
  initialState,
  reducers: {
    resetOM001: () => initialState,
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setDetailGroup: (state, action) => {
      state.detailGroup = action.payload;
    },
    setIsVisibleOM001: (state, action) => {
      state.isVisible = action.payload;
    },
  },
});

export const { resetOM001, setLoading, setDetailGroup, setIsVisibleOM001 } =
  om001Slice.actions;

export default om001Slice.reducer;
