import { useState } from "react";
import {
  AM008ServiceImpl,
  AttendanceServiceImpl,
} from "./../../usecase/ServiceImpl";
import { useSearchParams } from "react-router-dom";
import { Form } from "antd";
import moment from "moment";
import COMMON from "../../../../../common/constants/COMMON";
import { useDispatch, useSelector } from "react-redux";
import { setTableColumns } from "../slice/Slice";
import {
  calculateCalendarTableHeader,
  convertDataForExport,
  getTitleByFromToDate,
} from "../../helper";
import { RangeDateType } from "../../../AM002/entity/Entity";
import { MemberType } from "../../../AM006/entity/Entity";
import { exportAttendanceConstruction } from "../../../../../common/helpers/exports/attendance_construction";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import { RootState } from "../../../../../store";
import helpers from "../../../../../common/helpers/common";
import { doExportForResponse } from "../../../../../common/helpers/exports/common";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../../common/constants/MESSAGE";
// Self constant

const SCREEN_NAME = "自社勤務実績";
const TAB_NAME_CONSTRUCTION = "現場出勤";
const TAB_NAME_NO_CONSTRUCTION = "非現場出勤";

const AM008Handler = (
  service: AM008ServiceImpl,
  attendanceService: AttendanceServiceImpl
) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const tableColumns = useSelector(
    (state: RootState) => state.am008.tableColumns
  );
  const [searchParams, setSearchParams] = useSearchParams();

  const pageDate = searchParams.get("pageDate") || COMMON.DEFAULT_PAGE + "";
  const fromDate =
    searchParams.get("startDate") ||
    moment().startOf("month").format(COMMON.FORMAT_DATE2);
  const toDate =
    searchParams.get("endDate") || moment().format(COMMON.FORMAT_DATE2);

  const [members, setMembers] = useState<MemberType[]>([]);
  const [fullMembers, setFullMembers] = useState<MemberType[]>([]);
  const [totalRecord, setTotalRecord] = useState<number>(0);
  const [rangeDate, setRangeDate] = useState<RangeDateType>({
    from: moment().startOf("month"),
    to: moment(),
  });
  const [expandKey, setExpandKey] = useState<any[]>([]);

  const onFromDateChange = (value: any) => {
    setRangeDate({ ...rangeDate, from: value });
  };
  const onToDateChange = (value: any) => {
    setRangeDate({ ...rangeDate, to: value });
  };
  const isCollapsed = (record: any) => {
    return expandKey.some((element) => element === record.key);
  };

  const projectList = useSelector(
    (state: RootState) => state.am008.projectList
  );

  const onChangeCollapseRow = (record: any, collapsed: boolean) => {
    const collapsedRow = collapsed
      ? [...expandKey, record.key]
      : expandKey.filter((element) => element !== record.key);

    setExpandKey(collapsedRow);
  };
  const onPagingMember = (page: string, size: string, keyword: string) => {
    const searchMembers = fullMembers.filter((element) =>
      helpers
        .toASCII(element.fullName || "")
        .toUpperCase()
        .includes(helpers.toASCII(keyword || "").toUpperCase())
    );
    const currentPage = parseInt(page);
    const pagingMembers = searchMembers.slice(
      (currentPage - 1) * parseInt(size),
      currentPage * parseInt(size)
    );
    setMembers(pagingMembers);
  };

  const getAttendanceConstructionMyMemberTimeSheet = async (params: {
    constructionId?: number;
    userId: number;
    from: string;
    to: string;
  }): Promise<any> => {
    const { results } =
      await attendanceService.getAttendanceConstructionMyMemberTimeSheet(
        params
      );
    return results;
  };

  const resetData = () => {
    setExpandKey([]);
    // set state
    setMembers([]);
    setFullMembers([]);
    setTotalRecord(0);
  };

  const getAttendanceConstructionListMyMember = async (params: {
    page: string;
    size: string;
    keyword: string;
    constructionId?: number;
    from: string;
    to: string;
  }): Promise<any> => {
    try {
      dispatch(setLoading(true));
      const { results } = await service.getAttendanceConstructionListMyMember({
        constructionId: params.constructionId,
        from: params.from,
        to: params.to,
      });
      const { data, construction } = results;

      if (!data) return resetData();
      if (Array.isArray(data) && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          data[i].construction = construction;
          const attendaceParams = {
            constructionId: params.constructionId,
            userId: data[i].id,
            from: params.from,
            to: params.to,
          };
          const attendanceResult =
            await getAttendanceConstructionMyMemberTimeSheet(attendaceParams);
          if (attendanceResult && Array.isArray(attendanceResult.timesheet)) {
            for (let k = 0; k < attendanceResult.timesheet.length; k++) {
              if (attendanceResult.timesheet[k].isMultipleCheckInOut) {
                const multipleCheckInOutData =
                  await service.getAttendanceUserTimeSheet({
                    date: moment(attendanceResult.timesheet[k].date).format(
                      COMMON.FORMAT_DATE2
                    ),
                    constructionId: params.constructionId + "",
                    contractorConstructionId: params.constructionId + "",
                    userId: data[i].id,
                  });
                if (multipleCheckInOutData)
                  attendanceResult.timesheet[k].multipleCheckInOutData =
                    multipleCheckInOutData.map((element) => ({ ...element }));
              }
            }

            data[i].attendance = attendanceResult;
          }
        }
        // keyword
        const filterMembers = data.filter((element: any) =>
          helpers
            .toASCII(element.fullName || "")
            .toUpperCase()
            .includes(helpers.toASCII((params.keyword || "").toUpperCase()))
        );

        // paging
        const currentPage = parseInt(params.page);
        const pagingMembers = filterMembers.slice(
          (currentPage - 1) * parseInt(params.size),
          currentPage * parseInt(params.size)
        );

        // set state
        // setExpandKey([pagingMembers[0].id]);
        setMembers(pagingMembers);
        setFullMembers(data);
        setTotalRecord(params.keyword.length == 0 ? data.length : filterMembers.length);
      } else {
        setExpandKey([]);
        // set state
        setMembers([]);
        setFullMembers([]);
        setTotalRecord(0);
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getAttendanceOffsiteListMyMember = async (params: {
    page: string;
    size: string;
    keyword: string;
    from: string;
    to: string;
  }): Promise<any> => {
    try {
      dispatch(setLoading(true));
      const { results } = await service.getAttendanceOffsiteListMyMember({
        from: params.from,
        to: params.to,
      });
      if (!results) {
        setExpandKey([]);
        // set state
        setMembers([]);
        setFullMembers([]);
        setTotalRecord(0);
        return;
      }
      if (Array.isArray(results) && results.length > 0) {
        // attendance
        for (let i = 0; i < results.length; i++) {
          const attendaceParams = {
            userId: results[i].id,
            from: params.from,
            to: params.to,
          };
          const attendanceResult =
            await getAttendanceConstructionMyMemberTimeSheet(attendaceParams);
          if (attendanceResult && Array.isArray(attendanceResult.timesheet)) {
            for (let k = 0; k < attendanceResult.timesheet.length; k++) {
              if (attendanceResult.timesheet[k].isMultipleCheckInOut) {
                const multipleCheckInOutData =
                  await service.getAttendanceUserTimeSheet({
                    date: moment(attendanceResult.timesheet[k].date).format(
                      COMMON.FORMAT_DATE2
                    ),
                    userId: results[i].id,
                  });
                if (multipleCheckInOutData)
                  attendanceResult.timesheet[k].multipleCheckInOutData =
                    multipleCheckInOutData.map((element) => ({ ...element }));
              }
            }
            results[i].attendance = attendanceResult;
          }
        }

        // keyword
        const filterMembers = results.filter((element: any) =>
          helpers
            .toASCII(element.fullName || "")
            .toUpperCase()
            .includes(helpers.toASCII((params.keyword || "").toUpperCase()))
        );

        // paging
        const currentPage = parseInt(params.page);
        const pagingMembers = filterMembers.slice(
          (currentPage - 1) * parseInt(params.size),
          currentPage * parseInt(params.size)
        );

        // setExpandKey([pagingMembers[0].id]);
        // set state
        setMembers(pagingMembers);
        setFullMembers(results);
        setTotalRecord(params.keyword.length == 0 ? results.length : filterMembers.length);
      } else {
        setExpandKey([]);
        // set state
        setMembers([]);
        setFullMembers([]);
        setTotalRecord(0);
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const exportData = async (params: any) => {
    // Get current user

    const user = localStorage.getItem("user");
    let companyName: string = "";
    if (user) {
      companyName = JSON.parse(user)?.company?.name;
    }

    // const rangeDate: string = getTitleByFromToDate(fromDate, toDate);

    const tabCurrent: string = searchParams.get("tab") ?? "1";

    const typeTemplate: boolean = tabCurrent === "1" ? true : false;

    // const title: string[] = [`${companyName}`, `期間： ${rangeDate}`];

    // let date_header: string[] = tableColumns?.columns.map((item: any) => item?.name);

    // const handleLoadingExport = (value: boolean) => {
    //     dispatch(setLoading(value));
    // };

    // const dataExport: any = {
    //     title: title,
    //     date_header: date_header,
    //     content: convertDataForExport(members),
    // };

    // exportAttendanceConstruction(
    //     dataExport,
    //     `${SCREEN_NAME}_${
    //         typeTemplate ? TAB_NAME_CONSTRUCTION : TAB_NAME_NO_CONSTRUCTION
    //     }_${moment().format(COMMON.FORMAT_DATE2)}`,
    //     typeTemplate,
    //     handleLoadingExport,
    // );

    try {
      dispatch(setLoading(true));
      let response;

      if (params.constructionId) {
        response = await service.doExportAM008({
          ...params,
          from: helpers.getFilterTime(params.from),
          to: helpers.getFilterTimeTo(params.to),
          companyName,
        } as any);
        doExportForResponse(
          response,
          `${SCREEN_NAME}_${typeTemplate ? TAB_NAME_CONSTRUCTION : TAB_NAME_NO_CONSTRUCTION
          }_${fullMembers[0].construction.projectName}_${moment().format(COMMON.FORMAT_DATE7)}.xlsx`
        );
      } else {
        response = await service.doExportAM009({
          ...params,
          from: helpers.getFilterTime(params.from),
          to: helpers.getFilterTimeTo(params.to),
          companyName,
        } as any);
        doExportForResponse(
          response,
          `${SCREEN_NAME}_${typeTemplate ? TAB_NAME_CONSTRUCTION : TAB_NAME_NO_CONSTRUCTION
          }_${moment().format(COMMON.FORMAT_DATE7)}.xlsx`
        );
      }


    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const onPageChange = (value: number) => {
    searchParams.set("page", value + "");
    setSearchParams(searchParams);
  };
  const onSizeChange = (value: number) => {
    searchParams.set("page", COMMON.DEFAULT_PAGE + "");
    searchParams.set("size", value + "");

    setSearchParams(searchParams);
  };

  const initiateFilter = () => {
    form.setFieldValue("text", searchParams.get("keyword") || "");

    let fromMoment: moment.Moment = moment().startOf("month");
    if (fromDate === null)
      form.setFieldValue("startDate", moment().startOf("month"));
    else {
      form.setFieldValue("startDate", moment(fromDate));
      fromMoment = moment(fromDate);
    }

    form.setFieldValue("endDate", moment(toDate));
    setRangeDate({
      to: moment(toDate),
      from: fromMoment,
    });
  };

  var myVar: any;
  const onSearch = () => {
    if (myVar) clearTimeout(myVar);
    myVar = setTimeout(function () {
      const searchText = form.getFieldValue("text");
      form.setFieldValue("text", searchText.trim());
      searchParams.set("keyword", searchText.trim());
      searchParams.set("page", COMMON.DEFAULT_PAGE + "");
      setSearchParams(searchParams);
    }, 1000);
  };

  const calculateTableHeader = (
    startDate: string | null,
    endDate: string | null
  ) => {
    const result = calculateCalendarTableHeader(startDate, endDate, pageDate);
    dispatch(setTableColumns(result));
  };

  const onCollapse = (value: boolean) => {
    searchParams.set("isCollapsed", value ? "isCollapsed" : "");
    setSearchParams(searchParams);
  };

  const onNext = () => {
    const pageInt = parseInt(pageDate);
    searchParams.set("pageDate", pageInt + 1 + "");
    setSearchParams(searchParams);
  };

  const onPrev = () => {
    const pageInt = parseInt(pageDate);
    searchParams.set("pageDate", (pageInt - 1 <= 0 ? 1 : pageInt - 1) + "");
    setSearchParams(searchParams);
  };
  const onTabChange = (value: any) => {
    searchParams.set("tab", value + "");
    setSearchParams(searchParams);
    form.setFieldValue("text", "");
    searchParams.set("keyword", "");
    searchParams.set("page", COMMON.DEFAULT_PAGE + "");
    setSearchParams(searchParams);
  };

  return {
    form,
    totalRecord,
    rangeDate,
    members,
    expandKey,
    projectList,

    onSearch,
    getAttendanceConstructionListMyMember,
    exportData,
    onSizeChange,
    onPageChange,
    onCollapse,
    initiateFilter,
    onNext,
    onPrev,
    calculateTableHeader,
    onFromDateChange,
    onToDateChange,
    onTabChange,
    getAttendanceOffsiteListMyMember,
    onPagingMember,
    setExpandKey,
    isCollapsed,
    onChangeCollapseRow,
    resetData,
  };
};

export default AM008Handler;
