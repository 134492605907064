import { HandlerImpl } from "../handler/HandlerImpl";
import Table from "./Table";
import Header from "./Header";
import CM0021Component from "../../../CM0021";

import { RootState } from "../../../../../store";
import { useSelector } from "react-redux";
import { MENU_ITEM } from "../handler/Handler";
import CM0022Component from "../../../CM0022";
import CM0023Component from "../../../CM0023";
import { useEffect } from "react";
import helpers from "../../../../../common/helpers/common";
import ModalApprove from "../../../../../common/components/approval-flow/ModalApprove";
import MESSAGE from "../../../../../common/constants/MESSAGE";
import COMMON from "../../../../../common/constants/COMMON";
import { useSearchParams } from "react-router-dom";

interface props {
  handler: HandlerImpl;
}

const MainUI = ({ handler }: props) => {
  const visibleCM0021 = useSelector(
    (state: RootState) => state.cm0021.isVisibleCM0021
  );
  const visibleCM0023 = useSelector(
    (state: RootState) => state.cm0023.isVisibleCM0023
  );
  const isRefresh = useSelector((state: RootState) => state.cm0020.isRefresh);

  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("tab") || "";

  useEffect(() => {
    (async () => {
      if (handler.menu === MENU_ITEM[0].key && !handler.openFilter) {
        handler.getDataTable({
          from: handler.clear ? "" : helpers.getFilterTimeMonth(handler.from),
          to: handler.clear ? "" : helpers.getFilterTimeMonthTo(handler.to),
          page: COMMON.DEFAULT_PAGE,
          size: COMMON.DEFAULT_SIZE,
          sortBy: "createdAt",
          sortType: "DESC",
        });
        handler.getMaterialCostCompanySummary({
          from: handler.clear ? "" : helpers.getFilterTimeMonth(handler.from),
          to: handler.clear ? "" : helpers.getFilterTimeMonthTo(handler.to),
        });
      }
    })();
  }, [
    handler.clear,
    handler.menu,
    handler.from,
    handler.to,
    handler.openFilter,
    isRefresh,
  ]);

  useEffect(() => {
    (async () => {
      if (handler.menu === MENU_ITEM[0].key && !handler.openFilter) {
        handler.getMoneySummaryMaterialCostCompanyStatus({
          from: helpers.getFilterTimeMonth(handler.from),
          to: helpers.getFilterTimeMonthTo(handler.to),
        });
      }
    })();
  }, [
    handler.clear,
    handler.menu,
    handler.from,
    handler.to,
    handler.refreshStatus,
    handler.openFilter,
    isRefresh,
  ]);

  useEffect(() => {
    handler.initialFilter();
  }, [handler.menu]);

  useEffect(() => {
    if (tab === "2") {
      searchParams.set("menu2", MENU_ITEM[0].key);
      setSearchParams(searchParams);
    }
  }, [tab]);

  useEffect(() => {
    handler.checkHavingData(handler.dataView?.length > 0);
  }, [JSON.stringify(handler.dataView)]);

  return (
    <>
      <div className="cm024">
        <Header handler={handler} />
        {handler.menu === MENU_ITEM[0].key ? (
          <Table handler={handler} />
        ) : (
          <CM0022Component />
        )}
      </div>

      {visibleCM0021 ? <CM0021Component /> : null}
      {visibleCM0023 ? <CM0023Component /> : null}
    </>
  );
};

export default MainUI;
