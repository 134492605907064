import { NOTIFICATION_TITLE } from "./../../../../../common/constants/MESSAGE";
import { AM040ServiceImpl } from "./../../usecase/ServiceImpl";
import { Form } from "antd";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { resetAM040 } from "../slice/Slice";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import { DoubleCheckPayload } from "../../entity/Entity";
import { convertUserTimeSheetData } from "../../helper";
import { openModalStreetMap } from "../../../../../common/components/open-street-map/Slice";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";

const Handler = (service: AM040ServiceImpl, afterSuccess: () => void) => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [report, setReport] = useState<any>();

  const onEdit = () => {
    setIsEdit(true);
  };

  const closeModal = () => {
    if (isEdit) setIsEdit(false);
    dispatch(resetAM040());
  };

  const postAttendanceDoubleCheck = async (): Promise<any> => {
    const resultData: DoubleCheckPayload[] = [];
    const formValues = form.getFieldsValue();
    for (let property in formValues) {
      const doubleCheckData = JSON.parse(formValues[`${property}`]);
      if (doubleCheckData.isTouched) {
        resultData.push({
          constructionId: doubleCheckData.constructionId,
          doubleCheck: doubleCheckData.doubleCheck,
          userTimeSheetId: doubleCheckData.userTimeSheetId,
        });
      }
    }
    try {
      setLoading(true);
      if (resultData.length !== 0)
        await service.postAttendanceDoubleCheck(resultData);
      SuccessNotification(NOTIFICATION_TITLE.SUCCESS);
      dispatch(resetAM040());
      setIsEdit(false);
      setReport(undefined);
      afterSuccess();
    } catch (error: any) {
      ErrorNotification(error.message || NOTIFICATION_TITLE.ERROR);
    } finally {
      setLoading(false);
    }
  };

  const getAttendanceUserTimeSheet = async (params: {
    date: string;
    constructionId?: string;
    contractorConstructionId?: string;
    userId: string;
  }): Promise<any> => {
    try {
      const responseData = await service.getAttendanceUserTimeSheet(params);
      setReport(convertUserTimeSheetData(responseData));
    } catch (error: any) {
      ErrorNotification(error.message || NOTIFICATION_TITLE.ERROR);
    }
  };
  const openStreetMap = (lat: any, lng: any) => {
    dispatch(
      openModalStreetMap({
        lat: lat,
        lng: lng,
      })
    );
  };
  return {
    setReport,
    onEdit,
    closeModal,
    getAttendanceUserTimeSheet,
    postAttendanceDoubleCheck,
    openStreetMap,
    isEdit,
    loading,
    form,
    report,
  };
};

export default Handler;
