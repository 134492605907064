import React, { useEffect } from "react";
import IconDown from "../../../../../assets/icons/icon-collapse-cm.svg";
import { Empty, Table } from "antd";
import SortTable from "../../../../../common/components/sort-table/SortTable";
import { COLUMN_SORT } from "../handler/Handler";
import { HandlerImpl } from "../handler/HandlerImpl";
import helpers from "../../../../../common/helpers/common";
import InfiniteScroll from "react-infinite-scroll-component";
import { TableDataEntity } from "../../entity/Entity";

const TableView = ({ handler }: { handler: HandlerImpl }) => {
  const tableBody = document.querySelector(".infinite-scroll-component ");
  const tableHead = document.querySelector(".cm0019-table-head");
  const tableFooter = document.querySelector(".cm019-table-footer");
  const onScroll = (e: any) => {
    if (tableHead) tableHead.scrollLeft = e.target.scrollLeft;
    if (tableFooter) tableFooter.scrollLeft = e.target.scrollLeft;
  };

  useEffect(() => {
    tableBody?.addEventListener("scroll", onScroll);
    return () => tableBody?.removeEventListener("scroll", onScroll);
  }, [handler.data]);

  return (
    <div
      className="cm024-table cm019-table"
      id="table-cm0020-scroll"
      onScroll={handler.handleScrollTable}
    >
      <table>
        <thead>
          <tr>
            <th rowSpan={2} style={{ width: 110 }}>
              No
            </th>
            <th style={{ width: 240 }} rowSpan={2}>
              案件名
            </th>
            <th style={{ width: 193 }} rowSpan={2} className="td-width">
              入金額合計
            </th>
            <th colSpan={4} style={{ width: 749 }}>支払金額合計</th>
            <th style={{ width: 213 }} rowSpan={2} className="td-width">
              粗利
            </th>
          </tr>
          <tr>
            <th style={{ width: 193 }}>材料費</th>
            <th style={{ width: 193 }}>外注費</th>
            <th style={{ width: 193 }}>労務費</th>
            <th style={{ width: 193 }}>間接工事費</th>
          </tr>
        </thead>
        {handler.data?.length === 0 ? (
          <tbody>
            <tr>
              <td colSpan={8}>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="データなし"
                />
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {handler.data.map((item: TableDataEntity, idx: number) => (
              <React.Fragment key={item.id}>
                <tr style={{ height: 4 }}></tr>
                <tr
                  className="row-level-0"
                  onClick={() => handler.funcSetExpendKey(item.id)}
                >
                  <td style={{ width: 110 }}>{idx + 1}</td>
                  <td style={{ width: 240 }}>
                    {helpers.getShortTextWithToolTip(item.projectName, 10)}
                  </td>
                  <td style={{ width: 193 }}>
                    {helpers.formatCurrency(item.totalDeposit)}円
                  </td>
                  <td style={{ width: 193 }}>
                    {helpers.formatCurrency(item.totalMaterial)}円
                  </td>
                  <td style={{ width: 193 }}>
                    {helpers.formatCurrency(item.totalOutsourcing)}円
                  </td>
                  <td style={{ width: 193 }}>
                    {helpers.formatCurrency(item.totalLabor)}円
                  </td>
                  <td style={{ width: 193 }}>
                    {helpers.formatCurrency(item.totalIndirect, true)}円
                  </td>
                  <td className="td-child" style={{ width: 193 }}>
                    {helpers.formatCurrency(item.totalGross, true)}円
                    <div className="icon-collapse-table">
                      <img
                        alt=""
                        src={IconDown}
                        className={`cm024-icon ${handler.funcCheckKeyExpand(item.id)
                          ? ""
                          : "cm024-icon-active"
                          }`}
                      />
                    </div>
                  </td>
                </tr>
                {handler.funcCheckKeyExpand(item.id) ? (
                  <tr className="cm019-table-child">
                    <td colSpan={8}>
                      <Table
                        columns={item.columns}
                        pagination={false}
                        dataSource={item.children}
                        scroll={{ x: "max-content" }}
                        rowClassName={(record) =>
                          record?.level === 1 ? "cm019-row-level-1" : ""
                        }
                      />
                    </td>
                  </tr>
                ) : null}
              </React.Fragment>
            ))}
          </tbody>
        )}

        <tfoot>
          <tr className="table-foot">
            <td
              colSpan={2}
              style={{
                textAlign: "left",
                paddingLeft: 50,
                width: 350,
              }}
            >
              合計金額
            </td>
            <td style={{ width: 193 }}>
              {helpers.formatCurrency(handler.summary.depositAmount, true)}円
            </td>
            <td style={{ width: 193 }}>
              {helpers.formatCurrency(handler.summary.materialAmount, true)}円
            </td>
            <td style={{ width: 193 }}>
              {helpers.formatCurrency(handler.summary.outsourcingAmount, true)}
              円
            </td>
            <td style={{ width: 193 }}>
              {helpers.formatCurrency(handler.summary.laborAmount, true)}円
            </td>
            <td style={{ width: 193 }}>
              {helpers.formatCurrency(handler.summary.indirectAmout, true)}円
            </td>
            <td style={{ width: 193 }}>
              {helpers.formatCurrency(handler.summary.grossAmount, true)}円
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default TableView;
