import { useSelector } from 'react-redux';
import { Button, Collapse, Form } from 'antd';
import OM005Section2 from './Section2';
import OM005Section1 from './Section1';
import OM005Section3 from './Section3';
import OM005ModalResult from './ModalResult';
import { OM005Hanlder } from '../handler/HandlerImpl';
import { data, dataTableView, progress } from '../slice/Slice';
import OM005Footer from './Footer';

const { Panel } = Collapse;

interface props {
    handler: OM005Hanlder;
}

const OM005Content = ({ handler }: props) => {
    const dataTable = useSelector(data);
    const prog = useSelector(progress);
    const dataSource = useSelector(dataTableView);

    return (
        <Form
            form={handler.form}
            initialValues={{ checkRequired: 2 }}
            name="om005Form"
            onValuesChange={handler.handleChangeForm}
            onFinish={handler.handleSubmitForm}
        >
            <div className=" w-100-percent OM005-container">
                <Collapse
                    bordered={false}
                    defaultActiveKey={['1', '2']}
                    activeKey={
                        dataTable.length === 0
                            ? prog === 0
                                ? ['1', '2']
                                : ['1', '2']
                            : prog !== 100
                            ? ['1', '2']
                            : ['1', '2', '3']
                    }
                    expandIcon={() => null}
                >
                    <Panel
                        header={
                            <div style={{ display: 'flex' }}>
                                <div className="number-circle ">1</div>
                                <div className="collapse-label">パスワードの作成方法</div>
                            </div>
                        }
                        key="1"
                        extra={
                            <Button className="section1-button" type="link">
                                {/* 管理者が作成 */}
                            </Button>
                        }
                    >
                        <OM005Section1 handler={handler} />
                    </Panel>
                    <Panel
                        header={
                            <div style={{ display: 'flex' }}>
                                <div className="number-circle ">2</div>
                                <div className="collapse-label">ファイル登録</div>
                            </div>
                        }
                        key="2"
                    >
                        <OM005Section2 handler={handler} />
                    </Panel>
                    <Panel
                        header={
                            <div style={{ display: 'flex' }}>
                                <div className="number-circle ">3</div>
                                <div className="collapse-label">メンバーを一括追加</div>
                            </div>
                        }
                        key="3"
                    >
                        <OM005Section3 key={`${prog}`} handler={handler} />
                    </Panel>
                </Collapse>
                <OM005ModalResult handler={handler} />
            </div>
            {dataSource?.length > 0 && prog === 100 ? <OM005Footer handler={handler} /> : null}
        </Form>
    );
};

export default OM005Content;
