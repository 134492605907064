import { Button, Col, Modal, Row, Spin } from "antd";
import moment from "moment";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import XIconModal from "../../../../../assets/icons/x-icon-modal.svg";
import "../../../../../assets/styles/DB/DB006.css";
import COMMON from "../../../../../common/constants/COMMON";
import helpers from "../../../../../common/helpers/common";
import { RootState } from "../../../../../store";
import { PARAMS_PROJECT_NAME } from "../../../DB001/presenter/handler/Handler";
import { Handler } from "../handler/HandlerImpl";
import TableComponent from "../../../DB007/presenter/ui/Table";
import LoadingComponent from "../../../../../common/components/icons/LoadingComponent";

interface props {
  handler: Handler;
}

function MainUI({ handler }: props) {
  const visibleDB006 = useSelector(
    (state: RootState) => state.db006.isVisibleDB006
  );
  const companyName = useSelector((state: RootState) => state.db006.companyName)
  const popupDataDB006 = useSelector(
    (state: RootState) => state.db006.dataPopupTableDB006
  );

  const [searchParams] = useSearchParams();

  const projectName = searchParams.get(PARAMS_PROJECT_NAME) ?? "";
  const fromDate =
    searchParams.get("startDate") || moment().format(COMMON.FORMAT_DATE4);
  const toDate =
    searchParams.get("endDate") || moment().format(COMMON.FORMAT_DATE4);

  useEffect(() => {}, []);

  return (
    <Spin indicator={LoadingComponent} spinning={false}>
      <Modal
        style={{ marginLeft: 300, marginTop: 40, marginBottom: 40 }}
        visible={visibleDB006}
        centered
        maskClosable={false}
        width={"1571px"}
        closable={false}
        onCancel={handler.cancelModalDB006}
        footer={
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              color: "#666666",
            }}
          >
            <Button
              size="large"
              style={{ marginLeft: 16, width: 118 }}
              onClick={handler.cancelModalDB006}
            >
              キャンセル
            </Button>
          </div>
        }
      >
        <div
          className="w-100-percent header-modal-containter"
          style={{ height: 50 }}
        >
          <div className="header-modal-title-om004 ">
            {projectName}　協力会社別 稼働人工数・稼働原価
          </div>
          <div style={{ cursor: "pointer" }} onClick={handler.cancelModalDB006}>
            <img alt="" src={XIconModal} style={{ height: 18, width: 18 }} />
          </div>
        </div>
        <div
          style={{
            margin: "24px",
            backgroundColor: "#ffffff",
            height: 300,
          }}
        >
          <div
            style={{ gap: "240px", marginBottom: "32px" }}
            className="db006-row"
          >
            <div style={{ gap: "24px" }} className="db006-row">
              <div className="CM0017_3-info-title">協力会社名:</div>
              <div className="CM0017_3-info-content">{companyName}</div>
            </div>
            <div style={{ gap: "24px" }} className="db006-row">
              <div className="CM0017_3-info-title">期間:</div>
              <div className="CM0017_3-info-content">
                {helpers.formatDatePresenter(fromDate, COMMON.FORMAT_DATE_DB)}～
                {helpers.formatDatePresenter(toDate, COMMON.FORMAT_DATE_DB)}
              </div>
            </div>
          </div>
          <TableComponent data={popupDataDB006} />
        </div>
      </Modal>
    </Spin>
  );
}

export default MainUI;
