import { createSlice } from "@reduxjs/toolkit";
import { PM008Slice } from "../type/Presenter";

const initialState: PM008Slice = {
    isModalOpen: false,
    isCreated: false
};

export const pm008Slice = createSlice({
    name: "toggleModal",
    initialState,
    reducers: {
        setIsModalOpen: (state, action) => {
            state.isModalOpen = action.payload;
        },
        setIsCreated: (state, action) => {
            state.isCreated = action.payload;
        },
    },
});

export const { setIsModalOpen, setIsCreated } = pm008Slice.actions;

export default pm008Slice.reducer;
