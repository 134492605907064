import React from "react";
import { Button, Form, Input, Modal } from "antd";

import IconCheckGreen from "../../../assets/icons/akar-icons_circle-check-fill-green.svg";
import IconCircleRed from "../../../assets/icons/infor-circle.svg";
import IconCloseModal from "../../../assets/icons/icon-close-flow.svg";
import REGEX from "../../constants/REGEX";
import MESSAGE from "../../constants/MESSAGE";
import helpers from "../../helpers/common";
import { FormInstance } from "antd/es/form/Form";

const { Item } = Form;

interface props {
  isApproveOrReject?: boolean | undefined;
  visible?: boolean | undefined;
  description?: string;
  form?: FormInstance;
  title?: string;
  handleCancel?: () => void;
  handleOk?: (note: string) => void;
}

function ModalApprove({
  isApproveOrReject,
  visible,
  description,
  form,
  title,

  handleCancel,
  handleOk,
}: props) {
  return !visible ? null : (
    <Modal
      centered
      className="modal-approve"
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      closeIcon={<img alt="" src={IconCloseModal} />}
      maskClosable={false}
    >
      <div className="modal-approve-header">
        {isApproveOrReject && (
          <>
            <img alt="" src={IconCheckGreen} />
            <h3>{title ? title : "提出確認"} </h3>
          </>
        )}
        {!isApproveOrReject && (
          <>
            <img alt="" src={IconCircleRed} />
            <h3>否認理由</h3>
          </>
        )}
      </div>

      <Form name="workflow-reject" onFinish={handleOk} form={form}>
        <div className="modal-approve-body">
          <span className="modal-approve-body-title">{description}</span>

          <div className="modal-reject-input">
            <span>{isApproveOrReject ? "コメント" : "理由"} </span>
            <Item
              style={{
                width: "100%",
                margin: 0,
              }}
              name="rejectReason"
              rules={[
                () => ({
                  validator(_, value) {
                    if (value && value !== "") {
                      // CHECK INPUT VIETNAMESE
                      if (!REGEX.NOT_VIETNAM.test(value)) {
                        return Promise.reject(
                          new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                        );
                      }
                      if (value.length > 200) {
                        return Promise.reject(new Error(""));
                      }
                      return Promise.resolve();
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input.TextArea
                size="large"
                autoSize={{ minRows: 1, maxRows: 8 }}
                autoComplete={undefined}
                onBlur={() => {
                  if (form) helpers.onBlurTrimFormItem(form, "rejectReason");
                }}
                showCount={{
                  formatter: ({ count }: { count: number }) => `${count}/200`,
                }}
                maxLength={200}
              />
            </Item>
          </div>
        </div>
        <div className="modal-approve-btn">
          {isApproveOrReject ? (
            <Button size="large" className="button-purple" htmlType="submit">
              はい
            </Button>
          ) : (
            <Button
              size="large"
              className="button-purple"
              htmlType="submit"
              style={{ letterSpacing: -1, width: 78 }}
            >
              送信
            </Button>
          )}
          <Button
            size="large"
            onClick={handleCancel}
            style={{ color: "#666" }}
            htmlType="button"
          >
            キャンセル
          </Button>
        </div>
      </Form>
    </Modal>
  );
}

export default ModalApprove;
