import axiosConfig from "../../../../../config/axiosConfig";

const ASChangePasswordApi = () => {
  const changePassword = async (data: any): Promise<any> => {
    const url = "/auth/changePassword";
    try {
      const response = await axiosConfig.post(url, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  return {
    changePassword,
  };
};

export default ASChangePasswordApi;
