import moment from "moment";
import COMMON from "../../../common/constants/COMMON";

const findCostByCategory = (items: any[], code: string): number => {
  if (items && items.length === 0) return 0;

  const finded = items.find((element: any) => element.category.code === code);

  if (finded) return finded.value;
  else return 0;
};

const findIdByCategory = (items: any[], code: string): number | undefined => {
  if (items && items.length === 0) return undefined;

  const finded = items.find((element: any) => element.category.code === code);

  if (finded) return finded.category.id;
  else return undefined;
};

const convertDataView = (data: any[], index: number) => {
  const output: any[] = [];

  data.forEach((item: any, idx: number) => {
    const listChildren: any[] = [];

    let _totalAmount = 0;
    let _totalTaxAtion1 = 0;
    let _totalTaxExempt1 = 0;
    let _totalTaxAtion2 = 0;
    let _totalTaxExempt2 = 0;
    let _totalOffsetAmount = 0;
    let _totalExcludingTax = 0;
    let _totalConsumptionTax = 0;
    let _totalTaxInclude = 0;

    item.children.forEach((ite: any, idx: number) => {
      const _item__child: any[] = [];

      let totalRecordAmount = 0;
      let totalTaxAtion1 = 0;
      let totalTaxExempt1 = 0;
      let totalTaxAtion2 = 0;
      let totalTaxExempt2 = 0;
      let totalOffsetAmount = 0;
      let totalExcludingTax = 0;
      let totalConsumptionTax = 0;
      let totalTaxInclude = 0;

      ite.children.forEach((e: any, idx: number) => {
        const startDate = e?.startDate
          ? moment(e?.startDate).format(COMMON.FORMAT_DATE_CI)
          : "";
        const endDate = e?.endDate
          ? moment(e?.endDate).format(COMMON.FORMAT_DATE_CI)
          : "";
        const splitDate = e?.startdate || e?.endDate ? "～" : "";
        const paymentDate = e?.paymentDate
          ? moment(e?.paymentDate).format(COMMON.FORMAT_DATE_CI)
          : "";

        const recordAmount = e?.actualCost ?? 0;
        const taxAtion1 = findCostByCategory(e?.offsetCost ?? [], "tax");
        const taxExempt1 = findCostByCategory(e?.offsetCost ?? [], "tax_free");
        const taxAtion2 = findCostByCategory(e?.offsetCostSummary ?? [], "tax");
        const taxExempt2 = findCostByCategory(
          e?.offsetCostSummary ?? [],
          "tax_free"
        );
        const offsetAmount = taxExempt1 + taxExempt2 + taxAtion1 + taxAtion2;
        const excludingTax = recordAmount - offsetAmount;
        const consumptionTax = Math.floor(
          (excludingTax + taxAtion1 + taxAtion2) * (e?.taxSetting ?? 1)
        );
        const taxInclude = consumptionTax + excludingTax;

        const taxId1 = findIdByCategory(e?.offsetCost ?? [], "tax");
        const taxId2 = findIdByCategory(e?.offsetCost ?? [], "tax_free");
        const taxId3 = findIdByCategory(e?.offsetCostSummary ?? [], "tax");
        const taxId4 = findIdByCategory(e?.offsetCostSummary ?? [], "tax_free");

        totalRecordAmount += recordAmount;
        totalTaxAtion1 += taxAtion1;
        totalTaxExempt1 += taxExempt1;
        totalTaxAtion2 += taxAtion2;
        totalTaxExempt2 += taxExempt2;
        totalOffsetAmount += offsetAmount;
        totalExcludingTax += excludingTax;
        totalConsumptionTax += consumptionTax;
        totalTaxInclude += taxInclude;

        const obj: any = {
          key: Math.random(),
          no: idx + 1,
          companyId: e?.company?.id ?? 0,
          colabRefId: e?.company?.colabRefId ?? 0,

          id: e?.documentDirectOutsourceCostInformationId ?? 0,
          traderName: `${e?.company?.name ?? ""}`,
          targetPeriod: `${startDate}${splitDate}${endDate}`,
          datePayment: `${paymentDate}`,
          recordedAmount: recordAmount,
          taxAtion1: taxAtion1,
          taxExempt1: taxExempt1,
          taxAtion2: taxAtion2,
          taxExempt2: taxExempt2,
          offsetAmount: offsetAmount,
          excludingTax: excludingTax,
          consumptionTax: consumptionTax,
          taxInclude: taxInclude,

          taxId1: taxId1,
          taxId2: taxId2,
          taxId3: taxId3,
          taxId4: taxId4,
        };

        _item__child.push(obj);
      });

      let _objChildren: any = {
        key: Math.random(),
        no: idx + 1,
        projectName: "",
        constructionName: ite?.constructionName ?? "",
        contractWork: ite?.subConstructionName,
        recordedAmount: totalRecordAmount,
        taxAtion1: totalTaxAtion1,
        taxExempt1: totalTaxExempt1,
        taxAtion2: totalTaxAtion2,
        taxExempt2: totalTaxExempt2,
        offsetAmount: totalOffsetAmount,
        excludingTax: totalExcludingTax,
        consumptionTax: totalConsumptionTax,
        taxInclude: totalTaxInclude,
        children: _item__child,
      };

      // Push into list children
      listChildren.push(_objChildren);

      _totalAmount += totalRecordAmount;
      _totalTaxAtion1 += totalTaxAtion1;
      _totalTaxExempt1 += totalTaxExempt1;
      _totalTaxAtion2 += totalTaxAtion2;
      _totalTaxExempt2 += totalTaxExempt2;
      _totalOffsetAmount += totalOffsetAmount;
      _totalExcludingTax += totalExcludingTax;
      _totalConsumptionTax += totalConsumptionTax;
      _totalTaxInclude += totalTaxInclude;
    });

    let _obj: any = {
      key: Math.random(),
      no: index,
      projectName: item.projectName,
      constructionName: "",
      contractWork: "",
      recordedAmount: _totalAmount,
      taxAtion1: _totalTaxAtion1,
      taxExempt1: _totalTaxExempt1,
      taxAtion2: _totalTaxAtion2,
      taxExempt2: _totalTaxExempt2,
      offsetAmount: _totalOffsetAmount,
      excludingTax: _totalExcludingTax,
      consumptionTax: _totalConsumptionTax,
      taxInclude: _totalTaxInclude,
    };

    _obj["children"] = listChildren;

    // Push into master data
    output.push(_obj);
    index++;
  });

  return output;
};

const convertLineSummary = (item: any) => {
  const recordAmount = item?.actualCost ?? 0;
  const taxAtion1 = findCostByCategory(item?.offsetCost ?? [], "tax");
  const taxExempt1 = findCostByCategory(item?.offsetCost ?? [], "tax_free");
  const taxAtion2 = item?.offsetCostSummaryWithTax ?? 0;
  const taxExempt2 = item?.offsetCostSummaryWithoutTax ?? 0;
  const offsetAmount = taxExempt1 + taxExempt2 + taxAtion1 + taxAtion2;
  const excludingTax = recordAmount - offsetAmount;
  const consumptionTax = Math.floor(
    (excludingTax + taxAtion1 + taxAtion2) * (item?.taxSetting ?? 1)
  );
  const taxInclude = consumptionTax + excludingTax;

  return {
    recordedAmount: recordAmount,
    taxAtion1: taxAtion1,
    taxExempt1: taxExempt1,
    taxAtion2: taxAtion2,
    taxExempt2: taxExempt2,
    offsetAmount: offsetAmount,
    excludingTax: excludingTax,
    consumptionTax: consumptionTax,
    taxInclude: taxInclude,
  };
};

export { convertDataView, convertLineSummary };
