import {
  Button,
  DatePicker,
  Empty,
  Form,
  Input,
  Modal,
  Select,
  Spin,
  Tag,
  TimePicker,
} from "antd";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import "../../../../../assets/styles/AM/AM012.css";

import LoadingComponent from "../../../../../common/components/icons/LoadingComponent";
import helpers from "../../../../../common/helpers/common";

import { RootState } from "../../../../../store";
import { AM012Hanlder } from "../handler/HandlerImpl";
import locale from "antd/es/date-picker/locale/ja_JP";
import "moment/locale/ja";
import CalendarSvg from "../../../../../assets/icons/calendar.svg";

import XIconModal from "../../../../../assets/icons/x-icon-modal.svg";
import CloseIcon from "../../../../../assets/icons/x-icon-modal.svg";
import CloseIconModal from "../../../../../assets/icons/close.png";
import MESSAGE from "../../../../../common/constants/MESSAGE";
import TextArea from "antd/lib/input/TextArea";
import COMMON from "../../../../../common/constants/COMMON";
import REGEX from "../../../../../common/constants/REGEX";
type Props = {
  handler: AM012Hanlder;
};

const AM012UI = ({ handler }: Props) => {
  const isVisible = useSelector((state: RootState) => state.am012.isVisible);
  const loading = useSelector((state: RootState) => state.am012.loading);
  const typeModal = useSelector((state: RootState) => state.am012.typeModal);
  const requestId = useSelector((state: RootState) => state.am012.requestId);
  const haveProject = useSelector(
    (state: RootState) => state.am010.haveProject
  );
  const isUserInWorkspace = useSelector(
    (state: RootState) => state.auth.authData?.isUserInWorkspace
  );

  const isDisableSubmitButton = loading ||
    (handler?.requestAttendance?.id ?
      !handler?.requestAttendance?.approver?.fullName :
      !handler?.requestApprover?.fullName)

  useEffect(() => {
    handler.getProjectStatus();
  }, []);

  useEffect(() => {
    handler?.requestAttendance?.project?.id &&
      handler?.projectStatus &&
      handler.getProject(
        {
          page: COMMON.DEFAULT_PAGE,
          size: COMMON.DEFAULT_SIZE,
          keyword: undefined,
          statusId: handler.projectStatus,
        },
        handler.requestAttendance.project.id
      );
  }, [handler?.requestAttendance?.project?.id, handler?.projectStatus]);

  useEffect(() => {
    if (typeModal === "create") {
      handler.handleGetConstructionFirstOpenModal({
        page: COMMON.DEFAULT_PAGE,
        size: COMMON.DEFAULT_SIZE,
        keyword: undefined,
        statusId: handler.projectStatus,
      });
      handler.getRequestApprover();
    }
  }, [handler?.projectStatus]);

  useEffect(() => {
    handler?.requestApprover?.fullName &&
      handler.form.setFieldValue("approver", handler.requestApprover.fullName);
  }, [handler?.requestApprover]);

  useEffect(() => {
    if (typeModal === "create") {
      handler?.projectStatus && handler.handleGetAttendanceCategory();
      handler?.projectStatus &&
        handler.handleGetProjectFirstOpenModal({
          page: COMMON.DEFAULT_PAGE,
          size: COMMON.DEFAULT_SIZE,
          statusId: handler?.projectStatus,
        });
    }
  }, [handler?.projectStatus]);

  useEffect(() => {
    if (typeModal === "update")
      requestId && handler.handleGetRequestAttendance(requestId);
  }, [typeModal, requestId]);

  useEffect(() => {
    if (handler?.requestAttendance?.id) {
      handler.handleFillFormUpdate();
      handler.handleGetAttendanceCategory();
    }
  }, [handler?.requestAttendance?.id]);

  useEffect(() => {
    if (handler.isDirty) {
      window.addEventListener("beforeunload", helpers.alertUser);
      return () => {
        window.removeEventListener("beforeunload", helpers.alertUser);
      };
    } else {
      window.removeEventListener("beforeunload", helpers.alertUser, true);
    }
  }, [handler.isDirty]);
  return (
    <Modal
      className="group-detail-modal"
      style={{ marginLeft: 300, marginTop: 40, marginBottom: 40 }}
      visible={isVisible}
      closable={false}
      maskClosable={false}
      width={"996px"}
      centered={true}
      closeIcon={<img src={CloseIconModal} alt="close icon" />}
      footer={
        <>
          <Button
            disabled={isDisableSubmitButton}
            size="large"
            type="primary"
            style={{ marginLeft: 16, width: 76, letterSpacing: -1.5 }}
            htmlType="submit"
            form="formAM012"
          >
            提出
          </Button>
          <Button
            disabled={loading}
            size="large"
            style={{
              marginLeft: 16,
              width: 118,
              color: "#666",
              fontWeight: 400,
              fontSize: 14,
            }}
            onClick={handler.onCancel}
          >
            キャンセル
          </Button>
        </>
      }
    >
      <Spin indicator={LoadingComponent} spinning={loading}>
        <div
          className="w-100-percent header-modal-containter"
          style={{ height: 50 }}
        >
          <div className="header-modal-title-om004 ">打刻申請</div>
          <div style={{ cursor: "pointer" }} onClick={handler.onCancel}>
            <img alt="" src={XIconModal} style={{ height: 18, width: 18 }} />
          </div>
        </div>
        <div style={{ minHeight: 224, marginTop: 24 }} className="am012-form">
          <Form
            onValuesChange={handler.checkDirty}
            form={handler.form}
            id="formAM012"
            layout="horizontal"
            autoComplete="off"
            onFinish={handler.handleCreateAttendance}
          >
            <Form.Item
              style={{ marginBottom: 16 }}
              name="requestCategoryId"
              labelAlign="right"
              colon={false}
              className="form-item-label"
              label={
                <div className="w-100-percent pr-20 form-label-member">
                  事由<span style={{ color: "#FE0909" }}>*</span>
                </div>
              }
              rules={[
                { required: true, message: MESSAGE.MESSAGE_REQUIRED_FIELD },
              ]}
            >
              <Select
                style={{ width: 826 }}
                options={handler.attendanceCategory}
              />
            </Form.Item>
            <Form.Item
              style={{ marginBottom: 16 }}
              name="requestDate"
              labelAlign="right"
              colon={false}
              className="form-item-label"
              label={
                <div className="w-100-percent pr-20 form-label-member">
                  対象日<span style={{ color: "#FE0909" }}>*</span>
                </div>
              }
              rules={[
                { required: true, message: MESSAGE.MESSAGE_REQUIRED_FIELD },
              ]}
            >
              <DatePicker
                inputReadOnly
                placeholder=""
                style={{ width: 826, height: 40 }}
                locale={locale}
                format={COMMON.FORMAT_DATE}
                suffixIcon={
                  <img alt="" src={CalendarSvg} className="icon-calender" />
                }
              />
            </Form.Item>
            <Form.Item
              style={{ marginBottom: 16 }}
              name="requestTime"
              labelAlign="right"
              colon={false}
              className="form-item-label"
              label={
                <div className="w-100-percent pr-20 form-label-member">
                  時間<span style={{ color: "#FE0909" }}>*</span>
                </div>
              }
              rules={[
                { required: true, message: MESSAGE.MESSAGE_REQUIRED_FIELD },
              ]}
            >
              <TimePicker
                placeholder=""
                format={COMMON.FORMAT_TIME1}
                getPopupContainer={(trigger: any) => trigger.parentNode}
                style={{ width: 826, height: 40 }}
                locale={locale}
              />
            </Form.Item>
            {isUserInWorkspace && (
              <>
                <Form.Item
                  style={{ marginBottom: 16 }}
                  name="projectId"
                  labelAlign="right"
                  colon={false}
                  className="form-item-label"
                  label={
                    <div className="w-100-percent pr-20 form-label-member">
                      案件名<span style={{ color: "#FE0909" }}>*</span>
                    </div>
                  }
                  rules={[
                    { required: true, message: MESSAGE.MESSAGE_REQUIRED_FIELD },
                  ]}
                >
                  <Select
                    showArrow
                    showSearch
                    listHeight={120}
                    filterOption={() => true}
                    loading={handler.loadingOption}
                    onSearch={handler.handleSearchOption}
                    getPopupContainer={(trigger: any) => trigger.parentNode}
                    onPopupScroll={handler.handleScrollProject}
                    notFoundContent={
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="該当なデータはありません"
                      />
                    }
                    onSelect={(e: any) => {
                      handler.form.setFieldValue("constructionId", null);
                      handler.resetProjectWhenSelectOrBlur(e);
                      handler.handleFindConstructions(e);
                    }}
                  >
                    {handler.projects.map((select) => {
                      return (
                        <Select.Option key={select.id} value={select.id}>
                          <div
                            style={{
                              fontSize: 14,
                              fontWeight: 400,
                              color: "#121212",
                            }}
                          >
                            {select?.projectName}
                          </div>
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: 16 }}
                  name="constructionId"
                  labelAlign="right"
                  colon={false}
                  className="form-item-label"
                  label={
                    <div className="w-100-percent pr-20 form-label-member">
                      工事名<span style={{ color: "#FE0909" }}>*</span>
                    </div>
                  }
                  rules={[
                    { required: true, message: MESSAGE.MESSAGE_REQUIRED_FIELD },
                  ]}
                >
                  <Select
                    className="am010__header-select"
                    showArrow
                    showSearch
                    listHeight={120}
                    filterOption={() => true}
                    onSearch={handler.handleSearchOptionConstruction}
                    onPopupScroll={handler.handleScrollConstruction}
                    loading={handler.loadingOption}
                    getPopupContainer={(trigger: any) => trigger.parentNode}
                    tagRender={({ label, closable, onClose }: any) => {
                      return (
                        <Tag
                          closable={closable}
                          onClose={onClose}
                          color="rgba(231, 230, 255, 1)"
                          closeIcon={
                            <img
                              style={{ width: 10, marginLeft: 6 }}
                              alt=""
                              src={CloseIcon}
                            />
                          }
                          style={{
                            margin: "2px 4px 2px 0",
                            display: "flex",
                            alignItems: "center",
                            height: 28,
                            borderRadius: 4,
                            color: "#222222",
                            fontSize: 14,
                            fontWeight: 400,
                          }}
                        >
                          {label[0]?.props?.children}
                        </Tag>
                      );
                    }}
                    notFoundContent={
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="該当なデータはありません"
                      />
                    }
                    onSelect={async (e: any) => {
                      await handler.resetConstructionWhenSelectOrBlur(e);
                      handler.handleFindProjects(e);
                    }}
                  >
                    {handler.constructions.map((select) => {
                      return (
                        <Select.Option
                          style={{ alignItems: "center" }}
                          key={select.id}
                          value={select.id}
                        >
                          <div
                            style={{
                              fontSize: 14,
                              fontWeight: 400,
                              color: "#121212",
                            }}
                          >
                            {select?.name}
                          </div>
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </>
            )}
            <Form.Item
              style={{ marginBottom: 16 }}
              name="approver"
              labelAlign="right"
              colon={false}
              className="form-item-label "
              label={
                <div className="w-100-percent pr-20 form-label-member">
                  承認者<span style={{ color: "#FE0909" }}>*</span>
                </div>
              }
              rules={[
                {
                  whitespace: true,
                  message: MESSAGE.MESSAGE_REQUIRED_FIELD,
                },
                {
                  required: true,
                  message: MESSAGE.MESSAGE_REQUIRED_FIELD,
                },
              ]}
            >
              <Input
                style={{ height: 40, width: 826 }}
                maxLength={50}
                disabled
              />
            </Form.Item>
            <Form.Item
              style={{ marginBottom: 16 }}
              name="note"
              labelAlign="right"
              colon={false}
              className="form-item-label "
              label={
                <div className="w-100-percent pr-20 form-label-member">
                  備考 <span style={{ color: "#FE0909" }}></span>
                </div>
              }
              rules={[
                {
                  pattern: new RegExp(REGEX.EXCEPT_VIETNAMESE),
                  message: MESSAGE.MESSAGE_INVALID_INPUT,
                },
              ]}
            >
              <TextArea
                rows={4}
                placeholder="3000文字"
                style={{ resize: "none", marginBottom: 3 }}
                maxLength={3000}
                onBlur={(value: any) => handler.trimValueInput(value, "note")}
              />
            </Form.Item>
          </Form>
        </div>
      </Spin>
    </Modal>
  );
};

export default AM012UI;
