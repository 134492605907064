import OM004Api from "./infrastructure/api/Api"
import OM004Handler from "./presenter/handler/Handler";
import OM004UI from "./presenter/ui/UI";
import OM004Service from "./usecase/Service";

const OM004Component = () => {
    const om004Api = OM004Api();
    const om004Service = OM004Service(om004Api)
    const om004Handler = OM004Handler(om004Service)
    return <OM004UI handler={om004Handler} />
}

export default OM004Component