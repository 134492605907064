import CM035Handler from './presenter/handler/Handler';
import MainUI from './presenter/ui/UI';

import '../../../../assets/styles/CM/cm035.css';
import CM0035Api from './infrastructure/api/Api';
import CM0035Service from './usecase/Service';

const CM035Component = () => {
    const api = CM0035Api();
    const service = CM0035Service(api);
    const handler = CM035Handler(service);
    return <MainUI handler={handler} />;
};

export default CM035Component;
