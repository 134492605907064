import React from 'react';
import { AM010Handler } from '../handler/HandlerImpl';
import AM010HeaderTimerForm from './AM010HeaderTimerForm';
import moment from 'moment';
import COMMON from '../../../../../common/constants/COMMON';
import helpers from '../../../../../common/helpers/common';
import ClockComponent from '../../../../../common/components/clock/ClockComponent';
import TimeComponent from '../../../../../common/components/clock/TimeComponent';

type props = {
  handler: AM010Handler;
};

export default function AM010HeaderTimerProject({ handler }: props) {
  return (
    <div className="am010__timer-container">
      <div className="am010__timer-box1">
        <div className="am010__timer-select">
          <div className="am010__timer-title">
            <h4>{moment().format(COMMON.FORMAT_DATE_OM)}</h4>
          </div>
          <div className="am010__timer-dropdown">
            <AM010HeaderTimerForm handler={handler} />
          </div>
        </div>
        <div className="am010__timer-clock">
          <ClockComponent />
        </div>
      </div>
      <div className="am010__timer-box2">
        <div className="am010__timer-counter">
          <p>
            {moment().locale('en').format('A')} &nbsp;
            <TimeComponent />
          </p>
        </div>
        <div className="am010__timer-action">

          <div
            className="am010__action-register1"
            onClick={() => {
              handler.handleButtonType(true);
              handler.form.submit();
            }}
          >
            出勤登録
          </div>
          <div
            className="am010__action-register2"
            onClick={() => {
              handler.handleButtonType(false);
              handler.form.submit();
            }}
          >
            退勤登録
          </div>
        </div>
      </div>
    </div>
  );
}
