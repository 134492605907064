import { Button, Col, Input, Row, Form } from "antd";
import SearchSvg from "../../../../../assets/icons/search-icon.svg";
import PlusVectorGreenSvg from "../../../../../assets/icons/plus-vector-green.svg";
import GraphSvg from "../../../../../assets/icons/graph.svg";
import { PM002HeaderHandler } from "../handler/HandlerImpl";
import { useLocation, useSearchParams } from "react-router-dom";
import ButtonGrid from "../../../../../assets/icons/button-grid.svg";
import ButtonList from "../../../../../assets/icons/button-list.svg";
import { useEffect } from "react";
import { RootState } from "../../../../../store";
import { useSelector } from "react-redux";
import { AUTHORIZATION_CODE, SYSTEM_ROLES } from "../../../../../common/constants/AUTHORIZATION";
import { isHasPrivilege } from "../../../../../common/helpers/privilege";
import { PLAN_TYPE } from "../../../../../common/constants/COMMON";
import BreadcrumbComponent from "../../../../../common/components/breadcrumb/BreadcrumbComponent";
import BREADCRUMB from "../../../../../common/constants/BREADCRUMB";

interface props {
  handler: PM002HeaderHandler;
}
const PM002HeaderUI = ({ handler }: props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const statusId = params.get("statusId") || "0";
  const keyword = searchParams.get("keyword") || "";
  const permissions = useSelector(
    (state: RootState) => state.auth.authData?.permission
  );


  const isRoleAdminBasic =
    useSelector((state: RootState) => state.auth?.authData?.isRoleAdminBasic)


  useEffect(() => {
    handler.form.setFieldsValue({ text: keyword });
  }, []);


  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
        }}
      >
        <div className="om002-header-container">
          <BreadcrumbComponent data={BREADCRUMB.PM003} />
          <div className="header-row-title" style={{ marginTop: 8 }}>
            案件管理
          </div>
        </div>
        <div className="search-project"    >
          <Form form={handler.form} style={{ padding: 0, margin: 0 }}>
            <Form.Item name={"text"} style={{ padding: 0, margin: 0 }}>
              <Input
                style={{ marginRight: 16, borderRadius: "3px" }}
                size="large"
                autoComplete="off"
                prefix={<img alt="" src={SearchSvg} />}
                placeholder="検索したい情報を入力してください（例：案件名、発注元会社）"  // CR #344212
                className="search"
                onChange={handler.onSearchChange}
                allowClear
              />
            </Form.Item>
          </Form>
        </div>
      </div>

      <Row className="header-info-number" gutter={[16, 20]}>
        <Col
          style={{ width: "20%", cursor: "pointer" }}
          onClick={() => handler.onInfoCardChange(0)}
        >
          <div
            className={`header-info-card ${statusId === "0" ? "info-card-default" : ""
              }`}
          >
            <Button
              className="header-button-icon"
              size="large"
              icon={
                <img
                  className="filter-white"
                  style={{ width: "24px" }}
                  alt=""
                  src={GraphSvg}
                />
              }
            ></Button>
            <div className="header-content">
              <div className="header-content-number">
                {handler.totalCountDefault}
              </div>
              <div>全部</div>
            </div>
          </div>
        </Col>
        {handler.newDataStatus.map((element: any) => (
          <Col
            style={{ width: "20%", cursor: "pointer" }}
            key={element.id}
            onClick={() => {
              handler.onInfoCardChange(element.id);
              handler.onPageChangeCard(1);
            }}
          >
            <div
              className={`header-info-card ${statusId == element.id ? "info-card-default-child" : ""
                }`}
            >
              <Button
                className="header-button-icon"
                size="large"
                icon={<img className="filter-white" alt="" src={element.src} />}
              ></Button>
              <div className="header-content">
                <div className="header-content-number">{element.count}</div>
                <div>{element.name}</div>
              </div>
            </div>
          </Col>
        ))}
      </Row>
      {/* row 3 */}
      <Row className="header-button" gutter={[16, 20]}>
        <Col span={4} className="header-button-title">
          案件一覧
        </Col>
        <Col span={20}>
          <div style={{ float: "right" }}>
            <Button
              size="large"
              type="link"
              style={{ marginRight: 8, border: "none", cursor: "context-menu" }}
              icon={<img alt="" src={ButtonGrid} />}
              onClick={handler.toOtherPageCard}
            ></Button>
            <Button
              type="link"
              style={{ marginRight: 24, border: "none" }}
              size="large"
              icon={<img alt="" src={ButtonList} />}
              onClick={() => {
                handler.onPageChangeCard(1);
                handler.toOtherPageList();
              }}
            ></Button>
            {((permissions?.includes(AUTHORIZATION_CODE.S1) || isRoleAdminBasic)) ? (
              <Button
                size="large"
                className="button-purple"
                onClick={handler.showModal}
                icon={
                  <img
                    alt=""
                    src={PlusVectorGreenSvg}
                    style={{
                      paddingRight: 12,
                      border: "none",
                      marginBottom: 4,
                    }}
                  />
                }
              >
                新規案件作成
              </Button>
            ) : null}
          </div>
        </Col>
      </Row>
    </>
  );
};
export default PM002HeaderUI;
