import PM012Api from "./infrastructure/api/Api";
import PM012Service from "./usecase/Service";
import PM012Handler from "./presenter/handler/Handler";
import PM012UI from "./presenter/ui/UI";

const PM012Component = () => {
  const pm012Api = PM012Api();
  const pm012Service = PM012Service(pm012Api);
  const pm012Handler = PM012Handler(pm012Service);
  return <PM012UI handler={pm012Handler} />;
};

export default PM012Component;
