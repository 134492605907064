import React from "react";
import { Handler } from "./handler/Handler";
import { MainUI } from "./ui/UI";
import { FormInstance } from "antd";

export interface UploadAvatarImpl {
    getBinary?: any;
    initialImageUrl?: string;
    style?: React.CSSProperties | undefined
    form: FormInstance<any>
}

const UploadAvatarComponent = ({ getBinary, initialImageUrl, form, style }: UploadAvatarImpl) => {
    const handler = Handler()
    return <MainUI handler={handler} getBinary={getBinary} initialImageUrl={initialImageUrl} form={form} style={style} />
}

export default UploadAvatarComponent