import CM0017Api from './infrastructure/api/Api';
import CM0017Handler from './presenter/handler/Handler';
import MainUI from './presenter/ui/UI';
import CM0017Service from './usecase/Service';

import '../../../assets/styles/AM/AM002.css';
import '../../../assets/styles/OM/OM002.css';
import '../../../assets/styles/CM/CM0017.css';

const CM0017Component = () => {
    const api = CM0017Api();
    const service = CM0017Service(api);
    const handler = CM0017Handler(service);
    return <MainUI handler={handler} />;
};

export default CM0017Component;
