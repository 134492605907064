import React, { useEffect } from "react";
import { Handler } from "../handler/HandlerImpl";
import "../../../../../assets/styles/AOP/AOP001.css";
import MenuWrapperComponent from "../../../../../common/components/menu-wrapper";
import BreadcrumbComponent from "../../../../../common/components/breadcrumb/BreadcrumbComponent";
import { Button } from "antd";
import DeleteRed from "../../../../../assets/icons/delete-red.svg";
import PlusFullWhite from "../../../../../assets/icons/plus-full-white.svg";
import TableComponent from "./Table";
import COMMON from "../../../../../common/constants/COMMON";
import HomeSvg from "../../../../../assets/icons/home.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import {
  AUTHORIZATION_CODE,
  SYSTEM_ROLES,
} from "../../../../../common/constants/AUTHORIZATION";

interface props {
  handler: Handler;
}
const BREADCRUMB_AOP001 = [
  {
    title: "設定",
    url: "",
    icon: (
      <img style={{ paddingBottom: 2, paddingRight: 8 }} alt="" src={HomeSvg} />
    ),
  },
  {
    title: "役職・権限",
    url: "",
    icon: "",
  },
];
function MainUI({ handler }: props) {
  const permission = useSelector(
    (state: RootState) => state.auth.authData?.permission
  );
  const isRoleAdminBasic = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdminBasic
  );
  useEffect(() => {
    handler.getListRole({
      page: handler.page,
      size: COMMON.DEFAULT_SIZE,
    });
  }, [handler.page, handler.isRefresh]);
  return (
    <MenuWrapperComponent chosenKey={71} openKey={7}>
      <div className="ic1-wrapper">
        <BreadcrumbComponent data={BREADCRUMB_AOP001} />
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h2 className="ic1-header">役職・権限</h2>
            {isRoleAdminBasic ? (
              <div style={{ display: "flex", gap: 16 }}>
                <Button
                  size="large"
                  icon={
                    <img
                      alt=""
                      src={PlusFullWhite}
                      style={{ marginRight: 9 }}
                    />
                  }
                  type="primary"
                  style={{ width: 118 }}
                  onClick={() => handler.redirectAOP002()}
                >
                  役職追加
                </Button>
                <Button
                  size="large"
                  icon={
                    <img
                      alt=""
                      src={DeleteRed}
                      style={{ marginRight: 9, marginBottom: 0 }}
                    />
                  }
                  style={{ width: 106 }}
                  className="button-delete-aop001"
                  disabled={handler.selectedRowsKey?.length == 0}
                  onClick={handler.deleteManyRole}
                >
                  削除
                </Button>
              </div>
            ) : null}
          </div>
        </div>
        <div className="ic1-body">
          <TableComponent handler={handler} />
        </div>
      </div>
    </MenuWrapperComponent>
  );
}

export default MainUI;
