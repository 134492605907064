import { OM002Api } from "./ServiceImpl";

const OM002Service = (OM002Api: OM002Api) => {
  const getGroup = async (): Promise<any> => {
    try {
      return await OM002Api.getGroup();
    } catch (error) {
      throw error;
    }
  };
  const getGroupMember = async (params: any): Promise<any> => {
    try {
      return await OM002Api.getGroupMember(params);
    } catch (error) {
      throw error;
    }
  };

  const deleteMember = async (params: any): Promise<any> => {
    try {
      return await OM002Api.deleteMember(params);
    } catch (error) {
      throw error;
    }
  };

  return {
    getGroup,
    getGroupMember,
    deleteMember,
  };
};

export default OM002Service;
