import { AM028TableHandler } from "../handler/HandlerImpl";
import { Table as TableAnt } from "antd";
import { columns } from "./Columns";

import EmptyData from "../../../../../assets/icons/empty-data.svg";
import PaginationComponentList from "../../../../../common/components/pagination/PaginationComponentList";
import { STATUS_REQUEST } from "../handler/Handler";
import { useMemo } from "react";

interface props {
  handler: AM028TableHandler;
}

const Table = ({ handler }: props) => {
  const index = useMemo(() => {
    return handler.funcCheckIndex(handler.currentTab, handler.menuChosen);
  }, [handler.currentTab, handler.menuChosen]);
  return (
    <div className="am028-table">
      <TableAnt
        columns={columns(handler)}
        ref={handler.refTable}
        dataSource={handler.dataTable}
        pagination={false}
        scroll={{ x: "max-content", y: "41vh", scrollToFirstRowOnChange: true }}
        onChange={handler.handleChangeTable}
        locale={{
          emptyText: <img src={EmptyData} alt="" style={{ width: "25%" }} />,
        }}
        rowSelection={
          handler.currentTab === "2"
            ? {
                columnWidth: 50,
                selectedRowKeys: handler.selectedRowKeys,

                getCheckboxProps: (record) => {
                  return {
                    disabled:
                      record?.status?.code === STATUS_REQUEST[0].code ||
                      record?.status?.code === STATUS_REQUEST[2].code,
                  };
                },
                onChange: handler.handleChangeSelectedRowKeys,
              }
            : undefined
        }
      />
      {handler.dataTable?.length > 0 ? (
        <PaginationComponentList
          total={handler.total}
          page={handler.page[index]}
          size={handler.size[index]}
          setPage={handler.handleChangePage}
          setSize={handler.handleChangeSize}
        />
      ) : null}
    </div>
  );
};

export default Table;
