import axiosConfig from '../../../../../config/axiosConfig';
import { PlanSetting, PlanSettingPayload } from '../../entity/Entity';

const API = () => {
    const getPlanList = async (params: any): Promise<any> => {
        try {
            const url = '/plan/list';
            const response = await axiosConfig.get(url, { params });
            return response.data;
        } catch (error) {
            throw error;
        }
    };
    const getPlanSettingDetail = async (params: any): Promise<any> => {
        try {
            const url = '/plan/setting/detail';
            const response = await axiosConfig.get(url, { params });
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    const postPlanSetting = async (data: PlanSettingPayload): Promise<any> => {
        try {
            const url = '/plan/setting';
            const response = await axiosConfig.post(url, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    return { postPlanSetting, getPlanSettingDetail, getPlanList };
};

export default API;
