import { MaterialCostAPIImpl } from "./ServiceImpl";
import ErrorNotification from "../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../common/constants/MESSAGE";
import {
  MoneyIndirectCostEnablePayload,
  BaseResponseType,
  MoneyIndirectCostListData,
  MoneyIndirectCostInformationListData,
  MoneySubmitIndirectCostPayload,
} from "../entity/Entity";

const MaterialCostService = (api: MaterialCostAPIImpl) => {
  const getMoneyIndirectCostList = async (params: {
    constructionId: number;
    keyword?: string;
    sortBy?: string;
    sortType?: string;
  }): Promise<MoneyIndirectCostListData | null> => {
    try {
      const response = await api.getMoneyIndirectCostList({
        ...params,
        documentId: 0,
      });
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return null;
    }
  };

  const putMoneyIndirectCostEnable = async (
    payload: MoneyIndirectCostEnablePayload
  ): Promise<BaseResponseType | null> => {
    try {
      const response = await api.putMoneyIndirectCostEnable(payload);
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return null;
    }
  };
  const getMoneyIndirectCostInformationList = async (params: {
    indirectCostId: number;
    constructionId: number;
    sortBy?: string;
    sortType?: string;
    from?: string;
    to?: string;
    page: number;
    size: number;
  }): Promise<MoneyIndirectCostInformationListData | null> => {
    try {
      const response = await api.getMoneyIndirectCostInformationList(params);
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      return null;
    }
  };
  const putMoneySubmitIndirectCost = async (
    payload: MoneySubmitIndirectCostPayload
  ): Promise<BaseResponseType | null> => {
    try {
      const response = await api.putMoneySubmitIndirectCost(payload);
      return response.data;
    } catch (error: any) {
      throw error;
    }
  };

  const doExportCM0015 = async (params: any): Promise<any> => {
    return await api.doExportCM0015(params);
  };
  return {
    getMoneyIndirectCostList,
    putMoneyIndirectCostEnable,
    getMoneyIndirectCostInformationList,
    putMoneySubmitIndirectCost,
    doExportCM0015,
  };
};

export default MaterialCostService;
