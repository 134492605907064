import { Tooltip } from "antd";
import helpers from "../../../../../common/helpers/common";
import TooltipText from "../../../../../common/components/tooltip-text/TooltipText";
import { TITLE } from "../../../../../common/constants/MESSAGE";

const COLUMNS: any = [
  {
    title: TITLE.NAME,
    key: TITLE.NAME,
    width: 150,
    render: (row: any) => {
      if (!row?.name) return "";
      if (!row.name.includes("INVALID")) return <div>{row.name}</div>;
      const errorMes = row.name.split("INVALID");
      if (errorMes[1] === "")
        return <div style={{ color: "#FF0909" }}>{errorMes[0]}</div>;
      return (
        <Tooltip mouseLeaveDelay={0} color="#FF0909" title={errorMes[0]}>
          <div style={{ color: "#FF0909" }}>{errorMes[1]}</div>
        </Tooltip>
      );
    },
  },
  {
    title: TITLE.CCUSTECHNICIANID,
    key: TITLE.CCUSTECHNICIANID,
    width: 170,
    render: (row: any) => {
      if (!row?.ccusId) return "";
      if (!row.ccusId.includes("INVALID"))
        return <div>{helpers.convertToCCUSFormat(row?.ccusId)}</div>;
      const errorMes = row.ccusId.split("INVALID");
      if (errorMes[1] === "" || errorMes[1] === "undefined")
        return <div style={{ color: "#FF0909" }}>{errorMes[0]}</div>;
      return (
        <Tooltip mouseLeaveDelay={0} color="#FF0909" title={errorMes[0]}>
          <div style={{ color: "#FF0909" }}>{errorMes[1]}</div>
        </Tooltip>
      );
    },
  },
  {
    title: TITLE.EXPIRATIONDATE,
    key: TITLE.EXPIRATIONDATE,
    width: 150,
    render: (row: any) => {
      if (!row?.ccusDate) return "";
      if (!row?.ccusDate.includes("INVALID"))
        return (
          <div>
            {helpers.formatDateStringJA(row?.ccusDate)}
          </div>
        );
      const errorMes = row.ccusDate.split("INVALID");
      if (errorMes[1] === "")
        return <div style={{ color: "#FF0909" }}>{errorMes[0]}</div>;
      return (
        <Tooltip mouseLeaveDelay={0} color="#FF0909" title={errorMes[0]}>
          <div style={{ color: "#FF0909" }}>{errorMes[1]}</div>
        </Tooltip>
      );
    },
  },
  {
    title: TITLE.DEPARTMENT_TITLE,
    key: TITLE.DEPARTMENT_TITLE,
    dataIndex: "branch",
    editable: true,
    width: 150,
  },
  {
    title: TITLE.POSITION,
    key: TITLE.POSITION,
    dataIndex: "role",
    editable: true,
    width: 150,
  },
  {
    title: TITLE.ID,
    key: TITLE.ID,
    width: 180,
    render: (row: any) => {
      if (!row?.username) return "";
      if (!row.username.includes("INVALID")) return <div>{row.username}</div>;
      const errorMes = row.username.split("INVALID");
      if (errorMes[1] === "")
        return <div style={{ color: "#FF0909" }}>{errorMes[0]}</div>;
      return (
        <div>
          <Tooltip
            overlayStyle={{ position: "absolute" }}
            color="#FF0909"
            title={errorMes[0]}
          >
            <div style={{ color: "#FF0909" }}>{errorMes[1]}</div>
          </Tooltip>
        </div>
      );
    },
  },
  {
    title: TITLE.PHONENUMBER,
    key: TITLE.PHONENUMBER,
    width: 200,
    render: (row: any) => {
      if (!row?.phone) return "";
      if (!row.phone.includes("INVALID")) return <div>{row.phone}</div>;
      const errorMes = row.phone.split("INVALID");
      if (errorMes[1] === "")
        return <div style={{ color: "#FF0909" }}>{errorMes[0]}</div>;
      return (
        <Tooltip color="#FF0909" title={errorMes[0]}>
          <div style={{ color: "#FF0909" }}>{errorMes[1]}</div>
        </Tooltip>
      );
    },
  },
  {
    title: TITLE.EMAILADDRESS,
    width: 250,
    key: TITLE.EMAILADDRESS,
    render: (row: any) => {
      if (!row?.email) return "";
      if (!row.email.includes("INVALID")) return <div>{row.email}</div>;
      const errorMes = row.email.split("INVALID");
      if (errorMes[1] === "")
        return <div style={{ color: "#FF0909" }}>{errorMes[0]}</div>;
      return (
        <Tooltip color="#FF0909" title={errorMes[0]}>
          <div style={{ color: "#FF0909" }}>{errorMes[1]}</div>
        </Tooltip>
      );
    },
  },
  {
    title: TITLE.ADDRESS,
    key: TITLE.ADDRESS,
    width: 150,
    render: (row: any) => {
      if (!row?.address) return "";
      if (!row.address.includes("INVALID")) return <div>{row.address}</div>;
      const errorMes = row.address.split("INVALID");
      if (errorMes[1] === "")
        return <div style={{ color: "#FF0909" }}>{errorMes[0]}</div>;
      return (
        <Tooltip color="#FF0909" title={errorMes[0]}>
          <div style={{ color: "#FF0909" }}>{errorMes[1]}</div>
        </Tooltip>
      );
    },
  },
  {
    title: TITLE.QUALIFICATIONGROUP,
    key: TITLE.QUALIFICATIONGROUP,
    width: 120,
    render: (row: any) => {
      return (
        <div>
          {row.certificate1?.map((element: any, index: number) => {
            if (!element) return <div key={TITLE.QUALIFICATIONGROUP + index}></div>;
            if (!(element + "").includes("INVALID"))
              return <div key={TITLE.QUALIFICATIONGROUP + index}>{element}</div>;
            const errorMes = element.split("INVALID");
            if (errorMes[1] === "")
              return (
                <div key={TITLE.QUALIFICATIONGROUP + index} style={{ color: "#FF0909" }}>
                  {errorMes[0]}
                </div>
              );
            return (
              <Tooltip
                key={TITLE.QUALIFICATIONGROUP + index}
                color="#FF0909"
                title={errorMes[0]}
              >
                <div style={{ color: "#FF0909" }}>{errorMes[1]}</div>
              </Tooltip>
            );
          })}
        </div>
      );
    },
  },
  {
    title: TITLE.QUALIFICATIONREGISTRATION,
    key: TITLE.QUALIFICATIONREGISTRATION,
    width: 200,
    render: (row: any) => {
      return (
        <div>
          {row.certificate2?.map((element: any, index: number) => {
            if (!element) return <div key={TITLE.QUALIFICATIONREGISTRATION + index} ></div>;
            if (!element.includes("INVALID"))
              return <div key={TITLE.QUALIFICATIONREGISTRATION + index} style={{ maxWidth: 170 }}><TooltipText content={element} /></div>;
            const errorMes = element.split("INVALID");
            if (errorMes[1] === "")
              return (
                <div key={TITLE.QUALIFICATIONREGISTRATION + index} style={{ color: "#FF0909" }}>
                  {errorMes[0]}
                </div>
              );
            return (
              <Tooltip
                key={TITLE.QUALIFICATIONREGISTRATION + index}
                color="#FF0909"
                title={errorMes[0]}
              >
                <div style={{ color: "#FF0909" }}>{errorMes[1]}</div>
              </Tooltip>
            );
          })}
        </div>
      );
    },
  },
  {
    title: TITLE.DATEOFACQUISITION,
    key: TITLE.DATEOFACQUISITION,
    width: 200,
    render: (row: any) => {
      return (
        <div>
          {row.certificate3?.map((element: any, index: number) => {
            if (!element) return <div key={TITLE.DATEOFACQUISITION + index} ></div>;
            if (!element.includes("INVALID"))
              return (
                <div key={TITLE.DATEOFACQUISITION + index}>
                  {helpers.formatDateStringJA(element)}
                </div>
              );
            const errorMes = element.split("INVALID");
            if (errorMes[1] === "")
              return (
                <div key={TITLE.DATEOFACQUISITION + index} style={{ color: "#FF0909" }}>
                  {errorMes[0]}
                </div>
              );
            return (
              <Tooltip
                key={TITLE.DATEOFACQUISITION + index}
                color="#FF0909"
                title={errorMes[0]}
              >
                <div style={{ color: "#FF0909" }}>{helpers.formatDateStringJA(errorMes[1])}</div>
              </Tooltip>
            );
          })}
        </div>
      );
    },
  },
  {
    title: TITLE.ACQUISITIONNUMBER,
    key: TITLE.ACQUISITIONNUMBER,
    width: 150,
    render: (row: any) => {
      return (
        <div>
          {row.certificate4?.map((element: any, index: number) => {
            if (!element) return <div key={"取得NO" + index} ></div>;
            if (!(element + "").includes("INVALID"))
              return <div key={"取得NO" + index} style={{ maxWidth: 110 }}><TooltipText content={element} /></div>;
            const errorMes = element.split("INVALID");
            if (errorMes[1] === "")
              return (
                <div key={"取得NO" + index} style={{ color: "#FF0909" }}>
                  {errorMes[0]}
                </div>
              );
            return (
              <Tooltip
                key={"取得NO" + index}
                color="#FF0909"
                title={errorMes[0]}
              >
                <div style={{ color: "#FF0909" }}>{errorMes[1]}</div>
              </Tooltip>
            );
          })}
        </div>
      );
    },
  },
  {
    title: TITLE.PLACEOFACQUISITION,
    key: TITLE.PLACEOFACQUISITION,
    width: 150,
    render: (row: any) => {
      return (
        <div>
          {row.certificate5?.map((element: any, index: number) => {
            if (!element) return <div key={TITLE.PLACEOFACQUISITION + index}></div>;
            if (!element.includes("INVALID"))
              return <div key={TITLE.PLACEOFACQUISITION + index} style={{ maxWidth: 110 }} ><TooltipText content={element} /></div>;
            const errorMes = element.split("INVALID");
            if (errorMes[1] === "")
              return (
                <div key={TITLE.PLACEOFACQUISITION + index} style={{ color: "#FF0909" }}>
                  {errorMes[0]}
                </div>
              );
            return (
              <Tooltip
                key={TITLE.PLACEOFACQUISITION + index}
                color="#FF0909"
                title={errorMes[0]}
              >
                <div style={{ color: "#FF0909" }}>{errorMes[1]}</div>
              </Tooltip>
            );
          })}
        </div>
      );
    },
  },
  {
    title: TITLE.EXPIRATIONDATE,
    key: TITLE.EXPIRATIONDATE,
    width: 200,
    render: (row: any) => {
      return (
        <div>
          {row.certificate6?.map((element: any, index: number) => {
            if (!element) return <div key={TITLE.EXPIRATIONDATE + index} ></div>;
            if (!element.includes("INVALID"))
              return (
                <div key={TITLE.EXPIRATIONDATE + index}>
                  {helpers.formatDateStringJA(element)}
                </div>
              );
            const errorMes = element.split("INVALID");
            if (errorMes[1] === "")
              return (
                <div key={TITLE.EXPIRATIONDATE + index} style={{ color: "#FF0909" }}>
                  {errorMes[0]}
                </div>
              );
            return (
              <Tooltip
                key={TITLE.EXPIRATIONDATE + index}
                color="#FF0909"
                title={errorMes[0]}
              >
                <div style={{ color: "#FF0909" }}>{helpers.formatDateStringJA(errorMes[1])}</div>
              </Tooltip>
            );
          })}
        </div>
      );
    },
  },
];

const COLUMNS_RESULTS = (handler: any, arrBranch: any[], arrRole: any[]) => {
  return [
    {
      title: TITLE.NAME,
      key: TITLE.NAME,
      dataIndex: "fullName",
      width: 150,
      render: (value: any) => value || "-"
    },
    {
      title: TITLE.CCUSTECHNICIANID,
      key: TITLE.CCUSTECHNICIANID,
      dataIndex: "ccusId",
      width: 170,
      render: (value: any) => {
        if (!value) return "-";
        if (!value.includes("INVALID"))
          return <div>{helpers.convertToCCUSFormat(value)}</div>;
      },
    },
    {
      title: TITLE.EXPIRATIONDATE,
      key: TITLE.EXPIRATIONDATE,
      dataIndex: "ccusExpiredDate",
      width: 150,
      render: (value: any) => {
        if (!value) return "-";
        if (!value.includes("INVALID"))
          return <div>{helpers.formatDateStringJA(value)}</div>;
      },
    },
    {
      title: TITLE.DEPARTMENT_TITLE,
      key: TITLE.DEPARTMENT_TITLE,
      dataIndex: "group",
      editable: true,
      width: 150,
      render: (value: any) => value?.name || "-",

    },
    {
      title: TITLE.POSITION,
      key: TITLE.POSITION,
      dataIndex: "role",
      editable: true,
      width: 150,
      render: (value: any) => value?.name || "-",
    },
    {
      title: TITLE.ID,
      key: TITLE.ID,
      dataIndex: "username",
      width: 180,
      render: (value: any) => value || "-"
    },
    {
      title: TITLE.EMAILADDRESS,
      key: TITLE.EMAILADDRESS,
      dataIndex: "emailAddress",
      width: 250,
      render: (value: any) => value || "-"
    },
    {
      title: TITLE.PHONENUMBER,
      key: TITLE.PHONENUMBER,
      dataIndex: "phoneNumber",
      width: 200,
      render: (value: any) => value || "-"
    },
    {
      title: TITLE.ADDRESS,
      key: TITLE.ADDRESS,
      dataIndex: "address",
      width: 150,
      render: (value: any) => value || "-"
    },
    {
      title: TITLE.QUALIFICATIONREGISTRATION,
      key: TITLE.QUALIFICATIONREGISTRATION,
      dataIndex: "certificate",
      width: 150,
      render: (value: string, row: any) => {
        if (row?.certificates?.length !== 0) {
          return (
            <div>
              {row?.certificates?.map((element: any, index: number) => {
                if (element || element !== "")
                  return <div key={index}>{element?.name}</div>;
                return <div key={index}></div>;
              })}
            </div>
          );
        }

        return "-";
      },
    },
    {
      title: TITLE.REMARKS,
      key: TITLE.REMARKS,
      dataIndex: "errorMessage",
      width: 450,
      render: (value: any) => {
        if (value && value !== "Invalid certificate type") {
          const errorArr = value.split("\n")
          return <div>
            {
              errorArr.map((element: string) => {
                return <div style={{ color: "#FF0909" }}>{element}</div>
              })
            }
          </div>
        }

        return "-";
      },
    },
  ];
};

export { COLUMNS, COLUMNS_RESULTS };
