import { createSlice } from '@reduxjs/toolkit';
import { CM005SliceType } from '../../entity/Entity';

export const initialState: CM005SliceType = {
    isRefresh: false,
    evidenceLink: '',
    moneySummaryPlanCostResponse: null,
    moneyConstructionWorkAmountCompletedResult: null,
    evidenceName: '',
};

export const CM005Slice = createSlice({
    name: 'cm005',
    initialState,
    reducers: {
        resetCM005: () => initialState,
        refreshCM005: (state) => {
            state.isRefresh = !state.isRefresh;
        },
        setEvidenceLink: (state, action) => {
            state.evidenceLink = action.payload;
        },
        setMoneySummaryPlanCostResponse: (state, action) => {
            state.moneySummaryPlanCostResponse = action.payload;
        },
        setMoneyConstructionWorkAmountCompletedResult: (state, action) => {
            state.moneyConstructionWorkAmountCompletedResult = action.payload;
        },
        setEvidenceName: (state, action) => {
            state.evidenceName = action.payload;
        },
    },
});

export const {
    resetCM005,
    refreshCM005,
    setEvidenceLink,
    setMoneySummaryPlanCostResponse,
    setMoneyConstructionWorkAmountCompletedResult,
    setEvidenceName
} = CM005Slice.actions;

export default CM005Slice.reducer;
