import { createSlice } from "@reduxjs/toolkit";
import { PM024SliceEntity } from "../type/Presenter";

const initialState: PM024SliceEntity = {
  isViewChooseCompany: false,
  chosenCompany: 0,
  refreshCompany: false,
  listCompany: [],
};

export const pm024Slice = createSlice({
  name: "pm024",
  initialState,
  reducers: {
    resetStatePM024: (state) => initialState,
    setViewChooseCompany: (state, action) => {
      state.isViewChooseCompany = action.payload;
      if (!action.payload) {
        state.chosenCompany = 0;
      }
    },
    setChangeChosenCompany: (state, action) => {
      state.chosenCompany = action.payload;
    },
    setChangeRefreshCompany: (state) => {
      state.refreshCompany = !state.refreshCompany;
    },
    setListCompany: (state, action) => {
      state.listCompany = action.payload;
    },
  },
});

export const {
  resetStatePM024,
  setViewChooseCompany,
  setChangeChosenCompany,
  setChangeRefreshCompany,
  setListCompany,
} = pm024Slice.actions;

export default pm024Slice.reducer;
