import { useDispatch } from "react-redux";
import moment from "moment";
import { useState, useRef } from "react";
import { setIsVisibleAM012 } from "../../../AM012/presenter/slice/Slice";
import { setIsVisibleAM022 } from "../../../AM022/presenter/slice/Slice";
import { setIsVisibleAM018 } from "../../../AM018/presenter/slice/Slice";
import {
    setIsVisibleAM016,
    setRequestIdAM016,
    setStatusCodeAM016,
} from "../../../AM016/presenter/slice/Slice";
import {
    setIsVisibleAM020,
    setRequestIdAM020,
    setStatusCodeAM020,
} from "../../../AM020/presenter/slice/Slice";
import { Form } from "antd";
import { AM010UseCase } from "../../usecase/ServiceImpl";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../../common/constants/MESSAGE";
import COMMON from "../../../../../common/constants/COMMON";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import {
    setHaveProject,
    setIsVisibleDetailShift,
    setRefreshAM010,
    setLoading as setLoadingAM010,
} from "../slice/Slice";
import {
    setIsVisibleAM014,
    setRequestIdAM014,
} from "../../../AM014/presenter/slice/Slice";
import STATUS, {
    STATUS_REQUEST,
    TYPE_REQUEST,
} from "../../../../../common/constants/STATUS";
import {
    setIsVisibleAM024,
    setRequestIdAM024,
    setStatusCodeAM024,
} from "../../../AM024/presenter/slice/Slice";
import { setLoading } from "../../../../../common/slice/CommonSlice";

export const ICON_CODE_1: string = "icon1",
    ICON_CODE_2 = "icon2",
    ICON_CODE_3 = "icon3",
    DROPDOWN_KEY_ONE = "KEY1",
    DROPDOWN_KEY_TWO = "KEY2",
    DROPDOWN_KEY_THREE = "KEY3",
    ATTENDANCE_STATUS_PURPLE = "PURPLE",
    ATTENDANCE_STATUS_ORANGE = "ORANGE",
    ATTENDANCE_STATUS_GREEN = "GREEN",
    ATTENDANCE_STATUS_RED = "RED";

export const MAX_LENGTH_TEXT = 30;

const AM010Handler = (am010Service: AM010UseCase) => {
    // Declare State
    const [form] = Form.useForm();
    const [month, setMonthValue] = useState<moment.Moment>(moment());
    const [loadingOption, setLoadingOption] = useState(false);

    const [pageOption, setPageOption] = useState(COMMON.DEFAULT_PAGE);
    const [sizeOption, setSizeOption] = useState(COMMON.DEFAULT_SIZE);
    const [keywordOption, setKeywordOption] = useState<any>(undefined);

    const [pageOptionConstruction, setPageOptionConstruction] = useState(
        COMMON.DEFAULT_PAGE
    );
    const [sizeOptionConstruction, setSizeOptionConstruction] = useState(
        COMMON.DEFAULT_SIZE
    );
    const [keywordOptionConstruction, setKeywordOptionConstruction] =
        useState<any>(undefined);

    const [projects, setProjects] = useState<any[]>([]);
    const [chosenProject, setChosenProject] = useState<boolean>(false);
    const [currentProject, setCurrentProject] = useState<any>();
    const [constructions, setConstructions] = useState<any[]>([]);
    const [projectStatus, setProjectStatus] = useState<any>();

    const [buttonType, setButtonType] = useState<boolean>(false);
    const [latLng, setLatLng] = useState<any>({
        latitude: 0,
        longitude: 0,
    });
    const [summaryRequest, setSummaryRequest] = useState({});
    const [requestAttendance, setRequestAttendance] = useState<any[]>([]);
    const [requestLateEarly, setRequestLateEarly] = useState<any[]>([]);
    const [requestLeave, setRequestLeave] = useState<any[]>([]);
    const [requestInformation, setRequestInformation] = useState<any[]>([]);
    const [paramDetail, setParamDetail] = useState<any>({});
    const [dataDetailShift, setDataDetailShift] = useState<any[]>([]);
    const [projectPagination, setProjectPagination] = useState<any>();
    const [projectIdChoosed, setProjectIdChoosed] = useState<number>(0);
    const [pageOptionConstructionChoosedProject, setPageOptionConstructionChoosedProject] = useState(
        COMMON.DEFAULT_PAGE
    );
    const [sizeOptionConstructionChoosedProject, setSizeOptionConstructionChoosedProject] = useState(
        COMMON.DEFAULT_SIZE
    );
    const dispatch = useDispatch();

    // Function Handle Logic

    const setMonthChoose = (value: moment.Moment) => {
        setMonthValue(value);
    };

    const addMonth = () => {
        setMonthValue(moment(month).add(1, "M"));
    };

    const subtractMonth = () => {
        setMonthValue(moment(month).subtract(1, "M"));
    };

    const chooseDropdown = (value: any) => {
        switch (value.key) {
            case DROPDOWN_KEY_ONE:
                dispatch(setIsVisibleAM012(true));
                break;
            case DROPDOWN_KEY_TWO:
                dispatch(setIsVisibleAM022(true));
                break;
            case DROPDOWN_KEY_THREE:
                dispatch(setIsVisibleAM018(true));
                break;
        }
    };

    const clickAttendance = (dataRequest: any) => {
        switch (dataRequest.type) {
            case TYPE_REQUEST.ATTENDANCE.type:
                if (
                    dataRequest.status === STATUS_REQUEST.REQUEST_APPROVED ||
                    dataRequest.status === STATUS_REQUEST.REQUEST_WAITING_FOR_APPROVAL
                ) {
                    dispatch(setRequestIdAM016(dataRequest.id));
                    dispatch(setStatusCodeAM016(dataRequest.status));
                    dispatch(setIsVisibleAM016(true));
                    break;
                }
                if (dataRequest.status === STATUS_REQUEST.REQUEST_REJECT) {
                    dispatch(setRequestIdAM014(dataRequest.id));
                    dispatch(setIsVisibleAM014(true));
                    break;
                }
                break;

            case TYPE_REQUEST.LATE_EARLY.type:
                dispatch(setRequestIdAM020(dataRequest.id));
                dispatch(setStatusCodeAM020(dataRequest.status));
                dispatch(setIsVisibleAM020(true));
                break;
            case TYPE_REQUEST.LEAVE.type:
                dispatch(setRequestIdAM024(dataRequest.id));
                dispatch(setStatusCodeAM024(dataRequest.status));
                dispatch(setIsVisibleAM024(true));
                break;
        }
    };

    const convertSummaryRequest = (data: any) => {
        let objAttendance: any = COMMON.REQUEST_CONSTANT.ATTENDANCE;
        let objLeave: any = COMMON.REQUEST_CONSTANT.LEAVE;
        let objLateEarly: any = COMMON.REQUEST_CONSTANT.LATE_EARLY;

        // ATTENDANCE
        data?.attendance?.forEach((value: any) => {
            objAttendance[`${value?.statusCode}`] = value?.count;
        });
        objAttendance.parentValue = data?.attendance?.reduce(
            (acc: number, crr: any) => {
                return acc + crr.count;
            },
            0
        );

        // LEAVE
        data?.leave?.forEach((value: any) => {
            objLeave[`${value?.statusCode}`] = value?.count;
        });
        objLeave.parentValue = data?.leave?.reduce((acc: number, crr: any) => {
            return acc + crr?.count;
        }, 0);

        // LATE EARLY
        data?.lateEarly?.forEach((value: any) => {
            objLateEarly[`${value?.statusCode}`] = value?.count;
        });
        objLateEarly.parentValue = data?.lateEarly?.reduce(
            (acc: number, crr: any) => {
                return acc + crr?.count;
            },
            0
        );

        return { objAttendance, objLeave, objLateEarly };
    };

    const getSummaryRequest = async (params: { month: string }) => {
        dispatch(setLoading(true));
        try {
            const paramsGet: any = { ...params };
            const response = await am010Service.getSummaryRequest(paramsGet);
            setSummaryRequest(convertSummaryRequest(response.results));
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        } finally {
            dispatch(setLoading(false));
        }
    };

    const getHaveProject = async () => {
        try {
            const paramsGet: any = {
                page: COMMON.DEFAULT_PAGE,
                size: COMMON.DEFAULT_SIZE,
                sortBy: COMMON.PARAM_LIST.CREATED_AT,
                sortType: COMMON.PARAM_LIST.DESC,
            };
            const response = await am010Service.getProjects(paramsGet);

            if (response?.results?.length > 0) {
                dispatch(setHaveProject(true));
            } else {
                dispatch(setHaveProject(false));
            }
            dispatch(setIsVisibleAM014(false));
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        }
    };

    const handleGetProjectFirstOpenModal = async (params: any): Promise<any> => {
        try {
            const data = await getListProject(params);
            setProjects([...data]);
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        }
    };

    const handleGetConstructionFirstOpenModal = async (
        params: any
    ): Promise<any> => {
        try {
            const data = await getListConstruction(params);
            setConstructions([...data]);
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        }
    };

    const getListProject = async (params: {
        page: number;
        size: number;
        sortBy?: string;
        sortType?: string;
        keyword?: string;
    }): Promise<any> => {
        const paramsGet: any = { ...params };
        const response = await am010Service.getProjects(paramsGet);
        setProjectPagination(response.pagination);
        if (response?.results) {
            return response.results;
        }
    };

    const getListConstruction = async (params: {
        page: number;
        size: number;
        keyword?: string;
        projectId?: number;

    }): Promise<any> => {
        try {
            const paramsGet = { ...params, onlyInvolved: true };
            const res = await am010Service.getAttendanceUserConstruction(paramsGet);
            if (res?.results) {
                const data = formatConstructions(res.results);
                return data;
            }
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        }
    };

    const getProject = async (params: any, projectId: number): Promise<any> => {
        const res = await handleFetchApiGetAllProject(params, projectId);
        if (res) setProjects([...res]);
    };

    const handleFetchApiGetAllProject = async (
        params: any,
        projectId: number
    ): Promise<any> => {
        try {
            const res = await am010Service.getProjects(params);
            if (res?.results?.length > 0) {
                if (handleCheckExistProject(res.results, projectId)) return res.results;
                else {
                    const param: any = {
                        page: params.page + 1,
                        size: params.size,
                        keyword: params.keyword,
                    };
                    setPageOption(param.page);
                    return res.results.concat(
                        await handleFetchApiGetAllProject(param, projectId)
                    );
                }
            } else return [];
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        }
    };

    const handleFetchApiGetAllConstruction = async (
        params: any,
        constructionId: number
    ): Promise<any> => {
        try {
            const paramGet = { ...params, onlyInvolved: true }
            const res = await am010Service.getAttendanceUserConstruction(paramGet);
            if (res?.results.length > 0) {
                if (handleCheckExistConstruction(res.results, constructionId))
                    return res.results;
                else {
                    const param: any = {
                        page: params.page + 1,
                        size: params.size,
                        keyword: params.keyword,

                    };
                    setPageOptionConstruction(param.page);
                    return res.results.concat(
                        await handleFetchApiGetAllConstruction(param, constructionId)
                    );
                }
            } else return [];
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        }
    };

    const handleCheckExistProject = (data: any, projectId: number) => {
        const res = data.find((item: any) => item?.id === projectId);
        if (res) {
            setCurrentProject(res);
            return true;
        } else return false;
    };

    const handleCheckExistConstruction = (data: any, constructionId: number) => {
        const res = data.find((item: any) => item?.id === constructionId);
        if (res) return true;
        else return false;
    };

    let timeOut: any = useRef();
    const handleSearchOption = (searchValue: string) => {
        if (timeOut.current) clearTimeout(timeOut.current);
        timeOut.current = setTimeout(async () => {
            try {
                setLoadingOption(true);
                setKeywordOption(searchValue);
                setPageOption(COMMON.DEFAULT_PAGE);
                setSizeOption(COMMON.DEFAULT_SIZE);
                const data = await getListProject({
                    page: COMMON.DEFAULT_PAGE,
                    size: COMMON.DEFAULT_SIZE,
                    keyword: searchValue,
                });
                if (data) setProjects([...data]);
            } catch (error: any) {
                ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            } finally {
                setLoadingOption(false);
            }
        }, 1000);
    };

    const handleScrollProject = async (e: any): Promise<any> => {
        try {
            if (projectPagination?.numPages === projectPagination?.page) return;
            setLoadingOption(true);
            const target = e.target;
            if (target.scrollTop + target.offsetHeight + 1 >= target.scrollHeight) {
                setPageOption(pageOption + 1);
                const data = await getListProject({
                    page: pageOption + 1,
                    size: sizeOption,
                    keyword: keywordOption ? keywordOption : undefined,
                });
                if (data) setProjects([...projects, ...data]);
            }
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        } finally {
            setLoadingOption(false);
        }
    };

    const formatConstructions = (data: any) => {
        const arr: any = [];
        data.map((item: any) => {
            arr.push({
                id: item.id,
                name: item.description,
                projectId: item.projectId,
                projectName: item.projectName,
            });
        });
        return arr;
    };

    const handleSearchOptionConstruction = (searchValue: string) => {
        if (!chosenProject) {
            if (timeOut.current) clearTimeout(timeOut.current);
            timeOut.current = setTimeout(async () => {
                if (!chosenProject) {
                    try {
                        setLoadingOption(true);
                        setKeywordOptionConstruction(searchValue);
                        setPageOptionConstruction(COMMON.DEFAULT_PAGE);
                        setSizeOptionConstruction(COMMON.DEFAULT_SIZE);
                        const data = await getListConstruction({
                            page: COMMON.DEFAULT_PAGE,
                            size: COMMON.DEFAULT_SIZE,
                            keyword: searchValue,

                        });
                        if (data) setConstructions([...data]);
                    } catch (error: any) {
                        ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
                    } finally {
                        setLoadingOption(false);
                    }
                }
            }, 1000);
        } else {
            if (timeOut.current) clearTimeout(timeOut.current);
            timeOut.current = setTimeout(async () => {
                if (chosenProject) {
                    try {
                        setLoadingOption(true);
                        setKeywordOptionConstruction(searchValue);
                        setPageOptionConstructionChoosedProject(COMMON.DEFAULT_PAGE);
                        setSizeOptionConstructionChoosedProject(COMMON.DEFAULT_SIZE);
                        const data = await getListConstruction({
                            page: COMMON.DEFAULT_PAGE,
                            size: COMMON.DEFAULT_SIZE,
                            keyword: searchValue,
                            projectId: projectIdChoosed
                        });
                        if (data) setConstructions([...data]);
                    } catch (error: any) {
                        ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
                    } finally {
                        setLoadingOption(false);
                    }
                }
            }, 1000);
        }
    };

    const handleScrollConstruction = async (e: any): Promise<any> => {
        if (!chosenProject) {
            try {
                setLoadingOption(true);
                const target = e.target;
                if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
                    setPageOptionConstruction(pageOptionConstruction + 1);
                    const data = await getListConstruction({
                        page: pageOptionConstruction + 1,
                        size: sizeOptionConstruction,
                        keyword: keywordOptionConstruction
                            ? keywordOptionConstruction
                            : undefined,

                    });
                    if (data) setConstructions([...constructions, ...data]);
                }
            } catch (error: any) {
                ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            } finally {
                setLoadingOption(false);
            }
        } else {
            try {
                setLoadingOption(true);
                const target = e.target;
                if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
                    setPageOptionConstructionChoosedProject(pageOptionConstructionChoosedProject + 1);
                    const data = await getListConstruction({
                        page: pageOptionConstructionChoosedProject + 1,
                        size: sizeOptionConstruction,
                        keyword: keywordOptionConstruction
                            ? keywordOptionConstruction
                            : undefined,
                        projectId: projectIdChoosed

                    });
                    if (data) setConstructions([...constructions, ...data]);
                }
            } catch (error: any) {
                ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            } finally {
                setLoadingOption(false);
            }
        }
    };

    const handleFindConstructions = async (projectId: number): Promise<any> => {
        try {
            setChosenProject(true);
            setProjectIdChoosed(projectId)
            if (projects.length !== 0) {

                let param = {
                    page: COMMON.DEFAULT_PAGE,
                    size: COMMON.DEFAULT_SIZE,
                    projectId
                }
                const response = await getListConstruction(param);
                setConstructions(response ?? []);
            }
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        }
    };

    const handleFindProjects = async (constructionId: number) => {
        if (!chosenProject && constructions.length > 0) {
            const data = await handleFetchApiGetAllConstruction(
                {
                    page: COMMON.DEFAULT_PAGE,
                    size: COMMON.DEFAULT_SIZE,
                    keyword: undefined,

                },
                constructionId
            );
            if (data) {
                const newData = formatConstructions(data);
                setConstructions([...newData]);
            }
            const goal: any = constructions.find(
                (element: any) => element.id === constructionId
            );
            if (goal?.projectId) {
                const data = await handleFetchApiGetAllProject(
                    {
                        page: COMMON.DEFAULT_PAGE,
                        size: COMMON.DEFAULT_SIZE,
                        keyword: undefined,
                    },
                    goal.projectId
                );
                if (data) setProjects([...data]);
                form.setFieldValue("projectName", goal.projectId);
            }
        }
    };

    const resetProjectWhenSelectOrBlur = async (
        projectId: number
    ): Promise<any> => {
        try {
            setKeywordOption(undefined);
            const data = await handleFetchApiGetAllProject(
                {
                    page: COMMON.DEFAULT_PAGE,
                    size: COMMON.DEFAULT_SIZE,
                },
                projectId
            );
            if (data) setProjects([...data]);
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        }
    };

    const resetConstructionWhenSelectOrBlur = async (
        constructionId: any
    ): Promise<any> => {
        if (chosenProject)
            try {
                setLoadingOption(true);
                setKeywordOptionConstruction(undefined);
                setPageOptionConstructionChoosedProject(COMMON.DEFAULT_PAGE);
                setSizeOptionConstructionChoosedProject(COMMON.DEFAULT_SIZE);
                const data = await getListConstruction({
                    page: COMMON.DEFAULT_PAGE,
                    size: COMMON.DEFAULT_SIZE,
                    keyword: undefined,
                    projectId: projectIdChoosed
                });
                if (data) setConstructions(data);
            } catch (error: any) {
                ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            } finally {
                setLoadingOption(false);
            }

        else {
            try {
                setKeywordOptionConstruction(undefined);
                const data = await handleFetchApiGetAllConstruction(
                    {
                        page: COMMON.DEFAULT_PAGE,
                        size: COMMON.DEFAULT_SIZE,
                        keyword: undefined,
                    },
                    constructionId
                );
                if (data) setConstructions([...data]);
            } catch (error: any) {
                ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            }
        }
    };

    const getProjectStatus = async (): Promise<any> => {
        try {
            const params = {
                page: COMMON.DEFAULT_PAGE,
                size: COMMON.DEFAULT_SIZE,
            };
            const res = await am010Service.getProjectStatus(params);
            if (res?.results) {
                res.results.forEach((item: any) => {
                    if (item.name === STATUS.UNDER_CONSTRUCTION)
                        setProjectStatus(item.id);
                });
            }
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        }
    };

    const handleButtonType = (value: boolean) => {
        setButtonType(value);
    };

    const handleSetLatLng = (lat: number, lng: number) => {
        setLatLng({
            latitude: lat,
            longitude: lng,
        });
    };

    const handleCheckIn = async (values: any) => {
        try {
            const response = await am010Service.checkIn({
                constructionId: Number(values.constructionName),
                lat: latLng.latitude,
                long: latLng.longitude,
            });
            SuccessNotification(
                response?.data?.message ?? NOTIFICATION_TITLE.SUCCESS
            );
            dispatch(setRefreshAM010());
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        }
    };

    const handleCheckOut = async (values: any) => {
        try {
            const response = await am010Service.checkOut({
                constructionId: Number(values.constructionName),
                lat: latLng.latitude,
                long: latLng.longitude,
            });
            SuccessNotification(
                response?.data?.message ?? NOTIFICATION_TITLE.SUCCESS
            );
            dispatch(setRefreshAM010());
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        }
    };

    const handleCheckInNoProject = async () => {
        try {
            const response = await am010Service.checkIn({
                lat: latLng.latitude,
                long: latLng.longitude,
            });
            SuccessNotification(
                response?.data?.message ?? NOTIFICATION_TITLE.SUCCESS
            );
            dispatch(setRefreshAM010());
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        }
    };

    const handleCheckOutNoProject = async () => {
        try {
            const response = await am010Service.checkOut({
                lat: latLng.latitude,
                long: latLng.longitude,
            });
            SuccessNotification(
                response?.data?.message ?? NOTIFICATION_TITLE.SUCCESS
            );
            dispatch(setRefreshAM010());
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        }
    };

    //TODO: Func Get element unit calendar

    const getListData = (value: any) => {
        let listData: any = [];
        const requestDay = moment(value).format(COMMON.FORMAT_DATE);
        if (requestInformation && requestInformation?.length > 0) {
            requestInformation.forEach((request: any) => {
                if (moment(request?.date).format(COMMON.FORMAT_DATE) === requestDay) {
                    listData.push({
                        type: "checkinCheckout",
                        checkin: request?.checkin,
                        checkout: request?.checkout,
                        constructionName: request?.constructionName,
                        projectName: request?.projectName,
                        isMultipleCheckInOut: request?.isMultipleCheckInOut,
                        date: request?.date,
                        constructionId: request?.constructionId,
                    });
                }
            });
        }

        if (requestAttendance && requestAttendance?.length > 0) {
            requestAttendance.forEach((request: any) => {
                if (
                    moment(request?.requestDate).format(COMMON.FORMAT_DATE) === requestDay
                ) {
                    listData.push({
                        id: request?.id,
                        type: "attendance",
                        status: request?.status?.code,
                        constructionName: request?.construction?.name,
                        projectName: request?.project?.name,
                    });
                }
            });
        }

        if (requestLateEarly && requestLateEarly?.length > 0) {
            requestLateEarly.forEach((request: any) => {
                if (
                    moment(request?.requestDate).format(COMMON.FORMAT_DATE) === requestDay
                ) {
                    listData.push({
                        id: request?.id,
                        type: "lateEarly",
                        status: request?.status?.code,
                        constructionName: request?.construction?.name,
                        projectName: request?.project?.name,
                    });
                }
            });
        }

        if (requestLeave && requestLeave?.length > 0) {
            requestLeave.forEach((request: any) => {
                if (
                    requestDay >= moment(request?.startDate).format(COMMON.FORMAT_DATE) &&
                    requestDay <= moment(request?.endDate).format(COMMON.FORMAT_DATE)
                ) {
                    listData.push({
                        id: request?.id,
                        type: "leave",
                        status: request?.status?.code,
                        constructionName: request?.construction?.name,
                        projectName: request?.project?.name,
                    });
                }
            });
        }
        return listData;
    };

    const getRequestAttendanceMonthly = async (params: {
        month: string;
        timeOffset: number;
    }) => {
        dispatch(setLoading(true));
        try {
            const paramsGet: any = { ...params };
            const response = await am010Service.getRequestAttendanceMonthly(
                paramsGet
            );
            setRequestAttendance(response.results);
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        } finally {
            dispatch(setLoading(false));
        }
    };

    const getRequestLateEarlyMonthly = async (params: {
        month: string;
        timeOffset: number;
    }) => {
        dispatch(setLoading(true));
        try {
            const paramsGet: any = { ...params };
            const response = await am010Service.getRequestLateEarlyMonthly(paramsGet);
            setRequestLateEarly(response.results);
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        } finally {
            dispatch(setLoading(false));
        }
    };

    const getRequestLeaveMonthly = async (params: {
        month: string;
        timeOffset: number;
    }) => {
        dispatch(setLoading(true));
        try {
            const paramsGet: any = { ...params };
            const response = await am010Service.getRequestLeaveMonthly(paramsGet);
            setRequestLeave(response.results);
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        } finally {
            dispatch(setLoading(false));
        }
    };

    const getRequestInformation = async (params: {
        from: string;
        to: string;
        timeOffset: number;
    }) => {
        dispatch(setLoading(true));
        try {
            const paramsGet: any = { ...params };
            const response = await am010Service.getRequestInformation(paramsGet);
            setRequestInformation(response.results);
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        } finally {
            dispatch(setLoading(false));
        }
    };

    const clickViewDetailShift = (dataRequest: any) => {
        dispatch(setIsVisibleDetailShift(true));
        setParamDetail(dataRequest);
    };

    const onCancel = () => {
        dispatch(setIsVisibleDetailShift(false));
    };

    const getAttendanceReport = async (params: {
        date: string;
        constructionId: number;
        timeOffset: number;
    }) => {
        dispatch(setLoadingAM010(true));
        try {
            const paramsGet: any = { ...params };
            const response = await am010Service.getAttendanceReport(paramsGet);
            setDataDetailShift(response.results);
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        } finally {
            dispatch(setLoadingAM010(false));
        }
    };

    const showTooltipElement = (value: string, maxLength: number) => {
        if (value.length <= maxLength) return false;
        return true;
    };

    const formatLongString = (value: string, maxLength: number) => {
        if (value && value.length <= maxLength) return value;
        return `${value ? value.substring(0, maxLength) : ""}...`;
    };

    return {
        summaryRequest,
        month,
        form,
        loadingOption,
        projects,
        chosenProject,
        constructions,
        buttonType,
        paramDetail,
        dataDetailShift,
        projectStatus,

        getListData,
        setMonthChoose,
        addMonth,
        subtractMonth,
        chooseDropdown,
        clickAttendance,
        getListProject,
        handleSearchOption,
        handleScrollProject,
        handleFindConstructions,
        handleButtonType,
        handleCheckIn,
        handleCheckOut,
        handleSetLatLng,
        getHaveProject,
        handleCheckInNoProject,
        handleCheckOutNoProject,
        getSummaryRequest,
        getRequestLeaveMonthly,
        getRequestLateEarlyMonthly,
        getRequestAttendanceMonthly,
        getRequestInformation,
        clickViewDetailShift,
        onCancel,
        getAttendanceReport,
        handleSearchOptionConstruction,
        handleScrollConstruction,
        handleFindProjects,
        getProjectStatus,
        getProject,
        resetProjectWhenSelectOrBlur,
        resetConstructionWhenSelectOrBlur,
        handleGetProjectFirstOpenModal,
        handleGetConstructionFirstOpenModal,
        showTooltipElement,
        formatLongString,
    };
};

export default AM010Handler;
