import { AxiosResponse } from "axios";
import axiosConfig from "../../../../../config/axiosConfig";
import { ReSummaryCompanyProjectOutsourceCostRetrievePayload } from "../../entity/Entity";

const CM0025Api = () => {
  const getReSummarySubmittedOutsourceCostCompany = async (params: {
    documentId: number;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/re-summary/submitted/outsource-cost/company";
    return await axiosConfig.get(url, { params });
  };
  const getReSummarySubmittedOutsourceCostCompanyProject = async (params: {
    documentId: number;
    companyId?: number;
    colabRefId?: number;

    page?: number;
    size?: number;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/re-summary/submitted/outsource-cost/company/project";
    return await axiosConfig.get(url, { params });
  };
  const getReSummarySubmittedOutsourceCostCompanyProjectConstruction =
    async (params: {
      documentId: number;
      companyId?: number;
      colabRefId?: number;
      projectId: number;
    }): Promise<AxiosResponse<any, any>> => {
      const url =
        "/re-summary/submitted/outsource-cost/company/project/construction";
      return await axiosConfig.get(url, { params });
    };
  const putReSummaryCompanyProjectOutsourceCostRetrieve = async (
    data: ReSummaryCompanyProjectOutsourceCostRetrievePayload
  ): Promise<AxiosResponse<any, any>> => {
    const url = "/re-summary/company/project/outsource-cost/retrieve";
    return await axiosConfig.put(url, data);
  };

  // Get summary outsource company approval process (CM0024)
  const getWorkflowSummaryOutsourceCompanyApprovalProcess = async (params: {
    documentId: number;
  }): Promise<any> => {
    const url = "/workflow/summary/outsource/company/approval-process";
    return await axiosConfig.get(url, { params });
  };
  return {
    putReSummaryCompanyProjectOutsourceCostRetrieve,
    getReSummarySubmittedOutsourceCostCompanyProjectConstruction,
    getReSummarySubmittedOutsourceCostCompany,
    getReSummarySubmittedOutsourceCostCompanyProject,
    getWorkflowSummaryOutsourceCompanyApprovalProcess,
  };
};

export default CM0025Api;
