import React, { useEffect } from 'react';
import { HanlderAttendanceImpl } from '../../../handler/HandlerImpl';
import { Table, TableColumnsType } from 'antd';
import { DateColumn } from '../../../../entity/Entity';
import { convertToDataSource } from '../../../../../AM006/helper';

const getColumns = (tableColumns: DateColumn[]) => {
    const columns: TableColumnsType<any> = [
        {
            title: '',
            key: 'attendanceName',
            width: '21.5%',
            render: (record) => {
                return record.name;
            },
            onCell: () => {
                return {
                    className: 'border-left-table',
                };
            },
        },
        {
            title: '',
            key: 'prev',
            width: 70,
        },
    ];
    for (let i = 0; i < tableColumns.length; i++) {
        columns.push({
            title: '',
            key: i,
            width: `${72 / tableColumns.length}%`,
            render: (record) => record[`${tableColumns[i].value}`],
        });
    }
    columns.push({
        title: '',
        key: 'next',
        width: 29,
    });
    columns.push({
        title: '',
        key: 'ghost',
        width: '6.5%',
    });
    return columns;
};

interface Props {
    handler: HanlderAttendanceImpl;
    constructionId?: number;
    userId: number;
    columnsTable: DateColumn[];
    attendance: any;
}

const AttendanceUI = ({ handler, constructionId, userId, attendance, columnsTable }: Props) => {
    useEffect(() => {
        handler.setAttendance(attendance);
    }, [JSON.stringify(attendance)]);

    return (
        <Table
            bordered={true}
            className="am-main-table-construction attendance-table"
            columns={getColumns(columnsTable)}
            dataSource={convertToDataSource(
                userId,
                handler.attendance?.timesheet ?? [],
                columnsTable,
                handler.attendance?.laborCost ?? 0,
                handler.openStreetMap,
                constructionId,
            )}
            pagination={false}
        />
    );
};

export default AttendanceUI;
