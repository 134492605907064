import { Form, InputNumber } from 'antd';
import COMMON from '../../../../../common/constants/COMMON';
import MESSAGE from '../../../../../common/constants/MESSAGE';
import { Rule } from 'antd/lib/form';

type props = {
    name: string | string[];
    min?: number;
    max?: number;
    isRequired?: boolean;
    rules?: Rule[];
    hidden?: boolean;
};

export const InputNumberComponent = ({
    name,
    min,
    max,
    isRequired = false,
    rules,
    hidden,
}: props) => {
    return (
        <div className="cr001">
            <div className="cr001-cr-item-child color-gray">
                <Form.Item
                    hidden={hidden}
                    name={name}
                    style={{ margin: 0, padding: 0 }}
                    rules={
                        rules ?? [
                            {
                                required: isRequired,
                                message: MESSAGE.MESSAGE_REQUIRED,
                            },
                        ]
                    }
                >
                    <InputNumber controls={false}
                        style={{ width: 375 }}
                        maxLength={
                            ((max ?? COMMON.MAX_NUMBER_VALUE) + '').replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ',',
                            ).length
                        }
                        max={max ?? COMMON.MAX_NUMBER_VALUE}
                        min={min ?? 0}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        placeholder=""
                        parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                    />
                </Form.Item>
            </div>
        </div>
    );
};
