import PM008_1Api from "./infrastructure/api/Api";
import PM008_1Handler from "./presenter/handler/Handler";
import PM008_1UI from "./presenter/ui/UI";
import PM008_1Service from "./usecase/Service";
interface props {
  orderAddressId?: number;
  handleConfirm?: () => void;
  handleCancel?: () => void;
}
const PM008_1Component = (props: props) => {
  const pm008_1Api = PM008_1Api();
  const pm008_1Service = PM008_1Service(pm008_1Api);
  const pm008_1Handler = PM008_1Handler(pm008_1Service);
  return <PM008_1UI handler={pm008_1Handler} {...props} />;
};

export default PM008_1Component;
