import { CM0030SliceType } from './../type/Presenter';
import { createSlice } from '@reduxjs/toolkit';

const initialState: CM0030SliceType = {
    isRefresh: false,
};

export const cm0030Slice = createSlice({
    name: 'cm0030',
    initialState,
    reducers: {
        resetCM0030: () => initialState,
    },
});

export const { resetCM0030 } = cm0030Slice.actions;

export default cm0030Slice.reducer;
