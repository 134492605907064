import { useState } from 'react';
// Redux
import { useDispatch } from 'react-redux';

// Services

import { IC3UseCase } from '../../usecase/ServiceImpl';
import { useSearchParams } from 'react-router-dom';
import { setLoading } from '../../../../../common/slice/CommonSlice';
import { showIC5Modal } from '../../../../../common/components/modal-IC5/IC5ModalSlice';

export const DEFAULT_TAB_VALUE = '1';
export const DEFAULT_TAB_KEY = 'tab';

const IC3Handler = (service: IC3UseCase) => {
    const dispatch = useDispatch();

    const [searchParams, setSearchParams] = useSearchParams();
    const [dataOrderCompany, setDataOrderCompany] = useState();

    const getOrderCompany = async () => {
        try {
            dispatch(setLoading(true));
            const response: any = await service.getOrderCompany();
            setDataOrderCompany(response.results);
        } finally {
            dispatch(setLoading(false));
        }
    };
    const openModalIC5 = () => {
        dispatch(showIC5Modal())
    }
    return {
        dataOrderCompany,
        getOrderCompany,
        openModalIC5
    };
};
export default IC3Handler;
