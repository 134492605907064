import { ChangeForgotPasswordApi } from "./ServiceImpl";

const ChangeForgotPasswordService = (aS003Api: ChangeForgotPasswordApi) => {
  const changePassword = async (bodyData: any): Promise<any> => {
    try {
      const response = await aS003Api.changePassword(bodyData);
      // save local storage hear
      localStorage.removeItem("timerOTP");
      localStorage.removeItem("forgotToken");
      return response;
      // save local storage hear
    } catch (error) {
      throw error;
    }
  };

  const sendOTP = async (): Promise<any> => {
    try {
      return await aS003Api.sendOTP();
    } catch (error) {
      throw error;
    }
  };
  return { changePassword, sendOTP };
};

export default ChangeForgotPasswordService;
