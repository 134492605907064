import CM0027_2Api from './infrastructure/api/Api';
import CM0027_2Handler from './presenter/handler/Handler';
import CM0027_2UI from './presenter/ui/UI';
import CM0027_2Service from './usecase/Service';

const CM0027_2Component = () => {
    const cm0027_2Api = CM0027_2Api();
    const cm0027_2Service = CM0027_2Service(cm0027_2Api);
    const cm0027_2Handler = CM0027_2Handler(cm0027_2Service);
    return <CM0027_2UI handler={cm0027_2Handler} />;
};

export default CM0027_2Component;
