import { DefaultAvatarComponent } from '../../../../../../../common/components/default-avatar';
import { SYSTEM_ROLES } from '../../../../../../../common/constants/AUTHORIZATION';
import { AVATAR_ADMIN } from '../../../../../../../common/constants/COMMON';
import { Project } from '../../../handler/HandlerImpl';
import { Avatar } from 'antd';
type Props = {
    onClick: (value: any) => void;
    project: Project;
    isChosen: boolean;
    isCollapsed: boolean;
};

const ProjectItem = ({ onClick, project, isChosen, isCollapsed }: Props) => {
    return (
        <>
            {isCollapsed ? (
                <main
                    className={`company-item-container company-item-container-collapse ${isChosen ? 'chosen' : ''
                        }`}
                    onClick={onClick}
                >
                    <div className="project-item-avt" style={{ marginRight: 16 }}>
                        <DefaultAvatarComponent
                            isAdmin={[SYSTEM_ROLES.ADMIN, SYSTEM_ROLES.ADMIN_SYSTEM].includes(project?.projectManager?.roleCode ?? '')}
                            avatar={project?.projectManager?.avatar ?? undefined}
                            value={project?.projectManager?.fullName ?? ''}
                            size={32}
                        />
                    </div>
                </main>
            ) : (
                <main
                    className={`company-item-container ${isChosen ? 'chosen' : ''}`}
                    onClick={onClick}
                >
                    <div className="project-item-avt" style={{ marginRight: 16 }}>
                        <DefaultAvatarComponent
                            isAdmin={[SYSTEM_ROLES.ADMIN, SYSTEM_ROLES.ADMIN_SYSTEM].includes(project?.projectManager?.roleCode ?? '')}
                            avatar={project?.projectManager?.avatar ?? undefined}
                            value={project?.projectManager?.fullName ?? ''}
                            size={32}
                        />                    </div>
                    <p className="company-item-text"> {project?.projectName}</p>
                </main>
            )}
        </>
    );
};

export default ProjectItem;
