// Built-in
import React, { useMemo, useEffect } from 'react';

// 3rd party lib
import { Form, DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/ja_JP';

// Source files
import '../../../../../../assets/styles/CR/CR003.css';

// Image files
import CalendarSvg from '../../../../../../assets/icons/calendar.svg';
import COMMON from '../../../../../constants/COMMON';
import { DatePickerHandlerImpl } from '../../handler/HandlerImpl';

type props = {
    handler: DatePickerHandlerImpl;
    record: any;
    name: string;
    onAttendanceLaborCostChange?: (record: any, property: string, value: any) => Promise<void> | void;

};
const DatePickerUI = ({ record, name, handler, onAttendanceLaborCostChange }: props) => {
    const { setOpen, open } = handler;
    const bodyContent = document.querySelector('#yma-body');
    const elementTable = document.querySelector('.scroll-table');
    const tableScroll = elementTable?.querySelector('.ant-table-body');
    useEffect(() => {
        const funcScroll = () => {
            setOpen(false);
        };
        if (tableScroll) {
            tableScroll.addEventListener('scroll', funcScroll);
        }
        if (bodyContent) {
            bodyContent.addEventListener('scroll', funcScroll);
        }
        return () => {
            tableScroll?.removeEventListener('scroll', funcScroll);
            bodyContent?.removeEventListener('scroll', funcScroll);
        };
    }, []);

    return (
        <Form.Item name={[`record${record.id}`, name]} style={{ padding: 0, margin: 0 }}>
            <DatePicker
                inputReadOnly
                placeholder=""
                className="cr001-choose-date"
                locale={locale}
                format={COMMON.FORMAT_DATE_CI}
                suffixIcon={<img alt="" src={CalendarSvg} className="icon-calender" />}
                style={{ marginTop: 7, marginBottom: 7, height: 38 }}
                open={open}
                onOpenChange={(open) => setOpen(open)}
                onChange={(value) =>
                    onAttendanceLaborCostChange ? onAttendanceLaborCostChange(record, name, value) : undefined
                }
            />
        </Form.Item>
    );
};

export default DatePickerUI;
