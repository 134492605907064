import React from 'react';
import { Button, Pagination, Select } from 'antd';
import '../../../assets/styles/components/pagination.css';
import FirstSvg from '../../../assets/icons/first.svg';
import LastSvg from '../../../assets/icons/last.svg';
import ArrowRight from '../../../assets/icons/arrow-right-2.svg';
import ArrowLeft from '../../../assets/icons/arrow-left-2.svg';
import type { PaginationProps } from 'antd';
import { memo, useMemo } from 'react';

type Props = {
    total: number;
    page: number;
    size: number;
    setPage: (value: number) => void;
    setSize: (value: number) => void;
    disabled?: boolean;
    sizeLabelPage?: string;
    sizeLabelSize?: string;
    rangeLabel?: string;
    paddingProps?: number;
    marginTopProps?: number;
};

const { Option } = Select;

const sizeOptions: number[] = [10, 20, 50, 100];

const PaginationComponentList = ({
    total,
    page,
    size,
    setPage,
    setSize,
    disabled,
    sizeLabelSize,
    sizeLabelPage,
    rangeLabel,
    paddingProps,
    marginTopProps,
}: Props) => {
    const itemRender: PaginationProps['itemRender'] = (_, type, originalElement) => {
        if (type === 'prev') {
            return <img alt="" src={ArrowLeft} />;
        }
        if (type === 'next') {
            return <img alt="" src={ArrowRight} />;
        }
        return originalElement;
    };

    const lastPage = useMemo(() => {
        const result = Math.floor(total / size);
        if (result < 1) return 1;
        if (total % size > 0) return result + 1;
        return result;
    }, [total, size]);

    return (
        <div
            className="pagination-component-list"
            style={{ padding: paddingProps, marginTop: marginTopProps }}
        >
            <div className="pagination-container  w-100-percent">
                {/* total */}
                {total > 0 ? (
                    <div className="pagination-total">
                        {(page - 1) * size +
                            1 +
                            '-' +
                            (page * size > total ? total : page * size) +
                            '/' +
                            total}
                        {rangeLabel ?? '件'}
                    </div>
                ) : (
                    <div></div>
                )}
                {/* page */}
                {total - size > 0 ? (
                    <div style={{ display: 'flex' }}>
                        <Button
                            disabled={page === 1 || (disabled ?? false)}
                            onClick={() => setPage(1)}
                            type="link"
                            style={{
                                border: 'none',
                                backgroundColor: 'transparent',
                                marginTop: 1.2,
                            }}
                            icon={
                                <img
                                    alt=""
                                    src={FirstSvg}
                                    style={{ marginBottom: 4, width: '16px', height: '16px' }}
                                />
                            }
                        ></Button>

                        <Pagination
                            disabled={disabled ?? false}
                            showSizeChanger={false}
                            itemRender={itemRender}
                            onChange={setPage}
                            current={page}
                            pageSize={size}
                            total={total}
                        />

                        <Button
                            disabled={page === lastPage || (disabled ?? false)}
                            onClick={() => setPage(lastPage)}
                            type="link"
                            style={{
                                border: 'none',
                                backgroundColor: 'transparent',
                                marginTop: 1.2,
                            }}
                            icon={
                                <img
                                    alt=""
                                    src={LastSvg}
                                    style={{ marginBottom: 4, width: '16px', height: '16px' }}
                                />
                            }
                        ></Button>
                    </div>
                ) : (
                    <></>
                )}
                {/* size */}
                <div>
                    <Select
                        disabled={disabled ?? false}
                        className="pagination-size"
                        dropdownMatchSelectWidth={false}
                        placement={'topRight'}
                        value={size}
                        onChange={setSize}
                        getPopupContainer={(trigger: any) => trigger.parentNode}
                    >
                        {sizeOptions.map((element: number) => (
                            <Option key={element} value={element}>{`${element}${
                                sizeLabelPage ?? '件'
                            }/1${sizeLabelSize ?? 'ページ'}`}</Option>
                        ))}
                    </Select>
                </div>
            </div>
        </div>
    );
};
export default memo(PaginationComponentList);
