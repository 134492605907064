import { Button, Layout } from 'antd';
import Sider from 'antd/lib/layout/Sider';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import EmptyData from '../../../../../assets/icons/empty-data.svg';
import HomeSvg from '../../../../../assets/icons/home.svg';
import UploadLightSVG from '../../../../../assets/icons/upload-icon-purple.svg';
import BreadcrumbComponent from '../../../../../common/components/breadcrumb/BreadcrumbComponent';
import MenuWrapperComponent from '../../../../../common/components/menu-wrapper';
import { RootState } from '../../../../../store';
import ProjectComponent from '../component/project';
import { HandlerImpl } from '../handler/HandlerImpl';
import { CM0061ContentComponent } from './CM0061ContentComponent';
import { CM0061TitleComponent } from './CM0061TitleComponent';
import { CM001Handler } from '../../../CM001/presenter/handler/HandlerImpl';
import { PARAM } from '../handler/HandlerProject';
import { DEFAULT_TAB_KEY, DEFAULT_TAB_VALUE } from '../handler/Handler';
import { useEffectOnce } from 'usehooks-ts';
import { CONSTRUCTION_ID_KEY } from '../../../CM003/presenter/handler/Handler';

interface props {
    handler: HandlerImpl;
    cm001Handler: CM001Handler;
}
const BREADCRUMB_CM0061: any[] = [
    {
        title: '収支管理',
        url: '',
        icon: <img style={{ paddingBottom: 2, paddingRight: 8 }} alt="" src={HomeSvg} />,
    },
    {
        title: '案件別収支管理',
        url: '',
        icon: '',
    },
];

const MainUI = ({ handler, cm001Handler }: props) => {
    const isCollapsed = useSelector((state: RootState) => state.cm0061.isCollapsed);
    const [searchParams, setSearchParams] = useSearchParams();
    const tab = searchParams.get(DEFAULT_TAB_KEY) ?? DEFAULT_TAB_VALUE;
    const projectName: string = searchParams.get(PARAM.projectName) ?? '';
    const rangeDate: string = searchParams.get(PARAM.rangeDate) ?? '';

    useEffectOnce(() => {
        const currentConstructionId = searchParams.get(CONSTRUCTION_ID_KEY)
        if(currentConstructionId) {
            searchParams.delete(CONSTRUCTION_ID_KEY)
            setSearchParams(searchParams)
        }
    })

    return (
        <MenuWrapperComponent chosenKey={51} openKey={5}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'end',
                }}
            >
                <div className="om002-header-container">
                    <BreadcrumbComponent data={BREADCRUMB_CM0061} />
                    <div className="header-row-title" style={{ marginTop: 8 }}>
                        案件別収支管理
                    </div>
                </div>
            </div>
            <Layout
                style={{ minHeight: '86vh', marginTop: 24, display: 'flex', flexDirection: 'row' }}
                className={isCollapsed ? 'close-side' : 'open-side'}
            >
                <Sider>
                    <ProjectComponent />
                </Sider>
                <Layout style={{ backgroundColor: '#ffffff' }}>
                    {/* <div className="data-empty" style={{ height: '80%' }}>
                        <img src={EmptyData} alt="" />
                    </div> */}

                    <div className="cm0061-wrapper">
                        <div className="cm0061-content">
                            <CM0061TitleComponent projectName={projectName} rangeDate={rangeDate} />
                            <CM0061ContentComponent
                                handler={cm001Handler}
                                projectName={projectName}
                                rangeDate={rangeDate}
                                tab={tab}
                            />
                        </div>
                    </div>
                </Layout>
            </Layout>
        </MenuWrapperComponent>
    );
};

export default MainUI;
