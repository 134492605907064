import { CM0034SliceType } from './../type/Presenter';
import { createSlice } from '@reduxjs/toolkit';

const initialState: CM0034SliceType = {
    isRefresh: false,
};

export const cm0034Slice = createSlice({
    name: 'cm0034',
    initialState,
    reducers: {
        resetCM0034: () => initialState,
        refresh: (state) => {
            state.isRefresh = !state.isRefresh;
        },
    },
});

export const { resetCM0034, refresh } = cm0034Slice.actions;

export default cm0034Slice.reducer;
