import React from 'react';

import { Button, Modal, Form, Input, InputNumber } from 'antd';
import MESSAGE from '../../../../../common/constants/MESSAGE';
import { PM014TableHandler } from '../handler/HandlerImpl';
import { typeInteger } from '../../../../../common/helpers/typeInput';
import helpers from '../../../../../common/helpers/common';
import { useDispatch, useSelector } from 'react-redux';
import { refreshPM014, setAllChecked, setAllCheckedApprove, setRefreshPM014 } from '../slice/Slice';
import COMMON from '../../../../../common/constants/COMMON';
import { MIN_INPUT_NUMBER } from '../../../../CR/CR003/presenter/ui/Estimate';

type props = {
  widthProp: number;
  marginLeftProp: number;
  marginTopProp: number;
  handler: PM014TableHandler;
};

const PM014InviteMemberModal = ({ widthProp, marginLeftProp, marginTopProp, handler }: props) => {
  const dispatch = useDispatch();
  const isRefresh = useSelector(refreshPM014);

  const cancelModalInvite = () => {
    // Reset Form
    handler.formInvite.resetFields();
    // Close Form
    handler.funcOpenModalInvite();
  };

  const handleInviteSubcontractorMember = async (items: any) => {
    try {
      await handler.funcInviteSubcontractorMember(
        Number(handler.chosenNavItem.constructionId),
        handler.funcHandleMapperInviteData(handler.checkedKeys),
        items.laborCost,
      );

      // Reset Form
      handler.formInvite.resetFields();
      // Close Form
      handler.funcOpenModalInvite();
      handler.setCheckedKeys([]);
      dispatch(setAllChecked([]));
      dispatch(setAllCheckedApprove([]));
      dispatch(setRefreshPM014(!isRefresh));
    } catch (error) {
      throw error;
    }
  };

  return (
    <Modal
      title="人工単価設定"
      visible={handler.visibleModalInvite}
      centered
      width={widthProp}
      style={{
        marginLeft: marginLeftProp, // 260px
        marginTop: `-${marginTopProp}px`, // -200px
        height: 444,
      }}
      className={'pm014__modal-invite'}
      onCancel={cancelModalInvite}
      footer={[
        <Button form="basic" htmlType="submit" className="pm014__button-confirm" style={{ letterSpacing: -1.5 }}>
          招待
        </Button>,
        <Button className="pm014__button-cancel" onClick={() => cancelModalInvite()}>
          キャンセル
        </Button>,
      ]}
    >
      <Form
        form={handler.formInvite}
        name="basic"
        labelCol={{
          span: 3,
        }}
        style={{
          height: 231,
        }}
        onFinish={handleInviteSubcontractorMember}
      // onFinishFailed={onFinishFailed}
      >
        <Form.Item label="選択人数" labelAlign="right">
          <span style={{ marginLeft: 10 }} className="ant-form-text">
            {handler.checkedKeys.length}名
          </span>
        </Form.Item>

        <Form.Item
          label="人工単価（円）"
          labelAlign="right"
          style={{ marginLeft: 10 }}
          name="laborCost"
          rules={[
            {
              required: true,
              message: MESSAGE.MESSAGE_REQUIRED,
            },
          ]}
        >
          <InputNumber controls={false}
            className="w-100-percent"
            maxLength={COMMON.MAX_INPUT_NUMBER}
            max={COMMON.MAX_NUMBER_VALUE}
            min={MIN_INPUT_NUMBER}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PM014InviteMemberModal;
