import axiosConfig from "../../../../../config/axiosConfig";
import { OffsetCostUpdateData, ReceivedMoneyOffset } from "../../entity/Entity";

const API = () => {
  const getReSummaryCompanyOutSourceCostOffsetList = async (params: {
    companyId: number;
    colabRefId: number;

    categoryId: number;
    from: string;
    to: string;
  }) => {
    const url = "/re-summary/company/outsource-cost/offset/list";
    return await axiosConfig.get(url, { params });
  };

  const getMoneyReceivedMoneyOffsetSubCategory = async () => {
    const url = "/money/received-money-offset/sub-category";
    return await axiosConfig.get(url);
  };

  const putReSummaryCompanyOutsourceCostOffsetUpdate = async (data: {
    companyId: number;
    colabRefId: number;

    data: OffsetCostUpdateData;
  }) => {
    const url = "/re-summary/company/outsource-cost/offset/update";
    return await axiosConfig.put(url, data);
  };
  const deleteReSummaryCompanyOutsourceCostOffsetDelete = async (data: {
    id: number[];
  }) => {
    const url = "/re-summary/company/outsource-cost/offset/delete";
    return await axiosConfig.delete(url, { data });
  };

  return {
    getReSummaryCompanyOutSourceCostOffsetList,
    getMoneyReceivedMoneyOffsetSubCategory,
    putReSummaryCompanyOutsourceCostOffsetUpdate,
    deleteReSummaryCompanyOutsourceCostOffsetDelete,
  };
};

export default API;
