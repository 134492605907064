import React from 'react';
import CM0015Api from './infrastructure/api/Api';
import CM0015Handler from './presenter/handler/Handler';
import CM0015Service from './usecase/Service';
import MainUI from './presenter/ui/UI';

import '../../../assets/styles/AM/AM028.css';
import '../../../assets/styles/AM/AM002.css';
import '../../../assets/styles/OM/OM002.css';
import '../../../assets/styles/CM/cm3854.css';
import '../../../assets/styles/CM/CM007.css';

const CM0015Component = () => {
    const api = CM0015Api();
    const service = CM0015Service(api);
    const handler = CM0015Handler(service);
    return <MainUI handler={handler} />;
};

export default CM0015Component;
