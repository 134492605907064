import { ConfigProvider, Form, Popover } from "antd";
import CollapsedFalseSVG from "../../../../../../../assets/icons/collapsed-false.svg";
import CollapsedTrueSVG from "../../../../../../../assets/icons/collapsed-true.svg";
import { CCUS02Handler } from "../../../handler/HandlerImpl";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import FilterComponent from "./Filter";
import React from "react";
import FilterIconSvg from "../../../../../../../assets/icons/filter-icon.svg";

type Props = {
  handler: CCUS02Handler;
};

const FilterSearchUI = ({ handler }: Props) => {
  const [searchParams] = useSearchParams();
  const isCollapsed = searchParams.get("isCollapsed") ? true : false;

  return (
    <ConfigProvider direction="ltr">
      <main className="filter-ccus-container">
        <Form
          form={handler.form}
          onFinish={handler.onFilter}
          className="from-filter-ccus"
        >
          {isCollapsed ? (
            <React.Fragment>
              <main className="ccus-mb-27 center-item">
                <div
                  className="collapsed-button-open"
                  onClick={() => handler.onCollapse(false)}
                >
                  <img alt="" src={CollapsedFalseSVG} />
                </div>
              </main>
              <Popover
                getPopupContainer={(trigger: any) => trigger.parentNode}
                placement="rightTop"
                content={
                  <div className="content-popover-ccus02">
                    {
                      <React.Fragment>
                        <main>
                          <div className="title-filter">
                            <span className="text-title-filter">絞込み</span>
                          </div>
                        </main>

                        <FilterComponent
                          onFromDateChange={handler.onFromDateChange}
                          onToDateChange={handler.onToDateChange}
                          onResetFilter={handler.onResetFilter}
                          isCollapsed={isCollapsed}
                          rangeDate={handler.rangeDate}
                          listProject={handler.listProject}
                          listCompany={handler.listCompany}
                          listApprover={handler.listApprover}
                          handleGetProject={handler.handleGetProject}
                          handleGetCompany={handler.handleGetCompany}
                          handleGetApprover={handler.handleGetApprover}
                          handleScrollProject={handler.handleScrollProject}
                          handleScrollCompany={handler.handleScrollCompany}
                          handleScrollApprover={handler.handleScrollApprover}
                          loadingCompany={handler.loadingCompany}
                          loadingProject={handler.loadingProject}
                          loadingApprover={handler.loadingApprover}
                          onChangeProject={handler.onChangeProject}
                          onChangeCompany={handler.onChangeCompany}
                          onChangeApprover={handler.onChangeApprover}
                          form={handler.form}
                        />
                      </React.Fragment>
                    }
                  </div>
                }
                trigger="click"
                autoAdjustOverflow={true}
                overlayStyle={{ maxHeight: "70vh" }}
              >
                <div className="ccus-mb-27 center-item">
                  {" "}
                  <img src={FilterIconSvg} alt="" />
                </div>
              </Popover>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <main>
                <div className="header-filter-ccus">
                  <span className="text-title-filter-ccus02">絞込み</span>
                  <div
                    className="collapsed-button-close"
                    onClick={() => handler.onCollapse(true)}
                  >
                    <img alt="" src={CollapsedTrueSVG} />
                  </div>
                </div>
              </main>
              <div className="ccus-px-16">
                <FilterComponent
                  onFromDateChange={handler.onFromDateChange}
                  onToDateChange={handler.onToDateChange}
                  onResetFilter={handler.onResetFilter}
                  isCollapsed={isCollapsed}
                  rangeDate={handler.rangeDate}
                  listProject={handler.listProject}
                  listCompany={handler.listCompany}
                  listApprover={handler.listApprover}
                  handleGetProject={handler.handleGetProject}
                  handleGetCompany={handler.handleGetCompany}
                  handleGetApprover={handler.handleGetApprover}
                  handleScrollProject={handler.handleScrollProject}
                  handleScrollCompany={handler.handleScrollCompany}
                  handleScrollApprover={handler.handleScrollApprover}
                  loadingCompany={handler.loadingCompany}
                  loadingProject={handler.loadingProject}
                  loadingApprover={handler.loadingApprover}
                  onChangeProject={handler.onChangeProject}
                  onChangeCompany={handler.onChangeCompany}
                  onChangeApprover={handler.onChangeApprover}
                  form={handler.form}
                />
              </div>
            </React.Fragment>
          )}
        </Form>
      </main>
    </ConfigProvider>
  );
};

export default FilterSearchUI;
