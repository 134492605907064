import SN01Api from './infrastructure/api/Api';
import SN01Handler from './presenter/handler/Handler';
import MainUI from './presenter/ui/UI';
import SN01Service from './usecase/Service';

import '../../../assets/styles/SN/SN01.css';

const SN01Component = () => {
    const api = SN01Api();
    const service = SN01Service(api);
    const handler = SN01Handler(service);
    return <MainUI handler={handler} />;
};

export default SN01Component;
