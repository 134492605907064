import { Modal } from "antd"
import { useSelector } from "react-redux"
import { RootState } from "../../../store"
import PdfViewerComponent from "../pdf-viewer/PdfViewerComponent"
import usePdfViewerModal from "./usePdfViewerModal"
import XIconModal from '../../../assets/icons/x-icon-modal.svg'
import useWindowDimensions from "../../helpers/windowDimension"

type props = {
    pdfURL: string,
    marginLeft?: number,
    title?: string;

}

const PdfViewerModalComponent = ({ title, pdfURL, marginLeft = 300 }: props) => {
    const isOpenPdfViewer = useSelector((state: RootState) => state.pdfViewerModal.isOpenPdfViewer)
    const { onHidePdfViwerModal } = usePdfViewerModal()
    const { width } = useWindowDimensions();

    return isOpenPdfViewer ?
        <Modal
            style={{ marginLeft: width > 1872 ? 300 : 0, marginTop: 40, marginBottom: 40 }}
            centered={width > 1700}
            width={1572}
            className="group-detail-modal modal-fixed-1572"

            title={
                <div className="w-100-percent " style={{ display: 'flex', justifyContent: "space-between", alignContent: "center" }}>
                    <div className="header-modal-title-cm006">{title ? title : null}</div>
                    <div style={{ cursor: 'pointer' }} onClick={() => onHidePdfViwerModal()}>
                        <img alt="" src={XIconModal} style={{ height: 18, width: 18 }} />
                    </div>
                </div>
            }
            closable={false}
            maskClosable={false}
            footer={null}
            visible={true}
        >
            <div style={{ marginTop: 20 }}>
                <PdfViewerComponent url={pdfURL} />
            </div>
        </Modal> :
        <></>
}

export default PdfViewerModalComponent