import { DateColumn } from "../../../entity/Entity";
import AttendanceApi from "../../../infrastructure/api/ApiAttendance";
import AttendanceService from "../../../usecase/ServiceAttendance";
import AttendanceHandler from "../../handler/HandlerAttendance";
import AttendanceUI from "./ui/UI";

interface Props {
  contractorConstructionId: number;
  userId: number;
  columnsTable: DateColumn[];
  constructionId: number;
  attendance: any
}

const AttendanceComponent = ({
  contractorConstructionId,
  userId,
  constructionId,
  columnsTable,
  attendance,
}: Props) => {
  const api = AttendanceApi();
  const service = AttendanceService(api);
  const handler = AttendanceHandler(service);
  return (
    <AttendanceUI
      attendance={attendance}
      handler={handler}
      contractorConstructionId={contractorConstructionId}
      userId={userId}
      columnsTable={columnsTable}
      constructionId={constructionId}
    />
  );
};

export default AttendanceComponent;
