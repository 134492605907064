import AM010UI from "./presenter/ui/UI";

//CSS
import "../../../assets/styles/AM/AM010.css";
import AM010Handler from "./presenter/handler/Handler";
import AM010Api from "./infrastructure/api/Api";
import AM010Service from "./usecase/Service";

function AM010Component() {
  const am010Api = AM010Api();
  const am010Service = AM010Service(am010Api);
  const am101Handler = AM010Handler(am010Service);

  return <AM010UI handler={am101Handler} />;
}

export default AM010Component;
