// Library
import React from "react";

// Service

import { CM001Handler } from "../handler/HandlerImpl";

// Icons

import eyeIconBlack from "../../../../../assets/icons/eyeIconBlack.svg";
import { mappingDataProjectSummary, subtractAmount } from "../../helper";
import helpers from "../../../../../common/helpers/common";
import ThresholdPopoverComponent from "../../../../../common/components/threshold-popover";
import {
  CODE_FOUR,
  CODE_ONE,
  CODE_SIX,
  CODE_THREE,
  CODE_TWO,
  DEFAULT_TAB_VALUE,
  PROJECT_PARAM_ID_KEY,
} from "../handler/Handler";
import { useSearchParams } from "react-router-dom";

type props = {
  handler: CM001Handler;
  searchValueParams: string;
};

type itemProps = {
  item: any;
};

const CM001ElementComponent = ({ handler, searchValueParams }: props) => {
  const [searchParams] = useSearchParams();
  const projectId: string = searchParams.get(PROJECT_PARAM_ID_KEY) ?? "0";

  React.useEffect(() => {
    if (projectId !== "")
      handler.getSummaryProjectMoneyReceived({ projectId: projectId });
    handler.getSummaryProjectCost({ projectId: projectId });
  }, [handler.refresh]);

  const record = { ...handler.summaryData };

  return (
    <div className="cm001-element">
      {Object.keys(handler.summaryData).length !== 0 ? (
        <>
          <div className="element-revenue">
            <div className="header">案件総出来高</div>
            <div className="content">
              <div className="header-title">
                <div className="item">契約金額</div>
                <div className="item">請求出来高</div>
                <div className="item">請求出来高(％)</div>
                <div className="item">請求残金</div>
                <div className="item"></div>
              </div>
              <div className="content-items">
                <div className="item">
                  {helpers.formatNumberWithCommas(record?.contractAmount ?? 0)}
                  円
                </div>
                <div className="item">
                  {helpers.formatNumberWithCommas(record?.billingVolume ?? 0)}円
                </div>
                <div className="item">
                  {helpers.calculateRateAmount(
                    record?.contractAmount,
                    record?.billingVolume
                  )}
                  ％
                  <ThresholdPopoverComponent
                    initialData={{
                      ...record
                    }}
                    update={handler.updateThresholdData}
                  />
                </div>
                <div className="item">
                  {helpers.formatNumberWithCommas(
                    subtractAmount(
                      handler.summaryData?.contractAmount,
                      handler.summaryData?.billingVolume
                    )
                  )}
                  円
                </div>
                <div className="item">
                  <img
                    style={{ cursor: "pointer" }}
                    src={eyeIconBlack}
                    alt="view"
                    onClick={() => handler.navigateCM003(searchValueParams)}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      <div className="element-budget">
        <div className="header">予算残高一覧</div>
        <div className="content">
          <div className="header-title">
            <div className="item"></div>
            <div className="item">予算金額</div>
            <div className="item">実費用</div>
            <div className="item">予算残高</div>
            <div className="item">予算消化率(％)</div>
          </div>
          {mappingDataProjectSummary(
            handler.summaryDataCost,
            handler.summaryData.contractAmount,
            handler.summaryData.billingVolume
          ).map((item: any, idx: number) => (
            <div className="content-items" key={idx}>
              <div className="item item-left">{item?.name ?? ""}</div>
              <div className="item item-center">
                {helpers.formatNumberWithCommas(item.planCost)}円
              </div>
              <div className="item item-center">
                {helpers.formatNumberWithCommas(item.actualCost)}円
              </div>
              <div className="item item-center">
                {item.code !== CODE_SIX
                  ? `${helpers.formatNumberWithCommas(item.balanceCost)}円`
                  : ""}
              </div>
              <div className="item item-center">
                {item.code !== CODE_SIX ? (
                  <>
                    {/* HOTFIX: default value */}
                    {item.threshold_view} % &nbsp;{" "}
                    <ThresholdPopoverComponent
                      initialData={{
                        ...item,
                      }}
                      update={
                        item.code === CODE_ONE
                          ? handler.updateThresholdCostMaterialData
                          : item.code === CODE_TWO
                            ? handler.updateThresholdCostOutsourceData
                            : item.code === CODE_THREE
                              ? handler.updateThresholdCostLaborData
                              : item.code === CODE_FOUR
                                ? handler.updateThresholdCostIndirectData
                                : handler.updateThresholdCostTotalData
                      }
                    />
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export { CM001ElementComponent };
