import { useState } from "react";
import { useDispatch } from "react-redux";
import { DB006UseCase } from "../../usecase/ServiceImpl";
import { setVisibleDB006 } from "../slice/Slice";

const DB006Handler = (service: DB006UseCase) => {
  const dispatch = useDispatch();

  const cancelModalDB006 = () => {
    dispatch(setVisibleDB006(false));
  };

  return {cancelModalDB006};
};
export default DB006Handler;
