import PM025Api from "./infrastructure/api/Api";
import PM025Service from "./usecase/Service";
import PM025Handler from "./presenter/handler/Handler";
import PM025UI from "./presenter/ui/UI";

const PM025Component = () => {
  const pm025Api = PM025Api();
  const pm025Service = PM025Service(pm025Api);
  const pm025Handler = PM025Handler(pm025Service);
  return <PM025UI handler={pm025Handler} />;
};

export default PM025Component;
