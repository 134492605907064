import React from 'react';
import { CM0028Hanlder } from '../handler/HandlerImpl';
import { DatePicker, Button, Form } from 'antd';
import IconCircleCheck from '../../../../../assets/icons/akar-icons_circle-check-fill.svg';
import IconExport from '../../../../../assets/icons/upload-purple.svg';
import locale from 'antd/es/date-picker/locale/ja_JP';
import 'moment/locale/ja';
import moment from 'moment';
import COMMON, { STATUS_CM_CHILD_TABLE } from '../../../../../common/constants/COMMON';
import CalendarSvg from '../../../../../assets/icons/calender-purple.svg';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { AUTHORIZATION_CODE, SYSTEM_ROLES } from '../../../../../common/constants/AUTHORIZATION';
import UploadLightSVG from '../../../../../assets/icons/upload-icon-purple.svg';
import ApproveConfirmModal from '../../../../../common/components/modal-approve-confirm';

const { RangePicker } = DatePicker;

interface props {
    handler: CM0028Hanlder;
}

const Header = ({ handler }: props) => {
    const permission = useSelector((state: RootState) => state.auth.authData?.permission);

    const isRoleAdmin = useSelector((state: RootState) => state.auth?.authData?.isRoleAdmin)

    const isRoleAdminSystem =
        useSelector((state: RootState) => state.auth?.authData?.isRoleAdminSystem)

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
            <div className="cm024-header-title">
                <span className="cm024-header-label">ステータス:</span>
                <span
                    className="cm024-header-content"
                    style={{
                        color: STATUS_CM_CHILD_TABLE[handler.status?.code?.toUpperCase() as keyof typeof STATUS_CM_CHILD_TABLE]?.COLOR,
                    }}
                >
                    {handler.status?.name}
                </span>
            </div>
            <div className="cm024-header-filter">
                <div className="cm024-header-filter-choose">
                    <Form onValuesChange={handler.handleFilterTime} form={handler.formFilter}>
                        <Form.Item name="filterTime4">
                            <RangePicker
                                allowClear={false}
                                inputReadOnly
                                size="large"
                                locale={locale}
                                picker="month"
                                className="am028-range cm024-range cm0020-range"
                                format={COMMON.FORMAT_DATE_JA_MONTH}
                                suffixIcon={
                                    <img
                                        alt=""
                                        src={CalendarSvg}
                                        className="icon-calender"
                                        style={{ width: 20 }}
                                    />
                                }
                                onOpenChange={handler.handleOpenCalendar}
                            />
                        </Form.Item>
                    </Form>
                    {permission?.includes(AUTHORIZATION_CODE.S47) || isRoleAdmin || isRoleAdminSystem ? (
                        <>
                            <Button
                                className="button-purple cm047-btn-submit cm024-btn-submit"
                                icon={<img alt="" src={IconCircleCheck} />}
                                size="large"
                                onClick={handler.onSubmit}
                                disabled={
                                    handler.status?.code ===
                                    STATUS_CM_CHILD_TABLE.MONEY_WAITING_FOR_APPROVAL.CODE ||
                                    handler.projects?.length === 0
                                }
                            >
                                提出
                            </Button>
                        </>
                    ) : null}
                    {permission?.includes(AUTHORIZATION_CODE.B9) || isRoleAdmin || isRoleAdminSystem ? (
                        <Button
                            className="button-brown-3"
                            style={{ border: '1px solid #605BFF', width: 106, marginLeft: 16 }}
                            size="large"
                            onClick={handler.exportExcel}
                        >
                            <div className="center-item">
                                <img
                                    alt=""
                                    src={UploadLightSVG}
                                    style={{
                                        marginRight: 8,
                                        fontWeight: 700,
                                        fontSize: 14,
                                    }}
                                />
                                <div
                                    className="export-button"
                                    style={{ color: "#605BFF" }}
                                >
                                    出力
                                </div>
                            </div>
                        </Button>
                    ) : (
                        <div></div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Header;
