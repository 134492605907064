import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { HandlerImpl } from "../handler/HandlerImpl";
import BreadcrumbComponent from "../../../../../common/components/breadcrumb/BreadcrumbComponent";
import { Button, Layout } from "antd";
import UploadLightSVG from "../../../../../assets/icons/upload-icon-purple.svg";
import CompanyComponent from "../component/company";
import HomeSvg from "../../../../../assets/icons/home.svg";
import PaginationComponent from "./Pagination";
import TableComponent from "./Table";
import MenuWrapperComponent from "../../../../../common/components/menu-wrapper";
import moment from "moment";
import COMMON from "../../../../../common/constants/COMMON";
import { getTitleByFromToDate } from "../../../AM008/helper";
import helpers from "../../../../../common/helpers/common";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import {
  AUTHORIZATION_CODE,
  SYSTEM_ROLES,
} from "../../../../../common/constants/AUTHORIZATION";
import NoDataTableComponent from "../../../../../common/components/no-data/NoDataTable";

interface props {
  handler: HandlerImpl;
}
const { Content, Sider } = Layout;

const BREADCRUMB_AM002: any[] = [
  {
    title: "勤怠管理",
    url: "",
    icon: (
      <img style={{ paddingBottom: 2, paddingRight: 8 }} alt="" src={HomeSvg} />
    ),
  },
  {
    title: "会社別勤務実績",
    url: "",
    icon: "",
  },
];

const MainUI = ({ handler }: props) => {
  const {
    totalRecord,
    getAttendanceCompanyColabSubConstruction,
    getAttendanceCompanyColabTotalCost,
  } = handler;
  const [searchParams] = useSearchParams();
  const selectedCompany = searchParams.get("selectedCompany") || undefined;
  const page = searchParams.get("page") || COMMON.DEFAULT_PAGE + "";
  const size = searchParams.get("size") || COMMON.DEFAULT_SIZE + "";
  const keyword = searchParams.get("keyword") || "";
  const sortBy = searchParams.get("sortBy") || undefined;
  const sortType = searchParams.get("sortType") || undefined;
  const fromDate =
    searchParams.get("startDate") ||
    moment().startOf("month").format(COMMON.FORMAT_DATE2);
  const toDate =
    searchParams.get("endDate") || moment().format(COMMON.FORMAT_DATE2);
  const isCollapsed = searchParams.get("isCollapsed") ? true : false;
  const loading = useSelector((state: RootState) => state.common.loading);
  const permission = useSelector(
    (state: RootState) => state.auth.authData?.permission
  );

  const isRoleAdmin = useSelector((state: RootState) => state.auth?.authData?.isRoleAdmin)

  const isRoleAdminSystem =
    useSelector((state: RootState) => state.auth?.authData?.isRoleAdminSystem)

  useEffect(() => {
    (async () => {
      if (selectedCompany && !isNaN(parseInt(selectedCompany))) {
        const param: any = {
          companyId: parseInt(selectedCompany),
          page: page,
          size: size,
          keyword: keyword,
          from:
            fromDate === ""
              ? undefined
              : fromDate === null
                ? moment().startOf("month").format(COMMON.FORMAT_DATE2)
                : moment(fromDate).format(COMMON.FORMAT_DATE2),
          to: toDate,
          sortType: sortType && (sortType === "ascend" ? "ASC" : "DESC"),
          sortBy: sortBy,
        };

        await Promise.all([
          getAttendanceCompanyColabSubConstruction(param),
          getAttendanceCompanyColabTotalCost(param),
        ]);
      }
    })();
  }, [
    selectedCompany,
    page,
    size,
    keyword,
    sortBy,
    sortType,
    fromDate,
    toDate,
  ]);

  return (
    <MenuWrapperComponent chosenKey={22} openKey={2}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
        }}
      >
        <div className="om002-header-container">
          <BreadcrumbComponent data={BREADCRUMB_AM002} />
          <div className="header-row-title" style={{ marginTop: 8 }}>
            会社別勤務実績
          </div>
        </div>
        {permission?.includes(AUTHORIZATION_CODE.B9) || isRoleAdmin || isRoleAdminSystem ? (
          <Button
            className="button-brown-3"
            style={{ border: "1px solid #605BFF", width: 106 }}
            size="large"
            onClick={() => handler.exportData({
              companyId: parseInt(selectedCompany || '0'),
              page: 1,
              size: 1000,
              keyword: keyword,
              from:
                fromDate === ""
                  ? undefined
                  : fromDate === null
                    ? moment().startOf("month").format(COMMON.FORMAT_DATE2)
                    : moment(fromDate).format(COMMON.FORMAT_DATE2),
              to: toDate,
              sortType: sortType && (sortType === "ascend" ? "ASC" : "DESC"),
              sortBy: sortBy,
            })}
          >
            <div className="center-item">
              <img
                alt=""
                src={UploadLightSVG}
                style={{
                  marginRight: 8,
                  fontWeight: 700,
                  fontSize: 14,
                }}
              />
              <div
                className="export-button"
                style={{ color: "#605BFF" }}
              >
                出力
              </div>
            </div>
          </Button>
        ) : (
          <div></div>
        )}
      </div>
      <Layout
        style={{ minHeight: "84vh", marginTop: 24 }}
        className={isCollapsed ? "close-side" : "open-side"}
      >
        <Sider>
          <CompanyComponent />
        </Sider>
        <Layout style={{ backgroundColor: "#ffffff" }}>
          <div
            className="w-100-percent"
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 18,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                gap: 116,
              }}
            >
              <p className="total-money">
                合計金額：{handler.companyList.length == 0 ? 0 : helpers.formatCurrency(handler.totalMoney || 0)}円
              </p>
              <p className="total-money">
                合計稼働人工数：
                {handler.companyList.length == 0 ? 0 : helpers.formatCurrency(handler.totalWorkingDay || 0)}人工
              </p>
            </div>
            <p className="total-money date-label">
              {getTitleByFromToDate(fromDate, toDate)}
            </p>
          </div>
          <Content
            style={{ margin: "0px 24px 0px 24px", backgroundColor: "#ffffff" }}
          >
            { handler.companyList.length != 0 ? (
              <TableComponent
                expandKey={handler.expandKey}
                isCollapsed={handler.isCollapsed}
                onChangeCollapseRow={handler.onChangeCollapseRow}
                dataSource={handler.listConstruction}
                setDefaultSorter={handler.setDefaultSorter}
                onTableChange={handler.onTableChange}
              />
            ) : (
                <NoDataTableComponent/>
            )}
          </Content>
          {handler.listConstruction.length > 0 && handler.companyList.length > 0 ? (
            <PaginationComponent
              page={page}
              size={size}
              onPageChange={handler.onPageChange}
              onSizeChange={handler.onSizeChange}
              total={totalRecord}
            />
          ) : null}
        </Layout>
      </Layout>
    </MenuWrapperComponent>
  );
};

export default MainUI;
