import { Avatar, Badge, Button, Col, Form, Row, Select, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import IconMap from "../../../../../assets/icons/ic_map.svg";
import NotificationBing from "../../../../../assets/icons/notification-bing.svg";
import { PlusOutlined, UserOutlined } from "@ant-design/icons";
import EmptyData from "../../../../../assets/icons/empty-data.svg";
import { PM002UIHandler } from "../handler/HandlerImpl";
import ModalInfo from "./ModalInfo";
import { useEffect } from "react";
import TooltipText from "../../../../../common/components/tooltip-text/TooltipText";
import { DefaultAvatarComponent } from "../../../../../common/components/default-avatar";
import { SYSTEM_ROLES } from "../../../../../common/constants/AUTHORIZATION";
import { isHasPrivilege } from "../../../../../common/helpers/privilege";
import {
  PRIVILEGE_CREATOR,
  PRIVILEGE_MEMBER,
  PRIVILEGE_PM,
  PRIVILEGE_SUB_PM,
} from "../../../../../common/constants/PRIVILEGE";
import { PLAN_TYPE } from "../../../../../common/constants/COMMON";

const { Option } = Select;
export interface DataType {
  projectName: string;
  constructionName: string;
  picName: string;
  constructionToDate: string;
  location: string;
  orderAddress: string;
  statusId: string;
}
interface props {
  handler: PM002UIHandler;
}

const MAX_LENGTH = 18;
const MAX_LENGTH_DATE = 35;
const MAX_LENGTH_CONSTRUCTION = 13;

const PM002CardListUI = ({ handler }: props) => {
  const data = useSelector((state: RootState) => state.pm002.data);
  const allStatus = useSelector((state: RootState) => state.pm002.allStatus);
  const [form] = Form.useForm();
  const isRoleAdmin = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdmin
  );

  const isRoleAdminSystem = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdminSystem
  );

  useEffect(() => {
    data.forEach((project) => {
      form.setFieldValue(`status${project?.id}`, project?.status?.id);
    });
  }, [data]);
  const isPlanFree =
    useSelector((state: RootState) => state.auth.authData?.order?.code) ===
    PLAN_TYPE.PLAN_FREE;

  return (
    <Form form={form}>
      {data.length > 0 ? (
        <Row gutter={[16, 24]}>
          {data.map((element) => {
            let members: any[] = [];
            if (element.members) {
              members = [...element.members];
            }

            const constructions: any = [];
            if (element?.constructions?.length > 0) {
              element?.constructions.forEach((ele: any) => {
                if (ele.code === "main") {
                  constructions.unshift(ele);
                } else constructions.push(ele);
              });
            }
            return (
              <Col
                xl={12}
                lg={24}
                xxl={8}
                key={element.id}
                style={{ cursor: "pointer" }}
                onClick={(e: any) => {
                  e.stopPropagation();
                  handler.onViewDetail(element);
                }}
              >
                <Col>
                  <div className="card-container">
                    <Row className="card-header">
                      <Col span={13} className="card-header-left">
                        <div className="card-header-avatar">
                          <DefaultAvatarComponent
                            isAdmin={[
                              SYSTEM_ROLES.ADMIN,
                              SYSTEM_ROLES.ADMIN_SYSTEM,
                            ].includes(element?.projectManager?.roleCode ?? "")}
                            avatar={
                              element?.projectManager?.avatar ?? undefined
                            }
                            value={element?.projectManager?.fullName ?? ""}
                            size={50}
                          />
                        </div>
                        <div
                          className="card-header-info"
                          style={{
                            marginBottom: -2,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <div className="card-header-contruction-name">
                            <div className="text-card">
                              <TooltipText content={element.projectName} />
                            </div>
                          </div>
                          {element?.parentId !== 0 ? (
                            <div className="pm002-subcontractor">
                              <Tooltip
                                placement="bottomLeft"
                                title={`${element?.rootContractor?.company?.name}
                              から招待された案件`}
                              >
                                <span>
                                  {element?.rootContractor?.company?.name}
                                  から招待された案件
                                </span>
                              </Tooltip>
                            </div>
                          ) : null}
                        </div>
                      </Col>
                      <Col span={11} className="card-header-right">
                        <Form.Item
                          name={`status${element.id}`}
                          style={{ margin: 0 }}
                        >
                          <Select
                            style={{
                              width: 138,
                            }}
                            disabled={
                              !(
                                isHasPrivilege(element, [
                                  PRIVILEGE_PM,
                                  PRIVILEGE_CREATOR,
                                ]) ||
                                isRoleAdmin ||
                                isRoleAdminSystem
                              )
                            }
                            className="header-select border-color-select"
                            onClick={(e: any) => {
                              e.stopPropagation();
                            }}
                            onChange={(e) => {
                              handler.handleUpdateStatus({
                                id: element.id,
                                statusId: e,
                              });
                            }}
                            getPopupContainer={(trigger: any) =>
                              trigger.parentNode
                            }
                          >
                            {allStatus.map((element) => (
                              <Option key={element.id} value={element.id}>
                                {element.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Button
                          type="link"
                          style={{ border: "none" }}
                          icon={
                            <img
                              style={{ width: "30px" }}
                              alt=""
                              src={IconMap}
                            />
                          }
                          onClick={(e: any) => {
                            e.stopPropagation();
                            if (element.document) {
                              handler.onShowPdfMap(element);
                            }
                          }}
                        ></Button>
                        {!isPlanFree ? (
                          <>
                            <Badge
                              count={element?.notificationNumber}
                              className="avatar-badge-button"
                            >
                              <Button
                                type="link"
                                style={{ border: "none" }}
                                icon={
                                  <img
                                    alt=""
                                    style={{ width: "24px" }}
                                    src={NotificationBing}
                                  />
                                }
                                onClick={(e: any) => {
                                  e.stopPropagation();
                                  handler.handleViewNotification(element);
                                }}
                              ></Button>
                            </Badge>
                          </>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                    <Row className="card-content">
                      <Col span="20" className="card-content-left">
                        <h3 style={{ color: "#666" }}>案件基本情報</h3>
                        <div className="content-title">
                          <div className="title-left">案件名</div>
                          <div className="pm002-text">
                            <TooltipText content={element.projectName ?? "-"} />
                          </div>
                        </div>
                        <div className="content-title">
                          <div className="title-left">案件担当者</div>
                          <div className="pm002-text">
                            <TooltipText
                              content={
                                (element?.projectManager?.fullName ||
                                  element?.projectManager?.username) ??
                                "-"
                              }
                            />
                          </div>
                        </div>
                        <div className="content-title">
                          <div className="title-left">案件副担当者</div>
                          <div className="pm002-text">
                            <TooltipText
                              content={
                                element?.deputyProjectManager
                                  ?.map(
                                    (ele: any) => ele.fullName || ele.username
                                  )
                                  .join("、") ?? "-"
                              }
                            />
                          </div>
                        </div>
                        <div className="content-title">
                          <div className="title-left">発注元会社</div>
                          <div className="pm002-text">
                            <TooltipText
                              content={element.orderAddress ?? "-"}
                            />
                          </div>
                        </div>

                        <div className="content-title">
                          <div className="title-left">契約工事期間</div>
                          <div>
                            <TooltipText
                              content={element.constructionDate ?? "-"}
                            />
                          </div>
                        </div>
                        <div className="content-title">
                          <div className="title-left">住所</div>
                          <div className="pm002-text">
                            <TooltipText content={element.location ?? "-"} />
                          </div>
                        </div>
                        <div className="content-title">
                          <div className="title-left">工事名</div>
                          <div className="pm002-text">
                            <TooltipText
                              content={
                                constructions
                                  ?.map((ele: any) => `${ele.name}`)
                                  .join("、") ?? "-"
                              }
                            />
                          </div>
                        </div>

                        <div className="content-title">
                          <div className="title-left">案件作成者</div>
                          <div className="pm002-text">
                            <TooltipText
                              content={element?.projectCreator?.fullName ?? "-"}
                            />
                          </div>
                        </div>
                      </Col>

                      <Col span={4} className="card-content-right">
                        <div
                          style={{
                            paddingTop: 10,
                            display: "inline-block",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div>
                            <Avatar.Group
                              style={{ transform: "rotate(90deg)" }}
                              maxCount={members?.length > 3 ? 2 : 3}
                              maxPopoverTrigger="click"
                              size="large"
                              maxStyle={{
                                color: "#f56a00",
                                backgroundColor: "#fde3cf",
                                cursor: "pointer",
                                transform: "rotate(270deg)",
                              }}
                            >
                              {members?.map((element: any, index: any) => (
                                <DefaultAvatarComponent
                                  style={{
                                    transform: "rotate(270deg)",
                                  }}
                                  key={index}
                                  isAdmin={[
                                    SYSTEM_ROLES.ADMIN,
                                    SYSTEM_ROLES.ADMIN_SYSTEM,
                                  ].includes(element.roleCode ?? "")}
                                  avatar={element?.avatar ?? undefined}
                                  value={element?.fullName ?? ""}
                                  size={40}
                                />
                              ))}

                              {members?.length <= 2 &&
                                (isHasPrivilege(element, [
                                  PRIVILEGE_PM,
                                  PRIVILEGE_SUB_PM,
                                  PRIVILEGE_MEMBER,
                                  PRIVILEGE_CREATOR,
                                ]) ||
                                  isRoleAdminSystem ||
                                  isRoleAdmin) && (
                                  <Avatar
                                    onClick={(e: any) => {
                                      e.stopPropagation();
                                      // handler.changeShowModal();
                                      handler.changeProject(element.id);
                                      handler.handleShowModalMember(element);
                                    }}
                                    style={{
                                      backgroundColor: "#605BFF",
                                      transform: "rotate(270deg)",
                                      cursor: "pointer",
                                    }}
                                    icon={<PlusOutlined />}
                                  />
                                )}
                            </Avatar.Group>
                          </div>
                          {(members?.length > 2 || !members) &&
                            (isHasPrivilege(element, [
                              PRIVILEGE_PM,
                              PRIVILEGE_SUB_PM,
                              PRIVILEGE_MEMBER,
                              PRIVILEGE_CREATOR,
                            ]) ||
                              isRoleAdminSystem ||
                              isRoleAdmin) && (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginTop: 20,
                                  cursor: "pointer",
                                }}
                              >
                                <Avatar
                                  onClick={(e: any) => {
                                    e.stopPropagation();
                                    handler.handleShowModalMember(element);
                                  }}
                                  size="large"
                                  style={{
                                    backgroundColor: "#605BFF",
                                    cursor: "pointer",
                                  }}
                                  icon={<PlusOutlined />}
                                />
                              </div>
                            )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Col>
            );
          })}
        </Row>
      ) : (
        <div className="data-empty" style={{ height: "56vh" }}>
          <img src={EmptyData} alt="" />
        </div>
      )}

      <ModalInfo handler={handler} />
    </Form>
  );
};
export default PM002CardListUI;
