import { Button, Col, DatePicker, Form, Input, Row } from "antd";
import React, { useEffect } from "react";
import { Handler } from "../handler/HandlerImpl";
import helpers from "../../../../../common/helpers/common";
import locale from "antd/es/date-picker/locale/ja_JP";
import CalendarSvg from "../../../../../assets/icons/calendar.svg";
import COMMON, { MAX_LENGTH } from "../../../../../common/constants/COMMON";
import REGEX from "../../../../../common/constants/REGEX";
import MESSAGE from "../../../../../common/constants/MESSAGE";
import {
  inputPhoneNumberFormat,
  onBlurTrimFormItem,
  typeInteger,
  typeIntegerORD,
} from "../../../../../common/helpers/typeInput";
import { validateInput } from "../../../../ORD/ORD002_1/helper";
import { TYPE_COMPANY_INFOR, TYPE_PARAM } from "../handler/Handler";
import { useSearchParams } from "react-router-dom";
import { CCUS_VERRIFICATION_STATE } from "../../../../../common/constants/STATUS";

interface props {
  handler: Handler;
}

const EditInformation = ({ handler }: props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const typeInformationCompany =
    searchParams.get(TYPE_PARAM) || TYPE_COMPANY_INFOR.view;

  useEffect(() => {
    handler.fillValueForm();
  }, [typeInformationCompany]);

  return (
    <div className="ic2-body">
      <div className="ic2-content">
        <Form
          onValuesChange={handler.checkDirty}
          form={handler.form}
          id="formEditProfileCompany"
          layout="horizontal"
          onFinish={handler.submitForm}
          autoComplete="off"
        >
          <div style={{ display: "flex", gap: 140, width: "100%" }}>
            <Col>
              <div className="content-wrapper-ic1 content-form">
                <div className="item-info-ic2">
                  <div
                    className="label-text"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    会社名<span className="label-required">*</span>
                  </div>
                  <Form.Item
                    style={{ margin: 0 }}
                    name="name"
                    rules={[
                      validateInput({
                        maxLength: 50,
                        required: true,
                        regex: REGEX.NOT_VIETNAM,
                      }),
                    ]}
                  >
                    <Input
                      style={{ height: 40, width: 375 }}
                      maxLength={50}
                      autoComplete="off"
                      onBlur={() => onBlurTrimFormItem(handler.form, "name")}
                      onChange={(e) => {
                        helpers.preventInputJapanese(e);
                      }}
                    />
                  </Form.Item>
                </div>
                <div className="item-info-ic2">
                  <div
                    className="label-text"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    郵便番号<span className="label-required">*</span>
                  </div>
                  <Form.Item
                    style={{ margin: 0 }}
                    name="postNumber"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (value && value !== "") {
                            value = value.trim().replaceAll(" ", "");
                            value = value.replaceAll("-", "");
                            if (!REGEX.REGEX_PHONE_FAX.test(value))
                              return Promise.reject(
                                MESSAGE.MESSAGE_LESS_THAN_7
                              );
                            if (value?.length < 7) {
                              return Promise.reject(
                                MESSAGE.MESSAGE_LESS_THAN_7
                              );
                            }
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(MESSAGE.MESSAGE_REQUIRED_FIELD)
                          );
                        },
                      }),
                    ]}
                  >
                    <Input
                      style={{ height: 40, width: 375 }}
                      maxLength={8}
                      onKeyPress={typeInteger}
                      onPaste={typeInteger}
                      onChange={(e) => {
                        helpers.formatZipCode(
                          handler.form,
                          "postNumber",
                          e.target.value
                        );
                        helpers.preventInputJapanese(e);
                      }}
                      onBlur={() =>
                        onBlurTrimFormItem(handler.form, "postNumber")
                      }
                      autoComplete="off"
                    />
                  </Form.Item>
                </div>
                <div className="item-info-ic2">
                  <div
                    className="label-text"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    住所<span className="label-required">*</span>
                  </div>
                  <Form.Item
                    style={{ margin: 0 }}
                    name="address"
                    rules={[
                      {
                        whitespace: true,
                        message: MESSAGE.MESSAGE_REQUIRED_FIELD,
                      },
                      {
                        required: true,
                        message: MESSAGE.MESSAGE_REQUIRED_FIELD,
                      },
                      {
                        pattern: new RegExp(REGEX.EXCEPT_VIETNAMESE),
                        message: MESSAGE.MESSAGE_INVALID_INPUT,
                      },
                      {
                        max: 50,
                        message: "",
                      },
                    ]}
                  >
                    <Input
                      style={{ height: 40, width: 375 }}
                      maxLength={MAX_LENGTH.ADDRESS}
                      autoComplete="off"
                    />
                  </Form.Item>
                </div>
                <div className="item-info-ic2">
                  <div
                    className="label-text"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    電話番号<span className="label-required">*</span>
                  </div>
                  <Form.Item
                    style={{ margin: 0 }}
                    name="phoneNumber"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (value && value !== "") {
                            value = value.trim().replaceAll(" ", "");
                            value = value.replaceAll("-", "");
                            if (!REGEX.PHONENUMBER_2.test(value))
                              return Promise.reject(
                                MESSAGE.MESSAGE_WRONG_PHONE_FORMAT
                              );
                            if (value?.length < 10) {
                              return Promise.reject(
                                MESSAGE.MESSAGE_INVALID_INPUT
                              );
                            }

                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(MESSAGE.MESSAGE_REQUIRED_FIELD)
                          );
                        },
                      }),
                    ]}
                  >
                    <Input
                      style={{ height: 40, width: 375 }}
                      placeholder="例：09011112222　ハイフン(ー)なし　半角"
                      maxLength={COMMON.MAX_LENGTH_PHONE_NUMBER}
                      onKeyPress={typeInteger}
                      onPaste={typeInteger}
                      onChange={(e) => {
                        helpers.preventInputJapanese(e);
                        inputPhoneNumberFormat(e, "phoneNumber", handler.form);
                      }}
                    />
                  </Form.Item>
                </div>
                <div className="item-info-ic2">
                  <div
                    className="label-text"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    FAX
                  </div>
                  <Form.Item
                    style={{ margin: 0 }}
                    name="fax"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (value && value !== "") {
                            value = value.trim().replaceAll(" ", "");
                            value = value.replaceAll("-", "");
                            if (!REGEX.PHONENUMBER_2.test(value))
                              return Promise.reject(
                                MESSAGE.MESSAGE_WRONG_FAX_FORMAT
                              );
                            if (value?.length < 10) {
                              return Promise.reject(
                                MESSAGE.MESSAGE_INVALID_INPUT
                              );
                            }
                            if (
                              value?.length >
                              COMMON.MAX_LENGTH_PHONE_NUMBER - 2
                            ) {
                              return Promise.reject(
                                MESSAGE.MESSAGE_VALIDATE_FAX
                              );
                            }
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input
                      style={{ height: 40, width: 375 }}
                      autoComplete="off"
                      size="large"
                      placeholder="例：0012345678　ハイフン(ー)なし　半角"
                      maxLength={24}
                      onKeyPress={typeInteger}
                      onPaste={typeInteger}
                      onChange={(e) => {
                        helpers.preventInputJapanese(e);
                        inputPhoneNumberFormat(e, "fax", handler.form);
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
            </Col>
            <Col>
              <div
                className="content-wrapper-ic1"
                style={{ display: "flex", flexDirection: "column", gap: 16 }}
              >
                <div className="item-info-ic2" style={{ height: 40 }}>
                  <div
                    className="label-text"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    ID
                  </div>
                  <div
                    className="info-text"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {handler?.profileCompany?.adminName || "-"}
                  </div>
                </div>
                <div className="item-info-ic2">
                  <div
                    className="label-text"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    アドミン名<span className="label-required">*</span>
                  </div>
                  <Form.Item
                    style={{ margin: 0 }}
                    name="adminFullName"
                    rules={[
                      validateInput({
                        maxLength: 50,
                        required: true,
                        regex: REGEX.NOT_VIETNAM,
                      }),
                    ]}
                  >
                    <Input
                      style={{ height: 40, width: 375 }}
                      autoComplete="off"
                      size="large"
                      maxLength={50}
                      onBlur={() =>
                        onBlurTrimFormItem(handler.form, "adminFullName")
                      }
                      onChange={(e) => {
                        helpers.preventInputJapanese(e);
                      }}
                    />
                  </Form.Item>
                </div>
                <div className="item-info-ic2" style={{ height: 40 }}>
                  <div
                    className="label-text"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    メールアドレス
                  </div>
                  <div
                    className="info-text"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {handler?.profileCompany?.email || "-"}
                  </div>
                </div>
                <div className="item-info-ic2">
                  <div
                    className="label-text"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    CCUS事業者ID
                  </div>
                  <Form.Item
                    style={{ margin: 0 }}
                    name="ccusId"
                    rules={[
                      () => ({
                        validator(_, value) {
                          const expirationDate =
                            handler.form.getFieldValue("ccusExpiredDate");
                          value = value ? value.replaceAll(" ", "") : "";
                          if (
                            (!value && expirationDate) ||
                            (value && value.length < MAX_LENGTH.CCUSID) ||
                            (value &&
                              !REGEX.PHONENUMBER.test(
                                value.replaceAll(" ", "")
                              ))
                          )
                            return Promise.reject(MESSAGE.MESSAGE_LESS_THAN_14);
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input
                      style={{ height: 40, width: 375 }}
                      onKeyPress={typeInteger}
                      onInput={(e: React.FormEvent<HTMLInputElement>) => {
                        const inputElement = e.currentTarget;
                        const inputValue = inputElement.value;
                        const filteredValue = inputValue.replace(/[^0-9]/g, "");
                        inputElement.value = filteredValue.slice(0, 14);
                      }}
                      onChange={(e) => {
                        helpers.preventInputJapanese(e);
                        helpers.inputCCUSFormat(e, "ccusId", handler.form);
                      }}
                      maxLength={MAX_LENGTH.FORMAT_CCUSID}
                      placeholder="xxxx xxxx xxxx xx"
                      disabled={
                        handler.profileCompany.state ==
                        CCUS_VERRIFICATION_STATE.DONE
                      }
                    />
                  </Form.Item>
                  <div
                    style={{
                      margin: "0 24px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    有効期限
                  </div>
                  <div className="info-text">
                    <Form.Item style={{ margin: 0 }} name="ccusExpiredDate">
                      <DatePicker
                        inputReadOnly
                        style={{ width: 174, height: 40 }}
                        locale={locale}
                        format={COMMON.FORMAT_DATE_CM}
                        placeholder="[ ---- / -- / -- ]"
                        suffixIcon={
                          <img
                            alt=""
                            src={CalendarSvg}
                            className="icon-calender"
                          />
                        }
                        getPopupContainer={(trigger: any) => trigger.parentNode}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </Col>
          </div>
        </Form>
      </div>
      <div className="ic2-btn-wrapper">
        <Button
          htmlType="submit"
          form="formEditProfileCompany"
          size="large"
          className="button-purple"
          style={{ width: 76, letterSpacing: -1.5 }}
        >
          保存
        </Button>
        <Button
          size="large"
          style={{
            marginLeft: 16,
            width: 118,
            fontWeight: 400,
            fontSize: 14,
            lineHeight: 22,
            color: "#666666",
          }}
          onClick={() => handler.onCancel()}
        >
          キャンセル
        </Button>
      </div>
    </div>
  );
};

export default EditInformation;
