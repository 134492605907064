
import COMMON from "../../../../common/constants/COMMON";
import axiosConfig from "../../../../config/axiosConfig";
import { TodoEntity } from "../../entity/TodoEntity";

const EXAMPLE_API = "https://jsonplaceholder.typicode.com";

const TodoApi = () => {
  const getTodos = async (): Promise<TodoEntity[]> => {
    try {
      const response = await axiosConfig.get(`${EXAMPLE_API}/todos`);
      if (response.status === COMMON.HTTP_STATUS_OK) {
        return response.data
      } else {
        return [];
      }
    } catch (error) {
      return [];
    }
  };
  return {
    getTodos,
  };
};

export default TodoApi;
