import React from 'react'
import ClearInputSVG from "../../../../../assets/icons/clear-input.svg"
import LightDeleteSVG from "../../../../../assets/icons/light-delete.svg"

import { Button, Form, FormInstance, Input } from 'antd'
import MESSAGE from '../../../../../common/constants/MESSAGE'
import helpers from '../../../../../common/helpers/common'
import { ScheduleCategory } from '../../entity/Entity'

interface props {
    data: ScheduleCategory,
    form: FormInstance<any>,
    deleteRow: (value: ScheduleCategory) => void,

}

export const FormRow = ({ form, data, deleteRow, }: props) => {
    return <div className='form-row-container'>
        <div className='color-view-container' style={{ background: data.color.colorRgb }}></div>
        <Form.Item hidden name={[`category${data.id}`, 'id']} ><Input /></Form.Item>
        <Form.Item hidden name={[`category${data.id}`, 'colorId']} ><Input /></Form.Item>
        <Form.Item
            style={{ margin: 0, padding: 0 }}
            name={[`category${data.id}`, 'name']}
            className="form-item-label "
        >
            <Input
                placeholder='カテゴリー名を入力してください'
                style={{ height: 40, width: 389 }}
                maxLength={100}
                onBlur={() =>
                    helpers.onBlurTrimFormItem(form, `${data.id}`)
                }
                allowClear={{ clearIcon: <img alt='' src={ClearInputSVG} /> }}
            />
        </Form.Item>
        {
            !data.isDefault && data.isDeletable
                ? <Button
                    style={{ width: 22 }}
                    icon={
                        <img alt='' src={LightDeleteSVG} />
                    }
                    type="text"
                    onClick={() => deleteRow(data)}
                /> :
                <div style={{ width: 22 }}></div>
        }

    </div >
}