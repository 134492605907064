import moment from "moment";
import helpers from "../../../common/helpers/common";
import { TableDataEntity, TableSummaryData } from "./entity/Entity";

const convertChildrenTable = (data: any[], tax: number) => {
  let originColumns: any = [
    {
      key: "constructionName",
      title: "工事名",
      dataIndex: "constructionName",
      fixed: "left",
      width: 350,
      align: "left",
      onCell: (record: any) => {
        return {
          className:
            record?.level === 1 ? "cm019-td-level-1" : "cm019-td-level-2",
        };
      },
    },
  ];

  let origin: any = [
    {
      key: Math.random(),
      constructionName: "入金額",
      level: 1,
    },
    {
      key: Math.random(),
      constructionName: "支払金額",
      level: 1,
    },
    {
      key: Math.random(),
      constructionName: "材料費",
      level: 2,
    },
    {
      key: Math.random(),
      constructionName: "外注費",
      level: 2,
    },
    {
      key: Math.random(),
      constructionName: "労務費",
      level: 2,
    },
    {
      key: Math.random(),
      constructionName: "間接工事費",
      level: 2,
    },
    {
      key: Math.random(),
      constructionName: "粗利",
      level: 1,
    },
  ];

  let totalTableDeposit = 0;
  let totalTableMaterial = 0;
  let totalTableOutsourcing = 0;
  let totalTableLabor = 0;
  let totalTableIndirect = 0;
  let totalGross = 0;

  // Sort data

  data.sort((a, b: any) => {
    return moment(b.createdAt).diff(a.createdAt);
  });

  data.forEach((item: any, idx: number) => {
    const total4 =
      item.materialCost +
      item.outsourcingCost +
      item.laborCost +
      item.indirectCost;

    totalTableDeposit += item.depositAmount;
    totalTableMaterial += item.materialCost;
    totalTableOutsourcing += item.outsourcingCost;
    totalTableLabor += item.laborCost;
    totalTableIndirect += item.indirectCost;
    totalGross += item.depositAmount - total4;

    origin[0][`construction${idx + 1}`] =
      item.depositAmount + tax * item.depositAmount;
    origin[1][`construction${idx + 1}`] = total4 + tax * total4;
    origin[2][`construction${idx + 1}`] =
      item.materialCost + tax * item.materialCost;
    origin[3][`construction${idx + 1}`] =
      item.outsourcingCost + tax * item.outsourcingCost;
    origin[4][`construction${idx + 1}`] = Math.floor(
      item.laborCost + tax * item.laborCost
    );
    origin[5][`construction${idx + 1}`] = Math.floor(
      item.indirectCost + tax * item.indirectCost
    );
    origin[6][`construction${idx + 1}`] = Math.floor(
      item.depositAmount - total4 + tax * (item.depositAmount - total4)
    );

    // Add columns

    if (data.length > 6) {
      originColumns.push({
        key: `${item.constructionName}`,
        title: () =>
          helpers.getShortTextWithToolTip(item.constructionName ?? "", 10),
        dataIndex: `construction${idx + 1}`,
        width: 193,
        render: (text: any) => {
          if (!text) return "-";
          return `${helpers.formatCurrency(text, true)}円`;
        },
      });
    } else {
      originColumns.push({
        key: `${item.constructionName}`,
        title: () =>
          helpers.getShortTextWithToolTip(item.constructionName ?? "", 10),
        dataIndex: `construction${idx + 1}`,
        // width: data.length > 6 ? 193 : null,
        render: (text: any) => {
          if (!text) return "-";
          return `${helpers.formatCurrency(text, true)}円`;
        },
      });
    }
  });

  return [
    origin,
    originColumns,
    totalTableDeposit,
    totalTableMaterial,
    totalTableOutsourcing,
    totalTableLabor,
    totalTableIndirect,
    totalGross,
  ];
};

const convertDataTable = (data: any[], tax: number): TableDataEntity[] => {
  const output: TableDataEntity[] = [];

  data.forEach((item: any, idx: number) => {
    const cv = convertChildrenTable(item?.children ?? [], tax);

    const obj: TableDataEntity = {
      id: item.id,
      projectName: item?.projectName ?? "",
      children: cv[0],
      columns: cv[1],
      totalDeposit: cv[2] + tax * cv[2],
      totalMaterial: cv[3] + tax * cv[3],
      totalOutsourcing: cv[4] + tax * cv[4],
      totalLabor: cv[5] + tax * cv[5],
      totalIndirect: cv[6] + tax * cv[6],
      totalGross: cv[7] + tax * cv[7],
    };
    output.push(obj);
  });

  return output;
};

const convertSummaryData = (item: any, tax: number): TableSummaryData => {
    return {
        depositAmount: item?.depositAmount + (tax * item?.depositAmount) ?? 0,
        materialAmount: item?.materialCost + (tax * item?.materialCost) ?? 0,
        outsourcingAmount: item?.outsourcingCost + (tax * item?.outsourcingCost) ?? 0,
        laborAmount: item?.laborCost + (tax * item?.laborCost) ?? 0,
        indirectAmout: item?.indirectCost + (tax * item?.indirectCost) ?? 0,
        grossAmount:
            item.depositAmount -
            (item.materialCost + item.outsourcingCost + item.laborCost + item.indirectCost)
            + (tax * (item.depositAmount -
                (item.materialCost + item.outsourcingCost + item.laborCost + item.indirectCost)))
        ,
    };
};

const findObject = (constructionName: string, items: any[]) => {
  return items.find((ele: any) => ele.constructionName === constructionName);
};

const convertDataExport = (data: any[]) => {
  let summary: string[] = [];

  let totalDeposit = 0;
  let totalMaterial = 0;
  let totalOutsourcing = 0;
  let totalLabor = 0;
  let totalIndirect = 0;
  let totalGross = 0;
  let totalOther = 0;

  const _data: any[] = [];

  data.forEach((item: any, idx: number) => {
    totalDeposit += item.totalDeposit;
    totalMaterial += item.totalMaterial;
    totalOutsourcing += item.totalOutsourcing;
    totalLabor += item.totalLabor;
    totalIndirect += item.totalIndirect;
    totalGross += item.totalGross;

    summary = [
      `${idx + 1}`,
      item.projectName,
      "",
      `${helpers.formatCurrency(item.totalDeposit)}円`,
      `${helpers.formatCurrency(item.totalMaterial)}円`,
      `${helpers.formatCurrency(item.totalOutsourcing)}円`,
      `${helpers.formatCurrency(item.totalLabor)}円`,
      `${helpers.formatCurrency(item.totalIndirect)}円`,
      "",
      `${helpers.formatCurrency(item.totalGross)}円`,
    ];

    const contents: string[] = [];

    item.columns
      .filter((a: any) => a.key !== "constructionName")
      .forEach((ele: any, idx: number) => {
        totalOther += findObject("支払金額", item.children)[`${ele.dataIndex}`];

        const obj: any = {
          a: `${idx + 1}`,
          b: `${ele.key}`,
          c: "",
          d: `${helpers.formatCurrency(
            findObject("入金額", item.children)[`${ele.dataIndex}`]
          )}円`,
          e: `${helpers.formatCurrency(
            findObject("材料費", item.children)[`${ele.dataIndex}`]
          )}円`,
          f: `${helpers.formatCurrency(
            findObject("外注費", item.children)[`${ele.dataIndex}`]
          )}円`,
          g: `${helpers.formatCurrency(
            findObject("労務費", item.children)[`${ele.dataIndex}`]
          )}円`,
          h: `${helpers.formatCurrency(
            findObject("間接工事費", item.children)[`${ele.dataIndex}`]
          )}円`,
          i: `${helpers.formatCurrency(
            findObject("支払金額", item.children)[`${ele.dataIndex}`]
          )}円`,
          j: `${helpers.formatCurrency(
            findObject("粗利", item.children)[`${ele.dataIndex}`]
          )}円`,
        };
        contents.push(obj);
      });

    _data.push({
      header: [
        "No",
        "案件名",
        "契約金額",
        "入金額合計",
        "材料費",
        "外注費",
        "労務費",
        "間接工事費",
        "支払金額合計",
        "粗利",
      ],
      header_child: [
        "No",
        "工事名",
        "契約金額",
        "入金額合計",
        "材料費",
        "外注費",
        "労務費",
        "間接工事費",
        "支払金額合計",
        "粗利",
      ],
      summary: summary,
      contents: contents,
    });
  });

  const output: any = {
    data: _data,
    footers: [
      "合計金額",
      "合計金額",
      "",
      `${helpers.formatCurrency(totalDeposit)}円`,
      `${helpers.formatCurrency(totalMaterial)}円`,
      `${helpers.formatCurrency(totalOutsourcing)}円`,
      `${helpers.formatCurrency(totalLabor)}円`,
      `${helpers.formatCurrency(totalIndirect)}円`,
      `${helpers.formatCurrency(totalOther)}円`,
      `${helpers.formatCurrency(totalGross)}円`,
    ],
  };

  return output;
};

export { convertDataTable, convertSummaryData, convertDataExport };
