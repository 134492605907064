import { Button, DatePicker, Empty, Form, Popover, Select } from "antd";
import CalendarSvg from "../../../../../../../assets/icons/calendar.svg";
import locale from "antd/es/date-picker/locale/ja_JP";
import COMMON, {
  LABEL_APPROVER,
  LABEL_COMPANY,
  LABEL_PROJECT,
  LABEL_REGISTER_TYPE,
  NODATA_TEXT,
} from "../../../../../../../common/constants/COMMON";
import moment from "moment";
import React from "react";
import { RangeDateType } from "../../../../../../AM/AM002/entity/Entity";
import { MENU_ITEM, PARAMS, REGISTER_TYPE } from "../../../../constants";
import { FilterCCUSType } from "../../../../entity/Entity";
import { useSearchParams } from "react-router-dom";
import { FormInstance } from "antd";

type Props = {
  onResetFilter: () => void;
  onFromDateChange: (value: any) => void;
  onToDateChange: (value: any) => void;
  handleGetProject: () => void;
  handleScrollProject: (e: any) => void;
  handleGetCompany: () => void;
  handleScrollCompany: (e: any) => void;
  handleGetApprover: () => void;
  handleScrollApprover: (e: any) => void;
  onChangeProject: (value: string) => void;
  onChangeCompany: (value: string) => void;
  onChangeApprover: (value: string) => void;

  isCollapsed: boolean;
  rangeDate: RangeDateType;
  listProject: FilterCCUSType[];
  listCompany: FilterCCUSType[];
  listApprover: FilterCCUSType[];
  loadingProject: boolean;
  loadingCompany: boolean;
  loadingApprover: boolean;
  form: FormInstance<any>;
};

const FilterComponent = ({
  onResetFilter,
  onFromDateChange,
  onToDateChange,
  handleGetProject,
  handleGetCompany,
  handleGetApprover,
  handleScrollProject,
  handleScrollCompany,
  handleScrollApprover,
  onChangeProject,
  onChangeCompany,
  onChangeApprover,
  rangeDate,
  listProject,
  listCompany,
  listApprover,
  loadingProject,
  loadingCompany,
  loadingApprover,
  form,
}: Props) => {
  const [searchParams] = useSearchParams();

  const state = searchParams.get(PARAMS.state) || MENU_ITEM[0].code;
  const isCollapsed = searchParams.get("isCollapsed") ? true : false;
  const content = (
    <React.Fragment>
      <div>
        <label className="filter-label">{LABEL_PROJECT}</label>
        <div className="item-filter-container-ccus061">
          <Form.Item
            name="project"
            className="item-filter-child"
            labelAlign="right"
          >
            <Select
              size="large"
              listHeight={130}
              filterOption={() => true}
              loading={loadingProject}
              onDropdownVisibleChange={(open) => {
                if (open) handleGetProject();
              }}
              onPopupScroll={handleScrollProject}
              getPopupContainer={(trigger: any) => trigger.parentNode}
              notFoundContent={
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={NODATA_TEXT}
                />
              }
              onChange={onChangeProject}
              allowClear
            >
              {listProject.map((select: FilterCCUSType) => {
                return (
                  <Select.Option
                    style={{ alignItems: "center" }}
                    key={select.id}
                    value={select.id}
                  >
                    {select.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </div>
      </div>

      <div>
        <label className="filter-label">{LABEL_COMPANY}</label>
        <div className="item-filter-container-ccus061">
          <Form.Item
            name="company"
            className="item-filter-child"
            labelAlign="right"
          >
            <Select
              size="large"
              listHeight={130}
              filterOption={() => true}
              loading={loadingCompany}
              onDropdownVisibleChange={(open) => {
                if (open) handleGetCompany();
              }}
              onPopupScroll={handleScrollCompany}
              getPopupContainer={(trigger: any) => trigger.parentNode}
              notFoundContent={
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={NODATA_TEXT}
                />
              }
              allowClear
              onChange={onChangeCompany}
            >
              {listCompany.map((select: FilterCCUSType) => {
                return (
                  <Select.Option
                    style={{ alignItems: "center" }}
                    key={select.id}
                    value={select.id}
                  >
                    {select.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </div>
      </div>

      <div>
        <label className="filter-label">{LABEL_APPROVER}</label>
        <div className="item-filter-container-ccus061">
          <Form.Item
            name="approver"
            className="item-filter-child"
            labelAlign="right"
          >
            <Select
              size="large"
              listHeight={130}
              filterOption={() => true}
              loading={loadingApprover}
              onDropdownVisibleChange={(open) => {
                if (open) handleGetApprover();
              }}
              onPopupScroll={handleScrollApprover}
              getPopupContainer={(trigger: any) => trigger.parentNode}
              notFoundContent={
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={NODATA_TEXT}
                />
              }
              allowClear
              onChange={onChangeApprover}
            >
              {listApprover.map((select: FilterCCUSType) => {
                return (
                  <Select.Option
                    style={{ alignItems: "center" }}
                    key={select.id}
                    value={select.id}
                  >
                    {select.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </div>
      </div>

      <div>
        <label className="filter-label">開始日</label>
        <div className="item-filter-container-ccus061">
          <Form.Item name={"startDate"} className="item-filter-child">
            <DatePicker
              allowClear
              onChange={onFromDateChange}
              disabledDate={(value) =>
                form.getFieldValue("endDate") &&
                value > form.getFieldValue("endDate").endOf("day")
              }
              format={(value: moment.Moment) => {
                const currentTime = moment(value, true).isValid()
                  ? moment(value).format(COMMON.FORMAT_DATE_OM)
                  : "";
                const isToday = moment().isSame(currentTime, "day");
                if (isToday) return COMMON.TODAY;

                return currentTime;
              }}
              className="ci001-date-picker w-100-percent"
              inputReadOnly
              locale={locale}
              getPopupContainer={(trigger: any) => trigger.parentNode}
              placeholder=""
              suffixIcon={
                <img alt="" src={CalendarSvg} className="icon-calender" />
              }
            />
          </Form.Item>
        </div>
      </div>
      <div>
        <label className="filter-label">終了日</label>
        <div className="item-filter-container-ccus061">
          <Form.Item name={"endDate"} className="item-filter-child">
            <DatePicker
              allowClear
              onChange={onToDateChange}
              disabledDate={(value) => value < form.getFieldValue("startDate")}
              format={(value: moment.Moment) => {
                const currentTime = moment(value, true).isValid()
                  ? moment(value).format(COMMON.FORMAT_DATE_OM)
                  : "";
                const isToday = moment().isSame(currentTime, "day");
                if (isToday) return COMMON.TODAY;

                return currentTime;
              }}
              className="ci001-date-picker w-100-percent"
              inputReadOnly
              locale={locale}
              getPopupContainer={(trigger: any) => trigger.parentNode}
              placeholder=""
              suffixIcon={
                <img alt="" src={CalendarSvg} className="icon-calender" />
              }
            />
          </Form.Item>
        </div>
      </div>

      {state === MENU_ITEM[0].code ? (
        <>
          <div>
            <label className="filter-label">登録日</label>
            <div className="item-filter-container-ccus061">
              <Form.Item
                name={"registrationDate"}
                className="item-filter-child"
              >
                <DatePicker
                  allowClear
                  format={(value: moment.Moment) => {
                    const isToday = moment().isSame(value, "day");
                    if (isToday) return COMMON.TODAY;
                    return value.format(COMMON.FORMAT_DATE_OM);
                  }}
                  className="ci001-date-picker w-100-percent"
                  inputReadOnly
                  locale={locale}
                  getPopupContainer={(trigger: any) => trigger.parentNode}
                  placeholder=""
                  suffixIcon={
                    <img alt="" src={CalendarSvg} className="icon-calender" />
                  }
                />
              </Form.Item>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      <div>
        <label className="filter-label">{LABEL_REGISTER_TYPE}</label>
        <div className="item-filter-container-ccus061">
          <Form.Item
            name="registerType"
            className="item-filter-child"
            labelAlign="right"
          >
            <Select
              size="large"
              filterOption={() => true}
              getPopupContainer={(trigger: any) => trigger.parentNode}
              placement="topRight"
              allowClear
            >
              {REGISTER_TYPE.map((select: { code: string; name: string }) => {
                return (
                  <Select.Option
                    style={{ alignItems: "center" }}
                    key={select.code}
                    value={select.code}
                  >
                    {select.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </div>
      </div>

      <div className="btn-filter-container">
        <Button
          htmlType="submit"
          size="large"
          className="btn-filter-width"
          type="primary"
        >
          絞込み
        </Button>
        <Button
          className="reset-filter-btn btn-filter-width"
          onClick={onResetFilter}
          size="large"
        >
          クリア
        </Button>
      </div>
    </React.Fragment>
  );

  return (
    <main
      style={{
        height: "100%",
        paddingTop: 24,
        display: "flex",
        flexDirection: "column",
        gap: isCollapsed ? "8px" : "24px",
      }}
    >
      {content}
    </main>
  );
};

export default FilterComponent;
