import { ReceivedMoneyOffset } from '../entity/Entity';
import { CM0063Api } from './ServiceImpl';

const CM0063Service = (cm0063: CM0063Api) => {
    const getListReceivedMoneyOffset = async (params: any): Promise<any> => {
        try {
            return await cm0063.getListReceivedMoneyOffset(params);
        } catch (error) {
            throw error;
        }
    };

    const getSubCategory = async (): Promise<any> => {
        try {
            return await cm0063.getSubCategory();
        } catch (error) {
            throw error;
        }
    };

    const createReceivedMoneyOffset = async (data: any): Promise<any> => {
        try {
            return await cm0063.createReceivedMoneyOffset(data);
        } catch (error) {
            throw error;
        }
    };

    const deleteReceivedMoneyOffset = async (data: any): Promise<any> => {
        try {
            return await cm0063.deleteReceivedMoneyOffset(data);
        } catch (error) {
            throw error;
        }
    };

    const editReceivedMoneyOffset = async (data: any): Promise<any> => {
        try {
            return await cm0063.editReceivedMoneyOffset(data);
        } catch (error) {
            throw error;
        }
    };

    return {
        getListReceivedMoneyOffset,
        getSubCategory,
        createReceivedMoneyOffset,
        deleteReceivedMoneyOffset,
        editReceivedMoneyOffset,
    };
};

export default CM0063Service;
