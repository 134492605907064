import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import PaginationComponent from "../../../../../common/components/pagination/PaginationComponent";
import { RootState } from "../../../../../store";
import { PM002PaginationHandler } from "../handler/HandlerImpl";
interface props {
  handler: PM002PaginationHandler;
}

const PM002Pagination = ({ handler }: props) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const page = params.get("page");
  const total = useSelector((state: RootState) => state.pm002.total);
  const data = useSelector((state: RootState) => state.pm002.data);

  return data && data?.length > 0 ? (
    <div style={{ paddingBottom: 42 }}>
      <PaginationComponent
        disabled={data.length !== 0 ? false : true}
        total={total}
        page={parseInt(page || "1")}
        size={6}
        setPage={handler.onPageChange}
      />
    </div>
  ) : null;
};
export default PM002Pagination;
