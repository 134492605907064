import { useDispatch, useSelector } from "react-redux";
import { useRef, useState, useMemo } from "react";
import { CR001MailContractorsUseCase } from "../../usecase/ServiceImpl";
import { RootState } from "../../../../../store";
import { setHideModalCR001, setSelectedRowKeys } from "../slice/Slice";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../../common/constants/MESSAGE";
import ConfirmModal from "../../../../../common/components/modal/ConfirmModal";
import { COMPANY_COLAB_STATUS } from "../../../../../common/constants/COMMON";
import { refreshCR003 } from "../../../CR003/presenter/slice/Slice";

const CR002Handler = (
  cr001MailContractorService: CR001MailContractorsUseCase,
  afterSendMail?: (keyItem: React.Key[]) => void
) => {
  const dispatch = useDispatch();
  const loading = !!useSelector((state: RootState) => state.common.loading);
  const isVisible = useSelector(
    (state: RootState) => state.cr001MailContractor.isVisible
  );
  const selectedRowKeys = useSelector(
    (state: RootState) => state.cr001MailContractor.selectedRowKeys
  );
  const listCompany = useSelector(
    (state: RootState) => state.cr001MailContractor.listCompany
  );
  const total = useSelector(
    (state: RootState) => state.cr001MailContractor.total
  );

  //state
  const [isViewDataAfter, setViewDataAfter] = useState(false);

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  const resetState = () => {
    setViewDataAfter(false);
    setPage(1);
    setSize(10);
  };

  const handleChangePage = (page: number) => {
    setPage(page);
    dispatch(setSelectedRowKeys([]));
  };
  const handleChangeSize = (size: number) => {
    setSize(size);
    setPage(1);
    dispatch(setSelectedRowKeys([]));
  };

  const handlerChangeRow = (
    selectedRowKeys: React.Key[],
    selectedRows: {
      key: number;
      contractorName: string;
      companyName: string;
      email: string;
    }[]
  ) => {
    dispatch(setSelectedRowKeys(selectedRowKeys));
  };

  const handleCancel = () => {
    if (isViewDataAfter) {
      resetState();
    } else {
      dispatch(setHideModalCR001());
      resetState();
    }
  };
  const handleCloseModal = () => {
    dispatch(setHideModalCR001());
    resetState();
  };

  const handleConfirmChoose = () => {
    setViewDataAfter(true);
  };
  const handlerConfirmData = async () => {
    dispatch(setLoading(true));
    try {
      const dataRaw = listCompany.filter((data: { id: number }) =>
        selectedRowKeys.includes(data.id)
      );
      const deactiveData: any[] = [];
      const activeData: any[] = [];
      dataRaw.forEach((element) => {
        if (element?.status?.code === COMPANY_COLAB_STATUS.DEACTIVE_PLAN) {
          deactiveData.push({
            name: element.name,
            email: element.email,
            outsourceRegisterCostId: element.id,
            constructionName: element.constructionNameChild,
          });
        } else if (element?.status?.code === COMPANY_COLAB_STATUS.ACTIVE_PLAN) {
          activeData.push({
            name: element.name,
            email: element.email,
            outsourceRegisterCostId: element.id,
            constructionName: element.constructionNameChild,
          });
        }
      });
      const callAPI: any[] = [];

      if (activeData.length > 0)
        callAPI.push(
          cr001MailContractorService.addCompanyForList({ data: activeData })
        );

      if (deactiveData.length > 0)
        callAPI.push(
          cr001MailContractorService.postInviteConstructionContractorSendEmail({
            data: deactiveData,
          })
        );
      const response = await Promise.all(callAPI);
      const havingMessage = response.find((element) => !!element?.message);

      const successMessage: string = havingMessage
        ? havingMessage.message
        : NOTIFICATION_TITLE.MESSAGE_DELETE_NOTIFICATION_SUCCESS;

      afterSendMail && afterSendMail(selectedRowKeys);
      dispatch(refreshCR003());
      dispatch(setHideModalCR001());
      SuccessNotification(successMessage);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const dataSource = useMemo(() => {
    const dataRaw = listCompany.map((element) => {
      return {
        ...element,
        key: element.id,
      };
    });
    if (!dataRaw || dataRaw?.length === 0) return [];

    const dataHandleSizePage = dataRaw.slice((page - 1) * size, page * size);

    if (!isViewDataAfter) return dataHandleSizePage;
    return dataHandleSizePage?.filter((data: { key: number }) =>
      selectedRowKeys.includes(data.key)
    );
  }, [listCompany, selectedRowKeys, isViewDataAfter, size, page]);

  return {
    loading,
    isVisible,
    isViewDataAfter,
    dataSource,
    page,
    size,
    total,
    selectedRowKeys,

    resetState,
    handleChangePage,
    handleChangeSize,
    handlerChangeRow,
    handleCancel,
    handleConfirmChoose,
    handlerConfirmData,
    handleCloseModal,
  };
};

export default CR002Handler;
