import moment from "moment";
import COMMON from "../../../common/constants/COMMON";

export const STATE_PENDING = "pending"

export const REGISTER_TYPE = [
    {
        code: 'create',
        name: '新規登録',
    },
    {
        code: 'update',
        name: '更新',
    },
];

export const MENU_ITEM = [
    {
        name: "登録済み",
        code: "success"
    },
    {
        name: "登録待ち",
        code: "initial"
    },
    {
        name: "登録エラー",
        code: "error"
    },
];

export const PARAMS = {
    state: "state",
    page: "page",
    size: "size",
    projectId: "projectId",
    companyId: "companyId",
    approverId: "approverId",
    startDate: "startDate",
    endDate: "endDate",
    syncTime: "syncTime",
    registerType: "registerType",
    sortType: "sortType",
    sortBy: "sortBy",
};

export const DEFAULT_PARAM = {
    state: "success",
    page: 1,
    size: 10,
    projectId: "",
    companyId: "",
    approverId: "",
    startDate: moment().startOf("month"),
    endDate: moment(),
    syncTime: moment(),
    registerType: "create",
    sortType: "",
    sortBy: "ASC",
};
export const getTitleByFromToDate = (fromDate: any, toDate: any) =>
    !fromDate && !toDate
        ? `${moment().startOf('month').format(COMMON.FORMAT_DATE_OM)}～${moment().format(
            COMMON.FORMAT_DATE_OM,
        )}`
        : `${fromDate === ''
            ? ''
            : fromDate === null
                ? moment().startOf('month').format(COMMON.FORMAT_DATE_OM)
                : moment(fromDate).format(COMMON.FORMAT_DATE_OM)
        }～${toDate ? moment(toDate).format(COMMON.FORMAT_DATE_OM) : ''}`;
