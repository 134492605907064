import { CM0027_2SliceType } from './../type/Presenter';
import { createSlice } from '@reduxjs/toolkit';

const initialState: CM0027_2SliceType = {
    loading: false,
    isVisible: false,
    refresh: false,
    typeModal: false,
    parentCode: {
        documentId: 108,
        companyId: 108,
        categoryId: 13,
    },
    isUpdate: true,
    modalTitle: null,
};

export const cm0027_2Slice = createSlice({
    name: 'cm0027_2',
    initialState,
    reducers: {
        resetCM0027_2: () => initialState,
        setLoadingCM0027_2: (state, action) => {
            state.loading = action.payload;
        },
        setIsVisibleCM0027_2: (state, action) => {
            state.isVisible = action.payload;
        },
        setRefreshCM0027_2: (state) => {
            state.refresh = !state.refresh;
        },
        setTypeModalCM0027_2: (state, action) => {
            state.typeModal = action.payload;
        },
        setParentCodeCM0027_2: (state, action) => {
            state.parentCode = action.payload;
        },
        setIsUpdate: (state, action) => {
            state.isUpdate = action.payload;
        },
        setModalTitleCM0027_2: (state, action) => {
            state.modalTitle = action.payload;
        },
    },
});

export const {
    setIsUpdate,
    setLoadingCM0027_2,
    setIsVisibleCM0027_2,
    resetCM0027_2,
    setRefreshCM0027_2,
    setTypeModalCM0027_2,
    setParentCodeCM0027_2,
    setModalTitleCM0027_2,
} = cm0027_2Slice.actions;

export default cm0027_2Slice.reducer;
