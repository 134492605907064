import { AxiosResponse } from "axios";
import axiosConfig from "../../../../../config/axiosConfig";
import { ReSummaryCompanyProjectOutsourceCostRetrievePayload } from "../../entity/Entity";

const CM0021API = () => {
  const getReSummarySubmittedMaterialCostCompany = async (params: {
    documentId: number;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/re-summary/material-cost/company/list";
    return await axiosConfig.get(url, { params });
  };
  const getReSummarySubmittedMaterialCostCompanyProject = async (params: {
    documentCompanyId: number;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/re-summary/material-cost/company/project/list";
    return await axiosConfig.get(url, { params });
  };
  const getReSummarySubmittedMaterialCostCompanyProjectConstruction =
    async (params: {
      documentProjectId: number;
      page?: number;
      size?: number;
    }): Promise<AxiosResponse<any, any>> => {
      const url = "/re-summary/material-cost/company/project/construction/list";
      return await axiosConfig.get(url, { params });
    };
  const putReSummaryMaterialCostCompanyRetrieve = async (
    data: ReSummaryCompanyProjectOutsourceCostRetrievePayload
  ): Promise<AxiosResponse<any, any>> => {
    const url = "/re-summary/material-cost/company/retrieve";
    return await axiosConfig.put(url, data);
  };
  const getWorkflowSummaryMaterialCompanyApprovalProcess = async (params: {
    documentId: number;
  }): Promise<any> => {
    const url = "/workflow/summary/material/company/approval-process";
    return await axiosConfig.get(url, { params });
  };
  return {
    getReSummarySubmittedMaterialCostCompany,
    getReSummarySubmittedMaterialCostCompanyProject,
    getReSummarySubmittedMaterialCostCompanyProjectConstruction,
    putReSummaryMaterialCostCompanyRetrieve,
    getWorkflowSummaryMaterialCompanyApprovalProcess,
  };
};

export default CM0021API;
