import { useState } from 'react';
import { FormInstance } from 'antd';

const useCertificationViewComponent = () => {
    const [lines, setLines] = useState<any[]>([]);
    const [maxKey, setMaxKey] = useState(1);

    const addLine = () => {
        setLines([...lines, maxKey + 1]);
        setMaxKey(maxKey + 1);
    };

    const removeLine = (value: number) => {
        const cloneLineQuantity = [...lines];
        const index = cloneLineQuantity.findIndex((element) => element.id === value);
        if (index > -1) cloneLineQuantity.splice(index, 1);
        setLines(cloneLineQuantity);
    };

    const setValidExpireDate = (form: FormInstance, cert: string) => {
        form.setFields([
            {
                name: [cert, 'expireDate'],
                errors: [],
            },
        ]);
    };
    const setValidIssueDate = (form: FormInstance, cert: string) => {
        form.setFields([
            {
                name: [cert, 'issueDate'],
                errors: [],
            },
        ]);
    };

    return {
        setMaxKey,
        setLineQuantity: setLines,
        addLine,
        removeLine,
        setValidIssueDate,
        setValidExpireDate,
        lineQuantity: lines,
    };
};

export default useCertificationViewComponent;
