import { useDispatch, useSelector } from "react-redux";
import { useRef, useState } from "react";
import { PM024UseCase } from "../../usecase/ServiceImpl";
import { setIsVisiblePM013 } from "../../../PM013/presenter/slice/Slice";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../../common/constants/MESSAGE";
import { Form } from "antd";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import { RootState } from "../../../../../store";
import {
  resetStatePM024,
  setChangeChosenCompany,
  setListCompany,
  setViewChooseCompany,
} from "../slice/Slice";

const PM024Handler = (pm024Service: PM024UseCase) => {
  const dispatch = useDispatch();
  const size = 10;
  const refAuto: any = useRef();

  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [keywordMaster, setKeywordMaster] = useState("");
  const [form] = Form.useForm();
  const refCompany: any = useRef(null);
  const [isAddCompany, setIsAddCompany] = useState(false);
  const [refreshModal, setRefreshModal] = useState(false);
  const [companyMasters, setCompanyMasters] = useState<any[]>([]);

  //pagination
  const [page, setPage] = useState(1);
  const [pageInput, setPageInput] = useState(1);

  //redux
  const isViewChooseCompany = useSelector(
    (state: RootState) => state?.pm024.isViewChooseCompany
  );
  const chosenCompany = useSelector(
    (state: RootState) => state?.pm024.chosenCompany
  );
  const listCompany = useSelector(
    (state: RootState) => state?.pm024.listCompany
  );
  const project = useSelector((state: RootState) => state?.pm012.project);
  const getCompanyList = async (params: any) => {
    setLoading(true);
    try {
      const response = await pm024Service.getCompanyList(params);
      setLoading(false);
      if (params.page === 1) {
        return dispatch(setListCompany(response.data.results));
      }
      dispatch(setListCompany([...listCompany, ...response.data.results]));
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      setLoading(false);
      dispatch(setListCompany([]));
    }
  };

  const getCompanyMasters = async (params: any) => {
    try {
      const response = await pm024Service.getCompanyList(params);
      if (params.page === 1) {
        return setCompanyMasters(response.data.results);
      }
      setCompanyMasters([...companyMasters, ...response?.data?.results]);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      setCompanyMasters([]);
    }
  };

  const addCompanyForList = async (data: any) => {
    setLoading(true);
    try {
      const response = await pm024Service.addCompanyForList(data);
      if (refCompany) {
        refCompany?.current?.scrollTo(0, 0);
      }
      SuccessNotification(response.data?.message ?? NOTIFICATION_TITLE.SUCCESS);
      setLoading(false);
      setIsAddCompany(false);
      setRefreshModal(!refreshModal);
      setPage(1);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      setLoading(false);
    }
  };

  //modal choose company
  const handleOpenChooseCompany = () => {
    dispatch(setViewChooseCompany(true));
  };

  const handleCloseChooseCompany = () => {
    dispatch(setViewChooseCompany(false));
    setPage(1);
    dispatch(resetStatePM024());
    dispatch(setChangeChosenCompany(0));
  };

  const handleChangeChosenCompany = (e: any) => {
    dispatch(setChangeChosenCompany(e.target.value));
  };

  const handleConfirmChosenCompany = () => {};

  const handleAddCompanies = () => {
    setIsAddCompany(true);
  };

  const handleDeleteAddCompanies = () => {
    setIsAddCompany(false);
    form.resetFields(["name", "email"]);
  };

  let myVarCompany: any;
  const onSearchCompanyChange = () => {
    if (myVarCompany) clearTimeout(myVarCompany);
    myVarCompany = setTimeout(function () {
      const searchText = form.getFieldValue("keyword");
      form.setFieldValue("keyword", searchText.trim());
      onSearchCompany(searchText?.trim());
    }, 1000);
  };

  const onSearchCompany = (stringWord: string) => {
    setKeyword(stringWord);
  };

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const handleViewModalAddMember = () => {
    dispatch(setIsVisiblePM013(true));
  };

  const handleSaveAddCompany = (value: any) => {
    addCompanyForList({
      name: value?.name,
      email: value?.email,
      projectId: project?.id,
    });
  };
  const handleScrollListCompany = () => {
    setPage(page + 1);
  };

  const handleValueFormInputAutoComplete = (value: any) => {
    if (refAuto) {
      refAuto.current.children[0].scrollTo(0, 0);
      if (value.email) {
        refAuto.current.style.transform = "scale(1, 1)";
        refAuto.current.style.opacity = 1;
        setKeywordMaster(value.email);
      } else {
        refAuto.current.style.transform = "scale(1, 0)";
        refAuto.current.style.opacity = 0;
      }
    }
    setPageInput(1);
  };

  const handleBlurInputAutoComplete = () => {
    if (refAuto) {
      refAuto.current.style.transform = "scale(1, 0)";
      refAuto.current.style.opacity = 0;
    }
  };
  const handleScrollPopup = (e: any) => {
    const target = e.target;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      setPageInput(pageInput + 1);
    }
  };

  const handleClickItemAutoComplete = (
    idItem: number,
    email: string,
    name: string
  ) => {
    form.setFields([
      {
        name: "name",
        value: name,
      },
      {
        name: "email",
        value: email,
        errors: undefined,
      },
    ]);
    if (refAuto) {
      refAuto.current.style.transform = "scale(1, 0)";
      refAuto.current.style.opacity = 0;
    }
  };

  return {
    loading,
    isViewChooseCompany,
    listCompany,
    chosenCompany,
    page,
    size,
    keyword,
    form,
    refCompany,
    isAddCompany,
    refreshModal,
    project,
    pageInput,
    keywordMaster,
    companyMasters,
    refAuto,

    handleOpenChooseCompany,
    handleCloseChooseCompany,
    handleChangeChosenCompany,
    handleConfirmChosenCompany,
    handleAddCompanies,
    handleDeleteAddCompanies,
    handleViewModalAddMember,
    onSearchCompanyChange,
    handleChangePage,
    getCompanyList,
    getCompanyMasters,
    handleSaveAddCompany,
    handleScrollListCompany,
    handleValueFormInputAutoComplete,
    handleBlurInputAutoComplete,
    handleClickItemAutoComplete,
    handleScrollPopup,
  };
};

export default PM024Handler;
