import SM04Api from './infrastructure/api/Api';
import SM04Handler from './presenter/handler/Handler';
import SM04UI from './presenter/ui/UI';
import SM04Service from './usecase/Service';

const SM04Component = () => {
    const sm04Api = SM04Api();
    const sm04Service = SM04Service(sm04Api);
    const sm04Handler = SM04Handler(sm04Service);
    return <SM04UI handler={sm04Handler} />;
};

export default SM04Component;
