import { Modal } from "antd";
import { uuidv4 } from "@firebase/util";

type props = {
  onOk: () => void;
  onCancel?: () => void;
  canceText?: string;
  okText: string;
  iconUrl?: string;
  title: string;
  description?: string | string[];
};

const content = (content: string | string[] | null | undefined) => {
  if (content === null || content === undefined) return "";
  if (typeof content === "string") return content;
  return (
    <>
      {content.map((item) => (
        <div
          className="text-content-modal-confirm-ccus"
          style={{ color: "#666666" }}
          key={uuidv4()}
        >
          {item}
        </div>
      ))}
    </>
  );
};

const ErrorModalCCUS = ({
  onOk,
  onCancel,
  canceText,
  okText,
  iconUrl,
  title,
  description,
}: props) => {
  Modal.error({
    width: 560,
    style: {
      height: 190,
      marginLeft: 300,
    },
    centered: true,
    className: "error__modal-ccus",
    icon: null,
    content: (
      <section style={{ marginBottom: 32 }}>
        <div className="title-modal-confirm-ccus-container">
          {iconUrl && <img src={iconUrl} />}
          <div className="title-modal-confirm-ccus">{title}</div>
        </div>
        {content(description)}
      </section>
    ),
    okText: okText,
    okType: "primary",
    closable: false,
    cancelText: canceText,
    onOk: async () => {
      if (onOk) {
        await onOk();
      }
    },
    onCancel: () => {
      if (onCancel) {
        onCancel();
      }
    },
  });
};

export default ErrorModalCCUS;
