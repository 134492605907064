import { createSlice } from "@reduxjs/toolkit";
import { AM008SliceType } from "../../entity/Entity";

export const initialState: AM008SliceType = {
  isRefresh: false,
  isCollapsed: false,
  tableColumns: {
    columns: [],
    isNext: true,
    isPrev: true,
  },
  projectList: [],
  constructionList: [],
  emptyConstruction: false,
};

export const AM008Slice = createSlice({
  name: "am008",
  initialState,
  reducers: {
    refreshAM008: (state) => {
      state.isRefresh = !state.isRefresh;
    },
    setIsCollapsed: (state, action) => {
      state.isCollapsed = action.payload;
    },
    setTableColumns: (state, action) => {
      state.tableColumns = action.payload;
    },
    setProjectListData: (state, action) => {
      state.projectList = action.payload;
    }, 
    setContructionListData: (state, action) => {
      state.constructionList = action.payload;
    },
    setContructionList: (state, action) => {
      state.emptyConstruction = action.payload;
    },
  },
});

export const { refreshAM008, setIsCollapsed, setTableColumns, setProjectListData, setContructionListData, setContructionList} =
  AM008Slice.actions;

export default AM008Slice.reducer;
