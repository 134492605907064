import { useSearchParams } from "react-router-dom";
import { Button, Input } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { CCUS01Handler } from "../handler/HandlerImpl";
import MESSAGE, { TITLE_CCUS01 } from "../../../../../common/constants/MESSAGE";
import { CCUS_VERRIFICATION_STATE } from "../../../../../common/constants/STATUS";
import helpers from "../../../../../common/helpers/common";
import BackgroundImage from "../../../../../assets/images/background_ccus01.png";

interface props {
  handler: CCUS01Handler;
}

const WaitingCCUS01UI = ({ handler }: props) => {
  const [searchParams] = useSearchParams();

  const hanlderState = () => {
    let subLabel;
    switch (handler.ccusCompanyInformation?.state) {
      case CCUS_VERRIFICATION_STATE.INITIAL:
        subLabel = TITLE_CCUS01.LABEL_CCUS_COMPANY_WAITING;
        break;
      case CCUS_VERRIFICATION_STATE.DONE:
        subLabel = TITLE_CCUS01.LABEL_CCUS_COMPANY_DONE;
        break;
      default:
        subLabel = TITLE_CCUS01.LABEL_CCUS_COMPANY_ERROR;
    }
    return subLabel;
  };

  const state = handler.ccusCompanyInformation?.state;
  let className = "title-ccus-status";

  switch (state) {
    case CCUS_VERRIFICATION_STATE.ERROR:
    case CCUS_VERRIFICATION_STATE.INVALID:
      className = "title-ccus-status-error";
      break;
    case CCUS_VERRIFICATION_STATE.DONE:
      className = "title-ccus-status-success";
      break;
    default:
      className = "title-ccus-status";
      break;
  }

  return (
    <>
      <div className="container-status-ccus01">
        <div className="container-content-ccus01">
          <div className="header-title-ccus01">
            {TITLE_CCUS01.LABEL_TITLE_CCUS01}
          </div>
          <div className={className}>{hanlderState()}</div>
          <div className="infomation_ccus01">
            <div className="ccus-business-id">
              <p className="title-ccus01">{TITLE_CCUS01.CCUISID}</p>
              <p className="content-ccus01">
                {helpers.convertToCCUSFormat(
                  handler.ccusCompanyInformation?.ccusId
                )}
              </p>
            </div>

            <div className="ccus-business-password">
              <div className="title-password-comfirm">
                <div className="title-ccus01">{TITLE_CCUS01.CCUS_PASSWORD}</div>
              </div>
              <div className="input-password-comfirm">
                <Input.Password
                  readOnly
                  className="custom-password-input"
                  value={handler.ccusCompanyInformation?.ccusCode}
                  bordered={false}
                  iconRender={(visible) =>
                    visible ? (
                      <EyeTwoTone style={{ fontSize: 16 }} />
                    ) : (
                      <EyeInvisibleOutlined style={{ fontSize: 16 }} />
                    )
                  }
                />
              </div>
            </div>
          </div>

          {handler.ccusCompanyInformation?.state ==
          CCUS_VERRIFICATION_STATE.DONE ? (
            <></>
          ) : (
            <Button
              size="large"
              style={{ marginTop: 36 }}
              className={`button-purple w-100-percent cm047-btn-submit ${
                handler.ccusCompanyInformation?.state ===
                CCUS_VERRIFICATION_STATE.INITIAL
                  ? "button-brown-ccus"
                  : ""
              }`}
              disabled={
                handler.ccusCompanyInformation?.state ===
                CCUS_VERRIFICATION_STATE.INITIAL
              }
              onClick={handler.resetCcusCompany}
            >
              編集
            </Button>
          )}
        </div>

        <div className="contaner-background-ccus01" >
                <img className="img-background-ccus01" alt="" src={BackgroundImage} style={{objectFit:'contain'}} />
              </div>
      </div>
    </>
  );
};

export default WaitingCCUS01UI;
