import { Button, Tabs } from "antd";
import { CR003BodyUiHandler } from "../handler/HandlerImpl";
import CR003BasicInformation from "./CR003BasicInformation";
import CR003AmountRelate from "./CR003AmountRelate";

import IconCircleCheck from "../../../../../assets/icons/akar-icons_circle-check-fill.svg";
import { useParams, useSearchParams } from "react-router-dom";
import CR001MailContractorComponent from "../../../CR001-mail-contractors/index";
import { useMemo } from "react";
import { USER_FLOW } from "../../../../../common/constants/COMMON";
import helpers from "../../../../../common/helpers/common";
import { RootState } from "../../../../../store";
import { useSelector } from "react-redux";
import {
  AUTHORIZATION_CODE,
  SYSTEM_ROLES,
} from "../../../../../common/constants/AUTHORIZATION";
import { isHasPrivilege } from "../../../../../common/helpers/privilege";
import { PRIVILEGE_PM } from "../../../../../common/constants/PRIVILEGE";
import UploadLightSVG from "../../../../../assets/icons/upload-icon-purple.svg";
import { PARAMS, VERSION_APPROVAL } from "../CONSTANT";
import MESSAGE from "../../../../../common/constants/MESSAGE";
const { TabPane } = Tabs;

interface props {
  handler: CR003BodyUiHandler;
}

function CR003Body({ handler }: props) {
  const { summaryId, projectManagerId } = useParams();

  const permission = useSelector(
    (state: RootState) => state.auth.authData?.permission
  );
  const projectDetail = useSelector(
    (state: RootState) => state.pm005.projectDetail
  );

  const isRoleAdmin = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdmin
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const versionType = searchParams.get(PARAMS.VERSION_TYPE) || "";

  const listApprovalProcessWithoutSubmitter =
    handler.listApprovalProcess.filter((item: any) => !item.isSubmitter);
  const approvalProcessSubmitter = handler.listApprovalProcess.find(
    (item: any) => item.isSubmitter
  );
  const user = helpers.getObjectFromLocalStorage("user");
  const isCloneWithoutWorkflow =
    !!handler.detailProject?.parentProject?.id &&
    handler.listApprovalProcess.length === 0 &&
    !handler.currentUserApproval;

  const checkButtonWithWorkflowRolePMAdmin = useMemo(() => {
    return helpers.checkButtonWithWorkflowRolePMAdmin(
      handler.currentUserApproval,
      listApprovalProcessWithoutSubmitter,
      +(projectManagerId ?? 0)
    );
  }, [
    handler.currentUserApproval,
    handler.listApprovalProcess,
    projectManagerId,
  ]);

  const checkButtonWithWorkflowRolePM = useMemo(() => {
    return helpers.checkButtonWithWorkflowRolePM(
      handler.currentUserApproval,
      listApprovalProcessWithoutSubmitter,
      +(projectManagerId ?? 0)
    );
  }, [
    handler.currentUserApproval,
    handler.listApprovalProcess,
    projectManagerId,
  ]);

  return (
    <div className="ci001-body">
      <Tabs
        type="card"
        onChange={handler.onChangeCurrentTab}
        activeKey={handler.currentTab}
      >
        <TabPane tab={"基本情報"} key="1">
          <CR003BasicInformation handler={handler} />
        </TabPane>
        <TabPane tab={"金額関係"} key="2">
          <CR003AmountRelate handler={handler} />
          <CR001MailContractorComponent
            afterSendMail={handler.handleAfterSendMail}
          />
        </TabPane>
      </Tabs>
      {(handler.listRelateMoney || handler.basicInfo) && !handler.isEdit && (
        <div className="btn-approve">
          {handler.funcCheckButtonSendMail() &&
          handler.currentTab === "2" &&
          !handler.isEdit &&
          !versionType ? (
            <Button
              size="large"
              className="button-purple"
              style={{
                letterSpacing: -1.8,
                minWidth: 82,
                marginLeft: 16,
              }}
              onClick={handler.funcViewModalMailContractor}
              disabled={handler?.subcontractCostList?.every(
                (element) => element.isInvited || element.company === null
              )}
            >
              招待
            </Button>
          ) : null}
          {!(checkButtonWithWorkflowRolePM === USER_FLOW.USER) &&
            !(checkButtonWithWorkflowRolePM === USER_FLOW.MANAGER_SUBMIT) &&
            !(checkButtonWithWorkflowRolePM === USER_FLOW.MANAGER_SUBMITTED) &&
            !versionType && (
              <>
                <Button
                  size="large"
                  className="button-purple cr001-header-btn-ok"
                  icon={<img alt="" src={IconCircleCheck} />}
                  onClick={() =>
                    handler.handleApproveCI(
                      MESSAGE.MESSAGE_DESCRIPTION_CR_CONFIRM_APPROVE,
                      "承認確認"
                    )
                  }
                  disabled={
                    checkButtonWithWorkflowRolePM ===
                      USER_FLOW.MANAGER_FLOW_SUBMITTED ||
                    checkButtonWithWorkflowRolePMAdmin ===
                      USER_FLOW.MANAGER_SUBMIT ||
                    checkButtonWithWorkflowRolePM === USER_FLOW.FLOW_SUBMITTED
                  }
                  style={{
                    marginLeft: 16,
                  }}
                >
                  承認
                </Button>
                <Button
                  size="large"
                  className="btn-reject"
                  onClick={() => handler.handleRejectCI()}
                  style={{
                    backgroundColor: "#dae2ee",
                    letterSpacing: -1.5,
                    marginLeft: 16,
                  }}
                  disabled={
                    checkButtonWithWorkflowRolePM ===
                      USER_FLOW.MANAGER_FLOW_SUBMITTED ||
                    checkButtonWithWorkflowRolePMAdmin ===
                      USER_FLOW.MANAGER_SUBMIT ||
                    checkButtonWithWorkflowRolePM === USER_FLOW.FLOW_SUBMITTED
                  }
                >
                  否認
                </Button>
              </>
            )}
          {[
            USER_FLOW.MANAGER_SUBMITTED,
            USER_FLOW.MANAGER_FLOW_SUBMITTED,
            USER_FLOW.MANAGER_FLOW_SUBMIT,
            USER_FLOW.FLOW_SUBMITTED,
            USER_FLOW.FLOW_SUBMIT,
            USER_FLOW.USER,
          ].includes(checkButtonWithWorkflowRolePMAdmin) &&
            (isRoleAdmin ||
              +user.id === approvalProcessSubmitter?.id ||
              isHasPrivilege(handler.detailProject, [PRIVILEGE_PM])) &&
            !versionType && (
              <Button
                size="large"
                className="btn-withdraw"
                onClick={() => handler.handleWithDraw(summaryId)}
                style={{
                  marginLeft: 16,
                }}
              >
                取り下げ
              </Button>
            )}
          {checkButtonWithWorkflowRolePMAdmin === USER_FLOW.MANAGER_SUBMIT &&
            !isCloneWithoutWorkflow &&
            (isRoleAdmin ||
              isHasPrivilege(handler.detailProject, [PRIVILEGE_PM])) &&
            !handler.isEdit &&
            !versionType && (
              <Button
                size="large"
                className="button-purple cr001-header-btn-ok"
                icon={<img alt="" src={IconCircleCheck} />}
                onClick={() =>
                  handler.handleApproveCI(
                    MESSAGE.MESSAGE_DESCRIPTION_CR_CONFIRM_SUBMIT,
                    "提出確認"
                  )
                }
                style={{
                  marginLeft: 16,
                }}
              >
                提出
              </Button>
            )}
          {!handler.isEdit &&
            (permission?.includes(AUTHORIZATION_CODE.B9) || isRoleAdmin) && (
              <Button
                className="button-brown-3"
                style={{
                  border: "1px solid #605BFF",
                  width: 106,
                  marginLeft: 16,
                }}
                size="large"
                onClick={() =>
                  handler.exportTemplate(summaryId, projectManagerId)
                }
              >
                <div className="center-item">
                  <img
                    alt=""
                    src={UploadLightSVG}
                    style={{
                      marginRight: 8,
                      fontWeight: 700,
                      fontSize: 14,
                    }}
                  />
                  <div
                    className="export-button"
                    style={{ marginTop: 2, color: "#605BFF" }}
                  >
                    出力
                  </div>
                </div>
              </Button>
            )}
        </div>
      )}
    </div>
  );
}

export default CR003Body;
