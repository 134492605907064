import { OrderingCompany } from "../../PM025/entity/PM012Entity";
import { APIImpl } from "./ServiceImpl";

const Service = (api: APIImpl) => {
  const postOrderingCompanyAdd = async (data: any): Promise<any> => {
    return await api.postOrderingCompanyAdd(data);
  };
  const putOrderingCompanyUpdate = async (data: any): Promise<any> => {
    return await api.putOrderingCompanyUpdate(data);
  };

  const getOrderingCompanyInformation = async (
    params: any
  ): Promise<OrderingCompany> => {
    return await api.getOrderingCompanyInformation(params);
  };

  return {
    postOrderingCompanyAdd,
    getOrderingCompanyInformation,
    putOrderingCompanyUpdate,
  };
};

export default Service;
