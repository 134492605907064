import WarningRedIcon from "../../../../../../../assets/icons/warning-red.svg";
import { HandlerButtonWarningImpl } from "../../../handler/HandlerImpl";

type props = {
  date: string,
  constructionId?: string;
  contractorConstructionId?: string
  userId: string
  handler: HandlerButtonWarningImpl

}

const ButtonWarningUI = ({ date, constructionId, userId, handler, contractorConstructionId }: props) => {
  return <div className="button-style" onClick={() => handler.onClickWarningButton({ date, constructionId, userId, contractorConstructionId })}>
    <img alt="" src={WarningRedIcon} />
  </div>

}

export default ButtonWarningUI