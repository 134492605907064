import { useDispatch } from "react-redux";
import { Form, message } from "antd";
import { AOP003ServiceImpl } from "../../usecase/ServiceImpl";
import { useState, useMemo } from "react";
import COMMON from "../../../../../common/constants/COMMON";
import { showH2Modal } from "../../../../../common/components/modal-H2/H2ModalSlice";
import { showH3Modal } from "../../../../../common/components/modal-H3/H3ModalSlice";
import { showH4Modal } from "../../../../../common/components/modal-H4/H4ModalSlice";
import { showH1Modal } from "../../../../../common/components/modal-H1/H1ModalSlice";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ConfirmModal from "../../../../../common/components/modal/ConfirmModal";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import {
  ParamsPermissionList,
  ParamsRoleDetail,
  ParamsRoleUserList,
} from "../../entity/Entity";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import { DEFAULT_OPTION, TABS, TYPE_I } from "../../constant";
import { FormInstance } from "antd/es/form/Form";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import { getDataTable } from "../../helper";

type Permission = {
  code: string;
  description: string;
  flag: boolean;
  id: number;
  name: string;
  subCategory: {
    code: string;
    description: string;
    id: number;
  };
};

export const EMPLOYEE_ITEM = "employee";

const AOP003Handler = (service: AOP003ServiceImpl) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { roleId } = useParams();

  const [loadingOption, setLoadingOption] = useState(false);
  const [filterOption, setFilterOption] = useState(DEFAULT_OPTION);
  const [members, setMembers] = useState<any[]>([]);
  const [memberChosen, setMemberChosen] = useState<any[]>([]);
  const [isChange, setIsChange] = useState(false);
  const [permissionCategory, setPermissionCategory] = useState<any[]>([]);
  const [detailRole, setDetailRole] = useState<any>({});
  const [listPermission, setListPermission] = useState<any[]>([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const tabParams = searchParams.get("tab");
  const [role, setRole] = useState<string>("");

  const getGroupListMember = async (params: {
    page: number;
    size: number;
    keyword?: string;
  }) => {
    setLoadingOption(true);
    try {
      const response = await service.getGroupListMember(params);
      if (response?.pagination) {
        setFilterOption({
          keyword: params.keyword ?? "",
          page: response.pagination.page,
          numPages: response.pagination.numPages,
        });
      }
      if (response?.results) {
        const memberData = response.results?.map((item) => {
          return {
            name:
              (item.fullName || item.username) +
              (item.group?.name ? `(${item.group?.name})` : ""),
            value: item.id,
          };
        });
        if (params.page === 1) {
          setMembers(funcCheckDuplicate(memberData));
        } else {
          setMembers(funcCheckDuplicate([...members, ...memberData]));
        }
      } else setMembers([]);
    } finally {
      setLoadingOption(false);
    }
  };

  const getRoleUserList = async (params: ParamsRoleUserList) => {
    try {
      dispatch(setLoading(true));
      const response = await service.getRoleUserList(params);
      if (response?.results) {
        const users = [...response.results];

        if (response.pagination && response.pagination.numPages > 1) {
          for (let page = 2; page <= response.pagination.numPages; page++) {
            const res = await service.getRoleUserList({ ...params, page });
            if (res?.results) {
              for (const item of res.results) users.push(item);
            }
          }
        }
        const mapUser = users?.map((item) => ({
          name:
            (item.fullName || item.username) +
            (item.group?.name ? `(${item.group?.name})` : ""),
          value: item.id,
        }));
        setMemberChosen(mapUser);
      } else setMemberChosen([]);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getRolePermissionCategory = async () => {
    try {
      const response = await service.getRolePermissionCategory();
      if (response?.results && response.results.length > 0) {
        const results: any = [];
        const idTab: any = [];
        let permissions: any[] = [];
        for (const item of TABS) {
          const find = response.results.find((ele) => ele.code === item);
          idTab.push(find?.id ?? 0);
          results.push(find);
        }

        if (roleId !== "0") {
          // Get detail
          const tab1: any = await getDetailRole({
            roleId: +(roleId ?? 0),
            page: COMMON.DEFAULT_PAGE,
            size: COMMON.DEFAULT_SIZE,
            categoryId: idTab[0],
            keyword: "",
          });
          if (tab1.listPermission) {
            permissions = [...tab1.listPermission];
          }

          if (response.results.length > 1) {
            const tab2: any = await getDetailRole({
              roleId: +(roleId ?? 0),
              page: COMMON.DEFAULT_PAGE,
              size: COMMON.DEFAULT_SIZE,
              categoryId: idTab[1],
              keyword: "",
            });
            if (tab2.listPermission) {
              permissions = [
                ...(tab1.listPermission ?? []),
                ...tab2.listPermission,
              ];
            }
          }
        } else {
          const tab1: any = await getPermissionList({
            page: COMMON.DEFAULT_PAGE,
            size: COMMON.DEFAULT_SIZE,
            categoryId: idTab[0],
            keyword: "",
          });
          if (tab1) {
            permissions = [...tab1];
          }

          if (response.results.length > 1) {
            const tab2: any = await getPermissionList({
              page: COMMON.DEFAULT_PAGE,
              size: COMMON.DEFAULT_SIZE,
              categoryId: idTab[1],
              keyword: "",
            });
            if (tab2) {
              permissions = [...(tab1 ?? []), ...tab2];
            }
          }
        }
        for (const item of permissions)
          form.setFieldValue(item.code, item.flag);

        setListPermission(permissions);
        setPermissionCategory(results);
      }
    } catch {
      //
    }
  };

  const getDetailRole = async (params: ParamsRoleDetail) => {
    const response = await service.getRoleDetail(params);
    if (response?.results) {
      const permission = [...response.results.listPermission];
      form.setFieldValue("roleName", response.results.name);

      if (response.pagination && response.pagination.numPages > 1) {
        for (let page = 2; page <= response.pagination.numPages; page++) {
          const res = await service.getRoleDetail({ ...params, page });
          if (res?.results) {
            for (const item of res.results.listPermission)
              permission.push(item);
          }
        }
      }
      setRole(response?.results?.code);
      return { ...response.results, listPermission: permission };
    } else {
      return {};
    }
  };

  const getPermissionList = async (params: ParamsPermissionList) => {
    const response = await service.getPermissionList(params);
    if (response?.results) {
      const permission = [...response.results];

      if (response.pagination && response.pagination.numPages > 1) {
        for (let page = 2; page <= response.pagination.numPages; page++) {
          const res = await service.getPermissionList({ ...params, page });
          if (res?.results) {
            for (const item of res.results) permission.push(item);
          }
        }
      }
      return permission;
    } else {
      return [];
    }
  };

  const funcCheckDuplicate = (data: { value: number }[]) => {
    const check: any = {};
    const results: any[] = [];
    for (const item of data) {
      if (!(item.value in check)) {
        results.push(item);
        check[item.value] = item;
      }
    }
    return results;
  };

  const getListMember = () => {
    getGroupListMember({
      page: COMMON.DEFAULT_PAGE,
      size: COMMON.DEFAULT_SIZE + memberChosen?.length,
      keyword: filterOption.keyword
    });
    setFilterOption(filterOption.keyword ? { ...filterOption, page: COMMON.DEFAULT_PAGE, numPages: COMMON.DEFAULT_SIZE + memberChosen?.length } : DEFAULT_OPTION);
  };

  const handleScrollMember = (e: any) => {
    const target = e.target;
    if (
      target.scrollTop + target.offsetHeight === target.scrollHeight &&
      filterOption.page < filterOption.numPages
    ) {
      getGroupListMember({
        page: filterOption.page + 1,
        size: COMMON.DEFAULT_SIZE,
        keyword: filterOption.keyword ? filterOption.keyword : undefined,
      });
    }
  };

  const handleSearchOption = (searchValue: string) => {
    getGroupListMember({
      page: COMMON.DEFAULT_PAGE,
      size: COMMON.DEFAULT_SIZE + memberChosen?.length,
      keyword: searchValue ? searchValue : undefined,
    });
    setFilterOption({ ...filterOption, keyword: searchValue });
  };

  const handleChosenMember = (item: {
    name: string;
    value: string | number;
  }) => {
    setMemberChosen([...memberChosen, item]);
    setFilterOption(DEFAULT_OPTION);
  };

  const handleRemoveMember = (item: {
    name: string;
    value: string | number;
  }) => {
    ConfirmModal({
      onOk: () => {
        const newMember = memberChosen.filter(
          (element) => element.value !== item.value
        );
        setMemberChosen(newMember);
      },
      className: "confirm__modal",
      title: MESSAGE.MESSAGE_MODAL_DELETE_TITLE,
      description: MESSAGE.MESSAGE_DELETE_MEMBER_AOP,
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_MODAL,
      isCenterWithoutMenu: true,
      height: "auto",
    });
  };

  const handleClickI = (type: string) => {
    switch (type) {
      case TYPE_I.h1:
        dispatch(showH1Modal());
        break;
      case TYPE_I.h2:
        dispatch(showH2Modal());
        break;
      case TYPE_I.h3:
        dispatch(showH3Modal());
        break;
      case TYPE_I.h4:
        dispatch(showH4Modal());
        break;
    }
  };

  const handleChangeCurrentTab = (currentTab: string) => {
    searchParams.set("tab", currentTab);
    setSearchParams(searchParams);
  };

  const handleChangeFormValue = (value: any) => {
    setIsChange(true);
  };

  const handleSubmit = async (value: any) => {
    const dataUpdate = { ...value };
    const permission: number[] = [];
    const userId: number[] = [];

    if (listPermission) {
      for (const item of listPermission) {
        if (
          (item.code in dataUpdate && dataUpdate[item.code]) ||
          (!(item.code in dataUpdate) && item.flag)
        ) {
          permission.push(item.id);
        }
      }
    }

    if (memberChosen?.length > 0) {
      for (const item of memberChosen) {
        userId.push(item.value);
      }
    }
    try {
      dispatch(setLoading(true));
      if (roleId === "0") {
        const response = await service.postRoleCreate({
          code: "",
          description: "",
          listPermisionId: permission,
          name: value.roleName ?? "",
        });
        SuccessNotification(response?.message || NOTIFICATION_TITLE.SUCCESS);
        navigate("/app-aop001");
      } else {
        const response = await Promise.all([
          service.putRoleUpdate({
            id: +(roleId ?? 0),
            description: "",
            name: value?.roleName ?? "",
          }),
          service.postPermission({
            roleId: +(roleId ?? 0),
            listPermissionId: permission,
          }),
          service.putRoleUserUpdate({
            roleId: +(roleId ?? 0),
            listUserId: userId,
          }),
        ]);
        SuccessNotification(response[0]?.message || NOTIFICATION_TITLE.SUCCESS);
        navigate("/app-aop001");
      }
    } catch (error: any) {
      ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleCancelSave = (
    description: any,
    fromDetail: boolean,
    roleId: string,
    roleName: string
  ) => {
    if (isChange) {
      ConfirmModal({
        onOk: () => {
          if (fromDetail)
            navigate(
              `/app-aop004?groupRoleId=${roleId}&groupRoleName=${roleName}`
            );
          else navigate("/app-aop001");
        },
        className: "confirm__modal",
        title: MESSAGE.MESSAGE_020,
        description: description,
        canceText: LABEL_MESSAGE.CANCEL_MODAL,
        okText: LABEL_MESSAGE.OK_MODAL,
        isCenterWithoutMenu: true,
        height: "auto",
      });
    } else {
      if (fromDetail)
        navigate(`/app-aop004?groupRoleId=${roleId}&groupRoleName=${roleName}`);
      else navigate("/app-aop001");
    }
  };

  const handleData = (
    checkData: { [keyword: string]: Permission },
    column: { column: string[]; code: string[]; name: string; key: string }[]
  ) => {
    const results = column.map((item, index) => {
      const flag: { [keyword: string]: boolean } = {};
      for (const code of item.code) {
        if (code in checkData) flag[code] = checkData[code].flag;
        // else flag[code] = false;
      }

      return { ...item, flag, no: index + 1 };
    });
    return results;
  };

  const currentTab = useMemo(() => {
    if (tabParams) return tabParams;
    if (permissionCategory && permissionCategory.length > 0)
      return permissionCategory[0].code;
  }, [tabParams, permissionCategory]);

  const memberSelect = useMemo(() => {
    const check: any = {};
    const results: any[] = [];
    for (const item of memberChosen) check[item.value] = item;
    for (const item of members) if (!(item.value in check)) results.push(item);
    return results;
  }, [memberChosen, members]);

  const dataTab1 = useMemo(() => {
    if (!listPermission) return [[], []];
    const checkData: { [keyword: string]: Permission } = {};
    if (listPermission && listPermission.length > 0) {
      for (const item of listPermission) {
        if (item?.code) {
          checkData[item.code] = item;
        }
      }
    }

    const dataTable = getDataTable();
    return [
      handleData(checkData, dataTable.TAB1[0]),
      handleData(checkData, dataTable.TAB1[1]),
    ];
  }, [listPermission, form]);

  const dataTab2 = useMemo(() => {
    if (!listPermission) return [[], [], [], [], [], []];
    const checkData: { [keyword: string]: Permission } = {};
    if (listPermission && listPermission.length > 0) {
      for (const item of listPermission) {
        if (item?.code) {
          checkData[item.code] = item;
        }
      }
    }
    const dataTable: any = getDataTable();

    return [
      handleData(checkData, dataTable.TAB2[0]),
      handleData(checkData, dataTable.TAB2[1]),
      handleData(checkData, dataTable.TAB2[2]),
      handleData(checkData, dataTable.TAB2[3]),
      handleData(checkData, dataTable.TAB2[4]),
      handleData(checkData, dataTable.TAB2[5]),
    ];
  }, [listPermission]);

  /* Business rule sprint 9.3 */
  const onFormValuesChange = (changedValues: any, allValues: any) => {
    if (!isChange) setIsChange(true);

    // Check permission need to be checked
    const changedValuesString = Object.keys(changedValues)[0];
    if (
      [
        "M9",
        "M7",
        "M6",
        "B10",
        "S42",
        "S49",
        "S43",
        "S48",
        "S46",
        "S45",
        "S44",
        "S47",
        "B6",
      ].includes(changedValuesString)
    )
      checkRequiredPermissions(changedValues);
  };

  const checkRequiredPermissions = (changedValues: any): boolean => {
    const { M9, M6, B6, S44, S46, S47, S48, S45, S43, S49, S42, B10, M7 } =
      form.getFieldsValue([
        ["M9"],
        ["M6"],
        ["B6"],
        ["S47"],
        ["S46"],
        ["S44"],
        ["S48"],
        ["S45"],
        ["S43"],
        ["S49"],
        ["S42"],
        ["B10"],
        ["M7"],
      ]);

    let permissionM6: any,
      permissionB6: any,
      permissionS47: any,
      permissionS46: any,
      permissionS44: any,
      permissionS48: any,
      permissionS45: any,
      permissionS43: any,
      permissionS49: any,
      permissionS42: any,
      permissionB10: any,
      permissionM9: any,
      permissionM7: any;

    listPermission.forEach((element) => {
      switch (element.code) {
        case "M6":
          return (permissionM6 = element);
        case "B6":
          return (permissionB6 = element);
        case "S47":
          return (permissionS47 = element);
        case "S46":
          return (permissionS46 = element);
        case "S44":
          return (permissionS44 = element);
        case "S48":
          return (permissionS48 = element);
        case "S45":
          return (permissionS45 = element);
        case "S43":
          return (permissionS43 = element);
        case "S49":
          return (permissionS49 = element);
        case "S42":
          return (permissionS42 = element);
        case "B10":
          return (permissionB10 = element);
        case "M7":
          return (permissionM7 = element);
        case "M9":
          return (permissionM9 = element);
      }
    });

    let messageM6: string = M6 ? permissionM6?.name ?? "" : "",
      messageB6: string = B6 ? permissionB6?.name ?? "" : "",
      messageS47: string = S47 ? permissionS47?.name ?? "" : "",
      messageS46: string = S46 ? permissionS46?.name ?? "" : "",
      messageS44: string = S44 ? permissionS44?.name ?? "" : "",
      messageS48: string = S48 ? permissionS48?.name ?? "" : "",
      messageS45: string = S45 ? permissionS45?.name ?? "" : "",
      messageS43: string = S43 ? permissionS43?.name ?? "" : "",
      messageS49: string = S49 ? permissionS49?.name ?? "" : "",
      messageS42: string = S42 ? permissionS42?.name ?? "" : "",
      messageB10: string = B10 ? permissionB10?.name ?? "" : "",
      messageM9: string = M9 ? permissionM9?.name ?? "" : "",
      messageM7: string = M7 ? permissionM7?.name ?? "" : "";

    if (changedValues.hasOwnProperty("M9")) {
      // Check M9 but uncheck M7
      if (M9 && !M7) {
        const confirmDescription = getConfirmRequiredPermissionDescription(
          permissionM9?.name ?? "",
          permissionM7?.name ?? ""
        );
        ConfirmModal({
          onOk: async () => {
            form.setFieldsValue({
              M7: true,
            });
          },
          onCancel: () => {
            form.setFieldsValue({
              M9: false,
            });
          },
          className: "confirm__modal confirm__modal-purple-oke",
          title: MESSAGE.MESSAGE_TITLE_AUTHORIZATION_CONFIRMATION_1,
          description: confirmDescription[0],
          extraDescription: confirmDescription[1],
          canceText: LABEL_MESSAGE.CANCEL_MODAL,
          okText: LABEL_MESSAGE.OK_MODAL,
          isCenterWithoutMenu: true,
          height: "auto",
        });
        return true;
      }
    } else if (changedValues.hasOwnProperty("B6")) {
      // Check SB6 but uncheck one of these permission: M7, B10, S42, S49, S43, S48, S46, S45, S44, S47
      if (
        B6 &&
        !(M7 && B10 && S42 && S49 && S43 && S48 && S46 && S45 && S44 && S47)
      ) {
        const confirmDescription = getConfirmRequiredPermissionDescription(
          permissionB6?.name ?? "",
          joinArrayString([
            permissionM7?.name ?? "",
            permissionB10?.name ?? "",
            permissionS42?.name ?? "",
            permissionS49?.name ?? "",
            permissionS43?.name ?? "",
            permissionS48?.name ?? "",
            permissionS46?.name ?? "",
            permissionS45?.name ?? "",
            permissionS44?.name ?? "",
            permissionS47?.name ?? "",
          ])
        );
        ConfirmModal({
          onOk: async () => {
            form.setFieldsValue({
              M7: true,
              B10: true,
              S42: true,
              S49: true,
              S43: true,
              S48: true,
              S46: true,
              S45: true,
              S44: true,
              S47: true,
            });
          },
          onCancel: () => {
            form.setFieldsValue({
              B6: false,
            });
          },
          className: "confirm__modal confirm__modal-purple-oke",
          title: MESSAGE.MESSAGE_TITLE_AUTHORIZATION_CONFIRMATION_1,
          description: confirmDescription[0],
          extraDescription: confirmDescription[1],

          canceText: LABEL_MESSAGE.CANCEL_MODAL,
          okText: LABEL_MESSAGE.OK_MODAL,
          isCenterWithoutMenu: true,
          height: "auto",
        });
        return true;
      }
    } else if (changedValues.hasOwnProperty("S45")) {
      if (S45 && !S43) {
        // Check S45 but uncheck S43
        const confirmDescription = getConfirmRequiredPermissionDescription(
          permissionS45?.name ?? "",
          joinArrayString([
            permissionS43?.name ?? "",
            permissionS42?.name ?? "",
            permissionB10?.name ?? "",
            permissionM7?.name ?? "",
          ])
        );
        ConfirmModal({
          onOk: async () => {
            form.setFieldsValue({
              S43: true,
              S42: true,
              B10: true,
              M7: true,
            });
          },
          onCancel: () => {
            form.setFieldsValue({
              S45: false,
            });
          },
          className: "confirm__modal confirm__modal-purple-oke",
          title: MESSAGE.MESSAGE_TITLE_AUTHORIZATION_CONFIRMATION_1,
          description: confirmDescription[0],
          extraDescription: confirmDescription[1],

          canceText: LABEL_MESSAGE.CANCEL_MODAL,
          okText: LABEL_MESSAGE.OK_MODAL,
          isCenterWithoutMenu: true,
          height: "auto",
        });
        return true;
      } else if (!S45 && B6) {
        // Uncheck S45 but check B6
        const confirmDescription = getUnselectPermissionDescription(
          permissionS45?.name ?? "",
          joinArrayString([messageB6])
        );
        ConfirmModal({
          onOk: async () => {
            form.setFieldsValue({
              B6: false,
            });
          },
          onCancel: () => {
            form.setFieldsValue({
              S45: true,
            });
          },
          className: "confirm__modal confirm__modal-purple-oke",
          title: MESSAGE.MESSAGE_TITLE_AUTHORIZATION_CONFIRMATION_2,
          description: confirmDescription[0],
          extraDescription: confirmDescription[1],

          canceText: LABEL_MESSAGE.CANCEL_MODAL,
          okText: LABEL_MESSAGE.OK_MODAL,
          isCenterWithoutMenu: true,
          height: "auto",
        });
        return true;
      }
    } else if (changedValues.hasOwnProperty("S44")) {
      // Check S44 but uncheck S43
      if (S44 && !S43) {
        // Check S44 but uncheck S43
        const confirmDescription = getConfirmRequiredPermissionDescription(
          permissionS44?.name ?? "",
          joinArrayString([
            permissionS43?.name ?? "",
            permissionS42?.name ?? "",
            permissionB10?.name ?? "",
            permissionM7?.name ?? "",
          ])
        );
        ConfirmModal({
          onOk: async () => {
            form.setFieldsValue({
              S43: true,
              S42: true,
              B10: true,
              M7: true,
            });
          },
          onCancel: () => {
            form.setFieldsValue({
              S44: false,
            });
          },
          className: "confirm__modal confirm__modal-purple-oke",
          title: MESSAGE.MESSAGE_TITLE_AUTHORIZATION_CONFIRMATION_1,
          description: confirmDescription[0],
          extraDescription: confirmDescription[1],

          canceText: LABEL_MESSAGE.CANCEL_MODAL,
          okText: LABEL_MESSAGE.OK_MODAL,
          isCenterWithoutMenu: true,
          height: "auto",
        });
        return true;
      } else if (!S44 && B6) {
        // Uncheck S44 but check B6
        const confirmDescription = getUnselectPermissionDescription(
          permissionS44?.name ?? "",
          joinArrayString([messageB6])
        );
        ConfirmModal({
          onOk: async () => {
            form.setFieldsValue({
              B6: false,
            });
          },
          onCancel: () => {
            form.setFieldsValue({
              S44: true,
            });
          },
          className: "confirm__modal confirm__modal-purple-oke",
          title: MESSAGE.MESSAGE_TITLE_AUTHORIZATION_CONFIRMATION_2,
          description: confirmDescription[0],
          extraDescription: confirmDescription[1],

          canceText: LABEL_MESSAGE.CANCEL_MODAL,
          okText: LABEL_MESSAGE.OK_MODAL,
          isCenterWithoutMenu: true,
          height: "auto",
        });
        return true;
      }
    } else if (changedValues.hasOwnProperty("S47")) {
      if (S47 && !S46) {
        // Check S47 but uncheck S46
        const confirmDescription = getConfirmRequiredPermissionDescription(
          permissionS47?.name ?? "",
          joinArrayString([
            permissionS46?.name ?? "",
            permissionS42?.name ?? "",
            permissionB10?.name ?? "",
            permissionM7?.name ?? "",
          ])
        );
        ConfirmModal({
          onOk: async () => {
            form.setFieldsValue({
              S46: true,
              S42: true,
              B10: true,
              M7: true,
            });
          },
          onCancel: () => {
            form.setFieldsValue({
              S47: false,
            });
          },
          className: "confirm__modal confirm__modal-purple-oke",
          title: MESSAGE.MESSAGE_TITLE_AUTHORIZATION_CONFIRMATION_1,
          description: confirmDescription[0],
          extraDescription: confirmDescription[1],

          canceText: LABEL_MESSAGE.CANCEL_MODAL,
          okText: LABEL_MESSAGE.OK_MODAL,
          isCenterWithoutMenu: true,
          height: "auto",
        });
        return true;
      } else if (!S47 && B6) {
        // Uncheck S45 but check B6
        const confirmDescription = getUnselectPermissionDescription(
          permissionS47?.name ?? "",
          joinArrayString([messageB6])
        );
        ConfirmModal({
          onOk: async () => {
            form.setFieldsValue({
              B6: false,
            });
          },
          onCancel: () => {
            form.setFieldsValue({
              S47: true,
            });
          },
          className: "confirm__modal confirm__modal-purple-oke",
          title: MESSAGE.MESSAGE_TITLE_AUTHORIZATION_CONFIRMATION_2,
          description: confirmDescription[0],
          extraDescription: confirmDescription[1],
          canceText: LABEL_MESSAGE.CANCEL_MODAL,
          okText: LABEL_MESSAGE.OK_MODAL,
          isCenterWithoutMenu: true,
          height: "auto",
        });
        return true;
      }
    } else if (changedValues.hasOwnProperty("S48")) {
      // Check S48 but uncheck S42
      if (S48 && !S42) {
        const confirmDescription = getConfirmRequiredPermissionDescription(
          permissionS48?.name ?? "",
          joinArrayString([
            permissionS42?.name ?? "",
            permissionB10?.name ?? "",
            permissionM7?.name ?? "",
          ])
        );
        ConfirmModal({
          onOk: async () => {
            form.setFieldsValue({
              S42: true,
              B10: true,
              M7: true,
            });
          },
          onCancel: () => {
            form.setFieldsValue({
              S48: false,
            });
          },
          className: "confirm__modal confirm__modal-purple-oke",
          title: MESSAGE.MESSAGE_TITLE_AUTHORIZATION_CONFIRMATION_1,
          description: confirmDescription[0],
          extraDescription: confirmDescription[1],

          canceText: LABEL_MESSAGE.CANCEL_MODAL,
          okText: LABEL_MESSAGE.OK_MODAL,
          isCenterWithoutMenu: true,
          height: "auto",
        });
        return true;
      } else if (!S48 && B6) {
        // Uncheck S48 but check B6
        // Uncheck S44 but check B6
        const confirmDescription = getUnselectPermissionDescription(
          permissionS48?.name ?? "",
          joinArrayString([messageB6])
        );
        ConfirmModal({
          onOk: async () => {
            form.setFieldsValue({
              B6: false,
            });
          },
          onCancel: () => {
            form.setFieldsValue({
              S48: true,
            });
          },
          className: "confirm__modal confirm__modal-purple-oke",
          title: MESSAGE.MESSAGE_TITLE_AUTHORIZATION_CONFIRMATION_2,
          description: confirmDescription[0],
          extraDescription: confirmDescription[1],

          canceText: LABEL_MESSAGE.CANCEL_MODAL,
          okText: LABEL_MESSAGE.OK_MODAL,
          isCenterWithoutMenu: true,
          height: "auto",
        });
        return true;
      }
    } else if (changedValues.hasOwnProperty("S46")) {
      // Check S46 but uncheck S42
      if (!S42) {
        const confirmDescription = getConfirmRequiredPermissionDescription(
          permissionS46?.name ?? "",
          joinArrayString([
            permissionS42?.name ?? "",
            permissionB10?.name ?? "",
            permissionM7?.name ?? "",
          ])
        );
        ConfirmModal({
          onOk: async () => {
            form.setFieldsValue({
              S42: true,
              B10: true,
              M7: true,
            });
          },
          onCancel: () => {
            form.setFieldsValue({
              S46: false,
            });
          },
          className: "confirm__modal confirm__modal-purple-oke",
          title: MESSAGE.MESSAGE_TITLE_AUTHORIZATION_CONFIRMATION_1,
          description: confirmDescription[0],
          extraDescription: confirmDescription[1],

          canceText: LABEL_MESSAGE.CANCEL_MODAL,
          okText: LABEL_MESSAGE.OK_MODAL,
          isCenterWithoutMenu: true,
          height: "auto",
        });
        return true;
      } else if (!S46 && (S47 || B6)) {
        // Uncheck S46 but check S47 or B6
        const confirmDescription = getConfirmRequiredPermissionDescription(
          permissionS46?.name ?? "",
          joinArrayString([permissionS47?.name ?? "", permissionB6?.name ?? ""])
        );
        ConfirmModal({
          onOk: async () => {
            form.setFieldsValue({
              S47: false,
              B6: false,
            });
          },
          onCancel: () => {
            form.setFieldsValue({
              S46: true,
            });
          },
          className: "confirm__modal confirm__modal-purple-oke",
          title: MESSAGE.MESSAGE_TITLE_AUTHORIZATION_CONFIRMATION_1,
          description: confirmDescription[0],
          extraDescription: confirmDescription[1],
          canceText: LABEL_MESSAGE.CANCEL_MODAL,
          okText: LABEL_MESSAGE.OK_MODAL,
          isCenterWithoutMenu: true,
          height: "auto",
        });
        return true;
      }
    } else if (changedValues.hasOwnProperty("S49")) {
      // Check S49 but uncheck S42
      if (S49 && !S42) {
        const confirmDescription = getConfirmRequiredPermissionDescription(
          permissionS49?.name ?? "",
          joinArrayString([
            permissionS42?.name ?? "",
            permissionB10?.name ?? "",
            permissionM7?.name ?? "",
          ])
        );
        ConfirmModal({
          onOk: async () => {
            form.setFieldsValue({
              S42: true,
              B10: true,
              M7: true,
            });
          },
          onCancel: () => {
            form.setFieldsValue({
              S49: false,
            });
          },
          className: "confirm__modal confirm__modal-purple-oke",
          title: MESSAGE.MESSAGE_TITLE_AUTHORIZATION_CONFIRMATION_1,
          description: confirmDescription[0],
          extraDescription: confirmDescription[1],

          canceText: LABEL_MESSAGE.CANCEL_MODAL,
          okText: LABEL_MESSAGE.OK_MODAL,
          isCenterWithoutMenu: true,
          height: "auto",
        });
        return true;
      } else if (!S49 && B6) {
        // Uncheck S49 but check B6
        const confirmDescription = getUnselectPermissionDescription(
          permissionS49?.name ?? "",
          joinArrayString([messageB6])
        );
        ConfirmModal({
          onOk: async () => {
            form.setFieldsValue({
              B6: false,
            });
          },
          onCancel: () => {
            form.setFieldsValue({
              S49: true,
            });
          },
          className: "confirm__modal confirm__modal-purple-oke",
          title: MESSAGE.MESSAGE_TITLE_AUTHORIZATION_CONFIRMATION_2,
          description: confirmDescription[0],
          extraDescription: confirmDescription[1],

          canceText: LABEL_MESSAGE.CANCEL_MODAL,
          okText: LABEL_MESSAGE.OK_MODAL,
          isCenterWithoutMenu: true,
          height: "auto",
        });
        return true;
      }
    } else if (changedValues.hasOwnProperty("S43")) {
      // Check S43 but uncheck S42
      if (!S42) {
        const confirmDescription = getConfirmRequiredPermissionDescription(
          permissionS43?.name ?? "",
          joinArrayString([
            permissionS42?.name ?? "",
            permissionB10?.name ?? "",
            permissionM7?.name ?? "",
          ])
        );
        ConfirmModal({
          onOk: async () => {
            form.setFieldsValue({
              S42: true,
              B10: true,
              M7: true,
            });
          },
          onCancel: () => {
            form.setFieldsValue({
              S43: false,
            });
          },
          className: "confirm__modal confirm__modal-purple-oke",
          title: MESSAGE.MESSAGE_TITLE_AUTHORIZATION_CONFIRMATION_1,
          description: confirmDescription[0],
          extraDescription: confirmDescription[1],
          canceText: LABEL_MESSAGE.CANCEL_MODAL,
          okText: LABEL_MESSAGE.OK_MODAL,
          isCenterWithoutMenu: true,
          height: "auto",
        });
        return true;
      } else if (!S43 && (S45 || S44 || B6)) {
        // Uncheck S43 but check S45 or S44 or B6
        const confirmDescription = getConfirmRequiredPermissionDescription(
          permissionS43?.name ?? "",
          joinArrayString([
            permissionS45?.name ?? "",
            permissionS44?.name ?? "",
            permissionB6?.name ?? "",
          ])
        );
        ConfirmModal({
          onOk: async () => {
            form.setFieldsValue({
              S45: false,
              S44: false,
              B6: false,
            });
          },
          onCancel: () => {
            form.setFieldsValue({
              S43: true,
            });
          },
          className: "confirm__modal confirm__modal-purple-oke",
          title: MESSAGE.MESSAGE_TITLE_AUTHORIZATION_CONFIRMATION_1,
          description: confirmDescription[0],
          extraDescription: confirmDescription[1],
          canceText: LABEL_MESSAGE.CANCEL_MODAL,
          okText: LABEL_MESSAGE.OK_MODAL,
          isCenterWithoutMenu: true,
          height: "auto",
        });
        return true;
      }
    } else if (changedValues.hasOwnProperty("S42")) {
      // Action on S42
      if (!S42 && (S49 || S43 || S48 || S46 || B6)) {
        /* Uncheck S42 but check S49 or S43 or S48 or S46 or B6*/
        const confirmDescription = getUnselectPermissionDescription(
          joinArrayString([permissionS42?.name ?? ""]),
          joinArrayString([
            messageS49,
            messageS43,
            messageS45,
            messageS48,
            messageS46,
            messageS44,
            messageS47,
            messageB6,
          ])
        );
        ConfirmModal({
          onOk: async () => {
            form.setFieldsValue({
              S44: false,
              S47: false,
              S49: false,
              S43: false,
              S45: false,
              S48: false,
              S46: false,
              B6: false,
            });
          },
          onCancel: () => {
            form.setFieldsValue({
              S42: true,
            });
          },
          className: "confirm__modal confirm__modal-purple-oke",
          title: MESSAGE.MESSAGE_TITLE_AUTHORIZATION_CONFIRMATION_1,
          description: confirmDescription[0],
          extraDescription: confirmDescription[1],
          canceText: LABEL_MESSAGE.CANCEL_MODAL,
          okText: LABEL_MESSAGE.OK_MODAL,
          isCenterWithoutMenu: true,
          height: "auto",
        });
        return true;
      } else if (!B10 || !M7) {
        /* Check S42 but uncheck B10 or M7  */
        const confirmDescription = getConfirmRequiredPermissionDescription(
          permissionS42?.name ?? "",
          joinArrayString([permissionB10?.name ?? "", permissionM7?.name ?? ""])
        );
        ConfirmModal({
          onOk: async () => {
            form.setFieldsValue({
              B10: true,
              M7: true,
            });
          },
          onCancel: () => {
            form.setFieldsValue({
              S42: false,
            });
          },
          className: "confirm__modal confirm__modal-purple-oke",
          title: MESSAGE.MESSAGE_TITLE_AUTHORIZATION_CONFIRMATION_1,
          description: confirmDescription[0],
          extraDescription: confirmDescription[1],

          canceText: LABEL_MESSAGE.CANCEL_MODAL,
          okText: LABEL_MESSAGE.OK_MODAL,
          isCenterWithoutMenu: true,
          height: "auto",
        });
        return true;
      }
    } else if (changedValues.hasOwnProperty("M7")) {
      // Uncheck M7 but still check S42 or M6 or M9
      if (!M7 && (S42 || M6 || M9)) {
        const confirmDescription = getUnselectPermissionDescription(
          permissionM7?.name ?? "",
          joinArrayString([
            messageM9,
            messageM6,
            messageB6,
            messageS42,
            messageS49,
            messageS43,
            messageS48,
            messageS46,
            messageS45,
            messageS44,
            messageS47,
          ])
        );
        ConfirmModal({
          onOk: async () => {
            form.setFieldsValue({
              M9: false,
              M6: false,
              S42: false,
              S49: false,
              S46: false,
              S43: false,
              S45: false,
              S48: false,
              S44: false,
              S47: false,
              B6: false,
            });
          },
          onCancel: () => {
            form.setFieldsValue({
              M7: true,
            });
          },
          className: "confirm__modal confirm__modal-purple-oke",
          title: MESSAGE.MESSAGE_TITLE_AUTHORIZATION_CONFIRMATION_2,
          description: confirmDescription[0],
          extraDescription: confirmDescription[1],

          canceText: LABEL_MESSAGE.CANCEL_MODAL,
          okText: LABEL_MESSAGE.OK_MODAL,
          isCenterWithoutMenu: true,
          height: "auto",
        });
        return true;
      }
    } else if (changedValues.hasOwnProperty("B10")) {
      // Uncheck B10 but still check S42
      if (!B10 && S42) {
        const confirmDescription = getUnselectPermissionDescription(
          permissionB10?.name ?? "",
          joinArrayString([
            messageM6,
            messageS42,
            messageS49,
            messageS43,
            messageS48,
            messageS46,
            messageS45,
            messageS44,
            messageS47,
          ])
        );
        ConfirmModal({
          onOk: async () => {
            form.setFieldsValue({
              S42: false,
              S49: false,
              S46: false,
              S43: false,
              S45: false,
              S48: false,
              S44: false,
              S47: false,
              B6: false,
            });
          },
          onCancel: () => {
            form.setFieldsValue({
              B10: true,
            });
          },
          className: "confirm__modal confirm__modal-purple-oke",
          title: MESSAGE.MESSAGE_TITLE_AUTHORIZATION_CONFIRMATION_2,
          description: confirmDescription[0],
          extraDescription: confirmDescription[1],

          canceText: LABEL_MESSAGE.CANCEL_MODAL,
          okText: LABEL_MESSAGE.OK_MODAL,
          isCenterWithoutMenu: true,
          height: "auto",
        });
        return true;
      }
    } else if (changedValues.hasOwnProperty("M6")) {
      // Check M6 but uncheck M7
      if (M6 && !M7) {
        const confirmDescription = getConfirmRequiredPermissionDescription(
          permissionM6?.name ?? "",
          permissionM7?.name ?? ""
        );
        ConfirmModal({
          onOk: async () => {
            form.setFieldsValue({
              M7: true,
            });
          },
          onCancel: () => {
            form.setFieldsValue({
              M6: false,
            });
          },
          className: "confirm__modal confirm__modal-purple-oke",
          title: MESSAGE.MESSAGE_TITLE_AUTHORIZATION_CONFIRMATION_1,
          description: confirmDescription[0],
          extraDescription: confirmDescription[1],
          canceText: LABEL_MESSAGE.CANCEL_MODAL,
          okText: LABEL_MESSAGE.OK_MODAL,
          height: "auto",
          isCenterWithoutMenu: true,
        });
        return true;
      }
    }
    return false;
  };

  const getConfirmRequiredPermissionDescription = (
    requiredPermission: string,
    followedPermission: string
  ) => {
    return [
      `${requiredPermission}権限を付与するために、${followedPermission}権限を付与する必要があります。`,
      `${followedPermission}権限も付与してよろしいですか？`,
    ];
  };
  const getUnselectPermissionDescription = (
    unCheckedPermission: string,
    followedPermission: string
  ) => {
    return [
      `${unCheckedPermission}権限を解除するために、${followedPermission}権限を解除する必要があります。`,
      `${followedPermission}権限も解除してよろしいですか？`,
    ];
  };

  const joinArrayString = (arrayString: string[]) => {
    const filterArr = arrayString.filter((element) => element !== "");
    return filterArr.join("、");
  };

  return {
    form,
    loadingOption,
    memberSelect,
    memberChosen,
    currentTab,
    roleId,
    detailRole,
    permissionCategory,
    dataTab1,
    dataTab2,
    role,

    handleScrollMember,
    handleSearchOption,
    handleChosenMember,
    handleRemoveMember,
    handleClickI,
    handleChangeCurrentTab,
    handleSubmit,
    handleChangeFormValue,
    handleCancelSave,
    onFormValuesChange,
    //api
    getListMember,
    getRolePermissionCategory,
    getDetailRole,
    getRoleUserList,
  };
};

export default AOP003Handler;
