import OM007Api from "./infrastructure/api/Api"
import OM007Handler from "./presenter/handler/Handler";
import OM007UI from "./presenter/ui/UI";
import OM007Service from "./usecase/Service";


const OM007Component = () => {
    const om007Api = OM007Api();
    const om007Service = OM007Service(om007Api)
    const om007Handler = OM007Handler(om007Service)
    return <OM007UI handler={om007Handler} />
}

export default OM007Component