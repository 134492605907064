import { BranchPaginationEntity, MemberEntity, RolePaginationEntity } from '../entity/Entity';
import { OM004Api } from './ServiceImpl';

const OM004Service = (om004: OM004Api) => {
    const createNewMember = async (data: MemberEntity): Promise<any> => {
        try {
            return await om004.createNewMember(data);
        } catch (error) {
            throw error;
        }
    };

    const getPresignAvatarUpload = async (params: any): Promise<any> => {
        try {
            return await om004.getPresignAvatarUpload(params);
        } catch (error) {
            throw error;
        }
    };

    const uploadAvatar = async (data: any, url: string, type: string): Promise<any> => {
        try {
            return await om004.uploadAvatar(data, url, type);
        } catch (error) {
            throw error;
        }
    };

    const getBranchList = async (params: any): Promise<BranchPaginationEntity> => {
        return await om004.getBranchList(params);
    };

    const getRoleList = async (params: any): Promise<RolePaginationEntity> => {
        return await om004.getRoleList(params);
    };

    const getCertificateType = async (): Promise<any> => await om004.getCertificateType();

    const updateMemberAvatar = async (params: any): Promise<any> => {
        try {
            return await om004.updateMemberAvatar(params);
        } catch (error) {
            throw error;
        }
    };
    const getGroup = async (): Promise<any> => {
        try {
            return await om004.getGroup();
        } catch (error) {
            throw error;
        }
    };
    return {
        createNewMember,
        getBranchList,
        getRoleList,
        getCertificateType,
        getPresignAvatarUpload,
        uploadAvatar,
        updateMemberAvatar,
        getGroup,
    };
};

export default OM004Service;
