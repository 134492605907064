import { AxiosResponse } from "axios";
import axiosConfig from "../../../../../config/axiosConfig";
import axios from "axios";

const CollapsedTableApi = () => {
  const getMoneyIndirectCostInformationList = async (params: {
    indirectCostId: number;
    constructionId: number;
    sortBy?: string;
    sortType?: string;
    from?: string;
    to?: string;
    page: number;
    size: number;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/money/indirect-cost/information/list";
    return await axiosConfig.get(url, { params });
  };
  const deleteMoneyIndirectCostInformationDelete = async (
    data: any
  ): Promise<AxiosResponse<any, any>> => {
    const url = "/money/indirect-cost/information/delete";
    return await axiosConfig.delete(url, { data });
  };
  const postMoneyIndirectCostInformationCreate = async (
    data: any
  ): Promise<AxiosResponse<any, any>> => {
    const url = "/money/indirect-cost/information/create";
    return await axiosConfig.post(url, data);
  };
  const putMoneyIndirectCostInformationEdit = async (
    data: any
  ): Promise<AxiosResponse<any, any>> => {
    const url = "/money/indirect-cost/information/edit";
    return await axiosConfig.put(url, data);
  };

  const getMoneyIndirectCostInformationInputCategory = async (): Promise<
    AxiosResponse<any, any>
  > => {
    const url = "/money/indirect-cost/information/input-category";
    return await axiosConfig.get(url);
  };

  const getLinkPresignUpload = async (
    params: any
  ): Promise<AxiosResponse<any, any>> => {
    const url = "/project/presign-upload";
    return await axiosConfig.get(url, { params });
  };
  const uploadToS3 = async (
    url: any,
    data: any
  ): Promise<AxiosResponse<any, any>> => {
    return await axios.put(url, data, {
      headers: {
        "Content-Type": data.type,
      },
    });
  };
  const getPresignLinkS3 = async (
    params: any
  ): Promise<AxiosResponse<any, any>> => {
    const url = "/project/presign-link";
    return await axiosConfig.get(url, { params });
  };

  return {
    getMoneyIndirectCostInformationList,
    deleteMoneyIndirectCostInformationDelete,
    postMoneyIndirectCostInformationCreate,
    putMoneyIndirectCostInformationEdit,
    getMoneyIndirectCostInformationInputCategory,
    uploadToS3,
    getPresignLinkS3,
    getLinkPresignUpload,
  };
};

export default CollapsedTableApi;
