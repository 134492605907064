import { OM001Api } from "./ServiceImpl";

const OM001Service = (om001: OM001Api) => {
  const getDetailGroup = async (params: any): Promise<any> => {
    try {
      return await om001.getDetailGroup(params);
    } catch (error) {
      throw error;
    }
  };
  return { getDetailGroup };
};

export default OM001Service;
