import type { ColumnsType } from "antd/es/table";

import IconDelete from "../../../../../assets/icons/delete-red.svg";
import IconEdit from "../../../../../assets/icons/edit.svg";
import IconCheck from "../../../../../assets/icons/icon-check.svg";
import { PM025TableHandler } from "../handler/HandlerImpl";
import COMMON, {
  COMPANY_COLAB_STATUS,
} from "../../../../../common/constants/COMMON";
import moment from "moment";
import helpers from "../../../../../common/helpers/common";
import { TABS_KEY } from "../handler/Handler";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import {
  AUTHORIZATION_CODE,
  SYSTEM_ROLES,
} from "../../../../../common/constants/AUTHORIZATION";
import { Tooltip } from "antd";

interface DataType {
  key: string;
  name: string;
  email: string;
  createdAt: string;
  status: string;
  updatedAt: string;
  numberConstruction: string;
}

const descriptionDelete = (
  <>
    <div>削除すると、復元できません。</div>
    <div>会社マスタを削除してもよろしいでしょうか？</div>
  </>
);

export const COLUMN = (handler: PM025TableHandler) => {
  const permission = useSelector(
    (state: RootState) => state.auth.authData?.permission
  );
  const isRoleAdminBasic = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdminBasic
  );

  const isVisibleButton =
    permission?.includes(AUTHORIZATION_CODE.M6) || isRoleAdminBasic;

  const columnsTab1: ColumnsType<DataType> = [
    {
      title: "会社名",
      dataIndex: "name",
      key: "name",
      width: 140,
      sorter: true,
      showSorterTooltip: false,
      sortDirections: ["ascend", "descend", "ascend"],
      sortOrder: handler.handleSetDefaultSorter("name"),
      render: (_, record: any) => {
        return (
          <div style={{ width: 140, flexWrap: "wrap" }}>
            <span>{record.name}</span>
            {record?.checkSynchronization && (
              <Tooltip title="会社の情報が同期されました。" placement="bottom">
                <img src={IconCheck} />
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      title: "メールアドレス",
      key: "メールアドレス",
      render: (_, record: any) => {
        return (
          <div style={{ width: 250, flexWrap: "wrap" }}>{record.email}</div>
        );
      },
    },
    {
      title: "電話番号",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: 200,
      render: (text: any) => {
        if (!text) return "-";
        return (
          <div style={{ width: 200, flexWrap: "wrap" }}>
            {helpers.getPhoneNumberFormat(text)}
          </div>
        );
      },
    },
    {
      title: "住所",
      dataIndex: "address",
      key: "address",
      width: 220,
      render: (text) => {
        if (!text) return "-";
        return text;
      },
    },
    {
      title: "CCUS事業者ID",
      dataIndex: "ccus_id",
      key: "ccus_id",
      width: 140,
      render: (_, record: any) => {
        return (
          <div style={{ width: 140, flexWrap: "wrap" }}>
            {helpers.convertToCCUSFormat(record?.ccusId)}
          </div>
        );
      },
    },
    {
      title: "作成日",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 120,
      sorter: true,
      showSorterTooltip: false,
      sortDirections: ["ascend", "descend", "ascend"],
      sortOrder: handler.handleSetDefaultSorter("createdAt"),
      render: (_, record: any) => {
        return (
          <div style={{ width: 140, flexWrap: "wrap" }}>
            {moment(record.createdAt).format(COMMON.FORMAT_DATE_CI)}
          </div>
        );
      },
    },
    {
      title: "システム導入状況",
      dataIndex: "status",
      key: "status",
      width: 160,
      sorter: true,
      showSorterTooltip: false,
      sortDirections: ["ascend", "descend", "ascend"],
      sortOrder: handler.handleSetDefaultSorter("status"),
      render: (_, record: any) => (
        <span
          className={`${
            record.status?.code === COMPANY_COLAB_STATUS.ACTIVE_PLAN
              ? "pm018-text-status-activate"
              : "pm018-text-status-deactivate"
          }`}
        >
          {record.status?.name}
        </span>
      ),
    },
    {
      title: "招待履歴",
      dataIndex: "updatedAt",
      key: "updatedAt",
      width: 120,
      sorter: true,
      showSorterTooltip: false,
      sortDirections: ["ascend", "descend", "ascend"],
      sortOrder: handler.handleSetDefaultSorter("updatedAt"),
      render: (_, record: any) => (
        <span>{record?.numberConstruction == 0 ? "無" : "有"}</span>
      ),
    },
    {
      title: "参加した工事数",
      dataIndex: "numberConstruction",
      key: "numberConstruction",
      width: 150,
      sorter: true,
      showSorterTooltip: false,
      sortDirections: ["ascend", "descend", "ascend"],
      sortOrder: handler.handleSetDefaultSorter("numberConstruction"),
      render: (_, record: any) => {
        return record.numberConstruction;
      },
    },
    isVisibleButton
      ? {
          title: "操作",
          key: "操作",
          width: 150,
          render: (_, record) => {
            return (
              <section style={{ display: "flex" }}>
                <div
                  className="button-style"
                  onClick={() => handler.handleEditCompany(record)}
                  style={{ marginRight: 16 }}
                >
                  <img alt="" src={IconEdit} />
                </div>
                <div
                  className="button-style"
                  onClick={() =>
                    handler.handleDeleteCompany(record, descriptionDelete)
                  }
                >
                  <img alt="" src={IconDelete} />
                </div>
              </section>
            );
          },
        }
      : {},
  ];

  const columnsTab2: ColumnsType<DataType> = [
    {
      title: "会社名",
      dataIndex: "name",
      key: "name",
      width: 180,
      sorter: true,
      showSorterTooltip: false,
      sortDirections: ["ascend", "descend", "ascend"],
      sortOrder: handler.handleSetDefaultSorter("name"),
      render: (_, record: any) => {
        return (
          <div style={{ width: 180, flexWrap: "wrap" }}>{record.name}</div>
        );
      },
    },
    {
      title: "メールアドレス",
      key: "メールアドレス",
      render: (_, record: any) => {
        return (
          <div style={{ width: 250, flexWrap: "wrap" }}>{record.email}</div>
        );
      },
    },
    {
      title: "電話番号",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: 180,
      render: (text: any) => {
        if (!text) return "-";
        return (
          <div style={{ width: 180, flexWrap: "wrap" }}>
            {helpers.getPhoneNumberFormat(text)}
          </div>
        );
      },
    },
    {
      title: "住所",
      dataIndex: "address",
      key: "address",
      width: 260,
      render: (text) => {
        if (!text) return "-";
        return text;
      },
    },
    {
      title: "作成日",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 180,
      sorter: true,
      showSorterTooltip: false,
      sortDirections: ["ascend", "descend", "ascend"],
      sortOrder: handler.handleSetDefaultSorter("createdAt"),
      render: (text) => {
        return (
          <div style={{ width: 180, flexWrap: "wrap" }}>
            {moment(text).format(COMMON.FORMAT_DATE_CI)}
          </div>
        );
      },
    },
    isVisibleButton
      ? {
          title: "操作",
          key: "操作",
          width: 150,
          render: (_, record) => {
            return (
              <section style={{ display: "flex" }}>
                <div
                  className="button-style"
                  onClick={() => handler.handleEditCompany(record)}
                  style={{ marginRight: 16 }}
                >
                  <img alt="" src={IconEdit} />
                </div>
                <div
                  className="button-style"
                  onClick={() =>
                    handler.handleDeleteCompany(record, descriptionDelete)
                  }
                >
                  <img alt="" src={IconDelete} />
                </div>
              </section>
            );
          },
        }
      : {},
  ];

  const columnsTab3: ColumnsType<DataType> = [
    {
      title: "会社名", // Company Name
      key: "会社名",
      width: 180 + 32,
      sorter: true,
      dataIndex: "name",
      showSorterTooltip: false,
      sortDirections: ["ascend", "descend", "ascend"],
      sortOrder: handler.handleSetDefaultSorter("name"),
      render: (_, record: any) => {
        return (
          <div style={{ maxWidth: 180 }}>{record?.companyName ?? "-"}</div>
        );
      },
    },
    {
      title: "フリガナ", // Furigana
      key: "フリガナ",
      width: 120 + 32,
      render: (_, record: any) => {
        return record?.furiganaName ?? "-";
      },
    },
    {
      title: "メールアドレス", // email address
      key: "メールアドレス",
      render: (_, record: any) => {
        return (
          <div style={{ width: 250, flexWrap: "wrap" }}>
            {record?.mail ?? "-"}
          </div>
        );
      },
    },
    {
      title: "電話番号", // telephone number
      key: "電話番号",
      width: 200,
      render: (_, record: any) => {
        return record?.phoneNumber
          ? helpers.getPhoneNumberFormat(record.phoneNumber) ?? "-"
          : "-";
      },
    },
    {
      title: "FAX",
      key: "FAX",
      width: 96 + 32,
      render: (_, record: any) => {
        return record?.fax
          ? helpers.getPhoneNumberFormat(record.fax) ?? "-"
          : "-";
      },
    },
    {
      title: "住所", // address
      key: "住所",
      width: 300 + 32,
      render: (_, record: any) => {
        return record?.address ?? "-";
      },
    },
    {
      title: "確認先部署", // Confirmation department
      key: "確認先部署",
      width: 80 + 32,
      render: (_, record: any) => {
        return record?.confirmationDepartment ?? "-";
      },
    },
    {
      title: "確認先担当者名", // Confirmation person name
      key: "確認先担当者名",
      width: 100 + 32,
      render: (_, record: any) => {
        return record?.managerName ?? "-";
      },
    },
    {
      title: "作成日",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 112 + 32,
      sorter: true,
      showSorterTooltip: false,
      sortDirections: ["ascend", "descend", "ascend"],
      sortOrder: handler.handleSetDefaultSorter("createdAt"),
      render: (text) => {
        return (
          <div style={{ width: 112 + 32, flexWrap: "wrap" }}>
            {moment(text).format(COMMON.FORMAT_DATE_CI)}
          </div>
        );
      },
    },
    isVisibleButton
      ? {
          title: "操作",
          key: "操作",
          width: 150,
          render: (_, record) => {
            return (
              <section style={{ display: "flex" }}>
                {/*
              - Click button edit: Mở popup edit thông tin công ty PM029, xem mục #4.2
              Sau khi edit sẽ ánh xạ lại thông tin tên công ty ở các màn: PM002, PM005, PM003, CM006, CM0032, CM0033, CM0062, CM0063, CM0034, CM0042, CM0047, CM0053 
              */}
                <div
                  className="button-style"
                  onClick={() => handler.handleEditCompany(record)}
                  style={{ marginRight: 16 }}
                >
                  <img alt="" src={IconEdit} />
                </div>
                {/*
              - Buton xóa:
                + Điều kiện enable: Khi công ty chưa được chọn vào dự án nào ở popup PM008 
                + Click button ở trạng thái enable: Hiển thị popup confirm (Làm giống màn PM025 hiện tại)
                + Hover vào button ở trạng thái disable thì hiển thị icon cấm
              */}
                <div
                  className="button-style"
                  onClick={() =>
                    handler.handleDeleteCompany(record, descriptionDelete)
                  }
                >
                  <img alt="" src={IconDelete} />
                </div>
              </section>
            );
          },
        }
      : {},
  ];
  switch (handler.tab) {
    case TABS_KEY[1].key:
      return columnsTab2;
    case TABS_KEY[2].key:
      return columnsTab3;
    default:
      return columnsTab1;
  }
};
