import { CM0012_2SliceType } from './../type/Presenter';
import { createSlice } from '@reduxjs/toolkit';

const initialState: CM0012_2SliceType = {
    loading: false,
    isVisible: false,
    refresh: false,
    typeModal: false,
    parentCode: {
        categoryId: 13,
        outsourceCostInformationId: 104,
    },
    headerData: {
        traderName: null,
        contractWorkName: null,
        targetPeriod: null,
    },
    title: '',
};

export const cm0012_2Slice = createSlice({
    name: 'cm0012_2',
    initialState,
    reducers: {
        resetCM0012_2: () => initialState,
        setLoadingCM0012_2: (state, action) => {
            state.loading = action.payload;
        },
        setIsVisibleCM0012_2: (state, action) => {
            state.isVisible = action.payload;
        },
        setRefreshCM0012_2: (state) => {
            state.refresh = !state.refresh;
        },
        setTypeModalCM0012_2: (state, action) => {
            state.typeModal = action.payload;
        },
        setParentCodeCM0012_2: (state, action) => {
            state.parentCode = action.payload;
        },
        setHeaderDataCM0012_2: (state, action) => {
            state.headerData = action.payload;
        },
        setTitle: (state, action) => {
            state.title = action.payload;
        },
    },
});

export const {
    setLoadingCM0012_2,
    setIsVisibleCM0012_2,
    resetCM0012_2,
    setRefreshCM0012_2,
    setTypeModalCM0012_2,
    setParentCodeCM0012_2,
    setHeaderDataCM0012_2,
    setTitle,
} = cm0012_2Slice.actions;

export default cm0012_2Slice.reducer;
