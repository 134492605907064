import { PM002UseCase } from "../../usecase/ServiceImpl";
import { useDispatch, useSelector } from "react-redux";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";

import {
  setAllStatus,
  setAllUsers,
  setData,
  setKeyWordChange,
  setPageChange,
  setPageChangeCard,
  setPageChangeMember,
  setShowModal,
  setStatus,
  setStatusFilterChange,
  setSortKeyName,
  setSortTypeName,
  setSearchKeywordMember,
  setSizeChangeMember,
  setTotalMember,
} from "../slice/Slice";
import { Form } from "antd";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import DocumentSvg from "../../../../../assets/icons/document.svg";
import IconBuildOutlineSvg from "../../../../../assets/icons/ion_build-outline.svg";
import ShieldDoneSvg from "../../../../../assets/icons/Shield Done.svg";
import PaperFailSvg from "../../../../../assets/icons/Paper Fail.svg";
import { useState } from "react";
import moment from "moment";
import { PM005UseCase } from "../../../PM005/usecase/ServiceImpl";

import { NOTIFICATION_TITLE } from "../../../../../common/constants/MESSAGE";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import { setIsModalOpen } from "../../../PM008/presenter/slice/Slice";
import COMMON from "../../../../../common/constants/COMMON";
import {
  pm012ChangeOpen,
  setProject,
} from "../../../PM012/presenter/slice/Slice";

import {
  changeProjectNoti,
  changeViewNoti,
} from "../../../PM009/presenter/slice/Slice";

import { showImageViewerModal } from "../../../../../common/components/image-viewer-modal/ImageViewSlice";
import { showPdfViewerModal } from "../../../../../common/components/pdf-viewer-modal/pdfViewerModalSlice";
import { setProjectDetail } from "../../../PM005/presenter/slice/Slice";
const dataSrc = [
  {
    icon: DocumentSvg,
    code: "estimated",
  },
  {
    icon: IconBuildOutlineSvg,
    code: "underConstruction",
  },
  {
    icon: ShieldDoneSvg,
    code: "complete",
  },
  {
    icon: PaperFailSvg,
    code: "bot",
  },
];

const SORT_TYPE: string = "DESC",
  SORT_BY: string = "updatedAt";

const PM002Handler = (
  PM002Service: PM002UseCase,
  pM005Service: PM005UseCase
) => {
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [newDataStatus, setNewDataStatus] = useState([]);
  const [totalCountDefault, setTotalCountDefault] = useState(0);
  const [pdfUrl, setPdfUrl] = useState("");
  const [showPdf, setShowPdf] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [projectId, setProjectId] = useState(1);
  const [isViewNotifi, setIsViewNotifi] = useState<boolean>(false);
  const [isDetailNotification, setIsDetailNotification] =
    useState<boolean>(false);

  const changeProject = (projectId: number) => {
    setProjectId(projectId);
  };

  const keyword = searchParams.get("keyword") || "";

  const getPdfMap = async (params: any) => {
    try {
      dispatch(setLoading(true));
      const response = await pM005Service.getPdfMap(params);
      setPdfUrl(response.results);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const checkKeywordQueryString = () => {
    if (form.getFieldValue("text") !== keyword) {
      form.setFieldValue("text", keyword);
    }
  };

  const toOtherPageList = () => {
    const params = onParams();
    delete params.size;
    navigate({
      pathname: "/app-pm003",
      search: `?${createSearchParams(params)}`,
    });
  };
  const toOtherPageCard = () => {};

  const getStatus = async (params: any) => {
    try {
      dispatch(setLoading(true));
      const response = await PM002Service.getStatus({
        page: 1,
        size: 1000,
      });
      if (response) {
        dispatch(setAllStatus(response.results));
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };
  const onViewDetail = (record: any) => {
    return navigate(`/app-pm005/${record.id}`);
  };

  const getAllUser = async (params: any) => {
    try {
      dispatch(setLoading(true));
      const response = await PM002Service.getAllUser(params);
      if (response) {
        const newData = response.results.map((element: any) => {
          const objStatus = { ...element };
          objStatus.key = element.id;
          return objStatus;
        });
        dispatch(setAllUsers(newData));
        dispatch(setTotalMember(response));
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const changeShowModal = () => {
    dispatch(setShowModal(true));
  };
  const closeShowModal = () => {
    dispatch(setShowModal(false));
  };
  const getData = async (params: any) => {
    checkKeywordQueryString();
    try {
      dispatch(setLoading(true));
      const response = await PM002Service.getData(params);
      if (response) {
        const totalCount = response.results.status.reduce(
          (acc: any, cur: any) => {
            return acc + cur.count;
          },
          0
        );
        setTotalCountDefault(totalCount);
        const newDataStatus = response.results.status.map((status: any) => {
          const objStatus = { ...status };
          dataSrc.forEach((src) => {
            if (src.code === status.code) {
              objStatus.src = src.icon;
            }
          });
          return objStatus;
        });
        setNewDataStatus(newDataStatus);
        const newData = response.results.project.map((element: any) => {
          const objStatus = { ...element };
          objStatus.statusName = element.status.name;
          objStatus.constructionDate =
            element.constructionFromDate && element.constructionToDate
              ? `${moment(element.constructionFromDate).format(
                  "YYYY 年 MM 月 DD 日"
                )} - ${moment(element.constructionToDate).format(
                  "YYYY 年 MM 月 DD 日"
                )}`
              : "";
          return objStatus;
        });
        dispatch(setData(newData));
        dispatch(setPageChange(response));
        dispatch(setStatus(response));
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const onPageChange = async (page: number) => {
    searchParams.set("page", page + "");
    setSearchParams(searchParams);
  };
  const onPageChangeMember = (page: number) => {
    dispatch(setPageChangeMember(page));
  };
  const onSizeChangeMember = (size: number) => {
    dispatch(setSizeChangeMember(size));
  };

  var myVar: any;
  function onSearchChange() {
    if (myVar) clearTimeout(myVar);
    myVar = setTimeout(function () {
      const searchText = form.getFieldValue("text");
      searchParams.set("keyword", searchText);
      searchParams.set("page", COMMON.DEFAULT_PAGE.toString());
      setSearchParams(searchParams);
    }, 1000);
  }
  const onSearchMembers = (keyword: string) => {
    if (myVar) clearTimeout(myVar);
    myVar = setTimeout(function () {
      dispatch(setSearchKeywordMember(keyword));
    }, 1000);
  };
  const resetKeyworkMember = () => {
    dispatch(setSearchKeywordMember(""));
    form.resetFields();
  };
  const onInfoCardChange = (statusId: number) => {
    dispatch(setStatusFilterChange(statusId));
    searchParams.set("statusId", statusId + "");
    setSearchParams(searchParams);
  };
  const onPageChangeCard = (page: number) => {
    dispatch(setPageChangeCard(page));
    searchParams.set("page", page + "");
    setSearchParams(searchParams);
  };

  const onParams = () => {
    const searchText = form.getFieldValue("text");
    dispatch(setKeyWordChange(searchText));
    const pageParams = searchParams.get("page") || "1";
    const workspaceId = 1;
    const statusId = searchParams.get("statusId") || 0;
    const keywordParams = searchParams.get("keyword") || searchText;
    const params: any = {
      workspaceId,
      statusId,
      size: 6,
      page: pageParams,
      sortBy: SORT_BY,
      sortType: SORT_TYPE,
    };
    if (keywordParams) {
      params.keyword = keywordParams;
      form.setFieldValue("text", keywordParams);
    }
    return params;
  };
  const getParamsMembers = (
    keyword: string,
    page: number,
    size: number,
    sortKey: string,
    sortType: string
  ) => {
    const params: any = {
      companyId: 1, // TODO: Dieu chinh sau khi thong luong
      keyword: keyword,
      page: page,
      size: size,
      sortKey: sortKey,
      sortType: sortType,
    };
    return params;
  };
  const showModal = () => {
    dispatch(setIsModalOpen(true));
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleUpdateStatus = async (params: any) => {
    const data = {
      projectId: params.id,
      statusId: params.statusId,
    };
    try {
      dispatch(setLoading(true));
      const response = await PM002Service.updateProjectInfoStatus(data);
      if (response) {
        SuccessNotification(
          response?.message ??
            NOTIFICATION_TITLE.MESSAGE_DELETE_NOTIFICATION_SUCCESS
        );
        setIsRefresh(!isRefresh);
      }
      return response;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };
  const onSizeChange = (value: number) => {
    searchParams.set("size", value + "");
    searchParams.set("page", COMMON.DEFAULT_PAGE + "");
    setSearchParams(searchParams);
  };

  const onChangeSort = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: any
  ) => {
    dispatch(setSortKeyName(sorter.field));
    dispatch(setSortTypeName(sorter.order.slice(0, -3)));
  };

  const openPdf = () => setShowPdf(true);

  const cancelPdf = () => {
    setShowPdf(false);
    setPdfUrl("");
  };

  const onShowPdfMap = async (record: any) => {
    try {
      dispatch(setLoading(true));
      setPdfUrl("");
      const rootId = record?.rootId ? record?.rootId : record?.id;
      const response = await pM005Service.getPdfMap({
        keyName: "project/" + rootId + "/" + record.document,
      });
      setPdfUrl(response.results);
      setShowPdf(true);
      const checkFile = response.results?.split(".").pop()?.split("?").shift();
      if (checkFile === "pdf") {
        return dispatch(showPdfViewerModal());
      }
      dispatch(showImageViewerModal());
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handlerAddMember = async (params: any) => {
    const data = {
      projectId: params.projectId,
      membersId: params.membersId,
    };
    try {
      dispatch(setLoading(true));
      const response = await PM002Service.handlerAddMember(data);
      if (response) {
        SuccessNotification(
          response?.message ??
            NOTIFICATION_TITLE.MESSAGE_DELETE_NOTIFICATION_SUCCESS
        );
        setIsRefresh(!isRefresh);
      }
      return response.data;
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleShowModalMember = (member: any) => {
    dispatch(pm012ChangeOpen(true));
    dispatch(setProject(member));
    dispatch(setProjectDetail(member));
  };

  const handleViewNotification = (project: any) => {
    dispatch(changeViewNoti(true));
    dispatch(changeProjectNoti(project));
  };

  const funcCheckUpdateStatus = (userId: number) => {
    const idUserLogin = +JSON.parse(localStorage.getItem("user") || "{}")?.id;
    if (!idUserLogin) return true;
    return userId !== idUserLogin;
  };
  const funcAfter = () => {
    const keyword = searchParams.get("keyword");
    const page = searchParams.get("page");
    const statusId = searchParams.get("statusId");
    if (keyword || page || statusId) {
      form.setFieldValue("text", "");
      setSearchParams({});
    } else {
      setIsRefresh(!isRefresh);
    }
  };

  return {
    handleUpdateStatus,
    getData,
    onSearchChange,
    onPageChange,
    setSearchParams,
    onParams,
    toOtherPageList,
    toOtherPageCard,
    onInfoCardChange,
    getStatus,
    changeShowModal,
    closeShowModal,
    showModal,
    getAllUser,
    handleCancel,
    onViewDetail,
    onPageChangeCard,
    onSizeChange,
    getParamsMembers,
    onSearchMembers,
    onChangeSort,
    openPdf,
    cancelPdf,
    getPdfMap,
    onShowPdfMap,
    onPageChangeMember,
    onSizeChangeMember,
    handlerAddMember,
    changeProject,
    resetKeyworkMember,
    setIsViewNotifi,
    setIsDetailNotification,
    handleViewNotification,
    handleShowModalMember,
    funcCheckUpdateStatus,
    funcAfter,

    isViewNotifi,
    isDetailNotification,
    form,
    searchParams,
    totalCountDefault,
    newDataStatus,
    pdfUrl,
    showPdf,
    isRefresh,
    projectId,
  };
};
export default PM002Handler;
