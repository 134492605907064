import React from 'react';
import IconDown from '../../../../../assets/icons/icon-down-small.svg';
import NoDataTableComponent from '../../../../../common/components/no-data/NoDataTable';
import { Empty } from 'antd';
import helpers from '../../../../../common/helpers/common';
import { OffsetCost } from '../../entity/Entity';
import { TAX_CODE } from '../../../../../common/constants/COMMON';
import EyeViewDetailSvg from '../../../../../assets/icons/eye-view-detail.svg';
import ProjectRowComponent from './ProjectRow';

type props = {
    data: any;
    index: number;
    setCompanyExpandKeys: (key: any) => void;
    checkCompanyKeyExpand: (key: any) => boolean;
    setProjectExpandKeys: (key: any) => void;
    checkProjectKeyExpand: (key: any) => boolean;
};

const CompanyRowComponent = ({
    data,
    index,
    setCompanyExpandKeys,
    checkCompanyKeyExpand,
    setProjectExpandKeys,
    checkProjectKeyExpand,
}: props) => {
    return (
        <>
            <tr className="row-level-0 button-style" onClick={() => setCompanyExpandKeys(data.id)}>
                {/*+ No
                STT đánh từ bé đến lớn*/}
                <td colSpan={2}>{index + 1}</td>
                <td>{data?.projectName ?? ''}</td>
                <td></td>
                <td></td>
                <td>{`${helpers.formatNumberMoney(data.paymentAmountExcludeTax)}円`}</td>
                <td>{`${helpers.formatNumberMoney(data.taxAmount)}円`}</td>
                <td>{`${helpers.formatNumberMoney(
                    data.paymentAmountExcludeTax + data.taxAmount,
                )}円`}</td>
                <td>
                    <img
                        alt=""
                        src={IconDown}
                        className={`cm024-icon ${
                            !checkCompanyKeyExpand(data.id) ? '' : 'cm024-icon-active'
                        }`}
                    />
                </td>
            </tr>
            {checkCompanyKeyExpand(data.id) ? (
                <ProjectRowComponent
                    checkProjectKeyExpand={checkProjectKeyExpand}
                    setProjectExpandKeys={setProjectExpandKeys}
                    constructionList={data.constructionList}
                />
            ) : (
                <></>
            )}
            <tr style={{ height: 4 }}></tr>
        </>
    );
};

export default CompanyRowComponent;
