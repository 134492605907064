import { CI001Handler } from "../handler/HandlerImpl";
import MenuWrapperComponent from "../../../../../common/components/menu-wrapper";
import CI001BodyUI from "./CI001BodyUI";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { TYPE_USER } from "../../../../CR/CR003/presenter/handler/Handler";
import ModalApprove from "../../../../../common/components/approval-flow/ModalApprove";
import MESSAGE from "../../../../../common/constants/MESSAGE";
import HomeSvg from "../../../../../assets/icons/home.svg";
import { CI001Header } from "./CI001Header";
import "../../../../../assets/styles/CI/ci001.css";
import ImageViewerModalComponent from "../../../../../common/components/image-viewer-modal/ImageViewerComponent";
import PdfViewerModalComponent from "../../../../../common/components/pdf-viewer-modal/PdfViewerModalComponent";

interface props {
  handler: CI001Handler;
}
const SCREEN_ID = "1";

const CI001UI = ({ handler }: props) => {
  const { projectId, constructionId } = useParams();

  useEffect(() => {
    if (projectId) handler.getProjectForPermission(projectId);
  }, []);

  useEffect(() => {
    const unloadCallback = (event: any) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    if (handler.isEdit && handler.isChangeEdit) {
      window.addEventListener("beforeunload", unloadCallback);
    }
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, [handler.isEdit, handler.isChangeEdit]);

  useEffect(() => {
    handler.getApprovalProcess({ constructionId });
  }, [constructionId, handler.refreshApprove]);

  useEffect(() => {
    handler.getBasicInformation({ constructionId });
  }, [constructionId, handler.currentTab, handler.isRefresh]);

  useEffect(() => {
    handler.getScreenMaster();
  }, [handler.isRefresh]);

  useEffect(() => {
    handler.getPaymentTerm({ constructionId });
  }, [constructionId, handler.currentTab, handler.isRefreshPayment]);

  const BREADCRUMB_CI001 = [
    {
      title: "案件管理",
      url: "/app-pm002",
      icon: (
        <img
          style={{ paddingBottom: 2, paddingRight: 8 }}
          alt=""
          src={HomeSvg}
        />
      ),
    },
    {
      title: "案件詳細",
      url: `/app-pm005/${projectId}`,
      icon: "",
    },
    {
      title: "工事引合書",
      url: "",
      icon: "",
    },
  ];

  return (
    <MenuWrapperComponent
      chosenKey={81}
      openKey={8}
      onClickMenu={handler.onClickMenu}
    >
      <CI001Header handler={handler} />
      <CI001BodyUI handler={handler} />
      <ModalApprove
        isApproveOrReject={handler.isApproveOrReject}
        visible={handler.isApprove}
        handleCancel={handler.handleCancelApprove}
        handleOk={(note) => handler.handleOkApprove(constructionId, note)}
        form={handler.formReject}
        // description={
        //   handler.checkAutApprove(
        //     handler.currentUserApproval,
        //     handler.listApprovalProcess
        //   ) === TYPE_USER.owner_submit
        //     ? MESSAGE.MESSAGE_DESCRIPTION_CI_CONFIRM
        //     : ""
        // }
        description={handler.descriptionPopup}
        title={handler.titlePopup}
      />
      <>
        <ImageViewerModalComponent
          imageURL={
            handler.currentTab == "1"
              ? handler.pdfUrl
              : handler.pdfUrlPaymentTerm
          }
          title={
            handler.currentTab == "1"
              ? handler.fileName
              : handler.fileNamePaymentTerm
          }
        />
        <PdfViewerModalComponent
          pdfURL={
            handler.currentTab == "1"
              ? handler.pdfUrl
              : handler.pdfUrlPaymentTerm
          }
          title={
            handler.currentTab == "1"
              ? handler.fileName
              : handler.fileNamePaymentTerm
          }
        />
      </>
    </MenuWrapperComponent>
  );
};

export default CI001UI;
