import React from 'react';
import SW02Api from './infrastructure/api/Api';
import SW02Service from './usecase/Service';
import SW02Handler from './presenter/handler/Handler';
import MainUI from './presenter/ui/UI';

import '../../../assets/styles/SW/sw02.css';

const SW02Component = () => {
    const api = SW02Api();
    const service = SW02Service(api);
    const handler = SW02Handler(service);
    return <MainUI handler={handler} />;
};

export default SW02Component;
