/* eslint-disable no-empty */
// Built-in
import React from "react";
import { useState } from "react";
import { DEFAULT_THRESHOLD } from "../..";

type props = {
  update: (data: any) => Promise<void>;
};

const Handler = ({ update }: props) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const [inputValue, setInputValue] = useState<number>(DEFAULT_THRESHOLD);
  const [isThreshold, setIsThreshold] = useState<boolean>(false);

  const onThresholdChange = (value: any) => {
    if (isThreshold && isEdit) setInputValue(value);
  };

  const cancelEdit = () => {
    setInputValue(data?.threshold ?? DEFAULT_THRESHOLD);
    setIsEdit(false);
  };

  const updateData = async () => {
    try {
      await update({
        ...data,
        enable: isThreshold,
        threshold: inputValue,
      });
      setIsEdit(false);
    } catch (error) {}
  };

  const updateThreshold = async (value: any) => {
    try {
      await update({
        ...data,
        enable: value,
        threshold: data.threshold,
      });
      // success => set state
      setIsThreshold(value);
    } catch (error) {}
  };

  return {
    setIsEdit,
    setData,
    setInputValue,
    setIsThreshold,
    updateData,
    updateThreshold,
    cancelEdit,
    onThresholdChange,
    data,
    isEdit,
    inputValue,
    isThreshold,
  };
};

export default Handler;
