// Library
import React from "react";
import { useLocation, useSearchParams } from "react-router-dom";

// Components
import BreadcrumbComponent from "../../../../../common/components/breadcrumb/BreadcrumbComponent";
import MenuWrapperComponent from "../../../../../common/components/menu-wrapper";
import { CM001TitleComponent } from "./CM001TitleComponent";
import { CM001ContentComponent } from "./CM001ContentComponent";

// Services
import { CM001Handler } from "../handler/HandlerImpl";

// icons
import HomeSvg from "../../../../../assets/icons/home.svg";
import {
  DEFAULT_TAB_VALUE,
  PROJECT_MANAGER_PARAM_ID_KEY,
  PROJECT_PARAM_ID_KEY,
  PROJECT_PARAM_KEY,
  RANGE_DATE_PARAM_KEY,
} from "../handler/Handler";
import { DEFAULT_TAB_KEY } from "../../../CM0019/presenter/handler/Handler";

// Commons

type props = {
  handler: CM001Handler;
};

const MainUI = ({ handler }: props) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const projectId: string = searchParams.get(PROJECT_PARAM_ID_KEY) ?? "0";
  const tabValue: string =
    searchParams.get(DEFAULT_TAB_KEY) ?? DEFAULT_TAB_VALUE;
  const projectName: string = searchParams.get(PROJECT_PARAM_KEY) ?? "";
  const rangeDate: string = searchParams.get(RANGE_DATE_PARAM_KEY) ?? "";

  React.useEffect(() => {
    handler.initialTab(tabValue);
  }, [tabValue]);

  let BREADCRUMB_CM001: any[] = [];

  let chosenKey = 51;
  let openKey = 5;

  switch (location.pathname) {
    case "/app-cm001/pm005":
      chosenKey = 81;
      openKey = 8;
      BREADCRUMB_CM001 = [
        {
          title: "案件管理",
          url: ``,
          icon: (
            <img
              style={{ paddingBottom: 2, paddingRight: 8 }}
              alt=""
              src={HomeSvg}
            />
          ),
        },
        {
          title: "案件一覧",
          url: "/app-pm002",
          icon: "",
        },
        {
          title: "案件詳細",
          url: `/app-pm005/${projectId}`,
          icon: "",
        },
        {
          title: "案件別収支管理",
          url: "",
          icon: "",
        },
      ];
      break;
    default:
      BREADCRUMB_CM001 = [
        {
          title: "収支管理",
          url: "",
          icon: (
            <img
              style={{ paddingBottom: 2, paddingRight: 8 }}
              alt=""
              src={HomeSvg}
            />
          ),
        },
        {
          title: "案件別収支管理",
          url: "",
          icon: "",
        },
      ];
      break;
  }

  return (
    <MenuWrapperComponent chosenKey={chosenKey} openKey={openKey}>
      <BreadcrumbComponent data={BREADCRUMB_CM001} />
      <div className="cm001-wrapper">
        <h1>案件別収支管理</h1>
        <div className="cm001-content">
          <CM001TitleComponent
            projectName={projectName}
            rangeDate={rangeDate}
          />
          <CM001ContentComponent
            handler={handler}
            searchParams={location.search}
            projectName={projectName}
            rangeDate={rangeDate}
            tab={tabValue}
          />
        </div>
      </div>
    </MenuWrapperComponent>
  );
};

export default MainUI;
