import React from "react";
import { Handler } from "../handler/HandlerImpl";
import { Button, Form, Input, Modal } from "antd";
import REGEX from "../../../../../common/constants/REGEX";
import MESSAGE from "../../../../../common/constants/MESSAGE";
import COMMON from "../../../../../common/constants/COMMON";
import {
  inputPhoneNumberFormat,
  onBlurTrimFormItem,
  typeInteger,
  typeIntegerORD,
} from "../../../../../common/helpers/typeInput";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import "../../../../../assets/styles/IC/IC4.css";
import XIconModal from "../../../../../assets/icons/x-icon-modal.svg";
import { validateInput } from "../../../../ORD/ORD002_1/helper";
import helpers from "../../../../../common/helpers/common";
interface props {
  handler: Handler;
}
const MainUI = ({ handler }: props) => {
  const isOpenIC4Modal = useSelector(
    (state: RootState) => state.ic4Modal.isOpenIC4
  );
  const profileCompany = useSelector(
    (state: RootState) => state.ic1.profileCompany
  );
  return (
    <Modal
      className="group-detail-modal h1-modal ic5-modal"
      style={{ marginLeft: 300, marginTop: 40, marginBottom: 40 }}
      visible={isOpenIC4Modal}
      closable={false}
      maskClosable={false}
      width={825}
      centered={true}
      footer={null}
    >
      <div
        className="w-100-percent "
        style={{
          height: 56,
          marginBottom: 32,
          display: "flex",
          alignItems: "top",
          justifyContent: "space-between",
        }}
      >
        <div className="header-modal-title-om004 ">
          アドミン メールアドレス変更依頼
        </div>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handler.onCancelModalIC4()}
        >
          <img alt="" src={XIconModal} style={{ height: 18, width: 18 }} />
        </div>
      </div>
      <Form
        name="ic4-modal"
        form={handler.form}
        onFinish={handler.handleSubmitForm}
        onValuesChange={handler.checkDirty}
        autoComplete="off"
      >
        <div className="ic4-modal-form-item">
          <div className="ic4-modal-form-item-label">
            会社名<span style={{ color: "#FF0909" }}>*</span>
          </div>
          <Form.Item
            className="ic4-modal-form-item-input"
            name="companyName"
            rules={[
              validateInput({
                maxLength: 50,
                required: true,
                regex: REGEX.NOT_VIETNAM,
              }),
            ]}
          >
            <Input
              placeholder="例：株式会社ONE Unit"
              size="large"
              maxLength={50}
              onBlur={() => onBlurTrimFormItem(handler.form, "companyName")}
            />
          </Form.Item>
        </div>

        <div className="ic4-modal-form-item">
          <div className="ic4-modal-form-item-label">
            {" "}
            名前<span style={{ color: "#FF0909" }}>*</span>
          </div>
          <Form.Item
            className="ic4-modal-form-item-input"
            name="name"
            rules={[
              validateInput({
                maxLength: 50,
                required: true,
                regex: REGEX.NOT_VIETNAM,
              }),
            ]}
          >
            <Input
              placeholder="例：山田　太郎（依頼者の名前）"
              size="large"
              maxLength={50}
              onBlur={() => onBlurTrimFormItem(handler.form, "name")}
            />
          </Form.Item>
        </div>
        <div className="ic4-modal-form-item">
          <div className="ic4-modal-form-item-label">
            {" "}
            電話番号<span style={{ color: "#FF0909" }}>*</span>
          </div>

          <Form.Item
            className="ic4-modal-form-item-input"
            name="phoneNumber"
            rules={[
              () => ({
                validator(_, value) {
                  if (value && value !== "") {
                    value = value.trim().replaceAll(" ", "");
                    value = value.replaceAll("-", "");
                    if (!REGEX.PHONENUMBER_2.test(value))
                      return Promise.reject(MESSAGE.MESSAGE_WRONG_PHONE_FORMAT);
                    if (value?.length < 10) {
                      return Promise.reject(MESSAGE.MESSAGE_INVALID_INPUT);
                    }
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(MESSAGE.MESSAGE_REQUIRED_FIELD)
                  );
                },
              }),
            ]}
          >
            <Input
              onKeyPress={typeInteger}
              onPaste={typeInteger}
              onChange={(e) => {
                helpers.preventInputJapanese(e);
                inputPhoneNumberFormat(e, "phoneNumber", handler.form);
              }}
              autoComplete="off"
              size="large"
              maxLength={COMMON.MAX_LENGTH_PHONE_NUMBER}
              onBlur={() => onBlurTrimFormItem(handler.form, "phoneNumber")}
              placeholder="例：0012345678　ハイフン(ー)なし　半角"
            />
          </Form.Item>
        </div>
        <div className="ic4-modal-form-item">
          <div className="ic4-modal-form-item-label"> 現在のメールアドレス</div>
          <div className="text-content">{profileCompany.email}</div>
        </div>

        <div className="ic4-modal-form-item">
          <div className="ic4-modal-form-item-label">
            {" "}
            新しいメールアドレス
            <span style={{ color: "#FF0909" }}>*</span>
          </div>
          <Form.Item
            className="ic4-modal-form-item-input"
            name="email"
            rules={[
              () => ({
                validator(_, value) {
                  // required
                  if (!value || value.trim() === "")
                    return Promise.reject(MESSAGE.MESSAGE_REQUIRED_FIELD);
                  if (value === profileCompany.email) {
                    return Promise.reject(MESSAGE.MESSAGE_DUPLICATE_EMAIL);
                  }
                  // test regex
                  if (!REGEX.EMAIL_COMPANY.test(value.trim())) {
                    return Promise.reject(MESSAGE.MESSAGE_WRONG_EMAIL_FORMAT);
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              placeholder="例：user@oneunit.com"
              size="large"
              onChange={(e) => {
                helpers.preventInputJapanese(e);
              }}
              maxLength={50}
              onBlur={() => helpers.onBlurTrimFormItem(handler.form, "email")}
            />
          </Form.Item>
        </div>

        <div className="ic4-modal-btn">
          <Form.Item>
            <Button
              htmlType="submit"
              size="large"
              className="button-purple"
              style={{ width: 118, marginRight: 16 }}
            >
              送信
            </Button>
          </Form.Item>
          <Button
            size="large"
            onClick={() => handler.onCancelModalIC4()}
            style={{ width: 118, color: "#666" }}
          >
            キャンセル
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default MainUI;
