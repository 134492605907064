import React from 'react';
import CompanyRowComponent from './CompanyRow';
import { RootState } from '../../../../../store';
import { useSelector } from 'react-redux';
import helpers from '../../../../../common/helpers/common';
import Handler from '../../../../AM/AM042/presenter/handler/Handler';
import IconExtend from '../../../../../assets/icons/extend-purple.svg';
import IconNarrow from '../../../../../assets/icons/narrow-purple.svg';
import {
    ReSummarySubmittedProjectOutsourceCostTotalResults,
    SummaryTotal,
} from '../../entity/Entity';
interface props {
    projects: any[];
    summaryTotal: SummaryTotal;
    openCM0027_1: (value: any) => void;
    openCM0027_2: (value: any) => void;
    setCompanyExpandKeys: (key: any) => void;
    checkCompanyKeyExpand: (key: any) => boolean;
    setProjectExpandKeys: (key: any) => void;
    checkProjectKeyExpand: (key: any) => boolean;
    handleScroll: (e: any, documentId: number) => Promise<void>;
    showOffsetColumn: (isShow: boolean) => void;
}

const Table = ({
    projects,
    summaryTotal,
    openCM0027_1,
    openCM0027_2,
    setCompanyExpandKeys,
    checkCompanyKeyExpand,
    setProjectExpandKeys,
    checkProjectKeyExpand,
    handleScroll,
    showOffsetColumn,
}: props) => {
    const documentId = useSelector((state: RootState) => state.cm0027.documentId);
    const isShowOffset = useSelector((state: RootState) => state.cm0027.isShowOffset);

    const TABLE_HEADER_WITH_OFFSET = (
        <thead>
            <tr>
                <th rowSpan={2} colSpan={2} style={{ minWidth: 150 }}>
                    No
                </th>
                <th rowSpan={2} style={{ minWidth: 150 }}>
                    案件名
                </th>
                <th rowSpan={2} style={{ minWidth: 200 }}>
                    工事名
                </th>
                <th rowSpan={2} style={{ minWidth: 100 }}>
                    契約工事名
                </th>
                <th rowSpan={2} style={{ minWidth: 100 }}>
                    計上金額
                </th>
                <th colSpan={2} style={{ minWidth: 400 }} className="td-border-top">
                    案件別相殺金額
                </th>
                <th colSpan={2} style={{ minWidth: 400 }} className="td-border-top">
                    業者別相殺金額
                </th>
                <th rowSpan={2} style={{ minWidth: 200 }} className="td-border-top">
                    <div className="center-item">
                        <div>相殺合計金額</div>
                        <div
                            className="button-style"
                            style={{ marginLeft: 8, marginBottom: 2 }}
                            onClick={() => showOffsetColumn(false)}
                        >
                            <img alt="" src={IconNarrow} />
                        </div>
                    </div>
                </th>
                <th rowSpan={2} style={{ minWidth: 150 }}>
                    支払金額(税抜)
                </th>
                <th rowSpan={2} style={{ minWidth: 150 }}>
                    消費税
                </th>
                <th rowSpan={2} style={{ minWidth: 150 }}>
                    支払金額(税込)
                </th>
                <th rowSpan={2} style={{ minWidth: 40 }}></th>
            </tr>
            <tr>
                <th>課税</th>
                <th>非課税</th>
                <th>課税</th>
                <th>非課税</th>
            </tr>
        </thead>
    );
    const TABLE_HEADER_WITHOUT_OFFSET = (
        <thead>
            <tr>
                <th colSpan={2} style={{ minWidth: 150 }}>
                    No
                </th>
                <th style={{ minWidth: 150 }}>案件名</th>
                <th style={{ minWidth: 200 }}>工事名</th>
                <th style={{ minWidth: 100 }}>契約工事名</th>
                <th style={{ minWidth: 100 }}>計上金額</th>
                <th style={{ minWidth: 200 }} className="td-border-top">
                    <div className="center-item">
                        <div>相殺合計金額</div>
                        <div
                            className="button-style"
                            style={{ marginLeft: 8, marginBottom: 2 }}
                            onClick={() => showOffsetColumn(true)}
                        >
                            <img alt="" src={IconExtend} />
                        </div>
                    </div>
                </th>
                <th style={{ minWidth: 150 }}>支払金額(税抜)</th>
                <th style={{ minWidth: 150 }}>消費税</th>
                <th style={{ minWidth: 150 }}>支払金額(税込)</th>
                <th style={{ minWidth: 40 }}></th>
            </tr>
        </thead>
    );
    return (
        <div
            className="cm024-table"
            onScroll={(e: any) => {
                if (documentId) handleScroll(e, documentId);
            }}
        >
            <table>
                {isShowOffset ? TABLE_HEADER_WITH_OFFSET : TABLE_HEADER_WITHOUT_OFFSET}
                <tbody>
                    {projects.map((element, index) => (
                        <CompanyRowComponent
                            openCM0027_2={openCM0027_2}
                            openCM0027_1={openCM0027_1}
                            key={element.id}
                            index={index}
                            data={element}
                            setCompanyExpandKeys={setCompanyExpandKeys}
                            checkCompanyKeyExpand={checkCompanyKeyExpand}
                            setProjectExpandKeys={setProjectExpandKeys}
                            checkProjectKeyExpand={checkProjectKeyExpand}
                        />
                    ))}
                </tbody>
                <tfoot>
                    <tr className="table-foot">
                        <td colSpan={2}>合計金額</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{`${helpers.formatNumberMoney(summaryTotal.actualCost)}円`}</td>
                        {isShowOffset ? (
                            <>
                                <td>{`${helpers.formatNumberMoney(
                                    summaryTotal.offsetCostWithTax,
                                )}円`}</td>
                                <td>{`${helpers.formatNumberMoney(
                                    summaryTotal.offsetCostWithoutTax,
                                )}円`}</td>
                                <td>{`${helpers.formatNumberMoney(
                                    summaryTotal.offsetCostSummaryWithTax,
                                )}円`}</td>
                                <td>{`${helpers.formatNumberMoney(
                                    summaryTotal.offsetCostSummaryWithoutTax,
                                )}円`}</td>
                            </>
                        ) : (
                            <></>
                        )}
                        <td>{`${helpers.formatNumberMoney(summaryTotal.totalOffset)}円`}</td>
                        <td>{`${helpers.formatNumberMoney(
                            summaryTotal.paymentAmountExcludingTax,
                        )}円`}</td>
                        <td>{`${helpers.formatNumberMoney(summaryTotal.consumptionTax)}円`}</td>
                        <td>{`${helpers.formatNumberMoney(
                            summaryTotal.paymentAmountIncludingTax,
                        )}円`}</td>
                        <td></td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};

export default Table;
