import { createSlice } from "@reduxjs/toolkit";
import { AM004SliceType } from "../../entity/Entity";

export const initialState: AM004SliceType = {
  isRefresh: false,
  isCollapsed: false,
  tableColumns: {
    columns: [],
    isNext: true,
    isPrev: true,
  },
};

export const AM004Slice = createSlice({
  name: "am004",
  initialState,
  reducers: {
    refreshAM004: (state) => {
      state.isRefresh = !state.isRefresh;
    },
    setIsCollapsed: (state, action) => {
      state.isCollapsed = action.payload;
    },
    setTableColumns: (state, action) => {
      state.tableColumns = action.payload;
    },
  },
});

export const { refreshAM004, setIsCollapsed, setTableColumns } =
  AM004Slice.actions;

export default AM004Slice.reducer;
