import React from 'react';
import { HandlerImpl } from '../handler/HandlerImpl';
import { Form, Select, Button, DatePicker, Input, Tag, Empty } from 'antd';

import CalendarSvg from '../../../../../assets/icons/calendar-purple.svg';
import IconExport from '../../../../../assets/icons/upload-purple.svg';
import IconX from '../../../../../assets/icons/close-square-purple.svg';
import IconTick from '../../../../../assets/icons/tick-square.svg';
import CloseIcon from '../../../../../assets/icons/x-icon-modal.svg';
import COMMON from '../../../../../common/constants/COMMON';
import locale from 'antd/es/date-picker/locale/ja_JP';
import 'moment/locale/ja';
import TableView from './Table';
import { useSearchParams } from 'react-router-dom';
import {
    FROM_MONTH_KEY,
    PROJECT_PARAM_ID_KEY,
    Project_ALL,
    TO_MONTH_KEY,
} from '../handler/Handler';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { AUTHORIZATION_CODE, SYSTEM_ROLES } from '../../../../../common/constants/AUTHORIZATION';
import UploadLightSVG from '../../../../../assets/icons/upload-icon-purple.svg';
import helpers from '../../../../../common/helpers/common';
import moment from 'moment';

const { Item } = Form;
const { RangePicker } = DatePicker;

interface props {
    handler: HandlerImpl;
}

const Body = ({ handler }: props) => {
    const [searchParams] = useSearchParams();
    const projectId: string = searchParams.get(PROJECT_PARAM_ID_KEY) ?? '';

    const permission = useSelector((state: RootState) => state.auth.authData?.permission);

    const isRoleAdmin = useSelector((state: RootState) => state.auth?.authData?.isRoleAdmin)

    const isRoleAdminSystem =
        useSelector((state: RootState) => state.auth?.authData?.isRoleAdminSystem)

    const from =
        searchParams.get(FROM_MONTH_KEY) ??
        `${helpers.getFilterTimeMonth(
            moment().startOf("month").format(COMMON.FORMAT_DATE4)
        )}`;
    const to =
        searchParams.get(TO_MONTH_KEY) ??
        `${helpers.getFilterTimeMonthTo(moment().format(COMMON.FORMAT_DATE4))}`;
    return (
        <div className="am028-body cm0019-body">
            <Form
                name="cm034-cm53-filter"
                className="am028-filter"
                form={handler.formFilter}
                onValuesChange={handler.handleFilterMonth}
            // onFinish={handler.handleFinishFormFilter}
            >
                <div className="am028-filter-chosen">
                    <div className="am028-filter-item">
                        <span className="am028-filter-item-label">案件名</span>
                        <Item name="project">
                            <Select
                                className="cm019-select am028-select-status"
                                placeholder=""
                                showArrow
                                showSearch
                                allowClear
                                onClear={handler.handleClearDropdown}
                                listHeight={130}
                                filterOption={() => true}
                                loading={handler.loadingOption}
                                onSearch={(e) => handler.handleSearchOptionConstruction(e, from, to)}
                                onSelect={handler.handleSelected}
                                getPopupContainer={(trigger: any) => trigger.parentNode}
                                tagRender={({ label, closable, onClose }: any) => {
                                    return (
                                        <Tag
                                            closable={closable}
                                            onClose={onClose}
                                            color="rgba(231, 230, 255, 1)"
                                            closeIcon={
                                                <img
                                                    style={{ width: 10, marginLeft: 6 }}
                                                    alt=""
                                                    src={CloseIcon}
                                                />
                                            }
                                            style={{
                                                margin: '2px 4px 2px 0',
                                                display: 'flex',
                                                alignItems: 'center',
                                                height: 28,
                                                borderRadius: 4,
                                                color: '#222222',
                                                fontSize: 14,
                                                fontWeight: 400,
                                            }}
                                        >
                                            {label[0]?.props?.children}
                                        </Tag>
                                    );
                                }}
                                onPopupScroll={(value) => handler.handleScrollConstruction(value, from, to)}
                                notFoundContent={
                                    <Empty
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        description="該当なデータはありません"
                                    />
                                }
                            >
                                {handler.listProject.map((select) => {
                                    return (
                                        <Select.Option
                                            // style={{ alignItems: 'center' }}
                                            key={select.id}
                                            value={select.id}
                                        >
                                            {/* <Tooltip title={select?.name} placement="topLeft">
                                                    {handler.formatLongString(
                                                        select?.name,
                                                        MAX_LENGTH_TEXT,
                                                    )}
                                                </Tooltip> */}
                                            {select?.name}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Item>
                    </div>
                    <div className="am028-filter-item">
                        <Item name="targetPeriod">
                            <RangePicker
                                allowClear={false}
                                inputReadOnly
                                size="large"
                                locale={locale}
                                picker="month"
                                placeholder={['', '']}
                                className="am019-range"
                                // onChange={handler.handleFilterMonth}
                                onOpenChange={handler.handleOpenCalendar}
                                format={COMMON.FORMAT_DATE_JA_MONTH}
                                getPopupContainer={(trigger: any) => trigger.parentNode}
                                suffixIcon={
                                    <img alt="" src={CalendarSvg} className="icon-calender" />
                                }
                            />
                        </Item>
                    </div>
                </div>
                <div className="am028-filter-btn cm0019-filter-btn">
                    <Button
                        icon={<img style={{ marginRight: 8 }} alt="" src={handler.disableBtn === 'btn1' ? IconTick : IconX} />}
                        size="large"
                        className={`${handler.disableBtn === 'btn1' ? 'button-purple' : ''} cm019-btn-tick`}
                        // disabled={handler.disableBtn === 'btn1'}
                        onClick={() => {
                            if (handler.disableBtn !== 'btn1') handler.handleTaxIncluded()
                        }}
                        style={{ color: '#605bff' }}
                    >
                        税込
                    </Button>
                    <Button
                        icon={<img alt="" style={{ marginRight: 8 }} src={handler.disableBtn === 'btn2' ? IconTick : IconX} />}
                        size="large"
                        className={`${handler.disableBtn === 'btn2' ? 'button-purple' : ''} cm019-btn-tick`}
                        // disabled={handler.disableBtn === 'btn2'}
                        onClick={() => {
                            if (handler.disableBtn !== 'btn2') handler.handleTaxExcluded()
                        }}
                        style={{ color: '#605bff' }}
                    >
                        税抜
                    </Button>
                    {permission?.includes(AUTHORIZATION_CODE.B9) || isRoleAdmin || isRoleAdminSystem ? (
                        <Button
                            className="button-brown-3"
                            style={{ border: '1px solid #605BFF', width: 106, marginLeft: 16 }}
                            size="large"
                            onClick={() => handler.handleExport()}
                        >
                            <div className="center-item">
                                <img
                                    alt=""
                                    src={UploadLightSVG}
                                    style={{
                                        marginRight: 8,
                                        fontWeight: 700,
                                        fontSize: 14,
                                    }}
                                />
                                <div
                                    className="export-button"
                                    style={{ color: "#605BFF" }}
                                >
                                    出力
                                </div>
                            </div>
                        </Button>
                    ) : (
                        <div></div>
                    )}
                </div>
            </Form>
            <div className="cm-body">
                <TableView handler={handler} />
            </div>
        </div>
    );
};

export default Body;
