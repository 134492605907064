import CM006Api from '../CM006/infrastructure/api/Api';
import CM006Service from '../CM006/usecase/Service';
import CM0014Api from './infrastructure/api/Api';
import CM0014Handler from './presenter/handler/Handler';
import CM0014UI from './presenter/ui/UI';
import CM0014Service from './usecase/Service';

type props = {
    onSubmit?: () => void;
    onReject?: () => void;
    disableSubmitOrReject?: boolean;
    isCheckbox?: boolean
};

const CM0014Component = (props: props) => {
    const cm006Api = CM006Api();
    const cm006Service = CM006Service(cm006Api);

    const cm0014Api = CM0014Api();
    const cm0014Service = CM0014Service(cm0014Api);

    const cm0014Handler = CM0014Handler(cm0014Service, cm006Service);
    return <CM0014UI handler={cm0014Handler} {...props} />;
};

export default CM0014Component;
