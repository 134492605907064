const STATUS = {
  UPLOADING: "uploading",
  DONE: "done",
  UNDER_CONSTRUCTION: "施工中",
};

export const STATUS_PM008 = {
  RED: 6,
  GREEN: 5,
  BLUE: 4,
};

export const STATUS_REQUEST = {
  REQUEST_WAITING_FOR_APPROVAL: "request_waiting_for_approval",
  REQUEST_APPROVED: "request_approved",
  REQUEST_REJECT: "request_reject",
};

export const SESSION_OF_DAY_CODE = {
  OFF_ALL_DAY_CODE: "full_day",
  OFF_MOR_CODE: "half_day_mor",
  OFF_AF_CODE: "half_day_af",
};

export const TYPE_REQUEST = {
  ATTENDANCE: {
    title: "打刻申請",
    type: "attendance",
  },
  LATE_EARLY: {
    title: "早退・遅刻申請 ",
    type: "lateEarly",
  },
  LEAVE: {
    title: "休暇申請",
    type: "leave",
  },
  CHECKIN_CHECKOUT: {
    title: "",
    type: "checkinCheckout",
  },
};

export const ATTENDANCE_CATEGORY = {
  CHECK_IN: "checkin",
  CHECK_OUT: "checkout",
};

export const CCUS_VERRIFICATION_STATE = {
  INITIAL: 0,
  DONE: 10,
  ERROR: 20,
  INVALID: 30,
};

export default STATUS;
