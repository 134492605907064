import { Checkbox } from "antd";
import { MemberType } from "../../entity/Entity";
import React from "react";
import DisabledCheckboxSvg from "../../../../../assets/icons/disabled-checkbox.svg"
import helpers from "../../../../../common/helpers/common";

type Props = {
    chosenMembers: MemberType[],
    members: any[],
    onChooseCheckbox: (value: any) => void;
}

const MemberCheckBoxComponent = ({ chosenMembers, members, onChooseCheckbox }: Props) => {
    return <Checkbox.Group className="w-100-percent" value={chosenMembers.map((element: any) => element.id)} onChange={onChooseCheckbox} >
        {
            members.map((element: MemberType) => <React.Fragment key={element.id}>
                <div className="pm011-member-item" >
                    <div className="pm011-member-item-checkbox">
                        {
                            element.approve ?
                                <img alt="" src={DisabledCheckboxSvg} style={{ width: 16, height: 16 }} />
                                :
                                <Checkbox value={element.id} />
                        }
                    </div>
                    <div className="pm011-member-item-name pm014-table-4">
                        <span>{element?.fullName ?? ""}</span>
                    </div>
                    <div className="pm011-member-item-phone pm014-table-4">
                        <span>{element?.ccusId ? helpers.convertToCCUSFormat(element?.ccusId) : ""}</span>
                    </div>
                    <div className="pm011-member-item-phone pm014-table-4">
                        <span>{element?.phoneNumber ? helpers.toPhoneNumberFormat(element.phoneNumber) : ""}</span>
                    </div>
                    <div className="pm011-member-item-email pm014-table-4">
                        <span>{element?.email ?? ""}</span>
                    </div>
                </div>
            </React.Fragment>)
        }
    </Checkbox.Group>
}

export default MemberCheckBoxComponent