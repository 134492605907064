import { AxiosResponse } from 'axios';
import axiosConfig from '../../../../../../config/axiosConfig';
import axios from 'axios';

const CM0048Api = () => {
    const getReMaterialList = async (): Promise<AxiosResponse<any, any>> => {
        const url = '/re/document/material/review/list';
        return await axiosConfig.get(url);
    };

    const getReOutsourceList = async (): Promise<AxiosResponse<any, any>> => {
        const url = '/re/document/outsource/review/list';
        return await axiosConfig.get(url);
    };

    const getReLaborList = async (): Promise<AxiosResponse<any, any>> => {
        const url = '/re/document/labor/review/list';
        return await axiosConfig.get(url);
    };

    const getReIndirectList = async (): Promise<AxiosResponse<any, any>> => {
        const url = '/re/document/indirect/review/list';
        return await axiosConfig.get(url);
    };

    const putMoneyRejectCost = async (data: any): Promise<AxiosResponse<any, any>> => {
        const url = '/money/reject/cost';
        return await axiosConfig.put(url, data);
    };

    const putMoneyApproveCost = async (data: any): Promise<AxiosResponse<any, any>> => {
        const url = '/money/approve/cost';
        return await axiosConfig.put(url, data);
    };

    return {
        getReMaterialList,
        getReOutsourceList,
        getReLaborList,
        getReIndirectList,
        putMoneyRejectCost,
        putMoneyApproveCost,
    };
};

export default CM0048Api;
