import {
  BranchPaginationEntity,
  RolePaginationEntity,
} from "../../OM004/entity/Entity";
import {
  DetailMemberEntity,
  MemberUpdateEntity,
  S3ImageParams,
} from "../entity/Entity";
import { OM006Api, OM004Api } from "./ServiceImpl";

const OM006Service = (om006: OM006Api, om004: OM004Api) => {
  const updateMember = async (data: MemberUpdateEntity): Promise<any> => {
    try {
      return await om006.updateMember(data);
    } catch (error) {
      throw error;
    }
  };

  const getBranchList = async (
    params: any
  ): Promise<BranchPaginationEntity> => {
    return await om004.getBranchList(params);
  };

  const getRoleList = async (params: any): Promise<RolePaginationEntity> => {
    return await om004.getRoleList(params);
  };

  const getCertificateType = async (): Promise<any> =>
    await om004.getCertificateType();

  const getDetailMember = async (
    params: any
  ): Promise<DetailMemberEntity | null> => {
    const detailMember = await om006.getDetailMember(params);
    if (detailMember)
      return { ...detailMember, s3ImageUrl: detailMember?.avatar };
    return detailMember;
  };

  const getS3MemberImage = async (params: any): Promise<any> => {
    try {
      return await om006.getS3MemberImage(params);
    } catch (error) {
      throw error;
    }
  };

  return {
    updateMember,
    getBranchList,
    getRoleList,
    getDetailMember,
    getCertificateType,
    getS3MemberImage,
  };
};

export default OM006Service;
