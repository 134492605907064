import { memo } from "react";
import { PM012NavHandler } from "../handler/HandlerImpl";

import { Collapse, Tooltip } from "antd";
import PM012NavItem from "./PM012NavItem";

import PlusPurple from "../../../../../assets/icons/plus-purpose.svg";
import IconDown from "../../../../../assets/icons/arrow.svg";
import TooltipText from "../../../../../common/components/tooltip-text/TooltipText";

const { Panel } = Collapse;

interface props {
  handler: PM012NavHandler;
}
export function PM012Nav({ handler }: props) {
  return (
    <div className="pm012-nav">
      <Collapse
        onChange={handler.handleChangeCollapseNav}
        activeKey={handler.navItemActiveKey}
        collapsible="header"
        expandIcon={({ isActive }) => {
          return (
            <div
              className={`${isActive ? "nav-collapse-icon-active" : ""
                } nav-collapse-icon`}
            >
              <img src={IconDown} alt="" />
            </div>
          );
        }}
      >
        {handler.listConstruction?.map((construction: any, index: number) => {
          return (
            <Panel
              key={construction?.id}
              header={
                <div>
                  {index === 0 &&
                    handler.funcCheckRoleCreate() &&
                    handler.detailProject?.parentId ? (
                    <div
                      onClick={(e) => {
                        handler.handleViewModalSendMember();
                        e.stopPropagation();
                      }}
                      className="pm012-nav-share-member"
                    >
                      メンバーリスト共有
                    </div>
                  ) : null}
                  <div className="pm012-nav-item-header">
                    <h3 className="item-text-long-header">
                      <TooltipText
                        content={construction?.name || ""}
                      />
                    </h3>

                  </div>
                </div>
              }
            >
              <PM012NavItem construction={construction} handler={handler} />
            </Panel>
          );
        })}
      </Collapse>
    </div>
  );
}

