import { FormInstance } from 'antd/es/form/Form';
const typeInteger = (event: any) => {
    if (!/[0-9]/.test(event.key)) {
        event.preventDefault();
    }
};

const typeIntegerORD = (event: any) => {
    if (!/[0-9-]/.test(event.key)) {
        event.preventDefault();
    }
};

const onBlurTrimFormItem = (form: FormInstance, formName: string | string[]): void => {
    if (form.getFieldValue(formName))
        form.setFieldValue(formName, form.getFieldValue(formName).trim());
};

const inputPhoneNumberFormat = (e: any, key: string, form: any) => {
    const phone = e.target.value;
    if (phone.trim() === '') return;
    let results = '';
    let checkPhone = 0;
    const phoneList = phone.split('').filter((item: string) => item !== '-');
    if (phoneList.length <= 10) {
        phoneList.forEach((item: string, index: number) => {
            if (index === 5) {
                checkPhone = index;
            }
            if (index <= 2 || index === 4 || index === 5) {
                results = `${results}${item}`;
            } else if (index === 3) {
                results = `${results}-${item}`;
            } else {
                if (index === checkPhone + 1) {
                    results = `${results}-${item}`;
                } else {
                    results = `${results}${item}`;
                }
                if (index === checkPhone + 4) {
                    checkPhone = index;
                }
            }
        });
    } else if (phoneList.length === 11) {
        phoneList.splice(3, 0, '-');
        phoneList.splice(8, 0, '-');
        results = phoneList.join('');
    } else {
        phoneList.forEach((item: string, index: number) => {
            if (index === 3) {
                checkPhone = index;
            }
            if (index <= 3) {
                results = `${results}${item}`;
            } else {
                if (index === checkPhone + 1) {
                    results = `${results}-${item}`;
                } else {
                    results = `${results}${item}`;
                }
                if (index === checkPhone + 4) {
                    checkPhone = index;
                }
            }
        });
    }

    form.setFieldValue([key], results);
};


const formatCCUSID = (e: any, key: string, form: any) => {
    const input = e.target.value.replace(/\s/g, ''); // Remove existing spaces
    if (input.trim() === '') return;

    let results = '';

    for (let i = 0; i < input.length; i++) {
        results += input[i];
        // Add a space after every 4th character except at the end
        if ((i + 1) % 4 === 0 && i !== input.length - 1) {
            results += ' ';
        }
    }

    form.setFieldValue([key], results);
};

export { typeInteger, inputPhoneNumberFormat,formatCCUSID, onBlurTrimFormItem, typeIntegerORD };
