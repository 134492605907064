import helpers from '../../../common/helpers/common';
import { DataTableConstructionBudgetBalance } from '../CM0016/entity/Entity';
import { TableBudgetBalanceContact } from './entity/Entity';

const convertDataTable = (planCost: number, planLaborCost: number): TableBudgetBalanceContact[] => {
    const obj: TableBudgetBalanceContact = {
        key: 0,
        budgetAmount: planCost,
        actualCost: planLaborCost,
        budgetBalance: planCost - planLaborCost,
        budgetRate: helpers.calculateRateAmount(planCost, planLaborCost),
    };

    return [obj];
};

const convertDataTableInformationCost = (data: any[]): DataTableConstructionBudgetBalance[] => {
    const output: DataTableConstructionBudgetBalance[] = [];

    data.forEach((item: any, idx: number) => {
        const budget = item?.laborCost?.planCost ?? 0;
        const actual = item?.laborCost?.actualCost ?? 0;

        const obj: DataTableConstructionBudgetBalance = {
            key: idx,
            budgetAmount: budget,
            actualCost: actual,
            budgetBalance: budget - actual,
            budgetRate: helpers.calculateRateAmount(budget, actual),
        };

        output.push(obj);
    });
    return output;
};

export { convertDataTable, convertDataTableInformationCost };
