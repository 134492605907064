// Built-in
import React from 'react';

// 3rd party lib
import { Form, Select } from 'antd';

// Source files
import '../../../assets/styles/CR/CR003.css';

type props = {
    record: any;
    name: string;
    options: {
        code: string;
        description: string;
        id: number;
        type: string;
    }[];
};
const SelectFormItemComponent = ({ record, name, options }: props) => (
    <Form.Item name={[`record${record.id}`, name]} style={{ padding: 0, margin: 0 }}>
        <Select
            getPopupContainer={(trigger: any) => trigger.parentNode}
            style={{ width: '100%' }}
            placeholder="手入力"
            showArrow={true}
            size="large"
        >
            {options.map((element) => (
                <Select.Option value={element.id} key={element.id}>
                    {element.description}
                </Select.Option>
            ))}
        </Select>
    </Form.Item>
);

export default SelectFormItemComponent;
