import ChangeForgotPasswordApi from "./infrastructure/api/Api"
import ChangeForgotPasswordHandler from "./presenter/handler/Handler";
import ChangeForgotPasswordUI from "./presenter/ui/UI";
import ChangeForgotPasswordService from "./usecase/Service"

const ASChangeForgotPasswordComponent = () => {
    const changeForgotPasswordApi = ChangeForgotPasswordApi()
    const changeForgotPasswordService = ChangeForgotPasswordService(changeForgotPasswordApi);
    const changeForgotPasswordHandler = ChangeForgotPasswordHandler(changeForgotPasswordService)
    return <ChangeForgotPasswordUI handler={changeForgotPasswordHandler} />
}

export default ASChangeForgotPasswordComponent