/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import '../../../assets/styles/style.css';
import IconView from '../../../assets/icons/icon-eye-blue.svg';
import helpers from '../../helpers/common';
import TooltipText from '../tooltip-text/TooltipText';
type props = {
    items: any[];
    handleViewDetail: (ite: any) => void;
};

const BottomAxisChart = ({ items, handleViewDetail }: props) => {
    return (
        <div className="bottom-axis-wrapper">
            {items.map((ite: any, idx: number) => {
                return (
                    <div style={{ flexBasis: (100 / items.length) + "%", display: "flex", justifyContent: "center", justifyItems: "center" }} key={idx}>
                        <div className="bottom-axis-wrapper__item">
                            <span
                                style={{
                                    display: 'block',
                                    maxWidth: 68,
                                    fontSize: 12,
                                    color: '#666',
                                }}
                            >
                                <TooltipText content={ite.projectName} />
                            </span>
                            <img
                                alt="icon eye"
                                src={IconView}
                                onClick={() => handleViewDetail(ite)}
                            />
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default BottomAxisChart;
