import React from 'react';
import DateRangePickerHandler from './handler/Handler';
import DateRangePickerUI from './presenter/ui/UI';

type props = {
    record: any;
    name: string;
    onAttendanceLaborCostChange?: (record: any, property: string, value: any) => Promise<void> | void;

};
const DateRangePickerCMComponent = ({ record, name, onAttendanceLaborCostChange }: props) => {
    const handler = DateRangePickerHandler();
    return <DateRangePickerUI handler={handler} record={record} name={name} onAttendanceLaborCostChange={onAttendanceLaborCostChange} />;
};

export default DateRangePickerCMComponent;
