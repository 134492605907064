import { useDispatch } from "react-redux";
import { useState, useMemo } from "react";
import { SW02ServiceImpl } from "../../usecase/ServiceImpl";
import { useSearchParams } from "react-router-dom";
import { setIsVisibleOM007 } from "../../../../OM/OM007/presenter/slice/Slice";
import { Form } from "antd";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import COMMON from "../../../../../common/constants/COMMON";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import moment from "moment";
import { uuidv4 } from "@firebase/util";
import { WorkflowUpdatePayload } from "../../entity/Entity";
import ConfirmModalAsync from "../../../../../common/components/modal/ConfirmModalAsync";
import ConfirmModal from "../../../../../common/components/modal/ConfirmModal";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";

const PARAMS = {
  menu: "menu",
};

const DEFAULT_OPTION = {
  page: COMMON.DEFAULT_PAGE,
  numPages: 0,
  keyword: "",
};

const DEFAULT_MEMBER_LIST = [
  {
    key: uuidv4(),
    fullName: "",
    groupName: "",
    userId: 0,
    roleName: "",
    id: 0,
    role: { name: "" },
    branch: { name: "" },
    username: "",
    priority: 1,
  },
];

const SW02Handler = (service: SW02ServiceImpl) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const menuParams = searchParams.get(PARAMS.menu);

  const [isEdit, setIsEdit] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [dataLeft, setDataLeft] = useState<any[]>([]);
  const [dataRight, setDataRight] = useState<any[]>([]);
  const [currentDataRight, setCurrentDataRight] = useState<any[]>([]);
  const [isAddDataRight, setIsAddDataRight] = useState({
    isAdd: false,
    isChange: false,
  });
  const [refreshLeft, setRefreshLeft] = useState(false);
  const [loadingOption, setLoadingOption] = useState(false);
  const [members, setMembers] = useState<any[]>(DEFAULT_MEMBER_LIST);
  const [filterOption, setFilterOption] = useState(DEFAULT_OPTION);
  const [isChange, setIsChange] = useState(false);
  const [isEditBtn, setEditBtn] = useState<boolean>(true);

  const menu = useMemo(() => {
    if (menuParams) return menuParams;
    if (dataLeft?.length > 0) return `${dataLeft[0].id}`;
    return null;
  }, [menuParams, dataLeft]);

  const getWorkflowList = async () => {
    try {
      dispatch(setLoading(true));
      const response = await service.getWorkflowList({});
      if (response?.results && response.results.length > 0) {
        const results = response.results.sort((a, b) => {
          const A = moment(a.createdAt);
          const B = moment(b.createdAt);
          return A.valueOf() - B.valueOf();
        });
        setDataLeft(results);
        setEditBtn(false); // enable edit button
        const responseDetail = await service.getWorkflowDetail({
          workflowId: menu ? +menu : results[0].id,
        });
        form.setFieldValue("name", responseDetail?.results.name ?? "");
        if (
          responseDetail?.results &&
          responseDetail.results.listUser?.length > 0
        ) {
          const resultsRight = responseDetail?.results.listUser?.map(
            (member) => {
              const obj = {
                ...member,
                id: member.userId,
                role: member.workspaceRole,
                key: uuidv4(),
              };
              form.setFieldValue(obj.key, member.userId);
              return obj;
            }
          );
          const sortResultsRight = resultsRight.sort(
            (a, b) => a.priority - b.priority
          );
          setDataRight(sortResultsRight);
          setCurrentDataRight(sortResultsRight);
        } else {
          setDataRight(DEFAULT_MEMBER_LIST);
          setCurrentDataRight(DEFAULT_MEMBER_LIST);
        }
      } else setEditBtn(true); // disable edit button
    } catch (error: any) {
      ErrorNotification(error.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getGroupListMember = async (params: {
    page: number;
    size: number;
    keyword?: string;
  }) => {
    setLoadingOption(true);
    try {
      const response = await service.getGroupListMember(params);
      if (response?.pagination) {
        setFilterOption({
          keyword: params.keyword ?? "",
          page: response.pagination.page,
          numPages: response.pagination.numPages,
        });
      }
      if (response?.results) {
        if (params.page === 1) {
          setMembers(response.results);
        } else {
          setMembers([...members, ...response.results]);
        }
      }
    } catch (error: any) {
      ErrorNotification(error.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      setLoadingOption(false);
    }
  };

  const getListMember = (item: any) => {
    getGroupListMember({
      page: COMMON.DEFAULT_PAGE,
      size: COMMON.DEFAULT_SIZE + dataRight.length,
    });
    setFilterOption(DEFAULT_OPTION);
  };

  const handleScrollMember = (e: any) => {
    const target = e.target;
    if (
      target.scrollTop + target.offsetHeight <= target.scrollHeight + 1 &&
      target.scrollTop + target.offsetHeight >= target.scrollHeight - 1 &&
      filterOption.page < filterOption.numPages
    ) {
      getGroupListMember({
        page: filterOption.page + 1,
        size: COMMON.DEFAULT_SIZE + dataRight.length,
        keyword: filterOption.keyword ? filterOption.keyword : undefined,
      });
    }
  };

  const handleSearchOption = (searchValue: string) => {
    getGroupListMember({
      page: COMMON.DEFAULT_PAGE,
      size: COMMON.DEFAULT_SIZE + dataRight.length,
      keyword: searchValue ? searchValue : undefined,
    });
    setFilterOption({ ...filterOption, keyword: searchValue });
  };

  const handleChangeMenu = (key: string | number, description: any) => {
    if (isChange) {
      ConfirmModalAsync({
        onOk: async () => {
          form.resetFields();
          searchParams.set(PARAMS.menu, key.toString());
          setSearchParams(searchParams);
          setIsChange(false);
          setIsEdit(false);
          getDetail(key);
        },
        onCancel: () => {},
        title: MESSAGE.MESSAGE_020,
        description: description,
        canceText: LABEL_MESSAGE.CANCEL_MODAL,
        okText: LABEL_MESSAGE.OK_MODAL,
      });
    } else {
      form.resetFields();
      setIsEdit(false);
      searchParams.set(PARAMS.menu, key.toString());
      setSearchParams(searchParams);
      getDetail(key);
    }
  };

  const getDetail = async (key: string | number) => {
    try {
      dispatch(setLoading(true));
      const response = await service.getWorkflowDetail({ workflowId: +key });
      form.setFieldValue("name", response?.results.name ?? "");
      if (response?.results && response.results.listUser?.length > 0) {
        const resultsRight = response?.results.listUser?.map((member) => {
          const obj = {
            ...member,
            id: member.userId,
            role: member.workspaceRole,
            key: uuidv4(),
          };
          form.setFieldValue(obj.key, member.userId);
          return obj;
        });
        const sortResultsRight = resultsRight.sort(
          (a, b) => a.priority - b.priority
        );
        setDataRight(sortResultsRight);
      } else {
        setDataRight(DEFAULT_MEMBER_LIST);
      }
    } catch (error: any) {
      ErrorNotification(error.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleAddDataLeft = async () => {
    try {
      dispatch(setLoading(true));
      const indexName = await service.getWorkIndexName();
      const response = await service.postWorkflowCreate({
        name: "ワークフロー" + indexName,
      });
      if (response?.status === `${COMMON.HTTP_STATUS_OK}`) {
        SuccessNotification(response.message || NOTIFICATION_TITLE.SUCCESS);
        setRefreshLeft(!refreshLeft);
      }
    } catch (error: any) {
      ErrorNotification(error.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleRemoveDataLeft = async (key: number | string) => {
    ConfirmModalAsync({
      onOk: async () => {
        try {
          dispatch(setLoading(true));
          const response = await service.deleteWorkflow({ workflowId: +key });
          if (response?.status === `${COMMON.HTTP_STATUS_OK}`) {
            const dataLeftNew = dataLeft.filter((ele) => ele.id !== key);
            if (key?.toString() === menu) {
              if (dataLeftNew.length > 0) {
                searchParams.set(PARAMS.menu, dataLeftNew[0]?.id);
                getDetail(dataLeftNew[0]?.id);
              } else searchParams.delete(PARAMS.menu);
              setSearchParams(searchParams);
            }
            setDataLeft(dataLeftNew);
            setIsEdit(false);
            setIsChange(false);
            setRefresh(!refresh);
            SuccessNotification(response.message || NOTIFICATION_TITLE.SUCCESS);
          }
        } finally {
          dispatch(setLoading(false));
        }
      },
      onCancel: () => {},
      className: "confirm__modal",
      title: MESSAGE.MESSAGE_MODAL_DELETE_TITLE,
      description: MESSAGE.MESSAGE_DELETE_SW,
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_DELETE,
    });
  };

  const handleAddDataRight = () => {
    setDataRight([
      ...dataRight,
      {
        fullName: "",
        groupName: "",
        priority: dataRight.slice(-1)[0]?.priority + 1,
        roleName: "",
        userId: 0,
        key: uuidv4(),
      },
    ]);
    setIsAddDataRight({ isAdd: true, isChange: !isAddDataRight.isChange });
    setIsChange(true);
  };

  const handleRemoveDataRight = (key: string | number, priority: number) => {
    const newDataRight: any[] = [];
    for (const item of dataRight) {
      if (key !== item.key) {
        newDataRight.push({
          ...item,
          priority:
            priority > item.priority ? item.priority : item.priority - 1,
        });
      }
    }
    setDataRight(newDataRight);
  };

  const handleSubmit = async (value: any) => {
    try {
      dispatch(setLoading(true));
      const dataUpdate: WorkflowUpdatePayload = {
        workflowId: +(menu ?? 0),
        workflowName: value.name,
        workflowUser: [],
      };
      for (const item of dataRight) {
        dataUpdate.workflowUser.push({
          userId: item.userId,
          priority: item.priority,
        });
      }

      const response = await service.putWorkflowUpdate(dataUpdate);
      if (response?.status === `${COMMON.HTTP_STATUS_OK}`) {
        SuccessNotification(response.message || NOTIFICATION_TITLE.SUCCESS);
        setIsChange(false);
        setIsEdit(false);
        setRefresh(!refresh);
      }
    } catch (error: any) {
      ErrorNotification(error.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleChangeForm = (value: any) => {
    if (value) {
      const keyUser = Object.keys(value)[0];
      const newDataRight = dataRight.map((item) => {
        if (keyUser === item.key) {
          const findMember = members.find((ele) => ele.id === value[keyUser]);

          return {
            ...item,
            fullName: findMember.fullName || findMember.username,
            groupName: findMember.group?.name ?? "",
            userId: findMember.id,
            roleName: findMember.role?.name ?? "",
            id: findMember.id,
            role: findMember.role,
            group: findMember.group,
            branch: findMember.branch,
            username: findMember.fullName || findMember.username,
          };
        }
        return item;
      });
      setDataRight(newDataRight);
      setFilterOption((prevState) => {
        return { ...prevState, keyword: "" };
      });
      setIsChange(true);
    }
  };

  const handleCancelEdit = (description: any) => {
    if (isChange) {
      ConfirmModal({
        onOk: () => {
          setIsChange(false);
          setIsEdit(false);
          setRefresh(!refresh);
        },
        className: "confirm__modal",
        title: MESSAGE.MESSAGE_020,
        description: description,
        canceText: LABEL_MESSAGE.CANCEL_MODAL,
        okText: LABEL_MESSAGE.OK_MODAL,
      });
    } else {
      setIsChange(false);
      setIsEdit(false);
      setRefresh(!refresh);
    }
  };

  const dataRightView = useMemo(() => {
    const filterMembers: any = [];
    const check: any = {};
    for (const item of members) {
      const found = dataRight.find((ele) => ele.userId === item.id);
      if (!(item.id in check) && !found) {
        filterMembers.push(item);
        check[item.id] = item;
      }
    }
    const results = dataRight.map((member) => {
      const obj = { ...member, options: [] };
      if (filterOption.keyword || !member.userId) obj.options = filterMembers;
      else obj.options = [member, ...filterMembers];
      return obj;
    });
    return results;
  }, [members, dataRight, filterOption.keyword]);

  return {
    form,
    isEdit,
    dataLeft,
    menu,
    dataRightView,
    isAddDataRight,
    refreshLeft,
    refresh,
    isEditBtn,

    setIsEdit,
    handleChangeMenu,
    handleAddDataLeft,
    handleRemoveDataLeft,
    handleSubmit,
    handleChangeForm,
    handleAddDataRight,
    handleRemoveDataRight,
    getWorkflowList,
    getListMember,
    handleScrollMember,
    handleSearchOption,
    handleCancelEdit,
  };
};

export default SW02Handler;
