import axiosConfig from "../../../../../config/axiosConfig";
import { IndirectCostProjectSubmitPayload } from "../../entity/Entity";

const CM0030Api = () => {
  const getListSummaryIndirectCost = async (params: any): Promise<any> => {
    const url = "/money/summary/indirect-cost/project/list";
    return await axiosConfig.get(url, { params });
  };

  const getSummaryIndirectCost = async (params: any): Promise<any> => {
    const url = "/money/summary/indirect-cost/project/list-indirect-cost";
    return await axiosConfig.get(url, { params });
  };

  const getStatusIndirectCost = async (params: any): Promise<any> => {
    const url = "/summary/indirect-cost/project/status";
    return await axiosConfig.get(url, { params });
  };

  const getTotalIndirectCost = async (params: any) => {
    const url = "/money/summary/indirect-cost/project/summary";
    return await axiosConfig.get(url, { params });
  };

  const getIndirectCostProjectDocumentStatus = async (params: {
    from: string;
    to: string;
  }) => {
    const url = "/re-summary/indirect-cost/project/document/status";
    return await axiosConfig.get(url, { params });
  };

  const putIndirectCostProjectSubmit = async (
    data: IndirectCostProjectSubmitPayload
  ) => {
    const url = "/re-summary/indirect-cost/project/submit";
    return await axiosConfig.post(url, data);
  };

  const getProjectAlls = async (params: {
    page: number;
    size: number;
    from: string;
    to: string;
    sortBy: string;
    sortType: string;
  }): Promise<any> => {
    const url = "/re-summary/project/indirect";
    return await axiosConfig.get(url, { params });
  };

  const doExportCM0030 = async (params: any): Promise<any> => {
    try {
        const url = '/re-summary/exportCM0030';
        const response = await axiosConfig.get(url, { params, responseType: 'blob'  });
        
        return response;
    } catch (error) {
        throw error;
    }
  };

  return {
    getListSummaryIndirectCost,
    getSummaryIndirectCost,
    getStatusIndirectCost,
    getTotalIndirectCost,
    getIndirectCostProjectDocumentStatus,
    putIndirectCostProjectSubmit,
    getProjectAlls,
    doExportCM0030,
  };
};

export default CM0030Api;
