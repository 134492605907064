import AOP004Api from './infrastructure/api/Api';
import AOP004Handler from './presenter/handler/Handler';
import MainUI from './presenter/ui/UI';
import AOP004Service from './usecase/Service';

const AOP004Component = () => {
    const api = AOP004Api();
    const service = AOP004Service(api);
    const handler = AOP004Handler(service);

    return <MainUI handler={handler}></MainUI>;
};

export default AOP004Component;
