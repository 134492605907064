import { Button, DatePicker, Form, Input, Modal, Spin } from "antd";
import { useSelector } from "react-redux";
import React, { useEffect } from "react";

import LoadingComponent from "../../../../../common/components/icons/LoadingComponent";
import COMMON, { MAX_LENGTH } from "../../../../../common/constants/COMMON";
import REGEX from "../../../../../common/constants/REGEX";
import helpers from "../../../../../common/helpers/common";
import CertificationFormItemComponent from "../../../../../common/components/certification-form-item/CertificationFormItemComponent";
import {
  inputPhoneNumberFormat,
  onBlurTrimFormItem,
  typeInteger,
  typeIntegerORD,
} from "../../../../../common/helpers/typeInput";
import { RootState } from "../../../../../store";
import { HandlerImpl } from "../handler/HandlerImpl";
import locale from "antd/es/date-picker/locale/ja_JP";
import "moment/locale/ja";
import "../../../../../assets/styles/OM/OM002.css";
import "../../../../../assets/styles/OM/OM004.css";
import "../../../../../assets/styles/OM/OM007.css";
import CalendarSvg from "../../../../../assets/icons/calendar.svg";

import XIconModal from "../../../../../assets/icons/x-icon-modal.svg";
import MESSAGE from "../../../../../common/constants/MESSAGE";
import UploadAvatarComponent from "../../../../../common/components/upload-image";
import { validateInput } from "../../../../ORD/ORD002_1/helper";
type Props = {
  handler: HandlerImpl;
};

const MainUI = ({ handler }: Props) => {
  const isVisible = useSelector((state: RootState) => state.pm028.isVisible);
  const orderId = useSelector((state: RootState) => state.pm028.orderId);
  const loading = useSelector((state: RootState) => state.pm028.loading);

  useEffect(() => {
    if (handler.isDirty) {
      window.addEventListener("beforeunload", helpers.alertUser);
      return () => {
        window.removeEventListener("beforeunload", helpers.alertUser);
      };
    } else {
      window.removeEventListener("beforeunload", helpers.alertUser, true);
    }
  }, [handler.isDirty]);

  useEffect(() => {
    (async () => {
      if (orderId && isVisible) await handler.getDetailOrder(orderId);
    })();
  }, [orderId, isVisible]);

  return !isVisible ? null : (
    <Modal
      title={
        <div
          className="w-100-percent "
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignContent: "center",
          }}
        >
          <div className="header-modal-title-om004 ">
            {orderId ? "発注元会社マスタ編集" : "発注元会社マスタ追加"}
          </div>
          <div style={{ cursor: "pointer" }} onClick={handler.onCancel}>
            <img alt="" src={XIconModal} style={{ height: 18, width: 18 }} />
          </div>
        </div>
      }
      style={{ marginLeft: 300, marginTop: 40, marginBottom: 40 }}
      closable={false}
      centered={true}
      maskClosable={false}
      visible={isVisible}
      onCancel={handler.onCancel}
      footer={null}
      width={995}
    >
      <Spin indicator={LoadingComponent} spinning={loading}>
        <Form
          name="pm025-modal"
          form={handler.form}
          onValuesChange={handler.checkDirty}
          id="formOrder"
          onFinish={(value: any) => handler.submit({ ...value, orderId })}
          autoComplete="off"
        >
          <div className="pm025-modal-form-item">
            <div className="pm025-modal-form-item-label">
              会社名<span style={{ color: "rgba(255, 9, 9, 1)" }}>*</span>
            </div>
            <Form.Item
              className="pm025-modal-form-item-input"
              name="companyName"
              rules={[
                validateInput({
                  maxLength: 50,
                  required: true,
                  regex: REGEX.NOT_VIETNAM,
                }),
              ]}
            >
              <Input
                size="large"
                placeholder="例：株式会社ONE Unit"
                style={{ height: 40 }}
                maxLength={50}
                autoComplete="off"
                onBlur={() => onBlurTrimFormItem(handler.form, "companyName")}
                onChange={(e) => {
                  helpers.preventInputJapanese(e);
                }}
              />
            </Form.Item>
          </div>

          <div className="pm025-modal-form-item">
            <div className="pm025-modal-form-item-label">
              フリガナ<span style={{ color: "rgba(255, 9, 9, 1)" }}>*</span>
            </div>
            <Form.Item
              className="pm025-modal-form-item-input"
              name="furiganaName"
              rules={[
                () => ({
                  validator(_, value) {
                    if (value && value !== "") {
                      value = value.trim();
                      value = value.replaceAll(" ", "");
                      value = value.replaceAll("　", "");

                      if (!REGEX.ONLY_KATAKANA.test(value))
                        return Promise.reject(MESSAGE.MESSAGE_PM027_MSG_012);
                      return Promise.resolve();
                    }
                    return Promise.reject(MESSAGE.MESSAGE_REQUIRED);
                  },
                }),
              ]}
            >
              <Input
                style={{ height: 40 }}
                placeholder="例：カブシキガイシャワンユニット"
                size="large"
                maxLength={50}
                autoComplete="off"
                onBlur={() => onBlurTrimFormItem(handler.form, "furiganaName")}
                onChange={(e) => {
                  helpers.preventInputJapanese(e);
                }}
              />
            </Form.Item>
          </div>

          <div className="pm025-modal-form-item">
            <span className="pm025-modal-form-item-label">メールアドレス</span>
            <Form.Item
              className="pm025-modal-form-item-input"
              name="mail"
              rules={[
                () => ({
                  validator(_, value) {
                    if (value && !REGEX.EMAIL_COMPANY.test(value.trim())) {
                      return Promise.reject(MESSAGE.MESSAGE_WRONG_EMAIL_FORMAT);
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                style={{ height: 40 }}
                size="large"
                maxLength={50}
                onChange={(e) => {
                  helpers.preventInputJapanese(e);
                }}
                placeholder="例：user@oneunit.com"
                onBlur={() => helpers.onBlurTrimFormItem(handler.form, "mail")}
              />
            </Form.Item>
          </div>
          <div className="pm025-modal-form-item">
            <span className="pm025-modal-form-item-label">電話番号</span>
            <Form.Item
              className="pm025-modal-form-item-input"
              name="phoneNumber"
              rules={[
                () => ({
                  validator(_, value) {
                    if (value && value !== "") {
                      value = value.trim().replaceAll(" ", "");
                      value = value.replaceAll("-", "");
                      if (!REGEX.PHONENUMBER_2.test(value))
                        return Promise.reject(
                          MESSAGE.MESSAGE_WRONG_PHONE_FORMAT
                        );
                      if (value?.length < 10) {
                        return Promise.reject(MESSAGE.MESSAGE_INVALID_INPUT);
                      }
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                style={{ height: 40 }}
                placeholder="例：09011112222　ハイフン(ー)なし　半角"
                autoComplete="off"
                maxLength={COMMON.MAX_LENGTH_PHONE_NUMBER}
                onKeyPress={typeInteger}
                onPaste={typeInteger}
                onChange={(e) => {
                  helpers.preventInputJapanese(e);
                  inputPhoneNumberFormat(e, "phoneNumber", handler.form);
                }}
              />
            </Form.Item>
          </div>
          <div className="pm025-modal-form-item">
            <span className="pm025-modal-form-item-label">FAX</span>
            <Form.Item
              className="pm025-modal-form-item-input"
              name="fax"
              rules={[
                () => ({
                  validator(_, value) {
                    if (value && value !== "") {
                      value = value.trim().replaceAll(" ", "");
                      value = value.replaceAll("-", "");
                      if (!REGEX.PHONENUMBER_2.test(value))
                        return Promise.reject(MESSAGE.MESSAGE_WRONG_FAX_FORMAT);
                      if (value?.length < 10) {
                        return Promise.reject(MESSAGE.MESSAGE_INVALID_INPUT);
                      }
                      if (value?.length > COMMON.MAX_LENGTH_PHONE_NUMBER - 2) {
                        return Promise.reject(MESSAGE.MESSAGE_VALIDATE_FAX);
                      }
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                style={{ height: 40 }}
                placeholder="例：09011112222　ハイフン(ー)なし　半角"
                autoComplete="off"
                maxLength={100000}
                onKeyPress={typeInteger}
                onPaste={typeInteger}
                onChange={(e) => {
                  helpers.preventInputJapanese(e);
                  inputPhoneNumberFormat(e, "fax", handler.form);
                }}
              />
            </Form.Item>
          </div>
          <div className="pm025-modal-form-item">
            <span className="pm025-modal-form-item-label">住所</span>
            <Form.Item
              className="pm025-modal-form-item-input"
              name="address"
              rules={[
                {
                  pattern: new RegExp(REGEX.EXCEPT_VIETNAMESE),
                  message: MESSAGE.MESSAGE_INVALID_INPUT,
                },
              ]}
            >
              <Input
                style={{ height: 40 }}
                placeholder="例：東京都渋谷区神南1-2-3 シブヤビルディング101号室"
                size="large"
                onChange={(e) => {
                  helpers.preventInputJapanese(e);
                }}
                maxLength={MAX_LENGTH.ADDRESS}
                onBlur={() =>
                  helpers.onBlurTrimFormItem(handler.form, "address")
                }
              />
            </Form.Item>
          </div>
          <div className="pm025-modal-form-item">
            <span className="pm025-modal-form-item-label">確認先部署</span>
            <Form.Item
              className="pm025-modal-form-item-input"
              name="confirmationDepartment"
              rules={[
                validateInput({
                  maxLength: 20,
                  required: false,
                  regex: REGEX.NOT_VIETNAM,
                }),
              ]}
            >
              <Input
                style={{ height: 40 }}
                placeholder="例：工事部"
                size="large"
                onBlur={() =>
                  onBlurTrimFormItem(handler.form, "confirmationDepartment")
                }
                autoComplete="off"
                maxLength={20}
              />
            </Form.Item>
          </div>
          <div className="pm025-modal-form-item">
            <span className="pm025-modal-form-item-label">確認先担当者名</span>
            <Form.Item
              className="pm025-modal-form-item-input"
              name="managerName"
              rules={[
                {
                  pattern: new RegExp(REGEX.EXCEPT_VIETNAMESE),
                  message: MESSAGE.MESSAGE_INVALID_INPUT,
                },
              ]}
            >
              <Input
                maxLength={20}
                style={{ height: 40 }}
                placeholder="例：山田　太郎"
                size="large"
                onBlur={() => onBlurTrimFormItem(handler.form, "managerName")}
              />
            </Form.Item>
          </div>
          <div className="pm025-modal-btn">
            <Button
              htmlType="submit"
              size="large"
              className="button-purple"
              style={{ width: 76, letterSpacing: -1.5 }}
            >
              保存
            </Button>
            <Button
              size="large"
              onClick={handler.onCancel}
              style={{ color: "#666" }}
            >
              キャンセル
            </Button>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default MainUI;
