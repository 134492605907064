import React from 'react';
import CM0012_2Api from './infrastructure/api/Api';
import CM0012_2Handler from './presenter/handler/Handler';
import CM0012_2UI from './presenter/ui/UI';
import CM0012_2Service from './usecase/Service';

const CM0012_2Component = () => {
    const cm0012_2Api = CM0012_2Api();
    const cm0012_2Service = CM0012_2Service(cm0012_2Api);
    const cm0012_2Handler = CM0012_2Handler(cm0012_2Service);
    return <CM0012_2UI handler={cm0012_2Handler} />;
};

export default CM0012_2Component;
