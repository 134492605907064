import { useEffect } from "react";
import AttendanceApi from "../../../infrastructure/api/ApiAttendance";
import AttendanceService from "../../../usecase/ServiceAttendance";
import AttendanceHandler from "../../handler/HandlerAttendance";
import AttendanceUI from "./ui/UI";
import { DateColumn } from "../../../entity/Entity";

interface Props {
  constructionId?: number;
  userId: number;
  columnsTable: DateColumn[];
  attendance: any
}

const AttendanceComponent = ({
  constructionId,
  userId,
  columnsTable,
  attendance
}: Props) => {

  const api = AttendanceApi();
  const service = AttendanceService(api);
  const handler = AttendanceHandler(service);
  return (
    <AttendanceUI
      handler={handler}
      constructionId={constructionId}
      userId={userId}
      columnsTable={columnsTable}
      attendance={attendance}
    />
  );
};

export default AttendanceComponent;
