import React, { useRef, useEffect, useState } from 'react';
import { Empty, Form, Input } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import '../../../../../assets/styles/components/input-search.css';
import Handler from './InputSelectHandler';
import COMMON from '../../../../../common/constants/COMMON';
import IconSearch from '../../../../../assets/icons/search-icon.svg';
import TooltipText from '../../../../../common/components/tooltip-text/TooltipText';

const { Item } = Form;

type props = {
    name: string;
    form: FormInstance;
    option: { name: string; value: string | number }[];
    customFooter?: any;

    onFocus?: () => void;
    onOpen?: (open?: boolean) => void;
    onChooseItem?: (item: { name: string; value: number | string }) => void;
    onScrollPopup?: (e: any) => void;
    onSearch?: (value: string) => void;
};
const InputSelect = ({
    name,
    form,
    option,
    customFooter,
    onFocus,
    onOpen,
    onChooseItem,
    onScrollPopup,
    onSearch,
}: props) => {
    const { isOpen, handleFocus, handleBlur, handleClickItem } = Handler();
    const refPopup: any = useRef(null);
    const [isSearch, setIsSearch] = useState(false);
    useEffect(() => {
        if (refPopup && refPopup.current) {
            refPopup.current.scrollTo(0, 0);
        }
    }, [isOpen, isSearch]);

    const valueInput = form.getFieldValue(name);

    return (
        <div
            className="input-select"
            onBlur={() => {
                handleBlur(name, form);
                if (onOpen) onOpen(false);
            }}
        >
            <Item name={`${name}${COMMON.INPUT_SEARCH}`}>
                <Input
                    size="large"
                    placeholder="社員名を入力"
                    prefix={<img alt="" src={IconSearch} />}
                    onClick={(e) => {
                        if (onFocus) onFocus();
                        if (onOpen) onOpen(true);
                        handleFocus(e);
                    }}
                    onChange={(e) => {
                        if (onSearch) {
                            onSearch(e?.target?.value);
                            setIsSearch(!isSearch);
                        }
                    }}
                />
            </Item>
            <Item name={name} hidden>
                <Input hidden />
            </Item>
            <div
                className={`input-select-list ${isOpen ? 'active' : ''}`}
                onMouseDown={(e) => e.preventDefault()}
                onScroll={onScrollPopup}
                ref={refPopup}
            >
                {option?.length > 0 ? (
                    option?.map((item) => (
                        <div
                            key={item.value}
                            className={`input-select-item ${
                                valueInput === item.value ? 'active' : ''
                            }`}
                            onClick={() => {
                                handleClickItem(item, name, form);
                                if (onChooseItem && valueInput !== item.value) onChooseItem(item);
                            }}
                        >
                            <TooltipText content={item.name} />
                        </div>
                    ))
                ) : (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="該当するデータはありません"
                    />
                )}
                {customFooter ? (
                    <div className="input-select-item footer">{customFooter}</div>
                ) : null}
            </div>
        </div>
    );
};

export default InputSelect;
