// package
import { Button, Checkbox, Col, Form, Input, Row, Tabs } from 'antd';
import { ASLoginHandler } from '../handler/HandlerImpl';
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';

// css
import '../../../../../assets/styles/S-001/B001.css';

// icon
import LogoBlack from '../../../../../assets/images/logo-black.svg';

import { useEffect } from 'react';
import AuthenticationSideImage from '../../../../../common/components/authentication-side-image/AuthenticationSideImage';
import MESSAGE from '../../../../../common/constants/MESSAGE';
import REGEX from '../../../../../common/constants/REGEX';

interface props {
    handler: ASLoginHandler;
}

const { TabPane } = Tabs;

const ASLoginUI = ({ handler }: props) => {
    useEffect(() => {

        handler.checkLogin();
    }, []);

    return (
        <div className="login-container">
            <div className="half-container">
                <div className="login-form">
                    <div style={{ marginBottom: 64 }}>
                        <img alt="" src={LogoBlack} />
                    </div>
                    <div className={`login-title`} style={{ marginBottom: 41 }}>
                        ログイン
                    </div>

                    <Tabs
                        onChange={handler.changeInputType}
                        activeKey={handler.inputType}
                        style={{ marginBottom: 20 }}
                    >
                        <TabPane tab="ID" key="id"></TabPane>
                        <TabPane tab="メールアドレス" key="email"></TabPane>
                    </Tabs>
                    <Form
                        layout="vertical"
                        form={handler.form}
                        onFinish={handler.login}
                        initialValues={{ isRemembered: true }}
                    >
                        {handler.inputType === 'email' ? (
                            <Form.Item
                                name="email"
                                className="input-style"
                                label="メールアドレス"
                                rules={[
                                    {
                                        pattern: new RegExp(REGEX.EMAIL_COMPANY),
                                        message: MESSAGE.MESSAGE_WRONG_EMAIL_FORMAT,
                                    },
                                    {
                                        required: true,
                                        message: '',
                                    },
                                ]}
                            >
                                <Input
                                    placeholder="メールアドレスを入力してください"
                                    size="large"
                                />
                            </Form.Item>
                        ) : (
                            <Form.Item
                                name={'username'}
                                className="input-style"
                                style={{ border: 'none', outlineColor: '#ffffff' }}
                                label="ID"
                                rules={[
                                    { max: 30, message: '' },
                                    { min: 6, message: '' },
                                    { required: true, message: '' },
                                    {
                                        pattern: new RegExp(REGEX.ONLY_LATIN_2),
                                        message: MESSAGE.MESSAGE_WRONG_ID_FORMAT,
                                    },
                                ]}
                            >
                                <Input placeholder="IDを入力してください" size="large" />
                            </Form.Item>
                        )}

                        <Form.Item
                            name={'password'}
                            className="input-password-style"
                            style={{ marginBottom: 16 }}
                            label="パスワード"
                            rules={[
                                {
                                    min: 8,
                                    message: MESSAGE.MESSAGE_8_16_CHARACTERS,
                                },
                                {
                                    max: 16,
                                    message: MESSAGE.MESSAGE_8_16_CHARACTERS,
                                },
                                {
                                    required: true,
                                    message: '',
                                },
                            ]}
                        >
                            <Input.Password
                                onCopy={(e) => {
                                    e.preventDefault();
                                    return false;
                                }}
                                placeholder="パスワードを入力してください"
                                iconRender={(visible) =>
                                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                                }
                                size="large"
                            />
                        </Form.Item>

                        <Row style={{ marginBottom: 24 }}>
                            <Col span={12}>
                                <Form.Item name="isRemembered" valuePropName="checked">
                                    <Checkbox>ログイン状態を保持</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Button
                                    onClick={handler.redirectToForgotPasswordPage}
                                    className="login-forgot-button"
                                    style={{ float: 'right', padding: 0, margin: 0 }}
                                    type="link"
                                >
                                    パスワードを変更または忘れた場合
                                </Button>
                            </Col>
                        </Row>

                        <Form.Item>
                            <Button
                                htmlType="submit"
                                size="large"
                                className="button-purple w-100-percent "
                            >
                                ログイン
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
            <AuthenticationSideImage />
        </div>
    );
};

export default ASLoginUI;
