import { uuidv4 } from "@firebase/util";
import { NOTIFICATION_TITLE } from "./../../../../../common/constants/MESSAGE";

import { RootState } from "./../../../../../store";
import { useState } from "react";
import { Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import { ServiceImpl } from "../../usecase/ServiceImpl";
import { setLoading, resetSM010, setRefreshSM010 } from "../slice/Slice";
import ConfirmModal from "../../../../../common/components/modal/ConfirmModal";
import MESSAGE, {
  LABEL_MESSAGE,
} from "../../../../../common/constants/MESSAGE";
import {
  ScheduleCategory,
  ScheduleCategoryColors,
  ScheduleCategoryUpdate,
  ScheduleCategoryUpdateCreate,
  ScheduleCategoryUpdateUpdate,
} from "../../entity/Entity";

const DEFAULT_STATE = {
  isDirty: false,
  certType: [],
  fileData: undefined,
};

const Handler = (service: ServiceImpl) => {
  // LIB FUNCTION
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  // STATE
  const [isDirty, setIsDirty] = useState(DEFAULT_STATE.isDirty);
  const [listColors, setListColors] = useState<ScheduleCategoryColors[]>([]);
  const [availableColors, setAvailableColors] = useState<
    ScheduleCategoryColors[]
  >([]);
  const [defaultColor, setDefaultColor] = useState<ScheduleCategoryColors>();
  const [scheduleCategories, setScheduleCategories] = useState<
    ScheduleCategory[]
  >([]);
  const [isColorModal, setIsColorModal] = useState<boolean>(false);

  const [deletedId, setDeletedId] = useState<number[]>([]);
  const [newCategories, setNewCategories] = useState<ScheduleCategory[]>([]);

  // FUNCTION
  const initiatingData = async () => {
    try {
      dispatch(setLoading(true));
      const [colors, categories] = await Promise.all([
        service.getScheduleCategoryColors(),
        service.getScheduleCategoryList(),
      ]);

      // filter available colors
      const availableColorList = colors.filter(
        (color) =>
          !categories.some((category) => {
            return category.color.id === color.id;
          })
      );

      // set form value
      const formValue: any = {};
      categories.forEach((category) => {
        formValue[`category${category.id}`] = {
          id: category.id,
          colorId: category.color.id,
          name: category.name,
        };
      });
      form.setFieldsValue(formValue);

      // find default color
      const foundDefaultColor = colors.find((element) => element.isDefault);
      if (foundDefaultColor) setDefaultColor(foundDefaultColor);

      setAvailableColors(availableColorList);
      setScheduleCategories(categories);
      setListColors(colors);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const putScheduleCategoryUpdate = async (formData: any) => {
    try {
      dispatch(setLoading(true));
      const formCategoryList: any[] = [];
      for (let property in formData) {
        formCategoryList.push(formData[`${property}`]);
      }

      const scheduleCategoryCreate: ScheduleCategoryUpdateCreate[] = [];
      const scheduleCategoryUpdate: ScheduleCategoryUpdateUpdate[] = [];
      formCategoryList.forEach((category) => {
        if ((category.id + "").includes("new")) {
          scheduleCategoryCreate.push({
            name: category?.name ?? "",
            colorId: category.colorId,
          });
        } else {
          scheduleCategoryUpdate.push({
            id: category.id,
            name: category?.name ?? "",
            colorId: category.colorId,
          });
        }
      });

      const payload: ScheduleCategoryUpdate = {
        create: scheduleCategoryCreate,
        update: scheduleCategoryUpdate,
        delete: deletedId,
      };
      const response = await service.putScheduleCategoryUpdate(payload);
      resetState();
      dispatch(setRefreshSM010());
      SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const resetState = () => {
    form.resetFields();
    setIsDirty(DEFAULT_STATE.isDirty);
    dispatch(resetSM010());
  };

  const onCancel = () => {
    if (!isDirty) {
      resetState();
      return;
    }
    ConfirmModal({
      onOk: () => resetState(),
      className: "confirm__modal",
      title: MESSAGE.MESSAGE_020,
      description: MESSAGE.MESSAGE_022_1,
      extraDescription: MESSAGE.MESSAGE_022_2,
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_MODAL,
      isCenterWithoutMenu: true,
    });
  };

  const onDeleteCategory = (value: ScheduleCategory) => {
    ConfirmModal({
      onOk: () => {
        // delete category
        if ((value.id + "").includes("new")) {
          const newCategoryList = newCategories.filter(
            (category) => category.id !== value.id
          );
          setNewCategories(newCategoryList);
        } else {
          const existingCategoryList = scheduleCategories.filter(
            (category) => category.id !== value.id
          );
          setScheduleCategories(existingCategoryList);
          setDeletedId([...deletedId, value.id]);
        }

        // return available color
        setAvailableColors([...availableColors, value.color]);
        setIsDirty(true)
      },
      className: "confirm__modal",
      title: MESSAGE.MESSAGE_DELETE_MANY_TIT,
      description: MESSAGE.MESSAGE_DESCRIPTION_CR_7,
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_DELETE,
      isCenterWithoutMenu: true,
    });
  };
  const checkDirty = (): void => {
    const formValue = form.getFieldsValue();
    const formProps = Object.keys(formValue);
    for (let i = 0; i < formProps.length; i++) {
      if (form.isFieldTouched(formProps[i])) {
        return setIsDirty(true);
      }
    }
    setIsDirty(false);
  };

  const openColorPickerModal = () => {
    setIsColorModal(true);
  };
  const closeColorPickerModal = () => {
    setIsColorModal(false);
  };

  const chooseNewColor = (color: ScheduleCategoryColors) => {
    const newId = "new" + uuidv4();
    const newCategory: ScheduleCategory = {
      id: newId,
      name: "",
      color: {
        id: color.id,
        name: color.name,
        colorRgb: color.colorRgb,
      },
      createdAt: "",
      updatedAt: "",
      isDefault: false,
      isDeletable: true,
    };
    const newCategoryList = [...newCategories, newCategory];
    setNewCategories(newCategoryList);
    setIsColorModal(false);
    form.setFieldValue([`category${newId}`, `colorId`], color.id);
    form.setFieldValue([`category${newId}`, `id`], newId);

    // get available colors
    const availableColorList = listColors.filter(
      (color) =>
        ![...newCategoryList, ...scheduleCategories].some((category) => {
          return category.color.id === color.id;
        })
    );
    setAvailableColors(availableColorList);
  };

  return {
    onCancel,
    checkDirty,
    putScheduleCategoryUpdate,
    initiatingData,
    onDeleteCategory,
    openColorPickerModal,
    closeColorPickerModal,
    chooseNewColor,
    defaultColor,
    form,
    isDirty,
    listColors,
    availableColors,
    scheduleCategories,
    newCategories,
    isColorModal,
  };
};

export default Handler;
