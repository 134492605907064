import { createSlice } from '@reduxjs/toolkit';

type H1ModalSlice = {
    isOpenH1: boolean;
};
const initialState: H1ModalSlice = {
    isOpenH1: false,
};

export const H1ModalSlice = createSlice({
    name: 'H1ModalSlice',
    initialState,
    reducers: {
        showH1Modal: (state) => {
            state.isOpenH1 = true;
        },
        hideH1Modal: (state) => {
            state.isOpenH1 = false;
        },
    },
});

export const { showH1Modal, hideH1Modal } = H1ModalSlice.actions;
export default H1ModalSlice.reducer;
