import axiosConfig from "../../../../../config/axiosConfig";

const CM0024Api = () => {
  const getListReSummaryOutsourceCostCompany = async (
    params: any
  ): Promise<any> => {
    try {
      const url = "/re-summary/outsource-cost/company";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getListReSummaryOutsourceCostCompanyProject = async (
    params: any
  ): Promise<any> => {
    try {
      const url = "/re-summary/outsource-cost/company/project";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getListReSummaryCompanyProjectOutsourceCost = async (
    params: any
  ): Promise<any> => {
    try {
      const url = "/re-summary/company/project/outsource-cost";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getSubCategory = async (): Promise<any> => {
    try {
      const url = "/money/received-money-offset/sub-category";
      const response = await axiosConfig.get(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getListMoneyDirectCostOutsourceOffset = async (
    params: any
  ): Promise<any> => {
    try {
      const url = "/money/direct-cost/outsource/offset/list";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getStatus = async (params: any): Promise<any> => {
    try {
      const url = "/re-summary/outsource-cost/company/project/document/status";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const submit = async (data: any): Promise<any> => {
    try {
      const url = "/re-summary/outsource-cost/company/project/submit";
      const response = await axiosConfig.post(url, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getStatusCM0026 = async (params: any): Promise<any> => {
    try {
      const url = "/re-summary/outsource-cost/project/document/status";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const submitCM0026 = async (data: any): Promise<any> => {
    try {
      const url = "/re-summary/outsource-cost/project/submit";
      const response = await axiosConfig.post(url, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const doExportCM0024 = async (params: any) => {
    try {
      const url = "/re-summary/exportCM0024";
      const response = await axiosConfig.get(url, {
        params,
        responseType: "blob",
      });

      return response;
    } catch (error) {
      throw error;
    }
  };

  return {
    getListReSummaryOutsourceCostCompany,
    getListReSummaryOutsourceCostCompanyProject,
    getListReSummaryCompanyProjectOutsourceCost,
    getSubCategory,
    getListMoneyDirectCostOutsourceOffset,
    getStatus,
    submit,
    getStatusCM0026,
    submitCM0026,
    doExportCM0024,
  };
};

export default CM0024Api;
