import React, { useEffect, useRef } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { HandlerImpl } from "../handler/HandlerImpl";
import BreadcrumbComponent from "../../../../../common/components/breadcrumb/BreadcrumbComponent";
import { Button, Form, Layout } from "antd";
import UploadLightSVG from "../../../../../assets/icons/upload-icon-purple.svg";
import HomeSvg from "../../../../../assets/icons/home.svg";
import TableComponent from "./Table";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import MenuWrapperComponent from "../../../../../common/components/menu-wrapper";
import { DataType } from "../../entity/Entity";
import moment from "moment";
import COMMON from "../../../../../common/constants/COMMON";
import FilterSearchComponent from "../../../AM004/presenter/ui/FilterSearch";
import { getTitleByFromToDate } from "../../../AM008/helper";
import AM042Component from "../../../AM042";
import SearchBarComponent from "../../../AM008/presenter/component/filter/ui/SearchBar";
import { OpenStreetMapComponent } from "../../../../../common/components/open-street-map/OpenStreetMapComponent";
import {
  AUTHORIZATION_CODE,
  SYSTEM_ROLES,
} from "../../../../../common/constants/AUTHORIZATION";

interface props {
  handler: HandlerImpl;
}
const { Content, Sider } = Layout;

const BREADCRUMB_AM006 = (preParams: string) => [
  {
    title: "勤怠管理",
    url: "",
    icon: (
      <img style={{ paddingBottom: 2, paddingRight: 8 }} alt="" src={HomeSvg} />
    ),
  },
  {
    title: "案件別勤務実績",
    url: "/app-am005",
    icon: "",
  },
  {
    title: "会社詳細勤務実績",
    url: "",
    icon: "",
  },
];

const MainUI = ({ handler }: props) => {
  // Ref
  const mountFirst = useRef(false);
  // Selector
  const isVisibleStreetMap = useSelector(
    (state: RootState) => state.openStreetMap.isVisible
  );
  const tableColumns = useSelector(
    (state: RootState) => state.am006.tableColumns
  );
  const permission = useSelector(
    (state: RootState) => state.auth.authData?.permission
  );
  const isRoleAdmin = useSelector((state: RootState) => state.auth?.authData?.isRoleAdmin)

  const isRoleAdminSystem =
    useSelector((state: RootState) => state.auth?.authData?.isRoleAdminSystem)
  // Params
  const { constructionId } = useParams();
  const [searchParams] = useSearchParams();
  const fromDate =
    searchParams.get("startDate") ||
    moment().startOf("month").format(COMMON.FORMAT_DATE2);
  const toDate =
    searchParams.get("endDate") || moment().format(COMMON.FORMAT_DATE2);
  const pageDate = searchParams.get("pageDate");
  const title = searchParams.get("title") || "";
  const keyword = searchParams.get("keyword") || "";
  const isCollapsed = searchParams.get("isCollapsed") ? true : false;
  const preParams = searchParams.get("preParams") || "";
  const constructionName = searchParams.get("constructionName") || "";
  const projectId = searchParams.get("projectId") || "";
  const projectName = searchParams.get("projectName") || "";
  useEffect(() => {
    handler.initiateFilter();
  }, []);

  useEffect(() => {
    if (mountFirst.current) handler.onPagingMember("page", "size", keyword); // page and size is not in use in this AM006
    mountFirst.current = true;
  }, [keyword]);

  useEffect(() => {
    handler.calculateTableHeader(fromDate, toDate);
  }, [fromDate, toDate, pageDate]);

  useEffect(() => {
    (async () => {
      const param: any = {
        keyword: keyword,
        from: fromDate,
        to: toDate,
      };
      if (constructionId) param.constructionId = parseInt(constructionId);
      await handler.getAttendanceConstructionListMyMember(param);
    })();
  }, [fromDate, toDate,]);
  return (
    <>
      <MenuWrapperComponent chosenKey={23} openKey={2}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
          }}
        >
          <div className="om002-header-container">
            <BreadcrumbComponent data={BREADCRUMB_AM006(preParams)} />
            <div className="header-row-title" style={{ marginTop: 8 }}>
              会社詳細勤務実績
            </div>
          </div>
          <div>
            <Form form={handler.form}>
              <SearchBarComponent onSearch={handler.onSearch} width={1082} />
            </Form>
          </div>
          {permission?.includes(AUTHORIZATION_CODE.B9) || isRoleAdmin || isRoleAdminSystem ? (
            <Button
              className="button-brown-3"
              style={{ border: "1px solid #605BFF", width: 106 }}
              size="large"
              onClick={() => handler.exportData({
                keyword: keyword,
                from: fromDate,
                to: toDate,
                projectId,
                projectName,
                constructionId,
              })}
            >
              <div className="center-item">
                <img
                  alt=""
                  src={UploadLightSVG}
                  style={{
                    marginRight: 8,
                    fontWeight: 700,
                    fontSize: 14,
                  }}
                />
                <div
                  className="export-button"
                  style={{ color: "#605BFF" }}
                >
                  出力
                </div>
              </div>
            </Button>
          ) : (
            <div></div>
          )}
        </div>
        <Layout
          style={{ minHeight: "84vh", marginTop: 24 }}
          className={isCollapsed ? "close-side" : "open-side"}
        >
          <Sider>
            <FilterSearchComponent
              onFromDateChange={handler.onFromDateChange}
              onToDateChange={handler.onToDateChange}
              rangeDate={handler.rangeDate}
              onFilter={handler.onFilter}
              onResetFilter={handler.onResetFilter}
              onCollapse={handler.onCollapse}
              onChange={handler.onSearch}
              isCollapsed={isCollapsed}
              form={handler.form}
            />
          </Sider>
          <Layout style={{ backgroundColor: "#ffffff" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "24px 24px 16px 24px",
                justifyContent: "space-between",
              }}
            >
              <div
                className="construction-name"
                style={{ margin: 0 }}
              >{`${title} / ${constructionName} / 自社`}</div>
              <div
                className=" date-label"
                style={{ minWidth: "18%", padding: 0 }}
              >
                {getTitleByFromToDate(fromDate, toDate)}
              </div>
            </div>
            <Content
              style={{
                margin: "0px 24px 16px 24px",
                backgroundColor: "#ffffff",
              }}
            >
              <TableComponent
                expandKey={handler.expandKey}
                isCollapsed={handler.isCollapsed}
                onChangeCollapseRow={handler.onChangeCollapseRow}
                constructionId={constructionId}
                onNext={handler.onNext}
                onPrev={handler.onPrev}
                dataSource={handler.members}
                columnsTable={tableColumns.columns}
                isPrev={tableColumns.isPrev}
                isNext={tableColumns.isNext}
              />
            </Content>
          </Layout>
        </Layout>
      </MenuWrapperComponent>
      <AM042Component />
      {isVisibleStreetMap ? <OpenStreetMapComponent /> : <></>}
    </>
  );
};

export default MainUI;
