import axiosConfig from "../../../../../config/axiosConfig";

const PM012Api = () => {
  const getListConstruction = async (params: any): Promise<any> => {
    try {
      const url = "/project/detail";
      return await axiosConfig.get(url, { params });
    } catch (error) {
      throw error;
    }
  };
  const getMySubcontractor = async (params: any): Promise<any> => {
    try {
      const url = "/invite/construction/list-partner-construction";
      return await axiosConfig.get(url, { params });
    } catch (error) {
      throw error;
    }
  };

  const getMyUninvitedMember = async (params: any): Promise<any> => {
    try {
      const url = "/invite/construction/list-all-my-member";
      return await axiosConfig.get(url, { params });
    } catch (error) {
      throw error;
    }
  };

  const getMyInvitedMember = async (params: any): Promise<any> => {
    try {
      const url = "/invite/construction/list-my-member";
      return await axiosConfig.get(url, { params });
    } catch (error) {
      throw error;
    }
  };

  const addCompanyForConstruction = async (data: any): Promise<any> => {
    try {
      const url = "/invite/construction/contractor";
      return await axiosConfig.post(url, data);
    } catch (error) {
      throw error;
    }
  };

  const inviteMemberToConstruction = async (data: any): Promise<any> => {
    try {
      const url = "/invite/add-my-member";
      return await axiosConfig.post(url, data);
    } catch (error) {
      throw error;
    }
  };

  const unInviteMemberToConstruction = async (data: any): Promise<any> => {
    try {
      const url = "/invite/remove-my-member";
      return await axiosConfig.delete(url, { data });
    } catch (error) {
      throw error;
    }
  };

  const updateDoubleCheck = async (data: any) => {
    try {
      const url = "/invite/subconstruction/double-check";
      return await axiosConfig.post(url, data);
    } catch (error) {
      throw error;
    }
  };

  const getListRole = async (params?: any): Promise<any> => {
    try {
      const url = "/organization/list-role";
      return await axiosConfig.get(url, { params });
    } catch (error) {
      throw error;
    }
  };

  // API PM014
  const getSubcontractorinformation = async (params: any): Promise<any> => {
    try {
      const url = "/invite/subconstruction/information";
      return await axiosConfig.get(url, { params });
    } catch (error) {
      throw error;
    }
  };

  const getSubcontractorListMemberParent = async (
    params: any
  ): Promise<any> => {
    try {
      const url = "/invite/subconstruction/list-review-member";
      return await axiosConfig.get(url, { params });
    } catch (error) {
      throw error;
    }
  };

  const getListSubcontractor = async (params: any): Promise<any> => {
    try {
      const url = "/invite/subconstruction/list-subconstruction";
      return await axiosConfig.get(url, { params });
    } catch (error) {
      throw error;
    }
  };

  const updateContractorLevel = async (data: any): Promise<any> => {
    try {
      const url = "/invite/contractor/update-level";
      return await axiosConfig.put(url, data);
    } catch (error) {
      throw error;
    }
  };

  const getSubcontractorListMember = async (params: any): Promise<any> => {
    try {
      const url = "/invite/subcontractor/list-member";
      return await axiosConfig.get(url, { params });
    } catch (error) {
      throw error;
    }
  };

  const inviteSubcontractorMember = async (data: any): Promise<any> => {
    try {
      const url = "/invite/subcontractor/member";
      return await axiosConfig.post(url, data);
    } catch (error) {
      throw error;
    }
  };

  const updateLaborCost = async (data: any): Promise<any> => {
    try {
      const url = "/invite/subcontractor/member/labor-cost/edit";
      return await axiosConfig.put(url, data);
    } catch (error) {
      throw error;
    }
  };

  const uninviteSubcontractorMember = async (data: any): Promise<any> => {
    try {
      const url = "/invite/subcontractor/member/uninvite";
      return await axiosConfig.post(url, data);
    } catch (error) {
      throw error;
    }
  };

  const removeSubcontractorMember = async (data: any): Promise<any> => {
    try {
      const url = "/invite/subcontractor/member/remove";
      return await axiosConfig.delete(url, { data });
    } catch (error) {
      throw error;
    }
  };

  return {
    getListConstruction,
    getMySubcontractor,
    getMyUninvitedMember,
    getMyInvitedMember,
    addCompanyForConstruction,
    inviteMemberToConstruction,
    unInviteMemberToConstruction,
    getListRole,
    updateDoubleCheck,

    // API PM014
    getSubcontractorinformation,
    getSubcontractorListMemberParent,
    getListSubcontractor,
    updateContractorLevel,
    getSubcontractorListMember,
    inviteSubcontractorMember,
    updateLaborCost,
    uninviteSubcontractorMember,
    removeSubcontractorMember,
  };
};

export default PM012Api;
