// Built-in
import React, { useEffect } from 'react';

// 3rd party lib
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';

// Source files
import { CompletedPaidAmountHandlerImpl } from '../../../handler/HandlerImpl';
import { useSearchParams } from 'react-router-dom';
import COMMON from '../../../../../../../common/constants/COMMON';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store';
import { calculateEstimate } from '../../../../helper';
import helpers from '../../../../../../../common/helpers/common';

// Image files
type props = {
    handler: CompletedPaidAmountHandlerImpl;
};

const COLUMN_WIDTH_COMPLETED_PAID_AMOUNT = [`${100 / 3}`, `${100 / 3}`, `${100 / 3}`];

const CompletedPaidAmountUI = ({ handler }: props) => {
    // Params
    const [searchParams] = useSearchParams();
    const constructionId = searchParams.get('constructionId');
    const fromDate = searchParams.get('filterDate') && moment(searchParams.get('filterDate')).isValid()
        ? moment(searchParams.get('filterDate')).startOf('month').format(COMMON.FORMAT_DATE2)
        : moment().startOf('month').format(COMMON.FORMAT_DATE2);
    const toDate = searchParams.get('filterDate') && moment(searchParams.get('filterDate')).isValid()
        ? moment(searchParams.get('filterDate')).endOf('month').format(COMMON.FORMAT_DATE2)
        : moment().endOf('month').format(COMMON.FORMAT_DATE2);
    useEffect(() => {
        (async () => {
            if (constructionId && !isNaN(parseInt(constructionId)))
                await handler.getMoneyConstructionWorkAmountCompleted({
                    constructionId: parseInt(constructionId),

                });
        })();
    }, [constructionId, fromDate, toDate]);

    const constructionInfo = useSelector((state: RootState) => state.cm004.constructionInfo);
    const moneyConstructionWorkAmountCompletedResult = useSelector(
        (state: RootState) => state.cm005.moneyConstructionWorkAmountCompletedResult,
    );

    const columns: ColumnsType<any> = [
        {
            /*
            + 請求出来高 (Số tiền nhận từ công ty thầu)
            Tính tổng tiền 請求金額 ở mục #8.3, chỉ tính tổng đối với record có trạng thái 承認済
            */
            title: '請求出来高',
            key: '請求出来高',
            align: 'center',
            width: COLUMN_WIDTH_COMPLETED_PAID_AMOUNT[0],
            render: (record) => `${helpers.formatNumberMoney(record.billingVolume)}円`,
        },
        {
            /*
            + 請求出来高(％) ( số % nhận được từ công ty thầu)
            Công thức: 請求出来高 x 100/ 契約金額 ở mục #3
            */
            title: '請求出来高(％)',
            key: '請求出来高(％)',
            align: 'center',
            width: COLUMN_WIDTH_COMPLETED_PAID_AMOUNT[1],
            render: (record) => {
                const estimateCost = calculateEstimate(constructionInfo);
                return `${helpers.calculateRateAmount(estimateCost, record.billingVolume)}%`;
            },
        },
        {
            /*
            + 請求残金 (Số tiền còn lại sẽ nhận được từ công ty thầu)
            Công thức: 契約金額 ở mục #3 - 請求出来高"	
            */
            title: '請求残金',
            key: '請求残金',
            align: 'center',
            width: COLUMN_WIDTH_COMPLETED_PAID_AMOUNT[2],
            render: (record) => {
                const billBalance = calculateEstimate(constructionInfo) - record.billingVolume;
                return `${helpers.formatNumberMoney(billBalance)}円`;
            },
        },
    ];

    return (
        <Table
            title={() => <main className="cm005-title-header">工事出来高</main>}
            bordered={true}
            columns={columns}
            dataSource={[
                {
                    key: 'completed-paid-amount',
                    billingVolume: moneyConstructionWorkAmountCompletedResult?.billingVolume ?? 0,
                },
            ]}
            className="collapsed-table-header table-footer-inside cm005-table-header"
            pagination={false}
        />
    );
};

export default CompletedPaidAmountUI;
