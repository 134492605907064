import { SCREENCM0032 } from './../../../../../common/constants/SCREENS';
import { Form } from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmModal from '../../../../../common/components/modal/ConfirmModal';
import ErrorNotification from '../../../../../common/components/notification/ErrorNotification';
import MESSAGE, {
    LABEL_MESSAGE,
    NOTIFICATION_TITLE,
} from '../../../../../common/constants/MESSAGE';
import { RootState } from '../../../../../store';
import { ReceivedMoneyOffset, SubCategory } from '../../entity/Entity';
import { CM0063UseCase } from '../../usecase/ServiceImpl';
import {
    setDataLocalCM0063,
    setIsVisibleCM0063,
    setLoadingCM0063,
    setOriginDataLocal,
    setTypeModalCM0063,
} from '../slice/Slice';
import { DataLocal } from '../type/Presenter';
import COMMON from '../../../../../common/constants/COMMON';
import { uuidv4 } from '@firebase/util';

const CM0063Handler = (CM0063Service: CM0063UseCase) => {
    // LIB FUNCTION
    const dispatch = useDispatch();

    //STATE
    const [form] = Form.useForm();
    const [isDirty, setIsDirty] = useState(false);

    const [pageOption, setPageOption] = useState(COMMON.DEFAULT_PAGE);
    const [sizeOption, setSizeOption] = useState(COMMON.DEFAULT_SIZE);
    const [totalPage, setTotalPage] = useState<number>();

    const typeModal = useSelector((state: RootState) => state.cm0063.typeModal);
    const parentCode = useSelector((state: RootState) => state.cm0063.parentCode);
    const dataLocal = useSelector((state: RootState) => state.cm0063.dataLocal);
    const screen = useSelector((state: RootState) => state.cm0063.screen);
    const taxCode = useSelector((state: RootState) => state.cm0063.taxCode);
    const [defaultCategory, setDefaultCategory] = useState<string>('');
    const [defaultCategoryName, setDefaultCategoryName] = useState<string>('');

    const [contentDescription, setContentDescription] = useState<any>();
    const [listReceivedMoneyOffset, setListReceivedMoneyOffset] = useState<ReceivedMoneyOffset[]>(
        [],
    );
    const [listReceivedMoneyOffsetLocal, setListReceivedMoneyOffsetLocal] = useState<
        ReceivedMoneyOffset[]
    >([]);

    const [subCategory, setSubCategory] = useState<SubCategory[]>([]);
    const [totalCost, setTotalCost] = useState<number>(0);

    const childFunc = async (receiveMoneyId: string | number, page: number, size: Number) => {
        const params = {
            receiveMoneyId,
            page,
            size,
        };

        let dataOutput: any[] = [];
        let origin: any = {};

        let stop = true;
        let index = 0;

        do {
            const res = await CM0063Service.getListReceivedMoneyOffset({
                ...params,
                page: params.page + index,
            });

            dataOutput.push(...res.results.data);
            origin['num'] = res.pagination?.numPages ?? 0;
            origin['startDate'] = res.results?.startDate ?? '';
            origin['endDate'] = res.results?.endDate ?? '';
            origin['orderingCompanyName'] = res.results?.orderingCompanyName ?? '';

            index += 1;

            if (res.results.data.length === 0) {
                stop = false;
            }
        } while (stop);

        setTotalPage(origin?.num ?? 0);
        const contentDescription = {
            startDate: origin.startDate,
            endDate: origin.endDate,
            orderingCompanyName: origin.orderingCompanyName,
        };
        setContentDescription(contentDescription);
        const data = formatReceivedMoneyOffset(dataOutput);
        if (page === COMMON.DEFAULT_PAGE) {
            setListReceivedMoneyOffset(data);
            setListReceivedMoneyOffsetLocal(data);
        } else {
            setListReceivedMoneyOffset([...listReceivedMoneyOffset, ...data]);
            setListReceivedMoneyOffsetLocal([...listReceivedMoneyOffsetLocal, ...data]);
        }
        const newDataLocal: DataLocal[] = AddDataLocal({
            parentRecordId: parentCode?.parentRecordId,
            arr: data,
            startDate: origin.startDate,
            endDate: origin.endDate,
            orderingCompanyName: origin.orderingCompanyName,
        });

        dispatch(setDataLocalCM0063(newDataLocal));
        handleFillForm(data);
    };

    //function
    const handleGetListReceivedMoneyOffset = async (
        receiveMoneyId: string | number,
        page: number,
        size: Number,
    ): Promise<any> => {
        try {
            dispatch(setLoadingCM0063(true));
            const goal = dataLocal.find(
                (item) => item.parentRecordId === parentCode.parentRecordId,
            );

            if (goal && parentCode?.parentRecordId) {
                if (page === COMMON.DEFAULT_PAGE && screen !== SCREENCM0032) {
                    getListReceivedMoneyOffsetLocal(parentCode.parentRecordId);
                } else {
                    childFunc(receiveMoneyId, page, size);
                }
            }
            if (!goal && typeof parentCode?.parentRecordId === 'number') {
                childFunc(receiveMoneyId, page, size);
            }
            if (!goal && typeof parentCode?.parentRecordId === 'string')
                setListReceivedMoneyOffsetLocal([]);
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        } finally {
            dispatch(setLoadingCM0063(false));
        }
    };

    const handleScrollListReceivedMoneyOffset = async (
        e: any,
        receiveMoneyId: string | number,
    ): Promise<any> => {
        try {
            const target = e.target;
            if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
                if (totalPage && pageOption + 1 <= totalPage) {
                    setPageOption(pageOption + 1);
                    const data = await handleGetListReceivedMoneyOffset(
                        receiveMoneyId,
                        pageOption + 1,
                        sizeOption,
                    );
                }
            }
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        }
    };

    const getListReceivedMoneyOffsetLocal = (parentRecordId: string | number): void => {
        const res = dataLocal.find((item) => item.parentRecordId === parentRecordId);

        if (res) {
            const contentDescription = {
                startDate: res.startDate,
                endDate: res.endDate,
                orderingCompanyName: res.orderingCompanyName,
            };
            setContentDescription(contentDescription);
            setListReceivedMoneyOffsetLocal(res.arr);
            handleFillForm(res?.arr);
        }
    };

    const formatReceivedMoneyOffset = (data: any) => {
        const arr: ReceivedMoneyOffset[] = [];
        data.forEach((item: any, index: number) => {
            if (taxCode && screen === SCREENCM0032)
                taxCode === item?.category?.code &&
                    arr.push({
                        key: uuidv4(),
                        id: item.id,
                        item: item.name,
                        taxCategoryComsumptionId: item?.category?.id,
                        taxCategoryComsumptionName: item?.category?.description,
                        offsetAmount: item.value,
                    });
            else
                arr.push({
                    key: uuidv4(),
                    id: item.id,
                    item: item.name,
                    taxCategoryComsumptionId: item?.category?.id,
                    taxCategoryComsumptionName: item?.category?.description,
                    offsetAmount: item.value,
                });
        });
        return arr;
    };

    const handleGetSubCategory = async (): Promise<any> => {
        try {
            dispatch(setLoadingCM0063(true));
            const res = await CM0063Service.getSubCategory();
            if (res?.results) {
                setSubCategory(handleFormatSubCategory(res.results));
                const finded = res.results.find((item: any) => item.code === 'tax');

                if (finded) {
                    setDefaultCategory(finded.id);
                    setDefaultCategoryName(finded.description);
                }
            }
        } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        } finally {
            dispatch(setLoadingCM0063(false));
        }
    };

    const handleFormatSubCategory = (data: any) => {
        const arr: SubCategory[] = data.map((item: any) => {
            return {
                label: item.description,
                value: `${item.id}`,
                code: item.code,
            };
        });
        return arr;
    };

    const handleFillForm = (data: ReceivedMoneyOffset[]) => {
        data.map((item) => {
            form.setFieldValue(`item${item.key}`, item.item);
            form.setFieldValue(
                `taxCategoryConsumption${item.key}`,
                `${item.taxCategoryComsumptionId}`,
            );
            form.setFieldValue(`offsetAmount${item.key}`, item.offsetAmount);
        });
    };

    const handleCalculateChange = (data: ReceivedMoneyOffset[]) => {
        let total: number = 0;
        data.forEach((item) => {
            total += item?.offsetAmount
                ? item.offsetAmount
                : form.getFieldValue(`offsetAmount${item.key}`) ?? 0;
        });
        setTotalCost(total);
    };

    const handleAddRowLocal = () => {
        const uniqueId = uuidv4();
        const data: ReceivedMoneyOffset = {
            key: uuidv4(),
            item: undefined,
            taxCategoryComsumptionId: undefined,
            offsetAmount: undefined,
        };
        setListReceivedMoneyOffsetLocal([...listReceivedMoneyOffsetLocal, data]);
        form.setFieldValue(`taxCategoryConsumption${uniqueId}`, subCategory[0]?.value);
    };

    const handleRemoveRowLocal = (key: string) => {
        if (
            !form.getFieldValue(`item${key}`) &&
            form.getFieldValue(`taxCategoryConsumption${key}`) === subCategory[0]?.value &&
            !form.getFieldValue(`offsetAmount${key}`)
        ) {
            const arr = listReceivedMoneyOffsetLocal.filter((item) => item.key !== key);
            setListReceivedMoneyOffsetLocal([...arr]);
            handleCalculateChange(arr);
            return;
        }
        ConfirmModal({
            onOk: async () => {
                const arr = listReceivedMoneyOffsetLocal.filter((item) => item.key !== key);
                setListReceivedMoneyOffsetLocal(arr);
                handleCalculateChange(arr);
                setIsDirty(true);
            },
            className: 'confirm__modal',
            title: MESSAGE.MESSAGE_MODAL_DELETE_TITLE,
            description: MESSAGE.MESSAGE_MODAL_DELETE_DESCRIPTION_CM,
            canceText: LABEL_MESSAGE.CANCEL_MODAL,
            okText: LABEL_MESSAGE.OK_MODAL,
            isCenterWithoutMenu: true,
        });
    };

    const handleSubmitForm = async (value: any) => {
        let listData: ReceivedMoneyOffset[] = [];
        listReceivedMoneyOffsetLocal.forEach((item) => {
            listData.push({
                key: item.key,
                item: form.getFieldValue(`item${item.key}`),
                taxCategoryComsumptionId:
                    form.getFieldValue(`taxCategoryConsumption${item.key}`) ?? `${defaultCategory}`,
                taxCategoryComsumptionName: form.getFieldValue(`taxCategoryConsumption${item.key}`)
                    ? convertCategoryIdToCategoryName(
                          form.getFieldValue(`taxCategoryConsumption${item.key}`),
                      )
                    : `${defaultCategoryName}`,

                offsetAmount: form.getFieldValue(`offsetAmount${item.key}`) ?? 0,
            });
        });

        const newDataLocal: DataLocal[] = AddDataLocal({
            parentRecordId: parentCode?.parentRecordId,
            arr: listData,
        });
        setListReceivedMoneyOffsetLocal(listData);
        dispatch(setDataLocalCM0063(newDataLocal));
        if (typeof parentCode.parentRecordId === 'number') {
            form.resetFields();
            setIsDirty(false);
            return dispatch(setTypeModalCM0063(false));
        }
        dispatch(setIsVisibleCM0063(false));
        setIsDirty(false);
    };

    const convertCategoryIdToCategoryName = (id: number) => {
        const res = subCategory.find((item) => String(item.value) === String(id));
        return res?.label;
    };

    const AddDataLocal = (data: DataLocal) => {
        let checkExist = false;
        const arr: DataLocal[] = [];

        dataLocal.forEach((item) => {
            if (item.parentRecordId === data.parentRecordId) {
                arr.push(data);
                checkExist = true;
            } else arr.push(item);
        });
        if (!checkExist) arr.push(data);
        return arr;
    };

    const handleChangeTypeModal = (type: boolean) => {
        if (type) handleFillForm(listReceivedMoneyOffsetLocal);
        dispatch(setTypeModalCM0063(type));
    };

    const checkDirty = (): void => {
        const formValue = form.getFieldsValue();
        const formProps = Object.keys(formValue);
        for (let i = 0; i < formProps.length; i++) {
            if (form.isFieldTouched(formProps[i])) {
                return setIsDirty(true);
            }
        }
        setIsDirty(false);
    };

    const onCancel = () => {
        if (!isDirty) {
            if (!typeModal) dispatch(setIsVisibleCM0063(false));
            else {
                if (typeof parentCode.parentRecordId === 'number') {
                    getListReceivedMoneyOffsetLocal(parentCode.parentRecordId);
                    dispatch(setTypeModalCM0063(false));
                } else dispatch(setIsVisibleCM0063(false));
            }
            return;
        }
        ConfirmModal({
            onOk: async () => {
                if (typeof parentCode.parentRecordId === 'number') {
                    dispatch(setTypeModalCM0063(false));
                    getListReceivedMoneyOffsetLocal(parentCode.parentRecordId);
                } else {
                    const prevData = dataLocal.find(
                        (item) => item.parentRecordId === parentCode.parentRecordId,
                    );
                    if (prevData) {
                        handleCalculateChange(prevData.arr);
                        setListReceivedMoneyOffsetLocal(prevData.arr);
                    }
                    dispatch(setIsVisibleCM0063(false));
                    form.resetFields();
                }
                setIsDirty(false);
            },
            className: 'confirm__modal',
            title: MESSAGE.MESSAGE_020,
            description: MESSAGE.MESSAGE_021,
            canceText: LABEL_MESSAGE.CANCEL_MODAL,
            okText: LABEL_MESSAGE.OK_MODAL,
            isCenterWithoutMenu: true,
        });
    };

    return {
        form,
        typeModal,
        contentDescription,
        listReceivedMoneyOffsetLocal,
        totalCost,
        defaultCategory,

        subCategory,
        onCancel,
        checkDirty,
        handleAddRowLocal,
        handleRemoveRowLocal,
        handleSubmitForm,
        handleCalculateChange,
        handleGetListReceivedMoneyOffset,
        getListReceivedMoneyOffsetLocal,
        handleGetSubCategory,
        handleChangeTypeModal,
        handleScrollListReceivedMoneyOffset,
    };
};

export default CM0063Handler;
