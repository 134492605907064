import { ImportMemberEntity } from '../entity/Entity';
import { OM005Api } from './ServiceImpl';

const OM005Service = (OM005Api: OM005Api) => {
    const importMember = async (data: ImportMemberEntity): Promise<any> => {
        try {
            return await OM005Api.importMember(data);
        } catch (error) {
            throw error;
        }
    };

    const getGroupList = async (params: any): Promise<any> => {
        try {
            return await OM005Api.getGroupList(params);
        } catch (error) {
            throw error;
        }
    };
    const getRoleList = async (params: any): Promise<any> => {
        try {
            return await OM005Api.getRoleList(params);
        } catch (error) {
            throw error;
        }
    };

    const exportTemplateCSV = async (): Promise<any> => {
        try {
            return await OM005Api.exportTemplateCSV();
        } catch (error) {
            throw error;
        }
    };

    const exportTemplateEXCEL = async (): Promise<any> => {
        try {
            return await OM005Api.exportTemplateEXCEL();
        } catch (error) {
            throw error;
        }
    };

    return {
        importMember,
        getGroupList,
        getRoleList,
        exportTemplateCSV,
        exportTemplateEXCEL,
    };
};

export default OM005Service;
