import { Input, Modal } from "antd";
import { ReactNode } from "react";
import { LegacyButtonType } from "antd/lib/button/button";
import "../../../assets/styles/components/confirm-modal.css";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { TITLE_CCUS01 } from "../../constants/MESSAGE";
import helpers from "../../helpers/common";

type props = {
  onOk: () => void;
  onCancel?: () => void;
  title: string;
  description: string | JSX.Element;
  canceText: string;
  okText: string;
  icon?: ReactNode;
  ccusId: string;
  ccusPassword: string;
  width?: number;
  height?: any;
  className?: string;
  okType?: LegacyButtonType;
  isCenterWithoutMenu?: boolean;
};

const CcusidModal = ({
  onOk,
  onCancel,
  title,
  description,
  canceText,
  okText,
  icon,
  width,
  height,
  className,
  okType,
  isCenterWithoutMenu,
  ccusId,
  ccusPassword,
}: props) => {
  const formattedTitle = title
    .split("\n")
    .map((line, index) => <div key={index}>{line}</div>);

  const formattedDescription =
    typeof description === "string"
      ? description
          .split("\n")
          .map((line, index) => <div key={index}>{line}</div>)
      : description;

  const descriptionComponent = (
    <>
      <div className="description-ccus01">{formattedDescription}</div>
      
      <div className="infomation_ccus01">
        <div className="ccus-business-id">
          <p className="title-ccus01">{TITLE_CCUS01.CCUISID}</p>
          <p className="content-ccus01">
            {helpers.convertToCCUSFormat(ccusId)}
          </p>
        </div>
        <div className="ccus-business-password">
          <div className="title-password-comfirm">
            <p className="title-ccus01">{TITLE_CCUS01.CCUS_PASSWORD}</p>
          </div>
          <div className="input-password-comfirm">
            <Input.Password
              readOnly
              className="custom-password-input"
              value={ccusPassword}
              bordered={false}
              iconRender={(visible) =>
                visible ? (
                  <EyeTwoTone style={{ fontSize: 16 }} />
                ) : (
                  <EyeInvisibleOutlined style={{ fontSize: 16 }} />
                )
              }
            />
          </div>
        </div>
      </div>

      <div style={{ paddingTop: 24 }}>
        <div className="line_ccus01" />
      </div>
    </>
  );

  return Modal.confirm({
    width: width ?? 470,
    style: {
      height: 272,
      marginLeft: 290,
      marginBottom: 170,
    },

    centered: true,
    className:
      className ??
      "ccus__confirm__modal confirm__modal-confirm ccus__confirm__modal-purple-oke ",
    title: <div style={{ fontWeight: "700" }}>{formattedTitle}</div>,
    icon: icon,
    content: descriptionComponent,
    okText: okText,
    okType: okType ? okType : "danger",
    closable: true,
    cancelText: canceText,
    zIndex: 9999,
    onOk: () => {
      if (onOk) {
        onOk();
      }
    },
    onCancel: () => {
      if (onCancel) {
        onCancel();
      }
    },
  });
};

export default CcusidModal;
