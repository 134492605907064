import React, { useEffect } from "react";
import { Button, Form, Modal, Spin } from "antd";
import { useSelector } from "react-redux";
import LocationGPSIcon from "../../../../../assets/icons/location-gps.svg";
import WarningTriangle from "../../../../../assets/icons/warning-triangle.svg";

import XIconModal from "../../../../../assets/icons/x-icon-modal.svg";
import LoadingComponent from "../../../../../common/components/icons/LoadingComponent";
import { RootState } from "../../../../../store";
import { HandlerImpl } from "../handler/HandlerImpl";
import COMMON from "../../../../../common/constants/COMMON";
import moment from "moment";
import XRedIcon from "../../../../../assets/icons/x-red.svg";
import CircleGreenIcon from "../../../../../assets/icons/circle-green.svg";
import { OpenStreetMapComponent } from "../../../../../common/components/open-street-map/OpenStreetMapComponent";

interface Props {
  handler: HandlerImpl;
}
const getTextWithTime = (
  value: any,
  openStreetMap: (lat: any, lng: any) => void
) => (
  <main
    style={{
      display: "flex",
      marginBottom: 16,
      height: 22,
      justifyContent: "center",
    }}
  >
    <p className="modal-label" style={{ marginRight: 7 }}>
      {value.time ? moment(value.time).format(COMMON.FORMAT_TIME1) : ""}
    </p>

    {value.lat != 0 && value.long != 0 ? (
      <div
        className="center-item"
        onClick={() => openStreetMap(value.lat, value.long)}
      >
        <img alt="" src={LocationGPSIcon} />
      </div>
    ) : (
      <></>
    )}
  </main>
);

const getNoTime = () => (
  <main
    style={{
      display: "flex",
      marginBottom: 16,
      height: 22,
      justifyContent: "center",
    }}
  ></main>
);

const AM006 = "am006";
const AM008 = "am008";

const MainUI = ({ handler }: Props) => {
  const { loading, report } = handler;
  const params = useSelector((state: RootState) => state.am042.params);
  const isVisibleStreetMap = useSelector(
    (state: RootState) => state.openStreetMap.isVisible
  );

  useEffect(() => {
    (async () => {
      if (params) await handler.getAttendanceUserTimeSheet(params);
    })();
  }, [JSON.stringify(params)]);

  const showDoubleCheckContent = (
    <div style={{ minHeight: 244, marginTop: 24 }}>
      <div style={{ display: "flex", marginLeft: 6 }}>
        <div style={{ minHeight: 174, width: 190 }}>
          <div style={{ marginBottom: 16, height: 22 }}></div>
          <p style={{ marginBottom: 16 }} className="modal-label">
            出勤時刻
          </p>
          <p style={{ marginBottom: 16 }} className="modal-label">
            退勤時刻
          </p>
          <p style={{ marginBottom: 16 }} className="modal-label">
            担当者確認(出勤)
            {report?.isWarningCheckIn ? (
              <img alt="" src={WarningTriangle} style={{ marginLeft: 8 }} />
            ) : (
              <></>
            )}
          </p>
          {report?.companyList.map((company: any) => {
            return (
              <p
                key={company.companyId}
                style={{ marginBottom: 16 }}
                className="modal-label"
              >
                {company.companyName}
              </p>
            );
          })}
          <p className="modal-label">
            担当者確認(退勤)
            {report?.isWarningCheckOut ? (
              <img alt="" src={WarningTriangle} style={{ marginLeft: 8 }} />
            ) : (
              <></>
            )}
          </p>
          {report?.companyList.map((company: any) => {
            return (
              <p
                key={company.companyId}
                style={{ marginBottom: 16 }}
                className="modal-label"
              >
                {company.companyName}
              </p>
            );
          })}

          <div style={{ height: 24 }}></div>
        </div>
        <div
          style={{
            width: 109 * 7,
            minHeight: 190,
            display: "flex",
            overflow: "auto",
            overflowY: "hidden",
          }}
        >
          {report?.sttArray?.map((element: any, index: number) => (
            <main
              key={element}
              style={{ minWidth: 109, height: 170, marginRight: 24 }}
            >
              <p
                style={{
                  marginBottom: 16,
                  color: "#605BFF",
                  textAlign: "center",
                }}
                className="modal-label"
              >{`${index + 1}回目`}</p>
              {report?.companyList[0].checkin[element]?.time
                ? getTextWithTime(
                    report?.companyList[0].checkin[element],
                    handler.openStreetMap
                  )
                : getNoTime()}
              {report?.companyList[0].checkout[element]?.time
                ? getTextWithTime(
                    report?.companyList[0].checkout[element],
                    handler.openStreetMap
                  )
                : getNoTime()}
              <div
                style={{ marginBottom: 16, height: 22, textAlign: "center" }}
              ></div>
              {report?.companyList.map((company: any, index: number) => {
                return (
                  <div
                    key={index}
                    style={{
                      marginBottom: 16,
                      height: 22,
                      textAlign: "center",
                    }}
                  >
                    {company.checkin[element] ? (
                      company.checkin[element].isDoubleCheck === null ? (
                        <></>
                      ) : company.checkin[element].isDoubleCheck ? (
                        <img alt="" src={CircleGreenIcon} />
                      ) : (
                        <img alt="" src={XRedIcon} />
                      )
                    ) : (
                      <></>
                    )}
                  </div>
                );
              })}
              <div
                style={{ marginBottom: 16, height: 22, textAlign: "center" }}
              ></div>
              {report?.companyList.map((company: any, index: number) => {
                return (
                  <div
                    key={index}
                    style={{
                      marginBottom: 16,
                      height: 22,
                      textAlign: "center",
                    }}
                  >
                    {company.checkout[element] ? (
                      company.checkout[element].isDoubleCheck === null ? (
                        <></>
                      ) : company.checkout[element].isDoubleCheck ? (
                        <img alt="" src={CircleGreenIcon} />
                      ) : (
                        <img alt="" src={XRedIcon} />
                      )
                    ) : (
                      <></>
                    )}
                  </div>
                );
              })}
            </main>
          ))}
        </div>
      </div>
    </div>
  );

  const hideDoubleCheckContent = (
    <div style={{ marginTop: 24 }}>
      <div style={{ display: "flex", marginLeft: 6 }}>
        <div style={{ width: 190 }}>
          <div style={{ marginBottom: 16, height: 22 }}></div>
          <p style={{ marginBottom: 16 }} className="modal-label">
            出勤時刻
          </p>
          <p style={{}} className="modal-label">
            退勤時刻
          </p>
        </div>
        <div
          style={{
            width: 109 * 7,
            display: "flex",
            overflow: "auto",
            overflowY: "hidden",
          }}
        >
          {report?.companyList.map((company: any) => {
            return report?.sttArray?.map((element: any, index: number) => (
              <main key={element} style={{ minWidth: 109, marginRight: 24 }}>
                <p
                  style={{
                    marginBottom: 16,
                    color: "#605BFF",
                    textAlign: "center",
                  }}
                  className="modal-label"
                >{`${index + 1}回目`}</p>
                {company.checkin[element]?.time
                  ? getTextWithTime(
                      company.checkin[element],
                      handler.openStreetMap
                    )
                  : getNoTime()}
                {company.checkout[element]?.time
                  ? getTextWithTime(
                      company.checkout[element],
                      handler.openStreetMap
                    )
                  : getNoTime()}
              </main>
            ));
          })}
        </div>
      </div>
    </div>
  );

  return (
    <Modal
      className="group-detail-modal "
      style={{ marginLeft: 300, marginTop: 40, marginBottom: 40 }}
      visible={params ? true : false}
      closable={false}
      maskClosable={false}
      width={996}
      centered={true}
      footer={
        <Button
          disabled={loading}
          size="large"
          style={{ marginLeft: 16, width: 118 }}
          onClick={handler.closeModal}
        >
          キャンセル
        </Button>
      }
    >
      <Spin indicator={LoadingComponent} spinning={loading}>
        <div
          className="w-100-percent header-modal-containter"
          style={{ height: 50 }}
        >
          <div className="header-modal-title-om004 ">複数打刻</div>
          <div style={{ cursor: "pointer" }} onClick={handler.closeModal}>
            <img alt="" src={XIconModal} style={{ height: 18, width: 18 }} />
          </div>
        </div>
        {window.location.pathname.includes(AM006) ||
        window.location.pathname.includes(AM008)
          ? hideDoubleCheckContent
          : showDoubleCheckContent}
        {isVisibleStreetMap ? <OpenStreetMapComponent /> : <></>}
      </Spin>
    </Modal>
  );
};

export default MainUI;
