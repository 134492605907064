import { Modal, Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { PM012HandlerUI } from '../handler/HandlerImpl';
import { RootState } from '../../../../../store';
import PM012Content from './PM012Content';

import '../../../../../assets/styles/PM/PM012.css';
import '../../../../../assets/styles/PM/PM014.css';
import EmptyData from '../../../../../assets/icons/empty-data.svg';
import XIconModal from "../../../../../assets/icons/x-icon-modal.svg";

import PM014Content from './PM014Content';
import PM014Footer from './PM014Footer';
import PM012Footer from './PM012Footer';
import { useEffect, useRef } from 'react';
import PM024Component from '../../../PM024';
import OM004Component from '../../../../OM/OM004';
import PM011Component from '../../../PM011';
import { hidePM011Modal } from '../../../PM011/presenter/slice/Slice';
import { pm012ChangeOpen } from '../slice/Slice';
import { PM012Nav } from './PM012Nav';
import useWindowDimensions from '../../../../../common/helpers/windowDimension';

interface props {
    handler: PM012HandlerUI;
}

const PM012UI = ({ handler }: props) => {
    const dispatch = useDispatch();
    const didMountRef = useRef(false);
    const isOpen = useSelector((state: RootState) => state.pm012.isOpen);
    const project = useSelector((state: RootState) => state.pm012.project);
    const isViewChooseCompany = useSelector((state: RootState) => state?.pm024.isViewChooseCompany);
    const isOpenPM011 = useSelector((state: RootState) => state.pm011.isOpenPM011);
    const { width } = useWindowDimensions();
    useEffect(() => {
        if (isOpenPM011) dispatch(hidePM011Modal());
        didMountRef.current = true;
        return () => {
            dispatch(pm012ChangeOpen(false));
        };
    }, []);

    useEffect(() => {
        if (project.id) {
            handler.getListConstruction({ projectId: project?.id });
        }
    }, [project, handler.refresh]);

    useEffect(() => {
        handler.getListRole();
    }, []);

    const handleTitlePopup = (): string => {
        if (handler.isViewNav) return 'メンバー招待リスト';
        return 'メンバー招待';
    };

    return (
        <Modal
            title={
                <div
                    className="w-100-percent "
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignContent: "center",
                    }}
                >
                    <div className="header-modal-title-om004 ">
                        {handleTitlePopup()}
                    </div>
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={!handler.loading ? handler.handleClose : () => { }}
                    >
                        <img alt="" src={XIconModal} style={{ height: 18, width: 18 }} />
                    </div>
                </div>
            }
            visible={isOpen}
            width={1572}
            style={{ marginLeft: width > 1872 ? 300 : 0, marginTop: 40, marginBottom: 40 }}
            closable={false}
            className="modal-fixed-1572"
            centered={width > 1700}
            maskClosable={false}
            footer={
                handler.chosenNavItem?.subcontractorId !== 'my_company' ? (
                    <PM014Footer handler={handler} />
                ) : !handler.isViewNav ? (
                    <PM012Footer handler={handler} />
                ) : null
            }
        >
            <div style={{ overflowX: width > 1610 ? 'hidden' : 'auto', overflowY: "hidden" }}>
                <Spin spinning={handler.loading}>
                    <div className="pm012-wrapper">
                        {handler.listConstruction?.length > 0 ? (
                            <>
                                {handler.isViewNav ? (
                                    <>
                                        <PM012Nav handler={handler} />
                                        {didMountRef.current ? <PM011Component /> : <></>}
                                        {handler.chosenNavItem?.subcontractorId !== 'my_company' ? (
                                            <PM014Content handler={handler} />
                                        ) : (
                                            <PM012Content handler={handler} />
                                        )}
                                    </>
                                ) : handler.chosenNavItem?.subcontractorId !== 'my_company' ? (
                                    <PM014Content handler={handler} />
                                ) : (
                                    <PM012Content handler={handler} />
                                )}
                                <>
                                    {isViewChooseCompany ? (
                                        <PM024Component
                                            handleCancel={handler.handleCancelChosenCompany}
                                            handleConfirm={handler.handleConfirmChosenCompany}
                                        />
                                    ) : null}
                                </>
                                <OM004Component />
                            </>
                        ) : (
                            <div className="pm012-empty-data">
                                <img src={EmptyData} alt="" />
                            </div>
                        )}
                    </div>
                </Spin>
            </div>

        </Modal>
    );
};

export default PM012UI;
