import IconSearch from '../../../../../assets/icons/search-icon.svg';
import IconDelete from '../../../../../assets/icons/delete-red.svg';
import { Button, Form, Input } from 'antd';
import { PM014HeaderHandler } from '../handler/HandlerImpl';
import { useDispatch, useSelector } from 'react-redux';
import { project, refreshPM014, setAllChecked } from '../slice/Slice';
import ConfirmModal from '../../../../../common/components/modal/ConfirmModal';
import { LABEL_MESSAGE, NOTIFICATION_TITLE } from '../../../../../common/constants/MESSAGE';
import ErrorNotification from '../../../../../common/components/notification/ErrorNotification';
import { isHasPrivilege } from '../../../../../common/helpers/privilege';
import { PRIVILEGE_CREATOR, PRIVILEGE_PM, PRIVILEGE_SUB_PM } from '../../../../../common/constants/PRIVILEGE';
import { RootState } from '../../../../../store';
const descriptionDelete = (
    <>
        <div>削除されたメンバーは案件にアクセスできません。</div>
        <div>削除してもよろしいでしょうか？</div>
    </>
);
interface props {
    handler: PM014HeaderHandler;
}
function PM014Header({ handler }: props) {
    const dispatch = useDispatch();
    const pData = useSelector(project);
    const projectDetail = useSelector(
        (state: RootState) => state.pm005.projectDetail
    );
    const handleDeleteOrUnInvite = async (constructionId: number) => {
        ConfirmModal({
            onOk: async () => {
                const listUserId: number[] = [];
                let mapper: any = {};
                const data: any[] = [];

                if (handler.checkedKeys.length !== 0) {
                    handler.checkedKeys.forEach((item: any) => {
                        let element: any[] = item.split('-');
                        if (handler.currentTab === '1') {
                            if (`${element[0]}` in mapper) {
                                mapper[`${element[0]}`] = mapper[`${element[0]}`].concat(
                                    Number(element[1]),
                                );
                            } else {
                                mapper[`${element[0]}`] = [Number(element[1])];
                            }
                        } else {
                            listUserId.push(Number(element[1]));
                        }
                    });
                }

                for (const [key, value] of Object.entries(mapper)) {
                    data.push({
                        contractorConstructionId: Number(key),
                        listUserId: value,
                    });
                }

                try {
                    if (handler.currentTab === '2') {
                        await handler.funcUnInviteSubcontractorMember(
                            constructionId,
                            handler.funcHandleMapperInviteData(handler.checkedKeys),
                        );
                        handler.setCheckedKeys([]);
                        handler.setRefreshPM014(!handler.isRefreshPM014);
                        dispatch(setAllChecked([]));
                        handler.setCurrentTab('1');
                    } else {
                        await handler.funcRemoveSubcontractorMember(data, Number(constructionId));
                        handler.setRefreshPM014(!handler.isRefreshPM014);
                        handler.setCheckedKeys([]);
                        dispatch(setAllChecked([]));
                    }
                } catch (error: any) {
                    ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR)
                }
            },
            onCancel: () => { },
            className: 'confirm__modal confirm__modal-confirm',
            title: "削除確認",
            description: descriptionDelete,
            canceText: LABEL_MESSAGE.CANCEL_MODAL,
            okText: LABEL_MESSAGE.OK_DELETE,
        });

    };

    return (
        <div className="pm014-header">
            <div className="pm014-header-tab">
                <div
                    className={`pm014-header-tab-item ${handler.currentTab === '1' && 'pm014-header-tab-item-active'
                        }`}
                    onClick={() => {
                        handler.setCurrentTab('1');
                        handler.setCheckedKeys([]);
                        dispatch(setAllChecked([]));
                    }}
                >
                    未招待メンバー
                </div>
                <div
                    className={`pm014-header-tab-item ${handler.currentTab === '2' && 'pm014-header-tab-item-active'
                        }`}
                    onClick={() => {
                        handler.setCurrentTab('2');
                        handler.setCheckedKeys([]);
                        dispatch(setAllChecked([]));
                    }}
                >
                    招待済みメンバー
                </div>
            </div>
            <>
                <div className="pm014-header-search">
                    <Form
                        form={handler.formSearch}
                        style={{ width: "100%" }}
                        name="pm012-search"
                    >
                        <Form.Item name={"keySearch"} style={{ margin: 0 }}>
                            <Input
                                size="large"
                                prefix={<img alt="" src={IconSearch} />}
                                className="pm012-input-search"
                                placeholder="検索したい名前を入力してください"
                                disabled={!handler.expandAllCollapse}
                                onChange={(e: any) => handler.funcFindKeySearch(e.target.value)}
                            // value={handler.keySearch}
                            />
                        </Form.Item>
                    </Form>

                    {isHasPrivilege(projectDetail, [
                        PRIVILEGE_PM,
                        PRIVILEGE_SUB_PM,
                        PRIVILEGE_CREATOR,
                    ]) ? (
                        <Button
                            style={{ marginLeft: 16 }}
                            className="button-warning pm012-btn-delete pm012-btn-flex"
                            size="large"
                            icon={<img alt="" src={IconDelete} />}
                            onClick={() => handleDeleteOrUnInvite(handler.chosenNavItem.constructionId)}
                            disabled={handler.checkedKeys.length === 0 ? true : false}
                        >
                            削除
                        </Button>
                    ) : (
                        <></>
                    )}
                </div>
            </>
        </div>
    );
}

export default PM014Header;
