import React from "react";
import "antd/dist/antd.min.css";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { SYSTEM_ROLES } from "../common/constants/AUTHORIZATION";
import { PLAN_TYPE } from "../common/constants/COMMON";

export const hasSomeElements = (arrayA: string[], arrayB: string[]) => {
  return arrayB.some((element) => arrayA.includes(element));
};

export const AuthorizationPermissionRouter = (props: {
  allowedPermissions: string[];
  notFree?: boolean
}) => {
  const authData = useSelector((state: RootState) => state.auth.authData);
  const permissions = authData?.permission ?? [];
  const isRoleAdminSystem = authData?.role?.code === SYSTEM_ROLES.ADMIN_SYSTEM;
  const isRoleAdmin = authData?.role?.code === SYSTEM_ROLES.ADMIN;
  const order = useSelector((state: RootState) => state.auth?.authData?.order?.code)  //action.payload?.order?.code;
  const isOrderFree = order === PLAN_TYPE.PLAN_FREE;
  const isOrderBasic = order === PLAN_TYPE.PLAN_BASIC;
  const result = hasSomeElements(permissions, props.allowedPermissions);


  return (result || isRoleAdminSystem || isRoleAdmin) && (!!props.notFree ? !isOrderFree : true) ? (
    <Outlet />
  ) : (
    <Navigate to={"/app-error-403"} replace />
  );
};

export const AuthorizationRoleRouter = (props: { roles: string[] }) => {
  const role = useSelector((state: RootState) => state.auth.authData?.role);
  if (!role) return <Navigate to={"/app-error-403"} replace />;
  const result = props.roles.includes(role.code);
  return result ? <Outlet /> : <Navigate to={"/app-error-403"} replace />;
};

export const AuthorizationPermissionWithoutAdminRouter = (props: {
  allowedPermissions: string[];
}) => {
  const authData = useSelector((state: RootState) => state.auth.authData);
  const permissions = authData?.permission ?? [];

  const result = hasSomeElements(permissions, props.allowedPermissions);
  return result ? <Outlet /> : <Navigate to={"/app-error-403"} replace />;
};


export const AuthorizationPermissionExcludeAminRouter = () => {
  const authData = useSelector((state: RootState) => state.auth.authData);
  const isRoleAdminSystem = authData?.role?.code === SYSTEM_ROLES.ADMIN_SYSTEM;
  const isRoleAdmin = authData?.role?.code === SYSTEM_ROLES.ADMIN;

  return isRoleAdminSystem || isRoleAdmin ? (
    < Navigate to={"/app-error-403"} replace />
  ) : (
    <Outlet />
  );
};
