import { Button, Modal } from 'antd';
import { useSelector } from 'react-redux';
import '../../../../../assets/styles/SM/SM06.css';
import { RootState } from '../../../../../store';
import { SM06Hanlder } from '../handler/HandlerImpl';
import InfoCircle from '../../../../../assets/icons/InfoCircle.svg';

type Props = {
    handler: SM06Hanlder;
};
const SM06UI = ({ handler }: Props) => {
    const isVisible = useSelector((state: RootState) => state.sm06.isVisible);
    const scheduleId = useSelector((state: RootState) => state.sm06.scheduleId);

    return (
        <Modal
            className="sm06_modal"
            style={{ marginLeft: 300, marginTop: 40, marginBottom: 40 }}
            visible={isVisible}
            closable={false}
            maskClosable={false}
            width={'773px'}
            centered={true}
            footer={
                <>
                    <Button
                        size="large"
                        type="primary"
                        style={{ marginLeft: 16, width: 95, letterSpacing: -1.5 }}
                        onClick={() => scheduleId && handler.deleteSchedule(scheduleId)}
                    >
                        はい
                    </Button>
                    <Button
                        size="large"
                        style={{ marginLeft: 16, width: 118 }}
                        onClick={handler.onCancel}
                    >
                        キャンセル
                    </Button>
                </>
            }
        >
            <div className="sm06-header">
                <img src={InfoCircle} alt="" style={{ marginRight: 14 }} />
                削除確認
            </div>
            <div className="body">イベントを削除してもよろしいでしょうか？</div>
        </Modal>
    );
};

export default SM06UI;
