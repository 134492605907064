import { NOTIFICATION_TITLE } from "./../../../../../common/constants/MESSAGE";
import { useDispatch } from "react-redux";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import { UseCaseImpl } from "../../usecase/ServiceImpl";
import { useState } from "react";
import { HistoryApproval } from "../../entity/Entity";
import { resetCR005 } from "../slice/Slice";

export const Handler = (service: UseCaseImpl) => {
  const dispatch = useDispatch();

  const [historyData, setHistoryData] = useState<HistoryApproval[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getProjectCRApprovalProcess = async (params: { versionId: number }) => {
    try {
      setLoading(true);
      let resultsData = await service.getProjectCRApprovalProcess(params);
      const found = resultsData.find((item: any) => item.isSubmitter)
      if (found) {
        const data = resultsData
        const returnFromSplice = data.splice(1, 1, found)[0]
        data[0] = returnFromSplice
        resultsData = data
      }
      setHistoryData(resultsData);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      setLoading(false);
    }
  };

  const onCancel = () => {
    dispatch(resetCR005());
  };

  return {
    historyData,
    loading,
    getProjectCRApprovalProcess,
    onCancel,
  };
};
