// Built-in
import { useState } from "react";

// 3rd party lib
import { useSearchParams } from "react-router-dom";
import { Form } from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

// Source files
import {
  AM001ServiceImpl,
  AttendanceServiceImpl,
} from "./../../usecase/ServiceImpl";
import COMMON from "../../../../../common/constants/COMMON";
import {
  refreshAM001,
  setIsRefreshFullApi,
  setTableColumns,
} from "../slice/Slice";
import {
  calculateCalendarTableHeader,
  checkMissParamsOnURL,
} from "../../../AM008/helper";
import { RangeDateType } from "../../../AM002/entity/Entity";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import { RootState } from "../../../../../store";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../../common/constants/MESSAGE";

const AM001Handler = (
  service: AM001ServiceImpl,
  attendanceService: AttendanceServiceImpl
) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const sortBy = searchParams.get("sortby") || "";
  const sortType = searchParams.get("sorttype") || "";
  const pageDate = searchParams.get("pageDate") || COMMON.DEFAULT_PAGE + "";
  const fromDate =
    searchParams.get("startDate") ||
    moment().startOf("month").format(COMMON.FORMAT_DATE2);
  const toDate =
    searchParams.get("endDate") || moment().format(COMMON.FORMAT_DATE2);

  const [totalMoney, setTotalMoney] = useState<number>(0);
  const [totalPeople, setTotalPeople] = useState<number>(0);
  const [totalWorkingDay, setTotalWorkingDay] = useState<number>(0);

  const [listConstractor, setListConstractor] = useState<any[]>([]);
  const [rangeDate, setRangeDate] = useState<RangeDateType>({
    from: moment().startOf("month"),
    to: moment(),
  });
  const [expandKey, setExpandKey] = useState<any[]>([]);
  const [expandKeyMember, setExpandKeyMember] = useState<any[]>([]);
  const [isInitCollapse, setIsInitCollapse] = useState<boolean>(true);

  const isRefreshFullApi = useSelector(
    (state: RootState) => state.am001.isRefreshFullApi
  );

  const onFromDateChange = (value: any) => {
    setRangeDate({ ...rangeDate, from: value });
  };
  const onToDateChange = (value: any) => {
    setRangeDate({ ...rangeDate, to: value });
  };

  const afterDoubleCheckSuccess = () => {
    dispatch(refreshAM001());
    dispatch(setIsRefreshFullApi(false));
  };

  const isCollapsedMember = (record: any) => {
    return expandKeyMember.some((element) => element === record.key);
  };

  const onChangeCollapseRowMember = (record: any, collapsed: boolean) => {
    const collapsedRow = collapsed
      ? [...expandKeyMember, record.key]
      : expandKeyMember.filter((element) => element !== record.key);

    setExpandKeyMember(collapsedRow);
  };

  const isCollapsed = (record: any) => {
    return expandKey.some((element) => element === record.key);
  };

  const onChangeCollapseRow = (record: any, collapsed: boolean) => {
    const collapsedRow = collapsed
      ? [...expandKey, record.key]
      : expandKey.filter((element) => element !== record.key);

    setExpandKey(collapsedRow);
  };

  // recursive
  const recursion = async (
    param: any,
    array: any,
    results: any[],
    treeLevel: number
  ) => {
    if (array.length === 0) return;
    // STOP CONDITION
    for (let i = 0; i < array.length; i++) {
      results.push({
        ...array[i],
        treeLevel: treeLevel,
      });
      const contractorData =
        await service.getAttendanceSubConstructionListMember({
          ...param,
          contractorConstructionId: array[i].id,
        });

      if (contractorData.results)
        await recursion(param, contractorData.results, results, treeLevel + 1);
    }
  };
  const getAttendanceConstructionTotalCost = async (params: {
    constructionId: number;
    from: string;
    to: string;
    keyword: string;
  }): Promise<any> => {
    try {
      dispatch(setLoading(true));
      const responseResults = await service.getAttendanceConstructionTotalCost(
        params
      );

      setTotalMoney(responseResults.totalLaborCost);
      setTotalWorkingDay(responseResults.totalWorkingDay);
    } catch (error: any) {
      ErrorNotification(error.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const displayEmptyData = () => {
    setTotalMoney(0);
    setTotalPeople(0);
    setTotalWorkingDay(0);
    setExpandKeyMember([]);
    setExpandKey([]);
    setListConstractor([]);
  };

  const getAttendanceSubConstructionListMember = async (params: {
    constructionId: number;
    contractorConstructionId: number;
    from: string; // 2006-01-02
    to: string; // 2006-01-02
  }): Promise<any> => {
    try {
      dispatch(setLoading(true));
      let totalPeopleResult = 0;
      let totalMoneyResult = 0;
      let sumTotalWorkingDay = 0;
      const contractorData =
        await service.getAttendanceSubConstructionListMember({
          constructionId: params.constructionId,
          contractorConstructionId: params.contractorConstructionId,
          from: params.from,
          to: params.to,
        });

      const contractorArray = contractorData?.results ?? [];

      if (contractorArray?.length === 0) return displayEmptyData();

      const flatArray: any[] = [];
      let treeLevel = 1;
      for (let i = 0; i < contractorArray.length; i++) {
        flatArray.push({
          ...contractorArray[i],
          treeLevel: treeLevel,
          parentSubConstructionId: contractorArray[i].id,
        });
        const contractorData2 =
          await service.getAttendanceSubConstructionListMember({
            constructionId: params.constructionId,
            contractorConstructionId: contractorArray[i].id,
            from: params.from,
            to: params.to,
          });
        const param = {
          constructionId: params.constructionId,
          from: params.from, // 2006-01-02
          to: params.to, // 2006-01-02
        };
        await recursion(
          param,
          contractorData2.results,
          flatArray,
          treeLevel + 1
        );
      }

      flatArray.forEach((element: any) => {
        totalMoneyResult += element.totalLaborCost;
        totalPeopleResult += element.numberOfMember;
        sumTotalWorkingDay += element.totalWorkingDay;
      });

      for (let i = 0; i < flatArray.length; i++) {
        for (let j = 0; j < flatArray[i].contractorMembers.length; j++) {
          const attendanceParams = {
            contractorConstructionId: flatArray[i].id,
            constructionId: params.constructionId,
            userId: flatArray[i].contractorMembers[j].id,
            from: params.from,
            to: params.to,
          };
          const attendanceResult =
            await getAttendanceSubConstructionMemberTimeSheet(attendanceParams);
          if (attendanceResult)
            flatArray[i].contractorMembers[j].attendance = attendanceResult;
        }
      }
      if (isInitCollapse) {
        const memberKeys = flatArray[0].contractorMembers.map(
          (element: any) => element.id
        );
        if (memberKeys?.length > 0) setExpandKeyMember([memberKeys[0]]);
        setExpandKey([flatArray[0].id]);
      }

      setListConstractor(flatArray);
      setTotalMoney(totalMoneyResult);
      setTotalPeople(totalPeopleResult);
      setTotalWorkingDay(sumTotalWorkingDay);
    } catch (error: any) {
      displayEmptyData();
      ErrorNotification(error.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      if (!isRefreshFullApi) dispatch(setIsRefreshFullApi(true));
      if (isInitCollapse) setIsInitCollapse(false);
      dispatch(setLoading(false));
    }
  };
  const getAttendanceSubConstructionMemberTimeSheet = async (params: {
    constructionId: number;
    userId: number;
    from: string;
    to: string;
  }) => {
    const { results } =
      await attendanceService.getAttendanceSubConstructionMemberTimeSheet(
        params
      );
    return results;
  };

  const initiateFilter = () => {
    let fromMoment: moment.Moment = moment().startOf("month");
    if (fromDate === null)
      form.setFieldValue("startDate", moment().startOf("month"));
    else {
      form.setFieldValue("startDate", moment(fromDate));
      fromMoment = moment(fromDate);
    }

    form.setFieldValue("endDate", moment(toDate));
    setRangeDate({
      to: moment(toDate),
      from: fromMoment,
    });
  };

  const calculateTableHeader = (
    startDate: string | null,
    endDate: string | null
  ) => {
    const result = calculateCalendarTableHeader(startDate, endDate, pageDate);
    dispatch(setTableColumns(result));
    return result;
  };

  const onNext = () => {
    const pageInt = parseInt(pageDate);
    checkMissParamsOnURL(searchParams, setSearchParams);
    searchParams.set("pageDate", pageInt + 1 + "");
    setSearchParams(searchParams);
  };

  const onPrev = () => {
    const pageInt = parseInt(pageDate);
    checkMissParamsOnURL(searchParams, setSearchParams);
    searchParams.set("pageDate", (pageInt - 1 <= 0 ? 1 : pageInt - 1) + "");
    setSearchParams(searchParams);
  };
  const onChoose = (value: any) => {};
  return {
    form,
    listConstractor,
    rangeDate,
    totalPeople,
    totalMoney,
    expandKey,
    expandKeyMember,
    totalWorkingDay,
    isCollapsed,
    isCollapsedMember,
    getAttendanceSubConstructionListMember,
    initiateFilter,
    onNext,
    onPrev,
    calculateTableHeader,
    onFromDateChange,
    onToDateChange,
    onChoose,
    afterDoubleCheckSuccess,
    setExpandKey,
    setExpandKeyMember,
    onChangeCollapseRow,
    onChangeCollapseRowMember,
    getAttendanceConstructionTotalCost,
  };
};

export default AM001Handler;
