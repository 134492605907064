import { Empty, Table } from "antd";
import helpers from "../../../../../common/helpers/common";
import { DB001Handler } from "../handler/HandlerImpl";
import TooltipText from "../../../../../common/components/tooltip-text/TooltipText";

// import '../../../../../assets/styles/DB/db001.css';

type props = {
  handler: DB001Handler;
  data: any[];
  projectId: number;
};

const TableComponent = ({ data, projectId, handler }: props) => {
  const columns = [
    {
      title: "案件名",
      dataIndex: "projectName",
      key: "projectName",
      render: (record: any, raw: any) => {
        return {
          props: {
            style: {
              background:
                Number(raw.projectId) === Number(projectId)
                  ? "#E7E6FF"
                  : "none",
            },
          },
          children: <TooltipText content={record} placement="topLeft" />,
        };
      },
    },
    {
      title: "契約金額",
      dataIndex: "contractCost",
      key: "contractCost",
      render: (record: any, raw: any) => {
        return {
          props: {
            style: {
              background:
                Number(raw.projectId) === Number(projectId)
                  ? "#E7E6FF"
                  : "none",
            },
          },
          children: (
            <TooltipText
              content={`${helpers.formatCurrency(record)}円`}
              placement="topLeft"
            />
          ),
        };
      },
    },
    {
      title: "完成出来高",
      dataIndex: "billingVolume",
      key: "billingVolume",
      render: (record: any, raw: any) => {
        return {
          props: {
            style: {
              background:
                Number(raw.projectId) === Number(projectId)
                  ? "#E7E6FF"
                  : "none",
            },
          },
          children: (
            <TooltipText
              content={`${helpers.formatCurrency(record)}円`}
              placement="topLeft"
            />
          ),
        };
      },
    },
    {
      title: "完成出来高(%)",
      dataIndex: "billingVolumeRate",
      key: "billingVolumeRate",
      render: (record: any, raw: any) => {
        return {
          props: {
            style: {
              background:
                Number(raw.projectId) === Number(projectId)
                  ? "#E7E6FF"
                  : "none",
            },
          },
          children: <div>{`${helpers.roundFloatNumber(record, 2)}%`}</div>,
        };
      },
    },
    {
      title: "請求残高",
      dataIndex: "billingBalance",
      key: "billingBalance",
      render: (record: any, raw: any) => {
        return {
          props: {
            style: {
              background:
                Number(raw.projectId) === Number(projectId)
                  ? "#E7E6FF"
                  : "none",
            },
          },
          children: (
            <TooltipText
              content={`${helpers.formatCurrency(record)}円`}
              placement="topLeft"
            />
          ),
        };
      },
    },
    {
      title: "請求残高(%)",
      dataIndex: "billingBalanceRate",
      key: "billingBalanceRate",
      render: (record: any, raw: any) => {
        return {
          props: {
            style: {
              background:
                Number(raw.projectId) === Number(projectId)
                  ? "#E7E6FF"
                  : "none",
            },
          },
          children: <div>{`${helpers.roundFloatNumber(record, 2)}%`}</div>,
        };
      },
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      scroll={{ y: 55 * 6 - 12 }}
      pagination={false}
      onRow={(record, rowIndex) => {
        return {
          onClick: (event) => {
            handler.chooseProject(record);
          }, // click row
        };
      }}
      locale={{
        emptyText: (
          <Empty
            style={{ marginTop: 130 }}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="データなし"
          />
        ),
      }}
    />
  );
};

export default TableComponent;
