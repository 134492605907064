import React from 'react';
import IconDown from '../../../../../assets/icons/icon-down-small.svg';
import NoDataTableComponent from '../../../../../common/components/no-data/NoDataTable';
import { Empty } from 'antd';
import helpers from '../../../../../common/helpers/common';
import { OffsetCost } from '../../entity/Entity';
import { TAX_CODE } from '../../../../../common/constants/COMMON';
import EyeViewDetailSvg from '../../../../../assets/icons/eye-view-detail.svg';
import ProjectRowComponent from './ProjectRow';
import TooltipText from '../../../../../common/components/tooltip-text/TooltipText';
import { getOffsetSummaryValue } from '../../../CM0027/helper';

type props = {
    data: any;
    index: number;
    setCompanyExpandKeys: (key: any) => void;
    checkCompanyKeyExpand: (key: any) => boolean;
    setProjectExpandKeys: (key: any) => void;
    checkProjectKeyExpand: (key: any) => boolean;
    openCM0025_2: (value: any) => void;
    openCM0025_1: (value: any) => void;
};

const CompanyRowComponent = ({
    data,
    index,
    setCompanyExpandKeys,
    checkCompanyKeyExpand,
    setProjectExpandKeys,
    checkProjectKeyExpand,
    openCM0025_2,
    openCM0025_1,
}: props) => {
    const getOffsetComponent = (record: any, taxFree?: boolean) => {
        const offsetCost = record.offsetCost?.find(
            (element: OffsetCost) =>
                element.category.code === (taxFree ? TAX_CODE.TAX_FREE : TAX_CODE.TAX),
        );

        return (
            <main className="center-item">
                <div className="">{`${helpers.formatNumberMoney(offsetCost?.value ?? 0)}円`}</div>
            </main>
        );
    };

    const getOffsetSummaryComponent = (record: any, taxFree?: boolean) => {
        const offsetCostSummary = record.offsetCostSummary?.find(
            (element: OffsetCost) =>
                element.category.code === (taxFree ? TAX_CODE.TAX_FREE : TAX_CODE.TAX),
        );

        return (
            <main className="center-item">
                <div className="">{`${helpers.formatNumberMoney(
                    offsetCostSummary?.value ?? 0,
                )}円`}</div>
                <div
                    style={{ marginLeft: 10 }}
                    className="button-style"
                    onClick={(event) => {
                        event.stopPropagation();
                        openCM0025_2({
                            ...record,
                            categoryId: offsetCostSummary?.category?.id ?? 0,
                        });
                    }}
                >
                    <img alt="" src={EyeViewDetailSvg} />
                </div>
            </main>
        );
    };
    return (
        <>
            <tr className="row-level-0 button-style" onClick={() => setCompanyExpandKeys(data.id)}>
                {/*+ No
                STT đánh từ bé đến lớn*/}
                <td colSpan={2}>{index + 1}</td>

                {/*+ 業者名 Tên công ty hợp tác
                Hiển thị tên công ty hợp tác thuê ngoài ở giấy dự toán CR001_thông tin tiền mới nhất và đã được duyệt */}
                <td>
                    <div style={{ width: 160 }}>
                        <TooltipText content={data?.company?.name ?? ''} />
                    </div>
                </td>

                {/*+ 相殺金額合計
                Công thức: 業者別相殺金額(課税 & 非課税) + 案件別相殺金額(課税 & 非課税)*/}
                <td>{`${helpers.formatNumberMoney(data.totalOffset)}円`}</td>

                {/*+ 業者別相殺金額
                Gồm 2 cột con: 課税 & 非課税
                Hiển thị số tiền từ popup CM017_1 theo type 課税 hoặc 非課税
                Click icon mắt của cột 課税 sẽ mở ra popup CM0017_1, popup ẩn button edit & chỉ hiển thị các mục có giá trị 課税 ở trường 消費税区分
                Click icon mắt của cột 非課税 sẽ mở ra popup CM0017_1, popup ẩn button edit & chỉ hiển thị các mục có giá trị 非課税 ở trường 消費税区分
                Chi tiết popup CM017_1 xem mục #10, sheet Thống kê tiền trả cho công ty hợp tác_CM0017*/}
                <td colSpan={2}>{getOffsetSummaryComponent(data)}</td>
                <td colSpan={2}>{getOffsetSummaryComponent(data, true)}</td>

                {/*+ 計上金額
                Cộng tổng các record của dự án ở mục #9.4*/}
                <td>{`${helpers.formatNumberMoney(data.actualCost)}円`}</td>

                {/*+ 案件別相殺金額
                Gồm 2 cột con: 課税 & 非課税
                Count tổng các dự án ở mục #9.4
                */}
                <td>{getOffsetComponent(data)}</td>
                <td>{getOffsetComponent(data, true)}</td>

                {/*+ 支払金額(税抜)
                支払金額（税抜）＝計上金額ー相殺金額合計*/}
                <td>{`${helpers.formatNumberMoney(data.paymentAmountExcludingTax)}円`}</td>

                {/*消費税
                Công thức: (Tổng thuế của các dự án ở #9.4 + 業者別相殺金額(課税)x% thuế setting*/}
                <td>{`${helpers.formatNumberMoney(data.consumptionTax)}円`}</td>

                {/*+ 支払金額(税込)
                Công thức: 支払金額(税抜) + 消費税*/}
                <td>{`${helpers.formatNumberMoney(data.paymentAmountIncludingTax)}円`}</td>

                {/*
                + Icon expand/collapse
                DefaultL Mở nội dung của công ty đầu tiên
                Click icon trạng thái collapse mở ra mục #9.4~#9.6
                Click icon trạng thái expand sẽ đónh mục #9.4~#9.6"
                */}
                <td>
                    <img
                        alt=""
                        src={IconDown}
                        className={`cm024-icon ${
                            !checkCompanyKeyExpand(data.id) ? '' : 'cm024-icon-active'
                        }`}
                    />
                </td>
            </tr>
            {checkCompanyKeyExpand(data.id) ? (
                <ProjectRowComponent
                    openCM0025_1={openCM0025_1}
                    checkProjectKeyExpand={checkProjectKeyExpand}
                    setProjectExpandKeys={setProjectExpandKeys}
                    projectList={data.projectList}
                />
            ) : (
                <></>
            )}
            <tr style={{ height: 4 }}></tr>
        </>
    );
};

export default CompanyRowComponent;
