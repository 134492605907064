import React, { useEffect } from "react";
import { HandlerImpl } from "../handler/HandlerImpl";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { PLAN_TYPE } from "../../../../common/constants/COMMON";

interface Props {
  handler: HandlerImpl;
}

export const MainUI = ({ handler }: Props) => {
  const isRefreshDoubleCheck = useSelector(
    (state: RootState) => state.auth.isRefreshDoubleCheck
  );
  const isRefreshReWorkflow = useSelector(
    (state: RootState) => state.auth.isRefreshReWorkflow
  );
  const isRefreshUserInWorkspace = useSelector(
    (state: RootState) => state.auth.isRefreshUserInWorkspace
  );
  const isRefreshUserIsProjectManager = useSelector(
    (state: RootState) => state.auth.isRefreshUserIsProjectManager
  );
  const isRefreshWorkFlowUserIsInWorkFlows = useSelector(
    (state: RootState) => state.auth.isRefreshWorkFlowUserIsInWorkFlows
  );
  const isRefreshIsVerifiedCompany = useSelector(
    (state: RootState) => state.auth.isRefreshIsVerifiedCompany
  );
  const subscription = useSelector(
    (state: RootState) => state.auth?.authData?.order?.subscription
  );

  const order = useSelector(
    (state: RootState) => state.auth?.authData?.order?.code
  ); //action.payload?.order?.code;
  const isOrderFree = order === PLAN_TYPE.PLAN_FREE;
  const isOrderBasic = order === PLAN_TYPE.PLAN_BASIC;
  useEffect(() => {
    (async () => {
      if (!isOrderFree) await handler.getUserIsDoubleCheck();
    })();
  }, [isRefreshDoubleCheck]);

  useEffect(() => {
    (async () => {
      if (!isOrderFree) await handler.getUserIsReWorkflow();
    })();
  }, [isRefreshReWorkflow]);

  useEffect(() => {
    (async () => {
      await handler.getProjectCheckUserInWorkspace();
    })();
  }, [isRefreshUserInWorkspace]);

  useEffect(() => {
    (async () => {
      await handler.getWorkFlowUserIsInWorkFlows();
    })();
  }, [isRefreshWorkFlowUserIsInWorkFlows]);

  useEffect(() => {
    (async () => {
      await handler.getUserIsProjectManager(!!subscription);
    })();
  }, [isRefreshUserIsProjectManager]);

  useEffect(() => {
    (async () => {
      await handler.getIsVerifiedCompany();
    })();
  }, [isRefreshIsVerifiedCompany]);

  return <Outlet />;
};
