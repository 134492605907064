import { Form } from 'antd';
import { ORD001UseCase } from '../../usecase/ServiceImpl';
import { useNavigate, useSearchParams } from 'react-router-dom';
import COMMON from '../../../../../common/constants/COMMON';
import {
    FROM_PARAM,
    KEYWORD_PARAM,
    PAGE_PARAM,
    PLAN,
    RANGE_EXPIRED_DATE,
    SEARCH_KEY,
    SIZE_PARAM,
    SORT_BY,
    SORT_TYPE,
    STATUS,
    SUBSCRIPTION,
    TEXT_ALL_JP,
    TO_PARAM,
} from '../../contants';
import { useState } from 'react';
import ConfirmModal from '../../../../../common/components/modal/ConfirmModal';
import MESSAGE, {
    LABEL_MESSAGE,
    NOTIFICATION_TITLE,
} from '../../../../../common/constants/MESSAGE';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../../../../common/slice/CommonSlice';
import { DataType } from '../../entity/Entity';
import { converDataTable } from '../../helpers';
import helpers from '../../../../../common/helpers/common';
import moment from 'moment';
import { createORD002, updateORD002 } from '../../../ORD002_1/presenter/slice/Slice';
import SuccessNotification from '../../../../../common/components/notification/SuccessNotification';
import ErrorNotification from '../../../../../common/components/notification/ErrorNotification';
import {
    setDetailPlanSetting,
    setIsRefreshORD001,
    setListPlanOrder,
    setListStatusOrder,
    setSelectedRowsKey,
} from '../slice/Slice';
import { RootState } from '../../../../../store';

const ORD001Handler = (service: ORD001UseCase) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const selectedRowsKey = useSelector((state: RootState) => state.ord001.selectedRowsKey);

    const page = Number(searchParams.get(PAGE_PARAM)) || COMMON.DEFAULT_PAGE;
    const size = Number(searchParams.get(SIZE_PARAM)) || COMMON.DEFAULT_SIZE;
    const keyword = searchParams.get(COMMON.PARAM_LIST.KEYWORD) || '';
    const status = searchParams.get(STATUS) || TEXT_ALL_JP;
    const plan = searchParams.get(PLAN) || TEXT_ALL_JP;
    const subscription = searchParams.get(SUBSCRIPTION) || TEXT_ALL_JP;
    const sortType = searchParams.get(SORT_TYPE);
    const sortBy = searchParams.get(SORT_BY);
    const rangeExpiredDate = searchParams.get(RANGE_EXPIRED_DATE) || TEXT_ALL_JP;

    const [dataTable, setDataTable] = useState<DataType[]>([]);
    const [chooseStatus, setChooseStatus] = useState<string>(status);
    const [choosePlan, setChoosePlan] = useState<string>(plan);
    const [chooseSubscription, setChooseSubscription] = useState<string>(subscription);
    const [chooseRangeExpiredDate, setChooseRangeExpiredDate] = useState<string>(rangeExpiredDate);
    const [total, setTotal] = useState<number>(0);
    const [expiredDate, setExpiredDate] = useState<number>(0);

    const dataSelectExpiredDate = [
        {
            code: 'equalX',
            name: `=${expiredDate} 日`,
            value: `=${expiredDate}`,
        },
        {
            code: 'biggerX',
            name: `>${expiredDate} 日`,
            value: `>${expiredDate}`,
        },
        {
            code: 'bigger0_lessX',
            name: `>0 & <${expiredDate} 日`,
            value: `>0 & <${expiredDate}`,
        },
    ];

    /*
        Func init default value
            + text (keyword search)
            + option chosen
    */
    const init = () => {
        const text = searchParams.get(KEYWORD_PARAM) ?? '';
        form.setFieldValue(SEARCH_KEY, text);
        // TODO: get option from param
    };

    /*
        Search by keyword affect for field:
        + 会社名
        + ID
        + メールアドレス
        + アドミン名

        duration 1000ms 
    */

    var myVar: any;
    const onSearch = () => {
        if (myVar) clearTimeout(myVar);
        myVar = setTimeout(function () {
            const searchText = form.getFieldValue(SEARCH_KEY);
            form.setFieldValue(SEARCH_KEY, searchText.trim());
            searchParams.set(KEYWORD_PARAM, searchText.trim());
            searchParams.set(PAGE_PARAM, COMMON.DEFAULT_PAGE + '');
            setSearchParams(searchParams);
            dispatch(setSelectedRowsKey([]));
        }, 1000);
    };

    const resetOption = () => {
        searchParams.delete(PLAN);
        searchParams.delete(STATUS);
        searchParams.delete(SUBSCRIPTION);
        searchParams.delete(RANGE_EXPIRED_DATE);
        searchParams.delete(FROM_PARAM);
        searchParams.delete(TO_PARAM);
        searchParams.set(COMMON.PARAM_LIST.PAGE, COMMON.DEFAULT_PAGE.toString());
        setChoosePlan(TEXT_ALL_JP);
        setChooseStatus(TEXT_ALL_JP);
        setChooseSubscription(TEXT_ALL_JP);
        setChooseRangeExpiredDate(TEXT_ALL_JP);
        setSearchParams(searchParams);
        dispatch(setSelectedRowsKey([]))
    };

    /*
        Action filter option
            --> Make http request with filter
    */
    const fitlerWithOption = () => {
        searchParams.set(PLAN, choosePlan);
        searchParams.set(STATUS, chooseStatus);
        searchParams.set(SUBSCRIPTION, chooseSubscription);
        searchParams.set(PAGE_PARAM, COMMON.DEFAULT_PAGE.toString());
        searchParams.set(RANGE_EXPIRED_DATE, chooseRangeExpiredDate);
        let from;
        let to;
        switch (chooseRangeExpiredDate) {
            case `=${expiredDate}`:
                from = helpers.getFilterTime(moment().add(expiredDate, 'days'));
                to = helpers.getFilterTime(moment().add(expiredDate, 'days'));
                searchParams.set(FROM_PARAM, from);
                searchParams.set(TO_PARAM, to);
                break;
            case `>${expiredDate}`:
                from = helpers.getFilterTime(moment().add(expiredDate + 1, 'days'));
                searchParams.set(FROM_PARAM, from);
                searchParams.delete(TO_PARAM);
                break;
            case `>0 & <${expiredDate}`:
                from = helpers.getFilterTime(moment().add(1, 'days'));
                to = helpers.getFilterTime(moment().add(expiredDate - 1, 'days'));
                searchParams.set(FROM_PARAM, from);
                searchParams.set(TO_PARAM, to);
                break;
        }

        if (chooseStatus == TEXT_ALL_JP) searchParams.delete(STATUS);
        if (choosePlan == TEXT_ALL_JP) searchParams.delete(PLAN);
        if (chooseSubscription == TEXT_ALL_JP) searchParams.delete(SUBSCRIPTION);
        if (chooseRangeExpiredDate == TEXT_ALL_JP) {
            searchParams.delete(FROM_PARAM);
            searchParams.delete(TO_PARAM);
        }
        dispatch(setSelectedRowsKey([]))
        setSearchParams(searchParams);
    };

    /*
        Confirm delete one
    */
    const deleteOne = (value: any) => {
        ConfirmModal({
            onOk: async () => {
                dispatch(setLoading(true));
                try {
                    const response = await service.deleteOrder({
                        listId: [value],
                    });
                    SuccessNotification(response.data?.message ?? NOTIFICATION_TITLE.SUCCESS);
                } catch (error: any) {
                    ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
                } finally {
                    dispatch(setLoading(false));
                    dispatch(setIsRefreshORD001());
                }
            },
            className: 'confirm__modal',
            title: MESSAGE.MESSAGE_MODAL_DELETE_TITLE,
            description: MESSAGE.MESSAGE_DELETE_MULTI_ORD001,
            extraDescription: MESSAGE.MESSAGE_DESCRIPTION2_DELETE_ORD001,
            thirdDescription: MESSAGE.MESSAGE_DESCRIPTION3_DELETE_ORD001,
            canceText: LABEL_MESSAGE.CANCEL_MODAL,
            okText: LABEL_MESSAGE.OK_DELETE,
            isCenterWithoutMenu: true,
        });
    };

    /*
        Confirm delete multiple
    */
    const deleteMultiple = () => {
        ConfirmModal({
            onOk: async () => {
                dispatch(setLoading(true));
                try {
                    const response = await service.deleteOrder({
                        listId: selectedRowsKey,
                    });
                    SuccessNotification(response.data?.message ?? NOTIFICATION_TITLE.SUCCESS);
                } catch (error: any) {
                    ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
                } finally {
                    dispatch(setLoading(false));
                    dispatch(setIsRefreshORD001());
                    dispatch(setSelectedRowsKey([]))
                }
            },
            className: 'confirm__modal',
            title: MESSAGE.MESSAGE_MODAL_DELETE_TITLE,
            description: MESSAGE.MESSAGE_DELETE_MULTI_ORD001,
            extraDescription: MESSAGE.MESSAGE_DESCRIPTION2_DELETE_ORD001,
            thirdDescription: MESSAGE.MESSAGE_DESCRIPTION3_DELETE_ORD001,
            canceText: LABEL_MESSAGE.CANCEL_MODAL,
            okText: LABEL_MESSAGE.OK_DELETE,
            isCenterWithoutMenu: true,
        });
    };

    /*
        Get selected key from table
    */
    const getSelectedKey = (values: React.Key[]) => {
        dispatch(setSelectedRowsKey(values))
    };

    /*
        Dispacth show modal create order ORD002
            + dispatch boolean value --> true
    */
    const showORD002 = () => {
        // dispatch(setVisible(true));
    };

    const createNewOrder = () => {
        dispatch(createORD002());
    };

    const updateOrder = (value: any) => {
        dispatch(updateORD002(value));
    };

    /*
        Redirect to ORD006
            + pass params
    */
    const redirectORD006 = (orderId: React.Key) => {
        navigate(`/app-ord006/${orderId}`);
    };

    const funcCheckSortOrder = (columnName: string): 'ASC' | 'DESC' | undefined => {
        if (columnName === sortBy) {
            return !sortType ? undefined : sortType === 'ASC' ? 'ASC' : 'DESC';
        }
    };
    const funcSortTable = (column: string, sortOrder: string) => {
        searchParams.set(SORT_BY, column);
        searchParams.set(SORT_TYPE, sortOrder);
        setSearchParams(searchParams);
        dispatch(setSelectedRowsKey([]))
    };

    const getListOrder = async (params: {
        page: number;
        size: number;
        planId?: number;
        statusId?: number;
        subcription?: string;
        from?: string;
        to?: string;
        sortBy?: string;
        sortType?: string;
    }) => {
        try {
            dispatch(setLoading(true));
            const response: any = await service.getListOrder(params);

            if (params?.page > 1 && response.results?.length === 0) {
                searchParams.set(PAGE_PARAM, `${params.page - 1}`);
                setSearchParams(searchParams);
                return;
            }

            setDataTable(converDataTable(response.results, page, size));
            setTotal(response.pagination.count);
        } finally {
            dispatch(setLoading(false));
        }
    };

    const getListPlan = async () => {
        try {
            dispatch(setLoading(true));
            const response: any = await service.getListPlan();
            dispatch(setListPlanOrder(response.results ?? []));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const getListStatusOrder = async () => {
        try {
            dispatch(setLoading(true));
            const response: any = await service.getListStatusOrder();
            dispatch(setListStatusOrder(response.results ?? []));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const getDetailPlanSetting = async () => {
        try {
            dispatch(setLoading(true));
            const response: any = await service.getDetailPlanSetting();
            setExpiredDate(response.results?.basicPlan?.dueDate);
            dispatch(setDetailPlanSetting(response.results));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const onChangeStatus = (value: string) => {
        setChooseStatus(value);
    };

    const onChangePlan = (value: string) => {
        setChoosePlan(value);
    };

    const onChangeSubscription = (value: string) => {
        setChooseSubscription(value);
    };

    const onChangeRangeExpiredDate = (value: string) => {
        setChooseRangeExpiredDate(value);
    };

    const onPageChange = (value: number) => {
        searchParams.set(PAGE_PARAM, `${value}`);
        setSearchParams(searchParams);
        dispatch(setSelectedRowsKey([]))
    };

    const onSizeChange = (value: number) => {
        searchParams.set(SIZE_PARAM, `${value}`);
        searchParams.set(PAGE_PARAM, COMMON.DEFAULT_PAGE.toString());
        setSearchParams(searchParams);
        dispatch(setSelectedRowsKey([]))
    };

    const checkDisableEmail = (email: string) => {
        const user = helpers.getObjectFromLocalStorage('user');

        if (user?.company?.email === email) return true;
        return false;
    };

    return {
        form,
        selectedRowsKey,
        dataTable,
        chooseStatus,
        choosePlan,
        chooseSubscription,
        total,
        page,
        size,
        expiredDate,
        dataSelectExpiredDate,
        chooseRangeExpiredDate,

        init,
        onSearch,
        resetOption,
        fitlerWithOption,
        deleteOne,
        deleteMultiple,
        getSelectedKey,
        showORD002,
        redirectORD006,
        funcCheckSortOrder,
        funcSortTable,
        getListOrder,
        getListPlan,
        getListStatusOrder,
        onChangeStatus,
        onChangePlan,
        onChangeSubscription,
        onPageChange,
        onSizeChange,
        getDetailPlanSetting,
        onChangeRangeExpiredDate,
        createNewOrder,
        updateOrder,
        checkDisableEmail,
    };
};

export default ORD001Handler;
