import CM0025_2Api from './infrastructure/api/Api';
import CM0025_2Handler from './presenter/handler/Handler';
import CM0025_2UI from './presenter/ui/UI';
import CM0025_2Service from './usecase/Service';

const CM0025_2Component = () => {
    const cm0025_2Api = CM0025_2Api();
    const cm0025_2Service = CM0025_2Service(cm0025_2Api);
    const cm0025_2Handler = CM0025_2Handler(cm0025_2Service);
    return <CM0025_2UI handler={cm0025_2Handler} />;
};

export default CM0025_2Component;
