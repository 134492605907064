import OM007Api from "../OM007/infrastructure/api/Api";
import OM007Handler from "../OM007/presenter/handler/Handler";
import OM007Service from "../OM007/usecase/Service";
import OM008Api from "./infrastructure/api/Api"
import OM008Handler from "./presenter/handler/Handler";
import OM008UI from "./presenter/ui/UI";
import OM008Service from "./usecase/Service";


const OM008Component = () => {
    const om008Api = OM008Api();
    const om007Api = OM007Api()

    const om008Service = OM008Service(om008Api)
    const om007Service = OM007Service(om007Api)

    const om008Handler = OM008Handler(om008Service)
    const om007Handler = OM007Handler(om007Service)

    return <OM008UI handler008={om008Handler} handler007={om007Handler} />
}

export default OM008Component