import { Pagination } from "antd";
import "../../../assets/styles/components/pagination.css";
import LeftIcon from "../../../assets/icons/left.svg";
import RightIcon from "../../../assets/icons/right.svg";
import type { PaginationProps } from "antd";
import { memo } from "react";

type Props = {
    total: number;
    page: number;
    size: number;
    disabled?: boolean;
    sizeLabelPage?: string;
    sizeLabelSize?: string;
    rangeLabel?: string;
    setPage: (value: number) => void;
}

const MiniPaginationComponent = ({
    total,
    page,
    size,
    disabled,
    setPage,
}: Props) => {
    const itemRender: PaginationProps["itemRender"] = (
        value,
        type,
        originalElement
    ) => {
        if (type === "prev") return <div className="min-pre-next center-item" >
            <img style={{ width: 8, height: 8 }} alt="" src={LeftIcon} />
        </div>;
        if (type === "next") return <div className="min-pre-next center-item" > <img alt="" style={{ width: 8, height: 8, }} src={RightIcon} /></div>;
        return originalElement;
    };

    return < Pagination
        className="mini-page-style"
        itemRender={itemRender}
        disabled={disabled ?? false}
        showSizeChanger={false}
        onChange={setPage}
        current={page}
        pageSize={size}
        total={total}
        showLessItems={false}
        showQuickJumper={false}
    />

};
export default memo(MiniPaginationComponent);
