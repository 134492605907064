import React from 'react';
import { Button, Modal, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { hideH3Modal } from './H3ModalSlice';
import type { ColumnsType } from 'antd/es/table';
import { ROWS, convertToDisplayTable } from './helpers';
import helpers from '../../helpers/common';

import XIconModal from '../../../assets/icons/x-icon-modal.svg';
import SwooshIcon from '../../../assets/icons/swoosh-purple.svg';
import '../../../assets/styles/components/h3-modal.css';
import { COLUMN_WIDTH_H1_MODAL } from '../modal-H1/H1Modal';

const H3Modal = () => {
    const dispatch = useDispatch();
    const isOpenH3Modal = useSelector((state: RootState) => state.h3Modal.isOpenH3);

    const handleCancel = () => {
        dispatch(hideH3Modal());
    };

    const getDisplayContent = (value: boolean) => {
        return value ? <img src={SwooshIcon} alt="" /> : '-';
    };

    const columns: ColumnsType<any> = [
        {
            title: '機能',
            key: '機能',
            width: COLUMN_WIDTH_H1_MODAL[0],
            align: 'center',
            render: (record) => {
                return (
                    <span className={`h1-row-cell white-space-nowrap header-style-row`}>
                        {record.name}
                    </span>
                );
            },
        },
        {
            title: (
                <div>
                    <div>案件担当者</div>
                    <div className="note-header-h3">(案件範囲のみ)</div>
                </div>
            ),
            key: '案件担当者',
            width: COLUMN_WIDTH_H1_MODAL[1],
            align: 'center',
            render: (record) => getDisplayContent(record.allowProjectManager),
        },
        {
            title: '副案件担当者',
            key: '副案件担当者',
            width: COLUMN_WIDTH_H1_MODAL[1],
            align: 'center',
            render: (record) => getDisplayContent(record.allowDeputyProjectManager),
        },
        {
            title: '案件作成者',
            key: '案件作成者',
            width: COLUMN_WIDTH_H1_MODAL[1],
            align: 'center',
            render: (record) => getDisplayContent(record.allowItemCreator),
        },
        {
            title: '案件メンバー',
            key: '案件メンバー',
            width: COLUMN_WIDTH_H1_MODAL[1],
            align: 'center',
            render: (record) => getDisplayContent(record.allowProjectMember),
        },
        {
            title: 'アドミン',
            key: 'アドミン',
            width: COLUMN_WIDTH_H1_MODAL[1],
            align: 'center',
            render: (record) => getDisplayContent(record.isAdmin),
        },
    ];

    return (
        <Modal
            className="group-detail-modal h1-modal"
            style={{ marginLeft: 300, marginTop: 40, marginBottom: 40 }}
            visible={isOpenH3Modal}
            closable={false}
            maskClosable={false}
            width={'56.2%'}
            centered={true}
            footer={
                <Button size="large" style={{ marginLeft: 16, width: 118 }} onClick={handleCancel}>
                    キャンセル
                </Button>
            }
        >
            <div className="w-100-percent header-modal-containter" style={{ height: 50 }}>
                <div className="header-modal-title-om004 ">固定権限一覧</div>
                <div style={{ cursor: 'pointer' }} onClick={handleCancel}>
                    <img alt="" src={XIconModal} style={{ height: 18, width: 18 }} />
                </div>
            </div>
            <div style={{ marginTop: 24, marginBottom: 24 }}>
                <Table
                    bordered={true}
                    dataSource={helpers.addKeyIndexToDataSource(convertToDisplayTable(ROWS))}
                    title={() => <main className="cm005-title-header">収支管理</main>}
                    columns={columns}
                    id="main-table"
                    rowClassName={'header-style-row-h2'}
                    pagination={false}
                />
            </div>
        </Modal>
    );
};

export default H3Modal;
