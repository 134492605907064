import { useState } from "react";
// Redux
import { useDispatch } from "react-redux";

// Services

import { setVisibleDB003 } from "../slice/Slice";
import { DB003UseCase } from "../../usecase/ServiceImpl";
import { DataTableType } from "../../entity/Entity";

export const TYPE_ITEM = {
  contractAmount: {
    key: "contractAmount",
    value: "契約金額",
  },
  billingVolume: {
    key: "billingVolume",
    value: "完成出来高",
  },
  billingBalance: {
    key: "billingBalance",
    value: "請求残高",
  },
  billingVolumeRate: {
    key: "billingVolumeRate",
    value: "完成出来高(％)",
  },
  billingBalanceRate: {
    key: "billingBalanceRate",
    value: "請求残高（％）",
  },
};

const DB003Handler = (service: DB003UseCase) => {
  const dispatch = useDispatch();

  const [dataTable, setDataTable] = useState<DataTableType[]>([]);
  const [dataInformation, setDataInformation] = useState({});
  const [loading, setLoading] = useState<boolean>(false);

  const cancelModalDB003 = () => {
    dispatch(setVisibleDB003(false));
  };

  const getTurnoverAmount = async (params: {
    projectId: number;
    from: string;
    to: string;
  }) => {
    try {
      setLoading(true);
      const response = await service.getTurnoverAmount(params);
      setDataInformation({
        projectName: response?.results?.projectName,
        constructionFromDate: response?.results?.constructionFromDate,
      });
      setDataTable(converDataTable(response?.results));
    } finally {
      setLoading(false);
    }
  };

  const converDataTable = (data: any) => {
    let results: any = [];
    delete data.projectName;
    delete data.constructionFromDate;
    Object.entries(data).forEach(([key, value]) => {
      results.push({
        key: key,
        item:
          key === TYPE_ITEM.contractAmount.key
            ? TYPE_ITEM.contractAmount.value
            : key === TYPE_ITEM.billingVolume.key
            ? TYPE_ITEM.billingVolume.value
            : key === TYPE_ITEM.billingBalance.key
            ? TYPE_ITEM.billingBalance.value
            : key === TYPE_ITEM.billingVolumeRate.key
            ? TYPE_ITEM.billingVolumeRate.value
            : key === TYPE_ITEM.billingBalanceRate.key
            ? TYPE_ITEM.billingBalanceRate.value
            : "",
        amountMoney: value ?? 0,
      });
    });
    return results;
  };

  return {
    loading,
    dataTable,
    dataInformation,
    cancelModalDB003,
    getTurnoverAmount,
  };
};
export default DB003Handler;
