import { ReceivedMoneyOffset } from '../entity/Entity';
import { CM0012_2Api } from './ServiceImpl';

const CM0012_2Service = (cm0012_2: CM0012_2Api) => {
    const getListReceivedMoneyOffset = async (params: any): Promise<any> => {
        try {
            return await cm0012_2.getListReceivedMoneyOffset(params);
        } catch (error) {
            throw error;
        }
    };

    const getSubCategory = async (): Promise<any> => {
        try {
            return await cm0012_2.getSubCategory();
        } catch (error) {
            throw error;
        }
    };

    const createReceivedMoneyOffset = async (data: any): Promise<any> => {
        try {
            return await cm0012_2.createReceivedMoneyOffset(data);
        } catch (error) {
            throw error;
        }
    };

    const deleteReceivedMoneyOffset = async (data: any): Promise<any> => {
        try {
            return await cm0012_2.deleteReceivedMoneyOffset(data);
        } catch (error) {
            throw error;
        }
    };

    const editReceivedMoneyOffset = async (data: any): Promise<any> => {
        try {
            return await cm0012_2.editReceivedMoneyOffset(data);
        } catch (error) {
            throw error;
        }
    };

    return {
        getListReceivedMoneyOffset,
        getSubCategory,
        createReceivedMoneyOffset,
        deleteReceivedMoneyOffset,
        editReceivedMoneyOffset,
    };
};

export default CM0012_2Service;
