import helpers from '../../../../../common/helpers/common';
import AddRowButtonIconSvg from '../../../../../assets/icons/add-row-button.svg';
import { InputNumberComponent } from './InputNumber';
import { PlanPerson, PlanUser } from '../../entity/Entity';
import MinusSvg from '../../../../../assets/icons/Minus_cm0063.svg';
import MESSAGE from '../../../../../common/constants/MESSAGE';

type props = {
    isEditing: boolean;
    planUsers: PlanUser[];
    planUserAddition: string[];
    planPerson?: PlanPerson[];
    addPlanUser: () => void;
    removePlanUser: (value: string) => void;
};

export const PlanPersonComponent = ({
    isEditing,
    planUsers,
    planUserAddition,
    planPerson,
    addPlanUser,
    removePlanUser,
}: props) => {
    return (
        <>
            <section className="ord007-header-style" style={{ marginTop: 8 }}>
                ベーシックプラン　発行ユーザー数
            </section>
            <section style={{ width: '100%' }}>
                <header
                    className="header-plan-user"
                    style={{
                        width: '100%',
                        display: 'flex',
                        backgroundColor: '#FAFAFA',
                    }}
                >
                    <div className="center-item ord007-cell-container ord007-border-left ten-percent-width">
                        No
                    </div>
                    <div className="center-item ord007-cell-container forty-percent-width">
                        ユーザー数
                    </div>
                    <div className="center-item ord007-cell-container half-width">基本料金</div>
                </header>
                {planPerson?.map((element: PlanPerson, index: number) => (
                    <section key={element.id} style={{ width: '100%', display: 'flex' }}>
                        <div className="center-item ord007-cell-container ord007-border-left ten-percent-width">
                            {index + 1}
                        </div>
                        <div className="center-item ord007-cell-container forty-percent-width">{`${element?.userNumber ? element.userNumber + 'ID' : ''
                            }`}</div>
                        <div className="center-item ord007-cell-container half-width">
                            {isEditing ? (
                                <>
                                    <InputNumberComponent
                                        name={[`existedPlanUsers${element.id}`, `cost`]}
                                        max={99999}
                                        isRequired={true}
                                    />
                                    <div style={{ color: '#666666', marginLeft: 8 }}>円</div>
                                    <InputNumberComponent
                                        name={[`existedPlanUsers${element.id}`, `userNumber`]}
                                        hidden={true}
                                    />
                                    <InputNumberComponent
                                        name={[`existedPlanUsers${element.id}`, `id`]}
                                        hidden={true}
                                    />
                                </>
                            ) : (
                                `${element?.cost ? helpers.formatCurrency(element.cost) + '円' : ''
                                }`
                            )}
                        </div>
                    </section>
                ))}
                {isEditing &&
                    planUserAddition.map((element: string, index: number) => (
                        <section key={element} style={{ width: '100%', display: 'flex' }}>
                            <div className="center-item ord007-cell-container ord007-border-left ten-percent-width">
                                {index + 1 + (planPerson?.length ?? 0)}
                            </div>
                            <div className="center-item ord007-cell-container forty-percent-width ">
                                <InputNumberComponent
                                    name={[`newPlanUsers${element}`, `userNumber`]}
                                    max={999}
                                    isRequired={true}
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (value && value !== '') {
                                                    const existedID = planPerson?.map(
                                                        (element) => element.userNumber,
                                                    );
                                                    const addedId: number[] = [];
                                                    planUserAddition.forEach((addPlanUser) => {
                                                        const idNumber = getFieldValue([
                                                            `newPlanUsers${addPlanUser}`,
                                                            `userNumber`,
                                                        ]);
                                                        if (idNumber) addedId.push(idNumber);
                                                    });
                                                    const idArr = [
                                                        ...(existedID ?? []),
                                                        ...addedId,
                                                    ];
                                                    if (new Set(idArr).size !== idArr.length) {
                                                        return Promise.reject(
                                                            new Error(MESSAGE.MESSAGE_010),
                                                        );
                                                    }
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(
                                                    new Error(MESSAGE.MESSAGE_REQUIRED),
                                                );
                                            },
                                        }),
                                    ]}
                                />
                                <div style={{ color: '#666666', marginLeft: 8 }}>ID</div>
                            </div>
                            <div
                                className="center-item ord007-cell-container half-width "
                                style={{ paddingLeft: 35 }}
                            >
                                <InputNumberComponent
                                    name={[`newPlanUsers${element}`, `cost`]}
                                    max={99999}
                                    isRequired={true}
                                />
                                <div style={{ color: '#666666', marginLeft: 8 }}>円</div>
                                <div
                                    className="button-style"
                                    style={{ marginLeft: 8 }}
                                    onClick={() => removePlanUser(element)}
                                >
                                    <img alt="" src={MinusSvg} />
                                </div>
                            </div>
                        </section>
                    ))}
                {isEditing ? (
                    <section
                        className="ord007-cell-container ord007-border-left"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            paddingLeft: 24,
                            backgroundColor: '#FAFAFA',
                        }}
                    >
                        <div
                            className="add-row-style button-style"
                            style={{ display: 'flex', alignItems: 'center', width: 'fit-content' }}
                            onClick={addPlanUser}
                        >
                            <img src={AddRowButtonIconSvg} alt="" style={{ marginRight: 8 }} />
                            <span>項目追加</span>
                        </div>
                    </section>
                ) : null}
            </section>
        </>
    );
};
