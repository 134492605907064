import React from 'react';
import { Button, Modal } from 'antd';
import '../../../assets/styles/components/h4-modal.css';
import InfoSquare3 from '../../../assets/icons/info-square-3.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { hideH4Modal } from './H4ModalSlice';

const H4Modal = () => {
    const dispatch = useDispatch();
    const isOpenH4Modal = useSelector((state: RootState) => state.h4Modal.isOpenH4);

    const handleCancel = () => {
        dispatch(hideH4Modal());
    };

    return (
        <>
            <Modal
                style={{ marginLeft: 300 }}
                width={773}
                closeIcon={<></>}
                centered
                visible={isOpenH4Modal}
                footer={false}
                maskClosable={false}
            >
                <section style={{ display: 'flex', justifyContent: 'start' }}>
                    <div>
                        <img alt="" src={InfoSquare3} style={{ marginRight: 8 }} />
                    </div>
                    <div>
                        <p style={{ margin: 0, padding: 0 }} className="h4-modal-title">
                            ヘルプドキュメント
                        </p>
                        <div className="h4-content " style={{ marginTop: 16, marginBottom: 24 }}>
                            <div>案件範囲：参加している案件に与えられる権限の範囲です。</div>
                            <div>
                                例）工事引合書の閲覧に権限を付与した場合、メンバーが参加している案件の工事引合書が閲覧可能となる。
                            </div>
                            <br />
                            <div>
                                システム全体：案件への参加を問わず、すべての情報に対して与えられる権限の範囲です。
                            </div>
                            <div>
                                例）案件管理の閲覧に権限を付与した場合、すべての案件管理にある情報が閲覧可能となる。
                            </div>
                        </div>
                    </div>
                </section>
                <section style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                    <Button style={{ width: 90 }} size="large" onClick={handleCancel}>
                        <span className="h4-button-style">閉じる</span>
                    </Button>
                </section>
            </Modal>
        </>
    );
};

export default H4Modal;
