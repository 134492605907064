import { FilterType, SubConstructionType } from "./../../entity/Entity";
import { useState } from "react";
import { ConstructionServiceImpl } from "./../../usecase/ServiceImpl";
import { useNavigate, useSearchParams } from "react-router-dom";

const ConstructionHandler = (service: ConstructionServiceImpl) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filter, setFilter] = useState<FilterType>({});
  const [subConstruction, setSubConstruction] = useState<SubConstructionType[]>(
    []
  );

  const onTableChange = (__: any, _: any, sorter: any) => {
    const filterTable: FilterType = {};
    if (sorter.order) {
      filterTable.sorType = sorter.order;
      filterTable.sortBy = sorter.columnKey;
    }
    setFilter(filterTable);
  };

  const getAttendanceSubConstructionListSubConstruction = async (params: {
    contractorConstructionId: number;
    constructionId: number;
    from?: string;
    to?: string;
  }) => {
    const responseData =
      await service.getAttendanceSubConstructionListSubConstruction(params);
    setSubConstruction(responseData);
  };

  const onConstractorChoose = (constractor: any, construction: any) => {
    //rootConstructionId
    const constructionId = constractor.rootConstructionId;
    const contractorConstructionId = constractor.id;
    navigate(
      `/app-am004/${constructionId}/${contractorConstructionId}?title=${
        construction.name + "/" + constractor.description
      }
           &selectedCompanyName=${searchParams.get("selectedCompanyName")}`
    );
  };

  return {
    onTableChange,
    getAttendanceSubConstructionListSubConstruction,
    onConstractorChoose,
    setSubConstruction,
    subConstruction,
    filter,
  };
};

export default ConstructionHandler;
