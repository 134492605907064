import React, { memo, useCallback } from 'react';
import DoublePrevious from '../../../../../assets/icons/double-previous.svg';
import DoubleNext from '../../../../../assets/icons/double-next.svg';
import ExpandedIconSvg from '../../../../../assets/icons/expanded-icon.svg';
import ClosedIconSvg from '../../../../../assets/icons/closed-icon.svg';
import { ConfigProvider, Empty } from 'antd';
import type { TableColumnsType } from 'antd';
import { Table } from 'antd';
import { DateColumn } from '../../entity/Entity';
import AttendanceComponent from '../component/attendance';
import { getHeaderTitle } from '../../../AM008/presenter/ui/Table';
import HeaderMemberRowComponent from './HeaderMemberRow';
import helpers from '../../../../../common/helpers/common';
import { convertToDataSourceList } from '../../helper';
import NoDataTableComponent from '../../../../../common/components/no-data/NoDataTable';
import MemberRowComponent from './MemberRow';

interface props {
  onNext: () => void;
  onPrev: () => void;
  columnsTable: DateColumn[];
  dataSource: any[];
  isPrev: boolean;
  isNext: boolean;
  constructionId: string | undefined;
  expandKey: any[];
  isCollapsed: (record: any) => boolean;
  onChangeCollapseRow: (record: any, collapsed: boolean) => void;
}

const TableComponent = ({
  isPrev,
  isNext,
  columnsTable,
  dataSource,
  constructionId,
  expandKey,
  onNext,
  onPrev,
  isCollapsed,
  onChangeCollapseRow,
}: props) => {
  const getColumns = () => {
    const columns: TableColumnsType<any> = [
      {
        title: <div style={{ paddingLeft: 16 }}>日付</div>,
        key: 'date',
        width: '23%',
        onCell: (_, index) => ({
          colSpan: columnsTable.length + 3,
        }),
        render: (_, record, index) => {
          if (record.key === 'nodata') return <NoDataTableComponent />;
          else return <MemberRowComponent index={index + 1} record={record} />;
        },
      },
    ];
    columns.push({
      title: isPrev ? (
        <div
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            justifyContent: 'flex-end',
          }}
          onClick={onPrev}
        >
          <img alt="" src={DoublePrevious} />
        </div>
      ) : (
        <></>
      ),
      key: 'prev',
      width: 70,
      onCell: (_, index) => ({
        colSpan: 0,
      }),
    });
    for (let i = 0; i < columnsTable.length; i++) {
      columns.push({
        title: getHeaderTitle(columnsTable[i]),
        key: i,
        width: `${77 / columnsTable.length}%`,
        onCell: (_, index) => ({
          colSpan: 0,
        }),
      });
    }
    columns.push({
      title: (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {isNext ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={onNext}
            >
              <img alt="" src={DoubleNext} />
            </div>
          ) : (
            <></>
          )}
        </div>
      ),
      key: 'next',
      width: 29,
      onCell: (_, index) => ({
        colSpan: 0,
      }),
    });
    columns.push({
      title: '',
      key: 'collapsed',
      width: 40,
      render: (record) => {
        return isCollapsed(record) ? (
          <div
            style={{ cursor: 'pointer' }}
            onClick={(e: any) => onChangeCollapseRow(record, false)}
          >
            <img alt="" src={ExpandedIconSvg} />
          </div>
        ) : (
          <div
            style={{ cursor: 'pointer' }}
            onClick={(e: any) => onChangeCollapseRow(record, true)}
          >
            <img alt="" src={ClosedIconSvg} />
          </div>
        );
      },
    });
    return columns;
  };

  return (
    <div className="pm025-table ">
      <Table
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) =>
              isCollapsed(record)
                ? onChangeCollapseRow(record, false)
                : onChangeCollapseRow(record, true), // click row
          };
        }}
        scroll={{
          x: 0,
          y: 500,
        }}
        rowClassName={(record) => {
          if (record.key === 'header') return 'row-member-header button-style';
          else if (record.key === 'nodata') return 'row-no-data no-click button-style';
          else return 'row-company button-style';
        }}
        id="main-table"
        className="am-main-table calendar-header"
        style={{ display: 'flex', flexDirection: 'column-reverse' }}
        columns={getColumns()}
        dataSource={convertToDataSourceList(dataSource)}
        pagination={false}
        expandable={{
          showExpandColumn: false,
          expandedRowKeys: expandKey,
          expandedRowRender: (record: any) => {
            return constructionId && !isNaN(parseInt(constructionId)) ? (
              <AttendanceComponent
                attendance={record.attendance}
                constructionId={parseInt(constructionId)}
                userId={record.id}
              />
            ) : (
              <></>
            );
          },
        }}
        summary={() => {
          return (
            <Table.Summary fixed="top">
              <Table.Summary.Row style={{ background: '#F0F0F0' }}>
                {columnsTable.map((element, index) => (
                  <Table.Summary.Cell index={index + 1} colSpan={0}></Table.Summary.Cell>
                ))}
                <Table.Summary.Cell index={0} colSpan={columnsTable.length + 4}>
                  <HeaderMemberRowComponent />
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
      />
    </div>
  );
};

export default TableComponent;
