import { Params } from '../presenter/type/Presenter';
import { CM0017_3_2Api } from './ServiceImpl';

const CM0017_3_2Service = (cm0017_3_2: CM0017_3_2Api) => {
    const getReSummarySubmittedCompanyOutsourceCostOffsetList = async (
        params: Params,
    ): Promise<any> => {
        try {
            return await cm0017_3_2.getReSummarySubmittedCompanyOutsourceCostOffsetList(params);
        } catch (error) {
            throw error;
        }
    };

    return {
        getReSummarySubmittedCompanyOutsourceCostOffsetList,
    };
};

export default CM0017_3_2Service;
