import { useState, useRef } from "react";
import { Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AM012UseCase } from "../../usecase/ServiceImpl";
import { setLoading, resetAM012, setRefreshAM012 } from "../slice/Slice";
import ConfirmModal from "../../../../../common/components/modal/ConfirmModal";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import COMMON from "../../../../../common/constants/COMMON";
import { AttendanceCategory, OptionType } from "../type/Presenter";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import {
  RequestCreateAttendance,
  RequestUpdateAttendance,
} from "../../entity/Entity";
import moment from "moment";
import { RootState } from "../../../../../store";
import helpers from "../../../../../common/helpers/common";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import { setIsVisibleAM014 } from "../../../AM014/presenter/slice/Slice";
import { setRefreshAM010 } from "../../../AM010/presenter/slice/Slice";
import STATUS, {
  ATTENDANCE_CATEGORY,
} from "../../../../../common/constants/STATUS";

const DEFAULT_STATE = {
  isDirty: false,
  certType: [],
  fileData: undefined,
};

const AM012Handler = (am012Service: AM012UseCase) => {
  // LIB FUNCTION
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  // STATE
  const [isDirty, setIsDirty] = useState(DEFAULT_STATE.isDirty);

  const [pageOption, setPageOption] = useState(COMMON.DEFAULT_PAGE);
  const [sizeOption, setSizeOption] = useState(COMMON.DEFAULT_SIZE);
  const [keywordOption, setKeywordOption] = useState<any>(undefined);

  const [pageOptionConstruction, setPageOptionConstruction] = useState(
    COMMON.DEFAULT_PAGE
  );
  const [sizeOptionConstruction, setSizeOptionConstruction] = useState(
    COMMON.DEFAULT_SIZE
  );
  const [keywordOptionConstruction, setKeywordOptionConstruction] =
    useState<any>(undefined);

  const [attendanceCategory, setAttendanceCategory] = useState<OptionType[]>(
    []
  );
  const [loadingOption, setLoadingOption] = useState(false);
  const [projects, setProjects] = useState<any[]>([]);
  const [constructions, setConstructions] = useState<any[]>([]);
  const [chosenProject, setChosenProject] = useState<boolean>(false);
  const [currentProject, setCurrentProject] = useState<any>();
  const [requestAttendance, setRequestAttendance] = useState<any>({});
  const [requestApprover, setRequestApprover] = useState<any>({});
  const [projectStatus, setProjectStatus] = useState<any>();
  const [projectIdChoosed, setProjectIdChoosed] = useState<number>(0);
  const [pageOptionConstructionChoosedProject, setPageOptionConstructionChoosedProject] = useState(
    COMMON.DEFAULT_PAGE
  );
  const typeModal = useSelector((state: RootState) => state.am012.typeModal);
  const requestId = useSelector((state: RootState) => state.am012.requestId);

  // FUNCTION

  const handleGetProjectFirstOpenModal = async (params: any): Promise<any> => {
    try {
      const data = await getListProject(params);
      setProjects([...data]);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const handleGetConstructionFirstOpenModal = async (
    params: any
  ): Promise<any> => {
    try {
      const data = await getListConstruction(params);
      setConstructions([...data]);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const getListProject = async (params: {
    page: number;
    size: number;
    keyword?: string;
    statusId?: string;
  }): Promise<any> => {
    const paramsGet: any = { ...params };
    const response = await am012Service.getProjects(paramsGet);
    if (response?.results) {
      return response.results;
    }
  };

  const getListConstruction = async (params: {
    page: number;
    size: number;
    keyword?: string;
    projectId?: number;
  }): Promise<any> => {
    try {
      const paramsGet = { ...params, onlyInvolved: true };
      const res = await am012Service.getAttendanceUserConstruction(paramsGet);
      if (res?.results) {
        const data = formatConstructions(res.results);
        return data;
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const getProject = async (params: any, projectId: number): Promise<any> => {
    const res = await handleFetchApiGetAllProject(params, projectId);
    if (res) setProjects([...res]);
  };

  const handleFetchApiGetAllProject = async (
    params: any,
    projectId: number
  ): Promise<any> => {
    try {
      const res = await am012Service.getProjects(params);
      if (res?.results?.length > 0) {
        if (handleCheckExistProject(res.results, projectId)) return res.results;
        else {
          const param: any = {
            page: params.page + 1,
            size: params.size,
            keyword: params.keyword,
            statusId: params.statusId,
          };
          setPageOption(param.page);
          return res.results.concat(
            await handleFetchApiGetAllProject(param, projectId)
          );
        }
      } else return [];
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const handleFetchApiGetAllConstruction = async (
    params: any,
    constructionId: number
  ): Promise<any> => {
    try {
      const paramsGet = { ...params, onlyInvolved: true };
      const res = await am012Service.getAttendanceUserConstruction(paramsGet);
      if (res?.results.length > 0) {
        if (handleCheckExistConstruction(res.results, constructionId))
          return res.results;
        else {
          const param: any = {
            page: params.page + 1,
            size: params.size,
            keyword: params.keyword,
            statusId: params.statusId,
          };
          setPageOptionConstruction(param.page);
          return res.results.concat(
            await handleFetchApiGetAllConstruction(param, constructionId)
          );
        }
      } else return [];
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const handleCheckExistProject = (data: any, projectId: number) => {
    const res = data.find((item: any) => item?.id === projectId);
    if (res) {
      setCurrentProject(res);
      return true;
    } else return false;
  };

  const handleCheckExistConstruction = (data: any, constructionId: number) => {
    const res = data.find((item: any) => item?.id === constructionId);
    if (res) return true;
    else return false;
  };

  const handleFormatOptionAttendanceCategory = (data: AttendanceCategory[]) => {
    const option: OptionType[] = [];
    const codes = Object.values(ATTENDANCE_CATEGORY);
    codes.forEach((code) => {
      const res = data.find((item) => item.code === code);
      if (res)
        option.push({
          label: res.name,
          value: res.categoryId,
        });
    });
    return option;
  };

  const handleGetAttendanceCategory = async (): Promise<any> => {
    const res = await am012Service.getAttendanceCategory();
    const option: OptionType[] = handleFormatOptionAttendanceCategory(
      res.results
    );
    setAttendanceCategory(option);
  };

  const handleGetDetailProjectById = async (
    projectId: number
  ): Promise<any> => {
    try {
      const res = await am012Service.getProjectById({ projectId });
      if (
        res?.results?.id &&
        res?.results?.projectName &&
        res?.results?.constructions
      ) {
        const data = {
          id: res.results.id,
          projectName: res.results.projectName,
          constructions: res.results.constructions,
        };
        return data;
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  let timeOut: any = useRef();
  const handleSearchOption = (searchValue: string) => {
    if (timeOut.current) clearTimeout(timeOut.current);
    timeOut.current = setTimeout(async () => {
      try {
        setLoadingOption(true);
        setKeywordOption(searchValue);
        setPageOption(COMMON.DEFAULT_PAGE);
        setSizeOption(COMMON.DEFAULT_SIZE);
        const data = await getListProject({
          page: COMMON.DEFAULT_PAGE,
          size: COMMON.DEFAULT_SIZE,
          keyword: searchValue,
          statusId: projectStatus,
        });
        if (data) setProjects([...data]);
      } catch (error: any) {
        ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      } finally {
        setLoadingOption(false);
      }
    }, 1000);
  };

  const handleScrollProject = async (e: any): Promise<any> => {
    try {
      setLoadingOption(true);
      const target = e.target;
      if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
        setPageOption(pageOption + 1);
        const data = await getListProject({
          page: pageOption + 1,
          size: sizeOption,
          keyword: keywordOption ? keywordOption : undefined,
          statusId: projectStatus,
        });
        if (data) setProjects([...projects, ...data]);
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      setLoadingOption(false);
    }
  };

  const handleSearchOptionConstruction = (searchValue: string) => {
    if (!chosenProject) {
      if (timeOut.current) clearTimeout(timeOut.current);
      timeOut.current = setTimeout(async () => {
        if (!chosenProject) {
          try {
            setLoadingOption(true);
            setKeywordOptionConstruction(searchValue);
            setPageOptionConstruction(COMMON.DEFAULT_PAGE);
            setSizeOptionConstruction(COMMON.DEFAULT_SIZE);
            const data = await getListConstruction({
              page: COMMON.DEFAULT_PAGE,
              size: COMMON.DEFAULT_SIZE,
              keyword: searchValue,

            });
            if (data) setConstructions([...data]);
          } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
          } finally {
            setLoadingOption(false);
          }
        }
      }, 1000);
    } else {
      if (timeOut.current) clearTimeout(timeOut.current);
      timeOut.current = setTimeout(async () => {
        if (chosenProject) {
          try {
            setLoadingOption(true);
            setKeywordOptionConstruction(searchValue);
            setPageOptionConstructionChoosedProject(COMMON.DEFAULT_PAGE);

            const data = await getListConstruction({
              page: COMMON.DEFAULT_PAGE,
              size: COMMON.DEFAULT_SIZE,
              keyword: searchValue,
              projectId: projectIdChoosed
            });
            if (data) setConstructions([...data]);
          } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
          } finally {
            setLoadingOption(false);
          }
        }
      }, 1000);
    }
  };

  const handleScrollConstruction = async (e: any): Promise<any> => {
    if (typeModal === "create" && !chosenProject) {
      try {
        setLoadingOption(true);
        const target = e.target;
        if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
          setPageOptionConstruction(pageOptionConstruction + 1);
          const data = await getListConstruction({
            page: pageOptionConstruction + 1,
            size: sizeOptionConstruction,
            keyword: keywordOptionConstruction
              ? keywordOptionConstruction
              : undefined,

          });
          if (data) setConstructions([...constructions, ...data]);
        }
      } catch (error: any) {
        ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      } finally {
        setLoadingOption(false);
      }
    } else {
      try {
        setLoadingOption(true);
        const target = e.target;
        if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
          setPageOptionConstructionChoosedProject(pageOptionConstructionChoosedProject + 1);
          const data = await getListConstruction({
            page: pageOptionConstructionChoosedProject + 1,
            size: sizeOptionConstruction,
            keyword: keywordOptionConstruction
              ? keywordOptionConstruction
              : undefined,
            projectId: projectIdChoosed

          });
          if (data) setConstructions([...constructions, ...data]);
        }
      } catch (error: any) {
        ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      } finally {
        setLoadingOption(false);
      }
    }
  };

  const handleFindConstructions = async (projectId: number): Promise<any> => {
    try {
      setChosenProject(true);
      setProjectIdChoosed(projectId)
      if (projects.length !== 0) {
        if (projects.length !== 0) {

          let param = {
            page: COMMON.DEFAULT_PAGE,
            size: COMMON.DEFAULT_SIZE,
            projectId
          }
          const response = await getListConstruction(param);
          setConstructions(response ?? []);
        }

      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const resetProjectWhenSelectOrBlur = async (
    projectId: number
  ): Promise<any> => {
    try {
      setKeywordOption(undefined);
      const data = await handleFetchApiGetAllProject(
        {
          page: COMMON.DEFAULT_PAGE,
          size: COMMON.DEFAULT_SIZE,
          keyword: undefined,
          statusId: projectStatus,
        },
        projectId
      );
      if (data) setProjects([...data]);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const resetConstructionWhenSelectOrBlur = async (
    constructionId: any
  ): Promise<any> => {
    if (chosenProject) {

      try {
        setLoadingOption(true);
        setKeywordOptionConstruction(undefined);
        setPageOptionConstructionChoosedProject(COMMON.DEFAULT_PAGE);

        const data = await getListConstruction({
          page: COMMON.DEFAULT_PAGE,
          size: COMMON.DEFAULT_SIZE,
          keyword: undefined,
          projectId: projectIdChoosed
        });
        if (data) setConstructions(data);
      } catch (error: any) {
        ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      } finally {
        setLoadingOption(false);
      }

    }

    else {
      try {
        setKeywordOptionConstruction(undefined);
        const data = await handleFetchApiGetAllConstruction(
          {
            page: COMMON.DEFAULT_PAGE,
            size: COMMON.DEFAULT_SIZE,
            keyword: undefined,
          },
          constructionId
        );
        if (data) setConstructions([...data]);
      } catch (error: any) {
        ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      }
    }
  };

  const handleFindProjects = async (constructionId: number) => {
    if (typeModal === "create" && !chosenProject && constructions.length > 0) {
      const data = await handleFetchApiGetAllConstruction(
        {
          page: COMMON.DEFAULT_PAGE,
          size: COMMON.DEFAULT_SIZE,
          keyword: undefined,
          statusId: projectStatus,
        },
        constructionId
      );
      if (data) {
        const newData = formatConstructions(data);
        setConstructions([...newData]);
      }
      const goal: any = constructions.find(
        (element: any) => element.id === constructionId
      );
      if (goal?.projectId) {
        const data = await handleFetchApiGetAllProject(
          {
            page: COMMON.DEFAULT_PAGE,
            size: COMMON.DEFAULT_SIZE,
            keyword: undefined,
            statusId: projectStatus,
          },
          goal.projectId
        );
        if (data) setProjects([...data]);
        form.setFieldValue("projectId", goal.projectId);
      }
    }
  };

  const handleCreateAttendance = async (): Promise<any> => {
    const time = form.getFieldValue("requestTime");
    const date = form.getFieldValue("requestDate");
    const requestDate = helpers.combineDateTime(date, time);
    let response: any;

    const dataRequestCreate: RequestCreateAttendance = {
      requestCategoryId: form.getFieldValue("requestCategoryId"),
      requestDate,
      constructionId: form.getFieldValue("constructionId"),
      note: form.getFieldValue("note"),
    };
    if (typeModal === "update") {
      const dataRequestUpdate: RequestUpdateAttendance = {
        ...dataRequestCreate,
        requestId: requestId,
      };

      ConfirmModal({
        onOk: async () => {
          try {
            dispatch(setLoading(true));
            response = await am012Service.updateAttendance(dataRequestUpdate);
            dispatch(setIsVisibleAM014(false));
            SuccessNotification(
              response?.message ?? NOTIFICATION_TITLE.SUCCESS
            );
            resetState();
            dispatch(setRefreshAM010());
          } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
          } finally {
            dispatch(setLoading(false));
          }
        },
        className: "confirm__modal confirm__modal-purple-oke",
        title: MESSAGE.MESSAGE_TITLE_BEFORE_CREATE,
        description: MESSAGE.MESSAGE_DESCRIPTION_BEFORE_CREATE_ATTENDANCE,
        canceText: LABEL_MESSAGE.CANCEL_MODAL,
        okText: LABEL_MESSAGE.OK_MODAL,
        isCenterWithoutMenu: true,
      });
    } else
      ConfirmModal({
        onOk: async () => {
          try {
            dispatch(setLoading(true));
            response = await am012Service.createAttendance(dataRequestCreate);
            SuccessNotification(
              response?.message ?? NOTIFICATION_TITLE.SUCCESS
            );
            resetState();
            dispatch(setRefreshAM010());
          } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
          } finally {
            dispatch(setLoading(false));
          }
        },
        className: "confirm__modal confirm__modal-purple-oke",
        title: MESSAGE.MESSAGE_TITLE_BEFORE_CREATE,
        description: MESSAGE.MESSAGE_DESCRIPTION_BEFORE_CREATE_ATTENDANCE,
        canceText: LABEL_MESSAGE.CANCEL_MODAL,
        okText: LABEL_MESSAGE.OK_MODAL,
        isCenterWithoutMenu: true,
      });
  };

  const handleGetRequestAttendance = async (requestId: any): Promise<any> => {
    try {
      const params = {
        requestId: requestId,
      };
      const res = await am012Service.getRequestAttendance(params);
      setRequestAttendance(res.results);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const handleFillFormUpdate = async (): Promise<any> => {
    form.setFieldValue("requestCategoryId", requestAttendance?.category?.id);
    form.setFieldValue("requestDate", moment(requestAttendance?.requestDate));
    form.setFieldValue("requestTime", moment(requestAttendance?.requestDate));
    form.setFieldValue("projectId", requestAttendance?.project?.id);
    form.setFieldValue("constructionId", requestAttendance?.construction?.id);
    form.setFieldValue("approver", requestAttendance?.approver?.fullName);
    form.setFieldValue("note", requestAttendance?.note);
    const response = await handleGetDetailProjectById(
      requestAttendance.project.id
    );
    if (response?.constructions) setConstructions([...response.constructions]);
    setChosenProject(true);
  };

  const formatConstructions = (data: any) => {
    const arr: any = [];
    data.map((item: any) => {
      arr.push({
        id: item.id,
        name: item.description,
        projectId: item.projectId,
        projectName: item.projectName,
      });
    });
    return arr;
  };

  const getRequestApprover = async (): Promise<any> => {
    try {
      const res = await am012Service.getRequestApprover();
      if (res?.results) {
        setRequestApprover(res.results);
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const getProjectStatus = async (): Promise<any> => {
    try {
      const params = {
        page: COMMON.DEFAULT_PAGE,
        size: COMMON.DEFAULT_SIZE,
      };
      const res = await am012Service.getProjectStatus(params);
      if (res?.results) {
        res.results.forEach((item: any) => {
          if (item.name === STATUS.UNDER_CONSTRUCTION)
            setProjectStatus(item.id);
        });
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const resetState = () => {
    form.resetFields();
    setIsDirty(DEFAULT_STATE.isDirty);
    dispatch(resetAM012());
  };

  const onCancel = () => {
    if (!isDirty) {
      resetState();
      return;
    }
    ConfirmModal({
      onOk: () => resetState(),
      className: "confirm__modal",
      title: MESSAGE.MESSAGE_020,
      description: MESSAGE.MESSAGE_022_1,
      extraDescription: MESSAGE.MESSAGE_022_2,
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_MODAL,
      isCenterWithoutMenu: true,
    });
  };

  const checkDirty = (): void => {
    const formValue = form.getFieldsValue();
    const formProps = Object.keys(formValue);
    for (let i = 0; i < formProps.length; i++) {
      if (form.isFieldTouched(formProps[i])) {
        return setIsDirty(true);
      }
    }
    setIsDirty(false);
  };

  const trimValueInput = (item: any, field: string) => {
    form.setFieldValue(field, item.target.value.trim());
  };

  return {
    form,
    isDirty,
    attendanceCategory,
    loadingOption,
    projects,
    constructions,
    chosenProject,
    requestAttendance,
    requestApprover,
    projectStatus,
    onCancel,
    checkDirty,
    handleGetAttendanceCategory,
    getListProject,
    handleSearchOption,
    handleScrollProject,
    handleSearchOptionConstruction,
    handleScrollConstruction,
    handleCreateAttendance,
    handleFindConstructions,
    handleFindProjects,
    handleGetRequestAttendance,
    handleFillFormUpdate,
    getRequestApprover,
    trimValueInput,
    getProjectStatus,
    getProject,
    resetProjectWhenSelectOrBlur,
    resetConstructionWhenSelectOrBlur,
    handleGetProjectFirstOpenModal,
    handleGetConstructionFirstOpenModal,
  };
};

export default AM012Handler;
