import { ConfigProvider, Form, Popover, Tooltip } from "antd";
import { CCUS061Handler } from "../../../handler/HandlerImpl";
import { useSearchParams } from "react-router-dom";
import FilterComponent from "./Filter";
import { MENU_ITEM, PARAMS } from "../../../../constants";
import IconInfoGray from "../../../../../../../assets/icons/icon-info-square.svg";
type Props = {
  handler: CCUS061Handler;
};

const FilterSearchUI = ({ handler }: Props) => {
  const [searchParams] = useSearchParams();

  const state = searchParams.get(PARAMS.state) || MENU_ITEM[0].code;

  function getContent() {
    return (
      <>
        建設キャリアアップシステムに登録されている技能者のIDを検索し、取得します。
        <br />
        「前回検索した技能者IDを除く」を選択し、検索を行うと建設キャリアアップシステムより前回取得した技能者ID以降に更新された技能者IDのみ取得します。
        <br />
        技能者IDを取得後、必須項目を入力し認証を実行してください。
      </>
    );
  }

  return (
    <ConfigProvider direction="ltr">
      <main
        className="filter-ccus061-container"
        style={{ border: "none", paddingTop: 0 }}
      >
        <Form form={handler.formFilter} onFinish={handler.onFilter}>
          <main>
            <div className="header-filter-ccus">
              <span className="text-title-filter-ccus06">
                {state === MENU_ITEM[0].code ? (
                  <div>絞込み</div>
                ) : (
                  <div className="title-ccus062">
                    技能者ID検索{" "}
                    <Tooltip title={getContent()} placement="rightTop">
                      <img src={IconInfoGray} />
                    </Tooltip>
                  </div>
                )}{" "}
              </span>
            </div>
          </main>
          <div className="ccus-pr-24">
            <FilterComponent
              onResetFilter={handler.onResetFilter}
              listTechnician={handler.listTechnician}
              listDepartment={handler.listDepartment}
              handleGetTechnician={handler.handleGetTechnician}
              handleGetDepartment={handler.handleGetDepartment}
              handleScrollTechnician={handler.handleScrollTechnician}
              handleScrollDepartment={handler.handleScrollDepartment}
              loadingDepartment={handler.loadingDepartment}
              loadingTechnician={handler.loadingTechnician}
              handleSearchOptionTechnician={
                handler.handleSearchOptionTechnician
              }
              onChangeTechnician={handler.onChangeTechnician}
              onChangeDepartment={handler.onChangeDepartment}
            />
          </div>
        </Form>
      </main>
    </ConfigProvider>
  );
};

export default FilterSearchUI;
