// Core library
import React from 'react';

// Component library
import { Tabs } from 'antd';
import { TabHandler } from '../handler/HandlerImpl';
import { useSearchParams } from 'react-router-dom';
import { DEFAULT_TAB_KEY, DEFAULT_TAB_VALUE } from '../handler/Handler';

const { TabPane } = Tabs;

type props = {
    handler: TabHandler;
};

const MainUI = ({ handler }: props) => {
    const [searchParams] = useSearchParams();
    const tab = searchParams.get(DEFAULT_TAB_KEY) ?? DEFAULT_TAB_VALUE;

    return (
        <Tabs type="card" onChange={handler.switchTab} className="am028" activeKey={tab}>
            <TabPane tab="集計" key="1"></TabPane>
            <TabPane tab="材料費" key="2"></TabPane>
            <TabPane tab="外注費" key="3"></TabPane>
            <TabPane tab="労務費" key="4"></TabPane>
            <TabPane tab="間接工事費" key="5"></TabPane>
            <TabPane tab="入金額" key="6"></TabPane>
        </Tabs>
    );
};

export { MainUI };
