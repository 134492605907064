import ErrorNotification from '../../../../common/components/notification/ErrorNotification';
import { NOTIFICATION_TITLE } from '../../../../common/constants/MESSAGE';
import { ORD001Api } from './ServiceImpl';

const ORD001Service = (api: ORD001Api) => {
    const getListOrder = async (params: {
        page?: number;
        size?: number;
        planId?: number;
        statusId?: number;
        subcription?: string;
        from?: string;
        to?: string;
        sortBy?: string;
        sortType?: string;
    }) => {
        try {
            const response = await api.getListOrder(params);
            return response?.data;
        } catch (error: any) {
            ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };

    const getListPlan = async () => {
        try {
            const response = await api.getListPlan();
            return response?.data;
        } catch (error: any) {
            ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };

    const getListStatusOrder = async () => {
        try {
            const response = await api.getListStatusOrder();
            return response?.data;
        } catch (error: any) {
            ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };

    const getDetailPlanSetting = async () => {
        try {
            const response = await api.getDetailPlanSetting();
            return response?.data;
        } catch (error: any) {
            ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
            return null;
        }
    };

    const deleteOrder = async (data: any): Promise<any> => {
        try {
            return await api.deleteOrder(data);
        } catch (error) {
            throw error;
        }
    };

    return {
        getListOrder,
        getListPlan,
        getListStatusOrder,
        getDetailPlanSetting,
        deleteOrder,
    };
};

export default ORD001Service;
