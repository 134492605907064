import moment from "moment";
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  setIsVisibleSM03,
  setTypeModalSM03,
} from "../../../SM03/presenter/slice/Slice";
import { useSearchParams } from "react-router-dom";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../../common/constants/MESSAGE";
import COMMON from "../../../../../common/constants/COMMON";
import { SM01UseCase } from "../../usecase/ServiceImpl";
import { DayEventState, TagEntity } from "../../entity/Entity";
import {
  setIsVisibleSM04,
  setScheduleId,
} from "../../../SM04/presenter/slice/Slice";
import {
  formatListEvent,
  formatListEventDay,
  formatListEventMonth,
  getDayOfWeek,
} from "../../helper";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import helpers from "../../../../../common/helpers/common";
import { setIsVisibleSM010 } from "../../../SM010/presenter/slice/Slice";

export const PARAMS_KEY = {
  MODE: "mode",
  FILTER_DATE: "filterDate",
  FILTER_DATE_CALENDAR: "filterDateCalendar",
  LIST_MEMBER_CHOOSE: "listMemberChoose",
};
export const STORE_MEMBER_KEY = "storage_member_key";
export const MODE = {
  WEEK: {
    TEXT: "週別",
    CODE: "week",
  },
  DAY: {
    TEXT: "日別",
    CODE: "day",
  },
  MONTH: {
    TEXT: "月別",
    CODE: "month",
  },
};

export const DAY_IN_WEEK = ["日", "月", "火", "水", "木", "金", "土"];

const SM01Handler = (service: SM01UseCase) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const [totalPage, setTotalPage] = useState<number>(0);
  const [tags, setTags] = useState<TagEntity[]>([]);
  const [loadingOption, setLoadingOption] = useState(false);
  const [keywordOption, setKeywordOption] = useState("");
  const [pageOption, setPageOption] = useState(COMMON.DEFAULT_PAGE);
  const [sizeOption, setSizeOption] = useState(COMMON.DEFAULT_SIZE);
  const [members, setMembers] = useState<any[]>([]);
  const [valueMember, setValueMember] = useState<any[]>([]);
  const [headerWeek, setHeaderWeek] = useState<any>({});
  const [events, setEvents] = useState<any>({});

  const [weekEvents, setWeekEvents] = useState<any>();
  const [monthEvents, setMonthEvents] = useState<any>();
  const [dayEvents, setDayEvents] = useState<DayEventState[]>([]);

  const [openPopover, setOpenPopover] = useState<boolean>(false);

  const onChangeDate = (e: any) => {
    searchParams.set(
      PARAMS_KEY.FILTER_DATE_CALENDAR,
      e.format(COMMON.FORMAT_DATE2)
    );
    searchParams.set(PARAMS_KEY.FILTER_DATE, e.format(COMMON.FORMAT_DATE2));
    setSearchParams(searchParams);
  };

  const prevMonth = () => {
    const current =
      searchParams.get(PARAMS_KEY.FILTER_DATE_CALENDAR) ??
      moment().format(COMMON.FORMAT_DATE2);
    searchParams.set(
      PARAMS_KEY.FILTER_DATE_CALENDAR,
      moment(current).subtract(1, "months").format(COMMON.FORMAT_DATE2)
    );
    setSearchParams(searchParams);
  };

  const nextMonth = () => {
    const current =
      searchParams.get(PARAMS_KEY.FILTER_DATE_CALENDAR) ??
      moment().format(COMMON.FORMAT_DATE2);
    searchParams.set(
      PARAMS_KEY.FILTER_DATE_CALENDAR,
      moment(current).add(1, "months").format(COMMON.FORMAT_DATE2)
    );
    setSearchParams(searchParams);
  };

  const deleteMemberTag = (id: number) => {
    setTags((prev) =>
      prev.filter((a: TagEntity) => Number(a.id) !== Number(id))
    );
    setValueMember((prev) => prev.filter((a: any) => Number(a) !== Number(id)));
  };

  const openModalSM003 = () => {
    dispatch(setIsVisibleSM03(true));
    dispatch(setTypeModalSM03(false));
  };

  const onChangeMode = (e: any) => {
    searchParams.set(PARAMS_KEY.MODE, e);
    setSearchParams(searchParams);
  };

  const getListMember = async (params: {
    page: number;
    size: number;
    keyword?: string;
  }) => {
    setLoadingOption(true);
    try {
      const response = await service.getListMember(params);
      const numPages = response?.pagination?.numPages ?? 0;
      let finalResults = response.results ?? [];
      for (let i = 2; i <= numPages; i++) {
        const responseMorePage = await service.getListMember({
          ...params,
          page: i,
        });
        finalResults = [...finalResults, ...responseMorePage.results];
      }
      setMembers(finalResults);
      setTotalPage(response?.pagination?.numPages ?? 0);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      setLoadingOption(false);
    }
  };

  const handleSearchOption = (searchValue: string) => {
    getListMember({
      page: COMMON.DEFAULT_PAGE,
      size: COMMON.DEFAULT_SIZE,
      keyword: searchValue,
    });
    setKeywordOption(searchValue);
    setPageOption(COMMON.DEFAULT_PAGE);
    setSizeOption(COMMON.DEFAULT_SIZE);
  };

  const handleGetMember = () => {
    setMembers([]);
    setPageOption(COMMON.DEFAULT_PAGE);
    setSizeOption(COMMON.DEFAULT_SIZE);
    getListMember({ page: COMMON.DEFAULT_PAGE, size: COMMON.DEFAULT_SIZE });
  };

  const memberDeputyProjectManager = useMemo(() => {
    const user = helpers.getObjectFromLocalStorage("user");

    const checkDuplicate: any = {};
    const results: any[] = [];
    for (const member of members) {
      if (!(member.id in checkDuplicate) && member.username !== user.username) {
        results.push(member);
        checkDuplicate[member.id] = member;
      }
    }
    return results;
  }, [members]);

  const initiatingMemberData = async () => {
    const chosenMembers = searchParams.get(PARAMS_KEY.LIST_MEMBER_CHOOSE) ?? "";
    if (chosenMembers) {
      const data = chosenMembers.split(",");
      let finalResults: any[] = [];
      let checkList: any[] = [];
      let stop = true;
      let index = 0;

      do {
        try {
          const response = await service.getListMember({
            page: COMMON.DEFAULT_PAGE + index,
            size: COMMON.DEFAULT_SIZE,
          });

          finalResults = [...finalResults, ...response.results];
        } catch (error: any) {
          ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        }

        finalResults.forEach((item: any) => {
          data.forEach((a: any) => {
            if (Number(item.id) === Number(a)) {
              checkList.push("1");

              if (checkList.length === data.length) {
                stop = false;
              }
            }
          });
        });

        checkList = [];

        index++;
      } while (stop);

      const tagObject: TagEntity[] = [];
      data.forEach((v: any) => {
        const foundMember = finalResults.find(
          (item: any) => Number(item.id) === Number(v)
        );
        if (foundMember)
          tagObject.push({
            id: foundMember.id,
            text: foundMember.fullName || foundMember.username,
            username: foundMember.username,
            fullName: foundMember.fullName,
          });
      });
      setTags(tagObject);
      setValueMember(data.map((item: any) => Number(item)));
    }
  };

  const getListEvents = async (
    listMember: string,
    from: string,
    to: string,
    currentDate: string,
    mode: string
  ) => {
    try {
      dispatch(setLoading(true));
      setHeaderWeek(getDayOfWeek(currentDate));

      const obj: TagEntity[] = [];

      if (memberDeputyProjectManager.length !== 0) {
        valueMember.forEach((v: any) => {
          const foundProjectManager = memberDeputyProjectManager.find(
            (item: any) => Number(item.id) === Number(v)
          );

          if (foundProjectManager) {
            obj.push({
              id: foundProjectManager.id,
              text:
                foundProjectManager.fullName || foundProjectManager.username,
              username: foundProjectManager.username,
              fullName: foundProjectManager.fullName,
            });
          } else {
            const tagItem = tags.find(item => item.id === Number(v))
            if (tagItem) {
              obj.push(tagItem);
            }
          }

        });

        setTags(obj);
        searchParams.set(
          PARAMS_KEY.LIST_MEMBER_CHOOSE,
          valueMember.map((item: any) => item).join(",")
        );
        setSearchParams(searchParams);
      }

      let dataRefer: TagEntity[] = [];

      if (obj.length === 0 && valueMember.length !== 0) dataRefer = tags;
      else dataRefer = obj;

      const user = helpers.getObjectFromLocalStorage("user");
      const response = await service.getListEvent({
        listMember: listMember ? listMember : `${user.id}`,
        from: from,
        to: to,
      });

      switch (mode) {
        case MODE.WEEK.CODE:
          setEvents(formatListEvent(response.results, currentDate, dataRefer));
          break;
        case MODE.MONTH.CODE:
          setEvents(
            formatListEventMonth(response.results, dataRefer, currentDate)
          );
          break;
        default:
          setDayEvents(formatListEventDay(response?.results ?? [], dataRefer));
          break;
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const openModalDetailSM04 = (scheduleId: number) => {
    dispatch(setIsVisibleSM04(true));
    dispatch(setScheduleId(scheduleId));
  };

  const handleOpenPopover = () => {
    setOpenPopover((prev) => !prev);
  };
  const openModalSM010 = () => {
    dispatch(setIsVisibleSM010(true));
  };
  return {
    tags,
    loadingOption,
    memberDeputyProjectManager,
    valueMember,
    headerWeek,
    events,
    openPopover,
    dayEvents,
    setValueMember,
    onChangeDate,
    prevMonth,
    nextMonth,
    deleteMemberTag,
    openModalSM003,
    onChangeMode,
    handleSearchOption,
    handleGetMember,
    openModalDetailSM04,
    getListEvents,
    handleOpenPopover,
    initiatingMemberData,
    openModalSM010,
  };
};

export default SM01Handler;
