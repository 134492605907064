import { createSlice } from "@reduxjs/toolkit";
import { DB001SliceType } from "../../entity/Entity";

const initialState: DB001SliceType = {
  isRefreshDB001: false,
};

export const db01Slice = createSlice({
  name: "db001",
  initialState,
  reducers: {
    resetDB001: () => initialState,
    refreshDB001: (state) => {
      state.isRefreshDB001 = !state.isRefreshDB001;
    },
  },
});

export const { refreshDB001 } = db01Slice.actions;

export default db01Slice.reducer;
