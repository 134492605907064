import React, { useEffect } from "react";
import { Checkbox, Form, Select } from "antd";
import { Table } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { EditableCellProps, EditableRowProps } from "../type/TypeTable";
import COMMON, { PLAN_TYPE } from "../../../../../common/constants/COMMON";
import helpers from "../../../../../common/helpers/common";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { TITLE } from "../../../../../common/constants/MESSAGE";

const { Option } = Select;

const EditableContext = React.createContext<FormInstance<any> | null>(null);

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

type props = {
  data: any;
  paddingProps: number;
  scrollProps: number;
  selectionOptionProps: boolean;
  getDataSource?: Function;
  getRowKeySelected?: Function;
  columnCustomize: any;
  branchDataProps?: any;
  roleDataProps?: any;
  roleDefaultValue?: number;
  roleNameDefaultValue?: string;
};

const OM005DataTable = ({
  paddingProps,
  scrollProps,
  selectionOptionProps,
  data,
  getDataSource,
  getRowKeySelected,
  columnCustomize,
  branchDataProps,
  roleDataProps,
  roleDefaultValue,
  roleNameDefaultValue,
}: props) => {
  const [dataSource, setDataSource] = React.useState<[]>(data);
  const [rowKeySelected, setRowKeySelected] = React.useState<[]>([]);
  const [keyRender, setKeyRender] = React.useState<boolean>(false);

  const [loadingModal, setLoadingModal] = React.useState(false);

  const order = useSelector(
    (state: RootState) => state.auth?.authData?.order?.code
  ); //action.payload?.order?.code;
  const isOrderFree = order === PLAN_TYPE.PLAN_FREE;
  const isOrderBasic = order === PLAN_TYPE.PLAN_BASIC;

  const fakeSpinLoading = () => {
    setLoadingModal(true);
    setTimeout(() => {
      setLoadingModal(false);
    }, 500);
  };

  useEffect(() => {
    setDataSource(data);
    setKeyRender(!keyRender);
    fakeSpinLoading();
  }, [data.length]);

  let roleIdDefault: number = 0;

  if (roleDataProps) {
    roleDataProps.map((element: any) => {
      if (element.code == COMMON.DEFAULT_ROLE_CODE) {
        roleIdDefault = element.id;
      }
    });
  }

  const EditableCell: React.FC<EditableCellProps> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = React.useState<boolean>(true);
    const form = React.useContext(EditableContext)!;

    const save = async () => {
      try {
        const values = await form.validateFields();
        handleSave({ ...record, ...values });
      } catch (errInfo) { }
    };

    let childNode = children;

    if (editable) {
      childNode = editing ? (
        <Form.Item style={{ margin: 0 }} name={dataIndex}>
          {dataIndex === "branch" ? (
            <Select
              style={{
                width: 130,
              }}
              onChange={save}
              placeholder={TITLE.DEPARTMENT}
            >
              {branchDataProps.map((item: any, idx: number) => (
                <Option key={idx} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          ) : (
            <Select
              style={{
                width: 130,
              }}
              onChange={save}
              placeholder={roleNameDefaultValue}
              className={"om005__selection-placeholder"}
            >
              {roleDataProps.map((item: any, idx: number) => (
                <Option key={idx} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
      ) : (
        children
      );
    }

    return <td {...restProps}>{childNode}</td>;
  };

  const onSelectChange = (newSelectedRowKeys: any) => {
    setRowKeySelected(newSelectedRowKeys);
    if (getRowKeySelected) {
      getRowKeySelected(newSelectedRowKeys);
    }
    if (getDataSource) {

      const newData: any = [];
      dataSource.forEach((item: any) => {
        let obj: any = {
          ...item,
        };

        if (!item.role) {
          obj["role"] = roleIdDefault;
        }

        newData.push(obj);
      });

      getDataSource(newData);
    }
  };

  const handleSave = (row: any) => {
    const newData: any = [...dataSource];
    const index = newData.findIndex((item: any) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
    if (getDataSource) {
      getDataSource(newData);
    }
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const getColumnsEditing = (columnCustomize: any) => {
    let columnsEditing = columnCustomize.map((col: any) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record: any) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave,
        }),
      };
    });
    if (!isOrderBasic)
      columnsEditing = columnsEditing.filter((element: any) => {
        return element.dataIndex !== "role"
      })
    return columnsEditing
  }

  const getColumnsView = (columnCustomize: any) => {
    let columnsView = columnCustomize
    if (!isOrderBasic)
      columnsView = columnsView.filter((element: any) => {
        return element.dataIndex !== "role"
      })
    return columnsView
  }

  return (
    <>
      {selectionOptionProps == true ? (
        <Table
          key={`${keyRender}`}
          className="om005__table"
          components={components}
          style={{ padding: paddingProps }}
          rowSelection={{
            getCheckboxProps: (record: any) => ({
              disabled: !record?.isValid, // Column configuration not to be checked
            }),
            selectedRowKeys: rowKeySelected,
            type: "checkbox",
            onChange: onSelectChange,
          }}
          columns={getColumnsEditing(columnCustomize)}
          dataSource={dataSource}
          scroll={{
            y: 800,
            x: "max-content",
          }}
          pagination={false}
          loading={loadingModal}
        />
      ) : (
        <Table
          className="om005__table"
          style={{ padding: paddingProps }}
          columns={getColumnsView(columnCustomize)}
          dataSource={helpers.addKeyIndexToDataSource(data)}
          scroll={{
            y: scrollProps,
            x: 2000,
          }}
          pagination={false}
        />
      )}
    </>
  );
};

export default OM005DataTable;
