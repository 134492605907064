import "../../../../../assets/styles/CI/ci001.css";
import { useParams } from "react-router-dom";
import ApprovalFlow from "../../../../../common/components/approval-flow/ApprovalFlow";
import HomeSvg from "../../../../../assets/icons/home.svg";
import BreadcrumbComponent from "../../../../../common/components/breadcrumb/BreadcrumbComponent";
import { CI001Handler } from "../handler/HandlerImpl";

interface props {
    handler: CI001Handler;
}

export const CI001Header = ({ handler }: props) => {
    const { projectId } = useParams();

    const BREADCRUMB_CI001 = [
        {
            title: "案件管理",
            url: "/app-pm002",
            icon: (
                <img
                    style={{ paddingBottom: 2, paddingRight: 8 }}
                    alt=""
                    src={HomeSvg}
                />
            ),
        },
        {
            title: "案件詳細",
            url: `/app-pm005/${projectId}`,
            icon: "",
        },
        {
            title: "工事引合書",
            url: "",
            icon: "",
        },
    ];

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "start",
                marginBottom: 32
            }}
        >
            <div className="om002-header-container">
                <BreadcrumbComponent data={BREADCRUMB_CI001} />
                <div className="header-row-title" style={{ marginTop: 8 }}>
                    工事引合書
                </div>
            </div>
            <ApprovalFlow
                isHiddenFirst={true}
                isNotCheckingUser={true}
                breadcrumb={[]}
                listApprove={(handler.listApprovalProcess)}
                currentUserApproval={handler.currentUserApproval}
                onClickBreadcrumb={handler.onClickBreadcrumb}
            />
        </div>
    );
};

