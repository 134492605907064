import React, { useEffect } from 'react';
import { Handler } from '../handler/HandlerImpl';
import { Modal, Button, Row, Col, Spin } from 'antd';
import XIconModal from '../../../../../assets/icons/x-icon-modal.svg';
import TableComponent from './Table';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import moment from 'moment';
import COMMON from '../../../../../common/constants/COMMON';
import helpers from '../../../../../common/helpers/common';
import LoadingComponent from '../../../../../common/components/icons/LoadingComponent';
import '../../../../../assets/styles/DB/DB003.css';
import { PARAM_END_DATE, PARAM_START_DATE } from '../../../DB001/presenter/handler/Handler';
import { useSearchParams } from 'react-router-dom';

interface props {
    handler: Handler;
}

function MainUI({ handler }: props) {
    const visibleDB003 = useSelector((state: RootState) => state.db003.isVisibleDB003);
    const projectId = useSelector((state: RootState) => state.db003.projectId);
    const [searchParams, setSearchParams] = useSearchParams();
    const from =
        searchParams.get(PARAM_START_DATE) || moment().startOf('month').format(COMMON.FORMAT_DATE);
    const to =
        searchParams.get(PARAM_END_DATE) || moment().endOf("month").format(COMMON.FORMAT_DATE);

    useEffect(() => {
        if (projectId && from && to) handler.getTurnoverAmount({
            projectId: +projectId,
            from: helpers.getFilterTime(from),
            to: helpers.getFilterTimeTo(to),
        });
    }, [projectId, from, to]);

    return (
        <Spin indicator={LoadingComponent} spinning={handler.loading}>
            <Modal
                className="group-detail-modal"
                style={{ marginLeft: 300, marginTop: 40, marginBottom: 40 }}
                visible={visibleDB003}
                centered
                maskClosable={false}
                width={'1027px'}
                closable={false}
                onCancel={handler.cancelModalDB003}
                footer={
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            size="large"
                            style={{ marginLeft: 16, width: 118, color: '#666666' }}
                            onClick={handler.cancelModalDB003}
                        >
                            キャンセル
                        </Button>
                    </div>
                }
            >
                <div className="w-100-percent header-modal-containter" style={{ height: 50 }}>
                    <div className="header-modal-title-om004 ">
                        {handler.dataInformation.projectName}　出来高
                    </div>
                    <div style={{ cursor: 'pointer' }} onClick={handler.cancelModalDB003}>
                        <img alt="" src={XIconModal} style={{ height: 18, width: 18 }} />
                    </div>
                </div>
                <div style={{ margin: '24px 24px 24px 24px', backgroundColor: '#ffffff' }}>
                    <Row style={{ marginBottom: 24 }}>
                        <Col span={8} style={{ display: 'flex' }}>
                            <div style={{ marginRight: 24 }} className="CM0017_3-info-title">
                                期間
                            </div>
                            <div className="CM0017_3-info-content">
                                {' '}
                                {helpers.formatDatePresenter(
                                   from, COMMON.FORMAT_DATE_DB,
                                )}
                                ～
                                {helpers.formatDatePresenter(
                                   to, COMMON.FORMAT_DATE_DB
                                )}
                            </div>
                        </Col>
                    </Row>
                    <TableComponent handler={handler}></TableComponent>
                </div>

            </Modal>
        </Spin>
    );
}

export default MainUI;
