import { Button, DatePicker, Form, FormInstance, Input, Popover } from 'antd';
import CalendarSvg from '../../../../../assets/icons/calendar.svg';
import COMMON from '../../../../../common/constants/COMMON';
import CollapsedFalseSVG from '../../../../../assets/icons/collapsed-false.svg';
import CollapsedTrueSVG from '../../../../../assets/icons/collapsed-true.svg';
import locale from 'antd/es/date-picker/locale/ja_JP';
import type { RangePickerProps } from 'antd/es/date-picker';
import moment from 'moment';
import { RangeDateType } from '../../../AM002/entity/Entity';
import React from 'react';
import FilterIconSvg from '../../../../../assets/icons/filter-icon.svg';

type Props = {
    onFilter: () => void;
    onResetFilter: () => void;
    onCollapse: (value: boolean) => void;
    onChange: () => void;
    onFromDateChange: (value: any) => void;
    onToDateChange: (value: any) => void;
    isCollapsed: boolean;
    form: FormInstance<any>;
    rangeDate: RangeDateType;
};

const FilterSearchComponent = ({
    onFilter,
    onResetFilter,
    onCollapse,
    onChange,
    onToDateChange,
    onFromDateChange,
    isCollapsed,
    form,
    rangeDate,
}: Props) => {
    const disabledFromDate = (current: any, rangeDate: RangeDateType) => {
        if (
            moment(current).format(COMMON.FORMAT_DATE_OM) ===
            moment(rangeDate.to).format(COMMON.FORMAT_DATE_OM)
        )
            return false;
        return current && current > rangeDate.to;
    };
    const disabledToDate = (current: any, rangeDate: RangeDateType) => {
        if (
            moment(current).format(COMMON.FORMAT_DATE_OM) ===
            moment(rangeDate.from).format(COMMON.FORMAT_DATE_OM)
        )
            return false;
        return (
            current &&
            (rangeDate.from
                ? current < rangeDate.from || current > moment().endOf('day')
                : current > moment().endOf('day'))
        );
    };
    const content = (
        <Form
            form={form}
            style={{ paddingLeft: 16, paddingBottom: 34 }}
            className="company-container"
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: 24,
                }}
            >
                <div className="company-filter">絞込み</div>
                <div className="collapsed-button-close" onClick={() => onCollapse(!isCollapsed)}>
                    <img alt="" src={CollapsedTrueSVG} />
                </div>
            </div>
            <div style={{ paddingRight: 16 }}>
                <label className="filter-label">開始日</label>
                <Form.Item name={'startDate'} style={{ marginTop: 8 }}>
                    <DatePicker
                        allowClear={false}
                        onChange={onFromDateChange}
                        disabledDate={(current) => disabledFromDate(current, rangeDate)}
                        format={(value: moment.Moment) => {
                            const isToday = moment().isSame(value, 'day');
                            if (isToday) return COMMON.TODAY;
                            return value.format(COMMON.FORMAT_DATE_OM);
                        }}
                        className="ci001-date-picker w-100-percent date-picker-border"
                        inputReadOnly
                        locale={locale}
                        getPopupContainer={(trigger: any) => trigger.parentNode}
                        placeholder=""
                        suffixIcon={<img alt="" src={CalendarSvg} className="icon-calender" />}
                    />
                </Form.Item>
                <label className="filter-label">終了日</label>
                <Form.Item name={'endDate'} style={{ marginTop: 8 }}>
                    <DatePicker
                        allowClear={false}
                        onChange={onToDateChange}
                        disabledDate={(current) => disabledToDate(current, rangeDate)}
                        format={(value: moment.Moment) => {
                            const isToday = moment().isSame(value, 'day');
                            if (isToday) return COMMON.TODAY;
                            return value.format(COMMON.FORMAT_DATE_OM);
                        }}
                        className="ci001-date-picker w-100-percent date-picker-border"
                        inputReadOnly
                        locale={locale}
                        getPopupContainer={(trigger: any) => trigger.parentNode}
                        placeholder=""
                        suffixIcon={<img alt="" src={CalendarSvg} className="icon-calender" />}
                    />
                </Form.Item>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button onClick={onFilter} size="large" style={{ width: 97 }} type="primary">
                        絞込み
                    </Button>
                    <Button
                        className="reset-filter-btn"
                        onClick={onResetFilter}
                        size="large"
                        style={{ width: 97 }}
                    >
                        クリア
                    </Button>
                </div>
            </div>
        </Form>
    );
    const contentCollapsed = (
        <Form form={form} style={{ width: 258 - 24, padding: '8px 12px' }}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: 24,
                }}
            >
                <div className="company-filter">絞込み</div>
            </div>
            <div>
                <label className="filter-label">開始日</label>
                <Form.Item name={'startDate'} style={{ marginTop: 8 }}>
                    <DatePicker
                        allowClear={false}
                        onChange={onFromDateChange}
                        disabledDate={(current) => disabledFromDate(current, rangeDate)}
                        format={(value: moment.Moment) => {
                            const isToday = moment().isSame(value, 'day');
                            if (isToday) return COMMON.TODAY;
                            return value.format(COMMON.FORMAT_DATE_OM);
                        }}
                        className="ci001-date-picker w-100-percent"
                        inputReadOnly
                        locale={locale}
                        getPopupContainer={(trigger: any) => trigger.parentNode}
                        placeholder=""
                        suffixIcon={<img alt="" src={CalendarSvg} className="icon-calender" />}
                    />
                </Form.Item>
                <label className="filter-label">終了日</label>
                <Form.Item name={'endDate'} style={{ marginTop: 8 }}>
                    <DatePicker
                        allowClear={false}
                        onChange={onToDateChange}
                        disabledDate={(current) => disabledToDate(current, rangeDate)}
                        format={(value: moment.Moment) => {
                            const isToday = moment().isSame(value, 'day');
                            if (isToday) return COMMON.TODAY;
                            return value.format(COMMON.FORMAT_DATE_OM);
                        }}
                        className="ci001-date-picker w-100-percent"
                        inputReadOnly
                        locale={locale}
                        getPopupContainer={(trigger: any) => trigger.parentNode}
                        placeholder=""
                        suffixIcon={<img alt="" src={CalendarSvg} className="icon-calender" />}
                    />
                </Form.Item>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button onClick={onFilter} size="large" style={{ width: 97 }} type="primary">
                        絞込み
                    </Button>
                    <Button
                        className="reset-filter-btn"
                        onClick={onResetFilter}
                        size="large"
                        style={{ width: 97 }}
                    >
                        クリア
                    </Button>
                </div>
            </div>
        </Form>
    );
    return isCollapsed ? (
        <React.Fragment>
            <main className="company-container">
                <div className="center-item w-100-percent">
                    <div
                        className="collapsed-button-open"
                        style={{ marginBottom: 27, display: 'flex', justifyContent: 'center' }}
                        onClick={() => onCollapse(!isCollapsed)}
                    >
                        <img alt="" src={CollapsedFalseSVG} />
                    </div>
                </div>

                <Popover
                    getPopupContainer={(trigger: any) => trigger.parentNode}
                    placement="rightTop"
                    content={contentCollapsed}
                    trigger="click"
                >
                    <div style={{ cursor: 'pointer' }} className="center-item">
                        <img src={FilterIconSvg} alt="" />
                    </div>
                </Popover>
            </main>
        </React.Fragment>
    ) : (
        content
    );
};

export default FilterSearchComponent;
