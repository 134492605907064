import React from 'react';

interface InfoRowProps {
  title: any;
  value: string;
  width: number;
}

const InfoRow: React.FC<InfoRowProps> = ({ title, value, width }) => {
  return (
    <div style={{display: 'flex', paddingRight: 16}}>
      <div className='info-row-title' style={{minWidth: width}}>{title}</div>
      <div className='info-row-value'>{value}</div>
    </div>
  );
};

export default InfoRow;
