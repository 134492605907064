import { PlanEntity, PlanSetting, PlanSettingPayload, PlanUser } from '../entity/Entity';
import { APIInterface } from './ServiceImpl';

const Service = (api: APIInterface) => {
    const getDataORD007 = async (): Promise<PlanEntity> => {
        try {
            const planUsers: PlanUser[] = [
                {
                    id: 1,
                    money: 2000,
                    numberID: 30,
                },
                {
                    id: 2,
                    money: 2000,
                    numberID: 31,
                },
            ];

            const result: PlanEntity = {
                basicCharge: 2000,
                planUsers: planUsers,
                income: 2000,
                constructionPayment: 2000,
                internalBalance: 2000,
                dashboard: 2000,
                userNumber: 90,
                notiDateNumber: 5,
            };
            return result;
        } catch (error) {
            throw error;
        }
    };

    const updateDataORD007 = async (data: any): Promise<void> => {
        try {
        } catch (error) {
            throw error;
        }
    };
    const postPlanSetting = async (data: PlanSettingPayload): Promise<any> => {
        try {
            return await api.postPlanSetting(data);
        } catch (error) {
            throw error;
        }
    };

    const getPlanSettingDetail = async (params: any): Promise<PlanSetting> => {
        const data = await api.getPlanSettingDetail(params);
        return data.results;
    };
    return { getDataORD007, updateDataORD007, postPlanSetting, getPlanSettingDetail };
};

export default Service;
