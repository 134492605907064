import React from 'react';
import CM0025_1Api from './infrastructure/api/Api';
import CM0025_1Handler from './presenter/handler/Handler';
import CM0025_1UI from './presenter/ui/UI';
import CM0025_1Service from './usecase/Service';

const CM0025_1Component = () => {
    const cm0025_1Api = CM0025_1Api();
    const cm0025_1Service = CM0025_1Service(cm0025_1Api);
    const cm0025_1Handler = CM0025_1Handler(cm0025_1Service);
    return <CM0025_1UI handler={cm0025_1Handler} />;
};

export default CM0025_1Component;
