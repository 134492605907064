import { DB002SliceType } from "./../type/Presenter";
import { createSlice } from "@reduxjs/toolkit";

const initialState: DB002SliceType = {
  isVisibleDB002: false,
  isRefresh: false,
  dataProjectDB002: {},
};

export const db002Slice = createSlice({
  name: "db002",
  initialState,
  reducers: {
    resetDB002: () => initialState,
    setVisibleDB002: (state, action) => {
      state.isVisibleDB002 = action.payload;
    },
    setDataProjectDB002: (state, action) => {
      state.dataProjectDB002 = action.payload;
    },
  },
});

export const { resetDB002, setVisibleDB002, setDataProjectDB002 } =
  db002Slice.actions;

export default db002Slice.reducer;
