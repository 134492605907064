import { Form, Switch, Table } from "antd";
import { useEffect } from "react";
import { HandlerImpl } from "../handler/HandlerImpl";
import { RootState } from "../../../../../store";
import { useSelector } from "react-redux";
import {
  AUTHORIZATION_CODE,
  SYSTEM_ROLES,
} from "../../../../../common/constants/AUTHORIZATION";
import { isHasPrivilegeByPermission } from "../../../../../common/helpers/privilege";
import { PLAN_TYPE } from "../../../../../common/constants/COMMON";

interface props {
  handler: HandlerImpl;
}

const columnsTableLeftTop: any = [
  {
    title: "案件管理",
    dataIndex: "title",
    width: "33.33%",
    render: (text: string) => (
      <div style={{ display: "flex", justifyContent: "center" }}>{text}</div>
    ),
  },
  {
    title: "作成",
    dataIndex: "create",
    width: "33.33%",
    render: (value: string) => (
      <Form.Item
        valuePropName="checked"
        style={{ display: "flex", justifyContent: "center" }}
        name={value}
      >
        <Switch />
      </Form.Item>
    ),
  },
  {
    title: "編集",
    dataIndex: "edit",
    width: "33.33%",
    render: (value: string) => (
      <Form.Item
        style={{ display: "flex", justifyContent: "center" }}
        name={value}
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
    ),
  },
];

const columnsTableLeftBot: any = [
  {
    title: "収支管理",
    dataIndex: "title",
    width: "33.33%",
    render: (text: string) => (
      <div style={{ display: "flex", justifyContent: "center" }}>{text}</div>
    ),
  },
  {
    title: "作成",
    dataIndex: "create",
    width: "33.33%",
    render: (value: string) => (
      <Form.Item
        style={{ display: "flex", justifyContent: "center" }}
        valuePropName="checked"
        name={value}
      >
        <Switch />
      </Form.Item>
    ),
  },
  {
    title: "編集",
    dataIndex: "edit",
    width: "33.33%",
    render: (value: string) => (
      <Form.Item
        style={{ display: "flex", justifyContent: "center" }}
        valuePropName="checked"
        name={value}
      >
        <Switch />
      </Form.Item>
    ),
  },
];

const columnsTableRightTop: any = [
  {
    title: "勤怠管理",
    dataIndex: "title",
    width: 375,
    render: (text: string) => (
      <div style={{ display: "flex", justifyContent: "center" }}>{text}</div>
    ),
  },
  {
    title: "打刻",
    dataIndex: "engraving",
    width: 375,
    render: (value: string) => (
      <Form.Item
        style={{ display: "flex", justifyContent: "center" }}
        valuePropName="checked"
        name={value}
      >
        <Switch />
      </Form.Item>
    ),
  },
];

const columnsTableRightBot: any = [
  {
    title: "ワークフロー",
    dataIndex: "title",
    width: 375,
    render: (text: any) => (
      <div style={{ display: "flex", justifyContent: "center" }}>{text}</div>
    ),
  },
  {
    title: "編集",
    dataIndex: "edit",
    width: 375,
    render: (value: string) => (
      <Form.Item
        style={{ display: "flex", justifyContent: "center" }}
        valuePropName="checked"
        name={value}
      >
        <Switch />
      </Form.Item>
    ),
  },
];



const columnsTableRightBot2: any = [
  {
    title: "スケジュール",
    dataIndex: "title",
    width: 375,
    render: (text: any) => (
      <div style={{ display: "flex", justifyContent: "center" }}>{text}</div>
    ),
  },
  {
    title: "コメント",
    dataIndex: "edit",
    width: 375,
    render: (value: string) => (
      <Form.Item
        style={{ display: "flex", justifyContent: "center" }}
        valuePropName="checked"
        name={value}
      >
        <Switch />
      </Form.Item>
    ),
  },
];

const SN01Component = ({ handler }: props) => {
  const isWorkFlowUserIsInWorkFlows = useSelector(
    (state: RootState) => state.auth.authData?.isWorkFlowUserIsInWorkFlows
  );

  const isRoleAdminBasic = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdminBasic
  );
  const isUserInWorkspace = useSelector(
    (state: RootState) => state.auth.authData?.isUserInWorkspace
  );

  const isUserIsProjectManager = useSelector(
    (state: RootState) => state.auth.authData?.isUserIsProjectManager
  );

  const subscriptions =
    useSelector(
      (state: RootState) => state.auth?.authData?.order?.subscription
    ) ?? [];

  useEffect(() => {
    handler.getListNotification();
  }, []);

  return (
    <>
      <Form
        form={handler.form}
        name="sn01"
        onValuesChange={(value: any) => handler.handleChangeValue(value)}
      >
        <div className="sn01-table">
          <div>
            {(isRoleAdminBasic ||
              isUserInWorkspace ||
              isUserIsProjectManager ||
              isHasPrivilegeByPermission([
                AUTHORIZATION_CODE.S11,
                AUTHORIZATION_CODE.S18,
                AUTHORIZATION_CODE.S14,
                AUTHORIZATION_CODE.B10,
              ])) &&
              handler.dataTableLeftTop ? (
              <Table
                columns={columnsTableLeftTop}
                dataSource={handler.dataTableLeftTop}
                pagination={false}
                bordered
              />
            ) : (
              <></>
            )}
            {((isRoleAdminBasic && subscriptions.length > 0) ||
              (isUserIsProjectManager && subscriptions.length > 0) ||
              isHasPrivilegeByPermission([
                AUTHORIZATION_CODE.S28,
                AUTHORIZATION_CODE.S29,
                AUTHORIZATION_CODE.S30,
                AUTHORIZATION_CODE.S301,
                AUTHORIZATION_CODE.S31,
                AUTHORIZATION_CODE.S42,
                AUTHORIZATION_CODE.S48,
                AUTHORIZATION_CODE.S49,
              ])) &&
              handler.dataTableLeftBot ? (
              <Table
                columns={columnsTableLeftBot}
                dataSource={handler.dataTableLeftBot}
                pagination={false}
                bordered
              />
            ) : (
              <></>
            )}
          </div>


          <div>
            {(isRoleAdminBasic ||
              isHasPrivilegeByPermission([
                AUTHORIZATION_CODE.M8,
                AUTHORIZATION_CODE.B13,
                AUTHORIZATION_CODE.M9,
              ])) &&
              handler.dataTableRightTop ? (
              <Table
                columns={columnsTableRightTop}
                dataSource={handler.dataTableRightTop}
                pagination={false}
                bordered
                style={{ height: 108 }}
              />
            ) : (
              <></>
            )}
            {(isRoleAdminBasic || isWorkFlowUserIsInWorkFlows) &&
              handler.dataTableRightBot ? (
              <Table
                columns={columnsTableRightBot}
                dataSource={handler.dataTableRightBot}
                pagination={false}
                bordered
                style={{ height: 108 }}
              />
            ) : (
              <></>
            )}

            {(isRoleAdminBasic || isWorkFlowUserIsInWorkFlows) &&
              handler.dataTableRightBot2 ? (
              <Table
                columns={columnsTableRightBot2}
                dataSource={handler.dataTableRightBot2}
                pagination={false}
                bordered
                style={{ height: 108 }}
              />
            ) : (
              <></>
            )}
          </div>



        </div>
      </Form>
    </>
  );
};

export default SN01Component;
