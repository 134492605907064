/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import '../../../assets/styles/style.css';

type props = {
    items: any[];
    isPie: boolean;
};

const Legend = ({ items, isPie }: props) => {
    return (
        <div className={`${isPie ? 'legend-wrapper--pie' : 'legend-wrapper'}`}>
            {items.map((ite: any, idx: number) => (
                <div className="legend-wrapper__item" key={idx}>
                    <div className="shape" style={{ background: ite.color }}></div>
                    <div className="text">{ite.name}</div>
                </div>
            ))}
        </div>
    );
};

export default Legend;
