import CM006Api from '../CM006/infrastructure/api/Api';
import CM006Service from '../CM006/usecase/Service';
import CM008Api from './infrastructure/api/Api';
import CM008Handler from './presenter/handler/Handler';
import CM008UI from './presenter/ui/UI';
import CM008Service from './usecase/Service';

type props = {
    onSubmit?: () => void;
    onReject?: () => void;
    disableSubmitOrReject?: boolean;
    isCheckbox?: boolean
};

const CM008Component = (props: props) => {
    const cm006Api = CM006Api();
    const cm006Service = CM006Service(cm006Api);

    const cm008Api = CM008Api();
    const cm008Service = CM008Service(cm008Api);

    const cm008Handler = CM008Handler(cm008Service, cm006Service);

    return <CM008UI handler={cm008Handler} {...props} />;
};

export default CM008Component;
