import React from 'react';
import moment from 'moment';
import COMMON, { TAX_CODE } from '../../../../../common/constants/COMMON';
import helpers from '../../../../../common/helpers/common';
import { Empty } from 'antd';
import { OffsetCost } from '../../entity/Entity';
import EyeViewDetailSvg from '../../../../../assets/icons/eye-view-detail.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import TooltipText from '../../../../../common/components/tooltip-text/TooltipText';
import MESSAGE from '../../../../../common/constants/MESSAGE';

type props = {
    outSourceCost: any[];
    openCM0027_1: (value: any) => void;
    openCM0027_2: (value: any) => void;
};

const CostTableComponent = ({ outSourceCost, openCM0027_1, openCM0027_2 }: props) => {
    const isShowOffset = useSelector((state: RootState) => state.cm0027.isShowOffset);
    const getOffsetValue = (record: any, taxFree?: boolean) => {
        const offsetCost = record.offsetCost?.find(
            (element: OffsetCost) =>
                element.category.code === (taxFree ? TAX_CODE.TAX_FREE : TAX_CODE.TAX),
        );

        return (
            <main className="center-item">
                <div className="collapsed-table-row-style">
                    {`${helpers.formatNumberMoney(offsetCost?.value ?? 0)}円`}
                </div>
                <div
                    style={{ marginLeft: 10 }}
                    className="button-style"
                    onClick={() =>
                        openCM0027_1({
                            ...record,
                            categoryId: offsetCost?.category?.id ?? 0,
                        })
                    }
                >
                    <img alt="" src={EyeViewDetailSvg} />
                </div>
            </main>
        );
    };
    const getOffsetSummaryValue = (record: any, taxFree?: boolean) => {
        const offsetCost = record.offsetCostSummary?.find(
            (element: OffsetCost) =>
                element.category.code === (taxFree ? TAX_CODE.TAX_FREE : TAX_CODE.TAX),
        );

        return (
            <main className="center-item">
                <div className="collapsed-table-row-style">
                    {`${helpers.formatNumberMoney(offsetCost?.value ?? 0)}円`}
                </div>
                <div
                    style={{ marginLeft: 10 }}
                    className="button-style"
                    onClick={() =>
                        openCM0027_2({
                            ...record,
                            categoryId: offsetCost?.category?.id ?? 0,
                        })
                    }
                >
                    <img alt="" src={EyeViewDetailSvg} />
                </div>
            </main>
        );
    };
    return (
        <React.Fragment>
            <tr className="bg-fa">
                <td></td>
                <td></td>
                <td>業者名</td>
                <td>対象期間</td>
                <td>支払日</td>
                <td></td>
                {isShowOffset ? (
                    <>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </>
                ) : (
                    <></>
                )}
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            {outSourceCost.length === 0 ? (
                <tr>
                    <td colSpan={11}>
                        <Empty
                            style={{ width: '100%' }}
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={MESSAGE.MESSAGE_DATA_NOT_FOUND}
                        />
                    </td>
                </tr>
            ) : (
                outSourceCost.map((element, index) => (
                    <tr key={`table${element.id}`} className="">
                        <td style={{ width: 75 }}></td>
                        <td style={{ width: 75 }}>{index + 1}</td>
                        <td>
                            <main className="center-item" style={{ width: 150 }}>
                                <div className="collapsed-table-row-style" style={{ maxWidth: 120 }}>
                                    <TooltipText content={element?.company?.name ?? ''} />
                                </div>
                                <div
                                    style={{ marginLeft: 10 }}
                                    className="button-style"
                                    onClick={() =>
                                        openCM0027_2({
                                            ...element,
                                            categoryId: null,
                                        })
                                    }
                                >
                                    <img alt="" src={EyeViewDetailSvg} />
                                </div>
                            </main>
                        </td>
                        <td>{helpers.getStartEndDate(element.startDate, element.endDate)}</td>

                        <td>
                            {element.paymentDate
                                ? moment(element.paymentDate).format(COMMON.FORMAT_DATE_OM)
                                : ''}
                        </td>

                        <td>{`${helpers.formatNumberMoney(element.actualCost)}円`}</td>
                        {isShowOffset ? (
                            <>
                                <td>{getOffsetValue(element)}</td>
                                <td>{getOffsetValue(element, true)}</td>
                                <td>{getOffsetSummaryValue(element)}</td>
                                <td>{getOffsetSummaryValue(element, true)}</td>
                            </>
                        ) : (
                            <></>
                        )}
                        <td>{`${helpers.formatNumberMoney(element.totalOffset)}円`}</td>
                        <td>{`${helpers.formatNumberMoney(
                            element.paymentAmountExcludingTax,
                        )}円`}</td>
                        <td>{`${helpers.formatNumberMoney(element.consumptionTax)}円`}</td>
                        <td>{`${helpers.formatNumberMoney(
                            element.paymentAmountIncludingTax,
                        )}円`}</td>
                        <td></td>
                    </tr>
                ))
            )}
        </React.Fragment>
    );
};

export default CostTableComponent;
