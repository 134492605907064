import { Button, Pagination, Select } from "antd";
import "../../../assets/styles/components/pagination.css";
import FirstSvg from "../../../assets/icons/first.svg";
import LastSvg from "../../../assets/icons/last.svg";
import ArrowRight from "../../../assets/icons/arrow-right-2.svg";
import ArrowLeft from "../../../assets/icons/arrow-left-2.svg";
import type { PaginationProps } from "antd";
import { useMemo } from "react";

interface props {
  total: number;
  page: number;
  size: number;
  setPage: (value: number) => void;
  setSize?: (value: number) => void;
  disabled?: boolean;
}

const PaginationComponent = ({
  total,
  page,
  setPage,
  size,
  disabled,
}: props) => {
  const itemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return <img alt="" src={ArrowLeft} />;
    }
    if (type === "next") {
      return <img alt="" src={ArrowRight} />;
    }
    return originalElement;
  };

  const lastPage = useMemo(() => {
    const result = Math.floor(total / 6);
    if (result < 1) return 1;
    if (total % size > 0) return result + 1;
    return result;
  }, [total, size]);

  return (
    <div className="pagination-container  w-100-percent">
      <div style={{ display: "flex" }}>
        <Button
          disabled={page === 1 || (disabled ?? false)}
          onClick={() => setPage(1)}
          type="link"
          style={{
            border: "none",
            backgroundColor: "transparent",
            marginTop: 1.2,
          }}
          icon={
            <img
              alt=""
              src={FirstSvg}
              style={{ marginBottom: 4, width: "16px", height: "16px" }}
            />
          }
        ></Button>
        <Pagination
          disabled={disabled ?? false}
          showSizeChanger={false}
          itemRender={itemRender}
          onChange={setPage}
          current={page}
          total={total}
          pageSize={6}
        />
        <Button
          disabled={page === lastPage || (disabled ?? false)}
          onClick={() => setPage(lastPage)}
          type="link"
          style={{
            border: "none",
            backgroundColor: "transparent",
            marginTop: 1.2,
          }}
          icon={
            <img
              alt=""
              src={LastSvg}
              style={{ marginBottom: 4, width: "16px", height: "16px" }}
            />
          }
        ></Button>
      </div>
      {/* size */}
    </div>
  );
};
export default PaginationComponent;
