import { AM042ServiceImpl } from './../../usecase/ServiceImpl';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { resetAM042 } from '../slice/Slice';
import ErrorNotification from '../../../../../common/components/notification/ErrorNotification';
import { NOTIFICATION_TITLE } from '../../../../../common/constants/MESSAGE';
import { convertUserTimeSheetData } from '../../helper';
import { openModalStreetMap } from '../../../../../common/components/open-street-map/Slice';

const Handler = (service: AM042ServiceImpl) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState<boolean>(false);
    const [report, setReport] = useState<any>();

    const closeModal = () => {
        dispatch(resetAM042());
    };

    const getAttendanceUserTimeSheet = async (params: {
        date: string;
        constructionId?: string;
        contractorConstructionId?: string;
        userId: string;
    }): Promise<any> => {
        try {
            setLoading(true);
            const responseData = await service.getAttendanceUserTimeSheet(params);
            setReport(convertUserTimeSheetData(responseData));
        } catch (error: any) {
            ErrorNotification(error.message || NOTIFICATION_TITLE.ERROR);
        } finally {
            setLoading(false);
        }
    };
    const openStreetMap = (lat: any, lng: any) => {
        dispatch(
            openModalStreetMap({
                lat: lat,
                lng: lng,
            }),
        );
    };
    return {
        openStreetMap,
        setReport,
        closeModal,
        getAttendanceUserTimeSheet,
        loading,
        report,
    };
};

export default Handler;
