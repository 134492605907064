import { AxiosResponse } from 'axios';
import axiosConfig from '../../../../../config/axiosConfig';

const AttendanceApi = () => {
    const getAttendanceSubConstructionMemberTimeSheet = async (params: {
        constructionId: number;
        userId: number;
        from: string;
        to: string;
        timeOffset: number;
    }): Promise<AxiosResponse<any, any>> => {
        const url = '/attendance/subconstruction/member/timesheet';
        return await axiosConfig.get(url, { params });
    };

    return {
        getAttendanceSubConstructionMemberTimeSheet,
    };
};

export default AttendanceApi;
