import { useState, useRef } from "react";
import { Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AM022UseCase } from "../../usecase/ServiceImpl";
import { resetAM022, setLoading } from "../slice/Slice";
import ConfirmModal from "../../../../../common/components/modal/ConfirmModal";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import moment from "moment";
import { RootState } from "../../../../../store";
import COMMON from "../../../../../common/constants/COMMON";
import { setRefreshAM010 } from "../../../AM010/presenter/slice/Slice";
import STATUS from "../../../../../common/constants/STATUS";
import { setIsVisibleAM024 } from "../../../AM024/presenter/slice/Slice";

const DEFAULT_STATE = {
  isDirty: false,
  certType: [],
  fileData: undefined,
};

export const OFF_ALL_DAY_CODE = "full_day",
  OFF_MOR_CODE = "half_day_mor",
  OFF_AF_CODE = "half_day_af";

const AM022Handler = (am022Service: AM022UseCase) => {
  // LIB FUNCTION
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  // STATE
  const [isDirty, setIsDirty] = useState(DEFAULT_STATE.isDirty);

  const [categories, setCategory] = useState<any[]>([]);
  const [subCategories, setSubCategory] = useState<any[]>([]);
  const [numberOfDayOff, setDayOff] = useState<number>(0);
  const [requestLeave, setRequestLeave] = useState<any>();
  const [requestApprover, setRequestApprover] = useState<any>({});

  const [pageOption, setPageOption] = useState(COMMON.DEFAULT_PAGE);
  const [sizeOption, setSizeOption] = useState(COMMON.DEFAULT_SIZE);
  const [keywordOption, setKeywordOption] = useState<any>(undefined);

  const [pageOptionConstruction, setPageOptionConstruction] = useState(
    COMMON.DEFAULT_PAGE
  );
  const [sizeOptionConstruction, setSizeOptionConstruction] = useState(
    COMMON.DEFAULT_SIZE
  );
  const [keywordOptionConstruction, setKeywordOptionConstruction] =
    useState<any>(undefined);

  const [projects, setProjects] = useState<any[]>([]);
  const [constructions, setConstructions] = useState<any[]>([]);
  const [loadingOption, setLoadingOption] = useState(false);
  const [chosenProject, setChosenProject] = useState<boolean>(false);
  const [currentProject, setCurrentProject] = useState<any>();
  const [projectStatus, setProjectStatus] = useState<any>();
  const [projectIdChoosed, setProjectIdChoosed] = useState<number>(0);
  const [pageOptionConstructionChoosedProject, setPageOptionConstructionChoosedProject] = useState(
    COMMON.DEFAULT_PAGE
  );
  const typeModal = useSelector((state: RootState) => state.am022.typeModal);
  const requestId = useSelector((state: RootState) => state.am022.requestId);

  // FUNCTION

  const getListCategoryLeave = async () => {
    try {
      const response = await am022Service.getLeaveCategory();
      setCategory(response?.results);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const getListSubCategoryLeave = async () => {
    try {
      const response = await am022Service.getLeaveSubCategory();
      if (response?.results) {
        const data = formatSubCategory(response?.results);
        setSubCategory(data);
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const formatSubCategory = (data: any) => {
    const arr: any = [];
    data.forEach((item: any) => {
      if (item?.code === OFF_ALL_DAY_CODE) {
        arr[0] = item;
      } else if (item?.code === OFF_MOR_CODE) {
        arr[1] = item;
      } else {
        arr[2] = item;
      }
    });
    return arr;
  };

  const handleDayOffDefaultValue = () => {
    if (subCategories.length > 0) {
      const goal: any = subCategories.find(
        (element: any) => element?.code === OFF_ALL_DAY_CODE
      );
      form.setFieldValue("dayOff", goal?.categoryId);
    }
    return;
  };

  const handleDisableDate = (value: any) => {
    return value <= form.getFieldValue("dateStart");
  };

  const handleCountDayOff = () => {
    let dayOffDate: number = 0;
    if (form.getFieldValue("dateFinish") && form.getFieldValue("dateStart")) {
      const count = countDayExcludeSaturdaySunday(
        form.getFieldValue("dateStart"),
        form.getFieldValue("dateFinish")
      );
      dayOffDate =
        Math.floor(
          (form.getFieldValue("dateFinish") - form.getFieldValue("dateStart")) /
          (1000 * 3600 * 24)
        ) +
        1 -
        count;
      if (form.getFieldValue("dayOff")) {
        let result: any = subCategories.find(
          (element: any) => element?.categoryId === form.getFieldValue("dayOff")
        );
        switch (result?.code) {
          case OFF_MOR_CODE:
            dayOffDate /= 2;
            break;
          case OFF_AF_CODE:
            dayOffDate /= 2;
            break;
        }
      }
    }
    setDayOff(dayOffDate);
  };

  const countDayExcludeSaturdaySunday = (startDate: any, endDate: any) => {
    let start = new Date(startDate);
    let end = new Date(endDate);
    let dayMilliseconds = 1000 * 60 * 60 * 24;
    let count = 0;
    while (start.getTime() <= end.getTime()) {
      let day = start.getDay();
      // if (day === 0 || day === 6) {
      //     count++;
      // }
      start = new Date(+start + dayMilliseconds);
    }
    return count;
  };

  const handleCreateLeaveRequest = async (
    values: any,
    isPro: boolean | undefined
  ) => {
    let data: any = {
      requestCategoryId: Number(values.category),
      requestSubCategoryId: Number(values.dayOff),
      startDate: values.dateStart,
      endDate: values.dateFinish,
      note: values.comment ?? "",
    };
    if (typeModal === "update") data.requestId = requestId;

    if (!isPro) {
      if (typeModal === "update") {
        ConfirmModal({
          onOk: async () => {
            try {
              dispatch(setLoading(true));
              const response = await am022Service.updateLeave(data);
              dispatch(resetAM022());
              dispatch(setIsVisibleAM024(false));
              setDayOff(0);
              form.resetFields();
              SuccessNotification(
                response?.message ?? NOTIFICATION_TITLE.SUCCESS
              );
              dispatch(setRefreshAM010());
            } catch (error: any) {
              ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            } finally {
              dispatch(setLoading(false));
            }
          },
          className: "confirm__modal confirm__modal-purple-oke",
          title: MESSAGE.MESSAGE_TITLE_BEFORE_CREATE,
          description: MESSAGE.MESSAGE_DESCRIPTION_BEFORE_CREATE_LEAVE,
          canceText: LABEL_MESSAGE.CANCEL_MODAL,
          okText: LABEL_MESSAGE.OK_MODAL,
          isCenterWithoutMenu: true,
        });
      } else {
        ConfirmModal({
          onOk: async () => {
            try {
              dispatch(setLoading(true));
              const response = await am022Service.createLeaveRequest(data);
              dispatch(resetAM022());
              dispatch(setIsVisibleAM024(false));
              setDayOff(0);
              form.resetFields();
              SuccessNotification(
                response?.message ?? NOTIFICATION_TITLE.SUCCESS
              );
              dispatch(setRefreshAM010());
            } catch (error: any) {
              ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            } finally {
              dispatch(setLoading(false));
            }
          },
          className: "confirm__modal confirm__modal-purple-oke",
          title: MESSAGE.MESSAGE_TITLE_BEFORE_CREATE,
          description: MESSAGE.MESSAGE_DESCRIPTION_BEFORE_CREATE_LEAVE,
          canceText: LABEL_MESSAGE.CANCEL_MODAL,
          okText: LABEL_MESSAGE.OK_MODAL,
          isCenterWithoutMenu: true,
        });
      }
    } else {
      data.constructionId = Number(values.constructionName);
      if (typeModal === "update") {
        ConfirmModal({
          onOk: async () => {
            try {
              dispatch(setLoading(true));
              const response = await am022Service.updateLeave(data);
              dispatch(resetAM022());
              dispatch(setIsVisibleAM024(false));
              setDayOff(0);
              form.resetFields();
              SuccessNotification(
                response?.message ?? NOTIFICATION_TITLE.SUCCESS
              );
              dispatch(setRefreshAM010());
            } catch (error: any) {
              ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            } finally {
              dispatch(setLoading(false));
            }
          },
          className: "confirm__modal confirm__modal-purple-oke",
          title: MESSAGE.MESSAGE_TITLE_BEFORE_CREATE,
          description: MESSAGE.MESSAGE_DESCRIPTION_BEFORE_CREATE_LEAVE,
          canceText: LABEL_MESSAGE.CANCEL_MODAL,
          okText: LABEL_MESSAGE.OK_MODAL,
          isCenterWithoutMenu: true,
        });
      } else {
        ConfirmModal({
          onOk: async () => {
            try {
              dispatch(setLoading(true));
              const response = await am022Service.createLeaveRequest(data);
              dispatch(resetAM022());
              dispatch(setIsVisibleAM024(false));
              setDayOff(0);
              form.resetFields();
              SuccessNotification(
                response?.message ?? NOTIFICATION_TITLE.SUCCESS
              );
              dispatch(setRefreshAM010());
            } catch (error: any) {
              ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
            } finally {
              dispatch(setLoading(false));
            }
          },
          className: "confirm__modal confirm__modal-purple-oke",
          title: MESSAGE.MESSAGE_TITLE_BEFORE_CREATE,
          description: MESSAGE.MESSAGE_DESCRIPTION_BEFORE_CREATE_LEAVE,
          canceText: LABEL_MESSAGE.CANCEL_MODAL,
          okText: LABEL_MESSAGE.OK_MODAL,
          isCenterWithoutMenu: true,
        });
      }
    }
  };

  const handleChooseSecondDate = (value: any) => {
    if (form.getFieldValue("dateStart") && form.getFieldValue("dateFinish")) {
      handleCountDayOff();
    }
  };

  const handleGetRequestLeave = async (requestId: number): Promise<any> => {
    try {
      const params = {
        requestId,
      };
      const res = await am022Service.getRequestLeave(params);
      if (res?.results) setRequestLeave(res.results);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const handleGetProjectFirstOpenModal = async (params: any): Promise<any> => {
    try {
      const data = await getListProject(params);
      setProjects([...data]);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const handleGetConstructionFirstOpenModal = async (
    params: any
  ): Promise<any> => {
    try {
      const data = await getListConstruction(params);
      setConstructions([...data]);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const getListProject = async (params: {
    page: number;
    size: number;
    keyword?: string;
    statusId?: string;
  }): Promise<any> => {
    const paramsGet: any = { ...params };
    const response = await am022Service.getProjects(paramsGet);
    if (response?.results) {
      return response.results;
    }
  };

  const getListConstruction = async (params: {
    page: number;
    size: number;
    keyword?: string;
    projectId?: number;
  }): Promise<any> => {
    try {
      const paramsGet = { ...params, onlyInvolved: true };
      const res = await am022Service.getAttendanceUserConstruction(paramsGet);
      if (res?.results) {
        const data = formatConstructions(res.results);
        return data;
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const getProject = async (params: any, projectId: number): Promise<any> => {
    const res = await handleFetchApiGetAllProject(params, projectId);
    if (res) setProjects([...res]);
  };

  const handleFetchApiGetAllProject = async (
    params: any,
    projectId: number
  ): Promise<any> => {
    try {
      const res = await am022Service.getProjects(params);
      if (res?.results?.length > 0) {
        if (handleCheckExistProject(res.results, projectId)) return res.results;
        else {
          const param: any = {
            page: params.page + 1,
            size: params.size,
            keyword: params.keyword,
            statusId: params.statusId,
          };
          setPageOption(param.page);
          return res.results.concat(
            await handleFetchApiGetAllProject(param, projectId)
          );
        }
      } else return [];
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const handleFetchApiGetAllConstruction = async (
    params: any,
    constructionId: number
  ): Promise<any> => {
    try {
      const paramsGet = { ...params, onlyInvolved: true };
      const res = await am022Service.getAttendanceUserConstruction(paramsGet);
      if (res?.results.length > 0) {
        if (handleCheckExistConstruction(res.results, constructionId))
          return res.results;
        else {
          const param: any = {
            page: params.page + 1,
            size: params.size,
            keyword: params.keyword,
            statusId: params.statusId,
          };
          setPageOptionConstruction(param.page);
          return res.results.concat(
            await handleFetchApiGetAllConstruction(param, constructionId)
          );
        }
      } else return [];
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const handleCheckExistProject = (data: any, projectId: number) => {
    const res = data.find((item: any) => item?.id === projectId);
    if (res) {
      setCurrentProject(res);
      return true;
    } else return false;
  };

  const handleCheckExistConstruction = (data: any, constructionId: number) => {
    const res = data.find((item: any) => item?.id === constructionId);
    if (res) return true;
    else return false;
  };

  let timeOut: any = useRef();
  const handleSearchOption = (searchValue: string) => {
    if (timeOut.current) clearTimeout(timeOut.current);
    timeOut.current = setTimeout(async () => {
      try {
        setLoadingOption(true);
        setKeywordOption(searchValue);
        setPageOption(COMMON.DEFAULT_PAGE);
        setSizeOption(COMMON.DEFAULT_SIZE);
        const data = await getListProject({
          page: COMMON.DEFAULT_PAGE,
          size: COMMON.DEFAULT_SIZE,
          keyword: searchValue,
          statusId: projectStatus,
        });
        if (data) setProjects([...data]);
      } catch (error: any) {
        ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      } finally {
        setLoadingOption(false);
      }
    }, 1000);
  };

  const handleScrollProject = async (e: any): Promise<any> => {
    try {
      setLoadingOption(true);
      const target = e.target;
      if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
        setPageOption(pageOption + 1);
        const data = await getListProject({
          page: pageOption + 1,
          size: sizeOption,
          keyword: keywordOption ? keywordOption : undefined,
          statusId: projectStatus,
        });
        if (data) setProjects([...projects, ...data]);
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      setLoadingOption(false);
    }
  };

  const handleSearchOptionConstruction = (searchValue: string) => {
    if (!chosenProject) {
      if (timeOut.current) clearTimeout(timeOut.current);
      timeOut.current = setTimeout(async () => {
        if (!chosenProject) {
          try {
            setLoadingOption(true);
            setKeywordOptionConstruction(searchValue);
            setPageOptionConstruction(COMMON.DEFAULT_PAGE);
            setSizeOptionConstruction(COMMON.DEFAULT_SIZE);
            const data = await getListConstruction({
              page: COMMON.DEFAULT_PAGE,
              size: COMMON.DEFAULT_SIZE,
              keyword: searchValue,

            });
            if (data) setConstructions([...data]);
          } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
          } finally {
            setLoadingOption(false);
          }
        }
      }, 1000);
    } else {
      if (timeOut.current) clearTimeout(timeOut.current);
      timeOut.current = setTimeout(async () => {
        if (chosenProject) {
          try {
            setLoadingOption(true);
            setKeywordOptionConstruction(searchValue);
            setPageOptionConstructionChoosedProject(COMMON.DEFAULT_PAGE);

            const data = await getListConstruction({
              page: COMMON.DEFAULT_PAGE,
              size: COMMON.DEFAULT_SIZE,
              keyword: searchValue,
              projectId: projectIdChoosed
            });
            if (data) setConstructions([...data]);
          } catch (error: any) {
            ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
          } finally {
            setLoadingOption(false);
          }
        }
      }, 1000);
    }
  };

  const handleScrollConstruction = async (e: any): Promise<any> => {
    if (typeModal === "create" && !chosenProject) {
      try {
        setLoadingOption(true);
        const target = e.target;
        if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
          setPageOptionConstruction(pageOptionConstruction + 1);
          const data = await getListConstruction({
            page: pageOptionConstruction + 1,
            size: sizeOptionConstruction,
            keyword: keywordOptionConstruction
              ? keywordOptionConstruction
              : undefined,

          });
          if (data) setConstructions([...constructions, ...data]);
        }
      } catch (error: any) {
        ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      } finally {
        setLoadingOption(false);
      }
    } else {
      try {
        setLoadingOption(true);
        const target = e.target;
        if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
          setPageOptionConstructionChoosedProject(pageOptionConstructionChoosedProject + 1);
          const data = await getListConstruction({
            page: pageOptionConstructionChoosedProject + 1,
            size: sizeOptionConstruction,
            keyword: keywordOptionConstruction
              ? keywordOptionConstruction
              : undefined,
            projectId: projectIdChoosed

          });
          if (data) setConstructions([...constructions, ...data]);
        }
      } catch (error: any) {
        ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      } finally {
        setLoadingOption(false);
      }
    }
  };

  const handleFindConstructions = async (projectId: number): Promise<any> => {
    try {
      setChosenProject(true);
      setProjectIdChoosed(projectId)
      if (projects.length !== 0) {
        if (projects.length !== 0) {

          let param = {
            page: COMMON.DEFAULT_PAGE,
            size: COMMON.DEFAULT_SIZE,
            projectId
          }
          const response = await getListConstruction(param);
          setConstructions(response ?? []);
        }

      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const handleFindProjects = async (constructionId: number) => {
    if (typeModal === "create" && !chosenProject && constructions.length > 0) {
      const data = await handleFetchApiGetAllConstruction(
        {
          page: COMMON.DEFAULT_PAGE,
          size: COMMON.DEFAULT_SIZE,
          keyword: undefined,
          statusId: projectStatus,
        },
        constructionId
      );
      if (data) {
        const newData = formatConstructions(data);
        setConstructions([...newData]);
      }
      const goal: any = constructions.find(
        (element: any) => element.id === constructionId
      );
      if (goal?.projectId) {
        const data = await handleFetchApiGetAllProject(
          {
            page: COMMON.DEFAULT_PAGE,
            size: COMMON.DEFAULT_SIZE,
            keyword: undefined,
            statusId: projectStatus,
          },
          goal.projectId
        );
        if (data) setProjects([...data]);
        form.setFieldValue("projectName", goal.projectId);
      }
    }
  };

  const resetProjectWhenSelectOrBlur = async (
    projectId: number
  ): Promise<any> => {
    try {
      setKeywordOption(undefined);
      const data = await handleFetchApiGetAllProject(
        {
          page: COMMON.DEFAULT_PAGE,
          size: COMMON.DEFAULT_SIZE,
          keyword: undefined,
          statusId: projectStatus,
        },
        projectId
      );
      if (data) setProjects([...data]);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const resetConstructionWhenSelectOrBlur = async (
    constructionId: any
  ): Promise<any> => {
    if (chosenProject) {

      try {
        setLoadingOption(true);
        setKeywordOptionConstruction(undefined);
        setPageOptionConstructionChoosedProject(COMMON.DEFAULT_PAGE);

        const data = await getListConstruction({
          page: COMMON.DEFAULT_PAGE,
          size: COMMON.DEFAULT_SIZE,
          keyword: undefined,
          projectId: projectIdChoosed
        });
        if (data) setConstructions(data);
      } catch (error: any) {
        ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      } finally {
        setLoadingOption(false);
      }

    }

    else {
      try {
        setKeywordOptionConstruction(undefined);
        const data = await handleFetchApiGetAllConstruction(
          {
            page: COMMON.DEFAULT_PAGE,
            size: COMMON.DEFAULT_SIZE,
            keyword: undefined,
          },
          constructionId
        );
        if (data) setConstructions([...data]);
      } catch (error: any) {
        ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      }
    }
  };

  const handleFillFormUpdate = async (): Promise<any> => {
    form.setFieldValue("category", requestLeave?.category?.id);
    form.setFieldValue("dateStart", moment(requestLeave?.startDate));
    form.setFieldValue("dateFinish", moment(requestLeave?.endDate));
    form.setFieldValue("dayOff", requestLeave?.subCategory?.id);
    form.setFieldValue("projectName", requestLeave?.project?.id);
    form.setFieldValue("constructionName", requestLeave?.construction?.id);
    form.setFieldValue("authorizedPerson", requestLeave?.approver?.fullName);
    form.setFieldValue("comment", requestLeave?.note);
    handleCountDayOff();
    const response = await handleGetDetailProjectById(requestLeave.project.id);
    if (response?.constructions) setConstructions([...response.constructions]);
    setChosenProject(true);
  };

  const handleGetDetailProjectById = async (
    projectId: number
  ): Promise<any> => {
    try {
      const res = await am022Service.getProjectById({ projectId });
      if (
        res?.results?.id &&
        res?.results?.projectName &&
        res?.results?.constructions
      ) {
        const data = {
          id: res.results.id,
          projectName: res.results.projectName,
          constructions: res.results.constructions,
        };
        return data;
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const formatConstructions = (data: any) => {
    const arr: any = [];
    data.map((item: any) => {
      arr.push({
        id: item.id,
        name: item.description,
        projectId: item.projectId,
        projectName: item.projectName,
      });
    });
    return arr;
  };

  const getRequestApprover = async (): Promise<any> => {
    try {
      const res = await am022Service.getRequestApprover();
      if (res?.results) {
        setRequestApprover(res.results);
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const getProjectStatus = async (): Promise<any> => {
    try {
      const params = {
        page: COMMON.DEFAULT_PAGE,
        size: COMMON.DEFAULT_SIZE,
      };
      const res = await am022Service.getProjectStatus(params);
      if (res?.results) {
        res.results.forEach((item: any) => {
          if (item.name === STATUS.UNDER_CONSTRUCTION)
            setProjectStatus(item.id);
        });
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const resetState = () => {
    form.resetFields();
    setIsDirty(DEFAULT_STATE.isDirty);
    dispatch(resetAM022());
  };

  const onCancel = () => {
    if (!isDirty) {
      resetState();
      return;
    }
    ConfirmModal({
      onOk: () => {
        setDayOff(0);
        resetState();
      },
      className: "confirm__modal",
      title: MESSAGE.MESSAGE_020,
      description: MESSAGE.MESSAGE_022_1,
      extraDescription: MESSAGE.MESSAGE_022_2,
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_MODAL,
      isCenterWithoutMenu: true,
    });
  };

  const checkDirty = (): void => {
    const formValue = form.getFieldsValue();
    const formProps = Object.keys(formValue);
    for (let i = 0; i < formProps.length; i++) {
      if (form.isFieldTouched(formProps[i])) {
        return setIsDirty(true);
      }
    }
    setIsDirty(false);
  };

  return {
    form,
    isDirty,
    categories,
    subCategories,
    numberOfDayOff,
    requestLeave,
    loadingOption,
    projects,
    constructions,
    chosenProject,
    requestApprover,
    projectStatus,
    onCancel,
    checkDirty,
    getListCategoryLeave,
    getListSubCategoryLeave,
    handleDayOffDefaultValue,
    handleDisableDate,
    handleCountDayOff,
    handleCreateLeaveRequest,
    handleChooseSecondDate,
    handleGetRequestLeave,
    handleFindConstructions,
    handleFindProjects,
    getListProject,
    handleSearchOption,
    handleScrollProject,
    handleSearchOptionConstruction,
    handleScrollConstruction,
    handleFillFormUpdate,
    getRequestApprover,
    getProjectStatus,
    getProject,
    resetProjectWhenSelectOrBlur,
    resetConstructionWhenSelectOrBlur,
    handleGetProjectFirstOpenModal,
    handleGetConstructionFirstOpenModal,
  };
};

export default AM022Handler;
