import { NOTIFICATION_TITLE } from "./../../../../../common/constants/MESSAGE";
import {
  CertificateEntity,
  MemberCertificateEntity,
} from "./../../entity/Entity";
import { RootState } from "./../../../../../store";
import { useState } from "react";
import { Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import { refreshMemberOM002 } from "../../../OM002/presenter/slice/Slice";
import { OM004UseCase } from "../../usecase/ServiceImpl";
import {
  setLoading,
  resetOM004,
  toggleIsHasCreatedOM004,
} from "../slice/Slice";
import { MemberEntity } from "../../entity/Entity";
import ConfirmModal from "../../../../../common/components/modal/ConfirmModal";
import MESSAGE, {
  LABEL_MESSAGE,
} from "../../../../../common/constants/MESSAGE";

const DEFAULT_STATE = {
  isDirty: false,
  certType: [],
  fileData: undefined,
};

const OM004Handler = (om004Service: OM004UseCase) => {
  // LIB FUNCTION
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  // STATE
  const [isDirty, setIsDirty] = useState(DEFAULT_STATE.isDirty);
  const [certType, setCertType] = useState<CertificateEntity[]>(
    DEFAULT_STATE.certType
  );
  const [fileData, setFileData] = useState<any>();

  // FUNCTION

  const getGroups = async () => {
    dispatch(setLoading(true));
    try {
      const response = await om004Service.getGroup();
      if (response?.results?.groups?.length > 0) {
        return {
          results: response?.results?.groups,
          pagination: {
            count: response?.results?.groups?.length,
          },
        };
      }
      return {
        results: response?.results?.groups,
        pagination: {
          count: response?.results?.groups?.length,
        },
      };
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getAvatarBinary = (value: any) => {
    setFileData(value);
  };

  const getCertificateType = async () => {
    try {
      dispatch(setLoading(true));
      const certificateTypes = await om004Service.getCertificateType();
      setCertType(certificateTypes);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getRoleList = async (params: any) => {
    const response = await om004Service.getRoleList(params);
    return {
      results: response.results,
      pagination: response.pagination,
    };
  };

  const getBranchList = async (params: any) => {
    return await om004Service.getBranchList(params);
  };

  const createNewMember = async (formData: any) => {
    try {
      dispatch(setLoading(true));
      // avatar
      const avatarFormValueArr = formData?.avatar ?? [];
      const avatarFileName =
        avatarFormValueArr.length === 0
          ? undefined
          : avatarFormValueArr[avatarFormValueArr.length - 1];

      // certificates
      const memberCertificates: MemberCertificateEntity[] = [];
      const formProperties = Object.keys(formData);
      formProperties.forEach((element: string) => {
        if (element.startsWith("cert") && formData[`${element}`].name) {
          memberCertificates.push({
            categoryId: parseInt(formData[`${element}`].certTypeId),
            expireDate: formData[`${element}`].expireDate
              ? formData[`${element}`].expireDate.toISOString()
              : "",
            id: parseInt(formData[`${element}`].certId),
            issueDate: formData[`${element}`].issueDate
              ? formData[`${element}`].issueDate.toISOString()
              : "",
            issuer: formData[`${element}`].issuer ?? "",
            registrationCode: formData[`${element}`].registrationCode ?? "",
            name: formData[`${element}`].name ?? "",
          });
        }
      });

      // member info
      const data: MemberEntity = {
        avatar: avatarFileName?.name ?? undefined,
        certificates: memberCertificates,
        email: formData.email,
        fullName: formData.name,
        username: formData.id,
        phoneNumber: formData.phoneNumber.replaceAll("-", ""),
        roleId: parseInt(formData.roleId),
        address: formData.address,
      };
      if (formData.branchId) data.groupId = parseInt(formData.branchId);
      if (formData.CCUSID) {
        data.ccusId = formData.CCUSID.replaceAll(" ", "");
        if (formData.expirationDate)
          data.ccusExpiredDate = formData.expirationDate.toISOString();
      }

      const response = await om004Service.createNewMember(data);
      if (formData.avatar) {
        // Nếu người dùng nhập avatar
        const preSignResult = await om004Service.getPresignAvatarUpload({
          avatar: avatarFileName.name,
          userId: response.results.id,
        });
        await om004Service.uploadAvatar(
          fileData,
          preSignResult.results,
          fileData?.type
        );
        await om004Service.updateMemberAvatar({
          avatar: fileData?.name ?? "",
          userId: response.results.id,
        });
      }
      resetState();
      SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const resetState = () => {
    form.resetFields();
    setIsDirty(DEFAULT_STATE.isDirty);
    setFileData(DEFAULT_STATE.fileData);
    setCertType(DEFAULT_STATE.certType);
    dispatch(resetOM004());
  };

  const onCancel = () => {
    if (!isDirty) {
      resetState();
      return;
    }
    ConfirmModal({
      onOk: () => resetState(),
      className: "confirm__modal",
      title: MESSAGE.MESSAGE_020,
      description: MESSAGE.MESSAGE_022_1,
      extraDescription: MESSAGE.MESSAGE_022_2,
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_MODAL,
      isCenterWithoutMenu: true,
    });
  };

  const checkDirty = (): void => {
    const formValue = form.getFieldsValue();
    const formProps = Object.keys(formValue);
    for (let i = 0; i < formProps.length; i++) {
      if (form.isFieldTouched(formProps[i])) {
        return setIsDirty(true);
      }
    }
    setIsDirty(false);
  };

  const onExpirationDateChange = (value: string) => {
    const ccusid = form.getFieldValue("CCUSID");
    if (!ccusid && !value)
      return form.setFields([
        {
          name: "CCUSID",
          errors: [],
        },
      ]);

    if (!ccusid)
      return form.setFields([
        {
          name: "CCUSID",
          errors: [MESSAGE.MESSAGE_LESS_THAN_14],
        },
      ]);
  };
  return {
    getBranchList,
    getRoleList,
    createNewMember,
    onCancel,
    checkDirty,
    getCertificateType,
    getAvatarBinary,
    onExpirationDateChange,
    getGroups,
    form,
    isDirty,
    certType,
  };
};

export default OM004Handler;
