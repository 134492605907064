import React from 'react';
import CM0052Api from './infrastructure/api/Api';
import CM0052Handler from './presenter/handler/Handler';
import CM0052Service from './usecase/Service';
import MainUI from './presenter/ui/UI';

const CM052Component = () => {
    const api = CM0052Api();
    const service = CM0052Service(api);
    const handler = CM0052Handler(service);
    return <MainUI handler={handler} />;
};

export default CM052Component;
