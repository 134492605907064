import IconPlus from "../../../../../assets/icons/fluent_add-16-filled.svg";
import { PM014ContentHandler } from "../handler/HandlerImpl";
import AvatarDefault from "../../../../../assets/icons/avatar-default.jpg";
import { useParams } from "react-router-dom";
import { useMemo } from "react";
import { isHasPrivilege } from "../../../../../common/helpers/privilege";
import {
  PRIVILEGE_CREATOR,
  PRIVILEGE_MEMBER,
  PRIVILEGE_PM,
  PRIVILEGE_SUB_PM,
} from "../../../../../common/constants/PRIVILEGE";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { SYSTEM_ROLES } from "../../../../../common/constants/AUTHORIZATION";
import { DefaultAvatarComponent } from "../../../../../common/components/default-avatar";

const MAX_IMG = 8;

interface props {
  handler: PM014ContentHandler;
}

function AvatarComponent({ handler }: props) {
  const { projectId } = useParams();
  const isRoleAdmin = [
    SYSTEM_ROLES.ADMIN || SYSTEM_ROLES.ADMIN_SYSTEM,
  ].includes(
    useSelector((state: RootState) => state.auth.authData?.role?.code) ?? ""
  );
  const members = useMemo(() => {
    if (!handler.projectInfo?.members)
      return {
        dataSource: [],
        count: 0,
      };
    const listMember = [...handler.projectInfo?.members];
    return {
      dataSource: listMember,
      count: handler?.projectInfo.totalMember - MAX_IMG,
    };
  }, [handler?.projectInfo]);

  return (
    <div className="avatar-list">
      {members.dataSource?.map((element: any, index: number) => {
        if (index < MAX_IMG) {
          return (
            <div className="avatar-item" key={element.id}>
              <DefaultAvatarComponent
                isAdmin={[
                  SYSTEM_ROLES.ADMIN,
                  SYSTEM_ROLES.ADMIN_SYSTEM,
                ].includes(element.roleCode ?? "")}
                avatar={element?.avatar ?? undefined}
                value={element?.fullName ?? ""}
                size={54}
              />
              {index === MAX_IMG - 1 &&
              members?.dataSource?.length > MAX_IMG ? (
                <div className="overlap-img">+{members.count}</div>
              ) : null}
            </div>
          );
        }
        return null;
      })}
      {isHasPrivilege(handler.projectInfo, [
        PRIVILEGE_PM,
        PRIVILEGE_SUB_PM,
        PRIVILEGE_MEMBER,
        PRIVILEGE_CREATOR,
      ]) || isRoleAdmin ? (
        <div
          className="avatar-btn"
          onClick={() => handler.handleViewAddMember({ id: projectId })}
        >
          <img alt="" src={IconPlus} />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default AvatarComponent;
