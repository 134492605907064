import { createSlice } from '@reduxjs/toolkit';

type H2ModalSlice = {
    isOpenH2: boolean;
};
const initialState: H2ModalSlice = {
    isOpenH2: false,
};

export const H2ModalSlice = createSlice({
    name: 'H2ModalSlice',
    initialState,
    reducers: {
        showH2Modal: (state) => {
            state.isOpenH2 = true;
        },
        hideH2Modal: (state) => {
            state.isOpenH2 = false;
        },
    },
});

export const { showH2Modal, hideH2Modal } = H2ModalSlice.actions;
export default H2ModalSlice.reducer;
