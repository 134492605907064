import { CommonPutDeletePostResponse } from "../../../CM/CM007/entity/Entity";
import {
  ScheduleCategory,
  ScheduleCategoryColors,
  ScheduleCategoryUpdate,
} from "../entity/Entity";
import { APIImpl } from "./ServiceImpl";

const Service = (api: APIImpl) => {
  const putScheduleCategoryUpdate = async (
    data: ScheduleCategoryUpdate
  ): Promise<CommonPutDeletePostResponse> => {
    const response = await api.putScheduleCategoryUpdate(data);
    return response.data;
  };

  const getScheduleCategoryList = async (): Promise<ScheduleCategory[]> => {
    const response = await api.getScheduleCategoryList();
    return response.data?.results ?? [];
  };

  const getScheduleCategoryColors = async (): Promise<
    ScheduleCategoryColors[]
  > => {
    const response = await api.getScheduleCategoryColors();
    return response.data?.results ?? [];
  };
  return {
    putScheduleCategoryUpdate,
    getScheduleCategoryList,
    getScheduleCategoryColors,
  };
};

export default Service;
