import axiosConfig from "../../../../../config/axiosConfig";

export const API = () => {
  const getProjectCRApprovalProcess = async (params: { versionId: number }) => {
    const url = "/project/cr-approval-process";
    return await axiosConfig.get(url, { params });
  };
  return {
    getProjectCRApprovalProcess,
  };
};
