import axiosConfig from "../../../../../config/axiosConfig";

const ProjectApi = () => {
  const getListProject = async (params: any): Promise<any> => {
    try {
      const url = "/project/list-by-role";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  return {
    getListProject,
  };
};

export default ProjectApi;
