import { AxiosResponse } from 'axios';
import axiosConfig from '../../../../../config/axiosConfig';

const CM0039Api = () => {
    const getReSummaryCompanyDocumentOutSourceCostSubmitedList = async (params: {
        statusId?: number;
        page?: number;
        size?: number;
        sortBy?: string;
        sortType?: string;
        from?: string;
        to?: string;
        keyword?: string;
    }): Promise<AxiosResponse<any, any>> => {
        const url = '/re-summary/company/document/outsource-cost/submited/list';
        return await axiosConfig.get(url, { params });
    };
    return { getReSummaryCompanyDocumentOutSourceCostSubmitedList };
};

export default CM0039Api;
