import axiosConfig from '../../../../../config/axiosConfig';
import { RequestUploadCCUSCompany } from '../../entity/Entity';

const CCUS01Api = () => {
  const uploadCCUSCompany = async (data: RequestUploadCCUSCompany): Promise<any> => {
    try {
      const url = "/ccus/company/validate";
      const response = await axiosConfig.post(url, data, {
        timeout: 40000,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getStateCCUSCompany = async (): Promise<any> => {
    try {
      const url = "/ccus/company/information";
      const response = await axiosConfig.get(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  return {
    uploadCCUSCompany,
    getStateCCUSCompany,
  };
};

export default CCUS01Api;
