import React, { useEffect } from "react";
import { HandlerImpl } from "../handler/HandlerImpl";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { PLAN_TYPE } from "../../../../common/constants/COMMON";

interface Props {
  handler: HandlerImpl;
}

export const MainUI = ({ handler }: Props) => {
  const user = useSelector((state: RootState) => state.auth.authData);

  const renderConditions = () => {
    if (!user) return <Navigate to={"/app-login"} replace />;
    else if (user && user.isFirstLogin)
      return <Navigate to={"/app-change-password"} replace />;
    else return <Outlet />;
  };

  return renderConditions();
};
