// Built-in
import React, { useEffect } from "react";

// Source files
import MaterialCostComponent from "../component/material-cost";
import { HandlerImpl } from "../handler/HandlerImpl";

// Image files
import ImageViewerModalComponent from "../../../../../common/components/image-viewer-modal/ImageViewerComponent";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import COMMON from "../../../../../common/constants/COMMON";
import PdfViewerModalComponent from "../../../../../common/components/pdf-viewer-modal/PdfViewerModalComponent";
interface props {
  handler: HandlerImpl;
}

const MainUI = ({ handler }: props) => {
  const evidenceLink = useSelector(
    (state: RootState) => state.cm007.evidenceLink
  );
  const isOpenImageViewer = useSelector(
    (state: RootState) => state.imageViewerModal.isOpenImageViewer
  );
  const evidenceName = useSelector(
    (state: RootState) => state.cm005.evidenceName
  );
  useEffect(() => {
    return () => {
      handler.resetRedux();
    };
  }, []);

  return (
    <>
      <MaterialCostComponent />
      <ImageViewerModalComponent
        imageURL={evidenceLink}
        title={evidenceName}
      />
      <PdfViewerModalComponent
        pdfURL={evidenceLink}
        title={evidenceName}
      />
    </>
  );
};

export default MainUI;
