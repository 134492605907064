import { Form, InputNumber } from "antd";
import { memo } from "react";

import { CR001AmountRelateHandler } from "../handler/HandlerImpl";
import COMMON from "../../../../../common/constants/COMMON";

const { Item } = Form;

interface props {
  handler: CR001AmountRelateHandler;
}

const MESSAGE_REQUIRED = "";
export const MIN_INPUT_NUMBER = 0;
const REQUIRED_INPUT = false;

const Estimate = memo(({ handler }: props) => {
  return (
    <div className="cr001-contract-amount-content cr001-icon-rotate">
      <div className="cr001-contract-amount-content-label">
        <div className="cr001-contract-amount-content-label-left">
          見積金額（円）
        </div>
        <div className="cr001-contract-amount-content-label-right">
          契約金額（円）
        </div>
      </div>
      <div className="cr001-contract-amount-content-item">
        <div className="cr001-contract-amount-content-item-left color-text">
          {handler.isEdit ? (
            <Item
              rules={[
                {
                  required: REQUIRED_INPUT,
                  message: MESSAGE_REQUIRED,
                },
              ]}
              name={["estimateContractMoney", "estimateMoney"]}
            >
              <InputNumber
                controls={false}
                maxLength={COMMON.MAX_INPUT_NUMBER}
                max={COMMON.MAX_NUMBER_VALUE}
                min={MIN_INPUT_NUMBER}
                className="input-width-200"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
              />
            </Item>
          ) : (
            handler.checkFormatValue(
              handler.estimateContractMoney?.estimateMoney,
              false,
              true
            )
          )}
        </div>
        <div className="cr001-contract-amount-content-item-right color-text">
          {handler.isEdit ? (
            <Item
              rules={[
                {
                  required: REQUIRED_INPUT,
                  message: MESSAGE_REQUIRED,
                },
              ]}
              name={["estimateContractMoney", "contractMoney"]}
            >
              <InputNumber
                controls={false}
                maxLength={COMMON.MAX_INPUT_NUMBER}
                max={COMMON.MAX_NUMBER_VALUE}
                min={MIN_INPUT_NUMBER}
                className="input-width-200"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
              />
            </Item>
          ) : (
            handler.checkFormatValue(
              handler.estimateContractMoney?.contractMoney,
              false,
              true
            )
          )}
        </div>
      </div>
      <div className="cr001-contract-amount-content-label">
        <div className="cr001-contract-amount-content-label-left">
          人工単価（円）原価
        </div>
        <div className="cr001-contract-amount-content-label-right">
          人工単価（円）見積
        </div>
      </div>
      <div className="cr001-contract-amount-content-item">
        <div className="cr001-contract-amount-content-item-left color-text">
          {handler.isEdit ? (
            <Item
              rules={[
                {
                  required: REQUIRED_INPUT,
                  message: MESSAGE_REQUIRED,
                },
              ]}
              name={["estimateContractMoney", "unitPersonCost"]}
            >
              <InputNumber
                controls={false}
                maxLength={COMMON.MAX_INPUT_NUMBER}
                max={COMMON.MAX_NUMBER_VALUE}
                min={MIN_INPUT_NUMBER}
                className="input-width-200"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
              />
            </Item>
          ) : (
            handler.checkFormatValue(
              handler.estimateContractMoney?.unitPersonCost,
              false,
              true
            )
          )}
        </div>
        <div className="cr001-contract-amount-content-item-right color-text">
          {handler.isEdit ? (
            <Item
              rules={[
                {
                  required: REQUIRED_INPUT,
                  message: MESSAGE_REQUIRED,
                },
              ]}
              name={["estimateContractMoney", "estimateLaborCost"]}
            >
              <InputNumber
                controls={false}
                maxLength={COMMON.MAX_INPUT_NUMBER}
                max={COMMON.MAX_NUMBER_VALUE}
                min={MIN_INPUT_NUMBER}
                className="input-width-200"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
              />
            </Item>
          ) : (
            handler.checkFormatValue(
              handler.estimateContractMoney?.estimateLaborCost,
              false,
              true
            )
          )}
        </div>
      </div>
    </div>
  );
});

export default Estimate;
