import React from "react";
import CI001Api from "./infrastructure/api/Api";
import CI001Service from "./usecase/Service";
import CI001Handler from "./presenter/handler/Handler";
import CI001UI from "./presenter/ui/CI001UI";

function CI001Component() {
  const ci001Api = CI001Api();
  const ci001Service = CI001Service(ci001Api);
  const ci001Handler = CI001Handler(ci001Service);
  return <CI001UI handler={ci001Handler} />;
}

export default CI001Component;
