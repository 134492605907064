import { Button, Form, Modal, Spin } from "antd";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import CloseIconModal from "../../../../../assets/icons/close.png";
import EmptyDataSVG from "../../../../../assets/icons/empty-data.svg";
import { default as XIconModal } from "../../../../../assets/icons/x-icon-modal.svg";
import "../../../../../assets/styles/CM/CM0017_3_1.css";
import LoadingComponent from "../../../../../common/components/icons/LoadingComponent";
import helpers from "../../../../../common/helpers/common";
import { RootState } from "../../../../../store";
import { CM0017_3_1Hanlder } from "../handler/HandlerImpl";
import { HeaderData, ParentCode } from "../type/Presenter";

type Props = {
  handler: CM0017_3_1Hanlder;
};

const CM0017_3_1UI = ({ handler }: Props) => {
  const formRef: any = useRef();
  const isVisible = useSelector(
    (state: RootState) => state.cm0017_3_1.isVisible
  );
  const loading = useSelector((state: RootState) => state.cm0017_3_1.loading);
  const parentCode: ParentCode = useSelector(
    (state: RootState) => state.cm0017_3_1.parentCode
  );
  const headerData: HeaderData = useSelector(
    (state: RootState) => state.cm0017_3_1.headerData
  );
  const title = useSelector((state: RootState) => state.cm0017_3_1.title);

  useEffect(() => {
    if (isVisible) {
      parentCode?.documentOutsourceCostDataInformationSummaryId &&
        parentCode?.categoryId &&
        handler.getReSummarySubmittedOutsourceCostOffsetList(
          parentCode.documentOutsourceCostDataInformationSummaryId,
          parentCode.categoryId
        );
    }
  }, [
    isVisible,
    parentCode?.documentOutsourceCostDataInformationSummaryId,
    parentCode?.categoryId,
  ]);

  return (
    <>
      <Modal
        className="group-detail-modal"
        style={{ marginLeft: 300, marginTop: 40, marginBottom: 40 }}
        visible={true}
        closable={false}
        maskClosable={false}
        width={"1027px"}
        centered={true}
        closeIcon={<img src={CloseIconModal} alt="close icon" />}
        footer={
          <Button
            disabled={loading}
            size="large"
            type="primary"
            style={{ marginLeft: 16, width: 92, letterSpacing: -1.5 }}
            onClick={handler.onCancel}
          >
            閉じる
          </Button>
        }
      >
        <Spin indicator={LoadingComponent} spinning={loading}>
          <div
            className="w-100-percent header-modal-containter"
            style={{ height: 50 }}
          >
            <div className="header-modal-title-cm0017_3_1">
              {title ? title : "相殺金額"}
            </div>
            <div style={{ cursor: "pointer" }} onClick={handler.onCancel}>
              <img alt="" src={XIconModal} style={{ height: 18, width: 18 }} />
            </div>
          </div>
          <div
            style={{ minHeight: 224, marginTop: 24 }}
            className="cm0017_3_1-content"
          >
            <div className="content-description">
              <div className="content-description-text">
                <div className="description-col">
                  <label> 業者名</label>
                  <p>{headerData?.traderName}</p>
                </div>
                <div className="description-col">
                  <label> 契約工事名</label>
                  <p>{headerData?.contractWorkName}</p>
                </div>
                <div className="description-col">
                  <label> 対象期間</label>
                  <p>{headerData?.targetPeriod}</p>
                </div>
              </div>
            </div>
            <div className="content-table">
              <div
                className="table-header table-row"
                style={{ paddingRight: "7px" }}
              >
                <div className="cell cell-order">No</div>
                <div className="cell cell-item">項目</div>
                <div className="cell cell-taxCategoryComsumption">
                  消費税区分
                </div>
                <div className="cell cell-offsetAmount">相殺金額 (税抜)</div>
              </div>
              <div ref={formRef} className="form-cm0017_3_1">
                <Form
                  form={handler.form}
                  id="formCM0017_3_1"
                  style={{
                    height: "100%",
                  }}
                >
                  {handler?.reSummarySubmittedOutsourceCostOffsetListLocal?.map(
                    (item, index) => {
                      return (
                        <div className="table-row" key={item.key}>
                          <div className="cell cell-order">{index + 1}</div>
                          <div className="cell cell-item">{item.item}</div>
                          <div className="cell cell-taxCategoryComsumption">
                            {item.taxCategoryComsumptionName}
                          </div>
                          <div className="cell cell-offsetAmount">
                            {item.offsetAmount &&
                              helpers.formatCurrency(item.offsetAmount)}
                            円
                          </div>
                        </div>
                      );
                    }
                  )}
                  {handler?.reSummarySubmittedOutsourceCostOffsetListLocal
                    ?.length === 0 && (
                    <div className="empty-data">
                      <img src={EmptyDataSVG} alt="" />
                    </div>
                  )}
                </Form>
              </div>
              <div className="table-row table-row-blue">
                <div className="cell cell-order">合計金額</div>
                <div className="cell cell-item"></div>
                <div className="cell cell-taxCategoryComsumption"></div>
                <div className="cell cell-offsetAmount">{`${helpers.formatCurrency(
                  handler.totalCost
                )}円`}</div>
              </div>
            </div>
          </div>
        </Spin>
      </Modal>
    </>
  );
};

export default CM0017_3_1UI;
