import API from './infrastructure/api/Api';
import Handler from './presenter/handler/Handler';
import MainUI from './presenter/ui/UI';
import Service from './usecase/Service';
import '../../../assets/styles/ORD/ORD007.css';

const ORD007Component = () => {
    const serverAPI = API();
    const service = Service(serverAPI);
    const handler = Handler(service);
    return <MainUI handler={handler} />;
};

export default ORD007Component;
