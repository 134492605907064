import { useDispatch } from "react-redux";
import { useState } from "react";
import { hideImageViewerModal, showImageViewerModal } from "./ImageViewSlice";
export const ZOOM = [0.3, 0.5, 0.8, 1, 1.3, 1.5, 1.8, 2];
export const DEFAULT_SIZE = 80;

const useImageViewer = () => {
  const dispatch = useDispatch();

  const [zoom, setZoom] = useState<number>(3);
  const [typeZoom, setTypeZoom] = useState<number>(100);
  const [imgDimension, setImgDimension] = useState<any>({
    height: 0,
    width: 0,
  });

  const handleZoomIn = () => {
    if (zoom < 7) {
      setZoom(zoom + 1);
      setTypeZoom(ZOOM[zoom + 1] * 100);
    }
  };
  const handleZoomOut = () => {
    if (zoom > 0) {
      setZoom(zoom - 1);
      setTypeZoom(ZOOM[zoom - 1] * 100);
    }
  };

  const onHideImageViewerModal = () => dispatch(hideImageViewerModal());
  const onShowImageViewerModal = () => dispatch(showImageViewerModal());

  return {
    handleZoomOut,
    handleZoomIn,
    onHideImageViewerModal,
    onShowImageViewerModal,
    setImgDimension,

    typeZoom,
    zoom,
    imgDimension,
  };
};

export default useImageViewer;
