import { Calendar, Empty, Select } from 'antd';

// Image
import LeftArrow from '../../../../../assets/icons/SM01-left-arrow.svg';
import RightArrow from '../../../../../assets/icons/SM01-right-arrow.svg';
import SearchIcon from '../../../../../assets/icons/SM01-search.svg';
import DeleteIcon from '../../../../../assets/icons/SM01-delete.svg';

// CSS
import '../../../../../assets/styles/SM/SM01.css';

import { SM01HandlerImpl } from '../handler/HandlerImpl';
import COMMON from '../../../../../common/constants/COMMON';
import { useSearchParams } from 'react-router-dom';
import { PARAMS_KEY } from '../handler/Handler';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/ja_JP';
import { TagEntity } from '../../entity/Entity';

type props = {
    handler: SM01HandlerImpl;
};

const CalendarFilter = ({ handler }: props) => {
    const [searchParams] = useSearchParams();
    const filterDateCalendar =
        searchParams.get(PARAMS_KEY.FILTER_DATE_CALENDAR) ??
        `${moment().format(COMMON.FORMAT_DATE2)}`;

    return (
        <div className="sm01-nav">
            <div className="calendar-filter-container-custom">
                <Calendar
                    locale={locale}
                    value={moment(filterDateCalendar)}
                    headerRender={({ value }) => {
                        return (
                            <div className="sm01-header">
                                <img
                                    src={LeftArrow}
                                    alt="left-arrow"
                                    onClick={() => handler.prevMonth()}
                                />
                                <div className="text" style={{ width: 'fit-content' }}>
                                    {moment(value).format(COMMON.FORMAT_DATE_JA_MONTH)}
                                </div>
                                <img
                                    src={RightArrow}
                                    alt="right-arrow"
                                    onClick={() => handler.nextMonth()}
                                />
                            </div>
                        );
                    }}
                    fullscreen={false}
                    onSelect={handler.onChangeDate}
                />
            </div>
            <div className="sm01-tags">
                {handler.tags.map((item: TagEntity, idx: number) => (
                    <div className="sm01-tags__item" key={idx}>
                        <div className="sm01-tags__item-name">{item.text}</div>
                        <img
                            src={DeleteIcon}
                            alt="delete-icon"
                            onClick={() => handler.deleteMemberTag(item.id)}
                        />
                    </div>
                ))}
            </div>
            <div className="sm01-search">
                <Select
                    className="select-search-sm01"
                    placeholder="名前を入力"    // CR #344212
                    mode="multiple"
                    size="large"
                    showSearch
                    showArrow
                    value={handler.valueMember}
                    listHeight={210}
                    filterOption={() => true}
                    loading={handler.loadingOption}
                    onSearch={handler.handleSearchOption}
                    onDropdownVisibleChange={(open) => {
                        if (open) handler.handleGetMember();
                    }}
                    getPopupContainer={(trigger: any) => trigger.parentNode}
                    notFoundContent={
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description="該当するデータがありません"
                        />
                    }
                    onChange={(e: any) => {
                        handler.setValueMember(e);
                    }}
                    suffixIcon={<img src={SearchIcon} alt="search-icon" />}
                >
                    {handler.memberDeputyProjectManager.map((select) => {
                        return (
                            <Select.Option
                                style={{ alignItems: 'center' }}
                                key={select.id + Math.random()}
                                value={select.id}
                            >
                                <div
                                    style={{
                                        fontSize: 14,
                                        fontWeight: 600,
                                        color: '#121212',
                                    }}
                                >
                                    {select.fullName || select.username}
                                </div>
                                <span
                                    style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: '#666',
                                    }}
                                >
                                    {select.emailAddress}
                                </span>
                            </Select.Option>
                        );
                    })}
                </Select>
            </div>
        </div>

    );
};

export default CalendarFilter;
