import { Col, Form, InputNumber, Row } from 'antd';
import helpers from '../../../../../common/helpers/common';
import COMMON from '../../../../../common/constants/COMMON';
import { InputNumberComponent } from './InputNumber';

type props = {
    isEditing: boolean;
    basicPlanCost?: number;
};

export const BasicPlanComponent = ({ isEditing, basicPlanCost }: props) => {
    return (
        <>
            <section className="ord007-header-style">ベーシックプラン</section>
            <section style={{ width: '100%', display: 'flex' }}>
                <div className="center-item ord007-cell-container ord007-border-left half-width">
                    基本料金
                </div>
                <div className="center-item ord007-cell-container half-width">
                    {isEditing ? (
                        <>
                            <InputNumberComponent
                                name="basicPlanCost"
                                max={99999}
                                isRequired={true}
                            />
                            <div style={{ color: '#666666', marginLeft: 8 }}>円</div>
                        </>
                    ) : (
                        `${basicPlanCost ? helpers.formatCurrency(basicPlanCost) + '円' : ''}`
                    )}
                </div>
            </section>
        </>
    );
};
