import { AutoComplete, Form, Select } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setContructionList } from "../../../slice/Slice";

interface Props {
  onConstructionChoose: React.ChangeEventHandler<HTMLInputElement>;
  onProjectChoose: React.ChangeEventHandler<HTMLInputElement>;
  onScroll: (event: any) => Promise<void>;
  onSearchDropDown: () => void;
  loading: boolean;
  project: any[];
  construction: any[];
  searchDropDownValue: string;
  style?: any;
}
const { Option } = Select;
const ProjectDropDownComponent = ({
  onProjectChoose,
  onConstructionChoose,
  onScroll,
  onSearchDropDown,
  loading,
  project,
  construction,
  searchDropDownValue,
  style = {},
}: Props) => {
  const dispatch = useDispatch();
  const toHalfWidth = (str: string): string => {
    return str
      .replace(/[\uff01-\uff5e]/g, (ch) =>
        String.fromCharCode(ch.charCodeAt(0) - 0xfee0)
      )
      .replace(/　/g, " ");
  };
  const handleSearch = (value: any) => {
    const normalizedInputValue = toHalfWidth(value.trim().toLowerCase());

    const filteredOptions = construction.filter((element) => {
      const normalizedLabel = toHalfWidth(element.name.trim().toLowerCase());
      const normalizedValue = toHalfWidth(
        element.id.toString().trim().toLowerCase()
      );

      return (
        normalizedLabel.includes(normalizedInputValue) ||
        normalizedValue.includes(normalizedInputValue)
      );
    });

    dispatch(setContructionList(filteredOptions.length === 0));
  };
  return (
    <main style={{ paddingBottom: 0, paddingTop: 0, ...style }}>
      <label className="filter-label">案件名</label>
      <div
        className="searchbar-container"
        style={{ marginBottom: 24, marginTop: 8 }}
      >
        <Form.Item
          name="project"
          style={{ margin: 0, padding: 0, width: "100%" }}
          labelAlign="right"
        >
          <AutoComplete
            placeholder=""
            allowClear={true}
            getPopupContainer={(trigger: any) => trigger.parentNode}
            // autoClearSearchValue={false}
            onChange={onSearchDropDown}
            size="large"
            onSelect={async (value: any, option: any) => {
              await onProjectChoose(option);
            }}
            className="search-bar-om001  "
            onPopupScroll={onScroll}
            showArrow={true}
            options={project.map((element) => ({
              key: element.id,
              value: element.id + "",
              label: element.projectName,
              ...element,
            }))}
            notFoundContent={
              <div style={{ width: "100%", textAlign: "center" }}>
                データなし
              </div>
            }
          ></AutoComplete>
        </Form.Item>
      </div>
      <label className="filter-label">工事名</label>
      <div
        className="searchbar-container"
        style={{ marginBottom: 0, marginTop: 8 }}
      >
        <Form.Item
          name="construction"
          style={{ margin: 0, padding: 0, width: "100%" }}
          labelAlign="right"
        >
          <AutoComplete
            placeholder=""
            allowClear={true}
            getPopupContainer={(trigger: any) => trigger.parentNode}
            size="large"
            onSelect={(value: any, option: any) => {
              onConstructionChoose(option);
            }}
            className="search-bar-om001  "
            showArrow={true}
            options={construction.map((element) => ({
              key: element.id,
              value: element.id + "",
              label: element.name,
              ...element,
            }))}
            onSearch={handleSearch}
            filterOption={(inputValue, option) => {
              const normalizedInputValue = inputValue
                .trim()
                .replace(/　/g, " ")
                .toLowerCase();

              const normalizedLabel = option.label
                .toString()
                .trim()
                .replace(/　/g, " ")
                .toLowerCase();

              return normalizedLabel.includes(normalizedInputValue);
            }}
            notFoundContent={
              <div style={{ width: "100%", textAlign: "center" }}>
                データなし
              </div>
            }
          ></AutoComplete>
        </Form.Item>
      </div>
    </main>
  );
};
export default ProjectDropDownComponent;
