import SM06Api from './infrastructure/api/Api';
import SM06Handler from './presenter/handler/Handler';
import SM06UI from './presenter/ui/UI';
import SM06Service from './usecase/Service';

const SM06Component = () => {
    const am06Api = SM06Api();
    const am06Service = SM06Service(am06Api);
    const am06Handler = SM06Handler(am06Service);
    return <SM06UI handler={am06Handler} />;
};

export default SM06Component;
