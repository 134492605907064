import "../../../../../assets/styles/CM/CM0034.css";
import { CM0034Handler } from "../handler/HandlerImpl";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import PaginationComponentList from "../../../../../common/components/pagination/PaginationComponentList";
import { TableColumns } from "../../entity/Entity";
import { useMemo } from "react";
import SortTable from "../../../../../common/components/sort-table/SortTable";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import CM006Component from "../../../CM006";
import React from "react";
import { useSearchParams } from "react-router-dom";
import {
  FROM_DATE_FILTER,
  KEYWORD_FILTER,
  STATUS_FILTER,
  TO_DATE_FILTER,
} from "../handler/Handler";
import helpers from "../../../../../common/helpers/common";
import moment from "moment";
import ModalRejectView from "../../../../../common/components/modal-reject/ModalRejectView";
import { STATUS_CM_CHILD_TABLE } from "../../../../../common/constants/COMMON";
import WarningIcon from "../../../../../assets/icons/warning-red.svg";

type Props = {
  handler: CM0034Handler;
};
const CM0034UI = ({ handler }: Props) => {
  const [searchParams] = useSearchParams();
  const visibleCM006 = useSelector((state: RootState) => state.cm006.isVisible);
  const refresh = useSelector((state: RootState) => state.cm0034.isRefresh);
  const from = searchParams.get(FROM_DATE_FILTER) ?? "";
  const to = searchParams.get(TO_DATE_FILTER) ?? "";
  const statusId = searchParams.get(STATUS_FILTER) ?? "";
  const keyword = searchParams.get(KEYWORD_FILTER) ?? "";

  const sortType = useMemo(() => {
    return handler.sortType;
  }, [handler.sortType]);
  const sortBy = useMemo(() => {
    return handler.sortBy;
  }, [handler.sortBy]);

  let caller = true;

  React.useEffect(() => {
    if (caller) {
      handler.handleGetData({
        page: handler.page,
        size: handler.size,
        sortBy: handler.sortBy,
        sortType: handler.sortType,
        from:
          from === ""
            ? helpers.getFilterTime(moment().startOf("month"))
            : helpers.getFilterTime(from),
        to:
          to === ""
            ? helpers.getFilterTimeTo(moment())
            : helpers.getFilterTimeTo(to),
        statusId: statusId,
        keyword: keyword,
      });
    }

    return () => {
      caller = false;
    };
  }, [
    handler.page,
    handler.size,
    handler.sortBy,
    handler.sortType,
    from,
    to,
    statusId,
    keyword,
    refresh,
  ]);

  const columns: ColumnsType<TableColumns> = [
    {
      key: "no",
      dataIndex: "no",
      title: "No",
      width: 110,
      align: "center",
      render: (_, __, index) => {
        return (
          <span className="collapsed-table-row-style">{`${
            index + 1 + (Number(handler.page) - 1) * Number(handler.size)
          }`}</span>
        );
      },
    },
    {
      key: "documentTitle",
      title: "資料名",
      align: "center",
      width: 343,
      render: (row: any) => {
        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              handler.handleViewDetail(
                row.key,
                row.submittedDate,
                row.createdAt,
                row?.status
              )
            }
          >
            {row.documentTitle}
          </div>
        );
      },
    },
    {
      key: "status",
      dataIndex: "status",
      title: "ステータス",
      align: "center",
      width: 343,
      render: (row, record) => (
        <>
          <span
            style={{
              color:
                row.code === STATUS_CM_CHILD_TABLE.MONEY_REJECT.CODE
                  ? STATUS_CM_CHILD_TABLE.MONEY_REJECT.COLOR
                  : row.code === STATUS_CM_CHILD_TABLE.MONEY_APPROVED.CODE
                  ? STATUS_CM_CHILD_TABLE.MONEY_APPROVED.COLOR
                  : "#FF9054",
            }}
          >{`${row.name ?? ""}`}</span>
          {row.code === STATUS_CM_CHILD_TABLE.MONEY_REJECT.CODE ? (
            <img
              alt=""
              src={WarningIcon}
              className="cm053-icon"
              onClick={() => {
                handler.setRejectReason(record.reason);
                handler.setVisibleRejectView(true);
              }}
            />
          ) : null}
        </>
      ),
    },
    {
      key: "submittedDate",
      dataIndex: "submittedDate",
      title: (
        <SortTable
          columnTitle="提出日"
          sortOrder={handler.funcCheckSortOrder(
            "submittedDate",
            sortType,
            sortBy
          )}
          onChange={(sortOrder) =>
            handler.funcSortTable("submittedDate", sortOrder)
          }
        />
      ),
      align: "center",
      width: 343,
    },
    {
      key: "submitter",
      dataIndex: "submitter",
      title: "提出者",
      align: "center",
      width: 343,
    },
  ];

  return (
    <div className="cm0034-table">
      <Table
        bordered
        columns={columns}
        pagination={false}
        dataSource={handler.dataTable}
        loading={handler.loading}
      />
      {handler.dataTable.length > 0 ? (
        <PaginationComponentList
          disabled={handler.dataTable.length !== 0 ? false : true}
          total={handler.total}
          page={handler.page}
          size={handler.size}
          setPage={handler.onPageChange}
          setSize={handler.onSizeChange}
        />
      ) : null}
      {visibleCM006 ? <CM006Component isCheckbox={true} /> : null}
      <ModalRejectView
        visible={handler.visibleRejectView}
        funcClose={() => handler.setVisibleRejectView(false)}
        rejectReason={handler.rejectReason}
      />
    </div>
  );
};

export default CM0034UI;
