import { createSlice } from "@reduxjs/toolkit";

export type SliceType = {
  isVisible: boolean;
  versionId?: number;
};

const initialState: SliceType = {
  isVisible: false,
};

export const CR005Slice = createSlice({
  name: "CR005",
  initialState,
  reducers: {
    resetCR005: () => initialState,
    setVersionIdCR005: (state, action) => {
      state.isVisible = true;
      state.versionId = action.payload;
    },
  },
});

export const { setVersionIdCR005, resetCR005 } = CR005Slice.actions;

export default CR005Slice.reducer;
