// Built-in
import React, { useEffect } from 'react';

// 3rd party lib
import { Divider, InputNumber, Popover, Switch } from 'antd';

// Image files
import SettingPurple from '../../../../../assets/icons/setting-purple.svg';
import SwooshGreenIcon from '../../../../../assets/icons/swoosh-green-icon.svg';
import XRedIcon from '../../../../../assets/icons/x-red-icon.svg';
import EditPopoverIcon from '../../../../../assets/icons/edit-popover-icon.svg';
import { HandlerImpl } from '../handler/HandlerImpl';

import '../../../../../assets/styles/components/thresshold-popover.css';
import { typeInteger } from '../../../../helpers/typeInput';
import { DEFAULT_THRESHOLD } from '../..';

type props = {
    initialData: any;
    handler: HandlerImpl;
};

const UI = ({ handler, initialData }: props) => {
    const {
        setIsEdit,
        setData,
        setInputValue,
        setIsThreshold,
        data,
        isEdit,
        inputValue,
        isThreshold,
    } = handler;

    useEffect(() => {
        setData(initialData);
        setInputValue(initialData?.threshold ?? DEFAULT_THRESHOLD);
        setIsThreshold(initialData.enable ? true : false);
    }, [JSON.stringify(initialData)]);

    const content = (
        <main className="input-number-background" style={{ width: 200 }}>
            {!isThreshold ? (
                <></>
            ) : (
                <React.Fragment>
                    <div
                        className="w-100-percent input-add-on-threshold"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            borderRadius: 3,
                            border: '1px solid #DAE2EE',
                        }}
                    >
                        <InputNumber controls={false}
                            disabled={!isEdit}
                            size="large"
                            onChange={(value: any) => {
                                handler.onThresholdChange(value);
                            }}
                            onKeyPress={typeInteger}
                            value={inputValue}
                            style={{ padding: 0, margin: 0, color: '#222222', width: 55 }}
                            bordered={false}
                            min={0}
                            max={100}
                            maxLength={3}
                            addonAfter={'%'}
                        />
                        <div style={{ display: 'flex', marginRight: 8 }}>
                            {isEdit ? (
                                <>
                                    <div
                                        className="button-style"
                                        style={{ marginRight: 8 }}
                                        onClick={() => handler.updateData()}
                                    >
                                        <img alt="" src={SwooshGreenIcon} />
                                    </div>
                                    <div
                                        className="button-style"
                                        onClick={() => handler.cancelEdit()}
                                    >
                                        <img alt="" src={XRedIcon} />
                                    </div>
                                </>
                            ) : (
                                <div className="button-style" onClick={() => setIsEdit(true)}>
                                    <img alt="" src={EditPopoverIcon} />
                                </div>
                            )}
                        </div>
                    </div>
                    <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                </React.Fragment>
            )}
            <div
                className="w-100-percent"
                style={{ display: 'flex', justifyContent: 'space-between' }}
            >
                <span className="popover-text">通知</span>
                <Switch
                    checked={isThreshold}
                    onChange={(value: any) => handler.updateThreshold(value)}
                />
            </div>
        </main>
    );

    return (
        <Popover
            className="threshold-container"
            placement="topRight"
            content={content}
            trigger="click"
            getPopupContainer={(trigger: any) => trigger.parentNode}
        >
            <img className="button-style" src={SettingPurple} alt="" style={{ marginLeft: 8 }} />
        </Popover>
    );
};

export default UI;
