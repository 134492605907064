import { CM0031SliceType } from './../type/Presenter';
import { createSlice } from '@reduxjs/toolkit';

const initialState: CM0031SliceType = {
    isRefresh: false,
    isVisibleCM0031: false,
    refresh: false,
    document: {
        id: 0,
        documentName: '',
        submittedDate: '',
        from: '',
        to: '',
        status: '',
        documentType: '',
    },
};

export const cm0031Slice = createSlice({
    name: 'cm0031',
    initialState,
    reducers: {
        resetCM0031: () => initialState,
        setVisibleCM0031: (state, action) => {
            state.isVisibleCM0031 = action.payload;
        },
        setDocumentCM0031: (state, action) => {
            state.document = action.payload;
        },
        setRefresh: (state) => {
            state.refresh = !state.refresh;
        },
    },
});

export const { resetCM0031, setVisibleCM0031, setDocumentCM0031, setRefresh } = cm0031Slice.actions;

export default cm0031Slice.reducer;
