import { createSlice } from "@reduxjs/toolkit";
import { PM002SliceEntity } from "../type/Presenter";
const initialState: PM002SliceEntity = {
  data: [],
  page: 1,
  size: 6,
  total: 16,
  keyword: "",
  filter: "",
  status: [],
  statusId: 0,
  allStatus: [],
  members: [],
  showModal: false,
  users: [],
  totalMembers: 10,
  sizeMember: 10,
  pageMember: 1,
  sortKey: "username",
  sortType: "desc",
  keywordMember: "",
  isRefreshPM002: false,
};

export const pM002Slice = createSlice({
  name: "PM002",
  initialState,
  reducers: {
    resetPM002: () => initialState,
    setData: (state, action) => {
      state.data = action.payload;
    },
    setPageChange: (state, action) => {
      state.total = action.payload.pagination.count;
      state.page = action.payload.pagination.page;
      state.size = action.payload.pagination.displayRecord;
    },
    setPageChangeMember: (state, action) => {
      state.pageMember = action.payload;
      // state.sizeMember = action.payload.pagination.displayRecord;
    },
    setSizeChangeMember: (state, action) => {
      // state.totalMembers = action.payload.pagination.count;
      // state.pageMember = action.payload.pagination.page;
      state.sizeMember = action.payload;
    },
    setTotalMember: (state, action) => {
      state.totalMembers = action.payload.pagination.count;
    },

    setKeyWordChange: (state, action) => {
      state.keyword = action.payload;
    },

    setStatus: (state, action) => {
      state.status = action.payload.results.status;
    },
    setStatusFilterChange: (state, action) => {
      state.statusId = action.payload;
    },
    setAllStatus: (state, action) => {
      state.allStatus = action.payload;
    },
    setMembers: (state, action) => {
      state.members = action.payload;
    },
    setShowModal: (state, action) => {
      state.showModal = action.payload;
    },
    setAllUsers: (state, action) => {
      state.users = action.payload;
    },
    setPageChangeCard: (state, action) => {
      state.page = action.payload;
    },
    setSortKeyName: (state, action) => {
      state.sortKey = action.payload;
    },
    setSortTypeName: (state, action) => {
      state.sortType = action.payload;
    },
    setSearchKeywordMember: (state, action) => {
      state.keywordMember = action.payload;
    },

    setRefreshPM002: (state, action) => {
      state.isRefreshPM002 = action.payload;
    },
  },
});

export const isRefreshPM002 = (state: any) => state.pm002.isRefreshPM002;

export const {
  setData,
  resetPM002,
  setPageChange,
  setKeyWordChange,
  setStatus,
  setStatusFilterChange,
  setAllStatus,
  setMembers,
  setShowModal,
  setAllUsers,
  setPageChangeCard,
  setPageChangeMember,
  setSortKeyName,
  setSortTypeName,
  setSearchKeywordMember,
  setSizeChangeMember,
  setTotalMember,
  setRefreshPM002,
} = pM002Slice.actions;

export default pM002Slice.reducer;
