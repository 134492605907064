import axiosConfig from "../../../../../config/axiosConfig";

const AM010Api = () => {
  const getProjects = async (params: {
    page: number;
    size: number;
    sortBy?: string;
    sortType?: string;
    keyword?: string;
  }): Promise<any> => {
    try {
      const url = "/money/project/list";
      const response = await axiosConfig.get(url, { params });
      return response?.data;
    } catch (error) {
      throw error;
    }
  };

  const checkIn = async (data: any): Promise<any> => {
    try {
      const url = "/attendance/checkin";
      const response = await axiosConfig.post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const checkOut = async (data: any): Promise<any> => {
    try {
      const url = "/attendance/checkout";
      const response = await axiosConfig.post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const getSummaryRequest = async (params: any): Promise<any> => {
    try {
      const url = "/request/summary";
      const response = await axiosConfig.get(url, { params });
      return response?.data;
    } catch (error) {
      throw error;
    }
  };

  const getRequestAttendanceMonthly = async (params: any): Promise<any> => {
    try {
      const url = "/request/attendance/monthly";
      const response = await axiosConfig.get(url, { params });
      return response?.data;
    } catch (error) {
      throw error;
    }
  };

  const getRequestLateEarlyMonthly = async (params: any): Promise<any> => {
    try {
      const url = "/request/late-early/monthly";
      const response = await axiosConfig.get(url, { params });
      return response?.data;
    } catch (error) {
      throw error;
    }
  };

  const getRequestLeaveMonthly = async (params: any): Promise<any> => {
    try {
      const url = "/request/leave/monthly";
      const response = await axiosConfig.get(url, { params });
      return response?.data;
    } catch (error) {
      throw error;
    }
  };

  const getRequestInformation = async (params: any): Promise<any> => {
    try {
      const url = "/attendance/information";
      const response = await axiosConfig.get(url, { params });
      return response?.data;
    } catch (error) {
      throw error;
    }
  };

  const getAttendanceReport = async (params: any): Promise<any> => {
    try {
      const url = "/attendance/report";
      const response = await axiosConfig.get(url, { params });
      return response?.data;
    } catch (error) {
      throw error;
    }
  };

  const getAttendanceUserConstruction = async (params: any): Promise<any> => {
    try {
      const url = "/attendance/user/construction";
      const response = await axiosConfig.get(url, { params });
      return response?.data;
    } catch (error) {
      throw error;
    }
  };

  const getProjectById = async (params: any): Promise<any> => {
    try {
      const url = "/project/detail";
      const response = await axiosConfig.get(url, { params });
      return response?.data;
    } catch (error) {
      throw error;
    }
  };

  const getProjectStatus = async (params: any): Promise<any> => {
    try {
      const url = "/project/status";
      const response = await axiosConfig.get(url, { params });
      return response?.data;
    } catch (error) {
      throw error;
    }
  };

  return {
    getProjects,
    checkIn,
    checkOut,
    getSummaryRequest,
    getRequestAttendanceMonthly,
    getRequestLateEarlyMonthly,
    getRequestLeaveMonthly,
    getRequestInformation,
    getAttendanceReport,
    getAttendanceUserConstruction,
    getProjectById,
    getProjectStatus,
  };
};

export default AM010Api;
