import React from "react";
import { ColumnsType } from "antd/lib/table";
import WarningIcon from "../../../../../../assets/icons/warning-red.svg";
import { Empty, Table } from "antd";
import { SortOrder } from "antd/lib/table/interface";
import { STATUS_CODE } from "../handler/Handler";
import moment from "moment";
import COMMON, {
  STATUS_CM_CHILD_TABLE,
} from "../../../../../../common/constants/COMMON";

type DataType = {
  no: number;
  status: {
    id: number;
    code: string;
    name: string;
    type: string;
    description: string;
  };
  reason: string;
  canApprove: boolean;
  user: {
    id: number;
    username: string;
    email: string;
    fullName: string;
  };
};
type props = {
  dataSource: any[];
  selectedRowKeys: React.Key[];
  type: string;
  sortOrder: SortOrder | undefined;
  title: string;

  onChangeSelectKey(value: any, type: string): void;
  handleSortTable(sorter: any, type: string): void;
  handleViewRejectReason(reason: string): void;
  handleViewModal(
    documentId: number,
    documentDirectMaterialCostId: number,
    submittedDate: string,
    statusCode: string,
    constructionId?: number
  ): void;
};

const TableItem = ({
  dataSource,
  selectedRowKeys,
  type,
  sortOrder,
  title,
  onChangeSelectKey,
  handleSortTable,
  handleViewRejectReason,
  handleViewModal,
}: props) => {
  const columns: ColumnsType<DataType> = [
    {
      key: "no",
      dataIndex: "no",
      title: "No",
      align: 'center',
      width: 110,
    },
    {
      key: "fileName",
      dataIndex: "fileName",
      title: "資料名",
      align: "center",
      onCell: (record: any) => {
        return {
          onClick: () =>
            handleViewModal(
              record.id,
              record.originId,
              record.submittedDate,
              record.status?.code,
              record.constructionId
            ),
          style: { cursor: "pointer" },
        };
      },
    },
    {
      key: "status",
      dataIndex: "status",
      title: "ステータス",
      align: "center",
      render: (record, row) => {
        return (
          <>
            <span
              style={{
                color:
                  record.code === STATUS_CM_CHILD_TABLE.MONEY_REJECT.CODE
                    ? STATUS_CM_CHILD_TABLE.MONEY_REJECT.COLOR
                    : record.code === STATUS_CM_CHILD_TABLE.MONEY_APPROVED.CODE
                      ? STATUS_CM_CHILD_TABLE.MONEY_APPROVED.COLOR
                      : "#FF9054",
              }}
            >{`${record.name ?? ""}`}</span>
            {record.code === STATUS_CODE.reject ? (
              <img
                alt=""
                src={WarningIcon}
                className="cm053-icon"
                onClick={() => handleViewRejectReason(row.reason)}
              />
            ) : null}
          </>
        );
      },
    },
    {
      key: "submittedDate",
      dataIndex: "submittedDate",
      title: "提出日",
      align: "center",
      sorter: true,
      showSorterTooltip: false,
      sortDirections: ["ascend", "descend", "ascend"],
      sortOrder: sortOrder,
      render: (record) => {
        return record ? moment(record).format(COMMON.FORMAT_DATE_OM) : "";
      },
    },
    {
      key: "user",
      dataIndex: "user",
      title: "提出者",
      align: "center",
      render: (record) => {
        return `${record.fullName ?? ""}${record?.branchName ? `（${record?.branchName}）` : ""
          }`;
      },
    },
  ];
  return (
    <div className="cm047-table">
      <div className="cm048-table-item">
        <div className="cm048-item-header">{title}</div>
        <Table
          bordered
          columns={columns}
          pagination={false}
          dataSource={dataSource}
          //   rowSelection={{
          //     selectedRowKeys: selectedRowKeys,

          //     onChange: (value) => onChangeSelectKey(value, type),
          //     getCheckboxProps: (record) => {
          //       return {
          //         disabled: !record.canApprove,
          //       };
          //     },
          //   }}
          onChange={(_, filter, sorter) => handleSortTable(sorter, type)}
          scroll={{
            y: 112,
          }}
          locale={{
            emptyText: (
              <Empty
                style={{ margin: "0 0" }}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="データなし"
              />
            ),
          }}
        />
      </div>
    </div>
  );
};

export default TableItem;
