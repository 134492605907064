import LocationGPSIcon from '../../../assets/icons/location-gps.svg';
import moment from 'moment';
import { DateColumn } from './entity/Entity';
import { TimeSheet } from '../AM004/entity/Entity';
import COMMON from '../../../common/constants/COMMON';
import helpers from '../../../common/helpers/common';
import ButtonWarningAM042Component from '../AM042/presenter/component/button-warning';
import { ExportDataCompanyChildren, ExportDataCompanyElement } from '../../../common/entity/Entity';
import { Empty } from 'antd';

const CHECK_IN_CODE: string = 'checkin';

const findAttributeByDate = (timesheet: TimeSheet[], date: string, attribute: string) => {
    const foundData: any = timesheet.find((element) =>
        moment(element.date).isSame(moment(date), 'day'),
    );
    if (foundData) return foundData[`${attribute}`];
    return '';
};

const convertToDataSource = (
    userId: number,
    timesheet: TimeSheet[],
    tableColumns: DateColumn[],
    laborCost: number,
    openStreetMap: (lat: any, lng: any) => void,
    constructionId?: number,
) => {
    const columnFrames = [
        {
            key: 1,
            id: 1,
            name: '複数打刻',
        },
        {
            key: 2,
            id: 2,
            name: '出勤時刻',
        },
        {
            key: 3,
            id: 3,
            name: '退勤時刻',
        },
        {
            key: 4,
            id: 4,
            name: '稼働時間',
        },
    ];
    const result: any[] = [];

    columnFrames.forEach((frame) => {
        const record: any = {};
        if (frame.id === 1)
            tableColumns.forEach((col) => {
                const foundData: any = timesheet.find((element) =>
                    moment(element.date).isSame(moment(col.value), 'day'),
                );
                const isMultipleCheckInOut = findAttributeByDate(
                    timesheet,
                    col.value,
                    'isMultipleCheckInOut',
                );
                record[`${col.value}`] = isMultipleCheckInOut ? (
                    <ButtonWarningAM042Component
                        contractorConstructionId={constructionId ? constructionId + '' : undefined}
                        constructionId={constructionId ? constructionId + '' : undefined}
                        date={moment(foundData.date).format(COMMON.FORMAT_DATE2)}
                        userId={userId + ''}
                    />
                ) : (
                    <></>
                );
            });
        else if (frame.id === 2) {
            tableColumns.forEach((col) => {
                const checkin = findAttributeByDate(timesheet, col.value, 'checkin');
                const checkinLocation = findAttributeByDate(
                    timesheet,
                    col.value,
                    'checkinLocation',
                );

                record[`${col.value}`] = checkin ? (
                    <main style={{ display: 'flex' }}>
                        <div style={{ marginRight: 7 }}>{`${moment(checkin).format(
                            COMMON.FORMAT_TIME1,
                        )}`}</div>
                        {checkinLocation &&
                            checkinLocation?.lat !== 0 &&
                            checkinLocation?.long !== 0 ? (
                            <div
                                className="center-item"
                                onClick={() =>
                                    openStreetMap(checkinLocation?.lat, checkinLocation?.long)
                                }
                            >
                                <img alt="" src={LocationGPSIcon} />
                            </div>
                        ) : (
                            <></>
                        )}
                    </main>
                ) : (
                    <></>
                );
            });
        } else if (frame.id === 3) {
            tableColumns.forEach((col) => {
                const checkout = findAttributeByDate(timesheet, col.value, 'checkout');
                const checkoutLocation = findAttributeByDate(
                    timesheet,
                    col.value,
                    'checkoutLocation',
                );

                record[`${col.value}`] = checkout ? (
                    <main style={{ display: 'flex' }}>
                        <div style={{ marginRight: 7 }}>
                            {`${moment(checkout).format(COMMON.FORMAT_TIME1)}`}
                        </div>
                        {checkoutLocation &&
                            checkoutLocation?.lat !== 0 &&
                            checkoutLocation?.long !== 0 ? (
                            <div
                                className="center-item"
                                onClick={() =>
                                    openStreetMap(checkoutLocation?.lat, checkoutLocation?.long)
                                }
                            >
                                <img alt="" src={LocationGPSIcon} />
                            </div>
                        ) : (
                            <></>
                        )}
                    </main>
                ) : (
                    <></>
                );
            });
        } else if (frame.id === 4) {
            tableColumns.forEach((col) => {
                const checkin = findAttributeByDate(timesheet, col.value, 'checkin');
                const checkout = findAttributeByDate(timesheet, col.value, 'checkout');

                if (checkin && checkout) {
                    const duration = moment.duration(
                        moment(checkout, COMMON.FORMAT_DATE_4).diff(
                            moment(checkin, COMMON.FORMAT_DATE_4),
                        ),
                    );
                    const totalMinutes = duration.asMinutes();
                    record[`${col.value}`] = `${Math.round((totalMinutes / 60) * 100) / 100}`;
                } else record[`${col.value}`] = <></>;
            });
        } else if (frame.id === 5)
            tableColumns.forEach((col) => {
                const checkin = findAttributeByDate(timesheet, col.value, 'checkin');
                const checkout = findAttributeByDate(timesheet, col.value, 'checkout');
                const dailyLaborCost = findAttributeByDate(timesheet, col.value, 'dailyLaborCost');
                if (checkin && checkout) record[`${col.value}`] = helpers.formatCurrency(dailyLaborCost ?? 0);
                else record[`${col.value}`] = '';
            });
        else if (frame.id === 6)
            tableColumns.forEach((col) => {
                record[`${col.value}`] = findAttributeByDate(timesheet, col.value, '');
            });
        // (frame.id === 7)
        else
            tableColumns.forEach((col) => {
                record[`${col.value}`] = findAttributeByDate(timesheet, col.value, '');
            });

        result.push({ ...frame, ...record });
    });
    return result;
};

const subtractInOutTime = (checkIn: string, checkOut: string) => {
    if (checkIn && checkOut) {
        const duration = moment.duration(
            moment(checkOut, COMMON.FORMAT_DATE_4).diff(moment(checkIn, COMMON.FORMAT_DATE_4)),
        );
        const value: number = duration.hours() + duration.minutes() / 60;
        return `${value.toFixed(2)}`;
    } else return '';
};

const convertMultipleTimeSheet = (data: any[]) => {
    const dataCheckIn: string[] = [];
    const dataCheckOut: string[] = [];
    const results: any[] = [];

    data.forEach((element: any) => {
        if (element?.category?.code === CHECK_IN_CODE) dataCheckIn.push(element?.time);
        else dataCheckOut.push(element?.time);
    });

    if (dataCheckIn.length > dataCheckOut.length) {
        dataCheckIn.forEach((item: any, idx: number) => {
            const checkIn: string = dataCheckIn[idx] ?? '';
            const checkOut: string = dataCheckOut[idx] ?? '';

            results.push({
                check_in: checkIn,
                check_out: checkOut,
                total_hour: subtractInOutTime(checkIn, checkOut),
            });
        });
    } else {
        dataCheckOut.forEach((item: any, idx: number) => {
            const checkIn: string = dataCheckIn[idx] ?? '';
            const checkOut: string = dataCheckOut[idx] ?? '';

            results.push({
                check_in: checkIn,
                check_out: checkOut,
                total_hour: subtractInOutTime(checkIn, checkOut),
            });
        });
    }

    return results;
};

const convertDataForExport = (data: any[]) => {
    const results: ExportDataCompanyElement[] = [];

    data.forEach((element: any, idx: number) => {
        let obj: ExportDataCompanyElement = {
            employee_name: element?.fullName,
            children: [],
        };

        let listItems: ExportDataCompanyChildren[] = [];

        element?.attendance?.timesheet.forEach((item: any) => {
            let objItem: ExportDataCompanyChildren = {
                check_in: item?.checkin,
                check_out: item?.checkout,
                total_hour: subtractInOutTime(item?.checkin, item?.checkout),
                items: item?.isMultipleCheckInOut
                    ? convertMultipleTimeSheet(item?.multipleCheckInOutData ?? [])
                    : [],
            };

            listItems.push(objItem);
        });

        obj['children'] = listItems;

        results.push(obj);
    });

    return results;
};

const convertToDataSourceList = (dataSource: any[]) => {
    let convertedData: any[] = [{ key: 'nodata' }];
    if (dataSource && Array.isArray(dataSource) && dataSource.length > 0)
        convertedData = [...helpers.addKeyToDataSource(dataSource)];

    return convertedData;
};

export { convertToDataSourceList, convertToDataSource, convertDataForExport };
