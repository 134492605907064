import axiosConfig from "../../../../../config/axiosConfig";
import { RetrieveLaborDocument } from "../../entity/Entity";

const CM0014Api = () => {
  const getListSubmittedDirectCostLabor = async (params: any): Promise<any> => {
    try {
      const url = "/re/submitted/direct-cost/labor/list";
      const response = await axiosConfig.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getPresignLinkS3 = async (params: any): Promise<any> => {
    const url = "/project/presign-link";
    return await axiosConfig.get(url, { params });
  };

  const getProjectSummaryReceivedMoney = async (params: any): Promise<any> => {
    const url = "/money/project/summary/received-money";
    return await axiosConfig.get(url, { params });
  };

  const getConstructionSummaryCostInformation = async (
    params: any
  ): Promise<any> => {
    const url = "/money/summary/construction/cost/information";
    return await axiosConfig.get(url, { params });
  };

  const getLaborListManager = async (params: any): Promise<any> => {
    const url = "/money/direct-cost/labor/list-manager";
    return await axiosConfig.get(url, { params });
  };

  const getLaborList = async (params: any): Promise<any> => {
    const url = "/money/direct-cost/labor/list";
    return await axiosConfig.get(url, { params });
  };

  const retrieveLaborDocument = async (
    data: RetrieveLaborDocument
  ): Promise<any> => {
    const url = "/money/retrieve/direct-cost/labor";
    return await axiosConfig.put(url, data);
  };
  const getWorkflowLaborApprovalProcess = async (params: {
    documentId: number;
  }): Promise<any> => {
    const url = "/workflow/labor-approval-process";
    return await axiosConfig.get(url, { params });
  };

  return {
    getListSubmittedDirectCostLabor,
    getPresignLinkS3,
    getProjectSummaryReceivedMoney,
    getConstructionSummaryCostInformation,
    getLaborListManager,
    getLaborList,
    retrieveLaborDocument,
    getWorkflowLaborApprovalProcess,
  };
};

export default CM0014Api;
