import { AxiosResponse } from 'axios';
import axiosConfig from '../../../../../config/axiosConfig';
import { WorkflowCreatePayload, WorkflowSettingPayload } from '../../entity/Entity';

const SW01Api = () => {
    const getWorkflowList = async (params: { categoryId?: number }) => {
        const url = '/workflow/list';
        return axiosConfig.get(url, { params });
    };

    const getWorkflowTypeList = async (params: {}) => {
        const url = '/workflow/workflow-type/list';
        return axiosConfig.get(url, { params });
    };

    const postWorkflowCreate = async (payload: WorkflowCreatePayload) => {
        const url = '/workflow/create';
        return axiosConfig.post(url, payload);
    };

    const putWorkflowSetting = async (payload: WorkflowSettingPayload) => {
        const url = 'workflow/setting';
        return axiosConfig.put(url, payload);
    };

    return {
        getWorkflowList,
        getWorkflowTypeList,
        postWorkflowCreate,
        putWorkflowSetting,
    };
};

export default SW01Api;
