import { createSlice } from '@reduxjs/toolkit';
import { CM0027SliceType } from '../../entity/Entity';

export const initialState: CM0027SliceType = {
    isDisableRetrieveButton: true,
    isRefresh: false,
    isVisibleCM0027: false,
    documentId: null,
    documentInfo: {
        documentTitle: '',
        createdDate: '',
        targetPeriod: '',
        status: '',
    },
    isShowOffset: false,
};

export const CM0027Slice = createSlice({
    name: 'CM0027',
    initialState,
    reducers: {
        resetCM0027: () => initialState,
        setVisibleCM0027: (state, action) => {
            state.isVisibleCM0027 = action.payload;
        },
        setDocumentIdCM0027: (state, action) => {
            state.documentId = action.payload;
        },
        setDocumentInfoCM0027: (state, action) => {
            state.documentInfo = action.payload;
        },
        setIsShowOffset: (state, action) => {
            state.isShowOffset = action.payload;
        },
        setIsDisableRetrieveButtonCM0027: (state, action) => {
            state.isDisableRetrieveButton = action.payload;
        },
    },
});

export const {
    resetCM0027,
    setVisibleCM0027,
    setDocumentIdCM0027,
    setDocumentInfoCM0027,
    setIsShowOffset,
    setIsDisableRetrieveButtonCM0027,
} = CM0027Slice.actions;

export default CM0027Slice.reducer;
