import { createSlice } from "@reduxjs/toolkit";
import { OM002SliceState } from "../type/Presenter";

export const initialState: OM002SliceState = {
  isRefresh: false,
  isRefreshGroup: false,
  isRefreshMember: false,
  undefinedGroupId: null,
};

export const OM002Slice = createSlice({
  name: "om002",
  initialState,
  reducers: {
    resetOM002: () => initialState,
    refreshOM002: (state) => {
      state.isRefresh = !state.isRefresh;
    },
    refreshMemberOM002: (state) => {
      state.isRefreshMember = !state.isRefreshMember;
    },
    refreshGroupOM002: (state) => {
      state.isRefreshGroup = !state.isRefreshGroup;
    },
    setUndefinedGroupId: (state, action) => {
      state.undefinedGroupId = action.payload;
    },
  },
});

export const {
  refreshOM002,
  refreshMemberOM002,
  refreshGroupOM002,
  setUndefinedGroupId,
} = OM002Slice.actions;

export default OM002Slice.reducer;
