import { Empty } from 'antd';
import React from 'react';

const AMSideNoDataComponent = () => (
  <div
    className="center-item"
    style={{
      minHeight: 64 * 2 + 80,
      borderBottom: '1px solid #DAE2EE',
    }}
  >
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="データなし" />
  </div>
);

export default AMSideNoDataComponent;
