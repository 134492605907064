import { useSearchParams } from "react-router-dom";
import React, { useEffect } from "react";
import { HanlderCompanyImpl } from "../../../handler/HandlerImpl";
import CompanyItem from "./CompanyItem";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../store";
import SearchBarComponent from "./SearchBar";
import { Empty, Form } from "antd";
import PaginationComponent from "./Pagination";

interface props {
  handler: HanlderCompanyImpl;
}

const CompanyUI = ({ handler }: props) => {
  const { onSearch, getAttendanceCompanyColab, form } = handler;

  const isLoading = useSelector((state: RootState) => state.common.loading);

  const [searchParams] = useSearchParams();
  const selectedCompanyId = searchParams.get("selectedCompany");
  const companyPage = searchParams.get("companyPage") || "1";
  const keywordCompany = searchParams.get("companyKeyword") || "";
  const sizeCompany = "10";
  const isCollapsed = searchParams.get("isCollapsed") ? true : false;
  const colabRefId = searchParams.get("selectedColabRefId");

  useEffect(() => {
    getAttendanceCompanyColab({
      keyword: keywordCompany,
      page: companyPage,
      size: sizeCompany,
    });
  }, [companyPage, keywordCompany]);

  const companyList = (
    <main>
      <div>
        {handler.companyList.map((element: any) => (
          <React.Fragment key={element.id + "" + element.colabRefId}>
            <CompanyItem
              isCollapsed={isCollapsed}
              company={element}
              isChosen={element.id + "" === selectedCompanyId && element.colabRefId + "" === colabRefId}
              onClick={() => handler.onChooseCompany(element)}
            />
          </React.Fragment>
        ))}
      </div>
      {isCollapsed ? (
        <></>
      ) : (
        <PaginationComponent
          page={companyPage}
          size={sizeCompany}
          total={handler.totalCompany}
          onPageChange={handler.onPageChange}
        />
      )}
    </main>
  );
  const noData = (
    <div className="center-item" style={{ minHeight: 500 }}>
      <Empty
        style={{ marginTop: 200 }}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description="データなし"
      />
    </div>
  );
  return isLoading ? (
    <></>
  ) : (
    <main className="company-container">
      <Form form={form}>
        <SearchBarComponent
          onChange={onSearch}
          isCollapsed={isCollapsed}
          onCollapse={handler.onCollapse}
        />
        {handler.companyList.length === 0 ? noData : companyList}
      </Form>
    </main>
  );
};

export default CompanyUI;
