import { Form } from "antd";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import COMMON from "../../../../../common/constants/COMMON";
import MESSAGE, {
  LABEL_MESSAGE,
  NOTIFICATION_TITLE,
} from "../../../../../common/constants/MESSAGE";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import {
  setGroupIdOM004,
  setIsVisibleOM004,
} from "../../../OM004/presenter/slice/Slice";
import { setIsVisibleOM007 } from "../../../OM007/presenter/slice/Slice";
import {
  setGroupIdOM009,
  setIsVisibleOM009,
} from "../../../OM009/presenter/slice/Slice";

import {
  setDetailGroup,
  setIsVisibleOM001,
} from "../../../OM001/presenter/slice/Slice";
import { OM002UseCase, OM004UseCase } from "../../usecase/ServiceImpl";
import {
  refreshGroupOM002,
  refreshOM002,
  setUndefinedGroupId,
} from "../slice/Slice";
import { OM002State } from "../type/Presenter";
import ConfirmModalAsync from "../../../../../common/components/modal/ConfirmModalAsync";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import {
  setMemberIdOM006,
  setIsVisibleOM006,
} from "../../../OM006/presenter/slice/Slice";
import { setIsVisibleAM014 } from "../../../../AM/AM014/presenter/slice/Slice";
import helpers from "../../../../../common/helpers/common";
import { DetailGroup } from "../../../OM008/entity/Entity";
import { SYSTEM_ROLES } from "../../../../../common/constants/AUTHORIZATION";

const InitialState: OM002State = {
  groupMembers: [],
  groups: [],
  total: 0,
  keyword: "",
  chosenRecord: [],
  isRefresh: false,
  chosenGroup: {
    id: 0,
    name: 0,
  },
  allMember: {
    name: "メンバー 全員",
    id: 0,
    explanation: "メンバー 全員",
    totalMember: 0,
  },
  filterRole: [],
};

const OM002Handler = (
  om002Service: OM002UseCase,
  om004Service: OM004UseCase
) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") || "1";
  const keyword = searchParams.get("keyword");

  const [groups, setGroups] = useState(InitialState.groups);
  const [groupMembers, setGroupMembers] = useState(InitialState.groups);
  const [total, setTotal] = useState(InitialState.total);
  const [chosenRecord, setChosenRecord] = useState(InitialState.chosenRecord);
  const [allMemberGroup, setAllMemberGroup] = useState(InitialState.allMember);
  const [filterRole, setFilterRole] = useState<any[]>(InitialState.filterRole);

  const refTable: any = useRef();

  const getRoleList = async () => {
    const response = await om004Service.getRoleList({
      page: COMMON.DEFAULT_PAGE,
      size: 100,
    });
    const roleFilterData = response?.results?.filter(
      (element) => element.code !== SYSTEM_ROLES.ADMIN
    );
    roleFilterData.unshift({
      id: 0,
      code: "all",
      name: "全て",
      createdAt: "",
      updatedAt: "",
    });
    setFilterRole(roleFilterData);
  };

  const onFilterRole = (value: any) => {
    searchParams.set("page", COMMON.DEFAULT_PAGE + "");
    searchParams.set("roleId", value + "");
    setSearchParams(searchParams);
  };

  const getGroups = async () => {
    dispatch(setLoading(true));
    try {
      const response = await om002Service.getGroup();
      if (response?.results?.groups?.length > 0) {
        dispatch(setUndefinedGroupId(response.results.groups[0].id));
        setGroups(response.results.groups);
      }
      setAllMemberGroup((prevState) => {
        return {
          ...prevState,
          totalMember: response?.results?.totalMember ?? 0,
        };
      });
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getGroupMember = async (params: any) => {
    if (!params.groupId) {
      dispatch(refreshGroupOM002());
    }
    checkKeywordQueryString(); //
    dispatch(setLoading(true));
    try {
      const response = await om002Service.getGroupMember(params);
      if (response.results.length === 0 && parseInt(params.page) > 1) {
        searchParams.set("page", parseInt(page ?? "2") - 1 + "");
        setSearchParams(searchParams);
        return;
      }
      const members = response.results.map((element: any) => ({
        ...element,
        key: element.id,
      }));
      setGroupMembers(members);
      setTotal(response?.pagination?.count ?? 0);
      getGroups()
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const onSelectRow = (selectedRowKeys: React.Key[], selectedRows: any[]) => {
    const chosenId = selectedRows.map((element) => element.id);
    setChosenRecord(chosenId);
  };

  const onPageChange = (value: number) => {
    checkTableSelection(() => {
      searchParams.set("page", value + "");
      setSearchParams(searchParams);
    });

    if (refTable.current) {
      refTable.current?.children[0]?.children[0]?.children[0]?.children[0]?.children[1]?.scrollTo(
        0,
        0
      );
    }
  };

  const onSizeChange = (value: number) => {
    checkTableSelection(() => {
      searchParams.set("size", value + "");
      searchParams.set("page", COMMON.DEFAULT_PAGE + "");
      setSearchParams(searchParams);
    });
  };

  const onTableChange = (
    pagination: any,
    sorter: any,
    filters: any,
    extra: any
  ) => {
    checkTableSelection(() => {
      searchParams.set("page", COMMON.DEFAULT_PAGE + "");
      searchParams.set("sortBy", filters.columnKey ?? "");
      searchParams.set("sortType", filters.order === "ascend" ? "DESC" : "ASC");
      setSearchParams(searchParams);
    });
  };

  const checkTableSelection = (callback: Function) => {
    if (chosenRecord.length > 0) {
      setChosenRecord(InitialState.chosenRecord);
    }
    callback();
  };

  var myVar: any;
  const onSearch = () => {
    if (myVar) clearTimeout(myVar);
    myVar = setTimeout(function () {
      const searchText = form.getFieldValue("text");
      searchParams.set("keyword", searchText.trim());
      form.setFieldValue("text", searchText.trim());
      searchParams.set("page", COMMON.DEFAULT_PAGE + "");
      setSearchParams(searchParams);
      if (chosenRecord.length > 0) {
        setChosenRecord(InitialState.chosenRecord);
      }
    }, 1000);
  };

  const chooseGroup = (value: any) => {
    checkTableSelection(() => {
      searchParams.delete("roleId");
      searchParams.set("page", COMMON.DEFAULT_PAGE + "");
      searchParams.set("groupId", value.id);
      setSearchParams(searchParams);
    });
  };
  const viewGroup = (value: DetailGroup) => {
    dispatch(setDetailGroup(value));
    dispatch(setIsVisibleOM001(true));
  };

  const deleteGroup = (value: any) => {
    dispatch(setGroupIdOM009(value));
    dispatch(setIsVisibleOM009(true));
  };

  // params: id,workspaceId
  const updateMember = async (id: number) => {
    dispatch(setMemberIdOM006(id));
    dispatch(setIsVisibleOM006(true));
  };

  //params: id, workspaceId
  const deleteMember = async (value: any) => {
    ConfirmModalAsync({
      onOk: async () => {
        dispatch(setLoading(true));
        try {
          const params = {
            listId: [value.id],
          };
          const response = await om002Service.deleteMember(params);
          dispatch(refreshOM002());

          //chosen record
          const index = chosenRecord.indexOf(value.id);
          if (index > -1) {
            const newChosenRecord = [...chosenRecord];
            newChosenRecord.splice(index, 1);
            setChosenRecord(newChosenRecord);
          }
          SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
        } catch (error: any) {
          ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        } finally {
          dispatch(setLoading(false));
        }
      },
      onCancel: () => { },
      className: "confirm__modal confirm__modal-confirm",
      title: MESSAGE.MESSAGE_DELETE_MANY_TIT,
      description: MESSAGE.MESSAGE_DELETE_MANY_DES_1,
      description2: MESSAGE.MESSAGE_DELETE_MANY_DES_2,
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_DELETE,
    });
  };

  const deleteManyMember = async () => {
    ConfirmModalAsync({
      onOk: async () => {
        dispatch(setLoading(true));
        try {
          const params = {
            listId: chosenRecord,
          };
          const response = await om002Service.deleteMember(params);
          dispatch(refreshOM002());
          setChosenRecord(InitialState.chosenRecord);
          SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
        } catch (error: any) {
          ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
        } finally {
          dispatch(setLoading(false));
        }
      },
      onCancel: () => { },
      className: "confirm__modal confirm__modal-confirm",
      title: MESSAGE.MESSAGE_DELETE_MANY_TIT,
      description: MESSAGE.MESSAGE_DELETE_MANY_DES_1,
      description2: MESSAGE.MESSAGE_DELETE_MANY_DES_2,
      canceText: LABEL_MESSAGE.CANCEL_MODAL,
      okText: LABEL_MESSAGE.OK_DELETE,
    });
  };

  const createGroup = () => dispatch(setIsVisibleOM007(true));

  const createMember = (groupId: number) => {
    dispatch(setGroupIdOM004(groupId));
    dispatch(setIsVisibleOM004(true));
    dispatch(setIsVisibleAM014(true));
  };

  const redirectToOM005 = (groupId: string) =>
    navigate(`/app-om005/${groupId}`);

  const checkKeywordQueryString = () => {
    if (form.getFieldValue("text") !== keyword) {
      form.setFieldValue("text", keyword);
    }
  };

  const checkUsingAction = (record: any) => {
    const user = helpers.getObjectFromLocalStorage("user");
    let isAdmin = false;
    let isPlanOrder = false;

    if (user.role.code === "admin") isAdmin = true;
    if (user.order.code !== "") isPlanOrder = true;

    if (isAdmin && isPlanOrder && user.company.email === record.emailAddress)
      return true;

    return false;
  };

  const viewDetailMember = (record: any) => {
    navigate(`/app-om003/${record?.id ?? 0}`);
  };

  return {
    form,
    groups,
    groupMembers,
    total,
    chosenRecord,
    allMemberGroup,
    filterRole,
    onSelectRow,
    onPageChange,
    onTableChange,
    onSizeChange,
    getGroups,
    getGroupMember,
    chooseGroup,
    viewGroup,
    deleteGroup,
    updateMember,
    deleteMember,
    onSearch,
    createGroup,
    createMember,
    deleteManyMember,
    redirectToOM005,
    getRoleList,
    onFilterRole,
    checkUsingAction,
    viewDetailMember,
  };
};

export default OM002Handler;
