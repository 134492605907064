import { AM028Entity } from "../entity/Entity";
import { AM028Api } from "./ServiceImpl";
import COMMON from "../../../../common/constants/COMMON";

const AM028Service = (AM028Api: AM028Api) => {
  const getOrganizationIsUserApprover = async (): Promise<any> => {
    try {
      const response = await AM028Api.getOrganizationIsUserApprover();
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getListMonthlyAttendance = async (params: any): Promise<any[]> => {
    try {
      return await AM028Api.getListMonthlyAttendance({
        ...params,
        timeOffset: COMMON.TIME_ZONE,
      });
    } catch (error) {
      throw error;
    }
  };

  const getListMyAttendance = async (params: any): Promise<any[]> => {
    try {
      return await AM028Api.getListMyAttendance({
        ...params,
        timeOffset: COMMON.TIME_ZONE,
      });
    } catch (error) {
      throw error;
    }
  };
  const getListMyReviewAttendance = async (params: any): Promise<any[]> => {
    try {
      return await AM028Api.getListMyReviewAttendance({
        ...params,
        timeOffset: COMMON.TIME_ZONE,
      });
    } catch (error) {
      throw error;
    }
  };
  const getListMonthlyLateEarly = async (params: any): Promise<any[]> => {
    try {
      return await AM028Api.getListMonthlyLateEarly({
        ...params,
        timeOffset: COMMON.TIME_ZONE,
      });
    } catch (error) {
      throw error;
    }
  };
  const getListMyLateEarly = async (params: any): Promise<any[]> => {
    try {
      return await AM028Api.getListMyLateEarly({
        ...params,
        timeOffset: COMMON.TIME_ZONE,
      });
    } catch (error) {
      throw error;
    }
  };
  const getListReviewLateEarly = async (params: any): Promise<any[]> => {
    try {
      return await AM028Api.getListReviewLateEarly({
        ...params,
        timeOffset: COMMON.TIME_ZONE,
      });
    } catch (error) {
      throw error;
    }
  };
  const getListMonthlyLeave = async (params: any): Promise<any[]> => {
    try {
      return await AM028Api.getListMonthlyLeave({
        ...params,
        timeOffset: COMMON.TIME_ZONE,
      });
    } catch (error) {
      throw error;
    }
  };
  const getListMyLeave = async (params: any): Promise<any[]> => {
    try {
      return await AM028Api.getListMyLeave({
        ...params,
        timeOffset: COMMON.TIME_ZONE,
      });
    } catch (error) {
      throw error;
    }
  };
  const getListMyReviewLeave = async (params: any): Promise<any[]> => {
    try {
      return await AM028Api.getListMyReviewLeave({
        ...params,
        timeOffset: COMMON.TIME_ZONE,
      });
    } catch (error) {
      throw error;
    }
  };

  const getListStatus = async (params: any): Promise<any[]> => {
    try {
      return await AM028Api.getListStatus(params);
    } catch (error) {
      throw error;
    }
  };
  const getAttendanceCategory = async (params: any): Promise<any[]> => {
    try {
      return await AM028Api.getAttendanceCategory(params);
    } catch (error) {
      throw error;
    }
  };

  const getLeaveCategory = async (params: any): Promise<any[]> => {
    try {
      return await AM028Api.getLeaveCategory(params);
    } catch (error) {
      throw error;
    }
  };
  const getLateEarlyCategory = async (params: any): Promise<any[]> => {
    try {
      return await AM028Api.getLateEarlyCategory(params);
    } catch (error) {
      throw error;
    }
  };

  const approveAttendance = async (params: any): Promise<any[]> => {
    try {
      return await AM028Api.approveAttendance(params);
    } catch (error) {
      throw error;
    }
  };

  const approveLateEarly = async (params: any): Promise<any[]> => {
    try {
      return await AM028Api.approveLateEarly(params);
    } catch (error) {
      throw error;
    }
  };

  const approveLeave = async (params: any): Promise<any[]> => {
    try {
      return await AM028Api.approveLeave(params);
    } catch (error) {
      throw error;
    }
  };

  const postRejectReason = async (params: any): Promise<any[]> => {
    try {
      return await AM028Api.postRejectReason(params);
    } catch (error) {
      throw error;
    }
  };

  return {
    getListMonthlyAttendance,
    getListMyAttendance,
    getListMyReviewAttendance,
    getListMonthlyLateEarly,
    getListMyLateEarly,
    getListReviewLateEarly,
    getListMonthlyLeave,
    getListMyLeave,
    getListMyReviewLeave,
    getListStatus,
    getAttendanceCategory,
    getLeaveCategory,
    getLateEarlyCategory,
    approveAttendance,
    approveLateEarly,
    approveLeave,
    postRejectReason,
    getOrganizationIsUserApprover,
  };
};

export default AM028Service;
