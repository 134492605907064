// Built-in
import { useState } from "react";

// 3rd party lib
import { useDispatch } from "react-redux";

// Source files
import { DoubleCheckServiceImpl } from "../../usecase/ServiceImpl";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../../common/constants/MESSAGE";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import { refreshAM001 } from "../slice/Slice";

const DoubleCheckHandler = (service: DoubleCheckServiceImpl) => {
  const dispatch = useDispatch();

  const [isDoubleCheck, setIsDoubleCheck] = useState<boolean | null>(null);

  const postAttendanceSubConstructionMemberDoubleCheck = async (data: {
    constructionId: number;
    doubleCheck: null | boolean;
    userTimeSheetId: number;
  }): Promise<any> => {
    try {
      dispatch(setLoading(true));
      const response =
        await service.postAttendanceSubConstructionMemberDoubleCheck(data);
      SuccessNotification(response?.message ?? NOTIFICATION_TITLE.SUCCESS);
      setIsDoubleCheck(data.doubleCheck);
      dispatch(refreshAM001());
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const initiateData = (defaultValue: boolean | null) => {
    setIsDoubleCheck(defaultValue);
  };

  return {
    postAttendanceSubConstructionMemberDoubleCheck,
    initiateData,
    isDoubleCheck,
  };
};

export default DoubleCheckHandler;
