import { AxiosResponse } from 'axios';
import axiosConfig from '../../../../../config/axiosConfig';
import {
    MoneyDirectCostOutsourceEnable,
    MoneySubmitDirectCostOutsourcePayload,
} from '../../entity/Entity';

const MaterialCostApi = () => {
    const getMoneyDirectCostOutsourceOffsetList = async (params: {
        outsourceCostInformationId: number;
        categoryId?: number;
        keyword?: number;
        sortBy?: string;
        sortType?: string;
        page?: number;
        size?: number;
    }): Promise<AxiosResponse<any, any>> => {
        const url = '/money/direct-cost/outsource/offset/list';
        return await axiosConfig.get(url, { params });
    };
    const getMoneyDirectCostOutsourceInformationList = async (params: {
        constructionId: number;
        page?: number;
        size?: number;
        sortBy?: string;
        sortType?: string;
        keyword?: string;
    }): Promise<AxiosResponse<any, any>> => {
        const url = '/money/direct-cost/outsource/information/list';
        return await axiosConfig.get(url, { params });
    };
    const putMoneyDirectCostOutsourceEnable = async (
        data: MoneyDirectCostOutsourceEnable,
    ): Promise<AxiosResponse<any, any>> => {
        const url = '/money/direct-cost/outsource/enable';
        return await axiosConfig.put(url, data);
    };
    const getMoneyDirectCostOutsourceList = async (params: {
        outsourceCostId: number;
        page?: number;
        size?: number;
        sortBy?: string;
        sortType?: string;
    }): Promise<AxiosResponse<any, any>> => {
        const url = '/money/direct-cost/outsource/list';
        return await axiosConfig.get(url, { params });
    };
    const putMoneySubmitDirectCostOutsource = async (
        data: MoneySubmitDirectCostOutsourcePayload,
    ): Promise<AxiosResponse<any, any>> => {
        const url = '/money/submit/direct-cost/outsource';
        return await axiosConfig.put(url, data);
    };
    /**
     * 
     * @param params 
     * @returns 
     */
    const doExportCM009 = async (params: any): Promise<any> => {
        try {
            const url = '/money/exportCM009';
            const response = await axiosConfig.get(url, { params, responseType: 'blob'  });
            
            return response;
        } catch (error) {
            throw error;
        }
    };
    return {
        getMoneyDirectCostOutsourceInformationList,
        putMoneyDirectCostOutsourceEnable,
        getMoneyDirectCostOutsourceList,
        putMoneySubmitDirectCostOutsource,
        getMoneyDirectCostOutsourceOffsetList,
        doExportCM009,
    };
};

export default MaterialCostApi;
