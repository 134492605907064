import helpers from '../../../../../common/helpers/common';
import { LEVEL_COLOR } from '../../../../PM/PM011/components/sub-construction/presenter/ui/UI';
import FirstLevelSvg from '../../../../../assets/icons/first-level.svg';
import { ConfigProvider, Tooltip } from 'antd';

interface props {
  record: any;
}

const CompanyRowComponent = ({ record }: props) => {
  const content = (
    <main
      className="table-cell-inside"
      style={{ display: 'flex' }}
    >
      <div style={{ display: 'flex', width: "45.7%", justifyContent: 'flex-start', marginRight: 64 }}>
        {!record.treeLevel ? (
          <></>
        ) : (
          <div
            style={{
              height: 22,
              marginLeft: LEVEL_COLOR[`LEVEL${record.treeLevel || '1'}`].padding,
            }}
          >
            {record.treeLevel === 1 ? (
              <></>
            ) : (
              helpers.getDirectoryIcon(LEVEL_COLOR[`LEVEL${record.treeLevel || '1'}`].color)
            )}
          </div>
        )}
        <div
          style={{
            marginRight: 36,
            marginLeft: record.treeLevel === 1 ? 0 : 16,
          }}
        >
          {record.treeLevel === 1
            ? `${record.companyName}`
            : `${record.companyName} (${record.description})`}
        </div>
        <div
          className="level-badge center-item"
          style={{
            backgroundColor: LEVEL_COLOR[`LEVEL${record.treeLevel || '1'}`].background,
            color: LEVEL_COLOR[`LEVEL${record.treeLevel || '1'}`].color,
            direction: 'ltr',
          }}
        >
          {`（${record.titleLevel || ''}）`}
        </div>
      </div>
      <div
        className='center-item responsive-width-34 ' >
        <label className="money-label">合計金額：</label>
        <span className="money-label-number" >{`${helpers.formatCurrency(
          record.totalLaborCost,
        )}円`}</span>
      </div>
      <div style={{ marginRight: 0 }}>
        <label className="money-label">稼働人工数：</label>
        <span
          className="money-label-number"
        >
          {`${helpers.formatCurrency(record.totalWorkingDay)}人工`}
        </span>
      </div>
    </main>
  );
  return record.treeLevel && record.treeLevel !== 1 ? (
    content
  ) : (
    <Tooltip title="クリックして詳細を表示します。">{content}</Tooltip>
  );
};

export default CompanyRowComponent;
