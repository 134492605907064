import { CCUS02Handler } from "../handler/HandlerImpl";
import { ColumnsType } from "antd/es/table";
import { DataTableType } from "../../entity/Entity";
import SortTable from "../../../../../common/components/sort-table/SortTable";
import { MENU_ITEM } from "../../constants";
import IconInfoRed from "../../../../../assets/icons/info-square-red.svg";
import IconInfoGray from "../../../../../assets/icons/icon-info-square.svg";
import IconSortUp from "../../../../../assets/icons/bx_bxs-up-arrow.svg";
import { Tooltip } from "antd";
import moment from "moment";
import COMMON from "../../../../../common/constants/COMMON";
import { useEffect, useMemo, useState } from "react";

type props = {
  sortOrder: "ASC" | "DESC" | undefined;
  columnTitle: string;

  onChange?: (sortOrder: "ASC" | "DESC") => void;
};

const SortApprovalTime = ({ sortOrder, columnTitle, onChange }: props) => {
  const [tooltipContent, setTooltipContent] = useState(getContent());

  function getContent() {
    return (
      <>
        {`${moment()
          .set({ hour: 19, minute: 59, second: 59 })
          .format(
            COMMON.FORMAT_DATE_TIME2
          )} までに承認された就業履歴情報は ${moment().format(
          COMMON.FORMAT_DATE
        )} 20時以降にCCUSへ送信されます。`}
        <br />
        {`それ以降に承認された就業履歴情報は ${moment()
          .add(1, "days")
          .format(COMMON.FORMAT_DATE)} 20時以降にCCUSへ送信されます。`}
      </>
    );
  }

  // Update tooltip content every second
  useEffect(() => {
    const interval = setInterval(() => {
      setTooltipContent(getContent());
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  return (
    <div className="table-sort-wrapper-ccus">
      <div style={{ marginRight: "8px" }}> {columnTitle}</div>
      <Tooltip title={tooltipContent}>
        <img src={IconInfoGray} />
      </Tooltip>
      <div
        className="table-sort"
        onClick={() =>
          onChange && onChange(sortOrder === "ASC" ? "DESC" : "ASC")
        }
      >
        <img
          alt=""
          src={IconSortUp}
          className={`icon-up ${sortOrder === "ASC" ? "icon-sorted" : ""}`}
        />
        <img
          alt=""
          src={IconSortUp}
          className={`icon-down ${sortOrder === "DESC" ? "icon-sorted" : ""}`}
        />
      </div>
    </div>
  );
};

export const COLUMN = (handler: CCUS02Handler) => {
  const sortType = useMemo(() => {
    return handler.sortType;
  }, [handler.sortType]);
  const sortBy = useMemo(() => {
    return handler.sortBy;
  }, [handler.sortBy]);

  const columnsTab1: ColumnsType<DataTableType> = [
    {
      title: "No",
      dataIndex: "No",
      width: 57,
      render: (text) => <div className="text-table-ccus">{text}</div>,
    },
    {
      title: (
        <SortTable
          columnTitle="案件名"
          sortOrder={handler.funcCheckSortOrder("project", sortType, sortBy)}
          onChange={(sortOrder) => handler.funcSortTable("project", sortOrder)}
          justifyContent="start"
        />
      ),
      dataIndex: "project",
      render: (text) => <div className="text-table-ccus">{text}</div>,
      width: 130,
    },
    {
      title: (
        <SortTable
          columnTitle="所属事業者名"
          sortOrder={handler.funcCheckSortOrder("company", sortType, sortBy)}
          onChange={(sortOrder) => handler.funcSortTable("company", sortOrder)}
          justifyContent="start"
        />
      ),
      dataIndex: "company",
      render: (text) => <div className="text-table-ccus">{text}</div>,
      width: 140,
    },
    {
      title: (
        <SortTable
          columnTitle="技能者名"
          sortOrder={handler.funcCheckSortOrder("user", sortType, sortBy)}
          onChange={(sortOrder) => handler.funcSortTable("user", sortOrder)}
          justifyContent="start"
        />
      ),
      dataIndex: "user",
      render: (text) => <div className="text-table-ccus">{text}</div>,
      width: 135,
    },
    {
      title: (
        <SortTable
          columnTitle="対象日"
          sortOrder={handler.funcCheckSortOrder(
            "attendanceDate",
            sortType,
            sortBy
          )}
          onChange={(sortOrder) =>
            handler.funcSortTable("attendanceDate", sortOrder)
          }
          justifyContent="start"
        />
      ),
      dataIndex: "attendanceDate",
      render: (text) => <div className="text-table-ccus">{text}</div>,
      width: 120,
    },
    {
      title: (
        <SortTable
          columnTitle="出勤時間"
          sortOrder={handler.funcCheckSortOrder("checkin", sortType, sortBy)}
          onChange={(sortOrder) => handler.funcSortTable("checkin", sortOrder)}
          justifyContent="start"
        />
      ),
      dataIndex: "checkin",
      render: (text) => <div className="text-table-ccus">{text}</div>,
      width: 110,
    },
    {
      title: (
        <SortTable
          columnTitle="退勤時間"
          sortOrder={handler.funcCheckSortOrder("checkout", sortType, sortBy)}
          onChange={(sortOrder) => handler.funcSortTable("checkout", sortOrder)}
          justifyContent="start"
        />
      ),
      dataIndex: "checkout",
      render: (text) => <div className="text-table-ccus">{text}</div>,
      width: 110,
    },
    {
      title: (
        <SortTable
          columnTitle="登録区分"
          sortOrder={handler.funcCheckSortOrder(
            "registerType",
            sortType,
            sortBy
          )}
          onChange={(sortOrder) =>
            handler.funcSortTable("registerType", sortOrder)
          }
          justifyContent="start"
        />
      ),
      dataIndex: "registerType",
      render: (text) => <div className="text-table-ccus">{text}</div>,
      width: 115,
    },

    {
      title: (
        <SortTable
          columnTitle="登録日"
          sortOrder={handler.funcCheckSortOrder("syncTime", sortType, sortBy)}
          onChange={(sortOrder) => handler.funcSortTable("syncTime", sortOrder)}
          justifyContent="start"
        />
      ),
      dataIndex: "syncTime",
      render: (text) => <div className="text-table-ccus">{text}</div>,
      width: 120,
    },
    {
      title: (
        <SortTable
          columnTitle="元請事業者承認者"
          sortOrder={handler.funcCheckSortOrder("approver", sortType, sortBy)}
          onChange={(sortOrder) => handler.funcSortTable("approver", sortOrder)}
          justifyContent="start"
        />
      ),
      dataIndex: "approver",
      render: (text) => <div className="text-table-ccus">{text}</div>,
      width: 170,
    },
  ];

  const columnsTab2: ColumnsType<DataTableType> = [
    {
      title: "No",
      dataIndex: "No",
      width: 57,
      render: (text) => <div className="text-table-ccus">{text}</div>,
    },
    {
      title: (
        <SortTable
          columnTitle="案件名"
          sortOrder={handler.funcCheckSortOrder("project", sortType, sortBy)}
          onChange={(sortOrder) => handler.funcSortTable("project", sortOrder)}
          justifyContent="start"
        />
      ),
      dataIndex: "project",
      render: (text) => <div className="text-table-ccus">{text}</div>,
      width: 130,
    },
    {
      title: (
        <SortTable
          columnTitle="所属事業者名"
          sortOrder={handler.funcCheckSortOrder("company", sortType, sortBy)}
          onChange={(sortOrder) => handler.funcSortTable("company", sortOrder)}
          justifyContent="start"
        />
      ),
      dataIndex: "company",
      render: (text) => <div className="text-table-ccus">{text}</div>,
      width: 140,
    },
    {
      title: (
        <SortTable
          columnTitle="技能者名"
          sortOrder={handler.funcCheckSortOrder("user", sortType, sortBy)}
          onChange={(sortOrder) => handler.funcSortTable("user", sortOrder)}
          justifyContent="start"
        />
      ),
      dataIndex: "user",
      render: (text) => <div className="text-table-ccus">{text}</div>,
      width: 135,
    },
    {
      title: (
        <SortTable
          columnTitle="対象日"
          sortOrder={handler.funcCheckSortOrder(
            "attendanceDate",
            sortType,
            sortBy
          )}
          onChange={(sortOrder) =>
            handler.funcSortTable("attendanceDate", sortOrder)
          }
          justifyContent="start"
        />
      ),
      dataIndex: "attendanceDate",
      render: (text) => <div className="text-table-ccus">{text}</div>,
      width: 120,
    },
    {
      title: (
        <SortTable
          columnTitle="出勤時間"
          sortOrder={handler.funcCheckSortOrder("checkin", sortType, sortBy)}
          onChange={(sortOrder) => handler.funcSortTable("checkin", sortOrder)}
          justifyContent="start"
        />
      ),
      dataIndex: "checkin",
      render: (text) => <div className="text-table-ccus">{text}</div>,
      width: 110,
    },
    {
      title: (
        <SortTable
          columnTitle="退勤時間"
          sortOrder={handler.funcCheckSortOrder("checkout", sortType, sortBy)}
          onChange={(sortOrder) => handler.funcSortTable("checkout", sortOrder)}
          justifyContent="start"
        />
      ),
      dataIndex: "checkout",
      render: (text) => <div className="text-table-ccus">{text}</div>,
      width: 110,
    },
    {
      title: (
        <SortTable
          columnTitle="登録区分"
          sortOrder={handler.funcCheckSortOrder(
            "registerType",
            sortType,
            sortBy
          )}
          onChange={(sortOrder) =>
            handler.funcSortTable("registerType", sortOrder)
          }
          justifyContent="start"
        />
      ),
      width: 120,
      dataIndex: "registerType",
      render: (text) => <div className="text-table-ccus">{text}</div>,
    },
    {
      title: (
        <SortTable
          columnTitle="元請事業者承認者"
          sortOrder={handler.funcCheckSortOrder("approver", sortType, sortBy)}
          onChange={(sortOrder) => handler.funcSortTable("approver", sortOrder)}
          justifyContent="start"
        />
      ),
      dataIndex: "approver",
      width: 170,
      render: (text) => <div className="text-table-ccus">{text}</div>,
    },
    {
      title: "エラー",
      render: (_, record) => {
        return record.message ? (
          <div
            className="button-style"
            style={{ display: "flex", justifyContent: "center" }}
            onClick={() => handler.openModalError(record.message)}
          >
            <img alt="" src={IconInfoRed} />
          </div>
        ) : (
          "-"
        );
      },
      width: 80,
    },
  ];

  const columnsTab3: ColumnsType<DataTableType> = [
    {
      title: "No",
      dataIndex: "No",
      width: 57,
      render: (text) => <div className="text-table-ccus">{text}</div>,
    },
    {
      title: (
        <SortTable
          columnTitle="案件名"
          sortOrder={handler.funcCheckSortOrder("project", sortType, sortBy)}
          onChange={(sortOrder) => handler.funcSortTable("project", sortOrder)}
          justifyContent="start"
        />
      ),
      dataIndex: "project",
      render: (text) => <div className="text-table-ccus">{text}</div>,
      width: 130,
    },
    {
      title: (
        <SortTable
          columnTitle="所属事業者名"
          sortOrder={handler.funcCheckSortOrder("company", sortType, sortBy)}
          onChange={(sortOrder) => handler.funcSortTable("company", sortOrder)}
          justifyContent="start"
        />
      ),
      dataIndex: "company",
      render: (text) => <div className="text-table-ccus">{text}</div>,
      width: 140,
    },
    {
      title: (
        <SortTable
          columnTitle="技能者名"
          sortOrder={handler.funcCheckSortOrder("user", sortType, sortBy)}
          onChange={(sortOrder) => handler.funcSortTable("user", sortOrder)}
          justifyContent="start"
        />
      ),
      dataIndex: "user",
      render: (text) => <div className="text-table-ccus">{text}</div>,
      width: 125,
    },
    {
      title: (
        <SortTable
          columnTitle="対象日"
          sortOrder={handler.funcCheckSortOrder(
            "attendanceDate",
            sortType,
            sortBy
          )}
          onChange={(sortOrder) =>
            handler.funcSortTable("attendanceDate", sortOrder)
          }
          justifyContent="start"
        />
      ),
      dataIndex: "attendanceDate",
      render: (text) => <div className="text-table-ccus">{text}</div>,
      width: 110,
    },
    {
      title: (
        <SortTable
          columnTitle="出勤時間"
          sortOrder={handler.funcCheckSortOrder("checkin", sortType, sortBy)}
          onChange={(sortOrder) => handler.funcSortTable("checkin", sortOrder)}
          justifyContent="start"
        />
      ),
      dataIndex: "checkin",
      render: (text) => <div className="text-table-ccus">{text}</div>,
      width: 110,
    },
    {
      title: (
        <SortTable
          columnTitle="退勤時間"
          sortOrder={handler.funcCheckSortOrder("checkout", sortType, sortBy)}
          onChange={(sortOrder) => handler.funcSortTable("checkout", sortOrder)}
          justifyContent="start"
        />
      ),
      dataIndex: "checkout",
      render: (text) => <div className="text-table-ccus">{text}</div>,
      width: 110,
    },
    {
      title: (
        <SortTable
          columnTitle="登録区分"
          sortOrder={handler.funcCheckSortOrder(
            "registerType",
            sortType,
            sortBy
          )}
          onChange={(sortOrder) =>
            handler.funcSortTable("registerType", sortOrder)
          }
          justifyContent="start"
        />
      ),
      width: 110,
      dataIndex: "registerType",
      render: (text) => <div className="text-table-ccus">{text}</div>,
    },
    {
      title: (
        <SortTable
          columnTitle="元請事業者承認者"
          sortOrder={handler.funcCheckSortOrder("approver", sortType, sortBy)}
          onChange={(sortOrder) => handler.funcSortTable("approver", sortOrder)}
          justifyContent="start"
        />
      ),
      dataIndex: "approver",
      render: (text) => <div className="text-table-ccus">{text}</div>,
      width: 170,
    },
    {
      title: (
        <SortApprovalTime
          columnTitle="承認時間"
          sortOrder={handler.funcCheckSortOrder("approvedAt", sortType, sortBy)}
          onChange={(sortOrder) =>
            handler.funcSortTable("approvedAt", sortOrder)
          }
        />
      ),
      dataIndex: "approvedAt",
      render: (text) => <div className="text-table-ccus">{text}</div>,
      width: 150,
    },
  ];

  switch (handler.state) {
    case MENU_ITEM[1].code:
      return columnsTab3;
    case MENU_ITEM[2].code:
      return columnsTab2;
    default:
      return columnsTab1;
  }
};
