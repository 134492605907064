import {
  PM012ListSubcontractorEntity,
  PM012SubcontractorInformationEntity,
} from "../entity/PM012Entity";
import { PM012Api } from "./ServiceImpl";

const PM012Service = (pm012Api: PM012Api) => {
  const getListConstruction = async (params: any): Promise<any> => {
    try {
      return await pm012Api.getListConstruction(params);
    } catch (error) {
      throw error;
    }
  };

  const getMySubcontractor = async (params: any): Promise<any> => {
    try {
      return await pm012Api.getMySubcontractor(params);
    } catch (error) {
      throw error;
    }
  };
  const getMyInvitedMember = async (params: any): Promise<any> => {
    try {
      return await pm012Api.getMyInvitedMember(params);
    } catch (error) {
      throw error;
    }
  };
  const getMyUninvitedMember = async (params: any): Promise<any> => {
    try {
      return await pm012Api.getMyUninvitedMember(params);
    } catch (error) {
      throw error;
    }
  };
  const addCompanyForConstruction = async (data: any): Promise<any> => {
    try {
      return await pm012Api.addCompanyForConstruction(data);
    } catch (error) {
      throw error;
    }
  };
  const inviteMemberToConstruction = async (data: any): Promise<any> => {
    try {
      return await pm012Api.inviteMemberToConstruction(data);
    } catch (error) {
      throw error;
    }
  };
  const unInviteMemberToConstruction = async (data: any): Promise<any> => {
    try {
      return await pm012Api.unInviteMemberToConstruction(data);
    } catch (error) {
      throw error;
    }
  };

  const updateDoubleCheck = async (data: any): Promise<any> => {
    try {
      return await pm012Api.updateDoubleCheck(data);
    } catch (error) {
      throw error;
    }
  };
  const getListRole = async (params?: any): Promise<any> => {
    try {
      return await pm012Api.getListRole(params);
    } catch (error) {
      throw error;
    }
  };

  const getSubcontractorinformation = async (params: any): Promise<any> => {
    try {
      const response = await pm012Api.getSubcontractorinformation(params);
      return response?.data?.results ?? {};
    } catch (error) {
      throw error;
    }
  };

  const getSubcontractorListMemberParent = async (
    params: any
  ): Promise<any> => {
    try {
      return await pm012Api.getSubcontractorListMemberParent(params);
    } catch (error) {
      throw error;
    }
  };

  const getListSubcontractor = async (params: any): Promise<any> => {
    try {
      const response = await pm012Api.getListSubcontractor(params);
      return response?.data?.results ?? [];
    } catch (error) {
      throw error;
    }
  };

  const updateContractorLevel = async (data: any): Promise<any> => {
    try {
      const response = await pm012Api.updateContractorLevel(data);
      return response?.data?.message ?? "";
    } catch (error) {
      throw error;
    }
  };

  const getSubcontractorListMember = async (params: any): Promise<any> => {
    try {
      return await pm012Api.getSubcontractorListMember(params);
    } catch (error) {
      throw error;
    }
  };

  const inviteSubcontractorMember = async (data: any): Promise<any> => {
    try {
      const response = await pm012Api.inviteSubcontractorMember(data);
      return response?.data?.message ?? "";
    } catch (error) {
      throw error;
    }
  };

  const updateLaborCost = async (data: any): Promise<any> => {
    try {
      const response = await pm012Api.updateLaborCost(data);
      return response?.data?.message ?? "";
    } catch (error) {
      throw error;
    }
  };
  const uninviteSubcontractorMember = async (data: any): Promise<any> => {
    try {
      const response = await pm012Api.uninviteSubcontractorMember(data);
      return response?.data?.message ?? "";
    } catch (error) {
      throw error;
    }
  };

  const removeSubcontractorMember = async (data: any): Promise<any> => {
    try {
      const response = await pm012Api.removeSubcontractorMember(data);
      return response?.data?.message ?? "";
    } catch (error) {
      throw error;
    }
  };

  return {
    getListConstruction,
    getMySubcontractor,
    getMyUninvitedMember,
    getMyInvitedMember,
    addCompanyForConstruction,
    inviteMemberToConstruction,
    unInviteMemberToConstruction,
    getListRole,
    updateDoubleCheck,

    // API PM014
    getSubcontractorinformation,
    getSubcontractorListMemberParent,
    getListSubcontractor,
    updateContractorLevel,
    getSubcontractorListMember,
    inviteSubcontractorMember,
    updateLaborCost,
    uninviteSubcontractorMember,
    removeSubcontractorMember,
  };
};

export default PM012Service;
