import { SM04Api } from './ServiceImpl';

const SM04Service = (sm04: SM04Api) => {
    const getDetailSchedule = async (params: { scheduleId: number }): Promise<any> => {
        return await sm04.getDetailSchedule(params);
    };

    const postComment = async (payload: { content: string }, scheduleId: number): Promise<void> => {
        const response = await sm04.postComment(payload, scheduleId);
        return response?.data;
    };
    const getListComment = async (params: { page: number; size: number; sortBy: string }, scheduleId: number) => {
        const response = await sm04.getListComment(params, scheduleId);
        return response?.data;
    };

    const putEditComment = async (payload: { content: string }, scheduleId: number, commentId: number): Promise<void> => {
        const response = await sm04.putEditComment(payload, scheduleId, commentId);
        return response?.data;
    };

    const deleteComment = async (scheduleId: number, commentId: number): Promise<void> => {
        const response = await sm04.deleteComment(scheduleId, commentId);
        return response?.data;
    };

    return {
        getDetailSchedule,
        postComment,
        getListComment,
        putEditComment,
        deleteComment
    };
};

export default SM04Service;
