import React from "react";
import { AM028BodyHandler } from "../handler/HandlerImpl";
import Menu from "./Menu";
import Filter from "./Filter";
import Table from "./Table";
import ModalAM037 from "./ModalAM037";
import ModalAM038 from "./ModalAM038";
import { Button } from "antd";
import ApproveConfirmModal from "../../../../../common/components/modal-approve-confirm";

interface props {
  handler: AM028BodyHandler;
}

function Body({ handler }: props) {
  return (
    <div className="am028-body">
      <div className="am028-nav">
        <Menu handler={handler} />
        <div className="am028-nav-btn">
          {handler.currentTab === "2" ? (
            <>
              <Button
                size="large"
                className="button-purple am028-btn-purple"
                onClick={handler.showConfirmApproveModal}
                disabled={
                  handler.funcFilterKeyApproveReject(
                    handler.selectedRowKeys,
                    handler.dataTable
                  )?.length === 0
                }
              >
                承認
              </Button>
              <Button
                size="large"
                className="btn-nav am028-btn-gray-red"
                onClick={handler.funcReject}
                disabled={
                  handler.funcFilterKeyApproveReject(
                    handler.selectedRowKeys,
                    handler.dataTable
                  )?.length === 0
                }
              >
                否認
              </Button>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <Filter handler={handler} />
      <Table handler={handler} />
      <ModalAM037 handler={handler} />
      <ModalAM038 handler={handler} />
      <ApproveConfirmModal onSubmit={handler.funcApprove} />
    </div>
  );
}

export default Body;
