import { AxiosResponse } from "axios";
import axiosConfig from "../../../../../config/axiosConfig";

const CM0019Api = () => {
  const getProjectAlls = async (params: {
    keyword?: string;
    statusId?: string;
    page?: number;
    size?: number;
    sortBy?: string;
    sortType?: string;
  }): Promise<AxiosResponse<any, any>> => {
    const url = "/project/alls";
    return await axiosConfig.get(url, { params });
  };

  const getReSummaryCostProjectList = async (params: any): Promise<any> => {
    const url = "/re-summary/cost/project/list";
    return await axiosConfig.get(url, { params });
  };

  const getReSummaryCostProjectConstructionList = async (
    params: any
  ): Promise<any> => {
    const url = "/re-summary/cost/project/construction/list";
    return await axiosConfig.get(url, { params });
  };

  const getLineSummary = async (params: any): Promise<any> => {
    const url = "/re-summary/cost/project/list/summary";
    return await axiosConfig.get(url, { params });
  };

  const getTaxSetting = async (params: any): Promise<any> => {
    const url = "/tax-setting";
    return await axiosConfig.get(url, { params });
  };

  /**
   * 
   * @param params 
   * @returns 
   */
  const doExportCM0019 = async (params: any): Promise<any> => {
      try {
          const url = '/re-summary/exportCM0019';
          const response = await axiosConfig.get(url, { params, responseType: 'blob'  });
          
          return response;
      } catch (error) {
          throw error;
      }
  };
  return {
    getProjectAlls,
    getReSummaryCostProjectList,
    getReSummaryCostProjectConstructionList,
    getLineSummary,
    getTaxSetting,
    doExportCM0019,
  };
};

export default CM0019Api;
