import BreadcrumbComponent from '../../../../../common/components/breadcrumb/BreadcrumbComponent';
import MenuWrapperComponent from '../../../../../common/components/menu-wrapper';
import { HandlerImpl } from '../handler/HandlerImpl';
import HomeSvg from '../../../../../assets/icons/home.svg';
import SN01Component from './SN01Component';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

interface props {
    handler: HandlerImpl;
}

const BREADCRUMB_SN01: any[] = [
    {
        title: '設定',
        url: '',
        icon: <img style={{ paddingBottom: 2, paddingRight: 8 }} alt="" src={HomeSvg} />,
    },
    {
        title: '通知設定',
        url: '',
        icon: '',
    },
];

const BREADCRUMB_PM_SN01 = (path: string) => {
    return [
        {
            title: '案件管理',
            url: path,
            icon: <img style={{ paddingBottom: 2, paddingRight: 8 }} alt="" src={HomeSvg} />,
        },
        {
            title: '通知設定',
            url: '',
            icon: '',
        },
    ];
};

const PATH_02 = '/app-pm002/sn01';
const PATH_03 = '/app-pm003/sn01';

const MainUI = ({ handler }: props) => {
    const location = useLocation();
    const breadcrumb = useMemo(() => {
        if (location.pathname === PATH_02) {
            return BREADCRUMB_PM_SN01('/app-pm002');
        }
        if (location.pathname === PATH_03) {
            return BREADCRUMB_PM_SN01('/app-pm003');
        }
        return BREADCRUMB_SN01;
    }, [location]);

    const keyOpen = useMemo(() => {
        if (location.pathname === PATH_02 || location.pathname === PATH_03) {
            return {
                chosen: 81,
                open: 8,
            };
        }
        return {
            chosen: 75,
            open: 7,
        };
    }, [location]);
    return (
        <MenuWrapperComponent chosenKey={keyOpen.chosen} openKey={keyOpen.open}>
            <div className="sn01-wrapper">
                <BreadcrumbComponent data={breadcrumb} />
                <h2 className="sn01-header">通知設定</h2>
                <div className="sn01-body">
                    <SN01Component handler={handler} />
                </div>
            </div>
        </MenuWrapperComponent>
    );
};

export default MainUI;
