import { Button, Form, Modal, Spin } from "antd";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import "../../../../../assets/styles/SM/SM04.css";
import LoadingComponent from "../../../../../common/components/icons/LoadingComponent";
import { RootState } from "../../../../../store";
import { SM04Hanlder } from "../handler/HandlerImpl";
import Delete from "../../../../../assets/icons/delete-red.svg";
import XIconModal from "../../../../../assets/icons/x-icon-modal.svg";
import COMMON from "../../../../../common/constants/COMMON";
import moment from "moment";
import SM06Component from "../../../SM06";
import SM03Component from "../../../SM03";
import { Member } from "../type/Presenter";
import CommentComponent from "./CommentComponent";
import helpers from "../../../../../common/helpers/common";

type Props = {
  handler: SM04Hanlder;
};

const SM04UI = ({ handler }: Props) => {
  const isVisible = useSelector((state: RootState) => state.sm04.isVisible);
  const loading = useSelector((state: RootState) => state.sm04.loading);
  const scheduleId = useSelector((state: RootState) => state.sm04.scheduleId);
  const isVisibleSM06 = useSelector((state: RootState) => state.sm06.isVisible);
  const user = helpers.getObjectFromLocalStorage("user");

  useEffect(() => {
    if (scheduleId) handler.getDetailSchedule(scheduleId);
  }, [scheduleId]);

  useEffect(() => {
    handler.getListComment({
      page: handler.page,
      size: COMMON.DEFAULT_SIZE,
      sortBy: "oldest",
    });
  }, [handler.page, handler.isRefresh]);

  return (
    <Modal
      className="group-detail-modal modal-sm04"
      style={{ marginLeft: 300, marginTop: 40, marginBottom: 40, padding: 0 }}
      visible={isVisible}
      closable={false}
      maskClosable={false}
      width={"996px"}
      centered={true}
      footer={null}
    >
      <Spin indicator={LoadingComponent} spinning={loading}>
        <div
          className="w-100-percent header-modal-containter"
          style={{ height: 50, padding: "0 24px" }}
        >
          <div className="header-modal-title-om004 ">イベント詳細</div>
          <div style={{ cursor: "pointer" }} onClick={handler.onCancel}>
            <img alt="" src={XIconModal} style={{ height: 18, width: 18 }} />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 24,
            padding: "40px 46px",
            borderBottom: "1px solid #DAE2EE",
          }}
        >
          <div className="sm04-data">
            <div style={{ width: "50%" }}>
              <div className="row">
                <div className="title">タイトル</div>
                <div className="description description_first-child">
                  {handler?.dataView?.name || "-"}
                </div>
              </div>
              <div className="row">
                <div className="title">案件名</div>
                <div className="description">
                  {handler?.dataView?.project?.name || "-"}
                </div>
              </div>
              <div className="row">
                <div className="title">工事名</div>
                <div className="description">
                  {handler?.dataView?.construction?.name || "-"}
                </div>
              </div>
              <div className="row">
                <div className="title">参加者</div>
                <div className="description">
                  {handler?.dataView?.listMember?.map(
                    (item: any, index: number) =>
                      index !== 0 ? `、${item.fullName}` : item.fullName
                  )}
                </div>
              </div>
            </div>
            <div style={{ width: "50%" }}>
              <div className="row">
                <div className="title">日付</div>
                <div className="description description_first-child">
                  {handler?.dataView?.date ?? "-"}
                </div>
              </div>
              <div className="row">
                <div className="title">時間</div>
                <div className="description">{handler?.dataView?.time}</div>
              </div>
              {/* <div className="row">
                <div className="title">繰り返し</div>
                <div className="description">月曜日</div>
              </div> */}
              <div className="row">
                <div className="title">カテゴリー</div>
                <div
                  className="description"
                  style={{ display: "flex", alignItems: "center", gap: 8 }}
                >
                  {handler?.dataView?.category != null ? (
                    <>
                      <div
                        className="sm04-circle"
                        style={{
                          backgroundColor:
                            handler?.dataView?.category?.colorRgb,
                        }}
                      ></div>{" "}
                      <div>{handler?.dataView?.category?.name || "-"}</div>
                    </>
                  ) : (
                    "-"
                  )}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: 0,
              }}
              className="sm04-data"
            >
              <div className="row">
                <div className="title">連絡事項</div>
                <div className="description description_first-child">
                  {handler?.dataView?.notification || "-"}
                </div>
              </div>
              <div className="row">
                <div className="title">問題点</div>
                <div className="description">
                  {handler?.dataView?.problem || "-"}
                </div>
              </div>
              <div className="row">
                <div className="title">説明</div>
                <div className="description">
                  {handler?.dataView?.note || "-"}
                </div>
              </div>
            </div>
          </div>
        </div>
        {+user.id === handler?.dataView?.owner?.id && (
          <div
            style={{
              width: "100%",
              height: "89px",
              borderBottom: "1px solid #DAE2EE",
              padding: 24,
            }}
          >
            <div style={{ float: "right" }}>
              <Button
                className="sm04-btn-save"
                disabled={handler.disabledBtn}
                size="large"
                style={{ marginLeft: 16, width: 76, letterSpacing: -1.5 }}
                onClick={() => {
                  scheduleId && handler.handleOpenModalEdit(scheduleId);
                }}
              >
                編集
              </Button>
              <Button
                disabled={handler.disabledBtn}
                icon={<img alt="" src={Delete} style={{ marginRight: 12 }} />}
                size="large"
                style={{
                  marginLeft: 16,
                  width: 106,
                  backgroundColor: "#DAE2EE",
                  border: "1px solid rgba(255, 9, 9, 0.5)",
                  color: "#FF0909",
                  letterSpacing: -1.5,
                }}
                onClick={() =>
                  scheduleId && handler.handleOpenModalConfirmDelete(scheduleId)
                }
              >
                削除
              </Button>
            </div>
          </div>
        )}

        <CommentComponent handler={handler} />
      </Spin>
      {isVisibleSM06 ? <SM06Component /> : <></>}
    </Modal>
  );
};

export default SM04UI;
