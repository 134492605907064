import axiosConfig from "../../../../../config/axiosConfig";

const CM0026Api = () => {
  const getProjectData = async (params: {
    page: number;
    size: number;
    from: string;
    to: string;
    sortBy: string;
    sortType: string;
  }): Promise<any> => {
    const url = "/re-summary/project/outsource";
    return axiosConfig.get(url, { params });
  };

  const getOutsourceCost = async (params: {
    documentDirectOutsourceCostId: number;
    from: string;
    to: string;
  }) => {
    const url = "/re-summary/project/construction/outsource-cost";
    return axiosConfig.get(url, { params });
  };

  const getLineSummary = async (params: any) => {
    const url = "/re-summary/project/outsource-cost/total";
    return axiosConfig.get(url, { params });
  };

  const getReSummaryProjectConstruction = async (params: {
    projectId: number;
    from: string;
    to: string;
  }) => {
    const url = "/re-summary/project/construction";
    return axiosConfig.get(url, { params });
  };
  const doExportCM0026 = async (params: any) => {
    try {
      const url = "/re-summary/exportCM0026";
      const response = await axiosConfig.get(url, {
        params,
        responseType: "blob",
      });

      return response;
    } catch (error) {
      throw error;
    }
  };

  return {
    getProjectData,
    getOutsourceCost,
    getLineSummary,
    doExportCM0026,
    getReSummaryProjectConstruction,
  };
};

export default CM0026Api;
