// BUILT-IN
import React, { useEffect } from "react";

// DEPENDENCY
import { Button, Checkbox, Form, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

// SOURCE
import COMMON, {
  STATUS_CM_CHILD_TABLE,
} from "../../../../../../../common/constants/COMMON";
import { COLUMN_WIDTH_CM007 } from "../../material-cost/ui/UI";
import { CollapsedTableHandlerImpl } from "../../../handler/HandlerImpl";
import helpers from "../../../../../../../common/helpers/common";
import { RootState } from "../../../../../../../store";
import { CheckBoxCollapseComponentCM007CM009 } from "./CheckBoxAll";
import NumberFormatTextComponent from "../../../../../../../common/components/common-form-item-cm/NumberFormatTextComponent";
import UploadEvidenceComponent from "../../../../../../../common/components/common-form-item-cm/upload-evidence";
import DateRangePickerCMComponent from "../../../../../../../common/components/common-form-item-cm/date-range-picker";
import DatePickerCMComponent from "../../../../../../../common/components/common-form-item-cm/date-picker";
import { hideImageViewerModal } from "../../../../../../../common/components/image-viewer-modal/ImageViewSlice";
import { hidePdfViewerModal } from "../../../../../../../common/components/pdf-viewer-modal/pdfViewerModalSlice";
import { AUTHORIZATION_CODE } from "../../../../../../../common/constants/AUTHORIZATION";
import ApproveEditModal from "../../../../../../../common/components/modal-approve-edit";
import ApproveViewModal from "../../../../../../../common/components/modal-approve-view";
import { isHasPrivilege } from "../../../../../../../common/helpers/privilege";
import { PRIVILEGE_PM } from "../../../../../../../common/constants/PRIVILEGE";
import InputFormMoneyItemComponent from "../../../../../../../common/components/common-form-item-cm/InputFormMoneyItemComponent";

// ICON
import EditButtonIconSvg from "../../../../../../../assets/icons/edit-button-icon.svg";
import DocumentIconSvg from "../../../../../../../assets/icons/document-icon.svg";
import DeleteButtonIconSvg from "../../../../../../../assets/icons/delete-button-icon.svg";
import AddRowButtonIconSvg from "../../../../../../../assets/icons/add-row-button.svg";
import { EyeNote } from "./EyeNote";
import { ViewDateWithRange } from "../../../../../../../common/components/view-date/ViewDate";

type props = {
  handler: CollapsedTableHandlerImpl;
  parentData: any;
  onParentCheckboxChange: (record: any, checked: boolean) => void;
};

const CollapsedTableUI = ({
  handler,
  parentData,
  onParentCheckboxChange,
}: props) => {
  const dispatch = useDispatch();
  const checkedCollapse = useSelector(
    (state: RootState) => state.cm007.checkedCollapse
  );
  const parentStatus = useSelector(
    (state: RootState) => state.cm007.parentStatus
  );
  const permission = useSelector(
    (state: RootState) => state.auth.authData?.permission
  );
  const isDisabledCheckAll = handler.data.some(
    (element) =>
      element?.status?.code === STATUS_CM_CHILD_TABLE.MONEY_NOT_APPROVED.CODE
  );
  const detailProject = useSelector(
    (state: RootState) => state.cm004.detailProject
  );
  useEffect(() => {
    handler.checkParentCBChange(parentData, checkedCollapse);
  }, [JSON.stringify(checkedCollapse)]);

  useEffect(() => {
    return () => {
      dispatch(hideImageViewerModal());
      dispatch(hidePdfViewerModal());
    };
  }, []);
  useEffect(() => {
    (async () => {
      if (parentData.id) {
        handler.setParentId(parentData.id);
        handler.resetEditingLines();
        await handler.getMoneyDirectCostMaterialListLocal(parentData.child);
      }
    })();
  }, [JSON.stringify(parentData.child)]);

  useEffect(() => {
    handler.setParentData(parentData);
  }, [JSON.stringify(parentData)]);

  useEffect(() => {
    if (handler.checkedList.length > 0)
      onParentCheckboxChange(parentData, true);
    else onParentCheckboxChange(undefined, true);
  }, [JSON.stringify(handler.checkedList)]);

  const isRoleAdmin = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdmin
  );

  const checkButton =
    isRoleAdmin ||
    isHasPrivilege(detailProject, [PRIVILEGE_PM]) ||
    permission?.includes(AUTHORIZATION_CODE.S48);

  const editActionButton = (record: any) => (
    <div>
      <Button
        disabled={
          String(record.id).includes("new")
            ? !handler.isEnableSaveButton(record)
            : false
        }
        type="primary"
        size="small"
        className="cm-save"
        onClick={() => handler.saveRecord(record, parseInt(parentData.id))}
      >
        保存
      </Button>
      <Button
        size="small"
        className="cm-cancel"
        onClick={() => handler.cancelOperation(record)}
      >
        キャンセル
      </Button>
    </div>
  );

  const columns: ColumnsType<any> = [
    {
      /*
      Hiển thị text: 項目追加
      Click vào tạo 1 record mới giống #11.6
      Điều kiện enable button: Khi status ở mục #11.3 là 未承認 hoặc 承認済"											
      */
      title: (
        <CheckBoxCollapseComponentCM007CM009
          onCheckAllChange={handler.onCheckAllChange}
          checkAll={handler.checkAll}
          isDisabled={!isDisabledCheckAll}
          showCB={handler.data?.length > 0}
        />
      ),
      key: "No",
      width: COLUMN_WIDTH_CM007[0],
      render: (_, record, index) => {
        const conditionCR = true;
        const foundData = parentStatus.find(
          (element) => element.id === parentData.id
        );
        if (record.key === "add")
          return conditionCR ? (
            checkButton ? (
              <div
                className="add-row-style button-style"
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "fit-content",
                }}
                onClick={() => handler.addNewLine(parseInt(parentData.id))}
              >
                <img
                  src={AddRowButtonIconSvg}
                  alt=""
                  style={{ marginRight: 8 }}
                />
                <span>項目追加</span>
              </div>
            ) : (
              <></>
            )
          ) : (
            <div
              className="add-row-style disable-style"
              style={{
                display: "flex",
                alignItems: "center",
                width: "fit-content",
              }}
            >
              <img
                src={AddRowButtonIconSvg}
                alt=""
                style={{ marginRight: 8 }}
              />
              <span>項目追加</span>
            </div>
          );
        const {
          MONEY_APPROVED,
          MONEY_WAITING_FOR_APPROVAL,
          MONEY_REJECT,
          MONEY_RETRIEVE,
        } = STATUS_CM_CHILD_TABLE;

        return (
          <span
            onClick={(event) => {
              event.stopPropagation();
            }}
            className="cell-cm007-style white-space-nowrap"
            style={{ width: "100%", float: "left", textAlign: "left" }}
          >
            {[
              MONEY_REJECT.CODE,
              MONEY_WAITING_FOR_APPROVAL.CODE,
              MONEY_RETRIEVE.CODE,
              MONEY_APPROVED.CODE,
            ].includes(record?.status?.code ?? "") ? (
              <Checkbox
                className="no-hover"
                disabled={true}
                style={{ marginRight: 8 }}
                checked
              />
            ) : (
              <Checkbox
                onChange={(value: any) =>
                  handler.onCheckboxChange(
                    record,
                    value?.target?.checked ? true : false
                  )
                }
                style={{ marginRight: 8 }}
                  checked={handler.isCheckbox(record)}
                  disabled={!record?.status}
              />
            )}
            {index}
          </span>
        );
      },
      onCell: (record) => ({
        colSpan: record.key === "add" ? 9 : 1,
      }),
    },
    {
      title: "対象期間", //Xử lý giống trường 契約工事期間 ở giấy dự toán CR001_thông tin cơ bản
      key: "対象期間",
      width: COLUMN_WIDTH_CM007[1],
      render: (record) => {
        return handler.isEditing(record) ? (
          <DateRangePickerCMComponent name="targetPeriod" record={record} />
        ) : (
          <span className="collapsed-table-row-style">
            <ViewDateWithRange {...record} />
          </span>
        );
      },
      onCell: (record) => ({
        colSpan: record.key === "add" ? 0 : 1,
      }),
    },
    {
      title: "支払日", //Xử lý giống trường 契約日 ở giấy dự toán CR001_thông tin cơ bản
      key: "支払日",
      width: COLUMN_WIDTH_CM007[2],
      render: (record) => {
        return handler.isEditing(record) ? (
          <DatePickerCMComponent record={record} name="paymentDate" />
        ) : (
          <span className="collapsed-table-row-style">
            {record.paymentDate
              ? moment(record.paymentDate).format(COMMON.FORMAT_DATE_OM)
              : ""}
          </span>
        );
      },
      onCell: (record) => ({
        colSpan: record.key === "add" ? 0 : 1,
      }),
    },
    {
      title: "支払予定金額",
      key: "支払予定金額",
      width: COLUMN_WIDTH_CM007[3],
      render: (record) => {
        return handler.isEditing(record) ? (
          <InputFormMoneyItemComponent
            record={record}
            name="planMaterialCost"
            onValueNumberChange={handler.onValueNumberChange}
          />
        ) : (
          <NumberFormatTextComponent value={record.planMaterialCost} />
        );
      },
      onCell: (record) => ({
        colSpan: record.key === "add" ? 0 : 1,
      }),
    },
    {
      title: "請求金額",
      key: "請求金額",
      width: COLUMN_WIDTH_CM007[4],
      render: (record) => {
        return handler.isEditing(record) ? (
          <InputFormMoneyItemComponent
            record={record}
            name="paidCost"
            onValueNumberChange={handler.onValueNumberChange}
          />
        ) : (
          <NumberFormatTextComponent value={record.paidCost} />
        );
      },
      onCell: (record) => {
        return {
          colSpan: record.key === "add" ? 0 : 1,
        };
      },
    },
    {
      title: "エビデンス",
      key: "エビデンス",
      width: COLUMN_WIDTH_CM007[5],
      render: (record) =>
        handler.isEditing(record) ? (
          <UploadEvidenceComponent
            nameForm={[`record${record.id}`, "evidence"]}
            fileNameDefault={record.evidence}
            form={handler.form}
            afterClear={handler.afterClearEvidence}
          />
        ) : record?.evidence ? (
          <div
            className="button-style"
            onClick={() => handler.handleViewEvidence(record)}
          >
            <img src={DocumentIconSvg} alt="" />
          </div>
        ) : null,
      onCell: (record) => ({
        colSpan: record.key === "add" ? 0 : 1,
      }),
    },

    {
      title: "実費用",
      key: "実費用",
      width: COLUMN_WIDTH_CM007[6],
      render: (record) => {
        return handler.isEditing(record) ? (
          <InputFormMoneyItemComponent
            record={record}
            name="actualCost"
            onValueNumberChange={handler.onValueNumberChange}
          />
        ) : (
          <NumberFormatTextComponent value={record.actualCost} />
        );
      },
      onCell: (record) => ({
        colSpan: record.key === "add" ? 0 : 1,
      }),
    },
    {
      title: "", // trường hợp submit nội dung record đang có status 否認 hoặc 取り下げ thì status 否認 hoặc 取り下げ sẽ chuyển thành 承認待ち"
      key: "status",
      width: COLUMN_WIDTH_CM007[7],
      render: (record) => {
        if (record.key === "add") return;
        const checkEditing = handler.isEditing(record);
        if (checkEditing) return editActionButton(record);
        if (record?.status?.code === false) return <></>;

        const color = STATUS_CM_CHILD_TABLE.hasOwnProperty(
          `${record?.status?.code?.toUpperCase()}`
        )
          ? {
              color:
                STATUS_CM_CHILD_TABLE[
                  `${record?.status?.code?.toUpperCase()}` as keyof typeof STATUS_CM_CHILD_TABLE
                ].COLOR,
            }
          : {};
        return (
          <div className="collapsed-table-row-style center-item" style={color}>
            {`${record.status.name}` || ""}
            <EyeNote
              record={record}
              viewApproveModal={handler.viewApproveModal}
            />
          </div>
        );
      },
      onCell: (record) => ({
        colSpan: record.key === "add" ? 0 : handler.isEditing(record) ? 2 : 1,
      }),
    },
    {
      /*
                + Trạng thái 未承認 hiển thị khi: Tạo thành công 1 record mới và chưa submit,
                hoặc sau khi edit thành công các record có trạng thái 否認 hoặc 取り下げ và chưa submit
                + Click button edit thì sẽ chuyển record sang trạng thái #11.6, sau khi edit và lưu lại thì chuyển status về trạng thái 未承認. 
                + Sau đó click button submit thì chuyển status 未承認 về trạng thái 承認待ち.
                + Điều kiện hiển thị button xóa: Khi record có trạng thái 否認 hoặc 取り下げ hoặc 未承認
                + Click button xóa thì hiển thị popup confirm xóa (Dùng popup hiện tại hệ thống đang dùng)                                                                                        
                + Sau khi xóa update lại No của các record theo thứ tự từ bé đến lớn
                ＋ Trường hợp submit nội dung record đang có status 否認 hoặc 取り下げ thì status 否認 hoặc 取り下げ sẽ chuyển thành 承認待ち"	
             */
      title: "",
      key: "action",
      width: COLUMN_WIDTH_CM007[8],
      render: (record) => {
        /*
                 + Điều kiện hiển thị button edit: Khi record có trạng thái 否認 (Tài liệu submit bị reject) 
                hoặc 取り下げ (Tài liệu submit bị thu hồi) hoặc 未承認 (Tài liệu chưa được duyệt)
                 */
        const {
          MONEY_REJECT,
          MONEY_NOT_APPROVED,
          MONEY_RETRIEVE,
          MONEY_APPROVED,
        } = STATUS_CM_CHILD_TABLE;

        const editButton = [
          MONEY_REJECT.CODE,
          MONEY_NOT_APPROVED.CODE,
          MONEY_RETRIEVE.CODE,
          MONEY_APPROVED.CODE,
        ].includes(record?.status?.code ?? "") ? (
          <div className="button-style">
            <img
              alt=""
              src={EditButtonIconSvg}
              onClick={() => handler.edit(record)}
            />
          </div>
        ) : (
          <></>
        );
        /**
                + Điều kiện hiển thị button xóa: Khi record có trạng thái 否認 hoặc 取り下げ hoặc 未承認
                 */
        const deleteButton = [
          MONEY_REJECT.CODE,
          MONEY_NOT_APPROVED.CODE,
          MONEY_RETRIEVE.CODE,
        ].includes(record?.status?.code ?? "") ? (
          <div
            className="button-style"
            style={{ marginLeft: 8 }}
            onClick={() => handler.deleteRecord(record)}
          >
            <img alt="" src={DeleteButtonIconSvg} />
          </div>
        ) : (
          <></>
        );

        return (
          <div className="center-item">
            {checkButton ? (
              <>
                {editButton}
                {deleteButton}
              </>
            ) : (
              <></>
            )}
          </div>
        );
      },

      onCell: (record) => ({
        colSpan: record.key === "add" ? 0 : handler.isEditing(record) ? 0 : 1,
      }),
    },
  ];

  return (
    <Form
      form={handler.form}
      style={{ padding: 0, margin: 0 }}
      onValuesChange={(changedValues: any, values: any) => {
        handler.checkEnableButtons(changedValues, values);
      }}
    >
      <Table
        rowClassName={(record) =>
          handler.isEditing(record) ? "row-no-padding" : ""
        }
        scroll={{ x: "max-content", y: 320 }}
        bordered={true}
        columns={columns}
        dataSource={[
          { key: "add" },
          ...helpers.addKeyToDataSource(handler.data),
          ...handler.newLines,
        ]}
        className="collapsed-table-header table-footer-inside hide-scroll-bar"
        pagination={false}
        summary={(pageData) => {
          let planMaterialCostTotal = 0;
          let paidCostTotal = 0;
          let actualCostTotal = 0;

          pageData.forEach(({ planMaterialCost, paidCost, actualCost }) => {
            planMaterialCostTotal += planMaterialCost || 0;
            paidCostTotal += paidCost || 0;
            actualCostTotal += actualCost || 0;
          });

          return (
            <Table.Summary fixed>
              <Table.Summary.Row
                className="children-table-summary"
                style={{ background: "#FAFAFA" }}
              >
                <Table.Summary.Cell index={1} colSpan={3}>
                  合計金額
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} colSpan={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={3} colSpan={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={4}>
                  <span className="center-item white-space-nowrap">{`${helpers.formatNumberMoney(
                    planMaterialCostTotal
                  )}円`}</span>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5}>
                  <span className="center-item white-space-nowrap">{`${helpers.formatNumberMoney(
                    paidCostTotal
                  )}円`}</span>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6}> </Table.Summary.Cell>
                <Table.Summary.Cell index={7} className="text-center">
                  <span className="center-item white-space-nowrap">{`${helpers.formatNumberMoney(
                    actualCostTotal
                  )}円`}</span>
                </Table.Summary.Cell>
                <Table.Summary.Cell
                  index={8}
                  align="center"
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={9}
                  align="center"
                ></Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
      />
      <ApproveEditModal callbackOk={handler.updateOnNoteModal} />
      <ApproveViewModal callbackOk={handler.updateOnNoteModal} />
    </Form>
  );
};

export default CollapsedTableUI;
