import { GroupEntity, ProjectUserResponse } from "./../entity/Entity";
import { OM007Api } from "./ServiceImpl";

const OM007Service = (api: OM007Api) => {
  const createNewGroup = async (data: GroupEntity): Promise<any> => {
    try {
      return await api.createNewGroup(data);
    } catch (error) {
      throw error;
    }
  };
  const getProjectUsers = async (params: any): Promise<ProjectUserResponse> => {
    try {
      return await api.getProjectUsers(params);
    } catch (error) {
      throw error;
    }
  };
  return { createNewGroup, getProjectUsers };
};

export default OM007Service;
