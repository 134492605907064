import moment from "moment";
import COMMON from "../../../common/constants/COMMON";
import helpers from "../../../common/helpers/common";
import { DataViewEntity, DataViewNestedEntity } from "./entity/Entity";

const CODE_TAX_FREE = "tax_free";
const CODE_TAX = "tax";

const findValue = (items: any[], code: string) => {
  if (items.length === 0) return [0, 0];
  const finded = items.find((ite: any) => ite.category.code === code);
  if (finded) return [finded?.value ?? 0, finded?.category?.id ?? 0];
  return [0, 0];
};

const convertDataView = (data: any[]) => {
  const output: any[] = [];

  // Sort data

  data.sort((a, b: any) => {
    return moment(b.createdAt).diff(a.createdAt);
  });

  data.forEach((item: any) => {
    const nested: any[] = [];

    let totalConsumptionTax = 0;
    let totalDepositAmount = 0;
    let totalTaxAtion = 0;
    let totaTaxExempt = 0;
    let totalTotalTax = 0;

    item.children.forEach((ele: any, idx: number) => {
      const [tax_value, tax_id] = findValue(
        ele?.receiveMoneyOffsetValue ?? [],
        CODE_TAX
      );
      const [tax_free_value, tax_free_id] = findValue(
        ele?.receiveMoneyOffsetValue ?? [],
        CODE_TAX_FREE
      );
      const actual = ele?.contractorReceiveMoney ?? 0;
      const actual_tax = Math.floor(
        (actual + tax_value) * (ele?.taxSetting ?? 1)
      );

      const obj: any = {
        key: Math.random(),
        no: idx + 1,
        id: ele?.id ?? 0,
        constructionDescription: ele?.construction?.description ?? "",
        startDate: ele?.startDate
          ? helpers.formatDatePresenter(ele.startDate, COMMON.FORMAT_DATE_CI)
          : "",
        endDate: ele?.endDate
          ? helpers.formatDatePresenter(ele.endDate, COMMON.FORMAT_DATE_CI)
          : "",
        consumptionTax: actual_tax,
        depositAmount: actual,
        orderAddress: item?.orderAddress ?? "",
        receiveAt: ele?.receiveAt
          ? helpers.formatDatePresenter(ele.receiveAt, COMMON.FORMAT_DATE_CI)
          : "",
        taxAtion: tax_value,
        taxExempt: tax_free_value,
        totalTax: actual_tax + actual,
        createdAt: ele.createdAt,
        taxId: tax_id,
        taxFreeId: tax_free_id,
      };

      totalConsumptionTax += actual_tax;
      totalDepositAmount += actual;
      totalTaxAtion += tax_value;
      totaTaxExempt += tax_free_value;
      totalTotalTax += actual_tax + actual;

      nested.push(obj);
    });

    // sort created at desc

    nested.sort((a, b: any) => {
      return moment(b.createdAt).diff(a.createdAt);
    });

    const obj: any = {
      key: Math.random(),
      projectName: item?.name ?? "",
      consumptionTax: totalConsumptionTax,
      depositAmount: totalDepositAmount,
      taxAtion: totalTaxAtion,
      taxExempt: totaTaxExempt,
      totalTax: totalTotalTax,
      children: nested,
    };

    output.push(obj);
  });

  return output;
};

const summaryFooter = (data: any) => {
  const [tax_value, tax_id] = findValue(
    data?.receiveMoneyOffset ?? [],
    CODE_TAX
  );
  const [tax_free_value, tax_free_id] = findValue(
    data?.receiveMoneyOffset ?? [],
    CODE_TAX_FREE
  );
  const actual = data?.contractorReceiveMoney ?? 0;
  const actual_tax = Math.floor((actual + tax_value) * (data?.taxSetting ?? 1));

  return {
    consumptionTax: actual_tax,
    depositAmount: actual,
    taxAtion: tax_value,
    taxExempt: tax_free_value,
    totalTax: actual_tax + actual,
  };
};

const convertDataViewCompany = (data: any[]) => {
  // Sort data
  data.sort((a, b: any) => {
    return moment(b.createdAt).diff(a.createdAt);
  });

  const output: any[] = [];

  data.forEach((item: any) => {
    let totalConsumptionTax = 0;
    let totalDepositAmount = 0;
    let totalTaxAtion = 0;
    let totaTaxExempt = 0;
    let totalTotalTax = 0;

    const nestedChildren = item.children.map((project: any, idx: number) => {
      const projectNested: any[] = [];

      project.children.forEach((ele: any) => {
        const [tax_value, tax_id] = findValue(
          ele?.receiveMoneyOffsetValue ?? [],
          CODE_TAX
        );
        const [tax_free_value, tax_free_id] = findValue(
          ele?.receiveMoneyOffsetValue ?? [],
          CODE_TAX_FREE
        );
        const actual = ele?.contractorReceiveMoney ?? 0;
        const actual_tax = Math.floor(
          (actual + tax_value) * (ele?.taxSetting ?? 1)
        );

        const obj = {
          key: Math.random(),
          no: idx + 1,
          id: ele?.id ?? 0,
          constructionDescription: ele?.construction?.description ?? "",
          startDate: ele?.startDate
            ? helpers.formatDatePresenter(ele.startDate, COMMON.FORMAT_DATE_CI)
            : "",
          endDate: ele?.endDate
            ? helpers.formatDatePresenter(ele.endDate, COMMON.FORMAT_DATE_CI)
            : "",
          consumptionTax: actual_tax,
          depositAmount: actual,
          orderAddress: item?.picName ?? "",
          receiveAt: ele?.receiveAt
            ? helpers.formatDatePresenter(ele.receiveAt, COMMON.FORMAT_DATE_CI)
            : "",
          taxAtion: tax_value,
          taxExempt: tax_free_value,
          totalTax: actual_tax + actual,
          createdAt: ele.createdAt,
          taxId: tax_id,
          taxFreeId: tax_free_id,
        };

        totalConsumptionTax += actual_tax;
        totalDepositAmount += actual;
        totalTaxAtion += tax_value;
        totaTaxExempt += tax_free_value;
        totalTotalTax += actual_tax + actual;

        projectNested.push(obj);
      });

      projectNested.sort((a, b: any) => moment(b.createdAt).diff(a.createdAt));

      return {
        key: Math.random(),
        traderName: project.projectName ?? "",
        consumptionTax: totalConsumptionTax,
        depositAmount: totalDepositAmount,
        taxAtion: totalTaxAtion,
        taxExempt: totaTaxExempt,
        totalTax: totalTotalTax,
        children: projectNested,
      };
    });

    const obj: any = {
      key: Math.random(),
      traderName: item?.orderAddress ?? "",
      consumptionTax: totalConsumptionTax,
      depositAmount: totalDepositAmount,
      taxAtion: totalTaxAtion,
      taxExempt: totaTaxExempt,
      totalTax: totalTotalTax,
      children: nestedChildren,
    };

    output.push(obj);
  });

  return output;
};


export { convertDataView, summaryFooter, convertDataViewCompany };
