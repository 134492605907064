import {
  Button,
  Card,
  Col,
  DatePicker,
  Empty,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Tag,
  Tooltip,
} from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PM007Hanlder } from "../handler/HandlerImpl";
import locale from "antd/es/date-picker/locale/ja_JP";
import PdfIcon from "../../../../../assets/icons/pdf-icon.svg";
import "../../../../../../src/assets/styles/PM-007/PM007.css";
import Dragger from "antd/lib/upload/Dragger";
import CloudUpIcon from "../../../../../assets/icons/cloud-up-icon.svg";
import { RootState } from "../../../../../store";
import IconFile from "../../../../../assets/icons/ant-design_file-pdf-filled.svg";
import XIconModal from "../../../../../assets/icons/x-icon-modal.svg";

import "moment/locale/ja";
import moment from "moment";
import COMMON, {
  MAX_LENGTH,
  PLAN_TYPE,
} from "../../../../../common/constants/COMMON";
import LoadingComponent from "../../../../../common/components/icons/LoadingComponent";
import ImageViewerModalComponent from "../../../../../common/components/image-viewer-modal/ImageViewerComponent";
import PdfViewerModalComponent from "../../../../../common/components/pdf-viewer-modal/PdfViewerModalComponent";
import {
  formatCCUSID,
  onBlurTrimFormItem,
  typeInteger,
} from "../../../../../common/helpers/typeInput";
import CloseIcon from "../../../../../assets/icons/x-icon-modal.svg";
import REGEX from "../../../../../common/constants/REGEX";
import MESSAGE, { TITLE } from "../../../../../common/constants/MESSAGE";
import helpers from "../../../../../common/helpers/common";
import { isHasPrivilege } from "../../../../../common/helpers/privilege";
import {
  PRIVILEGE_CREATOR,
  PRIVILEGE_PM,
} from "../../../../../common/constants/PRIVILEGE";
import BuildingSVG from "../../../../../assets/icons/building-purple.svg";
import PM008_1Component from "../../../PM008_1";
import IconCloseModal from "../../../../../assets/icons/icon-close-modal.svg";
import IconTooltip from "../../../../../assets/icons/icon-tooltip.svg";

const dummyRequest = (value: any) => {
  setTimeout(() => {
    value.onSuccess("ok");
  }, 0);
};
moment.locale("ja", {
  week: {
    dow: 1, /// Date offset
  },
});

interface props {
  handler: PM007Hanlder;
}

const PM007UI = ({ handler }: props) => {
  const isModalOpen = useSelector(
    (state: RootState) => state.pm007.isModalOpen
  );
  const projectId = useSelector((state: RootState) => state.pm007.projectId);
  const isRoleAdmin = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdmin
  );

  const isRoleAdminSystem = useSelector(
    (state: RootState) => state.auth?.authData?.isRoleAdminSystem
  );

  const isPlanFree =
    useSelector((state: RootState) => state.auth.authData?.order?.code) ===
    PLAN_TYPE.PLAN_FREE;

  useEffect(() => {
    if (isModalOpen) handler.getDataPM007();
    if (!isModalOpen) handler.formPM007.resetFields();
  }, [isModalOpen, projectId]);
  useEffect(() => {
    const unloadCallback = (event: any) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };
    if (handler.isEdit) {
      window.addEventListener("beforeunload", unloadCallback);
    } else {
      window.removeEventListener("beforeunload", unloadCallback);
    }
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, [handler.isEdit, isModalOpen]);

  const isDisableOrderingCompany = handler.dataDetail?.parentId && isPlanFree;

  return (
    <>
      <PM008_1Component
        handleConfirm={handler.handleConfirmChooseCompany}
        orderAddressId={handler.orderingCompanyId}
      />
      <Modal
        // title={<p className="title-modal">編集</p>}
        visible={isModalOpen}
        width="996px"
        centered={true}
        style={{ marginLeft: 300 }}
        forceRender={true}
        closable={false}
        closeIcon={<img alt="" src={IconCloseModal} />}
        footer={
          <>
            <Button
              size="large"
              htmlType="submit"
              type="primary"
              form="pm007form"
              style={{ letterSpacing: -1, marginRight: 8 }}
            >
              保存
            </Button>
            <Button key="back" onClick={handler.handleCancel} size="large">
              キャンセル
            </Button>
          </>
        }
      >
        <Spin spinning={handler.loading} indicator={LoadingComponent}>
          <div
            className="w-100-percent header-modal-containter"
            style={{ height: 50, marginBottom: 24 }}
          >
            <div className="header-modal-title-om004 ">編集</div>
            <div style={{ cursor: "pointer" }} onClick={handler.handleCancel}>
              <img alt="" src={XIconModal} style={{ height: 18, width: 18 }} />
            </div>
          </div>
          <Form
            layout="vertical"
            form={handler.formPM007}
            id="pm007form"
            name="pm007form"
            onFinish={handler.handleOk}
            onValuesChange={() => handler.setIsEdit(true)}
            onKeyDown={helpers.preventEnterForm}
          >
            {isHasPrivilege(handler.dataDetail, [
              PRIVILEGE_PM,
              PRIVILEGE_CREATOR,
            ]) ||
            isRoleAdmin ||
            isRoleAdminSystem ? (
              <Row gutter={25}>
                <Col span={3}>
                  <span className="label-input-modal">
                    ステータス<span className="sterisk">*</span>
                  </span>
                </Col>
                <Col span={21}>
                  <Form.Item
                    name={"statusId"}
                    rules={[{ required: true, message: "" }]}
                  >
                    <Select
                      disabled={
                        !isHasPrivilege(handler.dataDetail, [
                          PRIVILEGE_PM,
                          PRIVILEGE_CREATOR,
                        ])
                      }
                      className="container-dropdown"
                      size="large"
                      style={{
                        minWidth: "100%",
                        color:
                          handler?.status === 6
                            ? "#FF0909"
                            : handler?.status === 5
                            ? "#0FA44A"
                            : handler?.status === 4
                            ? "#0050AE"
                            : "#FF9054",
                      }}
                      onChange={handler.handleChangeStatus}
                      onPopupScroll={handler.onScrollLocation}
                    >
                      {handler.dataStatus.map((element: any, index: number) => {
                        return (
                          <Select.Option
                            key={element?.id + index}
                            value={element?.id}
                            className="controller__update--option"
                          >
                            {element?.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            ) : (
              <></>
            )}

            {!isPlanFree && handler.dataDetail?.parentId == 0 ? (
              <Row gutter={25}>
                <Col span={3} style={{ paddingLeft: 4 }}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Tooltip
                      placement="topLeft"
                      title={
                        <div style={{ width: "fit-content" }}>
                          {MESSAGE.MESSAGE_CCUSID_TUTORIAL}
                        </div>
                      }
                    >
                      <img src={IconTooltip} alt="" />
                    </Tooltip>
                    <span className="label-input-modal">{TITLE.CCUSID}</span>
                  </div>
                </Col>
                <Col span={21}>
                  <Form.Item
                    name="projectCcus"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (value && value !== "") {
                            value = value.replaceAll(" ", "");
                            if (value?.length < MAX_LENGTH.CCUSID) {
                              return Promise.reject(
                                new Error(MESSAGE.MESSAGE_LESS_THAN_14)
                              );
                            }
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input
                      size="large"
                      onKeyPress={typeInteger}
                      onInput={(e: React.FormEvent<HTMLInputElement>) => {
                        const inputElement = e.currentTarget;
                        const inputValue = inputElement.value;
                        const filteredValue = inputValue.replace(/[^0-9]/g, '');
                        inputElement.value = filteredValue.slice(0,14);
                      }}
                      onChange={(e) => {
                        formatCCUSID(e, "projectCcus", handler.formPM007);
                      }}
                      disabled={!handler.dataDetail?.allowCcusUpdate}
                      maxLength={MAX_LENGTH.FORMAT_CCUSID}
                      onBlur={() =>
                        onBlurTrimFormItem(handler.formPM007, "projectCcus")
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            ) : (
              <></>
            )}

            <Row gutter={25}>
              <Col span={3}>
                <span className="label-input-modal">
                  案件名<span className="sterisk">*</span>
                </span>
              </Col>
              <Col span={21}>
                <Form.Item
                  name={"projectName"}
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (value && value !== "") {
                          // CHECK INPUT VIETNAMESE
                          if (!REGEX.NOT_VIETNAM.test(value)) {
                            return Promise.reject(
                              new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                            );
                          }
                          if (value?.length > MAX_LENGTH.ADDRESS) {
                            return Promise.reject(new Error(""));
                          }
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(MESSAGE.MESSAGE_REQUIRED)
                        );
                      },
                    }),
                  ]}
                >
                  <Input
                    size="large"
                    disabled={handler.dataDetail?.parentId}
                    onBlur={() =>
                      onBlurTrimFormItem(handler.formPM007, "projectName")
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={25}>
              <Col span={3}>
                <span className="label-input-modal">
                  案件担当者<span className="sterisk">*</span>
                </span>
              </Col>
              <Col span={21}>
                <Form.Item
                  name={"projectManager"}
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (value && value !== "") {
                          // CHECK INPUT VIETNAMESE
                          if (!REGEX.NOT_VIETNAM.test(value)) {
                            return Promise.reject(
                              new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                            );
                          }
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(MESSAGE.MESSAGE_REQUIRED)
                        );
                      },
                    }),
                  ]}
                >
                  <Select
                    size="large"
                    listHeight={210}
                    showSearch
                    onSearch={handler.handleSearchOption}
                    filterOption={() => true}
                    onDropdownVisibleChange={(open) => {
                      if (open) handler.handleGetMember();
                    }}
                    onPopupScroll={handler.handleScrollMember}
                    getPopupContainer={(trigger: any) => trigger.parentNode}
                    notFoundContent={
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="該当なデータはありません"
                      />
                    }
                    style={{ fontSize: 14, fontWeight: 400 }}
                    className="select-item-text"
                  >
                    {handler.memberProjectManager.map((select) => {
                      return (
                        <Select.Option
                          style={{ alignItems: "center" }}
                          key={select.id}
                          value={select.id}
                        >
                          <div
                            style={{
                              fontSize: 14,
                              fontWeight: 600,
                              color: "#121212",
                            }}
                          >
                            {select.fullName || select.username}
                          </div>
                          <div
                            style={{
                              fontSize: 12,
                              fontWeight: 400,
                              color: "#666",
                            }}
                          >
                            {select.emailAddress || select.email}
                          </div>
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={25}>
              <Col span={3}>
                <span className="label-input-modal" style={{ marginRight: 4 }}>
                  案件副担当者
                </span>
              </Col>
              <Col span={21}>
                <Form.Item
                  name={"deputyProjectManager"}
                  rules={[{ required: false, message: "" }]}
                >
                  <Select
                    mode="multiple"
                    size="large"
                    showArrow
                    showSearch
                    listHeight={210}
                    loading={handler.loadingOption}
                    filterOption={() => true}
                    onSearch={handler.handleSearchOption}
                    getPopupContainer={(trigger: any) => trigger.parentNode}
                    onDropdownVisibleChange={(open) => {
                      if (open) handler.handleGetMember();
                    }}
                    tagRender={({ label, closable, onClose }: any) => {
                      return (
                        <Tag
                          closable={closable}
                          onClose={onClose}
                          color="rgba(231, 230, 255, 1)"
                          closeIcon={
                            <img
                              style={{ width: 10, marginLeft: 6 }}
                              alt=""
                              src={CloseIcon}
                            />
                          }
                          style={{
                            margin: "2px 4px 2px 0",
                            display: "flex",
                            alignItems: "center",
                            height: 28,
                            borderRadius: 4,
                            color: "#222222",
                            fontSize: 14,
                            fontWeight: 400,
                          }}
                        >
                          {label.length === 2 ? label[0].props?.children : ""}
                        </Tag>
                      );
                    }}
                    onPopupScroll={handler.handleScrollMember}
                    notFoundContent={
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="該当なデータはありません"
                      />
                    }
                  >
                    {handler.memberDeputyProjectManager.map((select) => {
                      return (
                        <Select.Option
                          style={{ alignItems: "center" }}
                          key={select.id}
                          value={select.id}
                        >
                          <div
                            style={{
                              fontSize: 14,
                              fontWeight: 600,
                              color: "#121212",
                            }}
                          >
                            {select.fullName || select.username}
                          </div>
                          <div
                            style={{
                              fontSize: 12,
                              fontWeight: 400,
                              color: "#666",
                            }}
                          >
                            {select.emailAddress || select.email}
                          </div>
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={25}>
              <Col span={3}>
                <span className="label-input-modal">
                  発注元会社<span className="sterisk">*</span>
                </span>
              </Col>
              <Col span={21}>
                <Form.Item
                  name={"orderAddress"}
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (value && value !== "") {
                          // CHECK INPUT VIETNAMESE
                          if (!REGEX.NOT_VIETNAM.test(value)) {
                            return Promise.reject(
                              new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                            );
                          }
                          if (value?.length > MAX_LENGTH.ADDRESS) {
                            return Promise.reject(new Error(""));
                          }
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(MESSAGE.MESSAGE_REQUIRED)
                        );
                      },
                    }),
                  ]}
                >
                  <Input
                    size="large"
                    readOnly
                    suffix={
                      <img
                        src={BuildingSVG}
                        style={{
                          cursor: isDisableOrderingCompany
                            ? "not-allowed"
                            : "pointer",
                          pointerEvents: isDisableOrderingCompany
                            ? "none"
                            : undefined,
                        }}
                        onClick={() => handler.handleOpenChooseCompany()}
                      />
                    }
                    disabled={isDisableOrderingCompany}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={25}>
              <Col span={3}>
                <span className="label-input-modal">
                  契約工事期間<span className="sterisk">*</span>
                </span>
              </Col>
              <Col span={21}>
                <Form.Item
                  name={"constructionPeriod"}
                  rules={[
                    { required: true, message: MESSAGE.MESSAGE_REQUIRED },
                  ]}
                >
                  <DatePicker.RangePicker
                    style={{ width: "100%", height: "40px" }}
                    format={COMMON.FORMAT_DATE_CI}
                    locale={locale}
                    placement="bottomLeft"
                    className="time__picker"
                    disabled={handler.dataDetail?.parentId}
                    inputReadOnly
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={25}>
              <Col span={3}>
                <span className="label-input-modal">
                  住所<span className="sterisk">*</span>
                </span>
              </Col>
              <Col span={21}>
                <Form.Item
                  name={"location"}
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (value && value !== "") {
                          // CHECK INPUT VIETNAMESE
                          if (!REGEX.NOT_VIETNAM.test(value)) {
                            return Promise.reject(
                              new Error(MESSAGE.MESSAGE_INPUT_INVALID)
                            );
                          }
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(MESSAGE.MESSAGE_REQUIRED)
                        );
                      },
                    }),
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="記入例：〇〇市〇〇区１−２−１"
                    disabled={handler.dataDetail?.parentId}
                    onBlur={() =>
                      onBlurTrimFormItem(handler.formPM007, "location")
                    }
                    maxLength={MAX_LENGTH.ADDRESS}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={25}>
              <Col span={3}>
                <span className="label-input-modal">現場案内図</span>
              </Col>
              <Col span={21}>
                <Card
                  bodyStyle={{
                    paddingLeft: "11px",
                    backgroundColor: handler.dataDetail?.parentId && "#f5f5f5",
                    cursor: handler.dataDetail?.parentId
                      ? "not-allowed"
                      : undefined,
                    padding: handler.dataDetail?.parentId
                      ? "19px"
                      : "24px 24px 24px 11px",
                    border: handler.dataDetail?.parentId
                      ? "solid 0.7px #d9d9d9"
                      : "none",
                  }}
                  className={`${
                    handler.fileList?.length !== 0 && "pm005-upload-file"
                  }`}
                >
                  {!handler.dataDetail?.parentId ||
                  (handler.dataDetail?.parentId &&
                    handler.fileList.length != 0) ? (
                    <Form.Item name={"filePdf"}>
                      <Dragger
                        disabled={handler.dataDetail?.parentId}
                        onPreview={async (file) =>
                          !handler.dataDetail?.parentId
                            ? await handler.getPdfMap(file)
                            : () => {}
                        }
                        customRequest={dummyRequest}
                        action={PdfIcon}
                        // listType="picture"
                        multiple={false}
                        fileList={[...handler.fileList]}
                        onChange={handler.onChangeUpload}
                        onRemove={handler.onDropUpload}
                        iconRender={() => <img alt="" src={IconFile} />}
                        accept="application/pdf, image/png, image/jpeg"
                      >
                        {handler.fileList?.length === 0 && (
                          <>
                            <p className="ant-upload-drag-icon">
                              <img alt="" src={CloudUpIcon} />
                            </p>
                            <p className="ant-upload-text">
                              ここにファイルをドラッグ・ドロップ (.pdf または
                              .png/.jpeg/.jpg)
                            </p>
                            <p
                              className="ant-upload-hint"
                              style={{ margin: "6px 0px 10px 0px" }}
                            >
                              または
                            </p>
                            <div className="btn-upload-custom">
                              ファイルを選択する（.pdf または .png/.jpeg/.jpg）
                            </div>
                          </>
                        )}
                      </Dragger>
                    </Form.Item>
                  ) : null}
                </Card>
              </Col>
            </Row>
          </Form>
        </Spin>
        {isModalOpen ? (
          <>
            <ImageViewerModalComponent
              imageURL={handler.pdfUrl}
              title="現場案内図"
            />
            <PdfViewerModalComponent pdfURL={handler.pdfUrl} />
          </>
        ) : (
          <></>
        )}
      </Modal>
    </>
  );
};

export default PM007UI;
