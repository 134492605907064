import { description } from "./../../../../../pages/SW/SW02/presenter/ui/UI";
import { createSlice } from "@reduxjs/toolkit";

type ApproveConfirmModalSlice = {
  isVisible: boolean;
  description: string | string[];
};

const initialState: ApproveConfirmModalSlice = {
  isVisible: false,
  description: "このリクエストを承認してもよろしいですか?",
};

export const ApproveConfirmModalSlice = createSlice({
  name: "ApproveConfirmModalSlice",
  initialState,
  reducers: {
    showModalApproveConfirm: (state) => {
      state.isVisible = true;
    },
    setModalApproveConfirmDescription: (state, action) => {
      state.description = action.payload;
    },
    resetApproveConfirmModal: () => initialState,
  },
});

export const { showModalApproveConfirm, resetApproveConfirmModal } =
  ApproveConfirmModalSlice.actions;
export default ApproveConfirmModalSlice.reducer;
