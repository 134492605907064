import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ProjectServiceImpl } from "../../usecase/ServiceImpl";
import { Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { setLoading } from "../../../../../common/slice/CommonSlice";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import { NOTIFICATION_TITLE } from "../../../../../common/constants/MESSAGE";
import { Project } from "./HandlerImpl";
import { RootState } from "../../../../../store";
import { setIsCollapsed, setProjectChosen } from "../slice/Slice";
import COMMON from "../../../../../common/constants/COMMON";
import { RangeDateType } from "../../entity/Entity";
import { formatRangeDate } from "../../helper";

export const PARAM = {
  page: "pageProject",
  size: "sizeProject",
  keyword: "keywordProject",
  projectId: "projectId",
  projectName: "projectName",
  startDate: "startDate",
  endDate: "endDate",
  clear: "clear",
  collapse: "collapse",
  rangeDate: "rangeDate",
};

export const DEFAULT_PARAM = {
  page: 1,
  size: 11,
  keyword: "",
  projectId: 0,
  startDate: moment().startOf("month"),
  endDate: moment(),
  clear: "0",
  collapse: "0",
};

const ProjectHandler = (service: ProjectServiceImpl) => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [total, setTotal] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const page = +(searchParams.get(PARAM.page) || DEFAULT_PARAM.page);
  const size = +(searchParams.get(PARAM.size) || DEFAULT_PARAM.size);
  const keyword = searchParams.get(PARAM.keyword) || DEFAULT_PARAM.keyword;
  const projectId = +(
    searchParams.get(PARAM.projectId) || DEFAULT_PARAM.projectId
  );
  const startDate = moment(
    searchParams.get(PARAM.startDate) || DEFAULT_PARAM.startDate
  );
  const endDate = moment(
    searchParams.get(PARAM.endDate) || DEFAULT_PARAM.endDate
  );
  const clear = +(searchParams.get(PARAM.clear) || DEFAULT_PARAM.clear);
  const collapse = +(
    searchParams.get(PARAM.collapse) || DEFAULT_PARAM.collapse
  );

  const [projects, setProjects] = useState([]);
  const [rangeDate, setRangeDate] = useState<RangeDateType>({
    from: startDate,
    to: endDate,
  });

  const projectChosen = useSelector(
    (state: RootState) => state.am005.projectChosen
  );

  const getListProject = async (params: {
    page: number;
    size: number;
    statusId?: number;
    keyword?: string;
  }) => {
    try {
      dispatch(setLoading(true));

      const response = await service.getListProject(params);
      const projectData = response?.results ?? [];

      setProjects(projectData);
      setTotal(response.pagination?.count);
      if (projectData?.length > 0 && !projectId) {
        searchParams.set(PARAM.projectName, projectData[0]?.projectName);
        searchParams.set(PARAM.projectId, projectData[0]?.id);
        searchParams.set(
          PARAM.rangeDate,
          formatRangeDate(
            projectData[0]?.constructionFromDate,
            projectData[0]?.constructionToDate
          )
        );
        setSearchParams(searchParams);
        dispatch(setProjectChosen(projectData[0]));
      } else {
        const projectChosenGet = projectData?.find(
          (project: { id: number }) => project?.id === projectId
        );
        dispatch(setProjectChosen(projectChosenGet));
      }
    } catch (error: any) {
      ErrorNotification(error?.message || NOTIFICATION_TITLE.ERROR);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const initiateFilter = () => {
    form.setFields([
      {
        name: "startDate",
        value: clear ? null : startDate,
      },
      {
        name: "endDate",
        value: endDate,
      },
      {
        name: "text",
        value: keyword,
      },
    ]);
    if (!keyword) {
      searchParams.delete(PARAM.keyword);
      setSearchParams(searchParams);
    }
    dispatch(setIsCollapsed(collapse));
  };

  var myVar: any;
  const onSearch = () => {
    if (myVar) clearTimeout(myVar);
    myVar = setTimeout(function () {
      const searchText = form.getFieldValue("text");
      searchParams.set(PARAM.keyword, searchText.trim());
      form.setFieldValue("text", searchText.trim());
      searchParams.delete(PARAM.page);
      setSearchParams(searchParams);
    }, 1000);
  };

  const onChooseProject = (project: Project) => {
    searchParams.set(PARAM.projectId, `${project?.id}`);
    searchParams.set(PARAM.projectName, `${project?.projectName}`);
    searchParams.set(
      PARAM.rangeDate,
      formatRangeDate(
        project?.constructionFromDate,
        project?.constructionToDate
      )
    );
    setSearchParams(searchParams);
    dispatch(setProjectChosen(project));
  };

  const onPageChange = (value: any) => {
    searchParams.set(PARAM.page, value);
    setSearchParams(searchParams);
  };

  const onPageSizeChange = (value: any) => {
    searchParams.set(PARAM.size, value);
    searchParams.delete(PARAM.size);
    setSearchParams(searchParams);
  };

  const onFilter = () => {
    searchParams.set("pageDate", COMMON.DEFAULT_PAGE + "");
    const startDate = form.getFieldValue(PARAM.startDate);
    const endDate = form.getFieldValue(PARAM.endDate);
    searchParams.delete(PARAM.startDate);
    searchParams.delete(PARAM.endDate);
    searchParams.delete(PARAM.clear);
    if (
      startDate &&
      moment(startDate).format(COMMON.FORMAT_DATE) !==
        moment(DEFAULT_PARAM.startDate).format(COMMON.FORMAT_DATE)
    ) {
      searchParams.set(
        PARAM.startDate,
        moment(startDate).format(COMMON.FORMAT_DATE)
      );
    }

    if (!startDate) {
      searchParams.set(PARAM.clear, "1");
    }

    if (
      moment(startDate).format(COMMON.FORMAT_DATE) !==
      moment(DEFAULT_PARAM.startDate).format(COMMON.FORMAT_DATE)
    ) {
      searchParams.set(
        PARAM.endDate,
        moment(endDate).format(COMMON.FORMAT_DATE)
      );
    }
    setSearchParams(searchParams);
  };

  const onResetFilter = () => {
    form.setFieldsValue({
      startDate: DEFAULT_PARAM.startDate,
      endDate: DEFAULT_PARAM.endDate,
      keyword,
    });
    searchParams.delete(PARAM.startDate);
    searchParams.delete(PARAM.endDate);
    searchParams.delete(PARAM.clear);
    setSearchParams(searchParams);
  };

  const onCollapse = (value: boolean) => {
    dispatch(setIsCollapsed(value));
    searchParams.delete(PARAM.collapse);
    if (value) searchParams.set(PARAM.collapse, "1");
    setSearchParams(searchParams);
  };

  const onFromDateChange = (value: any) => {
    setRangeDate({ ...rangeDate, from: value });
  };
  const onToDateChange = (value: any) => {
    setRangeDate({ ...rangeDate, to: value });
  };

  return {
    onChooseProject,
    onSearch,
    onPageChange,
    onPageSizeChange,
    onFilter,
    onResetFilter,
    onCollapse,
    initiateFilter,
    onFromDateChange,
    onToDateChange,
    //api
    getListProject,

    form,
    total,
    page,
    size,
    keyword,
    projects,
    projectId,
    projectChosen,
    rangeDate,
    startDate,
    endDate,
  };
};

export default ProjectHandler;
