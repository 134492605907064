import COMMON from "../../../../../common/constants/COMMON";
import { AM010Handler } from "../handler/HandlerImpl";
import AM010HeaderTimer from "./AM010HeaderTimer";
import AM010HeaderUnit from "./AM010HeaderUnit";

type props = {
  handler: AM010Handler;
};
export default function AM010Header({ handler }: props) {
  return (
    <div className="am010__header">
      <h1>個人勤務実績</h1>
      <div className="am010__header-element">
        {/* ATTENDANCE */}
        <AM010HeaderUnit
          title={COMMON.REQUEST_CONSTANT.ATTENDANCE.title}
          color={COMMON.REQUEST_CONSTANT.ATTENDANCE.color}
          icon={COMMON.REQUEST_CONSTANT.ATTENDANCE.icon}
          parentValue={
            handler?.summaryRequest?.objAttendance?.parentValue ||
            COMMON.REQUEST_CONSTANT.ATTENDANCE.parentValue
          }
          childrenValue1={
            handler?.summaryRequest?.objAttendance
              ?.request_waiting_for_approval ||
            COMMON.REQUEST_CONSTANT.ATTENDANCE.request_waiting_for_approval
          }
          childrenValue2={
            handler?.summaryRequest?.objAttendance?.request_approved ||
            COMMON.REQUEST_CONSTANT.ATTENDANCE.request_approved
          }
          childrenValue3={
            handler?.summaryRequest?.objAttendance?.request_reject ||
            COMMON.REQUEST_CONSTANT.ATTENDANCE.request_reject
          }
        />
        {/* LEAVE */}
        <AM010HeaderUnit
          title={COMMON.REQUEST_CONSTANT.LEAVE.title}
          color={COMMON.REQUEST_CONSTANT.LEAVE.color}
          icon={COMMON.REQUEST_CONSTANT.LEAVE.icon}
          parentValue={
            handler?.summaryRequest?.objLeave?.parentValue ||
            COMMON.REQUEST_CONSTANT.LEAVE.parentValue
          }
          childrenValue1={
            handler?.summaryRequest?.objLeave?.request_waiting_for_approval ||
            COMMON.REQUEST_CONSTANT.LEAVE.request_waiting_for_approval
          }
          childrenValue2={
            handler?.summaryRequest?.objLeave?.request_approved ||
            COMMON.REQUEST_CONSTANT.LEAVE.request_approved
          }
          childrenValue3={
            handler?.summaryRequest?.objLeave?.request_reject ||
            COMMON.REQUEST_CONSTANT.LEAVE.request_reject
          }
        />
        {/* LATE EARLY */}
        <AM010HeaderUnit
          title={COMMON.REQUEST_CONSTANT.LATE_EARLY.title}
          color={COMMON.REQUEST_CONSTANT.LATE_EARLY.color}
          icon={COMMON.REQUEST_CONSTANT.LATE_EARLY.icon}
          parentValue={
            handler?.summaryRequest?.objLateEarly?.parentValue ||
            COMMON.REQUEST_CONSTANT.LATE_EARLY.parentValue
          }
          childrenValue1={
            handler?.summaryRequest?.objLateEarly
              ?.request_waiting_for_approval ||
            COMMON.REQUEST_CONSTANT.LATE_EARLY.request_waiting_for_approval
          }
          childrenValue2={
            handler?.summaryRequest?.objLateEarly?.request_approved ||
            COMMON.REQUEST_CONSTANT.LATE_EARLY.request_approved
          }
          childrenValue3={
            handler?.summaryRequest?.objLateEarly?.request_reject ||
            COMMON.REQUEST_CONSTANT.LATE_EARLY.request_reject
          }
        />

        <AM010HeaderTimer handler={handler} />
      </div>
    </div>
  );
}
