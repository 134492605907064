import MESSAGE, {
  NOTIFICATION_TITLE,
} from "./../../../../../common/constants/MESSAGE";
import { RootState } from "./../../../../../store";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { Form } from "antd";
import SuccessNotification from "../../../../../common/components/notification/SuccessNotification";
import ErrorNotification from "../../../../../common/components/notification/ErrorNotification";
import { resetPM007, setIsModalOpen } from "../slice/Slice";
import { PM007UseCase } from "../../usecase/ServiceImpl";
import COMMON from "../../../../../common/constants/COMMON";
import { refreshPM005 } from "../../../PM005/presenter/slice/Slice";
import { showPdfViewerModal } from "../../../../../common/components/pdf-viewer-modal/pdfViewerModalSlice";
import { showImageViewerModal } from "../../../../../common/components/image-viewer-modal/ImageViewSlice";
import helpers from "../../../../../common/helpers/common";
import { RcFile } from "antd/es/upload";
import ConfirmModal from "../../../../../common/components/modal/ConfirmModal";
import { resetStatePM008_1, setChangeChosenCompany, setViewChooseCompany } from "../../../PM008_1/presenter/slice/Slice";
import { formatCCUSID } from "../../../../../common/helpers/typeInput";

const PM007Handler = (pm007Service: PM007UseCase) => {
  const dispatch = useDispatch();
  const [formPM007] = Form.useForm();

  // SELECTOR
  const projectId = useSelector((state: RootState) => state.pm007.projectId);

  // STATE
  const chosenCompany = useSelector(
    (state: RootState) => state?.pm008_1.chosenCompany
  );
  const listCompany = useSelector(
    (state: RootState) => state?.pm008_1.listCompany
  );
  const [dataDetail, setDataDetail] = useState<any>({});
  const [dataStatus, setDataStatus] = useState([]);
  const [status, setStatus] = useState("");
  const [dataMembers, setDataMembers] = useState<
    { id: string; username: string }[]
  >([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const filteredOptions = dataMembers.filter(
    (item: { id: string; username: string }) =>
      !selectedItems.includes(item.username)
  );
  const [page, setPage] = useState(COMMON.DEFAULT_PAGE);
  const [fileList, setFileList] = useState<any>([{ uid: "1", name: "" }]);
  const [pdfUrl, setPdfUrl] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState(false);
  const [members, setMembers] = useState<any[]>([]);
  const [pageOption, setPageOption] = useState(COMMON.DEFAULT_PAGE);
  const [sizeOption, setSizeOption] = useState(COMMON.DEFAULT_SIZE);
  const [keywordOption, setKeywordOption] = useState("");
  const [loadingOption, setLoadingOption] = useState(false);
  const [currentOption, setCurrentOption] = useState([]);
  const [orderingCompanyId, setOrderingCompanyId] = useState(undefined);
  // FUNCTION
  const getDataPM007 = async () => {
    if (!projectId) return;
    setLoading(true);

    await getDataDetailProject();
    await getDataStatus({
      page: page,
      size: "5",
    });
    setLoading(false);
  };

  const getDataDetailProject = async () => {
    try {
      const { results } = await pm007Service.getDataDetailProject({
        projectId: projectId,
      });
      setDataDetail(results);
      if (results.document !== "") {
        const rootId = results.rootId ? results.rootId : results.id;
        setFileList([
          {
            key: 1,
            uid: "-1",
            name: results.document,
            path: "project/" + rootId + "/" + results.document,
          },
        ]);
      } else {
        setFileList([]);
      }

      const newValue = {
        ...results,
        constructionPeriod: [
          results.constructionFromDate
            ? moment(results.constructionFromDate)
            : "",
          results.constructionToDate ? moment(results.constructionToDate) : "",
        ],
        projectManager: results.projectManager?.id || null,
        deputyProjectManager:
          results?.deputyProjectManager?.length > 0
            ? results?.deputyProjectManager?.map(
              (ele: { id: number }) => ele.id
            )
            : [],
      };
      newValue.statusId = results?.status?.id;
      newValue.projectCcus = results?.ccusVerification?.ccusCode ? helpers.convertToCCUSFormat(results?.ccusVerification?.ccusCode) : "";

      let resultsOption = [];
      if (results?.deputyProjectManager?.length > 0) {
        resultsOption = results?.deputyProjectManager;
      }

      if (results?.projectManager?.id) {
        const found = resultsOption.find(
          (element: { id: number }) =>
            element.id === results?.projectManager?.id
        );
        if (!found) resultsOption.push(results?.projectManager);
      }
      setCurrentOption(resultsOption);
      setMembers(resultsOption);

      setStatus(results?.status?.id);
      formPM007.setFieldsValue(newValue);
      setOrderingCompanyId(newValue?.orderingCompanyId)
      dispatch(setChangeChosenCompany(newValue?.orderingCompanyId));
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const getDataStatus = async (params: any) => {
    try {
      const { results } = await pm007Service.getDataStatus(params);
      setDataStatus(results);
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const getDataMembers = async (params: any) => {
    try {
      const response = await pm007Service.getDataMembers(params);

      if (response) {
        setDataMembers(response.results);
        return response.data;
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const getListMember = async (params: {
    page: number;
    size: number;
    keyword?: string;
  }) => {
    setLoadingOption(true);
    try {
      const response = await pm007Service.getListMember(params);
      if (params.page === 1) {
        setMembers(
          handleDuplicateMember(response.results, currentOption, params.keyword)
        );
      } else {
        setMembers(
          handleDuplicateMember(
            [...members, ...response.results],
            currentOption,
            params.keyword
          )
        );
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      setLoadingOption(false);
    }
  };

  const handleDuplicateMember = (
    raw: any,
    currentRaw: any,
    keyword?: string
  ) => {
    if (keyword) return raw;
    const results = [...currentRaw, ...raw];
    const check: any = {};
    const newResults: any = [];
    results.forEach((element: any) => {
      if (!check[element.id]) {
        newResults.push(element);
        check[element.id] = element;
      }
    });
    return newResults;
  };

  const handleChangeStatus = (value: string) => {
    setStatus(value);
  };

  const getLinkPresignUpload = async (params: any) => {
    try {
      const response = await pm007Service.getLinkPresignUpload(params);

      if (response) {
        return response.results;
      }
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    }
  };

  const uploadTos3 = async (url: any, data: any) => {
    try {
      const response = await pm007Service.uploadToS3(url, data);
      return response.results;
    } catch (error) {
      throw error;
    }
  };

  const handleOk = async (formData: any) => {
    setLoading(true);
    const newData = {
      statusId: formData.statusId,
      projectId: projectId,
      workspaceId: 1,
      projectName: formData.projectName,
      constructionName: formData.constructionName || "",
      orderAddress: chosenCompany + "",
      projectManager: { userId: formData.projectManager },
      deputyProjectManager:
        formData.deputyProjectManager &&
          formData.deputyProjectManager?.length > 0
          ? {
            userId: formData.deputyProjectManager,
          }
          : null,
      picName: "案件担当者",
      constructionFromDate: formData.constructionPeriod
        ? helpers.getFilterTime(formData.constructionPeriod[0]?._d) || ""
        : "",
      constructionToDate: formData.constructionPeriod
        ? helpers.getFilterTime(formData.constructionPeriod[1]._d) || ""
        : "",
      location: formData.location,
      document:
        fileList?.length === 0 || !fileList[0].originFileObj
          ? fileList[0]?.name !== ""
            ? fileList[0]?.name
            : ""
          : fileList[0].originFileObj.name,
          projectCcus: formData?.projectCcus ? formData.projectCcus.replaceAll(" ","") : "" 
    };
    try {
      const response = await pm007Service.editDetailProject(newData);
      if (fileList?.length !== 0 && fileList[0].originFileObj) {
        const linkPreSignUpload = await getLinkPresignUpload({
          keyName: `project/${projectId}/${fileList[0].originFileObj.name}`,
        });
        if (linkPreSignUpload) {
          await uploadTos3(linkPreSignUpload, fileList[0].originFileObj);
        }
      }
      SuccessNotification(
        response?.message ??
        NOTIFICATION_TITLE.MESSAGE_DELETE_NOTIFICATION_SUCCESS
      );
      formPM007.resetFields();
      dispatch(resetStatePM008_1())
      dispatch(resetPM007());
      dispatch(refreshPM005());
    } catch (error: any) {
      ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
    } finally {
      setLoading(false);
    }
  };

  const showModal = () => {
    dispatch(setIsModalOpen(true));
  };

  const handleCancel = () => {
    if (isEdit) {
      ConfirmModal({
        onOk: () => {
          dispatch(setIsModalOpen(false));
          dispatch(resetStatePM008_1())
          setIsEdit(false);
        },
        onCancel: () => { },
        className: "confirm__modal modal-ml",
        title: MESSAGE.MESSAGE_020,
        description: MESSAGE.MESSAGE_022_1,
        extraDescription: MESSAGE.MESSAGE_022_2,
        canceText: "キャンセル",
        okText: "はい",
      });
    } else {
      dispatch(setIsModalOpen(false));
      dispatch(resetStatePM008_1())
      setIsEdit(false);
    }
  };

  const resetForm = () => {
    formPM007.resetFields();
  };

  const onChangeUpload = (value: any) => {
    if (value?.file?.status === "removed") {
      setFileList([]);
    } else {
      if (value?.file) {
        const found = helpers.beforeUpload(value?.file);
        if (found) {
          setFileList([value.file]);
        }
      }
    }
  };
  const onDropUpload = (value: any) => {
    setFileList([]);
  };
  const getPdfMap = async (value: any) => {
    if (value.path)
      try {
        const params = {
          keyName: value.path,
        };
        const response = await pm007Service.getPdfMap(params);
        setPdfUrl(response.results);
        const checkFile = response.results.pdfUrl
          ?.split(".")
          .pop()
          ?.split("?")
          .shift();
        if (checkFile === "pdf") return dispatch(showPdfViewerModal());
        dispatch(showImageViewerModal());
      } catch (error: any) {
        ErrorNotification(error?.message ?? NOTIFICATION_TITLE.ERROR);
      }
    else {
      if (!value.url && !value.preview) {
        value.preview = await helpers.getBase64(value.originFileObj as RcFile);
      }
      setPdfUrl(value.url || (value.preview as string));
      if (value.type.includes("image/")) {
        dispatch(showImageViewerModal());
      } else {
        dispatch(showPdfViewerModal());
      }
    }
  };

  const onScrollLocation = (e: any) => {
    const target = e.target;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      setPage(page + 1);
    }
  };

  const handleGetMember = () => {
    setMembers([]);
    setPageOption(1);
    setSizeOption(10);
    getListMember({ page: 1, size: 10 });
  };

  const handleScrollMember = (e: any) => {
    const target = e.target;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      setPageOption(pageOption + 1);
      getListMember({
        page: pageOption + 1,
        size: sizeOption,
        keyword: keywordOption ? keywordOption : undefined,
      });
    }
  };

  const handleSearchOption = (searchValue: string) => {
    getListMember({ page: 1, size: 10, keyword: searchValue });
    setKeywordOption(searchValue);
    setPageOption(1);
    setSizeOption(10);
  };

  const memberProjectManager = useMemo(() => {
    const idDeputyProjectManager = formPM007.getFieldValue(
      "deputyProjectManager"
    );
    const checkDuplicate: any = {};
    const results: any[] = [];
    for (const member of members) {
      if (
        !(member.id in checkDuplicate) &&
        !idDeputyProjectManager?.includes(member.id)
      ) {
        results.push(member);
        checkDuplicate[member.id] = member;
      }
    }
    return results;
  }, [members]);

  const memberDeputyProjectManager = useMemo(() => {
    const idProjectManager = formPM007.getFieldValue("projectManager");
    const checkDuplicate: any = {};
    const results: any[] = [];
    for (const member of members) {
      if (!(member.id in checkDuplicate) && idProjectManager !== member.id) {
        results.push(member);
        checkDuplicate[member.id] = member;
      }
    }
    return results;
  }, [members]);

  const handleOpenChooseCompany = () => {
    dispatch(setViewChooseCompany(true));
  };

  const handleCancelChooseCompany = () => {
    dispatch(setViewChooseCompany(false));
  };

  const handleConfirmChooseCompany = () => {
    const company = listCompany.find(
      (company: any) => company.id === chosenCompany
    );
    dispatch(setViewChooseCompany(false));
    formPM007.setFieldValue("orderAddress", company?.companyName);
    formPM007.validateFields(["orderAddress"]);
  };

  return {
    getDataDetailProject,
    handleOk,
    getDataStatus,
    handleChangeStatus,
    showModal,
    handleCancel,
    getDataMembers,
    setSelectedItems,
    resetForm,
    onChangeUpload,
    onScrollLocation,
    onDropUpload,
    getPdfMap,
    getDataPM007,
    setIsEdit,
    handleGetMember,
    handleScrollMember,
    handleSearchOption,
    handleOpenChooseCompany,
    handleCancelChooseCompany,
    handleConfirmChooseCompany,

    selectedItems,
    dataDetail,
    formPM007,
    dataStatus,
    status,
    dataMembers,
    filteredOptions,
    fileList,
    pdfUrl,
    page,
    loading,
    isEdit,
    members,
    loadingOption,
    memberProjectManager,
    memberDeputyProjectManager,
    orderingCompanyId
  };
};

export default PM007Handler;
