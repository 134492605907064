import React from "react";
import IconSortUp from "../../../../../../assets/icons/bx_bxs-up-arrow.svg";
import { memo } from "react";
import { TableData } from "../../entity/Entity";
import { data } from "../../../../../OM/OM005/presenter/slice/Slice";
import { formatDateView } from "../../helper";
import WarningIcon from "../../../../../../assets/icons/warning-red.svg";
import { STATUS_CM_CHILD_TABLE } from "../../../../../../common/constants/COMMON";
import { Empty } from "antd";

interface props {
  title: string;
  sortType: string | null;
  data: TableData[] | [];

  funcSort?: (sortType: string) => void;
  onClickDocument(
    documentId: number,
    documentDirectMaterialCostId: number,
    submittedDate: string,
    statusCode: string,
    constructionId?: number
  ): void;
  handleViewRejectReason(reason: string): void;
}

const TableView = ({
  title,
  sortType,
  data,
  funcSort,
  onClickDocument,
  handleViewRejectReason,
}: props) => {
  return (
    <div className="cm035-item">
      <div className="cm035-item-header-2">{title}</div>
      <div className="cm035-table-header">
        <table className="cm035-table">
          <thead>
            <tr>
              <th className="td-w10">No</th>
              <th className="td-w4">資料名</th>
              <th className="td-w4">ステータス</th>
              <th
                className="td-w4 table-cursor"
                onClick={() => {
                  if (funcSort) {
                    funcSort(!sortType || sortType === "DESC" ? "ASC" : "DESC");
                  }
                }}
              >
                <div className="table-sort-wrapper">
                  提出日
                  <div className="table-sort">
                    <img
                      alt=""
                      src={IconSortUp}
                      className={`icon-up ${sortType === "ASC" ? "icon-sorted" : ""
                        }`}
                    />
                    <img
                      alt=""
                      src={IconSortUp}
                      className={`icon-down ${sortType === "DESC" ? "icon-sorted" : ""
                        }`}
                    />
                  </div>
                </div>
              </th>
              <th className="td-w4 th-not-border-right">提出者</th>
              <th className="th-scroll"></th>
            </tr>
          </thead>
        </table>
      </div>
      <div className="cm035-table-body cm035-table-body-color">
        <table className="cm035-table">
          <tbody>
            {data?.length > 0 ? (
              data?.map((item: TableData, idx: number) => (
                <tr key={idx}>
                  <td className="td-w10">{item.no}</td>
                  <td
                    className="td-w4 table-cursor"
                    onClick={() =>
                      onClickDocument(
                        item.id,
                        item.originId,
                        item.submittedDate,
                        item.statusCode,
                        item.constructionId
                      )
                    }
                  >
                    {item.fileName}
                  </td>
                  <td className="td-w4">
                    <span
                      style={{
                        color:
                          item.statusCode ===
                            STATUS_CM_CHILD_TABLE.MONEY_REJECT.CODE
                            ? STATUS_CM_CHILD_TABLE.MONEY_REJECT.COLOR
                            : item.statusCode ===
                              STATUS_CM_CHILD_TABLE.MONEY_APPROVED.CODE
                              ? STATUS_CM_CHILD_TABLE.MONEY_APPROVED.COLOR
                              : "#FF9054",
                      }}
                    >{`${item.statusName}`}</span>

                    {item.statusCode ===
                      STATUS_CM_CHILD_TABLE.MONEY_REJECT.CODE ? (
                      <img
                        alt=""
                        src={WarningIcon}
                        className="cm053-icon"
                        onClick={() => handleViewRejectReason(item.reason)}
                      />
                    ) : null}
                  </td>
                  <td className="td-w4">
                    {formatDateView(item.submittedDate)}
                  </td>
                  <td className="td-w4">{item.approver}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="no-data-cm0042">
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="データなし"
                  />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableView;
